export const modelString = `# 3ds Max Wavefront OBJ Exporter v0.97b - (c)2007 guruware
# File Created: 19.04.2021 15:30:50

mtllib F_M_cityscape_190421.mtl

#
# object Sehir_geometri
#

v  -12.257174 0.000000 10.745413
v  -15.409227 0.000000 10.745413
v  -15.409227 0.000000 14.620455
v  -12.257174 0.000000 14.620455
v  -12.455576 8.248968 10.989321
v  -15.210825 8.248968 10.989321
v  -15.210825 8.248968 14.376543
v  -12.455576 8.248968 14.376543
v  -12.524458 8.248968 11.074001
v  -15.141943 8.248968 11.074001
v  -15.141943 8.248968 14.291861
v  -12.524458 8.248968 14.291861
v  -12.524458 8.218967 11.074001
v  -15.141943 8.218967 11.074001
v  -15.141943 8.218967 14.291861
v  -12.524458 8.218967 14.291861
v  -14.176752 8.218967 12.873945
v  -13.607798 8.218967 12.873945
v  -14.176752 8.421869 12.873945
v  -13.607798 8.421869 12.873945
v  -14.176752 8.421869 12.473663
v  -13.607798 8.421869 12.473663
v  -14.176752 8.218967 12.473663
v  -13.607798 8.218967 12.473663
v  -13.301733 8.218967 12.873945
v  -12.732780 8.218967 12.873945
v  -12.732780 8.421869 12.873945
v  -13.301733 8.421869 12.873945
v  -12.732780 8.421869 12.473663
v  -13.301733 8.421869 12.473663
v  -12.732780 8.218967 12.473663
v  -13.301733 8.218967 12.473663
v  -15.051769 8.218967 12.873945
v  -14.482817 8.218967 12.873945
v  -14.482817 8.421869 12.873945
v  -15.051769 8.421869 12.873945
v  -14.482817 8.421869 12.473663
v  -15.051769 8.421869 12.473663
v  -14.482817 8.218967 12.473663
v  -15.051769 8.218967 12.473663
v  -9.134100 0.000000 10.787104
v  -12.215853 0.000000 10.787104
v  -12.215853 0.000000 13.896338
v  -9.134100 0.000000 13.896338
v  -9.134100 6.220679 10.787104
v  -12.215853 6.220679 10.787104
v  -12.215853 6.220679 13.896338
v  -9.134100 6.220679 13.896338
v  -9.211143 6.220679 10.864836
v  -12.138807 6.220679 10.864836
v  -12.138807 6.220679 13.818605
v  -9.211143 6.220679 13.818605
v  -9.211143 6.190680 10.864836
v  -12.138807 6.190680 10.864836
v  -12.138807 6.190680 13.818605
v  -9.211143 6.190680 13.818605
v  -9.092777 1.191370 10.745413
v  -12.257174 1.191370 10.745413
v  -12.257174 1.191370 13.938028
v  -9.092777 1.191370 13.938028
v  -9.092777 1.339481 10.745413
v  -12.257174 1.339481 10.745413
v  -12.257174 1.339481 13.938028
v  -9.092777 1.339481 13.938028
v  -9.092777 1.339481 10.745413
v  -12.257174 1.339481 10.745413
v  -12.257174 1.339481 13.938028
v  -9.092777 1.339481 13.938028
v  -9.092777 1.191370 10.745413
v  -9.092777 1.191370 13.938028
v  -12.257174 1.191370 13.938028
v  -12.257174 1.191370 10.745413
v  -9.092777 2.228149 10.745413
v  -12.257174 2.228149 10.745413
v  -12.257174 2.228149 13.938028
v  -9.092777 2.228149 13.938028
v  -9.092777 2.080038 10.745413
v  -9.092777 2.080038 13.938028
v  -12.257174 2.080038 13.938028
v  -12.257174 2.080038 10.745413
v  -9.092777 3.116818 10.745413
v  -12.257174 3.116818 10.745413
v  -12.257174 3.116818 13.938028
v  -9.092777 3.116818 13.938028
v  -9.092777 2.968707 10.745413
v  -9.092777 2.968707 13.938028
v  -12.257174 2.968707 13.938028
v  -12.257174 2.968707 10.745413
v  -9.092777 4.005486 10.745413
v  -12.257174 4.005486 10.745413
v  -12.257174 4.005486 13.938028
v  -9.092777 4.005486 13.938028
v  -9.092777 3.857375 10.745413
v  -9.092777 3.857375 13.938028
v  -12.257174 3.857375 13.938028
v  -12.257174 3.857375 10.745413
v  -9.092777 4.894155 10.745413
v  -12.257174 4.894155 10.745413
v  -12.257174 4.894155 13.938028
v  -9.092777 4.894155 13.938028
v  -9.092777 4.746043 10.745413
v  -9.092777 4.746043 13.938028
v  -12.257174 4.746043 13.938028
v  -12.257174 4.746043 10.745413
v  -10.945917 6.190680 11.870100
v  -11.970917 6.190680 11.870100
v  -11.970917 6.190680 12.813339
v  -10.945917 6.190680 12.813339
v  -10.945917 6.582400 11.870100
v  -11.970917 6.582400 11.870100
v  -11.970917 6.582400 12.813339
v  -10.945917 6.582400 12.813339
v  -10.971540 6.582400 11.893682
v  -11.945293 6.582400 11.893682
v  -11.945293 6.582400 12.789757
v  -10.971540 6.582400 12.789757
v  -10.971540 6.567400 11.893682
v  -11.945293 6.567400 11.893682
v  -11.945293 6.567400 12.789757
v  -10.971540 6.567400 12.789757
v  -10.872976 6.190680 11.309994
v  -10.419563 6.190680 11.309994
v  -10.872976 6.450787 11.309994
v  -10.419563 6.450787 11.309994
v  -10.872976 6.450787 10.953125
v  -10.419563 6.450787 10.953125
v  -10.872976 6.190680 10.953125
v  -10.419563 6.190680 10.953125
v  -9.908144 6.190680 11.309994
v  -9.454731 6.190680 11.309994
v  -9.454731 6.450787 11.309994
v  -9.908144 6.450787 11.309994
v  -9.454731 6.450787 10.953125
v  -9.908144 6.450787 10.953125
v  -9.454731 6.190680 10.953125
v  -9.908144 6.190680 10.953125
v  -11.837809 6.190680 11.309994
v  -11.384396 6.190680 11.309994
v  -11.384396 6.450787 11.309994
v  -11.837809 6.450787 11.309994
v  -11.384396 6.450787 10.953125
v  -11.837809 6.450787 10.953125
v  -11.384396 6.190680 10.953125
v  -11.837809 6.190680 10.953125
v  -12.257174 0.000000 7.920736
v  -15.085566 0.000000 7.920736
v  -15.085566 0.000000 10.745413
v  -12.257174 0.000000 10.745413
v  -12.257174 2.639440 7.920736
v  -15.085566 2.639440 7.920736
v  -15.085566 2.639440 10.745413
v  -12.257174 2.639440 10.745413
v  -12.327883 2.639440 7.991354
v  -15.014856 2.639440 7.991354
v  -15.014856 2.639440 10.674795
v  -12.327883 2.639440 10.674795
v  -12.327883 2.609440 7.991354
v  -15.014856 2.609440 7.991354
v  -15.014856 2.609440 10.674795
v  -12.327883 2.609440 10.674795
v  -14.591814 2.609440 10.246330
v  -12.575327 2.609440 10.246330
v  -14.591814 2.843597 10.246330
v  -12.575327 2.843597 10.246330
v  -14.591814 2.843597 8.270290
v  -12.575327 2.843597 8.270290
v  -14.591814 2.609440 8.270290
v  -12.575327 2.609440 8.270290
v  -8.525747 0.000000 7.456556
v  -12.257174 0.000000 7.456556
v  -12.257174 0.000000 10.745413
v  -8.525747 0.000000 10.745413
v  -8.525747 4.124765 7.456556
v  -12.257174 4.124765 7.456556
v  -12.257174 4.124765 10.745413
v  -8.525747 4.124765 10.745413
v  -8.619034 4.124765 7.538778
v  -12.163889 4.124765 7.538778
v  -12.163889 4.124765 10.663191
v  -8.619034 4.124765 10.663191
v  -8.619034 4.094766 7.538778
v  -12.163889 4.094766 7.538778
v  -12.163889 4.094766 10.663191
v  -8.619034 4.094766 10.663191
v  -12.163889 4.094766 8.660738
v  -8.619034 4.094766 8.660738
v  -8.619034 4.471081 7.538778
v  -12.163889 4.471081 7.538778
v  -12.163889 4.471081 8.660738
v  -8.619034 4.471081 8.660738
v  12.208281 0.000000 -21.870111
v  9.226810 0.000000 -21.870111
v  9.226810 0.000000 -18.680101
v  12.208281 0.000000 -18.680101
v  12.208281 8.352775 -21.870111
v  9.226810 8.352775 -21.870111
v  9.226810 8.352775 -18.680101
v  12.208281 8.352775 -18.680101
v  12.133743 8.352775 -21.790361
v  9.301348 8.352775 -21.790361
v  9.301348 8.352775 -18.759851
v  12.133743 8.352775 -18.759851
v  12.133743 8.322774 -21.790361
v  9.301348 8.322774 -21.790361
v  9.301348 8.322774 -18.759851
v  12.133743 8.322774 -18.759851
v  11.261776 8.322774 -20.878412
v  10.173317 8.322774 -20.878412
v  10.173317 8.322774 -19.671801
v  11.261776 8.322774 -19.671801
v  11.261776 8.586019 -20.878412
v  10.173317 8.586019 -20.878412
v  10.173317 8.586019 -19.671801
v  11.261776 8.586019 -19.671801
v  9.505434 8.322774 -20.195856
v  11.425623 8.322774 -20.195856
v  9.505434 8.601906 -20.195856
v  11.425623 8.601906 -20.195856
v  9.505434 8.601906 -21.440035
v  11.425623 8.601906 -21.440035
v  9.505434 8.322774 -21.440035
v  11.425623 8.322774 -21.440035
v  -6.614736 0.000000 -11.846828
v  -9.465081 0.000000 -11.846828
v  -9.465081 0.000000 -7.893147
v  -6.614736 0.000000 -7.893147
v  -6.614736 10.181928 -11.846828
v  -9.465081 10.181928 -11.846828
v  -9.465081 10.181928 -7.893147
v  -6.614736 10.181928 -7.893147
v  -6.685994 10.181928 -11.747986
v  -9.393823 10.181928 -11.747986
v  -9.393823 10.181928 -7.991990
v  -6.685994 10.181928 -7.991990
v  -6.685994 10.151927 -11.747986
v  -9.393823 10.151927 -11.747986
v  -9.393823 10.151927 -7.991990
v  -6.685994 10.151927 -7.991990
v  -7.060288 10.151927 -11.228807
v  -9.019528 10.151927 -11.228807
v  -9.019528 10.151927 -8.511168
v  -7.060288 10.151927 -8.511168
v  -7.060288 11.210813 -11.228807
v  -9.019528 11.210813 -11.228807
v  -9.019528 11.210813 -8.511168
v  -7.060288 11.210813 -8.511168
v  -7.109269 11.210813 -11.160866
v  -8.970548 11.210813 -11.160866
v  -8.970548 11.210813 -8.579110
v  -7.109269 11.210813 -8.579110
v  -7.109269 11.180812 -11.160866
v  -8.970548 11.180812 -11.160866
v  -8.970548 11.180812 -8.579110
v  -7.109269 11.180812 -8.579110
v  -7.946845 11.180812 -9.999076
v  -8.132974 11.180812 -9.999076
v  -8.132974 11.180812 -9.740901
v  -7.946845 11.180812 -9.740901
v  -8.030601 14.296998 -9.882899
v  -8.049214 14.296998 -9.882899
v  -8.049214 14.296998 -9.857081
v  -8.030601 14.296998 -9.857081
v  11.935252 0.000000 -24.874985
v  9.292871 0.000000 -24.874985
v  9.292871 0.000000 -21.945126
v  11.935252 0.000000 -21.945126
v  11.935252 6.068041 -24.874985
v  9.292871 6.068041 -24.874985
v  9.292871 6.068041 -21.945126
v  11.935252 6.068041 -21.945126
v  11.869193 6.068041 -24.801739
v  9.358930 6.068041 -24.801739
v  9.358930 6.068041 -22.018372
v  11.869193 6.068041 -22.018372
v  11.869193 6.038042 -24.801739
v  9.358930 6.038042 -24.801739
v  9.358930 6.038042 -22.018372
v  11.869193 6.038042 -22.018372
v  12.001310 0.000000 -21.870111
v  12.001310 0.000000 -22.020140
v  12.001310 5.922347 -21.870111
v  12.001310 5.922347 -22.020140
v  9.226809 5.922347 -21.870111
v  9.226809 5.922347 -22.020140
v  9.226809 0.000000 -21.870111
v  9.226809 0.000000 -22.020140
v  12.001310 0.000000 -22.358421
v  12.001310 0.000000 -22.508450
v  12.001310 5.922347 -22.508450
v  12.001310 5.922347 -22.358421
v  9.226809 5.922347 -22.508450
v  9.226809 5.922347 -22.358421
v  9.226809 0.000000 -22.508450
v  9.226809 0.000000 -22.358421
v  12.001310 0.000000 -22.846733
v  12.001310 0.000000 -22.996758
v  12.001310 5.922347 -22.996758
v  12.001310 5.922347 -22.846733
v  9.226809 5.922347 -22.996758
v  9.226809 5.922347 -22.846733
v  9.226809 0.000000 -22.996758
v  9.226809 0.000000 -22.846733
v  12.001310 0.000000 -23.335041
v  12.001310 0.000000 -23.485069
v  12.001310 5.922347 -23.485069
v  12.001310 5.922347 -23.335041
v  9.226809 5.922347 -23.485069
v  9.226809 5.922347 -23.335041
v  9.226809 0.000000 -23.485069
v  9.226809 0.000000 -23.335041
v  12.001310 0.000000 -23.823351
v  12.001310 0.000000 -23.973379
v  12.001310 5.922347 -23.973379
v  12.001310 5.922347 -23.823351
v  9.226809 5.922347 -23.973379
v  9.226809 5.922347 -23.823351
v  9.226809 0.000000 -23.973379
v  9.226809 0.000000 -23.823351
v  12.001310 0.000000 -24.311661
v  12.001310 0.000000 -24.461689
v  12.001310 5.922347 -24.461689
v  12.001310 5.922347 -24.311661
v  9.226809 5.922347 -24.461689
v  9.226809 5.922347 -24.311661
v  9.226809 0.000000 -24.461689
v  9.226809 0.000000 -24.311661
v  12.001310 0.000000 -24.799973
v  12.001310 0.000000 -24.950001
v  12.001310 5.922347 -24.950001
v  12.001310 5.922347 -24.799973
v  9.226809 5.922347 -24.950001
v  9.226809 5.922347 -24.799973
v  9.226809 0.000000 -24.950001
v  9.226809 0.000000 -24.799973
v  11.030906 6.038042 -23.785961
v  10.197217 6.038042 -23.785961
v  10.197217 6.038042 -23.034151
v  11.030906 6.038042 -23.034151
v  11.030906 6.478110 -23.785961
v  10.197217 6.478110 -23.785961
v  10.197217 6.478110 -23.034151
v  11.030906 6.478110 -23.034151
v  11.010064 6.478110 -23.767166
v  10.218059 6.478110 -23.767166
v  10.218059 6.478110 -23.052946
v  11.010064 6.478110 -23.052946
v  11.010064 6.463110 -23.767166
v  10.218059 6.463110 -23.767166
v  10.218059 6.463110 -23.052946
v  11.010064 6.463110 -23.052946
v  10.028172 6.038042 -22.913893
v  10.028172 6.038042 -23.361931
v  10.028172 6.329536 -22.913893
v  10.028172 6.329536 -23.361931
v  9.788740 6.329536 -22.913893
v  9.788740 6.329536 -23.361931
v  9.788740 6.038042 -22.913893
v  9.788740 6.038042 -23.361931
v  10.022186 6.329536 -22.925093
v  10.022186 6.329536 -23.350729
v  9.794725 6.329536 -23.350729
v  9.794725 6.329536 -22.925093
v  10.022186 6.325164 -22.925093
v  10.022186 6.325164 -23.350729
v  9.794725 6.325164 -23.350729
v  9.794725 6.325164 -22.925093
v  10.028172 6.038042 -23.689528
v  10.028172 6.038042 -24.137566
v  10.028172 6.329536 -24.137566
v  10.028172 6.329536 -23.689528
v  10.022185 6.325164 -23.700729
v  10.022185 6.325164 -24.126366
v  9.794724 6.325164 -24.126366
v  9.794724 6.325164 -23.700729
v  9.788740 6.329536 -23.689528
v  9.788740 6.329536 -24.137566
v  9.788740 6.038042 -24.137566
v  9.788740 6.038042 -23.689528
v  10.028172 6.038042 -22.138256
v  10.028172 6.038042 -22.586296
v  10.028172 6.329536 -22.586296
v  10.028172 6.329536 -22.138256
v  10.022185 6.325164 -22.149458
v  10.022185 6.325164 -22.575094
v  9.794724 6.325164 -22.575094
v  9.794724 6.325164 -22.149458
v  9.788740 6.329536 -22.138256
v  9.788740 6.329536 -22.586296
v  9.788740 6.038042 -22.586296
v  9.788740 6.038042 -22.138256
v  -0.896320 0.000000 18.608791
v  -4.341330 0.000000 18.608791
v  -4.341330 0.000000 22.439264
v  -0.896320 0.000000 22.439264
v  -0.896320 10.684544 18.608791
v  -4.341330 10.684544 18.608791
v  -4.341330 10.684544 22.439264
v  -0.896320 10.684544 22.439264
v  -0.982445 10.684544 18.704554
v  -4.255203 10.684544 18.704554
v  -4.255203 10.684544 22.343502
v  -0.982445 10.684544 22.343502
v  -0.982445 10.654543 18.704554
v  -4.255203 10.654543 18.704554
v  -4.255203 10.654543 22.343502
v  -0.982445 10.654543 22.343502
v  -0.810194 0.000000 22.503782
v  -0.810194 0.000000 22.374746
v  -0.810194 10.508604 22.503782
v  -0.810194 10.508604 22.374746
v  -4.427454 10.508604 22.503782
v  -4.427454 10.508604 22.374746
v  -4.427454 0.000000 22.503782
v  -4.427454 0.000000 22.374746
v  -0.810194 0.000000 21.737688
v  -0.810194 0.000000 21.608652
v  -0.810194 10.508604 21.608652
v  -0.810194 10.508604 21.737688
v  -4.427454 10.508604 21.608652
v  -4.427454 10.508604 21.737688
v  -4.427454 0.000000 21.608652
v  -4.427454 0.000000 21.737688
v  -0.810194 0.000000 20.971594
v  -0.810194 0.000000 20.842558
v  -0.810194 10.508604 20.842558
v  -0.810194 10.508604 20.971594
v  -4.427454 10.508604 20.842558
v  -4.427454 10.508604 20.971594
v  -4.427454 0.000000 20.842558
v  -4.427454 0.000000 20.971594
v  -0.810194 0.000000 20.205500
v  -0.810194 0.000000 20.076462
v  -0.810194 10.508604 20.076462
v  -0.810194 10.508604 20.205500
v  -4.427454 10.508604 20.076462
v  -4.427454 10.508604 20.205500
v  -4.427454 0.000000 20.076462
v  -4.427454 0.000000 20.205500
v  -0.810194 0.000000 19.439405
v  -0.810194 0.000000 19.310368
v  -0.810194 10.508604 19.310368
v  -0.810194 10.508604 19.439405
v  -4.427454 10.508604 19.310368
v  -4.427454 10.508604 19.439405
v  -4.427454 0.000000 19.310368
v  -4.427454 0.000000 19.439405
v  -0.810194 0.000000 18.673309
v  -0.810194 0.000000 18.544273
v  -0.810194 10.508604 18.544273
v  -0.810194 10.508604 18.673309
v  -4.427454 10.508604 18.544273
v  -4.427454 10.508604 18.673309
v  -4.427454 0.000000 18.544273
v  -4.427454 0.000000 18.673309
v  -1.199504 10.654543 19.886971
v  -2.376763 10.654543 19.886971
v  -2.376763 10.654543 21.161085
v  -1.199504 10.654543 21.161085
v  -1.199504 11.069887 19.886971
v  -2.376763 11.069887 19.886971
v  -2.376763 11.069887 21.161085
v  -1.199504 11.069887 21.161085
v  -1.228935 11.069887 19.918823
v  -2.347332 11.069887 19.918823
v  -2.347332 11.069887 21.129232
v  -1.228935 11.069887 21.129232
v  -1.228935 11.054888 19.918823
v  -2.347332 11.054888 19.918823
v  -2.347332 11.054888 21.129232
v  -1.228935 11.054888 21.129232
v  -3.767850 10.654543 21.982698
v  -1.601246 10.654543 21.982698
v  -3.767850 10.893778 21.982698
v  -1.601246 10.893778 21.982698
v  -3.767850 10.893778 20.149107
v  -1.601246 10.893778 20.149107
v  -3.767850 10.654543 20.149107
v  -1.601246 10.654543 20.149107
v  -5.694796 0.000000 13.998528
v  -9.070875 0.000000 13.998528
v  -9.070875 0.000000 17.163860
v  -5.694796 0.000000 17.163860
v  -5.694796 8.228107 13.998528
v  -9.070875 8.228107 13.998528
v  -9.070875 8.228107 17.163860
v  -5.694796 8.228107 17.163860
v  -5.779197 8.228107 14.077661
v  -8.986473 8.228107 14.077661
v  -8.986473 8.228107 17.084726
v  -5.779197 8.228107 17.084726
v  -5.779197 8.198107 14.077661
v  -8.986473 8.198107 14.077661
v  -8.986473 8.198107 17.084726
v  -5.779197 8.198107 17.084726
v  -6.887527 8.198107 14.421887
v  -7.878143 8.198107 14.421887
v  -7.878143 8.198107 15.586270
v  -6.887527 8.198107 15.586270
v  -6.887527 8.486664 14.421887
v  -7.878143 8.486664 14.421887
v  -7.878143 8.486664 15.586270
v  -6.887527 8.486664 15.586270
v  -8.824478 8.198107 16.412878
v  -7.031260 8.198107 16.412878
v  -8.824478 8.411730 16.412878
v  -7.031260 8.411730 16.412878
v  -8.824478 8.411730 15.425564
v  -7.031260 8.411730 15.425564
v  -8.824478 8.198107 15.425564
v  -7.031260 8.198107 15.425564
v  -4.406103 0.000000 9.239492
v  -7.693821 0.000000 9.239492
v  -7.693821 0.000000 12.580381
v  -4.406103 0.000000 12.580381
v  -5.008865 6.331787 9.852002
v  -7.091060 6.331787 9.852002
v  -7.091060 6.331787 11.967869
v  -5.008865 6.331787 11.967869
v  -5.060920 6.331787 9.904899
v  -7.039004 6.331787 9.904899
v  -7.039004 6.331787 11.914973
v  -5.060920 6.331787 11.914973
v  -5.060920 6.301787 9.904899
v  -7.039004 6.301787 9.904899
v  -7.039004 6.301787 11.914973
v  -5.060920 6.301787 11.914973
v  -5.784434 6.301787 11.012163
v  -6.315490 6.301787 11.012163
v  -6.315490 6.301787 11.773532
v  -5.784434 6.301787 11.773532
v  -5.784434 6.543243 11.012163
v  -6.315490 6.543243 11.012163
v  -6.315490 6.543243 11.773532
v  -5.784434 6.543243 11.773532
v  -5.797710 6.543243 11.031197
v  -6.302213 6.543243 11.031197
v  -6.302213 6.543243 11.754498
v  -5.797710 6.543243 11.754498
v  -5.797710 6.528243 11.031197
v  -6.302213 6.528243 11.031197
v  -6.302213 6.528243 11.754498
v  -5.797710 6.528243 11.754498
v  -5.886267 6.301787 11.247852
v  -5.538877 6.301787 11.247852
v  -5.191488 6.301787 11.247852
v  -5.886267 6.514307 11.247852
v  -5.538877 6.514307 11.247852
v  -5.191488 6.514307 11.247852
v  -5.886267 6.514307 10.154488
v  -5.538877 6.514307 10.154488
v  -5.191488 6.514307 10.154488
v  -5.886267 6.514307 9.911119
v  -5.538877 6.514307 9.911119
v  -5.191488 6.514307 9.911119
v  -5.886267 6.301787 9.911119
v  -5.538877 6.301787 9.911119
v  -5.191488 6.301787 9.911119
v  -5.886267 6.301787 10.154488
v  -5.191488 6.301787 10.154488
v  -7.026479 6.301787 10.154488
v  -7.026479 6.301787 11.247852
v  -7.026479 6.514307 11.247852
v  -7.026479 6.514307 10.154488
v  24.567627 0.000000 1.047648
v  21.054941 0.000000 1.047648
v  21.054941 0.000000 3.952352
v  24.567627 0.000000 3.952352
v  24.567627 4.789922 1.047648
v  21.054941 4.789922 1.047648
v  21.054941 4.789922 3.952352
v  24.567627 4.789922 3.952352
v  24.479809 4.789922 1.120266
v  21.142759 4.789922 1.120266
v  21.142759 4.789922 3.879734
v  24.479809 4.789922 3.879734
v  24.479809 4.759923 1.120266
v  21.142759 4.759923 1.120266
v  21.142759 4.759923 3.879734
v  24.479809 4.759923 3.879734
v  22.336370 4.759923 1.120266
v  22.336370 4.759923 3.879734
v  24.479809 5.022740 1.120266
v  22.336370 5.022740 1.120266
v  22.336370 5.022740 3.879734
v  24.479809 5.022740 3.879734
v  21.969799 4.759923 2.795343
v  24.193510 4.759923 2.795343
v  21.969799 5.055146 2.795343
v  24.193510 5.055146 2.795343
v  21.969799 5.055146 1.501697
v  24.193510 5.055146 1.501697
v  21.969799 4.759923 1.501697
v  24.193510 4.759923 1.501697
v  11.601147 0.000000 -18.595697
v  11.601147 0.000000 -18.761559
v  11.601147 3.020997 -18.761559
v  11.601147 3.020997 -18.595697
v  8.555209 3.020997 -18.761559
v  8.555209 3.020997 -18.595697
v  8.555209 0.000000 -18.761559
v  8.555209 0.000000 -18.595697
v  11.601147 0.000000 -19.270788
v  11.601147 0.000000 -19.436651
v  11.601147 3.020997 -19.436651
v  11.601147 3.020997 -19.270788
v  8.555209 3.020997 -19.436651
v  8.555209 3.020997 -19.270788
v  8.555209 0.000000 -19.436651
v  8.555209 0.000000 -19.270788
v  11.601147 0.000000 -19.945879
v  11.601147 0.000000 -20.111742
v  11.601147 3.020997 -20.111742
v  11.601147 3.020997 -19.945879
v  8.555209 3.020997 -20.111742
v  8.555209 3.020997 -19.945879
v  8.555209 0.000000 -20.111742
v  8.555209 0.000000 -19.945879
v  10.489692 0.000000 -3.877431
v  6.987689 0.000000 -3.877431
v  6.987689 0.000000 -1.122569
v  10.489692 0.000000 -1.122569
v  10.489692 6.207723 -3.877431
v  6.987689 6.207723 -3.877431
v  6.987689 6.207723 -1.122569
v  10.489692 6.207723 -1.122569
v  10.402143 6.207723 -3.808559
v  7.075240 6.207723 -3.808559
v  7.075240 6.207723 -1.191440
v  10.402143 6.207723 -1.191440
v  10.402143 6.177723 -3.808559
v  7.075240 6.177723 -3.808559
v  7.075240 6.177723 -1.191440
v  10.402143 6.177723 -1.191440
v  9.946256 6.177723 -3.449934
v  7.531126 6.177723 -3.449934
v  7.531126 6.177723 -1.550065
v  9.946256 6.177723 -1.550065
v  9.946256 7.567865 -3.449934
v  7.531126 7.567865 -3.449934
v  7.531126 7.567865 -1.550065
v  9.946256 7.567865 -1.550065
v  9.885877 7.567865 -3.402438
v  7.591505 7.567865 -3.402438
v  7.591505 7.567865 -1.597562
v  9.885877 7.567865 -1.597562
v  9.885877 7.537866 -3.402438
v  7.591505 7.537866 -3.402438
v  7.591505 7.537866 -1.597562
v  9.885877 7.537866 -1.597562
v  8.853410 7.537866 -2.590244
v  8.623972 7.537866 -2.590244
v  8.623972 7.537866 -2.409756
v  8.853410 7.537866 -2.409756
v  8.750162 12.441266 -2.509024
v  8.727219 12.441266 -2.509024
v  8.727219 12.441266 -2.490976
v  8.750162 12.441266 -2.490976
v  10.539655 0.829260 -3.916734
v  6.937728 0.829260 -3.916734
v  6.937728 0.829260 -1.083266
v  10.539655 0.829260 -1.083266
v  10.539655 1.050964 -3.916734
v  6.937728 1.050964 -3.916734
v  6.937728 1.050964 -1.083266
v  10.539655 1.050964 -1.083266
v  10.539655 1.050964 -3.916734
v  6.937728 1.050964 -3.916734
v  6.937728 1.050964 -1.083266
v  10.539655 1.050964 -1.083266
v  10.539655 0.829260 -3.916734
v  10.539655 0.829260 -1.083266
v  6.937728 0.829260 -1.083266
v  6.937728 0.829260 -3.916734
v  10.539655 2.381191 -3.916734
v  6.937728 2.381191 -3.916734
v  6.937728 2.381191 -1.083266
v  10.539655 2.381191 -1.083266
v  10.539655 2.159486 -3.916734
v  10.539655 2.159486 -1.083266
v  6.937728 2.159486 -1.083266
v  6.937728 2.159486 -3.916734
v  10.539655 3.711417 -3.916734
v  6.937728 3.711417 -3.916734
v  6.937728 3.711417 -1.083266
v  10.539655 3.711417 -1.083266
v  10.539655 3.489712 -3.916734
v  10.539655 3.489712 -1.083266
v  6.937728 3.489712 -1.083266
v  6.937728 3.489712 -3.916734
v  14.148818 -0.064248 -2.506528
v  11.483947 -0.064248 -2.506528
v  11.483947 -0.064248 -0.076804
v  14.148818 -0.064248 -0.076804
v  14.148818 4.049273 -2.506528
v  11.483947 4.049273 -2.506528
v  11.483947 4.049273 -0.076804
v  14.148818 4.049273 -0.076804
v  14.082194 4.049273 -2.445785
v  11.550570 4.049273 -2.445785
v  11.550570 4.049273 -0.137547
v  14.082194 4.049273 -0.137547
v  14.082194 4.021059 -2.445785
v  11.550570 4.021059 -2.445785
v  11.550570 4.021059 -0.137547
v  14.082194 4.021059 -0.137547
v  12.370217 4.021059 -2.445785
v  12.370217 4.021059 -0.137547
v  14.082194 4.424889 -2.445785
v  12.370217 4.424889 -2.445785
v  12.370217 4.424889 -0.137547
v  14.082194 4.424889 -0.137547
v  13.345197 4.071423 -1.589682
v  13.700372 4.071423 -1.589682
v  14.055552 4.071423 -1.589682
v  13.345197 4.287859 -1.589682
v  13.700372 4.287859 -1.589682
v  14.055552 4.287859 -1.589682
v  13.345197 4.287859 -1.914985
v  13.700372 4.287859 -1.914985
v  14.055552 4.287859 -1.914985
v  13.345197 4.287859 -2.113495
v  13.700372 4.287859 -2.113495
v  14.055552 4.287859 -2.113495
v  13.345197 4.071423 -2.113495
v  13.700372 4.071423 -2.113495
v  14.055552 4.071423 -2.113495
v  13.345197 4.071423 -1.914985
v  14.055552 4.071423 -1.914985
v  12.206595 4.071423 -1.914985
v  12.206595 4.071423 -1.589682
v  12.206595 4.287859 -1.589682
v  12.206595 4.287859 -1.914985
v  13.591099 4.499829 -1.038321
v  13.591099 4.499829 -1.306693
v  13.591099 4.499829 -1.575064
v  13.591099 4.692089 -1.038321
v  13.591099 4.692089 -1.306693
v  13.591099 4.692089 -1.575064
v  12.892731 4.692089 -1.038321
v  12.892731 4.692089 -1.306693
v  12.892731 4.692089 -1.575064
v  12.736900 4.692089 -1.038321
v  12.736900 4.692089 -1.306693
v  12.736900 4.692089 -1.575064
v  12.736900 4.499829 -1.038321
v  12.736900 4.499829 -1.306693
v  12.736900 4.499829 -1.575064
v  12.892731 4.499829 -1.038321
v  12.892731 4.499829 -1.575064
v  12.892731 4.499829 -0.411815
v  13.591099 4.499829 -0.411815
v  13.591099 4.692089 -0.411815
v  12.892731 4.692089 -0.411815
v  24.448248 0.000000 -4.647361
v  20.551752 0.000000 -4.647361
v  20.551752 0.000000 -0.841699
v  24.448248 0.000000 -0.841699
v  24.448248 10.049522 -4.647361
v  20.551752 10.049522 -4.647361
v  20.551752 10.049522 -0.841699
v  24.448248 10.049522 -0.841699
v  24.350836 10.049522 -4.552219
v  20.649164 10.049522 -4.552219
v  20.649164 10.049522 -0.936840
v  24.350836 10.049522 -0.936840
v  24.350836 10.019522 -4.552219
v  20.649164 10.019522 -4.552219
v  20.649164 10.019522 -0.936840
v  24.350836 10.019522 -0.936840
v  23.999338 10.019522 -3.301515
v  22.614233 10.019522 -3.301515
v  22.614233 10.019522 -2.187544
v  23.999338 10.019522 -2.187544
v  23.999338 10.422915 -3.301515
v  22.614233 10.422915 -3.301515
v  22.614233 10.422915 -2.187544
v  23.999338 10.422915 -2.187544
v  23.964712 10.422915 -3.273666
v  22.648859 10.422915 -3.273666
v  22.648859 10.422915 -2.215393
v  23.964712 10.422915 -2.215393
v  23.964712 10.407915 -3.273666
v  22.648859 10.407915 -3.273666
v  22.648859 10.407915 -2.215393
v  23.964712 10.407915 -2.215393
v  22.434893 10.019522 -2.389184
v  22.434893 10.019522 -2.995044
v  22.434893 10.311630 -2.389184
v  22.434893 10.311630 -2.995044
v  22.024805 10.311630 -2.389184
v  22.024805 10.311630 -2.995044
v  22.024805 10.019522 -2.389184
v  22.024805 10.019522 -2.995044
v  22.434893 10.019522 -3.613508
v  22.434893 10.019522 -4.219368
v  22.434893 10.311630 -4.219368
v  22.434893 10.311630 -3.613508
v  22.024805 10.311630 -4.219368
v  22.024805 10.311630 -3.613508
v  22.024805 10.019522 -4.219368
v  22.024805 10.019522 -3.613508
v  22.434893 10.019522 -1.164860
v  22.434893 10.019522 -1.770720
v  22.434893 10.311630 -1.770720
v  22.434893 10.311630 -1.164860
v  22.024805 10.311630 -1.770720
v  22.024805 10.311630 -1.164860
v  22.024805 10.019522 -1.770720
v  22.024805 10.019522 -1.164860
v  -20.532080 0.000000 3.342813
v  -23.123571 0.000000 3.342813
v  -23.123571 0.000000 6.664753
v  -20.532080 0.000000 6.664753
v  -20.532080 1.466470 3.342813
v  -23.123571 1.466470 3.342813
v  -23.123571 1.466470 6.664753
v  -20.532080 1.466470 6.664753
v  -20.596869 1.466470 3.425860
v  -23.058783 1.466470 3.425860
v  -23.058783 1.466470 6.581703
v  -20.596869 1.466470 6.581703
v  -20.596869 1.436470 3.425860
v  -23.058783 1.436470 3.425860
v  -23.058783 1.436470 6.581703
v  -20.596869 1.436470 6.581703
v  -21.727551 1.436470 5.973878
v  -21.220072 1.436470 5.973878
v  -20.712591 1.436470 5.973878
v  -21.727551 1.649537 5.973878
v  -21.220072 1.649537 5.973878
v  -20.712591 1.649537 5.973878
v  -21.727551 1.649537 5.128112
v  -21.220072 1.649537 5.128112
v  -20.712591 1.649537 5.128112
v  -21.727551 1.649537 4.842336
v  -21.220072 1.649537 4.842336
v  -20.712591 1.649537 4.842336
v  -21.727551 1.436470 4.842336
v  -21.220072 1.436470 4.842336
v  -20.712591 1.436470 4.842336
v  -21.727551 1.436470 5.128112
v  -20.712591 1.436470 5.128112
v  -22.965044 1.436470 5.128112
v  -22.965044 1.436470 5.973878
v  -22.965044 1.649537 5.973878
v  -22.965044 1.649537 5.128112
v  -16.059332 0.000000 3.400553
v  -18.706339 0.000000 3.400553
v  -18.706339 0.000000 6.607013
v  -16.059332 0.000000 6.607013
v  -16.059332 2.968906 3.400553
v  -18.706339 2.968906 3.400553
v  -18.706339 2.968906 6.607013
v  -16.059332 2.968906 6.607013
v  -16.125505 2.968906 3.480716
v  -18.640165 2.968906 3.480716
v  -18.640165 2.968906 6.526850
v  -16.125505 2.968906 6.526850
v  -16.125505 2.938906 3.480716
v  -18.640165 2.938906 3.480716
v  -18.640165 2.938906 6.526850
v  -16.125505 2.938906 6.526850
v  -17.599552 2.938906 4.188208
v  -17.225449 2.938906 4.188208
v  -17.599552 3.219158 4.188208
v  -17.225449 3.219158 4.188208
v  -17.599552 3.219158 3.981598
v  -17.225449 3.219158 3.981598
v  -17.599552 2.938906 3.981598
v  -17.225449 2.938906 3.981598
v  -16.615261 2.938906 4.188208
v  -16.241156 2.938906 4.188208
v  -16.241156 3.219158 4.188208
v  -16.615261 3.219158 4.188208
v  -16.241156 3.219158 3.981598
v  -16.615261 3.219158 3.981598
v  -16.241156 2.938906 3.981598
v  -16.615261 2.938906 3.981598
v  -18.583845 2.938906 4.188208
v  -18.209740 2.938906 4.188208
v  -18.209740 3.219158 4.188208
v  -18.583845 3.219158 4.188208
v  -18.209740 3.219158 3.981598
v  -18.583845 3.219158 3.981598
v  -18.209740 2.938906 3.981598
v  -18.583845 2.938906 3.981598
v  -9.932169 0.000000 2.547712
v  -13.848822 0.000000 2.547712
v  -13.848822 0.000000 6.035397
v  -9.932169 0.000000 6.035397
v  -9.932169 7.323152 2.547712
v  -13.848822 7.323152 2.547712
v  -13.848822 7.323152 6.035397
v  -9.932169 7.323152 6.035397
v  -10.030085 7.323152 2.634905
v  -13.750906 7.323152 2.634905
v  -13.750906 7.323152 5.948204
v  -10.030085 7.323152 5.948204
v  -10.030085 7.293152 2.634905
v  -13.750906 7.293152 2.634905
v  -13.750906 7.293152 5.948204
v  -10.030085 7.293152 5.948204
v  -9.882835 2.632442 2.503782
v  -13.898155 2.632442 2.503782
v  -13.898155 2.632442 6.079327
v  -9.882835 2.632442 6.079327
v  -9.882835 2.266284 2.503782
v  -9.882835 2.266284 6.079327
v  -13.898155 2.266284 6.079327
v  -13.898155 2.266284 2.503782
v  -9.882835 3.730914 2.503782
v  -13.898155 3.730914 2.503782
v  -13.898155 3.730914 6.079327
v  -9.882835 3.730914 6.079327
v  -9.882835 3.364757 2.503782
v  -9.882835 3.364757 6.079327
v  -13.898155 3.364757 6.079327
v  -13.898155 3.364757 2.503782
v  -9.882835 4.829387 2.503782
v  -13.898155 4.829387 2.503782
v  -13.898155 4.829387 6.079327
v  -9.882835 4.829387 6.079327
v  -9.882835 4.463230 2.503782
v  -9.882835 4.463230 6.079327
v  -13.898155 4.463230 6.079327
v  -13.898155 4.463230 2.503782
v  11.601149 0.000000 -23.104498
v  8.457485 0.000000 -23.104498
v  8.457485 0.000000 -20.111742
v  11.601149 0.000000 -20.111742
v  11.231855 5.851891 -22.752932
v  8.826777 5.851891 -22.752932
v  8.826777 5.851891 -20.463306
v  11.231855 5.851891 -20.463306
v  11.171728 5.851891 -22.695690
v  8.886906 5.851891 -22.695690
v  8.886906 5.851891 -20.520548
v  11.171728 5.851891 -20.520548
v  11.171728 5.821891 -22.695690
v  8.886906 5.821891 -22.695690
v  8.886906 5.821891 -20.520548
v  11.171728 5.821891 -20.520548
v  10.359119 5.821891 -20.639017
v  10.763758 5.821891 -20.639017
v  11.168394 5.821891 -20.639017
v  10.359119 6.023614 -20.639017
v  10.763758 6.023614 -20.639017
v  11.168394 6.023614 -20.639017
v  10.359119 6.023614 -21.588131
v  10.763758 6.023614 -21.588131
v  11.168394 6.023614 -21.588131
v  10.359119 6.023614 -21.883041
v  10.763758 6.023614 -21.883041
v  11.168394 6.023614 -21.883041
v  10.359119 5.821891 -21.883041
v  10.763758 5.821891 -21.883041
v  11.168394 5.821891 -21.883041
v  10.359119 5.821891 -21.588131
v  11.168394 5.821891 -21.588131
v  8.890606 5.821891 -21.588131
v  8.890606 5.821891 -20.639017
v  8.890606 6.023614 -20.639017
v  8.890606 6.023614 -21.588131
v  14.210690 0.000000 -22.799597
v  11.601148 0.000000 -22.799597
v  11.601148 0.000000 -20.111742
v  14.210690 0.000000 -20.111742
v  14.210690 2.628273 -22.799597
v  11.601148 2.628273 -22.799597
v  11.601148 2.628273 -20.111742
v  14.210690 2.628273 -20.111742
v  14.145452 2.628273 -22.732401
v  11.666386 2.628273 -22.732401
v  11.666386 2.628273 -20.178938
v  14.145452 2.628273 -20.178938
v  14.145452 2.598273 -22.732401
v  11.666386 2.598273 -22.732401
v  11.666386 2.598273 -20.178938
v  14.145452 2.598273 -20.178938
v  13.109018 2.598273 -20.510508
v  13.591749 2.598273 -20.510508
v  14.074484 2.598273 -20.510508
v  13.109018 2.832885 -20.510508
v  13.591749 2.832885 -20.510508
v  14.074484 2.832885 -20.510508
v  13.109018 2.832885 -21.380800
v  13.591749 2.832885 -21.380800
v  14.074484 2.832885 -21.380800
v  13.109018 2.832885 -21.748360
v  13.591749 2.832885 -21.748360
v  14.074484 2.832885 -21.748360
v  13.109018 2.598273 -21.748360
v  13.591749 2.598273 -21.748360
v  14.074484 2.598273 -21.748360
v  13.109018 2.598273 -21.380800
v  14.074484 2.598273 -21.380800
v  11.726448 2.598273 -21.380800
v  11.726448 2.598273 -20.510508
v  11.726448 2.832885 -20.510508
v  11.726448 2.832885 -21.380800
v  10.844562 0.000000 -9.442596
v  7.178854 0.000000 -9.442596
v  7.178854 0.000000 -5.557404
v  10.844562 0.000000 -5.557404
v  10.844562 9.080868 -9.442596
v  7.178854 9.080868 -9.442596
v  7.178854 9.080868 -5.557404
v  10.844562 9.080868 -5.557404
v  10.752919 9.080868 -9.345467
v  7.270496 9.080868 -9.345467
v  7.270496 9.080868 -5.654534
v  10.752919 9.080868 -5.654534
v  10.752919 9.050867 -9.345467
v  7.270496 9.050867 -9.345467
v  7.270496 9.050867 -5.654534
v  10.752919 9.050867 -5.654534
v  10.397107 9.050867 -8.968349
v  7.626307 9.050867 -8.968349
v  7.626307 9.050867 -6.031650
v  10.397107 9.050867 -6.031650
v  10.397107 10.838661 -8.968349
v  7.626307 10.838661 -8.968349
v  7.626307 10.838661 -6.031650
v  10.397107 10.838661 -6.031650
v  10.327838 10.838661 -8.894932
v  7.695578 10.838661 -8.894932
v  7.695578 10.838661 -6.105068
v  10.327838 10.838661 -6.105068
v  10.327838 10.808661 -8.894932
v  7.695578 10.808661 -8.894932
v  7.695578 10.808661 -6.105068
v  10.327838 10.808661 -6.105068
v  23.750029 0.000000 -9.390560
v  21.249971 0.000000 -9.390560
v  21.249971 0.000000 -5.609439
v  23.750029 0.000000 -5.609439
v  23.750029 7.377601 -9.390560
v  21.249971 7.377601 -9.390560
v  21.249971 7.377601 -5.609439
v  23.750029 7.377601 -5.609439
v  23.687529 7.377601 -9.296032
v  21.312471 7.377601 -9.296032
v  21.312471 7.377601 -5.703968
v  23.687529 7.377601 -5.703968
v  23.687529 7.347601 -9.296032
v  21.312471 7.347601 -9.296032
v  21.312471 7.347601 -5.703968
v  23.687529 7.347601 -5.703968
v  22.324991 7.347601 -5.840997
v  22.917534 7.347601 -5.840997
v  23.510075 7.347601 -5.840997
v  22.324991 7.595866 -5.840997
v  22.917534 7.595866 -5.840997
v  23.510075 7.595866 -5.840997
v  22.324991 7.595866 -8.314647
v  22.917534 7.595866 -8.314647
v  23.510075 7.595866 -8.314647
v  22.324991 7.595866 -8.825094
v  22.917534 7.595866 -8.825094
v  23.510075 7.595866 -8.825094
v  22.324991 7.347601 -8.825094
v  22.917534 7.347601 -8.825094
v  23.510075 7.347601 -8.825094
v  22.324991 7.347601 -8.314647
v  23.510075 7.347601 -8.314647
v  21.426588 7.347601 -8.314647
v  21.426588 7.347601 -5.840997
v  21.426588 7.595866 -5.840997
v  21.426588 7.595866 -8.314647
v  22.800600 7.347601 -7.008631
v  23.225946 7.347601 -7.008631
v  23.651293 7.347601 -7.008631
v  22.800600 7.621565 -7.008631
v  23.225946 7.621565 -7.008631
v  23.651293 7.621565 -7.008631
v  22.800600 7.621565 -8.421206
v  23.225946 7.621565 -8.421206
v  23.651293 7.621565 -8.421206
v  22.800600 7.621565 -8.791735
v  23.225946 7.621565 -8.791735
v  23.651293 7.621565 -8.791735
v  22.800600 7.347601 -8.791735
v  23.225946 7.347601 -8.791735
v  23.651293 7.347601 -8.791735
v  22.800600 7.347601 -8.421206
v  23.651293 7.347601 -8.421206
v  21.584225 7.347601 -8.421206
v  21.584225 7.347601 -7.008631
v  21.584225 7.621565 -7.008631
v  21.584225 7.621565 -8.421206
v  -21.072454 0.000000 -1.473078
v  -23.693216 0.000000 -1.473078
v  -23.693216 0.000000 1.480642
v  -21.072454 0.000000 1.480642
v  -21.072454 7.142211 -1.473078
v  -23.693216 7.142211 -1.473078
v  -23.693216 7.142211 1.480642
v  -21.072454 7.142211 1.480642
v  -21.137972 7.142211 -1.399235
v  -23.627699 7.142211 -1.399235
v  -23.627699 7.142211 1.406799
v  -21.137972 7.142211 1.406799
v  -21.137972 7.112211 -1.399235
v  -23.627699 7.112211 -1.399235
v  -23.627699 7.112211 1.406799
v  -21.137972 7.112211 1.406799
v  -22.296642 7.112211 -1.399235
v  -22.296642 7.112211 1.406799
v  -21.137972 7.373915 -1.399235
v  -22.296642 7.373915 -1.399235
v  -22.296642 7.373915 1.406799
v  -21.137972 7.373915 1.406799
v  -9.286870 0.000000 -14.540013
v  -12.222744 0.000000 -14.540013
v  -12.222744 0.000000 -11.187840
v  -9.286870 0.000000 -11.187840
v  -9.286870 1.380998 -14.540013
v  -12.222744 1.380998 -14.540013
v  -12.222744 1.380998 -11.187840
v  -9.286870 1.380998 -11.187840
v  -9.360267 1.380998 -14.456208
v  -12.149348 1.380998 -14.456208
v  -12.149348 1.380998 -11.271646
v  -9.360267 1.380998 -11.271646
v  -9.360267 1.350998 -14.456208
v  -12.149348 1.350998 -14.456208
v  -12.149348 1.350998 -11.271646
v  -9.360267 1.350998 -11.271646
v  -9.240397 0.256032 -14.593076
v  -12.269217 0.256032 -14.593076
v  -12.269217 0.256032 -11.134778
v  -9.240397 0.256032 -11.134778
v  -9.240397 0.428657 -14.593076
v  -12.269217 0.428657 -14.593076
v  -12.269217 0.428657 -11.134778
v  -9.240397 0.428657 -11.134778
v  -9.240397 0.428657 -14.593076
v  -12.269217 0.428657 -14.593076
v  -12.269217 0.428657 -11.134778
v  -9.240397 0.428657 -11.134778
v  -9.240397 0.256032 -14.593076
v  -9.240397 0.256032 -11.134778
v  -12.269217 0.256032 -11.134778
v  -12.269217 0.256032 -14.593076
v  -9.240397 0.946531 -14.593076
v  -12.269217 0.946531 -14.593076
v  -12.269217 0.946531 -11.134778
v  -9.240397 0.946531 -11.134778
v  -9.240397 0.773906 -14.593076
v  -9.240397 0.773906 -11.134778
v  -12.269217 0.773906 -11.134778
v  -12.269217 0.773906 -14.593076
v  -10.487720 1.350998 -11.863250
v  -9.944851 1.350998 -11.863250
v  -9.401979 1.350998 -11.863250
v  -10.487720 1.557224 -11.863250
v  -9.944851 1.557224 -11.863250
v  -9.401979 1.557224 -11.863250
v  -10.487720 1.557224 -13.216167
v  -9.944851 1.557224 -13.216167
v  -9.401979 1.557224 -13.216167
v  -10.487720 1.557224 -13.685553
v  -9.944851 1.557224 -13.685553
v  -9.401979 1.557224 -13.685553
v  -10.487720 1.350998 -13.685553
v  -9.944851 1.350998 -13.685553
v  -9.401979 1.350998 -13.685553
v  -10.487720 1.350998 -13.216167
v  -9.401979 1.350998 -13.216167
v  -11.924845 1.350998 -13.216167
v  -11.924845 1.350998 -11.863250
v  -11.924845 1.557224 -11.863250
v  -11.924845 1.557224 -13.216167
v  -6.434392 0.000000 -14.073665
v  -9.240397 0.000000 -14.073665
v  -9.240397 0.000000 -11.134778
v  -6.434392 0.000000 -11.134778
v  -6.434392 2.335301 -14.073665
v  -9.240397 2.335301 -14.073665
v  -9.240397 2.335301 -11.134778
v  -6.434392 2.335301 -11.134778
v  -6.504542 2.335301 -14.000192
v  -9.170247 2.335301 -14.000192
v  -9.170247 2.335301 -11.208251
v  -6.504542 2.335301 -11.208251
v  -6.504542 2.305301 -14.000192
v  -9.170247 2.305301 -14.000192
v  -9.170247 2.305301 -11.208251
v  -6.504542 2.305301 -11.208251
v  -7.618752 2.305301 -12.238182
v  -7.167377 2.305301 -12.238182
v  -6.716000 2.305301 -12.238182
v  -7.618752 2.591084 -12.238182
v  -7.167377 2.591084 -12.238182
v  -6.716000 2.591084 -12.238182
v  -7.618752 2.591084 -13.621386
v  -7.167377 2.591084 -13.621386
v  -6.716000 2.591084 -13.621386
v  -7.618752 2.591084 -13.901233
v  -7.167377 2.591084 -13.901233
v  -6.716000 2.591084 -13.901233
v  -7.618752 2.305301 -13.901233
v  -7.167377 2.305301 -13.901233
v  -6.716000 2.305301 -13.901233
v  -7.618752 2.305301 -13.621386
v  -6.716000 2.305301 -13.621386
v  -8.747191 2.305301 -13.621386
v  -8.747191 2.305301 -12.238182
v  -8.747191 2.591084 -12.238182
v  -8.747191 2.591084 -13.621386
v  -9.026020 2.305301 -11.630349
v  -6.673181 2.305301 -11.630349
v  -9.026020 2.574752 -11.630349
v  -6.673181 2.574752 -11.630349
v  -9.026020 2.574752 -13.499528
v  -6.673181 2.574752 -13.499528
v  -9.026020 2.305301 -13.499528
v  -6.673181 2.305301 -13.499528
v  22.993387 0.000000 -15.000000
v  20.000000 0.000000 -15.000000
v  20.000000 0.000000 -12.212910
v  22.993387 0.000000 -12.212910
v  22.993387 4.368376 -15.000000
v  20.000000 4.368376 -15.000000
v  20.000000 4.368376 -12.212910
v  22.993387 4.368376 -12.212910
v  22.918554 4.368376 -14.930323
v  20.074837 4.368376 -14.930323
v  20.074837 4.368376 -12.282587
v  22.918554 4.368376 -12.282587
v  22.918554 4.338376 -14.930323
v  20.074837 4.338376 -14.930323
v  20.074837 4.338376 -12.282587
v  22.918554 4.338376 -12.282587
v  21.912621 4.338376 -12.629841
v  22.320398 4.338376 -12.629841
v  22.728178 4.338376 -12.629841
v  21.912621 4.544234 -12.629841
v  22.320398 4.544234 -12.629841
v  22.728178 4.544234 -12.629841
v  21.912621 4.544234 -12.996686
v  22.320398 4.544234 -12.996686
v  22.728178 4.544234 -12.996686
v  21.912621 4.544234 -13.266104
v  22.320398 4.544234 -13.266104
v  22.728178 4.544234 -13.266104
v  21.912621 4.338376 -13.266104
v  22.320398 4.338376 -13.266104
v  22.728178 4.338376 -13.266104
v  21.912621 4.338376 -12.996686
v  22.728178 4.338376 -12.996686
v  20.349659 4.338376 -12.996686
v  20.349659 4.338376 -12.629841
v  20.349659 4.544234 -12.629841
v  20.349659 4.544234 -12.996686
v  -20.799871 0.000000 -18.823820
v  -24.516794 0.000000 -18.823820
v  -24.516794 0.000000 -16.176180
v  -20.799871 0.000000 -16.176180
v  -20.799871 4.323427 -18.823820
v  -24.516794 4.323427 -18.823820
v  -24.516794 4.323427 -16.176180
v  -20.799871 4.323427 -16.176180
v  -20.892794 4.323427 -18.757627
v  -24.423872 4.323427 -18.757627
v  -24.423872 4.323427 -16.242373
v  -20.892794 4.323427 -16.242373
v  -20.892794 4.293427 -18.757627
v  -24.423872 4.293427 -18.757627
v  -24.423872 4.293427 -16.242373
v  -20.892794 4.293427 -16.242373
v  -24.423872 4.293427 -17.357307
v  -20.892794 4.293427 -17.357307
v  -20.892794 4.758795 -18.757627
v  -24.423872 4.758795 -18.757627
v  -24.423872 4.758795 -17.357307
v  -20.892794 4.758795 -17.357307
v  -24.563318 0.000000 -16.109989
v  -24.470270 0.000000 -16.109989
v  -24.563318 4.284224 -16.109989
v  -24.470270 4.284224 -16.109989
v  -24.563318 4.284224 -18.890011
v  -24.470270 4.284224 -18.890011
v  -24.563318 0.000000 -18.890011
v  -24.470270 0.000000 -18.890011
v  -24.032330 0.000000 -16.109989
v  -23.939281 0.000000 -16.109989
v  -23.939281 4.284224 -16.109989
v  -24.032330 4.284224 -16.109989
v  -23.939281 4.284224 -18.890011
v  -24.032330 4.284224 -18.890011
v  -23.939281 0.000000 -18.890011
v  -24.032330 0.000000 -18.890011
v  -23.501339 0.000000 -16.109989
v  -23.408293 0.000000 -16.109989
v  -23.408293 4.284224 -16.109989
v  -23.501339 4.284224 -16.109989
v  -23.408293 4.284224 -18.890011
v  -23.501339 4.284224 -18.890011
v  -23.408293 0.000000 -18.890011
v  -23.501339 0.000000 -18.890011
v  -22.970350 0.000000 -16.109989
v  -22.877304 0.000000 -16.109989
v  -22.877304 4.284224 -16.109989
v  -22.970350 4.284224 -16.109989
v  -22.877304 4.284224 -18.890011
v  -22.970350 4.284224 -18.890011
v  -22.877304 0.000000 -18.890011
v  -22.970350 0.000000 -18.890011
v  -22.439362 0.000000 -16.109989
v  -22.346315 0.000000 -16.109989
v  -22.346315 4.284224 -16.109989
v  -22.439362 4.284224 -16.109989
v  -22.346315 4.284224 -18.890011
v  -22.439362 4.284224 -18.890011
v  -22.346315 0.000000 -18.890011
v  -22.439362 0.000000 -18.890011
v  -21.908373 0.000000 -16.109989
v  -21.815327 0.000000 -16.109989
v  -21.815327 4.284224 -16.109989
v  -21.908373 4.284224 -16.109989
v  -21.815327 4.284224 -18.890011
v  -21.908373 4.284224 -18.890011
v  -21.815327 0.000000 -18.890011
v  -21.908373 0.000000 -18.890011
v  -21.377384 0.000000 -16.109989
v  -21.284338 0.000000 -16.109989
v  -21.284338 4.284224 -16.109989
v  -21.377384 4.284224 -16.109989
v  -21.284338 4.284224 -18.890011
v  -21.377384 4.284224 -18.890011
v  -21.284338 0.000000 -18.890011
v  -21.377384 0.000000 -18.890011
v  -20.846395 0.000000 -16.109989
v  -20.753349 0.000000 -16.109989
v  -20.753349 4.284224 -16.109989
v  -20.846395 4.284224 -16.109989
v  -20.753349 4.284224 -18.890011
v  -20.846395 4.284224 -18.890011
v  -20.753349 0.000000 -18.890011
v  -20.846395 0.000000 -18.890011
v  -22.537855 4.293427 -16.600517
v  -22.174574 4.293427 -16.600517
v  -21.811296 4.293427 -16.600517
v  -22.537855 4.511245 -16.600517
v  -22.174574 4.511245 -16.600517
v  -21.811296 4.511245 -16.600517
v  -22.537855 4.511245 -17.827642
v  -22.174574 4.511245 -17.827642
v  -21.811296 4.511245 -17.827642
v  -22.537855 4.511245 -18.076454
v  -22.174574 4.511245 -18.076454
v  -21.811296 4.511245 -18.076454
v  -22.537855 4.293427 -18.076454
v  -22.174574 4.293427 -18.076454
v  -21.811296 4.293427 -18.076454
v  -22.537855 4.293427 -17.827642
v  -21.811296 4.293427 -17.827642
v  -23.894218 4.293427 -17.827642
v  -23.894218 4.293427 -16.600517
v  -23.894218 4.511245 -16.600517
v  -23.894218 4.511245 -17.827642
v  -11.019149 0.000000 -19.389698
v  -14.267083 0.000000 -19.389698
v  -14.267083 0.000000 -15.610302
v  -11.019149 0.000000 -15.610302
v  -11.531058 1.195119 -18.794025
v  -13.755174 1.195119 -18.794025
v  -13.755174 1.195119 -16.205976
v  -11.531058 1.195119 -16.205976
v  -11.586661 1.195119 -18.729324
v  -13.699571 1.195119 -18.729324
v  -13.699571 1.195119 -16.270678
v  -11.586661 1.195119 -16.270678
v  -11.586661 1.165119 -18.729324
v  -13.699571 1.165119 -18.729324
v  -13.699571 1.165119 -16.270678
v  -11.586661 1.165119 -16.270678
v  -12.478852 1.165119 -17.633163
v  -12.155133 1.165119 -17.633163
v  -11.831413 1.165119 -17.633163
v  -12.478852 1.400538 -17.633163
v  -12.155133 1.400538 -17.633163
v  -11.831413 1.400538 -17.633163
v  -12.478852 1.400538 -18.198093
v  -12.155133 1.400538 -18.198093
v  -11.831413 1.400538 -18.198093
v  -12.478852 1.400538 -18.414486
v  -12.155133 1.400538 -18.414486
v  -11.831413 1.400538 -18.414486
v  -12.478852 1.165119 -18.414486
v  -12.155133 1.165119 -18.414486
v  -11.831413 1.165119 -18.414486
v  -12.478852 1.165119 -18.198093
v  -11.831413 1.165119 -18.198093
v  -13.358539 1.165119 -18.198093
v  -13.358539 1.165119 -17.633163
v  -13.358539 1.400538 -17.633163
v  -13.358539 1.400538 -18.198093
v  -6.692071 0.000000 -19.370861
v  -9.314724 0.000000 -19.370861
v  -9.314724 0.000000 -15.629139
v  -6.692071 0.000000 -15.629139
v  -6.692071 9.919143 -19.370861
v  -9.314724 9.919143 -19.370861
v  -9.314724 9.919143 -15.629139
v  -6.692071 9.919143 -15.629139
v  -6.757638 9.919143 -19.277317
v  -9.249157 9.919143 -19.277317
v  -9.249157 9.919143 -15.722683
v  -6.757638 9.919143 -15.722683
v  -6.757638 9.889142 -19.277317
v  -9.249157 9.889142 -19.277317
v  -9.249157 9.889142 -15.722683
v  -6.757638 9.889142 -15.722683
v  10.000000 0.000000 -14.752470
v  6.596092 0.000000 -14.752470
v  6.596092 0.000000 -11.636759
v  10.000000 0.000000 -11.636759
v  10.000000 3.090473 -14.752470
v  6.596092 3.090473 -14.752470
v  6.596092 3.090473 -11.636759
v  10.000000 3.090473 -11.636759
v  9.914902 3.090473 -14.674578
v  6.681190 3.090473 -14.674578
v  6.681190 3.090473 -11.714651
v  9.914902 3.090473 -11.714651
v  9.914902 3.060473 -14.674578
v  6.681190 3.060473 -14.674578
v  6.681190 3.060473 -11.714651
v  9.914902 3.060473 -11.714651
v  12.941968 0.000000 -14.752470
v  10.000000 0.000000 -14.752470
v  10.000000 0.000000 -11.256250
v  12.941968 0.000000 -11.256250
v  12.501784 7.401172 -14.229361
v  10.440184 7.401172 -14.229361
v  10.440184 7.401172 -11.779364
v  12.501784 7.401172 -11.779364
v  12.450244 7.401172 -14.168112
v  10.491724 7.401172 -14.168112
v  10.491724 7.401172 -11.840612
v  12.450244 7.401172 -11.840612
v  12.450244 7.371172 -14.168112
v  10.491724 7.371172 -14.168112
v  10.491724 7.371172 -11.840612
v  12.450244 7.371172 -11.840612
v  12.061101 7.371172 -13.705656
v  10.880866 7.371172 -13.705656
v  10.880866 7.371172 -12.303068
v  12.061101 7.371172 -12.303068
v  12.061101 8.584740 -13.705656
v  10.880866 8.584740 -13.705656
v  10.880866 8.584740 -12.303068
v  12.061101 8.584740 -12.303068
v  12.031594 8.584740 -13.670591
v  10.910373 8.584740 -13.670591
v  10.910373 8.584740 -12.338133
v  12.031594 8.584740 -12.338133
v  12.031594 8.554739 -13.670591
v  10.910373 8.554739 -13.670591
v  10.910373 8.554739 -12.338133
v  12.031594 8.554739 -12.338133
v  11.808816 8.554739 -13.405844
v  11.133151 8.554739 -13.405844
v  11.133151 8.554739 -12.602880
v  11.808816 8.554739 -12.602880
v  11.808816 9.525593 -13.405844
v  11.133151 9.525593 -13.405844
v  11.133151 9.525593 -12.602880
v  11.808816 9.525593 -12.602880
v  11.791924 9.525593 -13.385771
v  11.150043 9.525593 -13.385771
v  11.150043 9.525593 -12.622953
v  11.791924 9.525593 -12.622953
v  11.791924 9.495592 -13.385771
v  11.150043 9.495592 -13.385771
v  11.150043 9.495592 -12.622953
v  11.791924 9.495592 -12.622953
v  18.296310 0.000000 -19.928234
v  15.054683 0.000000 -19.928234
v  15.054683 0.000000 -17.057617
v  18.296310 0.000000 -17.057617
v  18.296310 8.208327 -19.928234
v  15.054683 8.208327 -19.928234
v  15.054683 8.208327 -17.057617
v  18.296310 8.208327 -17.057617
v  18.215271 8.208327 -19.856468
v  15.135724 8.208327 -19.856468
v  15.135724 8.208327 -17.129383
v  18.215271 8.208327 -17.129383
v  18.215271 8.178327 -19.856468
v  15.135724 8.178327 -19.856468
v  15.135724 8.178327 -17.129383
v  18.215271 8.178327 -17.129383
v  17.859089 8.178327 -19.541054
v  15.491906 8.178327 -19.541054
v  15.491906 8.178327 -17.444798
v  17.859089 8.178327 -17.444798
v  17.859089 9.813498 -19.541054
v  15.491906 9.813498 -19.541054
v  15.491906 9.813498 -17.444798
v  17.859089 9.813498 -17.444798
v  17.799908 9.813498 -19.488647
v  15.551087 9.813498 -19.488647
v  15.551087 9.813498 -17.497204
v  17.799908 9.813498 -17.497204
v  17.799908 9.783498 -19.488647
v  15.551087 9.783498 -19.488647
v  15.551087 9.783498 -17.497204
v  17.799908 9.783498 -17.497204
v  15.000000 0.000000 -16.985851
v  15.109365 0.000000 -16.985851
v  15.000000 7.984897 -16.985851
v  15.109365 7.984897 -16.985851
v  15.000000 7.984897 -20.000000
v  15.109365 7.984897 -20.000000
v  15.000000 0.000000 -20.000000
v  15.109365 0.000000 -20.000000
v  15.648326 0.000000 -16.985851
v  15.757691 0.000000 -16.985851
v  15.757691 7.984897 -16.985851
v  15.648326 7.984897 -16.985851
v  15.757691 7.984897 -20.000000
v  15.648326 7.984897 -20.000000
v  15.757691 0.000000 -20.000000
v  15.648326 0.000000 -20.000000
v  16.296650 0.000000 -16.985851
v  16.406017 0.000000 -16.985851
v  16.406017 7.984897 -16.985851
v  16.296650 7.984897 -16.985851
v  16.406017 7.984897 -20.000000
v  16.296650 7.984897 -20.000000
v  16.406017 0.000000 -20.000000
v  16.296650 0.000000 -20.000000
v  16.944977 0.000000 -16.985851
v  17.054340 0.000000 -16.985851
v  17.054340 7.984897 -16.985851
v  16.944977 7.984897 -16.985851
v  17.054340 7.984897 -20.000000
v  16.944977 7.984897 -20.000000
v  17.054340 0.000000 -20.000000
v  16.944977 0.000000 -20.000000
v  17.593300 0.000000 -16.985851
v  17.702667 0.000000 -16.985851
v  17.702667 7.984897 -16.985851
v  17.593300 7.984897 -16.985851
v  17.702667 7.984897 -20.000000
v  17.593300 7.984897 -20.000000
v  17.702667 0.000000 -20.000000
v  17.593300 0.000000 -20.000000
v  18.241627 0.000000 -16.985851
v  18.350994 0.000000 -16.985851
v  18.350994 7.984897 -16.985851
v  18.241627 7.984897 -16.985851
v  18.350994 7.984897 -20.000000
v  18.241627 7.984897 -20.000000
v  18.350994 0.000000 -20.000000
v  18.241627 0.000000 -20.000000
v  23.735727 0.000000 -17.735016
v  20.000000 0.000000 -17.735016
v  20.000000 0.000000 -15.000000
v  23.735727 0.000000 -15.000000
v  23.248156 4.844468 -17.378052
v  20.487572 4.844468 -17.378052
v  20.487572 4.844468 -15.356964
v  23.248156 4.844468 -15.356964
v  23.179140 4.844468 -17.327526
v  20.556587 4.844468 -17.327526
v  20.556587 4.844468 -15.407490
v  23.179140 4.844468 -15.407490
v  23.179140 4.814468 -17.327526
v  20.556587 4.814468 -17.327526
v  20.556587 4.814468 -15.407490
v  23.179140 4.814468 -15.407490
v  -21.155853 0.000000 -24.710770
v  -23.844147 0.000000 -24.710770
v  -23.844147 0.000000 -21.746147
v  -21.155853 0.000000 -21.746147
v  -21.155853 6.289536 -24.710770
v  -23.844147 6.289536 -24.710770
v  -23.844147 6.289536 -21.746147
v  -21.155853 6.289536 -21.746147
v  -21.223061 6.289536 -24.636654
v  -23.776939 6.289536 -24.636654
v  -23.776939 6.289536 -21.820263
v  -21.223061 6.289536 -21.820263
v  -21.223061 6.259536 -24.636654
v  -23.776939 6.259536 -24.636654
v  -23.776939 6.259536 -21.820263
v  -21.223061 6.259536 -21.820263
v  -23.619345 6.259536 -22.712984
v  -22.260050 6.259536 -22.712984
v  -23.619345 6.546646 -22.712984
v  -22.260050 6.546646 -22.712984
v  -23.619345 6.546646 -23.985708
v  -22.260050 6.546646 -23.985708
v  -23.619345 6.259536 -23.985708
v  -22.260050 6.259536 -23.985708
v  -16.084015 0.000000 -23.585991
v  -18.915985 0.000000 -23.585991
v  -18.915985 0.000000 -20.475639
v  -16.084015 0.000000 -20.475639
v  -16.084015 5.291362 -23.585991
v  -18.915985 5.291362 -23.585991
v  -18.915985 5.291362 -20.475639
v  -16.084015 5.291362 -20.475639
v  -16.154816 5.291362 -23.508232
v  -18.845184 5.291362 -23.508232
v  -18.845184 5.291362 -20.553398
v  -16.154816 5.291362 -20.553398
v  -16.154816 5.261363 -23.508232
v  -18.845184 5.261363 -23.508232
v  -18.845184 5.261363 -20.553398
v  -16.154816 5.261363 -20.553398
v  -18.845184 5.261363 -22.559200
v  -16.154816 5.261363 -22.559200
v  -16.154816 5.558596 -23.508232
v  -18.845184 5.558596 -23.508232
v  -18.845184 5.558596 -22.559200
v  -16.154816 5.558596 -22.559200
v  -18.340382 5.261363 -21.327457
v  -16.883152 5.261363 -21.327457
v  -18.340382 5.489334 -21.327457
v  -16.883152 5.489334 -21.327457
v  -18.340382 5.489334 -22.434467
v  -16.883152 5.489334 -22.434467
v  -18.340382 5.261363 -22.434467
v  -16.883152 5.261363 -22.434467
v  -18.231586 5.558596 -22.612471
v  -17.146309 5.558596 -22.612471
v  -18.231586 5.760469 -22.612471
v  -17.146309 5.760469 -22.612471
v  -18.231586 5.760469 -23.507460
v  -17.146309 5.760469 -23.507460
v  -18.231586 5.558596 -23.507460
v  -17.146309 5.558596 -23.507460
v  -10.000000 0.000000 -25.000000
v  -12.763769 0.000000 -25.000000
v  -12.763769 0.000000 -21.485371
v  -10.000000 0.000000 -21.485371
v  -10.000000 1.306169 -25.000000
v  -12.763769 1.306169 -25.000000
v  -12.763769 1.306169 -21.485371
v  -10.000000 1.306169 -21.485371
v  -10.069094 1.306169 -24.912136
v  -12.694675 1.306169 -24.912136
v  -12.694675 1.306169 -21.573236
v  -10.069094 1.306169 -21.573236
v  -10.069094 1.276169 -24.912136
v  -12.694675 1.276169 -24.912136
v  -12.694675 1.276169 -21.573236
v  -10.069094 1.276169 -21.573236
v  -11.202162 1.276169 -22.034693
v  -10.720708 1.276169 -22.034693
v  -10.239254 1.276169 -22.034693
v  -11.202162 1.499268 -22.034693
v  -10.720708 1.499268 -22.034693
v  -10.239254 1.499268 -22.034693
v  -11.202162 1.499268 -22.513870
v  -10.720708 1.499268 -22.513870
v  -10.239254 1.499268 -22.513870
v  -11.202162 1.499268 -22.915051
v  -10.720708 1.499268 -22.915051
v  -10.239254 1.499268 -22.915051
v  -11.202162 1.276169 -22.915051
v  -10.720708 1.276169 -22.915051
v  -10.239254 1.276169 -22.915051
v  -11.202162 1.276169 -22.513870
v  -10.239254 1.276169 -22.513870
v  -12.513984 1.276169 -22.513870
v  -12.513984 1.276169 -22.034693
v  -12.513984 1.499268 -22.034693
v  -12.513984 1.499268 -22.513870
v  -6.193997 0.000000 -24.932615
v  -9.871566 0.000000 -24.932615
v  -9.871566 0.000000 -22.237238
v  -6.193997 0.000000 -22.237238
v  -6.193997 10.711503 -24.932615
v  -9.871566 10.711503 -24.932615
v  -9.871566 10.711503 -22.237238
v  -6.193997 10.711503 -22.237238
v  -6.285936 10.711503 -24.865232
v  -9.779626 10.711503 -24.865232
v  -9.779626 10.711503 -22.304621
v  -6.285936 10.711503 -22.304621
v  -6.285936 10.681502 -24.865232
v  -9.779626 10.681502 -24.865232
v  -9.779626 10.681502 -22.304621
v  -6.285936 10.681502 -22.304621
v  -6.683883 10.681502 -24.573568
v  -9.381680 10.681502 -24.573568
v  -9.381680 10.681502 -22.596285
v  -6.683883 10.681502 -22.596285
v  -6.683883 12.796733 -24.573568
v  -9.381680 12.796733 -24.573568
v  -9.381680 12.796733 -22.596285
v  -6.683883 12.796733 -22.596285
v  -6.751328 12.796733 -24.524137
v  -9.314235 12.796733 -24.524137
v  -9.314235 12.796733 -22.645716
v  -6.751328 12.796733 -22.645716
v  -6.751328 12.766732 -24.524137
v  -9.314235 12.766732 -24.524137
v  -9.314235 12.766732 -22.645716
v  -6.751328 12.766732 -22.645716
v  -7.043254 12.766732 -24.310177
v  -9.022309 12.766732 -24.310177
v  -9.022309 12.766732 -22.859674
v  -7.043254 12.766732 -22.859674
v  -7.043254 14.458917 -24.310177
v  -9.022309 14.458917 -24.310177
v  -9.022309 14.458917 -22.859674
v  -7.043254 14.458917 -22.859674
v  -7.092731 14.458917 -24.273914
v  -8.972833 14.458917 -24.273914
v  -8.972833 14.458917 -22.895937
v  -7.092731 14.458917 -22.895937
v  -7.092731 14.428916 -24.273914
v  -8.972833 14.428916 -24.273914
v  -8.972833 14.428916 -22.895937
v  -7.092731 14.428916 -22.895937
v  -10.000000 0.000000 -22.169853
v  -9.743132 0.000000 -22.169853
v  -10.000000 10.504098 -22.169853
v  -9.743132 10.504098 -22.169853
v  -10.000000 10.504098 -25.000000
v  -9.743132 10.504098 -25.000000
v  -10.000000 0.000000 -25.000000
v  -9.743132 0.000000 -25.000000
v  -9.264486 0.000000 -22.169853
v  -9.007618 0.000000 -22.169853
v  -9.007618 10.504098 -22.169853
v  -9.264486 10.504098 -22.169853
v  -9.007618 10.504098 -25.000000
v  -9.264486 10.504098 -25.000000
v  -9.007618 0.000000 -25.000000
v  -9.264486 0.000000 -25.000000
v  -8.528973 0.000000 -22.169853
v  -8.272104 0.000000 -22.169853
v  -8.272104 10.504098 -22.169853
v  -8.528973 10.504098 -22.169853
v  -8.272104 10.504098 -25.000000
v  -8.528973 10.504098 -25.000000
v  -8.272104 0.000000 -25.000000
v  -8.528973 0.000000 -25.000000
v  -7.793459 0.000000 -22.169853
v  -7.536591 0.000000 -22.169853
v  -7.536591 10.504098 -22.169853
v  -7.793459 10.504098 -22.169853
v  -7.536591 10.504098 -25.000000
v  -7.793459 10.504098 -25.000000
v  -7.536591 0.000000 -25.000000
v  -7.793459 0.000000 -25.000000
v  -7.057945 0.000000 -22.169853
v  -6.801077 0.000000 -22.169853
v  -6.801077 10.504098 -22.169853
v  -7.057945 10.504098 -22.169853
v  -6.801077 10.504098 -25.000000
v  -7.057945 10.504098 -25.000000
v  -6.801077 0.000000 -25.000000
v  -7.057945 0.000000 -25.000000
v  -6.322432 0.000000 -22.169853
v  -6.065563 0.000000 -22.169853
v  -6.065563 10.504098 -22.169853
v  -6.322432 10.504098 -22.169853
v  -6.065563 10.504098 -25.000000
v  -6.322432 10.504098 -25.000000
v  -6.065563 0.000000 -25.000000
v  -6.322432 0.000000 -25.000000
v  9.951540 0.000000 -18.103193
v  6.001295 0.000000 -18.103193
v  6.001295 0.000000 -14.793077
v  9.951540 0.000000 -14.793077
v  9.951540 9.283607 -18.103193
v  6.001295 9.283607 -18.103193
v  6.001295 9.283607 -14.793077
v  9.951540 9.283607 -14.793077
v  9.852783 9.283607 -18.020441
v  6.100051 9.283607 -18.020441
v  6.100051 9.283607 -14.875830
v  9.852783 9.283607 -14.875830
v  9.852783 9.253607 -18.020441
v  6.100051 9.253607 -18.020441
v  6.100051 9.253607 -14.875830
v  9.852783 9.253607 -14.875830
v  9.363972 9.253607 -17.610842
v  6.588862 9.253607 -17.610842
v  6.588862 9.253607 -15.285429
v  9.363972 9.253607 -15.285429
v  9.363972 12.047810 -17.610842
v  6.588862 12.047810 -17.610842
v  6.588862 12.047810 -15.285429
v  9.363972 12.047810 -15.285429
v  9.294594 12.047810 -17.552706
v  6.658240 12.047810 -17.552706
v  6.658240 12.047810 -15.343565
v  9.294594 12.047810 -15.343565
v  9.294594 12.017809 -17.552706
v  6.658240 12.017809 -17.552706
v  6.658240 12.017809 -15.343565
v  9.294594 12.017809 -15.343565
v  8.951197 12.017809 -17.264956
v  7.001638 12.017809 -17.264956
v  7.001638 12.017809 -15.631315
v  8.951197 12.017809 -15.631315
v  8.951197 14.253170 -17.264956
v  7.001638 14.253170 -17.264956
v  7.001638 14.253170 -15.631315
v  8.951197 14.253170 -15.631315
v  8.902458 14.253170 -17.224115
v  7.050377 14.253170 -17.224115
v  7.050377 14.253170 -15.672155
v  8.902458 14.253170 -15.672155
v  8.902458 14.223169 -17.224115
v  7.050377 14.223169 -17.224115
v  7.050377 14.223169 -15.672155
v  8.902458 14.223169 -15.672155
v  8.069021 14.223169 -16.525734
v  7.883814 14.223169 -16.525734
v  7.883814 14.223169 -16.370539
v  8.069021 14.223169 -16.370539
v  7.985678 17.450596 -16.455894
v  7.967157 17.450596 -16.455894
v  7.967157 17.450596 -16.440376
v  7.985678 17.450596 -16.440376
v  10.000000 1.346796 -18.143801
v  5.952835 1.346796 -18.143801
v  5.952835 1.346796 -14.752470
v  10.000000 1.346796 -14.752470
v  10.000000 1.733613 -18.143801
v  5.952835 1.733613 -18.143801
v  5.952835 1.733613 -14.752470
v  10.000000 1.733613 -14.752470
v  10.000000 1.733613 -18.143801
v  5.952835 1.733613 -18.143801
v  5.952835 1.733613 -14.752470
v  10.000000 1.733613 -14.752470
v  10.000000 1.346796 -18.143801
v  10.000000 1.346796 -14.752470
v  5.952835 1.346796 -14.752470
v  5.952835 1.346796 -18.143801
v  10.000000 2.894064 -18.143801
v  5.952835 2.894064 -18.143801
v  5.952835 2.894064 -14.752470
v  10.000000 2.894064 -14.752470
v  10.000000 2.507247 -18.143801
v  10.000000 2.507247 -14.752470
v  5.952835 2.507247 -14.752470
v  5.952835 2.507247 -18.143801
v  10.000000 4.054515 -18.143801
v  5.952835 4.054515 -18.143801
v  5.952835 4.054515 -14.752470
v  10.000000 4.054515 -14.752470
v  10.000000 3.667698 -18.143801
v  10.000000 3.667698 -14.752470
v  5.952835 3.667698 -14.752470
v  5.952835 3.667698 -18.143801
v  10.000000 5.214966 -18.143801
v  5.952835 5.214966 -18.143801
v  5.952835 5.214966 -14.752470
v  10.000000 5.214966 -14.752470
v  10.000000 4.828149 -18.143801
v  10.000000 4.828149 -14.752470
v  5.952835 4.828149 -14.752470
v  5.952835 4.828149 -18.143801
v  10.000000 6.375417 -18.143801
v  5.952835 6.375417 -18.143801
v  5.952835 6.375417 -14.752470
v  10.000000 6.375417 -14.752470
v  10.000000 5.988600 -18.143801
v  10.000000 5.988600 -14.752470
v  5.952835 5.988600 -14.752470
v  5.952835 5.988600 -18.143801
v  13.658777 0.000000 -17.276762
v  10.000000 0.000000 -17.276762
v  10.000000 0.000000 -14.752470
v  13.658777 0.000000 -14.752470
v  13.227806 2.927616 -16.979422
v  10.430971 2.927616 -16.979422
v  10.430971 2.927616 -15.049810
v  13.227806 2.927616 -15.049810
v  13.157885 2.927616 -16.931181
v  10.500893 2.927616 -16.931181
v  10.500893 2.927616 -15.098051
v  13.157885 2.927616 -15.098051
v  13.157885 2.897616 -16.931181
v  10.500893 2.897616 -16.931181
v  10.500893 2.897616 -15.098051
v  13.157885 2.897616 -15.098051
v  11.227948 2.897616 -15.483843
v  12.442048 2.897616 -15.483843
v  11.227948 3.097845 -15.483843
v  12.442048 3.097845 -15.483843
v  11.227948 3.097845 -16.558561
v  12.442048 3.097845 -16.558561
v  11.227948 2.897616 -16.558561
v  12.442048 2.897616 -16.558561
v  17.956476 0.000000 -23.112106
v  15.000000 0.000000 -23.112106
v  15.000000 0.000000 -20.000000
v  17.956476 0.000000 -20.000000
v  17.521040 7.061238 -22.653751
v  15.435434 7.061238 -22.653751
v  15.435434 7.061238 -20.458355
v  17.521040 7.061238 -20.458355
v  17.468901 7.061238 -22.598866
v  15.487574 7.061238 -22.598866
v  15.487574 7.061238 -20.513241
v  17.468901 7.061238 -20.513241
v  17.468901 7.031239 -22.598866
v  15.487574 7.031239 -22.598866
v  15.487574 7.031239 -20.513241
v  17.468901 7.031239 -20.513241
v  15.494809 7.031239 -20.718838
v  17.427284 7.031239 -20.718838
v  15.494809 7.255477 -20.718838
v  17.427284 7.255477 -20.718838
v  15.494809 7.255477 -22.024254
v  17.427284 7.255477 -22.024254
v  15.494809 7.031239 -22.024254
v  17.427284 7.031239 -22.024254
v  25.000000 0.000000 -25.000000
v  21.538754 0.000000 -25.000000
v  21.538754 0.000000 -22.229572
v  25.000000 0.000000 -22.229572
v  25.000000 1.995893 -25.000000
v  21.538754 1.995893 -25.000000
v  21.538754 1.995893 -22.229572
v  25.000000 1.995893 -22.229572
v  24.913467 1.995893 -24.930740
v  21.625286 1.995893 -24.930740
v  21.625286 1.995893 -22.298832
v  24.913467 1.995893 -22.298832
v  24.913467 1.965894 -24.930740
v  21.625286 1.965894 -24.930740
v  21.625286 1.965894 -22.298832
v  24.913467 1.965894 -22.298832
v  22.195391 1.965894 -22.769228
v  24.472734 1.965894 -22.769228
v  22.195391 2.220600 -22.769228
v  24.472734 2.220600 -22.769228
v  22.195391 2.220600 -24.296560
v  24.472734 2.220600 -24.296560
v  22.195391 1.965894 -24.296560
v  24.472734 1.965894 -24.296560
v  22.252325 2.220600 -22.807411
v  24.415800 2.220600 -22.807411
v  24.415800 2.220600 -24.258377
v  22.252325 2.220600 -24.258377
v  22.252325 2.216779 -22.807411
v  24.415800 2.216779 -22.807411
v  24.415800 2.216779 -24.258377
v  22.252325 2.216779 -24.258377
v  -20.825497 4.758795 21.774420
v  -24.356575 4.758795 21.774420
v  -24.356575 4.758795 23.174744
v  -20.825497 4.758795 23.174744
v  -20.732574 0.000000 21.708227
v  -20.732574 0.000000 24.355871
v  -24.449497 0.000000 24.355871
v  -24.449497 0.000000 21.708227
v  -24.449497 4.323427 21.708227
v  -20.732574 4.323427 21.708227
v  -24.449497 4.323427 24.355871
v  -20.732574 4.323427 24.355871
v  -24.356575 4.323427 21.774420
v  -20.825497 4.323427 21.774420
v  -24.356575 4.323427 24.289679
v  -20.825497 4.323427 24.289679
v  -24.356575 4.293427 21.774420
v  -20.825497 4.293427 21.774420
v  -24.356575 4.293427 24.289679
v  -24.356575 4.293427 23.174744
v  -20.825497 4.293427 24.289679
v  -20.825497 4.293427 23.174744
v  -24.496021 0.000000 24.422062
v  -24.402973 0.000000 24.422062
v  -24.402973 4.284224 24.422062
v  -24.496021 4.284224 24.422062
v  -24.402973 4.284224 21.642040
v  -24.496021 4.284224 21.642040
v  -24.402973 0.000000 21.642040
v  -24.496021 0.000000 21.642040
v  -23.965033 0.000000 24.422062
v  -23.871984 0.000000 24.422062
v  -23.871984 4.284224 24.422062
v  -23.965033 4.284224 24.422062
v  -23.871984 4.284224 21.642040
v  -23.965033 4.284224 21.642040
v  -23.871984 0.000000 21.642040
v  -23.965033 0.000000 21.642040
v  -23.434042 0.000000 24.422062
v  -23.340996 0.000000 24.422062
v  -23.340996 4.284224 24.422062
v  -23.434042 4.284224 24.422062
v  -23.340996 4.284224 21.642040
v  -23.434042 4.284224 21.642040
v  -23.340996 0.000000 21.642040
v  -23.434042 0.000000 21.642040
v  -22.903053 0.000000 24.422062
v  -22.810007 0.000000 24.422062
v  -22.810007 4.284224 24.422062
v  -22.903053 4.284224 24.422062
v  -22.810007 4.284224 21.642040
v  -22.903053 4.284224 21.642040
v  -22.810007 0.000000 21.642040
v  -22.903053 0.000000 21.642040
v  -22.372065 0.000000 24.422062
v  -22.279018 0.000000 24.422062
v  -22.279018 4.284224 24.422062
v  -22.372065 4.284224 24.422062
v  -22.279018 4.284224 21.642040
v  -22.372065 4.284224 21.642040
v  -22.279018 0.000000 21.642040
v  -22.372065 0.000000 21.642040
v  -21.841076 0.000000 24.422062
v  -21.748030 0.000000 24.422062
v  -21.748030 4.284224 24.422062
v  -21.841076 4.284224 24.422062
v  -21.748030 4.284224 21.642040
v  -21.841076 4.284224 21.642040
v  -21.748030 0.000000 21.642040
v  -21.841076 0.000000 21.642040
v  -21.310087 0.000000 24.422062
v  -21.217041 0.000000 24.422062
v  -21.217041 4.284224 24.422062
v  -21.310087 4.284224 24.422062
v  -21.217041 4.284224 21.642040
v  -21.310087 4.284224 21.642040
v  -21.217041 0.000000 21.642040
v  -21.310087 0.000000 21.642040
v  -20.779099 0.000000 24.422062
v  -20.686052 0.000000 24.422062
v  -20.686052 4.284224 24.422062
v  -20.779099 4.284224 24.422062
v  -20.686052 4.284224 21.642040
v  -20.779099 4.284224 21.642040
v  -20.686052 0.000000 21.642040
v  -20.779099 0.000000 21.642040
v  -22.470558 4.293427 23.931534
v  -22.107277 4.293427 23.931534
v  -22.107277 4.511245 23.931534
v  -22.470558 4.511245 23.931534
v  -21.743999 4.293427 23.931534
v  -21.743999 4.511245 23.931534
v  -22.107277 4.511245 22.704405
v  -22.470558 4.511245 22.704405
v  -21.743999 4.511245 22.704405
v  -22.107277 4.511245 22.455597
v  -22.470558 4.511245 22.455597
v  -21.743999 4.511245 22.455597
v  -22.107277 4.293427 22.455597
v  -22.470558 4.293427 22.455597
v  -21.743999 4.293427 22.455597
v  -21.743999 4.293427 22.704405
v  -22.470558 4.293427 22.704405
v  -23.826921 4.293427 22.704405
v  -23.826921 4.293427 23.931534
v  -23.826921 4.511245 23.931534
v  -23.826921 4.511245 22.704405
v  -11.519364 1.165119 21.802727
v  -13.632274 1.165119 21.802727
v  -13.632274 1.165119 24.261374
v  -11.519364 1.165119 24.261374
v  -10.951852 0.000000 21.142353
v  -10.951852 0.000000 24.921749
v  -14.199786 0.000000 24.921749
v  -14.199786 0.000000 21.142353
v  -13.687877 1.195119 21.738026
v  -11.463761 1.195119 21.738026
v  -13.687877 1.195119 24.326075
v  -11.463761 1.195119 24.326075
v  -13.632274 1.195119 21.802727
v  -11.519364 1.195119 21.802727
v  -13.632274 1.195119 24.261374
v  -11.519364 1.195119 24.261374
v  -12.411555 1.165119 22.898888
v  -12.087836 1.165119 22.898888
v  -12.087836 1.400538 22.898888
v  -12.411555 1.400538 22.898888
v  -11.764116 1.165119 22.898888
v  -11.764116 1.400538 22.898888
v  -12.087836 1.400538 22.333958
v  -12.411555 1.400538 22.333958
v  -11.764116 1.400538 22.333958
v  -12.087836 1.400538 22.117565
v  -12.411555 1.400538 22.117565
v  -11.764116 1.400538 22.117565
v  -12.087836 1.165119 22.117565
v  -12.411555 1.165119 22.117565
v  -11.764116 1.165119 22.117565
v  -11.764116 1.165119 22.333958
v  -12.411555 1.165119 22.333958
v  -13.291242 1.165119 22.333958
v  -13.291242 1.165119 22.898888
v  -13.291242 1.400538 22.898888
v  -13.291242 1.400538 22.333958
v  -21.155764 6.259536 15.895397
v  -23.709642 6.259536 15.895397
v  -23.709642 6.259536 18.711788
v  -21.155764 6.259536 18.711788
v  -21.088556 0.000000 15.821281
v  -21.088556 0.000000 18.785904
v  -23.776850 0.000000 18.785904
v  -23.776850 0.000000 15.821281
v  -23.776850 6.289536 15.821281
v  -21.088556 6.289536 15.821281
v  -23.776850 6.289536 18.785904
v  -21.088556 6.289536 18.785904
v  -23.709642 6.289536 15.895397
v  -21.155764 6.289536 15.895397
v  -23.709642 6.289536 18.711788
v  -21.155764 6.289536 18.711788
v  -23.552048 6.259536 17.819071
v  -22.192753 6.259536 17.819071
v  -22.192753 6.546646 17.819071
v  -23.552048 6.546646 17.819071
v  -22.192753 6.546646 16.546343
v  -23.552048 6.546646 16.546343
v  -22.192753 6.259536 16.546343
v  -23.552048 6.259536 16.546343
v  -16.087519 5.558596 17.023819
v  -18.777887 5.558596 17.023819
v  -18.777887 5.558596 17.972851
v  -16.087519 5.558596 17.972851
v  -16.016718 0.000000 16.946060
v  -16.016718 0.000000 20.056412
v  -18.848688 0.000000 20.056412
v  -18.848688 0.000000 16.946060
v  -18.848688 5.291362 16.946060
v  -16.016718 5.291362 16.946060
v  -18.848688 5.291362 20.056412
v  -16.016718 5.291362 20.056412
v  -18.777887 5.291362 17.023819
v  -16.087519 5.291362 17.023819
v  -18.777887 5.291362 19.978653
v  -16.087519 5.291362 19.978653
v  -18.777887 5.261363 17.023819
v  -16.087519 5.261363 17.023819
v  -18.777887 5.261363 19.978653
v  -18.777887 5.261363 17.972851
v  -16.087519 5.261363 19.978653
v  -16.087519 5.261363 17.972851
v  -18.273085 5.261363 19.204594
v  -16.815855 5.261363 19.204594
v  -16.815855 5.489334 19.204594
v  -18.273085 5.489334 19.204594
v  -16.815855 5.489334 18.097584
v  -18.273085 5.489334 18.097584
v  -16.815855 5.261363 18.097584
v  -18.273085 5.261363 18.097584
v  -18.164289 5.558596 17.919580
v  -17.079012 5.558596 17.919580
v  -17.079012 5.760469 17.919580
v  -18.164289 5.760469 17.919580
v  -17.079012 5.760469 17.024591
v  -18.164289 5.760469 17.024591
v  -17.079012 5.558596 17.024591
v  -18.164289 5.558596 17.024591
v  -10.001797 1.276169 15.619915
v  -12.627378 1.276169 15.619915
v  -12.627378 1.276169 18.958818
v  -10.001797 1.276169 18.958818
v  -9.932703 0.000000 15.532051
v  -9.932703 0.000000 19.046680
v  -12.696472 0.000000 19.046680
v  -12.696472 0.000000 15.532051
v  -12.696472 1.306169 15.532051
v  -9.932703 1.306169 15.532051
v  -12.696472 1.306169 19.046680
v  -9.932703 1.306169 19.046680
v  -12.627378 1.306169 15.619915
v  -10.001797 1.306169 15.619915
v  -12.627378 1.306169 18.958818
v  -10.001797 1.306169 18.958818
v  -11.134865 1.276169 18.497358
v  -10.653411 1.276169 18.497358
v  -10.653411 1.499268 18.497358
v  -11.134865 1.499268 18.497358
v  -10.171957 1.276169 18.497358
v  -10.171957 1.499268 18.497358
v  -10.653411 1.499268 18.018181
v  -11.134865 1.499268 18.018181
v  -10.171957 1.499268 18.018181
v  -10.653411 1.499268 17.617001
v  -11.134865 1.499268 17.617001
v  -10.171957 1.499268 17.617001
v  -10.653411 1.276169 17.617001
v  -11.134865 1.276169 17.617001
v  -10.171957 1.276169 17.617001
v  -10.171957 1.276169 18.018181
v  -11.134865 1.276169 18.018181
v  -12.446687 1.276169 18.018181
v  -12.446687 1.276169 18.497358
v  -12.446687 1.499268 18.497358
v  -12.446687 1.499268 18.018181
v  -6.079453 7.373915 21.123148
v  -7.238123 7.373915 21.123148
v  -7.238123 7.373915 23.929182
v  -6.079453 7.373915 23.929182
v  -6.013935 0.000000 21.049305
v  -6.013935 0.000000 24.003025
v  -8.634697 0.000000 24.003025
v  -8.634697 0.000000 21.049305
v  -8.634697 7.142211 21.049305
v  -6.013935 7.142211 21.049305
v  -8.634697 7.142211 24.003025
v  -6.013935 7.142211 24.003025
v  -8.569180 7.142211 21.123148
v  -6.079453 7.142211 21.123148
v  -8.569180 7.142211 23.929182
v  -6.079453 7.142211 23.929182
v  -8.569180 7.112211 21.123148
v  -7.238123 7.112211 21.123148
v  -6.079453 7.112211 21.123148
v  -8.569180 7.112211 23.929182
v  -6.079453 7.112211 23.929182
v  -7.238123 7.112211 23.929182
v  -20.924257 1.436470 12.192925
v  -23.386171 1.436470 12.192925
v  -23.386171 1.436470 15.348768
v  -20.924257 1.436470 15.348768
v  -20.859468 0.000000 12.109878
v  -20.859468 0.000000 15.431818
v  -23.450960 0.000000 15.431818
v  -23.450960 0.000000 12.109878
v  -23.450960 1.466470 12.109878
v  -20.859468 1.466470 12.109878
v  -23.450960 1.466470 15.431818
v  -20.859468 1.466470 15.431818
v  -23.386171 1.466470 12.192925
v  -20.924257 1.466470 12.192925
v  -23.386171 1.466470 15.348768
v  -20.924257 1.466470 15.348768
v  -22.054939 1.436470 14.740944
v  -21.547461 1.436470 14.740944
v  -21.547461 1.649537 14.740944
v  -22.054939 1.649537 14.740944
v  -21.039980 1.436470 14.740944
v  -21.039980 1.649537 14.740944
v  -21.547461 1.649537 13.895178
v  -22.054939 1.649537 13.895178
v  -21.039980 1.649537 13.895178
v  -21.547461 1.649537 13.609402
v  -22.054939 1.649537 13.609402
v  -21.039980 1.649537 13.609402
v  -21.547461 1.436470 13.609402
v  -22.054939 1.436470 13.609402
v  -21.039980 1.436470 13.609402
v  -21.039980 1.436470 13.895178
v  -22.054939 1.436470 13.895178
v  -23.292433 1.436470 13.895178
v  -23.292433 1.436470 14.740944
v  -23.292433 1.649537 14.740944
v  -23.292433 1.649537 13.895178
v  -16.452894 2.938906 12.247781
v  -18.967554 2.938906 12.247781
v  -18.967554 2.938906 15.293915
v  -16.452894 2.938906 15.293915
v  -16.386721 0.000000 12.167618
v  -16.386721 0.000000 15.374079
v  -19.033728 0.000000 15.374079
v  -19.033728 0.000000 12.167618
v  -19.033728 2.968906 12.167618
v  -16.386721 2.968906 12.167618
v  -19.033728 2.968906 15.374079
v  -16.386721 2.968906 15.374079
v  -18.967554 2.968906 12.247781
v  -16.452894 2.968906 12.247781
v  -18.967554 2.968906 15.293915
v  -16.452894 2.968906 15.293915
v  -17.926941 2.938906 12.955273
v  -17.552837 2.938906 12.955273
v  -17.552837 3.219158 12.955273
v  -17.926941 3.219158 12.955273
v  -17.552837 3.219158 12.748663
v  -17.926941 3.219158 12.748663
v  -17.552837 2.938906 12.748663
v  -17.926941 2.938906 12.748663
v  -18.911234 2.938906 12.955273
v  -18.537128 2.938906 12.955273
v  -18.537128 3.219158 12.955273
v  -18.911234 3.219158 12.955273
v  -18.537128 3.219158 12.748663
v  -18.911234 3.219158 12.748663
v  -18.537128 2.938906 12.748663
v  -18.911234 2.938906 12.748663
v  -21.465361 7.373915 7.367830
v  -22.624031 7.373915 7.367830
v  -22.624031 7.373915 10.173864
v  -21.465361 7.373915 10.173864
v  -21.399843 0.000000 7.293987
v  -21.399843 0.000000 10.247707
v  -24.020605 0.000000 10.247707
v  -24.020605 0.000000 7.293987
v  -24.020605 7.142211 7.293987
v  -21.399843 7.142211 7.293987
v  -24.020605 7.142211 10.247707
v  -21.399843 7.142211 10.247707
v  -23.955088 7.142211 7.367830
v  -21.465361 7.142211 7.367830
v  -23.955088 7.142211 10.173864
v  -21.465361 7.142211 10.173864
v  -23.955088 7.112211 7.367830
v  -22.624031 7.112211 7.367830
v  -21.465361 7.112211 7.367830
v  -23.955088 7.112211 10.173864
v  -21.465361 7.112211 10.173864
v  -22.624031 7.112211 10.173864
v  -3.220312 5.509231 -8.578408
v  -5.040520 5.509231 -8.578408
v  -5.040520 5.509231 -6.124242
v  -3.220312 5.509231 -6.124242
v  -3.149477 0.000000 -8.642991
v  -3.149477 0.000000 -6.059659
v  -5.982821 0.000000 -6.059659
v  -5.982821 0.000000 -8.642991
v  -5.982821 5.109869 -8.642991
v  -3.149477 5.109869 -8.642991
v  -5.982821 5.109869 -6.059659
v  -3.149477 5.109869 -6.059659
v  -5.911985 5.109869 -8.578408
v  -3.220312 5.109869 -8.578408
v  -5.911985 5.109869 -6.124242
v  -3.220312 5.109869 -6.124242
v  -5.911985 5.079870 -8.578408
v  -5.040520 5.079870 -8.578408
v  -3.220312 5.079870 -8.578408
v  -5.911985 5.079870 -6.124242
v  -3.220312 5.079870 -6.124242
v  -5.040520 5.079870 -6.124242
v  -4.037540 5.079870 -7.633350
v  -3.659908 5.079870 -7.633350
v  -3.659908 5.309990 -7.633350
v  -4.037540 5.309990 -7.633350
v  -3.282274 5.079870 -7.633350
v  -3.282274 5.309990 -7.633350
v  -3.659908 5.309990 -7.979219
v  -4.037540 5.309990 -7.979219
v  -3.282274 5.309990 -7.979219
v  -3.659908 5.309990 -8.190278
v  -4.037540 5.309990 -8.190278
v  -3.282274 5.309990 -8.190278
v  -3.659908 5.079870 -8.190278
v  -4.037540 5.079870 -8.190278
v  -3.282274 5.079870 -8.190278
v  -3.282274 5.079870 -7.979219
v  -4.037540 5.079870 -7.979219
v  -5.248123 5.079870 -7.979219
v  -5.248123 5.079870 -7.633350
v  -5.248123 5.309990 -7.633350
v  -5.248123 5.309990 -7.979219
v  -3.758228 5.509231 -7.092682
v  -3.758228 5.509231 -7.378020
v  -3.758228 5.713646 -7.378020
v  -3.758228 5.713646 -7.092682
v  -3.758228 5.509231 -7.663358
v  -3.758228 5.713646 -7.663358
v  -4.500748 5.713646 -7.378020
v  -4.500748 5.713646 -7.092682
v  -4.500748 5.713646 -7.663358
v  -4.666431 5.713646 -7.378020
v  -4.666431 5.713646 -7.092682
v  -4.666431 5.713646 -7.663358
v  -4.666431 5.509231 -7.378020
v  -4.666431 5.509231 -7.092682
v  -4.666431 5.509231 -7.663358
v  -4.500748 5.509231 -7.663358
v  -4.500748 5.509231 -7.092682
v  -4.500748 5.509231 -6.426568
v  -3.758228 5.509231 -6.426568
v  -3.758228 5.713646 -6.426568
v  -4.500748 5.713646 -6.426568
v  5.962915 8.818661 -4.423865
v  3.684834 8.818661 -4.423865
v  3.684834 8.818661 -1.352354
v  5.962915 8.818661 -1.352354
v  6.051569 -0.093493 -4.504694
v  6.051569 -0.093493 -1.271525
v  2.505498 -0.093493 -1.271525
v  2.505498 -0.093493 -4.504694
v  2.505498 8.172623 -4.504694
v  6.051569 8.172623 -4.504694
v  2.505498 8.172623 -1.271525
v  6.051569 8.172623 -1.271525
v  2.594152 8.172623 -4.423865
v  5.962915 8.172623 -4.423865
v  2.594152 8.172623 -1.352354
v  5.962915 8.172623 -1.352354
v  2.594152 8.124094 -4.423865
v  3.684834 8.124094 -4.423865
v  5.962915 8.124094 -4.423865
v  2.594152 8.124094 -1.352354
v  5.962915 8.124094 -1.352354
v  3.684834 8.124094 -1.352354
v  3.726377 7.600931 -3.102484
v  4.199003 7.600931 -3.102484
v  4.199003 7.973190 -3.102484
v  3.726377 7.973190 -3.102484
v  4.671631 7.600931 -3.102484
v  4.671631 7.973190 -3.102484
v  4.199003 7.973190 -3.535356
v  3.726377 7.973190 -3.535356
v  4.671631 7.973190 -3.535356
v  4.199003 7.973190 -3.799507
v  3.726377 7.973190 -3.799507
v  4.671631 7.973190 -3.799507
v  4.199003 7.600931 -3.799507
v  3.726377 7.600931 -3.799507
v  4.671631 7.600931 -3.799507
v  4.671631 7.600931 -3.535356
v  3.726377 7.600931 -3.535356
v  2.211273 7.600931 -3.535356
v  2.211273 7.600931 -3.102484
v  2.211273 7.973190 -3.102484
v  2.211273 7.973190 -3.535356
v  5.226916 8.040213 -2.607051
v  5.226916 8.040213 -2.964166
v  5.226916 8.370890 -2.964166
v  5.226916 8.370890 -2.607051
v  5.226916 8.040213 -3.321280
v  5.226916 8.370890 -3.321280
v  4.297616 8.370890 -2.964166
v  4.297616 8.370890 -2.607051
v  4.297616 8.370890 -3.321280
v  4.090255 8.370890 -2.964166
v  4.090255 8.370890 -2.607051
v  4.090255 8.370890 -3.321280
v  4.090255 8.040213 -2.964166
v  4.090255 8.040213 -2.607051
v  4.090255 8.040213 -3.321280
v  4.297616 8.040213 -3.321280
v  4.297616 8.040213 -2.607051
v  4.297616 8.040213 -1.773376
v  5.226916 8.040213 -1.773376
v  5.226916 8.370890 -1.773376
v  4.297616 8.370890 -1.773376
v  15.153954 1.965894 -0.181097
v  18.442135 1.965894 -0.181097
v  18.442135 1.965894 -2.813005
v  15.153954 1.965894 -2.813005
v  15.067421 0.000000 -0.111837
v  15.067421 0.000000 -2.882265
v  18.528667 0.000000 -2.882265
v  18.528667 0.000000 -0.111837
v  15.067421 1.995893 -2.882265
v  18.528667 1.995893 -2.882265
v  15.067421 1.995893 -0.111837
v  18.528667 1.995893 -0.111837
v  15.153954 1.995893 -2.813005
v  18.442135 1.995893 -2.813005
v  15.153954 1.995893 -0.181097
v  18.442135 1.995893 -0.181097
v  18.001402 2.220600 -0.651489
v  15.724058 2.220600 -0.651489
v  15.724058 1.965894 -0.651489
v  18.001402 1.965894 -0.651489
v  17.944468 2.216779 -2.140638
v  15.780993 2.216779 -2.140638
v  15.780993 2.216779 -0.689672
v  17.944468 2.216779 -0.689672
v  18.001402 1.965894 -2.178822
v  15.724058 1.965894 -2.178822
v  15.724058 2.220600 -2.178822
v  18.001402 2.220600 -2.178822
v  17.944468 2.220600 -0.689672
v  15.780993 2.220600 -0.689672
v  17.944468 2.220600 -2.140638
v  15.780993 2.220600 -2.140638
# 2470 vertices

o Sehir_geometri
g Sehir_geometri
usemtl 11___Defadwdlt
s off
f 15 16 13 14 
f 3 2 1 4 
f 6 5 1 2 
f 7 6 2 3 
f 8 7 3 4 
f 5 8 4 1 
s 1
f 10 9 5 6 
f 11 10 6 7 
f 12 11 7 8 
f 9 12 8 5 
s off
f 14 13 9 10 
f 15 14 10 11 
f 16 15 11 12 
f 13 16 12 9 
f 20 19 17 18 
f 22 21 19 20 
f 24 23 21 22 
f 22 20 18 24 
f 19 21 23 17 
f 27 28 25 26 
f 29 30 28 27 
f 31 32 30 29 
f 29 27 26 31 
f 28 30 32 25 
f 35 36 33 34 
f 37 38 36 35 
f 39 40 38 37 
f 37 35 34 39 
f 36 38 40 33 
f 55 56 53 54 
f 43 42 41 44 
f 46 45 41 42 
f 47 46 42 43 
f 48 47 43 44 
f 45 48 44 41 
s 2
f 50 49 45 46 
f 51 50 46 47 
f 52 51 47 48 
f 49 52 48 45 
s off
f 54 53 49 50 
f 55 54 50 51 
f 56 55 51 52 
f 53 56 52 49 
f 63 64 61 62 
f 59 58 57 60 
f 62 61 57 58 
f 63 62 58 59 
f 64 63 59 60 
f 61 64 60 57 
f 67 68 65 66 
f 71 72 69 70 
f 66 65 69 72 
f 67 66 72 71 
f 68 67 71 70 
f 65 68 70 69 
f 75 76 73 74 
f 79 80 77 78 
f 74 73 77 80 
f 75 74 80 79 
f 76 75 79 78 
f 73 76 78 77 
f 83 84 81 82 
f 87 88 85 86 
f 82 81 85 88 
f 83 82 88 87 
f 84 83 87 86 
f 81 84 86 85 
f 91 92 89 90 
f 95 96 93 94 
f 90 89 93 96 
f 91 90 96 95 
f 92 91 95 94 
f 89 92 94 93 
f 99 100 97 98 
f 103 104 101 102 
f 98 97 101 104 
f 99 98 104 103 
f 100 99 103 102 
f 97 100 102 101 
f 119 120 117 118 
f 107 106 105 108 
f 110 109 105 106 
f 111 110 106 107 
f 112 111 107 108 
f 109 112 108 105 
s 3
f 114 113 109 110 
f 115 114 110 111 
f 116 115 111 112 
f 113 116 112 109 
s off
f 118 117 113 114 
f 119 118 114 115 
f 120 119 115 116 
f 117 120 116 113 
f 124 123 121 122 
f 126 125 123 124 
f 128 127 125 126 
f 126 124 122 128 
f 123 125 127 121 
f 131 132 129 130 
f 133 134 132 131 
f 135 136 134 133 
f 133 131 130 135 
f 132 134 136 129 
f 139 140 137 138 
f 141 142 140 139 
f 143 144 142 141 
f 141 139 138 143 
f 140 142 144 137 
f 159 160 157 158 
f 147 146 145 148 
f 150 149 145 146 
f 151 150 146 147 
f 152 151 147 148 
f 149 152 148 145 
s 25
f 154 153 149 150 
f 155 154 150 151 
f 156 155 151 152 
f 153 156 152 149 
s off
f 158 157 153 154 
f 159 158 154 155 
f 160 159 155 156 
f 157 160 156 153 
f 164 163 161 162 
f 166 165 163 164 
f 168 167 165 166 
f 166 164 162 168 
f 163 165 167 161 
f 189 190 187 188 
f 171 170 169 172 
f 174 173 169 170 
f 175 174 170 171 
f 176 175 171 172 
f 173 176 172 169 
s 26
f 178 177 173 174 
f 179 178 174 175 
f 180 179 175 176 
f 177 180 176 173 
s off
f 182 181 177 178 
f 178 179 183 185 
f 178 185 182 
f 184 183 179 180 
f 180 177 181 186 
f 180 186 184 
f 183 184 186 185 
f 188 187 181 182 
f 189 188 182 185 
f 190 189 185 186 
f 187 190 186 181 
f 205 206 203 204 
f 193 192 191 194 
f 196 195 191 192 
f 197 196 192 193 
f 198 197 193 194 
f 195 198 194 191 
s 27
f 200 199 195 196 
f 201 200 196 197 
f 202 201 197 198 
f 199 202 198 195 
s off
f 204 203 199 200 
f 205 204 200 201 
f 206 205 201 202 
f 203 206 202 199 
f 213 214 211 212 
f 209 208 207 210 
f 212 211 207 208 
f 213 212 208 209 
f 214 213 209 210 
f 211 214 210 207 
f 218 217 215 216 
f 220 219 217 218 
f 222 221 219 220 
f 220 218 216 222 
f 217 219 221 215 
f 261 262 259 260 
f 225 224 223 226 
f 228 227 223 224 
f 229 228 224 225 
f 230 229 225 226 
f 227 230 226 223 
s 50
f 232 231 227 228 
f 233 232 228 229 
f 234 233 229 230 
f 231 234 230 227 
s off
f 236 235 231 232 
f 237 236 232 233 
f 238 237 233 234 
f 235 238 234 231 
s 51
f 240 239 235 236 
f 241 240 236 237 
f 242 241 237 238 
f 239 242 238 235 
s off
f 244 243 239 240 
f 245 244 240 241 
f 246 245 241 242 
f 243 246 242 239 
s 52
f 248 247 243 244 
f 249 248 244 245 
f 250 249 245 246 
f 247 250 246 243 
s off
f 252 251 247 248 
f 253 252 248 249 
f 254 253 249 250 
f 251 254 250 247 
s 53
f 256 255 251 252 
f 257 256 252 253 
f 258 257 253 254 
f 255 258 254 251 
s off
f 260 259 255 256 
f 261 260 256 257 
f 262 261 257 258 
f 259 262 258 255 
f 277 278 275 276 
f 265 264 263 266 
f 268 267 263 264 
f 269 268 264 265 
f 270 269 265 266 
f 267 270 266 263 
s 54
f 272 271 267 268 
f 273 272 268 269 
f 274 273 269 270 
f 271 274 270 267 
s off
f 276 275 271 272 
f 277 276 272 273 
f 278 277 273 274 
f 275 278 274 271 
f 282 281 279 280 
f 284 283 281 282 
f 286 285 283 284 
f 284 282 280 286 
f 281 283 285 279 
f 289 290 287 288 
f 291 292 290 289 
f 293 294 292 291 
f 291 289 288 293 
f 290 292 294 287 
f 297 298 295 296 
f 299 300 298 297 
f 301 302 300 299 
f 299 297 296 301 
f 298 300 302 295 
f 305 306 303 304 
f 307 308 306 305 
f 309 310 308 307 
f 307 305 304 309 
f 306 308 310 303 
f 313 314 311 312 
f 315 316 314 313 
f 317 318 316 315 
f 315 313 312 317 
f 314 316 318 311 
f 321 322 319 320 
f 323 324 322 321 
f 325 326 324 323 
f 323 321 320 325 
f 322 324 326 319 
f 329 330 327 328 
f 331 332 330 329 
f 333 334 332 331 
f 331 329 328 333 
f 330 332 334 327 
f 349 350 347 348 
f 337 336 335 338 
f 340 339 335 336 
f 341 340 336 337 
f 342 341 337 338 
f 339 342 338 335 
s 55
f 344 343 339 340 
f 345 344 340 341 
f 346 345 341 342 
f 343 346 342 339 
s off
f 348 347 343 344 
f 349 348 344 345 
f 350 349 345 346 
f 347 350 346 343 
f 354 353 351 352 
f 365 366 363 364 
f 358 357 355 356 
f 356 354 352 358 
f 353 355 357 351 
s 56
f 360 359 353 354 
f 361 360 354 356 
f 362 361 356 355 
f 359 362 355 353 
s off
f 364 363 359 360 
f 365 364 360 361 
f 366 365 361 362 
f 363 366 362 359 
f 369 370 367 368 
f 373 374 371 372 
f 377 378 375 376 
f 376 369 368 377 
f 370 375 378 367 
f 381 382 379 380 
f 385 386 383 384 
f 389 390 387 388 
f 388 381 380 389 
f 382 387 390 379 
f 405 406 403 404 
f 393 392 391 394 
f 396 395 391 392 
f 397 396 392 393 
f 398 397 393 394 
f 395 398 394 391 
s 88
f 400 399 395 396 
f 401 400 396 397 
f 402 401 397 398 
f 399 402 398 395 
s off
f 404 403 399 400 
f 405 404 400 401 
f 406 405 401 402 
f 403 406 402 399 
f 410 409 407 408 
f 412 411 409 410 
f 414 413 411 412 
f 412 410 408 414 
f 409 411 413 407 
f 417 418 415 416 
f 419 420 418 417 
f 421 422 420 419 
f 419 417 416 421 
f 418 420 422 415 
f 425 426 423 424 
f 427 428 426 425 
f 429 430 428 427 
f 427 425 424 429 
f 426 428 430 423 
f 433 434 431 432 
f 435 436 434 433 
f 437 438 436 435 
f 435 433 432 437 
f 434 436 438 431 
f 441 442 439 440 
f 443 444 442 441 
f 445 446 444 443 
f 443 441 440 445 
f 442 444 446 439 
f 449 450 447 448 
f 451 452 450 449 
f 453 454 452 451 
f 451 449 448 453 
f 450 452 454 447 
f 469 470 467 468 
f 457 456 455 458 
f 460 459 455 456 
f 461 460 456 457 
f 462 461 457 458 
f 459 462 458 455 
s 89
f 464 463 459 460 
f 465 464 460 461 
f 466 465 461 462 
f 463 466 462 459 
s off
f 468 467 463 464 
f 469 468 464 465 
f 470 469 465 466 
f 467 470 466 463 
f 474 473 471 472 
f 476 475 473 474 
f 478 477 475 476 
f 476 474 472 478 
f 473 475 477 471 
f 493 494 491 492 
f 481 480 479 482 
f 484 483 479 480 
f 485 484 480 481 
f 486 485 481 482 
f 483 486 482 479 
s 102
f 488 487 483 484 
f 489 488 484 485 
f 490 489 485 486 
f 487 490 486 483 
s off
f 492 491 487 488 
f 493 492 488 489 
f 494 493 489 490 
f 491 494 490 487 
f 501 502 499 500 
f 497 496 495 498 
f 500 499 495 496 
f 501 500 496 497 
f 502 501 497 498 
f 499 502 498 495 
f 506 505 503 504 
f 508 507 505 506 
f 510 509 507 508 
f 508 506 504 510 
f 505 507 509 503 
f 525 526 523 524 
f 513 512 511 514 
f 516 515 511 512 
f 517 516 512 513 
f 518 517 513 514 
f 515 518 514 511 
s 103
f 520 519 515 516 
f 521 520 516 517 
f 522 521 517 518 
f 519 522 518 515 
s off
f 524 523 519 520 
f 525 524 520 521 
f 526 525 521 522 
f 523 526 522 519 
f 541 542 539 540 
f 529 528 527 530 
f 532 531 527 528 
f 533 532 528 529 
f 534 533 529 530 
f 531 534 530 527 
s 104
f 536 535 531 532 
f 537 536 532 533 
f 538 537 533 534 
f 535 538 534 531 
s off
f 540 539 535 536 
f 541 540 536 537 
f 542 541 537 538 
f 539 542 538 535 
s 105
f 547 546 543 544 
f 548 547 544 545 
s 106
f 550 549 546 547 
f 551 550 547 548 
f 553 552 549 550 
f 554 553 550 551 
s 107
f 556 555 552 553 
f 557 556 553 554 
s 108
f 554 551 559 557 
f 551 548 545 559 
s off
f 549 552 555 558 
f 562 563 560 561 
f 561 560 558 543 
s 105
f 562 561 543 546 
s 106
f 563 562 546 549 
s off
f 560 563 549 558 
f 584 585 582 583 
f 566 565 564 567 
f 569 568 564 565 
f 570 569 565 566 
f 571 570 566 567 
f 568 571 567 564 
s 118
f 573 572 568 569 
f 574 573 569 570 
f 575 574 570 571 
f 572 575 571 568 
s off
f 572 573 577 580 
f 572 580 576 
f 578 577 573 574 
f 574 575 579 581 
f 574 581 578 
f 576 579 575 572 
f 578 581 580 577 
f 583 582 576 580 
f 584 583 580 581 
f 585 584 581 579 
f 582 585 579 576 
f 589 588 586 587 
f 591 590 588 589 
f 593 592 590 591 
f 591 589 587 593 
f 588 590 592 586 
f 596 597 594 595 
f 598 599 597 596 
f 600 601 599 598 
f 598 596 595 600 
f 597 599 601 594 
f 604 605 602 603 
f 606 607 605 604 
f 608 609 607 606 
f 606 604 603 608 
f 605 607 609 602 
f 612 613 610 611 
f 614 615 613 612 
f 616 617 615 614 
f 614 612 611 616 
f 613 615 617 610 
f 656 657 654 655 
f 620 619 618 621 
f 623 622 618 619 
f 624 623 619 620 
f 625 624 620 621 
f 622 625 621 618 
s 151
f 627 626 622 623 
f 628 627 623 624 
f 629 628 624 625 
f 626 629 625 622 
s off
f 631 630 626 627 
f 632 631 627 628 
f 633 632 628 629 
f 630 633 629 626 
s 152
f 635 634 630 631 
f 636 635 631 632 
f 637 636 632 633 
f 634 637 633 630 
s off
f 639 638 634 635 
f 640 639 635 636 
f 641 640 636 637 
f 638 641 637 634 
s 153
f 643 642 638 639 
f 644 643 639 640 
f 645 644 640 641 
f 642 645 641 638 
s off
f 647 646 642 643 
f 648 647 643 644 
f 649 648 644 645 
f 646 649 645 642 
s 154
f 651 650 646 647 
f 652 651 647 648 
f 653 652 648 649 
f 650 653 649 646 
s off
f 655 654 650 651 
f 656 655 651 652 
f 657 656 652 653 
f 654 657 653 650 
f 664 665 662 663 
f 660 659 658 661 
f 663 662 658 659 
f 664 663 659 660 
f 665 664 660 661 
f 662 665 661 658 
f 668 669 666 667 
f 672 673 670 671 
f 667 666 670 673 
f 668 667 673 672 
f 669 668 672 671 
f 666 669 671 670 
f 676 677 674 675 
f 680 681 678 679 
f 675 674 678 681 
f 676 675 681 680 
f 677 676 680 679 
f 674 677 679 678 
f 684 685 682 683 
f 688 689 686 687 
f 683 682 686 689 
f 684 683 689 688 
f 685 684 688 687 
f 682 685 687 686 
f 710 711 708 709 
f 692 691 690 693 
f 695 694 690 691 
f 696 695 691 692 
f 697 696 692 693 
f 694 697 693 690 
s 155
f 699 698 694 695 
f 700 699 695 696 
f 701 700 696 697 
f 698 701 697 694 
s off
f 698 699 703 706 
f 698 706 702 
f 704 703 699 700 
f 700 701 705 707 
f 700 707 704 
f 702 705 701 698 
f 704 707 706 703 
f 709 708 702 706 
f 710 709 706 707 
f 711 710 707 705 
f 708 711 705 702 
s 156
f 716 715 712 713 
f 717 716 713 714 
s 157
f 719 718 715 716 
f 720 719 716 717 
f 722 721 718 719 
f 723 722 719 720 
s 158
f 725 724 721 722 
f 726 725 722 723 
s 159
f 723 720 728 726 
f 720 717 714 728 
s off
f 718 721 724 727 
f 731 732 729 730 
f 730 729 727 712 
s 156
f 731 730 712 715 
s 157
f 732 731 715 718 
s off
f 729 732 718 727 
s 160
f 737 736 733 734 
f 738 737 734 735 
s 161
f 740 739 736 737 
f 741 740 737 738 
f 743 742 739 740 
f 744 743 740 741 
s 162
f 746 745 742 743 
f 747 746 743 744 
s 163
f 744 741 749 747 
f 741 738 735 749 
s off
f 739 742 745 748 
f 752 753 750 751 
f 751 750 748 733 
s 160
f 752 751 733 736 
s 161
f 753 752 736 739 
s off
f 750 753 739 748 
f 768 769 766 767 
f 756 755 754 757 
f 759 758 754 755 
f 760 759 755 756 
f 761 760 756 757 
f 758 761 757 754 
s 164
f 763 762 758 759 
f 764 763 759 760 
f 765 764 760 761 
f 762 765 761 758 
s off
f 767 766 762 763 
f 768 767 763 764 
f 769 768 764 765 
f 766 769 765 762 
f 784 785 782 783 
f 772 771 770 773 
f 775 774 770 771 
f 776 775 771 772 
f 777 776 772 773 
f 774 777 773 770 
s 165
f 779 778 774 775 
f 780 779 775 776 
f 781 780 776 777 
f 778 781 777 774 
s off
f 783 782 778 779 
f 784 783 779 780 
f 785 784 780 781 
f 782 785 781 778 
f 789 788 786 787 
f 791 790 788 789 
f 793 792 790 791 
f 791 789 787 793 
f 788 790 792 786 
f 796 797 794 795 
f 798 799 797 796 
f 800 801 799 798 
f 798 796 795 800 
f 797 799 801 794 
f 804 805 802 803 
f 806 807 805 804 
f 808 809 807 806 
f 806 804 803 808 
f 805 807 809 802 
f 824 825 822 823 
f 812 811 810 813 
f 815 814 810 811 
f 816 815 811 812 
f 817 816 812 813 
f 814 817 813 810 
s 166
f 819 818 814 815 
f 820 819 815 816 
f 821 820 816 817 
f 818 821 817 814 
s off
f 823 822 818 819 
f 824 823 819 820 
f 825 824 820 821 
f 822 825 821 818 
s 167
f 830 829 826 827 
f 831 830 827 828 
s 168
f 833 832 829 830 
f 834 833 830 831 
f 836 835 832 833 
f 837 836 833 834 
s 169
f 839 838 835 836 
f 840 839 836 837 
s 170
f 837 834 842 840 
f 834 831 828 842 
s off
f 832 835 838 841 
f 845 846 843 844 
f 844 843 841 826 
s 167
f 845 844 826 829 
s 168
f 846 845 829 832 
s off
f 843 846 832 841 
f 861 862 859 860 
f 849 848 847 850 
f 852 851 847 848 
f 853 852 848 849 
f 854 853 849 850 
f 851 854 850 847 
s 171
f 856 855 851 852 
f 857 856 852 853 
f 858 857 853 854 
f 855 858 854 851 
s off
f 860 859 855 856 
f 861 860 856 857 
f 862 861 857 858 
f 859 862 858 855 
f 866 865 863 864 
f 868 867 865 866 
f 870 869 867 868 
f 868 866 864 870 
f 865 867 869 863 
f 873 874 871 872 
f 875 876 874 873 
f 877 878 876 875 
f 875 873 872 877 
f 874 876 878 871 
f 881 882 879 880 
f 883 884 882 881 
f 885 886 884 883 
f 883 881 880 885 
f 882 884 886 879 
f 901 902 899 900 
f 889 888 887 890 
f 892 891 887 888 
f 893 892 888 889 
f 894 893 889 890 
f 891 894 890 887 
s 172
f 896 895 891 892 
f 897 896 892 893 
f 898 897 893 894 
f 895 898 894 891 
s off
f 900 899 895 896 
f 901 900 896 897 
f 902 901 897 898 
f 899 902 898 895 
f 905 906 903 904 
f 909 910 907 908 
f 904 903 907 910 
f 905 904 910 909 
f 906 905 909 908 
f 903 906 908 907 
f 913 914 911 912 
f 917 918 915 916 
f 912 911 915 918 
f 913 912 918 917 
f 914 913 917 916 
f 911 914 916 915 
f 921 922 919 920 
f 925 926 923 924 
f 920 919 923 926 
f 921 920 926 925 
f 922 921 925 924 
f 919 922 924 923 
f 941 942 939 940 
f 929 928 927 930 
f 932 931 927 928 
f 933 932 928 929 
f 934 933 929 930 
f 931 934 930 927 
s 177
f 936 935 931 932 
f 937 936 932 933 
f 938 937 933 934 
f 935 938 934 931 
s off
f 940 939 935 936 
f 941 940 936 937 
f 942 941 937 938 
f 939 942 938 935 
s 178
f 947 946 943 944 
f 948 947 944 945 
s 179
f 950 949 946 947 
f 951 950 947 948 
f 953 952 949 950 
f 954 953 950 951 
s 180
f 956 955 952 953 
f 957 956 953 954 
s 181
f 954 951 959 957 
f 951 948 945 959 
s off
f 949 952 955 958 
f 962 963 960 961 
f 961 960 958 943 
s 178
f 962 961 943 946 
s 179
f 963 962 946 949 
s off
f 960 963 949 958 
f 978 979 976 977 
f 966 965 964 967 
f 969 968 964 965 
f 970 969 965 966 
f 971 970 966 967 
f 968 971 967 964 
s 182
f 973 972 968 969 
f 974 973 969 970 
f 975 974 970 971 
f 972 975 971 968 
s off
f 977 976 972 973 
f 978 977 973 974 
f 979 978 974 975 
f 976 979 975 972 
s 183
f 984 983 980 981 
f 985 984 981 982 
s 184
f 987 986 983 984 
f 988 987 984 985 
f 990 989 986 987 
f 991 990 987 988 
s 185
f 993 992 989 990 
f 994 993 990 991 
s 186
f 991 988 996 994 
f 988 985 982 996 
s off
f 986 989 992 995 
f 999 1000 997 998 
f 998 997 995 980 
s 183
f 999 998 980 983 
s 184
f 1000 999 983 986 
s off
f 997 1000 986 995 
f 1031 1032 1029 1030 
f 1003 1002 1001 1004 
f 1006 1005 1001 1002 
f 1007 1006 1002 1003 
f 1008 1007 1003 1004 
f 1005 1008 1004 1001 
s 188
f 1010 1009 1005 1006 
f 1011 1010 1006 1007 
f 1012 1011 1007 1008 
f 1009 1012 1008 1005 
s off
f 1014 1013 1009 1010 
f 1015 1014 1010 1011 
f 1016 1015 1011 1012 
f 1013 1016 1012 1009 
s 189
f 1018 1017 1013 1014 
f 1019 1018 1014 1015 
f 1020 1019 1015 1016 
f 1017 1020 1016 1013 
s off
f 1022 1021 1017 1018 
f 1023 1022 1018 1019 
f 1024 1023 1019 1020 
f 1021 1024 1020 1017 
s 190
f 1026 1025 1021 1022 
f 1027 1026 1022 1023 
f 1028 1027 1023 1024 
f 1025 1028 1024 1021 
s off
f 1030 1029 1025 1026 
f 1031 1030 1026 1027 
f 1032 1031 1027 1028 
f 1029 1032 1028 1025 
f 1047 1048 1045 1046 
f 1035 1034 1033 1036 
f 1038 1037 1033 1034 
f 1039 1038 1034 1035 
f 1040 1039 1035 1036 
f 1037 1040 1036 1033 
s 195
f 1042 1041 1037 1038 
f 1043 1042 1038 1039 
f 1044 1043 1039 1040 
f 1041 1044 1040 1037 
s off
f 1046 1045 1041 1042 
f 1047 1046 1042 1043 
f 1048 1047 1043 1044 
f 1045 1048 1044 1041 
s 196
f 1053 1052 1049 1050 
f 1054 1053 1050 1051 
s 197
f 1056 1055 1052 1053 
f 1057 1056 1053 1054 
f 1059 1058 1055 1056 
f 1060 1059 1056 1057 
s 198
f 1062 1061 1058 1059 
f 1063 1062 1059 1060 
s 199
f 1060 1057 1065 1063 
f 1057 1054 1051 1065 
s off
f 1055 1058 1061 1064 
f 1068 1069 1066 1067 
f 1067 1066 1064 1049 
s 196
f 1068 1067 1049 1052 
s 197
f 1069 1068 1052 1055 
s off
f 1066 1069 1055 1064 
s 200
f 1074 1073 1070 1071 
f 1075 1074 1071 1072 
s 201
f 1077 1076 1073 1074 
f 1078 1077 1074 1075 
f 1080 1079 1076 1077 
f 1081 1080 1077 1078 
s 202
f 1083 1082 1079 1080 
f 1084 1083 1080 1081 
s 203
f 1081 1078 1086 1084 
f 1078 1075 1072 1086 
s off
f 1076 1079 1082 1085 
f 1089 1090 1087 1088 
f 1088 1087 1085 1070 
s 200
f 1089 1088 1070 1073 
s 201
f 1090 1089 1073 1076 
s off
f 1087 1090 1076 1085 
f 1111 1112 1109 1110 
f 1093 1092 1091 1094 
f 1096 1095 1091 1092 
f 1097 1096 1092 1093 
f 1098 1097 1093 1094 
f 1095 1098 1094 1091 
s 204
f 1100 1099 1095 1096 
f 1101 1100 1096 1097 
f 1102 1101 1097 1098 
f 1099 1102 1098 1095 
s off
f 1099 1100 1104 1107 
f 1099 1107 1103 
f 1105 1104 1100 1101 
f 1101 1102 1106 1108 
f 1101 1108 1105 
f 1103 1106 1102 1099 
f 1105 1108 1107 1104 
f 1110 1109 1103 1107 
f 1111 1110 1107 1108 
f 1112 1111 1108 1106 
f 1109 1112 1106 1103 
f 1127 1128 1125 1126 
f 1115 1114 1113 1116 
f 1118 1117 1113 1114 
f 1119 1118 1114 1115 
f 1120 1119 1115 1116 
f 1117 1120 1116 1113 
s 214
f 1122 1121 1117 1118 
f 1123 1122 1118 1119 
f 1124 1123 1119 1120 
f 1121 1124 1120 1117 
s off
f 1126 1125 1121 1122 
f 1127 1126 1122 1123 
f 1128 1127 1123 1124 
f 1125 1128 1124 1121 
f 1135 1136 1133 1134 
f 1131 1130 1129 1132 
f 1134 1133 1129 1130 
f 1135 1134 1130 1131 
f 1136 1135 1131 1132 
f 1133 1136 1132 1129 
f 1139 1140 1137 1138 
f 1143 1144 1141 1142 
f 1138 1137 1141 1144 
f 1139 1138 1144 1143 
f 1140 1139 1143 1142 
f 1137 1140 1142 1141 
f 1147 1148 1145 1146 
f 1151 1152 1149 1150 
f 1146 1145 1149 1152 
f 1147 1146 1152 1151 
f 1148 1147 1151 1150 
f 1145 1148 1150 1149 
s 215
f 1157 1156 1153 1154 
f 1158 1157 1154 1155 
s 216
f 1160 1159 1156 1157 
f 1161 1160 1157 1158 
f 1163 1162 1159 1160 
f 1164 1163 1160 1161 
s 217
f 1166 1165 1162 1163 
f 1167 1166 1163 1164 
s 218
f 1164 1161 1169 1167 
f 1161 1158 1155 1169 
s off
f 1159 1162 1165 1168 
f 1172 1173 1170 1171 
f 1171 1170 1168 1153 
s 215
f 1172 1171 1153 1156 
s 216
f 1173 1172 1156 1159 
s off
f 1170 1173 1159 1168 
f 1188 1189 1186 1187 
f 1176 1175 1174 1177 
f 1179 1178 1174 1175 
f 1180 1179 1175 1176 
f 1181 1180 1176 1177 
f 1178 1181 1177 1174 
s 219
f 1183 1182 1178 1179 
f 1184 1183 1179 1180 
f 1185 1184 1180 1181 
f 1182 1185 1181 1178 
s off
f 1187 1186 1182 1183 
f 1188 1187 1183 1184 
f 1189 1188 1184 1185 
f 1186 1189 1185 1182 
s 220
f 1194 1193 1190 1191 
f 1195 1194 1191 1192 
s 221
f 1197 1196 1193 1194 
f 1198 1197 1194 1195 
f 1200 1199 1196 1197 
f 1201 1200 1197 1198 
s 222
f 1203 1202 1199 1200 
f 1204 1203 1200 1201 
s 223
f 1201 1198 1206 1204 
f 1198 1195 1192 1206 
s off
f 1196 1199 1202 1205 
f 1209 1210 1207 1208 
f 1208 1207 1205 1190 
s 220
f 1209 1208 1190 1193 
s 221
f 1210 1209 1193 1196 
s off
f 1207 1210 1196 1205 
f 1214 1213 1211 1212 
f 1216 1215 1213 1214 
f 1218 1217 1215 1216 
f 1216 1214 1212 1218 
f 1213 1215 1217 1211 
f 1233 1234 1231 1232 
f 1221 1220 1219 1222 
f 1224 1223 1219 1220 
f 1225 1224 1220 1221 
f 1226 1225 1221 1222 
f 1223 1226 1222 1219 
s 230
f 1228 1227 1223 1224 
f 1229 1228 1224 1225 
f 1230 1229 1225 1226 
f 1227 1230 1226 1223 
s off
f 1232 1231 1227 1228 
f 1233 1232 1228 1229 
f 1234 1233 1229 1230 
f 1231 1234 1230 1227 
s 231
f 1239 1238 1235 1236 
f 1240 1239 1236 1237 
s 232
f 1242 1241 1238 1239 
f 1243 1242 1239 1240 
f 1245 1244 1241 1242 
f 1246 1245 1242 1243 
s 233
f 1248 1247 1244 1245 
f 1249 1248 1245 1246 
s 234
f 1246 1243 1251 1249 
f 1243 1240 1237 1251 
s off
f 1241 1244 1247 1250 
f 1254 1255 1252 1253 
f 1253 1252 1250 1235 
s 231
f 1254 1253 1235 1238 
s 232
f 1255 1254 1238 1241 
s off
f 1252 1255 1241 1250 
f 1276 1277 1274 1275 
f 1258 1257 1256 1259 
f 1261 1260 1256 1257 
f 1262 1261 1257 1258 
f 1263 1262 1258 1259 
f 1260 1263 1259 1256 
s 235
f 1265 1264 1260 1261 
f 1266 1265 1261 1262 
f 1267 1266 1262 1263 
f 1264 1267 1263 1260 
s off
f 1269 1268 1264 1265 
f 1265 1266 1270 1272 
f 1265 1272 1269 
f 1271 1270 1266 1267 
f 1267 1264 1268 1273 
f 1267 1273 1271 
f 1270 1271 1273 1272 
f 1275 1274 1268 1269 
f 1276 1275 1269 1272 
f 1277 1276 1272 1273 
f 1274 1277 1273 1268 
f 1281 1280 1278 1279 
f 1283 1282 1280 1281 
f 1285 1284 1282 1283 
f 1283 1281 1279 1285 
f 1280 1282 1284 1278 
f 1288 1289 1286 1287 
f 1290 1291 1289 1288 
f 1292 1293 1291 1290 
f 1290 1288 1287 1292 
f 1289 1291 1293 1286 
f 1296 1297 1294 1295 
f 1298 1299 1297 1296 
f 1300 1301 1299 1298 
f 1298 1296 1295 1300 
f 1297 1299 1301 1294 
f 1304 1305 1302 1303 
f 1306 1307 1305 1304 
f 1308 1309 1307 1306 
f 1306 1304 1303 1308 
f 1305 1307 1309 1302 
f 1312 1313 1310 1311 
f 1314 1315 1313 1312 
f 1316 1317 1315 1314 
f 1314 1312 1311 1316 
f 1313 1315 1317 1310 
f 1320 1321 1318 1319 
f 1322 1323 1321 1320 
f 1324 1325 1323 1322 
f 1322 1320 1319 1324 
f 1321 1323 1325 1318 
f 1328 1329 1326 1327 
f 1330 1331 1329 1328 
f 1332 1333 1331 1330 
f 1330 1328 1327 1332 
f 1329 1331 1333 1326 
f 1336 1337 1334 1335 
f 1338 1339 1337 1336 
f 1340 1341 1339 1338 
f 1338 1336 1335 1340 
f 1337 1339 1341 1334 
s 236
f 1346 1345 1342 1343 
f 1347 1346 1343 1344 
s 237
f 1349 1348 1345 1346 
f 1350 1349 1346 1347 
f 1352 1351 1348 1349 
f 1353 1352 1349 1350 
s 238
f 1355 1354 1351 1352 
f 1356 1355 1352 1353 
s 239
f 1353 1350 1358 1356 
f 1350 1347 1344 1358 
s off
f 1348 1351 1354 1357 
f 1361 1362 1359 1360 
f 1360 1359 1357 1342 
s 236
f 1361 1360 1342 1345 
s 237
f 1362 1361 1345 1348 
s off
f 1359 1362 1348 1357 
f 1377 1378 1375 1376 
f 1365 1364 1363 1366 
f 1368 1367 1363 1364 
f 1369 1368 1364 1365 
f 1370 1369 1365 1366 
f 1367 1370 1366 1363 
s 241
f 1372 1371 1367 1368 
f 1373 1372 1368 1369 
f 1374 1373 1369 1370 
f 1371 1374 1370 1367 
s off
f 1376 1375 1371 1372 
f 1377 1376 1372 1373 
f 1378 1377 1373 1374 
f 1375 1378 1374 1371 
s 242
f 1383 1382 1379 1380 
f 1384 1383 1380 1381 
s 243
f 1386 1385 1382 1383 
f 1387 1386 1383 1384 
f 1389 1388 1385 1386 
f 1390 1389 1386 1387 
s 244
f 1392 1391 1388 1389 
f 1393 1392 1389 1390 
s 245
f 1390 1387 1395 1393 
f 1387 1384 1381 1395 
s off
f 1385 1388 1391 1394 
f 1398 1399 1396 1397 
f 1397 1396 1394 1379 
s 242
f 1398 1397 1379 1382 
s 243
f 1399 1398 1382 1385 
s off
f 1396 1399 1385 1394 
f 1414 1415 1412 1413 
f 1402 1401 1400 1403 
f 1405 1404 1400 1401 
f 1406 1405 1401 1402 
f 1407 1406 1402 1403 
f 1404 1407 1403 1400 
s 246
f 1409 1408 1404 1405 
f 1410 1409 1405 1406 
f 1411 1410 1406 1407 
f 1408 1411 1407 1404 
s off
f 1413 1412 1408 1409 
f 1414 1413 1409 1410 
f 1415 1414 1410 1411 
f 1412 1415 1411 1408 
f 1430 1431 1428 1429 
f 1418 1417 1416 1419 
f 1421 1420 1416 1417 
f 1422 1421 1417 1418 
f 1423 1422 1418 1419 
f 1420 1423 1419 1416 
s 248
f 1425 1424 1420 1421 
f 1426 1425 1421 1422 
f 1427 1426 1422 1423 
f 1424 1427 1423 1420 
s off
f 1429 1428 1424 1425 
f 1430 1429 1425 1426 
f 1431 1430 1426 1427 
f 1428 1431 1427 1424 
f 1478 1479 1476 1477 
f 1434 1433 1432 1435 
f 1437 1436 1432 1433 
f 1438 1437 1433 1434 
f 1439 1438 1434 1435 
f 1436 1439 1435 1432 
s 249
f 1441 1440 1436 1437 
f 1442 1441 1437 1438 
f 1443 1442 1438 1439 
f 1440 1443 1439 1436 
s off
f 1445 1444 1440 1441 
f 1446 1445 1441 1442 
f 1447 1446 1442 1443 
f 1444 1447 1443 1440 
s 250
f 1449 1448 1444 1445 
f 1450 1449 1445 1446 
f 1451 1450 1446 1447 
f 1448 1451 1447 1444 
s off
f 1453 1452 1448 1449 
f 1454 1453 1449 1450 
f 1455 1454 1450 1451 
f 1452 1455 1451 1448 
s 251
f 1457 1456 1452 1453 
f 1458 1457 1453 1454 
f 1459 1458 1454 1455 
f 1456 1459 1455 1452 
s off
f 1461 1460 1456 1457 
f 1462 1461 1457 1458 
f 1463 1462 1458 1459 
f 1460 1463 1459 1456 
s 252
f 1465 1464 1460 1461 
f 1466 1465 1461 1462 
f 1467 1466 1462 1463 
f 1464 1467 1463 1460 
s off
f 1469 1468 1464 1465 
f 1470 1469 1465 1466 
f 1471 1470 1466 1467 
f 1468 1471 1467 1464 
s 253
f 1473 1472 1468 1469 
f 1474 1473 1469 1470 
f 1475 1474 1470 1471 
f 1472 1475 1471 1468 
s off
f 1477 1476 1472 1473 
f 1478 1477 1473 1474 
f 1479 1478 1474 1475 
f 1476 1479 1475 1472 
f 1510 1511 1508 1509 
f 1482 1481 1480 1483 
f 1485 1484 1480 1481 
f 1486 1485 1481 1482 
f 1487 1486 1482 1483 
f 1484 1487 1483 1480 
s 254
f 1489 1488 1484 1485 
f 1490 1489 1485 1486 
f 1491 1490 1486 1487 
f 1488 1491 1487 1484 
s off
f 1493 1492 1488 1489 
f 1494 1493 1489 1490 
f 1495 1494 1490 1491 
f 1492 1495 1491 1488 
s 255
f 1497 1496 1492 1493 
f 1498 1497 1493 1494 
f 1499 1498 1494 1495 
f 1496 1499 1495 1492 
s off
f 1501 1500 1496 1497 
f 1502 1501 1497 1498 
f 1503 1502 1498 1499 
f 1500 1503 1499 1496 
s 256
f 1505 1504 1500 1501 
f 1506 1505 1501 1502 
f 1507 1506 1502 1503 
f 1504 1507 1503 1500 
s off
f 1509 1508 1504 1505 
f 1510 1509 1505 1506 
f 1511 1510 1506 1507 
f 1508 1511 1507 1504 
f 1515 1514 1512 1513 
f 1517 1516 1514 1515 
f 1519 1518 1516 1517 
f 1517 1515 1513 1519 
f 1514 1516 1518 1512 
f 1522 1523 1520 1521 
f 1524 1525 1523 1522 
f 1526 1527 1525 1524 
f 1524 1522 1521 1526 
f 1523 1525 1527 1520 
f 1530 1531 1528 1529 
f 1532 1533 1531 1530 
f 1534 1535 1533 1532 
f 1532 1530 1529 1534 
f 1531 1533 1535 1528 
f 1538 1539 1536 1537 
f 1540 1541 1539 1538 
f 1542 1543 1541 1540 
f 1540 1538 1537 1542 
f 1539 1541 1543 1536 
f 1546 1547 1544 1545 
f 1548 1549 1547 1546 
f 1550 1551 1549 1548 
f 1548 1546 1545 1550 
f 1547 1549 1551 1544 
f 1554 1555 1552 1553 
f 1556 1557 1555 1554 
f 1558 1559 1557 1556 
f 1556 1554 1553 1558 
f 1555 1557 1559 1552 
f 1574 1575 1572 1573 
f 1562 1561 1560 1563 
f 1565 1564 1560 1561 
f 1566 1565 1561 1562 
f 1567 1566 1562 1563 
f 1564 1567 1563 1560 
s 257
f 1569 1568 1564 1565 
f 1570 1569 1565 1566 
f 1571 1570 1566 1567 
f 1568 1571 1567 1564 
s off
f 1573 1572 1568 1569 
f 1574 1573 1569 1570 
f 1575 1574 1570 1571 
f 1572 1575 1571 1568 
f 1590 1591 1588 1589 
f 1578 1577 1576 1579 
f 1581 1580 1576 1577 
f 1582 1581 1577 1578 
f 1583 1582 1578 1579 
f 1580 1583 1579 1576 
s 258
f 1585 1584 1580 1581 
f 1586 1585 1581 1582 
f 1587 1586 1582 1583 
f 1584 1587 1583 1580 
s off
f 1589 1588 1584 1585 
f 1590 1589 1585 1586 
f 1591 1590 1586 1587 
f 1588 1591 1587 1584 
f 1595 1594 1592 1593 
f 1597 1596 1594 1595 
f 1599 1598 1596 1597 
f 1597 1595 1593 1599 
f 1594 1596 1598 1592 
f 1620 1621 1618 1619 
f 1602 1601 1600 1603 
f 1605 1604 1600 1601 
f 1606 1605 1601 1602 
f 1607 1606 1602 1603 
f 1604 1607 1603 1600 
s 259
f 1609 1608 1604 1605 
f 1610 1609 1605 1606 
f 1611 1610 1606 1607 
f 1608 1611 1607 1604 
s off
f 1613 1612 1608 1609 
f 1609 1610 1614 1616 
f 1609 1616 1613 
f 1615 1614 1610 1611 
f 1611 1608 1612 1617 
f 1611 1617 1615 
f 1614 1615 1617 1616 
f 1619 1618 1612 1613 
f 1620 1619 1613 1616 
f 1621 1620 1616 1617 
f 1618 1621 1617 1612 
f 1625 1624 1622 1623 
f 1627 1626 1624 1625 
f 1629 1628 1626 1627 
f 1627 1625 1623 1629 
f 1624 1626 1628 1622 
f 1633 1632 1630 1631 
f 1635 1634 1632 1633 
f 1637 1636 1634 1635 
f 1635 1633 1631 1637 
f 1632 1634 1636 1630 
f 1652 1653 1650 1651 
f 1640 1639 1638 1641 
f 1643 1642 1638 1639 
f 1644 1643 1639 1640 
f 1645 1644 1640 1641 
f 1642 1645 1641 1638 
s 260
f 1647 1646 1642 1643 
f 1648 1647 1643 1644 
f 1649 1648 1644 1645 
f 1646 1649 1645 1642 
s off
f 1651 1650 1646 1647 
f 1652 1651 1647 1648 
f 1653 1652 1648 1649 
f 1650 1653 1649 1646 
s 261
f 1658 1657 1654 1655 
f 1659 1658 1655 1656 
s 262
f 1661 1660 1657 1658 
f 1662 1661 1658 1659 
f 1664 1663 1660 1661 
f 1665 1664 1661 1662 
s 263
f 1667 1666 1663 1664 
f 1668 1667 1664 1665 
s 264
f 1665 1662 1670 1668 
f 1662 1659 1656 1670 
s off
f 1660 1663 1666 1669 
f 1673 1674 1671 1672 
f 1672 1671 1669 1654 
s 261
f 1673 1672 1654 1657 
s 262
f 1674 1673 1657 1660 
s off
f 1671 1674 1660 1669 
f 1721 1722 1719 1720 
f 1677 1676 1675 1678 
f 1680 1679 1675 1676 
f 1681 1680 1676 1677 
f 1682 1681 1677 1678 
f 1679 1682 1678 1675 
s 265
f 1684 1683 1679 1680 
f 1685 1684 1680 1681 
f 1686 1685 1681 1682 
f 1683 1686 1682 1679 
s off
f 1688 1687 1683 1684 
f 1689 1688 1684 1685 
f 1690 1689 1685 1686 
f 1687 1690 1686 1683 
s 266
f 1692 1691 1687 1688 
f 1693 1692 1688 1689 
f 1694 1693 1689 1690 
f 1691 1694 1690 1687 
s off
f 1696 1695 1691 1692 
f 1697 1696 1692 1693 
f 1698 1697 1693 1694 
f 1695 1698 1694 1691 
s 267
f 1700 1699 1695 1696 
f 1701 1700 1696 1697 
f 1702 1701 1697 1698 
f 1699 1702 1698 1695 
s off
f 1704 1703 1699 1700 
f 1705 1704 1700 1701 
f 1706 1705 1701 1702 
f 1703 1706 1702 1699 
s 268
f 1708 1707 1703 1704 
f 1709 1708 1704 1705 
f 1710 1709 1705 1706 
f 1707 1710 1706 1703 
s off
f 1712 1711 1707 1708 
f 1713 1712 1708 1709 
f 1714 1713 1709 1710 
f 1711 1714 1710 1707 
s 269
f 1716 1715 1711 1712 
f 1717 1716 1712 1713 
f 1718 1717 1713 1714 
f 1715 1718 1714 1711 
s off
f 1720 1719 1715 1716 
f 1721 1720 1716 1717 
f 1722 1721 1717 1718 
f 1719 1722 1718 1715 
f 1726 1725 1723 1724 
f 1728 1727 1725 1726 
f 1730 1729 1727 1728 
f 1728 1726 1724 1730 
f 1725 1727 1729 1723 
f 1733 1734 1731 1732 
f 1735 1736 1734 1733 
f 1737 1738 1736 1735 
f 1735 1733 1732 1737 
f 1734 1736 1738 1731 
f 1741 1742 1739 1740 
f 1743 1744 1742 1741 
f 1745 1746 1744 1743 
f 1743 1741 1740 1745 
f 1742 1744 1746 1739 
f 1749 1750 1747 1748 
f 1751 1752 1750 1749 
f 1753 1754 1752 1751 
f 1751 1749 1748 1753 
f 1750 1752 1754 1747 
f 1757 1758 1755 1756 
f 1759 1760 1758 1757 
f 1761 1762 1760 1759 
f 1759 1757 1756 1761 
f 1758 1760 1762 1755 
f 1765 1766 1763 1764 
f 1767 1768 1766 1765 
f 1769 1770 1768 1767 
f 1767 1765 1764 1769 
f 1766 1768 1770 1763 
f 1825 1826 1823 1824 
f 1773 1772 1771 1774 
f 1776 1775 1771 1772 
f 1777 1776 1772 1773 
f 1778 1777 1773 1774 
f 1775 1778 1774 1771 
s 272
f 1780 1779 1775 1776 
f 1781 1780 1776 1777 
f 1782 1781 1777 1778 
f 1779 1782 1778 1775 
s off
f 1784 1783 1779 1780 
f 1785 1784 1780 1781 
f 1786 1785 1781 1782 
f 1783 1786 1782 1779 
s 273
f 1788 1787 1783 1784 
f 1789 1788 1784 1785 
f 1790 1789 1785 1786 
f 1787 1790 1786 1783 
s off
f 1792 1791 1787 1788 
f 1793 1792 1788 1789 
f 1794 1793 1789 1790 
f 1791 1794 1790 1787 
s 274
f 1796 1795 1791 1792 
f 1797 1796 1792 1793 
f 1798 1797 1793 1794 
f 1795 1798 1794 1791 
s off
f 1800 1799 1795 1796 
f 1801 1800 1796 1797 
f 1802 1801 1797 1798 
f 1799 1802 1798 1795 
s 275
f 1804 1803 1799 1800 
f 1805 1804 1800 1801 
f 1806 1805 1801 1802 
f 1803 1806 1802 1799 
s off
f 1808 1807 1803 1804 
f 1809 1808 1804 1805 
f 1810 1809 1805 1806 
f 1807 1810 1806 1803 
s 276
f 1812 1811 1807 1808 
f 1813 1812 1808 1809 
f 1814 1813 1809 1810 
f 1811 1814 1810 1807 
s off
f 1816 1815 1811 1812 
f 1817 1816 1812 1813 
f 1818 1817 1813 1814 
f 1815 1818 1814 1811 
s 277
f 1820 1819 1815 1816 
f 1821 1820 1816 1817 
f 1822 1821 1817 1818 
f 1819 1822 1818 1815 
s off
f 1824 1823 1819 1820 
f 1825 1824 1820 1821 
f 1826 1825 1821 1822 
f 1823 1826 1822 1819 
f 1833 1834 1831 1832 
f 1829 1828 1827 1830 
f 1832 1831 1827 1828 
f 1833 1832 1828 1829 
f 1834 1833 1829 1830 
f 1831 1834 1830 1827 
f 1837 1838 1835 1836 
f 1841 1842 1839 1840 
f 1836 1835 1839 1842 
f 1837 1836 1842 1841 
f 1838 1837 1841 1840 
f 1835 1838 1840 1839 
f 1845 1846 1843 1844 
f 1849 1850 1847 1848 
f 1844 1843 1847 1850 
f 1845 1844 1850 1849 
f 1846 1845 1849 1848 
f 1843 1846 1848 1847 
f 1853 1854 1851 1852 
f 1857 1858 1855 1856 
f 1852 1851 1855 1858 
f 1853 1852 1858 1857 
f 1854 1853 1857 1856 
f 1851 1854 1856 1855 
f 1861 1862 1859 1860 
f 1865 1866 1863 1864 
f 1860 1859 1863 1866 
f 1861 1860 1866 1865 
f 1862 1861 1865 1864 
f 1859 1862 1864 1863 
f 1869 1870 1867 1868 
f 1873 1874 1871 1872 
f 1868 1867 1871 1874 
f 1869 1868 1874 1873 
f 1870 1869 1873 1872 
f 1867 1870 1872 1871 
f 1889 1890 1887 1888 
f 1877 1876 1875 1878 
f 1880 1879 1875 1876 
f 1881 1880 1876 1877 
f 1882 1881 1877 1878 
f 1879 1882 1878 1875 
s 278
f 1884 1883 1879 1880 
f 1885 1884 1880 1881 
f 1886 1885 1881 1882 
f 1883 1886 1882 1879 
s off
f 1888 1887 1883 1884 
f 1889 1888 1884 1885 
f 1890 1889 1885 1886 
f 1887 1890 1886 1883 
f 1894 1893 1891 1892 
f 1896 1895 1893 1894 
f 1898 1897 1895 1896 
f 1896 1894 1892 1898 
f 1893 1895 1897 1891 
f 1913 1914 1911 1912 
f 1901 1900 1899 1902 
f 1904 1903 1899 1900 
f 1905 1904 1900 1901 
f 1906 1905 1901 1902 
f 1903 1906 1902 1899 
s 279
f 1908 1907 1903 1904 
f 1909 1908 1904 1905 
f 1910 1909 1905 1906 
f 1907 1910 1906 1903 
s off
f 1912 1911 1907 1908 
f 1913 1912 1908 1909 
f 1914 1913 1909 1910 
f 1911 1914 1910 1907 
f 1918 1917 1915 1916 
f 1920 1919 1917 1918 
f 1922 1921 1919 1920 
f 1920 1918 1916 1922 
f 1917 1919 1921 1915 
f 1937 1938 1935 1936 
f 1925 1924 1923 1926 
f 1928 1927 1923 1924 
f 1929 1928 1924 1925 
f 1930 1929 1925 1926 
f 1927 1930 1926 1923 
s 280
f 1932 1931 1927 1928 
f 1933 1932 1928 1929 
f 1934 1933 1929 1930 
f 1931 1934 1930 1927 
s off
f 1936 1935 1931 1932 
f 1937 1936 1932 1933 
f 1938 1937 1933 1934 
f 1935 1938 1934 1931 
f 1942 1941 1939 1940 
f 1953 1954 1951 1952 
f 1946 1945 1943 1944 
f 1944 1942 1940 1946 
f 1941 1943 1945 1939 
s 281
f 1948 1947 1941 1942 
f 1949 1948 1942 1944 
f 1950 1949 1944 1943 
f 1947 1950 1943 1941 
s off
f 1952 1951 1947 1948 
f 1953 1952 1948 1949 
f 1954 1953 1949 1950 
f 1951 1954 1950 1947 
f 1957 1958 1955 1956 
f 1961 1962 1959 1960 
f 1963 1964 1959 1962 
f 1965 1963 1962 1961 
f 1966 1965 1961 1960 
f 1964 1966 1960 1959 
s 235
f 1967 1968 1964 1963 
f 1969 1967 1963 1965 
f 1970 1969 1965 1966 
f 1968 1970 1966 1964 
s off
f 1971 1972 1968 1967 
f 1967 1969 1973 1974 
f 1967 1974 1971 
f 1975 1973 1969 1970 
f 1970 1968 1972 1976 
f 1970 1976 1975 
f 1973 1975 1976 1974 
f 1956 1955 1972 1971 
f 1957 1956 1971 1974 
f 1958 1957 1974 1976 
f 1955 1958 1976 1972 
f 1979 1980 1977 1978 
f 1981 1982 1980 1979 
f 1983 1984 1982 1981 
f 1981 1979 1978 1983 
f 1980 1982 1984 1977 
f 1987 1988 1985 1986 
f 1989 1990 1988 1987 
f 1991 1992 1990 1989 
f 1989 1987 1986 1991 
f 1988 1990 1992 1985 
f 1995 1996 1993 1994 
f 1997 1998 1996 1995 
f 1999 2000 1998 1997 
f 1997 1995 1994 1999 
f 1996 1998 2000 1993 
f 2003 2004 2001 2002 
f 2005 2006 2004 2003 
f 2007 2008 2006 2005 
f 2005 2003 2002 2007 
f 2004 2006 2008 2001 
f 2011 2012 2009 2010 
f 2013 2014 2012 2011 
f 2015 2016 2014 2013 
f 2013 2011 2010 2015 
f 2012 2014 2016 2009 
f 2019 2020 2017 2018 
f 2021 2022 2020 2019 
f 2023 2024 2022 2021 
f 2021 2019 2018 2023 
f 2020 2022 2024 2017 
f 2027 2028 2025 2026 
f 2029 2030 2028 2027 
f 2031 2032 2030 2029 
f 2029 2027 2026 2031 
f 2028 2030 2032 2025 
f 2035 2036 2033 2034 
f 2037 2038 2036 2035 
f 2039 2040 2038 2037 
f 2037 2035 2034 2039 
f 2036 2038 2040 2033 
s 236
f 2043 2044 2041 2042 
f 2046 2043 2042 2045 
s 237
f 2047 2048 2044 2043 
f 2049 2047 2043 2046 
f 2050 2051 2048 2047 
f 2052 2050 2047 2049 
s 238
f 2053 2054 2051 2050 
f 2055 2053 2050 2052 
s 239
f 2052 2049 2056 2055 
f 2049 2046 2045 2056 
s off
f 2048 2051 2054 2057 
f 2060 2061 2058 2059 
f 2059 2058 2057 2041 
s 236
f 2060 2059 2041 2044 
s 237
f 2061 2060 2044 2048 
s off
f 2058 2061 2048 2057 
f 2064 2065 2062 2063 
f 2068 2069 2066 2067 
f 2070 2071 2066 2069 
f 2072 2070 2069 2068 
f 2073 2072 2068 2067 
f 2071 2073 2067 2066 
s 241
f 2074 2075 2071 2070 
f 2076 2074 2070 2072 
f 2077 2076 2072 2073 
f 2075 2077 2073 2071 
s off
f 2063 2062 2075 2074 
f 2064 2063 2074 2076 
f 2065 2064 2076 2077 
f 2062 2065 2077 2075 
s 242
f 2080 2081 2078 2079 
f 2083 2080 2079 2082 
s 243
f 2084 2085 2081 2080 
f 2086 2084 2080 2083 
f 2087 2088 2085 2084 
f 2089 2087 2084 2086 
s 244
f 2090 2091 2088 2087 
f 2092 2090 2087 2089 
s 245
f 2089 2086 2093 2092 
f 2086 2083 2082 2093 
s off
f 2085 2088 2091 2094 
f 2097 2098 2095 2096 
f 2096 2095 2094 2078 
s 242
f 2097 2096 2078 2081 
s 243
f 2098 2097 2081 2085 
s off
f 2095 2098 2085 2094 
f 2101 2102 2099 2100 
f 2105 2106 2103 2104 
f 2107 2108 2103 2106 
f 2109 2107 2106 2105 
f 2110 2109 2105 2104 
f 2108 2110 2104 2103 
s 258
f 2111 2112 2108 2107 
f 2113 2111 2107 2109 
f 2114 2113 2109 2110 
f 2112 2114 2110 2108 
s off
f 2100 2099 2112 2111 
f 2101 2100 2111 2113 
f 2102 2101 2113 2114 
f 2099 2102 2114 2112 
f 2117 2118 2115 2116 
f 2119 2120 2118 2117 
f 2121 2122 2120 2119 
f 2119 2117 2116 2121 
f 2118 2120 2122 2115 
f 2125 2126 2123 2124 
f 2129 2130 2127 2128 
f 2131 2132 2127 2130 
f 2133 2131 2130 2129 
f 2134 2133 2129 2128 
f 2132 2134 2128 2127 
s 259
f 2135 2136 2132 2131 
f 2137 2135 2131 2133 
f 2138 2137 2133 2134 
f 2136 2138 2134 2132 
s off
f 2139 2140 2136 2135 
f 2135 2137 2141 2142 
f 2135 2142 2139 
f 2143 2141 2137 2138 
f 2138 2136 2140 2144 
f 2138 2144 2143 
f 2141 2143 2144 2142 
f 2124 2123 2140 2139 
f 2125 2124 2139 2142 
f 2126 2125 2142 2144 
f 2123 2126 2144 2140 
f 2147 2148 2145 2146 
f 2149 2150 2148 2147 
f 2151 2152 2150 2149 
f 2149 2147 2146 2151 
f 2148 2150 2152 2145 
f 2155 2156 2153 2154 
f 2157 2158 2156 2155 
f 2159 2160 2158 2157 
f 2157 2155 2154 2159 
f 2156 2158 2160 2153 
f 2163 2164 2161 2162 
f 2167 2168 2165 2166 
f 2169 2170 2165 2168 
f 2171 2169 2168 2167 
f 2172 2171 2167 2166 
f 2170 2172 2166 2165 
s 260
f 2173 2174 2170 2169 
f 2175 2173 2169 2171 
f 2176 2175 2171 2172 
f 2174 2176 2172 2170 
s off
f 2162 2161 2174 2173 
f 2163 2162 2173 2175 
f 2164 2163 2175 2176 
f 2161 2164 2176 2174 
s 261
f 2179 2180 2177 2178 
f 2182 2179 2178 2181 
s 262
f 2183 2184 2180 2179 
f 2185 2183 2179 2182 
f 2186 2187 2184 2183 
f 2188 2186 2183 2185 
s 263
f 2189 2190 2187 2186 
f 2191 2189 2186 2188 
s 264
f 2188 2185 2192 2191 
f 2185 2182 2181 2192 
s off
f 2184 2187 2190 2193 
f 2196 2197 2194 2195 
f 2195 2194 2193 2177 
s 261
f 2196 2195 2177 2180 
s 262
f 2197 2196 2180 2184 
s off
f 2194 2197 2184 2193 
f 2200 2201 2198 2199 
f 2204 2205 2202 2203 
f 2206 2207 2202 2205 
f 2208 2206 2205 2204 
f 2209 2208 2204 2203 
f 2207 2209 2203 2202 
s 204
f 2210 2211 2207 2206 
f 2212 2210 2206 2208 
f 2213 2212 2208 2209 
f 2211 2213 2209 2207 
s off
f 2211 2210 2214 2215 
f 2211 2215 2216 
f 2217 2214 2210 2212 
f 2212 2213 2218 2219 
f 2212 2219 2217 
f 2216 2218 2213 2211 
f 2217 2219 2215 2214 
f 2199 2198 2216 2215 
f 2200 2199 2215 2219 
f 2201 2200 2219 2218 
f 2198 2201 2218 2216 
f 2222 2223 2220 2221 
f 2226 2227 2224 2225 
f 2228 2229 2224 2227 
f 2230 2228 2227 2226 
f 2231 2230 2226 2225 
f 2229 2231 2225 2224 
s 166
f 2232 2233 2229 2228 
f 2234 2232 2228 2230 
f 2235 2234 2230 2231 
f 2233 2235 2231 2229 
s off
f 2221 2220 2233 2232 
f 2222 2221 2232 2234 
f 2223 2222 2234 2235 
f 2220 2223 2235 2233 
s 167
f 2238 2239 2236 2237 
f 2241 2238 2237 2240 
s 168
f 2242 2243 2239 2238 
f 2244 2242 2238 2241 
f 2245 2246 2243 2242 
f 2247 2245 2242 2244 
s 169
f 2248 2249 2246 2245 
f 2250 2248 2245 2247 
s 170
f 2247 2244 2251 2250 
f 2244 2241 2240 2251 
s off
f 2243 2246 2249 2252 
f 2255 2256 2253 2254 
f 2254 2253 2252 2236 
s 167
f 2255 2254 2236 2239 
s 168
f 2256 2255 2239 2243 
s off
f 2253 2256 2243 2252 
f 2259 2260 2257 2258 
f 2263 2264 2261 2262 
f 2265 2266 2261 2264 
f 2267 2265 2264 2263 
f 2268 2267 2263 2262 
f 2266 2268 2262 2261 
s 171
f 2269 2270 2266 2265 
f 2271 2269 2265 2267 
f 2272 2271 2267 2268 
f 2270 2272 2268 2266 
s off
f 2258 2257 2270 2269 
f 2259 2258 2269 2271 
f 2260 2259 2271 2272 
f 2257 2260 2272 2270 
f 2275 2276 2273 2274 
f 2277 2278 2276 2275 
f 2279 2280 2278 2277 
f 2277 2275 2274 2279 
f 2276 2278 2280 2273 
f 2283 2284 2281 2282 
f 2285 2286 2284 2283 
f 2287 2288 2286 2285 
f 2285 2283 2282 2287 
f 2284 2286 2288 2281 
f 2291 2292 2289 2290 
f 2295 2296 2293 2294 
f 2297 2298 2293 2296 
f 2299 2297 2296 2295 
f 2300 2299 2295 2294 
f 2298 2300 2294 2293 
s 204
f 2301 2302 2298 2297 
f 2303 2301 2297 2299 
f 2304 2303 2299 2300 
f 2302 2304 2300 2298 
s off
f 2302 2301 2305 2306 
f 2302 2306 2307 
f 2308 2305 2301 2303 
f 2303 2304 2309 2310 
f 2303 2310 2308 
f 2307 2309 2304 2302 
f 2308 2310 2306 2305 
f 2290 2289 2307 2306 
f 2291 2290 2306 2310 
f 2292 2291 2310 2309 
f 2289 2292 2309 2307 
f 2313 2314 2311 2312 
f 2317 2318 2315 2316 
f 2319 2320 2315 2318 
f 2321 2319 2318 2317 
f 2322 2321 2317 2316 
f 2320 2322 2316 2315 
s 155
f 2323 2324 2320 2319 
f 2325 2323 2319 2321 
f 2326 2325 2321 2322 
f 2324 2326 2322 2320 
s off
f 2324 2323 2327 2328 
f 2324 2328 2329 
f 2330 2327 2323 2325 
f 2325 2326 2331 2332 
f 2325 2332 2330 
f 2329 2331 2326 2324 
f 2330 2332 2328 2327 
f 2312 2311 2329 2328 
f 2313 2312 2328 2332 
f 2314 2313 2332 2331 
f 2311 2314 2331 2329 
s 156
f 2335 2336 2333 2334 
f 2338 2335 2334 2337 
s 157
f 2339 2340 2336 2335 
f 2341 2339 2335 2338 
f 2342 2343 2340 2339 
f 2344 2342 2339 2341 
s 158
f 2345 2346 2343 2342 
f 2347 2345 2342 2344 
s 159
f 2344 2341 2348 2347 
f 2341 2338 2337 2348 
s off
f 2340 2343 2346 2349 
f 2352 2353 2350 2351 
f 2351 2350 2349 2333 
s 156
f 2352 2351 2333 2336 
s 157
f 2353 2352 2336 2340 
s off
f 2350 2353 2340 2349 
s 160
f 2356 2357 2354 2355 
f 2359 2356 2355 2358 
s 161
f 2360 2361 2357 2356 
f 2362 2360 2356 2359 
f 2363 2364 2361 2360 
f 2365 2363 2360 2362 
s 162
f 2366 2367 2364 2363 
f 2368 2366 2363 2365 
s 163
f 2365 2362 2369 2368 
f 2362 2359 2358 2369 
s off
f 2361 2364 2367 2370 
f 2373 2374 2371 2372 
f 2372 2371 2370 2354 
s 160
f 2373 2372 2354 2357 
s 161
f 2374 2373 2357 2361 
s off
f 2371 2374 2361 2370 
f 2377 2378 2375 2376 
f 2381 2382 2379 2380 
f 2383 2384 2379 2382 
f 2385 2383 2382 2381 
f 2386 2385 2381 2380 
f 2384 2386 2380 2379 
s 155
f 2387 2388 2384 2383 
f 2389 2387 2383 2385 
f 2390 2389 2385 2386 
f 2388 2390 2386 2384 
s off
f 2388 2387 2391 2392 
f 2388 2392 2393 
f 2394 2391 2387 2389 
f 2389 2390 2395 2396 
f 2389 2396 2394 
f 2393 2395 2390 2388 
f 2394 2396 2392 2391 
f 2376 2375 2393 2392 
f 2377 2376 2392 2396 
f 2378 2377 2396 2395 
f 2375 2378 2395 2393 
s 156
f 2399 2400 2397 2398 
f 2402 2399 2398 2401 
s 157
f 2403 2404 2400 2399 
f 2405 2403 2399 2402 
f 2406 2407 2404 2403 
f 2408 2406 2403 2405 
s 158
f 2409 2410 2407 2406 
f 2411 2409 2406 2408 
s 159
f 2408 2405 2412 2411 
f 2405 2402 2401 2412 
s off
f 2404 2407 2410 2413 
f 2416 2417 2414 2415 
f 2415 2414 2413 2397 
s 156
f 2416 2415 2397 2400 
s 157
f 2417 2416 2400 2404 
s off
f 2414 2417 2404 2413 
s 160
f 2420 2421 2418 2419 
f 2423 2420 2419 2422 
s 161
f 2424 2425 2421 2420 
f 2426 2424 2420 2423 
f 2427 2428 2425 2424 
f 2429 2427 2424 2426 
s 162
f 2430 2431 2428 2427 
f 2432 2430 2427 2429 
s 163
f 2429 2426 2433 2432 
f 2426 2423 2422 2433 
s off
f 2425 2428 2431 2434 
f 2437 2438 2435 2436 
f 2436 2435 2434 2418 
s 160
f 2437 2436 2418 2421 
s 161
f 2438 2437 2421 2425 
s off
f 2435 2438 2425 2434 
f 2439 2440 2441 2442 
f 2443 2444 2445 2446 
f 2447 2448 2445 2444 
f 2449 2447 2444 2443 
f 2450 2449 2443 2446 
f 2448 2450 2446 2445 
s 280
f 2451 2452 2448 2447 
f 2453 2451 2447 2449 
f 2454 2453 2449 2450 
f 2452 2454 2450 2448 
s off
f 2442 2441 2452 2451 
f 2439 2442 2451 2453 
f 2440 2439 2453 2454 
f 2441 2440 2454 2452 
f 2455 2456 2457 2458 
f 2459 2460 2461 2462 
f 2463 2464 2465 2466 
f 2466 2455 2458 2463 
f 2456 2465 2464 2457 
s 281
f 2467 2468 2456 2455 
f 2469 2467 2455 2466 
f 2470 2469 2466 2465 
f 2468 2470 2465 2456 
s off
f 2462 2461 2468 2467 
f 2459 2462 2467 2469 
f 2460 2459 2469 2470 
f 2461 2460 2470 2468 
# 1947 polygons - 24 triangles

#
# object sehir_plan_Sehir
#

v  -25.000000 0.000000 25.000000
v  25.000000 0.000000 -25.000000
v  -13.540256 -0.410400 -10.567606
v  -2.852714 -0.410400 8.914135
v  -24.300411 -0.410400 -3.998791
v  -3.785456 -0.410400 -4.888479
v  -10.257504 -0.410400 -6.243203
v  25.000000 0.000000 25.000000
v  -9.937245 -0.410400 1.462259
v  5.000000 0.000000 5.000000
v  0.000000 0.000000 -5.000000
v  0.179508 -0.410400 0.565280
v  -25.000000 0.000000 -15.850014
v  -10.733222 -0.410400 -8.443505
v  -10.000000 0.000000 -10.000000
v  -25.000000 0.000000 -25.000000
v  -12.538321 -0.410400 -7.291145
v  -5.000000 0.000000 10.000000
v  0.468496 -0.410400 6.701972
v  -18.974068 -0.410400 -2.426685
v  -5.891945 -0.410400 6.169105
v  -20.337059 -0.410400 -14.260836
v  -1.124712 -0.410400 -2.521937
v  -14.460419 -0.410400 -11.438886
v  -8.531482 -0.410400 -5.256903
v  -19.415993 0.000000 -2.367012
v  -5.637463 -0.410400 -4.644061
v  -10.935705 0.000000 -7.903344
v  -16.869589 0.000000 -12.507595
v  -0.559677 -0.410400 8.104321
v  -5.236961 0.000000 7.203244
v  -12.821156 0.000000 -7.555533
v  -9.942014 0.000000 1.710060
v  -7.774321 0.000000 -5.098625
v  -3.881704 0.000000 -4.974273
v  0.000000 0.000000 10.000000
v  -13.719320 0.000000 -10.901751
v  0.902480 0.000000 2.033189
v  -20.143267 0.000000 -14.429409
v  0.967106 0.000000 7.203244
v  -0.703174 0.000000 -2.466871
v  -10.437352 0.000000 -6.548464
v  -25.000000 0.000000 -0.000000
v  -16.055967 -0.410400 -11.894293
v  -22.957363 -0.410400 -15.164464
v  -15.189320 0.000000 -0.293337
v  -10.776496 0.000000 -8.674896
v  -2.070302 0.000000 9.594395
v  -15.379502 -0.410400 -0.664538
v  -3.104313 0.000000 -4.817136
v  -10.885084 -0.410400 -7.707554
v  0.596096 -0.410400 3.175881
v  -0.596722 0.000000 9.050070
v  -12.455069 0.000000 -7.505415
v  -25.000000 0.000000 -4.015168
v  -5.818593 0.000000 -4.687885
v  -7.587415 0.000000 3.831592
v  -8.593125 -0.410400 2.386914
v  -15.000000 0.000000 -15.000000
v  -14.683996 0.000000 -11.815178
v  -4.139682 -0.410400 8.061188
v  0.327821 0.000000 -0.398849
v  -23.591980 0.000000 -15.720982
v  -9.080400 0.000000 -5.591891
v  -3.411615 0.000000 9.016155
v  -0.340779 -0.410400 -1.072681
v  -9.003151 0.000000 2.279821
v  -24.300411 -0.410400 -15.287542
v  -3.033332 -0.410400 -4.534792
v  -13.237249 -0.410400 -9.248108
v  -9.637473 -0.410400 -5.779527
v  -14.090683 0.000000 -11.473584
v  -17.403175 0.000000 -1.554763
v  -10.974764 -0.410400 -8.380244
v  -17.583759 -0.410400 -1.913587
v  -2.428757 -0.410400 8.982788
v  1.178882 0.000000 4.313364
v  -11.029723 0.000000 -8.608575
v  -10.634915 -0.410400 -6.795839
v  -13.894483 -0.410400 -11.113054
v  -1.505718 -0.410400 8.740889
v  -10.757843 0.000000 -7.153836
v  -17.085938 -0.410400 -12.387262
v  -2.287353 0.000000 -4.255289
v  -1.840715 -0.410400 -3.440302
v  -23.447283 0.000000 -3.429285
v  -13.401656 0.000000 -9.518421
v  0.625214 -0.410400 6.126872
v  -2.514767 0.000000 9.522421
v  -13.020496 0.000000 -7.979462
v  -7.691294 -0.410400 3.485897
v  -6.004091 -0.410400 -4.640467
v  -12.073927 -0.410400 -7.480209
v  -12.873677 -0.410400 -7.780160
v  1.210537 0.000000 5.894717
v  -12.683535 -0.410400 -7.375793
v  -10.087657 0.000000 -6.200089
v  -20.844917 0.000000 -14.773640
v  -7.133626 -0.410400 -4.833485
v  0.329139 0.000000 8.228399
v  0.710579 -0.410400 4.715714
v  -5.047795 0.000000 -4.754960
v  -23.721352 -0.410400 -3.743129
v  -19.667782 -0.410400 -13.932488
v  -5.701023 0.000000 6.644600
v  -1.453814 0.000000 -3.429662
v  -4.527876 -0.410400 -4.870507
v  -0.120038 0.000000 -1.472426
v  -10.873942 -0.410400 -7.537719
v  -11.736953 -0.410400 0.722534
v  -10.761989 0.000000 -8.589167
v  -23.612801 -0.410400 -15.296738
v  -7.002769 0.000000 -4.890241
v  -14.942425 -0.410400 -0.448696
v  -8.532872 0.000000 2.679445
v  -15.647540 0.000000 -0.519621
v  -3.415055 -0.410400 -4.763754
v  -21.842388 -0.410400 -3.166410
v  -9.041704 -0.410400 2.005729
v  -24.279123 0.000000 -15.859653
v  -3.492632 0.000000 -4.947895
v  0.210959 -0.410400 7.206803
v  -3.279188 -0.410400 8.722914
v  -12.668918 0.000000 -7.466790
v  -10.719384 -0.410400 -8.361732
v  -10.409727 -0.410400 -6.415194
v  -15.880608 0.000000 -12.139624
v  -1.982514 -0.410400 8.919528
v  -17.436525 0.000000 -12.809433
v  -12.334340 -0.410400 -7.327988
v  0.664138 0.000000 0.769690
v  -11.828783 0.000000 0.934552
v  -5.449297 -0.410400 6.701972
v  -13.226243 0.000000 -8.909281
v  -18.614483 0.000000 -2.102857
v  -13.687446 -0.410400 -10.861986
v  -0.140033 -0.410400 7.679823
v  0.304054 -0.410400 1.156557
v  -8.060897 0.000000 3.198524
v  -9.627741 0.000000 -5.882047
v  -1.023172 -0.410400 8.463580
v  -13.873631 0.000000 -11.210371
v  -24.392925 0.000000 -3.747138
v  -10.924024 0.000000 -7.725293
v  -1.602472 0.000000 9.528074
v  -3.708178 -0.410400 8.431231
v  -4.319012 0.000000 8.181297
v  -14.366143 0.000000 -11.672737
v  -10.859398 0.000000 -8.694679
v  -4.156576 -0.410400 -4.913640
v  -2.961873 0.000000 9.321949
v  -18.209541 -0.410400 -2.174719
v  0.700695 -0.410400 5.453824
v  -14.157232 -0.410400 -11.303018
v  -9.115389 -0.410400 -5.502759
v  -8.142928 -0.410400 2.882041
v  -5.434230 0.000000 -4.691653
v  -11.876452 0.000000 -7.900329
v  -0.826020 -0.410400 -2.051432
v  -12.934580 0.000000 -7.733583
v  1.131404 0.000000 6.600324
v  -16.545162 -0.410400 -12.099353
v  -16.594765 -0.410400 -1.392940
v  -6.375390 -0.410400 -4.686475
v  -9.489565 -0.410400 1.703801
v  -8.468246 0.000000 -5.334142
v  -13.069930 -0.410400 -8.667075
v  -2.635793 -0.410400 -4.227832
v  -19.434645 0.000000 -14.028090
v  -0.141290 -0.410400 -0.549339
v  -4.270967 0.000000 -4.929054
v  -10.812298 -0.410400 -8.462376
v  -7.869576 -0.410400 -5.032254
v  0.697110 0.000000 7.732497
v  -9.472677 0.000000 1.963287
v  -22.819344 -0.410400 -3.439942
v  -1.102611 0.000000 9.340794
v  -2.704123 0.000000 -4.577098
v  -10.853634 -0.410400 -7.889970
v  -10.567923 0.000000 -6.743660
v  -12.791725 -0.410400 -7.545627
v  -10.411728 0.000000 1.500357
v  -13.437277 -0.410400 -10.244472
v  -17.958427 0.000000 -1.829092
v  -2.234300 -0.410400 -3.857969
v  -0.110805 0.000000 8.673431
v  -20.983505 -0.410400 -14.503457
v  1.220899 0.000000 5.120902
v  -22.892967 0.000000 -15.502235
v  -16.356730 0.000000 -12.292616
v  -6.338548 -0.410400 5.540087
v  -7.113556 0.000000 4.535691
v  -0.568483 -0.410400 -1.573378
v  -3.863994 0.000000 8.628212
v  -12.182058 0.000000 -7.665000
v  -1.466899 -0.410400 -2.989925
v  -22.290604 -0.410400 -14.955812
v  -5.268860 -0.410400 -4.704447
v  -22.423067 0.000000 -3.142520
v  0.670501 -0.410400 3.945439
v  -18.991859 -0.410400 -13.549687
v  -1.866439 0.000000 -3.867534
v  -20.353724 0.000000 -2.621558
v  -15.601815 -0.410400 -11.748361
v  -17.054127 -0.410400 -1.651916
v  -0.390034 0.000000 -1.973605
v  -13.611360 0.000000 -10.562984
v  -10.779590 -0.410400 -7.185829
v  -13.533357 0.000000 -10.209332
v  -14.802963 -0.410400 -11.543301
v  0.031600 -0.410400 -0.003351
v  -1.061914 0.000000 -2.957499
v  -10.902733 0.000000 -8.094584
v  -4.573705 -0.410400 7.634894
v  -10.277766 0.000000 -6.368153
v  -20.000000 0.000000 -15.000000
v  -21.522638 0.000000 -15.027998
v  -10.385285 -0.410400 1.262232
v  -6.169230 0.000000 5.985153
v  -10.076168 -0.410400 -6.082893
v  0.118681 0.000000 -0.947508
v  -7.239300 -0.410400 4.157507
v  -6.207854 0.000000 -4.736119
v  -11.207500 -0.410400 -8.197829
v  -19.868530 -0.410400 -2.669486
v  -10.534272 -0.410400 -6.601383
v  -11.782423 -0.410400 -7.704679
v  -10.673434 0.000000 -6.947524
v  -16.070906 0.000000 -0.762297
v  -13.362873 -0.410400 -9.907138
v  -11.284600 -0.410400 0.911239
v  -16.921591 0.000000 -1.283259
v  1.100878 0.000000 3.506579
v  0.509074 0.000000 0.173551
v  -15.043112 0.000000 -11.924644
v  -24.241814 -0.410400 -15.304106
v  -10.793264 0.000000 -8.453321
v  -17.684761 -0.410400 -12.745803
v  -9.868592 -0.410400 -5.928155
v  -15.783332 -0.410400 -0.896017
v  -11.273718 0.000000 -8.417336
v  -9.870040 0.000000 -6.037865
v  0.794708 0.000000 1.389570
v  0.406853 -0.410400 1.770477
v  -11.354548 0.000000 1.132386
v  -6.604840 0.000000 -4.804701
v  -12.940173 -0.410400 -8.058906
v  -6.754058 -0.410400 -4.751893
v  -18.064318 0.000000 -13.185318
v  -10.749216 -0.410400 -8.232155
v  -9.385507 -0.410400 -5.637549
v  -9.363585 0.000000 -5.733201
v  -10.715429 -0.410400 -6.992631
v  -10.825107 0.000000 -7.356380
v  -13.090209 0.000000 -8.271691
v  -21.627621 -0.410400 -14.719301
v  -8.829815 -0.410400 -5.375698
v  -7.394857 0.000000 -4.989346
v  -8.781012 0.000000 -5.458683
v  -7.507622 -0.410400 -4.928017
v  0.503003 -0.410400 2.439931
v  -8.213018 -0.410400 -5.142962
v  -22.197912 0.000000 -15.254284
v  -5.000000 0.000000 -5.000000
v  -8.134378 0.000000 -5.214688
v  -24.938566 0.000000 -15.867378
v  -6.640450 0.000000 5.267864
v  1.003282 0.000000 2.735027
v  -13.301410 -0.410400 -9.570165
v  -18.325462 -0.410400 -13.143700
v  -5.010242 -0.410400 7.174454
v  -18.736010 0.000000 -13.602463
v  -6.788025 -0.410400 4.855896
v  -4.659852 0.000000 -4.845021
v  -4.776668 0.000000 7.698583
v  -4.898818 -0.410400 -4.790352
v  -13.468920 0.000000 -9.856058
v  -13.155024 0.000000 -8.588791
v  -13.001997 -0.410400 -8.361373
v  -10.801156 -0.410400 -8.069868
v  -10.831168 -0.410400 -7.369503
v  -10.879181 0.000000 -7.548938
v  -10.847716 0.000000 -8.283184
v  -10.834223 -0.410400 1.084849
v  -15.183788 -0.410400 -11.638910
v  -10.882383 0.000000 1.314393
v  -15.442359 0.000000 -12.024879
v  -13.315174 0.000000 -9.211684
v  -13.154757 -0.410400 -8.955524
v  -20.841705 -0.410400 -2.912826
v  -21.373980 0.000000 -2.876669
v  -11.485167 -0.410400 -7.958262
v  -11.564816 0.000000 -8.166180
v  -16.179790 -0.410400 -1.139896
v  -16.486544 0.000000 -1.017974
v  -19.531128 -0.410400 -13.855095
v  -13.326452 -0.410400 -9.707465
v  -20.912350 -0.410400 -14.476750
v  -13.495174 0.000000 -10.000000
v  -20.000000 0.000000 -14.348271
v  -21.448040 0.000000 -15.000000
v  -0.453984 -0.410400 7.997406
v  -5.000000 0.000000 7.458246
v  -20.000000 0.000000 -2.525540
v  -19.531128 -0.410400 -2.577899
v  -0.020820 -0.410400 -0.168894
v  -5.223270 -0.410400 -4.715030
v  -9.992556 -0.410400 1.437565
v  0.000000 0.000000 8.561345
v  -6.813535 0.000000 5.000000
v  -7.431853 0.000000 -5.000000
v  -7.542911 -0.410400 -4.938180
v  -10.000000 0.000000 1.684172
v  0.704578 -0.410400 4.600391
v  -14.761841 -0.410400 -0.382713
v  -14.386502 0.000000 -0.000000
v  -5.000000 0.000000 -4.766056
v  -14.176652 -0.410400 -0.168894
v  0.000000 0.000000 -1.208475
v  -0.453984 -0.410400 -1.321607
v  0.454119 0.000000 -0.000000
v  5.000000 0.000000 -0.000000
v  -10.000000 0.000000 10.000000
v  25.000000 0.000000 15.000000
v  15.000000 0.000000 5.000000
v  -25.000000 0.000000 15.000000
v  -5.223270 -0.410400 6.945208
v  25.000000 0.000000 20.000000
v  20.000000 0.000000 10.000000
v  -6.953124 -0.410400 4.600391
v  -20.000000 0.000000 -0.000000
v  -25.000000 0.000000 10.000000
v  25.000000 0.000000 5.000000
v  20.000000 0.000000 25.000000
v  -25.000000 0.000000 5.000000
v  15.000000 0.000000 -25.000000
v  -20.000000 0.000000 25.000000
v  0.000000 0.000000 25.000000
v  0.000000 0.000000 -10.000000
v  25.000000 0.000000 -5.000000
v  -20.000000 0.000000 -25.000000
v  -5.000000 0.000000 25.000000
v  5.000000 0.000000 -20.000000
v  25.000000 0.000000 -15.000000
v  -15.000000 0.000000 25.000000
v  0.000000 0.000000 -15.000000
v  10.000000 0.000000 -20.000000
v  25.000000 0.000000 -10.000000
v  -10.000000 0.000000 25.000000
v  -15.000000 0.000000 -25.000000
v  20.000000 0.000000 -5.000000
v  -5.000000 0.000000 -25.000000
v  1.214608 0.000000 5.000000
v  -10.000000 0.000000 -25.000000
v  15.000000 0.000000 -0.000000
v  10.000000 0.000000 -5.000000
v  25.000000 0.000000 -0.000000
v  -25.000000 0.000000 20.000000
v  20.000000 0.000000 -0.000000
v  -5.000000 0.000000 -10.000000
v  15.000000 0.000000 25.000000
v  10.000000 0.000000 25.000000
v  5.000000 0.000000 -25.000000
v  5.000000 -0.298872 25.000000
v  10.000000 0.000000 -25.000000
v  0.000000 0.000000 -25.000000
v  25.000000 0.000000 -20.000000
v  0.000000 0.000000 -20.000000
v  20.000000 0.000000 -25.000000
v  5.000000 0.000000 -5.000000
v  10.000000 0.000000 -0.000000
v  10.000000 0.000000 5.000000
v  15.000000 0.000000 -5.000000
v  5.000000 0.000000 -10.000000
v  5.000000 0.000000 -15.000000
v  25.000000 0.000000 10.000000
v  10.000000 0.000000 -10.000000
v  20.000000 0.000000 5.000000
v  -25.000000 0.000000 -20.000000
v  20.000000 0.000000 -20.000000
v  15.000000 0.000000 -15.000000
v  15.000000 0.000000 -20.000000
v  15.000000 0.000000 -10.000000
v  20.000000 0.000000 -15.000000
v  20.000000 0.000000 -10.000000
v  10.000000 0.000000 -15.000000
v  20.000000 0.000000 15.000000
v  15.000000 0.000000 10.000000
v  20.000000 0.000000 20.000000
v  15.000000 0.000000 15.000000
v  15.000000 0.000000 20.000000
v  10.000000 0.000000 10.000000
v  10.000000 0.000000 15.000000
v  5.000000 0.000000 10.000000
v  -20.000000 0.000000 20.000000
v  -20.000000 0.000000 5.000000
v  -20.000000 0.000000 15.000000
v  -20.000000 0.000000 10.000000
v  -15.000000 0.000000 20.000000
v  -15.000000 0.000000 15.000000
v  -10.000000 0.000000 20.000000
v  10.000000 0.000000 20.000000
v  -5.000000 0.000000 20.000000
v  0.000000 0.000000 20.000000
v  5.000000 0.000000 20.000000
v  -10.000000 0.000000 15.000000
v  0.000000 0.000000 15.000000
v  5.000000 0.000000 15.000000
v  -5.000000 0.000000 15.000000
v  -5.000000 0.000000 -20.000000
v  -10.000000 0.000000 -20.000000
v  -20.000000 0.000000 -20.000000
v  -15.000000 0.000000 -20.000000
v  -5.000000 0.000000 -15.000000
v  -10.000000 0.000000 -15.000000
v  -9.992556 -0.410400 -6.020564
v  -15.000000 0.000000 10.000000
v  -10.000000 0.000000 5.000000
v  -15.000000 0.000000 5.000000
v  -15.000000 0.000000 -0.224163
v  -15.000000 0.000000 -0.000000
v  -10.000000 0.000000 -6.134745
v  -15.000000 0.000000 -11.911503
v  -14.761841 -0.410400 -11.530766
# 424 vertices

o sehir_plan_Sehir
g sehir_plan_Sehir
usemtl 11___Defadwdlt
s 1
f 2794 2846 2799 
f 2802 2796 2867 
f 2481 2809 2830 
f 2884 2830 2809 
f 2799 2857 2794 
f 2807 2865 2828 
f 2793 2780 2888 
f 2842 2841 2792 
f 2480 2658 2565 
f 2481 2734 2505 
f 2480 2792 2791 
f 2481 2672 2576 
f 2809 2816 2884 
f 2830 2734 2481 
f 2485 2604 2502 
f 2892 2485 2517 
f 2686 2882 2849 
f 2485 2548 2619 
f 2834 2875 2874 
f 2488 2621 2535 
f 2506 2480 2510 
f 2801 2866 2889 
f 2888 2780 2783 
f 2849 2590 2533 
f 2480 2704 2601 
f 2485 2618 2542 
f 2734 2485 2712 
s off
f 2580 2788 2786 
f 2588 2646 2556 
s 1
f 2716 2526 2572 
s off
f 2678 2723 2552 
f 2619 2548 2544 
f 2542 2618 2624 
f 2719 2742 2740 
f 2615 2647 2551 
f 2561 2626 2609 
f 2684 2741 2745 
f 2564 2651 2630 
f 2600 2563 2665 
f 2786 2602 2580 
f 2585 2609 2626 
f 2610 2712 2709 
f 2569 2718 2716 
f 2577 2620 2505 
f 2638 2655 2672 
s 1
f 2506 2647 2615 
f 2488 2506 2518 
s off
f 2725 2748 2749 
f 2550 2606 2612 
f 2601 2704 2681 
f 2565 2658 2571 
f 2671 2740 2742 
f 2667 2726 2733 
f 2592 2607 2570 
f 2571 2623 2565 
f 2629 2663 2578 
f 2576 2672 2655 
f 2886 2709 2712 
f 2650 2698 2549 
s 1
f 2513 2556 2613 
s off
f 2674 2755 2757 
f 2533 2590 2582 
f 2605 2654 2545 
s 1
f 2801 2654 2496 
s off
f 2683 2753 2750 
s 1
f 2892 2581 2614 
s off
f 2535 2621 2593 
f 2551 2598 2615 
f 2633 2675 2543 
f 2520 2591 2476 
s 1
f 2481 2591 2520 
s off
f 2720 2750 2753 
f 2714 2731 2738 
s 1
f 2506 2644 2570 
s off
f 2570 2644 2592 
s 1
f 2485 2502 2548 
s off
f 2524 2594 2487 
f 2613 2556 2646 
f 2693 2716 2718 
f 2572 2627 2497 
f 2737 2689 2661 
s 1
f 2793 2689 2737 
s off
f 2589 2635 2645 
s 1
f 2529 2742 2660 
f 2485 2529 2530 
s off
f 2597 2660 2514 
s 1
f 2529 2660 2757 
s off
f 2593 2616 2535 
s 1
f 2481 2676 2578 
s off
f 2578 2676 2629 
s 1
f 2849 2659 2687 
s off
f 2687 2733 2726 
s 1
f 2712 2729 2735 
s off
f 2636 2729 2727 
s 1
f 2488 2664 2617 
s off
f 2617 2664 2531 
f 2640 2776 2791 
s 1
f 2527 2585 2783 
s off
f 2503 2645 2635 
f 2706 2582 2590 
f 2476 2587 2520 
f 2653 2700 2679 
f 2625 2727 2729 
s 1
f 2485 2612 2507 
s off
f 2507 2612 2606 
s 1
f 2801 2586 2654 
s off
f 2702 2765 2764 
f 2544 2642 2619 
f 2545 2622 2605 
f 2546 2474 2559 
f 2703 2738 2731 
s 1
f 2480 2738 2703 
s off
f 2628 2665 2563 
s 1
f 2502 2524 2628 
s off
f 2549 2696 2650 
f 2494 2624 2618 
f 2500 2611 2523 
s 1
f 2892 2614 2698 
s off
f 2724 2752 2751 
f 2553 2632 2499 
s 1
f 2481 2648 2554 
s off
f 2554 2648 2539 
f 2555 2666 2682 
s 1
f 2513 2761 2669 
s off
f 2669 2761 2760 
f 2679 2677 2653 
s 1
f 2485 2677 2679 
s off
f 2558 2489 2510 
f 2559 2518 2546 
s 1
f 2488 2518 2559 
f 2748 2560 2502 
s off
f 2502 2630 2651 
f 2661 2743 2737 
f 2562 2497 2627 
f 2697 2762 2763 
f 2637 2749 2748 
f 2510 2631 2558 
s 1
f 2480 2631 2510 
s off
f 2566 2487 2594 
f 2567 2685 2477 
s 1
f 2892 2698 2512 
s off
f 2509 2568 2492 
s 1
f 2529 2686 2770 
s off
f 2643 2782 2781 
f 2523 2656 2500 
s 1
f 2506 2656 2523 
s off
f 2522 2670 2547 
f 2641 2744 2746 
s 1
f 2734 2744 2641 
s off
f 2573 2475 2525 
f 2492 2574 2509 
s 1
f 2793 2501 2575 
s off
f 2575 2501 2603 
f 2511 2682 2666 
s 1
f 2481 2682 2511 
s off
f 2777 2746 2744 
s 1
f 2792 2691 2532 
s off
f 2532 2691 2536 
f 2521 2579 2614 
f 2688 2754 2756 
s 1
f 2892 2517 2581 
s off
f 2581 2517 2484 
f 2582 2515 2533 
s 1
f 2728 2716 2572 
s off
f 2583 2728 2730 
f 2584 2519 2586 
f 2537 2585 2528 
s 1
f 2585 2645 2783 
f 2801 2516 2586 
s off
f 2586 2516 2584 
f 2587 2539 2648 
f 2695 2760 2761 
f 2528 2589 2537 
f 2590 2736 2706 
s 1
f 2849 2736 2590 
s off
f 2591 2505 2620 
s 1
f 2481 2505 2591 
s off
f 2489 2592 2644 
f 2474 2593 2621 
s 1
f 2502 2594 2524 
s off
f 2594 2502 2566 
f 2484 2595 2581 
f 2596 2477 2685 
s 1
f 2529 2757 2705 
s off
f 2705 2757 2755 
f 2598 2546 2518 
f 2499 2599 2553 
s 1
f 2742 2599 2660 
s off
f 2487 2600 2524 
f 2508 2713 2608 
s 1
f 2480 2713 2508 
s off
f 2715 2756 2754 
s 1
f 2783 2715 2786 
s off
f 2603 2491 2575 
s 1
f 2485 2758 2604 
s off
f 2604 2758 2759 
s 1
f 2654 2605 2496 
s off
f 2496 2605 2622 
f 2606 2473 2507 
f 2607 2772 2779 
f 2482 2608 2713 
f 2609 2527 2561 
s 1
f 2527 2609 2585 
s off
f 2534 2722 2721 
s 1
f 2712 2722 2729 
s off
f 2611 2551 2647 
f 2612 2542 2550 
s 1
f 2485 2542 2612 
s off
f 2525 2613 2573 
s 1
f 2513 2613 2525 
s off
f 2614 2498 2521 
s 1
f 2707 2683 2614 
f 2506 2615 2518 
s off
f 2518 2615 2598 
f 2616 2531 2664 
f 2773 2745 2741 
s 1
f 2488 2745 2773 
f 2485 2530 2618 
s off
f 2618 2530 2494 
s 1
f 2485 2619 2517 
s off
f 2517 2619 2642 
f 2620 2476 2591 
s 1
f 2488 2559 2621 
s off
f 2621 2559 2474 
f 2622 2490 2496 
f 2623 2558 2631 
f 2624 2550 2542 
f 2541 2721 2722 
f 2626 2528 2585 
f 2627 2526 2562 
s 1
f 2526 2627 2572 
s off
f 2711 2763 2762 
s 1
f 2502 2628 2711 
s off
f 2493 2629 2676 
f 2630 2560 2564 
s 1
f 2560 2630 2502 
s off
f 2631 2565 2623 
s 1
f 2480 2565 2631 
s off
f 2632 2514 2660 
f 2710 2764 2765 
f 2634 2562 2526 
f 2635 2479 2503 
f 2504 2735 2732 
s 1
f 2734 2728 2572 
s off
f 2540 2759 2758 
f 2539 2638 2554 
f 2639 2770 2766 
s 1
f 2529 2770 2639 
s off
f 2536 2640 2532 
s 1
f 2734 2641 2505 
s off
f 2505 2641 2577 
f 2642 2484 2517 
f 2495 2732 2735 
s 1
f 2506 2510 2644 
s off
f 2644 2510 2489 
f 2645 2537 2589 
s 1
f 2585 2537 2645 
s off
f 2646 2573 2613 
f 2647 2523 2611 
s 1
f 2506 2523 2647 
f 2481 2520 2648 
s off
f 2648 2520 2587 
f 2649 2521 2498 
s 1
f 2698 2650 2512 
s off
f 2512 2650 2696 
f 2651 2566 2502 
s 1
f 2783 2756 2715 
s off
f 2652 2783 2778 
f 2473 2653 2677 
s 1
f 2699 2702 2654 
s off
f 2654 2543 2675 
f 2655 2555 2576 
f 2779 2570 2607 
s 1
f 2506 2570 2779 
s off
f 2768 2492 2568 
s 1
f 2823 2480 2547 
s off
f 2823 2547 2670 
s 1
f 2849 2533 2659 
s off
f 2659 2533 2515 
s 1
f 2599 2499 2660 
s off
f 2660 2499 2632 
f 2491 2661 2689 
f 2527 2662 2692 
s 1
f 2780 2527 2783 
s off
f 2663 2790 2789 
f 2664 2535 2616 
s 1
f 2488 2535 2664 
s off
f 2665 2524 2600 
s 1
f 2524 2665 2628 
s off
f 2666 2493 2511 
f 2515 2667 2659 
f 2497 2668 2572 
s 1
f 2513 2669 2556 
s off
f 2556 2669 2588 
f 2670 2784 2823 
f 2766 2671 2639 
f 2672 2554 2638 
s 1
f 2481 2554 2672 
s off
f 2774 2496 2490 
s 1
f 2801 2496 2774 
s off
f 2514 2674 2597 
f 2675 2545 2654 
s 1
f 2481 2511 2676 
s off
f 2676 2511 2493 
f 2677 2507 2473 
s 1
f 2485 2507 2677 
s off
f 2579 2751 2752 
f 2557 2747 2739 
s 1
f 2485 2747 2758 
s off
f 2894 2494 2530 
f 2681 2482 2601 
f 2682 2576 2555 
s 1
f 2481 2576 2682 
f 2683 2498 2614 
s off
f 2498 2683 2649 
f 2531 2684 2617 
f 2685 2512 2596 
s 1
f 2892 2512 2685 
f 2686 2568 2509 
f 2686 2849 2687 
s off
f 2568 2771 2768 
s 1
f 2686 2771 2568 
s off
f 2778 2688 2652 
s 1
f 2793 2575 2689 
s off
f 2689 2575 2491 
f 2477 2690 2567 
f 2789 2578 2663 
s 1
f 2789 2481 2578 
s off
f 2692 2561 2527 
f 2526 2693 2634 
s 1
f 2716 2693 2526 
s off
f 2694 2544 2548 
f 2490 2775 2774 
f 2696 2596 2512 
f 2563 2697 2628 
s 1
f 2614 2724 2698 
s off
f 2698 2552 2723 
f 2699 2586 2519 
s 1
f 2586 2699 2654 
s off
f 2739 2540 2557 
f 2701 2580 2602 
f 2543 2702 2633 
s 1
f 2702 2543 2654 
f 2480 2703 2547 
s off
f 2547 2703 2522 
s 1
f 2791 2792 2532 
s off
f 2791 2532 2640 
f 2530 2893 2894 
s 1
f 2529 2893 2530 
s off
f 2538 2706 2736 
s 1
f 2581 2707 2614 
s off
f 2707 2581 2595 
f 2708 2553 2599 
f 2709 2541 2610 
f 2519 2710 2699 
s 1
f 2502 2711 2548 
s off
f 2548 2711 2694 
f 2712 2892 2886 
s 1
f 2485 2892 2712 
f 2480 2601 2713 
s off
f 2713 2601 2482 
f 2608 2714 2508 
s 1
f 2715 2602 2786 
s off
f 2602 2715 2701 
s 1
f 2728 2583 2716 
s off
f 2716 2583 2569 
f 2717 2564 2560 
f 2718 2634 2693 
f 2599 2719 2708 
s 1
f 2742 2719 2599 
s off
f 2595 2720 2707 
f 2721 2625 2534 
s 1
f 2712 2610 2722 
s off
f 2722 2610 2541 
f 2723 2549 2698 
f 2552 2724 2678 
s 1
f 2724 2552 2698 
f 2748 2725 2560 
s off
f 2560 2725 2717 
f 2726 2657 2687 
f 2727 2495 2636 
s 1
f 2781 2734 2504 
s off
f 2781 2504 2643 
s 1
f 2722 2534 2729 
s off
f 2729 2534 2625 
f 2730 2569 2583 
f 2731 2522 2703 
f 2732 2643 2504 
f 2733 2659 2667 
s 1
f 2659 2733 2687 
f 2734 2735 2504 
f 2734 2712 2735 
s off
f 2735 2636 2495 
s 1
f 2729 2636 2735 
s off
f 2736 2483 2538 
s 1
f 2849 2483 2736 
f 2780 2662 2527 
s off
f 2662 2780 2800 
f 2738 2508 2714 
s 1
f 2480 2508 2738 
s off
f 2700 2767 2769 
f 2740 2708 2719 
f 2797 2603 2501 
f 2742 2639 2671 
s 1
f 2529 2639 2742 
s off
f 2743 2800 2780 
f 2744 2787 2777 
s 1
f 2734 2787 2744 
s off
f 2745 2617 2684 
s 1
f 2488 2617 2745 
s off
f 2746 2577 2641 
s 1
f 2769 2485 2679 
s off
f 2769 2679 2700 
f 2748 2604 2637 
s 1
f 2604 2748 2502 
s off
f 2749 2717 2725 
f 2750 2649 2683 
f 2751 2678 2724 
s 1
f 2614 2752 2724 
s off
f 2752 2614 2579 
f 2753 2707 2720 
s 1
f 2707 2753 2683 
s off
f 2754 2701 2715 
f 2755 2680 2705 
f 2756 2652 2688 
s 1
f 2783 2652 2756 
s off
f 2757 2597 2674 
s 1
f 2660 2597 2757 
f 2747 2557 2758 
s off
f 2758 2557 2540 
f 2759 2637 2604 
f 2760 2588 2669 
s 1
f 2513 2774 2761 
s off
f 2761 2673 2695 
f 2762 2694 2711 
f 2763 2628 2697 
s 1
f 2628 2763 2711 
s off
f 2764 2633 2702 
f 2765 2699 2710 
s 1
f 2699 2765 2702 
s off
f 2574 2766 2770 
f 2767 2739 2747 
f 2657 2768 2771 
f 2747 2769 2767 
s 1
f 2485 2769 2747 
f 2770 2686 2509 
s off
f 2770 2509 2574 
f 2771 2687 2657 
s 1
f 2771 2686 2687 
s off
f 2772 2500 2656 
f 2501 2773 2797 
s 1
f 2793 2773 2501 
f 2774 2673 2761 
s off
f 2673 2774 2775 
f 2775 2695 2673 
f 2776 2681 2704 
f 2668 2777 2787 
f 2479 2778 2783 
f 2656 2779 2772 
s 1
f 2506 2779 2656 
s off
f 2780 2737 2743 
s 1
f 2793 2737 2780 
f 2734 2781 2728 
s off
f 2728 2781 2782 
f 2782 2730 2728 
s 1
f 2645 2503 2783 
s off
f 2783 2503 2479 
f 2784 2571 2658 
f 2785 2584 2516 
s 1
f 2801 2890 2516 
s off
f 2516 2890 2785 
s 1
f 2787 2734 2572 
s off
f 2787 2572 2668 
f 2788 2785 2890 
f 2691 2789 2790 
s 1
f 2481 2789 2691 
s off
f 2790 2536 2691 
s 1
f 2480 2791 2704 
s off
f 2704 2791 2776 
s 1
f 2792 2481 2691 
f 2792 2840 2481 
f 2793 2488 2773 
f 2808 2874 2873 
f 2478 2798 2859 
f 2792 2480 2842 
f 2862 2842 2480 
f 2828 2471 2807 
s off
f 2741 2797 2773 
s 1
f 2798 2794 2857 
f 2795 2858 2799 
f 2799 2848 2795 
s off
f 2800 2692 2662 
s 1
f 2866 2801 2513 
f 2513 2801 2774 
f 2805 2802 2868 
f 2825 2795 2848 
f 2859 2804 2478 
f 2513 2805 2866 
f 2803 2827 2829 
f 2865 2807 2815 
f 2874 2808 2834 
f 2844 2845 2816 
f 2848 2829 2825 
f 2810 2818 2855 
f 2882 2811 2486 
f 2873 2812 2808 
f 2813 2833 2836 
f 2843 2825 2829 
f 2814 2837 2850 
f 2869 2815 2819 
f 2838 2816 2845 
f 2816 2838 2880 
f 2854 2855 2818 
f 2833 2813 2817 
f 2818 2814 2854 
f 2871 2819 2812 
f 2883 2820 2811 
f 2855 2821 2810 
f 2853 2843 2821 
f 2880 2822 2824 
s off
f 2658 2823 2784 
s 1
f 2480 2823 2658 
f 2881 2824 2820 
f 2825 2843 2826 
f 2809 2481 2840 
f 2844 2840 2826 
f 2826 2847 2844 
f 2827 2810 2821 
f 2796 2828 2865 
f 2821 2829 2827 
f 2829 2821 2843 
f 2734 2830 2485 
f 2885 2485 2830 
f 2861 2831 2804 
f 2872 2832 2831 
f 2817 2835 2833 
f 2875 2834 2832 
f 2835 2817 2852 
f 2822 2880 2838 
f 2839 2850 2837 
f 2836 2838 2813 
f 2838 2836 2822 
f 2837 2472 2839 
f 2841 2826 2840 
f 2840 2792 2841 
f 2826 2841 2825 
f 2795 2825 2841 
f 2841 2842 2795 
f 2858 2795 2842 
f 2847 2826 2843 
f 2843 2853 2847 
f 2816 2809 2844 
f 2840 2844 2809 
f 2813 2845 2856 
f 2845 2813 2838 
f 2848 2799 2846 
f 2845 2844 2847 
f 2847 2856 2845 
f 2846 2803 2848 
f 2829 2848 2803 
f 2486 2849 2882 
f 2850 2839 2806 
f 2852 2806 2835 
f 2851 2852 2817 
f 2856 2817 2813 
f 2806 2852 2850 
f 2850 2854 2814 
f 2856 2847 2853 
f 2821 2855 2853 
f 2854 2850 2852 
f 2852 2851 2854 
f 2851 2853 2855 
f 2855 2854 2851 
f 2817 2856 2851 
f 2853 2851 2856 
f 2857 2799 2858 
f 2857 2859 2798 
f 2842 2862 2858 
f 2858 2860 2857 
f 2804 2859 2861 
f 2859 2857 2860 
f 2860 2858 2862 
f 2860 2861 2859 
f 2831 2861 2872 
f 2861 2860 2863 
f 2862 2863 2860 
f 2480 2864 2862 
f 2863 2862 2864 
f 2863 2872 2861 
f 2506 2864 2480 
f 2864 2878 2863 
f 2865 2867 2796 
f 2815 2869 2865 
f 2868 2866 2805 
f 2866 2868 2887 
f 2867 2868 2802 
f 2867 2865 2869 
f 2868 2867 2870 
f 2870 2887 2868 
f 2819 2871 2869 
f 2869 2870 2867 
f 2887 2870 2876 
f 2870 2869 2871 
f 2812 2873 2871 
f 2871 2876 2870 
f 2872 2863 2878 
f 2832 2872 2875 
f 2876 2871 2873 
f 2879 2873 2874 
f 2877 2874 2875 
f 2874 2877 2879 
f 2878 2875 2872 
f 2875 2878 2877 
f 2876 2793 2887 
f 2873 2879 2876 
f 2488 2879 2877 
f 2877 2506 2488 
f 2878 2864 2506 
f 2506 2877 2878 
f 2793 2876 2879 
f 2879 2488 2793 
f 2824 2881 2880 
f 2880 2884 2816 
f 2884 2880 2881 
f 2820 2883 2881 
f 2882 2686 2529 
f 2811 2882 2883 
f 2529 2883 2882 
f 2885 2881 2883 
f 2830 2884 2885 
f 2881 2885 2884 
f 2883 2529 2885 
f 2485 2885 2529 
s off
f 2690 2886 2892 
s 1
f 2889 2887 2793 
f 2887 2889 2866 
f 2793 2888 2889 
f 2889 2888 2783 
f 2889 2783 2786 
f 2889 2891 2801 
s off
f 2890 2786 2788 
s 1
f 2801 2891 2890 
f 2890 2891 2786 
f 2889 2786 2891 
s off
f 2892 2567 2690 
s 1
f 2892 2685 2567 
f 2893 2529 2705 
s off
f 2893 2705 2680 
f 2680 2894 2893 
# 0 polygons - 649 triangles

#
# object Cylinder018
#

v  16.802778 4.856005 -11.461722
v  16.803114 4.852501 -11.464512
v  16.803923 4.850145 -11.468244
v  16.805107 4.849221 -11.472467
v  16.806526 4.849841 -11.476671
v  16.808008 4.851929 -11.480351
v  16.809372 4.855233 -11.483063
v  16.810455 4.859355 -11.484478
v  16.811129 4.863800 -11.484426
v  16.811308 4.868029 -11.482914
v  16.810972 4.871533 -11.480124
v  16.810163 4.873889 -11.476393
v  16.808977 4.874813 -11.472169
v  16.807560 4.874194 -11.467964
v  16.806078 4.872107 -11.464285
v  16.804712 4.868801 -11.461574
v  16.805733 4.863038 -11.467651
v  16.863297 4.837627 -11.456363
v  16.802958 4.860234 -11.460211
v  16.803629 4.864678 -11.460159
v  16.857344 4.833250 -11.447836
v  16.855198 4.830152 -11.449785
v  16.853849 4.827617 -11.452950
v  16.853460 4.825952 -11.456947
v  16.854076 4.825356 -11.461296
v  16.855627 4.825905 -11.465471
v  16.857922 4.827528 -11.468969
v  16.860685 4.830031 -11.471370
v  16.863585 4.833113 -11.472382
v  16.866268 4.836402 -11.471882
v  16.868416 4.839500 -11.469933
v  16.869764 4.842035 -11.466770
v  16.870153 4.843700 -11.462772
v  16.869535 4.844295 -11.458423
v  16.867987 4.843748 -11.454247
v  16.865692 4.842124 -11.450748
v  16.862928 4.839621 -11.448349
v  16.860029 4.836538 -11.447337
v  16.807852 4.845755 -11.477669
v  16.744293 4.845409 -11.479794
v  16.746651 4.842455 -11.481942
v  16.748970 4.840517 -11.485193
v  16.750969 4.839829 -11.489156
v  16.752409 4.840476 -11.493351
v  16.753117 4.842377 -11.497272
v  16.753002 4.845304 -11.500447
v  16.752085 4.848904 -11.502494
v  16.750473 4.852743 -11.503165
v  16.748362 4.856358 -11.502378
v  16.746004 4.859311 -11.500231
v  16.743685 4.861250 -11.496979
v  16.741684 4.861937 -11.493017
v  16.740246 4.861292 -11.488822
v  16.739538 4.859390 -11.484900
v  16.739651 4.856463 -11.481724
v  16.744736 4.850170 -11.486450
v  16.806473 4.862992 -11.477517
v  16.742182 4.849024 -11.479008
v  16.740570 4.852862 -11.479678
v  16.800833 4.860523 -11.460760
v  16.800705 4.856287 -11.462258
v  16.801086 4.852777 -11.465036
v  16.801926 4.850417 -11.468760
v  16.803127 4.849490 -11.472979
v  16.804543 4.850111 -11.477184
v  16.806004 4.852201 -11.480870
v  16.807331 4.855511 -11.483591
v  16.808367 4.859641 -11.485018
v  16.808985 4.864092 -11.484980
v  16.809113 4.868328 -11.483480
v  16.808733 4.871838 -11.480703
v  16.807892 4.874198 -11.476980
v  16.806690 4.875124 -11.472761
v  16.805275 4.874505 -11.468555
v  16.803816 4.872414 -11.464869
v  16.802488 4.869104 -11.462149
v  16.801453 4.864974 -11.460722
v  16.804825 4.849181 -11.483801
v  16.719826 4.786531 -11.488241
v  16.723593 4.786915 -11.489899
v  16.726803 4.787075 -11.492840
v  16.729071 4.786993 -11.496708
v  16.730120 4.786677 -11.501038
v  16.729824 4.786168 -11.505306
v  16.728220 4.785525 -11.508999
v  16.725500 4.784827 -11.511669
v  16.721994 4.784158 -11.512997
v  16.718122 4.783597 -11.512820
v  16.714355 4.783214 -11.511163
v  16.711143 4.783055 -11.508223
v  16.708878 4.783136 -11.504354
v  16.707829 4.783452 -11.500025
v  16.708124 4.783962 -11.495756
v  16.709728 4.784604 -11.492064
v  16.720747 4.785775 -11.496450
v  16.744751 4.846329 -11.488264
v  16.715954 4.785972 -11.488066
v  16.712448 4.785303 -11.489393
v  16.740458 4.847685 -11.479706
v  16.742674 4.844151 -11.480449
v  16.745115 4.841262 -11.482564
v  16.747484 4.839365 -11.485794
v  16.749496 4.838687 -11.489751
v  16.750910 4.839312 -11.493958
v  16.751553 4.841164 -11.497904
v  16.751348 4.844020 -11.501118
v  16.750320 4.847535 -11.503208
v  16.748594 4.851285 -11.503925
v  16.746376 4.854818 -11.503181
v  16.743937 4.857708 -11.501066
v  16.741568 4.859607 -11.497835
v  16.739555 4.860284 -11.493877
v  16.738142 4.859659 -11.489673
v  16.737499 4.857807 -11.485725
v  16.737703 4.854951 -11.482512
v  16.738731 4.851436 -11.480421
v  16.713408 4.783433 -11.513307
v  16.740971 4.720438 -11.479861
v  16.743994 4.722946 -11.481796
v  16.746666 4.724645 -11.484938
v  16.748661 4.725329 -11.488906
v  16.749744 4.724917 -11.493222
v  16.749783 4.723458 -11.497368
v  16.748770 4.721127 -11.500840
v  16.746830 4.718205 -11.503222
v  16.744196 4.715046 -11.504225
v  16.741186 4.712031 -11.503729
v  16.738163 4.709523 -11.501793
v  16.735493 4.707824 -11.498652
v  16.733496 4.707139 -11.494684
v  16.732412 4.707551 -11.490368
v  16.732376 4.709011 -11.486223
v  16.733389 4.711342 -11.482750
v  16.742197 4.718810 -11.487958
v  16.714891 4.781689 -11.500137
v  16.737963 4.717422 -11.479366
v  16.735329 4.714263 -11.480369
v  16.716129 4.783441 -11.487881
v  16.719990 4.784140 -11.488066
v  16.723751 4.784637 -11.489730
v  16.726957 4.784872 -11.492676
v  16.729223 4.784816 -11.496545
v  16.730274 4.784476 -11.500872
v  16.729986 4.783894 -11.505135
v  16.728390 4.783138 -11.508820
v  16.725683 4.782301 -11.511482
v  16.722187 4.781484 -11.512800
v  16.718327 4.780784 -11.512616
v  16.714565 4.780286 -11.510952
v  16.711359 4.780052 -11.508007
v  16.709093 4.780107 -11.504137
v  16.708042 4.780447 -11.499810
v  16.708330 4.781030 -11.495546
v  16.709925 4.781786 -11.491861
v  16.712633 4.782623 -11.489199
v  16.739914 4.723145 -11.476374
v  16.793976 4.691650 -11.467278
v  16.795185 4.695213 -11.469717
v  16.796539 4.697647 -11.473222
v  16.797880 4.698659 -11.477369
v  16.799044 4.698126 -11.481659
v  16.799889 4.696113 -11.485573
v  16.800316 4.692863 -11.488641
v  16.800270 4.688767 -11.490490
v  16.799761 4.684320 -11.490900
v  16.798847 4.680058 -11.489820
v  16.797638 4.676496 -11.487379
v  16.796284 4.674061 -11.483875
v  16.794943 4.673049 -11.479728
v  16.793779 4.673583 -11.475438
v  16.792934 4.675595 -11.471523
v  16.792507 4.678846 -11.468456
v  16.797697 4.685266 -11.483289
v  16.743729 4.713921 -11.496153
v  16.793062 4.687388 -11.466197
v  16.792551 4.682941 -11.466606
v  16.739407 4.715575 -11.478956
v  16.742353 4.718669 -11.479469
v  16.745323 4.721246 -11.481420
v  16.747955 4.722994 -11.484571
v  16.749931 4.723705 -11.488545
v  16.751017 4.723289 -11.492862
v  16.751078 4.721799 -11.497000
v  16.750109 4.719415 -11.500461
v  16.748224 4.716423 -11.502826
v  16.745653 4.713184 -11.503811
v  16.742704 4.710089 -11.503298
v  16.739737 4.707512 -11.501348
v  16.737104 4.705763 -11.498196
v  16.735126 4.705054 -11.494222
v  16.734041 4.705469 -11.489905
v  16.733980 4.706959 -11.485768
v  16.734951 4.709343 -11.482307
v  16.736835 4.712336 -11.479941
v  16.798405 4.702215 -11.482970
v  16.852539 4.705645 -11.453854
v  16.851803 4.708633 -11.456743
v  16.851841 4.710679 -11.460552
v  16.852650 4.711535 -11.464821
v  16.854134 4.711098 -11.469037
v  16.856112 4.709421 -11.472690
v  16.858345 4.706706 -11.475340
v  16.860567 4.703281 -11.476665
v  16.862507 4.699557 -11.476509
v  16.863932 4.695987 -11.474889
v  16.864670 4.692998 -11.472000
v  16.864632 4.690952 -11.468191
v  16.863821 4.690096 -11.463921
v  16.862339 4.690533 -11.459705
v  16.860359 4.692210 -11.456053
v  16.858126 4.694925 -11.453403
v  16.860378 4.696702 -11.464199
v  16.799509 4.690323 -11.479279
v  16.853966 4.702074 -11.452233
v  16.855904 4.698351 -11.452076
v  16.795208 4.687142 -11.465721
v  16.796057 4.691411 -11.466816
v  16.797211 4.694981 -11.469269
v  16.798527 4.697418 -11.472782
v  16.799849 4.698432 -11.476933
v  16.801018 4.697897 -11.481222
v  16.801889 4.695881 -11.485130
v  16.802362 4.692625 -11.488187
v  16.802378 4.688522 -11.490023
v  16.801933 4.684068 -11.490417
v  16.801085 4.679799 -11.489323
v  16.799931 4.676230 -11.486870
v  16.798615 4.673792 -11.483356
v  16.797291 4.672778 -11.479204
v  16.796124 4.673312 -11.474916
v  16.795250 4.675329 -11.471007
v  16.794779 4.678586 -11.467951
v  16.794764 4.682688 -11.466115
v  16.803276 4.701653 -11.481895
v  16.878483 4.767046 -11.447035
v  16.876305 4.766846 -11.450308
v  16.875364 4.766733 -11.454378
v  16.875774 4.766721 -11.458755
v  16.877485 4.766810 -11.462910
v  16.880289 4.766991 -11.466341
v  16.883852 4.767241 -11.468636
v  16.887739 4.767530 -11.469517
v  16.891485 4.767824 -11.468878
v  16.894636 4.768086 -11.466796
v  16.896812 4.768286 -11.463523
v  16.897755 4.768400 -11.459452
v  16.897345 4.768412 -11.455076
v  16.895634 4.768323 -11.450921
v  16.892828 4.768143 -11.447490
v  16.889267 4.767892 -11.445195
v  16.888449 4.767665 -11.461506
v  16.861729 4.701839 -11.468581
v  16.881634 4.767309 -11.444954
v  16.885380 4.767603 -11.444314
v  16.855759 4.703532 -11.451799
v  16.854216 4.707006 -11.453447
v  16.853378 4.709914 -11.456361
v  16.853348 4.711905 -11.460186
v  16.854130 4.712737 -11.464462
v  16.855627 4.712314 -11.468675
v  16.857662 4.710682 -11.472315
v  16.859987 4.708041 -11.474942
v  16.862322 4.704708 -11.476240
v  16.864388 4.701087 -11.476054
v  16.865931 4.697612 -11.474405
v  16.866768 4.694704 -11.471493
v  16.866796 4.692713 -11.467667
v  16.866016 4.691881 -11.463390
v  16.864517 4.692306 -11.459178
v  16.862484 4.693937 -11.455540
v  16.860157 4.696578 -11.452911
v  16.857822 4.699910 -11.451612
v  16.890131 4.765783 -11.441526
v  16.854939 4.830454 -11.449829
v  16.853600 4.827907 -11.452991
v  16.853218 4.826232 -11.456988
v  16.853840 4.825633 -11.461336
v  16.855389 4.826181 -11.465511
v  16.857679 4.827810 -11.469011
v  16.860434 4.830324 -11.471414
v  16.863321 4.833419 -11.472425
v  16.865993 4.836722 -11.471930
v  16.868126 4.839835 -11.469982
v  16.869465 4.842382 -11.466821
v  16.869846 4.844056 -11.462824
v  16.869225 4.844656 -11.458475
v  16.867676 4.844108 -11.454300
v  16.865387 4.842479 -11.450800
v  16.862631 4.839965 -11.448400
v  16.858488 4.832367 -11.457253
v  16.888382 4.770309 -11.461460
v  16.857071 4.833566 -11.447883
v  16.859743 4.836870 -11.447386
v  16.881569 4.769853 -11.444908
v  16.878420 4.769461 -11.446992
v  16.876245 4.769156 -11.450268
v  16.875307 4.768975 -11.454339
v  16.875717 4.768940 -11.458715
v  16.877428 4.769054 -11.462870
v  16.880230 4.769305 -11.466300
v  16.883789 4.769661 -11.468594
v  16.887674 4.770080 -11.469472
v  16.891417 4.770512 -11.468830
v  16.894564 4.770904 -11.466746
v  16.896738 4.771209 -11.463471
v  16.897678 4.771389 -11.459400
v  16.897266 4.771425 -11.455023
v  16.895557 4.771310 -11.450869
v  16.892754 4.771060 -11.447439
v  16.889194 4.770703 -11.445147
v  16.885311 4.770284 -11.444267
v  16.861141 4.827551 -11.474882
v  16.733963 4.860843 -11.496111
v  16.863377 4.692633 -11.453777
v  16.718273 4.778061 -11.516329
v  16.884834 4.772409 -11.440246
v  16.803593 4.878585 -11.467655
v  16.807552 4.875031 -11.461281
v  16.795282 4.681602 -11.461945
v  16.796232 4.673774 -11.490580
v  16.800009 4.701020 -11.470778
v  16.733578 4.782650 -11.506512
v  16.752287 4.841271 -11.504555
v  16.732563 4.783950 -11.495206
v  16.869339 4.697483 -11.477348
v  16.747576 4.863195 -11.502446
v  16.872944 4.843571 -11.451792
v  16.706699 4.786709 -11.489518
v  16.858204 4.838522 -11.443586
v  16.736238 4.710217 -11.475977
v  16.726511 4.708339 -11.490671
v  16.862370 4.698030 -11.480717
v  16.859301 4.688171 -11.453835
v  16.800480 4.669871 -11.484619
v  16.897202 4.766016 -11.469973
v  16.807051 4.878181 -11.478746
v  16.869961 4.850386 -11.464367
v  16.738190 4.699114 -11.499784
v  16.901337 4.777218 -11.460242
v  16.739109 4.859371 -11.478117
v  16.858576 4.700431 -11.447353
v  16.804239 4.865263 -11.455871
v  16.789612 4.682249 -11.463205
v  16.709114 4.776919 -11.510245
v  16.740921 4.841492 -11.477314
v  16.811396 4.878592 -11.471658
v  16.860418 4.835951 -11.443210
v  16.744892 4.844575 -11.475708
v  16.707205 4.779362 -11.488997
v  16.799980 4.871466 -11.459205
v  16.808733 4.870440 -11.487289
v  16.866478 4.838278 -11.475901
v  16.796034 4.671899 -11.468362
v  16.747799 4.711399 -11.507426
v  16.892876 4.772704 -11.472593
v  16.804434 4.846154 -11.466566
v  16.795092 4.701586 -11.471863
v  16.804688 4.683418 -11.493956
v  16.790009 4.672590 -11.469706
v  16.851580 4.822232 -11.455896
v  16.874613 4.764909 -11.464843
v  16.814079 4.864113 -11.487837
v  16.799149 4.684063 -11.495187
v  16.801064 4.845609 -11.473404
v  16.744087 4.716144 -11.508478
v  16.871820 4.764817 -11.453609
v  16.868597 4.835817 -11.475540
v  16.704742 4.777432 -11.499453
v  16.735504 4.850897 -11.477367
v  16.813974 4.874281 -11.482145
v  16.853634 4.696411 -11.448551
v  16.732361 4.715178 -11.477077
v  16.797901 4.860154 -11.457349
v  16.807856 4.858988 -11.489285
v  16.715349 4.781804 -11.483774
v  16.803007 4.853858 -11.457958
v  16.791620 4.693700 -11.464058
v  16.862869 4.684490 -11.470530
v  16.727934 4.780324 -11.514854
v  16.805124 4.694699 -11.491049
v  16.849443 4.706198 -11.450832
v  16.853949 4.827668 -11.446451
v  16.811661 4.852847 -11.486069
v  16.882843 4.771315 -11.472283
v  16.800005 4.695295 -11.492182
v  16.875927 4.765063 -11.443941
v  16.725363 4.783617 -11.486226
v  16.731064 4.787454 -11.511799
v  16.861151 4.710899 -11.478143
v  16.798328 4.849942 -11.462956
v  16.753271 4.854405 -11.506389
v  16.796852 4.693102 -11.462898
v  16.743376 4.718717 -11.475391
v  16.851988 4.829946 -11.446785
v  16.859262 4.829731 -11.475202
v  16.753540 4.719755 -11.503048
v  16.720018 4.788771 -11.484460
v  16.857124 4.707622 -11.479118
v  16.875774 4.771055 -11.443838
v  16.874474 4.770341 -11.464748
v  16.747503 4.728564 -11.483032
v  16.750912 4.838032 -11.482876
v  16.750227 4.723994 -11.503988
v  16.848688 4.712953 -11.459614
v  16.751575 4.728899 -11.493938
v  16.756378 4.844447 -11.502901
v  16.851721 4.713515 -11.470784
v  16.849806 4.824292 -11.456198
v  16.750654 4.724532 -11.482139
v  16.852678 4.824207 -11.467421
v  16.754669 4.724943 -11.493060
v  16.854420 4.822185 -11.467125
v  16.755447 4.837981 -11.493614
v  16.871683 4.770238 -11.453514
v  16.853579 4.709561 -11.449829
v  16.882998 4.765296 -11.472387
v  16.733580 4.788758 -11.501303
v  16.729218 4.789278 -11.490506
v  16.852268 4.715865 -11.458744
v  16.855259 4.716393 -11.469926
v  16.751875 4.835209 -11.495059
v  16.747387 4.835297 -11.484301
v  16.901136 4.763227 -11.454542
v  16.722849 4.785975 -11.517081
v  16.744005 4.868646 -11.492088
v  16.708168 4.787979 -11.511273
v  16.869972 4.692255 -11.456543
v  16.740103 4.857543 -11.505791
v  16.873299 4.843118 -11.468790
v  16.736031 4.709021 -11.505309
v  16.722702 4.782722 -11.503594
v  16.864723 4.838564 -11.461026
v  16.731344 4.857603 -11.484990
v  16.860315 4.832026 -11.463356
v  16.898460 4.777114 -11.448669
v  16.866972 4.850504 -11.452811
v  16.734079 4.698663 -11.488527
v  16.857502 4.705228 -11.461023
v  16.887598 4.767692 -11.452417
v  16.882561 4.769789 -11.454336
v  16.803345 4.861622 -11.468223
v  16.873037 4.692786 -11.468065
v  16.703678 4.788503 -11.500149
v  16.740345 4.713594 -11.486993
v  16.738935 4.713006 -11.494427
v  16.748932 4.847619 -11.493460
v  16.794914 4.683165 -11.474676
v  16.857420 4.704118 -11.467564
v  16.748428 4.850645 -11.508345
v  16.742619 4.853630 -11.492607
v  16.715099 4.784325 -11.501998
v  16.899977 4.767973 -11.459967
v  16.797632 4.680887 -11.476858
v  16.808611 4.862702 -11.476965
v  16.740913 4.865067 -11.481152
v  16.895012 4.763092 -11.444246
v  16.744545 4.855125 -11.491827
v  16.859728 4.683899 -11.459025
v  16.893051 4.765797 -11.472712
v  16.899872 4.773859 -11.465532
v  16.867962 4.843204 -11.447687
v  16.728376 4.708391 -11.484814
v  16.870945 4.845850 -11.469191
v  16.740128 4.703783 -11.504148
v  16.861778 4.698469 -11.462105
v  16.889971 4.770616 -11.454567
v  16.744419 4.719006 -11.493934
v  16.882420 4.767242 -11.457621
v  16.794407 4.670573 -11.485973
v  16.733816 4.706205 -11.479075
v  16.740562 4.861272 -11.499195
v  16.861956 4.842656 -11.444929
v  16.867624 4.690185 -11.463179
v  16.706844 4.783365 -11.505150
v  16.801064 4.667849 -11.478670
v  16.889944 4.773090 -11.441401
v  16.864128 4.693238 -11.478611
v  16.736464 4.861338 -11.489414
v  16.788677 4.669814 -11.475264
v  16.723021 4.783366 -11.498884
v  16.859175 4.831704 -11.462057
v  16.723137 4.785488 -11.502363
v  16.717995 4.782838 -11.495559
v  16.864487 4.687633 -11.463940
v  16.855766 4.703816 -11.461443
v  16.805050 4.866988 -11.471214
v  16.899376 4.772267 -11.454649
v  16.871094 4.846140 -11.458134
v  16.739462 4.863666 -11.488202
v  16.731678 4.705755 -11.495346
v  16.870722 4.846570 -11.458197
v  16.733988 4.702802 -11.494692
v  16.897436 4.767883 -11.449745
v  16.868555 4.691177 -11.468305
v  16.705589 4.783736 -11.499963
v  16.858604 4.831421 -11.458742
v  16.797606 4.687834 -11.473751
v  16.865826 4.690690 -11.458134
v  16.738150 4.862081 -11.494455
v  16.709560 4.783259 -11.509785
v  16.741192 4.864443 -11.493225
v  16.865463 4.688662 -11.469053
v  16.735710 4.859131 -11.484678
v  16.747223 4.846292 -11.494152
v  16.899487 4.767984 -11.454722
v  16.897327 4.772125 -11.449673
v  16.868866 4.845914 -11.453195
v  16.732687 4.703300 -11.489521
v  16.871477 4.839834 -11.472964
v  16.743414 4.859007 -11.503061
v  16.802174 4.673085 -11.489257
v  16.740063 4.712254 -11.507843
v  16.740210 4.854551 -11.475467
v  16.710289 4.787472 -11.485992
v  16.885010 4.765532 -11.440364
v  16.867186 4.701953 -11.479549
v  16.750595 4.859242 -11.505310
v  16.717646 4.788218 -11.517492
v  16.863718 4.697740 -11.448124
v  16.890697 4.767891 -11.456209
v  16.795364 4.676192 -11.464348
v  16.900417 4.763253 -11.465996
v  16.875851 4.843548 -11.463362
v  16.730726 4.708654 -11.501899
v  16.745787 4.717799 -11.491951
v  16.796362 4.681329 -11.480593
v  16.855732 4.705091 -11.447629
v  16.727474 4.786691 -11.515325
v  16.737894 4.846056 -11.476377
v  16.804226 4.857389 -11.472912
v  16.706131 4.776965 -11.505149
v  16.705116 4.778265 -11.493843
v  16.863520 4.835431 -11.464474
v  16.799862 4.685015 -11.482808
v  16.812943 4.877378 -11.477222
v  16.805454 4.879401 -11.473194
v  16.801693 4.875830 -11.462796
v  16.809525 4.877778 -11.466122
v  16.746357 4.855560 -11.505588
v  16.713329 4.777300 -11.514129
v  16.797880 4.678459 -11.493779
v  16.729752 4.711405 -11.480228
v  16.856447 4.691814 -11.450325
v  16.864285 4.839952 -11.444533
v  16.710758 4.780591 -11.485500
v  16.814369 4.869673 -11.485832
v  16.789494 4.676863 -11.465654
v  16.808189 4.875068 -11.483642
v  16.897015 4.773351 -11.469847
v  16.798664 4.866022 -11.457313
v  16.805716 4.870686 -11.457721
v  16.803637 4.677791 -11.492498
v  16.869232 4.842441 -11.473346
v  16.743980 4.707245 -11.506732
v  16.717396 4.781627 -11.504414
v  16.795126 4.686443 -11.473808
v  16.739559 4.714562 -11.474697
v  16.854742 4.834108 -11.444231
v  16.795799 4.687474 -11.461441
v  16.879913 4.771702 -11.441092
v  16.808617 4.864856 -11.489249
v  16.723345 4.779111 -11.516581
v  16.888119 4.765547 -11.473551
v  16.859936 4.703025 -11.480893
v  16.865007 4.831552 -11.476206
v  16.813137 4.858269 -11.487919
v  16.799887 4.689910 -11.494632
v  16.803299 4.859419 -11.455953
v  16.735889 4.719255 -11.475738
v  16.790352 4.688095 -11.462650
v  16.862713 4.688158 -11.458887
v  16.797785 4.854569 -11.459309
v  16.747616 4.720222 -11.507139
v  16.755280 4.849267 -11.505552
v  16.860094 4.834221 -11.455245
v  16.863245 4.835750 -11.464520
v  16.856829 4.831685 -11.443876
v  16.742218 4.849412 -11.474629
v  16.720421 4.782856 -11.484026
v  16.805534 4.858365 -11.469322
v  16.805206 4.689291 -11.493452
v  16.750818 4.845805 -11.507354
v  16.899868 4.772219 -11.459892
v  16.730381 4.706248 -11.490174
v  16.869236 4.845484 -11.453131
v  16.880077 4.765282 -11.441202
v  16.851202 4.701406 -11.448727
v  16.887953 4.771997 -11.473439
v  16.751118 4.715744 -11.506146
v  16.714914 4.788188 -11.484236
v  16.863014 4.833864 -11.476545
v  16.705944 4.784327 -11.494848
v  16.806538 4.853544 -11.487394
v  16.797501 4.670191 -11.479326
# 594 vertices

o Cylinder018
g Cylinder018
usemtl 11___Defadwdlt
s 1
f 2895 2913 2911 
f 2896 2895 3474 
f 2897 2896 3474 
f 2898 2897 3474 
f 2899 2898 3474 
f 2900 2899 3348 
f 2901 2900 3348 
f 2902 2901 3348 
f 2903 2902 3348 
f 2904 2903 3348 
f 2905 2904 2911 
f 2906 2905 2911 
f 2907 2906 2911 
f 2908 2907 2911 
f 2909 2908 2911 
f 2910 2909 2911 
f 2914 2910 2911 
f 2913 2914 2911 
f 2915 2916 3390 
f 2916 2917 3390 
f 2917 2918 3390 
f 2918 2919 3390 
f 2919 2920 3328 
f 2920 2921 3328 
f 2921 2922 3328 
f 2922 2923 3328 
f 2923 2924 3427 
f 2924 2925 3427 
f 2925 2926 3427 
f 2926 2927 2912 
f 2927 2928 2912 
f 2928 2929 2912 
f 2929 2930 2912 
f 2930 2931 3469 
f 2931 2932 3469 
f 2932 2915 3469 
f 3462 2913 2895 
f 3270 2895 2896 
f 3250 2896 2897 
f 3250 2897 2898 
f 2933 2898 2899 
f 2933 2899 2900 
f 3277 2900 2901 
f 3277 2901 2902 
f 3460 2902 2903 
f 3256 2903 2904 
f 3440 2904 2905 
f 3264 2905 2906 
f 3429 2906 2907 
f 3240 2907 2908 
f 3432 2908 2909 
f 3212 2909 2910 
f 3445 2910 2914 
f 3236 2914 2913 
f 3288 2916 2915 
f 3288 2917 2916 
f 3302 2918 2917 
f 3302 2919 2918 
f 3304 2920 2919 
f 3304 2921 2920 
f 3289 2922 2921 
f 3485 2923 2922 
f 3246 2924 2923 
f 3447 2925 2924 
f 3357 2926 2925 
f 3357 2927 2926 
f 3385 2928 2927 
f 3401 2929 2928 
f 3366 2930 2929 
f 3366 2931 2930 
f 3223 2932 2931 
f 3452 2915 2932 
f 2934 2952 2950 
f 2935 2934 2950 
f 2936 2935 2950 
f 2937 2936 3340 
f 2938 2937 3340 
f 2939 2938 3340 
f 2940 2939 3340 
f 2941 2940 3340 
f 2942 2941 3340 
f 2943 2942 3351 
f 2944 2943 3351 
f 2945 2944 3351 
f 2946 2945 3351 
f 2947 2946 3351 
f 2948 2947 2950 
f 2949 2948 2950 
f 2953 2949 2950 
f 2952 2953 2950 
f 2954 2955 3335 
f 2955 2956 3335 
f 2956 2957 3424 
f 2957 2958 3424 
f 2958 2959 3424 
f 2959 2960 3424 
f 2960 2961 3424 
f 2961 2962 2951 
f 2962 2963 2951 
f 2963 2964 2951 
f 2964 2965 2951 
f 2965 2966 3380 
f 2966 2967 3380 
f 2967 2968 3380 
f 2968 2969 3380 
f 2969 2970 3380 
f 2970 2971 3335 
f 2971 2954 3335 
f 3472 2952 2934 
f 3242 2934 2935 
f 3296 2935 2936 
f 3296 2936 2937 
f 3307 2937 2938 
f 3307 2938 2939 
f 3300 2939 2940 
f 3300 2940 2941 
f 3468 2941 2942 
f 3285 2942 2943 
f 3411 2943 2944 
f 3220 2944 2945 
f 3220 2945 2946 
f 3395 2946 2947 
f 3383 2947 2948 
f 3234 2948 2949 
f 3234 2949 2953 
f 3407 2953 2952 
f 3466 2955 2954 
f 3284 2956 2955 
f 3284 2957 2956 
f 3258 2958 2957 
f 3258 2959 2958 
f 2972 2960 2959 
f 3487 2961 2960 
f 3268 2962 2961 
f 3455 2963 2962 
f 3245 2964 2963 
f 3442 2965 2964 
f 3230 2966 2965 
f 3430 2967 2966 
f 3211 2968 2967 
f 3431 2969 2968 
f 3244 2970 2969 
f 3444 2971 2970 
f 3267 2954 2971 
f 2973 2991 2989 
f 2974 2973 2989 
f 2975 2974 2989 
f 2976 2975 2989 
f 2977 2976 2989 
f 2978 2977 3376 
f 2979 2978 3376 
f 2980 2979 3376 
f 2981 2980 3376 
f 2982 2981 3345 
f 2983 2982 3345 
f 2984 2983 3345 
f 2985 2984 3345 
f 2986 2985 3345 
f 2987 2986 3345 
f 2988 2987 3345 
f 2992 2988 3345 
f 2991 2992 2989 
f 2993 2994 2990 
f 2994 2995 2990 
f 2995 2996 2990 
f 2996 2997 3398 
f 2997 2998 3398 
f 2998 2999 3398 
f 2999 3000 3398 
f 3000 3001 3398 
f 3001 3002 3344 
f 3002 3003 3344 
f 3003 3004 3344 
f 3004 3005 3344 
f 3005 3006 3344 
f 3006 3007 3344 
f 3007 3008 3344 
f 3008 3009 3344 
f 3009 3010 3344 
f 3010 2993 2990 
f 3484 2991 2973 
f 3291 2973 2974 
f 3312 2974 2975 
f 3312 2975 2976 
f 3311 2976 2977 
f 3311 2977 2978 
f 3282 2978 2979 
f 3282 2979 2980 
f 3422 2980 2981 
f 3318 2981 2982 
f 3318 2982 2983 
f 3011 2983 2984 
f 3394 2984 2985 
f 3368 2985 2986 
f 3389 2986 2987 
f 3486 2987 2988 
f 3222 2988 2992 
f 3408 2992 2991 
f 3239 2994 2993 
f 3239 2995 2994 
f 3316 2996 2995 
f 3316 2997 2996 
f 3315 2998 2997 
f 3315 2999 2998 
f 3217 3000 2999 
f 3476 3001 3000 
f 3343 3002 3001 
f 3433 3003 3002 
f 3404 3004 3003 
f 3365 3005 3004 
f 3393 3006 3005 
f 3372 3007 3006 
f 3397 3008 3007 
f 3263 3009 3008 
f 3263 3010 3009 
f 3423 2993 3010 
f 3012 3030 3028 
f 3013 3012 3028 
f 3014 3013 3028 
f 3015 3014 3028 
f 3016 3015 3028 
f 3017 3016 3361 
f 3018 3017 3361 
f 3019 3018 3361 
f 3020 3019 3361 
f 3021 3020 3361 
f 3022 3021 3339 
f 3023 3022 3339 
f 3024 3023 3339 
f 3025 3024 3339 
f 3026 3025 3339 
f 3027 3026 3339 
f 3031 3027 3339 
f 3030 3031 3028 
f 3032 3033 3377 
f 3033 3034 3374 
f 3034 3035 3374 
f 3035 3036 3374 
f 3036 3037 3374 
f 3037 3038 3325 
f 3038 3039 3325 
f 3039 3040 3325 
f 3040 3041 3449 
f 3041 3042 3449 
f 3042 3043 3449 
f 3043 3044 3029 
f 3044 3045 3029 
f 3045 3046 3029 
f 3046 3047 3029 
f 3047 3048 3377 
f 3048 3049 3377 
f 3049 3032 3377 
f 3463 3030 3012 
f 3050 3012 3013 
f 3295 3013 3014 
f 3295 3014 3015 
f 3299 3015 3016 
f 3299 3016 3017 
f 3297 3017 3018 
f 3297 3018 3019 
f 3467 3019 3020 
f 3259 3020 3021 
f 3406 3021 3022 
f 3324 3022 3023 
f 3324 3023 3024 
f 3384 3024 3025 
f 3478 3025 3026 
f 3356 3026 3027 
f 3436 3027 3031 
f 3266 3031 3030 
f 3473 3033 3032 
f 3281 3034 3033 
f 3281 3035 3034 
f 3218 3036 3035 
f 3218 3037 3036 
f 3216 3038 3037 
f 3216 3039 3038 
f 3273 3040 3039 
f 3456 3041 3040 
f 3209 3042 3041 
f 3434 3043 3042 
f 3238 3044 3043 
f 3425 3045 3044 
f 3262 3046 3045 
f 3426 3047 3046 
f 3243 3048 3047 
f 3439 3049 3048 
f 3269 3032 3049 
f 3051 3069 3450 
f 3052 3051 3450 
f 3053 3052 3450 
f 3054 3053 3450 
f 3055 3054 3450 
f 3056 3055 3067 
f 3057 3056 3067 
f 3058 3057 3067 
f 3059 3058 3067 
f 3060 3059 3067 
f 3061 3060 3067 
f 3062 3061 3420 
f 3063 3062 3420 
f 3064 3063 3420 
f 3065 3064 3341 
f 3066 3065 3341 
f 3070 3066 3341 
f 3069 3070 3341 
f 3071 3072 3338 
f 3072 3073 3338 
f 3073 3074 3338 
f 3074 3075 3338 
f 3075 3076 3419 
f 3076 3077 3419 
f 3077 3078 3419 
f 3078 3079 3068 
f 3079 3080 3068 
f 3080 3081 3068 
f 3081 3082 3068 
f 3082 3083 3068 
f 3083 3084 3068 
f 3084 3085 3338 
f 3085 3086 3338 
f 3086 3087 3338 
f 3087 3088 3338 
f 3088 3071 3338 
f 3464 3069 3051 
f 3271 3051 3052 
f 3251 3052 3053 
f 3251 3053 3054 
f 3089 3054 3055 
f 3089 3055 3056 
f 3279 3056 3057 
f 3279 3057 3058 
f 3461 3058 3059 
f 3257 3059 3060 
f 3435 3060 3061 
f 3214 3061 3062 
f 3363 3062 3063 
f 3363 3063 3064 
f 3253 3064 3065 
f 3253 3065 3066 
f 3441 3066 3070 
f 3237 3070 3069 
f 3287 3072 3071 
f 3287 3073 3072 
f 3303 3074 3073 
f 3303 3075 3074 
f 3305 3076 3075 
f 3305 3077 3076 
f 3290 3078 3077 
f 3483 3079 3078 
f 3248 3080 3079 
f 3448 3081 3080 
f 3358 3082 3081 
f 3358 3083 3082 
f 3386 3084 3083 
f 3402 3085 3084 
f 3364 3086 3085 
f 3364 3087 3086 
f 3224 3088 3087 
f 3451 3071 3088 
f 3090 3108 3379 
f 3091 3090 3379 
f 3092 3091 3379 
f 3093 3092 3379 
f 3094 3093 3379 
f 3095 3094 3342 
f 3096 3095 3342 
f 3097 3096 3342 
f 3098 3097 3342 
f 3099 3098 3342 
f 3100 3099 3342 
f 3101 3100 3106 
f 3102 3101 3106 
f 3103 3102 3106 
f 3104 3103 3106 
f 3105 3104 3106 
f 3109 3105 3106 
f 3108 3109 3106 
f 3110 3111 3391 
f 3111 3112 3391 
f 3112 3113 3107 
f 3113 3114 3107 
f 3114 3115 3107 
f 3115 3116 3107 
f 3116 3117 3428 
f 3117 3118 3428 
f 3118 3119 3428 
f 3119 3120 3347 
f 3120 3121 3347 
f 3121 3122 3347 
f 3122 3123 3347 
f 3123 3124 3347 
f 3124 3125 3347 
f 3125 3126 3347 
f 3126 3127 3347 
f 3127 3110 3391 
f 3481 3108 3090 
f 3275 3090 3091 
f 3298 3091 3092 
f 3298 3092 3093 
f 3301 3093 3094 
f 3301 3094 3095 
f 3292 3095 3096 
f 3292 3096 3097 
f 3458 3097 3098 
f 3226 3098 3099 
f 3371 3099 3100 
f 3371 3100 3101 
f 3396 3101 3102 
f 3378 3102 3103 
f 3465 3103 3104 
f 3227 3104 3105 
f 3437 3105 3109 
f 3265 3109 3108 
f 3286 3111 3110 
f 3286 3112 3111 
f 3215 3113 3112 
f 3215 3114 3113 
f 3128 3115 3114 
f 3128 3116 3115 
f 3274 3117 3116 
f 3475 3118 3117 
f 3252 3119 3118 
f 3446 3120 3119 
f 3405 3121 3120 
f 3228 3122 3121 
f 3488 3123 3122 
f 3247 3124 3123 
f 3247 3125 3124 
f 3415 3126 3125 
f 3213 3127 3126 
f 3453 3110 3127 
f 3129 3147 3333 
f 3130 3129 3333 
f 3131 3130 3333 
f 3132 3131 3362 
f 3133 3132 3362 
f 3134 3133 3362 
f 3135 3134 3362 
f 3136 3135 3145 
f 3137 3136 3145 
f 3138 3137 3145 
f 3139 3138 3145 
f 3140 3139 3145 
f 3141 3140 3414 
f 3142 3141 3414 
f 3143 3142 3414 
f 3144 3143 3414 
f 3148 3144 3333 
f 3147 3148 3333 
f 3149 3150 3332 
f 3150 3151 3332 
f 3151 3152 3332 
f 3152 3153 3332 
f 3153 3154 3146 
f 3154 3155 3146 
f 3155 3156 3146 
f 3156 3157 3146 
f 3157 3158 3146 
f 3158 3159 3146 
f 3159 3160 3146 
f 3160 3161 3359 
f 3161 3162 3359 
f 3162 3163 3359 
f 3163 3164 3359 
f 3164 3165 3332 
f 3165 3166 3332 
f 3166 3149 3332 
f 3480 3147 3129 
f 3280 3129 3130 
f 3260 3130 3131 
f 3260 3131 3132 
f 3255 3132 3133 
f 3255 3133 3134 
f 3310 3134 3135 
f 3310 3135 3136 
f 3457 3136 3137 
f 3353 3137 3138 
f 3229 3138 3139 
f 3229 3139 3140 
f 3346 3140 3141 
f 3399 3141 3142 
f 3387 3142 3143 
f 3167 3143 3144 
f 3167 3144 3148 
f 3409 3148 3147 
f 3309 3150 3149 
f 3309 3151 3150 
f 3313 3152 3151 
f 3313 3153 3152 
f 3314 3154 3153 
f 3314 3155 3154 
f 3283 3156 3155 
f 3283 3157 3156 
f 3410 3158 3157 
f 3219 3159 3158 
f 3219 3160 3159 
f 3388 3161 3160 
f 3367 3162 3161 
f 3392 3163 3162 
f 3208 3164 3163 
f 3235 3165 3164 
f 3235 3166 3165 
f 3421 3149 3166 
f 3168 3186 3184 
f 3169 3168 3184 
f 3170 3169 3184 
f 3171 3170 3184 
f 3172 3171 3375 
f 3173 3172 3375 
f 3174 3173 3375 
f 3175 3174 3375 
f 3176 3175 3470 
f 3177 3176 3470 
f 3178 3177 3470 
f 3179 3178 3326 
f 3180 3179 3326 
f 3181 3180 3326 
f 3182 3181 3326 
f 3183 3182 3326 
f 3187 3183 3184 
f 3186 3187 3184 
f 3188 3189 3334 
f 3189 3190 3334 
f 3190 3191 3334 
f 3191 3192 3185 
f 3192 3193 3185 
f 3193 3194 3185 
f 3194 3195 3185 
f 3195 3196 3185 
f 3196 3197 3185 
f 3197 3198 3185 
f 3198 3199 3185 
f 3199 3200 3185 
f 3200 3201 3360 
f 3201 3202 3360 
f 3202 3203 3360 
f 3203 3204 3360 
f 3204 3205 3360 
f 3205 3188 3334 
f 3471 3186 3168 
f 3276 3168 3169 
f 3254 3169 3170 
f 3254 3170 3171 
f 3306 3171 3172 
f 3306 3172 3173 
f 3206 3173 3174 
f 3206 3174 3175 
f 3459 3175 3176 
f 3261 3176 3177 
f 3403 3177 3178 
f 3323 3178 3179 
f 3323 3179 3180 
f 3382 3180 3181 
f 3479 3181 3182 
f 3355 3182 3183 
f 3438 3183 3187 
f 3241 3187 3186 
f 3293 3189 3188 
f 3293 3190 3189 
f 3308 3191 3190 
f 3308 3192 3191 
f 3294 3193 3192 
f 3294 3194 3193 
f 3278 3195 3194 
f 3482 3196 3195 
f 3249 3197 3196 
f 3443 3198 3197 
f 3354 3199 3198 
f 3477 3200 3199 
f 3381 3201 3200 
f 3400 3202 3201 
f 3370 3203 3202 
f 3370 3204 3203 
f 3210 3205 3204 
f 3454 3188 3205 
f 3001 3476 3343 
f 2986 3389 3368 
f 2997 3316 3315 
f 2976 3311 3312 
f 3161 3388 3367 
f 3164 3208 3235 
f 3047 3426 3243 
f 3039 3216 3273 
f 3027 3436 3356 
f 3019 3467 3297 
f 3198 3443 3354 
f 3178 3323 3403 
f 3192 3308 3294 
f 3171 3306 3254 
f 2952 3472 3407 
f 2941 3468 3300 
f 2955 3466 3284 
f 2961 3487 3268 
f 2925 3447 3357 
f 2904 3440 3256 
f 2910 3445 3212 
f 2929 3401 3366 
f 3114 3215 3128 
f 3093 3301 3298 
f 3123 3488 3247 
f 3102 3378 3396 
f 3081 3448 3358 
f 3060 3435 3257 
f 3066 3441 3253 
f 3085 3402 3364 
f 3112 3286 3215 
f 3091 3298 3275 
f 3015 3299 3295 
f 3035 3281 3218 
f 2980 3422 3282 
f 3343 3476 3422 
f 3017 3297 3299 
f 3037 3218 3216 
f 3140 3346 3229 
f 3153 3313 3314 
f 3132 3255 3260 
f 3321 3336 3317 
f 2967 3430 3211 
f 2947 3383 3395 
f 3183 3438 3355 
f 3202 3400 3370 
f 3320 3337 3207 
f 3008 3397 3263 
f 2919 3302 3304 
f 2898 2933 3250 
f 3075 3303 3305 
f 3054 3089 3251 
f 3043 3434 3238 
f 3022 3324 3406 
f 3116 3128 3274 
f 3095 3292 3301 
f 3108 3481 3265 
f 3126 3415 3213 
f 3101 3396 3371 
f 3120 3446 3405 
f 3416 3317 3336 
f 3136 3457 3310 
f 3211 3430 3319 
f 2965 3442 3230 
f 3366 3330 3212 
f 2908 3432 3240 
f 3358 3448 3435 
f 3064 3253 3363 
f 3403 3323 3354 
f 3370 3329 3355 
f 3319 3349 3211 
f 2969 3431 3244 
f 3147 3480 3409 
f 3235 3413 3167 
f 3212 3445 3366 
f 2913 3462 3236 
f 3069 3464 3237 
f 3253 3441 3364 
f 3238 3434 3324 
f 3045 3425 3262 
f 2993 3423 3239 
f 2974 3312 3291 
f 2906 3429 3264 
f 3240 3432 3330 
f 3186 3471 3241 
f 3355 3438 3370 
f 2959 3258 2972 
f 2939 3300 3307 
f 3033 3473 3281 
f 3030 3463 3266 
f 2971 3444 3267 
f 3244 3431 3349 
f 3230 3442 3220 
f 2963 3455 3245 
f 2921 3304 3289 
f 2900 3277 2933 
f 3105 3437 3227 
f 3247 3369 3352 
f 3077 3305 3290 
f 3056 3279 3089 
f 3175 3459 3206 
f 3194 3294 3278 
f 2917 3288 3302 
f 2896 3250 3270 
f 3073 3287 3303 
f 3052 3251 3271 
f 3405 3446 3371 
f 3118 3475 3252 
f 3364 3331 3253 
f 3363 3373 3232 
f 3190 3293 3308 
f 3169 3254 3276 
f 3410 3283 3457 
f 3157 3283 3410 
f 3256 3440 3447 
f 2902 3460 3277 
f 3058 3461 3279 
f 3257 3435 3448 
f 2957 3284 3258 
f 2937 3307 3296 
f 3041 3456 3209 
f 3406 3324 3434 
f 3151 3309 3313 
f 3130 3260 3280 
f 3354 3443 3403 
f 3196 3482 3249 
f 3243 3426 3356 
f 3262 3425 3225 
f 3263 3327 3222 
f 2991 3484 3408 
f 3264 3429 3231 
f 3357 3447 3440 
f 3213 3415 3437 
f 3227 3437 3415 
f 3049 3439 3269 
f 3356 3436 3243 
f 3267 3444 3407 
f 3407 3472 3267 
f 2943 3411 3285 
f 3285 3411 3245 
f 3269 3439 3266 
f 3266 3463 3269 
f 3236 3462 3452 
f 2915 3452 3288 
f 3237 3464 3451 
f 3071 3451 3287 
f 3371 3272 3405 
f 3272 3352 3369 
f 3209 3456 3259 
f 3297 3467 3273 
f 3097 3458 3292 
f 3252 3475 3458 
f 3110 3453 3286 
f 3265 3481 3453 
f 3241 3471 3454 
f 3188 3454 3293 
f 3246 3485 3460 
f 2923 3485 3246 
f 3249 3482 3459 
f 3206 3459 3482 
f 3079 3483 3248 
f 3248 3483 3461 
f 3409 3480 3421 
f 3149 3421 3309 
f 3281 3473 3050 
f 3013 3295 3050 
f 3282 3422 3476 
f 2978 3282 3311 
f 3134 3310 3255 
f 3155 3314 3283 
f 3284 3466 3242 
f 2935 3296 3242 
f 3300 3468 3268 
f 3245 3455 3285 
f 3275 3298 3286 
f 3286 3453 3481 
f 3271 3251 3287 
f 3287 3451 3464 
f 3270 3250 3288 
f 3288 3452 3462 
f 3277 3460 3485 
f 3289 3304 3277 
f 3279 3461 3483 
f 3290 3305 3279 
f 3239 3423 3484 
f 3408 3484 3423 
f 3292 3458 3475 
f 3274 3128 3292 
f 3276 3254 3293 
f 3293 3454 3471 
f 3278 3294 3206 
f 3173 3206 3306 
f 3050 3295 3281 
f 3218 3281 3295 
f 3242 3296 3284 
f 3258 3284 3296 
f 3299 3297 3216 
f 3273 3216 3297 
f 3298 3301 3215 
f 3215 3286 3298 
f 3295 3299 3218 
f 3216 3218 3299 
f 3307 3300 2972 
f 3268 3487 3300 
f 3301 3292 3128 
f 3128 3215 3301 
f 3250 2933 3302 
f 3302 3288 3250 
f 3251 3089 3303 
f 3303 3287 3251 
f 2933 3277 3304 
f 3304 3302 2933 
f 3089 3279 3305 
f 3305 3303 3089 
f 3306 3206 3294 
f 3294 3308 3306 
f 3296 3307 3258 
f 2972 3258 3307 
f 3254 3306 3308 
f 3308 3293 3254 
f 3280 3260 3309 
f 3309 3421 3480 
f 3310 3457 3283 
f 3283 3314 3310 
f 3217 3315 3282 
f 2999 3315 3217 
f 3291 3312 3239 
f 2995 3239 3316 
f 3260 3255 3313 
f 3313 3309 3260 
f 3255 3310 3314 
f 3314 3313 3255 
f 3311 3282 3315 
f 3315 3316 3311 
f 3312 3311 3316 
f 3316 3239 3312 
f 3350 3167 3413 
f 3143 3167 3387 
f 2983 3011 3318 
f 3318 3412 3343 
f 3220 3319 3230 
f 2946 3395 3220 
f 3412 3320 3322 
f 2984 3394 3011 
f 3413 3321 3350 
f 3163 3392 3208 
f 3004 3404 3365 
f 3207 3322 3320 
f 3180 3382 3323 
f 3417 3221 3233 
f 3418 3225 3425 
f 3024 3384 3324 
f 3374 3377 3033 
f 3029 3449 3043 
f 3326 3184 3183 
f 3375 3470 3175 
f 3327 3207 3337 
f 3006 3393 3372 
f 3390 3469 2915 
f 2912 3427 2926 
f 3201 3381 3400 
f 3329 3233 3221 
f 2928 3385 3401 
f 3330 3231 3240 
f 3084 3386 3402 
f 3331 3232 3373 
f 3146 3332 3153 
f 3332 3359 3164 
f 3333 3362 3131 
f 3145 3414 3140 
f 3185 3334 3191 
f 3334 3360 3205 
f 3424 3335 2956 
f 3380 2951 2965 
f 3336 3219 3229 
f 3160 3219 3388 
f 3337 3222 3327 
f 2987 3486 3389 
f 3338 3068 3084 
f 3419 3338 3075 
f 3339 3028 3031 
f 3361 3339 3021 
f 2950 3340 2936 
f 3351 2950 2947 
f 3450 3067 3055 
f 3420 3341 3064 
f 3106 3379 3108 
f 3342 3106 3100 
f 3322 3343 3412 
f 3003 3433 3404 
f 3344 3398 3001 
f 2990 3344 3010 
f 3345 2989 2992 
f 3376 3345 2981 
f 3346 3317 3416 
f 3141 3399 3346 
f 3391 3347 3127 
f 3347 3428 3119 
f 3348 2911 2904 
f 3474 3348 2899 
f 3349 3234 3244 
f 2948 3234 3383 
f 3317 3350 3321 
f 3142 3387 3399 
f 3340 3351 2942 
f 3103 3465 3378 
f 3352 3227 3247 
f 3353 3229 3219 
f 3138 3229 3353 
f 3233 3354 3323 
f 3200 3477 3381 
f 3221 3355 3329 
f 3181 3479 3382 
f 3225 3356 3426 
f 3025 3478 3384 
f 2927 3357 3385 
f 3231 3357 3264 
f 3232 3358 3214 
f 3083 3358 3386 
f 3359 3146 3160 
f 3360 3185 3200 
f 3028 3361 3016 
f 3362 3145 3135 
f 3214 3363 3232 
f 3062 3363 3214 
f 3087 3364 3224 
f 3224 3364 3441 
f 3005 3365 3393 
f 3365 3322 3207 
f 2931 3366 3223 
f 3223 3366 3445 
f 3367 3336 3321 
f 3162 3367 3392 
f 3368 3337 3320 
f 2985 3368 3394 
f 3369 3247 3488 
f 3369 3228 3272 
f 3204 3370 3210 
f 3210 3370 3438 
f 3226 3371 3446 
f 3099 3371 3226 
f 3372 3207 3327 
f 3007 3372 3397 
f 3373 3253 3331 
f 3373 3363 3253 
f 3325 3374 3037 
f 3184 3375 3171 
f 2989 3376 2977 
f 3377 3029 3047 
f 3378 3352 3272 
f 3352 3378 3465 
f 3379 3342 3094 
f 3335 3380 2970 
f 3381 3233 3329 
f 3233 3381 3477 
f 3221 3382 3479 
f 3382 3221 3417 
f 3383 3349 3319 
f 3349 3383 3234 
f 3384 3225 3418 
f 3225 3384 3478 
f 3385 3231 3330 
f 3231 3385 3357 
f 3386 3232 3331 
f 3232 3386 3358 
f 3350 3387 3167 
f 3387 3350 3317 
f 3336 3367 3388 
f 3336 3388 3219 
f 3337 3389 3486 
f 3337 3368 3389 
f 3328 3390 2919 
f 3107 3391 3112 
f 3321 3208 3392 
f 3321 3392 3367 
f 3207 3372 3393 
f 3207 3393 3365 
f 3320 3394 3368 
f 3320 3011 3394 
f 3319 3395 3383 
f 3319 3220 3395 
f 3272 3396 3378 
f 3272 3371 3396 
f 3327 3263 3397 
f 3327 3397 3372 
f 3398 2990 2996 
f 3317 3399 3387 
f 3317 3346 3399 
f 3329 3370 3400 
f 3329 3400 3381 
f 3330 3366 3401 
f 3330 3401 3385 
f 3331 3364 3402 
f 3331 3402 3386 
f 3177 3403 3261 
f 3261 3403 3443 
f 3322 3404 3433 
f 3322 3365 3404 
f 3121 3405 3228 
f 3228 3405 3272 
f 3259 3406 3209 
f 3021 3406 3259 
f 3234 3407 3444 
f 2953 3407 3234 
f 3222 3408 3263 
f 2992 3408 3222 
f 3148 3409 3167 
f 3167 3409 3235 
f 3219 3410 3353 
f 3158 3410 3219 
f 2944 3220 3411 
f 3411 3220 3442 
f 3011 3320 3412 
f 3412 3318 3011 
f 3413 3235 3208 
f 3208 3321 3413 
f 3414 3333 3144 
f 3415 3247 3227 
f 3125 3247 3415 
f 3416 3229 3346 
f 3229 3416 3336 
f 3323 3417 3233 
f 3417 3323 3382 
f 3324 3418 3238 
f 3418 3324 3384 
f 3068 3419 3078 
f 3067 3420 3061 
f 3166 3235 3421 
f 3421 3235 3409 
f 2981 3318 3422 
f 3422 3318 3343 
f 3010 3263 3423 
f 3423 3263 3408 
f 2951 3424 2961 
f 3425 3238 3418 
f 3044 3238 3425 
f 3426 3262 3225 
f 3046 3262 3426 
f 3427 3328 2923 
f 3428 3107 3116 
f 3429 3240 3231 
f 2907 3240 3429 
f 3430 3230 3319 
f 2966 3230 3430 
f 3431 3211 3349 
f 2968 3211 3431 
f 2909 3212 3432 
f 3432 3212 3330 
f 3433 3343 3322 
f 3002 3343 3433 
f 3042 3209 3434 
f 3434 3209 3406 
f 3061 3214 3435 
f 3435 3214 3358 
f 3031 3266 3436 
f 3436 3266 3439 
f 3437 3265 3213 
f 3109 3265 3437 
f 3438 3241 3210 
f 3187 3241 3438 
f 3048 3243 3439 
f 3439 3243 3436 
f 2905 3264 3440 
f 3440 3264 3357 
f 3441 3237 3224 
f 3070 3237 3441 
f 2964 3245 3442 
f 3442 3245 3411 
f 3443 3249 3261 
f 3197 3249 3443 
f 2970 3244 3444 
f 3444 3244 3234 
f 2914 3236 3445 
f 3445 3236 3223 
f 3446 3252 3226 
f 3119 3252 3446 
f 2924 3246 3447 
f 3447 3246 3256 
f 3448 3248 3257 
f 3080 3248 3448 
f 3449 3325 3040 
f 3341 3450 3069 
f 3451 3224 3237 
f 3088 3224 3451 
f 3452 3223 3236 
f 2932 3223 3452 
f 3127 3213 3453 
f 3453 3213 3265 
f 3454 3210 3241 
f 3205 3210 3454 
f 2962 3268 3455 
f 3455 3268 3468 
f 3456 3273 3467 
f 3040 3273 3456 
f 3137 3353 3457 
f 3457 3353 3410 
f 3098 3226 3458 
f 3458 3226 3252 
f 3459 3261 3249 
f 3176 3261 3459 
f 2903 3256 3460 
f 3460 3256 3246 
f 3461 3257 3248 
f 3059 3257 3461 
f 2895 3270 3462 
f 3462 3270 3288 
f 3463 3050 3473 
f 3012 3050 3463 
f 3464 3271 3287 
f 3051 3271 3464 
f 3104 3227 3465 
f 3352 3465 3227 
f 2954 3267 3466 
f 3466 3267 3472 
f 3020 3259 3467 
f 3467 3259 3456 
f 3468 3285 3455 
f 2942 3285 3468 
f 3469 2912 2930 
f 3470 3326 3178 
f 3471 3276 3293 
f 3168 3276 3471 
f 3472 3242 3466 
f 2934 3242 3472 
f 3032 3269 3473 
f 3473 3269 3463 
f 2911 3474 2895 
f 3117 3274 3475 
f 3475 3274 3292 
f 3000 3217 3476 
f 3476 3217 3282 
f 3233 3477 3354 
f 3199 3354 3477 
f 3026 3356 3478 
f 3225 3478 3356 
f 3221 3479 3355 
f 3182 3355 3479 
f 3480 3280 3309 
f 3129 3280 3480 
f 3481 3275 3286 
f 3090 3275 3481 
f 3195 3278 3482 
f 3482 3278 3206 
f 3078 3290 3483 
f 3483 3290 3279 
f 3484 3291 3239 
f 2973 3291 3484 
f 2922 3289 3485 
f 3485 3289 3277 
f 2988 3222 3486 
f 3337 3486 3222 
f 3487 2972 3300 
f 2960 2972 3487 
f 3122 3228 3488 
f 3369 3488 3228 
# 0 polygons - 1131 triangles

#
# object Line013
#

v  14.874490 5.703322 -11.751333
v  14.633232 5.696877 -11.779291
v  14.568656 5.462926 -11.337112
v  14.712942 5.277054 -11.221913
v  14.917203 5.659111 -11.574165
v  14.547523 5.639936 -11.613173
v  14.548551 5.538380 -11.426243
v  14.783453 5.335703 -11.234337
v  14.859383 5.519561 -11.357760
v  14.636167 5.316973 -11.258856
v  14.920439 5.690663 -11.672022
v  14.722851 5.698573 -11.802657
v  14.825543 5.705431 -11.783507
v  14.597229 5.687868 -11.743173
v  14.817706 5.417410 -11.267673
v  14.680886 5.280381 -11.231780
v  14.924294 5.674685 -11.616712
v  14.566587 5.671567 -11.687859
v  14.902292 5.627943 -11.517199
v  14.553422 5.514092 -11.390905
v  14.900885 5.698833 -11.719897
# 21 vertices

o Line013
g Line013
usemtl 11___Defadwdlt
s 1
f 3494 3495 3507 
f 3495 3503 3497 
f 3502 3499 3509 
f 3502 3506 3493 
f 3495 3498 3504 
f 3495 3492 3496 
f 3495 3497 3507 
f 3495 3508 3498 
f 3502 3493 3505 
f 3502 3501 3500 
f 3502 3489 3501 
f 3490 3502 3500 
f 3495 3496 3503 
f 3495 3504 3492 
f 3502 3505 3499 
f 3506 3494 3493 
f 3494 3507 3493 
f 3508 3491 3498 
f 3502 3509 3489 
# 0 polygons - 19 triangles

#
# object cloth_1
#

v  14.586706 5.503397 -4.393867
v  14.451097 3.076895 -4.695686
v  15.447550 5.652051 -4.931047
v  14.403800 5.334062 -4.949931
v  15.051249 3.540250 -4.539842
v  14.909362 6.315036 -5.113065
v  14.519913 5.370326 -4.566299
v  14.762780 3.026034 -5.200352
v  14.322418 3.062219 -4.716649
v  15.238018 4.923841 -4.700044
v  15.389153 6.250916 -4.793861
v  15.160098 5.146085 -5.059574
v  15.021791 5.400745 -4.503479
v  14.263939 4.164861 -4.849098
v  15.177597 5.753359 -5.125570
v  15.466797 5.650446 -4.522699
v  15.070744 3.107095 -5.061258
v  14.266731 5.227744 -4.672672
v  15.048314 4.670380 -5.143465
v  15.073129 3.082601 -4.606960
v  14.839657 3.837346 -4.427406
v  14.819377 6.127180 -4.471620
v  14.870235 5.618272 -4.416811
v  15.006936 5.040635 -4.514723
v  14.830678 5.455389 -5.043046
v  15.160837 5.430528 -4.660595
v  14.508326 4.490598 -4.517332
v  15.020698 4.296604 -4.500204
v  14.665236 3.907526 -5.191342
v  15.167215 6.399195 -4.871172
v  15.343432 6.192301 -4.987446
v  15.203233 3.984079 -4.734870
v  14.338680 3.644601 -4.636291
v  14.842736 5.036519 -4.404670
v  15.234215 5.499194 -4.955344
v  14.947218 6.413260 -4.914668
v  15.304640 5.927835 -4.541048
v  14.789307 4.814289 -5.193269
v  14.554298 5.105309 -4.456419
v  14.271257 4.933669 -4.741791
v  14.387880 3.436888 -5.044416
v  15.244707 6.308009 -4.639073
v  14.389915 5.048758 -5.055385
v  15.084162 4.503019 -4.567335
v  14.732237 3.249556 -4.487583
v  14.845662 5.812665 -5.215626
v  15.216832 5.224368 -4.895418
v  15.057042 4.895866 -4.446043
v  15.064452 6.195810 -5.149533
v  14.993805 3.792316 -4.503446
v  14.378749 4.218135 -5.096705
v  15.099333 4.208483 -5.098720
v  14.802130 5.073966 -5.154566
v  14.840511 3.360123 -5.195790
v  14.617737 4.461518 -4.535865
v  15.092752 3.562144 -5.069323
v  15.187717 5.708712 -4.535090
v  14.869658 4.347756 -4.474840
v  15.113682 5.224931 -4.579521
v  14.289124 4.588407 -4.912360
v  15.431542 6.070442 -4.846082
v  15.274494 6.329884 -4.953974
v  15.219980 4.364216 -4.840022
v  14.609233 4.896090 -4.507202
v  14.567300 3.660234 -4.504853
v  14.390053 4.820151 -4.600518
v  14.404545 5.214700 -4.484066
v  15.073739 5.857069 -4.484423
v  15.119907 5.560637 -4.986759
v  14.975248 6.222365 -4.482306
v  14.980131 6.339345 -5.135729
v  15.175100 4.971461 -5.051672
v  14.656192 3.512781 -4.562459
v  14.405931 4.291984 -4.571780
v  14.900012 3.533923 -4.485012
v  14.647806 5.262470 -5.073816
v  15.124558 4.749217 -4.599180
v  14.535571 3.020784 -5.101698
v  14.478874 5.114225 -4.515523
v  14.550995 5.495720 -5.017012
v  14.406501 5.386858 -4.474245
v  15.011539 5.593741 -5.106150
v  14.902535 3.746214 -4.523912
v  14.875978 6.324872 -4.631545
v  15.087791 5.251875 -4.996944
v  14.959156 4.016845 -4.482568
v  14.353739 5.211029 -4.979198
v  15.268071 5.357864 -4.800727
v  14.300619 5.194143 -4.885692
v  14.302913 5.024729 -4.660748
v  15.221472 5.042933 -4.831192
v  15.165676 3.681152 -4.689576
v  14.408510 3.809200 -4.617055
v  15.040787 6.403314 -4.723446
v  14.975945 4.360218 -4.454996
v  15.159838 6.102089 -4.502447
v  15.312902 5.825700 -4.485294
v  15.164015 3.542342 -4.948066
v  14.982513 4.187285 -4.542075
v  15.070238 3.504057 -4.575142
v  14.282817 3.193282 -4.772770
v  15.559739 5.609364 -4.713800
v  15.382012 6.151840 -4.769146
v  14.895500 4.177327 -5.183486
v  15.318010 5.584144 -5.011678
v  14.316938 4.473164 -4.794151
v  14.528538 4.644739 -4.539142
v  15.126751 3.067950 -4.750576
v  14.942673 5.875307 -4.417999
v  15.085535 4.990797 -5.104777
v  14.699278 4.386293 -4.449800
v  14.915401 6.089375 -5.221823
v  15.198417 4.827138 -4.800252
v  14.863357 5.987418 -4.450668
v  14.417913 3.897296 -5.096902
v  14.681396 3.911542 -4.428357
v  15.142305 4.602584 -5.068798
v  14.799522 3.669921 -5.193941
v  14.465302 4.996002 -4.502985
v  15.413713 5.924156 -4.597876
v  15.236557 5.399864 -4.971631
v  14.379496 4.790173 -5.074678
v  15.081562 3.958784 -5.100011
v  14.733141 4.940969 -5.181859
v  15.026103 5.447114 -4.534695
v  15.124249 5.353477 -4.577982
v  14.724567 5.411208 -4.515717
v  14.608576 3.395153 -4.544045
v  14.576900 5.354721 -4.390898
v  15.014400 4.828346 -4.520929
v  15.354332 5.991755 -5.020508
v  14.851463 5.623487 -5.140830
v  14.724949 5.764963 -4.448340
v  14.312948 5.002245 -4.929299
v  15.107986 6.355947 -5.042493
v  15.156904 4.079513 -4.643417
v  14.792648 5.839570 -5.185236
v  14.311880 3.256135 -4.660749
v  15.201963 6.252732 -5.084485
v  14.999738 3.526120 -4.500797
v  14.633574 4.937538 -4.466356
v  15.165401 5.076349 -5.013926
v  14.348702 5.293032 -4.985689
v  14.775440 5.519027 -4.439076
v  14.941193 5.138001 -4.484118
v  14.793007 3.736474 -4.478096
v  14.291982 4.198946 -4.981687
v  14.320032 3.752513 -4.928092
v  15.123421 5.481605 -5.027484
v  15.035232 4.899088 -4.420849
v  14.800171 4.280564 -4.416031
v  14.963530 5.249264 -4.424919
v  14.970479 3.799549 -4.451599
v  15.095965 5.785890 -5.109574
v  14.410858 3.093864 -4.612843
v  15.153007 4.847157 -5.029713
v  14.468294 3.805104 -4.537263
v  14.997993 4.196789 -4.455263
v  14.593205 3.995149 -4.506445
v  14.707586 3.271932 -5.201509
v  14.345387 4.698421 -4.657483
v  15.155373 5.597143 -5.044165
v  15.040222 5.351552 -4.467365
v  14.868302 4.626592 -5.177269
v  14.259584 5.129660 -4.770914
v  15.161110 4.075578 -5.018422
v  15.255507 5.669001 -4.497113
v  15.258549 5.967648 -4.496399
v  15.190121 5.155864 -4.619640
v  14.329706 5.121731 -4.577665
v  15.161556 4.974134 -4.582937
v  15.146832 3.276701 -4.660133
v  14.568665 5.256409 -4.465913
v  14.825824 4.891656 -4.487081
v  15.027517 4.467571 -4.488045
v  14.919877 6.202450 -4.511544
v  15.247999 5.408402 -4.891370
v  14.418892 4.115050 -4.606474
v  15.384671 6.087580 -4.680140
v  15.112448 6.262409 -4.532470
v  14.337139 5.124468 -4.951790
v  15.039382 3.234735 -5.109223
v  14.321226 3.726751 -4.743376
v  15.362000 5.762060 -5.027527
v  14.926108 3.839173 -4.416863
v  14.328236 4.227915 -4.659441
v  14.977694 3.084437 -4.583966
v  14.337419 4.396720 -5.012655
v  14.878620 3.981031 -5.162733
v  15.189507 4.575846 -4.744494
v  14.560903 4.550088 -4.494257
v  14.313223 4.841545 -4.889104
v  14.740637 5.555089 -5.074789
v  15.057913 4.284615 -5.121003
v  14.807552 5.252848 -5.106759
v  15.145718 3.826659 -5.035570
v  14.657473 5.266650 -4.414624
v  15.442126 5.830009 -4.944777
v  14.971289 6.143360 -5.146557
v  15.206977 4.265987 -4.734973
v  14.312372 5.266649 -4.742401
v  14.630869 5.140717 -5.118596
v  15.284073 6.352057 -4.845329
v  15.033669 4.355667 -4.563139
v  15.066750 5.174407 -5.073528
v  14.990143 5.376991 -4.466959
v  14.923446 5.813140 -5.178705
v  15.344885 6.191655 -4.659639
v  15.368363 6.114351 -4.928005
v  15.088386 4.415578 -5.090584
v  14.774984 3.434811 -5.177949
v  14.683653 4.208704 -5.190088
v  14.361242 5.090097 -4.612899
v  15.027453 5.932817 -4.451043
v  14.474306 5.382632 -4.405009
v  15.049946 3.654204 -4.515581
v  14.376347 4.926723 -4.578194
v  15.200414 5.535762 -4.948660
v  15.029715 5.435952 -5.024172
v  14.323092 5.201818 -4.689540
v  14.869150 6.085288 -5.199579
v  15.202211 3.734036 -4.778550
v  14.364906 4.547276 -4.680189
v  15.171879 3.371678 -4.810755
v  14.656587 5.454145 -4.398674
v  14.724760 3.587803 -4.475965
v  15.014059 6.141796 -4.453773
v  15.003197 6.432004 -4.852758
v  14.862207 5.421079 -4.438790
v  14.325375 5.280209 -4.561501
v  15.273628 6.242186 -4.667024
v  14.783337 4.963726 -4.427740
v  15.251331 5.162354 -4.884862
v  15.120650 6.360680 -4.645342
v  15.192668 5.303472 -4.639181
v  15.040846 5.781971 -4.453935
v  15.236409 4.956059 -4.924400
v  15.368885 5.788615 -4.525186
v  15.204802 6.348288 -4.764061
v  14.462803 3.401333 -5.109112
v  15.250509 6.010329 -5.055643
v  15.018236 5.045844 -4.436091
v  14.367176 3.333546 -4.601840
v  14.355474 4.833354 -5.037708
v  15.095641 5.105851 -5.071485
v  15.005252 5.236665 -5.032737
v  15.435775 5.993303 -4.782011
v  14.994501 4.930274 -4.528832
v  14.349443 3.035958 -4.856647
v  14.681704 4.628950 -5.184909
v  14.437183 3.718474 -5.108132
v  15.116358 5.733200 -4.519184
v  14.955365 4.458737 -4.453668
v  14.489109 5.213474 -5.041807
v  15.021533 4.090169 -4.518116
v  15.069081 6.036053 -5.149815
v  14.671218 5.377846 -5.083282
v  14.382853 4.410362 -5.078444
v  15.036488 5.552456 -5.037867
v  14.349453 4.101832 -5.017219
v  15.066603 4.596180 -4.588209
v  14.893375 4.133843 -4.484342
v  14.452484 4.355146 -4.540501
v  14.605700 4.864687 -5.150382
v  15.209776 3.982143 -4.833161
v  14.274208 5.024515 -4.798660
v  15.051157 4.874868 -4.570087
v  14.781286 3.435024 -4.480592
v  14.326080 4.983947 -4.681872
v  14.649454 3.672208 -5.170866
v  14.480164 5.066907 -5.101411
v  14.938910 5.297957 -4.510543
v  15.219266 6.014655 -4.500492
v  15.448483 5.905762 -4.702857
v  14.933060 3.778103 -4.421003
v  15.091819 5.612065 -4.554825
v  15.007759 6.331872 -4.599427
v  14.474844 3.239726 -4.655813
v  14.585965 4.957269 -4.502111
v  14.951678 4.692203 -5.177270
v  15.060354 3.469428 -5.103010
v  14.909321 6.337265 -4.614726
v  15.198799 5.038290 -4.746637
v  14.368961 5.173613 -4.520767
v  14.442484 5.379800 -5.011988
v  14.984955 4.993892 -5.129968
v  15.044306 4.218304 -5.139240
v  15.117591 3.762371 -4.628215
v  15.087491 3.349912 -5.062706
v  14.936150 3.688980 -4.496423
v  15.113213 5.917724 -4.507334
v  15.138829 6.222599 -5.101992
v  14.798489 4.674884 -4.430418
v  14.848768 6.136406 -4.451319
v  14.794471 4.987353 -5.151992
v  15.254850 5.323217 -4.904239
v  14.948578 3.038751 -5.134509
v  15.191243 3.682387 -4.912693
v  14.874472 5.594738 -5.107976
v  14.419230 4.968828 -4.537610
v  15.092448 6.404895 -4.887871
v  14.987570 3.322903 -4.557886
v  14.455914 5.295268 -4.470877
v  14.677134 5.487216 -5.057626
v  14.317945 4.326120 -4.794171
v  15.163242 4.273579 -5.025282
v  15.137902 4.170082 -4.660052
v  14.821917 5.920769 -4.433632
v  14.947216 5.163817 -4.433465
v  14.919894 4.351095 -4.545095
v  15.149342 5.982242 -5.132080
v  14.990955 6.281677 -5.162375
v  14.414656 3.719412 -4.565577
v  14.799335 4.092822 -5.201111
v  14.620123 4.240399 -4.536568
v  14.287609 3.462712 -4.746630
v  14.349737 4.035633 -4.676934
v  14.314889 4.436532 -4.912987
v  15.263371 5.510695 -4.951719
v  15.039092 3.508700 -4.519948
v  14.359690 5.352365 -4.567300
v  14.304604 5.089232 -4.719661
v  14.402052 4.621610 -4.630027
v  14.459507 4.009095 -4.599595
v  15.240137 5.576098 -5.005966
v  15.031577 4.083094 -4.580063
v  14.674623 4.602034 -4.498184
v  14.470976 4.113443 -5.118565
v  14.579394 4.756115 -4.506775
v  15.346292 6.145524 -4.735393
v  15.053679 5.639892 -5.119502
v  14.621027 4.608884 -4.495572
v  14.694307 3.014726 -5.185982
v  15.024662 6.278768 -5.122383
v  15.105860 3.839622 -5.069760
v  14.867550 5.385742 -4.528448
v  14.354758 3.541690 -4.993211
v  14.805678 5.329009 -5.056250
v  14.793339 3.785966 -4.414428
v  14.624805 3.732219 -4.459580
v  14.869667 4.372093 -5.179582
v  14.682420 3.445541 -4.530412
v  14.954778 4.285205 -4.464643
v  14.294764 4.641823 -4.848851
v  15.163995 6.332250 -5.014326
v  15.138562 4.816959 -4.661775
v  14.475664 5.156035 -4.510856
v  15.129630 5.298075 -4.984744
v  14.874968 5.555019 -4.419451
v  15.215736 4.576596 -4.837911
v  15.111915 3.346024 -4.594576
v  14.869894 3.802847 -5.170114
v  14.961042 5.124031 -5.110981
v  15.037228 4.583688 -4.470241
v  14.282957 5.267090 -4.875627
v  15.151505 3.644869 -5.009486
v  15.027035 5.323431 -4.543005
v  14.312894 4.080128 -4.915255
v  14.686046 5.664267 -5.115294
v  15.207978 4.262261 -4.837834
v  14.777988 5.844913 -4.408692
v  15.165276 3.897089 -4.698300
v  14.897894 5.244357 -4.428466
v  15.185995 4.767322 -4.920362
v  15.159523 4.661501 -4.645221
v  14.863577 3.758951 -4.503281
v  15.179358 6.283467 -4.601456
v  14.792562 3.674472 -4.489142
v  15.112541 5.567933 -4.517628
v  14.369268 4.385188 -4.680792
v  14.934494 4.056597 -4.478724
v  15.198908 4.472732 -4.838807
v  15.237886 6.357366 -4.804976
v  15.258408 5.293219 -4.763116
v  15.131739 6.121949 -4.484423
v  15.276262 6.141222 -5.059206
v  14.726742 4.094811 -4.433188
v  14.631280 3.270831 -5.166805
v  14.726563 4.729938 -4.462736
v  14.512717 5.091227 -4.477293
v  14.485306 3.903380 -5.149031
v  14.735039 5.382514 -5.051516
v  15.211191 5.173985 -4.986136
v  14.370355 5.231045 -4.527413
v  14.635658 4.098508 -4.544803
v  14.863776 3.332412 -4.486782
v  15.147031 4.491512 -5.016662
v  15.029359 4.444174 -4.569154
v  14.872293 3.198394 -5.178649
v  15.159634 6.155691 -5.126619
v  14.380397 4.761328 -4.600605
v  14.623286 5.355795 -4.435411
v  15.226403 5.697070 -4.540684
v  15.008821 4.942940 -4.413345
v  14.425646 4.861223 -4.547819
v  14.452034 4.727478 -5.119624
v  15.315926 6.303594 -4.837881
v  15.030798 3.599341 -4.510300
v  14.341862 4.914616 -4.984542
v  14.962388 5.794516 -4.449634
v  14.969541 6.371061 -5.083832
v  14.854168 5.128798 -4.505807
v  14.530584 4.985660 -5.104084
v  15.025990 4.422517 -5.134129
v  15.216076 5.844402 -4.515899
v  15.261812 5.766962 -5.072328
v  14.776872 5.664366 -5.150689
v  14.783076 5.469635 -4.432930
v  14.977623 3.734177 -4.514674
v  14.459593 3.583044 -5.096026
v  14.795648 5.668458 -4.424940
v  15.284216 6.265203 -4.981580
v  14.325319 4.547895 -4.795104
v  14.355939 5.091949 -5.007255
v  15.185868 4.590410 -4.997821
v  15.278065 6.070527 -4.558451
v  14.472584 4.951856 -4.517427
v  14.475268 4.510631 -4.561390
v  15.397619 5.825324 -4.561235
v  14.939460 5.619915 -4.458941
v  14.316628 4.824116 -4.771194
v  14.293861 4.415338 -4.853665
v  15.170479 3.574721 -4.774726
v  15.100211 5.291696 -4.606621
v  15.102361 5.423068 -4.604402
v  15.375791 5.977688 -4.662773
v  15.147779 5.904126 -4.482346
v  15.232099 5.281204 -4.867925
v  14.290304 3.192484 -4.820139
v  15.217403 5.796402 -4.507034
v  15.075765 5.490988 -5.020601
v  14.610052 5.079088 -4.455487
v  14.866142 3.572047 -4.482010
v  15.400795 6.250841 -4.839208
v  14.996370 5.645076 -5.107734
v  14.323901 3.413924 -4.655788
v  14.918639 6.126263 -4.489996
v  14.657494 4.007069 -5.175035
v  14.988452 5.504763 -4.497610
v  14.412746 5.331989 -4.999316
v  14.840555 3.771234 -4.436664
v  15.144306 5.082170 -4.637351
v  14.947653 3.752876 -4.487974
v  15.257257 4.933598 -4.756849
v  14.873501 4.871218 -5.162445
v  14.872324 5.020497 -5.146995
v  15.038616 3.938839 -5.126303
v  14.856014 3.494525 -5.163338
v  15.138523 6.414856 -4.824397
v  15.233992 5.860885 -5.109837
v  14.958265 6.180443 -5.174132
v  14.395247 5.128343 -4.556126
v  15.063271 5.925914 -4.447187
v  14.299971 5.204035 -4.810808
v  14.292845 4.260105 -4.853031
v  14.432974 3.076670 -5.008414
v  14.652630 4.241120 -4.493360
v  15.026130 4.266376 -4.580749
v  15.221604 6.272952 -4.592394
v  15.152517 3.984379 -5.012013
v  14.990381 6.353908 -4.677634
v  15.323856 5.656780 -4.506939
v  14.682051 3.692297 -4.472142
v  15.335852 5.899903 -4.510529
v  14.808634 4.351086 -4.433044
v  14.320860 5.193352 -4.565012
v  14.322722 4.559169 -5.015012
v  15.414125 6.013060 -4.944824
v  15.010859 3.643129 -5.119243
v  14.957875 5.819757 -5.164960
v  14.959181 5.051552 -5.117826
v  15.102385 3.226910 -5.032579
v  14.529493 3.900224 -4.519793
v  15.186691 4.167827 -4.741323
v  15.229805 4.998733 -4.719280
v  15.050786 4.988861 -4.456252
v  15.188887 4.468984 -4.687276
v  14.736460 3.558754 -5.178333
v  15.126596 3.487438 -4.614901
v  14.859298 5.852466 -5.204902
v  15.156267 5.959445 -4.488545
v  15.164688 4.909982 -5.057650
v  14.589774 5.181034 -5.083663
v  14.476856 4.788096 -4.534750
v  14.759926 3.930273 -4.423082
v  14.537080 4.712695 -4.544753
v  14.856400 4.394927 -4.458877
v  14.949932 6.076580 -4.439236
v  14.687957 3.665427 -4.548839
v  14.545538 3.464462 -4.536552
v  14.361753 3.720271 -4.643797
v  15.077915 4.970981 -4.532043
v  15.030396 4.131141 -5.128921
v  14.759388 4.698524 -5.178066
v  14.292248 4.360966 -4.915684
v  14.933108 4.656584 -4.447430
v  14.589514 3.804742 -4.522681
v  14.866356 4.178295 -4.547339
v  15.039083 3.298687 -5.099962
v  15.224411 5.448935 -4.979738
v  14.725838 4.758905 -5.170107
v  15.080549 4.757354 -4.553601
v  14.502354 4.412327 -4.517665
v  15.385014 6.182189 -4.839807
v  14.518339 5.012305 -4.480638
v  14.915762 5.248631 -5.079335
v  15.181383 5.674459 -4.511234
v  15.372094 6.178943 -4.765795
v  14.950793 5.051678 -4.415761
v  15.199555 5.056814 -4.942045
v  15.361404 6.155997 -4.930963
v  15.024557 5.323597 -4.441475
v  14.952167 4.888593 -4.416053
v  14.695107 4.938011 -5.176973
v  14.313118 3.652774 -4.865999
v  14.297567 4.984235 -4.805491
v  14.333038 5.285827 -4.863019
v  14.932920 4.988933 -4.504165
v  14.971275 5.376390 -5.055150
v  15.196754 4.074460 -4.788070
v  14.856304 5.898696 -4.452967
v  14.922063 6.225445 -5.176043
v  14.966770 4.627990 -4.536064
v  15.156054 5.235842 -4.660214
v  14.272356 4.937127 -4.815982
v  14.758901 5.784077 -4.436823
v  16.155396 5.596939 -4.329356
v  15.912839 3.089268 -4.573047
v  15.922821 3.099182 -5.052248
v  16.714314 6.248064 -5.023714
v  16.152946 5.450646 -4.488522
v  16.820374 3.083211 -5.018477
v  15.733810 4.419086 -4.865710
v  16.588505 3.431512 -4.476367
v  16.732988 5.403743 -5.059255
v  15.886897 3.098698 -4.543022
v  16.483648 6.166022 -4.401352
v  16.746473 3.001400 -4.619734
v  16.397266 4.727326 -4.468093
v  16.793520 4.706254 -4.606437
v  16.784454 3.957391 -5.148296
v  15.917957 4.145711 -5.174256
v  16.201515 5.872467 -5.012395
v  15.869013 4.836700 -5.099867
v  16.865017 6.303397 -4.689033
v  16.005434 5.286026 -4.562650
v  16.714701 5.633287 -4.488771
v  16.270996 5.050481 -5.300838
v  16.828182 3.646168 -4.676515
v  16.080860 3.835049 -4.421884
v  16.695246 5.654675 -4.613320
v  16.371771 4.217112 -5.313664
v  15.872319 4.108823 -4.567319
v  16.353220 5.135795 -4.397000
v  15.819201 3.568508 -5.020380
v  15.745800 3.386421 -4.609497
v  15.989566 5.428687 -4.737521
v  16.710888 3.374486 -5.218215
v  16.691002 5.836081 -5.067831
v  15.781662 4.967156 -4.905590
v  16.923075 4.164275 -4.634960
v  16.771597 5.908718 -4.450865
v  16.311846 3.149760 -5.175217
v  16.266014 3.543430 -4.527220
v  16.388988 5.883144 -4.268440
v  16.869503 4.820594 -5.065795
v  16.628525 6.432722 -4.844480
v  15.934221 4.996982 -4.590462
v  16.826252 3.839945 -4.546734
v  16.255888 4.506559 -4.422101
v  16.282204 5.455404 -5.113511
v  16.851961 5.038469 -4.672346
v  16.177849 4.141812 -5.236331
v  16.877708 3.847227 -4.867140
v  16.447813 6.303824 -4.992150
v  15.871864 3.471296 -4.477704
v  16.174953 5.009250 -4.440868
v  16.401228 4.638468 -5.318253
v  16.630157 4.973324 -4.469976
v  16.295645 3.921625 -4.483136
v  15.853039 4.455509 -4.612740
v  16.770447 3.742636 -5.084213
v  15.997100 5.200613 -5.107111
v  16.556511 5.902368 -5.131855
v  16.400913 3.529725 -4.416076
v  16.843746 4.618010 -4.729999
v  16.064226 5.470579 -4.908412
v  16.112219 5.115614 -4.521933
v  16.035437 3.592772 -5.129426
v  15.745097 4.062373 -4.893717
v  16.881084 3.266084 -4.931253
v  16.609423 4.273427 -4.492602
v  15.881723 4.609065 -5.131003
v  15.849764 3.974195 -5.077761
v  16.437941 4.903964 -5.291784
v  15.773874 4.751034 -4.897922
v  15.791928 3.079017 -4.722025
v  16.645760 6.331878 -5.040110
v  16.755470 5.367155 -4.501473
v  16.213957 5.211938 -4.417292
v  16.170746 3.636674 -4.486717
v  16.110210 4.606112 -4.559911
v  16.806940 3.943231 -4.596108
v  16.102585 4.053956 -5.262851
v  16.593178 3.873380 -5.210408
v  15.931692 4.690043 -4.607944
v  16.663721 5.314045 -5.065735
v  15.893716 5.274483 -4.856771
v  16.680796 6.146768 -4.445942
v  15.809558 3.156282 -4.975609
v  15.864336 3.932073 -4.490278
v  16.807379 4.867962 -4.793078
v  16.535091 6.380756 -4.692944
v  16.768814 5.270945 -4.677022
v  16.157955 4.148715 -4.539135
v  16.782070 4.273040 -5.166456
v  15.999650 4.812040 -5.231331
v  15.953370 3.352368 -4.492593
v  16.802004 3.242546 -4.619625
v  16.730530 5.102542 -4.538631
v  16.803759 4.322657 -4.555753
v  16.807270 4.088670 -5.068663
v  16.013147 4.089168 -4.455321
v  15.740075 3.681455 -4.678203
v  16.345264 5.930820 -5.075027
v  16.486622 3.671599 -5.281247
v  16.140100 3.079903 -4.611316
v  16.193882 4.866380 -4.496229
v  15.810144 4.582254 -4.942220
v  16.884693 4.343933 -5.046350
v  16.049469 5.188753 -4.500600
v  16.756943 5.740442 -4.819718
v  16.643707 6.417742 -4.740345
v  15.801615 4.349904 -5.029057
v  16.003819 3.879026 -5.162533
v  16.108906 4.398775 -5.261196
v  16.547901 5.454233 -5.045316
v  15.787632 4.240091 -4.783266
v  16.447456 3.267126 -5.181579
v  15.963726 3.444071 -5.148353
v  16.798244 2.982592 -4.715761
v  16.831835 3.349681 -5.110364
v  16.641850 4.916730 -5.234057
v  16.355989 4.476080 -5.271739
v  16.469322 4.496450 -4.483217
v  16.638515 5.171121 -4.579716
v  15.840920 4.819761 -4.776057
v  16.734510 6.062274 -4.493489
v  16.796007 3.586596 -4.996814
v  16.594770 5.617071 -4.454678
v  16.728283 5.598803 -5.009474
v  15.767330 3.168036 -4.582088
v  16.906322 4.074343 -4.760937
v  16.416176 5.013530 -4.458168
v  15.972704 4.415449 -4.569355
v  15.992641 4.167285 -4.548706
v  16.791634 4.273373 -4.625308
v  16.382404 5.316969 -4.384015
v  16.921795 4.445689 -4.692585
v  16.599201 4.257548 -5.213693
v  16.284639 5.664143 -5.046941
v  16.774763 6.334182 -4.678746
v  16.454494 3.916021 -4.479351
v  15.862928 3.620204 -4.491363
v  16.182575 4.226820 -4.432478
v  15.919775 3.652567 -5.059566
v  16.121529 5.104807 -5.240641
v  16.083303 5.396700 -4.422050
v  16.773396 3.635871 -4.578361
v  16.744558 6.393662 -4.932074
v  16.539986 6.175999 -4.392923
v  16.558025 4.111543 -5.284879
v  15.798553 4.682775 -5.017302
v  16.779497 3.731479 -4.615927
v  15.746181 3.253969 -4.786802
v  16.217573 3.265502 -5.183470
v  16.122730 5.295334 -4.475796
v  16.713915 3.280263 -4.565034
v  16.495155 4.708571 -5.259700
v  16.778498 4.895877 -4.531338
v  15.950327 5.126217 -4.648018
v  16.075832 4.456550 -4.511070
v  15.911871 5.085395 -5.039908
v  16.391420 5.466650 -4.481341
v  15.996134 4.829443 -4.584305
v  16.190403 5.147130 -4.469567
v  16.432667 3.321081 -4.452445
v  16.288948 4.843184 -4.446278
v  15.868179 4.204856 -4.553715
v  16.517902 5.985198 -5.100680
v  15.969844 5.323009 -4.828590
v  16.636410 5.249295 -4.460433
v  16.517010 6.393864 -4.914074
v  16.858276 4.873787 -4.843916
v  15.953826 3.966772 -5.116807
v  15.864385 4.909976 -5.108986
v  16.856518 4.547525 -5.086708
v  16.182802 5.501929 -4.334693
v  16.017487 4.740097 -5.205400
v  16.474064 5.720279 -5.108164
v  16.724304 6.421917 -4.813740
v  16.748360 4.627203 -4.583853
v  15.795616 4.740060 -4.760509
v  16.082184 3.651621 -5.199075
v  16.006472 5.427200 -4.831891
v  16.409813 6.098655 -5.067629
v  15.751307 4.152949 -4.769296
v  16.213501 4.483879 -4.541258
v  16.668442 3.526987 -5.236529
v  15.997429 3.692096 -4.429893
v  16.677706 3.901973 -4.553709
v  16.853846 3.921881 -4.815967
v  16.006296 3.833348 -4.466047
v  16.305367 5.670707 -4.287004
v  16.459375 5.287587 -4.411191
v  16.098122 3.388883 -4.447518
v  16.059013 3.192902 -4.486639
v  16.653709 5.581319 -4.497458
v  16.384674 3.638266 -4.429506
v  15.967961 4.493314 -4.533644
v  16.163860 3.987488 -4.457222
v  16.826200 5.039153 -5.022872
v  16.275782 4.714920 -5.317873
v  16.226257 4.907439 -5.271770
v  16.123224 4.990839 -4.459836
v  15.927530 4.021410 -4.462367
v  15.819573 5.056321 -4.833508
v  16.801737 4.908937 -5.125137
v  16.235668 4.311674 -5.306948
v  15.837328 4.065142 -5.028164
v  16.556738 4.111217 -4.526290
v  16.568672 3.120541 -5.174957
v  16.090256 5.203365 -4.467240
v  16.639139 6.239434 -5.076554
v  16.622915 6.135230 -4.437817
v  16.700659 3.890332 -4.523027
v  16.843735 4.001631 -4.949015
v  16.752687 5.830244 -4.431835
v  16.175089 5.441595 -5.059587
v  16.688656 4.610413 -4.535324
v  16.767883 5.389352 -4.908677
v  16.338810 4.380512 -4.487557
v  16.860966 4.438780 -4.673597
v  16.748384 5.449919 -4.520513
v  15.848694 4.521876 -5.028126
v  15.813699 4.472786 -4.673050
v  16.626015 3.731894 -5.216115
v  15.748568 3.762345 -4.621276
v  16.026016 4.050439 -5.243145
v  16.832710 5.000831 -4.781127
v  16.195642 3.168086 -4.604669
v  15.937972 5.242630 -4.614522
v  15.814600 3.092104 -4.908560
v  16.302755 5.195067 -4.419775
v  16.248184 4.649498 -4.460827
v  16.094046 3.566638 -4.486722
v  16.522602 5.133522 -4.447062
v  16.868586 4.001434 -4.669188
v  16.857533 6.336452 -4.769621
v  16.024166 5.080737 -4.574572
v  16.821934 3.867827 -5.072940
v  16.382343 5.184471 -5.217405
v  16.131739 5.547602 -4.962465
v  16.002375 5.368910 -4.522576
v  16.696211 6.150614 -5.066209
v  16.319347 6.024616 -5.022346
v  15.993378 4.498592 -5.219410
v  16.588217 6.407949 -4.920319
v  16.176790 4.228891 -5.246185
v  15.810244 4.894289 -5.019047
v  15.770345 3.389171 -4.553582
v  16.180479 3.352301 -4.543475
v  16.121065 3.461192 -5.147810
v  16.946463 4.338082 -4.865732
v  16.099907 4.063492 -4.428889
v  16.499973 4.380227 -4.509312
v  15.790813 3.971219 -4.994393
v  15.774606 4.655445 -4.892549
v  16.048279 5.456725 -4.530442
v  16.721762 5.402834 -4.491126
v  16.645954 5.414445 -4.469446
v  16.331619 4.847644 -5.279954
v  16.019917 3.432281 -4.492383
v  15.858397 5.041904 -4.700420
v  15.870863 4.293265 -4.634162
v  16.812366 3.496258 -4.822022
v  16.715385 5.774025 -5.044750
v  16.774799 3.109323 -5.087839
v  15.897526 3.720148 -5.101413
v  16.510599 3.581635 -4.453403
v  16.596304 5.237396 -5.135567
v  16.812563 4.856290 -4.662531
v  16.662609 6.343103 -4.631890
v  16.736952 3.614961 -4.571065
v  16.238123 4.905423 -4.465343
v  16.316650 5.088577 -4.405187
v  16.436363 4.213663 -5.311000
v  16.493484 6.105731 -4.342876
v  16.775791 5.396915 -4.753130
v  16.277975 5.805371 -4.301644
v  15.783575 3.205535 -4.910925
v  15.967636 4.091835 -4.526982
v  16.129753 4.439991 -4.452886
v  16.040638 5.296799 -4.513767
v  16.724575 3.515853 -4.542180
v  16.555626 6.316906 -5.003206
v  15.848497 5.040842 -5.015812
v  15.835464 3.681402 -4.545596
v  16.192476 3.967013 -5.277444
v  16.730423 5.174027 -4.604874
v  15.813597 3.846168 -4.523037
v  16.572580 6.002568 -5.077558
v  16.314693 5.371478 -4.373363
v  16.353992 5.507404 -5.092220
v  15.822798 3.185018 -4.557665
v  16.560844 3.035104 -4.578249
v  16.413750 3.160431 -4.570759
v  16.515396 4.939350 -4.439887
v  16.155182 3.724097 -5.218983
v  16.768908 3.693605 -4.538520
v  16.733507 3.566481 -5.153036
v  15.945247 3.232599 -5.081401
v  16.402264 3.961614 -4.468272
v  16.510757 3.468663 -5.253183
v  16.449312 5.707877 -4.327269
v  16.101706 5.688803 -4.973264
v  15.982567 4.752442 -4.571299
v  15.833185 4.217010 -4.604703
v  15.823818 4.252964 -5.061907
v  15.874197 4.881322 -4.718639
v  15.826784 3.642424 -5.013534
v  16.668951 5.843997 -4.428150
v  16.225315 5.030906 -4.426624
v  16.082781 4.981895 -5.217627
v  16.052628 4.401700 -5.226086
v  16.192421 4.359238 -4.431583
v  16.765585 4.200371 -4.580204
v  16.403498 5.595695 -5.069442
v  16.770151 3.394600 -4.598024
v  16.155954 4.790349 -5.298033
v  16.018463 5.306240 -5.014989
v  16.692238 4.403124 -5.217616
v  16.824999 5.201355 -4.879409
v  16.090111 3.907361 -4.462551
v  16.796810 3.654046 -4.994775
v  16.300980 3.218165 -5.194772
v  16.386013 5.842847 -5.097864
v  16.546530 4.522601 -4.476620
v  16.612108 4.172860 -5.217487
v  16.674742 4.214110 -4.506788
v  16.637419 5.593796 -4.566548
v  15.885292 3.884925 -5.122111
v  16.781050 3.885512 -5.138015
v  15.905920 5.157755 -4.759130
v  16.944159 4.247756 -4.702903
v  16.830082 4.529295 -4.681953
v  16.741234 5.500861 -5.038914
v  15.878418 4.542737 -4.639941
v  16.758230 6.351355 -4.662972
v  16.078999 5.314186 -4.476132
v  16.165333 3.830848 -4.445946
v  15.944880 4.055012 -5.130586
v  16.873581 3.314641 -4.864053
v  15.978006 4.585564 -4.561545
v  16.835167 4.352918 -4.638635
v  16.749866 5.171129 -5.054193
v  16.177578 5.187843 -4.443202
v  16.808743 2.983755 -4.810902
v  15.798892 4.436039 -4.982630
v  16.846064 5.178405 -4.698575
v  16.748535 5.734195 -4.963695
v  16.444471 4.121874 -5.304134
v  16.755173 6.162852 -4.550054
v  15.750656 4.341218 -4.923790
v  15.841099 4.759143 -5.003335
v  16.235743 4.559227 -5.261155
v  16.396690 3.738623 -5.284811
v  16.196978 4.509630 -4.438537
v  15.980688 3.594064 -5.086304
v  16.795567 3.593762 -4.609345
v  16.083059 4.968009 -4.505644
v  16.135168 4.826047 -4.504096
v  15.933949 5.250679 -5.008610
v  16.682631 4.991382 -4.526053
v  16.728340 4.227560 -4.538620
v  15.769237 6.183477 -4.455279
v  16.365030 6.543330 -4.697640
v  16.474987 5.175199 -4.539485
v  16.062298 5.312752 -4.931865
v  16.871344 6.269266 -4.751402
v  16.422211 6.982425 -4.430943
v  16.306499 6.654757 -4.215122
v  16.261864 6.474416 -4.469460
v  16.067684 6.060950 -4.905811
v  16.675539 5.991277 -4.959259
v  17.003548 5.749230 -5.218127
v  16.649696 5.814722 -4.534150
v  16.505692 6.412207 -4.679103
v  16.779490 5.677155 -5.243097
v  16.621269 5.563312 -4.420670
v  16.448774 5.837087 -3.434272
v  15.843000 5.533049 -4.026666
v  16.443830 6.061777 -3.589625
v  16.028072 5.940439 -4.447555
v  16.424206 5.328717 -5.035913
v  16.706755 5.421552 -4.447266
v  16.317434 6.754970 -4.258260
v  16.139479 6.724916 -4.635560
v  16.529766 6.625647 -4.525338
v  15.828938 5.720486 -4.014995
v  16.311522 5.865078 -4.308697
v  16.962950 5.666773 -5.323296
v  16.107355 6.341600 -4.789267
v  16.314758 6.465678 -4.286266
v  16.422709 6.013775 -3.434835
v  16.731009 5.582952 -4.388044
v  16.123144 5.202279 -4.568717
v  16.254696 6.998421 -4.587185
v  15.889703 6.226161 -4.354738
v  16.847578 6.296646 -4.925030
v  16.718391 6.234332 -5.033962
v  16.001833 5.606293 -4.069837
v  16.298779 6.802058 -4.729637
v  16.810699 5.619614 -4.721344
v  16.382530 6.021046 -3.579929
v  16.213535 6.919759 -4.363880
v  16.256645 6.310919 -4.470240
v  16.263458 5.795452 -3.642272
v  16.684177 5.273769 -4.762746
v  16.974808 5.559636 -5.157467
v  15.977295 5.988897 -4.679461
v  15.975208 5.865587 -4.079319
v  16.879179 6.046852 -4.831597
v  16.462402 6.631787 -4.323834
v  16.854227 5.743999 -5.031057
v  16.448874 6.971576 -4.594737
v  16.493261 6.003227 -3.509793
v  16.626638 5.433472 -4.443760
v  16.001059 5.271851 -4.732798
v  16.552910 6.752245 -4.606926
v  16.515793 5.915801 -3.479390
v  16.635790 5.531321 -4.417368
v  15.797763 6.026529 -4.550828
v  16.794504 5.554933 -5.174393
v  16.226061 6.472753 -4.659091
v  16.383856 5.495871 -4.439226
v  16.637369 5.310019 -4.929817
v  16.686155 5.564577 -4.551499
v  16.701204 5.366653 -4.442415
v  16.121744 5.814649 -3.962680
v  16.490261 6.323481 -4.550024
v  15.979823 5.691549 -3.790420
v  16.184566 6.595856 -4.540765
v  16.366795 6.083456 -5.029844
v  16.438965 6.794641 -4.311423
v  16.473072 5.978643 -4.367993
v  16.718367 5.607462 -4.517700
v  16.481052 6.016558 -3.490987
v  16.378313 6.487971 -4.271771
v  16.184546 5.512563 -4.935805
v  16.952627 5.750998 -5.023621
v  16.730440 6.379745 -4.843903
v  15.948215 5.911899 -4.505544
v  16.644419 5.431540 -4.440473
v  16.384384 6.396378 -4.869570
v  15.988295 6.099644 -4.300267
v  16.809717 6.051630 -4.673911
v  16.765675 5.712120 -5.153378
v  16.732439 5.464195 -4.387121
v  16.019907 6.176506 -4.767879
v  16.698154 5.409734 -4.505940
v  16.102053 5.619514 -3.919831
v  16.228050 5.914270 -3.688179
v  16.333399 6.942285 -4.322799
v  16.432873 5.940956 -3.455549
v  16.521418 5.857123 -3.471605
v  16.198029 6.734950 -4.367353
v  16.400118 6.808660 -4.722346
v  16.844919 5.456238 -4.707826
v  15.883121 5.527771 -4.089803
v  16.858160 5.788718 -5.312898
v  16.763088 5.998600 -4.830210
v  16.416037 5.987653 -3.631186
v  16.390627 6.046720 -3.462414
v  16.397480 5.975174 -3.607882
v  16.369278 6.440361 -4.410676
v  16.232744 5.394373 -4.486558
v  15.832025 6.253038 -4.529487
v  16.904364 6.251117 -4.826169
v  16.765266 5.451941 -4.649326
v  16.642149 5.889943 -4.445826
v  16.288889 6.549675 -4.242716
v  15.843925 5.813236 -4.036471
v  16.035616 5.498384 -4.757968
v  16.679235 5.961934 -5.129420
v  16.043373 6.333941 -4.581986
v  16.453360 6.473375 -4.472464
v  16.462114 6.043360 -3.541565
v  16.452003 5.903868 -3.542839
v  16.391977 5.900080 -3.487621
v  16.334862 6.367985 -4.466626
v  16.197657 6.547640 -4.408931
v  15.966650 5.849128 -4.766553
v  16.797482 5.598473 -4.552954
v  16.270008 6.149852 -4.341846
v  16.700596 5.858650 -4.680615
v  16.109573 6.260900 -4.855705
v  16.497082 5.988987 -3.563074
v  16.611376 6.174582 -4.483036
v  16.730785 5.804861 -5.033358
v  16.430946 5.980284 -3.555105
v  16.384987 5.935965 -3.503126
v  16.440092 5.952121 -3.411396
v  16.042574 5.867078 -3.916495
v  16.291246 5.149506 -4.519531
v  16.940531 5.920925 -5.194992
v  16.694202 6.115895 -5.051045
v  16.139513 6.753409 -4.560534
v  16.380318 5.851191 -3.607830
v  16.704121 5.640749 -4.562206
v  16.614586 5.571503 -4.481962
v  16.007566 5.743564 -4.043601
v  16.650583 5.536602 -4.507425
v  16.724804 5.518733 -4.513550
v  16.049458 5.985124 -4.347308
v  16.892069 5.761045 -5.166796
v  15.791470 5.642714 -4.115519
v  16.426836 6.485857 -4.707265
v  16.294052 7.018453 -4.516849
v  16.541809 6.783807 -4.554227
v  16.610077 5.521286 -4.940194
v  16.256603 6.643970 -4.294744
v  15.965473 5.780404 -4.403678
v  16.478409 6.740391 -4.375464
v  16.253635 5.784892 -4.369849
v  16.979507 5.759086 -5.113400
v  16.916126 6.162653 -4.808705
v  16.437088 5.614106 -5.004004
v  16.636906 5.543972 -4.432611
v  16.713921 5.574866 -4.508816
v  16.643063 5.596292 -4.419209
v  16.497086 5.902519 -3.499247
v  16.437803 5.867102 -3.526533
v  16.642368 5.484905 -4.425990
v  16.731268 5.455477 -4.519433
v  16.520071 5.385895 -4.540175
v  16.649384 5.384255 -4.473434
v  16.996328 5.653542 -5.006043
v  16.711752 5.478028 -4.430803
v  15.815297 6.098350 -4.331067
v  16.385292 6.653320 -4.272749
v  16.043118 5.798081 -4.312352
v  16.485203 5.834766 -3.533141
v  15.824560 5.628719 -3.959616
v  16.932169 5.603172 -5.312202
v  16.636553 5.485821 -4.430222
v  16.483150 5.962129 -3.480005
v  16.709772 5.532420 -4.394136
v  16.637011 5.438783 -4.503690
v  16.258591 6.909573 -4.689160
v  16.775612 5.644994 -5.263235
v  16.668575 5.487921 -4.504910
v  16.367073 6.039631 -3.634243
v  16.393593 6.921396 -4.690360
v  16.703722 5.933038 -4.810893
v  16.358067 6.554241 -4.232038
v  16.706228 6.352518 -4.937734
v  16.713781 5.923285 -4.521877
v  15.790031 6.174045 -4.567068
v  16.424049 5.892169 -3.471308
v  16.474581 6.465959 -4.649351
v  16.306650 6.566715 -4.685354
v  16.835932 5.449660 -4.579144
v  16.341707 5.879709 -3.710439
v  15.985888 5.821194 -3.844237
v  16.253736 6.379187 -4.500350
v  16.347273 6.278903 -4.954325
v  15.993618 5.745991 -4.608162
v  16.903929 6.118464 -4.979816
v  16.455368 5.889060 -3.416747
v  16.002739 5.606195 -3.849399
v  16.620987 5.534573 -4.428937
v  16.170156 6.907351 -4.434584
v  16.793125 5.740242 -5.051999
v  16.231590 6.527828 -4.594850
v  16.427111 6.008010 -3.484962
v  16.464952 5.823946 -4.415586
v  16.500153 5.980153 -3.490788
v  16.450872 5.885278 -3.460456
v  15.997572 6.142618 -4.314086
v  16.751709 5.601168 -4.678267
v  16.185184 6.850470 -4.644500
v  15.789037 6.040977 -4.345418
v  16.243874 5.843916 -3.621011
v  16.366472 5.982100 -4.308893
v  16.278515 5.246354 -4.524266
v  16.779709 6.165926 -5.050615
v  16.032064 5.898818 -4.160954
v  16.422722 5.932695 -3.543915
v  17.029236 5.693356 -5.170500
v  16.561319 5.326605 -5.008249
v  16.440397 6.061812 -3.503506
v  16.780277 5.495795 -4.408661
v  16.744253 5.482355 -4.496113
v  16.508465 5.502186 -4.483109
v  16.708302 6.001167 -5.159166
v  16.139730 5.320096 -4.980837
v  15.832191 5.535021 -4.078905
v  16.182285 6.718218 -4.629146
v  16.097544 5.588790 -4.866063
v  16.200615 6.779675 -4.328641
v  16.734959 5.404198 -4.416873
v  16.985231 5.569786 -5.045795
v  16.687557 6.285734 -4.625977
v  16.640398 5.403967 -4.674112
v  15.992836 6.020689 -4.736570
v  16.791580 5.506638 -4.843405
v  16.247040 5.903943 -3.774640
v  15.823438 5.930653 -4.452771
v  16.499989 6.610248 -4.553048
v  16.649872 5.634499 -4.583332
v  16.468498 6.926647 -4.411271
v  16.489803 6.074688 -5.049342
v  16.627506 5.971900 -4.979362
v  16.325891 6.638177 -4.242410
v  16.231678 6.962626 -4.629999
v  16.717928 6.104561 -4.527479
v  16.414555 6.042511 -3.637583
v  16.393692 5.996136 -3.575597
v  16.481272 6.562825 -4.371352
v  16.381187 5.877448 -5.030705
v  16.504223 6.022265 -3.583002
v  16.647877 5.495922 -4.508505
v  16.156700 5.785358 -4.417436
v  16.241901 5.603794 -4.414131
v  16.751278 5.704660 -5.241943
v  16.207932 6.643573 -4.332483
v  16.493427 6.870480 -4.621117
v  16.425329 6.304370 -4.497756
v  15.804213 5.826881 -4.090223
v  16.399755 5.901081 -3.420432
v  16.475098 6.026587 -3.519421
v  16.094839 5.370492 -4.572455
v  15.998147 6.063836 -4.292420
v  16.647219 5.625396 -4.493464
v  16.801655 5.868309 -4.900025
v  16.804020 5.705786 -5.306189
v  16.383106 6.745643 -4.316693
v  16.838598 5.536209 -5.182920
v  16.744131 5.657596 -5.176300
v  16.430777 6.571556 -4.665978
v  16.724901 5.576413 -4.488905
v  16.585226 6.392719 -4.795681
v  16.825687 5.554492 -4.543516
v  16.915211 6.055367 -4.886164
v  16.710499 5.534438 -4.549425
v  16.018629 6.045146 -4.870520
v  16.986916 5.752340 -5.189391
v  16.295767 5.970724 -3.567239
v  16.442390 6.043993 -3.560278
v  16.813505 6.034611 -4.743463
v  16.510639 6.739540 -4.606548
v  16.003508 5.636755 -4.059978
v  16.034950 6.308809 -4.711380
v  16.448284 5.892828 -3.501370
v  16.723206 5.499476 -4.508603
v  16.268799 5.565113 -4.945985
v  16.646996 5.551696 -4.421106
v  16.375835 5.985710 -3.593835
v  16.434904 5.845858 -3.492093
v  16.448410 5.250232 -4.539554
v  16.819229 6.337749 -4.779656
v  16.207954 5.946505 -4.294982
v  16.226517 6.124413 -4.935954
v  15.906970 6.289266 -4.499225
v  16.419184 5.924797 -3.521204
v  16.513235 6.009205 -3.519332
v  16.588671 5.998837 -4.401047
v  16.527243 5.876732 -3.500204
v  15.903239 5.813344 -4.026607
v  15.761509 6.148919 -4.504018
v  16.776964 5.629176 -4.804756
v  16.392082 6.800364 -4.278977
v  16.674061 5.584534 -4.571354
v  16.697636 5.430532 -4.521137
v  16.326061 5.804231 -3.614705
v  16.286591 6.980112 -4.379425
v  16.622978 5.398425 -4.445207
v  15.846405 5.664872 -4.257643
v  16.195816 6.610459 -4.579858
v  16.122665 6.754625 -4.593338
v  16.638231 5.441346 -4.403055
v  16.979410 5.623785 -5.294662
v  17.009768 5.603957 -5.179950
v  16.032450 5.460102 -4.670208
v  16.598770 5.781295 -4.982072
v  16.656483 6.256483 -5.027629
v  16.286524 6.459563 -4.331080
v  16.067188 6.257668 -4.396145
v  16.262890 6.934689 -4.334245
v  15.937789 6.110967 -4.296011
v  16.680296 5.543930 -4.705877
v  15.986101 5.951795 -4.630008
v  16.262102 6.735718 -4.323678
v  16.345291 5.773338 -4.379390
v  16.618355 5.451865 -4.440453
v  16.538265 6.746916 -4.521048
v  16.460201 5.842513 -3.549651
v  16.397568 7.007679 -4.575969
v  16.580465 5.791044 -4.476957
v  16.510082 5.936994 -3.444767
v  16.340862 5.668258 -4.986866
v  16.912096 5.742335 -5.178170
v  16.901196 5.771809 -5.024855
v  16.045959 6.299597 -4.478193
v  16.693605 5.547709 -4.493251
v  16.827379 5.695272 -5.171110
v  16.829313 5.988451 -4.840277
v  16.461147 6.013835 -3.504686
v  16.662214 5.529697 -4.426255
v  16.344553 5.981484 -3.679714
v  16.673967 5.408855 -4.461406
v  16.637846 5.553374 -4.408354
v  16.746758 5.496485 -4.381128
v  16.384798 5.955801 -3.541813
v  16.749378 5.862334 -4.932765
v  16.649845 5.242023 -4.664971
v  16.084576 5.852290 -3.947937
v  16.241154 6.790614 -4.289990
v  16.229408 5.750285 -3.762471
v  16.011972 5.795434 -4.377944
v  16.663897 5.610048 -4.464562
v  16.444267 6.132221 -4.409534
v  16.540754 6.396780 -4.851042
v  16.417284 6.134089 -5.032686
v  16.655655 5.610642 -4.555383
v  16.631784 5.583012 -4.451312
v  16.770760 5.723142 -5.127386
v  16.398409 6.448133 -4.417240
v  16.725368 5.591380 -4.511565
v  16.334723 6.674442 -4.214399
v  16.850204 6.324379 -4.849823
v  15.807483 5.688231 -4.240506
v  16.079319 5.658245 -4.016853
v  16.419741 5.847537 -3.436838
v  16.843733 5.482392 -4.806129
v  15.905702 5.534472 -4.001172
v  16.550781 5.929617 -4.386078
v  16.044399 5.932662 -4.370108
v  16.308647 6.516087 -4.698288
v  16.621027 5.496089 -4.450777
v  16.473257 6.445333 -4.728695
v  15.838553 6.108208 -4.637202
v  16.343023 7.018312 -4.478607
v  16.462866 6.352233 -4.525388
v  16.633537 5.536413 -4.469403
v  16.620686 6.045486 -5.017557
v  15.877224 5.745255 -4.015608
v  16.723898 5.576771 -4.417988
v  16.231506 6.474000 -4.555065
v  16.184605 6.943501 -4.562283
v  16.412312 5.919185 -4.328659
v  16.668121 5.494862 -4.486206
v  16.139339 6.757428 -4.584482
v  16.409712 6.019185 -3.488583
v  16.083363 5.855036 -4.907193
v  15.973156 5.736876 -3.800548
v  16.435966 5.947063 -3.518555
v  16.248041 5.798533 -3.802816
v  16.279076 6.685391 -4.267854
v  16.921713 6.090810 -4.932499
v  16.501873 6.881501 -4.461619
v  16.453333 6.772525 -4.683852
v  16.061655 5.751084 -4.502183
v  17.002064 5.770834 -5.170023
v  16.480938 5.888975 -3.493805
v  16.741674 5.481904 -4.513091
v  16.069775 5.986955 -4.333879
v  16.143744 5.502960 -4.483712
v  16.670147 5.922918 -5.084196
v  16.233442 5.927297 -4.955055
v  15.999837 5.557748 -3.989507
v  16.157215 6.676865 -4.623853
v  16.507898 6.631157 -4.566347
v  16.347837 6.007485 -3.542393
v  16.487679 6.033800 -3.520922
v  16.014450 5.294092 -4.840543
v  16.467178 5.928633 -3.399758
v  16.409340 5.877022 -3.490291
v  16.237968 6.306641 -4.875301
v  16.169136 5.655335 -4.915639
v  15.859019 5.989881 -4.171170
v  15.941641 6.212084 -4.337847
v  16.869608 5.883495 -5.266973
v  16.314323 6.918034 -4.702811
v  16.411140 6.485494 -4.306963
v  15.829956 6.242344 -4.431006
v  15.856880 5.935000 -4.499226
v  16.028091 5.246068 -4.645260
v  16.240623 6.791831 -4.699703
v  16.924480 5.744193 -5.062634
v  16.002733 5.761940 -4.065907
v  16.541683 6.695435 -4.595996
v  16.771477 5.532115 -4.838024
v  15.942530 5.819754 -3.928702
v  16.425917 6.007018 -3.597137
v  16.073425 6.244591 -4.829520
v  16.197416 6.418818 -4.574502
v  15.993387 5.871528 -4.820450
v  16.661488 5.399337 -4.510299
v  16.489658 6.638424 -4.373833
v  16.443697 6.013125 -3.475353
v  16.914093 5.650403 -5.333949
v  16.826128 5.696054 -5.212182
v  16.455906 5.998080 -3.574974
v  16.850975 5.588370 -4.693501
v  16.373005 6.676772 -4.263501
v  15.875639 5.669031 -3.887506
v  16.739967 5.999473 -4.809687
v  16.493795 5.854554 -3.485342
v  16.669979 6.211411 -5.041913
v  16.722929 5.903028 -4.590626
v  16.721087 5.530138 -4.418562
v  16.470545 6.504399 -4.489398
v  16.293930 6.404459 -4.474037
v  16.328833 7.000835 -4.614238
v  16.507271 5.928164 -3.509094
v  16.364838 6.013515 -3.459796
v  16.680725 5.444242 -4.502699
v  16.674002 5.505285 -4.812982
v  16.406723 5.963856 -3.593815
v  16.700682 5.985363 -4.910629
v  16.904802 5.962178 -4.887808
v  16.254383 5.822381 -4.331782
v  16.486422 5.912607 -3.475968
v  16.660088 6.073145 -5.026234
v  16.671259 5.441169 -4.492840
v  16.385546 6.937872 -4.335241
v  16.713125 5.369891 -4.463254
v  15.784435 6.002203 -4.386077
v  16.520473 5.879240 -3.457043
v  16.916384 5.599889 -4.827723
v  15.857269 5.739563 -3.944124
v  16.446529 6.917206 -4.658267
v  16.734623 5.484874 -4.619556
v  16.305788 6.590528 -4.235129
v  16.988924 5.834997 -5.211139
v  16.468189 6.009974 -3.520579
v  16.195131 6.446229 -4.641845
v  15.910721 5.813577 -4.441222
v  16.461344 5.842867 -3.458267
v  16.363985 5.429786 -5.017255
v  16.572836 5.493793 -4.971262
v  16.674088 5.571027 -4.379879
v  16.761150 5.385943 -4.458199
v  16.339476 6.593625 -4.227798
v  16.164316 6.693173 -4.479013
v  16.235395 5.902364 -3.651826
v  15.981242 5.683383 -4.049603
v  16.711910 5.503494 -4.443682
v  16.751015 5.695604 -5.071417
v  16.235613 6.966820 -4.398458
v  16.445999 5.880095 -3.500477
v  16.590851 5.322344 -4.611821
v  16.745377 6.064069 -5.143785
v  16.644466 5.425853 -4.529741
v  16.659460 5.483392 -4.400421
v  16.391966 6.411516 -4.446423
v  16.096481 6.365682 -4.742425
v  16.862041 5.893003 -4.894091
v  15.872697 6.086593 -4.289493
v  16.241318 6.999030 -4.499417
v  16.430393 6.054655 -3.517845
v  16.556791 6.786725 -4.573091
v  15.993156 5.918968 -4.498446
v  16.377678 5.952167 -3.504533
v  16.708054 5.402039 -4.469295
v  16.426006 5.893360 -3.536600
v  16.217136 6.560019 -4.343638
v  16.428236 5.637243 -4.422923
v  16.249584 5.369110 -4.988003
v  16.671385 5.580793 -4.498096
v  15.898321 5.680607 -4.290369
v  15.800642 6.208834 -4.411146
v  16.674614 5.532176 -4.482296
v  15.980363 6.099007 -4.733338
v  16.637014 5.764799 -4.943088
v  16.521040 6.636386 -4.514992
v  15.769580 6.062712 -4.515837
v  16.416588 5.410319 -4.482131
v  16.348936 6.463190 -4.284482
v  16.814854 6.279429 -4.701334
v  16.497017 6.000478 -3.491518
v  16.445461 6.005653 -3.510729
v  15.892843 6.191299 -4.331446
v  16.414928 6.812831 -4.294255
v  16.756466 5.560674 -4.390080
v  16.420244 5.988252 -3.539596
v  16.117470 5.455874 -4.903882
v  16.460638 5.878353 -3.535860
v  15.926850 5.542697 -4.088243
v  16.940647 5.553345 -4.922417
v  15.994478 5.574619 -4.053687
v  16.451641 5.952699 -3.439507
v  16.898901 6.233250 -4.908547
v  16.662823 5.544987 -4.456373
v  17.008232 5.725705 -5.113586
v  16.400202 5.910822 -3.476463
v  16.340910 5.343083 -4.491967
v  16.509556 6.329978 -4.574053
v  16.057030 6.341987 -4.686762
v  15.939924 5.756750 -4.034125
v  16.393345 6.053426 -3.605692
v  16.714998 5.539266 -4.632299
v  16.167625 5.780587 -3.928668
v  16.902834 6.214262 -4.776864
v  16.612797 5.757070 -4.518157
v  16.866337 5.772866 -5.112307
v  16.830162 6.140536 -5.052567
v  16.445250 5.829403 -3.515753
v  16.241104 5.814203 -3.634521
v  16.547398 6.377570 -4.684712
v  16.704418 5.492769 -4.488676
v  16.071472 5.421118 -4.585761
v  16.659193 6.023458 -4.996311
v  16.380173 6.467780 -4.748598
v  16.816652 6.257766 -4.990817
v  16.740355 5.431190 -4.393760
v  16.656443 5.536283 -4.417815
v  16.269686 6.561419 -4.660254
v  16.328699 6.742287 -4.729840
v  16.423115 5.982449 -3.413057
v  16.395494 6.629953 -4.691529
v  15.799721 6.229011 -4.472744
v  16.668648 5.563695 -4.429961
v  15.791199 5.733199 -4.083609
v  16.426613 6.080058 -5.046485
v  16.324909 6.507566 -4.260251
v  16.431044 5.985633 -3.585562
v  16.020138 6.004513 -4.282405
v  16.404026 6.038508 -3.444789
v  16.839363 5.551833 -5.223821
v  16.447754 6.748492 -4.342078
v  16.767164 5.587014 -4.499015
v  16.473639 5.845269 -3.508612
v  16.718884 5.500566 -4.523379
v  15.915277 6.003407 -4.651711
v  16.170483 6.596798 -4.554615
v  16.832951 6.121558 -4.676794
v  16.750902 5.809246 -5.247140
v  16.180305 6.267862 -4.431228
v  16.865049 6.094491 -4.735520
v  16.800262 5.610725 -5.269244
v  15.971966 5.628641 -3.830432
v  16.687265 5.473513 -4.527168
v  16.471685 6.392119 -4.540802
v  15.860159 6.174123 -4.647060
v  16.469458 6.961955 -4.540036
v  15.960014 6.142829 -4.307985
v  16.781284 5.612543 -4.711026
v  16.175957 6.212149 -4.370578
v  16.176975 6.214989 -4.367826
v  16.179279 6.217844 -4.365808
v  16.182598 6.220368 -4.364768
v  16.186531 6.222260 -4.364841
v  16.190611 6.223289 -4.366028
v  16.194347 6.223332 -4.368190
v  16.197285 6.222387 -4.371065
v  16.199064 6.220568 -4.374301
v  16.199469 6.218095 -4.377499
v  16.198448 6.215264 -4.380266
v  16.196123 6.212416 -4.382266
v  16.192778 6.209893 -4.383261
v  16.188822 6.207999 -4.383138
v  16.184736 6.206963 -4.381920
v  16.181013 6.206911 -4.379761
v  16.185143 6.211974 -4.376247
v  16.169630 6.237955 -4.393598
v  16.176346 6.209670 -4.373733
v  16.178101 6.207850 -4.376919
v  16.162491 6.234701 -4.393375
v  16.162004 6.237257 -4.390194
v  16.162966 6.240143 -4.387425
v  16.165258 6.243008 -4.385401
v  16.168613 6.245504 -4.384369
v  16.172626 6.247334 -4.384460
v  16.176819 6.248274 -4.385674
v  16.180687 6.248213 -4.387868
v  16.183758 6.247159 -4.390776
v  16.185661 6.245242 -4.394041
v  16.186161 6.242692 -4.397261
v  16.185194 6.239815 -4.400042
v  16.182880 6.236957 -4.402046
v  16.179501 6.234462 -4.403035
v  16.175467 6.232630 -4.402897
v  16.171270 6.231683 -4.401655
v  16.167418 6.231735 -4.399467
v  16.164370 6.232784 -4.396592
v  16.191031 6.187140 -4.352310
v  16.192118 6.189924 -4.349575
v  16.194437 6.192768 -4.347561
v  16.197710 6.195328 -4.346515
v  16.201551 6.197295 -4.346570
v  16.205500 6.198431 -4.347730
v  16.209078 6.198604 -4.349860
v  16.211857 6.197791 -4.352705
v  16.213493 6.196092 -4.355914
v  16.213787 6.193711 -4.359091
v  16.212700 6.190936 -4.361846
v  16.210363 6.188098 -4.363843
v  16.207060 6.185542 -4.364843
v  16.203196 6.183572 -4.364736
v  16.199240 6.182427 -4.363540
v  16.195671 6.182248 -4.361408
v  16.198034 6.189167 -4.354395
v  16.189327 6.217388 -4.370311
v  16.191307 6.184753 -4.355441
v  16.192917 6.183055 -4.358597
v  16.176851 6.208795 -4.373068
v  16.176464 6.211271 -4.369913
v  16.177486 6.214108 -4.367162
v  16.179792 6.216963 -4.365143
v  16.183107 6.219489 -4.364104
v  16.187038 6.221383 -4.364176
v  16.191114 6.222414 -4.365362
v  16.194845 6.222463 -4.367523
v  16.197777 6.221520 -4.370396
v  16.199553 6.219706 -4.373632
v  16.199953 6.217235 -4.376829
v  16.198931 6.214406 -4.379595
v  16.196604 6.211557 -4.381595
v  16.193262 6.209034 -4.382590
v  16.189308 6.207138 -4.382468
v  16.185226 6.206099 -4.381251
v  16.181509 6.206043 -4.379093
v  16.178602 6.206979 -4.376252
v  16.206221 6.163989 -4.336580
v  16.207388 6.166698 -4.333854
v  16.209723 6.169528 -4.331842
v  16.212946 6.172134 -4.330792
v  16.216675 6.174201 -4.330842
v  16.220465 6.175479 -4.331991
v  16.223860 6.175817 -4.334111
v  16.226448 6.175176 -4.336946
v  16.227915 6.173632 -4.340147
v  16.228077 6.171371 -4.343319
v  16.226913 6.168669 -4.346071
v  16.224560 6.165847 -4.348066
v  16.221308 6.163245 -4.349068
v  16.217552 6.161176 -4.348961
v  16.213749 6.159890 -4.347770
v  16.210363 6.159544 -4.345645
v  16.219984 6.166979 -4.343053
v  16.198547 6.188346 -4.353810
v  16.206362 6.161725 -4.339700
v  16.207798 6.160181 -4.342844
v  16.191824 6.183928 -4.354857
v  16.191553 6.186311 -4.351728
v  16.192642 6.189092 -4.348993
v  16.194962 6.191935 -4.346979
v  16.198235 6.194497 -4.345932
v  16.202070 6.196466 -4.345987
v  16.206013 6.197608 -4.347146
v  16.209587 6.197784 -4.349277
v  16.212360 6.196978 -4.352121
v  16.213991 6.195282 -4.355329
v  16.214281 6.192905 -4.358504
v  16.213192 6.190133 -4.361259
v  16.210854 6.187296 -4.363256
v  16.207554 6.184738 -4.364257
v  16.203691 6.182765 -4.364149
v  16.199739 6.181617 -4.362954
v  16.196177 6.181432 -4.360823
v  16.193428 6.182235 -4.358013
v  16.229652 6.169530 -4.348404
v  16.221016 6.143507 -4.323749
v  16.222288 6.146113 -4.321011
v  16.224644 6.148922 -4.318999
v  16.227800 6.151595 -4.317960
v  16.231382 6.153805 -4.318027
v  16.234964 6.155288 -4.319205
v  16.238115 6.155866 -4.321360
v  16.240458 6.155471 -4.324233
v  16.241701 6.154150 -4.327472
v  16.241692 6.152066 -4.330675
v  16.240425 6.149466 -4.333446
v  16.238054 6.146664 -4.335445
v  16.234867 6.143996 -4.336432
v  16.231251 6.141784 -4.336298
v  16.227652 6.140294 -4.335070
v  16.224504 6.139708 -4.332905
v  16.228720 6.144690 -4.329397
v  16.214056 6.164071 -4.340573
v  16.220978 6.141417 -4.326890
v  16.222189 6.140098 -4.330066
v  16.206873 6.160979 -4.339210
v  16.206738 6.163238 -4.336090
v  16.207909 6.165946 -4.333363
v  16.210243 6.168775 -4.331352
v  16.213465 6.171381 -4.330303
v  16.217190 6.173452 -4.330352
v  16.220972 6.174736 -4.331501
v  16.224360 6.175082 -4.333621
v  16.226942 6.174446 -4.336456
v  16.228401 6.172909 -4.339658
v  16.228559 6.170655 -4.342830
v  16.227392 6.167953 -4.345582
v  16.225039 6.165132 -4.347578
v  16.221788 6.162528 -4.348579
v  16.218037 6.160456 -4.348472
v  16.214239 6.159163 -4.347281
v  16.210859 6.158811 -4.345155
v  16.208303 6.159441 -4.342354
v  16.243748 6.115844 -4.308640
v  16.245348 6.118215 -4.305856
v  16.247856 6.120929 -4.303839
v  16.250969 6.123653 -4.302832
v  16.254318 6.126062 -4.302967
v  16.257509 6.127863 -4.304243
v  16.260159 6.128843 -4.306519
v  16.261950 6.128881 -4.309521
v  16.262661 6.127975 -4.312881
v  16.262199 6.126235 -4.316182
v  16.260612 6.123869 -4.319012
v  16.258093 6.121164 -4.321021
v  16.254944 6.118441 -4.321968
v  16.251553 6.116030 -4.321751
v  16.248335 6.114222 -4.320407
v  16.245684 6.113236 -4.318111
v  16.256670 6.123705 -4.312568
v  16.229189 6.144071 -4.329020
v  16.243248 6.114099 -4.311861
v  16.243919 6.113194 -4.315146
v  16.221466 6.140779 -4.326508
v  16.221510 6.142860 -4.323365
v  16.222788 6.145463 -4.320625
v  16.225143 6.148272 -4.318614
v  16.228298 6.150946 -4.317575
v  16.231874 6.153162 -4.317644
v  16.235447 6.154654 -4.318824
v  16.238588 6.155242 -4.320982
v  16.240921 6.154858 -4.323857
v  16.242155 6.153548 -4.327099
v  16.242138 6.151471 -4.330304
v  16.240868 6.148875 -4.333076
v  16.238495 6.146072 -4.335074
v  16.235310 6.143403 -4.336061
v  16.231701 6.141184 -4.335926
v  16.228109 6.139686 -4.334695
v  16.224972 6.139090 -4.332527
v  16.222666 6.139469 -4.329686
v  16.267593 6.090015 -4.296916
v  16.269457 6.092218 -4.294127
v  16.272057 6.094890 -4.292125
v  16.275082 6.097704 -4.291152
v  16.278173 6.100327 -4.291337
v  16.280968 6.102437 -4.292676
v  16.283136 6.103784 -4.295021
v  16.284414 6.104205 -4.298092
v  16.284647 6.103650 -4.301513
v  16.283794 6.102187 -4.304856
v  16.281952 6.099991 -4.307702
v  16.279341 6.097326 -4.309697
v  16.276278 6.094512 -4.310604
v  16.273136 6.091888 -4.310322
v  16.270304 6.089773 -4.308904
v  16.268131 6.088419 -4.306532
v  16.278061 6.097598 -4.303449
v  16.250002 6.117476 -4.311728
v  16.266695 6.088550 -4.300165
v  16.266880 6.087995 -4.303499
v  16.244043 6.113183 -4.311408
v  16.244558 6.114916 -4.308185
v  16.246170 6.117281 -4.305401
v  16.248680 6.119992 -4.303383
v  16.251791 6.122720 -4.302378
v  16.255133 6.125135 -4.302515
v  16.258308 6.126947 -4.303794
v  16.260942 6.127940 -4.306072
v  16.262714 6.127993 -4.309078
v  16.263409 6.127100 -4.312442
v  16.262932 6.125371 -4.315744
v  16.261335 6.123013 -4.318575
v  16.258812 6.120308 -4.320582
v  16.255665 6.117584 -4.321529
v  16.252281 6.115166 -4.321310
v  16.249077 6.113347 -4.319963
v  16.246443 6.112348 -4.317665
v  16.244696 6.112290 -4.314696
v  16.248165 6.110522 -4.322151
v  16.291300 6.067111 -4.287773
v  16.293335 6.069219 -4.284998
v  16.295952 6.071905 -4.283023
v  16.298840 6.074846 -4.282087
v  16.301657 6.077688 -4.282317
v  16.304073 6.080086 -4.283704
v  16.305803 6.081754 -4.286095
v  16.306643 6.082489 -4.289207
v  16.306480 6.082208 -4.292656
v  16.305332 6.080940 -4.296012
v  16.303324 6.078840 -4.298850
v  16.300694 6.076158 -4.300819
v  16.297764 6.073220 -4.301681
v  16.294891 6.070375 -4.301345
v  16.292435 6.067973 -4.299870
v  16.290695 6.066298 -4.297448
v  16.300970 6.076684 -4.293415
v  16.273005 6.092392 -4.300224
v  16.290100 6.065841 -4.291024
v  16.289886 6.065557 -4.294378
v  16.267504 6.087719 -4.299813
v  16.268414 6.089179 -4.296563
v  16.270283 6.091377 -4.293775
v  16.272884 6.094047 -4.291773
v  16.275906 6.096867 -4.290801
v  16.278988 6.099495 -4.290989
v  16.281771 6.101616 -4.292329
v  16.283922 6.102974 -4.294676
v  16.285185 6.103407 -4.297749
v  16.285402 6.102863 -4.301171
v  16.284538 6.101408 -4.304515
v  16.282690 6.099216 -4.307361
v  16.280075 6.096551 -4.309356
v  16.277016 6.093735 -4.310260
v  16.273882 6.091104 -4.309977
v  16.271065 6.088978 -4.308556
v  16.268908 6.087614 -4.306182
v  16.267672 6.087177 -4.303148
v  16.314175 6.047729 -4.280400
v  16.316275 6.049817 -4.277650
v  16.318821 6.052578 -4.275714
v  16.321508 6.055683 -4.274828
v  16.324022 6.058753 -4.275111
v  16.326069 6.061421 -4.276551
v  16.327410 6.063365 -4.278989
v  16.327883 6.064352 -4.282136
v  16.327425 6.064262 -4.285605
v  16.326084 6.063107 -4.288960
v  16.324013 6.061027 -4.291777
v  16.321455 6.058270 -4.293704
v  16.318720 6.055168 -4.294513
v  16.316149 6.052094 -4.294116
v  16.314058 6.049422 -4.292583
v  16.312710 6.047472 -4.290113
v  16.322371 6.058374 -4.286256
v  16.301720 6.075995 -4.293145
v  16.312778 6.046572 -4.283645
v  16.312267 6.046483 -4.287012
v  16.290892 6.065120 -4.290748
v  16.292101 6.066386 -4.287497
v  16.294140 6.068490 -4.284721
v  16.296757 6.071177 -4.282748
v  16.299639 6.074125 -4.281815
v  16.302444 6.076973 -4.282046
v  16.304850 6.079381 -4.283434
v  16.306566 6.081059 -4.285826
v  16.307390 6.081806 -4.288939
v  16.307219 6.081531 -4.292390
v  16.306061 6.080268 -4.295745
v  16.304049 6.078171 -4.298583
v  16.301420 6.075488 -4.300550
v  16.298494 6.072544 -4.301412
v  16.295631 6.069693 -4.301073
v  16.293188 6.067280 -4.299596
v  16.291462 6.065596 -4.297174
v  16.290667 6.064845 -4.294103
v  16.335733 6.032441 -4.274208
v  16.337761 6.034589 -4.271500
v  16.340109 6.037496 -4.269627
v  16.342497 6.040808 -4.268817
v  16.344641 6.044127 -4.269182
v  16.346296 6.047054 -4.270700
v  16.347271 6.049233 -4.273202
v  16.347448 6.050406 -4.276393
v  16.346802 6.050431 -4.279878
v  16.345398 6.049304 -4.283218
v  16.343399 6.047161 -4.285995
v  16.341040 6.044259 -4.287859
v  16.338606 6.040947 -4.288587
v  16.336401 6.037626 -4.288106
v  16.334700 6.034696 -4.286493
v  16.333715 6.032510 -4.283959
v  16.342375 6.044113 -4.280371
v  16.318497 6.052552 -4.287449
v  16.334274 6.031310 -4.277437
v  16.333570 6.031333 -4.280818
v  16.313538 6.045979 -4.283415
v  16.314938 6.047134 -4.280171
v  16.317038 6.049222 -4.277422
v  16.319580 6.051989 -4.275488
v  16.322260 6.055098 -4.274604
v  16.324762 6.058177 -4.274889
v  16.326796 6.060854 -4.276331
v  16.328123 6.062807 -4.278770
v  16.328585 6.063801 -4.281919
v  16.328119 6.063716 -4.285389
v  16.326775 6.062565 -4.288743
v  16.324701 6.060483 -4.291559
v  16.322147 6.057722 -4.293486
v  16.319422 6.054614 -4.294291
v  16.316862 6.051533 -4.293893
v  16.314783 6.048851 -4.292356
v  16.313448 6.046893 -4.289886
v  16.313017 6.045895 -4.286783
v  16.359739 6.019979 -4.267831
v  16.361633 6.022224 -4.265175
v  16.363663 6.025315 -4.263406
v  16.365583 6.028876 -4.262736
v  16.367170 6.032477 -4.263261
v  16.368244 6.035686 -4.264939
v  16.368685 6.038117 -4.267583
v  16.368437 6.039476 -4.270879
v  16.367527 6.039601 -4.274421
v  16.366056 6.038475 -4.277764
v  16.364191 6.036232 -4.280484
v  16.362152 6.033146 -4.282244
v  16.360186 6.029587 -4.282832
v  16.358540 6.025981 -4.282192
v  16.357420 6.022768 -4.280420
v  16.356970 6.020334 -4.277747
v  16.361061 6.025248 -4.272070
v  16.343029 6.043702 -4.280187
v  16.358213 6.018850 -4.271062
v  16.357248 6.018972 -4.274500
v  16.334986 6.030864 -4.277239
v  16.336445 6.031996 -4.274011
v  16.338469 6.034148 -4.271304
v  16.340805 6.037061 -4.269434
v  16.343180 6.040380 -4.268628
v  16.345310 6.043710 -4.268997
v  16.346951 6.046645 -4.270517
v  16.347914 6.048831 -4.273023
v  16.348082 6.050011 -4.276215
v  16.347429 6.050036 -4.279700
v  16.346027 6.048910 -4.283041
v  16.344034 6.046762 -4.285814
v  16.341684 6.043854 -4.287675
v  16.339264 6.040534 -4.288401
v  16.337072 6.037205 -4.287915
v  16.335386 6.034265 -4.286300
v  16.334415 6.032072 -4.283764
v  16.334278 6.030890 -4.280620
v  16.389828 6.011382 -4.262197
v  16.391703 6.013657 -4.259542
v  16.393356 6.016866 -4.257874
v  16.394588 6.020622 -4.257388
v  16.395258 6.024472 -4.258157
v  16.395292 6.027953 -4.260105
v  16.394693 6.030644 -4.263012
v  16.393536 6.032225 -4.266531
v  16.391958 6.032500 -4.270232
v  16.390141 6.031436 -4.273652
v  16.388294 6.029165 -4.276364
v  16.386639 6.025956 -4.278031
v  16.385372 6.022198 -4.278451
v  16.384655 6.018342 -4.277586
v  16.384581 6.014858 -4.275556
v  16.385166 6.012165 -4.272619
v  16.388567 6.017257 -4.268353
v  16.362043 6.024837 -4.271835
v  16.387964 6.010315 -4.265523
v  16.386343 6.010587 -4.269137
v  16.359240 6.018421 -4.270813
v  16.360779 6.019547 -4.267579
v  16.362673 6.021794 -4.264925
v  16.364693 6.024889 -4.263159
v  16.366594 6.028458 -4.262495
v  16.368156 6.032071 -4.263028
v  16.369202 6.035292 -4.264714
v  16.369612 6.037733 -4.267367
v  16.369337 6.039102 -4.270671
v  16.368408 6.039232 -4.274218
v  16.366924 6.038107 -4.277562
v  16.365059 6.035866 -4.280282
v  16.363029 6.032773 -4.282038
v  16.361082 6.029206 -4.282620
v  16.359461 6.025590 -4.281973
v  16.358372 6.022365 -4.280192
v  16.357952 6.019919 -4.277511
v  16.358255 6.018549 -4.274257
v  16.418888 6.013277 -4.261530
v  16.420649 6.015547 -4.258837
v  16.421722 6.018707 -4.257194
v  16.421974 6.022377 -4.256796
v  16.421377 6.026117 -4.257695
v  16.420006 6.029475 -4.259790
v  16.418030 6.032047 -4.262839
v  16.415691 6.033523 -4.266479
v  16.413275 6.033722 -4.270272
v  16.411068 6.032621 -4.273752
v  16.409334 6.030353 -4.276491
v  16.408276 6.027186 -4.278152
v  16.408022 6.023510 -4.278531
v  16.408600 6.019764 -4.277585
v  16.409945 6.016405 -4.275437
v  16.411898 6.013834 -4.272354
v  16.412813 6.025784 -4.271028
v  16.392897 6.023807 -4.264795
v  16.416658 6.012172 -4.264957
v  16.414228 6.012364 -4.268715
v  16.388971 6.010188 -4.265407
v  16.390848 6.011252 -4.262077
v  16.392721 6.013529 -4.259423
v  16.394358 6.016740 -4.257756
v  16.395559 6.020500 -4.257277
v  16.396191 6.024354 -4.258054
v  16.396181 6.027841 -4.260011
v  16.395540 6.030538 -4.262926
v  16.394348 6.032121 -4.266452
v  16.392742 6.032399 -4.270158
v  16.390911 6.031338 -4.273582
v  16.389069 6.029064 -4.276294
v  16.387430 6.025853 -4.277958
v  16.386194 6.022089 -4.278374
v  16.385515 6.018230 -4.277501
v  16.385485 6.014741 -4.275463
v  16.386112 6.012043 -4.272518
v  16.387323 6.010462 -4.269028
v  16.415382 6.036728 -4.266033
v  16.444954 6.028624 -4.268798
v  16.446556 6.030811 -4.265988
v  16.447048 6.033624 -4.264164
v  16.446363 6.036724 -4.263543
v  16.444580 6.039740 -4.264194
v  16.441912 6.042310 -4.266035
v  16.438683 6.044121 -4.268850
v  16.435286 6.044956 -4.272303
v  16.432138 6.044711 -4.275984
v  16.429621 6.043416 -4.279450
v  16.428036 6.041224 -4.282288
v  16.427574 6.038404 -4.284145
v  16.428284 6.035295 -4.284794
v  16.430077 6.032275 -4.284153
v  16.432735 6.029706 -4.282296
v  16.435938 6.027902 -4.279449
v  16.440878 6.034014 -4.271049
v  16.418024 6.020366 -4.264352
v  16.442436 6.027327 -4.272263
v  16.439306 6.027076 -4.275963
v  16.417603 6.012460 -4.265060
v  16.419847 6.013570 -4.261631
v  16.421602 6.015838 -4.258935
v  16.422653 6.018990 -4.257290
v  16.422869 6.022649 -4.256890
v  16.422226 6.026373 -4.257789
v  16.420803 6.029715 -4.259883
v  16.418779 6.032271 -4.262931
v  16.416399 6.033734 -4.266570
v  16.413954 6.033924 -4.270362
v  16.411734 6.032819 -4.273843
v  16.410004 6.030550 -4.276585
v  16.408970 6.027392 -4.278248
v  16.408751 6.023726 -4.278630
v  16.409376 6.019998 -4.277688
v  16.410772 6.016654 -4.275542
v  16.412775 6.014100 -4.272460
v  16.415146 6.012644 -4.268821
v  16.430019 6.047050 -4.276296
v  16.219046 6.463767 -4.716674
v  16.220390 6.467683 -4.715424
v  16.222191 6.470368 -4.712929
v  16.224228 6.471499 -4.709493
v  16.226265 6.470942 -4.705541
v  16.228056 6.468761 -4.701557
v  16.229387 6.465225 -4.698029
v  16.230097 6.460757 -4.695380
v  16.230099 6.455895 -4.693924
v  16.229385 6.451228 -4.693825
v  16.228043 6.447314 -4.695088
v  16.226233 6.444627 -4.697560
v  16.224173 6.443489 -4.700945
v  16.222118 6.444042 -4.704844
v  16.220318 6.446217 -4.708797
v  16.218992 6.449754 -4.712330
v  16.224195 6.452120 -4.703580
v  16.254568 6.455538 -4.718380
v  16.218319 6.459093 -4.716530
v  16.218300 6.454226 -4.715018
v  16.250828 6.462921 -4.732305
v  16.251591 6.467562 -4.732366
v  16.252743 6.471402 -4.730929
v  16.254147 6.473977 -4.728170
v  16.255632 6.474976 -4.724422
v  16.257025 6.474283 -4.720145
v  16.258162 6.471980 -4.715867
v  16.258905 6.468345 -4.712108
v  16.259167 6.463818 -4.709317
v  16.258911 6.458942 -4.707827
v  16.258163 6.454307 -4.707807
v  16.257011 6.450467 -4.709253
v  16.255596 6.447889 -4.711989
v  16.254089 6.446882 -4.715688
v  16.252676 6.447570 -4.719913
v  16.251532 6.449870 -4.724166
v  16.250793 6.453506 -4.727934
v  16.250549 6.458039 -4.730762
v  16.186661 6.458757 -4.694921
v  16.188135 6.462784 -4.693924
v  16.190332 6.465642 -4.691833
v  16.192989 6.466990 -4.688905
v  16.195787 6.466667 -4.685499
v  16.198395 6.464709 -4.682039
v  16.200495 6.461356 -4.678948
v  16.201836 6.457011 -4.676595
v  16.202253 6.452199 -4.675261
v  16.201693 6.447500 -4.675095
v  16.200218 6.443478 -4.676110
v  16.198009 6.440618 -4.678180
v  16.195333 6.439266 -4.681059
v  16.192518 6.439584 -4.684407
v  16.189903 6.441536 -4.687833
v  16.187809 6.444888 -4.690927
v  16.193718 6.458447 -4.686487
v  16.223070 6.451980 -4.702931
v  16.186085 6.454051 -4.694706
v  16.186483 6.449234 -4.693316
v  16.217131 6.458930 -4.715852
v  16.217863 6.463608 -4.716006
v  16.219219 6.467530 -4.714771
v  16.221035 6.470222 -4.712292
v  16.223093 6.471362 -4.708872
v  16.225151 6.470812 -4.704932
v  16.226961 6.468640 -4.700957
v  16.228308 6.465106 -4.697433
v  16.229029 6.460641 -4.694783
v  16.229031 6.455780 -4.693320
v  16.228313 6.451108 -4.693211
v  16.226959 6.447190 -4.694460
v  16.225132 6.444495 -4.696916
v  16.223053 6.443348 -4.700285
v  16.220976 6.443892 -4.704171
v  16.219156 6.446062 -4.708114
v  16.217815 6.449593 -4.711643
v  16.217112 6.454062 -4.714333
v  16.196346 6.435003 -4.680357
v  16.159214 6.449748 -4.666116
v  16.160421 6.453724 -4.664895
v  16.162752 6.456674 -4.662994
v  16.165926 6.458239 -4.660646
v  16.169563 6.458230 -4.658144
v  16.173229 6.456650 -4.655797
v  16.176481 6.453690 -4.653894
v  16.178925 6.449708 -4.652664
v  16.180260 6.445186 -4.652246
v  16.180326 6.440666 -4.652685
v  16.179113 6.436695 -4.653920
v  16.176767 6.433749 -4.655799
v  16.173576 6.432183 -4.658101
v  16.169928 6.432187 -4.660553
v  16.166260 6.433762 -4.662872
v  16.163019 6.436716 -4.664779
v  16.171234 6.440209 -4.658895
v  16.193720 6.447625 -4.682726
v  16.159273 6.445222 -4.666513
v  16.160593 6.440697 -4.666046
v  16.185072 6.453840 -4.693863
v  16.185631 6.458544 -4.694068
v  16.187103 6.462571 -4.693073
v  16.189310 6.465435 -4.690993
v  16.191988 6.466791 -4.688084
v  16.194817 6.466474 -4.684705
v  16.197456 6.464526 -4.681273
v  16.199594 6.461182 -4.678210
v  16.200970 6.456844 -4.675881
v  16.201412 6.452036 -4.674565
v  16.200867 6.447339 -4.674408
v  16.199396 6.443315 -4.675422
v  16.197178 6.440452 -4.677481
v  16.194481 6.439092 -4.680340
v  16.191635 6.439402 -4.683664
v  16.188986 6.441345 -4.687061
v  16.186853 6.444688 -4.690126
v  16.185495 6.449027 -4.692491
v  16.139803 6.434567 -4.632763
v  16.140743 6.438406 -4.631176
v  16.143049 6.441392 -4.629303
v  16.146446 6.443165 -4.627374
v  16.150526 6.443509 -4.625631
v  16.154802 6.442383 -4.624289
v  16.158754 6.439925 -4.623515
v  16.161903 6.436433 -4.623399
v  16.163868 6.432327 -4.623951
v  16.164406 6.428102 -4.625097
v  16.163456 6.424270 -4.626689
v  16.161133 6.421288 -4.628540
v  16.157719 6.419517 -4.630426
v  16.153629 6.419170 -4.632129
v  16.149359 6.420290 -4.633450
v  16.145424 6.422741 -4.634233
v  16.147387 6.432578 -4.630397
v  16.170530 6.439840 -4.657934
v  16.140341 6.430338 -4.633875
v  16.142292 6.426231 -4.634383
v  16.158461 6.444796 -4.665405
v  16.158377 6.449310 -4.664976
v  16.159573 6.453280 -4.663738
v  16.161901 6.456232 -4.661838
v  16.165089 6.457804 -4.659509
v  16.168753 6.457808 -4.657042
v  16.172451 6.456244 -4.654740
v  16.175741 6.453306 -4.652889
v  16.178221 6.449343 -4.651708
v  16.179590 6.444836 -4.651334
v  16.179680 6.440329 -4.651805
v  16.178478 6.436363 -4.653056
v  16.176134 6.433414 -4.654933
v  16.172930 6.431842 -4.657217
v  16.169254 6.431833 -4.659636
v  16.165556 6.433390 -4.661909
v  16.162277 6.436325 -4.663765
v  16.159815 6.440287 -4.664981
v  16.127998 6.421103 -4.605646
v  16.128881 6.424982 -4.604090
v  16.131189 6.428070 -4.602361
v  16.134651 6.430000 -4.600674
v  16.138849 6.430535 -4.599239
v  16.143280 6.429613 -4.598234
v  16.147408 6.427346 -4.597787
v  16.150732 6.424007 -4.597948
v  16.152847 6.420002 -4.598692
v  16.153498 6.415812 -4.599922
v  16.152603 6.411943 -4.601484
v  16.150276 6.408859 -4.603190
v  16.146797 6.406931 -4.604837
v  16.142591 6.406392 -4.606234
v  16.138168 6.407308 -4.607218
v  16.134056 6.409569 -4.607674
v  16.135860 6.419477 -4.603883
v  16.146980 6.432154 -4.629540
v  16.128649 6.416910 -4.606844
v  16.130751 6.412904 -4.607546
v  16.139929 6.429903 -4.633002
v  16.139389 6.434134 -4.631894
v  16.140327 6.437978 -4.630314
v  16.142635 6.440969 -4.628448
v  16.146034 6.442747 -4.626528
v  16.150118 6.443096 -4.624792
v  16.154396 6.441976 -4.623457
v  16.158354 6.439522 -4.622687
v  16.161507 6.436030 -4.622575
v  16.163473 6.431926 -4.623126
v  16.164015 6.427700 -4.624267
v  16.163065 6.423862 -4.625854
v  16.160740 6.420876 -4.627696
v  16.157324 6.419100 -4.629575
v  16.153231 6.418746 -4.631269
v  16.148958 6.419861 -4.632583
v  16.145016 6.422308 -4.633361
v  16.141882 6.425796 -4.633509
v  16.118235 6.404931 -4.575120
v  16.119009 6.408710 -4.573345
v  16.121286 6.411823 -4.571618
v  16.124794 6.413888 -4.570150
v  16.129112 6.414659 -4.569129
v  16.133720 6.414042 -4.568682
v  16.138063 6.412112 -4.568865
v  16.141611 6.409105 -4.569654
v  16.143934 6.405383 -4.570948
v  16.144749 6.401395 -4.572584
v  16.143959 6.397622 -4.574361
v  16.141661 6.394516 -4.576064
v  16.138136 6.392453 -4.577491
v  16.133812 6.391680 -4.578478
v  16.129211 6.392290 -4.578910
v  16.124887 6.394213 -4.578739
v  16.126410 6.403845 -4.574344
v  16.135490 6.418992 -4.602919
v  16.119051 6.400938 -4.576729
v  16.121361 6.397214 -4.577983
v  16.128273 6.416414 -4.605862
v  16.127619 6.420604 -4.604654
v  16.128496 6.424479 -4.603093
v  16.130806 6.427571 -4.601368
v  16.134268 6.429504 -4.599689
v  16.138472 6.430048 -4.598266
v  16.142908 6.429133 -4.597278
v  16.147043 6.426876 -4.596848
v  16.150375 6.423547 -4.597025
v  16.152498 6.419548 -4.597783
v  16.153152 6.415363 -4.599022
v  16.152262 6.411495 -4.600588
v  16.149935 6.408409 -4.602292
v  16.146456 6.406476 -4.603931
v  16.142244 6.405930 -4.605315
v  16.137814 6.406836 -4.606282
v  16.133696 6.409089 -4.606721
v  16.130383 6.412414 -4.606577
v  16.134718 6.388461 -4.580667
v  16.112717 6.384490 -4.541805
v  16.113417 6.388064 -4.539667
v  16.115660 6.391133 -4.537847
v  16.119181 6.393326 -4.536569
v  16.123560 6.394378 -4.535995
v  16.128269 6.394162 -4.536200
v  16.132738 6.392704 -4.537160
v  16.136425 6.390186 -4.538758
v  16.138882 6.386908 -4.540793
v  16.139809 6.383267 -4.543016
v  16.139095 6.379702 -4.545153
v  16.136829 6.376641 -4.546947
v  16.133287 6.374451 -4.548186
v  16.128902 6.373395 -4.548727
v  16.124203 6.373605 -4.548511
v  16.119753 6.375052 -4.547565
v  16.121071 6.384118 -4.542188
v  16.126146 6.403244 -4.573280
v  16.113646 6.380845 -4.544003
v  16.116089 6.377567 -4.546001
v  16.118784 6.400326 -4.575645
v  16.117962 6.404310 -4.574018
v  16.118734 6.408084 -4.572233
v  16.121010 6.411196 -4.570504
v  16.124517 6.413266 -4.569045
v  16.128839 6.414044 -4.568038
v  16.133453 6.413440 -4.567612
v  16.137802 6.411525 -4.567820
v  16.141357 6.408532 -4.568635
v  16.143686 6.404821 -4.569952
v  16.144506 6.400844 -4.571607
v  16.143721 6.397076 -4.573394
v  16.141424 6.393971 -4.575099
v  16.137897 6.391903 -4.576519
v  16.133570 6.391120 -4.577491
v  16.128965 6.391720 -4.577902
v  16.124636 6.393628 -4.577704
v  16.121099 6.396616 -4.576922
v  16.113604 6.358333 -4.506557
v  16.114311 6.361624 -4.504033
v  16.116549 6.364602 -4.502083
v  16.120050 6.366910 -4.500946
v  16.124397 6.368266 -4.500768
v  16.129066 6.368509 -4.501575
v  16.133495 6.367610 -4.503273
v  16.137146 6.365679 -4.505653
v  16.139570 6.362950 -4.508423
v  16.140478 6.359752 -4.511241
v  16.139755 6.356470 -4.513764
v  16.137495 6.353498 -4.515687
v  16.133972 6.351193 -4.516784
v  16.129616 6.349833 -4.516929
v  16.124952 6.349584 -4.516109
v  16.120543 6.350475 -4.514425
v  16.121887 6.358773 -4.508009
v  16.120985 6.383349 -4.541044
v  16.114510 6.355129 -4.509350
v  16.116919 6.352401 -4.512080
v  16.113560 6.380064 -4.542843
v  16.112631 6.383697 -4.540623
v  16.113327 6.387261 -4.538471
v  16.115570 6.390327 -4.536647
v  16.119091 6.392525 -4.535375
v  16.123470 6.393587 -4.534815
v  16.128180 6.393385 -4.535042
v  16.132652 6.391948 -4.536030
v  16.136341 6.389448 -4.537656
v  16.138800 6.386188 -4.539719
v  16.139729 6.382561 -4.541963
v  16.139015 6.379004 -4.544112
v  16.136749 6.375945 -4.545911
v  16.133209 6.373751 -4.547144
v  16.128822 6.372685 -4.547670
v  16.124121 6.372879 -4.547431
v  16.119671 6.374309 -4.546458
v  16.116005 6.376805 -4.544868
v  16.143677 6.356339 -4.516141
v  16.119274 6.335129 -4.480313
v  16.120041 6.338238 -4.477600
v  16.122292 6.341156 -4.475585
v  16.125763 6.343534 -4.474517
v  16.130039 6.345082 -4.474532
v  16.134607 6.345613 -4.475635
v  16.138914 6.345066 -4.477695
v  16.142439 6.343508 -4.480462
v  16.144753 6.341127 -4.483595
v  16.145569 6.338212 -4.486709
v  16.144793 6.335112 -4.489425
v  16.142517 6.332200 -4.491414
v  16.139023 6.329824 -4.492440
v  16.134733 6.328275 -4.492388
v  16.130169 6.327734 -4.491269
v  16.125879 6.328274 -4.489222
v  16.127377 6.336082 -4.482291
v  16.122049 6.357788 -4.506811
v  16.120087 6.332208 -4.483398
v  16.122379 6.329827 -4.486490
v  16.114674 6.354131 -4.508138
v  16.113771 6.357317 -4.505326
v  16.114483 6.360598 -4.502790
v  16.116720 6.363574 -4.500835
v  16.120220 6.365885 -4.499702
v  16.124563 6.367251 -4.499537
v  16.129230 6.367511 -4.500361
v  16.133654 6.366631 -4.502082
v  16.137299 6.364721 -4.504487
v  16.139721 6.362011 -4.507280
v  16.140623 6.358829 -4.510117
v  16.139900 6.355557 -4.512651
v  16.137638 6.352590 -4.514579
v  16.134117 6.350282 -4.515672
v  16.129763 6.348912 -4.515804
v  16.125103 6.348646 -4.514966
v  16.120699 6.349516 -4.513260
v  16.117079 6.351420 -4.510891
v  16.126547 6.314124 -4.458789
v  16.127356 6.317159 -4.456025
v  16.129618 6.320060 -4.453998
v  16.133064 6.322474 -4.452956
v  16.137283 6.324111 -4.453033
v  16.141769 6.324771 -4.454227
v  16.145981 6.324380 -4.456397
v  16.149406 6.322985 -4.459278
v  16.151628 6.320755 -4.462518
v  16.152374 6.317957 -4.465716
v  16.151556 6.314930 -4.468485
v  16.149271 6.312037 -4.470488
v  16.145802 6.309626 -4.471487
v  16.141567 6.307987 -4.471371
v  16.137083 6.307317 -4.470158
v  16.132889 6.307700 -4.467999
v  16.135687 6.317584 -4.459093
v  16.127581 6.335423 -4.481588
v  16.127287 6.311321 -4.461957
v  16.129488 6.309090 -4.465153
v  16.120293 6.331543 -4.482693
v  16.119482 6.334461 -4.479603
v  16.120249 6.337567 -4.476888
v  16.122501 6.340485 -4.474872
v  16.125971 6.342864 -4.473805
v  16.130245 6.344416 -4.473824
v  16.134811 6.344952 -4.474931
v  16.139116 6.344412 -4.476996
v  16.142637 6.342860 -4.479768
v  16.144945 6.340485 -4.482906
v  16.145760 6.337574 -4.486025
v  16.144981 6.334476 -4.488743
v  16.142708 6.331565 -4.490732
v  16.139214 6.329190 -4.491758
v  16.134926 6.327636 -4.491703
v  16.130363 6.327091 -4.490579
v  16.126078 6.327622 -4.488526
v  16.122581 6.329170 -4.485789
v  16.136158 6.290534 -4.436235
v  16.137012 6.293515 -4.433453
v  16.139284 6.296402 -4.431422
v  16.142704 6.298846 -4.430392
v  16.146862 6.300550 -4.430494
v  16.151260 6.301312 -4.431725
v  16.155371 6.301041 -4.433937
v  16.158691 6.299767 -4.436864
v  16.160818 6.297651 -4.440145
v  16.161491 6.294944 -4.443378
v  16.160627 6.291972 -4.446168
v  16.158335 6.289093 -4.448175
v  16.154890 6.286652 -4.449163
v  16.150715 6.284943 -4.449019
v  16.146317 6.284173 -4.447767
v  16.142223 6.284438 -4.445563
v  16.145164 6.294146 -4.436595
v  16.135975 6.316819 -4.458335
v  16.136822 6.287822 -4.439434
v  16.138927 6.285704 -4.442672
v  16.127577 6.310554 -4.461198
v  16.126839 6.313353 -4.458029
v  16.127649 6.316386 -4.455263
v  16.129911 6.319285 -4.453236
v  16.133358 6.321701 -4.452195
v  16.137575 6.323340 -4.452274
v  16.142057 6.324007 -4.453469
v  16.146265 6.323620 -4.455641
v  16.149687 6.322228 -4.458525
v  16.151907 6.320002 -4.461766
v  16.152651 6.317209 -4.464968
v  16.151831 6.314185 -4.467737
v  16.149546 6.311292 -4.469740
v  16.146076 6.308880 -4.470740
v  16.141844 6.307237 -4.470621
v  16.137363 6.306563 -4.469406
v  16.133173 6.306942 -4.467245
v  16.129774 6.308327 -4.464396
v  16.147776 6.265240 -4.413593
v  16.148678 6.268174 -4.410811
v  16.150961 6.271049 -4.408782
v  16.154350 6.273519 -4.407754
v  16.158442 6.275285 -4.407859
v  16.162746 6.276133 -4.409092
v  16.166744 6.275965 -4.411308
v  16.169952 6.274800 -4.414240
v  16.171976 6.272779 -4.417525
v  16.172569 6.270150 -4.420762
v  16.171659 6.267225 -4.423553
v  16.169355 6.264357 -4.425560
v  16.165941 6.261889 -4.426545
v  16.161831 6.260120 -4.426395
v  16.157524 6.259264 -4.425139
v  16.153542 6.259425 -4.422930
v  16.155760 6.267879 -4.414736
v  16.152830 6.290511 -4.442246
v  16.148357 6.262605 -4.416793
v  16.150360 6.260585 -4.420033
v  16.137186 6.286981 -4.438654
v  16.136524 6.289690 -4.435454
v  16.137381 6.292670 -4.432672
v  16.139652 6.295556 -4.430641
v  16.143072 6.298001 -4.429611
v  16.147228 6.299707 -4.429714
v  16.151623 6.300473 -4.430944
v  16.155729 6.300204 -4.433158
v  16.159046 6.298935 -4.436086
v  16.161169 6.296822 -4.439368
v  16.161840 6.294117 -4.442601
v  16.160976 6.291148 -4.445391
v  16.158682 6.288269 -4.447398
v  16.155239 6.285828 -4.448386
v  16.151066 6.284117 -4.448241
v  16.146669 6.283344 -4.446989
v  16.142580 6.283604 -4.444784
v  16.139288 6.284867 -4.441892
v  16.161007 6.239132 -4.391709
v  16.161964 6.242021 -4.388940
v  16.164257 6.244886 -4.386915
v  16.167614 6.247383 -4.385882
v  16.171633 6.249207 -4.385976
v  16.175833 6.250142 -4.387192
v  16.179708 6.250072 -4.389387
v  16.182791 6.249012 -4.392297
v  16.184702 6.247087 -4.395564
v  16.185207 6.244532 -4.398786
v  16.184246 6.241651 -4.401568
v  16.181932 6.238792 -4.403572
v  16.178551 6.236300 -4.404560
v  16.174511 6.234472 -4.404421
v  16.170307 6.233531 -4.403178
v  16.166445 6.233591 -4.400988
v  16.168650 6.239817 -4.395117
v  16.156183 6.266999 -4.413967
v  16.161501 6.236571 -4.394893
v  16.163387 6.234647 -4.398111
v  16.148785 6.261721 -4.416025
v  16.148205 6.264353 -4.412825
v  16.149109 6.267284 -4.410044
v  16.151392 6.270161 -4.408014
v  16.154779 6.272632 -4.406986
v  16.158871 6.274399 -4.407091
v  16.163172 6.275250 -4.408324
v  16.167166 6.275084 -4.410541
v  16.170368 6.273923 -4.413471
v  16.172390 6.271908 -4.416757
v  16.172979 6.269279 -4.419993
v  16.172068 6.266356 -4.422783
v  16.169764 6.263489 -4.424790
v  16.166351 6.261022 -4.425776
v  16.162243 6.259251 -4.425627
v  16.157940 6.258390 -4.424370
v  16.153959 6.258548 -4.422160
v  16.150782 6.259704 -4.419265
v  16.559162 6.381062 -4.589628
v  16.560038 6.384326 -4.587422
v  16.559196 6.387082 -4.585114
v  16.556740 6.388997 -4.582978
v  16.552967 6.389839 -4.581280
v  16.548336 6.389504 -4.580227
v  16.543413 6.388035 -4.579953
v  16.538795 6.385602 -4.580494
v  16.535036 6.382501 -4.581780
v  16.532589 6.379104 -4.583653
v  16.531740 6.375825 -4.585881
v  16.532589 6.373062 -4.588192
v  16.535034 6.371148 -4.590306
v  16.538778 6.370316 -4.591973
v  16.543379 6.370663 -4.592998
v  16.548283 6.372146 -4.593259
v  16.541708 6.376661 -4.588046
v  16.543745 6.355030 -4.556640
v  16.556683 6.377678 -4.591469
v  16.552904 6.374582 -4.592729
v  16.558601 6.355546 -4.559139
v  16.561098 6.358734 -4.557006
v  16.562004 6.361856 -4.554607
v  16.561205 6.364540 -4.552228
v  16.558796 6.366462 -4.550156
v  16.555067 6.367391 -4.548642
v  16.550472 6.367213 -4.547875
v  16.545574 6.365947 -4.547953
v  16.540966 6.363744 -4.548867
v  16.537201 6.360868 -4.550508
v  16.534731 6.357670 -4.552670
v  16.533850 6.354534 -4.555090
v  16.534658 6.351841 -4.557471
v  16.537060 6.349921 -4.559527
v  16.540764 6.349000 -4.561012
v  16.545328 6.349193 -4.561752
v  16.550207 6.350469 -4.561663
v  16.554817 6.352676 -4.560755
v  16.554928 6.402048 -4.622202
v  16.555759 6.405447 -4.620189
v  16.554878 6.408255 -4.617932
v  16.552389 6.410138 -4.615703
v  16.548595 6.410866 -4.613777
v  16.543959 6.410351 -4.612389
v  16.539045 6.408650 -4.611716
v  16.534451 6.405971 -4.611837
v  16.530727 6.402634 -4.612740
v  16.528320 6.399042 -4.614306
v  16.527515 6.395629 -4.616342
v  16.528402 6.392813 -4.618598
v  16.530876 6.390931 -4.620804
v  16.534641 6.390212 -4.622696
v  16.539246 6.390740 -4.624053
v  16.544142 6.392452 -4.624715
v  16.537491 6.396862 -4.619229
v  16.541599 6.377398 -4.589151
v  16.552488 6.398468 -4.623734
v  16.548740 6.395135 -4.624606
v  16.556576 6.378434 -4.592609
v  16.559055 6.381824 -4.590779
v  16.559927 6.385095 -4.588580
v  16.559086 6.387852 -4.586273
v  16.556627 6.389765 -4.584135
v  16.552853 6.390604 -4.582428
v  16.548222 6.390265 -4.581366
v  16.543299 6.388787 -4.581079
v  16.538681 6.386347 -4.581606
v  16.534924 6.383236 -4.582880
v  16.532476 6.379834 -4.584744
v  16.531630 6.376551 -4.586965
v  16.532480 6.373785 -4.589273
v  16.534925 6.371872 -4.591390
v  16.538671 6.371044 -4.593065
v  16.543272 6.371396 -4.594099
v  16.548176 6.372885 -4.594372
v  16.552797 6.375329 -4.593856
v  16.527069 6.375741 -4.587547
v  16.548384 6.419550 -4.651104
v  16.549149 6.423098 -4.649322
v  16.548231 6.425949 -4.647109
v  16.545738 6.427761 -4.644731
v  16.541977 6.428317 -4.642481
v  16.537405 6.427548 -4.640639
v  16.532581 6.425545 -4.639429
v  16.528088 6.422545 -4.639001
v  16.524469 6.418912 -4.639404
v  16.522154 6.415083 -4.640583
v  16.521416 6.411525 -4.642387
v  16.522339 6.408667 -4.644598
v  16.524813 6.406854 -4.646949
v  16.528542 6.406306 -4.649160
v  16.533081 6.407088 -4.650973
v  16.537889 6.409102 -4.652172
v  16.539274 6.421331 -4.645407
v  16.545561 6.404887 -4.618313
v  16.546034 6.415735 -4.652246
v  16.542387 6.412106 -4.652614
v  16.552303 6.399126 -4.624788
v  16.554743 6.402714 -4.623268
v  16.555573 6.406116 -4.621261
v  16.554691 6.408926 -4.619006
v  16.552200 6.410807 -4.616773
v  16.548407 6.411530 -4.614837
v  16.543770 6.411007 -4.613437
v  16.538858 6.409300 -4.612748
v  16.534266 6.406610 -4.612854
v  16.530544 6.403265 -4.613741
v  16.528139 6.399666 -4.615297
v  16.527336 6.396249 -4.617326
v  16.528225 6.393431 -4.619581
v  16.530699 6.391550 -4.621790
v  16.534464 6.390837 -4.623692
v  16.539066 6.391373 -4.625062
v  16.543961 6.393092 -4.625739
v  16.548559 6.395784 -4.625644
v  16.533710 6.437154 -4.685465
v  16.533796 6.441090 -4.684452
v  16.532394 6.444073 -4.682620
v  16.529675 6.445743 -4.680188
v  16.525969 6.445898 -4.677459
v  16.521730 6.444519 -4.674767
v  16.517477 6.441771 -4.672443
v  16.513723 6.437983 -4.670770
v  16.510920 6.433611 -4.669944
v  16.509398 6.429185 -4.670058
v  16.509335 6.425240 -4.671091
v  16.510735 6.422253 -4.672916
v  16.513428 6.420584 -4.675312
v  16.517096 6.420434 -4.677999
v  16.521301 6.421823 -4.680657
v  16.525545 6.424580 -4.682975
v  16.518421 6.428344 -4.676807
v  16.530998 6.413785 -4.647075
v  16.532150 6.432736 -4.685538
v  16.529312 6.428369 -4.684671
v  16.545771 6.416248 -4.653126
v  16.548117 6.420074 -4.652001
v  16.548878 6.423626 -4.650229
v  16.547958 6.426477 -4.648016
v  16.545467 6.428288 -4.645632
v  16.541708 6.428837 -4.643369
v  16.537142 6.428057 -4.641505
v  16.532322 6.426040 -4.640273
v  16.527836 6.423027 -4.639822
v  16.524223 6.419382 -4.640203
v  16.521912 6.415545 -4.641366
v  16.521177 6.411980 -4.643162
v  16.522102 6.409120 -4.645370
v  16.524576 6.407310 -4.647728
v  16.528301 6.406770 -4.649954
v  16.532837 6.407563 -4.651786
v  16.537638 6.409591 -4.653008
v  16.542131 6.412608 -4.653474
v  16.511515 6.446513 -4.713882
v  16.511189 6.450519 -4.713094
v  16.509661 6.453421 -4.711156
v  16.507113 6.454870 -4.708305
v  16.503860 6.454691 -4.704889
v  16.500301 6.452907 -4.701331
v  16.496868 6.449728 -4.698063
v  16.493977 6.445541 -4.695480
v  16.491972 6.440849 -4.693889
v  16.491091 6.436218 -4.693474
v  16.491432 6.432207 -4.694279
v  16.492952 6.429300 -4.696203
v  16.495472 6.427851 -4.699018
v  16.498688 6.428031 -4.702391
v  16.502222 6.429821 -4.705920
v  16.505650 6.433003 -4.709188
v  16.500288 6.436249 -4.703173
v  16.517855 6.428717 -4.677779
v  16.510599 6.441885 -4.713431
v  16.508560 6.437193 -4.711797
v  16.531523 6.433172 -4.686618
v  16.533052 6.437603 -4.686577
v  16.533113 6.441547 -4.685585
v  16.531696 6.444530 -4.683760
v  16.528973 6.446194 -4.681320
v  16.525274 6.446333 -4.678568
v  16.521055 6.444936 -4.675843
v  16.516830 6.442166 -4.673479
v  16.513109 6.438354 -4.671763
v  16.510340 6.433965 -4.670898
v  16.508850 6.429524 -4.670979
v  16.508810 6.425570 -4.671992
v  16.510225 6.422583 -4.673809
v  16.512922 6.420922 -4.676213
v  16.516582 6.420788 -4.678922
v  16.520769 6.422195 -4.681614
v  16.524982 6.424973 -4.683972
v  16.528719 6.428786 -4.685711
v  16.484648 6.452679 -4.734777
v  16.484825 6.456558 -4.733549
v  16.483963 6.459287 -4.731050
v  16.482164 6.460535 -4.727582
v  16.479652 6.460155 -4.723572
v  16.476734 6.458190 -4.719508
v  16.473766 6.454880 -4.715885
v  16.471107 6.450620 -4.713139
v  16.469074 6.445923 -4.711600
v  16.467907 6.441357 -4.711442
v  16.467741 6.437471 -4.712680
v  16.468597 6.434737 -4.715166
v  16.470369 6.433484 -4.718599
v  16.472853 6.433865 -4.722571
v  16.475748 6.435833 -4.726613
v  16.478714 6.439151 -4.730237
v  16.473997 6.441823 -4.721363
v  16.496948 6.441355 -4.700643
v  16.483454 6.448114 -4.734587
v  16.481390 6.443417 -4.733008
v  16.509752 6.442107 -4.714260
v  16.510658 6.446735 -4.714715
v  16.510334 6.450739 -4.713920
v  16.508814 6.453638 -4.711968
v  16.506283 6.455080 -4.709097
v  16.503052 6.454893 -4.705659
v  16.499517 6.453100 -4.702077
v  16.496109 6.449914 -4.698787
v  16.493240 6.445721 -4.696187
v  16.491251 6.441024 -4.694585
v  16.490377 6.436390 -4.694168
v  16.490719 6.432382 -4.694978
v  16.492229 6.429480 -4.696916
v  16.494732 6.428037 -4.699750
v  16.497927 6.428226 -4.703146
v  16.501434 6.430025 -4.706699
v  16.504839 6.433215 -4.709989
v  16.507729 6.437411 -4.712616
v  16.453886 6.459709 -4.749444
v  16.454437 6.463514 -4.748013
v  16.454172 6.466115 -4.745208
v  16.453127 6.467199 -4.741368
v  16.451427 6.466636 -4.736962
v  16.449287 6.464496 -4.732529
v  16.446964 6.461034 -4.728606
v  16.444742 6.456669 -4.725666
v  16.442883 6.451925 -4.724059
v  16.441608 6.447372 -4.723972
v  16.441067 6.443562 -4.725412
v  16.441324 6.440954 -4.728201
v  16.442348 6.439863 -4.732008
v  16.444021 6.440423 -4.736379
v  16.446144 6.442565 -4.740792
v  16.448465 6.446033 -4.744719
v  16.445663 6.448288 -4.734889
v  16.476664 6.452279 -4.723828
v  16.452589 6.455157 -4.749328
v  16.450705 6.450407 -4.747684
v  16.482445 6.448363 -4.735210
v  16.483669 6.452920 -4.735381
v  16.483881 6.456791 -4.734131
v  16.483055 6.459511 -4.731607
v  16.481291 6.460751 -4.728120
v  16.478806 6.460362 -4.724092
v  16.475904 6.458395 -4.720016
v  16.472942 6.455081 -4.716391
v  16.470274 6.450824 -4.713651
v  16.468222 6.446132 -4.712123
v  16.467026 6.441573 -4.711985
v  16.466825 6.437695 -4.713246
v  16.467644 6.434969 -4.715755
v  16.469383 6.433726 -4.719209
v  16.471838 6.434113 -4.723199
v  16.474718 6.436086 -4.727251
v  16.477678 6.439404 -4.730879
v  16.480364 6.443669 -4.733644
v  16.418772 6.465172 -4.757712
v  16.419516 6.468960 -4.756223
v  16.419699 6.471500 -4.753301
v  16.419296 6.472491 -4.749301
v  16.418364 6.471811 -4.744712
v  16.417017 6.469544 -4.740092
v  16.415419 6.465965 -4.736002
v  16.413763 6.461502 -4.732936
v  16.412249 6.456694 -4.731257
v  16.411051 6.452118 -4.731164
v  16.410313 6.448327 -4.732659
v  16.410122 6.445779 -4.735564
v  16.410503 6.444781 -4.739530
v  16.411415 6.445455 -4.744085
v  16.412748 6.447722 -4.748686
v  16.414347 6.451308 -4.752781
v  16.413210 6.453320 -4.742540
v  16.449001 6.456109 -4.741947
v  16.417555 6.460595 -4.757589
v  16.416018 6.455779 -4.755875
v  16.451454 6.455371 -4.749715
v  16.452749 6.459925 -4.749832
v  16.453308 6.463728 -4.748398
v  16.453060 6.466328 -4.745587
v  16.452036 6.467407 -4.741740
v  16.450365 6.466839 -4.737324
v  16.448254 6.464693 -4.732881
v  16.445955 6.461226 -4.728949
v  16.443752 6.456858 -4.726003
v  16.441904 6.452111 -4.724392
v  16.440632 6.447558 -4.724306
v  16.440083 6.443748 -4.725748
v  16.440323 6.441143 -4.728543
v  16.441324 6.440057 -4.732358
v  16.442970 6.440620 -4.736738
v  16.445066 6.442770 -4.741161
v  16.447361 6.446243 -4.745097
v  16.449581 6.450620 -4.748069
v  16.380257 6.468673 -4.759741
v  16.381130 6.472461 -4.758235
v  16.381683 6.474977 -4.755284
v  16.381851 6.475923 -4.751249
v  16.381615 6.475182 -4.746621
v  16.381008 6.472847 -4.741965
v  16.380106 6.469198 -4.737845
v  16.379015 6.464675 -4.734760
v  16.377867 6.459823 -4.733074
v  16.376795 6.455225 -4.732985
v  16.375925 6.451435 -4.734497
v  16.375362 6.448911 -4.737428
v  16.375177 6.447958 -4.741427
v  16.375393 6.448693 -4.746020
v  16.375990 6.451026 -4.750657
v  16.376894 6.454679 -4.754783
v  16.379793 6.467307 -4.748223
v  16.411888 6.453844 -4.741174
v  16.379169 6.464071 -4.759622
v  16.378000 6.459210 -4.757898
v  16.416290 6.460748 -4.757761
v  16.417501 6.465326 -4.757884
v  16.418253 6.469114 -4.756393
v  16.418447 6.471653 -4.753470
v  16.418068 6.472641 -4.749468
v  16.417160 6.471959 -4.744875
v  16.415838 6.469688 -4.740252
v  16.414265 6.466105 -4.736157
v  16.412630 6.461641 -4.733091
v  16.411125 6.456831 -4.731410
v  16.409933 6.452254 -4.731316
v  16.409189 6.448462 -4.732813
v  16.408985 6.445916 -4.735720
v  16.409346 6.444920 -4.739689
v  16.410231 6.445599 -4.744247
v  16.411539 6.447869 -4.748852
v  16.413115 6.451457 -4.752951
v  16.414764 6.455931 -4.756046
v  16.344194 6.469986 -4.756489
v  16.345182 6.473785 -4.754997
v  16.346033 6.476309 -4.752076
v  16.346643 6.477250 -4.748082
v  16.346947 6.476499 -4.743502
v  16.346907 6.474147 -4.738897
v  16.346531 6.470479 -4.734825
v  16.345863 6.465935 -4.731777
v  16.344982 6.461061 -4.730114
v  16.343992 6.456448 -4.730031
v  16.343006 6.452648 -4.731530
v  16.342146 6.450119 -4.734431
v  16.341515 6.449170 -4.738387
v  16.341194 6.449914 -4.742927
v  16.341225 6.452264 -4.747512
v  16.341604 6.455936 -4.751591
v  16.345064 6.468597 -4.745095
v  16.375948 6.456651 -4.744418
v  16.343187 6.465368 -4.756374
v  16.342287 6.460488 -4.754671
v  16.377804 6.464150 -4.759583
v  16.378887 6.468754 -4.759700
v  16.379765 6.472542 -4.758194
v  16.380329 6.475059 -4.755245
v  16.380514 6.476004 -4.751210
v  16.380302 6.475262 -4.746583
v  16.379719 6.472925 -4.741928
v  16.378839 6.469274 -4.737809
v  16.377769 6.464750 -4.734724
v  16.376633 6.459897 -4.733038
v  16.375566 6.455297 -4.732950
v  16.374693 6.451508 -4.734462
v  16.374119 6.448984 -4.737392
v  16.373915 6.448031 -4.741393
v  16.374109 6.448767 -4.745985
v  16.374681 6.451104 -4.750620
v  16.375563 6.454758 -4.754745
v  16.376648 6.459290 -4.757860
v  16.306036 6.470009 -4.749169
v  16.307098 6.473824 -4.747695
v  16.308161 6.476361 -4.744819
v  16.309103 6.477315 -4.740890
v  16.309811 6.476574 -4.736392
v  16.310204 6.474228 -4.731872
v  16.310234 6.470560 -4.727881
v  16.309900 6.466011 -4.724898
v  16.309238 6.461129 -4.723279
v  16.308325 6.456501 -4.723209
v  16.307266 6.452687 -4.724691
v  16.306190 6.450144 -4.727545
v  16.305231 6.449181 -4.731431
v  16.304504 6.449915 -4.735887
v  16.304102 6.452260 -4.740384
v  16.304075 6.455932 -4.744382
v  16.307911 6.468647 -4.737977
v  16.341887 6.457843 -4.741199
v  16.305107 6.465377 -4.749064
v  16.304426 6.460488 -4.747399
v  16.341927 6.465389 -4.756191
v  16.342932 6.470007 -4.756306
v  16.343922 6.473808 -4.754814
v  16.344780 6.476331 -4.751894
v  16.345406 6.477272 -4.747901
v  16.345722 6.476521 -4.743325
v  16.345697 6.474170 -4.738721
v  16.345337 6.470500 -4.734652
v  16.344681 6.465957 -4.731606
v  16.343807 6.461083 -4.729945
v  16.342819 6.456469 -4.729861
v  16.341831 6.452668 -4.731360
v  16.340961 6.450140 -4.734259
v  16.340319 6.449190 -4.738213
v  16.339983 6.449934 -4.742752
v  16.339998 6.452283 -4.747334
v  16.340364 6.455956 -4.751411
v  16.341034 6.460507 -4.754488
v  16.267803 6.468671 -4.738305
v  16.268925 6.472502 -4.736855
v  16.270206 6.475063 -4.734046
v  16.271496 6.476043 -4.730223
v  16.272640 6.475327 -4.725854
v  16.273506 6.473003 -4.721474
v  16.273993 6.469352 -4.717616
v  16.274036 6.464811 -4.714746
v  16.273630 6.459929 -4.713200
v  16.272821 6.455295 -4.713159
v  16.271702 6.451464 -4.714618
v  16.270409 6.448899 -4.717402
v  16.269098 6.447911 -4.721179
v  16.267935 6.448620 -4.725501
v  16.267057 6.450941 -4.729855
v  16.266579 6.454595 -4.733720
v  16.269840 6.456579 -4.723967
v  16.305145 6.457829 -4.734041
v  16.266977 6.464031 -4.738224
v  16.266552 6.459142 -4.736629
v  16.303780 6.465354 -4.748746
v  16.304705 6.469986 -4.748851
v  16.305767 6.473802 -4.747378
v  16.306839 6.476340 -4.744503
v  16.307791 6.477295 -4.740578
v  16.308512 6.476556 -4.736082
v  16.308920 6.474209 -4.731565
v  16.308964 6.470541 -4.727578
v  16.308643 6.465991 -4.724599
v  16.307989 6.461110 -4.722981
v  16.307077 6.456481 -4.722912
v  16.306017 6.452667 -4.724393
v  16.304935 6.450124 -4.727245
v  16.303963 6.449160 -4.731128
v  16.303223 6.449893 -4.735580
v  16.302807 6.452238 -4.740074
v  16.302765 6.455909 -4.744069
v  16.303104 6.460464 -4.747083
v  16.233421 6.465730 -4.724297
v  16.234621 6.469582 -4.722886
v  16.236214 6.472184 -4.720215
v  16.238007 6.473222 -4.716608
v  16.239788 6.472572 -4.712510
v  16.241346 6.470314 -4.708424
v  16.242495 6.466721 -4.704847
v  16.243095 6.462225 -4.702210
v  16.243069 6.457370 -4.700825
v  16.242418 6.452738 -4.700848
v  16.241219 6.448888 -4.702269
v  16.239614 6.446284 -4.704915
v  16.237801 6.445239 -4.708472
v  16.236000 6.445883 -4.712520
v  16.234434 6.448138 -4.716577
v  16.233292 6.451731 -4.720160
v  16.237869 6.453861 -4.710981
v  16.268642 6.456510 -4.723560
v  16.232756 6.461092 -4.724277
v  16.232710 6.456231 -4.722837
v  16.265718 6.463958 -4.737796
v  16.266541 6.468599 -4.737876
v  16.267662 6.472430 -4.736426
v  16.268953 6.474991 -4.733622
v  16.270256 6.475974 -4.729803
v  16.271418 6.475260 -4.725439
v  16.272305 6.472937 -4.721066
v  16.272806 6.469285 -4.717215
v  16.272867 6.464746 -4.714349
v  16.272470 6.459865 -4.712808
v  16.271666 6.455229 -4.712767
v  16.270544 6.451397 -4.714226
v  16.269243 6.448832 -4.717007
v  16.267920 6.447843 -4.720778
v  16.266737 6.448551 -4.725094
v  16.265842 6.450871 -4.729442
v  16.265345 6.454524 -4.733300
v  16.265303 6.459070 -4.736204
v  16.460436 6.048410 -4.279149
v  16.461557 6.050692 -4.276396
v  16.461369 6.053255 -4.274570
v  16.459892 6.055790 -4.273884
v  16.457298 6.057997 -4.274414
v  16.453897 6.059606 -4.276093
v  16.450104 6.060421 -4.278723
v  16.446377 6.060347 -4.281986
v  16.443174 6.059389 -4.285499
v  16.440884 6.057662 -4.288840
v  16.439781 6.055375 -4.291611
v  16.439997 6.052804 -4.293472
v  16.441502 6.050261 -4.294195
v  16.444107 6.048056 -4.293685
v  16.447498 6.046451 -4.292001
v  16.451267 6.045641 -4.289345
v  16.447176 6.051969 -4.287957
v  16.441267 6.034383 -4.271248
v  16.458147 6.046686 -4.282500
v  16.454964 6.045723 -4.286043
v  16.442854 6.027724 -4.272477
v  16.445375 6.029026 -4.269013
v  16.446976 6.031209 -4.266199
v  16.447458 6.034015 -4.264372
v  16.446760 6.037103 -4.263743
v  16.444960 6.040102 -4.264386
v  16.442274 6.042653 -4.266220
v  16.439028 6.044446 -4.269027
v  16.435617 6.045267 -4.272474
v  16.432459 6.045012 -4.276152
v  16.429937 6.043713 -4.279618
v  16.428356 6.041524 -4.282456
v  16.427902 6.038713 -4.284319
v  16.428627 6.035616 -4.284976
v  16.430437 6.032613 -4.284344
v  16.433113 6.030062 -4.282495
v  16.436333 6.028274 -4.279656
v  16.439714 6.027462 -4.276175
v  16.426245 6.035853 -4.288604
v  16.471987 6.070908 -4.293310
v  16.473032 6.073092 -4.290414
v  16.472700 6.075435 -4.288370
v  16.471027 6.077653 -4.287419
v  16.468210 6.079484 -4.287669
v  16.464586 6.080704 -4.289086
v  16.460594 6.081165 -4.291498
v  16.456718 6.080811 -4.294619
v  16.453434 6.079683 -4.298078
v  16.451136 6.077917 -4.301463
v  16.450106 6.075726 -4.304368
v  16.450462 6.073377 -4.306442
v  16.452160 6.071152 -4.307430
v  16.454990 6.069322 -4.307206
v  16.458609 6.068108 -4.305793
v  16.462580 6.067655 -4.303359
v  16.457981 6.073160 -4.301288
v  16.447546 6.052594 -4.288338
v  16.469692 6.069145 -4.296710
v  16.466427 6.068015 -4.300201
v  16.458553 6.047369 -4.282918
v  16.460844 6.049097 -4.279566
v  16.461962 6.051373 -4.276807
v  16.461767 6.053925 -4.274972
v  16.460279 6.056447 -4.274276
v  16.457676 6.058635 -4.274795
v  16.454264 6.060225 -4.276464
v  16.450460 6.061026 -4.279083
v  16.446728 6.060939 -4.282340
v  16.443520 6.059973 -4.285850
v  16.441227 6.058244 -4.289192
v  16.440128 6.055961 -4.291966
v  16.440351 6.053401 -4.293838
v  16.441864 6.050873 -4.294571
v  16.444481 6.048685 -4.294073
v  16.447884 6.047098 -4.292400
v  16.451662 6.046305 -4.289754
v  16.455366 6.046400 -4.286458
v  16.483192 6.097467 -4.311199
v  16.484180 6.099603 -4.308182
v  16.483753 6.101830 -4.305964
v  16.481956 6.103880 -4.304808
v  16.479004 6.105510 -4.304848
v  16.475248 6.106519 -4.306075
v  16.471148 6.106786 -4.308341
v  16.467196 6.106277 -4.311376
v  16.463877 6.105052 -4.314817
v  16.461590 6.103261 -4.318255
v  16.460615 6.101119 -4.321280
v  16.461065 6.098885 -4.323524
v  16.462885 6.096831 -4.324714
v  16.465849 6.095203 -4.324700
v  16.469599 6.094201 -4.323477
v  16.473680 6.093942 -4.321194
v  16.468769 6.099008 -4.318604
v  16.458351 6.073981 -4.301821
v  16.480911 6.095680 -4.314654
v  16.477608 6.094456 -4.318128
v  16.470081 6.069998 -4.297268
v  16.472374 6.071762 -4.293866
v  16.473417 6.073943 -4.290966
v  16.473082 6.076280 -4.288916
v  16.471403 6.078492 -4.287957
v  16.468580 6.080313 -4.288198
v  16.464952 6.081522 -4.289608
v  16.460955 6.081975 -4.292014
v  16.457077 6.081614 -4.295132
v  16.453791 6.080482 -4.298590
v  16.451492 6.078715 -4.301976
v  16.450464 6.076527 -4.304886
v  16.450825 6.074183 -4.306967
v  16.452528 6.071966 -4.307961
v  16.455362 6.070146 -4.307746
v  16.458986 6.068941 -4.306339
v  16.462961 6.068496 -4.303912
v  16.466812 6.068862 -4.300759
v  16.494217 6.126193 -4.331789
v  16.495155 6.128308 -4.328667
v  16.494650 6.130476 -4.326303
v  16.492756 6.132440 -4.324982
v  16.489700 6.133960 -4.324854
v  16.485847 6.134852 -4.325933
v  16.481667 6.135011 -4.328087
v  16.477661 6.134413 -4.331060
v  16.474321 6.133130 -4.334498
v  16.472050 6.131318 -4.337990
v  16.471123 6.129196 -4.341118
v  16.471649 6.127021 -4.343504
v  16.473562 6.125055 -4.344855
v  16.476629 6.123538 -4.345006
v  16.480476 6.122651 -4.343932
v  16.484640 6.122500 -4.341762
v  16.479483 6.127336 -4.338754
v  16.469137 6.099951 -4.319254
v  16.491947 6.124386 -4.335296
v  16.488623 6.123104 -4.338760
v  16.481293 6.096640 -4.315324
v  16.483574 6.098428 -4.311867
v  16.484560 6.100562 -4.308845
v  16.484129 6.102786 -4.306621
v  16.482328 6.104833 -4.305459
v  16.479372 6.106457 -4.305493
v  16.475615 6.107460 -4.306715
v  16.471510 6.107723 -4.308976
v  16.467556 6.107210 -4.312008
v  16.464235 6.105984 -4.315450
v  16.461950 6.104192 -4.318891
v  16.460976 6.102050 -4.321918
v  16.461430 6.099819 -4.324168
v  16.463253 6.097769 -4.325364
v  16.466221 6.096147 -4.325355
v  16.469973 6.095149 -4.324139
v  16.474060 6.094893 -4.321859
v  16.477989 6.095412 -4.318796
v  16.467020 6.128579 -4.342504
v  16.504921 6.155052 -4.353706
v  16.505823 6.157168 -4.350504
v  16.505255 6.159319 -4.348029
v  16.503284 6.161248 -4.346577
v  16.500143 6.162719 -4.346318
v  16.496212 6.163556 -4.347281
v  16.491966 6.163658 -4.349349
v  16.487919 6.163009 -4.352275
v  16.484562 6.161686 -4.355711
v  16.482304 6.159853 -4.359245
v  16.481415 6.157728 -4.362454
v  16.482002 6.155569 -4.364950
v  16.483990 6.153640 -4.366426
v  16.487137 6.152172 -4.366705
v  16.491062 6.151340 -4.365746
v  16.495293 6.151248 -4.363663
v  16.489941 6.155990 -4.360332
v  16.479847 6.128325 -4.339478
v  16.502663 6.153222 -4.357252
v  16.499319 6.151902 -4.360712
v  16.492321 6.125384 -4.336035
v  16.494589 6.127192 -4.332527
v  16.495527 6.129307 -4.329401
v  16.495020 6.131474 -4.327034
v  16.493122 6.133434 -4.325706
v  16.490063 6.134952 -4.325574
v  16.486208 6.135842 -4.326648
v  16.482023 6.135998 -4.328799
v  16.478018 6.135396 -4.331771
v  16.474678 6.134114 -4.335208
v  16.472406 6.132301 -4.338702
v  16.471481 6.130178 -4.341833
v  16.472010 6.128004 -4.344223
v  16.473927 6.126040 -4.345580
v  16.476995 6.124527 -4.345736
v  16.480844 6.123642 -4.344666
v  16.485012 6.123495 -4.342500
v  16.488997 6.124101 -4.339499
v  16.514990 6.181935 -4.375295
v  16.515869 6.184082 -4.372047
v  16.515261 6.186258 -4.369506
v  16.513237 6.188200 -4.367972
v  16.510036 6.189678 -4.367630
v  16.506046 6.190509 -4.368516
v  16.501749 6.190593 -4.370524
v  16.497667 6.189918 -4.373415
v  16.494293 6.188566 -4.376844
v  16.492039 6.186698 -4.380401
v  16.491175 6.184544 -4.383657
v  16.491800 6.182362 -4.386217
v  16.493841 6.180416 -4.387773
v  16.497046 6.178943 -4.388130
v  16.501028 6.178120 -4.387244
v  16.505306 6.178042 -4.385221
v  16.499823 6.182821 -4.381690
v  16.490292 6.156951 -4.361079
v  16.512733 6.180074 -4.378859
v  16.509371 6.178721 -4.382307
v  16.503021 6.154188 -4.358011
v  16.505280 6.156018 -4.354464
v  16.506180 6.158134 -4.351259
v  16.505610 6.160286 -4.348782
v  16.503637 6.162214 -4.347324
v  16.500494 6.163685 -4.347063
v  16.496561 6.164521 -4.348022
v  16.492311 6.164620 -4.350087
v  16.488264 6.163970 -4.353012
v  16.484907 6.162648 -4.356448
v  16.482649 6.160812 -4.359983
v  16.481760 6.158687 -4.363195
v  16.482349 6.156528 -4.365694
v  16.484341 6.154600 -4.367175
v  16.487490 6.153131 -4.367456
v  16.491417 6.152303 -4.366500
v  16.495647 6.152211 -4.364420
v  16.499678 6.152865 -4.361470
v  16.524679 6.206476 -4.396214
v  16.525549 6.208656 -4.392937
v  16.524920 6.210871 -4.390353
v  16.522861 6.212853 -4.388771
v  16.519621 6.214367 -4.388376
v  16.515591 6.215228 -4.389216
v  16.511257 6.215329 -4.391189
v  16.507147 6.214658 -4.394062
v  16.503757 6.213295 -4.397489
v  16.501499 6.211405 -4.401062
v  16.500645 6.209216 -4.404347
v  16.501295 6.206994 -4.406950
v  16.503366 6.205009 -4.408553
v  16.506609 6.203500 -4.408958
v  16.510628 6.202648 -4.408114
v  16.514944 6.202555 -4.406126
v  16.509377 6.207433 -4.402473
v  16.500145 6.183681 -4.382396
v  16.522419 6.204593 -4.399790
v  16.519037 6.203229 -4.403234
v  16.513062 6.180933 -4.379570
v  16.515320 6.182795 -4.376006
v  16.516197 6.184944 -4.372758
v  16.515589 6.187121 -4.370214
v  16.513563 6.189066 -4.368680
v  16.510361 6.190545 -4.368335
v  16.506371 6.191376 -4.369220
v  16.502071 6.191461 -4.371226
v  16.497988 6.190785 -4.374117
v  16.494614 6.189432 -4.377545
v  16.492359 6.187563 -4.381102
v  16.491495 6.185407 -4.384359
v  16.492123 6.183223 -4.386921
v  16.494164 6.181274 -4.388477
v  16.497370 6.179801 -4.388836
v  16.501352 6.178977 -4.387952
v  16.505634 6.178901 -4.385930
v  16.509699 6.179580 -4.383018
v  16.535789 6.234612 -4.420814
v  16.536623 6.236720 -4.417415
v  16.535931 6.238840 -4.414663
v  16.533789 6.240720 -4.412888
v  16.530457 6.242133 -4.412300
v  16.526335 6.242906 -4.412971
v  16.521921 6.242947 -4.414818
v  16.517754 6.242248 -4.417623
v  16.514336 6.240892 -4.421049
v  16.512081 6.239046 -4.424686
v  16.511261 6.236931 -4.428095
v  16.511972 6.234803 -4.430862
v  16.514124 6.232922 -4.432655
v  16.517458 6.231514 -4.433250
v  16.521570 6.230748 -4.432577
v  16.525965 6.230716 -4.430716
v  16.519091 6.236868 -4.424833
v  16.509773 6.208435 -4.403336
v  16.533529 6.232771 -4.424453
v  16.530117 6.231419 -4.427893
v  16.522825 6.205601 -4.400669
v  16.525085 6.207480 -4.397089
v  16.525953 6.209658 -4.393808
v  16.525320 6.211868 -4.391216
v  16.523258 6.213848 -4.389627
v  16.520016 6.215358 -4.389225
v  16.515984 6.216216 -4.390059
v  16.511648 6.216316 -4.392031
v  16.507536 6.215646 -4.394902
v  16.504147 6.214284 -4.398332
v  16.501888 6.212398 -4.401907
v  16.501036 6.210214 -4.405199
v  16.501688 6.207997 -4.407809
v  16.503763 6.206015 -4.409419
v  16.507008 6.204509 -4.409830
v  16.511032 6.203659 -4.408992
v  16.515348 6.203566 -4.407007
v  16.519444 6.204241 -4.404114
v  16.497372 6.211068 -4.407630
v  16.545181 6.260720 -4.444287
v  16.546022 6.262866 -4.440876
v  16.545296 6.264973 -4.438037
v  16.543085 6.266784 -4.436109
v  16.539660 6.268083 -4.435323
v  16.535429 6.268710 -4.435774
v  16.530907 6.268589 -4.437408
v  16.526644 6.267735 -4.440032
v  16.523153 6.266248 -4.443330
v  16.520857 6.264307 -4.446905
v  16.520033 6.262151 -4.450326
v  16.520775 6.260039 -4.453178
v  16.522993 6.258226 -4.455118
v  16.526417 6.256935 -4.455907
v  16.530636 6.256316 -4.455450
v  16.535141 6.256444 -4.453803
v  16.528051 6.262377 -4.447437
v  16.519428 6.237778 -4.425624
v  16.542879 6.258788 -4.447862
v  16.539392 6.257304 -4.451167
v  16.533880 6.233696 -4.425269
v  16.536140 6.235537 -4.421631
v  16.536976 6.237643 -4.418229
v  16.536280 6.239762 -4.415473
v  16.534136 6.241639 -4.413692
v  16.530800 6.243048 -4.413097
v  16.526674 6.243818 -4.413760
v  16.522259 6.243854 -4.415603
v  16.518089 6.243153 -4.418404
v  16.514669 6.241795 -4.421827
v  16.512413 6.239947 -4.425465
v  16.511595 6.237832 -4.428876
v  16.512306 6.235707 -4.431649
v  16.514462 6.233829 -4.433446
v  16.517797 6.232425 -4.434049
v  16.521914 6.231663 -4.433381
v  16.526312 6.231635 -4.431525
v  16.530468 6.232341 -4.428707
v  16.507813 6.238552 -4.431136
v  16.552595 6.285434 -4.468030
v  16.553473 6.287755 -4.464746
v  16.552740 6.289946 -4.461925
v  16.550478 6.291743 -4.459901
v  16.546965 6.292930 -4.458920
v  16.542622 6.293363 -4.459103
v  16.537975 6.292986 -4.460426
v  16.533590 6.291846 -4.462734
v  16.529997 6.290078 -4.465749
v  16.527628 6.287895 -4.469106
v  16.526766 6.285563 -4.472400
v  16.527514 6.283366 -4.475232
v  16.529778 6.281568 -4.477259
v  16.533283 6.280387 -4.478235
v  16.537613 6.279964 -4.478044
v  16.542242 6.280351 -4.476709
v  16.535000 6.286244 -4.470058
v  16.528324 6.263217 -4.448203
v  16.550213 6.283259 -4.471380
v  16.546618 6.281495 -4.474395
v  16.543165 6.259649 -4.448656
v  16.545471 6.261587 -4.445087
v  16.546310 6.263738 -4.441678
v  16.545584 6.265845 -4.438837
v  16.543371 6.267653 -4.436905
v  16.539942 6.268948 -4.436113
v  16.535707 6.269569 -4.436555
v  16.531183 6.269443 -4.438181
v  16.526915 6.268579 -4.440796
v  16.523420 6.267086 -4.444086
v  16.521124 6.265140 -4.447657
v  16.520298 6.262979 -4.451076
v  16.521040 6.260866 -4.453930
v  16.523260 6.259056 -4.455873
v  16.526688 6.257767 -4.456669
v  16.530910 6.257154 -4.456220
v  16.535419 6.257291 -4.454582
v  16.539675 6.258157 -4.451954
v  16.557823 6.309341 -4.493536
v  16.558748 6.311948 -4.490527
v  16.558018 6.314315 -4.487837
v  16.555719 6.316160 -4.485790
v  16.552130 6.317260 -4.484632
v  16.547684 6.317481 -4.484509
v  16.542923 6.316792 -4.485435
v  16.538424 6.315279 -4.487301
v  16.534729 6.313121 -4.489883
v  16.532284 6.310579 -4.492867
v  16.531378 6.307961 -4.495891
v  16.532120 6.305585 -4.498587
v  16.534418 6.303741 -4.500629
v  16.537992 6.302649 -4.501773
v  16.542419 6.302441 -4.501883
v  16.547163 6.303138 -4.500945
v  16.539812 6.309196 -4.494246
v  16.535202 6.287042 -4.470852
v  16.555359 6.306809 -4.496505
v  16.551657 6.304656 -4.499078
v  16.550426 6.284079 -4.472206
v  16.552811 6.286263 -4.468866
v  16.553692 6.288591 -4.465590
v  16.552958 6.290788 -4.462770
v  16.550695 6.292586 -4.460745
v  16.547178 6.293770 -4.459759
v  16.542831 6.294195 -4.459929
v  16.538181 6.293808 -4.461241
v  16.533792 6.292656 -4.463536
v  16.530195 6.290875 -4.466538
v  16.527822 6.288682 -4.469885
v  16.526958 6.286343 -4.473172
v  16.527706 6.284140 -4.476002
v  16.529972 6.282341 -4.478030
v  16.533482 6.281166 -4.479012
v  16.537813 6.280751 -4.478831
v  16.542448 6.281146 -4.477509
v  16.546827 6.282301 -4.475207
v  16.560711 6.333055 -4.522278
v  16.561644 6.335964 -4.519612
v  16.560892 6.338526 -4.517111
v  16.558542 6.340431 -4.515075
v  16.554882 6.341452 -4.513753
v  16.550354 6.341463 -4.513308
v  16.545509 6.340460 -4.513797
v  16.540936 6.338562 -4.515163
v  16.537184 6.335999 -4.517242
v  16.534706 6.333080 -4.519779
v  16.533794 6.330157 -4.522463
v  16.534557 6.327588 -4.524968
v  16.536900 6.325683 -4.526993
v  16.540541 6.324671 -4.528293
v  16.545044 6.324673 -4.528716
v  16.549871 6.325686 -4.528215
v  16.553057 6.334184 -4.520491
v  16.539938 6.309976 -4.495124
v  16.558208 6.330147 -4.524791
v  16.554443 6.327588 -4.526852
v  16.555496 6.307609 -4.497415
v  16.557962 6.310153 -4.494461
v  16.558887 6.312771 -4.491464
v  16.558157 6.315145 -4.488780
v  16.555859 6.316993 -4.486733
v  16.552265 6.318089 -4.485571
v  16.547817 6.318304 -4.485435
v  16.543053 6.317605 -4.486347
v  16.538549 6.316079 -4.488196
v  16.534853 6.313906 -4.490762
v  16.532404 6.311349 -4.493731
v  16.531498 6.308721 -4.496742
v  16.532240 6.306339 -4.499432
v  16.534538 6.304492 -4.501474
v  16.538116 6.303401 -4.502624
v  16.542545 6.303201 -4.502743
v  16.547293 6.303909 -4.501821
v  16.551790 6.305439 -4.499969
v  16.527246 6.308652 -4.498221
v  16.561157 6.357033 -4.554607
v  16.562065 6.360143 -4.552193
v  16.561270 6.362820 -4.549808
v  16.558863 6.364742 -4.547739
v  16.555138 6.365676 -4.546238
v  16.550549 6.365510 -4.545492
v  16.545652 6.364260 -4.545596
v  16.541044 6.362075 -4.546539
v  16.537279 6.359219 -4.548205
v  16.534809 6.356035 -4.550391
v  16.533926 6.352911 -4.552826
v  16.534731 6.350225 -4.555214
v  16.537128 6.348305 -4.557266
v  16.540829 6.347379 -4.558740
v  16.545389 6.347558 -4.559459
v  16.550266 6.348821 -4.559342
v  16.553385 6.357924 -4.552382
v  16.553106 6.334996 -4.521532
v  16.558659 6.353860 -4.556764
v  16.554874 6.351008 -4.558407
v  16.558262 6.330956 -4.525834
v  16.560764 6.333876 -4.523335
v  16.561699 6.336793 -4.520679
v  16.560946 6.339360 -4.518183
v  16.558594 6.341267 -4.516147
v  16.554930 6.342284 -4.514818
v  16.550400 6.342288 -4.514361
v  16.545553 6.341276 -4.514837
v  16.540977 6.339366 -4.516189
v  16.537226 6.336791 -4.518251
v  16.534746 6.333860 -4.520775
v  16.533836 6.330930 -4.523449
v  16.534601 6.328356 -4.525949
v  16.536945 6.326448 -4.527973
v  16.540588 6.325440 -4.529280
v  16.545094 6.325449 -4.529714
v  16.549923 6.326473 -4.529226
v  16.554497 6.328387 -4.527880
v  16.417709 6.467523 -4.762241
v  16.144617 6.262883 -4.411095
v  16.378345 6.470953 -4.763920
v  16.132982 6.294414 -4.432040
v  16.549280 6.430644 -4.643670
v  16.217318 6.139894 -4.327089
v  16.158298 6.439580 -4.668973
v  16.143217 6.378900 -4.546797
v  16.504614 6.428484 -4.669206
v  16.291906 6.064828 -4.301918
v  16.231853 6.449331 -4.690694
v  16.465712 6.051189 -4.274688
v  16.116018 6.331301 -4.483897
v  16.167894 6.432941 -4.623000
v  16.331583 6.066215 -4.280964
v  16.558506 6.305192 -4.496833
v  16.350744 6.052739 -4.275366
v  16.467926 6.131073 -4.344316
v  16.345654 6.477651 -4.737194
v  16.286898 6.063807 -4.290995
v  16.553232 6.281547 -4.471610
v  16.143904 6.446171 -4.625370
v  16.368586 6.040778 -4.279061
v  16.485550 6.454915 -4.739574
v  16.210791 6.201826 -4.348441
v  16.519550 6.188400 -4.368092
v  16.234432 6.472968 -4.726600
v  16.453779 6.462058 -4.754195
v  16.172525 6.248972 -4.380943
v  16.489071 6.425782 -4.695084
v  16.271341 6.444806 -4.715081
v  16.533596 6.347879 -4.561841
v  16.533554 6.324676 -4.530595
v  16.144703 6.269011 -4.409386
v  16.521099 6.404314 -4.648648
v  16.116545 6.329605 -4.482093
v  16.478138 6.159512 -4.365622
v  16.449812 6.071558 -4.311525
v  16.241068 6.145119 -4.337326
v  16.116762 6.394866 -4.532534
v  16.308886 6.477672 -4.729955
v  16.306847 6.446008 -4.725049
v  16.559292 6.401999 -4.622626
v  16.564615 6.385066 -4.586773
v  16.562052 6.352386 -4.557225
v  16.187431 6.242270 -4.404140
v  16.375387 6.444433 -4.745934
v  16.166122 6.294178 -4.443131
v  16.122160 6.416160 -4.566958
v  16.194010 6.208898 -4.386695
v  16.540354 6.235965 -4.415065
v  16.258320 6.130740 -4.301999
v  16.499607 6.129941 -4.327965
v  16.156954 6.317239 -4.465484
v  16.548574 6.432013 -4.646014
v  16.519510 6.183338 -4.369624
v  16.548746 6.321339 -4.483401
v  16.251143 6.474646 -4.734017
v  16.222219 6.475696 -4.709753
v  16.541256 6.238326 -4.417149
v  16.498653 6.127394 -4.326087
v  16.153261 6.424951 -4.594774
v  16.509617 6.458980 -4.709153
v  16.344860 6.454292 -4.725985
v  16.245386 6.456862 -4.697452
v  16.174492 6.213378 -4.364690
v  16.148788 6.417121 -4.635533
v  16.274260 6.098871 -4.288375
v  16.488081 6.454288 -4.738012
v  16.187254 6.469227 -4.692479
v  16.441425 6.435380 -4.730254
v  16.437256 6.440726 -4.722429
v  16.218706 6.138082 -4.326004
v  16.304699 6.477138 -4.750793
v  16.343277 6.477178 -4.758402
v  16.522863 6.286576 -4.474949
v  16.526348 6.279356 -4.479413
v  16.127865 6.411519 -4.609791
v  16.149591 6.339183 -4.488240
v  16.522738 6.395145 -4.617461
v  16.559990 6.393186 -4.583889
v  16.308718 6.085696 -4.288571
v  16.480801 6.106409 -4.301196
v  16.188366 6.184620 -4.350210
v  16.117744 6.372820 -4.550020
v  16.174664 6.206218 -4.378330
v  16.181433 6.253370 -4.388069
v  16.415600 6.473254 -4.738986
v  16.151108 6.257479 -4.425320
v  16.550503 6.322743 -4.529702
v  16.562069 6.344256 -4.515112
v  16.327398 6.063763 -4.274155
v  16.549116 6.369057 -4.594482
v  16.238478 6.440895 -4.707535
v  16.495558 6.178754 -4.392620
v  16.197813 6.436368 -4.674833
v  16.110413 6.354659 -4.510424
v  16.448881 6.069497 -4.310175
v  16.331745 6.028629 -4.277227
v  16.487331 6.138539 -4.324336
v  16.158962 6.459485 -4.662003
v  16.274509 6.453228 -4.709491
v  16.197842 6.180518 -4.366457
v  16.309649 6.454381 -4.719339
v  16.475466 6.462082 -4.719224
v  16.132017 6.432766 -4.598219
v  16.132736 6.286909 -4.439908
v  16.244642 6.156628 -4.327847
v  16.500975 6.205544 -4.413208
v  16.409817 6.014783 -4.278096
v  16.144365 6.409972 -4.566509
v  16.485699 6.152195 -4.371257
v  16.360455 6.020350 -4.263039
v  16.384481 6.012580 -4.277823
v  16.527685 6.246445 -4.411247
v  16.152857 6.309453 -4.471766
v  16.462496 6.056157 -4.270348
v  16.484701 6.464658 -4.728600
v  16.146042 6.329668 -4.492707
v  16.447950 6.468316 -4.731817
v  16.286924 6.106642 -4.301996
v  16.470236 6.123120 -4.347427
v  16.380518 6.480495 -4.752729
v  16.391464 6.011312 -4.257054
v  16.264212 6.132048 -4.308947
v  16.163965 6.231664 -4.403346
v  16.549513 6.296417 -4.457353
v  16.542259 6.271388 -4.433460
v  16.135128 6.348958 -4.473652
v  16.128735 6.396025 -4.532125
v  16.407896 6.450290 -4.727234
v  16.199934 6.225355 -4.370623
v  16.266356 6.452310 -4.736570
v  16.525827 6.422549 -4.686372
v  16.304106 6.453628 -4.747215
v  16.254967 6.479356 -4.725442
v  16.188026 6.249430 -4.396222
v  16.161955 6.286483 -4.449445
v  16.202829 6.219696 -4.379116
v  16.531412 6.369577 -4.593370
v  16.167614 6.426370 -4.622423
v  16.536530 6.318387 -4.487002
v  16.521418 6.230441 -4.437100
v  16.232117 6.138671 -4.338419
v  16.517586 6.415166 -4.640275
v  16.544836 6.410062 -4.654079
v  16.542395 6.368964 -4.596942
v  16.277769 6.092649 -4.313117
v  16.269663 6.480376 -4.730902
v  16.168016 6.461583 -4.655720
v  16.218260 6.447430 -4.715107
v  16.551001 6.345766 -4.560642
v  16.310610 6.083966 -4.287893
v  16.136225 6.435927 -4.634655
v  16.446705 6.444030 -4.747930
v  16.151739 6.304677 -4.429735
v  16.516367 6.263489 -4.453118
v  16.148811 6.270532 -4.404757
v  16.179176 6.456665 -4.652903
v  16.133936 6.416409 -4.565099
v  16.266863 6.091647 -4.292311
v  16.477329 6.074201 -4.289149
v  16.505568 6.430735 -4.712368
v  16.307377 6.481738 -4.741829
v  16.109715 6.380485 -4.545401
v  16.418491 6.477148 -4.751143
v  16.186533 6.442430 -4.693415
v  16.345243 6.481738 -4.749291
v  16.256834 6.116119 -4.324252
v  16.183405 6.205044 -4.384912
v  16.165678 6.430409 -4.664682
v  16.115314 6.400704 -4.578474
v  16.536337 6.388668 -4.580501
v  16.305590 6.444738 -4.730159
v  16.269625 6.443505 -4.720050
v  16.216629 6.198507 -4.356432
v  16.142710 6.304109 -4.472908
v  16.115952 6.339252 -4.476274
v  16.124256 6.422330 -4.607329
v  16.336294 6.035850 -4.290779
v  16.507408 6.194007 -4.366783
v  16.232624 6.449452 -4.722958
v  16.531839 6.408820 -4.611587
v  16.486641 6.434879 -4.691444
v  16.496037 6.452239 -4.695722
v  16.313431 6.046508 -4.294697
v  16.239889 6.158907 -4.319783
v  16.486416 6.136021 -4.322522
v  16.222260 6.162391 -4.352338
v  16.470015 6.080453 -4.284159
v  16.148085 6.396273 -4.575245
v  16.156544 6.451530 -4.669162
v  16.463730 6.439944 -4.708704
v  16.442822 6.043802 -4.263179
v  16.148964 6.403624 -4.606166
v  16.487810 6.186141 -4.386706
v  16.415382 6.036728 -4.266032
v  16.384481 6.012580 -4.277822
v  16.384481 6.012580 -4.277822
v  16.415382 6.036728 -4.266032
v  16.426245 6.035853 -4.288605
v  16.462496 6.056157 -4.270349
v  16.462496 6.056157 -4.270349
v  16.426245 6.035853 -4.288605
v  16.146042 6.329668 -4.492707
v  16.116545 6.329605 -4.482093
v  16.116545 6.329605 -4.482093
v  16.146042 6.329668 -4.492707
v  16.391464 6.011312 -4.257055
v  16.368586 6.040778 -4.279062
v  16.368586 6.040778 -4.279062
v  16.391464 6.011312 -4.257055
v  16.386343 6.010587 -4.269138
v  16.358255 6.018549 -4.274258
v  16.418888 6.013277 -4.261529
v  16.392721 6.013529 -4.259422
v  16.137283 6.324111 -4.453033
v  16.137083 6.307317 -4.470158
v  16.440884 6.057662 -4.288841
v  16.435617 6.045267 -4.272475
v  16.116545 6.329605 -4.482093
v  16.146042 6.329668 -4.492707
v  15.012360 5.600490 -4.234555
v  15.014004 5.596498 -4.236684
v  15.016499 5.593658 -4.239497
v  15.019541 5.592314 -4.242654
v  15.022764 5.592624 -4.245774
v  15.025782 5.594553 -4.248482
v  15.028227 5.597867 -4.250450
v  15.029806 5.602169 -4.251442
v  15.030329 5.606937 -4.251337
v  15.029732 5.611598 -4.250148
v  15.028087 5.615590 -4.248019
v  15.025593 5.618430 -4.245207
v  15.022551 5.619775 -4.242049
v  15.019326 5.619464 -4.238929
v  15.016310 5.617536 -4.236221
v  15.013864 5.614221 -4.234253
v  15.017683 5.607531 -4.238863
v  15.011763 5.605151 -4.233366
v  15.012285 5.609920 -4.233262
v  15.054403 5.579367 -4.195610
v  15.053452 5.575806 -4.198164
v  15.053770 5.572756 -4.201462
v  15.055320 5.570588 -4.205106
v  15.057916 5.569561 -4.208657
v  15.061242 5.569803 -4.211686
v  15.064899 5.571280 -4.213829
v  15.068446 5.573818 -4.214828
v  15.071454 5.577107 -4.214559
v  15.073561 5.580753 -4.213058
v  15.074512 5.584316 -4.210504
v  15.074195 5.587365 -4.207206
v  15.072644 5.589534 -4.203562
v  15.070048 5.590559 -4.200010
v  15.066723 5.590318 -4.196981
v  15.063065 5.588840 -4.194839
v  15.059518 5.586304 -4.193840
v  15.056509 5.583013 -4.194109
v  14.969173 5.585504 -4.277145
v  14.972302 5.582228 -4.277798
v  14.975903 5.579930 -4.279508
v  14.979542 5.578888 -4.282067
v  14.982780 5.579230 -4.285172
v  14.985226 5.580911 -4.288444
v  14.986586 5.583729 -4.291488
v  14.986694 5.587346 -4.293940
v  14.985540 5.591322 -4.295503
v  14.983261 5.595181 -4.295987
v  14.980133 5.598458 -4.295335
v  14.976532 5.600756 -4.293626
v  14.972894 5.601797 -4.291064
v  14.969656 5.601457 -4.287961
v  14.967211 5.599775 -4.284690
v  14.965850 5.596957 -4.281644
v  14.972640 5.589967 -4.283137
v  14.966896 5.589363 -4.277631
v  14.965741 5.593340 -4.279193
v  15.010137 5.605336 -4.234857
v  15.010773 5.600669 -4.236009
v  15.012452 5.596675 -4.238107
v  15.014971 5.593832 -4.240896
v  15.018024 5.592484 -4.244042
v  15.021246 5.592797 -4.247166
v  15.024246 5.594727 -4.249888
v  15.026664 5.598046 -4.251883
v  15.028206 5.602351 -4.252908
v  15.028688 5.607125 -4.252840
v  15.028051 5.611790 -4.251688
v  15.026373 5.615785 -4.249591
v  15.023853 5.618627 -4.246800
v  15.020800 5.619974 -4.243653
v  15.017578 5.619664 -4.240531
v  15.014578 5.617732 -4.237808
v  15.012160 5.614415 -4.235814
v  15.010618 5.610109 -4.234789
v  14.952113 5.521065 -4.292660
v  14.956224 5.521442 -4.292411
v  14.960446 5.521444 -4.293549
v  14.964271 5.521066 -4.295938
v  14.967236 5.520355 -4.299287
v  14.968984 5.519398 -4.303194
v  14.969304 5.518308 -4.307188
v  14.968158 5.517219 -4.310786
v  14.965684 5.516261 -4.313556
v  14.962179 5.515550 -4.315161
v  14.958069 5.515171 -4.315410
v  14.953847 5.515170 -4.314271
v  14.950023 5.515548 -4.311883
v  14.947058 5.516259 -4.308534
v  14.945310 5.517217 -4.304626
v  14.944989 5.518306 -4.300632
v  14.948610 5.520353 -4.294266
v  14.946136 5.519396 -4.297034
v  14.965712 5.587811 -4.278974
v  14.968061 5.584045 -4.278407
v  14.971247 5.580843 -4.278995
v  14.974882 5.578594 -4.280664
v  14.978530 5.577564 -4.283215
v  14.981749 5.577879 -4.286340
v  14.984152 5.579504 -4.289660
v  14.985448 5.582240 -4.292777
v  14.985481 5.585757 -4.295315
v  14.984249 5.589633 -4.296966
v  14.981897 5.593397 -4.297531
v  14.978712 5.596599 -4.296945
v  14.975077 5.598851 -4.295275
v  14.971430 5.599880 -4.292724
v  14.968210 5.599564 -4.289599
v  14.965808 5.597939 -4.286278
v  14.964512 5.595202 -4.283161
v  14.964478 5.591687 -4.280623
v  14.967621 5.452058 -4.271643
v  14.971179 5.454652 -4.272107
v  14.975000 5.456258 -4.273762
v  14.978624 5.456678 -4.276407
v  14.981614 5.455867 -4.279725
v  14.983609 5.453918 -4.283314
v  14.984369 5.451068 -4.286741
v  14.983801 5.447661 -4.289595
v  14.981974 5.444108 -4.291529
v  14.979109 5.440836 -4.292311
v  14.975552 5.438241 -4.291847
v  14.971729 5.436636 -4.290192
v  14.968105 5.436213 -4.287546
v  14.965116 5.437027 -4.284229
v  14.963120 5.438974 -4.280641
v  14.962361 5.441825 -4.277212
v  14.964756 5.448786 -4.272426
v  14.962929 5.445231 -4.274360
v  14.948706 5.517677 -4.293877
v  14.952203 5.518535 -4.292291
v  14.956309 5.519036 -4.292058
v  14.960528 5.519114 -4.293208
v  14.964353 5.518764 -4.295598
v  14.967319 5.518029 -4.298943
v  14.969072 5.516993 -4.302839
v  14.969398 5.515785 -4.306815
v  14.968259 5.514548 -4.310392
v  14.965792 5.513433 -4.313139
v  14.962295 5.512574 -4.314724
v  14.958189 5.512074 -4.314958
v  14.953970 5.511995 -4.313811
v  14.950146 5.512343 -4.311418
v  14.947179 5.513081 -4.308074
v  14.945426 5.514116 -4.304178
v  14.945100 5.515325 -4.300202
v  14.946239 5.516561 -4.296624
v  14.965018 5.455241 -4.269182
v  15.008959 5.424323 -4.234237
v  15.011135 5.427934 -4.236052
v  15.013954 5.430243 -4.238681
v  15.017078 5.430976 -4.241809
v  15.020127 5.430041 -4.245058
v  15.022737 5.427551 -4.248036
v  15.024590 5.423808 -4.250384
v  15.025463 5.419261 -4.251819
v  15.025251 5.414462 -4.252167
v  15.023981 5.409985 -4.251387
v  15.021805 5.406374 -4.249572
v  15.018986 5.404064 -4.246943
v  15.015862 5.403333 -4.243814
v  15.012812 5.404268 -4.240566
v  15.010203 5.406756 -4.237588
v  15.008350 5.410501 -4.235240
v  15.019840 5.416121 -4.246402
v  15.007689 5.419847 -4.233458
v  15.007477 5.415048 -4.233806
v  14.965862 5.446903 -4.271285
v  14.968679 5.450254 -4.270552
v  14.972197 5.452919 -4.271058
v  14.975988 5.454574 -4.272744
v  14.979597 5.455023 -4.275405
v  14.982589 5.454206 -4.278720
v  14.984603 5.452228 -4.282291
v  14.985394 5.449324 -4.285686
v  14.984869 5.445844 -4.288494
v  14.983090 5.442209 -4.290380
v  14.980272 5.438856 -4.291113
v  14.976756 5.436192 -4.290606
v  14.972964 5.434536 -4.288921
v  14.969355 5.434089 -4.286260
v  14.966363 5.434903 -4.282945
v  14.964351 5.436882 -4.279374
v  14.963558 5.439786 -4.275979
v  14.964084 5.443268 -4.273170
v  15.054359 5.442498 -4.195939
v  15.055031 5.445388 -4.199023
v  15.056830 5.447211 -4.202512
v  15.059543 5.447748 -4.205987
v  15.062840 5.446932 -4.209026
v  15.066325 5.444864 -4.211267
v  15.069577 5.441791 -4.212435
v  15.072204 5.438086 -4.212392
v  15.073889 5.434194 -4.211142
v  15.074428 5.430585 -4.208836
v  15.073757 5.427697 -4.205752
v  15.071957 5.425873 -4.202264
v  15.069244 5.425337 -4.198789
v  15.065947 5.426152 -4.195749
v  15.062462 5.428221 -4.193509
v  15.059210 5.431293 -4.192341
v  15.019507 5.421957 -4.242280
v  15.054899 5.438890 -4.193635
v  15.056583 5.434999 -4.192384
v  15.009367 5.419703 -4.232025
v  15.010588 5.424181 -4.232849
v  15.012720 5.427797 -4.234700
v  15.015511 5.430109 -4.237356
v  15.018620 5.430841 -4.240497
v  15.021673 5.429904 -4.243742
v  15.024302 5.427413 -4.246701
v  15.026192 5.423666 -4.249019
v  15.027111 5.419115 -4.250413
v  15.026952 5.414310 -4.250716
v  15.025732 5.409831 -4.249892
v  15.023598 5.406217 -4.248041
v  15.020808 5.403904 -4.245385
v  15.017699 5.403171 -4.242245
v  15.014646 5.404108 -4.238999
v  15.012016 5.406600 -4.236039
v  15.010127 5.410347 -4.233723
v  15.009207 5.414897 -4.232329
v  15.073462 5.509525 -4.181338
v  15.073072 5.508929 -4.185248
v  15.074152 5.508390 -4.189301
v  15.076571 5.507976 -4.193007
v  15.080035 5.507737 -4.195917
v  15.084128 5.507698 -4.197681
v  15.088356 5.507868 -4.198088
v  15.092209 5.508224 -4.197085
v  15.095222 5.508725 -4.194798
v  15.097033 5.509309 -4.191498
v  15.097422 5.509904 -4.187588
v  15.096342 5.510444 -4.183535
v  15.093924 5.510857 -4.179830
v  15.090460 5.511096 -4.176919
v  15.086367 5.511135 -4.175155
v  15.082138 5.510964 -4.174749
v  15.075272 5.510108 -4.178038
v  15.078285 5.510609 -4.175750
v  15.056268 5.440547 -4.192500
v  15.055637 5.444046 -4.194880
v  15.056232 5.446844 -4.198027
v  15.057981 5.448604 -4.201559
v  15.060671 5.449115 -4.205051
v  15.063980 5.448313 -4.208082
v  15.067509 5.446297 -4.210285
v  15.070829 5.443309 -4.211397
v  15.073544 5.439709 -4.211281
v  15.075323 5.435933 -4.209953
v  15.075953 5.432434 -4.207572
v  15.075357 5.429635 -4.204426
v  15.073609 5.427874 -4.200894
v  15.070919 5.427365 -4.197402
v  15.067610 5.428165 -4.194371
v  15.064081 5.430182 -4.192167
v  15.060760 5.433170 -4.191056
v  15.058046 5.436769 -4.191172
v  15.053238 5.576114 -4.198339
v  15.053566 5.573051 -4.201630
v  15.055122 5.570875 -4.205269
v  15.057720 5.569845 -4.208817
v  15.061047 5.570086 -4.211847
v  15.064701 5.571568 -4.213993
v  15.068241 5.574117 -4.214997
v  15.071239 5.577420 -4.214736
v  15.073336 5.581080 -4.213243
v  15.074277 5.584659 -4.210698
v  15.073950 5.587720 -4.207408
v  15.072393 5.589898 -4.203769
v  15.069795 5.590928 -4.200220
v  15.066468 5.590687 -4.197190
v  15.062815 5.589202 -4.195045
v  15.059275 5.586656 -4.194041
v  15.054180 5.579690 -4.195794
v  15.056276 5.583352 -4.194301
v  15.075166 5.512824 -4.178172
v  15.073360 5.512103 -4.181463
v  15.072976 5.511394 -4.185369
v  15.074059 5.510784 -4.189419
v  15.076478 5.510345 -4.193122
v  15.079941 5.510133 -4.196033
v  15.084032 5.510169 -4.197803
v  15.088255 5.510452 -4.198215
v  15.092103 5.510948 -4.197220
v  15.095110 5.511595 -4.194940
v  15.096915 5.512316 -4.191648
v  15.097300 5.513025 -4.187741
v  15.096217 5.513634 -4.183692
v  15.093799 5.514071 -4.179988
v  15.090336 5.514286 -4.177076
v  15.086245 5.514250 -4.175309
v  15.082022 5.513966 -4.174897
v  15.078174 5.513472 -4.175891
v  15.070525 5.570854 -4.217600
v  15.015842 5.434192 -4.235089
v  15.075612 5.582382 -4.216644
v  14.986707 5.440033 -4.292502
v  15.078421 5.505428 -4.198861
v  14.973117 5.520196 -4.308472
v  15.084054 5.510011 -4.181941
v  14.970488 5.444060 -4.277892
v  15.065135 5.439735 -4.205794
v  15.066491 5.434373 -4.198596
v  15.087133 5.513007 -4.182918
v  14.977297 5.449314 -4.282490
v  15.062717 5.576433 -4.207332
v  14.961689 5.518725 -4.303636
v  14.953970 5.516370 -4.299812
v  15.018709 5.611384 -4.242576
v  15.060658 5.576425 -4.204630
v  14.978499 5.585185 -4.288618
v  14.963427 5.595528 -4.275706
v  14.974809 5.600462 -4.297045
v  15.053347 5.447092 -4.192101
v  15.095482 5.515078 -4.178725
v  15.069869 5.422729 -4.198356
v  15.024613 5.591187 -4.252872
v  14.968592 5.431602 -4.287078
v  14.975319 5.437302 -4.295937
v  15.063625 5.590359 -4.191121
v  15.016208 5.589578 -4.237539
v  15.070969 5.593048 -4.199389
v  15.023096 5.399666 -4.245422
v  15.091509 5.510799 -4.175013
v  15.011992 5.434525 -4.238366
v  15.024450 5.588214 -4.245815
v  14.968646 5.604021 -4.287908
v  15.069519 5.514014 -4.179973
v  15.072263 5.425630 -4.196372
v  14.948594 5.515647 -4.313547
v  15.077347 5.579862 -4.215199
v  14.941088 5.520690 -4.299891
v  14.963718 5.599391 -4.286252
v  15.018348 5.400082 -4.249482
v  14.953066 5.508365 -4.316666
v  15.007721 5.414152 -4.228314
v  14.982524 5.602445 -4.296800
v  14.961071 5.436707 -4.273450
v  15.076379 5.427308 -4.211902
v  14.960024 5.437639 -4.287280
v  15.023895 5.622680 -4.248988
v  14.972912 5.515657 -4.302329
v  15.080355 5.432132 -4.208605
v  15.057019 5.589833 -4.191421
v  15.078261 5.588448 -4.207425
v  15.023948 5.433923 -4.243507
v  15.100819 5.506884 -4.193020
v  15.016172 5.620992 -4.233031
v  15.015622 5.624044 -4.240741
v  15.011505 5.403218 -4.233127
v  15.031664 5.618431 -4.248584
v  14.965066 5.581446 -4.276278
v  15.080900 5.516893 -4.171350
v  15.020132 5.434257 -4.246755
v  14.944122 5.509787 -4.309113
v  15.076126 5.508761 -4.172287
v  14.967796 5.585021 -4.273185
v  14.986183 5.588526 -4.300941
v  14.966690 5.518080 -4.292809
v  14.964019 5.509315 -4.317900
v  15.029481 5.613671 -4.255373
v  15.010805 5.610970 -4.229196
v  15.003284 5.414532 -4.232100
v  15.031060 5.413374 -4.252552
v  15.054922 5.582789 -4.190219
v  15.289631 6.208822 -4.710825
v  14.779684 6.524153 -4.980929
v  15.022441 5.092280 -5.036642
v  14.196114 6.153042 -4.977018
v  14.397015 5.079523 -4.692202
v  15.379458 5.441308 -4.772593
v  14.828939 7.030744 -4.867842
v  14.742353 6.679555 -4.484948
v  15.151834 5.962450 -4.963491
v  14.400363 6.039943 -4.724201
v  14.247839 5.393754 -4.771286
v  14.708906 6.974865 -4.615941
v  14.882161 6.021611 -4.474735
v  14.757120 4.922091 -4.571844
v  14.919413 6.012572 -5.154711
v  14.863923 6.506536 -4.762096
v  14.556513 6.795098 -4.824358
v  14.736038 5.628750 -4.021439
v  14.821906 5.753551 -4.065867
v  15.283098 5.652451 -4.647614
v  14.999640 5.429887 -4.175193
v  14.232735 6.314684 -4.837294
v  14.283930 5.684808 -4.611031
v  14.634793 6.554668 -4.654538
v  14.965340 6.764322 -4.899657
v  15.356083 6.130208 -4.918770
v  14.825775 5.586453 -4.162352
v  14.700085 5.509354 -4.128130
v  15.103437 5.910841 -4.707671
v  14.890472 5.436595 -5.003025
v  15.206462 5.430223 -4.787712
v  14.782684 5.397358 -4.046709
v  14.638695 6.495917 -4.923601
v  14.527006 5.972379 -4.500704
v  14.781738 5.612449 -4.128703
v  15.117228 5.079525 -4.692198
v  14.394148 5.382968 -4.657178
v  14.806572 6.498825 -4.566948
v  14.842782 6.358529 -4.722837
v  14.389824 5.959638 -4.951031
v  14.767258 5.748857 -4.213213
v  14.801174 5.577187 -4.483081
v  14.469638 5.459137 -4.902422
v  14.163548 5.539848 -4.720989
v  14.861195 5.592739 -4.070341
v  14.896070 6.354450 -5.074137
v  14.656733 6.995130 -4.907547
v  14.982464 5.567334 -4.286583
v  14.674232 5.607949 -4.195883
v  14.158020 6.199583 -4.808965
v  14.534643 6.006999 -5.145005
v  14.666885 5.480139 -5.010634
v  14.703213 5.574376 -4.134459
v  14.811403 5.390965 -4.138648
v  14.350597 6.131578 -4.634233
v  14.805095 6.975231 -4.613795
v  15.274434 5.456698 -4.453220
v  14.741011 6.470494 -4.687195
v  14.368399 5.528250 -4.708792
v  14.642731 5.722698 -4.161739
v  14.599775 6.630453 -4.811449
v  15.180324 6.328369 -4.763649
v  14.641352 6.334696 -4.709624
v  14.491798 5.092279 -5.036640
v  15.109995 5.957656 -4.807627
v  15.227488 6.270833 -5.017346
v  14.757095 6.795197 -4.535355
v  14.439028 5.745108 -4.794397
v  14.715475 5.409436 -4.139717
v  14.954849 5.543754 -4.123411
v  15.033917 5.982578 -4.530153
v  14.730049 5.773616 -4.159662
v  14.835476 5.743403 -4.085701
v  14.537063 5.592246 -4.547029
v  15.116688 5.802153 -4.883093
v  14.633877 5.562096 -4.247801
v  14.729572 5.779407 -4.039244
v  15.191725 6.089660 -4.673878
v  14.855989 5.651944 -4.068676
v  15.048090 5.500784 -4.902973
v  14.885341 6.696115 -4.616318
v  14.631273 6.680513 -4.608902
v  14.727070 5.410204 -4.125728
v  14.241393 6.256310 -4.728320
v  14.751207 5.575586 -4.053350
v  14.646626 6.312977 -5.104106
v  15.410755 5.608227 -4.760956
v  14.923701 6.635979 -4.807528
v  14.861796 6.485066 -4.957291
v  15.238281 5.982282 -4.979616
v  14.930099 5.370514 -4.534080
v  15.197995 5.592759 -4.695187
v  14.307222 5.494648 -4.854923
v  14.591774 6.754483 -4.895858
v  14.769768 5.466043 -4.082750
v  14.294492 5.973721 -4.973881
v  14.423121 5.941209 -4.848913
v  14.387873 6.342830 -5.001160
v  14.437370 5.883676 -4.662348
v  14.865575 6.934340 -4.950204
v  14.636559 6.081179 -4.487380
v  14.730314 5.610750 -4.215419
v  15.088545 5.473921 -4.763676
v  14.630727 5.304386 -5.019595
v  15.274028 6.056989 -4.704567
v  14.323192 6.103226 -5.065233
v  14.851642 5.774195 -4.153766
v  14.380936 5.420113 -4.759607
v  14.678085 7.020209 -4.709879
v  14.909293 6.186633 -4.547846
v  15.036614 6.002248 -5.117450
v  15.005774 5.450508 -4.283245
v  14.699492 5.751465 -4.267075
v  14.732185 6.828110 -5.014594
v  14.813305 6.627498 -4.532641
v  14.596460 5.608188 -4.238766
v  14.814039 5.546430 -4.210849
v  14.789953 5.619315 -4.059763
v  15.263666 5.527015 -4.880039
v  14.351748 5.677586 -4.595329
v  14.806595 5.788528 -4.112530
v  14.747101 5.732867 -4.193689
v  14.730490 6.976814 -4.960769
v  15.196555 5.653752 -4.526193
v  14.510553 5.028419 -4.601188
v  14.796980 5.651273 -4.137629
v  14.733133 5.638956 -4.088247
v  14.222599 5.448565 -4.619678
v  14.229488 5.584799 -4.658276
v  15.298294 5.548214 -4.478316
v  15.165827 5.885595 -4.670130
v  14.795441 5.368824 -4.102003
v  14.927913 5.819479 -4.529003
v  14.759666 5.742055 -4.015282
v  14.205680 6.017466 -4.724293
v  14.731271 7.050458 -4.801418
v  14.643173 6.530879 -4.753210
v  15.108805 6.338587 -5.010797
v  14.776655 5.728858 -4.138535
v  14.727184 5.676977 -4.098049
v  14.803911 5.568029 -4.051748
v  14.493107 5.652295 -4.587873
v  14.644032 5.088707 -5.093378
v  15.261546 5.380116 -4.674175
v  14.775480 5.677212 -4.001202
v  14.959867 6.798561 -4.847780
v  14.687332 5.600745 -4.164021
v  14.886276 6.504886 -4.867297
v  14.723589 6.498838 -4.551333
v  14.907055 6.950829 -4.705815
v  14.883510 5.304399 -5.019598
v  14.768913 5.509669 -4.188295
v  14.830601 5.501672 -4.143616
v  15.322233 6.281214 -4.821133
v  14.628911 5.861932 -4.510185
v  14.390979 5.773602 -4.847126
v  14.334744 5.596410 -4.460877
v  14.602076 6.885865 -4.914754
v  14.758319 5.458557 -4.034372
v  15.317286 6.260782 -4.936760
v  14.710020 5.520831 -4.140907
v  14.816114 5.555992 -4.155067
v  15.198936 5.764527 -4.957824
v  14.162547 5.662772 -4.817601
v  14.433836 5.836536 -5.022322
v  15.051320 5.051724 -4.620955
v  14.713234 5.463745 -4.123776
v  14.842841 5.439622 -4.134780
v  14.449642 5.414346 -4.664088
v  14.848835 6.832772 -4.576044
v  14.664354 5.105244 -4.537385
v  15.046858 5.570189 -4.624472
v  14.671789 5.485610 -4.498594
v  14.396603 6.323046 -4.729872
v  14.607182 6.950828 -4.705815
v  14.754657 5.361648 -4.140832
v  15.335896 5.701303 -4.646233
v  15.115969 5.874803 -4.967861
v  14.384953 5.426645 -4.489080
v  14.732298 6.593419 -4.507357
v  14.829578 5.581213 -4.137167
v  14.711452 5.463531 -4.130802
v  14.761581 5.411491 -4.179683
v  14.823353 5.698037 -4.063938
v  14.783768 5.464235 -4.167924
v  14.350410 6.222223 -5.068468
v  14.838799 5.642307 -4.091748
v  14.549509 6.417479 -4.928730
v  15.246061 6.075146 -5.028074
v  14.630436 6.833409 -4.607735
v  14.806168 6.413449 -4.722290
v  14.763968 5.628157 -4.071215
v  15.358626 6.007227 -4.833380
v  14.392509 5.685710 -4.897748
v  14.598392 5.705377 -4.311883
v  14.372671 5.966935 -4.690149
v  14.741692 5.522312 -4.063980
v  14.843171 5.738144 -4.140192
v  14.999105 6.394041 -4.912288
v  14.778171 5.631666 -4.195749
v  14.395971 5.697812 -4.669202
v  15.229319 5.393062 -4.487798
v  14.841933 6.554370 -4.942832
v  14.742609 5.809711 -4.178860
v  14.886117 6.219701 -5.138208
v  14.757136 6.066563 -4.498145
v  15.109496 5.606533 -4.575565
v  15.391697 5.458261 -4.692639
v  14.548901 6.764330 -4.899671
v  14.767693 5.744626 -4.065224
v  14.907069 5.587127 -4.139482
v  14.841040 5.717438 -4.070338
v  14.429053 5.403708 -4.814472
v  14.184605 5.978343 -4.880446
v  15.244940 5.859128 -4.980166
v  14.771498 5.673578 -4.046997
v  15.270390 6.324610 -4.832728
v  14.961536 5.443563 -4.113574
v  14.684961 5.641457 -4.337336
v  15.398015 5.673670 -4.827925
v  14.782240 6.653315 -4.527092
v  14.945860 5.611128 -5.015256
v  14.704178 6.902486 -4.994534
v  15.188770 5.477675 -4.782712
v  14.767774 5.680151 -4.136006
v  14.782138 5.800630 -4.073593
v  14.896414 6.564337 -4.713694
v  15.160133 6.017621 -4.733434
v  14.757118 5.874989 -5.104777
v  14.836307 5.469732 -4.112742
v  14.809072 7.037375 -4.736039
v  14.224304 6.202199 -4.710235
v  15.126434 5.859986 -4.711184
v  15.316540 5.609862 -4.680506
v  14.713903 6.672748 -4.519169
v  14.595519 5.812366 -5.104079
v  14.628929 5.962136 -4.475596
v  15.043144 5.859860 -4.622038
v  14.476974 5.466698 -4.649313
v  14.806973 5.494637 -4.025303
v  14.572022 6.234053 -4.591139
v  14.224318 5.633839 -4.889433
v  14.791273 5.615767 -4.015823
v  14.382457 5.597706 -4.688542
v  14.766149 5.519726 -4.075155
v  14.536484 5.298825 -4.559950
v  14.574444 6.641003 -4.838211
v  14.176371 6.007604 -4.773521
v  14.567595 6.169555 -5.141832
v  14.849881 5.105244 -4.537387
v  14.978170 5.476048 -4.279006
v  14.506866 5.841557 -4.584112
v  14.922466 6.754486 -4.895863
v  14.826270 5.503582 -4.160152
v  14.297688 5.852182 -4.973114
v  15.241071 5.630562 -4.503111
v  14.779658 5.408318 -4.090195
v  14.980701 5.587248 -4.261169
v  14.769802 5.469038 -4.184871
v  14.870891 6.782526 -4.616535
v  14.647402 6.450350 -4.986828
v  15.323289 6.026347 -4.728587
v  14.231555 6.294161 -4.940349
v  14.671734 6.418542 -4.743655
v  14.713577 5.571020 -4.174915
v  14.974631 5.805826 -5.088415
v  14.870205 5.088706 -5.093376
v  14.791779 5.814816 -4.158056
v  14.934890 6.910408 -4.816384
v  14.817992 5.768202 -4.092600
v  14.178330 5.549225 -4.677962
v  14.876221 6.990642 -4.889051
v  14.811645 5.560545 -4.133188
v  14.774361 6.492496 -4.551024
v  14.254976 5.598824 -4.570385
v  14.592125 5.534349 -4.400841
v  14.283213 6.339736 -4.894623
v  14.755336 5.719289 -4.181067
v  14.976428 5.639582 -4.549925
v  14.803895 6.672213 -4.551385
v  15.126413 5.959088 -4.999346
v  14.757109 5.249602 -5.037935
v  15.080441 5.836529 -5.022349
v  14.782051 6.828110 -5.014594
v  14.612297 6.447646 -4.848215
v  14.912162 6.885860 -4.914754
v  14.410109 6.046515 -5.079802
v  14.609266 5.696009 -4.377955
v  14.757118 6.490650 -4.652367
v  14.716033 6.760700 -4.562691
v  14.790307 5.633557 -4.098940
v  14.827274 5.482814 -4.138030
v  15.320179 5.975150 -4.927491
v  14.724472 5.741132 -4.179677
v  14.776419 5.585825 -4.098557
v  14.594825 6.012573 -5.154730
v  14.884938 6.284863 -4.653345
v  14.386431 5.091804 -4.799839
v  14.580744 6.901948 -4.756750
v  14.762627 5.668636 -4.115232
v  14.857515 6.962670 -4.639038
v  14.856070 5.750693 -4.092661
v  14.375825 5.892054 -4.662233
v  14.869017 5.616755 -4.094804
v  14.740950 6.412486 -4.712886
v  14.463043 5.422429 -4.537607
v  14.844736 5.353804 -4.500787
v  14.335568 5.630434 -4.634280
v  14.819603 5.410270 -4.153609
v  15.130781 5.440149 -4.668643
v  15.118937 5.934309 -4.859444
v  14.757300 5.578475 -4.045019
v  14.715555 5.374209 -4.137998
v  14.697773 5.421920 -4.102512
v  14.369280 5.517407 -4.425066
v  15.127807 5.091808 -4.799835
v  14.434813 5.940256 -5.025554
v  14.649574 6.821485 -4.972596
v  14.905036 6.628243 -4.832580
v  15.266195 6.260734 -4.725329
v  14.757118 7.018652 -4.660880
v  14.412866 5.697334 -4.535258
v  14.271668 6.143682 -4.672938
v  14.628206 6.561094 -4.853085
v  14.336266 6.272788 -4.689950
v  15.110973 6.017749 -4.599245
v  14.788273 5.792435 -4.132242
v  14.757118 5.528013 -5.001743
v  14.766094 5.804029 -4.210516
v  15.021456 6.096801 -4.536159
v  14.306704 5.391985 -4.618818
v  14.374456 6.025179 -4.725719
v  14.705993 5.426725 -4.145100
v  14.565096 5.531022 -4.337519
v  14.864662 6.821485 -4.972595
v  14.499274 5.556901 -4.581482
v  15.110256 5.406763 -4.543284
v  15.126219 5.824825 -4.844440
v  15.354622 6.216311 -4.809710
v  14.805813 5.591817 -4.153912
v  14.971149 6.797007 -4.837880
v  15.175967 5.649168 -4.690436
v  14.493263 5.553372 -4.944116
v  14.541910 5.306454 -4.996694
v  15.057484 5.547699 -4.207453
v  14.253096 6.052547 -4.700340
v  14.901939 6.447645 -4.848214
v  14.847738 5.667166 -4.031519
v  14.792098 5.527595 -4.152014
v  15.216155 6.339891 -4.933123
v  15.212799 6.044429 -4.703135
v  14.163761 6.111876 -4.916011
v  14.803047 5.753319 -4.081221
v  14.726089 5.510672 -4.118032
v  14.666575 6.925970 -4.609041
v  14.764366 5.390221 -4.119773
v  14.775354 5.353712 -4.081958
v  14.783661 6.942521 -4.984916
v  14.694645 5.532108 -4.123184
v  14.352192 5.841315 -4.965755
v  14.249988 5.426988 -4.807159
v  14.757118 6.617593 -4.973893
v  14.729736 5.702944 -4.132826
v  14.540584 6.799755 -4.867707
v  14.512149 6.310270 -5.081996
v  14.987247 5.972364 -4.500755
v  14.698481 6.835267 -4.555201
v  14.763624 5.492862 -4.025507
v  14.685297 7.030744 -4.867842
v  14.655294 6.643682 -4.925869
v  14.770817 6.700891 -4.491284
v  14.692774 5.754353 -4.127478
v  14.715935 5.557254 -4.164110
v  14.898272 6.437799 -4.976314
v  15.331047 5.421829 -4.793582
v  15.319103 6.174423 -4.979100
v  14.413671 5.967389 -4.800814
v  14.592079 6.361991 -4.764914
v  14.713307 5.392916 -4.488511
v  14.712202 5.469977 -4.157762
v  14.858942 6.643681 -4.925871
v  14.476070 5.522727 -4.654690
v  15.155045 6.237803 -5.065468
v  14.577290 5.665133 -4.276635
v  14.181086 5.616028 -4.665014
v  14.980659 6.006783 -5.145647
v  14.602529 6.708269 -4.663599
v  14.441437 6.002014 -4.563761
v  15.309194 5.540266 -4.876999
v  15.126284 5.638971 -4.516951
v  14.381596 5.495559 -4.730289
v  14.702509 6.712582 -4.535680
v  14.765335 5.566210 -4.079768
v  14.680127 6.505488 -4.700789
v  14.417965 5.869416 -4.702590
v  14.757108 6.070950 -5.134228
v  14.613926 6.981550 -4.835663
v  14.974534 5.539351 -4.291678
v  14.771029 5.472466 -4.154955
v  14.725006 5.594193 -4.222370
v  14.672302 6.554372 -4.942832
v  14.750570 5.756407 -4.068180
v  14.779115 5.690000 -4.107733
v  14.697838 6.617796 -4.528717
v  14.393034 6.052051 -4.673817
v  15.227899 5.632248 -4.666571
v  15.357428 5.595531 -4.677261
v  15.014469 5.304703 -4.971728
v  14.757093 5.086583 -5.063275
v  15.149609 5.757515 -4.927349
v  14.830256 5.379725 -4.059075
v  14.222187 5.692633 -4.622680
v  14.581799 5.434607 -4.987466
v  15.403662 5.540103 -4.696660
v  15.346102 5.585721 -4.592228
v  14.690041 5.623528 -4.173030
v  14.801720 5.651871 -3.991709
v  14.822338 5.628200 -4.089796
v  14.845258 5.467632 -4.127252
v  15.352875 6.019721 -4.777684
v  14.586154 5.820137 -4.529085
v  14.951558 5.432364 -4.210664
v  14.247692 6.195602 -5.022256
v  15.210288 5.546267 -4.732212
v  14.830628 5.775449 -4.091978
v  14.949811 6.715128 -4.888224
v  14.750706 5.616498 -4.093451
v  14.432568 5.808665 -4.873860
v  15.124200 5.959483 -4.809113
v  14.183935 6.266077 -4.823379
v  15.381151 5.632282 -4.687556
v  14.897260 6.829293 -4.637052
v  15.120915 5.458306 -4.278615
v  14.885254 5.861841 -4.510445
v  14.939472 6.765891 -4.774447
v  14.546927 5.757273 -5.073444
v  14.757120 6.205617 -4.580639
v  14.398348 5.665020 -4.626676
v  14.774602 5.762226 -4.175805
v  14.578824 6.642746 -4.808307
v  14.774031 6.504863 -4.540503
v  14.539511 6.089649 -4.515204
v  14.786737 5.375361 -4.163541
v  14.783529 5.747927 -4.053737
v  14.457993 5.418240 -4.636281
v  14.802938 5.749463 -4.153338
v  15.080323 5.432570 -4.303847
v  14.714302 5.343777 -5.007890
v  14.189548 6.239656 -4.938126
v  14.740937 5.748229 -4.158731
v  14.834644 5.592735 -4.086258
v  14.900311 6.981549 -4.835663
v  15.327123 5.396600 -4.743709
v  15.346411 6.193207 -4.930597
v  14.757117 6.390699 -5.060840
v  14.757135 6.008862 -4.490827
v  14.347528 5.404203 -4.767904
v  14.967464 5.462183 -4.558856
v  15.284492 5.958949 -4.675417
v  14.834105 6.505485 -4.700787
v  14.221059 5.617574 -4.637800
v  15.066220 5.488112 -4.193409
v  14.849602 5.669134 -4.096453
v  14.772574 6.624759 -4.504305
v  14.703750 5.746469 -4.044020
v  14.794073 5.422920 -4.152196
v  14.799426 6.717687 -4.539134
v  14.607033 5.634919 -4.434900
v  14.867651 5.579773 -4.241433
v  14.826458 5.648501 -4.067943
v  14.780675 5.419351 -4.150188
v  15.294050 5.878732 -4.961630
v  15.187551 5.499247 -4.750725
v  14.186377 5.648045 -4.860178
v  14.894310 6.360988 -4.747246
v  14.859142 5.612146 -4.052421
v  15.210785 6.347473 -4.843760
v  15.371857 5.419182 -4.717472
v  14.545986 5.461489 -4.557169
v  14.942135 6.233611 -4.591019
v  14.208032 5.423029 -4.742739
v  14.811230 5.752077 -4.096987
v  15.060188 5.495440 -4.670779
v  14.815746 6.835252 -4.555303
v  14.757118 4.975009 -4.555102
v  14.703099 5.556672 -4.081221
v  15.093226 6.303484 -4.702386
v  14.573179 6.746714 -4.787594
v  14.757118 5.145769 -4.517395
v  14.679214 5.586293 -4.308228
v  14.423999 5.068409 -4.651411
v  14.850298 5.618174 -5.033955
v  14.974333 6.382772 -4.841387
v  14.341774 5.964078 -4.971101
v  14.621927 6.459648 -4.947796
v  15.225709 5.906576 -4.654693
v  15.072235 5.492089 -4.861692
v  14.835161 7.006916 -4.676957
v  14.148457 6.126970 -4.858276
v  15.194525 5.970613 -4.979544
v  14.578509 6.878588 -4.859314
v  14.787995 5.620872 -4.100333
v  15.114722 6.003361 -4.707063
v  14.564428 6.715127 -4.888217
v  14.668750 6.169519 -5.146936
v  15.046336 5.585730 -4.257736
v  14.316315 6.011667 -4.691219
v  14.281919 5.637090 -4.591221
v  14.784724 5.397737 -4.192972
v  14.709977 5.466545 -4.093285
v  14.365681 6.064303 -4.695202
v  14.416103 5.941154 -4.903898
v  14.617827 6.564339 -4.713695
v  14.278791 6.060590 -5.023096
v  15.299181 6.275404 -4.766732
v  14.773214 5.796037 -4.089600
v  14.869002 5.488523 -4.198130
v  14.400009 6.363225 -4.905770
v  14.851974 5.614332 -4.190444
v  14.486553 5.930182 -4.546144
v  14.719954 5.693254 -4.097271
v  14.770628 5.640119 -4.135097
v  15.174629 5.399369 -4.700147
v  14.339861 5.689741 -4.921555
v  15.098475 5.939862 -5.037313
v  14.776373 5.556870 -4.174541
v  15.127336 5.937807 -4.916165
v  14.771407 5.510506 -4.153341
v  14.789025 5.682329 -4.027928
v  14.757118 6.262489 -5.116070
v  15.136194 5.685257 -4.857872
v  14.592602 5.578525 -4.257927
v  14.734273 6.653242 -4.516253
v  14.837911 5.737633 -4.067913
v  14.787862 5.746396 -4.089643
v  14.933494 6.901948 -4.756751
v  14.811516 5.554760 -4.182044
v  14.713138 5.720793 -4.488716
v  15.175895 6.115041 -4.638920
v  14.764841 5.734248 -4.122726
v  15.280776 5.412936 -4.506927
v  14.188911 5.871681 -4.685070
v  14.749849 5.463178 -4.072237
v  14.437490 5.536320 -4.866089
v  14.805156 5.624876 -4.134377
v  14.934962 5.518782 -4.263389
v  14.663939 5.618177 -5.033950
v  14.877692 6.081192 -4.487385
v  15.238783 5.622394 -4.674398
v  14.740501 5.647626 -4.074903
v  14.482796 6.000896 -5.116640
v  14.648661 6.934339 -4.950204
v  14.573479 6.810667 -4.852330
v  14.432910 5.424319 -4.711492
v  14.615207 6.419027 -4.807429
v  14.392019 5.826040 -4.904523
v  14.323442 6.329255 -4.769713
v  14.185235 6.203890 -4.753060
v  14.419413 5.977311 -4.619511
v  14.158325 5.645378 -4.709138
v  14.788488 5.573419 -4.123816
v  14.773224 5.556897 -4.035614
v  14.405787 5.947492 -4.753223
v  14.770471 5.426528 -4.029626
v  14.800489 5.475157 -4.133677
v  14.918717 5.812366 -5.104072
v  14.758584 5.707552 -3.999025
v  14.640398 6.767990 -4.614058
v  14.773310 6.561636 -4.527869
v  14.715891 5.517036 -4.116192
v  14.707911 5.569848 -4.197312
v  14.388871 5.463546 -4.756750
v  14.171448 5.993721 -4.829133
v  14.838730 6.900033 -4.981606
v  15.270867 6.310456 -4.936089
v  14.731497 5.544843 -4.121782
v  14.888940 5.382593 -4.134157
v  14.776337 5.518348 -4.099577
v  14.600895 6.647836 -4.848336
v  14.778938 5.739173 -4.167597
v  14.938091 6.637175 -4.819807
v  14.741685 5.768267 -4.022310
v  15.404687 5.622324 -4.721710
v  14.815145 5.564524 -4.129847
v  14.836672 6.605927 -4.560750
v  14.335716 6.097159 -4.668582
v  14.813791 5.430487 -4.031065
v  15.261728 6.218049 -5.022257
v  14.816244 5.587619 -4.112127
v  14.833394 5.482164 -4.152183
v  14.838339 6.524256 -4.589009
v  14.617557 5.734364 -4.305668
v  14.616426 5.729908 -4.307094
v  14.614539 5.726369 -4.309567
v  14.612122 5.724178 -4.312790
v  14.609467 5.723596 -4.316372
v  14.606893 5.724691 -4.319883
v  14.604712 5.727335 -4.322899
v  14.603187 5.731209 -4.325056
v  14.602500 5.735844 -4.326094
v  14.602736 5.740681 -4.325886
v  14.603867 5.745136 -4.324460
v  14.605754 5.748673 -4.321987
v  14.608171 5.750865 -4.318764
v  14.610826 5.751449 -4.315182
v  14.613400 5.750352 -4.311670
v  14.615582 5.747708 -4.308655
v  14.612818 5.739944 -4.312216
v  14.660619 5.719726 -4.350366
v  14.617793 5.739201 -4.305460
v  14.617106 5.743834 -4.306498
v  14.665039 5.717546 -4.340209
v  14.663064 5.713656 -4.339170
v  14.660445 5.710011 -4.339531
v  14.657497 5.707054 -4.341247
v  14.654577 5.705137 -4.344110
v  14.652037 5.704494 -4.347777
v  14.650183 5.705203 -4.351802
v  14.649238 5.707178 -4.355703
v  14.649317 5.710181 -4.359009
v  14.650410 5.713848 -4.361320
v  14.652385 5.717739 -4.362357
v  14.655004 5.721383 -4.361998
v  14.657951 5.724343 -4.360282
v  14.660872 5.726258 -4.357418
v  14.663412 5.726901 -4.353753
v  14.665266 5.726192 -4.349726
v  14.666211 5.724217 -4.345825
v  14.666132 5.721215 -4.342520
v  14.610451 5.719130 -4.317547
v  14.572875 5.715290 -4.266383
v  14.573257 5.711687 -4.269180
v  14.572498 5.708795 -4.272677
v  14.570688 5.706964 -4.276452
v  14.568046 5.706412 -4.280048
v  14.564890 5.707207 -4.283036
v  14.561601 5.709254 -4.285050
v  14.558576 5.712305 -4.285851
v  14.556179 5.715991 -4.285342
v  14.554701 5.719870 -4.283583
v  14.554317 5.723472 -4.280787
v  14.555078 5.726364 -4.277289
v  14.556888 5.728196 -4.273515
v  14.559530 5.728748 -4.269917
v  14.562686 5.727952 -4.266932
v  14.565975 5.725904 -4.264916
v  14.566708 5.718189 -4.271008
v  14.605494 5.736914 -4.318320
v  14.571396 5.719168 -4.264625
v  14.569000 5.722855 -4.264114
v  14.616086 5.739235 -4.304053
v  14.615891 5.734399 -4.304294
v  14.614798 5.729942 -4.305750
v  14.612936 5.726404 -4.308245
v  14.610531 5.724212 -4.311477
v  14.607872 5.723628 -4.315058
v  14.605282 5.724726 -4.318555
v  14.603071 5.727370 -4.321546
v  14.601507 5.731244 -4.323670
v  14.600779 5.735879 -4.324673
v  14.600973 5.740716 -4.324431
v  14.602067 5.745173 -4.322976
v  14.603929 5.748712 -4.320481
v  14.606334 5.750904 -4.317247
v  14.608992 5.751488 -4.313668
v  14.611583 5.750390 -4.310171
v  14.613793 5.747745 -4.307179
v  14.615357 5.743872 -4.305055
v  14.603064 5.720658 -4.318871
v  14.567049 5.650822 -4.244153
v  14.567783 5.650878 -4.248222
v  14.567251 5.650288 -4.252522
v  14.565516 5.649122 -4.256535
v  14.562785 5.647519 -4.259774
v  14.559390 5.645675 -4.261851
v  14.555739 5.643810 -4.262515
v  14.552275 5.642150 -4.261683
v  14.549412 5.640899 -4.259461
v  14.547499 5.640202 -4.256113
v  14.546763 5.640143 -4.252044
v  14.547296 5.640736 -4.247744
v  14.549032 5.641901 -4.243731
v  14.551762 5.643504 -4.240493
v  14.555157 5.645349 -4.238416
v  14.558807 5.647213 -4.237752
v  14.561307 5.647572 -4.249400
v  14.566248 5.713670 -4.271585
v  14.565134 5.650124 -4.240806
v  14.562272 5.648871 -4.238582
v  14.570206 5.717495 -4.263438
v  14.571756 5.713718 -4.265269
v  14.572196 5.710196 -4.268123
v  14.571472 5.707355 -4.271654
v  14.569671 5.705535 -4.275437
v  14.567009 5.704957 -4.279018
v  14.563809 5.705693 -4.281960
v  14.560457 5.707650 -4.283912
v  14.557357 5.710594 -4.284636
v  14.554881 5.714170 -4.284049
v  14.553330 5.717947 -4.282217
v  14.552891 5.721468 -4.279364
v  14.553616 5.724310 -4.275832
v  14.555416 5.726130 -4.272049
v  14.558077 5.726708 -4.268470
v  14.561277 5.725974 -4.265527
v  14.564630 5.724017 -4.263576
v  14.567730 5.721073 -4.262850
v  14.602076 5.586082 -4.249254
v  14.601642 5.588216 -4.253110
v  14.600263 5.589128 -4.257256
v  14.598104 5.588711 -4.261190
v  14.595428 5.587014 -4.264439
v  14.592554 5.584240 -4.266611
v  14.589832 5.580727 -4.267444
v  14.587591 5.576897 -4.266837
v  14.586098 5.573211 -4.264864
v  14.585534 5.570115 -4.261763
v  14.585968 5.567982 -4.257907
v  14.587348 5.567069 -4.253762
v  14.589506 5.567486 -4.249827
v  14.592183 5.569184 -4.246578
v  14.595056 5.571956 -4.244407
v  14.597777 5.575469 -4.243573
v  14.596813 5.581981 -4.254588
v  14.601513 5.582985 -4.246154
v  14.600020 5.579299 -4.244180
v  14.566010 5.647575 -4.240554
v  14.567876 5.648413 -4.243915
v  14.568573 5.648586 -4.247993
v  14.568016 5.648069 -4.252302
v  14.566272 5.646931 -4.256317
v  14.563551 5.645305 -4.259555
v  14.560183 5.643385 -4.261626
v  14.556572 5.641407 -4.262280
v  14.553154 5.639608 -4.261438
v  14.550343 5.638206 -4.259202
v  14.548475 5.637368 -4.255841
v  14.547778 5.637195 -4.251760
v  14.548335 5.637710 -4.247455
v  14.550079 5.638850 -4.243440
v  14.552799 5.640477 -4.240201
v  14.556169 5.642395 -4.238130
v  14.559779 5.644372 -4.237475
v  14.563197 5.646172 -4.238317
v  14.649155 5.562570 -4.282541
v  14.647033 5.565556 -4.285306
v  14.644440 5.567059 -4.288656
v  14.641687 5.566895 -4.292188
v  14.639108 5.565084 -4.295475
v  14.637012 5.561847 -4.298120
v  14.635653 5.557571 -4.299805
v  14.635194 5.552777 -4.300326
v  14.635691 5.548036 -4.299621
v  14.637084 5.543925 -4.297774
v  14.639205 5.540938 -4.295009
v  14.641798 5.539437 -4.291659
v  14.644550 5.539599 -4.288127
v  14.647130 5.541409 -4.284841
v  14.649226 5.544648 -4.282196
v  14.650585 5.548923 -4.280510
v  14.640268 5.551248 -4.293789
v  14.592457 5.574495 -4.259851
v  14.650547 5.558458 -4.280694
v  14.651045 5.553719 -4.279989
v  14.603113 5.581268 -4.246901
v  14.603608 5.584438 -4.249969
v  14.603114 5.586635 -4.253798
v  14.601691 5.587594 -4.257923
v  14.599512 5.587200 -4.261847
v  14.596837 5.585500 -4.265098
v  14.593990 5.582699 -4.267282
v  14.591315 5.579135 -4.268137
v  14.589135 5.575239 -4.267559
v  14.587711 5.571479 -4.265619
v  14.587216 5.568307 -4.262550
v  14.587709 5.566112 -4.258721
v  14.589132 5.565153 -4.254597
v  14.591312 5.565547 -4.250670
v  14.593987 5.567246 -4.247421
v  14.596833 5.570046 -4.245237
v  14.599508 5.573611 -4.244383
v  14.601689 5.577507 -4.244961
v  14.636696 5.568878 -4.296151
v  14.689381 5.583735 -4.324874
v  14.685948 5.585905 -4.326221
v  14.682467 5.586854 -4.328612
v  14.679356 5.586470 -4.331757
v  14.676991 5.584795 -4.335277
v  14.675659 5.582035 -4.338747
v  14.675518 5.578521 -4.341749
v  14.676587 5.574677 -4.343921
v  14.678736 5.570968 -4.345000
v  14.681705 5.567840 -4.344857
v  14.685139 5.565670 -4.343510
v  14.688621 5.564721 -4.341118
v  14.691730 5.565105 -4.337974
v  14.694096 5.566780 -4.334454
v  14.695428 5.569540 -4.330984
v  14.695569 5.573055 -4.327982
v  14.687918 5.571689 -4.336059
v  14.643186 5.557775 -4.293631
v  14.692351 5.580606 -4.324731
v  14.694500 5.576899 -4.325809
v  14.652197 5.558445 -4.282166
v  14.650754 5.562558 -4.283969
v  14.648591 5.565544 -4.286696
v  14.645967 5.567046 -4.290020
v  14.643201 5.566882 -4.293539
v  14.640625 5.565071 -4.296829
v  14.638550 5.561832 -4.299493
v  14.637227 5.557558 -4.301208
v  14.636814 5.552761 -4.301771
v  14.637363 5.548021 -4.301111
v  14.638806 5.543909 -4.299309
v  14.640970 5.540924 -4.296581
v  14.643593 5.539421 -4.293257
v  14.646359 5.539585 -4.289738
v  14.648935 5.541396 -4.286448
v  14.651011 5.544633 -4.283785
v  14.652334 5.548910 -4.282068
v  14.652746 5.553706 -4.281506
v  14.640440 5.568841 -4.299496
v  14.694133 5.650377 -4.349513
v  14.690377 5.649073 -4.349523
v  14.686674 5.647670 -4.351004
v  14.683470 5.646336 -4.353779
v  14.681150 5.645236 -4.357512
v  14.679997 5.644497 -4.361753
v  14.680148 5.644213 -4.365993
v  14.681585 5.644415 -4.369717
v  14.684135 5.645082 -4.372478
v  14.687490 5.646130 -4.373940
v  14.691246 5.647434 -4.373932
v  14.694950 5.648838 -4.372451
v  14.698154 5.650171 -4.369676
v  14.700473 5.651272 -4.365942
v  14.701626 5.652009 -4.361700
v  14.701475 5.652295 -4.357461
v  14.688250 5.647036 -4.365853
v  14.683957 5.575714 -4.340203
v  14.697488 5.651424 -4.350977
v  14.700038 5.652091 -4.353737
v  14.693345 5.582325 -4.326133
v  14.690310 5.585340 -4.326182
v  14.686821 5.587415 -4.327453
v  14.683302 5.588300 -4.329790
v  14.680176 5.587887 -4.332913
v  14.677820 5.586226 -4.336444
v  14.676518 5.583520 -4.339958
v  14.676428 5.580095 -4.343032
v  14.677559 5.576361 -4.345292
v  14.679777 5.572771 -4.346469
v  14.682813 5.569755 -4.346419
v  14.686300 5.567681 -4.345149
v  14.689819 5.566796 -4.342812
v  14.692945 5.567209 -4.339688
v  14.695302 5.568869 -4.336157
v  14.696604 5.571575 -4.332643
v  14.696694 5.575002 -4.329570
v  14.695562 5.578734 -4.327309
v  14.662807 5.713943 -4.339015
v  14.660198 5.710287 -4.339383
v  14.657258 5.707319 -4.341103
v  14.654342 5.705398 -4.343968
v  14.651801 5.704758 -4.347633
v  14.649941 5.705471 -4.351657
v  14.648988 5.707455 -4.355554
v  14.649056 5.710470 -4.358850
v  14.650136 5.714151 -4.361155
v  14.652100 5.718056 -4.362185
v  14.654708 5.721713 -4.361819
v  14.657648 5.724680 -4.360099
v  14.660563 5.726602 -4.357233
v  14.663105 5.727242 -4.353568
v  14.664965 5.726529 -4.349544
v  14.665917 5.724545 -4.345649
v  14.658506 5.713807 -4.346295
v  14.687590 5.649776 -4.366076
v  14.664769 5.717848 -4.340046
v  14.665850 5.721529 -4.342349
v  14.696853 5.654063 -4.351192
v  14.693530 5.652881 -4.349717
v  14.689800 5.651467 -4.349717
v  14.686113 5.649993 -4.351192
v  14.682916 5.648638 -4.353967
v  14.680591 5.647562 -4.357702
v  14.679420 5.646897 -4.361951
v  14.679543 5.646722 -4.366199
v  14.680948 5.647059 -4.369933
v  14.683464 5.647869 -4.372705
v  14.686787 5.649051 -4.374180
v  14.690516 5.650463 -4.374180
v  14.694203 5.651936 -4.372705
v  14.697402 5.653293 -4.369931
v  14.699727 5.654368 -4.366195
v  14.700897 5.655034 -4.361947
v  14.700773 5.655208 -4.357699
v  14.699368 5.654871 -4.353965
v  14.647352 5.703563 -4.357748
v  14.561444 5.641841 -4.265206
v  14.692156 5.589056 -4.323936
v  14.564016 5.649741 -4.263011
v  14.557560 5.642321 -4.254638
v  14.546206 5.633418 -4.257551
v  14.598026 5.729242 -4.325553
v  14.654388 5.541935 -4.282558
v  14.686963 5.643135 -4.377585
v  14.694114 5.655373 -4.345965
v  14.620676 5.745778 -4.304677
v  14.652225 5.553913 -4.275595
v  14.554669 5.645072 -4.266640
v  14.614100 5.750973 -4.303747
v  14.647485 5.571444 -4.290539
v  14.705374 5.651276 -4.355884
v  14.603292 5.747488 -4.328375
v  14.636572 5.537087 -4.295321
v  14.601072 5.752245 -4.321234
v  14.644632 5.535069 -4.295044
v  14.604239 5.724055 -4.326182
v  14.632088 5.558988 -4.301805
v  14.656589 5.553883 -4.279484
v  14.648998 5.712230 -4.365205
v  14.607730 5.755900 -4.312254
v  14.601412 5.735255 -4.330459
v  14.632038 5.546432 -4.301476
v  14.603544 5.589897 -4.246754
v  14.546083 5.633689 -4.246490
v  14.615867 5.754356 -4.311546
v  14.649748 5.541970 -4.278427
v  14.678479 5.642553 -4.356008
v  14.617142 5.722784 -4.308593
v  14.643706 5.571476 -4.287163
v  14.564016 5.649741 -4.263012
v  14.554669 5.645072 -4.266641
v  14.554669 5.645072 -4.266640
v  14.564016 5.649741 -4.263011
v  14.552275 5.642150 -4.261684
v  14.557357 5.710594 -4.284636
v  14.538792 6.287107 -4.641335
v  14.538223 6.290696 -4.638885
v  14.539034 6.294089 -4.636734
v  14.541123 6.296879 -4.635140
v  14.544243 6.298727 -4.634296
v  14.548014 6.299413 -4.634305
v  14.551982 6.298852 -4.635163
v  14.555669 6.297112 -4.636768
v  14.558630 6.294405 -4.638927
v  14.560508 6.291057 -4.641379
v  14.561075 6.287467 -4.643827
v  14.560266 6.284075 -4.645979
v  14.558174 6.281285 -4.647573
v  14.555056 6.279436 -4.648417
v  14.551285 6.278750 -4.648408
v  14.547317 6.279311 -4.647550
v  14.550277 6.285117 -4.644063
v  14.545873 6.312818 -4.669992
v  14.540668 6.283758 -4.643786
v  14.543630 6.281051 -4.645946
v  14.537531 6.303438 -4.674976
v  14.535634 6.306916 -4.672727
v  14.535050 6.310613 -4.670449
v  14.535851 6.314083 -4.668421
v  14.537936 6.316909 -4.666884
v  14.541058 6.318747 -4.666029
v  14.544838 6.319377 -4.665953
v  14.548820 6.318725 -4.666668
v  14.552525 6.316866 -4.668088
v  14.555506 6.314028 -4.670040
v  14.557402 6.310550 -4.672291
v  14.557984 6.306851 -4.674567
v  14.557185 6.303380 -4.676596
v  14.555099 6.300556 -4.678131
v  14.551978 6.298717 -4.678988
v  14.548198 6.298087 -4.679064
v  14.544216 6.298739 -4.678349
v  14.540511 6.300599 -4.676929
v  14.543232 6.266188 -4.611724
v  14.542700 6.269604 -4.609033
v  14.543536 6.272871 -4.606705
v  14.545640 6.275597 -4.605020
v  14.548758 6.277450 -4.604182
v  14.552513 6.278208 -4.604290
v  14.556454 6.277779 -4.605333
v  14.560103 6.276217 -4.607185
v  14.563023 6.273706 -4.609622
v  14.564859 6.270554 -4.612351
v  14.565392 6.267137 -4.615041
v  14.564555 6.263870 -4.617369
v  14.562451 6.261145 -4.619054
v  14.559334 6.259293 -4.619894
v  14.555578 6.258533 -4.619784
v  14.551639 6.258962 -4.618741
v  14.550601 6.266323 -4.612964
v  14.549154 6.292335 -4.637580
v  14.545069 6.263035 -4.614453
v  14.547988 6.260525 -4.616889
v  14.540800 6.283051 -4.642723
v  14.538923 6.286396 -4.640263
v  14.538357 6.289979 -4.637806
v  14.539167 6.293369 -4.635650
v  14.541258 6.296156 -4.634055
v  14.544376 6.298006 -4.633211
v  14.548147 6.298694 -4.633222
v  14.552114 6.298137 -4.634086
v  14.555800 6.296403 -4.635699
v  14.558761 6.293702 -4.637866
v  14.560637 6.290357 -4.640326
v  14.561205 6.286774 -4.642782
v  14.560393 6.283384 -4.644938
v  14.558303 6.280596 -4.646534
v  14.555184 6.278747 -4.647377
v  14.551414 6.278059 -4.647367
v  14.547446 6.278615 -4.646503
v  14.543760 6.280350 -4.644890
v  14.548910 6.245114 -4.586345
v  14.548454 6.248251 -4.583323
v  14.549347 6.251312 -4.580746
v  14.551481 6.253923 -4.578926
v  14.554600 6.255772 -4.578082
v  14.558327 6.256637 -4.578317
v  14.562213 6.256410 -4.579600
v  14.565788 6.255121 -4.581779
v  14.568623 6.252924 -4.584591
v  14.570374 6.250086 -4.587694
v  14.570830 6.246948 -4.590717
v  14.569938 6.243887 -4.593294
v  14.567802 6.241277 -4.595114
v  14.564684 6.239427 -4.595958
v  14.560957 6.238564 -4.595723
v  14.557072 6.238790 -4.594440
v  14.558656 6.244219 -4.589868
v  14.555143 6.271263 -4.608521
v  14.550662 6.242276 -4.589449
v  14.553496 6.240078 -4.592260
v  14.545240 6.262321 -4.613517
v  14.543406 6.265467 -4.610777
v  14.542876 6.268875 -4.608077
v  14.543714 6.272137 -4.605742
v  14.545817 6.274859 -4.604053
v  14.548936 6.276712 -4.603214
v  14.552691 6.277473 -4.603326
v  14.556629 6.277050 -4.604377
v  14.560277 6.275496 -4.606238
v  14.563194 6.272994 -4.608686
v  14.565029 6.269850 -4.611426
v  14.565559 6.266440 -4.614126
v  14.564721 6.263179 -4.616462
v  14.562617 6.260457 -4.618151
v  14.559500 6.258604 -4.618989
v  14.555744 6.257842 -4.618876
v  14.551806 6.258265 -4.617826
v  14.548158 6.259820 -4.615965
v  14.557895 6.217570 -4.560724
v  14.557746 6.220229 -4.557245
v  14.558898 6.222886 -4.554345
v  14.561212 6.225220 -4.552373
v  14.564407 6.226951 -4.551567
v  14.568101 6.227868 -4.552025
v  14.571845 6.227861 -4.553690
v  14.575190 6.226933 -4.556363
v  14.577731 6.225194 -4.559722
v  14.579162 6.222855 -4.563359
v  14.579310 6.220195 -4.566838
v  14.578158 6.217539 -4.569739
v  14.575845 6.215205 -4.571711
v  14.572649 6.213475 -4.572516
v  14.568955 6.212557 -4.572060
v  14.565211 6.212563 -4.570394
v  14.568692 6.217275 -4.565886
v  14.559355 6.250345 -4.582900
v  14.559325 6.215230 -4.564363
v  14.561867 6.213490 -4.567721
v  14.550875 6.241567 -4.588685
v  14.549129 6.244390 -4.585566
v  14.548676 6.247517 -4.582530
v  14.549571 6.250567 -4.579943
v  14.551707 6.253175 -4.578116
v  14.554826 6.255023 -4.577273
v  14.558551 6.255891 -4.577513
v  14.562434 6.255672 -4.578806
v  14.566006 6.254395 -4.580998
v  14.568836 6.252213 -4.583824
v  14.570582 6.249390 -4.586943
v  14.571035 6.246264 -4.589979
v  14.570140 6.243214 -4.592566
v  14.568005 6.240607 -4.594392
v  14.564885 6.238758 -4.595236
v  14.561160 6.237890 -4.594996
v  14.557278 6.238108 -4.593702
v  14.553705 6.239385 -4.591511
v  14.577919 6.214365 -4.575350
v  14.569502 6.185891 -4.538725
v  14.569590 6.188193 -4.534999
v  14.570932 6.190568 -4.531940
v  14.573366 6.192734 -4.529922
v  14.576599 6.194425 -4.529183
v  14.580241 6.195440 -4.529816
v  14.583852 6.195656 -4.531743
v  14.586996 6.195045 -4.534732
v  14.589295 6.193684 -4.538422
v  14.590472 6.191735 -4.542368
v  14.590384 6.189433 -4.546095
v  14.589042 6.187057 -4.549152
v  14.586607 6.184892 -4.551172
v  14.583374 6.183199 -4.551909
v  14.579733 6.182186 -4.551277
v  14.576122 6.181970 -4.549350
v  14.584010 6.189934 -4.541245
v  14.572570 6.218173 -4.564216
v  14.570678 6.183942 -4.542671
v  14.572977 6.182580 -4.546362
v  14.559674 6.214211 -4.563562
v  14.558253 6.216537 -4.559910
v  14.558114 6.219181 -4.556420
v  14.559275 6.221826 -4.553512
v  14.561593 6.224152 -4.551537
v  14.564792 6.225880 -4.550733
v  14.568483 6.226799 -4.551198
v  14.572223 6.226801 -4.552874
v  14.575560 6.225884 -4.555561
v  14.578092 6.224161 -4.558934
v  14.579513 6.221836 -4.562585
v  14.579651 6.219191 -4.566075
v  14.578492 6.216547 -4.568984
v  14.576172 6.214220 -4.570959
v  14.572974 6.212492 -4.571763
v  14.569283 6.211573 -4.571298
v  14.565543 6.211571 -4.569622
v  14.562206 6.212487 -4.566935
v  14.583044 6.152586 -4.520051
v  14.583294 6.154661 -4.516200
v  14.584755 6.156880 -4.513081
v  14.587254 6.158978 -4.511068
v  14.590487 6.160700 -4.510406
v  14.594065 6.161839 -4.511173
v  14.597556 6.162258 -4.513278
v  14.600540 6.161906 -4.516466
v  14.602656 6.160825 -4.520353
v  14.603650 6.159146 -4.524471
v  14.603401 6.157071 -4.528322
v  14.601939 6.154851 -4.531442
v  14.599441 6.152755 -4.533454
v  14.596208 6.151031 -4.534117
v  14.592629 6.149893 -4.533349
v  14.589138 6.149475 -4.531244
v  14.590587 6.153549 -4.524485
v  14.584438 6.188822 -4.540573
v  14.584038 6.150907 -4.524168
v  14.586154 6.149827 -4.528055
v  14.571106 6.182825 -4.541986
v  14.569937 6.184762 -4.538033
v  14.570032 6.187055 -4.534300
v  14.571380 6.189424 -4.531239
v  14.573817 6.191585 -4.529219
v  14.577050 6.193276 -4.528482
v  14.580689 6.194294 -4.529122
v  14.584296 6.194518 -4.531055
v  14.587435 6.193918 -4.534052
v  14.589726 6.192566 -4.537751
v  14.590897 6.190629 -4.541705
v  14.590801 6.188337 -4.545438
v  14.589454 6.185968 -4.548498
v  14.587016 6.183808 -4.550519
v  14.583783 6.182116 -4.551254
v  14.580143 6.181097 -4.550616
v  14.576537 6.180875 -4.548682
v  14.573399 6.181475 -4.545685
v  14.597926 6.119905 -4.504144
v  14.598268 6.121873 -4.500245
v  14.599783 6.124052 -4.497122
v  14.602290 6.126179 -4.495152
v  14.605486 6.127996 -4.494571
v  14.608986 6.129288 -4.495452
v  14.612368 6.129894 -4.497685
v  14.615223 6.129745 -4.501006
v  14.617208 6.128856 -4.505010
v  14.618083 6.127336 -4.509215
v  14.617742 6.125368 -4.513114
v  14.616226 6.123188 -4.516236
v  14.613719 6.121062 -4.518208
v  14.610523 6.119244 -4.518786
v  14.607023 6.117954 -4.517906
v  14.603642 6.117347 -4.515672
v  14.604137 6.122196 -4.505707
v  14.593552 6.152451 -4.525943
v  14.598802 6.118385 -4.508348
v  14.600786 6.117496 -4.512353
v  14.584523 6.149772 -4.523584
v  14.583535 6.151443 -4.519464
v  14.583789 6.153512 -4.515610
v  14.585254 6.155729 -4.512489
v  14.587753 6.157825 -4.510477
v  14.590985 6.159550 -4.509818
v  14.594562 6.160694 -4.510590
v  14.598049 6.161119 -4.512699
v  14.601028 6.160775 -4.515893
v  14.603140 6.159702 -4.519786
v  14.604128 6.158030 -4.523907
v  14.603874 6.155961 -4.527761
v  14.602409 6.153744 -4.530881
v  14.599910 6.151648 -4.532893
v  14.596678 6.149924 -4.533552
v  14.593102 6.148779 -4.532781
v  14.589614 6.148354 -4.530671
v  14.586635 6.148699 -4.527477
v  14.613588 6.090049 -4.490435
v  14.613942 6.092040 -4.486547
v  14.615431 6.094306 -4.483475
v  14.617874 6.096575 -4.481586
v  14.620979 6.098572 -4.481108
v  14.624372 6.100056 -4.482100
v  14.627640 6.100850 -4.484441
v  14.630392 6.100856 -4.487849
v  14.632296 6.100074 -4.491915
v  14.633121 6.098598 -4.496145
v  14.632767 6.096608 -4.500031
v  14.631279 6.094341 -4.503105
v  14.628835 6.092072 -4.504995
v  14.625730 6.090075 -4.505473
v  14.622338 6.088591 -4.504481
v  14.619069 6.087798 -4.502140
v  14.624999 6.096828 -4.489894
v  14.612052 6.124568 -4.505548
v  14.614414 6.088573 -4.494666
v  14.616317 6.087791 -4.498731
v  14.599325 6.117307 -4.507847
v  14.598454 6.118823 -4.503640
v  14.598796 6.120789 -4.499740
v  14.600312 6.122969 -4.496617
v  14.602818 6.125099 -4.494650
v  14.606012 6.126922 -4.494073
v  14.609509 6.128217 -4.494957
v  14.612887 6.128830 -4.497195
v  14.615739 6.128686 -4.500518
v  14.617721 6.127804 -4.504525
v  14.618591 6.126287 -4.508732
v  14.618249 6.124320 -4.512630
v  14.616734 6.122139 -4.515754
v  14.614227 6.120011 -4.517722
v  14.611033 6.118187 -4.518299
v  14.607536 6.116891 -4.517415
v  14.604158 6.116280 -4.515177
v  14.601307 6.116423 -4.511853
v  14.629549 6.065156 -4.478316
v  14.629785 6.067343 -4.474527
v  14.631106 6.069878 -4.471590
v  14.633354 6.072453 -4.469858
v  14.636259 6.074759 -4.469542
v  14.639469 6.076519 -4.470677
v  14.642597 6.077519 -4.473129
v  14.645267 6.077640 -4.476601
v  14.647155 6.076866 -4.480674
v  14.648035 6.075291 -4.484858
v  14.647799 6.073102 -4.488647
v  14.646478 6.070569 -4.491584
v  14.644230 6.067993 -4.493316
v  14.641325 6.065686 -4.493632
v  14.638115 6.063927 -4.492498
v  14.634987 6.062927 -4.490046
v  14.635336 6.069118 -4.478878
v  14.627312 6.095613 -4.492341
v  14.630429 6.063580 -4.482500
v  14.632318 6.062807 -4.486573
v  14.614958 6.087625 -4.494227
v  14.614133 6.089102 -4.489997
v  14.614486 6.091095 -4.486112
v  14.615971 6.093368 -4.483042
v  14.618411 6.095644 -4.481155
v  14.621511 6.097649 -4.480682
v  14.624898 6.099142 -4.481678
v  14.628163 6.099941 -4.484023
v  14.630912 6.099952 -4.487434
v  14.632813 6.099172 -4.491501
v  14.633638 6.097696 -4.495731
v  14.633286 6.095702 -4.499617
v  14.631801 6.093429 -4.502687
v  14.629360 6.091154 -4.504573
v  14.626260 6.089149 -4.505046
v  14.622873 6.087656 -4.504050
v  14.619608 6.086857 -4.501705
v  14.616859 6.086845 -4.498294
v  14.648803 6.044447 -4.465061
v  14.648636 6.047015 -4.461514
v  14.649442 6.050059 -4.458891
v  14.651124 6.053212 -4.457506
v  14.653480 6.056094 -4.457528
v  14.656224 6.058358 -4.458953
v  14.659027 6.059731 -4.461610
v  14.661551 6.060047 -4.465178
v  14.663488 6.059267 -4.469226
v  14.664609 6.057487 -4.473268
v  14.664776 6.054920 -4.476814
v  14.663969 6.051876 -4.479438
v  14.662288 6.048723 -4.480823
v  14.659932 6.045840 -4.480801
v  14.657187 6.043577 -4.479376
v  14.654385 6.042203 -4.476719
v  14.657597 6.054331 -4.466265
v  14.636118 6.066952 -4.481553
v  14.649923 6.042666 -4.469103
v  14.651861 6.041888 -4.473151
v  14.630982 6.062834 -4.482101
v  14.630097 6.064415 -4.477922
v  14.630325 6.066614 -4.474139
v  14.631637 6.069161 -4.471209
v  14.633874 6.071752 -4.469485
v  14.636768 6.074073 -4.469175
v  14.639970 6.075845 -4.470318
v  14.643092 6.076853 -4.472774
v  14.645759 6.076976 -4.476247
v  14.647649 6.076199 -4.480320
v  14.648533 6.074616 -4.484499
v  14.648306 6.072419 -4.488282
v  14.646995 6.069870 -4.491213
v  14.644756 6.067281 -4.492936
v  14.641863 6.064959 -4.493246
v  14.638661 6.063188 -4.492104
v  14.635539 6.062180 -4.489648
v  14.632872 6.062057 -4.486175
v  14.648172 6.078843 -4.474407
v  14.683609 6.026749 -4.448036
v  14.682960 6.029414 -4.444621
v  14.682888 6.032788 -4.442295
v  14.683404 6.036464 -4.441340
v  14.684445 6.039998 -4.441872
v  14.685885 6.042964 -4.443826
v  14.687551 6.045006 -4.446965
v  14.689242 6.045875 -4.450912
v  14.690754 6.045466 -4.455191
v  14.691904 6.043832 -4.459285
v  14.692554 6.041167 -4.462701
v  14.692624 6.037793 -4.465026
v  14.692109 6.034117 -4.465981
v  14.691068 6.030581 -4.465449
v  14.689627 6.027616 -4.463496
v  14.687961 6.025575 -4.460357
v  14.687186 6.031228 -4.454716
v  14.656856 6.046778 -4.471416
v  14.684758 6.025113 -4.452131
v  14.686270 6.024705 -4.456409
v  14.651036 6.041776 -4.468419
v  14.649911 6.043551 -4.464378
v  14.649727 6.046124 -4.460837
v  14.650506 6.049184 -4.458221
v  14.652155 6.052360 -4.456852
v  14.654475 6.055271 -4.456889
v  14.657187 6.057566 -4.458330
v  14.659962 6.058967 -4.461002
v  14.662466 6.059305 -4.464581
v  14.664397 6.058539 -4.468635
v  14.665524 6.056764 -4.472677
v  14.665708 6.054191 -4.476218
v  14.664927 6.051132 -4.478832
v  14.663279 6.047955 -4.480203
v  14.660958 6.045043 -4.480165
v  14.658248 6.042749 -4.478724
v  14.655473 6.041348 -4.476053
v  14.652968 6.041010 -4.472474
v  14.667193 6.060667 -4.468229
v  14.721121 6.026628 -4.438612
v  14.720080 6.029251 -4.435260
v  14.719032 6.032596 -4.433135
v  14.718104 6.036258 -4.432492
v  14.717408 6.039798 -4.433411
v  14.717027 6.042788 -4.435778
v  14.717006 6.044867 -4.439310
v  14.717350 6.045784 -4.443580
v  14.718016 6.045431 -4.448072
v  14.718925 6.043847 -4.452248
v  14.719964 6.041224 -4.455600
v  14.721012 6.037880 -4.457726
v  14.721940 6.034218 -4.458367
v  14.722637 6.030678 -4.457449
v  14.723018 6.027688 -4.455082
v  14.723039 6.025609 -4.451550
v  14.719019 6.036988 -4.440817
v  14.687075 6.032765 -4.449762
v  14.722029 6.025045 -4.442786
v  14.722695 6.024691 -4.447280
v  14.686020 6.024823 -4.451671
v  14.684875 6.026453 -4.447574
v  14.684209 6.029119 -4.444162
v  14.684105 6.032499 -4.441845
v  14.684574 6.036185 -4.440905
v  14.685560 6.039732 -4.441454
v  14.686944 6.042714 -4.443425
v  14.688560 6.044769 -4.446582
v  14.690211 6.045650 -4.450542
v  14.691699 6.045250 -4.454830
v  14.692845 6.043621 -4.458927
v  14.693510 6.040954 -4.462340
v  14.693615 6.037575 -4.464656
v  14.693146 6.033889 -4.465596
v  14.692159 6.030342 -4.465048
v  14.690775 6.027359 -4.463076
v  14.689160 6.025306 -4.459919
v  14.687509 6.024424 -4.455958
v  14.756396 6.043185 -4.439072
v  14.755138 6.045702 -4.435714
v  14.753315 6.048686 -4.433577
v  14.751145 6.051775 -4.432916
v  14.748891 6.054599 -4.433813
v  14.746823 6.056815 -4.436160
v  14.745193 6.058156 -4.439672
v  14.744195 6.058462 -4.443928
v  14.743951 6.057693 -4.448412
v  14.744490 6.055945 -4.452584
v  14.745747 6.053428 -4.455942
v  14.747570 6.050444 -4.458080
v  14.749740 6.047355 -4.458740
v  14.751994 6.044530 -4.457843
v  14.754062 6.042315 -4.455497
v  14.755692 6.040974 -4.451983
v  14.749847 6.051497 -4.441217
v  14.721606 6.032211 -4.442669
v  14.756934 6.041436 -4.443244
v  14.756691 6.040668 -4.447729
v  14.723293 6.025347 -4.442631
v  14.722398 6.026933 -4.438455
v  14.721346 6.029551 -4.435103
v  14.720267 6.032887 -4.432981
v  14.719290 6.036538 -4.432344
v  14.718533 6.040063 -4.433270
v  14.718086 6.043039 -4.435644
v  14.718005 6.045104 -4.439183
v  14.718299 6.046010 -4.443460
v  14.718932 6.045650 -4.447957
v  14.719829 6.044064 -4.452133
v  14.720880 6.041446 -4.455484
v  14.721959 6.038110 -4.457606
v  14.722937 6.034459 -4.458243
v  14.723694 6.030932 -4.457319
v  14.724140 6.027957 -4.454944
v  14.724220 6.025893 -4.451405
v  14.723927 6.024986 -4.447128
v  14.741266 6.059810 -4.449120
v  14.672421 6.456103 -5.031404
v  14.672714 6.460388 -5.030534
v  14.673271 6.463900 -5.028497
v  14.674024 6.466217 -5.025537
v  14.674880 6.467061 -5.022015
v  14.675737 6.466328 -5.018352
v  14.676493 6.464107 -5.014991
v  14.677055 6.460668 -5.012337
v  14.677356 6.456422 -5.010711
v  14.677360 6.451884 -5.010309
v  14.677066 6.447599 -5.011179
v  14.676509 6.444087 -5.013216
v  14.675757 6.441770 -5.016174
v  14.674901 6.440926 -5.019697
v  14.674043 6.441658 -5.023360
v  14.673287 6.443879 -5.026721
v  14.675222 6.449303 -5.019059
v  14.707916 6.459478 -5.026582
v  14.672423 6.451565 -5.031001
v  14.672725 6.447320 -5.029375
v  14.707256 6.452515 -5.036907
v  14.706950 6.457057 -5.037060
v  14.706801 6.461287 -5.035928
v  14.706828 6.464694 -5.033648
v  14.707026 6.466865 -5.030497
v  14.707374 6.467541 -5.026852
v  14.707828 6.466638 -5.023155
v  14.708332 6.464266 -5.019851
v  14.708828 6.460711 -5.017338
v  14.709254 6.456403 -5.015920
v  14.709561 6.451859 -5.015768
v  14.709709 6.447629 -5.016900
v  14.709683 6.444223 -5.019179
v  14.709485 6.442051 -5.022331
v  14.709138 6.441377 -5.025975
v  14.708685 6.442280 -5.029673
v  14.708179 6.444652 -5.032977
v  14.707685 6.448205 -5.035490
v  14.637131 6.456186 -5.018708
v  14.637410 6.460485 -5.017910
v  14.638263 6.464034 -5.016047
v  14.639585 6.466407 -5.013343
v  14.641221 6.467316 -5.010125
v  14.642972 6.466650 -5.006780
v  14.644625 6.464494 -5.003712
v  14.645981 6.461102 -5.001292
v  14.646877 6.456890 -4.999810
v  14.647206 6.452360 -4.999445
v  14.646927 6.448061 -5.000243
v  14.646075 6.444510 -5.002106
v  14.644751 6.442140 -5.004810
v  14.643116 6.441231 -5.008029
v  14.641366 6.441895 -5.011373
v  14.639712 6.444052 -5.014440
v  14.642531 6.449267 -5.008674
v  14.673721 6.459032 -5.021026
v  14.637460 6.451657 -5.018343
v  14.638356 6.447442 -5.016861
v  14.671194 6.451579 -5.030702
v  14.671190 6.456117 -5.031110
v  14.671493 6.460401 -5.030247
v  14.672064 6.463917 -5.028218
v  14.672836 6.466237 -5.025266
v  14.673716 6.467083 -5.021750
v  14.674595 6.466355 -5.018092
v  14.675371 6.464138 -5.014734
v  14.675948 6.460700 -5.012079
v  14.676257 6.456456 -5.010450
v  14.676262 6.451919 -5.010043
v  14.675960 6.447634 -5.010906
v  14.675388 6.444118 -5.012935
v  14.674617 6.441799 -5.015887
v  14.673738 6.440952 -5.019403
v  14.672856 6.441680 -5.023061
v  14.672081 6.443898 -5.026420
v  14.671504 6.447336 -5.029074
v  14.604976 6.451852 -4.994656
v  14.604884 6.456100 -4.993587
v  14.605782 6.459659 -4.991762
v  14.607561 6.462099 -4.989403
v  14.610008 6.463127 -4.986793
v  14.612827 6.462617 -4.984246
v  14.615679 6.460634 -4.982071
v  14.618218 6.457414 -4.980530
v  14.620140 6.453347 -4.979808
v  14.621212 6.448922 -4.979993
v  14.621305 6.444674 -4.981062
v  14.620407 6.441115 -4.982886
v  14.618627 6.438674 -4.985246
v  14.616180 6.437646 -4.987856
v  14.613361 6.438157 -4.990402
v  14.610510 6.440141 -4.992578
v  14.614278 6.445498 -4.987528
v  14.641489 6.449233 -5.008125
v  14.606049 6.447428 -4.994841
v  14.607970 6.443361 -4.994119
v  14.636308 6.451614 -5.017738
v  14.635957 6.456143 -5.018092
v  14.636228 6.460442 -5.017292
v  14.637086 6.463993 -5.015433
v  14.638429 6.466367 -5.012740
v  14.640094 6.467277 -5.009538
v  14.641880 6.466615 -5.006211
v  14.643573 6.464459 -5.003164
v  14.644968 6.461071 -5.000762
v  14.645896 6.456860 -4.999295
v  14.646247 6.452331 -4.998941
v  14.645976 6.448031 -4.999742
v  14.645119 6.444482 -5.001601
v  14.643776 6.442108 -5.004293
v  14.642111 6.441196 -5.007496
v  14.640324 6.441860 -5.010822
v  14.638631 6.444014 -5.013869
v  14.637237 6.447402 -5.016272
v  14.585477 6.444722 -4.970263
v  14.585312 6.448974 -4.969220
v  14.586267 6.452598 -4.967562
v  14.588227 6.455159 -4.965491
v  14.590957 6.456347 -4.963257
v  14.594127 6.456017 -4.961127
v  14.597354 6.454213 -4.959360
v  14.600250 6.451149 -4.958169
v  14.602466 6.447196 -4.957697
v  14.603732 6.442830 -4.958001
v  14.603898 6.438579 -4.959045
v  14.602944 6.434954 -4.960702
v  14.600983 6.432393 -4.962772
v  14.598253 6.431205 -4.965008
v  14.595083 6.431534 -4.967137
v  14.591855 6.433339 -4.968904
v  14.593204 6.448600 -4.963796
v  14.611192 6.446187 -4.988372
v  14.586744 6.440356 -4.970568
v  14.588960 6.436403 -4.970096
v  14.605070 6.447142 -4.993800
v  14.603972 6.451558 -4.993587
v  14.603866 6.455801 -4.992503
v  14.604761 6.459360 -4.990678
v  14.606552 6.461802 -4.988330
v  14.609022 6.462838 -4.985744
v  14.611874 6.462337 -4.983232
v  14.614761 6.460365 -4.981096
v  14.617339 6.457156 -4.979594
v  14.619295 6.453099 -4.978909
v  14.620392 6.448680 -4.979120
v  14.620499 6.444438 -4.980205
v  14.619603 6.440880 -4.982031
v  14.617811 6.438436 -4.984378
v  14.615341 6.437402 -4.986964
v  14.612491 6.437901 -4.989476
v  14.609602 6.439875 -4.991611
v  14.607025 6.443084 -4.993114
v  14.568855 6.437092 -4.943248
v  14.568607 6.441325 -4.942146
v  14.569569 6.444981 -4.940563
v  14.571624 6.447619 -4.938691
v  14.574524 6.448922 -4.936754
v  14.577920 6.448730 -4.934988
v  14.581402 6.447071 -4.933604
v  14.584550 6.444139 -4.932769
v  14.586985 6.440294 -4.932585
v  14.588412 6.435995 -4.933074
v  14.588659 6.431762 -4.934177
v  14.587698 6.428106 -4.935759
v  14.585643 6.425467 -4.937632
v  14.582743 6.424165 -4.939568
v  14.579347 6.424355 -4.941335
v  14.575865 6.426016 -4.942720
v  14.578359 6.441221 -4.936944
v  14.595442 6.438731 -4.963655
v  14.570282 6.432793 -4.943738
v  14.572717 6.428946 -4.943553
v  14.586157 6.440121 -4.969721
v  14.584886 6.444485 -4.969414
v  14.584718 6.448737 -4.968372
v  14.585674 6.452363 -4.966720
v  14.587639 6.454927 -4.964657
v  14.590376 6.456120 -4.962432
v  14.593554 6.455797 -4.960314
v  14.596789 6.453996 -4.958559
v  14.599693 6.450937 -4.957376
v  14.601915 6.446987 -4.956911
v  14.603188 6.442622 -4.957218
v  14.603354 6.438370 -4.958260
v  14.602399 6.434742 -4.959913
v  14.600433 6.432178 -4.961975
v  14.597697 6.430986 -4.964200
v  14.594520 6.431311 -4.966318
v  14.591284 6.433111 -4.968073
v  14.588379 6.436170 -4.969256
v  14.588243 6.422162 -4.938173
v  14.554317 6.426913 -4.911463
v  14.553955 6.431076 -4.910147
v  14.554881 6.434721 -4.908518
v  14.556984 6.437408 -4.906773
v  14.560010 6.438814 -4.905121
v  14.563594 6.438767 -4.903763
v  14.567303 6.437275 -4.902862
v  14.570691 6.434517 -4.902526
v  14.573348 6.430828 -4.902796
v  14.574954 6.426649 -4.903641
v  14.575316 6.422484 -4.904956
v  14.574389 6.418839 -4.906585
v  14.572287 6.416152 -4.908331
v  14.569262 6.414748 -4.909983
v  14.565678 6.414793 -4.911341
v  14.561968 6.416286 -4.912242
v  14.566410 6.422163 -4.908485
v  14.577837 6.440935 -4.935966
v  14.555923 6.422734 -4.912307
v  14.558580 6.419043 -4.912578
v  14.569749 6.432498 -4.942738
v  14.568316 6.436794 -4.942239
v  14.568064 6.441026 -4.941132
v  14.569025 6.444682 -4.939548
v  14.571082 6.447323 -4.937681
v  14.573987 6.448628 -4.935755
v  14.577391 6.448442 -4.934002
v  14.580881 6.446788 -4.932633
v  14.584038 6.443861 -4.931814
v  14.586481 6.440020 -4.931643
v  14.587914 6.435724 -4.932142
v  14.588165 6.431493 -4.933249
v  14.587205 6.427835 -4.934833
v  14.585148 6.425196 -4.936700
v  14.582243 6.423890 -4.938626
v  14.578839 6.424076 -4.940379
v  14.575348 6.425731 -4.941748
v  14.572191 6.428656 -4.942567
v  14.542983 6.412632 -4.874805
v  14.542521 6.416675 -4.873178
v  14.543405 6.420269 -4.871420
v  14.545527 6.422986 -4.869744
v  14.548632 6.424496 -4.868351
v  14.552345 6.424614 -4.867411
v  14.556219 6.423332 -4.867036
v  14.559787 6.420800 -4.867270
v  14.562617 6.417324 -4.868088
v  14.564370 6.413325 -4.869389
v  14.564832 6.409284 -4.871017
v  14.563949 6.405688 -4.872774
v  14.561827 6.402973 -4.874452
v  14.558722 6.401464 -4.875844
v  14.555008 6.401342 -4.876784
v  14.551133 6.402627 -4.877159
v  14.552701 6.409007 -4.874040
v  14.565985 6.421771 -4.907368
v  14.544736 6.408634 -4.876107
v  14.547566 6.405159 -4.876925
v  14.555481 6.422326 -4.911147
v  14.553868 6.426500 -4.910289
v  14.553503 6.430660 -4.908963
v  14.554428 6.434304 -4.907331
v  14.556532 6.436993 -4.905589
v  14.559562 6.438402 -4.903947
v  14.563150 6.438361 -4.902602
v  14.566867 6.436874 -4.901719
v  14.570262 6.434124 -4.901402
v  14.572927 6.430439 -4.901690
v  14.574538 6.426266 -4.902549
v  14.574904 6.422106 -4.903875
v  14.573978 6.418462 -4.905506
v  14.571875 6.415772 -4.907248
v  14.568846 6.414365 -4.908891
v  14.565256 6.414406 -4.910234
v  14.561541 6.415891 -4.911119
v  14.558146 6.418642 -4.911435
v  14.564637 6.400207 -4.876143
v  14.536228 6.394180 -4.835915
v  14.535706 6.398081 -4.833991
v  14.536558 6.401614 -4.832097
v  14.538682 6.404354 -4.830463
v  14.541822 6.405971 -4.829286
v  14.545600 6.406269 -4.828708
v  14.549559 6.405210 -4.828799
v  14.553222 6.402926 -4.829547
v  14.556147 6.399691 -4.830863
v  14.557981 6.395894 -4.832587
v  14.558503 6.391993 -4.834511
v  14.557652 6.388459 -4.836404
v  14.555527 6.385720 -4.838039
v  14.552387 6.384103 -4.839216
v  14.548610 6.383805 -4.839793
v  14.544651 6.384861 -4.839702
v  14.547682 6.390727 -4.836378
v  14.551023 6.409415 -4.872643
v  14.538063 6.390383 -4.837639
v  14.540988 6.387146 -4.838954
v  14.544421 6.408061 -4.874778
v  14.542665 6.412053 -4.873461
v  14.542200 6.416089 -4.871821
v  14.543081 6.419682 -4.870058
v  14.545204 6.422399 -4.868383
v  14.548311 6.423912 -4.866998
v  14.552029 6.424037 -4.866071
v  14.555906 6.422761 -4.865715
v  14.559479 6.420238 -4.865970
v  14.562314 6.416771 -4.866807
v  14.564070 6.412780 -4.868124
v  14.564535 6.408742 -4.869763
v  14.563653 6.405149 -4.871527
v  14.561531 6.402433 -4.873202
v  14.558424 6.400920 -4.874587
v  14.554707 6.400795 -4.875513
v  14.550828 6.402071 -4.875869
v  14.547256 6.404593 -4.875616
v  14.533909 6.380181 -4.808386
v  14.533356 6.384075 -4.806455
v  14.534184 6.387638 -4.804607
v  14.536294 6.390440 -4.803065
v  14.539432 6.392146 -4.802013
v  14.543219 6.392546 -4.801580
v  14.547198 6.391594 -4.801818
v  14.550890 6.389407 -4.802699
v  14.553849 6.386245 -4.804113
v  14.555718 6.382491 -4.805894
v  14.556273 6.378597 -4.807826
v  14.555444 6.375033 -4.809674
v  14.553333 6.372231 -4.811217
v  14.550196 6.370527 -4.812268
v  14.546409 6.370125 -4.812700
v  14.542429 6.371076 -4.812461
v  14.541544 6.384830 -4.805575
v  14.544980 6.398797 -4.831493
v  14.535778 6.376428 -4.810167
v  14.538737 6.373265 -4.811582
v  14.537968 6.389936 -4.836772
v  14.536131 6.393733 -4.835046
v  14.535607 6.397634 -4.833122
v  14.536458 6.401170 -4.831231
v  14.538583 6.403912 -4.829602
v  14.541722 6.405533 -4.828431
v  14.545501 6.405835 -4.827858
v  14.549460 6.404782 -4.827955
v  14.553124 6.402501 -4.828708
v  14.556051 6.399269 -4.830027
v  14.557887 6.395472 -4.831752
v  14.558411 6.391572 -4.833677
v  14.557560 6.388037 -4.835567
v  14.555436 6.385293 -4.837197
v  14.552296 6.383673 -4.838368
v  14.548518 6.383370 -4.838940
v  14.544559 6.384424 -4.838844
v  14.540894 6.386703 -4.838090
v  14.532653 6.364326 -4.777261
v  14.532080 6.368196 -4.775290
v  14.532891 6.371764 -4.773443
v  14.534991 6.374598 -4.771944
v  14.538124 6.376357 -4.770972
v  14.541914 6.376830 -4.770645
v  14.545902 6.375957 -4.771003
v  14.549609 6.373846 -4.772003
v  14.552587 6.370750 -4.773523
v  14.554477 6.367044 -4.775381
v  14.555049 6.363173 -4.777352
v  14.554236 6.359607 -4.779198
v  14.552137 6.356772 -4.780698
v  14.549005 6.355012 -4.781670
v  14.545215 6.354541 -4.781996
v  14.541225 6.355412 -4.781639
v  14.545652 6.361589 -4.778373
v  14.545366 6.376528 -4.808272
v  14.534542 6.360619 -4.779119
v  14.537519 6.357523 -4.780639
v  14.535718 6.375914 -4.809153
v  14.533848 6.379667 -4.807371
v  14.533294 6.383560 -4.805439
v  14.534121 6.387123 -4.803590
v  14.536231 6.389927 -4.802050
v  14.539370 6.391634 -4.801002
v  14.543157 6.392039 -4.800574
v  14.547136 6.391090 -4.800817
v  14.550829 6.388905 -4.801701
v  14.553789 6.385745 -4.803119
v  14.555658 6.381993 -4.804903
v  14.556213 6.378099 -4.806835
v  14.555386 6.374536 -4.808684
v  14.553276 6.371732 -4.810222
v  14.550138 6.370025 -4.811270
v  14.546350 6.369621 -4.811699
v  14.542371 6.370569 -4.811457
v  14.538678 6.372754 -4.810572
v  14.532474 6.346878 -4.743813
v  14.531890 6.350712 -4.741774
v  14.532692 6.354266 -4.739897
v  14.534783 6.357111 -4.738409
v  14.537912 6.358908 -4.737491
v  14.541700 6.359435 -4.737251
v  14.545692 6.358632 -4.737720
v  14.549405 6.356595 -4.738840
v  14.552392 6.353568 -4.740477
v  14.554292 6.349917 -4.742432
v  14.554876 6.346085 -4.744472
v  14.554074 6.342531 -4.746346
v  14.551983 6.339684 -4.747835
v  14.548854 6.337888 -4.748754
v  14.545065 6.337359 -4.748994
v  14.541074 6.338164 -4.748525
v  14.545483 6.344365 -4.745283
v  14.544174 6.360843 -4.777394
v  14.534373 6.343227 -4.745769
v  14.537360 6.340200 -4.747404
v  14.534518 6.360048 -4.778007
v  14.532628 6.363753 -4.776146
v  14.532055 6.367621 -4.774172
v  14.532866 6.371190 -4.772325
v  14.534966 6.374023 -4.770826
v  14.538099 6.375784 -4.769857
v  14.541888 6.376259 -4.769533
v  14.545877 6.375390 -4.769895
v  14.549584 6.373281 -4.770898
v  14.552563 6.370188 -4.772422
v  14.554452 6.366484 -4.774283
v  14.555025 6.362614 -4.776256
v  14.554214 6.359046 -4.778103
v  14.552114 6.356212 -4.779602
v  14.548982 6.354451 -4.780572
v  14.545192 6.353977 -4.780895
v  14.541203 6.354846 -4.780534
v  14.537496 6.356955 -4.779531
v  14.533396 6.328125 -4.709336
v  14.532807 6.331904 -4.707200
v  14.533605 6.335430 -4.705266
v  14.535691 6.338274 -4.703767
v  14.538816 6.340094 -4.702885
v  14.542602 6.340672 -4.702724
v  14.546592 6.339937 -4.703306
v  14.550304 6.337977 -4.704558
v  14.553293 6.335030 -4.706331
v  14.555196 6.331450 -4.708411
v  14.555784 6.327671 -4.710546
v  14.554987 6.324146 -4.712480
v  14.552899 6.321301 -4.713978
v  14.549775 6.319480 -4.714860
v  14.545990 6.318903 -4.715022
v  14.542000 6.319640 -4.714441
v  14.543646 6.333965 -4.706513
v  14.545496 6.343750 -4.744126
v  14.535298 6.324544 -4.711415
v  14.538286 6.321598 -4.713188
v  14.534388 6.342607 -4.744601
v  14.532487 6.346255 -4.742642
v  14.531903 6.350087 -4.740599
v  14.532704 6.353641 -4.738722
v  14.534796 6.356487 -4.737235
v  14.537924 6.358284 -4.736317
v  14.541713 6.358814 -4.736081
v  14.545705 6.358013 -4.736553
v  14.549418 6.355978 -4.737677
v  14.552404 6.352952 -4.739317
v  14.554305 6.349306 -4.741277
v  14.554890 6.345474 -4.743320
v  14.554088 6.341919 -4.745198
v  14.551996 6.339074 -4.746685
v  14.548867 6.337276 -4.747602
v  14.545079 6.336747 -4.747839
v  14.541087 6.337548 -4.747366
v  14.537374 6.339582 -4.746242
v  14.555589 6.319110 -4.716490
v  14.535444 6.308361 -4.675129
v  14.534860 6.312065 -4.672863
v  14.535659 6.315540 -4.670843
v  14.537745 6.318367 -4.669310
v  14.540866 6.320204 -4.668452
v  14.544646 6.320832 -4.668371
v  14.548630 6.320173 -4.669075
v  14.552336 6.318306 -4.670483
v  14.555317 6.315459 -4.672422
v  14.557214 6.311973 -4.674660
v  14.557797 6.308269 -4.676926
v  14.556999 6.304794 -4.678947
v  14.554913 6.301968 -4.680479
v  14.551791 6.300128 -4.681338
v  14.548011 6.299501 -4.681419
v  14.544027 6.300160 -4.680714
v  14.542880 6.308136 -4.675844
v  14.544996 6.324955 -4.710060
v  14.537341 6.304874 -4.677367
v  14.540322 6.302027 -4.679307
v  14.535350 6.323885 -4.710235
v  14.533446 6.327463 -4.708151
v  14.532859 6.331241 -4.706011
v  14.533656 6.334763 -4.704076
v  14.535743 6.337606 -4.702576
v  14.538868 6.339428 -4.701694
v  14.542653 6.340008 -4.701537
v  14.546642 6.339274 -4.702121
v  14.550356 6.337318 -4.703379
v  14.553344 6.334374 -4.705157
v  14.555246 6.330798 -4.707242
v  14.555835 6.327021 -4.709382
v  14.555037 6.323497 -4.711318
v  14.552951 6.320652 -4.712817
v  14.549826 6.318831 -4.713698
v  14.546041 6.318253 -4.713856
v  14.542050 6.318985 -4.713271
v  14.538338 6.320941 -4.712013
v  14.916466 6.392617 -4.752281
v  14.915983 6.396401 -4.750128
v  14.914268 6.399775 -4.748553
v  14.911526 6.402327 -4.747746
v  14.908088 6.403753 -4.747803
v  14.904368 6.403880 -4.748719
v  14.900815 6.402690 -4.750381
v  14.897859 6.400331 -4.752591
v  14.895854 6.397085 -4.755082
v  14.895044 6.393343 -4.757552
v  14.895526 6.389559 -4.759705
v  14.897242 6.386186 -4.761280
v  14.899983 6.383633 -4.762087
v  14.903422 6.382206 -4.762030
v  14.907142 6.382081 -4.761115
v  14.910694 6.383270 -4.759451
v  14.908785 6.390159 -4.755808
v  14.901329 6.372197 -4.727284
v  14.915655 6.388875 -4.754752
v  14.913651 6.385629 -4.757242
v  14.908220 6.370944 -4.726326
v  14.909025 6.374635 -4.723777
v  14.908536 6.378366 -4.721533
v  14.906810 6.381687 -4.719862
v  14.904056 6.384198 -4.718969
v  14.900606 6.385596 -4.718958
v  14.896876 6.385713 -4.719832
v  14.893316 6.384533 -4.721488
v  14.890356 6.382202 -4.723721
v  14.888352 6.378998 -4.726266
v  14.887547 6.375308 -4.728815
v  14.888036 6.371578 -4.731059
v  14.889762 6.368257 -4.732728
v  14.892516 6.365745 -4.733623
v  14.895966 6.364347 -4.733634
v  14.899696 6.364231 -4.732759
v  14.903255 6.365408 -4.731104
v  14.906216 6.367741 -4.728871
v  14.926175 6.412603 -4.789084
v  14.925743 6.416594 -4.787327
v  14.924069 6.420126 -4.786096
v  14.921356 6.422776 -4.785539
v  14.917932 6.424222 -4.785724
v  14.914207 6.424292 -4.786628
v  14.910634 6.422976 -4.788142
v  14.907640 6.420432 -4.790084
v  14.905591 6.416970 -4.792219
v  14.904730 6.413003 -4.794291
v  14.905163 6.409014 -4.796047
v  14.906837 6.405480 -4.797278
v  14.909549 6.402831 -4.797836
v  14.912974 6.401385 -4.797651
v  14.916698 6.401316 -4.796747
v  14.920272 6.402632 -4.795233
v  14.917718 6.416631 -4.789328
v  14.902340 6.395420 -4.756425
v  14.925315 6.408638 -4.791156
v  14.923265 6.405175 -4.793290
v  14.916036 6.389733 -4.756173
v  14.916847 6.393482 -4.753714
v  14.916367 6.397274 -4.751574
v  14.914653 6.400651 -4.750010
v  14.911912 6.403210 -4.749211
v  14.908474 6.404636 -4.749274
v  14.904755 6.404762 -4.750191
v  14.901202 6.403571 -4.751852
v  14.898245 6.401206 -4.754056
v  14.896240 6.397953 -4.756536
v  14.895429 6.394205 -4.758996
v  14.895909 6.390412 -4.761136
v  14.897623 6.387033 -4.762700
v  14.900364 6.384477 -4.763498
v  14.903802 6.383049 -4.763436
v  14.907520 6.382924 -4.762518
v  14.911074 6.384115 -4.760858
v  14.914031 6.386479 -4.758654
v  14.932728 6.424965 -4.819191
v  14.932336 6.429100 -4.817793
v  14.930680 6.432713 -4.816794
v  14.927960 6.435370 -4.816313
v  14.924505 6.436752 -4.816408
v  14.920731 6.436691 -4.817070
v  14.917091 6.435193 -4.818217
v  14.914028 6.432441 -4.819711
v  14.911908 6.428766 -4.821372
v  14.910990 6.424611 -4.823000
v  14.911382 6.420476 -4.824397
v  14.913037 6.416863 -4.825397
v  14.915756 6.414206 -4.825878
v  14.919211 6.412825 -4.825782
v  14.922987 6.412887 -4.825121
v  14.926625 6.414383 -4.823974
v  14.922292 6.420221 -4.822639
v  14.915253 6.417732 -4.790861
v  14.931808 6.420812 -4.820819
v  14.929689 6.417136 -4.822479
v  14.925591 6.409152 -4.792250
v  14.926455 6.413126 -4.790195
v  14.926023 6.417121 -4.788451
v  14.924352 6.420660 -4.787232
v  14.921638 6.423312 -4.786682
v  14.918214 6.424757 -4.786868
v  14.914489 6.424823 -4.787768
v  14.910913 6.423501 -4.789274
v  14.907919 6.420950 -4.791203
v  14.905867 6.417480 -4.793324
v  14.905003 6.413506 -4.795379
v  14.905434 6.409510 -4.797121
v  14.907106 6.405972 -4.798341
v  14.909819 6.403321 -4.798891
v  14.913243 6.401875 -4.798705
v  14.916968 6.401809 -4.797805
v  14.920544 6.403131 -4.796299
v  14.923539 6.405681 -4.794370
v  14.935631 6.434532 -4.847650
v  14.935236 6.438671 -4.846271
v  14.933562 6.442233 -4.845125
v  14.930810 6.444786 -4.844351
v  14.927313 6.446023 -4.844041
v  14.923491 6.445795 -4.844234
v  14.919807 6.444129 -4.844905
v  14.916705 6.441226 -4.845973
v  14.914557 6.437438 -4.847311
v  14.913626 6.433218 -4.848757
v  14.914021 6.429079 -4.850135
v  14.915694 6.425518 -4.851281
v  14.918446 6.422965 -4.852055
v  14.921944 6.421728 -4.852365
v  14.925764 6.421955 -4.852173
v  14.929448 6.423621 -4.851501
v  14.927000 6.438063 -4.846725
v  14.923033 6.429716 -4.820250
v  14.934698 6.430313 -4.849093
v  14.932551 6.426524 -4.850433
v  14.931971 6.421154 -4.821784
v  14.932891 6.425313 -4.820168
v  14.932500 6.429449 -4.818778
v  14.930844 6.433063 -4.817781
v  14.928123 6.435719 -4.817296
v  14.924666 6.437097 -4.817384
v  14.920889 6.437030 -4.818032
v  14.917248 6.435525 -4.819162
v  14.914182 6.432766 -4.820640
v  14.912061 6.429086 -4.822286
v  14.911140 6.424927 -4.823900
v  14.911531 6.420790 -4.825291
v  14.913187 6.417176 -4.826288
v  14.915908 6.414520 -4.826772
v  14.919365 6.413143 -4.826685
v  14.923142 6.413209 -4.826038
v  14.926784 6.414713 -4.824906
v  14.929850 6.417473 -4.823429
v  14.918640 6.409587 -4.828847
v  14.933763 6.445803 -4.883835
v  14.933268 6.450012 -4.882729
v  14.931519 6.453548 -4.881621
v  14.928725 6.455985 -4.880644
v  14.925227 6.457028 -4.879916
v  14.921444 6.456552 -4.879525
v  14.917833 6.454613 -4.879519
v  14.914829 6.451446 -4.879898
v  14.912796 6.447432 -4.880616
v  14.911978 6.443057 -4.881586
v  14.912474 6.438846 -4.882692
v  14.914223 6.435309 -4.883801
v  14.917015 6.432873 -4.884778
v  14.920514 6.431829 -4.885506
v  14.924297 6.432307 -4.885896
v  14.927908 6.434246 -4.885903
v  14.923775 6.449264 -4.881639
v  14.923624 6.429533 -4.850750
v  14.932944 6.441426 -4.884806
v  14.930911 6.437413 -4.885524
v  14.934726 6.430649 -4.850094
v  14.935658 6.434867 -4.848647
v  14.935264 6.439004 -4.847261
v  14.933589 6.442562 -4.846104
v  14.930837 6.445110 -4.845313
v  14.927340 6.446341 -4.844986
v  14.923517 6.446107 -4.845161
v  14.919833 6.444437 -4.845819
v  14.916730 6.441531 -4.846877
v  14.914582 6.437740 -4.848210
v  14.913651 6.433522 -4.849657
v  14.914045 6.429385 -4.851043
v  14.915720 6.425827 -4.852200
v  14.918472 6.423280 -4.852990
v  14.921970 6.422048 -4.853317
v  14.925792 6.422282 -4.853142
v  14.929476 6.423952 -4.852486
v  14.932579 6.426857 -4.851427
v  14.925095 6.453174 -4.919871
v  14.924512 6.457417 -4.918953
v  14.922755 6.460915 -4.917736
v  14.920036 6.463243 -4.916365
v  14.916684 6.464123 -4.915008
v  14.913101 6.463446 -4.913827
v  14.909720 6.461295 -4.912965
v  14.906950 6.457930 -4.912525
v  14.905123 6.453755 -4.912561
v  14.904461 6.449276 -4.913069
v  14.905044 6.445033 -4.913988
v  14.906800 6.441535 -4.915205
v  14.909519 6.439208 -4.916574
v  14.912871 6.438329 -4.917932
v  14.916454 6.439004 -4.919113
v  14.919836 6.441155 -4.919976
v  14.917782 6.448653 -4.917984
v  14.921805 6.439886 -4.884975
v  14.924433 6.448695 -4.920378
v  14.922606 6.444520 -4.920414
v  14.932773 6.441742 -4.886070
v  14.933585 6.446124 -4.885119
v  14.933085 6.450336 -4.884025
v  14.931334 6.453873 -4.882919
v  14.928540 6.456305 -4.881934
v  14.925043 6.457342 -4.881189
v  14.921264 6.456857 -4.880774
v  14.917659 6.454909 -4.880740
v  14.914661 6.451733 -4.881089
v  14.912634 6.447711 -4.881780
v  14.911821 6.443330 -4.882730
v  14.912321 6.439116 -4.883825
v  14.914073 6.435582 -4.884931
v  14.916866 6.433148 -4.885916
v  14.920362 6.432112 -4.886661
v  14.924142 6.432597 -4.887075
v  14.927748 6.434545 -4.887110
v  14.930745 6.437722 -4.886761
v  14.924477 6.428972 -4.888976
v  14.908676 6.458435 -4.952303
v  14.908158 6.462656 -4.951251
v  14.906602 6.466085 -4.949635
v  14.904195 6.468309 -4.947647
v  14.901226 6.469059 -4.945530
v  14.898055 6.468245 -4.943537
v  14.895063 6.465965 -4.941909
v  14.892613 6.462492 -4.940843
v  14.890999 6.458249 -4.940466
v  14.890415 6.453744 -4.940825
v  14.890933 6.449522 -4.941877
v  14.892489 6.446094 -4.943494
v  14.894897 6.443870 -4.945481
v  14.897865 6.443120 -4.947598
v  14.901036 6.443934 -4.949592
v  14.904028 6.446216 -4.951220
v  14.898901 6.451113 -4.946962
v  14.913651 6.446455 -4.918115
v  14.908092 6.453930 -4.952662
v  14.906478 6.449687 -4.952286
v  14.924006 6.448891 -4.921547
v  14.924664 6.453371 -4.921049
v  14.924080 6.457614 -4.920132
v  14.922327 6.461111 -4.918904
v  14.919615 6.463436 -4.917516
v  14.916270 6.464310 -4.916133
v  14.912698 6.463628 -4.914923
v  14.909328 6.461471 -4.914032
v  14.906568 6.458102 -4.913567
v  14.904749 6.453925 -4.913584
v  14.904092 6.449444 -4.914082
v  14.904675 6.445199 -4.914999
v  14.906428 6.441705 -4.916225
v  14.909141 6.439380 -4.917615
v  14.912484 6.438506 -4.918998
v  14.916057 6.439187 -4.920208
v  14.919427 6.441344 -4.921099
v  14.922187 6.444713 -4.921564
v  14.898067 6.471976 -4.941854
v  14.885375 6.462497 -4.980363
v  14.884841 6.466718 -4.979317
v  14.883444 6.470114 -4.977496
v  14.881352 6.472275 -4.975121
v  14.878819 6.472940 -4.972476
v  14.876146 6.472032 -4.969883
v  14.873661 6.469656 -4.967652
v  14.871660 6.466101 -4.966054
v  14.870386 6.461795 -4.965281
v  14.869992 6.457259 -4.965425
v  14.870525 6.453038 -4.966473
v  14.871922 6.449642 -4.968292
v  14.874015 6.447481 -4.970669
v  14.876549 6.446815 -4.973311
v  14.879220 6.447725 -4.975905
v  14.881706 6.450099 -4.978137
v  14.876276 6.455120 -4.972040
v  14.897142 6.451551 -4.947065
v  14.884981 6.457961 -4.980508
v  14.883707 6.453655 -4.979734
v  14.907423 6.454096 -4.953651
v  14.908001 6.458601 -4.953301
v  14.907482 6.462823 -4.952250
v  14.905930 6.466250 -4.950625
v  14.903531 6.468472 -4.948625
v  14.900577 6.469219 -4.946487
v  14.897421 6.468400 -4.944471
v  14.894445 6.466115 -4.942822
v  14.892009 6.462640 -4.941734
v  14.890406 6.458393 -4.941343
v  14.889829 6.453887 -4.941693
v  14.890347 6.449666 -4.942744
v  14.891899 6.446238 -4.944368
v  14.894298 6.444016 -4.946370
v  14.897253 6.443272 -4.948507
v  14.900409 6.444089 -4.950522
v  14.903384 6.446373 -4.952173
v  14.905820 6.449850 -4.953260
v  14.855227 6.464757 -5.004526
v  14.854710 6.468976 -5.003464
v  14.853551 6.472353 -5.001451
v  14.851891 6.474481 -4.998730
v  14.849928 6.475101 -4.995627
v  14.847901 6.474142 -4.992519
v  14.846054 6.471716 -4.989779
v  14.844609 6.468120 -4.987739
v  14.843741 6.463783 -4.986644
v  14.843554 6.459230 -4.986627
v  14.844072 6.455012 -4.987688
v  14.845231 6.451634 -4.989701
v  14.846891 6.449509 -4.992423
v  14.848853 6.448886 -4.995524
v  14.850880 6.449847 -4.998633
v  14.852727 6.452271 -5.001371
v  14.849597 6.467025 -4.995596
v  14.877242 6.464993 -4.973595
v  14.855041 6.460206 -5.004508
v  14.854172 6.455869 -5.003413
v  14.884063 6.458067 -4.981410
v  14.884450 6.462605 -4.981272
v  14.883917 6.466825 -4.980225
v  14.882526 6.470221 -4.978398
v  14.880446 6.472381 -4.976010
v  14.877930 6.473044 -4.973349
v  14.875278 6.472133 -4.970736
v  14.872811 6.469755 -4.968487
v  14.870828 6.466197 -4.966872
v  14.869565 6.461891 -4.966086
v  14.869178 6.457353 -4.966226
v  14.869712 6.453133 -4.967272
v  14.871102 6.449738 -4.969100
v  14.873181 6.447577 -4.971488
v  14.875699 6.446915 -4.974149
v  14.878350 6.447825 -4.976761
v  14.880817 6.450203 -4.979011
v  14.882801 6.453761 -4.980625
v  14.820841 6.465010 -5.021433
v  14.820322 6.469223 -5.020349
v  14.819434 6.472587 -5.018183
v  14.818286 6.474697 -5.015197
v  14.817015 6.475297 -5.011751
v  14.815774 6.474318 -5.008258
v  14.814713 6.471876 -5.005144
v  14.813961 6.468264 -5.002780
v  14.813607 6.463919 -5.001456
v  14.813696 6.459364 -5.001327
v  14.814215 6.455152 -5.002411
v  14.815102 6.451787 -5.004577
v  14.816251 6.449677 -5.007563
v  14.817522 6.449077 -5.011010
v  14.818763 6.450056 -5.014501
v  14.819822 6.452499 -5.017617
v  14.816877 6.457386 -5.009916
v  14.848122 6.457004 -4.996230
v  14.820929 6.460455 -5.021305
v  14.820575 6.456111 -5.019979
v  14.853904 6.460248 -5.005228
v  14.854083 6.464801 -5.005250
v  14.853566 6.469019 -5.004188
v  14.852418 6.472395 -5.002169
v  14.850775 6.474523 -4.999435
v  14.848836 6.475142 -4.996319
v  14.846834 6.474183 -4.993195
v  14.845012 6.471756 -4.990440
v  14.843589 6.468158 -4.988386
v  14.842736 6.463820 -4.987280
v  14.842557 6.459268 -4.987257
v  14.843073 6.455049 -4.988321
v  14.844222 6.451673 -4.990340
v  14.845866 6.449547 -4.993072
v  14.847805 6.448925 -4.996189
v  14.849806 6.449887 -4.999314
v  14.851628 6.452312 -5.002069
v  14.853052 6.455911 -5.004123
v  14.785066 6.463116 -5.031497
v  14.784525 6.467319 -5.030387
v  14.783805 6.470675 -5.028147
v  14.782993 6.472779 -5.025048
v  14.782187 6.473376 -5.021464
v  14.781484 6.472395 -5.017825
v  14.780970 6.469954 -5.014573
v  14.780704 6.466350 -5.012098
v  14.780721 6.462013 -5.010701
v  14.781017 6.457469 -5.010547
v  14.781559 6.453266 -5.011658
v  14.782278 6.449911 -5.013898
v  14.783090 6.447807 -5.016998
v  14.783896 6.447208 -5.020583
v  14.784599 6.448190 -5.024220
v  14.785114 6.450630 -5.027473
v  14.782713 6.465313 -5.021191
v  14.815392 6.458163 -5.009148
v  14.785363 6.458571 -5.031345
v  14.785379 6.454236 -5.029947
v  14.819808 6.460426 -5.021711
v  14.819710 6.464980 -5.021840
v  14.819187 6.469192 -5.020755
v  14.818305 6.472556 -5.018586
v  14.817168 6.474665 -5.015594
v  14.815915 6.475265 -5.012141
v  14.814696 6.474284 -5.008642
v  14.813659 6.471839 -5.005519
v  14.812927 6.468226 -5.003150
v  14.812591 6.463882 -5.001821
v  14.812690 6.459330 -5.001690
v  14.813212 6.455117 -5.002776
v  14.814095 6.451754 -5.004944
v  14.815231 6.449644 -5.007936
v  14.816484 6.449045 -5.011390
v  14.817703 6.450025 -5.014889
v  14.818741 6.452470 -5.018011
v  14.819471 6.456081 -5.020380
v  14.746387 6.460063 -5.037199
v  14.745948 6.464272 -5.036064
v  14.745485 6.467643 -5.033779
v  14.745053 6.469768 -5.030618
v  14.744706 6.470393 -5.026966
v  14.744485 6.469440 -5.023259
v  14.744415 6.467028 -5.019948
v  14.744508 6.463444 -5.017429
v  14.744749 6.459122 -5.016009
v  14.745111 6.454583 -5.015856
v  14.745551 6.450374 -5.016992
v  14.746014 6.447002 -5.019278
v  14.746446 6.444878 -5.022438
v  14.746793 6.444252 -5.026091
v  14.747014 6.445205 -5.029797
v  14.747084 6.447618 -5.033109
v  14.746150 6.452308 -5.026360
v  14.781453 6.465221 -5.021458
v  14.746750 6.455524 -5.037048
v  14.746992 6.451202 -5.035627
v  14.784064 6.458478 -5.031621
v  14.783763 6.463023 -5.031773
v  14.783224 6.467226 -5.030662
v  14.782511 6.470582 -5.028420
v  14.781711 6.472686 -5.025318
v  14.780920 6.473284 -5.021730
v  14.780232 6.472303 -5.018088
v  14.779734 6.469865 -5.014833
v  14.779482 6.466259 -5.012356
v  14.779508 6.461924 -5.010957
v  14.779809 6.457380 -5.010805
v  14.780348 6.453176 -5.011916
v  14.781061 6.449820 -5.014158
v  14.781861 6.447717 -5.017260
v  14.782653 6.447119 -5.020848
v  14.783340 6.448099 -5.024489
v  14.783838 6.450538 -5.027745
v  14.784090 6.454143 -5.030221
v  14.709190 6.457202 -5.037283
v  14.709015 6.461431 -5.036149
v  14.709001 6.464835 -5.033865
v  14.709149 6.467002 -5.030709
v  14.709442 6.467675 -5.027059
v  14.709845 6.466769 -5.023356
v  14.710307 6.464394 -5.020048
v  14.710774 6.460838 -5.017533
v  14.711190 6.456529 -5.016114
v  14.711505 6.451986 -5.015962
v  14.711679 6.447758 -5.017097
v  14.711694 6.444354 -5.019380
v  14.711546 6.442185 -5.022537
v  14.711253 6.441514 -5.026186
v  14.710851 6.442420 -5.029890
v  14.710388 6.444795 -5.033199
v  14.710155 6.459267 -5.025369
v  14.744203 6.461994 -5.028197
v  14.709504 6.452660 -5.037132
v  14.709920 6.448350 -5.035713
v  14.745426 6.455416 -5.037150
v  14.745064 6.459955 -5.037302
v  14.744630 6.464164 -5.036167
v  14.744178 6.467535 -5.033880
v  14.743763 6.469662 -5.030718
v  14.743433 6.470288 -5.027063
v  14.743228 6.469337 -5.023356
v  14.743176 6.466925 -5.020043
v  14.743279 6.463342 -5.017524
v  14.743526 6.459021 -5.016103
v  14.743890 6.454481 -5.015952
v  14.744323 6.450272 -5.017087
v  14.744776 6.446901 -5.019374
v  14.745192 6.444774 -5.022536
v  14.745522 6.444148 -5.026190
v  14.745725 6.445098 -5.029898
v  14.745778 6.447511 -5.033211
v  14.745675 6.451094 -5.035731
v  14.777691 6.064299 -4.446094
v  14.776333 6.066365 -4.442476
v  14.774115 6.068716 -4.439956
v  14.771304 6.071070 -4.438838
v  14.768240 6.073142 -4.439256
v  14.765292 6.074683 -4.441159
v  14.762815 6.075507 -4.444320
v  14.761109 6.075514 -4.448355
v  14.760380 6.074704 -4.452779
v  14.760714 6.073174 -4.457057
v  14.762072 6.071107 -4.460674
v  14.764290 6.068757 -4.463194
v  14.767100 6.066403 -4.464313
v  14.770164 6.064331 -4.463895
v  14.773112 6.062789 -4.461991
v  14.775588 6.061965 -4.458831
v  14.765816 6.071027 -4.452037
v  14.749556 6.052722 -4.442210
v  14.778025 6.062768 -4.450372
v  14.777295 6.061959 -4.454795
v  14.757504 6.041861 -4.443350
v  14.756970 6.043613 -4.439178
v  14.755712 6.046130 -4.435820
v  14.753879 6.049107 -4.433681
v  14.751691 6.052183 -4.433017
v  14.749413 6.054988 -4.433909
v  14.747322 6.057187 -4.436252
v  14.745667 6.058511 -4.439760
v  14.744649 6.058801 -4.444011
v  14.744391 6.058022 -4.448493
v  14.744924 6.056270 -4.452664
v  14.746183 6.053752 -4.456022
v  14.748017 6.050777 -4.458162
v  14.750204 6.047700 -4.458826
v  14.752481 6.044894 -4.457932
v  14.754573 6.042696 -4.455590
v  14.756227 6.041373 -4.452083
v  14.757245 6.041082 -4.447831
v  14.767513 6.073988 -4.435188
v  14.793602 6.090837 -4.454667
v  14.792268 6.092726 -4.450946
v  14.789960 6.094776 -4.448250
v  14.786956 6.096739 -4.446904
v  14.783619 6.098381 -4.447072
v  14.780352 6.099502 -4.448731
v  14.777546 6.099966 -4.451684
v  14.775543 6.099719 -4.455572
v  14.774583 6.098790 -4.459929
v  14.774782 6.097290 -4.464226
v  14.776117 6.095402 -4.467947
v  14.778425 6.093352 -4.470643
v  14.781428 6.091388 -4.471989
v  14.784766 6.089746 -4.471822
v  14.788034 6.088626 -4.470161
v  14.790839 6.088161 -4.467210
v  14.785666 6.091977 -4.463558
v  14.773005 6.067796 -4.449707
v  14.793801 6.089338 -4.458965
v  14.792842 6.088408 -4.463321
v  14.778596 6.063569 -4.450629
v  14.778267 6.065094 -4.446348
v  14.776907 6.067147 -4.442725
v  14.774682 6.069482 -4.440196
v  14.771860 6.071815 -4.439066
v  14.768781 6.073869 -4.439472
v  14.765817 6.075392 -4.441365
v  14.763325 6.076201 -4.444516
v  14.761604 6.076200 -4.448545
v  14.760865 6.075389 -4.452968
v  14.761195 6.073864 -4.457248
v  14.762554 6.071812 -4.460872
v  14.764779 6.069477 -4.463401
v  14.767601 6.067142 -4.464530
v  14.770680 6.065090 -4.464125
v  14.773644 6.063567 -4.462232
v  14.776137 6.062756 -4.459081
v  14.777857 6.062757 -4.455050
v  14.808207 6.122655 -4.465992
v  14.806953 6.124599 -4.462272
v  14.804665 6.126606 -4.459526
v  14.801618 6.128431 -4.458087
v  14.798179 6.129857 -4.458128
v  14.794764 6.130711 -4.459644
v  14.791784 6.130889 -4.462453
v  14.789599 6.130372 -4.466214
v  14.788472 6.129220 -4.470476
v  14.788540 6.127572 -4.474723
v  14.789794 6.125628 -4.478444
v  14.792082 6.123622 -4.481190
v  14.795129 6.121797 -4.482629
v  14.798568 6.120370 -4.482587
v  14.801982 6.119517 -4.481071
v  14.804962 6.119338 -4.478263
v  14.796988 6.127262 -4.466247
v  14.782140 6.097309 -4.456785
v  14.808274 6.121007 -4.470240
v  14.807147 6.119855 -4.474501
v  14.794315 6.090353 -4.459304
v  14.794121 6.091855 -4.455007
v  14.792789 6.093743 -4.451283
v  14.790481 6.095788 -4.448585
v  14.787474 6.097743 -4.447234
v  14.784131 6.099374 -4.447395
v  14.780856 6.100483 -4.449049
v  14.778044 6.100938 -4.451996
v  14.776033 6.100681 -4.455879
v  14.775067 6.099746 -4.460233
v  14.775262 6.098245 -4.464530
v  14.776593 6.096356 -4.468252
v  14.778902 6.094312 -4.470952
v  14.781909 6.092356 -4.472302
v  14.785252 6.090724 -4.472140
v  14.788527 6.089616 -4.470488
v  14.791339 6.089161 -4.467541
v  14.793349 6.089417 -4.463656
v  14.821950 6.157113 -4.480635
v  14.820803 6.159266 -4.476995
v  14.818581 6.161395 -4.474288
v  14.815551 6.163240 -4.472839
v  14.812078 6.164582 -4.472822
v  14.808581 6.165255 -4.474240
v  14.805483 6.165182 -4.476922
v  14.803157 6.164369 -4.480544
v  14.801882 6.162914 -4.484670
v  14.801814 6.160995 -4.488801
v  14.802960 6.158841 -4.492439
v  14.805182 6.156712 -4.495147
v  14.808212 6.154867 -4.496596
v  14.811686 6.153526 -4.496614
v  14.815182 6.152852 -4.495194
v  14.818280 6.152925 -4.492514
v  14.815719 6.157572 -4.484736
v  14.797465 6.128398 -4.466681
v  14.821881 6.155193 -4.484766
v  14.820607 6.153738 -4.488892
v  14.808753 6.122154 -4.470680
v  14.808690 6.123807 -4.466435
v  14.807440 6.125757 -4.462717
v  14.805153 6.127765 -4.459971
v  14.802106 6.129589 -4.458530
v  14.798665 6.131010 -4.458569
v  14.795247 6.131856 -4.460081
v  14.792263 6.132027 -4.462885
v  14.790072 6.131499 -4.466641
v  14.788940 6.130338 -4.470901
v  14.789003 6.128683 -4.475143
v  14.790254 6.126735 -4.478864
v  14.792540 6.124727 -4.481609
v  14.795588 6.122902 -4.483050
v  14.799028 6.121480 -4.483012
v  14.802446 6.120634 -4.481500
v  14.805431 6.120465 -4.478696
v  14.807621 6.120993 -4.474939
v  14.835074 6.191480 -4.499076
v  14.834070 6.193984 -4.495624
v  14.831955 6.196374 -4.493053
v  14.828983 6.198365 -4.491676
v  14.825514 6.199715 -4.491657
v  14.821965 6.200261 -4.492998
v  14.818765 6.199936 -4.495539
v  14.816299 6.198781 -4.498971
v  14.814866 6.196936 -4.502882
v  14.814637 6.194621 -4.506800
v  14.815641 6.192117 -4.510252
v  14.817758 6.189725 -4.512821
v  14.820728 6.187735 -4.514199
v  14.824198 6.186386 -4.514218
v  14.827746 6.185841 -4.512877
v  14.830947 6.186164 -4.510337
v  14.823747 6.195817 -4.499124
v  14.812415 6.162368 -4.480717
v  14.834846 6.189165 -4.502994
v  14.833412 6.187319 -4.506905
v  14.822335 6.156377 -4.485327
v  14.822409 6.158308 -4.481201
v  14.821266 6.160471 -4.477567
v  14.819047 6.162607 -4.474862
v  14.816018 6.164454 -4.473413
v  14.812545 6.165795 -4.473396
v  14.809047 6.166464 -4.474811
v  14.805944 6.166380 -4.477489
v  14.803613 6.165556 -4.481105
v  14.802334 6.164092 -4.485225
v  14.802260 6.162159 -4.489351
v  14.803403 6.159996 -4.492985
v  14.805622 6.157862 -4.495688
v  14.808651 6.156013 -4.497138
v  14.812124 6.154673 -4.497156
v  14.815622 6.154005 -4.495741
v  14.818725 6.154087 -4.493063
v  14.821055 6.154911 -4.489447
v  14.847762 6.222969 -4.521765
v  14.846959 6.225966 -4.518672
v  14.845007 6.228759 -4.516394
v  14.842141 6.231009 -4.515205
v  14.838708 6.232448 -4.515251
v  14.835121 6.232901 -4.516524
v  14.831813 6.232311 -4.518871
v  14.829183 6.230752 -4.522007
v  14.827548 6.228413 -4.525559
v  14.827106 6.225572 -4.529093
v  14.827909 6.222575 -4.532187
v  14.829861 6.219783 -4.534464
v  14.832726 6.217532 -4.535653
v  14.836160 6.216093 -4.535608
v  14.839746 6.215641 -4.534336
v  14.843055 6.216229 -4.531989
v  14.836546 6.227582 -4.522011
v  14.829125 6.192689 -4.503655
v  14.847320 6.220129 -4.525300
v  14.845685 6.217789 -4.528851
v  14.835280 6.190291 -4.503689
v  14.835516 6.192622 -4.499781
v  14.834517 6.195141 -4.496338
v  14.832406 6.197544 -4.493775
v  14.829437 6.199541 -4.492401
v  14.825969 6.200891 -4.492383
v  14.822418 6.201435 -4.493722
v  14.819215 6.201102 -4.496257
v  14.816745 6.199934 -4.499681
v  14.815305 6.198072 -4.503582
v  14.815070 6.195743 -4.507490
v  14.816069 6.193223 -4.510934
v  14.818179 6.190821 -4.513497
v  14.821148 6.188824 -4.514870
v  14.824617 6.187471 -4.514888
v  14.828167 6.186931 -4.513550
v  14.831370 6.187263 -4.511015
v  14.833841 6.188431 -4.507590
v  14.857738 6.246140 -4.544374
v  14.857069 6.249442 -4.541573
v  14.855214 6.252455 -4.539503
v  14.852397 6.254815 -4.538416
v  14.848957 6.256238 -4.538441
v  14.845310 6.256554 -4.539576
v  14.841895 6.255722 -4.541684
v  14.839124 6.253844 -4.544510
v  14.837331 6.251147 -4.547714
v  14.836733 6.247953 -4.550909
v  14.837401 6.244653 -4.553710
v  14.839256 6.241640 -4.555779
v  14.842072 6.239279 -4.556867
v  14.845512 6.237855 -4.556842
v  14.849159 6.237540 -4.555707
v  14.852574 6.238372 -4.553599
v  14.847974 6.243399 -4.550736
v  14.836981 6.228590 -4.522881
v  14.857139 6.242948 -4.547569
v  14.855346 6.240250 -4.550773
v  14.847740 6.221104 -4.526140
v  14.848191 6.223964 -4.522622
v  14.847396 6.226980 -4.519546
v  14.845451 6.229789 -4.517282
v  14.842590 6.232050 -4.516103
v  14.839159 6.233493 -4.516151
v  14.835570 6.233942 -4.517422
v  14.832258 6.233344 -4.519761
v  14.829622 6.231770 -4.522886
v  14.827979 6.229412 -4.526421
v  14.827528 6.226552 -4.529938
v  14.828322 6.223535 -4.533014
v  14.830268 6.220726 -4.535278
v  14.833129 6.218465 -4.536457
v  14.836560 6.217021 -4.536408
v  14.840148 6.216573 -4.535138
v  14.843460 6.217171 -4.532800
v  14.846097 6.218745 -4.529675
v  14.867291 6.269181 -4.571110
v  14.866693 6.272647 -4.568499
v  14.864889 6.275775 -4.566561
v  14.862095 6.278189 -4.565531
v  14.858647 6.279593 -4.565533
v  14.854963 6.279823 -4.566566
v  14.851487 6.278848 -4.568505
v  14.848638 6.276786 -4.571117
v  14.846759 6.273887 -4.574087
v  14.846077 6.270500 -4.577057
v  14.846675 6.267033 -4.579668
v  14.848479 6.263905 -4.581606
v  14.851274 6.261493 -4.582636
v  14.854721 6.260087 -4.582634
v  14.858405 6.259858 -4.581601
v  14.861881 6.260833 -4.579662
v  14.859833 6.272118 -4.571197
v  14.848295 6.244163 -4.551561
v  14.866610 6.265793 -4.574080
v  14.864731 6.262894 -4.577049
v  14.857465 6.243721 -4.548406
v  14.858068 6.246922 -4.545218
v  14.857402 6.250232 -4.542426
v  14.855549 6.253250 -4.540362
v  14.852734 6.255613 -4.539277
v  14.849294 6.257035 -4.539301
v  14.845645 6.257347 -4.540432
v  14.842226 6.256507 -4.542533
v  14.839452 6.254622 -4.545350
v  14.837655 6.251915 -4.548545
v  14.837053 6.248714 -4.551730
v  14.837719 6.245407 -4.554523
v  14.839571 6.242389 -4.556588
v  14.842387 6.240026 -4.557674
v  14.845827 6.238603 -4.557649
v  14.849476 6.238292 -4.556519
v  14.852894 6.239130 -4.554418
v  14.855669 6.241014 -4.551600
v  14.876611 6.292288 -4.601068
v  14.876057 6.295858 -4.598591
v  14.874283 6.299061 -4.596747
v  14.871502 6.301507 -4.595759
v  14.868051 6.302902 -4.595746
v  14.864346 6.303079 -4.596708
v  14.860832 6.302013 -4.598531
v  14.857935 6.299838 -4.600993
v  14.856003 6.296813 -4.603799
v  14.855269 6.293302 -4.606609
v  14.855824 6.289731 -4.609086
v  14.857598 6.286529 -4.610929
v  14.860379 6.284082 -4.611917
v  14.863831 6.282688 -4.611931
v  14.867536 6.282512 -4.610969
v  14.871049 6.283576 -4.609146
v  14.865328 6.296741 -4.601102
v  14.856347 6.274464 -4.572178
v  14.875878 6.288778 -4.603878
v  14.873946 6.285752 -4.606684
v  14.866932 6.266582 -4.575055
v  14.867616 6.269975 -4.572092
v  14.867020 6.273446 -4.569486
v  14.865216 6.276577 -4.567553
v  14.862422 6.278991 -4.566524
v  14.858974 6.280396 -4.566525
v  14.855290 6.280622 -4.567554
v  14.851812 6.279644 -4.569489
v  14.848960 6.277577 -4.572096
v  14.847079 6.274673 -4.575060
v  14.846395 6.271281 -4.578022
v  14.846992 6.267809 -4.580628
v  14.848795 6.264678 -4.582563
v  14.851589 6.262265 -4.583591
v  14.855036 6.260859 -4.583590
v  14.858721 6.260633 -4.582560
v  14.862199 6.261611 -4.580625
v  14.865050 6.263678 -4.578018
v  14.885553 6.314881 -4.632700
v  14.885028 6.318521 -4.630321
v  14.883274 6.321773 -4.628546
v  14.880503 6.324242 -4.627589
v  14.877049 6.325632 -4.627567
v  14.873330 6.325775 -4.628482
v  14.869793 6.324653 -4.630222
v  14.866865 6.322402 -4.632579
v  14.864901 6.319293 -4.635269
v  14.864134 6.315701 -4.637965
v  14.864660 6.312062 -4.640345
v  14.866414 6.308811 -4.642120
v  14.869184 6.306341 -4.643076
v  14.872638 6.304951 -4.643099
v  14.876357 6.304809 -4.642184
v  14.879894 6.305930 -4.640444
v  14.872906 6.318883 -4.633371
v  14.865642 6.297527 -4.602167
v  14.884788 6.311290 -4.635398
v  14.882822 6.308181 -4.638086
v  14.876190 6.289561 -4.604939
v  14.876925 6.293074 -4.602134
v  14.876371 6.296648 -4.599661
v  14.874599 6.299851 -4.597820
v  14.871819 6.302299 -4.596834
v  14.868367 6.303694 -4.596820
v  14.864660 6.303869 -4.597780
v  14.861146 6.302802 -4.599599
v  14.858248 6.300622 -4.602057
v  14.856315 6.297594 -4.604859
v  14.855580 6.294081 -4.607665
v  14.856133 6.290505 -4.610137
v  14.857906 6.287302 -4.611978
v  14.860686 6.284856 -4.612965
v  14.864138 6.283461 -4.612979
v  14.867845 6.283286 -4.612019
v  14.871359 6.284352 -4.610199
v  14.874258 6.286530 -4.607741
v  14.893967 6.336357 -4.664446
v  14.893459 6.340044 -4.662136
v  14.891719 6.343329 -4.660410
v  14.888955 6.345817 -4.659479
v  14.885501 6.347205 -4.659453
v  14.881774 6.347328 -4.660337
v  14.878222 6.346169 -4.662023
v  14.875276 6.343870 -4.664309
v  14.873288 6.340708 -4.666917
v  14.872500 6.337061 -4.669535
v  14.873008 6.333375 -4.671846
v  14.874747 6.330090 -4.673572
v  14.877511 6.327604 -4.674502
v  14.880965 6.326215 -4.674529
v  14.884693 6.326093 -4.673645
v  14.888245 6.327250 -4.671958
v  14.884104 6.340736 -4.664098
v  14.875721 6.312022 -4.639051
v  14.893179 6.332713 -4.667064
v  14.891191 6.329550 -4.669673
v  14.885085 6.312045 -4.636489
v  14.885851 6.315639 -4.633795
v  14.885326 6.319281 -4.631420
v  14.883573 6.322534 -4.629646
v  14.880802 6.325004 -4.628690
v  14.877349 6.326394 -4.628668
v  14.873630 6.326536 -4.629581
v  14.870092 6.325413 -4.631320
v  14.867163 6.323160 -4.633674
v  14.865197 6.320049 -4.636360
v  14.864430 6.316454 -4.639054
v  14.864955 6.312813 -4.641430
v  14.866709 6.309560 -4.643203
v  14.869479 6.307090 -4.644159
v  14.872932 6.305700 -4.644182
v  14.876653 6.305559 -4.643268
v  14.880190 6.306682 -4.641530
v  14.883119 6.308935 -4.639177
v  14.901692 6.356103 -4.694736
v  14.901197 6.359821 -4.692472
v  14.899466 6.363129 -4.690781
v  14.896708 6.365629 -4.689869
v  14.893254 6.367019 -4.689846
v  14.889524 6.367132 -4.690715
v  14.885964 6.365953 -4.692372
v  14.883006 6.363627 -4.694614
v  14.881005 6.360433 -4.697174
v  14.880205 6.356756 -4.699742
v  14.880700 6.353039 -4.702007
v  14.882432 6.349730 -4.703697
v  14.885190 6.347230 -4.704610
v  14.888643 6.345840 -4.704632
v  14.892374 6.345726 -4.703763
v  14.895934 6.346905 -4.702106
v  14.890402 6.360537 -4.694736
v  14.882950 6.341494 -4.665509
v  14.900892 6.352426 -4.697304
v  14.898891 6.349232 -4.699864
v  14.893456 6.333420 -4.668134
v  14.894243 6.337066 -4.665519
v  14.893737 6.340755 -4.663210
v  14.891996 6.344040 -4.661486
v  14.889234 6.346528 -4.660555
v  14.885779 6.347917 -4.660529
v  14.882051 6.348038 -4.661412
v  14.878500 6.346879 -4.663097
v  14.875552 6.344579 -4.665380
v  14.873565 6.341414 -4.667987
v  14.872776 6.337768 -4.670603
v  14.873282 6.334080 -4.672912
v  14.875023 6.330793 -4.674636
v  14.877787 6.328307 -4.675567
v  14.881241 6.326918 -4.675591
v  14.884968 6.326796 -4.674709
v  14.888520 6.327956 -4.673024
v  14.891467 6.330256 -4.670740
v  14.908570 6.373497 -4.721986
v  14.908080 6.377228 -4.719741
v  14.906354 6.380548 -4.718070
v  14.903600 6.383059 -4.717175
v  14.900149 6.384457 -4.717163
v  14.896420 6.384572 -4.718037
v  14.892859 6.383393 -4.719689
v  14.889898 6.381061 -4.721923
v  14.887895 6.377856 -4.724467
v  14.887089 6.374166 -4.727015
v  14.887579 6.370436 -4.729261
v  14.889305 6.367115 -4.730931
v  14.892059 6.364604 -4.731827
v  14.895510 6.363206 -4.731838
v  14.899240 6.363091 -4.730965
v  14.902800 6.364271 -4.729311
v  14.897289 6.377953 -4.722020
v  14.892084 6.361133 -4.695394
v  14.907764 6.369808 -4.724534
v  14.905761 6.366602 -4.727077
v  14.901142 6.353064 -4.698295
v  14.901943 6.356742 -4.695728
v  14.901448 6.360461 -4.693463
v  14.899717 6.363769 -4.691773
v  14.896959 6.366269 -4.690863
v  14.893506 6.367660 -4.690841
v  14.889774 6.367772 -4.691710
v  14.886215 6.366595 -4.693365
v  14.883257 6.364268 -4.695607
v  14.881256 6.361072 -4.698165
v  14.880455 6.357394 -4.700731
v  14.880950 6.353677 -4.702996
v  14.882681 6.350368 -4.704686
v  14.885440 6.347867 -4.705597
v  14.888893 6.346477 -4.705619
v  14.892624 6.346364 -4.704750
v  14.896184 6.347542 -4.703094
v  14.899142 6.349869 -4.700852
v  14.891113 6.385997 -4.717494
v  14.793831 6.088076 -4.470263
v  14.540504 6.352593 -4.784175
v  14.915458 6.438231 -4.816570
v  14.896820 6.332697 -4.668982
v  14.778741 6.100471 -4.445095
v  14.558267 6.383090 -4.839884
v  14.912928 6.381670 -4.763351
v  14.614673 6.130001 -4.493970
v  14.820154 6.187953 -4.518945
v  14.873216 6.282027 -4.612691
v  14.550161 6.407676 -4.825573
v  14.854654 6.258358 -4.587030
v  14.588396 6.184477 -4.555039
v  14.540332 6.335394 -4.751132
v  14.916530 6.420064 -4.854929
v  14.807878 6.119503 -4.481539
v  14.587440 6.148312 -4.534534
v  14.575027 6.413071 -4.909398
v  14.595479 6.117566 -4.509269
v  14.845519 6.236255 -4.561178
v  14.556101 6.236518 -4.597357
v  14.560779 6.279344 -4.650340
v  14.825956 6.158625 -4.480353
v  14.557566 6.299887 -4.683115
v  14.845544 6.215320 -4.535486
v  14.745366 6.459769 -5.012598
v  14.564989 6.259382 -4.621973
v  14.624505 6.443032 -4.979700
v  14.786065 6.444424 -5.025023
v  14.911951 6.379858 -4.720680
v  14.636663 6.095741 -4.501438
v  14.882017 6.304293 -4.643985
v  14.677882 6.440922 -5.011008
v  14.712948 6.441164 -5.017159
v  14.715803 6.048971 -4.443089
v  14.844672 6.448323 -4.987350
v  14.815171 6.448517 -5.002140
v  14.648029 6.441437 -5.000268
v  14.604203 6.428698 -4.963653
v  14.870714 6.446325 -4.966221
v  14.595181 6.161693 -4.506622
v  14.807173 6.166282 -4.470479
v  14.581130 6.195494 -4.525177
v  14.820694 6.201626 -4.489286
v  14.621601 6.087502 -4.508363
v  14.568660 6.228374 -4.547369
v  14.789325 6.131844 -4.459617
v  14.847059 6.229371 -4.512839
v  14.908886 6.425784 -4.786128
v  14.867961 6.327031 -4.627753
v  14.863595 6.305724 -4.593619
v  14.876419 6.348616 -4.659618
v  14.542368 6.425098 -4.866551
v  14.568150 6.256151 -4.578670
v  14.849587 6.281085 -4.565976
v  14.553749 6.440026 -4.904394
v  14.767306 6.066978 -4.468735
v  14.884189 6.368475 -4.690056
v  14.930238 6.460223 -4.882316
v  14.542156 6.342940 -4.698664
v  14.628258 6.070330 -4.468719
v  14.903626 6.406637 -4.745599
v  14.558424 6.315175 -4.668379
v  14.541190 6.361828 -4.733309
v  14.916276 6.435453 -4.921888
v  14.853770 6.256656 -4.534823
v  14.538662 6.298059 -4.631235
v  14.555404 6.371360 -4.770687
v  14.537615 6.394661 -4.798591
v  14.746421 6.470996 -5.035992
v  14.821004 6.475904 -5.020021
v  14.690206 6.022023 -4.461694
v  14.593014 6.193247 -4.536567
v  14.921129 6.460152 -4.877539
v  14.912661 6.398617 -4.801379
v  14.885985 6.473272 -4.978244
v  14.855756 6.475602 -5.002742
v  14.572262 6.452318 -4.934517
v  14.741869 6.473099 -5.022391
v  14.665804 6.046688 -4.483434
v  14.794905 6.122693 -4.487261
v  14.637961 6.467158 -5.018733
v  14.585153 6.458351 -4.964428
v  14.932745 6.447994 -4.842384
v  14.781954 6.476301 -5.016607
v  14.705166 6.472085 -5.026801
v  14.637741 6.471846 -5.008830
v  14.546959 6.366960 -4.815230
v  14.645424 6.043109 -4.464304
v  14.817001 6.152914 -4.499478
v  14.725002 6.022386 -4.441669
v  14.756484 6.040979 -4.458802
v  14.554043 6.221046 -4.556375
v  14.606462 6.160409 -4.518748
v  14.895398 6.361399 -4.736618
v  14.882064 6.326487 -4.624283
v  14.570435 6.181455 -4.548729
v  14.601422 6.125069 -4.490606
v  14.535290 6.318955 -4.664204
v  14.531156 6.313205 -4.673118
v  14.607246 6.466630 -4.984710
v  14.529127 6.333090 -4.707591
v  14.860780 6.251611 -4.541178
v  14.778594 6.061254 -4.461590
v  14.898826 6.382144 -4.766835
v  14.681280 6.024293 -4.446631
v  14.834035 6.234812 -4.512999
v  14.543944 6.381976 -4.842093
v  14.876184 6.325961 -4.678763
v  14.629920 6.101322 -4.480902
v  14.558341 6.258099 -4.574050
v  14.799793 6.157886 -4.494472
v  14.556605 6.386917 -4.801395
v  14.867911 6.304828 -4.647443
v  14.931022 6.420967 -4.854314
v  14.614112 6.434468 -4.992071
v  14.532229 6.376400 -4.768568
v  14.581291 6.224934 -4.557595
v  14.834042 6.243317 -4.555013
v  14.781393 6.092249 -4.476517
v  14.802264 6.128584 -4.453856
v  14.904486 6.352285 -4.699079
v  14.883806 6.345440 -4.708693
v  14.873019 6.303653 -4.592368
v  14.902231 6.442945 -4.912437
v  14.889355 6.464777 -4.940587
v  14.567375 6.439887 -4.899340
v  14.833621 6.185909 -4.513875
v  14.562333 6.277420 -4.603966
v  14.552345 6.280012 -4.600114
v  14.923005 6.425510 -4.782690
v  14.629830 6.061049 -4.488468
v  14.751766 6.042408 -4.461523
v  14.542150 6.407698 -4.878405
v  14.550747 6.256515 -4.621572
v  14.890553 6.348151 -4.656265
v  14.556235 6.335530 -4.703446
v  14.672609 6.450788 -5.034447
v  14.555270 6.354117 -4.737586
v  14.849442 6.446066 -5.000805
v  14.556079 6.369728 -4.813269
v  14.757664 6.074066 -4.458656
v  14.547734 6.301404 -4.630142
v  14.849483 6.444702 -4.994992
v  14.709528 6.463661 -5.039252
v  14.721642 6.021586 -4.442083
v  14.890694 6.442817 -4.941859
v  14.684812 6.021591 -4.457645
v  14.563910 6.210894 -4.573634
v  14.912875 6.467176 -4.912139
v  14.554698 6.337390 -4.750215
v  14.675813 6.462845 -5.009185
v  14.581356 6.449903 -4.930435
v  14.800037 6.165132 -4.478790
v  14.807818 6.155552 -4.501315
v  14.556472 6.425595 -4.863078
v  14.831934 6.217015 -4.540297
v  14.544377 6.322211 -4.662979
v  14.929765 6.438448 -4.814024
v  14.656512 6.041890 -4.483124
v  14.848249 6.477960 -4.991022
v  14.564179 6.290445 -4.639527
v  14.530346 6.378898 -4.806972
v  14.593233 6.146036 -4.535862
v  14.601861 6.116186 -4.518906
v  14.910179 6.433539 -4.850503
v  14.864089 6.259354 -4.583134
v  14.844061 6.267515 -4.583136
v  14.565951 6.188879 -4.533787
v  14.898337 6.368047 -4.686760
v  14.900392 6.440356 -4.952200
v  14.616426 6.118399 -4.521008
v  14.611157 6.087492 -4.495444
v  14.909361 6.469121 -4.949826
v  14.776234 6.092397 -4.473959
v  14.641914 6.437996 -5.012516
v  14.863554 6.280209 -4.562051
v  14.541347 6.379348 -4.766870
v  14.782965 6.446689 -5.011440
v  14.852612 6.297481 -4.602971
v  14.905258 6.385558 -4.714184
v  14.559086 6.401488 -4.830428
v  14.859159 6.282685 -4.616327
v  14.818079 6.475826 -5.021060
v  14.711920 6.438622 -5.031005
v  14.657752 6.059318 -4.454626
v  14.897955 6.345012 -4.705399
v  14.935249 6.420162 -4.822454
v  14.544737 6.249032 -4.582774
v  14.597228 6.457138 -4.956431
v  14.716557 6.036374 -4.428518
v  14.841310 6.470126 -4.986379
v  14.539065 6.323925 -4.667313
v  14.533056 6.339776 -4.700029
v  14.554873 6.354378 -4.782927
v  14.537042 6.343875 -4.701910
v  14.532081 6.358761 -4.734824
v  14.936701 6.451519 -4.881710
v  14.689322 6.025393 -4.466947
v  14.930723 6.459391 -4.878969
v  14.914900 6.429683 -4.887406
v  14.648560 6.054604 -4.454326
v  14.555663 6.398072 -4.879238
v  14.892416 6.397704 -4.753996
v  14.595671 6.427825 -4.968719
v  14.695288 6.033368 -4.469224
v  14.561455 6.413260 -4.914592
v  14.674700 6.437735 -5.024387
v  14.876327 6.475802 -4.968266
v  14.795762 6.094126 -4.448862
v  14.589969 6.429624 -4.968934
v  14.579965 6.420706 -4.943073
v  14.747048 6.443811 -5.016901
v  14.742536 6.473523 -5.032089
v  14.607089 6.441343 -4.997046
v  14.532609 6.393107 -4.834887
v  14.541718 6.368216 -4.814921
v  14.903797 6.460351 -4.913086
v  14.535834 6.406518 -4.827725
v  14.649825 6.069452 -4.494097
v  14.907188 6.435697 -4.918419
v  14.726138 6.023086 -4.453234
v  14.892373 6.440253 -4.946688
v  14.644076 6.470598 -5.006484
v  14.780910 6.462626 -5.007231
v  14.601600 6.462039 -4.991169
v  14.923109 6.449335 -4.842264
v  14.759768 6.042282 -4.437213
v  14.547844 6.393950 -4.798355
v  14.543231 6.297513 -4.683432
v  14.541233 6.316926 -4.717106
v  14.839945 6.257861 -4.539139
v  14.880733 6.324131 -4.678797
v  14.546479 6.276750 -4.650321
v  14.438081 7.231233 -4.924395
v  14.733803 6.900870 -4.581688
v  14.867107 6.773304 -4.940533
v  15.077115 7.247115 -4.826226
v  14.624325 6.789635 -4.900910
v  14.879689 6.880306 -4.642799
v  14.719332 6.756891 -5.002725
v  14.826077 6.924794 -5.005271
v  14.947006 7.099171 -4.722079
v  14.540465 7.136642 -4.944498
v  14.625971 6.878047 -4.649891
v  14.986444 7.001800 -4.847172
v  14.692436 6.986318 -4.611979
v  14.929743 6.822070 -4.794847
v  14.569901 6.927381 -4.937056
v  14.767174 6.911719 -4.562821
v  14.564301 6.839820 -4.774594
v  14.479832 7.155791 -4.876847
v  14.822224 6.758562 -4.997985
v  14.664950 6.905757 -4.592333
v  14.947418 7.028966 -4.715993
v  14.818624 7.111219 -4.958289
v  14.926170 6.788743 -4.905122
v  14.506291 7.010598 -4.862830
v  14.738020 7.151341 -4.743505
v  14.586962 6.799630 -4.885545
v  14.711422 6.838701 -5.019664
v  14.894946 6.890860 -4.629891
v  14.670600 7.135232 -4.963070
v  14.754097 6.760000 -4.980620
v  14.981052 6.992288 -4.787294
v  14.805957 7.241012 -4.870055
v  14.774626 6.970773 -4.584759
v  14.612226 6.887404 -4.639886
v  14.961069 7.003550 -4.732754
v  14.589282 6.823697 -4.808236
v  14.783899 6.947143 -5.012824
v  14.509090 7.204940 -4.876965
v  14.952264 6.825549 -4.796927
v  14.948307 6.967201 -4.905251
v  14.609573 7.015642 -4.973935
v  14.539656 6.990250 -4.758462
v  15.000271 7.214396 -4.801006
v  14.868632 7.104556 -4.700316
v  14.855524 7.232383 -4.827733
v  14.731764 6.912702 -4.565241
v  14.534014 7.001241 -4.914188
v  14.861528 6.877173 -4.991319
v  14.882070 6.770300 -4.961911
v  14.546206 6.917788 -4.873296
v  14.879553 6.944867 -4.618943
v  14.814026 6.970239 -4.590791
v  15.072491 7.215402 -4.828171
v  14.911328 6.873426 -4.658361
v  14.648264 7.231149 -4.891381
v  14.595961 6.922967 -4.962238
v  14.645046 7.122963 -4.739738
v  14.701225 6.919023 -5.015067
v  14.630938 6.918926 -4.622527
v  14.488050 7.093513 -4.886554
v  14.965305 6.883332 -4.843197
v  15.001036 7.091616 -4.757479
v  14.577527 6.865329 -4.701812
v  14.569507 7.162316 -4.943295
v  14.968700 6.900606 -4.769782
v  14.670690 6.868351 -5.006836
v  14.900928 7.026833 -4.945958
v  14.558582 6.973382 -4.938451
v  14.838421 6.917641 -4.586427
v  14.915443 7.012311 -4.683284
v  14.768354 7.139592 -4.956266
v  14.810513 7.219198 -4.899427
v  14.489617 7.181724 -4.875506
v  14.627878 6.776495 -4.947688
v  14.796021 6.898373 -4.587482
v  14.730462 7.063691 -4.659629
v  14.506849 7.017032 -4.833825
v  14.440562 7.219759 -4.926948
v  15.075354 7.237433 -4.818686
v  14.937841 6.866853 -4.911664
v  16.636984 7.245386 -4.613937
v  16.457897 6.760540 -4.646804
v  16.167934 6.842555 -4.465817
v  16.006819 7.199302 -4.673673
v  16.340656 6.918017 -4.294625
v  16.267534 6.742624 -4.685945
v  16.392494 6.893934 -4.745014
v  16.251503 6.896730 -4.342356
v  16.085012 7.077962 -4.536651
v  16.606739 7.108133 -4.572675
v  16.481495 6.855484 -4.434389
v  16.200321 6.892632 -4.725232
v  16.348803 7.048591 -4.393160
v  16.191608 7.058301 -4.440781
v  16.544899 6.976118 -4.502419
v  16.324335 6.726840 -4.728615
v  16.140842 6.813127 -4.535049
v  16.461348 6.889744 -4.365554
v  16.211943 6.901563 -4.340931
v  16.275177 7.165982 -4.697426
v  16.423342 7.190988 -4.551816
v  16.151398 6.961685 -4.434254
v  16.451569 6.743310 -4.690322
v  16.100700 7.009556 -4.661694
v  16.124660 6.916811 -4.569586
v  16.544601 7.095917 -4.513436
v  16.093266 7.024382 -4.539801
v  16.200634 6.752541 -4.668314
v  16.522024 6.797325 -4.569789
v  16.303902 6.955055 -4.750957
v  16.267363 6.939600 -4.313038
v  16.546249 6.932391 -4.559974
v  16.367901 6.900675 -4.330926
v  16.153595 6.863521 -4.424705
v  16.542652 6.982811 -4.629072
v  16.515713 6.847250 -4.459209
v  16.451656 6.965176 -4.368979
v  16.443790 7.103998 -4.464404
v  16.074299 7.109999 -4.686976
v  16.084478 7.027783 -4.609251
v  16.375462 6.916230 -4.301511
v  16.170218 7.157069 -4.555804
v  16.384010 6.738437 -4.695945
v  16.605690 7.102540 -4.592626
v  16.466352 6.976190 -4.701080
v  16.537563 7.025920 -4.497439
v  16.004253 7.191485 -4.671441
v  16.357613 6.774783 -4.742701
v  16.143274 6.932389 -4.679081
v  16.633186 7.216744 -4.596749
v  16.307241 7.048337 -4.393833
v  16.460760 7.037427 -4.432048
v  16.564846 7.011179 -4.545979
v  16.173260 6.932340 -4.381613
v  16.112486 7.199860 -4.675758
v  16.234827 6.888871 -4.739573
v  16.339703 7.045204 -4.732931
v  16.135033 6.931713 -4.454170
v  16.143517 7.106143 -4.713005
v  16.460207 6.857925 -4.707401
v  16.217691 6.759190 -4.648682
v  16.103657 7.054767 -4.526028
v  16.284557 7.105763 -4.721938
v  16.112711 6.952504 -4.543551
v  16.533478 6.953042 -4.495661
v  16.419001 6.970900 -4.350767
v  16.171364 6.797368 -4.564432
v  16.040522 7.199749 -4.662539
v  16.500769 6.812860 -4.530165
v  16.258165 6.734958 -4.709934
v  16.236921 6.919267 -4.322362
v  16.493679 7.124955 -4.496585
v  16.159899 6.779868 -4.607571
v  16.539608 7.050328 -4.653230
v  16.052166 7.072551 -4.626698
v  16.582125 7.088835 -4.547839
v  16.310825 7.105869 -4.454803
v  16.121885 6.894003 -4.562797
v  16.166542 7.013901 -4.433144
v  16.582605 7.170557 -4.552019
v  17.432358 6.372618 -4.766141
v  17.750317 6.594516 -4.195658
v  18.181446 5.141527 -4.609997
v  18.207657 6.080209 -4.370230
v  17.211637 6.116180 -4.161485
v  18.218048 6.106834 -4.920263
v  17.638340 5.211401 -5.004444
v  17.862459 6.977044 -4.540386
v  17.696480 6.467325 -4.632172
v  17.632986 5.853872 -4.462091
v  18.411880 6.290147 -4.550442
v  18.338730 5.468072 -4.415195
v  17.988121 6.688332 -4.547601
v  17.588308 6.088520 -4.980337
v  17.784025 6.400225 -4.409753
v  17.581142 5.159781 -4.640440
v  17.498320 5.639936 -4.143610
v  17.590157 5.547906 -4.051036
v  17.659252 6.936676 -4.367651
v  17.252035 5.931676 -4.117991
v  17.342056 6.368156 -3.340361
v  18.420958 5.615489 -4.263205
v  17.419851 6.255882 -3.394034
v  17.846949 5.499967 -4.181252
v  18.234612 5.950466 -4.571568
v  17.593437 6.702415 -4.622341
v  17.470617 6.224409 -4.531408
v  18.021269 5.210297 -5.063673
v  18.340408 6.270831 -4.405165
v  17.536436 5.995282 -4.649765
v  17.854687 5.038094 -4.551895
v  17.816629 6.506435 -4.686011
v  17.803532 6.114518 -5.032837
v  17.859310 6.799304 -4.681427
v  17.506123 5.459147 -4.058080
v  17.453487 6.349114 -3.324921
v  17.367840 6.076159 -4.778320
v  17.826656 5.565938 -4.239289
v  17.957417 6.450224 -4.566366
v  17.918610 5.933073 -4.350874
v  17.355862 6.296793 -3.279799
v  17.780958 5.456088 -4.285730
v  17.236462 6.393565 -3.360797
v  18.456116 5.471415 -4.325318
v  18.073853 6.394620 -4.565941
v  17.620449 6.744659 -4.311246
v  17.364180 6.061804 -4.098585
v  17.679020 6.962356 -4.573933
v  18.263275 5.791760 -4.845253
v  17.777853 6.418233 -4.268868
v  17.857313 6.764037 -4.268258
v  17.282982 6.240233 -3.591853
v  17.635345 5.497464 -4.117861
v  17.376961 6.288360 -3.625047
v  17.305456 6.231602 -4.712981
v  18.463251 5.671056 -4.468598
v  17.550657 6.255863 -4.942709
v  18.052010 6.322175 -4.862006
v  17.278738 6.155366 -4.149255
v  17.724531 5.453233 -4.982332
v  17.619362 5.556117 -4.194210
v  18.098381 5.794475 -4.462271
v  17.987469 5.485560 -4.479244
v  17.586428 5.434863 -4.056272
v  17.629820 6.574281 -4.541455
v  17.455534 6.206290 -3.423546
v  17.359695 6.281121 -3.289563
v  17.425919 6.259439 -4.562612
v  18.128811 5.656178 -4.328440
v  17.298079 6.293241 -3.382958
v  17.731380 6.707138 -4.227898
v  17.520555 5.495861 -4.092179
v  18.057051 5.820112 -5.023458
v  17.749743 6.909905 -4.284105
v  17.502277 6.324677 -4.572777
v  18.299580 5.989474 -4.657059
v  17.955614 6.599377 -4.478508
v  18.363207 6.092482 -4.367721
v  18.344044 5.646400 -4.290103
v  18.449753 6.140722 -4.582943
v  17.182743 5.990573 -4.011106
v  17.831619 5.418518 -4.204830
v  17.790323 5.548438 -4.298172
v  18.161364 5.211145 -5.004270
v  17.591913 5.525872 -3.999032
v  17.526453 5.606047 -4.106043
v  17.478802 6.222908 -4.547407
v  18.190823 5.513224 -4.891581
v  17.385500 5.996856 -4.666243
v  17.388899 6.272893 -3.296475
v  17.385708 6.235983 -3.419441
v  17.895729 6.905062 -4.607518
v  18.214836 5.396852 -4.341545
v  17.321636 6.178679 -3.603306
v  17.902477 6.506084 -4.651238
v  17.223066 6.344028 -3.320740
v  17.259165 6.293491 -3.391861
v  17.688047 6.588582 -4.232932
v  17.841837 6.292705 -4.457224
v  17.379076 6.209983 -3.296894
v  18.187042 5.876371 -4.407701
v  17.597223 5.381531 -4.054164
v  17.511736 5.406604 -4.090629
v  17.814520 5.446248 -4.491548
v  18.470753 5.649654 -4.309332
v  17.898642 6.915270 -4.387263
v  17.570957 5.483487 -4.805862
v  18.285969 5.583994 -4.392418
v  17.196314 6.196567 -3.940866
v  17.329243 6.365367 -3.405167
v  17.448814 5.959240 -4.470735
v  17.970236 6.754107 -4.465191
v  17.679890 5.592535 -4.522582
v  17.316376 6.238597 -4.584285
v  18.299843 5.771368 -4.582124
v  17.804369 6.261209 -4.971591
v  17.357809 6.009642 -3.968155
v  18.327263 6.323264 -4.458632
v  17.499731 6.115067 -4.401053
v  17.314592 6.408739 -3.295191
v  18.069141 5.858646 -4.382330
v  17.593756 5.604074 -4.050055
v  17.877277 6.620380 -4.289728
v  18.363434 5.741237 -4.248482
v  17.635813 6.832917 -4.623862
v  17.729021 5.838576 -4.423330
v  17.597780 5.646606 -4.183014
v  17.899849 5.505834 -4.987927
v  18.253601 6.059297 -4.392694
v  17.385366 6.205100 -3.409996
v  17.959951 6.334950 -4.488283
v  18.206644 5.436889 -4.659919
v  17.349316 6.270242 -3.369851
v  17.362669 6.344480 -3.400328
v  17.888990 6.470495 -4.416340
v  17.271530 6.414625 -3.386364
v  17.573885 5.528277 -4.161757
v  17.685066 6.399714 -4.543990
v  17.226683 6.003612 -4.378529
v  17.287415 6.282248 -3.613118
v  17.270048 6.352563 -3.403998
v  17.578547 5.878870 -4.946021
v  17.727583 6.420613 -4.278048
v  17.409170 6.370854 -3.303980
v  18.395351 5.877702 -4.602050
v  17.643671 5.597866 -4.152286
v  18.140865 5.510418 -4.163392
v  17.632185 6.583883 -4.326740
v  17.525089 5.551733 -4.107837
v  17.631355 5.569834 -4.140587
v  18.215218 5.830264 -4.943249
v  17.419632 6.205410 -3.631128
v  17.276106 6.333292 -3.310904
v  17.969723 6.111537 -4.365202
v  17.768528 6.510369 -4.213326
v  17.293367 6.207829 -4.239384
v  17.778431 5.209609 -5.063303
v  17.528500 5.980513 -4.808056
v  17.734137 6.933171 -4.638411
v  17.930264 6.463798 -4.680756
v  18.349176 5.564291 -4.206192
v  17.646164 6.495164 -4.450876
v  17.612177 5.465276 -4.145019
v  17.646420 5.485334 -4.092912
v  17.759272 6.791297 -4.699872
v  17.643824 5.981796 -4.438470
v  17.778360 6.999757 -4.467655
v  17.516008 5.441206 -4.095945
v  18.147776 5.444824 -4.582361
v  17.494522 5.618417 -4.165319
v  17.557264 6.333642 -4.903576
v  17.601366 6.891500 -4.441618
v  17.169437 6.020915 -4.117321
v  17.547928 5.388309 -4.097718
v  17.615891 5.425769 -4.124608
v  17.354610 6.248028 -3.880107
v  17.585476 5.493828 -4.053487
v  17.565229 6.002300 -4.921349
v  17.341032 6.256616 -3.289774
v  18.266325 5.700515 -4.296570
v  18.196701 5.426881 -4.207061
v  17.574585 5.414409 -4.706579
v  18.259153 5.185878 -4.727724
v  17.287422 6.408470 -3.330627
v  17.534197 5.549175 -4.083959
v  18.198727 6.033751 -4.455418
v  17.929424 5.772656 -4.445313
v  17.585011 6.733084 -4.554470
v  17.331646 6.322371 -3.303351
v  17.952847 6.178071 -4.958482
v  17.763449 5.935240 -5.041710
v  18.275145 6.183276 -4.362218
v  17.933701 6.527529 -4.533400
v  17.436852 6.283063 -3.316122
v  18.405958 6.052797 -4.393101
v  17.595640 6.415385 -4.796397
v  18.328564 6.106050 -4.750627
v  17.594742 5.427196 -4.012769
v  17.791069 6.404437 -4.833088
v  17.754725 6.572378 -4.225225
v  18.437889 6.041617 -4.548767
v  17.273577 6.316082 -3.329927
v  17.797188 5.414952 -4.246046
v  17.899849 5.088557 -4.530014
v  18.348427 6.315664 -4.646882
v  17.313185 6.375823 -3.384560
v  17.530684 6.396373 -4.650239
v  17.397751 6.128426 -3.967313
v  17.441723 6.303834 -3.411974
v  17.201685 5.946821 -4.133412
v  17.645233 5.481857 -4.927995
v  17.832016 6.435620 -4.301028
v  17.578207 5.435349 -4.145662
v  17.354851 6.310036 -4.758903
v  18.005518 6.764046 -4.530455
v  17.793669 6.021761 -4.406042
v  17.769428 6.748186 -4.235152
v  17.386255 6.294529 -3.306439
v  18.147131 6.258368 -4.406227
v  17.630350 6.225308 -4.980856
v  17.475458 5.998137 -4.414806
v  17.353395 6.251289 -3.332000
v  17.354275 6.217609 -4.258166
v  17.523424 5.496392 -4.085541
v  17.741432 6.278197 -4.490088
v  18.138147 5.665718 -4.273269
v  17.706543 6.508884 -4.235499
v  17.939859 6.857249 -4.568072
v  17.708797 6.433657 -4.434872
v  17.387268 6.160832 -3.668020
v  17.503862 6.020132 -4.621388
v  17.726046 5.352488 -4.532849
v  17.551174 5.972698 -4.518176
v  17.812311 6.948915 -4.339691
v  17.532330 5.592988 -4.051796
v  18.026241 5.314632 -5.021705
v  17.915321 6.526879 -4.376935
v  18.022449 6.417146 -4.752509
v  17.389151 6.295082 -3.381514
v  17.855869 5.656103 -4.449642
v  18.284712 6.004519 -4.758963
v  17.680885 6.901636 -4.302818
v  17.229092 6.325468 -3.391234
v  18.188963 5.945013 -4.423216
v  18.230478 5.880938 -4.589333
v  17.533710 5.775811 -4.788311
v  17.540546 5.185731 -4.728116
v  17.406742 6.253576 -3.424419
v  17.633633 5.516264 -4.141637
v  17.338470 6.351859 -3.547467
v  17.543211 5.636031 -4.133829
v  17.419270 6.326188 -3.335442
v  17.569962 5.488369 -4.154320
v  17.225790 5.966998 -3.996680
v  17.726915 5.588610 -4.141478
v  17.345778 6.314851 -4.675690
v  18.120079 5.600012 -4.184595
v  18.394472 5.594892 -4.492763
v  18.210838 5.517538 -4.415731
v  17.199942 6.114973 -3.815589
v  18.425562 5.454258 -4.365194
v  17.257915 6.360494 -3.367987
v  17.719526 5.783097 -4.462059
v  18.047884 5.557311 -4.374025
v  17.340925 5.994508 -4.095326
v  18.277988 5.621292 -4.373747
v  18.301821 6.360040 -4.556540
v  17.631319 5.898526 -4.999394
v  17.371582 6.248198 -3.411517
v  17.634615 5.521347 -4.005218
v  17.628527 5.558742 -4.124069
v  17.645430 5.452886 -4.112430
v  17.927322 5.951197 -5.012466
v  17.611975 5.809334 -4.550063
v  17.356255 5.954893 -4.549725
v  18.447163 6.175286 -4.470737
v  17.420170 6.243692 -3.331281
v  17.292042 6.334542 -3.372331
v  17.661282 6.578624 -4.582411
v  17.765459 5.441284 -4.108951
v  17.333641 6.309556 -3.298131
v  17.529644 5.441432 -4.083916
v  17.608425 6.631637 -4.391376
v  17.898380 6.588181 -4.625143
v  17.786238 6.658778 -4.241916
v  18.316225 5.954331 -4.300811
v  17.991285 5.602818 -4.999972
v  17.221659 6.137377 -4.243246
v  17.634121 6.701504 -4.616469
v  17.696850 5.563523 -4.246210
v  17.373775 6.349828 -4.721586
v  17.293121 6.372870 -3.340109
v  18.257244 5.735484 -4.535460
v  17.348717 6.401934 -3.309683
v  17.312277 6.146861 -4.133945
v  17.781404 6.711793 -4.261899
v  17.772163 6.984016 -4.571244
v  17.702902 5.438339 -4.190043
v  17.419344 6.182273 -3.390241
v  17.640202 5.515114 -4.125781
v  17.605583 6.353154 -4.581138
v  17.752359 6.539054 -4.210791
v  17.688225 6.576033 -4.267228
v  18.247246 6.004703 -4.353562
v  17.607668 5.409157 -4.106715
v  18.409176 6.238928 -4.431779
v  17.992744 6.766304 -4.488744
v  17.782146 6.583413 -4.672645
v  18.127747 5.870708 -5.006237
v  17.681391 6.450903 -4.632866
v  17.662889 5.119677 -4.588090
v  17.900982 6.410009 -4.465665
v  17.856297 5.292385 -4.508525
v  17.273247 6.316563 -3.379726
v  18.225792 5.207465 -4.903647
v  17.416729 6.324358 -3.517175
v  17.529844 5.597903 -4.147000
v  18.228745 5.483456 -4.805505
v  18.256880 5.634951 -4.445736
v  17.505690 5.522223 -4.099089
v  17.289213 6.296726 -3.314456
v  17.531439 6.199693 -4.531522
v  17.491272 6.173141 -4.403632
v  17.809090 6.428250 -4.369561
v  17.573910 5.208025 -4.904455
v  17.433443 6.212229 -3.334950
v  18.410936 5.488684 -4.259289
v  17.687706 6.851530 -4.662536
v  17.986946 5.374215 -4.496387
v  17.816534 5.311952 -5.024255
v  18.416288 6.020806 -4.590209
v  17.855078 6.201702 -4.444548
v  17.439835 6.369722 -3.359030
v  17.608130 5.586782 -4.016964
v  17.991550 5.160065 -4.527360
v  17.729858 6.139925 -5.023951
v  18.204941 6.171924 -4.898495
v  17.307747 6.172270 -4.726174
v  17.286398 5.963058 -4.028763
v  17.474531 6.203557 -4.915754
v  17.623508 5.587308 -4.123321
v  17.273216 6.179690 -3.661526
v  17.903109 5.421208 -4.262126
v  17.851891 6.873353 -4.663293
v  17.951542 6.614701 -4.519140
v  18.484631 5.711233 -4.384933
v  18.295097 5.765572 -4.262251
v  17.638968 6.092575 -5.008324
v  17.577589 5.395304 -4.126077
v  17.338173 6.295228 -3.391800
v  18.218561 5.159782 -4.640438
v  17.620304 5.423029 -4.620079
v  17.859198 6.560481 -4.291817
v  17.381739 6.156636 -4.826085
v  18.205311 5.906869 -4.355790
v  18.220686 5.464283 -4.411212
v  17.334135 6.318891 -3.387997
v  18.319813 5.516110 -4.196308
v  17.528019 5.459103 -4.124364
v  18.219009 5.972771 -4.522744
v  17.756784 6.953777 -4.334085
v  18.175461 6.316255 -4.799824
v  18.060823 6.139050 -4.967864
v  17.389116 6.347065 -3.331098
v  17.905735 6.943779 -4.485480
v  18.364840 6.027658 -4.346830
v  17.624907 6.921308 -4.536860
v  17.235495 6.311148 -3.319892
v  17.747162 6.410031 -4.291063
v  17.842329 6.345499 -4.893279
v  17.816122 5.571167 -4.262606
v  17.588396 5.397951 -4.021698
v  17.573580 6.729362 -4.583983
v  17.391464 5.936538 -4.450438
v  18.232576 5.467810 -4.755808
v  18.121216 5.634829 -4.511988
v  17.636419 6.681448 -4.327330
v  17.774485 6.459582 -4.726859
v  17.597383 5.450289 -4.102496
v  17.811249 5.920630 -4.396811
v  18.377337 5.629930 -4.255257
v  17.673956 6.974391 -4.465786
v  17.964191 6.778892 -4.507439
v  17.390167 6.049642 -3.944984
v  18.263552 5.908897 -4.853241
v  17.673462 6.480427 -4.499335
v  17.237925 6.223062 -3.952333
v  17.288931 6.321032 -3.399168
v  17.958973 6.725952 -4.557287
v  17.324717 6.298554 -3.621233
v  17.534317 5.982451 -4.707796
v  17.414881 6.236350 -3.668348
v  17.608799 5.494162 -4.108952
v  17.923225 5.462244 -4.315959
v  17.495901 5.631900 -4.173316
v  17.793142 6.602789 -4.221196
v  17.367937 6.253643 -4.559789
v  17.761387 5.598999 -4.994827
v  17.343693 6.309186 -3.363522
v  17.568298 5.554926 -4.036802
v  17.397154 6.226698 -3.377958
v  18.310406 5.540624 -4.400789
v  18.350073 5.819150 -4.605719
v  17.345236 6.230620 -3.306857
v  17.498018 6.406224 -4.724441
v  17.576283 5.494032 -4.125402
v  17.327742 6.422212 -3.368527
v  18.115057 5.315284 -4.998708
v  17.508305 5.654202 -4.153675
v  17.160112 6.033186 -4.029696
v  18.393486 6.169516 -4.688753
v  18.227978 6.086502 -4.383165
v  17.210056 6.093440 -4.254470
v  17.532034 5.584877 -4.135283
v  17.889921 6.743571 -4.316706
v  17.823254 6.810665 -4.263377
v  17.413467 6.267746 -3.292679
v  18.377672 6.289716 -4.443991
v  18.268282 5.826502 -4.766664
v  17.637072 5.401886 -4.029928
v  17.248194 6.173874 -4.226089
v  17.380836 6.186530 -3.535653
v  17.579506 5.440656 -4.116607
v  17.234131 6.291062 -3.392954
v  18.124557 5.620348 -4.372087
v  17.978632 6.268441 -4.451647
v  17.503317 6.174798 -4.534076
v  18.242590 5.938040 -4.914223
v  17.691090 6.704411 -4.274327
v  17.388029 6.265046 -3.284218
v  17.580299 6.279119 -4.536141
v  17.641893 6.752604 -4.281084
v  17.622814 5.519617 -4.152039
v  18.343414 5.992851 -4.644327
v  17.391775 6.247198 -3.348338
v  17.248753 6.358383 -3.398350
v  18.137224 6.385416 -4.579574
v  18.392185 6.260223 -4.651711
v  17.778868 6.340705 -4.453597
v  18.324785 5.613974 -4.342976
v  17.857033 5.357368 -5.008386
v  18.031723 5.660630 -4.465282
v  17.684643 5.315051 -4.998652
v  17.556816 5.439094 -4.021546
v  17.685484 6.616663 -4.256491
v  18.213110 5.839077 -4.571478
v  17.608303 6.651002 -4.613340
v  17.588732 5.447565 -4.138074
v  17.516376 6.016794 -4.877045
v  17.697504 5.608535 -4.206208
v  17.828421 5.533258 -4.190914
v  17.948561 5.427267 -4.210237
v  18.055616 5.781995 -4.449409
v  18.217642 6.382616 -4.650711
v  17.271206 6.330439 -3.346116
v  17.743816 6.520577 -4.662471
v  17.282324 6.031152 -4.572084
v  17.564468 5.416805 -4.081329
v  18.180038 5.997785 -4.458561
v  17.162878 6.108696 -4.007200
v  17.824648 6.588348 -4.671441
v  17.580837 5.492090 -4.003457
v  17.970831 6.454120 -4.621211
v  17.535774 5.555202 -4.096392
v  17.431063 6.274995 -4.574632
v  17.396019 6.327239 -3.306718
v  18.305805 6.083786 -4.355869
v  17.591133 5.596088 -4.139810
v  17.827339 6.986665 -4.437754
v  17.354790 6.093812 -4.106096
v  17.699329 6.431693 -4.306077
v  17.930408 6.503509 -4.605474
v  17.570684 6.381380 -4.616539
v  17.807625 6.438134 -4.265411
v  17.361910 6.268284 -4.794297
v  17.433073 6.254091 -3.396252
v  17.427208 5.986667 -4.657080
v  17.265892 6.059980 -3.804045
v  17.252323 5.943742 -4.204151
v  17.928406 6.826266 -4.605416
v  17.997190 6.010659 -4.343086
v  18.254438 5.928769 -4.707257
v  17.207546 6.162205 -4.065546
v  18.090103 5.943963 -5.010438
v  17.846521 6.486279 -4.695302
v  17.932178 6.859879 -4.408324
v  18.436481 6.077087 -4.448494
v  17.535051 5.502789 -4.085611
v  17.597530 5.410930 -4.069376
v  17.360163 6.335835 -3.412909
v  17.291258 6.275449 -3.491469
v  17.841349 6.373919 -4.437359
v  17.899849 5.198766 -5.040741
v  17.403208 6.089044 -3.957702
v  18.244324 5.675216 -4.344858
v  17.378057 6.298777 -3.283942
v  18.084005 5.593153 -4.980269
v  17.432907 6.211932 -4.324851
v  17.216494 5.968055 -4.217106
v  17.587776 6.201597 -4.976117
v  17.910322 5.566172 -4.312603
v  17.856039 5.368666 -4.487989
v  17.398449 6.272176 -3.404912
v  17.604597 5.545573 -4.131213
v  17.387794 6.297037 -3.324899
v  17.775524 6.869267 -4.684256
v  17.526558 6.039874 -4.555541
v  17.525362 5.502135 -4.055352
v  17.404600 6.184011 -3.593596
v  17.783092 6.573210 -4.234066
v  17.717926 6.238959 -4.496827
v  17.855606 5.525661 -4.464714
v  17.447737 6.277599 -3.505944
v  18.228695 5.393340 -4.299644
v  17.566273 5.485673 -4.058725
v  17.314650 5.987813 -3.957806
v  17.242516 6.351448 -3.304347
v  17.573957 5.415235 -4.157068
v  17.884205 6.472525 -4.355855
v  17.902617 5.827969 -5.011725
v  18.000750 6.739423 -4.552639
v  17.807087 5.105244 -4.537385
v  17.670025 6.202259 -4.997762
v  17.308338 6.370585 -3.402452
v  17.475580 6.381348 -4.789688
v  18.328541 5.438869 -4.247771
v  18.020836 5.846418 -4.377873
v  17.896505 5.435729 -4.289246
v  17.652643 5.480770 -4.108259
v  18.376089 5.655396 -4.265501
v  17.865206 6.700702 -4.299824
v  17.261509 6.341761 -3.481867
v  17.812231 5.485533 -4.478976
v  17.679514 6.919658 -4.623961
v  17.622480 5.567068 -4.166356
v  17.406414 6.359505 -4.659315
v  18.221210 6.025845 -4.439700
v  17.766069 6.727870 -4.697483
v  17.500725 6.084234 -4.918509
v  18.411924 5.745589 -4.261515
v  18.075169 5.453218 -4.981656
v  17.557125 5.967678 -4.879366
v  18.195881 5.808275 -4.447106
v  17.877403 6.868348 -4.319952
v  17.235603 6.342515 -3.349005
v  17.688717 5.432097 -4.551696
v  17.910049 6.655772 -4.336561
v  17.581892 5.531618 -4.127796
v  18.203421 6.209311 -4.374880
v  17.604563 6.846541 -4.369833
v  17.592154 6.734274 -4.580541
v  17.371876 6.257276 -3.381859
v  17.831871 6.903157 -4.301954
v  17.862379 6.735556 -4.679595
v  17.718273 6.718192 -4.234396
v  18.365513 6.230365 -4.392202
v  17.860718 6.125079 -5.019815
v  17.634100 5.588243 -4.151281
v  17.480095 6.208726 -4.560807
v  17.594551 6.814229 -4.547198
v  17.296896 6.350729 -3.279123
v  17.953848 6.842958 -4.481305
v  17.450788 5.991747 -4.338612
v  18.088869 5.095767 -4.571816
v  17.524681 5.387139 -4.083372
v  18.287628 5.650759 -4.331014
v  17.373106 6.201723 -3.949989
v  18.306852 6.019894 -4.341121
v  18.148153 5.465206 -4.174705
v  17.677792 6.530524 -4.272486
v  17.987196 5.255636 -4.522070
v  17.684912 6.459060 -4.580417
v  17.389780 6.194580 -3.319661
v  17.745874 5.404080 -4.135246
v  18.435532 6.234713 -4.486389
v  17.503168 6.204884 -4.538221
v  17.985176 5.446527 -4.492032
v  17.708435 5.877672 -4.417492
v  17.726364 5.971505 -4.409273
v  18.245808 5.809286 -4.898411
v  17.275049 6.323432 -3.300526
v  17.423092 6.380750 -4.713798
v  17.524427 5.553682 -4.087364
v  17.461727 5.990627 -4.595248
v  17.389978 6.270457 -3.322492
v  18.262787 5.959721 -4.624023
v  17.937656 6.443491 -4.517790
v  17.660572 6.570549 -4.308936
v  17.312988 5.984943 -4.564011
v  17.677319 5.795759 -4.480218
v  17.240181 6.339787 -3.411339
v  17.311869 6.256021 -3.880767
v  17.739220 6.576044 -4.656415
v  17.386911 6.277718 -3.650607
v  17.639042 5.494771 -4.132601
v  18.242641 5.573710 -4.403453
v  17.876860 5.808364 -4.436980
v  17.267944 6.319682 -3.412097
v  18.194529 5.651121 -4.226571
v  17.256060 6.406157 -3.331812
v  17.618109 6.577044 -4.556834
v  17.523338 5.982712 -4.746762
v  18.236275 5.422995 -4.388278
v  17.730835 6.426259 -4.390816
v  18.266609 6.105842 -4.367190
v  17.667969 6.455117 -4.398542
v  17.312222 6.339889 -3.320603
v  17.378283 6.304727 -3.408457
v  17.349201 5.948328 -4.210329
v  17.383335 6.398517 -3.308789
v  17.924582 6.612667 -4.579792
v  17.718597 6.537197 -4.221523
v  17.342100 6.372496 -3.313366
v  17.536663 5.615188 -4.197740
v  17.274654 6.174899 -4.101395
v  17.971846 6.603057 -4.488043
v  18.345871 5.434256 -4.391105
v  17.594234 5.573116 -4.063721
v  17.363073 6.313817 -3.315081
v  17.526464 5.439380 -4.088631
v  18.443703 6.021439 -4.489462
v  17.264484 5.979372 -3.953698
v  18.210707 5.941794 -4.553657
v  17.795906 6.534692 -4.228035
v  17.515852 5.542912 -4.092654
v  17.439796 6.362157 -3.310777
v  17.373215 6.269518 -3.367955
v  17.919054 6.510544 -4.418308
v  17.631981 5.456632 -4.005242
v  17.673054 5.587723 -4.108797
v  18.448545 5.699853 -4.288144
v  17.809616 5.505370 -5.001026
v  17.820971 5.263151 -5.047403
v  17.380184 6.341548 -3.398754
v  17.612370 5.470229 -4.895692
v  17.836660 6.990048 -4.526031
v  18.439419 6.206382 -4.597629
v  17.831320 5.469501 -4.173666
v  17.663143 6.855041 -4.292728
v  17.695801 6.978774 -4.551789
v  17.406906 6.350576 -4.776829
v  18.222837 6.294134 -4.420580
v  18.247772 5.812732 -4.565067
v  18.349489 5.585002 -4.493822
v  17.303648 6.311081 -3.404773
v  17.646465 6.592331 -4.579440
v  18.325842 5.690166 -4.265052
v  17.936043 6.049460 -4.354984
v  18.154467 5.481892 -4.927538
v  17.813320 6.941770 -4.629339
v  18.270473 6.340435 -4.477205
v  17.293955 6.158670 -4.122829
v  17.337151 6.301742 -3.286792
v  17.405243 6.250288 -4.431439
v  17.911385 6.286863 -4.459482
v  17.388319 6.407825 -3.349457
v  18.456715 6.160086 -4.528494
v  18.399324 6.102477 -4.670408
v  18.369791 5.603115 -4.229563
v  17.675766 6.738225 -4.657957
v  17.579407 5.848239 -4.587938
v  17.288347 6.206398 -4.636734
v  17.637005 5.535028 -4.173000
v  17.559805 5.568676 -4.027155
v  17.916590 6.346211 -4.866910
v  18.429916 5.901082 -4.567347
v  17.741581 6.984094 -4.403586
v  17.317816 6.337631 -3.375779
v  17.371134 6.028743 -4.720379
v  18.201216 5.967511 -4.951197
v  17.661329 6.385402 -4.565115
v  17.669254 6.175689 -4.474338
v  17.844032 6.458482 -4.291178
v  17.526623 6.068192 -4.628263
v  18.257420 6.080778 -4.877981
v  17.587360 5.416705 -4.860502
v  17.698841 6.595408 -4.216900
v  17.466301 6.164101 -4.317238
v  17.541273 5.440033 -4.069534
v  17.426098 6.076752 -4.209373
v  17.594006 5.879805 -4.488941
v  17.197765 6.195949 -3.846313
v  18.135216 5.843880 -4.438210
v  17.588821 5.828636 -4.947923
v  17.584343 5.461861 -4.050790
v  17.543814 5.743022 -4.851738
v  18.198620 5.910382 -4.472986
v  17.602003 6.669326 -4.535400
v  18.212339 5.416658 -4.860064
v  17.629711 6.044100 -5.000752
v  17.546202 5.639171 -4.192957
v  18.378540 5.961272 -4.313080
v  18.222322 5.902690 -4.546566
v  17.535486 5.607353 -4.101751
v  17.730892 6.592375 -4.197328
v  18.194128 5.403602 -4.245773
v  17.636110 5.457445 -4.086151
v  17.586296 6.832233 -4.471653
v  17.735193 6.478745 -4.238493
v  17.220922 5.938045 -4.078985
v  17.360394 6.323199 -3.273551
v  17.453554 6.218506 -3.384272
v  17.514223 5.482344 -4.062951
v  17.611391 5.428646 -4.108444
v  17.768719 5.619006 -4.475616
v  18.435509 5.610565 -4.476677
v  17.739479 5.421905 -4.203715
v  17.635462 5.580461 -4.116505
v  17.586258 5.606947 -4.033201
v  17.289618 6.232828 -4.472126
v  17.241541 6.143130 -4.157946
v  17.613665 5.406271 -4.122456
v  17.725784 6.865870 -4.265141
v  17.744280 5.550443 -4.109238
v  17.423840 6.288546 -3.380835
v  17.945013 5.038094 -4.551896
v  17.899204 5.738116 -4.440485
v  17.648142 6.281194 -4.948540
v  17.598930 6.878051 -4.413184
v  17.892981 6.901558 -4.359425
v  17.350918 6.253934 -3.277815
v  17.753683 6.402733 -4.422632
v  18.090942 6.238748 -4.911712
v  17.291960 6.143981 -3.684020
v  17.324953 6.135895 -3.684725
v  17.278173 6.351645 -3.294066
v  17.751547 6.365176 -4.882952
v  17.432981 6.274416 -3.303158
v  17.233547 6.123005 -4.454903
v  17.617697 6.324687 -4.538711
v  17.616190 6.327261 -4.535062
v  17.616293 6.329443 -4.531564
v  17.617989 6.330975 -4.528637
v  17.621075 6.331669 -4.526635
v  17.625179 6.331429 -4.525799
v  17.629807 6.330275 -4.526230
v  17.634399 6.328337 -4.527876
v  17.638403 6.325847 -4.530539
v  17.641333 6.323111 -4.533897
v  17.642838 6.320472 -4.537544
v  17.642736 6.318256 -4.541043
v  17.641041 6.316741 -4.543970
v  17.637955 6.316103 -4.545972
v  17.633850 6.316412 -4.546808
v  17.629223 6.317619 -4.546377
v  17.632753 6.320886 -4.540014
v  17.624039 6.350568 -4.555492
v  17.620626 6.322020 -4.542068
v  17.624630 6.319568 -4.544731
v  17.618383 6.345742 -4.565049
v  17.615444 6.348478 -4.561760
v  17.613935 6.351101 -4.558153
v  17.614037 6.353308 -4.554665
v  17.615734 6.354836 -4.551713
v  17.618826 6.355499 -4.549656
v  17.622938 6.355206 -4.548740
v  17.627575 6.353981 -4.549078
v  17.632177 6.351962 -4.550628
v  17.636189 6.349394 -4.553202
v  17.639126 6.346591 -4.556490
v  17.640636 6.343904 -4.560097
v  17.640535 6.341666 -4.563586
v  17.638836 6.340155 -4.566538
v  17.635744 6.339548 -4.568595
v  17.631632 6.339910 -4.569510
v  17.626997 6.341186 -4.569172
v  17.622395 6.343213 -4.567623
v  17.621103 6.299534 -4.515900
v  17.619606 6.302043 -4.512201
v  17.619709 6.304201 -4.508693
v  17.621401 6.305751 -4.505796
v  17.624477 6.306504 -4.503861
v  17.628567 6.306359 -4.503122
v  17.633177 6.305321 -4.503667
v  17.637751 6.303507 -4.505430
v  17.641739 6.301134 -4.508199
v  17.644657 6.298495 -4.511640
v  17.646154 6.295918 -4.515338
v  17.646051 6.293726 -4.518847
v  17.644360 6.292191 -4.521743
v  17.641283 6.291493 -4.523678
v  17.637194 6.291711 -4.524418
v  17.632584 6.292803 -4.523873
v  17.636105 6.296107 -4.517573
v  17.632851 6.320036 -4.539222
v  17.624022 6.296963 -4.519341
v  17.628008 6.294627 -4.522110
v  17.620726 6.321164 -4.541268
v  17.617794 6.323830 -4.537908
v  17.616289 6.326402 -4.534258
v  17.616390 6.328583 -4.530759
v  17.618088 6.330113 -4.527833
v  17.621174 6.330809 -4.525833
v  17.625278 6.330573 -4.525000
v  17.629904 6.329421 -4.525434
v  17.634497 6.327486 -4.527084
v  17.638498 6.325000 -4.529750
v  17.641430 6.322267 -4.533111
v  17.642935 6.319629 -4.536760
v  17.642834 6.317415 -4.540259
v  17.641136 6.315898 -4.543185
v  17.638050 6.315259 -4.545185
v  17.633947 6.315567 -4.546018
v  17.629320 6.316770 -4.545584
v  17.624727 6.318715 -4.543934
v  17.625624 6.274874 -4.494953
v  17.624140 6.277295 -4.491192
v  17.624247 6.279428 -4.487671
v  17.625929 6.281019 -4.484814
v  17.628986 6.281874 -4.482967
v  17.633049 6.281878 -4.482352
v  17.637627 6.281022 -4.483044
v  17.642168 6.279396 -4.484959
v  17.646126 6.277196 -4.487865
v  17.649019 6.274694 -4.491413
v  17.650503 6.272202 -4.495174
v  17.650398 6.270035 -4.498695
v  17.648714 6.268456 -4.501553
v  17.645658 6.267659 -4.503400
v  17.641594 6.267726 -4.504014
v  17.637016 6.268640 -4.503323
v  17.632303 6.276554 -4.491068
v  17.636238 6.295263 -4.516834
v  17.628519 6.272440 -4.498502
v  17.632475 6.270278 -4.501409
v  17.624157 6.296107 -4.518591
v  17.621239 6.298674 -4.515147
v  17.619741 6.301181 -4.511447
v  17.619844 6.303337 -4.507938
v  17.621536 6.304889 -4.505043
v  17.624613 6.305644 -4.503111
v  17.628702 6.305502 -4.502375
v  17.633312 6.304471 -4.502924
v  17.637886 6.302661 -4.504691
v  17.641870 6.300293 -4.507464
v  17.644789 6.297658 -4.510909
v  17.646286 6.295084 -4.514608
v  17.646183 6.292893 -4.518118
v  17.644491 6.291357 -4.521013
v  17.641417 6.290657 -4.522945
v  17.637327 6.290870 -4.523682
v  17.632717 6.291956 -4.523131
v  17.628143 6.293776 -4.521364
v  17.631308 6.251815 -4.476636
v  17.629852 6.254111 -4.472783
v  17.629963 6.256215 -4.469245
v  17.631628 6.257882 -4.466447
v  17.634645 6.258905 -4.464726
v  17.638651 6.259155 -4.464292
v  17.643162 6.258584 -4.465195
v  17.647635 6.257254 -4.467326
v  17.651529 6.255321 -4.470430
v  17.654375 6.253026 -4.474132
v  17.655830 6.250659 -4.477984
v  17.655720 6.248517 -4.481523
v  17.654055 6.246864 -4.484322
v  17.651037 6.245899 -4.486041
v  17.647032 6.245727 -4.486476
v  17.642521 6.246356 -4.485574
v  17.637899 6.253868 -4.473028
v  17.632477 6.275740 -4.490400
v  17.634153 6.249594 -4.480337
v  17.638048 6.247701 -4.483442
v  17.628693 6.271623 -4.497830
v  17.625799 6.274050 -4.494278
v  17.624315 6.276469 -4.490514
v  17.624422 6.278600 -4.486992
v  17.626104 6.280193 -4.484137
v  17.629160 6.281052 -4.482293
v  17.633223 6.281064 -4.481684
v  17.637798 6.280215 -4.482381
v  17.642338 6.278596 -4.484302
v  17.646294 6.276404 -4.487214
v  17.649187 6.273909 -4.490767
v  17.650671 6.271420 -4.494531
v  17.650564 6.269253 -4.498053
v  17.648882 6.267673 -4.500908
v  17.645826 6.266870 -4.502752
v  17.641764 6.266932 -4.503361
v  17.637188 6.267838 -4.502664
v  17.632648 6.269468 -4.500743
v  17.643080 6.218529 -4.452807
v  17.641817 6.220495 -4.448726
v  17.642052 6.222463 -4.445111
v  17.643759 6.224199 -4.442400
v  17.646730 6.225492 -4.440919
v  17.650608 6.226172 -4.440845
v  17.654924 6.226147 -4.442190
v  17.659159 6.225405 -4.444790
v  17.662800 6.224036 -4.448331
v  17.665411 6.222213 -4.452387
v  17.666674 6.220168 -4.456469
v  17.666437 6.218162 -4.460083
v  17.664732 6.216443 -4.462794
v  17.661760 6.215217 -4.464275
v  17.657883 6.214620 -4.464349
v  17.653566 6.214709 -4.463004
v  17.649567 6.221171 -4.449724
v  17.638111 6.253136 -4.472466
v  17.645689 6.216784 -4.456864
v  17.649332 6.215462 -4.460405
v  17.634367 6.248854 -4.479770
v  17.631523 6.251067 -4.476063
v  17.630070 6.253356 -4.472206
v  17.630182 6.255460 -4.468666
v  17.631845 6.257130 -4.465871
v  17.634861 6.258162 -4.464156
v  17.638863 6.258421 -4.463729
v  17.643372 6.257864 -4.464641
v  17.647840 6.256546 -4.466782
v  17.651731 6.254626 -4.469895
v  17.654575 6.252341 -4.473602
v  17.656029 6.249978 -4.477459
v  17.655918 6.247836 -4.480998
v  17.654255 6.246180 -4.483794
v  17.651239 6.245207 -4.485509
v  17.647236 6.245025 -4.485936
v  17.642729 6.245642 -4.485023
v  17.638258 6.246972 -4.482882
v  17.669970 6.218251 -4.462980
v  17.658880 6.185313 -4.432118
v  17.657793 6.187026 -4.427885
v  17.658117 6.188931 -4.424245
v  17.659815 6.190804 -4.421637
v  17.662682 6.192417 -4.420376
v  17.666370 6.193560 -4.420615
v  17.670437 6.194078 -4.422324
v  17.674389 6.193902 -4.425299
v  17.677753 6.193048 -4.429178
v  17.680120 6.191629 -4.433496
v  17.681208 6.189834 -4.437730
v  17.680883 6.187889 -4.441370
v  17.679186 6.186038 -4.443978
v  17.676319 6.184502 -4.445239
v  17.672630 6.183451 -4.445000
v  17.668566 6.182997 -4.443291
v  17.665133 6.188656 -4.429522
v  17.650045 6.220037 -4.448977
v  17.661247 6.183984 -4.436437
v  17.664612 6.183183 -4.440316
v  17.646166 6.215645 -4.456110
v  17.643564 6.217371 -4.452043
v  17.642307 6.219328 -4.447955
v  17.642548 6.221292 -4.444338
v  17.644255 6.223032 -4.441631
v  17.647223 6.224336 -4.440156
v  17.651094 6.225033 -4.440095
v  17.655403 6.225025 -4.441453
v  17.659628 6.224304 -4.444067
v  17.663261 6.222954 -4.447623
v  17.665863 6.221146 -4.451689
v  17.667120 6.219110 -4.455777
v  17.666880 6.217108 -4.459393
v  17.665173 6.215385 -4.462101
v  17.662205 6.214149 -4.463576
v  17.658331 6.213537 -4.463636
v  17.654024 6.213607 -4.462279
v  17.649797 6.214340 -4.459663
v  17.684078 6.188456 -4.444535
v  17.677305 6.154043 -4.414504
v  17.676344 6.155609 -4.410187
v  17.676697 6.157545 -4.406565
v  17.678322 6.159625 -4.404075
v  17.681025 6.161592 -4.403017
v  17.684477 6.163194 -4.403520
v  17.688265 6.164222 -4.405521
v  17.691927 6.164540 -4.408781
v  17.695026 6.164109 -4.412907
v  17.697187 6.162992 -4.417399
v  17.698149 6.161339 -4.421716
v  17.697796 6.159364 -4.425338
v  17.696169 6.157311 -4.427828
v  17.693468 6.155426 -4.428886
v  17.690014 6.153922 -4.428383
v  17.686228 6.152961 -4.426381
v  17.691294 6.158859 -4.419554
v  17.665716 6.187570 -4.428885
v  17.679466 6.153020 -4.418996
v  17.682564 6.152647 -4.423120
v  17.661835 6.182885 -4.435791
v  17.659475 6.184202 -4.431467
v  17.658392 6.185908 -4.427228
v  17.658720 6.187812 -4.423588
v  17.660416 6.189692 -4.420983
v  17.663279 6.191316 -4.419730
v  17.666960 6.192473 -4.419979
v  17.671017 6.193012 -4.421700
v  17.674959 6.192852 -4.424685
v  17.678314 6.192016 -4.428574
v  17.680674 6.190609 -4.432899
v  17.681757 6.188819 -4.437137
v  17.681429 6.186876 -4.440778
v  17.679733 6.185019 -4.443381
v  17.676870 6.183471 -4.444635
v  17.673189 6.182404 -4.444386
v  17.669132 6.181931 -4.442666
v  17.665190 6.182100 -4.439681
v  17.697136 6.125957 -4.399463
v  17.696220 6.127499 -4.395126
v  17.696518 6.129574 -4.391568
v  17.697990 6.131936 -4.389218
v  17.700460 6.134293 -4.388359
v  17.703630 6.136348 -4.389095
v  17.707119 6.137834 -4.391337
v  17.710503 6.138561 -4.394816
v  17.713377 6.138442 -4.399110
v  17.715393 6.137503 -4.403702
v  17.716309 6.135871 -4.408038
v  17.716011 6.133761 -4.411596
v  17.714539 6.131433 -4.413947
v  17.712069 6.129160 -4.414805
v  17.708899 6.127206 -4.414069
v  17.705410 6.125786 -4.411827
v  17.709768 6.133955 -4.402395
v  17.691921 6.157898 -4.419031
v  17.699152 6.125115 -4.404056
v  17.702024 6.125060 -4.408349
v  17.680122 6.152007 -4.418446
v  17.677969 6.153020 -4.413949
v  17.677010 6.154583 -4.409630
v  17.677362 6.156523 -4.406009
v  17.678986 6.158611 -4.403524
v  17.681681 6.160591 -4.402472
v  17.685123 6.162208 -4.402984
v  17.688900 6.163253 -4.404995
v  17.692554 6.163586 -4.408263
v  17.695644 6.163167 -4.412395
v  17.697800 6.162059 -4.416891
v  17.698757 6.160408 -4.421210
v  17.698404 6.158431 -4.424831
v  17.696783 6.156371 -4.427317
v  17.694088 6.154473 -4.428368
v  17.690643 6.152953 -4.427857
v  17.686867 6.151976 -4.425845
v  17.683212 6.151647 -4.422577
v  17.717209 6.102168 -4.386468
v  17.716217 6.103835 -4.382185
v  17.716326 6.106177 -4.378756
v  17.717522 6.108918 -4.376592
v  17.719660 6.111719 -4.375955
v  17.722483 6.114228 -4.376923
v  17.725649 6.116124 -4.379378
v  17.728779 6.117169 -4.383023
v  17.731493 6.117239 -4.387422
v  17.733465 6.116335 -4.392040
v  17.734455 6.114583 -4.396322
v  17.734346 6.112210 -4.399753
v  17.733152 6.109506 -4.401917
v  17.731014 6.106791 -4.402553
v  17.728191 6.104380 -4.401586
v  17.725023 6.102545 -4.399131
v  17.722218 6.106553 -4.388185
v  17.710653 6.131700 -4.404981
v  17.719181 6.101362 -4.391087
v  17.721895 6.101497 -4.395484
v  17.699839 6.124227 -4.403584
v  17.697826 6.125063 -4.398989
v  17.696911 6.126609 -4.394653
v  17.697203 6.128690 -4.391098
v  17.698669 6.131062 -4.388753
v  17.701130 6.133435 -4.387901
v  17.704290 6.135505 -4.388644
v  17.707767 6.137005 -4.390895
v  17.711143 6.137744 -4.394379
v  17.714010 6.137635 -4.398678
v  17.716022 6.136699 -4.403273
v  17.716938 6.135067 -4.407608
v  17.716644 6.132950 -4.411162
v  17.715179 6.130610 -4.413508
v  17.712719 6.128324 -4.414360
v  17.709558 6.126353 -4.413616
v  17.706081 6.124918 -4.411366
v  17.702705 6.124180 -4.407882
v  17.740540 6.080268 -4.372801
v  17.739416 6.082141 -4.368612
v  17.739216 6.084860 -4.365385
v  17.739960 6.088099 -4.363509
v  17.741560 6.091462 -4.363211
v  17.743822 6.094526 -4.364525
v  17.746473 6.096909 -4.367293
v  17.749195 6.098312 -4.371183
v  17.751657 6.098571 -4.375724
v  17.753565 6.097662 -4.380369
v  17.754688 6.095715 -4.384558
v  17.754889 6.092973 -4.387785
v  17.754145 6.089774 -4.389661
v  17.752544 6.086494 -4.389959
v  17.750284 6.083519 -4.388645
v  17.747631 6.081190 -4.385877
v  17.750059 6.090544 -4.380883
v  17.722528 6.107414 -4.384826
v  17.742447 6.079453 -4.377446
v  17.744909 6.079778 -4.381988
v  17.719860 6.100633 -4.390668
v  17.717888 6.101440 -4.386050
v  17.716892 6.103115 -4.381771
v  17.716990 6.105470 -4.378347
v  17.718172 6.108224 -4.376191
v  17.720297 6.111042 -4.375563
v  17.723106 6.113566 -4.376539
v  17.726261 6.115477 -4.379001
v  17.729382 6.116532 -4.382652
v  17.732092 6.116606 -4.387053
v  17.734066 6.115701 -4.391670
v  17.735062 6.113942 -4.395949
v  17.734964 6.111556 -4.399374
v  17.733780 6.108837 -4.401529
v  17.731657 6.106106 -4.402158
v  17.728848 6.103678 -4.401181
v  17.725693 6.101829 -4.398719
v  17.722570 6.100771 -4.395068
v  17.772497 6.060287 -4.358689
v  17.771362 6.062292 -4.354527
v  17.770754 6.065370 -4.351507
v  17.770748 6.069153 -4.349992
v  17.771343 6.073175 -4.350165
v  17.772470 6.076941 -4.352003
v  17.773989 6.079985 -4.355288
v  17.775721 6.081931 -4.359621
v  17.777452 6.082548 -4.364481
v  17.778980 6.081774 -4.369281
v  17.780115 6.079714 -4.373442
v  17.780722 6.076626 -4.376462
v  17.780729 6.072884 -4.377976
v  17.780132 6.068933 -4.377806
v  17.779007 6.065237 -4.375966
v  17.777487 6.062230 -4.372682
v  17.775066 6.074482 -4.360647
v  17.750990 6.089802 -4.380395
v  17.774023 6.059589 -4.363489
v  17.775757 6.060267 -4.368349
v  17.743481 6.078623 -4.376898
v  17.741585 6.079432 -4.372248
v  17.740463 6.081310 -4.368059
v  17.740253 6.084040 -4.364839
v  17.740978 6.087296 -4.362974
v  17.742552 6.090683 -4.362690
v  17.744785 6.093771 -4.364021
v  17.747406 6.096178 -4.366808
v  17.750101 6.097600 -4.370712
v  17.752544 6.097873 -4.375263
v  17.754440 6.096970 -4.379914
v  17.755562 6.095017 -4.384102
v  17.755772 6.092266 -4.387322
v  17.755047 6.089047 -4.389187
v  17.753473 6.085747 -4.389471
v  17.751240 6.082746 -4.388140
v  17.748619 6.080393 -4.385354
v  17.745924 6.078959 -4.381450
v  17.806801 6.052298 -4.350072
v  17.805548 6.054451 -4.345946
v  17.804234 6.057777 -4.343111
v  17.803015 6.061880 -4.341908
v  17.802038 6.066257 -4.342481
v  17.801424 6.070374 -4.344761
v  17.801243 6.073729 -4.348475
v  17.801519 6.075914 -4.353172
v  17.802219 6.076672 -4.358288
v  17.803257 6.075912 -4.363205
v  17.804508 6.073738 -4.367331
v  17.805824 6.070413 -4.370166
v  17.807041 6.066336 -4.371369
v  17.808018 6.061996 -4.370797
v  17.808634 6.057911 -4.368516
v  17.808813 6.054568 -4.364803
v  17.805801 6.064982 -4.362291
v  17.777996 6.068386 -4.368236
v  17.807838 6.051577 -4.354989
v  17.808538 6.052368 -4.360105
v  17.775154 6.059093 -4.363096
v  17.773643 6.059789 -4.358291
v  17.772505 6.061798 -4.354131
v  17.771879 6.064888 -4.351118
v  17.771837 6.068687 -4.349615
v  17.772388 6.072731 -4.349804
v  17.773464 6.076519 -4.351661
v  17.774935 6.079582 -4.354963
v  17.776623 6.081542 -4.359311
v  17.778324 6.082170 -4.364182
v  17.779837 6.081399 -4.368987
v  17.780973 6.079336 -4.373146
v  17.781601 6.076237 -4.376160
v  17.781641 6.072476 -4.377663
v  17.781090 6.068505 -4.377474
v  17.780014 6.064786 -4.375617
v  17.778543 6.061759 -4.372314
v  17.776855 6.059781 -4.367967
v  17.840542 6.060452 -4.349061
v  17.839127 6.062692 -4.344942
v  17.837059 6.065926 -4.342134
v  17.834585 6.069762 -4.340975
v  17.832005 6.073740 -4.341604
v  17.829630 6.077381 -4.343946
v  17.827747 6.080245 -4.347720
v  17.826584 6.081992 -4.352468
v  17.826277 6.082409 -4.357619
v  17.826868 6.081448 -4.362552
v  17.828283 6.079221 -4.366670
v  17.830351 6.075994 -4.369478
v  17.832825 6.072155 -4.370638
v  17.835405 6.068170 -4.370008
v  17.837780 6.064516 -4.367665
v  17.839663 6.061640 -4.363893
v  17.832418 6.072880 -4.361053
v  17.806759 6.064970 -4.362154
v  17.841133 6.059478 -4.353993
v  17.840828 6.059891 -4.359144
v  17.809010 6.051567 -4.354820
v  17.807989 6.052294 -4.349900
v  17.806732 6.054450 -4.345777
v  17.805389 6.057781 -4.342945
v  17.804125 6.061883 -4.341748
v  17.803089 6.066259 -4.342330
v  17.802408 6.070374 -4.344620
v  17.802164 6.073728 -4.348343
v  17.802385 6.075911 -4.353048
v  17.803047 6.076663 -4.358170
v  17.804068 6.075902 -4.363090
v  17.805325 6.073724 -4.367213
v  17.806667 6.070395 -4.370045
v  17.807932 6.066317 -4.371242
v  17.808968 6.061976 -4.370660
v  17.809649 6.057892 -4.368370
v  17.809893 6.054550 -4.364648
v  17.809671 6.052352 -4.359941
v  17.777941 6.492326 -4.786843
v  17.777287 6.495784 -4.784272
v  17.776793 6.498372 -4.780706
v  17.776520 6.499775 -4.776574
v  17.776499 6.499818 -4.772375
v  17.776735 6.498480 -4.768617
v  17.777199 6.495912 -4.765751
v  17.777832 6.492423 -4.764124
v  17.778563 6.488439 -4.763932
v  17.779299 6.484448 -4.765198
v  17.779955 6.480949 -4.767770
v  17.780449 6.478369 -4.771337
v  17.780722 6.477015 -4.775468
v  17.780743 6.477046 -4.779666
v  17.780506 6.478443 -4.783424
v  17.780043 6.481028 -4.786290
v  17.778923 6.486912 -4.780586
v  17.807158 6.492043 -4.769408
v  17.778679 6.488407 -4.788110
v  17.779408 6.484487 -4.787917
v  17.809927 6.489484 -4.785265
v  17.809164 6.493537 -4.783922
v  17.808277 6.497071 -4.781306
v  17.807377 6.499661 -4.777732
v  17.806570 6.500999 -4.773629
v  17.805954 6.500916 -4.769494
v  17.805603 6.499414 -4.765825
v  17.805559 6.496669 -4.763064
v  17.805830 6.493005 -4.761546
v  17.806379 6.488869 -4.761452
v  17.807144 6.484766 -4.762794
v  17.808029 6.481203 -4.765409
v  17.808931 6.478610 -4.768985
v  17.809738 6.477302 -4.773088
v  17.810354 6.477432 -4.777223
v  17.810705 6.478976 -4.780890
v  17.810747 6.481740 -4.783652
v  17.810478 6.485389 -4.785171
v  17.744402 6.491186 -4.784795
v  17.743959 6.494581 -4.782354
v  17.743923 6.497188 -4.778901
v  17.744301 6.498688 -4.774854
v  17.745043 6.498888 -4.770703
v  17.746063 6.497749 -4.766947
v  17.747236 6.495392 -4.764039
v  17.748421 6.492096 -4.762330
v  17.749475 6.488269 -4.762026
v  17.750271 6.484385 -4.763164
v  17.750713 6.480933 -4.765606
v  17.750750 6.478335 -4.769059
v  17.750372 6.476908 -4.773105
v  17.749630 6.476807 -4.777256
v  17.748611 6.478029 -4.781012
v  17.747438 6.480412 -4.783921
v  17.748217 6.483611 -4.775237
v  17.777773 6.486885 -4.780605
v  17.745197 6.487400 -4.785933
v  17.746252 6.483664 -4.785630
v  17.777494 6.488366 -4.788132
v  17.776768 6.492280 -4.786873
v  17.776127 6.495737 -4.784307
v  17.775652 6.498327 -4.780744
v  17.775398 6.499734 -4.776614
v  17.775398 6.499784 -4.772415
v  17.775650 6.498454 -4.768652
v  17.776125 6.495894 -4.765782
v  17.776764 6.492414 -4.764149
v  17.777493 6.488435 -4.763950
v  17.778219 6.484448 -4.765210
v  17.778860 6.480951 -4.767775
v  17.779335 6.478369 -4.771338
v  17.779589 6.477012 -4.775468
v  17.779589 6.477036 -4.779667
v  17.779337 6.478425 -4.783430
v  17.778862 6.481002 -4.786300
v  17.778223 6.484453 -4.787934
v  17.712500 6.488264 -4.775226
v  17.711796 6.491556 -4.772737
v  17.711945 6.494133 -4.769363
v  17.712931 6.495687 -4.765512
v  17.714634 6.496014 -4.761651
v  17.716848 6.495053 -4.758243
v  17.719307 6.492902 -4.755698
v  17.721712 6.489811 -4.754325
v  17.723776 6.486164 -4.754290
v  17.725248 6.482417 -4.755595
v  17.725952 6.479042 -4.758084
v  17.725801 6.476463 -4.761458
v  17.724815 6.474988 -4.765306
v  17.723114 6.474786 -4.769169
v  17.720900 6.475857 -4.772578
v  17.718441 6.478053 -4.775123
v  17.716993 6.485121 -4.769678
v  17.747215 6.483580 -4.775060
v  17.713972 6.484641 -4.776531
v  17.716036 6.481101 -4.776495
v  17.744087 6.487353 -4.785741
v  17.743277 6.491134 -4.784604
v  17.742832 6.494525 -4.782164
v  17.742809 6.497132 -4.778715
v  17.743208 6.498635 -4.774674
v  17.743982 6.498841 -4.770528
v  17.745039 6.497706 -4.766777
v  17.746250 6.495356 -4.763873
v  17.747469 6.492065 -4.762167
v  17.748549 6.488244 -4.761865
v  17.749359 6.484364 -4.763002
v  17.749804 6.480913 -4.765443
v  17.749828 6.478317 -4.768891
v  17.749428 6.476886 -4.772932
v  17.748653 6.476782 -4.777078
v  17.747597 6.478000 -4.780828
v  17.746386 6.480377 -4.783732
v  17.745167 6.483623 -4.785439
v  17.686123 6.481704 -4.759126
v  17.685112 6.484889 -4.756491
v  17.685265 6.487428 -4.753145
v  17.686562 6.489015 -4.749495
v  17.688848 6.489447 -4.745976
v  17.691847 6.488647 -4.743017
v  17.695198 6.486697 -4.740973
v  17.698494 6.483819 -4.740089
v  17.701342 6.480367 -4.740476
v  17.703394 6.476776 -4.742083
v  17.704405 6.473499 -4.744718
v  17.704252 6.470947 -4.748062
v  17.702953 6.469431 -4.751714
v  17.700668 6.469121 -4.755231
v  17.697668 6.470036 -4.758191
v  17.694319 6.472043 -4.760235
v  17.692232 6.478894 -4.754491
v  17.720264 6.481291 -4.764961
v  17.688175 6.478238 -4.760733
v  17.691021 6.474890 -4.761118
v  17.712950 6.484478 -4.776062
v  17.711447 6.488095 -4.774744
v  17.710730 6.491382 -4.772248
v  17.710880 6.493957 -4.768876
v  17.711884 6.495512 -4.765034
v  17.713617 6.495842 -4.761186
v  17.715872 6.494890 -4.757795
v  17.718374 6.492747 -4.755272
v  17.720827 6.489666 -4.753920
v  17.722931 6.486026 -4.753901
v  17.724432 6.482285 -4.755219
v  17.725149 6.478915 -4.757715
v  17.724998 6.476336 -4.761087
v  17.723995 6.474861 -4.764929
v  17.722263 6.474654 -4.768777
v  17.720007 6.475718 -4.772167
v  17.717505 6.477907 -4.774692
v  17.715052 6.480947 -4.776044
v  17.667688 6.475826 -4.744164
v  17.666662 6.479010 -4.741586
v  17.666874 6.481562 -4.738345
v  17.668299 6.483177 -4.734832
v  17.670765 6.483646 -4.731471
v  17.673973 6.482894 -4.728667
v  17.677538 6.480990 -4.726759
v  17.681030 6.478156 -4.725976
v  17.684027 6.474735 -4.726414
v  17.686169 6.471159 -4.728018
v  17.687195 6.467880 -4.730597
v  17.686983 6.465310 -4.733837
v  17.685558 6.463762 -4.737350
v  17.683094 6.463410 -4.740711
v  17.679884 6.464281 -4.743515
v  17.676319 6.466244 -4.745423
v  17.672989 6.475667 -4.738200
v  17.690512 6.482256 -4.751130
v  17.669830 6.472375 -4.745769
v  17.672827 6.469054 -4.746206
v  17.687563 6.478044 -4.760271
v  17.685511 6.481510 -4.758668
v  17.684502 6.484695 -4.756038
v  17.684658 6.487236 -4.752699
v  17.685959 6.488825 -4.749052
v  17.688250 6.489258 -4.745538
v  17.691254 6.488461 -4.742581
v  17.694607 6.486511 -4.740537
v  17.697906 6.483633 -4.739653
v  17.700754 6.480182 -4.740035
v  17.702806 6.476589 -4.741638
v  17.703814 6.473310 -4.744268
v  17.703659 6.470757 -4.747608
v  17.702356 6.469241 -4.751255
v  17.700066 6.468930 -4.754767
v  17.697062 6.469841 -4.757726
v  17.693708 6.471848 -4.759769
v  17.690409 6.474695 -4.760653
v  17.649446 6.468574 -4.725546
v  17.648283 6.471708 -4.722861
v  17.648485 6.474235 -4.719636
v  17.650026 6.475853 -4.716261
v  17.652721 6.476357 -4.713143
v  17.656246 6.475668 -4.710656
v  17.660175 6.473847 -4.709104
v  17.664034 6.471107 -4.708670
v  17.667356 6.467780 -4.709409
v  17.669744 6.464282 -4.711230
v  17.670908 6.461056 -4.713916
v  17.670708 6.458506 -4.717140
v  17.669167 6.456944 -4.720515
v  17.666471 6.456551 -4.723634
v  17.662947 6.457352 -4.726120
v  17.659018 6.459233 -4.727674
v  17.658310 6.469986 -4.715422
v  17.672365 6.475451 -4.737639
v  17.651834 6.465194 -4.727368
v  17.655159 6.461955 -4.728106
v  17.669195 6.472159 -4.745198
v  17.667048 6.475607 -4.743590
v  17.666019 6.478792 -4.741010
v  17.666231 6.481344 -4.737771
v  17.667660 6.482959 -4.734262
v  17.670134 6.483429 -4.730909
v  17.673351 6.482680 -4.728113
v  17.676928 6.480779 -4.726213
v  17.680429 6.477945 -4.725439
v  17.683437 6.474526 -4.725883
v  17.685585 6.470953 -4.727490
v  17.686613 6.467675 -4.730071
v  17.686401 6.465104 -4.733310
v  17.684973 6.463554 -4.736818
v  17.682499 6.463202 -4.740171
v  17.679281 6.464071 -4.742968
v  17.675705 6.466032 -4.744867
v  17.672201 6.468841 -4.745642
v  17.687271 6.460265 -4.736814
v  17.633499 6.458317 -4.702847
v  17.632162 6.461355 -4.699930
v  17.632318 6.463827 -4.696653
v  17.633947 6.465439 -4.693409
v  17.636852 6.465989 -4.690592
v  17.640682 6.465393 -4.688540
v  17.644978 6.463707 -4.687501
v  17.649218 6.461124 -4.687601
v  17.652893 6.457961 -4.688828
v  17.655560 6.454607 -4.691033
v  17.656895 6.451487 -4.693951
v  17.656740 6.448989 -4.697228
v  17.655111 6.447422 -4.700472
v  17.652206 6.446966 -4.703289
v  17.648376 6.447659 -4.705339
v  17.644081 6.449403 -4.706379
v  17.641582 6.460128 -4.694504
v  17.654676 6.468209 -4.719418
v  17.636166 6.455066 -4.705051
v  17.639841 6.451973 -4.706279
v  17.651249 6.464906 -4.726670
v  17.648851 6.468283 -4.724838
v  17.647682 6.471414 -4.722146
v  17.647881 6.473939 -4.718921
v  17.649426 6.475559 -4.715550
v  17.652128 6.476064 -4.712441
v  17.655664 6.475377 -4.709969
v  17.659607 6.473559 -4.708431
v  17.663481 6.470824 -4.708014
v  17.666817 6.467500 -4.708766
v  17.669214 6.464005 -4.710599
v  17.670383 6.460783 -4.713290
v  17.670183 6.458235 -4.716516
v  17.668638 6.456673 -4.719886
v  17.665936 6.456275 -4.722995
v  17.662399 6.457075 -4.725468
v  17.658457 6.458951 -4.727006
v  17.654585 6.461670 -4.727424
v  17.621876 6.443089 -4.675735
v  17.620409 6.445981 -4.672521
v  17.620523 6.448370 -4.669146
v  17.622200 6.449972 -4.666018
v  17.625240 6.450588 -4.663513
v  17.629276 6.450130 -4.661935
v  17.633820 6.448639 -4.661473
v  17.638327 6.446289 -4.662184
v  17.642248 6.443362 -4.663981
v  17.645117 6.440221 -4.666648
v  17.646582 6.437259 -4.669863
v  17.646469 6.434844 -4.673238
v  17.644791 6.433275 -4.676366
v  17.641750 6.432733 -4.678870
v  17.637716 6.433273 -4.680448
v  17.633171 6.434813 -4.680909
v  17.630327 6.445076 -4.668245
v  17.641119 6.459704 -4.693679
v  17.624743 6.440032 -4.678402
v  17.628666 6.437160 -4.680199
v  17.635693 6.454644 -4.704213
v  17.633018 6.457889 -4.701993
v  17.631676 6.460923 -4.699066
v  17.631830 6.463393 -4.695786
v  17.633461 6.465004 -4.692546
v  17.636372 6.465557 -4.689739
v  17.640211 6.464964 -4.687704
v  17.644518 6.463284 -4.686686
v  17.648769 6.460709 -4.686806
v  17.652456 6.457551 -4.688051
v  17.655130 6.454204 -4.690270
v  17.656471 6.451088 -4.693198
v  17.656319 6.448594 -4.696479
v  17.654688 6.447026 -4.699718
v  17.651777 6.446567 -4.702524
v  17.647936 6.447256 -4.704559
v  17.643631 6.448994 -4.705579
v  17.639378 6.451559 -4.705459
v  17.617100 6.427691 -4.653174
v  17.615595 6.430476 -4.649780
v  17.615696 6.432803 -4.646345
v  17.617388 6.434393 -4.643284
v  17.620470 6.435054 -4.640966
v  17.624569 6.434690 -4.639670
v  17.629189 6.433336 -4.639554
v  17.633776 6.431145 -4.640630
v  17.637774 6.428382 -4.642769
v  17.640701 6.425390 -4.645714
v  17.642206 6.422541 -4.649108
v  17.642105 6.420187 -4.652544
v  17.640413 6.418621 -4.655605
v  17.637331 6.418025 -4.657922
v  17.633232 6.418462 -4.659218
v  17.628613 6.419866 -4.659335
v  17.625666 6.429790 -4.646190
v  17.630037 6.444445 -4.667263
v  17.620028 6.424772 -4.656120
v  17.624025 6.422057 -4.658258
v  17.624449 6.439402 -4.677408
v  17.621576 6.442451 -4.674725
v  17.620108 6.445338 -4.671500
v  17.620220 6.447722 -4.668120
v  17.621899 6.449325 -4.664996
v  17.624941 6.449943 -4.662503
v  17.628981 6.449491 -4.660940
v  17.633532 6.448008 -4.660498
v  17.638044 6.445667 -4.661230
v  17.641972 6.442749 -4.663046
v  17.644844 6.439616 -4.665729
v  17.646313 6.436661 -4.668953
v  17.646200 6.434249 -4.672332
v  17.644522 6.432680 -4.675457
v  17.641479 6.432135 -4.677951
v  17.637440 6.432669 -4.679513
v  17.632889 6.434202 -4.679955
v  17.628378 6.436540 -4.679224
v  17.615017 6.412520 -4.633254
v  17.613506 6.415262 -4.629789
v  17.613605 6.417556 -4.626333
v  17.615303 6.419131 -4.623307
v  17.618393 6.419792 -4.621073
v  17.622505 6.419449 -4.619902
v  17.627142 6.418130 -4.619936
v  17.631744 6.415988 -4.621168
v  17.635757 6.413282 -4.623451
v  17.638697 6.410345 -4.626510
v  17.640207 6.407540 -4.629976
v  17.640108 6.405220 -4.633430
v  17.638411 6.403666 -4.636457
v  17.635319 6.403067 -4.638690
v  17.631208 6.403480 -4.639861
v  17.626572 6.404846 -4.639828
v  17.623610 6.414620 -4.626502
v  17.625576 6.429326 -4.645561
v  17.617956 6.409653 -4.636312
v  17.621969 6.406990 -4.638596
v  17.619938 6.424308 -4.655485
v  17.617010 6.427225 -4.652535
v  17.615505 6.430007 -4.649138
v  17.615604 6.432333 -4.645702
v  17.617298 6.433925 -4.642642
v  17.620380 6.434585 -4.640329
v  17.624479 6.434223 -4.639038
v  17.629101 6.432869 -4.638927
v  17.633688 6.430682 -4.640009
v  17.637688 6.427922 -4.642155
v  17.640615 6.424931 -4.645105
v  17.642120 6.422082 -4.648502
v  17.642021 6.419732 -4.651937
v  17.640327 6.418166 -4.654997
v  17.637245 6.417570 -4.657313
v  17.633146 6.418005 -4.658602
v  17.628525 6.419405 -4.658713
v  17.623938 6.421595 -4.657629
v  17.614031 6.394269 -4.611136
v  17.612518 6.396974 -4.607617
v  17.612617 6.399238 -4.604150
v  17.614317 6.400793 -4.601150
v  17.617413 6.401448 -4.598981
v  17.621529 6.401112 -4.597904
v  17.626171 6.399815 -4.598048
v  17.630777 6.397706 -4.599397
v  17.634794 6.395037 -4.601788
v  17.637737 6.392138 -4.604932
v  17.639250 6.389371 -4.608450
v  17.639151 6.387080 -4.611918
v  17.637451 6.385545 -4.614918
v  17.634357 6.384949 -4.617088
v  17.630241 6.385354 -4.618165
v  17.625599 6.386700 -4.618021
v  17.622633 6.396346 -4.604560
v  17.623558 6.414043 -4.625783
v  17.616974 6.391439 -4.614280
v  17.620991 6.388813 -4.616671
v  17.617905 6.409078 -4.635590
v  17.614965 6.411944 -4.632528
v  17.613453 6.414684 -4.629060
v  17.613552 6.416976 -4.625605
v  17.615250 6.418550 -4.622580
v  17.618341 6.419211 -4.620349
v  17.622454 6.418869 -4.619182
v  17.627090 6.417552 -4.619219
v  17.631695 6.415411 -4.620456
v  17.635708 6.412707 -4.622743
v  17.638647 6.409770 -4.625805
v  17.640158 6.406968 -4.629275
v  17.640059 6.404648 -4.632728
v  17.638361 6.403096 -4.635754
v  17.635269 6.402494 -4.637985
v  17.631157 6.402909 -4.639152
v  17.626520 6.404274 -4.639114
v  17.621918 6.406415 -4.637878
v  17.614134 6.373317 -4.587609
v  17.612621 6.375985 -4.584047
v  17.612720 6.378220 -4.580568
v  17.614420 6.379759 -4.577591
v  17.617516 6.380410 -4.575477
v  17.621632 6.380085 -4.574480
v  17.626272 6.378814 -4.574720
v  17.630880 6.376740 -4.576168
v  17.634895 6.374112 -4.578649
v  17.637838 6.371254 -4.581866
v  17.639351 6.368523 -4.585428
v  17.639250 6.366259 -4.588907
v  17.637550 6.364738 -4.591883
v  17.634457 6.364144 -4.593997
v  17.630341 6.364537 -4.594994
v  17.625698 6.365858 -4.594755
v  17.622734 6.375380 -4.581184
v  17.622620 6.395669 -4.603776
v  17.617075 6.370527 -4.590825
v  17.621092 6.367939 -4.593306
v  17.616961 6.390765 -4.613493
v  17.614017 6.393594 -4.610346
v  17.612505 6.396297 -4.606826
v  17.612604 6.398560 -4.603358
v  17.614304 6.400114 -4.600359
v  17.617397 6.400768 -4.598192
v  17.621513 6.400433 -4.597118
v  17.626156 6.399136 -4.597266
v  17.630764 6.397027 -4.598618
v  17.634781 6.394361 -4.601013
v  17.637724 6.391465 -4.604159
v  17.639236 6.388699 -4.607679
v  17.639137 6.386408 -4.611147
v  17.637438 6.384872 -4.614146
v  17.634342 6.384278 -4.616313
v  17.630226 6.384682 -4.617388
v  17.625584 6.386026 -4.617240
v  17.620977 6.388140 -4.615887
v  17.615318 6.350198 -4.563489
v  17.613808 6.352825 -4.559885
v  17.613909 6.355032 -4.556396
v  17.615608 6.356562 -4.553443
v  17.618700 6.357223 -4.551382
v  17.622812 6.356926 -4.550462
v  17.627449 6.355698 -4.550793
v  17.632051 6.353674 -4.552335
v  17.636065 6.351101 -4.554903
v  17.639002 6.348295 -4.558187
v  17.640512 6.345603 -4.561790
v  17.640411 6.343365 -4.565279
v  17.638712 6.341853 -4.568232
v  17.635620 6.341247 -4.570292
v  17.631508 6.341613 -4.571214
v  17.626871 6.342893 -4.570883
v  17.630407 6.346154 -4.564466
v  17.622757 6.374620 -4.580368
v  17.618258 6.347457 -4.566772
v  17.622269 6.344923 -4.569341
v  17.617098 6.369768 -4.590007
v  17.614155 6.372557 -4.586789
v  17.612644 6.375224 -4.583225
v  17.612743 6.377458 -4.579745
v  17.614443 6.378995 -4.576770
v  17.617538 6.379648 -4.574657
v  17.621655 6.379323 -4.573663
v  17.626295 6.378053 -4.573906
v  17.630901 6.375979 -4.575357
v  17.634918 6.373353 -4.577842
v  17.637859 6.370498 -4.581061
v  17.639372 6.367768 -4.584624
v  17.639273 6.365504 -4.588103
v  17.637573 6.363984 -4.591079
v  17.634478 6.363389 -4.593191
v  17.630362 6.363781 -4.594186
v  17.625721 6.365102 -4.593943
v  17.621115 6.367182 -4.592491
v  17.638258 6.339354 -4.573916
v  18.014074 6.400613 -4.534948
v  18.012552 6.404558 -4.531939
v  18.009785 6.408122 -4.529742
v  18.006105 6.410875 -4.528625
v  18.001959 6.412485 -4.528720
v  17.997845 6.412761 -4.530018
v  17.994259 6.411674 -4.532360
v  17.991634 6.409359 -4.535465
v  17.990290 6.406096 -4.538959
v  17.990383 6.402276 -4.542418
v  17.991905 6.398355 -4.545428
v  17.994673 6.394804 -4.547625
v  17.998352 6.392044 -4.548742
v  18.002499 6.390413 -4.548647
v  18.006613 6.390110 -4.547350
v  18.010197 6.391177 -4.545007
v  18.002333 6.397210 -4.542507
v  17.993904 6.377705 -4.518529
v  18.014168 6.396768 -4.538408
v  18.012821 6.393488 -4.541902
v  18.008623 6.374874 -4.514293
v  18.008486 6.378518 -4.510615
v  18.006935 6.382336 -4.507446
v  18.004154 6.385863 -4.505167
v  18.000479 6.388673 -4.504052
v  17.996355 6.390427 -4.504236
v  17.992277 6.390917 -4.505698
v  17.988739 6.390086 -4.508260
v  17.986166 6.388039 -4.511613
v  17.984871 6.385023 -4.515354
v  17.985006 6.381402 -4.519031
v  17.986559 6.377606 -4.522200
v  17.989340 6.374092 -4.524479
v  17.993013 6.371279 -4.525594
v  17.997137 6.369505 -4.525410
v  18.001215 6.368993 -4.523949
v  18.004753 6.369803 -4.521386
v  18.007326 6.371847 -4.518033
v  18.018131 6.421453 -4.560435
v  18.016630 6.425509 -4.557588
v  18.013872 6.429087 -4.555458
v  18.010185 6.431751 -4.554304
v  18.006018 6.433183 -4.554265
v  18.001869 6.433214 -4.555344
v  17.998243 6.431846 -4.557412
v  17.995575 6.429245 -4.560220
v  17.994186 6.425729 -4.563429
v  17.994246 6.421717 -4.566650
v  17.995747 6.417686 -4.569498
v  17.998505 6.414121 -4.571628
v  18.002192 6.411446 -4.572782
v  18.006359 6.409986 -4.572822
v  18.010508 6.409924 -4.571742
v  18.014133 6.411273 -4.569674
v  18.003241 6.418712 -4.566646
v  17.999338 6.406107 -4.537121
v  18.018190 6.417409 -4.563657
v  18.016802 6.413873 -4.566866
v  18.014338 6.397516 -4.539281
v  18.014246 6.401369 -4.535828
v  18.012724 6.405318 -4.532824
v  18.009956 6.408883 -4.530630
v  18.006277 6.411633 -4.529511
v  18.002131 6.413237 -4.529604
v  17.998014 6.413505 -4.530894
v  17.994429 6.412410 -4.533229
v  17.991802 6.410085 -4.536325
v  17.990454 6.406814 -4.539809
v  17.990547 6.402987 -4.543262
v  17.992069 6.399063 -4.546266
v  17.994835 6.395510 -4.548460
v  17.998514 6.392754 -4.549578
v  18.002663 6.391127 -4.549486
v  18.006777 6.390831 -4.548195
v  18.010365 6.391908 -4.545861
v  18.012989 6.394229 -4.542765
v  18.019861 6.438925 -4.584490
v  18.018373 6.443105 -4.581842
v  18.015617 6.446680 -4.579771
v  18.011925 6.449218 -4.578523
v  18.007742 6.450416 -4.578248
v  18.003571 6.450136 -4.578980
v  17.999918 6.448416 -4.580632
v  17.997221 6.445467 -4.583004
v  17.995806 6.441645 -4.585810
v  17.995846 6.437406 -4.588711
v  17.997332 6.433256 -4.591356
v  18.000088 6.429687 -4.593429
v  18.003780 6.427131 -4.594678
v  18.007965 6.425897 -4.594953
v  18.012135 6.426142 -4.594219
v  18.015789 6.427842 -4.592567
v  18.006210 6.442755 -4.583676
v  18.003336 6.419336 -4.567463
v  18.019899 6.434648 -4.587391
v  18.018486 6.430797 -4.590196
v  18.018291 6.418065 -4.564505
v  18.018232 6.422115 -4.561292
v  18.016733 6.426175 -4.558450
v  18.013973 6.429751 -4.556323
v  18.010286 6.432413 -4.555167
v  18.006119 6.433837 -4.555121
v  18.001970 6.433860 -4.556191
v  17.998341 6.432481 -4.558249
v  17.995672 6.429871 -4.561045
v  17.994282 6.426346 -4.564242
v  17.994341 6.422326 -4.567455
v  17.995840 6.418292 -4.570297
v  17.998600 6.414726 -4.572424
v  18.002287 6.412054 -4.573580
v  18.006454 6.410602 -4.573626
v  18.010603 6.410549 -4.572556
v  18.014233 6.411907 -4.570498
v  18.016901 6.414517 -4.567702
v  18.017651 6.456510 -4.616947
v  18.015865 6.461057 -4.615196
v  18.012890 6.464706 -4.613657
v  18.009083 6.467021 -4.612514
v  18.004906 6.467728 -4.611906
v  18.000860 6.466751 -4.611905
v  17.997437 6.464219 -4.612512
v  17.995045 6.460437 -4.613655
v  17.993975 6.455857 -4.615194
v  17.994356 6.451024 -4.616944
v  17.996143 6.446511 -4.618694
v  17.999119 6.442853 -4.620233
v  18.002924 6.440496 -4.621377
v  18.007103 6.439732 -4.621984
v  18.011147 6.440659 -4.621985
v  18.014572 6.443179 -4.621377
v  18.010210 6.451150 -4.618207
v  18.010843 6.441922 -4.584736
v  18.018032 6.451620 -4.618697
v  18.016964 6.446987 -4.620236
v  18.019911 6.435162 -4.588158
v  18.019871 6.439447 -4.585271
v  18.018385 6.443634 -4.582633
v  18.015629 6.447208 -4.580563
v  18.011936 6.449742 -4.579309
v  18.007751 6.450931 -4.579024
v  18.003582 6.450636 -4.579740
v  17.999928 6.448902 -4.581374
v  17.997231 6.445939 -4.583725
v  17.995815 6.442105 -4.586512
v  17.995853 6.437858 -4.589398
v  17.997341 6.433702 -4.592037
v  18.000097 6.430134 -4.594107
v  18.003790 6.427582 -4.595361
v  18.007973 6.426357 -4.595647
v  18.012144 6.426615 -4.594930
v  18.015799 6.428329 -4.593297
v  18.018496 6.431299 -4.590946
v  18.009861 6.465676 -4.649626
v  18.007854 6.470238 -4.648245
v  18.004786 6.473742 -4.646697
v  18.001022 6.475772 -4.645166
v  17.997021 6.476094 -4.643838
v  17.993261 6.474679 -4.642874
v  17.990198 6.471706 -4.642389
v  17.988201 6.467536 -4.642442
v  17.987511 6.462663 -4.643027
v  17.988211 6.457662 -4.644073
v  17.990217 6.453125 -4.645454
v  17.993286 6.449595 -4.647003
v  17.997049 6.447504 -4.648533
v  18.001051 6.447112 -4.649860
v  18.004810 6.448480 -4.650825
v  18.007874 6.451449 -4.651309
v  17.999798 6.467046 -4.646204
v  18.007019 6.459230 -4.616362
v  18.010561 6.460612 -4.650672
v  18.009871 6.455666 -4.651257
v  18.017876 6.452050 -4.619819
v  18.017479 6.456954 -4.618107
v  18.015682 6.461507 -4.616385
v  18.012699 6.465155 -4.614858
v  18.008890 6.467459 -4.613712
v  18.004715 6.468149 -4.613084
v  18.000677 6.467151 -4.613051
v  17.997263 6.464595 -4.613616
v  17.994886 6.460790 -4.614711
v  17.993832 6.456192 -4.616205
v  17.994228 6.451344 -4.617917
v  17.996025 6.446825 -4.619639
v  17.999008 6.443170 -4.621166
v  18.002817 6.440823 -4.622312
v  18.006992 6.440072 -4.622940
v  18.011030 6.441021 -4.622973
v  18.014444 6.443564 -4.622407
v  18.016821 6.447397 -4.621313
v  17.996216 6.471981 -4.678793
v  17.994474 6.476337 -4.676827
v  17.991753 6.479600 -4.674524
v  17.988377 6.481378 -4.672161
v  17.984756 6.481469 -4.670022
v  17.981325 6.479873 -4.668366
v  17.978500 6.476788 -4.667393
v  17.976620 6.472586 -4.667218
v  17.975912 6.467764 -4.667866
v  17.976463 6.462898 -4.669255
v  17.978203 6.458559 -4.671220
v  17.980927 6.455270 -4.673523
v  17.984301 6.453431 -4.675886
v  17.987923 6.453275 -4.678025
v  17.991352 6.454831 -4.679682
v  17.994179 6.457920 -4.680655
v  17.987122 6.472748 -4.673309
v  17.997946 6.456386 -4.648457
v  17.996765 6.467056 -4.680182
v  17.996058 6.462169 -4.680829
v  18.010195 6.460832 -4.651731
v  18.009491 6.465896 -4.650691
v  18.007484 6.470454 -4.649305
v  18.004417 6.473952 -4.647741
v  18.000662 6.475974 -4.646188
v  17.996670 6.476285 -4.644833
v  17.992922 6.474862 -4.643838
v  17.989870 6.471881 -4.643325
v  17.987885 6.467706 -4.643354
v  17.987202 6.462829 -4.643924
v  17.987907 6.457828 -4.644964
v  17.989914 6.453296 -4.646349
v  17.992981 6.449771 -4.647913
v  17.996737 6.447688 -4.649467
v  18.000729 6.447306 -4.650823
v  18.004477 6.448683 -4.651816
v  18.007526 6.451661 -4.652331
v  18.009514 6.455884 -4.652300
v  17.976513 6.479592 -4.704320
v  17.975027 6.483778 -4.702024
v  17.972698 6.486833 -4.699207
v  17.969809 6.488391 -4.696208
v  17.966707 6.488277 -4.693390
v  17.963768 6.486515 -4.691093
v  17.961344 6.483319 -4.689595
v  17.959730 6.479074 -4.689075
v  17.959118 6.474286 -4.689595
v  17.959585 6.469521 -4.691094
v  17.961071 6.465348 -4.693392
v  17.963400 6.462265 -4.696209
v  17.966290 6.460651 -4.699207
v  17.969391 6.460710 -4.702024
v  17.972330 6.462442 -4.704321
v  17.974752 6.465647 -4.705820
v  17.966265 6.469794 -4.697132
v  17.986591 6.472982 -4.674169
v  17.976978 6.474782 -4.705820
v  17.976368 6.469934 -4.706341
v  17.996176 6.467323 -4.681137
v  17.995644 6.472237 -4.679719
v  17.993923 6.476583 -4.677721
v  17.991222 6.479834 -4.675383
v  17.987867 6.481602 -4.672986
v  17.984262 6.481684 -4.670821
v  17.980843 6.480082 -4.669149
v  17.978020 6.476996 -4.668172
v  17.976133 6.472799 -4.668006
v  17.975414 6.467986 -4.668672
v  17.975946 6.463128 -4.670090
v  17.977667 6.458801 -4.672089
v  17.980368 6.455523 -4.674427
v  17.983723 6.453696 -4.676823
v  17.987328 6.453547 -4.678988
v  17.990749 6.455109 -4.680659
v  17.993572 6.458198 -4.681638
v  17.995457 6.462442 -4.681804
v  17.951149 6.485771 -4.726801
v  17.949823 6.489828 -4.724367
v  17.947832 6.492742 -4.721252
v  17.945415 6.494165 -4.717835
v  17.942863 6.493936 -4.714527
v  17.940485 6.492088 -4.711726
v  17.938568 6.488844 -4.709771
v  17.937342 6.484599 -4.708898
v  17.936956 6.479853 -4.709211
v  17.937454 6.475173 -4.710674
v  17.938780 6.471121 -4.713109
v  17.940771 6.468181 -4.716223
v  17.943188 6.466713 -4.719640
v  17.945740 6.466903 -4.722949
v  17.948118 6.468733 -4.725749
v  17.950035 6.471986 -4.727705
v  17.945766 6.475941 -4.721428
v  17.966654 6.469407 -4.699037
v  17.951649 6.481060 -4.728264
v  17.951262 6.476270 -4.728577
v  17.976198 6.475024 -4.706640
v  17.975731 6.479832 -4.705142
v  17.974251 6.484013 -4.702838
v  17.971935 6.487061 -4.700009
v  17.969061 6.488615 -4.696993
v  17.965979 6.488496 -4.694156
v  17.963058 6.486728 -4.691840
v  17.960651 6.483529 -4.690322
v  17.959049 6.479282 -4.689788
v  17.958445 6.474495 -4.690301
v  17.958912 6.469734 -4.691800
v  17.960392 6.465565 -4.694103
v  17.962709 6.462489 -4.696932
v  17.965582 6.460881 -4.699948
v  17.968664 6.460944 -4.702785
v  17.971586 6.462682 -4.705101
v  17.973991 6.465888 -4.706619
v  17.975594 6.470178 -4.707153
v  17.921032 6.490087 -4.746009
v  17.919830 6.494020 -4.743495
v  17.918144 6.496830 -4.740186
v  17.916178 6.498180 -4.736480
v  17.914167 6.497914 -4.732825
v  17.912355 6.496067 -4.729661
v  17.910963 6.492862 -4.727370
v  17.910154 6.488683 -4.726228
v  17.910028 6.484029 -4.726374
v  17.910603 6.479459 -4.727788
v  17.911804 6.475520 -4.730302
v  17.913490 6.472689 -4.733612
v  17.915457 6.471311 -4.737317
v  17.917467 6.471556 -4.740972
v  17.919279 6.473398 -4.744136
v  17.920673 6.476618 -4.746427
v  17.917992 6.483232 -4.740993
v  17.943953 6.475417 -4.721025
v  17.921606 6.485503 -4.747424
v  17.921480 6.480823 -4.747569
v  17.950691 6.481244 -4.728978
v  17.950190 6.485950 -4.727517
v  17.948870 6.490004 -4.725077
v  17.946890 6.492914 -4.721956
v  17.944489 6.494335 -4.718526
v  17.941956 6.494102 -4.715204
v  17.939598 6.492251 -4.712389
v  17.937698 6.489009 -4.710420
v  17.936485 6.484764 -4.709537
v  17.936108 6.480021 -4.709844
v  17.936609 6.475346 -4.711305
v  17.937929 6.471296 -4.713744
v  17.939909 6.468360 -4.716865
v  17.942310 6.466897 -4.720294
v  17.944843 6.467089 -4.723617
v  17.947201 6.468921 -4.726432
v  17.949100 6.472175 -4.728401
v  17.950314 6.476457 -4.729285
v  17.891169 6.492309 -4.760037
v  17.890083 6.496131 -4.757475
v  17.888659 6.498872 -4.754046
v  17.887064 6.500200 -4.750166
v  17.885494 6.499959 -4.746302
v  17.884136 6.498178 -4.742919
v  17.883156 6.495070 -4.740426
v  17.882669 6.491007 -4.739124
v  17.882738 6.486476 -4.739170
v  17.883350 6.482026 -4.740558
v  17.884436 6.478189 -4.743120
v  17.885862 6.475435 -4.746549
v  17.887455 6.474093 -4.750429
v  17.889027 6.474333 -4.754293
v  17.890385 6.476121 -4.757676
v  17.891365 6.479245 -4.760169
v  17.887184 6.492157 -4.750247
v  17.916620 6.481742 -4.741128
v  17.891783 6.487860 -4.761425
v  17.891850 6.483322 -4.761471
v  17.920504 6.485620 -4.748019
v  17.919926 6.490199 -4.746606
v  17.918728 6.494129 -4.744090
v  17.917051 6.496934 -4.740776
v  17.915100 6.498283 -4.737062
v  17.913107 6.498018 -4.733397
v  17.911316 6.496171 -4.730223
v  17.909941 6.492969 -4.727921
v  17.909147 6.488794 -4.726772
v  17.909031 6.484144 -4.726912
v  17.909607 6.479578 -4.728325
v  17.910807 6.475643 -4.730841
v  17.912483 6.472815 -4.734156
v  17.914434 6.471438 -4.737869
v  17.916426 6.471684 -4.741534
v  17.918219 6.473526 -4.744708
v  17.919594 6.476743 -4.747009
v  17.920387 6.480945 -4.748158
v  17.858253 6.493515 -4.771877
v  17.857254 6.497221 -4.769287
v  17.856028 6.499897 -4.765789
v  17.854721 6.501223 -4.761805
v  17.853495 6.501038 -4.757816
v  17.852495 6.499361 -4.754303
v  17.851843 6.496391 -4.751688
v  17.851616 6.492483 -4.750289
v  17.851841 6.488109 -4.750274
v  17.852493 6.483798 -4.751644
v  17.853495 6.480072 -4.754234
v  17.854721 6.477386 -4.757731
v  17.856026 6.476065 -4.761715
v  17.857252 6.476265 -4.765705
v  17.858253 6.477962 -4.769218
v  17.858906 6.480947 -4.771832
v  17.856815 6.487193 -4.766161
v  17.885031 6.491443 -4.747884
v  17.858906 6.489222 -4.773246
v  17.859133 6.484858 -4.773231
v  17.890713 6.487921 -4.761859
v  17.890099 6.492364 -4.760472
v  17.889017 6.496183 -4.757908
v  17.887598 6.498921 -4.754477
v  17.886015 6.500249 -4.750593
v  17.884457 6.500010 -4.746723
v  17.883112 6.498233 -4.743335
v  17.882143 6.495129 -4.740838
v  17.881666 6.491071 -4.739531
v  17.881741 6.486546 -4.739574
v  17.882355 6.482099 -4.740962
v  17.883436 6.478267 -4.743526
v  17.884853 6.475513 -4.746956
v  17.886436 6.474174 -4.750842
v  17.887995 6.474411 -4.754711
v  17.889339 6.476199 -4.758099
v  17.890310 6.479317 -4.760597
v  17.890785 6.483388 -4.761902
v  17.824093 6.493756 -4.781045
v  17.823168 6.497341 -4.778436
v  17.822149 6.499957 -4.774883
v  17.821159 6.501287 -4.770816
v  17.820318 6.501171 -4.766725
v  17.819727 6.499614 -4.763102
v  17.819454 6.496798 -4.760386
v  17.819538 6.493060 -4.758903
v  17.819963 6.488851 -4.758833
v  17.820683 6.484688 -4.760183
v  17.821608 6.481076 -4.762793
v  17.822624 6.478456 -4.766345
v  17.823614 6.477146 -4.770413
v  17.824457 6.477298 -4.774505
v  17.825048 6.478889 -4.778127
v  17.825319 6.481721 -4.780844
v  17.821457 6.489087 -4.766093
v  17.852898 6.488479 -4.757703
v  17.824810 6.489629 -4.782396
v  17.825237 6.485451 -4.782326
v  17.857738 6.489253 -4.773612
v  17.857084 6.493538 -4.772243
v  17.856087 6.497241 -4.769651
v  17.854866 6.499916 -4.766150
v  17.853571 6.501242 -4.762165
v  17.852356 6.501058 -4.758172
v  17.851368 6.499384 -4.754655
v  17.850727 6.496419 -4.752037
v  17.850510 6.492517 -4.750635
v  17.850742 6.488150 -4.750618
v  17.851397 6.483844 -4.751987
v  17.852394 6.480123 -4.754578
v  17.853613 6.477438 -4.758078
v  17.854908 6.476118 -4.762064
v  17.856123 6.476316 -4.766057
v  17.857111 6.478011 -4.769574
v  17.857752 6.480989 -4.772192
v  17.857971 6.484895 -4.773594
v  17.791964 6.492990 -4.786078
v  17.791136 6.496467 -4.783456
v  17.790419 6.499034 -4.779858
v  17.789904 6.500385 -4.775720
v  17.789652 6.500347 -4.771541
v  17.789692 6.498914 -4.767823
v  17.790020 6.496250 -4.765018
v  17.790596 6.492672 -4.763462
v  17.791351 6.488617 -4.763343
v  17.792194 6.484585 -4.764675
v  17.793024 6.481070 -4.767298
v  17.793739 6.478505 -4.770896
v  17.794254 6.477197 -4.775034
v  17.794506 6.477298 -4.779213
v  17.794466 6.478784 -4.782930
v  17.794138 6.481468 -4.785736
v  17.792870 6.485992 -4.779352
v  17.822081 6.484663 -4.772347
v  17.792807 6.489019 -4.787411
v  17.793562 6.485021 -4.787292
v  17.823662 6.489625 -4.782647
v  17.822939 6.493747 -4.781297
v  17.822018 6.497327 -4.778687
v  17.821009 6.499940 -4.775133
v  17.820030 6.501273 -4.771063
v  17.819204 6.501159 -4.766968
v  17.818630 6.499606 -4.763342
v  17.818375 6.496795 -4.760622
v  17.818470 6.493062 -4.759136
v  17.818905 6.488860 -4.759064
v  17.819626 6.484701 -4.760414
v  17.820547 6.481092 -4.763023
v  17.821558 6.478475 -4.766579
v  17.822535 6.477165 -4.770649
v  17.823360 6.477315 -4.774744
v  17.823936 6.478904 -4.778370
v  17.824192 6.481730 -4.781090
v  17.824097 6.485454 -4.782576
v  17.861118 6.077279 -4.352575
v  17.859249 6.079852 -4.348710
v  17.856531 6.083062 -4.346185
v  17.853291 6.086521 -4.345304
v  17.849918 6.089816 -4.346175
v  17.846825 6.092555 -4.348691
v  17.844378 6.094413 -4.352550
v  17.842875 6.095168 -4.357285
v  17.842499 6.094728 -4.362327
v  17.843292 6.093142 -4.367065
v  17.845161 6.090595 -4.370931
v  17.847879 6.087389 -4.373455
v  17.851120 6.083909 -4.374336
v  17.854490 6.080580 -4.373466
v  17.857586 6.077807 -4.370950
v  17.860033 6.075934 -4.367091
v  17.853865 6.084363 -4.354588
v  17.836309 6.068053 -4.351691
v  17.861912 6.075656 -4.357314
v  17.861534 6.075189 -4.362355
v  17.841696 6.059774 -4.354052
v  17.841112 6.060755 -4.349120
v  17.839695 6.062994 -4.345001
v  17.837614 6.066225 -4.342192
v  17.835123 6.070052 -4.341031
v  17.832520 6.074017 -4.341658
v  17.830120 6.077641 -4.343998
v  17.828211 6.080492 -4.347769
v  17.827026 6.082227 -4.352514
v  17.826706 6.082634 -4.357664
v  17.827290 6.081667 -4.362596
v  17.828709 6.079440 -4.366714
v  17.830788 6.076218 -4.369524
v  17.833281 6.072389 -4.370685
v  17.835882 6.068414 -4.370058
v  17.838284 6.064774 -4.367718
v  17.840191 6.061913 -4.363948
v  17.841375 6.060177 -4.359201
v  17.877804 6.098263 -4.359983
v  17.875879 6.100819 -4.356083
v  17.873003 6.103870 -4.353478
v  17.869524 6.107052 -4.352484
v  17.865864 6.109982 -4.353219
v  17.862459 6.112314 -4.355596
v  17.859724 6.113771 -4.359327
v  17.857988 6.114184 -4.363962
v  17.857458 6.113501 -4.368944
v  17.858202 6.111798 -4.373670
v  17.860128 6.109272 -4.377570
v  17.863003 6.106223 -4.380175
v  17.866482 6.103018 -4.381169
v  17.870144 6.100047 -4.380434
v  17.873547 6.097677 -4.378057
v  17.876282 6.096200 -4.374326
v  17.865875 6.107811 -4.362516
v  17.854416 6.084949 -4.354782
v  17.878548 6.096516 -4.364709
v  17.878019 6.095797 -4.369690
v  17.862492 6.076274 -4.357520
v  17.861700 6.077900 -4.352782
v  17.859829 6.080472 -4.348915
v  17.857101 6.083673 -4.346387
v  17.853849 6.087120 -4.345501
v  17.850464 6.090399 -4.346366
v  17.847353 6.093120 -4.348876
v  17.844891 6.094961 -4.352730
v  17.843378 6.095702 -4.357461
v  17.842993 6.095253 -4.362499
v  17.843784 6.093663 -4.367238
v  17.845655 6.091117 -4.371105
v  17.848383 6.087919 -4.373633
v  17.851635 6.084452 -4.374518
v  17.855021 6.081139 -4.373655
v  17.858133 6.078383 -4.371144
v  17.860594 6.076528 -4.367290
v  17.862108 6.075796 -4.362559
v  17.893980 6.123749 -4.369711
v  17.892035 6.126332 -4.365793
v  17.889091 6.129323 -4.363143
v  17.885509 6.132361 -4.362080
v  17.881716 6.135084 -4.362733
v  17.878172 6.137168 -4.365023
v  17.875303 6.138370 -4.368674
v  17.873455 6.138547 -4.373245
v  17.872852 6.137679 -4.378186
v  17.873568 6.135863 -4.382899
v  17.875513 6.133311 -4.386818
v  17.878454 6.130323 -4.389468
v  17.882038 6.127263 -4.390531
v  17.885830 6.124500 -4.389878
v  17.889374 6.122375 -4.387588
v  17.892244 6.121155 -4.383937
v  17.884439 6.130764 -4.370847
v  17.866417 6.108593 -4.362803
v  17.894693 6.121890 -4.374425
v  17.894091 6.120986 -4.379365
v  17.879108 6.097322 -4.365007
v  17.878366 6.099073 -4.360281
v  17.876440 6.101630 -4.356380
v  17.873562 6.104679 -4.353773
v  17.870077 6.107853 -4.352776
v  17.866409 6.110774 -4.353507
v  17.862999 6.113095 -4.355881
v  17.860258 6.114543 -4.359609
v  17.858517 6.114947 -4.364242
v  17.857985 6.114255 -4.369221
v  17.858727 6.112547 -4.373946
v  17.860653 6.110022 -4.377848
v  17.863531 6.106977 -4.380454
v  17.867014 6.103777 -4.381452
v  17.870684 6.100817 -4.380720
v  17.874094 6.098456 -4.378347
v  17.876835 6.096990 -4.374619
v  17.878576 6.096597 -4.369987
v  17.909529 6.151582 -4.380886
v  17.907579 6.154212 -4.376962
v  17.904608 6.157194 -4.374287
v  17.900974 6.160161 -4.373185
v  17.897116 6.162764 -4.373789
v  17.893499 6.164691 -4.376026
v  17.890558 6.165719 -4.379625
v  17.888651 6.165726 -4.384154
v  17.888004 6.164710 -4.389066
v  17.888697 6.162788 -4.393768
v  17.890646 6.160188 -4.397692
v  17.893618 6.157215 -4.400367
v  17.897251 6.154226 -4.401468
v  17.901110 6.151586 -4.400864
v  17.904726 6.149621 -4.398627
v  17.907667 6.148572 -4.395027
v  17.899828 6.158309 -4.381900
v  17.883640 6.129711 -4.382123
v  17.910221 6.149621 -4.385588
v  17.909575 6.148573 -4.390499
v  17.895237 6.122814 -4.374791
v  17.894524 6.124677 -4.370077
v  17.892578 6.127262 -4.366158
v  17.889635 6.130250 -4.363507
v  17.886049 6.133286 -4.362443
v  17.882254 6.136003 -4.363093
v  17.878706 6.138081 -4.365381
v  17.875834 6.139276 -4.369030
v  17.873983 6.139447 -4.373600
v  17.873379 6.138573 -4.378539
v  17.874092 6.136753 -4.383252
v  17.876038 6.134199 -4.387171
v  17.878981 6.131214 -4.389823
v  17.882566 6.128156 -4.390887
v  17.886362 6.125399 -4.390236
v  17.889912 6.123281 -4.387948
v  17.892782 6.122066 -4.384299
v  17.894632 6.121905 -4.379730
v  17.924141 6.179593 -4.392591
v  17.922197 6.182285 -4.388670
v  17.919220 6.185285 -4.385988
v  17.915565 6.188230 -4.384870
v  17.911678 6.190769 -4.385448
v  17.908024 6.192599 -4.387654
v  17.905045 6.193508 -4.391223
v  17.903099 6.193387 -4.395723
v  17.902422 6.192251 -4.400612
v  17.903097 6.190233 -4.405299
v  17.905039 6.187570 -4.409220
v  17.908018 6.184577 -4.411901
v  17.911671 6.181616 -4.413021
v  17.915560 6.179044 -4.412442
v  17.919214 6.177178 -4.410235
v  17.922194 6.176250 -4.406668
v  17.912870 6.183631 -4.404346
v  17.898911 6.156982 -4.393145
v  17.924814 6.177540 -4.397279
v  17.924139 6.176377 -4.402167
v  17.910740 6.150587 -4.385983
v  17.910048 6.152552 -4.381282
v  17.908098 6.155185 -4.377357
v  17.905127 6.158165 -4.374681
v  17.901491 6.161130 -4.373579
v  17.897631 6.163731 -4.374183
v  17.894012 6.165654 -4.376417
v  17.891069 6.166677 -4.380016
v  17.889160 6.166678 -4.384542
v  17.888512 6.165658 -4.389454
v  17.889206 6.163733 -4.394155
v  17.891155 6.161130 -4.398080
v  17.894127 6.158156 -4.400755
v  17.897760 6.155169 -4.401857
v  17.901621 6.152533 -4.401255
v  17.905239 6.150573 -4.399019
v  17.908182 6.149529 -4.395421
v  17.910091 6.149536 -4.390893
v  17.937441 6.205622 -4.403902
v  17.935516 6.208388 -4.399997
v  17.932552 6.211438 -4.397326
v  17.928904 6.214403 -4.396211
v  17.925013 6.216928 -4.396787
v  17.921349 6.218714 -4.398984
v  17.918352 6.219548 -4.402539
v  17.916384 6.219335 -4.407020
v  17.915684 6.218099 -4.411890
v  17.916336 6.215987 -4.416558
v  17.918259 6.213248 -4.420463
v  17.921225 6.210206 -4.423134
v  17.924871 6.207228 -4.424249
v  17.928762 6.204674 -4.423673
v  17.932428 6.202857 -4.421475
v  17.935425 6.202003 -4.417921
v  17.927662 6.212173 -4.404850
v  17.910803 6.186842 -4.403285
v  17.938091 6.203477 -4.408570
v  17.937391 6.202219 -4.413440
v  17.925295 6.178473 -4.397676
v  17.924622 6.180531 -4.392991
v  17.922678 6.183225 -4.389070
v  17.919701 6.186225 -4.386388
v  17.916046 6.189171 -4.385269
v  17.912157 6.191708 -4.385847
v  17.908503 6.193537 -4.388053
v  17.905521 6.194441 -4.391621
v  17.903576 6.194316 -4.396120
v  17.902899 6.193177 -4.401008
v  17.903572 6.191155 -4.405695
v  17.905514 6.188490 -4.409616
v  17.908493 6.185498 -4.412297
v  17.912146 6.182536 -4.413417
v  17.916035 6.179965 -4.412839
v  17.919691 6.178102 -4.410632
v  17.922670 6.177177 -4.407063
v  17.924618 6.177308 -4.402566
v  17.949905 6.229216 -4.414654
v  17.948008 6.232049 -4.410763
v  17.945066 6.235155 -4.408106
v  17.941439 6.238159 -4.407002
v  17.937561 6.240700 -4.407586
v  17.933901 6.242476 -4.409786
v  17.930901 6.243277 -4.413337
v  17.928921 6.243009 -4.417810
v  17.928204 6.241704 -4.422668
v  17.928831 6.239520 -4.427321
v  17.930729 6.236712 -4.431211
v  17.933670 6.233618 -4.433869
v  17.937298 6.230605 -4.434972
v  17.941175 6.228040 -4.434389
v  17.944836 6.226234 -4.432189
v  17.947836 6.225416 -4.428638
v  17.935360 6.237684 -4.419768
v  17.924004 6.212555 -4.414527
v  17.950533 6.227004 -4.419308
v  17.949814 6.225683 -4.424165
v  17.938519 6.204305 -4.408940
v  17.937868 6.206451 -4.404272
v  17.935946 6.209220 -4.400367
v  17.932981 6.212274 -4.397697
v  17.929335 6.215240 -4.396583
v  17.925444 6.217767 -4.397160
v  17.921780 6.219551 -4.399357
v  17.918781 6.220385 -4.402911
v  17.916815 6.220168 -4.407393
v  17.916113 6.218929 -4.412260
v  17.916763 6.216814 -4.416928
v  17.918686 6.214072 -4.420833
v  17.921650 6.211028 -4.423502
v  17.925299 6.208047 -4.424616
v  17.929188 6.205493 -4.424040
v  17.932854 6.203679 -4.421842
v  17.935850 6.202826 -4.418289
v  17.937817 6.203044 -4.413807
v  17.963587 6.256357 -4.426970
v  17.961679 6.259145 -4.423041
v  17.958708 6.262171 -4.420329
v  17.955034 6.265068 -4.419160
v  17.951101 6.267488 -4.419675
v  17.947380 6.269141 -4.421811
v  17.944324 6.269833 -4.425312
v  17.942299 6.269483 -4.429754
v  17.941549 6.268133 -4.434604
v  17.942167 6.265944 -4.439273
v  17.944077 6.263179 -4.443201
v  17.947046 6.260164 -4.445913
v  17.950720 6.257261 -4.447083
v  17.954655 6.254822 -4.446568
v  17.958374 6.253143 -4.444431
v  17.961432 6.252434 -4.440930
v  17.948818 6.264349 -4.431830
v  17.939081 6.233870 -4.426792
v  17.964205 6.254145 -4.431640
v  17.963455 6.252781 -4.436488
v  17.951046 6.227971 -4.419752
v  17.950418 6.230182 -4.415096
v  17.948519 6.233011 -4.411204
v  17.945576 6.236113 -4.408545
v  17.941946 6.239113 -4.407439
v  17.938066 6.241650 -4.408020
v  17.934406 6.243423 -4.410218
v  17.931404 6.244222 -4.413768
v  17.929424 6.243953 -4.418242
v  17.928705 6.242649 -4.423099
v  17.929333 6.240466 -4.427754
v  17.931232 6.237660 -4.431646
v  17.934175 6.234569 -4.434305
v  17.937805 6.231560 -4.435412
v  17.941685 6.228999 -4.434831
v  17.945347 6.227199 -4.432633
v  17.948347 6.226381 -4.429083
v  17.950327 6.226650 -4.424609
v  17.974997 6.281814 -4.438882
v  17.973124 6.284659 -4.434970
v  17.970158 6.287674 -4.432242
v  17.966455 6.290492 -4.431027
v  17.962467 6.292773 -4.431472
v  17.958672 6.294245 -4.433522
v  17.955524 6.294734 -4.436932
v  17.953409 6.294184 -4.441288
v  17.952579 6.292662 -4.446066
v  17.953135 6.290350 -4.450689
v  17.955009 6.287525 -4.454601
v  17.957975 6.284522 -4.457329
v  17.961678 6.281702 -4.458544
v  17.965666 6.279405 -4.458100
v  17.969461 6.277910 -4.456049
v  17.972607 6.277404 -4.452640
v  17.963150 6.284397 -4.450066
v  17.952467 6.260530 -4.438879
v  17.975554 6.279481 -4.443505
v  17.974724 6.277950 -4.448283
v  17.964628 6.255040 -4.432048
v  17.964014 6.257254 -4.427378
v  17.962105 6.260042 -4.423450
v  17.959133 6.263066 -4.420736
v  17.955458 6.265961 -4.419565
v  17.951523 6.268374 -4.420076
v  17.947802 6.270024 -4.422212
v  17.944742 6.270710 -4.425710
v  17.942715 6.270355 -4.430150
v  17.941963 6.269001 -4.434998
v  17.942579 6.266812 -4.439668
v  17.944489 6.264046 -4.443596
v  17.947458 6.261032 -4.446309
v  17.951134 6.258132 -4.447480
v  17.955070 6.255698 -4.446968
v  17.958792 6.254025 -4.444835
v  17.961851 6.253321 -4.441336
v  17.963879 6.253673 -4.436895
v  17.984682 6.306123 -4.451785
v  17.982885 6.309152 -4.447966
v  17.979958 6.312247 -4.445279
v  17.976254 6.315032 -4.444050
v  17.972218 6.317174 -4.444426
v  17.968336 6.318417 -4.446362
v  17.965078 6.318610 -4.449623
v  17.962839 6.317737 -4.453818
v  17.961885 6.315902 -4.458440
v  17.962334 6.313327 -4.462932
v  17.964130 6.310318 -4.466750
v  17.967058 6.307236 -4.469437
v  17.970764 6.304448 -4.470666
v  17.974800 6.302291 -4.470290
v  17.978680 6.301030 -4.468355
v  17.981937 6.300818 -4.465093
v  17.972454 6.307697 -4.462465
v  17.963495 6.285215 -4.450468
v  17.985130 6.303529 -4.456276
v  17.984177 6.301686 -4.460899
v  17.975906 6.280324 -4.443919
v  17.975355 6.282662 -4.439299
v  17.973482 6.285512 -4.435389
v  17.970518 6.288528 -4.432661
v  17.966816 6.291345 -4.431445
v  17.962826 6.293621 -4.431888
v  17.959026 6.295085 -4.433934
v  17.955877 6.295566 -4.437339
v  17.953758 6.295007 -4.441692
v  17.952925 6.293476 -4.446465
v  17.953476 6.291159 -4.451087
v  17.955349 6.288329 -4.454997
v  17.958313 6.285325 -4.457725
v  17.962015 6.282506 -4.458941
v  17.966007 6.280212 -4.458498
v  17.969805 6.278726 -4.456450
v  17.972954 6.278229 -4.453046
v  17.975073 6.278784 -4.448693
v  17.969675 6.302205 -4.474546
v  17.993179 6.329741 -4.467067
v  17.991489 6.333055 -4.463434
v  17.988626 6.336316 -4.460861
v  17.984938 6.339126 -4.459660
v  17.980867 6.341146 -4.459973
v  17.976906 6.342136 -4.461765
v  17.973534 6.341980 -4.464819
v  17.971155 6.340699 -4.468765
v  17.970057 6.338448 -4.473129
v  17.970373 6.335500 -4.477385
v  17.972063 6.332207 -4.481017
v  17.974926 6.328959 -4.483590
v  17.978615 6.326149 -4.484792
v  17.982685 6.324115 -4.484478
v  17.986647 6.323103 -4.482686
v  17.990019 6.323242 -4.479631
v  17.978613 6.336215 -4.469383
v  17.977901 6.307437 -4.459180
v  17.993496 6.326775 -4.471322
v  17.992397 6.324517 -4.475686
v  17.985434 6.304336 -4.456746
v  17.984989 6.306941 -4.452261
v  17.983196 6.309978 -4.448447
v  17.980272 6.313077 -4.445764
v  17.976566 6.315864 -4.444534
v  17.972528 6.318002 -4.444907
v  17.968645 6.319233 -4.446837
v  17.965384 6.319417 -4.450094
v  17.963139 6.318530 -4.454282
v  17.962181 6.316683 -4.458897
v  17.962626 6.314095 -4.463382
v  17.964418 6.311079 -4.467196
v  17.967344 6.307992 -4.469880
v  17.971050 6.305204 -4.471108
v  17.975086 6.303052 -4.470736
v  17.978971 6.301800 -4.468804
v  17.982231 6.301600 -4.465549
v  17.984476 6.302481 -4.461361
v  18.000965 6.353157 -4.486006
v  17.999363 6.356771 -4.482623
v  17.996555 6.360207 -4.480215
v  17.992884 6.363045 -4.479072
v  17.988785 6.364944 -4.479330
v  17.984758 6.365681 -4.480961
v  17.981289 6.365166 -4.483766
v  17.978792 6.363465 -4.487407
v  17.977573 6.360785 -4.491446
v  17.977776 6.357451 -4.495394
v  17.979378 6.353858 -4.498776
v  17.982183 6.350438 -4.501184
v  17.985857 6.347597 -4.502328
v  17.989954 6.345681 -4.502069
v  17.993980 6.344923 -4.500439
v  17.997452 6.345419 -4.497634
v  17.986612 6.353434 -4.494723
v  17.978886 6.337002 -4.469955
v  18.001167 6.349802 -4.489954
v  17.999947 6.347114 -4.493993
v  17.993769 6.327564 -4.471894
v  17.993458 6.330544 -4.467649
v  17.991770 6.333869 -4.464025
v  17.988909 6.337136 -4.461457
v  17.985222 6.339947 -4.460257
v  17.981150 6.341964 -4.460568
v  17.977188 6.342946 -4.462356
v  17.973810 6.342778 -4.465401
v  17.971426 6.341481 -4.469337
v  17.970324 6.339216 -4.473691
v  17.970634 6.336254 -4.477936
v  17.972322 6.332950 -4.481561
v  17.975182 6.329696 -4.484128
v  17.978870 6.326884 -4.485328
v  17.982941 6.324852 -4.485016
v  17.986904 6.323850 -4.483230
v  17.990282 6.324001 -4.480185
v  17.992664 6.325290 -4.476248
v  17.989954 6.342060 -4.505156
v  18.008020 6.376837 -4.508870
v  18.006466 6.380642 -4.505689
v  18.003683 6.384167 -4.503402
v  18.000010 6.386980 -4.502286
v  17.995888 6.388743 -4.502476
v  17.991812 6.389249 -4.503949
v  17.988277 6.388438 -4.506526
v  17.985710 6.386411 -4.509898
v  17.984417 6.383415 -4.513658
v  17.984558 6.379809 -4.517351
v  17.986113 6.376027 -4.520533
v  17.988894 6.372516 -4.522820
v  17.992569 6.369699 -4.523934
v  17.996691 6.367917 -4.523745
v  18.000765 6.367388 -4.522272
v  18.004299 6.368180 -4.519694
v  17.993216 6.382209 -4.510584
v  17.992382 6.357983 -4.487397
v  18.008160 6.373209 -4.512564
v  18.006868 6.370201 -4.516323
v  18.001421 6.350601 -4.490670
v  18.001221 6.353967 -4.486731
v  17.999620 6.357591 -4.483356
v  17.996815 6.361029 -4.480953
v  17.993141 6.363868 -4.479811
v  17.989044 6.365764 -4.480068
v  17.985016 6.366489 -4.481692
v  17.981543 6.365963 -4.484489
v  17.979044 6.364251 -4.488121
v  17.977819 6.361558 -4.492149
v  17.978020 6.358212 -4.496088
v  17.979620 6.354611 -4.499462
v  17.982426 6.351187 -4.501864
v  17.986099 6.348347 -4.503008
v  17.990196 6.346435 -4.502751
v  17.994226 6.345685 -4.501126
v  17.997698 6.346193 -4.498329
v  18.000198 6.347900 -4.494698
v  17.853781 6.504949 -4.756287
v  17.886070 6.122140 -4.393906
v  17.608389 6.399945 -4.601511
v  17.945313 6.270989 -4.417919
v  17.960659 6.279704 -4.462601
v  17.956791 6.474072 -4.686490
v  17.933813 6.473830 -4.709212
v  17.991688 6.445673 -4.646365
v  17.717808 6.139936 -4.404703
v  17.635872 6.330443 -4.525818
v  17.628366 6.416974 -4.661366
v  17.639193 6.305628 -4.503359
v  17.632339 6.399694 -4.597258
v  18.006340 6.405839 -4.575144
v  17.996653 6.364037 -4.526506
v  17.751451 6.100058 -4.368657
v  17.801867 6.079657 -4.351870
v  17.713369 6.479472 -4.779188
v  18.002457 6.386370 -4.551163
v  17.671286 6.453422 -4.720095
v  17.773193 6.078078 -4.347606
v  17.648829 6.444172 -4.706631
v  17.776684 6.488441 -4.760127
v  17.621447 6.438414 -4.678903
v  17.666433 6.193259 -4.415621
v  17.913807 6.147552 -4.392207
v  17.648996 6.259375 -4.465194
v  17.664192 6.484225 -4.739505
v  17.748566 6.488356 -4.757957
v  17.969027 6.493196 -4.697136
v  17.717367 6.113012 -4.372043
v  17.891323 6.166328 -4.372141
v  17.919258 6.220569 -4.395132
v  17.844713 6.094458 -4.345026
v  17.848757 6.498898 -4.749110
v  17.997007 6.439193 -4.620588
v  17.654415 6.269129 -4.500938
v  18.002205 6.453533 -4.575971
v  17.976370 6.479607 -4.664711
v  17.925182 6.220365 -4.393187
v  17.804434 6.504612 -4.768404
v  17.869577 6.107092 -4.347712
v  17.906452 6.484015 -4.723975
v  17.992430 6.319681 -4.481560
v  17.817345 6.488749 -4.755499
v  17.756710 6.094738 -4.391685
v  17.626343 6.255841 -4.466093
v  17.639084 6.221008 -4.441349
v  17.700676 6.160316 -4.428699
v  17.896267 6.152580 -4.405648
v  17.901953 6.187718 -4.412171
v  17.996321 6.415765 -4.526590
v  17.875586 6.104980 -4.349779
v  17.923903 6.205473 -4.428407
v  17.824316 6.499912 -4.780717
v  17.990219 6.392087 -4.500397
v  17.972353 6.459042 -4.707809
v  17.860920 6.478476 -4.774741
v  17.949717 6.255363 -4.451202
v  18.000422 6.436392 -4.552085
v  17.674551 6.155373 -4.402594
v  17.944107 6.498820 -4.718782
v  17.956684 6.296177 -4.429633
v  17.983097 6.368337 -4.477306
v  18.021870 6.429351 -4.592669
v  17.936247 6.228683 -4.439062
v  17.626951 6.338584 -4.571724
v  17.608559 6.378747 -4.577783
v  17.629303 6.314167 -4.548164
v  17.718285 6.129868 -4.417095
v  17.918488 6.470033 -4.747445
v  17.714661 6.103897 -4.374699
v  17.777212 6.059824 -4.375731
v  17.615780 6.304307 -4.505739
v  17.987644 6.474757 -4.640258
v  17.982603 6.449507 -4.678166
v  17.966455 6.320532 -4.442506
v  17.993416 6.450622 -4.680695
v  17.641577 6.371531 -4.581576
v  17.791653 6.498906 -4.785949
v  17.975143 6.344519 -4.457992
v  17.809460 6.473535 -4.773129
v  17.842611 6.055394 -4.353333
v  17.622864 6.453924 -4.661869
v  17.778374 6.478652 -4.789552
v  18.007990 6.421659 -4.597069
v  17.808741 6.048668 -4.361341
v  17.626040 6.270279 -4.498994
v  17.637913 6.399416 -4.640234
v  17.870405 6.097892 -4.384526
v  17.924303 6.485663 -4.750289
v  17.994856 6.467230 -4.610240
v  17.691380 6.491771 -4.740944
v  17.928326 6.179583 -4.391287
v  17.833647 6.073112 -4.375457
v  17.628838 6.466462 -4.697241
v  17.898325 6.119828 -4.380984
v  17.635387 6.432998 -4.638311
v  17.655596 6.187045 -4.420352
v  17.881783 6.475497 -4.741556
v  17.885731 6.503980 -4.744708
v  17.955736 6.468335 -4.690217
v  17.905878 6.194311 -4.383768
v  17.747225 6.474884 -4.782909
v  17.854261 6.472174 -4.762315
v  17.642838 6.243020 -4.487686
v  17.997288 6.330264 -4.466475
v  18.012556 6.373875 -4.514769
v  17.951653 6.233031 -4.408306
v  17.609886 6.354498 -4.552608
v  17.612278 6.329669 -4.528629
v  17.967556 6.251708 -4.438278
v  18.018253 6.396614 -4.539872
v  17.979099 6.281978 -4.437757
v  17.633333 6.417918 -4.618954
v  18.005356 6.349477 -4.490993
v  17.632727 6.289379 -4.525764
v  17.807482 6.072364 -4.374255
v  17.977844 6.486561 -4.702745
v  17.713409 6.128983 -4.419342
v  17.941542 6.205458 -4.402550
v  17.869928 6.137119 -4.384721
v  17.735584 6.119049 -4.393203
v  17.758894 6.092983 -4.390542
v  17.704493 6.465930 -4.751246
v  17.633556 6.355753 -4.550271
v  17.745146 6.502427 -4.769793
v  17.645441 6.476886 -4.720592
v  18.010939 6.448041 -4.651925
v  17.823917 6.475647 -4.780933
v  17.637018 6.381157 -4.618589
v  17.857969 6.478590 -4.775663
v  17.980673 6.379695 -4.518080
v  17.614292 6.368658 -4.591300
v  18.022171 6.417252 -4.565205
v  17.976032 6.455607 -4.669663
v  17.887571 6.160436 -4.400677
v  17.854408 6.116510 -4.362843
v  17.678177 6.149482 -4.418976
v  17.654158 6.211006 -4.465019
v  17.769060 6.059885 -4.351854
v  17.913542 6.467289 -4.738418
v  17.649010 6.440228 -4.665903
v  17.615044 6.408100 -4.637064
v  17.722673 6.114655 -4.372344
v  17.988773 6.306445 -4.450872
v  17.973913 6.357549 -4.496353
v  17.951878 6.287372 -4.457409
v  17.997477 6.479019 -4.677020
v  17.992134 6.431182 -4.558529
v  18.013506 6.423153 -4.597876
v  17.781441 6.084528 -4.370471
v  18.014706 6.469025 -4.614817
v  17.720272 6.099632 -4.397785
v  17.947691 6.465361 -4.729185
v  17.810694 6.475751 -4.783413
v  17.646854 6.317119 -4.543150
v  17.907269 6.158947 -4.370859
v  17.669273 6.179410 -4.445509
v  17.650126 6.292693 -4.521018
v  17.937641 6.269672 -4.434771
v  17.951427 6.224288 -4.431854
v  17.992153 6.392167 -4.549821
v  17.652620 6.265078 -4.503073
v  17.875984 6.138802 -4.361174
v  17.924274 6.243300 -4.422866
v  17.753578 6.086273 -4.394576
v  17.777767 6.498131 -4.786852
v  17.700129 6.485413 -4.737090
v  17.894510 6.117708 -4.386077
v  17.995996 6.411304 -4.573580
v  17.910686 6.179878 -4.417176
v  17.864527 6.072155 -4.356359
v  17.617987 6.319941 -4.542473
v  17.911800 6.219767 -4.412127
v  17.710606 6.496984 -4.771000
v  17.937412 6.491385 -4.706432
v  17.970943 6.492630 -4.695117
v  17.668606 6.223624 -4.452854
v  17.938143 6.464634 -4.716546
v  17.816626 6.499196 -4.757555
v  17.921829 6.176361 -4.414517
v  17.683067 6.193417 -4.434169
v  18.007723 6.455225 -4.576996
v  17.893240 6.493910 -4.762783
v  17.632414 6.378778 -4.574078
v  17.631840 6.247210 -4.480738
v  17.891745 6.130853 -4.359620
v  17.934935 6.201786 -4.425687
v  17.739553 6.086351 -4.358402
v  17.966557 6.335835 -4.478600
v  18.010336 6.383723 -4.505767
v  18.016129 6.406909 -4.531383
v  17.892101 6.121628 -4.391803
v  18.002991 6.358707 -4.481380
v  17.961311 6.264097 -4.416999
v  17.907413 6.148909 -4.402905
v  17.789995 6.504075 -4.775764
v  17.643574 6.281518 -4.482860
v  17.779381 6.473388 -4.775279
v  17.898506 6.193863 -4.400833
v  17.850965 6.477446 -4.752451
v  17.865000 6.075919 -4.350657
v  17.651052 6.462945 -4.685250
v  17.884090 6.166369 -4.389317
v  17.889923 6.502553 -4.754898
v  17.879551 6.486394 -4.736352
v  17.743727 6.500155 -4.780598
v  17.714098 6.499499 -4.760821
v  17.803955 6.499041 -4.759931
v  17.711357 6.141376 -4.393007
v  17.935108 6.213137 -4.393893
v  17.996235 6.323308 -4.477778
v  17.921846 6.187093 -4.382592
v  17.986387 6.370106 -4.525290
v  17.989405 6.485660 -4.670943
v  17.915194 6.213397 -4.423420
v  18.020121 6.427956 -4.557246
v  17.876362 6.129834 -4.393370
v  17.645004 6.323534 -4.533740
v  17.682526 6.486763 -4.758703
v  17.908789 6.472830 -4.729149
v  17.620417 6.279419 -4.484713
v  17.960854 6.252163 -4.448729
v  17.804405 6.061183 -4.336954
v  17.642710 6.348643 -4.557972
v  17.635387 6.432998 -4.638310
v  17.621447 6.438414 -4.678903
v  17.621447 6.438414 -4.678903
v  17.635387 6.432998 -4.638310
v  17.633333 6.417918 -4.618955
v  17.628366 6.416974 -4.661366
v  17.628366 6.416974 -4.661366
v  17.633333 6.417918 -4.618955
v  17.704493 6.465930 -4.751246
v  17.664192 6.484225 -4.739505
v  17.664192 6.484225 -4.739505
v  17.704493 6.465930 -4.751246
v  17.645441 6.476886 -4.720592
v  17.687271 6.460265 -4.736814
v  17.687271 6.460265 -4.736813
v  17.645441 6.476886 -4.720591
v  17.686169 6.471159 -4.728018
v  17.702806 6.476589 -4.741638
v  17.648283 6.471708 -4.722860
v  17.666019 6.478792 -4.741008
v  17.633232 6.418462 -4.659218
v  17.644522 6.432680 -4.675457
v  17.613605 6.417556 -4.626333
v  17.617298 6.433925 -4.642643
v  18.072039 7.212755 -4.498042
v  17.711048 6.828115 -4.279605
v  17.708403 6.732255 -4.657846
v  17.445190 7.172585 -4.566500
v  17.917763 6.800204 -4.384580
v  17.834188 6.727592 -4.693539
v  17.837460 6.834082 -4.268720
v  17.587442 6.805641 -4.385524
v  17.676884 7.092961 -4.628273
v  17.959620 6.767656 -4.526618
v  17.597639 6.782449 -4.461334
v  17.735716 6.880415 -4.262022
v  17.700006 6.730860 -4.681046
v  17.966274 6.997531 -4.574511
v  17.640680 6.837729 -4.294478
v  17.574940 7.054090 -4.445231
v  17.980545 6.903986 -4.425065
v  17.794216 6.889393 -4.697449
v  17.922453 6.936452 -4.346906
v  17.938866 6.774328 -4.485795
v  17.576872 6.776346 -4.499917
v  17.825747 6.729157 -4.666574
v  17.491871 7.039671 -4.527874
v  17.916008 6.742446 -4.628878
v  17.829338 6.824996 -4.289145
v  18.014484 7.207452 -4.498353
v  18.010624 7.010171 -4.497288
v  17.641932 6.741509 -4.638176
v  17.563328 7.063106 -4.618463
v  17.573277 6.897017 -4.381551
v  17.900520 6.998845 -4.361975
v  17.700518 7.088953 -4.424133
v  18.021238 7.146421 -4.531295
v  17.737383 6.839269 -4.253277
v  17.543619 6.907129 -4.457771
v  17.620340 6.756770 -4.562649
v  17.937832 6.806603 -4.374177
v  17.905659 6.921640 -4.642900
v  17.837812 6.777107 -4.704004
v  17.501945 7.036966 -4.568850
v  17.652044 6.838212 -4.671054
v  17.710663 6.909111 -4.688203
v  17.835218 7.069186 -4.392068
v  17.562153 6.979855 -4.428271
v  17.970644 6.862309 -4.566416
v  17.447325 7.142372 -4.552946
v  17.637293 6.814502 -4.334622
v  17.926634 6.977213 -4.370671
v  17.898378 6.742722 -4.611046
v  17.773643 6.880084 -4.262391
v  18.025763 7.068440 -4.459461
v  17.909927 7.104021 -4.584668
v  17.599152 6.757418 -4.575081
v  17.768814 7.124709 -4.606726
v  17.766233 6.726504 -4.700590
v  17.868883 6.746957 -4.683364
v  17.867664 6.828932 -4.287608
v  17.494839 7.138599 -4.581817
v  17.570911 7.163069 -4.579761
v  17.984875 6.951183 -4.435847
v  17.907909 7.195146 -4.524491
v  17.508520 7.069149 -4.498302
v  18.072094 7.202573 -4.491747
v  17.986942 6.871943 -4.493610
v  17.666071 6.857901 -4.277526
v  17.662533 6.950109 -4.339567
v  17.499760 7.041625 -4.507647
v  17.547577 6.852854 -4.516685
v  17.571102 6.934263 -4.616823
v  17.984158 6.977691 -4.552344
v  17.442141 7.162747 -4.565322
v  17.974827 7.123028 -4.441716
v  17.541143 6.917511 -4.558822
v  17.631195 6.930641 -4.660055
v  17.568565 7.101190 -4.481443
v  17.988098 7.072012 -4.425685
v  17.550350 6.863581 -4.442099
v  17.969152 6.954117 -4.408910
v  17.759600 6.838684 -4.708619
v  17.685810 6.837251 -4.688900
v  18.042786 7.121889 -4.501524
v  17.673107 6.834233 -4.274545
v  18.047176 7.207409 -4.510181
v  17.908770 7.045816 -4.606380
v  17.960257 6.787539 -4.448751
v  17.602345 6.814362 -4.351647
v  17.855007 7.184933 -4.490167
v  17.788303 6.954718 -4.678032
v  17.610371 7.129334 -4.488598
v  17.819920 6.960330 -4.314587
v  17.599297 7.062721 -4.628844
v  17.864664 6.731411 -4.677125
v  17.875706 6.855083 -4.292335
v  17.571039 6.938331 -4.405622
v  17.804974 6.837636 -4.256405
v  17.479305 7.114748 -4.579607
v  17.514721 6.993060 -4.489067
v  17.948339 6.934344 -4.371649
v  17.980175 6.867404 -4.530250
v  17.871166 6.845785 -4.680394
v  17.344021 6.303306 -3.620484
v  17.343729 6.299536 -3.621656
v  17.343288 6.296403 -3.624038
v  17.342751 6.294288 -3.627340
v  17.342180 6.293444 -3.631166
v  17.341648 6.293971 -3.635053
v  17.341217 6.295809 -3.638533
v  17.340940 6.298734 -3.641186
v  17.340849 6.302393 -3.642693
v  17.340956 6.306346 -3.642870
v  17.341248 6.310117 -3.641698
v  17.341690 6.313249 -3.639317
v  17.342228 6.315365 -3.636014
v  17.342796 6.316210 -3.632189
v  17.343328 6.315681 -3.628302
v  17.343760 6.313845 -3.624821
v  17.343082 6.307165 -3.628028
v  17.407005 6.289963 -3.634566
v  17.344128 6.307260 -3.620662
v  17.344038 6.310920 -3.622168
v  17.405764 6.289116 -3.624720
v  17.403406 6.285950 -3.624432
v  17.401186 6.282854 -3.625511
v  17.399374 6.280202 -3.627825
v  17.398186 6.278316 -3.631097
v  17.397764 6.277421 -3.634931
v  17.398163 6.277627 -3.638864
v  17.399334 6.278908 -3.642423
v  17.401133 6.281108 -3.645178
v  17.403343 6.283966 -3.646796
v  17.405703 6.287133 -3.647083
v  17.407921 6.290229 -3.646006
v  17.409735 6.292880 -3.643691
v  17.410923 6.294766 -3.640419
v  17.411343 6.295660 -3.636586
v  17.410944 6.295455 -3.632652
v  17.409775 6.294176 -3.629093
v  17.407976 6.291973 -3.626339
v  17.343702 6.289773 -3.631230
v  17.282393 6.288805 -3.608177
v  17.284208 6.285692 -3.609816
v  17.285337 6.283058 -3.612546
v  17.285646 6.281219 -3.616039
v  17.285097 6.280400 -3.619873
v  17.283756 6.280696 -3.623586
v  17.281782 6.282074 -3.626729
v  17.279417 6.284369 -3.628923
v  17.276945 6.287300 -3.629907
v  17.274664 6.290517 -3.629558
v  17.272848 6.293630 -3.627919
v  17.271717 6.296265 -3.625189
v  17.271408 6.298102 -3.621696
v  17.271957 6.298923 -3.617863
v  17.273300 6.298625 -3.614149
v  17.275272 6.297246 -3.611006
v  17.279135 6.290566 -3.614631
v  17.339539 6.303954 -3.635571
v  17.280109 6.292022 -3.607828
v  17.277637 6.294954 -3.608810
v  17.341822 6.307320 -3.620331
v  17.341772 6.303365 -3.620161
v  17.341528 6.299592 -3.621340
v  17.341122 6.296461 -3.623726
v  17.340599 6.294343 -3.627031
v  17.340027 6.293499 -3.630856
v  17.339472 6.294028 -3.634740
v  17.339001 6.295866 -3.638215
v  17.338671 6.298793 -3.640861
v  17.338522 6.302454 -3.642359
v  17.338573 6.306410 -3.642528
v  17.338816 6.310181 -3.641349
v  17.339224 6.313313 -3.638963
v  17.339745 6.315430 -3.635658
v  17.340317 6.316276 -3.631833
v  17.340874 6.315746 -3.627949
v  17.341343 6.313908 -3.624475
v  17.341673 6.310981 -3.621828
v  17.337599 6.290608 -3.635572
v  17.265442 6.237817 -3.587937
v  17.268244 6.237597 -3.590724
v  17.270002 6.236783 -3.594176
v  17.270504 6.235471 -3.597878
v  17.269688 6.233819 -3.601382
v  17.267651 6.232028 -3.604267
v  17.264641 6.230315 -3.606184
v  17.261021 6.228883 -3.606901
v  17.257225 6.227907 -3.606334
v  17.253716 6.227505 -3.604548
v  17.250912 6.227724 -3.601763
v  17.249153 6.228539 -3.598310
v  17.248652 6.229851 -3.594608
v  17.249470 6.231502 -3.591105
v  17.251505 6.233293 -3.588220
v  17.254515 6.235007 -3.586303
v  17.262903 6.234556 -3.594126
v  17.279018 6.286835 -3.614864
v  17.261930 6.237413 -3.586153
v  17.258137 6.236439 -3.585586
v  17.278397 6.290699 -3.607290
v  17.280783 6.287564 -3.607672
v  17.282681 6.284513 -3.609337
v  17.283861 6.281919 -3.612082
v  17.284184 6.280092 -3.615580
v  17.283606 6.279251 -3.619405
v  17.282202 6.279499 -3.623097
v  17.280138 6.280807 -3.626213
v  17.277662 6.283017 -3.628374
v  17.275078 6.285861 -3.629320
v  17.272692 6.288997 -3.628939
v  17.270794 6.292047 -3.627274
v  17.269611 6.294641 -3.624528
v  17.269291 6.296471 -3.621031
v  17.269867 6.297311 -3.617206
v  17.271273 6.297061 -3.613512
v  17.273336 6.295753 -3.610397
v  17.275810 6.293545 -3.608236
v  17.300358 6.186325 -3.570807
v  17.301973 6.187758 -3.574124
v  17.302870 6.188140 -3.577961
v  17.302942 6.187426 -3.581854
v  17.302179 6.185699 -3.585334
v  17.300673 6.183168 -3.587981
v  17.298607 6.180140 -3.589476
v  17.296228 6.176980 -3.589638
v  17.293825 6.174067 -3.588448
v  17.291687 6.171756 -3.586049
v  17.290071 6.170321 -3.582732
v  17.289175 6.169939 -3.578895
v  17.289103 6.170654 -3.575002
v  17.289865 6.172379 -3.571522
v  17.291370 6.174910 -3.568875
v  17.293438 6.177938 -3.567381
v  17.298306 6.182385 -3.576777
v  17.256437 6.230020 -3.593402
v  17.298220 6.184011 -3.568409
v  17.295816 6.181098 -3.567219
v  17.262604 6.235399 -3.585353
v  17.266079 6.235913 -3.587181
v  17.268852 6.235785 -3.590003
v  17.270592 6.235030 -3.593478
v  17.271088 6.233738 -3.597188
v  17.270279 6.232069 -3.600684
v  17.268263 6.230220 -3.603547
v  17.265285 6.228415 -3.605428
v  17.261702 6.226871 -3.606103
v  17.257946 6.225778 -3.605490
v  17.254473 6.225264 -3.603663
v  17.251699 6.225390 -3.600842
v  17.249958 6.226146 -3.597367
v  17.249464 6.227437 -3.593657
v  17.250273 6.229106 -3.590160
v  17.252289 6.230956 -3.587298
v  17.255266 6.232761 -3.585415
v  17.258850 6.234303 -3.584740
v  17.361074 6.166770 -3.573655
v  17.360567 6.168907 -3.576950
v  17.359932 6.169775 -3.580759
v  17.359247 6.169271 -3.584626
v  17.358593 6.167454 -3.588082
v  17.358049 6.164544 -3.590712
v  17.357681 6.160892 -3.592196
v  17.357534 6.156940 -3.592359
v  17.357624 6.153162 -3.591177
v  17.357943 6.150016 -3.588797
v  17.358450 6.147880 -3.585502
v  17.359083 6.147012 -3.581692
v  17.359770 6.147516 -3.577826
v  17.360424 6.149333 -3.574369
v  17.360968 6.152243 -3.571740
v  17.361336 6.155894 -3.570255
v  17.358786 6.156385 -3.585045
v  17.297054 6.175741 -3.581993
v  17.361391 6.163624 -3.571274
v  17.361483 6.159847 -3.570093
v  17.300081 6.182629 -3.568130
v  17.302141 6.185000 -3.570539
v  17.303686 6.186486 -3.573868
v  17.304535 6.186905 -3.577712
v  17.304581 6.186210 -3.581609
v  17.303820 6.184481 -3.585089
v  17.302345 6.181928 -3.587731
v  17.300333 6.178860 -3.589218
v  17.298027 6.175646 -3.589369
v  17.295704 6.172674 -3.588167
v  17.293646 6.170300 -3.585756
v  17.292101 6.168816 -3.582429
v  17.291252 6.168396 -3.578585
v  17.291206 6.169093 -3.574687
v  17.291965 6.170822 -3.571207
v  17.293442 6.173374 -3.568565
v  17.295454 6.176442 -3.567079
v  17.297760 6.179657 -3.566927
v  17.420198 6.182563 -3.590401
v  17.417744 6.184083 -3.593110
v  17.415791 6.184536 -3.596523
v  17.414574 6.183864 -3.600230
v  17.414240 6.182149 -3.603783
v  17.414829 6.179600 -3.606753
v  17.416271 6.176521 -3.608784
v  17.418390 6.173285 -3.609627
v  17.420933 6.170284 -3.609183
v  17.423592 6.167878 -3.607506
v  17.426046 6.166357 -3.604797
v  17.427999 6.165905 -3.601383
v  17.429216 6.166575 -3.597676
v  17.429550 6.168291 -3.594124
v  17.428961 6.170841 -3.591154
v  17.427519 6.173920 -3.589124
v  17.424704 6.171903 -3.598464
v  17.361383 6.161822 -3.584252
v  17.422857 6.180156 -3.588724
v  17.425400 6.177155 -3.588280
v  17.363680 6.163576 -3.571680
v  17.363293 6.166724 -3.574049
v  17.362726 6.168860 -3.577333
v  17.362051 6.169729 -3.581137
v  17.361345 6.169225 -3.584999
v  17.360697 6.167407 -3.588456
v  17.360182 6.164496 -3.591090
v  17.359863 6.160845 -3.592584
v  17.359781 6.156889 -3.592756
v  17.359943 6.153111 -3.591587
v  17.360329 6.149962 -3.589218
v  17.360895 6.147826 -3.585934
v  17.361570 6.146956 -3.582130
v  17.362276 6.147461 -3.578268
v  17.362925 6.149280 -3.574810
v  17.363440 6.152190 -3.572177
v  17.363758 6.155843 -3.570683
v  17.363840 6.159797 -3.570511
v  17.361919 6.170269 -3.590908
v  17.437420 6.235096 -3.613139
v  17.433971 6.233880 -3.614654
v  17.431339 6.232478 -3.617259
v  17.429844 6.231059 -3.620638
v  17.429663 6.229796 -3.624386
v  17.430820 6.228841 -3.628049
v  17.433174 6.228308 -3.631188
v  17.436443 6.228261 -3.633420
v  17.440231 6.228708 -3.634479
v  17.444084 6.229593 -3.634235
v  17.447533 6.230810 -3.632721
v  17.450165 6.232212 -3.630117
v  17.451660 6.233631 -3.626737
v  17.451841 6.234894 -3.622989
v  17.450684 6.235848 -3.619326
v  17.448328 6.236382 -3.616189
v  17.440552 6.230948 -3.627829
v  17.423227 6.174705 -3.603700
v  17.441271 6.235981 -3.612896
v  17.445059 6.236429 -3.613956
v  17.424503 6.181492 -3.589518
v  17.421736 6.183810 -3.591142
v  17.419189 6.185256 -3.593806
v  17.417175 6.185658 -3.597191
v  17.415932 6.184965 -3.600884
v  17.415611 6.183263 -3.604445
v  17.416252 6.180755 -3.607439
v  17.417778 6.177743 -3.609509
v  17.420002 6.174593 -3.610404
v  17.422659 6.171685 -3.610016
v  17.425426 6.169365 -3.608391
v  17.427971 6.167919 -3.605726
v  17.429987 6.167518 -3.602343
v  17.431231 6.168210 -3.598648
v  17.431551 6.169914 -3.595088
v  17.430910 6.172421 -3.592093
v  17.429384 6.175432 -3.590023
v  17.427160 6.178582 -3.589128
v  17.403088 6.286182 -3.624452
v  17.400883 6.283078 -3.625529
v  17.399078 6.280418 -3.627844
v  17.397894 6.278530 -3.631115
v  17.397474 6.277635 -3.634949
v  17.397867 6.277844 -3.638882
v  17.399025 6.279134 -3.642442
v  17.400810 6.281344 -3.645197
v  17.403008 6.284213 -3.646817
v  17.405350 6.287392 -3.647105
v  17.407557 6.290498 -3.646029
v  17.409359 6.293156 -3.643714
v  17.410543 6.295046 -3.640443
v  17.410963 6.295938 -3.636609
v  17.410572 6.295730 -3.632675
v  17.409412 6.294441 -3.629116
v  17.402939 6.285364 -3.631845
v  17.440062 6.233131 -3.628533
v  17.405430 6.289361 -3.624741
v  17.407627 6.292230 -3.626360
v  17.440798 6.238081 -3.613574
v  17.436972 6.237090 -3.613782
v  17.433542 6.235786 -3.615269
v  17.430923 6.234329 -3.617855
v  17.429430 6.232892 -3.621229
v  17.429245 6.231648 -3.624983
v  17.430389 6.230752 -3.628666
v  17.432724 6.230306 -3.631831
v  17.435970 6.230367 -3.634099
v  17.439732 6.230927 -3.635195
v  17.443560 6.231919 -3.634986
v  17.446989 6.233222 -3.633499
v  17.449608 6.234680 -3.630913
v  17.451099 6.236116 -3.627539
v  17.451284 6.237359 -3.623785
v  17.450142 6.238256 -3.620103
v  17.447805 6.238702 -3.616938
v  17.444561 6.238642 -3.614670
v  17.398670 6.275743 -3.644470
v  17.271296 6.228757 -3.605587
v  17.280012 6.277448 -3.628313
v  17.355236 6.151573 -3.594027
v  17.428625 6.231154 -3.614769
v  17.247799 6.226751 -3.587751
v  17.339828 6.316792 -3.622020
v  17.346228 6.302757 -3.617178
v  17.359875 6.169487 -3.570966
v  17.365908 6.150229 -3.569537
v  17.404079 6.282310 -3.650221
v  17.249048 6.227212 -3.603040
v  17.426420 6.227616 -3.624154
v  17.336454 6.296885 -3.643620
v  17.294258 6.182908 -3.563911
v  17.417147 6.296306 -3.636638
v  17.363716 6.173326 -3.581272
v  17.356194 6.144619 -3.586531
v  17.432739 6.171918 -3.590762
v  17.363464 6.143337 -3.582647
v  17.410225 6.292819 -3.623277
v  17.338415 6.319910 -3.631701
v  17.345531 6.319046 -3.627502
v  17.445503 6.226872 -3.636969
v  17.359480 6.150362 -3.568402
v  17.366318 6.160248 -3.567324
v  17.358475 6.173440 -3.580338
v  17.280916 6.286392 -3.603915
v  17.336212 6.306917 -3.645800
v  17.346367 6.312778 -3.619411
v  17.401491 6.284212 -3.650381
v  17.345175 6.293671 -3.621844
v  17.247387 6.222857 -3.597244
v  17.360271 6.160373 -3.566252
v  17.344109 6.318629 -3.637663
v  17.336987 6.316010 -3.641092
v  17.262913 6.296210 -3.620681
v  17.253281 6.221837 -3.605591
v  17.254326 6.231696 -3.581554
v  17.342766 6.311719 -3.645141
v  17.274149 6.294086 -3.604585
v  17.300398 6.189666 -3.568611
v  17.445484 6.241677 -3.612137
v  17.275822 6.300791 -3.608978
v  17.429592 6.168242 -3.587942
v  17.429626 6.180379 -3.586432
v  17.451090 6.235833 -3.613208
v  17.451738 6.239188 -3.634631
v  17.419245 6.167677 -3.611790
v  17.284866 6.289435 -3.605156
v  17.292727 6.162434 -3.578252
v  17.296488 6.170047 -3.591143
v  17.272730 6.296101 -3.631283
v  17.340567 6.308114 -3.616579
v  17.252375 6.237552 -3.583866
v  17.283987 6.172927 -3.569696
v  17.427422 6.168313 -3.611836
v  17.342131 6.301553 -3.646435
v  17.263910 6.235378 -3.581552
v  17.425089 6.176698 -3.584245
v  17.299263 6.179193 -3.563163
v  17.407522 6.294806 -3.623444
v  17.361145 6.151440 -3.595070
v  17.356726 6.170384 -3.589985
v  17.291700 6.173599 -3.591859
v  17.439125 6.231514 -3.639164
v  17.274988 6.233459 -3.597239
v  17.339111 6.291025 -3.625425
v  17.398668 6.277491 -3.625250
v  17.407730 6.297849 -3.646725
v  17.816135 5.566263 -4.250722
v  17.818764 5.563330 -4.251115
v  17.822201 5.561477 -4.251762
v  17.826035 5.560930 -4.252587
v  17.829800 5.561754 -4.253487
v  17.833046 5.563848 -4.254355
v  17.835377 5.566964 -4.255089
v  17.836514 5.570722 -4.255596
v  17.836321 5.574670 -4.255819
v  17.834818 5.578331 -4.255727
v  17.832190 5.581264 -4.255334
v  17.828751 5.583117 -4.254687
v  17.824917 5.583664 -4.253862
v  17.821152 5.582840 -4.252963
v  17.817907 5.580746 -4.252093
v  17.815575 5.577631 -4.251361
v  17.821239 5.572902 -4.252315
v  17.814632 5.569926 -4.250630
v  17.814438 5.573873 -4.250854
v  17.830833 5.557461 -4.189584
v  17.831724 5.554383 -4.191908
v  17.833832 5.551949 -4.194213
v  17.836899 5.550455 -4.196220
v  17.840557 5.550077 -4.197690
v  17.844360 5.550867 -4.198442
v  17.847858 5.552722 -4.198388
v  17.850622 5.555425 -4.197533
v  17.852322 5.558647 -4.195981
v  17.852749 5.561997 -4.193918
v  17.851856 5.565077 -4.191595
v  17.849751 5.567510 -4.189290
v  17.846684 5.569005 -4.187282
v  17.843025 5.569381 -4.185813
v  17.839222 5.568593 -4.185061
v  17.835724 5.566737 -4.185115
v  17.832960 5.564034 -4.185970
v  17.831261 5.560812 -4.187522
v  17.806282 5.545670 -4.311044
v  17.809221 5.543605 -4.309378
v  17.812889 5.542408 -4.308490
v  17.816845 5.542224 -4.308486
v  17.820610 5.543075 -4.309367
v  17.823730 5.544858 -4.311028
v  17.825829 5.547359 -4.313267
v  17.826654 5.550275 -4.315815
v  17.826107 5.553254 -4.318363
v  17.824253 5.555938 -4.320606
v  17.821316 5.558002 -4.322271
v  17.817646 5.559198 -4.323160
v  17.813692 5.559382 -4.323164
v  17.809929 5.558533 -4.322282
v  17.806807 5.556748 -4.320621
v  17.804707 5.554250 -4.318382
v  17.829128 5.573037 -4.256502
v  17.804428 5.548355 -4.313286
v  17.803881 5.551333 -4.315835
v  17.814125 5.569755 -4.252899
v  17.815639 5.566098 -4.252934
v  17.818279 5.563167 -4.253280
v  17.821726 5.561317 -4.253893
v  17.825562 5.560771 -4.254701
v  17.829327 5.561595 -4.255605
v  17.832567 5.563689 -4.256496
v  17.834890 5.566801 -4.257269
v  17.836016 5.570555 -4.257828
v  17.835808 5.574499 -4.258106
v  17.834293 5.578156 -4.258070
v  17.831656 5.581087 -4.257726
v  17.828209 5.582937 -4.257112
v  17.824371 5.583483 -4.256305
v  17.820606 5.582659 -4.255401
v  17.817368 5.580564 -4.254509
v  17.815044 5.577454 -4.253736
v  17.813919 5.573699 -4.253178
v  17.834616 5.560872 -4.258435
v  17.808220 5.490313 -4.326165
v  17.811050 5.491392 -4.323615
v  17.814650 5.492171 -4.322165
v  17.818584 5.492558 -4.321988
v  17.822384 5.492507 -4.323108
v  17.825583 5.492023 -4.325387
v  17.827801 5.491166 -4.328552
v  17.828766 5.490037 -4.332222
v  17.828367 5.488775 -4.335953
v  17.826649 5.487529 -4.339293
v  17.823818 5.486451 -4.341844
v  17.820219 5.485671 -4.343295
v  17.816282 5.485284 -4.343471
v  17.812485 5.485334 -4.342352
v  17.809286 5.485818 -4.340072
v  17.807068 5.486676 -4.336905
v  17.814983 5.489870 -4.329232
v  17.806501 5.489068 -4.329507
v  17.806101 5.487804 -4.333238
v  17.804367 5.546866 -4.314946
v  17.806225 5.544272 -4.312602
v  17.809166 5.542280 -4.310857
v  17.812836 5.541128 -4.309919
v  17.816792 5.540955 -4.309904
v  17.820555 5.541781 -4.310811
v  17.823673 5.543510 -4.312531
v  17.825769 5.545932 -4.314860
v  17.826591 5.548753 -4.317513
v  17.826038 5.551634 -4.320172
v  17.824181 5.554226 -4.322517
v  17.821239 5.556220 -4.324262
v  17.817570 5.557373 -4.325199
v  17.813614 5.557546 -4.325214
v  17.809851 5.556717 -4.324308
v  17.806732 5.554990 -4.322585
v  17.804636 5.552568 -4.320258
v  17.803814 5.549747 -4.317604
v  17.817272 5.437720 -4.289888
v  17.819790 5.440475 -4.288568
v  17.823162 5.442470 -4.288009
v  17.826984 5.443464 -4.288277
v  17.830797 5.443336 -4.289340
v  17.834137 5.442101 -4.291071
v  17.836603 5.439910 -4.293260
v  17.837896 5.437027 -4.295643
v  17.837864 5.433796 -4.297933
v  17.836506 5.430613 -4.299853
v  17.833990 5.427856 -4.301174
v  17.830616 5.425862 -4.301734
v  17.826794 5.424868 -4.301466
v  17.822983 5.424997 -4.300403
v  17.819643 5.426229 -4.298672
v  17.817177 5.428421 -4.296483
v  17.815916 5.434535 -4.291810
v  17.815882 5.431304 -4.294100
v  17.806683 5.486928 -4.328766
v  17.808392 5.488292 -4.325465
v  17.811211 5.489467 -4.322947
v  17.814806 5.490310 -4.321517
v  17.818739 5.490720 -4.321347
v  17.822538 5.490649 -4.322457
v  17.825745 5.490103 -4.324714
v  17.827972 5.489150 -4.327846
v  17.828951 5.487904 -4.331474
v  17.828564 5.486516 -4.335160
v  17.826855 5.485153 -4.338462
v  17.824036 5.483979 -4.340980
v  17.820442 5.483135 -4.342410
v  17.816507 5.482725 -4.342580
v  17.812708 5.482797 -4.341470
v  17.809502 5.483342 -4.339211
v  17.807274 5.484294 -4.336081
v  17.806295 5.485539 -4.332453
v  17.832905 5.423433 -4.229653
v  17.834934 5.426738 -4.230444
v  17.837959 5.429105 -4.231408
v  17.841608 5.430251 -4.232429
v  17.845444 5.430035 -4.233381
v  17.849005 5.428484 -4.234152
v  17.851860 5.425786 -4.234648
v  17.853664 5.422267 -4.234808
v  17.854202 5.418350 -4.234615
v  17.853407 5.414507 -4.234091
v  17.851376 5.411202 -4.233299
v  17.848354 5.408834 -4.232335
v  17.844704 5.407690 -4.231315
v  17.840866 5.407906 -4.230362
v  17.837307 5.409456 -4.229590
v  17.834452 5.412154 -4.229095
v  17.847395 5.418733 -4.232924
v  17.832108 5.419590 -4.229128
v  17.832645 5.415673 -4.228934
v  17.816395 5.433233 -4.289930
v  17.817730 5.436472 -4.288090
v  17.820230 5.439278 -4.286839
v  17.823591 5.441307 -4.286330
v  17.827408 5.442317 -4.286624
v  17.831219 5.442188 -4.287684
v  17.834568 5.440933 -4.289384
v  17.837048 5.438704 -4.291518
v  17.838360 5.435770 -4.293829
v  17.838348 5.432485 -4.296038
v  17.837011 5.429244 -4.297878
v  17.834513 5.426440 -4.299129
v  17.831152 5.424409 -4.299638
v  17.827335 5.423398 -4.299344
v  17.823523 5.423529 -4.298284
v  17.820175 5.424784 -4.296584
v  17.817696 5.427011 -4.294450
v  17.816383 5.429946 -4.292139
v  17.846020 5.446974 -4.172213
v  17.847630 5.449401 -4.174892
v  17.850365 5.451177 -4.177136
v  17.853899 5.452084 -4.178672
v  17.857805 5.452013 -4.179315
v  17.861610 5.450974 -4.178987
v  17.864859 5.449092 -4.177727
v  17.867155 5.446594 -4.175689
v  17.868225 5.443779 -4.173117
v  17.867937 5.440990 -4.170322
v  17.866329 5.438561 -4.167642
v  17.863590 5.436788 -4.165399
v  17.860056 5.435881 -4.163863
v  17.856152 5.435950 -4.163221
v  17.852345 5.436990 -4.163548
v  17.849098 5.438871 -4.164808
v  17.844587 5.423420 -4.230268
v  17.845732 5.444184 -4.169418
v  17.846802 5.441370 -4.166846
v  17.832676 5.419802 -4.226885
v  17.833454 5.423637 -4.227477
v  17.835472 5.426937 -4.228327
v  17.838486 5.429300 -4.229331
v  17.842129 5.430444 -4.230371
v  17.845968 5.430227 -4.231318
v  17.849535 5.428679 -4.232060
v  17.852402 5.425987 -4.232507
v  17.854223 5.422472 -4.232605
v  17.854778 5.418561 -4.232342
v  17.853998 5.414725 -4.231750
v  17.851982 5.411426 -4.230901
v  17.848969 5.409061 -4.229896
v  17.845324 5.407919 -4.228856
v  17.841486 5.408134 -4.227909
v  17.837917 5.409683 -4.227167
v  17.835051 5.412376 -4.226720
v  17.833231 5.415891 -4.226622
v  17.847069 5.433898 -4.230314
v  17.845707 5.504793 -4.157039
v  17.847317 5.504200 -4.160607
v  17.850052 5.503930 -4.163455
v  17.853584 5.504012 -4.165241
v  17.857489 5.504438 -4.165747
v  17.861292 5.505157 -4.164914
v  17.864536 5.506081 -4.162841
v  17.866829 5.507100 -4.159779
v  17.867895 5.508090 -4.156098
v  17.867607 5.508932 -4.152240
v  17.865997 5.509523 -4.148672
v  17.863260 5.509796 -4.145824
v  17.859728 5.509713 -4.144039
v  17.855825 5.509286 -4.143532
v  17.852022 5.508568 -4.144366
v  17.848778 5.507643 -4.146438
v  17.860970 5.507334 -4.155200
v  17.861586 5.445561 -4.170355
v  17.845417 5.505635 -4.153182
v  17.846485 5.506625 -4.149499
v  17.846014 5.445790 -4.167848
v  17.846283 5.448472 -4.170747
v  17.847876 5.450810 -4.173515
v  17.850601 5.452526 -4.175817
v  17.854132 5.453407 -4.177377
v  17.858040 5.453351 -4.178006
v  17.861855 5.452363 -4.177629
v  17.865116 5.450562 -4.176291
v  17.867432 5.448164 -4.174151
v  17.868521 5.445463 -4.171472
v  17.868252 5.442779 -4.168572
v  17.866659 5.440440 -4.165805
v  17.863932 5.438725 -4.163503
v  17.860401 5.437844 -4.161942
v  17.856495 5.437901 -4.161313
v  17.852680 5.438889 -4.161690
v  17.849419 5.440690 -4.163029
v  17.847103 5.443087 -4.165167
v  17.831619 5.554591 -4.192225
v  17.833731 5.552149 -4.194516
v  17.836800 5.550648 -4.196514
v  17.840458 5.550268 -4.197978
v  17.844265 5.551056 -4.198730
v  17.847759 5.552917 -4.198684
v  17.850521 5.555625 -4.197839
v  17.852215 5.558856 -4.196301
v  17.852638 5.562219 -4.194254
v  17.851740 5.565308 -4.191945
v  17.849628 5.567750 -4.189654
v  17.846558 5.569250 -4.187656
v  17.842899 5.569631 -4.186192
v  17.839094 5.568841 -4.185438
v  17.835600 5.566981 -4.185486
v  17.832840 5.564271 -4.186331
v  17.838629 5.556956 -4.193018
v  17.860645 5.509590 -4.155748
v  17.830721 5.557679 -4.189916
v  17.831144 5.561040 -4.187869
v  17.845104 5.507804 -4.153709
v  17.845409 5.506854 -4.157540
v  17.847033 5.506172 -4.161086
v  17.849777 5.505843 -4.163920
v  17.853312 5.505906 -4.165701
v  17.857212 5.506353 -4.166213
v  17.861006 5.507133 -4.165394
v  17.864239 5.508148 -4.163342
v  17.866516 5.509276 -4.160309
v  17.867565 5.510384 -4.156656
v  17.867260 5.511336 -4.152825
v  17.865639 5.512018 -4.149279
v  17.862894 5.512347 -4.146444
v  17.859358 5.512284 -4.144664
v  17.855457 5.511836 -4.144153
v  17.851664 5.511058 -4.144971
v  17.848433 5.510042 -4.147021
v  17.846155 5.508913 -4.150057
v  17.853756 5.553395 -4.198370
v  17.828445 5.489748 -4.321871
v  17.803339 5.541634 -4.312140
v  17.821526 5.558146 -4.249692
v  17.835915 5.432197 -4.232824
v  17.857944 5.502244 -4.168958
v  17.821594 5.487655 -4.330384
v  17.844120 5.557041 -4.194298
v  17.823154 5.431741 -4.292660
v  17.828320 5.430979 -4.297503
v  17.818516 5.548522 -4.313984
v  17.858757 5.446666 -4.174230
v  17.814594 5.552433 -4.320497
v  17.829950 5.482815 -4.339818
v  17.839138 5.569906 -4.260277
v  17.841711 5.507690 -4.158709
v  17.825176 5.587386 -4.252132
v  17.830963 5.493392 -4.327710
v  17.836559 5.406886 -4.224479
v  17.853504 5.408597 -4.235637
v  17.851833 5.437941 -4.159751
v  17.811489 5.578978 -4.255032
v  17.837219 5.432680 -4.227685
v  17.831532 5.558511 -4.251976
v  17.845776 5.433423 -4.235407
v  17.828815 5.586195 -4.259525
v  17.815937 5.583539 -4.249830
v  17.851116 5.406069 -4.228055
v  17.830357 5.415049 -4.223876
v  17.818802 5.585828 -4.257284
v  17.813881 5.439825 -4.289657
v  17.821518 5.480008 -4.344962
v  17.838888 5.565376 -4.254021
v  17.854206 5.427824 -4.237152
v  17.839455 5.569414 -4.179280
v  17.861437 5.506009 -4.139304
v  17.811335 5.479556 -4.343723
v  17.812904 5.556928 -4.331537
v  17.842447 5.502577 -4.157462
v  17.864513 5.518075 -4.144647
v  17.847164 5.574712 -4.189543
v  17.833488 5.418920 -4.298141
v  17.871967 5.443351 -4.166885
v  17.834740 5.584224 -4.254123
v  17.834969 5.406292 -4.230783
v  17.820812 5.489321 -4.348478
v  17.803486 5.545061 -4.308316
v  17.820639 5.424508 -4.306100
v  17.767887 5.336265 -4.499841
v  17.621277 5.477722 -4.992917
v  18.316126 5.691017 -4.195758
v  18.311640 2.957232 -4.640251
v  18.129986 6.421366 -4.646101
v  17.550241 3.044010 -4.706693
v  17.641260 5.592135 -4.320268
v  18.273546 5.343408 -4.899350
v  17.819300 4.090427 -5.292435
v  18.178276 3.500713 -4.453166
v  18.305397 3.027879 -5.111386
v  17.608900 4.718244 -4.407714
v  17.568539 2.980730 -4.659490
v  18.141911 5.391434 -4.433944
v  17.925802 5.889149 -5.097251
v  18.490393 3.799847 -4.741415
v  18.274832 4.834082 -4.475977
v  18.546400 5.943302 -4.349597
v  17.926304 5.390976 -5.081450
v  18.198702 5.795082 -5.027086
v  18.455992 6.293087 -4.518945
v  17.506275 4.097693 -4.523067
v  18.285805 3.297943 -4.532918
v  17.528156 4.213407 -5.176409
v  17.731417 5.114395 -4.442508
v  18.421865 5.009381 -4.942350
v  17.641605 2.954610 -5.117200
v  17.869772 6.041835 -4.299640
v  18.306168 6.193326 -4.250394
v  17.545452 5.063618 -5.084441
v  17.830799 4.995559 -5.225148
v  18.289833 4.040456 -5.132897
v  18.113598 5.334912 -4.514641
v  17.448460 5.177362 -4.631881
v  17.432545 4.067570 -4.913056
v  18.165895 5.646182 -4.286851
v  18.140009 3.727681 -4.343509
v  18.492086 5.977427 -4.745515
v  17.845844 3.561319 -4.524018
v  17.812834 3.617277 -5.268548
v  17.699411 3.799593 -4.386776
v  18.441696 3.335684 -4.922865
v  18.202579 4.764982 -4.402276
v  18.317739 3.859448 -4.514482
v  18.411457 5.065877 -4.682425
v  17.546219 5.417245 -4.731945
v  18.069469 6.141205 -5.021932
v  17.437124 4.692270 -4.612609
v  18.276333 6.421942 -4.650688
v  18.158121 3.600450 -5.209666
v  18.386282 5.715722 -4.804695
v  17.569082 3.343944 -4.553210
v  18.245125 5.043279 -5.094008
v  18.428547 4.482383 -4.634135
v  17.745176 5.241436 -5.111172
v  17.973860 3.773800 -4.335506
v  18.049843 5.505743 -5.057857
v  17.947721 3.052100 -5.223426
v  18.116528 6.278296 -4.274605
v  17.681753 5.335829 -4.408246
v  18.036173 5.204543 -5.132437
v  17.461163 4.360242 -4.572101
v  18.471191 6.097423 -4.320653
v  18.123444 5.886209 -4.278072
v  17.891829 5.292661 -4.397811
v  17.546329 4.796840 -5.167818
v  17.990229 3.610636 -5.183870
v  18.389568 3.451113 -4.596148
v  18.352659 5.290757 -4.834167
v  17.536438 3.454117 -5.093130
v  17.738914 4.326017 -4.375131
v  18.062885 4.194370 -4.421178
v  18.032972 5.236451 -4.396668
v  17.526543 4.911438 -4.611635
v  18.258244 6.374219 -4.777227
v  18.046257 4.439982 -5.248837
v  18.480835 6.237674 -4.650101
v  18.093718 3.865353 -5.260605
v  17.489096 3.374891 -4.911530
v  18.098396 4.953528 -4.497026
v  17.847786 5.482835 -5.060651
v  18.282742 5.309902 -4.627443
v  18.436125 3.718197 -4.915606
v  17.510380 5.437779 -4.942170
v  18.234901 4.716386 -5.159440
v  18.461510 4.086296 -4.915153
v  18.450174 4.670477 -4.936695
v  17.783590 4.867215 -4.393102
v  18.049971 5.672144 -5.084548
v  17.875511 5.879674 -4.261177
v  17.572580 3.908833 -4.500042
v  17.813992 4.467072 -5.257781
v  18.025946 6.284270 -4.411541
v  17.547688 5.393429 -4.520307
v  18.036921 3.598915 -4.389392
v  17.617306 3.073507 -4.558475
v  17.686462 5.129898 -5.133243
v  17.960178 6.167651 -4.992447
v  18.207251 4.428803 -4.510774
v  18.344584 6.002944 -4.194577
v  18.430708 4.755835 -4.645885
v  18.237169 6.378949 -4.429365
v  18.548971 6.025752 -4.415411
v  17.947994 5.097071 -5.171569
v  18.227192 3.753357 -4.448516
v  17.461693 5.346063 -4.685210
v  18.207550 5.168496 -4.576466
v  18.281851 3.543687 -4.509454
v  17.872206 2.965033 -4.599421
v  18.179163 4.419256 -4.441646
v  18.247959 3.661074 -5.190476
v  18.250816 6.113469 -4.942371
v  18.251741 5.491339 -4.877450
v  18.448734 6.063012 -4.800149
v  18.452131 3.816418 -4.617127
v  18.336136 4.214940 -4.556357
v  17.522053 3.577911 -4.591747
v  17.650103 4.931974 -4.397400
v  18.396957 5.794847 -4.242948
v  17.541176 5.283132 -5.107091
v  18.311943 5.151318 -4.938540
v  18.234795 5.747042 -4.977926
v  17.939100 5.802629 -4.248330
v  17.674389 5.295748 -5.054187
v  18.210323 3.342979 -5.200826
v  18.346455 2.946280 -4.759601
v  17.924574 5.493692 -5.090135
v  18.314653 5.277020 -4.960041
v  17.471275 5.119246 -4.790695
v  17.896492 5.496141 -4.264017
v  17.419458 4.495450 -4.842899
v  17.662378 3.610512 -5.217377
v  17.680222 3.662840 -4.447663
v  17.925318 4.914029 -5.239552
v  18.102608 5.641291 -4.293926
v  17.443377 3.842959 -4.773511
v  18.267218 5.348454 -4.768725
v  17.858305 4.076277 -4.493424
v  18.104111 5.276381 -5.051551
v  17.790348 5.641357 -4.292877
v  18.043182 3.331773 -4.475185
v  17.559788 4.264205 -4.484050
v  18.350153 3.196431 -5.112892
v  18.142481 5.485444 -4.997237
v  17.458714 4.300125 -5.090301
v  17.477066 4.792301 -5.088362
v  17.832106 5.056958 -4.413211
v  18.097546 4.650818 -4.385763
v  18.514969 6.192764 -4.572048
v  18.462952 4.754176 -4.836762
v  18.024553 5.046201 -4.408177
v  17.869904 3.674722 -4.407531
v  18.390360 3.529484 -5.042931
v  17.907095 5.651706 -5.103914
v  18.160450 5.206654 -4.445975
v  17.736668 5.565619 -5.016357
v  17.736942 5.819040 -4.272620
v  18.466312 4.181208 -4.743452
v  17.891624 3.343215 -4.444556
v  18.273012 4.591579 -4.501880
v  17.759806 5.448709 -5.043198
v  18.344381 6.227435 -4.273640
v  17.415049 4.646609 -4.758956
v  17.824677 5.218790 -5.102054
v  18.328209 5.169199 -4.714253
v  18.280350 4.043691 -4.551669
v  18.237324 4.946482 -4.534652
v  17.631374 5.494569 -4.361110
v  18.441156 3.379772 -4.806972
v  18.141333 4.103021 -4.414176
v  18.444160 6.200557 -4.359902
v  18.055882 6.370442 -4.812024
v  18.057611 5.949805 -5.071332
v  18.350111 3.221688 -4.618031
v  17.517725 5.341321 -4.887209
v  17.556719 2.958289 -4.902070
v  18.119118 5.841143 -5.035450
v  18.099514 3.212865 -4.535946
v  18.025578 5.602348 -4.315308
v  17.560556 4.574730 -4.471558
v  18.376625 5.850476 -4.888936
v  17.682835 4.177183 -4.439433
v  17.890514 4.748767 -5.237603
v  18.021523 5.422491 -5.042264
v  18.302322 5.961343 -4.946708
v  18.439962 3.989249 -4.693097
v  18.462809 4.377977 -4.919301
v  17.490826 4.116256 -4.657058
v  18.408062 3.576628 -4.649724
v  18.519270 6.009207 -4.561582
v  18.378366 4.190182 -5.022285
v  17.518473 3.389050 -4.644605
v  18.048073 3.486818 -4.442229
v  17.779285 5.073170 -4.484097
v  17.734604 4.706751 -4.418097
v  17.957357 5.420133 -4.341202
v  17.584711 5.458755 -4.502353
v  18.180500 4.227525 -5.180152
v  18.139622 5.646561 -4.308758
v  17.768419 4.627123 -5.269404
v  18.165449 6.148508 -4.970294
v  18.394617 4.291165 -4.647472
v  18.388321 6.337645 -4.699636
v  18.263317 5.862431 -4.924537
v  17.693258 5.433454 -4.396302
v  18.392021 6.137287 -4.238155
v  18.009182 5.196383 -4.495339
v  18.072266 5.344363 -4.386969
v  17.413355 4.261217 -4.913165
v  17.702145 5.060683 -4.451854
v  17.842316 3.237338 -5.241582
v  17.993801 3.974168 -5.276100
v  17.811592 4.474388 -4.365115
v  17.486685 5.152839 -4.910703
v  18.464115 5.809612 -4.253112
v  18.130901 3.007626 -5.171205
v  17.743397 3.317833 -4.555665
v  17.592377 3.990731 -5.176950
v  18.395994 6.263339 -4.348934
v  17.598976 5.441288 -5.013651
v  18.389353 4.671125 -5.044170
v  17.923321 6.024228 -4.243355
v  17.598150 5.231190 -4.472612
v  18.267931 4.759330 -4.501851
v  18.058872 3.788049 -4.368871
v  17.965471 5.513494 -5.066721
v  17.504492 5.036861 -4.593591
v  17.756498 5.336549 -5.101125
v  18.294167 3.058854 -4.598771
v  17.591362 4.955249 -4.505500
v  18.192497 5.393245 -4.968605
v  17.792814 3.880598 -5.248631
v  17.927505 5.212678 -5.122695
v  17.509521 4.957432 -4.998349
v  18.015327 3.772229 -5.269093
v  17.667139 5.640441 -4.271616
v  18.097450 5.179616 -4.417016
v  18.306000 5.761817 -4.247798
v  17.427738 4.304665 -4.708168
v  18.250523 6.247023 -4.295246
v  18.270838 3.614929 -4.523028
v  18.044704 6.261278 -4.300056
v  17.474894 3.503685 -4.766751
v  17.974207 4.353612 -4.487086
v  17.898544 3.694960 -5.206183
v  17.487837 4.521268 -4.578511
v  17.635445 5.320683 -5.077200
v  18.361420 3.268100 -4.704524
v  17.464565 3.976622 -5.034702
v  18.248959 5.148167 -5.049674
v  18.456882 5.847135 -4.654068
v  17.822430 5.953833 -4.311592
v  18.165268 3.656675 -4.371325
v  18.350351 4.883861 -4.614597
v  18.109962 5.949571 -4.245618
v  17.712814 5.463628 -5.058749
v  18.392412 6.184180 -4.813085
v  17.946672 4.680826 -4.359946
v  17.634052 5.110874 -5.126678
v  18.182045 5.754125 -4.260519
v  17.927057 4.029913 -4.358966
v  18.101618 4.902966 -4.398612
v  18.299879 4.831907 -5.070047
v  18.317486 6.338117 -4.412725
v  17.492025 4.790070 -4.750280
v  18.055317 5.584296 -5.062898
v  18.361488 5.240216 -4.801519
v  17.541130 5.202734 -5.080093
v  18.342480 2.978627 -4.987719
v  17.508947 4.491388 -5.119278
v  18.051659 4.832380 -4.485713
v  18.461525 6.245205 -4.529085
v  18.294193 5.645376 -4.922300
v  18.211372 3.590214 -4.444156
v  18.016953 4.096116 -4.406473
v  17.612459 3.312087 -4.540345
v  17.544170 4.415188 -4.463363
v  18.373114 3.812335 -5.020011
v  18.112349 4.073274 -4.503293
v  18.191692 5.273690 -5.038730
v  18.336197 6.174210 -4.274159
v  18.465921 3.986823 -4.859977
v  17.645512 3.458798 -4.491868
v  17.484905 4.015501 -4.582707
v  17.495670 3.576726 -4.641804
v  17.572422 3.736910 -4.505773
v  18.336414 2.995161 -4.664214
v  18.186657 5.643972 -5.018924
v  18.433596 4.824399 -4.891572
v  17.504032 5.312051 -4.956385
v  17.817291 3.035020 -5.200028
v  18.415892 3.740393 -4.576817
v  17.892235 5.324578 -4.461289
v  18.109877 5.902081 -5.019336
v  18.121071 3.559945 -4.398441
v  17.491737 5.393586 -4.747480
v  18.111113 4.417344 -4.419217
v  17.981493 3.718011 -4.382919
v  17.562019 3.772488 -5.164714
v  18.052494 3.355555 -5.239722
v  18.224844 3.918770 -5.166010
v  17.806715 3.808050 -4.359751
v  17.557196 3.247564 -4.609036
v  18.068546 6.377325 -4.499009
v  17.738277 4.475607 -4.403152
v  18.033182 5.407899 -4.322335
v  18.079889 2.967089 -4.585807
v  17.552059 5.083503 -4.547486
v  18.433790 5.985965 -4.252478
v  18.147226 5.477254 -4.422061
v  18.248510 5.268842 -4.591762
v  18.189396 6.351800 -4.827783
v  17.607670 4.254900 -4.431767
v  17.875553 3.550768 -5.266897
v  18.553747 6.001764 -4.463071
v  18.265270 5.962233 -4.973012
v  17.918461 3.978824 -5.299227
v  17.807207 5.319136 -4.379665
v  17.859474 5.810750 -4.293471
v  17.547041 5.449527 -4.996047
v  17.501087 4.941278 -4.802284
v  17.731634 5.075739 -4.428193
v  18.433746 4.576404 -4.705625
v  17.934204 5.307842 -5.106449
v  17.432659 5.155200 -4.719165
v  18.406790 3.506544 -4.693968
v  17.762341 5.018436 -4.397988
v  18.263720 5.353970 -4.683999
v  18.153366 5.664934 -4.287231
v  17.510363 4.748275 -4.496257
v  18.047531 4.260248 -5.221452
v  17.604065 5.488208 -4.428133
v  17.594959 4.845891 -5.189692
v  18.066002 3.536504 -5.192246
v  17.464758 4.638427 -5.034528
v  17.607620 5.458483 -4.960513
v  18.314289 3.347267 -4.553964
v  18.325434 4.673154 -4.541966
v  17.923565 5.042941 -5.184616
v  17.873425 3.266742 -4.474722
v  18.189342 4.899238 -4.464942
v  18.381948 5.119561 -4.695505
v  18.449381 6.076681 -4.274917
v  17.671873 5.337080 -5.071110
v  17.605104 3.133981 -5.129253
v  17.576538 4.103458 -5.196910
v  18.279148 3.330724 -5.171715
v  18.001068 5.276634 -5.106243
v  17.850811 4.526855 -4.487932
v  17.844584 5.928021 -4.272100
v  18.111883 4.997180 -5.144814
v  18.166510 6.407711 -4.516316
v  18.005789 3.441101 -4.524734
v  18.496347 6.160025 -4.663614
v  17.507332 3.671823 -5.090956
v  18.428778 5.984178 -4.809064
v  18.365019 5.068105 -4.618566
v  18.300531 3.438120 -5.141884
v  18.271519 6.181698 -4.895978
v  17.458393 4.466751 -4.706202
v  18.065371 6.199406 -4.251668
v  17.895975 4.540131 -5.274103
v  18.232267 5.117590 -4.546611
v  18.261215 5.306767 -5.005999
v  17.527081 5.138865 -5.093476
v  18.061100 5.773928 -4.267526
v  17.837994 5.512988 -4.261562
v  18.170732 3.750373 -4.365783
v  18.456596 4.278015 -4.807918
v  18.237383 5.411133 -4.898357
v  17.447063 3.935779 -4.705442
v  18.240723 6.063752 -4.253725
v  17.693102 3.338934 -4.499461
v  17.600756 3.150457 -4.615702
v  18.522467 6.015073 -4.644866
v  17.875185 5.380659 -5.101311
v  18.033625 5.544435 -5.035667
v  18.429335 4.887726 -4.794057
v  18.202047 4.016977 -4.452193
v  17.584616 5.371528 -5.014451
v  18.447994 4.183568 -4.910485
v  17.435778 4.684704 -4.904276
v  18.397636 3.293489 -5.011069
v  17.517408 4.200536 -4.599388
v  17.466282 5.299865 -4.692642
v  18.205217 3.533067 -4.467961
v  17.733963 5.423513 -4.351712
v  17.531317 3.322829 -5.044273
v  17.818289 5.661400 -5.040985
v  18.055737 3.947120 -4.385358
v  18.026251 6.190413 -4.994455
v  17.523516 3.163420 -4.704613
v  18.092791 5.483943 -5.041768
v  18.388756 3.099961 -4.871099
v  17.813526 5.139736 -5.143722
v  17.458839 5.226323 -4.963756
v  18.472321 6.281761 -4.555224
v  17.451199 4.046564 -4.779999
v  18.011023 4.929199 -4.377687
v  17.493553 5.348393 -4.570394
v  17.616642 3.991208 -4.464192
v  17.787352 4.913144 -5.227465
v  17.905745 5.771200 -5.087105
v  17.745327 4.550318 -4.409041
v  17.895685 3.463218 -4.441783
v  17.634718 3.736954 -4.489781
v  17.648165 5.447649 -5.042108
v  17.787079 5.224243 -5.091755
v  18.397997 5.058259 -4.932706
v  18.019485 4.610296 -4.481041
v  18.067247 4.744728 -5.215008
v  17.957001 5.367748 -5.076230
v  17.905645 5.366608 -4.389909
v  18.182966 4.920774 -4.407866
v  17.436359 4.294292 -4.638429
v  18.079212 4.516011 -5.218736
v  17.942047 3.692592 -5.199176
v  18.020931 3.746539 -4.362355
v  18.274471 5.678379 -4.202610
v  17.931240 4.615827 -5.261458
v  18.440960 3.639386 -4.866143
v  18.112202 6.057876 -5.033303
v  18.232191 3.487275 -4.510595
v  17.493444 5.143369 -4.571075
v  18.052620 3.274387 -4.540639
v  17.834015 5.422966 -4.300301
v  17.809080 5.396360 -5.113017
v  17.544245 3.507728 -4.604302
v  17.893072 5.538185 -5.065238
v  18.503548 6.096840 -4.480426
v  17.706982 4.907331 -4.388025
v  18.092337 5.264643 -4.393886
v  18.305157 6.116959 -4.910388
v  17.861460 5.421887 -5.064027
v  17.655313 5.041179 -4.480192
v  17.874762 5.085185 -4.396158
v  18.479013 3.718545 -4.682004
v  18.201899 5.163687 -5.066812
v  17.877691 3.619893 -4.405963
v  18.062935 4.651667 -4.388624
v  17.539457 3.825004 -4.543166
v  18.454180 4.479334 -4.693173
v  17.568949 3.396809 -5.134203
v  18.473658 6.217196 -4.442457
v  17.581291 4.570336 -5.185164
v  17.648001 5.265357 -4.440008
v  18.285961 5.248002 -5.015436
v  17.735519 5.406969 -5.064014
v  18.073963 5.721148 -5.076423
v  17.689800 5.787495 -4.317028
v  18.287720 6.048470 -4.222779
v  17.474630 3.745717 -4.708117
v  17.910961 5.878077 -4.257150
v  18.413645 4.382578 -5.000796
v  18.136906 5.405332 -4.433608
v  18.296637 6.274837 -4.321501
v  18.406998 3.653850 -5.002784
v  18.327389 4.108368 -5.088969
v  18.303366 3.184659 -4.586510
v  18.301329 6.344931 -4.753251
v  17.521624 4.974206 -4.604026
v  18.349045 5.297683 -4.787790
v  18.381590 3.153851 -4.763366
v  17.579748 5.157959 -5.119202
v  18.294897 5.346774 -4.827569
v  18.440371 5.888122 -4.789921
v  17.608963 5.261001 -5.076484
v  18.308584 5.594904 -4.879942
v  17.711372 3.153173 -5.185750
v  17.490133 4.309160 -5.133130
v  18.328815 4.831981 -4.532310
v  18.157061 3.158519 -5.197867
v  17.516855 4.658028 -5.122956
v  17.863781 4.890780 -4.377990
v  18.255629 6.425616 -4.616949
v  18.371029 4.394055 -4.603254
v  17.873432 6.012866 -4.268190
v  17.466307 4.088260 -5.028596
v  17.777168 5.497949 -5.066049
v  18.113766 4.060119 -5.226720
v  17.896593 3.830451 -4.354776
v  18.397295 6.352026 -4.607958
v  17.827362 3.983722 -5.289273
v  17.603285 3.396419 -4.517135
v  17.615110 4.486796 -4.423879
v  17.487288 5.262332 -4.834970
v  17.729826 5.040374 -4.409846
v  17.548168 5.262257 -4.523963
v  18.438995 3.327903 -4.867972
v  17.465229 3.928215 -4.640796
v  18.039238 3.703072 -4.390328
v  17.848719 5.237870 -4.474571
v  18.246061 4.448578 -5.145621
v  17.860067 5.271736 -5.106743
v  17.504829 3.232623 -4.836142
v  17.736399 5.744707 -4.245391
v  18.400602 4.093404 -4.649093
v  18.239134 5.002703 -4.535066
v  18.334463 4.493553 -4.545376
v  18.157911 3.560739 -4.402707
v  17.635771 5.428019 -4.423341
v  17.561352 3.584337 -4.560565
v  18.354750 5.812507 -4.242686
v  17.589474 5.452833 -4.836425
v  17.671471 5.054626 -5.167450
v  17.560749 4.653731 -4.466912
v  18.151953 6.334353 -4.327160
v  17.856565 4.377076 -5.257287
v  17.454006 4.777785 -5.028738
v  17.471603 4.680937 -4.561123
v  17.671873 5.725137 -4.281982
v  18.123600 6.173600 -4.964731
v  18.068129 4.343468 -4.413552
v  17.643543 4.864337 -4.408275
v  18.443375 5.006572 -4.808991
v  18.385391 3.993360 -5.026869
v  17.815216 4.292761 -5.269658
v  18.478252 4.083125 -4.860977
v  18.450480 4.576266 -4.878020
v  18.375593 5.991988 -4.888282
v  18.184162 4.537546 -4.432519
v  17.628292 4.086445 -4.475921
v  18.140009 3.790982 -4.346726
v  18.377104 4.200190 -4.593345
v  18.473780 4.377011 -4.867103
v  17.881655 3.489753 -5.267740
v  17.601059 3.222819 -4.544978
v  18.253212 3.965964 -4.501098
v  17.910000 3.014647 -4.582834
v  18.209309 3.756567 -4.434400
v  18.135576 5.685775 -5.026283
v  18.142086 5.546887 -4.393053
v  18.262138 5.743237 -4.960657
v  18.468639 4.670794 -4.831327
v  18.220764 5.658003 -4.983489
v  18.089218 5.564031 -4.318182
v  17.755865 5.040266 -5.206085
v  18.358458 6.094295 -4.210973
v  17.537962 5.417264 -4.628465
v  18.457510 6.240571 -4.697346
v  17.865612 3.880146 -4.516302
v  18.352972 5.245286 -4.750115
v  18.141014 5.632768 -4.297008
v  18.155636 4.663642 -4.393688
v  18.194664 5.019524 -4.435004
v  17.901999 5.160409 -5.152467
v  17.497095 4.347176 -4.522513
v  18.202387 3.781188 -4.514525
v  17.609644 5.170051 -4.494902
v  17.658993 3.808653 -4.427099
v  18.340744 5.739709 -4.234703
v  18.249634 5.224429 -4.611772
v  18.030319 5.497866 -5.063192
v  18.232193 5.860646 -5.006322
v  18.167278 5.484624 -4.394187
v  18.197557 3.670418 -4.408226
v  18.330954 3.609274 -5.119720
v  18.103119 3.765995 -5.257794
v  18.000996 5.125037 -5.138818
v  18.329489 3.108806 -4.633738
v  18.239061 3.307090 -4.518815
v  18.015472 5.374734 -5.056126
v  18.001875 5.285264 -4.373088
v  17.893978 3.782714 -5.243526
v  18.141825 4.522985 -4.511014
v  17.728537 3.141218 -4.505858
v  18.241550 5.767499 -4.247364
v  17.951471 3.613653 -5.192452
v  17.684336 4.556153 -4.399283
v  18.209461 6.165274 -4.923959
v  18.420822 3.571597 -4.697332
v  17.919666 5.176258 -4.407446
v  18.226572 6.193907 -4.270748
v  17.821667 3.290113 -4.456116
v  18.437389 5.008893 -4.908653
v  17.474970 5.354008 -4.625060
v  17.704224 5.513120 -4.344079
v  17.971716 5.973783 -4.233535
v  18.155804 5.330390 -5.031712
v  18.276688 5.823699 -4.919229
v  18.532648 6.041579 -4.419055
v  17.492449 5.388208 -4.956991
v  17.982557 5.702782 -4.268378
v  18.032490 4.953595 -4.494395
v  18.199280 5.918177 -4.994691
v  18.104433 3.487543 -4.449811
v  17.964502 3.869442 -4.334953
v  17.467382 3.662052 -4.910531
v  18.198378 3.527806 -5.193322
v  18.261955 4.978726 -5.098327
v  17.640339 5.377567 -5.025477
v  17.463646 5.364991 -4.757848
v  17.650835 5.642258 -4.301872
v  17.976084 4.175985 -5.264338
v  18.077511 6.343253 -4.443783
v  18.488174 5.979700 -4.282085
v  17.700739 5.414955 -5.034869
v  17.815510 3.730673 -4.378562
v  18.426262 5.059515 -4.814750
v  17.470076 4.772151 -4.612597
v  18.312969 5.309373 -4.913671
v  18.067825 5.221193 -5.117186
v  18.000990 4.765822 -4.485637
v  17.541515 5.028167 -4.542201
v  18.235922 2.965142 -4.598750
v  18.155790 5.868049 -4.277606
v  18.380766 3.323437 -4.699683
v  17.444584 4.470770 -5.043478
v  17.735001 3.987134 -5.241719
v  18.487391 6.078733 -4.696227
v  17.580685 3.131042 -5.098183
v  17.532055 4.275023 -4.545537
v  18.170300 6.377726 -4.799227
v  18.201408 4.834984 -4.456225
v  17.757149 3.693246 -5.250945
v  17.837860 5.623534 -4.268005
v  17.489380 4.107630 -4.585006
v  17.687580 4.777861 -4.386556
v  17.931469 4.427835 -4.372647
v  18.534595 5.914169 -4.509917
v  17.478024 3.578261 -4.768908
v  17.632366 4.399549 -5.179233
v  18.266857 6.136236 -4.241758
v  17.827396 4.619489 -4.371922
v  17.760197 5.129385 -4.431634
v  18.290144 5.313560 -4.741329
v  17.431377 4.153747 -4.845825
v  18.017900 6.012079 -5.066721
v  18.178532 3.261376 -4.521214
v  18.155787 3.688872 -4.360765
v  17.993553 5.499666 -4.321609
v  18.318521 5.149804 -4.842278
v  17.499388 4.895724 -5.007553
v  18.555296 5.943361 -4.395870
v  17.890089 3.238368 -5.247964
v  18.242115 6.404022 -4.542711
v  18.300232 5.314066 -4.687614
v  18.367632 3.458713 -4.562168
v  17.716761 5.333160 -4.380937
v  18.194206 3.973381 -4.489717
v  17.947527 5.007096 -5.193332
v  18.140190 3.954737 -5.212056
v  17.981207 5.427254 -5.051834
v  18.075689 5.111246 -4.419297
v  18.183599 5.691328 -4.280302
v  17.542795 4.103633 -5.158387
v  17.463783 4.604802 -4.627284
v  17.456566 3.860652 -4.979383
v  17.815119 4.803143 -4.384990
v  18.336475 5.707616 -4.901257
v  18.340279 5.887405 -4.217347
v  18.230333 4.855124 -5.119610
v  18.325102 3.019417 -5.080384
v  18.346504 6.349920 -4.504702
v  18.002455 5.230208 -5.126806
v  18.421227 6.159463 -4.277782
v  18.090628 3.697429 -4.505992
v  16.266632 5.946391 -3.691504
v  16.265659 5.942110 -3.692454
v  16.263803 5.938554 -3.694464
v  16.261286 5.936151 -3.697289
v  16.258413 5.935193 -3.700590
v  16.255529 5.935791 -3.703970
v  16.252983 5.937878 -3.707020
v  16.251081 5.941200 -3.709372
v  16.250055 5.945354 -3.710741
v  16.250027 5.949843 -3.710965
v  16.250999 5.954124 -3.710016
v  16.252855 5.957681 -3.708007
v  16.255373 5.960083 -3.705181
v  16.258245 5.961042 -3.701880
v  16.261129 5.960443 -3.698500
v  16.263676 5.958356 -3.695450
v  16.261110 5.950773 -3.698112
v  16.307068 5.931240 -3.738029
v  16.266603 5.950880 -3.691729
v  16.265575 5.955036 -3.693098
v  16.312340 5.930280 -3.728101
v  16.310675 5.926683 -3.726584
v  16.308250 5.923169 -3.726428
v  16.305361 5.920158 -3.727650
v  16.302353 5.918015 -3.730103
v  16.299593 5.917001 -3.733491
v  16.297409 5.917233 -3.737406
v  16.296068 5.918688 -3.741375
v  16.295731 5.921187 -3.744919
v  16.296436 5.924431 -3.747612
v  16.298103 5.928028 -3.749126
v  16.300526 5.931542 -3.749284
v  16.303417 5.934553 -3.748062
v  16.306423 5.936695 -3.745608
v  16.309185 5.937711 -3.742221
v  16.311369 5.937477 -3.738306
v  16.312710 5.936024 -3.734337
v  16.313047 5.933524 -3.730793
v  16.259563 5.931025 -3.701455
v  16.226133 5.929926 -3.647367
v  16.226517 5.926392 -3.649870
v  16.225670 5.923399 -3.653038
v  16.223696 5.921313 -3.656490
v  16.220833 5.920382 -3.659810
v  16.217426 5.920720 -3.662598
v  16.213884 5.922285 -3.664515
v  16.210638 5.924889 -3.665334
v  16.208076 5.928217 -3.664953
v  16.206511 5.931870 -3.663420
v  16.206127 5.935404 -3.660917
v  16.206972 5.938396 -3.657749
v  16.208946 5.940483 -3.654297
v  16.211809 5.941413 -3.650978
v  16.215218 5.941076 -3.648190
v  16.218758 5.939512 -3.646272
v  16.219486 5.931927 -3.651725
v  16.253546 5.947125 -3.703344
v  16.224566 5.933578 -3.645834
v  16.222004 5.936908 -3.645453
v  16.265005 5.950949 -3.690199
v  16.265074 5.946457 -3.690012
v  16.264133 5.942175 -3.690994
v  16.262302 5.938619 -3.693026
v  16.259796 5.936214 -3.695863
v  16.256920 5.935256 -3.699162
v  16.254021 5.935855 -3.702526
v  16.251448 5.937943 -3.705549
v  16.249510 5.941267 -3.707867
v  16.248444 5.945425 -3.709198
v  16.248375 5.949914 -3.709385
v  16.249313 5.954196 -3.708403
v  16.251146 5.957755 -3.706371
v  16.253653 5.960157 -3.703534
v  16.256529 5.961117 -3.700235
v  16.259428 5.960516 -3.696871
v  16.262001 5.958428 -3.693848
v  16.263939 5.955106 -3.691530
v  16.252024 5.931973 -3.702321
v  16.225687 5.872032 -3.619357
v  16.226116 5.871783 -3.623462
v  16.225304 5.870858 -3.627641
v  16.223349 5.869368 -3.631392
v  16.220486 5.867494 -3.634259
v  16.217060 5.865459 -3.635900
v  16.213484 5.863513 -3.636116
v  16.210190 5.861887 -3.634879
v  16.207577 5.860780 -3.632340
v  16.205957 5.860322 -3.628805
v  16.205526 5.860570 -3.624701
v  16.206339 5.861496 -3.620522
v  16.208296 5.862986 -3.616772
v  16.211159 5.864861 -3.613903
v  16.214584 5.866895 -3.612263
v  16.218161 5.868841 -3.612047
v  16.219772 5.868328 -3.623844
v  16.219246 5.927689 -3.651881
v  16.224068 5.871573 -3.615822
v  16.221455 5.870467 -3.613284
v  16.223564 5.932077 -3.644423
v  16.225191 5.928516 -3.646042
v  16.225624 5.925054 -3.648611
v  16.224806 5.922108 -3.651821
v  16.222839 5.920032 -3.655284
v  16.219961 5.919076 -3.658583
v  16.216516 5.919360 -3.661318
v  16.212923 5.920845 -3.663160
v  16.209612 5.923354 -3.663889
v  16.206985 5.926583 -3.663414
v  16.205357 5.930144 -3.661795
v  16.204926 5.933607 -3.659225
v  16.205742 5.936553 -3.656016
v  16.207708 5.938630 -3.652553
v  16.210587 5.939584 -3.649254
v  16.214031 5.939301 -3.646519
v  16.217625 5.937816 -3.644676
v  16.220936 5.935308 -3.643948
v  16.263924 5.813565 -3.621666
v  16.263086 5.815193 -3.625643
v  16.261354 5.815627 -3.629730
v  16.258942 5.814815 -3.633433
v  16.256136 5.812855 -3.636308
v  16.253277 5.809982 -3.638005
v  16.250708 5.806544 -3.638321
v  16.248741 5.802955 -3.637218
v  16.247612 5.799648 -3.634828
v  16.247458 5.797021 -3.631440
v  16.248297 5.795393 -3.627463
v  16.250027 5.794959 -3.623376
v  16.252441 5.795772 -3.619673
v  16.255247 5.797731 -3.616799
v  16.258106 5.800603 -3.615101
v  16.260675 5.804043 -3.614785
v  16.258530 5.809092 -3.626204
v  16.263771 5.810939 -3.618279
v  16.262642 5.807631 -3.615889
v  16.225103 5.869286 -3.615425
v  16.226667 5.869869 -3.618981
v  16.227051 5.869725 -3.623102
v  16.226210 5.868866 -3.627294
v  16.224245 5.867402 -3.631048
v  16.221392 5.865506 -3.633914
v  16.217997 5.863406 -3.635546
v  16.214468 5.861357 -3.635744
v  16.211231 5.859605 -3.634488
v  16.208677 5.858362 -3.631927
v  16.207113 5.857778 -3.628372
v  16.206728 5.857923 -3.624251
v  16.207569 5.858779 -3.620059
v  16.209536 5.860246 -3.616305
v  16.212387 5.862142 -3.613439
v  16.215784 5.864242 -3.611808
v  16.219313 5.866291 -3.611609
v  16.222549 5.868042 -3.612864
v  16.309710 5.791363 -3.656414
v  16.307222 5.793787 -3.659248
v  16.304308 5.794773 -3.662499
v  16.301317 5.794200 -3.665780
v  16.298611 5.792138 -3.668689
v  16.296516 5.788834 -3.670877
v  16.295286 5.784688 -3.672082
v  16.295069 5.780200 -3.672156
v  16.295889 5.775910 -3.671092
v  16.297649 5.772337 -3.669018
v  16.300137 5.769913 -3.666183
v  16.303051 5.768927 -3.662931
v  16.306042 5.769500 -3.659652
v  16.308746 5.771562 -3.656743
v  16.310841 5.774866 -3.654554
v  16.312071 5.779012 -3.653350
v  16.300690 5.779571 -3.665931
v  16.254238 5.801547 -3.630455
v  16.311470 5.787788 -3.654339
v  16.312290 5.783501 -3.653275
v  16.265408 5.809369 -3.618999
v  16.265491 5.812062 -3.622357
v  16.264591 5.813747 -3.626307
v  16.262817 5.814225 -3.630374
v  16.260382 5.813434 -3.634068
v  16.257578 5.811471 -3.636943
v  16.254745 5.808573 -3.638653
v  16.252226 5.805089 -3.638991
v  16.250322 5.801440 -3.637914
v  16.249264 5.798065 -3.635556
v  16.249180 5.795371 -3.632198
v  16.250078 5.793684 -3.628249
v  16.251854 5.793208 -3.624181
v  16.254290 5.793999 -3.620487
v  16.257093 5.795961 -3.617611
v  16.259926 5.798860 -3.615903
v  16.262445 5.802344 -3.615565
v  16.264351 5.805993 -3.616641
v  16.295940 5.795465 -3.669495
v  16.345428 5.809294 -3.703407
v  16.341785 5.811019 -3.704662
v  16.338089 5.811532 -3.706845
v  16.334784 5.810771 -3.709693
v  16.332268 5.808824 -3.712862
v  16.330845 5.805929 -3.715971
v  16.330687 5.802433 -3.718643
v  16.331814 5.798759 -3.720557
v  16.334087 5.795351 -3.721482
v  16.337236 5.792619 -3.721307
v  16.340878 5.790892 -3.720053
v  16.344576 5.790379 -3.717869
v  16.347881 5.791141 -3.715021
v  16.350397 5.793087 -3.711852
v  16.351820 5.795984 -3.708744
v  16.351976 5.799479 -3.706071
v  16.343845 5.797190 -3.713380
v  16.303228 5.785743 -3.666556
v  16.348576 5.806560 -3.703232
v  16.350851 5.803153 -3.704157
v  16.313004 5.787735 -3.655930
v  16.311197 5.791308 -3.657956
v  16.308670 5.793735 -3.660749
v  16.305729 5.794722 -3.663975
v  16.302725 5.794148 -3.667239
v  16.300022 5.792085 -3.670152
v  16.297949 5.788781 -3.672360
v  16.296751 5.784633 -3.673599
v  16.296577 5.780143 -3.673717
v  16.297445 5.775853 -3.672702
v  16.299252 5.772278 -3.670675
v  16.301779 5.769852 -3.667881
v  16.304722 5.768865 -3.664658
v  16.307726 5.769439 -3.661392
v  16.310427 5.771501 -3.658480
v  16.312502 5.774806 -3.656271
v  16.313698 5.778954 -3.655033
v  16.313873 5.783443 -3.654914
v  16.299425 5.795333 -3.673107
v  16.344501 5.868943 -3.733913
v  16.340837 5.867560 -3.733519
v  16.337122 5.865969 -3.734581
v  16.333805 5.864358 -3.736974
v  16.331285 5.862925 -3.740408
v  16.329868 5.861840 -3.744469
v  16.329723 5.861234 -3.748667
v  16.330870 5.861181 -3.752496
v  16.333166 5.861688 -3.755493
v  16.336340 5.862693 -3.757299
v  16.340004 5.864075 -3.757694
v  16.343719 5.865667 -3.756632
v  16.347036 5.867277 -3.754239
v  16.349556 5.868711 -3.750805
v  16.350973 5.869797 -3.746744
v  16.351116 5.870403 -3.742546
v  16.337637 5.864233 -3.749401
v  16.339365 5.800372 -3.717529
v  16.347673 5.869947 -3.735720
v  16.349972 5.870455 -3.738718
v  16.349363 5.808076 -3.704849
v  16.346163 5.810709 -3.704917
v  16.342478 5.812352 -3.706082
v  16.338751 5.812808 -3.708204
v  16.335434 5.812021 -3.711026
v  16.332924 5.810088 -3.714209
v  16.331526 5.807240 -3.717368
v  16.331408 5.803822 -3.720122
v  16.332584 5.800245 -3.722139
v  16.334913 5.796942 -3.723177
v  16.338114 5.794309 -3.723108
v  16.341799 5.792665 -3.721944
v  16.345526 5.792210 -3.719822
v  16.348843 5.792996 -3.716999
v  16.351353 5.794930 -3.713817
v  16.352751 5.797778 -3.710658
v  16.352869 5.801196 -3.707904
v  16.351692 5.804773 -3.705886
v  16.310413 5.926949 -3.726435
v  16.308001 5.923423 -3.726285
v  16.305119 5.920403 -3.727512
v  16.302114 5.918259 -3.729967
v  16.299353 5.917243 -3.733355
v  16.297165 5.917481 -3.737267
v  16.295815 5.918945 -3.741230
v  16.295465 5.921455 -3.744768
v  16.296160 5.924713 -3.747453
v  16.297813 5.928320 -3.748961
v  16.300226 5.931847 -3.749112
v  16.303108 5.934865 -3.747886
v  16.306110 5.937012 -3.745430
v  16.308874 5.938026 -3.742043
v  16.311062 5.937788 -3.738131
v  16.312412 5.936325 -3.734167
v  16.305605 5.926019 -3.733318
v  16.336803 5.866712 -3.749804
v  16.312067 5.930558 -3.727943
v  16.312759 5.933814 -3.730629
v  16.346874 5.872332 -3.736108
v  16.343742 5.871207 -3.734282
v  16.340111 5.869726 -3.733871
v  16.336416 5.868071 -3.734924
v  16.333107 5.866438 -3.737312
v  16.330580 5.865028 -3.740750
v  16.329140 5.864008 -3.744822
v  16.328960 5.863503 -3.749037
v  16.330067 5.863572 -3.752885
v  16.332321 5.864208 -3.755903
v  16.335453 5.865333 -3.757729
v  16.339085 5.866813 -3.758141
v  16.342777 5.868468 -3.757088
v  16.346088 5.870100 -3.754699
v  16.348616 5.871511 -3.751261
v  16.350054 5.872530 -3.747190
v  16.350233 5.873036 -3.742975
v  16.349127 5.872968 -3.739127
v  16.294250 5.915096 -3.742952
v  16.219078 5.861744 -3.639069
v  16.347952 5.814408 -3.703147
v  16.221346 5.869334 -3.637759
v  16.215961 5.862876 -3.628307
v  16.204956 5.853887 -3.629558
v  16.246021 5.939101 -3.709293
v  16.316111 5.772580 -3.655088
v  16.335718 5.859605 -3.760622
v  16.344454 5.873843 -3.730813
v  16.269152 5.957148 -3.691734
v  16.313778 5.784098 -3.649018
v  16.212042 5.864285 -3.640237
v  16.262371 5.961704 -3.690737
v  16.306948 5.798807 -3.664982
v  16.355173 5.869779 -3.741198
v  16.250004 5.955945 -3.714059
v  16.297714 5.766210 -3.665960
v  16.248043 5.960815 -3.707195
v  16.305878 5.764754 -3.666143
v  16.252460 5.934558 -3.709965
v  16.291508 5.785660 -3.673909
v  16.317837 5.783955 -3.653221
v  16.294840 5.922551 -3.751225
v  16.255121 5.965244 -3.699106
v  16.248686 5.944400 -3.714943
v  16.292210 5.774106 -3.672514
v  16.265350 5.817358 -3.619620
v  16.205631 5.855046 -3.618586
v  16.263363 5.964263 -3.698910
v  16.311792 5.772732 -3.650625
v  16.328890 5.860449 -3.738476
v  16.266672 5.935451 -3.693395
v  16.303432 5.798935 -3.661335
v  16.221346 5.869334 -3.637759
v  16.212042 5.864285 -3.640237
v  16.212042 5.864285 -3.640237
v  16.221346 5.869334 -3.637759
v  16.210190 5.861887 -3.634879
v  16.209612 5.923354 -3.663889
v  -2.181571 5.208451 14.348714
v  -1.673053 2.907239 14.280515
v  -3.190738 2.808046 15.645770
v  -2.725377 2.860688 13.510396
v  -2.167926 4.596640 13.929732
v  -2.701709 6.028813 14.332870
v  -2.494527 5.626507 15.136104
v  -2.094676 4.017738 14.278562
v  -2.994205 4.944970 15.301639
v  -1.762324 6.016380 14.373689
v  -2.231633 2.849726 13.680257
v  -2.940804 3.309850 13.921638
v  -2.785699 2.779931 15.559154
v  -2.430358 4.775807 14.972371
v  -1.750529 5.194884 14.111110
v  -2.257880 3.193653 14.824864
v  -2.790063 5.157433 13.960010
v  -3.212813 2.823549 14.816922
v  -3.014060 3.966228 15.289918
v  -1.992854 2.875029 15.007226
v  -1.877841 6.589591 14.174338
v  -2.487359 3.593928 15.273419
v  -3.079848 2.871948 13.621768
v  -2.237538 5.328953 13.866080
v  -1.857982 2.862229 13.991607
v  -2.075107 3.200863 14.394431
v  -2.571216 5.685988 13.710303
v  -2.486693 4.910778 13.829745
v  -2.487985 2.913321 15.478560
v  -3.043149 4.278691 14.157247
v  -2.104228 4.784521 14.320138
v  -2.178159 5.961092 14.977601
v  -2.150720 4.051626 14.573058
v  -3.007550 3.724228 14.562615
v  -2.776603 4.535531 15.403630
v  -1.774447 6.237853 14.158946
v  -3.318381 2.795319 15.414180
v  -2.405820 3.389290 13.492064
v  -2.263413 4.905646 14.564260
v  -2.097980 3.985103 13.787354
v  -3.195951 2.804460 14.432923
v  -1.907863 3.556978 14.234635
v  -2.806321 5.750579 15.192575
v  -2.055179 2.794855 13.863082
v  -2.333367 3.590836 14.895825
v  -2.513434 6.262253 14.448644
v  -3.058213 4.934947 15.002139
v  -2.799642 3.596999 15.470759
v  -3.087190 2.715528 15.452603
v  -2.146548 5.402043 14.012372
v  -2.341405 5.091365 14.473472
v  -2.369143 4.219871 13.788377
v  -1.937167 6.650772 14.440375
v  -1.939986 4.663512 14.145091
v  -2.359712 4.283282 14.974771
v  -3.094300 2.846472 13.891194
v  -2.420848 3.200945 14.952424
v  -2.321252 2.878759 13.470925
v  -1.880996 2.803310 14.691535
v  -3.225488 3.186639 14.059251
v  -2.886401 5.426293 13.875371
v  -2.515880 2.989991 15.341432
v  -2.799773 4.325844 13.815661
v  -2.182401 3.959510 14.406167
v  -2.812209 5.620591 14.179025
v  -3.251534 2.754650 15.118535
v  -1.705676 6.326033 14.410295
v  -2.947968 5.582118 15.146127
v  -2.087966 3.182962 13.699758
v  -2.034968 3.779499 13.910691
v  -3.011479 3.690582 15.105449
v  -2.983590 4.549522 14.765952
v  -2.146741 5.005865 14.837464
v  -3.138236 2.756301 15.590238
v  -2.284497 4.541665 14.688933
v  -2.612239 5.133342 15.202493
v  -1.929973 5.191263 14.354550
v  -2.875091 3.042435 13.504138
v  -3.266448 2.999434 14.499417
v  -2.206101 3.136060 14.639195
v  -2.282081 5.883770 13.785691
v  -2.664588 5.992964 14.738700
v  -2.055675 4.167297 14.248004
v  -2.206414 6.262177 14.022267
v  -2.070003 6.302347 14.663224
v  -1.744426 2.837248 14.137469
v  -2.875552 2.875196 13.966727
v  -2.563666 4.175440 13.770638
v  -2.351960 3.636165 15.058261
v  -1.955413 5.731789 14.600202
v  -2.007125 3.019918 14.441869
v  -2.861400 3.079397 15.558280
v  -1.994117 3.953937 14.125392
v  -2.133300 5.361479 14.129527
v  -2.377994 5.189987 14.715730
v  -3.100560 3.303051 14.768186
v  -2.174460 2.973827 15.012101
v  -2.906266 5.226534 15.258971
v  -2.987781 3.217263 13.831283
v  -3.064538 4.275212 15.227199
v  -2.552251 3.971564 15.281674
v  -2.853017 4.137016 15.379619
v  -3.038104 4.041733 14.741530
v  -2.321158 6.355592 14.381267
v  -2.253776 4.557750 14.489062
v  -2.854028 5.610975 14.661010
v  -2.090594 5.160439 14.300469
v  -1.861224 6.363689 14.108836
v  -2.252952 3.528127 13.697341
v  -3.108176 2.990770 15.266017
v  -2.417706 6.039132 13.821294
v  -2.030192 3.607226 14.332110
v  -2.051482 4.494349 14.041234
v  -3.086202 2.882052 15.613700
v  -3.147565 3.034621 15.505399
v  -2.297816 4.923361 13.870608
v  -2.121112 4.822889 13.959371
v  -2.161382 3.335403 14.441478
v  -2.149978 2.958562 14.740701
v  -1.782695 2.844280 14.457969
v  -2.310525 4.000241 14.713700
v  -2.850883 3.959020 13.800696
v  -2.401380 5.252914 15.153188
v  -1.855536 3.353997 14.312058
v  -3.022300 3.754359 14.064271
v  -2.108554 5.011924 14.285188
v  -2.649320 5.701341 15.206426
v  -2.468167 3.034855 15.151212
v  -3.216517 2.918853 14.363564
v  -2.479535 4.426937 15.044240
v  -2.994069 2.712923 15.521304
v  -2.488845 2.967819 13.400775
v  -3.099290 3.661097 15.307504
v  -1.987807 2.981387 13.948768
v  -2.007121 5.316672 14.113622
v  -2.402678 5.387620 15.008464
v  -2.834264 4.827572 13.908237
v  -2.866313 5.620073 13.914647
v  -2.350848 4.508459 13.831153
v  -2.113055 5.721601 13.952985
v  -2.148337 5.841772 14.934648
v  -2.453107 6.201438 14.679112
v  -1.744418 6.430347 14.202909
v  -3.101874 2.875822 13.815143
v  -1.946040 5.770351 14.236043
v  -2.163056 2.829673 13.731435
v  -2.893077 5.775575 15.038787
v  -2.428805 4.660463 14.790568
v  -2.605201 3.203704 13.549947
v  -2.288678 4.834029 14.709357
v  -2.394423 5.411775 13.768601
v  -1.802730 2.854641 14.041543
v  -1.850883 2.976276 14.399762
v  -1.954303 4.827778 14.265212
v  -1.816157 6.079930 14.554830
v  -3.036796 4.091450 14.336293
v  -1.823741 5.245497 14.191825
v  -3.001914 5.445467 15.037545
v  -1.804309 6.634643 14.374202
v  -2.533154 3.301140 15.334340
v  -2.142575 4.355356 13.885852
v  -2.295964 2.999729 13.510665
v  -2.086925 5.458370 14.464776
v  -2.126887 2.890798 15.201447
v  -3.005197 4.708578 15.113690
v  -2.391751 6.111451 14.872176
v  -2.451589 3.236496 15.026937
v  -2.118381 5.485016 14.628644
v  -2.095515 5.663071 14.044570
v  -1.761744 3.089900 14.254464
v  -3.249312 2.834236 14.676396
v  -1.690196 2.905450 14.317992
v  -2.979653 3.115257 13.584542
v  -2.162635 3.761735 14.425097
v  -2.057185 5.240539 14.340629
v  -3.255670 2.882238 15.475904
v  -3.015117 4.868725 14.749417
v  -2.171027 5.289672 14.309319
v  -2.210895 3.444612 14.756459
v  -3.261693 2.978033 13.995120
v  -3.031127 3.980334 15.206492
v  -2.964414 3.499835 13.954682
v  -2.240672 6.361373 14.533553
v  -3.155784 2.945801 14.785571
v  -2.173356 4.301369 14.359117
v  -1.935336 5.973868 14.117663
v  -2.045108 2.885745 14.673213
v  -2.236577 5.163920 14.756545
v  -2.309535 2.909858 15.156102
v  -3.248744 2.767065 14.188968
v  -2.661408 5.118292 13.852538
v  -1.822383 4.976857 14.222301
v  -2.808291 5.592282 15.242934
v  -2.015807 4.310494 14.158732
v  -2.030887 6.089058 14.710168
v  -2.027453 6.596465 14.174418
v  -2.292888 5.290359 14.567309
v  -2.710063 4.343702 15.398305
v  -2.355557 5.158518 13.794707
v  -2.345353 3.989604 14.930181
v  -2.998895 4.413358 14.511651
v  -2.662000 6.019633 14.077699
v  -2.693824 5.606802 13.727820
v  -2.252307 4.568998 14.609582
v  -2.831538 4.544456 13.901462
v  -2.186717 4.104847 13.783288
v  -3.187150 2.728607 15.260806
v  -2.426276 3.733602 15.189168
v  -2.195823 4.359685 13.858260
v  -2.966315 2.906862 15.584435
v  -2.086307 5.391875 14.054738
v  -2.055148 3.667888 13.784381
v  -2.594752 4.051857 15.423753
v  -2.218696 4.900167 14.467916
v  -2.993892 3.284633 13.770728
v  -2.428914 6.296575 14.230119
v  -2.237697 3.046908 13.684850
v  -2.621036 2.952931 15.412223
v  -2.172610 3.880342 14.563809
v  -2.047877 3.819273 14.285121
v  -2.983392 4.865701 14.172183
v  -2.348266 3.885279 13.690085
v  -2.271401 4.361924 14.715703
v  -2.256257 2.871050 13.531529
v  -1.883464 5.222793 14.031117
v  -2.885196 2.846271 14.050662
v  -2.758077 3.222136 15.495322
v  -2.400920 3.450533 15.103919
v  -2.775102 3.240204 13.534030
v  -3.232171 2.817776 13.920312
v  -2.644744 4.531165 13.851415
v  -2.107203 5.143676 14.362271
v  -3.018367 3.672155 14.440551
v  -2.152946 3.901096 14.362092
v  -1.920737 5.708557 14.384076
v  -3.052938 4.282196 15.280145
v  -2.884917 4.879989 14.031754
v  -2.715329 3.760507 15.445558
v  -2.964124 5.095073 14.271258
v  -3.052415 3.888218 14.179734
v  -2.061831 3.034005 13.749537
v  -2.310739 3.219978 14.878301
v  -2.855403 2.763160 15.544875
v  -2.343917 5.081780 14.601102
v  -1.883098 2.853400 14.578753
v  -2.037143 5.118536 13.917472
v  -2.118182 6.607282 14.327132
v  -2.864289 5.332712 13.932240
v  -2.809106 3.975446 15.438365
v  -3.135797 3.044241 13.902415
v  -3.092996 3.610155 14.190402
v  -2.355556 3.836651 14.801604
v  -2.037215 4.315285 14.238362
v  -3.142207 2.836656 15.647086
v  -2.770139 4.056061 13.768889
v  -2.235291 5.206550 13.861920
v  -1.996928 5.172732 14.171390
v  -2.178323 5.946587 13.919902
v  -2.162160 4.115668 14.422387
v  -2.080905 6.463207 14.569505
v  -1.838176 3.061448 14.360255
v  -1.861079 4.928277 14.070199
v  -2.407985 5.984756 14.983655
v  -1.849139 2.795047 14.836759
v  -2.579883 3.561246 13.604596
v  -2.406156 2.989639 15.082841
v  -3.044511 3.974632 14.811431
v  -2.271264 3.993100 13.783517
v  -2.323062 5.722074 15.038863
v  -2.131856 4.587464 13.986300
v  -2.122458 5.328212 14.336806
v  -1.963428 6.643931 14.209680
v  -2.206651 3.491944 14.536181
v  -2.407920 2.974627 13.412534
v  -2.215053 3.895966 14.670667
v  -3.138055 2.795502 15.630736
v  -1.903007 5.299134 14.113883
v  -2.418342 4.996451 13.820783
v  -2.774730 4.710734 15.378413
v  -2.342816 5.051610 14.509774
v  -2.900769 5.420276 14.568650
v  -2.082179 4.097926 13.962771
v  -3.069379 2.770027 15.489991
v  -1.791717 5.153004 14.232511
v  -2.937720 3.504858 13.909484
v  -2.260852 5.972251 15.007106
v  -2.115043 2.981368 13.713317
v  -3.010219 3.231009 15.551843
v  -3.103478 3.229026 15.454219
v  -2.087039 5.343579 13.999812
v  -2.157006 3.160467 14.504243
v  -2.664527 4.633140 15.244512
v  -3.252021 2.867461 15.570540
v  -1.911777 5.758201 14.530164
v  -2.488229 2.824973 13.407069
v  -2.284965 5.025868 15.000766
v  -2.175305 5.073039 14.246602
v  -3.067584 3.539248 15.184926
v  -2.800336 5.278849 13.820956
v  -2.566747 2.941723 15.416430
v  -3.031922 3.607370 14.556891
v  -2.201791 4.344241 14.623408
v  -2.430718 4.554797 14.826373
v  -2.992368 4.696971 14.406077
v  -2.233762 4.404310 14.493495
v  -2.801097 3.414668 15.483119
v  -2.061908 5.563844 14.106341
v  -2.491755 4.601303 14.918078
v  -2.216357 5.195731 14.368384
v  -2.361043 3.446046 14.938096
v  -2.502801 3.478512 15.280389
v  -2.738162 5.928508 14.584865
v  -2.112967 4.313508 14.289119
v  -3.283963 2.892062 14.566333
v  -1.683303 2.908217 14.253779
v  -2.111949 2.840595 13.765765
v  -2.403213 5.335364 14.876035
v  -3.043351 3.635770 14.980519
v  -2.122031 5.371446 14.233043
v  -2.944661 3.079664 15.569381
v  -2.339421 5.485945 13.831008
v  -2.825061 5.621063 13.820828
v  -2.270482 3.601600 14.815402
v  -1.924640 6.593740 14.485198
v  -1.983974 3.497918 13.956756
v  -2.271028 6.353820 14.156325
v  -2.540965 4.336440 13.785081
v  -2.888314 4.255742 13.941913
v  -2.147773 3.443916 13.701217
v  -2.235723 4.992721 14.669467
v  -2.740539 5.871842 15.117766
v  -2.020232 5.180739 14.310340
v  -2.922925 3.469009 13.705432
v  -3.299909 2.796793 15.493747
v  -3.006856 4.982816 14.726684
v  -2.064540 3.306892 14.367486
v  -3.042338 3.666117 15.383437
v  -2.269256 3.260556 13.632918
v  -2.797530 5.148968 15.244527
v  -3.035022 4.696069 14.975469
v  -3.129745 2.869293 13.695685
v  -2.838340 5.388321 14.093427
v  -2.226626 4.890128 14.820359
v  -3.138244 2.914369 15.301261
v  -2.428851 2.916589 15.356023
v  -2.560481 5.938257 13.869773
v  -3.251937 2.810629 14.925773
v  -2.093715 2.914558 14.944629
v  -2.261561 5.226821 14.946942
v  -2.265233 6.038469 13.839042
v  -2.860274 2.853975 13.524323
v  -2.148698 2.980936 14.652970
v  -2.303742 5.297567 14.672105
v  -2.942401 3.862425 13.986747
v  -3.212388 2.968015 15.017306
v  -2.633466 3.423295 15.372855
v  -3.091233 3.504652 14.055037
v  -3.028045 4.349193 14.941073
v  -2.597186 5.361833 15.236861
v  -1.975329 3.811107 14.083371
v  -3.232326 2.998757 13.936543
v  -2.312984 3.108116 14.955094
v  -2.824546 5.379234 15.239383
v  -1.713576 6.275731 14.275859
v  -2.802371 5.231659 14.042037
v  -2.431275 4.156137 13.749627
v  -2.705182 3.863115 15.465714
v  -1.816897 3.019628 14.059640
v  -2.610299 3.012226 13.486011
v  -2.236415 5.704112 13.865401
v  -2.336610 4.136053 14.889348
v  -2.864871 3.616090 15.474549
v  -2.145139 5.002632 13.936929
v  -2.713674 5.365050 13.731622
v  -2.299026 3.180932 13.524011
v  -2.140174 5.321839 14.385224
v  -2.787271 2.796787 15.523564
v  -1.972140 5.697982 14.280503
v  -1.981349 3.681556 13.992742
v  -2.348126 3.418593 13.526069
v  -3.018167 4.964787 15.263705
v  -2.998107 4.591934 14.200660
v  -2.264966 4.307121 13.824784
v  -2.380061 4.751662 13.845693
v  -3.024133 4.220340 15.309350
v  -2.032123 4.375588 14.085538
v  -1.881198 6.070241 14.603055
v  -2.214570 4.526617 14.438375
v  -2.163161 4.752352 14.416774
v  -2.031322 3.516733 13.858232
v  -2.894465 3.053924 13.970160
v  -1.769938 3.017667 14.168935
v  -2.352712 5.206758 14.645825
v  -2.963523 5.185083 15.252358
v  -2.019542 2.849790 14.746549
v  -1.809039 5.165993 14.069777
v  -2.990672 3.916465 15.015111
v  -3.140925 2.885841 15.613704
v  -2.220202 6.178546 14.826337
v  -2.837142 2.922475 15.541326
v  -1.814776 4.971721 14.131202
v  -1.725039 2.946126 14.216866
v  -2.538102 3.071820 15.329265
v  -2.617208 5.380712 13.716964
v  -2.939404 3.212741 13.890027
v  -2.443057 6.275213 14.592063
v  -2.087302 2.790421 13.831950
v  -2.247760 4.953961 13.911338
v  -2.125514 5.347501 14.022546
v  -2.478703 3.061090 15.224578
v  -2.082484 3.029687 14.520881
v  -3.005338 5.390975 14.930654
v  -2.251073 5.469781 13.891025
v  -3.153312 3.011454 14.731359
v  -2.458747 3.838708 15.209449
v  -2.858928 3.223608 13.540320
v  -2.691731 5.529074 15.260390
v  -3.004132 3.256704 13.941625
v  -3.142705 2.890880 15.206140
v  -2.673446 4.928302 15.245332
v  -2.275882 5.186313 14.466353
v  -2.853475 4.769420 15.356616
v  -2.395415 5.758809 15.088922
v  -2.020270 5.548434 14.332942
v  -3.103511 3.287562 14.894311
v  -3.012803 4.501205 15.079448
v  -1.744990 6.561475 14.285792
v  -2.335126 3.746546 15.013568
v  -2.196399 2.899488 15.335662
v  -2.671475 4.935930 13.886189
v  -2.630212 6.084192 14.256253
v  -1.917918 2.848293 13.953729
v  -1.945376 5.271317 14.119233
v  -1.974360 3.754223 14.244403
v  -2.495654 6.095347 13.983045
v  -2.225118 3.111251 14.792287
v  -2.815202 5.815761 14.889455
v  -2.192836 4.956674 14.743855
v  -2.768136 5.855690 14.347106
v  -1.751006 3.046498 14.309251
v  -1.921420 5.093578 13.997527
v  -2.561957 6.101418 14.592095
v  -1.948058 5.862502 14.137755
v  -3.217502 2.959044 14.297212
v  -1.908107 3.577604 14.151053
v  -2.279948 5.117033 14.448344
v  -2.328382 4.902258 14.935727
v  -1.963412 6.689944 14.350497
v  -2.512961 4.749180 13.831565
v  -2.787704 5.233262 13.925216
v  -2.073527 6.392516 14.100291
v  -1.932441 5.246285 14.126941
v  -2.237874 4.768277 14.603963
v  -2.857232 4.833593 13.931445
v  -3.264691 2.796585 13.999925
v  -1.723346 6.485684 14.384681
v  -2.119952 4.140389 14.289142
v  -2.747289 4.584662 15.386628
v  -2.207248 3.304697 14.670575
v  -2.375216 4.561509 14.781171
v  -2.385943 5.121668 14.661683
v  -1.837314 6.496920 14.142385
v  -2.114421 4.420114 13.997243
v  -1.747622 6.260293 14.209662
v  -3.089604 3.064885 13.748366
v  -2.134625 3.492670 14.415690
v  -2.317868 5.461370 14.842227
v  -2.072764 5.124678 14.309236
v  -2.950583 4.896617 14.109183
v  -2.377099 5.854153 13.745461
v  -2.926778 2.897344 13.894492
v  -2.501149 4.310907 15.122466
v  -2.998469 3.845250 14.495555
v  -2.744166 4.318324 13.799451
v  -1.795856 6.011145 14.438249
v  -2.472684 4.161040 13.742692
v  -2.192258 5.137455 14.364879
v  -3.009473 4.329648 15.130813
v  -2.069156 6.012509 14.016988
v  -2.142310 5.350857 14.426866
v  -2.181670 3.838396 14.473666
v  -2.492011 5.375873 15.177270
v  -2.041553 5.881321 14.062940
v  -2.234944 2.978076 15.052556
v  -1.900752 4.767701 14.162074
v  -1.730472 2.886831 14.371556
v  -2.337770 4.304108 13.786403
v  -2.064975 2.875828 13.818086
v  -2.561059 6.032036 14.879075
v  -1.946780 2.823253 14.851443
v  -3.069444 3.848713 14.726737
v  -1.942363 2.906918 14.539624
v  -2.522702 3.952135 15.237216
v  -1.913981 3.354573 14.016894
v  -3.009337 4.930421 14.498679
v  -2.248895 2.864961 13.622630
v  -2.298533 4.320271 14.769290
v  -2.425448 4.367569 14.990260
v  -1.933360 5.280843 14.048484
v  -1.864017 5.223639 14.317971
v  -2.177186 3.714562 14.631326
v  -2.787854 3.823038 13.712205
v  -2.172519 5.238649 14.331971
v  -2.625669 4.111043 15.435058
v  -2.184781 3.043709 14.635943
v  -3.260350 2.855064 14.528098
v  -1.956988 5.343261 14.031486
v  -3.062541 3.402057 13.987735
v  -2.884158 5.619821 14.762797
v  -2.439118 2.964891 15.182565
v  -2.144969 6.053815 14.903907
v  -2.006354 3.470861 14.335704
v  -2.862615 5.364900 14.175139
v  -2.720958 3.576548 13.618251
v  -3.264785 2.936623 14.616258
v  -2.173104 4.229058 14.464887
v  -2.591054 2.889784 15.509953
v  -2.044589 3.186834 13.763854
v  -2.766898 3.581731 15.456082
v  -2.984481 4.449563 14.692174
v  -2.989617 2.792566 15.554952
v  -2.223491 3.208832 13.681079
v  -3.255427 2.754482 15.217195
v  -2.844138 5.837853 15.079144
v  -2.886159 3.760966 13.801137
v  -2.500373 4.727191 15.058688
v  -2.078486 5.664845 14.703712
v  -3.056434 3.047078 13.637706
v  -2.326999 5.152877 14.738458
v  -2.150819 4.109235 14.348596
v  -2.261067 4.520056 14.538508
v  -1.876109 6.166665 14.110080
v  -1.968585 3.670674 14.271457
v  -1.762225 6.206587 14.462325
v  -2.136268 4.035623 13.778295
v  -2.032794 4.245152 14.214063
v  -2.102946 5.937901 14.893453
v  -2.139821 4.078714 14.503373
v  -3.009208 4.677793 14.597829
v  -2.064731 3.270061 13.724097
v  -2.085570 3.133269 14.431113
v  -2.046161 5.305386 14.277898
v  -2.953637 5.058333 14.177974
v  -1.824378 6.082947 14.172122
v  -2.870904 3.800332 15.438207
v  -2.586827 2.845993 13.435168
v  -2.557796 3.707343 15.307080
v  -2.121325 4.778832 14.334163
v  -1.980273 4.715183 14.051042
v  -2.978381 4.482733 14.106622
v  -2.384261 5.303725 15.120231
v  -2.228597 5.106712 14.945146
v  -2.920369 4.366718 15.348189
v  -1.985290 6.559067 14.141276
v  -3.028663 4.683509 14.905385
v  -2.328469 2.889901 15.267127
v  -2.202641 3.704066 14.712255
v  -3.052510 4.886837 14.913232
v  -2.178981 3.805550 14.446170
v  -2.295201 6.216190 14.004018
v  -2.754386 4.504612 13.852538
v  -3.284926 2.785031 15.336642
v  -2.693032 5.190196 15.228940
v  -1.971477 5.118452 14.326709
v  -2.086932 3.618093 13.738847
v  -1.903522 3.259796 14.352365
v  -2.022708 2.809038 13.891044
v  -2.580764 4.147966 15.379537
v  -2.577792 3.329303 15.368541
v  -2.291295 4.473619 13.848920
v  -2.876216 5.772282 15.143342
v  -2.345901 5.292102 13.774165
v  -2.045154 5.320790 14.188273
v  -1.952952 4.740009 14.238795
v  -2.932878 3.110391 13.957673
v  -1.848941 3.348069 14.189900
v  -3.088422 3.573881 15.368627
v  -2.685954 3.842482 15.451865
v  -2.123317 5.407404 14.117536
v  -2.328631 3.124473 13.479722
v  -2.315572 6.328736 14.523410
v  -3.016538 4.243435 14.397307
v  -2.415181 3.368515 15.104249
v  -2.616082 2.892142 15.485332
v  -1.870368 6.663254 14.268910
v  -3.120217 3.333670 14.619658
v  -1.791369 5.163698 14.189546
v  -1.914363 3.493918 14.313279
v  -2.180709 4.522906 13.893445
v  -3.113034 3.425776 14.264974
v  -2.053401 5.546141 14.566821
v  -3.173697 2.938361 15.562682
v  -2.023685 5.931491 14.700946
v  -2.191951 5.110705 14.816228
v  -2.161199 5.407073 13.971553
v  -2.219512 4.774623 14.485444
v  -3.025172 3.880685 15.302844
v  -3.151845 3.263600 14.409030
v  -2.938674 5.384844 14.696610
v  -2.200480 4.192754 14.642440
v  -2.538770 2.974929 13.424787
v  -2.837455 2.966510 13.496133
v  -2.018580 3.003512 13.921000
v  -1.872813 3.016588 14.004451
v  -2.611943 4.482599 15.246423
v  -1.808944 6.279615 14.524905
v  -2.640651 5.262980 13.740796
v  -2.377110 3.748761 13.616071
v  -2.463187 6.304224 14.424745
v  -2.474143 4.951855 15.089520
v  -2.225173 3.352757 14.780250
v  -2.825978 5.601294 14.075606
v  -2.788877 5.756382 14.001908
v  -3.209016 3.014308 14.362760
v  -2.075568 5.433884 14.086799
v  -1.983668 3.214162 14.368915
v  -2.327081 5.388637 13.829314
v  -1.757022 5.227325 14.145795
v  -1.798976 5.269041 14.128366
v  -2.248609 5.097626 14.655263
v  -2.191533 5.227359 13.873631
v  -3.088778 3.756699 14.812844
v  -2.161199 4.941624 13.940112
v  -2.694854 5.968292 14.189950
v  -1.720149 2.858073 14.182920
v  -3.058528 2.808674 15.541097
v  -2.462070 3.105486 15.050364
v  -2.419745 3.248035 14.947755
v  -2.332318 4.422999 14.836099
v  -2.033580 4.911998 14.299294
v  -2.403533 5.208323 15.148644
v  -3.140168 3.055349 15.128951
v  -2.252673 3.119819 14.892306
v  -3.290620 2.845675 15.480836
v  -2.934714 3.736350 15.417840
v  -2.381660 2.931314 15.150205
v  -3.163346 2.736779 15.446235
v  -2.156283 4.990458 14.353717
v  -3.034663 2.914593 15.600553
v  -2.281840 3.070436 13.601418
v  -2.477275 5.930752 13.764470
v  -3.092998 3.413461 14.722325
v  -2.920283 2.892034 15.571342
v  -2.333192 3.666348 14.883227
v  -2.380538 5.492960 14.965512
v  -1.960483 5.326740 14.100816
v  -2.603736 3.789888 15.349763
v  -3.113149 3.512732 14.099771
v  -3.103997 2.803735 15.609624
v  -2.085364 6.342366 14.640334
v  -2.800773 5.037595 13.946807
v  -3.177741 3.120137 14.654807
v  -3.009217 4.227474 14.088408
v  -2.357551 5.127584 14.543056
v  -2.374501 5.554048 13.771344
v  -2.635142 5.994160 14.842147
v  -2.749299 3.078397 15.491235
v  -2.022578 4.193892 14.099065
v  -1.968406 2.844650 14.666186
v  -3.214831 2.828748 14.748216
v  -2.979192 4.022112 15.318612
v  -2.472152 3.414053 13.511863
v  -3.038960 3.344821 15.488505
v  -2.479039 3.980155 13.679088
v  -2.188827 5.235652 14.356996
v  -2.245908 6.246695 14.695746
v  -1.876895 5.858542 14.294869
v  -2.197431 2.907460 13.695302
v  -1.956370 4.657153 14.101144
v  -2.147094 4.179708 13.811496
v  -2.426848 3.311919 14.986045
v  -2.573173 5.883540 15.090388
v  -1.840617 5.889429 14.426370
v  -3.051667 4.183150 14.821740
v  -2.659274 3.098889 13.526175
v  -1.884566 6.664896 14.409245
v  -2.989020 5.439320 15.129683
v  -2.156142 5.319499 14.362300
v  -2.277150 3.563732 13.679833
v  -2.134831 6.089486 13.997809
v  -2.039371 5.188483 14.195916
v  -2.168759 5.316655 14.310206
v  -2.431195 3.582808 15.175406
v  -2.843249 4.545475 15.390595
v  -2.471156 6.258388 14.316918
v  -2.262862 3.875591 13.761765
v  -2.242726 5.055476 13.901952
v  -2.359252 5.893859 15.039304
v  -2.173316 5.272689 14.411399
v  -2.948184 2.968806 15.587031
v  -2.864442 5.516844 13.826829
v  -2.163909 6.430393 14.127212
v  -2.167569 2.988540 14.802405
v  -2.723489 5.953725 14.456506
v  -2.264785 4.934177 14.639512
v  -2.249715 4.166986 13.794545
v  -1.987075 6.101066 14.096686
v  -3.254191 2.773507 15.042691
v  -3.035087 4.833614 15.196822
v  -2.104831 4.154630 13.855868
v  -2.987720 3.103371 13.850060
v  -2.327468 3.725283 14.828561
v  -1.808455 5.195578 14.085078
v  -2.053241 5.233936 13.926713
v  -2.175765 2.949788 13.702043
v  -2.352191 3.166247 14.929622
v  -2.322543 3.979124 13.772008
v  -3.021131 4.464828 14.839789
v  -2.035876 4.460433 14.265569
v  -3.061978 4.387165 15.209886
v  -3.013196 3.934776 14.603467
v  -3.218776 3.196196 14.122003
v  -2.076934 3.880637 13.792375
v  -2.298322 5.235547 14.532868
v  -3.131324 3.169006 14.843835
v  -1.934764 5.046841 14.313546
v  -1.903033 2.801718 14.717015
v  -2.324212 4.824436 14.799260
v  -1.922969 4.876372 14.261092
v  -2.730437 4.187870 15.421275
v  -2.012019 3.875082 14.006421
v  -2.891461 4.577590 14.001390
v  -2.284041 4.645961 14.706952
v  -2.699531 5.552822 13.719636
v  -2.134257 2.950402 14.964360
v  -2.858113 4.215964 13.853669
v  -2.003413 4.411903 14.156362
v  -3.143149 2.743547 15.377244
v  -3.042611 3.889432 15.231183
v  -2.104098 5.178953 14.339543
v  -1.909312 5.241205 14.286815
v  -3.052648 2.708990 15.482972
v  -2.280999 3.887038 14.706706
v  -2.039271 3.728011 14.311883
v  -2.732787 4.881851 13.905732
v  -2.183465 5.088346 14.278144
v  -2.004210 5.591516 14.418736
v  -3.038968 4.916868 15.113868
v  -2.995648 4.300960 15.067908
v  -3.238967 2.900001 14.973458
v  -2.424754 4.105324 15.093535
v  -2.994710 2.855412 13.938720
v  -2.862910 3.702883 13.728511
v  -3.016759 3.765589 14.369880
v  -2.016105 4.018157 14.214087
v  -2.841863 4.959140 15.312547
v  -2.057403 5.372329 14.085946
v  -3.083135 3.733436 15.309836
v  -2.313809 4.150444 14.772000
v  -3.051288 4.491414 15.259221
v  -2.742793 2.811960 15.507106
v  -2.094096 5.775929 14.776099
v  -2.862565 2.982249 15.560554
v  -2.704961 3.013667 15.462903
v  -2.992139 4.526670 14.355948
v  -2.727885 4.946453 15.283168
v  -2.507302 6.153113 14.677726
v  -2.052722 3.245911 14.373050
v  -2.039134 2.821607 13.879457
v  -2.501119 4.351457 13.787105
v  -3.251243 2.860948 15.412667
v  -2.111121 2.908941 15.111154
v  -1.871737 5.238639 14.150468
v  -2.099086 5.409957 14.415507
v  -2.456025 4.710840 14.850589
v  -1.921237 5.730356 14.481008
v  -1.856967 2.931927 14.429700
v  -2.937036 5.516255 15.194869
v  -2.333514 3.638865 15.007770
v  -2.951934 4.981418 15.318835
v  -2.151048 4.663973 13.947680
v  -2.441709 4.296589 15.047587
v  -2.289630 5.217834 14.848832
v  -3.023530 5.301609 15.059997
v  -2.136397 4.101646 14.541007
v  -2.480962 5.510942 13.734506
v  -2.126239 5.144567 14.378184
v  -1.829181 4.945625 14.102479
v  -2.775765 5.783023 14.686587
v  -2.020579 3.256815 13.895267
v  -3.221976 2.844392 14.290633
v  -2.371116 5.223129 14.803477
v  -2.304818 5.006366 14.567916
v  -1.766192 6.020284 14.392230
v  -2.385720 3.165652 13.450353
v  -2.004935 5.841558 14.100644
v  -2.713979 5.450174 15.263122
v  -2.389464 4.541152 13.834910
v  -2.884812 5.407557 14.340524
v  -3.136821 3.076040 15.404084
v  -2.782678 3.827417 15.464313
v  -2.989631 4.964538 14.266124
v  -2.019473 4.649254 14.271522
v  -2.213470 4.357079 14.649796
v  -2.043162 3.548796 14.350728
v  -2.350816 3.911986 15.008670
v  -2.634479 2.902956 15.447459
v  -3.058379 3.631473 14.072104
v  -3.214061 2.836128 15.625421
v  -2.642194 5.535405 15.248054
v  -2.432616 3.344452 15.157973
v  -1.800288 5.027386 14.164056
v  -3.021794 4.069693 14.485425
v  -2.915446 3.279720 13.592883
v  -2.260827 4.597206 14.516210
v  -3.045642 4.220666 14.752168
v  -3.020888 3.781097 15.145877
v  -2.882058 5.557748 13.967502
v  -3.046012 3.863616 14.247113
v  -3.263497 2.943770 14.092240
v  -2.255006 4.709860 14.669860
v  -2.215848 5.101659 14.367581
v  -2.825639 2.761003 15.565516
v  -2.245955 2.887162 13.647323
v  -2.848983 3.386888 15.508245
v  -2.525659 6.172728 14.547984
v  -2.068439 6.604095 14.449632
v  -1.872882 5.245088 14.093804
v  -1.782962 2.847878 14.069571
v  -1.984447 6.424357 14.573588
v  -2.422429 2.913245 15.454198
v  -2.109263 4.975342 14.298985
v  -1.954047 5.271387 14.016994
v  -2.187682 5.325160 14.486032
v  -2.382615 5.157349 14.617202
v  -2.004832 4.483215 14.100537
v  -3.134429 3.083018 15.482301
v  -2.625163 5.957375 13.939046
v  -2.624101 4.297957 13.798562
v  -1.722880 6.474816 14.266019
v  -2.203786 3.336333 14.540681
v  -2.764516 3.684901 15.454555
v  -2.281674 6.380637 14.279706
v  -2.065753 5.269942 14.322030
v  -3.191903 2.958007 15.517211
v  -2.078975 5.079437 14.299191
v  -2.589416 5.113239 13.814792
v  -2.900237 3.972755 15.365958
v  -2.156718 5.568505 13.971679
v  -1.727122 3.009671 14.266377
v  -2.553552 4.368220 15.201850
v  -2.492347 6.237438 14.571649
v  -2.227946 3.442197 14.784776
v  -1.866332 3.304513 14.077603
v  -2.987481 5.389940 14.838927
v  -2.586892 3.728824 13.652560
v  -2.249566 4.641497 14.644341
v  -1.831210 6.416334 14.119407
v  -3.027349 2.841757 15.573125
v  -1.752330 5.182603 14.142900
v  -2.828696 5.029522 13.967505
v  -3.182992 2.882401 14.826056
v  -2.052329 5.551660 14.240176
v  -2.233531 3.148610 14.774472
v  -2.332423 5.331746 13.788583
v  -2.320636 5.959183 13.777667
v  -2.893954 2.968095 14.002313
v  -3.071980 3.045642 15.574426
v  -2.836955 4.309536 13.840682
v  -2.231933 4.409700 13.851472
v  -2.594916 3.127933 15.386464
v  -2.239236 4.828645 14.563432
v  -1.976626 5.324639 14.119369
v  -2.073947 6.192837 14.721675
v  -2.065688 6.654897 14.363046
v  -1.848643 6.104132 14.129495
v  -2.483712 6.196888 14.126363
v  -2.770579 5.850259 14.529353
v  -2.580024 3.442738 15.342080
v  -2.384414 3.044233 15.021882
v  -2.306373 5.166174 13.814485
v  -2.951653 3.431320 13.773273
v  -2.161054 4.467695 13.904004
v  -2.115165 3.086264 14.502778
v  -1.793452 6.570504 14.430354
v  -1.700351 6.276713 14.346335
v  -2.192794 5.217724 14.371976
v  -2.313130 3.952455 14.713134
v  -2.942590 3.421854 13.882867
v  -2.180987 4.493875 14.382915
v  -2.248206 3.216669 13.664609
v  -2.311576 3.861535 13.727935
v  -1.801539 6.603537 14.239158
v  -3.050862 5.030140 14.941497
v  -1.846786 5.984170 14.574018
v  -2.041118 2.889638 15.095548
v  -3.030126 3.953293 15.262683
v  -2.160647 4.673789 14.403304
v  -2.140147 5.449242 14.555308
v  -2.509505 5.533406 15.154952
v  -2.508664 3.249622 15.294070
v  -2.078898 5.350625 14.062699
v  -2.512227 4.651253 15.081481
v  -2.348395 3.942482 14.866508
v  -2.104213 5.277803 14.330211
v  -1.931048 4.692054 14.171357
v  -1.956786 4.974064 14.291881
v  -2.615003 5.963952 14.991027
v  -2.823680 5.126653 14.026317
v  -2.856553 5.282019 14.118676
v  -2.080829 4.443590 14.022902
v  -2.444152 3.035344 13.409995
v  -2.079013 4.018386 13.923123
v  -3.284942 2.900972 14.592002
v  -2.160392 3.018826 14.609946
v  -2.071620 2.880609 14.795703
v  -2.321057 5.768037 13.765595
v  -2.683820 6.000862 14.604203
v  -2.496364 5.229370 13.750506
v  -2.273526 5.056695 14.455823
v  -2.000171 2.981454 14.478866
v  -3.060771 2.860093 15.607368
v  -2.534488 4.203791 15.191672
v  -1.829432 3.244231 14.155158
v  -2.447850 3.018432 15.103696
v  -2.176106 3.297489 14.479053
v  -2.286262 5.122391 15.043780
v  -2.250045 4.986480 14.491906
v  -2.883492 5.400248 13.952065
v  -3.094987 3.594959 15.256644
v  -2.110080 3.351869 13.698937
v  -2.489953 5.033128 13.812261
v  -2.261773 3.008372 13.659213
v  -1.969569 3.789024 14.222415
v  -2.042499 4.065214 14.243281
v  -2.065177 5.344356 14.101930
v  -2.646083 3.522491 15.368405
v  -3.093068 3.288137 15.177428
v  -3.047757 4.204616 14.245378
v  -2.179116 3.636212 14.628581
v  -2.734740 5.906004 14.744220
v  -2.677781 4.421495 15.347217
v  -3.004468 4.632383 14.512292
v  -2.201108 3.941936 14.638019
v  -2.137336 5.275030 14.276780
v  -2.903291 2.949448 13.514390
v  -3.110581 2.969328 13.780995
v  -3.213305 3.147754 13.981347
v  -2.922137 3.157644 13.948447
v  -1.824004 2.858273 14.017739
v  -2.296292 4.155340 13.787423
v  -2.111396 4.146095 13.825747
v  -2.384988 3.735463 15.104918
v  -2.215831 5.371210 14.631873
v  -2.992967 2.742693 15.526950
v  -2.192101 3.721882 14.537475
v  -2.369805 3.488170 15.018132
v  -2.316161 3.470537 14.877919
v  -2.254613 2.887783 15.305587
v  -3.030822 4.985756 15.211955
v  -2.489280 5.999303 14.970529
v  -3.233481 2.782609 15.150716
v  -2.023692 5.664199 14.132854
v  -2.025737 6.662838 14.279323
v  -2.350064 3.980259 14.811162
v  -3.011662 4.226687 14.613936
v  -2.118625 6.431193 14.108388
v  -2.508838 5.155776 15.163959
v  -1.813136 3.176235 14.324430
v  -2.102454 6.034116 14.865451
v  -2.933757 5.557842 14.877866
v  -2.396091 5.370759 14.875058
v  -3.073394 3.788685 15.250564
v  -2.787112 3.066441 13.504720
v  -3.189286 2.785327 14.390262
v  -1.781878 5.992300 14.345868
v  -1.802882 6.129743 14.239192
v  -2.111575 5.385518 14.368545
v  -2.365184 4.055737 15.028571
v  -3.076264 3.384458 14.987445
v  -2.742921 2.891652 13.509662
v  -2.065566 6.208536 14.045987
v  -1.806140 5.262650 14.161103
v  -3.063157 3.571817 14.643312
v  -2.866712 5.543610 15.233665
v  -2.908113 3.166949 15.552547
v  -1.872996 2.792640 14.800065
v  -2.178691 3.913284 14.399451
v  -1.718150 6.353348 14.433555
v  -3.172271 2.815346 15.652575
v  -2.419417 4.322354 13.811238
v  -1.811984 5.905815 14.388497
v  -2.302188 5.136107 14.653234
v  -2.099589 3.180298 14.420592
v  -2.149564 5.028605 14.294511
v  -3.098874 3.367925 14.684981
v  -3.030883 4.280552 14.128627
v  -1.966876 2.935917 13.952479
v  -3.132257 2.966129 15.171239
v  -1.986430 2.874310 14.628469
v  -2.055564 5.574815 14.622005
v  -2.835359 5.324016 13.826100
v  -2.667689 4.054597 15.453637
v  -1.938262 3.375835 13.993981
v  -2.236968 5.037755 14.679404
v  -3.007197 4.481829 14.247519
v  -2.043372 5.219349 14.333110
v  -2.160073 3.985905 14.359855
v  -2.220029 6.283833 14.619399
v  -3.234702 3.098848 14.534341
v  -2.590814 4.806302 15.151742
v  -1.950999 6.292487 14.111859
v  -2.110988 3.291903 14.395541
v  -2.203197 5.336490 13.893666
v  -2.144193 3.991270 14.510085
v  -3.066710 3.498678 14.955302
v  -2.316651 4.270843 14.854650
v  -2.353894 5.812405 15.074475
v  -2.031016 4.142529 14.215110
v  -1.797801 6.632213 14.304820
v  -2.601567 4.435995 13.821500
v  -2.834714 3.599881 15.477561
v  -2.939086 3.646900 13.953300
v  -2.170386 4.354435 14.347896
v  -1.787929 3.164652 14.283990
v  -2.806954 3.463005 13.595378
v  -2.243316 4.421458 14.539290
v  -1.854423 5.275349 14.132945
v  -2.797278 2.847225 15.531518
v  -2.953818 3.308004 13.936751
v  -1.887565 3.370933 14.332608
v  -2.103936 6.828314 14.482023
v  -2.023755 6.520549 14.537205
v  -1.968604 6.509071 14.077028
v  -2.536622 6.383863 14.380867
v  -1.582960 6.578066 14.681383
v  -1.854314 6.481794 13.765261
v  -2.058185 6.499249 14.864384
v  -1.869750 6.861995 14.244022
v  -2.243171 6.413170 13.814079
v  -1.455617 6.578068 14.170673
v  -2.241202 6.442379 14.406168
v  -2.222135 6.470037 14.281153
v  -1.770225 6.559584 14.333080
v  -1.896878 6.516217 14.562519
v  -2.084365 6.455700 14.054485
v  -1.740041 6.529538 14.210836
v  -2.242898 6.790191 14.245600
v  -2.009204 6.828315 14.102097
v  -1.930533 6.859629 14.438536
v  -2.501716 6.373579 14.108713
v  -2.224587 6.439864 14.369005
v  -1.755198 6.524489 14.248363
v  -2.047065 6.459723 14.070488
v  -1.932721 6.504630 14.546879
v  -1.619736 6.533041 13.891439
v  -1.443952 6.590006 14.372461
v  -2.308740 6.442394 14.767521
v  -2.478936 6.404059 14.569988
v  -1.737197 6.554203 14.803144
v  -2.055060 6.489947 14.563663
v  -1.958097 6.479232 14.038010
v  -2.256253 6.433318 14.277872
v  -1.724990 6.538599 14.339132
v  -1.765244 6.529689 14.396912
v  -1.901116 6.488264 14.079359
v  -2.214541 6.434664 14.220457
v  -2.078670 6.476089 14.538010
v  -2.396680 6.385518 13.936022
v  -2.182609 6.797000 14.145582
v  -2.201718 6.806576 14.421600
v  -2.036187 6.842371 14.291111
v  -2.188779 6.439087 14.127377
v  -1.792464 6.532829 14.489628
v  -1.810756 6.510846 14.105282
v  -2.170487 6.461071 14.511723
v  -1.827582 6.554685 14.461132
v  -1.843234 6.535968 14.134212
v  -2.164777 6.474936 14.153101
v  -2.149125 6.493652 14.480021
v  -1.989617 6.454958 13.749170
v  -1.870244 6.866439 14.338520
v  -1.502159 6.567102 14.047052
v  -2.172212 6.454873 14.471434
v  -2.148240 6.442837 14.124695
v  -1.831546 6.521516 14.492672
v  -1.807574 6.509481 14.145935
v  -2.102101 6.810437 14.102451
v  -2.011039 6.846192 14.481668
v  -2.243392 6.794634 14.340097
v  -1.911422 6.850053 14.162518
v  -1.478650 6.593794 14.508511
v  -1.727296 6.508631 13.814579
v  -1.990022 6.508627 14.868235
v  -2.536414 6.377367 14.244761
v  -2.191079 6.468120 14.831245
v  -1.506416 6.587522 14.571270
v  -1.859698 6.536826 14.850689
v  -2.121400 6.434335 13.766352
v  -2.405448 6.419504 14.678799
v  -1.783021 6.551885 14.224106
v  -2.075854 6.489084 14.091034
v  -2.209338 6.477736 14.390127
v  -1.916503 6.540537 14.523199
v  -2.213323 6.792744 14.180145
v  -1.757737 6.547977 14.400257
v  -1.900064 6.504591 14.067770
v  -2.228230 6.448474 14.215569
v  -1.945123 6.842076 14.131588
v  -1.958518 6.505111 14.578508
v  -1.756984 6.533256 14.306095
v  -1.991200 6.476812 14.070290
v  -1.990935 6.499735 14.546494
v  -2.225152 6.443292 14.310686
v  -2.158769 6.457036 14.115238
v  -1.827199 6.539415 14.500589
v  -1.802056 6.526821 14.137520
v  -2.183911 6.469631 14.478308
v  -1.747245 6.542528 14.244722
v  -2.052878 6.474710 14.058506
v  -1.933091 6.521742 14.557320
v  -2.238725 6.453923 14.371103
v  -2.438727 6.383865 13.988260
v  -2.168019 6.814553 14.452532
v  -1.899819 6.863885 14.403975
v  -2.181853 6.444981 14.172194
v  -1.800281 6.531567 14.444590
v  -2.126698 6.471528 14.503706
v  -1.855437 6.505020 14.113077
v  -2.118371 6.828193 14.378773
v  -2.522857 6.372728 14.175791
v  -1.671628 6.524979 13.849703
v  -1.475697 6.575090 14.107239
v  -2.245976 6.449609 14.310362
v  -1.993096 6.485845 14.050579
v  -1.739993 6.546843 14.305464
v  -2.204392 6.446365 14.423382
v  -1.775393 6.517988 14.193987
v  -2.100707 6.450400 14.091524
v  -1.879079 6.513953 14.525845
v  -2.158988 6.814978 14.266521
v  -2.056720 6.446186 13.753681
v  -1.922183 6.468124 13.752840
v  -2.125323 6.481791 14.852144
v  -1.456780 6.590857 14.441614
v  -1.773429 6.534305 14.393887
v  -1.905040 6.494175 14.086220
v  -2.077096 6.482372 14.530561
v  -2.208707 6.442243 14.222895
v  -1.789289 6.499253 13.785976
v  -2.504670 6.392282 14.509983
v  -2.540635 6.379850 14.313393
v  -1.440465 6.591311 14.303648
v  -2.252341 6.454954 14.802826
v  -2.473220 6.376063 14.046135
v  -1.924378 6.524975 14.863359
v  -1.541643 6.583508 14.628963
v  -1.444473 6.587297 14.236174
v  -1.992873 6.510607 14.565247
v  -2.032306 6.835645 14.188730
v  -2.359900 6.430543 14.725965
v  -2.524753 6.389305 14.446550
v  -2.183761 6.423295 13.786613
v  -1.797338 6.547866 14.830427
v  -1.574193 6.544081 13.938606
v  -2.349895 6.392285 13.889261
v  -1.629743 6.571300 14.728144
v  -1.535358 6.557994 13.990568
v  -2.445006 6.409379 14.626654
v  -2.298272 6.400273 13.848545
v  -1.681363 6.563311 14.768860
v  -1.887330 6.525960 14.102694
v  -2.105029 6.503660 14.511539
v  -2.196164 6.471057 14.198244
v  -1.796195 6.558563 14.415989
v  -2.085904 6.491860 14.548056
v  -1.846387 6.516327 14.096832
v  -2.139583 6.480124 14.518993
v  -1.786798 6.545014 14.455124
v  -2.199170 6.451437 14.160702
v  -2.217586 6.460729 14.428026
v  -1.768382 6.535723 14.187799
v  -1.876937 6.531502 14.535299
v  -2.109031 6.464951 14.080526
v  -2.056483 6.837728 14.487694
v  -1.864238 6.865275 14.290195
v  -2.248904 6.791354 14.293922
v  -2.056657 6.818902 14.096424
v  -1.990020 6.470349 14.062923
v  -1.748281 6.528606 14.306324
v  -2.231505 6.435747 14.311043
v  -1.989766 6.494004 14.554445
v  -2.241855 6.438701 14.245859
v  -1.927718 6.489949 14.052958
v  -1.740926 6.541195 14.370764
v  -2.227321 6.799808 14.383373
v  -1.885819 6.856821 14.200747
v  -2.144794 6.803018 14.119192
v  -1.968349 6.853612 14.464928
v  -1.953679 6.855681 14.290562
v  -2.218450 6.447278 14.366817
v  -1.935691 6.510025 14.539165
v  -2.046445 6.466522 14.077616
v  -1.763686 6.529269 14.249964
v  -1.783254 6.522969 14.197275
v  -1.883714 6.519059 14.518784
v  -2.098422 6.457489 14.098000
v  -2.198882 6.453578 14.419508
v  -1.837646 6.526389 14.486633
v  -1.814441 6.514724 14.150705
v  -2.167693 6.461823 14.466078
v  -2.144488 6.450159 14.130148
v  -1.862702 6.499056 14.068153
v  -2.225751 6.434520 14.180557
v  -2.118539 6.472861 14.548851
v  -1.755490 6.537397 14.436447
v  -2.211997 6.450747 14.463272
v  -1.769246 6.521169 14.153732
v  -1.840474 6.525630 14.532293
v  -2.140769 6.446287 14.084711
v  -2.022725 6.466806 14.038496
v  -2.256362 6.436454 14.343609
v  -1.724881 6.535462 14.273396
v  -2.023145 6.492684 14.578995
v  -1.990346 6.458746 13.748987
v  -1.474969 6.571302 14.107420
v  -2.505399 6.396070 14.509802
v  -2.186812 6.437491 14.168100
v  -1.849896 6.499462 14.107090
v  -1.792974 6.526862 14.449266
v  -2.129889 6.464891 14.510277
v  -2.222226 6.472699 14.336956
v  -2.023465 6.498523 14.077440
v  -1.968894 6.531097 14.536793
v  -1.770131 6.556921 14.277277
v  -2.057803 6.840775 14.397800
v  -2.123908 6.481064 14.116787
v  -2.184455 6.484866 14.438778
v  -1.868449 6.548557 14.497446
v  -1.807902 6.544755 14.175455
v  -1.985925 6.833155 14.109495
v  -1.877728 6.866138 14.362267
v  -2.127215 6.823474 14.474625
v  -2.235412 6.790492 14.221851
v  -2.437997 6.380077 13.988441
v  -1.796607 6.544078 14.830610
v  -2.120669 6.430547 13.766533
v  -2.478206 6.400271 14.570169
v  -2.535684 6.373579 14.244944
v  -1.444685 6.593794 14.372280
v  -1.990750 6.512415 14.868053
v  -2.242440 6.409382 13.814260
v  -1.534631 6.554206 13.990751
v  -1.501431 6.563314 14.047234
v  -2.183030 6.419507 13.786795
v  -1.574923 6.547869 13.938425
v  -1.682096 6.567100 14.768677
v  -1.456347 6.581856 14.170492
v  -2.397410 6.389307 13.935841
v  -1.670896 6.521191 13.849884
v  -2.055989 6.442398 13.753862
v  -2.309470 6.446182 14.767338
v  -1.507146 6.591310 14.571089
v  -2.473948 6.379851 14.045952
v  -2.539905 6.376062 14.313574
v  -1.728026 6.512419 13.814396
v  -1.922912 6.471912 13.752659
v  -1.477921 6.590006 14.508692
v  -1.855044 6.485582 13.765079
v  -2.253072 6.458742 14.802645
v  -2.523587 6.376516 14.175610
v  -2.057456 6.495461 14.864565
v  -2.299003 6.404061 13.848364
v  -2.350625 6.396073 13.889080
v  -2.445737 6.413167 14.626472
v  -1.737927 6.557991 14.802961
v  -1.620465 6.536830 13.891256
v  -1.630475 6.575088 14.727961
v  -2.406177 6.423292 14.678616
v  -2.524022 6.385516 14.446732
v  -1.583688 6.581855 14.681200
v  -1.858968 6.533038 14.850872
v  -2.360629 6.434331 14.725784
v  -2.535893 6.380075 14.381050
v  -1.542372 6.587296 14.628780
v  -1.443746 6.583509 14.236355
v  -1.923648 6.521187 14.863541
v  -1.439732 6.587523 14.303829
v  -1.788560 6.495464 13.786158
v  -2.502446 6.377367 14.108530
v  -1.457508 6.594645 14.441431
v  -2.191808 6.471908 14.831064
v  -2.126054 6.485579 14.851963
v  -2.105737 6.821943 14.197969
v  -2.019420 6.494173 14.544943
v  -1.962714 6.482374 14.071840
v  -2.223369 6.442243 14.281696
v  -1.758767 6.534305 14.335087
v  -1.747895 6.548374 14.369026
v  -1.930680 6.498227 14.058039
v  -2.055289 6.498225 14.557787
v  -2.238074 6.448078 14.246801
v  -2.186511 6.460724 14.482435
v  -1.796269 6.519172 14.134186
v  -1.821977 6.532040 14.505217
v  -2.160804 6.447856 14.111404
v  -2.220916 6.451629 14.431061
v  -1.872794 6.523954 14.540684
v  -1.761863 6.528267 14.185560
v  -2.109986 6.455942 14.075937
v  -1.780684 6.537762 14.458752
v  -1.841577 6.508447 14.092606
v  -2.141204 6.471449 14.524015
v  -0.595205 6.110755 12.069311
v  -0.484335 6.148881 12.164194
v  -2.477292 4.929106 14.810120
v  -0.503641 6.048064 12.107384
v  -2.465396 4.991252 14.845139
v  -0.565214 6.187380 12.123348
v  -0.474657 6.076803 12.140217
v  -2.428302 4.945954 14.852049
v  -2.492763 4.964110 14.814131
v  -0.547026 6.051918 12.078285
v  -0.501074 6.169314 12.161077
v  -2.445062 4.920028 14.829079
v  -0.598562 6.135787 12.077637
v  -2.435363 4.970690 14.857626
v  -2.476003 4.990036 14.837101
v  -3.621333 -0.120437 13.330087
v  -3.621335 -0.120437 15.988093
v  -1.829843 -0.120437 15.988093
v  -1.829843 -0.120437 13.330087
v  -3.621333 0.600524 13.330087
v  -3.621335 0.600524 15.988093
v  -1.829843 0.600524 15.988093
v  -1.829843 0.600524 13.330087
v  -3.576547 0.600524 13.396537
v  -3.576547 0.600524 15.921643
v  -1.874631 0.600524 15.921643
v  -1.874631 0.600524 13.396539
v  -3.576547 2.863424 13.396537
v  -3.576547 2.863424 15.921643
v  -1.874631 2.863424 15.921643
v  -1.874631 2.863424 13.396539
v  16.917707 0.001522 2.096973
v  3.828773 0.001522 8.958046
v  11.448765 0.001522 23.494778
v  24.537695 0.001522 16.633724
v  23.963789 1.146000 7.669022
v  22.684126 1.146000 5.747565
v  21.032934 1.146000 4.134151
v  19.082378 1.146000 2.899300
v  16.917707 1.146000 2.096972
v  10.106813 1.146000 2.533001
v  8.062120 1.146000 3.604805
v  6.284950 1.146000 5.078304
v  4.852974 1.146000 6.889098
v  3.828775 1.146000 8.958046
v  4.402686 1.146000 17.922731
v  5.682348 1.146000 19.844189
v  7.333539 1.146000 21.457600
v  9.284094 1.146000 22.692451
v  11.448765 1.146000 23.494778
v  18.259651 1.146000 23.058754
v  20.304342 1.146000 21.986952
v  22.081512 1.146000 20.513458
v  23.513491 1.146000 18.702669
v  24.537693 1.146000 16.633724
v  19.480761 1.262264 10.018974
v  18.787647 1.262264 8.978239
v  11.975288 1.262264 7.237108
v  10.867804 1.262264 7.817638
v  8.885714 1.262264 15.572779
v  9.578827 1.262264 16.613514
v  16.391180 1.262264 18.354647
v  17.498665 1.262264 17.774117
v  12.702142 1.262264 18.590813
v  19.791609 1.262264 14.874602
v  15.664331 1.262264 7.000938
v  8.574860 1.262264 10.717159
v  18.743076 1.406666 7.361670
v  17.513599 1.406666 6.532376
v  16.138569 1.406666 5.976829
v  14.678080 1.406666 5.719305
v  13.195964 1.406666 5.771061
v  11.756996 1.406666 6.129836
v  10.424068 1.406666 6.779949
v  9.255434 1.406666 7.692986
v  8.302168 1.406666 8.829044
v  7.605932 1.406666 10.138472
v  7.197157 1.406666 11.564041
v  7.093707 1.406666 13.043447
v  7.300104 1.406666 14.512033
v  7.807326 1.406666 15.905616
v  8.593206 1.406666 17.163288
v  9.623398 1.406666 18.230082
v  10.852877 1.406666 19.059376
v  12.227907 1.406666 19.614923
v  13.688396 1.406666 19.872448
v  15.170511 1.406666 19.820690
v  16.609478 1.406666 19.461914
v  17.942406 1.406666 18.811804
v  19.111038 1.406666 17.898766
v  20.064304 1.406666 16.762711
v  20.760540 1.406666 15.453285
v  21.169315 1.406666 14.027718
v  21.272768 1.406666 12.548313
v  21.066374 1.406666 11.079728
v  20.559155 1.406666 9.686147
v  19.773277 1.406666 8.428476
v  18.743076 1.591126 7.361670
v  17.513599 1.591126 6.532376
v  16.138569 1.591126 5.976829
v  14.678080 1.591126 5.719305
v  13.195964 1.591126 5.771061
v  11.756996 1.591126 6.129836
v  10.424068 1.591126 6.779949
v  9.255434 1.591126 7.692986
v  8.302168 1.591126 8.829044
v  7.605932 1.591126 10.138472
v  7.197157 1.591126 11.564041
v  7.093707 1.591126 13.043447
v  7.300104 1.591126 14.512033
v  7.807326 1.591126 15.905616
v  8.593206 1.591126 17.163288
v  9.623398 1.591126 18.230082
v  10.852877 1.591126 19.059376
v  12.227907 1.591126 19.614923
v  13.688396 1.591126 19.872448
v  15.170511 1.591126 19.820690
v  16.609478 1.591126 19.461914
v  17.942406 1.591126 18.811804
v  19.111038 1.591126 17.898766
v  20.064304 1.591126 16.762711
v  20.760540 1.591126 15.453285
v  21.169315 1.591126 14.027718
v  21.272768 1.591126 12.548313
v  21.066374 1.591126 11.079728
v  20.559155 1.591126 9.686147
v  19.773277 1.591126 8.428476
v  17.332754 1.591126 10.335216
v  17.661530 4.888200 8.650581
v  16.746805 5.013387 8.061388
v  15.654320 5.072420 7.634102
v  14.516452 5.090598 7.410728
v  13.454214 5.093143 7.434020
v  12.376170 5.089544 7.731246
v  11.318735 5.057664 8.253238
v  10.403862 4.971479 8.935966
v  9.725920 4.786954 9.744421
v  9.213410 4.442218 10.763929
v  8.883942 4.077347 11.895741
v  8.778894 3.859157 13.008866
v  8.940060 3.932813 14.059000
v  9.356745 4.240184 15.140770
v  9.966324 4.614849 16.142517
v  10.704826 4.888335 16.941254
v  11.619629 5.013677 17.530489
v  12.712132 5.072994 17.957804
v  13.849965 5.091154 18.181189
v  14.912171 5.092873 18.157885
v  15.990250 5.086904 17.860647
v  17.047686 5.051993 17.338654
v  17.962555 4.963577 16.655916
v  18.640495 4.779080 15.847467
v  19.152994 4.436624 14.827974
v  19.482456 4.074780 13.696156
v  19.587511 3.858860 12.582981
v  19.426374 3.933163 11.532884
v  19.009693 4.240432 10.451157
v  18.400089 4.614785 9.449402
v  9.006460 3.828986 14.043010
v  14.064328 3.813559 12.668134
v  17.640011 4.766788 8.676226
v  17.939173 4.836572 16.632019
v  18.963846 3.885256 11.644304
v  10.271939 1.574277 10.118104
v  16.432800 1.574277 8.641905
v  18.094477 1.574277 15.473784
v  9.402586 3.884937 13.947578
v  11.933630 1.574277 16.949968
v  10.427243 4.844005 8.959866
v  10.106813 0.001522 2.533001
v  13.518521 4.942908 7.907023
v  14.847865 4.942662 17.684881
v  18.259651 0.001522 23.058754
v  23.963791 0.001522 7.669022
v  4.402685 0.001522 17.922731
v  13.768321 4.285822 9.771437
v  10.726344 4.766913 16.915611
v  13.543720 1.574277 8.093274
v  14.822662 1.574277 17.498608
v  11.845776 4.870452 17.112829
v  16.520657 4.870188 8.479048
v  18.784069 1.574277 11.687577
v  9.582365 1.574277 13.904306
v  13.906393 3.806519 13.872840
v  19.546282 -0.114743 9.984630
v  8.820193 -0.114743 15.607124
v  16.418489 -0.114743 18.423399
v  8.505495 -0.114743 10.691448
v  11.947981 -0.114743 7.168355
v  19.860975 -0.114743 14.900312
v  15.682650 -0.114743 6.929265
v  12.683824 -0.114743 18.662487
v  14.907646 4.956263 18.124538
v  19.379898 3.860465 11.544076
v  9.852024 2.562928 12.966580
v  18.514381 2.562928 12.625273
v  8.820193 -0.114743 15.607124
v  8.885714 1.262264 15.572779
v  8.505495 -0.114743 10.691448
v  11.203147 3.862816 12.285698
v  17.755424 2.562928 15.241656
v  10.610991 2.562928 10.350234
v  13.458738 4.956519 7.467365
v  19.546282 -0.114743 9.984630
v  19.480761 1.262264 10.018974
v  19.860975 -0.114743 14.900312
v  10.737257 4.831977 9.276476
v  17.629160 4.824772 16.315409
v  14.767229 2.562928 17.090954
v  13.599154 2.562928 8.500927
v  16.970835 2.562928 9.473721
v  11.395522 2.562928 16.118118
v  17.234016 3.925125 12.053857
v  17.244051 3.987405 13.324697
v  7.333539 0.001522 21.457600
v  21.032934 0.001522 4.134151
v  22.081512 0.001522 20.513458
v  19.439529 3.994618 13.688873
v  8.574860 1.262264 10.717159
v  8.936234 3.979461 11.904613
v  19.791609 1.262264 14.874602
v  9.351418 4.016721 11.975153
v  19.014982 4.014380 13.616743
v  14.690180 3.861559 13.145330
v  15.664331 1.262264 7.000938
v  15.682650 -0.114743 6.929265
v  11.975288 1.262264 7.237108
v  4.852973 0.001522 6.889098
v  11.947981 -0.114743 7.168355
v  18.051292 2.562928 10.916646
v  10.315141 2.562928 14.675274
v  10.286936 4.624308 10.128371
v  11.142637 4.755167 16.419493
v  17.212069 2.562928 15.889197
v  11.154345 2.562928 9.702686
v  14.450441 2.562928 8.481982
v  13.915972 2.562928 17.109917
v  13.481929 3.876676 13.695592
v  20.226059 -0.114743 12.409018
v  8.140415 -0.114743 13.182731
v  8.214241 1.262264 13.178005
v  15.762634 4.939316 17.221167
v  10.338318 4.506806 15.847299
v  18.028095 4.506748 9.744617
v  13.355295 3.678746 12.243594
v  12.721225 4.937878 17.925774
v  15.645226 4.937339 7.666131
v  10.482917 1.574277 15.732702
v  17.883495 1.574277 9.859209
v  11.685556 3.946208 11.084699
v  14.474686 4.944872 8.090641
v  14.430148 -0.114743 6.745718
v  16.418489 -0.114743 18.423399
v  16.391180 1.262264 18.354647
v  12.683824 -0.114743 18.662487
v  9.533107 1.574277 12.005920
v  18.833290 1.574277 13.585975
v  19.942347 1.262264 11.181073
v  13.179271 1.262264 6.899521
v  12.702142 1.262264 18.590813
v  16.149179 4.261888 10.450111
v  9.416487 3.329229 13.933541
v  9.442150 3.080173 13.935245
v  9.232170 3.285584 12.970187
v  17.401222 3.329229 9.028866
v  17.378490 3.080173 9.040895
v  18.064068 3.285584 9.751818
v  15.606398 3.329229 8.068676
v  15.590858 3.080173 8.089170
v  16.512299 3.285584 8.444666
v  18.634378 3.288471 10.661190
v  18.609905 3.038310 10.662530
v  18.950438 3.244619 11.589107
v  19.038237 3.288471 13.689681
v  19.017662 3.038310 13.676361
v  18.747658 3.244619 14.625890
v  16.767603 3.288471 17.002005
v  16.763735 3.038311 16.977802
v  15.877422 3.244619 17.412495
v  13.867423 3.288472 17.722437
v  13.878228 3.038311 17.700438
v  12.903509 3.244619 17.544132
v  11.015638 3.288471 16.582314
v  11.037361 3.038311 16.570957
v  10.343697 3.244619 15.868580
v  18.079847 3.337291 9.760204
v  18.055103 3.088234 9.767219
v  18.577005 3.293646 10.605698
v  10.273443 3.288471 15.809884
v  10.297104 3.038311 15.803483
v  9.771282 3.244619 14.968008
v  11.854527 3.288471 17.148796
v  11.873477 3.038311 17.133251
v  11.051132 3.244619 16.587124
v  12.778881 3.288471 17.528591
v  12.794312 3.038310 17.509546
v  11.878829 3.244619 17.140224
v  15.786473 3.288471 17.464947
v  15.787985 3.038311 17.440483
v  14.828126 3.244619 17.671083
v  18.252693 3.288471 15.590466
v  18.239332 3.038310 15.569918
v  17.605843 3.244619 16.327017
v  16.696682 1.574277 16.781017
v  11.669738 1.574277 8.810863
v  9.129607 1.262264 9.596537
v  19.236862 1.262264 15.995222
v  16.836802 1.262264 7.435508
v  11.529674 1.262264 18.156244
v  15.530025 2.698143 8.228378
v  15.515811 2.455981 8.248954
v  16.426121 2.655705 8.554731
v  9.482155 2.700249 11.946134
v  9.504065 2.458088 11.958191
v  9.716093 2.657813 11.021597
v  17.951406 2.708088 9.855928
v  17.927727 2.465927 9.863974
v  18.476353 2.665651 10.652122
v  16.486448 2.700249 8.624151
v  16.468325 2.458088 8.641383
v  17.296795 2.657812 9.126978
v  18.853245 2.662079 13.653412
v  18.832493 2.418844 13.641691
v  18.626188 2.619441 14.579099
v  10.382513 2.660620 15.726470
v  10.405518 2.417385 15.720246
v  9.894254 2.617982 14.907902
v  16.686916 2.660586 16.829306
v  16.681751 2.417350 16.806042
v  15.846873 2.617947 17.279617
v  11.108650 2.660620 16.473698
v  11.129631 2.417385 16.462397
v  10.446736 2.617981 15.787899
v  11.940582 2.660620 16.999846
v  11.957833 2.417385 16.983404
v  11.121030 2.617981 16.513241
v  18.455061 2.671387 10.743438
v  18.431479 2.428152 10.746876
v  18.842340 2.628749 11.614336
v  18.942219 2.664412 12.621988
v  18.919638 2.421178 12.614373
v  18.893011 2.621775 13.573845
v  13.885182 2.661455 17.551373
v  13.895031 2.418220 17.529671
v  12.943106 2.618817 17.406660
v  17.301764 3.067217 9.131106
v  17.379223 3.285584 9.036252
v  16.556295 3.080173 8.490158
v  9.472780 3.067217 13.915108
v  9.357077 3.285584 13.955228
v  9.771198 3.080172 14.851850
v  13.567822 3.067218 8.013306
v  13.545190 3.285584 7.892952
v  12.597336 3.080173 8.170443
v  9.425628 3.067218 11.999719
v  9.303816 3.285584 11.987114
v  9.299673 3.080173 12.974743
v  11.610988 3.067218 8.717241
v  11.539776 3.285584 8.617612
v  10.796928 3.080173 9.268463
v  18.925522 3.026519 13.517178
v  19.045135 3.244619 13.533907
v  19.090906 3.038311 12.547797
v  16.773947 3.026518 16.832994
v  16.840391 3.244619 16.933853
v  17.611025 3.038311 16.316900
v  12.549531 3.067218 8.259056
v  12.501441 3.285583 8.146431
v  11.635878 3.080173 8.622066
v  15.451311 3.067218 8.154438
v  15.481544 3.285583 8.035767
v  14.505076 3.080173 7.887648
v  14.507791 3.067217 7.991535
v  14.511683 3.285583 7.869135
v  13.526270 3.080172 7.935383
v  10.200034 3.067218 10.078189
v  10.095049 3.285583 10.015140
v  9.672665 3.080173 10.907899
v  9.704233 3.067218 11.009747
v  9.588048 3.285584 10.971045
v  9.369982 3.080172 11.934308
v  18.166225 3.026518 15.469007
v  18.267311 3.244619 15.535106
v  18.728874 3.038311 14.662486
v  13.935419 3.026519 17.586384
v  13.930625 3.244619 17.707066
v  14.916417 3.038311 17.654903
v  18.974445 3.026518 12.564055
v  19.095001 3.244619 12.556772
v  18.944645 3.038310 11.581120
v  10.787163 3.067218 9.383179
v  10.697426 3.285584 9.299852
v  10.190947 3.080173 9.982688
v  12.595585 2.445492 8.384692
v  12.548827 2.657813 8.275185
v  11.707228 2.458088 8.737651
v  9.558169 2.445492 12.021791
v  9.439730 2.657813 12.009536
v  9.435703 2.458088 12.969821
v  17.877655 2.445492 9.901192
v  17.968565 2.657813 9.824292
v  17.279015 2.458088 9.155947
v  9.488349 2.445492 12.951690
v  9.370068 2.657813 12.965391
v  9.574234 2.458088 13.903731
v  10.311136 2.445492 10.153461
v  10.209058 2.657813 10.092156
v  9.798367 2.458088 10.960198
v  15.419956 2.445492 8.275133
v  15.447338 2.657812 8.159253
v  14.495539 2.458088 8.031800
v  11.978904 2.410292 16.924763
v  11.931232 2.622354 17.032084
v  12.840691 2.421758 17.338970
v  13.943104 2.405919 17.453787
v  13.938442 2.617982 17.571127
v  14.896942 2.417385 17.520409
v  18.878113 2.405920 12.571315
v  18.994490 2.617982 12.555593
v  18.778618 2.417385 11.620342
v  18.089806 2.405919 15.420444
v  18.193295 2.617982 15.475949
v  18.566839 2.417385 14.591779
v  17.439606 2.405919 16.158833
v  17.525146 2.617981 16.239288
v  18.115318 2.417385 15.482327
v  10.882010 2.445492 9.477693
v  10.794758 2.657813 9.396672
v  10.302299 2.458088 10.060603
v  9.604017 2.445492 13.884151
v  9.491516 2.657812 13.923161
v  9.894173 2.458088 14.794959
v  13.585685 2.445492 8.145744
v  13.563679 2.657813 8.028724
v  12.642066 2.458088 8.298531
v  14.499629 2.445492 8.124577
v  14.503413 2.657813 8.005566
v  13.545282 2.458088 8.069979
v  11.683025 2.445492 8.830192
v  11.613786 2.657813 8.733321
v  10.891504 2.458088 9.366152
v  16.708775 2.405920 16.732386
v  16.775913 2.617982 16.828733
v  17.509323 2.417385 16.209538
v  14.796174 2.405919 17.452229
v  14.820724 2.617981 17.567066
v  15.736787 2.417385 17.280495
v  18.467155 3.075279 10.659827
v  17.520777 3.026518 16.241282
v  11.934175 3.026518 17.032875
v  14.810889 3.026519 17.551544
v  9.879007 3.026518 14.913400
v  11.126963 3.026519 16.493118
v  17.967953 3.067218 9.827706
v  9.353819 3.067218 12.956095
v  16.457151 3.067218 8.554008
v  18.833708 3.026518 11.620112
v  18.635038 3.026519 14.582259
v  12.933575 3.026519 17.427158
v  15.834491 3.026518 17.299606
v  10.437106 3.026519 15.792019
v  18.960218 3.211345 13.532375
v  19.631464 4.152992 13.741912
v  18.754560 2.474773 13.646348
v  9.406328 3.211870 12.058703
v  8.735119 4.158299 11.849052
v  9.612003 2.474773 11.944762
v  12.968857 2.406754 17.292086
v  18.732018 2.416687 11.654578
v  18.776426 2.409712 13.559750
v  17.226732 2.445491 9.223257
v  18.372383 2.453331 10.710165
v  16.377243 2.443385 8.663310
v  9.829062 2.445492 11.059228
v  15.798623 2.405884 17.172556
v  18.514324 2.407378 14.543365
v  10.536628 2.405919 15.712335
v  9.998999 2.405919 14.854805
v  11.187770 2.405919 16.416616
v  9.653370 3.329229 10.890892
v  13.531716 3.329229 7.910247
v  9.347450 3.329229 11.921906
v  14.515823 3.329229 7.864280
v  11.630217 3.329229 8.596977
v  18.746229 3.288471 14.679793
v  14.910480 3.288471 17.678680
v  18.968899 3.288471 11.584651
v  10.175141 3.329229 9.962562
v  16.575893 3.329230 8.473501
v  10.785997 3.329229 9.245181
v  12.597225 3.329229 8.144722
v  9.745776 3.329229 14.855747
v  9.274990 3.329230 12.967519
v  17.620062 3.288472 16.339684
v  19.113981 3.288471 12.556061
v  14.099373 3.842238 12.164147
v  10.880877 2.700250 9.343515
v  13.550578 2.700249 8.045540
v  12.641959 2.700250 8.273523
v  9.869454 2.700249 14.798750
v  15.736922 2.660620 17.304325
v  17.518682 2.660620 16.231453
v  18.398745 2.474773 10.847073
v  19.142067 4.333609 10.369286
v  18.333122 2.474819 10.713209
v  18.893097 1.423590 10.557447
v  9.969480 2.474773 14.748613
v  9.475382 1.423590 15.038690
v  10.035199 2.474818 14.882442
v  9.226577 4.334559 15.227082
v  9.779606 2.700250 10.943664
v  17.300711 2.700249 9.143511
v  14.505590 2.700250 8.008902
v  9.411702 2.700250 12.962797
v  9.549282 2.700249 13.902075
v  11.701722 2.700250 8.713257
v  12.826787 2.664993 17.358326
v  18.128990 2.660620 15.501845
v  14.891171 2.660620 17.543530
v  18.802389 2.660620 11.622025
v  18.585108 2.660620 14.607080
v  10.286932 2.700250 10.041035
v  15.530053 2.474773 8.352362
v  15.666726 5.232154 7.471995
v  15.427304 2.490995 8.169205
v  15.642490 1.423590 7.789986
v  12.839294 2.474773 17.240419
v  12.727221 1.423590 17.802876
v  12.942150 2.490995 17.423531
v  12.703186 5.233304 18.120920
v  11.776025 2.474773 8.823159
v  11.263519 5.221920 8.102088
v  11.649164 2.474819 8.900047
v  11.432510 1.423590 8.362921
v  16.601604 2.474773 16.762108
v  17.116129 5.210707 17.481789
v  16.728237 2.474819 16.684963
v  16.946415 1.423590 17.221437
v  15.683714 2.474773 17.200661
v  15.919235 1.423590 17.723043
v  15.870369 2.490995 17.286348
v  16.029440 5.245816 18.017735
v  12.694568 2.474773 8.386826
v  12.351077 5.250904 7.568756
v  12.508259 2.490995 8.300636
v  12.460499 1.423590 7.863769
v  11.905375 2.474773 16.862289
v  11.672998 1.423590 17.386700
v  12.034638 2.474819 16.926918
v  11.580762 5.173179 17.686596
v  16.465960 2.474773 8.732128
v  16.791613 5.172174 7.908236
v  16.336826 2.474819 8.667389
v  16.698990 1.423590 8.207991
v  14.556727 2.474773 8.146724
v  14.550094 1.423590 7.569350
v  14.425602 2.490995 7.985377
v  14.510889 5.249036 7.247236
v  13.822446 2.474773 17.446613
v  13.870585 5.250081 18.346104
v  13.953824 2.490995 17.607594
v  13.830597 1.423590 18.024014
v  9.907996 2.474773 10.973055
v  9.075706 4.557962 10.691155
v  9.854264 2.474819 11.111301
v  9.361911 1.423590 10.800106
v  18.458212 2.474773 14.619345
v  19.290466 4.547600 14.901339
v  18.511951 2.474819 14.481105
v  19.004274 1.423590 14.792357
v  8.062120 0.001522 3.604807
v  18.675293 3.228250 12.618930
v  9.691113 3.228304 12.972920
v  10.483227 2.474773 15.622534
v  9.854199 4.742157 16.251907
v  10.569228 2.474819 15.738777
v  10.059994 1.423590 16.013802
v  17.879759 2.474773 9.964618
v  18.508057 4.744503 9.334458
v  17.793655 2.474819 9.848513
v  18.302532 1.423590 9.572813
v  10.959417 2.474773 9.413464
v  10.524885 1.423590 9.032093
v  10.858541 2.474819 9.506847
v  10.304262 5.131769 8.814960
v  17.402784 2.474773 16.182184
v  18.057232 5.117912 16.781458
v  17.503859 2.474818 16.088875
v  17.836872 1.423590 16.564047
v  10.373438 2.474773 10.082916
v  9.619797 4.941138 9.621890
v  10.298811 2.474819 10.198115
v  9.876903 1.423590 9.791637
v  18.003847 2.474773 15.492723
v  18.501581 1.423590 15.781762
v  18.078102 2.474819 15.376884
v  18.759375 4.920212 15.950368
v  9.459120 3.855536 12.982060
v  13.484196 2.474819 8.169636
v  13.497522 1.423590 7.584607
v  13.596082 2.490951 8.002069
v  13.400091 5.251894 7.277158
v  14.871605 2.474819 17.424088
v  14.953763 5.251199 18.316807
v  14.759527 2.490951 17.591288
v  14.857081 1.423590 18.009029
v  11.030087 2.490951 16.455935
v  10.628250 5.040318 17.081047
v  11.224419 2.474819 16.425243
v  10.785889 1.423590 16.809761
v  17.349758 2.490950 9.147739
v  17.753881 5.036695 8.523900
v  17.155437 2.474819 9.177505
v  17.595203 1.423590 8.794676
v  14.362870 3.842433 12.792631
v  18.927971 2.490995 13.526914
v  19.327637 1.423590 13.697245
v  9.438574 2.490996 12.064169
v  9.038937 1.423590 11.893765
v  12.128635 2.562928 16.589872
v  16.237795 2.562928 9.001999
v  17.223719 4.755018 9.172341
v  13.605937 3.842885 12.817878
v  11.457760 4.020800 14.122200
v  13.983685 4.354166 15.854101
v  18.735947 2.474773 11.760302
v  19.046282 3.199068 11.684793
v  18.841541 2.490995 11.589226
v  19.282175 1.423590 11.582392
v  9.626070 2.474773 13.814624
v  9.315408 3.199213 13.888966
v  9.519740 2.490995 13.985071
v  9.079077 1.423590 13.990409
v  18.079481 4.662291 15.463520
v  5.682348 0.001522 19.844189
v  20.304342 0.001522 21.986952
v  6.284950 0.001522 5.078305
v  23.513493 0.001522 18.702669
v  22.684126 0.001522 5.747565
v  9.284095 0.001522 22.692451
v  19.082378 0.001522 2.899301
v  18.873421 3.217186 11.581456
v  19.565752 4.002114 11.453132
v  9.508329 2.474819 13.043452
v  9.185682 3.202240 12.922344
v  9.357640 2.490950 12.915675
v  8.930989 1.423590 12.973986
v  9.487827 3.217307 13.992721
v  8.794947 3.996847 14.118517
v  18.857510 2.474819 12.534104
v  19.180658 3.202278 12.653937
v  19.008713 2.490951 12.661138
v  19.435177 1.423590 12.601592
v  18.899805 2.490951 13.671007
v  9.466763 2.490951 11.920078
v  9.327316 3.222885 13.050474
v  8.622475 3.914082 12.991817
v  19.038509 3.222887 12.526522
v  19.743647 3.911838 12.582827
v  9.255636 3.817778 12.990084
v  12.603785 4.925629 8.370710
v  19.110769 3.817507 12.601768
v  12.735145 2.562928 8.738757
v  15.631269 2.562928 16.853117
v  8.851513 3.760857 13.006004
v  19.527452 3.777594 12.585348
v  17.335550 3.918089 12.676682
v  15.009032 4.270902 9.895987
v  9.753492 4.673143 9.763288
v  18.612923 4.665738 15.828600
v  18.531242 2.490950 10.783236
v  9.837041 2.490950 14.812572
v  17.562731 2.562928 10.113785
v  10.803679 2.562928 15.478127
v  18.166161 2.562928 14.424653
v  15.199617 -0.114743 18.765160
v  10.200244 2.562928 11.167249
v  9.825079 3.224299 13.845836
v  18.541351 3.224382 11.746048
v  20.101242 1.262264 13.663136
v  13.939341 1.262264 18.772118
v  12.387361 4.953228 7.762722
v  15.979058 4.950742 17.829170
v  15.362305 2.562928 8.660297
v  13.004141 2.562928 16.931597
v  18.374001 4.513610 9.470094
v  9.992413 4.513665 16.121826
v  16.478802 2.562928 16.435568
v  11.887619 2.562928 9.156309
v  11.571424 4.910559 8.653974
v  16.794994 4.905388 16.937918
v  13.891730 4.942470 17.501263
v  16.059145 5.065912 17.797901
v  15.839137 4.870290 17.197586
v  15.570967 5.054902 7.644482
v  15.400988 4.860554 8.259362
v  14.455606 5.071365 7.438711
v  14.418969 4.877231 8.079521
v  12.798833 5.055486 17.948357
v  12.968411 4.858148 17.333355
v  13.925303 5.071886 18.154408
v  13.960202 4.875174 17.513426
v  12.320870 5.068447 7.788544
v  12.539252 4.856793 8.389490
v  17.101929 5.028081 17.266636
v  16.757097 4.835505 16.729643
v  14.969198 5.073342 18.114563
v  14.876594 5.010603 17.465042
v  13.385038 5.073639 7.479435
v  13.479116 5.007822 8.128695
v  14.487055 4.940587 7.885787
v  13.879362 4.941093 17.706129
v  16.661644 4.999737 8.055742
v  16.361757 4.808944 8.620272
v  11.710568 5.000470 17.538937
v  12.009768 4.808344 16.974066
v  9.771729 2.490950 10.917484
v  18.594471 2.490950 14.674928
v  11.277115 5.033662 8.317362
v  11.620431 4.809272 8.855289
v  11.570910 2.490995 8.775422
v  13.963021 2.474819 17.460064
v  14.416004 2.474818 8.132868
v  12.982462 2.474819 17.282091
v  15.386901 2.474818 8.310616
v  16.806839 2.490995 16.809376
v  12.557564 2.474818 8.439469
v  15.820687 2.474819 17.147659
v  13.813249 2.490951 17.594143
v  14.566326 2.490950 7.999234
v  11.964514 2.490995 17.056738
v  16.407110 2.490995 8.537647
v  12.798981 2.490951 17.381857
v  15.570459 2.490950 8.210949
v  12.645263 2.490950 8.247990
v  11.697771 2.490951 8.698534
v  17.993740 4.936149 16.581877
v  17.541656 4.754770 16.126764
v  15.733397 2.490950 17.339350
v  15.697415 5.004569 17.240482
v  16.680208 2.490950 16.886520
v  15.542122 4.986164 8.312105
v  12.827256 4.983645 17.280685
v  16.623608 4.972430 16.797939
v  10.754818 2.490995 9.401184
v  12.680975 4.991516 8.346964
v  11.835250 2.490950 16.992107
v  16.536243 2.490951 8.602386
v  10.855696 2.490951 9.307801
v  11.575347 3.178279 8.666071
v  9.363682 3.178279 11.992543
v  9.413618 3.178279 13.934817
v  13.557046 3.178279 7.951884
v  17.341793 3.178279 9.083289
v  17.607452 2.490995 16.194654
v  17.506374 2.490950 16.287970
v  18.986317 3.137445 13.526449
v  16.807140 3.137445 16.884766
v  11.754127 4.944904 8.787271
v  18.495028 3.199356 10.635181
v  9.873366 3.199138 14.960612
v  10.367388 2.490950 15.713909
v  17.995487 2.490950 9.873090
v  16.764889 3.161205 16.990040
v  15.786439 3.161205 17.452679
v  18.622196 3.161205 10.662650
v  18.245356 3.161205 15.580633
v  12.785975 3.161205 17.518579
v  11.863491 3.161205 17.140419
v  10.285055 3.161205 15.805923
v  11.026123 3.161205 16.575939
v  19.027529 3.161205 13.683691
v  13.872110 3.161205 17.711100
v  10.251589 2.490951 9.998775
v  18.067680 3.210586 9.764481
v  15.599255 3.202524 8.079414
v  17.390219 3.202524 9.035590
v  9.429384 3.202524 13.933599
v  14.559985 5.008599 8.104630
v  18.126032 2.490950 15.576340
v  10.780994 4.881527 16.961632
v  11.190338 4.705801 16.466610
v  13.819306 5.006044 17.488722
v  17.600842 4.878295 8.642753
v  17.189672 4.703026 9.136263
v  10.368057 4.941994 9.014418
v  10.820700 4.718050 9.468999
v  13.890608 1.574277 17.519369
v  14.475806 1.574277 8.072530
v  13.464172 2.490995 8.022753
v  13.616105 2.474773 8.148951
v  14.891296 2.490995 17.571020
v  17.924423 2.553479 9.862601
v  9.497939 2.553479 12.014814
v  15.434619 2.553479 8.216299
v  9.428038 2.553479 12.957932
v  10.259541 2.553479 10.121612
v  12.572457 2.553479 8.328644
v  10.838017 2.553479 9.435975
v  14.739836 2.474773 17.444355
v  18.142147 2.513775 15.449356
v  11.953956 2.518147 16.979107
v  13.939992 2.513775 17.513502
v  17.482653 2.513774 16.200335
v  18.937466 2.513775 12.564043
v  11.884871 4.922723 16.899153
v  16.486511 4.923096 8.695289
v  18.654242 4.742813 15.764660
v  18.038496 4.733587 15.517033
v  10.176962 2.490995 10.113976
v  16.683584 2.536842 16.817852
v  18.443392 2.547644 10.745916
v  11.948668 2.536877 16.991076
v  10.393803 2.536877 15.722619
v  18.842501 2.538335 13.648228
v  11.118766 2.536876 16.467375
v  13.889400 2.537712 17.540215
v  18.930693 2.540669 12.618913
v  10.146971 3.178279 10.045433
v  9.645315 3.178279 10.989319
v  15.467443 3.178279 8.094200
v  14.510532 3.178279 7.929235
v  12.525744 3.178279 8.201412
v  10.741919 3.178279 9.340274
v  16.477911 2.577052 8.633337
v  17.939804 2.584891 9.860688
v  15.523548 2.574946 8.239117
v  9.493494 2.577053 11.951489
v  18.217278 3.137445 15.503299
v  19.035873 3.137445 12.561107
v  13.932219 3.137445 17.647800
v  18.200289 2.490995 15.460499
v  17.254042 2.474773 9.261041
v  16.322586 4.310267 14.992461
v  11.125383 2.474773 16.342279
v  17.251165 2.490995 9.064214
v  11.129107 2.490995 16.538887
v  9.943268 4.574073 16.055351
v  10.527677 4.485163 15.772217
v  18.419197 4.576068 9.531105
v  17.835169 4.485695 9.815022
v  18.385239 2.562928 11.783658
v  9.981192 2.562928 13.808228
v  17.432018 4.893350 16.212753
v  9.724245 4.755199 9.807851
v  10.338890 4.698048 10.058459
v  15.839410 4.419706 15.433203
v  9.718000 2.490995 11.055736
v  18.648211 2.490995 14.536688
v  11.648373 2.553479 8.780437
v  9.546492 2.553479 13.903316
v  13.575207 2.553479 8.086023
v  14.502295 2.553479 8.064003
v  16.742352 2.513775 16.781864
v  14.807951 2.513775 17.510853
v  17.909384 2.490995 9.756987
v  18.932045 3.208890 13.676466
v  10.453389 2.490995 15.830154
v  9.434525 3.209498 11.914616
v  10.930150 4.853135 9.382930
v  11.124785 3.870823 12.910581
v  14.747580 4.877128 17.498487
v  11.397713 3.895053 11.656975
v  13.608242 4.875764 8.094841
v  19.153332 4.397234 14.736501
v  18.496956 4.428158 14.635728
v  18.122526 4.589030 15.406680
v  9.778421 3.217393 12.047600
v  18.587978 3.216996 13.544299
v  9.212858 4.401453 10.855980
v  9.869251 4.397727 10.956675
v  9.350904 4.205152 15.053531
v  9.987577 4.176971 14.906038
v  11.097793 4.801076 16.374453
v  19.017599 4.203858 10.542715
v  18.380768 4.172966 10.689653
v  17.281755 4.797571 9.228975
v  10.254509 4.558763 10.168131
v  17.354689 4.756043 9.016265
v  11.011672 4.756167 16.575575
v  10.449814 4.537994 15.648221
v  17.913143 4.538337 9.938886
v  18.465618 2.490995 10.649365
v  9.902761 2.490995 14.946402
v  12.214798 4.265912 15.142332
v  18.561386 4.295450 14.500692
v  9.804830 4.270123 11.091722
v  19.465303 4.046168 13.612436
v  18.795963 4.097353 13.653916
v  15.169903 3.835139 12.746755
v  8.901260 4.048332 11.978554
v  9.570604 4.082164 11.937187
v  9.931398 4.201565 14.766406
v  18.436844 4.197002 10.829317
v  8.990362 3.940929 14.117241
v  9.611692 3.914160 13.963598
v  19.370344 3.945391 11.455065
v  18.749681 3.915041 11.611043
v  20.174438 -0.114743 13.673865
v  13.166855 -0.114743 6.826593
v  8.192033 -0.114743 11.917904
v  8.352898 -0.114743 14.430652
v  20.013577 -0.114743 11.161099
v  13.936324 -0.114743 18.846031
v  15.196691 3.987573 13.844859
v  18.835314 3.998433 13.510649
v  8.424127 1.262264 14.410680
v  20.152233 1.262264 12.413744
v  14.427131 1.262264 6.819634
v  15.187200 1.262264 18.692230
v  8.265228 1.262264 11.928631
v  9.905220 1.262264 8.615741
v  18.461246 1.262264 16.976015
v  17.893297 1.262264 8.104351
v  10.473176 1.262264 17.487400
v  9.531231 3.986804 12.080452
v  19.552368 3.848356 12.500035
v  18.911020 3.832050 12.531389
v  8.813406 3.849521 13.075390
v  9.454814 3.831678 13.046004
v  10.119120 4.663730 10.013611
v  18.247295 4.656550 15.578277
v  9.781781 3.121415 14.964628
v  14.828286 3.121415 17.660055
v  11.885361 3.121415 17.131336
v  11.059320 3.121415 16.579733
v  17.599401 3.121415 16.318064
v  18.565977 3.170996 10.609390
v  15.714016 5.049416 7.686701
v  12.655766 5.049993 17.906212
v  18.777122 3.936345 11.750806
v  18.831814 2.508956 11.616383
v  18.882698 2.501981 13.570900
v  12.947072 2.499023 17.396696
v  9.584854 3.935732 13.823962
v  9.583817 2.474819 12.088853
v  18.782724 2.474819 13.502255
v  11.581437 4.988375 17.473869
v  16.790642 4.987627 8.120920
v  9.663507 2.474819 13.950672
v  18.697922 2.474819 11.624161
v  14.755879 3.902691 11.734085
v  9.440938 1.574277 12.982777
v  18.925468 1.574277 12.609072
v  9.386602 4.166720 15.126274
v  17.235420 1.574277 9.158398
v  11.130938 1.574277 16.433441
v  15.922009 5.066897 17.850601
v  18.402189 4.189763 10.746164
v  9.244154 4.353892 10.776485
v  9.600004 3.924824 13.900059
v  16.975105 5.034267 17.343525
v  9.482302 2.490951 13.849022
v  18.879564 2.490951 11.725362
v  12.458038 5.069071 7.736201
v  14.596253 5.069395 7.453022
v  13.784802 5.069973 18.140505
v  11.404167 5.039442 8.240733
v  12.570973 4.134877 10.236515
v  10.028936 4.619010 16.171808
v  18.333427 4.620908 9.414793
v  9.505768 2.474773 12.909939
v  17.426908 3.187179 9.003661
v  9.381392 3.187179 13.941497
v  9.620708 3.187179 10.875784
v  14.520912 3.187179 7.828655
v  11.612614 3.187179 8.565590
v  13.528992 3.187179 7.874364
v  9.312284 3.187179 11.914265
v  15.619059 3.187179 8.034990
v  10.146652 3.187179 9.940640
v  18.902931 3.886144 12.666048
v  10.866865 1.574277 9.409095
v  17.499550 1.574277 16.182787
v  19.073551 3.145175 13.698848
v  18.668325 3.145174 10.647817
v  10.990277 3.145174 16.608543
v  19.004822 3.145174 11.578263
v  13.862479 3.145175 17.758587
v  14.913249 3.145175 17.715063
v  18.778868 3.145175 14.696104
v  16.784420 3.145175 17.034384
v  18.860596 2.474773 12.667329
v  9.463432 3.885074 12.911090
v  15.875170 3.121415 17.401697
v  18.939465 3.121415 11.590228
v  12.907900 3.121415 17.534014
v  10.353220 3.121415 15.863014
v  18.738129 3.121415 14.620338
v  18.560640 3.202426 10.769055
v  17.892456 4.950588 16.674940
v  9.782511 4.165193 14.933922
v  18.583923 4.165419 10.658002
v  9.838929 4.334338 11.019503
v  9.807655 3.202204 14.826780
v  9.269955 3.191768 12.035526
v  19.096596 3.191497 13.555525
v  9.360201 2.490995 13.049190
v  13.852014 4.954677 18.147720
v  14.514403 4.954155 7.444196
v  19.005629 2.490995 12.527909
v  9.936204 2.562928 12.074405
v  18.430193 2.562928 13.517492
v  19.122252 4.348628 14.815417
v  13.517087 5.072304 7.459113
v  19.008268 3.197267 11.548651
v  12.631838 2.562132 8.240028
v  13.547928 2.562132 8.010651
v  16.503880 2.562131 8.593813
v  9.447964 2.562132 11.938704
v  9.837816 2.562132 14.813696
v  10.857591 2.562132 9.317398
v  17.979950 2.569971 9.835690
v  15.540879 2.560025 8.195116
v  14.837292 5.072167 18.134472
v  9.352839 3.197438 14.025019
v  16.730949 4.882697 8.090715
v  11.635485 4.882969 17.501162
v  18.888058 2.522749 13.660235
v  16.705126 2.521255 16.859751
v  10.352932 2.521290 15.746055
v  11.923923 2.521290 17.031168
v  11.084311 2.521290 16.499506
v  15.748293 2.521290 17.337929
v  17.542187 2.521290 16.258026
v  18.616611 2.499647 14.574271
v  11.128436 2.498188 16.505484
v  15.844059 2.498154 17.269270
v  9.904463 2.498188 14.904616
v  10.455928 2.498188 15.782373
v  18.911438 3.219073 11.717597
v  9.450397 3.219172 13.856667
v  10.469142 4.956330 8.921284
v  9.188236 3.202785 13.055861
v  19.177584 3.202811 12.520709
v  18.979836 4.166958 10.465652
v  9.324765 3.222329 12.916960
v  19.041588 3.222320 12.659750
v  9.416269 4.251763 15.187536
v  18.579636 4.776750 15.880371
v  18.952330 4.250406 10.408669
v  10.682248 4.859460 16.878422
v  17.699139 4.855776 8.726521
v  9.291791 3.178279 12.962515
v  18.017363 3.178279 9.789663
v  16.485909 3.178279 8.498677
v  11.336462 4.923765 8.281423
v  17.029959 4.918430 17.310469
v  9.799221 4.788769 9.692779
v  18.619431 3.179838 10.575239
v  9.749017 3.179677 15.020668
v  9.713238 3.187179 14.871120
v  9.239004 3.187179 12.967680
v  12.586816 3.187179 8.110274
v  16.595531 3.187179 8.443345
v  10.762049 3.187179 9.218321
v  18.110233 3.195240 9.740923
v  12.766016 3.145175 17.562733
v  10.243021 3.145175 15.830026
v  18.281208 3.145175 15.613227
v  15.795813 3.145175 17.500217
v  19.150457 3.145175 12.556911
v  11.835050 3.145175 17.179649
v  17.643515 3.145175 16.367634
v  17.263004 2.553478 9.174668
v  9.771774 2.553479 11.039365
v  18.425682 2.561317 10.681257
v  16.402805 2.551372 8.608329
v  19.034586 3.121415 13.530682
v  16.835844 3.121415 16.923801
v  19.068422 3.189170 13.699616
v  9.368477 3.162934 13.952927
v  13.545785 3.162934 7.904568
v  11.545207 3.162934 8.627896
v  17.370724 3.162934 9.044191
v  9.315119 3.162934 11.989853
v  18.714584 4.344303 14.648718
v  9.298153 3.189482 11.891438
v  9.651822 4.349404 10.943183
v  17.324844 2.562132 9.118176
v  9.376712 2.562132 12.962954
v  9.747849 2.562132 10.928974
v  14.509934 2.562132 7.974182
v  9.515158 2.562132 13.909810
v  11.684607 2.562132 8.682739
v  10.259232 2.562132 10.019720
v  18.486763 2.532058 10.727515
v  14.893862 2.521290 17.578903
v  18.977695 2.525083 12.622153
v  18.618099 2.521290 14.620130
v  18.836763 2.521290 11.613251
v  12.816223 2.525663 17.392193
v  18.157465 2.521290 15.523006
v  13.881449 2.522125 17.586651
v  19.099215 4.443978 14.874614
v  13.940547 2.498188 17.560612
v  17.518585 2.498188 16.230804
v  11.937072 2.502561 17.023090
v  18.184755 2.498188 15.469458
v  18.983768 2.498188 12.555342
v  17.959028 2.538558 9.830371
v  9.381373 2.538558 12.965611
v  9.450720 2.538558 12.012199
v  15.443283 2.538558 8.169809
v  12.551831 2.538558 8.286087
v  10.217882 2.538558 10.099227
v  10.802075 2.538558 9.405249
v  10.040939 3.262922 10.238001
v  11.401426 3.262922 8.800518
v  13.342680 3.262922 8.000586
v  15.252533 3.262922 8.047958
v  9.583714 3.262922 11.200340
v  9.493373 3.262922 14.139668
v  17.166430 3.262922 8.950729
v  12.326954 3.262922 8.295258
v  9.349273 3.262923 12.211900
v  14.290708 3.262922 7.930495
v  19.009285 3.222461 13.307999
v  16.986330 3.222461 16.757725
v  14.151869 3.222461 17.649008
v  18.331131 3.222460 15.315454
v  19.015137 3.222460 12.342434
v  10.618779 3.262922 9.483675
v  19.408012 3.917344 11.591349
v  8.953280 3.913427 13.981046
v  9.266964 4.448488 10.717857
v  11.421357 1.359509 8.504166
v  16.957163 1.359509 17.080212
v  15.529283 1.359509 7.876716
v  12.840364 1.359509 17.716082
v  9.136406 1.359509 11.995182
v  19.230152 1.359509 13.595848
v  9.616385 1.359509 15.065025
v  18.752106 1.359509 10.530995
v  10.199553 1.359509 16.008610
v  18.163004 1.359509 9.578206
v  9.437773 1.359509 10.922406
v  18.928423 1.359509 14.670046
v  14.462424 1.359509 7.676762
v  13.917868 1.359509 17.916340
v  15.958556 1.359509 17.584387
v  12.420866 1.359509 8.002332
v  12.841904 4.924536 17.502449
v  15.524545 4.924013 8.089454
v  11.801594 1.359509 17.322104
v  16.570347 1.359509 8.272461
v  14.009962 3.843534 12.806504
v  17.813440 1.359509 16.428198
v  10.548532 1.359509 9.167891
v  15.830841 4.937220 17.413864
v  12.535578 4.939627 8.178030
v  18.314137 3.198112 14.485163
v  17.780361 2.635778 9.704703
v  9.624039 2.635778 14.102494
v  13.366776 2.635778 8.133377
v  14.288556 2.635778 8.065227
v  12.379170 2.635778 8.419889
v  9.483929 2.635778 12.228098
v  9.460579 2.635778 13.169182
v  11.479266 2.635778 8.911162
v  10.156446 2.635778 10.308847
v  15.224907 2.635778 8.174986
v  12.151991 2.600809 17.059078
v  17.623571 2.596436 16.039852
v  16.913300 2.596436 16.653841
v  18.901653 2.596436 12.353495
v  14.153561 2.596437 17.514677
v  15.015155 2.596436 17.459087
v  18.236599 2.596436 15.257804
v  10.718287 2.635778 9.575406
v  19.148186 1.359509 11.531985
v  9.212849 1.359509 14.041186
v  10.052269 3.198914 11.106739
v  9.048279 1.359509 13.045518
v  19.317593 1.359509 12.530584
v  18.448446 1.359509 15.650238
v  9.929560 1.359509 9.923208
v  13.437836 1.359509 7.711282
v  14.916948 1.359509 17.882553
v  17.464277 1.359509 8.835789
v  10.916776 1.359509 16.768080
v  14.145793 3.848076 13.430370
v  14.912678 3.161205 17.666611
v  18.737003 3.161205 14.671708
v  17.614811 3.161205 16.328596
v  18.956671 3.161205 11.583671
v  19.102188 3.161205 12.552677
v  15.768712 1.574277 17.238197
v  12.597705 1.574277 8.353678
v  9.359110 3.202524 11.927416
v  13.529768 3.202524 7.922996
v  16.566601 3.202524 8.482445
v  12.598077 3.202524 8.157592
v  9.663552 3.202524 10.898807
v  9.287568 3.202524 12.970370
v  9.758379 3.202524 14.853010
v  10.792189 3.202524 9.256495
v  11.633826 3.202524 8.609358
v  14.511168 3.202524 7.876309
v  10.183618 3.202524 9.972241
v  12.892229 1.574277 17.324123
v  15.474216 1.574277 8.267771
v  18.523426 3.186341 10.632948
v  19.084024 3.121415 12.555700
v  13.932789 3.121415 17.696249
v  18.259142 3.121415 15.527694
v  14.113390 3.884330 14.197916
v  18.544197 1.574277 14.579240
v  9.822207 1.574277 11.012664
v  12.917530 3.137445 17.486526
v  14.818913 3.137445 17.612516
v  18.893337 3.137445 11.605061
v  11.905319 3.137445 17.087183
v  10.389065 3.137445 15.830412
v  15.855639 3.137445 17.357353
v  9.823814 3.137445 14.940525
v  11.087761 3.137445 16.540504
v  17.563549 3.137445 16.285469
v  18.692104 3.137445 14.605183
v  18.054054 3.162934 9.757734
v  16.505714 3.162934 8.454252
v  9.243797 3.162934 12.970411
v  9.598488 3.162934 10.976169
v  14.509756 3.162934 7.880604
v  10.104126 3.162934 10.022411
v  12.504531 3.162934 8.157644
v  15.477186 3.162934 8.046549
v  10.704953 3.162934 9.308674
v  9.948010 1.574277 14.853648
v  18.418423 1.574277 10.738274
v  13.784245 3.862117 13.271378
v  16.286057 3.262922 8.407114
v  9.325259 3.262923 13.179780
v  18.418930 3.270984 10.439543
v  17.874765 3.262923 9.622360
v  18.829720 3.222460 11.394821
v  12.103882 3.222460 17.181103
v  15.035119 3.222461 17.573751
v  16.058157 3.222460 17.272299
v  9.929771 3.222460 15.132934
v  11.263340 3.222460 16.672489
v  18.764404 3.222460 14.397769
v  10.534031 3.222460 15.995434
v  17.714092 3.222460 16.125517
v  13.132015 3.222461 17.533897
v  19.436756 4.085152 13.756422
v  12.833107 2.541249 17.348209
v  18.575489 2.536877 14.600027
v  18.790461 2.536877 11.621953
v  15.736086 2.536877 17.292425
v  17.513300 2.536877 16.220808
v  14.893307 2.536877 17.531794
v  18.121532 2.536876 15.492537
v  11.705233 2.577053 8.725296
v  9.561821 2.577053 13.902132
v  12.642787 2.577053 8.286036
v  9.423932 2.577053 12.965569
v  9.881708 2.577052 14.796087
v  9.789507 2.577053 10.951359
v  13.548684 2.577053 8.057936
v  17.290237 2.577053 9.150406
v  10.886896 2.577053 9.354516
v  14.501268 2.577052 8.020674
v  10.295174 2.577053 10.050446
v  8.929832 4.087622 11.834570
v  14.820158 2.498188 17.556356
v  16.771240 2.498188 16.819080
v  18.571054 2.515234 14.562263
v  11.153180 2.513775 16.465393
v  12.955024 2.514610 17.350260
v  9.945333 2.513775 14.881180
v  18.788443 2.524542 11.634784
v  10.490383 2.513775 15.750242
v  15.822515 2.513740 17.227371
v  18.835697 2.517568 13.567659
v  9.726244 2.538558 11.026579
v  18.465828 2.546397 10.656258
v  17.288973 2.538558 9.135145
v  13.564258 2.538558 8.040017
v  11.619066 2.538558 8.743320
v  9.502601 2.538558 13.920924
v  16.420137 2.536451 8.564325
v  14.501539 2.538558 8.016718
v  17.085583 2.635777 9.055473
v  16.204746 2.633671 8.527963
v  9.711878 2.635778 11.244544
v  18.314566 2.643617 10.498668
v  18.708437 2.607203 11.436763
v  18.629047 2.597895 14.356715
v  11.332957 2.596436 16.580690
v  10.048355 2.596437 15.068262
v  10.633320 2.596436 15.908932
v  16.014112 2.596402 17.133011
v  13.164878 2.597271 17.389938
v  18.854065 2.600229 13.354878
v  18.195000 3.208388 10.846828
v  10.171434 3.208293 14.745096
v  19.555086 3.858305 12.633192
v  11.787802 4.167051 14.686036
v  8.811213 3.860230 12.941944
v  18.296171 3.080686 15.288750
v  16.964880 3.080686 16.719315
v  14.156825 3.080686 17.605293
v  17.685472 3.080686 16.092106
v  16.045568 3.080686 17.230146
v  10.570004 3.080686 15.970110
v  18.786489 3.080685 11.402975
v  9.970360 3.080686 15.115966
v  12.126846 3.080685 17.143579
v  18.971149 3.080686 12.341867
v  11.293392 3.080686 16.640360
v  18.724655 3.080686 14.378919
v  18.966278 3.080686 13.298735
v  15.032042 3.080686 17.529865
v  13.146053 3.080686 17.492205
v  17.135689 3.121402 8.983450
v  12.341678 3.121402 8.337669
v  16.263109 3.121403 8.445702
v  9.536709 3.121402 14.127936
v  9.625221 3.121402 11.217448
v  17.837683 3.121402 9.647671
v  10.077732 3.121402 10.263729
v  9.393501 3.121402 12.219606
v  15.238459 3.121402 8.090590
v  11.424936 3.121402 8.838765
v  14.286172 3.121402 7.975161
v  9.370108 3.121402 13.177719
v  18.377380 3.129464 10.456546
v  13.347898 3.121402 8.045176
v  10.649767 3.121402 9.516103
v  18.683849 3.074327 10.871829
v  16.563223 3.074327 17.073030
v  18.094751 3.074327 15.738353
v  18.975689 3.074327 11.800915
v  19.077824 3.074327 12.769388
v  12.589517 3.074327 17.423914
v  18.954264 3.074327 13.889092
v  13.659511 3.074327 17.662533
v  10.181241 3.074327 15.614142
v  14.694620 3.074327 17.663839
v  15.571514 3.074327 17.489614
v  17.436005 3.074327 16.453436
v  18.622625 3.074327 14.857382
v  11.690125 3.074327 17.008129
v  10.883383 3.074327 16.411070
v  9.303463 3.116011 12.752707
v  17.530571 3.116011 9.202715
v  9.397733 3.116011 13.717665
v  10.965824 3.116010 9.124283
v  9.421797 3.116010 11.718369
v  12.811243 3.116010 8.110797
v  13.748011 3.116010 7.923340
v  15.796995 3.116010 8.171762
v  18.170017 3.124072 9.957243
v  9.680687 3.116010 14.649064
v  9.770211 3.116010 10.708403
v  14.724186 3.116010 7.923774
v  16.739735 3.116010 8.615316
v  11.831858 3.116010 8.517633
v  10.307068 3.116011 9.830925
v  18.685043 3.182764 10.709112
v  9.683306 3.182601 14.886833
v  15.999437 2.458552 17.092833
v  18.667288 2.469355 11.448442
v  10.087821 2.458587 15.051764
v  17.594641 2.458587 16.008345
v  14.158381 2.458587 17.472174
v  12.172167 2.462959 17.021360
v  11.359776 2.458587 16.547367
v  18.200483 2.458587 15.234882
v  16.891478 2.458587 16.617052
v  18.858959 2.458587 12.356105
v  13.177285 2.459422 17.349003
v  18.589363 2.460045 14.340754
v  10.667989 2.458586 15.883877
v  18.812088 2.462379 13.346653
v  15.009295 2.458586 17.416716
v  13.317403 3.766338 12.642986
v  9.504187 2.498176 13.167178
v  17.057512 2.498176 9.088903
v  9.526934 2.498177 12.235592
v  10.192220 2.498176 10.333863
v  12.393486 2.498177 8.461128
v  9.666176 2.498176 14.091088
v  14.284146 2.498177 8.108656
v  11.502126 2.498177 8.948351
v  16.184111 2.496069 8.566430
v  13.371848 2.498177 8.176733
v  17.745155 2.498177 9.730516
v  15.211946 2.498176 8.216670
v  18.275066 2.506015 10.517252
v  9.752237 2.498176 11.261179
v  10.748417 2.498177 9.606936
v  13.778239 3.762706 12.377424
v  17.017376 4.111148 13.950600
v  18.521402 2.463171 10.943083
v  18.910933 2.456197 12.830029
v  18.480347 2.452404 14.789521
v  18.783453 2.453862 13.851877
v  18.824507 2.452404 11.831240
v  16.492693 2.452369 16.910158
v  17.342667 2.452404 16.346684
v  11.771014 2.452404 16.875322
v  10.292863 2.452404 15.536148
v  10.977997 2.452404 16.308807
v  14.681286 2.452404 17.529099
v  13.681344 2.453239 17.499317
v  15.530005 2.452404 17.342335
v  12.637074 2.456776 17.267395
v  17.980478 2.452404 15.650853
v  18.624718 3.187815 14.687252
v  9.741482 3.189042 10.905166
v  9.439387 2.492934 12.753933
v  13.760883 2.492934 8.058270
v  17.432112 2.492934 9.308205
v  9.554444 2.492934 11.748231
v  9.531047 2.492934 13.692175
v  14.709163 2.492934 8.063209
v  11.055725 2.492934 9.225963
v  9.893213 2.492934 10.766226
v  12.850052 2.492934 8.240537
v  11.897781 2.492934 8.636110
v  9.806168 2.492934 14.597789
v  16.653183 2.492933 8.752956
v  18.048794 2.500772 10.042761
v  15.719590 2.490827 8.320335
v  10.415206 2.492934 9.913042
v  9.550936 4.032214 12.008952
v  18.806404 3.171397 14.601202
v  9.559799 3.171999 10.991239
v  14.559751 4.875695 8.092264
v  13.819573 4.873434 17.501087
v  15.702542 4.872005 17.251631
v  15.544436 4.855059 8.300041
v  12.824948 4.852826 17.292749
v  12.675880 4.860399 8.335802
v  16.631067 4.842326 16.807720
v  15.834003 5.002511 17.186440
v  14.419189 5.010062 8.091887
v  13.959949 5.007738 17.501060
v  15.398670 4.991957 8.271427
v  12.970721 4.989243 17.321287
v  12.544355 4.987324 8.400651
v  13.476480 4.875312 8.116635
v  13.610859 5.008556 8.106907
v  14.879201 4.877795 17.477110
v  14.744990 5.010076 17.486412
v  16.749640 4.964827 16.719858
v  11.746692 4.817890 8.777470
v  16.491417 4.797063 8.684029
v  11.879978 4.796749 16.910416
v  16.356859 4.935859 8.631535
v  12.014651 4.935166 16.962795
v  17.441374 4.769400 16.220865
v  11.627861 4.935272 8.865095
v  12.736420 4.326806 15.489335
v  14.711420 3.853865 12.530413
v  13.706888 3.762807 12.472281
v  17.532310 4.877266 16.118645
v  10.920782 4.733665 9.374830
v  18.678463 3.190792 14.549016
v  9.687744 3.191942 11.043410
v  11.090593 4.684783 16.384516
v  11.197535 4.823819 16.456553
v  17.288990 4.681561 9.218935
v  17.182438 4.820806 9.146294
v  10.830052 4.835948 9.477110
v  13.372924 3.743653 11.987906
v  18.049194 4.622103 15.523149
v  18.907288 3.857363 12.609788
v  16.591877 4.150309 10.890943
v  13.530939 3.313884 7.861615
v  9.300623 3.313884 11.908755
v  15.626202 3.313884 8.024252
v  16.604824 3.313884 8.434402
v  9.700635 3.313884 14.873858
v  17.437914 3.313884 8.996936
v  9.226426 3.313884 12.964828
v  9.368494 3.313884 13.941439
v  18.122400 3.321945 9.736647
v  11.609002 3.313884 8.553209
v  10.755857 3.313884 9.207007
v  12.585964 3.313884 8.097406
v  14.525566 3.313884 7.816628
v  9.610524 3.313884 10.867870
v  10.138175 3.313884 9.930961
v  13.857793 3.272441 17.769924
v  16.787134 3.272441 17.046349
v  15.795848 3.272441 17.512486
v  14.911052 3.272441 17.727133
v  19.017052 3.272441 11.579244
v  11.826087 3.272441 17.188026
v  17.648764 3.272441 16.378721
v  18.288546 3.272441 15.623059
v  19.162251 3.272441 12.560293
v  19.084259 3.272441 13.704836
v  18.788095 3.272441 14.704188
v  10.231410 3.272441 15.833988
v  12.758924 3.272441 17.572742
v  18.680506 3.272441 10.646357
v  10.979795 3.272441 16.614916
v  17.911362 3.247577 9.590317
v  18.461414 3.255639 10.415853
v  11.371166 3.247577 8.762432
v  9.448282 3.247577 14.157917
v  13.331273 3.247577 7.953298
v  17.195244 3.247577 8.911538
v  9.300696 3.247577 12.209357
v  9.998020 3.247577 10.215107
v  9.277286 3.247577 13.187825
v  16.305729 3.247577 8.362625
v  9.536840 3.247577 11.187331
v  12.305605 3.247577 8.251550
v  14.289782 3.247577 7.881861
v  15.262132 3.247577 8.000271
v  10.581728 3.247577 9.452169
v  13.122529 3.206430 17.581421
v  19.057571 3.206430 13.312084
v  18.875809 3.206430 11.379845
v  14.152588 3.206430 17.697462
v  11.235016 3.206430 16.711809
v  9.887807 3.206430 15.157169
v  18.373075 3.206430 15.339725
v  15.044640 3.206430 17.621267
v  17.015156 3.206430 16.796679
v  19.063278 3.206430 12.336879
v  16.077827 3.206430 17.316589
v  12.084056 3.206430 17.225323
v  10.498281 3.206430 16.028152
v  18.810478 3.206430 14.412786
v  17.750050 3.206430 16.158005
v  10.328217 4.590723 10.052296
v  13.531295 3.845830 13.177858
v  9.825563 2.685329 14.816359
v  9.364483 2.685329 12.960181
v  9.502617 2.685329 13.909754
v  11.681095 2.685329 8.670700
v  15.547357 2.683222 8.184376
v  9.436625 2.685329 11.933347
v  13.549822 2.685329 7.998254
v  12.631010 2.685329 8.227516
v  17.991550 2.693168 9.830929
v  10.851570 2.685329 9.306398
v  9.737947 2.685329 10.921279
v  17.335318 2.685329 9.111279
v  14.514256 2.685329 7.962411
v  16.512417 2.685328 8.584628
v  10.250990 2.685329 10.010308
v  15.244351 3.895021 13.275529
v  18.989220 2.648826 12.625228
v  18.848692 2.645034 11.613323
v  15.749130 2.645033 17.349829
v  12.809904 2.649406 17.402309
v  18.164925 2.645033 15.532313
v  11.074195 2.645033 16.505831
v  16.708458 2.644999 16.871204
v  18.627718 2.645034 14.627182
v  13.877232 2.645869 17.597809
v  18.498432 2.655801 10.725035
v  11.915838 2.645033 17.039938
v  10.341643 2.645034 15.749907
v  18.898800 2.646492 13.665419
v  14.891726 2.645034 17.590639
v  17.547571 2.645033 16.268669
v  17.814871 2.620858 9.672363
v  9.666304 2.620858 11.231895
v  10.114715 2.620858 10.286588
v  9.580197 2.620858 14.120239
v  18.354637 2.628696 10.473544
v  9.436697 2.620858 12.225626
v  14.287656 2.620858 8.017939
v  13.355684 2.620858 8.087399
v  12.358412 2.620858 8.377393
v  16.221945 2.618751 8.483904
v  9.413935 2.620858 13.177005
v  11.449844 2.620858 8.874131
v  17.111435 2.620857 9.015865
v  15.233431 2.620858 8.128463
v  10.682261 2.620858 9.544772
v  14.820210 4.945749 17.480579
v  18.674648 2.582308 14.368584
v  18.279274 2.580850 15.277779
v  18.751755 2.591617 11.418226
v  14.154261 2.580850 17.561790
v  11.308332 2.580850 16.620863
v  10.007553 2.580850 15.091825
v  13.157068 2.581685 17.436405
v  10.598959 2.580850 15.941173
v  18.947933 2.580850 12.344650
v  16.942307 2.580850 16.690975
v  17.659603 2.580850 16.070215
v  12.135239 2.585222 17.103119
v  18.901081 2.584643 13.357975
v  15.027503 2.580850 17.504559
v  16.035788 2.580815 17.174850
v  14.998233 3.921851 11.824183
v  18.111820 4.697482 15.400576
v  14.603900 4.361245 15.839291
v  10.265189 4.663186 10.174280
v  14.162929 3.843200 12.601136
v  18.527479 4.362176 14.572403
v  10.497104 4.517341 15.721445
v  10.536688 4.584226 15.763774
v  17.826168 4.584765 9.823479
v  14.249214 3.843516 12.984363
v  9.551573 1.375042 14.993386
v  18.816868 1.375042 10.602682
v  15.625574 1.375042 7.877093
v  12.744080 1.375042 17.715757
v  18.919651 1.375042 14.766064
v  9.446539 1.375042 10.826390
v  11.486049 1.375042 8.433840
v  16.892677 1.375042 17.150658
v  15.882356 1.375042 17.642437
v  12.497156 1.375042 7.944479
v  9.964248 4.192750 14.845762
v  19.238922 1.375042 13.689846
v  9.127651 1.375042 11.901182
v  10.440811 4.442604 15.656672
v  17.922136 4.442964 9.930424
v  10.341688 3.180706 15.734200
v  11.708495 1.375042 17.305386
v  18.021164 3.180654 9.852768
v  16.663391 1.375042 8.289261
v  10.125165 1.375042 15.952919
v  18.237434 1.375042 9.633778
v  19.197836 1.375042 11.610366
v  18.508675 4.340695 14.639347
v  9.163536 1.375042 13.962764
v  18.752653 3.168573 14.739443
v  14.551573 1.375042 7.658678
v  13.828883 1.375042 17.934681
v  18.766430 3.922094 11.691828
v  17.486835 4.825337 16.169804
v  13.158541 4.248411 9.933851
v  9.613538 3.169214 10.852990
v  15.222291 4.387156 15.683005
v  18.424360 1.375042 15.737462
v  9.953941 1.375042 9.836285
v  9.857531 4.313681 10.953059
v  17.769423 1.375042 16.505297
v  10.592402 1.375042 9.090766
v  13.516312 1.375042 7.671801
v  14.838498 1.375042 17.921783
v  9.019861 1.375042 12.963657
v  17.163258 5.208297 17.453072
v  11.216304 5.219792 8.130705
v  19.346336 1.375042 12.612181
v  15.719910 5.230215 7.487825
v  12.649997 5.231352 18.105120
v  14.676910 3.944624 14.181787
v  17.869308 4.516003 9.870471
v  16.080406 5.245443 17.998039
v  12.300097 5.250798 7.588319
v  13.546172 4.943744 8.111307
v  10.838072 1.375042 16.737215
v  17.542763 1.375042 8.867054
v  10.318918 3.158731 15.936247
v  11.532753 5.168585 17.662291
v  16.839575 5.167589 7.932582
v  19.310583 4.529012 14.850008
v  18.043730 3.158654 9.650726
v  9.055593 4.539136 10.742488
v  9.202284 4.316017 15.177286
v  19.166325 4.315103 10.419099
v  14.563178 5.248421 7.252650
v  13.818353 5.249503 18.340841
v  19.642080 4.137510 13.688404
v  8.724498 4.142570 11.902555
v  9.822355 4.724334 16.208614
v  18.539940 4.726734 9.377699
v  18.637760 3.090357 10.886806
v  18.927547 3.090357 11.806471
v  18.580679 3.090357 14.833113
v  18.058794 3.090357 15.705864
v  19.029533 3.090357 12.765304
v  16.543550 3.090357 17.028742
v  13.668997 3.090357 17.615011
v  17.407179 3.090357 16.414482
v  18.908188 3.090357 13.874076
v  14.693900 3.090357 17.615383
v  10.223206 3.090357 15.589907
v  15.561993 3.090357 17.442101
v  11.718449 3.090357 16.968807
v  10.919132 3.090357 16.378351
v  12.609341 3.090357 17.379694
v  16.065239 3.064655 17.274437
v  12.107021 3.064655 17.187799
v  18.770729 3.064655 14.393935
v  14.157545 3.064655 17.653749
v  10.534255 3.064655 16.002829
v  18.338116 3.064655 15.313020
v  17.721430 3.064655 16.124594
v  16.993706 3.064655 16.758270
v  9.928396 3.064655 15.140202
v  15.041563 3.064655 17.577381
v  18.832579 3.064655 11.387999
v  19.019289 3.064655 12.336312
v  19.014565 3.064655 13.302820
v  13.136567 3.064655 17.539726
v  11.265068 3.064655 16.679680
v  18.094873 5.112195 16.746769
v  16.710920 3.131356 8.654509
v  12.822651 3.131356 8.158084
v  18.127531 3.139417 9.980932
v  10.996085 3.131356 9.162368
v  14.714588 3.131356 7.971461
v  13.748937 3.131356 7.971975
v  9.445706 3.131356 13.709619
v  17.493973 3.131356 9.234759
v  11.853208 3.131356 8.561341
v  9.352039 3.131356 12.755249
v  9.813131 3.131356 10.731296
v  9.725776 3.131356 14.630815
v  9.468668 3.131356 11.731378
v  15.777326 3.131356 8.216251
v  10.344119 3.131356 9.862431
v  17.874281 3.106057 9.615625
v  10.034811 3.106057 10.240836
v  9.491619 3.106057 14.146187
v  9.344925 3.106057 12.217065
v  9.578349 3.106057 11.204440
v  12.320328 3.106057 8.293962
v  15.248056 3.106057 8.042903
v  18.419865 3.114119 10.432856
v  16.282780 3.106057 8.401213
v  14.285246 3.106057 7.926527
v  11.394676 3.106057 8.800680
v  9.322134 3.106057 13.185765
v  17.164503 3.106057 8.944259
v  13.336491 3.106057 7.997890
v  10.266697 5.126146 8.849672
v  10.612715 3.106057 9.484597
v  16.725443 4.251027 14.523846
v  18.549662 4.378854 14.497077
v  18.787104 4.906702 15.907331
v  9.591930 4.927720 9.664692
v  18.437670 2.467991 14.769547
v  18.863914 2.471783 12.826934
v  18.478083 2.478758 10.961620
v  18.778225 2.467991 11.840085
v  11.795639 2.467990 16.835150
v  18.737854 2.469449 13.840010
v  11.012358 2.467990 16.276566
v  17.313660 2.467990 16.309551
v  12.653824 2.472363 17.223352
v  13.689155 2.468826 17.452850
v  10.333666 2.467991 15.512584
v  16.471018 2.467956 16.868320
v  14.680586 2.467991 17.481985
v  15.517655 2.467990 17.296864
v  17.944447 2.467990 15.620491
v  13.169476 2.443835 17.395470
v  18.634962 2.444458 14.352623
v  10.047018 2.443000 15.075328
v  18.710608 2.453767 11.429906
v  18.243160 2.443000 15.254856
v  18.905239 2.443000 12.347260
v  11.335152 2.443000 16.587540
v  16.920485 2.443000 16.654184
v  16.021111 2.442966 17.134670
v  10.633627 2.443000 15.916117
v  17.630672 2.443000 16.038708
v  14.159081 2.443000 17.519287
v  12.155416 2.447373 17.065401
v  15.021644 2.443000 17.462187
v  18.859104 2.446793 13.349749
v  13.449175 5.251359 7.269584
v  14.904734 5.250801 18.324224
v  16.627333 2.507854 8.792563
v  12.861143 2.507854 8.286514
v  9.486620 2.507854 12.756405
v  11.085148 2.507854 9.262995
v  9.600019 2.507854 11.760880
v  14.700639 2.507854 8.109732
v  13.761785 2.507854 8.105558
v  9.577692 2.507854 13.684352
v  11.918540 2.507854 8.678608
v  17.397600 2.507854 9.340547
v  9.934944 2.507854 10.788485
v  9.850008 2.507854 14.580044
v  15.702393 2.505747 8.364393
v  18.008722 2.515692 10.067883
v  10.451232 2.507854 9.943675
v  19.579754 3.990924 11.503774
v  8.781164 3.985889 14.067905
v  9.457541 2.483256 13.175001
v  12.372727 2.483256 8.418631
v  9.622334 2.483255 14.108833
v  10.150488 2.483256 10.311604
v  9.479700 2.483256 12.233120
v  9.706663 2.483256 11.248530
v  17.779667 2.483256 9.698175
v  11.472704 2.483256 8.911320
v  14.283246 2.483256 8.061368
v  17.083363 2.483256 9.049295
v  13.360757 2.483256 8.130756
v  18.315138 2.491094 10.492128
v  15.220470 2.483256 8.170148
v  16.201309 2.481149 8.522371
v  10.712392 2.483256 9.576303
v  9.816556 4.350335 11.095334
v  13.757578 3.924110 11.517668
v  10.591652 5.031760 17.050163
v  17.790319 5.027946 8.554996
v  9.998114 4.249734 14.899743
v  8.623289 3.909788 13.041412
v  19.742640 3.907918 12.533337
v  15.623350 4.271490 10.127517
v  18.370224 4.245286 10.695942
v  9.920864 4.132690 14.772697
v  18.447382 4.128586 10.823034
v  17.888140 3.180201 15.332518
v  11.213017 3.920022 13.523817
v  10.478275 3.181288 10.259371
v  18.808245 4.036089 13.654913
v  10.427681 3.177767 15.850451
v  9.558321 4.022620 11.936186
v  17.935070 3.177708 9.736659
v  12.061266 4.037212 10.626215
v  13.299235 3.712770 12.446544
v  15.151268 3.841013 12.516252
v  16.996807 4.048705 11.429626
v  10.232923 3.161537 15.819996
v  18.129826 3.161462 9.766832
v  13.534832 3.849926 13.414381
v  9.351526 3.261868 13.719904
v  9.732235 3.261868 10.681988
v  11.816576 3.261868 8.473970
v  9.257870 3.261868 12.744881
v  10.941501 3.261868 9.084934
v  9.636064 3.261868 14.661264
v  17.568714 3.261868 9.176540
v  15.820542 3.261868 8.131943
v  16.771324 3.261868 8.581521
v  14.738578 3.261868 7.879809
v  13.752568 3.261868 7.877305
v  12.805752 3.261868 8.064864
v  18.212786 3.269930 9.939614
v  9.378983 3.261868 11.700849
v  10.275064 3.261868 9.797579
v  10.846384 3.220448 16.437258
v  18.995272 3.220448 13.908410
v  19.021017 3.220448 11.801381
v  12.565952 3.220448 17.462639
v  18.124329 3.220448 15.772704
v  16.576305 3.220448 17.116432
v  11.659244 3.220448 17.041313
v  18.658718 3.220448 14.884806
v  10.139463 3.220448 15.631734
v  15.574800 3.220448 17.534826
v  14.689629 3.220448 17.708893
v  18.728371 3.220448 10.863314
v  17.458210 3.220448 16.492956
v  13.645157 3.220448 17.705532
v  19.122162 3.220448 12.778820
v  15.740754 2.632647 8.280645
v  16.682020 2.634754 8.718437
v  14.722408 2.634754 8.020226
v  9.856287 2.634754 10.740542
v  13.765315 2.634754 8.013510
v  9.762779 2.634754 14.609651
v  11.032074 2.634754 9.187704
v  18.089445 2.642592 10.023508
v  17.468319 2.634754 9.281518
v  9.395056 2.634754 12.746323
v  12.844712 2.634754 8.195875
v  11.882923 2.634754 8.593656
v  9.486120 2.634754 13.694352
v  9.512815 2.634754 11.731195
v  10.384089 2.634754 9.880619
v  18.563770 2.605247 10.930940
v  18.954206 2.598272 12.838394
v  11.743469 2.594479 16.909729
v  18.868492 2.594479 11.828437
v  10.942341 2.594479 16.334715
v  18.824387 2.595938 13.868219
v  16.507921 2.594445 16.951519
v  10.252242 2.594480 15.553252
v  18.010372 2.594479 15.683241
v  12.616384 2.598852 17.306313
v  14.676433 2.594480 17.572905
v  18.517620 2.594480 14.813043
v  17.365250 2.594479 16.384533
v  15.536155 2.594479 17.385979
v  13.668669 2.595315 17.541531
v  17.688290 3.188890 10.014135
v  10.678123 3.188843 15.577776
v  18.823030 4.056365 13.509644
v  14.351297 3.860277 12.190802
v  9.543515 4.043259 12.081458
v  18.153160 3.168333 15.594885
v  18.738018 3.965413 11.614948
v  9.623373 3.964560 13.959740
v  18.815464 4.045832 13.582949
v  13.412419 3.812701 12.820906
v  10.224539 3.169640 9.980111
v  18.342148 3.151577 15.557558
v  18.788788 3.888354 11.746915
v  9.573169 3.887704 13.827807
v  18.915228 3.841800 12.664554
v  9.451133 3.840904 12.912550
v  10.035496 3.152056 10.016308
v  10.879581 4.787352 9.422081
v  16.687048 4.905794 16.765739
v  14.370691 4.284266 9.765357
v  10.401261 3.010488 15.824623
v  18.973791 3.010488 13.521411
v  13.935989 3.010488 17.634836
v  11.914216 3.010488 17.077028
v  11.098522 3.010488 16.532349
v  15.854022 3.010489 17.343950
v  16.802650 3.010489 16.872032
v  17.556629 3.010488 16.273876
v  18.879837 3.010488 11.605280
v  18.208092 3.010488 15.493403
v  12.923944 3.010489 17.474646
v  9.836973 3.010488 14.937504
v  14.820263 3.010489 17.599083
v  19.022596 3.010488 12.558649
v  18.681061 3.010488 14.597414
v  16.744204 3.054341 16.933460
v  17.582321 3.054341 16.277863
v  10.339136 3.054341 15.779380
v  11.901918 3.054341 17.094021
v  14.915846 3.054341 17.606451
v  18.896494 3.054341 11.586527
v  13.887858 3.054341 17.652950
v  12.814269 3.054341 17.465393
v  18.203478 3.054341 15.537323
v  19.042637 3.054341 12.543565
v  11.073205 3.054341 16.538355
v  15.778611 3.054341 17.392944
v  18.563778 3.054341 10.677363
v  18.687010 3.054341 14.638090
v  18.971638 3.054341 13.661205
v  17.330694 3.051872 9.092007
v  9.657407 3.051872 10.996596
v  18.004644 3.051872 9.795777
v  16.476955 3.051872 8.509584
v  9.377064 3.051872 11.997027
v  15.461055 3.051872 8.106785
v  18.509706 3.059934 10.636270
v  13.556561 3.051872 7.965989
v  12.528317 3.051872 8.215288
v  10.157189 3.051872 10.055167
v  9.427639 3.051872 13.933218
v  9.305826 3.051872 12.963992
v  14.507013 3.051872 7.942904
v  11.580848 3.051872 8.679068
v  10.750197 3.051872 9.351579
v  9.416809 3.095518 11.947458
v  10.827068 3.095518 9.306637
v  9.348238 3.095518 12.977433
v  12.608597 3.095518 8.217758
v  17.341799 3.095518 9.072824
v  14.495333 3.095517 7.935300
v  13.527047 3.095517 7.984016
v  18.012550 3.103579 9.790775
v  9.490143 3.095518 13.927347
v  9.715509 3.095518 10.930920
v  15.571054 3.095518 8.133595
v  11.657091 3.095518 8.665834
v  9.816339 3.095517 14.833739
v  16.527365 3.095518 8.529256
v  11.569916 3.300929 8.655787
v  10.137896 3.300929 10.038161
v  18.027376 3.300929 9.783748
v  9.634875 3.300929 10.984195
v  18.534451 3.308991 10.629254
v  9.402218 3.300929 13.937117
v  9.352380 3.300929 11.989804
v  9.280163 3.300929 12.962289
v  14.512461 3.300929 7.917768
v  13.556451 3.300929 7.940269
v  12.522655 3.300929 8.190200
v  17.350292 3.300929 9.075352
v  16.492495 3.300929 8.489090
v  15.471801 3.300929 8.083418
v  10.227913 3.095518 10.014288
v  10.734392 3.300929 9.331452
v  12.913140 3.260649 17.496645
v  16.811686 3.260649 16.894815
v  11.079573 3.260649 16.547894
v  10.379540 3.260649 15.835976
v  18.996864 3.260649 13.529675
v  18.904308 3.260649 11.603940
v  13.930054 3.260649 17.658615
v  18.225447 3.260649 15.510710
v  15.857891 3.260649 17.368153
v  19.046850 3.260649 12.562180
v  9.813315 3.260649 14.943904
v  14.818751 3.260649 17.623545
v  17.569990 3.260649 16.294422
v  18.701635 3.260649 14.610734
v  11.898787 3.260649 17.096071
v  18.227423 3.170503 15.479050
v  18.898729 3.875558 12.532867
v  9.467112 3.875105 13.044562
v  13.943659 2.390333 17.500896
v  18.775389 2.401100 11.636177
v  16.737663 2.390333 16.769602
v  15.820166 2.390298 17.214455
v  18.823429 2.394126 13.562990
v  17.475540 2.390333 16.189301
v  9.958128 2.390333 14.878242
v  18.924416 2.390333 12.562613
v  11.962020 2.394706 16.968746
v  14.808382 2.390333 17.497732
v  10.502172 2.390333 15.744467
v  12.960905 2.391168 17.338522
v  18.132416 2.390333 15.440545
v  11.163025 2.390333 16.456707
v  18.559879 2.391791 14.555372
v  18.786936 2.434430 13.629684
v  18.872635 2.436764 12.611134
v  10.446387 2.432971 15.696810
v  17.480433 2.432971 16.172321
v  18.524231 2.432971 14.571676
v  15.724580 2.432971 17.234989
v  14.896387 2.432971 17.473299
v  18.388107 2.443739 10.765278
v  18.732315 2.432971 11.629045
v  16.660208 2.432937 16.764141
v  11.164087 2.432971 16.430265
v  11.982578 2.432971 16.943314
v  12.857574 2.437344 17.294987
v  13.902982 2.433806 17.483234
v  18.079384 2.432971 15.451858
v  18.412529 2.438410 10.685167
v  9.441685 2.430572 12.959369
v  9.783532 2.430572 11.046441
v  9.510950 2.430572 12.019175
v  17.252701 2.430571 9.183733
v  10.269477 2.430572 10.131076
v  14.498874 2.430572 8.077291
v  15.428622 2.430572 8.228643
v  17.912262 2.430572 9.868961
v  12.574959 2.430572 8.342135
v  9.560125 2.430571 13.901760
v  16.394575 2.428464 8.619310
v  11.653720 2.430572 8.793076
v  13.574736 2.430572 8.099739
v  10.846067 2.430572 9.446966
v  17.244408 2.473008 9.188179
v  13.546038 2.473008 8.117266
v  12.653015 2.473009 8.344538
v  9.482923 2.473009 12.972437
v  10.920811 2.473009 9.403269
v  9.840026 2.473008 10.982583
v  9.549595 2.473009 11.970978
v  14.486875 2.473008 8.078293
v  9.620899 2.473008 13.896052
v  17.887583 2.480847 9.888973
v  11.727854 2.473009 8.780207
v  9.938065 2.473008 14.777350
v  15.498480 2.470901 8.292954
v  16.442356 2.473008 8.680906
v  17.933958 2.672733 9.856524
v  18.436207 2.680571 10.677120
v  9.761623 2.672733 11.034384
v  9.535408 2.672733 13.905552
v  9.486949 2.672733 12.012151
v  16.408791 2.670626 8.598733
v  11.643091 2.672733 8.770438
v  10.250717 2.672733 10.114540
v  17.270826 2.672732 9.166502
v  15.438673 2.672733 8.205744
v  12.569453 2.672733 8.317741
v  13.574628 2.672733 8.074730
v  14.504169 2.672733 8.052851
v  9.416732 2.672733 12.957712
v  10.338243 2.473008 10.091330
v  10.830700 2.672733 9.427398
v  16.747023 2.633568 16.791517
v  18.580629 2.635027 14.567092
v  12.951056 2.634403 17.360222
v  18.150684 2.633568 15.455847
v  18.798969 2.644336 11.632738
v  11.948116 2.637941 16.988102
v  11.145775 2.633568 16.473150
v  10.481192 2.633568 15.755768
v  18.948187 2.633568 12.564295
v  17.489212 2.633568 16.208818
v  18.846008 2.637361 13.570604
v  13.937887 2.633568 17.524017
v  9.935123 2.633568 14.884465
v  14.808517 2.633568 17.521563
v  15.825331 2.633533 17.237719
v  10.149904 3.171744 10.095308
v  13.753494 3.277213 7.925940
v  11.837926 3.277214 8.517678
v  15.800872 3.277214 8.176432
v  9.306446 3.277214 12.747422
v  9.681154 3.277213 14.643014
v  14.728979 3.277213 7.927496
v  12.817160 3.277214 8.112150
v  17.532116 3.277214 9.208584
v  9.399500 3.277214 13.711859
v  18.170300 3.285276 9.963305
v  10.971761 3.277214 9.123018
v  9.775154 3.277214 10.704881
v  16.742510 3.277214 8.620712
v  9.425854 3.277214 11.713857
v  10.312115 3.277214 9.829084
v  17.429382 3.236478 16.454002
v  18.949196 3.236478 13.893394
v  13.654642 3.236478 17.658007
v  10.181428 3.236478 15.607498
v  18.616772 3.236478 14.860537
v  16.556633 3.236478 17.072144
v  15.565279 3.236478 17.487309
v  14.688909 3.236478 17.660439
v  10.882133 3.236478 16.404541
v  18.972878 3.236478 11.806937
v  18.088371 3.236478 15.740216
v  12.585777 3.236478 17.418419
v  18.682283 3.236478 10.878290
v  11.687568 3.236478 17.001991
v  19.073874 3.236478 12.774735
v  13.677539 3.843254 13.764087
v  9.806622 2.649673 14.591906
v  15.723556 2.647567 8.324705
v  13.766216 2.649674 8.060798
v  9.898019 2.649674 10.762802
v  16.656170 2.649674 8.758044
v  9.442287 2.649674 12.748795
v  17.433805 2.649674 9.313858
v  14.713885 2.649674 8.066747
v  11.903682 2.649674 8.636153
v  12.855803 2.649674 8.241853
v  9.532765 2.649674 13.686529
v  9.558390 2.649674 11.743843
v  18.049374 2.657512 10.048632
v  11.061497 2.649674 9.224734
v  10.420115 2.649674 9.911252
v  18.907188 2.613859 12.835298
v  12.633134 2.614438 17.262270
v  17.974340 2.610065 15.652878
v  18.520451 2.620833 10.949476
v  18.822210 2.610066 11.837281
v  10.976702 2.610065 16.302475
v  15.523806 2.610066 17.340506
v  18.778786 2.611525 13.856351
v  11.768093 2.610065 16.869556
v  18.474945 2.610066 14.793070
v  10.293045 2.610066 15.529687
v  17.336243 2.610066 16.347401
v  16.486246 2.610031 16.909681
v  14.675732 2.610066 17.525791
v  13.676478 2.610901 17.495064
v  11.679375 4.877936 8.826141
v  14.538122 3.955048 11.492807
v  16.424179 4.867967 8.657831
v  12.897180 4.922786 17.306765
v  15.224862 3.856325 12.995314
v  14.388574 3.933901 14.287216
v  14.268441 3.846746 13.428930
v  18.267883 3.149521 15.673392
v  14.991611 3.996640 14.076529
v  17.370991 3.164140 9.122585
v  13.652824 3.860724 13.059789
v  11.008946 3.163918 16.481169
v  13.873374 3.784879 12.286285
v  13.563771 3.811564 11.803888
v  10.110132 3.150045 9.901114
v  14.758637 3.851146 12.901408
v  12.766413 1.406394 17.800350
v  15.603294 1.406394 7.792491
v  11.408144 1.406394 8.393335
v  16.970694 1.406394 17.190975
v  9.045153 1.406394 11.931776
v  19.321419 1.406394 13.659239
v  9.503734 1.406394 15.066072
v  18.864767 1.406394 10.530043
v  19.005211 1.406394 14.753011
v  9.360977 1.406394 10.839451
v  10.091879 1.406394 16.034351
v  18.270634 1.406394 9.552312
v  15.948689 1.406394 17.697283
v  12.431005 1.406394 7.889453
v  14.514279 1.406394 7.579319
v  13.866339 1.406394 18.013943
v  17.362289 3.143894 8.932681
v  11.711428 1.406394 17.390923
v  16.660580 1.406394 8.203733
v  17.852560 1.406394 16.531260
v  10.509256 1.406394 9.064886
v  19.259689 1.406394 11.551816
v  9.101429 1.406394 14.021009
v  11.018466 3.143707 16.670824
v  18.508881 1.406394 15.745447
v  15.469270 4.925373 8.285131
v  9.869478 1.406394 9.827839
v  8.945082 1.406394 13.006627
v  19.420952 1.406394 12.569065
v  13.466650 1.406394 7.603079
v  14.887948 1.406394 17.990656
v  15.155945 3.916360 11.980740
v  17.562094 1.406394 8.784277
v  10.819013 1.406394 16.819939
v  14.267263 4.000247 11.288214
v  15.251410 3.937742 13.561014
v  17.272409 3.162638 9.039055
v  11.107956 3.162446 16.564123
v  15.141502 3.876602 12.260014
v  17.460855 3.145325 9.016200
v  10.919469 3.145109 16.587881
v  17.727722 3.140419 16.317455
v  13.653255 3.785205 12.581381
v  14.515353 3.856722 13.324299
v  17.630457 3.159061 16.218132
v  10.731783 3.160728 9.377736
v  13.577456 3.163305 8.341363
v  14.460369 3.163353 8.321712
v  13.906044 3.163420 17.270187
v  14.788928 3.163243 17.250523
v  14.176500 3.843172 12.996209
v  10.634395 3.141298 9.278526
v  10.832667 3.159755 9.284359
v  17.529377 3.158126 16.311438
v  14.041956 3.813600 12.697875
v  13.484594 3.835049 12.981537
v  12.681346 3.164044 8.588026
v  12.960334 3.164583 17.085243
v  15.406111 3.164510 8.506653
v  15.685068 3.163646 17.003849
v  14.346359 3.845293 12.712666
v  11.942257 4.867021 16.934044
v  13.343017 4.368329 15.737959
v  13.971885 4.024345 11.203191
v  13.886629 3.859296 13.343804
v  14.218647 3.854614 12.159882
v  11.187689 3.264227 8.963252
v  14.022071 3.264227 7.930061
v  9.506542 3.264227 11.457653
v  14.990329 3.264227 7.989522
v  9.909643 3.264227 10.472365
v  18.292974 3.272289 10.202265
v  17.702011 3.264227 9.416637
v  16.042660 3.264227 8.293433
v  13.080289 3.264227 8.058174
v  16.953327 3.264227 8.787168
v  9.588996 3.264227 14.390709
v  9.329697 3.264227 12.479822
v  12.083191 3.264227 8.408152
v  9.364208 3.264227 13.445579
v  10.466815 3.264227 9.657616
v  19.039785 3.223618 13.041141
v  18.754261 3.223618 11.137043
v  17.899940 3.223618 15.931602
v  12.345635 3.223618 17.298143
v  11.476569 3.223618 16.835819
v  14.420435 3.223618 17.652916
v  13.393755 3.223618 17.594196
v  15.299916 3.223618 17.528746
v  10.709463 3.223618 16.198822
v  18.472425 3.223618 15.087027
v  10.057199 3.223618 15.369377
v  18.992205 3.223618 12.074820
v  17.206841 3.223618 16.604368
v  18.855106 3.223618 14.144952
v  16.306730 3.223618 17.170542
v  14.013653 3.847977 12.843134
v  17.324596 3.170469 16.004116
v  19.012281 3.143403 13.306311
v  19.017742 3.143403 12.340186
v  16.989576 3.143403 16.758860
v  14.153845 3.143403 17.651823
v  18.334562 3.143403 15.315205
v  11.398457 3.184341 8.799713
v  9.580838 3.184341 11.201435
v  10.037895 3.184341 10.238443
v  9.491760 3.184341 14.142288
v  15.251233 3.184341 8.045170
v  9.346704 3.184341 12.213592
v  14.288837 3.184341 7.928053
v  13.340326 3.184341 7.998604
v  12.324227 3.184341 8.293832
v  17.166428 3.184341 8.947653
v  10.615889 3.184341 9.483276
v  17.605732 2.637047 9.510460
v  18.180418 2.644885 10.274549
v  14.969011 2.637047 8.122618
v  13.111649 2.637047 8.189371
v  9.636843 2.637047 11.494734
v  14.027357 2.637047 8.064805
v  11.271447 2.637047 9.069391
v  9.498450 2.637047 13.427622
v  15.963441 2.634940 8.427980
v  10.028784 2.637047 10.536723
v  16.869844 2.637047 8.908225
v  9.464894 2.637047 12.488603
v  12.142156 2.637047 8.529659
v  9.717014 2.637047 14.346586
v  10.570531 2.637047 9.744532
v  13.421040 2.598397 17.440781
v  12.393253 2.601934 17.159058
v  14.414692 2.597562 17.518476
v  15.269089 2.597561 17.398083
v  10.172256 2.597562 15.298159
v  17.123747 2.597562 16.499193
v  18.878880 2.601355 13.094901
v  16.249435 2.597527 17.019754
v  11.551504 2.597561 16.723663
v  10.806340 2.597561 16.104553
v  18.354212 2.597562 15.024628
v  18.702236 2.599020 14.106021
v  18.612803 2.608329 11.193745
v  17.797661 2.597561 15.845179
v  18.860195 2.597562 12.095647
v  17.626642 3.139537 16.410759
v  11.041818 3.171556 9.587766
v  12.152764 2.523940 17.062332
v  14.155482 2.519567 17.517414
v  15.017695 2.519567 17.461264
v  18.239901 2.519567 15.257273
v  16.916485 2.519567 16.654863
v  17.626900 2.519567 16.040195
v  18.904016 2.519567 12.351128
v  12.376519 2.559372 8.418504
v  17.780905 2.559372 9.701762
v  9.458498 2.559372 13.171329
v  10.153485 2.559372 10.309278
v  11.476379 2.559372 8.910379
v  14.286737 2.559372 8.062853
v  9.622472 2.559372 14.105042
v  9.481430 2.559372 12.229743
v  15.223597 2.559372 8.172297
v  13.364487 2.559372 8.131452
v  10.715477 2.559372 9.575018
v  10.735279 3.140394 9.185149
v  15.799907 3.196463 8.174044
v  9.398037 3.196463 13.713978
v  18.170967 3.204525 9.960817
v  17.532248 3.196463 9.206013
v  15.037575 3.143403 17.576160
v  12.104483 3.143403 17.184490
v  16.061079 3.143403 17.274115
v  9.928276 3.143403 15.136032
v  10.533238 3.143403 15.998782
v  13.133355 3.143403 17.537067
v  17.717499 3.143403 16.125990
v  18.767708 3.143403 14.396811
v  11.263247 3.143403 16.675928
v  18.831829 3.143403 11.392102
v  18.951874 3.155247 13.892202
v  11.687889 3.155247 17.004904
v  10.881853 3.155247 16.407455
v  15.567630 3.155247 17.489058
v  13.656140 3.155247 17.660524
v  10.180526 3.155247 15.610285
v  18.683744 3.155247 10.875751
v  12.586678 3.155247 17.421206
v  16.559311 3.155247 17.073334
v  18.091299 3.155247 15.740219
v  17.875427 3.184341 9.619356
v  9.323117 3.184341 13.181989
v  16.285391 3.184341 8.404112
v  18.420206 3.192403 10.436743
v  15.722516 2.569052 8.322426
v  16.655619 2.571158 8.755602
v  18.049896 2.578998 10.046183
v  9.556229 2.571159 11.745109
v  11.333115 2.519567 16.584032
v  13.166274 2.520402 17.392979
v  10.046902 2.519567 15.071274
v  16.017056 2.519533 17.134602
v  18.632198 2.521026 14.355592
v  18.856949 2.523361 13.353184
v  10.632590 2.519567 15.912196
v  18.710239 2.530335 11.433945
v  18.521645 2.541852 10.946890
v  18.781315 2.532543 13.855037
v  11.768614 2.531084 16.872356
v  10.976465 2.531084 16.305313
v  18.909424 2.534877 12.833533
v  10.292168 2.531084 15.532397
v  16.488913 2.531050 16.910681
v  13.678009 2.531919 17.497465
v  9.709083 2.559372 11.245608
v  17.085415 2.559372 9.052486
v  18.315662 2.567211 10.495886
v  16.203972 2.557266 8.525076
v  16.488691 3.136764 8.387022
v  11.883116 3.136756 17.207457
v  16.422701 3.155319 8.508840
v  16.617819 3.137565 8.451770
v  11.948957 3.155334 17.085564
v  11.753857 3.137549 17.142818
v  13.751613 3.196463 7.924181
v  10.969198 3.196463 9.122765
v  9.304559 3.196463 12.749174
v  14.727521 3.196463 7.925374
v  12.814942 3.196463 8.110841
v  9.772701 3.196463 10.705667
v  11.835479 3.196463 8.516878
v  9.680185 3.196463 14.645400
v  16.742083 3.196463 8.618174
v  9.423633 3.196463 11.715158
v  10.309732 3.196463 9.829144
v  12.752197 3.133783 17.546053
v  15.617349 3.133737 8.046784
v  17.432253 3.155247 16.454584
v  14.690902 3.155247 17.662584
v  19.076206 3.155247 12.772964
v  18.974812 3.155247 11.804739
v  18.619638 3.155247 14.859926
v  18.462692 3.177057 10.413054
v  13.328919 3.168995 7.951317
v  9.298126 3.168996 12.211049
v  11.368196 3.168995 8.761627
v  9.446671 3.168995 14.160538
v  17.195242 3.168995 8.908463
v  16.305061 3.168995 8.359622
v  9.994975 3.168995 10.215550
v  17.912025 3.168995 9.587313
v  9.275144 3.168996 13.190034
v  14.287911 3.168995 7.879418
v  12.302878 3.168995 8.250124
v  9.533966 3.168995 11.188426
v  15.260833 3.168995 7.997483
v  11.234922 3.127373 16.715248
v  15.047096 3.127373 17.623678
v  12.084658 3.127372 17.228710
v  19.060570 3.127373 13.310396
v  9.886310 3.127372 15.160267
v  17.018404 3.127373 16.797813
v  17.753456 3.127372 16.158478
v  10.578837 3.168996 9.451771
v  16.080750 3.127372 17.318405
v  18.376507 3.127372 15.339476
v  18.877918 3.127373 11.377126
v  19.065884 3.127372 12.334630
v  10.497489 3.127372 16.031500
v  14.154565 3.127373 17.700279
v  13.123869 3.127373 17.584589
v  18.813786 3.127372 14.411828
v  9.895634 2.571159 10.763567
v  14.712432 2.571159 8.064709
v  13.764386 2.571159 8.059088
v  11.059006 2.571159 9.224487
v  9.805679 2.571159 14.594225
v  9.440453 2.571159 12.750498
v  11.901302 2.571159 8.635376
v  9.531343 2.571159 13.688590
v  12.853648 2.571159 8.240580
v  17.433849 2.571159 9.311356
v  10.417797 2.571159 9.911310
v  16.824301 3.153477 16.836990
v  12.634166 2.535457 17.264925
v  15.526200 2.531084 17.342047
v  18.823931 2.531084 11.835011
v  17.339048 2.531084 16.347893
v  14.677670 2.531084 17.527878
v  18.477669 2.531084 14.792238
v  17.977186 2.531084 15.652782
v  11.553527 3.154643 8.747761
v  17.815416 2.544452 9.669422
v  9.434198 2.544452 12.227271
v  10.111755 2.544452 10.287019
v  12.355762 2.544452 8.376007
v  15.232121 2.544452 8.125774
v  9.411852 2.544452 13.179152
v  10.679451 2.544452 9.544384
v  9.663508 2.544452 11.232960
v  9.578630 2.544452 14.122787
v  11.446957 2.544452 8.873348
v  14.285837 2.544452 8.015564
v  18.355736 2.552290 10.470762
v  16.221169 2.542345 8.481015
v  17.111265 2.544451 9.012879
v  13.353395 2.544452 8.085472
v  17.662931 2.503981 16.070559
v  18.903965 2.507774 13.356279
v  18.753559 2.514749 11.415408
v  18.282578 2.503981 15.277246
v  13.158464 2.504816 17.439445
v  18.950298 2.503981 12.342282
v  14.156182 2.503981 17.564529
v  12.136014 2.508354 17.106373
v  11.308491 2.503981 16.624205
v  18.677797 2.505440 14.367460
v  15.030044 2.503981 17.506737
v  10.006098 2.503981 15.094839
v  10.598228 2.503981 15.944437
v  16.038731 2.503947 17.176441
v  16.945492 2.503981 16.691996
v  19.022953 3.139217 11.799183
v  16.578979 3.139217 17.117622
v  18.997948 3.139217 13.907219
v  10.846104 3.139217 16.440174
v  18.729832 3.139217 10.860775
v  13.646655 3.139217 17.708046
v  18.661583 3.139217 14.884197
v  14.691622 3.139217 17.711039
v  14.656590 3.859505 12.414415
v  19.124493 3.139217 12.777048
v  18.127258 3.139217 15.772706
v  12.566853 3.139217 17.465425
v  11.659565 3.139217 17.044224
v  10.138561 3.139217 15.634520
v  17.461079 3.139217 16.493538
v  15.577151 3.139217 17.536572
v  17.568846 3.181118 9.173967
v  9.376760 3.181118 11.702149
v  9.350063 3.181118 13.722023
v  13.750687 3.181118 7.875547
v  9.729782 3.181118 10.682775
v  15.819577 3.181118 8.129557
v  11.814129 3.181118 8.473170
v  14.737120 3.181118 7.877687
v  10.272682 3.181118 9.797639
v  9.255982 3.181118 12.746633
v  9.635094 3.181118 14.663651
v  10.938938 3.181118 9.084679
v  12.803535 3.181118 8.063554
v  16.770897 3.181118 8.578982
v  18.213451 3.189179 9.937128
v  14.577457 3.132379 7.828014
v  13.802585 3.132425 17.765406
v  16.898108 3.134984 16.953802
v  16.771471 3.134597 17.030941
v  11.606920 3.135144 8.553865
v  13.617840 3.855226 12.935413
v  10.942104 2.515497 16.337553
v  17.368053 2.515498 16.385025
v  16.510588 2.515463 16.952518
v  10.251365 2.515498 15.555962
v  11.743990 2.515498 16.912529
v  15.538548 2.515498 17.387518
v  18.826914 2.516956 13.866906
v  12.588012 3.132847 8.086827
v  15.791082 3.132576 17.500347
v  18.564964 2.526265 10.928353
v  14.678371 2.515498 17.574991
v  18.013218 2.515497 15.683145
v  18.956444 2.519291 12.836629
v  12.617414 2.519870 17.308966
v  -0.509594 1.867367 12.105017
v  -21.997673 2.656351 -14.531460
v  2.334370 1.716725 11.330304
v  4.019789 -0.429244 25.213615
v  -23.450470 2.724715 -12.018883
v  -31.314224 -0.401246 -14.634089
v  -0.938922 3.045660 7.988169
v  -22.710312 3.159248 -13.386278
v  -30.833221 -0.583500 -17.574215
v  -1.710878 2.806444 4.927064
v  1.055156 0.075775 25.100685
v  -3.665130 2.790938 7.051064
v  2.128959 1.329881 15.694301
v  -15.397823 3.426280 -6.894930
v  -20.327259 3.356706 -13.258736
v  2.709294 -0.083781 25.179413
v  -31.078907 -0.414653 -16.298685
v  -20.287128 2.893316 -9.426167
v  1.106491 2.040213 11.708574
v  3.463104 1.407206 15.632535
v  0.432990 1.207143 15.769363
v  -24.578136 2.588459 -14.360905
v  -18.371090 2.906300 -11.585335
v  -26.367657 1.601217 -16.461401
v  -3.657437 3.237735 7.060950
v  -25.162964 2.655333 -12.919626
v  -1.703187 3.253242 4.936950
v  -12.931023 3.424290 -6.359007
v  -2.638226 3.128729 5.912374
v  -19.150690 3.357260 -8.374714
v  1.799134 1.356462 15.716039
v  -16.764864 3.198674 -8.335228
v  0.184934 2.781948 7.292035
v  -21.123039 3.168875 -12.265469
v  2.377640 2.157037 11.393517
v  -18.395760 3.126135 -11.560881
v  -0.466324 2.307679 12.168229
v  -19.355610 3.228779 -10.437215
v  0.792618 2.056839 11.794076
v  -23.523628 3.161936 -12.075830
v  -26.632881 1.564125 -15.170250
v  0.488543 1.421331 15.796652
v  -4.604691 3.123607 4.337783
v  -2.239196 3.028635 8.815676
v  -31.469826 0.006982 -14.726236
v  3.421485 0.966298 15.572396
v  2.059710 1.548842 15.688193
v  -22.840874 2.949326 -13.180288
v  2.375934 -0.051625 25.163548
v  -26.713844 1.598100 -14.856005
v  4.040234 -0.212165 25.242781
v  -1.147274 2.835315 8.110260
v  -12.336218 3.305939 -3.630749
v  -31.104668 -0.141490 -16.252350
v  -22.070833 3.093572 -14.588408
v  -26.228260 1.186051 -16.373623
v  -11.272393 2.984214 -4.704604
v  -2.743119 3.268811 6.032610
v  -9.665592 3.395783 1.026772
v  1.035786 2.256531 11.724886
v  -13.317732 2.970300 -2.668786
v  2.001640 1.081585 17.547806
v  -10.403653 3.235186 -2.022150
v  -22.680538 2.941781 -13.457588
v  0.817259 1.057865 19.835670
v  -20.266830 3.113455 -9.452383
v  -24.044228 2.026146 -15.589464
v  -24.158691 2.450829 -15.668140
v  -27.101219 1.909057 -13.614059
v  -24.553570 2.364244 -14.424316
v  -1.730024 3.026175 4.956853
v  0.474609 1.648050 15.829503
v  3.406903 1.186139 15.604309
v  -2.290114 2.590338 8.744645
v  -16.913551 3.406766 -8.145931
v  2.322297 1.935214 11.370508
v  -0.454926 2.082321 12.127039
v  -26.961826 1.493891 -13.526285
v  -11.528889 3.186792 -0.912669
v  -2.783882 3.017813 6.102245
v  -0.873672 2.832621 7.944135
v  0.079390 2.345500 10.220213
v  -24.664410 2.386814 -14.120977
v  0.188961 2.565932 7.239411
v  -23.469429 2.939113 -12.076397
v  2.330257 1.011202 17.542826
v  -12.953409 3.197155 -6.334808
v  -3.638412 3.011033 7.031007
v  -31.140442 -0.372363 -15.979204
v  0.239879 3.004229 7.310441
v  -21.230438 3.377763 -12.043634
v  3.678589 1.115631 17.575054
v  1.895300 1.562029 15.699476
v  -25.048500 2.230650 -12.840952
v  4.025883 0.015213 25.271185
v  2.479629 0.198799 25.117563
v  0.373661 2.334997 10.089382
v  0.624938 0.945855 17.511015
v  1.568940 2.475698 9.595455
v  -21.322575 3.168410 -12.016796
v  -1.097410 2.570865 10.780905
v  -26.691063 1.811404 -14.954950
v  -27.021828 1.694623 -13.602896
v  -18.375530 3.353247 -11.587126
v  -24.112347 2.237575 -15.595936
v  -19.429409 3.160131 -10.396683
v  -22.050730 2.872353 -14.530006
v  -8.740801 3.215640 0.118190
v  -2.635788 3.269673 5.915504
v  -26.650345 1.794604 -15.111549
v  -19.251509 3.370485 -10.556568
v  1.397570 1.652280 13.939956
v  -19.217947 3.161563 -10.634978
v  -2.567496 3.060508 5.868737
v  -22.135231 3.352485 -11.005542
v  1.519915 2.036748 9.527155
v  2.632766 0.043636 25.106781
v  -31.086269 -0.249178 -16.063963
v  -16.484447 3.395513 -5.811547
v  -1.075161 3.046931 8.071315
v  0.879498 2.264704 11.768185
v  2.419936 0.940775 19.810032
v  2.086266 1.149951 17.497355
v  -30.912239 -0.376202 -17.621006
v  -25.093056 2.437279 -12.911926
v  -2.235963 2.805781 8.761944
v  1.076247 0.299735 25.130777
v  -22.791042 3.162817 -13.248486
v  -3.462774 3.315943 3.232089
v  -31.390812 -0.200317 -14.679443
v  -10.570818 3.443050 -1.850629
v  3.793933 0.332696 19.869633
v  -1.146437 2.131916 10.712603
v  -3.468509 2.869078 3.224106
v  -13.870117 3.299770 -5.387673
v  2.488267 0.715427 19.863169
v  -5.536634 2.857450 5.236848
v  1.512758 2.253916 9.574683
v  1.720331 1.630826 13.883658
v  0.102642 1.922547 14.196611
v  2.099375 1.289062 17.516163
v  -26.305477 1.394007 -16.383417
v  -24.633968 2.599519 -14.209873
v  3.027128 1.728153 13.686519
v  -15.889272 3.390823 -9.256400
v  -20.347424 3.129686 -13.231777
v  0.680737 1.159726 17.540413
v  -20.325191 2.909739 -13.257711
v  0.306955 2.549543 10.123161
v  -8.572983 3.284748 -0.051178
v  -15.578060 3.215932 -6.738585
v  2.983451 1.288361 13.620147
v  -26.606556 1.663563 -15.083918
v  -16.912086 3.265775 -8.145304
v  -21.130898 3.377998 -12.167616
v  2.252023 1.135054 17.497396
v  -30.756638 -0.784429 -17.528862
v  -22.112965 3.125564 -11.031460
v  -4.563004 3.192422 4.266551
v  0.999269 -0.137884 25.069832
v  -22.133160 2.905518 -11.004516
v  -5.530900 3.304315 5.244832
v  2.046579 1.409756 15.669215
v  -20.291569 3.340263 -9.427957
v  0.666496 1.386841 17.570633
v  -4.376442 3.124891 4.115647
v  -4.447378 3.334028 4.158289
v  -3.489954 3.088888 3.251652
v  -31.135353 -0.120401 -16.093029
v  -26.647274 1.680363 -14.927315
v  -19.250103 3.229494 -10.555999
v  -28.831551 0.583263 -15.766533
v  0.058966 1.482755 14.130239
v  2.645867 0.182763 25.125477
v  0.160325 2.554741 10.188632
v  2.151352 0.706333 19.847418
v  -18.268734 3.177416 -9.325183
v  -29.234657 0.992114 -14.232548
v  2.466527 0.059671 25.098866
v  -31.055580 -0.270267 -16.223282
v  -11.528872 3.413762 -0.911409
v  -28.494499 0.220028 -16.993164
v  -13.825386 3.232078 -5.456154
v  -2.745557 3.127867 6.029481
v  -29.089481 0.586664 -14.107367
v  -4.561200 3.333388 4.269064
v  -5.509544 3.077533 5.217161
v  -1.091019 2.346849 10.732310
v  1.882170 1.422943 15.680497
v  -1.091209 2.908663 8.048929
v  0.865911 2.125774 11.748421
v  0.144817 2.416288 10.167019
v  -21.130409 3.236999 -12.167431
v  1.470095 1.724432 13.877245
v  -0.954970 2.907392 7.965783
v  1.022198 2.117601 11.705122
v  0.291447 2.411090 10.101548
v  -22.687641 3.021223 -13.368572
v  -24.598152 2.465447 -14.185179
v  -21.229946 3.236763 -12.043449
v  -24.542324 2.454388 -14.336212
v  -22.768372 3.024792 -13.230782
v  -17.855066 3.149986 -7.191210
v  -9.522377 3.426947 -2.890227
v  -28.897217 0.623727 -15.448042
v  3.778964 0.553100 19.897770
v  -28.653641 0.634081 -17.050621
v  -12.400123 3.196546 -3.582394
v  2.970633 1.507107 13.658800
v  -19.357018 3.369770 -10.437784
v  2.240725 0.817277 19.786263
v  -10.457732 3.443793 -1.962153
v  3.835476 0.773716 19.929024
v  0.775718 0.616846 19.776281
v  -19.145805 2.910320 -8.372515
v  -16.764439 3.157676 -8.335046
v  1.631042 1.713724 13.849151
v  -16.802460 3.266548 -8.260239
v  -16.480503 2.948560 -5.810220
v  1.644855 1.852440 13.870187
v  -14.465494 2.962779 -7.876114
v  -8.513469 3.176073 -0.105094
v  -28.807411 0.727387 -15.689651
v  -4.449183 3.193062 4.155776
v  2.334070 1.051653 17.548294
v  -16.460804 3.395680 -5.835789
v  -3.487041 3.315806 3.255706
v  0.852674 1.054531 19.836767
v  -19.127695 3.357420 -8.399577
v  1.483908 1.863148 13.898281
v  -18.268286 3.136419 -9.324981
v  -13.319265 3.417269 -2.667812
v  -29.157391 0.784042 -14.186176
v  -15.912642 3.390658 -9.231892
v  2.155163 0.746787 19.852865
v  -28.855324 0.856683 -15.718437
v  -28.888233 0.876790 -15.559654
v  -17.186079 2.923938 -10.491399
v  0.114779 1.696940 14.156922
v  -28.576374 0.426009 -17.004250
v  -4.605217 3.082618 4.337050
v  3.637030 0.674644 17.515438
v  3.622141 0.894880 17.544729
v  2.265134 1.274165 17.516205
v  -14.938160 3.183322 -4.334195
v  -9.498267 3.427105 -2.914005
v  -28.840324 0.747494 -15.530870
v  -12.954870 3.424124 -6.334967
v  -18.398012 3.353095 -11.561790
v  -13.982888 3.439987 -5.275023
v  -19.217541 3.120566 -10.634814
v  -17.211479 3.143762 -10.467620
v  -7.633729 3.360612 3.048379
v  2.379745 -0.011170 25.168980
v  -4.376968 3.083901 4.114914
v  -7.606069 2.961796 -0.997087
v  -18.088707 3.387842 -9.482461
v  2.406830 0.801653 19.791294
v  -9.522394 3.199977 -2.891487
v  -1.726118 3.253060 4.961873
v  -15.577698 3.174934 -6.738463
v  -21.122849 3.127876 -12.265377
v  -22.673828 2.901676 -13.452364
v  -24.543072 2.325289 -14.417101
v  -2.568201 3.019525 5.867831
v  -21.322386 3.127410 -12.016703
v  -26.620098 1.526043 -15.162199
v  1.102523 1.999824 11.702776
v  1.393564 1.611939 13.933867
v  0.074893 2.305236 10.213949
v  1.795317 1.316018 15.710521
v  0.788650 2.016450 11.788277
v  0.369164 2.294733 10.083117
v  2.125141 1.289437 15.688784
v  1.716324 1.590484 13.877571
v  1.997829 1.041135 17.542339
v  -19.429003 3.119133 -10.396518
v  -12.174389 3.198081 -3.807076
v  -26.701057 1.560017 -14.847952
v  -0.878342 2.792417 7.937620
v  -24.653908 2.347859 -14.113762
v  2.253829 0.956398 19.804996
v  -1.151945 2.795110 8.103745
v  -22.834164 2.909221 -13.175066
v  2.484457 0.674973 19.857719
v  0.831579 0.830581 19.806606
v  -15.355312 3.176504 -6.966466
v  3.800060 0.777050 19.927929
v  -5.592322 3.372796 1.056753
v  -5.618434 3.145706 1.077347
v  -28.911171 0.661313 -15.456417
v  -2.783176 3.058797 6.103152
v  -28.845509 0.620849 -15.774908
v  -29.241642 0.996416 -14.198687
v  -28.646660 0.629779 -17.084482
v  3.643245 1.118813 17.575003
v  -16.443869 3.010851 -9.775150
v  -16.530666 3.010855 -9.861946
v  -16.443869 3.119159 -9.775150
v  -16.530666 3.119163 -9.861946
v  -16.347467 3.010851 -9.871551
v  -16.434265 3.010855 -9.958345
v  -16.347467 3.119159 -9.871551
v  -16.434265 3.119163 -9.958346
v  -15.798652 3.010851 -9.129932
v  -15.885449 3.010851 -9.216728
v  -15.798652 3.119159 -9.129932
v  -15.885449 3.119159 -9.216728
v  -15.702251 3.010851 -9.226334
v  -15.789049 3.010851 -9.313128
v  -15.702251 3.119159 -9.226334
v  -15.789049 3.119159 -9.313128
v  -14.970828 3.010851 -8.302108
v  -15.057625 3.010851 -8.388906
v  -14.970828 3.119159 -8.302109
v  -15.057625 3.119159 -8.388906
v  -14.874428 3.010851 -8.398508
v  -14.961225 3.010851 -8.485306
v  -14.874428 3.119159 -8.398509
v  -14.961225 3.119159 -8.485306
v  -14.259575 3.010855 -7.590854
v  -14.346371 3.010855 -7.677651
v  -14.259575 3.119163 -7.590854
v  -14.346371 3.119163 -7.677651
v  -14.163174 3.010855 -7.687254
v  -14.249970 3.010855 -7.774051
v  -14.163174 3.119163 -7.687254
v  -14.249970 3.119163 -7.774051
v  -13.426894 3.010855 -6.758173
v  -13.513691 3.010855 -6.844970
v  -13.426894 3.119163 -6.758173
v  -13.513691 3.119163 -6.844970
v  -13.330494 3.010855 -6.854573
v  -13.417291 3.010855 -6.941370
v  -13.330494 3.119163 -6.854573
v  -13.417291 3.119163 -6.941370
v  -12.623345 3.010855 -5.954623
v  -12.710143 3.010855 -6.041421
v  -12.623345 3.119163 -5.954623
v  -12.710143 3.119163 -6.041421
v  -12.526945 3.010855 -6.051023
v  -12.613743 3.010855 -6.137821
v  -12.526945 3.119163 -6.051023
v  -12.613743 3.119163 -6.137821
v  -11.836803 3.010855 -5.168081
v  -11.923601 3.010855 -5.254878
v  -11.836803 3.119163 -5.168081
v  -11.923601 3.119163 -5.254878
v  -11.740404 3.010855 -5.264481
v  -11.827200 3.010855 -5.351278
v  -11.740404 3.119163 -5.264481
v  -11.827200 3.119163 -5.351278
v  -4.695798 3.010852 1.972930
v  -4.609000 3.010852 2.059727
v  -4.695798 3.119160 1.972930
v  -4.609000 3.119160 2.059727
v  -4.599398 3.010852 1.876530
v  -4.512600 3.010852 1.963327
v  -4.599398 3.119160 1.876530
v  -4.512600 3.119160 1.963327
v  -5.341014 3.010856 1.327714
v  -5.254216 3.010856 1.414510
v  -5.341014 3.119164 1.327714
v  -5.254216 3.119164 1.414510
v  -5.244615 3.010856 1.231313
v  -5.157817 3.010856 1.318109
v  -5.244615 3.119164 1.231313
v  -5.157817 3.119164 1.318109
v  -6.168838 3.010855 0.499890
v  -6.082041 3.010855 0.586687
v  -6.168838 3.119163 0.499890
v  -6.082041 3.119163 0.586687
v  -6.072437 3.010855 0.403490
v  -5.985641 3.010855 0.490286
v  -6.072437 3.119163 0.403490
v  -5.985641 3.119163 0.490286
v  -6.880092 3.010855 -0.211364
v  -6.793295 3.010855 -0.124567
v  -6.880092 3.119163 -0.211364
v  -6.793295 3.119163 -0.124567
v  -6.783691 3.010855 -0.307765
v  -6.696895 3.010855 -0.220967
v  -6.783691 3.119163 -0.307765
v  -6.696895 3.119163 -0.220967
v  -7.712771 3.010855 -1.044045
v  -7.625975 3.010855 -0.957249
v  -7.712771 3.119163 -1.044045
v  -7.625975 3.119163 -0.957249
v  -7.616371 3.010855 -1.140446
v  -7.529574 3.010855 -1.053649
v  -7.616371 3.119163 -1.140446
v  -7.529574 3.119163 -1.053649
v  -8.516319 3.010855 -1.847594
v  -8.429523 3.010855 -1.760798
v  -8.516319 3.119163 -1.847594
v  -8.429523 3.119163 -1.760798
v  -8.419919 3.010855 -1.943995
v  -8.333122 3.010855 -1.857198
v  -8.419919 3.119163 -1.943995
v  -8.333122 3.119163 -1.857198
v  -9.302861 3.010851 -2.634136
v  -9.216064 3.010851 -2.547339
v  -9.302861 3.119159 -2.634136
v  -9.216064 3.119159 -2.547339
v  -9.206461 3.010851 -2.730537
v  -9.119663 3.010851 -2.643740
v  -9.206461 3.119159 -2.730537
v  -9.119663 3.119159 -2.643740
v  -6.724374 3.010853 4.001503
v  -6.637576 3.010853 4.088300
v  -6.724374 3.119161 4.001503
v  -6.637576 3.119161 4.088300
v  -6.820774 3.010853 4.097904
v  -6.733976 3.010853 4.184700
v  -6.820774 3.119161 4.097904
v  -6.733976 3.119161 4.184700
v  -7.369590 3.010857 3.356287
v  -7.282792 3.010857 3.443083
v  -7.369590 3.119164 3.356287
v  -7.282792 3.119164 3.443083
v  -7.465991 3.010857 3.452687
v  -7.379194 3.010857 3.539483
v  -7.465991 3.119164 3.452687
v  -7.379194 3.119164 3.539483
v  -8.197413 3.010856 2.528463
v  -8.110617 3.010856 2.615260
v  -8.197413 3.119164 2.528463
v  -8.110617 3.119164 2.615260
v  -8.293814 3.010856 2.624863
v  -8.207017 3.010856 2.711660
v  -8.293814 3.119164 2.624863
v  -8.207017 3.119164 2.711660
v  -8.908668 3.010853 1.817210
v  -8.821871 3.010853 1.904006
v  -8.908668 3.119161 1.817210
v  -8.821871 3.119161 1.904006
v  -9.005068 3.010853 1.913610
v  -8.918271 3.010853 2.000407
v  -9.005068 3.119161 1.913610
v  -8.918271 3.119161 2.000407
v  -9.741347 3.010853 0.984528
v  -9.654551 3.010853 1.071325
v  -9.741347 3.119161 0.984528
v  -9.654551 3.119161 1.071325
v  -9.837748 3.010853 1.080928
v  -9.750952 3.010853 1.167725
v  -9.837748 3.119161 1.080928
v  -9.750952 3.119161 1.167725
v  -10.544895 3.010853 0.180979
v  -10.458098 3.010853 0.267776
v  -10.544895 3.119161 0.180979
v  -10.458098 3.119161 0.267776
v  -10.641295 3.010853 0.277380
v  -10.554499 3.010853 0.364176
v  -10.641295 3.119161 0.277380
v  -10.554499 3.119161 0.364176
v  -11.331437 3.010852 -0.605564
v  -11.244640 3.010852 -0.518766
v  -11.331437 3.119160 -0.605564
v  -11.244640 3.119160 -0.518766
v  -11.427837 3.010852 -0.509163
v  -11.341041 3.010852 -0.422366
v  -11.427837 3.119160 -0.509163
v  -11.341041 3.119160 -0.422366
v  -18.472445 3.010852 -7.746577
v  -18.559242 3.010856 -7.833373
v  -18.472445 3.119160 -7.746577
v  -18.559242 3.119164 -7.833373
v  -18.568844 3.010852 -7.650177
v  -18.655643 3.010856 -7.736972
v  -18.568844 3.119160 -7.650177
v  -18.655643 3.119164 -7.736972
v  -17.827227 3.010852 -7.101360
v  -17.914024 3.010852 -7.188156
v  -17.827227 3.119160 -7.101360
v  -17.914024 3.119160 -7.188156
v  -17.923628 3.010852 -7.004960
v  -18.010426 3.010852 -7.091755
v  -17.923628 3.119160 -7.004960
v  -18.010426 3.119160 -7.091755
v  -16.999403 3.010852 -6.273536
v  -17.086201 3.010852 -6.360332
v  -16.999403 3.119160 -6.273536
v  -17.086201 3.119160 -6.360332
v  -17.095804 3.010852 -6.177135
v  -17.182600 3.010852 -6.263932
v  -17.095804 3.119160 -6.177135
v  -17.182600 3.119160 -6.263932
v  -16.288149 3.010852 -5.562281
v  -16.374947 3.010852 -5.649078
v  -16.288149 3.119160 -5.562281
v  -16.374947 3.119160 -5.649078
v  -16.384550 3.010852 -5.465880
v  -16.471348 3.010852 -5.552678
v  -16.384550 3.119160 -5.465880
v  -16.471348 3.119160 -5.552678
v  -15.455470 3.010852 -4.729600
v  -15.542267 3.010852 -4.816397
v  -15.455470 3.119160 -4.729600
v  -15.542266 3.119160 -4.816397
v  -15.551870 3.010852 -4.633200
v  -15.638667 3.010852 -4.719996
v  -15.551870 3.119160 -4.633200
v  -15.638666 3.119160 -4.719997
v  -14.651921 3.010852 -3.926050
v  -14.738718 3.010852 -4.012848
v  -14.651920 3.119160 -3.926050
v  -14.738718 3.119160 -4.012848
v  -14.748322 3.010852 -3.829649
v  -14.835119 3.010852 -3.916448
v  -14.748322 3.119160 -3.829649
v  -14.835119 3.119160 -3.916448
v  -13.865380 3.010856 -3.139508
v  -13.952177 3.010856 -3.226305
v  -13.865380 3.119164 -3.139508
v  -13.952176 3.119164 -3.226305
v  -13.961781 3.010856 -3.043107
v  -14.048576 3.010856 -3.129904
v  -13.961781 3.119164 -3.043107
v  -14.048576 3.119164 -3.129905
v  -11.837126 3.052240 -5.287846
v  -11.315033 9.057068 -3.405852
v  -11.818531 3.045205 -5.269251
v  -13.985144 3.052241 -3.139830
v  -13.966550 3.045206 -3.121235
v  -12.103148 9.057068 -2.617737
v  -9.183097 3.052238 -2.633815
v  -9.201691 3.045202 -2.652409
v  -11.065093 9.057068 -3.155911
v  -11.331116 3.052238 -0.485799
v  -11.853209 9.057069 -2.367797
v  -11.349709 3.045203 -0.504393
v  -14.768768 3.050861 -3.923452
v  -14.750896 3.040739 -3.905585
v  -12.108672 9.798411 -2.623198
v  -12.620748 3.050864 -6.071470
v  -11.320494 9.798410 -3.411376
v  -12.602880 3.040741 -6.053599
v  -8.399473 3.050865 -1.850194
v  -8.417343 3.040742 -1.868059
v  -11.059569 9.798411 -3.150450
v  -10.547495 3.050862 0.297824
v  -11.847748 9.798411 -2.362273
v  -10.565360 3.040740 0.279955
v  -9.740740 3.051967 1.104578
v  -11.775990 10.419531 -2.339177
v  -9.757899 3.039635 1.087419
v  -7.592721 3.051970 -1.043437
v  -7.609880 3.039638 -1.060597
v  -11.036472 10.419531 -3.078694
v  -13.427503 3.051969 -6.878224
v  -11.392250 10.419530 -3.434472
v  -13.410342 3.039637 -6.861064
v  -15.575521 3.051966 -4.730208
v  -15.558361 3.039634 -4.713048
v  -12.131768 10.419530 -2.694955
v  -14.263753 3.036242 -7.714477
v  -11.338090 11.074389 -3.294117
v  -14.279956 3.050938 -7.730680
v  -16.427975 3.050935 -5.582663
v  -16.411772 3.036240 -5.566461
v  -11.991413 11.074389 -2.640795
v  -6.756469 3.036243 -0.207186
v  -6.740267 3.050939 -0.190982
v  -11.176827 11.074389 -3.132854
v  -8.904489 3.036241 1.940831
v  -11.830151 11.074389 -2.479532
v  -8.888287 3.050936 1.957034
v  -6.021232 3.061029 0.528053
v  -6.037050 3.045519 0.512234
v  -11.059149 11.777874 -3.137989
v  -8.185070 3.045520 2.660250
v  -11.817142 11.775508 -2.378439
v  -8.169250 3.061030 2.676070
v  -17.147009 3.061026 -6.301698
v  -17.131191 3.045516 -6.285881
v  -12.109092 11.777874 -2.635660
v  -14.998990 3.061025 -8.449715
v  -11.332955 11.777874 -3.411796
v  -14.983172 3.045515 -8.433897
v  -17.948605 3.074946 -7.103291
v  -17.933361 3.058325 -7.088048
v  -12.098961 12.536715 -2.533056
v  -7.367658 3.074951 3.477662
v  -11.955965 12.538565 -2.355264
v  -7.382901 3.058330 3.462418
v  -5.219638 3.074950 1.329645
v  -5.234881 3.058329 1.314402
v  -11.069280 12.536715 -3.240594
v  -15.800585 3.074945 -9.251307
v  -11.212275 12.538565 -3.418385
v  -15.785341 3.058324 -9.236064
v  -16.428455 3.042503 -9.879176
v  -11.217546 12.997416 -3.452478
v  -16.443325 3.059786 -9.894053
v  -18.576469 3.042504 -7.731164
v  -18.591347 3.059787 -7.746033
v  -12.149774 12.997416 -2.520253
v  -6.724918 3.059784 4.120408
v  -11.968728 12.999013 -2.304383
v  -6.739788 3.042505 4.105530
v  -4.576895 3.059783 1.972387
v  -4.591772 3.042504 1.957517
v  -11.018466 12.997416 -3.253397
v  -11.950693 12.997416 -2.321172
v  -11.001678 12.999012 -3.271432
v  -12.166562 12.999012 -2.502217
v  -11.199512 12.999012 -3.469266
v  -11.937890 12.536716 -2.371984
v  -11.052559 12.538565 -3.258669
v  -12.115681 12.538565 -2.514980
v  -11.230350 12.536715 -3.401665
v  -11.075734 11.775508 -3.119845
v  -11.351099 11.775507 -3.395211
v  -12.092506 11.775507 -2.653804
v  -11.835285 11.777874 -2.361854
v  -12.007870 11.077243 -2.622599
v  -11.319895 11.077243 -3.310574
v  -11.160371 11.077243 -3.151050
v  -11.848347 11.077244 -2.463075
v  -11.766077 10.410454 -2.359218
v  -11.056514 10.410454 -3.068780
v  -11.402164 10.410454 -3.414431
v  -12.111728 10.410454 -2.704869
v  -11.838171 9.790024 -2.382591
v  -11.079887 9.790024 -3.140874
v  -12.088354 9.790024 -2.632774
v  -11.330070 9.790024 -3.391057
v  -11.085712 9.049520 -3.146763
v  -11.844060 9.049520 -2.388417
v  -12.082529 9.049520 -2.626885
v  -11.324181 9.049519 -3.385232
v  -9.184472 3.051047 -2.651915
v  -11.836630 3.046395 -5.270627
v  -11.349215 3.051048 -0.487175
v  -13.983768 3.051051 -3.121730
v  -11.819026 3.051050 -5.286470
v  -9.200315 3.046392 -2.634310
v  -13.967926 3.046396 -3.139334
v  -11.331610 3.046393 -0.503017
v  -12.620755 3.043887 -6.054680
v  -8.400555 3.047719 -1.868068
v  -14.751978 3.043884 -3.923460
v  -14.767686 3.047716 -3.905576
v  -12.602872 3.047719 -6.070388
v  -8.416263 3.043888 -1.850185
v  -10.547486 3.043885 0.281036
v  -10.565369 3.047717 0.296744
v  -15.574751 3.047481 -4.712610
v  -7.593491 3.047484 -1.061035
v  -9.740301 3.044120 1.088189
v  -9.758338 3.047481 1.103809
v  -13.409904 3.047483 -6.877455
v  -13.427940 3.044122 -6.861834
v  -15.559132 3.044120 -4.730646
v  -7.609110 3.044123 -1.043000
v  -8.905464 3.045015 1.956715
v  -16.427656 3.045015 -5.565485
v  -14.262777 3.045017 -7.730361
v  -6.740585 3.045018 -0.208161
v  -14.280931 3.042163 -7.714795
v  -16.412090 3.042161 -5.583638
v  -8.887310 3.042161 1.941150
v  -6.756150 3.042164 -0.190007
v  -6.743809 3.047513 4.116184
v  -18.587124 3.047512 -7.727142
v  -4.581118 3.047512 1.953496
v  -16.424435 3.047511 -9.889830
v  -6.720896 3.054777 4.109753
v  -4.587548 3.054776 1.976408
v  -18.580694 3.054780 -7.750054
v  -16.447346 3.054779 -9.883399
v  -7.363702 3.069995 3.466956
v  -17.944065 3.063281 -7.084090
v  -15.781384 3.063280 -9.246768
v  -5.230344 3.069994 1.333601
v  -5.224178 3.063285 1.310444
v  -15.804541 3.069989 -9.240602
v  -17.937899 3.069990 -7.107247
v  -7.386858 3.063286 3.473122
v  -14.979308 3.050417 -8.444665
v  -17.141960 3.050418 -6.282016
v  -15.002854 3.056123 -8.438947
v  -6.032001 3.056127 0.531917
v  -6.026281 3.050422 0.508370
v  -8.188933 3.050423 2.671020
v  -17.136242 3.056124 -6.305563
v  -8.165387 3.056128 2.665300
v  -11.761514 10.419492 -2.348325
v  -11.045620 10.419492 -3.064217
v  -12.122621 10.419492 -2.709432
v  -11.406727 10.419492 -3.425325
v  -11.387688 10.410492 -3.423579
v  -11.047366 10.410492 -3.083257
v  -11.780554 10.410493 -2.350071
v  -12.120874 10.410492 -2.690392
v  -11.833261 9.797641 -2.371365
v  -11.068661 9.797641 -3.135965
v  -11.852656 9.790794 -2.373500
v  -11.334979 9.797640 -3.402284
v  -12.099581 9.797641 -2.637683
v  -11.070796 9.790794 -3.155360
v  -12.097445 9.790794 -2.618289
v  -11.315584 9.790794 -3.400149
v  -12.094101 9.055177 -2.632167
v  -11.074141 9.055177 -3.141481
v  -11.329462 9.055176 -3.396804
v  -11.309752 9.051411 -3.394279
v  -12.091576 9.051411 -2.612456
v  -11.838778 9.055177 -2.376844
v  -11.076665 9.051411 -3.161193
v  -11.858490 9.051411 -2.379369
v  -11.199692 12.991131 -3.460741
v  -11.217367 13.005297 -3.461004
v  -11.950873 13.005297 -2.312646
v  -11.009941 13.005297 -3.253577
v  -12.158299 13.005297 -2.520072
v  -11.010203 12.991131 -3.271252
v  -12.158037 12.991131 -2.502398
v  -11.968548 12.991131 -2.312909
v  -11.230268 12.544374 -3.410281
v  -12.107576 12.544374 -2.532973
v  -11.060663 12.544374 -3.240677
v  -11.937973 12.544374 -2.363369
v  -12.107065 12.530905 -2.515063
v  -11.061175 12.530905 -3.258586
v  -11.212358 12.530905 -3.409769
v  -11.955883 12.530906 -2.363880
v  -12.101256 11.782815 -2.653872
v  -11.066984 11.782815 -3.119778
v  -11.835352 11.770567 -2.370603
v  -12.100342 11.770566 -2.635593
v  -11.351167 11.782815 -3.403960
v  -11.817074 11.782816 -2.369689
v  -11.332888 11.770566 -3.403046
v  -11.067898 11.770566 -3.138057
v  -12.000242 11.081466 -2.640961
v  -11.338257 11.081465 -3.302947
v  -11.848513 11.070167 -2.471904
v  -11.169200 11.070167 -3.151216
v  -11.167998 11.081466 -3.132688
v  -11.999041 11.070167 -2.622433
v  -11.319729 11.070167 -3.301745
v  -11.829985 11.081466 -2.470703
v  -18.573254 3.046136 -7.742620
v  -4.594987 3.046136 1.968973
v  -16.439911 3.046135 -9.875961
v  -6.728332 3.046137 4.102314
v  -6.736373 3.056152 4.123621
v  -4.573679 3.056151 1.960930
v  -16.431870 3.056154 -9.897268
v  -18.594563 3.056155 -7.734577
v  -7.379236 3.071597 3.480745
v  -7.371323 3.061684 3.459335
v  -15.789006 3.071591 -9.254390
v  -5.216556 3.071596 1.318067
v  -17.930277 3.061679 -7.099625
v  -17.951687 3.071592 -7.091712
v  -15.796919 3.061678 -9.232981
v  -5.237965 3.061683 1.325980
v  -6.039910 3.048372 0.524008
v  -8.173296 3.048373 2.657391
v  -14.987217 3.058172 -8.452574
v  -17.128332 3.048369 -6.297654
v  -17.149868 3.058173 -6.289926
v  -14.994946 3.048368 -8.431037
v  -6.018373 3.058177 0.516279
v  -8.181025 3.058177 2.678929
v  -11.208349 13.006095 -3.469398
v  -12.166693 13.006096 -2.511055
v  -11.959891 13.006096 -2.304252
v  -11.959530 12.990334 -2.321303
v  -11.001547 13.006096 -3.262595
v  -11.018598 12.990333 -3.262234
v  -12.149643 12.990333 -2.511415
v  -11.208710 12.990332 -3.452347
v  -12.115937 12.545300 -2.523935
v  -11.052303 12.545300 -3.249715
v  -11.221230 12.545300 -3.418641
v  -11.947011 12.545300 -2.355009
v  -12.098705 12.529980 -2.524101
v  -11.221395 12.529980 -3.401409
v  -11.069535 12.529980 -3.249548
v  -11.946845 12.529981 -2.372240
v  -11.826146 11.783998 -2.361397
v  -11.342094 11.783998 -3.412253
v  -11.058692 11.783998 -3.128850
v  -12.109549 11.783998 -2.644799
v  -11.341960 11.769382 -3.394754
v  -12.092051 11.769382 -2.644665
v  -11.076191 11.769383 -3.128984
v  -11.826281 11.769383 -2.378896
v  -11.839083 11.082893 -2.462474
v  -11.159770 11.082893 -3.141786
v  -11.329159 11.082892 -3.311175
v  -12.008471 11.082893 -2.631863
v  -11.328826 11.068739 -3.293516
v  -11.990812 11.068739 -2.631531
v  -11.177428 11.068739 -3.142118
v  -11.839415 11.068740 -2.480133
v  -11.780087 10.416673 -2.340956
v  -11.761981 10.413312 -2.357439
v  -12.113506 10.413312 -2.708965
v  -11.054734 10.413312 -3.064684
v  -11.038252 10.416673 -3.082790
v  -11.406260 10.413311 -3.416210
v  -12.129990 10.416673 -2.690859
v  -11.388155 10.416672 -3.432693
v  -11.077992 9.792297 -3.136667
v  -11.833964 9.792297 -2.380696
v  -11.316286 9.796138 -3.409481
v  -11.334277 9.792296 -3.392952
v  -12.106777 9.796138 -2.618991
v  -12.090249 9.792297 -2.636981
v  -11.851954 9.796138 -2.364168
v  -11.061464 9.796138 -3.154657
v  -11.083700 9.050966 -3.142448
v  -12.084541 9.050966 -2.631201
v  -11.857523 9.055621 -2.369809
v  -11.839745 9.050966 -2.386404
v  -11.067105 9.055621 -3.160226
v  -11.328497 9.050966 -3.387244
v  -11.310718 9.055620 -3.403839
v  -12.101136 9.055621 -2.613422
v  -11.840099 3.048466 -5.276337
v  -11.328140 3.048464 -0.497307
v  -13.978059 3.048980 -3.118261
v  -11.352684 3.048977 -0.492885
v  -9.190182 3.048976 -2.655384
v  -13.973636 3.048467 -3.142804
v  -9.194605 3.048463 -2.630841
v  -11.815557 3.048979 -5.280760
v  -14.757499 3.046672 -3.926729
v  -12.624024 3.046674 -6.060201
v  -14.762166 3.044929 -3.902308
v  -12.599604 3.044931 -6.064868
v  -10.544217 3.046672 0.286556
v  -8.410743 3.046675 -1.846916
v  -8.406075 3.044932 -1.871336
v  -10.568638 3.044930 0.291224
v  -15.564467 3.047428 -4.733728
v  -13.431023 3.047431 -6.867170
v  -15.569415 3.044173 -4.709529
v  -7.598826 3.044176 -1.064117
v  -13.406823 3.044175 -6.872118
v  -7.603775 3.047431 -1.039918
v  -9.761419 3.044173 1.098473
v  -9.737221 3.047429 1.093524
v  -6.751057 3.046029 -0.187172
v  -16.417183 3.046026 -5.586473
v  -8.908298 3.041151 1.951622
v  -14.259943 3.041152 -7.725267
v  -16.422562 3.041150 -5.562650
v  -8.884476 3.046026 1.946244
v  -14.283766 3.046028 -7.719889
v  -6.745679 3.041153 -0.210995
v  -14.274332 3.050667 -7.733486
v  -16.430780 3.050664 -5.577039
v  -6.737461 3.050668 -0.196606
v  -8.898865 3.036511 1.938025
v  -8.893909 3.050665 1.959840
v  -14.269377 3.036513 -7.711671
v  -16.408966 3.036511 -5.572084
v  -6.759274 3.036514 -0.201561
v  -9.746398 3.051981 1.107335
v  -7.589965 3.051983 -1.049096
v  -15.578277 3.051980 -4.724550
v  -13.421844 3.051983 -6.880980
v  -13.416001 3.039623 -6.858309
v  -15.555606 3.039620 -4.718707
v  -9.752241 3.039621 1.084663
v  -7.612636 3.039623 -1.054939
v  -8.420074 3.040462 -1.862391
v  -10.559692 3.040460 0.277225
v  -8.396744 3.051145 -1.855861
v  -10.553162 3.051142 0.300555
v  -12.615079 3.051144 -6.074200
v  -17.883141 -0.353833 -10.873102
v  -20.631620 -0.353833 -9.673258
v  -19.614601 -0.087513 -9.141642
v  -17.767275 -0.087513 -11.260138
v  -20.631620 -0.087513 -9.673258
v  -18.793043 -0.087513 -11.783005
v  -19.992887 -0.353833 -9.034525
v  -17.767275 -0.353833 -11.260138
v  -20.524504 -0.353833 -10.051544
v  -18.793043 -0.353833 -11.783005
v  -19.992887 -0.087513 -9.034525
v  -17.883141 -0.087513 -10.873102
v  -20.524504 -0.087513 -10.051544
v  -18.406008 -0.087513 -11.898870
v  -20.205799 -0.353833 -9.247436
v  -17.980186 -0.353833 -11.473049
v  -18.193096 -0.353833 -11.685960
v  -17.980186 -0.087513 -11.473049
v  -18.193096 -0.087513 -11.685960
v  -20.418711 -0.087513 -9.460346
v  -20.205799 -0.087513 -9.247436
v  -20.024395 -0.004919 -9.459163
v  -19.100878 -0.087513 -9.655366
v  -18.396866 -0.087513 -10.359378
v  -19.306767 -0.087513 -11.269280
v  -20.010780 -0.087513 -10.565269
v  -18.383249 -0.004919 -11.465484
v  -18.741762 -0.004919 -10.741795
v  -18.200661 2.983595 -11.282896
v  -19.665880 -0.004919 -10.182852
v  -20.206984 2.983595 -9.641750
v  -19.614601 -0.353833 -9.141642
v  -19.483294 2.983595 -10.000264
v  -18.924351 2.983595 -10.924382
v  -20.333273 -0.004919 -10.023153
v  -18.074371 -0.004919 -10.901493
v  -19.642992 -0.004919 -9.332872
v  -18.764652 -0.004919 -11.591774
v  -18.406008 -0.353833 -11.898870
v  -19.665880 2.983595 -10.182852
v  -20.024395 2.983595 -9.459163
v  -19.483294 -0.004919 -10.000264
v  -20.206984 -0.004919 -9.641750
v  -18.383249 2.983595 -11.465484
v  -18.383249 -0.004919 -11.465484
v  -18.741762 -0.004919 -10.741795
v  -18.741762 -0.004919 -10.741795
v  -18.383249 -0.004919 -11.465484
v  -18.383249 -0.004919 -11.465484
v  -18.741762 -0.004919 -10.741795
v  -18.074371 -0.004919 -10.901493
v  -18.764652 -0.004919 -11.591774
v  -11.863096 -0.188076 -0.895778
v  -14.776975 -0.188076 -1.037542
v  -13.006453 0.427397 0.247577
v  -11.879171 0.427397 -1.414754
v  -14.776975 0.427397 -1.037542
v  -13.658442 0.427397 -2.691124
v  -13.516679 -0.188076 0.222754
v  -11.879171 -0.188076 -1.414754
v  -14.801798 -0.188076 -1.547769
v  -13.658442 -0.188076 -2.691124
v  -13.516679 0.427397 0.222754
v  -11.863096 0.427397 -0.895778
v  -14.801798 0.427397 -1.547769
v  -13.139466 0.427397 -2.675050
v  -14.490662 0.618273 -1.144360
v  -8.445185 -0.188076 -4.313690
v  -11.359062 -0.188076 -4.455454
v  -9.730303 -0.188076 -6.084212
v  -9.588540 0.427397 -3.170335
v  -8.470007 0.427397 -4.823916
v  -11.359062 0.427397 -4.455454
v  -10.240530 0.427397 -6.109035
v  -10.098766 -0.188076 -3.195158
v  -8.470007 -0.188076 -4.823916
v  -11.383885 -0.188076 -4.965681
v  -10.240530 -0.188076 -6.109035
v  -10.098766 0.427397 -3.195158
v  -8.445185 0.427397 -4.313690
v  -11.383885 0.427397 -4.965681
v  -9.730303 0.427397 -6.084212
v  -11.072750 0.618273 -4.562271
v  -13.936777 -0.188076 -0.197345
v  -12.299270 -0.188076 -1.834853
v  -12.719368 -0.188076 -2.254951
v  -12.299270 0.427397 -1.834853
v  -12.719368 0.427397 -2.254951
v  -14.356875 0.427397 -0.617444
v  -13.936777 0.427397 -0.197345
v  -10.938963 -0.188076 -4.035356
v  -10.518865 -0.188076 -3.615257
v  -8.890106 0.427397 -5.244015
v  -9.310205 0.427397 -5.664114
v  -10.938963 0.427397 -4.035356
v  -10.518865 0.427397 -3.615257
v  -8.756320 0.618273 -4.717099
v  -12.174232 0.618273 -1.299187
v  -9.837121 0.618273 -5.797900
v  -9.991949 0.618273 -3.481470
v  -13.409861 0.618273 -0.063559
v  -13.255033 0.618273 -2.379988
v  -13.006453 -0.188076 0.247577
v  -9.588540 -0.188076 -3.170335
v  -13.139466 -0.188076 -2.675050
v  -12.161186 0.618273 -1.031028
v  -8.743274 0.618273 -4.448939
v  -11.085796 0.618273 -4.830431
v  -14.503708 0.618273 -1.412519
v  -13.523192 0.618273 -2.393034
v  -11.819718 10.371687 -3.090237
v  -9.428446 0.411055 -4.663748
v  -11.244001 10.995013 -2.848191
v  -11.410836 10.371687 -2.681355
v  -13.802113 0.411055 -1.107846
v  -11.986554 10.995013 -2.923402
v  -11.634254 12.168093 -2.457938
v  -11.596301 12.168093 -3.313654
v  -11.182618 9.150907 -2.909574
v  -12.047937 9.150907 -2.862019
v  -11.819718 12.162362 -3.090237
v  -11.410836 12.162362 -2.681355
v  -11.819718 9.149494 -3.090237
v  -11.410837 9.149494 -2.681355
v  -11.819718 10.371687 -3.090237
v  -11.410836 10.371687 -2.681355
v  -11.652883 10.995013 -3.257073
v  -11.577672 10.995013 -2.514520
v  -12.602120 15.177017 -2.307835
v  -10.628433 15.177017 -3.463757
v  -9.837328 0.411055 -5.072630
v  -13.393230 0.411055 -0.698965
v  -11.499442 8.587373 -3.410514
v  -11.731113 8.587373 -2.361079
v  -11.409765 11.580200 -2.682427
v  -11.820790 11.580200 -3.089165
v  -11.037315 15.177017 -3.872639
v  -12.193238 15.177017 -1.898953
v  -11.286738 10.371922 -3.623217
v  -11.943817 10.371922 -2.148376
v  -11.231326 9.795973 -2.860866
v  -11.999229 9.795973 -2.910727
v  -11.640208 9.795973 -3.269748
v  -11.590347 9.795973 -2.501845
v  -11.610987 11.585322 -2.481205
v  -11.619568 11.585322 -3.290387
v  -10.877856 10.371922 -3.214335
v  -12.352698 10.371922 -2.557257
v  -11.571644 10.371707 -2.520548
v  -11.658912 10.371707 -3.251045
v  -11.818646 11.580200 -3.091309
v  -11.411908 11.580200 -2.680284
v  -11.409999 10.990250 -2.682193
v  -11.820556 10.990250 -3.089400
v  -11.980526 10.371707 -2.929430
v  -11.250030 10.371707 -2.842163
v  -11.410564 8.584243 -2.681628
v  -11.819991 8.584243 -3.089965
v  -10.039979 0.435497 -4.052216
v  -13.190578 0.435497 -1.719379
v  -11.156636 12.754931 -2.935555
v  -12.073918 12.754931 -2.836037
v  -11.665037 12.754931 -2.427155
v  -11.565518 12.754931 -3.344437
v  -11.410837 9.795084 -2.681355
v  -11.819718 9.795084 -3.090237
v  -12.781697 0.435497 -1.310497
v  -10.448860 0.435497 -4.461097
v  -11.090561 8.587373 -3.001632
v  -12.139996 8.587373 -2.769961
v  -11.819447 8.584243 -3.090510
v  -11.411109 8.584243 -2.681083
v  -11.456107 14.654428 -3.453848
v  -11.774447 14.654428 -2.317744
v  -11.410836 12.752162 -2.681355
v  -11.819718 12.752162 -3.090237
v  -12.031449 9.100178 -2.060743
v  -11.199106 9.100178 -3.710850
v  -11.047225 14.654428 -3.044966
v  -12.183330 14.654428 -2.726626
v  -12.019869 11.585322 -2.890087
v  -11.210687 11.585322 -2.881505
v  -11.819718 12.752162 -3.090237
v  -11.410836 12.752162 -2.681355
v  -11.819718 9.149494 -3.090237
v  -11.410837 9.149494 -2.681355
v  -11.591500 9.150907 -3.318456
v  -11.639055 9.150907 -2.453137
v  -11.819718 12.162362 -3.090237
v  -11.410836 12.162362 -2.681355
v  -11.187420 12.168093 -2.904772
v  -12.043136 12.168093 -2.866820
v  -12.440331 9.100178 -2.469625
v  -10.790224 9.100178 -3.301968
v  -10.247746 5.229532 -3.844448
v  -12.982811 5.229532 -1.927146
v  -11.410837 9.795084 -2.681355
v  -11.819718 9.795084 -3.090237
v  -11.411674 10.990250 -2.680518
v  -11.818881 10.990250 -3.091075
v  -12.348675 6.671869 -1.743518
v  -10.881882 6.671869 -4.028075
v  -12.027460 13.026246 -2.064731
v  -11.203094 13.026246 -3.706861
v  -10.716176 14.304269 -3.376015
v  -12.514378 14.304269 -2.395577
v  -10.859764 11.502220 -3.232427
v  -12.370790 11.502220 -2.539165
v  -11.945447 10.875551 -2.146745
v  -11.285109 10.875551 -3.624847
v  -12.573312 7.973785 -2.336644
v  -10.657244 7.973785 -3.434949
v  -11.125058 14.304269 -3.784897
v  -12.105496 14.304269 -1.986695
v  -10.400615 3.681881 -4.509342
v  -12.829942 3.681881 -1.262252
v  -12.436342 13.026246 -2.473613
v  -10.794212 13.026246 -3.297979
v  -12.164431 7.973785 -1.927762
v  -11.066126 7.973785 -3.843831
v  -10.473000 6.671869 -3.619193
v  -12.757556 6.671869 -2.152400
v  -10.861690 10.015941 -3.230502
v  -12.368866 10.015941 -2.541090
v  -12.573929 5.229532 -1.518264
v  -10.656628 5.229532 -4.253329
v  -9.991734 3.681881 -4.100460
v  -13.238824 3.681881 -1.671134
v  -10.876226 10.875551 -3.215966
v  -12.354329 10.875551 -2.555627
v  -11.959984 10.015941 -2.132208
v  -11.270572 10.015941 -3.639384
v  -11.961908 11.502220 -2.130283
v  -11.268646 11.502220 -3.641309
v  -11.753742 10.991195 -3.156213
v  -11.476812 10.991194 -2.615379
v  -11.921415 10.994068 -2.988540
v  -11.309139 10.994068 -2.783052
v  -11.240320 12.227321 -3.669636
v  -11.990235 12.227321 -2.101957
v  -10.831438 12.227321 -3.260754
v  -12.399117 12.227321 -2.510839
v  -11.889058 11.581215 -3.020897
v  -11.341496 11.581215 -2.750695
v  -11.687837 11.584307 -3.222119
v  -11.542719 11.584307 -2.549473
v  -11.261070 12.166952 -2.831121
v  -11.969484 12.166952 -2.940471
v  -9.715216 2.064019 -4.376978
v  -13.515342 2.064019 -1.394616
v  -11.748881 12.163481 -3.161074
v  -11.481673 12.163481 -2.610518
v  -13.106460 2.064019 -0.985734
v  -10.124098 2.064019 -4.785860
v  -11.885695 10.991195 -3.024261
v  -11.344861 10.991194 -2.747331
v  -11.718021 10.994068 -3.191934
v  -11.512534 10.994068 -2.579658
v  -11.278955 11.584307 -2.813237
v  -11.951600 11.584307 -2.958355
v  -11.750379 11.581216 -3.159577
v  -11.480176 11.581215 -2.612015
v  -11.669952 12.166952 -3.240003
v  -11.339999 12.163481 -2.752192
v  -11.890555 12.163481 -3.019400
v  -11.560602 12.166952 -2.531589
v  -11.931353 9.795796 -2.978603
v  -11.299203 9.795796 -2.792989
v  -11.708085 9.795796 -3.201871
v  -11.522470 9.795796 -2.569721
v  -11.669796 9.150625 -3.240160
v  -11.560759 9.150625 -2.531433
v  -11.336689 9.149769 -2.755503
v  -11.893867 9.149769 -3.016090
v  -11.731109 10.993138 -3.178847
v  -11.499446 10.993138 -2.592746
v  -11.331773 10.992125 -2.760419
v  -11.898783 10.992125 -3.011173
v  -11.410837 8.728834 -2.681355
v  -11.819718 8.728834 -3.090237
v  -11.819475 8.664831 -3.090481
v  -11.411080 8.664831 -2.681112
v  -2.125670 -0.353833 4.884372
v  -4.874150 -0.353833 6.084217
v  -3.857131 -0.087513 6.615833
v  -2.009804 -0.087513 4.497337
v  -4.874150 -0.087513 6.084217
v  -3.035572 -0.087513 3.974470
v  -4.235417 -0.353833 6.722950
v  -2.009804 -0.353833 4.497337
v  -4.767033 -0.353833 5.705930
v  -3.035572 -0.353833 3.974470
v  -4.235417 -0.087513 6.722950
v  -2.125670 -0.087513 4.884372
v  -4.767033 -0.087513 5.705930
v  -2.648537 -0.087513 3.858604
v  -4.448328 -0.353833 6.510039
v  -2.222715 -0.353833 4.284426
v  -2.435626 -0.353833 4.071515
v  -2.222715 -0.087513 4.284426
v  -2.435626 -0.087513 4.071515
v  -4.661239 -0.087513 6.297128
v  -4.448328 -0.087513 6.510039
v  -4.266925 -0.004919 6.298312
v  -3.343406 -0.087513 6.102108
v  -2.639394 -0.087513 5.398097
v  -3.549297 -0.087513 4.488194
v  -4.253309 -0.087513 5.192206
v  -2.625778 -0.004919 4.291990
v  -2.984293 -0.004919 5.015680
v  -2.443191 2.983595 4.474578
v  -3.908411 -0.004919 5.574623
v  -4.449512 2.983595 6.115725
v  -3.857131 -0.353833 6.615833
v  -3.725823 2.983595 5.757210
v  -3.166880 2.983595 4.833092
v  -4.575803 -0.004919 5.734321
v  -2.316900 -0.004919 4.855981
v  -3.885521 -0.004919 6.424602
v  -3.007182 -0.004919 4.165700
v  -2.648537 -0.353833 3.858604
v  -3.908411 2.983595 5.574623
v  -4.266925 2.983595 6.298312
v  -3.725823 -0.004919 5.757210
v  -4.449512 -0.004919 6.115725
v  -2.625778 2.983595 4.291990
v  -2.625778 -0.004919 4.291990
v  -2.984293 -0.004919 5.015680
v  -2.984293 -0.004919 5.015680
v  -2.625778 -0.004919 4.291990
v  -2.625778 -0.004919 4.291990
v  -2.984293 -0.004919 5.015680
v  -2.316900 -0.004919 4.855981
v  -3.007182 -0.004919 4.165700
v  -0.806360 1.140069 -10.348189
v  -0.778707 1.109061 -11.253489
v  -0.778796 1.192324 -11.253489
v  -0.796023 1.192324 -11.253489
v  -0.796023 1.174523 -11.253489
v  -0.808082 1.174523 -11.253489
v  -0.808082 1.157296 -11.253489
v  -0.821863 1.157296 -11.253489
v  -0.821863 1.140069 -11.253489
v  -0.806360 1.140069 -11.253489
v  -0.806360 1.125713 -11.253489
v  -0.794875 1.125713 -11.253489
v  -0.794875 1.109061 -11.253489
v  -3.728585 2.237297 -11.224030
v  -3.699220 2.189061 -20.514696
v  -3.699309 2.272325 -20.514696
v  -3.716536 2.272325 -20.514696
v  -3.716536 2.254524 -20.514696
v  -3.728595 2.254524 -20.514696
v  -3.728595 2.237297 -20.514696
v  -3.742376 2.237297 -20.514696
v  -3.742376 2.220070 -20.514696
v  -3.726872 2.220070 -20.514696
v  -3.726872 2.205714 -20.514696
v  -3.715387 2.205714 -20.514696
v  -3.715387 2.189061 -20.514696
v  -0.806360 4.380070 -10.348189
v  -0.778707 4.349062 -11.253489
v  -0.778796 4.432325 -11.253489
v  -0.796023 4.432325 -11.253489
v  -0.796023 4.414524 -11.253489
v  -0.808082 4.414524 -11.253489
v  -0.808082 4.397297 -11.253489
v  -0.821863 4.397297 -11.253489
v  -0.821863 4.380070 -11.253489
v  -0.806360 4.380070 -11.253489
v  -0.806360 4.365715 -11.253489
v  -0.794875 4.365715 -11.253489
v  -0.794875 4.349062 -11.253489
v  -1.552437 4.812906 -11.546779
v  -1.552437 4.896169 -11.546867
v  -1.552437 4.896169 -11.564095
v  -1.552437 4.878368 -11.564095
v  -1.552437 4.878368 -11.576154
v  -1.552437 4.861141 -11.576154
v  -1.552437 4.861141 -11.589935
v  -1.552437 4.843914 -11.589935
v  -1.552437 4.843914 -11.574430
v  -1.552437 4.829558 -11.574430
v  -1.552437 4.829558 -11.562946
v  -1.552437 4.812906 -11.562946
v  -1.805567 4.843914 -11.574430
v  -1.550828 4.812906 -11.251311
v  -1.550918 4.896169 -11.251311
v  -1.568144 4.896169 -11.251311
v  -1.568144 4.878368 -11.251311
v  -1.580203 4.878368 -11.251311
v  -1.580203 4.861141 -11.251311
v  -1.593984 4.861141 -11.251311
v  -1.593984 4.843914 -11.251311
v  -1.578481 4.843914 -11.251311
v  -1.578481 4.829558 -11.251311
v  -1.566996 4.829558 -11.251311
v  -1.566996 4.812906 -11.251311
v  -1.566996 4.829558 -11.009666
v  -0.778707 2.189062 -11.253489
v  -0.778796 2.272325 -11.253489
v  -0.796023 2.272325 -11.253489
v  -0.796023 2.254524 -11.253489
v  -0.808082 2.254524 -11.253489
v  -0.808082 2.237297 -11.253489
v  -0.821863 2.237297 -11.253489
v  -0.821863 2.220070 -11.253489
v  -0.806360 2.220070 -11.253489
v  -0.806360 2.205714 -11.253489
v  -0.794875 2.205714 -11.253489
v  -0.794875 2.189062 -11.253489
v  -0.806360 2.220070 -10.348189
v  -1.538337 4.394198 -11.484158
v  -1.538426 4.310935 -11.484158
v  -1.555652 4.310935 -11.484158
v  -1.555652 4.328736 -11.484158
v  -1.567711 4.328736 -11.484158
v  -1.567711 4.345963 -11.484158
v  -1.581493 4.345963 -11.484158
v  -1.581493 4.363190 -11.484158
v  -1.565989 4.363190 -11.484158
v  -1.565989 4.377546 -11.484158
v  -1.554504 4.377546 -11.484158
v  -1.554504 4.394198 -11.484158
v  -1.567711 4.345963 -20.514265
v  -0.806360 3.300068 -10.348189
v  -0.778707 3.269059 -11.253489
v  -0.778796 3.352323 -11.253489
v  -0.796023 3.352323 -11.253489
v  -0.796023 3.334522 -11.253489
v  -0.808082 3.334522 -11.253489
v  -0.808082 3.317295 -11.253489
v  -0.821863 3.317295 -11.253489
v  -0.821863 3.300068 -11.253489
v  -0.806360 3.300068 -11.253489
v  -0.806360 3.285712 -11.253489
v  -0.794875 3.285712 -11.253489
v  -0.794875 3.269059 -11.253489
v  1.350269 4.139620 -11.253515
v  4.270782 2.522909 -11.253515
v  4.270782 2.522909 -20.514721
v  1.377928 1.140069 -10.348189
v  1.350276 1.109061 -11.253489
v  1.350365 1.192325 -11.253489
v  1.367592 1.192325 -11.253489
v  1.367592 1.174523 -11.253489
v  1.379651 1.174523 -11.253489
v  1.379651 1.157296 -11.253489
v  1.393432 1.157296 -11.253489
v  1.393432 1.140069 -11.253489
v  1.377928 1.140069 -11.253489
v  1.377928 1.125713 -11.253489
v  1.366443 1.125713 -11.253489
v  1.366443 1.109061 -11.253489
v  4.300154 2.237297 -11.224030
v  4.270789 2.189062 -20.514696
v  4.270878 2.272325 -20.514696
v  4.288104 2.272325 -20.514696
v  4.288104 2.254524 -20.514696
v  4.300163 2.254524 -20.514696
v  4.300163 2.237297 -20.514696
v  4.313945 2.237297 -20.514696
v  4.313945 2.220070 -20.514696
v  4.298441 2.220070 -20.514696
v  4.298441 2.205714 -20.514696
v  4.286956 2.205714 -20.514696
v  4.286956 2.189062 -20.514696
v  1.377928 4.380070 -10.348189
v  1.350276 4.349062 -11.253489
v  1.350365 4.432325 -11.253489
v  1.367592 4.432325 -11.253489
v  1.367592 4.414524 -11.253489
v  1.379651 4.414524 -11.253489
v  1.379651 4.397297 -11.253489
v  1.393432 4.397297 -11.253489
v  1.393432 4.380070 -11.253489
v  1.377928 4.380070 -11.253489
v  1.377928 4.365715 -11.253489
v  1.366443 4.365715 -11.253489
v  1.366443 4.349062 -11.253489
v  2.124005 4.812906 -11.546779
v  2.124005 4.896169 -11.546867
v  2.124005 4.896169 -11.564095
v  2.124005 4.878368 -11.564095
v  2.124005 4.878368 -11.576154
v  2.124005 4.861141 -11.576154
v  2.124005 4.861141 -11.589935
v  2.124005 4.843914 -11.589935
v  2.124005 4.843914 -11.574430
v  2.377136 4.843914 -11.574430
v  2.124005 4.829558 -11.574430
v  2.124005 4.829558 -11.562946
v  2.124005 4.812906 -11.562946
v  2.122397 4.812906 -11.251311
v  2.122486 4.896169 -11.251311
v  2.139713 4.896169 -11.251311
v  2.139713 4.878368 -11.251311
v  2.151772 4.878368 -11.251311
v  2.151772 4.861141 -11.251311
v  2.165553 4.861141 -11.251311
v  2.165553 4.843914 -11.251311
v  2.150049 4.843914 -11.251311
v  2.150049 4.829558 -11.251311
v  2.138564 4.829558 -11.251311
v  2.138564 4.829558 -11.009666
v  2.138564 4.812906 -11.251311
v  1.350276 2.189062 -11.253489
v  1.350365 2.272325 -11.253489
v  1.367592 2.272325 -11.253489
v  1.367592 2.254524 -11.253489
v  1.379651 2.254524 -11.253489
v  1.379651 2.237297 -11.253489
v  1.393432 2.237297 -11.253489
v  1.393432 2.220070 -11.253489
v  1.377928 2.220070 -11.253489
v  1.377928 2.220070 -10.348189
v  1.377928 2.205714 -11.253489
v  1.366443 2.205714 -11.253489
v  1.366443 2.189062 -11.253489
v  2.109905 4.394198 -11.484158
v  2.109994 4.310935 -11.484158
v  2.127221 4.310935 -11.484158
v  2.127221 4.328736 -11.484158
v  2.139280 4.328736 -11.484158
v  2.139280 4.345963 -11.484158
v  2.139280 4.345963 -20.514265
v  2.153061 4.345963 -11.484158
v  2.153061 4.363190 -11.484158
v  2.137558 4.363190 -11.484158
v  2.137558 4.377546 -11.484158
v  2.126072 4.377546 -11.484158
v  2.126072 4.394198 -11.484158
v  1.377928 3.300068 -10.348189
v  1.350276 3.269059 -11.253489
v  1.350365 3.352323 -11.253489
v  1.367592 3.352323 -11.253489
v  1.367592 3.334522 -11.253489
v  1.379651 3.334522 -11.253489
v  1.379651 3.317295 -11.253489
v  1.393432 3.317295 -11.253489
v  1.393432 3.300068 -11.253489
v  1.377928 3.300068 -11.253489
v  1.377928 3.285712 -11.253489
v  1.366443 3.285712 -11.253489
v  1.366443 3.269059 -11.253489
v  -0.324038 -0.067656 -10.464714
v  -3.699213 2.522909 -20.514721
v  -3.699213 2.522909 -11.253515
v  -0.778701 4.139620 -11.253515
v  -3.639213 1.748844 -16.865667
v  4.270782 1.782236 -14.913514
v  4.270782 -0.067656 -20.514721
v  -0.801062 4.432345 -23.709558
v  1.350269 2.272345 -11.253515
v  -0.778700 6.897895 -11.359185
v  -4.613664 1.945025 -23.018265
v  -1.538330 2.272345 -20.514290
v  -1.538330 4.563272 -11.484183
v  -1.538330 4.578956 -20.514290
v  2.109898 2.272344 -20.514290
v  2.109898 4.578956 -20.514290
v  2.109898 4.563272 -11.484183
v  4.210782 1.748844 -19.202202
v  -1.538330 2.272345 -11.484183
v  2.109898 2.272345 -11.484183
v  -1.538330 4.126236 -14.696275
v  2.109898 4.126236 -14.696275
v  5.185232 1.945025 -23.018265
v  -3.699213 4.432345 -20.514721
v  1.372631 4.432345 -25.103155
v  1.350269 7.686130 -12.209099
v  -3.699213 -0.067656 -20.514721
v  2.106414 -0.067656 -11.260997
v  -3.639213 2.014020 -14.838909
v  0.428500 6.516250 -10.375868
v  4.270782 4.432345 -20.514721
v  -3.699213 -0.067656 -11.253515
v  -0.778700 2.088519 -10.703896
v  -4.553664 1.946124 -21.193335
v  -0.801062 -0.067656 -25.103155
v  4.270782 0.106543 -16.992756
v  1.350269 6.897895 -11.359185
v  4.270782 1.782235 -12.591864
v  5.125232 1.946124 -21.193335
v  4.270782 1.675554 -16.994263
v  4.210782 1.818133 -17.191648
v  4.270782 0.106543 -12.411024
v  -3.639213 2.029530 -19.293402
v  -3.639213 1.748843 -12.278494
v  1.290269 3.115081 -10.601315
v  1.350269 5.339485 -10.681168
v  -1.538330 4.126236 -17.031321
v  2.109898 4.126236 -17.031321
v  -4.613664 -0.067656 -23.709558
v  5.185232 -0.067656 -23.709558
v  -1.534845 2.917420 -11.260997
v  -4.613664 5.462647 -22.112141
v  5.185232 5.462647 -22.112141
v  1.372631 1.945025 -24.387676
v  -3.699213 0.106543 -16.992756
v  -0.778700 4.259246 -10.681168
v  -0.801062 4.432345 -25.103155
v  -0.718700 5.268359 -11.579788
v  -0.801062 1.945025 -24.387676
v  0.624935 0.896291 -10.464714
v  1.350269 4.442204 -11.253515
v  -4.553664 4.043670 -22.886057
v  5.185232 4.042571 -21.331097
v  -4.613664 4.042571 -21.331095
v  -3.639213 0.108994 -14.762064
v  5.125232 4.043670 -22.886057
v  0.128609 5.719702 -10.443367
v  4.210782 0.108994 -14.762064
v  -0.778700 2.272345 -11.253515
v  1.372631 -0.067656 -25.103155
v  -0.778700 3.352343 -10.375868
v  -0.778700 7.686130 -12.209099
v  1.350269 2.088519 -10.703896
v  1.350269 4.259246 -10.681168
v  -0.778701 4.428919 -20.514292
v  1.350269 4.428919 -20.514292
v  -0.046899 3.107358 -10.435867
v  1.290269 5.268359 -11.579788
v  -2.463219 3.979309 -20.574539
v  3.034788 3.979309 -20.574539
v  -0.778700 4.442204 -11.253515
v  1.372631 -0.067656 -23.709558
v  -0.801062 -0.067656 -23.709558
v  2.109898 4.126236 -19.442043
v  -1.538330 4.126236 -19.442043
v  -3.699213 0.106543 -19.346256
v  1.372631 4.432345 -23.709558
v  4.584039 1.731870 -20.574722
v  4.270782 0.106543 -19.346256
v  -0.718700 6.800460 -10.691236
v  -0.778700 5.339485 -10.681168
v  -0.778700 -0.067656 -11.260997
v  -0.046900 6.800464 -10.435867
v  1.350269 6.804498 -10.899389
v  -1.538330 2.789599 -15.000845
v  -3.699213 0.106543 -12.411024
v  -2.887402 2.080153 -11.253515
v  3.458971 2.080153 -11.253515
v  -1.538330 4.126236 -12.361231
v  2.109898 4.126236 -12.361231
v  -2.419381 3.214549 -11.290576
v  -2.203138 3.268385 -11.350576
v  -2.836257 3.103310 -11.350576
v  2.990949 3.214549 -11.290576
v  2.774707 3.268385 -11.350576
v  3.407825 3.103310 -11.350576
v  -0.718700 3.115081 -10.601315
v  -0.741063 4.343114 -24.400667
v  1.312631 4.343114 -24.400667
v  -1.637494 3.418950 -11.350576
v  2.209063 3.418950 -11.350576
v  -4.012470 1.731870 -20.574722
v  4.270782 -0.067656 -11.253515
v  5.125232 4.343113 -22.126467
v  -4.553664 4.343113 -22.126467
v  -1.001507 2.080020 -11.260997
v  -4.613664 0.106543 -21.420027
v  5.185232 0.106543 -21.420027
v  -0.046899 2.017393 -10.435867
v  0.618468 2.017393 -10.435867
v  -4.553664 2.263645 -22.555698
v  0.618468 4.188120 -10.435867
v  -0.046900 4.188120 -10.435867
v  0.618468 5.260847 -10.435867
v  1.350269 -0.067656 -10.375868
v  1.350269 7.686130 -10.375868
v  0.618468 3.107358 -10.435867
v  -0.718700 6.219613 -11.015526
v  4.270782 2.013237 -14.656961
v  -3.699213 1.733067 -14.506203
v  4.270782 1.733066 -12.184904
v  -0.778700 2.272345 -20.514290
v  1.350269 2.272344 -20.514290
v  -3.699213 1.730771 -19.576792
v  4.270782 1.730771 -19.576792
v  -3.639213 1.930245 -12.592690
v  -3.699213 1.749376 -17.160067
v  1.350269 0.112344 -11.253515
v  4.270782 1.733067 -14.506203
v  5.125232 2.479519 -22.070839
v  1.350269 0.112344 -10.464714
v  -0.150616 0.805154 -10.464714
v  1.350269 0.112344 -10.375868
v  1.350269 0.112344 -10.375868
v  1.290269 6.219613 -11.015526
v  0.285784 6.963814 -10.443367
v  5.125232 1.423844 -22.070839
v  0.895607 0.112344 -10.464714
v  -4.613664 2.272345 -20.514721
v  -0.046899 5.260847 -10.435867
v  -0.778700 6.804499 -11.799118
v  1.350269 6.804499 -11.799118
v  -0.778700 3.352343 -20.514290
v  1.350269 3.352343 -20.514290
v  2.106413 2.917420 -11.260997
v  -4.613664 4.432345 -23.709558
v  5.185232 4.432345 -23.709558
v  -4.613664 1.447774 -22.290047
v  5.185232 2.272344 -20.514721
v  1.290269 5.676422 -11.253515
v  -0.718700 5.676422 -11.253515
v  -1.538330 2.789599 -17.335890
v  2.109898 2.789599 -15.000845
v  2.109898 2.789599 -17.335890
v  0.826623 6.804502 -10.375868
v  -4.613664 3.343168 -21.584177
v  5.125232 3.867363 -21.154333
v  -4.613664 3.352343 -23.709558
v  -4.613664 4.432345 -20.514721
v  5.185232 4.432345 -20.514721
v  5.185232 3.346902 -22.662891
v  -3.981175 4.127237 -20.574722
v  4.552743 4.127237 -20.574722
v  -2.002988 3.324764 -11.350576
v  2.574557 3.324764 -11.350576
v  -0.741063 0.105636 -24.615234
v  -0.035808 6.088989 -10.443367
v  -3.699213 1.782236 -19.183962
v  1.573076 2.080020 -11.260997
v  -0.801062 3.352343 -23.709558
v  4.270782 2.036559 -19.368738
v  -1.534845 -0.067656 -11.260997
v  1.350269 1.192344 -10.375868
v  4.270782 2.272344 -20.514721
v  3.551939 2.789599 -20.514616
v  -3.699213 2.272345 -20.514721
v  -2.980371 2.789599 -20.514616
v  5.185232 -0.067656 -22.112141
v  5.125232 0.105636 -23.245825
v  3.199358 3.136669 -11.350576
v  -2.627790 3.136669 -11.350576
v  2.049898 2.788694 -19.747511
v  -1.478330 2.788694 -19.747513
v  1.372631 0.106543 -24.614334
v  -3.699213 1.782236 -14.913514
v  -4.613664 -0.067656 -22.112141
v  -4.553664 0.105636 -23.245823
v  1.372631 3.361378 -24.117289
v  -3.699213 2.028669 -16.957148
v  -4.613664 3.346902 -22.662889
v  5.185232 3.343168 -21.584177
v  1.350269 -0.067656 -11.260997
v  -0.778700 6.804498 -10.899389
v  -2.964621 3.864064 -20.574614
v  3.536189 3.864064 -20.574614
v  0.607377 5.946003 -10.443367
v  3.603971 3.020329 -11.350576
v  3.799853 2.959222 -11.350576
v  -3.032403 3.020329 -11.350576
v  -3.228284 2.959222 -11.350576
v  1.290269 6.273035 -11.523806
v  -0.718700 6.273035 -11.523806
v  -0.778700 1.192344 -10.375868
v  -4.613664 -0.067656 -20.514721
v  5.185232 -0.067656 -20.514721
v  -1.538330 2.789599 -12.665800
v  2.109898 2.789599 -12.665800
v  -0.778700 5.882857 -20.514290
v  1.352799 5.323257 -20.875813
v  -0.782165 5.450737 -21.009272
v  -0.798659 4.810776 -23.366081
v  -4.613664 1.951681 -21.596478
v  5.125232 1.951681 -22.657063
v  4.210782 1.573904 -16.607718
v  4.270782 1.990934 -16.891788
v  1.290269 6.800460 -10.691236
v  3.259079 0.459269 -11.253515
v  -2.687511 0.459269 -11.253515
v  4.210782 1.682582 -12.369694
v  -4.553664 3.867363 -21.154331
v  -0.583260 4.398931 -11.253463
v  -0.583260 4.398931 -20.509037
v  1.154829 4.398931 -11.253463
v  2.106421 3.568144 -11.350576
v  -1.534852 3.568144 -11.350576
v  -3.699213 1.675553 -12.407087
v  0.591899 4.440056 -10.375868
v  2.369070 3.367868 -11.350576
v  -1.797501 3.367868 -11.350576
v  5.185232 1.664906 -21.407646
v  1.370227 4.810776 -23.366081
v  1.368885 5.450737 -23.174261
v  4.270782 1.675555 -19.368738
v  1.350269 5.882857 -20.514290
v  -0.797316 5.450737 -23.174261
v  -3.699213 1.583574 -19.282888
v  -2.419348 3.215647 -11.350576
v  2.990917 3.215647 -11.350576
v  4.270782 1.358973 -14.732319
v  -0.778700 7.686130 -10.375868
v  -4.553664 2.479519 -22.070839
v  -0.020330 2.439352 -10.375868
v  -3.639213 1.573904 -16.607718
v  -3.699220 2.877827 -11.350575
v  4.270789 2.877827 -11.350575
v  -3.699213 1.358973 -14.732319
v  -0.801062 3.352343 -25.103155
v  0.285784 4.432345 -25.103155
v  1.372631 3.352343 -25.103155
v  -0.778700 4.432345 -12.209099
v  1.350269 4.432345 -12.209099
v  1.350269 1.192344 -11.253515
v  1.350269 6.523818 -10.375868
v  5.185232 3.352343 -23.709558
v  -3.699213 0.106543 -17.449951
v  4.270782 0.106543 -17.449953
v  4.210782 0.105636 -19.804352
v  -3.639213 0.105636 -19.804352
v  -3.699213 0.106543 -12.868220
v  4.270782 0.106543 -12.868220
v  -3.639213 0.108994 -14.304869
v  -0.778700 4.432345 -20.514290
v  -0.020330 3.367329 -10.375868
v  4.270782 2.036557 -12.445031
v  5.185232 2.272344 -23.709558
v  4.270789 2.438853 -11.350576
v  -3.699220 2.438853 -11.350576
v  -3.639213 1.573904 -12.025986
v  -4.613664 1.664906 -21.407646
v  4.210782 0.108994 -14.304869
v  0.591899 1.349387 -10.375868
v  1.350269 4.432345 -20.514290
v  -0.778700 5.518775 -12.209099
v  1.350269 5.518775 -12.209099
v  0.591899 2.439352 -10.375868
v  -1.478330 3.979309 -16.818747
v  2.049898 3.979309 -16.818747
v  2.049898 3.979309 -19.229469
v  -1.478330 3.979309 -19.229469
v  2.049898 3.979309 -12.148657
v  -1.478330 3.979309 -12.148657
v  2.049898 3.979309 -14.483702
v  -1.478330 3.979309 -14.483702
v  -0.718700 5.877634 -11.607849
v  1.290269 5.877634 -11.607849
v  1.350269 3.352343 -10.375868
v  1.312631 1.788158 -24.229296
v  0.285784 5.130419 -11.253464
v  -0.255054 6.804502 -10.375868
v  1.350269 2.272344 -10.375868
v  -0.778700 2.272345 -10.375868
v  -3.699213 1.524527 -12.834199
v  -3.699213 1.358973 -16.992756
v  -0.778700 7.299047 -10.375868
v  5.185232 3.352343 -20.514721
v  -4.613664 3.352343 -20.514721
v  -0.778700 6.523818 -10.375868
v  5.125232 3.342261 -23.140072
v  -4.553664 3.342261 -23.140072
v  -0.020330 1.349387 -10.375868
v  4.270782 1.524528 -15.155495
v  -3.699213 1.524528 -17.415932
v  1.423886 0.459268 -11.260997
v  -0.852317 0.459267 -11.260997
v  -3.639213 1.818133 -19.528181
v  5.185232 4.118998 -21.903038
v  -4.613664 4.118998 -21.903038
v  4.841597 3.864065 -20.574722
v  -4.270028 3.864065 -20.574722
v  -0.741063 4.119430 -24.176443
v  1.312631 4.119430 -24.176443
v  0.860401 3.051165 -10.435867
v  -0.288833 4.131927 -10.435867
v  4.426486 1.573472 -20.514721
v  -3.854918 1.573472 -20.514721
v  -0.778700 4.432345 -10.375868
v  1.350269 4.432345 -10.375868
v  4.210782 1.573904 -18.961216
v  -1.534852 2.917400 -11.350576
v  2.106421 2.917400 -11.350576
v  2.436269 2.844470 -11.350576
v  -1.864700 2.844470 -11.350576
v  1.372631 5.882857 -23.709558
v  -0.801062 5.882857 -23.709558
v  -1.704693 2.879848 -11.350576
v  2.276262 2.879848 -11.350576
v  0.618469 6.800464 -10.435867
v  1.290269 6.800461 -11.590964
v  -0.718700 6.800461 -11.590964
v  -2.070187 2.799036 -11.350576
v  2.641756 2.799036 -11.350576
v  -0.718700 4.446672 -11.552319
v  1.290269 4.446672 -11.552319
v  -3.102564 1.800223 -11.313516
v  3.674132 1.800222 -11.313516
v  -4.553664 1.665094 -23.233425
v  -3.699213 0.106543 -15.189515
v  0.285784 1.084322 -10.464714
v  -3.639213 1.573904 -18.961216
v  4.210782 1.748844 -16.865669
v  3.266557 2.660891 -11.350576
v  -2.694989 2.660891 -11.350576
v  5.185232 1.736175 -21.642284
v  -0.778700 5.518775 -10.375868
v  1.350269 5.518775 -10.375868
v  3.058116 2.706978 -11.350576
v  -2.486547 2.706978 -11.350576
v  -2.270337 2.754782 -11.350576
v  2.841905 2.754782 -11.350576
v  5.125232 1.788158 -22.859886
v  -0.741063 1.788158 -24.229296
v  -3.639213 0.108994 -16.565306
v  4.210782 0.108994 -16.565306
v  4.210782 0.108994 -18.918804
v  -3.639213 0.108994 -18.918804
v  -3.639213 0.108994 -11.983575
v  4.210782 0.108994 -11.983575
v  1.350269 7.021914 -10.375868
v  -4.613664 0.109887 -22.818375
v  5.185232 0.109887 -22.818375
v  -4.553664 0.108994 -20.992577
v  5.125232 0.108994 -20.992577
v  1.312631 0.108995 -24.186886
v  -0.741063 0.108995 -24.186884
v  -0.778700 3.352343 -11.253515
v  1.350269 3.352343 -11.253515
v  4.270782 1.524528 -17.415932
v  4.210782 1.573904 -14.347281
v  -3.639213 1.573904 -14.347281
v  1.290269 6.893857 -11.151031
v  -0.718700 6.893857 -11.151031
v  -3.699213 1.524528 -15.155495
v  4.270782 1.524527 -12.834200
v  -4.239161 0.106543 -20.514721
v  4.810730 0.106543 -20.514721
v  -0.718700 3.058889 -10.843246
v  1.290269 3.058889 -10.843246
v  -0.288833 3.051165 -10.435867
v  -0.288833 1.961200 -10.435867
v  0.860401 1.961200 -10.435867
v  -0.778700 5.272398 -11.787941
v  1.350269 5.272398 -11.787941
v  0.860402 4.131927 -10.435867
v  -0.288833 5.204654 -10.435867
v  0.860402 5.204654 -10.435867
v  4.210782 1.573904 -12.025986
v  3.547675 2.598735 -11.350576
v  -2.976106 2.598735 -11.350576
v  5.125232 2.263646 -21.696936
v  -0.295963 6.517154 -10.375868
v  0.306972 6.359475 -10.443367
v  -4.553664 3.867363 -22.709326
v  5.125232 3.867363 -22.709326
v  -0.020331 6.518678 -10.375868
v  2.049898 3.864065 -19.730869
v  2.049898 3.864065 -14.985104
v  -1.478330 3.864065 -19.730869
v  2.049898 3.864065 -12.650059
v  -1.478330 3.864065 -12.650059
v  -1.478330 3.864065 -17.320148
v  -1.478330 3.864065 -14.985104
v  2.049898 3.864065 -17.320148
v  3.799885 2.958124 -11.290576
v  -3.228317 2.958124 -11.290576
v  1.154829 4.398931 -20.509037
v  0.285784 5.130419 -20.509037
v  -3.699213 1.782235 -12.591864
v  -4.553664 1.951681 -22.657063
v  -3.699213 1.990934 -14.626265
v  -0.778700 6.359488 -11.253515
v  1.350269 6.359488 -11.253515
v  4.210782 0.105636 -15.190414
v  5.185232 1.192344 -20.514721
v  4.857338 2.789599 -20.514721
v  -4.613664 1.192344 -20.514721
v  -4.285769 2.789599 -20.514721
v  3.667792 2.572177 -11.350576
v  -3.096224 2.572177 -11.350576
v  5.185232 4.049648 -22.399645
v  -4.613664 4.049648 -22.399643
v  5.125232 3.346009 -21.106997
v  -4.553664 3.346009 -21.106997
v  -3.639213 1.963268 -12.278494
v  2.049898 2.791839 -14.426773
v  -1.538330 2.792734 -14.427673
v  2.109898 2.792734 -12.092628
v  -1.538330 2.792734 -12.092628
v  -1.538330 2.792734 -19.173441
v  2.109898 2.792734 -19.173441
v  2.049898 2.791839 -16.761818
v  -1.478330 2.791839 -16.761818
v  -0.778700 6.517153 -11.400095
v  1.350269 6.517153 -11.400095
v  -0.741063 4.133795 -24.633026
v  1.312631 4.133795 -24.633026
v  1.372631 2.272344 -23.709558
v  2.978767 2.792734 -20.514530
v  -2.407199 2.792734 -20.514530
v  4.210782 1.991607 -14.869759
v  -1.162310 1.800223 -11.320996
v  1.733878 1.800223 -11.320996
v  -0.778700 1.346396 -10.852739
v  1.290269 5.877633 -10.979582
v  -0.718700 5.877633 -10.979582
v  4.288052 3.858298 -20.514721
v  -3.716484 3.858298 -20.514721
v  -4.613664 1.640249 -21.697668
v  4.210782 1.450840 -19.791954
v  -3.639213 1.450840 -19.791954
v  1.312631 1.665094 -24.602837
v  -4.205982 1.524857 -20.574722
v  4.777550 1.524856 -20.574722
v  4.210782 1.963269 -19.497332
v  5.185232 1.192344 -22.112141
v  0.591899 3.367329 -10.375868
v  -4.553664 1.788158 -22.859886
v  3.300591 1.923287 -11.313516
v  -2.729023 1.923287 -11.313516
v  -0.020330 4.440056 -10.375868
v  -4.613664 1.787726 -21.035782
v  5.185232 1.787726 -21.035782
v  4.284166 2.792734 -20.514721
v  -3.712598 2.792734 -20.514721
v  -3.639213 1.573904 -19.418413
v  4.270782 1.358973 -19.346256
v  3.247370 4.126235 -20.514570
v  -2.675801 4.126235 -20.514570
v  5.125232 1.557261 -22.481455
v  4.270782 1.358973 -12.411024
v  -3.699213 0.106543 -14.732319
v  -1.534845 4.548933 -11.260997
v  2.106413 4.548933 -11.260997
v  -3.639213 0.105636 -15.190415
v  1.350269 3.364729 -10.834354
v  -0.778700 3.364729 -10.834354
v  1.355076 4.810776 -21.201090
v  5.185232 1.738782 -23.210903
v  -0.801062 1.738782 -24.580315
v  -3.639213 1.963269 -17.160799
v  5.125232 2.263646 -22.555698
v  5.125232 3.812622 -23.102036
v  -4.553664 3.812622 -23.102036
v  5.125232 3.730012 -21.571241
v  -4.553664 3.730012 -21.571239
v  4.270782 1.698876 -14.656961
v  -3.639213 1.682582 -14.691344
v  -3.768627 3.979309 -20.574722
v  4.340195 3.979309 -20.574722
v  1.372631 1.192344 -23.709558
v  0.184195 6.898493 -10.375868
v  -3.699213 1.358973 -12.411024
v  4.210782 1.963268 -12.278494
v  1.350269 2.444055 -10.852661
v  -0.778700 2.444054 -10.852661
v  4.270782 1.358973 -16.992756
v  -3.699213 1.675554 -17.032206
v  1.350269 1.346396 -10.852739
v  -3.114061 0.455925 -11.253515
v  3.685630 0.455925 -11.253515
v  1.372631 4.432345 -23.709558
v  1.290269 4.188120 -10.578585
v  -0.718700 4.188120 -10.578585
v  1.290269 5.268359 -10.578585
v  -0.718700 5.268359 -10.578585
v  1.290269 2.017393 -10.601315
v  -0.718700 2.017393 -10.601315
v  -0.718700 6.517780 -10.663767
v  1.290269 6.517780 -10.663767
v  -0.801062 4.432345 -23.709558
v  1.350269 4.444966 -10.834289
v  -0.778700 4.444966 -10.834289
v  3.858044 2.530112 -11.350576
v  -3.286476 2.530112 -11.350576
v  -0.783507 4.810776 -21.201090
v  -0.801062 1.192344 -23.709558
v  -0.801062 3.361378 -24.117287
v  1.372631 3.352343 -23.709558
v  -0.778700 -0.067656 -10.375868
v  4.270782 1.962737 -19.202934
v  -0.883116 1.923287 -11.320996
v  1.454685 1.923287 -11.320996
v  0.777892 5.450737 -20.514290
v  -0.206323 5.450737 -20.514290
v  4.270782 0.106543 -14.732319
v  -4.613664 2.272345 -21.703556
v  4.270782 1.749376 -17.160067
v  4.270782 0.106543 -15.189515
v  0.868432 6.516249 -10.435867
v  1.290269 6.516247 -11.840927
v  -0.718700 6.516247 -11.840927
v  -0.778700 6.517152 -10.940300
v  1.350269 6.517152 -10.940300
v  0.306972 5.675517 -10.375868
v  4.210782 1.698093 -19.440968
v  -3.639213 1.674655 -19.368834
v  -0.801062 3.355484 -24.750475
v  1.372631 0.112344 -25.103155
v  1.372631 2.272344 -25.103155
v  1.372631 3.355484 -24.750477
v  1.290269 6.517781 -11.123563
v  -0.718700 6.517781 -11.123563
v  0.285784 5.518775 -10.375868
v  -4.613664 1.192344 -22.112141
v  -4.613664 1.424744 -22.070934
v  -3.699213 2.036557 -12.445031
v  -1.170676 0.455928 -11.260997
v  1.742244 0.455928 -11.260997
v  -3.811712 0.108994 -20.574720
v  4.383280 0.108994 -20.574720
v  -3.639213 2.014020 -19.440968
v  0.406428 6.871465 -10.375868
v  0.070451 6.273035 -10.443367
v  0.591900 6.518678 -10.375868
v  4.210782 2.029530 -17.069599
v  0.969710 4.810776 -20.514290
v  0.549219 6.219613 -10.443367
v  1.290269 6.965986 -11.253590
v  -0.718700 6.965986 -11.253590
v  1.290269 4.131927 -10.820518
v  -0.718700 4.131927 -10.820518
v  1.290269 5.212166 -10.820518
v  -0.718700 5.212166 -10.820518
v  1.290269 1.961200 -10.843246
v  -0.718700 1.961200 -10.843246
v  -0.778700 6.518676 -11.564394
v  1.350269 6.518676 -11.564394
v  -3.699213 1.733084 -16.766699
v  -3.639213 1.573904 -12.483181
v  4.210782 1.573904 -12.483181
v  4.270782 0.112344 -15.191315
v  4.210782 1.573904 -17.064913
v  -3.639213 1.573904 -17.064913
v  4.210782 1.573904 -14.804477
v  -3.639213 1.573904 -14.804477
v  4.210782 1.573904 -19.418413
v  4.270782 1.733084 -16.766697
v  0.861747 0.548664 -10.375868
v  -2.836289 3.102211 -11.290576
v  3.407858 3.102211 -11.290576
v  -0.778700 4.444958 -11.835111
v  1.350269 4.444958 -11.835111
v  0.880641 4.437493 -10.375868
v  -0.309072 4.437493 -10.375868
v  -0.290178 0.548664 -10.464714
v  0.285784 4.432345 -20.514290
v  -0.037119 4.813487 -20.514290
v  -2.003021 3.323666 -11.290576
v  2.574589 3.323666 -11.290576
v  0.285784 3.352343 -10.375868
v  0.874712 3.364737 -10.375868
v  -0.303143 3.364737 -10.375868
v  -3.699213 1.733034 -19.120012
v  -4.613664 3.361377 -21.843088
v  5.185232 3.361377 -21.843088
v  -2.203170 3.267287 -11.290576
v  2.774739 3.267287 -11.290576
v  -2.627822 3.135570 -11.290576
v  3.199391 3.135570 -11.290576
v  0.870034 1.346444 -10.375868
v  -0.298465 1.346444 -10.375868
v  0.869984 2.436393 -10.375868
v  -0.298415 2.436393 -10.375868
v  -3.699213 1.733066 -12.184904
v  -1.797533 3.366770 -11.290576
v  2.369102 3.366770 -11.290576
v  -3.032436 3.019230 -11.290576
v  3.604004 3.019230 -11.290576
v  -0.149482 6.871590 -10.375868
v  0.721025 5.332977 -10.435867
v  -0.149457 5.332977 -10.435867
v  -0.149457 4.260251 -10.435867
v  0.721025 4.260251 -10.435867
v  -0.149456 3.179488 -10.435867
v  0.721025 3.179488 -10.435867
v  0.721025 2.089523 -10.435867
v  -0.718700 5.340488 -11.682345
v  1.290269 5.340488 -11.682345
v  -0.149457 2.089522 -10.435867
v  1.290269 3.187211 -10.703871
v  -0.718700 3.187211 -10.703871
v  0.721026 6.872594 -10.435867
v  1.290269 6.872589 -10.793794
v  -0.718700 6.872589 -10.793794
v  -0.718700 6.872590 -11.693521
v  1.290269 6.872590 -11.693521
v  -1.637526 3.417851 -11.290576
v  2.209095 3.417851 -11.290576
v  4.270782 1.733034 -19.120012
v  -3.639213 1.963269 -16.865667
v  1.350269 2.447076 -10.574745
v  -0.778700 2.447076 -10.574745
v  -3.639213 1.818133 -14.926125
v  4.210782 1.818133 -14.926126
v  -3.639213 1.698092 -17.104433
v  4.210782 1.720505 -12.548109
v  4.210782 1.930245 -12.592690
v  -0.778700 1.349387 -10.574745
v  1.350269 1.349386 -10.574745
v  4.210782 1.682582 -14.691344
v  1.350269 5.518775 -11.253515
v  -0.778700 5.518775 -11.253515
v  0.143068 6.516250 -10.375868
v  5.125232 1.639717 -21.696938
v  -3.699213 1.675554 -14.766682
v  4.270782 1.675554 -12.445031
v  0.549219 5.815379 -10.375868
v  -3.699213 1.962737 -19.202934
v  5.185232 3.356636 -22.447842
v  -4.613664 3.356636 -22.447842
v  0.895607 -0.067656 -10.464714
v  -4.856044 0.678776 -20.497772
v  -4.586076 0.731031 -20.487436
v  -4.854896 0.762040 -20.498920
v  -4.866381 0.731031 -20.838051
v  1.352549 4.813487 -20.840067
v  -0.778700 6.156989 -11.607023
v  1.350269 6.156989 -11.607023
v  -3.699213 1.192344 -20.514721
v  4.270782 1.192344 -20.514721
v  1.367701 4.813487 -23.005056
v  -3.639213 1.674654 -12.445126
v  4.512946 2.296036 -11.237151
v  4.525005 2.278235 -11.579153
v  4.511797 2.212773 -11.238299
v  4.242978 2.243781 -11.226814
v  -0.780980 4.813487 -20.840067
v  -0.796132 4.813487 -23.005056
v  -0.398141 4.810776 -20.514290
v  0.608687 4.813487 -20.514290
v  -3.699213 1.358974 -19.346256
v  4.210782 1.731870 -12.641528
v  -3.639213 1.731870 -12.641528
v  -3.699213 1.782235 -14.581905
v  5.185232 2.478619 -22.181702
v  -1.538330 3.858298 -14.431560
v  2.109898 3.858298 -14.431560
v  1.367592 7.004093 -12.226389
v  1.377928 6.955283 -12.236726
v  1.379651 7.004093 -10.346466
v  -0.821863 6.969639 -12.252230
v  4.270782 1.962737 -17.160067
v  4.270782 1.782235 -12.260256
v  -4.200757 3.988211 -20.514721
v  4.772326 3.988211 -20.514721
v  1.377928 7.232417 -12.236726
v  1.367592 7.281226 -12.226389
v  1.366443 7.232417 -10.359674
v  -0.808082 7.263999 -12.238448
v  -0.796023 5.518755 -12.226389
v  -0.806360 5.452144 -10.348189
v  -0.794875 5.452144 -12.225241
v  1.377928 5.466500 -12.236726
v  1.290269 5.719702 -11.465647
v  -0.718700 5.719702 -11.465647
v  5.427613 0.678776 -20.497772
v  5.437950 0.745387 -20.487436
v  5.437950 0.745387 -20.838051
v  5.157645 0.731031 -20.487436
v  -4.630075 3.979371 -20.272953
v  -4.289796 4.031627 -20.262617
v  -4.640412 4.045982 -20.262617
v  -4.640412 4.045982 -20.542921
v  -0.809959 5.158405 -20.260895
v  -0.808237 5.109596 -20.262617
v  -0.808237 5.109596 -20.542921
v  -0.457621 5.123951 -20.262617
v  -2.895358 3.988211 -20.514603
v  3.466927 3.988211 -20.514603
v  -0.778700 5.762628 -11.064233
v  1.350269 5.762628 -11.064233
v  -3.699213 1.962737 -14.894543
v  1.061445 5.176206 -23.957792
v  1.051108 5.123950 -23.687824
v  1.062593 5.109594 -23.956644
v  1.401723 5.109594 -23.968128
v  4.210782 1.674654 -17.032301
v  1.365657 7.742988 -11.956481
v  1.096359 7.773996 -11.944997
v  1.366805 7.808450 -11.955333
v  1.377142 7.773996 -12.235516
v  -0.795236 7.826251 -11.955333
v  -0.805573 7.759641 -11.944997
v  -0.524791 7.773996 -11.944997
v  -0.805573 7.773996 -12.235516
v  -3.639213 1.450840 -12.399527
v  4.210782 1.450840 -12.399527
v  -3.639213 1.731870 -14.962824
v  4.210782 1.731870 -14.962824
v  1.366805 7.826251 -10.358356
v  1.096359 7.773996 -10.348021
v  1.377142 7.759641 -10.348021
v  1.377142 7.773996 -10.638539
v  -4.613664 2.410537 -22.391237
v  4.210782 1.731870 -17.223261
v  4.210782 1.963269 -14.600144
v  5.200613 4.062634 -23.951393
v  4.845977 4.014399 -23.978382
v  5.213821 3.997171 -23.964602
v  5.212098 4.045981 -23.682575
v  -4.629045 3.237485 -23.951393
v  -4.289913 3.220832 -23.962877
v  -4.642252 3.172023 -23.964600
v  -4.640529 3.220832 -23.682573
v  -3.639213 1.818133 -19.171352
v  1.616208 5.176206 -23.401882
v  1.626545 5.123950 -23.742161
v  1.615060 5.109594 -23.403030
v  1.344518 5.141178 -23.389824
v  4.872850 2.412337 -20.274101
v  4.845861 2.364102 -20.247112
v  5.213704 2.364102 -20.260895
v  4.861365 2.381329 -20.542921
v  1.365657 5.964258 -20.828100
v  1.378864 6.012493 -20.487247
v  1.366805 6.029720 -20.829248
v  1.096359 5.995266 -20.839586
v  -4.629045 4.062634 -23.951393
v  -4.640529 4.045981 -23.682575
v  -4.642252 3.997171 -23.964602
v  -4.289913 4.045981 -23.962879
v  -3.639213 1.450840 -14.720821
v  4.210782 1.450840 -14.720821
v  4.210782 1.450840 -19.334757
v  4.210782 1.450840 -16.981258
v  -3.639213 1.450840 -16.981258
v  -3.639213 1.731870 -17.223261
v  4.511797 0.575977 -18.333988
v  4.242874 0.561621 -18.345474
v  4.525005 0.527167 -18.347197
v  4.523283 0.561621 -17.994858
v  4.285829 2.885162 -11.013479
v  3.946698 2.899518 -11.001995
v  4.299037 2.933971 -11.000273
v  4.297314 2.899518 -11.282299
v  -0.794088 7.759641 -10.627054
v  -0.807296 7.808450 -10.640263
v  -0.805573 7.773996 -10.348021
v  -0.524791 7.773996 -10.638539
v  -4.301281 4.821173 -20.274101
v  -4.288074 4.869982 -20.260895
v  -4.640412 4.821173 -20.262617
v  -4.289796 4.835528 -20.542921
v  4.285829 0.526019 -11.013479
v  4.299037 0.574829 -11.000272
v  3.946698 0.540375 -11.001994
v  4.297314 0.540375 -11.282298
v  -3.714427 4.069621 -20.292286
v  -3.725912 4.052968 -20.561504
v  -3.727634 4.004159 -20.279078
v  -3.373573 4.021385 -20.279078
v  4.285995 4.069621 -20.292286
v  4.299202 4.004159 -20.279078
v  3.945141 4.021385 -20.279078
v  4.297480 4.052968 -20.561504
v  0.285784 1.084322 -10.375868
v  5.201644 3.997173 -20.272953
v  5.211981 4.045982 -20.262617
v  5.211981 4.031627 -20.542921
v  4.861365 4.031627 -20.262617
v  4.861365 1.570536 -20.262615
v  4.859642 1.521726 -20.260893
v  4.861365 1.556180 -20.542919
v  5.211981 1.556180 -20.262615
v  -0.491025 5.092942 -23.956644
v  -0.464036 5.141178 -23.983631
v  -0.479540 5.123950 -23.687824
v  -0.830154 5.123950 -23.968128
v  -1.034205 5.109596 -20.487438
v  -1.035928 5.158405 -20.485714
v  -0.753901 5.123951 -20.487438
v  -1.049709 5.141178 -20.853558
v  -1.795231 4.896167 -18.005039
v  -1.805567 4.829556 -17.994701
v  -1.805567 4.843912 -18.345318
v  -1.524785 4.829556 -17.994701
v  -3.715409 1.444333 -11.012331
v  -3.714261 1.377723 -11.013479
v  -3.375129 1.392078 -11.001994
v  -3.725746 1.392078 -11.282299
v  -4.640412 1.570536 -20.262615
v  -4.288074 1.538953 -20.260893
v  -4.642135 1.521726 -20.260893
v  -4.640412 1.556180 -20.542919
v  -0.013594 5.877635 -10.375868
v  -3.941378 2.951771 -16.078934
v  -3.940229 2.885160 -16.077784
v  -3.953437 2.933970 -15.736931
v  -3.670932 2.899516 -16.089270
v  -1.238201 1.015536 -11.008516
v  -1.227865 0.963281 -11.278588
v  -1.227865 0.948926 -10.998180
v  -1.578481 0.963281 -10.998180
v  5.200613 3.237485 -23.951393
v  5.213821 3.172023 -23.964600
v  4.859758 3.189250 -23.964600
v  5.212098 3.220832 -23.682573
v  1.350269 4.765993 -11.260997
v  -0.778700 4.765993 -11.260997
v  -0.778700 4.447567 -10.552016
v  1.350269 4.447567 -10.552016
v  -0.778700 3.367329 -10.552016
v  1.350269 3.367329 -10.552016
v  -4.854896 3.237486 -20.498920
v  -4.586076 3.206478 -20.487438
v  -4.856044 3.172024 -20.497772
v  -4.866381 3.206477 -20.838053
v  2.109898 3.858298 -19.177326
v  -1.538330 3.858298 -19.177326
v  -1.538330 3.858298 -16.766603
v  2.109898 3.858298 -16.766603
v  -3.940229 2.868509 -13.821579
v  -3.953437 2.933971 -13.834786
v  -3.670932 2.885161 -13.833064
v  -3.951714 2.899517 -13.482449
v  0.151098 6.837667 -10.443367
v  4.511797 2.229425 -13.821579
v  4.242604 2.243781 -13.833064
v  4.525005 2.278234 -13.834786
v  4.525005 2.261008 -13.480725
v  4.871818 0.678775 -23.952541
v  4.861481 0.745386 -23.962877
v  4.861481 0.731031 -23.682571
v  5.212098 0.731030 -23.962875
v  1.369469 5.176207 -20.272953
v  1.379805 5.109596 -20.542921
v  1.379805 5.109596 -20.262617
v  1.029190 5.123951 -20.262617
v  4.270782 1.962737 -14.894543
v  -4.301281 2.412337 -20.274101
v  -4.640412 2.381329 -20.262617
v  -4.274292 2.364102 -20.247112
v  -4.289796 2.381329 -20.542921
v  -1.538330 3.858298 -12.096514
v  2.109898 3.858298 -12.096514
v  -4.553664 3.936679 -21.846294
v  5.125232 3.936679 -21.846294
v  -3.951714 2.279382 -17.994860
v  -3.671036 2.265026 -17.994860
v  -3.953437 2.230572 -17.993135
v  -3.951714 2.265026 -18.345474
v  -3.940229 2.868508 -15.750138
v  -3.670932 2.899516 -15.738653
v  1.377928 6.457188 -12.236726
v  1.379651 6.488770 -12.238448
v  -0.808082 6.488770 -12.238448
v  1.366443 6.457188 -10.359674
v  1.367592 6.523799 -10.358526
v  0.285791 6.471544 -10.348189
v  -3.725746 0.526019 -11.001994
v  -3.386614 0.526019 -11.013479
v  -3.727468 0.574829 -11.000272
v  -3.725746 0.540375 -11.282298
v  4.871701 0.696577 -20.272953
v  4.861365 0.745387 -20.262615
v  4.861365 0.745387 -20.542919
v  5.211981 0.731032 -20.262615
v  4.512946 0.574829 -11.567094
v  4.523283 0.526019 -11.577430
v  4.523283 0.540375 -11.226814
v  4.242978 0.540375 -11.577430
v  4.871818 4.887783 -23.952541
v  4.872966 4.821172 -23.951393
v  5.212098 4.821172 -23.962879
v  4.861481 4.821172 -23.682573
v  4.512946 0.527167 -16.078932
v  4.523283 0.561621 -15.738653
v  4.523283 0.575977 -16.089268
v  4.242874 0.561621 -16.089268
v  -4.856044 4.869982 -20.497772
v  -4.866381 4.821173 -20.487438
v  -4.586076 4.835528 -20.487438
v  -4.866381 4.835528 -20.838053
v  -3.941378 0.509366 -13.822726
v  -3.951714 0.575977 -13.833063
v  -3.671306 0.561621 -13.833063
v  -3.953437 0.527168 -13.480724
v  -4.301281 3.220833 -20.274101
v  -4.289796 3.206478 -20.542921
v  -4.288074 3.172024 -20.260895
v  -4.640412 3.220833 -20.262617
v  5.426465 2.395684 -20.826567
v  5.427613 2.346875 -20.827717
v  5.157645 2.381329 -20.838053
v  5.437950 2.381329 -20.487438
v  -2.411085 3.858297 -20.514530
v  2.982653 3.858297 -20.514530
v  1.367592 7.299027 -10.358526
v  0.285791 7.246772 -10.348189
v  5.426465 1.587188 -20.826567
v  5.453454 1.538953 -20.853556
v  5.157645 1.556180 -20.838051
v  5.439672 1.538953 -20.485714
v  4.523283 2.279383 -15.738653
v  4.525005 2.230573 -15.736930
v  4.242604 2.265027 -15.738653
v  4.523283 2.265027 -16.089270
v  5.185232 1.447774 -22.290047
v  -3.951714 0.575977 -15.738652
v  -3.953437 0.527168 -15.736930
v  -3.671306 0.561621 -15.738652
v  -3.951714 0.561621 -16.089268
v  -3.951714 0.575977 -18.345474
v  -3.967218 0.544394 -18.360977
v  -3.671306 0.561621 -18.345474
v  -3.951714 0.561621 -17.994858
v  4.511797 2.229426 -11.565946
v  4.242978 2.243781 -11.577431
v  -0.796023 6.523799 -10.358526
v  -0.794875 6.457188 -10.359674
v  0.285777 6.471544 -10.348189
v  -4.640529 4.821172 -23.962879
v  -4.642252 4.869981 -23.964602
v  -4.301397 4.821172 -23.951393
v  -4.640529 4.835527 -23.682575
v  4.512946 1.378870 -15.748990
v  4.523283 1.427679 -15.738653
v  4.523283 1.413323 -16.089270
v  4.242739 1.427679 -15.738653
v  2.366799 4.896169 -11.234152
v  2.365651 4.829558 -11.235300
v  2.124005 4.861141 -11.222093
v  1.594289 5.092943 -20.826567
v  1.325470 5.123951 -20.838053
v  1.621278 5.141178 -20.853558
v  1.605774 5.123951 -20.487438
v  1.377928 5.452144 -10.348189
v  1.379651 5.500954 -10.346466
v  0.285791 5.466500 -10.348189
v  -1.566996 3.734263 -11.009665
v  -1.593984 3.782499 -10.982676
v  -1.578481 3.765272 -11.278858
v  -1.226142 3.799726 -10.996457
v  -4.300249 3.979370 -23.952541
v  -4.289913 4.031626 -23.682573
v  -0.806360 6.457188 -12.236726
v  -3.699213 1.782236 -16.847429
v  4.270782 1.782236 -19.183962
v  -4.553664 1.423844 -22.181795
v  1.379651 5.500954 -12.238448
v  -0.796023 7.004093 -12.226389
v  -0.806360 6.969639 -10.348189
v  4.859642 4.869982 -20.260895
v  5.213704 4.852756 -20.260895
v  4.872850 4.821173 -20.274101
v  4.861365 4.835528 -20.542921
v  5.201762 4.887783 -23.952541
v  5.212098 4.835527 -23.682575
v  4.511797 1.361069 -13.493933
v  4.523283 1.377722 -13.833063
v  4.538786 1.409304 -13.466944
v  4.242739 1.392078 -13.482448
v  -3.639213 2.029530 -17.069599
v  -3.699213 1.698876 -19.440514
v  1.372631 4.133320 -24.632259
v  -0.801062 4.133320 -24.632257
v  -4.300249 4.887783 -23.952541
v  -4.289913 4.821172 -23.682573
v  3.944975 1.426532 -11.000272
v  4.297314 1.392078 -11.001994
v  3.946698 1.392078 -11.001994
v  3.946698 1.392078 -11.282299
v  -3.373407 2.278235 -11.000272
v  -3.375129 2.243781 -11.001994
v  -3.375129 2.243781 -11.282299
v  -3.725746 2.243781 -11.001994
v  -1.226142 2.398730 -10.996457
v  -1.227865 2.364277 -10.998180
v  -1.227865 2.364277 -11.278723
v  -1.578481 2.364277 -10.998180
v  1.797711 2.398730 -10.996457
v  2.150049 2.364277 -10.998180
v  1.799433 2.364277 -10.998180
v  1.799433 2.364277 -11.278723
v  4.525005 2.933970 -15.736931
v  4.242500 2.899516 -15.738653
v  4.523283 2.899516 -15.738653
v  4.523283 2.885160 -16.089270
v  -1.807290 4.878367 -13.834630
v  -1.805567 4.843914 -13.832908
v  -1.524785 4.829558 -13.832908
v  -1.805567 4.843914 -13.482292
v  -3.699213 2.036558 -19.368738
v  -4.630193 2.329073 -23.952541
v  -4.640529 2.395683 -23.682573
v  -4.640529 2.381328 -23.962875
v  -4.288190 2.364101 -23.964600
v  -0.150616 0.805154 -10.375868
v  -1.226142 4.878368 -10.996458
v  -1.227865 4.843914 -10.998180
v  -1.226142 4.861141 -11.251311
v  1.797711 4.878368 -10.996458
v  1.797711 4.861141 -11.251311
v  1.799433 4.843914 -10.998180
v  -3.953437 1.426532 -11.225092
v  -3.951714 1.392078 -11.226814
v  -3.940229 1.377723 -11.565946
v  -3.671410 1.392078 -11.226814
v  -4.613664 2.478619 -22.181702
v  -4.630075 3.154222 -20.272953
v  -4.640412 3.206478 -20.542921
v  4.512946 1.444333 -11.567094
v  4.523283 1.392078 -11.226814
v  4.511797 1.377722 -11.565946
v  4.242978 1.392078 -11.577431
v  -3.940229 0.575977 -13.493933
v  -3.671306 0.561621 -13.482448
v  4.525005 0.527168 -13.834785
v  4.523283 0.561621 -13.482448
v  4.523283 0.561621 -13.833063
v  4.242874 0.561621 -13.833063
v  1.366443 6.955283 -10.359674
v  0.285791 6.969639 -10.348189
v  -1.043491 5.092942 -23.403030
v  -1.056699 5.141178 -23.389822
v  -1.054976 5.123950 -23.742161
v  -0.772949 5.141178 -23.389824
v  -4.613664 1.736176 -22.610350
v  -0.821863 5.483727 -10.332685
v  0.285777 5.466500 -10.348189
v  1.810918 0.932273 -11.009665
v  1.797711 0.997735 -10.996457
v  1.799433 0.963282 -11.278588
v  2.150049 0.963282 -10.998180
v  -3.639213 1.963269 -14.600144
v  4.210782 1.963269 -16.865669
v  1.797711 3.799726 -10.996457
v  1.799433 3.765272 -11.278858
v  1.799433 3.765272 -10.998180
v  2.151772 3.799726 -10.996457
v  4.525005 2.230572 -18.347197
v  4.511797 2.279382 -18.333990
v  4.242604 2.265026 -18.345474
v  4.523283 2.265026 -17.994860
v  1.366443 2.205714 -11.253489
v  1.393432 2.237297 -11.253489
v  -0.794875 2.205714 -11.253489
v  -0.821863 2.237297 -11.253489
v  -1.794082 4.829557 -16.077627
v  -1.821071 4.861139 -16.104616
v  -1.524785 4.843913 -16.089113
v  -1.805567 4.843913 -15.738497
v  -0.801062 1.658992 -24.190681
v  1.372631 1.658992 -24.190681
v  4.871701 3.154222 -20.272953
v  4.861365 3.206478 -20.262617
v  4.861365 3.206478 -20.542921
v  5.211981 3.220833 -20.262617
v  -0.778700 6.834774 -11.126334
v  1.350269 6.834774 -11.126334
v  -0.808082 7.281226 -10.346466
v  0.285777 7.246772 -10.348189
v  -0.806360 7.246772 -10.348189
v  -1.239350 3.750916 -11.009665
v  -1.227865 3.765272 -11.278858
v  -4.301281 0.762040 -20.274101
v  -4.628927 0.745387 -20.274101
v  -4.288074 0.713805 -20.260893
v  -4.289796 0.745387 -20.542919
v  -3.373573 4.878365 -20.279078
v  -3.375295 4.829556 -20.280800
v  -3.375295 4.843912 -20.561583
v  -3.725912 4.843912 -20.280800
v  -3.940229 1.444331 -18.006342
v  -3.671171 1.413323 -17.994858
v  -3.953437 1.378869 -17.993135
v  -3.951714 1.413323 -18.345474
v  2.378858 4.878366 -15.736774
v  2.096354 4.829557 -15.738497
v  2.377136 4.843913 -15.738497
v  2.378858 4.861139 -16.090836
v  5.201762 2.329073 -23.952541
v  5.212098 2.381328 -23.962875
v  4.859758 2.364101 -23.964600
v  5.212098 2.381328 -23.682573
v  -3.953437 2.933971 -11.225092
v  -3.951714 2.899518 -11.226815
v  -3.951714 2.899518 -11.577431
v  -3.671410 2.899518 -11.226815
v  -3.953437 2.278235 -11.225092
v  -3.951714 2.243781 -11.226814
v  -3.951714 2.243781 -11.577431
v  -3.671410 2.243781 -11.226814
v  3.945141 4.878365 -20.279078
v  4.285995 4.829556 -20.292286
v  3.946864 4.843912 -20.280800
v  3.946864 4.843912 -20.561583
v  -4.553664 3.945168 -22.431187
v  5.125232 3.945168 -22.431187
v  4.270782 1.782236 -16.847429
v  -3.940229 1.377722 -13.821579
v  -3.953437 1.426531 -13.834786
v  -3.671171 1.377722 -13.833064
v  -3.953437 1.409304 -13.480725
v  4.285995 3.226422 -20.292286
v  4.299202 3.177613 -20.279078
v  4.297480 3.226422 -20.561424
v  3.945141 3.194839 -20.279078
v  4.210782 1.731870 -14.505628
v  -4.553664 1.659099 -20.995476
v  5.125232 1.659099 -20.995476
v  4.525005 2.933971 -11.225092
v  4.523283 2.885162 -11.577431
v  4.523283 2.899518 -11.226815
v  4.242978 2.899518 -11.226815
v  1.350269 0.112344 -10.375868
v  -3.639213 1.450840 -19.334757
v  -0.805573 5.980911 -20.488970
v  -0.807296 6.012493 -20.487247
v  -0.807296 6.029720 -20.841309
v  -0.524791 5.995266 -20.488970
v  -4.881885 2.381329 -20.471933
v  -4.866381 2.381329 -20.838053
v  -4.856044 2.346875 -20.497772
v  -4.586076 2.381329 -20.487438
v  2.138564 3.734263 -11.009665
v  2.150049 3.765272 -11.278858
v  -3.814611 1.444845 -20.574722
v  4.386180 1.444845 -20.574722
v  -3.941378 0.592630 -11.237151
v  -3.671410 0.526019 -11.226814
v  -3.940229 0.526019 -11.238299
v  -3.951714 0.540375 -11.577430
v  -2.690408 1.794120 -11.253515
v  3.261976 1.794120 -11.253515
v  4.871818 3.979370 -23.952541
v  4.861481 4.031626 -23.682573
v  -4.300249 1.521725 -23.952541
v  -4.289913 1.556179 -23.682571
v  -4.289913 1.570535 -23.962875
v  -4.640529 1.556179 -23.962875
v  -3.699213 1.444737 -18.922602
v  -3.699213 1.444737 -16.569103
v  4.270782 1.444737 -18.922602
v  4.270782 1.444737 -16.569103
v  -3.699213 1.444737 -14.308665
v  4.270782 1.444737 -14.308665
v  -3.941378 0.592630 -11.567094
v  -3.671410 0.540375 -11.577430
v  -1.807290 4.878368 -11.222093
v  -1.805567 4.843914 -11.223815
v  -1.552437 4.861141 -11.222093
v  -3.941378 2.278234 -13.492785
v  -3.951714 2.229425 -13.482448
v  -3.671036 2.243781 -13.482448
v  -3.951714 2.243781 -13.833064
v  -3.699213 1.583574 -16.929390
v  -3.714261 2.885162 -11.013479
v  -3.727468 2.933971 -11.000273
v  -3.725746 2.899518 -11.282299
v  -3.375129 2.899518 -11.001995
v  -0.489492 5.980909 -23.728012
v  -0.476284 6.029719 -23.741219
v  -0.828624 5.995265 -23.739498
v  -0.476284 6.012492 -23.456993
v  -3.953437 2.278234 -13.834786
v  -3.671036 2.243781 -13.833064
v  -3.953437 2.933971 -13.480725
v  -3.670932 2.899517 -13.482449
v  -0.801062 4.118998 -24.177238
v  1.372631 4.118998 -24.177238
v  -3.699213 1.782235 -12.260256
v  -4.300249 3.172023 -23.952541
v  -4.289913 3.206477 -23.682571
v  3.957034 2.296036 -11.012331
v  3.946698 2.243781 -11.282299
v  3.946698 2.243781 -11.001994
v  4.297314 2.243781 -11.001994
v  2.366799 4.896169 -13.492628
v  2.096353 4.829558 -13.482292
v  2.377136 4.843914 -13.482292
v  2.377136 4.843914 -13.832908
v  0.607377 6.088989 -10.375868
v  1.061061 5.964257 -23.728012
v  1.047853 6.012492 -23.456993
v  1.047853 6.012492 -23.741220
v  1.401915 6.012492 -23.741219
v  -4.642252 1.521725 -23.964600
v  -4.640529 1.570535 -23.682573
v  4.525005 1.426531 -13.834786
v  4.242739 1.377722 -13.833064
v  4.270782 1.583574 -14.668952
v  -4.613664 1.658991 -22.821270
v  5.185232 1.658991 -22.821270
v  -3.951714 1.377722 -13.482448
v  -3.671171 1.392078 -13.482448
v  3.946864 3.226422 -20.280800
v  3.946864 3.212067 -20.561424
v  2.109898 3.794861 -17.334461
v  -1.538330 3.794861 -17.334461
v  2.109898 3.794861 -14.999416
v  -1.538330 3.794861 -14.999415
v  4.270782 1.444737 -11.987371
v  -3.699213 1.444737 -11.987371
v  -3.385466 2.951773 -11.012331
v  -3.375129 2.899518 -11.282299
v  -1.795230 4.878366 -15.748834
v  -1.524785 4.829557 -15.738497
v  -3.953437 1.378869 -18.347197
v  -3.671171 1.413323 -18.345474
v  4.525005 1.378869 -17.993135
v  4.523283 1.413323 -17.994858
v  4.523283 1.413323 -18.345474
v  4.242739 1.413323 -17.994858
v  -3.699213 1.583574 -14.668952
v  4.512946 2.212771 -18.005196
v  4.242604 2.265026 -17.994860
v  4.270782 1.583574 -12.347657
v  -3.639213 1.731870 -19.576759
v  4.210782 1.731870 -19.576759
v  1.607497 5.158405 -20.485714
v  1.325470 5.123951 -20.487438
v  4.525005 1.426532 -11.225092
v  4.242978 1.392078 -11.226814
v  -1.034205 5.109596 -20.838053
v  -0.753901 5.123951 -20.838053
v  -4.553664 1.739111 -21.386847
v  5.125232 1.739111 -21.386847
v  5.185232 3.866932 -22.710121
v  -4.613664 3.866932 -22.710121
v  -3.715575 4.878365 -20.291136
v  -3.725912 4.829556 -20.561583
v  4.210782 1.818133 -19.528181
v  4.270782 1.730771 -12.641561
v  -3.699213 1.730771 -12.641561
v  0.306972 6.359475 -10.375868
v  -0.818287 6.047520 -23.729160
v  -0.830346 6.012492 -23.456993
v  5.213704 3.172024 -20.260895
v  5.211981 3.206478 -20.542921
v  2.366799 4.896169 -11.564095
v  4.525005 2.916744 -13.480725
v  4.512946 2.933971 -13.822727
v  4.523283 2.899517 -13.482449
v  4.242500 2.899517 -13.482449
v  -4.642252 0.696577 -23.964600
v  -4.288190 0.713803 -23.964600
v  -4.640529 0.731030 -23.962875
v  -4.640529 0.745386 -23.682573
v  5.185232 2.272344 -22.549076
v  -0.806360 7.232417 -12.236726
v  0.306972 5.675517 -10.443367
v  5.201762 1.521726 -23.952541
v  5.212098 1.556179 -23.962875
v  5.212098 1.570535 -23.682573
v  4.859758 1.538952 -23.964600
v  4.523283 2.885161 -13.833064
v  4.242500 2.885161 -13.833064
v  -4.630075 0.678777 -20.272953
v  -4.640412 0.731031 -20.542919
v  -4.226781 1.450652 -20.514721
v  4.798349 1.450652 -20.514721
v  5.125232 1.951681 -21.595573
v  -4.553664 1.557261 -22.481455
v  -4.856044 2.346875 -20.827717
v  -4.586076 2.381329 -20.838053
v  -3.373407 0.574829 -11.000272
v  -3.375129 0.540375 -11.282299
v  -0.805573 5.980911 -20.839586
v  -0.524791 5.995266 -20.839586
v  4.512946 2.951773 -11.567095
v  4.242978 2.899518 -11.577431
v  -0.796023 7.021894 -10.358526
v  0.285777 6.969639 -10.348189
v  1.403446 5.158404 -23.969852
v  1.403446 5.141178 -23.686102
v  -4.630075 4.887784 -20.272953
v  -4.640412 4.821173 -20.542921
v  -3.639213 1.450840 -17.438454
v  4.210782 1.450840 -17.438454
v  -3.639213 1.450840 -12.856722
v  -3.639213 1.450840 -15.178017
v  4.210782 1.450840 -12.856722
v  4.210782 1.450840 -15.178017
v  4.270782 1.675554 -14.766682
v  5.438641 1.521726 -23.384569
v  5.436918 1.556179 -23.386292
v  5.438641 1.538952 -23.738632
v  5.154889 1.538952 -23.384571
v  -4.867072 1.521726 -23.384569
v  -4.583321 1.538952 -23.384571
v  -4.865349 1.556179 -23.386292
v  -4.867072 1.538952 -23.738632
v  5.438641 0.696577 -23.384569
v  5.436918 0.731031 -23.386292
v  5.438641 0.713803 -23.738632
v  5.154889 0.713803 -23.384571
v  5.439672 3.997172 -20.839775
v  5.437950 4.031626 -20.838053
v  5.157645 4.031626 -20.838053
v  5.439672 4.014400 -20.485714
v  5.439672 3.172024 -20.839775
v  5.437950 3.206477 -20.838053
v  5.157645 3.206477 -20.838053
v  5.439672 3.189251 -20.485714
v  -4.868104 3.997172 -20.839775
v  -4.868104 4.014400 -20.485714
v  -4.866381 4.031626 -20.838053
v  -4.586076 4.031626 -20.838053
v  5.438641 3.172023 -23.384569
v  5.436918 3.206477 -23.386292
v  5.438641 3.189250 -23.738632
v  5.154889 3.189250 -23.384571
v  5.438641 4.869981 -23.384571
v  5.438641 4.852755 -23.738634
v  5.436918 4.835527 -23.386293
v  5.154889 4.852755 -23.384571
v  5.438641 3.997172 -23.384571
v  5.436918 4.031626 -23.386293
v  5.438641 4.014399 -23.738634
v  5.154889 4.014399 -23.384571
v  -4.867072 4.869981 -23.384571
v  -4.865349 4.835527 -23.386293
v  -4.867072 4.852755 -23.738634
v  -4.583321 4.852755 -23.384571
v  -4.867072 3.172023 -23.384569
v  -4.867072 3.189250 -23.738632
v  -4.865349 3.206477 -23.386292
v  -4.583321 3.189250 -23.384571
v  -4.867072 3.997172 -23.384571
v  -4.583321 4.014399 -23.384571
v  -4.865349 4.031626 -23.386293
v  -4.867072 4.014399 -23.738634
v  -4.867072 2.346874 -23.384569
v  -4.867072 2.364101 -23.738632
v  -4.865349 2.381328 -23.386292
v  -4.583321 2.364101 -23.384571
v  5.438641 2.346874 -23.384569
v  5.436918 2.381328 -23.386292
v  5.438641 2.364101 -23.738632
v  5.154889 2.364101 -23.384571
v  -4.868104 1.521726 -20.839775
v  -4.868104 1.538953 -20.485714
v  -4.866381 1.556180 -20.838051
v  -4.586076 1.556180 -20.838051
v  -4.867072 0.696577 -23.384569
v  -4.867072 0.713804 -23.738632
v  -4.865349 0.731030 -23.386292
v  -4.583321 0.713804 -23.384571
v  -3.953437 1.378870 -16.090992
v  -3.671171 1.413324 -16.089270
v  -3.951714 1.413323 -16.089270
v  -3.953437 1.396097 -15.736931
v  -3.953437 2.230573 -16.090992
v  -3.671036 2.265027 -16.089270
v  -3.951714 2.265027 -16.089270
v  -3.953437 2.247800 -15.736931
v  2.378859 4.878366 -18.347040
v  2.096354 4.829556 -18.345318
v  2.377136 4.843912 -18.345318
v  2.378859 4.861139 -17.992979
v  -3.953437 2.933969 -18.347197
v  -3.951714 2.899515 -18.345474
v  -3.953437 2.916743 -17.993135
v  -3.670932 2.899515 -18.345474
v  4.525005 2.933969 -18.347197
v  4.242500 2.899515 -18.345474
v  4.523283 2.899515 -18.345474
v  4.525005 2.916743 -17.993135
v  -3.727634 3.177613 -20.279078
v  -3.373573 3.194839 -20.279078
v  -3.725912 3.212067 -20.280800
v  -3.725912 3.226422 -20.561424
v  4.210782 2.037458 -19.368834
v  5.437950 4.821173 -20.487438
v  5.437950 4.835528 -20.838053
v  5.439672 4.852756 -20.485714
v  5.157645 4.835528 -20.487438
v  4.270782 1.583574 -19.282888
v  4.287143 4.896166 -20.291136
v  4.297480 4.829556 -20.561583
v  5.436918 0.745386 -23.736908
v  5.156612 0.731031 -23.736908
v  5.436918 4.821172 -23.736912
v  5.156612 4.835527 -23.736912
v  5.436918 3.220832 -23.736908
v  5.156612 3.206477 -23.736908
v  -4.865349 4.821172 -23.736912
v  -4.585043 4.835527 -23.736912
v  5.436918 4.045981 -23.736912
v  5.156612 4.031626 -23.736912
v  -4.865349 3.220832 -23.736908
v  -4.585043 3.206477 -23.736908
v  -4.865349 4.045981 -23.736912
v  -4.585043 4.031626 -23.736912
v  5.436918 2.395683 -23.736908
v  5.156612 2.381328 -23.736908
v  -4.865349 2.395683 -23.736908
v  -4.585043 2.381328 -23.736908
v  -4.865349 1.570535 -23.736908
v  -4.585043 1.556179 -23.736908
v  5.436918 1.570535 -23.736908
v  5.156612 1.556179 -23.736908
v  -4.865349 0.745386 -23.736908
v  -4.585043 0.731030 -23.736908
v  -3.951714 1.427679 -15.738653
v  -3.671171 1.413324 -15.738653
v  -3.951714 2.279382 -15.738653
v  -3.671036 2.265027 -15.738653
v  4.523283 2.885160 -17.994860
v  4.242500 2.899516 -17.994860
v  -3.951714 2.885160 -17.994860
v  -3.670932 2.899516 -17.994860
v  2.377136 4.829556 -17.994701
v  2.096354 4.829556 -17.994701
v  5.437950 3.220833 -20.487438
v  5.157645 3.206478 -20.487438
v  -4.866381 4.045982 -20.487438
v  -4.586076 4.031627 -20.487438
v  -4.866381 1.570536 -20.487436
v  -4.586076 1.556180 -20.487436
v  5.437950 4.045982 -20.487438
v  5.157645 4.031627 -20.487438
v  -3.375295 3.226422 -20.280800
v  -3.375295 3.212067 -20.561424
v  4.525005 1.378869 -16.090992
v  4.242739 1.413324 -16.089270
v  1.372631 1.738782 -24.580315
v  2.139713 1.015537 -11.008516
v  2.150049 0.963282 -11.278588
v  -4.868104 4.869982 -20.839775
v  -4.586076 4.835528 -20.838053
v  4.525005 0.527168 -15.736930
v  4.242874 0.575977 -15.738653
v  -1.535752 3.566912 -11.290576
v  2.107320 3.566912 -11.290576
v  1.356577 5.176206 -23.731825
v  1.357725 5.109594 -23.730675
v  -0.785008 5.176206 -23.731825
v  -0.786157 5.109594 -23.730675
v  2.151772 4.878368 -10.996458
v  -1.580203 4.878368 -10.996458
v  -3.953437 1.426532 -11.579153
v  -3.671410 1.392078 -11.577431
v  -4.856044 3.172024 -20.827717
v  -4.586076 3.206477 -20.838053
v  -4.630075 2.346875 -20.272953
v  -4.640412 2.381329 -20.542921
v  -4.289913 0.745386 -23.962877
v  -4.289913 0.731030 -23.682571
v  -3.924151 0.509366 -16.061705
v  -3.671306 0.561621 -16.089268
v  5.427613 0.678776 -20.827715
v  5.157645 0.731031 -20.838051
v  5.201762 1.521725 -23.692909
v  4.861481 1.556179 -23.682571
v  4.241256 0.574829 -11.225092
v  4.242978 0.526019 -11.226814
v  4.253211 0.509366 -15.748989
v  4.241016 1.426531 -13.834786
v  4.871701 0.678776 -20.532583
v  5.211981 0.731031 -20.542919
v  4.287143 3.159811 -20.551086
v  -3.715575 3.159811 -20.551086
v  4.287143 3.986357 -20.551167
v  3.946864 4.038612 -20.561504
v  -3.715575 3.986357 -20.551167
v  -3.375295 4.038612 -20.561504
v  4.512946 2.951771 -16.078932
v  4.242500 2.899516 -16.089270
v  -4.630075 4.887784 -20.532583
v  4.287143 4.896166 -20.551247
v  -3.715575 4.896166 -20.551247
v  -4.630075 3.979371 -20.532583
v  -4.289796 4.031627 -20.542921
v  4.871818 4.887783 -23.692909
v  -4.300249 4.887783 -23.692909
v  -3.669448 1.426531 -13.834786
v  -3.669209 2.933971 -13.834786
v  4.240777 2.933971 -13.834786
v  2.106690 4.896167 -18.005039
v  -1.535121 4.896167 -18.005039
v  -1.524785 4.829556 -18.345318
v  4.253076 1.361069 -15.748990
v  2.106690 4.896169 -13.822571
v  2.096354 4.829558 -13.832908
v  -1.535121 4.896169 -13.822571
v  -1.524785 4.829558 -13.482292
v  -1.535121 4.896169 -13.492628
v  2.106690 4.896169 -13.492628
v  5.201762 3.172023 -23.692909
v  4.861481 3.206477 -23.682571
v  5.201762 3.997172 -23.692911
v  2.094631 4.878366 -18.347040
v  -1.523062 4.878366 -18.347040
v  -3.669687 0.574829 -11.225092
v  -1.523062 4.878366 -15.736774
v  2.094631 4.878366 -15.736774
v  2.096354 4.829557 -16.089113
v  -4.630193 0.678775 -23.692909
v  -4.630193 2.329072 -23.692909
v  -4.289913 2.381328 -23.682571
v  -4.630193 3.154222 -23.692909
v  -4.630193 3.979370 -23.692911
v  -4.630193 1.503924 -23.692909
v  -4.300133 0.678776 -20.532583
v  2.106690 4.896168 -16.078777
v  5.201762 0.696577 -23.692909
v  5.212098 0.745386 -23.682573
v  -0.809959 5.158405 -20.544643
v  -0.457621 5.123951 -20.542921
v  1.381528 5.158405 -20.544643
v  1.029190 5.123951 -20.542921
v  -4.553664 1.735807 -21.641459
v  -0.778700 3.119120 -10.809469
v  1.350269 3.119120 -10.809469
v  -0.255054 3.111397 -10.375868
v  0.826622 3.111397 -10.375868
v  -0.255054 2.021431 -10.375868
v  0.826622 2.021431 -10.375868
v  -0.255054 4.192159 -10.375868
v  0.826623 4.192159 -10.375868
v  -0.255054 5.264885 -10.375868
v  0.826623 5.264885 -10.375868
v  5.213704 0.696578 -20.260893
v  4.525005 0.574829 -11.225092
v  0.406428 6.871465 -10.443367
v  -0.741063 1.665094 -24.602837
v  5.427613 4.887783 -20.827717
v  5.157645 4.835528 -20.838053
v  1.290269 5.212166 -11.821722
v  -0.718700 5.212166 -11.821722
v  -3.941378 0.509366 -18.005194
v  -3.671306 0.561621 -17.994858
v  -3.373407 1.426532 -11.000272
v  -3.375129 1.392078 -11.282299
v  5.184329 4.804520 -20.290268
v  5.211981 4.835528 -20.542921
v  5.427613 2.329074 -20.497772
v  5.157645 2.381329 -20.487438
v  4.861481 1.570535 -23.962875
v  4.210782 1.963269 -19.202202
v  -1.478330 4.127238 -19.442017
v  2.049898 4.127238 -17.031296
v  2.049898 4.127238 -14.696250
v  -1.478330 4.127238 -14.696250
v  -1.478330 4.127238 -17.031296
v  2.049898 4.127238 -12.361206
v  -1.478330 4.127238 -12.361206
v  2.049898 4.127238 -19.442017
v  3.651609 1.873910 -11.253515
v  -3.080040 1.873910 -11.253515
v  -4.613664 1.738781 -23.210903
v  -3.699213 1.524528 -19.769430
v  4.270782 1.524528 -19.769430
v  5.185232 3.866932 -21.155127
v  -4.613664 3.866932 -21.155127
v  -3.698320 2.877169 -11.290575
v  4.269889 2.877169 -11.290575
v  1.039526 5.158405 -20.272953
v  2.377136 4.829557 -16.089113
v  5.213821 0.696577 -23.964600
v  -3.953437 2.230572 -18.347197
v  -3.671036 2.265026 -18.345474
v  -0.778700 6.219080 -11.016258
v  1.350269 6.219080 -11.016258
v  -4.553664 2.062030 -21.607170
v  0.285784 6.963814 -10.375868
v  4.525005 1.378869 -18.347197
v  4.242739 1.413323 -18.345474
v  1.378864 7.808450 -12.237238
v  1.096359 7.773996 -12.235516
v  4.523283 2.229425 -13.482448
v  4.242604 2.243781 -13.482448
v  1.350269 7.021914 -11.253515
v  1.290269 6.837663 -11.392965
v  -0.778700 7.021914 -11.253515
v  -0.718700 6.837663 -11.392965
v  -0.035808 6.088989 -10.375868
v  1.290269 6.744268 -10.933170
v  -0.718700 6.744268 -10.933170
v  1.290269 6.744269 -11.832897
v  -0.718700 6.744269 -11.832897
v  0.860402 6.744271 -10.435867
v  -0.288833 6.744271 -10.435867
v  -0.778700 6.343667 -11.399695
v  1.350269 6.343667 -11.399695
v  -3.375295 4.052968 -20.280800
v  3.946864 4.052968 -20.280800
v  5.185232 1.841522 -22.644579
v  -1.807290 4.878366 -18.347040
v  -4.613664 2.167187 -22.610350
v  4.270782 1.929878 -19.515572
v  -0.883952 1.922945 -11.260997
v  1.455521 1.922945 -11.260997
v  5.437950 1.556180 -20.487436
v  5.157645 1.556180 -20.487436
v  4.210782 2.037457 -12.406993
v  -3.639213 2.037457 -12.406993
v  4.210782 0.105636 -19.347155
v  -3.639213 0.105636 -19.347155
v  -3.639213 0.105636 -16.993656
v  4.210782 0.105636 -16.993656
v  2.378858 4.878367 -13.834630
v  4.299037 2.278235 -11.000272
v  4.297314 2.243781 -11.282299
v  2.109898 3.794861 -19.745182
v  -1.538330 3.794861 -19.745182
v  -3.639213 1.748844 -14.600144
v  4.210782 1.748844 -14.600144
v  4.210782 1.748843 -12.278494
v  -1.145238 1.873985 -11.260997
v  1.716807 1.873985 -11.260997
v  -3.699213 1.962737 -17.160067
v  -1.568144 0.997735 -11.008516
v  -1.578481 0.963281 -11.278588
v  -4.301281 1.587188 -20.274099
v  -4.289796 1.556180 -20.542919
v  0.895607 0.371893 -10.464714
v  5.125232 1.665094 -23.233425
v  3.957034 0.574829 -11.012331
v  3.946698 0.540375 -11.282299
v  4.270782 1.962736 -12.279226
v  -3.699213 1.962736 -12.279226
v  4.861481 2.395683 -23.962875
v  4.861481 2.381328 -23.682571
v  4.861481 3.220832 -23.962877
v  1.388708 5.980909 -23.728012
v  1.401915 6.012492 -23.456993
v  -1.807290 4.878368 -11.576154
v  4.859642 3.997173 -20.260895
v  4.861365 4.031627 -20.542921
v  1.365657 5.964258 -20.500454
v  1.096359 5.995266 -20.488970
v  -4.856044 0.678776 -20.827715
v  -4.586076 0.731031 -20.838051
v  1.616208 5.176206 -23.731825
v  5.201644 1.521726 -20.272951
v  5.211981 1.556180 -20.542919
v  5.185232 3.729824 -23.125351
v  -4.613664 3.729824 -23.125351
v  -4.553664 1.946124 -23.018232
v  5.125232 1.946124 -23.018232
v  -4.613664 3.812293 -21.546200
v  5.185232 3.812293 -21.546200
v  4.512946 2.212772 -16.078932
v  4.242604 2.265027 -16.089270
v  4.210782 1.731870 -19.119564
v  -3.639213 1.731870 -19.119564
v  -3.639213 1.731870 -14.505628
v  4.210782 1.731870 -16.766066
v  -3.639213 1.731870 -16.766066
v  -3.953437 2.278235 -11.579153
v  -3.671410 2.243781 -11.577431
v  -3.953437 2.933971 -11.579153
v  -3.671410 2.899518 -11.577431
v  4.270782 1.730771 -14.962855
v  -3.699213 1.730771 -14.962855
v  -0.778700 3.056000 -10.576616
v  1.350269 3.056000 -10.576616
v  1.350269 1.958311 -10.576616
v  -0.778700 1.958311 -10.576616
v  -0.778700 4.129038 -10.553887
v  1.350269 4.129038 -10.553887
v  -0.778700 5.209277 -11.555090
v  -0.778700 5.209277 -10.553887
v  1.350269 5.209277 -11.555090
v  1.350269 5.209277 -10.553887
v  0.593771 3.048276 -10.375868
v  -0.022202 3.048276 -10.375868
v  0.593771 4.129038 -10.375868
v  -0.022202 4.129038 -10.375868
v  0.593771 5.201765 -10.375868
v  -0.022202 5.201765 -10.375868
v  0.593771 1.958311 -10.375868
v  -0.022202 1.958311 -10.375868
v  1.378864 7.808450 -10.640263
v  1.096359 7.773996 -10.638539
v  0.593771 6.741382 -10.375868
v  -0.022203 6.741382 -10.375868
v  4.270782 1.730771 -17.223293
v  3.944975 2.933971 -11.000273
v  3.946698 2.899518 -11.282299
v  4.525005 0.527167 -17.993135
v  4.242874 0.561621 -17.994858
v  -0.807296 7.808450 -12.237238
v  -0.524791 7.773996 -12.235516
v  -0.807296 7.808450 -10.346298
v  -0.524791 7.773996 -10.348021
v  5.185232 2.272344 -21.703558
v  1.350269 1.994560 -10.828411
v  -0.778700 1.994560 -10.828411
v  5.185232 2.455589 -21.962587
v  -4.613664 4.342015 -22.126499
v  5.185232 4.342015 -22.126499
v  -3.639213 0.108994 -12.440769
v  4.210782 0.108994 -12.440769
v  -0.489876 5.176206 -23.957792
v  -1.538330 3.794861 -12.664371
v  2.109898 3.794861 -12.664371
v  2.162309 3.372310 -11.350576
v  -1.590740 3.372310 -11.350576
v  2.527803 3.278125 -11.350576
v  -1.956234 3.278125 -11.350576
v  2.322316 3.321228 -11.350576
v  -1.750747 3.321228 -11.350576
v  -2.156384 3.221745 -11.350576
v  2.727953 3.221745 -11.350576
v  -2.372595 3.169007 -11.350576
v  2.944163 3.169007 -11.350576
v  3.361071 3.056669 -11.350576
v  -2.789503 3.056669 -11.350576
v  -2.581036 3.090028 -11.350576
v  3.152605 3.090028 -11.350576
v  -1.700150 3.335785 -11.290576
v  2.271718 3.335785 -11.290576
v  -2.065644 3.241601 -11.290576
v  2.637212 3.241601 -11.290576
v  -1.860157 3.284703 -11.290576
v  2.431725 3.284703 -11.290576
v  -2.265794 3.185220 -11.290576
v  2.837362 3.185220 -11.290576
v  -2.482004 3.132482 -11.290576
v  3.053573 3.132482 -11.290576
v  -2.898912 3.020145 -11.290576
v  3.470481 3.020145 -11.290576
v  -2.690446 3.053504 -11.290576
v  3.262014 3.053504 -11.290576
v  -0.778700 6.741377 -10.666540
v  1.350269 6.741377 -10.666540
v  -0.778700 6.741377 -11.566268
v  1.350269 6.741377 -11.566268
v  0.070451 6.273035 -10.375868
v  -2.360072 2.734942 -11.350576
v  2.931640 2.734942 -11.350576
v  4.512946 1.444333 -13.492785
v  4.512946 0.509366 -13.492784
v  4.242874 0.561621 -13.482448
v  -3.699213 1.730771 -17.223293
v  0.428500 6.516250 -10.443367
v  4.871701 2.329074 -20.272953
v  5.185232 4.042571 -22.886089
v  -4.613664 4.042571 -22.886089
v  -4.553664 4.043670 -21.331062
v  5.125232 4.043670 -21.331062
v  -0.831877 5.158404 -23.969852
v  -0.831877 5.141178 -23.686102
v  1.290269 6.358569 -11.253515
v  -0.718700 6.358569 -11.253515
v  4.270782 1.524528 -14.698298
v  4.270782 1.524528 -16.958735
v  -3.699213 1.524527 -12.377003
v  -3.699213 1.524528 -14.698298
v  -3.699213 1.524528 -19.312235
v  -3.699213 1.524528 -16.958735
v  4.270782 1.524527 -12.377003
v  4.270782 1.524528 -19.312233
v  5.211981 2.381329 -20.262617
v  5.211981 2.381329 -20.542921
v  -4.300133 0.678776 -20.272953
v  -0.778700 6.965892 -11.253592
v  1.350269 6.965892 -11.253592
v  -3.699213 1.583574 -12.347657
v  5.201644 1.503925 -20.532583
v  5.201644 4.887784 -20.532583
v  5.201644 3.979371 -20.532583
v  5.201644 3.154222 -20.532583
v  5.201644 2.329074 -20.532583
v  4.859642 1.521727 -20.544643
v  5.154889 1.521726 -23.738632
v  4.871818 1.503924 -23.692907
v  -4.300249 0.678775 -23.692907
v  5.154889 0.696577 -23.738632
v  -4.584354 4.869982 -20.839775
v  -4.288074 1.521727 -20.544643
v  5.155922 4.869982 -20.839775
v  -4.288074 2.346875 -20.544643
v  4.859642 2.346875 -20.544643
v  -3.373573 4.878365 -20.563305
v  -4.583321 3.997172 -23.738634
v  -4.583321 3.172023 -23.738632
v  5.154889 4.869981 -23.738634
v  4.859642 3.997173 -20.544643
v  -4.288074 3.997173 -20.544643
v  -4.583321 4.869981 -23.738634
v  5.154889 3.997172 -23.738634
v  4.859642 4.869982 -20.544643
v  4.240777 2.933970 -16.090992
v  3.945141 4.878365 -20.563305
v  5.154889 3.172023 -23.738632
v  -4.288074 4.869982 -20.544643
v  4.859642 3.172024 -20.544643
v  -4.288074 3.172024 -20.544643
v  -3.373573 3.177613 -20.563147
v  3.945141 4.004159 -20.563225
v  3.945141 3.177613 -20.563147
v  -3.373573 4.004159 -20.563225
v  4.252941 2.212772 -15.748990
v  -4.630075 3.154222 -20.532583
v  -4.630075 1.503925 -20.532583
v  5.155922 3.997172 -20.839775
v  -4.584354 3.997172 -20.839775
v  -4.584354 3.172024 -20.839775
v  5.155922 3.172024 -20.839775
v  -4.583321 1.521725 -23.738632
v  -4.584354 1.521726 -20.839775
v  5.154889 2.346874 -23.738632
v  -4.583321 2.346874 -23.738632
v  -4.630075 2.329074 -20.532583
v  -4.584354 2.346875 -20.839775
v  5.155922 2.346875 -20.839775
v  5.155922 0.696577 -20.839775
v  5.155922 1.521726 -20.839775
v  -4.300249 1.503924 -23.692907
v  4.871818 2.329073 -23.692907
v  -4.300249 2.329073 -23.692907
v  4.871818 0.678776 -23.692907
v  -4.300249 3.154222 -23.692907
v  -4.300249 3.979370 -23.692909
v  4.871818 3.154222 -23.692907
v  4.871818 3.979370 -23.692909
v  -4.584354 0.696578 -20.839775
v  -4.583321 0.696577 -23.738632
v  4.241256 1.426532 -11.225092
v  4.241256 1.426532 -11.579153
v  4.299037 1.426532 -11.000272
v  4.297314 1.392078 -11.282299
v  4.299037 1.426532 -11.284021
v  3.944975 1.426532 -11.284021
v  3.957034 0.592630 -11.271962
v  -0.523068 6.029720 -20.841309
v  1.094637 6.029720 -20.841309
v  4.286977 0.592630 -11.271962
v  4.241016 1.378869 -16.090992
v  4.241256 0.574829 -11.579153
v  4.241152 0.527168 -13.834785
v  4.241152 0.527168 -13.480724
v  4.240881 2.278234 -13.480725
v  -3.669687 0.574829 -11.579153
v  4.240777 2.933969 -17.993135
v  -3.669209 2.933969 -17.993135
v  -3.385466 0.592630 -11.271962
v  -3.669448 1.378869 -18.347197
v  -3.669313 2.230572 -18.347197
v  -3.669583 0.527167 -18.347197
v  -3.669313 2.278234 -13.480725
v  -3.669583 0.527167 -15.736930
v  -3.669583 0.527168 -13.480724
v  -1.580203 0.997735 -11.280311
v  4.240777 2.933969 -18.347197
v  -3.669209 2.933969 -18.347197
v  4.241016 1.378869 -17.993135
v  4.240881 2.230572 -18.347197
v  3.944975 2.278235 -11.284021
v  -3.669687 1.426532 -11.579153
v  -3.727468 2.278235 -11.284021
v  -3.725746 2.243781 -11.282299
v  1.797711 2.398730 -11.280446
v  2.150049 2.364277 -11.278723
v  -3.373407 2.933971 -11.284021
v  -3.669209 2.933971 -13.480725
v  1.379651 3.334522 -10.346466
v  0.285791 3.300068 -10.348189
v  1.797711 3.799726 -11.280581
v  4.240777 2.933970 -15.736931
v  4.299037 2.933971 -11.284021
v  4.241256 2.933971 -11.225092
v  -1.226142 2.398730 -11.280446
v  -1.578481 2.364277 -11.278723
v  -3.669209 2.933970 -16.090992
v  -1.807290 4.878367 -13.480569
v  2.151772 3.799726 -11.280581
v  -0.808082 3.334522 -10.346466
v  0.285777 3.300068 -10.348189
v  -3.669209 2.933970 -15.736931
v  4.241256 2.933971 -11.579153
v  -1.580203 2.398730 -11.280446
v  -3.669687 2.933971 -11.225092
v  -3.669687 2.278235 -11.225092
v  4.241256 2.278235 -11.579153
v  -1.580203 3.799726 -11.280581
v  -3.669687 2.933971 -11.579153
v  -1.226142 3.799726 -11.280581
v  -3.669687 2.278235 -11.579153
v  4.240777 2.933971 -13.480725
v  -3.373407 2.278235 -11.284021
v  4.241256 2.278235 -11.225092
v  -1.580203 2.398730 -10.996457
v  -3.373407 1.426532 -11.284021
v  1.379651 4.414524 -10.346466
v  0.285791 4.380070 -10.348189
v  -3.669583 0.527168 -13.834785
v  2.151772 2.398730 -11.280446
v  3.944975 2.933971 -11.284021
v  -3.669687 1.426532 -11.225092
v  2.151772 2.398730 -10.996457
v  -0.808082 4.414524 -10.346466
v  0.285777 4.380070 -10.348189
v  -3.727468 2.933971 -11.284021
v  -0.808082 1.174523 -10.346466
v  0.285777 1.140069 -10.348189
v  4.240881 2.230573 -16.090992
v  4.299037 2.278235 -11.284021
v  -3.727468 2.278235 -11.000272
v  -0.808082 2.254524 -10.346466
v  0.285777 2.237297 -10.346466
v  -3.727468 1.426532 -11.284021
v  -3.669583 0.527167 -17.993135
v  -3.669583 0.527167 -16.090992
v  5.201762 2.346874 -23.692909
v  -3.669448 1.378870 -15.736931
v  -3.669313 2.230573 -15.736931
v  -3.669313 2.230573 -16.090992
v  -3.669448 1.378870 -16.090992
v  -1.226142 0.997735 -11.280311
v  -3.669313 2.230572 -17.993135
v  -3.669448 1.378869 -17.993135
v  -1.523062 4.878366 -16.090836
v  -3.669448 1.426531 -13.480725
v  5.201762 4.869981 -23.692911
v  1.379651 2.254524 -10.346466
v  0.285791 2.237297 -10.346466
v  4.241152 0.527167 -18.347197
v  4.241152 0.527167 -17.993135
v  4.241152 0.527167 -16.090992
v  4.240881 2.230572 -17.993135
v  4.241016 1.378869 -18.347197
v  -3.669313 2.278234 -13.834786
v  4.241016 1.426531 -13.480725
v  2.151772 0.997735 -11.280311
v  -3.727468 0.574829 -11.284021
v  5.213704 0.696577 -20.544643
v  1.797711 0.997735 -11.280311
v  1.379651 1.174523 -10.346466
v  0.285791 1.140069 -10.348189
v  4.240881 2.278234 -13.834786
v  1.061445 5.176206 -23.698162
v  -0.489876 5.176206 -23.698162
v  -1.056699 5.158404 -23.743883
v  1.335806 5.176207 -20.827717
v  -0.764238 5.176207 -20.827717
v  1.039526 5.176207 -20.532585
v  -0.467957 5.176207 -20.532585
v  5.155922 1.521727 -20.485714
v  -0.523068 7.808450 -10.346298
v  1.094637 7.808450 -12.237238
v  -0.523068 7.808450 -10.640263
v  1.094637 7.808450 -10.346298
v  -0.523068 7.808450 -12.237238
v  1.094637 7.808450 -10.640263
v  1.094637 7.808450 -11.943274
v  -0.523068 7.808450 -11.943274
v  -0.467957 5.176207 -20.272953
v  -0.764238 5.176207 -20.497774
v  1.335806 5.176207 -20.497774
v  -4.630193 4.887783 -23.692911
v  -4.584354 4.869982 -20.485714
v  5.155922 4.869982 -20.485714
v  -4.584354 0.696578 -20.485714
v  -4.584354 2.346875 -20.485714
v  -4.584354 1.521727 -20.485714
v  -4.642135 0.696578 -20.544643
v  -4.288074 3.997173 -20.260895
v  5.155922 2.346875 -20.485714
v  5.155922 3.997173 -20.485714
v  -4.584354 3.997173 -20.485714
v  -4.584354 3.172024 -20.485714
v  5.155922 3.172024 -20.485714
v  5.155922 0.696577 -20.485714
v  -0.523068 6.029720 -20.487247
v  1.094637 6.029720 -20.487247
v  -0.741063 1.946124 -24.387642
v  1.312631 1.946124 -24.387642
v  -2.887370 2.081252 -11.313516
v  3.458938 2.081252 -11.313516
v  -0.324038 -0.067656 -10.375868
v  1.388708 5.980909 -23.470200
v  -0.817139 5.980909 -23.470200
v  1.390238 5.109594 -23.699310
v  -0.818669 5.109594 -23.699310
v  -4.289913 2.395683 -23.962875
v  -0.489492 5.980909 -23.470200
v  -0.786157 5.109594 -23.403030
v  1.061061 5.980909 -23.470200
v  1.357725 5.109594 -23.403030
v  -3.726863 2.205714 -11.225758
v  4.298432 2.205714 -11.225758
v  5.168097 0.745386 -23.397778
v  5.168097 1.570535 -23.397778
v  -4.596529 4.045981 -23.397779
v  5.168097 4.821172 -23.397779
v  -4.596529 4.821172 -23.397779
v  -4.596529 3.220832 -23.397778
v  5.168097 3.220832 -23.397778
v  5.168097 4.045981 -23.397779
v  -4.596529 1.570535 -23.397778
v  -4.596529 2.395684 -23.397778
v  5.168097 2.395684 -23.397778
v  -4.596529 0.745386 -23.397778
v  4.210782 1.731870 -12.184332
v  -3.639213 1.731870 -12.184332
v  -0.778700 5.946190 -10.958252
v  1.350269 5.946190 -10.958252
v  -1.001469 2.081253 -11.320996
v  1.573038 2.081253 -11.320996
v  3.559568 2.976032 -11.350576
v  -2.987999 2.976032 -11.350576
v  -2.776980 2.642763 -11.350576
v  3.348549 2.642763 -11.350576
v  3.140082 2.688855 -11.350576
v  -2.568514 2.688855 -11.350576
v  -3.085826 2.961949 -11.290576
v  3.657394 2.961949 -11.290576
v  4.210782 1.963269 -17.160799
v  -3.639213 1.818133 -17.191648
v  3.551939 3.778005 -20.514616
v  -2.980371 3.778005 -20.514616
v  4.857338 3.778005 -20.514721
v  -4.285769 3.778005 -20.514721
v  3.247336 4.127237 -20.574570
v  -2.675768 4.127237 -20.574570
v  -3.176954 2.554327 -11.350576
v  3.748522 2.554327 -11.350576
v  1.290269 4.260250 -10.681143
v  -0.718700 4.260250 -10.681143
v  1.290269 5.340488 -10.681143
v  -0.718700 5.340488 -10.681143
v  4.270782 1.583574 -16.929390
v  -0.778700 5.211906 -10.819652
v  1.350269 5.211906 -10.819652
v  -0.778700 4.131667 -10.819652
v  1.350269 4.131667 -10.819652
v  -0.778700 5.676417 -11.329565
v  1.350269 5.676417 -11.329565
v  -0.718700 2.089522 -10.703871
v  1.290269 2.089522 -10.703871
v  -0.149457 6.872594 -10.435867
v  4.210782 2.037458 -14.728643
v  -0.290178 0.548664 -10.375868
v  -4.613664 1.945025 -21.193367
v  5.185232 1.945025 -21.193367
v  5.185232 1.557933 -21.771786
v  4.872966 4.062634 -23.951393
v  -0.801062 4.342015 -24.400700
v  1.372631 4.342015 -24.400700
v  -3.699213 2.036558 -14.766682
v  -3.699213 1.818322 -19.527296
v  5.185232 1.640249 -22.554966
v  -3.699213 1.962737 -19.496601
v  -2.904356 2.615521 -11.290576
v  3.475924 2.615521 -11.290576
v  -4.613664 2.455589 -21.962587
v  -3.924151 0.509366 -18.317909
v  -1.943712 2.827000 -11.350576
v  2.515281 2.827000 -11.350576
v  -0.778700 6.871585 -10.793818
v  1.350269 6.871585 -10.793818
v  -0.778700 6.871586 -11.693545
v  1.350269 6.871586 -11.693545
v  -0.149482 2.088519 -10.375868
v  0.721050 2.088519 -10.375868
v  -0.778700 5.339485 -11.682371
v  1.350269 5.339485 -11.682371
v  -0.149481 3.178484 -10.375868
v  0.721050 3.178484 -10.375868
v  -0.149482 4.259247 -10.375868
v  0.721050 4.259247 -10.375868
v  -0.149482 5.331974 -10.375868
v  0.721050 5.331974 -10.375868
v  0.721051 6.871590 -10.375868
v  -0.778700 3.186208 -10.703896
v  1.350269 3.186208 -10.703896
v  4.584071 1.730771 -20.514721
v  -4.012503 1.730771 -20.514721
v  -0.778796 2.272325 -11.253489
v  1.350365 2.272325 -11.253489
v  3.759366 2.918833 -11.350576
v  -3.187798 2.918833 -11.350576
v  3.853991 2.887180 -11.290576
v  -3.282423 2.887180 -11.290576
v  0.501117 6.273035 -10.375868
v  2.715430 2.782747 -11.350576
v  -2.143862 2.782747 -11.350576
v  5.427613 1.503925 -20.827715
v  -3.981200 4.126235 -20.514721
v  4.552768 4.126235 -20.514721
v  -4.317359 2.329074 -20.290180
v  -4.881885 0.713804 -20.471931
v  4.270782 2.028669 -17.069319
v  4.270782 1.929877 -14.581905
v  -1.044640 5.176206 -23.401882
v  -4.553664 4.119430 -21.902245
v  5.125232 4.119430 -21.902245
v  -3.741250 1.409305 -10.986490
v  2.392639 4.861141 -11.208311
v  1.035604 5.123950 -23.983631
v  -4.881885 3.189251 -20.471933
v  1.350276 5.435492 -12.209074
v  1.350365 6.523799 -12.209163
v  5.201644 4.869982 -20.272953
v  -3.967218 2.916743 -16.104773
v  -3.924062 0.592629 -16.061617
v  5.410297 2.412337 -20.515089
v  -0.794875 6.938631 -12.225241
v  -1.795230 4.896168 -16.078775
v  4.270782 1.749376 -19.496601
v  3.958183 2.212773 -11.013479
v  2.365651 4.812905 -13.493777
v  -4.553664 2.263645 -21.696936
v  5.125232 2.411320 -22.391689
v  5.453454 0.713805 -20.471931
v  -4.655916 4.014400 -20.247112
v  4.872850 3.237486 -20.274101
v  2.349483 4.812906 -11.546779
v  -4.553664 1.492043 -21.860945
v  -4.629045 2.412336 -23.951393
v  -3.386614 2.868509 -11.013479
v  -3.940229 0.509367 -11.565945
v  -0.817139 5.964257 -23.728012
v  -0.778796 6.523799 -12.209163
v  -4.854896 2.412337 -20.826567
v  1.290269 6.516247 -10.941200
v  -0.296863 6.516249 -10.435867
v  -0.718700 6.516247 -10.941200
v  5.125232 3.342261 -21.585077
v  -4.553664 3.342261 -21.585077
v  4.210782 0.105636 -14.733219
v  4.210782 0.105636 -12.869120
v  4.210782 0.105636 -17.450851
v  -3.639213 0.105636 -14.733219
v  -3.639213 0.105636 -17.450851
v  -3.639213 0.105636 -12.869120
v  -0.778700 6.518676 -11.124463
v  1.350269 6.518676 -11.124463
v  -4.274409 4.835527 -23.978382
v  4.210782 0.108994 -19.376001
v  -3.639213 0.108994 -19.376001
v  -3.639213 0.108994 -17.022503
v  4.210782 0.108994 -17.022503
v  2.981751 3.858450 -20.574532
v  -2.410183 3.858450 -20.574532
v  1.393432 7.246772 -10.332685
v  -0.778700 5.762629 -11.523199
v  1.350269 5.762629 -11.523199
v  -0.778700 5.946190 -11.629179
v  1.350269 5.946190 -11.629179
v  1.389856 6.047520 -23.729160
v  1.392646 7.791223 -11.929493
v  4.511797 2.296035 -18.006344
v  5.453454 2.364102 -20.853558
v  1.349579 6.047522 -20.516533
v  -3.967218 0.544394 -13.848567
v  5.185232 1.424744 -22.070934
v  0.895607 0.112344 -10.375868
v  2.122397 0.932273 -11.025832
v  -0.821863 6.471544 -10.332685
v  -3.699213 1.698876 -19.259018
v  5.227602 4.852754 -23.978382
v  -0.821863 5.483727 -12.252230
v  1.393432 7.263999 -12.252230
v  4.845861 0.713805 -20.247112
v  4.538786 0.557602 -11.592934
v  -0.821077 7.791223 -11.929493
v  -3.639213 1.818133 -12.604474
v  -3.639213 1.720506 -14.869759
v  4.210782 1.720506 -14.869759
v  -3.699213 1.962737 -16.866400
v  -3.698259 4.812903 -20.308453
v  4.270782 1.573472 -17.065708
v  -3.699213 1.573472 -19.419207
v  -3.699213 1.573472 -17.065708
v  4.270782 1.573472 -19.419207
v  4.270782 1.573472 -14.805271
v  -3.699213 1.573472 -14.805271
v  -3.699213 1.573472 -12.483976
v  4.270782 1.573472 -12.483976
v  1.595438 5.176207 -20.827717
v  -4.854896 2.412337 -20.498920
v  -0.778707 6.938631 -10.375841
v  -0.796023 5.518755 -10.358526
v  1.392646 7.791223 -10.332516
v  5.410387 4.887784 -20.514999
v  1.290269 6.516247 -11.400995
v  -0.718700 6.516247 -11.400995
v  0.591000 6.517783 -10.435867
v  1.290269 6.517781 -11.563494
v  -0.718700 6.517781 -11.563494
v  -0.019431 6.517783 -10.435867
v  -0.778700 6.517153 -11.840027
v  1.350269 6.517153 -11.840027
v  0.867532 6.517154 -10.375868
v  3.958183 1.361070 -11.013479
v  4.511797 2.868508 -15.750138
v  -1.794082 4.812905 -13.821423
v  -1.794082 4.812906 -11.235300
v  1.810918 2.333268 -11.009665
v  4.511797 2.868509 -13.493933
v  -1.239350 2.333268 -11.009665
v  -3.386614 2.212773 -11.013479
v  4.511797 1.444332 -18.006342
v  4.511797 0.592630 -13.821578
v  -3.699213 0.106543 -19.803452
v  -4.613664 0.106543 -23.244923
v  5.185232 0.106543 -23.244925
v  4.270782 0.106543 -19.803452
v  -4.613664 0.109887 -20.993477
v  -3.699213 0.109887 -11.984475
v  4.270782 0.109887 -11.984475
v  5.185232 0.109887 -20.993477
v  5.125232 3.346009 -22.661991
v  -4.553664 3.346009 -22.661991
v  -0.778700 6.518675 -10.664667
v  1.350269 6.518675 -10.664667
v  -4.613664 3.346902 -21.107897
v  5.185232 3.346902 -21.107897
v  4.287158 3.858451 -20.574722
v  -3.715590 3.858451 -20.574722
v  0.722184 0.805154 -10.375868
v  1.290269 6.088988 -11.630064
v  -0.718700 6.088988 -11.630064
v  -3.639213 0.105636 -12.411924
v  4.210782 0.105636 -12.411924
v  5.410297 4.804520 -20.810400
v  -4.553664 4.133794 -22.358828
v  5.125232 4.133794 -22.358828
v  1.077139 6.047520 -23.711933
v  2.049898 3.858451 -19.176432
v  2.049898 3.858451 -12.095620
v  -1.478330 3.858451 -12.095620
v  -1.478330 3.858451 -19.176432
v  -1.478330 3.858451 -16.765711
v  2.049898 3.858451 -16.765711
v  -1.478330 3.858451 -14.430666
v  2.049898 3.858451 -14.430666
v  -1.568144 3.817527 -11.008516
v  5.409355 1.503924 -23.709347
v  -4.837786 1.503924 -23.709347
v  -4.837786 2.329072 -23.709347
v  5.409355 2.329072 -23.709347
v  -0.778010 6.047522 -20.516533
v  -4.837786 3.154222 -23.709347
v  -4.837786 3.979370 -23.709347
v  -4.837786 4.887783 -23.709347
v  5.409355 3.154222 -23.709347
v  5.409355 3.979370 -23.709347
v  5.409355 4.887783 -23.709347
v  -4.837786 0.678775 -23.709347
v  -3.924151 1.444333 -13.510011
v  2.349573 4.896167 -18.022264
v  4.495719 2.951771 -18.022423
v  -3.924151 2.951771 -18.022423
v  -3.924151 1.361068 -15.766216
v  -3.924151 2.212772 -15.766216
v  -4.838818 1.503925 -20.514999
v  5.410387 3.154222 -20.514999
v  5.410387 3.979371 -20.514999
v  -4.838818 3.979371 -20.514999
v  5.410387 1.503925 -20.514999
v  5.409355 0.678776 -23.709347
v  -3.402858 3.159811 -20.308363
v  3.974427 3.986357 -20.308363
v  3.974427 3.159811 -20.308363
v  -3.402858 3.986357 -20.308363
v  4.511797 1.361070 -11.238299
v  1.810918 4.812906 -11.009666
v  -3.940229 1.361070 -11.238299
v  -1.239350 4.812906 -11.009666
v  1.615060 5.109594 -23.730675
v  5.200613 2.395684 -23.951393
v  5.200613 1.570535 -23.951393
v  -4.655916 0.713805 -20.247112
v  1.393432 6.488770 -10.332685
v  1.628267 5.141178 -23.389822
v  -1.023869 5.176207 -20.827717
v  4.845977 4.835527 -23.978382
v  4.511797 2.279382 -16.077784
v  -1.577318 2.908932 -11.290576
v  2.148886 2.908932 -11.290576
v  0.285777 5.500954 -10.346466
v  0.285777 7.004093 -10.346466
v  0.285791 5.500954 -10.346466
v  0.285791 7.004093 -10.346466
v  0.285777 1.174523 -10.346466
v  0.285777 3.334522 -10.346466
v  0.285777 4.414524 -10.346466
v  0.285791 4.414524 -10.346466
v  0.285791 3.334522 -10.346466
v  0.285791 1.174523 -10.346466
v  0.285777 6.505998 -10.346466
v  0.285791 7.281226 -10.346466
v  0.285777 7.281226 -10.346466
v  0.285791 6.505998 -10.346466
v  1.040674 5.109596 -20.274101
v  4.270782 1.893791 -12.603589
v  -3.699213 1.893791 -12.603589
v  1.367805 5.276234 -23.019974
v  1.395309 5.141178 -20.247112
v  -1.552437 4.812906 -11.235300
v  0.285777 2.189061 -10.359674
v  2.124005 4.812906 -11.235300
v  -1.554504 4.394198 -20.514265
v  2.126072 4.394198 -20.514265
v  1.810918 4.812906 -11.251311
v  -1.239350 4.812906 -11.251311
v  0.285791 2.189062 -10.359674
v  -0.796236 5.276234 -23.019974
v  4.889044 1.503924 -23.935314
v  -4.317476 0.678775 -23.935314
v  -0.781085 5.276234 -20.854984
v  4.525005 1.396097 -15.736930
v  -1.591535 3.371878 -11.290576
v  2.163103 3.371878 -11.290576
v  -1.957029 3.277693 -11.290576
v  2.528598 3.277693 -11.290576
v  -1.751542 3.320796 -11.290576
v  2.323110 3.320796 -11.290576
v  -2.157179 3.221313 -11.290576
v  2.728748 3.221313 -11.290576
v  2.933393 3.130792 -11.290576
v  -2.361825 3.130792 -11.290576
v  3.350302 3.018454 -11.290576
v  -2.778733 3.018454 -11.290576
v  -2.570267 3.051814 -11.290576
v  3.141835 3.051814 -11.290576
v  -4.289796 0.731031 -20.262615
v  2.266200 3.357827 -11.350576
v  -1.694631 3.357827 -11.350576
v  2.631694 3.263642 -11.350576
v  -2.060125 3.263642 -11.350576
v  3.464962 3.042187 -11.350576
v  -2.893394 3.042187 -11.350576
v  -1.854638 3.306746 -11.350576
v  2.426206 3.306746 -11.350576
v  -2.684927 3.075546 -11.350576
v  3.256495 3.075546 -11.350576
v  -2.476485 3.154525 -11.350576
v  3.048054 3.154525 -11.350576
v  2.831843 3.207263 -11.350576
v  -2.260275 3.207263 -11.350576
v  -1.566996 0.932273 -11.009665
v  -0.741063 3.945168 -24.705387
v  1.312631 3.945168 -24.705387
v  -1.807290 4.861139 -17.992979
v  -4.655916 3.189251 -20.247112
v  0.549219 5.815379 -10.443367
v  1.290269 6.088988 -10.957367
v  -0.718700 6.088988 -10.957367
v  3.974350 0.509367 -11.029646
v  -4.274409 4.014399 -23.978382
v  -3.924062 1.444331 -18.317822
v  1.827085 3.734263 -11.025832
v  -4.300133 1.521726 -20.272951
v  -1.212361 0.963281 -10.982676
v  -1.538330 4.032425 -19.613924
v  2.109898 4.032425 -17.203201
v  2.109898 4.032425 -14.868156
v  -1.538330 4.032425 -14.868156
v  -1.538330 4.032425 -17.203201
v  -1.538330 4.032425 -12.533112
v  2.109898 4.032425 -12.533112
v  2.109898 4.032425 -19.613924
v  4.512946 2.951772 -13.492785
v  4.525005 2.261008 -11.225092
v  4.859758 0.713803 -23.964600
v  -0.801062 0.106543 -24.614334
v  4.384180 0.109887 -20.514721
v  -3.812612 0.109887 -20.514721
v  -0.801062 0.109888 -24.187784
v  1.372631 0.109888 -24.187786
v  -4.613664 3.343168 -23.139172
v  5.185232 3.343168 -23.139172
v  1.312631 0.105636 -24.615234
v  5.125232 0.105636 -21.420927
v  -4.553664 0.105636 -21.420927
v  -4.240062 0.105636 -20.574720
v  4.811630 0.105636 -20.574720
v  -3.699213 0.109887 -16.566206
v  -3.699213 0.109887 -14.762964
v  -3.699213 0.109887 -14.305768
v  2.049898 2.788694 -15.001745
v  2.049898 2.788694 -12.666700
v  -3.699213 0.109887 -18.919704
v  4.270782 0.109887 -16.566206
v  4.270782 0.109887 -18.919704
v  4.270782 0.109887 -14.762964
v  -1.478330 2.788694 -12.666700
v  -1.478330 2.788694 -15.001745
v  4.270782 0.109887 -14.305769
v  0.623604 5.276234 -20.514290
v  0.285784 5.512938 -20.514290
v  -0.052035 5.276234 -20.514290
v  1.366443 6.938631 -12.225241
v  -0.778707 7.215764 -10.375841
v  -1.737325 2.873554 -11.290576
v  2.308893 2.873554 -11.290576
v  -2.977816 2.939733 -11.290576
v  3.549385 2.939733 -11.290576
v  4.871818 3.172023 -23.952541
v  5.212098 4.031625 -23.962879
v  1.392646 6.012493 -20.855089
v  -3.086667 2.962278 -11.350576
v  3.658236 2.962278 -11.350576
v  -4.854896 0.762040 -20.826567
v  0.802531 0.691182 -10.464714
v  5.409266 2.412337 -23.413944
v  -4.837697 2.412337 -23.413944
v  -4.837697 0.762039 -23.413944
v  -4.837697 3.237485 -23.413944
v  -4.837697 4.804519 -23.413946
v  -4.837697 4.062634 -23.413946
v  5.409266 4.062634 -23.413946
v  5.409266 3.237485 -23.413944
v  5.409266 4.804519 -23.413946
v  5.409266 1.587188 -23.413944
v  -4.837697 1.587188 -23.413944
v  -4.838728 4.804520 -20.810400
v  5.409266 0.762039 -23.413944
v  5.410297 3.237486 -20.810400
v  5.410297 4.062634 -20.810400
v  -4.838728 4.062634 -20.810400
v  -4.838728 1.587188 -20.810398
v  4.495630 2.868507 -18.317822
v  -1.777915 4.812904 -18.317665
v  -3.924062 2.868507 -18.317822
v  2.349484 4.812904 -18.317665
v  -3.924062 2.296035 -16.061617
v  -3.924062 1.444332 -16.061617
v  -3.924062 2.868509 -11.549779
v  -3.924062 2.212773 -11.549778
v  -3.924062 2.212772 -13.805411
v  4.495630 0.592630 -15.766304
v  -4.612877 0.762039 -23.935225
v  -3.698259 3.243075 -20.308453
v  4.312984 4.861138 -20.265297
v  4.538786 2.916744 -11.592935
v  1.355003 5.223945 -21.190641
v  2.349483 4.812904 -15.766150
v  1.349489 7.742988 -10.610888
v  5.200496 1.570536 -20.274099
v  -3.940229 0.592629 -18.006342
v  -4.612760 4.804520 -20.290268
v  4.297480 4.038612 -20.280800
v  -3.725912 4.038612 -20.280800
v  -4.640529 3.206476 -23.962875
v  -4.640529 4.031625 -23.962879
v  -0.796023 2.254524 -11.253489
v  1.367592 2.254524 -11.253489
v  5.184329 0.762040 -20.290268
v  4.495630 0.509367 -11.254467
v  4.495630 1.444332 -16.061617
v  4.495630 0.592629 -18.022511
v  -3.402781 1.361070 -11.029646
v  -1.777915 4.812904 -15.766150
v  3.974350 2.868509 -11.029647
v  -0.777921 7.742988 -12.207864
v  -0.777921 7.742988 -10.375672
v  0.895607 -0.067656 -10.375868
v  -4.629045 1.587188 -23.951393
v  -0.778707 6.440536 -12.209074
v  -0.801062 3.936572 -24.121391
v  1.372631 3.936572 -24.121391
v  -0.818287 6.047520 -23.469051
v  1.389856 6.047520 -23.469051
v  -4.595380 2.329073 -23.396629
v  5.166948 2.329073 -23.396629
v  -4.595380 3.154222 -23.396629
v  5.166948 3.154222 -23.396629
v  -4.595380 4.887783 -23.396631
v  5.166948 3.979370 -23.396631
v  -4.595380 3.979370 -23.396631
v  5.166948 4.887783 -23.396631
v  -4.595380 1.503924 -23.396629
v  -4.595380 0.678775 -23.396629
v  -0.819818 5.176206 -23.698162
v  1.391386 5.176206 -23.698162
v  1.356577 5.176206 -23.401882
v  5.166948 0.678776 -23.396629
v  -0.785008 5.176206 -23.401882
v  -0.488344 6.047520 -23.469051
v  1.059912 6.047520 -23.469051
v  5.166948 1.503924 -23.396629
v  -3.940229 2.212773 -11.238299
v  -3.940229 2.868509 -11.238300
v  3.958349 4.812903 -20.292286
v  -0.797900 5.176207 -20.272953
v  -0.795236 7.826251 -10.628202
v  4.512946 0.509366 -18.335136
v  -3.941378 2.296036 -11.237151
v  4.512946 2.951771 -15.748990
v  1.809770 2.416532 -11.008516
v  -3.941378 1.444333 -11.237151
v  -3.385466 2.296036 -11.012331
v  -3.941378 2.951772 -13.492785
v  4.512946 2.951773 -11.237152
v  -1.238201 4.896169 -11.008518
v  -1.795230 4.896169 -13.822571
v  -1.238201 2.416532 -11.008516
v  -3.941378 2.951773 -11.237152
v  1.809770 4.896169 -11.008518
v  1.809770 3.817527 -11.008516
v  4.286977 2.951773 -11.012331
v  -1.238201 3.817527 -11.008516
v  -3.715409 0.592630 -11.012331
v  3.957034 1.444333 -11.012331
v  -4.300133 4.887784 -20.272953
v  3.957200 4.896166 -20.291136
v  -4.553664 2.411320 -22.391689
v  4.538786 1.409305 -11.592935
v  1.330737 5.123950 -23.757664
v  -0.759168 5.123950 -23.757664
v  -3.967218 0.557602 -11.211310
v  5.212098 3.206476 -23.962875
v  1.366443 6.440536 -12.225241
v  5.200613 0.762039 -23.951393
v  -3.940229 2.296035 -18.333990
v  4.286977 0.592630 -11.012331
v  1.367592 7.021894 -10.358526
v  -3.639213 1.720506 -19.227716
v  -4.854896 3.237486 -20.826567
v  -4.628927 2.412337 -20.274101
v  -3.639213 1.963269 -19.202202
v  -3.639213 1.720506 -19.471817
v  -3.639213 2.037458 -14.728643
v  4.511797 0.592630 -13.493933
v  -4.613664 3.936571 -21.847191
v  5.185232 3.936571 -21.847191
v  -0.801062 3.944981 -24.704504
v  1.372631 3.944981 -24.704504
v  4.511797 2.868509 -11.238300
v  -3.699213 1.698876 -14.656961
v  4.270782 1.698875 -12.335310
v  -3.699213 1.698876 -16.922485
v  4.270782 1.698876 -19.259018
v  -3.639213 1.682582 -16.956869
v  4.210782 1.682583 -19.293402
v  2.366799 4.896168 -16.078777
v  -0.798586 5.223945 -23.355633
v  1.370154 5.223945 -23.355633
v  -0.796023 7.299027 -10.358526
v  2.366799 4.896168 -15.748834
v  4.512946 2.296036 -13.822727
v  -4.613664 1.658991 -20.996372
v  5.185232 1.658991 -20.996372
v  -0.741063 3.936680 -24.120495
v  1.312631 3.936680 -24.120495
v  -0.035808 5.946003 -10.443367
v  -0.718700 5.761955 -11.063627
v  1.290269 5.761955 -11.063627
v  5.125232 3.360485 -21.842188
v  -4.553664 3.360485 -21.842188
v  5.125232 0.108994 -22.817474
v  -4.553664 0.108994 -22.817474
v  -2.487447 2.707701 -11.290576
v  -2.271237 2.755505 -11.290576
v  2.842805 2.755505 -11.290576
v  3.059016 2.707701 -11.290576
v  -3.097124 2.572900 -11.290576
v  3.668692 2.572900 -11.290576
v  -2.695889 2.661614 -11.290576
v  3.267457 2.661614 -11.290576
v  3.750161 2.885727 -11.290576
v  -3.178592 2.885727 -11.290576
v  3.849330 2.906293 -11.350576
v  -3.277762 2.906293 -11.350576
v  4.387077 1.444737 -20.514721
v  -3.815508 1.444737 -20.514721
v  -3.953437 0.544394 -16.090992
v  -2.903456 2.614799 -11.350576
v  3.475024 2.614799 -11.350576
v  -2.896029 3.988808 -20.574604
v  3.467597 3.988808 -20.574604
v  -3.941378 2.296036 -13.822727
v  5.201762 3.154221 -23.952541
v  -4.630075 1.503925 -20.272951
v  -4.630193 3.154221 -23.952541
v  -4.201437 3.988808 -20.574722
v  4.773005 3.988808 -20.574722
v  -4.630193 1.503924 -23.952541
v  -3.924151 2.212771 -18.022423
v  -1.778004 4.896169 -11.251378
v  -3.924151 1.361068 -18.317909
v  5.227485 4.014400 -20.247112
v  4.512946 2.212772 -15.748990
v  4.512946 0.509366 -13.822726
v  1.350365 5.518755 -12.209163
v  -0.778796 7.299027 -12.209163
v  5.439672 2.364102 -20.485714
v  4.210782 1.818133 -12.604474
v  -3.639213 1.720505 -12.548109
v  4.270782 2.036558 -14.766682
v  4.270782 1.990933 -12.547503
v  -3.699213 1.990933 -12.547503
v  4.495719 1.444333 -11.254377
v  4.538786 0.544394 -16.104773
v  -4.881885 4.852756 -20.471933
v  4.871701 1.503925 -20.272951
v  -0.794088 5.964258 -20.500454
v  5.426465 0.762040 -20.498920
v  -0.796752 5.092943 -20.274101
v  5.200496 4.062634 -20.274101
v  -4.628927 4.062634 -20.274101
v  5.200496 2.412338 -20.531435
v  5.200496 1.587189 -20.531433
v  5.200496 4.804520 -20.531435
v  5.200496 4.062634 -20.531435
v  5.200496 3.237486 -20.531435
v  -4.628927 2.412338 -20.531435
v  -4.628927 1.587189 -20.531433
v  4.254089 2.296035 -15.750138
v  -4.628927 3.237486 -20.531435
v  4.889133 0.762039 -23.710224
v  -4.317564 0.762039 -23.710224
v  -4.317564 2.412336 -23.710224
v  4.889133 2.412336 -23.710224
v  4.889133 1.587188 -23.710224
v  -4.317564 1.587188 -23.710224
v  4.889133 4.062634 -23.710226
v  4.889133 3.237485 -23.710224
v  -4.317564 4.062634 -23.710226
v  -4.317564 3.237485 -23.710224
v  5.184445 4.804519 -23.710228
v  5.184445 2.412336 -23.710224
v  1.040674 5.092943 -20.531435
v  -0.765386 5.092943 -20.826567
v  -0.469106 5.092943 -20.531435
v  1.336955 5.092943 -20.826567
v  -4.612877 4.804519 -23.710228
v  1.336955 5.092943 -20.498920
v  -0.765386 5.092943 -20.498920
v  -0.469106 5.092943 -20.274101
v  2.049898 3.988809 -17.251556
v  2.049898 3.988809 -19.662279
v  -1.478330 3.988809 -17.251556
v  2.049898 3.988809 -14.916512
v  -1.478330 3.988809 -12.581467
v  2.049898 3.988809 -12.581467
v  -1.478330 3.988809 -14.916512
v  -1.478330 3.988809 -19.662279
v  -1.538330 3.978755 -16.819464
v  2.109898 3.978755 -16.819464
v  2.109898 3.978755 -14.484419
v  -1.538330 3.978755 -14.484419
v  -1.538330 3.978755 -19.230186
v  2.109898 3.978755 -19.230185
v  2.109898 3.978755 -12.149374
v  -1.538330 3.978755 -12.149374
v  4.270878 2.272325 -11.253400
v  -3.699309 2.272325 -11.253400
v  4.871818 2.329072 -23.952541
v  -4.300249 2.329072 -23.952541
v  5.184417 2.329074 -20.290180
v  4.512946 2.296036 -13.492785
v  4.340912 3.978755 -20.514721
v  -3.769343 3.978755 -20.514721
v  1.350365 5.518755 -10.375752
v  -2.463945 3.978755 -20.514540
v  3.035513 3.978755 -20.514540
v  -1.023869 5.176207 -20.497774
v  1.595438 5.176207 -20.497774
v  3.944975 0.557602 -11.284021
v  4.299037 0.557602 -11.284021
v  5.453454 0.713805 -20.853556
v  -3.741416 4.021385 -20.577007
v  4.312984 3.194839 -20.576927
v  -3.741416 3.194839 -20.576927
v  4.312984 4.021385 -20.577007
v  -4.655916 4.014400 -20.558426
v  4.312984 4.861138 -20.577087
v  -3.741416 4.861138 -20.577087
v  4.538786 2.916743 -16.104773
v  -4.655916 4.852756 -20.558426
v  -4.274409 4.852755 -23.667068
v  4.845977 4.852755 -23.667068
v  4.845861 0.713805 -20.558424
v  4.227370 0.544394 -15.723149
v  -1.509281 4.861139 -17.979198
v  2.080850 4.861139 -17.979198
v  -1.509281 4.861141 -13.466788
v  2.080849 4.861141 -13.466788
v  -3.373407 0.557602 -11.284021
v  2.094631 4.861141 -13.834630
v  -1.523062 4.861141 -13.834630
v  -4.656033 2.364101 -23.667068
v  -4.656033 1.538952 -23.667068
v  -4.656033 0.713804 -23.667068
v  -4.656033 3.189250 -23.667068
v  -4.656033 4.014399 -23.667072
v  2.080850 4.861139 -16.104616
v  -4.274292 0.713805 -20.558424
v  4.227235 1.396097 -15.723149
v  -0.477817 5.141178 -23.686104
v  1.049386 5.141178 -23.686104
v  -4.553664 3.878686 -21.514208
v  5.125232 3.878686 -21.514208
v  5.185232 3.878211 -23.068432
v  -4.613664 3.878211 -23.068432
v  -3.951714 2.899517 -13.833064
v  4.495719 2.296036 -11.549868
v  4.495719 1.361068 -18.317909
v  4.888928 4.887784 -20.290180
v  4.270782 1.782235 -14.581905
v  -4.317359 3.154222 -20.290180
v  -4.612966 4.887783 -23.935316
v  0.515007 0.970302 -10.375868
v  -2.689511 1.794228 -11.313516
v  3.261079 1.794228 -11.313516
v  -3.940229 1.361069 -13.493933
v  -0.794875 5.435492 -10.359674
v  3.958349 3.243075 -20.292286
v  -0.741063 1.659100 -24.189785
v  1.312631 1.659100 -24.189785
v  -4.553664 1.659099 -22.820374
v  5.125232 1.659099 -22.820374
v  -0.794875 7.215764 -12.225241
v  2.378858 4.878368 -11.576154
v  4.210782 1.444845 -11.986474
v  -3.639213 1.444845 -11.986474
v  4.210782 1.444845 -19.378901
v  4.210782 1.444845 -12.443669
v  -3.639213 1.444845 -17.025402
v  -3.639213 1.444845 -19.378901
v  -3.639213 1.444845 -12.443669
v  -3.639213 1.444845 -18.921705
v  -3.639213 1.444845 -14.764965
v  4.210782 1.444845 -17.025402
v  4.210782 1.444845 -14.764965
v  -3.639213 1.444845 -16.568207
v  4.210782 1.444845 -18.921703
v  4.210782 1.444845 -16.568207
v  -3.639213 1.444845 -14.307769
v  4.210782 1.444845 -14.307769
v  4.538786 2.916744 -13.848568
v  -3.940229 2.868509 -13.493933
v  -4.612966 3.979370 -23.935316
v  4.523283 2.243781 -11.226814
v  -3.951714 2.899516 -15.738653
v  -4.553664 2.411320 -21.860945
v  5.125232 2.411320 -21.860945
v  4.297314 0.540375 -11.001994
v  4.523283 0.561621 -18.345474
v  4.297314 2.899518 -11.001995
v  -0.805573 7.773996 -10.638539
v  -4.289796 4.835528 -20.262617
v  4.861365 4.835528 -20.262617
v  1.826996 1.015537 -11.025743
v  5.125232 1.735808 -22.611177
v  -4.553664 1.735808 -22.611176
v  5.125232 1.492043 -21.860945
v  5.125232 1.446913 -22.290325
v  -0.818669 5.092942 -23.956644
v  5.426465 1.587188 -20.498920
v  -4.613664 1.951681 -22.656157
v  5.185232 1.951681 -21.596478
v  5.185232 2.061841 -22.644579
v  4.523283 2.243781 -13.833064
v  -4.613664 1.557934 -22.480850
v  4.495719 2.212771 -18.317911
v  -3.698183 2.951773 -11.029558
v  4.269751 2.296036 -11.029557
v  -3.402693 1.444333 -11.029557
v  2.349572 4.896169 -13.805345
v  -3.924151 2.951771 -15.766216
v  -3.924151 0.509366 -13.510011
v  -3.402858 4.896166 -20.308363
v  -0.505570 6.047520 -23.711933
v  -3.951714 1.413323 -17.994858
v  2.150049 3.765272 -10.998180
v  1.799433 0.963282 -10.998180
v  4.889133 0.762039 -23.935225
v  -3.924062 2.296035 -18.022511
v  -4.317564 1.587187 -23.935225
v  -3.359626 0.540375 -10.986490
v  -3.940229 0.592630 -13.821578
v  4.872850 1.587188 -20.274099
v  1.367592 7.021894 -12.226389
v  5.185232 3.723213 -22.666124
v  -4.613664 3.723213 -22.666124
v  -3.941378 0.509366 -15.748989
v  -4.630193 0.678775 -23.952541
v  3.623032 1.933431 -11.313516
v  -3.051463 1.933431 -11.313516
v  -4.553664 1.798303 -23.182325
v  1.312631 1.798303 -24.551735
v  4.210782 1.584049 -14.669722
v  4.210782 1.584049 -16.930159
v  -3.639213 1.584049 -12.348427
v  4.210782 1.584049 -15.126917
v  -3.639213 1.584049 -14.669721
v  -3.639213 1.584049 -16.930159
v  -3.639213 1.584049 -19.283657
v  -3.639213 1.584049 -19.740852
v  -3.639213 1.584049 -12.805622
v  4.210782 1.584049 -12.348427
v  -3.639213 1.584049 -15.126917
v  4.210782 1.584049 -19.283657
v  4.210782 1.584049 -17.387354
v  -3.639213 1.584049 -17.387354
v  4.210782 1.584049 -19.740852
v  4.210782 1.584049 -12.805622
v  4.523283 2.265026 -18.345474
v  4.523283 2.243781 -11.577431
v  -4.613664 1.492827 -21.861397
v  4.495630 1.361069 -13.805411
v  -4.612760 1.587189 -20.290268
v  3.958183 0.509366 -11.270814
v  4.285829 0.509366 -11.270813
v  2.138564 0.932273 -11.267103
v  1.810918 0.932273 -11.267103
v  4.254224 1.444332 -18.333988
v  1.366443 1.109061 -10.359674
v  5.169130 0.762040 -20.826567
v  5.169130 1.587188 -20.826567
v  5.169130 2.412337 -20.826567
v  -3.386780 4.069621 -20.550018
v  3.958349 4.069621 -20.550018
v  3.958349 3.243075 -20.549938
v  -4.301281 3.237486 -20.531435
v  -3.386780 3.243075 -20.549938
v  4.872850 3.237486 -20.531435
v  4.872850 1.587189 -20.531433
v  4.872850 2.412338 -20.531435
v  -4.301281 2.412338 -20.531435
v  -4.301281 1.587189 -20.531433
v  5.169130 4.804520 -20.826567
v  -4.597561 4.804520 -20.826567
v  -4.596529 2.412336 -23.725424
v  5.168097 2.412336 -23.725424
v  4.872850 4.062634 -20.531435
v  5.168097 4.804519 -23.725426
v  -4.301281 4.062634 -20.531435
v  -3.386780 4.812903 -20.550098
v  -4.596529 4.062634 -23.725426
v  -4.596529 3.237485 -23.725424
v  -4.596529 4.804519 -23.725426
v  3.958349 4.812903 -20.550098
v  5.168097 3.237485 -23.725424
v  4.253985 2.868508 -16.077784
v  -4.301281 4.804520 -20.531435
v  5.168097 4.062634 -23.725426
v  4.872850 4.804520 -20.531435
v  -4.597561 2.412337 -20.826567
v  -4.597561 1.587189 -20.826567
v  -4.596529 1.587188 -23.725424
v  -4.597561 0.762040 -20.826567
v  5.168097 1.587188 -23.725424
v  -4.597561 4.062634 -20.826567
v  5.169130 4.062634 -20.826567
v  5.169130 3.237486 -20.826567
v  -4.597561 3.237486 -20.826567
v  -4.596529 0.762039 -23.725424
v  5.200496 0.762040 -20.531435
v  4.254224 1.361069 -13.493933
v  -0.536276 5.964258 -20.828100
v  1.107844 5.964258 -20.828100
v  5.168097 0.762039 -23.725424
v  3.958183 1.361070 -11.270814
v  4.254359 0.592629 -16.077784
v  4.254089 2.296035 -18.006344
v  4.254359 0.592629 -18.006342
v  4.285829 1.361070 -11.270814
v  4.285829 1.361070 -11.013479
v  4.254359 0.592629 -18.333988
v  4.254463 1.361070 -11.238299
v  4.254463 1.361070 -11.565946
v  1.366443 2.189062 -10.359674
v  -3.386614 0.509367 -11.270814
v  4.253985 2.868507 -18.333990
v  -3.682417 2.868507 -18.333990
v  -3.714261 0.509367 -11.270813
v  -3.682656 1.361069 -13.493933
v  -3.682791 0.592629 -18.006342
v  -1.536270 4.812904 -16.077627
v  -3.682791 0.592630 -16.077784
v  -1.566996 0.932273 -11.267103
v  -3.682656 1.444331 -18.006342
v  -3.682521 2.296035 -18.006344
v  -3.682656 1.444332 -16.077784
v  -1.239350 0.932273 -11.267103
v  -0.794875 2.189061 -10.359674
v  -3.682521 2.296035 -16.077784
v  -3.714261 2.212773 -11.013479
v  -3.714261 1.361070 -11.270814
v  4.254089 2.296035 -16.077784
v  -0.794875 4.349062 -10.359674
v  -3.714261 2.868509 -11.270814
v  -0.794875 1.109061 -10.359674
v  4.285829 2.212773 -11.270814
v  2.138564 2.333268 -11.009665
v  3.958183 2.868509 -11.270814
v  -3.682895 1.361070 -11.238299
v  1.366443 4.349062 -10.359674
v  -1.794082 4.812906 -11.562946
v  -3.386614 1.361070 -11.270814
v  -3.682791 0.592630 -13.821578
v  2.138564 2.333268 -11.267239
v  2.365651 4.812905 -13.821423
v  4.254463 2.212773 -11.238299
v  4.253985 2.868509 -13.493933
v  -3.386614 2.212773 -11.270814
v  -1.566996 2.333268 -11.009665
v  -3.682895 2.212773 -11.565946
v  -1.239350 3.734263 -11.267373
v  -3.682895 2.868509 -11.565946
v  -3.682895 2.212773 -11.238299
v  4.254463 2.212773 -11.565946
v  -1.566996 2.333268 -11.267239
v  -3.682895 2.868509 -11.238300
v  -1.566996 3.734263 -11.267373
v  -0.794875 3.269059 -10.359674
v  -3.682417 2.868508 -15.750138
v  2.138564 3.734263 -11.267373
v  4.254463 2.868509 -11.565946
v  -3.682417 2.868508 -16.077784
v  -1.794082 4.812905 -13.493776
v  -1.239350 2.333268 -11.267239
v  4.254463 2.868509 -11.238300
v  1.810918 3.734263 -11.267373
v  4.285829 2.212773 -11.013479
v  1.366443 3.269059 -10.359674
v  4.253985 2.868508 -15.750138
v  -3.386614 2.868509 -11.270814
v  -3.682417 2.868509 -13.493933
v  4.285829 2.868509 -11.270814
v  -3.714261 2.212773 -11.270814
v  -3.682791 0.592630 -13.493933
v  -3.682895 1.361070 -11.565946
v  1.810918 2.333268 -11.267239
v  3.958183 2.212773 -11.270814
v  4.254089 2.296035 -18.333990
v  -3.682521 2.212772 -13.493933
v  -3.682791 0.592629 -15.750137
v  4.253985 2.868507 -18.006344
v  -3.682417 2.868507 -18.006344
v  -3.682791 0.592629 -18.333988
v  -3.682656 1.444332 -15.750138
v  -3.682521 2.296035 -15.750138
v  -3.682656 1.444331 -18.333988
v  -3.682521 2.296035 -18.333990
v  -3.682895 0.509367 -11.565945
v  -3.682521 2.212772 -13.821579
v  5.169130 0.762040 -20.498920
v  -1.043491 5.092942 -23.730675
v  1.062593 5.092942 -23.699310
v  -0.491025 5.092942 -23.699310
v  4.254089 2.212772 -13.821579
v  5.200496 2.412337 -20.274101
v  -0.536276 7.742988 -11.956481
v  1.107844 7.742988 -11.956481
v  4.254089 2.212772 -13.493933
v  1.107844 7.742988 -10.627054
v  4.511797 1.444332 -18.333988
v  1.107844 7.742988 -10.359505
v  -0.536276 7.742988 -12.224030
v  4.254359 0.592630 -13.821578
v  4.254359 0.592630 -13.493933
v  1.107844 7.742988 -12.224030
v  4.254224 1.444332 -18.006342
v  -0.536276 7.742988 -10.627054
v  -0.536276 7.742988 -10.359505
v  1.365657 7.742988 -12.224030
v  4.254463 0.509366 -11.565945
v  -4.597561 3.237486 -20.498920
v  5.169130 3.237486 -20.498920
v  5.169130 2.412338 -20.498920
v  5.169130 1.587189 -20.498920
v  -4.597561 2.412338 -20.498920
v  -4.597561 1.587189 -20.498920
v  -4.597561 0.762040 -20.498920
v  -4.597561 4.062634 -20.498920
v  5.169130 4.062634 -20.498920
v  -4.301281 4.062634 -20.274101
v  4.872850 4.062634 -20.274101
v  5.169130 4.804520 -20.498920
v  -4.628927 0.762040 -20.531435
v  -4.597561 4.804520 -20.498920
v  1.594289 5.092943 -20.498920
v  1.107844 5.964258 -20.500454
v  -0.536276 5.964258 -20.500454
v  4.254224 1.444332 -16.077784
v  -0.778700 4.447567 -11.553219
v  1.350269 4.447567 -11.553219
v  -2.071087 2.799759 -11.290576
v  -1.865600 2.845193 -11.290576
v  2.437169 2.845193 -11.290576
v  2.642656 2.799759 -11.290576
v  -4.613664 1.797828 -21.356659
v  5.185232 1.797828 -21.356659
v  -4.175795 1.583574 -20.514721
v  4.747363 1.583574 -20.514721
v  -0.795236 6.047522 -20.829248
v  5.427613 3.154222 -20.827717
v  -4.856044 3.979371 -20.827717
v  5.427613 3.979371 -20.827717
v  -4.856044 1.503925 -20.827715
v  4.512946 1.361068 -16.078932
v  5.426581 1.503924 -23.396629
v  -4.855012 3.154222 -23.396629
v  5.426581 4.887783 -23.396631
v  5.426581 3.979370 -23.396631
v  -4.855012 3.979370 -23.396631
v  -4.855012 4.887783 -23.396631
v  5.426581 3.154222 -23.396629
v  -4.855012 0.678775 -23.396629
v  -4.855012 1.503924 -23.396629
v  -4.855012 2.329073 -23.396629
v  5.426581 2.329073 -23.396629
v  -3.941378 1.361068 -16.078932
v  -3.941378 2.212771 -16.078932
v  2.366799 4.896167 -18.334980
v  -3.941378 2.951771 -18.335138
v  4.512946 2.951771 -18.335138
v  -3.941378 1.444332 -13.822727
v  5.426581 0.678776 -23.396629
v  -3.715575 3.159811 -20.291136
v  4.287143 3.986357 -20.291136
v  -3.715575 3.986357 -20.291136
v  4.287143 3.159811 -20.291136
v  -3.951714 0.561621 -13.482448
v  -1.227865 3.765272 -10.998180
v  -0.741063 1.798303 -24.551735
v  5.125232 1.798303 -23.182325
v  -4.612760 3.237486 -20.290268
v  4.512946 1.361068 -18.005194
v  -4.274292 3.206478 -20.247112
v  -1.022720 5.092943 -20.498920
v  -1.795230 4.896168 -15.748834
v  3.301386 1.922855 -11.253515
v  -2.729817 1.922855 -11.253515
v  5.125232 1.788158 -21.034988
v  -4.553664 1.788158 -21.034988
v  4.425692 1.573904 -20.574722
v  -3.854123 1.573904 -20.574722
v  5.185232 1.787726 -22.860680
v  1.372631 1.787726 -24.230091
v  -4.613664 1.787726 -22.860680
v  -0.801062 1.787726 -24.230091
v  4.270782 1.573472 -14.348075
v  4.270782 1.573472 -16.608513
v  -3.699213 1.573472 -14.348075
v  4.270782 1.573472 -18.962011
v  -3.699213 1.573472 -18.962011
v  -3.699213 1.573472 -16.608513
v  4.270782 1.573472 -12.026781
v  -3.699213 1.573472 -12.026781
v  -3.699213 1.698875 -12.516806
v  -1.006553 5.092943 -20.810400
v  4.495630 2.296035 -15.766306
v  4.495630 2.212772 -13.510100
v  5.184445 4.804519 -23.935226
v  1.352153 5.092943 -20.290268
v  -4.612877 4.804519 -23.935226
v  1.374071 5.092942 -23.940477
v  4.889133 2.412336 -23.935225
v  4.889133 1.587187 -23.935225
v  3.931194 2.261008 -10.986490
v  2.392639 4.861141 -13.466788
v  2.165553 0.980508 -10.982676
v  5.201762 0.678775 -23.952541
v  5.185232 3.723213 -21.111130
v  -4.613664 3.723213 -21.111130
v  5.185232 2.410537 -22.391237
v  5.211981 4.821173 -20.262617
v  -4.301397 0.762039 -23.951393
v  5.425433 0.762039 -23.725424
v  -4.853864 4.804519 -23.725426
v  5.425433 3.237485 -23.725424
v  -4.853864 3.237485 -23.725424
v  5.425433 4.062634 -23.725426
v  -4.853864 4.062634 -23.725426
v  5.425433 4.804519 -23.725426
v  -4.853864 1.587187 -23.725424
v  -4.853864 0.762039 -23.725424
v  5.425433 2.412336 -23.725424
v  -4.853864 2.412336 -23.725424
v  5.425433 1.587187 -23.725424
v  -3.940229 0.592630 -15.750137
v  -3.940229 2.296035 -15.750138
v  -3.940229 1.444332 -15.750138
v  -3.940229 2.212772 -13.493933
v  -3.940229 2.868507 -18.006344
v  2.365651 4.812904 -18.006187
v  -1.794082 4.812904 -18.006187
v  4.511797 2.868507 -18.006344
v  4.511797 0.592629 -16.077784
v  -4.854896 4.062634 -20.498920
v  5.426465 4.062634 -20.498920
v  5.426465 3.237486 -20.498920
v  -4.854896 4.804520 -20.498920
v  5.426465 4.804520 -20.498920
v  -4.854896 1.587188 -20.498920
v  -3.386780 3.243075 -20.292286
v  0.285791 2.272325 -10.375752
v  -1.255428 4.896169 -11.251311
v  2.109994 4.310935 -20.514265
v  2.124005 4.896169 -11.251378
v  -1.538426 4.310935 -20.514265
v  1.826996 4.896169 -11.251311
v  -1.552437 4.896169 -11.251378
v  0.285777 2.272325 -10.375752
v  -1.795231 4.896167 -18.334980
v  4.845861 3.189251 -20.247112
v  -3.741416 4.861138 -20.265297
v  5.201644 0.678777 -20.272953
v  -4.856044 4.887783 -20.827717
v  4.512946 0.592630 -11.237151
v  1.366805 7.826251 -12.225180
v  -1.795230 4.896169 -11.564095
v  -3.941378 1.361068 -18.005194
v  -3.941378 2.212771 -18.335138
v  4.871701 3.979371 -20.272953
v  4.512946 0.509366 -15.748989
v  -4.655916 4.852756 -20.247112
v  5.125232 3.723321 -21.110233
v  -4.553664 3.723321 -21.110233
v  1.605774 5.109596 -20.838053
v  1.377142 5.980911 -20.839586
v  1.377142 7.759641 -11.944997
v  1.049576 5.980909 -23.739498
v  -1.578481 3.750916 -10.998180
v  -1.054976 5.109594 -23.391544
v  4.861365 2.395684 -20.262617
v  -4.289796 2.395684 -20.262617
v  1.366443 7.215764 -12.225241
v  4.495630 2.868509 -11.549779
v  -1.255517 0.932273 -11.025832
v  -3.924062 0.592629 -18.317822
v  -4.656033 2.364101 -23.978380
v  -4.288190 3.189250 -23.964600
v  -4.288190 1.538952 -23.964600
v  -3.953437 2.261008 -13.480725
v  -3.953437 0.527167 -18.347197
v  -0.830346 6.012492 -23.741219
v  -4.881885 0.713805 -20.853556
v  -3.967218 0.557602 -11.592934
v  -3.359626 2.916744 -10.986491
v  -3.714261 0.509367 -11.013479
v  -0.821863 6.986866 -10.332685
v  -4.881885 2.364102 -20.853558
v  0.194281 5.690464 -10.375868
v  1.350365 7.299027 -12.209163
v  4.270782 1.444737 -19.379797
v  -3.699213 1.444737 -19.379797
v  -3.699213 1.444737 -14.765862
v  4.270782 1.444737 -14.765862
v  -3.699213 1.444737 -17.026299
v  4.270782 1.444737 -17.026299
v  4.270782 1.444737 -12.444567
v  -3.699213 1.444737 -12.444567
v  5.185232 2.167187 -21.642284
v  -4.613664 2.167187 -21.642284
v  -4.301397 3.237485 -23.951393
v  -3.386780 4.069621 -20.292286
v  3.958349 4.069621 -20.292286
v  -4.301397 4.062634 -23.951393
v  1.366443 5.435492 -10.359674
v  -3.386780 4.812903 -20.292286
v  0.895607 0.112344 -10.375868
v  1.350269 -0.067656 -10.464714
v  5.166948 1.503924 -23.726572
v  4.871701 1.503925 -20.532583
v  5.167981 2.329074 -20.827717
v  -0.535127 6.047522 -20.829248
v  5.167981 0.678776 -20.827715
v  5.167981 1.503925 -20.827715
v  1.106696 6.047522 -20.829248
v  4.253076 1.444333 -13.822727
v  5.167981 3.154222 -20.827717
v  -4.596413 3.154222 -20.827717
v  -4.596413 3.979371 -20.827717
v  5.167981 3.979371 -20.827717
v  5.166948 3.979370 -23.726574
v  4.252837 2.951771 -16.078932
v  -4.595380 3.979370 -23.726574
v  -3.385632 4.896166 -20.551247
v  4.871701 3.979371 -20.532583
v  -4.595380 4.887783 -23.726574
v  3.957200 4.896166 -20.551247
v  -4.595380 3.154222 -23.726572
v  5.166948 3.154222 -23.726572
v  -4.300133 4.887784 -20.532583
v  5.166948 4.887783 -23.726574
v  4.871701 4.887784 -20.532583
v  -4.300133 3.979371 -20.532583
v  -4.595380 0.678775 -23.726572
v  -4.596413 1.503925 -20.827715
v  -4.595380 1.503924 -23.726572
v  -4.596413 0.678776 -20.827715
v  -4.300133 2.329074 -20.532583
v  4.871701 2.329074 -20.532583
v  -4.300133 1.503925 -20.532583
v  -4.596413 4.887783 -20.827717
v  5.167981 4.887783 -20.827717
v  -4.596413 2.329074 -20.827717
v  -4.595380 2.329073 -23.726572
v  5.166948 2.329073 -23.726572
v  -4.300133 3.154223 -20.532583
v  3.957200 3.986357 -20.551167
v  3.957200 3.159811 -20.551086
v  -3.385632 3.986357 -20.551167
v  4.871701 3.154223 -20.532583
v  -3.385632 3.159811 -20.551086
v  4.253076 1.361068 -18.335136
v  4.512946 1.444333 -13.822727
v  1.809770 1.015537 -11.268251
v  1.367592 1.192325 -10.358526
v  2.139713 1.015537 -11.268251
v  -0.795236 7.826251 -10.358356
v  4.253211 0.509366 -16.078932
v  4.253211 0.509366 -18.335136
v  1.106696 7.826251 -10.628202
v  4.252941 2.212771 -18.005196
v  4.512946 0.509366 -18.005194
v  4.253211 0.509366 -18.005194
v  -0.535127 7.826251 -10.628202
v  1.106696 7.826251 -10.358356
v  1.106696 7.826251 -12.225180
v  1.366805 7.826251 -10.628202
v  -0.535127 7.826251 -12.225180
v  -0.795236 7.826251 -12.225180
v  -0.535127 7.826251 -10.358356
v  1.367592 2.272325 -10.358526
v  4.253315 0.592630 -11.237151
v  4.253076 1.444333 -13.492785
v  -0.535127 7.826251 -11.955333
v  1.106696 7.826251 -11.955333
v  5.166948 0.678776 -23.726572
v  -3.698734 1.444332 -13.805500
v  4.252837 2.951771 -18.005196
v  -3.681268 2.951771 -18.005196
v  -3.681642 0.509366 -18.335136
v  -3.681642 0.509366 -15.748989
v  -3.681507 1.361068 -18.335136
v  -3.681372 2.212771 -18.335138
v  -3.681372 2.296036 -13.492785
v  -3.681507 1.361068 -15.748990
v  -3.681372 2.212772 -15.748990
v  -3.681642 0.509366 -13.822726
v  4.252941 2.212771 -18.335138
v  -3.385466 0.592630 -11.012331
v  -3.681642 0.509366 -13.492784
v  3.957034 2.951773 -11.271963
v  -1.568144 3.817527 -11.268522
v  -1.568144 1.015536 -11.268251
v  -1.238201 2.416532 -11.268387
v  -3.681746 2.951773 -11.237152
v  -1.795230 4.896169 -13.492628
v  -3.715409 1.444333 -11.271962
v  4.252941 2.212772 -16.078932
v  -3.681268 2.951771 -16.078934
v  -0.796023 4.432325 -10.358526
v  2.139713 4.896169 -11.008518
v  -3.715409 2.296036 -11.012331
v  4.286977 2.296036 -11.271962
v  -3.385466 2.951773 -11.271963
v  -0.796023 1.192324 -10.358526
v  1.367592 4.432325 -10.358526
v  4.252837 2.951772 -13.822727
v  -3.715409 2.296036 -11.271962
v  -3.385466 1.444333 -11.271962
v  -3.941378 2.951773 -11.567095
v  4.253315 2.951773 -11.237152
v  -3.681746 2.296036 -11.567094
v  -3.385466 2.296036 -11.271962
v  -3.681746 2.951773 -11.567095
v  4.253315 2.296036 -11.567094
v  -3.681746 2.296036 -11.237151
v  -1.568144 2.416532 -11.268387
v  -0.796023 3.352323 -10.358526
v  2.139713 3.817527 -11.008516
v  2.139713 3.817527 -11.268522
v  -1.568144 2.416532 -11.008516
v  4.253315 2.951773 -11.567095
v  -3.941378 1.444333 -11.567094
v  4.253315 2.296036 -11.237151
v  -3.681746 1.444333 -11.567094
v  1.809770 3.817527 -11.268522
v  -3.681746 1.444333 -11.237151
v  -3.681268 2.951772 -13.822727
v  4.252837 2.951771 -15.748990
v  1.367592 3.352323 -10.358526
v  -1.238201 3.817527 -11.268522
v  -0.796023 2.272325 -10.358526
v  4.286977 2.951773 -11.271963
v  -1.568144 4.896169 -11.008518
v  1.809770 2.416532 -11.268387
v  -3.715409 2.951773 -11.271963
v  2.139713 2.416532 -11.008516
v  -3.941378 2.296036 -11.567094
v  3.957034 2.296036 -11.271962
v  3.957034 2.951773 -11.012331
v  -3.681268 2.951772 -13.492785
v  -3.941378 2.951772 -13.822727
v  2.139713 2.416532 -11.268387
v  4.252837 2.951772 -13.492785
v  -3.681268 2.951771 -15.748990
v  -3.681642 0.509366 -16.078932
v  -3.681372 2.212771 -18.005196
v  -1.238201 1.015536 -11.268251
v  -3.681507 1.361068 -16.078932
v  -3.681507 1.361068 -18.005194
v  -1.535121 4.896168 -16.078775
v  -3.681372 2.212772 -16.078932
v  4.252837 2.951771 -18.335138
v  -3.681268 2.951771 -18.335138
v  -3.681642 0.509366 -18.005194
v  2.106690 4.896168 -15.748834
v  -1.535121 4.896168 -15.748834
v  -3.681507 1.444333 -13.492785
v  -3.681746 0.592630 -11.237151
v  5.184417 3.154222 -20.290180
v  -3.698599 2.296036 -13.805501
v  5.201644 0.678776 -20.532583
v  -3.715409 0.592630 -11.271962
v  4.270167 2.296036 -13.805501
v  -0.797900 5.176207 -20.532585
v  1.369469 5.176207 -20.532585
v  2.106690 4.896167 -18.334980
v  -1.535121 4.896167 -18.334980
v  -3.681746 0.592630 -11.567094
v  5.167981 1.503925 -20.497772
v  5.167981 0.678776 -20.497772
v  1.106696 6.047522 -20.499306
v  -0.535127 6.047522 -20.499306
v  4.253315 1.444333 -11.237151
v  4.286977 1.444333 -11.012331
v  4.253211 0.509366 -13.822726
v  4.286977 1.444333 -11.271962
v  4.252941 2.296036 -13.492785
v  3.957034 1.444333 -11.271962
v  4.253315 1.444333 -11.567094
v  4.253211 0.509366 -13.492784
v  4.253076 1.361068 -18.005194
v  4.253076 1.361069 -16.078932
v  5.201762 3.979370 -23.952541
v  4.253315 0.592630 -11.567094
v  5.167981 4.887784 -20.497772
v  -4.596413 4.887784 -20.497772
v  -4.630075 0.678776 -20.532583
v  -4.300133 3.979371 -20.272953
v  -4.596413 0.678776 -20.497772
v  -4.596413 3.979371 -20.497772
v  5.167981 3.979371 -20.497772
v  -4.596413 1.503925 -20.497772
v  -4.596413 2.329074 -20.497772
v  5.167981 2.329074 -20.497772
v  5.167981 3.154223 -20.497772
v  -4.596413 3.154223 -20.497772
v  -1.044640 5.176206 -23.731825
v  -0.819818 5.176206 -23.957792
v  1.391386 5.176206 -23.957792
v  1.377928 6.969639 -10.348189
v  -3.951714 1.392077 -13.833063
v  4.297480 3.212067 -20.280800
v  5.125232 3.723321 -22.665228
v  -4.553664 3.723321 -22.665226
v  1.377142 5.995266 -20.488970
v  5.453454 4.852755 -20.853558
v  -1.124116 1.933432 -11.320996
v  1.695685 1.933432 -11.320996
v  5.227602 1.538952 -23.667068
v  5.227602 4.014399 -23.667072
v  5.227602 3.189250 -23.667068
v  5.227602 0.713803 -23.667068
v  -1.593984 0.980508 -10.982676
v  4.538786 2.247799 -17.979355
v  -4.613664 4.244797 -22.003742
v  5.185232 4.244797 -22.003742
v  1.422986 0.458377 -11.320996
v  -0.851417 0.458377 -11.320996
v  3.258179 0.458376 -11.313516
v  -2.686611 0.458376 -11.313516
v  1.290269 2.444647 -10.851276
v  -0.718700 2.444647 -10.851276
v  -0.718700 1.346958 -10.851276
v  1.290269 1.346958 -10.851276
v  0.868431 2.436924 -10.435867
v  -0.296862 2.436924 -10.435867
v  0.868431 1.346958 -10.435867
v  -0.296862 1.346958 -10.435867
v  1.290269 4.445139 -10.828548
v  -0.718700 4.445139 -10.828548
v  1.290269 3.364900 -10.828548
v  -0.718700 3.364900 -10.828548
v  0.868431 3.364900 -10.435867
v  -0.296863 3.364900 -10.435867
v  0.868431 4.437627 -10.435867
v  -0.296863 4.437627 -10.435867
v  1.312631 3.355730 -24.722942
v  -0.741063 3.355730 -24.722942
v  2.049898 2.791839 -19.172541
v  -3.287376 2.530835 -11.290576
v  4.858238 2.788694 -20.574722
v  2.049898 2.791839 -12.091728
v  -1.538330 2.792734 -16.762718
v  -1.538330 2.789599 -19.746613
v  2.109898 2.792734 -16.762718
v  -1.478330 2.791839 -19.172541
v  -1.478330 2.791839 -12.091728
v  -1.478330 2.791839 -14.426773
v  2.109898 2.792734 -14.427672
v  -4.286670 2.788694 -20.574722
v  3.858944 2.530835 -11.290576
v  2.109898 2.789599 -19.746613
v  3.552831 2.788694 -20.574615
v  -2.981262 2.788694 -20.574615
v  -0.821849 2.237297 -11.217690
v  1.393418 2.237297 -11.217690
v  -0.853584 1.794228 -11.320996
v  1.425153 1.794228 -11.320996
v  -0.053366 0.896292 -10.375868
v  4.511797 1.444332 -15.750138
v  4.872850 0.762040 -20.274101
v  1.365657 7.742988 -10.359505
v  4.511797 0.509366 -11.565945
v  -0.794088 7.742988 -11.956481
v  2.365651 4.812904 -16.077629
v  -4.553664 1.423844 -22.070839
v  4.270391 1.361069 -13.805411
v  -0.794088 5.964258 -20.828100
v  4.285995 4.812903 -20.550098
v  -4.628927 4.062634 -20.531435
v  -3.714427 4.812903 -20.550098
v  -4.628927 4.804520 -20.531435
v  4.511797 2.868508 -16.077784
v  4.285995 3.243075 -20.549938
v  -3.714427 3.243075 -20.549938
v  4.285995 4.069621 -20.550018
v  -3.714427 4.069621 -20.550018
v  1.368320 5.092943 -20.531435
v  -0.796752 5.092943 -20.531435
v  5.426465 0.762040 -20.826567
v  -4.317564 4.804519 -23.710226
v  4.889133 4.804519 -23.710226
v  -3.698823 1.361069 -13.805411
v  -3.698584 2.868509 -13.805412
v  4.270152 2.868509 -13.805412
v  -1.536270 4.812904 -18.333832
v  2.107839 4.812904 -18.333832
v  2.107838 4.812905 -13.821423
v  -1.536270 4.812905 -13.821423
v  4.511797 2.868509 -13.821579
v  2.107838 4.812905 -13.493777
v  -1.536270 4.812905 -13.493776
v  2.107839 4.812904 -18.006187
v  -1.536270 4.812904 -18.006187
v  5.184445 0.762039 -23.710224
v  -3.682895 0.509367 -11.238299
v  5.184445 3.237485 -23.710224
v  5.184445 4.062634 -23.710228
v  4.254463 0.509367 -11.238299
v  -1.536270 4.812904 -15.749982
v  2.107838 4.812904 -15.749982
v  5.184445 1.587187 -23.710224
v  5.200496 3.237486 -20.274101
v  4.254224 1.444332 -15.750138
v  -4.612877 4.062634 -23.710228
v  -4.612877 3.237485 -23.710224
v  -4.612877 0.762039 -23.710224
v  -4.612877 1.587187 -23.710224
v  -4.612877 2.412336 -23.710224
v  2.107839 4.812904 -16.077629
v  -4.301281 0.762040 -20.531435
v  4.872850 0.762040 -20.531435
v  4.254359 0.592629 -15.750137
v  -4.301397 2.412336 -23.951393
v  -3.715382 2.189061 -11.237276
v  4.286951 2.189062 -11.237276
v  -0.462503 5.995265 -23.755001
v  2.150049 4.843914 -10.998180
v  -3.725746 2.899518 -11.001995
v  -1.578481 4.843914 -10.998180
v  -3.951714 1.392078 -11.577431
v  4.538786 0.544395 -13.466944
v  1.628267 5.158404 -23.389822
v  -1.056699 5.158404 -23.389822
v  1.378864 7.808450 -10.346298
v  -0.807296 7.808450 -11.943274
v  5.427613 0.696577 -20.497772
v  5.201762 2.346874 -23.952541
v  -4.630193 2.346874 -23.952541
v  4.859642 2.346875 -20.260895
v  -4.630075 3.997173 -20.272953
v  -0.489876 5.158404 -23.957792
v  1.061445 5.158404 -23.957792
v  1.366443 5.452144 -12.225241
v  5.125232 1.573288 -21.420927
v  -4.553664 1.573288 -21.420927
v  -4.240062 1.359034 -20.574722
v  4.811630 1.359034 -20.574722
v  -4.613664 1.736175 -21.642284
v  1.035604 5.141178 -23.983631
v  -1.805567 4.843913 -16.089113
v  2.377136 4.843914 -11.223815
v  -3.725746 1.392078 -11.001994
v  -3.951714 2.899516 -16.089270
v  1.366443 2.189062 -11.253489
v  -0.794875 2.189062 -11.253489
v  1.400192 5.995265 -23.739498
v  0.916193 5.331444 -20.514290
v  -0.344624 5.331444 -20.514290
v  -4.288074 2.346875 -20.260895
v  4.872966 3.237485 -23.951393
v  1.393432 6.471544 -12.252230
v  0.285791 1.109061 -10.375841
v  0.285777 6.938631 -10.375841
v  0.285791 5.435492 -10.375841
v  0.285791 6.938631 -10.375841
v  0.285777 5.435492 -10.375841
v  0.285791 4.349062 -10.375841
v  0.285777 1.109061 -10.375841
v  0.285777 3.269059 -10.375841
v  0.285777 4.349062 -10.375841
v  0.285791 3.269059 -10.375841
v  0.285791 7.215764 -10.375841
v  0.285777 7.215764 -10.375841
v  0.285777 6.440536 -10.375841
v  0.285791 6.440536 -10.375841
v  3.685630 1.708356 -11.253515
v  -3.114061 1.708356 -11.253515
v  -0.823741 5.141178 -20.247112
v  -0.821077 7.791223 -10.654043
v  4.538786 0.544394 -18.360977
v  1.379651 6.505998 -12.238448
v  -3.741250 0.557602 -10.986490
v  4.312818 2.916744 -10.986491
v  1.783929 4.861141 -10.982677
v  -1.212361 3.782499 -10.982676
v  1.783929 3.782499 -10.982676
v  -1.821071 4.861141 -13.848412
v  -1.821071 4.861141 -11.208311
v  -1.212361 2.381503 -10.982676
v  -1.212361 4.861141 -10.982677
v  -3.967218 2.916744 -11.211311
v  -3.359626 2.261008 -10.986490
v  -3.967218 2.916744 -13.466944
v  -3.967218 1.409305 -11.211310
v  4.538786 2.916744 -11.211311
v  -3.967218 2.247799 -17.979355
v  1.783929 2.381503 -10.982676
v  4.538786 2.916743 -15.723149
v  -3.967218 2.261008 -11.211310
v  -3.967218 1.396096 -18.360977
v  -4.274292 4.852756 -20.247112
v  3.931360 4.861138 -20.265297
v  3.931194 1.409305 -10.986490
v  -3.953437 0.574829 -11.225092
v  -3.373407 2.933971 -11.000273
v  2.378858 4.878367 -13.480570
v  -3.953437 2.933970 -16.090992
v  -3.727468 1.426532 -11.000272
v  4.525005 2.230572 -17.993135
v  3.944975 2.278235 -11.000272
v  2.378858 4.878368 -11.222093
v  1.379651 6.505998 -10.346466
v  5.213821 4.869981 -23.964602
v  1.401915 6.029719 -23.741219
v  1.642049 5.141178 -23.757664
v  4.538786 2.247799 -16.104773
v  5.227602 1.538952 -23.978380
v  5.227485 1.538953 -20.247112
v  5.227602 2.364101 -23.978380
v  5.410387 2.329074 -20.810490
v  5.184417 3.979371 -20.290180
v  -1.226142 0.997735 -10.996457
v  5.185232 1.573227 -23.244923
v  1.372631 1.573228 -24.614334
v  -4.613664 1.573227 -23.244923
v  -0.801062 1.573228 -24.614334
v  1.379651 6.986866 -12.238448
v  -3.699213 1.358973 -17.449951
v  4.270782 1.358973 -17.449953
v  -3.699213 1.358974 -19.803452
v  4.270782 1.358973 -19.803452
v  -3.699213 1.358973 -12.868220
v  4.270782 1.358973 -12.868220
v  -3.699213 1.358973 -15.189515
v  4.270782 1.358973 -15.189515
v  4.210782 2.029530 -19.293402
v  -0.778796 7.021894 -12.209163
v  -0.796023 5.500954 -12.226389
v  4.270782 1.893792 -14.925240
v  4.270782 1.698876 -17.103981
v  -3.699213 1.893792 -14.925240
v  4.270782 2.028669 -16.957148
v  -3.699213 1.893792 -17.190763
v  -3.699213 2.028669 -14.691624
v  -3.699213 1.675555 -19.368738
v  -4.613664 4.254560 -22.250828
v  5.185232 4.254560 -22.250828
v  1.415696 5.995265 -23.443211
v  -0.844128 5.995265 -23.443211
v  1.330737 5.123950 -23.376041
v  5.141108 0.731031 -23.370789
v  -0.759168 5.123950 -23.376041
v  -4.569540 4.031626 -23.370792
v  5.141108 4.031626 -23.370792
v  5.141108 2.381328 -23.370789
v  5.141108 4.835527 -23.370792
v  -4.569540 2.381328 -23.370789
v  5.141108 3.206477 -23.370789
v  -4.569540 4.835527 -23.370792
v  -4.569540 3.206477 -23.370789
v  -0.462503 5.995265 -23.443211
v  5.141108 1.556179 -23.370789
v  1.034072 5.995265 -23.443211
v  -4.569540 1.556179 -23.370789
v  -4.569540 0.731030 -23.370789
v  -0.845658 5.123950 -23.672321
v  1.417227 5.123950 -23.672321
v  4.312818 0.557602 -10.986490
v  4.538786 1.409305 -11.211310
v  1.393432 6.986866 -10.332685
v  4.888928 0.678776 -20.290180
v  -4.838818 2.329074 -20.810490
v  4.495719 0.592630 -11.549868
v  4.495719 1.361069 -15.766216
v  4.495719 2.951772 -13.805501
v  1.349579 7.826251 -11.972560
v  -4.838818 3.154222 -20.514999
v  4.495719 0.509366 -16.061705
v  -4.838818 4.887784 -20.514999
v  4.871818 3.997171 -23.952541
v  4.871701 3.172024 -20.272953
v  4.512946 1.426531 -13.492785
v  -4.856044 0.696578 -20.497772
v  4.210782 1.698092 -16.922033
v  -3.639213 1.698091 -12.334858
v  4.210782 1.893979 -12.247644
v  -3.639213 1.893979 -12.247644
v  4.210782 1.893980 -14.569295
v  -3.639213 1.893980 -14.569295
v  -3.639213 1.893980 -16.834820
v  4.210782 1.893980 -16.834820
v  -4.300133 0.696578 -20.272953
v  5.427613 4.869982 -20.497772
v  -0.821863 6.986866 -12.252230
v  -4.866381 3.220833 -20.838053
v  5.227485 4.852756 -20.558426
v  5.227485 2.364102 -20.558426
v  5.227485 4.014400 -20.558426
v  5.227485 1.538953 -20.558424
v  5.227485 3.189251 -20.558426
v  4.845977 0.713803 -23.667067
v  -4.655916 2.364102 -20.558426
v  -4.655916 1.538953 -20.558424
v  -4.655916 3.189251 -20.558426
v  4.227100 2.247800 -15.723149
v  -4.274409 0.713804 -23.667067
v  4.845977 2.364101 -23.667067
v  -4.274409 2.364101 -23.667067
v  -4.274409 1.538952 -23.667067
v  -4.274409 3.189250 -23.667067
v  4.845977 3.189250 -23.667067
v  -4.274409 4.014399 -23.667068
v  4.845977 4.014399 -23.667068
v  4.845977 1.538952 -23.667067
v  -3.967218 0.544394 -17.979355
v  5.227602 4.852755 -23.667072
v  5.227602 2.364101 -23.667068
v  3.931194 0.557602 -10.986490
v  -4.656033 4.852755 -23.667072
v  1.309966 5.141178 -20.853558
v  1.013686 5.141178 -20.558426
v  -0.738397 5.141178 -20.853558
v  -0.442117 5.141178 -20.558426
v  -0.738397 5.141178 -20.471933
v  -0.442117 5.141178 -20.247112
v  1.309966 5.141178 -20.471933
v  -4.642135 2.364102 -20.260895
v  1.027467 5.141178 -20.260895
v  -0.806360 5.466500 -12.236726
v  0.442960 5.719702 -10.375868
v  0.607377 6.088989 -10.443367
v  0.022349 5.815379 -10.443367
v  0.194281 6.344528 -10.443367
v  0.070451 5.761957 -10.375868
v  0.607377 5.946003 -10.375868
v  -0.718700 6.344528 -11.399975
v  1.290269 6.344528 -11.399975
v  1.290269 6.315289 -11.121784
v  -0.718700 6.315289 -11.121784
v  1.350269 6.272362 -11.523199
v  -0.778700 6.272362 -11.523199
v  1.350269 6.088800 -10.958252
v  -0.778700 6.088800 -10.958252
v  1.393432 6.969639 -12.252230
v  0.442960 5.719702 -10.443367
v  2.138564 0.948926 -11.009665
v  3.958183 1.377722 -11.013479
v  5.437950 0.731031 -20.487436
v  -3.940229 1.427679 -18.333988
v  2.365651 4.829558 -13.493777
v  -1.239350 2.349921 -11.009665
v  1.810918 3.750916 -11.009665
v  4.511797 2.885161 -13.493933
v  3.958183 2.229426 -11.013479
v  1.810918 2.349921 -11.009665
v  -1.794082 4.829558 -11.235300
v  -3.386614 2.229426 -11.013479
v  -1.794082 4.829558 -13.821423
v  4.511797 2.885160 -15.750138
v  5.211981 4.031627 -20.262617
v  -4.629045 2.395684 -23.951393
v  -4.640412 4.031627 -20.262617
v  4.511797 0.575977 -13.821578
v  4.511797 1.427679 -18.006342
v  -0.805573 5.995266 -20.488970
v  -1.034205 5.123951 -20.487438
v  -0.808237 5.123951 -20.262617
v  -0.821863 7.263999 -10.332685
v  -3.967218 0.544394 -15.723148
v  2.392640 4.861139 -15.722993
v  4.538786 2.261007 -13.848568
v  -4.656033 0.713803 -23.978380
v  -4.274409 4.852754 -23.978382
v  1.393432 5.483727 -10.332685
v  5.227602 0.713803 -23.978380
v  -1.821071 4.861139 -15.722993
v  5.201762 1.503924 -23.952541
v  -4.856044 3.154222 -20.827717
v  1.366805 6.047522 -20.829248
v  -4.856044 2.329074 -20.497772
v  5.201644 4.887784 -20.272953
v  -4.630075 2.329074 -20.272953
v  -3.715575 4.896166 -20.291136
v  5.439672 1.521726 -20.839775
v  -4.868104 0.696578 -20.839775
v  -0.808082 5.500954 -10.346466
v  -0.808082 6.505998 -10.346466
v  -0.808082 7.004093 -10.346466
v  1.379651 7.281226 -10.346466
v  -1.807290 4.878366 -16.090836
v  0.143068 6.516250 -10.443367
v  -0.464036 5.123950 -23.983631
v  -4.881885 3.206478 -20.471933
v  4.845977 4.031625 -23.978382
v  4.538786 1.392078 -13.466944
v  1.379651 5.483727 -12.238448
v  -0.778700 5.691324 -11.187736
v  1.350269 5.691324 -11.187736
v  5.426465 1.570536 -20.826567
v  -0.491025 5.109594 -23.956644
v  -4.854896 0.745387 -20.498920
v  4.270782 1.854285 -16.829910
v  -3.699213 1.854285 -16.829910
v  1.350276 7.215764 -10.375841
v  -3.402781 0.509367 -11.029646
v  4.269828 4.812903 -20.308453
v  1.598893 5.092942 -23.419197
v  4.270782 1.854114 -12.242731
v  -3.699213 1.854114 -12.242731
v  -3.699213 1.854127 -14.564383
v  4.270782 1.853613 -19.166426
v  -3.699213 1.853613 -19.166426
v  -4.274292 1.556180 -20.247112
v  2.109898 3.932938 -17.290255
v  -1.538330 3.932938 -14.955210
v  2.109898 3.932938 -19.700977
v  2.109898 3.932938 -14.955210
v  -1.538330 3.932938 -17.290255
v  -1.538330 3.932938 -19.700977
v  2.109898 3.932938 -12.620164
v  -1.538330 3.932938 -12.620164
v  1.426051 1.794147 -11.260997
v  -0.854483 1.794147 -11.260997
v  -0.821077 6.012493 -20.855089
v  4.538786 1.396097 -16.104773
v  -4.881885 4.014400 -20.853558
v  5.453454 4.014400 -20.853558
v  -4.881885 1.538953 -20.853556
v  5.453454 3.189250 -20.853558
v  -4.656033 3.189250 -23.978380
v  5.227602 3.189250 -23.978380
v  -3.967218 2.247800 -16.104773
v  -3.967218 1.396096 -16.104773
v  2.392640 4.861139 -18.360821
v  -3.967218 2.916742 -18.360979
v  -1.821071 4.861139 -18.360821
v  4.538786 2.916742 -18.360979
v  4.538786 2.261008 -11.592935
v  -3.967218 1.409304 -13.848567
v  -3.967218 2.261007 -13.848568
v  -4.655916 1.538953 -20.247112
v  4.312984 4.021386 -20.265297
v  -3.741416 4.021386 -20.265297
v  4.312984 3.194840 -20.265297
v  -3.741416 3.194840 -20.265297
v  -4.880853 4.852755 -23.370789
v  -4.880853 4.014399 -23.370789
v  5.452422 3.189250 -23.370789
v  5.452422 4.852755 -23.370789
v  -4.880853 3.189250 -23.370789
v  5.452422 4.014399 -23.370789
v  -4.880853 0.713804 -23.370789
v  -4.880853 2.364101 -23.370789
v  5.452422 2.364101 -23.370789
v  4.845861 4.852756 -20.247112
v  -4.880853 1.538953 -23.370789
v  5.452422 1.538953 -23.370789
v  -4.656033 1.538952 -23.978380
v  4.538786 1.396096 -18.360977
v  5.452422 0.713803 -23.370789
v  1.059912 6.029719 -23.729160
v  -4.300249 3.997171 -23.952541
v  4.512946 1.426532 -11.567094
v  -3.941378 0.527168 -13.822726
v  -3.941378 0.527167 -18.005194
v  -0.818287 6.029719 -23.729160
v  1.381528 5.158405 -20.260895
v  -3.953437 0.574829 -11.579153
v  4.871818 4.869981 -23.952541
v  4.525005 2.247800 -15.736930
v  5.426581 1.521725 -23.726572
v  -0.795236 6.029720 -20.499306
v  -4.855012 1.521725 -23.726572
v  5.426581 2.346874 -23.726572
v  -4.855012 2.346874 -23.726572
v  -4.855012 0.696577 -23.726572
v  5.426581 3.172023 -23.726572
v  5.426581 3.997172 -23.726574
v  5.426581 4.869981 -23.726574
v  -4.855012 4.869981 -23.726574
v  -4.855012 3.997172 -23.726574
v  -4.855012 3.172023 -23.726572
v  -3.941378 1.426531 -13.492785
v  4.512946 2.278235 -11.237151
v  -4.856044 1.521726 -20.497772
v  5.427613 3.172024 -20.497772
v  -4.856044 3.997173 -20.497772
v  5.427613 3.997173 -20.497772
v  -3.941378 2.933969 -18.005196
v  4.512946 2.933969 -18.005196
v  2.366799 4.878366 -18.005039
v  -1.795231 4.878366 -18.005039
v  -3.941378 2.230573 -15.748990
v  -3.941378 1.378870 -15.748990
v  5.427613 1.521726 -20.497772
v  5.426581 0.696577 -23.726572
v  -3.385632 4.004158 -20.291136
v  3.957200 4.004158 -20.291136
v  -3.385632 3.177613 -20.291136
v  3.957200 3.177613 -20.291136
v  -4.642252 4.852754 -23.964602
v  -4.288074 3.189251 -20.260895
v  4.525005 1.396096 -17.993135
v  4.525005 0.544394 -13.834785
v  4.872850 3.220833 -20.274101
v  -3.714427 4.829556 -20.292286
v  -0.794875 7.232417 -10.359674
v  -4.866381 2.395684 -20.487438
v  -0.794088 7.742988 -10.627054
v  1.040674 5.092943 -20.274101
v  -1.794082 4.812904 -16.077627
v  4.285829 2.868509 -11.013479
v  -3.714261 1.361070 -11.013479
v  2.365651 4.812906 -11.235300
v  -4.301281 4.804520 -20.274101
v  4.872850 4.804520 -20.274101
v  5.200613 1.587188 -23.951393
v  5.200613 2.412336 -23.951393
v  4.285829 0.509367 -11.013479
v  4.511797 0.592629 -18.333988
v  1.615060 5.092942 -23.730675
v  -0.796023 6.505998 -12.226389
v  -3.639213 1.674654 -14.766776
v  4.210782 1.893980 -14.926126
v  -3.639213 1.893980 -19.528181
v  4.210782 1.674654 -14.766776
v  4.210782 1.674654 -12.445126
v  -3.639213 1.893980 -14.926125
v  4.210782 1.991606 -12.548109
v  -3.639213 1.991606 -12.548109
v  -3.639213 2.037458 -16.994167
v  4.210782 1.698092 -17.104433
v  4.210782 2.037458 -16.994167
v  -1.070480 5.123950 -23.376041
v  -3.699213 1.698875 -12.335310
v  4.270782 1.698876 -16.922485
v  -3.699213 2.036558 -17.032206
v  4.270782 2.013236 -12.335310
v  4.270782 1.893792 -17.190763
v  -3.699213 2.013236 -12.335310
v  -3.699213 2.013237 -19.259018
v  4.270782 2.013237 -19.440514
v  4.270782 1.856057 -19.531261
v  4.270782 2.013237 -19.259018
v  -0.806360 6.471544 -12.236726
v  5.200613 4.045981 -23.951393
v  -4.629045 1.570535 -23.951393
v  5.453454 2.381329 -20.853558
v  5.453454 1.556180 -20.853556
v  -0.821863 6.488770 -10.332685
v  1.393432 7.263999 -10.332685
v  -0.806360 6.955283 -12.236726
v  5.125232 3.974414 -22.984537
v  -4.553664 3.974414 -22.984537
v  4.210782 1.893980 -19.171352
v  5.185232 3.973726 -21.428961
v  -4.613664 3.973726 -21.428961
v  4.845977 4.852754 -23.978382
v  1.350269 6.314504 -11.122236
v  -0.778700 6.314504 -11.122236
v  -0.821863 7.246772 -12.252230
v  -4.613664 3.815108 -22.447842
v  5.185232 3.815108 -22.447842
v  4.845977 0.731030 -23.978382
v  4.523283 1.377722 -11.226814
v  -0.821863 5.466500 -10.332685
v  -4.274409 3.206476 -23.978382
v  -4.274409 0.731030 -23.978382
v  4.538786 0.561621 -16.104773
v  5.452422 1.556179 -23.752413
v  -4.866381 0.745387 -20.838051
v  -4.289796 0.745387 -20.262615
v  -3.967218 2.243781 -13.466944
v  -3.967218 1.392078 -13.466944
v  -3.951714 0.526019 -11.577430
v  -3.951714 2.885161 -13.482449
v  -3.951714 2.885162 -11.226815
v  -1.227865 4.829558 -10.998180
v  -3.375129 2.885162 -11.001995
v  -3.951714 1.377723 -11.226814
v  -3.951714 2.229426 -11.226814
v  1.799433 4.829558 -10.998180
v  -3.967218 2.265026 -17.979355
v  -3.967218 0.561621 -15.723148
v  -0.828624 5.980909 -23.739498
v  -3.359791 4.038612 -20.265297
v  3.931360 3.212066 -20.265297
v  3.931360 4.038612 -20.265297
v  -3.359791 3.212066 -20.265297
v  -3.967218 2.265027 -15.723149
v  -3.967218 1.413324 -15.723149
v  4.538786 2.899516 -17.979355
v  -3.967218 2.899516 -17.979355
v  2.392640 4.843913 -17.979198
v  -1.821071 4.843913 -17.979198
v  3.946864 4.829556 -20.280800
v  5.453454 4.031627 -20.471933
v  -4.881885 4.031627 -20.471933
v  5.453454 3.206478 -20.471933
v  -4.881885 1.556180 -20.471931
v  -4.880853 1.556179 -23.752413
v  -4.274409 4.031625 -23.978382
v  -4.880853 3.206477 -23.752413
v  5.452422 3.206477 -23.752413
v  5.452422 4.031626 -23.752415
v  -4.880853 4.031626 -23.752415
v  5.452422 4.835527 -23.752415
v  -4.880853 4.835527 -23.752415
v  5.452422 2.381328 -23.752413
v  -4.880853 2.381328 -23.752413
v  -4.880853 0.731030 -23.752413
v  -4.881885 4.835528 -20.471933
v  5.453454 4.835528 -20.471933
v  -4.274409 1.556179 -23.978380
v  5.452422 0.731031 -23.752413
v  4.525005 2.933971 -13.480725
v  -4.642252 4.014399 -23.964602
v  4.859642 1.538953 -20.260893
v  5.426465 2.412337 -20.826567
v  4.511797 2.296035 -16.077784
v  -4.301397 4.804519 -23.951393
v  4.872966 4.804519 -23.951393
v  4.511797 2.212772 -13.821579
v  -4.301281 3.237486 -20.274101
v  1.062593 5.092942 -23.956644
v  1.039526 5.176207 -20.272953
v  -1.565989 4.363190 -20.514265
v  1.799433 4.843914 -11.251311
v  -1.552437 4.843914 -11.223815
v  -1.227865 4.843914 -11.251311
v  2.137558 4.363190 -20.514265
v  0.285791 2.220070 -10.348189
v  2.124005 4.843914 -11.223815
v  0.285777 2.220070 -10.348189
v  -4.866381 3.220833 -20.487438
v  -3.176054 2.555448 -11.290576
v  3.747622 2.555448 -11.290576
v  -2.975206 2.599856 -11.290576
v  3.546775 2.599856 -11.290576
v  -2.567614 2.689976 -11.290576
v  3.139182 2.689976 -11.290576
v  -2.776080 2.643883 -11.290576
v  3.347649 2.643883 -11.290576
v  -2.142962 2.783867 -11.290576
v  -2.359172 2.736063 -11.290576
v  2.930741 2.736063 -11.290576
v  2.714530 2.783867 -11.290576
v  -1.942812 2.828121 -11.290576
v  2.514381 2.828121 -11.290576
v  4.283266 2.791839 -20.574722
v  -3.711698 2.791839 -20.574722
v  5.125232 3.355729 -22.448742
v  -4.553664 3.355729 -22.448742
v  1.290269 4.445139 -11.829751
v  -0.718700 4.445139 -11.829751
v  -2.406290 2.791839 -20.574532
v  2.977859 2.791839 -20.574532
v  1.290269 4.446672 -10.551116
v  3.686530 0.455018 -11.313516
v  1.312631 3.360485 -24.116388
v  0.590999 3.366434 -10.435867
v  1.743144 0.455018 -11.320996
v  0.590999 4.439161 -10.435867
v  2.049898 2.788694 -17.336790
v  1.290269 3.366434 -10.551116
v  0.285784 4.432345 -10.375868
v  0.285784 2.272344 -10.375868
v  0.590999 2.438457 -10.435867
v  1.290269 1.348492 -10.573845
v  1.290269 2.446181 -10.573845
v  0.590999 1.348492 -10.435867
v  -3.698320 2.439974 -11.290576
v  -0.741063 3.360485 -24.116386
v  -0.718700 1.348492 -10.573845
v  -0.019430 3.366434 -10.435867
v  4.269889 2.439974 -11.290576
v  0.285784 1.192344 -10.375868
v  -1.478330 2.788694 -17.336790
v  -1.171576 0.455019 -11.320996
v  -3.114961 0.455018 -11.313516
v  -0.718700 2.446181 -10.573845
v  -0.718700 4.446672 -10.551116
v  -0.019430 2.438457 -10.435867
v  -0.019430 4.439161 -10.435867
v  -0.718700 3.366434 -10.551116
v  -0.019430 1.348492 -10.435867
v  0.151098 6.837667 -10.375868
v  0.285791 1.192325 -10.358526
v  0.285777 6.523799 -10.358526
v  0.285791 7.299027 -10.358526
v  0.285791 6.523799 -10.358526
v  0.285777 7.299027 -10.358526
v  0.285777 1.192324 -10.358526
v  0.285791 4.432325 -10.358526
v  0.285777 4.432325 -10.358526
v  0.285791 3.352323 -10.358526
v  0.285777 3.352323 -10.358526
v  0.285777 7.021894 -10.358526
v  0.285791 5.518755 -10.358526
v  0.285777 5.518755 -10.358526
v  0.285791 7.021894 -10.358526
v  4.538786 1.413324 -15.723149
v  4.845977 3.206476 -23.978382
v  1.350269 3.058629 -10.842381
v  -0.778700 3.058629 -10.842381
v  -1.535752 2.918123 -11.290576
v  2.107320 2.918123 -11.290576
v  0.859535 3.050905 -10.375868
v  -0.287966 3.050905 -10.375868
v  -0.287966 1.960940 -10.375868
v  0.859535 1.960940 -10.375868
v  1.350269 6.837403 -11.392098
v  -0.778700 6.837403 -11.392098
v  -0.324038 0.371893 -10.464714
v  1.350269 5.211906 -11.820855
v  -0.778700 5.211906 -11.820855
v  -0.287966 4.131667 -10.375868
v  0.859535 4.131667 -10.375868
v  -0.778700 6.744008 -10.932304
v  1.350269 6.744008 -10.932304
v  -0.778700 6.744009 -11.832031
v  1.350269 6.744009 -11.832031
v  0.859536 6.744010 -10.375868
v  -0.287967 6.744010 -10.375868
v  4.523283 1.392078 -13.833063
v  -0.794875 6.955283 -10.359674
v  5.200613 0.745386 -23.951393
v  -4.853864 0.745386 -23.397778
v  -4.853864 2.395684 -23.397778
v  5.425433 2.395684 -23.397778
v  -4.853864 3.220832 -23.397778
v  5.425433 4.821172 -23.397779
v  5.425433 4.045981 -23.397779
v  -4.853864 4.045981 -23.397779
v  5.425433 3.220832 -23.397778
v  -4.853864 4.821172 -23.397779
v  -4.853864 1.570535 -23.397778
v  5.425433 1.570535 -23.397778
v  5.426465 4.821172 -20.826567
v  -4.854896 4.821172 -20.826567
v  -1.794082 4.829556 -18.333832
v  -3.940229 2.885160 -18.333990
v  2.365651 4.829556 -18.333832
v  4.511797 2.885160 -18.333990
v  -3.940229 1.427679 -16.077784
v  -3.940229 2.279382 -16.077784
v  -4.854896 2.395684 -20.826567
v  -4.854896 4.045982 -20.826567
v  5.426465 4.045982 -20.826567
v  -4.854896 1.570536 -20.826567
v  5.426465 3.220833 -20.826567
v  -3.940229 0.575977 -16.077784
v  -3.940229 2.279382 -18.333990
v  -3.940229 2.885161 -13.821579
v  -3.940229 2.885162 -11.565946
v  -3.940229 2.229426 -11.565946
v  -3.940229 2.229425 -13.821579
v  5.425433 0.745386 -23.397778
v  -4.629045 3.220832 -23.951393
v  -4.629045 0.745386 -23.951393
v  -4.629045 4.045981 -23.951393
v  -4.640412 1.556180 -20.262615
v  -3.714427 4.052968 -20.292286
v  4.285995 4.052968 -20.292286
v  -3.714427 3.226422 -20.292286
v  4.511797 0.575977 -15.750137
v  0.859535 5.204394 -10.375868
v  -0.287966 5.204394 -10.375868
v  -0.718700 6.834926 -11.125441
v  1.290269 6.834926 -11.125441
v  0.184195 6.898493 -10.443367
v  -3.940229 2.868508 -16.077784
v  4.511797 2.212773 -11.565946
v  4.511797 2.296035 -18.333990
v  -4.881885 3.189250 -20.853558
v  -1.170676 1.708371 -11.260997
v  1.742245 1.708371 -11.260997
v  4.871818 3.154221 -23.952541
v  -4.300249 3.154221 -23.952541
v  -4.300249 1.503924 -23.952541
v  5.184535 2.329072 -23.710136
v  5.184535 3.979370 -23.710138
v  5.184535 1.503924 -23.710136
v  5.184535 4.887783 -23.710138
v  5.184535 3.154222 -23.710136
v  5.184535 0.678776 -23.710136
v  5.184417 1.503925 -20.290178
v  -1.568144 1.015536 -11.008516
v  -3.941378 2.296036 -13.492785
v  3.957034 0.592630 -11.012331
v  -4.300133 1.503925 -20.272951
v  5.201644 1.521727 -20.532583
v  4.871818 1.521726 -23.692907
v  5.201644 3.172024 -20.532583
v  5.201644 4.869982 -20.532583
v  5.201644 2.346875 -20.532583
v  5.201644 3.997173 -20.532583
v  -4.642135 0.696578 -20.260893
v  -3.715575 3.177613 -20.551086
v  -4.630075 3.172024 -20.532583
v  4.287143 3.177613 -20.551086
v  4.287143 4.004158 -20.551167
v  -3.715575 4.004158 -20.551167
v  -4.630075 4.869982 -20.532583
v  5.427613 4.869982 -20.827717
v  -4.630075 1.521727 -20.532583
v  4.512946 2.933970 -16.078932
v  4.287143 4.878365 -20.551247
v  -4.630075 2.346875 -20.532583
v  -0.764238 5.158405 -20.497774
v  5.427613 0.696577 -20.827715
v  4.252941 2.230573 -15.748990
v  -3.715575 4.878365 -20.551247
v  -0.467957 5.158405 -20.272953
v  1.335806 5.158405 -20.497774
v  -4.630075 3.997173 -20.532583
v  4.871818 3.997172 -23.692909
v  -4.300249 4.869981 -23.692909
v  4.871818 4.869981 -23.692909
v  4.871818 3.172023 -23.692907
v  -4.300249 0.696577 -23.692907
v  -4.300249 3.997172 -23.692909
v  -4.300249 3.172023 -23.692907
v  4.871818 0.696577 -23.692907
v  -4.300249 1.521725 -23.692907
v  -4.300249 2.346874 -23.692907
v  4.871818 2.346874 -23.692907
v  4.525005 0.527168 -13.480724
v  2.151772 0.997735 -10.996457
v  -1.580203 3.799726 -10.996457
v  4.525005 2.933971 -11.579153
v  4.525005 2.230573 -16.090992
v  2.106690 4.878366 -18.005039
v  -1.535121 4.878367 -13.492628
v  2.106690 4.878367 -13.492628
v  -1.535121 4.878366 -18.005039
v  1.607497 5.158405 -20.839775
v  -1.035928 5.158405 -20.839775
v  1.628267 5.158404 -23.743883
v  2.378858 4.878366 -16.090836
v  -4.630193 2.346874 -23.692909
v  -4.630193 4.869981 -23.692911
v  -4.630193 1.521725 -23.692909
v  -4.630193 0.696577 -23.692909
v  -4.630193 3.997172 -23.692911
v  -4.630193 3.172023 -23.692909
v  -4.630075 3.172024 -20.272953
v  1.356577 5.158404 -23.731825
v  1.335806 5.158405 -20.827717
v  -4.630075 4.869982 -20.272953
v  -0.785008 5.158404 -23.731825
v  4.871701 0.696577 -20.532583
v  4.253211 0.527167 -15.748989
v  4.287143 4.878365 -20.291136
v  -0.764238 5.158405 -20.827717
v  -0.467957 5.158405 -20.532585
v  1.039526 5.158405 -20.532585
v  2.106690 4.878366 -16.078777
v  -4.300249 4.869981 -23.952541
v  -4.300133 0.696578 -20.532583
v  4.253076 1.378869 -15.748990
v  -1.049709 5.141178 -20.471933
v  1.621278 5.141178 -20.471933
v  1.783929 0.980508 -10.982676
v  -4.553664 1.884073 -21.281570
v  4.672275 1.669819 -20.574722
v  -4.100706 1.669819 -20.574722
v  5.125232 1.884073 -21.281570
v  4.270782 0.109887 -19.376902
v  -3.699213 0.109887 -19.376902
v  -3.699213 0.109887 -17.023401
v  4.270782 0.109887 -17.023403
v  -3.699213 0.109887 -12.441669
v  4.270782 0.109887 -12.441669
v  1.290269 3.119714 -10.810152
v  -0.718700 3.119714 -10.810152
v  -0.718700 2.022025 -10.810152
v  1.290269 2.022025 -10.810152
v  -0.255736 3.111990 -10.435867
v  0.827305 3.111990 -10.435867
v  -0.255736 2.022025 -10.435867
v  0.827305 2.022025 -10.435867
v  -0.718700 6.898489 -11.359868
v  1.290269 6.898489 -11.359868
v  -0.718700 5.272991 -11.788624
v  1.290269 5.272991 -11.788624
v  0.827306 6.805096 -10.435867
v  -0.255737 6.805096 -10.435867
v  1.290269 5.272991 -10.787422
v  -0.718700 5.272991 -10.787422
v  -0.718700 4.192752 -10.787422
v  1.290269 4.192752 -10.787422
v  0.827305 4.192753 -10.435867
v  -0.255736 4.192753 -10.435867
v  1.290269 6.805092 -11.799801
v  -0.718700 6.805092 -11.799801
v  1.290269 6.805092 -10.900072
v  -0.718700 6.805092 -10.900072
v  0.827305 5.265479 -10.435867
v  -0.255736 5.265479 -10.435867
v  -0.801062 1.883386 -24.475296
v  -3.699213 1.669131 -15.050477
v  -4.613664 1.883385 -23.105885
v  4.270782 1.669131 -15.050477
v  1.372631 1.883386 -24.475296
v  -3.699213 1.669131 -17.310913
v  4.270782 1.669131 -12.729181
v  -3.699213 1.669131 -19.664413
v  -3.699213 1.669131 -12.729181
v  -2.975023 2.018514 -11.253515
v  4.270782 1.669131 -17.310913
v  4.270782 1.669131 -19.664413
v  3.546592 2.018513 -11.253515
v  5.185232 1.883385 -23.105885
v  2.365651 4.829557 -15.749982
v  1.365657 7.759641 -10.627054
v  4.523283 1.392078 -11.577431
v  -4.881885 2.364102 -20.471933
v  -4.640412 0.731032 -20.262615
v  4.297480 4.843912 -20.280800
v  -3.951714 0.540375 -11.226814
v  -4.866381 0.731031 -20.487436
v  1.330737 5.141178 -23.757664
v  -0.759168 5.141178 -23.757664
v  1.377928 6.471544 -10.348189
v  1.350269 5.267808 -10.579304
v  1.350269 5.267808 -11.580507
v  -0.778700 5.267808 -11.580507
v  -0.778700 5.267808 -10.579304
v  0.619187 5.260297 -10.375868
v  -0.047618 5.260297 -10.375868
v  -0.047618 2.016842 -10.375868
v  0.619187 2.016842 -10.375868
v  1.350269 4.187570 -10.579304
v  -0.778700 4.187570 -10.579304
v  0.619187 4.187570 -10.375868
v  0.619187 3.106807 -10.375868
v  -0.047619 4.187570 -10.375868
v  -0.047618 3.106807 -10.375868
v  1.350269 2.016842 -10.602034
v  -0.778700 6.893308 -11.151751
v  1.350269 6.893308 -11.151751
v  -0.778700 2.016842 -10.602034
v  1.350269 3.114531 -10.602034
v  -0.778700 3.114531 -10.602034
v  1.350269 6.799910 -10.691956
v  -0.778700 6.799910 -10.691956
v  -0.778700 6.799911 -11.591683
v  1.350269 6.799911 -11.591683
v  -0.047619 6.799914 -10.375868
v  0.619188 6.799914 -10.375868
v  -0.830154 5.109594 -23.968128
v  -3.725746 0.540375 -11.001994
v  -4.640412 3.206478 -20.262617
v  5.437950 2.395684 -20.487438
v  5.437950 1.570536 -20.487436
v  -0.801062 4.254560 -24.525028
v  1.372631 4.254560 -24.525028
v  1.642049 5.123950 -23.376041
v  4.538786 0.540375 -11.592934
v  -3.967218 0.561621 -18.360977
v  1.392646 7.773996 -11.929493
v  1.621278 5.123951 -20.853558
v  -0.821077 7.773996 -11.929493
v  4.845861 0.731031 -20.247112
v  1.312631 4.187760 -24.222672
v  -0.741063 4.187760 -24.222672
v  1.377142 5.980911 -20.488970
v  4.511797 2.885162 -11.238300
v  4.861481 4.045981 -23.962879
v  -4.640412 2.395684 -20.262617
v  -4.301281 1.570536 -20.274099
v  4.511797 1.377722 -13.493933
v  -0.794875 6.440536 -10.359674
v  1.366443 6.440536 -10.359674
v  5.168097 1.587188 -23.397778
v  5.168097 0.762039 -23.397778
v  -4.628927 0.762040 -20.274101
v  -3.940229 0.509367 -11.238299
v  -3.940229 0.592630 -13.493933
v  -1.566996 4.812906 -11.009666
v  -3.940229 1.361070 -11.565946
v  -1.239350 3.734263 -11.009665
v  2.138564 4.812906 -11.009666
v  -3.714261 2.868509 -11.013479
v  -3.940229 1.361069 -13.821579
v  4.285995 3.243075 -20.292286
v  -4.596529 4.062634 -23.397779
v  5.168097 3.237485 -23.397778
v  -4.596529 4.804519 -23.397779
v  -4.596529 0.762039 -23.397778
v  5.168097 4.804519 -23.397779
v  5.168097 4.062634 -23.397779
v  -4.596529 3.237485 -23.397778
v  -4.596529 1.587188 -23.397778
v  5.168097 2.412336 -23.397778
v  -4.596529 2.412336 -23.397778
v  1.388708 5.964257 -23.470200
v  -0.817139 5.964257 -23.470200
v  1.390238 5.092942 -23.699310
v  -0.818669 5.092942 -23.699310
v  1.357725 5.092942 -23.730675
v  -0.786157 5.092942 -23.730675
v  5.200496 1.587188 -20.274099
v  -0.489492 5.964257 -23.728012
v  4.511797 1.361070 -11.565946
v  1.366443 6.938631 -10.359674
v  1.357725 5.092942 -23.403030
v  -0.786157 5.092942 -23.403030
v  1.061061 5.964257 -23.470200
v  -0.489492 5.964257 -23.470200
v  1.388708 5.964257 -23.728012
v  1.290269 3.056151 -10.575722
v  -0.718700 3.056151 -10.575722
v  1.290269 1.958462 -10.575722
v  -0.718700 1.958462 -10.575722
v  1.290269 4.129190 -10.552994
v  -0.718700 4.129190 -10.552994
v  -0.718700 5.209429 -11.554196
v  -0.718700 5.209429 -10.552994
v  1.290269 5.209429 -11.554196
v  1.290269 5.209429 -10.552994
v  0.592877 3.048428 -10.435867
v  -0.021308 3.048428 -10.435867
v  0.592877 4.129190 -10.435867
v  -0.021308 4.129190 -10.435867
v  0.592877 1.958462 -10.435867
v  -0.021308 1.958463 -10.435867
v  0.592877 5.201917 -10.435867
v  -0.021308 5.201917 -10.435867
v  0.592878 6.741534 -10.435867
v  -0.021309 6.741534 -10.435867
v  -0.718700 6.741529 -10.665646
v  1.290269 6.741529 -10.665646
v  1.290269 6.741529 -11.565374
v  -0.718700 6.741529 -11.565374
v  5.142141 2.364102 -20.853558
v  5.142141 1.538953 -20.853556
v  5.142141 0.713805 -20.853556
v  1.080856 6.012493 -20.855089
v  -0.509287 6.012493 -20.855089
v  4.859758 1.521725 -23.964600
v  -0.821077 7.791223 -12.251019
v  1.080856 7.791223 -11.929493
v  -0.509287 7.791223 -10.332516
v  -0.509287 7.791223 -11.929493
v  -0.509287 7.791223 -12.251019
v  1.080856 7.791223 -12.251019
v  4.227235 1.409304 -13.466944
v  4.227235 1.396097 -16.104773
v  1.392646 7.791223 -12.251019
v  -0.509287 7.791223 -10.654043
v  1.080856 7.791223 -10.332516
v  4.227100 2.247799 -17.979355
v  1.080856 7.791223 -10.654043
v  4.538786 0.544394 -17.979355
v  4.227370 0.544394 -17.979355
v  1.392646 7.791223 -10.654043
v  4.538786 0.557602 -11.211310
v  4.227370 0.544394 -16.104773
v  4.227474 0.557602 -11.211310
v  4.227370 0.544394 -18.360977
v  -0.821077 7.791223 -10.332516
v  1.393432 2.237297 -10.332685
v  4.538786 2.247799 -18.360979
v  1.366805 6.029720 -20.499306
v  4.288098 2.254524 -11.236124
v  -0.509287 6.012493 -20.473465
v  1.395309 5.141178 -20.558426
v  5.142141 0.713805 -20.471931
v  5.227485 0.713805 -20.558424
v  1.080856 6.012493 -20.473465
v  -0.823741 5.141178 -20.558426
v  -4.881885 4.852755 -20.853558
v  -4.570572 3.189250 -20.853558
v  5.142141 3.189250 -20.853558
v  -3.655667 1.409304 -13.848568
v  5.142141 4.014400 -20.853558
v  -4.570572 4.014400 -20.853558
v  -4.569540 1.538952 -23.752413
v  -4.570572 1.538953 -20.853556
v  -4.570572 2.364102 -20.853558
v  -4.569540 0.713804 -23.752413
v  -4.274292 4.014400 -20.558426
v  5.141108 4.852755 -23.752415
v  4.845861 4.852756 -20.558426
v  5.141108 4.014399 -23.752415
v  5.141108 2.364101 -23.752413
v  -4.274292 4.852756 -20.558426
v  5.141108 3.189250 -23.752413
v  -4.569540 3.189250 -23.752413
v  3.931360 4.861138 -20.577087
v  -4.569540 2.364101 -23.752413
v  -4.569540 4.852755 -23.752415
v  -4.569540 4.014399 -23.752415
v  -3.359791 4.861138 -20.577087
v  4.845861 4.014400 -20.558426
v  4.226996 2.916743 -16.104773
v  -4.288190 0.696577 -23.964600
v  -4.570572 4.852755 -20.853558
v  5.142141 4.852755 -20.853558
v  -3.359791 3.194840 -20.576927
v  -4.274292 1.538953 -20.558424
v  4.845861 3.189251 -20.558426
v  4.845861 2.364102 -20.558426
v  3.931360 3.194840 -20.576927
v  -3.359791 4.021386 -20.577007
v  -4.274292 2.364102 -20.558426
v  -4.274292 3.189251 -20.558426
v  3.931360 4.021386 -20.577007
v  -4.570572 0.713805 -20.853556
v  -4.288190 2.346874 -23.964600
v  4.859758 2.346874 -23.964600
v  4.227100 2.247799 -18.360979
v  -3.655532 2.247800 -15.723149
v  -3.655667 1.396097 -15.723149
v  -3.359626 0.557602 -10.986490
v  -3.655667 1.396096 -18.360977
v  -3.655532 2.247799 -18.360979
v  -3.655906 0.557602 -11.211310
v  -3.655802 0.544394 -18.360977
v  -3.655802 0.544394 -15.723148
v  -3.953437 0.527167 -16.090992
v  3.931194 2.261008 -11.297802
v  2.392639 4.861141 -13.848412
v  3.931194 2.916744 -10.986491
v  -3.967218 2.261008 -11.592935
v  -3.741250 2.916744 -11.297803
v  2.165553 2.381503 -10.982676
v  -3.359626 1.409305 -10.986490
v  -1.593984 4.861141 -10.982677
v  1.783929 2.381503 -11.294228
v  -3.655906 0.557602 -11.592934
v  -0.821863 2.237297 -10.332685
v  -3.655667 1.409304 -13.466944
v  4.312818 2.916744 -11.297803
v  -3.655428 2.916743 -17.979355
v  2.392639 4.861141 -11.589935
v  1.393432 3.317295 -10.332685
v  -1.212361 3.782499 -11.294362
v  -3.655428 2.916744 -13.848568
v  4.226996 2.916743 -15.723149
v  -3.655906 1.409305 -11.211310
v  -3.967218 2.916744 -13.848568
v  -3.655906 1.409305 -11.592935
v  1.783929 3.782499 -11.294362
v  4.227474 2.261008 -11.211310
v  -3.967218 1.409305 -11.592935
v  -3.655532 2.261008 -13.466944
v  4.227474 2.916744 -11.592935
v  -1.593984 2.381503 -10.982676
v  -3.655428 2.916743 -15.723149
v  2.165553 3.782499 -11.294362
v  -3.967218 1.396096 -17.979355
v  -0.821863 3.317295 -10.332685
v  2.165553 3.782499 -10.982676
v  -1.593984 2.381503 -11.294228
v  4.227474 2.261008 -11.592935
v  -3.655906 2.261008 -11.211310
v  -3.655906 2.916744 -11.592935
v  4.226996 2.916744 -13.466944
v  -3.655906 2.261008 -11.592935
v  -3.359626 2.261008 -11.297802
v  -3.967218 2.916743 -15.723149
v  4.227474 2.916744 -11.211311
v  -3.967218 2.916744 -11.592935
v  -3.655802 0.544394 -13.848567
v  3.931194 2.916744 -11.297803
v  4.312818 2.261008 -10.986490
v  -1.821071 4.861141 -11.589935
v  2.165553 2.381503 -11.294228
v  -3.359626 1.409305 -11.297802
v  -3.741250 2.261008 -11.297802
v  1.393432 4.397297 -10.332685
v  -0.821863 1.157296 -10.332685
v  4.226996 2.916744 -13.848568
v  -1.509281 4.861139 -15.722993
v  -3.359626 2.916744 -11.297803
v  4.312818 2.261008 -11.297802
v  2.080850 4.861139 -15.722993
v  -3.741250 2.261008 -10.986490
v  -3.655802 0.544395 -13.466944
v  -0.821863 4.397297 -10.332685
v  2.165553 4.861141 -10.982677
v  -3.655428 2.916743 -16.104773
v  -1.509281 4.861139 -16.104616
v  -3.655532 2.247800 -16.104773
v  4.227100 2.247800 -16.104773
v  -3.741250 2.916744 -10.986491
v  -3.655667 1.396096 -17.979355
v  -3.741250 1.409305 -11.297802
v  -1.821071 4.861141 -13.466788
v  -1.212361 2.381503 -11.294228
v  4.226996 2.916743 -17.979355
v  -3.655667 1.396097 -16.104773
v  -3.655906 2.916744 -11.211311
v  -1.212361 0.980508 -11.294092
v  -3.655428 2.916744 -13.466944
v  -3.655532 2.247799 -17.979355
v  -1.593984 3.782499 -11.294362
v  -1.593984 0.980508 -11.294092
v  -3.967218 0.544395 -13.466944
v  -3.655802 0.544394 -16.104773
v  -3.655532 2.261007 -13.848568
v  -3.655802 0.544394 -17.979355
v  -3.967218 2.247799 -18.360979
v  -3.655428 2.916742 -18.360979
v  4.227100 2.261007 -13.848568
v  4.226996 2.916742 -18.360979
v  -3.373407 0.574829 -11.284021
v  -1.523062 4.878367 -13.834630
v  2.094631 4.878367 -13.834630
v  -1.523062 4.861139 -18.347040
v  2.094631 4.861139 -18.347040
v  5.427613 2.346875 -20.497772
v  5.227485 3.189251 -20.247112
v  5.201644 2.346875 -20.272953
v  -3.727468 0.557602 -11.284021
v  -3.716530 2.254524 -11.236124
v  -4.583321 3.172023 -23.384571
v  5.154889 3.172023 -23.384571
v  -4.583321 4.869981 -23.384571
v  5.154889 3.997172 -23.384571
v  5.154889 2.346874 -23.384571
v  5.154889 4.869981 -23.384571
v  -4.583321 3.997172 -23.384571
v  -4.583321 2.346874 -23.384571
v  -4.583321 0.696577 -23.384571
v  -4.583321 1.521726 -23.384571
v  -4.570572 4.852756 -20.471933
v  -4.274292 4.014400 -20.247112
v  5.142141 4.014400 -20.471933
v  -3.359791 4.861138 -20.265297
v  -4.655916 0.713805 -20.558424
v  -4.570572 4.014400 -20.471933
v  5.142141 4.852756 -20.471933
v  5.227602 4.014399 -23.978382
v  4.845861 4.014400 -20.247112
v  -4.570572 0.713805 -20.471931
v  -4.570572 2.364102 -20.471933
v  -4.570572 1.538953 -20.471931
v  5.142141 2.364102 -20.471933
v  -4.570572 3.189251 -20.471933
v  5.142141 3.189251 -20.471933
v  4.227235 1.409304 -13.848568
v  5.141108 1.538952 -23.752413
v  5.227485 0.713805 -20.247112
v  5.141108 0.713803 -23.752413
v  4.859758 0.696577 -23.964600
v  4.845861 1.538953 -20.558424
v  1.401915 6.029719 -23.456993
v  -0.830346 6.029719 -23.456993
v  4.538786 1.409304 -13.848567
v  4.227370 0.544394 -13.466944
v  4.227474 1.409305 -11.592935
v  4.227235 1.396096 -17.979355
v  2.165553 0.980508 -11.294092
v  1.393432 1.157296 -10.332685
v  4.227235 1.396096 -18.360977
v  4.227474 0.557602 -11.592934
v  3.931194 1.409305 -11.297802
v  4.227370 0.544394 -13.848567
v  4.312818 1.409305 -11.297802
v  4.312818 1.409305 -10.986490
v  1.783929 0.980508 -11.294092
v  4.227100 2.261008 -13.466944
v  4.227474 1.409305 -11.211310
v  4.525005 2.278234 -13.480725
v  3.944975 0.574829 -11.284021
v  4.299037 0.574829 -11.284021
v  5.154889 0.696577 -23.384571
v  5.154889 1.521726 -23.384571
v  1.344518 5.158404 -23.389824
v  -0.772949 5.158404 -23.389824
v  -0.476284 6.029719 -23.456993
v  1.047853 6.029719 -23.456993
v  4.538786 0.544394 -15.723149
v  -1.070480 5.141178 -23.757664
v  -0.845658 5.141178 -23.983631
v  1.417227 5.141178 -23.983631
v  1.049386 5.158404 -23.686104
v  -0.477817 5.158404 -23.686104
v  5.142141 1.538953 -20.471931
v  -0.476284 6.012492 -23.741220
v  -0.819818 5.158404 -23.698162
v  1.391386 5.158404 -23.698162
v  0.194281 6.344528 -10.375868
v  -0.796023 7.281226 -12.226389
v  0.377288 6.344528 -10.443367
v  4.270782 1.669131 -19.207216
v  -3.699213 1.669131 -19.207216
v  1.377928 7.246772 -12.236726
v  4.270782 1.669131 -12.271986
v  -3.699213 1.669131 -12.271986
v  4.270782 1.669131 -14.593281
v  -3.699213 1.669131 -14.593281
v  4.270782 1.669131 -16.853718
v  -3.699213 1.669131 -16.853718
v  1.377919 2.205714 -11.233238
v  -0.806350 2.205714 -11.233238
v  -4.613664 2.272345 -22.549076
v  0.056562 0.970302 -10.464714
v  4.313931 2.220070 -11.210209
v  -3.742362 2.220070 -11.210209
v  5.169130 2.395685 -20.826567
v  5.169130 0.745387 -20.826567
v  5.169130 1.570536 -20.826567
v  4.523283 2.265027 -15.738653
v  5.200496 0.745387 -20.531435
v  5.437950 0.731031 -20.838051
v  4.254224 1.377722 -13.493933
v  4.285829 0.526019 -11.270813
v  4.511797 0.526019 -11.238299
v  1.810918 0.948926 -11.009665
v  1.366443 2.205714 -10.359674
v  4.511797 1.427679 -16.077784
v  4.254463 1.377722 -11.238299
v  4.254463 1.377722 -11.565946
v  2.138564 0.948926 -11.267103
v  1.366443 1.125713 -10.359674
v  4.254359 0.575977 -18.333988
v  1.810918 0.948926 -11.267103
v  4.254224 1.427679 -18.333988
v  4.285829 1.377722 -11.270814
v  4.285829 1.377722 -11.013479
v  3.958183 0.526019 -11.013479
v  4.254359 0.575977 -18.006342
v  4.511797 0.575977 -18.006342
v  4.254089 2.279382 -18.006344
v  4.254359 0.575977 -16.077784
v  4.511797 2.279382 -18.006344
v  3.958183 1.377722 -11.270814
v  1.377928 5.466500 -10.348189
v  3.958183 0.526019 -11.270814
v  4.227235 1.392078 -13.848568
v  5.212098 1.556179 -23.682573
v  4.227474 0.540375 -11.211310
v  5.200496 4.821173 -20.531435
v  5.200496 2.395685 -20.531435
v  5.200496 4.045982 -20.531435
v  5.200496 1.570536 -20.531433
v  5.200496 3.220833 -20.531435
v  1.062593 5.109594 -23.699310
v  -1.043491 5.109594 -23.730675
v  -0.491025 5.109594 -23.699310
v  5.169130 0.745387 -20.498920
v  1.401723 5.123950 -23.968128
v  -4.301281 1.570536 -20.531433
v  -3.386780 3.226422 -20.549938
v  4.872850 3.220834 -20.531435
v  -4.301281 3.220834 -20.531435
v  4.872850 2.395685 -20.531435
v  -4.301281 2.395685 -20.531435
v  4.872850 1.570536 -20.531433
v  3.958349 4.052968 -20.550018
v  3.958349 3.226422 -20.549938
v  -3.386780 4.052968 -20.550018
v  -4.597561 4.821172 -20.826567
v  5.169130 4.821172 -20.826567
v  4.872850 4.821173 -20.531435
v  5.168097 2.395684 -23.725424
v  5.168097 4.045981 -23.725426
v  -4.301281 4.821173 -20.531435
v  5.168097 1.570535 -23.725424
v  -4.596529 2.395684 -23.725424
v  4.253985 2.885160 -16.077784
v  5.168097 3.220832 -23.725424
v  3.958349 4.829556 -20.550098
v  -4.596529 3.220832 -23.725424
v  -4.596529 4.821172 -23.725426
v  -3.386780 4.829556 -20.550098
v  5.168097 4.821172 -23.725426
v  4.872850 4.045982 -20.531435
v  -4.301281 4.045982 -20.531435
v  -4.596529 4.045981 -23.725426
v  -4.596529 0.745386 -23.725424
v  -4.597561 0.745387 -20.826567
v  -4.596529 1.570535 -23.725424
v  -4.597561 2.395685 -20.826567
v  -4.597561 1.570536 -20.826567
v  5.169130 4.045982 -20.826567
v  -4.597561 4.045982 -20.826567
v  -4.597561 3.220833 -20.826567
v  5.169130 3.220833 -20.826567
v  4.861365 0.731031 -20.542919
v  4.242874 0.561621 -15.738653
v  5.200613 3.220832 -23.951393
v  5.212098 4.835527 -23.962879
v  -4.640412 2.395685 -20.542921
v  -4.640412 1.570536 -20.542919
v  -4.640412 4.835528 -20.542921
v  4.523283 2.899516 -16.089270
v  4.297480 4.843912 -20.561583
v  -4.640412 4.031627 -20.542921
v  -3.725912 4.843912 -20.561583
v  -4.640412 3.220833 -20.542921
v  4.242604 2.279382 -15.738653
v  -3.725912 3.212066 -20.561424
v  4.297480 4.038612 -20.561504
v  4.297480 3.212066 -20.561424
v  -3.725912 4.038612 -20.561504
v  2.080850 4.843912 -18.360821
v  2.080849 4.843914 -13.848412
v  -1.509281 4.843912 -18.360821
v  -1.509281 4.843914 -13.848412
v  -4.289913 2.395684 -23.682571
v  4.861481 2.395684 -23.682571
v  -4.289913 1.570535 -23.682571
v  4.861481 4.045981 -23.682573
v  4.861481 1.570535 -23.682571
v  4.861481 3.220832 -23.682571
v  -4.289913 4.045981 -23.682573
v  -4.289913 0.745386 -23.682571
v  -4.289913 3.220832 -23.682571
v  4.861481 4.835527 -23.682573
v  -4.289913 4.835527 -23.682573
v  -3.682656 1.377722 -13.493933
v  -3.714261 0.526019 -11.270813
v  4.254089 2.229425 -13.821579
v  -3.682417 2.885160 -18.333990
v  4.253985 2.885160 -18.333990
v  -3.682521 2.229425 -13.821579
v  -1.566996 0.948926 -11.267103
v  -3.682791 0.575977 -18.006342
v  -3.682791 0.575977 -16.077784
v  4.523283 2.899517 -13.833064
v  -3.682895 0.526019 -11.565945
v  -0.794875 2.205714 -10.359674
v  4.285829 2.885162 -11.270814
v  -3.682521 2.229425 -13.493933
v  -3.682791 0.575977 -13.493933
v  -3.682417 2.885160 -18.006344
v  -3.682417 2.885161 -13.493933
v  -1.239350 0.948926 -11.267103
v  1.366443 3.285712 -10.359674
v  4.253985 2.885160 -15.750138
v  -3.386614 2.885162 -11.270814
v  -1.566996 0.948926 -11.009665
v  -3.682895 1.377723 -11.565946
v  4.285829 2.229426 -11.013479
v  1.810918 3.750916 -11.267373
v  2.365651 4.829558 -11.562946
v  -1.239350 2.349921 -11.267239
v  -1.794082 4.829558 -13.493776
v  4.254463 2.885162 -11.238300
v  -3.682417 2.885160 -16.077784
v  -3.682656 1.427679 -16.077784
v  2.138564 3.750916 -11.267373
v  4.254463 2.885162 -11.565946
v  -3.682417 2.885160 -15.750138
v  -0.794875 3.285712 -10.359674
v  2.138564 3.750916 -11.009665
v  -3.386614 1.377723 -11.013479
v  -1.566996 3.750916 -11.267373
v  -1.566996 2.349921 -11.267239
v  -3.682895 2.885162 -11.238300
v  4.253985 2.885160 -18.006344
v  -3.682895 2.229426 -11.238299
v  4.254463 2.229426 -11.565946
v  4.511797 2.229426 -11.238299
v  -3.682895 2.885162 -11.565946
v  -3.682895 2.229426 -11.565946
v  -1.239350 3.750916 -11.267373
v  4.253985 2.885161 -13.493933
v  -3.386614 2.229426 -11.270814
v  -1.566996 2.349921 -11.009665
v  1.810918 2.349921 -11.267239
v  4.254463 2.229426 -11.238299
v  -3.682521 2.279382 -18.006344
v  -1.794082 4.829557 -15.749982
v  2.138564 2.349921 -11.267239
v  2.365651 4.829558 -13.821423
v  -3.682791 0.575977 -13.821578
v  -1.794082 4.829558 -11.562946
v  -3.714261 2.229426 -11.270814
v  -3.386614 1.377723 -11.270814
v  3.958183 2.885162 -11.013479
v  1.366443 4.365715 -10.359674
v  -3.682895 1.377723 -11.238299
v  -3.940229 2.885160 -15.750138
v  3.958183 2.229426 -11.270814
v  3.958183 2.885162 -11.270814
v  2.138564 2.349921 -11.009665
v  -0.794875 1.125713 -10.359674
v  4.285829 2.229426 -11.270814
v  -3.714261 2.885162 -11.270814
v  -0.794875 4.365715 -10.359674
v  -1.536270 4.829557 -16.077627
v  -3.682521 2.279382 -16.077784
v  4.254089 2.279382 -16.077784
v  -3.682656 1.427679 -18.006342
v  -3.940229 1.427679 -18.006342
v  -3.714261 2.229426 -11.013479
v  -3.714261 1.377723 -11.270814
v  -3.951714 0.561621 -13.833063
v  -3.940229 0.575977 -18.006342
v  -3.682791 0.575977 -18.333988
v  -3.682791 0.575977 -15.750137
v  -3.682656 1.427679 -18.333988
v  -3.682521 2.279382 -18.333990
v  2.080849 4.843914 -13.466788
v  -1.509281 4.843914 -13.466788
v  -3.682656 1.427679 -15.750138
v  -3.682521 2.279382 -15.750138
v  4.254089 2.279382 -18.333990
v  -3.386614 0.526019 -11.270814
v  2.080850 4.843913 -17.979198
v  -3.655667 1.392077 -13.848568
v  -1.509281 4.843913 -17.979198
v  -1.524785 4.843913 -15.738497
v  2.096354 4.843913 -15.738497
v  4.845977 1.556179 -23.978380
v  5.212098 3.206477 -23.682573
v  5.212098 4.031626 -23.682575
v  5.212098 4.821172 -23.682575
v  5.212098 2.395683 -23.682573
v  -3.655906 0.540375 -11.211310
v  4.226996 2.899517 -13.848568
v  -3.655428 2.899517 -13.848568
v  5.200496 0.745387 -20.274101
v  -4.640529 4.835527 -23.962879
v  5.169130 1.570536 -20.498920
v  -4.597561 1.570536 -20.498920
v  5.169130 3.220834 -20.498920
v  -4.597561 2.395685 -20.498920
v  -4.597561 3.220834 -20.498920
v  5.169130 2.395685 -20.498920
v  -4.597561 0.745387 -20.498920
v  5.169130 4.045982 -20.498920
v  -4.597561 4.045982 -20.498920
v  4.872850 4.045982 -20.274101
v  -4.628927 0.745387 -20.531435
v  -4.597561 4.821173 -20.498920
v  5.169130 4.821173 -20.498920
v  -4.301281 4.045982 -20.274101
v  -3.375295 4.843912 -20.280800
v  5.211981 3.206478 -20.262617
v  5.211981 4.835528 -20.262617
v  5.211981 2.395684 -20.262617
v  4.861365 1.556180 -20.262615
v  -4.640529 4.031626 -23.682575
v  -4.640529 0.731030 -23.682573
v  -4.640529 3.206477 -23.682573
v  -4.640529 1.556179 -23.682573
v  -4.640529 4.821172 -23.682575
v  -4.640529 2.381328 -23.682573
v  1.107844 5.980911 -20.828100
v  1.336955 5.109596 -20.826567
v  1.040674 5.109596 -20.531435
v  -0.536276 5.980911 -20.828100
v  -0.765386 5.109596 -20.826567
v  5.168097 0.745386 -23.725424
v  -0.469106 5.109596 -20.531435
v  2.096354 4.843913 -16.089113
v  -4.640412 4.835528 -20.262617
v  -4.289796 0.731031 -20.542919
v  4.242739 1.413324 -15.738653
v  -0.808237 5.123951 -20.542921
v  1.379805 5.123951 -20.542921
v  -0.805573 5.995266 -20.839586
v  -1.034205 5.123951 -20.838053
v  4.861481 0.745386 -23.682571
v  -0.536276 7.759641 -11.956482
v  1.107844 7.759641 -11.956482
v  -0.794088 7.759641 -10.359505
v  -0.536276 7.759641 -10.359505
v  -0.536276 7.759641 -10.627054
v  1.107844 7.759641 -12.224030
v  4.254224 1.427679 -18.006342
v  4.511797 0.575977 -13.493933
v  4.254224 1.427679 -16.077784
v  1.365657 7.759641 -12.224030
v  4.254359 0.575977 -13.493933
v  4.254359 0.575977 -13.821578
v  -0.536276 7.759641 -12.224030
v  1.107844 7.759641 -10.359505
v  4.254463 0.526019 -11.565945
v  4.511797 1.427679 -18.333988
v  1.107844 7.759641 -10.627054
v  -0.794088 7.759641 -12.224030
v  4.254089 2.229425 -13.493933
v  4.523283 2.243781 -13.482448
v  4.845977 2.381328 -23.978380
v  -4.274409 2.381328 -23.978380
v  5.212098 0.731031 -23.682573
v  -0.469106 5.109596 -20.274101
v  -0.536276 5.980911 -20.500454
v  -0.765386 5.109596 -20.498920
v  1.336955 5.109596 -20.498920
v  1.107844 5.980911 -20.500454
v  1.605774 5.109596 -20.487438
v  1.379805 5.123951 -20.262617
v  1.350276 6.938631 -12.209074
v  4.845861 2.381329 -20.247112
v  -1.212361 0.980508 -10.982676
v  5.125232 3.627547 -23.140072
v  -4.553664 3.627547 -23.140072
v  1.379651 7.263999 -12.238448
v  -0.783507 5.149858 -21.201090
v  -4.613664 3.627487 -21.584177
v  5.185232 3.627487 -21.584177
v  -0.794875 5.435492 -12.225241
v  -4.553664 4.255248 -22.251411
v  5.125232 4.255248 -22.251411
v  1.393432 5.466500 -12.252230
v  -1.066743 2.018605 -11.260997
v  1.638312 2.018605 -11.260997
v  -0.778796 5.518755 -12.209163
v  2.392640 4.843913 -16.104616
v  1.378864 7.791223 -10.346298
v  1.034072 5.995265 -23.755001
v  2.149786 2.907812 -11.350576
v  -1.578218 2.907812 -11.350576
v  -0.794875 6.440536 -12.225241
v  -0.808072 2.254524 -11.231511
v  1.379641 2.254524 -11.231511
v  1.368371 5.395079 -23.100847
v  5.184535 3.154221 -23.935314
v  5.184535 2.329073 -23.935314
v  1.367592 6.523799 -12.226389
v  1.367592 7.299027 -12.226389
v  4.889044 0.678775 -23.935314
v  5.410387 0.678776 -20.514999
v  4.888928 3.154222 -20.290180
v  -4.612966 3.154221 -23.935314
v  -3.698183 0.592630 -11.029557
v  -1.778004 4.896167 -18.022264
v  2.349573 4.896168 -15.766060
v  4.495719 0.509366 -18.317909
v  4.495719 2.951773 -11.254378
v  -1.255428 2.416532 -11.025743
v  -4.317359 0.678776 -20.290180
v  4.269751 0.592630 -11.029557
v  1.826996 2.416532 -11.025743
v  -1.778004 4.896168 -15.766060
v  -1.255428 4.896169 -11.025743
v  -4.317359 4.887784 -20.290180
v  4.269751 2.951773 -11.029558
v  4.888928 1.503925 -20.290178
v  4.889044 3.979370 -23.935316
v  4.888928 2.329074 -20.290180
v  -3.924151 2.951773 -11.254378
v  -4.612966 1.503924 -23.935314
v  -3.924151 2.951772 -13.510012
v  -1.255428 3.817527 -11.025743
v  1.350365 7.021894 -10.375752
v  -0.778010 7.826251 -10.610976
v  -1.778004 4.896169 -13.805345
v  3.974427 4.896166 -20.308363
v  1.352242 5.176207 -20.290180
v  -0.507103 5.176206 -23.940567
v  -1.027413 5.176206 -23.419109
v  -1.006642 5.176207 -20.515001
v  1.350365 7.021894 -12.209163
v  1.078672 5.176206 -23.940567
v  1.598982 5.176206 -23.419109
v  -3.924151 1.444333 -11.254377
v  4.495719 0.509366 -13.805500
v  2.349572 4.896169 -11.546867
v  -1.255428 1.015536 -11.025743
v  1.578211 5.176207 -20.515001
v  -3.402693 2.296036 -11.029557
v  -0.778796 7.299027 -10.375752
v  4.495719 2.951771 -15.766216
v  -3.924151 0.592630 -11.549868
v  -4.612849 3.979371 -20.290180
v  4.495719 2.212772 -15.766216
v  -0.780674 5.176207 -20.290180
v  1.826996 4.896169 -11.025743
v  -0.778010 7.826251 -11.972560
v  3.974261 1.444333 -11.029557
v  4.889044 4.887783 -23.935316
v  4.495719 1.444333 -13.510011
v  -4.612966 2.329073 -23.935314
v  -4.838818 0.678776 -20.514999
v  -4.612849 1.503925 -20.290178
v  1.826996 3.817527 -11.025743
v  1.349579 7.826251 -10.375584
v  -3.924151 2.296036 -11.254377
v  -3.924151 2.296036 -13.805501
v  4.495719 2.296036 -13.805501
v  5.125232 3.921221 -21.190769
v  -4.553664 3.921221 -21.190767
v  -4.613664 3.920655 -22.746468
v  5.185232 3.920655 -22.746468
v  4.270782 1.854127 -14.564383
v  -2.981262 3.778092 -20.574615
v  3.552831 3.778092 -20.574615
v  -4.286670 3.778091 -20.574722
v  4.858238 3.778091 -20.574722
v  2.049898 4.024497 -14.527522
v  -1.478330 4.024497 -14.527523
v  2.049898 4.024497 -19.273289
v  -1.478330 4.024497 -12.192478
v  2.049898 4.024497 -12.192478
v  -3.812448 4.024497 -20.574722
v  4.384016 4.024497 -20.574722
v  -1.478330 4.024497 -19.273289
v  -1.478330 4.024497 -16.862568
v  2.049898 4.024497 -16.862568
v  -2.507040 4.024497 -20.574547
v  3.078608 4.024497 -20.574547
v  4.724650 4.032424 -20.514721
v  -4.153082 4.032424 -20.514721
v  -2.847683 4.032424 -20.514595
v  3.419251 4.032424 -20.514597
v  -4.553664 2.167555 -22.611176
v  5.125232 2.167555 -22.611177
v  5.125232 2.167555 -21.641457
v  -4.553664 1.951681 -21.595572
v  5.125232 1.841332 -21.607170
v  -4.553664 1.639717 -21.696936
v  -4.553664 1.492043 -22.391689
v  -0.798074 5.369642 -23.282553
v  -0.783132 5.331444 -21.147573
v  1.366443 5.435492 -12.225241
v  -4.612760 4.062634 -20.290268
v  -4.838728 0.762040 -20.515087
v  3.974350 2.212773 -11.029646
v  1.827085 2.333268 -11.025832
v  -4.838728 2.412337 -20.810400
v  -4.317449 2.412337 -20.290268
v  -1.255517 2.333268 -11.025832
v  4.889017 3.237486 -20.290268
v  4.495630 2.868508 -15.766306
v  -4.838728 3.237486 -20.515089
v  1.077228 5.964257 -23.711845
v  -3.924062 0.592630 -13.805411
v  5.410297 1.587188 -20.810398
v  4.269828 4.069621 -20.308453
v  -3.698259 4.069621 -20.308453
v  -1.027324 5.092942 -23.419197
v  -4.612877 4.062634 -23.935226
v  1.349489 5.964258 -20.811934
v  -0.507192 5.092942 -23.940477
v  -3.924062 2.868509 -13.805412
v  -1.255517 4.812906 -11.025833
v  -1.550828 3.734263 -11.025832
v  3.974350 1.361070 -11.029646
v  4.495630 1.444332 -18.022511
v  5.184329 4.062634 -20.290268
v  5.184445 3.237485 -23.935225
v  3.974516 3.243075 -20.308453
v  2.349483 4.812905 -13.509944
v  4.889017 1.587188 -20.290268
v  4.495630 2.868509 -13.510100
v  -0.777921 5.964258 -20.516621
v  5.184445 4.062634 -23.935226
v  4.889017 2.412337 -20.290268
v  -3.924062 2.868508 -15.766306
v  -3.924062 1.361069 -13.510099
v  1.827085 4.812906 -11.025833
v  -0.780585 5.092943 -20.290268
v  4.495630 1.361070 -11.254467
v  -3.402781 2.868509 -11.029647
v  -3.402781 2.212773 -11.029646
v  -3.924062 1.361070 -11.254467
v  4.495630 0.592630 -13.805411
v  -1.777915 4.812906 -11.251467
v  5.410297 0.762040 -20.515087
v  4.495630 2.296035 -18.022511
v  -0.778707 7.215764 -12.209074
v  -0.778707 5.435492 -10.375841
v  -0.800972 5.964257 -23.711845
v  -4.612877 3.237485 -23.935225
v  4.495630 2.212773 -11.254467
v  -4.317449 0.762040 -20.290268
v  1.349489 7.742988 -11.972649
v  -1.777915 4.812905 -13.805256
v  1.578122 5.092943 -20.810400
v  -4.612877 2.412336 -23.935225
v  -3.924062 0.509367 -11.549778
v  1.377928 2.220070 -11.253489
v  -0.806360 2.220070 -11.253489
v  1.367592 5.518755 -12.226389
v  5.185232 1.492827 -22.391237
v  -0.821849 2.220070 -11.217690
v  1.393418 2.220070 -11.217690
v  -1.593984 3.765272 -10.982676
v  -4.274292 2.381329 -20.247112
v  4.538786 2.899518 -11.592935
v  1.392646 5.995266 -20.855089
v  1.377928 6.969639 -12.236726
v  4.538786 2.899516 -15.723149
v  -4.274292 0.731031 -20.247112
v  4.845861 3.206478 -20.247112
v  4.538786 1.392078 -11.211310
v  -1.821071 4.843914 -13.848412
v  4.845861 4.835528 -20.247112
v  1.783929 4.843914 -10.982677
v  -1.821071 4.843914 -11.208311
v  -3.359626 2.243781 -10.986490
v  -3.967218 0.540375 -11.592934
v  -3.967218 0.561621 -13.466944
v  1.783929 2.364277 -10.982676
v  -3.967218 1.392078 -11.211310
v  3.931194 2.243781 -10.986490
v  4.538786 2.899518 -11.211311
v  4.538786 2.899517 -13.466944
v  3.931194 1.392078 -10.986490
v  -3.359626 2.899518 -10.986491
v  -1.049709 5.123951 -20.471933
v  -1.212361 3.765272 -10.982676
v  -3.967218 2.243781 -11.211310
v  5.227485 1.556180 -20.247112
v  4.538786 1.413323 -17.979355
v  -0.823741 5.123951 -20.247112
v  -4.655916 2.381329 -20.247112
v  4.538786 2.265026 -18.360979
v  -0.821863 7.246772 -10.332685
v  4.538786 2.265027 -16.104773
v  -3.741416 4.843912 -20.265297
v  -3.967218 2.899516 -16.104773
v  -1.212361 4.843914 -10.982677
v  -0.821077 7.773996 -10.654043
v  -4.655916 4.031627 -20.247112
v  -4.274292 4.835528 -20.247112
v  -3.741250 1.392078 -10.986490
v  1.392646 7.773996 -10.654043
v  1.783929 3.765272 -10.982676
v  1.013686 5.123951 -20.247112
v  4.538786 0.561621 -13.848567
v  2.392639 4.843914 -11.208311
v  4.538786 0.561621 -18.360977
v  4.312818 0.540375 -10.986490
v  2.165553 0.963282 -10.982676
v  -3.967218 1.413323 -18.360977
v  -3.967218 2.899517 -13.466944
v  4.538786 2.243781 -11.592935
v  2.392640 4.843913 -15.722993
v  -0.821077 5.995266 -20.473465
v  -1.212361 2.364277 -10.982676
v  1.642049 5.123950 -23.757664
v  4.312818 2.899518 -10.986491
v  4.538786 2.243781 -13.848568
v  2.392639 4.843914 -13.466788
v  -3.967218 2.899518 -11.211311
v  -4.881885 0.731031 -20.853556
v  1.392646 5.995266 -20.473465
v  5.453454 0.731031 -20.471931
v  5.227485 4.031627 -20.247112
v  -1.821071 4.843913 -16.104616
v  3.931360 4.843912 -20.265297
v  -4.289913 4.835527 -23.962879
v  4.861481 4.835527 -23.962879
v  1.051108 5.123950 -23.968128
v  1.415696 5.995265 -23.755001
v  -4.656033 2.381328 -23.978380
v  -0.844128 5.995265 -23.755001
v  5.227602 1.556179 -23.978380
v  5.227602 2.381328 -23.978380
v  4.210782 1.622159 -12.058631
v  -3.639213 1.622159 -12.058631
v  1.312631 1.836413 -24.261942
v  -0.741063 1.836413 -24.261942
v  -3.639213 1.622159 -17.097559
v  4.210782 1.622159 -17.097559
v  -3.639213 1.622159 -19.451057
v  -3.639213 1.622159 -18.993862
v  -3.639213 1.622159 -14.379927
v  -3.639213 1.622159 -14.837122
v  -3.639213 1.622159 -16.640364
v  4.210782 1.622159 -12.515827
v  4.210782 1.622159 -19.451059
v  4.210782 1.622159 -14.379927
v  -3.639213 1.622159 -12.515827
v  4.210782 1.622159 -14.837122
v  4.210782 1.622159 -16.640364
v  -2.761669 1.971541 -11.313516
v  -4.553664 1.836413 -22.892532
v  4.210782 1.622159 -18.993862
v  3.333237 1.971541 -11.313516
v  5.125232 1.836413 -22.892532
v  0.285777 7.263999 -10.332685
v  0.285777 6.488770 -10.332685
v  0.285791 7.263999 -10.332685
v  0.285791 6.488770 -10.332685
v  0.285777 4.397297 -10.332685
v  0.285791 3.317295 -10.332685
v  0.285777 3.317295 -10.332685
v  0.285777 1.157296 -10.332685
v  0.285791 4.397297 -10.332685
v  0.285777 6.986866 -10.332685
v  0.285791 5.483727 -10.332685
v  0.285777 5.483727 -10.332685
v  0.285791 1.157296 -10.332685
v  0.285791 6.986866 -10.332685
v  -4.613664 1.835847 -21.068338
v  4.459042 1.621593 -20.514721
v  -3.887473 1.621593 -20.514721
v  5.185232 1.835847 -21.068338
v  1.061061 5.980909 -23.728012
v  4.872850 0.745387 -20.274101
v  4.872966 3.220832 -23.951393
v  -1.043491 5.109594 -23.403030
v  4.511797 1.427679 -15.750138
v  -3.940229 0.575977 -18.333988
v  4.511797 2.885162 -11.565946
v  -1.566996 3.750916 -11.009665
v  -1.239350 0.948926 -11.009665
v  1.365657 5.980911 -20.828100
v  1.594289 5.109596 -20.826567
v  2.365651 4.829557 -16.077629
v  4.511797 0.526019 -11.565945
v  1.365657 7.759641 -11.956482
v  1.365657 7.759641 -10.359505
v  -0.794088 7.759641 -11.956482
v  4.872850 2.395684 -20.274101
v  -4.301281 2.395684 -20.274101
v  -3.639213 1.893980 -19.171352
v  0.285791 2.254524 -10.358526
v  2.124005 4.878368 -11.234152
v  0.285777 2.254524 -10.358526
v  1.809770 4.878368 -11.251311
v  -1.552437 4.878368 -11.234152
v  -1.238201 4.878368 -11.251311
v  -1.555652 4.328736 -20.514265
v  2.127221 4.328736 -20.514265
v  -0.808082 5.483727 -12.238448
v  -3.639213 2.037458 -19.368834
v  -3.639213 1.682583 -19.293402
v  4.210782 2.014020 -14.656509
v  -3.639213 2.029529 -12.482424
v  -3.639213 2.014020 -14.656508
v  4.210782 2.029530 -14.804074
v  4.210782 2.014019 -12.334858
v  4.210782 2.029529 -12.482424
v  -3.639213 1.963269 -19.497332
v  4.210782 2.014020 -19.440968
v  -3.639213 1.748844 -17.160799
v  -3.639213 1.963269 -14.895275
v  -3.639213 2.014019 -12.334858
v  -3.639213 2.014020 -16.922033
v  4.210782 1.748845 -19.497332
v  4.210782 1.748845 -17.160799
v  4.210782 1.674655 -19.368834
v  4.210782 2.014020 -16.922033
v  4.210782 1.893980 -19.528181
v  -3.639213 1.674654 -17.032301
v  4.210782 1.893980 -17.191648
v  -3.639213 1.893980 -17.191648
v  0.861747 0.548664 -10.464714
v  -3.699213 1.893792 -19.527296
v  -3.699213 2.013237 -19.440514
v  4.270782 1.698876 -19.440514
v  4.270782 1.818321 -17.190763
v  -3.699213 1.749376 -19.496601
v  -3.699213 1.698876 -17.103981
v  4.270782 2.013237 -14.838457
v  -3.699213 1.818322 -17.190763
v  -3.699213 1.698876 -14.838457
v  4.270782 1.698876 -14.838457
v  -3.699213 2.013236 -14.838457
v  4.270782 1.929878 -16.847429
v  -3.699213 2.013237 -17.103981
v  4.270782 1.698875 -12.516806
v  -3.699213 1.929877 -14.581905
v  3.569426 2.988998 -11.290576
v  -2.997858 2.988998 -11.290576
v  1.350269 2.042932 -10.786528
v  -0.778700 2.042932 -10.786528
v  1.350276 6.440536 -12.209074
v  1.354701 5.331444 -21.147573
v  0.364312 6.920105 -10.443367
v  0.428500 6.795747 -10.375868
v  -0.778700 5.676417 -11.257868
v  1.350269 5.676417 -11.257868
v  1.377928 6.471544 -12.236726
v  -0.806360 6.969639 -12.236726
v  1.353733 5.450737 -21.009272
v  1.350269 5.272398 -10.786739
v  -0.778700 5.272398 -10.786739
v  -0.778700 4.192159 -10.786739
v  1.350269 4.192159 -10.786739
v  -3.639213 1.818133 -16.834820
v  4.210782 1.818133 -16.834820
v  4.210782 1.818133 -14.569295
v  -3.639213 1.818133 -14.569295
v  4.210782 1.818132 -12.247644
v  -3.639213 1.818132 -12.247644
v  4.210782 1.818133 -19.171352
v  -2.591379 3.103710 -11.290576
v  3.162948 3.103710 -11.290576
v  3.371414 3.070351 -11.290576
v  -2.799846 3.070351 -11.290576
v  2.954506 3.182689 -11.290576
v  -2.382938 3.182689 -11.290576
v  0.895607 0.371893 -10.375868
v  -0.324038 0.371893 -10.375868
v  -0.844128 6.012492 -23.755001
v  1.393432 6.488770 -12.252230
v  1.415696 6.012492 -23.755001
v  -0.808082 6.986866 -10.346466
v  -0.808082 5.483727 -10.346466
v  4.525005 1.409305 -11.225092
v  -3.953437 2.261008 -11.225092
v  4.299037 0.557602 -11.000272
v  -3.953437 0.544394 -13.834785
v  4.859642 3.189251 -20.260895
v  4.525005 2.916743 -15.736931
v  -3.373407 2.916744 -11.000273
v  5.213704 4.014400 -20.260895
v  1.797711 2.381503 -10.996457
v  -3.953437 2.247799 -17.993135
v  -4.868104 0.713805 -20.839775
v  -3.953437 1.409305 -11.225092
v  4.525005 0.544394 -18.347197
v  -3.953437 2.916744 -13.480725
v  4.525005 2.916744 -11.225092
v  4.525005 0.544394 -16.090992
v  2.378858 4.861141 -11.222093
v  1.049386 5.141178 -23.969852
v  3.944975 1.409305 -11.000272
v  -0.807296 7.791223 -11.943274
v  2.151772 0.980508 -10.996457
v  3.945141 4.861138 -20.279078
v  -3.953437 2.916743 -16.090992
v  -1.035928 5.141178 -20.839775
v  -4.868104 2.364102 -20.839775
v  5.213821 4.852754 -23.964602
v  -3.373407 2.261008 -11.000272
v  1.379651 6.986866 -10.346466
v  -3.953437 2.916744 -11.225092
v  1.607497 5.141178 -20.839775
v  -4.288074 4.852756 -20.260895
v  -3.953437 1.396096 -18.347197
v  -1.226142 4.861141 -10.996458
v  -4.642135 4.014400 -20.260895
v  -0.477817 5.141178 -23.969852
v  4.525005 0.557602 -11.579153
v  -1.226142 2.381503 -10.996457
v  -0.807296 7.791223 -10.640263
v  -1.807290 4.861141 -11.222093
v  5.439672 0.713805 -20.485714
v  4.859642 0.713805 -20.260893
v  1.378864 7.791223 -11.943274
v  -1.807290 4.861141 -13.834630
v  -3.727634 4.861138 -20.279078
v  -3.727468 1.409305 -11.000272
v  -1.807290 4.861139 -16.090836
v  4.525005 1.409304 -13.480725
v  -3.727468 0.557602 -11.000272
v  1.797711 3.782499 -10.996457
v  2.378858 4.861141 -13.480570
v  -0.809959 5.141178 -20.260895
v  -1.226142 3.782499 -10.996457
v  4.525005 2.247799 -17.993135
v  3.944975 2.261008 -11.000272
v  1.797711 4.861141 -10.996458
v  -3.953437 0.544394 -18.347197
v  -3.953437 0.557602 -11.579153
v  4.299037 2.916744 -11.000273
v  -4.868104 3.189251 -20.485714
v  -4.868104 4.852756 -20.485714
v  -1.580203 0.980508 -10.996457
v  -1.070480 5.141178 -23.376041
v  4.859758 4.014399 -23.964602
v  -0.230962 0.691181 -10.464714
v  -1.738225 2.872434 -11.350576
v  2.309793 2.872434 -11.350576
v  0.285791 6.955283 -10.359674
v  0.285777 5.452144 -10.359674
v  0.285791 1.125713 -10.359674
v  0.285777 6.955283 -10.359674
v  0.285791 5.452144 -10.359674
v  0.285777 3.285712 -10.359674
v  0.285791 3.285712 -10.359674
v  0.285777 1.125713 -10.359674
v  0.285791 4.365715 -10.359674
v  0.285777 4.365715 -10.359674
v  0.285791 6.457188 -10.359674
v  0.285777 6.457188 -10.359674
v  0.285777 7.232417 -10.359674
v  0.285791 7.232417 -10.359674
v  -0.741063 4.255248 -24.525612
v  1.312631 4.255248 -24.525612
v  5.185232 2.478619 -22.070934
v  -4.613664 2.478619 -22.070934
v  5.125232 1.423844 -22.181795
v  -0.801062 4.244798 -24.277941
v  1.372631 4.244798 -24.277943
v  5.185232 1.447774 -21.962587
v  5.125232 2.479519 -22.181795
v  -4.553664 2.479519 -22.181795
v  -4.553664 4.245453 -22.003124
v  5.125232 4.245453 -22.003124
v  -0.936364 2.012285 -11.320996
v  1.507933 2.012285 -11.320996
v  2.277162 2.880570 -11.290576
v  -1.705593 2.880570 -11.290576
v  -4.553664 3.815168 -22.448742
v  5.125232 3.815168 -22.448742
v  -3.114961 1.708416 -11.313516
v  3.686530 1.708416 -11.313516
v  -0.778707 2.189062 -11.260971
v  1.350276 2.189062 -11.260971
v  -0.821863 6.471544 -12.252230
v  1.290269 5.675517 -11.329659
v  -0.718700 5.675517 -11.329659
v  1.312631 1.573288 -24.615234
v  -4.553664 1.573288 -23.245823
v  0.802531 0.691182 -10.375868
v  -0.230962 0.691181 -10.375868
v  4.210782 1.359034 -12.869120
v  -3.639213 1.359034 -12.869120
v  -3.639213 1.359034 -15.190415
v  4.210782 1.359034 -15.190414
v  4.210782 1.359034 -17.450851
v  -3.639213 1.359034 -19.347155
v  -3.639213 1.359034 -19.804352
v  -3.639213 1.359034 -17.450851
v  4.210782 1.359034 -19.347155
v  -3.639213 1.359034 -12.411924
v  4.210782 1.359034 -16.993656
v  4.210782 1.359034 -14.733219
v  4.210782 1.359034 -12.411924
v  -3.639213 1.359034 -16.993656
v  -3.639213 1.359034 -14.733219
v  4.210782 1.359034 -19.804352
v  -0.718700 6.934493 -11.197173
v  1.290269 6.934493 -11.197173
v  1.350269 4.213660 -10.763798
v  -0.778700 4.213660 -10.763798
v  1.350269 5.293898 -10.763798
v  -0.778700 5.293898 -10.763798
v  2.416751 3.323747 -11.290576
v  -1.845182 3.323747 -11.290576
v  2.256744 3.374829 -11.290576
v  -1.685175 3.374829 -11.290576
v  -2.050670 3.280644 -11.290576
v  2.622238 3.280644 -11.290576
v  3.455507 3.059189 -11.290576
v  -2.883938 3.059189 -11.290576
v  3.038598 3.171526 -11.290576
v  -2.467030 3.171526 -11.290576
v  2.822388 3.224264 -11.290576
v  -2.250819 3.224264 -11.290576
v  -2.675472 3.092548 -11.290576
v  3.247040 3.092548 -11.290576
v  5.184417 4.887784 -20.290180
v  5.184417 0.678777 -20.290180
v  5.201762 3.154222 -23.692909
v  5.201762 0.678776 -23.692909
v  5.201762 2.329072 -23.692909
v  5.201762 1.503924 -23.692909
v  5.201762 3.979370 -23.692911
v  5.201762 4.887783 -23.692911
v  5.201644 1.503925 -20.272951
v  5.201644 2.329074 -20.272953
v  5.201644 3.154222 -20.272953
v  5.201644 3.979371 -20.272953
v  5.184535 0.678775 -23.935314
v  5.184417 3.979371 -20.515358
v  5.184417 3.154222 -20.515358
v  5.184417 1.503925 -20.515356
v  5.184535 1.503924 -23.935314
v  5.184535 3.979370 -23.935316
v  5.184417 2.329074 -20.515358
v  5.184417 0.678776 -20.515358
v  5.184535 4.887783 -23.935316
v  5.184417 4.887784 -20.515358
v  -4.612966 1.503924 -23.710136
v  -4.612966 3.979370 -23.710138
v  -4.612966 3.154222 -23.710136
v  -4.612966 0.678775 -23.710136
v  -4.612966 2.329072 -23.710136
v  -4.612966 4.887783 -23.710138
v  1.059912 6.047520 -23.729160
v  4.512946 1.361068 -18.335136
v  -3.941378 2.212771 -18.005196
v  5.427613 1.503925 -20.497772
v  4.286977 2.296036 -11.012331
v  4.512946 2.951771 -18.005196
v  4.512946 2.296036 -11.567094
v  -3.941378 0.509366 -16.078932
v  -4.300133 2.329074 -20.272953
v  5.427613 3.154222 -20.497772
v  -3.385632 4.896166 -20.291136
v  4.871818 1.503924 -23.952541
v  -3.385632 3.159811 -20.291136
v  -4.630193 3.979370 -23.952541
v  -4.630193 4.887783 -23.952541
v  -4.856044 1.503925 -20.497772
v  -3.941378 2.951771 -15.748990
v  -4.856044 4.887784 -20.497772
v  -4.856044 3.979371 -20.497772
v  -3.941378 0.509366 -13.492784
v  -3.385466 1.444333 -11.012331
v  -4.856044 2.329074 -20.827717
v  -3.941378 0.509366 -18.335136
v  -3.941378 1.444333 -13.492785
v  -4.300133 3.154222 -20.272953
v  1.367592 5.518755 -10.358526
v  4.512946 1.444333 -11.237151
v  -4.300249 0.678775 -23.952541
v  1.366805 6.047522 -20.499306
v  -3.941378 1.361068 -18.335136
v  -0.795236 6.047522 -20.499306
v  1.392646 7.773996 -10.332516
v  4.512946 2.212771 -18.335138
v  -1.795230 4.896169 -11.234152
v  2.366799 4.896169 -13.822571
v  1.809770 1.015537 -11.008516
v  4.512946 2.951772 -13.822727
v  -3.715409 2.951773 -11.012331
v  5.427613 3.979371 -20.497772
v  5.427613 4.887784 -20.497772
v  -3.941378 2.951771 -18.005196
v  -3.385632 3.986357 -20.291136
v  1.366805 7.826251 -11.955333
v  3.957200 3.159811 -20.291136
v  -3.941378 2.212772 -15.748990
v  3.957200 3.986357 -20.291136
v  4.871701 4.887784 -20.272953
v  2.366799 4.896167 -18.005039
v  -4.856044 3.154222 -20.497772
v  -3.941378 1.361068 -15.748990
v  4.871701 0.678776 -20.272953
v  4.512946 1.361069 -15.748990
v  4.512946 0.592630 -11.567094
v  4.512946 0.509366 -16.078932
v  5.427613 2.329074 -20.827717
v  -0.488344 6.047520 -23.729160
v  -3.698973 0.592630 -11.549868
v  -3.924151 0.592630 -11.254377
v  4.270541 0.592630 -11.549868
v  4.495719 0.592630 -11.254377
v  -1.552348 4.896168 -16.061550
v  2.123917 4.896168 -16.061550
v  2.123916 4.896169 -13.805345
v  -1.552348 4.896167 -18.317755
v  2.123917 4.896167 -18.317755
v  -1.552348 4.896169 -13.805345
v  -4.317476 3.154221 -23.935314
v  4.270541 1.444333 -11.254377
v  4.269751 0.592630 -11.254736
v  -4.613639 3.979371 -20.810490
v  1.350365 6.523799 -10.375752
v  4.270167 2.212771 -18.317911
v  1.350365 2.272325 -10.375752
v  4.495719 2.212772 -16.061707
v  -1.778004 4.896168 -16.061550
v  -4.317359 1.503925 -20.290178
v  2.349573 4.896168 -16.061550
v  -0.802591 5.176206 -23.940567
v  -0.778010 7.826251 -10.375584
v  5.410387 1.503925 -20.810488
v  4.269751 2.951773 -11.254736
v  -3.402858 3.986357 -20.533939
v  4.269917 3.986357 -20.308363
v  -4.838818 1.503925 -20.810488
v  -1.778004 4.896169 -13.509855
v  1.056753 5.176207 -20.515358
v  1.123923 6.047522 -20.812023
v  1.353033 5.176207 -20.515001
v  -4.612849 3.979371 -20.515358
v  1.123923 7.826251 -11.972560
v  -4.612849 0.678776 -20.515358
v  5.185208 1.503925 -20.514999
v  -3.698734 1.361068 -18.022421
v  -4.317359 1.503925 -20.515356
v  4.270167 2.212772 -16.061707
v  -3.924151 0.509366 -13.805500
v  1.056753 5.176207 -20.290180
v  5.184175 2.329073 -23.413855
v  1.373804 5.176206 -23.419109
v  -4.317476 4.887783 -23.935316
v  -0.485184 5.176207 -20.515358
v  -3.698869 0.509366 -15.766215
v  1.374160 5.176206 -23.715389
v  2.122486 2.416532 -11.025743
v  -3.698973 0.592630 -11.254377
v  1.374160 5.176206 -23.940567
v  -3.698348 3.986357 -20.533939
v  5.185208 1.503925 -20.810488
v  4.495719 2.296036 -13.510011
v  4.888928 3.979371 -20.290180
v  -0.485184 5.176207 -20.290180
v  -0.778796 4.432325 -10.375752
v  -3.698183 1.444333 -11.254736
v  4.888928 3.979371 -20.515358
v  -3.698348 4.896166 -20.534019
v  -4.317359 0.678776 -20.515358
v  -4.317359 3.979371 -20.515358
v  4.270167 2.212772 -15.766216
v  -3.698183 2.296036 -11.029557
v  -3.402693 2.951773 -11.254736
v  -0.507103 5.176206 -23.715389
v  4.888928 3.154223 -20.515358
v  5.185208 4.887783 -20.810490
v  -4.838818 0.678776 -20.810488
v  2.123917 4.896168 -15.766060
v  4.889044 2.329072 -23.935314
v  5.184175 3.979370 -23.413857
v  -3.698869 0.509366 -18.317909
v  -4.612607 3.979370 -23.709347
v  2.349572 4.896169 -11.251378
v  -4.612607 3.979370 -23.413857
v  -0.505570 6.047520 -23.486279
v  -0.802591 5.176206 -23.715389
v  4.269917 3.986357 -20.533939
v  -1.778004 4.896169 -11.546867
v  -1.550918 1.015536 -11.251025
v  5.185208 2.329074 -20.514999
v  4.270541 2.296036 -11.254377
v  -0.780674 5.176207 -20.515358
v  -1.552348 4.896168 -15.766060
v  -3.924151 2.212771 -18.317911
v  -4.612966 0.678775 -23.935314
v  3.974261 2.296036 -11.029557
v  4.270437 0.509366 -16.061705
v  -4.613639 0.678776 -20.810488
v  4.270063 2.951772 -13.805501
v  -3.698495 2.951771 -16.061707
v  -0.801061 6.047520 -23.486279
v  -0.552354 6.047522 -20.516533
v  -3.698599 2.296036 -13.510011
v  5.184175 4.887783 -23.709347
v  1.350365 4.432325 -10.375752
v  3.974261 2.296036 -11.254736
v  -0.778796 1.192324 -10.375752
v  -3.698183 0.592630 -11.254736
v  1.826996 1.015537 -11.251025
v  5.410387 0.678776 -20.810488
v  2.122486 2.416532 -11.251160
v  -4.317359 3.979371 -20.290180
v  -3.698599 2.212771 -18.317911
v  -3.698183 2.296036 -11.254736
v  5.185208 4.887784 -20.514999
v  5.184175 0.678776 -23.413855
v  -4.613639 0.678776 -20.514999
v  5.410387 3.979371 -20.810490
v  -0.781464 5.176207 -20.810490
v  1.349579 7.826251 -10.610976
v  4.495719 2.951773 -11.549868
v  -4.613639 4.887783 -20.810490
v  -3.698183 2.951773 -11.254736
v  -3.402693 0.592630 -11.029557
v  -3.402693 1.444333 -11.254736
v  -3.924151 2.296036 -11.549868
v  1.598982 5.176206 -23.714598
v  -1.255428 3.817527 -11.251295
v  -1.550918 3.817527 -11.025743
v  4.495719 0.509366 -18.022421
v  -3.698599 2.212771 -18.022423
v  -4.317476 2.329072 -23.935314
v  -3.924151 2.951773 -11.549868
v  4.270437 0.509366 -18.022421
v  4.270302 1.361069 -16.061707
v  2.122486 4.896169 -11.025743
v  -1.550918 4.896169 -11.025743
v  -0.781464 5.176207 -20.515001
v  5.184175 1.503924 -23.413855
v  4.270541 0.592630 -11.254377
v  1.077139 6.047520 -23.486279
v  4.270063 2.951771 -18.317911
v  -3.698973 1.444333 -11.549868
v  5.184175 4.887783 -23.413857
v  1.078672 5.176206 -23.715389
v  -3.698495 2.951771 -18.022423
v  -0.778796 2.272325 -10.375752
v  3.974427 3.159811 -20.533859
v  -3.698869 0.509366 -13.805500
v  -3.698973 2.296036 -11.549868
v  1.826996 2.416532 -11.251160
v  4.269917 3.159811 -20.533859
v  -3.402858 4.896166 -20.534019
v  -3.402693 2.296036 -11.254736
v  4.269751 1.444333 -11.029557
v  -1.550918 2.416532 -11.025743
v  -3.698348 4.896166 -20.308363
v  -3.698973 2.951773 -11.549868
v  4.495719 2.951772 -13.510012
v  -0.552354 7.826251 -10.610976
v  -3.698869 0.509366 -13.510011
v  4.269917 4.896166 -20.308363
v  1.123923 7.826251 -10.610976
v  5.184175 2.329073 -23.709347
v  3.974261 0.592630 -11.029557
v  -3.698869 0.509366 -16.061705
v  -4.613639 4.887784 -20.514999
v  -4.612607 0.678775 -23.709347
v  -1.027413 5.176206 -23.714598
v  5.185208 3.154223 -20.514999
v  5.185208 3.154222 -20.810490
v  4.495719 1.361068 -18.022421
v  -3.698973 2.296036 -11.254377
v  5.184175 0.678776 -23.709347
v  -0.552354 6.047522 -20.812023
v  -4.612849 2.329074 -20.515358
v  3.974261 2.951773 -11.254736
v  4.269751 1.444333 -11.254736
v  5.185208 0.678776 -20.810488
v  4.270063 2.951772 -13.510012
v  -3.698973 2.951773 -11.254378
v  4.270437 0.509366 -15.766215
v  -4.317359 4.887784 -20.515358
v  4.269917 4.896166 -20.534019
v  3.974261 2.951773 -11.029558
v  -4.613639 3.979371 -20.514999
v  -4.613639 1.503925 -20.810488
v  4.270063 2.951771 -18.022423
v  -0.778010 7.826251 -12.207952
v  1.372629 6.047520 -23.486279
v  -4.612607 4.887783 -23.709347
v  -4.612607 0.678775 -23.413855
v  -0.802235 5.176206 -23.714598
v  4.888928 0.678776 -20.515358
v  -3.698495 2.951771 -18.317911
v  -1.006642 5.176207 -20.810490
v  -0.778796 3.352323 -10.375752
v  -0.552354 7.826251 -11.972560
v  2.122486 3.817527 -11.025743
v  -1.550918 2.416532 -11.251160
v  -3.924151 1.361068 -18.022421
v  5.184175 1.503924 -23.709347
v  -3.924151 0.509366 -18.022421
v  -3.924151 2.212771 -16.061707
v  4.270437 0.509366 -13.805500
v  -4.613639 1.503925 -20.514999
v  -1.778004 4.896167 -18.317755
v  2.122486 1.015537 -11.025743
v  1.123923 6.047522 -20.516533
v  -0.802235 5.176206 -23.419109
v  4.495719 2.212771 -18.022423
v  4.889044 3.154221 -23.935314
v  1.350365 7.299027 -10.375752
v  1.578211 5.176207 -20.810490
v  -1.550918 1.015536 -11.025743
v  4.495719 1.444333 -13.805500
v  4.270063 2.951771 -15.766216
v  3.974427 3.986357 -20.533939
v  3.974261 1.444333 -11.254736
v  -3.698734 1.444333 -13.510011
v  4.270302 1.444333 -13.805500
v  -3.698599 2.212772 -16.061707
v  -3.924151 2.951772 -13.805501
v  2.122486 3.817527 -11.251295
v  -3.698734 1.361068 -16.061707
v  3.974427 4.896166 -20.534019
v  4.270302 1.361068 -18.317909
v  -4.613639 3.154223 -20.514999
v  -1.550918 3.817527 -11.251295
v  5.185208 2.329074 -20.810490
v  -4.613639 2.329074 -20.810490
v  4.495719 2.951771 -16.061707
v  4.888928 1.503925 -20.515356
v  -4.612607 4.887783 -23.413857
v  -3.698599 2.212772 -15.766216
v  -4.612607 3.154222 -23.413855
v  -4.612849 3.154222 -20.515358
v  -3.924151 1.444333 -11.549868
v  5.185208 3.979371 -20.810490
v  -0.552354 7.826251 -12.207952
v  1.352242 5.176207 -20.515358
v  5.184175 3.154222 -23.413855
v  4.270541 2.951773 -11.254378
v  5.184175 3.154222 -23.709347
v  -4.612607 3.154222 -23.709347
v  -3.698734 1.361068 -15.766216
v  1.123923 7.826251 -10.375584
v  -4.612849 4.887784 -20.290180
v  -1.255428 2.416532 -11.251160
v  -4.317476 1.503924 -23.935314
v  4.270541 2.296036 -11.549868
v  4.888928 2.329074 -20.515358
v  -1.552348 4.896167 -18.022264
v  -4.612607 2.329073 -23.709347
v  4.270063 2.951771 -16.061707
v  -3.698869 0.509366 -18.022421
v  -4.612607 1.503924 -23.709347
v  2.123916 4.896169 -13.509855
v  4.270167 2.212771 -18.022423
v  -3.698734 1.361068 -18.317909
v  1.350365 1.192325 -10.375752
v  -0.778796 7.021894 -10.375752
v  1.826996 3.817527 -11.251295
v  1.349579 7.826251 -12.207952
v  -0.778796 6.523799 -10.375752
v  -3.698495 2.951771 -15.766216
v  -3.924151 0.509366 -15.766215
v  4.270437 0.509366 -18.317909
v  5.410387 2.329074 -20.514999
v  -4.612849 2.329074 -20.290180
v  -4.612849 4.887784 -20.515358
v  -4.317359 2.329074 -20.515358
v  -3.698183 1.444333 -11.029557
v  -4.613639 2.329074 -20.514999
v  1.353033 5.176207 -20.810490
v  5.185208 0.678776 -20.514999
v  1.373804 5.176206 -23.714598
v  4.270167 2.296036 -13.510011
v  -4.838818 2.329074 -20.514999
v  -4.612607 1.503924 -23.413855
v  -3.924151 2.951771 -18.317911
v  1.349579 6.047522 -20.812023
v  1.372629 6.047520 -23.711933
v  -0.778796 5.518755 -10.375752
v  1.350365 3.352323 -10.375752
v  4.495719 1.444333 -11.549868
v  -4.612849 0.678777 -20.290180
v  5.185208 3.979371 -20.514999
v  -3.698495 2.951772 -13.805501
v  -3.698973 1.444333 -11.254377
v  -3.924151 2.951771 -16.061707
v  -3.924151 2.296036 -13.510011
v  2.349572 4.896169 -13.509855
v  2.122486 1.015537 -11.251025
v  4.495719 2.296036 -11.254377
v  -4.612849 3.154222 -20.290180
v  1.123923 7.826251 -12.207952
v  -3.698348 3.159811 -20.533859
v  -1.255428 1.015536 -11.251025
v  4.495719 0.509366 -13.510011
v  -4.612607 2.329073 -23.413855
v  4.270437 0.509366 -13.510011
v  -0.801061 6.047520 -23.711933
v  5.410387 3.154222 -20.810490
v  -4.613639 3.154222 -20.810490
v  -4.317476 3.979370 -23.935316
v  4.270541 2.951773 -11.549868
v  4.270541 1.444333 -11.549868
v  4.270302 1.361069 -15.766216
v  -3.698495 2.951772 -13.510012
v  -4.317359 3.154223 -20.515358
v  -3.402693 2.951773 -11.029558
v  4.888928 4.887784 -20.515358
v  4.270302 1.444333 -13.510011
v  -4.612849 1.503925 -20.515356
v  2.123917 4.896167 -18.022264
v  -0.778010 6.047522 -20.812023
v  -1.552348 4.896169 -13.509855
v  4.269917 3.159811 -20.308363
v  -3.402858 3.159811 -20.533859
v  -3.698348 3.159811 -20.308363
v  -4.838818 3.154222 -20.810490
v  -3.924151 1.361068 -16.061707
v  -3.698348 3.986357 -20.308363
v  4.495719 2.951771 -18.317911
v  2.349573 4.896167 -18.317755
v  -4.838818 3.979371 -20.810490
v  -4.838818 4.887783 -20.810490
v  5.410387 4.887783 -20.810490
v  4.269751 2.296036 -11.254736
v  4.495719 0.509366 -15.766215
v  4.495719 1.361068 -16.061707
v  -0.552354 7.826251 -10.375584
v  4.270302 1.361068 -18.022421
v  5.184175 3.979370 -23.709347
v  3.974261 0.592630 -11.254736
v  -3.402693 0.592630 -11.254736
v  5.426581 3.979370 -23.726574
v  -4.855012 3.154222 -23.726572
v  -4.855012 4.887783 -23.726574
v  -4.855012 1.503924 -23.726572
v  5.426581 1.503924 -23.726572
v  5.426581 2.329072 -23.726572
v  -4.855012 2.329072 -23.726572
v  5.426581 4.887783 -23.726574
v  5.426581 3.154222 -23.726572
v  -4.855012 0.678775 -23.726572
v  5.426581 0.678776 -23.726572
v  -4.855012 3.979370 -23.726574
v  5.409355 1.503924 -23.413855
v  -4.317476 3.154222 -23.710135
v  -4.317476 1.503924 -23.710135
v  5.409355 3.979370 -23.413857
v  5.409355 3.154222 -23.413855
v  4.889044 2.329073 -23.710135
v  4.889044 0.678776 -23.710135
v  -4.317476 3.979370 -23.710136
v  5.409355 0.678776 -23.413855
v  -4.317476 0.678775 -23.710135
v  4.889044 1.503924 -23.710135
v  -4.317476 2.329073 -23.710135
v  -4.317476 4.887783 -23.710136
v  4.889044 4.887783 -23.710136
v  4.889044 3.979370 -23.710136
v  4.889044 3.154222 -23.710135
v  5.409355 2.329073 -23.413855
v  -4.837786 3.979370 -23.413857
v  -4.837786 3.154222 -23.413855
v  -4.837786 1.503924 -23.413855
v  -4.837786 2.329073 -23.413855
v  -4.837786 4.887783 -23.413857
v  5.409355 4.887783 -23.413857
v  -4.837786 0.678775 -23.413855
v  -3.681507 1.444332 -13.822727
v  -3.681372 2.296036 -13.822727
v  -3.924151 1.444332 -13.805500
v  4.252941 2.296036 -13.822727
v  4.288098 2.272325 -11.236124
v  -3.716530 2.272325 -11.236124
v  2.049898 3.778092 -15.001745
v  2.049898 3.778092 -19.747511
v  -1.478330 3.778092 -15.001745
v  -1.478330 3.778092 -19.747513
v  2.049898 3.778092 -17.336790
v  -1.478330 3.778092 -17.336790
v  -1.478330 3.778092 -12.666700
v  2.049898 3.778092 -12.666700
v  1.027467 5.158405 -20.260895
v  -4.288074 0.696578 -20.260893
v  -0.797900 5.158405 -20.272953
v  1.378864 6.029720 -20.841309
v  -4.868104 3.172024 -20.839775
v  5.439672 2.346875 -20.839775
v  1.616208 5.158404 -23.401882
v  1.595438 5.158405 -20.497774
v  -1.044640 5.158404 -23.401882
v  -1.023869 5.158405 -20.497774
v  4.512946 2.230573 -15.748990
v  1.379651 7.281226 -12.238448
v  1.379651 7.004093 -12.238448
v  -0.796023 7.281226 -10.358526
v  -0.795236 7.808450 -10.628202
v  -1.238201 3.799726 -11.008516
v  -1.795230 4.878368 -11.234152
v  -3.941378 1.426532 -11.237151
v  -3.941378 2.933971 -13.492785
v  4.512946 2.933971 -11.237152
v  3.957034 1.426532 -11.012331
v  2.366799 4.878367 -13.492628
v  -3.941378 2.933971 -11.237152
v  -1.238201 2.398730 -11.008516
v  1.367592 6.505998 -10.358526
v  -3.941378 1.378869 -18.335136
v  2.366799 4.878368 -11.234152
v  4.512946 1.378869 -18.005194
v  4.512946 0.527168 -13.822726
v  1.809770 4.878368 -11.008518
v  -3.715409 1.426532 -11.012331
v  4.512946 0.527167 -18.335136
v  -1.238201 4.878368 -11.008518
v  -3.941378 0.527168 -15.748989
v  1.367592 5.500954 -12.226389
v  -1.795230 4.878367 -13.822571
v  4.512946 2.933970 -15.748990
v  4.512946 2.278234 -13.822727
v  -3.385466 2.278235 -11.012331
v  -0.795236 7.808450 -11.955333
v  1.809770 3.799726 -11.008516
v  -3.941378 2.933970 -16.078934
v  1.367592 6.505998 -12.226389
v  1.366805 7.808450 -10.358356
v  -3.715409 0.574829 -11.012331
v  -3.941378 2.278235 -11.237151
v  -3.941378 2.230572 -18.005196
v  1.809770 2.398730 -11.008516
v  3.957034 2.278235 -11.012331
v  1.809770 0.997735 -11.008516
v  2.366799 4.878366 -15.748834
v  -3.941378 0.574829 -11.237151
v  4.512946 2.230572 -18.005196
v  4.286977 2.933971 -11.012331
v  -3.385466 2.933971 -11.012331
v  1.367592 5.500954 -10.358526
v  5.439672 0.696577 -20.485714
v  -4.868104 2.346875 -20.485714
v  5.439672 4.869982 -20.485714
v  -3.727634 4.878365 -20.279078
v  -4.642135 3.997173 -20.260895
v  1.049386 5.158404 -23.969852
v  -0.477817 5.158404 -23.969852
v  5.213704 4.869982 -20.260895
v  5.213704 3.997173 -20.260895
v  -4.642135 2.346875 -20.260895
v  -4.300133 4.869982 -20.272953
v  4.871701 2.346875 -20.272953
v  3.957200 4.878365 -20.291136
v  5.201762 0.696577 -23.952541
v  1.389856 6.029719 -23.729160
v  5.201762 4.869981 -23.952541
v  -4.630193 4.869981 -23.952541
v  -4.300133 3.172024 -20.272953
v  -4.630193 0.696577 -23.952541
v  5.213821 2.346874 -23.964600
v  5.213821 1.521726 -23.964600
v  -4.642252 2.346874 -23.964600
v  -3.196849 2.930606 -11.290576
v  3.768417 2.930606 -11.290576
v  0.959261 5.223945 -20.514290
v  -0.387693 5.223945 -20.514290
v  -3.699213 1.721178 -14.869153
v  4.270782 1.721178 -14.869154
v  -3.699213 1.721178 -12.547503
v  4.270782 1.721178 -12.547503
v  -4.613664 4.133320 -22.358059
v  5.185232 4.133320 -22.358059
v  -1.534845 2.917420 -11.253515
v  2.106413 2.917420 -11.253515
v  -4.613664 1.573227 -21.420027
v  5.185232 1.573227 -21.420027
v  2.124005 4.829558 -11.235300
v  1.810918 4.829558 -11.251311
v  -1.554504 4.377546 -20.514265
v  2.126072 4.377546 -20.514265
v  -1.239350 4.829558 -11.251311
v  0.285777 2.205714 -10.359674
v  -1.552437 4.829558 -11.235300
v  0.285791 2.205714 -10.359674
v  0.722184 0.805154 -10.464714
v  -4.239161 1.358973 -20.514721
v  4.810730 1.358973 -20.514721
v  -4.854896 3.220833 -20.826567
v  4.511797 1.377722 -13.821579
v  -0.794875 6.457188 -12.225241
v  1.366443 7.232417 -12.225241
v  1.366443 6.955283 -12.225241
v  4.297314 0.526019 -11.001994
v  -1.805567 4.829558 -11.223815
v  2.377136 4.829558 -11.223815
v  -3.951714 2.885160 -16.089270
v  -3.951714 1.427679 -18.345474
v  2.150049 0.948926 -10.998180
v  -3.375129 2.229426 -11.001994
v  -3.725746 1.377723 -11.001994
v  4.523283 2.229426 -11.577431
v  4.523283 0.575977 -13.833063
v  4.523283 0.575977 -18.345474
v  -0.805573 7.759641 -10.638539
v  -1.805567 4.829558 -13.832908
v  4.523283 2.885160 -15.738653
v  -1.227865 2.349921 -10.998180
v  4.523283 1.427679 -17.994858
v  4.297314 2.885162 -11.001995
v  2.377136 4.829558 -13.482292
v  3.946698 1.377722 -11.001994
v  4.523283 2.279382 -18.345474
v  1.799433 2.349921 -10.998180
v  3.946698 2.229426 -11.001994
v  1.799433 3.750916 -10.998180
v  4.523283 2.885161 -13.482449
v  -1.805567 4.829557 -16.089113
v  1.377928 5.452144 -12.236726
v  1.626545 5.109594 -23.742161
v  -0.794088 5.980911 -20.500454
v  -1.022720 5.109596 -20.498920
v  5.426465 0.745387 -20.498920
v  5.200496 4.045982 -20.274101
v  5.212098 4.045981 -23.962879
v  -0.796752 5.109596 -20.274101
v  -4.628927 4.045982 -20.274101
v  -4.628927 1.570536 -20.274099
v  -4.289796 4.821173 -20.262617
v  1.029190 5.109596 -20.262617
v  4.861365 4.821173 -20.262617
v  5.212098 2.395684 -23.962875
v  5.212098 1.570535 -23.962875
v  -4.640529 1.570535 -23.962875
v  -4.640529 2.395684 -23.962875
v  4.253985 2.868509 -13.821579
v  -3.682656 1.361069 -13.821579
v  4.254224 1.361069 -13.821579
v  -3.682417 2.868509 -13.821579
v  -4.629045 3.237485 -23.694057
v  5.200613 0.762039 -23.694057
v  -4.629045 1.587187 -23.694057
v  -4.629045 4.062634 -23.694059
v  5.200613 2.412336 -23.694057
v  -4.629045 0.762039 -23.694057
v  5.200613 1.587187 -23.694057
v  -4.629045 4.804519 -23.694059
v  -4.629045 2.412336 -23.694057
v  5.200613 4.062634 -23.694059
v  5.200613 4.804519 -23.694059
v  5.200613 3.237485 -23.694057
v  4.511797 1.361069 -13.821579
v  4.511797 0.509367 -11.238299
v  4.511797 2.296035 -15.750138
v  -1.239350 0.932273 -11.009665
v  -3.386614 1.361070 -11.013479
v  5.426465 2.412337 -20.498920
v  -4.854896 4.804520 -20.826567
v  2.365651 4.812904 -15.749982
v  4.285995 4.812903 -20.292286
v  -3.940229 2.868509 -11.565946
v  5.426465 4.062634 -20.826567
v  -4.628927 3.237486 -20.274101
v  -3.714427 3.243075 -20.292286
v  3.958183 0.509367 -11.013479
v  1.368320 5.092943 -20.274101
v  3.958183 2.868509 -11.013479
v  -0.794088 7.742988 -12.224030
v  -4.854896 4.062634 -20.826567
v  -4.628927 4.804520 -20.274101
v  -4.301397 1.587187 -23.951393
v  1.390238 5.092942 -23.956644
v  -0.794875 7.215764 -10.359674
v  -3.386614 0.509367 -11.013479
v  -3.940229 2.296035 -16.077784
v  1.615060 5.092942 -23.403030
v  -3.940229 1.444331 -18.333988
v  -0.794875 6.938631 -10.359674
v  1.366443 7.215764 -10.359674
v  -1.794082 4.812904 -15.749982
v  4.511797 2.868509 -11.565946
v  -3.940229 0.592629 -16.077784
v  -4.629045 0.762039 -23.951393
v  2.138564 0.932273 -11.009665
v  -3.714427 4.812903 -20.292286
v  -3.940229 2.212773 -11.565946
v  5.200496 4.804520 -20.274101
v  1.810918 3.734263 -11.009665
v  5.200613 4.804519 -23.951393
v  -4.629045 4.804519 -23.951393
v  4.872966 1.587187 -23.951393
v  4.511797 0.592629 -18.006342
v  4.872966 0.762039 -23.951393
v  4.511797 2.212772 -13.493933
v  -0.794088 7.742988 -10.359505
v  -3.940229 2.296035 -18.006344
v  -1.022720 5.092943 -20.826567
v  -3.940229 0.592629 -18.333988
v  -4.628927 1.587189 -20.274099
v  -4.854896 1.587188 -20.826567
v  1.365657 7.742988 -10.627054
v  -3.940229 2.868507 -18.333990
v  2.365651 4.812906 -11.562946
v  5.426465 4.804520 -20.826567
v  5.426465 3.237486 -20.826567
v  4.511797 2.868507 -18.333990
v  4.872966 2.412336 -23.951393
v  2.365651 4.812904 -18.333832
v  -1.794082 4.812904 -18.333832
v  -3.940229 1.444332 -16.077784
v  4.511797 0.592630 -15.750137
v  5.200496 0.762040 -20.274101
v  4.511797 1.444332 -16.077784
v  -3.699062 0.509367 -11.549778
v  -3.924062 0.509367 -11.254467
v  4.270630 0.509366 -11.549778
v  4.269662 0.509367 -11.254646
v  -3.698093 0.509367 -11.254646
v  2.124006 4.812904 -16.061460
v  2.124006 4.812904 -18.317665
v  -1.552437 4.812904 -16.061460
v  2.124006 4.812905 -13.805256
v  -1.552437 4.812905 -13.805256
v  -1.552437 4.812904 -18.317665
v  -0.778707 2.189061 -10.375841
v  5.184264 4.062634 -23.709259
v  4.889017 4.804520 -20.515268
v  -3.698823 1.444331 -18.317822
v  -0.552443 5.964258 -20.516621
v  -4.612696 2.412336 -23.413944
v  5.185297 2.412337 -20.810400
v  4.270630 2.868509 -11.549779
v  -3.698584 2.868507 -18.317822
v  -0.802502 5.092942 -23.715477
v  -4.613729 3.237486 -20.515089
v  -4.612760 0.762040 -20.290268
v  5.184329 0.762040 -20.515268
v  1.124012 5.964258 -20.811934
v  4.889017 0.762040 -20.515268
v  4.889017 4.804520 -20.290268
v  1.124012 7.742988 -11.972649
v  -1.777915 4.812905 -13.509944
v  1.350276 2.189062 -10.375841
v  -3.698823 1.444331 -18.022511
v  -3.698093 2.868509 -11.029647
v  4.270256 2.296035 -16.061617
v  -3.698688 2.212772 -13.510100
v  -4.838728 1.587188 -20.515087
v  5.184264 2.412336 -23.413944
v  2.122397 4.812906 -11.025833
v  1.374071 5.092942 -23.715477
v  -3.698688 2.296035 -16.061617
v  1.578122 5.092943 -20.515089
v  -0.552443 7.742988 -10.610888
v  4.269662 2.212773 -11.029646
v  4.889017 3.237486 -20.515268
v  -3.924062 2.296035 -18.317822
v  -4.612760 4.062634 -20.515268
v  4.270256 2.212772 -13.510100
v  -3.698093 1.361070 -11.254646
v  4.495630 2.868509 -13.805412
v  -4.613729 3.237486 -20.810400
v  -0.485273 5.092943 -20.290268
v  -3.402947 4.069621 -20.533852
v  -0.505659 5.964257 -23.711845
v  -0.802502 5.092942 -23.940477
v  -4.317449 3.237486 -20.515268
v  -4.612696 3.237485 -23.413944
v  -0.778707 4.349062 -10.375841
v  4.889017 4.062634 -20.515268
v  4.269662 2.212773 -11.254646
v  5.184329 3.237486 -20.290268
v  -4.613729 4.062634 -20.810400
v  4.270256 2.296035 -15.766306
v  5.185297 4.804520 -20.810400
v  -4.317449 4.062634 -20.515268
v  -3.698958 0.592629 -15.766305
v  -3.698093 2.212773 -11.029646
v  4.269828 3.243075 -20.308453
v  -4.612760 0.762040 -20.515268
v  5.184264 4.062634 -23.413946
v  -3.402947 4.812903 -20.308453
v  1.350276 6.440536 -10.375841
v  -3.698958 0.592629 -18.317822
v  -3.698688 2.296035 -15.766305
v  -3.402947 4.069621 -20.308453
v  -0.505659 5.964257 -23.486366
v  5.184329 4.062634 -20.515268
v  -4.317564 4.062634 -23.935226
v  -0.777921 5.964258 -20.811934
v  4.270526 0.592629 -16.061617
v  2.122397 2.333268 -11.025832
v  -4.317449 3.237486 -20.290268
v  5.185297 2.412338 -20.515089
v  -4.612760 2.412337 -20.290268
v  4.495630 0.592629 -18.317822
v  1.124012 5.964258 -20.516621
v  1.372540 5.964257 -23.711845
v  -4.613729 0.762040 -20.810398
v  -3.924062 2.868508 -16.061617
v  5.184264 4.804519 -23.709259
v  -0.800972 5.964257 -23.486366
v  3.974350 2.212773 -11.254646
v  -0.552443 7.742988 -12.207864
v  5.184329 1.587188 -20.290268
v  1.350276 4.349062 -10.375841
v  1.349489 5.964258 -20.516621
v  2.122397 2.333268 -11.251071
v  -1.777915 4.812906 -11.546779
v  5.185297 4.804520 -20.515089
v  1.372540 5.964257 -23.486366
v  4.495630 2.868507 -18.022511
v  -3.698584 2.868508 -16.061617
v  -3.698688 2.296035 -18.317822
v  -1.550828 0.932273 -11.250937
v  4.270256 2.296035 -18.022511
v  -4.317449 4.062634 -20.290268
v  -1.255517 3.734263 -11.251206
v  -0.778707 1.109061 -10.375841
v  5.410297 0.762040 -20.810398
v  -3.924062 1.444332 -15.766305
v  5.184264 0.762039 -23.413944
v  -4.613729 0.762040 -20.515087
v  1.827085 0.932273 -11.250937
v  5.185297 1.587188 -20.810398
v  -4.613729 2.412337 -20.810400
v  4.270391 1.444332 -16.061617
v  2.349483 4.812905 -13.805256
v  -4.613729 4.804520 -20.810400
v  -4.317564 0.762039 -23.935225
v  3.974516 4.069621 -20.533852
v  1.077228 5.964257 -23.486366
v  -3.698688 2.296035 -18.022511
v  -3.402781 1.361070 -11.254646
v  4.270526 0.592629 -18.022511
v  -4.317564 2.412336 -23.935225
v  3.974516 4.069621 -20.308453
v  4.270152 2.868507 -18.317822
v  -0.781553 5.092943 -20.515089
v  5.184264 4.804519 -23.413946
v  -3.698584 2.868507 -18.022511
v  5.184329 1.587189 -20.515266
v  1.827085 2.333268 -11.251071
v  -0.777921 7.742988 -11.972649
v  4.269662 1.361070 -11.029646
v  -1.255517 0.932273 -11.250937
v  -3.699062 2.212773 -11.549778
v  -4.317564 3.237485 -23.935225
v  4.269828 3.243075 -20.533772
v  1.124012 7.742988 -10.610888
v  -3.402781 2.212773 -11.254646
v  -3.698958 0.592630 -13.805411
v  -1.550828 2.333268 -11.025832
v  -3.699062 2.868509 -11.549779
v  3.974516 3.243075 -20.533772
v  -3.402947 4.812903 -20.533930
v  4.889133 4.062634 -23.935226
v  5.184445 1.587188 -23.935225
v  -4.838728 4.062634 -20.515089
v  3.974350 2.868509 -11.254647
v  1.350276 3.269059 -10.375841
v  -1.027324 5.092942 -23.714508
v  -3.698958 0.592630 -16.061617
v  -4.613729 4.804520 -20.515089
v  2.349484 4.812904 -18.022354
v  4.270152 2.868509 -13.510100
v  5.185297 3.237486 -20.810400
v  -3.924062 2.212773 -11.254467
v  4.269662 1.361070 -11.254646
v  -3.699062 2.212773 -11.254467
v  -3.924062 1.361069 -13.805411
v  5.185297 3.237486 -20.515089
v  4.269828 4.812903 -20.533930
v  -3.699062 2.868509 -11.254467
v  4.270630 2.868509 -11.254467
v  4.270526 0.592629 -15.766304
v  4.270152 2.868507 -18.022511
v  -4.612760 2.412338 -20.515268
v  4.495630 1.444332 -18.317822
v  -1.777915 4.812904 -18.022354
v  -4.613729 1.587189 -20.810398
v  3.974516 4.812903 -20.308453
v  4.889017 0.762040 -20.290268
v  1.350276 6.938631 -10.375841
v  5.184329 2.412337 -20.290268
v  -3.698958 0.592630 -13.510099
v  4.495630 2.296035 -18.317822
v  -4.838728 4.804520 -20.515089
v  -3.924062 1.444331 -18.022511
v  -4.612696 0.762039 -23.413944
v  -0.802324 5.092942 -23.714508
v  -4.612696 4.804519 -23.709259
v  -0.778707 6.440536 -10.375841
v  -4.612696 4.062634 -23.709259
v  -4.613729 4.062634 -20.515089
v  2.122397 0.932273 -11.250937
v  5.184445 0.762039 -23.935225
v  -0.778707 3.269059 -10.375841
v  4.270526 0.592630 -13.805411
v  -4.613729 2.412338 -20.515089
v  -1.255517 3.734263 -11.025832
v  -1.550828 2.333268 -11.251071
v  4.495630 2.296035 -16.061617
v  -4.613729 1.587189 -20.515087
v  -4.838728 2.412337 -20.515089
v  5.184329 2.412338 -20.515268
v  -0.802324 5.092942 -23.419197
v  3.974350 1.361070 -11.254646
v  4.495630 0.592630 -13.510099
v  4.889133 3.237485 -23.935225
v  2.122397 3.734263 -11.251206
v  -3.698823 1.361069 -13.510099
v  3.974516 4.812903 -20.533930
v  5.184264 0.762039 -23.709255
v  -3.698259 4.069621 -20.533852
v  -4.612877 1.587188 -23.935225
v  -3.698823 1.444332 -16.061617
v  4.270391 1.444332 -18.317822
v  4.495630 2.868508 -16.061617
v  -3.698093 2.868509 -11.254647
v  4.270526 0.592630 -13.510099
v  4.270630 2.212773 -11.549778
v  -1.550828 3.734263 -11.251206
v  4.889017 1.587189 -20.515266
v  -3.698093 2.212773 -11.254646
v  -3.924062 0.592630 -13.510099
v  -4.612696 4.804519 -23.413946
v  1.124012 7.742988 -10.375672
v  -4.612760 3.237486 -20.515268
v  -3.924062 1.361070 -11.549778
v  5.185297 4.062634 -20.810400
v  1.373893 5.092942 -23.714508
v  -3.699062 1.361070 -11.254467
v  5.184264 3.237485 -23.709255
v  -3.698823 1.444332 -15.766305
v  5.184264 3.237485 -23.413944
v  -4.838728 3.237486 -20.810400
v  -1.255517 2.333268 -11.251071
v  4.889017 2.412338 -20.515268
v  -4.612696 3.237485 -23.709255
v  4.270152 2.868508 -16.061617
v  -0.552443 7.742988 -11.972649
v  -1.550828 0.932273 -11.025832
v  -4.838728 0.762040 -20.810398
v  -4.612696 1.587188 -23.709255
v  -3.698958 0.592629 -18.022511
v  1.827085 3.734263 -11.251206
v  4.495630 1.444332 -15.766306
v  1.350276 1.109061 -10.375841
v  1.349489 7.742988 -12.207864
v  -4.317449 0.762040 -20.515268
v  1.078761 5.092942 -23.940477
v  -3.699062 1.361070 -11.549778
v  -0.552443 5.964258 -20.811934
v  -3.924062 0.592630 -15.766305
v  -3.924062 2.868509 -11.254467
v  2.122397 3.734263 -11.025832
v  4.495630 2.868509 -11.254467
v  4.270630 2.212773 -11.254467
v  -4.612696 4.062634 -23.413946
v  1.373893 5.092942 -23.419197
v  -4.317449 4.804520 -20.290268
v  -3.698093 0.509367 -11.029646
v  2.349483 4.812906 -11.251467
v  5.185297 1.587189 -20.515087
v  4.269662 0.509367 -11.029646
v  -3.698259 4.812903 -20.533930
v  5.410297 1.587188 -20.515087
v  5.410297 2.412337 -20.810400
v  -0.777921 7.742988 -10.610888
v  -1.777915 4.812904 -16.061460
v  4.270391 1.444332 -18.022511
v  4.270391 1.361069 -13.510099
v  -4.612696 1.587188 -23.413944
v  -3.698584 2.868508 -15.766306
v  4.495630 1.361070 -11.549778
v  2.349483 4.812904 -16.061460
v  4.270256 2.296035 -18.317822
v  -0.552443 7.742988 -10.375672
v  5.185297 4.062634 -20.515089
v  -3.698093 1.361070 -11.029646
v  1.124012 7.742988 -12.207864
v  1.056842 5.092943 -20.290268
v  4.269662 2.868509 -11.029647
v  1.598893 5.092942 -23.714508
v  -3.402781 2.868509 -11.254647
v  -3.698259 3.243075 -20.533772
v  4.889133 4.804519 -23.935226
v  1.349489 7.742988 -10.375672
v  5.184264 1.587188 -23.709255
v  1.353122 5.092943 -20.515089
v  -1.006553 5.092943 -20.515089
v  5.184329 3.237486 -20.515268
v  -4.317449 4.804520 -20.515268
v  4.270630 1.361070 -11.549778
v  5.410297 4.804520 -20.515089
v  4.270152 2.868508 -15.766306
v  1.827085 0.932273 -11.025832
v  -4.317564 4.804519 -23.935226
v  -3.924062 2.212772 -13.510100
v  1.350276 5.435492 -10.375841
v  4.270526 0.592629 -18.317822
v  -3.924062 0.592629 -18.022511
v  4.270391 1.444332 -15.766306
v  5.184329 4.804520 -20.515268
v  -3.698584 2.868509 -13.510100
v  -4.317449 1.587188 -20.290268
v  5.185297 0.762040 -20.810398
v  -4.612696 2.412336 -23.709255
v  -4.612760 1.587189 -20.515266
v  -1.550828 4.812906 -11.025833
v  4.269828 4.069621 -20.533852
v  -4.612760 4.804520 -20.515268
v  -4.317449 1.587189 -20.515266
v  -3.402947 3.243075 -20.533772
v  5.410297 4.062634 -20.515089
v  4.889017 4.062634 -20.290268
v  -0.507192 5.092942 -23.715477
v  -3.924062 2.296035 -15.766305
v  -3.402947 3.243075 -20.308453
v  -3.924062 2.868507 -18.022511
v  5.185297 0.762040 -20.515087
v  4.495630 2.212773 -11.549778
v  4.269662 2.868509 -11.254647
v  5.410297 3.237486 -20.515089
v  5.184445 2.412336 -23.935225
v  4.495630 1.361069 -13.510099
v  -4.317449 2.412338 -20.515268
v  -3.924062 2.868509 -13.510100
v  5.184264 2.412336 -23.709255
v  4.495630 0.592629 -16.061617
v  5.184264 1.587188 -23.413944
v  1.078761 5.092942 -23.715477
v  4.270630 1.361070 -11.254467
v  1.056842 5.092943 -20.515268
v  -0.781553 5.092943 -20.810400
v  -4.612696 0.762039 -23.709255
v  -0.485273 5.092943 -20.515268
v  1.352153 5.092943 -20.515268
v  -0.780585 5.092943 -20.515268
v  1.353122 5.092943 -20.810400
v  -1.552437 4.812905 -13.509944
v  2.124006 4.812904 -15.766150
v  -1.552437 4.812904 -15.766150
v  -1.552437 4.812904 -18.022354
v  2.124005 4.812905 -13.509944
v  2.124006 4.812904 -18.022354
v  3.974350 0.509367 -11.254646
v  -3.402781 0.509367 -11.254646
v  -3.699062 0.509367 -11.254466
v  4.270630 0.509367 -11.254466
v  -4.853864 1.587188 -23.397778
v  -4.301397 3.237485 -23.694057
v  -4.301397 1.587188 -23.694057
v  5.425433 0.762039 -23.397778
v  4.872966 2.412336 -23.694057
v  4.872966 0.762039 -23.694057
v  -4.301397 4.062634 -23.694057
v  -4.853864 0.762039 -23.397778
v  -4.301397 0.762039 -23.694057
v  4.872966 1.587188 -23.694057
v  -4.853864 3.237485 -23.397778
v  -4.853864 4.804519 -23.397779
v  -4.301397 2.412336 -23.694057
v  -4.301397 4.804519 -23.694057
v  4.872966 4.804519 -23.694057
v  4.872966 4.062634 -23.694057
v  4.872966 3.237485 -23.694057
v  5.425433 3.237485 -23.397778
v  -4.853864 2.412337 -23.397778
v  5.425433 4.804519 -23.397779
v  5.425433 1.587188 -23.397778
v  -4.853864 4.062634 -23.397779
v  5.425433 4.062634 -23.397779
v  5.425433 2.412337 -23.397778
v  -4.837697 0.762039 -23.709255
v  5.409266 4.062634 -23.709259
v  5.409266 0.762039 -23.709255
v  -4.837697 4.062634 -23.709259
v  -4.837697 2.412336 -23.709255
v  5.409266 2.412336 -23.709255
v  5.409266 3.237485 -23.709255
v  -4.837697 3.237485 -23.709255
v  5.409266 1.587187 -23.709255
v  -4.837697 4.804519 -23.709259
v  5.409266 4.804519 -23.709259
v  -4.837697 1.587187 -23.709255
v  -3.940229 2.212772 -13.821579
v  4.495630 2.212772 -13.805412
v  -3.698688 2.212772 -13.805411
v  4.270256 2.212772 -13.805412
v  4.495630 0.509366 -11.549778
v  4.270789 2.189062 -11.253489
v  -3.699220 2.189061 -11.253489
v  1.350269 3.049320 -11.260997
v  -0.778700 3.049320 -11.260997
v  -0.808082 6.986866 -12.238448
v  5.185232 2.345429 -21.771786
v  -4.613664 2.345429 -21.771786
v  5.227602 0.731030 -23.978380
v  5.227602 3.206476 -23.978380
v  -4.656033 3.206476 -23.978380
v  -4.656033 0.731030 -23.978380
v  -1.524785 4.843914 -13.832908
v  2.096354 4.843914 -13.832908
v  -1.524785 4.843912 -18.345318
v  2.096354 4.843912 -18.345318
v  -4.656033 3.206477 -23.667068
v  5.227602 4.031626 -23.667072
v  -4.656033 1.556179 -23.667068
v  5.227602 2.381328 -23.667068
v  -4.656033 4.031626 -23.667072
v  -4.656033 0.731030 -23.667068
v  5.227602 1.556179 -23.667068
v  5.227602 0.731031 -23.667068
v  -4.656033 2.381328 -23.667068
v  5.227602 4.835527 -23.667072
v  -4.656033 4.835527 -23.667072
v  5.227602 3.206477 -23.667068
v  5.156612 2.381328 -23.386292
v  -4.585043 4.031626 -23.386295
v  5.156612 4.031626 -23.386295
v  5.156612 4.835527 -23.386295
v  -4.585043 1.556179 -23.386292
v  -4.585043 0.731030 -23.386292
v  -0.774672 5.123950 -23.391546
v  -4.585043 4.835527 -23.386295
v  5.156612 3.206477 -23.386292
v  1.346241 5.123950 -23.391546
v  5.156612 0.731031 -23.386292
v  -4.585043 3.206477 -23.386292
v  5.156612 1.556179 -23.386292
v  -4.585043 2.381328 -23.386292
v  -0.478007 5.995265 -23.458715
v  1.049576 5.995265 -23.458715
v  4.538786 1.392078 -13.848567
v  -3.967218 1.392077 -13.848567
v  -3.741250 0.540375 -11.297802
v  4.312818 0.540375 -11.297802
v  -4.289913 0.731030 -23.962877
v  -4.289913 3.206476 -23.962877
v  -3.375295 3.212066 -20.280800
v  -3.375129 0.540375 -11.001994
v  3.946864 4.038612 -20.280800
v  5.437950 1.556180 -20.838051
v  3.946864 3.212066 -20.280800
v  4.861481 4.031625 -23.962879
v  -4.866381 4.031627 -20.487438
v  5.437950 2.381329 -20.838053
v  -3.951714 1.392078 -13.482448
v  -0.806360 6.471544 -10.348189
v  -1.805567 4.843913 -17.994701
v  -3.951714 2.265026 -17.994860
v  -4.866381 1.556180 -20.487436
v  -0.806360 5.466500 -10.348189
v  -3.951714 0.561621 -15.738652
v  -4.289796 3.206478 -20.262617
v  -4.866381 2.381329 -20.487438
v  4.523283 0.561621 -16.089268
v  1.377928 7.246772 -10.348189
v  4.523283 1.392078 -13.482448
v  -4.289913 4.031625 -23.962879
v  -3.951714 2.243781 -13.482448
v  -4.289796 1.556180 -20.262615
v  5.437950 4.031627 -20.487438
v  5.437950 4.835528 -20.487438
v  -4.866381 3.206478 -20.487438
v  2.377136 4.843913 -17.994701
v  -3.951714 1.413324 -15.738653
v  -4.866381 4.835528 -20.487438
v  -3.375295 4.038612 -20.280800
v  -3.951714 2.899516 -17.994860
v  4.523283 2.899516 -17.994860
v  -3.951714 2.265027 -15.738653
v  5.437950 3.206478 -20.487438
v  4.861481 0.731030 -23.962877
v  -4.570572 2.381329 -20.471933
v  -4.570572 3.206478 -20.471933
v  -3.741250 1.392078 -11.297802
v  5.142141 2.381329 -20.471933
v  4.538786 2.265027 -15.723149
v  -4.881885 2.381329 -20.853558
v  -3.967218 1.413323 -17.979355
v  1.417227 5.123950 -23.983631
v  -3.655667 1.413323 -17.979355
v  -4.569540 4.031626 -23.752415
v  -3.741250 2.243781 -10.986490
v  -3.741250 2.899518 -10.986491
v  -3.655532 2.265027 -16.104773
v  4.227100 2.265027 -16.104773
v  4.227100 2.243781 -13.466944
v  -4.881885 0.731031 -20.471931
v  4.538786 2.243781 -13.466944
v  -0.442117 5.123951 -20.558426
v  2.080850 4.843913 -16.104616
v  -3.655802 0.561621 -15.723148
v  -3.741416 4.843912 -20.577087
v  -3.741416 4.038612 -20.577007
v  -1.509281 4.843913 -16.104616
v  4.538786 2.899517 -13.848568
v  1.080856 7.773996 -11.929493
v  -0.821863 4.380070 -10.332685
v  -3.741250 2.899518 -11.297803
v  -4.274292 4.031627 -20.558426
v  -3.359791 4.038612 -20.577007
v  5.227485 4.835528 -20.247112
v  2.080850 4.843913 -15.722993
v  -3.967218 0.540375 -11.211310
v  -0.821077 7.773996 -12.251019
v  1.080856 5.995266 -20.473465
v  4.312818 2.243781 -11.297802
v  -4.656033 4.835527 -23.978382
v  -3.655802 0.561621 -18.360977
v  -3.655532 2.265027 -15.723149
v  5.142141 4.835528 -20.853558
v  -3.741416 3.212067 -20.265297
v  5.141108 0.731031 -23.752413
v  4.845861 4.031627 -20.558426
v  -0.821863 1.140069 -10.332685
v  5.453454 0.731031 -20.853556
v  1.783929 0.963282 -10.982676
v  -1.509281 4.843913 -15.722993
v  -3.967218 2.265026 -18.360979
v  2.165553 2.364277 -10.982676
v  4.538786 2.265026 -17.979355
v  3.931194 2.899518 -11.297803
v  4.227370 0.561621 -16.104773
v  -3.655906 1.392078 -11.211310
v  -3.967218 2.899516 -15.723149
v  3.931194 2.243781 -11.297802
v  4.538786 2.899515 -18.360979
v  1.393432 4.380070 -10.332685
v  5.227485 2.381329 -20.247112
v  3.931194 2.899518 -10.986491
v  -4.570572 0.731031 -20.853556
v  -1.049709 5.123951 -20.853558
v  -3.359626 1.392078 -11.297802
v  -4.274292 4.031627 -20.247112
v  -3.741250 2.243781 -11.297802
v  5.142141 4.835528 -20.471933
v  4.227100 2.265026 -17.979355
v  -0.821863 6.969639 -10.332685
v  -4.655916 4.031627 -20.558426
v  5.141108 4.835527 -23.752415
v  -1.821071 4.843914 -11.589935
v  -4.274292 0.731031 -20.558424
v  -3.655532 2.265026 -18.360979
v  -4.570572 4.835528 -20.853558
v  2.392639 4.843914 -13.848412
v  -3.655802 0.561621 -13.848567
v  3.931360 3.212067 -20.576927
v  -4.570572 0.731031 -20.471931
v  -3.359791 4.843912 -20.577087
v  -0.738397 5.123951 -20.853558
v  -4.655916 1.556180 -20.558424
v  2.165553 2.364277 -11.294228
v  4.538786 0.561621 -17.979355
v  4.312984 3.212066 -20.576927
v  4.227370 0.561621 -17.979355
v  -1.821071 4.843913 -15.722993
v  3.931360 4.038612 -20.577007
v  5.453454 3.206477 -20.853558
v  -1.593984 4.843914 -10.982677
v  -3.967218 2.243781 -11.592935
v  3.931194 0.540375 -10.986490
v  1.309966 5.123951 -20.471933
v  -3.655532 2.265026 -17.979355
v  -3.967218 2.899518 -11.592935
v  5.227485 1.556180 -20.558424
v  4.227474 2.243781 -11.211310
v  1.783929 2.364277 -11.294228
v  -4.655916 2.381329 -20.558426
v  -0.738397 5.123951 -20.471933
v  -0.509287 5.995266 -20.855089
v  5.227485 4.031627 -20.558426
v  4.226996 2.899515 -18.360979
v  4.226996 2.899517 -13.466944
v  -3.359626 2.243781 -11.297802
v  -1.593984 2.364277 -10.982676
v  1.080856 7.773996 -10.654043
v  5.227485 0.731032 -20.247112
v  -3.655906 2.243781 -11.592935
v  -4.656033 4.031625 -23.978382
v  4.312818 1.392078 -10.986490
v  -1.212361 3.765272 -11.294362
v  -3.655802 0.561621 -16.104773
v  -4.570572 4.835528 -20.471933
v  -3.655906 2.899518 -11.592935
v  4.312984 4.843912 -20.265297
v  5.142141 3.206478 -20.471933
v  5.142141 3.206477 -20.853558
v  1.393432 6.471544 -10.332685
v  5.227485 3.206478 -20.247112
v  4.227474 2.243781 -11.592935
v  4.538786 2.243781 -11.211310
v  4.538786 0.561621 -15.723149
v  4.227370 0.561621 -15.723149
v  -4.569540 0.731030 -23.752413
v  4.538786 1.413323 -18.360977
v  -4.655916 0.731031 -20.558424
v  -4.881885 1.556180 -20.853556
v  4.226996 2.899516 -17.979355
v  -3.655906 2.243781 -11.211310
v  4.312818 1.392078 -11.297802
v  -4.569540 4.835527 -23.752415
v  -0.509287 5.995266 -20.473465
v  -3.655906 2.899518 -11.211311
v  -0.821077 5.995266 -20.855089
v  4.312984 4.843912 -20.577087
v  1.395309 5.123951 -20.247112
v  4.538786 1.392078 -11.592935
v  -4.570572 1.556180 -20.853556
v  -3.359626 1.392078 -10.986490
v  -1.593984 3.765272 -11.294362
v  5.142141 1.556180 -20.853556
v  -1.593984 2.364277 -11.294228
v  -4.881885 4.031626 -20.853558
v  4.845861 1.556180 -20.247112
v  -0.442117 5.123951 -20.247112
v  -0.821863 3.300068 -10.332685
v  2.165553 3.765272 -10.982676
v  4.845861 1.556180 -20.558424
v  4.845861 4.031627 -20.247112
v  5.142141 0.731031 -20.471931
v  -3.655428 2.899516 -15.723149
v  4.227474 0.540375 -11.592934
v  4.227370 0.561621 -13.848567
v  3.931194 1.392078 -11.297802
v  -0.509287 7.773996 -12.251019
v  -4.570572 2.381329 -20.853558
v  2.392640 4.843912 -18.360821
v  1.080856 7.773996 -10.332516
v  -4.570572 1.556180 -20.471931
v  5.142141 0.731031 -20.853556
v  -3.967218 2.899515 -18.360979
v  5.227485 2.381329 -20.558426
v  -4.569540 3.206477 -23.752413
v  4.227474 2.899518 -11.592935
v  -3.967218 0.561621 -13.848567
v  3.931360 4.843912 -20.577087
v  -3.359791 4.843912 -20.265297
v  4.227235 1.413323 -18.360977
v  1.393432 2.220070 -10.332685
v  4.227100 2.265027 -15.723149
v  4.227370 0.561621 -13.466944
v  -3.655667 1.392078 -13.466944
v  2.165553 3.765272 -11.294362
v  -3.655428 2.899516 -16.104773
v  -3.655667 1.413324 -16.104773
v  1.395309 5.123951 -20.558426
v  -4.881885 3.206477 -20.853558
v  4.227474 2.899518 -11.211311
v  1.783929 0.963282 -11.294092
v  4.538786 2.899516 -16.104773
v  1.393432 5.466500 -10.332685
v  5.141108 3.206477 -23.752413
v  -4.274292 2.381329 -20.558426
v  -1.821071 4.843914 -13.466788
v  -4.570572 4.031626 -20.853558
v  -1.212361 2.364277 -11.294228
v  -4.655916 0.731032 -20.247112
v  -0.823741 5.123951 -20.558426
v  4.227370 0.561621 -18.360977
v  4.845861 2.381329 -20.558426
v  -4.655916 3.206478 -20.558426
v  -3.967218 1.392078 -11.592935
v  5.142141 4.031626 -20.853558
v  4.227100 2.265026 -18.360979
v  -3.655802 0.561621 -17.979355
v  5.227602 4.835527 -23.978382
v  -3.655667 1.413324 -15.723149
v  -4.655916 4.835528 -20.247112
v  1.783929 3.765272 -11.294362
v  1.393432 1.140069 -10.332685
v  2.392639 4.843914 -11.589935
v  4.226996 2.899516 -16.104773
v  1.013686 5.123951 -20.558426
v  -4.570572 4.031627 -20.471933
v  -0.509287 7.773996 -11.929493
v  -4.569540 1.556179 -23.752413
v  -3.655428 2.899515 -18.360979
v  4.312818 2.243781 -10.986490
v  -3.967218 1.413323 -16.104773
v  -4.274292 3.206478 -20.558426
v  -3.655906 1.392078 -11.592935
v  1.309966 5.123951 -20.853558
v  -1.593984 0.963281 -10.982676
v  -4.655916 4.835528 -20.558426
v  1.392646 7.773996 -12.251019
v  5.142141 4.031627 -20.471933
v  5.227602 4.031625 -23.978382
v  -3.359626 2.899518 -11.297803
v  4.538786 0.561621 -13.466944
v  -3.967218 2.899517 -13.848568
v  4.227235 1.413324 -16.104773
v  -3.967218 0.561621 -17.979355
v  4.227235 1.392078 -13.466944
v  4.227235 1.413323 -17.979355
v  -4.570572 3.206477 -20.853558
v  -1.593984 0.963281 -11.294092
v  -3.967218 0.561621 -16.104773
v  4.226996 2.899516 -15.723149
v  4.312984 4.038612 -20.577007
v  1.393432 3.300068 -10.332685
v  1.080856 7.773996 -12.251019
v  2.165553 0.963282 -11.294092
v  4.845861 3.206478 -20.558426
v  -3.741416 3.212066 -20.576927
v  -4.569540 2.381328 -23.752413
v  -1.212361 0.963281 -11.294092
v  5.141108 1.556179 -23.752413
v  4.227474 1.392078 -11.592935
v  5.227485 3.206478 -20.558426
v  -4.274292 4.835528 -20.558426
v  -4.655916 3.206478 -20.247112
v  -3.655428 2.899517 -13.466944
v  5.453454 1.556180 -20.471931
v  5.227485 4.835528 -20.558426
v  -0.464036 5.123950 -23.672321
v  -1.070480 5.123950 -23.757664
v  4.227235 1.413324 -15.723149
v  1.621278 5.123951 -20.471933
v  5.453454 2.381329 -20.471933
v  -3.359791 3.212067 -20.576927
v  -3.655428 2.899516 -17.979355
v  -0.845658 5.123950 -23.983631
v  -0.509287 7.773996 -10.654043
v  -3.655532 2.243781 -13.466944
v  -3.655802 0.561621 -13.466944
v  -3.741250 0.540375 -10.986490
v  -3.655532 2.243781 -13.848568
v  1.393432 6.969639 -10.332685
v  -3.967218 2.243781 -13.848568
v  4.312818 2.899518 -11.297803
v  -4.655916 1.556180 -20.247112
v  4.845861 0.731031 -20.558424
v  5.453454 4.031626 -20.853558
v  -3.967218 2.265027 -16.104773
v  5.227485 0.731031 -20.558424
v  4.312984 4.038612 -20.265297
v  -0.821863 2.220070 -10.332685
v  -1.821071 4.843912 -18.360821
v  4.227474 1.392078 -11.211310
v  5.141108 4.031626 -23.752415
v  -4.881885 4.835528 -20.853558
v  -4.274292 1.556180 -20.558424
v  5.453454 4.835528 -20.853558
v  -3.741416 4.038612 -20.265297
v  5.141108 2.381328 -23.752413
v  4.312984 3.212067 -20.265297
v  4.845861 4.835528 -20.558426
v  -0.509287 7.773996 -10.332516
v  1.035604 5.123950 -23.672321
v  4.538786 0.540375 -11.211310
v  4.538786 1.413323 -16.104773
v  1.080856 5.995266 -20.855089
v  5.142141 2.381329 -20.853558
v  -3.655906 0.540375 -11.592934
v  4.227100 2.243781 -13.848568
v  -4.656033 1.556179 -23.978380
v  2.165553 4.843914 -10.982677
v  -3.655667 1.413323 -18.360977
v  5.142141 1.556180 -20.471931
v  -0.821077 7.773996 -10.332516
v  -1.524785 4.843914 -13.482292
v  2.096353 4.843914 -13.482292
v  -1.524785 4.843913 -17.994701
v  2.096354 4.843913 -17.994701
v  4.242978 0.540375 -11.226814
v  -3.671410 0.540375 -11.226814
v  3.931194 0.540375 -11.297802
v  -3.359626 0.540375 -11.297802
v  4.242739 1.392078 -13.833064
v  -3.671171 1.392077 -13.833064
v  5.436918 4.835527 -23.736912
v  -0.828624 5.995265 -23.458714
v  -4.865349 4.835527 -23.736912
v  1.400192 5.995265 -23.458714
v  -0.479540 5.123950 -23.968128
v  -4.865349 2.381328 -23.736908
v  -4.865349 4.031626 -23.736912
v  5.436918 2.381328 -23.736908
v  5.436918 4.031626 -23.736912
v  5.436918 1.556179 -23.736908
v  5.436918 0.731031 -23.736908
v  -4.865349 3.206477 -23.736908
v  -4.865349 0.731030 -23.736908
v  -4.865349 1.556179 -23.736908
v  5.436918 3.206477 -23.736908
v  1.626545 5.123950 -23.391544
v  -4.880853 0.731030 -23.370789
v  5.452422 3.206477 -23.370789
v  -4.880853 4.031626 -23.370789
v  5.452422 4.031626 -23.370789
v  -4.880853 1.556179 -23.370789
v  5.452422 2.381328 -23.370789
v  5.452422 0.731031 -23.370789
v  5.452422 1.556179 -23.370789
v  5.452422 4.835527 -23.370789
v  -4.880853 3.206477 -23.370789
v  -4.880853 2.381328 -23.370789
v  -4.880853 4.835527 -23.370789
v  -4.274409 3.206477 -23.667067
v  -4.274409 1.556179 -23.667067
v  4.845977 0.731031 -23.667067
v  4.845977 2.381328 -23.667067
v  -4.274409 4.031626 -23.667068
v  -4.274409 0.731030 -23.667067
v  -4.274409 4.835527 -23.667068
v  -4.274409 2.381328 -23.667067
v  4.845977 1.556179 -23.667067
v  4.845977 4.835527 -23.667068
v  4.845977 4.031626 -23.667068
v  4.845977 3.206477 -23.667067
v  -0.478007 5.995265 -23.739498
v  4.242500 2.899517 -13.833064
v  -3.670932 2.899517 -13.833064
v  4.861481 2.381328 -23.962877
v  -4.289913 2.381328 -23.962877
v  4.861481 1.556179 -23.962877
v  1.401723 5.123950 -23.687824
v  -4.289913 1.556179 -23.962877
v  -0.830154 5.123950 -23.687824
v  -0.774672 5.123950 -23.742161
v  1.346241 5.123950 -23.742161
v  4.298432 2.220070 -11.225758
v  -3.726863 2.220070 -11.225758
v  -0.741063 4.049977 -24.674685
v  1.312631 4.049977 -24.674685
v  1.367592 2.272325 -11.253489
v  -0.796023 2.272325 -11.253489
v  0.420471 6.837667 -10.443367
v  -4.553664 3.974414 -21.429543
v  5.125232 3.974414 -21.429544
v  0.285791 2.220070 -10.332685
v  -1.212361 4.843914 -11.251311
v  2.153061 4.363190 -20.514265
v  2.124005 4.843914 -11.208311
v  -1.581493 4.363190 -20.514265
v  -1.552437 4.843914 -11.208311
v  1.783929 4.843914 -11.251311
v  0.285777 2.220070 -10.332685
v  -4.613664 3.973726 -22.983953
v  5.185232 3.973726 -22.983953
v  -1.579074 3.334203 -11.350576
v  2.150642 3.334203 -11.350576
v  -1.944568 3.240019 -11.350576
v  2.516137 3.240019 -11.350576
v  2.310649 3.283121 -11.350576
v  -1.739081 3.283121 -11.350576
v  -2.144718 3.183639 -11.350576
v  2.716287 3.183639 -11.350576
v  2.932497 3.130900 -11.350576
v  -2.360928 3.130900 -11.350576
v  3.349405 3.018563 -11.350576
v  -2.777837 3.018563 -11.350576
v  3.140938 3.051922 -11.350576
v  -2.569370 3.051922 -11.350576
v  -4.553664 3.966694 -22.788837
v  5.125232 3.966694 -22.788837
v  3.841039 2.920952 -11.290576
v  -3.269471 2.920952 -11.290576
v  0.501117 6.273035 -10.443367
v  -0.013594 6.157357 -10.443367
v  -0.043322 6.017496 -10.375868
v  0.022349 6.219613 -10.375868
v  0.549219 6.219613 -10.375868
v  -0.718700 5.761956 -11.523806
v  1.290269 5.761956 -11.523806
v  -0.718700 6.157357 -11.607849
v  1.290269 6.157357 -11.607849
v  1.290269 5.946002 -11.630064
v  -0.718700 5.946002 -11.630064
v  -0.718700 5.946002 -10.957367
v  1.290269 5.946002 -10.957367
v  5.185232 2.167187 -22.610350
v  1.350269 5.878001 -10.980408
v  -0.778700 5.878001 -10.980408
v  -0.778700 5.878002 -11.607023
v  1.350269 5.878002 -11.607023
v  -0.778700 5.720486 -11.465195
v  1.350269 5.720486 -11.465195
v  1.350269 6.088800 -11.629179
v  -0.778700 6.088800 -11.629179
v  5.185232 3.966038 -21.234463
v  -4.613664 3.966038 -21.234463
v  0.285784 7.021914 -10.375868
v  0.338243 6.937656 -10.375868
v  -4.269162 3.863802 -20.514721
v  4.840731 3.863802 -20.514721
v  3.535332 3.863801 -20.514614
v  -2.963763 3.863801 -20.514614
v  -1.478330 4.069130 -14.814690
v  2.049898 4.069130 -14.814690
v  -1.478330 4.069130 -12.479644
v  2.049898 4.069130 -12.479644
v  -1.478330 4.069130 -19.560455
v  -1.478330 4.069130 -17.149734
v  2.049898 4.069130 -17.149734
v  2.049898 4.069130 -19.560455
v  -4.099614 4.069129 -20.574722
v  4.671182 4.069129 -20.574722
v  3.365775 4.069128 -20.574589
v  -2.794206 4.069128 -20.574589
v  2.109898 4.061899 -12.244779
v  -1.538330 4.061899 -12.244779
v  2.109898 4.061899 -14.579823
v  -1.538330 4.061899 -14.579823
v  2.109898 4.061899 -19.325592
v  3.130919 4.061898 -20.514553
v  -2.559350 4.061898 -20.514553
v  -1.538330 4.061899 -19.325592
v  2.109898 4.061899 -16.914869
v  -1.538330 4.061899 -16.914869
v  4.436317 4.061898 -20.514721
v  -3.864749 4.061898 -20.514721
v  1.350269 1.919194 -10.850376
v  -0.778700 1.919194 -10.850376
v  1.049576 5.995265 -23.739498
v  4.861365 0.731031 -20.262615
v  -1.054976 5.123950 -23.391544
v  -1.227865 0.963281 -10.998180
v  4.861481 3.206476 -23.962877
v  2.377136 4.843913 -16.089113
v  1.377919 2.220070 -11.233238
v  -0.806350 2.220070 -11.233238
v  4.861365 2.381329 -20.262617
v  -4.289796 2.381329 -20.262617
v  4.523283 2.899518 -11.577431
v  -3.951714 0.561621 -18.345474
v  -1.578481 3.765272 -10.998180
v  1.605774 5.123951 -20.838053
v  1.377142 7.773996 -10.348021
v  -0.805573 7.773996 -11.944997
v  4.523283 0.540375 -11.577430
v  4.523283 1.413324 -15.738653
v  1.377142 5.995266 -20.839586
v  1.377142 7.773996 -11.944997
v  2.151539 3.334095 -11.290576
v  -1.579971 3.334095 -11.290576
v  2.517034 3.239911 -11.290576
v  -1.945465 3.239911 -11.290576
v  2.311546 3.283013 -11.290576
v  -1.739978 3.283013 -11.290576
v  2.717184 3.183531 -11.290576
v  -2.145615 3.183531 -11.290576
v  3.548488 2.939841 -11.350576
v  -2.976920 2.939841 -11.350576
v  0.377288 6.344528 -10.375868
v  -0.821863 5.466500 -12.252230
v  -0.796023 7.021894 -12.226389
v  -0.796023 7.299027 -12.226389
v  1.393432 7.246772 -12.252230
v  -0.796023 6.523799 -12.226389
v  -4.553664 3.627547 -21.585077
v  5.125232 3.627547 -21.585077
v  5.185232 3.627487 -23.139172
v  -4.613664 3.627487 -23.139172
v  1.379641 2.237297 -11.231511
v  -0.808072 2.237297 -11.231511
v  -3.669448 1.409304 -13.834786
v  4.241016 1.409304 -13.834786
v  5.213821 0.713803 -23.964600
v  -4.642252 3.189250 -23.964600
v  5.213821 3.189250 -23.964600
v  -4.642252 0.713803 -23.964600
v  2.080849 4.861141 -13.848412
v  -1.509281 4.861141 -13.848412
v  -1.509281 4.861139 -18.360821
v  2.080850 4.861139 -18.360821
v  -4.642252 3.189250 -23.680851
v  -4.642252 1.538952 -23.680851
v  5.213821 0.713803 -23.680851
v  5.213821 4.014399 -23.680853
v  -4.642252 4.014399 -23.680853
v  5.213821 2.364101 -23.680851
v  -4.642252 0.713804 -23.680851
v  5.213821 1.538952 -23.680851
v  -4.642252 2.364101 -23.680851
v  5.213821 4.852755 -23.680853
v  -4.642252 4.852755 -23.680853
v  5.213821 3.189250 -23.680851
v  5.141108 2.364101 -23.370789
v  5.141108 4.014399 -23.370792
v  5.141108 4.852755 -23.370792
v  5.141108 0.713803 -23.370789
v  -4.569540 0.713804 -23.370789
v  -4.569540 4.014399 -23.370792
v  -0.759168 5.141178 -23.376041
v  -4.569540 1.538952 -23.370789
v  -4.569540 4.852755 -23.370792
v  5.141108 3.189250 -23.370789
v  1.330737 5.141178 -23.376041
v  5.141108 1.538952 -23.370789
v  -4.569540 3.189250 -23.370789
v  -4.569540 2.364101 -23.370789
v  -0.462503 6.012492 -23.443211
v  1.034072 6.012492 -23.443211
v  -3.967218 2.247800 -15.723149
v  -1.821071 4.861139 -17.979198
v  -4.274292 3.189251 -20.247112
v  5.453454 4.014400 -20.471933
v  -4.274292 0.713805 -20.247112
v  3.931360 3.194839 -20.265297
v  2.392640 4.861139 -17.979198
v  5.227485 4.852756 -20.247112
v  -3.967218 0.544394 -16.104773
v  4.538786 1.396097 -15.723149
v  4.538786 0.544394 -13.848567
v  4.538786 2.261008 -11.211310
v  5.227485 2.364102 -20.247112
v  4.538786 1.396096 -17.979355
v  -4.656033 4.852754 -23.978382
v  1.392646 6.012493 -20.473465
v  -0.821077 6.012493 -20.473465
v  -0.464036 5.141178 -23.672321
v  2.392640 4.861139 -16.104616
v  -3.967218 1.409304 -13.466944
v  4.538786 2.916744 -13.466944
v  -3.967218 2.261008 -13.466944
v  -4.274292 1.538953 -20.247112
v  -3.359791 3.194839 -20.265297
v  5.453454 2.364102 -20.471933
v  -4.655916 2.364102 -20.247112
v  -3.967218 1.396097 -15.723149
v  4.845861 1.538953 -20.247112
v  1.013686 5.141178 -20.247112
v  -4.881885 4.014400 -20.471933
v  4.538786 2.916743 -17.979355
v  5.453454 4.852756 -20.471933
v  3.931360 4.021385 -20.265297
v  -3.967218 2.916743 -17.979355
v  4.538786 2.261008 -13.466944
v  5.453454 3.189251 -20.471933
v  -4.881885 1.538953 -20.471931
v  -3.359791 4.021385 -20.265297
v  4.538786 2.247800 -15.723149
v  5.453454 1.538953 -20.471931
v  1.035604 5.141178 -23.672321
v  -4.656033 4.014399 -23.978382
v  4.845977 0.713803 -23.978382
v  -4.274409 0.713803 -23.978382
v  -4.274409 3.189250 -23.978382
v  4.845977 3.189250 -23.978382
v  5.213821 2.364101 -23.964600
v  1.379651 5.483727 -10.346466
v  -0.809959 5.141178 -20.544643
v  1.378864 6.012493 -20.841309
v  -0.807296 7.791223 -10.346298
v  -1.807290 4.861141 -13.480569
v  5.213704 4.014400 -20.544643
v  -4.584354 3.189251 -20.485714
v  -3.727468 1.409305 -11.284021
v  -3.669448 1.396096 -17.993135
v  -3.727468 2.916744 -11.000273
v  4.240881 2.247800 -16.090992
v  1.403446 5.141178 -23.969852
v  1.344518 5.141178 -23.743883
v  -3.669313 2.247800 -16.090992
v  -4.868104 0.713804 -20.485714
v  2.094631 4.861139 -16.090836
v  4.240881 2.261008 -13.480725
v  -3.727634 4.021385 -20.563225
v  -1.523062 4.861139 -16.090836
v  -3.669583 0.544394 -15.736930
v  4.859642 4.014400 -20.260895
v  -3.727634 4.861138 -20.563305
v  -3.669209 2.916743 -16.090992
v  2.151772 4.861141 -10.996458
v  4.241152 0.544394 -18.347197
v  -0.831877 5.141178 -23.969852
v  -0.808082 4.397297 -10.346466
v  -0.455898 5.141178 -20.260895
v  -3.669583 0.544395 -13.480724
v  5.439672 3.189250 -20.839775
v  4.859642 4.014400 -20.544643
v  -3.373573 4.021386 -20.563225
v  4.525005 2.916744 -11.579153
v  -4.288074 4.014400 -20.544643
v  4.240881 2.247800 -15.736930
v  -3.727468 2.261008 -11.000272
v  -3.953437 0.557602 -11.225092
v  1.797711 0.980508 -10.996457
v  4.299037 2.261008 -11.284021
v  2.094631 4.861139 -15.736774
v  5.155922 2.364102 -20.485714
v  -4.642135 3.189251 -20.260895
v  -3.373407 2.916744 -11.284021
v  5.155922 4.852755 -20.839775
v  -3.669583 0.544394 -18.347197
v  -4.288190 4.014399 -23.964602
v  -3.669313 2.247800 -15.736931
v  -4.868104 4.852755 -20.839775
v  5.155922 1.538953 -20.485714
v  3.945141 4.021386 -20.563225
v  1.094637 6.012493 -20.487247
v  -4.288074 2.364102 -20.260895
v  -1.523062 4.861139 -15.736774
v  4.240777 2.916744 -13.834786
v  1.797711 0.980508 -11.280311
v  -3.669687 0.557602 -11.225092
v  4.525005 1.409304 -13.834786
v  2.151772 2.381503 -10.996457
v  -0.808082 1.157296 -10.346466
v  5.439672 0.713805 -20.839775
v  4.241256 0.557602 -11.225092
v  -3.953437 2.247799 -18.347197
v  4.241152 0.544394 -16.090992
v  5.213821 4.014399 -23.964602
v  -4.584354 0.713805 -20.839775
v  4.525005 0.557602 -11.225092
v  3.944975 2.261008 -11.284021
v  1.379651 4.397297 -10.346466
v  4.525005 2.916742 -18.347197
v  5.154889 4.852755 -23.738634
v  5.155922 4.852756 -20.485714
v  -3.727468 2.261008 -11.284021
v  -3.953437 1.409304 -13.834786
v  -3.373407 1.409305 -11.284021
v  1.378864 7.791223 -10.640263
v  -1.807290 4.861141 -11.576154
v  -1.580203 3.782499 -10.996457
v  2.151772 2.381503 -11.280446
v  3.944975 2.916744 -11.284021
v  -3.669313 2.247799 -18.347197
v  4.299037 2.261008 -11.000272
v  -4.584354 4.852755 -20.839775
v  -4.584354 0.713805 -20.485714
v  -0.752178 5.141178 -20.839775
v  3.944975 2.916744 -11.000273
v  2.378858 4.861141 -13.834630
v  -4.584354 4.014400 -20.839775
v  4.859642 0.713805 -20.544643
v  4.525005 0.544394 -17.993135
v  4.241152 0.544394 -17.993135
v  -3.669583 0.544394 -13.834785
v  -4.584354 4.014400 -20.485714
v  -1.807290 4.861139 -15.736774
v  -3.953437 2.261008 -11.579153
v  3.945141 3.194840 -20.563147
v  -3.373573 4.861138 -20.563305
v  -0.807296 7.791223 -12.237238
v  4.299202 3.194839 -20.563147
v  -0.455898 5.141178 -20.544643
v  -3.669313 2.247799 -17.993135
v  -4.583321 4.014399 -23.738634
v  -3.953437 2.916744 -11.579153
v  4.241256 2.916744 -11.225092
v  -0.752178 5.141178 -20.485714
v  5.213704 1.538953 -20.544643
v  4.240777 2.916742 -18.347197
v  1.797711 2.381503 -11.280446
v  -3.727468 2.916744 -11.284021
v  3.944975 0.557602 -11.000272
v  -3.953437 2.916743 -15.736931
v  -3.373407 2.261008 -11.284021
v  -1.580203 2.381503 -10.996457
v  -4.642135 2.364102 -20.544643
v  -3.669687 2.261008 -11.579153
v  1.094637 7.791223 -10.640263
v  4.240777 2.916744 -13.480725
v  4.299037 1.409305 -11.000272
v  -4.288074 0.713805 -20.544643
v  4.525005 2.247799 -18.347197
v  -3.669687 2.916744 -11.579153
v  -3.669583 0.544394 -16.090992
v  4.240881 2.247799 -17.993135
v  4.299202 4.861138 -20.279078
v  -4.584354 4.852756 -20.485714
v  -1.035928 5.141178 -20.485714
v  5.155922 3.189251 -20.485714
v  5.155922 3.189250 -20.839775
v  -1.580203 4.861141 -10.996458
v  -4.583321 0.713804 -23.738632
v  -1.056699 5.141178 -23.743883
v  4.525005 0.544394 -15.736930
v  4.241152 0.544394 -15.736930
v  -3.669687 2.261008 -11.225092
v  4.299037 1.409305 -11.284021
v  4.240777 2.916743 -17.993135
v  1.094637 6.012493 -20.841309
v  -3.669687 2.916744 -11.225092
v  -3.953437 0.544395 -13.480724
v  4.299202 4.861138 -20.563305
v  -4.584354 2.364102 -20.839775
v  -4.583321 4.852755 -23.738634
v  -4.584354 1.538953 -20.839775
v  5.154889 0.713803 -23.738632
v  5.213704 0.713805 -20.544643
v  1.379651 2.237297 -10.346466
v  -0.772949 5.141178 -23.743883
v  4.241256 2.261008 -11.579153
v  4.859758 4.852754 -23.964602
v  1.323747 5.141178 -20.485714
v  -1.580203 2.381503 -11.280446
v  -0.808082 3.317295 -10.346466
v  2.151772 3.782499 -10.996457
v  -3.373407 1.409305 -11.000272
v  -1.580203 3.782499 -11.280581
v  -3.953437 1.396096 -17.993135
v  4.859642 1.538953 -20.544643
v  -3.953437 0.544394 -17.993135
v  4.241152 0.544394 -13.834785
v  3.944975 1.409305 -11.284021
v  1.607497 5.141178 -20.485714
v  4.241256 0.557602 -11.579153
v  -4.584354 1.538953 -20.485714
v  5.213704 1.538953 -20.260893
v  5.213704 2.364102 -20.544643
v  3.945141 4.861138 -20.563305
v  -3.727634 3.194840 -20.279078
v  -4.583321 2.364101 -23.738632
v  4.241016 1.396096 -18.347197
v  1.094637 7.791223 -10.346298
v  -3.669448 1.409304 -13.480725
v  2.151772 3.782499 -11.280581
v  -4.642135 0.713805 -20.544643
v  -4.583321 3.189250 -23.738632
v  -3.669209 2.916743 -15.736931
v  -3.669448 1.396097 -16.090992
v  1.381528 5.141178 -20.260895
v  -3.373573 4.861138 -20.279078
v  5.155922 0.713805 -20.485714
v  -0.523068 7.791223 -10.640263
v  1.381528 5.141178 -20.544643
v  -1.580203 0.980508 -11.280311
v  4.525005 2.916743 -16.090992
v  4.241256 2.916744 -11.579153
v  5.155922 0.713805 -20.839775
v  5.154889 3.189250 -23.738632
v  -4.868104 3.189250 -20.839775
v  -1.807290 4.861139 -18.347040
v  -1.226142 2.381503 -11.280446
v  5.155922 4.014400 -20.485714
v  -3.669313 2.261008 -13.480725
v  -4.642135 3.189251 -20.544643
v  5.155922 1.538953 -20.839775
v  -3.953437 1.409305 -11.579153
v  5.155922 4.014400 -20.839775
v  -4.642135 0.713805 -20.260893
v  -4.642135 4.014400 -20.544643
v  4.859642 2.364102 -20.544643
v  4.241256 2.261008 -11.225092
v  -3.669448 1.396097 -15.736931
v  -4.642135 4.852756 -20.260895
v  -4.288074 2.364102 -20.544643
v  -3.669583 0.544394 -17.993135
v  4.240881 2.247799 -18.347197
v  4.240777 2.916743 -16.090992
v  1.797711 3.782499 -11.280581
v  1.379651 1.157296 -10.346466
v  -0.523068 6.012493 -20.841309
v  -4.583321 1.538952 -23.738632
v  -4.288074 3.189251 -20.544643
v  -3.669209 2.916742 -18.347197
v  -3.669687 1.409305 -11.579153
v  1.378864 7.791223 -12.237238
v  -4.642135 4.852756 -20.544643
v  5.213704 0.713805 -20.260893
v  1.379651 6.488770 -10.346466
v  1.379651 7.263999 -10.346466
v  4.859642 2.364102 -20.260895
v  -0.808082 6.488770 -10.346466
v  5.213704 3.189251 -20.260895
v  -0.808082 7.263999 -10.346466
v  -4.288074 4.014400 -20.260895
v  -4.584354 2.364102 -20.485714
v  -3.953437 2.916744 -13.834786
v  4.241016 1.396097 -16.090992
v  1.323747 5.141178 -20.839775
v  4.241016 1.409304 -13.480725
v  -4.288074 4.852756 -20.544643
v  -1.226142 0.980508 -10.996457
v  4.241152 0.544394 -13.480724
v  -0.523068 6.012493 -20.487247
v  4.525005 2.261008 -11.579153
v  -3.669687 1.409305 -11.225092
v  4.525005 1.409305 -11.579153
v  1.094637 7.791223 -12.237238
v  4.240777 2.916743 -15.736931
v  2.151772 0.980508 -11.280311
v  4.525005 1.396097 -16.090992
v  1.628267 5.141178 -23.743883
v  -4.868104 2.364102 -20.485714
v  -3.669209 2.916744 -13.834786
v  -3.953437 2.261007 -13.834786
v  4.859642 3.189251 -20.544643
v  4.299202 4.021385 -20.563225
v  -3.953437 0.544394 -15.736930
v  4.241016 1.396096 -17.993135
v  -3.727634 3.194839 -20.563147
v  -1.226142 0.980508 -11.280311
v  5.154889 1.538952 -23.738632
v  4.241016 1.396097 -15.736930
v  -3.373407 0.557602 -11.000272
v  -0.807296 6.012493 -20.841309
v  4.241256 1.409305 -11.579153
v  4.240881 2.261007 -13.834786
v  -1.226142 3.782499 -11.280581
v  4.525005 1.396096 -18.347197
v  -3.669209 2.916744 -13.480725
v  5.213704 4.852756 -20.544643
v  -4.584354 3.189250 -20.839775
v  1.379651 3.317295 -10.346466
v  2.378858 4.861141 -11.576154
v  1.027467 5.141178 -20.544643
v  5.213704 3.189251 -20.544643
v  -4.642135 1.538953 -20.544643
v  2.378858 4.861139 -15.736774
v  4.525005 2.916744 -13.834786
v  -0.523068 7.791223 -12.237238
v  -3.373573 3.194840 -20.563147
v  -4.642135 1.538953 -20.260893
v  -3.953437 2.247800 -16.090992
v  -3.669313 2.261007 -13.834786
v  5.439672 2.364102 -20.839775
v  5.439672 4.014400 -20.839775
v  -3.953437 2.916742 -18.347197
v  -3.669209 2.916743 -17.993135
v  -3.953437 1.396096 -16.090992
v  -4.868104 4.014400 -20.839775
v  -4.868104 1.538953 -20.839775
v  2.378859 4.861139 -18.347040
v  4.299037 2.916744 -11.284021
v  -4.288190 4.852754 -23.964602
v  -4.288074 1.538953 -20.544643
v  4.299202 3.194840 -20.279078
v  5.439672 4.852755 -20.839775
v  -3.727634 4.021386 -20.279078
v  4.525005 2.261007 -13.834786
v  -0.523068 7.791223 -11.943274
v  4.299202 4.021386 -20.279078
v  -0.808082 2.237297 -10.346466
v  5.154889 2.364101 -23.738632
v  4.241256 1.409305 -11.225092
v  4.859642 4.852756 -20.260895
v  4.525005 0.544395 -13.480724
v  5.154889 4.014399 -23.738634
v  4.525005 2.247799 -16.090992
v  5.439672 1.538953 -20.839775
v  -0.523068 7.791223 -10.346298
v  4.859642 4.852756 -20.544643
v  -4.642252 1.538952 -23.964600
v  -3.669687 0.557602 -11.579153
v  5.155922 2.364102 -20.839775
v  1.094637 7.791223 -11.943274
v  -4.642252 2.364101 -23.964600
v  5.213821 1.538952 -23.964600
v  -3.669448 1.396096 -18.347197
v  2.094631 4.861141 -13.480570
v  -1.523062 4.861139 -17.992979
v  2.094631 4.861139 -17.992979
v  -1.523062 4.861141 -13.480569
v  3.931194 0.557602 -11.297802
v  -3.359626 0.557602 -11.297802
v  -4.274409 2.364101 -23.978382
v  4.845977 2.364101 -23.978382
v  5.452422 1.538952 -23.752413
v  -4.880853 4.014399 -23.752415
v  -0.844128 6.012492 -23.443211
v  5.452422 0.713803 -23.752413
v  -4.880853 0.713804 -23.752413
v  -4.880853 4.852755 -23.752415
v  -4.880853 2.364101 -23.752413
v  1.415696 6.012492 -23.443211
v  5.452422 4.852755 -23.752415
v  5.452422 4.014399 -23.752415
v  -4.274409 1.538952 -23.978382
v  5.452422 2.364101 -23.752413
v  -4.880853 3.189250 -23.752413
v  -4.880853 1.538952 -23.752413
v  5.452422 3.189250 -23.752413
v  4.845977 1.538952 -23.978382
v  1.642049 5.141178 -23.376041
v  5.438641 1.538953 -23.384569
v  5.438641 0.713803 -23.384569
v  -4.867072 3.189250 -23.384569
v  -4.867072 2.364101 -23.384569
v  5.438641 4.014399 -23.384571
v  5.438641 3.189250 -23.384569
v  -4.867072 1.538953 -23.384569
v  5.438641 4.852755 -23.384571
v  5.438641 2.364101 -23.384569
v  -4.867072 0.713804 -23.384569
v  -4.867072 4.014399 -23.384571
v  -4.867072 4.852755 -23.384571
v  -4.288190 3.189250 -23.680847
v  4.859758 0.713803 -23.680847
v  4.859758 2.364101 -23.680847
v  -4.288190 4.014399 -23.680851
v  -4.288190 0.713804 -23.680847
v  -4.288190 2.364101 -23.680847
v  4.859758 1.538952 -23.680847
v  -4.288190 1.538952 -23.680847
v  -4.288190 4.852755 -23.680851
v  4.859758 4.852755 -23.680851
v  4.859758 4.014399 -23.680851
v  4.859758 3.189250 -23.680847
v  -3.741250 0.557602 -11.297802
v  4.312818 0.557602 -11.297802
v  1.417227 5.141178 -23.672321
v  -0.845658 5.141178 -23.672321
v  1.034072 6.012492 -23.755001
v  -0.462503 6.012492 -23.755001
v  4.313931 2.237297 -11.210209
v  -3.742362 2.237297 -11.210209
v  -1.171576 1.708417 -11.320996
v  1.743145 1.708417 -11.320996
v  4.210782 1.669819 -19.207800
v  -3.639213 1.669819 -19.207800
v  4.210782 1.669819 -12.272569
v  -3.639213 1.669819 -12.272569
v  -3.639213 1.669819 -14.593863
v  4.210782 1.669819 -14.593863
v  -3.639213 1.669819 -16.854301
v  4.210782 1.669819 -16.854301
v  4.210782 1.669819 -12.729765
v  1.312631 1.884073 -24.475880
v  -3.639213 1.669819 -12.729765
v  -3.639213 1.669819 -15.051060
v  -3.639213 1.669819 -19.664995
v  -0.741063 1.884073 -24.475880
v  3.547174 2.019201 -11.313516
v  5.125232 1.884073 -23.106468
v  -2.975606 2.019201 -11.313516
v  4.210782 1.669819 -15.051060
v  -3.639213 1.669819 -17.311497
v  -4.553664 1.884073 -23.106468
v  4.210782 1.669819 -17.311497
v  4.210782 1.669819 -19.664995
v  -4.613664 1.883385 -21.280989
v  5.185232 1.883385 -21.280989
v  4.671692 1.669131 -20.514721
v  -4.100123 1.669131 -20.514721
v  -0.796017 2.272325 -11.243605
v  1.367586 2.272325 -11.243605
v  0.624935 0.896291 -10.375868
v  -4.553664 1.877156 -21.106228
v  -3.925364 1.662902 -20.574722
v  4.496933 1.662902 -20.574722
v  5.125232 1.877156 -21.106230
v  5.125232 2.346102 -22.481455
v  -4.553664 2.346102 -22.481455
v  5.125232 2.346102 -21.771179
v  -4.553664 2.346102 -21.771179
v  -4.553664 1.446913 -21.962307
v  5.125232 1.446913 -21.962309
v  -0.801062 1.876500 -24.301155
v  1.372631 1.876500 -24.301155
v  -3.699213 1.662246 -17.136772
v  -4.613664 1.876500 -22.931744
v  4.270782 1.662246 -17.136772
v  4.270782 1.662246 -19.490273
v  -3.699213 1.662246 -19.490273
v  5.185232 1.876500 -22.931744
v  -3.699213 1.662246 -14.876336
v  -2.800882 2.011628 -11.253515
v  4.270782 1.662246 -12.555040
v  -3.699213 1.662246 -12.555040
v  3.372451 2.011628 -11.253515
v  4.270782 1.662246 -14.876336
v  -4.553664 1.841334 -22.645464
v  -4.553664 2.062029 -22.645464
v  -4.553664 2.167555 -21.641457
v  -4.553664 1.557261 -21.771179
v  5.125232 2.062030 -21.607170
v  -4.553664 1.841332 -21.607170
v  -4.553664 1.446913 -22.290325
v  5.125232 1.492043 -22.391689
v  5.125232 1.841334 -22.645464
v  5.125232 2.062029 -22.645464
v  -4.553664 2.456450 -22.290325
v  -4.553664 2.456450 -21.962307
v  -4.553664 1.639718 -22.555698
v  5.125232 1.735807 -21.641459
v  5.125232 1.557261 -21.771181
v  5.125232 2.456450 -22.290325
v  5.125232 2.456450 -21.962309
v  5.125232 1.639718 -22.555698
v  5.185232 2.061842 -21.608055
v  -4.613664 2.410537 -21.861397
v  5.185232 1.640249 -21.697668
v  5.185232 2.410537 -21.861397
v  -4.613664 1.492827 -22.391237
v  -4.613664 1.447774 -21.962587
v  5.185232 1.557934 -22.480850
v  5.185232 1.492827 -21.861397
v  -4.613664 1.557933 -21.771786
v  5.185232 1.951681 -22.656157
v  -4.613664 1.640249 -22.554966
v  5.125232 3.878686 -23.069202
v  -4.613664 2.061841 -21.608055
v  -4.613664 2.061841 -22.644579
v  -4.613664 1.841522 -22.644579
v  -4.613664 1.841521 -21.608055
v  -4.613664 2.455589 -22.290047
v  5.185232 2.455589 -22.290047
v  5.185232 1.736176 -22.610350
v  -4.553664 3.878686 -23.069202
v  5.185232 1.841520 -21.608057
v  -4.613664 3.878211 -21.513439
v  5.185232 3.878211 -21.513439
v  -0.778707 5.435492 -12.209074
v  1.350276 7.215764 -12.209074
v  -0.778707 6.938631 -12.209074
v  3.749264 2.885835 -11.350576
v  -3.177696 2.885835 -11.350576
v  -1.538330 3.924373 -14.451358
v  -1.538330 3.924373 -16.786404
v  2.109898 3.924373 -14.451358
v  2.109898 3.924373 -19.197124
v  -1.538330 3.924373 -19.197124
v  2.109898 3.924373 -16.786404
v  -1.538330 3.924373 -12.116314
v  2.109898 3.924373 -12.116314
v  1.290269 6.359474 -11.329659
v  -0.718700 6.359474 -11.329659
v  -0.806360 7.246772 -12.236726
v  -0.741063 1.573288 -24.615234
v  5.125232 1.573288 -23.245823
v  -0.053366 0.896292 -10.464714
v  5.185232 2.345429 -22.480850
v  -4.613664 2.345429 -22.480850
v  -3.699213 1.662246 -16.679577
v  4.270782 1.662246 -16.679577
v  -4.288190 3.172023 -23.964600
v  4.859758 3.172023 -23.964600
v  4.525005 1.378870 -15.736930
v  -4.856044 3.997172 -20.827717
v  -4.856044 1.521726 -20.827715
v  5.427613 3.997172 -20.827717
v  -4.856044 0.696578 -20.827715
v  5.427613 1.521726 -20.827715
v  -0.795236 6.029720 -20.829248
v  5.427613 3.172024 -20.827717
v  -4.856044 4.869982 -20.827717
v  4.859758 3.997171 -23.964603
v  -4.288190 3.997171 -23.964603
v  -4.288190 1.521725 -23.964600
v  -4.642135 3.997173 -20.544643
v  -3.727634 4.004158 -20.563225
v  5.439672 4.869982 -20.839775
v  -0.455898 5.158405 -20.260895
v  -3.727634 4.878365 -20.563305
v  4.240881 2.230573 -15.736930
v  4.241016 1.378869 -15.736930
v  5.439672 0.696577 -20.839775
v  5.213704 3.997173 -20.544643
v  -0.752178 5.158405 -20.485714
v  4.299202 3.177613 -20.563147
v  5.213704 1.521727 -20.544643
v  -4.642135 2.346875 -20.544643
v  4.299202 4.878365 -20.563305
v  -4.868104 2.346875 -20.839775
v  5.213704 2.346875 -20.544643
v  4.525005 2.933970 -16.090992
v  -4.642135 3.172024 -20.544643
v  1.323747 5.158405 -20.485714
v  4.859642 0.696577 -20.260893
v  -4.642135 4.869982 -20.544643
v  -3.727634 3.177613 -20.563147
v  5.213704 3.172024 -20.544643
v  4.299202 4.004158 -20.563225
v  5.213704 4.869982 -20.544643
v  -4.642135 1.521727 -20.544643
v  1.106696 6.029720 -20.829248
v  -3.385632 4.004159 -20.551167
v  4.871701 3.997173 -20.532583
v  4.871818 0.696577 -23.952541
v  5.167981 2.346875 -20.827717
v  -4.300133 3.997173 -20.532583
v  5.167981 4.869982 -20.827717
v  4.871818 2.346874 -23.952541
v  -4.300249 0.696577 -23.952541
v  -4.596413 0.696578 -20.827715
v  3.957200 4.004159 -20.551167
v  -0.818287 6.029719 -23.469051
v  5.166948 4.869981 -23.726574
v  -4.596413 4.869982 -20.827717
v  -4.300249 2.346874 -23.952541
v  3.957200 3.177613 -20.551086
v  -3.385632 4.878365 -20.551247
v  4.871818 1.521725 -23.952541
v  -4.595380 0.696577 -23.726572
v  5.167981 3.172024 -20.827717
v  -4.596413 1.521726 -20.827715
v  -4.595380 4.869981 -23.726574
v  1.369469 5.158405 -20.272953
v  5.201644 0.696578 -20.272953
v  3.957200 4.878365 -20.551247
v  4.871701 1.521727 -20.532583
v  -4.595380 3.172023 -23.726572
v  5.166948 3.172023 -23.726572
v  -4.595380 3.997172 -23.726574
v  5.167981 3.997172 -20.827717
v  4.871701 2.346875 -20.532583
v  4.252837 2.933970 -16.078932
v  -4.595380 1.521725 -23.726572
v  -4.300133 2.346875 -20.532583
v  -4.300133 3.172024 -20.532583
v  -4.596413 2.346875 -20.827717
v  5.167981 0.696577 -20.827715
v  -0.535127 6.029720 -20.829248
v  1.389856 6.029719 -23.469051
v  -4.630075 0.696578 -20.272953
v  -4.300133 4.869982 -20.532583
v  4.871701 3.172024 -20.532583
v  5.166948 0.696577 -23.726572
v  5.166948 1.521726 -23.726572
v  -4.595380 2.346874 -23.726572
v  5.167981 1.521726 -20.827715
v  -3.385632 3.177613 -20.551086
v  -4.596413 3.997172 -20.827717
v  -4.300133 1.521727 -20.532583
v  5.166948 2.346874 -23.726572
v  5.166948 3.997172 -23.726574
v  -4.596413 3.172024 -20.827717
v  4.871701 4.869982 -20.532583
v  5.438641 1.521725 -23.738632
v  -4.288190 1.521725 -23.680847
v  -4.288190 3.172023 -23.680847
v  4.859758 2.346874 -23.680847
v  4.859758 0.696577 -23.680847
v  -4.288190 3.997172 -23.680851
v  -4.288190 0.696577 -23.680847
v  4.859758 1.521726 -23.680847
v  -4.288190 2.346874 -23.680847
v  -4.288190 4.869981 -23.680851
v  4.859758 4.869981 -23.680851
v  4.859758 3.997172 -23.680851
v  5.438641 4.869981 -23.738634
v  4.859758 3.172023 -23.680847
v  5.438641 3.997172 -23.738634
v  -4.867072 0.696577 -23.738632
v  5.438641 2.346874 -23.738632
v  -4.867072 2.346874 -23.738632
v  -4.867072 4.869981 -23.738634
v  -4.867072 3.997172 -23.738634
v  -4.867072 1.521725 -23.738632
v  5.438641 0.696577 -23.738632
v  5.438641 3.172023 -23.738632
v  -4.867072 3.172023 -23.738632
v  5.426581 1.521726 -23.396629
v  -4.855012 2.346874 -23.396629
v  5.426581 2.346874 -23.396629
v  -4.855012 0.696577 -23.396629
v  -4.855012 1.521726 -23.396629
v  5.426581 0.696577 -23.396629
v  5.426581 3.172023 -23.396629
v  -4.855012 3.172023 -23.396629
v  -4.855012 3.997172 -23.396631
v  -4.855012 4.869981 -23.396631
v  5.426581 4.869981 -23.396631
v  5.426581 3.997172 -23.396631
v  4.525005 2.933969 -17.993135
v  4.525005 0.527167 -16.090992
v  -1.580203 0.997735 -10.996457
v  -3.953437 0.527168 -13.834785
v  1.378864 7.808450 -11.943274
v  4.525005 1.426532 -11.579153
v  3.944975 0.574829 -11.000272
v  -1.807290 4.878366 -15.736774
v  4.525005 2.278235 -11.225092
v  -3.953437 2.278234 -13.480725
v  4.525005 2.933971 -13.834786
v  -3.953437 0.527167 -17.993135
v  -3.953437 1.426531 -13.480725
v  2.378859 4.878366 -17.992979
v  -3.953437 1.378870 -15.736931
v  -3.953437 2.230573 -15.736931
v  -1.807290 4.878366 -17.992979
v  -3.953437 2.933969 -17.993135
v  4.525005 0.574829 -11.579153
v  4.525005 1.426531 -13.480725
v  -3.681507 1.378869 -18.335136
v  4.512946 2.230573 -16.078932
v  4.512946 2.278234 -13.492785
v  1.106696 7.808450 -11.955333
v  4.253076 1.426531 -13.822727
v  1.367592 2.254524 -10.358526
v  -3.941378 0.574829 -11.567094
v  4.253076 1.378869 -16.078932
v  2.139713 4.878368 -11.008518
v  -3.681372 2.278234 -13.492785
v  -3.681507 1.378869 -18.005194
v  -3.715409 2.933971 -11.012331
v  -0.535127 7.808450 -12.225180
v  4.252941 2.230573 -16.078932
v  -3.681372 2.230573 -16.078932
v  -3.715409 1.426532 -11.271962
v  4.252941 2.278234 -13.492785
v  4.286977 0.574829 -11.012331
v  -1.535121 4.878366 -16.078775
v  4.286977 2.278235 -11.012331
v  -3.941378 1.378869 -18.005194
v  4.253211 0.527167 -18.335136
v  -3.681642 0.527167 -15.748989
v  -0.796023 4.414524 -10.358526
v  4.286977 2.278235 -11.271962
v  -3.715409 2.278235 -11.012331
v  2.106690 4.878366 -15.748834
v  -3.385466 2.933971 -11.271963
v  4.253315 2.278235 -11.237151
v  -3.681642 0.527167 -18.335136
v  -3.681372 2.230573 -15.748990
v  -3.681268 2.933970 -16.078934
v  4.512946 2.933971 -11.567095
v  -3.681746 0.574829 -11.237151
v  -1.535121 4.878366 -15.748834
v  -0.535127 7.808450 -11.955333
v  -1.795230 4.878367 -13.492628
v  2.139713 2.398730 -11.008516
v  4.252837 2.933971 -13.822727
v  -3.941378 2.230572 -18.335138
v  4.253211 0.527167 -16.078932
v  1.809770 0.997735 -11.268251
v  -1.568144 0.997735 -11.268251
v  -0.796023 1.174523 -10.358526
v  3.957034 2.278235 -11.271962
v  1.367592 4.414524 -10.358526
v  -1.795230 4.878368 -11.564095
v  -3.681642 0.527168 -13.492784
v  -3.715409 2.278235 -11.271962
v  2.139713 2.398730 -11.268387
v  1.366805 7.808450 -10.628202
v  -3.681372 2.230572 -18.335138
v  4.253315 0.574829 -11.237151
v  -3.385466 1.426532 -11.271962
v  -1.568144 3.799726 -11.008516
v  -3.941378 0.527168 -13.492784
v  2.366799 4.878367 -13.822571
v  4.512946 0.527167 -18.005194
v  -3.941378 2.278235 -11.567094
v  4.253211 0.527167 -18.005194
v  -3.681372 2.230572 -18.005196
v  -3.941378 2.933971 -11.567095
v  3.957034 2.933971 -11.271963
v  -3.681642 0.527168 -13.822726
v  4.252837 2.933969 -18.335138
v  1.809770 2.398730 -11.268387
v  3.957034 2.933971 -11.012331
v  3.957034 0.574829 -11.271962
v  -3.681746 2.278235 -11.567094
v  -3.385466 2.278235 -11.271962
v  -1.568144 2.398730 -11.008516
v  4.286977 1.426532 -11.012331
v  1.106696 7.808450 -10.628202
v  -3.681746 2.933971 -11.567095
v  -0.795236 7.808450 -12.225180
v  -3.681642 0.527167 -16.078932
v  4.252837 2.933971 -13.492785
v  4.512946 2.230572 -18.335138
v  -3.681746 2.278235 -11.237151
v  4.286977 1.426532 -11.271962
v  4.512946 1.378869 -18.335136
v  4.252837 2.933969 -18.005196
v  -3.681746 2.933971 -11.237152
v  -0.795236 7.808450 -10.358356
v  -3.681507 1.426531 -13.822727
v  -0.796023 3.334522 -10.358526
v  -1.568144 2.398730 -11.268387
v  2.139713 3.799726 -11.008516
v  -3.941378 2.933970 -15.748990
v  4.253211 0.527168 -13.822726
v  -3.941378 0.527167 -16.078932
v  4.253315 0.574829 -11.567094
v  3.957034 1.426532 -11.271962
v  -3.385466 1.426532 -11.012331
v  4.252941 2.230572 -18.005196
v  4.253315 2.278235 -11.567094
v  -1.568144 3.799726 -11.268522
v  -3.681507 1.426531 -13.492785
v  2.139713 3.799726 -11.268522
v  4.253076 1.378869 -18.335136
v  -3.681507 1.378870 -16.078932
v  1.106696 7.808450 -10.358356
v  1.367592 3.334522 -10.358526
v  -3.941378 1.426532 -11.567094
v  -1.238201 2.398730 -11.268387
v  -3.681268 2.933970 -15.748990
v  -3.681507 1.378870 -15.748990
v  -3.681642 0.527167 -18.005194
v  4.252837 2.933970 -15.748990
v  4.253315 2.933971 -11.567095
v  -3.715409 0.574829 -11.271962
v  4.253315 2.933971 -11.237152
v  1.809770 3.799726 -11.268522
v  1.367592 1.174523 -10.358526
v  -3.681746 1.426532 -11.567094
v  4.252941 2.230572 -18.335138
v  -3.941378 2.933971 -13.822727
v  -3.681268 2.933969 -18.335138
v  1.367592 7.281226 -10.358526
v  -0.796023 5.500954 -10.358526
v  -0.796023 6.505998 -10.358526
v  -0.796023 7.004093 -10.358526
v  1.367592 7.004093 -10.358526
v  -1.568144 4.878368 -11.008518
v  -1.238201 0.997735 -11.008516
v  -3.941378 0.527167 -18.335136
v  4.253076 1.426531 -13.492785
v  4.512946 1.426532 -11.237151
v  4.512946 0.527168 -13.492784
v  -3.681746 1.426532 -11.237151
v  -3.941378 2.278234 -13.822727
v  4.253211 0.527168 -13.492784
v  1.106696 7.808450 -12.225180
v  2.139713 0.997735 -11.268251
v  -1.795230 4.878366 -16.078775
v  1.366805 7.808450 -12.225180
v  -3.715409 2.933971 -11.271963
v  -1.238201 0.997735 -11.268251
v  -3.681268 2.933971 -13.822727
v  -3.385466 0.574829 -11.012331
v  4.512946 2.933971 -13.492785
v  4.253315 1.426532 -11.567094
v  -3.681268 2.933971 -13.492785
v  4.512946 2.278235 -11.567094
v  -3.941378 1.426531 -13.822727
v  -3.941378 2.230573 -16.078932
v  -0.535127 7.808450 -10.628202
v  4.253076 1.378869 -18.005194
v  2.366799 4.878368 -11.564095
v  -3.385466 0.574829 -11.271962
v  -3.941378 2.933969 -18.335138
v  -1.795231 4.878366 -18.334980
v  4.512946 2.933969 -18.335138
v  2.366799 4.878366 -18.334980
v  -1.238201 3.799726 -11.268522
v  -3.941378 1.378870 -16.078932
v  -3.681372 2.278234 -13.822727
v  2.139713 0.997735 -11.008516
v  4.512946 1.378869 -16.078932
v  4.512946 0.574829 -11.237151
v  4.512946 1.426531 -13.822727
v  -0.796023 2.254524 -10.358526
v  -3.681268 2.933969 -18.005196
v  4.253315 1.426532 -11.237151
v  -0.535127 7.808450 -10.358356
v  4.286977 0.574829 -11.271962
v  4.286977 2.933971 -11.271963
v  -3.681746 0.574829 -11.567094
v  4.252941 2.278234 -13.822727
v  2.094631 4.878367 -13.480570
v  -1.523062 4.878366 -17.992979
v  2.094631 4.878366 -17.992979
v  -1.523062 4.878367 -13.480569
v  2.106690 4.878367 -13.822571
v  -1.535121 4.878366 -18.334980
v  2.106690 4.878366 -18.334980
v  -1.535121 4.878367 -13.822571
v  4.859642 3.172024 -20.260895
v  -0.830346 6.029719 -23.741219
v  -4.288074 1.521726 -20.260893
v  3.945141 3.177613 -20.279078
v  3.945141 4.004158 -20.279078
v  -3.373573 4.004158 -20.279078
v  -3.373573 3.177613 -20.279078
v  -0.488344 6.029719 -23.729160
v  -4.630075 1.521726 -20.272951
v  5.201644 3.172024 -20.272953
v  4.287143 3.177613 -20.291136
v  -3.715575 3.177613 -20.291136
v  4.287143 4.004159 -20.291136
v  -3.715575 4.004159 -20.291136
v  -4.642252 3.172023 -23.680851
v  -4.642252 1.521725 -23.680851
v  5.213821 0.696577 -23.680851
v  -4.642252 3.997172 -23.680853
v  5.213821 2.346874 -23.680851
v  5.213821 3.997172 -23.680853
v  -4.642252 0.696577 -23.680851
v  5.213821 1.521725 -23.680851
v  -4.642252 2.346874 -23.680851
v  5.213821 4.869981 -23.680853
v  -4.642252 4.869981 -23.680853
v  5.213821 3.172023 -23.680851
v  4.859642 0.696577 -20.544643
v  -0.455898 5.158405 -20.544643
v  1.027467 5.158405 -20.544643
v  2.094631 4.878366 -16.090836
v  5.439672 2.346875 -20.485714
v  1.378864 6.029720 -20.487247
v  -4.288190 4.869981 -23.964602
v  -0.752178 5.158405 -20.839775
v  1.344518 5.158404 -23.743883
v  -4.868104 3.172024 -20.485714
v  4.299202 4.878365 -20.279078
v  4.241152 0.527167 -15.736930
v  5.439672 1.521726 -20.485714
v  5.213704 2.346875 -20.260895
v  -0.772949 5.158404 -23.743883
v  4.859758 4.869981 -23.964602
v  -4.642135 3.172024 -20.260895
v  -4.642135 4.869982 -20.260895
v  -4.288074 0.696578 -20.544643
v  -4.868104 0.696578 -20.485714
v  -0.807296 6.029720 -20.487247
v  1.323747 5.158405 -20.839775
v  5.213704 1.521726 -20.260893
v  -4.868104 1.521726 -20.485714
v  5.439672 3.172024 -20.485714
v  5.439672 3.997173 -20.485714
v  -4.868104 4.869982 -20.485714
v  -4.868104 3.997173 -20.485714
v  -4.630193 1.521725 -23.952541
v  -4.595380 2.346874 -23.396629
v  -0.797900 5.158405 -20.532585
v  2.366799 4.878366 -16.078777
v  1.061445 5.158404 -23.698162
v  -4.595380 3.172023 -23.396629
v  5.166948 2.346874 -23.396629
v  -4.596413 3.172024 -20.497772
v  1.391386 5.158404 -23.957792
v  -0.819818 5.158404 -23.957792
v  4.871701 3.997173 -20.272953
v  -0.535127 6.029720 -20.499306
v  5.166948 3.997172 -23.396631
v  -4.300133 2.346875 -20.272953
v  5.167981 2.346875 -20.497772
v  1.106696 6.029720 -20.499306
v  -1.023869 5.158405 -20.827717
v  -4.300133 3.997173 -20.272953
v  5.167981 4.869982 -20.497772
v  -4.596413 0.696578 -20.497772
v  1.059912 6.029719 -23.469051
v  5.166948 4.869981 -23.396631
v  -4.596413 3.997173 -20.497772
v  -4.596413 4.869982 -20.497772
v  -4.630193 3.172023 -23.952541
v  -1.044640 5.158404 -23.731825
v  5.167981 3.172024 -20.497772
v  1.595438 5.158405 -20.827717
v  -4.595380 0.696577 -23.396629
v  1.369469 5.158405 -20.532585
v  -4.596413 1.521727 -20.497772
v  -0.785008 5.158404 -23.401882
v  -0.489876 5.158404 -23.698162
v  5.201644 0.696577 -20.532583
v  -4.595380 4.869981 -23.396631
v  -0.488344 6.029719 -23.469051
v  5.166948 3.172023 -23.396629
v  -3.385632 4.878365 -20.291136
v  5.167981 0.696577 -20.497772
v  5.166948 0.696577 -23.396629
v  -4.630075 0.696578 -20.532583
v  -4.595380 3.997172 -23.396631
v  -4.596413 2.346875 -20.497772
v  5.167981 1.521727 -20.497772
v  1.616208 5.158404 -23.731825
v  1.356577 5.158404 -23.401882
v  -4.595380 1.521726 -23.396629
v  5.167981 3.997173 -20.497772
v  4.871701 1.521726 -20.272951
v  4.512946 0.527168 -15.748989
v  5.166948 1.521726 -23.396629
v  -4.630193 3.997171 -23.952541
v  5.201762 3.172023 -23.952541
v  5.201762 3.997171 -23.952541
v  4.871701 4.869982 -20.272953
v  -0.831877 5.158404 -23.686104
v  1.403446 5.158404 -23.686104
v  1.047853 6.029719 -23.741219
v  4.300154 2.254524 -11.224030
v  -3.728585 2.254524 -11.224030
v  -3.699213 1.662246 -14.419140
v  4.270782 1.662246 -14.419140
v  4.270782 1.662246 -12.097845
v  -3.699213 1.662246 -12.097845
v  4.270782 1.662246 -19.033075
v  -3.699213 1.662246 -19.033075
v  -3.736283 3.924373 -20.514721
v  4.307851 3.924373 -20.514721
v  -4.553664 4.036918 -21.867249
v  5.125232 4.036918 -21.867249
v  -0.801062 4.036648 -24.142311
v  1.372631 4.036648 -24.142311
v  5.125232 1.798303 -21.357430
v  -4.553664 1.798303 -21.357430
v  -4.176564 1.584049 -20.574722
v  4.748132 1.584048 -20.574722
v  5.185232 1.797828 -23.181557
v  3.622263 1.932957 -11.253515
v  -3.699213 1.583574 -19.740084
v  4.270782 1.583574 -19.740084
v  -3.699213 1.583574 -17.386585
v  -3.050694 1.932957 -11.253515
v  4.270782 1.583574 -17.386585
v  -3.699213 1.583574 -12.804853
v  1.372631 1.797828 -24.550968
v  -3.699213 1.583574 -15.126148
v  4.270782 1.583574 -12.804853
v  -4.613664 1.797828 -23.181557
v  4.270782 1.583574 -15.126148
v  -0.801062 1.797828 -24.550968
v  -0.806360 5.452144 -12.236726
v  5.185232 1.424744 -22.181702
v  -4.613664 1.424744 -22.181702
v  1.638987 2.019202 -11.320996
v  -1.067418 2.019202 -11.320996
v  0.207257 6.920105 -10.375868
v  1.290269 3.137115 -10.622458
v  -0.718700 3.137115 -10.622458
v  -0.068043 3.129392 -10.435867
v  0.639612 3.129392 -10.435867
v  0.639612 2.039427 -10.435867
v  -0.068043 4.210155 -10.435867
v  0.639612 4.210155 -10.435867
v  -0.068043 2.039427 -10.435867
v  1.290269 2.039426 -10.622458
v  -0.718700 2.039426 -10.622458
v  1.290269 4.210154 -10.599730
v  -0.718700 4.210154 -10.599730
v  -0.068044 6.822498 -10.435867
v  0.639613 6.822498 -10.435867
v  -0.068043 5.282881 -10.435867
v  0.639612 5.282881 -10.435867
v  1.290269 5.290392 -11.600932
v  -0.718700 5.290392 -10.599730
v  1.290269 5.290392 -10.599730
v  -0.718700 5.290392 -11.600932
v  1.290269 6.822494 -11.612108
v  -0.718700 6.822494 -11.612108
v  1.290269 6.822494 -10.712381
v  -0.718700 6.822494 -10.712381
v  -2.430884 3.924373 -20.514534
v  3.002453 3.924373 -20.514534
v  -0.778700 3.140620 -10.786528
v  1.350269 3.140620 -10.786528
v  0.803680 4.213659 -10.375868
v  -0.232111 4.213659 -10.375868
v  -0.232111 3.132897 -10.375868
v  0.803680 3.132897 -10.375868
v  -0.232112 6.826003 -10.375868
v  0.803681 6.826003 -10.375868
v  1.350269 6.826000 -10.876446
v  -0.778700 6.826000 -10.876446
v  -0.232111 2.042932 -10.375868
v  -0.232111 5.286386 -10.375868
v  0.803680 5.286386 -10.375868
v  0.803680 2.042932 -10.375868
v  1.350269 6.826000 -11.776175
v  -0.778700 6.826000 -11.776175
v  -0.778700 5.293898 -11.765000
v  1.350269 5.293898 -11.765000
v  1.350269 6.919396 -11.336245
v  -0.778700 6.919396 -11.336245
v  -0.718700 5.690463 -11.187456
v  1.290269 5.690463 -11.187456
v  5.211981 0.745387 -20.262615
v  5.211981 1.570536 -20.542919
v  5.211981 4.045982 -20.542921
v  5.211981 2.395685 -20.542921
v  5.211981 3.220833 -20.542921
v  5.211981 4.821173 -20.542921
v  4.511797 2.279383 -15.750138
v  -4.301397 3.220832 -23.951393
v  -4.301397 0.745386 -23.951393
v  4.872966 0.745386 -23.951393
v  -4.866381 1.570536 -20.838051
v  -4.866381 2.395684 -20.838053
v  5.437950 2.395684 -20.838053
v  5.437950 4.045982 -20.838053
v  -4.866381 4.045982 -20.838053
v  5.437950 3.220833 -20.838053
v  5.437950 4.821172 -20.838053
v  -4.866381 4.821172 -20.838053
v  5.437950 1.570536 -20.838051
v  -4.628927 4.045982 -20.531435
v  -3.714427 4.052968 -20.550018
v  5.426465 0.745387 -20.826567
v  -1.022720 5.109596 -20.826567
v  -3.714427 4.829556 -20.550098
v  4.285995 3.226422 -20.549938
v  -0.794088 5.980911 -20.828100
v  -4.628927 2.395685 -20.531435
v  4.285995 4.829556 -20.550098
v  1.368320 5.109596 -20.531435
v  4.511797 2.885160 -16.077784
v  -4.628927 3.220833 -20.531435
v  -4.628927 4.821173 -20.531435
v  4.285995 4.052968 -20.550018
v  -4.301281 0.745387 -20.274101
v  4.254224 1.427679 -15.750138
v  -4.628927 1.570536 -20.531433
v  -3.714427 3.226422 -20.549938
v  5.200613 4.821172 -23.951393
v  -4.854896 0.745387 -20.826567
v  4.254089 2.279382 -15.750138
v  -0.796752 5.109596 -20.531435
v  -0.457621 5.109596 -20.542921
v  -4.289796 4.045982 -20.542921
v  -3.375295 4.052968 -20.561504
v  5.157645 4.821172 -20.838053
v  -4.586076 2.395685 -20.838053
v  -4.586076 0.745387 -20.838051
v  5.156612 4.821172 -23.736912
v  3.946864 3.226422 -20.561424
v  -4.586076 4.821172 -20.838053
v  -3.375295 4.829556 -20.561583
v  4.861365 4.045982 -20.542921
v  -0.753901 5.109596 -20.838053
v  5.157645 1.570536 -20.838051
v  5.157645 3.220833 -20.838053
v  -4.585043 0.745386 -23.736908
v  -4.585043 4.821172 -23.736912
v  5.157645 0.745387 -20.838051
v  -4.586076 1.570536 -20.838051
v  4.861365 1.570536 -20.542919
v  -4.585043 3.220832 -23.736908
v  3.946864 4.829556 -20.561583
v  -4.289796 2.395685 -20.542921
v  5.156612 3.220832 -23.736908
v  4.861365 2.395685 -20.542921
v  5.157645 4.045982 -20.838053
v  4.242500 2.885160 -16.089270
v  1.325470 5.109596 -20.838053
v  -4.585043 1.570535 -23.736908
v  -4.289796 3.220834 -20.542921
v  -4.586076 4.045982 -20.838053
v  3.946864 4.052968 -20.561504
v  -4.586076 3.220833 -20.838053
v  1.029190 5.109596 -20.542921
v  -0.524791 5.980911 -20.839586
v  4.861365 3.220834 -20.542921
v  -4.289796 4.821173 -20.542921
v  -4.585043 2.395684 -23.736908
v  5.156612 1.570535 -23.736908
v  5.156612 0.745386 -23.736908
v  -3.375295 3.226422 -20.561424
v  -4.640412 0.745387 -20.262615
v  -4.585043 4.045981 -23.736912
v  5.156612 4.045981 -23.736912
v  -4.289796 1.570536 -20.542919
v  4.861365 4.821173 -20.542921
v  5.156612 2.395684 -23.736908
v  5.157645 2.395685 -20.838053
v  5.211981 1.570536 -20.262615
v  1.096359 5.980911 -20.839586
v  1.401723 5.109594 -23.687824
v  -0.828624 5.980909 -23.458714
v  1.400192 5.980909 -23.458714
v  -0.830154 5.109594 -23.687824
v  -4.301397 3.220832 -23.694057
v  4.872966 0.745386 -23.694057
v  4.872966 2.395684 -23.694057
v  -4.301397 4.045981 -23.694057
v  -4.301397 0.745386 -23.694057
v  -4.301397 4.821172 -23.694057
v  -4.301397 2.395684 -23.694057
v  4.872966 1.570535 -23.694057
v  4.872966 4.045981 -23.694057
v  4.872966 4.821172 -23.694057
v  4.872966 3.220832 -23.694057
v  -4.301397 1.570535 -23.694057
v  -0.774672 5.109594 -23.742161
v  1.346241 5.109594 -23.742161
v  1.626545 5.109594 -23.391544
v  4.861481 4.821172 -23.962879
v  -4.289913 4.821172 -23.962879
v  1.051108 5.109594 -23.968130
v  -0.478007 5.980909 -23.739498
v  -0.479540 5.109594 -23.968130
v  5.425433 1.570535 -23.725424
v  5.425433 4.045981 -23.725426
v  5.425433 0.745386 -23.725424
v  5.425433 4.821172 -23.725426
v  5.425433 2.395683 -23.725424
v  -4.853864 4.045981 -23.725426
v  -4.853864 2.395683 -23.725424
v  -4.853864 3.220832 -23.725424
v  -4.853864 4.821172 -23.725426
v  -4.853864 1.570535 -23.725424
v  5.425433 3.220832 -23.725424
v  -4.853864 0.745386 -23.725424
v  5.436918 1.570535 -23.386292
v  -4.865349 2.395684 -23.386292
v  5.436918 0.745386 -23.386292
v  -4.865349 0.745386 -23.386292
v  -4.865349 3.220832 -23.386292
v  5.436918 4.821172 -23.386293
v  5.436918 4.045981 -23.386293
v  5.436918 3.220832 -23.386292
v  -4.865349 4.821172 -23.386293
v  -4.865349 1.570535 -23.386292
v  -4.865349 4.045981 -23.386293
v  5.436918 2.395684 -23.386292
v  -3.951714 2.885161 -13.833064
v  -3.951714 1.377722 -13.833063
v  4.511797 2.885161 -13.821579
v  4.523283 0.526019 -11.226814
v  -3.951714 0.526019 -11.226814
v  -3.725746 0.526019 -11.282298
v  4.297314 0.526019 -11.282298
v  2.107838 4.829558 -13.821423
v  -1.536270 4.829556 -18.333832
v  2.107839 4.829556 -18.333832
v  -1.536270 4.829558 -13.821423
v  2.365651 4.829556 -18.006187
v  1.366443 5.452144 -10.359674
v  4.511797 0.575977 -16.077784
v  -3.940229 0.526019 -11.565945
v  -3.940229 0.575977 -13.821578
v  -1.239350 4.829558 -11.009666
v  1.810918 4.829558 -11.009666
v  -3.714261 0.526019 -11.013479
v  4.511797 1.377722 -11.238299
v  -3.940229 0.575977 -15.750137
v  -3.940229 1.377723 -11.238299
v  -0.794875 5.452144 -10.359674
v  -3.940229 2.885162 -11.238300
v  -3.940229 2.229426 -11.238299
v  -3.940229 2.279382 -18.006344
v  -3.386614 2.885162 -11.013479
v  -3.940229 2.229425 -13.493933
v  -3.940229 1.377722 -13.493933
v  -1.794082 4.829556 -18.006187
v  4.511797 2.229425 -13.493933
v  -3.940229 1.427679 -15.750138
v  4.511797 2.885160 -18.006344
v  -3.940229 2.279382 -15.750138
v  -3.940229 2.885160 -18.006344
v  -3.940229 2.885161 -13.493933
v  -0.805573 7.759641 -10.348021
v  -1.805567 4.829558 -13.482292
v  -3.671306 0.575977 -15.738652
v  4.242604 2.279382 -16.089270
v  -1.524785 4.829557 -16.089113
v  -3.671036 2.279382 -16.089270
v  -3.671171 1.427679 -17.994858
v  -3.951714 2.885160 -15.738653
v  4.297314 2.229426 -11.001994
v  -0.806360 4.365715 -10.348189
v  4.242604 2.279382 -17.994860
v  -3.951714 1.427679 -17.994858
v  -3.725746 2.229426 -11.001994
v  4.523283 2.229426 -11.226814
v  2.377136 4.829557 -15.738497
v  3.946698 2.885162 -11.282299
v  -3.671306 0.575977 -18.345474
v  -3.671036 2.279382 -15.738653
v  4.297314 2.229426 -11.282299
v  -0.806360 1.125713 -10.348189
v  1.799433 0.948926 -11.278588
v  1.799433 0.948926 -10.998180
v  -3.375129 2.885162 -11.282299
v  3.946698 2.885162 -11.001995
v  -0.805573 7.759641 -12.235516
v  -3.375129 1.377723 -11.282299
v  2.150049 2.349921 -10.998180
v  -1.227865 3.750916 -10.998180
v  3.946698 2.229426 -11.282299
v  1.377928 4.365715 -10.348189
v  4.242874 0.575977 -16.089268
v  -3.725746 2.229426 -11.282299
v  -3.671306 0.575977 -13.833063
v  4.242874 0.575977 -18.345474
v  -1.805567 4.829558 -11.574430
v  2.150049 2.349921 -11.278723
v  2.377136 4.829558 -13.832908
v  -3.671036 2.279382 -18.345474
v  4.523283 0.575977 -17.994858
v  4.242874 0.575977 -17.994858
v  4.242978 2.885162 -11.226815
v  -1.805567 4.829557 -15.738497
v  3.946698 0.526019 -11.001994
v  4.242500 2.885161 -13.482449
v  1.377928 3.285712 -10.348189
v  4.242978 2.229426 -11.577431
v  -3.725746 2.885162 -11.282299
v  -3.671036 2.279382 -17.994860
v  1.799433 2.349921 -11.278723
v  4.242978 2.229426 -11.226814
v  4.242500 2.885160 -18.345474
v  -3.375129 2.229426 -11.282299
v  4.523283 1.427679 -16.089270
v  -1.578481 2.349921 -10.998180
v  1.096359 7.759641 -10.638539
v  4.523283 2.885162 -11.226815
v  -3.671410 2.229426 -11.577431
v  4.297314 1.377722 -11.001994
v  -3.671306 0.575977 -16.089268
v  -3.671410 2.885162 -11.577431
v  1.377142 7.759641 -10.638539
v  4.523283 1.427679 -18.345474
v  4.242500 2.885160 -17.994860
v  -3.671410 2.229426 -11.226814
v  -3.375129 1.377723 -11.001994
v  4.297314 1.377722 -11.282299
v  -3.670932 2.885160 -15.738653
v  -1.578481 3.750916 -11.278858
v  -3.671410 2.885162 -11.226815
v  -1.578481 2.349921 -11.278723
v  4.242978 2.885162 -11.577431
v  4.523283 2.279382 -17.994860
v  4.242604 2.229425 -13.482448
v  -3.951714 0.575977 -17.994858
v  1.096359 7.759641 -10.348021
v  -0.806360 3.285712 -10.348189
v  2.150049 3.750916 -10.998180
v  4.242978 0.526019 -11.577430
v  3.946698 1.377722 -11.282299
v  4.242874 0.575977 -13.833063
v  -3.951714 0.575977 -13.482448
v  -0.524791 7.759641 -11.944997
v  4.242739 1.427679 -18.345474
v  -1.578481 0.948926 -10.998180
v  -3.671171 1.377722 -13.482448
v  2.150049 3.750916 -11.278858
v  2.377136 4.829558 -11.574430
v  -3.671171 1.427679 -16.089270
v  4.242604 2.279382 -18.345474
v  -3.951714 0.575977 -16.089268
v  -1.227865 2.349921 -11.278723
v  -3.671306 0.575977 -17.994858
v  -3.671171 1.427679 -15.738653
v  1.799433 3.750916 -11.278858
v  1.377928 1.125713 -10.348189
v  -3.670932 2.885160 -18.345474
v  -0.524791 7.759641 -12.235516
v  -1.578481 0.948926 -11.278588
v  -3.671410 1.377723 -11.577431
v  1.377142 7.759641 -12.235516
v  4.242739 1.427679 -17.994858
v  4.523283 0.575977 -13.482448
v  -1.227865 3.750916 -11.278858
v  4.242874 0.575977 -13.482448
v  4.242500 2.885160 -15.738653
v  1.377928 6.955283 -10.348189
v  4.242739 1.377722 -13.482448
v  1.377928 6.457188 -10.348189
v  1.377928 7.232417 -10.348189
v  4.242739 1.427679 -16.089270
v  -0.806360 6.457188 -10.348189
v  4.523283 1.377722 -13.482448
v  -0.806360 6.955283 -10.348189
v  -0.806360 7.232417 -10.348189
v  -3.375129 0.526019 -11.001994
v  -3.951714 1.377723 -11.577431
v  -3.951714 2.279382 -18.345474
v  -3.671410 1.377723 -11.226814
v  -3.671036 2.229425 -13.482448
v  1.096359 7.759641 -12.235516
v  2.150049 0.948926 -11.278588
v  -3.671306 0.575977 -13.482448
v  2.150049 4.829558 -10.998180
v  -1.227865 0.948926 -11.278588
v  4.523283 1.377722 -11.577431
v  4.242978 1.377722 -11.577431
v  -1.578481 4.829558 -10.998180
v  -3.670932 2.885160 -17.994860
v  -3.670932 2.885161 -13.482449
v  4.297314 2.885162 -11.282299
v  -3.951714 2.885162 -11.577431
v  -3.951714 2.229426 -11.577431
v  -3.671036 2.229425 -13.833064
v  -0.524791 7.759641 -10.638539
v  -3.725746 2.885162 -11.001995
v  -0.806360 2.205714 -10.348189
v  1.096359 7.759641 -11.944997
v  4.242978 1.377722 -11.226814
v  -3.951714 2.229425 -13.833064
v  2.377136 4.829556 -18.345318
v  -1.805567 4.829556 -18.345318
v  -3.951714 2.279382 -16.089270
v  -3.951714 1.427679 -16.089270
v  4.523283 2.229425 -13.833064
v  -3.951714 2.885160 -18.345474
v  -3.670932 2.885160 -16.089270
v  4.523283 2.885160 -18.345474
v  -0.524791 7.759641 -10.348021
v  4.523283 2.279382 -16.089270
v  -3.671410 0.526019 -11.577430
v  4.242604 2.229425 -13.833064
v  -3.725746 1.377723 -11.282299
v  -3.671171 1.427679 -18.345474
v  1.377928 2.205714 -10.348189
v  2.107838 4.829558 -13.493777
v  -1.536270 4.829556 -18.006187
v  2.107839 4.829556 -18.006187
v  -1.536270 4.829558 -13.493776
v  3.946698 0.526019 -11.282299
v  -3.375129 0.526019 -11.282299
v  -3.682417 2.885161 -13.821579
v  4.254463 0.526019 -11.238299
v  4.253985 2.885161 -13.821579
v  -3.682895 0.526019 -11.238299
v  -3.682656 1.377722 -13.821579
v  4.254224 1.377722 -13.821579
v  2.107838 4.829557 -15.749982
v  -1.536270 4.829557 -15.749982
v  5.212098 0.745386 -23.962875
v  5.212098 3.220832 -23.962875
v  5.200613 0.745386 -23.694057
v  5.200613 2.395683 -23.694057
v  5.200613 1.570535 -23.694057
v  5.200613 4.821172 -23.694059
v  5.200613 3.220832 -23.694057
v  5.200613 4.045981 -23.694059
v  1.390238 5.109594 -23.956644
v  -0.818669 5.109594 -23.956644
v  -0.817139 5.980909 -23.728012
v  5.200496 2.395684 -20.274101
v  5.200496 4.821173 -20.274101
v  5.200496 3.220833 -20.274101
v  3.958349 4.829556 -20.292286
v  -3.386780 4.829556 -20.292286
v  -3.386780 4.052968 -20.292286
v  -3.386780 3.226422 -20.292286
v  3.958349 3.226422 -20.292286
v  3.958349 4.052968 -20.292286
v  4.872850 1.570536 -20.274099
v  -4.629045 4.821172 -23.951393
v  -4.866381 0.745387 -20.487436
v  -3.725912 4.829556 -20.280800
v  4.297480 4.829556 -20.280800
v  5.211981 0.745387 -20.542919
v  4.297480 3.226422 -20.280800
v  -3.725912 3.226422 -20.280800
v  4.297480 4.052968 -20.280800
v  -3.725912 4.052968 -20.280800
v  -4.640529 3.220832 -23.962875
v  -4.640529 0.745386 -23.962875
v  5.156612 2.395684 -23.386292
v  5.156612 4.045981 -23.386295
v  1.346241 5.109594 -23.391546
v  5.156612 4.821172 -23.386295
v  -1.054976 5.109594 -23.742161
v  -4.585043 4.045981 -23.386295
v  -4.585043 0.745386 -23.386292
v  -0.774672 5.109594 -23.391546
v  -4.585043 4.821172 -23.386295
v  5.156612 3.220832 -23.386292
v  -4.585043 1.570535 -23.386292
v  -4.585043 3.220832 -23.386292
v  5.156612 1.570535 -23.386292
v  -4.585043 2.395684 -23.386292
v  5.156612 0.745386 -23.386292
v  -4.629045 3.220832 -23.694057
v  -4.629045 1.570535 -23.694057
v  -4.629045 4.045981 -23.694059
v  -4.629045 0.745386 -23.694057
v  -4.629045 2.395683 -23.694057
v  -4.629045 4.821172 -23.694059
v  -0.478007 5.980909 -23.458715
v  1.049576 5.980909 -23.458715
v  -0.479540 5.109594 -23.687824
v  1.051108 5.109594 -23.687824
v  2.107839 4.829557 -16.077629
v  -4.628927 2.395684 -20.274101
v  1.365657 5.980911 -20.500454
v  4.872966 2.395683 -23.951393
v  1.594289 5.109596 -20.498920
v  4.872966 4.045981 -23.951393
v  -4.301397 2.395683 -23.951393
v  -4.854896 3.220833 -20.498920
v  4.872966 1.570535 -23.951393
v  4.254359 0.575977 -15.750137
v  1.368320 5.109596 -20.274101
v  -4.628927 4.821173 -20.274101
v  -4.301281 0.745387 -20.531435
v  -4.301397 1.570535 -23.951393
v  -4.628927 3.220833 -20.274101
v  4.872850 0.745387 -20.531435
v  -4.301397 4.045981 -23.951393
v  -4.854896 2.395684 -20.498920
v  5.426465 3.220833 -20.498920
v  -4.854896 1.570536 -20.498920
v  -4.854896 4.821173 -20.498920
v  5.426465 2.395684 -20.498920
v  -4.854896 4.045982 -20.498920
v  5.426465 4.821173 -20.498920
v  5.426465 4.045982 -20.498920
v  5.426465 1.570536 -20.498920
v  5.157645 2.395685 -20.487438
v  4.861365 4.045982 -20.262617
v  1.096359 5.980911 -20.488970
v  -0.457621 5.109596 -20.262617
v  -4.586076 4.045982 -20.487438
v  1.325470 5.109596 -20.487438
v  5.157645 1.570536 -20.487436
v  -4.289796 4.045982 -20.262617
v  5.157645 4.821173 -20.487438
v  -4.640412 0.745387 -20.542919
v  -4.586076 0.745387 -20.487436
v  -0.753901 5.109596 -20.487438
v  -4.586076 4.821173 -20.487438
v  5.157645 3.220834 -20.487438
v  5.157645 0.745387 -20.487436
v  1.400192 5.980909 -23.739498
v  -4.586076 1.570536 -20.487436
v  4.861365 3.220833 -20.262617
v  5.157645 4.045982 -20.487438
v  -4.586076 2.395685 -20.487438
v  -0.524791 5.980911 -20.488970
v  4.523283 0.575977 -15.738653
v  -4.289796 3.220833 -20.262617
v  -4.640529 4.045981 -23.962879
v  -4.586076 3.220834 -20.487438
v  -4.289796 1.570536 -20.262615
v  4.286951 2.205714 -11.237276
v  -3.715382 2.205714 -11.237276
v  0.515007 0.970302 -10.464714
v  0.056562 0.970302 -10.375868
v  1.379651 2.254524 -11.253489
v  -0.808082 2.254524 -11.253489
v  0.143068 6.795747 -10.443367
v  -0.821863 7.263999 -12.252230
v  -0.821863 6.488770 -12.252230
v  1.393432 6.986866 -12.252230
v  1.393432 5.483727 -12.252230
v  -0.937070 2.011724 -11.260997
v  1.508638 2.011724 -11.260997
v  5.125232 4.049977 -22.400486
v  -4.553664 4.049977 -22.400486
v  -0.741063 3.815168 -24.722942
v  1.312631 3.815168 -24.722942
v  2.106414 -0.067656 -11.253515
v  -1.534845 -0.067656 -11.253515
v  3.628132 3.002241 -11.290576
v  -3.056563 3.002241 -11.290576
v  1.312631 4.036918 -24.141449
v  -0.741063 4.036918 -24.141449
v  0.377288 5.690464 -10.375868
v  -4.613664 4.036648 -21.868111
v  5.185232 4.036648 -21.868113
v  2.127221 4.310935 -20.514265
v  -1.238201 4.896169 -11.251311
v  -1.555652 4.310935 -20.514265
v  1.809770 4.896169 -11.251311
v  -1.552437 4.896169 -11.234152
v  0.285777 2.272325 -10.358526
v  0.285791 2.272325 -10.358526
v  2.124005 4.896169 -11.234152
v  0.285777 7.299027 -10.375752
v  0.285791 5.518755 -10.375752
v  0.285777 4.432325 -10.375752
v  0.285791 4.432325 -10.375752
v  0.285777 7.021894 -10.375752
v  0.285791 7.021894 -10.375752
v  0.285777 1.192324 -10.375752
v  0.285777 6.523799 -10.375752
v  0.285777 3.352323 -10.375752
v  0.285791 1.192325 -10.375752
v  0.285791 3.352323 -10.375752
v  0.285791 6.523799 -10.375752
v  0.285777 5.518755 -10.375752
v  0.285791 7.299027 -10.375752
v  -0.808082 5.500954 -12.238448
v  -0.808082 6.505998 -12.238448
v  -0.808082 7.004093 -12.238448
v  -0.808082 7.281226 -12.238448
v  1.366438 2.205714 -11.244757
v  -0.794869 2.205714 -11.244757
v  3.276740 1.864746 -11.253515
v  -2.705172 1.864746 -11.253515
v  -1.478330 3.933378 -14.956000
v  2.049898 3.933378 -14.956000
v  2.049898 3.933378 -17.291046
v  2.049898 3.933378 -19.701767
v  -1.478330 3.933378 -17.291046
v  -1.478330 3.933378 -19.701767
v  -2.935518 3.933378 -20.574608
v  3.507086 3.933378 -20.574610
v  2.049898 3.933378 -12.620955
v  -1.478330 3.933378 -12.620955
v  4.812493 3.933378 -20.574722
v  -4.240925 3.933378 -20.574722
v  -2.653843 3.118349 -11.350576
v  3.225411 3.118349 -11.350576
v  2.800759 3.250065 -11.350576
v  -2.229191 3.250065 -11.350576
v  2.235115 3.400630 -11.350576
v  -1.663546 3.400630 -11.350576
v  2.395122 3.349548 -11.350576
v  -1.823554 3.349548 -11.350576
v  -2.862310 3.084990 -11.350576
v  3.433878 3.084990 -11.350576
v  2.600610 3.306445 -11.350576
v  -2.029041 3.306445 -11.350576
v  3.016969 3.197327 -11.350576
v  -2.445401 3.197327 -11.350576
v  -0.801062 4.049648 -24.673843
v  1.372631 4.049648 -24.673843
v  -1.123297 1.933052 -11.260997
v  1.694865 1.933052 -11.260997
v  -4.613664 3.944980 -22.430304
v  5.185232 3.944980 -22.430304
v  -1.538330 3.988212 -12.580788
v  2.109898 3.988212 -12.580788
v  2.109898 3.988212 -14.915833
v  2.109898 3.988212 -17.250877
v  -1.538330 3.988212 -14.915833
v  -1.538330 3.988212 -17.250877
v  -1.538330 3.988212 -19.661598
v  2.109898 3.988212 -19.661598
v  -0.794875 6.955283 -12.225241
v  1.366443 6.457188 -12.225241
v  -0.794875 7.232417 -12.225241
v  0.285791 7.215764 -10.359674
v  0.285777 7.215764 -10.359674
v  0.285791 5.435492 -10.359674
v  0.285777 4.349062 -10.359674
v  0.285791 4.349062 -10.359674
v  0.285777 6.938631 -10.359674
v  0.285791 6.938631 -10.359674
v  0.285777 1.109061 -10.359674
v  0.285777 6.440536 -10.359674
v  0.285777 3.269059 -10.359674
v  0.285791 1.109061 -10.359674
v  0.285791 3.269059 -10.359674
v  0.285791 6.440536 -10.359674
v  0.285777 5.435492 -10.359674
v  -1.552437 4.812906 -11.251467
v  -1.255517 4.812906 -11.251311
v  0.285777 2.189061 -10.375841
v  -1.538337 4.394198 -20.514265
v  1.827085 4.812906 -11.251311
v  2.109905 4.394198 -20.514265
v  0.285791 2.189062 -10.375841
v  2.124005 4.812906 -11.251467
v  -4.613664 3.802058 -21.127613
v  5.185232 3.802058 -21.127613
v  -0.801062 1.729618 -24.205444
v  1.372631 1.729618 -24.205446
v  5.185232 1.729617 -22.836035
v  -4.613664 1.729617 -22.836035
v  2.049898 3.924742 -19.196297
v  -2.430046 3.924742 -20.574533
v  3.001615 3.924742 -20.574535
v  4.307022 3.924742 -20.574722
v  -3.735454 3.924742 -20.574722
v  -1.478330 3.924742 -14.450529
v  -1.478330 3.924742 -16.785574
v  2.049898 3.924742 -14.450529
v  2.049898 3.924742 -16.785574
v  -1.478330 3.924742 -19.196297
v  2.049898 3.924742 -12.115484
v  -1.478330 3.924742 -12.115484
v  4.270782 1.515364 -16.583866
v  4.270782 1.515364 -12.459330
v  -3.699213 1.515364 -12.459330
v  -3.699213 1.515364 -12.002134
v  4.270782 1.515364 -12.002134
v  -3.699213 1.515364 -14.780625
v  4.270782 1.515364 -14.780625
v  -3.699213 1.515364 -14.323429
v  -3.699213 1.515364 -16.583866
v  4.270782 1.515364 -14.323429
v  -3.699213 1.515364 -18.937366
v  -3.699213 1.515364 -19.394562
v  4.270782 1.515364 -18.937366
v  -3.699213 1.515364 -17.041063
v  4.270782 1.515364 -19.394562
v  4.270782 1.515364 -17.041063
v  5.125232 3.802329 -22.681744
v  -4.553664 3.802329 -22.681744
v  3.628715 3.002929 -11.350576
v  -3.057146 3.002929 -11.350576
v  0.285791 5.466500 -10.332685
v  0.285777 7.246772 -10.332685
v  0.285777 4.380070 -10.332685
v  0.285791 4.380070 -10.332685
v  0.285777 6.969639 -10.332685
v  0.285777 1.140069 -10.332685
v  0.285777 6.471544 -10.332685
v  0.285777 3.300068 -10.332685
v  0.285791 3.300068 -10.332685
v  0.285791 1.140069 -10.332685
v  0.285791 7.246772 -10.332685
v  0.285791 6.471544 -10.332685
v  0.285777 5.466500 -10.332685
v  0.285791 6.969639 -10.332685
v  1.393432 2.220070 -11.253489
v  -0.821863 2.220070 -11.253489
v  -4.553664 3.812622 -21.547041
v  5.125232 3.812622 -21.547043
v  -4.613664 3.812293 -23.101194
v  5.185232 3.812293 -23.101194
v  -0.778700 2.057280 -10.647955
v  1.350269 2.057280 -10.647955
v  1.350269 1.192344 -11.260997
v  0.585163 5.877635 -10.375868
v  -0.013594 5.877635 -10.443367
v  0.585163 5.877635 -10.443367
v  0.128609 5.719702 -10.375868
v  0.022349 5.815379 -10.375868
v  0.022349 6.219613 -10.443367
v  -0.035808 5.946003 -10.375868
v  0.585163 6.157357 -10.443367
v  0.377288 5.690464 -10.443367
v  0.194281 5.690464 -10.443367
v  0.501117 5.761957 -10.375868
v  -0.013594 6.157357 -10.375868
v  0.070451 5.761957 -10.443367
v  0.501117 5.761957 -10.443367
v  0.585163 6.157357 -10.375868
v  0.614891 6.017496 -10.375868
v  -0.043322 6.017496 -10.443367
v  0.614891 6.017496 -10.443367
v  0.128609 6.315289 -10.375868
v  0.442960 6.315289 -10.375868
v  0.128609 6.315289 -10.443367
v  0.442960 6.315289 -10.443367
v  -0.718700 6.157357 -10.979582
v  1.290269 6.157357 -10.979582
v  -0.718700 5.719702 -11.121784
v  1.290269 5.719702 -11.121784
v  1.290269 5.690463 -11.399975
v  -0.718700 5.690463 -11.399975
v  -0.718700 6.017496 -10.949854
v  1.290269 6.017496 -10.949854
v  -0.718700 6.017496 -11.637578
v  1.290269 6.017496 -11.637578
v  1.290269 6.315289 -11.465647
v  -0.718700 6.315289 -11.465647
v  -0.718700 6.273035 -11.063627
v  1.290269 6.273035 -11.063627
v  1.290269 5.815378 -11.571906
v  -0.718700 5.815378 -11.571906
v  1.290269 6.219613 -11.571906
v  -0.718700 6.219613 -11.571906
v  -0.718700 5.815378 -11.015527
v  1.290269 5.815378 -11.015527
v  -2.934735 3.932937 -20.514608
v  3.506304 3.932937 -20.514610
v  -4.240134 3.932937 -20.514721
v  4.811702 3.932937 -20.514721
v  -0.778700 5.720486 -11.122236
v  1.350269 5.720486 -11.122236
v  -0.778700 6.156989 -10.980408
v  1.350269 6.156989 -10.980408
v  1.350269 6.358574 -11.329565
v  -0.778700 6.358574 -11.329565
v  1.350269 5.691324 -11.399695
v  -0.778700 5.691324 -11.399695
v  5.125232 1.729888 -21.010273
v  1.350269 6.017496 -11.636673
v  -0.778700 6.017496 -11.636673
v  -0.778700 6.017496 -10.950758
v  1.350269 6.017496 -10.950758
v  -0.778700 6.272362 -11.064233
v  1.350269 6.272362 -11.064233
v  1.350269 6.314504 -11.465195
v  -0.778700 6.314504 -11.465195
v  1.350269 6.219080 -11.571174
v  -0.778700 6.219080 -11.571174
v  1.350269 5.815910 -11.571174
v  -0.778700 5.815910 -11.571174
v  -4.553664 1.729888 -21.010273
v  -0.778700 5.815910 -11.016258
v  1.350269 5.815910 -11.016258
v  -3.829410 1.515634 -20.574722
v  4.400978 1.515634 -20.574722
v  3.666893 2.915718 -11.290576
v  -3.095324 2.915718 -11.290576
v  0.285791 2.205714 -10.348189
v  -1.552437 4.829558 -11.223815
v  -1.565989 4.377546 -20.514265
v  1.799433 4.829558 -11.251311
v  0.285777 2.205714 -10.348189
v  2.137558 4.377546 -20.514265
v  2.124005 4.829558 -11.223815
v  -1.227865 4.829558 -11.251311
v  -3.250845 2.943357 -11.350576
v  3.822413 2.943357 -11.350576
v  1.290269 6.344528 -11.187456
v  -0.718700 6.344528 -11.187456
v  5.185232 4.187194 -21.949175
v  -4.613664 4.187194 -21.949175
v  1.290269 3.016968 -10.851276
v  -0.718700 3.016968 -10.851276
v  -0.718700 1.919280 -10.851276
v  1.290269 1.919280 -10.851276
v  0.868431 3.009245 -10.435867
v  -0.296862 3.009245 -10.435867
v  -1.552437 4.861141 -11.208311
v  -1.581493 4.345963 -20.514265
v  1.783929 4.861141 -11.251311
v  0.285777 2.237297 -10.332685
v  2.153061 4.345963 -20.514265
v  2.124005 4.861141 -11.208311
v  0.285791 2.237297 -10.332685
v  -1.212361 4.861141 -11.251311
v  0.285791 5.483727 -10.346466
v  0.285777 7.263999 -10.346466
v  0.285791 6.986866 -10.346466
v  0.285777 4.397297 -10.346466
v  0.285791 4.397297 -10.346466
v  0.285777 6.986866 -10.346466
v  0.285777 1.157296 -10.346466
v  0.285777 6.488770 -10.346466
v  0.285777 3.317295 -10.346466
v  0.285791 1.157296 -10.346466
v  0.285791 6.488770 -10.346466
v  0.285791 7.263999 -10.346466
v  0.285777 5.483727 -10.346466
v  0.285791 3.317295 -10.346466
v  1.379651 2.237297 -11.253489
v  -0.808082 2.237297 -11.253489
v  0.868431 1.919280 -10.435867
v  -0.296862 1.919280 -10.435867
v  1.369643 5.369642 -23.282553
v  -3.639213 1.698092 -16.922033
v  -3.639213 1.698092 -14.656508
v  4.210782 1.698092 -14.656509
v  4.210782 1.698091 -12.334858
v  -3.639213 1.698093 -19.440968
v  -3.639213 1.698092 -14.838909
v  -3.639213 1.748844 -19.202202
v  4.210782 1.698092 -14.838909
v  -3.639213 1.893979 -12.604474
v  -3.639213 2.037458 -14.766776
v  4.210782 1.893979 -12.604474
v  -3.639213 1.781867 -12.592690
v  4.210782 1.698093 -19.258568
v  -3.639213 1.781868 -19.516399
v  4.210782 1.781867 -12.592690
v  4.210782 1.963269 -14.895275
v  -3.639213 2.014020 -17.104433
v  4.210782 2.014020 -17.104433
v  -3.639213 1.991607 -19.227716
v  4.210782 1.674654 -16.994167
v  -3.639213 1.674654 -12.406993
v  4.210782 1.991607 -19.227716
v  -3.639213 1.781868 -14.914341
v  4.210782 1.781868 -14.914341
v  -3.639213 1.698091 -12.517259
v  1.290269 6.795743 -11.400995
v  -0.718700 6.795743 -11.400995
v  4.210782 1.698091 -12.517259
v  5.125232 3.802329 -21.126749
v  -4.553664 3.802329 -21.126749
v  -3.699213 1.818322 -14.925240
v  4.270782 1.675554 -17.032206
v  -3.699213 1.675553 -12.445031
v  4.270782 1.818321 -14.925240
v  4.270782 1.962737 -19.496601
v  4.270782 1.990934 -14.626265
v  4.270782 1.683443 -14.691624
v  4.270782 1.818321 -12.603589
v  -3.699213 1.818321 -12.603589
v  4.270782 1.683442 -12.369973
v  -3.699213 1.683443 -19.293682
v  4.270782 2.036558 -14.728738
v  -3.699213 2.013237 -16.922485
v  4.270782 2.036559 -19.330795
v  -3.699213 2.036558 -19.330795
v  -3.699213 1.683443 -14.691624
v  4.270782 2.036557 -12.407087
v  -3.699213 2.036557 -12.407087
v  4.270782 1.782236 -19.515572
v  4.270782 1.683443 -19.293682
v  -3.699213 1.683443 -16.957148
v  -3.699213 1.929877 -12.591864
v  4.270782 1.929877 -12.591864
v  1.290269 5.170246 -11.829751
v  -0.718700 5.170246 -11.829751
v  4.270782 1.990934 -17.134678
v  4.270782 2.036558 -17.032206
v  -3.699213 1.929878 -19.183962
v  4.270782 1.929878 -19.183962
v  4.270782 2.013236 -12.516806
v  -3.699213 2.013236 -12.516806
v  -3.699213 1.929878 -16.847429
v  -3.699213 1.929877 -12.260256
v  4.270782 1.929877 -12.260256
v  -4.613664 3.802058 -22.682606
v  5.185232 3.802058 -22.682606
v  5.125232 4.200819 -22.308407
v  -0.718700 5.170246 -10.828548
v  1.290269 5.170246 -10.828548
v  -4.553664 4.200819 -22.308407
v  1.312631 4.200819 -24.582605
v  -0.741063 4.200819 -24.582605
v  -0.296863 6.702351 -10.435867
v  0.868432 6.702351 -10.435867
v  -0.718700 6.702347 -10.941200
v  1.290269 6.702347 -10.941200
v  1.290269 6.702348 -11.840927
v  -0.718700 6.702348 -11.840927
v  -0.718700 4.090007 -10.828548
v  1.290269 4.090007 -10.828548
v  0.868431 4.090007 -10.435867
v  -0.296863 4.090007 -10.435867
v  -0.741063 1.739111 -24.581154
v  4.210782 1.524857 -12.835041
v  -3.639213 1.524857 -12.835041
v  -3.639213 1.524857 -15.156336
v  4.210782 1.524857 -15.156336
v  4.210782 1.524857 -17.416773
v  4.210782 1.524857 -19.770271
v  -4.553664 1.739111 -23.211744
v  1.312631 1.739111 -24.581154
v  5.125232 1.739111 -23.211744
v  -3.080882 1.874239 -11.313516
v  3.652450 1.874239 -11.313516
v  -3.639213 1.524857 -19.770271
v  -3.639213 1.524857 -19.313076
v  -3.639213 1.524857 -17.416773
v  4.210782 1.524857 -14.699140
v  -3.639213 1.524857 -12.377845
v  4.210782 1.524857 -19.313076
v  4.210782 1.524857 -16.959576
v  4.210782 1.524857 -12.377845
v  -3.639213 1.524857 -16.959576
v  -3.639213 1.524857 -14.699140
v  -4.613664 1.738781 -21.386005
v  -4.205141 1.524527 -20.514721
v  5.185232 1.738782 -21.386005
v  4.776709 1.524527 -20.514721
v  -1.538330 3.863802 -12.649193
v  2.109898 3.863802 -12.649193
v  -1.538330 3.863802 -17.319283
v  2.109898 3.863802 -17.319283
v  2.109898 3.863802 -14.984238
v  -1.538330 3.863802 -19.730003
v  -1.538330 3.863802 -14.984238
v  2.109898 3.863802 -19.730003
v  -1.538330 3.794861 -14.426684
v  2.109898 3.794861 -19.172451
v  -1.538330 3.794861 -16.761730
v  2.109898 3.794861 -14.426684
v  -1.538330 3.794861 -19.172451
v  -1.538330 3.794861 -12.091640
v  2.109898 3.794861 -12.091640
v  2.109898 3.794861 -16.761730
v  5.125232 4.187760 -21.948471
v  -4.553664 4.187760 -21.948471
v  1.372631 4.187194 -24.223375
v  -0.801062 4.187194 -24.223375
v  -0.296863 5.162734 -10.435867
v  0.868431 5.162734 -10.435867
v  -0.781423 5.362199 -20.903328
v  -0.778700 6.343667 -11.187736
v  1.350269 6.343667 -11.187736
v  1.352653 5.276234 -20.854984
v  5.125232 3.730012 -23.126234
v  -4.553664 3.730012 -23.126234
v  -4.613664 3.729824 -21.570358
v  5.185232 3.729824 -21.570358
v  -4.613664 4.200219 -22.307732
v  5.185232 4.200219 -22.307732
v  -0.801062 4.200219 -24.581932
v  1.372631 4.200219 -24.581932
v  1.312631 4.245454 -24.277323
v  -0.741063 4.245454 -24.277323
v  -2.694989 3.026584 -11.350576
v  3.266558 3.026584 -11.350576
v  -2.070187 3.214680 -11.350576
v  2.641756 3.214680 -11.350576
v  3.058116 3.105562 -11.350576
v  -2.486548 3.105562 -11.350576
v  3.475024 2.993225 -11.350576
v  -2.903456 2.993225 -11.350576
v  2.841906 3.158300 -11.350576
v  -2.270337 3.158300 -11.350576
v  -1.704693 3.308865 -11.350576
v  2.276262 3.308865 -11.350576
v  2.436269 3.257784 -11.350576
v  -1.864700 3.257784 -11.350576
v  4.270782 1.749376 -16.866400
v  -3.699213 1.749376 -16.866400
v  -3.699213 1.749376 -14.600877
v  4.270782 1.749376 -14.600877
v  -3.699213 1.749375 -12.279226
v  4.270782 1.749375 -12.279226
v  -0.783306 5.283676 -21.172367
v  4.270782 1.749376 -19.202934
v  -3.699213 1.749376 -19.202934
v  0.264597 6.359475 -10.375868
v  0.264597 5.675517 -10.443367
v  1.290269 6.844256 -10.850943
v  -0.718700 6.844256 -10.850943
v  -0.718700 5.312155 -10.738291
v  1.290269 5.312155 -10.738291
v  -0.718700 6.844257 -11.750669
v  1.290269 6.844257 -11.750669
v  1.290269 4.231917 -10.738291
v  -0.718700 4.231917 -10.738291
v  -0.206605 2.061189 -10.435867
v  0.778174 2.061189 -10.435867
v  -0.718700 5.312155 -11.739493
v  1.290269 5.312155 -11.739493
v  -0.206605 3.151155 -10.435867
v  0.778174 3.151155 -10.435867
v  1.290269 3.158878 -10.761021
v  -0.718700 3.158878 -10.761021
v  0.778174 4.231916 -10.435867
v  -0.206605 4.231916 -10.435867
v  -0.718700 2.061189 -10.761021
v  1.290269 2.061189 -10.761021
v  0.778174 5.304643 -10.435867
v  -0.206605 5.304643 -10.435867
v  -0.206606 6.844260 -10.435867
v  0.778175 6.844260 -10.435867
v  4.210782 1.515634 -12.001271
v  -3.639213 1.515634 -12.001271
v  4.210782 1.515634 -12.458467
v  4.210782 1.515634 -16.583004
v  4.210782 1.515634 -14.779762
v  -3.639213 1.515634 -12.458467
v  -3.639213 1.515634 -14.779762
v  -3.639213 1.515634 -16.583004
v  -3.639213 1.515634 -14.322567
v  4.210782 1.515634 -14.322567
v  4.210782 1.515634 -19.393700
v  -3.639213 1.515634 -17.040199
v  4.210782 1.515634 -17.040199
v  4.210782 1.515634 -18.936502
v  -0.741063 1.729888 -24.204582
v  -2.704309 1.865017 -11.313516
v  1.312631 1.729888 -24.204582
v  -4.553664 1.729888 -22.835171
v  3.275877 1.865016 -11.313516
v  -3.639213 1.515634 -18.936502
v  -3.639213 1.515634 -19.393700
v  5.125232 1.729888 -22.835171
v  1.350269 6.840348 -10.737877
v  -0.778700 6.840348 -10.737877
v  -0.718700 6.937651 -11.310738
v  1.290269 6.937651 -11.310738
v  -0.778700 6.840349 -11.637605
v  1.350269 6.840349 -11.637605
v  4.401841 1.515364 -20.514721
v  -4.613664 1.729617 -21.011137
v  -3.830273 1.515363 -20.514721
v  5.185232 1.729617 -21.011137
v  1.350269 4.228008 -10.625225
v  -0.778700 4.228008 -10.625225
v  -0.093540 2.057280 -10.375868
v  -0.093540 4.228008 -10.375868
v  0.665109 4.228008 -10.375868
v  1.350269 5.308246 -11.626429
v  -0.778700 5.308246 -11.626429
v  1.350269 5.308246 -10.625225
v  -0.778700 5.308246 -10.625225
v  -0.093540 3.147246 -10.375868
v  0.665108 3.147246 -10.375868
v  0.665109 6.840352 -10.375868
v  -0.093541 6.840352 -10.375868
v  0.665109 5.300735 -10.375868
v  -0.093540 5.300735 -10.375868
v  0.665108 2.057280 -10.375868
v  1.350269 3.154969 -10.647955
v  -0.778700 3.154969 -10.647955
v  3.667792 2.915778 -11.350576
v  -3.096224 2.915778 -11.350576
v  -1.226142 4.878368 -11.251311
v  -1.552437 4.878368 -11.222093
v  -1.567711 4.328736 -20.514265
v  1.797711 4.878368 -11.251311
v  0.285777 2.254524 -10.346466
v  2.139280 4.328736 -20.514265
v  2.124005 4.878368 -11.222093
v  0.285791 2.254524 -10.346466
v  0.285791 5.500954 -10.358526
v  0.285777 7.281226 -10.358526
v  0.285777 4.414524 -10.358526
v  0.285791 4.414524 -10.358526
v  0.285791 7.004093 -10.358526
v  0.285777 7.004093 -10.358526
v  0.285777 1.174523 -10.358526
v  0.285777 6.505998 -10.358526
v  0.285777 3.334522 -10.358526
v  0.285791 1.174523 -10.358526
v  0.285791 3.334522 -10.358526
v  0.285791 6.505998 -10.358526
v  0.285791 7.281226 -10.358526
v  0.285777 5.500954 -10.358526
v  0.387374 6.898493 -10.375868
v  -4.553664 1.665094 -21.408529
v  -4.227664 1.450840 -20.574722
v  4.799232 1.450840 -20.574722
v  5.125232 1.665094 -21.408529
v  5.185232 1.664906 -23.232544
v  4.270782 1.450653 -19.333874
v  3.673249 1.800035 -11.253515
v  4.270782 1.450653 -19.791071
v  -3.699213 1.450652 -12.398643
v  4.270782 1.450652 -12.398643
v  -3.101681 1.800035 -11.253515
v  -4.613664 1.664906 -23.232544
v  -3.699213 1.450652 -16.980375
v  -3.699213 1.450652 -17.437572
v  -3.699213 1.450652 -14.719938
v  -3.699213 1.450652 -19.333874
v  -3.699213 1.450652 -19.791071
v  4.270782 1.450653 -16.980375
v  4.270782 1.450653 -17.437572
v  4.270782 1.450653 -14.719938
v  -3.699213 1.450652 -15.177135
v  4.270782 1.450653 -15.177135
v  -3.699213 1.450652 -12.855839
v  1.372631 1.664907 -24.601954
v  -0.801062 1.664906 -24.601954
v  4.270782 1.450652 -12.855839
v  0.285791 5.452144 -10.348189
v  0.285777 7.232417 -10.348189
v  0.285777 4.365715 -10.348189
v  0.285791 4.365715 -10.348189
v  0.285777 6.955283 -10.348189
v  0.285777 1.125713 -10.348189
v  0.285777 6.457188 -10.348189
v  0.285777 3.285712 -10.348189
v  0.285791 1.125713 -10.348189
v  0.285791 7.232417 -10.348189
v  0.285791 6.457188 -10.348189
v  0.285791 6.955283 -10.348189
v  0.285777 5.452144 -10.348189
v  0.285791 3.285712 -10.348189
v  1.377928 2.205714 -11.253489
v  -0.806360 2.205714 -11.253489
v  -3.712598 3.778071 -20.514721
v  4.284166 3.778071 -20.514721
v  1.437092 1.864810 -11.260997
v  -0.865524 1.864810 -11.260997
v  0.233326 6.937656 -10.443367
v  -1.966578 3.291807 -11.290576
v  2.538146 3.291807 -11.290576
v  2.738297 3.235427 -11.290576
v  -2.166728 3.235427 -11.290576
v  2.172652 3.385992 -11.290576
v  -1.601084 3.385992 -11.290576
v  -1.761091 3.334910 -11.290576
v  2.332659 3.334910 -11.290576
v  -0.778700 6.933743 -11.197672
v  1.350269 6.933743 -11.197672
v  3.858044 2.863894 -11.350576
v  -3.286476 2.863894 -11.350576
v  -4.553664 3.931514 -21.474466
v  5.125232 3.931514 -21.474466
v  5.185232 3.930914 -23.028786
v  -4.613664 3.930914 -23.028786
v  5.125232 3.921221 -22.745762
v  -4.553664 3.921221 -22.745762
v  -1.146104 1.874239 -11.320996
v  1.717673 1.874239 -11.320996
v  -0.778700 1.192344 -11.260997
v  -0.778700 1.192344 -11.253515
v  -4.613664 3.920655 -21.191471
v  5.185232 3.920655 -21.191473
v  -0.798284 5.331444 -23.312563
v  1.369853 5.331444 -23.312563
v  1.352566 5.219107 -20.842512
v  -0.039563 5.219107 -20.514290
v  0.611132 5.219107 -20.514290
v  -0.780998 5.219107 -20.842512
v  -0.398142 5.149858 -20.514290
v  0.969711 5.149858 -20.514290
v  -0.796149 5.219107 -23.007502
v  1.355076 5.149858 -21.201090
v  1.370227 5.149858 -23.366081
v  -0.798659 5.149858 -23.366081
v  1.367718 5.219107 -23.007504
v  -1.478330 4.033134 -19.614483
v  -2.848233 4.033134 -20.574596
v  3.419801 4.033134 -20.574596
v  -1.478330 4.033134 -17.203760
v  2.049898 4.033134 -17.203760
v  2.049898 4.033134 -19.614483
v  4.725209 4.033134 -20.574722
v  -4.153641 4.033134 -20.574722
v  2.049898 4.033134 -14.868715
v  -1.478330 4.033134 -14.868715
v  2.049898 4.033134 -12.533670
v  -1.478330 4.033134 -12.533670
v  4.384610 4.023817 -20.514721
v  -3.813041 4.023817 -20.514721
v  -1.538330 4.023817 -19.273884
v  -2.507643 4.023816 -20.514545
v  3.079211 4.023816 -20.514545
v  2.109898 4.023817 -19.273884
v  -1.538330 4.023817 -14.528116
v  -1.538330 4.023817 -16.863161
v  2.109898 4.023817 -14.528116
v  2.109898 4.023817 -16.863161
v  -1.538330 4.023817 -12.193071
v  2.109898 4.023817 -12.193071
v  -0.796382 5.323257 -23.040802
v  -0.072864 5.323258 -20.514290
v  0.644433 5.323258 -20.514290
v  -0.781231 5.323257 -20.875813
v  -4.553664 3.931514 -23.029459
v  5.125232 3.931514 -23.029461
v  5.185232 3.930914 -21.473791
v  -4.613664 3.930914 -21.473791
v  5.125232 3.966694 -21.233845
v  -4.553664 3.966694 -21.233845
v  -4.613664 3.966038 -22.789455
v  5.185232 3.966038 -22.789457
v  -0.796017 2.254524 -11.243605
v  1.367586 2.254524 -11.243605
v  0.396627 1.032896 -10.375868
v  -0.783434 5.223945 -21.190641
v  -0.369419 5.283676 -20.514290
v  0.940988 5.283676 -20.514290
v  1.370026 5.283676 -23.337357
v  1.354875 5.283676 -21.172367
v  -0.798458 5.283676 -23.337357
v  2.978768 3.778070 -20.514530
v  -2.407199 3.778070 -20.514530
v  2.109898 4.068354 -14.814230
v  -1.538330 4.068354 -14.814230
v  -1.538330 4.068354 -12.479185
v  2.109898 4.068354 -12.479185
v  -1.538330 4.068354 -19.559998
v  -1.538330 4.068354 -17.149275
v  2.109898 4.068354 -17.149275
v  2.109898 4.068354 -19.559996
v  -4.099154 4.068353 -20.514721
v  4.670723 4.068353 -20.514721
v  -2.793756 4.068353 -20.514587
v  3.365324 4.068353 -20.514587
v  4.210782 1.631382 -19.705246
v  4.210782 1.631382 -17.351746
v  4.210782 1.631382 -15.091310
v  -3.015856 1.980764 -11.313516
v  3.587424 1.980764 -11.313516
v  -4.553664 1.845635 -23.146717
v  -3.639213 1.631381 -15.091310
v  4.210782 1.631382 -14.634113
v  4.210782 1.631382 -16.894550
v  5.125232 1.845635 -23.146717
v  -3.639213 1.631381 -12.312819
v  -3.639213 1.631381 -19.705246
v  -3.639213 1.631381 -17.351746
v  -0.741063 1.845635 -24.516129
v  4.210782 1.631381 -12.312819
v  -3.639213 1.631381 -16.894550
v  -3.639213 1.631381 -14.634113
v  4.210782 1.631382 -19.248049
v  1.312631 1.845635 -24.516129
v  -3.639213 1.631381 -12.770014
v  -3.639213 1.631381 -19.248049
v  4.210782 1.631381 -12.770014
v  2.049898 3.778092 -19.172541
v  -1.478330 3.778092 -14.426773
v  -1.478330 3.778092 -16.761818
v  -1.478330 3.778092 -19.172541
v  2.049898 3.778092 -14.426773
v  2.049898 3.778092 -12.091728
v  -1.478330 3.778092 -12.091728
v  2.049898 3.778092 -16.761818
v  -4.140281 1.630781 -20.514721
v  4.711850 1.630781 -20.514721
v  -4.613664 1.845035 -21.321146
v  5.185232 1.845035 -21.321146
v  2.049898 4.062652 -12.244284
v  -1.478330 4.062652 -12.244284
v  2.049898 4.062652 -14.579329
v  -1.478330 4.062652 -14.579329
v  2.049898 4.062652 -19.325096
v  -1.478330 4.062652 -19.325096
v  -1.478330 4.062652 -16.914373
v  2.049898 4.062652 -16.914373
v  3.130414 4.062651 -20.574553
v  -2.558846 4.062651 -20.574553
v  4.435822 4.062651 -20.574722
v  -3.864254 4.062651 -20.574722
v  -0.801062 3.815108 -24.722042
v  1.372631 3.815108 -24.722042
v  5.125232 1.836413 -21.067635
v  4.458337 1.622159 -20.574722
v  -3.886769 1.622159 -20.574722
v  -4.553664 1.836413 -21.067635
v  4.270782 1.621593 -12.059336
v  -3.699213 1.621593 -12.059336
v  -3.699213 1.621593 -12.516531
v  4.270782 1.621593 -12.516531
v  3.333941 1.970976 -11.253515
v  -2.762373 1.970976 -11.253515
v  4.270782 1.621593 -19.451763
v  -3.699213 1.621594 -18.994566
v  4.270782 1.621593 -14.837827
v  -3.699213 1.621593 -14.837827
v  -3.699213 1.621593 -16.641068
v  -3.699213 1.621594 -19.451763
v  -3.699213 1.621593 -14.380630
v  4.270782 1.621593 -17.098263
v  4.270782 1.621593 -14.380630
v  4.270782 1.621593 -16.641068
v  4.270782 1.621593 -18.994566
v  5.185232 1.835847 -22.893236
v  -3.699213 1.621593 -17.098263
v  -4.613664 1.835847 -22.893236
v  1.372631 1.835847 -24.262646
v  -0.801062 1.835847 -24.262646
v  -0.864645 1.865017 -11.320996
v  1.436214 1.865017 -11.320996
v  -2.687511 1.708401 -11.253515
v  3.259080 1.708401 -11.253515
v  4.712524 1.631381 -20.574722
v  -4.140956 1.631381 -20.574722
v  -4.553664 1.845635 -21.321819
v  5.125232 1.845635 -21.321819
v  4.270782 1.630782 -15.090634
v  -4.613664 1.845035 -23.146042
v  5.185232 1.845035 -23.146042
v  -3.699213 1.630781 -15.090634
v  -0.801062 1.845035 -24.515453
v  4.270782 1.630782 -16.893875
v  4.270782 1.630782 -17.351070
v  4.270782 1.630782 -19.704571
v  4.270782 1.630782 -14.633438
v  -3.699213 1.630781 -17.351070
v  -3.015181 1.980164 -11.253515
v  3.586749 1.980164 -11.253515
v  -3.699213 1.630781 -16.893875
v  -3.699213 1.630781 -14.633438
v  1.372631 1.845035 -24.515453
v  -3.699213 1.630781 -19.704571
v  -3.699213 1.630781 -12.312143
v  4.270782 1.630781 -12.312143
v  4.270782 1.630782 -19.247374
v  -3.699213 1.630781 -12.769339
v  4.270782 1.630781 -12.769339
v  -3.699213 1.630781 -19.247374
v  1.312631 1.877156 -24.300537
v  -0.741063 1.877156 -24.300537
v  -3.639213 1.662902 -17.136154
v  4.210782 1.662902 -17.136154
v  4.210782 1.662902 -19.489655
v  -3.639213 1.662902 -14.418522
v  -3.639213 1.662902 -19.489655
v  -4.553664 1.877156 -22.931126
v  4.210782 1.662902 -14.418522
v  -3.639213 1.662902 -19.032457
v  4.210782 1.662902 -12.097227
v  -3.639213 1.662902 -12.097227
v  4.210782 1.662902 -19.032457
v  -3.639213 1.662902 -14.875718
v  -2.800264 2.012284 -11.313516
v  -3.639213 1.662902 -16.678959
v  5.125232 1.877156 -22.931128
v  4.210782 1.662902 -12.554422
v  -3.639213 1.662902 -12.554422
v  3.371832 2.012284 -11.313516
v  4.210782 1.662902 -16.678959
v  4.210782 1.662902 -14.875718
v  -4.613664 1.876500 -21.106846
v  5.185232 1.876500 -21.106846
v  -3.925982 1.662246 -20.514721
v  4.497551 1.662246 -20.514721
v  -2.406291 3.778092 -20.574532
v  2.977859 3.778092 -20.574532
v  0.264597 5.675517 -10.375868
v  0.264597 6.359475 -10.443367
v  -0.778700 2.060416 -10.760557
v  1.350269 2.060416 -10.760557
v  0.603292 4.161148 -10.375868
v  0.603292 3.080386 -10.375868
v  -0.031723 4.161148 -10.375868
v  -0.031723 3.080386 -10.375868
v  0.603292 1.990420 -10.375868
v  -0.031723 1.990420 -10.375868
v  -0.778700 4.161148 -10.563408
v  1.350269 4.161148 -10.563408
v  -0.778700 5.241386 -11.564611
v  -0.778700 5.241386 -10.563408
v  1.350269 5.241386 -11.564611
v  1.350269 5.241386 -10.563408
v  0.603292 5.233874 -10.375868
v  -0.031723 5.233874 -10.375868
v  -0.778700 3.088109 -10.586138
v  1.350269 3.088109 -10.586138
v  1.350269 1.990420 -10.586138
v  -0.778700 1.990420 -10.586138
v  1.350269 6.866885 -11.135855
v  -0.778700 6.866885 -11.135855
v  1.366438 2.189062 -11.244757
v  -0.794869 2.189062 -11.244757
v  1.350269 6.773489 -11.575788
v  -0.778700 6.773489 -11.575788
v  1.350269 6.773488 -10.676060
v  -0.778700 6.773488 -10.676060
v  0.603292 6.773491 -10.375868
v  -0.031724 6.773491 -10.375868
v  -1.161419 1.800081 -11.260997
v  1.732988 1.800081 -11.260997
v  -1.097502 1.980764 -11.320996
v  1.669071 1.980764 -11.320996
v  4.210782 1.856057 -16.830833
v  -3.639213 1.856057 -16.830833
v  4.210782 1.856056 -12.243658
v  -3.639213 1.856056 -12.243658
v  4.210782 1.856056 -14.565310
v  -3.639213 1.856056 -14.565310
v  4.210782 1.856057 -19.167366
v  -3.639213 1.856057 -19.167366
v  1.372631 1.573273 -24.187786
v  -0.801062 1.573273 -24.187784
v  5.185232 1.573272 -22.818375
v  5.185232 1.573272 -20.993477
v  -4.613664 1.573272 -20.993477
v  -4.613664 1.573272 -22.818375
v  1.367951 5.323257 -23.040802
v  0.428500 6.795747 -10.443367
v  0.143068 6.795747 -10.375868
v  1.290269 6.871461 -11.378922
v  -0.718700 6.871461 -11.378922
v  0.165141 6.871465 -10.443367
v  -0.274791 6.778068 -10.435867
v  0.846359 6.778068 -10.435867
v  0.846358 1.994997 -10.435867
v  -0.274790 1.994997 -10.435867
v  -0.718700 4.165724 -10.806476
v  1.290269 4.165724 -10.806476
v  -0.718700 5.245963 -10.806476
v  1.290269 5.245963 -10.806476
v  0.846358 3.084962 -10.435867
v  -0.274790 3.084962 -10.435867
v  -0.274790 4.165725 -10.435867
v  0.846359 4.165725 -10.435867
v  -0.718700 6.778064 -10.919127
v  1.290269 6.778064 -10.919127
v  -0.274790 5.238451 -10.435867
v  0.846359 5.238451 -10.435867
v  -0.718700 1.994997 -10.829205
v  1.290269 1.994997 -10.829205
v  1.290269 3.092686 -10.829205
v  -0.718700 3.092686 -10.829205
v  -0.718700 5.245963 -11.807678
v  1.290269 5.245963 -11.807678
v  1.290269 6.778065 -11.818853
v  -0.718700 6.778065 -11.818853
v  1.479859 1.971076 -11.260997
v  -0.908290 1.971076 -11.260997
v  1.354491 5.369642 -21.117563
v  -0.314613 5.369642 -20.514290
v  0.886182 5.369642 -20.514290
v  -0.782922 5.369642 -21.117561
v  4.210782 1.991607 -14.625659
v  4.210782 1.856057 -17.195635
v  4.210782 1.856057 -19.532167
v  -3.639213 1.856057 -17.195635
v  4.210782 2.037458 -19.330700
v  -3.639213 1.991606 -14.625659
v  4.210782 2.029530 -16.956869
v  -3.639213 2.037458 -19.330700
v  4.210782 2.037457 -12.445126
v  4.210782 2.037458 -14.766776
v  4.210782 1.720505 -12.304008
v  4.210782 1.720506 -19.471817
v  -3.639213 2.037457 -12.445126
v  -3.639213 2.029530 -16.956869
v  -3.639213 1.748844 -19.497332
v  -3.639213 1.930246 -19.516399
v  -3.639213 1.930246 -14.914341
v  -3.639213 1.682582 -12.369694
v  -3.639213 2.029529 -12.369694
v  -3.639213 2.029530 -14.691344
v  -3.639213 1.991607 -16.891184
v  -3.639213 1.674654 -16.994167
v  4.210782 1.674654 -14.728643
v  4.210782 1.674655 -19.330700
v  -3.639213 1.674654 -14.728643
v  4.210782 1.674654 -12.406993
v  -3.639213 1.674655 -19.330700
v  -3.639213 2.037458 -17.032301
v  -3.639213 1.856057 -19.532167
v  4.210782 1.720506 -17.135283
v  4.210782 1.991606 -12.304008
v  -3.639213 1.720505 -12.304008
v  -3.639213 2.029530 -14.804074
v  4.210782 2.029530 -19.406132
v  4.210782 1.682582 -16.956869
v  4.210782 1.930245 -14.914341
v  4.210782 2.029530 -14.691344
v  4.210782 2.029529 -12.369694
v  -3.639213 2.014020 -19.258568
v  -3.639213 1.698093 -19.258568
v  -3.639213 1.748844 -14.895275
v  4.210782 1.720506 -14.625660
v  4.210782 2.014020 -19.258568
v  4.210782 1.720506 -19.227716
v  4.210782 1.748845 -14.895275
v  -3.639213 1.720506 -17.135283
v  -3.639213 2.014019 -12.517259
v  4.210782 2.014020 -14.838909
v  4.210782 2.037458 -17.032301
v  -3.639213 1.720506 -14.625660
v  4.210782 1.930246 -19.516397
v  4.210782 1.781867 -19.183136
v  4.210782 1.930246 -17.179865
v  -3.639213 2.029530 -19.406132
v  4.210782 1.991607 -16.891184
v  4.210782 1.930246 -19.183136
v  -3.639213 1.991606 -14.869759
v  -3.639213 1.991607 -17.135283
v  -3.639213 1.991606 -12.304008
v  -3.639213 1.781867 -14.581079
v  -3.639213 1.781868 -19.183136
v  -3.639213 1.930246 -17.179865
v  -3.639213 1.930246 -19.183136
v  -3.639213 1.930246 -14.581079
v  -3.639213 1.781868 -17.179865
v  -3.639213 1.991607 -19.471817
v  4.210782 2.014019 -12.517259
v  -3.639213 1.720506 -16.891184
v  4.210782 1.781868 -19.516397
v  4.210782 1.930245 -14.581079
v  4.210782 1.781868 -17.179865
v  4.210782 1.781867 -14.581079
v  4.210782 1.720506 -16.891184
v  -3.639213 1.682583 -19.406132
v  4.210782 1.781867 -16.846603
v  4.210782 1.991607 -19.471817
v  4.210782 1.991607 -17.135283
v  4.210782 1.682583 -19.406132
v  4.210782 1.930246 -16.846603
v  -3.639213 1.781868 -16.846603
v  -3.639213 1.930246 -16.846603
v  -3.639213 1.682583 -14.804074
v  4.210782 1.682582 -12.482424
v  4.210782 1.682583 -14.804074
v  -3.639213 1.682583 -17.069599
v  -3.639213 1.682582 -12.482424
v  4.210782 1.682583 -17.069599
v  -3.639213 1.781867 -12.259428
v  -3.639213 1.930245 -12.259428
v  4.210782 1.781867 -12.259428
v  4.210782 1.930245 -12.259428
v  4.210782 1.856056 -12.608459
v  -3.639213 1.856056 -12.608459
v  -3.639213 1.963268 -12.573623
v  -3.639213 1.856056 -14.930110
v  4.210782 1.856056 -14.930110
v  4.210782 1.963268 -12.573623
v  -3.639213 1.748844 -12.573623
v  4.210782 1.748844 -12.573623
v  4.270782 1.962737 -16.866400
v  -3.699213 1.856057 -17.194729
v  4.270782 1.929877 -14.913514
v  4.270782 1.856057 -17.194729
v  -3.699213 2.013236 -14.656961
v  4.270782 1.962737 -14.600877
v  -3.699213 2.036558 -14.728738
v  -3.699213 2.028668 -12.369973
v  -3.699213 1.675554 -14.728738
v  -3.699213 1.929877 -14.913514
v  -3.699213 2.028669 -17.069319
v  -3.699213 1.962737 -14.600877
v  -3.699213 1.675554 -16.994263
v  -3.699213 1.675555 -19.330795
v  4.270782 2.028669 -14.691624
v  4.270782 1.675554 -14.728738
v  -3.699213 1.683442 -12.369973
v  4.270782 1.683443 -16.957148
v  -3.699213 1.721179 -19.471210
v  4.270782 1.721179 -19.471210
v  -3.699213 2.036558 -16.994263
v  4.270782 2.028668 -12.369973
v  4.270782 1.990934 -19.228321
v  4.270782 1.675554 -12.407087
v  4.270782 1.721178 -14.626266
v  4.270782 1.929878 -17.179039
v  -3.699213 2.028669 -14.803795
v  4.270782 2.013237 -16.922485
v  -3.699213 1.990934 -19.228321
v  4.270782 1.818321 -19.527296
v  -3.699213 1.990934 -16.891788
v  -3.699213 1.929878 -19.515572
v  -3.699213 2.028669 -19.293682
v  -3.699213 1.782236 -19.515572
v  4.270782 1.675555 -19.330795
v  4.270782 2.036558 -16.994263
v  -3.699213 1.749376 -14.894543
v  -3.699213 1.721179 -17.134678
v  4.270782 1.990934 -14.869154
v  -3.699213 2.028668 -12.482143
v  4.270782 1.782236 -17.179039
v  4.270782 2.028669 -14.803795
v  -3.699213 1.856057 -19.531261
v  -3.699213 1.721179 -19.228323
v  -3.699213 2.028669 -19.405851
v  4.270782 1.721178 -12.304615
v  -3.699213 1.721178 -12.304615
v  4.270782 2.028669 -19.405851
v  -3.699213 1.721178 -14.626266
v  4.270782 2.028668 -12.482143
v  4.270782 1.893792 -19.527296
v  -3.699213 1.990934 -14.869153
v  4.270782 1.683444 -19.405851
v  4.270782 2.028669 -19.293682
v  -3.699213 1.990934 -17.134678
v  4.270782 1.721179 -17.134678
v  -3.699213 1.990933 -12.304615
v  -3.699213 1.929878 -17.179039
v  -3.699213 1.782236 -17.179039
v  -3.699213 1.990934 -19.471210
v  4.270782 1.990934 -19.471210
v  4.270782 1.990933 -12.304615
v  4.270782 1.683443 -14.803795
v  4.270782 1.749376 -14.894543
v  4.270782 1.721179 -16.891788
v  -3.699213 1.721179 -16.891790
v  4.270782 1.683443 -12.482143
v  4.270782 2.013237 -17.103981
v  -3.699213 1.683444 -19.405851
v  -3.699213 1.683444 -17.069319
v  -3.699213 1.683443 -14.803795
v  4.270782 1.683444 -17.069319
v  4.270782 1.721179 -19.228323
v  -3.699213 1.683443 -12.482143
v  4.270782 1.856056 -14.929205
v  -3.699213 1.856056 -14.929205
v  4.270782 1.856056 -12.607554
v  -3.699213 1.856056 -12.607554
v  4.270782 1.962736 -12.572891
v  -3.699213 1.962736 -12.572891
v  4.270782 1.749376 -12.572891
v  -3.699213 1.749376 -12.572891
v  -0.778700 3.016883 -10.850376
v  1.350269 3.016883 -10.850376
v  1.352992 5.362199 -20.903328
v  -0.100379 5.362199 -20.514290
v  0.671948 5.362199 -20.514290
v  -0.796575 5.362199 -23.068317
v  1.368143 5.362199 -23.068317
v  0.867531 3.009160 -10.375868
v  -0.295962 3.009160 -10.375868
v  0.867531 1.919194 -10.375868
v  -0.295962 1.919194 -10.375868
v  -0.718700 6.773854 -11.574957
v  -0.718700 6.773854 -10.675230
v  1.290269 6.773854 -10.675230
v  1.290269 6.773854 -11.574957
v  -0.030893 1.990786 -10.435867
v  0.602461 1.990786 -10.435867
v  -0.030893 3.080751 -10.435867
v  -0.030893 4.161513 -10.435867
v  0.602461 3.080751 -10.435867
v  0.602462 4.161513 -10.435867
v  -0.718700 6.867250 -11.135025
v  1.290269 6.867250 -11.135025
v  -0.718700 5.241752 -11.563782
v  -0.718700 4.161513 -10.562578
v  1.290269 4.161513 -10.562578
v  1.290269 5.241752 -11.563782
v  -0.718700 5.241752 -10.562578
v  1.290269 5.241752 -10.562578
v  -0.030893 6.773857 -10.435867
v  0.602462 6.773857 -10.435867
v  -0.030893 5.234240 -10.435867
v  0.602462 5.234240 -10.435867
v  1.290269 3.088475 -10.585308
v  -0.718700 3.088475 -10.585308
v  1.290269 1.990786 -10.585308
v  -0.718700 1.990786 -10.585308
v  4.270782 1.359018 -14.305769
v  4.270782 1.359018 -14.762965
v  -3.699213 1.359018 -14.305769
v  -3.699213 1.359018 -14.762965
v  4.270782 1.359018 -12.441669
v  -3.699213 1.359018 -12.441669
v  4.270782 1.359018 -16.566206
v  4.270782 1.359019 -18.919704
v  -3.699213 1.359019 -18.919704
v  -3.699213 1.359019 -19.376902
v  4.270782 1.359018 -17.023403
v  4.384181 1.359018 -20.514721
v  -3.812612 1.359018 -20.514721
v  -3.699213 1.359018 -17.023401
v  -3.699213 1.359018 -16.566206
v  -3.699213 1.359018 -11.984475
v  4.270782 1.359018 -11.984475
v  4.270782 1.359019 -19.376902
v  1.350269 6.795658 -11.400095
v  -0.778700 6.795658 -11.400095
v  -0.778700 5.170160 -11.828852
v  1.350269 5.170160 -11.828852
v  1.350269 5.170160 -10.827648
v  -0.778700 5.170160 -10.827648
v  1.350269 4.089921 -10.827648
v  -0.778700 4.089921 -10.827648
v  0.867531 4.089921 -10.375868
v  -0.295963 4.089921 -10.375868
v  0.867532 6.702265 -10.375868
v  -0.295963 6.702265 -10.375868
v  1.350269 6.702262 -11.840027
v  -0.778700 6.702262 -11.840027
v  1.350269 6.702261 -10.940300
v  -0.778700 6.702261 -10.940300
v  1.350276 2.189062 -11.253489
v  -0.778707 2.189062 -11.253489
v  0.420471 6.837667 -10.375868
v  1.350269 1.960940 -10.842381
v  -0.778700 1.960940 -10.842381
v  -0.778796 2.272325 -11.260881
v  1.350365 2.272325 -11.260881
v  1.668320 1.980263 -11.260997
v  -1.096751 1.980263 -11.260997
v  1.479085 1.971542 -11.320996
v  -0.907517 1.971542 -11.320996
v  0.867531 5.162648 -10.375868
v  -0.295963 5.162648 -10.375868
v  0.165141 6.871465 -10.375868
v  1.350269 6.871024 -11.378130
v  -0.778700 6.871024 -11.378130
v  1.350269 6.777627 -10.918334
v  -0.778700 6.777627 -10.918334
v  0.845566 6.777631 -10.375868
v  -0.273998 6.777631 -10.375868
v  0.845565 3.084525 -10.375868
v  0.845566 4.165287 -10.375868
v  -0.273997 4.165287 -10.375868
v  -0.273997 3.084525 -10.375868
v  1.350269 4.165287 -10.805683
v  -0.778700 4.165287 -10.805683
v  -0.273997 1.994560 -10.375868
v  1.350269 5.245526 -10.805683
v  -0.778700 5.245526 -10.805683
v  0.845565 1.994560 -10.375868
v  -0.273997 5.238014 -10.375868
v  0.845566 5.238014 -10.375868
v  1.350269 6.777627 -11.818061
v  -0.778700 6.777627 -11.818061
v  1.350269 3.092249 -10.828411
v  -0.778700 3.092249 -10.828411
v  -0.778700 5.245526 -11.806885
v  1.350269 5.245526 -11.806885
v  -3.699213 1.893792 -19.172237
v  4.270782 1.893792 -19.172237
v  -3.711698 3.778092 -20.574722
v  4.283266 3.778092 -20.574722
v  0.174941 1.032896 -10.464714
v  4.270782 1.893791 -12.248529
v  -3.699213 1.893791 -12.248529
v  -3.699213 1.893792 -14.570180
v  4.270782 1.893792 -14.570180
v  -3.699213 1.893792 -16.835705
v  4.270782 1.893792 -16.835705
v  2.748987 3.248023 -11.350576
v  3.173639 3.116307 -11.350576
v  -2.602071 3.116307 -11.350576
v  -2.177419 3.248023 -11.350576
v  -2.810538 3.082947 -11.350576
v  3.382106 3.082947 -11.350576
v  -1.611775 3.398588 -11.350576
v  2.183343 3.398588 -11.350576
v  2.343351 3.347506 -11.350576
v  -1.771782 3.347506 -11.350576
v  -1.977269 3.304403 -11.350576
v  2.548838 3.304403 -11.350576
v  -2.393629 3.195284 -11.350576
v  2.965198 3.195284 -11.350576
v  3.224828 3.117661 -11.290576
v  -2.653260 3.117661 -11.290576
v  -2.028458 3.305757 -11.290576
v  2.600027 3.305757 -11.290576
v  3.433295 3.084302 -11.290576
v  -2.861727 3.084302 -11.290576
v  2.234532 3.399942 -11.290576
v  -1.662964 3.399942 -11.290576
v  3.016387 3.196639 -11.290576
v  -2.444818 3.196639 -11.290576
v  -1.822971 3.348860 -11.290576
v  2.394539 3.348860 -11.290576
v  2.800176 3.249377 -11.290576
v  -2.228608 3.249377 -11.290576
v  0.387374 6.898493 -10.443367
v  3.579545 3.000989 -11.350576
v  -3.007977 3.000989 -11.350576
v  -1.703793 3.308805 -11.290576
v  2.275362 3.308805 -11.290576
v  2.435369 3.257723 -11.290576
v  -1.863800 3.257723 -11.290576
v  2.640856 3.214619 -11.290576
v  -2.069287 3.214619 -11.290576
v  2.841006 3.158240 -11.290576
v  -2.269437 3.158240 -11.290576
v  3.057216 3.105502 -11.290576
v  -2.485648 3.105502 -11.290576
v  3.474124 2.993165 -11.290576
v  -2.902556 2.993165 -11.290576
v  3.265658 3.026524 -11.290576
v  -2.694089 3.026524 -11.290576
v  1.350269 2.021431 -10.809469
v  -0.778700 2.021431 -10.809469
v  0.174941 1.032896 -10.375868
v  0.396627 1.032896 -10.464714
v  -2.051439 3.281119 -11.350576
v  2.623007 3.281119 -11.350576
v  3.247809 3.093023 -11.350576
v  -2.676240 3.093023 -11.350576
v  -2.884707 3.059664 -11.350576
v  3.456276 3.059664 -11.350576
v  3.039367 3.172001 -11.350576
v  -2.467799 3.172001 -11.350576
v  -1.685944 3.375304 -11.350576
v  2.257513 3.375304 -11.350576
v  2.823157 3.224739 -11.350576
v  -2.251588 3.224739 -11.350576
v  2.417520 3.324222 -11.350576
v  -1.845952 3.324222 -11.350576
v  -3.206013 2.941589 -11.350576
v  3.777581 2.941589 -11.350576
v  -2.366160 3.151531 -11.290576
v  2.937729 3.151531 -11.290576
v  3.354638 3.039194 -11.290576
v  -2.783069 3.039194 -11.290576
v  -2.574602 3.072552 -11.290576
v  3.146171 3.072552 -11.290576
v  3.821831 2.942669 -11.290576
v  -3.250262 2.942669 -11.290576
v  -3.699213 1.818321 -16.835705
v  4.270782 1.818321 -16.835705
v  5.125232 3.815168 -21.842188
v  -4.553664 3.815168 -21.842188
v  -3.699213 1.818321 -14.570180
v  4.270782 1.818321 -14.570180
v  4.270782 1.818320 -12.248529
v  -3.699213 1.818320 -12.248529
v  1.372631 3.815153 -24.117289
v  -0.801062 3.815153 -24.117289
v  3.649986 2.978877 -11.350576
v  -3.078418 2.978877 -11.350576
v  -3.699213 1.818321 -19.172237
v  4.270782 1.818321 -19.172237
v  -3.285576 2.863834 -11.290576
v  3.857144 2.863834 -11.290576
v  3.553501 2.959421 -11.290576
v  -2.981932 2.959421 -11.290576
v  1.354254 5.400661 -21.083639
v  -0.782685 5.400661 -21.083639
v  -0.797837 5.400661 -23.248629
v  -0.280690 5.400661 -20.514290
v  0.852259 5.400661 -20.514290
v  1.369405 5.400661 -23.248629
v  -0.718700 6.915890 -11.172176
v  1.290269 6.915890 -11.172176
v  0.364312 6.920105 -10.375868
v  -3.270240 2.921427 -11.350576
v  3.841809 2.921427 -11.350576
v  -0.781651 5.395079 -20.935856
v  -0.796802 5.395079 -23.100847
v  -0.132908 5.395079 -20.514290
v  0.704477 5.395079 -20.514290
v  5.185232 4.300543 -22.189518
v  -4.613664 4.300543 -22.189518
v  3.258180 1.708416 -11.313516
v  -2.686611 1.708416 -11.313516
v  5.125232 1.573288 -20.992577
v  -0.741063 1.573288 -24.186884
v  -4.553664 1.573288 -20.992577
v  1.312631 1.573288 -24.186886
v  5.125232 1.573288 -22.817474
v  -4.553664 1.573288 -22.817474
v  -3.182342 2.903664 -11.290576
v  3.753911 2.903664 -11.290576
v  1.290269 6.920103 -11.336806
v  -0.718700 6.920103 -11.336806
v  0.207257 6.920105 -10.443367
v  -0.718700 2.043638 -10.787089
v  1.290269 2.043638 -10.787089
v  -0.232675 6.826709 -10.435867
v  0.804244 6.826709 -10.435867
v  -0.718700 5.294603 -11.765563
v  1.290269 5.294603 -11.765563
v  -0.718700 4.214365 -10.764360
v  1.290269 4.214365 -10.764360
v  1.290269 3.141327 -10.787089
v  -0.718700 3.141327 -10.787089
v  -0.232674 5.287092 -10.435867
v  0.804243 5.287092 -10.435867
v  0.804242 3.133603 -10.435867
v  -0.232674 3.133603 -10.435867
v  1.290269 6.826706 -11.776737
v  -0.718700 6.826706 -11.776737
v  0.804242 2.043638 -10.435867
v  -0.232674 2.043638 -10.435867
v  -0.232674 4.214365 -10.435867
v  0.804243 4.214365 -10.435867
v  -0.718700 6.826706 -10.877008
v  1.290269 6.826706 -10.877008
v  -0.718700 5.294603 -10.764360
v  1.290269 5.294603 -10.764360
v  5.185232 4.295351 -22.063950
v  -4.613664 4.295351 -22.063950
v  4.210782 1.359034 -14.304869
v  4.210782 1.359034 -14.762065
v  -3.639213 1.359034 -14.304869
v  -3.639213 1.359034 -14.762065
v  4.210782 1.359034 -12.440769
v  -3.639213 1.359034 -12.440769
v  -3.639213 1.359034 -19.376001
v  -3.639213 1.359034 -18.918804
v  4.383280 1.359034 -20.574722
v  -3.811712 1.359034 -20.574722
v  4.210782 1.359034 -18.918804
v  4.210782 1.359034 -19.376001
v  4.210782 1.359034 -11.983575
v  -3.639213 1.359034 -11.983575
v  -3.639213 1.359034 -17.022503
v  4.210782 1.359034 -17.022503
v  4.210782 1.359034 -16.565308
v  -0.778700 3.136438 -10.623055
v  1.350269 3.136438 -10.623055
v  1.350269 2.038749 -10.623055
v  -3.639213 1.359034 -16.565308
v  -0.778700 2.038749 -10.623055
v  -0.068641 3.128715 -10.375868
v  -0.068641 4.209477 -10.375868
v  0.640209 3.128715 -10.375868
v  0.640210 4.209477 -10.375868
v  0.640209 2.038749 -10.375868
v  -0.068641 5.282204 -10.375868
v  0.640210 6.821821 -10.375868
v  0.640210 5.282204 -10.375868
v  -0.068641 6.821821 -10.375868
v  -0.068641 2.038749 -10.375868
v  1.350269 4.209476 -10.600327
v  -0.778700 4.209476 -10.600327
v  1.350269 5.289715 -10.600327
v  -0.778700 5.289715 -11.601529
v  1.350269 5.289715 -11.601529
v  -0.778700 5.289715 -10.600327
v  1.350269 6.915213 -11.172773
v  -0.778700 6.915213 -11.172773
v  1.350269 6.821816 -11.612705
v  -0.778700 6.821816 -11.612705
v  -0.778700 6.821816 -10.712978
v  1.350269 6.821816 -10.712978
v  1.350269 6.516162 -11.253515
v  -0.778700 6.516162 -11.253515
v  -0.850517 1.192344 -11.260997
v  1.422086 1.192344 -11.260997
v  5.125232 4.301280 -22.190037
v  -0.741063 4.301280 -24.464233
v  1.312631 4.301280 -24.464233
v  -4.553664 4.301280 -22.190035
v  0.233326 6.937656 -10.375868
v  0.338243 6.937656 -10.443367
v  1.350269 4.231143 -10.737829
v  -0.778700 4.231143 -10.737829
v  1.350269 5.311382 -10.737829
v  -0.778700 5.311382 -10.737829
v  0.777711 4.231143 -10.375868
v  -0.206142 4.231143 -10.375868
v  -0.778700 3.158105 -10.760557
v  1.350269 3.158105 -10.760557
v  -0.778700 6.843483 -10.850479
v  1.350269 6.843483 -10.850479
v  -0.206142 2.060416 -10.375868
v  0.777711 2.060416 -10.375868
v  0.777711 3.150381 -10.375868
v  -0.206142 3.150381 -10.375868
v  -0.206142 5.303869 -10.375868
v  0.777711 5.303869 -10.375868
v  0.777712 6.843486 -10.375868
v  -0.206143 6.843486 -10.375868
v  1.350269 6.843483 -11.750207
v  -0.778700 6.843483 -11.750207
v  1.350269 5.311382 -11.739031
v  -0.778700 5.311382 -11.739031
v  1.350269 6.936878 -11.310274
v  -0.778700 6.936878 -11.310274
v  -0.718700 6.841099 -10.737377
v  1.290269 6.841099 -10.737377
v  1.290269 6.841099 -11.637106
v  -0.718700 6.841099 -11.637106
v  -0.801062 4.300544 -24.463717
v  0.664609 3.147996 -10.435867
v  -0.093040 3.147996 -10.435867
v  1.372631 4.300544 -24.463717
v  -0.093040 2.058031 -10.435867
v  0.664609 2.058031 -10.435867
v  -0.718700 4.228758 -10.624726
v  1.290269 4.228758 -10.624726
v  -0.093041 4.228759 -10.435867
v  0.664609 4.228759 -10.435867
v  -0.718700 5.308996 -11.625929
v  1.290269 5.308996 -11.625929
v  -0.718700 5.308996 -10.624726
v  1.290269 5.308996 -10.624726
v  -0.718700 3.155720 -10.647455
v  1.290269 3.155720 -10.647455
v  -0.093041 6.841102 -10.435867
v  0.664609 5.301485 -10.435867
v  -0.093041 5.301485 -10.435867
v  0.664610 6.841102 -10.435867
v  1.290269 2.058031 -10.647455
v  -0.718700 2.058031 -10.647455
v  5.125232 4.296056 -22.063391
v  -0.741063 4.296057 -24.337591
v  -4.553664 4.296056 -22.063391
v  1.312631 4.296057 -24.337591
v  -0.801062 4.295351 -24.338150
v  1.372631 4.295351 -24.338150
v  -1.583443 3.355104 -11.350576
v  2.155012 3.355104 -11.350576
v  -1.948937 3.260920 -11.350576
v  2.520506 3.260920 -11.350576
v  2.315019 3.304022 -11.350576
v  -1.743450 3.304022 -11.350576
v  -2.149087 3.204540 -11.350576
v  2.720656 3.204540 -11.350576
v  2.936866 3.151801 -11.350576
v  -2.365298 3.151801 -11.350576
v  3.353775 3.039464 -11.350576
v  -2.782206 3.039464 -11.350576
v  3.145308 3.072823 -11.350576
v  -2.573739 3.072823 -11.350576
v  3.153399 3.089596 -11.290576
v  -2.581831 3.089596 -11.290576
v  -2.790298 3.056237 -11.290576
v  3.361866 3.056237 -11.290576
v  -2.373389 3.168575 -11.290576
v  2.944958 3.168575 -11.290576
v  -4.613664 3.627532 -22.662889
v  -4.613664 3.627532 -21.107897
v  5.185232 3.627532 -21.107897
v  5.185232 3.627532 -22.662891
v  1.312631 3.815168 -24.116388
v  -0.741063 3.815168 -24.116388
v  5.185232 3.815153 -21.843088
v  -4.613664 3.815153 -21.843088
v  -2.981069 2.959692 -11.350576
v  3.552638 2.959692 -11.350576
v  3.560362 2.975600 -11.290576
v  -2.988794 2.975600 -11.290576
v  2.425365 3.306417 -11.290576
v  -1.853796 3.306417 -11.290576
v  -1.693789 3.357498 -11.290576
v  2.265358 3.357498 -11.290576
v  -2.259433 3.206933 -11.290576
v  2.831002 3.206933 -11.290576
v  3.464121 3.041858 -11.290576
v  -2.892552 3.041858 -11.290576
v  3.047212 3.154195 -11.290576
v  -2.475644 3.154195 -11.290576
v  3.255654 3.075217 -11.290576
v  -2.684085 3.075217 -11.290576
v  2.630852 3.263313 -11.290576
v  -2.059283 3.263313 -11.290576
v  3.663433 2.941334 -11.290576
v  -3.091865 2.941334 -11.290576
v  -0.851417 1.708417 -11.320996
v  1.422986 1.708417 -11.320996
v  -4.553664 4.010696 -21.381166
v  5.125232 4.010696 -21.381166
v  3.753047 2.903934 -11.350576
v  -3.181479 2.903934 -11.350576
v  -4.613664 4.009960 -22.935642
v  5.185232 3.357468 -22.112141
v  -4.613664 3.357468 -22.112141
v  5.185232 4.009960 -22.935642
v  -4.553664 4.010696 -22.936161
v  5.125232 4.010696 -22.936161
v  -3.067758 2.991550 -11.290576
v  3.639326 2.991550 -11.290576
v  3.760161 2.918401 -11.290576
v  -3.188592 2.918401 -11.290576
v  -3.710798 3.352343 -20.514721
v  4.282367 3.352343 -20.514721
v  -4.613664 4.009960 -21.380650
v  5.185232 4.009960 -21.380650
v  4.859138 3.352343 -20.514721
v  -4.287570 3.352343 -20.514721
v  3.553740 3.352343 -20.514616
v  -2.982171 3.352343 -20.514616
v  2.721519 3.204269 -11.290576
v  -2.149950 3.204269 -11.290576
v  2.521369 3.260649 -11.290576
v  -1.949800 3.260649 -11.290576
v  -1.744313 3.303752 -11.290576
v  2.315882 3.303752 -11.290576
v  -1.584306 3.354834 -11.290576
v  2.155875 3.354834 -11.290576
v  -4.553664 4.006579 -21.281347
v  5.125232 4.006579 -21.281347
v  -4.553664 4.006579 -22.836342
v  5.125232 4.006579 -22.836342
v  -4.613664 4.005873 -21.281906
v  5.185232 4.005873 -21.281906
v  -2.685711 1.192344 -11.253515
v  3.257280 1.192344 -11.253515
v  -3.115861 1.192344 -11.253515
v  1.744045 1.192344 -11.260997
v  3.687429 1.192344 -11.253515
v  -1.172476 1.192344 -11.260997
v  -3.639213 1.698638 -19.075020
v  4.210782 1.698638 -19.075020
v  5.185232 4.005873 -22.836901
v  -4.613664 4.005873 -22.836901
v  -3.639213 1.698638 -12.139788
v  4.210782 1.698638 -12.139788
v  -3.639213 1.698638 -14.461082
v  4.210782 1.698638 -14.461082
v  4.210782 1.698638 -16.721519
v  -3.639213 1.698638 -16.721519
v  3.848489 2.905963 -11.290576
v  -3.276921 2.905963 -11.290576
v  2.838245 3.185408 -11.350576
v  3.054455 3.132670 -11.350576
v  -2.266677 3.185408 -11.350576
v  -2.482887 3.132670 -11.350576
v  -2.066527 3.241788 -11.350576
v  2.638095 3.241788 -11.350576
v  -2.899795 3.020333 -11.350576
v  3.471364 3.020333 -11.350576
v  -1.701032 3.335973 -11.350576
v  2.272601 3.335973 -11.350576
v  3.262897 3.053692 -11.350576
v  -2.691328 3.053692 -11.350576
v  2.432608 3.284891 -11.350576
v  -1.861039 3.284891 -11.350576
v  -2.735637 4.098763 -20.514580
v  3.307206 4.098763 -20.514580
v  2.109898 4.098763 -17.091156
v  -1.538330 4.098763 -17.091156
v  -1.538330 4.098763 -12.421066
v  2.109898 4.098763 -12.421066
v  -4.041036 4.098763 -20.514721
v  4.612605 4.098763 -20.514721
v  2.109898 4.098763 -19.501879
v  -1.538330 4.098763 -19.501879
v  -1.538330 4.098763 -14.756111
v  2.109898 4.098763 -14.756111
v  -2.736025 4.099571 -20.574579
v  3.307594 4.099571 -20.574581
v  2.049898 4.099572 -12.421463
v  -1.478330 4.099572 -12.421463
v  -1.478330 4.099572 -17.091553
v  2.049898 4.099572 -17.091553
v  2.049898 4.099572 -19.502275
v  4.613001 4.099571 -20.574722
v  -4.041433 4.099571 -20.574722
v  -1.478330 4.099572 -19.502275
v  -1.478330 4.099572 -14.756508
v  2.049898 4.099572 -14.756508
v  5.185232 1.422944 -22.112141
v  -4.613664 1.422944 -22.112141
v  3.187544 4.096117 -20.574562
v  -2.615976 4.096117 -20.574562
v  -1.478330 4.096117 -16.971504
v  2.049898 4.096117 -16.971504
v  -1.478330 4.096117 -19.382225
v  2.049898 4.096117 -19.382225
v  4.492952 4.096116 -20.574722
v  -3.921383 4.096116 -20.574722
v  -1.478330 4.096117 -14.636458
v  2.049898 4.096117 -14.636458
v  2.049898 4.096117 -12.301414
v  -1.478330 4.096117 -12.301414
v  3.649217 2.978402 -11.290576
v  -3.077649 2.978402 -11.290576
v  3.503830 2.051710 -11.313516
v  4.210782 1.702327 -14.550519
v  4.210782 1.702327 -17.268152
v  4.210782 1.702327 -16.810957
v  -2.932261 2.051710 -11.313516
v  4.210782 1.702327 -12.229224
v  -3.639213 1.702327 -12.229224
v  5.125232 1.916581 -23.063126
v  -3.639213 1.702327 -19.621651
v  -3.639213 1.702327 -16.810957
v  -3.639213 1.702327 -17.268152
v  -3.639213 1.702327 -14.550519
v  4.210782 1.702327 -19.621651
v  4.210782 1.702327 -15.007715
v  -4.553664 1.916581 -23.063124
v  -3.639213 1.702327 -15.007715
v  4.210782 1.702327 -19.164455
v  -0.741063 1.916582 -24.432533
v  -3.639213 1.702327 -19.164455
v  4.210782 1.702327 -12.686420
v  -3.639213 1.702327 -12.686420
v  1.312631 1.916581 -24.432533
v  4.628413 1.701590 -20.514721
v  -4.056844 1.701590 -20.514721
v  -4.613664 1.915844 -21.237709
v  5.185232 1.915844 -21.237709
v  3.187989 4.095329 -20.514561
v  -2.616420 4.095329 -20.514561
v  2.109898 4.095329 -16.971939
v  -1.538330 4.095329 -19.382660
v  2.109898 4.095329 -19.382660
v  -1.538330 4.095329 -16.971939
v  4.493387 4.095328 -20.514721
v  -3.921819 4.095328 -20.514721
v  -1.538330 4.095329 -14.636894
v  2.109898 4.095329 -14.636894
v  -1.538330 4.095329 -12.301849
v  2.109898 4.095329 -12.301849
v  4.628931 1.702327 -20.574722
v  5.125232 1.916581 -21.238228
v  -4.057362 1.702327 -20.574722
v  -4.553664 1.916581 -21.238226
v  -3.699213 1.701591 -17.267635
v  5.185232 1.915844 -23.062607
v  4.270782 1.701590 -17.267635
v  4.270782 1.701590 -19.621134
v  3.503312 2.050973 -11.253515
v  -4.613664 1.915844 -23.062607
v  -2.931743 2.050973 -11.253515
v  -3.699213 1.701591 -19.621134
v  -3.699213 1.701591 -15.007197
v  4.270782 1.701590 -15.007197
v  -0.801062 1.915845 -24.432016
v  4.270782 1.701590 -12.685902
v  -3.699213 1.701590 -12.685902
v  1.372631 1.915845 -24.432016
v  -3.092747 2.941522 -11.350576
v  3.664316 2.941522 -11.350576
v  -4.553664 3.627547 -22.661991
v  5.125232 3.627547 -22.661991
v  -4.553664 3.627547 -21.106997
v  5.125232 3.627547 -21.106997
v  3.414394 2.048021 -11.313516
v  -3.639213 1.698638 -12.596983
v  4.210782 1.698638 -14.918279
v  4.210782 1.698638 -12.596983
v  -3.639213 1.698638 -14.918279
v  -2.842825 2.048020 -11.313516
v  -4.553664 1.912892 -22.973688
v  5.125232 1.912892 -21.148790
v  5.125232 1.912892 -22.973688
v  -3.967926 1.698638 -20.574722
v  4.210782 1.698638 -19.532215
v  4.539494 1.698638 -20.574722
v  -3.639213 1.698638 -19.532215
v  -0.741063 1.912892 -24.343098
v  -4.553664 1.912892 -21.148790
v  4.210782 1.698638 -17.178717
v  -3.639213 1.698638 -17.178717
v  1.312631 1.912892 -24.343100
v  4.270782 1.697932 -14.918838
v  4.270782 1.697932 -12.597543
v  -3.699213 1.697932 -12.597543
v  4.270782 1.697932 -19.532774
v  3.414953 2.047315 -11.253515
v  -2.843385 2.047315 -11.253515
v  -3.699213 1.697932 -14.918838
v  -4.613664 1.912186 -22.974247
v  5.185232 1.912186 -22.974247
v  -3.699213 1.697932 -19.532774
v  4.270782 1.697932 -17.179276
v  -0.801062 1.912186 -24.343658
v  1.372631 1.912186 -24.343658
v  -3.699213 1.697932 -17.179276
v  2.976968 3.352343 -20.514530
v  -2.405399 3.352343 -20.514530
v  4.540053 1.697932 -20.514721
v  -3.968485 1.697932 -20.514721
v  5.185232 1.912186 -21.149349
v  -4.613664 1.912186 -21.149349
v  1.606591 2.051710 -11.320996
v  -1.035022 2.051710 -11.320996
v  -3.283306 2.887367 -11.350576
v  3.854874 2.887367 -11.350576
v  -4.613664 2.480419 -22.112141
v  5.185232 2.480419 -22.112141
v  1.423886 1.708405 -11.260997
v  -0.852317 1.708405 -11.260997
v  -1.034405 2.051054 -11.260997
v  1.605974 2.051054 -11.260997
v  2.406332 3.337599 -11.290576
v  -1.834763 3.337599 -11.290576
v  2.811969 3.238116 -11.290576
v  -2.240400 3.238116 -11.290576
v  -2.456610 3.185378 -11.290576
v  3.028179 3.185378 -11.290576
v  -1.674756 3.388680 -11.290576
v  2.246325 3.388680 -11.290576
v  3.445087 3.073040 -11.290576
v  -2.873519 3.073040 -11.290576
v  2.611819 3.294495 -11.290576
v  -2.040250 3.294495 -11.290576
v  -2.665052 3.106399 -11.290576
v  3.236620 3.106399 -11.290576
v  -0.968830 2.047403 -11.260997
v  1.540399 2.047403 -11.260997
v  -1.965874 3.292373 -11.350576
v  2.537442 3.292373 -11.350576
v  2.953802 3.183254 -11.350576
v  -2.382234 3.183254 -11.350576
v  2.171948 3.386558 -11.350576
v  -1.760386 3.335475 -11.350576
v  2.331955 3.335475 -11.350576
v  -1.600379 3.386558 -11.350576
v  3.370710 3.070917 -11.350576
v  -2.799142 3.070917 -11.350576
v  -2.166024 3.235993 -11.350576
v  3.162244 3.104276 -11.350576
v  -2.590675 3.104276 -11.350576
v  2.737592 3.235993 -11.350576
v  1.539744 2.048021 -11.320996
v  -0.968175 2.048021 -11.320996
v  1.353998 5.426895 -21.047108
v  1.369150 5.426895 -23.212097
v  -0.797581 5.426895 -23.212097
v  -0.782429 5.426895 -21.047108
v  -0.244159 5.426895 -20.514290
v  0.815727 5.426895 -20.514290
v  2.812644 3.238716 -11.350576
v  -2.241075 3.238716 -11.350576
v  2.407007 3.338199 -11.350576
v  -1.835438 3.338199 -11.350576
v  3.028854 3.185978 -11.350576
v  -2.457285 3.185978 -11.350576
v  2.247000 3.389280 -11.350576
v  -1.675431 3.389280 -11.350576
v  -2.874194 3.073641 -11.350576
v  3.445762 3.073641 -11.350576
v  -2.040925 3.295095 -11.350576
v  2.612494 3.295095 -11.350576
v  3.237295 3.106999 -11.350576
v  -2.665727 3.106999 -11.350576
v  -2.997154 2.989564 -11.350576
v  3.568722 2.989564 -11.350576
v  2.749606 3.247367 -11.290576
v  -2.811156 3.082291 -11.290576
v  3.382724 3.082291 -11.290576
v  -2.178037 3.247367 -11.290576
v  -1.612393 3.397932 -11.290576
v  2.183962 3.397932 -11.290576
v  -2.602689 3.115651 -11.290576
v  3.174258 3.115651 -11.290576
v  -1.772400 3.346850 -11.290576
v  2.343969 3.346850 -11.290576
v  2.549456 3.303746 -11.290576
v  -1.977887 3.303746 -11.290576
v  -2.394248 3.194628 -11.290576
v  2.965816 3.194628 -11.290576
v  0.591899 1.919258 -10.375868
v  -0.020330 1.919258 -10.375868
v  0.591899 4.089985 -10.375868
v  0.591899 3.009223 -10.375868
v  -0.020330 4.089985 -10.375868
v  -0.020330 3.009223 -10.375868
v  0.591899 5.162713 -10.375868
v  -0.020330 5.162713 -10.375868
v  1.350269 5.170224 -10.552016
v  1.350269 4.089985 -10.552016
v  1.350269 5.170224 -11.553219
v  -0.778700 5.170224 -11.553219
v  -0.778700 4.089985 -10.552016
v  1.350269 3.016947 -10.574745
v  -0.778700 5.170224 -10.552016
v  -0.778700 1.919258 -10.574745
v  1.350269 1.919258 -10.574745
v  -0.778700 3.016947 -10.574745
v  1.353219 5.395079 -20.935858
v  -0.168780 5.423919 -20.514290
v  0.740348 5.423919 -20.514290
v  1.368622 5.423918 -23.136719
v  -0.781902 5.423918 -20.971727
v  -0.797054 5.423918 -23.136719
v  1.353471 5.423918 -20.971729
v  1.350269 6.523818 -10.942101
v  -0.778700 6.523818 -10.942101
v  1.350269 6.523818 -11.841827
v  -0.778700 6.523818 -11.841827
v  0.869332 6.523818 -10.375868
v  -0.297763 6.523818 -10.375868
v  5.185232 3.352343 -21.585978
v  -4.613664 3.352343 -21.585978
v  -0.018531 6.523818 -10.375868
v  0.590100 6.523818 -10.375868
v  1.350269 6.523818 -10.662867
v  -0.778700 6.523818 -10.662867
v  1.350269 6.523818 -11.562594
v  -0.778700 6.523818 -11.562594
v  1.350269 6.523818 -11.401895
v  -0.778700 6.523818 -11.401895
v  -4.613664 3.352343 -21.106096
v  5.185232 3.352343 -21.106096
v  1.350269 6.523818 -11.122663
v  -0.778700 6.523818 -11.122663
v  4.270782 1.192344 -16.564405
v  4.270782 1.192344 -14.303968
v  4.270782 1.192344 -14.761165
v  5.185232 0.112344 -23.246723
v  -4.613664 0.112344 -23.246723
v  -3.699213 0.112344 -16.564405
v  -3.699213 0.112344 -12.439869
v  4.270782 0.112344 -16.564405
v  4.270782 0.112344 -12.439869
v  -3.699213 0.112344 -14.761165
v  -3.699213 0.112344 -14.303968
v  4.270782 0.112344 -14.761165
v  4.270782 0.112344 -14.303968
v  5.185232 1.192344 -20.991676
v  4.270782 1.192344 -17.021603
v  4.270782 1.192344 -18.917906
v  1.372631 1.192344 -24.185986
v  4.270782 1.192344 -19.375101
v  5.185232 1.192344 -22.816574
v  4.270782 0.112344 -19.348055
v  4.270782 0.112344 -17.451752
v  4.270782 0.112344 -16.994556
v  4.270782 0.112344 -14.734119
v  4.270782 0.112344 -12.870020
v  4.270782 0.112344 -12.412825
v  4.270782 0.112344 -19.805252
v  -4.240962 0.112344 -20.514721
v  -4.613664 1.192344 -20.991676
v  -3.699213 1.192344 -19.805252
v  -3.699213 1.192344 -12.412825
v  -3.699213 1.192344 -12.870020
v  -3.699213 1.192344 -14.734119
v  -3.699213 1.192344 -15.191315
v  -3.699213 1.192344 -16.994556
v  -3.699213 1.192344 -17.021603
v  -3.699213 1.192344 -17.451752
v  -3.699213 1.192344 -18.917906
v  -3.699213 1.192344 -19.348055
v  -3.699213 1.192344 -19.375101
v  -3.699213 0.112344 -19.805252
v  -3.699213 0.112344 -19.375101
v  -3.699213 0.112344 -19.348055
v  -3.699213 0.112344 -18.917906
v  -3.699213 0.112344 -17.451752
v  -3.699213 0.112344 -17.021603
v  -3.699213 0.112344 -16.994556
v  -3.699213 0.112344 -15.191315
v  -3.699213 0.112344 -14.734119
v  4.270782 1.192344 -16.994556
v  -3.699213 0.112344 -12.870020
v  -3.699213 0.112344 -12.412825
v  1.372631 0.112344 -24.616135
v  -3.699213 1.192344 -11.982676
v  4.270782 1.192344 -17.451752
v  1.372631 1.192344 -24.616135
v  4.270782 1.192344 -11.982676
v  4.270782 1.192344 -19.348055
v  5.185232 0.112344 -21.421827
v  4.270782 1.192344 -12.412825
v  4.270782 1.192344 -19.805252
v  4.270782 0.112344 -19.375101
v  4.270782 0.112344 -18.917906
v  4.270782 0.112344 -17.021603
v  4.270782 1.192344 -12.870020
v  4.270782 1.192344 -14.734119
v  -0.801062 0.112344 -24.616135
v  -0.801062 1.192344 -24.616135
v  -0.801062 1.192344 -24.185984
v  -4.613664 0.112344 -21.421825
v  4.270782 1.192344 -15.191315
v  -4.613664 1.192344 -22.816574
v  4.812530 0.112344 -20.514721
v  -3.699213 1.192344 -14.303968
v  -3.699213 1.192344 -14.761165
v  -3.699213 1.192344 -16.564407
v  4.382380 1.192344 -20.514721
v  -3.810812 1.192344 -20.514721
v  4.270782 1.192344 -12.439870
v  -3.699213 1.192344 -12.439870
v  -4.613664 1.192344 -23.246723
v  5.185232 1.192344 -23.246723
v  -3.068433 2.992150 -11.350576
v  3.640001 2.992150 -11.350576
v  3.580163 3.000333 -11.290576
v  -3.008595 3.000333 -11.290576
v  4.270782 1.701590 -16.810438
v  -3.699213 1.701591 -16.810438
v  4.270782 1.701590 -14.550001
v  -3.699213 1.701591 -14.550001
v  -3.699213 1.701590 -12.228706
v  4.270782 1.701590 -12.228706
v  1.350269 6.795722 -11.124463
v  -0.778700 6.795722 -11.124463
v  1.367701 5.149858 -23.005058
v  -0.780980 5.149858 -20.840069
v  -0.796132 5.149858 -23.005058
v  1.352549 5.149858 -20.840069
v  -0.037120 5.149858 -20.514290
v  0.608689 5.149858 -20.514290
v  3.832030 2.932930 -11.290576
v  -3.260461 2.932930 -11.290576
v  4.270782 1.701590 -19.163937
v  -3.699213 1.701591 -19.163937
v  -3.196145 2.931171 -11.350576
v  3.767713 2.931171 -11.350576
v  -0.778700 6.702326 -11.564394
v  1.350269 6.702326 -11.564394
v  -0.778700 6.702325 -10.664667
v  1.350269 6.702325 -10.664667
v  0.591900 6.702330 -10.375868
v  -0.020331 6.702330 -10.375868
v  -3.261136 2.933529 -11.350576
v  3.832705 2.933529 -11.350576
v  -1.538330 3.778006 -15.000845
v  2.109898 3.778006 -17.335890
v  -1.538330 3.778006 -17.335890
v  -1.538330 3.778006 -19.746613
v  -1.538330 3.778006 -12.665800
v  2.109898 3.778006 -15.000845
v  2.109898 3.778006 -12.665800
v  2.109898 3.778006 -19.746613
v  3.778199 2.940933 -11.290576
v  -3.206631 2.940933 -11.290576
v  5.185232 4.333547 -22.112141
v  -4.613664 4.333547 -22.112141
v  2.977859 3.352343 -20.574532
v  -2.406291 3.352343 -20.574532
v  -0.778700 6.358574 -11.257868
v  1.350269 6.358574 -11.257868
v  -4.553664 2.479519 -22.112141
v  5.125232 2.479519 -22.112141
v  5.125232 4.332424 -22.112141
v  -4.553664 4.332424 -22.112141
v  5.125232 3.352343 -22.661991
v  -4.553664 3.352343 -22.661991
v  -0.741063 0.112344 -24.615234
v  -3.639213 0.112344 -19.804352
v  -3.639213 0.112344 -19.347155
v  -3.639213 0.112344 -17.450851
v  -3.639213 0.112344 -16.993656
v  -3.639213 0.112344 -15.190415
v  -3.639213 0.112344 -14.733219
v  -4.553664 0.112344 -23.245823
v  -4.553664 0.112344 -21.420927
v  -3.639213 0.112344 -12.869120
v  -3.639213 0.112344 -12.411924
v  -4.240062 0.112344 -20.574720
v  5.125232 1.192344 -23.245823
v  4.210782 0.112344 -19.347155
v  4.210782 0.112344 -17.450851
v  4.210782 0.112344 -16.993656
v  4.210782 0.112344 -15.190414
v  4.210782 0.112344 -14.733219
v  4.210782 0.112344 -19.804352
v  5.125232 0.112344 -23.245825
v  5.125232 0.112344 -21.420927
v  1.312631 0.112344 -24.615234
v  4.210782 0.112344 -12.411924
v  4.811630 0.112344 -20.574720
v  4.210782 0.112344 -12.869120
v  -3.639213 0.112344 -11.983575
v  -4.553664 0.112344 -22.817474
v  -4.553664 0.112344 -20.992577
v  4.383280 0.112344 -20.574720
v  5.125232 0.112344 -20.992577
v  4.210782 0.112344 -11.983575
v  5.125232 0.112344 -22.817474
v  -3.811712 0.112344 -20.574720
v  -0.741063 0.112344 -24.186884
v  1.312631 0.112344 -24.186886
v  -0.296863 6.523818 -10.435867
v  0.868432 6.523818 -10.435867
v  1.290269 6.523818 -11.840927
v  -0.718700 6.523818 -11.400995
v  -0.718700 6.523818 -11.840927
v  1.290269 6.523818 -11.400995
v  -0.718700 6.523818 -10.941200
v  1.290269 6.523818 -10.941200
v  5.125232 3.352343 -23.140072
v  -4.553664 3.352343 -23.140072
v  5.125232 3.352343 -21.585077
v  -4.553664 3.352343 -21.585077
v  5.125232 2.272344 -21.704769
v  -4.553664 2.272345 -21.704769
v  5.125232 2.272344 -22.547867
v  -4.553664 2.272345 -22.547867
v  1.350269 5.675502 -11.253515
v  -0.778700 5.675502 -11.253515
v  -0.718700 6.702348 -11.563494
v  1.290269 6.702348 -11.563494
v  -0.718700 6.702347 -10.663767
v  1.290269 6.702347 -10.663767
v  0.590999 1.919280 -10.435867
v  -0.019430 1.919280 -10.435867
v  -0.019431 6.702352 -10.435867
v  0.591000 6.702352 -10.435867
v  -0.019430 4.090007 -10.435867
v  0.590999 4.090007 -10.435867
v  0.590999 3.009245 -10.435867
v  -0.019430 3.009245 -10.435867
v  0.590999 5.162734 -10.435867
v  -0.019430 5.162734 -10.435867
v  -0.718700 6.795743 -11.123563
v  -0.718700 5.170246 -11.552319
v  -0.718700 4.090007 -10.551116
v  1.290269 4.090007 -10.551116
v  1.290269 1.919280 -10.573845
v  1.290269 5.170246 -11.552319
v  -0.718700 5.170246 -10.551116
v  -0.718700 1.919280 -10.573845
v  1.290269 3.016968 -10.573845
v  1.290269 5.170246 -10.551116
v  1.290269 6.795743 -11.123563
v  -0.718700 3.016968 -10.573845
v  5.185232 2.263113 -22.554966
v  5.185232 2.263113 -21.697668
v  -4.613664 2.263113 -22.554966
v  -4.613664 2.263113 -21.697666
v  5.185232 3.352343 -23.140972
v  -4.613664 3.352343 -23.140972
v  -4.613664 3.352343 -22.661091
v  5.185232 3.352343 -22.661091
v  2.049898 3.794861 -19.744267
v  2.049898 3.794861 -14.998499
v  -1.478330 3.794861 -19.744267
v  -1.478330 3.794861 -12.663454
v  2.049898 3.794861 -12.663454
v  -1.478330 3.794861 -17.333544
v  -1.478330 3.794861 -14.998499
v  2.049898 3.794861 -17.333544
v  -0.718700 6.517062 -11.253515
v  1.290269 6.517062 -11.253515
v  1.350269 6.966964 -11.253515
v  -0.778700 6.966964 -11.253515
v  5.125232 3.358368 -22.112141
v  -4.553664 3.358368 -22.112141
v  5.125232 1.423844 -22.112141
v  -4.553664 1.423844 -22.112141
v  -4.240062 1.192344 -20.574722
v  4.811630 1.192344 -20.574722
v  -4.553664 1.192344 -21.420927
v  5.125232 1.192344 -21.420927
v  -3.711698 3.352343 -20.574722
v  4.283266 3.352343 -20.574722
v  4.210782 1.192344 -17.022503
v  4.210782 1.192344 -19.376001
v  4.383280 1.192344 -20.574722
v  -3.639213 1.192344 -17.022503
v  4.210782 1.192344 -14.762065
v  -3.639213 1.192344 -12.440769
v  4.210782 1.192344 -18.918804
v  -3.639213 1.192344 -18.918804
v  4.210782 1.192344 -16.565306
v  -3.639213 1.192344 -14.304869
v  -3.639213 1.192344 -14.762065
v  4.210782 1.192344 -12.440769
v  -3.639213 1.192344 -16.565306
v  4.210782 1.192344 -14.304869
v  -3.811712 1.192344 -20.574722
v  -3.639213 1.192344 -19.376001
v  -3.639213 1.192344 -11.983575
v  4.210782 1.192344 -11.983575
v  -4.553664 1.192344 -22.817474
v  5.125232 1.192344 -20.992577
v  5.125232 1.192344 -22.817474
v  -0.741063 1.192344 -24.186884
v  1.312631 1.192344 -24.186886
v  -4.553664 1.192344 -20.992577
v  -2.686611 1.192344 -11.313516
v  3.258180 1.192344 -11.313516
v  -0.851417 1.192344 -11.320996
v  1.422986 1.192344 -11.320996
v  4.812530 1.192344 -20.514721
v  -4.240962 1.192344 -20.514721
v  4.382380 0.112344 -20.514721
v  -3.810812 0.112344 -20.514721
v  -4.613664 1.192344 -21.421825
v  1.372631 0.112344 -24.185986
v  -3.699213 0.112344 -11.982676
v  4.270782 0.112344 -11.982676
v  5.185232 0.112344 -22.816574
v  5.185232 0.112344 -20.991676
v  5.185232 1.192344 -21.421827
v  -0.801062 0.112344 -24.185984
v  -4.613664 0.112344 -22.816574
v  -4.613664 0.112344 -20.991676
v  4.270782 1.697932 -16.722078
v  -3.699213 1.697932 -16.722078
v  4.270782 1.697932 -14.461641
v  -3.699213 1.697932 -14.461641
v  4.270782 1.697932 -12.140347
v  -3.699213 1.697932 -12.140347
v  4.270782 1.697932 -19.075579
v  -3.699213 1.697932 -19.075579
v  1.290269 6.952497 -11.282663
v  -0.718700 6.952497 -11.282663
v  1.290269 6.859101 -11.722596
v  -0.718700 6.859100 -10.822869
v  -0.718700 6.859101 -11.722596
v  1.290269 6.859100 -10.822869
v  -0.718700 5.326999 -11.711421
v  1.290269 5.326999 -11.711421
v  1.290269 2.076033 -10.732946
v  -0.718700 2.076033 -10.732946
v  0.261399 6.952500 -10.443367
v  0.310170 6.952500 -10.375868
v  -0.718700 5.326999 -10.710218
v  1.290269 5.326999 -10.710218
v  0.750100 2.076033 -10.435867
v  -0.178532 2.076033 -10.435867
v  0.750100 3.165998 -10.435867
v  -0.178532 3.165998 -10.435867
v  -0.718700 3.173721 -10.732946
v  1.290269 3.173721 -10.732946
v  0.750101 6.859103 -10.435867
v  -0.178532 5.319487 -10.435867
v  -0.178533 6.859103 -10.435867
v  0.750101 5.319487 -10.435867
v  -0.718700 4.246760 -10.710218
v  1.290269 4.246760 -10.710218
v  -0.178532 4.246760 -10.435867
v  0.750101 4.246760 -10.435867
v  -0.778700 6.858294 -10.822470
v  1.350269 6.858294 -11.722197
v  -0.778700 6.858294 -11.722197
v  1.350269 6.858294 -10.822470
v  1.350269 2.075226 -10.732547
v  -0.778700 2.075226 -10.732547
v  -0.778700 5.326192 -11.711021
v  1.350269 5.326192 -11.711021
v  -0.178132 2.075226 -10.375868
v  0.749701 2.075226 -10.375868
v  1.350269 5.326192 -10.709818
v  -0.778700 5.326192 -10.709818
v  -0.178132 3.165192 -10.375868
v  0.749701 3.165192 -10.375868
v  -0.778700 3.172915 -10.732547
v  1.350269 3.172915 -10.732547
v  1.350269 4.245953 -10.709818
v  -0.778700 4.245953 -10.709818
v  -0.718700 6.857415 -10.764941
v  1.290269 6.857415 -10.764941
v  -0.178132 4.245953 -10.375868
v  0.749701 4.245953 -10.375868
v  -0.178132 5.318680 -10.375868
v  -0.178133 6.858296 -10.375868
v  0.749701 5.318680 -10.375868
v  0.749701 6.858296 -10.375868
v  1.290269 6.857416 -11.664669
v  -0.718700 6.857416 -11.664669
v  -0.120606 5.317803 -10.435867
v  -0.120607 6.857420 -10.435867
v  0.692175 5.317803 -10.435867
v  0.692176 6.857420 -10.435867
v  -0.120606 4.245076 -10.435867
v  0.692175 4.245076 -10.435867
v  -0.718700 5.325314 -11.653496
v  1.290269 5.325314 -11.653496
v  -0.718700 5.325314 -10.652292
v  1.290269 5.325314 -10.652292
v  -0.718700 4.245076 -10.652292
v  1.290269 4.245076 -10.652292
v  -0.120606 2.074348 -10.435867
v  0.692175 2.074348 -10.435867
v  -0.718700 2.074348 -10.675022
v  1.290269 2.074348 -10.675022
v  0.692175 3.164314 -10.435867
v  -0.120606 3.164314 -10.435867
v  -0.718700 3.172037 -10.675022
v  1.290269 3.172037 -10.675022
v  -0.718700 6.950812 -11.224738
v  1.290269 6.950812 -11.224738
v  -0.778700 6.856629 -10.765380
v  1.350269 6.856629 -10.765380
v  1.350269 6.856630 -11.665108
v  -0.778700 6.856630 -11.665108
v  -0.121045 5.317018 -10.375868
v  0.692615 6.856634 -10.375868
v  0.692614 5.317018 -10.375868
v  -0.121046 6.856634 -10.375868
v  -0.778700 5.324528 -11.653934
v  1.350269 5.324528 -11.653934
v  -0.778700 5.324528 -10.652731
v  1.350269 5.324528 -10.652731
v  -0.121045 4.244290 -10.375868
v  0.692614 4.244290 -10.375868
v  1.350269 4.244290 -10.652731
v  -0.778700 4.244290 -10.652731
v  0.692614 2.073562 -10.375868
v  -0.121045 2.073562 -10.375868
v  -0.778700 2.073562 -10.675461
v  1.350269 2.073562 -10.675461
v  -0.121045 3.163527 -10.375868
v  0.692614 3.163527 -10.375868
v  -0.778700 3.171251 -10.675461
v  1.350269 3.171251 -10.675461
v  0.285784 6.517038 -10.443367
v  0.285784 6.517038 -10.375868
v  2.109898 3.778070 -19.173441
v  2.109898 3.778070 -12.092628
v  -1.538330 3.778070 -19.173441
v  -1.538330 3.778070 -16.762718
v  -1.538330 3.778070 -12.092628
v  -1.538330 3.778070 -14.427673
v  2.109898 3.778070 -16.762718
v  2.109898 3.778070 -14.427672
v  0.310170 6.952500 -10.443367
v  0.261399 6.952500 -10.375868
v  -4.286670 3.352343 -20.574722
v  4.858238 3.352343 -20.574722
v  3.552831 3.352343 -20.574615
v  -2.981262 3.352343 -20.574615
v  -0.718700 5.675517 -11.257773
v  1.290269 5.675517 -11.257773
v  3.686530 1.192344 -11.313516
v  -3.114961 1.192344 -11.313516
v  1.743145 1.192344 -11.320996
v  -1.171576 1.192344 -11.320996
v  1.350269 6.951691 -11.282264
v  -0.778700 6.951691 -11.282264
v  2.149786 3.308866 -11.350576
v  -1.578218 3.308866 -11.350576
v  -1.943712 3.214681 -11.350576
v  2.515280 3.214681 -11.350576
v  -1.738225 3.257784 -11.350576
v  2.309793 3.257784 -11.350576
v  -2.143862 3.158301 -11.350576
v  2.715430 3.158301 -11.350576
v  2.931640 3.105562 -11.350576
v  -2.360072 3.105562 -11.350576
v  3.348549 2.993225 -11.350576
v  -2.776980 2.993225 -11.350576
v  3.140082 3.026584 -11.350576
v  -2.568514 3.026584 -11.350576
v  -2.976106 2.915778 -11.350576
v  3.547675 2.915778 -11.350576
v  -2.640527 3.127210 -11.290576
v  3.212095 3.127210 -11.290576
v  3.420562 3.093851 -11.290576
v  -2.848994 3.093851 -11.290576
v  2.587294 3.315305 -11.290576
v  -2.015725 3.315305 -11.290576
v  2.381807 3.358408 -11.290576
v  -1.810238 3.358408 -11.290576
v  -2.432085 3.206187 -11.290576
v  3.003654 3.206187 -11.290576
v  3.212613 3.127946 -11.350576
v  -2.641045 3.127946 -11.350576
v  2.221799 3.409489 -11.290576
v  -1.650231 3.409489 -11.290576
v  -2.215875 3.258924 -11.290576
v  2.787443 3.258924 -11.290576
v  -2.849512 3.094587 -11.350576
v  3.421080 3.094587 -11.350576
v  2.587811 3.316041 -11.350576
v  -2.016243 3.316041 -11.350576
v  2.382324 3.359144 -11.350576
v  -1.810756 3.359144 -11.350576
v  3.004172 3.206923 -11.350576
v  -2.432603 3.206923 -11.350576
v  -2.216393 3.259661 -11.350576
v  2.787961 3.259661 -11.350576
v  2.222317 3.410226 -11.350576
v  -1.650749 3.410226 -11.350576
v  -3.176954 2.863894 -11.350576
v  3.748522 2.863894 -11.350576
v  3.186206 3.126857 -11.350576
v  -2.614638 3.126857 -11.350576
v  2.561405 3.314952 -11.350576
v  -1.989836 3.314952 -11.350576
v  2.761554 3.258573 -11.350576
v  -2.189986 3.258573 -11.350576
v  2.195910 3.409138 -11.350576
v  -1.624342 3.409138 -11.350576
v  2.355917 3.358056 -11.350576
v  -1.784349 3.358056 -11.350576
v  -2.823105 3.093498 -11.350576
v  3.394673 3.093498 -11.350576
v  2.977765 3.205834 -11.350576
v  -2.406196 3.205834 -11.350576
v  3.616042 3.011308 -11.290576
v  -3.044473 3.011308 -11.290576
v  -3.044991 3.012044 -11.350576
v  3.616560 3.012044 -11.350576
v  -0.718700 6.359474 -11.257773
v  1.290269 6.359474 -11.257773
v  -2.615197 3.126151 -11.290576
v  3.186765 3.126151 -11.290576
v  -1.990395 3.314246 -11.290576
v  2.561964 3.314246 -11.290576
v  -1.784908 3.357350 -11.290576
v  2.356476 3.357350 -11.290576
v  -2.190545 3.257867 -11.290576
v  2.762113 3.257867 -11.290576
v  -1.624901 3.408432 -11.290576
v  2.196469 3.408432 -11.290576
v  3.395232 3.092791 -11.290576
v  -2.823663 3.092791 -11.290576
v  -2.406755 3.205128 -11.290576
v  2.978324 3.205128 -11.290576
v  -3.019912 3.011010 -11.350576
v  3.591480 3.011010 -11.350576
v  3.592039 3.010304 -11.290576
v  -3.020471 3.010304 -11.290576
v  -3.239245 2.950932 -11.290576
v  3.810813 2.950932 -11.290576
v  -3.239763 2.951669 -11.350576
v  3.811331 2.951669 -11.350576
v  -3.216895 2.950726 -11.350576
v  3.788464 2.950726 -11.350576
v  3.789023 2.950020 -11.290576
v  -3.217454 2.950020 -11.290576
v  2.150686 3.308851 -11.290576
v  -1.579118 3.308851 -11.290576
v  2.516181 3.214666 -11.290576
v  -1.944612 3.214666 -11.290576
v  -1.739125 3.257768 -11.290576
v  2.310693 3.257768 -11.290576
v  2.716331 3.158286 -11.290576
v  -2.144762 3.158286 -11.290576
v  -2.360972 3.105547 -11.290576
v  2.932540 3.105547 -11.290576
v  -2.777880 2.993210 -11.290576
v  3.349449 2.993210 -11.290576
v  3.140982 3.026569 -11.290576
v  -2.569413 3.026569 -11.290576
v  3.548575 2.915763 -11.290576
v  -2.977006 2.915763 -11.290576
v  -3.177854 2.863879 -11.290576
v  3.749422 2.863879 -11.290576
v  -1.478330 3.794861 -14.427586
v  2.049898 3.794861 -16.762630
v  2.049898 3.794861 -19.173353
v  -1.478330 3.794861 -16.762630
v  2.049898 3.794861 -14.427586
v  -1.478330 3.794861 -19.173353
v  -1.478330 3.794861 -12.092541
v  2.049898 3.794861 -12.092541
v  1.350269 6.950026 -11.225178
v  -0.778700 6.950026 -11.225178
v  1.290269 6.965947 -11.253515
v  -0.718700 6.965947 -11.253515
v  -0.019431 6.523818 -10.435867
v  0.591000 6.523818 -10.435867
v  -0.718700 6.523818 -10.663767
v  1.290269 6.523818 -10.663767
v  -0.718700 6.523818 -11.563494
v  1.290269 6.523818 -11.563494
v  5.125232 3.352343 -21.106997
v  -4.553664 3.352343 -21.106997
v  -0.718700 6.523818 -11.123563
v  1.290269 6.523818 -11.123563
v  2.106413 1.192344 -11.253515
v  -1.534845 1.192344 -11.253515
v  0.143068 6.523818 -10.443367
v  -0.653315 5.980910 -23.739498
v  1.224884 5.980910 -23.739498
v  -0.778700 5.512938 -20.514290
v  1.226417 5.109595 -23.968128
v  -0.654848 5.109595 -23.968128
v  5.426581 1.521726 -23.561600
v  5.426581 1.503924 -23.561600
v  5.036788 1.521726 -23.964600
v  5.438640 1.521726 -23.561600
v  0.285784 5.435491 -12.225241
v  0.285784 5.483727 -12.252230
v  0.285784 6.471543 -12.252230
v  0.285784 5.466499 -12.252230
v  0.285784 7.246771 -12.252230
v  5.409354 1.503924 -23.561600
v  0.285784 7.281226 -12.238448
v  1.393432 6.986866 -11.253489
v  -0.821863 5.483727 -11.253489
v  -0.821863 6.986866 -11.253489
v  -4.853863 2.395684 -23.561600
v  1.393432 5.483727 -11.253489
v  5.036788 2.381328 -23.962875
v  5.036788 2.364101 -23.978380
v  5.036788 2.346874 -23.964600
v  -4.465220 2.381328 -23.962875
v  -4.865348 4.835527 -23.561602
v  -4.465220 2.346874 -23.964600
v  5.436917 1.570535 -23.561600
v  5.438640 1.538952 -23.561600
v  5.409354 3.979370 -23.561602
v  5.036788 2.364101 -23.964600
v  -4.465220 2.364101 -23.964600
v  -4.465220 1.521725 -23.964600
v  5.425432 1.570535 -23.561600
v  5.452421 1.538952 -23.561600
v  -4.837696 1.587188 -23.561600
v  5.036788 0.713803 -23.978382
v  5.436917 2.381328 -23.561600
v  -4.465220 0.731030 -23.978382
v  5.036788 2.395684 -23.962875
v  -0.653315 5.995265 -23.755001
v  5.036788 4.014399 -23.964602
v  -4.865348 0.745386 -23.561600
v  -4.837785 3.979370 -23.561602
v  5.036788 3.220832 -23.962877
v  5.036788 1.556179 -23.962875
v  5.436917 3.220832 -23.561600
v  5.452421 3.206477 -23.561600
v  5.036788 1.538952 -23.978380
v  5.409354 0.678776 -23.561600
v  5.036788 3.189250 -23.964600
v  -4.853863 1.587188 -23.561600
v  -4.865348 1.570535 -23.561600
v  1.224884 5.995265 -23.755001
v  -4.880852 1.556179 -23.561600
v  -4.465220 2.395684 -23.962875
v  -4.465220 2.364101 -23.978380
v  -4.865348 4.031626 -23.561602
v  1.224884 5.980910 -23.728012
v  -4.465220 3.189250 -23.964600
v  5.425432 2.395684 -23.561600
v  -4.465220 0.731030 -23.962877
v  -4.465220 0.713803 -23.978382
v  5.036788 3.206476 -23.962877
v  -3.699213 2.438873 -11.253515
v  0.285784 5.452143 -12.225241
v  -4.880852 4.852755 -23.561602
v  -4.865348 2.381328 -23.561600
v  5.409265 2.412336 -23.561600
v  5.036788 0.731030 -23.978382
v  -4.837696 2.412336 -23.561600
v  5.036788 0.713803 -23.964600
v  5.409265 4.062634 -23.561602
v  -4.465220 0.713803 -23.964600
v  -4.865348 3.220832 -23.561600
v  -4.865348 4.821172 -23.561602
v  -4.853863 0.762039 -23.561600
v  -4.855012 3.172023 -23.561600
v  -4.880852 0.731030 -23.561600
v  -4.867071 0.713804 -23.561600
v  -4.837785 0.678775 -23.561600
v  5.426581 0.696577 -23.561600
v  5.426581 3.172023 -23.561600
v  -4.855012 1.521725 -23.561600
v  -4.837785 1.503924 -23.561600
v  -4.865348 2.395684 -23.561600
v  -4.865348 4.045981 -23.561602
v  -4.880852 4.014399 -23.561602
v  -4.837696 4.062634 -23.561602
v  5.436917 2.395684 -23.561600
v  5.409265 1.587188 -23.561600
v  5.036788 0.696577 -23.964600
v  -4.855012 3.154222 -23.561600
v  -4.880852 0.713804 -23.561600
v  -4.855012 0.678775 -23.561600
v  -4.465220 4.821172 -23.962879
v  -4.867071 2.346874 -23.561600
v  -0.653315 5.980910 -23.728012
v  5.425432 0.762039 -23.561600
v  5.036788 0.745386 -23.962877
v  5.438640 3.997172 -23.561602
v  5.425432 4.804519 -23.561602
v  5.426581 3.979370 -23.561602
v  5.436917 4.821172 -23.561602
v  5.452421 4.835527 -23.561602
v  -4.465220 0.745386 -23.962877
v  5.436917 0.745386 -23.561600
v  5.409354 4.887783 -23.561602
v  -4.855012 3.997172 -23.561602
v  5.452421 0.731031 -23.561600
v  -4.880852 3.206477 -23.561600
v  -4.853863 4.804519 -23.561602
v  -0.653315 5.995265 -23.739498
v  5.036788 3.997171 -23.964602
v  5.452421 4.852755 -23.561602
v  5.438640 4.869981 -23.561602
v  5.438640 0.713803 -23.561600
v  -4.855012 4.869981 -23.561602
v  5.036788 1.538952 -23.964600
v  5.426581 4.887783 -23.561602
v  -4.465220 1.556179 -23.962875
v  -4.465220 4.014399 -23.964602
v  -4.837785 4.887783 -23.561602
v  5.409265 4.804519 -23.561602
v  5.036788 1.556179 -23.978380
v  5.036788 3.206476 -23.978382
v  5.438640 3.189250 -23.561600
v  5.409354 3.154222 -23.561600
v  1.224884 5.995265 -23.739498
v  -4.867071 1.538952 -23.561600
v  -4.853863 4.821172 -23.561602
v  -4.853863 4.062634 -23.561602
v  1.224884 6.012492 -23.741219
v  -4.853863 2.412336 -23.561600
v  -4.465220 1.556179 -23.978380
v  5.425432 1.587188 -23.561600
v  -4.867071 4.869981 -23.561602
v  -4.855012 2.346874 -23.561600
v  -4.853863 4.045981 -23.561602
v  5.452421 1.556179 -23.561600
v  5.425432 4.062634 -23.561602
v  5.436917 4.045981 -23.561602
v  -4.867071 3.997172 -23.561602
v  -4.855012 4.887783 -23.561602
v  -4.855012 3.979370 -23.561602
v  5.452421 4.031626 -23.561602
v  -4.853863 1.570535 -23.561600
v  -4.865348 1.556179 -23.561600
v  -4.880852 1.538952 -23.561600
v  5.452421 2.381328 -23.561600
v  5.426581 2.346874 -23.561600
v  -4.855012 1.503924 -23.561600
v  -4.880852 4.031626 -23.561602
v  -4.465220 3.220832 -23.962877
v  -4.465220 3.206476 -23.978382
v  -4.865348 3.206477 -23.561600
v  5.036788 0.731030 -23.962877
v  -4.867071 3.172023 -23.561600
v  -4.865348 0.731030 -23.561600
v  5.036788 3.172023 -23.964600
v  5.425432 3.220832 -23.561600
v  5.452421 3.189250 -23.561600
v  5.438640 3.172023 -23.561600
v  5.409265 3.237485 -23.561600
v  5.425432 0.745386 -23.561600
v  5.438640 0.696577 -23.561600
v  -4.465220 3.172023 -23.964600
v  5.425432 4.045981 -23.561602
v  5.452421 2.364101 -23.561600
v  5.436917 4.031626 -23.561602
v  5.426581 2.329073 -23.561600
v  -4.880852 2.364101 -23.561600
v  5.452421 4.014399 -23.561602
v  -4.855012 2.329073 -23.561600
v  -4.853863 3.237485 -23.561600
v  5.425432 4.821172 -23.561602
v  -4.867071 3.189250 -23.561600
v  5.436917 4.835527 -23.561602
v  -4.855012 0.696577 -23.561600
v  -4.837785 3.154222 -23.561600
v  -4.465220 1.538952 -23.978380
v  5.425432 3.237485 -23.561600
v  -4.837785 2.329073 -23.561600
v  5.425432 2.412336 -23.561600
v  5.438640 2.364101 -23.561600
v  -4.867071 1.521725 -23.561600
v  5.436917 1.556179 -23.561600
v  5.409354 2.329073 -23.561600
v  -4.853863 3.220832 -23.561600
v  -4.880852 3.189250 -23.561600
v  5.409265 0.762039 -23.561600
v  -4.465220 0.696577 -23.964600
v  -4.853863 0.745386 -23.561600
v  -4.867071 0.696577 -23.561600
v  -4.837696 0.762039 -23.561600
v  5.436917 3.206477 -23.561600
v  5.426581 3.154222 -23.561600
v  5.436917 0.731031 -23.561600
v  5.452421 0.713803 -23.561600
v  5.426581 0.678776 -23.561600
v  5.438640 2.346874 -23.561600
v  -4.867071 4.014399 -23.561602
v  5.426581 4.869981 -23.561602
v  -0.653315 6.012492 -23.741219
v  -4.880852 4.835527 -23.561602
v  -4.867071 4.852755 -23.561602
v  -4.465220 1.570535 -23.962875
v  -4.465220 3.997171 -23.964602
v  5.036788 1.570535 -23.962875
v  -4.465220 2.381328 -23.978380
v  -4.880852 2.381328 -23.561600
v  -0.654848 5.109595 -23.956644
v  -4.465220 1.538952 -23.964600
v  -4.867071 2.364101 -23.561600
v  -4.837696 4.804519 -23.561602
v  5.426581 3.997172 -23.561602
v  1.226417 5.109595 -23.956644
v  -4.837696 3.237485 -23.561600
v  5.036788 3.189250 -23.978382
v  0.285784 6.488771 -12.238448
v  -4.465220 3.206476 -23.962877
v  -4.465220 3.189250 -23.978382
v  5.438640 4.014399 -23.561602
v  5.438640 4.852755 -23.561602
v  5.036788 2.381328 -23.978380
v  5.036788 4.821172 -23.962879
v  4.270782 2.438873 -11.253515
v  -0.801062 5.512938 -23.709558
v  1.372631 5.512938 -23.709558
v  0.285784 5.452144 -12.236726
v  0.285784 4.432345 -23.709558
v  0.285784 6.488770 -12.252230
v  0.285784 5.882857 -23.709558
v  5.036788 1.521726 -23.952541
v  -3.699213 3.352343 -20.514721
v  2.106413 1.192344 -11.260997
v  -1.534845 1.192344 -11.260997
v  4.525005 1.426531 -13.657756
v  4.523283 1.413324 -15.913961
v  5.453454 1.538953 -20.662743
v  0.285784 5.882857 -20.514290
v  4.538786 2.247800 -15.913961
v  2.106414 2.272345 -11.260997
v  4.525005 0.574829 -11.402122
v  4.495630 0.509366 -11.402122
v  4.525005 0.527167 -15.913960
v  4.512946 1.378870 -15.913961
v  5.036673 0.762040 -20.274101
v  -1.534845 2.272345 -11.253515
v  4.523283 2.279383 -15.913961
v  4.523283 0.540375 -11.402122
v  4.512946 1.361069 -15.913961
v  -0.808082 6.986866 -11.253489
v  4.512946 0.592630 -11.402122
v  4.512946 0.509366 -15.913960
v  4.495630 1.361069 -13.657756
v  5.426465 1.570535 -20.662743
v  4.525005 1.378870 -15.913961
v  4.511797 1.361069 -13.657756
v  4.495630 1.444332 -15.913961
v  5.439672 1.538953 -20.662743
v  4.511797 1.444332 -15.913961
v  4.512946 1.426531 -13.657756
v  5.036673 0.678776 -20.272953
v  5.036673 0.762040 -20.290268
v  4.538786 1.413324 -15.913961
v  4.270782 3.352343 -20.514721
v  4.538786 0.561621 -15.913960
v  4.512946 0.527167 -15.913960
v  4.495719 0.509366 -15.913960
v  4.525005 0.544394 -13.657755
v  5.036673 0.696577 -20.272953
v  4.511797 0.509366 -11.402122
v  4.538786 0.540375 -11.402122
v  -1.534845 2.272345 -11.260997
v  4.512946 0.574829 -11.402122
v  4.495719 0.592630 -11.402122
v  2.106414 2.272345 -11.253515
v  4.523283 0.561621 -15.913960
v  4.538786 0.544394 -13.657755
v  5.437950 1.570535 -20.662743
v  4.495630 0.592629 -15.913960
v  5.036673 0.731031 -20.262615
v  5.036673 0.696577 -20.260893
v  4.495719 1.361069 -15.913961
v  4.511797 2.279383 -15.913961
v  4.511797 0.592629 -15.913960
v  5.036673 0.731031 -20.247112
v  5.036673 0.678776 -20.290180
v  4.525005 2.247800 -15.913961
v  1.379651 6.986866 -11.253489
v  -0.808082 5.483727 -11.253489
v  1.350269 5.512938 -20.514290
v  1.379651 5.483727 -11.253489
v  5.036788 2.412336 -23.951393
v  5.036788 2.329073 -23.952541
v  5.036788 2.329073 -23.935314
v  -4.866381 3.220833 -20.662745
v  -4.465220 2.412336 -23.951393
v  5.437950 4.045982 -20.662745
v  -4.465220 2.412336 -23.935225
v  -3.550603 4.069621 -20.292286
v  5.426465 3.237486 -20.662745
v  5.453454 3.206477 -20.662745
v  -4.553664 1.192344 -23.245823
v  4.122171 3.243075 -20.292286
v  5.036673 1.570536 -20.274099
v  -3.924151 1.361068 -15.913961
v  -3.550603 4.052968 -20.292286
v  -4.856044 3.997172 -20.662745
v  -3.550603 4.004158 -20.279078
v  -3.951714 2.885160 -18.170166
v  4.122171 4.038612 -20.265297
v  5.426465 4.821172 -20.662745
v  4.122171 3.212066 -20.280800
v  -4.854896 4.821172 -20.662745
v  5.427613 4.887783 -20.662745
v  -3.550603 4.021385 -20.279078
v  -4.856044 4.887783 -20.662745
v  -4.838728 4.804520 -20.662745
v  5.437950 4.821172 -20.662745
v  -3.951714 2.279382 -15.913961
v  -3.967218 2.265027 -15.913961
v  5.427613 4.869982 -20.662745
v  5.426465 3.220833 -20.662745
v  5.453454 3.189250 -20.662745
v  4.523283 2.885160 -18.170166
v  -3.924062 1.444332 -15.913961
v  -4.856044 4.869982 -20.662745
v  4.122171 4.069621 -20.292286
v  -4.854896 4.062634 -20.662745
v  -4.854896 3.220833 -20.662745
v  -4.856044 3.979371 -20.662745
v  5.427613 3.979371 -20.662745
v  -4.868104 1.538953 -20.662743
v  4.122171 4.021385 -20.265297
v  -4.866381 4.045982 -20.662745
v  4.122171 4.004158 -20.279078
v  2.366799 4.896167 -18.170010
v  -4.465220 2.329073 -23.952541
v  -3.550603 3.212066 -20.280800
v  -1.795231 4.896167 -18.170010
v  -3.550603 3.194839 -20.265297
v  -3.550603 3.177613 -20.279078
v  5.439672 3.189250 -20.662745
v  -4.838818 3.154222 -20.662745
v  -3.550603 3.177613 -20.291136
v  -3.550603 3.243075 -20.292286
v  -3.940229 2.868507 -18.170166
v  5.437950 2.395684 -20.662745
v  -3.941378 2.933969 -18.170166
v  -3.940229 2.296035 -15.913961
v  4.512946 2.951771 -18.170166
v  -3.924151 2.212772 -15.913961
v  4.538786 2.899515 -18.170166
v  5.036673 1.570536 -20.262615
v  4.512946 2.933969 -18.170166
v  -3.953437 1.378870 -15.913961
v  -1.777915 4.812904 -18.170010
v  -3.941378 2.951771 -18.170166
v  -3.924062 2.868507 -18.170166
v  -4.838818 1.503925 -20.662743
v  -3.951714 1.427679 -15.913961
v  -1.821071 4.843912 -18.170010
v  2.349484 4.812904 -18.170010
v  -1.795231 4.878366 -18.170010
v  2.349573 4.896167 -18.170010
v  4.538786 2.261008 -13.657756
v  -3.924062 2.296035 -15.913961
v  -3.550603 4.038612 -20.280800
v  4.122171 4.052968 -20.280800
v  -3.550603 4.021385 -20.265297
v  -3.550603 3.986357 -20.291136
v  4.523283 2.229425 -13.657756
v  4.525005 2.261008 -13.657756
v  1.349579 7.826251 -12.090256
v  5.437950 4.835528 -20.662745
v  5.453454 4.852755 -20.662745
v  -3.967218 2.899515 -18.170166
v  1.224884 5.964257 -23.711845
v  4.122171 3.194839 -20.265297
v  1.224884 6.047520 -23.711933
v  -4.866381 4.835528 -20.662745
v  -3.953437 2.916742 -18.170166
v  4.122171 4.021385 -20.279078
v  4.122171 3.177613 -20.279078
v  -3.550603 4.038612 -20.265297
v  -4.868104 4.869982 -20.662745
v  -4.465220 4.852754 -23.978382
v  -3.550603 4.004158 -20.291136
v  5.426465 4.804520 -20.662745
v  5.036788 2.412336 -23.935225
v  2.378859 4.878366 -18.170010
v  4.122171 4.004158 -20.291136
v  2.377136 4.843912 -18.170010
v  5.439672 4.852755 -20.662745
v  5.439672 4.869982 -20.662745
v  4.511797 2.885160 -18.170166
v  5.036788 2.395684 -23.951393
v  4.523283 2.899515 -18.170166
v  -3.953437 2.247800 -15.913961
v  4.538786 2.916742 -18.170166
v  4.122171 3.986357 -20.308363
v  4.525005 2.933969 -18.170166
v  -3.941378 2.230573 -15.913961
v  5.437950 3.206477 -20.662745
v  4.495630 2.868507 -18.170166
v  5.439672 3.172024 -20.662745
v  -0.653315 5.964257 -23.711845
v  5.427613 3.154222 -20.662745
v  5.410297 3.237486 -20.662745
v  -4.465220 4.045981 -23.951393
v  4.511797 2.868507 -18.170166
v  -4.854896 4.045982 -20.662745
v  -4.881885 1.538953 -20.662743
v  2.392640 4.861139 -18.170010
v  -4.866381 4.031626 -20.662745
v  4.525005 2.916742 -18.170166
v  -4.854896 4.804520 -20.662745
v  -4.881885 4.014400 -20.662745
v  -4.866381 4.821172 -20.662745
v  4.495719 2.951771 -18.170166
v  -3.940229 2.885160 -18.170166
v  -4.881885 4.835528 -20.662745
v  -3.967218 1.396097 -15.913961
v  -4.881885 4.852755 -20.662745
v  -1.805567 4.843912 -18.170010
v  -4.868104 4.852755 -20.662745
v  -3.951714 2.899515 -18.170166
v  -4.868104 3.997172 -20.662745
v  -1.821071 4.861139 -18.170010
v  -3.941378 1.361068 -15.913961
v  -1.807290 4.878366 -18.170010
v  5.426465 4.045982 -20.662745
v  -3.967218 2.916742 -18.170166
v  5.453454 4.014400 -20.662745
v  -4.838818 4.887783 -20.662745
v  -3.953437 2.933969 -18.170166
v  5.439672 3.997172 -20.662745
v  4.122171 4.052968 -20.292286
v  -4.881885 1.556180 -20.662743
v  -4.866381 3.206477 -20.662745
v  4.122171 4.038612 -20.280800
v  -4.838728 4.062634 -20.662745
v  -4.868104 3.172024 -20.662745
v  -4.856044 3.172024 -20.662745
v  5.036788 4.852754 -23.978382
v  -4.856044 3.154222 -20.662745
v  5.426465 4.062634 -20.662745
v  1.349579 7.826251 -10.493279
v  -3.940229 1.444332 -15.913961
v  4.122171 4.069621 -20.308453
v  -3.967218 1.413324 -15.913961
v  -4.881885 4.031626 -20.662745
v  -3.941378 1.378870 -15.913961
v  -1.807290 4.861139 -18.170010
v  -3.550603 3.226422 -20.292286
v  -4.465220 2.329073 -23.935314
v  5.427613 3.997172 -20.662745
v  -3.550603 3.159811 -20.291136
v  -3.550603 3.243075 -20.308453
v  2.365651 4.812904 -18.170010
v  -0.654848 5.176206 -23.940567
v  5.426465 2.395684 -20.662745
v  2.377136 4.829556 -18.170010
v  -4.868104 4.014400 -20.662745
v  2.392640 4.843912 -18.170010
v  5.453454 2.364102 -20.662745
v  2.378859 4.861139 -18.170010
v  2.366799 4.878366 -18.170010
v  4.511797 2.229425 -13.657756
v  1.204497 5.141178 -20.260895
v  -0.632929 5.141178 -20.260895
v  -3.550603 4.812903 -20.308453
v  4.122171 3.226422 -20.280800
v  4.122171 3.177613 -20.291136
v  4.122171 3.159811 -20.308363
v  4.122171 3.243075 -20.308453
v  5.427613 3.172024 -20.662745
v  0.143068 6.523818 -10.375868
v  -4.854896 3.237486 -20.662745
v  -4.881885 3.206477 -20.662745
v  -4.881885 3.189250 -20.662745
v  -4.868104 3.189250 -20.662745
v  -4.838728 3.237486 -20.662745
v  -3.550603 3.226422 -20.280800
v  0.285784 5.512938 -23.709558
v  4.270782 0.112344 -20.514721
v  5.036673 1.538953 -20.247112
v  -4.868104 1.521726 -20.662743
v  -3.940229 1.427679 -15.913961
v  -3.951714 1.413324 -15.913961
v  5.036673 1.538953 -20.260893
v  -4.866381 1.570536 -20.662743
v  -1.794082 4.812904 -18.170010
v  -1.805567 4.829556 -18.170010
v  -3.953437 1.396097 -15.913961
v  -1.778004 4.896167 -18.170010
v  -3.940229 2.279382 -15.913961
v  -3.951714 2.265027 -15.913961
v  -3.967218 2.247800 -15.913961
v  4.210782 0.112344 -19.376001
v  -3.550603 4.069621 -20.308453
v  1.349489 7.742988 -12.090256
v  2.349483 4.812906 -11.399123
v  5.410297 4.804520 -20.662745
v  -4.465105 2.364102 -20.260895
v  5.439672 2.364102 -20.662745
v  -3.550603 4.052968 -20.280800
v  -4.838818 3.979371 -20.662745
v  4.122171 3.159811 -20.291136
v  -3.550603 3.986357 -20.308363
v  -3.924151 2.951771 -18.170166
v  -1.035928 5.141178 -20.662745
v  5.453454 4.835528 -20.662745
v  -4.465220 0.678775 -23.935314
v  5.410387 4.887783 -20.662745
v  1.349489 7.742988 -10.493279
v  -4.868104 2.364102 -20.662745
v  -4.866381 1.556180 -20.662743
v  -4.838728 1.587188 -20.662743
v  1.607497 5.141178 -20.662745
v  -4.465220 1.503924 -23.952541
v  -1.794082 4.829556 -18.170010
v  -0.777921 7.742988 -12.090256
v  5.437950 4.031626 -20.662745
v  -4.854896 1.587188 -20.662743
v  5.410297 4.062634 -20.662745
v  -4.856044 1.521726 -20.662743
v  4.122171 4.896166 -20.308363
v  -4.838818 2.329074 -20.662745
v  4.122171 3.986357 -20.291136
v  2.365651 4.829556 -18.170010
v  -3.550603 4.896166 -20.308363
v  5.453454 4.031626 -20.662745
v  0.285784 5.435492 -12.209074
v  5.439672 4.014400 -20.662745
v  -3.940229 2.229425 -13.657756
v  -4.838728 2.412337 -20.662745
v  -4.465105 1.556180 -20.262615
v  -4.868104 2.346875 -20.662745
v  5.410387 3.979371 -20.662745
v  -0.778707 5.435492 -11.253489
v  -4.465105 1.538953 -20.247112
v  -4.856044 2.329074 -20.662745
v  -3.941378 2.296036 -13.657756
v  0.285784 7.215764 -12.209074
v  -4.465105 1.587188 -20.290268
v  0.428500 6.523818 -10.443367
v  -3.953437 2.230573 -15.913961
v  -3.941378 2.212772 -15.913961
v  4.122171 3.212066 -20.265297
v  -4.465220 0.745386 -23.951393
v  -3.550603 3.212066 -20.265297
v  5.437950 3.220833 -20.662745
v  5.185232 0.112344 -20.514721
v  5.410387 3.154222 -20.662745
v  -3.639213 0.112344 -17.022503
v  -3.550603 3.194839 -20.279078
v  -4.613664 0.112344 -20.514721
v  1.350276 6.440536 -11.253489
v  -3.550603 3.159811 -20.308363
v  -3.924062 1.361069 -13.657756
v  -4.465105 1.556180 -20.247112
v  -4.465105 1.538953 -20.260893
v  1.578211 5.176207 -20.662745
v  -3.550437 2.951773 -11.029558
v  -3.967218 2.243781 -13.657756
v  4.495719 2.951772 -13.657756
v  -3.953437 2.261008 -13.657756
v  -4.465105 2.346875 -20.260895
v  -1.403173 3.750916 -11.009665
v  4.525005 2.916744 -11.402123
v  -3.550437 2.916744 -11.000273
v  -3.550437 2.933971 -11.012331
v  -1.006553 5.092943 -20.662745
v  4.511797 2.885161 -13.657756
v  1.974741 1.015537 -11.025743
v  2.349572 4.896169 -13.657599
v  -1.403173 3.734263 -11.025832
v  -4.854896 1.570536 -20.662743
v  4.525005 2.916744 -13.657756
v  -3.924062 2.296035 -18.170166
v  -4.856044 1.503925 -20.662743
v  -3.951714 1.392078 -13.657756
v  -1.006642 5.176207 -20.662745
v  -3.967218 1.409304 -13.657756
v  -3.953437 1.426531 -13.657756
v  -3.941378 1.444333 -13.657756
v  4.495630 2.868509 -13.657756
v  -4.465105 0.745387 -20.274101
v  -1.403173 3.817527 -11.025743
v  -3.953437 2.916744 -11.402123
v  5.036673 1.521726 -20.260893
v  1.578122 5.092943 -20.662745
v  -3.940229 1.361069 -13.657756
v  -3.951714 1.377722 -13.657756
v  1.974741 3.750916 -11.009665
v  -3.967218 1.392078 -13.657756
v  -3.953437 1.409304 -13.657756
v  -3.941378 1.426531 -13.657756
v  5.036673 2.346875 -20.260895
v  -3.924151 1.444333 -13.657756
v  -3.953437 2.916743 -15.913961
v  4.122006 2.916744 -11.000273
v  1.974741 3.817527 -11.025743
v  4.122006 2.933971 -11.012331
v  -3.951714 2.243781 -13.657756
v  -4.866381 2.395684 -20.662745
v  -4.881885 2.381329 -20.662745
v  -4.465105 1.570536 -20.262615
v  -3.924151 2.296036 -13.657756
v  4.495630 2.296035 -18.170166
v  -4.465105 1.503925 -20.272951
v  -4.465105 1.503925 -20.290178
v  -3.924062 2.212772 -13.657756
v  -1.403173 2.416532 -11.025743
v  5.437950 1.556180 -20.662743
v  -3.924151 2.951772 -13.657756
v  -4.465105 1.587188 -20.274099
v  5.439672 0.713805 -20.662745
v  5.439672 0.696577 -20.662745
v  -3.940229 0.509367 -11.402122
v  5.410387 0.678776 -20.662745
v  5.410297 0.762040 -20.662745
v  -3.967218 0.557602 -11.402122
v  -4.465105 0.731031 -20.262615
v  -4.838818 0.678776 -20.662745
v  -3.924062 0.592630 -13.657755
v  2.106414 0.112344 -11.253515
v  4.122006 0.592630 -11.029557
v  -1.778004 4.896169 -11.399123
v  -1.777915 4.812906 -11.399123
v  -4.465105 0.696578 -20.260893
v  4.210782 1.192344 -17.450851
v  -4.465105 0.696578 -20.272953
v  -4.465105 0.678776 -20.272953
v  -3.639213 0.112344 -19.376001
v  -3.967218 0.544394 -18.170166
v  -3.639213 0.112344 -18.918804
v  2.106414 0.112344 -11.260997
v  -3.639213 0.112344 -16.565306
v  -3.699213 0.112344 -11.253515
v  5.036673 0.745387 -20.274101
v  4.495630 0.592630 -13.657755
v  -1.534845 0.112344 -11.260997
v  4.210782 1.192344 -19.804352
v  -3.550437 0.540375 -11.001994
v  -3.550437 0.557602 -10.986490
v  4.210782 0.112344 -12.440769
v  -3.639213 1.192344 -19.347155
v  5.437950 0.745387 -20.662745
v  5.437950 0.731031 -20.662745
v  -3.639213 0.112344 -12.440769
v  -3.940229 0.592629 -18.170166
v  -3.941378 0.527167 -18.170166
v  -3.924151 0.509366 -13.657755
v  -3.924062 0.592629 -18.170166
v  -3.639213 1.192344 -15.190415
v  4.210782 0.112344 -17.022503
v  -3.967218 0.544395 -13.657755
v  5.036673 3.979371 -20.290180
v  5.036673 4.062634 -20.290268
v  2.378858 4.861139 -15.913805
v  1.366443 5.435492 -11.253489
v  -0.821863 7.246772 -11.253489
v  -4.465105 1.521726 -20.272951
v  -3.550437 2.885162 -11.013479
v  1.377928 5.452144 -11.253489
v  -0.796023 7.281226 -11.253489
v  4.495630 1.444332 -18.170166
v  0.285784 6.986866 -12.252230
v  4.525005 2.278235 -11.402122
v  -0.777921 7.742988 -10.493279
v  -3.940229 1.444331 -18.170166
v  4.538786 2.916744 -13.657756
v  -0.654848 5.123950 -23.968128
v  1.367592 5.500954 -11.253489
v  -3.940229 2.212772 -13.657756
v  5.437950 2.381329 -20.662745
v  -4.465220 4.804519 -23.951393
v  4.122171 3.226422 -20.292286
v  1.974741 0.932273 -11.009665
v  -3.951714 2.229425 -13.657756
v  1.350365 5.518755 -11.253489
v  -4.866381 2.381329 -20.662745
v  4.512946 2.933971 -13.657756
v  4.122006 2.296036 -11.029557
v  -3.941378 1.361068 -18.170166
v  4.495630 2.212772 -13.657756
v  2.377136 4.843913 -15.913805
v  1.377142 5.995266 -20.664276
v  2.349572 4.896169 -11.399123
v  2.392640 4.843913 -15.913805
v  4.525005 2.230573 -15.913961
v  2.392640 4.861139 -15.913805
v  5.036673 3.979371 -20.272953
v  1.392646 5.995266 -20.664276
v  -0.653315 6.047520 -23.711933
v  4.512946 2.230573 -15.913961
v  -3.941378 0.574829 -11.402122
v  5.036788 0.762039 -23.935225
v  -4.866381 0.731031 -20.662745
v  -3.941378 2.278234 -13.657756
v  4.512946 2.212772 -15.913961
v  2.378858 4.878366 -15.913805
v  -4.881885 0.731031 -20.662745
v  -4.465220 4.835527 -23.978382
v  4.495719 2.212772 -15.913961
v  2.366799 4.878366 -15.913805
v  4.122006 2.212773 -11.013479
v  -4.465220 4.045981 -23.962879
v  2.366799 4.896168 -15.913805
v  1.378864 6.012493 -20.664276
v  4.495630 2.296035 -15.913961
v  -3.550437 2.868509 -11.013479
v  4.122006 2.229426 -11.013479
v  -4.465220 4.031625 -23.978382
v  -1.403173 3.750916 -10.998180
v  2.349573 4.896168 -15.913805
v  -3.550437 2.885162 -11.001995
v  4.122006 2.229426 -11.001994
v  4.495719 2.296036 -11.402122
v  -3.550437 2.899518 -11.001995
v  -3.550437 2.899518 -10.986491
v  4.122006 2.243781 -11.001994
v  -3.550437 2.916744 -10.986491
v  -4.465220 4.852754 -23.964602
v  -4.465220 3.979370 -23.952541
v  0.285784 6.457188 -12.225241
v  4.122006 2.243781 -10.986490
v  -3.550437 2.933971 -11.000273
v  -1.403173 3.765272 -10.998180
v  4.122006 2.261008 -10.986490
v  1.974741 0.997735 -10.996457
v  -3.550437 2.951773 -11.012331
v  4.122006 2.261008 -11.000272
v  4.495630 2.212773 -11.402122
v  -3.550437 2.868509 -11.029647
v  5.036673 1.521726 -20.272951
v  4.122006 2.278235 -11.000272
v  -4.465220 4.869981 -23.964602
v  0.285784 6.986866 -12.238448
v  -4.881885 2.364102 -20.662745
v  4.122006 2.278235 -11.012331
v  -1.403173 3.765272 -10.982676
v  4.122006 2.296036 -11.012331
v  4.511797 2.868509 -13.657756
v  -0.653315 5.964257 -23.728012
v  4.538786 2.247799 -18.170166
v  0.285784 7.004093 -12.238448
v  1.605774 5.123951 -20.662745
v  5.036788 4.031625 -23.978382
v  -3.940229 2.229426 -11.402122
v  5.036673 3.997173 -20.260895
v  -0.653315 6.012492 -23.755001
v  1.974741 2.333268 -11.009665
v  -1.403173 3.782499 -10.982676
v  4.523283 2.279382 -18.170166
v  1.974741 2.349921 -11.009665
v  -3.940229 2.885162 -11.402123
v  4.512946 2.278235 -11.402122
v  4.523283 2.885161 -13.657756
v  -0.653315 6.029719 -23.729160
v  -0.653315 6.047520 -23.729160
v  0.285784 7.021894 -12.226389
v  1.974741 2.349921 -10.998180
v  -3.951714 0.561621 -13.657755
v  4.523283 2.265026 -18.170166
v  1.974741 2.364277 -10.998180
v  4.523283 2.899517 -13.657756
v  1.594289 5.092943 -20.662745
v  -1.403173 3.782499 -10.996457
v  -1.795230 4.878366 -15.913805
v  1.974741 2.364277 -10.982676
v  5.036788 2.346874 -23.952541
v  4.495630 0.592629 -18.170166
v  -1.795230 4.896168 -15.913805
v  -4.465220 4.062634 -23.951393
v  1.974741 2.381503 -10.982676
v  4.538786 2.899517 -13.657756
v  4.538786 2.265026 -18.170166
v  1.594289 5.109596 -20.662745
v  1.974741 2.381503 -10.996457
v  4.525005 2.247799 -18.170166
v  -4.465220 4.031625 -23.962879
v  1.974741 2.398730 -10.996457
v  -3.951714 2.279382 -18.170166
v  4.122171 4.829556 -20.292286
v  -3.967218 0.561621 -13.657755
v  -4.465220 4.887783 -23.935316
v  1.605774 5.109596 -20.662745
v  1.974741 2.398730 -11.008516
v  -1.022720 5.092943 -20.662745
v  -1.403173 3.799726 -10.996457
v  -3.967218 2.265026 -18.170166
v  -1.022720 5.109596 -20.662745
v  5.036788 3.979370 -23.952541
v  -3.967218 2.247799 -18.170166
v  1.974741 2.416532 -11.008516
v  -1.034205 5.109596 -20.662745
v  5.036788 1.587188 -23.935225
v  -3.953437 2.247799 -18.170166
v  -1.034205 5.123951 -20.662745
v  4.122006 0.574829 -11.012331
v  -4.465220 3.979370 -23.935316
v  -3.953437 2.230572 -18.170166
v  -1.049709 5.123951 -20.662745
v  -3.941378 2.230572 -18.170166
v  1.974741 2.416532 -11.025743
v  4.525005 2.933971 -13.657756
v  5.036788 3.979370 -23.935316
v  -1.054976 5.123950 -23.566853
v  -4.465220 4.804519 -23.935226
v  -3.924151 2.212771 -18.170166
v  -1.035928 5.158405 -20.662745
v  -3.940229 1.377722 -13.657756
v  1.621278 5.123951 -20.662745
v  4.122171 4.812903 -20.308453
v  -1.023869 5.158405 -20.662745
v  -1.403173 3.799726 -11.008516
v  -1.023869 5.176207 -20.662745
v  4.538786 1.396096 -18.170166
v  -3.550437 2.212773 -11.013479
v  -3.550437 2.229426 -11.013479
v  4.525005 2.230572 -18.170166
v  1.365657 7.742988 -10.493279
v  4.512946 2.951772 -13.657756
v  -3.550437 2.229426 -11.001994
v  5.036788 1.587188 -23.951393
v  5.036788 4.804519 -23.951393
v  1.204497 5.141178 -20.247112
v  -3.550437 2.243781 -11.001994
v  -1.403173 3.817527 -11.008516
v  5.036788 1.570535 -23.951393
v  1.365657 7.759641 -10.493279
v  -3.550437 2.243781 -10.986490
v  5.036673 2.364102 -20.260895
v  -0.821863 6.471544 -11.253489
v  4.122006 1.361070 -11.013479
v  5.036788 4.835527 -23.978382
v  -3.951714 2.229426 -11.402122
v  4.495719 1.361068 -18.170166
v  4.495719 2.212771 -18.170166
v  -3.550437 2.261008 -10.986490
v  5.426465 1.587188 -20.662743
v  -3.951714 2.243781 -11.402122
v  4.122006 1.377722 -11.013479
v  1.377142 7.759641 -10.493279
v  -3.967218 2.243781 -11.402122
v  -3.550603 4.829556 -20.280800
v  -3.550437 2.261008 -11.000272
v  -3.967218 2.261008 -11.402122
v  4.523283 2.899518 -11.402123
v  -3.951714 2.885162 -11.402123
v  4.122006 1.377722 -11.001994
v  -3.953437 2.261008 -11.402122
v  -3.951714 2.899518 -11.402123
v  1.204497 5.176207 -20.272953
v  -3.550437 2.278235 -11.000272
v  2.365651 4.829558 -13.657599
v  4.122006 2.868509 -11.029647
v  -3.953437 2.278235 -11.402122
v  5.036788 4.887783 -23.952541
v  -3.967218 2.899518 -11.402123
v  4.122006 1.392078 -11.001994
v  -3.941378 2.278235 -11.402122
v  4.525005 2.933971 -11.402123
v  -3.967218 2.916744 -11.402123
v  -3.550437 2.278235 -11.012331
v  -3.941378 2.296036 -11.402122
v  4.122171 4.829556 -20.280800
v  4.512946 2.230572 -18.170166
v  4.122006 1.392078 -10.986490
v  2.377136 4.829558 -13.657599
v  1.204497 5.176207 -20.290180
v  -3.953437 2.933971 -11.402123
v  -3.550437 2.296036 -11.012331
v  -0.821077 6.012493 -20.664276
v  4.122171 4.843912 -20.280800
v  -3.941378 2.933971 -11.402123
v  4.122006 1.409305 -10.986490
v  -3.550437 2.296036 -11.029557
v  -3.941378 2.951773 -11.402123
v  -3.940229 2.885160 -15.913961
v  4.122171 4.843912 -20.265297
v  4.122006 1.409305 -11.000272
v  -4.465105 3.220833 -20.262617
v  2.377136 4.843914 -13.657599
v  5.036788 3.172023 -23.952541
v  -4.465105 3.206478 -20.262617
v  4.122171 4.861138 -20.265297
v  5.036788 1.503924 -23.952541
v  4.122006 1.426532 -11.000272
v  1.392646 7.791223 -10.493279
v  -4.465105 3.189251 -20.247112
v  4.122171 4.861138 -20.279078
v  -3.951714 2.885160 -15.913961
v  4.122006 1.426532 -11.012331
v  2.392639 4.843914 -13.657599
v  4.122171 4.878365 -20.279078
v  4.122006 1.444333 -11.012331
v  1.378864 7.791223 -10.493279
v  -4.465105 3.154222 -20.272953
v  4.122171 4.878365 -20.291136
v  -1.043491 5.092942 -23.566853
v  -1.043491 5.109594 -23.566853
v  -3.951714 2.899516 -15.913961
v  2.392639 4.861141 -13.657599
v  4.512946 2.212771 -18.170166
v  4.122171 4.896166 -20.291136
v  -1.054976 5.109594 -23.566853
v  4.122006 1.361070 -11.029646
v  5.036673 1.503925 -20.272951
v  1.378864 7.808450 -10.493279
v  0.285784 6.505998 -12.238448
v  1.607497 5.158405 -20.662745
v  1.615060 5.092942 -23.566853
v  -1.070480 5.123950 -23.566853
v  -1.070480 5.141178 -23.566853
v  -3.967218 2.899516 -15.913961
v  2.378858 4.861141 -13.657599
v  -1.056699 5.141178 -23.566853
v  -1.056699 5.158404 -23.566853
v  1.366805 7.808450 -10.493279
v  5.453454 1.556180 -20.662743
v  -3.550437 1.361070 -11.029646
v  -1.044640 5.158404 -23.566853
v  4.512946 2.296036 -11.402122
v  -1.044640 5.176206 -23.566853
v  1.974741 3.750916 -10.998180
v  4.511797 1.444332 -18.170166
v  -4.465220 2.395684 -23.951393
v  2.378858 4.878367 -13.657599
v  4.122006 2.868509 -11.013479
v  -3.967218 2.916743 -15.913961
v  1.615060 5.109594 -23.566853
v  4.512946 2.951773 -11.402123
v  1.974741 3.765272 -10.998180
v  1.366805 7.826251 -10.493279
v  4.122006 2.885162 -11.013479
v  1.224884 6.012492 -23.755001
v  5.036673 1.587188 -20.290268
v  1.974741 3.765272 -10.982676
v  4.511797 1.427679 -18.170166
v  5.036788 4.852754 -23.964602
v  2.366799 4.878367 -13.657599
v  4.122006 2.885162 -11.001995
v  4.538786 2.899518 -11.402123
v  1.974741 3.782499 -10.982676
v  -3.924151 2.951771 -15.913961
v  5.036673 2.329074 -20.272953
v  5.036788 4.869981 -23.964602
v  4.122006 2.899518 -11.001995
v  -0.807296 6.029720 -20.664276
v  1.974741 3.782499 -10.996457
v  -3.953437 2.933970 -15.913961
v  1.595438 5.158405 -20.662745
v  4.523283 1.427679 -18.170166
v  5.036673 2.329074 -20.290180
v  -1.403173 4.829558 -11.009666
v  -1.534845 2.917419 -11.260997
v  1.204497 5.092943 -20.274101
v  4.122006 2.899518 -10.986491
v  2.366799 4.896169 -13.657599
v  1.974741 3.799726 -10.996457
v  1.626545 5.109594 -23.566853
v  4.122006 2.916744 -10.986491
v  -3.550437 1.361070 -11.013479
v  -1.403173 2.333268 -11.009665
v  1.974741 3.799726 -11.008516
v  4.523283 1.413323 -18.170166
v  1.204497 5.109596 -20.274101
v  -3.941378 2.933970 -15.913961
v  -3.550437 1.377723 -11.013479
v  -1.403173 2.349921 -11.009665
v  1.378864 7.808450 -12.090256
v  4.511797 2.868508 -15.913961
v  1.974741 3.817527 -11.008516
v  4.525005 2.261008 -11.402122
v  -0.805573 7.759641 -12.090256
v  5.036788 4.804519 -23.935226
v  -0.654848 5.092942 -23.956644
v  -4.465220 2.346874 -23.952541
v  -0.794875 5.435492 -11.253489
v  -0.821077 7.791223 -12.090256
v  4.122006 2.933971 -11.000273
v  1.626545 5.123950 -23.566853
v  -3.550437 1.377723 -11.001994
v  5.036673 3.220833 -20.262617
v  1.366805 7.826251 -12.090256
v  4.511797 2.885160 -15.913961
v  -1.403173 2.349921 -10.998180
v  -3.941378 2.951771 -15.913961
v  0.285784 7.232417 -12.236726
v  -0.807296 7.808450 -12.090256
v  5.036788 4.062634 -23.951393
v  4.538786 1.413323 -18.170166
v  0.285784 6.969639 -12.252230
v  1.204497 5.109596 -20.262617
v  -3.550437 1.392078 -11.001994
v  -0.795236 7.826251 -12.090256
v  -0.806360 5.452144 -11.253489
v  4.523283 2.885160 -15.913961
v  1.974741 3.734263 -11.025832
v  -1.403173 2.364277 -10.998180
v  0.285784 5.518755 -12.226389
v  5.036673 3.172024 -20.260895
v  1.224884 6.029719 -23.741219
v  -0.654848 5.123950 -23.983631
v  4.538786 1.392078 -13.657756
v  -0.821863 5.466500 -11.253489
v  -3.550437 1.392078 -10.986490
v  1.628267 5.141178 -23.566853
v  4.122006 2.951773 -11.012331
v  4.523283 2.899516 -15.913961
v  1.393432 7.246772 -11.253489
v  -1.403173 2.364277 -10.982676
v  -0.654848 5.141178 -23.983631
v  4.538786 1.409304 -13.657756
v  4.511797 1.377722 -11.402122
v  -3.550603 4.829556 -20.292286
v  -0.654848 5.141178 -23.969852
v  0.285784 7.299027 -12.209163
v  0.285784 7.263999 -12.238448
v  5.439672 1.521726 -20.662743
v  1.628267 5.158404 -23.566853
v  1.204497 5.158405 -20.272953
v  2.377136 4.829557 -15.913805
v  4.525005 1.409304 -13.657756
v  1.204497 5.123951 -20.262617
v  -3.924062 2.868508 -15.913961
v  4.523283 2.243781 -11.402122
v  -1.807290 4.878366 -15.913805
v  -4.465105 4.887784 -20.290180
v  -1.807290 4.861139 -15.913805
v  5.036673 3.997173 -20.272953
v  2.365651 4.829557 -15.913805
v  5.427613 2.346875 -20.662745
v  4.538786 2.265027 -15.913961
v  -1.778004 4.896169 -13.657599
v  2.365651 4.812904 -15.913805
v  -1.403173 2.398730 -10.996457
v  4.523283 2.265027 -15.913961
v  -4.465105 4.887784 -20.272953
v  -3.951714 1.377723 -11.402122
v  -0.794088 5.980911 -20.664276
v  -0.805573 7.759641 -10.493279
v  1.226417 5.092942 -23.956644
v  5.410387 1.503925 -20.662743
v  4.511797 2.296035 -18.170166
v  -4.854896 2.412337 -20.662745
v  2.106413 2.917419 -11.260997
v  5.036673 4.014400 -20.260895
v  4.538786 2.899516 -15.913961
v  -3.550437 1.426532 -11.000272
v  -3.550603 4.861138 -20.279078
v  -4.465220 1.521725 -23.952541
v  5.036673 4.869982 -20.260895
v  -4.465105 4.045982 -20.262617
v  1.392646 7.791223 -12.090256
v  4.523283 1.392078 -13.657756
v  4.512946 2.951771 -15.913961
v  -4.856044 2.346875 -20.662745
v  5.439672 2.346875 -20.662745
v  4.512946 2.933971 -11.402123
v  -1.795230 4.896169 -13.657599
v  -3.550437 1.409305 -10.986490
v  0.285784 6.955283 -12.236726
v  1.616208 5.158404 -23.566853
v  -3.951714 0.575977 -15.913960
v  -0.654848 5.158404 -23.969852
v  -1.821071 4.861139 -15.913805
v  5.036673 4.014400 -20.247112
v  1.595438 5.176207 -20.662745
v  0.285784 6.523799 -12.226389
v  -1.403173 2.381503 -10.982676
v  -1.403173 2.416532 -11.008516
v  -4.465105 4.869982 -20.272953
v  1.616208 5.176206 -23.566853
v  -0.654848 5.158404 -23.957792
v  4.511797 2.229426 -11.402122
v  -3.967218 2.261008 -13.657756
v  4.511797 1.427679 -15.913961
v  -0.794088 5.964258 -20.664276
v  4.538786 2.916743 -15.913961
v  1.642049 5.123950 -23.566853
v  -0.654848 5.176206 -23.957792
v  -3.550437 1.409305 -11.000272
v  -4.465105 1.570536 -20.274099
v  -0.632929 5.176207 -20.290180
v  -1.403173 1.015536 -11.025743
v  4.523283 1.427679 -15.913961
v  1.598982 5.176206 -23.566853
v  5.426465 2.412337 -20.662745
v  4.512946 1.444333 -13.657756
v  -0.805573 5.980911 -20.664276
v  -1.403173 2.381503 -10.996457
v  -3.550603 4.843912 -20.280800
v  4.512946 1.426532 -11.402122
v  -3.953437 2.278234 -13.657756
v  -0.805573 5.995266 -20.664276
v  -0.794088 7.742988 -10.493279
v  4.525005 1.396096 -18.170166
v  5.036788 4.045981 -23.951393
v  4.525005 2.916743 -15.913961
v  -0.654848 5.092942 -23.940477
v  4.512946 1.444333 -11.402122
v  5.036673 4.852756 -20.260895
v  -0.794088 7.759641 -10.493279
v  -0.821077 5.995266 -20.664276
v  1.204497 5.123951 -20.247112
v  1.367592 6.505998 -11.253489
v  4.523283 2.885162 -11.402123
v  5.036673 4.869982 -20.272953
v  -0.807296 6.012493 -20.664276
v  -3.550603 4.843912 -20.265297
v  -1.794082 4.812905 -13.657599
v  0.285784 6.359475 -10.443367
v  5.036673 4.887784 -20.272953
v  4.525005 2.933970 -15.913961
v  1.365657 5.964258 -20.664276
v  -1.794082 4.829558 -13.657599
v  1.224884 6.029719 -23.729160
v  -0.795236 6.029720 -20.664276
v  5.036673 4.804520 -20.290268
v  -0.821077 7.791223 -10.493279
v  -1.805567 4.829558 -13.657599
v  4.523283 2.229426 -11.402122
v  5.453454 2.381329 -20.662745
v  -0.807296 7.791223 -10.493279
v  -1.805567 4.843914 -13.657599
v  1.365657 5.980911 -20.664276
v  -3.967218 0.544394 -15.913960
v  4.525005 1.396097 -15.913961
v  -3.550437 1.426532 -11.012331
v  -0.778010 6.047522 -20.664276
v  -0.807296 7.808450 -10.493279
v  -1.821071 4.843914 -13.657599
v  -1.403173 2.398730 -11.008516
v  -0.795236 7.808450 -10.493279
v  -3.940229 1.361070 -11.402122
v  -1.821071 4.861141 -13.657599
v  -4.465105 4.062634 -20.274101
v  4.512946 2.933970 -15.913961
v  1.377142 5.980911 -20.664276
v  -3.550603 4.861138 -20.265297
v  4.525005 1.378869 -18.170166
v  1.642049 5.141178 -23.566853
v  -0.795236 7.826251 -10.493279
v  -1.807290 4.861141 -13.657599
v  -1.807290 4.878367 -13.657599
v  -4.465105 4.045982 -20.274101
v  -3.550437 1.444333 -11.012331
v  -1.795230 4.878367 -13.657599
v  4.511797 2.296035 -15.913961
v  -1.777915 4.812905 -13.657599
v  -3.953437 0.527167 -15.913960
v  -4.465105 4.031627 -20.262617
v  1.392646 6.012493 -20.664276
v  -3.951714 1.392078 -11.402122
v  -4.465105 4.804520 -20.274101
v  4.523283 1.377722 -13.657756
v  -3.940229 2.868508 -15.913961
v  -3.550603 4.878365 -20.279078
v  1.226417 5.123950 -23.968128
v  5.036788 4.045981 -23.962879
v  4.511797 2.212772 -13.657756
v  -4.465105 4.031627 -20.247112
v  4.511797 1.361070 -11.402122
v  -3.967218 1.392078 -11.402122
v  1.226417 5.123950 -23.983631
v  4.512946 1.378869 -18.170166
v  -4.465105 4.821173 -20.274101
v  4.538786 1.396097 -15.913961
v  1.226417 5.141178 -23.983631
v  5.410387 2.329074 -20.662745
v  -4.465105 4.014400 -20.247112
v  1.378864 6.029720 -20.664276
v  -3.967218 1.409305 -11.402122
v  -3.941378 0.527167 -15.913960
v  1.226417 5.141178 -23.969852
v  -3.940229 1.427679 -18.170166
v  4.523283 2.243781 -13.657756
v  -4.465105 4.821173 -20.262617
v  1.224884 6.047520 -23.729160
v  -3.550603 4.878365 -20.291136
v  1.226417 5.158404 -23.969852
v  5.036673 4.804520 -20.274101
v  -3.951714 2.885161 -13.657756
v  4.538786 2.243781 -13.657756
v  4.511797 2.885162 -11.402123
v  4.538786 2.243781 -11.402122
v  1.377142 7.759641 -12.090256
v  4.523283 1.377722 -11.402122
v  -4.465105 4.014400 -20.260895
v  4.495630 2.868508 -15.913961
v  1.226417 5.158404 -23.957792
v  1.366805 6.029720 -20.664276
v  -3.951714 1.413323 -18.170166
v  -3.953437 1.409305 -11.402122
v  -3.951714 2.899517 -13.657756
v  -0.632929 5.092943 -20.274101
v  1.974741 4.829558 -11.009666
v  4.538786 2.916744 -11.402123
v  -4.465105 4.835528 -20.262617
v  1.226417 5.176206 -23.957792
v  5.036673 4.821173 -20.274101
v  -3.967218 1.413323 -18.170166
v  -3.967218 2.899517 -13.657756
v  -1.794082 4.812904 -15.913805
v  -0.632929 5.109596 -20.274101
v  4.523283 1.392078 -11.402122
v  4.525005 2.278234 -13.657756
v  -4.465105 3.997173 -20.260895
v  5.427613 1.503925 -20.662743
v  -3.967218 2.916744 -13.657756
v  0.285784 5.500954 -12.226389
v  -0.632929 5.109596 -20.262617
v  1.366805 6.047522 -20.664276
v  -3.953437 1.426532 -11.402122
v  -4.465105 4.835528 -20.247112
v  1.226417 5.176206 -23.940567
v  -1.794082 4.829557 -15.913805
v  -3.550603 4.896166 -20.291136
v  5.036673 4.821173 -20.262617
v  -3.953437 1.396096 -18.170166
v  4.512946 2.278234 -13.657756
v  -3.953437 2.916744 -13.657756
v  -0.632929 5.123951 -20.262617
v  4.538786 1.392078 -11.402122
v  4.512946 1.361068 -18.170166
v  -3.953437 1.378869 -18.170166
v  -4.465105 3.997173 -20.272953
v  4.512946 2.296036 -13.657756
v  -3.953437 2.933971 -13.657756
v  0.285784 5.518755 -12.209163
v  -0.632929 5.123951 -20.247112
v  1.349489 5.964258 -20.664276
v  1.204497 5.158405 -20.260895
v  -3.941378 1.426532 -11.402122
v  -4.465105 4.852756 -20.247112
v  -1.805567 4.829557 -15.913805
v  5.036673 4.835528 -20.262617
v  -3.941378 1.378869 -18.170166
v  1.224884 5.964257 -23.728012
v  -3.941378 2.933971 -13.657756
v  -0.632929 5.141178 -20.247112
v  4.538786 1.409305 -11.402122
v  -4.465105 3.979371 -20.272953
v  4.495719 2.296036 -13.657756
v  -3.941378 2.951772 -13.657756
v  -3.941378 1.444333 -11.402122
v  -4.465105 4.852756 -20.260895
v  5.036673 4.062634 -20.274101
v  -1.805567 4.843913 -15.913805
v  5.036673 4.835528 -20.247112
v  -3.924151 1.361068 -18.170166
v  -0.632929 5.158405 -20.260895
v  5.036673 4.045982 -20.274101
v  4.525005 1.409305 -11.402122
v  4.538786 2.261008 -11.402122
v  -4.465105 3.979371 -20.290180
v  -0.632929 5.158405 -20.272953
v  5.036673 4.045982 -20.262617
v  -3.924151 1.444333 -11.402122
v  -4.465105 4.869982 -20.260895
v  -1.821071 4.843913 -15.913805
v  5.036673 4.852756 -20.247112
v  -0.632929 5.176207 -20.272953
v  -4.854896 2.395684 -20.662745
v  -0.795236 6.047522 -20.664276
v  5.036673 4.031627 -20.262617
v  4.525005 1.426532 -11.402122
v  -4.465105 4.062634 -20.290268
v  5.036673 4.031627 -20.247112
v  5.036673 3.237486 -20.290268
v  -1.403173 4.843914 -10.982677
v  1.974741 0.948926 -11.009665
v  1.974741 0.948926 -10.998180
v  1.350269 -0.067656 -10.375868
v  1.974741 0.963282 -10.998180
v  5.036673 3.206478 -20.262617
v  5.427613 0.696577 -20.662745
v  1.974741 0.963282 -10.982676
v  1.974741 0.980508 -10.982676
v  4.122171 3.194839 -20.279078
v  1.974741 0.980508 -10.996457
v  -4.465105 0.745387 -20.262615
v  5.036788 0.762039 -23.951393
v  5.427613 0.678776 -20.662745
v  5.036788 0.745386 -23.951393
v  1.393432 6.471544 -11.253489
v  1.974741 1.015537 -11.008516
v  -3.951714 0.540375 -11.402122
v  -1.403173 4.861141 -10.982677
v  1.974741 0.932273 -11.025832
v  5.036788 0.696577 -23.952541
v  -4.854896 0.762040 -20.662745
v  -4.854896 0.745387 -20.662745
v  -1.534845 0.112344 -11.253515
v  -3.941378 0.509366 -13.657755
v  -3.924151 0.592630 -11.402122
v  4.122006 0.509367 -11.013479
v  4.122006 0.526019 -11.013479
v  -4.856044 0.696578 -20.662745
v  -4.465105 0.731031 -20.247112
v  -4.856044 0.678776 -20.662745
v  -4.465220 0.696577 -23.952541
v  -4.465220 0.678775 -23.952541
v  -3.639213 1.192344 -16.993656
v  4.122006 0.526019 -11.001994
v  -4.838728 0.762040 -20.662745
v  5.036673 3.154222 -20.272953
v  -1.403173 4.861141 -10.996458
v  4.122006 0.540375 -11.001994
v  4.122006 0.540375 -10.986490
v  -4.465220 0.762039 -23.935225
v  4.122006 0.557602 -10.986490
v  4.122006 0.557602 -11.000272
v  -1.794082 4.812906 -11.399123
v  -0.806360 6.969639 -11.253489
v  -1.794082 4.829558 -11.399123
v  -1.805567 4.829558 -11.399123
v  -1.805567 4.843914 -11.399123
v  4.511797 0.592629 -18.170166
v  -1.821071 4.843914 -11.399123
v  4.511797 0.575977 -18.170166
v  -1.821071 4.861141 -11.399123
v  4.523283 0.575977 -18.170166
v  -0.796023 7.004093 -11.253489
v  4.122006 0.592630 -11.012331
v  -1.807290 4.861141 -11.399123
v  4.523283 0.561621 -18.170166
v  -4.465105 0.713805 -20.260893
v  -1.807290 4.878368 -11.399123
v  4.538786 0.561621 -18.170166
v  -1.795230 4.878368 -11.399123
v  4.538786 0.544394 -18.170166
v  -1.403173 4.878368 -10.996458
v  4.525005 0.544394 -18.170166
v  4.525005 0.527167 -18.170166
v  4.512946 0.527167 -18.170166
v  2.365651 4.812906 -11.399123
v  0.285784 7.232417 -12.225241
v  4.512946 0.509366 -18.170166
v  2.365651 4.829558 -11.399123
v  4.495719 0.509366 -18.170166
v  0.285784 6.440536 -12.225241
v  1.366443 6.440536 -11.253489
v  0.285784 7.263999 -12.252230
v  -3.699213 0.112344 -20.514721
v  2.377136 4.829558 -11.399123
v  0.285784 7.299027 -12.226389
v  2.377136 4.843914 -11.399123
v  0.428500 6.523818 -10.375868
v  -3.639213 1.192344 -12.411924
v  -1.403173 4.878368 -11.008518
v  5.036673 3.189251 -20.247112
v  0.285784 6.955283 -12.225241
v  2.392639 4.843914 -11.399123
v  0.285784 6.969639 -12.236726
v  4.523283 0.575977 -15.913960
v  2.392639 4.861141 -11.399123
v  1.367592 7.004093 -11.253489
v  4.538786 0.544394 -15.913960
v  0.285784 7.004093 -12.226389
v  2.378858 4.861141 -11.399123
v  4.525005 0.544394 -15.913960
v  5.036788 3.237485 -23.951393
v  -3.940229 0.575977 -18.170166
v  2.378858 4.878368 -11.399123
v  -3.639213 1.192344 -12.869120
v  -1.403173 4.896169 -11.008518
v  2.366799 4.878368 -11.399123
v  0.285784 6.505998 -12.226389
v  -3.953437 0.544394 -18.170166
v  -0.794875 6.440536 -11.253489
v  -3.953437 0.527167 -18.170166
v  1.377928 6.955283 -11.253489
v  5.036788 3.154221 -23.952541
v  -3.924151 0.509366 -18.170166
v  -0.796023 6.505998 -11.253489
v  0.285784 5.500954 -12.238448
v  5.036788 3.154221 -23.935314
v  5.036788 3.237485 -23.935225
v  5.036673 3.189251 -20.260895
v  -4.465105 3.237486 -20.274101
v  -4.465105 3.220833 -20.274101
v  1.393432 6.969639 -11.253489
v  0.285784 7.686130 -12.209099
v  1.350269 6.523818 -12.209099
v  -4.465105 3.206478 -20.247112
v  1.350269 7.299047 -11.253515
v  0.285784 5.675517 -10.443367
v  1.350269 7.686130 -11.253515
v  -4.465105 3.172024 -20.260895
v  1.350269 7.021914 -12.209099
v  0.285784 7.299047 -12.209099
v  1.350269 7.299047 -12.209099
v  1.377928 7.232417 -11.253489
v  0.285784 7.021914 -12.209099
v  1.350269 3.478235 -11.253515
v  1.350269 2.272345 -11.260997
v  0.285784 4.432345 -12.209099
v  1.350269 2.272345 -11.253515
v  0.285784 7.299047 -10.375868
v  1.350269 7.299047 -10.375868
v  5.185232 0.112344 -22.112141
v  0.285784 7.686130 -10.375868
v  0.285784 6.523818 -12.209099
v  0.285784 5.518775 -12.209099
v  1.379651 7.263999 -11.253489
v  -3.940229 0.575977 -15.913960
v  -0.778700 0.112344 -11.260997
v  0.285784 6.457188 -12.236726
v  5.036673 3.237486 -20.274101
v  -0.778700 -0.067656 -11.253515
v  1.377928 6.457188 -11.253489
v  1.350365 7.299027 -11.253489
v  -1.538330 3.794861 -11.484183
v  4.511797 0.592630 -13.657755
v  5.185232 3.352343 -22.112141
v  4.511797 0.575977 -13.657755
v  4.523283 0.575977 -13.657755
v  0.285784 6.938631 -12.209074
v  1.350276 6.938631 -11.253489
v  4.523283 0.561621 -13.657755
v  4.538786 0.561621 -13.657755
v  4.525005 0.527168 -13.657755
v  -3.953437 0.544394 -15.913960
v  5.036673 0.745387 -20.262615
v  4.512946 0.527168 -13.657755
v  -0.806360 5.466500 -11.253489
v  4.512946 0.509366 -13.657755
v  5.036673 0.713805 -20.247112
v  4.495719 0.509366 -13.657755
v  5.036673 0.713805 -20.260893
v  -0.801062 2.272345 -25.103155
v  5.185232 0.112344 -23.709558
v  -0.796023 5.500954 -11.253489
v  -4.613664 1.192344 -23.709558
v  -1.403173 4.812906 -11.009666
v  5.185232 1.192344 -23.709558
v  -4.613664 0.112344 -23.709558
v  -3.924062 0.592630 -15.913960
v  0.285784 -0.067656 -25.103155
v  -0.801062 0.112344 -25.103155
v  -0.801062 1.192344 -25.103155
v  -1.403173 0.963281 -10.998180
v  4.270782 1.192344 -11.253515
v  -1.403173 0.980508 -10.982676
v  1.350365 6.523799 -11.253489
v  -0.778700 7.686130 -11.253515
v  -0.778707 6.440536 -11.253489
v  -0.778700 6.523818 -12.209099
v  -0.778700 7.021914 -12.209099
v  1.372631 1.192344 -25.103155
v  -0.778700 3.478235 -11.253515
v  -0.778700 7.299047 -11.253515
v  1.350269 -0.067656 -11.253515
v  4.210782 0.112344 -16.565306
v  4.511797 0.526019 -11.402122
v  -0.778700 0.112344 -11.253515
v  -3.550437 0.526019 -11.001994
v  -4.613664 2.272345 -23.709558
v  1.974741 4.812906 -11.009666
v  1.350269 0.112344 -11.260997
v  4.523283 0.526019 -11.402122
v  -3.550437 0.557602 -11.000272
v  5.036673 3.172024 -20.272953
v  -3.550437 0.574829 -11.000272
v  1.974741 4.829558 -10.998180
v  4.210782 0.112344 -14.304869
v  1.974741 4.843914 -10.998180
v  -3.550437 0.592630 -11.012331
v  -0.778700 2.272345 -11.260997
v  1.974741 4.843914 -10.982677
v  1.974741 4.861141 -10.982677
v  -1.403173 4.829558 -10.998180
v  -0.778700 2.272345 -11.253515
v  1.974741 4.861141 -10.996458
v  1.974741 4.878368 -10.996458
v  1.974741 4.878368 -11.008518
v  -0.778700 7.299047 -12.209099
v  1.974741 4.896169 -11.008518
v  4.538786 0.557602 -11.402122
v  1.974741 4.812906 -11.025833
v  5.426465 0.762040 -20.662745
v  4.525005 0.557602 -11.402122
v  -4.465220 3.237485 -23.951393
v  1.974741 4.896169 -11.025743
v  5.426465 0.745387 -20.662745
v  0.285784 0.112344 -25.103155
v  -1.403173 4.843914 -10.998180
v  -4.465220 3.154221 -23.935314
v  -4.465220 3.237485 -23.935225
v  4.270782 0.112344 -11.253515
v  -4.613664 0.112344 -22.112141
v  5.453454 0.731031 -20.662745
v  -4.465105 0.762040 -20.274101
v  5.453454 0.713805 -20.662745
v  1.393432 5.466500 -11.253489
v  4.210782 1.192344 -16.993656
v  -0.778700 0.112344 -10.375868
v  -0.778700 7.021914 -10.375868
v  0.285784 3.352343 -25.103155
v  4.210782 1.192344 -14.733219
v  4.210782 1.192344 -15.190414
v  -3.639213 1.192344 -14.733219
v  -3.940229 0.575977 -13.657755
v  4.511797 0.575977 -15.913960
v  -3.953437 0.527168 -13.657755
v  -1.403173 0.948926 -10.998180
v  -1.403173 0.997735 -10.996457
v  -3.967218 0.561621 -15.913960
v  -0.324038 0.112344 -10.464714
v  -3.639213 1.192344 -17.450851
v  -3.951714 0.561621 -15.913960
v  -3.639213 0.112344 -14.762064
v  -3.924151 0.509366 -15.913960
v  4.210782 1.192344 -12.869120
v  4.210782 0.112344 -14.762064
v  -3.951714 0.575977 -13.657755
v  4.495630 1.361070 -11.402122
v  2.366799 4.896169 -11.399123
v  -4.465105 2.364102 -20.247112
v  4.511797 2.868509 -11.402123
v  5.410297 1.587188 -20.662743
v  -4.465105 2.381329 -20.262617
v  -3.940229 0.526019 -11.402122
v  -1.403173 3.734263 -11.009665
v  -3.953437 0.574829 -11.402122
v  -4.881885 0.713804 -20.662745
v  -4.868104 0.713804 -20.662745
v  -4.868104 0.696578 -20.662745
v  -3.940229 2.296035 -18.170166
v  -3.940229 2.279382 -18.170166
v  5.427613 2.329074 -20.662745
v  -4.465105 2.329074 -20.272953
v  2.349483 4.812904 -15.913805
v  -3.951714 2.265026 -18.170166
v  5.036673 1.556180 -20.247112
v  -4.465105 2.329074 -20.290180
v  -1.049709 5.141178 -20.662745
v  -3.941378 2.212771 -18.170166
v  1.974741 0.997735 -11.008516
v  4.495630 2.868509 -11.402123
v  -4.465105 2.412337 -20.290268
v  -1.795230 4.896169 -11.399123
v  4.511797 2.279382 -18.170166
v  4.122006 2.212773 -11.029646
v  -1.778004 4.896168 -15.913805
v  -1.777915 4.812904 -15.913805
v  1.974741 2.333268 -11.025832
v  1.621278 5.141178 -20.662745
v  4.122006 0.509367 -11.029646
v  5.036673 2.381329 -20.262617
v  -3.940229 2.212773 -11.402122
v  1.974741 3.734263 -11.009665
v  2.365651 4.812905 -13.657599
v  -3.940229 2.868509 -11.402123
v  -3.951714 0.561621 -18.170166
v  -3.550437 2.212773 -11.029646
v  -3.941378 0.509366 -18.170166
v  5.036673 2.395684 -20.274101
v  5.036673 2.395684 -20.262617
v  4.122006 1.444333 -11.029557
v  -3.940229 1.377723 -11.402122
v  5.036673 2.381329 -20.247112
v  -3.550437 1.444333 -11.029557
v  5.036673 2.346875 -20.272953
v  4.495719 2.951773 -11.402123
v  -3.940229 2.868509 -13.657756
v  -3.940229 2.885161 -13.657756
v  2.349483 4.812905 -13.657599
v  -3.940229 0.592630 -15.913960
v  4.511797 2.212773 -11.402122
v  -1.403173 0.948926 -11.009665
v  5.427613 1.521726 -20.662743
v  -1.403173 0.963281 -10.982676
v  -1.403173 1.015536 -11.008516
v  4.122006 2.951773 -11.029558
v  4.495719 1.444333 -13.657756
v  -3.550437 0.526019 -11.013479
v  -4.465105 2.412337 -20.274101
v  -4.465105 2.395684 -20.274101
v  -3.550437 0.540375 -10.986490
v  4.511797 1.377722 -13.657756
v  -3.550437 0.509367 -11.029646
v  -4.465105 2.395684 -20.262617
v  4.495719 2.951771 -15.913961
v  -3.951714 1.427679 -18.170166
v  -1.403173 2.333268 -11.025832
v  0.285784 5.483727 -12.238448
v  -3.967218 1.396096 -18.170166
v  -3.924062 1.361070 -11.402122
v  -0.324038 0.112344 -10.375868
v  -3.951714 0.526019 -11.402122
v  -3.953437 0.557602 -11.402122
v  -3.967218 0.540375 -11.402122
v  -3.941378 0.527168 -13.657755
v  -3.941378 0.592630 -11.402122
v  -3.924062 0.509367 -11.402122
v  4.122006 0.574829 -11.000272
v  -3.639213 1.192344 -19.804352
v  -3.951714 0.575977 -18.170166
v  -3.967218 0.561621 -18.170166
v  -4.465105 0.678776 -20.290180
v  -4.465105 0.762040 -20.290268
v  -1.538330 3.794861 -20.514290
v  4.210782 1.192344 -12.411924
v  -0.796023 5.518755 -11.253489
v  -3.940229 0.592630 -13.657755
v  -1.403173 0.980508 -10.996457
v  -3.550437 0.509367 -11.013479
v  -3.550437 0.574829 -11.012331
v  -3.550437 0.592630 -11.029557
v  -3.639213 0.112344 -14.304869
v  2.109898 3.794861 -11.484183
v  2.109898 3.794861 -20.514290
v  4.210782 1.192344 -19.347155
v  -3.953437 0.544395 -13.657755
v  1.367592 5.518755 -11.253489
v  -1.403173 0.932273 -11.025832
v  -3.941378 0.509366 -15.913960
v  -1.403173 0.932273 -11.009665
v  -0.794875 7.232417 -11.253489
v  4.495719 1.444333 -11.402122
v  1.378864 7.791223 -12.090256
v  -0.806360 7.246772 -11.253489
v  5.036673 4.887784 -20.290180
v  -0.821863 7.263999 -11.253489
v  -0.808082 7.281226 -11.253489
v  -0.796023 7.299027 -11.253489
v  -0.778796 7.299027 -11.253489
v  1.366805 7.808450 -12.090256
v  1.350276 7.215764 -11.253489
v  1.379651 5.500954 -11.253489
v  -4.465105 2.381329 -20.247112
v  5.036788 0.678775 -23.935314
v  -4.465220 0.762039 -23.951393
v  -4.465105 2.346875 -20.272953
v  5.036673 1.556180 -20.262615
v  1.379651 7.004093 -11.253489
v  -0.794875 6.955283 -11.253489
v  1.377142 7.773996 -10.493279
v  -0.808082 7.004093 -11.253489
v  0.285784 6.440536 -12.209074
v  5.410297 2.412337 -20.662745
v  -0.778707 6.938631 -11.253489
v  1.349579 6.047522 -20.664276
v  -4.465220 1.570535 -23.951393
v  -3.924062 2.212773 -11.402122
v  -3.924062 2.868509 -11.402123
v  5.036788 1.503924 -23.935314
v  5.036673 1.587188 -20.274099
v  -3.550603 4.812903 -20.292286
v  -0.794875 6.457188 -11.253489
v  -0.821863 6.488770 -11.253489
v  1.350365 7.021894 -11.253489
v  -0.808082 6.505998 -11.253489
v  4.122171 4.812903 -20.292286
v  1.204497 5.092943 -20.290268
v  -0.796023 6.523799 -11.253489
v  -3.924151 2.296036 -11.402122
v  -3.924151 2.951773 -11.402123
v  5.036673 2.412337 -20.274101
v  -4.465105 3.237486 -20.290268
v  5.036673 2.364102 -20.247112
v  -4.465105 0.713805 -20.247112
v  -1.027324 5.092942 -23.566853
v  -4.465105 4.804520 -20.290268
v  1.366443 7.232417 -11.253489
v  5.036673 2.412337 -20.290268
v  -0.807296 7.791223 -12.090256
v  -0.795236 7.808450 -12.090256
v  0.285784 6.523799 -12.209163
v  -0.778010 7.826251 -12.090256
v  1.366443 6.457188 -11.253489
v  1.366443 6.955283 -11.253489
v  -0.632929 5.092943 -20.290268
v  -3.924062 1.444331 -18.170166
v  1.377928 6.969639 -11.253489
v  -0.808082 5.500954 -11.253489
v  1.393432 7.263999 -11.253489
v  1.393432 6.488770 -11.253489
v  1.379651 6.505998 -11.253489
v  1.598893 5.092942 -23.566853
v  -0.805573 7.773996 -10.493279
v  1.367592 6.523799 -11.253489
v  -4.465105 1.521726 -20.260893
v  -0.777921 5.964258 -20.664276
v  1.379651 7.281226 -11.253489
v  1.367592 7.299027 -11.253489
v  1.350276 5.435492 -11.253489
v  5.036673 3.206478 -20.247112
v  -0.794875 6.938631 -11.253489
v  -0.806360 6.955283 -11.253489
v  -0.821863 6.969639 -11.253489
v  -0.778796 7.021894 -11.253489
v  0.285784 5.675517 -10.375868
v  0.285784 6.938631 -12.225241
v  1.366443 6.938631 -11.253489
v  -0.806360 6.457188 -11.253489
v  -0.808082 6.488770 -11.253489
v  -0.778796 6.523799 -11.253489
v  -1.403173 4.896169 -11.025743
v  -4.465105 3.189251 -20.260895
v  -4.465105 3.172024 -20.272953
v  0.285784 7.215764 -12.225241
v  1.366443 7.215764 -11.253489
v  -4.465105 3.154222 -20.290180
v  0.285784 7.246772 -12.236726
v  1.377928 7.246772 -11.253489
v  -1.403173 4.812906 -11.025833
v  5.036673 3.154222 -20.290180
v  5.036673 3.220833 -20.274101
v  1.377928 6.471544 -11.253489
v  -0.794875 5.452144 -11.253489
v  -0.778796 5.518755 -11.253489
v  4.210782 0.112344 -18.918804
v  -0.794875 7.215764 -11.253489
v  1.379651 6.488770 -11.253489
v  -0.806360 7.232417 -11.253489
v  1.366443 5.452144 -11.253489
v  0.285784 5.466500 -12.236726
v  1.377928 5.466500 -11.253489
v  -0.808082 7.263999 -11.253489
v  -4.866381 0.745387 -20.662745
v  5.036673 1.503925 -20.290178
v  -3.924062 2.868509 -13.657756
v  -1.403173 0.997735 -11.008516
v  -0.778707 7.215764 -11.253489
v  1.226417 5.092942 -23.940477
v  -0.653315 6.029719 -23.741219
v  -4.465220 4.821172 -23.951393
v  -0.794088 7.742988 -12.090256
v  -4.465220 4.835527 -23.962879
v  -4.465220 4.014399 -23.978382
v  -4.465220 3.997171 -23.952541
v  1.392646 7.773996 -10.493279
v  -0.805573 7.773996 -12.090256
v  -0.796023 7.021894 -11.253489
v  -4.465220 4.869981 -23.952541
v  5.036788 4.014399 -23.978382
v  -4.465220 1.587188 -23.951393
v  1.367592 7.021894 -11.253489
v  -0.794088 7.759641 -12.090256
v  5.036788 3.997171 -23.952541
v  5.036788 4.821172 -23.951393
v  5.036788 4.835527 -23.962879
v  5.036788 3.220832 -23.951393
v  -0.821077 7.773996 -12.090256
v  5.036788 4.887783 -23.935316
v  -1.027413 5.176206 -23.566853
v  5.036788 4.869981 -23.952541
v  0.285784 6.471544 -12.236726
v  1.377142 7.773996 -12.090256
v  -4.465220 3.172023 -23.952541
v  1.392646 7.773996 -12.090256
v  1.365657 7.759641 -12.090256
v  -0.821077 7.773996 -10.493279
v  -4.465220 1.587188 -23.935225
v  -0.778010 7.826251 -10.493279
v  1.365657 7.742988 -12.090256
v  5.036788 4.031625 -23.962879
v  -0.801062 0.112344 -23.709558
v  1.372631 0.112344 -23.709558
v  0.285784 6.359475 -10.375868
v  0.285784 7.021894 -12.209163
v  -0.741063 1.192344 -24.615234
v  -0.806360 6.471544 -11.253489
v  0.285784 7.281226 -12.226389
v  1.367592 7.281226 -11.253489
v  -3.699213 2.272345 -11.253515
v  1.312631 1.192344 -24.615234
v  -3.699213 1.192344 -11.253515
v  -0.801062 2.272345 -23.709558
v  -4.465220 3.154221 -23.952541
v  4.270782 2.272344 -11.253515
v  -4.465220 3.220832 -23.951393
v  5.036788 0.678775 -23.952541
v  -4.465220 4.887783 -23.952541
v  0.285784 2.272344 -25.103155
v  -4.465220 4.062634 -23.935226
v  0.285784 1.192344 -25.103155
v  5.036788 4.062634 -23.935226
v  -4.613664 3.352343 -22.112141
v  -4.465220 1.503924 -23.935314
v  -0.756263 4.432345 -25.058355
v  -0.756263 4.432345 -23.754358
v  1.327832 4.432345 -23.754358
v  1.327832 4.432345 -25.058355
v  -0.756263 4.352816 -25.058355
v  -0.756263 4.352816 -23.754358
v  1.327832 4.352816 -23.754358
v  1.327832 4.352816 -25.058355
v  3.117053 0.169258 -10.385433
v  3.045739 0.169258 -10.385433
v  3.045739 -0.082123 -10.385433
v  3.117053 -0.082123 -10.385433
v  3.117053 0.169258 -10.432781
v  3.117053 -0.082123 -10.432781
v  3.045739 0.169258 -10.480129
v  3.117053 0.169258 -10.480129
v  3.117053 -0.082123 -10.480129
v  3.045739 -0.082123 -10.480129
v  2.951833 0.169258 -10.313342
v  2.951833 0.169258 -10.385491
v  2.951833 -0.082123 -10.385491
v  2.951833 -0.082123 -10.313342
v  2.998633 0.169258 -10.313342
v  2.998633 -0.082123 -10.313342
v  3.045433 0.169258 -10.385491
v  3.045433 0.169258 -10.313342
v  3.045433 -0.082123 -10.313342
v  3.045433 -0.082123 -10.385491
v  2.139392 0.169258 -10.385433
v  2.210706 0.169258 -10.385433
v  2.210706 -0.082123 -10.385433
v  2.139392 -0.082123 -10.385433
v  2.139392 0.169258 -10.432781
v  2.139392 -0.082123 -10.432781
v  2.210706 0.169258 -10.480129
v  2.139392 0.169258 -10.480129
v  2.139392 -0.082123 -10.480129
v  2.210706 -0.082123 -10.480129
v  2.304611 0.169258 -10.313342
v  2.304611 0.169258 -10.385491
v  2.304611 -0.082123 -10.385491
v  2.304611 -0.082123 -10.313342
v  2.257812 0.169258 -10.313342
v  2.257812 -0.082123 -10.313342
v  2.211013 0.169258 -10.385491
v  2.211013 0.169258 -10.313342
v  2.211013 -0.082123 -10.313342
v  2.211013 -0.082123 -10.385491
v  2.210706 1.286014 -10.385433
v  2.139392 1.286014 -10.385433
v  2.139392 1.286014 -10.432781
v  2.139392 1.286014 -10.480129
v  2.210706 1.286014 -10.480129
v  2.257812 1.286014 -10.431128
v  2.304611 1.286014 -10.385491
v  2.304611 1.286014 -10.313342
v  2.257812 1.286014 -10.313342
v  2.211013 1.286014 -10.313342
v  2.211013 1.286014 -10.385491
v  2.139392 1.027680 -10.385433
v  2.210706 1.027680 -10.385433
v  2.210706 0.776299 -10.385433
v  2.139392 0.776299 -10.385433
v  2.139392 0.776299 -10.432781
v  2.210706 1.027680 -10.480129
v  2.139392 1.027680 -10.480129
v  2.139392 0.776299 -10.480129
v  2.210706 0.776299 -10.480129
v  2.304611 1.027680 -10.313342
v  2.304611 1.027680 -10.385491
v  2.304611 0.776299 -10.385491
v  2.304611 0.776299 -10.313342
v  2.257812 0.776299 -10.313342
v  2.211013 1.027680 -10.385491
v  2.211013 1.027680 -10.313342
v  2.211013 0.776299 -10.313342
v  2.211013 0.776299 -10.385491
v  3.045739 1.286014 -10.385433
v  3.117053 1.286014 -10.385433
v  3.117053 1.286014 -10.432781
v  3.117053 1.286014 -10.480129
v  3.045739 1.286014 -10.480129
v  2.998633 1.286014 -10.431128
v  2.951833 1.286014 -10.385491
v  2.951833 1.286014 -10.313342
v  2.998633 1.286014 -10.313342
v  3.045433 1.286014 -10.313342
v  3.045433 1.286014 -10.385491
v  3.117053 1.027680 -10.385433
v  3.045739 1.027680 -10.385433
v  3.045739 0.776299 -10.385433
v  3.117053 0.776299 -10.385433
v  3.117053 0.776299 -10.432781
v  3.045739 1.027680 -10.480129
v  3.117053 1.027680 -10.480129
v  3.117053 0.776299 -10.480129
v  3.045739 0.776299 -10.480129
v  2.951833 1.027680 -10.313342
v  2.951833 1.027680 -10.385491
v  2.951833 0.776299 -10.385491
v  2.951833 0.776299 -10.313342
v  2.998633 0.776299 -10.313342
v  3.045433 1.027680 -10.385491
v  3.045433 1.027680 -10.313342
v  3.045433 0.776299 -10.313342
v  3.045433 0.776299 -10.385491
v  2.139392 1.417913 -10.432781
v  3.117053 1.417913 -10.432781
v  2.998633 1.417913 -10.313342
v  2.257812 1.417913 -10.313342
v  2.225994 1.002087 -10.431230
v  3.029866 1.002087 -10.431230
v  2.225994 1.002087 -11.246701
v  3.029866 1.002087 -11.246701
v  3.029866 1.002087 -11.274077
v  3.029866 1.002087 -10.431230
v  3.029866 -0.075318 -11.274077
v  3.029866 -0.075318 -10.431230
v  2.225994 1.002087 -10.431230
v  2.225994 1.002087 -11.261898
v  2.225994 -0.075318 -10.431230
v  2.225994 -0.075318 -11.261898
v  2.627930 1.376718 -11.246701
v  2.627930 1.376718 -10.431230
v  2.627930 1.700937 -10.431230
v  2.225994 0.515974 -10.431230
v  2.649954 1.040515 -10.431230
v  3.029866 0.566669 -10.431230
v  2.225994 1.002087 -10.431230
v  3.029866 1.002087 -10.431230
v  2.162535 0.168516 -10.446701
v  2.173995 0.168516 -10.420961
v  2.149950 0.781630 -10.442612
v  3.093322 0.168516 -10.446701
v  3.105907 0.781630 -10.442612
v  3.081862 0.168516 -10.420961
v  3.080161 0.781630 -10.423906
v  2.175695 0.781630 -10.423906
v  2.987398 0.168516 -10.368868
v  2.987398 0.781630 -10.344690
v  2.960602 0.168516 -10.353397
v  2.268459 0.168516 -10.368868
v  2.295255 0.168516 -10.353397
v  2.268459 0.781630 -10.344690
v  3.027882 0.168516 -10.368868
v  3.027882 0.781630 -10.344690
v  3.001086 0.168516 -10.353397
v  2.227975 0.168516 -10.368868
v  2.254771 0.168516 -10.353397
v  2.227975 0.781630 -10.344690
v  3.046405 0.168516 -10.416435
v  3.062672 0.781630 -10.416435
v  3.062672 0.168516 -10.444611
v  2.209452 0.168516 -10.416435
v  2.193185 0.168516 -10.444611
v  2.193185 0.781630 -10.416435
v  2.290228 0.781630 -10.368868
v  2.965629 0.781630 -10.368868
v  2.249745 0.781630 -10.368868
v  3.006112 0.781630 -10.368868
v  3.065423 0.781630 -10.442612
v  2.190434 0.781630 -10.442612
v  2.257766 1.417913 -10.431954
v  2.998679 1.417913 -10.431954
v  3.038271 0.781630 -10.430523
v  2.217585 0.781630 -10.430523
v  2.274317 0.168516 -10.341309
v  2.981540 0.168516 -10.341309
v  2.233833 0.168516 -10.341309
v  3.022024 0.168516 -10.341309
v  2.304610 1.286014 -10.480129
v  2.951834 1.286014 -10.480129
v  2.149950 0.168516 -10.418434
v  3.105907 0.168516 -10.418434
v  2.145974 0.781630 -10.423906
v  3.109883 0.781630 -10.423906
v  3.008863 0.781630 -10.342691
v  2.246994 0.781630 -10.342691
v  2.968380 0.781630 -10.342691
v  2.287477 0.781630 -10.342691
v  2.314860 0.736612 -10.431230
v  2.211013 1.286014 -10.431128
v  3.045433 1.286014 -10.431128
v  3.043654 0.168516 -10.442612
v  2.212203 0.168516 -10.442612
v  2.190434 0.168516 -10.418434
v  3.065423 0.168516 -10.418434
v  3.109883 0.168516 -10.437140
v  2.145974 0.168516 -10.437140
v  2.971487 0.168516 -10.372251
v  2.243886 0.168516 -10.372251
v  3.011970 0.168516 -10.372251
v  2.284370 0.168516 -10.372251
v  2.981540 0.781630 -10.372251
v  3.022024 0.781630 -10.372251
v  2.274317 0.781630 -10.372251
v  2.233833 0.781630 -10.372251
v  2.257720 1.286014 -10.385433
v  2.998725 1.286014 -10.385433
v  2.168968 0.781630 -10.444611
v  2.209452 0.781630 -10.444611
v  3.086889 0.781630 -10.444611
v  3.046405 0.781630 -10.444611
v  2.973317 0.715755 -10.431230
v  2.162535 0.781630 -10.414345
v  3.093322 0.781630 -10.414345
v  2.263076 0.781630 -10.356779
v  2.992780 0.781630 -10.356779
v  3.033264 0.781630 -10.356779
v  2.222593 0.781630 -10.356779
v  2.222593 0.168516 -10.356779
v  2.263076 0.168516 -10.356779
v  3.033264 0.168516 -10.356779
v  2.992780 0.168516 -10.356779
v  3.069399 0.168516 -10.437140
v  2.186458 0.168516 -10.437140
v  2.186458 0.781630 -10.423906
v  3.069399 0.781630 -10.423906
v  2.217230 0.168516 -10.427140
v  3.038627 0.168516 -10.427140
v  2.255127 0.781630 -10.356779
v  2.960246 0.781630 -10.356779
v  3.000730 0.781630 -10.356779
v  2.295610 0.781630 -10.356779
v  3.078755 0.168516 -10.430523
v  2.177102 0.168516 -10.430523
v  2.214478 0.781630 -10.420961
v  3.041378 0.781630 -10.420961
v  2.387573 0.833923 -10.431230
v  2.906648 0.814961 -10.431230
v  2.254784 0.614921 -10.431230
v  3.002136 0.168516 -10.363396
v  2.294204 0.168516 -10.363396
v  2.253721 0.168516 -10.363396
v  2.961653 0.168516 -10.363396
v  2.175695 0.781630 -10.437140
v  3.080161 0.781630 -10.437140
v  2.284370 0.168516 -10.341309
v  2.971487 0.168516 -10.341309
v  2.243886 0.168516 -10.341309
v  3.011970 0.168516 -10.341309
v  3.096723 0.168516 -10.414345
v  2.159134 0.168516 -10.414345
v  2.469769 0.912945 -10.431230
v  2.827833 0.898725 -10.431230
v  2.199618 0.168516 -10.414345
v  3.056239 0.168516 -10.414345
v  3.103156 0.781630 -10.416435
v  2.152701 0.781630 -10.416435
v  3.103156 0.168516 -10.444611
v  2.152701 0.168516 -10.444611
v  2.978214 0.781630 -10.340601
v  2.277643 0.781630 -10.340601
v  2.237159 0.781630 -10.340601
v  3.018697 0.781630 -10.340601
v  2.159134 0.781630 -10.446701
v  3.096723 0.781630 -10.446701
v  3.056239 0.781630 -10.446701
v  2.199618 0.781630 -10.446701
v  3.052838 0.168516 -10.446701
v  2.203019 0.168516 -10.446701
v  3.052838 0.781630 -10.414345
v  2.203018 0.781630 -10.414345
v  2.171720 0.168516 -10.442612
v  3.084137 0.168516 -10.442612
v  2.558283 0.979778 -10.431230
v  2.740920 0.972193 -10.431230
v  2.284370 0.781630 -10.372251
v  2.243886 0.781630 -10.372251
v  2.971487 0.781630 -10.372251
v  3.011970 0.781630 -10.372251
v  3.022024 0.168516 -10.372251
v  2.233833 0.168516 -10.372251
v  2.981540 0.168516 -10.372251
v  2.274317 0.168516 -10.372251
v  3.023784 0.595960 -10.431230
v  3.027882 0.781630 -10.368868
v  2.227975 0.781630 -10.368868
v  2.268459 0.781630 -10.368868
v  2.987398 0.781630 -10.368868
v  3.027882 0.168516 -10.344690
v  2.227975 0.168516 -10.344690
v  3.086889 0.781630 -10.416435
v  2.168968 0.781630 -10.416435
v  2.268459 0.168516 -10.344690
v  2.987398 0.168516 -10.344690
v  2.168968 0.168516 -10.416435
v  3.086889 0.168516 -10.416435
v  2.252020 0.168516 -10.347218
v  3.003836 0.168516 -10.347218
v  2.292504 0.168516 -10.347218
v  2.963353 0.168516 -10.347218
v  2.186458 0.781630 -10.437140
v  3.109883 0.781630 -10.437140
v  2.145974 0.781630 -10.437140
v  3.069399 0.781630 -10.437140
v  3.039678 0.168516 -10.437140
v  2.186458 0.168516 -10.423906
v  2.145974 0.168516 -10.423906
v  3.109883 0.168516 -10.423906
v  3.069399 0.168516 -10.423906
v  2.216179 0.168516 -10.437140
v  3.039678 0.781630 -10.437140
v  2.216179 0.781630 -10.437140
v  2.292504 0.781630 -10.347218
v  3.003836 0.781630 -10.347218
v  2.963353 0.781630 -10.347218
v  2.252020 0.781630 -10.347218
v  2.249745 0.168516 -10.368868
v  2.290228 0.168516 -10.368868
v  3.006112 0.168516 -10.368868
v  2.965629 0.168516 -10.368868
v  3.046405 0.781630 -10.416435
v  2.209452 0.781630 -10.416435
v  2.175695 0.168516 -10.437140
v  3.080161 0.168516 -10.437140
v  2.991374 0.168516 -10.350163
v  3.031858 0.168516 -10.350163
v  2.264483 0.168516 -10.350163
v  2.223999 0.168516 -10.350163
v  2.991374 0.781630 -10.363396
v  2.264483 0.781630 -10.363396
v  3.031858 0.781630 -10.363396
v  2.223999 0.781630 -10.363396
v  3.111289 0.168516 -10.430523
v  3.070806 0.168516 -10.430523
v  2.185051 0.168516 -10.430523
v  2.144567 0.168516 -10.430523
v  2.144567 0.781630 -10.430523
v  3.070806 0.781630 -10.430523
v  3.111289 0.781630 -10.430523
v  2.185051 0.781630 -10.430523
v  2.214478 0.168516 -10.420961
v  3.041378 0.168516 -10.420961
v  2.991374 0.168516 -10.363396
v  2.264483 0.168516 -10.363396
v  3.031858 0.168516 -10.363396
v  2.223999 0.168516 -10.363396
v  3.002136 0.781630 -10.363396
v  3.031858 0.781630 -10.350163
v  2.294204 0.781630 -10.363396
v  2.253721 0.781630 -10.363396
v  2.223999 0.781630 -10.350163
v  2.961653 0.781630 -10.363396
v  3.078755 0.781630 -10.430523
v  2.177102 0.781630 -10.430523
v  2.264483 0.781630 -10.350163
v  2.991374 0.781630 -10.350163
v  3.105907 0.168516 -10.442612
v  3.065423 0.168516 -10.442612
v  2.149950 0.168516 -10.442612
v  2.190434 0.168516 -10.442612
v  3.065423 0.781630 -10.418434
v  2.149950 0.781630 -10.418434
v  3.084137 0.781630 -10.442612
v  2.171720 0.781630 -10.442612
v  3.043654 0.781630 -10.442612
v  2.190434 0.781630 -10.418434
v  3.105907 0.781630 -10.418434
v  2.212203 0.781630 -10.442612
v  2.243886 0.781630 -10.341309
v  2.284370 0.781630 -10.341309
v  3.011970 0.781630 -10.341309
v  2.971487 0.781630 -10.341309
v  3.096723 0.168516 -10.446701
v  2.159134 0.168516 -10.446701
v  2.199618 0.168516 -10.446701
v  3.056239 0.168516 -10.446701
v  3.052838 0.781630 -10.446701
v  2.162535 0.781630 -10.446701
v  3.093322 0.781630 -10.446701
v  2.203019 0.781630 -10.446701
v  2.960602 0.781630 -10.353397
v  2.217230 0.781630 -10.427140
v  3.038627 0.781630 -10.427140
v  2.295255 0.781630 -10.353397
v  2.254771 0.781630 -10.353397
v  3.001086 0.781630 -10.353397
v  3.081862 0.781630 -10.420961
v  2.173995 0.781630 -10.420961
v  2.246994 0.168516 -10.342691
v  2.968380 0.168516 -10.342691
v  2.287477 0.168516 -10.342691
v  3.008863 0.168516 -10.342691
v  2.274317 0.781630 -10.341309
v  2.981540 0.781630 -10.341309
v  3.093322 0.168516 -10.414345
v  3.052838 0.168516 -10.414345
v  2.203018 0.168516 -10.414345
v  2.162535 0.168516 -10.414345
v  2.199618 0.781630 -10.414345
v  2.159134 0.781630 -10.414345
v  3.096723 0.781630 -10.414345
v  3.056239 0.781630 -10.414345
v  2.255127 0.168516 -10.356779
v  2.960246 0.168516 -10.356779
v  2.295610 0.168516 -10.356779
v  2.217585 0.168516 -10.430523
v  3.038271 0.168516 -10.430523
v  3.000730 0.168516 -10.356779
v  2.175695 0.168516 -10.423906
v  3.080161 0.168516 -10.423906
v  3.015296 0.168516 -10.372957
v  2.240560 0.168516 -10.372957
v  2.974813 0.168516 -10.372957
v  2.237159 0.168516 -10.340601
v  3.018697 0.168516 -10.340601
v  2.281044 0.168516 -10.372957
v  2.978214 0.781630 -10.372957
v  3.018697 0.781630 -10.372957
v  2.237159 0.781630 -10.372957
v  2.277643 0.781630 -10.372957
v  2.233833 0.781630 -10.341309
v  3.022024 0.781630 -10.341309
v  2.277643 0.168516 -10.340601
v  2.978214 0.168516 -10.340601
v  3.062672 0.168516 -10.416435
v  2.209452 0.168516 -10.444611
v  2.168968 0.168516 -10.444611
v  3.086889 0.168516 -10.444611
v  3.103156 0.168516 -10.416435
v  2.193185 0.168516 -10.416435
v  2.152701 0.168516 -10.416435
v  3.046405 0.168516 -10.444611
v  2.193185 0.781630 -10.444611
v  3.062672 0.781630 -10.444611
v  3.103156 0.781630 -10.444611
v  2.152701 0.781630 -10.444611
v  3.079111 0.168516 -10.427140
v  2.176746 0.168516 -10.427140
v  2.974813 0.781630 -10.372957
v  3.015296 0.781630 -10.372957
v  2.281044 0.781630 -10.372957
v  2.240560 0.781630 -10.372957
v  2.978214 0.168516 -10.372957
v  3.018697 0.168516 -10.372957
v  2.237159 0.168516 -10.372957
v  2.277643 0.168516 -10.372957
v  3.079111 0.781630 -10.427140
v  2.176746 0.781630 -10.427140
v  3.070450 0.781630 -10.433905
v  2.222948 0.781630 -10.360162
v  3.110934 0.781630 -10.433905
v  2.992425 0.781630 -10.360162
v  2.144923 0.781630 -10.433905
v  2.185407 0.781630 -10.433905
v  2.263432 0.781630 -10.360162
v  3.032909 0.781630 -10.360162
v  2.992425 0.168516 -10.353397
v  2.263432 0.168516 -10.353397
v  2.144923 0.168516 -10.427140
v  3.070450 0.168516 -10.427140
v  3.079111 0.168516 -10.433905
v  2.222948 0.168516 -10.353397
v  2.960602 0.168516 -10.360162
v  2.185407 0.168516 -10.427140
v  2.217230 0.168516 -10.433905
v  2.176746 0.168516 -10.433905
v  3.001086 0.168516 -10.360162
v  2.295255 0.168516 -10.360162
v  3.032909 0.168516 -10.353397
v  3.110934 0.168516 -10.427140
v  3.038627 0.168516 -10.433905
v  2.254771 0.168516 -10.360162
v  2.271210 0.781630 -10.370867
v  2.984647 0.781630 -10.370867
v  3.025131 0.781630 -10.370867
v  2.230726 0.781630 -10.370867
v  3.025131 0.168516 -10.342691
v  2.287477 0.168516 -10.370867
v  2.968380 0.168516 -10.370867
v  2.230726 0.168516 -10.342691
v  2.246994 0.168516 -10.370867
v  3.008863 0.168516 -10.370867
v  2.185407 0.781630 -10.427140
v  2.992425 0.781630 -10.353397
v  3.001086 0.781630 -10.360162
v  2.176746 0.781630 -10.433905
v  2.144923 0.781630 -10.427140
v  2.263432 0.781630 -10.353397
v  3.110934 0.781630 -10.427140
v  2.295255 0.781630 -10.360162
v  2.254771 0.781630 -10.360162
v  3.070450 0.781630 -10.427140
v  3.032909 0.781630 -10.353397
v  3.079111 0.781630 -10.433905
v  2.222948 0.781630 -10.353397
v  2.217230 0.781630 -10.433905
v  2.960602 0.781630 -10.360162
v  3.038627 0.781630 -10.433905
v  2.144923 0.168516 -10.433905
v  2.222948 0.168516 -10.360162
v  2.263432 0.168516 -10.360162
v  3.032909 0.168516 -10.360162
v  3.110934 0.168516 -10.433905
v  2.992425 0.168516 -10.360162
v  3.070450 0.168516 -10.433905
v  2.185407 0.168516 -10.433905
v  2.968380 0.781630 -10.370867
v  2.246994 0.781630 -10.370867
v  2.287477 0.781630 -10.370867
v  3.025131 0.781630 -10.342691
v  3.008863 0.781630 -10.370867
v  2.230726 0.781630 -10.342691
v  2.230726 0.168516 -10.370867
v  2.984647 0.168516 -10.370867
v  3.025131 0.168516 -10.370867
v  2.271210 0.168516 -10.370867
v  3.100049 0.781630 -10.415051
v  2.196291 0.781630 -10.415051
v  2.165861 0.781630 -10.445993
v  3.049512 0.781630 -10.445993
v  2.155808 0.781630 -10.415051
v  3.059565 0.781630 -10.415051
v  3.089995 0.781630 -10.445993
v  2.206345 0.781630 -10.445993
v  2.155808 0.168516 -10.445993
v  2.196291 0.168516 -10.445993
v  3.100049 0.168516 -10.445993
v  3.059565 0.168516 -10.445993
v  2.266183 0.781630 -10.347218
v  2.252020 0.781630 -10.366341
v  3.041378 0.781630 -10.440084
v  3.067699 0.781630 -10.420961
v  3.108183 0.781630 -10.420961
v  3.081862 0.781630 -10.440084
v  2.963353 0.781630 -10.366341
v  3.003836 0.781630 -10.366341
v  2.188158 0.781630 -10.420961
v  2.225699 0.781630 -10.347218
v  2.989674 0.781630 -10.347218
v  2.173995 0.781630 -10.440084
v  2.292504 0.781630 -10.366341
v  2.147674 0.781630 -10.420961
v  2.214478 0.781630 -10.440084
v  3.030157 0.781630 -10.347218
v  3.108183 0.168516 -10.440084
v  2.989674 0.168516 -10.366341
v  3.030157 0.168516 -10.366341
v  2.188158 0.168516 -10.440084
v  2.147674 0.168516 -10.440084
v  2.266183 0.168516 -10.366341
v  3.067699 0.168516 -10.440084
v  2.225699 0.168516 -10.366341
v  2.188158 0.781630 -10.440084
v  3.030157 0.781630 -10.366341
v  2.147674 0.781630 -10.440084
v  2.266183 0.781630 -10.366341
v  2.225699 0.781630 -10.366341
v  3.108183 0.781630 -10.440084
v  3.067699 0.781630 -10.440084
v  2.989674 0.781630 -10.366341
v  2.963353 0.168516 -10.366341
v  2.188158 0.168516 -10.420961
v  3.108183 0.168516 -10.420961
v  3.081862 0.168516 -10.440084
v  2.173995 0.168516 -10.440084
v  2.989674 0.168516 -10.347218
v  2.292504 0.168516 -10.366341
v  3.030157 0.168516 -10.347218
v  2.252020 0.168516 -10.366341
v  3.003836 0.168516 -10.366341
v  3.041378 0.168516 -10.440084
v  2.147674 0.168516 -10.420961
v  2.225699 0.168516 -10.347218
v  2.214478 0.168516 -10.440084
v  2.266183 0.168516 -10.347218
v  3.067699 0.168516 -10.420961
v  2.196291 0.781630 -10.445993
v  3.059565 0.781630 -10.445993
v  3.100049 0.781630 -10.445993
v  2.155808 0.781630 -10.445993
v  3.049512 0.168516 -10.445993
v  2.196291 0.168516 -10.415051
v  3.059565 0.168516 -10.415051
v  2.206345 0.168516 -10.445993
v  3.100049 0.168516 -10.415051
v  2.165861 0.168516 -10.445993
v  3.089995 0.168516 -10.445993
v  2.155808 0.168516 -10.415051
v  2.984647 0.781630 -10.342691
v  2.271210 0.781630 -10.342691
v  2.271210 0.168516 -10.342691
v  2.984647 0.168516 -10.342691
v  3.039678 0.168516 -10.423906
v  2.216179 0.168516 -10.423906
v  2.294204 0.168516 -10.350163
v  2.961653 0.168516 -10.350163
v  3.002136 0.168516 -10.350163
v  2.253721 0.168516 -10.350163
v  2.294204 0.781630 -10.350163
v  3.002136 0.781630 -10.350163
v  2.961653 0.781630 -10.350163
v  3.039678 0.781630 -10.423906
v  2.216179 0.781630 -10.423906
v  2.253721 0.781630 -10.350163
v  3.015296 0.781630 -10.340601
v  2.281044 0.781630 -10.340601
v  2.240560 0.781630 -10.340601
v  2.974813 0.781630 -10.340601
v  3.015296 0.168516 -10.340601
v  2.974813 0.168516 -10.340601
v  2.281044 0.168516 -10.340601
v  2.240560 0.168516 -10.340601
v  3.089995 0.781630 -10.415051
v  3.049512 0.781630 -10.415051
v  2.165861 0.781630 -10.415051
v  2.206345 0.781630 -10.415051
v  2.165861 0.168516 -10.415051
v  3.049512 0.168516 -10.415051
v  2.206345 0.168516 -10.415051
v  3.089995 0.168516 -10.415051
v  2.171720 0.168516 -10.418434
v  3.084137 0.168516 -10.418434
v  3.043654 0.168516 -10.418434
v  2.965629 0.168516 -10.344690
v  2.249745 0.168516 -10.344690
v  2.212203 0.168516 -10.418434
v  2.290228 0.168516 -10.344690
v  3.006112 0.168516 -10.344690
v  3.084137 0.781630 -10.418434
v  2.212203 0.781630 -10.418434
v  2.965629 0.781630 -10.344690
v  2.171720 0.781630 -10.418434
v  2.290228 0.781630 -10.344690
v  2.249745 0.781630 -10.344690
v  3.043654 0.781630 -10.418434
v  3.006112 0.781630 -10.344690
v  2.627930 1.025923 -10.431230
v  2.257812 1.027680 -10.313342
v  2.998633 1.027680 -10.313342
v  2.139392 1.027680 -10.432781
v  3.117053 1.027680 -10.432781
v  -1.584607 0.169258 -10.385433
v  -1.655921 0.169258 -10.385433
v  -1.655921 -0.082123 -10.385433
v  -1.584607 -0.082123 -10.385433
v  -1.584607 0.169258 -10.432781
v  -1.584607 -0.082123 -10.432781
v  -1.655921 0.169258 -10.480129
v  -1.584607 0.169258 -10.480129
v  -1.584607 -0.082123 -10.480129
v  -1.655921 -0.082123 -10.480129
v  -1.749827 0.169258 -10.313342
v  -1.749827 0.169258 -10.385491
v  -1.749827 -0.082123 -10.385491
v  -1.749827 -0.082123 -10.313342
v  -1.703027 0.169258 -10.313342
v  -1.703027 -0.082123 -10.313342
v  -1.656228 0.169258 -10.385491
v  -1.656228 0.169258 -10.313342
v  -1.656228 -0.082123 -10.313342
v  -1.656228 -0.082123 -10.385491
v  -2.562268 0.169258 -10.385433
v  -2.490955 0.169258 -10.385433
v  -2.490955 -0.082123 -10.385433
v  -2.562268 -0.082123 -10.385433
v  -2.562268 0.169258 -10.432781
v  -2.562268 -0.082123 -10.432781
v  -2.490955 0.169258 -10.480129
v  -2.562268 0.169258 -10.480129
v  -2.562268 -0.082123 -10.480129
v  -2.490955 -0.082123 -10.480129
v  -2.397049 0.169258 -10.313342
v  -2.397049 0.169258 -10.385491
v  -2.397049 -0.082123 -10.385491
v  -2.397049 -0.082123 -10.313342
v  -2.443848 0.169258 -10.313342
v  -2.443848 -0.082123 -10.313342
v  -2.490648 0.169258 -10.385491
v  -2.490648 0.169258 -10.313342
v  -2.490648 -0.082123 -10.313342
v  -2.490648 -0.082123 -10.385491
v  -2.490955 1.286014 -10.385433
v  -2.562268 1.286014 -10.385433
v  -2.562268 1.286014 -10.432781
v  -2.562268 1.286014 -10.480129
v  -2.490955 1.286014 -10.480129
v  -2.443848 1.286014 -10.431128
v  -2.397049 1.286014 -10.385491
v  -2.397049 1.286014 -10.313342
v  -2.443848 1.286014 -10.313342
v  -2.490648 1.286014 -10.313342
v  -2.490648 1.286014 -10.385491
v  -2.562268 1.027680 -10.385433
v  -2.490955 1.027680 -10.385433
v  -2.490955 0.776299 -10.385433
v  -2.562268 0.776299 -10.385433
v  -2.562268 0.776299 -10.432781
v  -2.490955 1.027680 -10.480129
v  -2.562268 1.027680 -10.480129
v  -2.562268 0.776299 -10.480129
v  -2.490955 0.776299 -10.480129
v  -2.397049 1.027680 -10.313342
v  -2.397049 1.027680 -10.385491
v  -2.397049 0.776299 -10.385491
v  -2.397049 0.776299 -10.313342
v  -2.443848 0.776299 -10.313342
v  -2.490648 1.027680 -10.385491
v  -2.490648 1.027680 -10.313342
v  -2.490648 0.776299 -10.313342
v  -2.490648 0.776299 -10.385491
v  -1.655921 1.286014 -10.385433
v  -1.584607 1.286014 -10.385433
v  -1.584607 1.286014 -10.432781
v  -1.584607 1.286014 -10.480129
v  -1.655921 1.286014 -10.480129
v  -1.703027 1.286014 -10.431128
v  -1.749827 1.286014 -10.385491
v  -1.749827 1.286014 -10.313342
v  -1.703027 1.286014 -10.313342
v  -1.656228 1.286014 -10.313342
v  -1.656228 1.286014 -10.385491
v  -1.584607 1.027680 -10.385433
v  -1.655921 1.027680 -10.385433
v  -1.655921 0.776299 -10.385433
v  -1.584607 0.776299 -10.385433
v  -1.584607 0.776299 -10.432781
v  -1.655921 1.027680 -10.480129
v  -1.584607 1.027680 -10.480129
v  -1.584607 0.776299 -10.480129
v  -1.655921 0.776299 -10.480129
v  -1.749827 1.027680 -10.313342
v  -1.749827 1.027680 -10.385491
v  -1.749827 0.776299 -10.385491
v  -1.749827 0.776299 -10.313342
v  -1.703027 0.776299 -10.313342
v  -1.656228 1.027680 -10.385491
v  -1.656228 1.027680 -10.313342
v  -1.656228 0.776299 -10.313342
v  -1.656228 0.776299 -10.385491
v  -2.562268 1.417913 -10.432781
v  -1.584607 1.417913 -10.432781
v  -1.703027 1.417913 -10.313342
v  -2.443848 1.417913 -10.313342
v  -2.475667 1.002087 -10.431230
v  -1.671794 1.002087 -10.431230
v  -2.475667 1.002087 -11.246701
v  -1.671794 1.002087 -11.246701
v  -1.671794 1.002087 -11.274077
v  -1.671794 1.002087 -10.431230
v  -1.671794 -0.075318 -11.274077
v  -1.671794 -0.075318 -10.431230
v  -2.475667 1.002087 -10.431230
v  -2.475667 1.002087 -11.261898
v  -2.475667 -0.075318 -10.431230
v  -2.475667 -0.075318 -11.261898
v  -2.073730 1.376718 -11.246701
v  -2.073730 1.376718 -10.431230
v  -2.073730 1.700937 -10.431230
v  -2.475667 0.515974 -10.431230
v  -2.051706 1.040515 -10.431230
v  -1.671794 0.566669 -10.431230
v  -2.475667 1.002087 -10.431230
v  -1.671794 1.002087 -10.431230
v  -2.539125 0.168516 -10.446701
v  -2.527665 0.168516 -10.420961
v  -2.551711 0.781630 -10.442612
v  -1.608339 0.168516 -10.446701
v  -1.595753 0.781630 -10.442612
v  -1.619799 0.168516 -10.420961
v  -1.621499 0.781630 -10.423906
v  -2.525965 0.781630 -10.423906
v  -1.714262 0.168516 -10.368868
v  -1.714262 0.781630 -10.344690
v  -1.741059 0.168516 -10.353397
v  -2.433202 0.168516 -10.368868
v  -2.406405 0.168516 -10.353397
v  -2.433202 0.781630 -10.344690
v  -1.673779 0.168516 -10.368868
v  -1.673779 0.781630 -10.344690
v  -1.700575 0.168516 -10.353397
v  -2.473686 0.168516 -10.368868
v  -2.446889 0.168516 -10.353397
v  -2.473686 0.781630 -10.344690
v  -1.655255 0.168516 -10.416435
v  -1.638988 0.781630 -10.416435
v  -1.638988 0.168516 -10.444611
v  -2.492209 0.168516 -10.416435
v  -2.508476 0.168516 -10.444611
v  -2.508476 0.781630 -10.416435
v  -2.411432 0.781630 -10.368868
v  -1.736032 0.781630 -10.368868
v  -2.451916 0.781630 -10.368868
v  -1.695548 0.781630 -10.368868
v  -1.636237 0.781630 -10.442612
v  -2.511227 0.781630 -10.442612
v  -2.443894 1.417913 -10.431954
v  -1.702981 1.417913 -10.431954
v  -1.663389 0.781630 -10.430523
v  -2.484075 0.781630 -10.430523
v  -2.427344 0.168516 -10.341309
v  -1.720120 0.168516 -10.341309
v  -2.467828 0.168516 -10.341309
v  -1.679637 0.168516 -10.341309
v  -2.397050 1.286014 -10.480129
v  -1.749826 1.286014 -10.480129
v  -2.551711 0.168516 -10.418434
v  -1.595753 0.168516 -10.418434
v  -2.555686 0.781630 -10.423906
v  -1.591777 0.781630 -10.423906
v  -1.692797 0.781630 -10.342691
v  -2.454667 0.781630 -10.342691
v  -1.733281 0.781630 -10.342691
v  -2.414183 0.781630 -10.342691
v  -2.386800 0.736612 -10.431230
v  -2.490648 1.286014 -10.431128
v  -1.656228 1.286014 -10.431128
v  -1.658007 0.168516 -10.442612
v  -2.489457 0.168516 -10.442612
v  -2.511227 0.168516 -10.418434
v  -1.636237 0.168516 -10.418434
v  -1.591777 0.168516 -10.437140
v  -2.555686 0.168516 -10.437140
v  -1.730174 0.168516 -10.372251
v  -2.457774 0.168516 -10.372251
v  -1.689690 0.168516 -10.372251
v  -2.417290 0.168516 -10.372251
v  -1.720120 0.781630 -10.372251
v  -1.679637 0.781630 -10.372251
v  -2.427344 0.781630 -10.372251
v  -2.467828 0.781630 -10.372251
v  -2.443940 1.286014 -10.385433
v  -1.702935 1.286014 -10.385433
v  -2.532692 0.781630 -10.444611
v  -2.492209 0.781630 -10.444611
v  -1.614772 0.781630 -10.444611
v  -1.655255 0.781630 -10.444611
v  -1.728344 0.715755 -10.431230
v  -2.539125 0.781630 -10.414345
v  -1.608338 0.781630 -10.414345
v  -2.438584 0.781630 -10.356779
v  -1.708880 0.781630 -10.356779
v  -1.668396 0.781630 -10.356779
v  -2.479068 0.781630 -10.356779
v  -2.479068 0.168516 -10.356779
v  -2.438584 0.168516 -10.356779
v  -1.668396 0.168516 -10.356779
v  -1.708880 0.168516 -10.356779
v  -1.632261 0.168516 -10.437140
v  -2.515203 0.168516 -10.437140
v  -2.515203 0.781630 -10.423906
v  -1.632261 0.781630 -10.423906
v  -2.484431 0.168516 -10.427140
v  -1.663033 0.168516 -10.427140
v  -2.446534 0.781630 -10.356779
v  -1.741414 0.781630 -10.356779
v  -1.700931 0.781630 -10.356779
v  -2.406050 0.781630 -10.356779
v  -1.622905 0.168516 -10.430523
v  -2.524559 0.168516 -10.430523
v  -2.487182 0.781630 -10.420961
v  -1.660282 0.781630 -10.420961
v  -2.314087 0.833923 -10.431230
v  -1.795012 0.814961 -10.431230
v  -2.446877 0.614921 -10.431230
v  -1.699524 0.168516 -10.363396
v  -2.407456 0.168516 -10.363396
v  -2.447940 0.168516 -10.363396
v  -1.740008 0.168516 -10.363396
v  -2.525965 0.781630 -10.437140
v  -1.621499 0.781630 -10.437140
v  -2.417290 0.168516 -10.341309
v  -1.730174 0.168516 -10.341309
v  -2.457774 0.168516 -10.341309
v  -1.689690 0.168516 -10.341309
v  -1.604938 0.168516 -10.414345
v  -2.542526 0.168516 -10.414345
v  -2.231891 0.912945 -10.431230
v  -1.873828 0.898725 -10.431230
v  -2.502043 0.168516 -10.414345
v  -1.645422 0.168516 -10.414345
v  -1.598505 0.781630 -10.416435
v  -2.548959 0.781630 -10.416435
v  -1.598505 0.168516 -10.444611
v  -2.548959 0.168516 -10.444611
v  -1.723447 0.781630 -10.340601
v  -2.424017 0.781630 -10.340601
v  -2.464501 0.781630 -10.340601
v  -1.682963 0.781630 -10.340601
v  -2.542526 0.781630 -10.446701
v  -1.604938 0.781630 -10.446701
v  -1.645422 0.781630 -10.446701
v  -2.502043 0.781630 -10.446701
v  -1.648822 0.168516 -10.446701
v  -2.498642 0.168516 -10.446701
v  -1.648822 0.781630 -10.414345
v  -2.498642 0.781630 -10.414345
v  -2.529941 0.168516 -10.442612
v  -1.617523 0.168516 -10.442612
v  -2.143378 0.979778 -10.431230
v  -1.960741 0.972193 -10.431230
v  -2.417290 0.781630 -10.372251
v  -2.457774 0.781630 -10.372251
v  -1.730174 0.781630 -10.372251
v  -1.689690 0.781630 -10.372251
v  -1.679637 0.168516 -10.372251
v  -2.467828 0.168516 -10.372251
v  -1.720120 0.168516 -10.372251
v  -2.427344 0.168516 -10.372251
v  -1.677876 0.595960 -10.431230
v  -1.673779 0.781630 -10.368868
v  -2.473686 0.781630 -10.368868
v  -2.433202 0.781630 -10.368868
v  -1.714262 0.781630 -10.368868
v  -1.673779 0.168516 -10.344690
v  -2.473686 0.168516 -10.344690
v  -1.614772 0.781630 -10.416435
v  -2.532692 0.781630 -10.416435
v  -2.433202 0.168516 -10.344690
v  -1.714262 0.168516 -10.344690
v  -2.532692 0.168516 -10.416435
v  -1.614772 0.168516 -10.416435
v  -2.449640 0.168516 -10.347218
v  -1.697824 0.168516 -10.347218
v  -2.409157 0.168516 -10.347218
v  -1.738307 0.168516 -10.347218
v  -2.515203 0.781630 -10.437140
v  -1.591777 0.781630 -10.437140
v  -2.555686 0.781630 -10.437140
v  -1.632261 0.781630 -10.437140
v  -1.661983 0.168516 -10.437140
v  -2.515203 0.168516 -10.423906
v  -2.555686 0.168516 -10.423906
v  -1.591777 0.168516 -10.423906
v  -1.632261 0.168516 -10.423906
v  -2.485482 0.168516 -10.437140
v  -1.661983 0.781630 -10.437140
v  -2.485482 0.781630 -10.437140
v  -2.409157 0.781630 -10.347218
v  -1.697824 0.781630 -10.347218
v  -1.738307 0.781630 -10.347218
v  -2.449640 0.781630 -10.347218
v  -2.451916 0.168516 -10.368868
v  -2.411432 0.168516 -10.368868
v  -1.695548 0.168516 -10.368868
v  -1.736032 0.168516 -10.368868
v  -1.655255 0.781630 -10.416435
v  -2.492209 0.781630 -10.416435
v  -2.525965 0.168516 -10.437140
v  -1.621499 0.168516 -10.437140
v  -1.710286 0.168516 -10.350163
v  -1.669803 0.168516 -10.350163
v  -2.437178 0.168516 -10.350163
v  -2.477661 0.168516 -10.350163
v  -1.710286 0.781630 -10.363396
v  -2.437178 0.781630 -10.363396
v  -1.669803 0.781630 -10.363396
v  -2.477661 0.781630 -10.363396
v  -1.590371 0.168516 -10.430523
v  -1.630855 0.168516 -10.430523
v  -2.516609 0.168516 -10.430523
v  -2.557093 0.168516 -10.430523
v  -2.557093 0.781630 -10.430523
v  -1.630855 0.781630 -10.430523
v  -1.590371 0.781630 -10.430523
v  -2.516609 0.781630 -10.430523
v  -2.487182 0.168516 -10.420961
v  -1.660282 0.168516 -10.420961
v  -1.710286 0.168516 -10.363396
v  -2.437178 0.168516 -10.363396
v  -1.669803 0.168516 -10.363396
v  -2.477661 0.168516 -10.363396
v  -1.699524 0.781630 -10.363396
v  -1.669803 0.781630 -10.350163
v  -2.407456 0.781630 -10.363396
v  -2.447940 0.781630 -10.363396
v  -2.477661 0.781630 -10.350163
v  -1.740008 0.781630 -10.363396
v  -1.622905 0.781630 -10.430523
v  -2.524559 0.781630 -10.430523
v  -2.437178 0.781630 -10.350163
v  -1.710286 0.781630 -10.350163
v  -1.595753 0.168516 -10.442612
v  -1.636237 0.168516 -10.442612
v  -2.551711 0.168516 -10.442612
v  -2.511227 0.168516 -10.442612
v  -1.636237 0.781630 -10.418434
v  -2.551711 0.781630 -10.418434
v  -1.617523 0.781630 -10.442612
v  -2.529941 0.781630 -10.442612
v  -1.658007 0.781630 -10.442612
v  -2.511227 0.781630 -10.418434
v  -1.595753 0.781630 -10.418434
v  -2.489457 0.781630 -10.442612
v  -2.457774 0.781630 -10.341309
v  -2.417290 0.781630 -10.341309
v  -1.689690 0.781630 -10.341309
v  -1.730174 0.781630 -10.341309
v  -1.604938 0.168516 -10.446701
v  -2.542526 0.168516 -10.446701
v  -2.502043 0.168516 -10.446701
v  -1.645422 0.168516 -10.446701
v  -1.648822 0.781630 -10.446701
v  -2.539125 0.781630 -10.446701
v  -1.608339 0.781630 -10.446701
v  -2.498642 0.781630 -10.446701
v  -1.741059 0.781630 -10.353397
v  -2.484431 0.781630 -10.427140
v  -1.663033 0.781630 -10.427140
v  -2.406405 0.781630 -10.353397
v  -2.446889 0.781630 -10.353397
v  -1.700575 0.781630 -10.353397
v  -1.619799 0.781630 -10.420961
v  -2.527665 0.781630 -10.420961
v  -2.454667 0.168516 -10.342691
v  -1.733281 0.168516 -10.342691
v  -2.414183 0.168516 -10.342691
v  -1.692797 0.168516 -10.342691
v  -2.427344 0.781630 -10.341309
v  -1.720120 0.781630 -10.341309
v  -1.608338 0.168516 -10.414345
v  -1.648822 0.168516 -10.414345
v  -2.498642 0.168516 -10.414345
v  -2.539125 0.168516 -10.414345
v  -2.502043 0.781630 -10.414345
v  -2.542526 0.781630 -10.414345
v  -1.604938 0.781630 -10.414345
v  -1.645422 0.781630 -10.414345
v  -2.446534 0.168516 -10.356779
v  -1.741414 0.168516 -10.356779
v  -2.406050 0.168516 -10.356779
v  -2.484075 0.168516 -10.430523
v  -1.663389 0.168516 -10.430523
v  -1.700931 0.168516 -10.356779
v  -2.525965 0.168516 -10.423906
v  -1.621499 0.168516 -10.423906
v  -1.686364 0.168516 -10.372957
v  -2.461100 0.168516 -10.372957
v  -1.726847 0.168516 -10.372957
v  -2.464501 0.168516 -10.340601
v  -1.682963 0.168516 -10.340601
v  -2.420617 0.168516 -10.372957
v  -1.723447 0.781630 -10.372957
v  -1.682963 0.781630 -10.372957
v  -2.464501 0.781630 -10.372957
v  -2.424017 0.781630 -10.372957
v  -2.467828 0.781630 -10.341309
v  -1.679637 0.781630 -10.341309
v  -2.424017 0.168516 -10.340601
v  -1.723447 0.168516 -10.340601
v  -1.638988 0.168516 -10.416435
v  -2.492209 0.168516 -10.444611
v  -2.532692 0.168516 -10.444611
v  -1.614772 0.168516 -10.444611
v  -1.598505 0.168516 -10.416435
v  -2.508476 0.168516 -10.416435
v  -2.548959 0.168516 -10.416435
v  -1.655255 0.168516 -10.444611
v  -2.508476 0.781630 -10.444611
v  -1.638988 0.781630 -10.444611
v  -1.598505 0.781630 -10.444611
v  -2.548959 0.781630 -10.444611
v  -1.622550 0.168516 -10.427140
v  -2.524914 0.168516 -10.427140
v  -1.726847 0.781630 -10.372957
v  -1.686364 0.781630 -10.372957
v  -2.420617 0.781630 -10.372957
v  -2.461100 0.781630 -10.372957
v  -1.723447 0.168516 -10.372957
v  -1.682963 0.168516 -10.372957
v  -2.464501 0.168516 -10.372957
v  -2.424017 0.168516 -10.372957
v  -1.622550 0.781630 -10.427140
v  -2.524914 0.781630 -10.427140
v  -1.631210 0.781630 -10.433905
v  -2.478712 0.781630 -10.360162
v  -1.590727 0.781630 -10.433905
v  -1.709235 0.781630 -10.360162
v  -2.556737 0.781630 -10.433905
v  -2.516254 0.781630 -10.433905
v  -2.438229 0.781630 -10.360162
v  -1.668752 0.781630 -10.360162
v  -1.709235 0.168516 -10.353397
v  -2.438229 0.168516 -10.353397
v  -2.556737 0.168516 -10.427140
v  -1.631210 0.168516 -10.427140
v  -1.622550 0.168516 -10.433905
v  -2.478712 0.168516 -10.353397
v  -1.741059 0.168516 -10.360162
v  -2.516254 0.168516 -10.427140
v  -2.484431 0.168516 -10.433905
v  -2.524914 0.168516 -10.433905
v  -1.700575 0.168516 -10.360162
v  -2.406405 0.168516 -10.360162
v  -1.668752 0.168516 -10.353397
v  -1.590727 0.168516 -10.427140
v  -1.663033 0.168516 -10.433905
v  -2.446889 0.168516 -10.360162
v  -2.430450 0.781630 -10.370867
v  -1.717014 0.781630 -10.370867
v  -1.676530 0.781630 -10.370867
v  -2.470934 0.781630 -10.370867
v  -1.676530 0.168516 -10.342691
v  -2.414183 0.168516 -10.370867
v  -1.733281 0.168516 -10.370867
v  -2.470934 0.168516 -10.342691
v  -2.454667 0.168516 -10.370867
v  -1.692797 0.168516 -10.370867
v  -2.516254 0.781630 -10.427140
v  -1.709235 0.781630 -10.353397
v  -1.700575 0.781630 -10.360162
v  -2.524914 0.781630 -10.433905
v  -2.556737 0.781630 -10.427140
v  -2.438229 0.781630 -10.353397
v  -1.590727 0.781630 -10.427140
v  -2.406405 0.781630 -10.360162
v  -2.446889 0.781630 -10.360162
v  -1.631210 0.781630 -10.427140
v  -1.668752 0.781630 -10.353397
v  -1.622550 0.781630 -10.433905
v  -2.478712 0.781630 -10.353397
v  -2.484431 0.781630 -10.433905
v  -1.741059 0.781630 -10.360162
v  -1.663033 0.781630 -10.433905
v  -2.556737 0.168516 -10.433905
v  -2.478712 0.168516 -10.360162
v  -2.438229 0.168516 -10.360162
v  -1.668752 0.168516 -10.360162
v  -1.590727 0.168516 -10.433905
v  -1.709235 0.168516 -10.360162
v  -1.631210 0.168516 -10.433905
v  -2.516254 0.168516 -10.433905
v  -1.733281 0.781630 -10.370867
v  -2.454667 0.781630 -10.370867
v  -2.414183 0.781630 -10.370867
v  -1.676530 0.781630 -10.342691
v  -1.692797 0.781630 -10.370867
v  -2.470934 0.781630 -10.342691
v  -2.470934 0.168516 -10.370867
v  -1.717014 0.168516 -10.370867
v  -1.676530 0.168516 -10.370867
v  -2.430450 0.168516 -10.370867
v  -1.601611 0.781630 -10.415051
v  -2.505369 0.781630 -10.415051
v  -2.535799 0.781630 -10.445993
v  -1.652149 0.781630 -10.445993
v  -2.545853 0.781630 -10.415051
v  -1.642095 0.781630 -10.415051
v  -1.611665 0.781630 -10.445993
v  -2.495316 0.781630 -10.445993
v  -2.545853 0.168516 -10.445993
v  -2.505369 0.168516 -10.445993
v  -1.601611 0.168516 -10.445993
v  -1.642095 0.168516 -10.445993
v  -2.435477 0.781630 -10.347218
v  -2.449640 0.781630 -10.366341
v  -1.660282 0.781630 -10.440084
v  -1.633961 0.781630 -10.420961
v  -1.593478 0.781630 -10.420961
v  -1.619799 0.781630 -10.440084
v  -1.738307 0.781630 -10.366341
v  -1.697824 0.781630 -10.366341
v  -2.513503 0.781630 -10.420961
v  -2.475961 0.781630 -10.347218
v  -1.711987 0.781630 -10.347218
v  -2.527665 0.781630 -10.440084
v  -2.409157 0.781630 -10.366341
v  -2.553986 0.781630 -10.420961
v  -2.487182 0.781630 -10.440084
v  -1.671503 0.781630 -10.347218
v  -1.593478 0.168516 -10.440084
v  -1.711987 0.168516 -10.366341
v  -1.671503 0.168516 -10.366341
v  -2.513503 0.168516 -10.440084
v  -2.553986 0.168516 -10.440084
v  -2.435477 0.168516 -10.366341
v  -1.633961 0.168516 -10.440084
v  -2.475961 0.168516 -10.366341
v  -2.513503 0.781630 -10.440084
v  -1.671503 0.781630 -10.366341
v  -2.553986 0.781630 -10.440084
v  -2.435477 0.781630 -10.366341
v  -2.475961 0.781630 -10.366341
v  -1.593478 0.781630 -10.440084
v  -1.633961 0.781630 -10.440084
v  -1.711987 0.781630 -10.366341
v  -1.738307 0.168516 -10.366341
v  -2.513503 0.168516 -10.420961
v  -1.593478 0.168516 -10.420961
v  -1.619799 0.168516 -10.440084
v  -2.527665 0.168516 -10.440084
v  -1.711987 0.168516 -10.347218
v  -2.409157 0.168516 -10.366341
v  -1.671503 0.168516 -10.347218
v  -2.449640 0.168516 -10.366341
v  -1.697824 0.168516 -10.366341
v  -1.660282 0.168516 -10.440084
v  -2.553986 0.168516 -10.420961
v  -2.475961 0.168516 -10.347218
v  -2.487182 0.168516 -10.440084
v  -2.435477 0.168516 -10.347218
v  -1.633961 0.168516 -10.420961
v  -2.505369 0.781630 -10.445993
v  -1.642095 0.781630 -10.445993
v  -1.601611 0.781630 -10.445993
v  -2.545853 0.781630 -10.445993
v  -1.652149 0.168516 -10.445993
v  -2.505369 0.168516 -10.415051
v  -1.642095 0.168516 -10.415051
v  -2.495316 0.168516 -10.445993
v  -1.601611 0.168516 -10.415051
v  -2.535799 0.168516 -10.445993
v  -1.611665 0.168516 -10.445993
v  -2.545853 0.168516 -10.415051
v  -1.717014 0.781630 -10.342691
v  -2.430450 0.781630 -10.342691
v  -2.430450 0.168516 -10.342691
v  -1.717014 0.168516 -10.342691
v  -1.661983 0.168516 -10.423906
v  -2.485482 0.168516 -10.423906
v  -2.407456 0.168516 -10.350163
v  -1.740008 0.168516 -10.350163
v  -1.699524 0.168516 -10.350163
v  -2.447940 0.168516 -10.350163
v  -2.407456 0.781630 -10.350163
v  -1.699524 0.781630 -10.350163
v  -1.740008 0.781630 -10.350163
v  -1.661983 0.781630 -10.423906
v  -2.485482 0.781630 -10.423906
v  -2.447940 0.781630 -10.350163
v  -1.686364 0.781630 -10.340601
v  -2.420617 0.781630 -10.340601
v  -2.461100 0.781630 -10.340601
v  -1.726847 0.781630 -10.340601
v  -1.686364 0.168516 -10.340601
v  -1.726847 0.168516 -10.340601
v  -2.420617 0.168516 -10.340601
v  -2.461100 0.168516 -10.340601
v  -1.611665 0.781630 -10.415051
v  -1.652149 0.781630 -10.415051
v  -2.535799 0.781630 -10.415051
v  -2.495316 0.781630 -10.415051
v  -2.535799 0.168516 -10.415051
v  -1.652149 0.168516 -10.415051
v  -2.495316 0.168516 -10.415051
v  -1.611665 0.168516 -10.415051
v  -2.529941 0.168516 -10.418434
v  -1.617523 0.168516 -10.418434
v  -1.658007 0.168516 -10.418434
v  -1.736032 0.168516 -10.344690
v  -2.451916 0.168516 -10.344690
v  -2.489457 0.168516 -10.418434
v  -2.411432 0.168516 -10.344690
v  -1.695548 0.168516 -10.344690
v  -1.617523 0.781630 -10.418434
v  -2.489457 0.781630 -10.418434
v  -1.736032 0.781630 -10.344690
v  -2.529941 0.781630 -10.418434
v  -2.411432 0.781630 -10.344690
v  -2.451916 0.781630 -10.344690
v  -1.658007 0.781630 -10.418434
v  -1.695548 0.781630 -10.344690
v  -2.073730 1.025923 -10.431230
v  -2.443848 1.027680 -10.313342
v  -1.703027 1.027680 -10.313342
v  -2.562268 1.027680 -10.432781
v  -1.584607 1.027680 -10.432781
v  -1.553028 3.367838 -11.250783
v  -1.778028 3.367838 -11.250783
v  -1.553028 3.367838 -11.546095
v  -1.778028 3.367838 -11.546095
v  -1.778028 4.896170 -11.250784
v  -1.778028 4.896170 -11.546095
v  -1.778028 3.367838 -11.398438
v  -1.778028 4.896170 -11.398439
v  -1.552549 4.896170 -11.250784
v  -1.552549 4.896170 -11.546095
v  -1.465046 5.230972 -11.398439
v  -1.553028 2.275181 -13.509259
v  -1.778028 2.275181 -13.509259
v  -1.553028 2.275181 -13.804571
v  -1.778028 2.275181 -13.804571
v  -1.778028 4.896169 -13.509260
v  -1.778028 4.896169 -13.804572
v  -1.778028 5.230971 -13.656917
v  -1.778028 2.275181 -13.656915
v  -1.778028 4.896169 -13.656917
v  -1.552549 4.896169 -13.509260
v  -1.552549 4.896169 -13.804572
v  -1.552072 5.230971 -13.656917
v  -1.255630 -0.063106 -11.250147
v  -1.255630 -0.063106 -11.025146
v  -1.550941 -0.063106 -11.250147
v  -1.550941 -0.063106 -11.025146
v  -1.255630 4.896170 -11.025150
v  -1.550941 4.896170 -11.025150
v  -1.403286 -0.063106 -11.025146
v  -1.403286 4.896170 -11.025150
v  -1.255630 4.896170 -11.250628
v  -1.550941 4.896170 -11.250628
v  -1.550941 4.896170 -11.419357
v  -3.403060 -0.063111 -20.532768
v  -3.403060 -0.063111 -20.307768
v  -3.698372 -0.063111 -20.532768
v  -3.698372 -0.063111 -20.307768
v  -3.403060 4.896165 -20.307770
v  -3.698372 4.896165 -20.307770
v  -3.550716 -0.063111 -20.307768
v  -3.550716 4.896165 -20.307770
v  -3.403060 4.896165 -20.533249
v  -3.698372 4.896165 -20.533249
v  -3.550716 5.230967 -20.533726
v  -4.613842 -0.063111 -20.514404
v  -4.838841 -0.063111 -20.514404
v  -4.613842 -0.063111 -20.809715
v  -4.838841 -0.063111 -20.809715
v  -4.613842 4.887782 -20.514408
v  -4.838841 4.887782 -20.514408
v  -4.613842 4.887782 -20.809719
v  -4.838841 4.887782 -20.809719
v  -4.838841 -0.063111 -20.662060
v  -4.838841 4.887782 -20.662064
v  -4.465218 4.887782 -20.656902
v  -4.317562 -0.063111 -20.514584
v  -4.317562 -0.063111 -20.289585
v  -4.612873 -0.063111 -20.514584
v  -4.612873 -0.063111 -20.289585
v  -4.317562 4.887782 -20.514587
v  -4.317562 4.887782 -20.289587
v  -4.612873 4.887782 -20.514587
v  -4.612873 4.887782 -20.289587
v  -4.465218 -0.063111 -20.289585
v  -4.465218 4.887782 -20.289587
v  -3.699175 -0.063106 -11.253781
v  -3.924175 -0.063106 -11.253781
v  -3.699175 -0.063106 -11.549093
v  -3.924175 -0.063106 -11.549093
v  -3.699175 2.951773 -11.253783
v  -3.924175 2.951773 -11.253783
v  -3.699175 2.951773 -11.549095
v  -3.924175 2.951773 -11.549095
v  -3.924175 -0.063106 -11.401438
v  -3.924175 2.951773 -11.401439
v  -3.550551 2.951773 -11.396279
v  -3.402895 -0.063106 -11.253962
v  -3.402895 -0.063106 -11.028961
v  -3.698206 -0.063106 -11.253962
v  -3.698206 -0.063106 -11.028961
v  -3.402895 2.951773 -11.253963
v  -3.402895 2.951773 -11.028963
v  -3.698206 2.951773 -11.253963
v  -3.698206 2.951773 -11.028963
v  -3.550551 -0.063106 -11.028961
v  -3.550551 2.951773 -11.028963
v  -3.699175 -0.063107 -13.509415
v  -3.924175 -0.063107 -13.509415
v  -3.699175 -0.063107 -13.804727
v  -3.924175 -0.063107 -13.804727
v  -3.924175 2.951772 -13.509417
v  -3.924175 2.951771 -13.804729
v  -3.924175 3.286573 -13.657072
v  -3.924175 -0.063107 -13.657070
v  -3.924175 2.951772 -13.657072
v  -3.698697 2.951772 -13.509417
v  -3.698697 2.951771 -13.804729
v  -3.698219 3.286573 -13.657072
v  -3.699175 -0.063108 -15.765620
v  -3.924175 -0.063108 -15.765620
v  -3.699175 -0.063109 -16.060932
v  -3.924175 -0.063109 -16.060932
v  -3.924175 2.951771 -15.765622
v  -3.924175 2.951770 -16.060934
v  -3.924175 3.286572 -15.913279
v  -3.924175 -0.063108 -15.913277
v  -3.924175 2.951770 -15.913278
v  -3.698697 2.951771 -15.765622
v  -3.698697 2.951770 -16.060934
v  -3.698219 3.286572 -15.913279
v  -3.699175 -0.063109 -18.021826
v  -3.924175 -0.063109 -18.021826
v  -3.699175 -0.063110 -18.317137
v  -3.924175 -0.063110 -18.317137
v  -3.924175 2.951769 -18.021828
v  -3.924175 2.951769 -18.317139
v  -3.924175 3.286571 -18.169483
v  -3.924175 -0.063110 -18.169481
v  -3.924175 2.951769 -18.169483
v  -3.698697 2.951769 -18.021828
v  -3.698697 2.951769 -18.317139
v  -3.698219 3.286571 -18.169483
v  -1.553028 2.275180 -15.765465
v  -1.778028 2.275180 -15.765465
v  -1.553028 2.275179 -16.060776
v  -1.778028 2.275179 -16.060776
v  -1.778028 4.896167 -15.765466
v  -1.778028 4.896167 -16.060778
v  -1.778028 5.230969 -15.913122
v  -1.778028 2.275179 -15.913120
v  -1.778028 4.896167 -15.913122
v  -1.552550 4.896167 -15.765466
v  -1.552550 4.896167 -16.060778
v  -1.552072 5.230969 -15.913122
v  -1.553028 2.275179 -18.021669
v  -1.778028 2.275179 -18.021669
v  -1.553028 2.275178 -18.316982
v  -1.778028 2.275178 -18.316982
v  -1.778028 4.896166 -18.021671
v  -1.778028 4.896166 -18.316982
v  -1.778028 5.230968 -18.169327
v  -1.778028 2.275179 -18.169325
v  -1.778028 4.896166 -18.169327
v  -1.552550 4.896166 -18.021671
v  -1.552550 4.896166 -18.316982
v  -1.552072 5.230968 -18.169327
v  -0.781666 4.307630 -20.514406
v  -1.006666 4.307630 -20.514406
v  -1.006666 5.176205 -20.514408
v  -0.781666 5.176205 -20.514408
v  -1.006666 4.307630 -20.662064
v  -1.006666 5.176205 -20.662064
v  -1.006666 4.307630 -20.809719
v  -0.781666 4.307630 -20.809719
v  -0.781666 5.176205 -20.809719
v  -1.006666 5.176205 -20.809719
v  -0.633041 5.176205 -20.656902
v  -0.485386 4.307630 -20.514587
v  -0.485386 4.307631 -20.289587
v  -0.485386 5.176205 -20.289587
v  -0.485386 5.176205 -20.514587
v  -0.633041 4.307631 -20.289587
v  -0.633041 5.176205 -20.289587
v  -0.780697 4.307631 -20.289587
v  -0.780697 4.307630 -20.514587
v  -0.780697 5.176205 -20.514587
v  -0.780697 5.176205 -20.289587
v  -0.553033 5.516199 -20.515938
v  -0.778034 5.516199 -20.515938
v  -0.778034 6.047520 -20.515940
v  -0.552556 6.047520 -20.515940
v  -0.778034 5.516199 -20.663595
v  -0.778034 6.047520 -20.663595
v  -0.778034 5.516199 -20.811251
v  -0.553033 5.516199 -20.811251
v  -0.552556 6.047520 -20.811251
v  -0.778034 6.047520 -20.811251
v  -0.665056 6.382321 -20.663595
v  -4.612990 -0.063113 -23.709541
v  -4.612990 -0.063113 -23.934542
v  -4.612990 4.887780 -23.934544
v  -4.612990 4.887780 -23.709545
v  -4.465333 -0.063113 -23.934542
v  -4.465333 4.887780 -23.934544
v  -4.317678 -0.063113 -23.934542
v  -4.317678 -0.063113 -23.709541
v  -4.317678 4.887780 -23.709541
v  -4.317678 4.887780 -23.934544
v  -4.470493 4.887780 -23.560921
v  -4.612809 -0.063113 -23.413261
v  -4.837810 -0.063113 -23.413261
v  -4.837810 4.887780 -23.413263
v  -4.612809 4.887780 -23.413263
v  -4.837809 -0.063113 -23.560917
v  -4.837809 4.887780 -23.560919
v  -4.837810 -0.063113 -23.708572
v  -4.612809 -0.063113 -23.708572
v  -4.612809 4.887780 -23.708576
v  -4.837810 4.887780 -23.708576
v  -0.802615 4.418269 -23.714794
v  -0.802615 4.418269 -23.939793
v  -0.802615 5.176203 -23.939793
v  -0.802615 5.176203 -23.714794
v  -0.654960 4.418269 -23.939793
v  -0.654961 5.176203 -23.939793
v  -0.507305 4.418269 -23.939793
v  -0.507305 4.418269 -23.714794
v  -0.507305 5.176203 -23.714794
v  -0.507305 5.176203 -23.939793
v  -0.660119 5.176203 -23.566170
v  -0.802436 4.418269 -23.418514
v  -1.027437 5.176203 -23.418514
v  -0.802437 5.176203 -23.418514
v  -1.027437 5.176203 -23.566170
v  -0.802436 4.418269 -23.713825
v  -0.802437 5.176203 -23.713825
v  -1.027437 5.176203 -23.713825
v  -0.801084 5.516197 -23.486164
v  -0.801084 5.516197 -23.711163
v  -0.801084 6.047518 -23.711163
v  -0.801084 6.047518 -23.485685
v  -0.653428 5.516197 -23.711163
v  -0.653428 6.047518 -23.711163
v  -0.505772 5.516197 -23.711163
v  -0.505772 5.516197 -23.486164
v  -0.505772 6.047518 -23.485685
v  -0.505772 6.047518 -23.711163
v  -0.653428 6.382319 -23.598186
v  -0.553033 7.294931 -10.374989
v  -0.778034 7.294931 -10.374989
v  -0.778034 7.826253 -10.374989
v  -0.552556 7.826253 -10.374989
v  -0.778034 7.294931 -10.492597
v  -0.778034 7.826253 -10.492597
v  -0.778034 7.294931 -10.610205
v  -0.553033 7.294931 -10.610205
v  -0.552556 7.826253 -10.610205
v  -0.778034 7.826253 -10.610205
v  -0.665056 8.161055 -10.492597
v  -0.553033 7.294930 -11.971966
v  -0.778034 7.294930 -11.971966
v  -0.778034 7.826252 -11.971966
v  -0.552556 7.826252 -11.971966
v  -0.778034 7.294930 -12.089573
v  -0.778034 7.826252 -12.089573
v  -0.778034 7.294930 -12.207181
v  -0.553033 7.294930 -12.207181
v  -0.552556 7.826252 -12.207181
v  -0.778034 7.826252 -12.207181
v  -0.665056 8.161054 -12.089573
v  -3.699913 2.946154 -13.713334
v  -3.700485 2.661850 -13.713150
v  -3.401350 2.830045 -13.713150
v  -3.069935 2.982141 -13.713150
v  -2.726048 3.114432 -13.713150
v  -2.389499 3.223213 -13.713150
v  -2.080097 3.304775 -13.713150
v  -1.817652 3.355413 -13.713150
v  -1.621972 3.371418 -13.713150
v  -1.621823 3.635551 -13.713334
v  -3.699913 2.946154 -13.595584
v  -3.700485 2.661851 -13.595400
v  -3.401350 2.830045 -13.595400
v  -3.069935 2.982141 -13.595400
v  -2.726048 3.114433 -13.595400
v  -2.389499 3.223213 -13.595400
v  -2.080097 3.304776 -13.595400
v  -1.817652 3.355413 -13.595400
v  -1.621972 3.371419 -13.595400
v  -1.621823 3.635552 -13.595585
v  -3.699913 2.946156 -11.385523
v  -3.700485 2.661852 -11.385340
v  -3.401350 2.830046 -11.385340
v  -3.069935 2.982142 -11.385340
v  -2.726048 3.114434 -11.385340
v  -2.389499 3.223214 -11.385340
v  -2.080097 3.304777 -11.385340
v  -1.817652 3.355414 -11.385340
v  -1.621972 3.371420 -11.385340
v  -1.621823 3.635553 -11.385524
v  -3.699913 2.946156 -11.267773
v  -3.700485 2.661852 -11.267590
v  -3.401350 2.830046 -11.267590
v  -3.069935 2.982142 -11.267590
v  -2.726048 3.114434 -11.267590
v  -2.389499 3.223214 -11.267590
v  -2.080097 3.304777 -11.267590
v  -1.817652 3.355414 -11.267590
v  -1.621972 3.371420 -11.267590
v  -1.621823 3.635553 -11.267775
v  -3.699913 2.946153 -15.974542
v  -3.700485 2.661849 -15.974358
v  -3.401350 2.830043 -15.974358
v  -3.069935 2.982139 -15.974359
v  -2.726048 3.114431 -15.974359
v  -2.389499 3.223212 -15.974359
v  -2.080097 3.304774 -15.974358
v  -1.817652 3.355412 -15.974358
v  -1.621972 3.371417 -15.974359
v  -1.621823 3.635550 -15.974543
v  -3.699913 2.946153 -15.856791
v  -3.700485 2.661849 -15.856607
v  -3.401350 2.830043 -15.856607
v  -3.069935 2.982139 -15.856608
v  -2.726048 3.114431 -15.856608
v  -2.389499 3.223212 -15.856608
v  -2.080097 3.304774 -15.856607
v  -1.817652 3.355412 -15.856607
v  -1.621972 3.371417 -15.856608
v  -1.621823 3.635550 -15.856792
v  -3.699913 2.946152 -18.206099
v  -3.700485 2.661848 -18.205915
v  -3.401350 2.830042 -18.205915
v  -3.069935 2.982138 -18.205915
v  -2.726048 3.114430 -18.205915
v  -2.389499 3.223211 -18.205915
v  -2.080097 3.304773 -18.205915
v  -1.817652 3.355410 -18.205915
v  -1.621972 3.371416 -18.205915
v  -1.621823 3.635549 -18.206100
v  -3.699913 2.946152 -18.088348
v  -3.700485 2.661848 -18.088165
v  -3.401350 2.830042 -18.088165
v  -3.069935 2.982138 -18.088165
v  -2.726048 3.114430 -18.088165
v  -2.389499 3.223211 -18.088165
v  -2.080097 3.304773 -18.088165
v  -1.817652 3.355410 -18.088165
v  -1.621972 3.371416 -18.088165
v  -1.621823 3.635549 -18.088350
v  -3.924175 0.592630 -11.253782
v  -3.924175 1.444333 -11.253782
v  -3.924175 2.296037 -11.253782
v  -3.699175 2.296037 -11.253782
v  -3.699175 1.444333 -11.253782
v  -3.699175 0.592630 -11.253782
v  -3.699175 0.592630 -11.549093
v  -3.699175 1.444333 -11.549094
v  -3.699175 2.296037 -11.549094
v  -3.924175 2.296037 -11.549094
v  -3.924175 1.444333 -11.549094
v  -3.924175 0.592630 -11.549093
v  -3.402895 0.592630 -11.028961
v  -3.402895 1.444334 -11.028962
v  -3.402895 2.296037 -11.028963
v  -3.402895 2.296037 -11.253963
v  -3.402895 1.444333 -11.253963
v  -3.402895 0.592630 -11.253962
v  -3.698206 0.592630 -11.253962
v  -3.698206 1.444333 -11.253962
v  -3.698206 2.296037 -11.253963
v  -3.698206 2.296037 -11.028963
v  -3.698206 1.444334 -11.028962
v  -3.698206 0.592630 -11.028961
v  -1.255630 1.015537 -11.025147
v  -1.255630 2.416532 -11.025148
v  -1.255630 3.817528 -11.025149
v  -1.255630 3.817528 -11.250524
v  -1.255630 2.416532 -11.250387
v  -1.255630 1.015537 -11.250252
v  -1.550941 1.015537 -11.250252
v  -1.550941 2.416532 -11.250387
v  -1.550941 3.817528 -11.250524
v  -1.550941 3.817528 -11.025149
v  -1.550941 2.416532 -11.025148
v  -1.550941 1.015537 -11.025147
v  -3.924175 0.592629 -13.509416
v  -3.924175 1.444332 -13.509416
v  -3.924175 2.296036 -13.509417
v  -3.698801 2.296036 -13.509417
v  -3.698936 1.444332 -13.509416
v  -3.699071 0.592629 -13.509416
v  -3.699071 0.592629 -13.804727
v  -3.698936 1.444332 -13.804728
v  -3.698801 2.296036 -13.804728
v  -3.924175 2.296036 -13.804728
v  -3.924175 1.444332 -13.804728
v  -3.924175 0.592629 -13.804727
v  -3.924175 0.592628 -15.765621
v  -3.924175 1.444331 -15.765621
v  -3.924175 2.296035 -15.765622
v  -3.698801 2.296034 -15.765622
v  -3.698936 1.444331 -15.765621
v  -3.699071 0.592628 -15.765621
v  -3.699071 0.592628 -16.060932
v  -3.698936 1.444331 -16.060932
v  -3.698801 2.296034 -16.060934
v  -3.924175 2.296034 -16.060934
v  -3.924175 1.444331 -16.060932
v  -3.924175 0.592628 -16.060932
v  -3.924175 0.592627 -18.021826
v  -3.924175 1.444330 -18.021826
v  -3.924175 2.296033 -18.021828
v  -3.698801 2.296033 -18.021828
v  -3.698936 1.444330 -18.021826
v  -3.699071 0.592627 -18.021826
v  -3.699071 0.592627 -18.317137
v  -3.698936 1.444330 -18.317139
v  -3.698801 2.296033 -18.317139
v  -3.924175 2.296033 -18.317139
v  -3.924175 1.444330 -18.317139
v  -3.924175 0.592627 -18.317137
v  -1.778028 3.821564 -13.509260
v  -1.552745 3.821564 -13.509260
v  -1.552745 3.821564 -13.804571
v  -1.778028 3.821564 -13.804571
v  -1.778028 3.821563 -15.765466
v  -1.552746 3.821563 -15.765466
v  -1.552746 3.821562 -16.060778
v  -1.778028 3.821562 -16.060778
v  -1.778028 3.821562 -18.021671
v  -1.552746 3.821561 -18.021671
v  -1.552746 3.821562 -18.316982
v  -1.778028 3.821561 -18.316982
v  -1.778028 3.811054 -11.250783
v  -1.552889 3.811054 -11.250783
v  -1.552889 3.811054 -11.546095
v  -1.778028 3.811054 -11.546095
v  -3.403060 2.416527 -20.307770
v  -3.403060 2.416527 -20.533009
v  -3.698372 2.416527 -20.533009
v  -3.698372 2.416527 -20.307770
v  -4.838841 2.412335 -20.514406
v  -4.613842 2.412336 -20.514406
v  -4.613842 2.412335 -20.809717
v  -4.838841 2.412335 -20.809717
v  -4.317562 2.412335 -20.289585
v  -4.317562 2.412336 -20.514585
v  -4.612873 2.412336 -20.514585
v  -4.612873 2.412335 -20.289585
v  -4.612990 2.412334 -23.934544
v  -4.612990 2.412333 -23.709541
v  -4.317678 2.412334 -23.709541
v  -4.317678 2.412333 -23.934544
v  -4.837810 2.412334 -23.413261
v  -4.612809 2.412334 -23.413261
v  -4.612809 2.412334 -23.708574
v  -4.837810 2.412333 -23.708574
v  -4.838841 0.762038 -20.514404
v  -4.838841 1.587186 -20.514404
v  -4.613842 0.762038 -20.809715
v  -4.613842 1.587186 -20.809715
v  -4.317562 0.762038 -20.289585
v  -4.317562 1.587186 -20.289585
v  -4.612873 0.762038 -20.514585
v  -4.612873 1.587187 -20.514584
v  -4.612990 0.762036 -23.934542
v  -4.612990 1.587185 -23.934544
v  -4.317678 0.762036 -23.709541
v  -4.317678 1.587185 -23.709541
v  -4.837810 0.762036 -23.413261
v  -4.837810 1.587185 -23.413261
v  -4.612809 0.762036 -23.708572
v  -4.612809 1.587185 -23.708574
v  -4.613842 1.587187 -20.514404
v  -4.613842 0.762038 -20.514404
v  -4.838841 1.587186 -20.809715
v  -4.838841 0.762038 -20.809715
v  -4.317562 1.587187 -20.514584
v  -4.317562 0.762038 -20.514585
v  -4.612873 1.587187 -20.289585
v  -4.612873 0.762038 -20.289585
v  -4.612990 1.587185 -23.709541
v  -4.612990 0.762036 -23.709541
v  -4.317678 1.587185 -23.934544
v  -4.317678 0.762036 -23.934542
v  -4.612809 1.587185 -23.413261
v  -4.612809 0.762036 -23.413261
v  -4.837810 1.587185 -23.708574
v  -4.837810 0.762036 -23.708572
v  -3.403060 4.069619 -20.533169
v  -3.403060 3.243073 -20.533089
v  -3.698372 4.069619 -20.307770
v  -3.698372 3.243073 -20.307770
v  -4.613842 4.062633 -20.514406
v  -4.613842 3.237484 -20.514406
v  -4.838841 4.062633 -20.809719
v  -4.838841 3.237484 -20.809717
v  -4.317562 4.062633 -20.514585
v  -4.317562 3.237484 -20.514585
v  -4.612873 4.062634 -20.289585
v  -4.612873 3.237484 -20.289585
v  -4.612990 4.062632 -23.709545
v  -4.612990 3.237482 -23.709545
v  -4.317678 4.062632 -23.934544
v  -4.317678 3.237482 -23.934544
v  -4.612809 4.062632 -23.413263
v  -4.612809 3.237483 -23.413263
v  -4.837810 4.062632 -23.708576
v  -4.837810 3.237482 -23.708576
v  -3.403060 3.243073 -20.307770
v  -3.403060 4.069619 -20.307770
v  -3.698372 3.243073 -20.533089
v  -3.698372 4.069619 -20.533169
v  -4.838841 3.237484 -20.514406
v  -4.838841 4.062633 -20.514406
v  -4.613842 3.237484 -20.809717
v  -4.613842 4.062633 -20.809719
v  -4.317562 3.237484 -20.289585
v  -4.317562 4.062634 -20.289585
v  -4.612873 3.237484 -20.514585
v  -4.612873 4.062633 -20.514585
v  -4.612990 3.237482 -23.934544
v  -4.612990 4.062632 -23.934544
v  -4.317678 3.237482 -23.709541
v  -4.317678 4.062632 -23.709541
v  -4.837810 3.237483 -23.413263
v  -4.837810 4.062632 -23.413263
v  -4.612809 3.237482 -23.708576
v  -4.612809 4.062632 -23.708576
v  -1.027436 4.425235 -23.418514
v  -1.027436 4.425235 -23.566170
v  -1.027436 4.418269 -23.713825
v  -4.465333 5.222582 -23.934544
v  -0.654961 5.511005 -23.939795
v  -4.838841 5.222584 -20.662064
v  -1.006666 5.511006 -20.662064
v  -3.924175 3.286575 -11.401440
v  -1.403286 5.230972 -11.025150
v  -1.027437 5.511005 -23.566170
v  -4.837809 5.222582 -23.560919
v  -4.465218 5.222584 -20.289587
v  -0.633041 5.511006 -20.289587
v  -3.550551 3.286575 -11.028963
v  -4.465218 1.587186 -20.289585
v  -3.550716 2.416527 -20.307770
v  -4.465218 4.062634 -20.289585
v  -1.403286 3.817528 -11.025149
v  -4.838841 4.062633 -20.662062
v  -3.924175 2.296036 -13.657072
v  -4.465333 4.062632 -23.934544
v  -3.924175 2.296037 -11.401439
v  -3.550551 2.296037 -11.028963
v  -4.837809 4.062632 -23.560919
v  -4.837809 1.587185 -23.560917
v  -3.924175 2.296033 -18.169483
v  -4.465333 1.587185 -23.934544
v  -4.838841 1.587186 -20.662060
v  -3.924175 2.296034 -15.913278
v  -3.550716 4.069619 -20.307770
v  -1.465523 4.896170 -11.546095
v  -4.467914 5.222582 -23.561066
v  -4.465363 5.222584 -20.659483
v  -0.633187 5.511006 -20.659483
v  -3.550696 3.286575 -11.398859
v  -0.657539 5.511005 -23.566315
v  -1.403286 5.230972 -11.419835
v  -1.778028 5.230972 -11.398439
v  -3.550716 5.230967 -20.307770
v  -4.317677 4.887780 -23.413267
v  -3.402898 2.951773 -11.549095
v  -4.317564 4.887782 -20.809719
v  -0.485389 5.176205 -20.809719
v  -0.507305 5.176203 -23.418518
v  -1.465523 4.896170 -11.250784
v  -1.255630 4.896170 -11.419357
v  -4.470494 4.887780 -23.708574
v  -3.698206 2.951773 -11.396279
v  -0.660119 5.176203 -23.713825
v  -0.780697 5.176205 -20.656902
v  -4.612873 4.887782 -20.656902
v  -4.612990 4.887780 -23.561207
v  -3.550841 2.951773 -11.253783
v  -0.633332 5.176205 -20.514408
v  -4.465508 4.887782 -20.514408
v  -0.802615 5.176203 -23.566460
v  -4.465218 0.762038 -20.289585
v  -4.837809 3.237483 -23.560919
v  -4.837809 2.412334 -23.560917
v  -1.552072 5.230972 -11.398439
v  -1.778028 3.811054 -11.398438
v  -1.403286 1.015537 -11.025147
v  -1.403286 2.416532 -11.025148
v  -1.778028 3.821562 -15.913121
v  -1.778028 3.821562 -18.169327
v  -1.778028 3.821564 -13.656916
v  -4.837809 0.762036 -23.560917
v  -3.924175 0.592627 -18.169481
v  -3.924175 0.592630 -11.401438
v  -3.550551 1.444334 -11.028962
v  -3.550551 0.592630 -11.028961
v  -4.838841 3.237484 -20.662062
v  -4.465218 3.237484 -20.289585
v  -4.838841 2.412335 -20.662060
v  -4.465218 2.412335 -20.289585
v  -4.838841 0.762038 -20.662060
v  -3.924175 1.444330 -18.169481
v  -3.924175 1.444331 -15.913278
v  -3.924175 1.444332 -13.657072
v  -4.465333 3.237482 -23.934544
v  -3.924175 0.592629 -13.657071
v  -4.465333 0.762036 -23.934542
v  -3.924175 1.444333 -11.401438
v  -4.465333 2.412334 -23.934544
v  -3.924175 0.592628 -15.913277
v  -1.403286 5.230972 -11.251106
v  -3.550716 3.243073 -20.307770
v  -5.436832 0.169258 -21.460072
v  -5.436832 0.169258 -21.557159
v  -5.436832 -0.082123 -21.557159
v  -5.436832 -0.082123 -21.460072
v  -5.389484 0.169258 -21.460072
v  -5.389484 -0.082123 -21.460072
v  -5.342135 0.169258 -21.557159
v  -5.342135 0.169258 -21.460072
v  -5.342135 -0.082123 -21.460072
v  -5.342135 -0.082123 -21.557159
v  -5.508923 0.169258 -21.685005
v  -5.436774 0.169258 -21.685005
v  -5.436774 -0.082123 -21.685005
v  -5.508923 -0.082123 -21.685005
v  -5.508923 0.169258 -21.621292
v  -5.508923 -0.082123 -21.621292
v  -5.436774 0.169258 -21.557577
v  -5.508923 0.169258 -21.557577
v  -5.508923 -0.082123 -21.557577
v  -5.436774 -0.082123 -21.557577
v  -5.436831 0.169258 -22.791088
v  -5.436831 0.169258 -22.694000
v  -5.436831 -0.082123 -22.694000
v  -5.436831 -0.082123 -22.791088
v  -5.389483 0.169258 -22.791088
v  -5.389483 -0.082123 -22.791088
v  -5.342135 0.169258 -22.694000
v  -5.342135 0.169258 -22.791088
v  -5.342135 -0.082123 -22.791088
v  -5.342135 -0.082123 -22.694000
v  -5.508923 0.169258 -22.566153
v  -5.436774 0.169258 -22.566153
v  -5.436774 -0.082123 -22.566153
v  -5.508923 -0.082123 -22.566153
v  -5.508923 0.169258 -22.629868
v  -5.508923 -0.082123 -22.629868
v  -5.436774 0.169258 -22.693581
v  -5.508923 0.169258 -22.693581
v  -5.508923 -0.082123 -22.693581
v  -5.436774 -0.082123 -22.693581
v  -5.436831 1.286014 -22.693998
v  -5.436831 1.286014 -22.791088
v  -5.389483 1.286014 -22.791088
v  -5.342135 1.286014 -22.791088
v  -5.342135 1.286014 -22.693998
v  -5.391138 1.286014 -22.629868
v  -5.436774 1.286014 -22.566153
v  -5.508923 1.286014 -22.566153
v  -5.508923 1.286014 -22.629868
v  -5.508923 1.286014 -22.693581
v  -5.436774 1.286014 -22.693581
v  -5.436831 1.027680 -22.791088
v  -5.436831 1.027680 -22.693998
v  -5.436831 0.776299 -22.693998
v  -5.436831 0.776299 -22.791088
v  -5.389483 0.776299 -22.791088
v  -5.342135 1.027680 -22.693998
v  -5.342135 1.027680 -22.791088
v  -5.342135 0.776299 -22.791088
v  -5.342135 0.776299 -22.693998
v  -5.508923 1.027680 -22.566153
v  -5.436774 1.027680 -22.566153
v  -5.436774 0.776299 -22.566153
v  -5.508923 0.776299 -22.566153
v  -5.508923 0.776299 -22.629868
v  -5.436774 1.027680 -22.693581
v  -5.508923 1.027680 -22.693581
v  -5.508923 0.776299 -22.693581
v  -5.436774 0.776299 -22.693581
v  -5.436832 1.286014 -21.557159
v  -5.436832 1.286014 -21.460072
v  -5.389483 1.286014 -21.460072
v  -5.342135 1.286014 -21.460072
v  -5.342135 1.286014 -21.557159
v  -5.391138 1.286014 -21.621292
v  -5.436774 1.286014 -21.685005
v  -5.508923 1.286014 -21.685005
v  -5.508923 1.286014 -21.621292
v  -5.508923 1.286014 -21.557577
v  -5.436774 1.286014 -21.557577
v  -5.436832 1.027680 -21.460072
v  -5.436832 1.027680 -21.557159
v  -5.436832 0.776299 -21.557159
v  -5.436832 0.776299 -21.460072
v  -5.389484 0.776299 -21.460072
v  -5.342135 1.027680 -21.557159
v  -5.342135 1.027680 -21.460072
v  -5.342135 0.776299 -21.460072
v  -5.342135 0.776299 -21.557159
v  -5.508923 1.027680 -21.685005
v  -5.436774 1.027680 -21.685005
v  -5.436774 0.776299 -21.685005
v  -5.508923 0.776299 -21.685005
v  -5.508923 0.776299 -21.621292
v  -5.436774 1.027680 -21.557577
v  -5.508923 1.027680 -21.557577
v  -5.508923 0.776299 -21.557577
v  -5.436774 0.776299 -21.557577
v  -5.389483 1.417913 -22.791088
v  -5.389483 1.417913 -21.460072
v  -5.508923 1.417913 -21.621292
v  -5.508923 1.417913 -22.629868
v  -5.391036 1.002087 -22.673185
v  -5.391036 1.002087 -21.578770
v  -4.575564 1.002087 -22.673185
v  -4.575564 1.002087 -21.578770
v  -4.548188 1.002087 -21.578770
v  -5.391036 1.002087 -21.578770
v  -4.548188 -0.075318 -21.578770
v  -5.391036 -0.075318 -21.578770
v  -5.391036 1.002087 -22.673185
v  -4.560368 1.002087 -22.673185
v  -5.391036 -0.075318 -22.673185
v  -4.560368 -0.075318 -22.673185
v  -4.575564 1.376718 -22.125977
v  -5.391036 1.376718 -22.125977
v  -5.391036 1.700937 -22.125977
v  -5.391036 0.515974 -22.673185
v  -5.391036 1.040515 -22.095993
v  -5.391036 0.566669 -21.578770
v  -5.391036 1.002087 -22.673185
v  -5.391036 1.002087 -21.578770
v  -5.375565 0.168516 -22.759579
v  -5.401304 0.168516 -22.743979
v  -5.379654 0.781630 -22.776714
v  -5.375565 0.168516 -21.492378
v  -5.379654 0.781630 -21.475245
v  -5.401305 0.168516 -21.507980
v  -5.398359 0.781630 -21.510296
v  -5.398359 0.781630 -22.741663
v  -5.453397 0.168516 -21.636585
v  -5.477574 0.781630 -21.636585
v  -5.468868 0.168516 -21.673067
v  -5.453397 0.168516 -22.615372
v  -5.468868 0.168516 -22.578892
v  -5.477574 0.781630 -22.615372
v  -5.453397 0.168516 -21.581470
v  -5.477574 0.781630 -21.581470
v  -5.468868 0.168516 -21.617952
v  -5.453397 0.168516 -22.670488
v  -5.468868 0.168516 -22.634007
v  -5.477574 0.781630 -22.670488
v  -5.405830 0.168516 -21.556253
v  -5.405830 0.781630 -21.534107
v  -5.377655 0.168516 -21.534107
v  -5.405830 0.168516 -22.695705
v  -5.377655 0.168516 -22.717854
v  -5.405830 0.781630 -22.717854
v  -5.453397 0.781630 -22.585735
v  -5.453397 0.781630 -21.666224
v  -5.453397 0.781630 -22.640850
v  -5.453397 0.781630 -21.611109
v  -5.379654 0.781630 -21.530361
v  -5.379654 0.781630 -22.721598
v  -5.390311 1.417913 -22.629929
v  -5.390311 1.417913 -21.621229
v  -5.391743 0.781630 -21.567326
v  -5.391743 0.781630 -22.684633
v  -5.480957 0.168516 -22.607397
v  -5.480957 0.168516 -21.644562
v  -5.480957 0.168516 -22.662514
v  -5.480957 0.168516 -21.589447
v  -5.342135 1.286014 -22.566154
v  -5.342135 1.286014 -21.685003
v  -5.403831 0.168516 -22.776714
v  -5.403832 0.168516 -21.475245
v  -5.398359 0.781630 -22.782127
v  -5.398359 0.781630 -21.469831
v  -5.479574 0.781630 -21.607363
v  -5.479574 0.781630 -22.644596
v  -5.479574 0.781630 -21.662479
v  -5.479574 0.781630 -22.589481
v  -5.391036 0.736612 -22.552200
v  -5.391138 1.286014 -22.693581
v  -5.391138 1.286014 -21.557577
v  -5.379654 0.168516 -21.559998
v  -5.379654 0.168516 -22.691959
v  -5.403831 0.168516 -22.721598
v  -5.403832 0.168516 -21.530361
v  -5.385127 0.168516 -21.469831
v  -5.385127 0.168516 -22.782127
v  -5.450015 0.168516 -21.658249
v  -5.450015 0.168516 -22.648825
v  -5.450015 0.168516 -21.603134
v  -5.450015 0.168516 -22.593710
v  -5.450015 0.781630 -21.644562
v  -5.450015 0.781630 -21.589447
v  -5.450015 0.781630 -22.607397
v  -5.450015 0.781630 -22.662514
v  -5.436831 1.286014 -22.629992
v  -5.436831 1.286014 -21.621166
v  -5.377655 0.781630 -22.750822
v  -5.377655 0.781630 -22.695705
v  -5.377655 0.781630 -21.501137
v  -5.377655 0.781630 -21.556253
v  -5.391036 0.715755 -21.655758
v  -5.407920 0.781630 -22.759579
v  -5.407921 0.781630 -21.492378
v  -5.465486 0.781630 -22.622700
v  -5.465486 0.781630 -21.629259
v  -5.465486 0.781630 -21.574142
v  -5.465486 0.781630 -22.677814
v  -5.465486 0.168516 -22.677814
v  -5.465486 0.168516 -22.622700
v  -5.465486 0.168516 -21.574142
v  -5.465486 0.168516 -21.629259
v  -5.385127 0.168516 -21.524948
v  -5.385127 0.168516 -22.727011
v  -5.398359 0.781630 -22.727011
v  -5.398359 0.781630 -21.524948
v  -5.395125 0.168516 -22.685118
v  -5.395125 0.168516 -21.566841
v  -5.465486 0.781630 -22.633522
v  -5.465486 0.781630 -21.673552
v  -5.465486 0.781630 -21.618435
v  -5.465486 0.781630 -22.578407
v  -5.391743 0.168516 -21.512211
v  -5.391743 0.168516 -22.739748
v  -5.401304 0.781630 -22.688862
v  -5.401305 0.781630 -21.563097
v  -5.391036 0.833923 -22.453205
v  -5.391036 0.814961 -21.746521
v  -5.391036 0.614921 -22.633989
v  -5.458869 0.168516 -21.616522
v  -5.458869 0.168516 -22.580322
v  -5.458869 0.168516 -22.635437
v  -5.458869 0.168516 -21.671637
v  -5.385127 0.781630 -22.741663
v  -5.385127 0.781630 -21.510296
v  -5.480957 0.168516 -22.593710
v  -5.480957 0.168516 -21.658249
v  -5.480957 0.168516 -22.648825
v  -5.480957 0.168516 -21.603134
v  -5.407921 0.168516 -21.487749
v  -5.407920 0.168516 -22.764210
v  -5.391036 0.912945 -22.341303
v  -5.391036 0.898725 -21.853825
v  -5.407920 0.168516 -22.709095
v  -5.407921 0.168516 -21.542864
v  -5.405830 0.781630 -21.478991
v  -5.405830 0.781630 -22.772968
v  -5.377655 0.168516 -21.478991
v  -5.377655 0.168516 -22.772968
v  -5.481664 0.781630 -21.649090
v  -5.481664 0.781630 -22.602869
v  -5.481664 0.781630 -22.657984
v  -5.481664 0.781630 -21.593975
v  -5.375565 0.781630 -22.764210
v  -5.375565 0.781630 -21.487749
v  -5.375565 0.781630 -21.542864
v  -5.375565 0.781630 -22.709095
v  -5.375565 0.168516 -21.547495
v  -5.375565 0.168516 -22.704464
v  -5.407921 0.781630 -21.547495
v  -5.407920 0.781630 -22.704464
v  -5.379654 0.168516 -22.747076
v  -5.379654 0.168516 -21.504883
v  -5.391036 0.979778 -22.220798
v  -5.391036 0.972193 -21.972149
v  -5.450015 0.781630 -22.593710
v  -5.450015 0.781630 -22.648825
v  -5.450015 0.781630 -21.658249
v  -5.450015 0.781630 -21.603134
v  -5.450015 0.168516 -21.589447
v  -5.450015 0.168516 -22.662514
v  -5.450015 0.168516 -21.644562
v  -5.450015 0.168516 -22.607397
v  -5.391036 0.595960 -21.587049
v  -5.453397 0.781630 -21.581470
v  -5.453397 0.781630 -22.670488
v  -5.453397 0.781630 -22.615372
v  -5.453397 0.781630 -21.636585
v  -5.477574 0.168516 -21.581470
v  -5.477574 0.168516 -22.670488
v  -5.405830 0.781630 -21.501137
v  -5.405830 0.781630 -22.750822
v  -5.477574 0.168516 -22.615372
v  -5.477574 0.168516 -21.636585
v  -5.405830 0.168516 -22.750822
v  -5.405830 0.168516 -21.501137
v  -5.475047 0.168516 -22.637753
v  -5.475047 0.168516 -21.614206
v  -5.475047 0.168516 -22.582636
v  -5.475047 0.168516 -21.669323
v  -5.385127 0.781630 -22.727011
v  -5.385127 0.781630 -21.469831
v  -5.385127 0.781630 -22.782127
v  -5.385127 0.781630 -21.524948
v  -5.385127 0.168516 -21.565411
v  -5.398359 0.168516 -22.727011
v  -5.398359 0.168516 -22.782127
v  -5.398359 0.168516 -21.469831
v  -5.398359 0.168516 -21.524948
v  -5.385127 0.168516 -22.686546
v  -5.385127 0.781630 -21.565411
v  -5.385127 0.781630 -22.686546
v  -5.475047 0.781630 -22.582636
v  -5.475047 0.781630 -21.614206
v  -5.475047 0.781630 -21.669323
v  -5.475047 0.781630 -22.637753
v  -5.453397 0.168516 -22.640850
v  -5.453397 0.168516 -22.585735
v  -5.453397 0.168516 -21.611109
v  -5.453397 0.168516 -21.666224
v  -5.405830 0.781630 -21.556253
v  -5.405830 0.781630 -22.695705
v  -5.385127 0.168516 -22.741663
v  -5.385127 0.168516 -21.510296
v  -5.472102 0.168516 -21.631172
v  -5.472102 0.168516 -21.576057
v  -5.472102 0.168516 -22.620785
v  -5.472102 0.168516 -22.675901
v  -5.458869 0.781630 -21.631172
v  -5.458869 0.781630 -22.620785
v  -5.458869 0.781630 -21.576057
v  -5.458869 0.781630 -22.675901
v  -5.391743 0.168516 -21.467918
v  -5.391743 0.168516 -21.523033
v  -5.391743 0.168516 -22.728926
v  -5.391743 0.168516 -22.784042
v  -5.391743 0.781630 -22.784042
v  -5.391743 0.781630 -21.523033
v  -5.391743 0.781630 -21.467918
v  -5.391743 0.781630 -22.728926
v  -5.401304 0.168516 -22.688862
v  -5.401305 0.168516 -21.563097
v  -5.458869 0.168516 -21.631172
v  -5.458869 0.168516 -22.620785
v  -5.458869 0.168516 -21.576057
v  -5.458869 0.168516 -22.675901
v  -5.458869 0.781630 -21.616522
v  -5.472102 0.781630 -21.576057
v  -5.458869 0.781630 -22.580322
v  -5.458869 0.781630 -22.635437
v  -5.472102 0.781630 -22.675901
v  -5.458869 0.781630 -21.671637
v  -5.391743 0.781630 -21.512211
v  -5.391743 0.781630 -22.739748
v  -5.472102 0.781630 -22.620785
v  -5.472102 0.781630 -21.631172
v  -5.379654 0.168516 -21.475245
v  -5.379654 0.168516 -21.530361
v  -5.379654 0.168516 -22.776714
v  -5.379654 0.168516 -22.721598
v  -5.403832 0.781630 -21.530361
v  -5.403831 0.781630 -22.776714
v  -5.379654 0.781630 -21.504883
v  -5.379654 0.781630 -22.747076
v  -5.379654 0.781630 -21.559998
v  -5.403831 0.781630 -22.721598
v  -5.403832 0.781630 -21.475245
v  -5.379654 0.781630 -22.691959
v  -5.480957 0.781630 -22.648825
v  -5.480957 0.781630 -22.593710
v  -5.480957 0.781630 -21.603134
v  -5.480957 0.781630 -21.658249
v  -5.375565 0.168516 -21.487749
v  -5.375565 0.168516 -22.764210
v  -5.375565 0.168516 -22.709095
v  -5.375565 0.168516 -21.542864
v  -5.375565 0.781630 -21.547495
v  -5.375565 0.781630 -22.759579
v  -5.375565 0.781630 -21.492378
v  -5.375565 0.781630 -22.704464
v  -5.468868 0.781630 -21.673067
v  -5.395125 0.781630 -22.685118
v  -5.395125 0.781630 -21.566841
v  -5.468868 0.781630 -22.578892
v  -5.468868 0.781630 -22.634007
v  -5.468868 0.781630 -21.617952
v  -5.401305 0.781630 -21.507980
v  -5.401304 0.781630 -22.743979
v  -5.479574 0.168516 -22.644596
v  -5.479574 0.168516 -21.662479
v  -5.479574 0.168516 -22.589481
v  -5.479574 0.168516 -21.607363
v  -5.480957 0.781630 -22.607397
v  -5.480957 0.781630 -21.644562
v  -5.407921 0.168516 -21.492378
v  -5.407921 0.168516 -21.547495
v  -5.407920 0.168516 -22.704464
v  -5.407920 0.168516 -22.759579
v  -5.407920 0.781630 -22.709095
v  -5.407920 0.781630 -22.764210
v  -5.407921 0.781630 -21.487749
v  -5.407921 0.781630 -21.542864
v  -5.465486 0.168516 -22.633522
v  -5.465486 0.168516 -21.673552
v  -5.465486 0.168516 -22.578407
v  -5.391743 0.168516 -22.684633
v  -5.391743 0.168516 -21.567326
v  -5.465486 0.168516 -21.618435
v  -5.398359 0.168516 -22.741663
v  -5.398359 0.168516 -21.510296
v  -5.449307 0.168516 -21.598604
v  -5.449307 0.168516 -22.653355
v  -5.449307 0.168516 -21.653721
v  -5.481664 0.168516 -22.657984
v  -5.481664 0.168516 -21.593975
v  -5.449307 0.168516 -22.598238
v  -5.449307 0.781630 -21.649090
v  -5.449307 0.781630 -21.593975
v  -5.449307 0.781630 -22.657984
v  -5.449307 0.781630 -22.602869
v  -5.480957 0.781630 -22.662514
v  -5.480957 0.781630 -21.589447
v  -5.481664 0.168516 -22.602869
v  -5.481664 0.168516 -21.649090
v  -5.405830 0.168516 -21.534107
v  -5.377655 0.168516 -22.695705
v  -5.377655 0.168516 -22.750822
v  -5.377655 0.168516 -21.501137
v  -5.405830 0.168516 -21.478991
v  -5.405830 0.168516 -22.717854
v  -5.405830 0.168516 -22.772968
v  -5.377655 0.168516 -21.556253
v  -5.377655 0.781630 -22.717854
v  -5.377655 0.781630 -21.534107
v  -5.377655 0.781630 -21.478991
v  -5.377655 0.781630 -22.772968
v  -5.395125 0.168516 -21.511726
v  -5.395125 0.168516 -22.740232
v  -5.449307 0.781630 -21.653721
v  -5.449307 0.781630 -21.598604
v  -5.449307 0.781630 -22.598238
v  -5.449307 0.781630 -22.653355
v  -5.449307 0.168516 -21.649090
v  -5.449307 0.168516 -21.593975
v  -5.449307 0.168516 -22.657984
v  -5.449307 0.168516 -22.602869
v  -5.395125 0.781630 -21.511726
v  -5.395125 0.781630 -22.740232
v  -5.388361 0.781630 -21.523518
v  -5.462103 0.781630 -22.677332
v  -5.388361 0.781630 -21.468401
v  -5.462104 0.781630 -21.629742
v  -5.388361 0.781630 -22.783558
v  -5.388361 0.781630 -22.728441
v  -5.462103 0.781630 -22.622215
v  -5.462104 0.781630 -21.574627
v  -5.468868 0.168516 -21.629742
v  -5.468868 0.168516 -22.622215
v  -5.395125 0.168516 -22.783558
v  -5.395125 0.168516 -21.523518
v  -5.388361 0.168516 -21.511726
v  -5.468868 0.168516 -22.677332
v  -5.462104 0.168516 -21.673067
v  -5.395125 0.168516 -22.728441
v  -5.388361 0.168516 -22.685118
v  -5.388361 0.168516 -22.740232
v  -5.462104 0.168516 -21.617952
v  -5.462103 0.168516 -22.578892
v  -5.468868 0.168516 -21.574627
v  -5.395125 0.168516 -21.468401
v  -5.388361 0.168516 -21.566841
v  -5.462103 0.168516 -22.634007
v  -5.451398 0.781630 -22.611628
v  -5.451398 0.781630 -21.640331
v  -5.451398 0.781630 -21.585217
v  -5.451398 0.781630 -22.666742
v  -5.479574 0.168516 -21.585217
v  -5.451398 0.168516 -22.589481
v  -5.451398 0.168516 -21.662479
v  -5.479573 0.168516 -22.666742
v  -5.451398 0.168516 -22.644596
v  -5.451398 0.168516 -21.607363
v  -5.395125 0.781630 -22.728441
v  -5.468868 0.781630 -21.629742
v  -5.462104 0.781630 -21.617952
v  -5.388361 0.781630 -22.740232
v  -5.395125 0.781630 -22.783558
v  -5.468868 0.781630 -22.622215
v  -5.395125 0.781630 -21.468401
v  -5.462103 0.781630 -22.578892
v  -5.462103 0.781630 -22.634007
v  -5.395125 0.781630 -21.523518
v  -5.468868 0.781630 -21.574627
v  -5.388361 0.781630 -21.511726
v  -5.468868 0.781630 -22.677332
v  -5.388361 0.781630 -22.685118
v  -5.462104 0.781630 -21.673067
v  -5.388361 0.781630 -21.566841
v  -5.388361 0.168516 -22.783558
v  -5.462103 0.168516 -22.677332
v  -5.462103 0.168516 -22.622215
v  -5.462104 0.168516 -21.574627
v  -5.388361 0.168516 -21.468401
v  -5.462104 0.168516 -21.629742
v  -5.388361 0.168516 -21.523518
v  -5.388361 0.168516 -22.728441
v  -5.451398 0.781630 -21.662479
v  -5.451398 0.781630 -22.644596
v  -5.451398 0.781630 -22.589481
v  -5.479574 0.781630 -21.585217
v  -5.451398 0.781630 -21.607363
v  -5.479573 0.781630 -22.666742
v  -5.451398 0.168516 -22.666742
v  -5.451398 0.168516 -21.640331
v  -5.451398 0.168516 -21.585217
v  -5.451398 0.168516 -22.611628
v  -5.407214 0.781630 -21.483221
v  -5.407214 0.781630 -22.713623
v  -5.376272 0.781630 -22.755051
v  -5.376272 0.781630 -21.552023
v  -5.407214 0.781630 -22.768738
v  -5.407214 0.781630 -21.538336
v  -5.376272 0.781630 -21.496906
v  -5.376272 0.781630 -22.699936
v  -5.376272 0.168516 -22.768738
v  -5.376272 0.168516 -22.713623
v  -5.376272 0.168516 -21.483221
v  -5.376272 0.168516 -21.538336
v  -5.475047 0.781630 -22.618469
v  -5.455924 0.781630 -22.637753
v  -5.382182 0.781630 -21.563097
v  -5.401305 0.781630 -21.527262
v  -5.401305 0.781630 -21.472147
v  -5.382182 0.781630 -21.507980
v  -5.455924 0.781630 -21.669323
v  -5.455924 0.781630 -21.614206
v  -5.401304 0.781630 -22.724697
v  -5.475047 0.781630 -22.673586
v  -5.475047 0.781630 -21.633488
v  -5.382181 0.781630 -22.743979
v  -5.455924 0.781630 -22.582636
v  -5.401304 0.781630 -22.779812
v  -5.382181 0.781630 -22.688862
v  -5.475047 0.781630 -21.578373
v  -5.382182 0.168516 -21.472147
v  -5.455924 0.168516 -21.633488
v  -5.455924 0.168516 -21.578373
v  -5.382181 0.168516 -22.724697
v  -5.382181 0.168516 -22.779812
v  -5.455924 0.168516 -22.618469
v  -5.382182 0.168516 -21.527262
v  -5.455924 0.168516 -22.673586
v  -5.382181 0.781630 -22.724697
v  -5.455924 0.781630 -21.578373
v  -5.382181 0.781630 -22.779812
v  -5.455924 0.781630 -22.618469
v  -5.455924 0.781630 -22.673586
v  -5.382182 0.781630 -21.472147
v  -5.382182 0.781630 -21.527262
v  -5.455924 0.781630 -21.633488
v  -5.455924 0.168516 -21.669323
v  -5.401304 0.168516 -22.724697
v  -5.401305 0.168516 -21.472147
v  -5.382182 0.168516 -21.507980
v  -5.382181 0.168516 -22.743979
v  -5.475047 0.168516 -21.633488
v  -5.455924 0.168516 -22.582636
v  -5.475047 0.168516 -21.578373
v  -5.455924 0.168516 -22.637753
v  -5.455924 0.168516 -21.614206
v  -5.382182 0.168516 -21.563097
v  -5.401304 0.168516 -22.779812
v  -5.475047 0.168516 -22.673586
v  -5.382181 0.168516 -22.688862
v  -5.475047 0.168516 -22.618469
v  -5.401305 0.168516 -21.527262
v  -5.376272 0.781630 -22.713623
v  -5.376272 0.781630 -21.538336
v  -5.376272 0.781630 -21.483221
v  -5.376272 0.781630 -22.768738
v  -5.376272 0.168516 -21.552023
v  -5.407214 0.168516 -22.713623
v  -5.407214 0.168516 -21.538336
v  -5.376272 0.168516 -22.699936
v  -5.407214 0.168516 -21.483221
v  -5.376272 0.168516 -22.755051
v  -5.376272 0.168516 -21.496906
v  -5.407214 0.168516 -22.768738
v  -5.479574 0.781630 -21.640331
v  -5.479573 0.781630 -22.611628
v  -5.479573 0.168516 -22.611628
v  -5.479574 0.168516 -21.640331
v  -5.398359 0.168516 -21.565411
v  -5.398359 0.168516 -22.686546
v  -5.472102 0.168516 -22.580322
v  -5.472102 0.168516 -21.671637
v  -5.472102 0.168516 -21.616522
v  -5.472102 0.168516 -22.635437
v  -5.472102 0.781630 -22.580322
v  -5.472102 0.781630 -21.616522
v  -5.472102 0.781630 -21.671637
v  -5.398359 0.781630 -21.565411
v  -5.398359 0.781630 -22.686546
v  -5.472102 0.781630 -22.635437
v  -5.481664 0.781630 -21.598604
v  -5.481664 0.781630 -22.598238
v  -5.481664 0.781630 -22.653355
v  -5.481664 0.781630 -21.653721
v  -5.481664 0.168516 -21.598604
v  -5.481664 0.168516 -21.653721
v  -5.481664 0.168516 -22.598238
v  -5.481664 0.168516 -22.653355
v  -5.407214 0.781630 -21.496906
v  -5.407214 0.781630 -21.552023
v  -5.407214 0.781630 -22.755051
v  -5.407214 0.781630 -22.699936
v  -5.407214 0.168516 -22.755051
v  -5.407214 0.168516 -21.552023
v  -5.407214 0.168516 -22.699936
v  -5.407214 0.168516 -21.496906
v  -5.403831 0.168516 -22.747076
v  -5.403832 0.168516 -21.504883
v  -5.403832 0.168516 -21.559998
v  -5.477574 0.168516 -21.666224
v  -5.477574 0.168516 -22.640850
v  -5.403831 0.168516 -22.691959
v  -5.477574 0.168516 -22.585735
v  -5.477574 0.168516 -21.611109
v  -5.403832 0.781630 -21.504883
v  -5.403831 0.781630 -22.691959
v  -5.477574 0.781630 -21.666224
v  -5.403831 0.781630 -22.747076
v  -5.477574 0.781630 -22.585735
v  -5.477574 0.781630 -22.640850
v  -5.403832 0.781630 -21.559998
v  -5.477574 0.781630 -21.611109
v  -5.391036 1.025923 -22.125977
v  -5.508923 1.027680 -22.629868
v  -5.508923 1.027680 -21.621292
v  -5.389483 1.027680 -22.791088
v  -5.389484 1.027680 -21.460072
v  2.049618 4.382421 -20.830006
v  -1.543651 4.382421 -20.830006
v  2.049618 4.382421 -11.278632
v  -1.543651 4.382421 -11.278632
v  0.285784 4.382420 -20.830006
v  2.049618 4.382421 -19.874422
v  0.285784 4.382420 -11.278632
v  -1.543651 4.382421 -19.874422
v  1.351039 4.432302 -23.451445
v  5.148512 4.432303 -20.772739
v  5.148512 4.432302 -23.451445
v  1.351039 4.432303 -20.772739
v  5.151182 5.462605 -22.112091
v  1.351039 5.462605 -22.112091
v  -0.813209 4.432306 -23.451443
v  -4.610682 4.432306 -20.772736
v  -4.610682 4.432306 -23.451443
v  -0.813209 4.432306 -20.772736
v  -4.613352 5.462609 -22.112089
v  -0.813209 5.462609 -22.112089
v  1.304413 5.556647 -20.514240
v  -0.801077 5.556647 -23.709507
v  -0.759992 5.556647 -20.522377
v  1.332811 5.556647 -23.709507
v  0.287126 6.341318 -22.111874
v  5.009450 4.382421 -20.554996
v  5.009450 4.382421 -23.721985
v  -4.541924 4.382421 -20.554996
v  -4.541924 4.382421 -23.721989
v  5.009450 4.382420 -22.591784
v  4.053866 4.382421 -20.554996
v  -4.541924 4.382420 -22.591785
v  4.053866 4.382421 -23.721985
v  4.220243 2.271765 -20.727644
v  2.055874 2.789584 -20.727644
v  2.055874 2.789589 -11.253465
v  4.220243 2.271771 -11.253464
v  2.124805 3.367838 -11.250781
v  2.349805 3.367838 -11.250781
v  2.124805 3.367838 -11.546093
v  2.349805 3.367838 -11.546093
v  2.349805 4.896170 -11.250781
v  2.349805 4.896170 -11.546093
v  2.349805 3.367838 -11.398438
v  2.349805 4.896170 -11.398437
v  2.124327 4.896170 -11.250781
v  2.124327 4.896170 -11.546093
v  2.036823 5.230972 -11.398437
v  2.124805 2.275182 -13.509258
v  2.349805 2.275182 -13.509258
v  2.124805 2.275182 -13.804569
v  2.349805 2.275182 -13.804569
v  2.349805 4.896170 -13.509258
v  2.349805 4.896170 -13.804569
v  2.349805 5.230972 -13.656914
v  2.349805 2.275182 -13.656914
v  2.349805 4.896170 -13.656914
v  2.124327 4.896170 -13.509258
v  2.124327 4.896170 -13.804569
v  2.123849 5.230972 -13.656914
v  1.827407 -0.063106 -11.250147
v  1.827407 -0.063106 -11.025147
v  2.122719 -0.063106 -11.250147
v  2.122719 -0.063106 -11.025147
v  1.827407 4.896170 -11.025146
v  2.122719 4.896170 -11.025146
v  1.975063 -0.063106 -11.025147
v  1.975063 4.896170 -11.025146
v  1.827407 4.896170 -11.250626
v  2.122719 4.896170 -11.250626
v  2.122719 4.896170 -11.419354
v  3.974838 -0.063106 -20.532768
v  3.974838 -0.063106 -20.307768
v  4.270149 -0.063106 -20.532768
v  4.270149 -0.063106 -20.307768
v  3.974838 4.896170 -20.307768
v  4.270149 4.896170 -20.307768
v  4.122494 -0.063106 -20.307768
v  4.122494 4.896170 -20.307768
v  3.974838 4.896170 -20.533245
v  4.270149 4.896170 -20.533245
v  4.122494 5.230972 -20.533724
v  5.185619 -0.063106 -20.514404
v  5.410619 -0.063106 -20.514404
v  5.185619 -0.063106 -20.809715
v  5.410619 -0.063106 -20.809715
v  5.185619 4.887787 -20.514404
v  5.410619 4.887787 -20.514404
v  5.185619 4.887787 -20.809715
v  5.410619 4.887787 -20.809715
v  5.410619 -0.063106 -20.662060
v  5.410619 4.887787 -20.662060
v  5.036994 4.887787 -20.656900
v  4.889339 -0.063106 -20.514584
v  4.889339 -0.063106 -20.289585
v  5.184650 -0.063106 -20.514584
v  5.184650 -0.063106 -20.289585
v  4.889339 4.887787 -20.514584
v  4.889339 4.887787 -20.289583
v  5.184650 4.887787 -20.514584
v  5.184650 4.887787 -20.289583
v  5.036994 -0.063106 -20.289585
v  5.036994 4.887787 -20.289583
v  4.270952 -0.063106 -11.253781
v  4.495952 -0.063106 -11.253781
v  4.270952 -0.063106 -11.549093
v  4.495952 -0.063106 -11.549093
v  4.270952 2.951773 -11.253781
v  4.495952 2.951773 -11.253781
v  4.270952 2.951773 -11.549093
v  4.495952 2.951773 -11.549093
v  4.495952 -0.063106 -11.401438
v  4.495952 2.951773 -11.401438
v  4.122328 2.951773 -11.396277
v  3.974672 -0.063106 -11.253962
v  3.974672 -0.063106 -11.028961
v  4.269983 -0.063106 -11.253962
v  4.269983 -0.063106 -11.028961
v  3.974672 2.951773 -11.253962
v  3.974672 2.951773 -11.028961
v  4.269983 2.951773 -11.253962
v  4.269983 2.951773 -11.028961
v  4.122328 -0.063106 -11.028961
v  4.122328 2.951773 -11.028961
v  4.270952 -0.063106 -13.509415
v  4.495952 -0.063106 -13.509415
v  4.270952 -0.063106 -13.804727
v  4.495952 -0.063106 -13.804727
v  4.495952 2.951773 -13.509415
v  4.495952 2.951773 -13.804727
v  4.495952 3.286575 -13.657071
v  4.495952 -0.063106 -13.657071
v  4.495952 2.951773 -13.657071
v  4.270474 2.951773 -13.509415
v  4.270474 2.951773 -13.804727
v  4.269997 3.286575 -13.657071
v  4.270952 -0.063106 -15.765620
v  4.495952 -0.063106 -15.765620
v  4.270952 -0.063106 -16.060932
v  4.495952 -0.063106 -16.060932
v  4.495952 2.951773 -15.765620
v  4.495952 2.951773 -16.060932
v  4.495952 3.286575 -15.913277
v  4.495952 -0.063106 -15.913277
v  4.495952 2.951773 -15.913277
v  4.270474 2.951773 -15.765620
v  4.270474 2.951773 -16.060932
v  4.269997 3.286575 -15.913277
v  4.270952 -0.063106 -18.021826
v  4.495952 -0.063106 -18.021826
v  4.270952 -0.063106 -18.317137
v  4.495952 -0.063106 -18.317137
v  4.495952 2.951773 -18.021826
v  4.495952 2.951773 -18.317137
v  4.495952 3.286575 -18.169481
v  4.495952 -0.063106 -18.169481
v  4.495952 2.951773 -18.169481
v  4.270474 2.951773 -18.021826
v  4.270474 2.951773 -18.317137
v  4.269997 3.286575 -18.169481
v  2.124805 2.275182 -15.765464
v  2.349805 2.275182 -15.765464
v  2.124805 2.275182 -16.060776
v  2.349805 2.275182 -16.060776
v  2.349805 4.896170 -15.765464
v  2.349805 4.896170 -16.060776
v  2.349805 5.230972 -15.913119
v  2.349805 2.275182 -15.913119
v  2.349805 4.896170 -15.913119
v  2.124327 4.896170 -15.765464
v  2.124327 4.896170 -16.060776
v  2.123849 5.230972 -15.913119
v  2.124805 2.275182 -18.021669
v  2.349805 2.275182 -18.021669
v  2.124805 2.275182 -18.316980
v  2.349805 2.275182 -18.316980
v  2.349805 4.896170 -18.021669
v  2.349805 4.896170 -18.316980
v  2.349805 5.230972 -18.169323
v  2.349805 2.275182 -18.169325
v  2.349805 4.896170 -18.169323
v  2.124327 4.896170 -18.021669
v  2.124327 4.896170 -18.316980
v  2.123849 5.230972 -18.169323
v  1.353443 4.307635 -20.514404
v  1.578443 4.307635 -20.514404
v  1.578443 5.176210 -20.514404
v  1.353443 5.176210 -20.514404
v  1.578443 4.307635 -20.662060
v  1.578443 5.176210 -20.662060
v  1.578443 4.307635 -20.809715
v  1.353443 4.307635 -20.809715
v  1.353443 5.176210 -20.809715
v  1.578443 5.176210 -20.809715
v  1.204819 5.176210 -20.656900
v  1.057163 4.307635 -20.514584
v  1.057163 4.307635 -20.289585
v  1.057163 5.176210 -20.289583
v  1.057163 5.176210 -20.514584
v  1.204819 4.307635 -20.289585
v  1.204819 5.176210 -20.289583
v  1.352475 4.307635 -20.289585
v  1.352475 4.307635 -20.514584
v  1.352475 5.176210 -20.514584
v  1.352475 5.176210 -20.289583
v  1.124811 5.516204 -20.515936
v  1.349811 5.516204 -20.515936
v  1.349811 6.047525 -20.515936
v  1.124333 6.047525 -20.515936
v  1.349811 5.516204 -20.663591
v  1.349811 6.047525 -20.663591
v  1.349811 5.516204 -20.811247
v  1.124811 5.516204 -20.811247
v  1.124333 6.047525 -20.811247
v  1.349811 6.047525 -20.811247
v  1.236833 6.382327 -20.663591
v  5.184767 -0.063106 -23.709541
v  5.184767 -0.063106 -23.934542
v  5.184767 4.887787 -23.934542
v  5.184767 4.887787 -23.709541
v  5.037110 -0.063106 -23.934542
v  5.037110 4.887787 -23.934542
v  4.889455 -0.063106 -23.934542
v  4.889455 -0.063106 -23.709541
v  4.889455 4.887787 -23.709541
v  4.889455 4.887787 -23.934542
v  5.042271 4.887787 -23.560917
v  5.184586 -0.063106 -23.413261
v  5.409587 -0.063106 -23.413261
v  5.409587 4.887787 -23.413261
v  5.184586 4.887787 -23.413261
v  5.409586 -0.063106 -23.560917
v  5.409586 4.887787 -23.560917
v  5.409587 -0.063106 -23.708572
v  5.184586 -0.063106 -23.708572
v  5.184586 4.887787 -23.708572
v  5.409587 4.887787 -23.708572
v  1.374392 4.418276 -23.714790
v  1.374392 4.418276 -23.939793
v  1.374392 5.176210 -23.939793
v  1.374392 5.176210 -23.714790
v  1.226737 4.418276 -23.939793
v  1.226738 5.176210 -23.939793
v  1.079082 4.418276 -23.939793
v  1.079082 4.418276 -23.714790
v  1.079082 5.176210 -23.714790
v  1.079082 5.176210 -23.939793
v  1.231897 5.176210 -23.566166
v  1.374213 4.418276 -23.418512
v  1.599214 5.176210 -23.418512
v  1.374214 5.176210 -23.418512
v  1.599214 5.176210 -23.566166
v  1.374213 4.418276 -23.713823
v  1.374214 5.176210 -23.713823
v  1.599214 5.176210 -23.713823
v  1.372862 5.516204 -23.486160
v  1.372862 5.516204 -23.711159
v  1.372862 6.047525 -23.711159
v  1.372862 6.047525 -23.485682
v  1.225205 5.516204 -23.711159
v  1.225205 6.047525 -23.711159
v  1.077549 5.516204 -23.711159
v  1.077549 5.516204 -23.486160
v  1.077549 6.047525 -23.485682
v  1.077549 6.047525 -23.711159
v  1.225205 6.382327 -23.598183
v  1.124811 7.294931 -10.374985
v  1.349811 7.294931 -10.374985
v  1.349811 7.826252 -10.374985
v  1.124333 7.826252 -10.374985
v  1.349811 7.294931 -10.492593
v  1.349811 7.826252 -10.492592
v  1.349811 7.294931 -10.610201
v  1.124811 7.294931 -10.610201
v  1.124333 7.826252 -10.610201
v  1.349811 7.826252 -10.610201
v  1.236833 8.161054 -10.492592
v  1.124811 7.294931 -11.971962
v  1.349811 7.294931 -11.971962
v  1.349811 7.826252 -11.971962
v  1.124333 7.826252 -11.971962
v  1.349811 7.294931 -12.089569
v  1.349811 7.826252 -12.089569
v  1.349811 7.294931 -12.207176
v  1.124811 7.294931 -12.207176
v  1.124333 7.826252 -12.207176
v  1.349811 7.826252 -12.207176
v  1.236833 8.161054 -12.089569
v  4.271689 2.946156 -13.713332
v  4.272262 2.661852 -13.713148
v  3.973127 2.830046 -13.713148
v  3.641712 2.982142 -13.713149
v  3.297825 3.114434 -13.713148
v  2.961276 3.223214 -13.713148
v  2.651874 3.304777 -13.713148
v  2.389429 3.355414 -13.713148
v  2.193749 3.371420 -13.713148
v  2.193601 3.635553 -13.713332
v  4.271689 2.946156 -13.595582
v  4.272262 2.661852 -13.595398
v  3.973127 2.830046 -13.595398
v  3.641712 2.982142 -13.595399
v  3.297825 3.114434 -13.595398
v  2.961276 3.223214 -13.595398
v  2.651874 3.304777 -13.595398
v  2.389429 3.355414 -13.595398
v  2.193749 3.371420 -13.595398
v  2.193601 3.635553 -13.595582
v  4.271689 2.946156 -11.385522
v  4.272262 2.661852 -11.385338
v  3.973127 2.830046 -11.385338
v  3.641712 2.982142 -11.385338
v  3.297825 3.114434 -11.385338
v  2.961276 3.223214 -11.385338
v  2.651874 3.304777 -11.385338
v  2.389429 3.355414 -11.385338
v  2.193749 3.371420 -11.385338
v  2.193601 3.635553 -11.385522
v  4.271689 2.946156 -11.267772
v  4.272262 2.661852 -11.267588
v  3.973127 2.830046 -11.267588
v  3.641712 2.982142 -11.267589
v  3.297825 3.114434 -11.267588
v  2.961276 3.223214 -11.267588
v  2.651874 3.304777 -11.267588
v  2.389429 3.355414 -11.267588
v  2.193749 3.371420 -11.267588
v  2.193601 3.635553 -11.267772
v  4.271689 2.946156 -15.974540
v  4.272262 2.661852 -15.974357
v  3.973127 2.830046 -15.974357
v  3.641712 2.982142 -15.974357
v  3.297825 3.114434 -15.974357
v  2.961276 3.223214 -15.974357
v  2.651874 3.304777 -15.974356
v  2.389429 3.355414 -15.974356
v  2.193749 3.371420 -15.974357
v  2.193601 3.635553 -15.974541
v  4.271689 2.946156 -15.856791
v  4.272262 2.661852 -15.856606
v  3.973127 2.830046 -15.856606
v  3.641712 2.982142 -15.856606
v  3.297825 3.114434 -15.856606
v  2.961276 3.223214 -15.856606
v  2.651874 3.304777 -15.856606
v  2.389429 3.355414 -15.856606
v  2.193749 3.371420 -15.856606
v  2.193601 3.635553 -15.856791
v  4.271689 2.946156 -18.206097
v  4.272262 2.661852 -18.205914
v  3.973127 2.830046 -18.205914
v  3.641712 2.982142 -18.205914
v  3.297825 3.114434 -18.205914
v  2.961276 3.223214 -18.205914
v  2.651874 3.304777 -18.205914
v  2.389429 3.355414 -18.205914
v  2.193749 3.371420 -18.205914
v  2.193601 3.635553 -18.206099
v  4.271689 2.946156 -18.088348
v  4.272262 2.661852 -18.088163
v  3.973127 2.830046 -18.088163
v  3.641712 2.982142 -18.088165
v  3.297825 3.114434 -18.088163
v  2.961276 3.223214 -18.088163
v  2.651874 3.304777 -18.088163
v  2.389429 3.355414 -18.088163
v  2.193749 3.371420 -18.088163
v  2.193601 3.635553 -18.088348
v  4.495952 0.592630 -11.253781
v  4.495952 1.444333 -11.253781
v  4.495952 2.296037 -11.253781
v  4.270952 2.296037 -11.253781
v  4.270952 1.444333 -11.253781
v  4.270952 0.592630 -11.253781
v  4.270952 0.592630 -11.549093
v  4.270952 1.444333 -11.549093
v  4.270952 2.296037 -11.549093
v  4.495952 2.296037 -11.549093
v  4.495952 1.444333 -11.549093
v  4.495952 0.592630 -11.549093
v  3.974672 0.592630 -11.028961
v  3.974672 1.444333 -11.028961
v  3.974672 2.296037 -11.028961
v  3.974672 2.296037 -11.253962
v  3.974672 1.444333 -11.253962
v  3.974672 0.592630 -11.253962
v  4.269983 0.592630 -11.253962
v  4.269983 1.444333 -11.253962
v  4.269983 2.296037 -11.253962
v  4.269983 2.296037 -11.028961
v  4.269983 1.444333 -11.028961
v  4.269983 0.592630 -11.028961
v  1.827407 1.015536 -11.025147
v  1.827407 2.416532 -11.025146
v  1.827407 3.817528 -11.025146
v  1.827407 3.817528 -11.250521
v  1.827407 2.416532 -11.250386
v  1.827407 1.015536 -11.250252
v  2.122719 1.015536 -11.250252
v  2.122719 2.416532 -11.250386
v  2.122719 3.817528 -11.250521
v  2.122719 3.817528 -11.025146
v  2.122719 2.416532 -11.025146
v  2.122719 1.015536 -11.025147
v  4.495952 0.592630 -13.509415
v  4.495952 1.444333 -13.509415
v  4.495952 2.296037 -13.509415
v  4.270578 2.296037 -13.509415
v  4.270713 1.444333 -13.509415
v  4.270848 0.592630 -13.509415
v  4.270848 0.592630 -13.804727
v  4.270713 1.444333 -13.804727
v  4.270578 2.296037 -13.804727
v  4.495952 2.296037 -13.804727
v  4.495952 1.444333 -13.804727
v  4.495952 0.592630 -13.804727
v  4.495952 0.592630 -15.765620
v  4.495952 1.444334 -15.765620
v  4.495952 2.296037 -15.765620
v  4.270578 2.296037 -15.765620
v  4.270713 1.444333 -15.765620
v  4.270848 0.592630 -15.765620
v  4.270848 0.592630 -16.060932
v  4.270713 1.444334 -16.060932
v  4.270578 2.296037 -16.060932
v  4.495952 2.296037 -16.060932
v  4.495952 1.444333 -16.060932
v  4.495952 0.592630 -16.060932
v  4.495952 0.592630 -18.021826
v  4.495952 1.444334 -18.021826
v  4.495952 2.296037 -18.021826
v  4.270578 2.296037 -18.021826
v  4.270713 1.444334 -18.021826
v  4.270848 0.592631 -18.021826
v  4.270848 0.592630 -18.317137
v  4.270713 1.444334 -18.317137
v  4.270578 2.296037 -18.317137
v  4.495952 2.296037 -18.317137
v  4.495952 1.444334 -18.317137
v  4.495952 0.592631 -18.317137
v  2.349805 3.821565 -13.509258
v  2.124523 3.821565 -13.509258
v  2.124523 3.821565 -13.804569
v  2.349805 3.821565 -13.804569
v  2.349805 3.821565 -15.765464
v  2.124523 3.821565 -15.765464
v  2.124523 3.821565 -16.060776
v  2.349805 3.821565 -16.060776
v  2.349805 3.821565 -18.021669
v  2.124523 3.821565 -18.021669
v  2.124523 3.821565 -18.316980
v  2.349805 3.821565 -18.316980
v  2.349805 3.811054 -11.250781
v  2.124666 3.811054 -11.250781
v  2.124666 3.811054 -11.546093
v  2.349805 3.811054 -11.546093
v  3.974838 2.416532 -20.307768
v  3.974838 2.416532 -20.533007
v  4.270149 2.416532 -20.533007
v  4.270149 2.416532 -20.307768
v  5.410619 2.412340 -20.514404
v  5.185619 2.412341 -20.514404
v  5.185619 2.412341 -20.809715
v  5.410619 2.412340 -20.809715
v  4.889339 2.412340 -20.289583
v  4.889339 2.412341 -20.514584
v  5.184650 2.412341 -20.514584
v  5.184650 2.412340 -20.289583
v  5.184767 2.412341 -23.934542
v  5.184767 2.412340 -23.709541
v  4.889455 2.412341 -23.709541
v  4.889455 2.412340 -23.934542
v  5.409587 2.412341 -23.413261
v  5.184586 2.412340 -23.413261
v  5.184586 2.412341 -23.708572
v  5.409587 2.412340 -23.708572
v  5.410619 0.762043 -20.514404
v  5.410619 1.587191 -20.514404
v  5.185619 0.762043 -20.809715
v  5.185619 1.587192 -20.809715
v  4.889339 0.762043 -20.289585
v  4.889339 1.587191 -20.289583
v  5.184650 0.762043 -20.514584
v  5.184650 1.587192 -20.514584
v  5.184767 0.762043 -23.934542
v  5.184767 1.587192 -23.934542
v  4.889455 0.762043 -23.709541
v  4.889455 1.587192 -23.709541
v  5.409587 0.762043 -23.413261
v  5.409587 1.587192 -23.413261
v  5.184586 0.762043 -23.708572
v  5.184586 1.587192 -23.708572
v  5.185619 1.587192 -20.514404
v  5.185619 0.762043 -20.514404
v  5.410619 1.587192 -20.809715
v  5.410619 0.762043 -20.809715
v  4.889339 1.587192 -20.514584
v  4.889339 0.762043 -20.514584
v  5.184650 1.587192 -20.289583
v  5.184650 0.762043 -20.289585
v  5.184767 1.587192 -23.709541
v  5.184767 0.762043 -23.709541
v  4.889455 1.587192 -23.934542
v  4.889455 0.762043 -23.934542
v  5.184586 1.587192 -23.413261
v  5.184586 0.762043 -23.413261
v  5.409587 1.587192 -23.708572
v  5.409587 0.762043 -23.708572
v  3.974838 4.069624 -20.533165
v  3.974838 3.243078 -20.533087
v  4.270149 4.069624 -20.307768
v  4.270149 3.243078 -20.307768
v  5.185619 4.062638 -20.514404
v  5.185619 3.237489 -20.514404
v  5.410619 4.062638 -20.809715
v  5.410619 3.237489 -20.809715
v  4.889339 4.062638 -20.514584
v  4.889339 3.237489 -20.514584
v  5.184650 4.062638 -20.289583
v  5.184650 3.237489 -20.289583
v  5.184767 4.062638 -23.709541
v  5.184767 3.237489 -23.709541
v  4.889455 4.062638 -23.934542
v  4.889455 3.237489 -23.934542
v  5.184586 4.062638 -23.413261
v  5.184586 3.237489 -23.413261
v  5.409587 4.062638 -23.708572
v  5.409587 3.237489 -23.708572
v  3.974838 3.243078 -20.307768
v  3.974838 4.069624 -20.307768
v  4.270149 3.243078 -20.533087
v  4.270149 4.069624 -20.533165
v  5.410619 3.237489 -20.514404
v  5.410619 4.062638 -20.514404
v  5.185619 3.237489 -20.809715
v  5.185619 4.062638 -20.809715
v  4.889339 3.237489 -20.289583
v  4.889339 4.062638 -20.289583
v  5.184650 3.237489 -20.514584
v  5.184650 4.062638 -20.514584
v  5.184767 3.237489 -23.934542
v  5.184767 4.062638 -23.934542
v  4.889455 3.237489 -23.709541
v  4.889455 4.062638 -23.709541
v  5.409587 3.237489 -23.413261
v  5.409587 4.062638 -23.413261
v  5.184586 3.237489 -23.708572
v  5.184586 4.062638 -23.708572
v  1.599214 4.425242 -23.418512
v  1.599214 4.425242 -23.566166
v  1.599213 4.418276 -23.713823
v  5.037110 5.222589 -23.934542
v  1.226738 5.511012 -23.939793
v  5.410619 5.222589 -20.662060
v  1.578443 5.511012 -20.662060
v  4.495952 3.286575 -11.401438
v  1.975063 5.230972 -11.025146
v  1.599214 5.511012 -23.566166
v  5.409586 5.222589 -23.560917
v  5.036994 5.222589 -20.289583
v  1.204819 5.511012 -20.289583
v  4.122328 3.286575 -11.028961
v  5.036994 1.587191 -20.289583
v  4.122494 2.416532 -20.307768
v  5.036994 4.062638 -20.289583
v  1.975063 3.817528 -11.025146
v  5.410619 4.062638 -20.662060
v  4.495952 2.296037 -13.657071
v  5.037110 4.062638 -23.934542
v  4.495952 2.296037 -11.401438
v  4.122328 2.296037 -11.028961
v  5.409586 4.062638 -23.560917
v  5.409586 1.587192 -23.560917
v  4.495952 2.296037 -18.169481
v  5.037110 1.587192 -23.934542
v  5.410619 1.587191 -20.662060
v  4.495952 2.296037 -15.913277
v  4.122494 4.069624 -20.307768
v  2.037301 4.896170 -11.546093
v  5.039691 5.222589 -23.561062
v  5.037140 5.222589 -20.659481
v  1.204964 5.511012 -20.659481
v  4.122473 3.286575 -11.398857
v  1.229316 5.511012 -23.566313
v  1.975063 5.230972 -11.419832
v  2.349805 5.230972 -11.398437
v  4.122494 5.230972 -20.307768
v  4.889455 4.887787 -23.413265
v  3.974675 2.951773 -11.549093
v  4.889342 4.887787 -20.809715
v  1.057166 5.176210 -20.809715
v  1.079082 5.176210 -23.418514
v  2.037301 4.896170 -11.250781
v  1.827407 4.896170 -11.419354
v  5.042271 4.887787 -23.708572
v  4.269983 2.951773 -11.396277
v  1.231897 5.176210 -23.713823
v  1.352475 5.176210 -20.656900
v  5.184650 4.887787 -20.656900
v  5.184767 4.887787 -23.561207
v  4.122618 2.951773 -11.253781
v  1.205109 5.176210 -20.514404
v  5.037285 4.887787 -20.514404
v  1.374392 5.176210 -23.566458
v  5.036994 0.762043 -20.289585
v  5.409586 3.237489 -23.560917
v  5.409586 2.412341 -23.560917
v  2.123849 5.230972 -11.398437
v  2.349805 3.811054 -11.398437
v  1.975063 1.015536 -11.025147
v  1.975063 2.416532 -11.025146
v  2.349805 3.821565 -15.913119
v  2.349805 3.821565 -18.169323
v  2.349805 3.821565 -13.656914
v  5.409586 0.762043 -23.560917
v  4.495952 0.592630 -18.169481
v  4.495952 0.592630 -11.401438
v  4.122328 1.444333 -11.028961
v  4.122328 0.592630 -11.028961
v  5.410619 3.237489 -20.662060
v  5.036994 3.237489 -20.289583
v  5.410619 2.412340 -20.662060
v  5.036994 2.412340 -20.289583
v  5.410619 0.762043 -20.662060
v  4.495952 1.444334 -18.169481
v  4.495952 1.444334 -15.913277
v  4.495952 1.444333 -13.657071
v  5.037110 3.237489 -23.934542
v  4.495952 0.592630 -13.657071
v  5.037110 0.762043 -23.934542
v  4.495952 1.444333 -11.401438
v  5.037110 2.412341 -23.934542
v  4.495952 0.592630 -15.913277
v  1.975063 5.230972 -11.251102
v  4.122494 3.243078 -20.307768
v  -3.699228 2.271769 -20.727642
v  -1.534859 2.789587 -20.727642
v  -1.534859 2.789587 -11.253463
v  -3.699228 2.271769 -11.253462
v  6.042300 0.169258 -21.460070
v  6.042300 0.169258 -21.557159
v  6.042300 -0.082123 -21.557159
v  6.042300 -0.082123 -21.460070
v  5.994952 0.169258 -21.460070
v  5.994952 -0.082123 -21.460070
v  5.947604 0.169258 -21.557159
v  5.947604 0.169258 -21.460070
v  5.947605 -0.082123 -21.460070
v  5.947605 -0.082123 -21.557159
v  6.114392 0.169258 -21.685005
v  6.042242 0.169258 -21.685005
v  6.042242 -0.082123 -21.685005
v  6.114392 -0.082123 -21.685005
v  6.114392 0.169258 -21.621290
v  6.114392 -0.082123 -21.621290
v  6.042242 0.169258 -21.557577
v  6.114392 0.169258 -21.557577
v  6.114392 -0.082123 -21.557577
v  6.042242 -0.082123 -21.557577
v  6.042300 0.169258 -22.791088
v  6.042300 0.169258 -22.694000
v  6.042300 -0.082123 -22.694000
v  6.042300 -0.082123 -22.791088
v  5.994952 0.169258 -22.791088
v  5.994952 -0.082123 -22.791088
v  5.947604 0.169258 -22.694000
v  5.947604 0.169258 -22.791088
v  5.947604 -0.082123 -22.791088
v  5.947604 -0.082123 -22.694000
v  6.114392 0.169258 -22.566153
v  6.042242 0.169258 -22.566153
v  6.042242 -0.082123 -22.566153
v  6.114392 -0.082123 -22.566153
v  6.114392 0.169258 -22.629868
v  6.114392 -0.082123 -22.629868
v  6.042242 0.169258 -22.693581
v  6.114392 0.169258 -22.693581
v  6.114392 -0.082123 -22.693581
v  6.042242 -0.082123 -22.693581
v  6.042299 1.286014 -22.693998
v  6.042299 1.286014 -22.791088
v  5.994951 1.286014 -22.791088
v  5.947603 1.286014 -22.791088
v  5.947603 1.286014 -22.693998
v  5.996606 1.286014 -22.629868
v  6.042242 1.286014 -22.566153
v  6.114391 1.286014 -22.566153
v  6.114391 1.286014 -22.629868
v  6.114391 1.286014 -22.693581
v  6.042242 1.286014 -22.693581
v  6.042300 1.027680 -22.791088
v  6.042300 1.027680 -22.693998
v  6.042300 0.776299 -22.693998
v  6.042300 0.776299 -22.791088
v  5.994952 0.776299 -22.791088
v  5.947603 1.027680 -22.693998
v  5.947603 1.027680 -22.791088
v  5.947604 0.776299 -22.791088
v  5.947604 0.776299 -22.693998
v  6.114391 1.027680 -22.566153
v  6.042242 1.027680 -22.566153
v  6.042242 0.776299 -22.566153
v  6.114392 0.776299 -22.566153
v  6.114392 0.776299 -22.629868
v  6.042242 1.027680 -22.693581
v  6.114391 1.027680 -22.693581
v  6.114392 0.776299 -22.693581
v  6.042242 0.776299 -22.693581
v  6.042299 1.286014 -21.557159
v  6.042299 1.286014 -21.460070
v  5.994951 1.286014 -21.460070
v  5.947603 1.286014 -21.460070
v  5.947603 1.286014 -21.557159
v  5.996606 1.286014 -21.621290
v  6.042242 1.286014 -21.685005
v  6.114391 1.286014 -21.685005
v  6.114391 1.286014 -21.621290
v  6.114391 1.286014 -21.557577
v  6.042242 1.286014 -21.557577
v  6.042300 1.027680 -21.460070
v  6.042300 1.027680 -21.557159
v  6.042300 0.776299 -21.557159
v  6.042300 0.776299 -21.460070
v  5.994952 0.776299 -21.460070
v  5.947603 1.027680 -21.557159
v  5.947603 1.027680 -21.460070
v  5.947604 0.776299 -21.460070
v  5.947604 0.776299 -21.557159
v  6.114391 1.027680 -21.685005
v  6.042242 1.027680 -21.685005
v  6.042242 0.776299 -21.685005
v  6.114392 0.776299 -21.685005
v  6.114392 0.776299 -21.621290
v  6.042242 1.027680 -21.557577
v  6.114391 1.027680 -21.557577
v  6.114392 0.776299 -21.557577
v  6.042242 0.776299 -21.557577
v  5.994951 1.417913 -22.791088
v  5.994951 1.417913 -21.460070
v  6.114391 1.417913 -21.621290
v  6.114391 1.417913 -22.629868
v  5.996504 1.002088 -22.673185
v  5.996504 1.002088 -21.578770
v  5.181032 1.002087 -22.673185
v  5.181032 1.002087 -21.578770
v  5.153656 1.002087 -21.578770
v  5.996504 1.002088 -21.578770
v  5.153656 -0.075318 -21.578770
v  5.996504 -0.075318 -21.578770
v  5.996504 1.002088 -22.673185
v  5.165835 1.002087 -22.673185
v  5.996504 -0.075318 -22.673185
v  5.165836 -0.075318 -22.673185
v  5.181031 1.376718 -22.125977
v  5.996503 1.376719 -22.125977
v  5.996503 1.700937 -22.125977
v  5.996504 0.515974 -22.673185
v  5.996504 1.040515 -22.095993
v  5.996504 0.566670 -21.578770
v  5.996504 1.002088 -22.673185
v  5.996504 1.002088 -21.578770
v  5.981033 0.168517 -22.759579
v  6.006773 0.168517 -22.743979
v  5.985122 0.781630 -22.776714
v  5.981033 0.168517 -21.492378
v  5.985122 0.781630 -21.475245
v  6.006773 0.168517 -21.507980
v  6.003828 0.781630 -21.510296
v  6.003828 0.781630 -22.741663
v  6.058865 0.168517 -21.636585
v  6.083043 0.781630 -21.636585
v  6.074337 0.168517 -21.673067
v  6.058865 0.168517 -22.615372
v  6.074337 0.168517 -22.578892
v  6.083043 0.781630 -22.615372
v  6.058865 0.168517 -21.581470
v  6.083043 0.781630 -21.581470
v  6.074337 0.168517 -21.617952
v  6.058865 0.168517 -22.670488
v  6.074337 0.168517 -22.634007
v  6.083043 0.781630 -22.670488
v  6.011299 0.168517 -21.556253
v  6.011298 0.781630 -21.534107
v  5.983123 0.168517 -21.534107
v  6.011299 0.168517 -22.695705
v  5.983123 0.168517 -22.717854
v  6.011298 0.781630 -22.717854
v  6.058865 0.781630 -22.585735
v  6.058865 0.781630 -21.666224
v  6.058865 0.781630 -22.640850
v  6.058865 0.781630 -21.611109
v  5.985122 0.781630 -21.530361
v  5.985122 0.781630 -22.721598
v  5.995779 1.417913 -22.629929
v  5.995779 1.417913 -21.621229
v  5.997211 0.781630 -21.567326
v  5.997211 0.781630 -22.684633
v  6.086425 0.168517 -22.607397
v  6.086425 0.168517 -21.644562
v  6.086425 0.168517 -22.662514
v  6.086425 0.168517 -21.589447
v  5.947603 1.286014 -22.566154
v  5.947603 1.286014 -21.685003
v  6.009300 0.168517 -22.776714
v  6.009300 0.168517 -21.475245
v  6.003827 0.781630 -22.782127
v  6.003827 0.781630 -21.469831
v  6.085042 0.781630 -21.607363
v  6.085042 0.781630 -22.644596
v  6.085042 0.781630 -21.662479
v  6.085042 0.781630 -22.589481
v  5.996504 0.736613 -22.552200
v  5.996606 1.286014 -22.693581
v  5.996606 1.286014 -21.557577
v  5.985122 0.168517 -21.559998
v  5.985122 0.168517 -22.691959
v  6.009300 0.168517 -22.721598
v  6.009300 0.168517 -21.530361
v  5.990595 0.168517 -21.469831
v  5.990595 0.168517 -22.782127
v  6.055483 0.168517 -21.658249
v  6.055483 0.168517 -22.648825
v  6.055483 0.168517 -21.603134
v  6.055483 0.168517 -22.593710
v  6.055483 0.781630 -21.644562
v  6.055483 0.781630 -21.589447
v  6.055483 0.781630 -22.607397
v  6.055483 0.781630 -22.662514
v  6.042299 1.286014 -22.629992
v  6.042299 1.286014 -21.621166
v  5.983123 0.781630 -22.750822
v  5.983123 0.781630 -22.695705
v  5.983123 0.781630 -21.501137
v  5.983123 0.781630 -21.556253
v  5.996504 0.715756 -21.655758
v  6.013389 0.781630 -22.759579
v  6.013389 0.781630 -21.492378
v  6.070954 0.781630 -22.622700
v  6.070954 0.781630 -21.629259
v  6.070954 0.781630 -21.574142
v  6.070954 0.781630 -22.677814
v  6.070954 0.168517 -22.677814
v  6.070954 0.168517 -22.622700
v  6.070954 0.168517 -21.574142
v  6.070954 0.168517 -21.629259
v  5.990595 0.168517 -21.524948
v  5.990595 0.168517 -22.727011
v  6.003827 0.781630 -22.727011
v  6.003827 0.781630 -21.524948
v  6.000593 0.168517 -22.685116
v  6.000593 0.168517 -21.566841
v  6.070954 0.781630 -22.633522
v  6.070954 0.781630 -21.673552
v  6.070954 0.781630 -21.618435
v  6.070954 0.781630 -22.578407
v  5.997211 0.168517 -21.512211
v  5.997211 0.168517 -22.739748
v  6.006773 0.781630 -22.688862
v  6.006773 0.781630 -21.563095
v  5.996504 0.833923 -22.453205
v  5.996504 0.814962 -21.746521
v  5.996504 0.614921 -22.633989
v  6.064338 0.168517 -21.616522
v  6.064338 0.168517 -22.580322
v  6.064338 0.168517 -22.635437
v  6.064338 0.168517 -21.671637
v  5.990594 0.781630 -22.741663
v  5.990594 0.781630 -21.510296
v  6.086425 0.168517 -22.593710
v  6.086425 0.168517 -21.658249
v  6.086425 0.168517 -22.648825
v  6.086425 0.168517 -21.603134
v  6.013389 0.168517 -21.487749
v  6.013389 0.168517 -22.764210
v  5.996504 0.912945 -22.341303
v  5.996504 0.898725 -21.853825
v  6.013389 0.168517 -22.709095
v  6.013389 0.168517 -21.542864
v  6.011298 0.781630 -21.478991
v  6.011298 0.781630 -22.772968
v  5.983123 0.168517 -21.478991
v  5.983123 0.168517 -22.772968
v  6.087132 0.781630 -21.649090
v  6.087132 0.781630 -22.602869
v  6.087132 0.781630 -22.657984
v  6.087132 0.781630 -21.593975
v  5.981033 0.781630 -22.764210
v  5.981033 0.781630 -21.487749
v  5.981033 0.781630 -21.542864
v  5.981033 0.781630 -22.709095
v  5.981033 0.168517 -21.547495
v  5.981033 0.168517 -22.704464
v  6.013389 0.781630 -21.547495
v  6.013389 0.781630 -22.704464
v  5.985122 0.168517 -22.747076
v  5.985122 0.168517 -21.504883
v  5.996504 0.979778 -22.220798
v  5.996504 0.972193 -21.972149
v  6.055483 0.781630 -22.593710
v  6.055483 0.781630 -22.648825
v  6.055483 0.781630 -21.658249
v  6.055483 0.781630 -21.603134
v  6.055483 0.168517 -21.589447
v  6.055483 0.168517 -22.662514
v  6.055483 0.168517 -21.644562
v  6.055483 0.168517 -22.607397
v  5.996504 0.595960 -21.587049
v  6.058865 0.781630 -21.581470
v  6.058865 0.781630 -22.670488
v  6.058865 0.781630 -22.615372
v  6.058865 0.781630 -21.636585
v  6.083043 0.168517 -21.581470
v  6.083043 0.168517 -22.670488
v  6.011298 0.781630 -21.501137
v  6.011298 0.781630 -22.750822
v  6.083043 0.168517 -22.615372
v  6.083043 0.168517 -21.636585
v  6.011299 0.168517 -22.750822
v  6.011299 0.168517 -21.501137
v  6.080516 0.168517 -22.637753
v  6.080516 0.168517 -21.614206
v  6.080516 0.168517 -22.582636
v  6.080516 0.168517 -21.669323
v  5.990594 0.781630 -22.727011
v  5.990594 0.781630 -21.469831
v  5.990594 0.781630 -22.782127
v  5.990594 0.781630 -21.524948
v  5.990595 0.168517 -21.565411
v  6.003828 0.168517 -22.727011
v  6.003828 0.168517 -22.782127
v  6.003828 0.168517 -21.469831
v  6.003828 0.168517 -21.524948
v  5.990595 0.168517 -22.686546
v  5.990594 0.781630 -21.565411
v  5.990594 0.781630 -22.686546
v  6.080515 0.781630 -22.582636
v  6.080515 0.781630 -21.614206
v  6.080515 0.781630 -21.669323
v  6.080515 0.781630 -22.637753
v  6.058865 0.168517 -22.640850
v  6.058865 0.168517 -22.585735
v  6.058865 0.168517 -21.611109
v  6.058865 0.168517 -21.666224
v  6.011298 0.781630 -21.556253
v  6.011298 0.781630 -22.695705
v  5.990595 0.168517 -22.741663
v  5.990595 0.168517 -21.510296
v  6.077571 0.168517 -21.631172
v  6.077571 0.168517 -21.576057
v  6.077571 0.168517 -22.620785
v  6.077571 0.168517 -22.675901
v  6.064337 0.781630 -21.631172
v  6.064337 0.781630 -22.620785
v  6.064337 0.781630 -21.576057
v  6.064337 0.781630 -22.675901
v  5.997211 0.168517 -21.467916
v  5.997211 0.168517 -21.523033
v  5.997211 0.168517 -22.728926
v  5.997211 0.168517 -22.784042
v  5.997211 0.781630 -22.784042
v  5.997211 0.781630 -21.523033
v  5.997211 0.781630 -21.467916
v  5.997211 0.781630 -22.728926
v  6.006773 0.168517 -22.688862
v  6.006773 0.168517 -21.563095
v  6.064338 0.168517 -21.631172
v  6.064338 0.168517 -22.620785
v  6.064338 0.168517 -21.576057
v  6.064338 0.168517 -22.675901
v  6.064337 0.781630 -21.616522
v  6.077570 0.781630 -21.576057
v  6.064337 0.781630 -22.580322
v  6.064337 0.781630 -22.635437
v  6.077570 0.781630 -22.675901
v  6.064337 0.781630 -21.671637
v  5.997211 0.781630 -21.512211
v  5.997211 0.781630 -22.739748
v  6.077570 0.781630 -22.620785
v  6.077570 0.781630 -21.631172
v  5.985122 0.168517 -21.475245
v  5.985122 0.168517 -21.530361
v  5.985122 0.168517 -22.776714
v  5.985122 0.168517 -22.721598
v  6.009300 0.781630 -21.530361
v  6.009300 0.781630 -22.776714
v  5.985122 0.781630 -21.504883
v  5.985122 0.781630 -22.747076
v  5.985122 0.781630 -21.559998
v  6.009300 0.781630 -22.721598
v  6.009300 0.781630 -21.475245
v  5.985122 0.781630 -22.691959
v  6.086425 0.781630 -22.648825
v  6.086425 0.781630 -22.593710
v  6.086425 0.781630 -21.603134
v  6.086425 0.781630 -21.658249
v  5.981033 0.168517 -21.487749
v  5.981033 0.168517 -22.764210
v  5.981033 0.168517 -22.709095
v  5.981033 0.168517 -21.542864
v  5.981033 0.781630 -21.547495
v  5.981033 0.781630 -22.759579
v  5.981033 0.781630 -21.492378
v  5.981033 0.781630 -22.704464
v  6.074336 0.781630 -21.673067
v  6.000593 0.781630 -22.685116
v  6.000593 0.781630 -21.566841
v  6.074336 0.781630 -22.578892
v  6.074336 0.781630 -22.634007
v  6.074336 0.781630 -21.617952
v  6.006773 0.781630 -21.507980
v  6.006773 0.781630 -22.743979
v  6.085042 0.168517 -22.644596
v  6.085042 0.168517 -21.662479
v  6.085042 0.168517 -22.589481
v  6.085042 0.168517 -21.607363
v  6.086425 0.781630 -22.607397
v  6.086425 0.781630 -21.644562
v  6.013389 0.168517 -21.492378
v  6.013389 0.168517 -21.547495
v  6.013389 0.168517 -22.704464
v  6.013389 0.168517 -22.759579
v  6.013389 0.781630 -22.709095
v  6.013389 0.781630 -22.764210
v  6.013389 0.781630 -21.487749
v  6.013389 0.781630 -21.542864
v  6.070954 0.168517 -22.633522
v  6.070954 0.168517 -21.673552
v  6.070954 0.168517 -22.578407
v  5.997211 0.168517 -22.684633
v  5.997211 0.168517 -21.567326
v  6.070954 0.168517 -21.618435
v  6.003828 0.168517 -22.741663
v  6.003828 0.168517 -21.510296
v  6.054776 0.168517 -21.598604
v  6.054776 0.168517 -22.653355
v  6.054776 0.168517 -21.653721
v  6.087132 0.168517 -22.657984
v  6.087132 0.168517 -21.593975
v  6.054776 0.168517 -22.598238
v  6.054776 0.781630 -21.649090
v  6.054776 0.781630 -21.593975
v  6.054776 0.781630 -22.657984
v  6.054776 0.781630 -22.602869
v  6.086425 0.781630 -22.662514
v  6.086425 0.781630 -21.589447
v  6.087132 0.168517 -22.602869
v  6.087132 0.168517 -21.649090
v  6.011299 0.168517 -21.534107
v  5.983123 0.168517 -22.695705
v  5.983123 0.168517 -22.750822
v  5.983123 0.168517 -21.501137
v  6.011299 0.168517 -21.478991
v  6.011299 0.168517 -22.717854
v  6.011299 0.168517 -22.772968
v  5.983123 0.168517 -21.556253
v  5.983123 0.781630 -22.717854
v  5.983123 0.781630 -21.534107
v  5.983123 0.781630 -21.478991
v  5.983123 0.781630 -22.772968
v  6.000593 0.168517 -21.511726
v  6.000593 0.168517 -22.740232
v  6.054776 0.781630 -21.653721
v  6.054776 0.781630 -21.598604
v  6.054776 0.781630 -22.598238
v  6.054776 0.781630 -22.653355
v  6.054776 0.168517 -21.649090
v  6.054776 0.168517 -21.593975
v  6.054776 0.168517 -22.657984
v  6.054776 0.168517 -22.602869
v  6.000593 0.781630 -21.511726
v  6.000593 0.781630 -22.740232
v  5.993828 0.781630 -21.523518
v  6.067572 0.781630 -22.677332
v  5.993828 0.781630 -21.468401
v  6.067572 0.781630 -21.629742
v  5.993828 0.781630 -22.783558
v  5.993828 0.781630 -22.728441
v  6.067572 0.781630 -22.622215
v  6.067572 0.781630 -21.574627
v  6.074337 0.168517 -21.629742
v  6.074337 0.168517 -22.622215
v  6.000593 0.168517 -22.783558
v  6.000593 0.168517 -21.523518
v  5.993829 0.168517 -21.511726
v  6.074337 0.168517 -22.677332
v  6.067572 0.168517 -21.673067
v  6.000593 0.168517 -22.728441
v  5.993829 0.168517 -22.685116
v  5.993829 0.168517 -22.740232
v  6.067572 0.168517 -21.617952
v  6.067572 0.168517 -22.578892
v  6.074337 0.168517 -21.574627
v  6.000593 0.168517 -21.468401
v  5.993829 0.168517 -21.566841
v  6.067572 0.168517 -22.634007
v  6.056866 0.781630 -22.611628
v  6.056866 0.781630 -21.640331
v  6.056866 0.781630 -21.585217
v  6.056866 0.781630 -22.666742
v  6.085042 0.168517 -21.585217
v  6.056867 0.168517 -22.589481
v  6.056867 0.168517 -21.662479
v  6.085042 0.168517 -22.666742
v  6.056867 0.168517 -22.644596
v  6.056867 0.168517 -21.607363
v  6.000593 0.781630 -22.728441
v  6.074336 0.781630 -21.629742
v  6.067572 0.781630 -21.617952
v  5.993828 0.781630 -22.740232
v  6.000593 0.781630 -22.783558
v  6.074336 0.781630 -22.622215
v  6.000593 0.781630 -21.468401
v  6.067572 0.781630 -22.578892
v  6.067572 0.781630 -22.634007
v  6.000593 0.781630 -21.523518
v  6.074336 0.781630 -21.574627
v  5.993828 0.781630 -21.511726
v  6.074336 0.781630 -22.677332
v  5.993828 0.781630 -22.685116
v  6.067572 0.781630 -21.673067
v  5.993828 0.781630 -21.566841
v  5.993829 0.168517 -22.783558
v  6.067572 0.168517 -22.677332
v  6.067572 0.168517 -22.622215
v  6.067572 0.168517 -21.574627
v  5.993829 0.168517 -21.468401
v  6.067572 0.168517 -21.629742
v  5.993829 0.168517 -21.523518
v  5.993829 0.168517 -22.728441
v  6.056866 0.781630 -21.662479
v  6.056866 0.781630 -22.644596
v  6.056866 0.781630 -22.589481
v  6.085042 0.781630 -21.585217
v  6.056866 0.781630 -21.607363
v  6.085042 0.781630 -22.666742
v  6.056867 0.168517 -22.666742
v  6.056867 0.168517 -21.640331
v  6.056867 0.168517 -21.585217
v  6.056867 0.168517 -22.611628
v  6.012681 0.781630 -21.483221
v  6.012681 0.781630 -22.713623
v  5.981740 0.781630 -22.755051
v  5.981740 0.781630 -21.552023
v  6.012681 0.781630 -22.768738
v  6.012681 0.781630 -21.538336
v  5.981740 0.781630 -21.496906
v  5.981740 0.781630 -22.699936
v  5.981740 0.168517 -22.768738
v  5.981740 0.168517 -22.713623
v  5.981740 0.168517 -21.483221
v  5.981740 0.168517 -21.538336
v  6.080515 0.781630 -22.618469
v  6.061392 0.781630 -22.637753
v  5.987649 0.781630 -21.563095
v  6.006773 0.781630 -21.527262
v  6.006773 0.781630 -21.472147
v  5.987649 0.781630 -21.507980
v  6.061392 0.781630 -21.669323
v  6.061392 0.781630 -21.614206
v  6.006773 0.781630 -22.724695
v  6.080515 0.781630 -22.673586
v  6.080515 0.781630 -21.633488
v  5.987649 0.781630 -22.743979
v  6.061392 0.781630 -22.582636
v  6.006773 0.781630 -22.779812
v  5.987649 0.781630 -22.688862
v  6.080515 0.781630 -21.578373
v  5.987650 0.168517 -21.472147
v  6.061392 0.168517 -21.633488
v  6.061392 0.168517 -21.578373
v  5.987650 0.168517 -22.724695
v  5.987650 0.168517 -22.779812
v  6.061392 0.168517 -22.618469
v  5.987650 0.168517 -21.527262
v  6.061392 0.168517 -22.673586
v  5.987649 0.781630 -22.724695
v  6.061392 0.781630 -21.578373
v  5.987649 0.781630 -22.779812
v  6.061392 0.781630 -22.618469
v  6.061392 0.781630 -22.673586
v  5.987649 0.781630 -21.472147
v  5.987649 0.781630 -21.527262
v  6.061392 0.781630 -21.633488
v  6.061392 0.168517 -21.669323
v  6.006773 0.168517 -22.724695
v  6.006773 0.168517 -21.472147
v  5.987650 0.168517 -21.507980
v  5.987650 0.168517 -22.743979
v  6.080516 0.168517 -21.633488
v  6.061392 0.168517 -22.582636
v  6.080516 0.168517 -21.578373
v  6.061392 0.168517 -22.637753
v  6.061392 0.168517 -21.614206
v  5.987650 0.168517 -21.563095
v  6.006773 0.168517 -22.779812
v  6.080516 0.168517 -22.673586
v  5.987650 0.168517 -22.688862
v  6.080516 0.168517 -22.618469
v  6.006773 0.168517 -21.527262
v  5.981740 0.781630 -22.713623
v  5.981740 0.781630 -21.538336
v  5.981740 0.781630 -21.483221
v  5.981740 0.781630 -22.768738
v  5.981740 0.168517 -21.552023
v  6.012682 0.168517 -22.713623
v  6.012682 0.168517 -21.538336
v  5.981740 0.168517 -22.699936
v  6.012682 0.168517 -21.483221
v  5.981740 0.168517 -22.755051
v  5.981740 0.168517 -21.496906
v  6.012682 0.168517 -22.768738
v  6.085042 0.781630 -21.640331
v  6.085042 0.781630 -22.611628
v  6.085042 0.168517 -22.611628
v  6.085042 0.168517 -21.640331
v  6.003828 0.168517 -21.565411
v  6.003828 0.168517 -22.686546
v  6.077571 0.168517 -22.580322
v  6.077571 0.168517 -21.671637
v  6.077571 0.168517 -21.616522
v  6.077571 0.168517 -22.635437
v  6.077570 0.781630 -22.580322
v  6.077570 0.781630 -21.616522
v  6.077570 0.781630 -21.671637
v  6.003828 0.781630 -21.565411
v  6.003828 0.781630 -22.686546
v  6.077570 0.781630 -22.635437
v  6.087132 0.781630 -21.598604
v  6.087132 0.781630 -22.598238
v  6.087132 0.781630 -22.653355
v  6.087132 0.781630 -21.653721
v  6.087132 0.168517 -21.598604
v  6.087132 0.168517 -21.653721
v  6.087132 0.168517 -22.598238
v  6.087132 0.168517 -22.653355
v  6.012681 0.781630 -21.496906
v  6.012681 0.781630 -21.552023
v  6.012681 0.781630 -22.755051
v  6.012681 0.781630 -22.699936
v  6.012682 0.168517 -22.755051
v  6.012682 0.168517 -21.552023
v  6.012682 0.168517 -22.699936
v  6.012682 0.168517 -21.496906
v  6.009300 0.168517 -22.747076
v  6.009300 0.168517 -21.504883
v  6.009300 0.168517 -21.559998
v  6.083043 0.168517 -21.666224
v  6.083043 0.168517 -22.640850
v  6.009300 0.168517 -22.691959
v  6.083043 0.168517 -22.585735
v  6.083043 0.168517 -21.611109
v  6.009300 0.781630 -21.504883
v  6.009300 0.781630 -22.691959
v  6.083043 0.781630 -21.666224
v  6.009300 0.781630 -22.747076
v  6.083043 0.781630 -22.585735
v  6.083043 0.781630 -22.640850
v  6.009300 0.781630 -21.559998
v  6.083043 0.781630 -21.611109
v  5.996504 1.025923 -22.125977
v  6.114391 1.027680 -22.629868
v  6.114391 1.027680 -21.621290
v  5.994951 1.027680 -22.791088
v  5.994951 1.027680 -21.460070
v  1.350269 7.484959 -12.209099
v  -0.778701 7.484959 -12.209099
v  1.350269 7.484959 -10.375866
v  -0.778701 7.484959 -10.375866
v  0.285784 7.484959 -12.209099
v  1.350269 7.484959 -11.253514
v  0.285784 7.484959 -10.375866
v  -0.778701 7.484959 -11.253514
v  -1.795343 4.397736 -13.491943
v  -1.807402 4.362707 -13.833945
v  -1.794194 4.331125 -13.493092
v  -1.525093 4.331125 -13.481607
v  -1.807402 4.379933 -17.992294
v  -1.794195 4.331124 -18.005503
v  -1.525094 4.331123 -17.994017
v  -1.807402 4.379932 -18.346355
v  -1.807402 4.369424 -11.221408
v  -1.805679 4.320615 -11.223130
v  -1.525237 4.320615 -11.223130
v  -1.821183 4.334970 -11.589251
v  -1.794195 4.314471 -15.749297
v  -1.821184 4.362707 -15.722308
v  -1.807402 4.379933 -16.090151
v  -1.525093 4.331124 -15.737812
v  -1.805680 4.331124 -16.088428
v  -1.525093 4.345480 -16.088428
v  -1.805680 4.331123 -18.344633
v  -1.525094 4.345479 -18.344633
v  -1.795343 4.387226 -11.563410
v  -1.525237 4.320615 -11.573747
v  -1.805679 4.331125 -13.832223
v  -1.536578 4.331125 -13.820738
v  -1.523371 4.379934 -13.833945
v  -1.535430 4.397736 -13.491943
v  -1.535430 4.397735 -15.748148
v  -1.523371 4.379933 -17.992294
v  -1.523514 4.369424 -11.221408
v  -1.523514 4.369424 -11.575469
v  -1.523371 4.379933 -18.346355
v  -1.523371 4.379933 -16.090151
v  -1.795343 4.397735 -15.748148
v  -1.794194 4.303962 -11.562262
v  -1.821183 4.345481 -13.466103
v  -1.778117 4.397735 -18.021580
v  -1.778116 4.387226 -11.250693
v  -1.795343 4.397736 -13.821886
v  -1.523371 4.362707 -15.736090
v  -1.523371 4.362707 -13.479884
v  -1.821184 4.345479 -17.978514
v  -1.778117 4.397735 -16.060865
v  -1.794194 4.314472 -13.820738
v  -1.778027 4.303962 -11.250782
v  -1.536578 4.314471 -16.076942
v  -1.536579 4.314471 -18.333149
v  -1.795343 4.397734 -18.334297
v  -1.794195 4.314470 -18.333149
v  -1.794195 4.314471 -16.076942
v  -1.535430 4.397735 -18.334297
v  -1.535573 4.387226 -11.563410
v  -1.535430 4.397735 -16.078091
v  -1.535430 4.397735 -18.004354
v  -1.535573 4.387226 -11.233467
v  -1.552656 4.397736 -13.804660
v  -1.536578 4.314471 -18.005503
v  -1.536722 4.303962 -11.562262
v  -1.536722 4.303962 -11.234615
v  -1.536578 4.314472 -13.493092
v  -1.536578 4.314471 -15.749297
v  -1.509589 4.345481 -13.847727
v  -1.795343 4.379934 -13.491943
v  -1.807402 4.369424 -11.575469
v  -1.821184 4.362706 -17.978514
v  -1.821183 4.352198 -11.207626
v  -1.821183 4.362707 -13.466103
v  -1.821184 4.345480 -15.722308
v  -1.778028 4.314471 -18.021669
v  -1.794195 4.331124 -15.749297
v  -1.805679 4.334970 -11.223130
v  -1.805680 4.345480 -16.088428
v  -1.807402 4.379933 -15.736090
v  -1.821184 4.362707 -16.103931
v  -1.821184 4.362706 -18.360138
v  -1.821183 4.352198 -11.589251
v  -1.805679 4.320615 -11.573746
v  -1.536578 4.314472 -13.820738
v  -1.794194 4.314472 -13.493092
v  -1.523371 4.379934 -13.479884
v  -1.807402 4.379934 -13.833945
v  -1.523371 4.379933 -15.736090
v  -1.509733 4.352198 -11.207626
v  -1.509589 4.362707 -16.103931
v  -1.509589 4.362707 -13.847727
v  -1.509590 4.362706 -18.360138
v  -1.509733 4.352198 -11.589251
v  -1.523371 4.362706 -17.992294
v  -1.805680 4.345479 -18.344633
v  -1.536578 4.331124 -16.076942
v  -1.536579 4.331123 -18.333149
v  -1.525093 4.345480 -15.737812
v  -1.525094 4.345479 -17.994017
v  -1.525237 4.334970 -11.223130
v  -1.525093 4.345481 -13.481607
v  -1.525237 4.334970 -11.573747
v  -1.805679 4.345481 -13.832223
v  -1.778116 4.397736 -13.509171
v  -1.778028 4.314471 -15.765465
v  -1.778027 4.314472 -13.804571
v  -1.805679 4.345481 -13.481607
v  -1.805680 4.345479 -17.994017
v  -1.807402 4.352198 -11.221408
v  -1.807402 4.362706 -17.992294
v  -1.807402 4.362707 -15.736090
v  -1.795343 4.397735 -18.004354
v  -1.795343 4.397735 -16.078091
v  -1.795343 4.387226 -11.233467
v  -1.552656 4.397735 -16.060865
v  -1.552800 4.387226 -11.546184
v  -1.552657 4.397735 -18.317070
v  -1.552800 4.387226 -11.250693
v  -1.778117 4.397735 -15.765375
v  -1.778116 4.387226 -11.546184
v  -1.552656 4.397736 -13.509171
v  -1.552656 4.397735 -15.765375
v  -1.552657 4.397735 -18.021580
v  -1.778117 4.397734 -18.317070
v  -1.535430 4.397736 -13.821886
v  -1.778116 4.397736 -13.804660
v  -1.795343 4.379932 -18.334297
v  -1.795343 4.379933 -16.078091
v  -1.795343 4.369424 -11.563410
v  -1.807402 4.379934 -13.479884
v  -1.794194 4.320615 -11.234615
v  -1.794195 4.331124 -16.076942
v  -1.794195 4.314471 -18.005503
v  -1.794194 4.303962 -11.234615
v  -1.552746 4.314471 -18.316980
v  -1.552889 4.303962 -11.250782
v  -1.778027 4.314472 -13.509259
v  -1.552745 4.314472 -13.804571
v  -1.552746 4.314471 -16.060776
v  -1.552745 4.314472 -13.509259
v  -1.552746 4.314471 -15.765465
v  -1.552889 4.303962 -11.546094
v  -1.552746 4.314471 -18.021669
v  -1.778027 4.303962 -11.546094
v  -1.778028 4.314471 -16.060776
v  -1.778028 4.314470 -18.316980
v  -1.821183 4.334970 -11.207626
v  -1.805680 4.345480 -15.737812
v  -1.805679 4.334970 -11.573746
v  -1.509733 4.334970 -11.207626
v  -1.509590 4.345479 -17.978514
v  -1.821183 4.345481 -13.847727
v  -1.509589 4.345481 -13.466103
v  -1.821184 4.345479 -18.360138
v  -1.821184 4.345480 -16.103931
v  -1.509589 4.345480 -15.722308
v  -1.509589 4.345480 -16.103931
v  -1.509590 4.345479 -18.360138
v  -1.509733 4.334970 -11.589251
v  -1.525093 4.345481 -13.832223
v  -1.807402 4.362707 -13.479884
v  -1.821183 4.362707 -13.847727
v  -1.807402 4.362707 -16.090151
v  -1.807402 4.362706 -18.346355
v  -1.523514 4.352198 -11.221408
v  -1.509589 4.362707 -15.722308
v  -1.509590 4.362706 -17.978514
v  -1.509589 4.362707 -13.466103
v  -1.523371 4.362706 -18.346355
v  -1.523514 4.352198 -11.575469
v  -1.523371 4.362707 -13.833945
v  -1.523371 4.362707 -16.090151
v  -1.807402 4.352198 -11.575469
v  -1.795343 4.379933 -15.748148
v  -1.795343 4.379934 -13.821886
v  -1.795343 4.369424 -11.233467
v  -1.535430 4.379934 -13.491943
v  -1.795343 4.379933 -18.004354
v  -1.535430 4.379933 -15.748148
v  -1.535430 4.379933 -18.004354
v  -1.535573 4.369424 -11.233467
v  -1.535573 4.369424 -11.563410
v  -1.535430 4.379934 -13.821886
v  -1.535430 4.379933 -16.078091
v  -1.535430 4.379933 -18.334297
v  -1.525093 4.331125 -13.832223
v  -1.794194 4.320615 -11.562262
v  -1.794195 4.331123 -18.333149
v  -1.525093 4.331124 -16.088428
v  -1.805679 4.331125 -13.481607
v  -1.805680 4.331124 -15.737812
v  -1.805680 4.331124 -17.994017
v  -1.525094 4.331123 -18.344633
v  -1.536722 4.320615 -11.562262
v  -1.536578 4.331124 -15.749297
v  -1.536578 4.331123 -18.005503
v  -1.536722 4.320615 -11.234615
v  -1.536578 4.331125 -13.493092
v  -1.794194 4.331125 -13.820738
v  -1.778027 4.303962 -11.398438
v  -1.778028 4.314471 -18.169325
v  -1.778117 4.397735 -15.913120
v  -1.821184 4.345479 -18.169325
v  -1.807402 4.369424 -11.398438
v  -1.805679 4.320615 -11.398438
v  -1.805679 4.334970 -11.398438
v  -1.821184 4.362706 -18.169325
v  -1.807402 4.362707 -15.913120
v  -1.807402 4.379934 -13.656915
v  -1.795343 4.379933 -15.913120
v  -1.794195 4.331124 -15.913120
v  -1.778117 4.397735 -18.169325
v  -1.821183 4.345481 -13.656915
v  -1.794194 4.303962 -11.398438
v  -1.778028 4.314471 -15.913120
v  -1.795343 4.379934 -13.656915
v  -1.807402 4.352198 -11.398438
v  -1.794194 4.314472 -13.656915
v  -1.795343 4.369424 -11.398438
v  -1.821183 4.334970 -11.398438
v  -1.807402 4.379933 -15.913120
v  -1.821184 4.345480 -15.913120
v  -1.805680 4.331124 -15.913120
v  -1.795343 4.397735 -15.913120
v  -1.794195 4.314471 -15.913120
v  -1.821183 4.362707 -13.656915
v  -1.794194 4.320615 -11.398438
v  -1.778027 4.314472 -13.656915
v  -1.795343 4.387226 -11.398438
v  -1.794195 4.331123 -18.169325
v  -1.821184 4.362707 -15.913120
v  -1.805680 4.345480 -15.913120
v  -1.794194 4.331125 -13.656915
v  -1.805679 4.345481 -13.656915
v  -1.821183 4.352198 -11.398438
v  -1.795343 4.397736 -13.656915
v  -1.805679 4.331125 -13.656915
v  -1.778116 4.387226 -11.398438
v  -1.807402 4.362707 -13.656915
v  -1.807402 4.379933 -18.169325
v  -1.795343 4.379933 -18.169325
v  -1.778116 4.397736 -13.656915
v  -1.805680 4.345479 -18.169325
v  -1.795343 4.397735 -18.169325
v  -1.805680 4.331123 -18.169325
v  -1.807402 4.362706 -18.169325
v  -1.794195 4.314471 -18.169325
v  18.598257 3.191057 -5.439890
v  14.074232 3.191057 -5.439889
v  14.074232 3.191057 -4.114162
v  18.598257 3.191057 -4.114162
v  18.694281 -0.217132 -5.474776
v  18.694281 -0.217132 -4.079275
v  13.978212 -0.217132 -4.079275
v  13.978212 -0.217132 -5.474776
v  13.978212 0.394452 -5.474776
v  18.694281 0.394452 -5.474776
v  13.978212 0.394452 -4.079275
v  18.694281 0.394452 -4.079275
v  14.074232 0.394452 -5.439889
v  18.598257 0.394452 -5.439889
v  14.074232 0.394452 -4.114162
v  18.598257 0.394452 -4.114162
# 34331 vertices

o cloth_1
g cloth_1
usemtl 11___Defadwdlt
s 1
f 3560 3905 3759 
f 3966 3849 3625 
f 3783 3525 3611 
f 3916 3868 3555 
f 3573 3894 3819 
f 3681 3529 3617 
f 3809 3904 3993 
f 3631 3767 3976 
f 3781 3866 3845 
f 3774 3705 3969 
f 3685 3970 3791 
f 3570 3977 3718 
f 3534 3591 3768 
f 3600 3742 3678 
f 3884 3736 3618 
f 3888 3650 3841 
f 3564 4012 3687 
f 3550 3965 3758 
f 3910 3644 3843 
f 3730 3989 3646 
f 3782 3605 3962 
f 3978 3627 3956 
f 3830 3710 3527 
f 3853 3701 3908 
f 3873 3622 3924 
f 3711 3773 3912 
f 3811 4007 3998 
f 3849 3735 3972 
f 3660 3620 3886 
f 3920 3618 4030 
f 3761 3929 3948 
f 4005 4022 3802 
f 3678 3951 3792 
f 3797 3835 3609 
f 4015 3704 4028 
f 3795 3954 3955 
f 3544 3686 3630 
f 3695 3523 3687 
f 3832 3732 3927 
f 3725 3595 3559 
f 3861 3956 3627 
f 3760 3779 3919 
f 3743 3579 3689 
f 3540 3906 3943 
f 3621 3765 3979 
f 3640 3707 3693 
f 3859 3622 3874 
f 3859 3924 3622 
f 3825 3846 3938 
f 3752 3664 3637 
f 3706 3741 3738 
f 3715 3738 3872 
f 3693 3512 3614 
f 3910 3737 3810 
f 3878 3676 4016 
f 3928 3525 3783 
f 3633 3804 3954 
f 3850 3796 3613 
f 3638 3901 3653 
f 3682 3812 3576 
f 3722 3961 3679 
f 3674 3690 3831 
f 3769 3624 3657 
f 3867 4024 3692 
f 3520 3906 3740 
f 3605 3782 3717 
f 3949 3596 3598 
f 3794 3766 3763 
f 3683 4032 3639 
f 3874 3770 3699 
f 3799 3584 3907 
f 3979 3765 3820 
f 3979 3944 3808 
f 3862 3704 4015 
f 3742 3600 4019 
f 4021 3661 3751 
f 3899 3885 3820 
f 3921 3885 3899 
f 3848 3849 3972 
f 3694 3662 3595 
f 3759 3721 3560 
f 3760 3538 3779 
f 3572 3815 3896 
f 3673 3528 3913 
f 3794 3813 3766 
f 3728 3847 3534 
f 3898 3691 3517 
f 3987 3887 3749 
f 3886 3620 3966 
f 3841 3836 3888 
f 3563 3790 4008 
f 3733 3607 3932 
f 3761 3914 3936 
f 3525 3747 3902 
f 3660 3530 3771 
f 3762 3863 3659 
f 3866 3934 3845 
f 3556 3883 3744 
f 3767 3656 3697 
f 3814 3931 3964 
f 3935 3688 3546 
f 4013 3570 3718 
f 3882 3551 3712 
f 3644 3910 3810 
f 3709 3986 3553 
f 4029 3675 3869 
f 3636 3573 3911 
f 3982 3824 3833 
f 3822 3945 3752 
f 3900 3930 3853 
f 3726 3809 3778 
f 3812 3739 3893 
f 3722 3679 3674 
f 3761 3745 3929 
f 3920 3532 3618 
f 3768 3591 3671 
f 3658 4009 3630 
f 3685 3791 3803 
f 4035 3920 3817 
f 3621 3989 3730 
f 3910 3515 3545 
f 3862 3980 3704 
f 4019 3600 3746 
f 3582 3511 3696 
f 3583 3687 3772 
f 3583 3927 3732 
f 3637 3664 3554 
f 3895 3829 3584 
f 4028 3704 3847 
f 3711 3562 4023 
f 3757 3776 3680 
f 4027 3683 3639 
f 3587 3749 3887 
f 3838 3788 3926 
f 3901 3706 3917 
f 3763 3949 3794 
f 3739 3812 3724 
f 3524 3663 3750 
f 3671 3840 3524 
f 3942 3799 3592 
f 3875 3784 3694 
f 3970 3737 3791 
f 3594 3556 3892 
f 3658 3630 3857 
f 3537 3852 3667 
f 3764 3595 3725 
f 3763 3992 3552 
f 3643 3923 3552 
f 3535 3635 3597 
f 3597 3630 3686 
f 3729 3893 3739 
f 3598 3864 3652 
f 3690 3775 3831 
f 3599 3778 3809 
f 3600 3953 3746 
f 3792 3533 4001 
f 3835 3797 3871 
f 3705 3541 3731 
f 3826 4000 3602 
f 3602 3822 3666 
f 3743 3876 3748 
f 3603 3958 3737 
f 4005 3802 3996 
f 3852 3567 3771 
f 3876 3736 3884 
f 3740 3605 3717 
f 3973 3606 3629 
f 3939 3914 3761 
f 3798 3565 3607 
f 3705 3865 3844 
f 3683 3819 4032 
f 3897 3713 3709 
f 3860 3529 3681 
f 3835 3811 3609 
f 3758 3938 3550 
f 3752 3647 3664 
f 3756 3611 3977 
f 3717 3782 3925 
f 3943 4013 4020 
f 3850 3613 3721 
f 3861 3627 3538 
f 3727 3578 3671 
f 3964 3931 4004 
f 3853 3732 3670 
f 3838 3841 3650 
f 3616 3832 3927 
f 3798 3607 3733 
f 3782 3962 3990 
f 3909 3618 3532 
f 3651 3581 3619 
f 3619 3665 3528 
f 3543 3802 4022 
f 3620 3888 3836 
f 4031 3821 3960 
f 3765 3621 3708 
f 3855 3519 3776 
f 3953 3622 3873 
f 3946 3803 3623 
f 3997 4030 3618 
f 3837 3947 3890 
f 3837 3624 3769 
f 3625 3849 3848 
f 3530 3660 3994 
f 3719 3913 3528 
f 3665 3924 3528 
f 3720 3887 3987 
f 3957 3627 3978 
f 4014 3889 3588 
f 3628 3588 3722 
f 3629 3935 3973 
f 3611 3756 3783 
f 3937 3805 3597 
f 3937 3857 3805 
f 3912 3773 3631 
f 3552 3908 3643 
f 3869 3675 3815 
f 3956 3698 4002 
f 3759 3905 3773 
f 3633 3954 3547 
f 3634 3878 4016 
f 3738 3715 3948 
f 3933 3866 3781 
f 3744 3597 3635 
f 3516 3573 3636 
f 3574 3999 3735 
f 3777 3942 3851 
f 3510 3724 3734 
f 3920 4035 3510 
f 4011 3855 3776 
f 3586 3639 3770 
f 3750 3640 3959 
f 4020 3540 3943 
f 3702 3868 3916 
f 3591 3534 3808 
f 3803 3642 3817 
f 3643 3908 3701 
f 4025 3778 3599 
f 3571 3648 3854 
f 3854 3882 3712 
f 3835 3871 3645 
f 3709 4029 3869 
f 3589 3868 3813 
f 3610 3518 3647 
f 3825 3610 3945 
f 3801 3558 3644 
f 3820 3765 3899 
f 3584 3829 3649 
f 3725 3559 3907 
f 3889 4014 3788 
f 3741 3706 3650 
f 3892 3742 4019 
f 3754 3980 3862 
f 3794 3949 3513 
f 4026 3652 3864 
f 3948 3929 3738 
f 3532 3920 3653 
f 3654 3951 3678 
f 4027 3654 3683 
f 3877 3875 3694 
f 3942 3875 3877 
f 4004 3656 3964 
f 3692 3826 3867 
f 3760 3919 3846 
f 3550 3938 3846 
f 3728 3534 3768 
f 3578 3727 3658 
f 3557 3659 3863 
f 3903 3751 4018 
f 3974 3996 3802 
f 3660 3771 3567 
f 4018 3543 4022 
f 3872 3661 3715 
f 3952 3592 3799 
f 3595 3662 3559 
f 3979 3820 3944 
f 3524 3840 3663 
f 3647 3518 3664 
f 3873 3746 3953 
f 3665 3619 3991 
f 3574 3752 3999 
f 3574 4006 3982 
f 3880 3667 3852 
f 3667 3595 3764 
f 3833 3824 3564 
f 3620 3564 3824 
f 3887 3842 3587 
f 3517 3669 3898 
f 3853 3670 3900 
f 3900 3832 3616 
f 3915 3614 3834 
f 3828 3834 3614 
f 3661 4021 3715 
f 3759 3850 3721 
f 3547 3789 3673 
f 3674 3598 3596 
f 3963 3674 3679 
f 3632 3561 3675 
f 3969 3705 3844 
f 3902 3971 3525 
f 3677 3546 3688 
f 3914 3939 3546 
f 4033 3678 3742 
f 3654 3678 3568 
f 3893 3729 3975 
f 3893 3793 3588 
f 3519 3984 3680 
f 3680 3776 3519 
f 3681 3601 3988 
f 3932 3681 3733 
f 3682 3576 3856 
f 3856 3548 3682 
f 3683 3573 3819 
f 3683 3911 3573 
f 3604 3537 3684 
f 3997 3946 3623 
f 3970 3685 3786 
f 3597 3686 3535 
f 3687 3826 3602 
f 3564 3687 3833 
f 3688 3935 3612 
f 3925 4017 3717 
f 3884 3968 3876 
f 3551 3748 3876 
f 3596 3690 3674 
f 3596 3552 3923 
f 3798 3733 3981 
f 3517 3691 3806 
f 3602 4000 3822 
f 3945 3542 3825 
f 3915 3959 3640 
f 3834 3524 3915 
f 3880 3530 3694 
f 3694 3950 3877 
f 3583 3695 3687 
f 3615 3814 3879 
f 3529 3860 3696 
f 3753 3976 3569 
f 3569 3827 3615 
f 3823 3613 3698 
f 3613 4002 3698 
f 3924 3859 3881 
f 3986 3709 3572 
f 3700 3536 3564 
f 3616 3536 3700 
f 3549 3930 3575 
f 3701 4034 3643 
f 3534 3702 3641 
f 3891 3534 3585 
f 3703 3561 3632 
f 3561 3913 3719 
f 3704 3562 3711 
f 3562 3704 3980 
f 3807 3865 3705 
f 3731 3807 3705 
f 3548 3941 3706 
f 3682 3548 3706 
f 3611 3512 3707 
f 3512 3693 3707 
f 3821 3843 3708 
f 3960 3821 3708 
f 3983 4029 3709 
f 3816 3709 3713 
f 3527 3710 4026 
f 3704 3711 3585 
f 3763 3585 3992 
f 3712 3571 3854 
f 3540 3921 3906 
f 4007 3819 3894 
f 3608 3645 3967 
f 3521 3714 3755 
f 3714 3521 3754 
f 3522 3715 3672 
f 3522 3634 3715 
f 3716 3916 3555 
f 3989 3621 3716 
f 3520 3740 3717 
f 3717 4017 3520 
f 3718 3977 3640 
f 3640 3750 4020 
f 3626 3719 3528 
f 3881 3896 3924 
f 3957 3790 3563 
f 3669 3720 3563 
f 3947 3837 3721 
f 3823 3721 3613 
f 3722 3599 3628 
f 3674 3831 3722 
f 3909 3723 3618 
f 3618 3723 3884 
f 3510 3590 3724 
f 3724 3590 3739 
f 3907 3514 3725 
f 3904 3900 3993 
f 3575 3904 3549 
f 4009 3658 3727 
f 3828 3727 3834 
f 3857 3728 3658 
f 3658 3940 3578 
f 3864 3598 3963 
f 3963 3598 3674 
f 3580 4031 3515 
f 3731 3871 3601 
f 3731 3601 3807 
f 3879 3732 3615 
f 3615 3922 3569 
f 3617 3526 3733 
f 3681 3617 3733 
f 3653 3734 3638 
f 3812 3734 3724 
f 3942 3735 3851 
f 3877 3735 3942 
f 3689 3579 3997 
f 3946 3997 3579 
f 3737 3545 3593 
f 3737 3910 3545 
f 3706 3738 3917 
f 3917 3738 3858 
f 3739 3527 3729 
f 3527 3739 3830 
f 3551 3740 3712 
f 3605 3740 3968 
f 3802 3543 3888 
f 3741 3872 3738 
f 3742 3892 3556 
f 3742 3556 4033 
f 3743 3603 3786 
f 3958 3603 3743 
f 3568 3933 3781 
f 3744 4033 3556 
f 3745 3577 3723 
f 3745 3761 3936 
f 3991 3746 3873 
f 3746 3581 4019 
f 3928 3629 3606 
f 3902 3747 3939 
f 3644 3539 3882 
f 3958 3743 3748 
f 3749 3919 3779 
f 3749 3965 3550 
f 3750 3820 3885 
f 3540 4020 3750 
f 3751 3985 3672 
f 3985 3751 3659 
f 3666 3822 3752 
f 3574 3666 3752 
f 3780 3631 3753 
f 3753 3631 3976 
f 3521 3651 3754 
f 3754 3651 3619 
f 3728 3857 3755 
f 3521 3755 3594 
f 3570 3612 3935 
f 3570 3756 3977 
f 4027 4001 3533 
f 4027 3533 3654 
f 3518 3610 3758 
f 3673 3759 4003 
f 3759 4010 3547 
f 3624 3760 3657 
f 3624 3538 3760 
f 3761 3566 3939 
f 3566 3761 3785 
f 3567 3852 3762 
f 3604 3684 3762 
f 3763 3552 3596 
f 3596 3949 3763 
f 3667 3764 3537 
f 3801 3765 3558 
f 3558 3765 3708 
f 3766 3891 3585 
f 3585 3763 3766 
f 3767 3560 3656 
f 3905 3560 3767 
f 3578 3940 3768 
f 3578 3768 3671 
f 3867 3523 3656 
f 3656 3837 3769 
f 3639 4032 3770 
f 3770 3553 3986 
f 3771 3880 3852 
f 3880 3771 3530 
f 3927 3583 3772 
f 4012 3564 3536 
f 3547 4010 4023 
f 3773 3711 4023 
f 3983 3541 4029 
f 3541 3705 3774 
f 3643 3775 3923 
f 3599 3775 4025 
f 3639 3776 3757 
f 3639 4011 3776 
f 3554 3777 3637 
f 3942 3777 3895 
f 3778 3549 3726 
f 3549 3778 4025 
f 3538 3627 3779 
f 3627 3957 3987 
f 3780 3552 3992 
f 3753 3552 3780 
f 3911 3683 3654 
f 3654 3568 3781 
f 3990 3677 3782 
f 3925 3782 3677 
f 3935 3629 3783 
f 3629 3928 3783 
f 3662 3694 3784 
f 3592 3952 3784 
f 3948 3715 3634 
f 4016 3785 3634 
f 3579 3743 3786 
f 3579 3786 3685 
f 3511 3582 3787 
f 3788 3838 3650 
f 3889 3788 3650 
f 3528 3954 3619 
f 3528 3673 3789 
f 3790 3565 3798 
f 3957 3978 3790 
f 3803 3791 3593 
f 3593 3791 3737 
f 3678 3792 3600 
f 3953 3600 3984 
f 3679 3975 3729 
f 3975 3679 3961 
f 3813 3794 3589 
f 3589 3794 3513 
f 3619 3954 3795 
f 3619 3795 3754 
f 4002 3703 3632 
f 3850 3703 3796 
f 3797 3988 3601 
f 3871 3797 3601 
f 3691 3898 4008 
f 3691 3798 3981 
f 3918 3799 3907 
f 3559 3952 3799 
f 3577 3800 3962 
f 3677 3990 3936 
f 3854 3648 3801 
f 3899 3765 3801 
f 3888 3620 3802 
f 3974 3802 3620 
f 3642 3803 3531 
f 3593 3531 3803 
f 3954 3804 3955 
f 4023 3562 3804 
f 3630 3597 3805 
f 3630 3805 3857 
f 3526 3806 3691 
f 3807 3932 3607 
f 3865 3807 3607 
f 3641 3979 3808 
f 3641 3808 3534 
f 3809 3628 3599 
f 4014 3926 3788 
f 3644 3810 3539 
f 3810 3737 3958 
f 3582 3696 3811 
f 3696 3860 3811 
f 3812 3638 3734 
f 3812 3682 3638 
f 3534 3891 3702 
f 3868 3702 3813 
f 3695 3814 3523 
f 3695 3879 3814 
f 3815 3675 3561 
f 3815 3561 3719 
f 3983 3645 3541 
f 3816 3967 3645 
f 3817 3920 4030 
f 3623 3803 3817 
f 3661 4018 3751 
f 3661 3872 3818 
f 3967 3816 3713 
f 3713 3897 3819 
f 3820 3750 3663 
f 3820 3663 3944 
f 3821 4031 3580 
f 3580 3843 3821 
f 3945 3822 3542 
f 3822 4000 3542 
f 3947 3538 3890 
f 3947 3698 3538 
f 3824 3982 3668 
f 3849 3966 3668 
f 3692 3825 3542 
f 3825 3692 4024 
f 3826 3687 3523 
f 3523 3867 3826 
f 3569 3976 3827 
f 3656 4004 3697 
f 3544 3727 3828 
f 3514 3649 3829 
f 3739 3590 3830 
f 3722 3831 3599 
f 3775 3599 3831 
f 3900 3670 3832 
f 3670 3732 3832 
f 3602 3833 3687 
f 3833 3602 3666 
f 3671 3834 3727 
f 3524 3834 3671 
f 4007 3811 3835 
f 3645 3608 3835 
f 3841 3564 3836 
f 3620 3836 3564 
f 3837 3656 3560 
f 3837 3560 3721 
f 3926 3993 3995 
f 3995 3616 3700 
f 3612 3839 3688 
f 4013 3943 4017 
f 3671 3591 3840 
f 3840 3944 3663 
f 3838 3700 3841 
f 3564 3841 3700 
f 3669 3517 3842 
f 3708 3843 3558 
f 3843 3644 3558 
f 3675 3844 3632 
f 3956 3844 3978 
f 3636 3911 3845 
f 3846 3657 3760 
f 4024 3846 3825 
f 3585 3534 3847 
f 3704 3585 3847 
f 3530 3848 3655 
f 3994 3848 3530 
f 3574 3735 3849 
f 4006 3574 3849 
f 3673 3850 3759 
f 3913 3850 3673 
f 3777 3851 3637 
f 3851 3735 3999 
f 3537 3604 3852 
f 3852 3604 3762 
f 3569 3853 3753 
f 3853 3569 3922 
f 3644 3882 3854 
f 3801 3644 3854 
f 3622 3855 3586 
f 3855 3622 3519 
f 3576 3588 3856 
f 3856 3588 3889 
f 3556 3594 3857 
f 3594 3755 3857 
f 3532 3858 3745 
f 3858 3532 3917 
f 3874 3699 3859 
f 3881 3859 3699 
f 3860 3609 3811 
f 3988 3860 3681 
f 3861 3538 3698 
f 3698 3956 3861 
f 3862 3714 3754 
f 3714 3862 3755 
f 3762 3684 3863 
f 3963 3527 3864 
f 4026 3864 3527 
f 3565 3865 3607 
f 3844 3865 3565 
f 3866 3635 3934 
f 3635 3866 3933 
f 3656 3769 3867 
f 3867 3769 3657 
f 3868 3646 3555 
f 3815 3572 3869 
f 3572 3709 3869 
f 3817 3870 4035 
f 3870 3817 3642 
f 3645 3871 3541 
f 3731 3541 3871 
f 3741 3543 3872 
f 3543 4018 3872 
f 3873 3924 3665 
f 3873 3665 3991 
f 3874 3622 3586 
f 3770 3874 3586 
f 3592 3875 3942 
f 3592 3784 3875 
f 3689 3876 3743 
f 3736 3876 3689 
f 3950 3655 3877 
f 3972 3735 3877 
f 3634 3522 3878 
f 3732 3879 3583 
f 3695 3583 3879 
f 3667 3880 3595 
f 3595 3880 3694 
f 3572 3881 3986 
f 3896 3881 3572 
f 3882 3748 3551 
f 3748 3882 3539 
f 3597 3883 3937 
f 3597 3744 3883 
f 3968 3884 3605 
f 3605 3884 3723 
f 3885 3540 3750 
f 3921 3540 3885 
f 3886 3994 3660 
f 3625 3886 3966 
f 3842 3887 3669 
f 3887 3720 3669 
f 3650 3888 3741 
f 3888 3543 3741 
f 3548 3889 3650 
f 3889 3548 3856 
f 3837 3890 3624 
f 3890 3538 3624 
f 3891 3766 3813 
f 3813 3702 3891 
f 3892 4019 3521 
f 3521 3594 3892 
f 3893 3576 3812 
f 3588 3576 3893 
f 3894 3998 4007 
f 3895 3777 3554 
f 3896 3815 3719 
f 3719 3626 3896 
f 3553 3770 4032 
f 3553 3897 3709 
f 3898 3669 3563 
f 3563 4008 3898 
f 3648 3921 3899 
f 3899 3801 3648 
f 3900 3575 3930 
f 3900 3904 3575 
f 3901 3638 3682 
f 3706 3901 3682 
f 3676 3902 3566 
f 3902 3939 3566 
f 3659 4022 3762 
f 3903 3659 3751 
f 3904 3809 3726 
f 3904 3726 3549 
f 3905 3631 3773 
f 3767 3631 3905 
f 3906 3712 3740 
f 3712 3906 3571 
f 3649 3514 3907 
f 3649 3907 3584 
f 3908 3753 3853 
f 3552 3753 3908 
f 3745 3723 3909 
f 3745 3909 3532 
f 3515 3910 3580 
f 3843 3580 3910 
f 3845 3911 3781 
f 3654 3781 3911 
f 3631 3780 3912 
f 3711 3912 3780 
f 3850 3913 3703 
f 3561 3703 3913 
f 3677 3914 3546 
f 3936 3914 3677 
f 3640 3693 3915 
f 3614 3915 3693 
f 3916 3716 3641 
f 3916 3641 3702 
f 3532 3653 3917 
f 3917 3653 3901 
f 3799 3918 3559 
f 3559 3918 3907 
f 3550 3846 3919 
f 3919 3749 3550 
f 3920 3510 3734 
f 3734 3653 3920 
f 3571 3906 3921 
f 3571 3921 3648 
f 3922 3615 3732 
f 3922 3732 3853 
f 3596 3923 3690 
f 3690 3923 3775 
f 3896 3626 3924 
f 3924 3626 3528 
f 3925 3677 3688 
f 3688 3839 3925 
f 3993 3926 3809 
f 3628 3809 3926 
f 3536 3616 3927 
f 4012 3536 3927 
f 3525 3928 3747 
f 3606 3747 3928 
f 3858 3929 3745 
f 3738 3929 3858 
f 3930 3701 3853 
f 3930 3549 3701 
f 3827 3931 3615 
f 3814 3615 3931 
f 3601 3681 3932 
f 3807 3601 3932 
f 3933 3744 3635 
f 3744 3933 3568 
f 3535 3934 3635 
f 3756 3935 3783 
f 3935 3756 3570 
f 3936 3577 3745 
f 3577 3936 3800 
f 3857 3937 3556 
f 3556 3937 3883 
f 3938 3610 3825 
f 3938 3758 3610 
f 3606 3546 3939 
f 3606 3939 3747 
f 3940 3658 3728 
f 3940 3728 3768 
f 3650 3941 3548 
f 3941 3650 3706 
f 3799 3942 3584 
f 3895 3584 3942 
f 3943 3520 4017 
f 3943 3906 3520 
f 3808 3944 3591 
f 3944 3840 3591 
f 3945 3647 3752 
f 3647 3945 3610 
f 3803 3946 3685 
f 3579 3685 3946 
f 3823 3698 3947 
f 3947 3721 3823 
f 3785 3761 3948 
f 3948 3634 3785 
f 3598 3652 3949 
f 3652 3513 3949 
f 3950 3694 3530 
f 3655 3950 3530 
f 3951 3654 3533 
f 3533 3792 3951 
f 3662 3784 3952 
f 3559 3662 3952 
f 3984 3519 3953 
f 3953 3519 3622 
f 3528 3789 3954 
f 3547 3954 3789 
f 3562 3980 3955 
f 3955 3804 3562 
f 3956 3632 3844 
f 3632 3956 4002 
f 3987 3957 3720 
f 3563 3720 3957 
f 3958 3539 3810 
f 3539 3958 3748 
f 3959 3915 3524 
f 3750 3959 3524 
f 3960 3621 4031 
f 3621 3960 3708 
f 3961 3722 3588 
f 3961 3588 3793 
f 3577 3962 3723 
f 3723 3962 3605 
f 3963 3729 3527 
f 3679 3729 3963 
f 3523 3964 3656 
f 3814 3964 3523 
f 3749 3587 3965 
f 3965 3587 3758 
f 3966 3824 3668 
f 3824 3966 3620 
f 3819 4007 3967 
f 3967 3713 3819 
f 3876 3968 3551 
f 3740 3551 3968 
f 3675 4029 3969 
f 3675 3969 3844 
f 3786 3603 3970 
f 3603 3737 3970 
f 3676 3971 3902 
f 3972 3655 3848 
f 3655 3972 3877 
f 3546 3973 3935 
f 3546 3606 3973 
f 3660 3567 3974 
f 3620 3660 3974 
f 3961 3793 3975 
f 3893 3975 3793 
f 3697 3976 3767 
f 3827 3976 3697 
f 3707 3640 3977 
f 3707 3977 3611 
f 3565 3978 3844 
f 3978 3565 3790 
f 3716 3979 3641 
f 3979 3716 3621 
f 3980 3754 3795 
f 3795 3955 3980 
f 3526 3691 3981 
f 3981 3733 3526 
f 3574 3982 3666 
f 3833 3666 3982 
f 3709 3816 3983 
f 3645 3983 3816 
f 3792 3680 3984 
f 3984 3600 3792 
f 3985 3659 3557 
f 3699 3986 3881 
f 3986 3699 3770 
f 3749 3779 3987 
f 3627 3987 3779 
f 3609 3988 3797 
f 3988 3609 3860 
f 3989 3555 3646 
f 3716 3555 3989 
f 3800 3936 3990 
f 3990 3962 3800 
f 3746 3991 3581 
f 3991 3619 3581 
f 3992 3711 3780 
f 3992 3585 3711 
f 3616 3993 3900 
f 3616 3995 3993 
f 3886 3625 3994 
f 3994 3625 3848 
f 3700 3838 3995 
f 3926 3995 3838 
f 3996 3974 3567 
f 3762 3996 3567 
f 3997 3736 3689 
f 3618 3736 3997 
f 3998 3582 3811 
f 3637 3999 3752 
f 3999 3637 3851 
f 3542 4000 3692 
f 3826 3692 4000 
f 3680 4001 3757 
f 4001 3680 3792 
f 3796 3703 4002 
f 4002 3613 3796 
f 3547 4003 3759 
f 3673 4003 3547 
f 3827 3697 4004 
f 3931 3827 4004 
f 3996 3762 4005 
f 4005 3762 4022 
f 3668 4006 3849 
f 4006 3668 3982 
f 3608 3967 4007 
f 3835 3608 4007 
f 3798 4008 3790 
f 4008 3798 3691 
f 3630 4009 3544 
f 3727 3544 4009 
f 3773 4010 3759 
f 3773 4023 4010 
f 3639 3586 4011 
f 4011 3586 3855 
f 3927 3772 4012 
f 3772 3687 4012 
f 4013 3612 3570 
f 4017 3612 4013 
f 3926 4014 3628 
f 3588 3628 4014 
f 3755 4015 4028 
f 4015 3755 3862 
f 4016 3566 3785 
f 4016 3676 3566 
f 4017 3925 3839 
f 3839 3612 4017 
f 3818 4018 3661 
f 3818 3872 4018 
f 3651 3521 4019 
f 3651 4019 3581 
f 4020 3718 3640 
f 3718 4020 4013 
f 3672 4021 3751 
f 4021 3672 3715 
f 4022 3903 4018 
f 4022 3659 3903 
f 3633 4023 3804 
f 3547 4023 3633 
f 3657 4024 3867 
f 3657 3846 4024 
f 3775 3643 4025 
f 4034 4025 3643 
f 3652 4026 3513 
f 4001 4027 3757 
f 3639 3757 4027 
f 3847 3728 4028 
f 4028 3728 3755 
f 3774 3969 4029 
f 3774 4029 3541 
f 4030 3997 3623 
f 3623 3817 4030 
f 4031 3621 3730 
f 3730 3515 4031 
f 4032 3819 3897 
f 3897 3553 4032 
f 4033 3568 3678 
f 3568 4033 3744 
f 4034 3701 3549 
f 4025 4034 3549 
f 3870 3642 4035 
f 3510 4035 3642 
f 4232 4349 4233 
f 4248 4092 4350 
f 4193 4217 4211 
f 4346 4217 4220 
f 4145 4276 4385 
f 4317 4222 4120 
f 4165 4284 4174 
f 4092 4248 4176 
f 4155 4360 4193 
f 4184 4286 4103 
f 4394 4249 4359 
f 4125 4139 4246 
f 4322 4334 4207 
f 4373 4229 4191 
f 4364 4283 4168 
f 4141 4308 4250 
f 4386 4318 4114 
f 4372 4295 4348 
f 4135 4333 4282 
f 4310 4184 4064 
f 4356 4093 4209 
f 4367 4373 4287 
f 4102 4344 4378 
f 4076 4202 4122 
f 4117 4236 4363 
f 4177 4186 4207 
f 4368 4118 4382 
f 4159 4334 4322 
f 4188 4206 4351 
f 4323 4300 4152 
f 4150 4100 4128 
f 4187 4220 4073 
f 4265 4225 4094 
f 4172 4245 4359 
f 4296 4380 4250 
f 4114 4091 4135 
f 4188 4351 4087 
f 4287 4138 4367 
f 4257 4320 4216 
f 4224 4163 4266 
f 4391 4336 4111 
f 4328 4386 4213 
f 4103 4340 4184 
f 4225 4226 4094 
f 4184 4310 4161 
f 4288 4273 4036 
f 4151 4330 4158 
f 4297 4241 4067 
f 4215 4243 4199 
f 4343 4092 4176 
f 4138 4287 4182 
f 4139 4162 4246 
f 4220 4187 4303 
f 4341 4334 4159 
f 4185 4331 4149 
f 4326 4187 4281 
f 4244 4302 4180 
f 4046 4309 4074 
f 4075 4231 4203 
f 4237 4152 4300 
f 4244 4368 4302 
f 4210 4268 4179 
f 4269 4077 4390 
f 4117 4363 4261 
f 4329 4172 4299 
f 4089 4251 4345 
f 4197 4079 4048 
f 4209 4347 4356 
f 4248 4214 4096 
f 4211 4155 4193 
f 4231 4352 4203 
f 4143 4378 4344 
f 4318 4386 4328 
f 4221 4267 4183 
f 4058 4158 4083 
f 4315 4277 4107 
f 4215 4315 4243 
f 4280 4045 4127 
f 4257 4317 4320 
f 4195 4342 4305 
f 4163 4305 4342 
f 4385 4238 4145 
f 4306 4153 4169 
f 4249 4130 4189 
f 4290 4224 4266 
f 4230 4174 4284 
f 4265 4059 4219 
f 4312 4164 4198 
f 4337 4294 4147 
f 4114 4362 4091 
f 4083 4354 4270 
f 4236 4117 4192 
f 4069 4316 4279 
f 4296 4209 4093 
f 4068 4321 4274 
f 4043 4178 4299 
f 4196 4094 4226 
f 4075 4203 4168 
f 4211 4374 4252 
f 4052 4170 4272 
f 4313 4369 4273 
f 4261 4190 4269 
f 4340 4103 4298 
f 4149 4282 4185 
f 4383 4147 4255 
f 4383 4338 4147 
f 4377 4041 4100 
f 4297 4067 4151 
f 4154 4088 4048 
f 4154 4251 4332 
f 4276 4208 4232 
f 4182 4384 4208 
f 4239 4103 4286 
f 4371 4051 4258 
f 4080 4271 4057 
f 4232 4233 4291 
f 4105 4156 4053 
f 4339 4212 4115 
f 4065 4161 4324 
f 4039 4179 4268 
f 4274 4321 4243 
f 4289 4290 4201 
f 4109 4195 4063 
f 4322 4207 4109 
f 4228 4172 4110 
f 4110 4089 4370 
f 4345 4312 4165 
f 4111 4264 4391 
f 4183 4267 4112 
f 4070 4252 4374 
f 4278 4258 4051 
f 4114 4318 4082 
f 4082 4381 4114 
f 4181 4306 4169 
f 4194 4390 4077 
f 4293 4339 4194 
f 4366 4209 4296 
f 4375 4231 4237 
f 4066 4200 4055 
f 4350 4392 4117 
f 4334 4341 4118 
f 4244 4307 4074 
f 4149 4331 4119 
f 4262 4119 4038 
f 4311 4235 4132 
f 4320 4062 4216 
f 4095 4121 4049 
f 4121 4259 4301 
f 4142 4122 4180 
f 4379 4250 4308 
f 4301 4081 4319 
f 4220 4124 4073 
f 4131 4169 4351 
f 4087 4351 4169 
f 4126 4343 4233 
f 4053 4208 4384 
f 4085 4280 4127 
f 4292 4265 4085 
f 4348 4303 4187 
f 4295 4058 4348 
f 4088 4129 4289 
f 4249 4088 4357 
f 4359 4245 4130 
f 4270 4246 4083 
f 4246 4162 4221 
f 4353 4132 4235 
f 4353 4230 4132 
f 4133 4280 4173 
f 4184 4133 4286 
f 4215 4275 4084 
f 4052 4356 4170 
f 4067 4333 4135 
f 4218 4135 4256 
f 4281 4037 4326 
f 4197 4391 4264 
f 4390 4194 4137 
f 4138 4042 4255 
f 4378 4143 4383 
f 4283 4075 4168 
f 4152 4237 4206 
f 4376 4242 4195 
f 4269 4140 4261 
f 4060 4308 4141 
f 4277 4210 4179 
f 4122 4142 4076 
f 4338 4239 4099 
f 4143 4278 4051 
f 4361 4144 4213 
f 4103 4239 4371 
f 4278 4143 4344 
f 4238 4278 4145 
f 4146 4044 4116 
f 4323 4146 4300 
f 4338 4099 4216 
f 4133 4216 4286 
f 4282 4148 4355 
f 4241 4333 4067 
f 4119 4064 4149 
f 4175 4149 4064 
f 4128 4047 4150 
f 4151 4158 4372 
f 4100 4041 4151 
f 4271 4323 4152 
f 4206 4188 4152 
f 4232 4385 4276 
f 4169 4153 4087 
f 4357 4101 4249 
f 4285 4172 4240 
f 4123 4360 4155 
f 4123 4319 4379 
f 4339 4293 4069 
f 4069 4279 4339 
f 4157 4071 4054 
f 4341 4157 4118 
f 4158 4354 4083 
f 4330 4091 4158 
f 4290 4253 4227 
f 4159 4227 4247 
f 4231 4250 4352 
f 4209 4366 4044 
f 4106 4161 4310 
f 4161 4106 4324 
f 4070 4364 4168 
f 4221 4162 4070 
f 4048 4163 4197 
f 4266 4163 4327 
f 4373 4367 4229 
f 4255 4147 4294 
f 4198 4165 4312 
f 4165 4311 4132 
f 4166 4346 4112 
f 4166 4112 4267 
f 4163 4224 4063 
f 4263 4063 4224 
f 4049 4252 4365 
f 4095 4168 4203 
f 4050 4358 4131 
f 4050 4181 4358 
f 4170 4248 4272 
f 4323 4347 4146 
f 4268 4210 4171 
f 4054 4171 4382 
f 4172 4089 4110 
f 4172 4285 4332 
f 4317 4219 4222 
f 4085 4173 4280 
f 4345 4251 4079 
f 4387 4312 4345 
f 4388 4098 4149 
f 4213 4298 4361 
f 4176 4080 4057 
f 4176 4057 4343 
f 4055 4313 4273 
f 4066 4055 4273 
f 4329 4299 4178 
f 4039 4107 4179 
f 4277 4179 4107 
f 4074 4307 4046 
f 4122 4046 4180 
f 4114 4381 4181 
f 4362 4114 4181 
f 4102 4182 4208 
f 4254 4182 4102 
f 4389 4303 4348 
f 4183 4058 4083 
f 4133 4184 4065 
f 4184 4161 4065 
f 4148 4072 4355 
f 4355 4072 4331 
f 4186 4109 4207 
f 4242 4376 4369 
f 4047 4128 4187 
f 4325 4187 4326 
f 4104 4291 4057 
f 4104 4152 4188 
f 4129 4189 4108 
f 4363 4236 4293 
f 4077 4269 4190 
f 4191 4111 4115 
f 4111 4312 4387 
f 4192 4092 4343 
f 4392 4192 4117 
f 4040 4217 4193 
f 4336 4115 4111 
f 4339 4115 4336 
f 4195 4097 4086 
f 4242 4097 4195 
f 4094 4196 4043 
f 4342 4197 4163 
f 4342 4304 4197 
f 4311 4165 4198 
f 4337 4216 4062 
f 4093 4321 4068 
f 4093 4356 4199 
f 4200 4117 4261 
f 4200 4350 4117 
f 4327 4088 4266 
f 4289 4201 4088 
f 4202 4277 4084 
f 4203 4121 4095 
f 4259 4121 4203 
f 4144 4204 4113 
f 4204 4144 4361 
f 4126 4053 4205 
f 4192 4205 4279 
f 4139 4125 4206 
f 4206 4075 4283 
f 4223 4036 4207 
f 4036 4177 4207 
f 4232 4208 4349 
f 4053 4126 4208 
f 4146 4347 4209 
f 4044 4146 4209 
f 4210 4302 4368 
f 4210 4076 4302 
f 4252 4049 4211 
f 4211 4049 4319 
f 4212 4287 4373 
f 4105 4212 4156 
f 4213 4098 4388 
f 4386 4282 4213 
f 4066 4214 4200 
f 4134 4275 4215 
f 4215 4199 4134 
f 4216 4147 4338 
f 4147 4216 4337 
f 4220 4217 4124 
f 4151 4067 4218 
f 4135 4218 4067 
f 4085 4219 4173 
f 4219 4085 4265 
f 4220 4112 4346 
f 4220 4303 4112 
f 4246 4221 4083 
f 4221 4183 4083 
f 4059 4222 4219 
f 4230 4353 4370 
f 4036 4223 4074 
f 4118 4074 4334 
f 4167 4224 4159 
f 4224 4167 4263 
f 4225 4265 4292 
f 4127 4226 4225 
f 4127 4045 4226 
f 4253 4056 4227 
f 4056 4247 4227 
f 4299 4172 4228 
f 4094 4228 4265 
f 4229 4090 4164 
f 4191 4229 4164 
f 4089 4174 4230 
f 4230 4370 4089 
f 4044 4160 4231 
f 4375 4044 4231 
f 4385 4232 4087 
f 4291 4087 4232 
f 4233 4057 4291 
f 4233 4343 4057 
f 4234 4086 4097 
f 4086 4234 4304 
f 4222 4235 4120 
f 4311 4120 4235 
f 4236 4316 4069 
f 4236 4069 4293 
f 4206 4237 4075 
f 4075 4237 4231 
f 4082 4238 4061 
f 4306 4238 4153 
f 4051 4371 4239 
f 4239 4338 4051 
f 4359 4240 4172 
f 4240 4101 4285 
f 4241 4072 4148 
f 4140 4313 4055 
f 4313 4140 4097 
f 4107 4039 4243 
f 4315 4107 4243 
f 4118 4244 4074 
f 4118 4368 4244 
f 4172 4329 4245 
f 4245 4078 4130 
f 4246 4050 4131 
f 4246 4131 4125 
f 4071 4157 4341 
f 4056 4071 4247 
f 4176 4248 4080 
f 4248 4170 4080 
f 4189 4393 4249 
f 4393 4088 4249 
f 4296 4250 4160 
f 4160 4250 4231 
f 4048 4251 4154 
f 4251 4048 4079 
f 4365 4252 4168 
f 4168 4252 4070 
f 4253 4290 4289 
f 4138 4182 4254 
f 4378 4042 4138 
f 4255 4367 4138 
f 4294 4090 4255 
f 4256 4135 4091 
f 4256 4091 4330 
f 4257 4133 4173 
f 4216 4133 4257 
f 4258 4113 4204 
f 4113 4258 4082 
f 4081 4301 4259 
f 4250 4081 4259 
f 4326 4136 4325 
f 4136 4260 4037 
f 4261 4055 4200 
f 4055 4261 4140 
f 4262 4106 4310 
f 4167 4109 4263 
f 4063 4263 4109 
f 4111 4079 4264 
f 4079 4197 4264 
f 4059 4265 4110 
f 4110 4265 4228 
f 4266 4088 4201 
f 4201 4290 4266 
f 4070 4267 4221 
f 4267 4070 4166 
f 4171 4054 4268 
f 4097 4269 4234 
f 4140 4269 4097 
f 4091 4362 4270 
f 4050 4246 4270 
f 4152 4104 4271 
f 4271 4104 4057 
f 4248 4096 4272 
f 4335 4052 4272 
f 4273 4369 4177 
f 4177 4036 4273 
f 4243 4039 4274 
f 4052 4275 4134 
f 4276 4102 4208 
f 4344 4102 4276 
f 4076 4277 4202 
f 4277 4076 4210 
f 4278 4082 4258 
f 4082 4278 4238 
f 4053 4279 4205 
f 4279 4053 4156 
f 4133 4065 4280 
f 4324 4280 4065 
f 4073 4281 4187 
f 4098 4282 4149 
f 4098 4213 4282 
f 4139 4283 4162 
f 4283 4139 4206 
f 4165 4132 4284 
f 4230 4284 4132 
f 4154 4332 4285 
f 4101 4357 4285 
f 4239 4286 4099 
f 4099 4286 4216 
f 4105 4182 4287 
f 4287 4212 4105 
f 4288 4066 4273 
f 4108 4289 4129 
f 4253 4289 4108 
f 4224 4290 4159 
f 4227 4159 4290 
f 4087 4291 4188 
f 4104 4188 4291 
f 4292 4127 4225 
f 4085 4127 4292 
f 4077 4293 4194 
f 4190 4293 4077 
f 4090 4294 4164 
f 4164 4294 4337 
f 4158 4295 4372 
f 4295 4158 4058 
f 4296 4093 4068 
f 4151 4041 4297 
f 4175 4340 4298 
f 4388 4298 4213 
f 4228 4094 4299 
f 4043 4299 4094 
f 4300 4146 4116 
f 4300 4116 4237 
f 4121 4301 4049 
f 4049 4301 4319 
f 4142 4180 4302 
f 4142 4302 4076 
f 4389 4183 4303 
f 4303 4183 4112 
f 4391 4197 4304 
f 4234 4137 4304 
f 4163 4063 4305 
f 4305 4063 4195 
f 4061 4238 4306 
f 4306 4181 4381 
f 4046 4307 4180 
f 4244 4180 4307 
f 4060 4123 4308 
f 4308 4123 4379 
f 4309 4036 4074 
f 4262 4310 4119 
f 4064 4119 4310 
f 4198 4062 4311 
f 4120 4311 4062 
f 4111 4191 4312 
f 4164 4312 4191 
f 4242 4369 4313 
f 4097 4242 4313 
f 4043 4314 4178 
f 4315 4084 4277 
f 4315 4215 4084 
f 4316 4236 4192 
f 4279 4316 4192 
f 4173 4317 4257 
f 4317 4173 4219 
f 4318 4113 4082 
f 4328 4113 4318 
f 4319 4155 4211 
f 4155 4319 4123 
f 4120 4320 4317 
f 4062 4320 4120 
f 4321 4199 4243 
f 4199 4321 4093 
f 4109 4167 4322 
f 4322 4167 4159 
f 4271 4080 4323 
f 4347 4323 4080 
f 4045 4280 4324 
f 4045 4324 4106 
f 4047 4187 4325 
f 4136 4326 4260 
f 4260 4326 4037 
f 4088 4327 4048 
f 4048 4327 4163 
f 4144 4328 4213 
f 4113 4328 4144 
f 4178 4078 4329 
f 4078 4245 4329 
f 4256 4330 4218 
f 4151 4218 4330 
f 4331 4072 4038 
f 4331 4038 4119 
f 4332 4089 4172 
f 4089 4332 4251 
f 4148 4333 4241 
f 4333 4148 4282 
f 4334 4223 4207 
f 4223 4334 4074 
f 4096 4335 4272 
f 4336 4391 4194 
f 4336 4194 4339 
f 4337 4198 4164 
f 4062 4198 4337 
f 4143 4051 4338 
f 4338 4383 4143 
f 4212 4339 4156 
f 4339 4279 4156 
f 4064 4184 4340 
f 4064 4340 4175 
f 4341 4247 4071 
f 4159 4247 4341 
f 4195 4086 4342 
f 4086 4304 4342 
f 4205 4192 4343 
f 4343 4126 4205 
f 4344 4276 4145 
f 4145 4278 4344 
f 4165 4174 4345 
f 4089 4345 4174 
f 4346 4374 4211 
f 4346 4211 4217 
f 4347 4080 4170 
f 4170 4356 4347 
f 4348 4187 4128 
f 4348 4128 4372 
f 4233 4349 4126 
f 4126 4349 4208 
f 4350 4200 4214 
f 4350 4214 4248 
f 4125 4351 4206 
f 4351 4125 4131 
f 4352 4259 4203 
f 4250 4259 4352 
f 4222 4353 4235 
f 4353 4222 4059 
f 4270 4354 4091 
f 4158 4091 4354 
f 4185 4355 4331 
f 4355 4185 4282 
f 4134 4356 4052 
f 4134 4199 4356 
f 4357 4154 4285 
f 4154 4357 4088 
f 4131 4358 4169 
f 4181 4169 4358 
f 4240 4359 4101 
f 4101 4359 4249 
f 4360 4123 4060 
f 4361 4298 4103 
f 4361 4103 4204 
f 4181 4050 4362 
f 4050 4270 4362 
f 4293 4190 4363 
f 4190 4261 4363 
f 4283 4364 4162 
f 4162 4364 4070 
f 4168 4095 4365 
f 4049 4365 4095 
f 4160 4366 4296 
f 4044 4366 4160 
f 4090 4229 4367 
f 4367 4255 4090 
f 4368 4171 4210 
f 4382 4171 4368 
f 4186 4369 4376 
f 4186 4177 4369 
f 4059 4110 4370 
f 4353 4059 4370 
f 4371 4204 4103 
f 4204 4371 4258 
f 4151 4372 4100 
f 4100 4372 4128 
f 4373 4115 4212 
f 4115 4373 4191 
f 4346 4166 4374 
f 4070 4374 4166 
f 4375 4237 4116 
f 4375 4116 4044 
f 4376 4109 4186 
f 4109 4376 4195 
f 4100 4150 4377 
f 4378 4254 4102 
f 4254 4378 4138 
f 4081 4250 4379 
f 4081 4379 4319 
f 4141 4250 4380 
f 4082 4061 4381 
f 4306 4381 4061 
f 4054 4382 4157 
f 4157 4382 4118 
f 4255 4042 4383 
f 4383 4042 4378 
f 4182 4105 4384 
f 4053 4384 4105 
f 4087 4153 4385 
f 4385 4153 4238 
f 4386 4135 4282 
f 4114 4135 4386 
f 4387 4079 4111 
f 4079 4387 4345 
f 4149 4175 4388 
f 4298 4388 4175 
f 4389 4348 4058 
f 4183 4389 4058 
f 4390 4234 4269 
f 4234 4390 4137 
f 4137 4194 4391 
f 4304 4137 4391 
f 4350 4092 4392 
f 4192 4392 4092 
f 4393 4189 4129 
f 4088 4393 4129 
f 4130 4249 4394 
f 4359 4130 4394 
f 4673 4479 4966 
f 4599 4604 4514 
f 4933 4587 4704 
f 4456 4839 4438 
f 4586 4706 4744 
f 4518 4687 4636 
f 4773 4592 4770 
f 4464 4781 4631 
f 4708 4724 4428 
f 4877 4648 4764 
f 4687 4518 4742 
f 4635 4569 4601 
f 4704 4587 4512 
f 4547 4640 4632 
f 4879 4727 4491 
f 4515 4574 4831 
f 4874 4606 4803 
f 4576 4706 4743 
f 4643 4644 4544 
f 4839 4711 4438 
f 4782 4853 4647 
f 4743 4662 4471 
f 4723 4593 4969 
f 4626 4658 4755 
f 4444 4519 4747 
f 4959 4615 4656 
f 4481 4753 4778 
f 4603 4867 4776 
f 4607 4758 4949 
f 4956 4472 4807 
f 4698 4807 4859 
f 4843 4787 4643 
f 4790 4721 4676 
f 4433 4825 4557 
f 4659 4813 4691 
f 4781 4543 4820 
f 4511 4646 4486 
f 4699 4809 4581 
f 4486 4866 4511 
f 4965 4506 4748 
f 4580 4537 4629 
f 4590 4963 4411 
f 4758 4607 4561 
f 4459 4672 4531 
f 4536 4945 4602 
f 4406 4577 4500 
f 4889 4719 4596 
f 4851 4663 4911 
f 4856 4405 4421 
f 4474 4706 4586 
f 4594 4939 4454 
f 4639 4805 4833 
f 4501 4888 4423 
f 4774 4872 4840 
f 4837 4668 4603 
f 4869 4768 4665 
f 4864 4582 4612 
f 4643 4652 4788 
f 4967 4631 4781 
f 4871 4696 4881 
f 4806 4428 4724 
f 4602 4649 4559 
f 4623 4901 4681 
f 4405 4780 4664 
f 4789 4934 4845 
f 4766 4705 4632 
f 4912 4910 4740 
f 4910 4912 4791 
f 4782 4793 4660 
f 4581 4809 4940 
f 4466 4513 4529 
f 4600 4813 4923 
f 4523 4419 4814 
f 4528 4492 4840 
f 4620 4592 4527 
f 4709 4738 4483 
f 4769 4858 4817 
f 4742 4460 4648 
f 4754 4650 4603 
f 4739 4778 4528 
f 4555 4624 4630 
f 4702 4609 4703 
f 4658 4564 4439 
f 4816 4666 4625 
f 4818 4666 4619 
f 4801 4559 4649 
f 4767 4868 4811 
f 4399 4925 4498 
f 4636 4500 4577 
f 4575 4443 4865 
f 4416 4750 4826 
f 4784 4664 4545 
f 4784 4810 4726 
f 4573 4804 4835 
f 4763 4400 4718 
f 4824 4669 4434 
f 4638 4492 4824 
f 4937 4701 4621 
f 4558 4701 4478 
f 4503 4818 4619 
f 4716 4820 4897 
f 4793 4782 4671 
f 4566 4913 4522 
f 4522 4797 4720 
f 4558 4478 4876 
f 4869 4729 4832 
f 4452 4578 4762 
f 4628 4752 4849 
f 4813 4659 4453 
f 4951 4962 4564 
f 4878 4454 4759 
f 4684 4505 4920 
f 4680 4899 4605 
f 4630 4614 4555 
f 4547 4632 4705 
f 4693 4530 4745 
f 4665 4457 4854 
f 4499 4488 4848 
f 4447 4415 4621 
f 4924 4778 4753 
f 4482 4572 4627 
f 4506 4965 4580 
f 4681 4662 4623 
f 4563 4419 4536 
f 4776 4584 4852 
f 4454 4769 4594 
f 4707 4511 4402 
f 4403 4683 4790 
f 4775 4790 4676 
f 4738 4692 4483 
f 4657 4416 4826 
f 4604 4599 4682 
f 4596 4714 4889 
f 4741 4466 4529 
f 4915 4765 4466 
f 4728 4484 4595 
f 4597 4522 4913 
f 4506 4805 4748 
f 4468 4947 4423 
f 4908 4398 4503 
f 4676 4861 4890 
f 4609 4557 4703 
f 4927 4593 4723 
f 4681 4399 4751 
f 4936 4430 4576 
f 4740 4413 4561 
f 4712 4413 4884 
f 4731 4473 4415 
f 4415 4473 4621 
f 4743 4935 4761 
f 4759 4474 4878 
f 4802 4708 4428 
f 4710 4880 4801 
f 4636 4958 4925 
f 4828 4491 4727 
f 4659 4570 4453 
f 4823 4477 4535 
f 4567 4938 4733 
f 4582 4663 4612 
f 4818 4625 4666 
f 4762 4956 4452 
f 4556 4458 4697 
f 4886 4848 4556 
f 4778 4739 4481 
f 4963 4590 4930 
f 4572 4668 4794 
f 4668 4493 4794 
f 4763 4696 4400 
f 4905 4631 4847 
f 4785 4860 4598 
f 4840 4872 4528 
f 4589 4598 4450 
f 4589 4551 4598 
f 4620 4738 4435 
f 4416 4713 4779 
f 4487 4942 4940 
f 4804 4573 4487 
f 4622 4703 4557 
f 4439 4755 4658 
f 4752 4628 4698 
f 4791 4756 4910 
f 4421 4803 4525 
f 4656 4822 4570 
f 4574 4841 4828 
f 4655 4491 4735 
f 4412 4641 4637 
f 4583 4627 4730 
f 4638 4493 4774 
f 4882 4678 4903 
f 4837 4603 4650 
f 4611 4821 4651 
f 4900 4748 4805 
f 4402 4495 4707 
f 4496 4918 4455 
f 4455 4714 4644 
f 4578 4452 4690 
f 4428 4806 4893 
f 4780 4405 4856 
f 4751 4429 4471 
f 4886 4665 4854 
f 4886 4499 4848 
f 4465 4771 4596 
f 4500 4636 4687 
f 4865 4855 4575 
f 4646 4888 4501 
f 4710 4689 4441 
f 4811 4689 4767 
f 4448 4933 4704 
f 4448 4704 4796 
f 4959 4656 4645 
f 4789 4645 4519 
f 4817 4858 4505 
f 4505 4858 4920 
f 4639 4833 4897 
f 4580 4629 4833 
f 4903 4948 4651 
f 4678 4882 4815 
f 4685 4566 4508 
f 4674 4887 4450 
f 4585 4834 4402 
f 4402 4834 4495 
f 4402 4511 4594 
f 4462 4511 4866 
f 4413 4712 4512 
f 4625 4818 4712 
f 4906 4612 4663 
f 4953 4749 4768 
f 4436 4742 4648 
f 4708 4802 4514 
f 4831 4406 4515 
f 4630 4624 4711 
f 4816 4479 4422 
f 4422 4474 4799 
f 4641 4412 4795 
f 4824 4857 4669 
f 4518 4901 4623 
f 4460 4742 4518 
f 4735 4841 4404 
f 4519 4404 4789 
f 4777 4728 4907 
f 4520 4597 4608 
f 4424 4837 4650 
f 4424 4941 4902 
f 4627 4523 4814 
f 4459 4531 4737 
f 4605 4496 4426 
f 4588 4525 4928 
f 4525 4803 4606 
f 4606 4526 4430 
f 4830 4845 4766 
f 4601 4569 4809 
f 4462 4866 4792 
f 4695 4860 4437 
f 4528 4872 4860 
f 4693 4745 4600 
f 4433 4529 4513 
f 4529 4654 4741 
f 4765 4591 4530 
f 4868 4767 4531 
f 4921 4767 4523 
f 4642 4772 4565 
f 4729 4894 4532 
f 4475 4534 4413 
f 4413 4758 4561 
f 4722 4784 4726 
f 4470 4667 4927 
f 4536 4617 4411 
f 4411 4563 4536 
f 4537 4660 4629 
f 4759 4935 4474 
f 4881 4770 4592 
f 4718 4835 4763 
f 4897 4449 4716 
f 4647 4671 4782 
f 4540 4839 4456 
f 4547 4862 4610 
f 4401 4416 4779 
f 4855 4634 4541 
f 4807 4472 4859 
f 4413 4740 4884 
f 4416 4657 4692 
f 4781 4464 4543 
f 4714 4596 4420 
f 4787 4843 4682 
f 4545 4842 4879 
f 4444 4726 4810 
f 4958 4961 4546 
f 4842 4545 4664 
f 4547 4610 4414 
f 4861 4414 4890 
f 4725 4548 4746 
f 4944 4550 4768 
f 4732 4567 4863 
f 4550 4944 4661 
f 4551 4589 4850 
f 4551 4688 4717 
f 4860 4785 4829 
f 4954 4829 4785 
f 4447 4621 4701 
f 4715 4553 4571 
f 4553 4447 4838 
f 4447 4553 4558 
f 4873 4397 4736 
f 4680 4555 4899 
f 4731 4846 4473 
f 4480 4819 4875 
f 4557 4916 4470 
f 4557 4470 4433 
f 4558 4415 4447 
f 4869 4665 4886 
f 4904 4893 4559 
f 4880 4710 4904 
f 4952 4826 4443 
f 4560 4865 4443 
f 4431 4561 4607 
f 4740 4561 4912 
f 4717 4909 4551 
f 4785 4552 4954 
f 4563 4411 4963 
f 4827 4776 4852 
f 4439 4564 4702 
f 4490 4421 4822 
f 4451 4532 4760 
f 4729 4565 4772 
f 4565 4729 4869 
f 4876 4733 4938 
f 4674 4844 4566 
f 4720 4566 4522 
f 4450 4508 4836 
f 4913 4566 4844 
f 4768 4832 4944 
f 4768 4869 4832 
f 4567 4733 4906 
f 4906 4863 4567 
f 4568 4473 4846 
f 4697 4875 4819 
f 4804 4432 4809 
f 4487 4432 4804 
f 4570 4659 4408 
f 4570 4645 4656 
f 4932 4571 4553 
f 4715 4964 4838 
f 4922 4434 4669 
f 4637 4922 4412 
f 4835 4718 4573 
f 4647 4853 4445 
f 4839 4540 4896 
f 4841 4574 4633 
f 4443 4575 4468 
f 4468 4575 4947 
f 4471 4576 4743 
f 4429 4576 4471 
f 4476 4831 4574 
f 4958 4636 4577 
f 4690 4943 4578 
f 4673 4966 4497 
f 4887 4674 4798 
f 4566 4685 4674 
f 4965 4407 4580 
f 4662 4743 4761 
f 4940 4942 4581 
f 4454 4581 4759 
f 4864 4488 4582 
f 4582 4911 4663 
f 4528 4583 4492 
f 4583 4730 4492 
f 4814 4482 4627 
f 4584 4776 4867 
f 4436 4510 4585 
f 4817 4505 4436 
f 4799 4516 4422 
f 4586 4463 4683 
f 4652 4643 4783 
f 4512 4587 4413 
f 4914 4588 4429 
f 4780 4498 4546 
f 4797 4650 4754 
f 4589 4410 4485 
f 4590 4756 4791 
f 4590 4481 4739 
f 4530 4591 4409 
f 4466 4741 4915 
f 4592 4871 4881 
f 4435 4592 4620 
f 4535 4477 4593 
f 4691 4593 4870 
f 4939 4594 4699 
f 4462 4594 4511 
f 4595 4651 4821 
f 4821 4728 4595 
f 4500 4596 4719 
f 4596 4757 4465 
f 4902 4522 4597 
f 4777 4597 4728 
f 4484 4728 4597 
f 4597 4520 4902 
f 4844 4598 4913 
f 4774 4484 4598 
f 4475 4968 4904 
f 4682 4599 4787 
f 4600 4691 4813 
f 4600 4529 4969 
f 4773 4601 4618 
f 4770 4601 4773 
f 4849 4690 4898 
f 4602 4559 4893 
f 4603 4963 4930 
f 4754 4603 4930 
f 4604 4771 4465 
f 4742 4604 4465 
f 4605 4524 4680 
f 4605 4426 4524 
f 4588 4928 4936 
f 4430 4936 4928 
f 4672 4607 4811 
f 4653 4441 4607 
f 4521 4777 4907 
f 4734 4608 4521 
f 4405 4609 4702 
f 4667 4470 4916 
f 4456 4610 4862 
f 4950 4611 4493 
f 4493 4882 4774 
f 4906 4733 4612 
f 4864 4612 4937 
f 4838 4932 4553 
f 4964 4786 4554 
f 4714 4455 4889 
f 4899 4555 4614 
f 4874 4526 4606 
f 4526 4615 4504 
f 4908 4616 4398 
f 4617 4489 4756 
f 4740 4910 4892 
f 4618 4699 4792 
f 4618 4417 4773 
f 4676 4800 4775 
f 4503 4619 4908 
f 4713 4416 4620 
f 4866 4486 4620 
f 4864 4848 4488 
f 4697 4458 4621 
f 4755 4439 4911 
f 4911 4582 4755 
f 4518 4623 4919 
f 4919 4623 4931 
f 4736 4624 4873 
f 4711 4624 4438 
f 4762 4966 4479 
f 4816 4625 4479 
f 4488 4499 4626 
f 4854 4923 4813 
f 4627 4583 4924 
f 4737 4627 4459 
f 4807 4452 4956 
f 4898 4628 4849 
f 4793 4812 4418 
f 4833 4629 4897 
f 4614 4630 4926 
f 4926 4630 4515 
f 4400 4847 4631 
f 4631 4967 4400 
f 4766 4632 4830 
f 4632 4744 4830 
f 4845 4934 4766 
f 4633 4404 4841 
f 4541 4634 4401 
f 4560 4401 4634 
f 4770 4881 4427 
f 4569 4635 4835 
f 4518 4636 4901 
f 4925 4399 4636 
f 4637 4492 4730 
f 4492 4637 4641 
f 4493 4638 4794 
f 4434 4638 4824 
f 4443 4805 4639 
f 4820 4952 4443 
f 4463 4790 4683 
f 4640 4946 4632 
f 4641 4517 4492 
f 4517 4641 4686 
f 4955 4642 4532 
f 4642 4760 4532 
f 4643 4413 4783 
f 4413 4643 4787 
f 4652 4496 4788 
f 4455 4644 4496 
f 4726 4444 4747 
f 4645 4570 4408 
f 4541 4401 4779 
f 4501 4541 4646 
f 4781 4647 4967 
f 4671 4647 4539 
f 4510 4436 4648 
f 4648 4877 4510 
f 4536 4419 4945 
f 4649 4419 4502 
f 4650 4797 4522 
f 4650 4941 4424 
f 4651 4507 4611 
f 4507 4651 4948 
f 4808 4426 4652 
f 4496 4652 4426 
f 4441 4653 4710 
f 4534 4475 4653 
f 4745 4530 4654 
f 4409 4654 4530 
f 4655 4735 4444 
f 4444 4810 4879 
f 4490 4656 4615 
f 4490 4822 4656 
f 4657 4952 4464 
f 4657 4826 4952 
f 4951 4453 4962 
f 4626 4453 4658 
f 4477 4408 4659 
f 4691 4870 4659 
f 4396 4660 4537 
f 4660 4942 4487 
f 4725 4661 4944 
f 4891 4550 4661 
f 4662 4761 4407 
f 4662 4931 4623 
f 4825 4433 4513 
f 4953 4906 4663 
f 4546 4961 4664 
f 4546 4664 4780 
f 4749 4665 4768 
f 4665 4749 4457 
f 4666 4816 4403 
f 4775 4619 4666 
f 4664 4784 4405 
f 4916 4405 4667 
f 4668 4867 4603 
f 4572 4482 4668 
f 4412 4922 4669 
f 4412 4669 4795 
f 4961 4476 4670 
f 4670 4476 4574 
f 4449 4671 4539 
f 4812 4793 4671 
f 4672 4459 4753 
f 4607 4672 4431 
f 4878 4920 4858 
f 4479 4673 4422 
f 4579 4798 4674 
f 4917 4674 4685 
f 4894 4729 4772 
f 4532 4533 4955 
f 4469 4800 4676 
f 4469 4676 4890 
f 4591 4765 4915 
f 4677 4409 4591 
f 4948 4903 4678 
f 4815 4494 4678 
f 4929 4679 4552 
f 4552 4679 4954 
f 4680 4397 4873 
f 4397 4680 4524 
f 4471 4681 4751 
f 4471 4662 4681 
f 4682 4420 4604 
f 4420 4682 4843 
f 4683 4403 4516 
f 4516 4799 4683 
f 4920 4497 4684 
f 4806 4943 4395 
f 4685 4509 4917 
f 4887 4509 4685 
f 4857 4795 4669 
f 4446 4517 4686 
f 4742 4465 4687 
f 4465 4757 4687 
f 4688 4485 4562 
f 4485 4850 4589 
f 4767 4689 4502 
f 4801 4689 4710 
f 4690 4395 4943 
f 4690 4602 4395 
f 4691 4600 4969 
f 4593 4691 4969 
f 4905 4483 4692 
f 4464 4692 4657 
f 4693 4457 4530 
f 4693 4854 4457 
f 4694 4568 4846 
f 4875 4694 4480 
f 4528 4860 4695 
f 4528 4695 4739 
f 4696 4483 4400 
f 4709 4483 4696 
f 4473 4568 4697 
f 4621 4473 4697 
f 4489 4617 4698 
f 4752 4698 4617 
f 4957 4792 4699 
f 4462 4699 4594 
f 4417 4700 4773 
f 4866 4700 4417 
f 4553 4715 4701 
f 4701 4558 4553 
f 4421 4702 4564 
f 4702 4421 4405 
f 4703 4622 4439 
f 4439 4702 4703 
f 4512 4503 4704 
f 4796 4704 4503 
f 4862 4547 4705 
f 4705 4896 4540 
f 4706 4576 4430 
f 4830 4744 4706 
f 4900 4707 4495 
f 4423 4888 4707 
f 4436 4960 4514 
f 4960 4436 4724 
f 4738 4709 4435 
f 4696 4871 4709 
f 4710 4653 4475 
f 4904 4710 4475 
f 4630 4711 4515 
f 4515 4711 4839 
f 4956 4712 4472 
f 4712 4440 4625 
f 4713 4486 4779 
f 4620 4486 4713 
f 4544 4644 4714 
f 4714 4420 4843 
f 4838 4447 4715 
f 4701 4715 4447 
f 4449 4883 4716 
f 4781 4539 4647 
f 4562 4679 4929 
f 4562 4717 4688 
f 4718 4400 4967 
f 4445 4573 4718 
f 4406 4926 4515 
f 4500 4719 4406 
f 4720 4589 4450 
f 4450 4836 4720 
f 4676 4721 4861 
f 4547 4721 4640 
f 4535 4722 4823 
f 4722 4535 4667 
f 4470 4723 4433 
f 4927 4723 4470 
f 4436 4505 4724 
f 4724 4505 4684 
f 4549 4661 4725 
f 4746 4891 4725 
f 4726 4823 4722 
f 4823 4726 4645 
f 4670 4727 4442 
f 4727 4879 4842 
f 4728 4467 4520 
f 4467 4728 4821 
f 4532 4938 4729 
f 4938 4567 4729 
f 4730 4572 4637 
f 4730 4627 4572 
f 4556 4731 4886 
f 4819 4846 4731 
f 4567 4732 4548 
f 4732 4746 4548 
f 4876 4478 4733 
f 4733 4478 4612 
f 4520 4907 4728 
f 4608 4734 4520 
f 4519 4444 4735 
f 4404 4519 4735 
f 4624 4736 4438 
f 4523 4737 4921 
f 4627 4737 4523 
f 4416 4738 4620 
f 4692 4738 4416 
f 4437 4739 4695 
f 4739 4437 4590 
f 4740 4542 4472 
f 4740 4892 4542 
f 4409 4741 4654 
f 4677 4741 4409 
f 4436 4514 4742 
f 4514 4604 4742 
f 4935 4743 4474 
f 4743 4706 4474 
f 4632 4946 4744 
f 4744 4463 4586 
f 4529 4600 4745 
f 4654 4529 4745 
f 4550 4891 4746 
f 4746 4732 4550 
f 4645 4747 4519 
f 4747 4645 4726 
f 4900 4495 4748 
f 4495 4510 4877 
f 4765 4749 4466 
f 4953 4466 4749 
f 4750 4416 4401 
f 4401 4560 4750 
f 4498 4751 4399 
f 4429 4751 4914 
f 4536 4752 4617 
f 4536 4849 4752 
f 4481 4912 4753 
f 4672 4753 4912 
f 4754 4437 4410 
f 4589 4754 4410 
f 4626 4755 4488 
f 4488 4755 4582 
f 4411 4756 4590 
f 4756 4411 4617 
f 4757 4500 4687 
f 4500 4757 4596 
f 4534 4758 4413 
f 4949 4758 4534 
f 4942 4759 4581 
f 4537 4759 4396 
f 4565 4760 4642 
f 4760 4565 4451 
f 4580 4407 4761 
f 4761 4537 4580 
f 4479 4895 4762 
f 4762 4895 4956 
f 4763 4881 4696 
f 4835 4538 4763 
f 4648 4460 4764 
f 4919 4407 4965 
f 4765 4457 4749 
f 4765 4530 4457 
f 4766 4404 4633 
f 4705 4766 4633 
f 4419 4767 4502 
f 4419 4523 4767 
f 4768 4425 4953 
f 4768 4550 4425 
f 4594 4769 4402 
f 4769 4585 4402 
f 4427 4635 4770 
f 4601 4770 4635 
f 4596 4771 4420 
f 4604 4420 4771 
f 4772 4642 4955 
f 4894 4772 4675 
f 4527 4773 4700 
f 4592 4773 4527 
f 4774 4595 4484 
f 4903 4774 4882 
f 4666 4403 4775 
f 4790 4775 4403 
f 4776 4461 4603 
f 4461 4776 4827 
f 4521 4608 4777 
f 4608 4597 4777 
f 4583 4528 4778 
f 4778 4924 4583 
f 4779 4486 4646 
f 4779 4646 4541 
f 4588 4914 4780 
f 4856 4588 4780 
f 4781 4716 4539 
f 4716 4781 4820 
f 4782 4487 4853 
f 4487 4782 4660 
f 4587 4933 4783 
f 4587 4783 4413 
f 4722 4667 4784 
f 4784 4667 4405 
f 4551 4785 4598 
f 4785 4551 4909 
f 4786 4613 4554 
f 4932 4786 4571 
f 4787 4475 4413 
f 4599 4475 4787 
f 4788 4496 4644 
f 4643 4788 4644 
f 4845 4504 4789 
f 4645 4789 4959 
f 4640 4721 4790 
f 4463 4640 4790 
f 4791 4481 4590 
f 4481 4791 4912 
f 4417 4792 4866 
f 4417 4618 4792 
f 4793 4418 4629 
f 4793 4629 4660 
f 4434 4572 4794 
f 4638 4434 4794 
f 4795 4686 4641 
f 4795 4446 4686 
f 4398 4796 4503 
f 4589 4720 4797 
f 4754 4589 4797 
f 4798 4917 4509 
f 4798 4509 4887 
f 4586 4799 4474 
f 4683 4799 4586 
f 4775 4800 4619 
f 4908 4619 4800 
f 4649 4502 4801 
f 4689 4801 4502 
f 4475 4599 4968 
f 4802 4599 4514 
f 4803 4490 4874 
f 4803 4421 4490 
f 4835 4804 4569 
f 4809 4569 4804 
f 4443 4468 4805 
f 4900 4805 4468 
f 4724 4684 4806 
f 4497 4806 4684 
f 4452 4807 4628 
f 4807 4698 4628 
f 4448 4808 4652 
f 4809 4618 4601 
f 4618 4809 4699 
f 4879 4810 4545 
f 4545 4810 4784 
f 4689 4811 4441 
f 4441 4811 4607 
f 4671 4449 4812 
f 4897 4812 4449 
f 4813 4453 4626 
f 4854 4813 4626 
f 4584 4482 4814 
f 4814 4852 4584 
f 4507 4815 4882 
f 4948 4815 4507 
f 4516 4403 4816 
f 4816 4422 4516 
f 4817 4585 4769 
f 4436 4585 4817 
f 4818 4512 4712 
f 4503 4512 4818 
f 4731 4556 4819 
f 4819 4556 4697 
f 4897 4820 4639 
f 4639 4820 4443 
f 4821 4424 4467 
f 4821 4611 4950 
f 4822 4564 4962 
f 4564 4822 4421 
f 4477 4823 4408 
f 4645 4408 4823 
f 4824 4492 4517 
f 4517 4446 4824 
f 4513 4663 4825 
f 4851 4825 4663 
f 4826 4560 4443 
f 4750 4560 4826 
f 4827 4563 4461 
f 4563 4827 4419 
f 4574 4828 4670 
f 4727 4670 4828 
f 4562 4485 4829 
f 4860 4829 4485 
f 4706 4430 4830 
f 4526 4830 4430 
f 4577 4406 4831 
f 4577 4831 4476 
f 4832 4729 4567 
f 4567 4548 4832 
f 4580 4833 4506 
f 4833 4805 4506 
f 4585 4510 4834 
f 4834 4510 4495 
f 4538 4835 4427 
f 4427 4835 4635 
f 4566 4720 4836 
f 4836 4508 4566 
f 4493 4668 4837 
f 4837 4950 4493 
f 4613 4838 4554 
f 4554 4838 4964 
f 4574 4839 4896 
f 4839 4574 4515 
f 4840 4492 4638 
f 4774 4840 4638 
f 4841 4735 4491 
f 4491 4828 4841 
f 4842 4442 4727 
f 4442 4842 4664 
f 4544 4843 4643 
f 4843 4544 4714 
f 4450 4844 4674 
f 4450 4598 4844 
f 4830 4526 4845 
f 4504 4845 4526 
f 4694 4846 4480 
f 4846 4819 4480 
f 4905 4847 4483 
f 4847 4400 4483 
f 4848 4864 4458 
f 4458 4556 4848 
f 4602 4849 4536 
f 4602 4690 4849 
f 4485 4688 4850 
f 4688 4551 4850 
f 4851 4622 4557 
f 4851 4557 4825 
f 4852 4814 4419 
f 4827 4852 4419 
f 4573 4853 4487 
f 4573 4445 4853 
f 4499 4886 4854 
f 4626 4499 4854 
f 4501 4575 4855 
f 4541 4501 4855 
f 4421 4525 4856 
f 4588 4856 4525 
f 4857 4824 4446 
f 4795 4857 4446 
f 4858 4454 4878 
f 4769 4454 4858 
f 4859 4472 4542 
f 4892 4859 4542 
f 4410 4437 4860 
f 4485 4410 4860 
f 4547 4414 4861 
f 4721 4547 4861 
f 4862 4540 4456 
f 4540 4862 4705 
f 4425 4550 4863 
f 4732 4863 4550 
f 4937 4621 4864 
f 4621 4458 4864 
f 4865 4560 4634 
f 4634 4855 4865 
f 4527 4700 4866 
f 4527 4866 4620 
f 4584 4867 4482 
f 4668 4482 4867 
f 4672 4811 4868 
f 4531 4672 4868 
f 4869 4558 4565 
f 4869 4886 4558 
f 4659 4870 4477 
f 4593 4477 4870 
f 4435 4709 4871 
f 4592 4435 4871 
f 4872 4774 4598 
f 4860 4872 4598 
f 4873 4555 4680 
f 4873 4624 4555 
f 4615 4526 4874 
f 4615 4874 4490 
f 4875 4697 4568 
f 4694 4875 4568 
f 4876 4565 4558 
f 4876 4451 4565 
f 4877 4764 4748 
f 4877 4748 4495 
f 4422 4878 4474 
f 4878 4422 4673 
f 4879 4655 4444 
f 4491 4655 4879 
f 4559 4801 4880 
f 4559 4880 4904 
f 4427 4881 4538 
f 4538 4881 4763 
f 4611 4507 4882 
f 4882 4493 4611 
f 4539 4883 4449 
f 4539 4716 4883 
f 4472 4884 4740 
f 4472 4712 4884 
f 4907 4885 4521 
f 4521 4885 4734 
f 4415 4558 4886 
f 4415 4886 4731 
f 4508 4887 4685 
f 4508 4450 4887 
f 4888 4646 4511 
f 4511 4707 4888 
f 4889 4455 4614 
f 4889 4614 4926 
f 4469 4890 4616 
f 4414 4616 4890 
f 4549 4725 4891 
f 4661 4549 4891 
f 4698 4892 4489 
f 4859 4892 4698 
f 4893 4395 4602 
f 4395 4893 4806 
f 4675 4533 4894 
f 4533 4532 4894 
f 4625 4440 4895 
f 4895 4479 4625 
f 4633 4896 4705 
f 4896 4633 4574 
f 4897 4418 4812 
f 4629 4418 4897 
f 4452 4628 4898 
f 4898 4690 4452 
f 4899 4614 4455 
f 4899 4455 4918 
f 4468 4423 4900 
f 4707 4900 4423 
f 4901 4636 4399 
f 4399 4681 4901 
f 4902 4520 4467 
f 4902 4467 4424 
f 4651 4595 4903 
f 4774 4903 4595 
f 4893 4904 4428 
f 4428 4904 4968 
f 4631 4905 4464 
f 4905 4692 4464 
f 4863 4906 4425 
f 4906 4953 4425 
f 4734 4885 4907 
f 4907 4520 4734 
f 4800 4469 4908 
f 4616 4908 4469 
f 4717 4552 4909 
f 4909 4552 4785 
f 4910 4489 4892 
f 4910 4756 4489 
f 4911 4439 4622 
f 4622 4851 4911 
f 4672 4912 4431 
f 4431 4912 4561 
f 4484 4597 4913 
f 4484 4913 4598 
f 4498 4780 4914 
f 4914 4751 4498 
f 4677 4915 4741 
f 4915 4677 4591 
f 4405 4916 4609 
f 4557 4609 4916 
f 4917 4798 4579 
f 4674 4917 4579 
f 4899 4918 4605 
f 4918 4496 4605 
f 4919 4764 4460 
f 4518 4919 4460 
f 4878 4673 4920 
f 4497 4920 4673 
f 4921 4737 4531 
f 4921 4531 4767 
f 4572 4434 4922 
f 4922 4637 4572 
f 4600 4923 4693 
f 4854 4693 4923 
f 4753 4459 4924 
f 4924 4459 4627 
f 4925 4546 4498 
f 4546 4925 4958 
f 4719 4926 4406 
f 4889 4926 4719 
f 4927 4667 4535 
f 4593 4927 4535 
f 4928 4606 4430 
f 4606 4928 4525 
f 4717 4562 4929 
f 4552 4717 4929 
f 4437 4930 4590 
f 4754 4930 4437 
f 4931 4407 4919 
f 4407 4931 4662 
f 4786 4932 4613 
f 4932 4838 4613 
f 4652 4783 4933 
f 4652 4933 4448 
f 4934 4789 4404 
f 4404 4766 4934 
f 4935 4759 4537 
f 4935 4537 4761 
f 4429 4936 4576 
f 4936 4429 4588 
f 4478 4937 4612 
f 4478 4701 4937 
f 4532 4451 4938 
f 4451 4876 4938 
f 4454 4939 4581 
f 4699 4581 4939 
f 4487 4940 4432 
f 4432 4940 4809 
f 4650 4522 4941 
f 4522 4902 4941 
f 4759 4942 4396 
f 4660 4396 4942 
f 4497 4578 4943 
f 4806 4497 4943 
f 4944 4832 4548 
f 4944 4548 4725 
f 4649 4602 4945 
f 4419 4649 4945 
f 4463 4744 4946 
f 4640 4463 4946 
f 4575 4501 4947 
f 4501 4423 4947 
f 4815 4948 4494 
f 4678 4494 4948 
f 4653 4949 4534 
f 4607 4949 4653 
f 4950 4424 4821 
f 4837 4424 4950 
f 4453 4951 4658 
f 4951 4564 4658 
f 4820 4543 4952 
f 4464 4952 4543 
f 4466 4953 4513 
f 4663 4513 4953 
f 4829 4954 4562 
f 4679 4562 4954 
f 4533 4675 4955 
f 4955 4675 4772 
f 4440 4956 4895 
f 4956 4440 4712 
f 4957 4462 4792 
f 4699 4462 4957 
f 4476 4961 4958 
f 4577 4476 4958 
f 4789 4504 4959 
f 4615 4959 4504 
f 4708 4514 4960 
f 4724 4708 4960 
f 4442 4961 4670 
f 4961 4442 4664 
f 4570 4962 4453 
f 4962 4570 4822 
f 4963 4461 4563 
f 4963 4603 4461 
f 4964 4715 4571 
f 4571 4786 4964 
f 4965 4764 4919 
f 4748 4764 4965 
f 4966 4762 4578 
f 4578 4497 4966 
f 4967 4445 4718 
f 4445 4967 4647 
f 4968 4802 4428 
f 4802 4968 4599 
f 4969 4433 4723 
f 4529 4433 4969 
f 4970 4988 4986 
f 4971 4970 4986 
f 4972 4971 4986 
f 4973 4972 4986 
f 4974 4973 4986 
f 4975 4974 4986 
f 4976 4975 4986 
f 4977 4976 4986 
f 4978 4977 4986 
f 4979 4978 4986 
f 4980 4979 4986 
f 4981 4980 4986 
f 4982 4981 4986 
f 4983 4982 4986 
f 4984 4983 4986 
f 4985 4984 4986 
f 4989 4985 4986 
f 4988 4989 4986 
f 4990 4991 4987 
f 4991 4992 4987 
f 4992 4993 4987 
f 4993 4994 4987 
f 4994 4995 4987 
f 4995 4996 4987 
f 4996 4997 4987 
f 4997 4998 4987 
f 4998 4999 4987 
f 4999 5000 4987 
f 5000 5001 4987 
f 5001 5002 4987 
f 5002 5003 4987 
f 5003 5004 4987 
f 5004 5005 4987 
f 5005 5006 4987 
f 5006 5007 4987 
f 5007 4990 4987 
f 7044 4988 4970 
f 7044 4970 4971 
f 7044 4971 4972 
f 6987 4972 4973 
f 6987 4973 4974 
f 7090 4974 4975 
f 7090 4975 4976 
f 7090 4976 4977 
f 7090 4977 4978 
f 7097 4978 4979 
f 7097 4979 4980 
f 7097 4980 4981 
f 7008 4981 4982 
f 7008 4982 4983 
f 7128 4983 4984 
f 7128 4984 4985 
f 7044 4985 4989 
f 7044 4989 4988 
f 7044 4991 4990 
f 7044 4992 4991 
f 6987 4993 4992 
f 6987 4994 4993 
f 6987 4995 4994 
f 6987 4996 4995 
f 7090 4997 4996 
f 7090 4998 4997 
f 7090 4999 4998 
f 7090 5000 4999 
f 7097 5001 5000 
f 7097 5002 5001 
f 7008 5003 5002 
f 7008 5004 5003 
f 7128 5005 5004 
f 7128 5006 5005 
f 7044 5007 5006 
f 7044 4990 5007 
f 5008 5026 5024 
f 5009 5008 5024 
f 5010 5009 5024 
f 5011 5010 5024 
f 5012 5011 5024 
f 5013 5012 5024 
f 5014 5013 5024 
f 5015 5014 5024 
f 5016 5015 5024 
f 5017 5016 5024 
f 5018 5017 5024 
f 5019 5018 5024 
f 5020 5019 5024 
f 5021 5020 5024 
f 5022 5021 5024 
f 5023 5022 5024 
f 5027 5023 5024 
f 5026 5027 5024 
f 5028 5029 5025 
f 5029 5030 5025 
f 5030 5031 5025 
f 5031 5032 5025 
f 5032 5033 5025 
f 5033 5034 5025 
f 5034 5035 5025 
f 5035 5036 5025 
f 5036 5037 5025 
f 5037 5038 5025 
f 5038 5039 5025 
f 5039 5040 5025 
f 5040 5041 5025 
f 5041 5042 5025 
f 5042 5043 5025 
f 5043 5044 5025 
f 5044 5045 5025 
f 5045 5028 5025 
f 7024 5026 5008 
f 7024 5008 5009 
f 7024 5009 5010 
f 7024 5010 5011 
f 7024 5011 5012 
f 7024 5012 5013 
f 6983 5013 5014 
f 6983 5014 5015 
f 7134 5015 5016 
f 7134 5016 5017 
f 7061 5017 5018 
f 7061 5018 5019 
f 7061 5019 5020 
f 7061 5020 5021 
f 7061 5021 5022 
f 7061 5022 5023 
f 7024 5023 5027 
f 7024 5027 5026 
f 7024 5029 5028 
f 7024 5030 5029 
f 7024 5031 5030 
f 7024 5032 5031 
f 7024 5033 5032 
f 6983 5034 5033 
f 6983 5035 5034 
f 7134 5036 5035 
f 7134 5037 5036 
f 7134 5038 5037 
f 7061 5039 5038 
f 7061 5040 5039 
f 7061 5041 5040 
f 7061 5042 5041 
f 7061 5043 5042 
f 7061 5044 5043 
f 7024 5045 5044 
f 7024 5028 5045 
f 5046 5064 5062 
f 5047 5046 5062 
f 5048 5047 5062 
f 5049 5048 5062 
f 5050 5049 5062 
f 5051 5050 5062 
f 5052 5051 5062 
f 5053 5052 5062 
f 5054 5053 5062 
f 5055 5054 5062 
f 5056 5055 5062 
f 5057 5056 5062 
f 5058 5057 5062 
f 5059 5058 5062 
f 5060 5059 5062 
f 5061 5060 5062 
f 5065 5061 5062 
f 5064 5065 5062 
f 5066 5067 5063 
f 5067 5068 5063 
f 5068 5069 5063 
f 5069 5070 5063 
f 5070 5071 5063 
f 5071 5072 5063 
f 5072 5073 5063 
f 5073 5074 5063 
f 5074 5075 5063 
f 5075 5076 5063 
f 5076 5077 5063 
f 5077 5078 5063 
f 5078 5079 5063 
f 5079 5080 5063 
f 5080 5081 5063 
f 5081 5082 5063 
f 5082 5083 5063 
f 5083 5066 5063 
f 7042 5064 5046 
f 7042 5046 5047 
f 7042 5047 5048 
f 7042 5048 5049 
f 7042 5049 5050 
f 7042 5050 5051 
f 5084 5051 5052 
f 5084 5052 5053 
f 5084 5053 5054 
f 5084 5054 5055 
f 5084 5055 5056 
f 5084 5056 5057 
f 7147 5057 5058 
f 7147 5058 5059 
f 7147 5059 5060 
f 7147 5060 5061 
f 7042 5061 5065 
f 7042 5065 5064 
f 7042 5067 5066 
f 7042 5068 5067 
f 7042 5069 5068 
f 7042 5070 5069 
f 7042 5071 5070 
f 7042 5072 5071 
f 5084 5073 5072 
f 5084 5074 5073 
f 5084 5075 5074 
f 5084 5076 5075 
f 5084 5077 5076 
f 7147 5078 5077 
f 7147 5079 5078 
f 7147 5080 5079 
f 7147 5081 5080 
f 7042 5082 5081 
f 7042 5083 5082 
f 7042 5066 5083 
f 5085 5103 5101 
f 5086 5085 5101 
f 5087 5086 5101 
f 5088 5087 5101 
f 5089 5088 5101 
f 5090 5089 5101 
f 5091 5090 5101 
f 5092 5091 5101 
f 5093 5092 5101 
f 5094 5093 5101 
f 5095 5094 5101 
f 5096 5095 5101 
f 5097 5096 5101 
f 5098 5097 5101 
f 5099 5098 5101 
f 5100 5099 5101 
f 5104 5100 5101 
f 5103 5104 5101 
f 5105 5106 5102 
f 5106 5107 5102 
f 5107 5108 5102 
f 5108 5109 5102 
f 5109 5110 5102 
f 5110 5111 5102 
f 5111 5112 5102 
f 5112 5113 5102 
f 5113 5114 5102 
f 5114 5115 5102 
f 5115 5116 5102 
f 5116 5117 5102 
f 5117 5118 5102 
f 5118 5119 5102 
f 5119 5120 5102 
f 5120 5121 5102 
f 5121 5122 5102 
f 5122 5105 5102 
f 6964 5103 5085 
f 6964 5085 5086 
f 6964 5086 5087 
f 6964 5087 5088 
f 7145 5088 5089 
f 7145 5089 5090 
f 7145 5090 5091 
f 7145 5091 5092 
f 7066 5092 5093 
f 7066 5093 5094 
f 7066 5094 5095 
f 7066 5095 5096 
f 7066 5096 5097 
f 6964 5097 5098 
f 6964 5098 5099 
f 6964 5099 5100 
f 6964 5100 5104 
f 6964 5104 5103 
f 6964 5106 5105 
f 6964 5107 5106 
f 6964 5108 5107 
f 7145 5109 5108 
f 7145 5110 5109 
f 7145 5111 5110 
f 7145 5112 5111 
f 7066 5113 5112 
f 7066 5114 5113 
f 7066 5115 5114 
f 7066 5116 5115 
f 7066 5117 5116 
f 7066 5118 5117 
f 6964 5119 5118 
f 6964 5120 5119 
f 6964 5121 5120 
f 6964 5122 5121 
f 6964 5105 5122 
f 5123 5141 5139 
f 5124 5123 5139 
f 5125 5124 5139 
f 5126 5125 5139 
f 5127 5126 5139 
f 5128 5127 5139 
f 5129 5128 5139 
f 5130 5129 5139 
f 5131 5130 5139 
f 5132 5131 5139 
f 5133 5132 5139 
f 5134 5133 5139 
f 5135 5134 5139 
f 5136 5135 5139 
f 5137 5136 5139 
f 5138 5137 5139 
f 5142 5138 5139 
f 5141 5142 5139 
f 5143 5144 5140 
f 5144 5145 5140 
f 5145 5146 5140 
f 5146 5147 5140 
f 5147 5148 5140 
f 5148 5149 5140 
f 5149 5150 5140 
f 5150 5151 5140 
f 5151 5152 5140 
f 5152 5153 5140 
f 5153 5154 5140 
f 5154 5155 5140 
f 5155 5156 5140 
f 5156 5157 5140 
f 5157 5158 5140 
f 5158 5159 5140 
f 5159 5160 5140 
f 5160 5143 5140 
f 7031 5141 5123 
f 7031 5123 5124 
f 7031 5124 5125 
f 7010 5125 5126 
f 7010 5126 5127 
f 7010 5127 5128 
f 7010 5128 5129 
f 7083 5129 5130 
f 7083 5130 5131 
f 6997 5131 5132 
f 6997 5132 5133 
f 6997 5133 5134 
f 6997 5134 5135 
f 7102 5135 5136 
f 7102 5136 5137 
f 7031 5137 5138 
f 7031 5138 5142 
f 7031 5142 5141 
f 7031 5144 5143 
f 7031 5145 5144 
f 7031 5146 5145 
f 7010 5147 5146 
f 7010 5148 5147 
f 7010 5149 5148 
f 7010 5150 5149 
f 7083 5151 5150 
f 7083 5152 5151 
f 6997 5153 5152 
f 6997 5154 5153 
f 6997 5155 5154 
f 6997 5156 5155 
f 7102 5157 5156 
f 7102 5158 5157 
f 7031 5159 5158 
f 7031 5160 5159 
f 7031 5143 5160 
f 5161 5179 5177 
f 5162 5161 5177 
f 5163 5162 5177 
f 5164 5163 5177 
f 5165 5164 5177 
f 5166 5165 5177 
f 5167 5166 5177 
f 5168 5167 5177 
f 5169 5168 5177 
f 5170 5169 5177 
f 5171 5170 5177 
f 5172 5171 5177 
f 5173 5172 5177 
f 5174 5173 5177 
f 5175 5174 5177 
f 5176 5175 5177 
f 5180 5176 5177 
f 5179 5180 5177 
f 5181 5182 5178 
f 5182 5183 5178 
f 5183 5184 5178 
f 5184 5185 5178 
f 5185 5186 5178 
f 5186 5187 5178 
f 5187 5188 5178 
f 5188 5189 5178 
f 5189 5190 5178 
f 5190 5191 5178 
f 5191 5192 5178 
f 5192 5193 5178 
f 5193 5194 5178 
f 5194 5195 5178 
f 5195 5196 5178 
f 5196 5197 5178 
f 5197 5198 5178 
f 5198 5181 5178 
f 5199 5179 5161 
f 7119 5161 5162 
f 7119 5162 5163 
f 7026 5163 5164 
f 7026 5164 5165 
f 7079 5165 5166 
f 7079 5166 5167 
f 7079 5167 5168 
f 7079 5168 5169 
f 7079 5169 5170 
f 7079 5170 5171 
f 7127 5171 5172 
f 7127 5172 5173 
f 7127 5173 5174 
f 7127 5174 5175 
f 5199 5175 5176 
f 5199 5176 5180 
f 5199 5180 5179 
f 5199 5182 5181 
f 7119 5183 5182 
f 7119 5184 5183 
f 7026 5185 5184 
f 7026 5186 5185 
f 7079 5187 5186 
f 7079 5188 5187 
f 7079 5189 5188 
f 7079 5190 5189 
f 7079 5191 5190 
f 7127 5192 5191 
f 7127 5193 5192 
f 7127 5194 5193 
f 7127 5195 5194 
f 5199 5196 5195 
f 5199 5197 5196 
f 5199 5198 5197 
f 5199 5181 5198 
f 5200 5218 5216 
f 5201 5200 5216 
f 5202 5201 5216 
f 5203 5202 5216 
f 5204 5203 5216 
f 5205 5204 5216 
f 5206 5205 5216 
f 5207 5206 5216 
f 5208 5207 5216 
f 5209 5208 5216 
f 5210 5209 5216 
f 5211 5210 5216 
f 5212 5211 5216 
f 5213 5212 5216 
f 5214 5213 5216 
f 5215 5214 5216 
f 5219 5215 5216 
f 5218 5219 5216 
f 5220 5221 5217 
f 5221 5222 5217 
f 5222 5223 5217 
f 5223 5224 5217 
f 5224 5225 5217 
f 5225 5226 5217 
f 5226 5227 5217 
f 5227 5228 5217 
f 5228 5229 5217 
f 5229 5230 5217 
f 5230 5231 5217 
f 5231 5232 5217 
f 5232 5233 5217 
f 5233 5234 5217 
f 5234 5235 5217 
f 5235 5236 5217 
f 5236 5237 5217 
f 5237 5220 5217 
f 6978 5218 5200 
f 6978 5200 5201 
f 6978 5201 5202 
f 7040 5202 5203 
f 7040 5203 5204 
f 7040 5204 5205 
f 7040 5205 5206 
f 7040 5206 5207 
f 7040 5207 5208 
f 7040 5208 5209 
f 7040 5209 5210 
f 7106 5210 5211 
f 7106 5211 5212 
f 7106 5212 5213 
f 7106 5213 5214 
f 6978 5214 5215 
f 6978 5215 5219 
f 6978 5219 5218 
f 6978 5221 5220 
f 6978 5222 5221 
f 6978 5223 5222 
f 7040 5224 5223 
f 7040 5225 5224 
f 7040 5226 5225 
f 7040 5227 5226 
f 7040 5228 5227 
f 7040 5229 5228 
f 7040 5230 5229 
f 7106 5231 5230 
f 7106 5232 5231 
f 7106 5233 5232 
f 7106 5234 5233 
f 6978 5235 5234 
f 6978 5236 5235 
f 6978 5237 5236 
f 6978 5220 5237 
f 5238 5256 5254 
f 5239 5238 5254 
f 5240 5239 5254 
f 5241 5240 5254 
f 5242 5241 5254 
f 5243 5242 5254 
f 5244 5243 5254 
f 5245 5244 5254 
f 5246 5245 5254 
f 5247 5246 5254 
f 5248 5247 5254 
f 5249 5248 5254 
f 5250 5249 5254 
f 5251 5250 5254 
f 5252 5251 5254 
f 5253 5252 5254 
f 5257 5253 5254 
f 5256 5257 5254 
f 5258 5259 5255 
f 5259 5260 5255 
f 5260 5261 5255 
f 5261 5262 5255 
f 5262 5263 5255 
f 5263 5264 5255 
f 5264 5265 5255 
f 5265 5266 5255 
f 5266 5267 5255 
f 5267 5268 5255 
f 5268 5269 5255 
f 5269 5270 5255 
f 5270 5271 5255 
f 5271 5272 5255 
f 5272 5273 5255 
f 5273 5274 5255 
f 5274 5275 5255 
f 5275 5258 5255 
f 6968 5256 5238 
f 7050 5238 5239 
f 7050 5239 5240 
f 7050 5240 5241 
f 7050 5241 5242 
f 7050 5242 5243 
f 7050 5243 5244 
f 7111 5244 5245 
f 7111 5245 5246 
f 7111 5246 5247 
f 7111 5247 5248 
f 7111 5248 5249 
f 7111 5249 5250 
f 6968 5250 5251 
f 6968 5251 5252 
f 6968 5252 5253 
f 6968 5253 5257 
f 6968 5257 5256 
f 6968 5259 5258 
f 7050 5260 5259 
f 7050 5261 5260 
f 7050 5262 5261 
f 7050 5263 5262 
f 7111 5264 5263 
f 7111 5265 5264 
f 7111 5266 5265 
f 7111 5267 5266 
f 7111 5268 5267 
f 7111 5269 5268 
f 7111 5270 5269 
f 7111 5271 5270 
f 6968 5272 5271 
f 6968 5273 5272 
f 6968 5274 5273 
f 6968 5275 5274 
f 6968 5258 5275 
f 5276 5294 5292 
f 5277 5276 5292 
f 5278 5277 5292 
f 5279 5278 5292 
f 5280 5279 5292 
f 5281 5280 5292 
f 5282 5281 5292 
f 5283 5282 5292 
f 5284 5283 5292 
f 5285 5284 5292 
f 5286 5285 5292 
f 5287 5286 5292 
f 5288 5287 5292 
f 5289 5288 5292 
f 5290 5289 5292 
f 5291 5290 5292 
f 5295 5291 5292 
f 5294 5295 5292 
f 5296 5297 5293 
f 5297 5298 5293 
f 5298 5299 5293 
f 5299 5300 5293 
f 5300 5301 5293 
f 5301 5302 5293 
f 5302 5303 5293 
f 5303 5304 5293 
f 5304 5305 5293 
f 5305 5306 5293 
f 5306 5307 5293 
f 5307 5308 5293 
f 5308 5309 5293 
f 5309 5310 5293 
f 5310 5311 5293 
f 5311 5312 5293 
f 5312 5313 5293 
f 5313 5296 5293 
f 7057 5294 5276 
f 7057 5276 5277 
f 7057 5277 5278 
f 7057 5278 5279 
f 7057 5279 5280 
f 7057 5280 5281 
f 7057 5281 5282 
f 6973 5282 5283 
f 6973 5283 5284 
f 6973 5284 5285 
f 6973 5285 5286 
f 6973 5286 5287 
f 6973 5287 5288 
f 7144 5288 5289 
f 7144 5289 5290 
f 7144 5290 5291 
f 7144 5291 5295 
f 7057 5295 5294 
f 7057 5297 5296 
f 7057 5298 5297 
f 7057 5299 5298 
f 7057 5300 5299 
f 7057 5301 5300 
f 6973 5302 5301 
f 6973 5303 5302 
f 6973 5304 5303 
f 6973 5305 5304 
f 6973 5306 5305 
f 6973 5307 5306 
f 6973 5308 5307 
f 6973 5309 5308 
f 7144 5310 5309 
f 7144 5311 5310 
f 7144 5312 5311 
f 7144 5313 5312 
f 7057 5296 5313 
f 5314 5332 5330 
f 5315 5314 5330 
f 5316 5315 5330 
f 5317 5316 5330 
f 5318 5317 5330 
f 5319 5318 5330 
f 5320 5319 5330 
f 5321 5320 5330 
f 5322 5321 5330 
f 5323 5322 5330 
f 5324 5323 5330 
f 5325 5324 5330 
f 5326 5325 5330 
f 5327 5326 5330 
f 5328 5327 5330 
f 5329 5328 5330 
f 5333 5329 5330 
f 5332 5333 5330 
f 5334 5335 5331 
f 5335 5336 5331 
f 5336 5337 5331 
f 5337 5338 5331 
f 5338 5339 5331 
f 5339 5340 5331 
f 5340 5341 5331 
f 5341 5342 5331 
f 5342 5343 5331 
f 5343 5344 5331 
f 5344 5345 5331 
f 5345 5346 5331 
f 5346 5347 5331 
f 5347 5348 5331 
f 5348 5349 5331 
f 5349 5350 5331 
f 5350 5351 5331 
f 5351 5334 5331 
f 7071 5332 5314 
f 7071 5314 5315 
f 7071 5315 5316 
f 7071 5316 5317 
f 7071 5317 5318 
f 6975 5318 5319 
f 6975 5319 5320 
f 6975 5320 5321 
f 6975 5321 5322 
f 6975 5322 5323 
f 6975 5323 5324 
f 7138 5324 5325 
f 7138 5325 5326 
f 7138 5326 5327 
f 7138 5327 5328 
f 7138 5328 5329 
f 7138 5329 5333 
f 7071 5333 5332 
f 7071 5335 5334 
f 7071 5336 5335 
f 7071 5337 5336 
f 6975 5338 5337 
f 6975 5339 5338 
f 6975 5340 5339 
f 6975 5341 5340 
f 6975 5342 5341 
f 6975 5343 5342 
f 6975 5344 5343 
f 6975 5345 5344 
f 7138 5346 5345 
f 7138 5347 5346 
f 7138 5348 5347 
f 7138 5349 5348 
f 7138 5350 5349 
f 7138 5351 5350 
f 7071 5334 5351 
f 5352 5370 5368 
f 5353 5352 5368 
f 5354 5353 5368 
f 5355 5354 5368 
f 5356 5355 5368 
f 5357 5356 5368 
f 5358 5357 5368 
f 5359 5358 5368 
f 5360 5359 5368 
f 5361 5360 5368 
f 5362 5361 5368 
f 5363 5362 5368 
f 5364 5363 5368 
f 5365 5364 5368 
f 5366 5365 5368 
f 5367 5366 5368 
f 5371 5367 5368 
f 5370 5371 5368 
f 5372 5373 5369 
f 5373 5374 5369 
f 5374 5375 5369 
f 5375 5376 5369 
f 5376 5377 5369 
f 5377 5378 5369 
f 5378 5379 5369 
f 5379 5380 5369 
f 5380 5381 5369 
f 5381 5382 5369 
f 5382 5383 5369 
f 5383 5384 5369 
f 5384 5385 5369 
f 5385 5386 5369 
f 5386 5387 5369 
f 5387 5388 5369 
f 5388 5389 5369 
f 5389 5372 5369 
f 7082 5370 5352 
f 7082 5352 5353 
f 7082 5353 5354 
f 7082 5354 5355 
f 7082 5355 5356 
f 7082 5356 5357 
f 7082 5357 5358 
f 6981 5358 5359 
f 6981 5359 5360 
f 6981 5360 5361 
f 6981 5361 5362 
f 6981 5362 5363 
f 6981 5363 5364 
f 6981 5364 5365 
f 6981 5365 5366 
f 6981 5366 5367 
f 6981 5367 5371 
f 7082 5371 5370 
f 7082 5373 5372 
f 7082 5374 5373 
f 7082 5375 5374 
f 7082 5376 5375 
f 7082 5377 5376 
f 6981 5378 5377 
f 6981 5379 5378 
f 6981 5380 5379 
f 6981 5381 5380 
f 6981 5382 5381 
f 6981 5383 5382 
f 6981 5384 5383 
f 6981 5385 5384 
f 6981 5386 5385 
f 6981 5387 5386 
f 6981 5388 5387 
f 6981 5389 5388 
f 7082 5372 5389 
f 5390 5408 5406 
f 5391 5390 5406 
f 5392 5391 5406 
f 5393 5392 5406 
f 5394 5393 5406 
f 5395 5394 5406 
f 5396 5395 5406 
f 5397 5396 5406 
f 5398 5397 5406 
f 5399 5398 5406 
f 5400 5399 5406 
f 5401 5400 5406 
f 5402 5401 5406 
f 5403 5402 5406 
f 5404 5403 5406 
f 5405 5404 5406 
f 5409 5405 5406 
f 5408 5409 5406 
f 5410 5411 5407 
f 5411 5412 5407 
f 5412 5413 5407 
f 5413 5414 5407 
f 5414 5415 5407 
f 5415 5416 5407 
f 5416 5417 5407 
f 5417 5418 5407 
f 5418 5419 5407 
f 5419 5420 5407 
f 5420 5421 5407 
f 5421 5422 5407 
f 5422 5423 5407 
f 5423 5424 5407 
f 5424 5425 5407 
f 5425 5426 5407 
f 5426 5427 5407 
f 5427 5410 5407 
f 7072 5408 5390 
f 5428 5390 5391 
f 5428 5391 5392 
f 5428 5392 5393 
f 5428 5393 5394 
f 5428 5394 5395 
f 5428 5395 5396 
f 5428 5396 5397 
f 5428 5397 5398 
f 5428 5398 5399 
f 5428 5399 5400 
f 7072 5400 5401 
f 7072 5401 5402 
f 7072 5402 5403 
f 7072 5403 5404 
f 7072 5404 5405 
f 7072 5405 5409 
f 7072 5409 5408 
f 7072 5411 5410 
f 7072 5412 5411 
f 5428 5413 5412 
f 5428 5414 5413 
f 5428 5415 5414 
f 5428 5416 5415 
f 5428 5417 5416 
f 5428 5418 5417 
f 5428 5419 5418 
f 5428 5420 5419 
f 7072 5421 5420 
f 7072 5422 5421 
f 7072 5423 5422 
f 7072 5424 5423 
f 7072 5425 5424 
f 7072 5426 5425 
f 7072 5427 5426 
f 7072 5410 5427 
f 5429 5447 5445 
f 5430 5429 5445 
f 5431 5430 5445 
f 5432 5431 5445 
f 5433 5432 5445 
f 5434 5433 5445 
f 5435 5434 5445 
f 5436 5435 5445 
f 5437 5436 5445 
f 5438 5437 5445 
f 5439 5438 5445 
f 5440 5439 5445 
f 5441 5440 5445 
f 5442 5441 5445 
f 5443 5442 5445 
f 5444 5443 5445 
f 5448 5444 5445 
f 5447 5448 5445 
f 5449 5450 5446 
f 5450 5451 5446 
f 5451 5452 5446 
f 5452 5453 5446 
f 5453 5454 5446 
f 5454 5455 5446 
f 5455 5456 5446 
f 5456 5457 5446 
f 5457 5458 5446 
f 5458 5459 5446 
f 5459 5460 5446 
f 5460 5461 5446 
f 5461 5462 5446 
f 5462 5463 5446 
f 5463 5464 5446 
f 5464 5465 5446 
f 5465 5466 5446 
f 5466 5449 5446 
f 7068 5447 5429 
f 7068 5429 5430 
f 7152 5430 5431 
f 7152 5431 5432 
f 7152 5432 5433 
f 7152 5433 5434 
f 7152 5434 5435 
f 5467 5435 5436 
f 5467 5436 5437 
f 5467 5437 5438 
f 5467 5438 5439 
f 5467 5439 5440 
f 5467 5440 5441 
f 5467 5441 5442 
f 7068 5442 5443 
f 7068 5443 5444 
f 7068 5444 5448 
f 7068 5448 5447 
f 7068 5450 5449 
f 7068 5451 5450 
f 7068 5452 5451 
f 7152 5453 5452 
f 7152 5454 5453 
f 7152 5455 5454 
f 7152 5456 5455 
f 5467 5457 5456 
f 5467 5458 5457 
f 5467 5459 5458 
f 5467 5460 5459 
f 5467 5461 5460 
f 7068 5462 5461 
f 7068 5463 5462 
f 7068 5464 5463 
f 7068 5465 5464 
f 7068 5466 5465 
f 7068 5449 5466 
f 5468 5486 5484 
f 5469 5468 5484 
f 5470 5469 5484 
f 5471 5470 5484 
f 5472 5471 5484 
f 5473 5472 5484 
f 5474 5473 5484 
f 5475 5474 5484 
f 5476 5475 5484 
f 5477 5476 5484 
f 5478 5477 5484 
f 5479 5478 5484 
f 5480 5479 5484 
f 5481 5480 5484 
f 5482 5481 5484 
f 5483 5482 5484 
f 5487 5483 5484 
f 5486 5487 5484 
f 5488 5489 5485 
f 5489 5490 5485 
f 5490 5491 5485 
f 5491 5492 5485 
f 5492 5493 5485 
f 5493 5494 5485 
f 5494 5495 5485 
f 5495 5496 5485 
f 5496 5497 5485 
f 5497 5498 5485 
f 5498 5499 5485 
f 5499 5500 5485 
f 5500 5501 5485 
f 5501 5502 5485 
f 5502 5503 5485 
f 5503 5504 5485 
f 5504 5505 5485 
f 5505 5488 5485 
f 7016 5486 5468 
f 7016 5468 5469 
f 7016 5469 5470 
f 7094 5470 5471 
f 7094 5471 5472 
f 7094 5472 5473 
f 7094 5473 5474 
f 6969 5474 5475 
f 6969 5475 5476 
f 6969 5476 5477 
f 6969 5477 5478 
f 6969 5478 5479 
f 6969 5479 5480 
f 6969 5480 5481 
f 6969 5481 5482 
f 7109 5482 5483 
f 7109 5483 5487 
f 7016 5487 5486 
f 7016 5489 5488 
f 7016 5490 5489 
f 7016 5491 5490 
f 7094 5492 5491 
f 7094 5493 5492 
f 7094 5494 5493 
f 7094 5495 5494 
f 6969 5496 5495 
f 6969 5497 5496 
f 6969 5498 5497 
f 6969 5499 5498 
f 6969 5500 5499 
f 6969 5501 5500 
f 6969 5502 5501 
f 6969 5503 5502 
f 7109 5504 5503 
f 7109 5505 5504 
f 7016 5488 5505 
f 5506 5524 5522 
f 5507 5506 5522 
f 5508 5507 5522 
f 5509 5508 5522 
f 5510 5509 5522 
f 5511 5510 5522 
f 5512 5511 5522 
f 5513 5512 5522 
f 5514 5513 5522 
f 5515 5514 5522 
f 5516 5515 5522 
f 5517 5516 5522 
f 5518 5517 5522 
f 5519 5518 5522 
f 5520 5519 5522 
f 5521 5520 5522 
f 5525 5521 5522 
f 5524 5525 5522 
f 5526 5527 5523 
f 5527 5528 5523 
f 5528 5529 5523 
f 5529 5530 5523 
f 5530 5531 5523 
f 5531 5532 5523 
f 5532 5533 5523 
f 5533 5534 5523 
f 5534 5535 5523 
f 5535 5536 5523 
f 5536 5537 5523 
f 5537 5538 5523 
f 5538 5539 5523 
f 5539 5540 5523 
f 5540 5541 5523 
f 5541 5542 5523 
f 5542 5543 5523 
f 5543 5526 5523 
f 7017 5524 5506 
f 7017 5506 5507 
f 7017 5507 5508 
f 7017 5508 5509 
f 7017 5509 5510 
f 7017 5510 5511 
f 7017 5511 5512 
f 7017 5512 5513 
f 5544 5513 5514 
f 5544 5514 5515 
f 5544 5515 5516 
f 5544 5516 5517 
f 5544 5517 5518 
f 5544 5518 5519 
f 5544 5519 5520 
f 7125 5520 5521 
f 7125 5521 5525 
f 7017 5525 5524 
f 7017 5527 5526 
f 7017 5528 5527 
f 7017 5529 5528 
f 7017 5530 5529 
f 7017 5531 5530 
f 7017 5532 5531 
f 7017 5533 5532 
f 5544 5534 5533 
f 5544 5535 5534 
f 5544 5536 5535 
f 5544 5537 5536 
f 5544 5538 5537 
f 5544 5539 5538 
f 5544 5540 5539 
f 5544 5541 5540 
f 7125 5542 5541 
f 7125 5543 5542 
f 7017 5526 5543 
f 5545 5563 5561 
f 5546 5545 5561 
f 5547 5546 5561 
f 5548 5547 5561 
f 5549 5548 5561 
f 5550 5549 5561 
f 5551 5550 5561 
f 5552 5551 5561 
f 5553 5552 5561 
f 5554 5553 5561 
f 5555 5554 5561 
f 5556 5555 5561 
f 5557 5556 5561 
f 5558 5557 5561 
f 5559 5558 5561 
f 5560 5559 5561 
f 5564 5560 5561 
f 5563 5564 5561 
f 5565 5566 5562 
f 5566 5567 5562 
f 5567 5568 5562 
f 5568 5569 5562 
f 5569 5570 5562 
f 5570 5571 5562 
f 5571 5572 5562 
f 5572 5573 5562 
f 5573 5574 5562 
f 5574 5575 5562 
f 5575 5576 5562 
f 5576 5577 5562 
f 5577 5578 5562 
f 5578 5579 5562 
f 5579 5580 5562 
f 5580 5581 5562 
f 5581 5582 5562 
f 5582 5565 5562 
f 7150 5563 5545 
f 7150 5545 5546 
f 7028 5546 5547 
f 7028 5547 5548 
f 7028 5548 5549 
f 7028 5549 5550 
f 7117 5550 5551 
f 7117 5551 5552 
f 7054 5552 5553 
f 7054 5553 5554 
f 7054 5554 5555 
f 7054 5555 5556 
f 7054 5556 5557 
f 7054 5557 5558 
f 7129 5558 5559 
f 7129 5559 5560 
f 6965 5560 5564 
f 6965 5564 5563 
f 6965 5566 5565 
f 7028 5567 5566 
f 7028 5568 5567 
f 7028 5569 5568 
f 7028 5570 5569 
f 7028 5571 5570 
f 7054 5572 5571 
f 7054 5573 5572 
f 7054 5574 5573 
f 7054 5575 5574 
f 7054 5576 5575 
f 7054 5577 5576 
f 7054 5578 5577 
f 7054 5579 5578 
f 7054 5580 5579 
f 7129 5581 5580 
f 7129 5582 5581 
f 6965 5565 5582 
f 5583 5601 5599 
f 5584 5583 5599 
f 5585 5584 5599 
f 5586 5585 5599 
f 5587 5586 5599 
f 5588 5587 5599 
f 5589 5588 5599 
f 5590 5589 5599 
f 5591 5590 5599 
f 5592 5591 5599 
f 5593 5592 5599 
f 5594 5593 5599 
f 5595 5594 5599 
f 5596 5595 5599 
f 5597 5596 5599 
f 5598 5597 5599 
f 5602 5598 5599 
f 5601 5602 5599 
f 5603 5604 5600 
f 5604 5605 5600 
f 5605 5606 5600 
f 5606 5607 5600 
f 5607 5608 5600 
f 5608 5609 5600 
f 5609 5610 5600 
f 5610 5611 5600 
f 5611 5612 5600 
f 5612 5613 5600 
f 5613 5614 5600 
f 5614 5615 5600 
f 5615 5616 5600 
f 5616 5617 5600 
f 5617 5618 5600 
f 5618 5619 5600 
f 5619 5620 5600 
f 5620 5603 5600 
f 7112 5601 5583 
f 7112 5583 5584 
f 7059 5584 5585 
f 7059 5585 5586 
f 7108 5586 5587 
f 7108 5587 5588 
f 6972 5588 5589 
f 6972 5589 5590 
f 6972 5590 5591 
f 6972 5591 5592 
f 6972 5592 5593 
f 6972 5593 5594 
f 6972 5594 5595 
f 6972 5595 5596 
f 7025 5596 5597 
f 7025 5597 5598 
f 7025 5598 5602 
f 7025 5602 5601 
f 7112 5604 5603 
f 7112 5605 5604 
f 7059 5606 5605 
f 7059 5607 5606 
f 7108 5608 5607 
f 7108 5609 5608 
f 6972 5610 5609 
f 6972 5611 5610 
f 6972 5612 5611 
f 6972 5613 5612 
f 6972 5614 5613 
f 6972 5615 5614 
f 6972 5616 5615 
f 6972 5617 5616 
f 7025 5618 5617 
f 7025 5619 5618 
f 7025 5620 5619 
f 7025 5603 5620 
f 5621 5639 5637 
f 5622 5621 5637 
f 5623 5622 5637 
f 5624 5623 5637 
f 5625 5624 5637 
f 5626 5625 5637 
f 5627 5626 5637 
f 5628 5627 5637 
f 5629 5628 5637 
f 5630 5629 5637 
f 5631 5630 5637 
f 5632 5631 5637 
f 5633 5632 5637 
f 5634 5633 5637 
f 5635 5634 5637 
f 5636 5635 5637 
f 5640 5636 5637 
f 5639 5640 5637 
f 5641 5642 5638 
f 5642 5643 5638 
f 5643 5644 5638 
f 5644 5645 5638 
f 5645 5646 5638 
f 5646 5647 5638 
f 5647 5648 5638 
f 5648 5649 5638 
f 5649 5650 5638 
f 5650 5651 5638 
f 5651 5652 5638 
f 5652 5653 5638 
f 5653 5654 5638 
f 5654 5655 5638 
f 5655 5656 5638 
f 5656 5657 5638 
f 5657 5658 5638 
f 5658 5641 5638 
f 7137 5639 5621 
f 7137 5621 5622 
f 6980 5622 5623 
f 6980 5623 5624 
f 6980 5624 5625 
f 6980 5625 5626 
f 6980 5626 5627 
f 6980 5627 5628 
f 7099 5628 5629 
f 7099 5629 5630 
f 7099 5630 5631 
f 7099 5631 5632 
f 7153 5632 5633 
f 7153 5633 5634 
f 7153 5634 5635 
f 7153 5635 5636 
f 7036 5636 5640 
f 7036 5640 5639 
f 7036 5642 5641 
f 7137 5643 5642 
f 7137 5644 5643 
f 6980 5645 5644 
f 6980 5646 5645 
f 6980 5647 5646 
f 6980 5648 5647 
f 7099 5649 5648 
f 7099 5650 5649 
f 7099 5651 5650 
f 7099 5652 5651 
f 7153 5653 5652 
f 7153 5654 5653 
f 7153 5655 5654 
f 7153 5656 5655 
f 7036 5657 5656 
f 7036 5658 5657 
f 7036 5641 5658 
f 5659 5677 5675 
f 5660 5659 5675 
f 5661 5660 5675 
f 5662 5661 5675 
f 5663 5662 5675 
f 5664 5663 5675 
f 5665 5664 5675 
f 5666 5665 5675 
f 5667 5666 5675 
f 5668 5667 5675 
f 5669 5668 5675 
f 5670 5669 5675 
f 5671 5670 5675 
f 5672 5671 5675 
f 5673 5672 5675 
f 5674 5673 5675 
f 5678 5674 5675 
f 5677 5678 5675 
f 5679 5680 5676 
f 5680 5681 5676 
f 5681 5682 5676 
f 5682 5683 5676 
f 5683 5684 5676 
f 5684 5685 5676 
f 5685 5686 5676 
f 5686 5687 5676 
f 5687 5688 5676 
f 5688 5689 5676 
f 5689 5690 5676 
f 5690 5691 5676 
f 5691 5692 5676 
f 5692 5693 5676 
f 5693 5694 5676 
f 5694 5695 5676 
f 5695 5696 5676 
f 5696 5679 5676 
f 7130 5677 5659 
f 7064 5659 5660 
f 7064 5660 5661 
f 7064 5661 5662 
f 7064 5662 5663 
f 7064 5663 5664 
f 7020 5664 5665 
f 7020 5665 5666 
f 7149 5666 5667 
f 7149 5667 5668 
f 7149 5668 5669 
f 7149 5669 5670 
f 7149 5670 5671 
f 5697 5671 5672 
f 5697 5672 5673 
f 5697 5673 5674 
f 5697 5674 5678 
f 7130 5678 5677 
f 7130 5680 5679 
f 7064 5681 5680 
f 7064 5682 5681 
f 7064 5683 5682 
f 7064 5684 5683 
f 7064 5685 5684 
f 7064 5686 5685 
f 7020 5687 5686 
f 7020 5688 5687 
f 7149 5689 5688 
f 7149 5690 5689 
f 7149 5691 5690 
f 7149 5692 5691 
f 5697 5693 5692 
f 5697 5694 5693 
f 5697 5695 5694 
f 5697 5696 5695 
f 7130 5679 5696 
f 5698 5716 5714 
f 5699 5698 5714 
f 5700 5699 5714 
f 5701 5700 5714 
f 5702 5701 5714 
f 5703 5702 5714 
f 5704 5703 5714 
f 5705 5704 5714 
f 5706 5705 5714 
f 5707 5706 5714 
f 5708 5707 5714 
f 5709 5708 5714 
f 5710 5709 5714 
f 5711 5710 5714 
f 5712 5711 5714 
f 5713 5712 5714 
f 5717 5713 5714 
f 5716 5717 5714 
f 5718 5719 5715 
f 5719 5720 5715 
f 5720 5721 5715 
f 5721 5722 5715 
f 5722 5723 5715 
f 5723 5724 5715 
f 5724 5725 5715 
f 5725 5726 5715 
f 5726 5727 5715 
f 5727 5728 5715 
f 5728 5729 5715 
f 5729 5730 5715 
f 5730 5731 5715 
f 5731 5732 5715 
f 5732 5733 5715 
f 5733 5734 5715 
f 5734 5735 5715 
f 5735 5718 5715 
f 7123 5716 5698 
f 7007 5698 5699 
f 7007 5699 5700 
f 7007 5700 5701 
f 7118 5701 5702 
f 7118 5702 5703 
f 7069 5703 5704 
f 7069 5704 5705 
f 6966 5705 5706 
f 6966 5706 5707 
f 6966 5707 5708 
f 6966 5708 5709 
f 6966 5709 5710 
f 6966 5710 5711 
f 6966 5711 5712 
f 7043 5712 5713 
f 7043 5713 5717 
f 7123 5717 5716 
f 7123 5719 5718 
f 7007 5720 5719 
f 7007 5721 5720 
f 7007 5722 5721 
f 7007 5723 5722 
f 7118 5724 5723 
f 7118 5725 5724 
f 7069 5726 5725 
f 7069 5727 5726 
f 6966 5728 5727 
f 6966 5729 5728 
f 6966 5730 5729 
f 6966 5731 5730 
f 6966 5732 5731 
f 6966 5733 5732 
f 7043 5734 5733 
f 7043 5735 5734 
f 7123 5718 5735 
f 5736 5754 5752 
f 5737 5736 5752 
f 5738 5737 5752 
f 5739 5738 5752 
f 5740 5739 5752 
f 5741 5740 5752 
f 5742 5741 5752 
f 5743 5742 5752 
f 5744 5743 5752 
f 5745 5744 5752 
f 5746 5745 5752 
f 5747 5746 5752 
f 5748 5747 5752 
f 5749 5748 5752 
f 5750 5749 5752 
f 5751 5750 5752 
f 5755 5751 5752 
f 5754 5755 5752 
f 5756 5757 5753 
f 5757 5758 5753 
f 5758 5759 5753 
f 5759 5760 5753 
f 5760 5761 5753 
f 5761 5762 5753 
f 5762 5763 5753 
f 5763 5764 5753 
f 5764 5765 5753 
f 5765 5766 5753 
f 5766 5767 5753 
f 5767 5768 5753 
f 5768 5769 5753 
f 5769 5770 5753 
f 5770 5771 5753 
f 5771 5772 5753 
f 5772 5773 5753 
f 5773 5756 5753 
f 7055 5754 5736 
f 6998 5736 5737 
f 6998 5737 5738 
f 6998 5738 5739 
f 7088 5739 5740 
f 7088 5740 5741 
f 5774 5741 5742 
f 5774 5742 5743 
f 5774 5743 5744 
f 5774 5744 5745 
f 5774 5745 5746 
f 5774 5746 5747 
f 5774 5747 5748 
f 5774 5748 5749 
f 5774 5749 5750 
f 5774 5750 5751 
f 5774 5751 5755 
f 7055 5755 5754 
f 7055 5757 5756 
f 6998 5758 5757 
f 6998 5759 5758 
f 6998 5760 5759 
f 6998 5761 5760 
f 7088 5762 5761 
f 7088 5763 5762 
f 5774 5764 5763 
f 5774 5765 5764 
f 5774 5766 5765 
f 5774 5767 5766 
f 5774 5768 5767 
f 5774 5769 5768 
f 5774 5770 5769 
f 5774 5771 5770 
f 5774 5772 5771 
f 5774 5773 5772 
f 7055 5756 5773 
f 5775 5793 5791 
f 5776 5775 5791 
f 5777 5776 5791 
f 5778 5777 5791 
f 5779 5778 5791 
f 5780 5779 5791 
f 5781 5780 5791 
f 5782 5781 5791 
f 5783 5782 5791 
f 5784 5783 5791 
f 5785 5784 5791 
f 5786 5785 5791 
f 5787 5786 5791 
f 5788 5787 5791 
f 5789 5788 5791 
f 5790 5789 5791 
f 5794 5790 5791 
f 5793 5794 5791 
f 5795 5796 5792 
f 5796 5797 5792 
f 5797 5798 5792 
f 5798 5799 5792 
f 5799 5800 5792 
f 5800 5801 5792 
f 5801 5802 5792 
f 5802 5803 5792 
f 5803 5804 5792 
f 5804 5805 5792 
f 5805 5806 5792 
f 5806 5807 5792 
f 5807 5808 5792 
f 5808 5809 5792 
f 5809 5810 5792 
f 5810 5811 5792 
f 5811 5812 5792 
f 5812 5795 5792 
f 6971 5793 5775 
f 7136 5775 5776 
f 7136 5776 5777 
f 7087 5777 5778 
f 7087 5778 5779 
f 7087 5779 5780 
f 7087 5780 5781 
f 7037 5781 5782 
f 7037 5782 5783 
f 7037 5783 5784 
f 7037 5784 5785 
f 7037 5785 5786 
f 7037 5786 5787 
f 7037 5787 5788 
f 7037 5788 5789 
f 6971 5789 5790 
f 6971 5790 5794 
f 6971 5794 5793 
f 6971 5796 5795 
f 7136 5797 5796 
f 7136 5798 5797 
f 7087 5799 5798 
f 7087 5800 5799 
f 7087 5801 5800 
f 7087 5802 5801 
f 7037 5803 5802 
f 7037 5804 5803 
f 7037 5805 5804 
f 7037 5806 5805 
f 7037 5807 5806 
f 7037 5808 5807 
f 7037 5809 5808 
f 7037 5810 5809 
f 6971 5811 5810 
f 6971 5812 5811 
f 6971 5795 5812 
f 5813 5831 5829 
f 5814 5813 5829 
f 5815 5814 5829 
f 5816 5815 5829 
f 5817 5816 5829 
f 5818 5817 5829 
f 5819 5818 5829 
f 5820 5819 5829 
f 5821 5820 5829 
f 5822 5821 5829 
f 5823 5822 5829 
f 5824 5823 5829 
f 5825 5824 5829 
f 5826 5825 5829 
f 5827 5826 5829 
f 5828 5827 5829 
f 5832 5828 5829 
f 5831 5832 5829 
f 5833 5834 5830 
f 5834 5835 5830 
f 5835 5836 5830 
f 5836 5837 5830 
f 5837 5838 5830 
f 5838 5839 5830 
f 5839 5840 5830 
f 5840 5841 5830 
f 5841 5842 5830 
f 5842 5843 5830 
f 5843 5844 5830 
f 5844 5845 5830 
f 5845 5846 5830 
f 5846 5847 5830 
f 5847 5848 5830 
f 5848 5849 5830 
f 5849 5850 5830 
f 5850 5833 5830 
f 6994 5831 5813 
f 6994 5813 5814 
f 6994 5814 5815 
f 6994 5815 5816 
f 6994 5816 5817 
f 7077 5817 5818 
f 7077 5818 5819 
f 7077 5819 5820 
f 7077 5820 5821 
f 7077 5821 5822 
f 7077 5822 5823 
f 7077 5823 5824 
f 7077 5824 5825 
f 7077 5825 5826 
f 7077 5826 5827 
f 6994 5827 5828 
f 6994 5828 5832 
f 6994 5832 5831 
f 7179 5834 5833 
f 7179 5835 5834 
f 7179 5836 5835 
f 7179 5837 5836 
f 7180 5838 5837 
f 7180 5839 5838 
f 7180 5840 5839 
f 7180 5841 5840 
f 7180 5842 5841 
f 7180 5843 5842 
f 7180 5844 5843 
f 7180 5845 5844 
f 7180 5846 5845 
f 7180 5847 5846 
f 7180 5848 5847 
f 7179 5849 5848 
f 7179 5850 5849 
f 7179 5833 5850 
f 5851 5869 5867 
f 5852 5851 5867 
f 5853 5852 5867 
f 5854 5853 5867 
f 5855 5854 5867 
f 5856 5855 5867 
f 5857 5856 5867 
f 5858 5857 5867 
f 5859 5858 5867 
f 5860 5859 5867 
f 5861 5860 5867 
f 5862 5861 5867 
f 5863 5862 5867 
f 5864 5863 5867 
f 5865 5864 5867 
f 5866 5865 5867 
f 5870 5866 5867 
f 5869 5870 5867 
f 5871 5872 5868 
f 5872 5873 5868 
f 5873 5874 5868 
f 5874 5875 5868 
f 5875 5876 5868 
f 5876 5877 5868 
f 5877 5878 5868 
f 5878 5879 5868 
f 5879 5880 5868 
f 5880 5881 5868 
f 5881 5882 5868 
f 5882 5883 5868 
f 5883 5884 5868 
f 5884 5885 5868 
f 5885 5886 5868 
f 5886 5887 5868 
f 5887 5888 5868 
f 5888 5871 5868 
f 7065 5869 5851 
f 6962 5851 5852 
f 6962 5852 5853 
f 6962 5853 5854 
f 6962 5854 5855 
f 7114 5855 5856 
f 7114 5856 5857 
f 7012 5857 5858 
f 7012 5858 5859 
f 7012 5859 5860 
f 7012 5860 5861 
f 7074 5861 5862 
f 7074 5862 5863 
f 7135 5863 5864 
f 7135 5864 5865 
f 7065 5865 5866 
f 7065 5866 5870 
f 7065 5870 5869 
f 7065 5872 5871 
f 6962 5873 5872 
f 6962 5874 5873 
f 6962 5875 5874 
f 7114 5876 5875 
f 7114 5877 5876 
f 7012 5878 5877 
f 7012 5879 5878 
f 7012 5880 5879 
f 7012 5881 5880 
f 7012 5882 5881 
f 7074 5883 5882 
f 7074 5884 5883 
f 7135 5885 5884 
f 7135 5886 5885 
f 7065 5887 5886 
f 7065 5888 5887 
f 7065 5871 5888 
f 5889 5907 5905 
f 5890 5889 5905 
f 5891 5890 5905 
f 5892 5891 5905 
f 5893 5892 5905 
f 5894 5893 5905 
f 5895 5894 5905 
f 5896 5895 5905 
f 5897 5896 5905 
f 5898 5897 5905 
f 5899 5898 5905 
f 5900 5899 5905 
f 5901 5900 5905 
f 5902 5901 5905 
f 5903 5902 5905 
f 5904 5903 5905 
f 5908 5904 5905 
f 5907 5908 5905 
f 5909 5910 5906 
f 5910 5911 5906 
f 5911 5912 5906 
f 5912 5913 5906 
f 5913 5914 5906 
f 5914 5915 5906 
f 5915 5916 5906 
f 5916 5917 5906 
f 5917 5918 5906 
f 5918 5919 5906 
f 5919 5920 5906 
f 5920 5921 5906 
f 5921 5922 5906 
f 5922 5923 5906 
f 5923 5924 5906 
f 5924 5925 5906 
f 5925 5926 5906 
f 5926 5909 5906 
f 6992 5907 5889 
f 6992 5889 5890 
f 6992 5890 5891 
f 6992 5891 5892 
f 6992 5892 5893 
f 6992 5893 5894 
f 6992 5894 5895 
f 7006 5895 5896 
f 7006 5896 5897 
f 7006 5897 5898 
f 7006 5898 5899 
f 7096 5899 5900 
f 7096 5900 5901 
f 7047 5901 5902 
f 7047 5902 5903 
f 7047 5903 5904 
f 7047 5904 5908 
f 6992 5908 5907 
f 6992 5910 5909 
f 6992 5911 5910 
f 6992 5912 5911 
f 6992 5913 5912 
f 6992 5914 5913 
f 6992 5915 5914 
f 7006 5916 5915 
f 7006 5917 5916 
f 7006 5918 5917 
f 7006 5919 5918 
f 7006 5920 5919 
f 7096 5921 5920 
f 7096 5922 5921 
f 7047 5923 5922 
f 7047 5924 5923 
f 7047 5925 5924 
f 7047 5926 5925 
f 6992 5909 5926 
f 5927 5945 5943 
f 5928 5927 5943 
f 5929 5928 5943 
f 5930 5929 5943 
f 5931 5930 5943 
f 5932 5931 5943 
f 5933 5932 5943 
f 5934 5933 5943 
f 5935 5934 5943 
f 5936 5935 5943 
f 5937 5936 5943 
f 5938 5937 5943 
f 5939 5938 5943 
f 5940 5939 5943 
f 5941 5940 5943 
f 5942 5941 5943 
f 5946 5942 5943 
f 5945 5946 5943 
f 5947 5948 5944 
f 5948 5949 5944 
f 5949 5950 5944 
f 5950 5951 5944 
f 5951 5952 5944 
f 5952 5953 5944 
f 5953 5954 5944 
f 5954 5955 5944 
f 5955 5956 5944 
f 5956 5957 5944 
f 5957 5958 5944 
f 5958 5959 5944 
f 5959 5960 5944 
f 5960 5961 5944 
f 5961 5962 5944 
f 5962 5963 5944 
f 5963 5964 5944 
f 5964 5947 5944 
f 6960 5945 5927 
f 6960 5927 5928 
f 7116 5928 5929 
f 7116 5929 5930 
f 7045 5930 5931 
f 7045 5931 5932 
f 7045 5932 5933 
f 7045 5933 5934 
f 7095 5934 5935 
f 7095 5935 5936 
f 7004 5936 5937 
f 7004 5937 5938 
f 7004 5938 5939 
f 7004 5939 5940 
f 7004 5940 5941 
f 7084 5941 5942 
f 7084 5942 5946 
f 6960 5946 5945 
f 6960 5948 5947 
f 6960 5949 5948 
f 7116 5950 5949 
f 7116 5951 5950 
f 7045 5952 5951 
f 7045 5953 5952 
f 7045 5954 5953 
f 7045 5955 5954 
f 7095 5956 5955 
f 7095 5957 5956 
f 7004 5958 5957 
f 7004 5959 5958 
f 7004 5960 5959 
f 7004 5961 5960 
f 7084 5962 5961 
f 7084 5963 5962 
f 6960 5964 5963 
f 6960 5947 5964 
f 5965 5983 5981 
f 5966 5965 5981 
f 5967 5966 5981 
f 5968 5967 5981 
f 5969 5968 5981 
f 5970 5969 5981 
f 5971 5970 5981 
f 5972 5971 5981 
f 5973 5972 5981 
f 5974 5973 5981 
f 5975 5974 5981 
f 5976 5975 5981 
f 5977 5976 5981 
f 5978 5977 5981 
f 5979 5978 5981 
f 5980 5979 5981 
f 5984 5980 5981 
f 5983 5984 5981 
f 5985 5986 5982 
f 5986 5987 5982 
f 5987 5988 5982 
f 5988 5989 5982 
f 5989 5990 5982 
f 5990 5991 5982 
f 5991 5992 5982 
f 5992 5993 5982 
f 5993 5994 5982 
f 5994 5995 5982 
f 5995 5996 5982 
f 5996 5997 5982 
f 5997 5998 5982 
f 5998 5999 5982 
f 5999 6000 5982 
f 6000 6001 5982 
f 6001 6002 5982 
f 6002 5985 5982 
f 7002 5983 5965 
f 7002 5965 5966 
f 7002 5966 5967 
f 7002 5967 5968 
f 7002 5968 5969 
f 7002 5969 5970 
f 6990 5970 5971 
f 6990 5971 5972 
f 6990 5972 5973 
f 6990 5973 5974 
f 6990 5974 5975 
f 6990 5975 5976 
f 6990 5976 5977 
f 6990 5977 5978 
f 6990 5978 5979 
f 7051 5979 5980 
f 7051 5980 5984 
f 7002 5984 5983 
f 7002 5986 5985 
f 7002 5987 5986 
f 7002 5988 5987 
f 7002 5989 5988 
f 7002 5990 5989 
f 7002 5991 5990 
f 7002 5992 5991 
f 6990 5993 5992 
f 6990 5994 5993 
f 6990 5995 5994 
f 6990 5996 5995 
f 6990 5997 5996 
f 6990 5998 5997 
f 6990 5999 5998 
f 6990 6000 5999 
f 6990 6001 6000 
f 7051 6002 6001 
f 7051 5985 6002 
f 6003 6021 6019 
f 6004 6003 6019 
f 6005 6004 6019 
f 6006 6005 6019 
f 6007 6006 6019 
f 6008 6007 6019 
f 6009 6008 6019 
f 6010 6009 6019 
f 6011 6010 6019 
f 6012 6011 6019 
f 6013 6012 6019 
f 6014 6013 6019 
f 6015 6014 6019 
f 6016 6015 6019 
f 6017 6016 6019 
f 6018 6017 6019 
f 6022 6018 6019 
f 6021 6022 6019 
f 6023 6024 6020 
f 6024 6025 6020 
f 6025 6026 6020 
f 6026 6027 6020 
f 6027 6028 6020 
f 6028 6029 6020 
f 6029 6030 6020 
f 6030 6031 6020 
f 6031 6032 6020 
f 6032 6033 6020 
f 6033 6034 6020 
f 6034 6035 6020 
f 6035 6036 6020 
f 6036 6037 6020 
f 6037 6038 6020 
f 6038 6039 6020 
f 6039 6040 6020 
f 6040 6023 6020 
f 7001 6021 6003 
f 7001 6003 6004 
f 7039 6004 6005 
f 7039 6005 6006 
f 7039 6006 6007 
f 7039 6007 6008 
f 7039 6008 6009 
f 7039 6009 6010 
f 7131 6010 6011 
f 7131 6011 6012 
f 6041 6012 6013 
f 6041 6013 6014 
f 7098 6014 6015 
f 7098 6015 6016 
f 7105 6016 6017 
f 7105 6017 6018 
f 7105 6018 6022 
f 7105 6022 6021 
f 7039 6024 6023 
f 7039 6025 6024 
f 7039 6026 6025 
f 7039 6027 6026 
f 7039 6028 6027 
f 7039 6029 6028 
f 7039 6030 6029 
f 7131 6031 6030 
f 7131 6032 6031 
f 6041 6033 6032 
f 6041 6034 6033 
f 6041 6035 6034 
f 7098 6036 6035 
f 7098 6037 6036 
f 7105 6038 6037 
f 7105 6039 6038 
f 7105 6040 6039 
f 7105 6023 6040 
f 6042 6060 6058 
f 6043 6042 6058 
f 6044 6043 6058 
f 6045 6044 6058 
f 6046 6045 6058 
f 6047 6046 6058 
f 6048 6047 6058 
f 6049 6048 6058 
f 6050 6049 6058 
f 6051 6050 6058 
f 6052 6051 6058 
f 6053 6052 6058 
f 6054 6053 6058 
f 6055 6054 6058 
f 6056 6055 6058 
f 6057 6056 6058 
f 6061 6057 6058 
f 6060 6061 6058 
f 6062 6063 6059 
f 6063 6064 6059 
f 6064 6065 6059 
f 6065 6066 6059 
f 6066 6067 6059 
f 6067 6068 6059 
f 6068 6069 6059 
f 6069 6070 6059 
f 6070 6071 6059 
f 6071 6072 6059 
f 6072 6073 6059 
f 6073 6074 6059 
f 6074 6075 6059 
f 6075 6076 6059 
f 6076 6077 6059 
f 6077 6078 6059 
f 6078 6079 6059 
f 6079 6062 6059 
f 7104 6060 6042 
f 7104 6042 6043 
f 6963 6043 6044 
f 6963 6044 6045 
f 6963 6045 6046 
f 6963 6046 6047 
f 7141 6047 6048 
f 7141 6048 6049 
f 7141 6049 6050 
f 7141 6050 6051 
f 7038 6051 6052 
f 7038 6052 6053 
f 7038 6053 6054 
f 7038 6054 6055 
f 7104 6055 6056 
f 7104 6056 6057 
f 7104 6057 6061 
f 7104 6061 6060 
f 7104 6063 6062 
f 7104 6064 6063 
f 7104 6065 6064 
f 6963 6066 6065 
f 6963 6067 6066 
f 7141 6068 6067 
f 7141 6069 6068 
f 7141 6070 6069 
f 7141 6071 6070 
f 7038 6072 6071 
f 7038 6073 6072 
f 7038 6074 6073 
f 7038 6075 6074 
f 7038 6076 6075 
f 7104 6077 6076 
f 7104 6078 6077 
f 7104 6079 6078 
f 7104 6062 6079 
f 6080 6098 6096 
f 6081 6080 6096 
f 6082 6081 6096 
f 6083 6082 6096 
f 6084 6083 6096 
f 6085 6084 6096 
f 6086 6085 6096 
f 6087 6086 6096 
f 6088 6087 6096 
f 6089 6088 6096 
f 6090 6089 6096 
f 6091 6090 6096 
f 6092 6091 6096 
f 6093 6092 6096 
f 6094 6093 6096 
f 6095 6094 6096 
f 6099 6095 6096 
f 6098 6099 6096 
f 6100 6101 6097 
f 6101 6102 6097 
f 6102 6103 6097 
f 6103 6104 6097 
f 6104 6105 6097 
f 6105 6106 6097 
f 6106 6107 6097 
f 6107 6108 6097 
f 6108 6109 6097 
f 6109 6110 6097 
f 6110 6111 6097 
f 6111 6112 6097 
f 6112 6113 6097 
f 6113 6114 6097 
f 6114 6115 6097 
f 6115 6116 6097 
f 6116 6117 6097 
f 6117 6100 6097 
f 7013 6098 6080 
f 7013 6080 6081 
f 7013 6081 6082 
f 7013 6082 6083 
f 7013 6083 6084 
f 7013 6084 6085 
f 7013 6085 6086 
f 7013 6086 6087 
f 7103 6087 6088 
f 7103 6088 6089 
f 6993 6089 6090 
f 6993 6090 6091 
f 6993 6091 6092 
f 6993 6092 6093 
f 6993 6093 6094 
f 6993 6094 6095 
f 6993 6095 6099 
f 6993 6099 6098 
f 7013 6101 6100 
f 7013 6102 6101 
f 7013 6103 6102 
f 7013 6104 6103 
f 7013 6105 6104 
f 7013 6106 6105 
f 7013 6107 6106 
f 7103 6108 6107 
f 7103 6109 6108 
f 7103 6110 6109 
f 7103 6111 6110 
f 7103 6112 6111 
f 6993 6113 6112 
f 6993 6114 6113 
f 6993 6115 6114 
f 6993 6116 6115 
f 7013 6117 6116 
f 7013 6100 6117 
f 6118 6136 6134 
f 6119 6118 6134 
f 6120 6119 6134 
f 6121 6120 6134 
f 6122 6121 6134 
f 6123 6122 6134 
f 6124 6123 6134 
f 6125 6124 6134 
f 6126 6125 6134 
f 6127 6126 6134 
f 6128 6127 6134 
f 6129 6128 6134 
f 6130 6129 6134 
f 6131 6130 6134 
f 6132 6131 6134 
f 6133 6132 6134 
f 6137 6133 6134 
f 6136 6137 6134 
f 6138 6139 6135 
f 6139 6140 6135 
f 6140 6141 6135 
f 6141 6142 6135 
f 6142 6143 6135 
f 6143 6144 6135 
f 6144 6145 6135 
f 6145 6146 6135 
f 6146 6147 6135 
f 6147 6148 6135 
f 6148 6149 6135 
f 6149 6150 6135 
f 6150 6151 6135 
f 6151 6152 6135 
f 6152 6153 6135 
f 6153 6154 6135 
f 6154 6155 6135 
f 6155 6138 6135 
f 7021 6136 6118 
f 7021 6118 6119 
f 7021 6119 6120 
f 7021 6120 6121 
f 7021 6121 6122 
f 7021 6122 6123 
f 7143 6123 6124 
f 7143 6124 6125 
f 6967 6125 6126 
f 6967 6126 6127 
f 6967 6127 6128 
f 6967 6128 6129 
f 6967 6129 6130 
f 6967 6130 6131 
f 7092 6131 6132 
f 7092 6132 6133 
f 7092 6133 6137 
f 7092 6137 6136 
f 7021 6139 6138 
f 7021 6140 6139 
f 7021 6141 6140 
f 7021 6142 6141 
f 7021 6143 6142 
f 7143 6144 6143 
f 7143 6145 6144 
f 6967 6146 6145 
f 6967 6147 6146 
f 6967 6148 6147 
f 6967 6149 6148 
f 6967 6150 6149 
f 6967 6151 6150 
f 7092 6152 6151 
f 7092 6153 6152 
f 7092 6154 6153 
f 7092 6155 6154 
f 7021 6138 6155 
f 6156 6174 6172 
f 6157 6156 6172 
f 6158 6157 6172 
f 6159 6158 6172 
f 6160 6159 6172 
f 6161 6160 6172 
f 6162 6161 6172 
f 6163 6162 6172 
f 6164 6163 6172 
f 6165 6164 6172 
f 6166 6165 6172 
f 6167 6166 6172 
f 6168 6167 6172 
f 6169 6168 6172 
f 6170 6169 6172 
f 6171 6170 6172 
f 6175 6171 6172 
f 6174 6175 6172 
f 6176 6177 6173 
f 6177 6178 6173 
f 6178 6179 6173 
f 6179 6180 6173 
f 6180 6181 6173 
f 6181 6182 6173 
f 6182 6183 6173 
f 6183 6184 6173 
f 6184 6185 6173 
f 6185 6186 6173 
f 6186 6187 6173 
f 6187 6188 6173 
f 6188 6189 6173 
f 6189 6190 6173 
f 6190 6191 6173 
f 6191 6192 6173 
f 6192 6193 6173 
f 6193 6176 6173 
f 7027 6174 6156 
f 7027 6156 6157 
f 7076 6157 6158 
f 7076 6158 6159 
f 7076 6159 6160 
f 7076 6160 6161 
f 7076 6161 6162 
f 7142 6162 6163 
f 7142 6163 6164 
f 7142 6164 6165 
f 7142 6165 6166 
f 6988 6166 6167 
f 6988 6167 6168 
f 6988 6168 6169 
f 6988 6169 6170 
f 6988 6170 6171 
f 7121 6171 6175 
f 7121 6175 6174 
f 7027 6177 6176 
f 7076 6178 6177 
f 7076 6179 6178 
f 7076 6180 6179 
f 7076 6181 6180 
f 7076 6182 6181 
f 7076 6183 6182 
f 7142 6184 6183 
f 7142 6185 6184 
f 7142 6186 6185 
f 7142 6187 6186 
f 6988 6188 6187 
f 6988 6189 6188 
f 6988 6190 6189 
f 6988 6191 6190 
f 7121 6192 6191 
f 7121 6193 6192 
f 7027 6176 6193 
f 6194 6212 6210 
f 6195 6194 6210 
f 6196 6195 6210 
f 6197 6196 6210 
f 6198 6197 6210 
f 6199 6198 6210 
f 6200 6199 6210 
f 6201 6200 6210 
f 6202 6201 6210 
f 6203 6202 6210 
f 6204 6203 6210 
f 6205 6204 6210 
f 6206 6205 6210 
f 6207 6206 6210 
f 6208 6207 6210 
f 6209 6208 6210 
f 6213 6209 6210 
f 6212 6213 6210 
f 6214 6215 6211 
f 6215 6216 6211 
f 6216 6217 6211 
f 6217 6218 6211 
f 6218 6219 6211 
f 6219 6220 6211 
f 6220 6221 6211 
f 6221 6222 6211 
f 6222 6223 6211 
f 6223 6224 6211 
f 6224 6225 6211 
f 6225 6226 6211 
f 6226 6227 6211 
f 6227 6228 6211 
f 6228 6229 6211 
f 6229 6230 6211 
f 6230 6231 6211 
f 6231 6214 6211 
f 6982 6212 6194 
f 6982 6194 6195 
f 7063 6195 6196 
f 7063 6196 6197 
f 7063 6197 6198 
f 7063 6198 6199 
f 7063 6199 6200 
f 7063 6200 6201 
f 7151 6201 6202 
f 7151 6202 6203 
f 7151 6203 6204 
f 7151 6204 6205 
f 7029 6205 6206 
f 7029 6206 6207 
f 7029 6207 6208 
f 7029 6208 6209 
f 6982 6209 6213 
f 6982 6213 6212 
f 6982 6215 6214 
f 6982 6216 6215 
f 6982 6217 6216 
f 7063 6218 6217 
f 7063 6219 6218 
f 7063 6220 6219 
f 7063 6221 6220 
f 7151 6222 6221 
f 7151 6223 6222 
f 7151 6224 6223 
f 7151 6225 6224 
f 7151 6226 6225 
f 7151 6227 6226 
f 7029 6228 6227 
f 7029 6229 6228 
f 6982 6230 6229 
f 6982 6231 6230 
f 6982 6214 6231 
f 6232 6250 6248 
f 6233 6232 6248 
f 6234 6233 6248 
f 6235 6234 6248 
f 6236 6235 6248 
f 6237 6236 6248 
f 6238 6237 6248 
f 6239 6238 6248 
f 6240 6239 6248 
f 6241 6240 6248 
f 6242 6241 6248 
f 6243 6242 6248 
f 6244 6243 6248 
f 6245 6244 6248 
f 6246 6245 6248 
f 6247 6246 6248 
f 6251 6247 6248 
f 6250 6251 6248 
f 6252 6253 6249 
f 6253 6254 6249 
f 6254 6255 6249 
f 6255 6256 6249 
f 6256 6257 6249 
f 6257 6258 6249 
f 6258 6259 6249 
f 6259 6260 6249 
f 6260 6261 6249 
f 6261 6262 6249 
f 6262 6263 6249 
f 6263 6264 6249 
f 6264 6265 6249 
f 6265 6266 6249 
f 6266 6267 6249 
f 6267 6268 6249 
f 6268 6269 6249 
f 6269 6252 6249 
f 6986 6250 6232 
f 6986 6232 6233 
f 7078 6233 6234 
f 7078 6234 6235 
f 7078 6235 6236 
f 7078 6236 6237 
f 7078 6237 6238 
f 7078 6238 6239 
f 7030 6239 6240 
f 7030 6240 6241 
f 7030 6241 6242 
f 7030 6242 6243 
f 7030 6243 6244 
f 7030 6244 6245 
f 7030 6245 6246 
f 7113 6246 6247 
f 7113 6247 6251 
f 7113 6251 6250 
f 6986 6253 6252 
f 6986 6254 6253 
f 6986 6255 6254 
f 7078 6256 6255 
f 7078 6257 6256 
f 7078 6258 6257 
f 7078 6259 6258 
f 7030 6260 6259 
f 7030 6261 6260 
f 7030 6262 6261 
f 7030 6263 6262 
f 7030 6264 6263 
f 7030 6265 6264 
f 7113 6266 6265 
f 7113 6267 6266 
f 7113 6268 6267 
f 7113 6269 6268 
f 6986 6252 6269 
f 6270 6288 6286 
f 6271 6270 6286 
f 6272 6271 6286 
f 6273 6272 6286 
f 6274 6273 6286 
f 6275 6274 6286 
f 6276 6275 6286 
f 6277 6276 6286 
f 6278 6277 6286 
f 6279 6278 6286 
f 6280 6279 6286 
f 6281 6280 6286 
f 6282 6281 6286 
f 6283 6282 6286 
f 6284 6283 6286 
f 6285 6284 6286 
f 6289 6285 6286 
f 6288 6289 6286 
f 6290 6291 6287 
f 6291 6292 6287 
f 6292 6293 6287 
f 6293 6294 6287 
f 6294 6295 6287 
f 6295 6296 6287 
f 6296 6297 6287 
f 6297 6298 6287 
f 6298 6299 6287 
f 6299 6300 6287 
f 6300 6301 6287 
f 6301 6302 6287 
f 6302 6303 6287 
f 6303 6304 6287 
f 6304 6305 6287 
f 6305 6306 6287 
f 6306 6307 6287 
f 6307 6290 6287 
f 6959 6288 6270 
f 6959 6270 6271 
f 7124 6271 6272 
f 7124 6272 6273 
f 7124 6273 6274 
f 7046 6274 6275 
f 7046 6275 6276 
f 7046 6276 6277 
f 7046 6277 6278 
f 7089 6278 6279 
f 7089 6279 6280 
f 7089 6280 6281 
f 7089 6281 6282 
f 7005 6282 6283 
f 7005 6283 6284 
f 7005 6284 6285 
f 6959 6285 6289 
f 6959 6289 6288 
f 6959 6291 6290 
f 6959 6292 6291 
f 6959 6293 6292 
f 7124 6294 6293 
f 7124 6295 6294 
f 7046 6296 6295 
f 7046 6297 6296 
f 7046 6298 6297 
f 7046 6299 6298 
f 7089 6300 6299 
f 7089 6301 6300 
f 7089 6302 6301 
f 7089 6303 6302 
f 7005 6304 6303 
f 7005 6305 6304 
f 6959 6306 6305 
f 6959 6307 6306 
f 6959 6290 6307 
f 6308 6326 6324 
f 6309 6308 6324 
f 6310 6309 6324 
f 6311 6310 6324 
f 6312 6311 6324 
f 6313 6312 6324 
f 6314 6313 6324 
f 6315 6314 6324 
f 6316 6315 6324 
f 6317 6316 6324 
f 6318 6317 6324 
f 6319 6318 6324 
f 6320 6319 6324 
f 6321 6320 6324 
f 6322 6321 6324 
f 6323 6322 6324 
f 6327 6323 6324 
f 6326 6327 6324 
f 6328 6329 6325 
f 6329 6330 6325 
f 6330 6331 6325 
f 6331 6332 6325 
f 6332 6333 6325 
f 6333 6334 6325 
f 6334 6335 6325 
f 6335 6336 6325 
f 6336 6337 6325 
f 6337 6338 6325 
f 6338 6339 6325 
f 6339 6340 6325 
f 6340 6341 6325 
f 6341 6342 6325 
f 6342 6343 6325 
f 6343 6344 6325 
f 6344 6345 6325 
f 6345 6328 6325 
f 6961 6326 6308 
f 6961 6308 6309 
f 7081 6309 6310 
f 7081 6310 6311 
f 7081 6311 6312 
f 7081 6312 6313 
f 7081 6313 6314 
f 7022 6314 6315 
f 7022 6315 6316 
f 7022 6316 6317 
f 7022 6317 6318 
f 7022 6318 6319 
f 7022 6319 6320 
f 7022 6320 6321 
f 7022 6321 6322 
f 7022 6322 6323 
f 6961 6323 6327 
f 6961 6327 6326 
f 6961 6329 6328 
f 6961 6330 6329 
f 6961 6331 6330 
f 7081 6332 6331 
f 7081 6333 6332 
f 7081 6334 6333 
f 7081 6335 6334 
f 7022 6336 6335 
f 7022 6337 6336 
f 7022 6338 6337 
f 7022 6339 6338 
f 7022 6340 6339 
f 7022 6341 6340 
f 7022 6342 6341 
f 7022 6343 6342 
f 6961 6344 6343 
f 6961 6345 6344 
f 6961 6328 6345 
f 6346 6364 6362 
f 6347 6346 6362 
f 6348 6347 6362 
f 6349 6348 6362 
f 6350 6349 6362 
f 6351 6350 6362 
f 6352 6351 6362 
f 6353 6352 6362 
f 6354 6353 6362 
f 6355 6354 6362 
f 6356 6355 6362 
f 6357 6356 6362 
f 6358 6357 6362 
f 6359 6358 6362 
f 6360 6359 6362 
f 6361 6360 6362 
f 6365 6361 6362 
f 6364 6365 6362 
f 6366 6367 6363 
f 6367 6368 6363 
f 6368 6369 6363 
f 6369 6370 6363 
f 6370 6371 6363 
f 6371 6372 6363 
f 6372 6373 6363 
f 6373 6374 6363 
f 6374 6375 6363 
f 6375 6376 6363 
f 6376 6377 6363 
f 6377 6378 6363 
f 6378 6379 6363 
f 6379 6380 6363 
f 6380 6381 6363 
f 6381 6382 6363 
f 6382 6383 6363 
f 6383 6366 6363 
f 7033 6364 6346 
f 7033 6346 6347 
f 7033 6347 6348 
f 7126 6348 6349 
f 7126 6349 6350 
f 6977 6350 6351 
f 6977 6351 6352 
f 7062 6352 6353 
f 7062 6353 6354 
f 7062 6354 6355 
f 7062 6355 6356 
f 7000 6356 6357 
f 7000 6357 6358 
f 7132 6358 6359 
f 7132 6359 6360 
f 7093 6360 6361 
f 7093 6361 6365 
f 7033 6365 6364 
f 7033 6367 6366 
f 7033 6368 6367 
f 7033 6369 6368 
f 7126 6370 6369 
f 7126 6371 6370 
f 6977 6372 6371 
f 6977 6373 6372 
f 7062 6374 6373 
f 7062 6375 6374 
f 7062 6376 6375 
f 7062 6377 6376 
f 7000 6378 6377 
f 7000 6379 6378 
f 7132 6380 6379 
f 7132 6381 6380 
f 7093 6382 6381 
f 7093 6383 6382 
f 7033 6366 6383 
f 6384 6402 6400 
f 6385 6384 6400 
f 6386 6385 6400 
f 6387 6386 6400 
f 6388 6387 6400 
f 6389 6388 6400 
f 6390 6389 6400 
f 6391 6390 6400 
f 6392 6391 6400 
f 6393 6392 6400 
f 6394 6393 6400 
f 6395 6394 6400 
f 6396 6395 6400 
f 6397 6396 6400 
f 6398 6397 6400 
f 6399 6398 6400 
f 6403 6399 6400 
f 6402 6403 6400 
f 6404 6405 6401 
f 6405 6406 6401 
f 6406 6407 6401 
f 6407 6408 6401 
f 6408 6409 6401 
f 6409 6410 6401 
f 6410 6411 6401 
f 6411 6412 6401 
f 6412 6413 6401 
f 6413 6414 6401 
f 6414 6415 6401 
f 6415 6416 6401 
f 6416 6417 6401 
f 6417 6418 6401 
f 6418 6419 6401 
f 6419 6420 6401 
f 6420 6421 6401 
f 6421 6404 6401 
f 7032 6402 6384 
f 7032 6384 6385 
f 7032 6385 6386 
f 7122 6386 6387 
f 7122 6387 6388 
f 6999 6388 6389 
f 6999 6389 6390 
f 7060 6390 6391 
f 7060 6391 6392 
f 7060 6392 6393 
f 7060 6393 6394 
f 6989 6394 6395 
f 6989 6395 6396 
f 7133 6396 6397 
f 7133 6397 6398 
f 7091 6398 6399 
f 7091 6399 6403 
f 7032 6403 6402 
f 7032 6405 6404 
f 7032 6406 6405 
f 7032 6407 6406 
f 7122 6408 6407 
f 7122 6409 6408 
f 6999 6410 6409 
f 6999 6411 6410 
f 7060 6412 6411 
f 7060 6413 6412 
f 7060 6414 6413 
f 7060 6415 6414 
f 6989 6416 6415 
f 6989 6417 6416 
f 7133 6418 6417 
f 7133 6419 6418 
f 7091 6420 6419 
f 7091 6421 6420 
f 7032 6404 6421 
f 6422 6440 6438 
f 6423 6422 6438 
f 6424 6423 6438 
f 6425 6424 6438 
f 6426 6425 6438 
f 6427 6426 6438 
f 6428 6427 6438 
f 6429 6428 6438 
f 6430 6429 6438 
f 6431 6430 6438 
f 6432 6431 6438 
f 6433 6432 6438 
f 6434 6433 6438 
f 6435 6434 6438 
f 6436 6435 6438 
f 6437 6436 6438 
f 6441 6437 6438 
f 6440 6441 6438 
f 6442 6443 6439 
f 6443 6444 6439 
f 6444 6445 6439 
f 6445 6446 6439 
f 6446 6447 6439 
f 6447 6448 6439 
f 6448 6449 6439 
f 6449 6450 6439 
f 6450 6451 6439 
f 6451 6452 6439 
f 6452 6453 6439 
f 6453 6454 6439 
f 6454 6455 6439 
f 6455 6456 6439 
f 6456 6457 6439 
f 6457 6458 6439 
f 6458 6459 6439 
f 6459 6442 6439 
f 6985 6440 6422 
f 6985 6422 6423 
f 6985 6423 6424 
f 6985 6424 6425 
f 7107 6425 6426 
f 7107 6426 6427 
f 7023 6427 6428 
f 7023 6428 6429 
f 7023 6429 6430 
f 7023 6430 6431 
f 7023 6431 6432 
f 7023 6432 6433 
f 7052 6433 6434 
f 7052 6434 6435 
f 7052 6435 6436 
f 7140 6436 6437 
f 7140 6437 6441 
f 6985 6441 6440 
f 6985 6443 6442 
f 6985 6444 6443 
f 6985 6445 6444 
f 7107 6446 6445 
f 7107 6447 6446 
f 7023 6448 6447 
f 7023 6449 6448 
f 7023 6450 6449 
f 7023 6451 6450 
f 7023 6452 6451 
f 7023 6453 6452 
f 7052 6454 6453 
f 7052 6455 6454 
f 7052 6456 6455 
f 7052 6457 6456 
f 7140 6458 6457 
f 7140 6459 6458 
f 6985 6442 6459 
f 6460 6478 6476 
f 6461 6460 6476 
f 6462 6461 6476 
f 6463 6462 6476 
f 6464 6463 6476 
f 6465 6464 6476 
f 6466 6465 6476 
f 6467 6466 6476 
f 6468 6467 6476 
f 6469 6468 6476 
f 6470 6469 6476 
f 6471 6470 6476 
f 6472 6471 6476 
f 6473 6472 6476 
f 6474 6473 6476 
f 6475 6474 6476 
f 6479 6475 6476 
f 6478 6479 6476 
f 6480 6481 6477 
f 6481 6482 6477 
f 6482 6483 6477 
f 6483 6484 6477 
f 6484 6485 6477 
f 6485 6486 6477 
f 6486 6487 6477 
f 6487 6488 6477 
f 6488 6489 6477 
f 6489 6490 6477 
f 6490 6491 6477 
f 6491 6492 6477 
f 6492 6493 6477 
f 6493 6494 6477 
f 6494 6495 6477 
f 6495 6496 6477 
f 6496 6497 6477 
f 6497 6480 6477 
f 6498 6478 6460 
f 7075 6460 6461 
f 7075 6461 6462 
f 7075 6462 6463 
f 7075 6463 6464 
f 7075 6464 6465 
f 7075 6465 6466 
f 7075 6466 6467 
f 7075 6467 6468 
f 7075 6468 6469 
f 6498 6469 6470 
f 6498 6470 6471 
f 6498 6471 6472 
f 6498 6472 6473 
f 6498 6473 6474 
f 6498 6474 6475 
f 6498 6475 6479 
f 6498 6479 6478 
f 6498 6481 6480 
f 6498 6482 6481 
f 7075 6483 6482 
f 7075 6484 6483 
f 7075 6485 6484 
f 7075 6486 6485 
f 7075 6487 6486 
f 7075 6488 6487 
f 6498 6489 6488 
f 6498 6490 6489 
f 6498 6491 6490 
f 6498 6492 6491 
f 6498 6493 6492 
f 6498 6494 6493 
f 6498 6495 6494 
f 6498 6496 6495 
f 6498 6497 6496 
f 6498 6480 6497 
f 6499 6517 6515 
f 6500 6499 6515 
f 6501 6500 6515 
f 6502 6501 6515 
f 6503 6502 6515 
f 6504 6503 6515 
f 6505 6504 6515 
f 6506 6505 6515 
f 6507 6506 6515 
f 6508 6507 6515 
f 6509 6508 6515 
f 6510 6509 6515 
f 6511 6510 6515 
f 6512 6511 6515 
f 6513 6512 6515 
f 6514 6513 6515 
f 6518 6514 6515 
f 6517 6518 6515 
f 6519 6520 6516 
f 6520 6521 6516 
f 6521 6522 6516 
f 6522 6523 6516 
f 6523 6524 6516 
f 6524 6525 6516 
f 6525 6526 6516 
f 6526 6527 6516 
f 6527 6528 6516 
f 6528 6529 6516 
f 6529 6530 6516 
f 6530 6531 6516 
f 6531 6532 6516 
f 6532 6533 6516 
f 6533 6534 6516 
f 6534 6535 6516 
f 6535 6536 6516 
f 6536 6519 6516 
f 6970 6517 6499 
f 6970 6499 6500 
f 6970 6500 6501 
f 7148 6501 6502 
f 7148 6502 6503 
f 7148 6503 6504 
f 7148 6504 6505 
f 7056 6505 6506 
f 7056 6506 6507 
f 7056 6507 6508 
f 7056 6508 6509 
f 7056 6509 6510 
f 7056 6510 6511 
f 7056 6511 6512 
f 7056 6512 6513 
f 6970 6513 6514 
f 6970 6514 6518 
f 6970 6518 6517 
f 6970 6520 6519 
f 6970 6521 6520 
f 6970 6522 6521 
f 7148 6523 6522 
f 7148 6524 6523 
f 7148 6525 6524 
f 7148 6526 6525 
f 7056 6527 6526 
f 7056 6528 6527 
f 7056 6529 6528 
f 7056 6530 6529 
f 7056 6531 6530 
f 7056 6532 6531 
f 7056 6533 6532 
f 6970 6534 6533 
f 6970 6535 6534 
f 6970 6536 6535 
f 6970 6519 6536 
f 6537 6555 6553 
f 6538 6537 6553 
f 6539 6538 6553 
f 6540 6539 6553 
f 6541 6540 6553 
f 6542 6541 6553 
f 6543 6542 6553 
f 6544 6543 6553 
f 6545 6544 6553 
f 6546 6545 6553 
f 6547 6546 6553 
f 6548 6547 6553 
f 6549 6548 6553 
f 6550 6549 6553 
f 6551 6550 6553 
f 6552 6551 6553 
f 6556 6552 6553 
f 6555 6556 6553 
f 6557 6558 6554 
f 6558 6559 6554 
f 6559 6560 6554 
f 6560 6561 6554 
f 6561 6562 6554 
f 6562 6563 6554 
f 6563 6564 6554 
f 6564 6565 6554 
f 6565 6566 6554 
f 6566 6567 6554 
f 6567 6568 6554 
f 6568 6569 6554 
f 6569 6570 6554 
f 6570 6571 6554 
f 6571 6572 6554 
f 6572 6573 6554 
f 6573 6574 6554 
f 6574 6557 6554 
f 6996 6555 6537 
f 7120 6537 6538 
f 7120 6538 6539 
f 7041 6539 6540 
f 7041 6540 6541 
f 7041 6541 6542 
f 7041 6542 6543 
f 7041 6543 6544 
f 7041 6544 6545 
f 7041 6545 6546 
f 6996 6546 6547 
f 6996 6547 6548 
f 6996 6548 6549 
f 6996 6549 6550 
f 6996 6550 6551 
f 6996 6551 6552 
f 6996 6552 6556 
f 6996 6556 6555 
f 7120 6558 6557 
f 7120 6559 6558 
f 7120 6560 6559 
f 7041 6561 6560 
f 7041 6562 6561 
f 7041 6563 6562 
f 7041 6564 6563 
f 7041 6565 6564 
f 7041 6566 6565 
f 6996 6567 6566 
f 6996 6568 6567 
f 6996 6569 6568 
f 6996 6570 6569 
f 6996 6571 6570 
f 6996 6572 6571 
f 6996 6573 6572 
f 6996 6574 6573 
f 6996 6557 6574 
f 6575 6593 6591 
f 6576 6575 6591 
f 6577 6576 6591 
f 6578 6577 6591 
f 6579 6578 6591 
f 6580 6579 6591 
f 6581 6580 6591 
f 6582 6581 6591 
f 6583 6582 6591 
f 6584 6583 6591 
f 6585 6584 6591 
f 6586 6585 6591 
f 6587 6586 6591 
f 6588 6587 6591 
f 6589 6588 6591 
f 6590 6589 6591 
f 6594 6590 6591 
f 6593 6594 6591 
f 6595 6596 6592 
f 6596 6597 6592 
f 6597 6598 6592 
f 6598 6599 6592 
f 6599 6600 6592 
f 6600 6601 6592 
f 6601 6602 6592 
f 6602 6603 6592 
f 6603 6604 6592 
f 6604 6605 6592 
f 6605 6606 6592 
f 6606 6607 6592 
f 6607 6608 6592 
f 6608 6609 6592 
f 6609 6610 6592 
f 6610 6611 6592 
f 6611 6612 6592 
f 6612 6595 6592 
f 7019 6593 6575 
f 7019 6575 6576 
f 7019 6576 6577 
f 7019 6577 6578 
f 7019 6578 6579 
f 7019 6579 6580 
f 7146 6580 6581 
f 7146 6581 6582 
f 7146 6582 6583 
f 7146 6583 6584 
f 6613 6584 6585 
f 6613 6585 6586 
f 7080 6586 6587 
f 7080 6587 6588 
f 7080 6588 6589 
f 7080 6589 6590 
f 7080 6590 6594 
f 7019 6594 6593 
f 7019 6596 6595 
f 7019 6597 6596 
f 7019 6598 6597 
f 7019 6599 6598 
f 7019 6600 6599 
f 7146 6601 6600 
f 7146 6602 6601 
f 7146 6603 6602 
f 7146 6604 6603 
f 6613 6605 6604 
f 6613 6606 6605 
f 6613 6607 6606 
f 7080 6608 6607 
f 7080 6609 6608 
f 7080 6610 6609 
f 7080 6611 6610 
f 7019 6612 6611 
f 7019 6595 6612 
f 6614 6632 6630 
f 6615 6614 6630 
f 6616 6615 6630 
f 6617 6616 6630 
f 6618 6617 6630 
f 6619 6618 6630 
f 6620 6619 6630 
f 6621 6620 6630 
f 6622 6621 6630 
f 6623 6622 6630 
f 6624 6623 6630 
f 6625 6624 6630 
f 6626 6625 6630 
f 6627 6626 6630 
f 6628 6627 6630 
f 6629 6628 6630 
f 6633 6629 6630 
f 6632 6633 6630 
f 6634 6635 6631 
f 6635 6636 6631 
f 6636 6637 6631 
f 6637 6638 6631 
f 6638 6639 6631 
f 6639 6640 6631 
f 6640 6641 6631 
f 6641 6642 6631 
f 6642 6643 6631 
f 6643 6644 6631 
f 6644 6645 6631 
f 6645 6646 6631 
f 6646 6647 6631 
f 6647 6648 6631 
f 6648 6649 6631 
f 6649 6650 6631 
f 6650 6651 6631 
f 6651 6634 6631 
f 7011 6632 6614 
f 7011 6614 6615 
f 7011 6615 6616 
f 7011 6616 6617 
f 7011 6617 6618 
f 7011 6618 6619 
f 7058 6619 6620 
f 7058 6620 6621 
f 6976 6621 6622 
f 6976 6622 6623 
f 6976 6623 6624 
f 6976 6624 6625 
f 6976 6625 6626 
f 6976 6626 6627 
f 6976 6627 6628 
f 6976 6628 6629 
f 6976 6629 6633 
f 7011 6633 6632 
f 7011 6635 6634 
f 7011 6636 6635 
f 7011 6637 6636 
f 7011 6638 6637 
f 7011 6639 6638 
f 7058 6640 6639 
f 7058 6641 6640 
f 6976 6642 6641 
f 6976 6643 6642 
f 6976 6644 6643 
f 6976 6645 6644 
f 6976 6646 6645 
f 6976 6647 6646 
f 6976 6648 6647 
f 6976 6649 6648 
f 6976 6650 6649 
f 7011 6651 6650 
f 7011 6634 6651 
f 6652 6670 6668 
f 6653 6652 6668 
f 6654 6653 6668 
f 6655 6654 6668 
f 6656 6655 6668 
f 6657 6656 6668 
f 6658 6657 6668 
f 6659 6658 6668 
f 6660 6659 6668 
f 6661 6660 6668 
f 6662 6661 6668 
f 6663 6662 6668 
f 6664 6663 6668 
f 6665 6664 6668 
f 6666 6665 6668 
f 6667 6666 6668 
f 6671 6667 6668 
f 6670 6671 6668 
f 6672 6673 6669 
f 6673 6674 6669 
f 6674 6675 6669 
f 6675 6676 6669 
f 6676 6677 6669 
f 6677 6678 6669 
f 6678 6679 6669 
f 6679 6680 6669 
f 6680 6681 6669 
f 6681 6682 6669 
f 6682 6683 6669 
f 6683 6684 6669 
f 6684 6685 6669 
f 6685 6686 6669 
f 6686 6687 6669 
f 6687 6688 6669 
f 6688 6689 6669 
f 6689 6672 6669 
f 7014 6670 6652 
f 7014 6652 6653 
f 7014 6653 6654 
f 7014 6654 6655 
f 7014 6655 6656 
f 7014 6656 6657 
f 7014 6657 6658 
f 7014 6658 6659 
f 6995 6659 6660 
f 6995 6660 6661 
f 6995 6661 6662 
f 6995 6662 6663 
f 7070 6663 6664 
f 7070 6664 6665 
f 7070 6665 6666 
f 7070 6666 6667 
f 7070 6667 6671 
f 7014 6671 6670 
f 7014 6673 6672 
f 7014 6674 6673 
f 7014 6675 6674 
f 7014 6676 6675 
f 7014 6677 6676 
f 7014 6678 6677 
f 7014 6679 6678 
f 6995 6680 6679 
f 6995 6681 6680 
f 6995 6682 6681 
f 6995 6683 6682 
f 6995 6684 6683 
f 6995 6685 6684 
f 7070 6686 6685 
f 7070 6687 6686 
f 7070 6688 6687 
f 7070 6689 6688 
f 7014 6672 6689 
f 6690 6708 6706 
f 6691 6690 6706 
f 6692 6691 6706 
f 6693 6692 6706 
f 6694 6693 6706 
f 6695 6694 6706 
f 6696 6695 6706 
f 6697 6696 6706 
f 6698 6697 6706 
f 6699 6698 6706 
f 6700 6699 6706 
f 6701 6700 6706 
f 6702 6701 6706 
f 6703 6702 6706 
f 6704 6703 6706 
f 6705 6704 6706 
f 6709 6705 6706 
f 6708 6709 6706 
f 6710 6711 6707 
f 6711 6712 6707 
f 6712 6713 6707 
f 6713 6714 6707 
f 6714 6715 6707 
f 6715 6716 6707 
f 6716 6717 6707 
f 6717 6718 6707 
f 6718 6719 6707 
f 6719 6720 6707 
f 6720 6721 6707 
f 6721 6722 6707 
f 6722 6723 6707 
f 6723 6724 6707 
f 6724 6725 6707 
f 6725 6726 6707 
f 6726 6727 6707 
f 6727 6710 6707 
f 6984 6708 6690 
f 6984 6690 6691 
f 6984 6691 6692 
f 7139 6692 6693 
f 7139 6693 6694 
f 7139 6694 6695 
f 7139 6695 6696 
f 7139 6696 6697 
f 7154 6697 6698 
f 7154 6698 6699 
f 7154 6699 6700 
f 7154 6700 6701 
f 7053 6701 6702 
f 7053 6702 6703 
f 7053 6703 6704 
f 7053 6704 6705 
f 7053 6705 6709 
f 6984 6709 6708 
f 6984 6711 6710 
f 6984 6712 6711 
f 6984 6713 6712 
f 6984 6714 6713 
f 6984 6715 6714 
f 7139 6716 6715 
f 7139 6717 6716 
f 7154 6718 6717 
f 7154 6719 6718 
f 7154 6720 6719 
f 7154 6721 6720 
f 7154 6722 6721 
f 7154 6723 6722 
f 7053 6724 6723 
f 7053 6725 6724 
f 7053 6726 6725 
f 7053 6727 6726 
f 6984 6710 6727 
f 6728 6746 6744 
f 6729 6728 6744 
f 6730 6729 6744 
f 6731 6730 6744 
f 6732 6731 6744 
f 6733 6732 6744 
f 6734 6733 6744 
f 6735 6734 6744 
f 6736 6735 6744 
f 6737 6736 6744 
f 6738 6737 6744 
f 6739 6738 6744 
f 6740 6739 6744 
f 6741 6740 6744 
f 6742 6741 6744 
f 6743 6742 6744 
f 6747 6743 6744 
f 6746 6747 6744 
f 6748 6749 6745 
f 6749 6750 6745 
f 6750 6751 6745 
f 6751 6752 6745 
f 6752 6753 6745 
f 6753 6754 6745 
f 6754 6755 6745 
f 6755 6756 6745 
f 6756 6757 6745 
f 6757 6758 6745 
f 6758 6759 6745 
f 6759 6760 6745 
f 6760 6761 6745 
f 6761 6762 6745 
f 6762 6763 6745 
f 6763 6764 6745 
f 6764 6765 6745 
f 6765 6748 6745 
f 7009 6746 6728 
f 7009 6728 6729 
f 7009 6729 6730 
f 7009 6730 6731 
f 7009 6731 6732 
f 7009 6732 6733 
f 7009 6733 6734 
f 7009 6734 6735 
f 6766 6735 6736 
f 6766 6736 6737 
f 6766 6737 6738 
f 6766 6738 6739 
f 7067 6739 6740 
f 7067 6740 6741 
f 7067 6741 6742 
f 7067 6742 6743 
f 7009 6743 6747 
f 7009 6747 6746 
f 7009 6749 6748 
f 7009 6750 6749 
f 7009 6751 6750 
f 7009 6752 6751 
f 7009 6753 6752 
f 7009 6754 6753 
f 7009 6755 6754 
f 6766 6756 6755 
f 6766 6757 6756 
f 6766 6758 6757 
f 6766 6759 6758 
f 6766 6760 6759 
f 7067 6761 6760 
f 7067 6762 6761 
f 7067 6763 6762 
f 7067 6764 6763 
f 7009 6765 6764 
f 7009 6748 6765 
f 6767 6785 6783 
f 6768 6767 6783 
f 6769 6768 6783 
f 6770 6769 6783 
f 6771 6770 6783 
f 6772 6771 6783 
f 6773 6772 6783 
f 6774 6773 6783 
f 6775 6774 6783 
f 6776 6775 6783 
f 6777 6776 6783 
f 6778 6777 6783 
f 6779 6778 6783 
f 6780 6779 6783 
f 6781 6780 6783 
f 6782 6781 6783 
f 6786 6782 6783 
f 6785 6786 6783 
f 6787 6788 6784 
f 6788 6789 6784 
f 6789 6790 6784 
f 6790 6791 6784 
f 6791 6792 6784 
f 6792 6793 6784 
f 6793 6794 6784 
f 6794 6795 6784 
f 6795 6796 6784 
f 6796 6797 6784 
f 6797 6798 6784 
f 6798 6799 6784 
f 6799 6800 6784 
f 6800 6801 6784 
f 6801 6802 6784 
f 6802 6803 6784 
f 6803 6804 6784 
f 6804 6787 6784 
f 7018 6785 6767 
f 7018 6767 6768 
f 7018 6768 6769 
f 7018 6769 6770 
f 7018 6770 6771 
f 7018 6771 6772 
f 7073 6772 6773 
f 7073 6773 6774 
f 6805 6774 6775 
f 6805 6775 6776 
f 6805 6776 6777 
f 6805 6777 6778 
f 6805 6778 6779 
f 6805 6779 6780 
f 7101 6780 6781 
f 7101 6781 6782 
f 7101 6782 6786 
f 7018 6786 6785 
f 7018 6788 6787 
f 7018 6789 6788 
f 7018 6790 6789 
f 7018 6791 6790 
f 7018 6792 6791 
f 7073 6793 6792 
f 7073 6794 6793 
f 6805 6795 6794 
f 6805 6796 6795 
f 6805 6797 6796 
f 6805 6798 6797 
f 6805 6799 6798 
f 6805 6800 6799 
f 6805 6801 6800 
f 7101 6802 6801 
f 7101 6803 6802 
f 7018 6804 6803 
f 7018 6787 6804 
f 6806 6824 6822 
f 6807 6806 6822 
f 6808 6807 6822 
f 6809 6808 6822 
f 6810 6809 6822 
f 6811 6810 6822 
f 6812 6811 6822 
f 6813 6812 6822 
f 6814 6813 6822 
f 6815 6814 6822 
f 6816 6815 6822 
f 6817 6816 6822 
f 6818 6817 6822 
f 6819 6818 6822 
f 6820 6819 6822 
f 6821 6820 6822 
f 6825 6821 6822 
f 6824 6825 6822 
f 6826 6827 6823 
f 6827 6828 6823 
f 6828 6829 6823 
f 6829 6830 6823 
f 6830 6831 6823 
f 6831 6832 6823 
f 6832 6833 6823 
f 6833 6834 6823 
f 6834 6835 6823 
f 6835 6836 6823 
f 6836 6837 6823 
f 6837 6838 6823 
f 6838 6839 6823 
f 6839 6840 6823 
f 6840 6841 6823 
f 6841 6842 6823 
f 6842 6843 6823 
f 6843 6826 6823 
f 6979 6824 6806 
f 6979 6806 6807 
f 6979 6807 6808 
f 7086 6808 6809 
f 7086 6809 6810 
f 7086 6810 6811 
f 7115 6811 6812 
f 7115 6812 6813 
f 7115 6813 6814 
f 7115 6814 6815 
f 7115 6815 6816 
f 7115 6816 6817 
f 7035 6817 6818 
f 7035 6818 6819 
f 7035 6819 6820 
f 7035 6820 6821 
f 7035 6821 6825 
f 6979 6825 6824 
f 6979 6827 6826 
f 6979 6828 6827 
f 6979 6829 6828 
f 6979 6830 6829 
f 7086 6831 6830 
f 7086 6832 6831 
f 7115 6833 6832 
f 7115 6834 6833 
f 7115 6835 6834 
f 7115 6836 6835 
f 7115 6837 6836 
f 7115 6838 6837 
f 7035 6839 6838 
f 7035 6840 6839 
f 7035 6841 6840 
f 7035 6842 6841 
f 6979 6843 6842 
f 6979 6826 6843 
f 6844 6862 6860 
f 6845 6844 6860 
f 6846 6845 6860 
f 6847 6846 6860 
f 6848 6847 6860 
f 6849 6848 6860 
f 6850 6849 6860 
f 6851 6850 6860 
f 6852 6851 6860 
f 6853 6852 6860 
f 6854 6853 6860 
f 6855 6854 6860 
f 6856 6855 6860 
f 6857 6856 6860 
f 6858 6857 6860 
f 6859 6858 6860 
f 6863 6859 6860 
f 6862 6863 6860 
f 6864 6865 6861 
f 6865 6866 6861 
f 6866 6867 6861 
f 6867 6868 6861 
f 6868 6869 6861 
f 6869 6870 6861 
f 6870 6871 6861 
f 6871 6872 6861 
f 6872 6873 6861 
f 6873 6874 6861 
f 6874 6875 6861 
f 6875 6876 6861 
f 6876 6877 6861 
f 6877 6878 6861 
f 6878 6879 6861 
f 6879 6880 6861 
f 6880 6881 6861 
f 6881 6864 6861 
f 6974 6862 6844 
f 6974 6844 6845 
f 6974 6845 6846 
f 7085 6846 6847 
f 7085 6847 6848 
f 7085 6848 6849 
f 7034 6849 6850 
f 7034 6850 6851 
f 7034 6851 6852 
f 7034 6852 6853 
f 7034 6853 6854 
f 7034 6854 6855 
f 7034 6855 6856 
f 7034 6856 6857 
f 7034 6857 6858 
f 6974 6858 6859 
f 6974 6859 6863 
f 6974 6863 6862 
f 6974 6865 6864 
f 6974 6866 6865 
f 6974 6867 6866 
f 6974 6868 6867 
f 7085 6869 6868 
f 7085 6870 6869 
f 7034 6871 6870 
f 7034 6872 6871 
f 7034 6873 6872 
f 7034 6874 6873 
f 7034 6875 6874 
f 7034 6876 6875 
f 7034 6877 6876 
f 7034 6878 6877 
f 6974 6879 6878 
f 6974 6880 6879 
f 6974 6881 6880 
f 6974 6864 6881 
f 6882 6900 6898 
f 6883 6882 6898 
f 6884 6883 6898 
f 6885 6884 6898 
f 6886 6885 6898 
f 6887 6886 6898 
f 6888 6887 6898 
f 6889 6888 6898 
f 6890 6889 6898 
f 6891 6890 6898 
f 6892 6891 6898 
f 6893 6892 6898 
f 6894 6893 6898 
f 6895 6894 6898 
f 6896 6895 6898 
f 6897 6896 6898 
f 6901 6897 6898 
f 6900 6901 6898 
f 6902 6903 6899 
f 6903 6904 6899 
f 6904 6905 6899 
f 6905 6906 6899 
f 6906 6907 6899 
f 6907 6908 6899 
f 6908 6909 6899 
f 6909 6910 6899 
f 6910 6911 6899 
f 6911 6912 6899 
f 6912 6913 6899 
f 6913 6914 6899 
f 6914 6915 6899 
f 6915 6916 6899 
f 6916 6917 6899 
f 6917 6918 6899 
f 6918 6919 6899 
f 6919 6902 6899 
f 7048 6900 6882 
f 7015 6882 6883 
f 7015 6883 6884 
f 7015 6884 6885 
f 7015 6885 6886 
f 7015 6886 6887 
f 7100 6887 6888 
f 7100 6888 6889 
f 6920 6889 6890 
f 6920 6890 6891 
f 6920 6891 6892 
f 6920 6892 6893 
f 6920 6893 6894 
f 6920 6894 6895 
f 6920 6895 6896 
f 7048 6896 6897 
f 7048 6897 6901 
f 7048 6901 6900 
f 7015 6903 6902 
f 7015 6904 6903 
f 7015 6905 6904 
f 7015 6906 6905 
f 7015 6907 6906 
f 7015 6908 6907 
f 7015 6909 6908 
f 7100 6910 6909 
f 7100 6911 6910 
f 6920 6912 6911 
f 6920 6913 6912 
f 6920 6914 6913 
f 6920 6915 6914 
f 6920 6916 6915 
f 7048 6917 6916 
f 7048 6918 6917 
f 7048 6919 6918 
f 7048 6902 6919 
f 6921 6939 6937 
f 6922 6921 6937 
f 6923 6922 6937 
f 6924 6923 6937 
f 6925 6924 6937 
f 6926 6925 6937 
f 6927 6926 6937 
f 6928 6927 6937 
f 6929 6928 6937 
f 6930 6929 6937 
f 6931 6930 6937 
f 6932 6931 6937 
f 6933 6932 6937 
f 6934 6933 6937 
f 6935 6934 6937 
f 6936 6935 6937 
f 6940 6936 6937 
f 6939 6940 6937 
f 6941 6942 6938 
f 6942 6943 6938 
f 6943 6944 6938 
f 6944 6945 6938 
f 6945 6946 6938 
f 6946 6947 6938 
f 6947 6948 6938 
f 6948 6949 6938 
f 6949 6950 6938 
f 6950 6951 6938 
f 6951 6952 6938 
f 6952 6953 6938 
f 6953 6954 6938 
f 6954 6955 6938 
f 6955 6956 6938 
f 6956 6957 6938 
f 6957 6958 6938 
f 6958 6941 6938 
f 7003 6939 6921 
f 7003 6921 6922 
f 7003 6922 6923 
f 7049 6923 6924 
f 7049 6924 6925 
f 7049 6925 6926 
f 6991 6926 6927 
f 6991 6927 6928 
f 6991 6928 6929 
f 6991 6929 6930 
f 6991 6930 6931 
f 6991 6931 6932 
f 6991 6932 6933 
f 6991 6933 6934 
f 6991 6934 6935 
f 7110 6935 6936 
f 7110 6936 6940 
f 7003 6940 6939 
f 7003 6942 6941 
f 7003 6943 6942 
f 7003 6944 6943 
f 7049 6945 6944 
f 7049 6946 6945 
f 7049 6947 6946 
f 7049 6948 6947 
f 6991 6949 6948 
f 6991 6950 6949 
f 6991 6951 6950 
f 6991 6952 6951 
f 6991 6953 6952 
f 6991 6954 6953 
f 6991 6955 6954 
f 6991 6956 6955 
f 6991 6957 6956 
f 7110 6958 6957 
f 7110 6941 6958 
f 5773 5774 7055 
f 5763 7088 5774 
f 5741 5774 7088 
f 5755 7055 5774 
f 6403 7032 7091 
f 6421 7091 7032 
f 6394 6989 7060 
f 6415 7060 6989 
f 6365 7033 7093 
f 6356 7000 7062 
f 6383 7093 7033 
f 6377 7062 7000 
f 6010 7131 7039 
f 6037 7098 7105 
f 6016 7105 7098 
f 6030 7039 7131 
f 5992 7002 6990 
f 5979 7051 6990 
f 6001 6990 7051 
f 5970 6990 7002 
f 6935 7110 6991 
f 6926 6991 7049 
f 6948 7049 6991 
f 6957 6991 7110 
f 5961 7004 7084 
f 5941 7084 7004 
f 5930 7045 7116 
f 5951 7116 7045 
f 6305 7005 6959 
f 6285 6959 7005 
f 6278 7089 7046 
f 6299 7046 7089 
f 5926 7047 6992 
f 5908 6992 7047 
f 5895 7006 6992 
f 5915 6992 7006 
f 5727 7069 6966 
f 5705 6966 7069 
f 5698 7007 7123 
f 5719 7123 7007 
f 5000 7090 7097 
f 4978 7097 7090 
f 4972 6987 7044 
f 4992 7044 6987 
f 6735 6766 7009 
f 6743 7009 7067 
f 6764 7067 7009 
f 6755 7009 6766 
f 5146 7031 7010 
f 5131 6997 7083 
f 5152 7083 6997 
f 5125 7010 7031 
f 6641 7058 6976 
f 6633 7011 6976 
f 6621 6976 7058 
f 6650 6976 7011 
f 5857 7012 7114 
f 5865 7065 7135 
f 5886 7135 7065 
f 5877 7114 7012 
f 6116 6993 7013 
f 6098 7013 6993 
f 6107 7013 7103 
f 6087 7103 7013 
f 6679 7014 6995 
f 6671 7014 7070 
f 6689 7070 7014 
f 6659 6995 7014 
f 6887 7100 7015 
f 6909 7015 7100 
f 6882 7015 7048 
f 6902 7048 7015 
f 5487 7016 7109 
f 5505 7109 7016 
f 5474 6969 7094 
f 5495 7094 6969 
f 5513 5544 7017 
f 5525 7017 7125 
f 5543 7125 7017 
f 5533 7017 5544 
f 6803 7101 7018 
f 6792 7018 7073 
f 6786 7018 7101 
f 6772 7073 7018 
f 6600 7019 7146 
f 6611 7080 7019 
f 6580 7146 7019 
f 6594 7019 7080 
f 5671 5697 7149 
f 5692 7149 5697 
f 5659 7064 7130 
f 5680 7130 7064 
f 6155 7092 7021 
f 6143 7021 7143 
f 6136 7021 7092 
f 6123 7143 7021 
f 6314 7022 7081 
f 6343 7022 6961 
f 6335 7081 7022 
f 6323 6961 7022 
f 6441 6985 7140 
f 6425 7107 6985 
f 6445 6985 7107 
f 6459 7140 6985 
f 5044 7061 7024 
f 5013 6983 7024 
f 5033 7024 6983 
f 5023 7024 7061 
f 5596 7025 6972 
f 5617 6972 7025 
f 5607 7059 7108 
f 5586 7108 7059 
f 5191 7079 7127 
f 5161 7119 5199 
f 5182 5199 7119 
f 5171 7127 7079 
f 6171 7121 6988 
f 6191 6988 7121 
f 6162 7142 7076 
f 6183 7076 7142 
f 5571 7028 7054 
f 5550 7117 7028 
f 5546 7028 7150 
f 5566 6965 7028 
f 6221 7063 7151 
f 6229 7029 6982 
f 6201 7151 7063 
f 6209 6982 7029 
f 6269 7113 6986 
f 6239 7030 7078 
f 6259 7078 7030 
f 6250 6986 7113 
f 5158 7102 7031 
f 5137 7031 7102 
f 6407 7032 7122 
f 6386 7122 7032 
f 6348 7126 7033 
f 6369 7033 7126 
f 6878 7034 6974 
f 6858 6974 7034 
f 6849 7034 7085 
f 6870 7085 7034 
f 6811 7115 7086 
f 6832 7086 7115 
f 6842 7035 6979 
f 6825 6979 7035 
f 5642 7036 7137 
f 5632 7153 7099 
f 5652 7099 7153 
f 5639 7137 7036 
f 5798 7136 7087 
f 5789 6971 7037 
f 5810 7037 6971 
f 5777 7087 7136 
f 6055 7104 7038 
f 6047 7141 6963 
f 6076 7038 7104 
f 6067 6963 7141 
f 6004 7039 7001 
f 6023 7105 7039 
f 5202 7040 6978 
f 5223 6978 7040 
f 5234 7106 6978 
f 5214 6978 7106 
f 6566 7041 6996 
f 6546 6996 7041 
f 6537 7120 6996 
f 6557 6996 7120 
f 5072 7042 5084 
f 5051 5084 7042 
f 5081 7147 7042 
f 5061 7042 7147 
f 5712 7043 6966 
f 5733 6966 7043 
f 4985 7044 7128 
f 5006 7128 7044 
f 5934 7095 7045 
f 5955 7045 7095 
f 6293 6959 7124 
f 6271 7124 6959 
f 5901 7047 7096 
f 5922 7096 7047 
f 6916 6920 7048 
f 6896 7048 6920 
f 6923 7049 7003 
f 6944 7003 7049 
f 5244 7111 7050 
f 5238 7050 6968 
f 5259 6968 7050 
f 5263 7050 7111 
f 5985 7051 7002 
f 5984 7002 7051 
f 6433 7052 7023 
f 6453 7023 7052 
f 6717 7139 7154 
f 6709 6984 7053 
f 6727 7053 6984 
f 6697 7154 7139 
f 5580 7054 7129 
f 5558 7129 7054 
f 5736 6998 7055 
f 5757 7055 6998 
f 6533 7056 6970 
f 6505 7056 7148 
f 6513 6970 7056 
f 6526 7148 7056 
f 5288 7144 6973 
f 5309 6973 7144 
f 5301 7057 6973 
f 5282 6973 7057 
f 6639 7011 7058 
f 6619 7058 7011 
f 5584 7059 7112 
f 5605 7112 7059 
f 6390 7060 6999 
f 6411 6999 7060 
f 5017 7061 7134 
f 5038 7134 7061 
f 6352 7062 6977 
f 6373 6977 7062 
f 6217 6982 7063 
f 6195 7063 6982 
f 5686 7064 7020 
f 5664 7020 7064 
f 5851 6962 7065 
f 5872 7065 6962 
f 5108 6964 7145 
f 5097 6964 7066 
f 5118 7066 6964 
f 5088 7145 6964 
f 6760 6766 7067 
f 6739 7067 6766 
f 5452 7068 7152 
f 5430 7152 7068 
f 5461 5467 7068 
f 5442 7068 5467 
f 5723 7007 7118 
f 5701 7118 7007 
f 6663 7070 6995 
f 6685 6995 7070 
f 5318 6975 7071 
f 5333 7071 7138 
f 5351 7138 7071 
f 5337 7071 6975 
f 5420 5428 7072 
f 7173 7155 7156 
f 5400 7072 5428 
f 7174 7157 7158 
f 6794 7073 6805 
f 6774 6805 7073 
f 5861 7074 7012 
f 5882 7012 7074 
f 6482 6498 7075 
f 7177 7159 7160 
f 6460 7075 6498 
f 7178 7161 7162 
f 6177 7027 7076 
f 6157 7076 7027 
f 5848 7180 7179 
f 5837 7179 7180 
f 7175 7163 7164 
f 7176 7165 7166 
f 6255 6986 7078 
f 6233 7078 6986 
f 5186 7026 7079 
f 5165 7079 7026 
f 6607 6613 7080 
f 6586 7080 6613 
f 6331 6961 7081 
f 6309 7081 6961 
f 5377 7082 6981 
f 5358 6981 7082 
f 7171 7167 7168 
f 7172 7169 7170 
f 5129 7083 7010 
f 5150 7010 7083 
f 5946 6960 7084 
f 5963 7084 6960 
f 6846 7085 6974 
f 6868 6974 7085 
f 6830 6979 7086 
f 6808 7086 6979 
f 5781 7037 7087 
f 5802 7087 7037 
f 5739 7088 6998 
f 5761 6998 7088 
f 6282 7005 7089 
f 6303 7089 7005 
f 4974 7090 6987 
f 4996 6987 7090 
f 6398 7091 7133 
f 6419 7133 7091 
f 6151 6967 7092 
f 6131 7092 6967 
f 6360 7093 7132 
f 6381 7132 7093 
f 5470 7094 7016 
f 5491 7016 7094 
f 5936 7004 7095 
f 5957 7095 7004 
f 5899 7096 7006 
f 5920 7006 7096 
f 5002 7097 7008 
f 4981 7008 7097 
f 6014 7098 6041 
f 6035 6041 7098 
f 5648 6980 7099 
f 5628 7099 6980 
f 6911 7100 6920 
f 6889 6920 7100 
f 6780 7101 6805 
f 6801 6805 7101 
f 5135 7102 6997 
f 5156 6997 7102 
f 6089 6993 7103 
f 6112 7103 6993 
f 6065 7104 6963 
f 6043 6963 7104 
f 7039 7105 7001 
f 6021 7001 7105 
f 5210 7106 7040 
f 5230 7040 7106 
f 6427 7023 7107 
f 6447 7107 7023 
f 5588 6972 7108 
f 5609 7108 6972 
f 5482 7109 6969 
f 5503 6969 7109 
f 6940 7003 7110 
f 6941 7110 7003 
f 5250 6968 7111 
f 5271 7111 6968 
f 5603 7025 7112 
f 5601 7112 7025 
f 6265 7030 7113 
f 6246 7113 7030 
f 5875 6962 7114 
f 5855 7114 6962 
f 6838 7115 7035 
f 6817 7035 7115 
f 5928 7116 6960 
f 5949 6960 7116 
f 7054 7028 7117 
f 5552 7054 7117 
f 5703 7069 7118 
f 5725 7118 7069 
f 5163 7026 7119 
f 5184 7119 7026 
f 6560 7120 7041 
f 6539 7041 7120 
f 6193 7121 7027 
f 6174 7027 7121 
f 6388 6999 7122 
f 6409 7122 6999 
f 5717 7123 7043 
f 5735 7043 7123 
f 6274 7046 7124 
f 6295 7124 7046 
f 5541 5544 7125 
f 5520 7125 5544 
f 6350 6977 7126 
f 6371 7126 6977 
f 5175 5199 7127 
f 5195 7127 5199 
f 5004 7008 7128 
f 4983 7128 7008 
f 5560 6965 7129 
f 5582 7129 6965 
f 5678 7130 5697 
f 5696 5697 7130 
f 6032 7131 6041 
f 6012 6041 7131 
f 6358 7132 7000 
f 6379 7000 7132 
f 6417 6989 7133 
f 6396 7133 6989 
f 5035 6983 7134 
f 5015 7134 6983 
f 5863 7135 7074 
f 5884 7074 7135 
f 5775 7136 6971 
f 5796 6971 7136 
f 5644 7137 6980 
f 5622 6980 7137 
f 5324 7138 6975 
f 5345 6975 7138 
f 6715 6984 7139 
f 6692 7139 6984 
f 6436 7140 7052 
f 6457 7052 7140 
f 6071 7141 7038 
f 6051 7038 7141 
f 6187 7142 6988 
f 6166 6988 7142 
f 6125 6967 7143 
f 6145 7143 6967 
f 5313 7144 7057 
f 5295 7057 7144 
f 5092 7066 7145 
f 5112 7145 7066 
f 6604 7146 6613 
f 6584 6613 7146 
f 5057 7147 5084 
f 5077 5084 7147 
f 6522 6970 7148 
f 6501 7148 6970 
f 5666 7149 7020 
f 5688 7020 7149 
f 7028 6965 7150 
f 5563 7150 6965 
f 6205 7029 7151 
f 6227 7151 7029 
f 5435 5467 7152 
f 5456 7152 5467 
f 5656 7153 7036 
f 5636 7036 7153 
f 6723 7154 7053 
f 6701 7053 7154 
f 7181 7198 7197 
f 7182 7181 7197 
f 7183 7182 7197 
f 7184 7183 7197 
f 7185 7184 7197 
f 7186 7185 7197 
f 7187 7186 7197 
f 7188 7187 7197 
f 7189 7188 7197 
f 7190 7189 7197 
f 7191 7190 7197 
f 7192 7191 7197 
f 7193 7192 7197 
f 7194 7193 7197 
f 7195 7194 7197 
f 7196 7195 7197 
f 7199 7196 7197 
f 7198 7199 7197 
f 7200 7201 7490 
f 7201 7202 7490 
f 7202 7203 7490 
f 7203 7204 7490 
f 7204 7205 7490 
f 7205 7206 7490 
f 7206 7207 7490 
f 7207 7208 7490 
f 7208 7209 7490 
f 7209 7210 7490 
f 7210 7211 7490 
f 7211 7212 7490 
f 7212 7213 7490 
f 7213 7214 7490 
f 7214 7215 7490 
f 7215 7216 7490 
f 7216 7217 7490 
f 7217 7200 7490 
f 7524 7198 7181 
f 7524 7181 7182 
f 7501 7182 7183 
f 7501 7183 7184 
f 7506 7184 7185 
f 7506 7185 7186 
f 7476 7186 7187 
f 7476 7187 7188 
f 7476 7188 7189 
f 7476 7189 7190 
f 7531 7190 7191 
f 7531 7191 7192 
f 7502 7192 7193 
f 7502 7193 7194 
f 7528 7194 7195 
f 7528 7195 7196 
f 7542 7196 7199 
f 7542 7199 7198 
f 7524 7201 7200 
f 7524 7202 7201 
f 7501 7203 7202 
f 7501 7204 7203 
f 7506 7205 7204 
f 7506 7206 7205 
f 7476 7207 7206 
f 7476 7208 7207 
f 7476 7209 7208 
f 7476 7210 7209 
f 7476 7211 7210 
f 7476 7212 7211 
f 7502 7213 7212 
f 7502 7214 7213 
f 7524 7215 7214 
f 7524 7216 7215 
f 7524 7217 7216 
f 7524 7200 7217 
f 7218 7235 7234 
f 7219 7218 7234 
f 7220 7219 7234 
f 7221 7220 7234 
f 7222 7221 7234 
f 7223 7222 7234 
f 7224 7223 7234 
f 7225 7224 7234 
f 7226 7225 7234 
f 7227 7226 7234 
f 7228 7227 7234 
f 7229 7228 7234 
f 7230 7229 7234 
f 7231 7230 7234 
f 7232 7231 7234 
f 7233 7232 7234 
f 7236 7233 7234 
f 7235 7236 7234 
f 7237 7238 7489 
f 7238 7239 7489 
f 7239 7240 7489 
f 7240 7241 7489 
f 7241 7242 7489 
f 7242 7243 7489 
f 7243 7244 7489 
f 7244 7245 7489 
f 7245 7246 7489 
f 7246 7247 7489 
f 7247 7248 7489 
f 7248 7249 7489 
f 7249 7250 7489 
f 7250 7251 7489 
f 7251 7252 7489 
f 7252 7253 7489 
f 7253 7254 7489 
f 7254 7237 7489 
f 7537 7235 7218 
f 7537 7218 7219 
f 7537 7219 7220 
f 7537 7220 7221 
f 7537 7221 7222 
f 7537 7222 7223 
f 7497 7223 7224 
f 7497 7224 7225 
f 7517 7225 7226 
f 7517 7226 7227 
f 7517 7227 7228 
f 7517 7228 7229 
f 7517 7229 7230 
f 7507 7230 7231 
f 7507 7231 7232 
f 7492 7232 7233 
f 7492 7233 7236 
f 7492 7236 7235 
f 7492 7238 7237 
f 7537 7239 7238 
f 7537 7240 7239 
f 7537 7241 7240 
f 7537 7242 7241 
f 7497 7243 7242 
f 7497 7244 7243 
f 7517 7245 7244 
f 7517 7246 7245 
f 7541 7247 7246 
f 7541 7248 7247 
f 7521 7249 7248 
f 7521 7250 7249 
f 7529 7251 7250 
f 7529 7252 7251 
f 7507 7253 7252 
f 7507 7254 7253 
f 7492 7237 7254 
f 7255 7271 7487 
f 7256 7255 7487 
f 7257 7256 7487 
f 7258 7257 7487 
f 7259 7258 7487 
f 7260 7259 7487 
f 7261 7260 7487 
f 7262 7261 7487 
f 7263 7262 7487 
f 7264 7263 7487 
f 7265 7264 7487 
f 7266 7265 7487 
f 7267 7266 7487 
f 7268 7267 7487 
f 7269 7268 7487 
f 7270 7269 7487 
f 7272 7270 7487 
f 7271 7272 7487 
f 7273 7274 7491 
f 7274 7275 7491 
f 7275 7276 7491 
f 7276 7277 7491 
f 7277 7278 7491 
f 7278 7279 7491 
f 7279 7280 7491 
f 7280 7281 7491 
f 7281 7282 7491 
f 7282 7283 7491 
f 7283 7284 7491 
f 7284 7285 7491 
f 7285 7286 7491 
f 7286 7287 7491 
f 7287 7288 7491 
f 7288 7289 7491 
f 7289 7290 7491 
f 7290 7273 7491 
f 7512 7271 7255 
f 7512 7255 7256 
f 7532 7256 7257 
f 7532 7257 7258 
f 7479 7258 7259 
f 7479 7259 7260 
f 7479 7260 7261 
f 7479 7261 7262 
f 7538 7262 7263 
f 7538 7263 7264 
f 7538 7264 7265 
f 7510 7265 7266 
f 7510 7266 7267 
f 7510 7267 7268 
f 7512 7268 7269 
f 7512 7269 7270 
f 7512 7270 7272 
f 7512 7272 7271 
f 7532 7274 7273 
f 7532 7275 7274 
f 7532 7276 7275 
f 7532 7277 7276 
f 7479 7278 7277 
f 7479 7279 7278 
f 7479 7280 7279 
f 7479 7281 7280 
f 7538 7282 7281 
f 7538 7283 7282 
f 7538 7284 7283 
f 7493 7285 7284 
f 7493 7286 7285 
f 7493 7287 7286 
f 7513 7288 7287 
f 7512 7289 7288 
f 7512 7290 7289 
f 7512 7273 7290 
f 7291 7307 7485 
f 7292 7291 7485 
f 7293 7292 7485 
f 7294 7293 7485 
f 7295 7294 7485 
f 7296 7295 7485 
f 7297 7296 7485 
f 7298 7297 7485 
f 7299 7298 7485 
f 7300 7299 7485 
f 7301 7300 7485 
f 7302 7301 7485 
f 7303 7302 7485 
f 7304 7303 7485 
f 7305 7304 7485 
f 7306 7305 7485 
f 7308 7306 7485 
f 7307 7308 7485 
f 7309 7310 7488 
f 7310 7311 7488 
f 7311 7312 7488 
f 7312 7313 7488 
f 7313 7314 7488 
f 7314 7315 7488 
f 7315 7316 7488 
f 7316 7317 7488 
f 7317 7318 7488 
f 7318 7319 7488 
f 7319 7320 7488 
f 7320 7321 7488 
f 7321 7322 7488 
f 7322 7323 7488 
f 7323 7324 7488 
f 7324 7325 7488 
f 7325 7326 7488 
f 7326 7309 7488 
f 7327 7307 7291 
f 7327 7291 7292 
f 7327 7292 7293 
f 7327 7293 7294 
f 7539 7294 7295 
f 7539 7295 7296 
f 7522 7296 7297 
f 7522 7297 7298 
f 7499 7298 7299 
f 7499 7299 7300 
f 7499 7300 7301 
f 7499 7301 7302 
f 7499 7302 7303 
f 7520 7303 7304 
f 7520 7304 7305 
f 7520 7305 7306 
f 7327 7306 7308 
f 7327 7308 7307 
f 7327 7310 7309 
f 7327 7311 7310 
f 7327 7312 7311 
f 7539 7313 7312 
f 7539 7314 7313 
f 7522 7315 7314 
f 7522 7316 7315 
f 7499 7317 7316 
f 7499 7318 7317 
f 7540 7319 7318 
f 7540 7320 7319 
f 7515 7321 7320 
f 7515 7322 7321 
f 7535 7323 7322 
f 7535 7324 7323 
f 7520 7325 7324 
f 7520 7326 7325 
f 7327 7309 7326 
f 7328 7345 7344 
f 7329 7328 7344 
f 7330 7329 7344 
f 7331 7330 7344 
f 7332 7331 7344 
f 7333 7332 7344 
f 7334 7333 7344 
f 7335 7334 7344 
f 7336 7335 7344 
f 7337 7336 7344 
f 7338 7337 7344 
f 7339 7338 7344 
f 7340 7339 7344 
f 7341 7340 7344 
f 7342 7341 7344 
f 7343 7342 7344 
f 7346 7343 7344 
f 7345 7346 7344 
f 7347 7348 7481 
f 7348 7349 7481 
f 7349 7350 7481 
f 7350 7351 7481 
f 7351 7352 7481 
f 7352 7353 7481 
f 7353 7354 7481 
f 7354 7355 7481 
f 7355 7356 7481 
f 7356 7357 7481 
f 7357 7358 7481 
f 7358 7359 7481 
f 7359 7360 7481 
f 7360 7361 7481 
f 7361 7362 7481 
f 7362 7363 7481 
f 7363 7364 7481 
f 7364 7347 7481 
f 7518 7345 7328 
f 7518 7328 7329 
f 7505 7329 7330 
f 7505 7330 7331 
f 7534 7331 7332 
f 7534 7332 7333 
f 7477 7333 7334 
f 7477 7334 7335 
f 7477 7335 7336 
f 7477 7336 7337 
f 7514 7337 7338 
f 7514 7338 7339 
f 7514 7339 7340 
f 7514 7340 7341 
f 7514 7341 7342 
f 7514 7342 7343 
f 7543 7343 7346 
f 7543 7346 7345 
f 7518 7348 7347 
f 7518 7349 7348 
f 7505 7350 7349 
f 7505 7351 7350 
f 7534 7352 7351 
f 7534 7353 7352 
f 7477 7354 7353 
f 7477 7355 7354 
f 7477 7356 7355 
f 7477 7357 7356 
f 7477 7358 7357 
f 7477 7359 7358 
f 7498 7360 7359 
f 7498 7361 7360 
f 7518 7362 7361 
f 7518 7363 7362 
f 7518 7364 7363 
f 7518 7347 7364 
f 7365 7382 7482 
f 7366 7365 7482 
f 7367 7366 7482 
f 7368 7367 7482 
f 7369 7368 7482 
f 7370 7369 7482 
f 7371 7370 7482 
f 7372 7371 7482 
f 7373 7372 7482 
f 7374 7373 7482 
f 7375 7374 7482 
f 7376 7375 7482 
f 7377 7376 7482 
f 7378 7377 7482 
f 7379 7378 7482 
f 7380 7379 7482 
f 7383 7380 7482 
f 7382 7383 7482 
f 7384 7385 7381 
f 7385 7386 7381 
f 7386 7387 7381 
f 7387 7388 7381 
f 7388 7389 7381 
f 7389 7390 7381 
f 7390 7391 7381 
f 7391 7392 7381 
f 7392 7393 7381 
f 7393 7394 7381 
f 7394 7395 7381 
f 7395 7396 7381 
f 7396 7397 7381 
f 7397 7398 7381 
f 7398 7399 7381 
f 7399 7400 7381 
f 7400 7401 7381 
f 7401 7384 7381 
f 7516 7382 7365 
f 7516 7365 7366 
f 7475 7366 7367 
f 7475 7367 7368 
f 7526 7368 7369 
f 7526 7369 7370 
f 7519 7370 7371 
f 7519 7371 7372 
f 7519 7372 7373 
f 7519 7373 7374 
f 7519 7374 7375 
f 7519 7375 7376 
f 7496 7376 7377 
f 7496 7377 7378 
f 7530 7378 7379 
f 7530 7379 7380 
f 7516 7380 7383 
f 7516 7383 7382 
f 7516 7385 7384 
f 7516 7386 7385 
f 7475 7387 7386 
f 7475 7388 7387 
f 7526 7389 7388 
f 7526 7390 7389 
f 7519 7391 7390 
f 7519 7392 7391 
f 7544 7393 7392 
f 7544 7394 7393 
f 7503 7395 7394 
f 7503 7396 7395 
f 7503 7397 7396 
f 7530 7398 7397 
f 7530 7399 7398 
f 7530 7400 7399 
f 7516 7401 7400 
f 7516 7384 7401 
f 7402 7418 7480 
f 7403 7402 7480 
f 7404 7403 7480 
f 7405 7404 7480 
f 7406 7405 7480 
f 7407 7406 7480 
f 7408 7407 7480 
f 7409 7408 7480 
f 7410 7409 7480 
f 7411 7410 7480 
f 7412 7411 7480 
f 7413 7412 7480 
f 7414 7413 7480 
f 7415 7414 7480 
f 7416 7415 7480 
f 7417 7416 7480 
f 7419 7417 7480 
f 7418 7419 7480 
f 7420 7421 7483 
f 7421 7422 7483 
f 7422 7423 7483 
f 7423 7424 7483 
f 7424 7425 7483 
f 7425 7426 7483 
f 7426 7427 7483 
f 7427 7428 7483 
f 7428 7429 7483 
f 7429 7430 7483 
f 7430 7431 7483 
f 7431 7432 7483 
f 7432 7433 7483 
f 7433 7434 7483 
f 7434 7435 7483 
f 7435 7436 7483 
f 7436 7437 7483 
f 7437 7420 7483 
f 7536 7418 7402 
f 7536 7402 7403 
f 7494 7403 7404 
f 7494 7404 7405 
f 7478 7405 7406 
f 7478 7406 7407 
f 7523 7407 7408 
f 7523 7408 7409 
f 7527 7409 7410 
f 7527 7410 7411 
f 7527 7411 7412 
f 7527 7412 7413 
f 7504 7413 7414 
f 7504 7414 7415 
f 7504 7415 7416 
f 7536 7416 7417 
f 7536 7417 7419 
f 7536 7419 7418 
f 7494 7421 7420 
f 7494 7422 7421 
f 7494 7423 7422 
f 7494 7424 7423 
f 7478 7425 7424 
f 7478 7426 7425 
f 7523 7427 7426 
f 7523 7428 7427 
f 7523 7429 7428 
f 7523 7430 7429 
f 7523 7431 7430 
f 7523 7432 7431 
f 7509 7433 7432 
f 7509 7434 7433 
f 7509 7435 7434 
f 7536 7436 7435 
f 7536 7437 7436 
f 7536 7420 7437 
f 7438 7454 7486 
f 7439 7438 7486 
f 7440 7439 7486 
f 7441 7440 7486 
f 7442 7441 7486 
f 7443 7442 7486 
f 7444 7443 7486 
f 7445 7444 7486 
f 7446 7445 7486 
f 7447 7446 7486 
f 7448 7447 7486 
f 7449 7448 7486 
f 7450 7449 7486 
f 7451 7450 7486 
f 7452 7451 7486 
f 7453 7452 7486 
f 7455 7453 7486 
f 7454 7455 7486 
f 7456 7457 7484 
f 7457 7458 7484 
f 7458 7459 7484 
f 7459 7460 7484 
f 7460 7461 7484 
f 7461 7462 7484 
f 7462 7463 7484 
f 7463 7464 7484 
f 7464 7465 7484 
f 7465 7466 7484 
f 7466 7467 7484 
f 7467 7468 7484 
f 7468 7469 7484 
f 7469 7470 7484 
f 7470 7471 7484 
f 7471 7472 7484 
f 7472 7473 7484 
f 7473 7456 7484 
f 7533 7454 7438 
f 7533 7438 7439 
f 7508 7439 7440 
f 7508 7440 7441 
f 7474 7441 7442 
f 7474 7442 7443 
f 7474 7443 7444 
f 7474 7444 7445 
f 7511 7445 7446 
f 7511 7446 7447 
f 7525 7447 7448 
f 7525 7448 7449 
f 7525 7449 7450 
f 7500 7450 7451 
f 7500 7451 7452 
f 7500 7452 7453 
f 7545 7453 7455 
f 7545 7455 7454 
f 7508 7457 7456 
f 7508 7458 7457 
f 7508 7459 7458 
f 7508 7460 7459 
f 7474 7461 7460 
f 7474 7462 7461 
f 7474 7463 7462 
f 7474 7464 7463 
f 7511 7465 7464 
f 7511 7466 7465 
f 7495 7467 7466 
f 7495 7468 7467 
f 7495 7469 7468 
f 7495 7470 7469 
f 7533 7471 7470 
f 7533 7472 7471 
f 7533 7473 7472 
f 7533 7456 7473 
f 7258 7479 7532 
f 7277 7532 7479 
f 7268 7512 7510 
f 7284 7538 7493 
f 7432 7523 7509 
f 7416 7536 7504 
f 7405 7478 7494 
f 7424 7494 7478 
f 7466 7511 7495 
f 7447 7525 7511 
f 7450 7500 7525 
f 7470 7495 7533 
f 7376 7496 7519 
f 7366 7475 7516 
f 7397 7503 7530 
f 7386 7516 7475 
f 7250 7521 7529 
f 7238 7492 7537 
f 7235 7537 7492 
f 7230 7507 7517 
f 7329 7505 7518 
f 7349 7518 7505 
f 7359 7477 7498 
f 7337 7514 7477 
f 7296 7522 7539 
f 7306 7327 7520 
f 7326 7520 7327 
f 7314 7539 7522 
f 7439 7508 7533 
f 7456 7533 7508 
f 7204 7501 7506 
f 7214 7502 7524 
f 7196 7542 7528 
f 7184 7506 7501 
f 7192 7502 7531 
f 7212 7476 7502 
f 7394 7544 7503 
f 7519 7496 7503 
f 7527 7504 7509 
f 7413 7504 7527 
f 7331 7534 7505 
f 7351 7505 7534 
f 7186 7476 7506 
f 7206 7506 7476 
f 7232 7492 7507 
f 7254 7507 7492 
f 7441 7474 7508 
f 7460 7508 7474 
f 7536 7509 7504 
f 7435 7509 7536 
f 7265 7510 7538 
f 7538 7510 7493 
f 7445 7511 7474 
f 7464 7474 7511 
f 7510 7512 7513 
f 7288 7513 7512 
f 7513 7493 7510 
f 7287 7493 7513 
f 7361 7498 7518 
f 7498 7477 7514 
f 7320 7540 7515 
f 7303 7520 7499 
f 7380 7516 7530 
f 7400 7530 7516 
f 7244 7497 7517 
f 7225 7517 7497 
f 7343 7543 7514 
f 7518 7498 7514 
f 7368 7526 7475 
f 7388 7475 7526 
f 7499 7520 7515 
f 7322 7515 7535 
f 7517 7507 7521 
f 7248 7541 7521 
f 7298 7499 7522 
f 7316 7522 7499 
f 7407 7523 7478 
f 7426 7478 7523 
f 7182 7501 7524 
f 7202 7524 7501 
f 7495 7511 7525 
f 7525 7500 7495 
f 7370 7519 7526 
f 7390 7526 7519 
f 7409 7527 7523 
f 7509 7523 7527 
f 7194 7528 7502 
f 7524 7502 7528 
f 7252 7529 7507 
f 7529 7521 7507 
f 7378 7530 7496 
f 7496 7530 7503 
f 7531 7502 7476 
f 7190 7531 7476 
f 7273 7512 7532 
f 7256 7532 7512 
f 7453 7545 7500 
f 7533 7495 7500 
f 7333 7477 7534 
f 7353 7534 7477 
f 7535 7515 7520 
f 7324 7535 7520 
f 7403 7494 7536 
f 7420 7536 7494 
f 7242 7537 7497 
f 7223 7497 7537 
f 7262 7538 7479 
f 7281 7479 7538 
f 7312 7327 7539 
f 7294 7539 7327 
f 7515 7540 7499 
f 7318 7499 7540 
f 7246 7517 7541 
f 7521 7541 7517 
f 7528 7542 7524 
f 7198 7524 7542 
f 7514 7543 7518 
f 7345 7518 7543 
f 7392 7519 7544 
f 7503 7544 7519 
f 7500 7545 7533 
f 7454 7533 7545 
f 8024 7791 7718 
f 7750 7928 7931 
f 7870 7646 7786 
f 7794 8096 7651 
f 7888 7710 7981 
f 7581 8028 8003 
f 7722 8004 8041 
f 8017 7838 7765 
f 7626 7772 7980 
f 8058 8033 7985 
f 7710 7596 7981 
f 7861 8042 7648 
f 7848 7891 7957 
f 7739 7905 7973 
f 7600 7987 7870 
f 8089 8101 7710 
f 7977 8081 7695 
f 7768 7659 7863 
f 8025 7875 7865 
f 7616 8084 7546 
f 8034 7924 7791 
f 7838 7635 7734 
f 7811 7828 7625 
f 8020 7607 8038 
f 7733 8063 8102 
f 7926 7798 7864 
f 7809 7830 8100 
f 7844 7735 7720 
f 8046 7720 7942 
f 7702 7740 7867 
f 8013 7821 7784 
f 7769 7887 7778 
f 7960 7959 7952 
f 7807 7976 8128 
f 7637 8018 7752 
f 7731 7643 7910 
f 7709 8118 7759 
f 7820 7673 7702 
f 7604 8117 7613 
f 7784 7927 8013 
f 7957 8087 7930 
f 7735 7912 7900 
f 7583 7834 8005 
f 8119 7829 7903 
f 7997 7695 7814 
f 7762 8120 7895 
f 8128 7965 7807 
f 7677 8062 7967 
f 7763 7615 7785 
f 7801 7890 7675 
f 7855 7657 7943 
f 7648 8042 7723 
f 7778 7856 8076 
f 7861 7548 8042 
f 7649 7688 7609 
f 7934 8017 7765 
f 8068 7689 7882 
f 7785 7615 8107 
f 8123 7799 7677 
f 7910 7643 7733 
f 7946 7915 7907 
f 7891 8056 8131 
f 7701 7758 8099 
f 7724 7860 7673 
f 7947 7761 7663 
f 7636 7795 7711 
f 7724 7876 7582 
f 8069 7905 7739 
f 7725 7694 7986 
f 7695 7715 7977 
f 8038 7892 8020 
f 7736 8005 7603 
f 8019 7759 7800 
f 7862 7832 8096 
f 7947 8047 7823 
f 7622 7995 7917 
f 7717 7824 8003 
f 7979 7700 8083 
f 7889 7609 7588 
f 8099 7758 7843 
f 7800 7787 8019 
f 7673 7820 7816 
f 8015 7663 8074 
f 7857 7621 7686 
f 7683 7744 8022 
f 7733 7806 7910 
f 7903 7817 7645 
f 7942 7654 7914 
f 7803 8064 8051 
f 8051 7669 7803 
f 8062 8091 7796 
f 8091 7662 7945 
f 7567 7629 8102 
f 8044 7975 7994 
f 7774 7781 7841 
f 7941 7841 8050 
f 7696 7575 7953 
f 7781 7774 7873 
f 7799 8123 7938 
f 8055 7704 7913 
f 7956 7902 8122 
f 7802 7640 7677 
f 7987 7600 7933 
f 8108 7644 7950 
f 7776 7866 8043 
f 7557 8029 7601 
f 7675 7890 8007 
f 7747 7882 7689 
f 7606 7682 8058 
f 7819 7694 7834 
f 7687 7983 7833 
f 7853 7604 7746 
f 8010 7661 7563 
f 7857 7661 8077 
f 7809 7869 7830 
f 7946 7606 7915 
f 8032 8025 7865 
f 8120 7762 7699 
f 7786 7646 7982 
f 7608 7850 7809 
f 7588 7609 7843 
f 8072 7620 7856 
f 7610 7883 7723 
f 7921 7734 8133 
f 7571 7999 7884 
f 7835 7627 7937 
f 7612 8012 7626 
f 7848 7853 7746 
f 7905 8057 7973 
f 8088 7859 7704 
f 7640 7802 7614 
f 7615 7756 7630 
f 8051 7890 7801 
f 7616 7546 7875 
f 7700 7979 7558 
f 7947 7823 7995 
f 7749 7740 7605 
f 7874 7749 7813 
f 7995 7586 7991 
f 7600 8104 7933 
f 7966 7619 7718 
f 7664 7708 7760 
f 7778 8076 7769 
f 7686 7621 8035 
f 7879 7851 7821 
f 7679 8112 8079 
f 7898 7761 7755 
f 7871 7574 8048 
f 7871 7616 7574 
f 7788 7893 7962 
f 7788 7663 7624 
f 7953 7575 7625 
f 7767 7811 7625 
f 7626 8136 7772 
f 8136 7626 8130 
f 8058 7932 8033 
f 7949 7627 7569 
f 7577 7858 7902 
f 7901 7628 7802 
f 7629 7975 7595 
f 8087 7793 8105 
f 7938 7790 8121 
f 7742 7706 7790 
f 7941 8075 7750 
f 8000 7910 7806 
f 7959 7960 7753 
f 7765 7551 7934 
f 7633 7980 7772 
f 7864 7693 7634 
f 7634 8000 7547 
f 7634 7748 7864 
f 8045 7708 7554 
f 7656 8045 7826 
f 7824 7852 8003 
f 7852 7795 7636 
f 7887 7951 7676 
f 7935 7637 7752 
f 7906 7638 8002 
f 8019 7787 7906 
f 8033 8049 7985 
f 7942 7703 8046 
f 7790 7899 7742 
f 8088 7704 8055 
f 7585 8039 7832 
f 7905 8069 7800 
f 8099 8108 7701 
f 7613 7642 7940 
f 7733 7643 8063 
f 7808 7994 7975 
f 7714 7791 8024 
f 7881 7797 7644 
f 7817 7814 7831 
f 7880 7829 8119 
f 7982 7646 7751 
f 7700 8001 7782 
f 7823 8047 7647 
f 7647 8047 7686 
f 7824 7717 7783 
f 7723 7883 7648 
f 7588 7958 7889 
f 7993 7597 7873 
f 8084 7650 7546 
f 7546 7807 7965 
f 7994 7808 7968 
f 8059 7641 7759 
f 7652 7743 7586 
f 7813 7872 7970 
f 7851 7990 7821 
f 8002 7582 7556 
f 7557 7601 7866 
f 7735 7900 7720 
f 7982 7751 7655 
f 7842 8025 8032 
f 7928 7734 7931 
f 8111 8037 7774 
f 7978 7566 7992 
f 8122 7677 7967 
f 7586 7874 7652 
f 8013 7687 7833 
f 7659 7907 7915 
f 7829 7880 7926 
f 7725 8114 7660 
f 7916 8012 7612 
f 7605 7740 7929 
f 8010 7605 7661 
f 7799 7938 8129 
f 7810 8082 7572 
f 8134 7996 7963 
f 7732 7849 7885 
f 7955 7664 7769 
f 7576 7664 7920 
f 7702 7867 8053 
f 7789 7674 7665 
f 7839 8061 7984 
f 8080 8125 7815 
f 7592 8097 7703 
f 7914 7668 7592 
f 7952 7801 7960 
f 7669 7779 8094 
f 7791 7714 8036 
f 7836 8067 7624 
f 7845 7729 7671 
f 7589 8026 7673 
f 7556 7582 7876 
f 8006 7957 7930 
f 8006 7604 7853 
f 7602 7753 7960 
f 8007 7978 7675 
f 8041 7896 7773 
f 7974 7778 7773 
f 7599 7988 8054 
f 7901 8016 7628 
f 7911 7558 7979 
f 7911 7678 7783 
f 8061 7839 8080 
f 7771 7989 7815 
f 7679 8010 7563 
f 7995 7622 7947 
f 7680 7868 7777 
f 7793 7680 8103 
f 7668 7914 7552 
f 7776 8043 7997 
f 7694 7682 7939 
f 7809 7850 7603 
f 7683 7928 7591 
f 7919 7683 7591 
f 7684 7757 7770 
f 7948 7898 8085 
f 7732 7788 8021 
f 7686 7963 7996 
f 7936 7927 7784 
f 7687 7789 7983 
f 7548 7812 7696 
f 7920 7998 7576 
f 8023 8086 7998 
f 7857 7788 7962 
f 7563 8112 7679 
f 7798 7831 7691 
f 7633 7971 7886 
f 7692 7572 7745 
f 7662 8116 7945 
f 7864 7561 7693 
f 7561 7736 7892 
f 7949 7569 7694 
f 7694 7569 7682 
f 7715 7695 7846 
f 7695 7997 8043 
f 7575 7696 7993 
f 7688 7649 7827 
f 7804 7944 7727 
f 7899 8073 7697 
f 7965 7884 7546 
f 8060 7762 7865 
f 7700 7782 7579 
f 7797 7619 7966 
f 8108 7922 8101 
f 7862 8101 7642 
f 8077 7661 7860 
f 7740 7702 7929 
f 7915 7863 7659 
f 8098 7703 7639 
f 8109 7859 7577 
f 7956 7763 8132 
f 8133 7705 7921 
f 7611 7895 8120 
f 7598 8121 7818 
f 7894 7706 7918 
f 8121 7598 7938 
f 7904 7742 8031 
f 7769 8076 7955 
f 7554 7955 7620 
f 7709 8105 7793 
f 7589 8019 7906 
f 7862 7585 7832 
f 7862 8057 7585 
f 8003 7711 7581 
f 7614 7712 7640 
f 7614 7577 7859 
f 7878 7712 7730 
f 7712 7614 8011 
f 8108 8099 7644 
f 7644 7714 7881 
f 7715 7612 7805 
f 7715 7601 8029 
f 8034 7795 7852 
f 7670 8030 7716 
f 7717 7648 7574 
f 7861 7648 8028 
f 7718 8083 7966 
f 7924 8034 7852 
f 7733 8102 7923 
f 7870 7786 7719 
f 7720 7900 7654 
f 7654 7942 7720 
f 7721 7902 7858 
f 7802 7677 7901 
f 8128 7976 7952 
f 8041 7565 7722 
f 7856 7723 7826 
f 7828 7656 8070 
f 7724 7851 7879 
f 7860 7724 8077 
f 7780 7627 7949 
f 8078 7949 8009 
f 7963 7580 8134 
f 7885 8090 7732 
f 8055 7913 8115 
f 7899 7727 7944 
f 7573 7697 7925 
f 7727 7899 7640 
f 7858 8054 7988 
f 7728 7628 8016 
f 7624 7671 8008 
f 7893 7729 7690 
f 7836 8015 7729 
f 8074 7729 8015 
f 7878 7730 8011 
f 8110 7730 7712 
f 7968 7651 7549 
f 7651 7731 7794 
f 7788 7732 7663 
f 7963 7686 7663 
f 7923 7830 7733 
f 7733 8040 7806 
f 8045 7656 7734 
f 7656 7931 7734 
f 7932 8113 7735 
f 7932 7735 7844 
f 7850 7608 7584 
f 8020 7892 7736 
f 8067 7836 7972 
f 7729 7845 7836 
f 7738 7765 7838 
f 7884 7965 7571 
f 7638 7739 7653 
f 7739 7613 8117 
f 8013 7833 7658 
f 7749 7658 7740 
f 7877 7741 7922 
f 7891 8052 8056 
f 8107 8031 7742 
f 8107 7913 7785 
f 7991 8027 7872 
f 7743 7652 7847 
f 7744 7683 7919 
f 7744 7919 7892 
f 7745 7594 7961 
f 8014 7945 7745 
f 7613 7746 7604 
f 7940 7746 7613 
f 7753 7602 8086 
f 7747 7978 7992 
f 7748 7634 7907 
f 7829 7748 7907 
f 7617 7749 7917 
f 7991 7872 7617 
f 7928 7750 7591 
f 7750 8075 7591 
f 7751 8093 7655 
f 7558 8001 7700 
f 7752 7593 7935 
f 7855 7943 7752 
f 7551 8023 7998 
f 7753 7551 7959 
f 7639 7754 7909 
f 7792 8049 7639 
f 7755 7815 7989 
f 7989 7898 7755 
f 8064 7572 7756 
f 7756 8129 7938 
f 7948 7757 7898 
f 8079 7690 7757 
f 7761 7898 7757 
f 7757 7684 8079 
f 7843 7758 7588 
f 7758 7701 8089 
f 7897 7759 8118 
f 7759 7549 8059 
f 8017 7635 7838 
f 7635 7760 7708 
f 7757 7690 8074 
f 7729 8074 7690 
f 7607 7865 7762 
f 7607 8022 7744 
f 7978 8007 7566 
f 7763 7956 8122 
f 7647 7764 7586 
f 7586 7764 7658 
f 7765 7738 7632 
f 7551 7765 7632 
f 7660 8009 7725 
f 8012 8130 7626 
f 7625 7575 7767 
f 7993 7873 7575 
f 8097 7592 7668 
f 7768 7903 7829 
f 7855 7752 8018 
f 7576 7769 7664 
f 7685 7948 8085 
f 7908 7770 7685 
f 8127 7771 7622 
f 7622 8061 7947 
f 7772 7583 8005 
f 7561 7633 7772 
f 7896 7623 7773 
f 8048 7773 7623 
f 7560 8111 7774 
f 7750 7560 7941 
f 8011 8110 7712 
f 7730 7964 7713 
f 7552 7681 7776 
f 7681 7654 7776 
f 7629 7777 7600 
f 7595 8103 7629 
f 7676 7773 7778 
f 7676 7778 7887 
f 7976 7565 7779 
f 7779 7565 7969 
f 7937 7553 7835 
f 7766 7553 8078 
f 7781 8092 7888 
f 7873 8092 7781 
f 7646 7579 7782 
f 7646 7782 8001 
f 7574 7783 7717 
f 7616 7783 7574 
f 7784 8117 7936 
f 7653 7990 7582 
f 7956 8132 8109 
f 7913 7704 7785 
f 7786 7982 7608 
f 7608 7719 7786 
f 7787 7800 8069 
f 7638 7906 7787 
f 7788 7686 7590 
f 7788 7857 7686 
f 7927 7936 7789 
f 7604 7674 7789 
f 8123 7677 7640 
f 7640 7899 7790 
f 7670 7791 8036 
f 7670 7716 7791 
f 8058 7985 7606 
f 7915 7606 8124 
f 8103 7595 7793 
f 8118 8044 7897 
f 8050 8075 7941 
f 7910 7794 7731 
f 7795 8030 7711 
f 8030 7795 8034 
f 7796 7943 7657 
f 7566 7967 7657 
f 8104 8065 7933 
f 8065 7700 7579 
f 7691 7570 7798 
f 7971 7864 7798 
f 8062 7662 8091 
f 7572 8082 7799 
f 7905 7800 7641 
f 7759 7641 7800 
f 7669 8051 7801 
f 7801 7952 7669 
f 7614 7802 7577 
f 7802 7628 7577 
f 7593 7803 7669 
f 8014 8091 7945 
f 7804 7925 7697 
f 8135 7804 7697 
f 7805 7612 7626 
f 7977 7626 7980 
f 7547 8000 7806 
f 7907 7806 8040 
f 7546 7650 7807 
f 8004 7722 7807 
f 7643 7808 7822 
f 7643 7731 7808 
f 7809 7682 7869 
f 7682 7809 7939 
f 7572 7692 7810 
f 7810 7692 7594 
f 7656 7828 8111 
f 8111 7931 7656 
f 7827 7812 7954 
f 7652 7813 7970 
f 7813 7652 7874 
f 7814 7691 7831 
f 8081 7977 7980 
f 7666 7815 8125 
f 7815 7666 7771 
f 7673 7816 7589 
f 7709 7589 8105 
f 7552 7776 7997 
f 7817 7903 7552 
f 7894 7818 8121 
f 8071 7598 7818 
f 8130 8114 7583 
f 7834 7583 7819 
f 7674 7816 8053 
f 8053 7665 7674 
f 7821 8035 7621 
f 7764 8035 7821 
f 7822 7808 7567 
f 8102 7822 7567 
f 7647 7586 7823 
f 7823 7586 7995 
f 7587 7824 7678 
f 7678 7824 7783 
f 7825 7766 8130 
f 7553 7766 7825 
f 7856 7826 8072 
f 7554 7826 8045 
f 7827 7696 7812 
f 7649 7696 7827 
f 7828 8070 7723 
f 7828 7723 8042 
f 7907 7659 7829 
f 7768 7829 7659 
f 7578 7830 7869 
f 7733 7830 8040 
f 7880 8119 7831 
f 7645 7817 7831 
f 7832 7651 8096 
f 8059 7832 8039 
f 7867 7740 7833 
f 7740 7658 7833 
f 7834 7603 8005 
f 7939 7834 7694 
f 7735 8113 7912 
f 7553 7612 7835 
f 7737 7972 7836 
f 8095 7836 7845 
f 7697 7698 8135 
f 8073 7899 7944 
f 7838 7571 7738 
f 7734 7921 7838 
f 8125 8080 7839 
f 7984 7667 7839 
f 7580 7840 8134 
f 8106 7840 7580 
f 7596 7841 7781 
f 8050 7841 7596 
f 7584 7608 7842 
f 7607 8020 7842 
f 7843 7550 8099 
f 7703 8098 8046 
f 7844 8033 7932 
f 8067 7672 7845 
f 7845 7672 8095 
f 7601 7715 7846 
f 7601 8043 7866 
f 8027 7970 7872 
f 7618 7743 7847 
f 7891 7848 8052 
f 7642 7741 7940 
f 7849 7590 7726 
f 7590 8021 7788 
f 7584 7736 7850 
f 7603 7850 7736 
f 7851 7582 7990 
f 7582 7851 7724 
f 7852 7824 7587 
f 7587 7924 7852 
f 7853 7848 7568 
f 7853 7568 8006 
f 8054 7854 7599 
f 7854 7728 8016 
f 7855 7769 7576 
f 7855 8068 7882 
f 7610 7974 7773 
f 7723 7856 7610 
f 7857 7563 7661 
f 7962 7563 7857 
f 7628 7728 7858 
f 7577 7628 7858 
f 7712 7878 7859 
f 7859 8088 7712 
f 7860 7661 7702 
f 7860 7702 7673 
f 8028 7581 7861 
f 7862 8096 7710 
f 8101 7862 7710 
f 7863 7639 7703 
f 7703 8097 7863 
f 7864 7971 8126 
f 7633 7561 7864 
f 7546 7865 7875 
f 7865 7546 8060 
f 7866 7654 7557 
f 7776 7654 7866 
f 7665 8053 7867 
f 7833 7983 7867 
f 7777 7868 7600 
f 8131 8056 7950 
f 7869 7682 7606 
f 7578 7869 7606 
f 7870 8102 7629 
f 7600 7870 7629 
f 8084 7616 7871 
f 7871 8048 8084 
f 7813 7749 7872 
f 7749 7617 7872 
f 8037 7575 7873 
f 7774 8037 7873 
f 7658 7749 7874 
f 7874 7586 7658 
f 7655 8093 7875 
f 7911 7875 8093 
f 7673 7876 7724 
f 7673 8026 7876 
f 7555 7950 8056 
f 7922 7555 7877 
f 8011 7614 7878 
f 7859 7878 7614 
f 7821 7621 7879 
f 7879 8077 7724 
f 7926 7880 7798 
f 7831 7798 7880 
f 7797 7881 7619 
f 7619 8024 7718 
f 7747 7992 7882 
f 7657 7855 7882 
f 7883 8048 7574 
f 7883 7574 7648 
f 7699 7884 7999 
f 8060 7546 7884 
f 7726 7840 8106 
f 7726 7885 7849 
f 7691 7886 7570 
f 7691 8081 7980 
f 7887 7969 7951 
f 7887 8018 7969 
f 7888 7958 7588 
f 7710 7888 8089 
f 7649 7889 7958 
f 7609 7889 7649 
f 7756 7615 7890 
f 8007 7890 7615 
f 8087 7957 7891 
f 7680 7891 7868 
f 7693 7892 7919 
f 7561 7892 7693 
f 7624 8008 7893 
f 7893 7788 7624 
f 7707 7818 7894 
f 7918 8071 7894 
f 7895 7611 7683 
f 8022 7895 7683 
f 7896 8041 8004 
f 7623 7896 7650 
f 7994 7897 8044 
f 7549 7759 7897 
f 7564 7898 7989 
f 7898 7564 7684 
f 8115 7742 7899 
f 7697 8115 7899 
f 7912 7557 7900 
f 7900 7557 7654 
f 7721 7901 7677 
f 7988 8016 7901 
f 7902 7721 7677 
f 7677 8122 7902 
f 7768 7668 7903 
f 7903 7668 7552 
f 7742 7904 7706 
f 7904 7918 7706 
f 8057 7905 7585 
f 8039 7585 7905 
f 8002 7556 7906 
f 7589 7906 8026 
f 7634 7547 7907 
f 7806 7907 7547 
f 7770 7908 7684 
f 7684 8085 7898 
f 7909 8098 7639 
f 8049 7562 7909 
f 7631 7910 8000 
f 7794 7910 7631 
f 7875 7911 7616 
f 7783 7616 7911 
f 8029 7557 7912 
f 7912 7835 7612 
f 7742 8115 7913 
f 7742 7913 8107 
f 7681 7552 7914 
f 7914 7654 7681 
f 7639 7863 7915 
f 8124 7639 7915 
f 7916 7612 7553 
f 7916 7553 7825 
f 7605 7622 7917 
f 7749 7605 7917 
f 7598 8071 7918 
f 7918 7904 7598 
f 7919 7634 7693 
f 7591 7634 7919 
f 7934 7551 7920 
f 7998 7920 7551 
f 7921 7999 7571 
f 7571 7838 7921 
f 7741 7642 7922 
f 7922 7642 8101 
f 7809 8100 7608 
f 7923 7719 7608 
f 7924 7718 7791 
f 7587 7718 7924 
f 7727 7925 7804 
f 7925 7727 7573 
f 7926 7748 7829 
f 7926 7864 7748 
f 7789 7687 7927 
f 7687 8013 7927 
f 7611 7928 7683 
f 7734 7928 8133 
f 7661 7929 7702 
f 7605 7929 7661 
f 7674 7930 7816 
f 7589 7816 8105 
f 8111 7560 7931 
f 7931 7560 7750 
f 7627 8113 7932 
f 7627 7932 7569 
f 7933 7579 7987 
f 7579 7933 8065 
f 7920 7664 7934 
f 7760 7934 7664 
f 7669 7935 7593 
f 8094 7935 7669 
f 8117 7604 7936 
f 7604 7789 7936 
f 7553 7937 7780 
f 7627 7780 7937 
f 7938 7630 7756 
f 7938 7598 7630 
f 7834 7939 7603 
f 7603 7939 7809 
f 7940 7741 7848 
f 7746 7940 7848 
f 7841 7941 7774 
f 7560 7774 7941 
f 7914 7592 7942 
f 7703 7942 7592 
f 8091 8014 7593 
f 7593 7752 7943 
f 8073 7944 7837 
f 7944 7804 8135 
f 7945 7594 7745 
f 7594 7945 8116 
f 8040 7946 7907 
f 7606 7946 7578 
f 8080 7947 8061 
f 7947 7755 7761 
f 7685 7770 7948 
f 7770 7757 7948 
f 7949 7725 8009 
f 7694 7725 7949 
f 7950 7600 8131 
f 7950 8104 7600 
f 7951 7969 7565 
f 7951 7565 8041 
f 7779 7669 7952 
f 7779 7952 7976 
f 7696 7953 7548 
f 7548 7953 8042 
f 7954 7688 7827 
f 7708 7664 7955 
f 7955 7554 7708 
f 7902 7956 7577 
f 8109 7577 7956 
f 7957 8006 7568 
f 7957 7568 7848 
f 7958 7888 8092 
f 7597 7649 7958 
f 7632 7959 7551 
f 7952 7959 8128 
f 7960 7801 7675 
f 7960 7675 7602 
f 7745 7961 7692 
f 7692 7961 7594 
f 7690 7962 7893 
f 7563 7962 7690 
f 7732 7963 7663 
f 7963 7732 8090 
f 8110 7964 7730 
f 7964 7775 7713 
f 7632 7738 7965 
f 7738 7571 7965 
f 7700 7797 7966 
f 8083 7700 7966 
f 7796 7657 7967 
f 7967 8062 7796 
f 7968 7808 7731 
f 7968 7731 7651 
f 8094 7779 7969 
f 7969 8018 7637 
f 7970 7847 7652 
f 7970 7618 7847 
f 7971 7570 7886 
f 7971 7798 7570 
f 7972 8095 7672 
f 7972 7672 8067 
f 7973 7642 7613 
f 7739 7973 7613 
f 7974 7610 7856 
f 7778 7974 7856 
f 7975 7629 7567 
f 7975 7567 7808 
f 7565 7976 7722 
f 7976 7807 7722 
f 7977 7715 7805 
f 7977 7805 7626 
f 7602 7978 8086 
f 7978 7602 7675 
f 8083 7678 7979 
f 7979 7678 7911 
f 7691 7980 7886 
f 7886 7980 7633 
f 7888 7981 7781 
f 7781 7981 7596 
f 7842 7982 8025 
f 7842 7608 7982 
f 7665 7983 7789 
f 7665 7867 7983 
f 8125 7984 7666 
f 7666 7984 8061 
f 7792 7606 7985 
f 8049 7792 7985 
f 7819 7583 7986 
f 7986 7694 7819 
f 7646 7870 7987 
f 7646 7987 7579 
f 7988 7721 7858 
f 7901 7721 7988 
f 7989 7771 8127 
f 7989 7679 7564 
f 7990 7653 7784 
f 7990 7784 7821 
f 7743 7618 7991 
f 7991 7586 7743 
f 7992 7566 7657 
f 7882 7992 7657 
f 7597 7993 7649 
f 7696 7649 7993 
f 7994 7549 7897 
f 7549 7994 7968 
f 7617 7995 7991 
f 7995 7617 7917 
f 7726 7590 7996 
f 7686 7996 7590 
f 7814 7817 7997 
f 7997 7817 7552 
f 7998 7747 7689 
f 7689 7576 7998 
f 7999 7921 7705 
f 7705 7699 7999 
f 8000 7591 8075 
f 8000 7634 7591 
f 8001 7751 7646 
f 8093 7751 8001 
f 7653 8002 7638 
f 7582 8002 7653 
f 7711 8003 7636 
f 7636 8003 7852 
f 7650 8004 7807 
f 8004 7650 7896 
f 8005 7736 7561 
f 8005 7561 7772 
f 7604 8006 7674 
f 7674 8006 7930 
f 7566 8007 7763 
f 8007 7615 7763 
f 7729 7893 8008 
f 8008 7671 7729 
f 8009 7660 7766 
f 8009 7766 8078 
f 8010 8127 7622 
f 7622 7605 8010 
f 7775 8011 7713 
f 7713 8011 7730 
f 8012 7825 8130 
f 8012 7916 7825 
f 7764 8013 7658 
f 7821 8013 7764 
f 7593 8014 7803 
f 7803 8014 8064 
f 7624 7663 8015 
f 7624 8015 7836 
f 8016 7988 7599 
f 7854 8016 7599 
f 7760 7635 8017 
f 7934 7760 8017 
f 7769 7855 8018 
f 8018 7887 7769 
f 8019 7589 7709 
f 7709 7759 8019 
f 7584 8020 7736 
f 8020 7584 7842 
f 7849 7732 8021 
f 7590 7849 8021 
f 7762 7895 8022 
f 7762 8022 7607 
f 8086 8023 7753 
f 7551 7753 8023 
f 7714 8024 7881 
f 7619 7881 8024 
f 7875 8025 7655 
f 7655 8025 7982 
f 7556 8026 7906 
f 8026 7556 7876 
f 7970 8027 7618 
f 8027 7991 7618 
f 8028 7717 8003 
f 7717 8028 7648 
f 7715 8029 7612 
f 8029 7912 7612 
f 8030 7559 7711 
f 7670 7559 8030 
f 7630 7598 8031 
f 7904 8031 7598 
f 7842 8032 7607 
f 7865 7607 8032 
f 8033 7562 8049 
f 8033 7844 7562 
f 7716 8034 7791 
f 7716 8030 8034 
f 7686 8035 7647 
f 7647 8035 7764 
f 7550 8036 7714 
f 8037 7767 7575 
f 7767 8037 8111 
f 7744 8038 7607 
f 7892 8038 7744 
f 7641 8039 7905 
f 8039 7641 8059 
f 7578 7946 8040 
f 7578 8040 7830 
f 7773 7676 8041 
f 8041 7676 7951 
f 8042 7625 7828 
f 7953 7625 8042 
f 8043 7846 7695 
f 8043 7601 7846 
f 8044 7595 7975 
f 8044 7793 7595 
f 7708 8045 7635 
f 7734 7635 8045 
f 7844 8046 7562 
f 7720 8046 7844 
f 7686 8047 7663 
f 8047 7947 7663 
f 8048 7610 7773 
f 7883 7610 8048 
f 7754 7639 8049 
f 7909 7754 8049 
f 7631 8050 7794 
f 7596 7794 8050 
f 8051 8064 7756 
f 8051 7756 7890 
f 7741 8052 7848 
f 8052 7741 7877 
f 8053 7820 7702 
f 7820 8053 7816 
f 7854 8054 7728 
f 8054 7858 7728 
f 8055 7573 7727 
f 8055 7727 8088 
f 7877 8056 8052 
f 8056 7877 7555 
f 8057 7862 7642 
f 7642 7973 8057 
f 8058 7569 7932 
f 8058 7682 7569 
f 8059 7549 7651 
f 7832 8059 7651 
f 7762 8060 7699 
f 7884 7699 8060 
f 8061 7622 7771 
f 7771 7666 8061 
f 8082 8062 7799 
f 8062 7677 7799 
f 7822 8063 7643 
f 8102 8063 7822 
f 7745 8064 8014 
f 8064 7745 7572 
f 7797 8065 7644 
f 7700 8065 7797 
f 7685 8066 7908 
f 8085 8066 7685 
f 7671 8067 7845 
f 7671 7624 8067 
f 8068 7576 7689 
f 7576 8068 7855 
f 7787 8069 7638 
f 8069 7739 7638 
f 8070 7656 7826 
f 7723 8070 7826 
f 7707 7894 8071 
f 7818 7707 8071 
f 7620 8072 7554 
f 7554 8072 7826 
f 7698 7697 8073 
f 7837 7698 8073 
f 7761 8074 7663 
f 7761 7757 8074 
f 8075 7631 8000 
f 8075 8050 7631 
f 7620 8076 7856 
f 7955 8076 7620 
f 8077 7879 7621 
f 7857 8077 7621 
f 7780 8078 7553 
f 7949 8078 7780 
f 8079 7564 7679 
f 8079 7684 7564 
f 7815 7755 8080 
f 7947 8080 7755 
f 7691 7814 8081 
f 8081 7814 7695 
f 8062 8082 7662 
f 8082 7810 7662 
f 8083 7718 7587 
f 7678 8083 7587 
f 7650 8084 7623 
f 7623 8084 8048 
f 8085 7684 7908 
f 7908 8066 8085 
f 8086 7978 7747 
f 7747 7998 8086 
f 7793 8087 7680 
f 7891 7680 8087 
f 8088 7640 7712 
f 7640 8088 7727 
f 7888 7588 8089 
f 8089 7588 7758 
f 7885 7580 8090 
f 8090 7580 7963 
f 7943 7796 8091 
f 8091 7593 7943 
f 8092 7597 7958 
f 7597 8092 7873 
f 8093 7558 7911 
f 8001 7558 8093 
f 7637 7935 8094 
f 7969 7637 8094 
f 7836 8095 7737 
f 8095 7972 7737 
f 7596 7710 8096 
f 7794 7596 8096 
f 7863 8097 7768 
f 7768 8097 7668 
f 7562 8098 7909 
f 8098 7562 8046 
f 7714 7644 8099 
f 8099 7550 7714 
f 7608 8100 7923 
f 7830 7923 8100 
f 8101 8089 7701 
f 8108 8101 7701 
f 8102 7870 7719 
f 7719 7923 8102 
f 8103 7777 7629 
f 7777 8103 7680 
f 7644 8065 8104 
f 7950 7644 8104 
f 7930 8105 7816 
f 8105 7930 8087 
f 7885 7726 8106 
f 7580 7885 8106 
f 8031 8107 7630 
f 8107 7615 7630 
f 7950 7555 8108 
f 7555 7922 8108 
f 7704 8109 8132 
f 7704 7859 8109 
f 8110 8011 7775 
f 7964 8110 7775 
f 7811 7767 8111 
f 7811 8111 7828 
f 7563 7690 8112 
f 7690 8079 8112 
f 7912 8113 7835 
f 7627 7835 8113 
f 7986 7583 8114 
f 7986 8114 7725 
f 7697 7573 8115 
f 7573 8055 8115 
f 7594 8116 7810 
f 7662 7810 8116 
f 8117 7784 7653 
f 8117 7653 7739 
f 7793 8118 7709 
f 7793 8044 8118 
f 7645 7831 8119 
f 8119 7903 7645 
f 7611 8120 7705 
f 7699 7705 8120 
f 8121 7790 7706 
f 8121 7706 7894 
f 7967 7566 8122 
f 7763 8122 7566 
f 7938 8123 7790 
f 7640 7790 8123 
f 7639 8124 7792 
f 7606 7792 8124 
f 7984 8125 7667 
f 7839 7667 8125 
f 7633 8126 7971 
f 7864 8126 7633 
f 8010 7679 8127 
f 8127 7679 7989 
f 7632 8128 7959 
f 7965 8128 7632 
f 8129 7572 7799 
f 7756 7572 8129 
f 7766 7660 8130 
f 8114 8130 7660 
f 8131 7600 7868 
f 8131 7868 7891 
f 8132 7763 7785 
f 8132 7785 7704 
f 7705 8133 7611 
f 7928 7611 8133 
f 7840 7726 8134 
f 7996 8134 7726 
f 7698 7837 8135 
f 8135 7837 7944 
f 8136 8130 7583 
f 8136 7583 7772 
f 8137 8155 8153 
f 8138 8137 8153 
f 8139 8138 8153 
f 8140 8139 8153 
f 8141 8140 8153 
f 8142 8141 8153 
f 8143 8142 8153 
f 8144 8143 8153 
f 8145 8144 8153 
f 8146 8145 8153 
f 8147 8146 8153 
f 8148 8147 8153 
f 8149 8148 8153 
f 8150 8149 8153 
f 8151 8150 8153 
f 8152 8151 8153 
f 8156 8152 8153 
f 8155 8156 8153 
f 8157 8158 8154 
f 8158 8159 8154 
f 8159 8160 8154 
f 8160 8161 8154 
f 8161 8162 8154 
f 8162 8163 8154 
f 8163 8164 8154 
f 8164 8165 8154 
f 8165 8166 8154 
f 8166 8167 8154 
f 8167 8168 8154 
f 8168 8169 8154 
f 8169 8170 8154 
f 8170 8171 8154 
f 8171 8172 8154 
f 8172 8173 8154 
f 8173 8174 8154 
f 8174 8157 8154 
f 8454 8155 8137 
f 8454 8137 8138 
f 8476 8138 8139 
f 8476 8139 8140 
f 8175 8140 8141 
f 8175 8141 8142 
f 8464 8142 8143 
f 8464 8143 8144 
f 8469 8144 8145 
f 8469 8145 8146 
f 8460 8146 8147 
f 8460 8147 8148 
f 8473 8148 8149 
f 8473 8149 8150 
f 8473 8150 8151 
f 8473 8151 8152 
f 8454 8152 8156 
f 8454 8156 8155 
f 8454 8158 8157 
f 8454 8159 8158 
f 8476 8160 8159 
f 8476 8161 8160 
f 8175 8162 8161 
f 8175 8163 8162 
f 8464 8164 8163 
f 8464 8165 8164 
f 8469 8166 8165 
f 8469 8167 8166 
f 8460 8168 8167 
f 8460 8169 8168 
f 8460 8170 8169 
f 8460 8171 8170 
f 8454 8172 8171 
f 8454 8173 8172 
f 8454 8174 8173 
f 8454 8157 8174 
f 8176 8194 8192 
f 8177 8176 8192 
f 8178 8177 8192 
f 8179 8178 8192 
f 8180 8179 8192 
f 8181 8180 8192 
f 8182 8181 8192 
f 8183 8182 8192 
f 8184 8183 8192 
f 8185 8184 8192 
f 8186 8185 8192 
f 8187 8186 8192 
f 8188 8187 8192 
f 8189 8188 8192 
f 8190 8189 8192 
f 8191 8190 8192 
f 8195 8191 8192 
f 8194 8195 8192 
f 8196 8197 8193 
f 8197 8198 8193 
f 8198 8199 8193 
f 8199 8200 8193 
f 8200 8201 8193 
f 8201 8202 8193 
f 8202 8203 8193 
f 8203 8204 8193 
f 8204 8205 8193 
f 8205 8206 8193 
f 8206 8207 8193 
f 8207 8208 8193 
f 8208 8209 8193 
f 8209 8210 8193 
f 8210 8211 8193 
f 8211 8212 8193 
f 8212 8213 8193 
f 8213 8196 8193 
f 8214 8194 8176 
f 8214 8176 8177 
f 8214 8177 8178 
f 8214 8178 8179 
f 8214 8179 8180 
f 8214 8180 8181 
f 8214 8181 8182 
f 8214 8182 8183 
f 8450 8183 8184 
f 8450 8184 8185 
f 8462 8185 8186 
f 8462 8186 8187 
f 8462 8187 8188 
f 8462 8188 8189 
f 8457 8189 8190 
f 8457 8190 8191 
f 8457 8191 8195 
f 8457 8195 8194 
f 8457 8197 8196 
f 8214 8198 8197 
f 8214 8199 8198 
f 8214 8200 8199 
f 8214 8201 8200 
f 8214 8202 8201 
f 8214 8203 8202 
f 8450 8204 8203 
f 8450 8205 8204 
f 8462 8206 8205 
f 8462 8207 8206 
f 8462 8208 8207 
f 8462 8209 8208 
f 8468 8210 8209 
f 8468 8211 8210 
f 8457 8212 8211 
f 8457 8213 8212 
f 8457 8196 8213 
f 8215 8233 8231 
f 8216 8215 8231 
f 8217 8216 8231 
f 8218 8217 8231 
f 8219 8218 8231 
f 8220 8219 8231 
f 8221 8220 8231 
f 8222 8221 8231 
f 8223 8222 8231 
f 8224 8223 8231 
f 8225 8224 8231 
f 8226 8225 8231 
f 8227 8226 8231 
f 8228 8227 8231 
f 8229 8228 8231 
f 8230 8229 8231 
f 8234 8230 8231 
f 8233 8234 8231 
f 8235 8236 8232 
f 8236 8237 8232 
f 8237 8238 8232 
f 8238 8239 8232 
f 8239 8240 8232 
f 8240 8241 8232 
f 8241 8242 8232 
f 8242 8243 8232 
f 8243 8244 8232 
f 8244 8245 8232 
f 8245 8246 8232 
f 8246 8247 8232 
f 8247 8248 8232 
f 8248 8249 8232 
f 8249 8250 8232 
f 8250 8251 8232 
f 8251 8252 8232 
f 8252 8235 8232 
f 8447 8233 8215 
f 8447 8215 8216 
f 8447 8216 8217 
f 8447 8217 8218 
f 8447 8218 8219 
f 8447 8219 8220 
f 8456 8220 8221 
f 8456 8221 8222 
f 8447 8222 8223 
f 8447 8223 8224 
f 8447 8224 8225 
f 8447 8225 8226 
f 8447 8226 8227 
f 8447 8227 8228 
f 8447 8228 8229 
f 8447 8229 8230 
f 8447 8230 8234 
f 8447 8234 8233 
f 8447 8236 8235 
f 8447 8237 8236 
f 8447 8238 8237 
f 8447 8239 8238 
f 8447 8240 8239 
f 8447 8241 8240 
f 8456 8242 8241 
f 8456 8243 8242 
f 8447 8244 8243 
f 8447 8245 8244 
f 8447 8246 8245 
f 8447 8247 8246 
f 8447 8248 8247 
f 8447 8249 8248 
f 8447 8250 8249 
f 8447 8251 8250 
f 8447 8252 8251 
f 8447 8235 8252 
f 8253 8270 8269 
f 8254 8253 8269 
f 8255 8254 8269 
f 8256 8255 8269 
f 8257 8256 8269 
f 8258 8257 8269 
f 8259 8258 8269 
f 8260 8259 8269 
f 8261 8260 8269 
f 8262 8261 8269 
f 8263 8262 8269 
f 8264 8263 8269 
f 8265 8264 8269 
f 8266 8265 8269 
f 8267 8266 8269 
f 8268 8267 8269 
f 8271 8268 8269 
f 8270 8271 8269 
f 8272 8273 8448 
f 8273 8274 8448 
f 8274 8275 8448 
f 8275 8276 8448 
f 8276 8277 8448 
f 8277 8278 8448 
f 8278 8279 8448 
f 8279 8280 8448 
f 8280 8281 8448 
f 8281 8282 8448 
f 8282 8283 8448 
f 8283 8284 8448 
f 8284 8285 8448 
f 8285 8286 8448 
f 8286 8287 8448 
f 8287 8288 8448 
f 8288 8289 8448 
f 8289 8272 8448 
f 8471 8270 8253 
f 8471 8253 8254 
f 8471 8254 8255 
f 8471 8255 8256 
f 8471 8256 8257 
f 8471 8257 8258 
f 8445 8258 8259 
f 8445 8259 8260 
f 8449 8260 8261 
f 8449 8261 8262 
f 8449 8262 8263 
f 8471 8263 8264 
f 8471 8264 8265 
f 8471 8265 8266 
f 8471 8266 8267 
f 8471 8267 8268 
f 8471 8268 8271 
f 8471 8271 8270 
f 8471 8273 8272 
f 8471 8274 8273 
f 8471 8275 8274 
f 8471 8276 8275 
f 8471 8277 8276 
f 8445 8278 8277 
f 8445 8279 8278 
f 8449 8280 8279 
f 8449 8281 8280 
f 8449 8282 8281 
f 8449 8283 8282 
f 8472 8284 8283 
f 8472 8285 8284 
f 8471 8286 8285 
f 8471 8287 8286 
f 8471 8288 8287 
f 8471 8289 8288 
f 8471 8272 8289 
f 8290 8308 8306 
f 8291 8290 8306 
f 8292 8291 8306 
f 8293 8292 8306 
f 8294 8293 8306 
f 8295 8294 8306 
f 8296 8295 8306 
f 8297 8296 8306 
f 8298 8297 8306 
f 8299 8298 8306 
f 8300 8299 8306 
f 8301 8300 8306 
f 8302 8301 8306 
f 8303 8302 8306 
f 8304 8303 8306 
f 8305 8304 8306 
f 8309 8305 8306 
f 8308 8309 8306 
f 8310 8311 8307 
f 8311 8312 8307 
f 8312 8313 8307 
f 8313 8314 8307 
f 8314 8315 8307 
f 8315 8316 8307 
f 8316 8317 8307 
f 8317 8318 8307 
f 8318 8319 8307 
f 8319 8320 8307 
f 8320 8321 8307 
f 8321 8322 8307 
f 8322 8323 8307 
f 8323 8324 8307 
f 8324 8325 8307 
f 8325 8326 8307 
f 8326 8327 8307 
f 8327 8310 8307 
f 8455 8308 8290 
f 8455 8290 8291 
f 8477 8291 8292 
f 8477 8292 8293 
f 8328 8293 8294 
f 8328 8294 8295 
f 8465 8295 8296 
f 8465 8296 8297 
f 8470 8297 8298 
f 8470 8298 8299 
f 8461 8299 8300 
f 8461 8300 8301 
f 8461 8301 8302 
f 8461 8302 8303 
f 8474 8303 8304 
f 8474 8304 8305 
f 8455 8305 8309 
f 8455 8309 8308 
f 8455 8311 8310 
f 8455 8312 8311 
f 8477 8313 8312 
f 8477 8314 8313 
f 8328 8315 8314 
f 8328 8316 8315 
f 8465 8317 8316 
f 8465 8318 8317 
f 8470 8319 8318 
f 8470 8320 8319 
f 8461 8321 8320 
f 8461 8322 8321 
f 8461 8323 8322 
f 8461 8324 8323 
f 8455 8325 8324 
f 8455 8326 8325 
f 8455 8327 8326 
f 8455 8310 8327 
f 8329 8347 8345 
f 8330 8329 8345 
f 8331 8330 8345 
f 8332 8331 8345 
f 8333 8332 8345 
f 8334 8333 8345 
f 8335 8334 8345 
f 8336 8335 8345 
f 8337 8336 8345 
f 8338 8337 8345 
f 8339 8338 8345 
f 8340 8339 8345 
f 8341 8340 8345 
f 8342 8341 8345 
f 8343 8342 8345 
f 8344 8343 8345 
f 8348 8344 8345 
f 8347 8348 8345 
f 8349 8350 8346 
f 8350 8351 8346 
f 8351 8352 8346 
f 8352 8353 8346 
f 8353 8354 8346 
f 8354 8355 8346 
f 8355 8356 8346 
f 8356 8357 8346 
f 8357 8358 8346 
f 8358 8359 8346 
f 8359 8360 8346 
f 8360 8361 8346 
f 8361 8362 8346 
f 8362 8363 8346 
f 8363 8364 8346 
f 8364 8365 8346 
f 8365 8366 8346 
f 8366 8349 8346 
f 8466 8347 8329 
f 8466 8329 8330 
f 8458 8330 8331 
f 8458 8331 8332 
f 8367 8332 8333 
f 8367 8333 8334 
f 8367 8334 8335 
f 8367 8335 8336 
f 8367 8336 8337 
f 8367 8337 8338 
f 8367 8338 8339 
f 8367 8339 8340 
f 8463 8340 8341 
f 8451 8341 8342 
f 8451 8342 8343 
f 8451 8343 8344 
f 8466 8344 8348 
f 8466 8348 8347 
f 8466 8350 8349 
f 8466 8351 8350 
f 8458 8352 8351 
f 8458 8353 8352 
f 8367 8354 8353 
f 8367 8355 8354 
f 8367 8356 8355 
f 8367 8357 8356 
f 8367 8358 8357 
f 8367 8359 8358 
f 8463 8360 8359 
f 8463 8361 8360 
f 8463 8362 8361 
f 8451 8363 8362 
f 8451 8364 8363 
f 8451 8365 8364 
f 8466 8366 8365 
f 8466 8349 8366 
f 8368 8386 8384 
f 8369 8368 8384 
f 8370 8369 8384 
f 8371 8370 8384 
f 8372 8371 8384 
f 8373 8372 8384 
f 8374 8373 8384 
f 8375 8374 8384 
f 8376 8375 8384 
f 8377 8376 8384 
f 8378 8377 8384 
f 8379 8378 8384 
f 8380 8379 8384 
f 8381 8380 8384 
f 8382 8381 8384 
f 8383 8382 8384 
f 8387 8383 8384 
f 8386 8387 8384 
f 8388 8389 8385 
f 8389 8390 8385 
f 8390 8391 8385 
f 8391 8392 8385 
f 8392 8393 8385 
f 8393 8394 8385 
f 8394 8395 8385 
f 8395 8396 8385 
f 8396 8397 8385 
f 8397 8398 8385 
f 8398 8399 8385 
f 8399 8400 8385 
f 8400 8401 8385 
f 8401 8402 8385 
f 8402 8403 8385 
f 8403 8404 8385 
f 8404 8405 8385 
f 8405 8388 8385 
f 8459 8386 8368 
f 8459 8368 8369 
f 8446 8369 8370 
f 8446 8370 8371 
f 8475 8371 8372 
f 8475 8372 8373 
f 8452 8373 8374 
f 8452 8374 8375 
f 8452 8375 8376 
f 8452 8376 8377 
f 8452 8377 8378 
f 8452 8378 8379 
f 8459 8379 8380 
f 8459 8380 8381 
f 8459 8381 8382 
f 8459 8382 8383 
f 8459 8383 8387 
f 8459 8387 8386 
f 8446 8389 8388 
f 8446 8390 8389 
f 8446 8391 8390 
f 8446 8392 8391 
f 8475 8393 8392 
f 8475 8394 8393 
f 8452 8395 8394 
f 8452 8396 8395 
f 8452 8397 8396 
f 8452 8398 8397 
f 8452 8399 8398 
f 8452 8400 8399 
f 8459 8401 8400 
f 8459 8402 8401 
f 8459 8403 8402 
f 8459 8404 8403 
f 8459 8405 8404 
f 8459 8388 8405 
f 8406 8424 8422 
f 8407 8406 8422 
f 8408 8407 8422 
f 8409 8408 8422 
f 8410 8409 8422 
f 8411 8410 8422 
f 8412 8411 8422 
f 8413 8412 8422 
f 8414 8413 8422 
f 8415 8414 8422 
f 8416 8415 8422 
f 8417 8416 8422 
f 8418 8417 8422 
f 8419 8418 8422 
f 8420 8419 8422 
f 8421 8420 8422 
f 8425 8421 8422 
f 8424 8425 8422 
f 8426 8427 8423 
f 8427 8428 8423 
f 8428 8429 8423 
f 8429 8430 8423 
f 8430 8431 8423 
f 8431 8432 8423 
f 8432 8433 8423 
f 8433 8434 8423 
f 8434 8435 8423 
f 8435 8436 8423 
f 8436 8437 8423 
f 8437 8438 8423 
f 8438 8439 8423 
f 8439 8440 8423 
f 8440 8441 8423 
f 8441 8442 8423 
f 8442 8443 8423 
f 8443 8426 8423 
f 8453 8424 8406 
f 8453 8406 8407 
f 8453 8407 8408 
f 8453 8408 8409 
f 8444 8409 8410 
f 8444 8410 8411 
f 8444 8411 8412 
f 8444 8412 8413 
f 8467 8413 8414 
f 8467 8414 8415 
f 8467 8415 8416 
f 8453 8416 8417 
f 8453 8417 8418 
f 8453 8418 8419 
f 8453 8419 8420 
f 8453 8420 8421 
f 8453 8421 8425 
f 8453 8425 8424 
f 8453 8427 8426 
f 8453 8428 8427 
f 8453 8429 8428 
f 8453 8430 8429 
f 8444 8431 8430 
f 8444 8432 8431 
f 8444 8433 8432 
f 8444 8434 8433 
f 8467 8435 8434 
f 8467 8436 8435 
f 8453 8437 8436 
f 8453 8438 8437 
f 8453 8439 8438 
f 8453 8440 8439 
f 8453 8441 8440 
f 8453 8442 8441 
f 8453 8443 8442 
f 8453 8426 8443 
f 8258 8445 8471 
f 8260 8449 8445 
f 8279 8445 8449 
f 8277 8471 8445 
f 8203 8214 8450 
f 8209 8462 8468 
f 8183 8450 8214 
f 8189 8457 8462 
f 8359 8367 8463 
f 8344 8466 8451 
f 8340 8463 8367 
f 8365 8451 8466 
f 8400 8452 8459 
f 8373 8452 8475 
f 8379 8459 8452 
f 8394 8475 8452 
f 8416 8453 8467 
f 8436 8467 8453 
f 8409 8444 8453 
f 8430 8453 8444 
f 8138 8476 8454 
f 8159 8454 8476 
f 8146 8460 8469 
f 8167 8469 8460 
f 8291 8477 8455 
f 8312 8455 8477 
f 8299 8461 8470 
f 8320 8470 8461 
f 8241 8447 8456 
f 8220 8456 8447 
f 8482 8478 8479 
f 8483 8480 8481 
f 8197 8457 8214 
f 8194 8214 8457 
f 8332 8367 8458 
f 8353 8458 8367 
f 8369 8446 8459 
f 8388 8459 8446 
f 8171 8460 8454 
f 8152 8454 8473 
f 8324 8461 8455 
f 8305 8455 8474 
f 8205 8450 8462 
f 8185 8462 8450 
f 8341 8451 8463 
f 8362 8463 8451 
f 8142 8464 8175 
f 8163 8175 8464 
f 8295 8465 8328 
f 8316 8328 8465 
f 8330 8458 8466 
f 8351 8466 8458 
f 8413 8467 8444 
f 8434 8444 8467 
f 8211 8468 8457 
f 8462 8457 8468 
f 8144 8469 8464 
f 8165 8464 8469 
f 8297 8470 8465 
f 8318 8465 8470 
f 8285 8472 8471 
f 8263 8471 8449 
f 8472 8449 8471 
f 8283 8449 8472 
f 8454 8460 8473 
f 8148 8473 8460 
f 8303 8474 8461 
f 8455 8461 8474 
f 8371 8475 8446 
f 8392 8446 8475 
f 8161 8476 8175 
f 8140 8175 8476 
f 8293 8328 8477 
f 8314 8477 8328 
f 8484 8502 8500 
f 8485 8484 8500 
f 8486 8485 8500 
f 8487 8486 8500 
f 8488 8487 8500 
f 8489 8488 8500 
f 8490 8489 8500 
f 8491 8490 8500 
f 8492 8491 8500 
f 8493 8492 8500 
f 8494 8493 8500 
f 8495 8494 8500 
f 8496 8495 8500 
f 8497 8496 8500 
f 8498 8497 8500 
f 8499 8498 8500 
f 8503 8499 8500 
f 8502 8503 8500 
f 8504 8505 8501 
f 8505 8506 8501 
f 8506 8507 8501 
f 8507 8508 8501 
f 8508 8509 8501 
f 8509 8510 8501 
f 8510 8511 8501 
f 8511 8512 8501 
f 8512 8513 8501 
f 8513 8514 8501 
f 8514 8515 8501 
f 8515 8516 8501 
f 8516 8517 8501 
f 8517 8518 8501 
f 8518 8519 8501 
f 8519 8520 8501 
f 8520 8521 8501 
f 8521 8504 8501 
f 10570 8502 8484 
f 10570 8484 8485 
f 10570 8485 8486 
f 10570 8486 8487 
f 10570 8487 8488 
f 10570 8488 8489 
f 10570 8489 8490 
f 10629 8490 8491 
f 10629 8491 8492 
f 10629 8492 8493 
f 10534 8493 8494 
f 10534 8494 8495 
f 10493 8495 8496 
f 10493 8496 8497 
f 10705 8497 8498 
f 10705 8498 8499 
f 10705 8499 8503 
f 10705 8503 8502 
f 10570 8505 8504 
f 10570 8506 8505 
f 10570 8507 8506 
f 10570 8508 8507 
f 10629 8509 8508 
f 10629 8510 8509 
f 10629 8511 8510 
f 10629 8512 8511 
f 10534 8513 8512 
f 10534 8514 8513 
f 10534 8515 8514 
f 10534 8516 8515 
f 10493 8517 8516 
f 10493 8518 8517 
f 10570 8519 8518 
f 10570 8520 8519 
f 10570 8521 8520 
f 10570 8504 8521 
f 8522 8540 8538 
f 8523 8522 8538 
f 8524 8523 8538 
f 8525 8524 8538 
f 8526 8525 8538 
f 8527 8526 8538 
f 8528 8527 8538 
f 8529 8528 8538 
f 8530 8529 8538 
f 8531 8530 8538 
f 8532 8531 8538 
f 8533 8532 8538 
f 8534 8533 8538 
f 8535 8534 8538 
f 8536 8535 8538 
f 8537 8536 8538 
f 8541 8537 8538 
f 8540 8541 8538 
f 8542 8543 8539 
f 8543 8544 8539 
f 8544 8545 8539 
f 8545 8546 8539 
f 8546 8547 8539 
f 8547 8548 8539 
f 8548 8549 8539 
f 8549 8550 8539 
f 8550 8551 8539 
f 8551 8552 8539 
f 8552 8553 8539 
f 8553 8554 8539 
f 8554 8555 8539 
f 8555 8556 8539 
f 8556 8557 8539 
f 8557 8558 8539 
f 8558 8559 8539 
f 8559 8542 8539 
f 10606 8540 8522 
f 10538 8522 8523 
f 10538 8523 8524 
f 10538 8524 8525 
f 10614 8525 8526 
f 10614 8526 8527 
f 10614 8527 8528 
f 10614 8528 8529 
f 10633 8529 8530 
f 10633 8530 8531 
f 10498 8531 8532 
f 10498 8532 8533 
f 10498 8533 8534 
f 10498 8534 8535 
f 10606 8535 8536 
f 10606 8536 8537 
f 10606 8537 8541 
f 10606 8541 8540 
f 10538 8543 8542 
f 10538 8544 8543 
f 10538 8545 8544 
f 10538 8546 8545 
f 10538 8547 8546 
f 10614 8548 8547 
f 10614 8549 8548 
f 10614 8550 8549 
f 10614 8551 8550 
f 10633 8552 8551 
f 10633 8553 8552 
f 10498 8554 8553 
f 10498 8555 8554 
f 10498 8556 8555 
f 10606 8557 8556 
f 10606 8558 8557 
f 10606 8559 8558 
f 10538 8542 8559 
f 8560 8578 8576 
f 8561 8560 8576 
f 8562 8561 8576 
f 8563 8562 8576 
f 8564 8563 8576 
f 8565 8564 8576 
f 8566 8565 8576 
f 8567 8566 8576 
f 8568 8567 8576 
f 8569 8568 8576 
f 8570 8569 8576 
f 8571 8570 8576 
f 8572 8571 8576 
f 8573 8572 8576 
f 8574 8573 8576 
f 8575 8574 8576 
f 8579 8575 8576 
f 8578 8579 8576 
f 8580 8581 8577 
f 8581 8582 8577 
f 8582 8583 8577 
f 8583 8584 8577 
f 8584 8585 8577 
f 8585 8586 8577 
f 8586 8587 8577 
f 8587 8588 8577 
f 8588 8589 8577 
f 8589 8590 8577 
f 8590 8591 8577 
f 8591 8592 8577 
f 8592 8593 8577 
f 8593 8594 8577 
f 8594 8595 8577 
f 8595 8596 8577 
f 8596 8597 8577 
f 8597 8580 8577 
f 10492 8578 8560 
f 10660 8560 8561 
f 10660 8561 8562 
f 10660 8562 8563 
f 10601 8563 8564 
f 10601 8564 8565 
f 10601 8565 8566 
f 10600 8566 8567 
f 10600 8567 8568 
f 10600 8568 8569 
f 10600 8569 8570 
f 10600 8570 8571 
f 10492 8571 8572 
f 10492 8572 8573 
f 10492 8573 8574 
f 10492 8574 8575 
f 10492 8575 8579 
f 10492 8579 8578 
f 10660 8581 8580 
f 10660 8582 8581 
f 10601 8583 8582 
f 10601 8584 8583 
f 10601 8585 8584 
f 10601 8586 8585 
f 10601 8587 8586 
f 10600 8588 8587 
f 10600 8589 8588 
f 10600 8590 8589 
f 10600 8591 8590 
f 10600 8592 8591 
f 10600 8593 8592 
f 10492 8594 8593 
f 10492 8595 8594 
f 10492 8596 8595 
f 10492 8597 8596 
f 10660 8580 8597 
f 8598 8616 8614 
f 8599 8598 8614 
f 8600 8599 8614 
f 8601 8600 8614 
f 8602 8601 8614 
f 8603 8602 8614 
f 8604 8603 8614 
f 8605 8604 8614 
f 8606 8605 8614 
f 8607 8606 8614 
f 8608 8607 8614 
f 8609 8608 8614 
f 8610 8609 8614 
f 8611 8610 8614 
f 8612 8611 8614 
f 8613 8612 8614 
f 8617 8613 8614 
f 8616 8617 8614 
f 8618 8619 8615 
f 8619 8620 8615 
f 8620 8621 8615 
f 8621 8622 8615 
f 8622 8623 8615 
f 8623 8624 8615 
f 8624 8625 8615 
f 8625 8626 8615 
f 8626 8627 8615 
f 8627 8628 8615 
f 8628 8629 8615 
f 8629 8630 8615 
f 8630 8631 8615 
f 8631 8632 8615 
f 8632 8633 8615 
f 8633 8634 8615 
f 8634 8635 8615 
f 8635 8618 8615 
f 10564 8616 8598 
f 10564 8598 8599 
f 10564 8599 8600 
f 10564 8600 8601 
f 10582 8601 8602 
f 10582 8602 8603 
f 10582 8603 8604 
f 10525 8604 8605 
f 10525 8605 8606 
f 10525 8606 8607 
f 10525 8607 8608 
f 10525 8608 8609 
f 8636 8609 8610 
f 8636 8610 8611 
f 8636 8611 8612 
f 10620 8612 8613 
f 10620 8613 8617 
f 10564 8617 8616 
f 10564 8619 8618 
f 10564 8620 8619 
f 10564 8621 8620 
f 10564 8622 8621 
f 10564 8623 8622 
f 10582 8624 8623 
f 10582 8625 8624 
f 10525 8626 8625 
f 10525 8627 8626 
f 10525 8628 8627 
f 10525 8629 8628 
f 8636 8630 8629 
f 8636 8631 8630 
f 10620 8632 8631 
f 10620 8633 8632 
f 10620 8634 8633 
f 10620 8635 8634 
f 10620 8618 8635 
f 8637 8655 8653 
f 8638 8637 8653 
f 8639 8638 8653 
f 8640 8639 8653 
f 8641 8640 8653 
f 8642 8641 8653 
f 8643 8642 8653 
f 8644 8643 8653 
f 8645 8644 8653 
f 8646 8645 8653 
f 8647 8646 8653 
f 8648 8647 8653 
f 8649 8648 8653 
f 8650 8649 8653 
f 8651 8650 8653 
f 8652 8651 8653 
f 8656 8652 8653 
f 8655 8656 8653 
f 8657 8658 8654 
f 8658 8659 8654 
f 8659 8660 8654 
f 8660 8661 8654 
f 8661 8662 8654 
f 8662 8663 8654 
f 8663 8664 8654 
f 8664 8665 8654 
f 8665 8666 8654 
f 8666 8667 8654 
f 8667 8668 8654 
f 8668 8669 8654 
f 8669 8670 8654 
f 8670 8671 8654 
f 8671 8672 8654 
f 8672 8673 8654 
f 8673 8674 8654 
f 8674 8657 8654 
f 10568 8655 8637 
f 10640 8637 8638 
f 10640 8638 8639 
f 10517 8639 8640 
f 10517 8640 8641 
f 10517 8641 8642 
f 10517 8642 8643 
f 10517 8643 8644 
f 10589 8644 8645 
f 10589 8645 8646 
f 10484 8646 8647 
f 10484 8647 8648 
f 10484 8648 8649 
f 10484 8649 8650 
f 10484 8650 8651 
f 10568 8651 8652 
f 10568 8652 8656 
f 10568 8656 8655 
f 10640 8658 8657 
f 10640 8659 8658 
f 10517 8660 8659 
f 10517 8661 8660 
f 10517 8662 8661 
f 10517 8663 8662 
f 10517 8664 8663 
f 10517 8665 8664 
f 10589 8666 8665 
f 10589 8667 8666 
f 10484 8668 8667 
f 10484 8669 8668 
f 10484 8670 8669 
f 10484 8671 8670 
f 10484 8672 8671 
f 10484 8673 8672 
f 10568 8674 8673 
f 10568 8657 8674 
f 8675 8693 8691 
f 8676 8675 8691 
f 8677 8676 8691 
f 8678 8677 8691 
f 8679 8678 8691 
f 8680 8679 8691 
f 8681 8680 8691 
f 8682 8681 8691 
f 8683 8682 8691 
f 8684 8683 8691 
f 8685 8684 8691 
f 8686 8685 8691 
f 8687 8686 8691 
f 8688 8687 8691 
f 8689 8688 8691 
f 8690 8689 8691 
f 8694 8690 8691 
f 8693 8694 8691 
f 8695 8696 8692 
f 8696 8697 8692 
f 8697 8698 8692 
f 8698 8699 8692 
f 8699 8700 8692 
f 8700 8701 8692 
f 8701 8702 8692 
f 8702 8703 8692 
f 8703 8704 8692 
f 8704 8705 8692 
f 8705 8706 8692 
f 8706 8707 8692 
f 8707 8708 8692 
f 8708 8709 8692 
f 8709 8710 8692 
f 8710 8711 8692 
f 8711 8712 8692 
f 8712 8695 8692 
f 10488 8693 8675 
f 10488 8675 8676 
f 10514 8676 8677 
f 10514 8677 8678 
f 10514 8678 8679 
f 10514 8679 8680 
f 10514 8680 8681 
f 10514 8681 8682 
f 10544 8682 8683 
f 10544 8683 8684 
f 10544 8684 8685 
f 10544 8685 8686 
f 10544 8686 8687 
f 10544 8687 8688 
f 10544 8688 8689 
f 10488 8689 8690 
f 10488 8690 8694 
f 10488 8694 8693 
f 10514 8696 8695 
f 10514 8697 8696 
f 10514 8698 8697 
f 10514 8699 8698 
f 10514 8700 8699 
f 10514 8701 8700 
f 10514 8702 8701 
f 10514 8703 8702 
f 10544 8704 8703 
f 10544 8705 8704 
f 10544 8706 8705 
f 10544 8707 8706 
f 10544 8708 8707 
f 10544 8709 8708 
f 10544 8710 8709 
f 10544 8711 8710 
f 10488 8712 8711 
f 10488 8695 8712 
f 8713 8731 8729 
f 8714 8713 8729 
f 8715 8714 8729 
f 8716 8715 8729 
f 8717 8716 8729 
f 8718 8717 8729 
f 8719 8718 8729 
f 8720 8719 8729 
f 8721 8720 8729 
f 8722 8721 8729 
f 8723 8722 8729 
f 8724 8723 8729 
f 8725 8724 8729 
f 8726 8725 8729 
f 8727 8726 8729 
f 8728 8727 8729 
f 8732 8728 8729 
f 8731 8732 8729 
f 8733 8734 8730 
f 8734 8735 8730 
f 8735 8736 8730 
f 8736 8737 8730 
f 8737 8738 8730 
f 8738 8739 8730 
f 8739 8740 8730 
f 8740 8741 8730 
f 8741 8742 8730 
f 8742 8743 8730 
f 8743 8744 8730 
f 8744 8745 8730 
f 8745 8746 8730 
f 8746 8747 8730 
f 8747 8748 8730 
f 8748 8749 8730 
f 8749 8750 8730 
f 8750 8733 8730 
f 10490 8731 8713 
f 10490 8713 8714 
f 10512 8714 8715 
f 10512 8715 8716 
f 10512 8716 8717 
f 10512 8717 8718 
f 10512 8718 8719 
f 10512 8719 8720 
f 10565 8720 8721 
f 10565 8721 8722 
f 10565 8722 8723 
f 10565 8723 8724 
f 10635 8724 8725 
f 10635 8725 8726 
f 10635 8726 8727 
f 10636 8727 8728 
f 10636 8728 8732 
f 10490 8732 8731 
f 10490 8734 8733 
f 10490 8735 8734 
f 10512 8736 8735 
f 10512 8737 8736 
f 10512 8738 8737 
f 10512 8739 8738 
f 10512 8740 8739 
f 10512 8741 8740 
f 10565 8742 8741 
f 10565 8743 8742 
f 10635 8744 8743 
f 10635 8745 8744 
f 10635 8746 8745 
f 10635 8747 8746 
f 10635 8748 8747 
f 10635 8749 8748 
f 10636 8750 8749 
f 10636 8733 8750 
f 8751 8769 8767 
f 8752 8751 8767 
f 8753 8752 8767 
f 8754 8753 8767 
f 8755 8754 8767 
f 8756 8755 8767 
f 8757 8756 8767 
f 8758 8757 8767 
f 8759 8758 8767 
f 8760 8759 8767 
f 8761 8760 8767 
f 8762 8761 8767 
f 8763 8762 8767 
f 8764 8763 8767 
f 8765 8764 8767 
f 8766 8765 8767 
f 8770 8766 8767 
f 8769 8770 8767 
f 8771 8772 8768 
f 8772 8773 8768 
f 8773 8774 8768 
f 8774 8775 8768 
f 8775 8776 8768 
f 8776 8777 8768 
f 8777 8778 8768 
f 8778 8779 8768 
f 8779 8780 8768 
f 8780 8781 8768 
f 8781 8782 8768 
f 8782 8783 8768 
f 8783 8784 8768 
f 8784 8785 8768 
f 8785 8786 8768 
f 8786 8787 8768 
f 8787 8788 8768 
f 8788 8771 8768 
f 10644 8769 8751 
f 10644 8751 8752 
f 10569 8752 8753 
f 10569 8753 8754 
f 10569 8754 8755 
f 10569 8755 8756 
f 10479 8756 8757 
f 10479 8757 8758 
f 10479 8758 8759 
f 10479 8759 8760 
f 10479 8760 8761 
f 10479 8761 8762 
f 10643 8762 8763 
f 10643 8763 8764 
f 10516 8764 8765 
f 10516 8765 8766 
f 10644 8766 8770 
f 10644 8770 8769 
f 10569 8772 8771 
f 10569 8773 8772 
f 10569 8774 8773 
f 10569 8775 8774 
f 10569 8776 8775 
f 10569 8777 8776 
f 10479 8778 8777 
f 10479 8779 8778 
f 10479 8780 8779 
f 10479 8781 8780 
f 10643 8782 8781 
f 10643 8783 8782 
f 10643 8784 8783 
f 10643 8785 8784 
f 10516 8786 8785 
f 10516 8787 8786 
f 10644 8788 8787 
f 10644 8771 8788 
f 8789 8807 8805 
f 8790 8789 8805 
f 8791 8790 8805 
f 8792 8791 8805 
f 8793 8792 8805 
f 8794 8793 8805 
f 8795 8794 8805 
f 8796 8795 8805 
f 8797 8796 8805 
f 8798 8797 8805 
f 8799 8798 8805 
f 8800 8799 8805 
f 8801 8800 8805 
f 8802 8801 8805 
f 8803 8802 8805 
f 8804 8803 8805 
f 8808 8804 8805 
f 8807 8808 8805 
f 8809 8810 8806 
f 8810 8811 8806 
f 8811 8812 8806 
f 8812 8813 8806 
f 8813 8814 8806 
f 8814 8815 8806 
f 8815 8816 8806 
f 8816 8817 8806 
f 8817 8818 8806 
f 8818 8819 8806 
f 8819 8820 8806 
f 8820 8821 8806 
f 8821 8822 8806 
f 8822 8823 8806 
f 8823 8824 8806 
f 8824 8825 8806 
f 8825 8826 8806 
f 8826 8809 8806 
f 10603 8807 8789 
f 10532 8789 8790 
f 10532 8790 8791 
f 10532 8791 8792 
f 10532 8792 8793 
f 10532 8793 8794 
f 10581 8794 8795 
f 10581 8795 8796 
f 10581 8796 8797 
f 10581 8797 8798 
f 10581 8798 8799 
f 10581 8799 8800 
f 10502 8800 8801 
f 10502 8801 8802 
f 10502 8802 8803 
f 10502 8803 8804 
f 10603 8804 8808 
f 10603 8808 8807 
f 10532 8810 8809 
f 10532 8811 8810 
f 10532 8812 8811 
f 10532 8813 8812 
f 10532 8814 8813 
f 10532 8815 8814 
f 10581 8816 8815 
f 10581 8817 8816 
f 10581 8818 8817 
f 10581 8819 8818 
f 10502 8820 8819 
f 10502 8821 8820 
f 10502 8822 8821 
f 10502 8823 8822 
f 10502 8824 8823 
f 10502 8825 8824 
f 10603 8826 8825 
f 10603 8809 8826 
f 8827 8845 8843 
f 8828 8827 8843 
f 8829 8828 8843 
f 8830 8829 8843 
f 8831 8830 8843 
f 8832 8831 8843 
f 8833 8832 8843 
f 8834 8833 8843 
f 8835 8834 8843 
f 8836 8835 8843 
f 8837 8836 8843 
f 8838 8837 8843 
f 8839 8838 8843 
f 8840 8839 8843 
f 8841 8840 8843 
f 8842 8841 8843 
f 8846 8842 8843 
f 8845 8846 8843 
f 8847 8848 8844 
f 8848 8849 8844 
f 8849 8850 8844 
f 8850 8851 8844 
f 8851 8852 8844 
f 8852 8853 8844 
f 8853 8854 8844 
f 8854 8855 8844 
f 8855 8856 8844 
f 8856 8857 8844 
f 8857 8858 8844 
f 8858 8859 8844 
f 8859 8860 8844 
f 8860 8861 8844 
f 8861 8862 8844 
f 8862 8863 8844 
f 8863 8864 8844 
f 8864 8847 8844 
f 10560 8845 8827 
f 10560 8827 8828 
f 10560 8828 8829 
f 10673 8829 8830 
f 10673 8830 8831 
f 8865 8831 8832 
f 8865 8832 8833 
f 8865 8833 8834 
f 8865 8834 8835 
f 10691 8835 8836 
f 10691 8836 8837 
f 10691 8837 8838 
f 10691 8838 8839 
f 10691 8839 8840 
f 10631 8840 8841 
f 10631 8841 8842 
f 10631 8842 8846 
f 10631 8846 8845 
f 10560 8848 8847 
f 10673 8849 8848 
f 10673 8850 8849 
f 10673 8851 8850 
f 8865 8852 8851 
f 8865 8853 8852 
f 8865 8854 8853 
f 8865 8855 8854 
f 8865 8856 8855 
f 8865 8857 8856 
f 8865 8858 8857 
f 10691 8859 8858 
f 10691 8860 8859 
f 10691 8861 8860 
f 10691 8862 8861 
f 10631 8863 8862 
f 10631 8864 8863 
f 10560 8847 8864 
f 8866 8884 8882 
f 8867 8866 8882 
f 8868 8867 8882 
f 8869 8868 8882 
f 8870 8869 8882 
f 8871 8870 8882 
f 8872 8871 8882 
f 8873 8872 8882 
f 8874 8873 8882 
f 8875 8874 8882 
f 8876 8875 8882 
f 8877 8876 8882 
f 8878 8877 8882 
f 8879 8878 8882 
f 8880 8879 8882 
f 8881 8880 8882 
f 8885 8881 8882 
f 8884 8885 8882 
f 8886 8887 8883 
f 8887 8888 8883 
f 8888 8889 8883 
f 8889 8890 8883 
f 8890 8891 8883 
f 8891 8892 8883 
f 8892 8893 8883 
f 8893 8894 8883 
f 8894 8895 8883 
f 8895 8896 8883 
f 8896 8897 8883 
f 8897 8898 8883 
f 8898 8899 8883 
f 8899 8900 8883 
f 8900 8901 8883 
f 8901 8902 8883 
f 8902 8903 8883 
f 8903 8886 8883 
f 10577 8884 8866 
f 10577 8866 8867 
f 10577 8867 8868 
f 10577 8868 8869 
f 10577 8869 8870 
f 10657 8870 8871 
f 10657 8871 8872 
f 8904 8872 8873 
f 8904 8873 8874 
f 8904 8874 8875 
f 8904 8875 8876 
f 8904 8876 8877 
f 10551 8877 8878 
f 10551 8878 8879 
f 10670 8879 8880 
f 10670 8880 8881 
f 10619 8881 8885 
f 10619 8885 8884 
f 10619 8887 8886 
f 10577 8888 8887 
f 10577 8889 8888 
f 10577 8890 8889 
f 10657 8891 8890 
f 10657 8892 8891 
f 10657 8893 8892 
f 10657 8894 8893 
f 8904 8895 8894 
f 8904 8896 8895 
f 8904 8897 8896 
f 8904 8898 8897 
f 10551 8899 8898 
f 10551 8900 8899 
f 10670 8901 8900 
f 10670 8902 8901 
f 10619 8903 8902 
f 10619 8886 8903 
f 8905 8923 8921 
f 8906 8905 8921 
f 8907 8906 8921 
f 8908 8907 8921 
f 8909 8908 8921 
f 8910 8909 8921 
f 8911 8910 8921 
f 8912 8911 8921 
f 8913 8912 8921 
f 8914 8913 8921 
f 8915 8914 8921 
f 8916 8915 8921 
f 8917 8916 8921 
f 8918 8917 8921 
f 8919 8918 8921 
f 8920 8919 8921 
f 8924 8920 8921 
f 8923 8924 8921 
f 8925 8926 8922 
f 8926 8927 8922 
f 8927 8928 8922 
f 8928 8929 8922 
f 8929 8930 8922 
f 8930 8931 8922 
f 8931 8932 8922 
f 8932 8933 8922 
f 8933 8934 8922 
f 8934 8935 8922 
f 8935 8936 8922 
f 8936 8937 8922 
f 8937 8938 8922 
f 8938 8939 8922 
f 8939 8940 8922 
f 8940 8941 8922 
f 8941 8942 8922 
f 8942 8925 8922 
f 10617 8923 8905 
f 10617 8905 8906 
f 10617 8906 8907 
f 10662 8907 8908 
f 10662 8908 8909 
f 10506 8909 8910 
f 10506 8910 8911 
f 10506 8911 8912 
f 10506 8912 8913 
f 10506 8913 8914 
f 10506 8914 8915 
f 10506 8915 8916 
f 10506 8916 8917 
f 10677 8917 8918 
f 10677 8918 8919 
f 10543 8919 8920 
f 10543 8920 8924 
f 10617 8924 8923 
f 10617 8926 8925 
f 10617 8927 8926 
f 10662 8928 8927 
f 10662 8929 8928 
f 10506 8930 8929 
f 10506 8931 8930 
f 10506 8932 8931 
f 10506 8933 8932 
f 10506 8934 8933 
f 10506 8935 8934 
f 10506 8936 8935 
f 10506 8937 8936 
f 10677 8938 8937 
f 10677 8939 8938 
f 10543 8940 8939 
f 10543 8941 8940 
f 10543 8942 8941 
f 10543 8925 8942 
f 8943 8961 8959 
f 8944 8943 8959 
f 8945 8944 8959 
f 8946 8945 8959 
f 8947 8946 8959 
f 8948 8947 8959 
f 8949 8948 8959 
f 8950 8949 8959 
f 8951 8950 8959 
f 8952 8951 8959 
f 8953 8952 8959 
f 8954 8953 8959 
f 8955 8954 8959 
f 8956 8955 8959 
f 8957 8956 8959 
f 8958 8957 8959 
f 8962 8958 8959 
f 8961 8962 8959 
f 8963 8964 8960 
f 8964 8965 8960 
f 8965 8966 8960 
f 8966 8967 8960 
f 8967 8968 8960 
f 8968 8969 8960 
f 8969 8970 8960 
f 8970 8971 8960 
f 8971 8972 8960 
f 8972 8973 8960 
f 8973 8974 8960 
f 8974 8975 8960 
f 8975 8976 8960 
f 8976 8977 8960 
f 8977 8978 8960 
f 8978 8979 8960 
f 8979 8980 8960 
f 8980 8963 8960 
f 10562 8961 8943 
f 10562 8943 8944 
f 10562 8944 8945 
f 10562 8945 8946 
f 8981 8946 8947 
f 8981 8947 8948 
f 8981 8948 8949 
f 8981 8949 8950 
f 8981 8950 8951 
f 8981 8951 8952 
f 8981 8952 8953 
f 8981 8953 8954 
f 10604 8954 8955 
f 10604 8955 8956 
f 10604 8956 8957 
f 10693 8957 8958 
f 10693 8958 8962 
f 10562 8962 8961 
f 10562 8964 8963 
f 10562 8965 8964 
f 10562 8966 8965 
f 10562 8967 8966 
f 10562 8968 8967 
f 8981 8969 8968 
f 8981 8970 8969 
f 8981 8971 8970 
f 8981 8972 8971 
f 8981 8973 8972 
f 8981 8974 8973 
f 10604 8975 8974 
f 10604 8976 8975 
f 10604 8977 8976 
f 10693 8978 8977 
f 10693 8979 8978 
f 10693 8980 8979 
f 10562 8963 8980 
f 8982 9000 8998 
f 8983 8982 8998 
f 8984 8983 8998 
f 8985 8984 8998 
f 8986 8985 8998 
f 8987 8986 8998 
f 8988 8987 8998 
f 8989 8988 8998 
f 8990 8989 8998 
f 8991 8990 8998 
f 8992 8991 8998 
f 8993 8992 8998 
f 8994 8993 8998 
f 8995 8994 8998 
f 8996 8995 8998 
f 8997 8996 8998 
f 9001 8997 8998 
f 9000 9001 8998 
f 9002 9003 8999 
f 9003 9004 8999 
f 9004 9005 8999 
f 9005 9006 8999 
f 9006 9007 8999 
f 9007 9008 8999 
f 9008 9009 8999 
f 9009 9010 8999 
f 9010 9011 8999 
f 9011 9012 8999 
f 9012 9013 8999 
f 9013 9014 8999 
f 9014 9015 8999 
f 9015 9016 8999 
f 9016 9017 8999 
f 9017 9018 8999 
f 9018 9019 8999 
f 9019 9002 8999 
f 10609 9000 8982 
f 10609 8982 8983 
f 10609 8983 8984 
f 10609 8984 8985 
f 10557 8985 8986 
f 10557 8986 8987 
f 10557 8987 8988 
f 10557 8988 8989 
f 10557 8989 8990 
f 10557 8990 8991 
f 10504 8991 8992 
f 10504 8992 8993 
f 10504 8993 8994 
f 10504 8994 8995 
f 10679 8995 8996 
f 10679 8996 8997 
f 10679 8997 9001 
f 10609 9001 9000 
f 10609 9003 9002 
f 10609 9004 9003 
f 10609 9005 9004 
f 10557 9006 9005 
f 10557 9007 9006 
f 10557 9008 9007 
f 10557 9009 9008 
f 10557 9010 9009 
f 10557 9011 9010 
f 10557 9012 9011 
f 10504 9013 9012 
f 10504 9014 9013 
f 10679 9015 9014 
f 10679 9016 9015 
f 10679 9017 9016 
f 10679 9018 9017 
f 10609 9019 9018 
f 10609 9002 9019 
f 9020 9038 9036 
f 9021 9020 9036 
f 9022 9021 9036 
f 9023 9022 9036 
f 9024 9023 9036 
f 9025 9024 9036 
f 9026 9025 9036 
f 9027 9026 9036 
f 9028 9027 9036 
f 9029 9028 9036 
f 9030 9029 9036 
f 9031 9030 9036 
f 9032 9031 9036 
f 9033 9032 9036 
f 9034 9033 9036 
f 9035 9034 9036 
f 9039 9035 9036 
f 9038 9039 9036 
f 9040 9041 9037 
f 9041 9042 9037 
f 9042 9043 9037 
f 9043 9044 9037 
f 9044 9045 9037 
f 9045 9046 9037 
f 9046 9047 9037 
f 9047 9048 9037 
f 9048 9049 9037 
f 9049 9050 9037 
f 9050 9051 9037 
f 9051 9052 9037 
f 9052 9053 9037 
f 9053 9054 9037 
f 9054 9055 9037 
f 9055 9056 9037 
f 9056 9057 9037 
f 9057 9040 9037 
f 10647 9038 9020 
f 10553 9020 9021 
f 10553 9021 9022 
f 10553 9022 9023 
f 10695 9023 9024 
f 10695 9024 9025 
f 10695 9025 9026 
f 10695 9026 9027 
f 10623 9027 9028 
f 10509 9028 9029 
f 10509 9029 9030 
f 10509 9030 9031 
f 10509 9031 9032 
f 10509 9032 9033 
f 10509 9033 9034 
f 10647 9034 9035 
f 10647 9035 9039 
f 10647 9039 9038 
f 10553 9041 9040 
f 10553 9042 9041 
f 10553 9043 9042 
f 10695 9044 9043 
f 10695 9045 9044 
f 10695 9046 9045 
f 10623 9047 9046 
f 10623 9048 9047 
f 10623 9049 9048 
f 10509 9050 9049 
f 10509 9051 9050 
f 10509 9052 9051 
f 10509 9053 9052 
f 10509 9054 9053 
f 10509 9055 9054 
f 10647 9056 9055 
f 10647 9057 9056 
f 10647 9040 9057 
f 9058 9076 9074 
f 9059 9058 9074 
f 9060 9059 9074 
f 9061 9060 9074 
f 9062 9061 9074 
f 9063 9062 9074 
f 9064 9063 9074 
f 9065 9064 9074 
f 9066 9065 9074 
f 9067 9066 9074 
f 9068 9067 9074 
f 9069 9068 9074 
f 9070 9069 9074 
f 9071 9070 9074 
f 9072 9071 9074 
f 9073 9072 9074 
f 9077 9073 9074 
f 9076 9077 9074 
f 9078 9079 9075 
f 9079 9080 9075 
f 9080 9081 9075 
f 9081 9082 9075 
f 9082 9083 9075 
f 9083 9084 9075 
f 9084 9085 9075 
f 9085 9086 9075 
f 9086 9087 9075 
f 9087 9088 9075 
f 9088 9089 9075 
f 9089 9090 9075 
f 9090 9091 9075 
f 9091 9092 9075 
f 9092 9093 9075 
f 9093 9094 9075 
f 9094 9095 9075 
f 9095 9078 9075 
f 10686 9076 9058 
f 10558 9058 9059 
f 10558 9059 9060 
f 10558 9060 9061 
f 10558 9061 9062 
f 10558 9062 9063 
f 10558 9063 9064 
f 10499 9064 9065 
f 10499 9065 9066 
f 10499 9066 9067 
f 10499 9067 9068 
f 10499 9068 9069 
f 10499 9069 9070 
f 10499 9070 9071 
f 10587 9071 9072 
f 10587 9072 9073 
f 10587 9073 9077 
f 10686 9077 9076 
f 10686 9079 9078 
f 10558 9080 9079 
f 10558 9081 9080 
f 10558 9082 9081 
f 10558 9083 9082 
f 10558 9084 9083 
f 10499 9085 9084 
f 10499 9086 9085 
f 10499 9087 9086 
f 10499 9088 9087 
f 10499 9089 9088 
f 10499 9090 9089 
f 10499 9091 9090 
f 10499 9092 9091 
f 10499 9093 9092 
f 10587 9094 9093 
f 10587 9095 9094 
f 10587 9078 9095 
f 9096 9114 9112 
f 9097 9096 9112 
f 9098 9097 9112 
f 9099 9098 9112 
f 9100 9099 9112 
f 9101 9100 9112 
f 9102 9101 9112 
f 9103 9102 9112 
f 9104 9103 9112 
f 9105 9104 9112 
f 9106 9105 9112 
f 9107 9106 9112 
f 9108 9107 9112 
f 9109 9108 9112 
f 9110 9109 9112 
f 9111 9110 9112 
f 9115 9111 9112 
f 9114 9115 9112 
f 9116 9117 9113 
f 9117 9118 9113 
f 9118 9119 9113 
f 9119 9120 9113 
f 9120 9121 9113 
f 9121 9122 9113 
f 9122 9123 9113 
f 9123 9124 9113 
f 9124 9125 9113 
f 9125 9126 9113 
f 9126 9127 9113 
f 9127 9128 9113 
f 9128 9129 9113 
f 9129 9130 9113 
f 9130 9131 9113 
f 9131 9132 9113 
f 9132 9133 9113 
f 9133 9116 9113 
f 10676 9114 9096 
f 10697 9096 9097 
f 10697 9097 9098 
f 10697 9098 9099 
f 10572 9099 9100 
f 10572 9100 9101 
f 10572 9101 9102 
f 10572 9102 9103 
f 10510 9103 9104 
f 10510 9104 9105 
f 10510 9105 9106 
f 10510 9106 9107 
f 10510 9107 9108 
f 10676 9108 9109 
f 10676 9109 9110 
f 10676 9110 9111 
f 10676 9111 9115 
f 10676 9115 9114 
f 10676 9117 9116 
f 10697 9118 9117 
f 10697 9119 9118 
f 10572 9120 9119 
f 10572 9121 9120 
f 10572 9122 9121 
f 10572 9123 9122 
f 10510 9124 9123 
f 10510 9125 9124 
f 10510 9126 9125 
f 10510 9127 9126 
f 10510 9128 9127 
f 10510 9129 9128 
f 10510 9130 9129 
f 10510 9131 9130 
f 10676 9132 9131 
f 10676 9133 9132 
f 10676 9116 9133 
f 9134 9152 9150 
f 9135 9134 9150 
f 9136 9135 9150 
f 9137 9136 9150 
f 9138 9137 9150 
f 9139 9138 9150 
f 9140 9139 9150 
f 9141 9140 9150 
f 9142 9141 9150 
f 9143 9142 9150 
f 9144 9143 9150 
f 9145 9144 9150 
f 9146 9145 9150 
f 9147 9146 9150 
f 9148 9147 9150 
f 9149 9148 9150 
f 9153 9149 9150 
f 9152 9153 9150 
f 9154 9155 9151 
f 9155 9156 9151 
f 9156 9157 9151 
f 9157 9158 9151 
f 9158 9159 9151 
f 9159 9160 9151 
f 9160 9161 9151 
f 9161 9162 9151 
f 9162 9163 9151 
f 9163 9164 9151 
f 9164 9165 9151 
f 9165 9166 9151 
f 9166 9167 9151 
f 9167 9168 9151 
f 9168 9169 9151 
f 9169 9170 9151 
f 9170 9171 9151 
f 9171 9154 9151 
f 10682 9152 9134 
f 10554 9134 9135 
f 10554 9135 9136 
f 10554 9136 9137 
f 10661 9137 9138 
f 10661 9138 9139 
f 10661 9139 9140 
f 10661 9140 9141 
f 10661 9141 9142 
f 9172 9142 9143 
f 9172 9143 9144 
f 9172 9144 9145 
f 9172 9145 9146 
f 9172 9146 9147 
f 10683 9147 9148 
f 10683 9148 9149 
f 10683 9149 9153 
f 10682 9153 9152 
f 10682 9155 9154 
f 10554 9156 9155 
f 10554 9157 9156 
f 10554 9158 9157 
f 10554 9159 9158 
f 10661 9160 9159 
f 10661 9161 9160 
f 10661 9162 9161 
f 10661 9163 9162 
f 9172 9164 9163 
f 9172 9165 9164 
f 10683 9166 9165 
f 10683 9167 9166 
f 10683 9168 9167 
f 10683 9169 9168 
f 10682 9170 9169 
f 10682 9171 9170 
f 10682 9154 9171 
f 9173 9191 9189 
f 9174 9173 9189 
f 9175 9174 9189 
f 9176 9175 9189 
f 9177 9176 9189 
f 9178 9177 9189 
f 9179 9178 9189 
f 9180 9179 9189 
f 9181 9180 9189 
f 9182 9181 9189 
f 9183 9182 9189 
f 9184 9183 9189 
f 9185 9184 9189 
f 9186 9185 9189 
f 9187 9186 9189 
f 9188 9187 9189 
f 9192 9188 9189 
f 9191 9192 9189 
f 9193 9194 9190 
f 9194 9195 9190 
f 9195 9196 9190 
f 9196 9197 9190 
f 9197 9198 9190 
f 9198 9199 9190 
f 9199 9200 9190 
f 9200 9201 9190 
f 9201 9202 9190 
f 9202 9203 9190 
f 9203 9204 9190 
f 9204 9205 9190 
f 9205 9206 9190 
f 9206 9207 9190 
f 9207 9208 9190 
f 9208 9209 9190 
f 9209 9210 9190 
f 9210 9193 9190 
f 10678 9191 9173 
f 10549 9173 9174 
f 10549 9174 9175 
f 10549 9175 9176 
f 10549 9176 9177 
f 10549 9177 9178 
f 10624 9178 9179 
f 10624 9179 9180 
f 10624 9180 9181 
f 10624 9181 9182 
f 10489 9182 9183 
f 10489 9183 9184 
f 10489 9184 9185 
f 10489 9185 9186 
f 10678 9186 9187 
f 10678 9187 9188 
f 10678 9188 9192 
f 10678 9192 9191 
f 10678 9194 9193 
f 10549 9195 9194 
f 10549 9196 9195 
f 10549 9197 9196 
f 10549 9198 9197 
f 10549 9199 9198 
f 10624 9200 9199 
f 10624 9201 9200 
f 10624 9202 9201 
f 10624 9203 9202 
f 10489 9204 9203 
f 10489 9205 9204 
f 10489 9206 9205 
f 10489 9207 9206 
f 10489 9208 9207 
f 10678 9209 9208 
f 10678 9210 9209 
f 10678 9193 9210 
f 9211 9229 9227 
f 9212 9211 9227 
f 9213 9212 9227 
f 9214 9213 9227 
f 9215 9214 9227 
f 9216 9215 9227 
f 9217 9216 9227 
f 9218 9217 9227 
f 9219 9218 9227 
f 9220 9219 9227 
f 9221 9220 9227 
f 9222 9221 9227 
f 9223 9222 9227 
f 9224 9223 9227 
f 9225 9224 9227 
f 9226 9225 9227 
f 9230 9226 9227 
f 9229 9230 9227 
f 9231 9232 9228 
f 9232 9233 9228 
f 9233 9234 9228 
f 9234 9235 9228 
f 9235 9236 9228 
f 9236 9237 9228 
f 9237 9238 9228 
f 9238 9239 9228 
f 9239 9240 9228 
f 9240 9241 9228 
f 9241 9242 9228 
f 9242 9243 9228 
f 9243 9244 9228 
f 9244 9245 9228 
f 9245 9246 9228 
f 9246 9247 9228 
f 9247 9248 9228 
f 9248 9231 9228 
f 10605 9229 9211 
f 10527 9211 9212 
f 10527 9212 9213 
f 10527 9213 9214 
f 10527 9214 9215 
f 10598 9215 9216 
f 10598 9216 9217 
f 10598 9217 9218 
f 10598 9218 9219 
f 10598 9219 9220 
f 9249 9220 9221 
f 9249 9221 9222 
f 9249 9222 9223 
f 9249 9223 9224 
f 10674 9224 9225 
f 10674 9225 9226 
f 10605 9226 9230 
f 10605 9230 9229 
f 10605 9232 9231 
f 10527 9233 9232 
f 10527 9234 9233 
f 10527 9235 9234 
f 10527 9236 9235 
f 10527 9237 9236 
f 10598 9238 9237 
f 10598 9239 9238 
f 10598 9240 9239 
f 10598 9241 9240 
f 9249 9242 9241 
f 9249 9243 9242 
f 9249 9244 9243 
f 10674 9245 9244 
f 10674 9246 9245 
f 10674 9247 9246 
f 10605 9248 9247 
f 10605 9231 9248 
f 9250 9268 9266 
f 9251 9250 9266 
f 9252 9251 9266 
f 9253 9252 9266 
f 9254 9253 9266 
f 9255 9254 9266 
f 9256 9255 9266 
f 9257 9256 9266 
f 9258 9257 9266 
f 9259 9258 9266 
f 9260 9259 9266 
f 9261 9260 9266 
f 9262 9261 9266 
f 9263 9262 9266 
f 9264 9263 9266 
f 9265 9264 9266 
f 9269 9265 9266 
f 9268 9269 9266 
f 9270 9271 9267 
f 9271 9272 9267 
f 9272 9273 9267 
f 9273 9274 9267 
f 9274 9275 9267 
f 9275 9276 9267 
f 9276 9277 9267 
f 9277 9278 9267 
f 9278 9279 9267 
f 9279 9280 9267 
f 9280 9281 9267 
f 9281 9282 9267 
f 9282 9283 9267 
f 9283 9284 9267 
f 9284 9285 9267 
f 9285 9286 9267 
f 9286 9287 9267 
f 9287 9270 9267 
f 10579 9268 9250 
f 10579 9250 9251 
f 10524 9251 9252 
f 10524 9252 9253 
f 10627 9253 9254 
f 10627 9254 9255 
f 10627 9255 9256 
f 10627 9256 9257 
f 10653 9257 9258 
f 10653 9258 9259 
f 10653 9259 9260 
f 10653 9260 9261 
f 10477 9261 9262 
f 10477 9262 9263 
f 10579 9263 9264 
f 10579 9264 9265 
f 10579 9265 9269 
f 10579 9269 9268 
f 10579 9271 9270 
f 10524 9272 9271 
f 10524 9273 9272 
f 10524 9274 9273 
f 10524 9275 9274 
f 10524 9276 9275 
f 10627 9277 9276 
f 10627 9278 9277 
f 10653 9279 9278 
f 10653 9280 9279 
f 10653 9281 9280 
f 10477 9282 9281 
f 10477 9283 9282 
f 10579 9284 9283 
f 10579 9285 9284 
f 10579 9286 9285 
f 10579 9287 9286 
f 10579 9270 9287 
f 9288 9306 9304 
f 9289 9288 9304 
f 9290 9289 9304 
f 9291 9290 9304 
f 9292 9291 9304 
f 9293 9292 9304 
f 9294 9293 9304 
f 9295 9294 9304 
f 9296 9295 9304 
f 9297 9296 9304 
f 9298 9297 9304 
f 9299 9298 9304 
f 9300 9299 9304 
f 9301 9300 9304 
f 9302 9301 9304 
f 9303 9302 9304 
f 9307 9303 9304 
f 9306 9307 9304 
f 9308 9309 9305 
f 9309 9310 9305 
f 9310 9311 9305 
f 9311 9312 9305 
f 9312 9313 9305 
f 9313 9314 9305 
f 9314 9315 9305 
f 9315 9316 9305 
f 9316 9317 9305 
f 9317 9318 9305 
f 9318 9319 9305 
f 9319 9320 9305 
f 9320 9321 9305 
f 9321 9322 9305 
f 9322 9323 9305 
f 9323 9324 9305 
f 9324 9325 9305 
f 9325 9308 9305 
f 10688 9306 9288 
f 10687 9288 9289 
f 10687 9289 9290 
f 10687 9290 9291 
f 10690 9291 9292 
f 10690 9292 9293 
f 10690 9293 9294 
f 10482 9294 9295 
f 10482 9295 9296 
f 10482 9296 9297 
f 10612 9297 9298 
f 10612 9298 9299 
f 10612 9299 9300 
f 10612 9300 9301 
f 10559 9301 9302 
f 10559 9302 9303 
f 10688 9303 9307 
f 10688 9307 9306 
f 10687 9309 9308 
f 10687 9310 9309 
f 10690 9311 9310 
f 10690 9312 9311 
f 10482 9313 9312 
f 10482 9314 9313 
f 10482 9315 9314 
f 10482 9316 9315 
f 10482 9317 9316 
f 10482 9318 9317 
f 10612 9319 9318 
f 10612 9320 9319 
f 10559 9321 9320 
f 10559 9322 9321 
f 10559 9323 9322 
f 10559 9324 9323 
f 10688 9325 9324 
f 10688 9308 9325 
f 9326 9344 9342 
f 9327 9326 9342 
f 9328 9327 9342 
f 9329 9328 9342 
f 9330 9329 9342 
f 9331 9330 9342 
f 9332 9331 9342 
f 9333 9332 9342 
f 9334 9333 9342 
f 9335 9334 9342 
f 9336 9335 9342 
f 9337 9336 9342 
f 9338 9337 9342 
f 9339 9338 9342 
f 9340 9339 9342 
f 9341 9340 9342 
f 9345 9341 9342 
f 9344 9345 9342 
f 9346 9347 9343 
f 9347 9348 9343 
f 9348 9349 9343 
f 9349 9350 9343 
f 9350 9351 9343 
f 9351 9352 9343 
f 9352 9353 9343 
f 9353 9354 9343 
f 9354 9355 9343 
f 9355 9356 9343 
f 9356 9357 9343 
f 9357 9358 9343 
f 9358 9359 9343 
f 9359 9360 9343 
f 9360 9361 9343 
f 9361 9362 9343 
f 9362 9363 9343 
f 9363 9346 9343 
f 10634 9344 9326 
f 10634 9326 9327 
f 10634 9327 9328 
f 10634 9328 9329 
f 10540 9329 9330 
f 10540 9330 9331 
f 10540 9331 9332 
f 10540 9332 9333 
f 10584 9333 9334 
f 10584 9334 9335 
f 10584 9335 9336 
f 10584 9336 9337 
f 10666 9337 9338 
f 10666 9338 9339 
f 10473 9339 9340 
f 10473 9340 9341 
f 10473 9341 9345 
f 10473 9345 9344 
f 10634 9347 9346 
f 10634 9348 9347 
f 10634 9349 9348 
f 10634 9350 9349 
f 10540 9351 9350 
f 10540 9352 9351 
f 10700 9353 9352 
f 10700 9354 9353 
f 10584 9355 9354 
f 10584 9356 9355 
f 10584 9357 9356 
f 10584 9358 9357 
f 10666 9359 9358 
f 10666 9360 9359 
f 10473 9361 9360 
f 10473 9362 9361 
f 10473 9363 9362 
f 10634 9346 9363 
f 9364 9382 9380 
f 9365 9364 9380 
f 9366 9365 9380 
f 9367 9366 9380 
f 9368 9367 9380 
f 9369 9368 9380 
f 9370 9369 9380 
f 9371 9370 9380 
f 9372 9371 9380 
f 9373 9372 9380 
f 9374 9373 9380 
f 9375 9374 9380 
f 9376 9375 9380 
f 9377 9376 9380 
f 9378 9377 9380 
f 9379 9378 9380 
f 9383 9379 9380 
f 9382 9383 9380 
f 9384 9385 9381 
f 9385 9386 9381 
f 9386 9387 9381 
f 9387 9388 9381 
f 9388 9389 9381 
f 9389 9390 9381 
f 9390 9391 9381 
f 9391 9392 9381 
f 9392 9393 9381 
f 9393 9394 9381 
f 9394 9395 9381 
f 9395 9396 9381 
f 9396 9397 9381 
f 9397 9398 9381 
f 9398 9399 9381 
f 9399 9400 9381 
f 9400 9401 9381 
f 9401 9384 9381 
f 10485 9382 9364 
f 10485 9364 9365 
f 10588 9365 9366 
f 10588 9366 9367 
f 10649 9367 9368 
f 10649 9368 9369 
f 10649 9369 9370 
f 10649 9370 9371 
f 10539 9371 9372 
f 10539 9372 9373 
f 10539 9373 9374 
f 10539 9374 9375 
f 10622 9375 9376 
f 10622 9376 9377 
f 10485 9377 9378 
f 10485 9378 9379 
f 10485 9379 9383 
f 10485 9383 9382 
f 10485 9385 9384 
f 10485 9386 9385 
f 10588 9387 9386 
f 10588 9388 9387 
f 10649 9389 9388 
f 10649 9390 9389 
f 10649 9391 9390 
f 10649 9392 9391 
f 10539 9393 9392 
f 10539 9394 9393 
f 10539 9395 9394 
f 10539 9396 9395 
f 10622 9397 9396 
f 10622 9398 9397 
f 10485 9399 9398 
f 10485 9400 9399 
f 10485 9401 9400 
f 10485 9384 9401 
f 9402 9420 9418 
f 9403 9402 9418 
f 9404 9403 9418 
f 9405 9404 9418 
f 9406 9405 9418 
f 9407 9406 9418 
f 9408 9407 9418 
f 9409 9408 9418 
f 9410 9409 9418 
f 9411 9410 9418 
f 9412 9411 9418 
f 9413 9412 9418 
f 9414 9413 9418 
f 9415 9414 9418 
f 9416 9415 9418 
f 9417 9416 9418 
f 9421 9417 9418 
f 9420 9421 9418 
f 9422 9423 9419 
f 9423 9424 9419 
f 9424 9425 9419 
f 9425 9426 9419 
f 9426 9427 9419 
f 9427 9428 9419 
f 9428 9429 9419 
f 9429 9430 9419 
f 9430 9431 9419 
f 9431 9432 9419 
f 9432 9433 9419 
f 9433 9434 9419 
f 9434 9435 9419 
f 9435 9436 9419 
f 9436 9437 9419 
f 9437 9438 9419 
f 9438 9439 9419 
f 9439 9422 9419 
f 10573 9420 9402 
f 10573 9402 9403 
f 10573 9403 9404 
f 10573 9404 9405 
f 10667 9405 9406 
f 10667 9406 9407 
f 10667 9407 9408 
f 10535 9408 9409 
f 10535 9409 9410 
f 10535 9410 9411 
f 10610 9411 9412 
f 10610 9412 9413 
f 9440 9413 9414 
f 9440 9414 9415 
f 10702 9415 9416 
f 10702 9416 9417 
f 10702 9417 9421 
f 10702 9421 9420 
f 10573 9423 9422 
f 10573 9424 9423 
f 10668 9425 9424 
f 10668 9426 9425 
f 10535 9427 9426 
f 10535 9428 9427 
f 10535 9429 9428 
f 10535 9430 9429 
f 10610 9431 9430 
f 10610 9432 9431 
f 10610 9433 9432 
f 10610 9434 9433 
f 9440 9435 9434 
f 9440 9436 9435 
f 10573 9437 9436 
f 10573 9438 9437 
f 10573 9439 9438 
f 10573 9422 9439 
f 9441 9459 9457 
f 9442 9441 9457 
f 9443 9442 9457 
f 9444 9443 9457 
f 9445 9444 9457 
f 9446 9445 9457 
f 9447 9446 9457 
f 9448 9447 9457 
f 9449 9448 9457 
f 9450 9449 9457 
f 9451 9450 9457 
f 9452 9451 9457 
f 9453 9452 9457 
f 9454 9453 9457 
f 9455 9454 9457 
f 9456 9455 9457 
f 9460 9456 9457 
f 9459 9460 9457 
f 9461 9462 9458 
f 9462 9463 9458 
f 9463 9464 9458 
f 9464 9465 9458 
f 9465 9466 9458 
f 9466 9467 9458 
f 9467 9468 9458 
f 9468 9469 9458 
f 9469 9470 9458 
f 9470 9471 9458 
f 9471 9472 9458 
f 9472 9473 9458 
f 9473 9474 9458 
f 9474 9475 9458 
f 9475 9476 9458 
f 9476 9477 9458 
f 9477 9478 9458 
f 9478 9461 9458 
f 10571 9459 9441 
f 10571 9441 9442 
f 10571 9442 9443 
f 10571 9443 9444 
f 10664 9444 9445 
f 10664 9445 9446 
f 10664 9446 9447 
f 10531 9447 9448 
f 10531 9448 9449 
f 10531 9449 9450 
f 10608 9450 9451 
f 10608 9451 9452 
f 10495 9452 9453 
f 10495 9453 9454 
f 10701 9454 9455 
f 10701 9455 9456 
f 10701 9456 9460 
f 10701 9460 9459 
f 10571 9462 9461 
f 10571 9463 9462 
f 10665 9464 9463 
f 10665 9465 9464 
f 10531 9466 9465 
f 10531 9467 9466 
f 10531 9468 9467 
f 10531 9469 9468 
f 10608 9470 9469 
f 10608 9471 9470 
f 10608 9472 9471 
f 10608 9473 9472 
f 10495 9474 9473 
f 10495 9475 9474 
f 10571 9476 9475 
f 10571 9477 9476 
f 10571 9478 9477 
f 10571 9461 9478 
f 9479 9497 9495 
f 9480 9479 9495 
f 9481 9480 9495 
f 9482 9481 9495 
f 9483 9482 9495 
f 9484 9483 9495 
f 9485 9484 9495 
f 9486 9485 9495 
f 9487 9486 9495 
f 9488 9487 9495 
f 9489 9488 9495 
f 9490 9489 9495 
f 9491 9490 9495 
f 9492 9491 9495 
f 9493 9492 9495 
f 9494 9493 9495 
f 9498 9494 9495 
f 9497 9498 9495 
f 9499 9500 9496 
f 9500 9501 9496 
f 9501 9502 9496 
f 9502 9503 9496 
f 9503 9504 9496 
f 9504 9505 9496 
f 9505 9506 9496 
f 9506 9507 9496 
f 9507 9508 9496 
f 9508 9509 9496 
f 9509 9510 9496 
f 9510 9511 9496 
f 9511 9512 9496 
f 9512 9513 9496 
f 9513 9514 9496 
f 9514 9515 9496 
f 9515 9516 9496 
f 9516 9499 9496 
f 10501 9497 9479 
f 10501 9479 9480 
f 10501 9480 9481 
f 10501 9481 9482 
f 10501 9482 9483 
f 10533 9483 9484 
f 10533 9484 9485 
f 10675 9485 9486 
f 10675 9486 9487 
f 10675 9487 9488 
f 10566 9488 9489 
f 10566 9489 9490 
f 10566 9490 9491 
f 10566 9491 9492 
f 10566 9492 9493 
f 10566 9493 9494 
f 10501 9494 9498 
f 10501 9498 9497 
f 10501 9500 9499 
f 10501 9501 9500 
f 10501 9502 9501 
f 10501 9503 9502 
f 10501 9504 9503 
f 10675 9505 9504 
f 10675 9506 9505 
f 10675 9507 9506 
f 10675 9508 9507 
f 10566 9509 9508 
f 10566 9510 9509 
f 10566 9511 9510 
f 10566 9512 9511 
f 10566 9513 9512 
f 10566 9514 9513 
f 10501 9515 9514 
f 10501 9516 9515 
f 10501 9499 9516 
f 9517 9535 9533 
f 9518 9517 9533 
f 9519 9518 9533 
f 9520 9519 9533 
f 9521 9520 9533 
f 9522 9521 9533 
f 9523 9522 9533 
f 9524 9523 9533 
f 9525 9524 9533 
f 9526 9525 9533 
f 9527 9526 9533 
f 9528 9527 9533 
f 9529 9528 9533 
f 9530 9529 9533 
f 9531 9530 9533 
f 9532 9531 9533 
f 9536 9532 9533 
f 9535 9536 9533 
f 9537 9538 9534 
f 9538 9539 9534 
f 9539 9540 9534 
f 9540 9541 9534 
f 9541 9542 9534 
f 9542 9543 9534 
f 9543 9544 9534 
f 9544 9545 9534 
f 9545 9546 9534 
f 9546 9547 9534 
f 9547 9548 9534 
f 9548 9549 9534 
f 9549 9550 9534 
f 9550 9551 9534 
f 9551 9552 9534 
f 9552 9553 9534 
f 9553 9554 9534 
f 9554 9537 9534 
f 10478 9535 9517 
f 10478 9517 9518 
f 10478 9518 9519 
f 10602 9519 9520 
f 10602 9520 9521 
f 10520 9521 9522 
f 10520 9522 9523 
f 10520 9523 9524 
f 10520 9524 9525 
f 10520 9525 9526 
f 10520 9526 9527 
f 10576 9527 9528 
f 10576 9528 9529 
f 10576 9529 9530 
f 10576 9530 9531 
f 10576 9531 9532 
f 10478 9532 9536 
f 10478 9536 9535 
f 10478 9538 9537 
f 10478 9539 9538 
f 10478 9540 9539 
f 10478 9541 9540 
f 10602 9542 9541 
f 10520 9543 9542 
f 10520 9544 9543 
f 10520 9545 9544 
f 10520 9546 9545 
f 10520 9547 9546 
f 10576 9548 9547 
f 10576 9549 9548 
f 10576 9550 9549 
f 10576 9551 9550 
f 10576 9552 9551 
f 10478 9553 9552 
f 10478 9554 9553 
f 10478 9537 9554 
f 9555 9573 9571 
f 9556 9555 9571 
f 9557 9556 9571 
f 9558 9557 9571 
f 9559 9558 9571 
f 9560 9559 9571 
f 9561 9560 9571 
f 9562 9561 9571 
f 9563 9562 9571 
f 9564 9563 9571 
f 9565 9564 9571 
f 9566 9565 9571 
f 9567 9566 9571 
f 9568 9567 9571 
f 9569 9568 9571 
f 9570 9569 9571 
f 9574 9570 9571 
f 9573 9574 9571 
f 9575 9576 9572 
f 9576 9577 9572 
f 9577 9578 9572 
f 9578 9579 9572 
f 9579 9580 9572 
f 9580 9581 9572 
f 9581 9582 9572 
f 9582 9583 9572 
f 9583 9584 9572 
f 9584 9585 9572 
f 9585 9586 9572 
f 9586 9587 9572 
f 9587 9588 9572 
f 9588 9589 9572 
f 9589 9590 9572 
f 9590 9591 9572 
f 9591 9592 9572 
f 9592 9575 9572 
f 10546 9573 9555 
f 10630 9555 9556 
f 10630 9556 9557 
f 10630 9557 9558 
f 10630 9558 9559 
f 10474 9559 9560 
f 10474 9560 9561 
f 10474 9561 9562 
f 10474 9562 9563 
f 10474 9563 9564 
f 10474 9564 9565 
f 10546 9565 9566 
f 10546 9566 9567 
f 10546 9567 9568 
f 10546 9568 9569 
f 10546 9569 9570 
f 10546 9570 9574 
f 10546 9574 9573 
f 10546 9576 9575 
f 10630 9577 9576 
f 10630 9578 9577 
f 10630 9579 9578 
f 10630 9580 9579 
f 10474 9581 9580 
f 10474 9582 9581 
f 10474 9583 9582 
f 10474 9584 9583 
f 10474 9585 9584 
f 10546 9586 9585 
f 10546 9587 9586 
f 10546 9588 9587 
f 10546 9589 9588 
f 10546 9590 9589 
f 10546 9591 9590 
f 10546 9592 9591 
f 10546 9575 9592 
f 9593 9611 9609 
f 9594 9593 9609 
f 9595 9594 9609 
f 9596 9595 9609 
f 9597 9596 9609 
f 9598 9597 9609 
f 9599 9598 9609 
f 9600 9599 9609 
f 9601 9600 9609 
f 9602 9601 9609 
f 9603 9602 9609 
f 9604 9603 9609 
f 9605 9604 9609 
f 9606 9605 9609 
f 9607 9606 9609 
f 9608 9607 9609 
f 9612 9608 9609 
f 9611 9612 9609 
f 9613 9614 9610 
f 9614 9615 9610 
f 9615 9616 9610 
f 9616 9617 9610 
f 9617 9618 9610 
f 9618 9619 9610 
f 9619 9620 9610 
f 9620 9621 9610 
f 9621 9622 9610 
f 9622 9623 9610 
f 9623 9624 9610 
f 9624 9625 9610 
f 9625 9626 9610 
f 9626 9627 9610 
f 9627 9628 9610 
f 9628 9629 9610 
f 9629 9630 9610 
f 9630 9613 9610 
f 10659 9611 9593 
f 10555 9593 9594 
f 10555 9594 9595 
f 10555 9595 9596 
f 10555 9596 9597 
f 10555 9597 9598 
f 10698 9598 9599 
f 10698 9599 9600 
f 9631 9600 9601 
f 9631 9601 9602 
f 9631 9602 9603 
f 9631 9603 9604 
f 9631 9604 9605 
f 9631 9605 9606 
f 9631 9606 9607 
f 9631 9607 9608 
f 9631 9608 9612 
f 9631 9612 9611 
f 10659 9614 9613 
f 10555 9615 9614 
f 10555 9616 9615 
f 10555 9617 9616 
f 10698 9618 9617 
f 10698 9619 9618 
f 10698 9620 9619 
f 9631 9621 9620 
f 9631 9622 9621 
f 9631 9623 9622 
f 9631 9624 9623 
f 9631 9625 9624 
f 9631 9626 9625 
f 9631 9627 9626 
f 9631 9628 9627 
f 9631 9629 9628 
f 9631 9630 9629 
f 10659 9613 9630 
f 9632 9650 9648 
f 9633 9632 9648 
f 9634 9633 9648 
f 9635 9634 9648 
f 9636 9635 9648 
f 9637 9636 9648 
f 9638 9637 9648 
f 9639 9638 9648 
f 9640 9639 9648 
f 9641 9640 9648 
f 9642 9641 9648 
f 9643 9642 9648 
f 9644 9643 9648 
f 9645 9644 9648 
f 9646 9645 9648 
f 9647 9646 9648 
f 9651 9647 9648 
f 9650 9651 9648 
f 9652 9653 9649 
f 9653 9654 9649 
f 9654 9655 9649 
f 9655 9656 9649 
f 9656 9657 9649 
f 9657 9658 9649 
f 9658 9659 9649 
f 9659 9660 9649 
f 9660 9661 9649 
f 9661 9662 9649 
f 9662 9663 9649 
f 9663 9664 9649 
f 9664 9665 9649 
f 9665 9666 9649 
f 9666 9667 9649 
f 9667 9668 9649 
f 9668 9669 9649 
f 9669 9652 9649 
f 10586 9650 9632 
f 10669 9632 9633 
f 10669 9633 9634 
f 10671 9634 9635 
f 10671 9635 9636 
f 10671 9636 9637 
f 10545 9637 9638 
f 10545 9638 9639 
f 10637 9639 9640 
f 10637 9640 9641 
f 10637 9641 9642 
f 10637 9642 9643 
f 10486 9643 9644 
f 10486 9644 9645 
f 10486 9645 9646 
f 10486 9646 9647 
f 10586 9647 9651 
f 10586 9651 9650 
f 10586 9653 9652 
f 10669 9654 9653 
f 10671 9655 9654 
f 10671 9656 9655 
f 10545 9657 9656 
f 10637 9658 9657 
f 10637 9659 9658 
f 10637 9660 9659 
f 10637 9661 9660 
f 10637 9662 9661 
f 10637 9663 9662 
f 10486 9664 9663 
f 10486 9665 9664 
f 10486 9666 9665 
f 10586 9667 9666 
f 10586 9668 9667 
f 10586 9669 9668 
f 10586 9652 9669 
f 9670 9688 9686 
f 9671 9670 9686 
f 9672 9671 9686 
f 9673 9672 9686 
f 9674 9673 9686 
f 9675 9674 9686 
f 9676 9675 9686 
f 9677 9676 9686 
f 9678 9677 9686 
f 9679 9678 9686 
f 9680 9679 9686 
f 9681 9680 9686 
f 9682 9681 9686 
f 9683 9682 9686 
f 9684 9683 9686 
f 9685 9684 9686 
f 9689 9685 9686 
f 9688 9689 9686 
f 9690 9691 9687 
f 9691 9692 9687 
f 9692 9693 9687 
f 9693 9694 9687 
f 9694 9695 9687 
f 9695 9696 9687 
f 9696 9697 9687 
f 9697 9698 9687 
f 9698 9699 9687 
f 9699 9700 9687 
f 9700 9701 9687 
f 9701 9702 9687 
f 9702 9703 9687 
f 9703 9704 9687 
f 9704 9705 9687 
f 9705 9706 9687 
f 9706 9707 9687 
f 9707 9690 9687 
f 9708 9688 9670 
f 10530 9670 9671 
f 10530 9671 9672 
f 10621 9672 9673 
f 10621 9673 9674 
f 10621 9674 9675 
f 10621 9675 9676 
f 10621 9676 9677 
f 10596 9677 9678 
f 10596 9678 9679 
f 10596 9679 9680 
f 10596 9680 9681 
f 10672 9681 9682 
f 10672 9682 9683 
f 9708 9683 9684 
f 9708 9684 9685 
f 9708 9685 9689 
f 9708 9689 9688 
f 10530 9691 9690 
f 10530 9692 9691 
f 10530 9693 9692 
f 10530 9694 9693 
f 10530 9695 9694 
f 10621 9696 9695 
f 10621 9697 9696 
f 10596 9698 9697 
f 10596 9699 9698 
f 10596 9700 9699 
f 10596 9701 9700 
f 10672 9702 9701 
f 10672 9703 9702 
f 9708 9704 9703 
f 9708 9705 9704 
f 9708 9706 9705 
f 9708 9707 9706 
f 9708 9690 9707 
f 9709 9727 9725 
f 9710 9709 9725 
f 9711 9710 9725 
f 9712 9711 9725 
f 9713 9712 9725 
f 9714 9713 9725 
f 9715 9714 9725 
f 9716 9715 9725 
f 9717 9716 9725 
f 9718 9717 9725 
f 9719 9718 9725 
f 9720 9719 9725 
f 9721 9720 9725 
f 9722 9721 9725 
f 9723 9722 9725 
f 9724 9723 9725 
f 9728 9724 9725 
f 9727 9728 9725 
f 9729 9730 9726 
f 9730 9731 9726 
f 9731 9732 9726 
f 9732 9733 9726 
f 9733 9734 9726 
f 9734 9735 9726 
f 9735 9736 9726 
f 9736 9737 9726 
f 9737 9738 9726 
f 9738 9739 9726 
f 9739 9740 9726 
f 9740 9741 9726 
f 9741 9742 9726 
f 9742 9743 9726 
f 9743 9744 9726 
f 9744 9745 9726 
f 9745 9746 9726 
f 9746 9729 9726 
f 10645 9727 9709 
f 10645 9709 9710 
f 10645 9710 9711 
f 10645 9711 9712 
f 9747 9712 9713 
f 9747 9713 9714 
f 9747 9714 9715 
f 9747 9715 9716 
f 10689 9716 9717 
f 10618 9717 9718 
f 10618 9718 9719 
f 10618 9719 9720 
f 10618 9720 9721 
f 10618 9721 9722 
f 10692 9722 9723 
f 10692 9723 9724 
f 10692 9724 9728 
f 10645 9728 9727 
f 10645 9730 9729 
f 10645 9731 9730 
f 10645 9732 9731 
f 9747 9733 9732 
f 9747 9734 9733 
f 9747 9735 9734 
f 9747 9736 9735 
f 10689 9737 9736 
f 10689 9738 9737 
f 10618 9739 9738 
f 10618 9740 9739 
f 10692 9741 9740 
f 10692 9742 9741 
f 10536 9743 9742 
f 10536 9744 9743 
f 10536 9745 9744 
f 10692 9746 9745 
f 10692 9729 9746 
f 9748 9766 9764 
f 9749 9748 9764 
f 9750 9749 9764 
f 9751 9750 9764 
f 9752 9751 9764 
f 9753 9752 9764 
f 9754 9753 9764 
f 9755 9754 9764 
f 9756 9755 9764 
f 9757 9756 9764 
f 9758 9757 9764 
f 9759 9758 9764 
f 9760 9759 9764 
f 9761 9760 9764 
f 9762 9761 9764 
f 9763 9762 9764 
f 9767 9763 9764 
f 9766 9767 9764 
f 9768 9769 9765 
f 9769 9770 9765 
f 9770 9771 9765 
f 9771 9772 9765 
f 9772 9773 9765 
f 9773 9774 9765 
f 9774 9775 9765 
f 9775 9776 9765 
f 9776 9777 9765 
f 9777 9778 9765 
f 9778 9779 9765 
f 9779 9780 9765 
f 9780 9781 9765 
f 9781 9782 9765 
f 9782 9783 9765 
f 9783 9784 9765 
f 9784 9785 9765 
f 9785 9768 9765 
f 10547 9766 9748 
f 10547 9748 9749 
f 10547 9749 9750 
f 10547 9750 9751 
f 10680 9751 9752 
f 10680 9752 9753 
f 10680 9753 9754 
f 10680 9754 9755 
f 10597 9755 9756 
f 10597 9756 9757 
f 10511 9757 9758 
f 10511 9758 9759 
f 10511 9759 9760 
f 10511 9760 9761 
f 10694 9761 9762 
f 10694 9762 9763 
f 10694 9763 9767 
f 10547 9767 9766 
f 10547 9769 9768 
f 10547 9770 9769 
f 10547 9771 9770 
f 10680 9772 9771 
f 10680 9773 9772 
f 10680 9774 9773 
f 10597 9775 9774 
f 10597 9776 9775 
f 10597 9777 9776 
f 10597 9778 9777 
f 10597 9779 9778 
f 10694 9780 9779 
f 10694 9781 9780 
f 10642 9782 9781 
f 10642 9783 9782 
f 10642 9784 9783 
f 10694 9785 9784 
f 10694 9768 9785 
f 9786 9804 9802 
f 9787 9786 9802 
f 9788 9787 9802 
f 9789 9788 9802 
f 9790 9789 9802 
f 9791 9790 9802 
f 9792 9791 9802 
f 9793 9792 9802 
f 9794 9793 9802 
f 9795 9794 9802 
f 9796 9795 9802 
f 9797 9796 9802 
f 9798 9797 9802 
f 9799 9798 9802 
f 9800 9799 9802 
f 9801 9800 9802 
f 9805 9801 9802 
f 9804 9805 9802 
f 9806 9807 9803 
f 9807 9808 9803 
f 9808 9809 9803 
f 9809 9810 9803 
f 9810 9811 9803 
f 9811 9812 9803 
f 9812 9813 9803 
f 9813 9814 9803 
f 9814 9815 9803 
f 9815 9816 9803 
f 9816 9817 9803 
f 9817 9818 9803 
f 9818 9819 9803 
f 9819 9820 9803 
f 9820 9821 9803 
f 9821 9822 9803 
f 9822 9823 9803 
f 9823 9806 9803 
f 10548 9804 9786 
f 10548 9786 9787 
f 10548 9787 9788 
f 10548 9788 9789 
f 10632 9789 9790 
f 10632 9790 9791 
f 10632 9791 9792 
f 10632 9792 9793 
f 10507 9793 9794 
f 10507 9794 9795 
f 10507 9795 9796 
f 10507 9796 9797 
f 10507 9797 9798 
f 10507 9798 9799 
f 10615 9799 9800 
f 10615 9800 9801 
f 10615 9801 9805 
f 10548 9805 9804 
f 10548 9807 9806 
f 10548 9808 9807 
f 10548 9809 9808 
f 10632 9810 9809 
f 10632 9811 9810 
f 10632 9812 9811 
f 10507 9813 9812 
f 10507 9814 9813 
f 10507 9815 9814 
f 10507 9816 9815 
f 10507 9817 9816 
f 10507 9818 9817 
f 10507 9819 9818 
f 10615 9820 9819 
f 10615 9821 9820 
f 10615 9822 9821 
f 10548 9823 9822 
f 10548 9806 9823 
f 9824 9842 9840 
f 9825 9824 9840 
f 9826 9825 9840 
f 9827 9826 9840 
f 9828 9827 9840 
f 9829 9828 9840 
f 9830 9829 9840 
f 9831 9830 9840 
f 9832 9831 9840 
f 9833 9832 9840 
f 9834 9833 9840 
f 9835 9834 9840 
f 9836 9835 9840 
f 9837 9836 9840 
f 9838 9837 9840 
f 9839 9838 9840 
f 9843 9839 9840 
f 9842 9843 9840 
f 9844 9845 9841 
f 9845 9846 9841 
f 9846 9847 9841 
f 9847 9848 9841 
f 9848 9849 9841 
f 9849 9850 9841 
f 9850 9851 9841 
f 9851 9852 9841 
f 9852 9853 9841 
f 9853 9854 9841 
f 9854 9855 9841 
f 9855 9856 9841 
f 9856 9857 9841 
f 9857 9858 9841 
f 9858 9859 9841 
f 9859 9860 9841 
f 9860 9861 9841 
f 9861 9844 9841 
f 10542 9842 9824 
f 10542 9824 9825 
f 10542 9825 9826 
f 10542 9826 9827 
f 10542 9827 9828 
f 10542 9828 9829 
f 10542 9829 9830 
f 10542 9830 9831 
f 10663 9831 9832 
f 10663 9832 9833 
f 10508 9833 9834 
f 10508 9834 9835 
f 10508 9835 9836 
f 10508 9836 9837 
f 10508 9837 9838 
f 10611 9838 9839 
f 10611 9839 9843 
f 10611 9843 9842 
f 10542 9845 9844 
f 10542 9846 9845 
f 10542 9847 9846 
f 10542 9848 9847 
f 10542 9849 9848 
f 10542 9850 9849 
f 10542 9851 9850 
f 10663 9852 9851 
f 10663 9853 9852 
f 10508 9854 9853 
f 10508 9855 9854 
f 10508 9856 9855 
f 10508 9857 9856 
f 10611 9858 9857 
f 10611 9859 9858 
f 10611 9860 9859 
f 10611 9861 9860 
f 10611 9844 9861 
f 9862 9880 9878 
f 9863 9862 9878 
f 9864 9863 9878 
f 9865 9864 9878 
f 9866 9865 9878 
f 9867 9866 9878 
f 9868 9867 9878 
f 9869 9868 9878 
f 9870 9869 9878 
f 9871 9870 9878 
f 9872 9871 9878 
f 9873 9872 9878 
f 9874 9873 9878 
f 9875 9874 9878 
f 9876 9875 9878 
f 9877 9876 9878 
f 9881 9877 9878 
f 9880 9881 9878 
f 9882 9883 9879 
f 9883 9884 9879 
f 9884 9885 9879 
f 9885 9886 9879 
f 9886 9887 9879 
f 9887 9888 9879 
f 9888 9889 9879 
f 9889 9890 9879 
f 9890 9891 9879 
f 9891 9892 9879 
f 9892 9893 9879 
f 9893 9894 9879 
f 9894 9895 9879 
f 9895 9896 9879 
f 9896 9897 9879 
f 9897 9898 9879 
f 9898 9899 9879 
f 9899 9882 9879 
f 10500 9880 9862 
f 10655 9862 9863 
f 10655 9863 9864 
f 10655 9864 9865 
f 10556 9865 9866 
f 10556 9866 9867 
f 10556 9867 9868 
f 10556 9868 9869 
f 10696 9869 9870 
f 10696 9870 9871 
f 10650 9871 9872 
f 10650 9872 9873 
f 10650 9873 9874 
f 10650 9874 9875 
f 10500 9875 9876 
f 10500 9876 9877 
f 10500 9877 9881 
f 10500 9881 9880 
f 10500 9883 9882 
f 10655 9884 9883 
f 10655 9885 9884 
f 10556 9886 9885 
f 10556 9887 9886 
f 10556 9888 9887 
f 10556 9889 9888 
f 10696 9890 9889 
f 10696 9891 9890 
f 10650 9892 9891 
f 10650 9893 9892 
f 10650 9894 9893 
f 10650 9895 9894 
f 10650 9896 9895 
f 10650 9897 9896 
f 10500 9898 9897 
f 10500 9899 9898 
f 10500 9882 9899 
f 9900 9918 9916 
f 9901 9900 9916 
f 9902 9901 9916 
f 9903 9902 9916 
f 9904 9903 9916 
f 9905 9904 9916 
f 9906 9905 9916 
f 9907 9906 9916 
f 9908 9907 9916 
f 9909 9908 9916 
f 9910 9909 9916 
f 9911 9910 9916 
f 9912 9911 9916 
f 9913 9912 9916 
f 9914 9913 9916 
f 9915 9914 9916 
f 9919 9915 9916 
f 9918 9919 9916 
f 9920 9921 9917 
f 9921 9922 9917 
f 9922 9923 9917 
f 9923 9924 9917 
f 9924 9925 9917 
f 9925 9926 9917 
f 9926 9927 9917 
f 9927 9928 9917 
f 9928 9929 9917 
f 9929 9930 9917 
f 9930 9931 9917 
f 9931 9932 9917 
f 9932 9933 9917 
f 9933 9934 9917 
f 9934 9935 9917 
f 9935 9936 9917 
f 9936 9937 9917 
f 9937 9920 9917 
f 10541 9918 9900 
f 10541 9900 9901 
f 10541 9901 9902 
f 10541 9902 9903 
f 10541 9903 9904 
f 10541 9904 9905 
f 10541 9905 9906 
f 10541 9906 9907 
f 10497 9907 9908 
f 10497 9908 9909 
f 10684 9909 9910 
f 10684 9910 9911 
f 10684 9911 9912 
f 10684 9912 9913 
f 10541 9913 9914 
f 10541 9914 9915 
f 10541 9915 9919 
f 10541 9919 9918 
f 10541 9921 9920 
f 10541 9922 9921 
f 10541 9923 9922 
f 10541 9924 9923 
f 10541 9925 9924 
f 10541 9926 9925 
f 10541 9927 9926 
f 10497 9928 9927 
f 10497 9929 9928 
f 10497 9930 9929 
f 10684 9931 9930 
f 10684 9932 9931 
f 10541 9933 9932 
f 10541 9934 9933 
f 10541 9935 9934 
f 10541 9936 9935 
f 10541 9937 9936 
f 10541 9920 9937 
f 9938 9956 9954 
f 9939 9938 9954 
f 9940 9939 9954 
f 9941 9940 9954 
f 9942 9941 9954 
f 9943 9942 9954 
f 9944 9943 9954 
f 9945 9944 9954 
f 9946 9945 9954 
f 9947 9946 9954 
f 9948 9947 9954 
f 9949 9948 9954 
f 9950 9949 9954 
f 9951 9950 9954 
f 9952 9951 9954 
f 9953 9952 9954 
f 9957 9953 9954 
f 9956 9957 9954 
f 9958 9959 9955 
f 9959 9960 9955 
f 9960 9961 9955 
f 9961 9962 9955 
f 9962 9963 9955 
f 9963 9964 9955 
f 9964 9965 9955 
f 9965 9966 9955 
f 9966 9967 9955 
f 9967 9968 9955 
f 9968 9969 9955 
f 9969 9970 9955 
f 9970 9971 9955 
f 9971 9972 9955 
f 9972 9973 9955 
f 9973 9974 9955 
f 9974 9975 9955 
f 9975 9958 9955 
f 10616 9956 9938 
f 10616 9938 9939 
f 10616 9939 9940 
f 10616 9940 9941 
f 10550 9941 9942 
f 10550 9942 9943 
f 10550 9943 9944 
f 10550 9944 9945 
f 10550 9945 9946 
f 10550 9946 9947 
f 10505 9947 9948 
f 10505 9948 9949 
f 10505 9949 9950 
f 10505 9950 9951 
f 10656 9951 9952 
f 10656 9952 9953 
f 10656 9953 9957 
f 10616 9957 9956 
f 10616 9959 9958 
f 10616 9960 9959 
f 10616 9961 9960 
f 10685 9962 9961 
f 10550 9963 9962 
f 10550 9964 9963 
f 10550 9965 9964 
f 10550 9966 9965 
f 10550 9967 9966 
f 10550 9968 9967 
f 10505 9969 9968 
f 10505 9970 9969 
f 10505 9971 9970 
f 10505 9972 9971 
f 10505 9973 9972 
f 10656 9974 9973 
f 10656 9975 9974 
f 10656 9958 9975 
f 9976 9994 9992 
f 9977 9976 9992 
f 9978 9977 9992 
f 9979 9978 9992 
f 9980 9979 9992 
f 9981 9980 9992 
f 9982 9981 9992 
f 9983 9982 9992 
f 9984 9983 9992 
f 9985 9984 9992 
f 9986 9985 9992 
f 9987 9986 9992 
f 9988 9987 9992 
f 9989 9988 9992 
f 9990 9989 9992 
f 9991 9990 9992 
f 9995 9991 9992 
f 9994 9995 9992 
f 9996 9997 9993 
f 9997 9998 9993 
f 9998 9999 9993 
f 9999 10000 9993 
f 10000 10001 9993 
f 10001 10002 9993 
f 10002 10003 9993 
f 10003 10004 9993 
f 10004 10005 9993 
f 10005 10006 9993 
f 10006 10007 9993 
f 10007 10008 9993 
f 10008 10009 9993 
f 10009 10010 9993 
f 10010 10011 9993 
f 10011 10012 9993 
f 10012 10013 9993 
f 10013 9996 9993 
f 10014 9994 9976 
f 10014 9976 9977 
f 10014 9977 9978 
f 10014 9978 9979 
f 10014 9979 9980 
f 10014 9980 9981 
f 10613 9981 9982 
f 10613 9982 9983 
f 10613 9983 9984 
f 10613 9984 9985 
f 10613 9985 9986 
f 10613 9986 9987 
f 10613 9987 9988 
f 10613 9988 9989 
f 10613 9989 9990 
f 10563 9990 9991 
f 10563 9991 9995 
f 10563 9995 9994 
f 10699 9997 9996 
f 10699 9998 9997 
f 10014 9999 9998 
f 10014 10000 9999 
f 10014 10001 10000 
f 10014 10002 10001 
f 10613 10003 10002 
f 10613 10004 10003 
f 10613 10005 10004 
f 10613 10006 10005 
f 10613 10007 10006 
f 10613 10008 10007 
f 10613 10009 10008 
f 10563 10010 10009 
f 10563 10011 10010 
f 10563 10012 10011 
f 10699 10013 10012 
f 10699 9996 10013 
f 10015 10033 10031 
f 10016 10015 10031 
f 10017 10016 10031 
f 10018 10017 10031 
f 10019 10018 10031 
f 10020 10019 10031 
f 10021 10020 10031 
f 10022 10021 10031 
f 10023 10022 10031 
f 10024 10023 10031 
f 10025 10024 10031 
f 10026 10025 10031 
f 10027 10026 10031 
f 10028 10027 10031 
f 10029 10028 10031 
f 10030 10029 10031 
f 10034 10030 10031 
f 10033 10034 10031 
f 10035 10036 10032 
f 10036 10037 10032 
f 10037 10038 10032 
f 10038 10039 10032 
f 10039 10040 10032 
f 10040 10041 10032 
f 10041 10042 10032 
f 10042 10043 10032 
f 10043 10044 10032 
f 10044 10045 10032 
f 10045 10046 10032 
f 10046 10047 10032 
f 10047 10048 10032 
f 10048 10049 10032 
f 10049 10050 10032 
f 10050 10051 10032 
f 10051 10052 10032 
f 10052 10035 10032 
f 10575 10033 10015 
f 10575 10015 10016 
f 10575 10016 10017 
f 10476 10017 10018 
f 10476 10018 10019 
f 10476 10019 10020 
f 10476 10020 10021 
f 10476 10021 10022 
f 10476 10022 10023 
f 10476 10023 10024 
f 10476 10024 10025 
f 10646 10025 10026 
f 10646 10026 10027 
f 10528 10027 10028 
f 10528 10028 10029 
f 10575 10029 10030 
f 10575 10030 10034 
f 10575 10034 10033 
f 10575 10036 10035 
f 10476 10037 10036 
f 10476 10038 10037 
f 10476 10039 10038 
f 10476 10040 10039 
f 10476 10041 10040 
f 10476 10042 10041 
f 10476 10043 10042 
f 10476 10044 10043 
f 10646 10045 10044 
f 10646 10046 10045 
f 10528 10047 10046 
f 10528 10048 10047 
f 10528 10049 10048 
f 10528 10050 10049 
f 10575 10051 10050 
f 10575 10052 10051 
f 10575 10035 10052 
f 10053 10071 10069 
f 10054 10053 10069 
f 10055 10054 10069 
f 10056 10055 10069 
f 10057 10056 10069 
f 10058 10057 10069 
f 10059 10058 10069 
f 10060 10059 10069 
f 10061 10060 10069 
f 10062 10061 10069 
f 10063 10062 10069 
f 10064 10063 10069 
f 10065 10064 10069 
f 10066 10065 10069 
f 10067 10066 10069 
f 10068 10067 10069 
f 10072 10068 10069 
f 10071 10072 10069 
f 10073 10074 10070 
f 10074 10075 10070 
f 10075 10076 10070 
f 10076 10077 10070 
f 10077 10078 10070 
f 10078 10079 10070 
f 10079 10080 10070 
f 10080 10081 10070 
f 10081 10082 10070 
f 10082 10083 10070 
f 10083 10084 10070 
f 10084 10085 10070 
f 10085 10086 10070 
f 10086 10087 10070 
f 10087 10088 10070 
f 10088 10089 10070 
f 10089 10090 10070 
f 10090 10073 10070 
f 10592 10071 10053 
f 10592 10053 10054 
f 10592 10054 10055 
f 10592 10055 10056 
f 10592 10056 10057 
f 10592 10057 10058 
f 10518 10058 10059 
f 10518 10059 10060 
f 10518 10060 10061 
f 10518 10061 10062 
f 10591 10062 10063 
f 10591 10063 10064 
f 10591 10064 10065 
f 10591 10065 10066 
f 10591 10066 10067 
f 10591 10067 10068 
f 10472 10068 10072 
f 10472 10072 10071 
f 10681 10074 10073 
f 10681 10075 10074 
f 10681 10076 10075 
f 10592 10077 10076 
f 10592 10078 10077 
f 10592 10079 10078 
f 10592 10080 10079 
f 10518 10081 10080 
f 10518 10082 10081 
f 10591 10083 10082 
f 10591 10084 10083 
f 10591 10085 10084 
f 10591 10086 10085 
f 10591 10087 10086 
f 10591 10088 10087 
f 10472 10089 10088 
f 10472 10090 10089 
f 10681 10073 10090 
f 10091 10109 10107 
f 10092 10091 10107 
f 10093 10092 10107 
f 10094 10093 10107 
f 10095 10094 10107 
f 10096 10095 10107 
f 10097 10096 10107 
f 10098 10097 10107 
f 10099 10098 10107 
f 10100 10099 10107 
f 10101 10100 10107 
f 10102 10101 10107 
f 10103 10102 10107 
f 10104 10103 10107 
f 10105 10104 10107 
f 10106 10105 10107 
f 10110 10106 10107 
f 10109 10110 10107 
f 10111 10112 10108 
f 10112 10113 10108 
f 10113 10114 10108 
f 10114 10115 10108 
f 10115 10116 10108 
f 10116 10117 10108 
f 10117 10118 10108 
f 10118 10119 10108 
f 10119 10120 10108 
f 10120 10121 10108 
f 10121 10122 10108 
f 10122 10123 10108 
f 10123 10124 10108 
f 10124 10125 10108 
f 10125 10126 10108 
f 10126 10127 10108 
f 10127 10128 10108 
f 10128 10111 10108 
f 10487 10109 10091 
f 10487 10091 10092 
f 10487 10092 10093 
f 10487 10093 10094 
f 10487 10094 10095 
f 10513 10095 10096 
f 10513 10096 10097 
f 10625 10097 10098 
f 10625 10098 10099 
f 10625 10099 10100 
f 10583 10100 10101 
f 10583 10101 10102 
f 10552 10102 10103 
f 10552 10103 10104 
f 10552 10104 10105 
f 10552 10105 10106 
f 10487 10106 10110 
f 10487 10110 10109 
f 10487 10112 10111 
f 10487 10113 10112 
f 10487 10114 10113 
f 10487 10115 10114 
f 10487 10116 10115 
f 10513 10117 10116 
f 10513 10118 10117 
f 10625 10119 10118 
f 10625 10120 10119 
f 10583 10121 10120 
f 10583 10122 10121 
f 10552 10123 10122 
f 10552 10124 10123 
f 10552 10125 10124 
f 10552 10126 10125 
f 10487 10127 10126 
f 10487 10128 10127 
f 10487 10111 10128 
f 10129 10147 10145 
f 10130 10129 10145 
f 10131 10130 10145 
f 10132 10131 10145 
f 10133 10132 10145 
f 10134 10133 10145 
f 10135 10134 10145 
f 10136 10135 10145 
f 10137 10136 10145 
f 10138 10137 10145 
f 10139 10138 10145 
f 10140 10139 10145 
f 10141 10140 10145 
f 10142 10141 10145 
f 10143 10142 10145 
f 10144 10143 10145 
f 10148 10144 10145 
f 10147 10148 10145 
f 10149 10150 10146 
f 10150 10151 10146 
f 10151 10152 10146 
f 10152 10153 10146 
f 10153 10154 10146 
f 10154 10155 10146 
f 10155 10156 10146 
f 10156 10157 10146 
f 10157 10158 10146 
f 10158 10159 10146 
f 10159 10160 10146 
f 10160 10161 10146 
f 10161 10162 10146 
f 10162 10163 10146 
f 10163 10164 10146 
f 10164 10165 10146 
f 10165 10166 10146 
f 10166 10149 10146 
f 10494 10147 10129 
f 10494 10129 10130 
f 10494 10130 10131 
f 10494 10131 10132 
f 10494 10132 10133 
f 10515 10133 10134 
f 10515 10134 10135 
f 10515 10135 10136 
f 10515 10136 10137 
f 10515 10137 10138 
f 10515 10138 10139 
f 10515 10139 10140 
f 10626 10140 10141 
f 10626 10141 10142 
f 10561 10142 10143 
f 10561 10143 10144 
f 10561 10144 10148 
f 10561 10148 10147 
f 10494 10150 10149 
f 10494 10151 10150 
f 10494 10152 10151 
f 10494 10153 10152 
f 10494 10154 10153 
f 10515 10155 10154 
f 10515 10156 10155 
f 10515 10157 10156 
f 10515 10158 10157 
f 10515 10159 10158 
f 10515 10160 10159 
f 10626 10161 10160 
f 10626 10162 10161 
f 10626 10163 10162 
f 10561 10164 10163 
f 10561 10165 10164 
f 10561 10166 10165 
f 10561 10149 10166 
f 10167 10185 10183 
f 10168 10167 10183 
f 10169 10168 10183 
f 10170 10169 10183 
f 10171 10170 10183 
f 10172 10171 10183 
f 10173 10172 10183 
f 10174 10173 10183 
f 10175 10174 10183 
f 10176 10175 10183 
f 10177 10176 10183 
f 10178 10177 10183 
f 10179 10178 10183 
f 10180 10179 10183 
f 10181 10180 10183 
f 10182 10181 10183 
f 10186 10182 10183 
f 10185 10186 10183 
f 10187 10188 10184 
f 10188 10189 10184 
f 10189 10190 10184 
f 10190 10191 10184 
f 10191 10192 10184 
f 10192 10193 10184 
f 10193 10194 10184 
f 10194 10195 10184 
f 10195 10196 10184 
f 10196 10197 10184 
f 10197 10198 10184 
f 10198 10199 10184 
f 10199 10200 10184 
f 10200 10201 10184 
f 10201 10202 10184 
f 10202 10203 10184 
f 10203 10204 10184 
f 10204 10187 10184 
f 10519 10185 10167 
f 10519 10167 10168 
f 10519 10168 10169 
f 10519 10169 10170 
f 10519 10170 10171 
f 10578 10171 10172 
f 10578 10172 10173 
f 10578 10173 10174 
f 10578 10174 10175 
f 10578 10175 10176 
f 10480 10176 10177 
f 10480 10177 10178 
f 10480 10178 10179 
f 10480 10179 10180 
f 10599 10180 10181 
f 10599 10181 10182 
f 10599 10182 10186 
f 10599 10186 10185 
f 10599 10188 10187 
f 10519 10189 10188 
f 10519 10190 10189 
f 10519 10191 10190 
f 10519 10192 10191 
f 10578 10193 10192 
f 10578 10194 10193 
f 10578 10195 10194 
f 10480 10196 10195 
f 10480 10197 10196 
f 10480 10198 10197 
f 10480 10199 10198 
f 10480 10200 10199 
f 10480 10201 10200 
f 10480 10202 10201 
f 10599 10203 10202 
f 10599 10204 10203 
f 10599 10187 10204 
f 10205 10223 10221 
f 10206 10205 10221 
f 10207 10206 10221 
f 10208 10207 10221 
f 10209 10208 10221 
f 10210 10209 10221 
f 10211 10210 10221 
f 10212 10211 10221 
f 10213 10212 10221 
f 10214 10213 10221 
f 10215 10214 10221 
f 10216 10215 10221 
f 10217 10216 10221 
f 10218 10217 10221 
f 10219 10218 10221 
f 10220 10219 10221 
f 10224 10220 10221 
f 10223 10224 10221 
f 10225 10226 10222 
f 10226 10227 10222 
f 10227 10228 10222 
f 10228 10229 10222 
f 10229 10230 10222 
f 10230 10231 10222 
f 10231 10232 10222 
f 10232 10233 10222 
f 10233 10234 10222 
f 10234 10235 10222 
f 10235 10236 10222 
f 10236 10237 10222 
f 10237 10238 10222 
f 10238 10239 10222 
f 10239 10240 10222 
f 10240 10241 10222 
f 10241 10242 10222 
f 10242 10225 10222 
f 10496 10223 10205 
f 10496 10205 10206 
f 10496 10206 10207 
f 10537 10207 10208 
f 10537 10208 10209 
f 10703 10209 10210 
f 10703 10210 10211 
f 10703 10211 10212 
f 10703 10212 10213 
f 10590 10213 10214 
f 10590 10214 10215 
f 10590 10215 10216 
f 10590 10216 10217 
f 10628 10217 10218 
f 10628 10218 10219 
f 10628 10219 10220 
f 10496 10220 10224 
f 10496 10224 10223 
f 10496 10226 10225 
f 10496 10227 10226 
f 10496 10228 10227 
f 10537 10229 10228 
f 10537 10230 10229 
f 10590 10231 10230 
f 10590 10232 10231 
f 10590 10233 10232 
f 10590 10234 10233 
f 10590 10235 10234 
f 10590 10236 10235 
f 10628 10237 10236 
f 10628 10238 10237 
f 10628 10239 10238 
f 10628 10240 10239 
f 10496 10241 10240 
f 10496 10242 10241 
f 10496 10225 10242 
f 10243 10261 10259 
f 10244 10243 10259 
f 10245 10244 10259 
f 10246 10245 10259 
f 10247 10246 10259 
f 10248 10247 10259 
f 10249 10248 10259 
f 10250 10249 10259 
f 10251 10250 10259 
f 10252 10251 10259 
f 10253 10252 10259 
f 10254 10253 10259 
f 10255 10254 10259 
f 10256 10255 10259 
f 10257 10256 10259 
f 10258 10257 10259 
f 10262 10258 10259 
f 10261 10262 10259 
f 10263 10264 10260 
f 10264 10265 10260 
f 10265 10266 10260 
f 10266 10267 10260 
f 10267 10268 10260 
f 10268 10269 10260 
f 10269 10270 10260 
f 10270 10271 10260 
f 10271 10272 10260 
f 10272 10273 10260 
f 10273 10274 10260 
f 10274 10275 10260 
f 10275 10276 10260 
f 10276 10277 10260 
f 10277 10278 10260 
f 10278 10279 10260 
f 10279 10280 10260 
f 10280 10263 10260 
f 10574 10261 10243 
f 10574 10243 10244 
f 10574 10244 10245 
f 10648 10245 10246 
f 10648 10246 10247 
f 10526 10247 10248 
f 10526 10248 10249 
f 10526 10249 10250 
f 10526 10250 10251 
f 10491 10251 10252 
f 10491 10252 10253 
f 10491 10253 10254 
f 10491 10254 10255 
f 10491 10255 10256 
f 10491 10256 10257 
f 10491 10257 10258 
f 10491 10258 10262 
f 10574 10262 10261 
f 10574 10264 10263 
f 10574 10265 10264 
f 10574 10266 10265 
f 10648 10267 10266 
f 10648 10268 10267 
f 10526 10269 10268 
f 10526 10270 10269 
f 10526 10271 10270 
f 10491 10272 10271 
f 10491 10273 10272 
f 10491 10274 10273 
f 10491 10275 10274 
f 10491 10276 10275 
f 10491 10277 10276 
f 10491 10278 10277 
f 10491 10279 10278 
f 10491 10280 10279 
f 10574 10263 10280 
f 10281 10299 10297 
f 10282 10281 10297 
f 10283 10282 10297 
f 10284 10283 10297 
f 10285 10284 10297 
f 10286 10285 10297 
f 10287 10286 10297 
f 10288 10287 10297 
f 10289 10288 10297 
f 10290 10289 10297 
f 10291 10290 10297 
f 10292 10291 10297 
f 10293 10292 10297 
f 10294 10293 10297 
f 10295 10294 10297 
f 10296 10295 10297 
f 10300 10296 10297 
f 10299 10300 10297 
f 10301 10302 10298 
f 10302 10303 10298 
f 10303 10304 10298 
f 10304 10305 10298 
f 10305 10306 10298 
f 10306 10307 10298 
f 10307 10308 10298 
f 10308 10309 10298 
f 10309 10310 10298 
f 10310 10311 10298 
f 10311 10312 10298 
f 10312 10313 10298 
f 10313 10314 10298 
f 10314 10315 10298 
f 10315 10316 10298 
f 10316 10317 10298 
f 10317 10318 10298 
f 10318 10301 10298 
f 10638 10299 10281 
f 10595 10281 10282 
f 10595 10282 10283 
f 10595 10283 10284 
f 10595 10284 10285 
f 10522 10285 10286 
f 10522 10286 10287 
f 10651 10287 10288 
f 10651 10288 10289 
f 10651 10289 10290 
f 10639 10290 10291 
f 10639 10291 10292 
f 10639 10292 10293 
f 10483 10293 10294 
f 10483 10294 10295 
f 10483 10295 10296 
f 10638 10296 10300 
f 10638 10300 10299 
f 10638 10302 10301 
f 10595 10303 10302 
f 10595 10304 10303 
f 10595 10305 10304 
f 10595 10306 10305 
f 10651 10307 10306 
f 10651 10308 10307 
f 10651 10309 10308 
f 10651 10310 10309 
f 10639 10311 10310 
f 10639 10312 10311 
f 10639 10313 10312 
f 10639 10314 10313 
f 10483 10315 10314 
f 10483 10316 10315 
f 10638 10317 10316 
f 10638 10318 10317 
f 10638 10301 10318 
f 10319 10337 10335 
f 10320 10319 10335 
f 10321 10320 10335 
f 10322 10321 10335 
f 10323 10322 10335 
f 10324 10323 10335 
f 10325 10324 10335 
f 10326 10325 10335 
f 10327 10326 10335 
f 10328 10327 10335 
f 10329 10328 10335 
f 10330 10329 10335 
f 10331 10330 10335 
f 10332 10331 10335 
f 10333 10332 10335 
f 10334 10333 10335 
f 10338 10334 10335 
f 10337 10338 10335 
f 10339 10340 10336 
f 10340 10341 10336 
f 10341 10342 10336 
f 10342 10343 10336 
f 10343 10344 10336 
f 10344 10345 10336 
f 10345 10346 10336 
f 10346 10347 10336 
f 10347 10348 10336 
f 10348 10349 10336 
f 10349 10350 10336 
f 10350 10351 10336 
f 10351 10352 10336 
f 10352 10353 10336 
f 10353 10354 10336 
f 10354 10355 10336 
f 10355 10356 10336 
f 10356 10339 10336 
f 10567 10337 10319 
f 10567 10319 10320 
f 10567 10320 10321 
f 10567 10321 10322 
f 10567 10322 10323 
f 10521 10323 10324 
f 10521 10324 10325 
f 10521 10325 10326 
f 10521 10326 10327 
f 10521 10327 10328 
f 10521 10328 10329 
f 10521 10329 10330 
f 10521 10330 10331 
f 10654 10331 10332 
f 10654 10332 10333 
f 10654 10333 10334 
f 10481 10334 10338 
f 10567 10338 10337 
f 10567 10340 10339 
f 10567 10341 10340 
f 10567 10342 10341 
f 10567 10343 10342 
f 10567 10344 10343 
f 10521 10345 10344 
f 10521 10346 10345 
f 10521 10347 10346 
f 10521 10348 10347 
f 10521 10349 10348 
f 10521 10350 10349 
f 10654 10351 10350 
f 10654 10352 10351 
f 10481 10353 10352 
f 10481 10354 10353 
f 10481 10355 10354 
f 10481 10356 10355 
f 10567 10339 10356 
f 10357 10375 10373 
f 10358 10357 10373 
f 10359 10358 10373 
f 10360 10359 10373 
f 10361 10360 10373 
f 10362 10361 10373 
f 10363 10362 10373 
f 10364 10363 10373 
f 10365 10364 10373 
f 10366 10365 10373 
f 10367 10366 10373 
f 10368 10367 10373 
f 10369 10368 10373 
f 10370 10369 10373 
f 10371 10370 10373 
f 10372 10371 10373 
f 10376 10372 10373 
f 10375 10376 10373 
f 10377 10378 10374 
f 10378 10379 10374 
f 10379 10380 10374 
f 10380 10381 10374 
f 10381 10382 10374 
f 10382 10383 10374 
f 10383 10384 10374 
f 10384 10385 10374 
f 10385 10386 10374 
f 10386 10387 10374 
f 10387 10388 10374 
f 10388 10389 10374 
f 10389 10390 10374 
f 10390 10391 10374 
f 10391 10392 10374 
f 10392 10393 10374 
f 10393 10394 10374 
f 10394 10377 10374 
f 10503 10375 10357 
f 10503 10357 10358 
f 10503 10358 10359 
f 10607 10359 10360 
f 10607 10360 10361 
f 10523 10361 10362 
f 10523 10362 10363 
f 10523 10363 10364 
f 10523 10364 10365 
f 10523 10365 10366 
f 10523 10366 10367 
f 10585 10367 10368 
f 10585 10368 10369 
f 10503 10369 10370 
f 10503 10370 10371 
f 10503 10371 10372 
f 10503 10372 10376 
f 10503 10376 10375 
f 10503 10378 10377 
f 10503 10379 10378 
f 10503 10380 10379 
f 10607 10381 10380 
f 10607 10382 10381 
f 10523 10383 10382 
f 10523 10384 10383 
f 10523 10385 10384 
f 10523 10386 10385 
f 10523 10387 10386 
f 10585 10388 10387 
f 10585 10389 10388 
f 10585 10390 10389 
f 10585 10391 10390 
f 10585 10392 10391 
f 10503 10393 10392 
f 10503 10394 10393 
f 10503 10377 10394 
f 10395 10413 10411 
f 10396 10395 10411 
f 10397 10396 10411 
f 10398 10397 10411 
f 10399 10398 10411 
f 10400 10399 10411 
f 10401 10400 10411 
f 10402 10401 10411 
f 10403 10402 10411 
f 10404 10403 10411 
f 10405 10404 10411 
f 10406 10405 10411 
f 10407 10406 10411 
f 10408 10407 10411 
f 10409 10408 10411 
f 10410 10409 10411 
f 10414 10410 10411 
f 10413 10414 10411 
f 10415 10416 10412 
f 10416 10417 10412 
f 10417 10418 10412 
f 10418 10419 10412 
f 10419 10420 10412 
f 10420 10421 10412 
f 10421 10422 10412 
f 10422 10423 10412 
f 10423 10424 10412 
f 10424 10425 10412 
f 10425 10426 10412 
f 10426 10427 10412 
f 10427 10428 10412 
f 10428 10429 10412 
f 10429 10430 10412 
f 10430 10431 10412 
f 10431 10432 10412 
f 10432 10415 10412 
f 10475 10413 10395 
f 10475 10395 10396 
f 10475 10396 10397 
f 10641 10397 10398 
f 10641 10398 10399 
f 10529 10399 10400 
f 10529 10400 10401 
f 10529 10401 10402 
f 10529 10402 10403 
f 10529 10403 10404 
f 10529 10404 10405 
f 10580 10405 10406 
f 10580 10406 10407 
f 10475 10407 10408 
f 10475 10408 10409 
f 10475 10409 10410 
f 10475 10410 10414 
f 10475 10414 10413 
f 10475 10416 10415 
f 10475 10417 10416 
f 10475 10418 10417 
f 10641 10419 10418 
f 10641 10420 10419 
f 10529 10421 10420 
f 10529 10422 10421 
f 10529 10423 10422 
f 10529 10424 10423 
f 10529 10425 10424 
f 10580 10426 10425 
f 10580 10427 10426 
f 10580 10428 10427 
f 10704 10429 10428 
f 10704 10430 10429 
f 10475 10431 10430 
f 10475 10432 10431 
f 10475 10415 10432 
f 10433 10451 10449 
f 10434 10433 10449 
f 10435 10434 10449 
f 10436 10435 10449 
f 10437 10436 10449 
f 10438 10437 10449 
f 10439 10438 10449 
f 10440 10439 10449 
f 10441 10440 10449 
f 10442 10441 10449 
f 10443 10442 10449 
f 10444 10443 10449 
f 10445 10444 10449 
f 10446 10445 10449 
f 10447 10446 10449 
f 10448 10447 10449 
f 10452 10448 10449 
f 10451 10452 10449 
f 10453 10454 10450 
f 10454 10455 10450 
f 10455 10456 10450 
f 10456 10457 10450 
f 10457 10458 10450 
f 10458 10459 10450 
f 10459 10460 10450 
f 10460 10461 10450 
f 10461 10462 10450 
f 10462 10463 10450 
f 10463 10464 10450 
f 10464 10465 10450 
f 10465 10466 10450 
f 10466 10467 10450 
f 10467 10468 10450 
f 10468 10469 10450 
f 10469 10470 10450 
f 10470 10453 10450 
f 10593 10451 10433 
f 10593 10433 10434 
f 10593 10434 10435 
f 10652 10435 10436 
f 10652 10436 10437 
f 10471 10437 10438 
f 10471 10438 10439 
f 10471 10439 10440 
f 10471 10440 10441 
f 10471 10441 10442 
f 10471 10442 10443 
f 10594 10443 10444 
f 10594 10444 10445 
f 10658 10445 10446 
f 10658 10446 10447 
f 10658 10447 10448 
f 10658 10448 10452 
f 10593 10452 10451 
f 10593 10454 10453 
f 10593 10455 10454 
f 10593 10456 10455 
f 10652 10457 10456 
f 10652 10458 10457 
f 10471 10459 10458 
f 10471 10460 10459 
f 10471 10461 10460 
f 10471 10462 10461 
f 10471 10463 10462 
f 10594 10464 10463 
f 10594 10465 10464 
f 10594 10466 10465 
f 10594 10467 10466 
f 10594 10468 10467 
f 10658 10469 10468 
f 10658 10470 10469 
f 10593 10453 10470 
f 8714 10512 10490 
f 8735 10490 10512 
f 8724 10635 10565 
f 8743 10565 10635 
f 10102 10552 10583 
f 10095 10513 10487 
f 10122 10583 10552 
f 10116 10487 10513 
f 8682 10544 10514 
f 8703 10514 10544 
f 8695 10488 10514 
f 8676 10514 10488 
f 10133 10515 10494 
f 10154 10494 10515 
f 10160 10515 10626 
f 10140 10626 10515 
f 8771 10644 10569 
f 8762 10643 10479 
f 8752 10569 10644 
f 8781 10479 10643 
f 8639 10517 10640 
f 8646 10484 10589 
f 8659 10640 10517 
f 8667 10589 10484 
f 10088 10591 10472 
f 10076 10681 10592 
f 10071 10592 10472 
f 10068 10472 10591 
f 10185 10519 10599 
f 10195 10578 10480 
f 10188 10599 10519 
f 10176 10480 10578 
f 9547 10520 10576 
f 9527 10576 10520 
f 9541 10478 10602 
f 9519 10602 10478 
f 10350 10521 10654 
f 10323 10521 10567 
f 10344 10567 10521 
f 10331 10654 10521 
f 10310 10651 10639 
f 10302 10638 10595 
f 10281 10595 10638 
f 10290 10639 10651 
f 10359 10607 10503 
f 10380 10503 10607 
f 10367 10585 10523 
f 10387 10523 10585 
f 9257 10653 10627 
f 9271 10579 10524 
f 9251 10524 10579 
f 9278 10627 10653 
f 8601 10582 10564 
f 8609 8636 10525 
f 8629 10525 8636 
f 8623 10564 10582 
f 10262 10574 10491 
f 10271 10526 10491 
f 10280 10491 10574 
f 10251 10491 10526 
f 9211 10527 10605 
f 9232 10605 10527 
f 9220 9249 10598 
f 9241 10598 9249 
f 10017 10476 10575 
f 10036 10575 10476 
f 10044 10476 10646 
f 10025 10646 10476 
f 10397 10641 10475 
f 10418 10475 10641 
f 10405 10580 10529 
f 10425 10529 10580 
f 9690 9708 10530 
f 9677 10596 10621 
f 9697 10621 10596 
f 9670 10530 9708 
f 9450 10608 10531 
f 9459 10571 10701 
f 9475 10495 10571 
f 9469 10531 10608 
f 8794 10581 10532 
f 8815 10532 10581 
f 8825 10502 10603 
f 8804 10603 10502 
f 9504 10501 10675 
f 9514 10566 10501 
f 9494 10501 10566 
f 9483 10533 10501 
f 8516 10534 10493 
f 8508 10570 10629 
f 8490 10629 10570 
f 8495 10493 10534 
f 9411 10610 10535 
f 9420 10573 10702 
f 9430 10535 10610 
f 9436 9440 10573 
f 9736 9747 10689 
f 9716 10689 9747 
f 9728 10645 10692 
f 9729 10692 10645 
f 10236 10590 10628 
f 10228 10496 10537 
f 10217 10628 10590 
f 10207 10537 10496 
f 8529 10633 10614 
f 8559 10606 10538 
f 8522 10538 10606 
f 8551 10614 10633 
f 9375 10622 10539 
f 9386 10485 10588 
f 9396 10539 10622 
f 9365 10588 10485 
f 9358 10584 10666 
f 9337 10666 10584 
f 9363 10473 10634 
f 9344 10634 10473 
f 9913 10541 10684 
f 9927 10541 10497 
f 9932 10684 10541 
f 9907 10497 10541 
f 9842 10542 10611 
f 9844 10611 10542 
f 9851 10542 10663 
f 9831 10663 10542 
f 8927 10617 10662 
f 8907 10662 10617 
f 8917 10677 10506 
f 8937 10506 10677 
f 8711 10544 10488 
f 8689 10488 10544 
f 9654 10669 10671 
f 9634 10671 10669 
f 9657 10545 10637 
f 9639 10637 10545 
f 9576 10546 10630 
f 9565 10546 10474 
f 9585 10474 10546 
f 9555 10630 10546 
f 9779 10597 10694 
f 9757 10511 10597 
f 9768 10694 10547 
f 9767 10547 10694 
f 9805 10548 10615 
f 9812 10632 10507 
f 9793 10507 10632 
f 9822 10615 10548 
f 9182 10489 10624 
f 9173 10549 10678 
f 9203 10624 10489 
f 9194 10678 10549 
f 9968 10550 10505 
f 9958 10656 10616 
f 9957 10616 10656 
f 9947 10505 10550 
f 8884 10577 10619 
f 8887 10619 10577 
f 8877 10551 8904 
f 8898 8904 10551 
f 10106 10487 10552 
f 10126 10552 10487 
f 9040 10647 10553 
f 9028 10509 10623 
f 9020 10553 10647 
f 9049 10623 10509 
f 9142 9172 10661 
f 9134 10554 10682 
f 9163 10661 9172 
f 9155 10682 10554 
f 9600 9631 10698 
f 9611 10659 9631 
f 9630 9631 10659 
f 9620 10698 9631 
f 9891 10696 10650 
f 9862 10655 10500 
f 9883 10500 10655 
f 9871 10650 10696 
f 8991 10504 10557 
f 9012 10557 10504 
f 9001 10609 10679 
f 9018 10679 10609 
f 9084 10558 10499 
f 9058 10558 10686 
f 9079 10686 10558 
f 9064 10499 10558 
f 9297 10612 10482 
f 9291 10690 10687 
f 9318 10482 10612 
f 9310 10687 10690 
f 8840 10631 10691 
f 8862 10691 10631 
f 8831 8865 10673 
f 8851 10673 8865 
f 10149 10561 10494 
f 10147 10494 10561 
f 8962 10562 10693 
f 8968 10562 8981 
f 8946 8981 10562 
f 8980 10693 10562 
f 9998 10699 10014 
f 9994 10014 10563 
f 9990 10563 10613 
f 10009 10613 10563 
f 8618 10620 10564 
f 8617 10564 10620 
f 8741 10512 10565 
f 8720 10565 10512 
f 9488 10566 10675 
f 9508 10675 10566 
f 10338 10567 10481 
f 10356 10481 10567 
f 8651 10568 10484 
f 8673 10484 10568 
f 8777 10569 10479 
f 8756 10479 10569 
f 8502 10570 10705 
f 8518 10493 10570 
f 9444 10664 10571 
f 9463 10571 10665 
f 9103 10510 10572 
f 9096 10697 10676 
f 9123 10572 10510 
f 9117 10676 10697 
f 9405 10667 10573 
f 9424 10573 10668 
f 10266 10574 10648 
f 10245 10648 10574 
f 10029 10575 10528 
f 10050 10528 10575 
f 9532 10478 10576 
f 9552 10576 10478 
f 8890 10577 10657 
f 8870 10657 10577 
f 10192 10519 10578 
f 10171 10578 10519 
f 9283 10477 10579 
f 9263 10579 10477 
f 10407 10475 10580 
f 10430 10704 10475 
f 8800 10502 10581 
f 8819 10581 10502 
f 8604 10525 10582 
f 8625 10582 10525 
f 10118 10513 10625 
f 10097 10625 10513 
f 9333 10584 10540 
f 9354 10700 10584 
f 10369 10503 10585 
f 10392 10585 10503 
f 9666 10486 10586 
f 9647 10586 10486 
f 9093 10499 10587 
f 9071 10587 10499 
f 9371 10539 10649 
f 9392 10649 10539 
f 8644 10589 10517 
f 8665 10517 10589 
f 10230 10537 10590 
f 10213 10590 10703 
f 10082 10518 10591 
f 10062 10591 10518 
f 10080 10592 10518 
f 10058 10518 10592 
f 10463 10471 10594 
f 10435 10652 10593 
f 10456 10593 10652 
f 10443 10594 10471 
f 10470 10658 10593 
f 10452 10593 10658 
f 10285 10522 10595 
f 10306 10595 10651 
f 9703 10672 9708 
f 9683 9708 10672 
f 9751 10680 10547 
f 9771 10547 10680 
f 9237 10527 10598 
f 9215 10598 10527 
f 10202 10480 10599 
f 10180 10599 10480 
f 8571 10492 10600 
f 8563 10601 10660 
f 8593 10600 10492 
f 8582 10660 10601 
f 8587 10601 10600 
f 8566 10600 10601 
f 9521 10520 10602 
f 9542 10602 10520 
f 8809 10603 10532 
f 8789 10532 10603 
f 8974 8981 10604 
f 8954 10604 8981 
f 9247 10674 10605 
f 9226 10605 10674 
f 8556 10498 10606 
f 8535 10606 10498 
f 10382 10607 10523 
f 10361 10523 10607 
f 9473 10608 10495 
f 9452 10495 10608 
f 9005 10609 10557 
f 8985 10557 10609 
f 9413 9440 10610 
f 9434 10610 9440 
f 9857 10508 10611 
f 9838 10611 10508 
f 9320 10612 10559 
f 9301 10559 10612 
f 9981 10613 10014 
f 10002 10014 10613 
f 8525 10614 10538 
f 8547 10538 10614 
f 9799 10615 10507 
f 9819 10507 10615 
f 9962 10685 10550 
f 9941 10550 10616 
f 8925 10543 10617 
f 8924 10617 10543 
f 9740 10618 10692 
f 9722 10692 10618 
f 8881 10619 10670 
f 8902 10670 10619 
f 8631 8636 10620 
f 8612 10620 8636 
f 9695 10530 10621 
f 9672 10621 10530 
f 9377 10485 10622 
f 9398 10622 10485 
f 9023 10695 10553 
f 9043 10553 10695 
f 9199 10549 10624 
f 9178 10624 10549 
f 10100 10583 10625 
f 10120 10625 10583 
f 10142 10561 10626 
f 10163 10626 10561 
f 9253 10627 10524 
f 9276 10524 10627 
f 10220 10496 10628 
f 10240 10628 10496 
f 8512 10629 10534 
f 8493 10534 10629 
f 9559 10474 10630 
f 9580 10630 10474 
f 8845 10560 10631 
f 8864 10631 10560 
f 9809 10548 10632 
f 9789 10632 10548 
f 8531 10498 10633 
f 8553 10633 10498 
f 9350 10634 10540 
f 9329 10540 10634 
f 8749 10635 10636 
f 8727 10636 10635 
f 8732 10490 10636 
f 8733 10636 10490 
f 9663 10637 10486 
f 9643 10486 10637 
f 10316 10483 10638 
f 10296 10638 10483 
f 10293 10483 10639 
f 10314 10639 10483 
f 8637 10640 10568 
f 8657 10568 10640 
f 10399 10529 10641 
f 10420 10641 10529 
f 10694 10597 10511 
f 9761 10694 10511 
f 8764 10516 10643 
f 8785 10643 10516 
f 8766 10644 10516 
f 8787 10516 10644 
f 9712 9747 10645 
f 9732 10645 9747 
f 10027 10528 10646 
f 10046 10646 10528 
f 9034 10647 10509 
f 9055 10509 10647 
f 10247 10526 10648 
f 10268 10648 10526 
f 9367 10649 10588 
f 9388 10588 10649 
f 9897 10650 10500 
f 9875 10500 10650 
f 10651 10595 10522 
f 10287 10651 10522 
f 10458 10652 10471 
f 10437 10471 10652 
f 9261 10477 10653 
f 9281 10653 10477 
f 10334 10481 10654 
f 10352 10654 10481 
f 9865 10556 10655 
f 9885 10655 10556 
f 9973 10505 10656 
f 9951 10656 10505 
f 8894 10657 8904 
f 8872 8904 10657 
f 10445 10658 10594 
f 10468 10594 10658 
f 9593 10555 10659 
f 9614 10659 10555 
f 8597 10492 10660 
f 8560 10660 10492 
f 9159 10554 10661 
f 9137 10661 10554 
f 8929 10662 10506 
f 8909 10506 10662 
f 9853 10663 10508 
f 9833 10508 10663 
f 10571 10664 10531 
f 9447 10531 10664 
f 9465 10665 10531 
f 10531 10665 10571 
f 9339 10473 10666 
f 9360 10666 10473 
f 10573 10667 10535 
f 9408 10535 10667 
f 10535 10668 10573 
f 9426 10668 10535 
f 9632 10669 10586 
f 9653 10586 10669 
f 8879 10670 10551 
f 8900 10551 10670 
f 9656 10671 10545 
f 9637 10545 10671 
f 9701 10596 10672 
f 9681 10672 10596 
f 8848 10560 10673 
f 8829 10673 10560 
f 9224 10674 9249 
f 9244 9249 10674 
f 9485 10675 10533 
f 10675 10501 10533 
f 9108 10676 10510 
f 9131 10510 10676 
f 8919 10543 10677 
f 8939 10677 10543 
f 9208 10489 10678 
f 9186 10678 10489 
f 9014 10504 10679 
f 8995 10679 10504 
f 9755 10597 10680 
f 9774 10680 10597 
f 10090 10472 10681 
f 10472 10592 10681 
f 9153 10682 10683 
f 9169 10683 10682 
f 9165 9172 10683 
f 9147 10683 9172 
f 9909 10684 10497 
f 9930 10497 10684 
s 2
f 10550 10685 10616 
s 1
f 9961 10616 10685 
f 9078 10587 10686 
f 9077 10686 10587 
f 9324 10559 10688 
f 9303 10688 10559 
f 9308 10688 10687 
f 9288 10687 10688 
f 9717 10618 10689 
f 9738 10689 10618 
f 9312 10690 10482 
f 9294 10482 10690 
f 8858 8865 10691 
f 8835 10691 8865 
f 9742 10692 10536 
f 9745 10536 10692 
f 8977 10604 10693 
f 8957 10693 10604 
f 9781 10694 10642 
f 9784 10642 10694 
f 9046 10695 10623 
f 9027 10623 10695 
f 9889 10556 10696 
f 9869 10696 10556 
f 9099 10572 10697 
f 9119 10697 10572 
f 9617 10555 10698 
f 9598 10698 10555 
f 10012 10563 10699 
f 10563 10014 10699 
f 10540 10584 10700 
f 9352 10540 10700 
f 10571 10495 10701 
f 9454 10701 10495 
f 9415 10702 9440 
f 10573 9440 10702 
f 10209 10703 10537 
f 10590 10537 10703 
f 10428 10580 10704 
f 10704 10580 10475 
f 8497 10705 10493 
f 10570 10493 10705 
f 10749 10748 10730 
f 10747 10722 10768 
f 10776 10742 10734 
f 10766 10744 10728 
f 10761 10712 10779 
f 10781 10738 10749 
f 10735 10710 10712 
f 10745 10785 10753 
f 10732 10763 10742 
f 10713 10724 10732 
f 10740 10759 10770 
f 10774 10756 10757 
f 10734 10760 10777 
f 10755 10729 10752 
f 10718 10725 10751 
f 10745 10772 10758 
f 10784 10740 10736 
f 10730 10750 10743 
f 10778 10764 10718 
f 10744 10770 10759 
f 10761 10746 10771 
f 10752 10783 10715 
f 10733 10721 10780 
f 10751 10738 10718 
f 10768 10722 10741 
f 10731 10755 10720 
f 10764 10778 10723 
f 10783 10752 10765 
f 10785 10754 10753 
f 10712 10724 10735 
f 10751 10725 10716 
f 10739 10764 10747 
f 10784 10714 10767 
f 10749 10757 10756 
f 10758 10772 10727 
f 10777 10776 10734 
f 10754 10728 10708 
f 10728 10744 10708 
f 10722 10747 10782 
f 10783 10765 10723 
f 10738 10781 10718 
f 10743 10762 10730 
f 10779 10712 10710 
f 10722 10779 10741 
f 10712 10732 10724 
f 10712 10761 10771 
f 10744 10759 10711 
f 10780 10711 10733 
f 10706 10769 10715 
f 10734 10742 10763 
f 10708 10735 10754 
f 10744 10766 10770 
f 10717 10758 10736 
f 10750 10730 10748 
f 10777 10709 10776 
f 10721 10738 10751 
f 10757 10721 10774 
f 10741 10716 10768 
f 10725 10739 10716 
f 10714 10726 10767 
f 10759 10740 10726 
f 10710 10741 10779 
f 10727 10713 10742 
f 10742 10713 10732 
f 10743 10750 10760 
f 10706 10723 10778 
f 10711 10719 10744 
f 10719 10708 10744 
f 10758 10717 10745 
f 10785 10745 10766 
f 10715 10746 10773 
f 10746 10715 10769 
f 10747 10723 10782 
f 10723 10747 10764 
f 10748 10709 10750 
f 10714 10784 10748 
f 10714 10748 10749 
f 10775 10714 10749 
f 10750 10737 10760 
f 10750 10709 10737 
f 10716 10707 10751 
f 10721 10751 10707 
f 10715 10773 10752 
f 10752 10720 10755 
f 10724 10713 10753 
f 10753 10713 10745 
f 10724 10754 10735 
f 10753 10754 10724 
f 10722 10782 10755 
f 10755 10731 10722 
f 10759 10726 10756 
f 10756 10775 10749 
f 10757 10749 10738 
f 10721 10757 10738 
f 10736 10758 10784 
f 10709 10758 10727 
f 10756 10733 10759 
f 10759 10733 10711 
f 10769 10706 10760 
f 10743 10760 10706 
f 10779 10720 10761 
f 10761 10773 10746 
f 10762 10718 10781 
f 10718 10762 10778 
f 10771 10746 10763 
f 10734 10763 10746 
f 10725 10764 10739 
f 10725 10718 10764 
f 10729 10765 10752 
f 10765 10782 10723 
f 10717 10766 10745 
f 10766 10717 10770 
f 10740 10784 10767 
f 10740 10767 10726 
f 10739 10768 10716 
f 10747 10768 10739 
f 10746 10769 10734 
f 10760 10734 10769 
f 10736 10770 10717 
f 10770 10736 10740 
f 10763 10732 10771 
f 10712 10771 10732 
f 10745 10713 10772 
f 10727 10772 10713 
f 10720 10752 10773 
f 10720 10773 10761 
f 10756 10774 10733 
f 10733 10774 10721 
f 10775 10756 10726 
f 10714 10775 10726 
f 10742 10776 10727 
f 10727 10776 10709 
f 10760 10737 10777 
f 10709 10777 10737 
f 10706 10778 10743 
f 10743 10778 10762 
f 10720 10779 10731 
f 10731 10779 10722 
f 10707 10780 10721 
f 10730 10781 10749 
f 10730 10762 10781 
f 10729 10755 10782 
f 10765 10729 10782 
f 10706 10783 10723 
f 10706 10715 10783 
f 10784 10709 10748 
f 10758 10709 10784 
f 10785 10728 10754 
f 10728 10785 10766 
f 10842 10805 10786 
f 10821 10850 10803 
f 10834 10813 10858 
f 10799 10816 10836 
f 10790 10836 10816 
f 10818 10796 10803 
f 10815 10833 10841 
f 10792 10808 10833 
f 10845 10830 10820 
f 10807 10819 10839 
f 10809 10810 10825 
f 10827 10806 10853 
f 10851 10837 10798 
f 10829 10859 10786 
f 10817 10821 10814 
f 10841 10844 10815 
f 10832 10809 10860 
f 10811 10865 10857 
f 10806 10786 10853 
f 10799 10853 10794 
f 10849 10810 10807 
f 10814 10820 10817 
f 10837 10822 10831 
f 10828 10791 10801 
f 10841 10801 10855 
f 10819 10843 10863 
f 10846 10852 10858 
f 10837 10851 10822 
f 10803 10826 10818 
f 10788 10793 10804 
f 10790 10804 10793 
f 10848 10815 10844 
f 10840 10786 10805 
f 10862 10806 10827 
f 10823 10865 10806 
f 10839 10799 10864 
f 10864 10799 10847 
f 10801 10808 10828 
f 10820 10814 10808 
f 10824 10797 10834 
f 10797 10813 10834 
f 10863 10802 10819 
f 10858 10863 10834 
f 10835 10811 10795 
f 10831 10861 10811 
f 10864 10847 10812 
f 10832 10860 10794 
f 10858 10813 10846 
f 10855 10801 10791 
f 10808 10814 10787 
f 10854 10787 10814 
f 10842 10786 10830 
f 10833 10815 10792 
f 10790 10856 10804 
f 10799 10839 10816 
f 10838 10861 10831 
f 10821 10817 10850 
f 10793 10818 10790 
f 10819 10858 10852 
f 10804 10819 10788 
f 10829 10838 10820 
f 10820 10859 10829 
f 10814 10821 10854 
f 10821 10803 10796 
f 10822 10803 10850 
f 10803 10822 10826 
f 10837 10823 10798 
f 10806 10862 10823 
f 10844 10840 10848 
f 10809 10789 10824 
f 10812 10847 10825 
f 10825 10810 10849 
f 10790 10818 10826 
f 10798 10790 10851 
f 10827 10799 10836 
f 10853 10799 10827 
f 10787 10828 10808 
f 10786 10795 10829 
f 10838 10829 10861 
f 10830 10845 10792 
f 10792 10842 10830 
f 10800 10838 10831 
f 10800 10831 10822 
f 10809 10832 10789 
f 10840 10853 10786 
f 10801 10833 10808 
f 10801 10841 10833 
f 10824 10834 10809 
f 10809 10834 10810 
f 10865 10786 10806 
f 10795 10786 10835 
f 10790 10798 10836 
f 10862 10798 10823 
f 10831 10811 10837 
f 10811 10857 10837 
f 10817 10820 10838 
f 10838 10800 10817 
f 10839 10804 10856 
f 10804 10839 10819 
f 10824 10789 10840 
f 10853 10840 10789 
f 10841 10855 10797 
f 10797 10824 10841 
f 10842 10792 10815 
f 10815 10848 10842 
f 10819 10807 10843 
f 10843 10807 10810 
f 10840 10844 10824 
f 10844 10841 10824 
f 10808 10845 10820 
f 10808 10792 10845 
f 10791 10846 10813 
f 10794 10847 10799 
f 10847 10794 10860 
f 10805 10848 10840 
f 10842 10848 10805 
f 10849 10812 10825 
f 10864 10812 10849 
f 10800 10850 10817 
f 10800 10822 10850 
f 10826 10851 10790 
f 10851 10826 10822 
f 10852 10788 10819 
f 10832 10794 10853 
f 10789 10832 10853 
f 10796 10854 10821 
f 10813 10855 10791 
f 10797 10855 10813 
f 10816 10856 10790 
f 10856 10816 10839 
f 10837 10857 10823 
f 10865 10823 10857 
f 10819 10802 10858 
f 10863 10858 10802 
f 10859 10820 10830 
f 10830 10786 10859 
f 10825 10860 10809 
f 10860 10825 10847 
f 10861 10795 10811 
f 10795 10861 10829 
f 10827 10836 10862 
f 10862 10836 10798 
f 10810 10863 10843 
f 10863 10810 10834 
f 10864 10807 10839 
f 10807 10864 10849 
f 10786 10865 10835 
f 10811 10835 10865 
f 11318 11582 11105 
f 11263 11133 11056 
f 11090 11166 11376 
f 11025 11328 11302 
f 11245 11081 11444 
f 11007 11551 11256 
f 11110 11240 11284 
f 11070 11227 11103 
f 11128 10978 11570 
f 11180 11554 11048 
f 11425 11037 11232 
f 11177 11357 10996 
f 11592 10981 11085 
f 11166 11296 11376 
f 11344 11140 11239 
f 10984 11545 11428 
f 11169 11212 11151 
f 11196 11010 11299 
f 11523 11196 11299 
f 11291 11520 11197 
f 11585 11409 11351 
f 11149 11419 11476 
f 11322 11594 11203 
f 11064 11036 11061 
f 11102 10988 11538 
f 11027 11336 11453 
f 11111 11047 10895 
f 11246 11464 11122 
f 11221 11259 11129 
f 11453 11336 11435 
f 11409 11585 11418 
f 11432 11041 11073 
f 11364 11038 11004 
f 11325 11275 11125 
f 11543 11363 11335 
f 11461 11290 11131 
f 11211 10921 11066 
f 11223 11434 11046 
f 11212 11220 11045 
f 11457 11252 11255 
f 11125 11119 11343 
f 11495 11199 11579 
f 11163 11354 11114 
f 11034 10997 11311 
f 11408 11158 11184 
f 11148 11553 10930 
f 11458 11525 11403 
f 11270 11338 11175 
f 11262 11088 11519 
f 11406 11152 11362 
f 11132 11070 11319 
f 11486 11352 11211 
f 10895 11526 11372 
f 10895 11047 11526 
f 11034 11194 10868 
f 11458 11511 11154 
f 11243 11061 10874 
f 10938 11152 11385 
f 11303 11202 11227 
f 11030 11193 11371 
f 11427 11093 11230 
f 11285 11145 11494 
f 11483 11114 11042 
f 11134 11117 11229 
f 11257 11073 11041 
f 11023 10954 11314 
f 11205 11219 11340 
f 11495 11573 10987 
f 10926 11155 10907 
f 11493 11384 11000 
f 11476 11345 11254 
f 10869 11057 11346 
f 11311 11552 11548 
f 11331 11083 11229 
f 11475 11521 11272 
f 11572 11439 10967 
f 11561 11317 11208 
f 11229 11127 11134 
f 11426 11049 11591 
f 10958 11393 11468 
f 10877 11509 11123 
f 11319 11302 11132 
f 11019 11414 11084 
f 11504 11297 11420 
f 11563 11415 11584 
f 11249 11359 11017 
f 11474 11428 11130 
f 11247 11032 11505 
f 11515 10873 10957 
f 11062 11106 11293 
f 11514 11406 11362 
f 11471 11094 11336 
f 11077 11189 11000 
f 11218 11538 10988 
f 11082 11578 11420 
f 11001 11115 11005 
f 11134 11127 11356 
f 11490 11002 11185 
f 11353 11413 11050 
f 11459 11378 11257 
f 11521 11115 11272 
f 11340 11506 11205 
f 11447 11401 11072 
f 11123 11126 11482 
f 10909 10921 10970 
f 11205 11404 11219 
f 11213 11388 10981 
f 11228 11349 11138 
f 10981 11201 11213 
f 11153 11286 11575 
f 11252 11457 11480 
f 11195 11308 10925 
f 11549 11500 10972 
f 10926 11479 11155 
f 11116 11011 11315 
f 10986 10869 11346 
f 11462 11392 10905 
f 11178 11176 11387 
f 11436 11429 11194 
f 11380 11568 11327 
f 11309 11063 11327 
f 11458 11321 11511 
f 11094 11251 11437 
f 11567 11341 11142 
f 10995 11164 11438 
f 11017 11095 11249 
f 11341 10931 11074 
f 11363 10892 11519 
f 11330 11424 10895 
f 11122 11464 10903 
f 11290 11461 11129 
f 11018 11157 11472 
f 11054 11426 11446 
f 10936 11261 11150 
f 11294 11242 11310 
f 11050 11042 11353 
f 11380 11327 11373 
f 11349 11228 10871 
f 11174 10871 11535 
f 11420 11578 11504 
f 11532 11247 11226 
f 10895 10952 11330 
f 11330 10940 11401 
f 11299 11268 10980 
f 10914 11250 11347 
f 11476 11254 11210 
f 10977 11412 11102 
f 11057 11470 11332 
f 11171 11060 11141 
f 10944 11091 11246 
f 11557 11405 11496 
f 11502 11276 11303 
f 11141 11440 11171 
f 11487 11381 11590 
f 11038 11075 11275 
f 11145 11285 11439 
f 11317 11046 11434 
f 10903 10934 11236 
f 10948 10907 11155 
f 11406 11514 11273 
f 10950 11529 11265 
f 11529 10950 11199 
f 11199 11135 11579 
f 11373 10950 11265 
f 11081 11537 11444 
f 10940 11330 10952 
f 11174 11016 10953 
f 11183 11284 11240 
f 11454 11364 11004 
f 11314 10954 11534 
f 11370 11417 10955 
f 11368 11370 11059 
f 11345 11476 11419 
f 11030 11371 11209 
f 11046 11391 11223 
f 10958 11482 11126 
f 11287 11164 10995 
f 10959 10995 11356 
f 11103 11235 11064 
f 11403 11326 11173 
f 11108 11410 11320 
f 11067 11049 11382 
f 11560 10936 11542 
f 11020 11092 11564 
f 11587 11536 11090 
f 11357 11520 10996 
f 11438 11269 10995 
f 11191 11567 11142 
f 11220 11212 11169 
f 11225 10966 11402 
f 10967 11354 11163 
f 10929 11544 11063 
f 11354 10967 11039 
f 11063 11309 10929 
f 11436 11194 11367 
f 11105 11307 11318 
f 11496 11405 10887 
f 11496 10887 11192 
f 11230 10971 11427 
f 11334 11099 10971 
f 11112 11541 11190 
f 11500 11190 11541 
f 11158 10877 11184 
f 11267 11461 11131 
f 11275 11325 11038 
f 11480 11348 11252 
f 11143 11018 11472 
f 11222 11054 10935 
f 11342 11449 10976 
f 11086 11096 10984 
f 10942 10878 10977 
f 11254 11345 11248 
f 11526 11047 11217 
f 11097 11176 11178 
f 11079 11121 11156 
f 11575 11527 11153 
f 11451 11508 11558 
f 11408 11558 11158 
f 11202 11228 11588 
f 11103 10923 11530 
f 11204 11130 11381 
f 11487 11204 11381 
f 10894 11516 11507 
f 11283 11171 11440 
f 10892 11363 11543 
f 11292 11188 11539 
f 11518 11159 11478 
f 11009 11475 11566 
f 10986 11548 11552 
f 11052 11318 11392 
f 10987 10883 11329 
f 11483 10987 11573 
f 11280 11412 11351 
f 11161 11396 11280 
f 11512 11212 11045 
f 11512 11395 10989 
f 11232 10990 11425 
f 11525 11193 11030 
f 10978 11455 11139 
f 11031 10875 11443 
f 11315 11011 11120 
f 11236 11315 11120 
f 11195 10925 11497 
f 11263 11385 11497 
f 11051 11225 11402 
f 11470 10994 11332 
f 11134 11356 10995 
f 11142 11341 11300 
f 11516 11302 11084 
f 10904 10996 10910 
f 11034 11216 10997 
f 11240 11048 11554 
f 11146 11264 11215 
f 11492 11104 11083 
f 11058 11210 11000 
f 10996 10904 11177 
f 11157 11127 10886 
f 11465 10908 10961 
f 11127 11157 11067 
f 11001 11397 10908 
f 11118 11271 11353 
f 11050 11413 11002 
f 11437 11003 11094 
f 11175 11338 11166 
f 11203 10902 11322 
f 11322 11534 11454 
f 10908 11397 11356 
f 10917 11005 11547 
f 11289 11108 11456 
f 11301 11108 11179 
f 11133 11555 11201 
f 11467 11407 11007 
f 11189 11077 11234 
f 11092 11008 11564 
f 10901 11491 11282 
f 11580 10901 11117 
f 11521 11475 11009 
f 10901 11059 11117 
f 10980 11268 11123 
f 11531 11066 10921 
f 11423 11182 11400 
f 11114 11483 11573 
f 11434 11503 11317 
f 10889 11122 10903 
f 11148 10911 11563 
f 10911 11242 11294 
f 11369 11014 11279 
f 11448 11265 11529 
f 11559 11329 11136 
f 11329 11559 10987 
f 11016 11445 10953 
f 11250 11445 11293 
f 10931 11257 11378 
f 11164 11287 11374 
f 11186 10935 11054 
f 11143 11253 11018 
f 11019 11291 11197 
f 11513 11346 11057 
f 11092 11020 11167 
f 11150 11375 11218 
f 11335 11088 11160 
f 10924 11286 11153 
f 11101 11195 11306 
f 11096 11539 11188 
f 11256 11467 11007 
f 11032 11162 11505 
f 10913 11024 11399 
f 11337 11149 11476 
f 11025 11350 10960 
f 11122 11524 11246 
f 11223 11192 10887 
f 11437 11251 11144 
f 10930 11027 11148 
f 11258 11028 11224 
f 11485 11313 11244 
f 11403 11419 11326 
f 11209 10899 11030 
f 11187 11031 11537 
f 11098 10875 11031 
f 11501 11162 11032 
f 10971 11230 11334 
f 11485 10929 11309 
f 11224 11089 11258 
f 11485 11089 11550 
f 11089 11485 11244 
f 11311 10927 11241 
f 11307 11241 11318 
f 11274 11556 11479 
f 11400 11182 11035 
f 11036 10922 10866 
f 11583 11036 11592 
f 11107 11584 11415 
f 11247 11232 11037 
f 11278 11004 11038 
f 11348 11038 11325 
f 11323 11544 10929 
f 10968 11383 11214 
f 11323 11288 11544 
f 11039 11237 11430 
f 11255 10919 11457 
f 11041 11459 11257 
f 11380 11550 11089 
f 11354 11042 11114 
f 11404 11314 10902 
f 11555 11133 11407 
f 11044 10932 11087 
f 11295 11586 10965 
f 11360 11408 11184 
f 11045 10973 11512 
f 11379 11391 11561 
f 11208 11379 11561 
f 11541 11112 11047 
f 11217 11047 10881 
f 10997 11216 11048 
f 11465 11001 10908 
f 11001 11049 10985 
f 11042 11050 10883 
f 11265 11490 11373 
f 10994 11277 11051 
f 11109 11110 11324 
f 10905 11245 11462 
f 11128 11105 11582 
f 11553 11148 11053 
f 11416 10990 11154 
f 11472 11157 11478 
f 11054 11478 11159 
f 10981 11055 11422 
f 11055 11530 11588 
f 11056 11133 11201 
f 11422 11055 11138 
f 11332 10894 11057 
f 10894 11507 11414 
f 11058 11000 11452 
f 10904 11337 11058 
f 11593 11282 11266 
f 11282 11593 10901 
f 11496 11060 11557 
f 11421 10943 11060 
f 11390 11061 11036 
f 11072 11270 10866 
f 11293 11540 11062 
f 11303 11276 11202 
f 11285 10967 11439 
f 11430 11237 11063 
f 11350 11103 11064 
f 11243 11321 10897 
f 11375 11150 11261 
f 11065 10867 10963 
f 11523 10945 11196 
f 11352 11486 11522 
f 11233 10962 11067 
f 11463 11006 11179 
f 11163 11572 10967 
f 11259 11221 11393 
f 11200 11581 11429 
f 11176 10896 11387 
f 11303 10876 11502 
f 11070 11132 10876 
f 10975 11253 11389 
f 11054 11222 11533 
f 11296 11166 11338 
f 11072 11401 10940 
f 11359 11249 10912 
f 11160 11359 11335 
f 10901 11580 11074 
f 11181 11198 11074 
f 11565 10885 11119 
f 11364 11454 11344 
f 11076 10925 11308 
f 11500 11133 11263 
f 11339 11538 11489 
f 11234 11077 10915 
f 11577 11214 11383 
f 11078 11147 11288 
f 11203 11340 11219 
f 10920 11121 11079 
f 11172 11386 11254 
f 10878 11080 10977 
f 11513 11081 10905 
f 11245 10905 11081 
f 11280 11082 11161 
f 11418 11281 11409 
f 11361 11083 11331 
f 11229 11484 11146 
f 11264 11146 11484 
f 11083 11361 11492 
f 11291 11084 10910 
f 11291 11019 11084 
f 11036 11583 10922 
f 10879 11365 11085 
f 11086 10976 11449 
f 11474 10976 11428 
f 11300 10995 11269 
f 10888 10995 11300 
f 11088 11262 11021 
f 11160 11088 11021 
f 11568 11089 11033 
f 11568 11380 11089 
f 11304 11587 11090 
f 11197 10964 11090 
f 10934 10903 11091 
f 11431 10934 11091 
f 11168 11453 11435 
f 11168 11435 11477 
f 10957 11230 11093 
f 10899 10957 11345 
f 11003 11587 11094 
f 11469 11189 11234 
f 10995 10959 11287 
f 11590 10982 11095 
f 11467 11449 11342 
f 11449 11467 11256 
f 10978 11411 11398 
f 11411 11217 10881 
f 11372 10952 10895 
f 11031 11187 11098 
f 11578 11281 11418 
f 11532 11226 11099 
f 11100 10951 11448 
f 11574 10951 11100 
f 10893 11101 11273 
f 11101 10893 11498 
f 11000 11210 11493 
f 10942 10977 11102 
f 11350 11025 11103 
f 11302 11319 11025 
f 11264 11484 11104 
f 11473 11215 11355 
f 11398 11377 10978 
f 10928 11377 11398 
f 11062 10941 11106 
f 10890 11106 11451 
f 11584 11107 10884 
f 10884 11107 11226 
f 11108 11289 11233 
f 11108 10961 11410 
f 11109 11324 10869 
f 11109 10869 10986 
f 11347 11488 11110 
f 11284 11347 11110 
f 10972 11551 11549 
f 11047 11111 10972 
f 11112 10881 11047 
f 11113 11059 11266 
f 11417 11370 11368 
f 11423 11400 11114 
f 10926 11163 11528 
f 11198 11181 11115 
f 11255 11115 10919 
f 11182 10882 11035 
f 10992 11274 11116 
f 11331 11229 11117 
f 11117 10955 11331 
f 11460 11118 11298 
f 11118 11185 11002 
f 11125 10946 11119 
f 11119 10946 11565 
f 11120 10903 11236 
f 11120 11316 10903 
f 11330 11121 11262 
f 11527 10979 11121 
f 11012 11026 11122 
f 11122 10889 11012 
f 10921 11571 11010 
f 11126 11571 10921 
f 11461 11267 10877 
f 11129 11124 11221 
f 11547 11125 10917 
f 11125 11343 11207 
f 11391 11379 11126 
f 10921 10909 11126 
f 11356 11127 10908 
f 10908 11410 10961 
f 10875 11128 10991 
f 10991 11128 11052 
f 10948 11366 11259 
f 11236 10934 11366 
f 11545 11130 11428 
f 11130 10912 11249 
f 11431 10944 11305 
f 11091 10944 11431 
f 11283 10876 11132 
f 11516 11132 11302 
f 11133 11007 11407 
f 11500 11549 11133 
f 11134 10995 10888 
f 11117 11134 11580 
f 11327 11063 11494 
f 11199 10950 11135 
f 11279 11333 11369 
f 11136 11206 11559 
f 11028 11394 11137 
f 11258 11089 11244 
f 11263 11056 11385 
f 11138 11056 11422 
f 11546 11098 11139 
f 11455 11546 11139 
f 11534 10954 11140 
f 11140 11342 11239 
f 11522 11066 10945 
f 11502 11440 11522 
f 11191 11142 11295 
f 11450 11087 10932 
f 11071 11533 11222 
f 11143 11389 11253 
f 11466 11312 11511 
f 10930 11144 11251 
f 10947 11503 11145 
f 11145 11579 11135 
f 11566 11159 11518 
f 11518 10998 10906 
f 11383 10968 11147 
f 11063 11544 11430 
f 11027 11013 11148 
f 11013 11310 11242 
f 11326 11419 11149 
f 10960 11149 11337 
f 11396 11412 11280 
f 11150 11218 10988 
f 11405 11557 11151 
f 11433 11151 11231 
f 11152 11101 11306 
f 10993 11385 11152 
f 10924 11153 11576 
f 11038 10870 11278 
f 11154 11511 11312 
f 11154 10891 11416 
f 11155 11315 11236 
f 11155 11479 11556 
f 10866 11447 11072 
f 11156 11506 11079 
f 11049 11157 11591 
f 11049 11067 11157 
f 10941 10980 11508 
f 10877 11158 11509 
f 11426 11054 11159 
f 11272 10985 11159 
f 10924 11160 11021 
f 11432 11480 11041 
f 10936 11161 11082 
f 11161 10936 11150 
f 11515 11209 11371 
f 11024 11505 11162 
f 10926 10907 11163 
f 10907 11068 11163 
f 11164 11374 10931 
f 11164 11567 11191 
f 11413 11353 11271 
f 11298 11165 11460 
f 11166 11090 11536 
f 11175 11166 11536 
f 11167 11489 11375 
f 11065 11168 11477 
f 10963 11168 11065 
f 11542 11294 11310 
f 11402 11220 11169 
f 11433 11332 10994 
f 11383 11040 11577 
f 11288 11214 11170 
f 10894 11332 11421 
f 11171 11283 10894 
f 11351 11427 10971 
f 11080 11172 10977 
f 10897 11173 11326 
f 11321 11173 10897 
f 10953 11362 11174 
f 11385 11349 10938 
f 10874 11437 11144 
f 11175 10874 11270 
f 11176 11411 10881 
f 11452 11000 11177 
f 11177 11000 11189 
f 11367 10969 11097 
f 11398 11367 11194 
f 11320 11179 11108 
f 10962 11179 11067 
f 10949 11514 11180 
f 11181 10919 11115 
f 11459 11181 11378 
f 10882 11182 11116 
f 11423 11116 11182 
f 11554 11180 11514 
f 11183 10953 11445 
f 11184 10973 11360 
f 11184 10877 11267 
f 11118 10937 11185 
f 11373 10937 11380 
f 11446 11018 11253 
f 11426 11591 11446 
f 11372 11187 11441 
f 10940 11441 11187 
f 11545 10984 11543 
f 11188 10984 11096 
f 11189 11357 11177 
f 11469 10880 11189 
f 11190 11500 10872 
f 11191 11438 11164 
f 10965 11191 11295 
f 11192 11223 11391 
f 10970 11192 10909 
f 11193 11525 10990 
f 10990 11232 11399 
f 11442 11034 10928 
f 11442 10928 11398 
f 11358 11498 10893 
f 11308 11195 11022 
f 11531 11196 11066 
f 11066 11196 10945 
f 11197 11081 11513 
f 11090 11376 11197 
f 10901 11074 11198 
f 11198 11491 10901 
f 11199 11495 11574 
f 11100 11199 11574 
f 11581 11200 11069 
f 11436 11178 11069 
f 11201 10898 11056 
f 10981 10898 11201 
f 11228 11202 10871 
f 11540 11202 11276 
f 11203 10920 11340 
f 11527 11203 11594 
f 11204 11119 10885 
f 11474 10885 11239 
f 11404 11205 11365 
f 11205 10866 10922 
f 11333 11559 11206 
f 11015 11206 11136 
f 11356 11589 10959 
f 11207 10917 11125 
f 11068 11208 10947 
f 11393 11068 10907 
f 10957 10899 11209 
f 11515 10957 11209 
f 11210 10942 11493 
f 11210 10878 11481 
f 11211 10970 10921 
f 11060 11496 11211 
f 10989 11151 11212 
f 10989 11212 11512 
f 11388 11213 10879 
f 11043 10879 11213 
f 11214 11288 11323 
f 11039 11430 11214 
f 10998 11518 11215 
f 11215 11473 11492 
f 11216 11034 10868 
f 11139 11217 10978 
f 11217 11411 10978 
f 11218 11489 11538 
f 11218 11375 11489 
f 10902 11219 11404 
f 11203 11219 10902 
f 11220 10966 11360 
f 11220 11402 10966 
f 10958 11468 10877 
f 11124 10877 11221 
f 11222 10975 11071 
f 11510 10975 11222 
f 11012 11223 11026 
f 10887 11026 11223 
f 11224 11033 11089 
f 11313 11033 11224 
f 11347 11225 11051 
f 10966 11225 10890 
f 11226 11107 10939 
f 11226 10939 11418 
f 11227 10923 11103 
f 10923 11227 11202 
f 11138 11055 11228 
f 11055 11588 11228 
f 10886 11127 11229 
f 11146 10886 11229 
f 11501 11334 11230 
f 11230 10957 10873 
f 10943 11231 11060 
f 11231 10943 11433 
f 10913 11399 11232 
f 11232 11247 11505 
f 11233 10961 11108 
f 10961 11382 11465 
f 11094 11234 11336 
f 11234 10915 11008 
f 10981 11592 11235 
f 10981 11235 11055 
f 11366 10948 11236 
f 11236 10948 11155 
f 10967 11285 11237 
f 11237 11039 10967 
f 11053 10990 11416 
f 11238 11553 11053 
f 10976 11474 11239 
f 10976 11239 11342 
f 11311 10997 11240 
f 11048 11240 10997 
f 10928 11034 11241 
f 11311 11241 11034 
f 11148 11013 11242 
f 11148 11242 10911 
f 11064 11243 11350 
f 11061 11243 11064 
f 11313 11137 11244 
f 11562 11244 11137 
f 11245 10991 11462 
f 11444 11443 11245 
f 11395 10944 11246 
f 11246 11524 10887 
f 10884 11226 11247 
f 11037 10884 11247 
f 11254 11248 11172 
f 11172 11248 10977 
f 10982 11130 11249 
f 10982 11249 11095 
f 11347 11250 11106 
f 11250 11293 11106 
f 11251 11027 10930 
f 11471 11251 11094 
f 11252 10974 11255 
f 10974 11252 11348 
f 10935 11446 11253 
f 11253 10975 11510 
f 10878 11254 11386 
f 10878 11210 11254 
f 11115 11255 11005 
f 11255 11547 11005 
f 10895 11256 11111 
f 11424 11539 10895 
f 11017 11073 11257 
f 11257 10931 11017 
f 11028 11258 11394 
f 11258 11244 11029 
f 11259 10907 10948 
f 10907 11259 11393 
f 10926 11035 11479 
f 10882 11260 11035 
f 11375 11261 10867 
f 11261 10936 10867 
f 11262 11121 10979 
f 11575 11021 11262 
f 11263 10925 11076 
f 10925 11263 11497 
f 10999 11215 11264 
f 10999 11264 11104 
f 10883 11050 11265 
f 11265 11448 10883 
f 11417 11266 11282 
f 11266 10956 11113 
f 11267 10973 11184 
f 10973 11305 10944 
f 11010 11123 11268 
f 11268 11299 11010 
f 11087 11269 11044 
f 11269 10965 11044 
f 11390 11270 11061 
f 10874 11061 11270 
f 11271 11118 11460 
f 11413 11271 10918 
f 11001 11272 11115 
f 10985 11272 11001 
f 11273 10949 10893 
f 11514 10949 11273 
f 11260 10882 11274 
f 11116 11274 10882 
f 10946 11275 11075 
f 10946 11125 11275 
f 11276 11062 11540 
f 10945 11523 11276 
f 10869 11470 11057 
f 11277 10869 11324 
f 11594 11153 11527 
f 11278 10870 11153 
f 10951 11279 11014 
f 10951 11333 11279 
f 11082 11280 10916 
f 11280 11351 10916 
f 11281 11578 11082 
f 11281 11082 10916 
f 11282 11331 10955 
f 11282 10955 11417 
f 11283 10983 10894 
f 11132 10983 11283 
f 11347 11284 10914 
f 10914 11284 11183 
f 11285 11494 11063 
f 11063 11237 11285 
f 11021 11575 11286 
f 11286 10924 11021 
f 11374 11287 11095 
f 11287 10959 11095 
f 11569 11288 11170 
f 11569 11078 11288 
f 10962 11233 11289 
f 11463 11289 11456 
f 11290 10934 11431 
f 11290 11366 10934 
f 10910 10996 11291 
f 11520 11291 10996 
f 10892 11292 11539 
f 10892 11188 11292 
f 11535 11016 11174 
f 11016 11293 11445 
f 11420 10911 11294 
f 11542 11420 11294 
f 11295 11450 10932 
f 11295 10932 11586 
f 10940 11296 11338 
f 11537 10940 11187 
f 11297 10911 11420 
f 10911 11297 11504 
f 11298 11118 11002 
f 11002 11165 11298 
f 10980 10941 11299 
f 10941 11062 11299 
f 11087 11450 11300 
f 11269 11087 11300 
f 11456 11006 11463 
f 11301 11179 11006 
f 10910 11302 11328 
f 11302 10910 11084 
f 10876 11303 11070 
f 11227 11070 11303 
f 10964 11357 11304 
f 11090 10964 11304 
f 10973 11267 11305 
f 11131 11305 11267 
f 11306 10993 11152 
f 10993 11497 11385 
f 11307 11105 10928 
f 10928 11241 11307 
f 11022 10872 11308 
f 11308 10872 11076 
f 11033 11309 10900 
f 11327 11568 11309 
f 11310 11168 10963 
f 11310 11453 11168 
f 11552 11311 11110 
f 11240 11110 11311 
f 10891 11154 11312 
f 11238 10891 11312 
f 11224 11028 11313 
f 11137 11313 11028 
f 11043 11023 11314 
f 11043 11314 11404 
f 11315 10992 11116 
f 11155 10992 11315 
f 10903 11316 10889 
f 11503 11316 11145 
f 10947 11208 11317 
f 10947 11317 11503 
f 11392 11318 10927 
f 11241 10927 11318 
f 11025 11319 11103 
f 11103 11319 11070 
f 11320 11067 11179 
f 11067 11320 11127 
f 11144 11321 10874 
f 10874 11321 11243 
f 11004 11322 11454 
f 11322 11004 11594 
f 11323 11039 11214 
f 11039 11323 11354 
f 11488 11324 11110 
f 11324 11051 11277 
f 11125 11547 11325 
f 11325 10974 11348 
f 10960 11326 11149 
f 11350 11326 10960 
f 11373 11327 10950 
f 10950 11327 11135 
f 11337 10904 11328 
f 10910 11328 10904 
f 11136 11329 11014 
f 11014 11448 10951 
f 11330 10933 11424 
f 11262 10933 11330 
f 11331 11009 11361 
f 11331 11282 11491 
f 10943 11421 11332 
f 11332 11433 10943 
f 11015 11369 11333 
f 11206 11015 11333 
f 11099 11334 11532 
f 11032 11334 11501 
f 11335 10912 11543 
f 10912 11335 11359 
f 11336 11008 11435 
f 11336 11234 11008 
f 11025 11337 11328 
f 11025 10960 11337 
f 11072 11338 11270 
f 11338 11072 10940 
f 10915 11339 11020 
f 11077 11339 10915 
f 11079 11340 10920 
f 11340 11079 11506 
f 11341 10888 11300 
f 10888 11341 11074 
f 10954 11023 11342 
f 11342 11140 10954 
f 11590 11343 11487 
f 11590 11589 11343 
f 10885 11075 11344 
f 11344 11239 10885 
f 11093 11345 10957 
f 11093 11248 11345 
f 11513 10905 11346 
f 10905 11392 11346 
f 11106 10890 11347 
f 11347 10890 11225 
f 10870 11038 11348 
f 11348 11480 11576 
f 10938 11349 11174 
f 10871 11174 11349 
f 10897 11326 11350 
f 11243 10897 11350 
f 11412 10977 11351 
f 10977 11427 11351 
f 11211 11352 11060 
f 11352 11141 11060 
f 10937 11353 11042 
f 11353 10937 11118 
f 11550 11042 11354 
f 10929 11354 11323 
f 11499 11473 11355 
f 11355 11215 10999 
f 11397 10917 11356 
f 11589 11356 10917 
f 11357 11189 10880 
f 10880 11304 11357 
f 11358 11022 11498 
f 11073 11017 11359 
f 11160 11073 11359 
f 10973 11045 11360 
f 11360 11045 11220 
f 10906 11492 11361 
f 11566 11361 11009 
f 10938 11174 11362 
f 11152 10938 11362 
f 11519 11088 11363 
f 11088 11335 11363 
f 11075 11038 11364 
f 11364 11344 11075 
f 11365 10922 11583 
f 10922 11365 11205 
f 11366 11290 11129 
f 11129 11259 11366 
f 11436 11367 11178 
f 11178 11367 11097 
f 11059 11113 11368 
f 11368 11113 10956 
f 11014 11369 11136 
f 11369 11015 11136 
f 10955 11117 11370 
f 11370 11117 11059 
f 11024 11371 11193 
f 11371 11024 11515 
f 11372 11139 11098 
f 11098 11187 11372 
f 11185 11373 11490 
f 11373 11185 10937 
f 11095 11017 11374 
f 11017 10931 11374 
f 11375 11065 11167 
f 10867 11065 11375 
f 11081 11197 11376 
f 11537 11081 11376 
f 11128 11570 11105 
f 10928 11105 11377 
f 10931 11378 11074 
f 11378 11181 11074 
f 11208 10958 11379 
f 10958 11126 11379 
f 11042 11380 10937 
f 11550 11380 11042 
f 11130 10982 11381 
f 10982 11590 11381 
f 10961 11233 11382 
f 11233 11067 11382 
f 11040 11383 11078 
f 11147 11078 11383 
f 11000 11384 11077 
f 11538 11384 11102 
f 11056 11138 11385 
f 11385 11138 11349 
f 10878 11386 11080 
f 11172 11080 11386 
f 11178 11387 11069 
f 11069 11387 10896 
f 11085 11388 10879 
f 11388 11085 10981 
f 11389 11143 11071 
f 10975 11389 11071 
f 11390 10866 11270 
f 10866 11390 11036 
f 11391 11126 10909 
f 11391 10909 11192 
f 10927 10986 11392 
f 11346 11392 10986 
f 10958 11208 11393 
f 11068 11393 11208 
f 11394 11029 11137 
f 11394 11258 11029 
f 11246 10887 11395 
f 10887 10989 11395 
f 11396 11150 10988 
f 11161 11150 11396 
f 10917 11397 11005 
f 11005 11397 11001 
f 11398 11411 10969 
f 11398 10969 11367 
f 11193 10990 11399 
f 11193 11399 11024 
f 11400 10926 11528 
f 11035 10926 11400 
f 11121 11330 11401 
f 11401 11156 11121 
f 11169 10994 11402 
f 11051 11402 10994 
f 11030 11403 11525 
f 11403 11030 10899 
f 11404 10879 11043 
f 11365 10879 11404 
f 10989 10887 11405 
f 11151 10989 11405 
f 11406 11273 11101 
f 11406 11101 11152 
f 11407 11043 11555 
f 11023 11043 11407 
f 11408 11360 10966 
f 11558 11408 10966 
f 11409 10916 11351 
f 10916 11409 11281 
f 11410 10908 11127 
f 11127 11320 11410 
f 11411 11176 11097 
f 11411 11097 10969 
f 10988 11412 11396 
f 11412 10988 11102 
f 10918 11165 11413 
f 11165 11002 11413 
f 11414 11513 11057 
f 11414 11057 10894 
f 11415 11563 10911 
f 11504 11415 10911 
f 11053 11416 11238 
f 10891 11238 11416 
f 11266 11417 10956 
f 11368 10956 11417 
f 11099 11418 11585 
f 11418 11099 11226 
f 10899 11345 11419 
f 11419 11403 10899 
f 11420 10936 11082 
f 11420 11542 10936 
f 10894 11421 11171 
f 11060 11171 11421 
f 10898 11422 11056 
f 10981 11422 10898 
f 11495 11011 11573 
f 11116 11423 11011 
f 10933 10892 11424 
f 11539 11424 10892 
f 11037 11425 11563 
f 10990 11053 11425 
f 11049 11426 10985 
f 11159 10985 11426 
f 11427 10977 11248 
f 11427 11248 11093 
f 11428 11086 10984 
f 11086 11428 10976 
f 11194 11429 10868 
f 10968 11430 11544 
f 11214 11430 10968 
f 11431 11131 11290 
f 11305 11131 11431 
f 11432 11073 11517 
f 11480 11432 11517 
f 10994 11169 11433 
f 11151 11433 11169 
f 11012 10889 11434 
f 11223 11012 11434 
f 11477 11435 11092 
f 11092 11435 11008 
f 11069 11200 11436 
f 11429 11436 11200 
f 11437 10874 11175 
f 11175 11003 11437 
f 11269 11438 10965 
f 11191 10965 11438 
f 11068 10947 11439 
f 11439 10947 11145 
f 11440 11502 10876 
f 10876 11283 11440 
f 11441 10952 11372 
f 10940 10952 11441 
f 11194 11442 11398 
f 11194 11034 11442 
f 10991 11443 10875 
f 10991 11245 11443 
f 11031 11444 11537 
f 11443 11444 11031 
f 11250 10914 11445 
f 11445 10914 11183 
f 11054 11446 11186 
f 11446 10935 11186 
f 11401 11447 11156 
f 11506 11156 11447 
f 11448 11014 11329 
f 11329 10883 11448 
f 11449 11096 11086 
f 11256 11096 11449 
f 11300 11450 11142 
f 11450 11295 11142 
f 11508 11451 10941 
f 10941 11451 11106 
f 11177 10904 11452 
f 11058 11452 10904 
f 11013 11453 11310 
f 11453 11013 11027 
f 11140 11454 11534 
f 11140 11344 11454 
f 11455 11128 10875 
f 11128 11455 10978 
f 11108 11301 11456 
f 11301 11006 11456 
f 11459 11041 11457 
f 11041 11480 11457 
f 11321 11458 11173 
f 11403 11173 11458 
f 10919 11181 11459 
f 11457 10919 11459 
f 11165 10918 11460 
f 11460 10918 11271 
f 11124 11129 11461 
f 10877 11124 11461 
f 11052 11462 10991 
f 11392 11462 11052 
f 11289 11463 10962 
f 11179 10962 11463 
f 11464 11246 11091 
f 11464 11091 10903 
f 11001 11465 11049 
f 11049 11465 11382 
f 11312 11466 10930 
f 11511 10930 11466 
f 11342 11023 11467 
f 11407 11467 11023 
f 11468 11221 10877 
f 11221 11468 11393 
f 11234 11094 11469 
f 11094 11587 11469 
f 11277 10994 11470 
f 11470 10869 11277 
f 11471 11027 11251 
f 11336 11027 11471 
f 11478 11054 11472 
f 11533 11472 11054 
f 11473 11499 11104 
f 11104 11492 11473 
f 11204 10885 11474 
f 11474 11130 11204 
f 11272 11159 11475 
f 11159 11566 11475 
f 11476 11058 11337 
f 11210 11058 11476 
f 11092 11167 11477 
f 11477 11167 11065 
f 10886 11146 11478 
f 10886 11478 11157 
f 11479 11035 11260 
f 11260 11274 11479 
f 11517 10924 11480 
f 11576 11480 10924 
f 10942 11481 10878 
f 11210 11481 10942 
f 11482 10877 11123 
f 11482 10958 10877 
f 11042 10883 11483 
f 10987 11483 10883 
f 11229 11083 11484 
f 11083 11104 11484 
f 11313 11485 11033 
f 11309 11033 11485 
f 11066 11522 11486 
f 11066 11486 11211 
f 11119 11487 11343 
f 11204 11487 11119 
f 11051 11324 11488 
f 11051 11488 11347 
f 11020 11339 11489 
f 11020 11489 11167 
f 11002 11490 11050 
f 11265 11050 11490 
f 11198 11009 11491 
f 11491 11009 11331 
f 11492 10906 10998 
f 11492 10998 11215 
f 11102 11384 11493 
f 11102 11493 10942 
f 11494 11145 11135 
f 11494 11135 11327 
f 11011 11495 11120 
f 11579 11120 11495 
f 11192 10970 11496 
f 11496 10970 11211 
f 11497 10993 11306 
f 11497 11306 11195 
f 11498 11022 11195 
f 11195 11101 11498 
f 11104 11499 10999 
f 11499 11355 10999 
f 11500 11076 10872 
f 11076 11500 11263 
f 10873 11515 11501 
f 11230 10873 11501 
f 11276 11502 10945 
f 11522 10945 11502 
f 11503 10889 11316 
f 11503 11434 10889 
f 11107 11504 11578 
f 11415 11504 11107 
f 11505 10913 11232 
f 11505 11024 10913 
f 10866 11205 11506 
f 11447 10866 11506 
f 11084 11507 11516 
f 11084 11414 11507 
f 10980 11158 11508 
f 11558 11508 11158 
f 10980 11509 11158 
f 11509 10980 11123 
f 11253 11510 10935 
f 10935 11510 11222 
f 11321 11144 11511 
f 10930 11511 11144 
f 10944 11512 10973 
f 11395 11512 10944 
f 11197 11513 11019 
f 11414 11019 11513 
f 11362 10953 11514 
f 11554 11514 10953 
f 11162 11515 11024 
f 11162 11501 11515 
f 11132 11516 10983 
f 11516 10894 10983 
f 11160 10924 11517 
f 11517 11073 11160 
f 11146 11215 11518 
f 11478 11146 11518 
f 11262 11519 10933 
f 10892 10933 11519 
f 10964 11197 11520 
f 11357 10964 11520 
f 11009 11198 11521 
f 11115 11521 11198 
f 11522 11141 11352 
f 11141 11522 11440 
f 11276 11523 11062 
f 11299 11062 11523 
f 11524 11122 11026 
f 11026 10887 11524 
f 11154 11525 11458 
f 11525 11154 10990 
f 11217 11139 11526 
f 11139 11372 11526 
f 11203 11527 10920 
f 11121 10920 11527 
f 11163 11114 11528 
f 11528 11114 11400 
f 11448 11529 11100 
f 11199 11100 11529 
f 11235 11530 11055 
f 11235 11103 11530 
f 11010 11531 10921 
f 11196 11531 11010 
f 11334 11032 11532 
f 11532 11032 11247 
f 11472 11533 11143 
f 11071 11143 11533 
f 11534 11322 10902 
f 11534 10902 11314 
f 11016 11535 11293 
f 11535 10871 11293 
f 11536 11003 11175 
f 11587 11003 11536 
f 11296 10940 11537 
f 11537 11376 11296 
f 11077 11538 11339 
f 11538 11077 11384 
f 11256 10895 11539 
f 11539 11096 11256 
f 11540 10871 11202 
f 10871 11540 11293 
f 10972 11541 11047 
f 11541 10972 11500 
f 10963 11560 11542 
f 10963 11542 11310 
f 11543 11188 10892 
f 11188 11543 10984 
f 11544 11147 10968 
f 11544 11288 11147 
f 11130 11545 10912 
f 11545 11543 10912 
f 10875 11098 11546 
f 11546 11455 10875 
f 11547 11255 10974 
f 10974 11325 11547 
f 11548 10927 11311 
f 10986 10927 11548 
f 11007 11549 11551 
f 11133 11549 11007 
f 11550 10929 11485 
f 10929 11550 11354 
f 11551 11111 11256 
f 10972 11111 11551 
f 11110 11109 11552 
f 11552 11109 10986 
f 11553 11238 11312 
f 10930 11553 11312 
f 10953 11183 11554 
f 11240 11554 11183 
f 11555 11213 11201 
f 11213 11555 11043 
f 11155 11556 10992 
f 11274 10992 11556 
f 11231 11557 11060 
f 11557 11231 11151 
f 10890 11558 10966 
f 11558 10890 11451 
f 11559 10951 11574 
f 11559 11333 10951 
f 11560 10963 10867 
f 11560 10867 10936 
f 11391 11046 11561 
f 11046 11317 11561 
f 11562 11029 11244 
f 11562 11137 11029 
f 11563 11425 11053 
f 11563 11053 11148 
f 11564 10915 11020 
f 10915 11564 11008 
f 11075 11565 10946 
f 11075 10885 11565 
f 11361 11566 10906 
f 11518 10906 11566 
f 11567 11164 10931 
f 11567 10931 11341 
f 10900 11309 11568 
f 11033 10900 11568 
f 11078 11569 11040 
f 11040 11569 11170 
f 11377 11105 11570 
f 11377 11570 10978 
f 11123 11010 11571 
f 11571 11126 11123 
f 11068 11572 11163 
f 11439 11572 11068 
f 11423 11573 11011 
f 11573 11423 11114 
f 10987 11574 11495 
f 11574 10987 11559 
f 11527 11575 10979 
f 11262 10979 11575 
f 10870 11576 11153 
f 11348 11576 10870 
f 11170 11577 11040 
f 11577 11170 11214 
f 11418 10939 11578 
f 11578 10939 11107 
f 11316 11579 11145 
f 11579 11316 11120 
f 11074 11580 10888 
f 11134 10888 11580 
f 10896 11581 11069 
f 11582 11318 11052 
f 11582 11052 11128 
f 11085 11583 11592 
f 11583 11085 11365 
f 10884 11037 11584 
f 11563 11584 11037 
f 11585 10971 11099 
f 10971 11585 11351 
f 11044 11586 10932 
f 11586 11044 10965 
f 11587 10880 11469 
f 11587 11304 10880 
f 11530 10923 11588 
f 11588 10923 11202 
f 11589 11207 11343 
f 11589 10917 11207 
f 11590 11095 10959 
f 11589 11590 10959 
f 11591 11157 11018 
f 11018 11446 11591 
f 11064 11235 11592 
f 11064 11592 11036 
f 10901 11593 11059 
f 11593 11266 11059 
f 11594 11278 11153 
f 11594 11004 11278 
f 11595 11613 11611 
f 11596 11595 11611 
f 11597 11596 11611 
f 11598 11597 11611 
f 11599 11598 11611 
f 11600 11599 11611 
f 11601 11600 11611 
f 11602 11601 11611 
f 11603 11602 11611 
f 11604 11603 11611 
f 11605 11604 11611 
f 11606 11605 11611 
f 11607 11606 11611 
f 11608 11607 11611 
f 11609 11608 11611 
f 11610 11609 11611 
f 11614 11610 11611 
f 11613 11614 11611 
f 11615 11616 11612 
f 11616 11617 11612 
f 11617 11618 11612 
f 11618 11619 11612 
f 11619 11620 11612 
f 11620 11621 11612 
f 11621 11622 11612 
f 11622 11623 11612 
f 11623 11624 11612 
f 11624 11625 11612 
f 11625 11626 11612 
f 11626 11627 11612 
f 11627 11628 11612 
f 11628 11629 11612 
f 11629 11630 11612 
f 11630 11631 11612 
f 11631 11632 11612 
f 11632 11615 11612 
f 13643 11613 11595 
f 13643 11595 11596 
f 13686 11596 11597 
f 13686 11597 11598 
f 13686 11598 11599 
f 13686 11599 11600 
f 13686 11600 11601 
f 13586 11601 11602 
f 13586 11602 11603 
f 13796 11603 11604 
f 13796 11604 11605 
f 13733 11605 11606 
f 13733 11606 11607 
f 13643 11607 11608 
f 13643 11608 11609 
f 13643 11609 11610 
f 13643 11610 11614 
f 13643 11614 11613 
f 13643 11616 11615 
f 13686 11617 11616 
f 13686 11618 11617 
f 13686 11619 11618 
f 13686 11620 11619 
f 13686 11621 11620 
f 13586 11622 11621 
f 13586 11623 11622 
f 13796 11624 11623 
f 13796 11625 11624 
f 13733 11626 11625 
f 13733 11627 11626 
f 13733 11628 11627 
f 13643 11629 11628 
f 13643 11630 11629 
f 13643 11631 11630 
f 13643 11632 11631 
f 13643 11615 11632 
f 11633 11651 11649 
f 11634 11633 11649 
f 11635 11634 11649 
f 11636 11635 11649 
f 11637 11636 11649 
f 11638 11637 11649 
f 11639 11638 11649 
f 11640 11639 11649 
f 11641 11640 11649 
f 11642 11641 11649 
f 11643 11642 11649 
f 11644 11643 11649 
f 11645 11644 11649 
f 11646 11645 11649 
f 11647 11646 11649 
f 11648 11647 11649 
f 11652 11648 11649 
f 11651 11652 11649 
f 11653 11654 11650 
f 11654 11655 11650 
f 11655 11656 11650 
f 11656 11657 11650 
f 11657 11658 11650 
f 11658 11659 11650 
f 11659 11660 11650 
f 11660 11661 11650 
f 11661 11662 11650 
f 11662 11663 11650 
f 11663 11664 11650 
f 11664 11665 11650 
f 11665 11666 11650 
f 11666 11667 11650 
f 11667 11668 11650 
f 11668 11669 11650 
f 11669 11670 11650 
f 11670 11653 11650 
f 13750 11651 11633 
f 13750 11633 11634 
f 13687 11634 11635 
f 13687 11635 11636 
f 13687 11636 11637 
f 13687 11637 11638 
f 13687 11638 11639 
f 13588 11639 11640 
f 13588 11640 11641 
f 13588 11641 11642 
f 13588 11642 11643 
f 13736 11643 11644 
f 13736 11644 11645 
f 13645 11645 11646 
f 13645 11646 11647 
f 13645 11647 11648 
f 13645 11648 11652 
f 13645 11652 11651 
f 13750 11654 11653 
f 13687 11655 11654 
f 13687 11656 11655 
f 13687 11657 11656 
f 13687 11658 11657 
f 13687 11659 11658 
f 13588 11660 11659 
f 13588 11661 11660 
f 13588 11662 11661 
f 13588 11663 11662 
f 13736 11664 11663 
f 13736 11665 11664 
f 13736 11666 11665 
f 13645 11667 11666 
f 13645 11668 11667 
f 13645 11669 11668 
f 13645 11670 11669 
f 13750 11653 11670 
f 11671 11689 11687 
f 11672 11671 11687 
f 11673 11672 11687 
f 11674 11673 11687 
f 11675 11674 11687 
f 11676 11675 11687 
f 11677 11676 11687 
f 11678 11677 11687 
f 11679 11678 11687 
f 11680 11679 11687 
f 11681 11680 11687 
f 11682 11681 11687 
f 11683 11682 11687 
f 11684 11683 11687 
f 11685 11684 11687 
f 11686 11685 11687 
f 11690 11686 11687 
f 11689 11690 11687 
f 11691 11692 11688 
f 11692 11693 11688 
f 11693 11694 11688 
f 11694 11695 11688 
f 11695 11696 11688 
f 11696 11697 11688 
f 11697 11698 11688 
f 11698 11699 11688 
f 11699 11700 11688 
f 11700 11701 11688 
f 11701 11702 11688 
f 11702 11703 11688 
f 11703 11704 11688 
f 11704 11705 11688 
f 11705 11706 11688 
f 11706 11707 11688 
f 11707 11708 11688 
f 11708 11691 11688 
f 13693 11689 11671 
f 13693 11671 11672 
f 13650 11672 11673 
f 13650 11673 11674 
f 13650 11674 11675 
f 13650 11675 11676 
f 13650 11676 11677 
f 13775 11677 11678 
f 13775 11678 11679 
f 13613 11679 11680 
f 13613 11680 11681 
f 13613 11681 11682 
f 13613 11682 11683 
f 13693 11683 11684 
f 13693 11684 11685 
f 13693 11685 11686 
f 13693 11686 11690 
f 13693 11690 11689 
f 13693 11692 11691 
f 13650 11693 11692 
f 13650 11694 11693 
f 13650 11695 11694 
f 13650 11696 11695 
f 13650 11697 11696 
f 13775 11698 11697 
f 13775 11699 11698 
f 13613 11700 11699 
f 13613 11701 11700 
f 13613 11702 11701 
f 13613 11703 11702 
f 13613 11704 11703 
f 13693 11705 11704 
f 13693 11706 11705 
f 13693 11707 11706 
f 13693 11708 11707 
f 13693 11691 11708 
f 11709 11727 11725 
f 11710 11709 11725 
f 11711 11710 11725 
f 11712 11711 11725 
f 11713 11712 11725 
f 11714 11713 11725 
f 11715 11714 11725 
f 11716 11715 11725 
f 11717 11716 11725 
f 11718 11717 11725 
f 11719 11718 11725 
f 11720 11719 11725 
f 11721 11720 11725 
f 11722 11721 11725 
f 11723 11722 11725 
f 11724 11723 11725 
f 11728 11724 11725 
f 11727 11728 11725 
f 11729 11730 11726 
f 11730 11731 11726 
f 11731 11732 11726 
f 11732 11733 11726 
f 11733 11734 11726 
f 11734 11735 11726 
f 11735 11736 11726 
f 11736 11737 11726 
f 11737 11738 11726 
f 11738 11739 11726 
f 11739 11740 11726 
f 11740 11741 11726 
f 11741 11742 11726 
f 11742 11743 11726 
f 11743 11744 11726 
f 11744 11745 11726 
f 11745 11746 11726 
f 11746 11729 11726 
f 13664 11727 11709 
f 13664 11709 11710 
f 13799 11710 11711 
f 13799 11711 11712 
f 13603 11712 11713 
f 13603 11713 11714 
f 13603 11714 11715 
f 13603 11715 11716 
f 13603 11716 11717 
f 13603 11717 11718 
f 13603 11718 11719 
f 13603 11719 11720 
f 13603 11720 11721 
f 13740 11721 11722 
f 13740 11722 11723 
f 13740 11723 11724 
f 13664 11724 11728 
f 13664 11728 11727 
f 13664 11730 11729 
f 13799 11731 11730 
f 13799 11732 11731 
f 13799 11733 11732 
f 13799 11734 11733 
f 13603 11735 11734 
f 13603 11736 11735 
f 13603 11737 11736 
f 13603 11738 11737 
f 13603 11739 11738 
f 13603 11740 11739 
f 13740 11741 11740 
f 13740 11742 11741 
f 13740 11743 11742 
f 13740 11744 11743 
f 13664 11745 11744 
f 13664 11746 11745 
f 13664 11729 11746 
f 11747 11765 11763 
f 11748 11747 11763 
f 11749 11748 11763 
f 11750 11749 11763 
f 11751 11750 11763 
f 11752 11751 11763 
f 11753 11752 11763 
f 11754 11753 11763 
f 11755 11754 11763 
f 11756 11755 11763 
f 11757 11756 11763 
f 11758 11757 11763 
f 11759 11758 11763 
f 11760 11759 11763 
f 11761 11760 11763 
f 11762 11761 11763 
f 11766 11762 11763 
f 11765 11766 11763 
f 11767 11768 11764 
f 11768 11769 11764 
f 11769 11770 11764 
f 11770 11771 11764 
f 11771 11772 11764 
f 11772 11773 11764 
f 11773 11774 11764 
f 11774 11775 11764 
f 11775 11776 11764 
f 11776 11777 11764 
f 11777 11778 11764 
f 11778 11779 11764 
f 11779 11780 11764 
f 11780 11781 11764 
f 11781 11782 11764 
f 11782 11783 11764 
f 11783 11784 11764 
f 11784 11767 11764 
f 13763 11765 11747 
f 13763 11747 11748 
f 13623 11748 11749 
f 13623 11749 11750 
f 13623 11750 11751 
f 13623 11751 11752 
f 13623 11752 11753 
f 13623 11753 11754 
f 13755 11754 11755 
f 13755 11755 11756 
f 13755 11756 11757 
f 11785 11757 11758 
f 11785 11758 11759 
f 11785 11759 11760 
f 11785 11760 11761 
f 11785 11761 11762 
f 13682 11762 11766 
f 13682 11766 11765 
f 13763 11768 11767 
f 13623 11769 11768 
f 13623 11770 11769 
f 13623 11771 11770 
f 13623 11772 11771 
f 13623 11773 11772 
f 13623 11774 11773 
f 13623 11775 11774 
f 13623 11776 11775 
f 13755 11777 11776 
f 13755 11778 11777 
f 11785 11779 11778 
f 11785 11780 11779 
f 11785 11781 11780 
f 11785 11782 11781 
f 13682 11783 11782 
f 13682 11784 11783 
f 13763 11767 11784 
f 11786 11804 11802 
f 11787 11786 11802 
f 11788 11787 11802 
f 11789 11788 11802 
f 11790 11789 11802 
f 11791 11790 11802 
f 11792 11791 11802 
f 11793 11792 11802 
f 11794 11793 11802 
f 11795 11794 11802 
f 11796 11795 11802 
f 11797 11796 11802 
f 11798 11797 11802 
f 11799 11798 11802 
f 11800 11799 11802 
f 11801 11800 11802 
f 11805 11801 11802 
f 11804 11805 11802 
f 11806 11807 11803 
f 11807 11808 11803 
f 11808 11809 11803 
f 11809 11810 11803 
f 11810 11811 11803 
f 11811 11812 11803 
f 11812 11813 11803 
f 11813 11814 11803 
f 11814 11815 11803 
f 11815 11816 11803 
f 11816 11817 11803 
f 11817 11818 11803 
f 11818 11819 11803 
f 11819 11820 11803 
f 11820 11821 11803 
f 11821 11822 11803 
f 11822 11823 11803 
f 11823 11806 11803 
f 13624 11804 11786 
f 13624 11786 11787 
f 13624 11787 11788 
f 13624 11788 11789 
f 13624 11789 11790 
f 13624 11790 11791 
f 13624 11791 11792 
f 13624 11792 11793 
f 13759 11793 11794 
f 13759 11794 11795 
f 13759 11795 11796 
f 11824 11796 11797 
f 11824 11797 11798 
f 11824 11798 11799 
f 11824 11799 11800 
f 11824 11800 11801 
f 13716 11801 11805 
f 13716 11805 11804 
f 13624 11807 11806 
f 13624 11808 11807 
f 13624 11809 11808 
f 13624 11810 11809 
f 13624 11811 11810 
f 13624 11812 11811 
f 13624 11813 11812 
f 13624 11814 11813 
f 13624 11815 11814 
f 13759 11816 11815 
f 13759 11817 11816 
f 11824 11818 11817 
f 11824 11819 11818 
f 11824 11820 11819 
f 11824 11821 11820 
f 13716 11822 11821 
f 13716 11823 11822 
f 13624 11806 11823 
f 11825 11843 11841 
f 11826 11825 11841 
f 11827 11826 11841 
f 11828 11827 11841 
f 11829 11828 11841 
f 11830 11829 11841 
f 11831 11830 11841 
f 11832 11831 11841 
f 11833 11832 11841 
f 11834 11833 11841 
f 11835 11834 11841 
f 11836 11835 11841 
f 11837 11836 11841 
f 11838 11837 11841 
f 11839 11838 11841 
f 11840 11839 11841 
f 11844 11840 11841 
f 11843 11844 11841 
f 11845 11846 11842 
f 11846 11847 11842 
f 11847 11848 11842 
f 11848 11849 11842 
f 11849 11850 11842 
f 11850 11851 11842 
f 11851 11852 11842 
f 11852 11853 11842 
f 11853 11854 11842 
f 11854 11855 11842 
f 11855 11856 11842 
f 11856 11857 11842 
f 11857 11858 11842 
f 11858 11859 11842 
f 11859 11860 11842 
f 11860 11861 11842 
f 11861 11862 11842 
f 11862 11845 11842 
f 13675 11843 11825 
f 13675 11825 11826 
f 13675 11826 11827 
f 13675 11827 11828 
f 13601 11828 11829 
f 13601 11829 11830 
f 13601 11830 11831 
f 13601 11831 11832 
f 13601 11832 11833 
f 13601 11833 11834 
f 13601 11834 11835 
f 13625 11835 11836 
f 13625 11836 11837 
f 13625 11837 11838 
f 13625 11838 11839 
f 13625 11839 11840 
f 13735 11840 11844 
f 13735 11844 11843 
f 13675 11846 11845 
f 13675 11847 11846 
f 13675 11848 11847 
f 13675 11849 11848 
f 13675 11850 11849 
f 13601 11851 11850 
f 13601 11852 11851 
f 13601 11853 11852 
f 13601 11854 11853 
f 13601 11855 11854 
f 13601 11856 11855 
f 13625 11857 11856 
f 13625 11858 11857 
f 13625 11859 11858 
f 13625 11860 11859 
f 13735 11861 11860 
f 13735 11862 11861 
f 13675 11845 11862 
f 11863 11881 11879 
f 11864 11863 11879 
f 11865 11864 11879 
f 11866 11865 11879 
f 11867 11866 11879 
f 11868 11867 11879 
f 11869 11868 11879 
f 11870 11869 11879 
f 11871 11870 11879 
f 11872 11871 11879 
f 11873 11872 11879 
f 11874 11873 11879 
f 11875 11874 11879 
f 11876 11875 11879 
f 11877 11876 11879 
f 11878 11877 11879 
f 11882 11878 11879 
f 11881 11882 11879 
f 11883 11884 11880 
f 11884 11885 11880 
f 11885 11886 11880 
f 11886 11887 11880 
f 11887 11888 11880 
f 11888 11889 11880 
f 11889 11890 11880 
f 11890 11891 11880 
f 11891 11892 11880 
f 11892 11893 11880 
f 11893 11894 11880 
f 11894 11895 11880 
f 11895 11896 11880 
f 11896 11897 11880 
f 11897 11898 11880 
f 11898 11899 11880 
f 11899 11900 11880 
f 11900 11883 11880 
f 13715 11881 11863 
f 13715 11863 11864 
f 13637 11864 11865 
f 13637 11865 11866 
f 13637 11866 11867 
f 13637 11867 11868 
f 13637 11868 11869 
f 13787 11869 11870 
f 13787 11870 11871 
f 13585 11871 11872 
f 13585 11872 11873 
f 13696 11873 11874 
f 13696 11874 11875 
f 13696 11875 11876 
f 13696 11876 11877 
f 13696 11877 11878 
f 13696 11878 11882 
f 13696 11882 11881 
f 13715 11884 11883 
f 13637 11885 11884 
f 13637 11886 11885 
f 13637 11887 11886 
f 13637 11888 11887 
f 13637 11889 11888 
f 13637 11890 11889 
f 13787 11891 11890 
f 13787 11892 11891 
f 13585 11893 11892 
f 13585 11894 11893 
f 13696 11895 11894 
f 13696 11896 11895 
f 13696 11897 11896 
f 13696 11898 11897 
f 13696 11899 11898 
f 13696 11900 11899 
f 13715 11883 11900 
f 11901 11919 11917 
f 11902 11901 11917 
f 11903 11902 11917 
f 11904 11903 11917 
f 11905 11904 11917 
f 11906 11905 11917 
f 11907 11906 11917 
f 11908 11907 11917 
f 11909 11908 11917 
f 11910 11909 11917 
f 11911 11910 11917 
f 11912 11911 11917 
f 11913 11912 11917 
f 11914 11913 11917 
f 11915 11914 11917 
f 11916 11915 11917 
f 11920 11916 11917 
f 11919 11920 11917 
f 11921 11922 11918 
f 11922 11923 11918 
f 11923 11924 11918 
f 11924 11925 11918 
f 11925 11926 11918 
f 11926 11927 11918 
f 11927 11928 11918 
f 11928 11929 11918 
f 11929 11930 11918 
f 11930 11931 11918 
f 11931 11932 11918 
f 11932 11933 11918 
f 11933 11934 11918 
f 11934 11935 11918 
f 11935 11936 11918 
f 11936 11937 11918 
f 11937 11938 11918 
f 11938 11921 11918 
f 13607 11919 11901 
f 13607 11901 11902 
f 13607 11902 11903 
f 13607 11903 11904 
f 13607 11904 11905 
f 13607 11905 11906 
f 13607 11906 11907 
f 13699 11907 11908 
f 13699 11908 11909 
f 13699 11909 11910 
f 13699 11910 11911 
f 13646 11911 11912 
f 13646 11912 11913 
f 13646 11913 11914 
f 13646 11914 11915 
f 13646 11915 11916 
f 13730 11916 11920 
f 13730 11920 11919 
f 13730 11922 11921 
f 13607 11923 11922 
f 13607 11924 11923 
f 13607 11925 11924 
f 13607 11926 11925 
f 13607 11927 11926 
f 13607 11928 11927 
f 13699 11929 11928 
f 13699 11930 11929 
f 13699 11931 11930 
f 13699 11932 11931 
f 13646 11933 11932 
f 13646 11934 11933 
f 13646 11935 11934 
f 13646 11936 11935 
f 13646 11937 11936 
f 13646 11938 11937 
f 13730 11921 11938 
f 11939 11957 11955 
f 11940 11939 11955 
f 11941 11940 11955 
f 11942 11941 11955 
f 11943 11942 11955 
f 11944 11943 11955 
f 11945 11944 11955 
f 11946 11945 11955 
f 11947 11946 11955 
f 11948 11947 11955 
f 11949 11948 11955 
f 11950 11949 11955 
f 11951 11950 11955 
f 11952 11951 11955 
f 11953 11952 11955 
f 11954 11953 11955 
f 11958 11954 11955 
f 11957 11958 11955 
f 11959 11960 11956 
f 11960 11961 11956 
f 11961 11962 11956 
f 11962 11963 11956 
f 11963 11964 11956 
f 11964 11965 11956 
f 11965 11966 11956 
f 11966 11967 11956 
f 11967 11968 11956 
f 11968 11969 11956 
f 11969 11970 11956 
f 11970 11971 11956 
f 11971 11972 11956 
f 11972 11973 11956 
f 11973 11974 11956 
f 11974 11975 11956 
f 11975 11976 11956 
f 11976 11959 11956 
f 13648 11957 11939 
f 13648 11939 11940 
f 13648 11940 11941 
f 13648 11941 11942 
f 13721 11942 11943 
f 13721 11943 11944 
f 13721 11944 11945 
f 13721 11945 11946 
f 13721 11946 11947 
f 13622 11947 11948 
f 13622 11948 11949 
f 13622 11949 11950 
f 13622 11950 11951 
f 13743 11951 11952 
f 13743 11952 11953 
f 13743 11953 11954 
f 13743 11954 11958 
f 13743 11958 11957 
f 13648 11960 11959 
f 13648 11961 11960 
f 13648 11962 11961 
f 13648 11963 11962 
f 13648 11964 11963 
f 13721 11965 11964 
f 13721 11966 11965 
f 13721 11967 11966 
f 13721 11968 11967 
f 13622 11969 11968 
f 13622 11970 11969 
f 13622 11971 11970 
f 13622 11972 11971 
f 13743 11973 11972 
f 13743 11974 11973 
f 13743 11975 11974 
f 13743 11976 11975 
f 13648 11959 11976 
f 11977 11995 11993 
f 11978 11977 11993 
f 11979 11978 11993 
f 11980 11979 11993 
f 11981 11980 11993 
f 11982 11981 11993 
f 11983 11982 11993 
f 11984 11983 11993 
f 11985 11984 11993 
f 11986 11985 11993 
f 11987 11986 11993 
f 11988 11987 11993 
f 11989 11988 11993 
f 11990 11989 11993 
f 11991 11990 11993 
f 11992 11991 11993 
f 11996 11992 11993 
f 11995 11996 11993 
f 11997 11998 11994 
f 11998 11999 11994 
f 11999 12000 11994 
f 12000 12001 11994 
f 12001 12002 11994 
f 12002 12003 11994 
f 12003 12004 11994 
f 12004 12005 11994 
f 12005 12006 11994 
f 12006 12007 11994 
f 12007 12008 11994 
f 12008 12009 11994 
f 12009 12010 11994 
f 12010 12011 11994 
f 12011 12012 11994 
f 12012 12013 11994 
f 12013 12014 11994 
f 12014 11997 11994 
f 13649 11995 11977 
f 13717 11977 11978 
f 13717 11978 11979 
f 13766 11979 11980 
f 13766 11980 11981 
f 13592 11981 11982 
f 13592 11982 11983 
f 13592 11983 11984 
f 13592 11984 11985 
f 13592 11985 11986 
f 13700 11986 11987 
f 13700 11987 11988 
f 13700 11988 11989 
f 13700 11989 11990 
f 13700 11990 11991 
f 13649 11991 11992 
f 13649 11992 11996 
f 13649 11996 11995 
f 13649 11998 11997 
f 13717 11999 11998 
f 13717 12000 11999 
f 13766 12001 12000 
f 13766 12002 12001 
f 13592 12003 12002 
f 13592 12004 12003 
f 13592 12005 12004 
f 13592 12006 12005 
f 13700 12007 12006 
f 13700 12008 12007 
f 13700 12009 12008 
f 13700 12010 12009 
f 13700 12011 12010 
f 13700 12012 12011 
f 13649 12013 12012 
f 13649 12014 12013 
f 13649 11997 12014 
f 12015 12033 12031 
f 12016 12015 12031 
f 12017 12016 12031 
f 12018 12017 12031 
f 12019 12018 12031 
f 12020 12019 12031 
f 12021 12020 12031 
f 12022 12021 12031 
f 12023 12022 12031 
f 12024 12023 12031 
f 12025 12024 12031 
f 12026 12025 12031 
f 12027 12026 12031 
f 12028 12027 12031 
f 12029 12028 12031 
f 12030 12029 12031 
f 12034 12030 12031 
f 12033 12034 12031 
f 12035 12036 12032 
f 12036 12037 12032 
f 12037 12038 12032 
f 12038 12039 12032 
f 12039 12040 12032 
f 12040 12041 12032 
f 12041 12042 12032 
f 12042 12043 12032 
f 12043 12044 12032 
f 12044 12045 12032 
f 12045 12046 12032 
f 12046 12047 12032 
f 12047 12048 12032 
f 12048 12049 12032 
f 12049 12050 12032 
f 12050 12051 12032 
f 12051 12052 12032 
f 12052 12035 12032 
f 13663 12033 12015 
f 13663 12015 12016 
f 13597 12016 12017 
f 13597 12017 12018 
f 13597 12018 12019 
f 13597 12019 12020 
f 13597 12020 12021 
f 13597 12021 12022 
f 13728 12022 12023 
f 13728 12023 12024 
f 13728 12024 12025 
f 13728 12025 12026 
f 13728 12026 12027 
f 13728 12027 12028 
f 13663 12028 12029 
f 13663 12029 12030 
f 13663 12030 12034 
f 13663 12034 12033 
f 13663 12036 12035 
f 13663 12037 12036 
f 13663 12038 12037 
f 13597 12039 12038 
f 13597 12040 12039 
f 13597 12041 12040 
f 13597 12042 12041 
f 13728 12043 12042 
f 13728 12044 12043 
f 13728 12045 12044 
f 13728 12046 12045 
f 13728 12047 12046 
f 13728 12048 12047 
f 13728 12049 12048 
f 13663 12050 12049 
f 13663 12051 12050 
f 13663 12052 12051 
f 13663 12035 12052 
f 12053 12071 12069 
f 12054 12053 12069 
f 12055 12054 12069 
f 12056 12055 12069 
f 12057 12056 12069 
f 12058 12057 12069 
f 12059 12058 12069 
f 12060 12059 12069 
f 12061 12060 12069 
f 12062 12061 12069 
f 12063 12062 12069 
f 12064 12063 12069 
f 12065 12064 12069 
f 12066 12065 12069 
f 12067 12066 12069 
f 12068 12067 12069 
f 12072 12068 12069 
f 12071 12072 12069 
f 12073 12074 12070 
f 12074 12075 12070 
f 12075 12076 12070 
f 12076 12077 12070 
f 12077 12078 12070 
f 12078 12079 12070 
f 12079 12080 12070 
f 12080 12081 12070 
f 12081 12082 12070 
f 12082 12083 12070 
f 12083 12084 12070 
f 12084 12085 12070 
f 12085 12086 12070 
f 12086 12087 12070 
f 12087 12088 12070 
f 12088 12089 12070 
f 12089 12090 12070 
f 12090 12073 12070 
f 13659 12071 12053 
f 13659 12053 12054 
f 13659 12054 12055 
f 13801 12055 12056 
f 13801 12056 12057 
f 13593 12057 12058 
f 13593 12058 12059 
f 13593 12059 12060 
f 13593 12060 12061 
f 13694 12061 12062 
f 13694 12062 12063 
f 13694 12063 12064 
f 13694 12064 12065 
f 13694 12065 12066 
f 13694 12066 12067 
f 13694 12067 12068 
f 13694 12068 12072 
f 13659 12072 12071 
f 13659 12074 12073 
f 13659 12075 12074 
f 13659 12076 12075 
f 13801 12077 12076 
f 13801 12078 12077 
f 13593 12079 12078 
f 13593 12080 12079 
f 13593 12081 12080 
f 13593 12082 12081 
f 13694 12083 12082 
f 13694 12084 12083 
f 13694 12085 12084 
f 13694 12086 12085 
f 13694 12087 12086 
f 13694 12088 12087 
f 13694 12089 12088 
f 13694 12090 12089 
f 13659 12073 12090 
f 12091 12109 12107 
f 12092 12091 12107 
f 12093 12092 12107 
f 12094 12093 12107 
f 12095 12094 12107 
f 12096 12095 12107 
f 12097 12096 12107 
f 12098 12097 12107 
f 12099 12098 12107 
f 12100 12099 12107 
f 12101 12100 12107 
f 12102 12101 12107 
f 12103 12102 12107 
f 12104 12103 12107 
f 12105 12104 12107 
f 12106 12105 12107 
f 12110 12106 12107 
f 12109 12110 12107 
f 12111 12112 12108 
f 12112 12113 12108 
f 12113 12114 12108 
f 12114 12115 12108 
f 12115 12116 12108 
f 12116 12117 12108 
f 12117 12118 12108 
f 12118 12119 12108 
f 12119 12120 12108 
f 12120 12121 12108 
f 12121 12122 12108 
f 12122 12123 12108 
f 12123 12124 12108 
f 12124 12125 12108 
f 12125 12126 12108 
f 12126 12127 12108 
f 12127 12128 12108 
f 12128 12111 12108 
f 13732 12109 12091 
f 13744 12091 12092 
f 13744 12092 12093 
f 13617 12093 12094 
f 13617 12094 12095 
f 13786 12095 12096 
f 13786 12096 12097 
f 13786 12097 12098 
f 13658 12098 12099 
f 13658 12099 12100 
f 13658 12100 12101 
f 13658 12101 12102 
f 13658 12102 12103 
f 13658 12103 12104 
f 13732 12104 12105 
f 13732 12105 12106 
f 13732 12106 12110 
f 13732 12110 12109 
f 13744 12112 12111 
f 13744 12113 12112 
f 13617 12114 12113 
f 13617 12115 12114 
f 13617 12116 12115 
f 13617 12117 12116 
f 13786 12118 12117 
f 13786 12119 12118 
f 13658 12120 12119 
f 13658 12121 12120 
f 13658 12122 12121 
f 13658 12123 12122 
f 13658 12124 12123 
f 13658 12125 12124 
f 13732 12126 12125 
f 13732 12127 12126 
f 13732 12128 12127 
f 13732 12111 12128 
f 12129 12147 12145 
f 12130 12129 12145 
f 12131 12130 12145 
f 12132 12131 12145 
f 12133 12132 12145 
f 12134 12133 12145 
f 12135 12134 12145 
f 12136 12135 12145 
f 12137 12136 12145 
f 12138 12137 12145 
f 12139 12138 12145 
f 12140 12139 12145 
f 12141 12140 12145 
f 12142 12141 12145 
f 12143 12142 12145 
f 12144 12143 12145 
f 12148 12144 12145 
f 12147 12148 12145 
f 12149 12150 12146 
f 12150 12151 12146 
f 12151 12152 12146 
f 12152 12153 12146 
f 12153 12154 12146 
f 12154 12155 12146 
f 12155 12156 12146 
f 12156 12157 12146 
f 12157 12158 12146 
f 12158 12159 12146 
f 12159 12160 12146 
f 12160 12161 12146 
f 12161 12162 12146 
f 12162 12163 12146 
f 12163 12164 12146 
f 12164 12165 12146 
f 12165 12166 12146 
f 12166 12149 12146 
f 13661 12147 12129 
f 13661 12129 12130 
f 13784 12130 12131 
f 13784 12131 12132 
f 13703 12132 12133 
f 13703 12133 12134 
f 13599 12134 12135 
f 13599 12135 12136 
f 13599 12136 12137 
f 13599 12137 12138 
f 13599 12138 12139 
f 13599 12139 12140 
f 13599 12140 12141 
f 13776 12141 12142 
f 13776 12142 12143 
f 13661 12143 12144 
f 13661 12144 12148 
f 13661 12148 12147 
f 13661 12150 12149 
f 13784 12151 12150 
f 13784 12152 12151 
f 13703 12153 12152 
f 13703 12154 12153 
f 13599 12155 12154 
f 13599 12156 12155 
f 13599 12157 12156 
f 13599 12158 12157 
f 13599 12159 12158 
f 13599 12160 12159 
f 13599 12161 12160 
f 13776 12162 12161 
f 13776 12163 12162 
f 13661 12164 12163 
f 13661 12165 12164 
f 13661 12166 12165 
f 13661 12149 12166 
f 12167 12185 12183 
f 12168 12167 12183 
f 12169 12168 12183 
f 12170 12169 12183 
f 12171 12170 12183 
f 12172 12171 12183 
f 12173 12172 12183 
f 12174 12173 12183 
f 12175 12174 12183 
f 12176 12175 12183 
f 12177 12176 12183 
f 12178 12177 12183 
f 12179 12178 12183 
f 12180 12179 12183 
f 12181 12180 12183 
f 12182 12181 12183 
f 12186 12182 12183 
f 12185 12186 12183 
f 12187 12188 12184 
f 12188 12189 12184 
f 12189 12190 12184 
f 12190 12191 12184 
f 12191 12192 12184 
f 12192 12193 12184 
f 12193 12194 12184 
f 12194 12195 12184 
f 12195 12196 12184 
f 12196 12197 12184 
f 12197 12198 12184 
f 12198 12199 12184 
f 12199 12200 12184 
f 12200 12201 12184 
f 12201 12202 12184 
f 12202 12203 12184 
f 12203 12204 12184 
f 12204 12187 12184 
f 13680 12185 12167 
f 13680 12167 12168 
f 13752 12168 12169 
f 13752 12169 12170 
f 13785 12170 12171 
f 13785 12171 12172 
f 13785 12172 12173 
f 13605 12173 12174 
f 13605 12174 12175 
f 13605 12175 12176 
f 13605 12176 12177 
f 13605 12177 12178 
f 13605 12178 12179 
f 13680 12179 12180 
f 13680 12180 12181 
f 13680 12181 12182 
f 13680 12182 12186 
f 13680 12186 12185 
f 13680 12188 12187 
f 13752 12189 12188 
f 13752 12190 12189 
f 13785 12191 12190 
f 13785 12192 12191 
f 13605 12193 12192 
f 13605 12194 12193 
f 13605 12195 12194 
f 13605 12196 12195 
f 13605 12197 12196 
f 13605 12198 12197 
f 13605 12199 12198 
f 13680 12200 12199 
f 13680 12201 12200 
f 13680 12202 12201 
f 13680 12203 12202 
f 13680 12204 12203 
f 13680 12187 12204 
f 12205 12223 12221 
f 12206 12205 12221 
f 12207 12206 12221 
f 12208 12207 12221 
f 12209 12208 12221 
f 12210 12209 12221 
f 12211 12210 12221 
f 12212 12211 12221 
f 12213 12212 12221 
f 12214 12213 12221 
f 12215 12214 12221 
f 12216 12215 12221 
f 12217 12216 12221 
f 12218 12217 12221 
f 12219 12218 12221 
f 12220 12219 12221 
f 12224 12220 12221 
f 12223 12224 12221 
f 12225 12226 12222 
f 12226 12227 12222 
f 12227 12228 12222 
f 12228 12229 12222 
f 12229 12230 12222 
f 12230 12231 12222 
f 12231 12232 12222 
f 12232 12233 12222 
f 12233 12234 12222 
f 12234 12235 12222 
f 12235 12236 12222 
f 12236 12237 12222 
f 12237 12238 12222 
f 12238 12239 12222 
f 12239 12240 12222 
f 12240 12241 12222 
f 12241 12242 12222 
f 12242 12225 12222 
f 13594 12223 12205 
f 13797 12205 12206 
f 13797 12206 12207 
f 13669 12207 12208 
f 13669 12208 12209 
f 13669 12209 12210 
f 13669 12210 12211 
f 13745 12211 12212 
f 13745 12212 12213 
f 13745 12213 12214 
f 13745 12214 12215 
f 13745 12215 12216 
f 13745 12216 12217 
f 13594 12217 12218 
f 13594 12218 12219 
f 13594 12219 12220 
f 13594 12220 12224 
f 13594 12224 12223 
f 13594 12226 12225 
f 13594 12227 12226 
f 13669 12228 12227 
f 13669 12229 12228 
f 13669 12230 12229 
f 13669 12231 12230 
f 13669 12232 12231 
f 13745 12233 12232 
f 13745 12234 12233 
f 13745 12235 12234 
f 13745 12236 12235 
f 13594 12237 12236 
f 13594 12238 12237 
f 13594 12239 12238 
f 13594 12240 12239 
f 13594 12241 12240 
f 13594 12242 12241 
f 13594 12225 12242 
f 12243 12261 12259 
f 12244 12243 12259 
f 12245 12244 12259 
f 12246 12245 12259 
f 12247 12246 12259 
f 12248 12247 12259 
f 12249 12248 12259 
f 12250 12249 12259 
f 12251 12250 12259 
f 12252 12251 12259 
f 12253 12252 12259 
f 12254 12253 12259 
f 12255 12254 12259 
f 12256 12255 12259 
f 12257 12256 12259 
f 12258 12257 12259 
f 12262 12258 12259 
f 12261 12262 12259 
f 12263 12264 12260 
f 12264 12265 12260 
f 12265 12266 12260 
f 12266 12267 12260 
f 12267 12268 12260 
f 12268 12269 12260 
f 12269 12270 12260 
f 12270 12271 12260 
f 12271 12272 12260 
f 12272 12273 12260 
f 12273 12274 12260 
f 12274 12275 12260 
f 12275 12276 12260 
f 12276 12277 12260 
f 12277 12278 12260 
f 12278 12279 12260 
f 12279 12280 12260 
f 12280 12263 12260 
f 13604 12261 12243 
f 13604 12243 12244 
f 13604 12244 12245 
f 13604 12245 12246 
f 13604 12246 12247 
f 13604 12247 12248 
f 13604 12248 12249 
f 13604 12249 12250 
f 13604 12250 12251 
f 13604 12251 12252 
f 13701 12252 12253 
f 13701 12253 12254 
f 13701 12254 12255 
f 13701 12255 12256 
f 13701 12256 12257 
f 13701 12257 12258 
f 13701 12258 12262 
f 13701 12262 12261 
f 13604 12264 12263 
f 13604 12265 12264 
f 13604 12266 12265 
f 13604 12267 12266 
f 13604 12268 12267 
f 13604 12269 12268 
f 13604 12270 12269 
f 13604 12271 12270 
f 13604 12272 12271 
f 13604 12273 12272 
f 13701 12274 12273 
f 13701 12275 12274 
f 13701 12276 12275 
f 13701 12277 12276 
f 13701 12278 12277 
f 13701 12279 12278 
f 13701 12280 12279 
f 13701 12263 12280 
f 12281 12299 12297 
f 12282 12281 12297 
f 12283 12282 12297 
f 12284 12283 12297 
f 12285 12284 12297 
f 12286 12285 12297 
f 12287 12286 12297 
f 12288 12287 12297 
f 12289 12288 12297 
f 12290 12289 12297 
f 12291 12290 12297 
f 12292 12291 12297 
f 12293 12292 12297 
f 12294 12293 12297 
f 12295 12294 12297 
f 12296 12295 12297 
f 12300 12296 12297 
f 12299 12300 12297 
f 12301 12302 12298 
f 12302 12303 12298 
f 12303 12304 12298 
f 12304 12305 12298 
f 12305 12306 12298 
f 12306 12307 12298 
f 12307 12308 12298 
f 12308 12309 12298 
f 12309 12310 12298 
f 12310 12311 12298 
f 12311 12312 12298 
f 12312 12313 12298 
f 12313 12314 12298 
f 12314 12315 12298 
f 12315 12316 12298 
f 12316 12317 12298 
f 12317 12318 12298 
f 12318 12301 12298 
f 12319 12299 12281 
f 12319 12281 12282 
f 13704 12282 12283 
f 13704 12283 12284 
f 13704 12284 12285 
f 13704 12285 12286 
f 13704 12286 12287 
f 13704 12287 12288 
f 12319 12288 12289 
f 12319 12289 12290 
f 12319 12290 12291 
f 12319 12291 12292 
f 12319 12292 12293 
f 12319 12293 12294 
f 12319 12294 12295 
f 12319 12295 12296 
f 12319 12296 12300 
f 12319 12300 12299 
f 12319 12302 12301 
f 12319 12303 12302 
f 13704 12304 12303 
f 13704 12305 12304 
f 13704 12306 12305 
f 13704 12307 12306 
f 12319 12308 12307 
f 12319 12309 12308 
f 12319 12310 12309 
f 12319 12311 12310 
f 12319 12312 12311 
f 12319 12313 12312 
f 12319 12314 12313 
f 12319 12315 12314 
f 12319 12316 12315 
f 12319 12317 12316 
f 12319 12318 12317 
f 12319 12301 12318 
f 12320 12338 12336 
f 12321 12320 12336 
f 12322 12321 12336 
f 12323 12322 12336 
f 12324 12323 12336 
f 12325 12324 12336 
f 12326 12325 12336 
f 12327 12326 12336 
f 12328 12327 12336 
f 12329 12328 12336 
f 12330 12329 12336 
f 12331 12330 12336 
f 12332 12331 12336 
f 12333 12332 12336 
f 12334 12333 12336 
f 12335 12334 12336 
f 12339 12335 12336 
f 12338 12339 12336 
f 12340 12341 12337 
f 12341 12342 12337 
f 12342 12343 12337 
f 12343 12344 12337 
f 12344 12345 12337 
f 12345 12346 12337 
f 12346 12347 12337 
f 12347 12348 12337 
f 12348 12349 12337 
f 12349 12350 12337 
f 12350 12351 12337 
f 12351 12352 12337 
f 12352 12353 12337 
f 12353 12354 12337 
f 12354 12355 12337 
f 12355 12356 12337 
f 12356 12357 12337 
f 12357 12340 12337 
f 13672 12338 12320 
f 13672 12320 12321 
f 13672 12321 12322 
f 13672 12322 12323 
f 13672 12323 12324 
f 13672 12324 12325 
f 13672 12325 12326 
f 13780 12326 12327 
f 13780 12327 12328 
f 13596 12328 12329 
f 13596 12329 12330 
f 13596 12330 12331 
f 13596 12331 12332 
f 13596 12332 12333 
f 13596 12333 12334 
f 13596 12334 12335 
f 13596 12335 12339 
f 13596 12339 12338 
f 13672 12341 12340 
f 13672 12342 12341 
f 13672 12343 12342 
f 13672 12344 12343 
f 13672 12345 12344 
f 13780 12346 12345 
f 13780 12347 12346 
f 13596 12348 12347 
f 13596 12349 12348 
f 13596 12350 12349 
f 13596 12351 12350 
f 13596 12352 12351 
f 13596 12353 12352 
f 13596 12354 12353 
f 13596 12355 12354 
f 13596 12356 12355 
f 13596 12357 12356 
f 13596 12340 12357 
f 12358 12376 12374 
f 12359 12358 12374 
f 12360 12359 12374 
f 12361 12360 12374 
f 12362 12361 12374 
f 12363 12362 12374 
f 12364 12363 12374 
f 12365 12364 12374 
f 12366 12365 12374 
f 12367 12366 12374 
f 12368 12367 12374 
f 12369 12368 12374 
f 12370 12369 12374 
f 12371 12370 12374 
f 12372 12371 12374 
f 12373 12372 12374 
f 12377 12373 12374 
f 12376 12377 12374 
f 12378 12379 12375 
f 12379 12380 12375 
f 12380 12381 12375 
f 12381 12382 12375 
f 12382 12383 12375 
f 12383 12384 12375 
f 12384 12385 12375 
f 12385 12386 12375 
f 12386 12387 12375 
f 12387 12388 12375 
f 12388 12389 12375 
f 12389 12390 12375 
f 12390 12391 12375 
f 12391 12392 12375 
f 12392 12393 12375 
f 12393 12394 12375 
f 12394 12395 12375 
f 12395 12378 12375 
f 13598 12376 12358 
f 13660 12358 12359 
f 13660 12359 12360 
f 13660 12360 12361 
f 13660 12361 12362 
f 13660 12362 12363 
f 13660 12363 12364 
f 13719 12364 12365 
f 13719 12365 12366 
f 13719 12366 12367 
f 13719 12367 12368 
f 13719 12368 12369 
f 13719 12369 12370 
f 13598 12370 12371 
f 13598 12371 12372 
f 13598 12372 12373 
f 13598 12373 12377 
f 13598 12377 12376 
f 13598 12379 12378 
f 13598 12380 12379 
f 13660 12381 12380 
f 13660 12382 12381 
f 13660 12383 12382 
f 13660 12384 12383 
f 13719 12385 12384 
f 13719 12386 12385 
f 13719 12387 12386 
f 13719 12388 12387 
f 13719 12389 12388 
f 13719 12390 12389 
f 13598 12391 12390 
f 13598 12392 12391 
f 13598 12393 12392 
f 13598 12394 12393 
f 13598 12395 12394 
f 13598 12378 12395 
f 12396 12414 12412 
f 12397 12396 12412 
f 12398 12397 12412 
f 12399 12398 12412 
f 12400 12399 12412 
f 12401 12400 12412 
f 12402 12401 12412 
f 12403 12402 12412 
f 12404 12403 12412 
f 12405 12404 12412 
f 12406 12405 12412 
f 12407 12406 12412 
f 12408 12407 12412 
f 12409 12408 12412 
f 12410 12409 12412 
f 12411 12410 12412 
f 12415 12411 12412 
f 12414 12415 12412 
f 12416 12417 12413 
f 12417 12418 12413 
f 12418 12419 12413 
f 12419 12420 12413 
f 12420 12421 12413 
f 12421 12422 12413 
f 12422 12423 12413 
f 12423 12424 12413 
f 12424 12425 12413 
f 12425 12426 12413 
f 12426 12427 12413 
f 12427 12428 12413 
f 12428 12429 12413 
f 12429 12430 12413 
f 12430 12431 12413 
f 12431 12432 12413 
f 12432 12433 12413 
f 12433 12416 12413 
f 13600 12414 12396 
f 13600 12396 12397 
f 13600 12397 12398 
f 13674 12398 12399 
f 13674 12399 12400 
f 13674 12400 12401 
f 13674 12401 12402 
f 13674 12402 12403 
f 13674 12403 12404 
f 13674 12404 12405 
f 13674 12405 12406 
f 13674 12406 12407 
f 13674 12407 12408 
f 13674 12408 12409 
f 13674 12409 12410 
f 13600 12410 12411 
f 13600 12411 12415 
f 13600 12415 12414 
f 13600 12417 12416 
f 13600 12418 12417 
f 13600 12419 12418 
f 13600 12420 12419 
f 13674 12421 12420 
f 13674 12422 12421 
f 13674 12423 12422 
f 13674 12424 12423 
f 13674 12425 12424 
f 13674 12426 12425 
f 13674 12427 12426 
f 13674 12428 12427 
f 13674 12429 12428 
f 13600 12430 12429 
f 13600 12431 12430 
f 13600 12432 12431 
f 13600 12433 12432 
f 13600 12416 12433 
f 12434 12452 12450 
f 12435 12434 12450 
f 12436 12435 12450 
f 12437 12436 12450 
f 12438 12437 12450 
f 12439 12438 12450 
f 12440 12439 12450 
f 12441 12440 12450 
f 12442 12441 12450 
f 12443 12442 12450 
f 12444 12443 12450 
f 12445 12444 12450 
f 12446 12445 12450 
f 12447 12446 12450 
f 12448 12447 12450 
f 12449 12448 12450 
f 12453 12449 12450 
f 12452 12453 12450 
f 12454 12455 12451 
f 12455 12456 12451 
f 12456 12457 12451 
f 12457 12458 12451 
f 12458 12459 12451 
f 12459 12460 12451 
f 12460 12461 12451 
f 12461 12462 12451 
f 12462 12463 12451 
f 12463 12464 12451 
f 12464 12465 12451 
f 12465 12466 12451 
f 12466 12467 12451 
f 12467 12468 12451 
f 12468 12469 12451 
f 12469 12470 12451 
f 12470 12471 12451 
f 12471 12454 12451 
f 13587 12452 12434 
f 13587 12434 12435 
f 13587 12435 12436 
f 13691 12436 12437 
f 13691 12437 12438 
f 13691 12438 12439 
f 13691 12439 12440 
f 13691 12440 12441 
f 13691 12441 12442 
f 13691 12442 12443 
f 13691 12443 12444 
f 13691 12444 12445 
f 13691 12445 12446 
f 13691 12446 12447 
f 13691 12447 12448 
f 13587 12448 12449 
f 13587 12449 12453 
f 13587 12453 12452 
f 13587 12455 12454 
f 13587 12456 12455 
f 13587 12457 12456 
f 13587 12458 12457 
f 13691 12459 12458 
f 13691 12460 12459 
f 13691 12461 12460 
f 13691 12462 12461 
f 13691 12463 12462 
f 13691 12464 12463 
f 13691 12465 12464 
f 13691 12466 12465 
f 13691 12467 12466 
f 13587 12468 12467 
f 13587 12469 12468 
f 13587 12470 12469 
f 13587 12471 12470 
f 13587 12454 12471 
f 12472 12490 12488 
f 12473 12472 12488 
f 12474 12473 12488 
f 12475 12474 12488 
f 12476 12475 12488 
f 12477 12476 12488 
f 12478 12477 12488 
f 12479 12478 12488 
f 12480 12479 12488 
f 12481 12480 12488 
f 12482 12481 12488 
f 12483 12482 12488 
f 12484 12483 12488 
f 12485 12484 12488 
f 12486 12485 12488 
f 12487 12486 12488 
f 12491 12487 12488 
f 12490 12491 12488 
f 12492 12493 12489 
f 12493 12494 12489 
f 12494 12495 12489 
f 12495 12496 12489 
f 12496 12497 12489 
f 12497 12498 12489 
f 12498 12499 12489 
f 12499 12500 12489 
f 12500 12501 12489 
f 12501 12502 12489 
f 12502 12503 12489 
f 12503 12504 12489 
f 12504 12505 12489 
f 12505 12506 12489 
f 12506 12507 12489 
f 12507 12508 12489 
f 12508 12509 12489 
f 12509 12492 12489 
f 13720 12490 12472 
f 13720 12472 12473 
f 13720 12473 12474 
f 13720 12474 12475 
f 13589 12475 12476 
f 13589 12476 12477 
f 13589 12477 12478 
f 13589 12478 12479 
f 13589 12479 12480 
f 13589 12480 12481 
f 13589 12481 12482 
f 13665 12482 12483 
f 13665 12483 12484 
f 13665 12484 12485 
f 13665 12485 12486 
f 13665 12486 12487 
f 13720 12487 12491 
f 13720 12491 12490 
f 13720 12493 12492 
f 13720 12494 12493 
f 13720 12495 12494 
f 13720 12496 12495 
f 13589 12497 12496 
f 13589 12498 12497 
f 13589 12499 12498 
f 13589 12500 12499 
f 13589 12501 12500 
f 13589 12502 12501 
f 13665 12503 12502 
f 13665 12504 12503 
f 13665 12505 12504 
f 13665 12506 12505 
f 13665 12507 12506 
f 13720 12508 12507 
f 13720 12509 12508 
f 13720 12492 12509 
f 12510 12528 12526 
f 12511 12510 12526 
f 12512 12511 12526 
f 12513 12512 12526 
f 12514 12513 12526 
f 12515 12514 12526 
f 12516 12515 12526 
f 12517 12516 12526 
f 12518 12517 12526 
f 12519 12518 12526 
f 12520 12519 12526 
f 12521 12520 12526 
f 12522 12521 12526 
f 12523 12522 12526 
f 12524 12523 12526 
f 12525 12524 12526 
f 12529 12525 12526 
f 12528 12529 12526 
f 12530 12531 12527 
f 12531 12532 12527 
f 12532 12533 12527 
f 12533 12534 12527 
f 12534 12535 12527 
f 12535 12536 12527 
f 12536 12537 12527 
f 12537 12538 12527 
f 12538 12539 12527 
f 12539 12540 12527 
f 12540 12541 12527 
f 12541 12542 12527 
f 12542 12543 12527 
f 12543 12544 12527 
f 12544 12545 12527 
f 12545 12546 12527 
f 12546 12547 12527 
f 12547 12530 12527 
f 13707 12528 12510 
f 13707 12510 12511 
f 13579 12511 12512 
f 13579 12512 12513 
f 13579 12513 12514 
f 13579 12514 12515 
f 13579 12515 12516 
f 13762 12516 12517 
f 13762 12517 12518 
f 13655 12518 12519 
f 13655 12519 12520 
f 13655 12520 12521 
f 13655 12521 12522 
f 13707 12522 12523 
f 13707 12523 12524 
f 13707 12524 12525 
f 13707 12525 12529 
f 13707 12529 12528 
f 13707 12531 12530 
f 13579 12532 12531 
f 13579 12533 12532 
f 13579 12534 12533 
f 13579 12535 12534 
f 13579 12536 12535 
f 13762 12537 12536 
f 13762 12538 12537 
f 13655 12539 12538 
f 13655 12540 12539 
f 13655 12541 12540 
f 13655 12542 12541 
f 13655 12543 12542 
f 13707 12544 12543 
f 13707 12545 12544 
f 13707 12546 12545 
f 13707 12547 12546 
f 13707 12530 12547 
f 12548 12566 12564 
f 12549 12548 12564 
f 12550 12549 12564 
f 12551 12550 12564 
f 12552 12551 12564 
f 12553 12552 12564 
f 12554 12553 12564 
f 12555 12554 12564 
f 12556 12555 12564 
f 12557 12556 12564 
f 12558 12557 12564 
f 12559 12558 12564 
f 12560 12559 12564 
f 12561 12560 12564 
f 12562 12561 12564 
f 12563 12562 12564 
f 12567 12563 12564 
f 12566 12567 12564 
f 12568 12569 12565 
f 12569 12570 12565 
f 12570 12571 12565 
f 12571 12572 12565 
f 12572 12573 12565 
f 12573 12574 12565 
f 12574 12575 12565 
f 12575 12576 12565 
f 12576 12577 12565 
f 12577 12578 12565 
f 12578 12579 12565 
f 12579 12580 12565 
f 12580 12581 12565 
f 12581 12582 12565 
f 12582 12583 12565 
f 12583 12584 12565 
f 12584 12585 12565 
f 12585 12568 12565 
f 13710 12566 12548 
f 13710 12548 12549 
f 13644 12549 12550 
f 13644 12550 12551 
f 13644 12551 12552 
f 13644 12552 12553 
f 13644 12553 12554 
f 13702 12554 12555 
f 13702 12555 12556 
f 13802 12556 12557 
f 13802 12557 12558 
f 12586 12558 12559 
f 12586 12559 12560 
f 12586 12560 12561 
f 12586 12561 12562 
f 12586 12562 12563 
f 13710 12563 12567 
f 13710 12567 12566 
f 13710 12569 12568 
f 13644 12570 12569 
f 13644 12571 12570 
f 13644 12572 12571 
f 13644 12573 12572 
f 13644 12574 12573 
f 13702 12575 12574 
f 13702 12576 12575 
f 13802 12577 12576 
f 13802 12578 12577 
f 12586 12579 12578 
f 12586 12580 12579 
f 12586 12581 12580 
f 12586 12582 12581 
f 12586 12583 12582 
f 13710 12584 12583 
f 13710 12585 12584 
f 13710 12568 12585 
f 12587 12605 12603 
f 12588 12587 12603 
f 12589 12588 12603 
f 12590 12589 12603 
f 12591 12590 12603 
f 12592 12591 12603 
f 12593 12592 12603 
f 12594 12593 12603 
f 12595 12594 12603 
f 12596 12595 12603 
f 12597 12596 12603 
f 12598 12597 12603 
f 12599 12598 12603 
f 12600 12599 12603 
f 12601 12600 12603 
f 12602 12601 12603 
f 12606 12602 12603 
f 12605 12606 12603 
f 12607 12608 12604 
f 12608 12609 12604 
f 12609 12610 12604 
f 12610 12611 12604 
f 12611 12612 12604 
f 12612 12613 12604 
f 12613 12614 12604 
f 12614 12615 12604 
f 12615 12616 12604 
f 12616 12617 12604 
f 12617 12618 12604 
f 12618 12619 12604 
f 12619 12620 12604 
f 12620 12621 12604 
f 12621 12622 12604 
f 12622 12623 12604 
f 12623 12624 12604 
f 12624 12607 12604 
f 13684 12605 12587 
f 13768 12587 12588 
f 13768 12588 12589 
f 13628 12589 12590 
f 13628 12590 12591 
f 13628 12591 12592 
f 13628 12592 12593 
f 13628 12593 12594 
f 13628 12594 12595 
f 13628 12595 12596 
f 13628 12596 12597 
f 13739 12597 12598 
f 13739 12598 12599 
f 13595 12599 12600 
f 13595 12600 12601 
f 13595 12601 12602 
f 13595 12602 12606 
f 13684 12606 12605 
f 13684 12608 12607 
f 13768 12609 12608 
f 13768 12610 12609 
f 13628 12611 12610 
f 13628 12612 12611 
f 13628 12613 12612 
f 13628 12614 12613 
f 13628 12615 12614 
f 13628 12616 12615 
f 13628 12617 12616 
f 13739 12618 12617 
f 13739 12619 12618 
f 13595 12620 12619 
f 13595 12621 12620 
f 13595 12622 12621 
f 13595 12623 12622 
f 13595 12624 12623 
f 13684 12607 12624 
f 12625 12643 12641 
f 12626 12625 12641 
f 12627 12626 12641 
f 12628 12627 12641 
f 12629 12628 12641 
f 12630 12629 12641 
f 12631 12630 12641 
f 12632 12631 12641 
f 12633 12632 12641 
f 12634 12633 12641 
f 12635 12634 12641 
f 12636 12635 12641 
f 12637 12636 12641 
f 12638 12637 12641 
f 12639 12638 12641 
f 12640 12639 12641 
f 12644 12640 12641 
f 12643 12644 12641 
f 12645 12646 12642 
f 12646 12647 12642 
f 12647 12648 12642 
f 12648 12649 12642 
f 12649 12650 12642 
f 12650 12651 12642 
f 12651 12652 12642 
f 12652 12653 12642 
f 12653 12654 12642 
f 12654 12655 12642 
f 12655 12656 12642 
f 12656 12657 12642 
f 12657 12658 12642 
f 12658 12659 12642 
f 12659 12660 12642 
f 12660 12661 12642 
f 12661 12662 12642 
f 12662 12645 12642 
f 13689 12643 12625 
f 13769 12625 12626 
f 13769 12626 12627 
f 13636 12627 12628 
f 13636 12628 12629 
f 13636 12629 12630 
f 13636 12630 12631 
f 13726 12631 12632 
f 13726 12632 12633 
f 13726 12633 12634 
f 13726 12634 12635 
f 13747 12635 12636 
f 13747 12636 12637 
f 13590 12637 12638 
f 13590 12638 12639 
f 13590 12639 12640 
f 13590 12640 12644 
f 13689 12644 12643 
f 13689 12646 12645 
f 13769 12647 12646 
f 13769 12648 12647 
f 13636 12649 12648 
f 13636 12650 12649 
f 13636 12651 12650 
f 13726 12652 12651 
f 13726 12653 12652 
f 13726 12654 12653 
f 13726 12655 12654 
f 13747 12656 12655 
f 13747 12657 12656 
f 13590 12658 12657 
f 13590 12659 12658 
f 13590 12660 12659 
f 13590 12661 12660 
f 13590 12662 12661 
f 13689 12645 12662 
f 12663 12681 12679 
f 12664 12663 12679 
f 12665 12664 12679 
f 12666 12665 12679 
f 12667 12666 12679 
f 12668 12667 12679 
f 12669 12668 12679 
f 12670 12669 12679 
f 12671 12670 12679 
f 12672 12671 12679 
f 12673 12672 12679 
f 12674 12673 12679 
f 12675 12674 12679 
f 12676 12675 12679 
f 12677 12676 12679 
f 12678 12677 12679 
f 12682 12678 12679 
f 12681 12682 12679 
f 12683 12684 12680 
f 12684 12685 12680 
f 12685 12686 12680 
f 12686 12687 12680 
f 12687 12688 12680 
f 12688 12689 12680 
f 12689 12690 12680 
f 12690 12691 12680 
f 12691 12692 12680 
f 12692 12693 12680 
f 12693 12694 12680 
f 12694 12695 12680 
f 12695 12696 12680 
f 12696 12697 12680 
f 12697 12698 12680 
f 12698 12699 12680 
f 12699 12700 12680 
f 12700 12683 12680 
f 13711 12681 12663 
f 13794 12663 12664 
f 13794 12664 12665 
f 13614 12665 12666 
f 13614 12666 12667 
f 13614 12667 12668 
f 13614 12668 12669 
f 13614 12669 12670 
f 13614 12670 12671 
f 13662 12671 12672 
f 13662 12672 12673 
f 13662 12673 12674 
f 13662 12674 12675 
f 13662 12675 12676 
f 13662 12676 12677 
f 13662 12677 12678 
f 13662 12678 12682 
f 13711 12682 12681 
f 13711 12684 12683 
f 13794 12685 12684 
f 13794 12686 12685 
f 13614 12687 12686 
f 13614 12688 12687 
f 13614 12689 12688 
f 13614 12690 12689 
f 13614 12691 12690 
f 13662 12692 12691 
f 13662 12693 12692 
f 13662 12694 12693 
f 13662 12695 12694 
f 13662 12696 12695 
f 13662 12697 12696 
f 13662 12698 12697 
f 13662 12699 12698 
f 13662 12700 12699 
f 13711 12683 12700 
f 12701 12719 12717 
f 12702 12701 12717 
f 12703 12702 12717 
f 12704 12703 12717 
f 12705 12704 12717 
f 12706 12705 12717 
f 12707 12706 12717 
f 12708 12707 12717 
f 12709 12708 12717 
f 12710 12709 12717 
f 12711 12710 12717 
f 12712 12711 12717 
f 12713 12712 12717 
f 12714 12713 12717 
f 12715 12714 12717 
f 12716 12715 12717 
f 12720 12716 12717 
f 12719 12720 12717 
f 12721 12722 12718 
f 12722 12723 12718 
f 12723 12724 12718 
f 12724 12725 12718 
f 12725 12726 12718 
f 12726 12727 12718 
f 12727 12728 12718 
f 12728 12729 12718 
f 12729 12730 12718 
f 12730 12731 12718 
f 12731 12732 12718 
f 12732 12733 12718 
f 12733 12734 12718 
f 12734 12735 12718 
f 12735 12736 12718 
f 12736 12737 12718 
f 12737 12738 12718 
f 12738 12721 12718 
f 13641 12719 12701 
f 13641 12701 12702 
f 13641 12702 12703 
f 13760 12703 12704 
f 13760 12704 12705 
f 13668 12705 12706 
f 13668 12706 12707 
f 13668 12707 12708 
f 13668 12708 12709 
f 13612 12709 12710 
f 13612 12710 12711 
f 13612 12711 12712 
f 13612 12712 12713 
f 13612 12713 12714 
f 13612 12714 12715 
f 13727 12715 12716 
f 13727 12716 12720 
f 13641 12720 12719 
f 13641 12722 12721 
f 13641 12723 12722 
f 13641 12724 12723 
f 13641 12725 12724 
f 13760 12726 12725 
f 13760 12727 12726 
f 13668 12728 12727 
f 13668 12729 12728 
f 13612 12730 12729 
f 13612 12731 12730 
f 13612 12732 12731 
f 13612 12733 12732 
f 13612 12734 12733 
f 13612 12735 12734 
f 13727 12736 12735 
f 13727 12737 12736 
f 13641 12738 12737 
f 13641 12721 12738 
f 12739 12757 12755 
f 12740 12739 12755 
f 12741 12740 12755 
f 12742 12741 12755 
f 12743 12742 12755 
f 12744 12743 12755 
f 12745 12744 12755 
f 12746 12745 12755 
f 12747 12746 12755 
f 12748 12747 12755 
f 12749 12748 12755 
f 12750 12749 12755 
f 12751 12750 12755 
f 12752 12751 12755 
f 12753 12752 12755 
f 12754 12753 12755 
f 12758 12754 12755 
f 12757 12758 12755 
f 12759 12760 12756 
f 12760 12761 12756 
f 12761 12762 12756 
f 12762 12763 12756 
f 12763 12764 12756 
f 12764 12765 12756 
f 12765 12766 12756 
f 12766 12767 12756 
f 12767 12768 12756 
f 12768 12769 12756 
f 12769 12770 12756 
f 12770 12771 12756 
f 12771 12772 12756 
f 12772 12773 12756 
f 12773 12774 12756 
f 12774 12775 12756 
f 12775 12776 12756 
f 12776 12759 12756 
f 13705 12757 12739 
f 13729 12739 12740 
f 13729 12740 12741 
f 13651 12741 12742 
f 13651 12742 12743 
f 13651 12743 12744 
f 13651 12744 12745 
f 13651 12745 12746 
f 13651 12746 12747 
f 13584 12747 12748 
f 13584 12748 12749 
f 13584 12749 12750 
f 13584 12750 12751 
f 13584 12751 12752 
f 13584 12752 12753 
f 13705 12753 12754 
f 13705 12754 12758 
f 13705 12758 12757 
f 13705 12760 12759 
f 13705 12761 12760 
f 13729 12762 12761 
f 13729 12763 12762 
f 13651 12764 12763 
f 13651 12765 12764 
f 13651 12766 12765 
f 13651 12767 12766 
f 13584 12768 12767 
f 13584 12769 12768 
f 13584 12770 12769 
f 13584 12771 12770 
f 13584 12772 12771 
f 13584 12773 12772 
f 13584 12774 12773 
f 13584 12775 12774 
f 13705 12776 12775 
f 13705 12759 12776 
f 12777 12795 12793 
f 12778 12777 12793 
f 12779 12778 12793 
f 12780 12779 12793 
f 12781 12780 12793 
f 12782 12781 12793 
f 12783 12782 12793 
f 12784 12783 12793 
f 12785 12784 12793 
f 12786 12785 12793 
f 12787 12786 12793 
f 12788 12787 12793 
f 12789 12788 12793 
f 12790 12789 12793 
f 12791 12790 12793 
f 12792 12791 12793 
f 12796 12792 12793 
f 12795 12796 12793 
f 12797 12798 12794 
f 12798 12799 12794 
f 12799 12800 12794 
f 12800 12801 12794 
f 12801 12802 12794 
f 12802 12803 12794 
f 12803 12804 12794 
f 12804 12805 12794 
f 12805 12806 12794 
f 12806 12807 12794 
f 12807 12808 12794 
f 12808 12809 12794 
f 12809 12810 12794 
f 12810 12811 12794 
f 12811 12812 12794 
f 12812 12813 12794 
f 12813 12814 12794 
f 12814 12797 12794 
f 13654 12795 12777 
f 13725 12777 12778 
f 13725 12778 12779 
f 13792 12779 12780 
f 13792 12780 12781 
f 13792 12781 12782 
f 13615 12782 12783 
f 13615 12783 12784 
f 13615 12784 12785 
f 13615 12785 12786 
f 13712 12786 12787 
f 13712 12787 12788 
f 13712 12788 12789 
f 13712 12789 12790 
f 13654 12790 12791 
f 13654 12791 12792 
f 13654 12792 12796 
f 13654 12796 12795 
f 13725 12798 12797 
f 13725 12799 12798 
f 13792 12800 12799 
f 13792 12801 12800 
f 13615 12802 12801 
f 13615 12803 12802 
f 13615 12804 12803 
f 13615 12805 12804 
f 13615 12806 12805 
f 13615 12807 12806 
f 13712 12808 12807 
f 13712 12809 12808 
f 13712 12810 12809 
f 13712 12811 12810 
f 13654 12812 12811 
f 13654 12813 12812 
f 13654 12814 12813 
f 13654 12797 12814 
f 12815 12833 12831 
f 12816 12815 12831 
f 12817 12816 12831 
f 12818 12817 12831 
f 12819 12818 12831 
f 12820 12819 12831 
f 12821 12820 12831 
f 12822 12821 12831 
f 12823 12822 12831 
f 12824 12823 12831 
f 12825 12824 12831 
f 12826 12825 12831 
f 12827 12826 12831 
f 12828 12827 12831 
f 12829 12828 12831 
f 12830 12829 12831 
f 12834 12830 12831 
f 12833 12834 12831 
f 12835 12836 12832 
f 12836 12837 12832 
f 12837 12838 12832 
f 12838 12839 12832 
f 12839 12840 12832 
f 12840 12841 12832 
f 12841 12842 12832 
f 12842 12843 12832 
f 12843 12844 12832 
f 12844 12845 12832 
f 12845 12846 12832 
f 12846 12847 12832 
f 12847 12848 12832 
f 12848 12849 12832 
f 12849 12850 12832 
f 12850 12851 12832 
f 12851 12852 12832 
f 12852 12835 12832 
f 13695 12833 12815 
f 13695 12815 12816 
f 13695 12816 12817 
f 13754 12817 12818 
f 13754 12818 12819 
f 13754 12819 12820 
f 13582 12820 12821 
f 13582 12821 12822 
f 13582 12822 12823 
f 13582 12823 12824 
f 13582 12824 12825 
f 13582 12825 12826 
f 13652 12826 12827 
f 13652 12827 12828 
f 13652 12828 12829 
f 13652 12829 12830 
f 13652 12830 12834 
f 13695 12834 12833 
f 13695 12836 12835 
f 13695 12837 12836 
f 13695 12838 12837 
f 13754 12839 12838 
f 13754 12840 12839 
f 13582 12841 12840 
f 13582 12842 12841 
f 13582 12843 12842 
f 13582 12844 12843 
f 13582 12845 12844 
f 13582 12846 12845 
f 13652 12847 12846 
f 13652 12848 12847 
f 13652 12849 12848 
f 13652 12850 12849 
f 13652 12851 12850 
f 13652 12852 12851 
f 13695 12835 12852 
f 12853 12871 12869 
f 12854 12853 12869 
f 12855 12854 12869 
f 12856 12855 12869 
f 12857 12856 12869 
f 12858 12857 12869 
f 12859 12858 12869 
f 12860 12859 12869 
f 12861 12860 12869 
f 12862 12861 12869 
f 12863 12862 12869 
f 12864 12863 12869 
f 12865 12864 12869 
f 12866 12865 12869 
f 12867 12866 12869 
f 12868 12867 12869 
f 12872 12868 12869 
f 12871 12872 12869 
f 12873 12874 12870 
f 12874 12875 12870 
f 12875 12876 12870 
f 12876 12877 12870 
f 12877 12878 12870 
f 12878 12879 12870 
f 12879 12880 12870 
f 12880 12881 12870 
f 12881 12882 12870 
f 12882 12883 12870 
f 12883 12884 12870 
f 12884 12885 12870 
f 12885 12886 12870 
f 12886 12887 12870 
f 12887 12888 12870 
f 12888 12889 12870 
f 12889 12890 12870 
f 12890 12873 12870 
f 13633 12871 12853 
f 13606 12853 12854 
f 13606 12854 12855 
f 13606 12855 12856 
f 13606 12856 12857 
f 13606 12857 12858 
f 13753 12858 12859 
f 13753 12859 12860 
f 13678 12860 12861 
f 13678 12861 12862 
f 13678 12862 12863 
f 13678 12863 12864 
f 13633 12864 12865 
f 13633 12865 12866 
f 13633 12866 12867 
f 13633 12867 12868 
f 13633 12868 12872 
f 13633 12872 12871 
f 13606 12874 12873 
f 13606 12875 12874 
f 13606 12876 12875 
f 13606 12877 12876 
f 13606 12878 12877 
f 13753 12879 12878 
f 13753 12880 12879 
f 13678 12881 12880 
f 13678 12882 12881 
f 13678 12883 12882 
f 13678 12884 12883 
f 13633 12885 12884 
f 13633 12886 12885 
f 13633 12887 12886 
f 13633 12888 12887 
f 13633 12889 12888 
f 13633 12890 12889 
f 13633 12873 12890 
f 12891 12909 12907 
f 12892 12891 12907 
f 12893 12892 12907 
f 12894 12893 12907 
f 12895 12894 12907 
f 12896 12895 12907 
f 12897 12896 12907 
f 12898 12897 12907 
f 12899 12898 12907 
f 12900 12899 12907 
f 12901 12900 12907 
f 12902 12901 12907 
f 12903 12902 12907 
f 12904 12903 12907 
f 12905 12904 12907 
f 12906 12905 12907 
f 12910 12906 12907 
f 12909 12910 12907 
f 12911 12912 12908 
f 12912 12913 12908 
f 12913 12914 12908 
f 12914 12915 12908 
f 12915 12916 12908 
f 12916 12917 12908 
f 12917 12918 12908 
f 12918 12919 12908 
f 12919 12920 12908 
f 12920 12921 12908 
f 12921 12922 12908 
f 12922 12923 12908 
f 12923 12924 12908 
f 12924 12925 12908 
f 12925 12926 12908 
f 12926 12927 12908 
f 12927 12928 12908 
f 12928 12911 12908 
f 13667 12909 12891 
f 13638 12891 12892 
f 13638 12892 12893 
f 13638 12893 12894 
f 13638 12894 12895 
f 13638 12895 12896 
f 13638 12896 12897 
f 13583 12897 12898 
f 13583 12898 12899 
f 13583 12899 12900 
f 13583 12900 12901 
f 13756 12901 12902 
f 13756 12902 12903 
f 13731 12903 12904 
f 13731 12904 12905 
f 13731 12905 12906 
f 13731 12906 12910 
f 13667 12910 12909 
f 13638 12912 12911 
f 13638 12913 12912 
f 13638 12914 12913 
f 13638 12915 12914 
f 13638 12916 12915 
f 13638 12917 12916 
f 13638 12918 12917 
f 13583 12919 12918 
f 13583 12920 12919 
f 13583 12921 12920 
f 13583 12922 12921 
f 13756 12923 12922 
f 13756 12924 12923 
f 13731 12925 12924 
f 13731 12926 12925 
f 13731 12927 12926 
f 13731 12928 12927 
f 13731 12911 12928 
f 12929 12947 12945 
f 12930 12929 12945 
f 12931 12930 12945 
f 12932 12931 12945 
f 12933 12932 12945 
f 12934 12933 12945 
f 12935 12934 12945 
f 12936 12935 12945 
f 12937 12936 12945 
f 12938 12937 12945 
f 12939 12938 12945 
f 12940 12939 12945 
f 12941 12940 12945 
f 12942 12941 12945 
f 12943 12942 12945 
f 12944 12943 12945 
f 12948 12944 12945 
f 12947 12948 12945 
f 12949 12950 12946 
f 12950 12951 12946 
f 12951 12952 12946 
f 12952 12953 12946 
f 12953 12954 12946 
f 12954 12955 12946 
f 12955 12956 12946 
f 12956 12957 12946 
f 12957 12958 12946 
f 12958 12959 12946 
f 12959 12960 12946 
f 12960 12961 12946 
f 12961 12962 12946 
f 12962 12963 12946 
f 12963 12964 12946 
f 12964 12965 12946 
f 12965 12966 12946 
f 12966 12949 12946 
f 13761 12947 12929 
f 13761 12929 12930 
f 13782 12930 12931 
f 13782 12931 12932 
f 13677 12932 12933 
f 13677 12933 12934 
f 13677 12934 12935 
f 13619 12935 12936 
f 13619 12936 12937 
f 13619 12937 12938 
f 13619 12938 12939 
f 13798 12939 12940 
f 13798 12940 12941 
f 13718 12941 12942 
f 13718 12942 12943 
f 13647 12943 12944 
f 13647 12944 12948 
f 13647 12948 12947 
f 13761 12950 12949 
f 13761 12951 12950 
f 13782 12952 12951 
f 13782 12953 12952 
f 13677 12954 12953 
f 13677 12955 12954 
f 13619 12956 12955 
f 13619 12957 12956 
f 13619 12958 12957 
f 13619 12959 12958 
f 13798 12960 12959 
f 13798 12961 12960 
f 13718 12962 12961 
f 13718 12963 12962 
f 13647 12964 12963 
f 13647 12965 12964 
f 13647 12966 12965 
f 13647 12949 12966 
f 12967 12985 12983 
f 12968 12967 12983 
f 12969 12968 12983 
f 12970 12969 12983 
f 12971 12970 12983 
f 12972 12971 12983 
f 12973 12972 12983 
f 12974 12973 12983 
f 12975 12974 12983 
f 12976 12975 12983 
f 12977 12976 12983 
f 12978 12977 12983 
f 12979 12978 12983 
f 12980 12979 12983 
f 12981 12980 12983 
f 12982 12981 12983 
f 12986 12982 12983 
f 12985 12986 12983 
f 12987 12988 12984 
f 12988 12989 12984 
f 12989 12990 12984 
f 12990 12991 12984 
f 12991 12992 12984 
f 12992 12993 12984 
f 12993 12994 12984 
f 12994 12995 12984 
f 12995 12996 12984 
f 12996 12997 12984 
f 12997 12998 12984 
f 12998 12999 12984 
f 12999 13000 12984 
f 13000 13001 12984 
f 13001 13002 12984 
f 13002 13003 12984 
f 13003 13004 12984 
f 13004 12987 12984 
f 13634 12985 12967 
f 13634 12967 12968 
f 13577 12968 12969 
f 13577 12969 12970 
f 13577 12970 12971 
f 13577 12971 12972 
f 13577 12972 12973 
f 13577 12973 12974 
f 13577 12974 12975 
f 13783 12975 12976 
f 13783 12976 12977 
f 13676 12977 12978 
f 13676 12978 12979 
f 13634 12979 12980 
f 13634 12980 12981 
f 13634 12981 12982 
f 13634 12982 12986 
f 13634 12986 12985 
f 13634 12988 12987 
f 13577 12989 12988 
f 13577 12990 12989 
f 13577 12991 12990 
f 13577 12992 12991 
f 13577 12993 12992 
f 13577 12994 12993 
f 13577 12995 12994 
f 13783 12996 12995 
f 13783 12997 12996 
f 13676 12998 12997 
f 13676 12999 12998 
f 13634 13000 12999 
f 13634 13001 13000 
f 13634 13002 13001 
f 13634 13003 13002 
f 13634 13004 13003 
f 13634 12987 13004 
f 13005 13023 13021 
f 13006 13005 13021 
f 13007 13006 13021 
f 13008 13007 13021 
f 13009 13008 13021 
f 13010 13009 13021 
f 13011 13010 13021 
f 13012 13011 13021 
f 13013 13012 13021 
f 13014 13013 13021 
f 13015 13014 13021 
f 13016 13015 13021 
f 13017 13016 13021 
f 13018 13017 13021 
f 13019 13018 13021 
f 13020 13019 13021 
f 13024 13020 13021 
f 13023 13024 13021 
f 13025 13026 13022 
f 13026 13027 13022 
f 13027 13028 13022 
f 13028 13029 13022 
f 13029 13030 13022 
f 13030 13031 13022 
f 13031 13032 13022 
f 13032 13033 13022 
f 13033 13034 13022 
f 13034 13035 13022 
f 13035 13036 13022 
f 13036 13037 13022 
f 13037 13038 13022 
f 13038 13039 13022 
f 13039 13040 13022 
f 13040 13041 13022 
f 13041 13042 13022 
f 13042 13025 13022 
f 13708 13023 13005 
f 13631 13005 13006 
f 13631 13006 13007 
f 13631 13007 13008 
f 13631 13008 13009 
f 13611 13009 13010 
f 13611 13010 13011 
f 13611 13011 13012 
f 13611 13012 13013 
f 13611 13013 13014 
f 13611 13014 13015 
f 13778 13015 13016 
f 13778 13016 13017 
f 13681 13017 13018 
f 13681 13018 13019 
f 13708 13019 13020 
f 13708 13020 13024 
f 13708 13024 13023 
f 13708 13026 13025 
f 13631 13027 13026 
f 13631 13028 13027 
f 13611 13029 13028 
f 13611 13030 13029 
f 13611 13031 13030 
f 13611 13032 13031 
f 13611 13033 13032 
f 13611 13034 13033 
f 13611 13035 13034 
f 13778 13036 13035 
f 13778 13037 13036 
f 13681 13038 13037 
f 13681 13039 13038 
f 13708 13040 13039 
f 13708 13041 13040 
f 13708 13042 13041 
f 13708 13025 13042 
f 13043 13061 13059 
f 13044 13043 13059 
f 13045 13044 13059 
f 13046 13045 13059 
f 13047 13046 13059 
f 13048 13047 13059 
f 13049 13048 13059 
f 13050 13049 13059 
f 13051 13050 13059 
f 13052 13051 13059 
f 13053 13052 13059 
f 13054 13053 13059 
f 13055 13054 13059 
f 13056 13055 13059 
f 13057 13056 13059 
f 13058 13057 13059 
f 13062 13058 13059 
f 13061 13062 13059 
f 13063 13064 13060 
f 13064 13065 13060 
f 13065 13066 13060 
f 13066 13067 13060 
f 13067 13068 13060 
f 13068 13069 13060 
f 13069 13070 13060 
f 13070 13071 13060 
f 13071 13072 13060 
f 13072 13073 13060 
f 13073 13074 13060 
f 13074 13075 13060 
f 13075 13076 13060 
f 13076 13077 13060 
f 13077 13078 13060 
f 13078 13079 13060 
f 13079 13080 13060 
f 13080 13063 13060 
f 13706 13061 13043 
f 13656 13043 13044 
f 13656 13044 13045 
f 13774 13045 13046 
f 13774 13046 13047 
f 13757 13047 13048 
f 13757 13048 13049 
f 13757 13049 13050 
f 13621 13050 13051 
f 13621 13051 13052 
f 13621 13052 13053 
f 13621 13053 13054 
f 13621 13054 13055 
f 13621 13055 13056 
f 13706 13056 13057 
f 13706 13057 13058 
f 13706 13058 13062 
f 13706 13062 13061 
f 13656 13064 13063 
f 13656 13065 13064 
f 13774 13066 13065 
f 13774 13067 13066 
f 13757 13068 13067 
f 13757 13069 13068 
f 13757 13070 13069 
f 13757 13071 13070 
f 13621 13072 13071 
f 13621 13073 13072 
f 13621 13074 13073 
f 13621 13075 13074 
f 13621 13076 13075 
f 13621 13077 13076 
f 13706 13078 13077 
f 13706 13079 13078 
f 13706 13080 13079 
f 13706 13063 13080 
f 13081 13099 13097 
f 13082 13081 13097 
f 13083 13082 13097 
f 13084 13083 13097 
f 13085 13084 13097 
f 13086 13085 13097 
f 13087 13086 13097 
f 13088 13087 13097 
f 13089 13088 13097 
f 13090 13089 13097 
f 13091 13090 13097 
f 13092 13091 13097 
f 13093 13092 13097 
f 13094 13093 13097 
f 13095 13094 13097 
f 13096 13095 13097 
f 13100 13096 13097 
f 13099 13100 13097 
f 13101 13102 13098 
f 13102 13103 13098 
f 13103 13104 13098 
f 13104 13105 13098 
f 13105 13106 13098 
f 13106 13107 13098 
f 13107 13108 13098 
f 13108 13109 13098 
f 13109 13110 13098 
f 13110 13111 13098 
f 13111 13112 13098 
f 13112 13113 13098 
f 13113 13114 13098 
f 13114 13115 13098 
f 13115 13116 13098 
f 13116 13117 13098 
f 13117 13118 13098 
f 13118 13101 13098 
f 13749 13099 13081 
f 13749 13081 13082 
f 13749 13082 13083 
f 13610 13083 13084 
f 13610 13084 13085 
f 13610 13085 13086 
f 13610 13086 13087 
f 13610 13087 13088 
f 13610 13088 13089 
f 13610 13089 13090 
f 13610 13090 13091 
f 13671 13091 13092 
f 13671 13092 13093 
f 13671 13093 13094 
f 13671 13094 13095 
f 13671 13095 13096 
f 13671 13096 13100 
f 13749 13100 13099 
f 13749 13102 13101 
f 13749 13103 13102 
f 13610 13104 13103 
f 13610 13105 13104 
f 13610 13106 13105 
f 13610 13107 13106 
f 13610 13108 13107 
f 13610 13109 13108 
f 13610 13110 13109 
f 13610 13111 13110 
f 13610 13112 13111 
f 13671 13113 13112 
f 13671 13114 13113 
f 13671 13115 13114 
f 13671 13116 13115 
f 13671 13117 13116 
f 13671 13118 13117 
f 13671 13101 13118 
f 13119 13137 13135 
f 13120 13119 13135 
f 13121 13120 13135 
f 13122 13121 13135 
f 13123 13122 13135 
f 13124 13123 13135 
f 13125 13124 13135 
f 13126 13125 13135 
f 13127 13126 13135 
f 13128 13127 13135 
f 13129 13128 13135 
f 13130 13129 13135 
f 13131 13130 13135 
f 13132 13131 13135 
f 13133 13132 13135 
f 13134 13133 13135 
f 13138 13134 13135 
f 13137 13138 13135 
f 13139 13140 13136 
f 13140 13141 13136 
f 13141 13142 13136 
f 13142 13143 13136 
f 13143 13144 13136 
f 13144 13145 13136 
f 13145 13146 13136 
f 13146 13147 13136 
f 13147 13148 13136 
f 13148 13149 13136 
f 13149 13150 13136 
f 13150 13151 13136 
f 13151 13152 13136 
f 13152 13153 13136 
f 13153 13154 13136 
f 13154 13155 13136 
f 13155 13156 13136 
f 13156 13139 13136 
f 13779 13137 13119 
f 13618 13119 13120 
f 13618 13120 13121 
f 13618 13121 13122 
f 13618 13122 13123 
f 13714 13123 13124 
f 13714 13124 13125 
f 13714 13125 13126 
f 13714 13126 13127 
f 13714 13127 13128 
f 13714 13128 13129 
f 13714 13129 13130 
f 13714 13130 13131 
f 13666 13131 13132 
f 13666 13132 13133 
f 13666 13133 13134 
f 13666 13134 13138 
f 13779 13138 13137 
f 13779 13140 13139 
f 13779 13141 13140 
f 13618 13142 13141 
f 13618 13143 13142 
f 13714 13144 13143 
f 13714 13145 13144 
f 13714 13146 13145 
f 13714 13147 13146 
f 13714 13148 13147 
f 13714 13149 13148 
f 13714 13150 13149 
f 13714 13151 13150 
f 13666 13152 13151 
f 13666 13153 13152 
f 13666 13154 13153 
f 13666 13155 13154 
f 13666 13156 13155 
f 13666 13139 13156 
f 13157 13175 13173 
f 13158 13157 13173 
f 13159 13158 13173 
f 13160 13159 13173 
f 13161 13160 13173 
f 13162 13161 13173 
f 13163 13162 13173 
f 13164 13163 13173 
f 13165 13164 13173 
f 13166 13165 13173 
f 13167 13166 13173 
f 13168 13167 13173 
f 13169 13168 13173 
f 13170 13169 13173 
f 13171 13170 13173 
f 13172 13171 13173 
f 13176 13172 13173 
f 13175 13176 13173 
f 13177 13178 13174 
f 13178 13179 13174 
f 13179 13180 13174 
f 13180 13181 13174 
f 13181 13182 13174 
f 13182 13183 13174 
f 13183 13184 13174 
f 13184 13185 13174 
f 13185 13186 13174 
f 13186 13187 13174 
f 13187 13188 13174 
f 13188 13189 13174 
f 13189 13190 13174 
f 13190 13191 13174 
f 13191 13192 13174 
f 13192 13193 13174 
f 13193 13194 13174 
f 13194 13177 13174 
f 13746 13175 13157 
f 13629 13157 13158 
f 13629 13158 13159 
f 13741 13159 13160 
f 13741 13160 13161 
f 13741 13161 13162 
f 13741 13162 13163 
f 13698 13163 13164 
f 13698 13164 13165 
f 13698 13165 13166 
f 13698 13166 13167 
f 13795 13167 13168 
f 13795 13168 13169 
f 13578 13169 13170 
f 13578 13170 13171 
f 13746 13171 13172 
f 13746 13172 13176 
f 13746 13176 13175 
f 13629 13178 13177 
f 13629 13179 13178 
f 13629 13180 13179 
f 13629 13181 13180 
f 13741 13182 13181 
f 13741 13183 13182 
f 13741 13184 13183 
f 13741 13185 13184 
f 13698 13186 13185 
f 13698 13187 13186 
f 13698 13188 13187 
f 13698 13189 13188 
f 13795 13190 13189 
f 13795 13191 13190 
f 13578 13192 13191 
f 13578 13193 13192 
f 13746 13194 13193 
f 13746 13177 13194 
f 13195 13213 13211 
f 13196 13195 13211 
f 13197 13196 13211 
f 13198 13197 13211 
f 13199 13198 13211 
f 13200 13199 13211 
f 13201 13200 13211 
f 13202 13201 13211 
f 13203 13202 13211 
f 13204 13203 13211 
f 13205 13204 13211 
f 13206 13205 13211 
f 13207 13206 13211 
f 13208 13207 13211 
f 13209 13208 13211 
f 13210 13209 13211 
f 13214 13210 13211 
f 13213 13214 13211 
f 13215 13216 13212 
f 13216 13217 13212 
f 13217 13218 13212 
f 13218 13219 13212 
f 13219 13220 13212 
f 13220 13221 13212 
f 13221 13222 13212 
f 13222 13223 13212 
f 13223 13224 13212 
f 13224 13225 13212 
f 13225 13226 13212 
f 13226 13227 13212 
f 13227 13228 13212 
f 13228 13229 13212 
f 13229 13230 13212 
f 13230 13231 13212 
f 13231 13232 13212 
f 13232 13215 13212 
f 13673 13213 13195 
f 13764 13195 13196 
f 13764 13196 13197 
f 13608 13197 13198 
f 13608 13198 13199 
f 13608 13199 13200 
f 13608 13200 13201 
f 13608 13201 13202 
f 13781 13202 13203 
f 13781 13203 13204 
f 13713 13204 13205 
f 13713 13205 13206 
f 13626 13206 13207 
f 13626 13207 13208 
f 13626 13208 13209 
f 13770 13209 13210 
f 13770 13210 13214 
f 13673 13214 13213 
f 13673 13216 13215 
f 13673 13217 13216 
f 13764 13218 13217 
f 13764 13219 13218 
f 13608 13220 13219 
f 13608 13221 13220 
f 13608 13222 13221 
f 13608 13223 13222 
f 13781 13224 13223 
f 13781 13225 13224 
f 13713 13226 13225 
f 13713 13227 13226 
f 13626 13228 13227 
f 13626 13229 13228 
f 13770 13230 13229 
f 13770 13231 13230 
f 13673 13232 13231 
f 13673 13215 13232 
f 13233 13251 13249 
f 13234 13233 13249 
f 13235 13234 13249 
f 13236 13235 13249 
f 13237 13236 13249 
f 13238 13237 13249 
f 13239 13238 13249 
f 13240 13239 13249 
f 13241 13240 13249 
f 13242 13241 13249 
f 13243 13242 13249 
f 13244 13243 13249 
f 13245 13244 13249 
f 13246 13245 13249 
f 13247 13246 13249 
f 13248 13247 13249 
f 13252 13248 13249 
f 13251 13252 13249 
f 13253 13254 13250 
f 13254 13255 13250 
f 13255 13256 13250 
f 13256 13257 13250 
f 13257 13258 13250 
f 13258 13259 13250 
f 13259 13260 13250 
f 13260 13261 13250 
f 13261 13262 13250 
f 13262 13263 13250 
f 13263 13264 13250 
f 13264 13265 13250 
f 13265 13266 13250 
f 13266 13267 13250 
f 13267 13268 13250 
f 13268 13269 13250 
f 13269 13270 13250 
f 13270 13253 13250 
f 13602 13251 13233 
f 13734 13233 13234 
f 13734 13234 13235 
f 13679 13235 13236 
f 13679 13236 13237 
f 13679 13237 13238 
f 13679 13238 13239 
f 13679 13239 13240 
f 13777 13240 13241 
f 13777 13241 13242 
f 13627 13242 13243 
f 13627 13243 13244 
f 13748 13244 13245 
f 13748 13245 13246 
f 13748 13246 13247 
f 13773 13247 13248 
f 13773 13248 13252 
f 13602 13252 13251 
f 13734 13254 13253 
f 13734 13255 13254 
f 13734 13256 13255 
f 13734 13257 13256 
f 13679 13258 13257 
f 13679 13259 13258 
f 13679 13260 13259 
f 13679 13261 13260 
f 13777 13262 13261 
f 13777 13263 13262 
f 13627 13264 13263 
f 13627 13265 13264 
f 13748 13266 13265 
f 13748 13267 13266 
f 13773 13268 13267 
f 13773 13269 13268 
f 13602 13270 13269 
f 13602 13253 13270 
f 13271 13289 13287 
f 13272 13271 13287 
f 13273 13272 13287 
f 13274 13273 13287 
f 13275 13274 13287 
f 13276 13275 13287 
f 13277 13276 13287 
f 13278 13277 13287 
f 13279 13278 13287 
f 13280 13279 13287 
f 13281 13280 13287 
f 13282 13281 13287 
f 13283 13282 13287 
f 13284 13283 13287 
f 13285 13284 13287 
f 13286 13285 13287 
f 13290 13286 13287 
f 13289 13290 13287 
f 13291 13292 13288 
f 13292 13293 13288 
f 13293 13294 13288 
f 13294 13295 13288 
f 13295 13296 13288 
f 13296 13297 13288 
f 13297 13298 13288 
f 13298 13299 13288 
f 13299 13300 13288 
f 13300 13301 13288 
f 13301 13302 13288 
f 13302 13303 13288 
f 13303 13304 13288 
f 13304 13305 13288 
f 13305 13306 13288 
f 13306 13307 13288 
f 13307 13308 13288 
f 13308 13291 13288 
f 13670 13289 13271 
f 13670 13271 13272 
f 13670 13272 13273 
f 13609 13273 13274 
f 13609 13274 13275 
f 13609 13275 13276 
f 13609 13276 13277 
f 13609 13277 13278 
f 13751 13278 13279 
f 13751 13279 13280 
f 13793 13280 13281 
f 13793 13281 13282 
f 13630 13282 13283 
f 13630 13283 13284 
f 13630 13284 13285 
f 13758 13285 13286 
f 13758 13286 13290 
f 13670 13290 13289 
f 13670 13292 13291 
f 13670 13293 13292 
f 13790 13294 13293 
f 13790 13295 13294 
f 13609 13296 13295 
f 13609 13297 13296 
f 13609 13298 13297 
f 13609 13299 13298 
f 13751 13300 13299 
f 13751 13301 13300 
f 13793 13302 13301 
f 13793 13303 13302 
f 13630 13304 13303 
f 13630 13305 13304 
f 13758 13306 13305 
f 13758 13307 13306 
f 13670 13308 13307 
f 13670 13291 13308 
f 13309 13327 13325 
f 13310 13309 13325 
f 13311 13310 13325 
f 13312 13311 13325 
f 13313 13312 13325 
f 13314 13313 13325 
f 13315 13314 13325 
f 13316 13315 13325 
f 13317 13316 13325 
f 13318 13317 13325 
f 13319 13318 13325 
f 13320 13319 13325 
f 13321 13320 13325 
f 13322 13321 13325 
f 13323 13322 13325 
f 13324 13323 13325 
f 13328 13324 13325 
f 13327 13328 13325 
f 13329 13330 13326 
f 13330 13331 13326 
f 13331 13332 13326 
f 13332 13333 13326 
f 13333 13334 13326 
f 13334 13335 13326 
f 13335 13336 13326 
f 13336 13337 13326 
f 13337 13338 13326 
f 13338 13339 13326 
f 13339 13340 13326 
f 13340 13341 13326 
f 13341 13342 13326 
f 13342 13343 13326 
f 13343 13344 13326 
f 13344 13345 13326 
f 13345 13346 13326 
f 13346 13329 13326 
f 13697 13327 13309 
f 13697 13309 13310 
f 13697 13310 13311 
f 13788 13311 13312 
f 13788 13312 13313 
f 13616 13313 13314 
f 13616 13314 13315 
f 13616 13315 13316 
f 13742 13316 13317 
f 13742 13317 13318 
f 13642 13318 13319 
f 13642 13319 13320 
f 13642 13320 13321 
f 13642 13321 13322 
f 13642 13322 13323 
f 13765 13323 13324 
f 13765 13324 13328 
f 13697 13328 13327 
f 13697 13330 13329 
f 13697 13331 13330 
f 13788 13332 13331 
f 13788 13333 13332 
f 13616 13334 13333 
f 13616 13335 13334 
f 13616 13336 13335 
f 13616 13337 13336 
f 13742 13338 13337 
f 13742 13339 13338 
f 13642 13340 13339 
f 13642 13341 13340 
f 13642 13342 13341 
f 13642 13343 13342 
f 13765 13344 13343 
f 13765 13345 13344 
f 13697 13346 13345 
f 13697 13329 13346 
f 13347 13365 13363 
f 13348 13347 13363 
f 13349 13348 13363 
f 13350 13349 13363 
f 13351 13350 13363 
f 13352 13351 13363 
f 13353 13352 13363 
f 13354 13353 13363 
f 13355 13354 13363 
f 13356 13355 13363 
f 13357 13356 13363 
f 13358 13357 13363 
f 13359 13358 13363 
f 13360 13359 13363 
f 13361 13360 13363 
f 13362 13361 13363 
f 13366 13362 13363 
f 13365 13366 13363 
f 13367 13368 13364 
f 13368 13369 13364 
f 13369 13370 13364 
f 13370 13371 13364 
f 13371 13372 13364 
f 13372 13373 13364 
f 13373 13374 13364 
f 13374 13375 13364 
f 13375 13376 13364 
f 13376 13377 13364 
f 13377 13378 13364 
f 13378 13379 13364 
f 13379 13380 13364 
f 13380 13381 13364 
f 13381 13382 13364 
f 13382 13383 13364 
f 13383 13384 13364 
f 13384 13367 13364 
f 13685 13365 13347 
f 13685 13347 13348 
f 13685 13348 13349 
f 13580 13349 13350 
f 13580 13350 13351 
f 13580 13351 13352 
f 13580 13352 13353 
f 13580 13353 13354 
f 13737 13354 13355 
f 13737 13355 13356 
f 13635 13356 13357 
f 13635 13357 13358 
f 13635 13358 13359 
f 13635 13359 13360 
f 13635 13360 13361 
f 13738 13361 13362 
f 13738 13362 13366 
f 13685 13366 13365 
f 13685 13368 13367 
f 13685 13369 13368 
f 13685 13370 13369 
f 13580 13371 13370 
f 13580 13372 13371 
f 13580 13373 13372 
f 13580 13374 13373 
f 13580 13375 13374 
f 13737 13376 13375 
f 13737 13377 13376 
f 13635 13378 13377 
f 13635 13379 13378 
f 13635 13380 13379 
f 13635 13381 13380 
f 13738 13382 13381 
f 13738 13383 13382 
f 13738 13384 13383 
f 13685 13367 13384 
f 13385 13403 13401 
f 13386 13385 13401 
f 13387 13386 13401 
f 13388 13387 13401 
f 13389 13388 13401 
f 13390 13389 13401 
f 13391 13390 13401 
f 13392 13391 13401 
f 13393 13392 13401 
f 13394 13393 13401 
f 13395 13394 13401 
f 13396 13395 13401 
f 13397 13396 13401 
f 13398 13397 13401 
f 13399 13398 13401 
f 13400 13399 13401 
f 13404 13400 13401 
f 13403 13404 13401 
f 13405 13406 13402 
f 13406 13407 13402 
f 13407 13408 13402 
f 13408 13409 13402 
f 13409 13410 13402 
f 13410 13411 13402 
f 13411 13412 13402 
f 13412 13413 13402 
f 13413 13414 13402 
f 13414 13415 13402 
f 13415 13416 13402 
f 13416 13417 13402 
f 13417 13418 13402 
f 13418 13419 13402 
f 13419 13420 13402 
f 13420 13421 13402 
f 13421 13422 13402 
f 13422 13405 13402 
f 13688 13403 13385 
f 13772 13385 13386 
f 13772 13386 13387 
f 13639 13387 13388 
f 13639 13388 13389 
f 13639 13389 13390 
f 13639 13390 13391 
f 13639 13391 13392 
f 13724 13392 13393 
f 13724 13393 13394 
f 13724 13394 13395 
f 13724 13395 13396 
f 13581 13396 13397 
f 13581 13397 13398 
f 13581 13398 13399 
f 13800 13399 13400 
f 13800 13400 13404 
f 13688 13404 13403 
f 13772 13406 13405 
f 13772 13407 13406 
f 13772 13408 13407 
f 13772 13409 13408 
f 13639 13410 13409 
f 13639 13411 13410 
f 13639 13412 13411 
f 13639 13413 13412 
f 13724 13414 13413 
f 13724 13415 13414 
f 13724 13416 13415 
f 13724 13417 13416 
f 13581 13418 13417 
f 13581 13419 13418 
f 13800 13420 13419 
f 13800 13421 13420 
f 13688 13422 13421 
f 13688 13405 13422 
f 13423 13441 13439 
f 13424 13423 13439 
f 13425 13424 13439 
f 13426 13425 13439 
f 13427 13426 13439 
f 13428 13427 13439 
f 13429 13428 13439 
f 13430 13429 13439 
f 13431 13430 13439 
f 13432 13431 13439 
f 13433 13432 13439 
f 13434 13433 13439 
f 13435 13434 13439 
f 13436 13435 13439 
f 13437 13436 13439 
f 13438 13437 13439 
f 13442 13438 13439 
f 13441 13442 13439 
f 13443 13444 13440 
f 13444 13445 13440 
f 13445 13446 13440 
f 13446 13447 13440 
f 13447 13448 13440 
f 13448 13449 13440 
f 13449 13450 13440 
f 13450 13451 13440 
f 13451 13452 13440 
f 13452 13453 13440 
f 13453 13454 13440 
f 13454 13455 13440 
f 13455 13456 13440 
f 13456 13457 13440 
f 13457 13458 13440 
f 13458 13459 13440 
f 13459 13460 13440 
f 13460 13443 13440 
f 13690 13441 13423 
f 13690 13423 13424 
f 13690 13424 13425 
f 13653 13425 13426 
f 13653 13426 13427 
f 13653 13427 13428 
f 13653 13428 13429 
f 13653 13429 13430 
f 13653 13430 13431 
f 13653 13431 13432 
f 13461 13432 13433 
f 13461 13433 13434 
f 13461 13434 13435 
f 13461 13435 13436 
f 13461 13436 13437 
f 13461 13437 13438 
f 13461 13438 13442 
f 13690 13442 13441 
f 13690 13444 13443 
f 13690 13445 13444 
f 13690 13446 13445 
f 13690 13447 13446 
f 13653 13448 13447 
f 13653 13449 13448 
f 13653 13450 13449 
f 13653 13451 13450 
f 13653 13452 13451 
f 13653 13453 13452 
f 13461 13454 13453 
f 13461 13455 13454 
f 13461 13456 13455 
f 13461 13457 13456 
f 13461 13458 13457 
f 13461 13459 13458 
f 13690 13460 13459 
f 13690 13443 13460 
f 13462 13480 13478 
f 13463 13462 13478 
f 13464 13463 13478 
f 13465 13464 13478 
f 13466 13465 13478 
f 13467 13466 13478 
f 13468 13467 13478 
f 13469 13468 13478 
f 13470 13469 13478 
f 13471 13470 13478 
f 13472 13471 13478 
f 13473 13472 13478 
f 13474 13473 13478 
f 13475 13474 13478 
f 13476 13475 13478 
f 13477 13476 13478 
f 13481 13477 13478 
f 13480 13481 13478 
f 13482 13483 13479 
f 13483 13484 13479 
f 13484 13485 13479 
f 13485 13486 13479 
f 13486 13487 13479 
f 13487 13488 13479 
f 13488 13489 13479 
f 13489 13490 13479 
f 13490 13491 13479 
f 13491 13492 13479 
f 13492 13493 13479 
f 13493 13494 13479 
f 13494 13495 13479 
f 13495 13496 13479 
f 13496 13497 13479 
f 13497 13498 13479 
f 13498 13499 13479 
f 13499 13482 13479 
f 13620 13480 13462 
f 13722 13462 13463 
f 13722 13463 13464 
f 13657 13464 13465 
f 13657 13465 13466 
f 13657 13466 13467 
f 13657 13467 13468 
f 13767 13468 13469 
f 13767 13469 13470 
f 13767 13470 13471 
f 13767 13471 13472 
f 13767 13472 13473 
f 13767 13473 13474 
f 13620 13474 13475 
f 13620 13475 13476 
f 13620 13476 13477 
f 13620 13477 13481 
f 13620 13481 13480 
f 13722 13483 13482 
f 13722 13484 13483 
f 13722 13485 13484 
f 13722 13486 13485 
f 13657 13487 13486 
f 13657 13488 13487 
f 13767 13489 13488 
f 13767 13490 13489 
f 13767 13491 13490 
f 13767 13492 13491 
f 13767 13493 13492 
f 13767 13494 13493 
f 13620 13495 13494 
f 13620 13496 13495 
f 13620 13497 13496 
f 13620 13498 13497 
f 13620 13499 13498 
f 13620 13482 13499 
f 13500 13518 13516 
f 13501 13500 13516 
f 13502 13501 13516 
f 13503 13502 13516 
f 13504 13503 13516 
f 13505 13504 13516 
f 13506 13505 13516 
f 13507 13506 13516 
f 13508 13507 13516 
f 13509 13508 13516 
f 13510 13509 13516 
f 13511 13510 13516 
f 13512 13511 13516 
f 13513 13512 13516 
f 13514 13513 13516 
f 13515 13514 13516 
f 13519 13515 13516 
f 13518 13519 13516 
f 13520 13521 13517 
f 13521 13522 13517 
f 13522 13523 13517 
f 13523 13524 13517 
f 13524 13525 13517 
f 13525 13526 13517 
f 13526 13527 13517 
f 13527 13528 13517 
f 13528 13529 13517 
f 13529 13530 13517 
f 13530 13531 13517 
f 13531 13532 13517 
f 13532 13533 13517 
f 13533 13534 13517 
f 13534 13535 13517 
f 13535 13536 13517 
f 13536 13537 13517 
f 13537 13520 13517 
f 13789 13518 13500 
f 13683 13500 13501 
f 13683 13501 13502 
f 13640 13502 13503 
f 13640 13503 13504 
f 13640 13504 13505 
f 13640 13505 13506 
f 13640 13506 13507 
f 13640 13507 13508 
f 13723 13508 13509 
f 13723 13509 13510 
f 13723 13510 13511 
f 13723 13511 13512 
f 13538 13512 13513 
f 13538 13513 13514 
f 13538 13514 13515 
f 13538 13515 13519 
f 13789 13519 13518 
f 13683 13521 13520 
f 13683 13522 13521 
f 13683 13523 13522 
f 13683 13524 13523 
f 13640 13525 13524 
f 13640 13526 13525 
f 13640 13527 13526 
f 13640 13528 13527 
f 13723 13529 13528 
f 13723 13530 13529 
f 13723 13531 13530 
f 13723 13532 13531 
f 13538 13533 13532 
f 13538 13534 13533 
f 13538 13535 13534 
f 13538 13536 13535 
f 13789 13537 13536 
f 13789 13520 13537 
f 13539 13557 13555 
f 13540 13539 13555 
f 13541 13540 13555 
f 13542 13541 13555 
f 13543 13542 13555 
f 13544 13543 13555 
f 13545 13544 13555 
f 13546 13545 13555 
f 13547 13546 13555 
f 13548 13547 13555 
f 13549 13548 13555 
f 13550 13549 13555 
f 13551 13550 13555 
f 13552 13551 13555 
f 13553 13552 13555 
f 13554 13553 13555 
f 13558 13554 13555 
f 13557 13558 13555 
f 13559 13560 13556 
f 13560 13561 13556 
f 13561 13562 13556 
f 13562 13563 13556 
f 13563 13564 13556 
f 13564 13565 13556 
f 13565 13566 13556 
f 13566 13567 13556 
f 13567 13568 13556 
f 13568 13569 13556 
f 13569 13570 13556 
f 13570 13571 13556 
f 13571 13572 13556 
f 13572 13573 13556 
f 13573 13574 13556 
f 13574 13575 13556 
f 13575 13576 13556 
f 13576 13559 13556 
f 13692 13557 13539 
f 13771 13539 13540 
f 13771 13540 13541 
f 13632 13541 13542 
f 13632 13542 13543 
f 13632 13543 13544 
f 13632 13544 13545 
f 13632 13545 13546 
f 13632 13546 13547 
f 13709 13547 13548 
f 13709 13548 13549 
f 13791 13549 13550 
f 13791 13550 13551 
f 13591 13551 13552 
f 13591 13552 13553 
f 13591 13553 13554 
f 13591 13554 13558 
f 13692 13558 13557 
f 13692 13560 13559 
f 13771 13561 13560 
f 13771 13562 13561 
f 13632 13563 13562 
f 13632 13564 13563 
f 13632 13565 13564 
f 13632 13566 13565 
f 13632 13567 13566 
f 13709 13568 13567 
f 13709 13569 13568 
f 13791 13570 13569 
f 13791 13571 13570 
f 13591 13572 13571 
f 13591 13573 13572 
f 13591 13574 13573 
f 13591 13575 13574 
f 13591 13576 13575 
f 13692 13559 13576 
f 11768 13763 13623 
f 11776 13623 13755 
f 11754 13755 13623 
f 11748 13623 13763 
f 11815 13624 13759 
f 11793 13759 13624 
f 11804 13624 13716 
f 11823 13716 13624 
f 11843 13675 13735 
f 11835 13625 13601 
f 11856 13601 13625 
f 11862 13735 13675 
f 13223 13608 13781 
f 13231 13770 13673 
f 13214 13673 13770 
f 13202 13781 13608 
f 13267 13748 13773 
f 13235 13679 13734 
f 13247 13773 13748 
f 13257 13734 13679 
f 12589 13628 13768 
f 12610 13768 13628 
f 12597 13739 13628 
f 12617 13628 13739 
f 13177 13746 13629 
f 13185 13741 13698 
f 13163 13698 13741 
f 13157 13629 13746 
f 13280 13793 13751 
f 13290 13670 13758 
f 13301 13751 13793 
f 13307 13758 13670 
f 13028 13631 13611 
f 13009 13611 13631 
f 13039 13681 13708 
f 13019 13708 13681 
f 13558 13692 13591 
f 13567 13632 13709 
f 13576 13591 13692 
f 13547 13709 13632 
f 12873 13633 13606 
f 12853 13606 13633 
f 12864 13633 13678 
f 12884 13678 13633 
f 12979 13634 13676 
f 12999 13676 13634 
f 12968 13577 13634 
f 12988 13634 13577 
f 13377 13737 13635 
f 13366 13685 13738 
f 13384 13738 13685 
f 13356 13635 13737 
f 12655 13726 13747 
f 12635 13747 13726 
f 12648 13769 13636 
f 12627 13636 13769 
f 11869 13787 13637 
f 11890 13637 13787 
f 11900 13696 13715 
f 11881 13715 13696 
f 12911 13731 13638 
f 12891 13638 13667 
f 12897 13583 13638 
f 12918 13638 13583 
f 13404 13688 13800 
f 13392 13724 13639 
f 13421 13800 13688 
f 13413 13639 13724 
f 13508 13723 13640 
f 13528 13640 13723 
f 13519 13789 13538 
f 13536 13538 13789 
f 12737 13727 13641 
f 12703 13760 13641 
f 12725 13641 13760 
f 12720 13641 13727 
f 13345 13765 13697 
f 13318 13642 13742 
f 13339 13742 13642 
f 13328 13697 13765 
f 11596 13686 13643 
f 11607 13643 13733 
f 11616 13643 13686 
f 11628 13733 13643 
f 12554 13702 13644 
f 12563 13710 12586 
f 12583 12586 13710 
f 12574 13644 13702 
f 11645 13645 13736 
f 11654 13750 13687 
f 11666 13736 13645 
f 11634 13687 13750 
f 11919 13607 13730 
f 11922 13730 13607 
f 11928 13607 13699 
f 11907 13699 13607 
f 12963 13718 13647 
f 12930 13782 13761 
f 12951 13761 13782 
f 12943 13647 13718 
f 11957 13648 13743 
f 11947 13622 13721 
f 11976 13743 13648 
f 11968 13721 13622 
f 12000 13717 13766 
f 12012 13700 13649 
f 11979 13766 13717 
f 11991 13649 13700 
f 11677 13775 13650 
f 11697 13650 13775 
f 11672 13650 13693 
f 11692 13693 13650 
f 12739 13729 13705 
f 12767 13651 13584 
f 12761 13705 13729 
f 12747 13584 13651 
f 12826 13652 13582 
f 12846 13582 13652 
f 12817 13754 13695 
f 12838 13695 13754 
f 13459 13461 13690 
f 13432 13461 13653 
f 13442 13690 13461 
f 13453 13653 13461 
f 12807 13615 13712 
f 12777 13725 13654 
f 12786 13712 13615 
f 12797 13654 13725 
f 12516 13762 13579 
f 12522 13707 13655 
f 12536 13579 13762 
f 12543 13655 13707 
f 13067 13774 13757 
f 13056 13706 13621 
f 13077 13621 13706 
f 13047 13757 13774 
f 13464 13657 13722 
f 13474 13620 13767 
f 13486 13722 13657 
f 13494 13767 13620 
f 12098 13658 13786 
f 12119 13786 13658 
f 12091 13744 13732 
f 12111 13732 13744 
f 12090 13694 13659 
f 12055 13801 13659 
f 12076 13659 13801 
f 12072 13659 13694 
f 12384 13660 13719 
f 12380 13598 13660 
f 12364 13719 13660 
f 12358 13660 13598 
f 12143 13661 13776 
f 12130 13784 13661 
f 12163 13776 13661 
f 12150 13661 13784 
f 12665 13614 13794 
f 12671 13662 13614 
f 12691 13614 13662 
f 12686 13794 13614 
f 12038 13663 13597 
f 12028 13663 13728 
f 12049 13728 13663 
f 12016 13597 13663 
f 11724 13664 13740 
f 11734 13799 13603 
f 11744 13740 13664 
f 11712 13603 13799 
f 12502 13589 13665 
f 12475 13589 13720 
f 12482 13665 13589 
f 12496 13720 13589 
f 13139 13666 13779 
f 13151 13714 13666 
f 13131 13666 13714 
f 13138 13779 13666 
f 12903 13731 13756 
f 12924 13756 13731 
f 12729 13668 13612 
f 12709 13612 13668 
f 12227 13594 13669 
f 12236 13745 13594 
f 12217 13594 13745 
f 12205 13797 13594 
f 13273 13609 13670 
f 13295 13790 13609 
f 13091 13671 13610 
f 13112 13610 13671 
f 13100 13749 13671 
f 13101 13671 13749 
f 12347 13780 13596 
f 12328 13596 13780 
f 12340 13596 13672 
f 12338 13672 13596 
f 13197 13608 13764 
f 13219 13764 13608 
f 12420 13600 13674 
f 12398 13674 13600 
f 13824 13803 13804 
f 13823 13805 13806 
f 11850 13675 13601 
f 11828 13601 13675 
f 12995 13577 13783 
f 12975 13783 13577 
f 12935 13619 13677 
f 12955 13677 13619 
f 12878 13606 13753 
f 12858 13753 13606 
f 13240 13777 13679 
f 13261 13679 13777 
f 12190 13752 13785 
f 12179 13680 13605 
f 12170 13785 13752 
f 12199 13605 13680 
f 13015 13778 13611 
f 13035 13611 13778 
f 11782 11785 13682 
f 11762 13682 11785 
f 13524 13683 13640 
f 13502 13640 13683 
f 12606 13684 13595 
f 12624 13595 13684 
f 13370 13685 13580 
f 13349 13580 13685 
f 11621 13686 13586 
f 11601 13586 13686 
f 11659 13687 13588 
f 11639 13588 13687 
f 13409 13772 13639 
f 13387 13639 13772 
f 12644 13689 13590 
f 12662 13590 13689 
f 13425 13653 13690 
f 13447 13690 13653 
f 12467 13691 13587 
f 13825 13807 13808 
f 12448 13587 13691 
f 13826 13809 13810 
f 13541 13632 13771 
f 13562 13771 13632 
f 11704 13613 13693 
f 11683 13693 13613 
f 12061 13694 13593 
f 12082 13593 13694 
f 12834 13695 13652 
f 12852 13652 13695 
f 11894 13585 13696 
f 11873 13696 13585 
f 13331 13697 13788 
f 13311 13788 13697 
f 13167 13795 13698 
f 13189 13698 13795 
f 11911 13646 13699 
f 11932 13699 13646 
f 12006 13592 13700 
f 11986 13700 13592 
f 12261 13604 13701 
f 12263 13701 13604 
f 13819 13811 13812 
f 13820 13813 13814 
f 12558 12586 13802 
f 12578 13802 12586 
f 12134 13599 13703 
f 12154 13703 13599 
f 12307 13704 12319 
f 12288 12319 13704 
f 13821 13815 13816 
f 13822 13817 13818 
f 12753 13705 13584 
f 12775 13584 13705 
f 13043 13656 13706 
f 13063 13706 13656 
f 12511 13579 13707 
f 12531 13707 13579 
f 13005 13631 13708 
f 13026 13708 13631 
f 13551 13591 13791 
f 13571 13791 13591 
f 12549 13644 13710 
f 12569 13710 13644 
f 12682 13711 13662 
f 12700 13662 13711 
f 12811 13712 13654 
f 12790 13654 13712 
f 13206 13626 13713 
f 13227 13713 13626 
f 13123 13714 13618 
f 13143 13618 13714 
f 11884 13715 13637 
f 11864 13637 13715 
f 11821 11824 13716 
f 11801 13716 11824 
f 11977 13717 13649 
f 11998 13649 13717 
f 12959 13619 13798 
f 12939 13798 13619 
f 12390 13719 13598 
f 12370 13598 13719 
f 12487 13720 13665 
f 12507 13665 13720 
f 11964 13648 13721 
f 11942 13721 13648 
f 13482 13620 13722 
f 13462 13722 13620 
f 13512 13538 13723 
f 13532 13723 13538 
f 13396 13581 13724 
f 13417 13724 13581 
f 12779 13792 13725 
f 12799 13725 13792 
f 12631 13726 13636 
f 12651 13636 13726 
f 12715 13727 13612 
f 12735 13612 13727 
f 12042 13597 13728 
f 12022 13728 13597 
f 12741 13651 13729 
f 12763 13729 13651 
f 11938 13646 13730 
f 11916 13730 13646 
f 13638 13731 13667 
f 12910 13667 13731 
f 12104 13732 13658 
f 12125 13658 13732 
f 11605 13733 13796 
f 11625 13796 13733 
f 13253 13602 13734 
f 13233 13734 13602 
f 11840 13735 13625 
f 11860 13625 13735 
f 11643 13736 13588 
f 11663 13588 13736 
f 13354 13737 13580 
f 13375 13580 13737 
f 13381 13635 13738 
f 13361 13738 13635 
f 12619 13739 13595 
f 12599 13595 13739 
f 11721 13740 13603 
f 11740 13603 13740 
f 13181 13629 13741 
f 13159 13741 13629 
f 13337 13616 13742 
f 13316 13742 13616 
f 11972 13622 13743 
f 11951 13743 13622 
f 12093 13617 13744 
f 12113 13744 13617 
f 12211 13745 13669 
f 12232 13669 13745 
f 13193 13578 13746 
f 13171 13746 13578 
f 12637 13590 13747 
f 12657 13747 13590 
f 13244 13748 13627 
f 13265 13627 13748 
f 13103 13749 13610 
f 13083 13610 13749 
f 11670 13645 13750 
f 11651 13750 13645 
f 13278 13751 13609 
f 13299 13609 13751 
f 12168 13752 13680 
f 12188 13680 13752 
f 12860 13678 13753 
f 12880 13753 13678 
f 12820 13582 13754 
f 12840 13754 13582 
f 11757 11785 13755 
f 11778 13755 11785 
f 12901 13756 13583 
f 12922 13583 13756 
f 13071 13757 13621 
f 13050 13621 13757 
f 13285 13758 13630 
f 13305 13630 13758 
f 11796 11824 13759 
f 11817 13759 11824 
f 12705 13668 13760 
f 12727 13760 13668 
f 12949 13647 13761 
f 12947 13761 13647 
f 12538 13762 13655 
f 12518 13655 13762 
f 11784 13682 13763 
f 11765 13763 13682 
f 13195 13764 13673 
f 13217 13673 13764 
f 13323 13765 13642 
f 13343 13642 13765 
f 12002 13766 13592 
f 11981 13592 13766 
f 13488 13657 13767 
f 13468 13767 13657 
f 12587 13768 13684 
f 12608 13684 13768 
f 12625 13769 13689 
f 12646 13689 13769 
f 13209 13770 13626 
f 13229 13626 13770 
f 13539 13771 13692 
f 13560 13692 13771 
f 13385 13772 13688 
f 13405 13688 13772 
f 13252 13602 13773 
f 13269 13773 13602 
f 13045 13774 13656 
f 13065 13656 13774 
f 11699 13775 13613 
f 11679 13613 13775 
f 12161 13599 13776 
f 12141 13776 13599 
f 13242 13627 13777 
f 13263 13777 13627 
f 13017 13681 13778 
f 13037 13778 13681 
f 13119 13618 13779 
f 13141 13779 13618 
f 12326 13780 13672 
f 12345 13672 13780 
f 13204 13713 13781 
f 13225 13781 13713 
f 12932 13677 13782 
f 12953 13782 13677 
f 12997 13783 13676 
f 12977 13676 13783 
f 12132 13703 13784 
f 12152 13784 13703 
f 12192 13785 13605 
f 12173 13605 13785 
f 12095 13786 13617 
f 12117 13617 13786 
f 11871 13585 13787 
f 11892 13787 13585 
f 13333 13788 13616 
f 13313 13616 13788 
f 13500 13683 13789 
f 13520 13789 13683 
f 13293 13670 13790 
f 13670 13609 13790 
f 13569 13709 13791 
f 13549 13791 13709 
f 12782 13615 13792 
f 12801 13792 13615 
f 13282 13630 13793 
f 13303 13793 13630 
f 12684 13711 13794 
f 12663 13794 13711 
f 13169 13578 13795 
f 13191 13795 13578 
f 11623 13586 13796 
f 11603 13796 13586 
f 13669 13594 13797 
f 12207 13669 13797 
f 12961 13798 13718 
f 12941 13718 13798 
f 11730 13664 13799 
f 11710 13799 13664 
f 13419 13581 13800 
f 13399 13800 13581 
f 12057 13593 13801 
f 12078 13801 13593 
f 12556 13802 13702 
f 12576 13702 13802 
f 13915 13913 13830 
f 13907 13853 13840 
f 13849 13894 13861 
f 13910 13864 13914 
f 13869 13916 13857 
f 13875 13848 13918 
f 13881 13905 13865 
f 13863 13843 13924 
f 13851 13831 13883 
f 13923 13856 13920 
f 13837 13873 13912 
f 13867 13900 13868 
f 13887 13880 13885 
f 13850 13836 13846 
f 13843 13863 13911 
f 13879 13899 13895 
f 13860 13838 13891 
f 13876 13860 13921 
f 13881 13832 13848 
f 13879 13854 13862 
f 13865 13844 13926 
f 13850 13896 13871 
f 13908 13912 13873 
f 13856 13923 13861 
f 13858 13838 13869 
f 13920 13856 13892 
f 13877 13886 13890 
f 13904 13877 13902 
f 13914 13880 13878 
f 13835 13868 13917 
f 13863 13924 13919 
f 13833 13857 13876 
f 13831 13846 13911 
f 13847 13837 13834 
f 13862 13837 13847 
f 13848 13829 13881 
f 13872 13893 13888 
f 13855 13895 13866 
f 13896 13850 13882 
f 13832 13918 13848 
f 13921 13860 13851 
f 13887 13878 13880 
f 13898 13889 13852 
f 13911 13890 13843 
f 13853 13925 13896 
f 13900 13867 13895 
f 13854 13839 13829 
f 13866 13922 13855 
f 13885 13835 13884 
f 13892 13856 13891 
f 13856 13912 13841 
f 13874 13924 13904 
f 13919 13845 13857 
f 13901 13915 13830 
f 13892 13858 13842 
f 13909 13878 13887 
f 13840 13859 13907 
f 13828 13851 13860 
f 13860 13908 13828 
f 13903 13847 13834 
f 13834 13856 13903 
f 13829 13862 13854 
f 13911 13863 13831 
f 13863 13883 13831 
f 13864 13910 13840 
f 13882 13926 13896 
f 13882 13918 13832 
f 13865 13832 13881 
f 13897 13866 13849 
f 13894 13849 13899 
f 13881 13906 13905 
f 13867 13839 13854 
f 13835 13914 13868 
f 13844 13865 13905 
f 13898 13852 13869 
f 13869 13913 13915 
f 13842 13920 13892 
f 13872 13888 13901 
f 13836 13925 13890 
f 13925 13836 13850 
f 13849 13872 13897 
f 13830 13872 13901 
f 13873 13828 13908 
f 13889 13898 13902 
f 13845 13874 13857 
f 13846 13875 13850 
f 13916 13869 13838 
f 13860 13876 13838 
f 13877 13889 13902 
f 13889 13877 13853 
f 13878 13910 13914 
f 13827 13910 13878 
f 13879 13894 13899 
f 13847 13879 13862 
f 13885 13880 13835 
f 13914 13835 13880 
f 13906 13881 13839 
f 13839 13881 13829 
f 13832 13865 13882 
f 13926 13882 13865 
f 13883 13833 13851 
f 13857 13833 13919 
f 13835 13917 13884 
f 13884 13855 13922 
f 13887 13885 13830 
f 13885 13884 13830 
f 13886 13843 13890 
f 13843 13904 13924 
f 13830 13852 13887 
f 13909 13887 13852 
f 13888 13893 13870 
f 13888 13870 13842 
f 13889 13827 13852 
f 13827 13907 13859 
f 13890 13853 13877 
f 13925 13853 13890 
f 13856 13841 13891 
f 13891 13908 13860 
f 13838 13858 13892 
f 13892 13891 13838 
f 13872 13849 13893 
f 13849 13861 13923 
f 13894 13879 13847 
f 13847 13903 13894 
f 13895 13854 13879 
f 13854 13895 13867 
f 13840 13896 13864 
f 13896 13840 13853 
f 13922 13830 13884 
f 13872 13830 13897 
f 13857 13898 13869 
f 13898 13857 13902 
f 13866 13899 13849 
f 13899 13866 13895 
f 13917 13868 13900 
f 13855 13900 13895 
f 13842 13858 13901 
f 13842 13901 13888 
f 13902 13874 13904 
f 13874 13902 13857 
f 13903 13861 13894 
f 13861 13903 13856 
f 13843 13886 13904 
f 13877 13904 13886 
f 13914 13905 13868 
f 13906 13868 13905 
f 13839 13867 13906 
f 13868 13906 13867 
f 13907 13827 13889 
f 13889 13853 13907 
f 13908 13841 13912 
f 13908 13891 13841 
f 13909 13827 13878 
f 13852 13827 13909 
f 13859 13840 13910 
f 13910 13827 13859 
f 13890 13911 13836 
f 13836 13911 13846 
f 13856 13834 13912 
f 13912 13834 13837 
f 13913 13852 13830 
f 13913 13869 13852 
f 13864 13844 13914 
f 13914 13844 13905 
f 13915 13858 13869 
f 13915 13901 13858 
f 13876 13916 13838 
f 13916 13876 13857 
f 13917 13900 13855 
f 13855 13884 13917 
f 13918 13850 13875 
f 13882 13850 13918 
f 13919 13883 13863 
f 13883 13919 13833 
f 13920 13870 13923 
f 13920 13842 13870 
f 13876 13921 13833 
f 13833 13921 13851 
f 13830 13922 13897 
f 13866 13897 13922 
f 13893 13923 13870 
f 13923 13893 13849 
f 13845 13919 13924 
f 13874 13845 13924 
f 13871 13896 13925 
f 13850 13871 13925 
f 13864 13926 13844 
f 13926 13864 13896 
f 13927 13945 13943 
f 13928 13927 13943 
f 13929 13928 13943 
f 13930 13929 13943 
f 13931 13930 13943 
f 13932 13931 13943 
f 13933 13932 13943 
f 13934 13933 13943 
f 13935 13934 13943 
f 13936 13935 13943 
f 13937 13936 13943 
f 13938 13937 13943 
f 13939 13938 13943 
f 13940 13939 13943 
f 13941 13940 13943 
f 13942 13941 13943 
f 13946 13942 13943 
f 13945 13946 13943 
f 13947 13948 13944 
f 13948 13949 13944 
f 13949 13950 13944 
f 13950 13951 13944 
f 13951 13952 13944 
f 13952 13953 13944 
f 13953 13954 13944 
f 13954 13955 13944 
f 13955 13956 13944 
f 13956 13957 13944 
f 13957 13958 13944 
f 13958 13959 13944 
f 13959 13960 13944 
f 13960 13961 13944 
f 13961 13962 13944 
f 13962 13963 13944 
f 13963 13964 13944 
f 13964 13947 13944 
f 14241 13945 13927 
f 14241 13927 13928 
f 14265 13928 13929 
f 14265 13929 13930 
f 13965 13930 13931 
f 13965 13931 13932 
f 14264 13932 13933 
f 14264 13933 13934 
f 14291 13934 13935 
f 14291 13935 13936 
f 14273 13936 13937 
f 14273 13937 13938 
f 14268 13938 13939 
f 14268 13939 13940 
f 14256 13940 13941 
f 14256 13941 13942 
f 14263 13942 13946 
f 14263 13946 13945 
f 14241 13948 13947 
f 14241 13949 13948 
f 14265 13950 13949 
f 14265 13951 13950 
f 13965 13952 13951 
f 13965 13953 13952 
f 14264 13954 13953 
f 14264 13955 13954 
f 14264 13956 13955 
f 14264 13957 13956 
f 14303 13958 13957 
f 14303 13959 13958 
f 14303 13960 13959 
f 14303 13961 13960 
f 14295 13962 13961 
f 14295 13963 13962 
f 14295 13964 13963 
f 14295 13947 13964 
f 13966 13984 13982 
f 13967 13966 13982 
f 13968 13967 13982 
f 13969 13968 13982 
f 13970 13969 13982 
f 13971 13970 13982 
f 13972 13971 13982 
f 13973 13972 13982 
f 13974 13973 13982 
f 13975 13974 13982 
f 13976 13975 13982 
f 13977 13976 13982 
f 13978 13977 13982 
f 13979 13978 13982 
f 13980 13979 13982 
f 13981 13980 13982 
f 13985 13981 13982 
f 13984 13985 13982 
f 13986 13987 13983 
f 13987 13988 13983 
f 13988 13989 13983 
f 13989 13990 13983 
f 13990 13991 13983 
f 13991 13992 13983 
f 13992 13993 13983 
f 13993 13994 13983 
f 13994 13995 13983 
f 13995 13996 13983 
f 13996 13997 13983 
f 13997 13998 13983 
f 13998 13999 13983 
f 13999 14000 13983 
f 14000 14001 13983 
f 14001 14002 13983 
f 14002 14003 13983 
f 14003 13986 13983 
f 14283 13984 13966 
f 14283 13966 13967 
f 14283 13967 13968 
f 14283 13968 13969 
f 14301 13969 13970 
f 14301 13970 13971 
f 14004 13971 13972 
f 14004 13972 13973 
f 14247 13973 13974 
f 14247 13974 13975 
f 14286 13975 13976 
f 14286 13976 13977 
f 14286 13977 13978 
f 14286 13978 13979 
f 14277 13979 13980 
f 14277 13980 13981 
f 14277 13981 13985 
f 14277 13985 13984 
f 14287 13987 13986 
f 14283 13988 13987 
f 14283 13989 13988 
f 14301 13990 13989 
f 14301 13991 13990 
f 14004 13992 13991 
f 14004 13993 13992 
f 14247 13994 13993 
f 14247 13995 13994 
f 14262 13996 13995 
f 14262 13997 13996 
f 14269 13998 13997 
f 14269 13999 13998 
f 14255 14000 13999 
f 14255 14001 14000 
f 14240 14002 14001 
f 14240 14003 14002 
f 14287 13986 14003 
f 14005 14023 14021 
f 14006 14005 14021 
f 14007 14006 14021 
f 14008 14007 14021 
f 14009 14008 14021 
f 14010 14009 14021 
f 14011 14010 14021 
f 14012 14011 14021 
f 14013 14012 14021 
f 14014 14013 14021 
f 14015 14014 14021 
f 14016 14015 14021 
f 14017 14016 14021 
f 14018 14017 14021 
f 14019 14018 14021 
f 14020 14019 14021 
f 14024 14020 14021 
f 14023 14024 14021 
f 14025 14026 14022 
f 14026 14027 14022 
f 14027 14028 14022 
f 14028 14029 14022 
f 14029 14030 14022 
f 14030 14031 14022 
f 14031 14032 14022 
f 14032 14033 14022 
f 14033 14034 14022 
f 14034 14035 14022 
f 14035 14036 14022 
f 14036 14037 14022 
f 14037 14038 14022 
f 14038 14039 14022 
f 14039 14040 14022 
f 14040 14041 14022 
f 14041 14042 14022 
f 14042 14025 14022 
f 14274 14023 14005 
f 14274 14005 14006 
f 14261 14006 14007 
f 14261 14007 14008 
f 14236 14008 14009 
f 14236 14009 14010 
f 14236 14010 14011 
f 14236 14011 14012 
f 14245 14012 14013 
f 14245 14013 14014 
f 14245 14014 14015 
f 14245 14015 14016 
f 14245 14016 14017 
f 14245 14017 14018 
f 14288 14018 14019 
f 14288 14019 14020 
f 14288 14020 14024 
f 14288 14024 14023 
f 14261 14026 14025 
f 14261 14027 14026 
f 14261 14028 14027 
f 14261 14029 14028 
f 14236 14030 14029 
f 14236 14031 14030 
f 14236 14032 14031 
f 14236 14033 14032 
f 14270 14034 14033 
f 14270 14035 14034 
f 14270 14036 14035 
f 14270 14037 14036 
f 14270 14038 14037 
f 14270 14039 14038 
f 14270 14040 14039 
f 14274 14041 14040 
f 14274 14042 14041 
f 14274 14025 14042 
f 14043 14061 14059 
f 14044 14043 14059 
f 14045 14044 14059 
f 14046 14045 14059 
f 14047 14046 14059 
f 14048 14047 14059 
f 14049 14048 14059 
f 14050 14049 14059 
f 14051 14050 14059 
f 14052 14051 14059 
f 14053 14052 14059 
f 14054 14053 14059 
f 14055 14054 14059 
f 14056 14055 14059 
f 14057 14056 14059 
f 14058 14057 14059 
f 14062 14058 14059 
f 14061 14062 14059 
f 14063 14064 14060 
f 14064 14065 14060 
f 14065 14066 14060 
f 14066 14067 14060 
f 14067 14068 14060 
f 14068 14069 14060 
f 14069 14070 14060 
f 14070 14071 14060 
f 14071 14072 14060 
f 14072 14073 14060 
f 14073 14074 14060 
f 14074 14075 14060 
f 14075 14076 14060 
f 14076 14077 14060 
f 14077 14078 14060 
f 14078 14079 14060 
f 14079 14080 14060 
f 14080 14063 14060 
f 14275 14061 14043 
f 14275 14043 14044 
f 14275 14044 14045 
f 14275 14045 14046 
f 14300 14046 14047 
f 14300 14047 14048 
f 14235 14048 14049 
f 14235 14049 14050 
f 14298 14050 14051 
f 14298 14051 14052 
f 14298 14052 14053 
f 14289 14053 14054 
f 14289 14054 14055 
f 14289 14055 14056 
f 14289 14056 14057 
f 14289 14057 14058 
f 14248 14058 14062 
f 14248 14062 14061 
f 14292 14064 14063 
f 14275 14065 14064 
f 14275 14066 14065 
f 14300 14067 14066 
f 14300 14068 14067 
f 14235 14069 14068 
f 14235 14070 14069 
f 14298 14071 14070 
f 14298 14072 14071 
f 14271 14073 14072 
f 14271 14074 14073 
f 14266 14075 14074 
f 14266 14076 14075 
f 14239 14077 14076 
f 14239 14078 14077 
f 14272 14079 14078 
f 14272 14080 14079 
f 14292 14063 14080 
f 14081 14099 14097 
f 14082 14081 14097 
f 14083 14082 14097 
f 14084 14083 14097 
f 14085 14084 14097 
f 14086 14085 14097 
f 14087 14086 14097 
f 14088 14087 14097 
f 14089 14088 14097 
f 14090 14089 14097 
f 14091 14090 14097 
f 14092 14091 14097 
f 14093 14092 14097 
f 14094 14093 14097 
f 14095 14094 14097 
f 14096 14095 14097 
f 14100 14096 14097 
f 14099 14100 14097 
f 14101 14102 14098 
f 14102 14103 14098 
f 14103 14104 14098 
f 14104 14105 14098 
f 14105 14106 14098 
f 14106 14107 14098 
f 14107 14108 14098 
f 14108 14109 14098 
f 14109 14110 14098 
f 14110 14111 14098 
f 14111 14112 14098 
f 14112 14113 14098 
f 14113 14114 14098 
f 14114 14115 14098 
f 14115 14116 14098 
f 14116 14117 14098 
f 14117 14118 14098 
f 14118 14101 14098 
f 14242 14099 14081 
f 14242 14081 14082 
f 14260 14082 14083 
f 14260 14083 14084 
f 14297 14084 14085 
f 14297 14085 14086 
f 14285 14086 14087 
f 14285 14087 14088 
f 14237 14088 14089 
f 14237 14089 14090 
f 14251 14090 14091 
f 14251 14091 14092 
f 14251 14092 14093 
f 14251 14093 14094 
f 14258 14094 14095 
f 14258 14095 14096 
f 14267 14096 14100 
f 14267 14100 14099 
f 14242 14102 14101 
f 14242 14103 14102 
f 14260 14104 14103 
f 14260 14105 14104 
f 14297 14106 14105 
f 14297 14107 14106 
f 14285 14108 14107 
f 14285 14109 14108 
f 14285 14110 14109 
f 14285 14111 14110 
f 14284 14112 14111 
f 14284 14113 14112 
f 14284 14114 14113 
f 14284 14115 14114 
f 14294 14116 14115 
f 14294 14117 14116 
f 14294 14118 14117 
f 14294 14101 14118 
f 14119 14137 14135 
f 14120 14119 14135 
f 14121 14120 14135 
f 14122 14121 14135 
f 14123 14122 14135 
f 14124 14123 14135 
f 14125 14124 14135 
f 14126 14125 14135 
f 14127 14126 14135 
f 14128 14127 14135 
f 14129 14128 14135 
f 14130 14129 14135 
f 14131 14130 14135 
f 14132 14131 14135 
f 14133 14132 14135 
f 14134 14133 14135 
f 14138 14134 14135 
f 14137 14138 14135 
f 14139 14140 14136 
f 14140 14141 14136 
f 14141 14142 14136 
f 14142 14143 14136 
f 14143 14144 14136 
f 14144 14145 14136 
f 14145 14146 14136 
f 14146 14147 14136 
f 14147 14148 14136 
f 14148 14149 14136 
f 14149 14150 14136 
f 14150 14151 14136 
f 14151 14152 14136 
f 14152 14153 14136 
f 14153 14154 14136 
f 14154 14155 14136 
f 14155 14156 14136 
f 14156 14139 14136 
f 14259 14137 14119 
f 14259 14119 14120 
f 14250 14120 14121 
f 14250 14121 14122 
f 14157 14122 14123 
f 14157 14123 14124 
f 14282 14124 14125 
f 14282 14125 14126 
f 14282 14126 14127 
f 14282 14127 14128 
f 14282 14128 14129 
f 14282 14129 14130 
f 14253 14130 14131 
f 14278 14131 14132 
f 14278 14132 14133 
f 14278 14133 14134 
f 14293 14134 14138 
f 14293 14138 14137 
f 14259 14140 14139 
f 14259 14141 14140 
f 14250 14142 14141 
f 14250 14143 14142 
f 14157 14144 14143 
f 14157 14145 14144 
f 14282 14146 14145 
f 14282 14147 14146 
f 14296 14148 14147 
f 14296 14149 14148 
f 14253 14150 14149 
f 14253 14151 14150 
f 14253 14152 14151 
f 14243 14153 14152 
f 14243 14154 14153 
f 14243 14155 14154 
f 14259 14156 14155 
f 14259 14139 14156 
f 14158 14176 14174 
f 14159 14158 14174 
f 14160 14159 14174 
f 14161 14160 14174 
f 14162 14161 14174 
f 14163 14162 14174 
f 14164 14163 14174 
f 14165 14164 14174 
f 14166 14165 14174 
f 14167 14166 14174 
f 14168 14167 14174 
f 14169 14168 14174 
f 14170 14169 14174 
f 14171 14170 14174 
f 14172 14171 14174 
f 14173 14172 14174 
f 14177 14173 14174 
f 14176 14177 14174 
f 14178 14179 14175 
f 14179 14180 14175 
f 14180 14181 14175 
f 14181 14182 14175 
f 14182 14183 14175 
f 14183 14184 14175 
f 14184 14185 14175 
f 14185 14186 14175 
f 14186 14187 14175 
f 14187 14188 14175 
f 14188 14189 14175 
f 14189 14190 14175 
f 14190 14191 14175 
f 14191 14192 14175 
f 14192 14193 14175 
f 14193 14194 14175 
f 14194 14195 14175 
f 14195 14178 14175 
f 14279 14176 14158 
f 14279 14158 14159 
f 14238 14159 14160 
f 14238 14160 14161 
f 14246 14161 14162 
f 14246 14162 14163 
f 14246 14163 14164 
f 14246 14164 14165 
f 14257 14165 14166 
f 14257 14166 14167 
f 14257 14167 14168 
f 14257 14168 14169 
f 14280 14169 14170 
f 14280 14170 14171 
f 14280 14171 14172 
f 14280 14172 14173 
f 14280 14173 14177 
f 14280 14177 14176 
f 14279 14179 14178 
f 14279 14180 14179 
f 14238 14181 14180 
f 14238 14182 14181 
f 14246 14183 14182 
f 14246 14184 14183 
f 14246 14185 14184 
f 14246 14186 14185 
f 14290 14187 14186 
f 14290 14188 14187 
f 14290 14189 14188 
f 14290 14190 14189 
f 14252 14191 14190 
f 14252 14192 14191 
f 14252 14193 14192 
f 14279 14194 14193 
f 14279 14195 14194 
f 14279 14178 14195 
f 14196 14214 14212 
f 14197 14196 14212 
f 14198 14197 14212 
f 14199 14198 14212 
f 14200 14199 14212 
f 14201 14200 14212 
f 14202 14201 14212 
f 14203 14202 14212 
f 14204 14203 14212 
f 14205 14204 14212 
f 14206 14205 14212 
f 14207 14206 14212 
f 14208 14207 14212 
f 14209 14208 14212 
f 14210 14209 14212 
f 14211 14210 14212 
f 14215 14211 14212 
f 14214 14215 14212 
f 14216 14217 14213 
f 14217 14218 14213 
f 14218 14219 14213 
f 14219 14220 14213 
f 14220 14221 14213 
f 14221 14222 14213 
f 14222 14223 14213 
f 14223 14224 14213 
f 14224 14225 14213 
f 14225 14226 14213 
f 14226 14227 14213 
f 14227 14228 14213 
f 14228 14229 14213 
f 14229 14230 14213 
f 14230 14231 14213 
f 14231 14232 14213 
f 14232 14233 14213 
f 14233 14216 14213 
f 14276 14214 14196 
f 14276 14196 14197 
f 14302 14197 14198 
f 14302 14198 14199 
f 14234 14199 14200 
f 14234 14200 14201 
f 14234 14201 14202 
f 14234 14202 14203 
f 14244 14203 14204 
f 14244 14204 14205 
f 14244 14205 14206 
f 14249 14206 14207 
f 14249 14207 14208 
f 14249 14208 14209 
f 14249 14209 14210 
f 14249 14210 14211 
f 14254 14211 14215 
f 14254 14215 14214 
f 14276 14217 14216 
f 14276 14218 14217 
f 14302 14219 14218 
f 14302 14220 14219 
f 14234 14221 14220 
f 14234 14222 14221 
f 14244 14223 14222 
f 14244 14224 14223 
f 14299 14225 14224 
f 14299 14226 14225 
f 14281 14227 14226 
f 14281 14228 14227 
f 14281 14229 14228 
f 14281 14230 14229 
f 14276 14231 14230 
f 14276 14232 14231 
f 14276 14233 14232 
f 14276 14216 14233 
f 14053 14289 14298 
f 14061 14275 14248 
f 14072 14298 14271 
f 14064 14292 14275 
f 13987 14287 14283 
f 14001 14255 14240 
f 13984 14283 14277 
f 13979 14277 14286 
f 13961 14303 14295 
f 13928 14265 14241 
f 13949 14241 14265 
f 13938 14268 14273 
f 14094 14258 14251 
f 14115 14284 14294 
f 14084 14297 14260 
f 14105 14260 14297 
f 14149 14296 14253 
f 14134 14293 14278 
f 14130 14253 14282 
f 14155 14243 14259 
f 14214 14276 14254 
f 14230 14281 14276 
f 14222 14234 14244 
f 14203 14244 14234 
f 14025 14274 14261 
f 14012 14245 14236 
f 14033 14236 14270 
f 14006 14261 14274 
f 14161 14246 14238 
f 14182 14238 14246 
f 14169 14280 14257 
f 14193 14252 14279 
f 13993 14004 14247 
f 13973 14247 14004 
f 14074 14271 14266 
f 14078 14239 14272 
f 14206 14249 14244 
f 14226 14299 14281 
f 14122 14157 14250 
f 14143 14250 14157 
f 14090 14251 14237 
f 14111 14285 14284 
f 14190 14290 14252 
f 14252 14290 14280 
f 14131 14278 14253 
f 14152 14253 14243 
f 14249 14254 14276 
f 14211 14254 14249 
f 13995 14247 14262 
f 14286 14277 14255 
f 14295 14303 14256 
f 13945 14241 14263 
f 14165 14257 14246 
f 14257 14280 14290 
f 14294 14284 14258 
f 14099 14242 14267 
f 14120 14250 14259 
f 14141 14259 14250 
f 14082 14260 14242 
f 14103 14242 14260 
f 14008 14236 14261 
f 14029 14261 14236 
f 13999 14269 14255 
f 14255 14269 14286 
f 14256 14263 14295 
f 13942 14263 14256 
f 13932 14264 13965 
f 13953 13965 14264 
f 13951 14265 13965 
f 13930 13965 14265 
f 14076 14266 14239 
f 14239 14266 14289 
f 14096 14267 14258 
f 14258 14267 14294 
f 13940 14256 14268 
f 14268 14256 14303 
f 14269 14262 14286 
f 13997 14262 14269 
f 14270 14236 14245 
f 14040 14270 14274 
f 14266 14271 14289 
f 14298 14289 14271 
f 14080 14272 14292 
f 14272 14239 14289 
f 14273 14268 14303 
f 13936 14273 14291 
f 14245 14288 14270 
f 14018 14288 14245 
f 14048 14235 14300 
f 14068 14300 14235 
f 14199 14234 14302 
f 14220 14302 14234 
f 14240 14255 14277 
f 14003 14240 14287 
f 14243 14253 14278 
f 14278 14293 14243 
f 14159 14238 14279 
f 14180 14279 14238 
f 14279 14252 14280 
f 14176 14279 14280 
f 14276 14281 14249 
f 14244 14249 14281 
f 14124 14282 14157 
f 14145 14157 14282 
f 13991 14301 14004 
f 13971 14004 14301 
f 14284 14285 14251 
f 14251 14258 14284 
f 14237 14251 14285 
f 14088 14237 14285 
f 14247 14286 14262 
f 13975 14286 14247 
f 14277 14283 14287 
f 14287 14240 14277 
f 14023 14274 14288 
f 14274 14270 14288 
f 14289 14248 14272 
f 14058 14248 14289 
f 14290 14246 14257 
f 14186 14246 14290 
f 13934 14291 14264 
f 14291 14273 14264 
f 14248 14275 14292 
f 14292 14272 14248 
f 14259 14243 14293 
f 14137 14259 14293 
f 14101 14294 14242 
f 14267 14242 14294 
f 13947 14295 14241 
f 14263 14241 14295 
f 14147 14282 14296 
f 14253 14296 14282 
f 14086 14285 14297 
f 14107 14297 14285 
f 14050 14298 14235 
f 14070 14235 14298 
f 14281 14299 14244 
f 14224 14244 14299 
f 14066 14275 14300 
f 14046 14300 14275 
f 13989 14283 14301 
f 13969 14301 14283 
f 14197 14302 14276 
f 14218 14276 14302 
f 13957 14264 14303 
f 14303 14264 14273 
f 14304 14321 14320 
f 14305 14304 14320 
f 14306 14305 14320 
f 14307 14306 14320 
f 14308 14307 14320 
f 14309 14308 14320 
f 14310 14309 14320 
f 14311 14310 14320 
f 14312 14311 14320 
f 14313 14312 14320 
f 14314 14313 14320 
f 14315 14314 14320 
f 14316 14315 14320 
f 14317 14316 14320 
f 14318 14317 14320 
f 14319 14318 14320 
f 14322 14319 14320 
f 14321 14322 14320 
f 14323 14324 14610 
f 14324 14325 14610 
f 14325 14326 14610 
f 14326 14327 14610 
f 14327 14328 14610 
f 14328 14329 14610 
f 14329 14330 14610 
f 14330 14331 14610 
f 14331 14332 14610 
f 14332 14333 14610 
f 14333 14334 14610 
f 14334 14335 14610 
f 14335 14336 14610 
f 14336 14337 14610 
f 14337 14338 14610 
f 14338 14339 14610 
f 14339 14340 14610 
f 14340 14323 14610 
f 14606 14321 14304 
f 14606 14304 14305 
f 14606 14305 14306 
f 14606 14306 14307 
f 14626 14307 14308 
f 14626 14308 14309 
f 14635 14309 14310 
f 14635 14310 14311 
f 14643 14311 14312 
f 14643 14312 14313 
f 14646 14313 14314 
f 14646 14314 14315 
f 14619 14315 14316 
f 14619 14316 14317 
f 14629 14317 14318 
f 14629 14318 14319 
f 14606 14319 14322 
f 14606 14322 14321 
f 14606 14324 14323 
f 14606 14325 14324 
f 14606 14326 14325 
f 14606 14327 14326 
f 14626 14328 14327 
f 14626 14329 14328 
f 14635 14330 14329 
f 14635 14331 14330 
f 14643 14332 14331 
f 14643 14333 14332 
f 14643 14334 14333 
f 14643 14335 14334 
f 14643 14336 14335 
f 14643 14337 14336 
f 14606 14338 14337 
f 14606 14339 14338 
f 14606 14340 14339 
f 14606 14323 14340 
f 14341 14358 14613 
f 14342 14341 14613 
f 14343 14342 14613 
f 14344 14343 14613 
f 14345 14344 14613 
f 14346 14345 14613 
f 14347 14346 14613 
f 14348 14347 14613 
f 14349 14348 14613 
f 14350 14349 14613 
f 14351 14350 14613 
f 14352 14351 14613 
f 14353 14352 14613 
f 14354 14353 14613 
f 14355 14354 14613 
f 14356 14355 14613 
f 14359 14356 14613 
f 14358 14359 14613 
f 14360 14361 14357 
f 14361 14362 14357 
f 14362 14363 14357 
f 14363 14364 14357 
f 14364 14365 14357 
f 14365 14366 14357 
f 14366 14367 14357 
f 14367 14368 14357 
f 14368 14369 14357 
f 14369 14370 14357 
f 14370 14371 14357 
f 14371 14372 14357 
f 14372 14373 14357 
f 14373 14374 14357 
f 14374 14375 14357 
f 14375 14376 14357 
f 14376 14377 14357 
f 14377 14360 14357 
f 14649 14358 14341 
f 14649 14341 14342 
f 14649 14342 14343 
f 14649 14343 14344 
f 14649 14344 14345 
f 14649 14345 14346 
f 14378 14346 14347 
f 14378 14347 14348 
f 14617 14348 14349 
f 14617 14349 14350 
f 14628 14350 14351 
f 14628 14351 14352 
f 14628 14352 14353 
f 14628 14353 14354 
f 14624 14354 14355 
f 14624 14355 14356 
f 14624 14356 14359 
f 14624 14359 14358 
f 14624 14361 14360 
f 14649 14362 14361 
f 14649 14363 14362 
f 14649 14364 14363 
f 14649 14365 14364 
f 14378 14366 14365 
f 14378 14367 14366 
f 14617 14368 14367 
f 14617 14369 14368 
f 14628 14370 14369 
f 14628 14371 14370 
f 14628 14372 14371 
f 14628 14373 14372 
f 14632 14374 14373 
f 14632 14375 14374 
f 14624 14376 14375 
f 14624 14377 14376 
f 14624 14360 14377 
f 14379 14396 14395 
f 14380 14379 14395 
f 14381 14380 14395 
f 14382 14381 14395 
f 14383 14382 14395 
f 14384 14383 14395 
f 14385 14384 14395 
f 14386 14385 14395 
f 14387 14386 14395 
f 14388 14387 14395 
f 14389 14388 14395 
f 14390 14389 14395 
f 14391 14390 14395 
f 14392 14391 14395 
f 14393 14392 14395 
f 14394 14393 14395 
f 14397 14394 14395 
f 14396 14397 14395 
f 14398 14399 14615 
f 14399 14400 14615 
f 14400 14401 14615 
f 14401 14402 14615 
f 14402 14403 14615 
f 14403 14404 14615 
f 14404 14405 14615 
f 14405 14406 14615 
f 14406 14407 14615 
f 14407 14408 14615 
f 14408 14409 14615 
f 14409 14410 14615 
f 14410 14411 14615 
f 14411 14412 14615 
f 14412 14413 14615 
f 14413 14414 14615 
f 14414 14415 14615 
f 14415 14398 14615 
f 14605 14396 14379 
f 14605 14379 14380 
f 14605 14380 14381 
f 14605 14381 14382 
f 14620 14382 14383 
f 14620 14383 14384 
f 14620 14384 14385 
f 14620 14385 14386 
f 14620 14386 14387 
f 14620 14387 14388 
f 14620 14388 14389 
f 14648 14389 14390 
f 14648 14390 14391 
f 14648 14391 14392 
f 14605 14392 14393 
f 14605 14393 14394 
f 14605 14394 14397 
f 14605 14397 14396 
f 14605 14399 14398 
f 14605 14400 14399 
f 14605 14401 14400 
f 14605 14402 14401 
f 14620 14403 14402 
f 14620 14404 14403 
f 14620 14405 14404 
f 14620 14406 14405 
f 14620 14407 14406 
f 14620 14408 14407 
f 14620 14409 14408 
f 14640 14410 14409 
f 14640 14411 14410 
f 14640 14412 14411 
f 14640 14413 14412 
f 14605 14414 14413 
f 14605 14415 14414 
f 14605 14398 14415 
f 14416 14432 14612 
f 14417 14416 14612 
f 14418 14417 14612 
f 14419 14418 14612 
f 14420 14419 14612 
f 14421 14420 14612 
f 14422 14421 14612 
f 14423 14422 14612 
f 14424 14423 14612 
f 14425 14424 14612 
f 14426 14425 14612 
f 14427 14426 14612 
f 14428 14427 14612 
f 14429 14428 14612 
f 14430 14429 14612 
f 14431 14430 14612 
f 14433 14431 14612 
f 14432 14433 14612 
f 14434 14435 14609 
f 14435 14436 14609 
f 14436 14437 14609 
f 14437 14438 14609 
f 14438 14439 14609 
f 14439 14440 14609 
f 14440 14441 14609 
f 14441 14442 14609 
f 14442 14443 14609 
f 14443 14444 14609 
f 14444 14445 14609 
f 14445 14446 14609 
f 14446 14447 14609 
f 14447 14448 14609 
f 14448 14449 14609 
f 14449 14450 14609 
f 14450 14451 14609 
f 14451 14434 14609 
f 14633 14432 14416 
f 14633 14416 14417 
f 14633 14417 14418 
f 14633 14418 14419 
f 14633 14419 14420 
f 14633 14420 14421 
f 14604 14421 14422 
f 14604 14422 14423 
f 14616 14423 14424 
f 14616 14424 14425 
f 14616 14425 14426 
f 14650 14426 14427 
f 14650 14427 14428 
f 14650 14428 14429 
f 14650 14429 14430 
f 14650 14430 14431 
f 14633 14431 14433 
f 14633 14433 14432 
f 14633 14435 14434 
f 14633 14436 14435 
f 14633 14437 14436 
f 14633 14438 14437 
f 14633 14439 14438 
f 14604 14440 14439 
f 14604 14441 14440 
f 14616 14442 14441 
f 14616 14443 14442 
f 14616 14444 14443 
f 14616 14445 14444 
f 14634 14446 14445 
f 14634 14447 14446 
f 14639 14448 14447 
f 14639 14449 14448 
f 14650 14450 14449 
f 14650 14451 14450 
f 14633 14434 14451 
f 14452 14469 14468 
f 14453 14452 14468 
f 14454 14453 14468 
f 14455 14454 14468 
f 14456 14455 14468 
f 14457 14456 14468 
f 14458 14457 14468 
f 14459 14458 14468 
f 14460 14459 14468 
f 14461 14460 14468 
f 14462 14461 14468 
f 14463 14462 14468 
f 14464 14463 14468 
f 14465 14464 14468 
f 14466 14465 14468 
f 14467 14466 14468 
f 14470 14467 14468 
f 14469 14470 14468 
f 14471 14472 14611 
f 14472 14473 14611 
f 14473 14474 14611 
f 14474 14475 14611 
f 14475 14476 14611 
f 14476 14477 14611 
f 14477 14478 14611 
f 14478 14479 14611 
f 14479 14480 14611 
f 14480 14481 14611 
f 14481 14482 14611 
f 14482 14483 14611 
f 14483 14484 14611 
f 14484 14485 14611 
f 14485 14486 14611 
f 14486 14487 14611 
f 14487 14488 14611 
f 14488 14471 14611 
f 14607 14469 14452 
f 14607 14452 14453 
f 14607 14453 14454 
f 14607 14454 14455 
f 14627 14455 14456 
f 14627 14456 14457 
f 14636 14457 14458 
f 14636 14458 14459 
f 14644 14459 14460 
f 14644 14460 14461 
f 14622 14461 14462 
f 14622 14462 14463 
f 14622 14463 14464 
f 14622 14464 14465 
f 14647 14465 14466 
f 14647 14466 14467 
f 14607 14467 14470 
f 14607 14470 14469 
f 14607 14472 14471 
f 14607 14473 14472 
f 14607 14474 14473 
f 14607 14475 14474 
f 14627 14476 14475 
f 14627 14477 14476 
f 14636 14478 14477 
f 14636 14479 14478 
f 14644 14480 14479 
f 14644 14481 14480 
f 14644 14482 14481 
f 14644 14483 14482 
f 14644 14484 14483 
f 14644 14485 14484 
f 14607 14486 14485 
f 14607 14487 14486 
f 14607 14488 14487 
f 14607 14471 14488 
f 14489 14506 14614 
f 14490 14489 14614 
f 14491 14490 14614 
f 14492 14491 14614 
f 14493 14492 14614 
f 14494 14493 14614 
f 14495 14494 14614 
f 14496 14495 14614 
f 14497 14496 14614 
f 14498 14497 14614 
f 14499 14498 14614 
f 14500 14499 14614 
f 14501 14500 14614 
f 14502 14501 14614 
f 14503 14502 14614 
f 14504 14503 14614 
f 14507 14504 14614 
f 14506 14507 14614 
f 14508 14509 14505 
f 14509 14510 14505 
f 14510 14511 14505 
f 14511 14512 14505 
f 14512 14513 14505 
f 14513 14514 14505 
f 14514 14515 14505 
f 14515 14516 14505 
f 14516 14517 14505 
f 14517 14518 14505 
f 14518 14519 14505 
f 14519 14520 14505 
f 14520 14521 14505 
f 14521 14522 14505 
f 14522 14523 14505 
f 14523 14524 14505 
f 14524 14525 14505 
f 14525 14508 14505 
f 14631 14506 14489 
f 14631 14489 14490 
f 14625 14490 14491 
f 14625 14491 14492 
f 14526 14492 14493 
f 14526 14493 14494 
f 14526 14494 14495 
f 14526 14495 14496 
f 14526 14496 14497 
f 14526 14497 14498 
f 14526 14498 14499 
f 14526 14499 14500 
f 14630 14500 14501 
f 14621 14501 14502 
f 14621 14502 14503 
f 14621 14503 14504 
f 14631 14504 14507 
f 14631 14507 14506 
f 14631 14509 14508 
f 14631 14510 14509 
f 14625 14511 14510 
f 14625 14512 14511 
f 14526 14513 14512 
f 14526 14514 14513 
f 14526 14515 14514 
f 14526 14516 14515 
f 14526 14517 14516 
f 14526 14518 14517 
f 14630 14519 14518 
f 14630 14520 14519 
f 14630 14521 14520 
f 14621 14522 14521 
f 14621 14523 14522 
f 14621 14524 14523 
f 14631 14525 14524 
f 14631 14508 14525 
f 14527 14545 14543 
f 14528 14527 14543 
f 14529 14528 14543 
f 14530 14529 14543 
f 14531 14530 14543 
f 14532 14531 14543 
f 14533 14532 14543 
f 14534 14533 14543 
f 14535 14534 14543 
f 14536 14535 14543 
f 14537 14536 14543 
f 14538 14537 14543 
f 14539 14538 14543 
f 14540 14539 14543 
f 14541 14540 14543 
f 14542 14541 14543 
f 14546 14542 14543 
f 14545 14546 14543 
f 14547 14548 14544 
f 14548 14549 14544 
f 14549 14550 14544 
f 14550 14551 14544 
f 14551 14552 14544 
f 14552 14553 14544 
f 14553 14554 14544 
f 14554 14555 14544 
f 14555 14556 14544 
f 14556 14557 14544 
f 14557 14558 14544 
f 14558 14559 14544 
f 14559 14560 14544 
f 14560 14561 14544 
f 14561 14562 14544 
f 14562 14563 14544 
f 14563 14564 14544 
f 14564 14547 14544 
f 14641 14545 14527 
f 14641 14527 14528 
f 14641 14528 14529 
f 14641 14529 14530 
f 14608 14530 14531 
f 14608 14531 14532 
f 14645 14532 14533 
f 14645 14533 14534 
f 14645 14534 14535 
f 14645 14535 14536 
f 14645 14536 14537 
f 14645 14537 14538 
f 14638 14538 14539 
f 14638 14539 14540 
f 14638 14540 14541 
f 14623 14541 14542 
f 14623 14542 14546 
f 14623 14546 14545 
f 14641 14548 14547 
f 14641 14549 14548 
f 14641 14550 14549 
f 14641 14551 14550 
f 14608 14552 14551 
f 14608 14553 14552 
f 14645 14554 14553 
f 14645 14555 14554 
f 14645 14556 14555 
f 14645 14557 14556 
f 14645 14558 14557 
f 14645 14559 14558 
f 14623 14560 14559 
f 14623 14561 14560 
f 14623 14562 14561 
f 14623 14563 14562 
f 14623 14564 14563 
f 14623 14547 14564 
f 14565 14583 14581 
f 14566 14565 14581 
f 14567 14566 14581 
f 14568 14567 14581 
f 14569 14568 14581 
f 14570 14569 14581 
f 14571 14570 14581 
f 14572 14571 14581 
f 14573 14572 14581 
f 14574 14573 14581 
f 14575 14574 14581 
f 14576 14575 14581 
f 14577 14576 14581 
f 14578 14577 14581 
f 14579 14578 14581 
f 14580 14579 14581 
f 14584 14580 14581 
f 14583 14584 14581 
f 14585 14586 14582 
f 14586 14587 14582 
f 14587 14588 14582 
f 14588 14589 14582 
f 14589 14590 14582 
f 14590 14591 14582 
f 14591 14592 14582 
f 14592 14593 14582 
f 14593 14594 14582 
f 14594 14595 14582 
f 14595 14596 14582 
f 14596 14597 14582 
f 14597 14598 14582 
f 14598 14599 14582 
f 14599 14600 14582 
f 14600 14601 14582 
f 14601 14602 14582 
f 14602 14585 14582 
f 14618 14583 14565 
f 14618 14565 14566 
f 14618 14566 14567 
f 14618 14567 14568 
f 14603 14568 14569 
f 14603 14569 14570 
f 14603 14570 14571 
f 14603 14571 14572 
f 14642 14572 14573 
f 14642 14573 14574 
f 14642 14574 14575 
f 14637 14575 14576 
f 14637 14576 14577 
f 14637 14577 14578 
f 14637 14578 14579 
f 14637 14579 14580 
f 14618 14580 14584 
f 14618 14584 14583 
f 14618 14586 14585 
f 14618 14587 14586 
f 14618 14588 14587 
f 14618 14589 14588 
f 14603 14590 14589 
f 14603 14591 14590 
f 14603 14592 14591 
f 14603 14593 14592 
f 14642 14594 14593 
f 14642 14595 14594 
f 14642 14596 14595 
f 14642 14597 14596 
f 14642 14598 14597 
f 14642 14599 14598 
f 14618 14600 14599 
f 14618 14601 14600 
f 14618 14602 14601 
f 14618 14585 14602 
f 14421 14604 14633 
f 14423 14616 14604 
f 14441 14604 14616 
f 14439 14633 14604 
f 14367 14378 14617 
f 14373 14628 14632 
f 14348 14617 14378 
f 14354 14624 14628 
f 14568 14603 14618 
f 14589 14618 14603 
f 14572 14642 14603 
f 14593 14603 14642 
f 14337 14643 14606 
f 14317 14629 14619 
f 14311 14643 14635 
f 14331 14635 14643 
f 14382 14620 14605 
f 14413 14640 14605 
f 14402 14605 14620 
f 14392 14605 14648 
f 14518 14526 14630 
f 14504 14631 14621 
f 14500 14630 14526 
f 14524 14621 14631 
f 14485 14644 14607 
f 14467 14607 14647 
f 14459 14644 14636 
f 14479 14636 14644 
f 14559 14645 14623 
f 14530 14608 14641 
f 14551 14641 14608 
f 14538 14638 14645 
f 14361 14624 14649 
f 14358 14649 14624 
f 14492 14526 14625 
f 14512 14625 14526 
f 14327 14606 14626 
f 14307 14626 14606 
f 14455 14627 14607 
f 14475 14607 14627 
f 14369 14617 14628 
f 14350 14628 14617 
f 14319 14606 14629 
f 14606 14643 14629 
f 14501 14621 14630 
f 14521 14630 14621 
f 14490 14625 14631 
f 14510 14631 14625 
f 14375 14632 14624 
f 14628 14624 14632 
f 14447 14634 14639 
f 14426 14650 14616 
f 14634 14616 14650 
f 14445 14616 14634 
f 14309 14635 14626 
f 14329 14626 14635 
f 14457 14636 14627 
f 14477 14627 14636 
f 14599 14642 14618 
f 14580 14618 14637 
f 14541 14623 14638 
f 14623 14645 14638 
f 14431 14633 14650 
f 14451 14650 14633 
f 14389 14648 14620 
f 14409 14620 14640 
f 14545 14641 14623 
f 14547 14623 14641 
f 14575 14637 14642 
f 14618 14642 14637 
f 14619 14629 14643 
f 14315 14619 14646 
f 14465 14647 14622 
f 14461 14622 14644 
f 14532 14645 14608 
f 14553 14608 14645 
f 14646 14619 14643 
f 14313 14646 14643 
f 14622 14647 14644 
f 14607 14644 14647 
f 14648 14605 14640 
f 14640 14620 14648 
f 14365 14649 14378 
f 14346 14378 14649 
f 14639 14634 14650 
f 14449 14639 14650 
f 14781 15258 14859 
f 14751 14973 15184 
f 15061 14735 15066 
f 15119 14941 14995 
f 14752 15157 14890 
f 14799 15080 15260 
f 14739 15079 14804 
f 15258 14795 14859 
f 15012 15070 14726 
f 14933 15023 15055 
f 14766 14749 14816 
f 14911 15269 14863 
f 15074 15138 15226 
f 14894 14788 14929 
f 15138 14958 15199 
f 15017 14780 14773 
f 14907 15009 15190 
f 14837 15104 14871 
f 15044 14919 15033 
f 15253 14844 14999 
f 14815 15192 15202 
f 15011 14905 15223 
f 14983 15052 14850 
f 14906 14806 14804 
f 15116 14840 14701 
f 14881 15229 15043 
f 15110 14853 15220 
f 15106 14671 15304 
f 15274 14908 15124 
f 14781 15032 15159 
f 14828 14957 14879 
f 14824 15257 14987 
f 15285 14950 14708 
f 15095 14716 14850 
f 14846 14956 14785 
f 15124 15049 14797 
f 14930 15097 14900 
f 14815 15282 14917 
f 15093 15261 15038 
f 14790 15266 14969 
f 14821 14869 14931 
f 14856 14959 14993 
f 15030 14770 14940 
f 15046 14940 14770 
f 15057 15247 14994 
f 15291 15001 14784 
f 14793 15008 15033 
f 15239 15008 14997 
f 15148 14857 15142 
f 14971 14779 14884 
f 14958 14880 15085 
f 15021 15102 15091 
f 15038 15261 14826 
f 15301 15101 15217 
f 14990 15224 15216 
f 14820 15029 14947 
f 14923 15183 14831 
f 14966 15083 14907 
f 15003 15179 15075 
f 14882 15265 15259 
f 14995 14782 14861 
f 15056 14936 15152 
f 14792 14963 15135 
f 15087 15221 15071 
f 14841 14736 15166 
f 15049 15124 14908 
f 15087 14942 14839 
f 14788 15198 14929 
f 14973 14751 14988 
f 15148 15007 15013 
f 15242 14825 14684 
f 14739 15053 14823 
f 15041 14748 14962 
f 15111 15254 14877 
f 14830 14896 15197 
f 14748 14822 14962 
f 15002 15245 15157 
f 15244 15158 14981 
f 15133 14885 15214 
f 14794 14763 15020 
f 15224 15023 15216 
f 14893 15042 14953 
f 15249 15059 15282 
f 14871 15104 15143 
f 14766 14816 15147 
f 15175 15169 15092 
f 15187 15045 14989 
f 14983 15155 15052 
f 14722 14820 14947 
f 14783 15089 14802 
f 14851 15235 14944 
f 15185 15183 14923 
f 14661 14866 15122 
f 14905 15011 15228 
f 14743 15127 14892 
f 14740 15146 14790 
f 15151 14847 14744 
f 14883 15144 14974 
f 15043 14789 15212 
f 15048 14838 14889 
f 14792 14927 15197 
f 14799 15094 15080 
f 14959 14865 15246 
f 15256 15217 15101 
f 15281 14785 14829 
f 14710 15275 14968 
f 14723 15222 15086 
f 15283 15159 15032 
f 14716 14680 15155 
f 14895 14690 14882 
f 14984 14950 15176 
f 15288 14987 15257 
f 14891 14839 14942 
f 15014 14930 14881 
f 15112 15276 15287 
f 15259 15265 14949 
f 15005 14899 15296 
f 14691 14721 14832 
f 14863 15131 14911 
f 14706 14875 15040 
f 15163 15269 14911 
f 14912 15294 14801 
f 14858 15105 15182 
f 15032 14915 15283 
f 14980 14880 14724 
f 14725 15263 14699 
f 14853 14725 14699 
f 15108 14848 15143 
f 14682 15130 14848 
f 15132 14853 14699 
f 14869 15094 14922 
f 15133 14967 14885 
f 15208 14761 15239 
f 15238 15271 15102 
f 15238 14720 14893 
f 14999 15060 15253 
f 14904 15007 14817 
f 15026 15144 15084 
f 15079 14731 14804 
f 14732 14978 14683 
f 15202 14757 14815 
f 15071 14976 14819 
f 15033 15008 14803 
f 14734 14696 15154 
f 14870 15030 14970 
f 14913 15240 14676 
f 15240 15001 14711 
f 15168 14808 14836 
f 15166 14932 14666 
f 14939 15225 15165 
f 14939 14913 14676 
f 15137 15081 14738 
f 15219 15054 15156 
f 14916 14876 14739 
f 14938 14827 14670 
f 14740 15000 15146 
f 15127 14872 15011 
f 15140 15021 15091 
f 14672 15172 14963 
f 15158 15244 14659 
f 15272 14742 15167 
f 14954 15002 14655 
f 14855 15151 14744 
f 14972 15096 14744 
f 14875 14687 15040 
f 15280 14745 14945 
f 14746 14953 14656 
f 15045 14814 14883 
f 14705 14747 15114 
f 15278 14665 14748 
f 14749 15126 15149 
f 14921 15060 14874 
f 15306 14931 14856 
f 15101 15022 15256 
f 15165 14904 15028 
f 15165 15028 14939 
f 15125 14655 15002 
f 14671 15125 15304 
f 15246 14668 14753 
f 14965 15284 15270 
f 15209 14711 15001 
f 15305 15209 14974 
f 15206 14903 15150 
f 15189 15226 15050 
f 14696 14734 14946 
f 14943 15142 14857 
f 14683 14857 15202 
f 15288 14758 14987 
f 15073 15003 14987 
f 15179 14867 15024 
f 14947 15090 15163 
f 14761 14728 15292 
f 15107 14761 14951 
f 14944 15072 14851 
f 14851 14697 15162 
f 15116 14701 15300 
f 15300 14831 14688 
f 15006 15004 15260 
f 14749 14929 14816 
f 14942 14694 14891 
f 14836 14808 15174 
f 15126 14704 15149 
f 14842 14935 14893 
f 15152 15134 14933 
f 14738 15081 15268 
f 14662 15164 14980 
f 15295 15069 15201 
f 14769 14653 14865 
f 14918 15015 14864 
f 14909 14680 15114 
f 15097 14771 14900 
f 15059 14703 14771 
f 14670 14772 14938 
f 15170 15006 15230 
f 14714 15103 14773 
f 14910 15256 15016 
f 15241 14994 14774 
f 15114 15117 14705 
f 14775 14984 15239 
f 14997 14775 15239 
f 15113 15044 14692 
f 14777 15203 15293 
f 15144 15026 14669 
f 14658 15251 15014 
f 15282 14778 14917 
f 14684 14825 14975 
f 15111 14877 14779 
f 15289 14968 15076 
f 14780 15281 15233 
f 14889 14781 14859 
f 14712 14896 15010 
f 14782 14995 15093 
f 15296 14949 15005 
f 14783 14802 14691 
f 14741 15056 15200 
f 14833 15061 15066 
f 14989 14784 14681 
f 14910 15295 14888 
f 15182 15186 14858 
f 15238 15298 15005 
f 15048 15021 15140 
f 15115 15251 14658 
f 15307 15191 14689 
f 14998 15212 14789 
f 15088 15252 14789 
f 14790 14886 15227 
f 15037 15076 15017 
f 14809 14791 14843 
f 14963 14792 14672 
f 14838 15048 15140 
f 14919 14793 15033 
f 15122 14997 14661 
f 14794 14916 15181 
f 14881 15043 14794 
f 14795 15120 14685 
f 15167 14674 15272 
f 14796 14680 14716 
f 15123 14795 15258 
f 15137 14738 14977 
f 15275 14797 14968 
f 15194 15171 14693 
f 14798 14912 15049 
f 15047 14922 14727 
f 15190 15004 14764 
f 14973 15169 15184 
f 14913 14939 14737 
f 15086 14801 14723 
f 14801 15294 14723 
f 15131 15248 14706 
f 14948 15141 15068 
f 14733 14819 14803 
f 15107 14951 14928 
f 14665 15053 15039 
f 14823 15053 14665 
f 14664 15082 14805 
f 14906 15057 14806 
f 15100 15243 15161 
f 15161 15146 15000 
f 15019 15175 15092 
f 14852 15019 15092 
f 15055 15089 14783 
f 14843 15089 15055 
f 15121 14988 14751 
f 14874 15264 14921 
f 14731 15129 14804 
f 14811 14731 15077 
f 15022 15101 14931 
f 14890 15106 14914 
f 14698 14915 14813 
f 14698 15297 14830 
f 15077 15084 15144 
f 14747 14705 15058 
f 15007 14992 15013 
f 15059 15249 15165 
f 14765 15147 14816 
f 14816 15178 14765 
f 15142 15234 15148 
f 14817 14730 14991 
f 15227 14886 14818 
f 14657 14847 14982 
f 14819 14692 14803 
f 15113 14692 15139 
f 15018 15206 14820 
f 15040 15173 14820 
f 15306 14713 15231 
f 14856 14993 15306 
f 15263 14655 14699 
f 14697 14851 15072 
f 14823 14665 15278 
f 15255 14879 14957 
f 15113 14898 14824 
f 14825 14946 15232 
f 14940 14970 15030 
f 14656 15145 14826 
f 15181 14916 15099 
f 14823 14944 15099 
f 15109 15211 15279 
f 15279 15211 15003 
f 15233 14773 14780 
f 14910 15016 14829 
f 15156 14662 14980 
f 14662 15156 15054 
f 15006 14688 14831 
f 14831 15230 15006 
f 15051 15172 14672 
f 15200 14832 15051 
f 14833 15012 15052 
f 15052 14784 14833 
f 14998 15062 14834 
f 15293 15203 14834 
f 14854 15204 14835 
f 14944 15235 15204 
f 14836 14765 14666 
f 14836 14666 14932 
f 15031 15104 14837 
f 15175 15019 15031 
f 15262 14712 15034 
f 15262 15034 15267 
f 15257 14976 14718 
f 14758 15288 14839 
f 14821 15231 15080 
f 14753 15284 14965 
f 14841 15108 15143 
f 15166 15108 14841 
f 14953 14746 15177 
f 15134 15152 15078 
f 14660 15236 14791 
f 14945 15150 14903 
f 14651 15142 14943 
f 15268 14845 14738 
f 15274 15299 14845 
f 15213 14846 14715 
f 15063 15076 14968 
f 15226 15189 14696 
f 15012 14726 14981 
f 15066 15143 14848 
f 14686 15069 14979 
f 15193 15182 14960 
f 14850 14742 15095 
f 14981 15158 15012 
f 15235 14851 14762 
f 15083 15009 14907 
f 15126 14749 14766 
f 15174 14808 14852 
f 14853 15190 15009 
f 15132 14727 14853 
f 15204 14854 14772 
f 15230 14831 15183 
f 14744 14710 14855 
f 15076 15037 15289 
f 15188 14931 15101 
f 14750 14959 14856 
f 14857 15148 15013 
f 14757 15202 14857 
f 15082 14723 15294 
f 14956 14858 15186 
f 15277 14889 14859 
f 14685 14859 14795 
f 15199 14972 14873 
f 15081 15137 14860 
f 14690 14861 14782 
f 15285 15176 14950 
f 14967 14728 14885 
f 14862 15292 14728 
f 14955 15135 14963 
f 14721 15131 14863 
f 14864 14884 14779 
f 15136 14864 14779 
f 14668 15246 14865 
f 14866 14708 14950 
f 15179 15003 14867 
f 14996 14674 15167 
f 14674 15296 15128 
f 14922 15047 14869 
f 15106 14869 14671 
f 14970 14986 14870 
f 14806 15057 14652 
f 14871 14737 14837 
f 14913 14737 14871 
f 15000 14740 14872 
f 15228 15103 14905 
f 14744 14873 14972 
f 15138 15050 15226 
f 14704 14988 15149 
f 14810 14874 15060 
f 14745 14687 14875 
f 15141 14948 14745 
f 15203 14777 14876 
f 14777 15084 15079 
f 14975 14877 14684 
f 14958 15138 15074 
f 14878 14994 15098 
f 15058 14705 14878 
f 15210 14879 14937 
f 14824 14673 15109 
f 15085 14880 14768 
f 14880 14980 15164 
f 15014 14881 14658 
f 14794 15020 14881 
f 14659 14967 15133 
f 14659 15133 15259 
f 15209 15001 14754 
f 14883 15196 15045 
f 14884 14864 15015 
f 14680 14796 14884 
f 14700 14717 14885 
f 14885 15208 14700 
f 14886 15161 15243 
f 14982 14886 14657 
f 14887 14805 15082 
f 14912 15064 14887 
f 14959 14750 15153 
f 15201 14888 15295 
f 15034 14712 15065 
f 15010 15065 14712 
f 14905 15273 15223 
f 15223 14679 14812 
f 14788 15307 15198 
f 14839 14891 14758 
f 15157 15245 14892 
f 15127 15011 14892 
f 15042 14893 14729 
f 14729 14893 14720 
f 14929 14749 14894 
f 15215 14810 15060 
f 14895 15218 14964 
f 15067 14895 14885 
f 14813 15010 14896 
f 14813 15297 14698 
f 14770 15030 15117 
f 14774 14897 15241 
f 15139 14898 15113 
f 14976 15257 14819 
f 15120 15128 14685 
f 14685 14899 15021 
f 14703 14711 15088 
f 14771 14703 14900 
f 14840 14901 14701 
f 15000 14902 14807 
f 14903 15280 14945 
f 15173 15018 14820 
f 14904 15121 15028 
f 14667 14904 14817 
f 14905 15103 14714 
f 15022 14905 14714 
f 15057 14906 15247 
f 15129 14731 14811 
f 14907 14764 15170 
f 14764 14907 15190 
f 15163 15090 14908 
f 14908 15090 15049 
f 14747 14909 15114 
f 15187 15155 14747 
f 14829 14785 14910 
f 15295 14910 14785 
f 15237 15131 14706 
f 14706 15040 15237 
f 14693 14912 15194 
f 14693 15064 14912 
f 15302 15001 15240 
f 14913 14871 14735 
f 14914 14752 14890 
f 15286 15304 15125 
f 14971 15111 14779 
f 15250 14724 14915 
f 14876 15027 15203 
f 14794 15043 14916 
f 14719 14917 14778 
f 15192 15112 15287 
f 15117 15114 14770 
f 14770 14918 15046 
f 14793 14919 15303 
f 14920 15120 14795 
f 14795 15123 14920 
f 15234 14921 15264 
f 15253 15234 14844 
f 15004 14727 14922 
f 14799 14922 15094 
f 15300 14701 15118 
f 14763 14794 14923 
f 15180 14820 15206 
f 14911 14925 15163 
f 14820 14722 15040 
f 14702 14953 14926 
f 14933 15134 15023 
f 14830 15197 14927 
f 15156 15135 15219 
f 15166 14666 14733 
f 14928 14733 15107 
f 15178 14816 14929 
f 15290 15198 15178 
f 15229 14789 15043 
f 14789 14930 15088 
f 14812 14931 14869 
f 14812 14679 14931 
f 15031 15019 15168 
f 14932 15166 14736 
f 15055 14783 14933 
f 14933 14783 15152 
f 14741 14672 15267 
f 15267 14672 15262 
f 15078 14767 14935 
f 14767 15091 15102 
f 14741 14936 15056 
f 15091 14741 14934 
f 14879 14654 14937 
f 14776 14937 14654 
f 15185 14923 14794 
f 15181 14938 14794 
f 14800 14939 15028 
f 15184 14737 14939 
f 14940 15046 15136 
f 15232 14940 14825 
f 15285 14708 14941 
f 14942 14765 14694 
f 14942 15087 14765 
f 14857 14683 14943 
f 14827 15099 14944 
f 14944 15204 14827 
f 15150 14945 15236 
f 14945 14745 14843 
f 14696 14946 14756 
f 15242 14946 14825 
f 14760 14947 15029 
f 14947 15171 14798 
f 14948 14802 15089 
f 14706 15248 14948 
f 14949 15265 14782 
f 14949 14782 15093 
f 15122 14866 14950 
f 14775 14950 14984 
f 14928 14951 14682 
f 15130 14682 14951 
f 14952 14691 15248 
f 14721 14691 14952 
f 14953 14842 14893 
f 14842 14953 14702 
f 14954 15245 15002 
f 14863 14955 14721 
f 15054 14955 14863 
f 14846 15213 14956 
f 14858 14956 15213 
f 15075 15179 14957 
f 14958 14877 15254 
f 14684 14877 14958 
f 14865 14959 14769 
f 14769 14959 15153 
f 15205 14686 14960 
f 14960 15182 15105 
f 14683 14961 14732 
f 14732 15202 15192 
f 15110 14851 15162 
f 15263 14725 14962 
f 14721 14963 14832 
f 14963 14721 14955 
f 14690 14895 14964 
f 14861 14690 15176 
f 14753 14965 14840 
f 14901 14840 14965 
f 14966 14835 15204 
f 14907 15170 14966 
f 15244 14981 14862 
f 14728 14967 14862 
f 14968 14715 15063 
f 14715 14968 15086 
f 14969 15266 15103 
f 14969 14740 14790 
f 14734 14986 14970 
f 14970 14940 15232 
f 14915 14724 14971 
f 15283 14915 14971 
f 14972 14675 15096 
f 14675 14972 14977 
f 14973 15092 15169 
f 14704 14973 14988 
f 15144 14669 14974 
f 14834 15062 15293 
f 14779 14975 15136 
f 14779 14877 14975 
f 14839 14718 14976 
f 14839 15221 15087 
f 15299 14797 14977 
f 14675 14977 14797 
f 14978 15287 15276 
f 15182 15193 14979 
f 14785 14849 15295 
f 14830 15160 14698 
f 14724 15250 14980 
f 14848 15130 14981 
f 14726 14848 14981 
f 14855 14818 15151 
f 14886 14982 14818 
f 14850 14716 14983 
f 15155 14983 14716 
f 15176 15218 14984 
f 14717 14700 14984 
f 14985 14796 15123 
f 15159 14985 14781 
f 14986 14652 14870 
f 14673 14824 14987 
f 14673 14987 15003 
f 14874 14988 15121 
f 14988 14874 14810 
f 15291 14754 15001 
f 15045 15196 14989 
f 14809 14990 14791 
f 14991 14667 14817 
f 14667 15264 14874 
f 14992 14815 14757 
f 15282 14815 15249 
f 14713 15306 14993 
f 14713 15246 14753 
f 14994 14705 14774 
f 14878 14705 14994 
f 14995 14941 14677 
f 15261 14677 14826 
f 14868 14949 15296 
f 14868 14996 14659 
f 14793 14997 15008 
f 14997 14793 14661 
f 14998 14789 15252 
f 15305 14711 15209 
f 14999 14788 14894 
f 15127 15000 14872 
f 15127 14902 15000 
f 15001 15302 14735 
f 14735 15061 15001 
f 15286 15125 15002 
f 15157 14752 15002 
f 14689 15003 15307 
f 15003 14689 14867 
f 14922 14799 15004 
f 15004 14799 15260 
f 15093 15005 14949 
f 14720 15238 15005 
f 14764 15006 15170 
f 15006 14764 15004 
f 14992 15007 14695 
f 14904 14695 15007 
f 15008 15239 14761 
f 14761 15207 15008 
f 15110 15220 14851 
f 14762 14851 15220 
f 14889 15010 14781 
f 14813 14781 15010 
f 14892 15011 14709 
f 14890 14709 15011 
f 15012 14742 14850 
f 15052 15012 14850 
f 14757 15013 14992 
f 15013 14757 14857 
f 15097 14930 15014 
f 15251 14778 15014 
f 15114 14680 15015 
f 15015 14680 14884 
f 14773 15233 15016 
f 14773 15016 14714 
f 15266 14790 15017 
f 15017 14790 15037 
f 15206 15018 14903 
f 15280 14903 15018 
f 15019 14852 14808 
f 14808 15168 15019 
f 15020 14658 14881 
f 15298 14786 15021 
f 15102 15021 14786 
f 14931 14679 15022 
f 14905 15022 15273 
f 15134 14926 15023 
f 14746 15023 15177 
f 15024 14663 14759 
f 15025 15260 15080 
f 14688 15116 15300 
f 14669 15026 14777 
f 15084 14777 15026 
f 14876 14916 15027 
f 14707 15027 14916 
f 14751 15028 15121 
f 14800 15028 14751 
f 15029 14820 15180 
f 14897 15117 15030 
f 15030 14870 14897 
f 15168 14736 15031 
f 14841 15031 14736 
f 14915 15032 14813 
f 14781 14813 15032 
f 14692 15033 14803 
f 15044 15033 14692 
f 15034 14838 15267 
f 14838 15034 14889 
f 15035 14756 14946 
f 15226 15035 15074 
f 14924 15150 15036 
f 14855 15289 15037 
f 15227 15037 14790 
f 14729 14720 15038 
f 14826 15145 15038 
f 15039 14804 14806 
f 15237 15040 14925 
f 14722 14925 15040 
f 15278 14748 15041 
f 14697 15041 14962 
f 14656 15042 15145 
f 15042 14656 14953 
f 15043 14707 14916 
f 14707 15043 15212 
f 15044 14776 14919 
f 15113 14776 15044 
f 15058 15045 14747 
f 15045 15187 14747 
f 14864 15136 15046 
f 14864 15046 14918 
f 15047 14671 14869 
f 15132 15125 14671 
f 14859 14685 15277 
f 15021 15048 14685 
f 15049 14801 15086 
f 15049 14912 14801 
f 15189 15050 14744 
f 14873 14744 15050 
f 15051 14741 15200 
f 14672 14741 15051 
f 14681 14784 15052 
f 15187 15052 15155 
f 15039 15053 14804 
f 14739 14804 15053 
f 15054 14845 14662 
f 14845 15054 15274 
f 14809 14843 15055 
f 15055 15023 15224 
f 15152 14783 15056 
f 15200 15056 14783 
f 14870 14652 15057 
f 14994 15241 15057 
f 15045 15058 14814 
f 14878 14814 15058 
f 14703 15059 14676 
f 15165 15225 15059 
f 15060 14894 14749 
f 14894 15060 14999 
f 14784 15001 15061 
f 15061 14833 14784 
f 14998 14974 15062 
f 15062 14974 14669 
f 14846 15076 15063 
f 14846 15063 14715 
f 14805 14887 15064 
f 15064 15195 14805 
f 15034 15065 14889 
f 15010 14889 15065 
f 15066 14726 15070 
f 14848 14726 15066 
f 15067 14885 14717 
f 15218 14895 15067 
f 14875 14706 15068 
f 14948 15068 14706 
f 14653 15201 15069 
f 14833 15070 15012 
f 14833 15066 15070 
f 15071 14819 14733 
f 14666 15071 14733 
f 15072 14823 15278 
f 14823 15072 14944 
f 15198 15307 14758 
f 14987 14758 15073 
f 15035 14684 15074 
f 14958 15074 14684 
f 15279 15003 15075 
f 14957 14828 15075 
f 15281 14780 15076 
f 14780 15017 15076 
f 15098 15077 14878 
f 15144 14878 15077 
f 14935 14842 15078 
f 14702 15078 14842 
f 14876 14777 15079 
f 14876 15079 14739 
f 15080 14840 15025 
f 14840 15080 15231 
f 15164 15268 15081 
f 14860 14768 15081 
f 14723 15082 14858 
f 14858 15082 15105 
f 15083 15235 14762 
f 15009 15083 14762 
f 14731 15079 15084 
f 15084 15077 14731 
f 15199 14958 15085 
f 14768 14860 15085 
f 15086 14968 14797 
f 15049 15086 14797 
f 14666 14765 15087 
f 14666 15087 15071 
f 14900 15088 14930 
f 14900 14703 15088 
f 14948 15089 14745 
f 14843 14745 15089 
f 15090 14947 14798 
f 15049 15090 14798 
f 14936 15091 14767 
f 14741 15091 14936 
f 14973 14704 15092 
f 14704 14852 15092 
f 15038 14720 15093 
f 14720 15005 15093 
f 15080 15094 14821 
f 14869 14821 15094 
f 14716 15095 14920 
f 15272 15095 14742 
f 14675 14710 15096 
f 14710 14744 15096 
f 14778 14771 15097 
f 15097 15014 14778 
f 15077 15098 14811 
f 14906 14811 15098 
f 15099 14739 14823 
f 14739 15099 14916 
f 14902 15100 14807 
f 14750 15101 15301 
f 15101 14750 15188 
f 15102 14935 14767 
f 15271 14935 15102 
f 14740 15103 15228 
f 15103 14740 14969 
f 14841 15104 15031 
f 14841 15143 15104 
f 15082 14664 15105 
f 15205 14960 15105 
f 15106 14812 14869 
f 14890 14812 15106 
f 14733 14803 15107 
f 15207 14803 15008 
f 14848 15108 14682 
f 15108 15166 14682 
f 14879 15109 15279 
f 15109 14879 14824 
f 15110 15162 14725 
f 14725 14853 15110 
f 15111 14724 14880 
f 15111 14971 14724 
f 15276 15112 14719 
f 14917 14719 15112 
f 14776 15113 14937 
f 14937 15113 15210 
f 14918 14770 15114 
f 15015 14918 15114 
f 15276 14719 15115 
f 15025 14840 15116 
f 15116 14688 15025 
f 14774 14705 15117 
f 14774 15117 14897 
f 14763 14923 15118 
f 14941 15119 15285 
f 14861 15119 14995 
f 15128 15120 14674 
f 14920 14674 15120 
f 15121 14667 14874 
f 14667 15121 14904 
f 14997 15122 14775 
f 14775 15122 14950 
f 14920 15123 14716 
f 15123 14796 14716 
f 15299 15274 15124 
f 14797 15299 15124 
f 15125 15132 14699 
f 15125 14699 14655 
f 15126 15174 14852 
f 14704 15126 14852 
f 14743 14678 15127 
f 14678 14902 15127 
f 14899 14685 15128 
f 15128 15296 14899 
f 15129 14906 14804 
f 14906 15129 14811 
f 14862 14981 15130 
f 15292 14862 15130 
f 14721 14952 15131 
f 15248 15131 14952 
f 15047 15132 14671 
f 15132 15047 14727 
f 14882 15259 15133 
f 15133 15214 14882 
f 15078 14702 15134 
f 14926 15134 14702 
f 15135 14927 14792 
f 14830 14927 15135 
f 14825 15136 14975 
f 15136 14825 14940 
f 14972 14860 15137 
f 14972 15137 14977 
f 15199 14873 15138 
f 15050 15138 14873 
f 14692 14819 15139 
f 14819 14898 15139 
f 15091 14934 15140 
f 14838 15140 14934 
f 15141 14745 14875 
f 14875 15068 15141 
f 14651 14844 15142 
f 14844 15234 15142 
f 14735 14871 15143 
f 15066 14735 15143 
f 15144 14883 14814 
f 14878 15144 14814 
f 15038 15145 14729 
f 15042 14729 15145 
f 15146 15161 14886 
f 14886 14790 15146 
f 15147 14765 14836 
f 15174 15147 14836 
f 15148 14817 15007 
f 14730 14817 15148 
f 15149 14810 14749 
f 14810 15149 14988 
f 15236 14660 15150 
f 14660 15036 15150 
f 15151 14818 14982 
f 14847 15151 14982 
f 14767 15152 14936 
f 15078 15152 14767 
f 15301 15153 14750 
f 15153 14888 14769 
f 14734 15154 14986 
f 14680 14909 15155 
f 14909 14747 15155 
f 14830 15156 15160 
f 15156 14830 15135 
f 14892 14709 15157 
f 14890 15157 14709 
f 15167 14742 15158 
f 14742 15012 15158 
f 14985 15159 14796 
f 15159 15283 14796 
f 14980 15160 15156 
f 15160 14980 15250 
f 15000 14807 15161 
f 15161 14807 15100 
f 14962 15162 14697 
f 14962 14725 15162 
f 14947 15163 14722 
f 14925 14722 15163 
f 15164 14768 14880 
f 14768 15164 15081 
f 15165 15249 14695 
f 14904 15165 14695 
f 14928 14682 15166 
f 14928 15166 14733 
f 15167 14659 14996 
f 14659 15167 15158 
f 14736 15168 14932 
f 14836 14932 15168 
f 14737 15184 15169 
f 14737 15169 14837 
f 14835 14966 15170 
f 15230 14835 15170 
f 14947 14760 15171 
f 15172 15051 14832 
f 14832 14963 15172 
f 15018 15173 14687 
f 15173 15040 14687 
f 15147 15174 14766 
f 15126 14766 15174 
f 14837 15169 15175 
f 14837 15175 15031 
f 15176 14964 15218 
f 15176 14690 14964 
f 14926 15177 15023 
f 15177 14926 14953 
f 15178 14694 14765 
f 14694 15178 15198 
f 14759 15179 15024 
f 15179 14759 14957 
f 15029 15180 14755 
f 14924 14755 15180 
f 14827 14938 15181 
f 15099 14827 15181 
f 14979 14849 15182 
f 15186 15182 14849 
f 14772 15183 15185 
f 14854 15183 14772 
f 14939 14800 15184 
f 15184 14800 14751 
f 14938 15185 14794 
f 15185 14938 14772 
f 14849 14785 15186 
f 15186 14785 14956 
f 15052 15187 14681 
f 15187 14989 14681 
f 14931 15188 14856 
f 14856 15188 14750 
f 14744 14847 15189 
f 14853 14727 15190 
f 15004 15190 14727 
f 15307 14788 15191 
f 14917 15192 14815 
f 15112 15192 14917 
f 14979 15193 14686 
f 15193 14960 14686 
f 15194 14912 14798 
f 15171 15194 14798 
f 15195 15064 14693 
f 14754 14989 15196 
f 15196 15209 14754 
f 14896 14712 15197 
f 15262 15197 14712 
f 15198 14758 14891 
f 14891 14694 15198 
f 14860 14972 15199 
f 15199 15085 14860 
f 14832 15200 14691 
f 14691 15200 14783 
f 14888 15201 14769 
f 15201 14653 14769 
f 15202 14732 14961 
f 14961 14683 15202 
f 15203 14707 14834 
f 14707 15203 15027 
f 14772 14670 15204 
f 15204 14670 14827 
f 15205 15105 14664 
f 14924 15180 15206 
f 15150 14924 15206 
f 14803 15207 15107 
f 14761 15107 15207 
f 15208 14885 14728 
f 14761 15208 14728 
f 15209 15196 14883 
f 14883 14974 15209 
f 15210 14824 14879 
f 14824 15210 15113 
f 15003 15211 14673 
f 15109 14673 15211 
f 14998 14834 15212 
f 14834 14707 15212 
f 15213 15222 14723 
f 14858 15213 14723 
f 14882 15214 14895 
f 14895 15214 14885 
f 14810 15215 14749 
f 14749 15215 15060 
f 15023 14746 15216 
f 15256 14910 15217 
f 14888 15217 14910 
f 15067 14717 15218 
f 14717 14984 15218 
f 14955 15054 15219 
f 14955 15219 15135 
f 15009 14762 15220 
f 15009 15220 14853 
f 15221 14976 15071 
f 14976 15221 14839 
f 15213 14715 15222 
f 15222 14715 15086 
f 15011 15223 14890 
f 14812 14890 15223 
f 14809 15224 14990 
f 15055 15224 14809 
f 14676 15225 14939 
f 15225 14676 15059 
f 15035 15226 14756 
f 14696 14756 15226 
f 15037 15227 14855 
f 14818 14855 15227 
f 14872 15228 15011 
f 15228 14872 14740 
f 14930 14789 15229 
f 14930 15229 14881 
f 14835 15230 14854 
f 14854 15230 15183 
f 14753 15231 14713 
f 15231 14753 14840 
f 14734 15232 14946 
f 15232 14734 14970 
f 15233 14829 15016 
f 14829 15233 15281 
f 14730 15234 14991 
f 14730 15148 15234 
f 15235 14966 15204 
f 14966 15235 15083 
f 15236 14843 14791 
f 14843 15236 14945 
f 15237 14911 15131 
f 15237 14925 14911 
f 15298 15238 14786 
f 14786 15238 15102 
f 14700 15239 14984 
f 15239 14700 15208 
f 14703 14676 15240 
f 14711 14703 15240 
f 15057 15241 14870 
f 15241 14897 14870 
f 14684 15035 15242 
f 14946 15242 15035 
f 15243 14657 14886 
f 14967 14659 15244 
f 14967 15244 14862 
f 15245 14743 14892 
f 14954 14743 15245 
f 14993 15246 14713 
f 15246 14993 14959 
f 15098 15247 14906 
f 15247 15098 14994 
f 14802 15248 14691 
f 14802 14948 15248 
f 14695 15249 14992 
f 14992 15249 14815 
f 15250 14698 15160 
f 14915 14698 15250 
f 14719 15251 15115 
f 14719 14778 15251 
f 15252 15088 14711 
f 15252 14711 14998 
f 15234 15253 14921 
f 15060 14921 15253 
f 14958 15254 14880 
f 15254 15111 14880 
f 15255 14654 14879 
f 15256 15022 14714 
f 15256 14714 15016 
f 15257 14898 14819 
f 14824 14898 15257 
f 15258 14781 14985 
f 14985 15123 15258 
f 14659 15259 14868 
f 14868 15259 14949 
f 15025 14688 15260 
f 15260 14688 15006 
f 15093 14995 15261 
f 14995 14677 15261 
f 15262 14672 14792 
f 15197 15262 14792 
f 15263 14822 14655 
f 14822 15263 14962 
f 14667 14991 15264 
f 15264 14991 15234 
f 14690 14782 15265 
f 15265 14882 14690 
f 14773 15103 15266 
f 14773 15266 15017 
f 15267 14934 14741 
f 14934 15267 14838 
f 14845 15268 14662 
f 15268 15164 14662 
f 15274 15269 14908 
f 15269 15163 14908 
f 14965 15270 14901 
f 15238 14893 15271 
f 15271 14893 14935 
f 15095 15272 14920 
f 14920 15272 14674 
f 14679 15273 15022 
f 14679 15223 15273 
f 15274 14863 15269 
f 14863 15274 15054 
f 14675 15275 14710 
f 15275 14675 14797 
f 15276 14787 14978 
f 15115 14787 15276 
f 15277 15048 14889 
f 15277 14685 15048 
f 15041 14697 15278 
f 15278 14697 15072 
f 14828 15279 15075 
f 14879 15279 14828 
f 14745 15280 14687 
f 15018 14687 15280 
f 15076 14846 15281 
f 14785 15281 14846 
f 14771 15282 15059 
f 15282 14771 14778 
f 15283 14884 14796 
f 14971 14884 15283 
f 15284 14753 14668 
f 14861 15176 15285 
f 15119 14861 15285 
f 15002 14752 15286 
f 14752 14914 15286 
f 15287 14978 14732 
f 15192 15287 14732 
f 14839 15288 14718 
f 14718 15288 15257 
f 15289 14855 14710 
f 14968 15289 14710 
f 14929 15290 15178 
f 15198 15290 14929 
f 14784 14989 15291 
f 15291 14989 14754 
f 14951 15292 15130 
f 15292 14951 14761 
f 14669 15293 15062 
f 15293 14669 14777 
f 14887 15294 14912 
f 15294 14887 15082 
f 14849 14979 15295 
f 15295 14979 15069 
f 14868 15296 14996 
f 14674 14996 15296 
f 14896 15297 14813 
f 14896 14830 15297 
f 15021 14899 15298 
f 14899 15005 15298 
f 14845 15299 14738 
f 14977 14738 15299 
f 15118 14923 15300 
f 14831 15300 14923 
f 14888 15301 15217 
f 15301 14888 15153 
f 14735 15302 14913 
f 14913 15302 15240 
f 14661 14793 15303 
f 15304 15286 14914 
f 14914 15106 15304 
f 14974 14998 15305 
f 15305 14998 14711 
f 15306 14821 14931 
f 15231 14821 15306 
f 15073 14758 15307 
f 15307 15003 15073 
f 15308 15326 15324 
f 15309 15308 15324 
f 15310 15309 15324 
f 15311 15310 15324 
f 15312 15311 15324 
f 15313 15312 15324 
f 15314 15313 15324 
f 15315 15314 15324 
f 15316 15315 15324 
f 15317 15316 15324 
f 15318 15317 15324 
f 15319 15318 15324 
f 15320 15319 15324 
f 15321 15320 15324 
f 15322 15321 15324 
f 15323 15322 15324 
f 15327 15323 15324 
f 15326 15327 15324 
f 15328 15329 15325 
f 15329 15330 15325 
f 15330 15331 15325 
f 15331 15332 15325 
f 15332 15333 15325 
f 15333 15334 15325 
f 15334 15335 15325 
f 15335 15336 15325 
f 15336 15337 15325 
f 15337 15338 15325 
f 15338 15339 15325 
f 15339 15340 15325 
f 15340 15341 15325 
f 15341 15342 15325 
f 15342 15343 15325 
f 15343 15344 15325 
f 15344 15345 15325 
f 15345 15328 15325 
f 15625 15326 15308 
f 15625 15308 15309 
f 15647 15309 15310 
f 15647 15310 15311 
f 15346 15311 15312 
f 15346 15312 15313 
f 15635 15313 15314 
f 15635 15314 15315 
f 15640 15315 15316 
f 15640 15316 15317 
f 15631 15317 15318 
f 15631 15318 15319 
f 15644 15319 15320 
f 15644 15320 15321 
f 15644 15321 15322 
f 15644 15322 15323 
f 15625 15323 15327 
f 15625 15327 15326 
f 15625 15329 15328 
f 15625 15330 15329 
f 15647 15331 15330 
f 15647 15332 15331 
f 15346 15333 15332 
f 15346 15334 15333 
f 15635 15335 15334 
f 15635 15336 15335 
f 15640 15337 15336 
f 15640 15338 15337 
f 15631 15339 15338 
f 15631 15340 15339 
f 15631 15341 15340 
f 15631 15342 15341 
f 15625 15343 15342 
f 15625 15344 15343 
f 15625 15345 15344 
f 15625 15328 15345 
f 15347 15365 15363 
f 15348 15347 15363 
f 15349 15348 15363 
f 15350 15349 15363 
f 15351 15350 15363 
f 15352 15351 15363 
f 15353 15352 15363 
f 15354 15353 15363 
f 15355 15354 15363 
f 15356 15355 15363 
f 15357 15356 15363 
f 15358 15357 15363 
f 15359 15358 15363 
f 15360 15359 15363 
f 15361 15360 15363 
f 15362 15361 15363 
f 15366 15362 15363 
f 15365 15366 15363 
f 15367 15368 15364 
f 15368 15369 15364 
f 15369 15370 15364 
f 15370 15371 15364 
f 15371 15372 15364 
f 15372 15373 15364 
f 15373 15374 15364 
f 15374 15375 15364 
f 15375 15376 15364 
f 15376 15377 15364 
f 15377 15378 15364 
f 15378 15379 15364 
f 15379 15380 15364 
f 15380 15381 15364 
f 15381 15382 15364 
f 15382 15383 15364 
f 15383 15384 15364 
f 15384 15367 15364 
f 15385 15365 15347 
f 15385 15347 15348 
f 15385 15348 15349 
f 15385 15349 15350 
f 15385 15350 15351 
f 15385 15351 15352 
f 15385 15352 15353 
f 15385 15353 15354 
f 15621 15354 15355 
f 15621 15355 15356 
f 15633 15356 15357 
f 15633 15357 15358 
f 15633 15358 15359 
f 15633 15359 15360 
f 15628 15360 15361 
f 15628 15361 15362 
f 15628 15362 15366 
f 15628 15366 15365 
f 15628 15368 15367 
f 15385 15369 15368 
f 15385 15370 15369 
f 15385 15371 15370 
f 15385 15372 15371 
f 15385 15373 15372 
f 15385 15374 15373 
f 15621 15375 15374 
f 15621 15376 15375 
f 15633 15377 15376 
f 15633 15378 15377 
f 15633 15379 15378 
f 15633 15380 15379 
f 15639 15381 15380 
f 15639 15382 15381 
f 15628 15383 15382 
f 15628 15384 15383 
f 15628 15367 15384 
f 15386 15404 15402 
f 15387 15386 15402 
f 15388 15387 15402 
f 15389 15388 15402 
f 15390 15389 15402 
f 15391 15390 15402 
f 15392 15391 15402 
f 15393 15392 15402 
f 15394 15393 15402 
f 15395 15394 15402 
f 15396 15395 15402 
f 15397 15396 15402 
f 15398 15397 15402 
f 15399 15398 15402 
f 15400 15399 15402 
f 15401 15400 15402 
f 15405 15401 15402 
f 15404 15405 15402 
f 15406 15407 15403 
f 15407 15408 15403 
f 15408 15409 15403 
f 15409 15410 15403 
f 15410 15411 15403 
f 15411 15412 15403 
f 15412 15413 15403 
f 15413 15414 15403 
f 15414 15415 15403 
f 15415 15416 15403 
f 15416 15417 15403 
f 15417 15418 15403 
f 15418 15419 15403 
f 15419 15420 15403 
f 15420 15421 15403 
f 15421 15422 15403 
f 15422 15423 15403 
f 15423 15406 15403 
f 15618 15404 15386 
f 15618 15386 15387 
f 15618 15387 15388 
f 15618 15388 15389 
f 15618 15389 15390 
f 15618 15390 15391 
f 15627 15391 15392 
f 15627 15392 15393 
f 15618 15393 15394 
f 15618 15394 15395 
f 15618 15395 15396 
f 15618 15396 15397 
f 15618 15397 15398 
f 15618 15398 15399 
f 15618 15399 15400 
f 15618 15400 15401 
f 15618 15401 15405 
f 15618 15405 15404 
f 15618 15407 15406 
f 15618 15408 15407 
f 15618 15409 15408 
f 15618 15410 15409 
f 15618 15411 15410 
f 15618 15412 15411 
f 15627 15413 15412 
f 15627 15414 15413 
f 15618 15415 15414 
f 15618 15416 15415 
f 15618 15417 15416 
f 15618 15418 15417 
f 15618 15419 15418 
f 15618 15420 15419 
f 15618 15421 15420 
f 15618 15422 15421 
f 15618 15423 15422 
f 15618 15406 15423 
f 15424 15441 15440 
f 15425 15424 15440 
f 15426 15425 15440 
f 15427 15426 15440 
f 15428 15427 15440 
f 15429 15428 15440 
f 15430 15429 15440 
f 15431 15430 15440 
f 15432 15431 15440 
f 15433 15432 15440 
f 15434 15433 15440 
f 15435 15434 15440 
f 15436 15435 15440 
f 15437 15436 15440 
f 15438 15437 15440 
f 15439 15438 15440 
f 15442 15439 15440 
f 15441 15442 15440 
f 15443 15444 15619 
f 15444 15445 15619 
f 15445 15446 15619 
f 15446 15447 15619 
f 15447 15448 15619 
f 15448 15449 15619 
f 15449 15450 15619 
f 15450 15451 15619 
f 15451 15452 15619 
f 15452 15453 15619 
f 15453 15454 15619 
f 15454 15455 15619 
f 15455 15456 15619 
f 15456 15457 15619 
f 15457 15458 15619 
f 15458 15459 15619 
f 15459 15460 15619 
f 15460 15443 15619 
f 15642 15441 15424 
f 15642 15424 15425 
f 15642 15425 15426 
f 15642 15426 15427 
f 15642 15427 15428 
f 15642 15428 15429 
f 15616 15429 15430 
f 15616 15430 15431 
f 15620 15431 15432 
f 15620 15432 15433 
f 15620 15433 15434 
f 15642 15434 15435 
f 15642 15435 15436 
f 15642 15436 15437 
f 15642 15437 15438 
f 15642 15438 15439 
f 15642 15439 15442 
f 15642 15442 15441 
f 15642 15444 15443 
f 15642 15445 15444 
f 15642 15446 15445 
f 15642 15447 15446 
f 15642 15448 15447 
f 15616 15449 15448 
f 15616 15450 15449 
f 15620 15451 15450 
f 15620 15452 15451 
f 15620 15453 15452 
f 15620 15454 15453 
f 15643 15455 15454 
f 15643 15456 15455 
f 15642 15457 15456 
f 15642 15458 15457 
f 15642 15459 15458 
f 15642 15460 15459 
f 15642 15443 15460 
f 15461 15479 15477 
f 15462 15461 15477 
f 15463 15462 15477 
f 15464 15463 15477 
f 15465 15464 15477 
f 15466 15465 15477 
f 15467 15466 15477 
f 15468 15467 15477 
f 15469 15468 15477 
f 15470 15469 15477 
f 15471 15470 15477 
f 15472 15471 15477 
f 15473 15472 15477 
f 15474 15473 15477 
f 15475 15474 15477 
f 15476 15475 15477 
f 15480 15476 15477 
f 15479 15480 15477 
f 15481 15482 15478 
f 15482 15483 15478 
f 15483 15484 15478 
f 15484 15485 15478 
f 15485 15486 15478 
f 15486 15487 15478 
f 15487 15488 15478 
f 15488 15489 15478 
f 15489 15490 15478 
f 15490 15491 15478 
f 15491 15492 15478 
f 15492 15493 15478 
f 15493 15494 15478 
f 15494 15495 15478 
f 15495 15496 15478 
f 15496 15497 15478 
f 15497 15498 15478 
f 15498 15481 15478 
f 15626 15479 15461 
f 15626 15461 15462 
f 15648 15462 15463 
f 15648 15463 15464 
f 15499 15464 15465 
f 15499 15465 15466 
f 15636 15466 15467 
f 15636 15467 15468 
f 15641 15468 15469 
f 15641 15469 15470 
f 15632 15470 15471 
f 15632 15471 15472 
f 15632 15472 15473 
f 15632 15473 15474 
f 15645 15474 15475 
f 15645 15475 15476 
f 15626 15476 15480 
f 15626 15480 15479 
f 15626 15482 15481 
f 15626 15483 15482 
f 15648 15484 15483 
f 15648 15485 15484 
f 15499 15486 15485 
f 15499 15487 15486 
f 15636 15488 15487 
f 15636 15489 15488 
f 15641 15490 15489 
f 15641 15491 15490 
f 15632 15492 15491 
f 15632 15493 15492 
f 15632 15494 15493 
f 15632 15495 15494 
f 15626 15496 15495 
f 15626 15497 15496 
f 15626 15498 15497 
f 15626 15481 15498 
f 15500 15518 15516 
f 15501 15500 15516 
f 15502 15501 15516 
f 15503 15502 15516 
f 15504 15503 15516 
f 15505 15504 15516 
f 15506 15505 15516 
f 15507 15506 15516 
f 15508 15507 15516 
f 15509 15508 15516 
f 15510 15509 15516 
f 15511 15510 15516 
f 15512 15511 15516 
f 15513 15512 15516 
f 15514 15513 15516 
f 15515 15514 15516 
f 15519 15515 15516 
f 15518 15519 15516 
f 15520 15521 15517 
f 15521 15522 15517 
f 15522 15523 15517 
f 15523 15524 15517 
f 15524 15525 15517 
f 15525 15526 15517 
f 15526 15527 15517 
f 15527 15528 15517 
f 15528 15529 15517 
f 15529 15530 15517 
f 15530 15531 15517 
f 15531 15532 15517 
f 15532 15533 15517 
f 15533 15534 15517 
f 15534 15535 15517 
f 15535 15536 15517 
f 15536 15537 15517 
f 15537 15520 15517 
f 15637 15518 15500 
f 15637 15500 15501 
f 15629 15501 15502 
f 15629 15502 15503 
f 15538 15503 15504 
f 15538 15504 15505 
f 15538 15505 15506 
f 15538 15506 15507 
f 15538 15507 15508 
f 15538 15508 15509 
f 15538 15509 15510 
f 15538 15510 15511 
f 15634 15511 15512 
f 15622 15512 15513 
f 15622 15513 15514 
f 15622 15514 15515 
f 15637 15515 15519 
f 15637 15519 15518 
f 15637 15521 15520 
f 15637 15522 15521 
f 15629 15523 15522 
f 15629 15524 15523 
f 15538 15525 15524 
f 15538 15526 15525 
f 15538 15527 15526 
f 15538 15528 15527 
f 15538 15529 15528 
f 15538 15530 15529 
f 15634 15531 15530 
f 15634 15532 15531 
f 15634 15533 15532 
f 15622 15534 15533 
f 15622 15535 15534 
f 15622 15536 15535 
f 15637 15537 15536 
f 15637 15520 15537 
f 15539 15557 15555 
f 15540 15539 15555 
f 15541 15540 15555 
f 15542 15541 15555 
f 15543 15542 15555 
f 15544 15543 15555 
f 15545 15544 15555 
f 15546 15545 15555 
f 15547 15546 15555 
f 15548 15547 15555 
f 15549 15548 15555 
f 15550 15549 15555 
f 15551 15550 15555 
f 15552 15551 15555 
f 15553 15552 15555 
f 15554 15553 15555 
f 15558 15554 15555 
f 15557 15558 15555 
f 15559 15560 15556 
f 15560 15561 15556 
f 15561 15562 15556 
f 15562 15563 15556 
f 15563 15564 15556 
f 15564 15565 15556 
f 15565 15566 15556 
f 15566 15567 15556 
f 15567 15568 15556 
f 15568 15569 15556 
f 15569 15570 15556 
f 15570 15571 15556 
f 15571 15572 15556 
f 15572 15573 15556 
f 15573 15574 15556 
f 15574 15575 15556 
f 15575 15576 15556 
f 15576 15559 15556 
f 15630 15557 15539 
f 15630 15539 15540 
f 15617 15540 15541 
f 15617 15541 15542 
f 15646 15542 15543 
f 15646 15543 15544 
f 15623 15544 15545 
f 15623 15545 15546 
f 15623 15546 15547 
f 15623 15547 15548 
f 15623 15548 15549 
f 15623 15549 15550 
f 15630 15550 15551 
f 15630 15551 15552 
f 15630 15552 15553 
f 15630 15553 15554 
f 15630 15554 15558 
f 15630 15558 15557 
f 15617 15560 15559 
f 15617 15561 15560 
f 15617 15562 15561 
f 15617 15563 15562 
f 15646 15564 15563 
f 15646 15565 15564 
f 15623 15566 15565 
f 15623 15567 15566 
f 15623 15568 15567 
f 15623 15569 15568 
f 15623 15570 15569 
f 15623 15571 15570 
f 15630 15572 15571 
f 15630 15573 15572 
f 15630 15574 15573 
f 15630 15575 15574 
f 15630 15576 15575 
f 15630 15559 15576 
f 15577 15595 15593 
f 15578 15577 15593 
f 15579 15578 15593 
f 15580 15579 15593 
f 15581 15580 15593 
f 15582 15581 15593 
f 15583 15582 15593 
f 15584 15583 15593 
f 15585 15584 15593 
f 15586 15585 15593 
f 15587 15586 15593 
f 15588 15587 15593 
f 15589 15588 15593 
f 15590 15589 15593 
f 15591 15590 15593 
f 15592 15591 15593 
f 15596 15592 15593 
f 15595 15596 15593 
f 15597 15598 15594 
f 15598 15599 15594 
f 15599 15600 15594 
f 15600 15601 15594 
f 15601 15602 15594 
f 15602 15603 15594 
f 15603 15604 15594 
f 15604 15605 15594 
f 15605 15606 15594 
f 15606 15607 15594 
f 15607 15608 15594 
f 15608 15609 15594 
f 15609 15610 15594 
f 15610 15611 15594 
f 15611 15612 15594 
f 15612 15613 15594 
f 15613 15614 15594 
f 15614 15597 15594 
f 15624 15595 15577 
f 15624 15577 15578 
f 15624 15578 15579 
f 15624 15579 15580 
f 15615 15580 15581 
f 15615 15581 15582 
f 15615 15582 15583 
f 15615 15583 15584 
f 15638 15584 15585 
f 15638 15585 15586 
f 15638 15586 15587 
f 15624 15587 15588 
f 15624 15588 15589 
f 15624 15589 15590 
f 15624 15590 15591 
f 15624 15591 15592 
f 15624 15592 15596 
f 15624 15596 15595 
f 15624 15598 15597 
f 15624 15599 15598 
f 15624 15600 15599 
f 15624 15601 15600 
f 15615 15602 15601 
f 15615 15603 15602 
f 15615 15604 15603 
f 15615 15605 15604 
f 15638 15606 15605 
f 15638 15607 15606 
f 15624 15608 15607 
f 15624 15609 15608 
f 15624 15610 15609 
f 15624 15611 15610 
f 15624 15612 15611 
f 15624 15613 15612 
f 15624 15614 15613 
f 15624 15597 15614 
f 15429 15616 15642 
f 15431 15620 15616 
f 15450 15616 15620 
f 15448 15642 15616 
f 15374 15385 15621 
f 15380 15633 15639 
f 15354 15621 15385 
f 15360 15628 15633 
f 15530 15538 15634 
f 15515 15637 15622 
f 15511 15634 15538 
f 15536 15622 15637 
f 15571 15623 15630 
f 15544 15623 15646 
f 15550 15630 15623 
f 15565 15646 15623 
f 15587 15624 15638 
f 15607 15638 15624 
f 15580 15615 15624 
f 15601 15624 15615 
f 15309 15647 15625 
f 15330 15625 15647 
f 15317 15631 15640 
f 15338 15640 15631 
f 15462 15648 15626 
f 15483 15626 15648 
f 15470 15632 15641 
f 15491 15641 15632 
f 15412 15618 15627 
f 15391 15627 15618 
f 15653 15649 15650 
f 15654 15651 15652 
f 15368 15628 15385 
f 15365 15385 15628 
f 15503 15538 15629 
f 15524 15629 15538 
f 15540 15617 15630 
f 15559 15630 15617 
f 15342 15631 15625 
f 15323 15625 15644 
f 15495 15632 15626 
f 15476 15626 15645 
f 15376 15621 15633 
f 15356 15633 15621 
f 15512 15622 15634 
f 15533 15634 15622 
f 15313 15635 15346 
f 15334 15346 15635 
f 15466 15636 15499 
f 15487 15499 15636 
f 15501 15629 15637 
f 15522 15637 15629 
f 15584 15638 15615 
f 15605 15615 15638 
f 15382 15639 15628 
f 15633 15628 15639 
f 15315 15640 15635 
f 15336 15635 15640 
f 15468 15641 15636 
f 15489 15636 15641 
f 15456 15643 15642 
f 15434 15642 15620 
f 15643 15620 15642 
f 15454 15620 15643 
f 15625 15631 15644 
f 15319 15644 15631 
f 15474 15645 15632 
f 15626 15632 15645 
f 15542 15646 15617 
f 15563 15617 15646 
f 15332 15647 15346 
f 15311 15346 15647 
f 15464 15499 15648 
f 15485 15648 15499 
f 15742 16500 15909 
f 16064 15821 16237 
f 16446 16149 15893 
f 15876 16333 16034 
f 16524 15920 15843 
f 15765 16004 16510 
f 15722 16225 16178 
f 16304 15914 15837 
f 16465 15802 16377 
f 16040 15848 16312 
f 16228 15907 16550 
f 16021 15867 16647 
f 16098 15887 16244 
f 16336 15973 16507 
f 16406 15822 16181 
f 16033 16498 16148 
f 16109 15834 15884 
f 16179 15987 16526 
f 16581 15960 16010 
f 16655 15946 16180 
f 16571 15950 16206 
f 15936 16040 16312 
f 16430 15805 16226 
f 16155 16211 15833 
f 15772 16213 16485 
f 16123 15875 16197 
f 16214 16521 15980 
f 15764 16444 15943 
f 16036 15958 16149 
f 16289 16026 16199 
f 16559 15841 16065 
f 16626 16105 16351 
f 16474 15739 16041 
f 15775 16609 15906 
f 16495 16259 16222 
f 16458 16156 16670 
f 16417 16106 15911 
f 15900 16275 16358 
f 16172 16194 15866 
f 16220 16675 16166 
f 16146 16065 15841 
f 16573 15996 16462 
f 16491 16104 15953 
f 16340 15860 15983 
f 16027 16277 16341 
f 16311 16515 15814 
f 16008 16641 16307 
f 15884 16015 15904 
f 16427 16436 15971 
f 16154 16385 15731 
f 15796 15820 16320 
f 16121 15795 16616 
f 16600 15927 16135 
f 16671 16185 15956 
f 16276 15750 16660 
f 16051 16393 15921 
f 15730 16217 16410 
f 15663 16207 16338 
f 16032 16078 16336 
f 16110 15721 16484 
f 15769 16317 15943 
f 16500 16318 15692 
f 15949 16200 15786 
f 15685 16534 16668 
f 15868 16292 16466 
f 16350 16595 16037 
f 16014 16375 15936 
f 16654 16169 16240 
f 16022 16498 15740 
f 16220 16613 16675 
f 16326 16662 16342 
f 16071 16441 16013 
f 16642 16221 15788 
f 16597 16623 15862 
f 16455 16545 16057 
f 15796 16310 16143 
f 16521 15856 15870 
f 16225 16422 16629 
f 15994 16012 16080 
f 15942 16026 16289 
f 16666 15942 15974 
f 15897 16297 16175 
f 15944 16358 16658 
f 16133 16351 16137 
f 16564 15868 16466 
f 16343 16134 16478 
f 16389 16103 15885 
f 16277 16224 15770 
f 16519 16102 16608 
f 16081 16537 16664 
f 15916 16095 16050 
f 16370 15846 15938 
f 16259 16495 16547 
f 15957 16377 15802 
f 16591 16119 15995 
f 15751 16287 16360 
f 16602 15896 16497 
f 16333 16029 16034 
f 16002 16560 15773 
f 15905 15894 16452 
f 16398 16463 15905 
f 16482 15976 15856 
f 16028 16261 15953 
f 15999 16290 16164 
f 15667 16171 16238 
f 15859 15791 16215 
f 16204 16376 16307 
f 16120 16449 15828 
f 16429 16192 16170 
f 16462 16266 16267 
f 15801 16615 16066 
f 16624 16286 15725 
f 16181 16041 16247 
f 16621 16018 16620 
f 15701 16604 16428 
f 16253 15989 16499 
f 16333 15992 16029 
f 15941 15895 15970 
f 15815 16354 16596 
f 16194 16219 15866 
f 16582 15764 16574 
f 16132 16364 15819 
f 16365 16610 16457 
f 16296 16145 16628 
f 15804 16372 16419 
f 16274 15842 16649 
f 16566 16344 16293 
f 15956 16429 16170 
f 16661 16609 16403 
f 16013 16544 16454 
f 16205 15777 16003 
f 16363 15808 16447 
f 16490 16284 16551 
f 15909 16500 16168 
f 16590 16005 15677 
f 15825 15968 16160 
f 16098 16252 15887 
f 16379 16347 16090 
f 15927 16584 16113 
f 16561 16309 16430 
f 16493 16067 15794 
f 16348 16562 16096 
f 15966 16522 16433 
f 15685 16668 15967 
f 15662 16088 16579 
f 15762 16105 16208 
f 16608 15850 15901 
f 15849 16041 15739 
f 16053 15917 15940 
f 15969 16279 16056 
f 16229 16511 16396 
f 16262 16318 16020 
f 16414 16103 16442 
f 16423 15743 16601 
f 16082 16298 16548 
f 15948 16129 16539 
f 16030 15889 16418 
f 16421 16565 15774 
f 15945 16159 16528 
f 16054 16297 15897 
f 16408 16054 16673 
f 16578 16099 16399 
f 16230 16568 16099 
f 16233 15961 15799 
f 16161 16300 16153 
f 15984 16437 15898 
f 16543 16478 15817 
f 16306 16240 16169 
f 16394 16079 16001 
f 15843 16210 15818 
f 16002 16144 16560 
f 15663 15752 16048 
f 16076 15752 16424 
f 15741 16045 16059 
f 16533 16179 16526 
f 15835 16541 15754 
f 15673 16039 15890 
f 16201 16301 15892 
f 16567 16395 16126 
f 15756 16338 16207 
f 16647 15903 16021 
f 16460 16610 16365 
f 16362 16012 16209 
f 16235 16060 16320 
f 16346 15980 15901 
f 16043 15868 16250 
f 16377 16501 16465 
f 16149 15935 15893 
f 15760 16522 16443 
f 16490 15886 15951 
f 16589 16196 15973 
f 16351 16105 16656 
f 16018 16118 15797 
f 16575 15723 16176 
f 16219 15723 16575 
f 16177 15998 16073 
f 16009 16286 16624 
f 15857 15976 16000 
f 15681 16295 16124 
f 15874 15662 15888 
f 15662 15874 16088 
f 16323 16381 16480 
f 16432 15669 16055 
f 15908 16303 15768 
f 16052 16512 15769 
f 15947 15988 15657 
f 15998 16177 16216 
f 16491 15953 16261 
f 15932 16038 15682 
f 15924 16095 16203 
f 16277 16514 16224 
f 16195 16638 15945 
f 16120 15990 16449 
f 16528 16159 16559 
f 16090 15773 16159 
f 16421 15774 16140 
f 16387 16532 15906 
f 15877 15775 16254 
f 15909 16128 16483 
f 15982 16380 15939 
f 16612 15950 16285 
f 16013 16217 16612 
f 16187 15874 16242 
f 16669 16494 15824 
f 16011 16452 15836 
f 15982 16667 16008 
f 15868 16043 16292 
f 15780 16284 16490 
f 15781 15697 15847 
f 16077 16326 15781 
f 15999 15782 15716 
f 16064 16455 16057 
f 16464 16435 16098 
f 15783 15695 15968 
f 15784 15957 15962 
f 16395 16426 15784 
f 15864 15937 16386 
f 16234 16439 16034 
f 15692 16316 16500 
f 16383 16402 16541 
f 15991 16231 16317 
f 16044 15979 16434 
f 16648 15979 16033 
f 15911 16106 16227 
f 16401 16233 16580 
f 16616 15795 16299 
f 16544 16136 15790 
f 16305 16084 16389 
f 16123 16554 16553 
f 15976 16378 16345 
f 16462 15715 16573 
f 16442 16635 16414 
f 16037 16141 15793 
f 16004 16334 15735 
f 16561 16024 16309 
f 15923 15795 15686 
f 16165 16614 16518 
f 16348 16470 15700 
f 15796 16320 16060 
f 16502 15797 15690 
f 16502 16116 15797 
f 16355 15798 16125 
f 16621 16620 16321 
f 15961 16607 15799 
f 16359 15800 16322 
f 16310 16178 15985 
f 16091 16615 15801 
f 16547 15784 15962 
f 16419 16372 16101 
f 16329 16625 16256 
f 16023 15658 16329 
f 16107 16465 16501 
f 16092 16649 16248 
f 16249 16658 16067 
f 16509 15910 16226 
f 16648 16258 15788 
f 16220 16166 16270 
f 16195 15807 15680 
f 15846 16370 16373 
f 16551 16218 16122 
f 16260 16633 16529 
f 16438 15721 16188 
f 16641 15779 15894 
f 16236 16409 16650 
f 16241 16456 16272 
f 16627 16272 16273 
f 15812 15722 15801 
f 16428 16604 16331 
f 16529 15978 16260 
f 16239 16330 16664 
f 16201 16581 16010 
f 15965 16545 15882 
f 16117 16040 15936 
f 16243 16514 16277 
f 15928 16234 15712 
f 15816 16150 15878 
f 16420 16391 15889 
f 16245 15817 15948 
f 16540 16002 16416 
f 16392 16353 16604 
f 16404 16604 16353 
f 16552 16310 15985 
f 16326 15985 15781 
f 16602 15964 16282 
f 16281 15711 15821 
f 15851 16007 16047 
f 16121 16616 16047 
f 16607 15961 15823 
f 16493 16249 16067 
f 16669 15824 15696 
f 16230 16099 15696 
f 16314 16068 15825 
f 15826 16613 16220 
f 15732 15677 15827 
f 16182 15995 16119 
f 16135 15927 16213 
f 16184 15839 15913 
f 16196 15911 16227 
f 16157 15761 16651 
f 16415 15830 15998 
f 15769 16489 16246 
f 16209 15831 15726 
f 16538 16066 15989 
f 16030 16418 16343 
f 16549 16157 16651 
f 16113 15670 16508 
f 16265 15896 15670 
f 16366 16464 16098 
f 16015 16592 16162 
f 15725 16286 16582 
f 16393 16051 16132 
f 16072 16229 15710 
f 15939 16667 15982 
f 16235 16263 16060 
f 15837 16471 16519 
f 15838 16369 15750 
f 16306 16640 16240 
f 16668 16184 16111 
f 16668 16111 15967 
f 16097 16520 16198 
f 15840 16186 16520 
f 16049 16144 16371 
f 15899 16313 15713 
f 15727 16092 16248 
f 16571 16206 16003 
f 16138 16416 15751 
f 15751 16016 16138 
f 15783 16435 16619 
f 16103 16389 16084 
f 16261 16576 16491 
f 16055 16139 16432 
f 15846 16373 16139 
f 15752 16017 16422 
f 16441 16217 16013 
f 15848 16663 15747 
f 15907 16381 15708 
f 16406 16181 16247 
f 16614 15686 16191 
f 16214 15980 16346 
f 15738 16105 16626 
f 16308 15851 16479 
f 15851 16478 16543 
f 16112 15946 16074 
f 15689 15756 16374 
f 15910 16341 16525 
f 15932 15770 16038 
f 16025 16661 15709 
f 16025 15709 16450 
f 15831 15855 15726 
f 15855 16236 16174 
f 16348 15700 16085 
f 16278 15719 16093 
f 16295 15681 15857 
f 16028 16058 16261 
f 15759 16185 16671 
f 16250 16107 16459 
f 16215 15717 15859 
f 15859 16513 16380 
f 16189 16324 15694 
f 16219 16575 16189 
f 16291 16382 16280 
f 16597 15862 16337 
f 16147 16201 16069 
f 15863 16243 16527 
f 15815 16596 16324 
f 16503 15728 16280 
f 16344 16566 15864 
f 15823 15961 15704 
f 16546 15704 15865 
f 16044 16033 15979 
f 15724 15866 16367 
f 16495 16222 16567 
f 15867 16232 16301 
f 16564 16572 15868 
f 15693 15804 16107 
f 16119 16591 15753 
f 15869 15753 16533 
f 16263 16339 15700 
f 16339 16085 15700 
f 15871 16359 16322 
f 16577 16150 16294 
f 16064 16057 15716 
f 16405 16451 15872 
f 15873 16659 16429 
f 16155 15929 16211 
f 16166 16675 16388 
f 15874 15888 15766 
f 15684 16583 16650 
f 15875 16123 16204 
f 16536 16340 16333 
f 16130 16635 16020 
f 16448 15877 16254 
f 16114 16152 16283 
f 15712 15816 15878 
f 16153 16472 16357 
f 16050 16095 15879 
f 15741 15880 16045 
f 16408 16311 16054 
f 16469 15702 16630 
f 15882 16601 15743 
f 15743 16337 15882 
f 16329 16256 16618 
f 16156 16168 16670 
f 15904 15710 15884 
f 16128 16215 16483 
f 16103 15981 16665 
f 16390 16431 16131 
f 15655 15886 16384 
f 16628 15955 16640 
f 16398 16127 16463 
f 15888 15828 15766 
f 15828 15888 16632 
f 16078 16321 16620 
f 16129 16420 16327 
f 15890 15754 16541 
f 16353 16364 16404 
f 16108 15859 16376 
f 15891 16553 16505 
f 15892 16581 16201 
f 16021 15903 16445 
f 16554 16123 16197 
f 16093 15719 16443 
f 15684 15894 16583 
f 15810 16463 16127 
f 16434 16172 16044 
f 15895 16142 15970 
f 15896 16602 16282 
f 15920 16524 15711 
f 15897 15785 16467 
f 16405 15667 16238 
f 16308 16479 16115 
f 15842 16274 16183 
f 16146 15774 16565 
f 15924 15771 16095 
f 15900 15771 16027 
f 15980 16487 15901 
f 15758 15837 16519 
f 15845 15671 16104 
f 15996 16573 16019 
f 15903 16374 15756 
f 16315 16251 16492 
f 15836 16162 16011 
f 15904 16072 15710 
f 16366 16098 16244 
f 15905 16452 16302 
f 16356 15977 15929 
f 16548 16298 15906 
f 16399 15747 16663 
f 15737 15967 16111 
f 15930 15908 16634 
f 15947 16453 16246 
f 16156 16397 15776 
f 15776 15717 15909 
f 16509 15678 16275 
f 16275 15678 16358 
f 15911 16385 16417 
f 15829 15986 16335 
f 16137 15794 15912 
f 15735 15912 16024 
f 15873 16135 16659 
f 15718 16652 15913 
f 16330 15978 15813 
f 16471 15707 16519 
f 15807 16140 15915 
f 16270 15807 15915 
f 15767 16203 16323 
f 16203 16555 15924 
f 16552 15985 16605 
f 16053 15820 15917 
f 16144 16631 16371 
f 15919 16316 15803 
f 15919 15803 16168 
f 15711 16281 15920 
f 15782 15999 16164 
f 16145 16296 16276 
f 16362 16460 16328 
f 16595 16350 15922 
f 15922 16020 15706 
f 15661 15790 16299 
f 16662 15923 15686 
f 16117 16527 15659 
f 15924 15659 16425 
f 16336 16078 15925 
f 15832 16549 15829 
f 16537 16239 16664 
f 15850 15926 16208 
f 15734 16570 16485 
f 16113 15734 16485 
f 16439 16556 15692 
f 15786 15928 15949 
f 15687 16532 16588 
f 15873 15687 16588 
f 16503 16303 15728 
f 15931 16357 16472 
f 15931 16546 15865 
f 16525 16341 15932 
f 16563 16058 15853 
f 15663 16338 16076 
f 16074 16410 16112 
f 15934 16308 15898 
f 16100 15963 16075 
f 16499 16163 16253 
f 15760 16443 15935 
f 15815 15936 16557 
f 15936 16527 16117 
f 15937 16382 15703 
f 15937 15703 16386 
f 16154 16370 15938 
f 16272 16627 16241 
f 15666 15836 16533 
f 15666 16533 16059 
f 15686 15940 16662 
f 16165 16053 15940 
f 15895 15941 15723 
f 15723 16359 16176 
f 15974 16512 16293 
f 16052 16293 16512 
f 16231 16251 16402 
f 15943 15787 15764 
f 16300 16161 16063 
f 16249 15944 16658 
f 15772 16485 16570 
f 15772 15945 16638 
f 15946 16112 15852 
f 16586 16158 16222 
f 16489 15947 16246 
f 16288 15988 15947 
f 15948 16539 15744 
f 16645 15948 15744 
f 15949 15928 15712 
f 16419 16101 15668 
f 16655 15668 16264 
f 16466 16292 16390 
f 15951 15780 16490 
f 15952 16574 16617 
f 16461 15952 16617 
f 16028 16646 16345 
f 16646 15902 15715 
f 15954 15716 15872 
f 15683 15716 15954 
f 15955 15688 15887 
f 16252 15955 15887 
f 16448 15858 15729 
f 15877 16448 15729 
f 16152 15784 16426 
f 15709 16283 16152 
f 15958 16587 16149 
f 16650 16409 15958 
f 15913 15839 16170 
f 16042 15959 16668 
f 15960 15881 16010 
f 15746 15881 15960 
f 15865 15704 15961 
f 16401 16269 16233 
f 15962 16419 15668 
f 15957 15802 15962 
f 16343 16319 16030 
f 16431 16531 16131 
f 15821 16282 16325 
f 16325 16237 15821 
f 15676 15965 15862 
f 16523 15965 15676 
f 16096 16562 15736 
f 15736 15966 16433 
f 16363 15737 15907 
f 16363 15907 16228 
f 15783 15968 15733 
f 16558 16169 16654 
f 16094 16494 16669 
f 15970 15698 16061 
f 15970 15800 15941 
f 15790 15971 16299 
f 16205 16003 15971 
f 16624 15725 15972 
f 16276 15972 16051 
f 16233 16507 15973 
f 15748 16227 16580 
f 15974 16293 16344 
f 15746 16469 16630 
f 15678 16509 16271 
f 16024 15975 16309 
f 16267 15976 15792 
f 16345 15792 15976 
f 15699 15977 16356 
f 16602 16497 15977 
f 16304 15978 15914 
f 15914 15978 15707 
f 16648 15788 15979 
f 16434 15979 16257 
f 15870 15758 15980 
f 15980 16521 15870 
f 16318 16500 15742 
f 16665 15981 16483 
f 16380 15982 15859 
f 15859 15982 16376 
f 15983 15763 16340 
f 16575 16176 15763 
f 15693 16349 15804 
f 16349 15693 16437 
f 15697 15781 15985 
f 15985 16178 16225 
f 15761 16218 15986 
f 16651 15761 15986 
f 16156 16458 16397 
f 15987 16182 16119 
f 15691 15988 16288 
f 16193 15831 16149 
f 15989 16212 16538 
f 16270 15680 15807 
f 16638 16657 15772 
f 16251 16231 15991 
f 16251 16289 16199 
f 16294 15992 16577 
f 16176 16535 15763 
f 15752 16076 16400 
f 15993 16017 15752 
f 15701 16212 15994 
f 16604 15701 16392 
f 16182 15677 15995 
f 16443 15719 16167 
f 16266 16462 15996 
f 15727 15950 15997 
f 15727 15997 16092 
f 16444 16481 15943 
f 16444 15830 16481 
f 16210 16475 16603 
f 15683 15999 15716 
f 16214 15765 16089 
f 15857 16000 16295 
f 15672 16001 16506 
f 16002 15751 16416 
f 16287 15751 16379 
f 16206 16248 16003 
f 15971 16003 16427 
f 16214 16004 15765 
f 16004 15738 16334 
f 16005 16256 15658 
f 16006 15773 16560 
f 15773 16006 16159 
f 16543 16598 15851 
f 15822 15795 16121 
f 16667 15779 16008 
f 15779 16641 16008 
f 16643 16009 15720 
f 16394 16001 16352 
f 16223 15814 15965 
f 15814 16223 16311 
f 15905 16302 15714 
f 15714 16592 16015 
f 16328 15757 15921 
f 16012 15921 16393 
f 16544 16013 16136 
f 15777 16013 16612 
f 16568 16498 16099 
f 15936 16312 16014 
f 15834 16015 15884 
f 15714 16015 15834 
f 16360 15896 15711 
f 15896 16360 16287 
f 16422 16017 16629 
f 16071 16629 16441 
f 16438 16530 15721 
f 16484 15721 16530 
f 16553 15671 16505 
f 16019 16104 15671 
f 16340 16536 16361 
f 16262 16020 15876 
f 15892 16301 16232 
f 16486 16021 16445 
f 16148 16498 16022 
f 16022 16473 15806 
f 15803 16316 16255 
f 15786 16200 16255 
f 16024 15912 15794 
f 15794 15975 16024 
f 16450 15854 16025 
f 16548 15906 16609 
f 16026 15702 16199 
f 16630 15702 16666 
f 16275 15900 16027 
f 16341 15910 16027 
f 16058 16028 16378 
f 16345 16378 16028 
f 16029 15992 15816 
f 16234 15816 15712 
f 16549 16332 16157 
f 16078 16622 15925 
f 16467 16405 15897 
f 16673 16054 15897 
f 16621 16321 16198 
f 16233 15799 16507 
f 16557 15936 16033 
f 15724 16033 16044 
f 16439 15692 16034 
f 16318 16262 15692 
f 15663 16604 16404 
f 16035 15663 16048 
f 16446 16036 16149 
f 16036 15875 16204 
f 15863 16350 16037 
f 16514 16243 15863 
f 15793 16141 16442 
f 15770 15793 16038 
f 16039 15756 16207 
f 16039 15673 16315 
f 16480 15767 16323 
f 16040 16117 16555 
f 16260 16188 15721 
f 16539 16041 16181 
f 15759 15959 16042 
f 16542 16250 16042 
f 16534 15685 16202 
f 16202 15685 16476 
f 16044 15866 15724 
f 15866 16044 16172 
f 15666 16059 16045 
f 16072 16674 16593 
f 16046 16056 16279 
f 16568 15740 16498 
f 15749 16047 16616 
f 16115 16047 15749 
f 16035 16048 16331 
f 15722 16422 16225 
f 16371 16313 16049 
f 16049 15841 16006 
f 16273 15669 16357 
f 15669 16432 16050 
f 15725 16051 15972 
f 16132 16051 16461 
f 15908 15768 16052 
f 16453 15908 16246 
f 15739 16653 16320 
f 16053 16165 16518 
f 16297 16407 16344 
f 15881 15746 16407 
f 15846 16456 15938 
f 16139 16055 15846 
f 16056 16046 16230 
f 16056 15824 15969 
f 15716 16057 15872 
f 16408 16515 16311 
f 15681 16058 16378 
f 16058 16430 16226 
f 16059 16355 16125 
f 16533 15753 16059 
f 16060 16496 15796 
f 16060 16263 15700 
f 15800 15970 16061 
f 16277 15770 16062 
f 15932 16341 15770 
f 16063 15704 16546 
f 15944 15704 16063 
f 15716 15782 16064 
f 16064 15782 15821 
f 16195 15945 16528 
f 16146 16565 16065 
f 16066 16538 16428 
f 16066 15812 15801 
f 16271 16067 16658 
f 15975 15794 16067 
f 16068 15838 15750 
f 15838 16068 16314 
f 16069 15676 15862 
f 15709 16395 15862 
f 15732 16670 15883 
f 15827 16070 15732 
f 16077 15781 16454 
f 16071 15781 15847 
f 16072 16162 15836 
f 16674 16072 15836 
f 15998 15764 16073 
f 15764 16582 16286 
f 16074 15730 16410 
f 16612 16655 16264 
f 15705 16075 16368 
f 16075 15705 16100 
f 15933 16400 16076 
f 15933 16076 15689 
f 16299 16077 15661 
f 15661 16077 16454 
f 15889 16030 16078 
f 16078 15664 16636 
f 16079 15750 16369 
f 16624 16660 15750 
f 16132 15819 16080 
f 16080 15819 15994 
f 15797 16116 16484 
f 16484 16530 15797 
f 16082 15743 16423 
f 16623 16597 16450 
f 16210 16603 15818 
f 16491 16576 16084 
f 16084 16305 15845 
f 15719 16278 16267 
f 16085 16339 15856 
f 15788 16258 16642 
f 16087 16153 16300 
f 16546 15931 16087 
f 15696 16099 16578 
f 16187 16088 15874 
f 15856 16089 16482 
f 15765 16000 16089 
f 16090 16287 16379 
f 16508 16287 16090 
f 16433 16585 15736 
f 16585 16178 16310 
f 15997 15804 16092 
f 15804 15984 16092 
f 15966 16348 16522 
f 16093 15660 16278 
f 15826 15656 16094 
f 16494 16094 15656 
f 15879 16095 15900 
f 15771 15900 16095 
f 16411 16096 15736 
f 16496 16470 16411 
f 16097 15840 16520 
f 16097 16440 15840 
f 16098 16435 15783 
f 16268 16252 16098 
f 16099 15747 16399 
f 16014 15747 16099 
f 15705 15934 16564 
f 16564 15934 16572 
f 15950 15668 16101 
f 15950 16101 15997 
f 16330 16239 16102 
f 16519 15707 16102 
f 15682 16103 16084 
f 16103 15682 16442 
f 16104 15902 16646 
f 15902 16104 16019 
f 16105 16611 16208 
f 16611 16105 15738 
f 16580 16517 16401 
f 16672 16106 16417 
f 16516 16250 15868 
f 16572 16516 15868 
f 15791 15859 16108 
f 16305 15791 16505 
f 16464 16109 15844 
f 16529 15813 15978 
f 16081 16664 16110 
f 15888 15662 16111 
f 15737 16111 15662 
f 15689 15852 16112 
f 15933 15689 16112 
f 15833 16113 16584 
f 16265 15670 16113 
f 16377 15729 16501 
f 15877 15729 16114 
f 16637 16115 16183 
f 16308 16115 15898 
f 16116 16537 16081 
f 16116 15762 16208 
f 15924 16117 15659 
f 15924 16555 16117 
f 16118 16198 15690 
f 15690 15797 16118 
f 16119 15753 15869 
f 16526 15987 16119 
f 16120 16213 15772 
f 15772 16657 16120 
f 15822 16121 16598 
f 16047 16007 16121 
f 16122 16384 15886 
f 16122 16218 15761 
f 16123 16553 15891 
f 16376 16204 16123 
f 16004 15735 16510 
f 16561 16430 16124 
f 16125 15741 16059 
f 16395 15784 16126 
f 16547 16126 15784 
f 16127 16398 15688 
f 16145 16365 15688 
f 16128 15909 15717 
f 16215 16128 15717 
f 16636 15664 16129 
f 16188 16129 16438 
f 15981 16130 15742 
f 16130 16414 16635 
f 16466 15963 16564 
f 15963 16131 16531 
f 15835 16132 16461 
f 16132 15835 15754 
f 15912 16133 16137 
f 15738 16626 16334 
f 16391 16418 15889 
f 15817 16478 16134 
f 16135 15718 15913 
f 16632 16135 16213 
f 15777 16136 16013 
f 16205 16136 15777 
f 16493 15794 16137 
f 16440 16607 15823 
f 16524 15843 16138 
f 16138 15843 16416 
f 16550 15907 15708 
f 16139 16323 16432 
f 15826 15915 16140 
f 16442 16141 16635 
f 16595 15922 15706 
f 16413 16142 16257 
f 15698 15970 16142 
f 15820 16552 16605 
f 16143 15820 15796 
f 16144 16002 15674 
f 16144 15674 15918 
f 15921 15757 16145 
f 16145 15757 16365 
f 15899 15774 16146 
f 16313 15899 16644 
f 16567 16222 15755 
f 16147 16301 16201 
f 16022 15806 16148 
f 16148 16594 16258 
f 16149 15831 15989 
f 15989 15935 16149 
f 16322 16468 16577 
f 16403 15877 16151 
f 16283 15709 16661 
f 16152 16114 15957 
f 16152 15957 15784 
f 16358 15944 16477 
f 15879 16477 16357 
f 15811 16154 15938 
f 16154 15811 16385 
f 15873 16588 16155 
f 16584 15927 16600 
f 16156 15909 16168 
f 15909 16156 15776 
f 16157 16319 16384 
f 16157 16332 16319 
f 15903 16647 16374 
f 16586 15852 16158 
f 16090 16159 16508 
f 16159 15945 15734 
f 15968 15695 16160 
f 16063 16161 15944 
f 16477 15944 16161 
f 16162 15904 16015 
f 15904 16162 16072 
f 16163 15760 16253 
f 16091 16433 16615 
f 15920 16569 16290 
f 15920 16281 16569 
f 15686 16614 16165 
f 16165 15940 15686 
f 16166 16449 15990 
f 16166 16388 15766 
f 15996 16167 15719 
f 16554 15996 16019 
f 16329 15883 16168 
f 16670 16168 15883 
f 16306 16169 15825 
f 16169 16558 15825 
f 16671 15956 16170 
f 15959 16170 15839 
f 15683 15954 16171 
f 16194 16172 15895 
f 15895 16172 16142 
f 16469 16173 15702 
f 16486 15702 16173 
f 16174 15726 15855 
f 16362 16610 16460 
f 16386 16175 15864 
f 15897 16175 15785 
f 16535 16176 15871 
f 16359 15871 16176 
f 16177 16073 16606 
f 15801 15722 16178 
f 16091 15801 16178 
f 15776 16397 16179 
f 16179 15939 16380 
f 16655 16180 15668 
f 15668 16547 15962 
f 15744 16539 16181 
f 16181 16645 15744 
f 16458 16182 15987 
f 15677 16182 15827 
f 16427 16183 16436 
f 16115 15749 16183 
f 15888 16652 15718 
f 15888 16111 16652 
f 15858 16448 16185 
f 15858 16459 16107 
f 15762 16656 16105 
f 15690 16186 16502 
f 15778 15696 16187 
f 15696 16578 16187 
f 16188 15809 16129 
f 15809 16188 16260 
f 16324 16189 15860 
f 15983 15860 16189 
f 15907 16190 16381 
f 16190 15907 15737 
f 15795 15822 16406 
f 16191 15686 15795 
f 16135 15913 16659 
f 16170 16192 15913 
f 15958 15855 16587 
f 15855 15831 16193 
f 15723 16194 15895 
f 15723 16219 16194 
f 15807 16195 15745 
f 16528 15745 16195 
f 16589 15829 16488 
f 16335 16488 15829 
f 15893 16197 16446 
f 15893 16167 16197 
f 15799 16607 16198 
f 15799 16198 16321 
f 16199 16445 15903 
f 15903 15756 16199 
f 16023 16200 15658 
f 16069 16201 15676 
f 16201 16523 15676 
f 16534 16202 16542 
f 16043 16202 16292 
f 16095 15916 16203 
f 16203 16432 16323 
f 16204 16307 16641 
f 16641 16036 16204 
f 16136 16205 15790 
f 15790 16205 15971 
f 16206 15950 15727 
f 16206 15727 16248 
f 16207 15663 16404 
f 15890 16039 16207 
f 16116 16208 15675 
f 15675 16208 15926 
f 16212 16209 15994 
f 16209 16012 15994 
f 16475 16210 15999 
f 15999 16210 16290 
f 15977 16497 16211 
f 15929 15977 16211 
f 15989 15831 16212 
f 16212 15831 16209 
f 16120 15828 16213 
f 16213 15828 16632 
f 16004 16214 15738 
f 16214 16611 15738 
f 15885 16215 16389 
f 15885 16483 16215 
f 16216 16415 15998 
f 16017 15993 16217 
f 16400 16410 16217 
f 16551 16370 16218 
f 15731 15986 16218 
f 15694 16219 16189 
f 16219 16367 15866 
f 16270 15915 16220 
f 16220 15915 15826 
f 16413 16257 16221 
f 15867 15755 16222 
f 15867 16222 16158 
f 15965 16523 16223 
f 16223 16523 15881 
f 15793 16224 16037 
f 16224 15793 15770 
f 15697 16225 15847 
f 16225 15697 15985 
f 16226 15853 16058 
f 16525 16226 15910 
f 15748 15973 16227 
f 15973 16196 16227 
f 16228 15808 16363 
f 16550 16373 16228 
f 16593 16045 16511 
f 16593 15666 16045 
f 16230 15696 15824 
f 15824 16056 16230 
f 16231 15943 16317 
f 15943 16231 15787 
f 16232 16021 15892 
f 15867 16021 16232 
f 16233 15748 16580 
f 15973 15748 16233 
f 16029 15816 16234 
f 16034 16029 16234 
f 15837 15758 16235 
f 16304 15837 16653 
f 16610 16236 16457 
f 16583 15894 15810 
f 16237 16601 15882 
f 16064 16237 16455 
f 16238 16451 16405 
f 15954 16238 16171 
f 16239 16537 15926 
f 16239 16608 16102 
f 16240 16640 15955 
f 15955 16654 16240 
f 15811 15938 16241 
f 16241 15938 16456 
f 16613 15778 16675 
f 16242 15778 16187 
f 16527 16243 15659 
f 15771 15659 16243 
f 15905 16244 16398 
f 16366 16244 15905 
f 16598 16543 15822 
f 16245 15948 16645 
f 16052 15769 16246 
f 15908 16052 16246 
f 16247 16614 16191 
f 16247 16041 15849 
f 16183 16427 15842 
f 16248 16649 15842 
f 15944 16249 15704 
f 15823 15704 16249 
f 15759 16250 16459 
f 16042 16250 15759 
f 16251 16315 15673 
f 16402 16251 16541 
f 15733 16252 16268 
f 15955 16252 16654 
f 15989 16253 15935 
f 15760 15935 16253 
f 16448 16254 15687 
f 15775 15687 16254 
f 16255 16023 15803 
f 16023 16255 16200 
f 16618 16256 15732 
f 15677 15732 16590 
f 15979 15788 16257 
f 15788 16221 16257 
f 16594 15679 16258 
f 16258 15679 16086 
f 16259 16547 15946 
f 16586 15946 15852 
f 16041 16260 16474 
f 16041 15809 16260 
f 16261 16563 16576 
f 16563 16261 16058 
f 16034 16262 15876 
f 16262 16034 15692 
f 15758 16263 16235 
f 16339 16263 15758 
f 15950 16612 16264 
f 15668 15950 16264 
f 16497 15833 16211 
f 16113 15833 16265 
f 15996 15719 16266 
f 16266 15719 16267 
f 16267 15856 15976 
f 16267 16085 15856 
f 15783 15733 16268 
f 15783 16268 16098 
f 15961 16233 16269 
f 16269 15865 15961 
f 16412 16270 16166 
f 16412 15680 16270 
f 16067 16271 15975 
f 16271 15805 15975 
f 16456 16504 16272 
f 16273 16272 15669 
f 16627 16273 16672 
f 15931 16273 16357 
f 16637 16183 16274 
f 16274 16649 15898 
f 16275 15910 16509 
f 15910 16275 16027 
f 16145 16276 15921 
f 16051 15921 16276 
f 15771 16243 16277 
f 16277 16027 15771 
f 16085 16267 16278 
f 15660 16085 16278 
f 16046 16279 15740 
f 16280 16382 15937 
f 15864 16280 15937 
f 16281 15821 15782 
f 16569 16281 15782 
f 15896 16282 15711 
f 16282 15821 15711 
f 16114 16283 15877 
f 16283 16151 15877 
f 16447 15808 16284 
f 16284 15780 15685 
f 16003 15777 16571 
f 16285 15777 16612 
f 16073 16643 15720 
f 15764 16643 16073 
f 15670 15896 16287 
f 16508 15670 16287 
f 15691 16288 15830 
f 15830 16489 15769 
f 15991 16317 16289 
f 16251 15991 16289 
f 16290 15843 15920 
f 16290 16210 15843 
f 16280 15728 16291 
f 16202 16476 16292 
f 16476 16639 16292 
f 16566 16293 15768 
f 15768 16293 16052 
f 16150 15816 16294 
f 15816 15992 16294 
f 15765 16510 16295 
f 15765 16295 16000 
f 16640 16306 15750 
f 16296 15750 16276 
f 15864 16297 16344 
f 15864 16175 16297 
f 16298 16082 16423 
f 15699 16356 16298 
f 15923 16299 15795 
f 16299 15923 16077 
f 16300 15789 16087 
f 15789 16300 16063 
f 15755 16301 16147 
f 15867 16301 15755 
f 16592 15714 16302 
f 16011 16302 16452 
f 15728 16303 15930 
f 15908 15930 16303 
f 16304 16653 15739 
f 16304 15739 15978 
f 15671 16305 16505 
f 16305 15671 15845 
f 15750 16306 16068 
f 16068 16306 15825 
f 15982 16307 16376 
f 15982 16008 16307 
f 16308 15705 16368 
f 15705 16308 15934 
f 15975 15805 16309 
f 16430 16309 15805 
f 15736 16585 16310 
f 16411 15736 16310 
f 15881 16311 16223 
f 16311 15881 16407 
f 15747 16312 15848 
f 16014 16312 15747 
f 16049 16313 15841 
f 16644 15841 16313 
f 16506 16314 15672 
f 15756 16315 16492 
f 16039 16315 15756 
f 15786 16255 16316 
f 15692 16556 16316 
f 15942 16289 16317 
f 16317 15769 16512 
f 16318 15742 16130 
f 16318 16130 16020 
f 16431 15655 16531 
f 16319 15655 16384 
f 15820 16053 16320 
f 15739 16320 16053 
f 16321 16078 16032 
f 15799 16321 16032 
f 16322 15665 16468 
f 15665 16322 15800 
f 16139 15708 16323 
f 16381 16323 15708 
f 16324 15860 15863 
f 15863 15815 16324 
f 16325 16601 16237 
f 15964 16325 16282 
f 15917 16326 16342 
f 16605 15917 15820 
f 16636 15889 16078 
f 16420 15889 16327 
f 16328 15921 16012 
f 16328 16012 16362 
f 15803 16329 16168 
f 15803 16023 16329 
f 16330 15707 15978 
f 16102 15707 16330 
f 15722 16331 16422 
f 15722 15812 16331 
f 16030 16319 16332 
f 16622 16332 16549 
f 15992 16333 15763 
f 16340 15763 16333 
f 16334 15912 15735 
f 16334 16133 15912 
f 15911 16196 16335 
f 15986 15911 16335 
f 15832 16336 15925 
f 15973 16336 15832 
f 15965 16337 15862 
f 15882 16337 15965 
f 15689 16338 15756 
f 15689 16076 16338 
f 15870 16339 15758 
f 15856 16339 15870 
f 16340 16361 15922 
f 15922 15860 16340 
f 15770 16341 16062 
f 16062 16341 16277 
f 15917 16342 15940 
f 15940 16342 16662 
f 16478 15963 16343 
f 16343 15963 16531 
f 16344 15746 16630 
f 16407 15746 16344 
f 15715 16345 16646 
f 16345 15715 16462 
f 15850 16208 16611 
f 15850 16346 15901 
f 16347 16002 15773 
f 15773 16090 16347 
f 16348 16085 15660 
f 16348 15660 16522 
f 15804 16349 15984 
f 16437 15984 16349 
f 15922 16350 15860 
f 16350 15863 15860 
f 16440 16351 15840 
f 16656 15840 16351 
f 16009 16352 15720 
f 16392 15819 16353 
f 16353 15819 16364 
f 15724 16367 16354 
f 16354 15815 16557 
f 16355 16059 15753 
f 16591 16355 15753 
f 16387 15906 16356 
f 15906 16298 16356 
f 16357 16050 15879 
f 16357 15669 16050 
f 16477 15879 16358 
f 15900 16358 15879 
f 15800 16359 15941 
f 15723 15941 16359 
f 16016 15751 16360 
f 15711 16524 16360 
f 15876 16020 16361 
f 16361 16020 15922 
f 15726 16362 16209 
f 16610 16362 15726 
f 15967 16363 16447 
f 15737 16363 15967 
f 16364 16132 15754 
f 16364 15754 16404 
f 16365 16457 16127 
f 16365 16127 15688 
f 15834 16366 15714 
f 16366 15905 15714 
f 15694 16367 16219 
f 16367 16596 16354 
f 16368 15851 16308 
f 16478 15851 16368 
f 16001 16369 16506 
f 16001 16079 16369 
f 15731 16218 16370 
f 15731 16370 16154 
f 15713 16313 16371 
f 16371 16631 15713 
f 16372 15997 16101 
f 15997 16372 15804 
f 16373 15808 16228 
f 16551 16373 16370 
f 15852 15689 16374 
f 15852 16374 16158 
f 16033 16375 16498 
f 16033 15936 16375 
f 16108 16376 15891 
f 16376 16123 15891 
f 16377 15957 16114 
f 15729 16377 16114 
f 16378 15976 15857 
f 16378 15857 15681 
f 15751 16002 16379 
f 16347 16379 16002 
f 15776 16179 16380 
f 16380 16513 15776 
f 16381 15848 16480 
f 16381 16190 15848 
f 16382 15861 15703 
f 16382 16291 15861 
f 15835 16461 16617 
f 16541 15835 16383 
f 16384 15761 16157 
f 16384 16122 15761 
f 15986 15731 16385 
f 15911 15986 16385 
f 16386 16599 16175 
f 15929 16532 16387 
f 15929 16387 16356 
f 15874 16388 16242 
f 15874 15766 16388 
f 16215 15791 16389 
f 16389 15791 16305 
f 16639 16390 16292 
f 16431 16390 16639 
f 15817 16418 16391 
f 15948 15817 16391 
f 15994 15819 16392 
f 15701 15994 16392 
f 16132 16080 16393 
f 16080 16012 16393 
f 16079 16394 16009 
f 16009 16394 16352 
f 16069 15862 16395 
f 16069 16395 16567 
f 16396 15710 16229 
f 15987 16397 16458 
f 16397 15987 16179 
f 16244 15887 16398 
f 15688 16398 15887 
f 16399 16663 16579 
f 16399 16579 16088 
f 15752 16400 15993 
f 15993 16400 16217 
f 15865 16269 16401 
f 15865 16401 16517 
f 16402 16617 16574 
f 16231 16402 15787 
f 15775 15877 16403 
f 16609 15775 16403 
f 16404 15754 15890 
f 15890 16207 16404 
f 16031 16405 15872 
f 15897 16405 16031 
f 16191 16406 16247 
f 16406 16191 15795 
f 16407 16054 16311 
f 16054 16407 16297 
f 15872 16408 16031 
f 15872 16057 16408 
f 15855 15958 16409 
f 16236 15855 16409 
f 16400 15933 16410 
f 16410 15933 16112 
f 15796 16496 16411 
f 16411 16310 15796 
f 15680 16412 15990 
f 16412 16166 15990 
f 16413 16221 15698 
f 15698 16142 16413 
f 15981 16414 16130 
f 16103 16414 15981 
f 15830 16415 15691 
f 15691 16415 16216 
f 16540 16416 15818 
f 15843 15818 16416 
f 16417 15811 16672 
f 16385 15811 16417 
f 16134 16343 16418 
f 16134 16418 15817 
f 16419 15962 15802 
f 16419 15802 15804 
f 16391 16420 15948 
f 16129 15948 16420 
f 16140 15807 16421 
f 15807 15745 16421 
f 16422 16331 16048 
f 16048 15752 16422 
f 15699 16423 16601 
f 15699 16298 16423 
f 16076 16424 15663 
f 16424 15752 15663 
f 16425 15771 15924 
f 15659 15771 16425 
f 16426 15709 16152 
f 16426 16395 15709 
f 16248 16427 16003 
f 16427 16248 15842 
f 15812 16428 16331 
f 16066 16428 15812 
f 15956 15687 16429 
f 15687 15873 16429 
f 16430 16058 15681 
f 16124 16430 15681 
f 15886 16431 15951 
f 16431 15886 15655 
f 15916 16432 16203 
f 15916 16050 16432 
f 15760 16163 16433 
f 16522 15760 16433 
f 16434 16257 16142 
f 16142 16172 16434 
f 16435 15844 16619 
f 16464 15844 16435 
f 16436 15749 15971 
f 15749 16436 16183 
f 15693 15934 16437 
f 15898 16437 15934 
f 16129 15664 16438 
f 16438 15664 16530 
f 16556 16439 15928 
f 15928 16439 16234 
f 16137 16351 16440 
f 16137 16440 15823 
f 16017 16441 16629 
f 16217 16441 16017 
f 15793 16442 16038 
f 15682 16038 16442 
f 16443 16167 15893 
f 15935 16443 15893 
f 16444 15764 15998 
f 16444 15998 15830 
f 16199 15702 16445 
f 16486 16445 15702 
f 16446 15875 16036 
f 15875 16446 16197 
f 16447 15685 15967 
f 16447 16284 15685 
f 16448 15956 16185 
f 15687 15956 16448 
f 16166 15766 16449 
f 15766 15828 16449 
f 16450 16597 16082 
f 16450 16082 15854 
f 16451 16238 15954 
f 15872 16451 15954 
f 15836 16452 15779 
f 16452 15894 15779 
f 16453 15947 15657 
f 16634 15908 16453 
f 16071 16454 15781 
f 16013 16454 16071 
f 16455 15882 16545 
f 16455 16237 15882 
f 16456 15846 16055 
f 16456 16055 16504 
f 16457 16583 15810 
f 15810 16127 16457 
f 15827 16182 16458 
f 16458 16070 15827 
f 16185 15759 16459 
f 16185 16459 15858 
f 16460 15757 16328 
f 16460 16365 15757 
f 15952 16461 15725 
f 15725 16461 16051 
f 16462 15792 16345 
f 16462 16267 15792 
f 16463 15810 15894 
f 15894 15905 16463 
f 16464 16366 15834 
f 16464 15834 16109 
f 15804 15802 16465 
f 16107 15804 16465 
f 16390 16131 16466 
f 16131 15963 16466 
f 16467 15667 16405 
f 16468 15665 16150 
f 16468 16150 16577 
f 16469 15746 15960 
f 16173 16469 15960 
f 16470 16096 16411 
f 16096 16470 16348 
f 15914 15707 16471 
f 15837 15914 16471 
f 16087 16472 16153 
f 15931 16472 16087 
f 16473 16022 15740 
f 15978 16474 16260 
f 15978 15739 16474 
f 15999 15683 16475 
f 16476 15780 15951 
f 15780 16476 15685 
f 16161 16153 16477 
f 16477 16153 16357 
f 16478 16075 15963 
f 16478 16368 16075 
f 16047 16115 16479 
f 16047 16479 15851 
f 16480 16040 15767 
f 15848 16040 16480 
f 15943 16481 15769 
f 15769 16481 15830 
f 15976 16482 16000 
f 16000 16482 16089 
f 16483 15981 15742 
f 16483 15742 15909 
f 16081 16484 16116 
f 16081 16110 16484 
f 15927 16485 16213 
f 16485 15927 16113 
f 15892 16021 16486 
f 15892 16486 16173 
f 15980 15758 16487 
f 15758 15901 16487 
f 16335 16196 16488 
f 16589 16488 16196 
f 16288 15947 16489 
f 15830 16288 16489 
f 16490 16551 16122 
f 16490 16122 15886 
f 16084 15845 16491 
f 15845 16104 16491 
f 16199 15756 16492 
f 16251 16199 16492 
f 16249 16493 15823 
f 16493 16137 15823 
f 15969 15824 16494 
f 15969 16494 15656 
f 16495 16567 16126 
f 16495 16126 16547 
f 15700 16470 16496 
f 16060 15700 16496 
f 16497 16265 15833 
f 16265 16497 15896 
f 16498 16014 16099 
f 16498 16375 16014 
f 16066 16163 16499 
f 16499 15989 16066 
f 16500 15919 16168 
f 16500 16316 15919 
f 16501 15858 16107 
f 15858 16501 15729 
f 16502 15762 16116 
f 16186 15762 16502 
f 16503 15864 16303 
f 15864 16503 16280 
f 16504 15669 16272 
f 15669 16504 16055 
f 16108 16505 15791 
f 15891 16505 16108 
f 16369 15838 16506 
f 16314 16506 15838 
f 16507 15799 16032 
f 16507 16032 16336 
f 15734 16508 16159 
f 16508 15734 16113 
f 16226 15805 16509 
f 15805 16271 16509 
f 16124 16510 15735 
f 16510 16124 16295 
f 15880 16511 16045 
f 16511 15880 16396 
f 15974 15942 16512 
f 16512 15942 16317 
f 15859 15717 16513 
f 15776 16513 15717 
f 16514 15863 16037 
f 16224 16514 16037 
f 16408 16057 16515 
f 15814 16515 16057 
f 16107 16250 16516 
f 16516 15693 16107 
f 15865 16517 15931 
f 16580 16672 16517 
f 15739 16053 16518 
f 16518 15849 15739 
f 16519 15901 15758 
f 16608 15901 16519 
f 15690 16520 16186 
f 15690 16198 16520 
f 15856 16521 16089 
f 16089 16521 16214 
f 16093 16522 15660 
f 16443 16522 16093 
f 16201 16010 16523 
f 15881 16523 16010 
f 16360 16524 16016 
f 16524 16138 16016 
f 16525 15932 15853 
f 16226 16525 15853 
f 16526 16119 15869 
f 16526 15869 16533 
f 16527 15936 15815 
f 16527 15815 15863 
f 16559 16065 16528 
f 15745 16528 16065 
f 16633 16110 16529 
f 16110 15813 16529 
f 16018 15797 16530 
f 16530 15664 16018 
f 16319 16531 15655 
f 16531 16319 16343 
f 15687 15775 16532 
f 16532 15775 15906 
f 15939 16533 15836 
f 16179 16533 15939 
f 16534 16042 16668 
f 16542 16042 16534 
f 15992 15763 16535 
f 15992 16535 15871 
f 16361 16536 15876 
f 16333 15876 16536 
f 16537 15675 15926 
f 16116 15675 16537 
f 16538 15701 16428 
f 15701 16538 16212 
f 16041 16539 15809 
f 16129 15809 16539 
f 16002 16540 15674 
f 15890 16541 15673 
f 16541 16251 15673 
f 16250 16542 16043 
f 16202 16043 16542 
f 15822 16543 16245 
f 16543 15817 16245 
f 16544 15790 15661 
f 16544 15661 16454 
f 16545 15965 15814 
f 16545 15814 16057 
f 16546 16087 15789 
f 15789 16063 16546 
f 16180 16547 15668 
f 16547 16180 15946 
f 16548 15854 16082 
f 16609 15854 16548 
f 16549 15832 15925 
f 16622 16549 15925 
f 15708 16139 16550 
f 16550 16139 16373 
f 16551 15808 16373 
f 15808 16551 16284 
f 16310 16552 16143 
f 15820 16143 16552 
f 16019 16553 16554 
f 16019 15671 16553 
f 16197 16167 16554 
f 15996 16554 16167 
f 16555 16203 15767 
f 16040 16555 15767 
f 16316 16556 15786 
f 15928 15786 16556 
f 15724 16557 16033 
f 16557 15724 16354 
f 15968 15825 16558 
f 16558 15733 15968 
f 16006 15841 16559 
f 16006 16559 16159 
f 16144 16049 16560 
f 16006 16560 16049 
f 16024 16561 15735 
f 16124 15735 16561 
f 15736 16562 15966 
f 15966 16562 16348 
f 16563 15853 15932 
f 16563 15932 16576 
f 15963 16100 16564 
f 16564 16100 15705 
f 16565 16421 15745 
f 16565 15745 16065 
f 16566 15768 16303 
f 16566 16303 15864 
f 16147 16567 15755 
f 16567 16147 16069 
f 16046 15740 16568 
f 16568 16230 16046 
f 16290 16569 16164 
f 16164 16569 15782 
f 15945 15772 16570 
f 15945 16570 15734 
f 15777 16285 16571 
f 16571 16285 15950 
f 15934 15693 16572 
f 16572 15693 16516 
f 15715 15902 16573 
f 16019 16573 15902 
f 16574 15787 16402 
f 15764 15787 16574 
f 16189 16575 15983 
f 15763 15983 16575 
f 16576 15682 16084 
f 16576 15932 15682 
f 15871 16322 16577 
f 16577 15992 15871 
f 16088 16578 16399 
f 16088 16187 16578 
f 15662 16579 15737 
f 16579 16663 15737 
f 16106 16672 16580 
f 16106 16580 16227 
f 16173 16581 15892 
f 16173 15960 16581 
f 16582 16574 15952 
f 16582 15952 15725 
f 16583 16236 16650 
f 16236 16583 16457 
f 15833 16584 16155 
f 15873 16155 16584 
f 16585 16091 16178 
f 16585 16433 16091 
f 16586 16259 15946 
f 16259 16586 16222 
f 16149 16587 16193 
f 16587 15855 16193 
f 15929 16155 16588 
f 16588 16532 15929 
f 16589 15832 15829 
f 15973 15832 16589 
f 16256 16005 16590 
f 16256 16590 15732 
f 16355 16591 15798 
f 16591 15995 15798 
f 16592 16011 16162 
f 16302 16011 16592 
f 16511 16229 16593 
f 16593 16229 16072 
f 15806 16594 16148 
f 16141 16595 15706 
f 16037 16595 16141 
f 15694 16596 16367 
f 16324 16596 15694 
f 16082 16597 15743 
f 16597 16337 15743 
f 15851 16598 16007 
f 16598 16121 16007 
f 16599 16386 15785 
f 16175 16599 15785 
f 16584 16600 15873 
f 16135 15873 16600 
f 15699 16601 15964 
f 15964 16601 16325 
f 15699 15964 16602 
f 15977 15699 16602 
f 16603 16475 16083 
f 16083 15818 16603 
f 15663 16035 16604 
f 16035 16331 16604 
f 15985 16326 16605 
f 15917 16605 16326 
f 16073 15720 16606 
f 16606 15720 16177 
f 16097 16198 16607 
f 16440 16097 16607 
f 16608 16239 15926 
f 15850 16608 15926 
f 16025 16609 16661 
f 16025 15854 16609 
f 16236 16610 16174 
f 16174 16610 15726 
f 16214 16346 16611 
f 15850 16611 16346 
f 16655 16612 15730 
f 15730 16612 16217 
f 16094 16613 15826 
f 16669 16613 16094 
f 15849 16518 16614 
f 15849 16614 16247 
f 16066 16615 16163 
f 16433 16163 16615 
f 16616 15971 15749 
f 16299 15971 16616 
f 15835 16617 16383 
f 16383 16617 16402 
f 16618 15732 15883 
f 16618 15883 16329 
f 15695 15783 16619 
f 16620 15664 16078 
f 15664 16620 16018 
f 16118 16018 16621 
f 16621 16198 16118 
f 16622 16078 16030 
f 16622 16030 16332 
f 15709 16623 16450 
f 16623 15709 15862 
f 16009 16624 16079 
f 16624 15750 16079 
f 16625 15658 16256 
f 15658 16625 16329 
f 16626 16351 16133 
f 16626 16133 16334 
f 15811 16241 16627 
f 15811 16627 16672 
f 16145 15688 16628 
f 16628 15688 15955 
f 15847 16629 16071 
f 16225 16629 15847 
f 16666 15974 16630 
f 16630 15974 16344 
f 16631 15918 15713 
f 16631 16144 15918 
f 15888 15718 16632 
f 16632 15718 16135 
f 16110 16633 15721 
f 16260 15721 16633 
f 16453 15657 16634 
f 16141 15706 16635 
f 16635 15706 16020 
f 16327 16636 16129 
f 16327 15889 16636 
f 16637 15898 16115 
f 16637 16274 15898 
f 16638 15680 16657 
f 16195 15680 16638 
f 16639 16476 15951 
f 16639 15951 16431 
f 16628 16640 16296 
f 15750 16296 16640 
f 16641 15894 15684 
f 16641 15684 16650 
f 16258 16086 16642 
f 16221 16642 16086 
f 16643 16286 16009 
f 15764 16286 16643 
f 15899 16146 16644 
f 15841 16644 16146 
f 16645 16181 15822 
f 16645 15822 16245 
f 15953 16646 16028 
f 15953 16104 16646 
f 15867 16158 16647 
f 16158 16374 16647 
f 16148 16648 16033 
f 16148 16258 16648 
f 15984 16649 16092 
f 16649 15984 15898 
f 16650 16036 16641 
f 16036 16650 15958 
f 15829 16549 16651 
f 16651 15986 15829 
f 16111 16184 16652 
f 16652 16184 15913 
f 16235 16653 15837 
f 16653 16235 16320 
f 16252 15733 16654 
f 15733 16558 16654 
f 15946 16655 16074 
f 16655 15730 16074 
f 16186 16656 15762 
f 16186 15840 16656 
f 16657 15990 16120 
f 15680 15990 16657 
f 15678 16658 16358 
f 16271 16658 15678 
f 16659 15913 16192 
f 16429 16659 16192 
f 15972 16276 16660 
f 16660 16624 15972 
f 16661 16151 16283 
f 16151 16661 16403 
f 16077 15923 16662 
f 16077 16662 16326 
f 15848 16190 16663 
f 16663 16190 15737 
f 16330 15813 16664 
f 15813 16110 16664 
f 16665 15885 16103 
f 15885 16665 16483 
f 16666 16026 15942 
f 16026 16666 15702 
f 15836 16667 15939 
f 16667 15836 15779 
f 15959 15839 16668 
f 15839 16184 16668 
f 15696 15778 16669 
f 15778 16613 16669 
f 16070 16670 15732 
f 16458 16670 16070 
f 15959 16671 16170 
f 16671 15959 15759 
f 15931 16672 16273 
f 15931 16517 16672 
f 16031 16408 16673 
f 15897 16031 16673 
f 16593 16674 15666 
f 16674 15836 15666 
f 16242 16675 15778 
f 16388 16675 16242 
f 16821 16751 16753 
f 16822 16820 16768 
f 16729 16872 16713 
f 16824 16752 16749 
f 16730 16874 16680 
f 16823 16750 16769 
f 16809 16897 16731 
f 16891 16725 16698 
f 16926 16738 16836 
f 16702 16744 16875 
f 16886 16769 16750 
f 16842 16732 16828 
f 16707 16858 16767 
f 16799 16889 16872 
f 16912 16741 16709 
f 16812 16900 16719 
f 16908 16707 16767 
f 16923 16927 16859 
f 16728 16875 16744 
f 16731 16873 16809 
f 16868 16859 16906 
f 16682 16808 16754 
f 16706 16857 16776 
f 16743 16865 16786 
f 16907 16736 16708 
f 16708 16860 16929 
f 16840 16734 16825 
s 2
f 16804 16938 16716 
f 16804 16844 16735 
s 1
f 16753 16735 16821 
f 16843 16733 16827 
s 2
f 16734 16840 16774 
s 1
f 16739 16707 16915 
f 16909 16775 16711 
f 16852 16747 16882 
f 16835 16696 16928 
f 16849 16745 16884 
f 16834 16697 16930 
f 16851 16746 16881 
f 16833 16698 16725 
f 16850 16748 16883 
f 16836 16699 16926 
f 16737 16904 16873 
f 16706 16776 16910 
f 16930 16701 16834 
f 16797 16802 16867 
f 16788 16798 16712 
f 16936 16937 16868 
f 16795 16924 16696 
f 16871 16686 16806 
f 16811 16815 16730 
f 16901 16922 16860 
f 16803 16765 16829 
f 16754 16808 16689 
f 16690 16865 16743 
f 16779 16764 16832 
f 16778 16766 16831 
f 16796 16686 16866 
f 16780 16763 16830 
f 16691 16867 16802 
f 16721 16771 16853 
f 16688 16942 16790 
f 16678 16940 16791 
f 16722 16773 16854 
f 16687 16941 16793 
f 16723 16770 16856 
f 16677 16939 16792 
f 16724 16772 16855 
f 16684 16767 16864 
f 16810 16814 16729 
f 16888 16749 16752 
s 2
f 16774 16785 16734 
s 1
f 16887 16768 16820 
s 2
f 16768 16887 16774 
f 16733 16843 16880 
f 16769 16886 16844 
s 1
f 16828 16759 16842 
f 16864 16767 16717 
f 16827 16760 16843 
f 16808 16718 16863 
f 16719 16862 16812 
f 16826 16761 16841 
f 16825 16762 16840 
f 16720 16861 16703 
f 16790 16819 16688 
f 16791 16816 16678 
f 16793 16818 16687 
f 16792 16817 16677 
f 16869 16706 16911 
f 16725 16787 16833 
f 16830 16726 16780 
s 2
f 16886 16830 16844 
s 1
f 16777 16691 16902 
f 16870 16685 16697 
f 16747 16852 16845 
f 16696 16781 16795 
f 16746 16851 16847 
f 16698 16783 16899 
f 16748 16850 16846 
f 16699 16784 16890 
f 16697 16782 16870 
f 16745 16849 16848 
s 2
f 16938 16804 16732 
s 1
f 16832 16693 16779 
f 16829 16676 16803 
s 2
f 16887 16829 16880 
s 1
f 16831 16692 16778 
s 2
f 16888 16831 16785 
s 1
f 16841 16683 16826 
s 2
f 16683 16841 16844 
s 1
f 16935 16894 16867 
f 16701 16789 16709 
f 16706 16794 16913 
f 16681 16933 16710 
f 16808 16682 16895 
f 16738 16788 16712 
f 16686 16796 16679 
f 16928 16909 16835 
f 16868 16906 16914 
f 16798 16702 16875 
f 16741 16680 16874 
f 16925 16929 16860 
f 16718 16808 16920 
f 16815 16890 16784 
f 16896 16899 16783 
f 16807 16684 16864 
f 16744 16795 16781 
f 16859 16703 16919 
f 16755 16688 16879 
f 16756 16678 16877 
f 16758 16687 16876 
f 16757 16677 16878 
f 16749 16714 16824 
s 2
f 16732 16842 16938 
s 1
f 16839 16860 16708 
f 16943 16780 16726 
f 16838 16857 16706 
f 16944 16779 16693 
f 16946 16778 16692 
f 16837 16858 16707 
f 16885 16753 16751 
s 2
f 16885 16832 16804 
s 1
f 16754 16868 16682 
f 16945 16803 16676 
f 16879 16848 16755 
f 16834 16755 16848 
f 16833 16756 16847 
f 16877 16847 16756 
f 16836 16757 16846 
f 16878 16846 16757 
f 16876 16845 16758 
f 16835 16758 16845 
f 16858 16752 16824 
f 16759 16954 16950 
f 16760 16952 16949 
f 16955 16750 16823 
f 16761 16953 16948 
f 16956 16751 16821 
f 16762 16951 16947 
f 16957 16820 16822 
f 16763 16867 16691 
f 16953 16761 16826 
f 16764 16865 16690 
f 16954 16759 16828 
f 16952 16760 16827 
f 16765 16754 16689 
f 16766 16866 16686 
f 16951 16762 16825 
f 16917 16918 16767 
f 16889 16713 16872 
f 16768 16715 16822 
s 2
f 16715 16768 16774 
f 16694 16769 16844 
s 1
f 16769 16694 16823 
f 16770 16872 16729 
f 16818 16793 16770 
f 16819 16790 16771 
f 16771 16874 16730 
f 16772 16875 16728 
f 16817 16792 16772 
f 16773 16873 16731 
f 16816 16791 16773 
s 2
f 16844 16880 16694 
f 16785 16774 16716 
s 1
f 16775 16799 16711 
f 16915 16707 16934 
f 16776 16719 16921 
f 16904 16809 16873 
f 16898 16870 16782 
f 16777 16727 16691 
f 16866 16766 16778 
f 16778 16837 16866 
f 16865 16764 16779 
f 16779 16838 16865 
f 16867 16763 16780 
f 16780 16839 16867 
f 16882 16855 16852 
f 16781 16728 16744 
f 16884 16854 16849 
f 16782 16731 16897 
f 16881 16856 16851 
f 16783 16729 16814 
f 16883 16853 16850 
f 16784 16730 16815 
s 2
f 16714 16749 16938 
f 16749 16888 16938 
s 1
f 16891 16905 16725 
f 16869 16786 16865 
f 16787 16681 16710 
f 16913 16911 16706 
f 16682 16868 16937 
f 16788 16738 16916 
f 16789 16912 16709 
f 16867 16736 16935 
f 16709 16771 16790 
f 16709 16790 16942 
f 16710 16773 16791 
f 16710 16791 16940 
f 16712 16772 16792 
f 16712 16792 16939 
f 16711 16770 16793 
f 16711 16793 16941 
f 16933 16737 16710 
f 16910 16794 16706 
f 16813 16892 16795 
f 16871 16703 16686 
f 16775 16909 16893 
f 16739 16796 16707 
f 16930 16932 16701 
f 16894 16797 16867 
f 16798 16788 16740 
f 16868 16914 16936 
f 16908 16934 16707 
f 16775 16695 16799 
f 16738 16926 16931 
f 16895 16800 16808 
f 16741 16801 16680 
f 16929 16907 16708 
f 16802 16902 16691 
f 16685 16930 16697 
f 16754 16765 16803 
f 16803 16705 16754 
s 2
f 16753 16885 16804 
f 16735 16753 16804 
s 1
f 16702 16805 16744 
f 16927 16906 16859 
f 16679 16806 16686 
f 16924 16928 16696 
f 16864 16743 16807 
f 16899 16891 16698 
f 16890 16926 16699 
f 16800 16742 16808 
f 16904 16700 16809 
f 16900 16921 16719 
f 16713 16810 16729 
f 16918 16903 16767 
f 16922 16925 16860 
f 16680 16811 16730 
f 16812 16691 16727 
f 16897 16898 16782 
f 16795 16744 16813 
f 16919 16923 16859 
f 16767 16684 16917 
f 16814 16896 16783 
f 16815 16704 16890 
f 16860 16920 16901 
f 16773 16722 16816 
f 16772 16724 16817 
f 16770 16723 16818 
f 16771 16721 16819 
f 16820 16945 16676 
f 16820 16957 16705 
f 16761 16821 16735 
f 16948 16821 16761 
f 16762 16822 16715 
f 16947 16822 16762 
f 16760 16823 16694 
f 16949 16823 16760 
f 16759 16824 16714 
f 16950 16824 16759 
f 16766 16825 16734 
f 16686 16825 16766 
f 16763 16826 16683 
f 16691 16826 16763 
f 16765 16827 16733 
f 16689 16827 16765 
f 16764 16828 16732 
f 16690 16828 16764 
f 16733 16829 16765 
s 2
f 16829 16733 16880 
s 1
f 16683 16830 16763 
s 2
f 16830 16683 16844 
f 16831 16734 16785 
s 1
f 16734 16831 16766 
f 16732 16832 16764 
s 2
f 16832 16732 16804 
s 1
f 16940 16833 16710 
f 16710 16833 16787 
f 16942 16834 16709 
f 16709 16834 16701 
f 16711 16835 16909 
f 16941 16835 16711 
f 16939 16836 16712 
f 16712 16836 16738 
f 16752 16946 16692 
f 16752 16858 16837 
f 16751 16956 16838 
f 16751 16944 16693 
f 16750 16943 16726 
f 16750 16955 16839 
f 16715 16840 16762 
s 2
f 16840 16715 16774 
s 1
f 16735 16841 16761 
s 2
f 16841 16735 16844 
s 1
f 16714 16842 16759 
s 2
f 16842 16714 16938 
s 1
f 16694 16843 16760 
s 2
f 16843 16694 16880 
f 16844 16804 16716 
f 16880 16844 16716 
s 1
f 16845 16696 16835 
f 16696 16845 16852 
f 16846 16699 16836 
f 16699 16846 16850 
f 16847 16698 16833 
f 16698 16847 16851 
f 16848 16697 16834 
f 16697 16848 16849 
f 16849 16782 16697 
f 16782 16849 16854 
f 16850 16784 16699 
f 16784 16850 16853 
f 16851 16783 16698 
f 16783 16851 16856 
f 16852 16781 16696 
f 16781 16852 16855 
f 16853 16730 16784 
f 16730 16853 16771 
f 16854 16731 16782 
f 16731 16854 16773 
f 16855 16728 16781 
f 16728 16855 16772 
f 16856 16729 16783 
f 16729 16856 16770 
f 16821 16948 16956 
f 16857 16719 16776 
f 16824 16950 16858 
f 16858 16717 16767 
f 16822 16947 16957 
f 16703 16859 16720 
f 16823 16949 16955 
f 16920 16860 16718 
f 16825 16686 16951 
f 16686 16703 16861 
f 16826 16691 16953 
f 16691 16812 16862 
f 16827 16689 16952 
f 16863 16689 16808 
f 16864 16690 16743 
f 16828 16690 16954 
f 16865 16706 16869 
f 16838 16706 16865 
f 16837 16707 16866 
f 16866 16707 16796 
f 16867 16708 16736 
f 16839 16708 16867 
f 16705 16868 16754 
f 16705 16859 16868 
s 2
f 16786 16869 16725 
f 16787 16725 16869 
f 16727 16777 16870 
f 16685 16870 16777 
f 16703 16871 16795 
f 16924 16795 16871 
s 1
f 16872 16711 16799 
f 16770 16711 16872 
f 16873 16710 16737 
f 16773 16710 16873 
f 16771 16709 16874 
f 16874 16709 16741 
f 16772 16712 16875 
f 16875 16712 16798 
f 16845 16876 16747 
f 16847 16877 16746 
f 16846 16878 16748 
f 16848 16879 16745 
s 2
f 16774 16880 16716 
f 16880 16774 16887 
s 1
f 16856 16881 16723 
f 16855 16882 16724 
f 16853 16883 16721 
f 16854 16884 16722 
s 2
f 16693 16832 16885 
s 1
f 16693 16885 16751 
f 16726 16886 16750 
s 2
f 16726 16830 16886 
f 16676 16829 16887 
s 1
f 16676 16887 16820 
s 2
f 16692 16831 16888 
s 1
f 16692 16888 16752 
s 2
f 16908 16767 16889 
f 16713 16889 16767 
f 16920 16808 16890 
f 16926 16890 16808 
f 16807 16743 16891 
f 16905 16891 16743 
f 16919 16703 16892 
f 16795 16892 16703 
f 16796 16739 16893 
f 16775 16893 16739 
f 16797 16894 16701 
f 16789 16701 16894 
f 16800 16895 16738 
f 16916 16738 16895 
f 16899 16896 16684 
f 16917 16684 16896 
f 16898 16897 16812 
f 16900 16812 16897 
f 16870 16898 16727 
f 16812 16727 16898 
f 16891 16899 16807 
f 16684 16807 16899 
f 16897 16809 16900 
f 16921 16900 16809 
f 16922 16901 16815 
f 16704 16815 16901 
f 16777 16902 16685 
f 16930 16685 16902 
f 16767 16903 16713 
f 16810 16713 16903 
f 16700 16904 16776 
f 16910 16776 16904 
f 16743 16786 16905 
f 16725 16905 16786 
f 16805 16702 16906 
f 16914 16906 16702 
f 16736 16907 16741 
f 16801 16741 16907 
f 16934 16908 16799 
f 16889 16799 16908 
f 16679 16796 16909 
f 16893 16909 16796 
f 16794 16910 16737 
f 16904 16737 16910 
f 16869 16911 16787 
f 16681 16787 16911 
f 16935 16736 16912 
f 16741 16912 16736 
f 16911 16913 16681 
f 16933 16681 16913 
f 16936 16914 16798 
f 16702 16798 16914 
f 16739 16915 16775 
f 16695 16775 16915 
f 16895 16682 16916 
f 16788 16916 16682 
f 16896 16814 16917 
f 16918 16917 16814 
f 16903 16918 16810 
f 16814 16810 16918 
f 16892 16813 16919 
f 16923 16919 16813 
f 16901 16920 16704 
f 16890 16704 16920 
f 16776 16921 16700 
f 16809 16700 16921 
f 16925 16922 16811 
f 16815 16811 16922 
f 16927 16923 16744 
f 16813 16744 16923 
f 16871 16806 16924 
f 16928 16924 16806 
f 16929 16925 16680 
f 16811 16680 16925 
f 16808 16742 16926 
f 16931 16926 16742 
f 16906 16927 16805 
f 16744 16805 16927 
f 16806 16679 16928 
f 16909 16928 16679 
f 16907 16929 16801 
f 16680 16801 16929 
f 16932 16930 16802 
f 16902 16802 16930 
f 16742 16800 16931 
f 16738 16931 16800 
f 16802 16797 16932 
f 16701 16932 16797 
f 16737 16933 16794 
f 16913 16794 16933 
f 16915 16934 16695 
f 16799 16695 16934 
f 16912 16789 16935 
f 16894 16935 16789 
f 16937 16936 16740 
f 16798 16740 16936 
f 16740 16788 16937 
f 16682 16937 16788 
f 16938 16785 16716 
f 16785 16938 16888 
s 1
f 16836 16939 16757 
f 16677 16757 16939 
f 16678 16756 16940 
f 16833 16940 16756 
f 16687 16758 16941 
f 16835 16941 16758 
f 16834 16942 16755 
f 16688 16755 16942 
f 16839 16943 16750 
f 16839 16780 16943 
f 16838 16779 16944 
f 16838 16944 16751 
f 16705 16803 16945 
f 16705 16945 16820 
f 16837 16778 16946 
f 16837 16946 16752 
f 16947 16861 16720 
f 16947 16720 16957 
f 16948 16719 16956 
f 16948 16862 16719 
f 16949 16718 16955 
f 16949 16863 16718 
f 16950 16864 16717 
f 16950 16717 16858 
f 16951 16861 16947 
f 16951 16686 16861 
f 16952 16863 16949 
f 16952 16689 16863 
f 16953 16862 16948 
f 16953 16691 16862 
f 16954 16864 16950 
f 16954 16690 16864 
f 16955 16718 16860 
f 16955 16860 16839 
f 16956 16719 16857 
f 16956 16857 16838 
f 16957 16859 16705 
f 16957 16720 16859 
s 8
f 16968 16963 16962 
f 16961 16964 16965 
f 16958 16967 16960 
f 16965 16969 16961 
f 16966 16972 16963 
f 16963 16970 16966 
f 16964 16959 16971 
f 16962 16971 16968 
f 16960 16966 16958 
f 16967 16961 16969 
f 16968 16971 16959 
f 16969 16960 16967 
f 16958 16966 16970 
f 16971 16965 16964 
f 16962 16963 16972 
s off
f 16985 16986 16987 16988 
f 16973 16976 16975 16974 
f 16973 16974 16978 16977 
f 16974 16975 16979 16978 
f 16975 16976 16980 16979 
f 16976 16973 16977 16980 
s 109
f 16977 16978 16982 16981 
f 16978 16979 16983 16982 
f 16979 16980 16984 16983 
f 16980 16977 16981 16984 
s off
f 16981 16982 16986 16985 
f 16982 16983 16987 16986 
f 16983 16984 16988 16987 
f 16984 16981 16985 16988 
s 8
f 16994 16993 17131 
f 16995 16994 17596 
f 16996 16995 17173 
f 16997 16996 17598 
f 16999 16998 17127 
f 17000 16999 17528 
f 17001 17000 17594 
f 17002 17001 17185 
f 17004 17003 17132 
f 17005 17004 17592 
f 17006 17005 17172 
f 17007 17006 17597 
f 17009 17008 17130 
f 17010 17009 17593 
f 17011 17010 17174 
f 17012 17011 17595 
s off
f 16993 16994 17014 
f 17014 17013 16993 
f 16994 16995 17871 
f 16995 16996 17265 
f 16996 16997 17023 
f 17023 17265 16996 
f 16998 16999 17016 
f 17016 17015 16998 
f 16999 17000 17869 
f 17000 17001 17263 
f 17001 17002 17024 
f 17024 17263 17001 
f 17003 17004 17018 
f 17018 17017 17003 
f 17004 17005 17872 
f 17005 17006 17266 
f 17006 17007 17021 
f 17021 17266 17006 
f 17008 17009 17020 
f 17020 17019 17008 
f 17009 17010 17870 
f 17010 17011 17264 
f 17011 17012 17022 
f 17022 17264 17011 
s 2
f 17149 17021 17007 
f 17008 17019 17144 
f 17147 17022 17012 
f 16993 17013 17142 
f 17148 17023 16997 
f 16998 17015 17146 
f 17145 17024 17002 
f 17003 17017 17143 
s 1
f 17053 17052 17051 
f 17051 17050 17049 
f 17049 17048 17047 
f 17051 17049 17047 
f 17047 17046 17045 
f 17045 17044 17043 
f 17047 17045 17043 
f 17051 17047 17043 
f 17043 17042 17041 
f 17041 17040 17039 
f 17043 17041 17039 
f 17039 17038 17037 
f 17037 17036 17035 
f 17039 17037 17035 
f 17043 17039 17035 
f 17035 17034 17033 
f 17033 17032 17031 
f 17035 17033 17031 
f 17031 17030 17029 
f 17029 17028 17027 
f 17031 17029 17027 
f 17035 17031 17027 
f 17043 17035 17027 
f 17051 17043 17027 
f 17027 17026 17025 
f 17051 17027 17025 
f 17053 17051 17025 
f 17054 17053 17025 
s 8
f 17025 17026 17056 
f 17056 17055 17025 
f 17026 17027 17057 
f 17057 17056 17026 
f 17027 17028 17058 
f 17058 17057 17027 
f 17028 17029 17059 
f 17059 17058 17028 
f 17029 17030 17060 
f 17060 17059 17029 
f 17030 17031 17061 
f 17061 17060 17030 
f 17031 17032 17062 
f 17062 17061 17031 
f 17032 17033 17063 
f 17063 17062 17032 
f 17033 17034 17064 
f 17064 17063 17033 
f 17034 17035 17065 
f 17065 17064 17034 
f 17035 17036 17066 
f 17066 17065 17035 
f 17036 17037 17067 
f 17067 17066 17036 
f 17037 17038 17068 
f 17068 17067 17037 
f 17038 17039 17069 
f 17069 17068 17038 
f 17039 17040 17070 
f 17070 17069 17039 
f 17040 17041 17071 
f 17071 17070 17040 
f 17041 17042 17072 
f 17072 17071 17041 
f 17042 17043 17073 
f 17073 17072 17042 
f 17043 17044 17074 
f 17074 17073 17043 
f 17044 17045 17075 
f 17075 17074 17044 
f 17045 17046 17076 
f 17076 17075 17045 
f 17046 17047 17077 
f 17077 17076 17046 
f 17047 17048 17078 
f 17078 17077 17047 
f 17048 17049 17079 
f 17079 17078 17048 
f 17049 17050 17080 
f 17080 17079 17049 
f 17050 17051 17081 
f 17081 17080 17050 
f 17051 17052 17082 
f 17082 17081 17051 
f 17052 17053 17083 
f 17083 17082 17052 
f 17053 17054 17084 
f 17084 17083 17053 
f 17054 17025 17055 
f 17055 17084 17054 
s off
f 17055 17056 17085 
f 17056 17057 17085 
f 17057 17058 17085 
f 17058 17059 17085 
f 17059 17060 17085 
f 17060 17061 17085 
f 17061 17062 17085 
f 17062 17063 17085 
f 17063 17064 17085 
f 17064 17065 17085 
f 17065 17066 17085 
f 17066 17067 17085 
f 17067 17068 17085 
f 17068 17069 17085 
f 17069 17070 17085 
f 17070 17071 17085 
f 17071 17072 17085 
f 17072 17073 17085 
f 17073 17074 17085 
f 17074 17075 17085 
f 17075 17076 17085 
f 17076 17077 17085 
f 17077 17078 17085 
f 17078 17079 17085 
f 17079 17080 17085 
f 17080 17081 17085 
f 17081 17082 17085 
f 17082 17083 17085 
f 17083 17084 17085 
f 17084 17055 17085 
f 17086 17087 19111 
f 17087 17088 19150 
f 17088 17089 17208 
f 17089 17090 18612 
f 17090 17091 17618 
f 17091 17092 19109 
f 17092 17093 18861 
f 17093 17094 17189 
f 17094 17095 17951 
f 17095 17096 18385 
f 17096 17097 17555 
f 17097 17098 17908 
f 17098 17099 18573 
f 17099 17100 18559 
f 17100 17101 17190 
f 17101 17102 19186 
f 17102 17103 19112 
f 17103 17104 17649 
f 17104 17105 18537 
f 17105 17106 17199 
f 17106 17107 18862 
f 17107 17108 18591 
f 17108 17109 17591 
f 17109 17110 18558 
f 17110 17111 18852 
f 17111 17112 18427 
f 17112 17113 18590 
f 17113 17114 17906 
f 17114 17115 18609 
f 17115 17086 17579 
s 8
f 17974 17087 17086 
f 17204 17088 17087 
f 17089 17088 17204 
f 17090 17089 17957 
f 17091 17090 17160 
f 17092 17091 17639 
f 17093 17092 18004 
f 17094 17093 17126 
f 17095 17094 17626 
f 17096 17095 17907 
f 17097 17096 17177 
f 17116 17098 17097 
f 17902 17099 17098 
f 17644 17100 17099 
f 17134 17101 17100 
f 17975 17102 17101 
f 17203 17103 17102 
f 17104 17103 17203 
f 17105 17104 17956 
f 17106 17105 17150 
f 17107 17106 17640 
f 17108 17107 18005 
f 17109 17108 17119 
f 17110 17109 17627 
f 17111 17110 17961 
f 17112 17111 17175 
f 17151 17113 17112 
f 17993 17114 17113 
f 17643 17115 17114 
f 17118 17086 17115 
f 17626 17907 17095 
f 17203 17956 17104 
f 17175 17623 17112 
f 17668 18101 19183 
f 17187 18187 17206 
f 17669 18100 19182 
f 17188 18186 17205 
f 17204 17957 17089 
f 17528 17594 17000 
f 19181 17646 17647 
f 18036 17878 18772 
f 19184 17645 17648 
f 18034 17879 18770 
f 17174 17595 17011 
f 17173 17598 16996 
f 17172 17597 17006 
s off
f 19150 19111 17087 
f 17951 17189 17094 
s 8
f 17099 17902 17644 
f 17578 19183 18101 
f 17577 19182 18100 
f 18254 18845 17200 
f 18253 18844 17201 
f 17214 17960 17632 
f 17213 17959 17634 
s off
f 18559 18573 17099 
f 18427 18852 17111 
s 2
f 17142 17131 16993 
f 17143 17132 17003 
f 17144 17130 17008 
f 17002 16990 17145 
f 17146 17127 16998 
f 17012 16992 17147 
f 16997 16989 17148 
f 17007 16991 17149 
s 8
f 17640 18005 17107 
f 17114 17993 17643 
f 17200 17949 18254 
f 18130 17825 17179 
f 17201 17950 18253 
f 18109 17826 17180 
s 1
f 17868 17858 17197 
s off
f 17555 18385 17096 
f 17916 18777 18425 
s 8
f 17632 18165 17214 
f 17261 17645 17621 
f 17262 17646 17620 
f 17634 18166 17213 
f 17639 18004 17092 
s 1
f 17196 17865 17637 
s 8
f 17647 18108 19181 
f 17648 18107 19184 
f 17642 18158 17745 
f 17621 18145 17261 
f 17641 18159 17746 
f 17620 18146 17262 
f 17206 17630 17187 
f 18159 17641 17578 
f 17205 17631 17188 
f 18158 17642 17577 
s off
f 17218 18766 17899 
f 17171 17624 17847 
f 18537 17649 17104 
f 19117 18608 18594 
s 8
f 17132 17592 17004 
f 17131 17596 16994 
f 17130 17593 17009 
f 17627 17961 17110 
s 1
f 17859 17864 17198 
s 8
f 17177 17622 17097 
s 1
f 17637 17856 17196 
s 8
f 17124 17617 17530 
f 17120 17619 17529 
s off
f 19156 19163 18780 
f 18385 17555 17818 
s 1
f 17209 17866 17182 
s 8
f 17185 16990 17002 
s 1
f 17216 17857 17186 
s 8
f 17636 18253 17950 
f 17139 17799 17153 
f 17140 17800 17152 
f 17635 18254 17949 
s off
f 17618 18612 17090 
f 18777 17916 19109 
f 17649 18537 18555 
f 17842 18256 18559 
s 8
f 19222 18770 17879 
f 17123 17158 17191 
f 19270 18772 17878 
f 17121 17159 17192 
f 19172 19171 17128 
f 17135 17167 17193 
f 19173 19174 17129 
f 17136 17166 17194 
s off
f 19078 17141 18139 
f 19175 19221 19119 
s 1
f 17215 17860 17161 
f 17864 17859 17154 
f 17858 17868 17176 
s off
f 18591 18862 17107 
f 17804 17791 17862 
s 8
f 17137 17837 17169 
f 17138 17836 17168 
s off
f 18335 18778 17820 
f 19122 18760 18592 
s 8
f 17102 17975 17203 
f 17087 17974 17204 
f 17169 17904 17125 
f 17168 17903 17122 
s off
f 17189 17951 17820 
f 17820 17157 18335 
f 19111 19150 17625 
f 18612 17618 18592 
s 1
f 17182 17183 17209 
f 17638 17861 17633 
s 8
f 17152 17900 17140 
f 17153 17901 17139 
s 1
f 17161 17162 17215 
f 17186 17184 17216 
f 17633 17867 17638 
s off
f 18609 17906 17114 
f 18780 18428 19156 
s 2
f 19464 18784 18467 
f 19291 18666 18284 
f 19462 18790 18459 
f 19293 18667 18278 
f 19290 18673 18275 
f 19467 18791 18468 
f 19450 18810 18476 
f 19310 18629 18264 
f 19448 18800 18487 
f 19304 18637 18269 
f 19447 18804 18484 
f 19312 18634 18262 
f 19451 18812 18474 
f 19308 18635 18272 
f 19449 18799 18486 
f 19306 18642 18263 
f 19476 18796 18460 
f 19292 18662 18285 
f 19309 18639 18265 
f 19459 18807 18479 
f 19458 18805 18478 
f 19305 18641 18268 
f 19311 18643 18266 
f 19457 18802 18485 
f 19461 18808 18481 
f 19307 18640 18271 
f 19456 18803 18488 
f 19313 18632 18261 
s 8
f 17191 17931 17123 
f 17192 17930 17121 
s off
f 17263 17869 17000 
f 17264 17870 17010 
f 17265 17871 16995 
f 17266 17872 17005 
s 2
f 19318 18739 18344 
f 17971 18814 18531 
f 19321 18731 18349 
f 17967 18827 18523 
f 19320 18740 18348 
f 17970 18821 18526 
f 17966 18815 18534 
f 19319 18727 18337 
f 19489 18834 18538 
f 19331 18700 18331 
f 19335 18705 18322 
f 19486 18836 18543 
f 19485 18835 18552 
f 19336 18706 18320 
f 19483 18833 18545 
f 19333 18701 18332 
f 19487 18831 18542 
f 19332 18699 18326 
f 19492 18829 18540 
f 19330 18697 18321 
f 19495 18830 18550 
f 19334 18696 18333 
f 18051 18843 18544 
f 19337 18704 18330 
f 19371 18687 18315 
f 18273 19237 19356 
f 18276 19231 19355 
f 19370 18677 18312 
f 18286 19235 19352 
f 19367 18688 18308 
f 19368 18678 18303 
f 18280 19233 19350 
f 18282 19228 19349 
f 19369 18685 18306 
f 19383 18656 18292 
f 18270 19223 19363 
f 18259 19225 19361 
f 19385 18651 18299 
f 19377 18680 18316 
f 18274 19236 19354 
f 19379 18681 18314 
f 18281 19232 19351 
f 19376 18684 18309 
f 18283 19234 19348 
f 19373 18676 18313 
f 18279 19230 19353 
f 18277 19229 19357 
f 19378 18679 18307 
f 19389 18649 18300 
f 18258 19227 19365 
f 19393 18647 18297 
f 18260 19226 19362 
f 18267 19224 19364 
f 19391 18655 18291 
f 19387 18690 18317 
f 18287 19238 19358 
f 19419 18745 18379 
f 18340 19278 19402 
f 19417 18748 18370 
f 18338 19285 19401 
f 18346 19279 19405 
f 19416 18750 18372 
f 19421 18744 18374 
f 18336 19280 19403 
f 18339 19281 19396 
f 19418 18747 18377 
f 19420 18756 18375 
f 18347 19286 19397 
f 18325 19271 19408 
f 19438 18722 18366 
f 18324 19272 19412 
f 19437 18721 18363 
f 18329 19277 19410 
f 19436 18715 18357 
f 18327 19274 19413 
f 19434 18714 18355 
f 19431 18720 18367 
f 18323 19276 19414 
f 19422 18758 18384 
f 18350 19288 19406 
f 18341 19284 19400 
f 19424 18746 18380 
f 18345 19287 19404 
f 19430 18754 18378 
f 18342 19283 19398 
f 19426 18752 18371 
f 18343 19282 19399 
f 19425 18751 18376 
f 18328 19275 19411 
f 19445 18717 18359 
f 19441 18723 18365 
f 18334 19273 19409 
f 19366 18682 18311 
f 18285 19317 19292 
f 19386 18650 18290 
f 18261 19300 19313 
f 18266 19295 19311 
f 19382 18645 18293 
f 19381 18653 18298 
f 18271 19294 19307 
f 18265 19297 19309 
f 19384 18652 18296 
f 19380 18658 18301 
f 18268 19302 19305 
f 19374 18675 18304 
f 18002 18925 18192 
f 19375 18686 18305 
f 18001 18930 18190 
f 18003 18935 18189 
f 19372 18683 18310 
f 19390 18654 18288 
f 18264 19303 19310 
f 19395 18646 18294 
f 18269 19301 19304 
f 19394 18657 18295 
f 18272 19299 19308 
f 19388 18644 18289 
f 18262 19296 19312 
f 19392 18648 18302 
f 18263 19298 19306 
s 1
f 17845 17419 17814 
f 17420 18028 17954 
f 17423 18035 17953 
f 17848 17422 17816 
s 2
f 19435 18710 18364 
f 18330 19323 19337 
f 19433 18713 18353 
f 18321 19329 19330 
f 19432 18724 18354 
f 18333 19327 19334 
f 19429 18753 18381 
f 18022 19024 18241 
f 18024 19017 18244 
f 19427 18755 18382 
f 19428 18757 18383 
f 18025 19021 18242 
f 19423 18749 18373 
f 18023 19018 18243 
f 19444 18718 18358 
f 18320 19325 19336 
f 19440 18711 18356 
f 18331 19326 19331 
f 19443 18719 18362 
f 18332 19328 19333 
f 18322 19324 19335 
f 19442 18712 18361 
f 19439 18716 18360 
f 18326 19322 19332 
f 19466 18785 18466 
f 19353 18670 18279 
f 19465 18794 18471 
f 19348 18665 18283 
f 19357 18672 18277 
f 19463 18797 18469 
f 19469 18793 18472 
f 19351 18664 18281 
f 19468 18786 18470 
f 19354 18668 18274 
f 19365 18631 18258 
f 19452 18806 18480 
f 19362 18638 18260 
f 19453 18809 18477 
f 19364 18630 18267 
f 19446 18801 18483 
f 19470 18798 18473 
f 19358 18674 18287 
f 19475 18792 18464 
f 19356 18660 18273 
f 19349 18663 18282 
f 19473 18788 18461 
f 19474 18795 18463 
f 19352 18661 18286 
f 19355 18671 18276 
f 19472 18789 18462 
f 19471 18787 18465 
f 19350 18669 18280 
f 19361 18636 18259 
f 19460 18811 18482 
f 19455 18813 18475 
f 19363 18633 18270 
s off
f 19454 18847 18557 
f 18415 18351 18425 
s 2
f 19399 18730 18343 
f 17969 18820 18533 
f 19398 18733 18342 
f 17965 18818 18528 
f 17964 18824 18529 
f 19404 18728 18345 
f 17968 18819 18525 
f 19400 18738 18341 
f 19488 18842 18551 
f 19409 18708 18334 
f 19411 18702 18328 
f 19484 18841 18547 
s 1
f 17998 17717 17947 
f 19132 17840 17628 
f 18564 17460 17462 
f 18622 18318 18007 
f 18563 17464 17466 
f 18621 18319 18008 
f 17996 17718 17952 
f 19131 17841 17629 
s 2
f 19396 18737 18339 
f 18039 18817 18524 
f 18037 18822 18522 
f 19405 18736 18346 
f 18040 18816 18535 
f 19397 18732 18347 
f 18038 18823 18527 
f 19401 18729 18338 
f 18041 18826 18532 
f 19403 18734 18336 
f 18042 18825 18530 
f 19402 18735 18340 
f 19408 18703 18325 
f 19496 18838 18549 
f 19494 18837 18548 
f 19414 18709 18323 
f 19412 18707 18324 
f 19493 18839 18541 
f 19410 18698 18329 
f 18048 18832 18546 
f 19413 18695 18327 
f 18047 18840 18539 
f 18043 18828 18536 
f 19406 18741 18350 
s 1
f 18606 19360 17482 
f 17652 17682 17480 
f 19126 17482 17691 
f 18565 17480 17682 
f 18566 17484 17681 
f 18607 19359 17486 
f 17656 17681 17484 
f 19125 17486 17690 
f 17489 19481 17678 
f 17676 19415 17488 
f 19127 17678 17693 
f 18569 17488 17490 
f 17662 19407 17492 
f 19128 17683 17698 
f 18570 17492 17494 
f 17493 19480 19479 
f 18571 17496 17685 
f 17499 19491 17498 
f 17650 17685 17496 
f 19137 17498 17696 
f 17660 17684 17500 
f 19138 17502 17692 
f 18572 17500 17684 
f 17501 19490 17502 
f 18579 17504 17506 
f 18616 19347 19343 
f 17672 19346 17504 
f 19142 17688 17704 
f 17670 19344 17508 
f 19143 17689 17705 
f 18581 17508 17510 
f 18617 19345 19342 
f 18588 17512 17680 
f 18623 19477 17514 
f 17654 17680 17512 
f 19139 17514 17687 
f 17658 17679 17516 
f 19140 17518 17686 
f 18589 17516 17679 
f 18624 19478 17518 
f 17521 18593 18387 
f 17827 18419 18369 
f 19134 17805 17674 
f 18568 17520 17522 
f 17822 18418 18368 
f 19133 17806 17675 
f 18567 17524 17526 
f 17525 18587 18386 
s 8
f 17127 17528 16999 
f 17529 17636 17120 
f 17632 17960 17826 
f 17530 17635 17124 
f 17634 17959 17825 
s 1
f 17917 18774 18578 
f 19135 17815 17719 
f 18582 17531 17533 
f 18627 18781 18615 
f 18628 18782 18619 
f 17918 18776 18580 
f 19136 17813 17720 
f 18583 17535 17537 
f 18599 17539 17541 
f 17542 19289 19176 
f 17743 19170 19177 
f 19145 17702 17706 
f 17544 19269 19166 
f 17694 19169 19178 
f 19144 17712 17713 
f 18598 17543 17545 
f 17802 19047 18854 
f 19151 17767 17731 
f 18596 17547 17549 
f 17548 19123 18860 
f 18595 17551 17553 
f 17554 19116 18855 
f 17765 18954 18849 
f 19149 17789 17737 
s off
f 17555 17908 18771 
f 17908 17555 17097 
s 2
f 18135 17556 17747 
f 17962 17748 17558 
f 17559 17747 17556 
f 17557 17558 17748 
f 17561 17749 17560 
f 17563 17562 17757 
f 18136 17560 17749 
f 17972 17757 17562 
f 17565 19148 19162 
f 17567 17564 17792 
f 18138 17566 17794 
f 17999 19120 19165 
f 18000 19118 19164 
f 17569 19141 19161 
f 17571 17568 17790 
f 18137 17570 17793 
s off
f 19168 19115 18608 
f 19113 18506 18352 
s 1
f 17573 17954 18028 
f 19130 17573 17611 
f 17419 17894 17421 
f 18574 17421 17894 
f 17422 17893 17424 
f 18575 17424 17893 
f 17575 17953 18035 
f 19129 17575 17612 
s 8
f 17125 17577 17169 
f 17169 17577 17137 
f 17168 17578 17138 
f 17122 17578 17168 
s off
f 17906 18609 18428 
f 18766 17218 17579 
f 18778 18335 19167 
f 19482 19119 19221 
f 18256 17842 17195 
f 18771 17581 18490 
f 18555 18594 18608 
f 19187 18413 19186 
s 1
f 17599 17898 17583 
f 18584 17583 17898 
f 17584 17911 17585 
f 19146 17585 17911 
f 17588 17910 17589 
f 19147 17589 17910 
f 17605 17897 17587 
f 18586 17587 17897 
s off
f 18558 17591 17109 
f 18862 18591 17791 
s 8
f 17592 17172 17005 
f 17593 17174 17010 
f 17594 17185 17001 
f 17595 16992 17012 
f 17596 17173 16995 
f 17597 16991 17007 
f 17598 16989 16997 
s 1
f 17583 17988 17599 
f 18742 17584 17963 
f 17585 17963 17584 
f 17854 17599 17988 
s 2
f 17991 17955 17601 
f 18131 17601 17955 
f 17994 17919 17603 
f 17604 17603 17919 
s 1
f 18743 17588 17973 
f 17589 17973 17588 
f 17852 17605 17989 
f 17587 17989 17605 
s 2
f 17995 17940 17609 
f 17610 17609 17940 
f 17992 17958 17607 
f 18132 17607 17958 
f 17574 17611 17421 
f 18203 17814 18028 
f 18222 17816 18035 
f 17576 17612 17424 
s 1
f 17876 17613 17994 
f 17919 17994 17613 
f 17614 17602 17991 
f 17955 17991 17602 
f 17616 17608 17992 
f 17958 17992 17608 
f 17874 17615 17995 
f 17940 17995 17615 
s 8
f 17617 17179 17825 
s off
f 19109 18861 18777 
f 18861 19109 17092 
s 8
f 17619 17180 17826 
f 19171 19181 18108 
f 18146 17620 17167 
f 19174 19184 18107 
f 18145 17621 17166 
f 17097 17622 17116 
f 17112 17623 17151 
s off
f 18590 18427 17112 
f 17624 17170 18779 
f 19188 19159 18863 
f 17133 18863 17208 
s 8
f 17126 17626 17094 
f 17119 17627 17109 
s 2
f 17832 17947 18318 
f 17463 17628 17460 
f 17829 17952 18319 
f 17465 17629 17464 
s 8
f 18844 17168 17836 
f 17903 17168 17630 
f 18845 17169 17837 
f 17904 17169 17631 
f 18770 18109 18034 
f 18165 17632 17158 
s 1
f 17867 17633 17210 
s 8
f 18772 18130 18036 
f 18166 17634 17159 
f 17152 17800 17635 
f 17635 17530 17152 
f 17636 17529 17153 
f 17153 17799 17636 
s 1
f 17856 17637 17178 
f 17861 17638 17217 
s 8
f 17160 17639 17091 
f 17150 17640 17106 
f 19183 19172 17668 
f 17746 17193 17641 
f 19182 19173 17669 
f 17745 17194 17642 
f 17115 17643 17118 
f 17100 17644 17134 
f 17645 19222 17165 
f 17931 17191 17645 
f 17646 19270 17164 
f 17930 17192 17646 
f 17164 17647 17646 
f 17165 17648 17645 
s off
f 19112 19186 17102 
f 19186 19112 19187 
s 2
f 18395 18610 17650 
f 17685 17650 18610 
s 4
f 17651 17650 17905 
s 1
f 17496 17905 17650 
s 2
f 18391 17886 18606 
f 19360 18606 17886 
f 18398 17481 17652 
f 17682 17652 17481 
f 18396 17515 17654 
f 17680 17654 17515 
f 18388 17913 18623 
f 19477 18623 17913 
f 19359 18607 17887 
f 18399 17487 17656 
f 17681 17656 17487 
f 18392 17887 18607 
f 18389 17914 18624 
f 19478 18624 17914 
f 18397 17517 17658 
f 17679 17658 17517 
s 4
f 17661 17660 17912 
s 1
f 17500 17912 17660 
s 2
f 18400 18611 17660 
f 17684 17660 18611 
f 18405 18603 17662 
f 19407 17662 18603 
s 4
f 17663 17662 17909 
s 1
f 17492 17909 17662 
s 2
f 17560 17664 17561 
f 17819 17972 18726 
f 17562 18726 17972 
f 17665 17561 17664 
f 17821 17962 18725 
f 17558 18725 17962 
f 17667 17559 17666 
f 17556 17666 17559 
s 8
f 17128 17668 19172 
f 17129 17669 19173 
s 2
f 18407 17896 18617 
f 19345 18617 17896 
f 18409 17509 17670 
f 19344 17670 17509 
f 18410 17507 17672 
f 19346 17672 17507 
f 18408 17895 18616 
f 19347 18616 17895 
f 17523 17674 17520 
f 18083 18369 18593 
f 18052 18368 18587 
f 17527 17675 17524 
f 19415 17676 18604 
s 4
f 17677 17676 17915 
s 1
f 17488 17915 17676 
s 2
f 18412 18604 17676 
f 18604 17678 19415 
f 18084 17490 17678 
f 18097 17679 17518 
f 17517 17518 17679 
f 17515 17514 17680 
f 18096 17680 17514 
f 17487 17486 17681 
f 18087 17681 17486 
f 17481 17482 17682 
f 18086 17682 17482 
f 18085 17494 17683 
f 18603 19479 19407 
f 18099 17684 17502 
f 18611 17502 17684 
f 18610 17498 17685 
f 18098 17685 17498 
f 17914 17516 19478 
f 17519 17686 17516 
f 17913 17512 19477 
f 17513 17687 17512 
f 17507 19343 19346 
f 18102 17506 17688 
f 18103 17510 17689 
f 17509 19342 19344 
f 17887 17484 19359 
f 17485 17690 17484 
f 17483 17691 17480 
f 17886 17480 19360 
f 17912 17500 19490 
f 17503 17692 17500 
f 17491 17693 17488 
f 17915 17488 19481 
s 4
f 17695 17694 17948 
s 1
f 19178 17948 17694 
s 2
f 18416 18659 17694 
f 19169 17694 18659 
f 17905 17496 19491 
f 17497 17696 17496 
s 4
f 18610 18395 17697 
f 17905 18390 17651 
s 2
f 17909 17492 19480 
f 17495 17698 17492 
s 4
f 17481 18398 17699 
f 17886 18391 17653 
f 17487 18399 17700 
f 17887 18392 17657 
f 18603 18405 17701 
f 17909 18394 17663 
s 2
f 18689 19176 19170 
f 18106 17541 17702 
s 4
f 18611 18400 17703 
f 17912 18393 17661 
s 2
f 17505 17704 17504 
f 17895 17504 19347 
f 17896 17508 19345 
f 17511 17705 17508 
f 17990 19177 19289 
f 17540 17706 17539 
s 1
f 19191 18461 18788 
f 19202 18465 18787 
f 19201 18462 18789 
f 19199 18463 18795 
f 19200 18464 18792 
s 2
f 18659 19166 19169 
f 18105 17545 17712 
f 17546 17713 17543 
f 17948 19178 19269 
s 1
f 19206 18475 18813 
f 19218 18482 18811 
s 4
f 17915 18406 17677 
f 18604 18412 17716 
s 1
f 17717 17462 17460 
s 2
f 17461 18007 17717 
s 1
f 17718 17466 17464 
s 2
f 17467 18008 17718 
f 17795 18578 18781 
f 17534 17719 17531 
f 17538 17720 17535 
f 17797 18580 18782 
s 1
f 19220 18484 18804 
f 19213 18481 18808 
f 19207 18476 18810 
f 19208 18488 18803 
f 19209 18485 18802 
f 19210 18478 18805 
f 19216 18479 18807 
f 19214 18486 18799 
f 19219 18487 18800 
f 19212 18474 18812 
s 2
f 18006 18854 19123 
f 17550 17731 17547 
s 1
f 19196 18460 18796 
f 19198 18468 18791 
f 19197 18459 18790 
f 19204 18467 18784 
s 4
f 17913 18388 17655 
f 17515 18396 17736 
s 2
f 17997 18849 19116 
f 17552 17737 17551 
f 18420 17999 18761 
f 19165 18761 17999 
f 18421 17565 17738 
f 19162 17738 17565 
s 4
f 17517 18397 17740 
f 17914 18389 17659 
s 2
f 18423 17569 17741 
f 19161 17741 17569 
f 18422 18000 18762 
f 19164 18762 18000 
f 18424 18689 17743 
f 19170 17743 18689 
s 4
f 17744 17743 17990 
s 1
f 19177 17990 17743 
s 8
f 17194 17745 17136 
f 17193 17746 17135 
s 1
f 18725 17558 17747 
f 19154 17747 17558 
f 18600 17748 17556 
f 17666 17556 17748 
f 18726 17562 17749 
f 19155 17749 17562 
f 19239 18522 18822 
f 19250 18527 18823 
f 19241 18535 18816 
f 19246 18532 18826 
f 19248 18524 18817 
f 19251 18530 18825 
f 19253 18536 18828 
f 17664 17560 17757 
f 18601 17757 17560 
f 19264 18539 18840 
f 19255 18549 18838 
f 19256 18541 18839 
f 19267 18548 18837 
f 19268 18546 18832 
s 4
f 17895 18408 17673 
f 17507 18410 17763 
f 17509 18409 17764 
f 17896 18407 17671 
s 2
f 18554 18693 17765 
f 18954 17765 18693 
s 4
f 17824 17765 17997 
s 1
f 18849 17997 17765 
s 2
f 18134 17549 17767 
f 18694 18860 19047 
s 1
f 19261 18552 18835 
f 19266 18540 18829 
f 19262 18542 18831 
f 19258 18543 18836 
f 19265 18538 18834 
f 19263 18545 18833 
f 19254 18544 18843 
f 19260 18550 18830 
f 19195 18466 18785 
f 19193 18469 18797 
f 19194 18472 18793 
f 19192 18471 18794 
f 19203 18470 18786 
f 19205 18473 18798 
f 19249 18534 18815 
f 19240 18526 18821 
f 19247 18531 18814 
f 19243 18523 18827 
f 19215 18480 18806 
f 19217 18483 18801 
f 19211 18477 18809 
s 2
f 18693 18855 18954 
f 18133 17553 17789 
s 1
f 18614 17790 17570 
f 17741 19161 19118 
s off
f 18691 18352 18506 
f 18352 18691 17591 
s 1
f 17738 19162 19120 
f 18613 17792 17566 
f 18762 19164 19141 
f 19157 17793 17568 
f 19158 17794 17564 
f 18761 19165 19148 
s 2
f 18576 17795 18627 
f 18781 18627 17795 
s 4
f 17796 17917 17795 
s 1
f 18578 17795 17917 
s 4
f 17798 17918 17797 
s 1
f 18580 17797 17918 
s 2
f 18577 17797 18628 
f 18782 18628 17797 
s 8
f 18187 17187 17799 
f 18253 17636 17799 
f 18186 17188 17800 
f 18254 17635 17800 
s 4
f 18659 18416 17801 
f 17948 18411 17695 
s 1
f 18854 18006 17802 
s 2
f 18556 18694 17802 
f 19047 17802 18694 
s 4
f 17835 17802 18006 
s off
f 17791 17804 18862 
f 18594 18555 18537 
s 2
f 18620 18387 18419 
f 18094 17522 17805 
f 18095 17526 17806 
f 18618 18386 18418 
s 1
f 19245 18533 18820 
f 19252 18525 18819 
f 19242 18529 18824 
f 19244 18528 18818 
f 19259 18547 18841 
f 19257 18551 18842 
s 2
f 17536 18619 18776 
f 18093 17537 17813 
s 1
f 17421 17814 17419 
s 2
f 17814 17421 17611 
f 18092 17533 17815 
f 17532 18615 18774 
f 17816 17424 17612 
s 1
f 17424 17816 17422 
s 4
f 18689 18424 17817 
f 17990 18417 17744 
s off
f 17157 17818 18853 
f 19180 18853 17818 
s 2
f 18726 18404 17819 
f 17664 18403 17665 
s off
f 17820 17207 17189 
f 17207 17820 18778 
s 2
f 17666 18401 17667 
f 18725 18402 17821 
f 18692 18618 17822 
f 18418 17822 18618 
s 4
f 17843 17822 18052 
s 1
f 18368 18052 17822 
s 4
f 17997 18426 17824 
f 18693 18554 17766 
s 8
f 17530 17825 17959 
f 17825 17530 17617 
f 17529 17826 17960 
f 17826 17529 17619 
s 4
f 17844 17827 18083 
s 1
f 18369 18083 17827 
s 2
f 18759 18620 17827 
f 18419 17827 18620 
s 4
f 17830 17996 17829 
s 1
f 17952 17829 17996 
s 2
f 18768 17829 18621 
f 18319 18621 17829 
s 4
f 17565 18421 17831 
f 17999 18420 17739 
s 1
f 17947 17832 17998 
s 2
f 18769 17832 18622 
f 18318 18622 17832 
s 4
f 17833 17998 17832 
f 17569 18423 17834 
f 18000 18422 17742 
f 18694 18556 17803 
f 18006 18489 17835 
s 8
f 17836 17201 18844 
f 17837 17200 18845 
s 4
f 17532 18560 17838 
f 17795 18576 17796 
f 17797 18577 17798 
f 17536 18561 17839 
s 2
f 18091 17462 17840 
f 17462 17717 18007 
f 17466 17718 18008 
f 18090 17466 17841 
s off
f 17141 19078 18413 
f 18413 19187 17141 
s 4
f 18618 18692 17823 
f 18052 18585 17843 
f 18083 18597 17844 
f 18620 18759 17828 
f 17863 17845 18203 
s 1
f 17814 18203 17845 
s 2
f 18846 18625 17845 
f 17419 17845 18625 
s off
f 18557 19185 19454 
f 18506 19113 19124 
s 2
f 18848 18626 17848 
f 17422 17848 18626 
s 4
f 17873 17848 18222 
s 1
f 17816 18222 17848 
s 4
f 17829 18768 17830 
f 17467 18763 17850 
f 17461 18767 17851 
f 17832 18769 17833 
f 17853 17852 18082 
s 1
f 17989 18082 17852 
s 2
f 18851 17606 17852 
f 17605 17852 17606 
f 18850 17600 17854 
f 17599 17854 17600 
s 4
f 17855 17854 18081 
s 1
f 17988 18081 17854 
f 17178 17163 17856 
f 17866 17209 17857 
f 17176 17156 17858 
f 17198 17197 17859 
f 17865 17196 17860 
f 17217 17212 17861 
s off
f 19160 17862 17791 
f 18608 19117 19168 
s 4
f 18625 18846 17846 
f 18203 18773 17863 
s 1
f 17154 17155 17864 
f 17860 17215 17865 
f 17857 17216 17866 
f 17210 17211 17867 
f 17197 17198 17868 
s off
f 17869 17016 16999 
f 17870 17020 17009 
f 17871 17014 16994 
f 17872 17018 17004 
s 4
f 18626 18848 17849 
f 18222 18775 17873 
s 2
f 18955 18765 17874 
f 17615 17874 18765 
s 4
f 17875 17874 18255 
s 1
f 17995 18255 17874 
s 2
f 18956 18764 17876 
f 17613 17876 18764 
s 4
f 17877 17876 18257 
s 1
f 17994 18257 17876 
s 8
f 17878 17164 19270 
f 17879 17165 19222 
s 2
f 18479 19384 19459 
f 18481 19381 19461 
f 18485 19382 19457 
f 18478 19380 19458 
f 18488 19386 19456 
f 18460 19366 19476 
s 4
f 17653 17652 17886 
s 1
f 17480 17886 17652 
s 4
f 17657 17656 17887 
s 1
f 17484 17887 17656 
s 4
f 17600 18850 17888 
f 18081 18856 17855 
s 2
f 18540 19433 19492 
f 18550 19432 19495 
f 18544 19435 18051 
s 4
f 18082 18857 17853 
f 17606 18851 17892 
s 2
f 17953 17575 17893 
f 18088 17893 17575 
f 17954 17573 17894 
f 18089 17894 17573 
s 4
f 17673 17672 17895 
s 1
f 17504 17895 17672 
s 4
f 17671 17670 17896 
s 1
f 17508 17896 17670 
s 2
f 17973 17589 17897 
f 18129 17897 17589 
f 17963 17585 17898 
f 18128 17898 17585 
s off
f 19159 19110 17625 
f 18847 19454 18553 
s 8
f 17959 17213 17900 
f 17960 17214 17901 
f 17098 17116 17902 
f 17630 17206 17903 
f 17631 17205 17904 
s 2
f 18390 17905 17499 
f 19491 17499 17905 
s off
f 17906 18590 17113 
f 18428 18780 17906 
s 8
f 17907 17177 17096 
s off
f 17581 18771 17908 
f 18573 18559 18256 
s 2
f 18394 17909 17493 
f 19480 17493 17909 
f 17989 17587 17910 
f 17590 17910 17587 
f 17988 17583 17911 
f 17586 17911 17583 
f 18393 17912 17501 
f 19490 17501 17912 
s 4
f 17655 17654 17913 
s 1
f 17512 17913 17654 
f 17516 17914 17658 
s 4
f 17659 17658 17914 
s 2
f 18406 17915 17489 
f 19481 17489 17915 
s off
f 17133 18592 18760 
f 18592 17133 18612 
s 2
f 18560 17532 17917 
f 18774 17917 17532 
f 18776 17918 17536 
f 18561 17536 17918 
s 1
f 17613 17601 17919 
f 18602 17919 17601 
s 2
f 18304 19462 19374 
f 18305 19464 19375 
f 18313 19466 19373 
f 18314 19469 19379 
f 18316 19468 19377 
f 18309 19465 19376 
f 18307 19463 19378 
f 18310 19467 19372 
f 18317 19470 19387 
s 4
f 18255 18858 17875 
f 18765 18955 17929 
s 8
f 17646 17262 17930 
f 17645 17261 17931 
s 2
f 18294 19448 19395 
f 18288 19450 19390 
f 18302 19449 19392 
f 18291 19446 19391 
f 18295 19451 19394 
f 18297 19453 19393 
f 18300 19452 19389 
f 18289 19447 19388 
s 1
f 17615 17607 17940 
f 18605 17940 17607 
s 4
f 18764 18956 17941 
f 18257 18859 17877 
s 2
f 19388 19447 18484 
f 19390 19450 18476 
f 19394 19451 18474 
f 19392 19449 18486 
f 19395 19448 18487 
s 1
f 17460 17947 17717 
s 2
f 17947 17460 17628 
f 18411 17948 17544 
f 19269 17544 17948 
s 8
f 17949 17124 17635 
f 17950 17120 17636 
s off
f 18385 17951 17095 
f 17157 17820 17951 
s 2
f 17952 17464 17629 
s 1
f 17464 17952 17718 
s 2
f 18626 17953 17422 
f 17893 17422 17953 
f 17894 17419 17954 
f 18625 17954 17419 
s 1
f 17602 17603 17955 
f 19152 17955 17603 
s 8
f 17956 17150 17105 
f 17957 17160 17090 
s 1
f 17608 17609 17958 
f 19153 17958 17609 
s 8
f 17959 17152 17530 
f 17900 17152 17959 
f 17960 17153 17529 
f 17901 17153 17960 
f 17961 17175 17111 
s 4
f 18401 17666 17962 
s 1
f 17748 17962 17666 
s 2
f 17600 17963 17599 
f 17898 17599 17963 
f 18378 17964 19430 
f 18371 17965 19426 
f 18381 17966 19429 
f 18373 17967 19423 
f 18380 17968 19424 
f 18376 17969 19425 
f 18382 17970 19427 
f 18383 17971 19428 
s 4
f 18403 17664 17972 
s 1
f 17757 17972 17664 
s 2
f 17606 17973 17605 
f 17897 17605 17973 
s 8
f 17086 17118 17974 
f 17101 17134 17975 
s 2
f 18356 19489 19440 
f 18358 19485 19444 
f 18361 19486 19442 
f 18360 19487 19439 
f 18362 19483 19443 
f 18365 19488 19441 
f 18359 19484 19445 
f 19440 19489 18538 
f 19439 19487 18542 
f 19444 19485 18552 
f 19442 19486 18543 
f 19443 19483 18545 
f 18081 17988 17584 
f 17911 17584 17988 
f 18082 17989 17588 
f 17910 17588 17989 
f 19289 17542 17990 
f 18417 17990 17542 
f 18764 17991 17613 
f 17601 17613 17991 
f 18765 17992 17615 
f 17607 17615 17992 
s 8
f 17113 17151 17993 
s 2
f 18257 17994 17602 
f 17603 17602 17994 
f 17609 17608 17995 
f 18255 17995 17608 
f 18763 17467 17996 
f 17718 17996 17467 
f 18426 17997 17554 
f 19116 17554 17997 
f 18767 17461 17998 
f 17717 17998 17461 
s 4
f 17739 17738 17999 
s 1
f 19120 17999 17738 
s 4
f 17742 17741 18000 
s 1
f 19118 18000 17741 
s 2
f 18284 19315 19291 
f 18278 19314 19293 
f 18275 19316 19290 
s 8
f 18004 17126 17093 
f 18005 17119 17108 
s 2
f 18489 18006 17548 
f 19123 17548 18006 
f 18007 17840 17462 
s 1
f 17840 18007 18318 
f 17841 18008 18319 
s 2
f 18008 17841 17466 
f 19472 19370 18312 
f 19471 19368 18303 
f 19474 19367 18308 
f 19475 19371 18315 
f 19473 19369 18306 
f 19476 19366 18311 
f 19457 19382 18293 
f 19459 19384 18296 
f 19456 19386 18290 
f 19461 19381 18298 
f 19455 19383 18292 
f 19458 19380 18301 
f 19460 19385 18299 
f 18337 19339 19319 
f 18349 19338 19321 
f 18348 19340 19320 
f 18344 19341 19318 
f 18475 19383 19455 
f 18482 19385 19460 
s 1
f 18028 17611 17573 
s 2
f 17611 18028 17814 
f 18462 19370 19472 
f 18463 19367 19474 
f 18461 19369 19473 
f 18464 19371 19475 
f 18465 19368 19471 
s 8
f 17180 18034 18109 
s 2
f 17612 18035 17816 
s 1
f 18035 17612 17575 
s 8
f 17179 18036 18130 
s 2
f 18037 19416 18372 
f 18038 19417 18370 
f 18039 19418 18377 
f 18040 19420 18375 
f 18041 19421 18374 
f 18042 19419 18379 
f 18043 19422 18384 
f 19492 19433 18353 
f 19493 19437 18363 
f 19495 19432 18354 
f 18047 19434 18355 
f 18048 19436 18357 
f 19496 19438 18366 
f 19494 19431 18367 
f 18051 19435 18364 
f 18585 18052 17525 
f 18587 17525 18052 
f 18541 19437 19493 
f 18548 19431 19494 
f 18549 19438 19496 
f 18539 19434 18047 
f 18546 19436 18048 
f 18522 19416 18037 
f 18532 19421 18041 
f 18527 19417 18038 
f 18535 19420 18040 
f 18530 19419 18042 
f 18524 19418 18039 
f 18536 19422 18043 
f 17776 18924 18065 
f 19230 19195 19059 
f 17707 18923 18066 
f 19228 19191 19058 
f 17710 18932 18067 
f 19235 19199 19054 
f 17778 18936 18068 
f 19232 19194 19053 
f 17777 18926 18069 
f 19229 19193 19061 
f 17709 18928 18070 
f 19231 19201 19052 
f 17711 18934 18071 
f 19237 19200 19060 
f 17780 18933 18072 
f 19236 19203 19049 
f 17708 18929 18073 
f 19233 19202 19051 
f 17779 18931 18074 
f 19234 19192 19048 
f 17714 18943 18075 
f 19223 19206 19077 
f 19225 19218 19063 
f 17715 18940 18076 
f 17788 18945 18077 
f 19226 19211 19070 
f 17786 18946 18078 
f 19227 19215 19067 
f 19224 19217 19072 
f 17787 18948 18079 
f 17781 18938 18080 
f 19238 19205 19062 
f 17584 18742 18081 
f 18856 18081 18742 
f 18857 18082 18743 
f 17588 18743 18082 
f 18597 18083 17521 
f 18593 17521 18083 
f 17488 18569 17491 
f 17678 19127 18084 
f 17683 19128 18085 
f 17492 18570 17495 
f 17480 18565 17483 
f 17482 19126 18086 
f 17486 19125 18087 
f 17484 18566 17485 
f 17575 19129 18088 
f 17424 18575 17576 
f 17421 18574 17574 
f 17573 19130 18089 
f 17464 18563 17465 
f 17841 19131 18090 
f 17460 18564 17463 
f 17840 19132 18091 
f 17815 19135 18092 
f 17531 18582 17534 
f 17535 18583 17538 
f 17813 19136 18093 
f 17520 18568 17523 
f 17805 19134 18094 
f 17806 19133 18095 
f 17524 18567 17527 
f 17512 18588 17513 
f 17514 19139 18096 
f 17516 18589 17519 
f 17518 19140 18097 
f 17498 19137 18098 
f 17496 18571 17497 
f 17500 18572 17503 
f 17502 19138 18099 
s 8
f 18100 17137 17577 
f 18101 17138 17578 
s 2
f 17504 18579 17505 
f 17688 19142 18102 
f 17689 19143 18103 
f 17508 18581 17511 
s off
f 18351 18415 19179 
f 18557 19221 19185 
s 2
f 17543 18598 17546 
f 17712 19144 18105 
f 17702 19145 18106 
f 17539 18599 17540 
s 8
f 18107 17129 19174 
f 18108 17128 19171 
f 17826 18109 17632 
f 17158 17632 18109 
s 2
f 17750 19016 18110 
f 19279 19239 19085 
f 17808 19019 18111 
f 19284 19252 19079 
f 17809 19027 18112 
f 19287 19242 19088 
f 17810 19028 18113 
f 19283 19244 19081 
f 17755 19026 18114 
f 19278 19251 19087 
f 17751 19020 18115 
f 19285 19250 19084 
f 17753 19029 18116 
f 19280 19246 19089 
f 19282 19245 19092 
f 17807 19022 18117 
f 17754 19023 18118 
f 19281 19248 19082 
f 17752 19025 18119 
f 19286 19241 19086 
f 17759 19037 18120 
f 19271 19255 19095 
f 17761 19041 18121 
f 19276 19267 19096 
f 19275 19259 19105 
f 17811 19032 18122 
f 19277 19268 19098 
f 17762 19040 18123 
f 17760 19043 18124 
f 19272 19256 19107 
f 17812 19045 18125 
f 19273 19257 19100 
f 17758 19035 18126 
f 19274 19264 19103 
f 17756 19031 18127 
f 19288 19253 19093 
f 17585 19146 18128 
f 17583 18584 17586 
f 17587 18586 17590 
f 17589 19147 18129 
s 8
f 17825 18130 17634 
f 17159 17634 18130 
s 2
f 17919 18602 17604 
f 17955 19152 18131 
f 17940 18605 17610 
f 17958 19153 18132 
f 17551 18595 17552 
f 17789 19149 18133 
f 17547 18596 17550 
f 17767 19151 18134 
f 17747 19154 18135 
f 17748 18600 17557 
f 17757 18601 17563 
f 17749 19155 18136 
f 17793 19157 18137 
f 17790 18614 17571 
f 17792 18613 17567 
f 17794 19158 18138 
s off
f 19115 19168 18562 
f 19189 18139 19175 
s 2
f 19070 19362 19226 
f 19067 19365 19227 
f 19063 19361 19225 
f 19072 19364 19224 
f 19077 19363 19223 
s 8
f 17166 17136 18145 
f 17167 17135 18146 
s 2
f 19061 19357 19229 
f 19048 19348 19234 
f 19060 19356 19237 
f 19054 19352 19235 
f 19059 19353 19230 
f 19051 19350 19233 
f 19052 19355 19231 
f 19058 19349 19228 
f 19049 19354 19236 
f 19053 19351 19232 
f 19062 19358 19238 
s 8
f 17577 17125 18158 
f 17578 17122 18159 
s 2
f 18160 18927 18191 
f 19391 19446 18483 
f 19393 19453 18477 
f 19389 19452 18480 
s off
f 17141 18164 18139 
f 18164 17141 19187 
s 8
f 17158 17123 18165 
f 17159 17121 18166 
s 2
f 18167 18939 18202 
f 18168 18950 18195 
f 18169 18944 18193 
f 18170 18953 18194 
f 18171 18942 18200 
f 18172 18947 18196 
f 18173 18949 18197 
f 18174 18941 18198 
f 18175 18951 18201 
f 18176 18952 18199 
f 19374 19462 18459 
f 19372 19467 18468 
f 19375 19464 18467 
f 19378 19463 18469 
f 19376 19465 18471 
f 19373 19466 18466 
f 19377 19468 18470 
f 19379 19469 18472 
f 19387 19470 18473 
s 8
f 17800 17140 18186 
f 17799 17139 18187 
s off
f 18139 19189 19078 
f 17195 18783 18256 
s 2
f 19290 19198 19050 
f 19198 19290 19316 
f 19204 19291 19315 
f 19291 19204 19056 
f 19292 19196 19057 
f 19196 19292 19317 
f 19197 19293 19314 
f 19293 19197 19055 
f 19207 19310 19303 
f 19310 19207 19075 
f 19311 19209 19074 
f 19209 19311 19295 
f 19213 19307 19294 
f 19307 19213 19069 
f 19220 19312 19296 
f 19312 19220 19068 
f 19309 19216 19066 
f 19216 19309 19297 
f 19210 19305 19302 
f 19305 19210 19076 
f 19219 19304 19301 
f 19304 19219 19064 
f 19214 19306 19298 
f 19306 19214 19071 
f 19208 19313 19300 
f 19313 19208 19073 
f 19212 19308 19299 
f 19308 19212 19065 
f 18773 18203 17420 
f 18028 17420 18203 
f 19095 19408 19271 
f 19103 19413 19274 
f 19098 19410 19277 
f 19100 19409 19273 
f 19105 19411 19275 
f 19107 19412 19272 
f 19096 19414 19276 
f 19087 19402 19278 
f 19089 19403 19280 
f 19088 19404 19287 
f 19084 19401 19285 
f 19079 19400 19284 
f 19082 19396 19281 
f 19081 19398 19283 
f 19085 19405 19279 
f 19092 19399 19282 
f 19086 19397 19286 
f 19093 19406 19288 
f 18035 17423 18222 
f 18775 18222 17423 
f 19441 19488 18551 
f 19445 19484 18547 
f 18225 19033 18246 
f 18226 19038 18247 
f 18227 19034 18251 
f 18228 19044 18248 
f 18229 19036 18245 
f 18230 19039 18249 
f 18231 19046 18250 
f 18232 19042 18252 
f 19423 17967 18523 
f 19427 17970 18526 
f 19429 17966 18534 
f 19430 17964 18529 
f 19425 17969 18533 
f 19424 17968 18525 
f 19428 17971 18531 
f 19426 17965 18528 
f 19319 19249 19083 
f 19249 19319 19339 
f 19318 19247 19080 
f 19247 19318 19341 
f 19321 19243 19090 
f 19243 19321 19338 
f 19320 19240 19091 
f 19240 19320 19340 
f 19266 19330 19329 
f 19330 19266 19097 
f 19265 19331 19326 
f 19331 19265 19101 
f 19332 19262 19102 
f 19262 19332 19322 
f 19335 19258 19104 
f 19258 19335 19324 
f 19263 19333 19328 
f 19333 19263 19099 
f 19336 19261 19106 
f 19261 19336 19325 
f 19254 19337 19323 
f 19337 19254 19108 
f 19334 19260 19094 
f 19260 19334 19327 
s 8
f 18844 18253 17187 
f 17799 17187 18253 
f 18845 18254 17188 
f 17800 17188 18254 
s 2
f 18858 18255 17616 
f 17608 17616 18255 
s off
f 17581 18256 18783 
f 18256 17581 18573 
s 2
f 18859 18257 17614 
f 17602 17614 18257 
f 18163 18873 18649 
f 19227 18258 17339 
f 19225 18259 17321 
f 18027 18870 18651 
f 18162 18866 18647 
f 19226 18260 17342 
f 17884 18871 18650 
f 19300 18261 17406 
f 17942 18869 18644 
f 19296 18262 17417 
f 19298 18263 17418 
f 17945 18864 18648 
f 17943 18872 18654 
f 19303 18264 17414 
f 17880 18875 18652 
f 19297 18265 17409 
f 19295 18266 17407 
f 17882 18867 18645 
f 19224 18267 17345 
f 18161 18877 18655 
f 17883 18868 18658 
f 19302 18268 17410 
f 19301 18269 17415 
f 17946 18878 18646 
f 18026 18865 18656 
f 19223 18270 17318 
f 19294 18271 17408 
f 17881 18876 18653 
f 17944 18874 18657 
f 19299 18272 17416 
f 18032 18894 18687 
f 19237 18273 17303 
f 18183 18902 18680 
f 19236 18274 17324 
f 19316 18275 17413 
f 18178 18897 18683 
f 19231 18276 17306 
f 18029 18904 18677 
f 19229 18277 17336 
f 18180 18895 18679 
f 19314 18278 17411 
f 18177 18896 18675 
f 18182 18903 18676 
f 19230 18279 17333 
f 18033 18898 18678 
f 19233 18280 17312 
f 18184 18899 18681 
f 19232 18281 17327 
f 19228 18282 17315 
f 18031 18907 18685 
f 18181 18906 18684 
f 19234 18283 17330 
f 19315 18284 17412 
f 18179 18905 18686 
f 17885 18900 18682 
f 19317 18285 17405 
f 19235 18286 17309 
f 18030 18901 18688 
f 19238 18287 17348 
f 18185 18908 18690 
f 19450 18288 17229 
f 17723 18891 18629 
f 17721 18879 18634 
f 19447 18289 17235 
f 17724 18887 18632 
f 19456 18290 17259 
f 19446 18291 17347 
f 18143 18884 18630 
f 18144 18888 18633 
f 19455 18292 17320 
f 19457 18293 17253 
f 17725 18886 18643 
f 19448 18294 17232 
f 17729 18893 18637 
f 17730 18885 18635 
f 19451 18295 17238 
f 17727 18881 18639 
f 19459 18296 17247 
f 18140 18883 18638 
f 19453 18297 17344 
f 19461 18298 17256 
f 17722 18890 18640 
f 19460 18299 17323 
f 18142 18880 18636 
f 18141 18892 18631 
f 19452 18300 17341 
f 17726 18882 18641 
f 19458 18301 17250 
f 19449 18302 17241 
f 17728 18889 18642 
f 18152 18911 18669 
f 19471 18303 17314 
f 19462 18304 17223 
f 17734 18913 18667 
f 19464 18305 17220 
f 17735 18917 18666 
f 19473 18306 17317 
f 18154 18910 18663 
f 19463 18307 17338 
f 18147 18909 18672 
f 19474 18308 17311 
f 18150 18912 18661 
f 18148 18915 18665 
f 19465 18309 17332 
f 19467 18310 17226 
f 17733 18919 18673 
f 17732 18916 18662 
f 19476 18311 17244 
f 19472 18312 17308 
f 18153 18921 18671 
f 18151 18918 18670 
f 19466 18313 17335 
f 19469 18314 17329 
f 18156 18914 18664 
f 18149 18922 18660 
f 19475 18315 17305 
f 18155 18920 18668 
f 19468 18316 17326 
f 19470 18317 17350 
f 18157 18937 18674 
s 1
f 18318 17628 17840 
s 2
f 17628 18318 17947 
f 17629 18319 17952 
s 1
f 18319 17629 17841 
s 2
f 17985 18960 18718 
f 19325 18320 17432 
f 17889 18958 18713 
f 19329 18321 17426 
f 19324 18322 17435 
f 17986 18963 18712 
f 18054 18962 18720 
f 19276 18323 17381 
f 19272 18324 17372 
f 18053 18957 18721 
f 19271 18325 17369 
f 18055 18965 18722 
f 17984 18970 18716 
f 19322 18326 17436 
f 18056 18969 18714 
f 19274 18327 17378 
f 19275 18328 17399 
f 18224 18959 18717 
f 19277 18329 17375 
f 18057 18964 18715 
f 17891 18968 18710 
f 19323 18330 17425 
f 19326 18331 17433 
f 17983 18971 18711 
f 17987 18967 18719 
f 19328 18332 17434 
f 17890 18961 18724 
f 19327 18333 17427 
f 19273 18334 17402 
f 18223 18966 18723 
s off
f 18853 19180 19482 
f 17580 19167 18335 
s 2
f 18059 18988 18744 
f 19280 18336 17360 
f 18235 18991 18753 
f 19339 18337 17428 
f 18060 18990 18748 
f 19285 18338 17354 
f 18063 18992 18747 
f 19281 18339 17363 
f 18062 18996 18745 
f 19278 18340 17351 
f 19284 18341 17387 
f 18238 18997 18746 
f 18240 18993 18752 
f 19283 18342 17393 
f 19282 18343 17396 
f 18237 18999 18751 
f 19341 18344 17430 
f 18239 18998 18757 
f 19287 18345 17390 
f 18236 19000 18754 
f 19279 18346 17357 
f 18058 18995 18750 
f 18061 18994 18756 
f 19286 18347 17366 
f 18234 18987 18755 
f 19340 18348 17429 
f 19338 18349 17431 
f 18233 18989 18749 
f 18064 19001 18758 
f 19288 18350 17384 
s off
f 17453 19121 18557 
f 19121 17453 19188 
f 17591 18558 18352 
f 18352 17171 19113 
s 2
f 17769 18979 18697 
f 19492 18353 17295 
f 17775 18973 18696 
f 19495 18354 17298 
f 18047 18355 17380 
f 18205 18976 18695 
f 19489 18356 17280 
f 17772 18972 18700 
f 18048 18357 17377 
f 18206 18980 18698 
f 17768 18981 18706 
f 19485 18358 17286 
f 19484 18359 17401 
f 18208 18975 18702 
f 17770 18983 18699 
f 19487 18360 17292 
f 19486 18361 17283 
f 17771 18974 18705 
f 19483 18362 17289 
f 17773 18982 18701 
f 19493 18363 17374 
f 18209 18978 18707 
f 17774 18985 18704 
f 18051 18364 17301 
f 19488 18365 17404 
f 18207 18977 18708 
f 19496 18366 17371 
f 18204 18984 18703 
f 18210 18986 18709 
f 19494 18367 17383 
s 1
f 17524 18368 18418 
s 2
f 18368 17524 17675 
f 18369 17520 17674 
s 1
f 17520 18369 18419 
s 2
f 18214 19005 18729 
f 18038 18370 17356 
f 17965 18371 17395 
f 18217 19003 18733 
f 18037 18372 17359 
f 18218 19002 18736 
f 17967 18373 17271 
f 17785 19008 18731 
f 18212 19010 18734 
f 18041 18374 17362 
f 18220 19009 18732 
f 18040 18375 17368 
f 17969 18376 17398 
f 18219 19006 18730 
f 18216 19007 18737 
f 18039 18377 17365 
f 17964 18378 17392 
f 18213 19004 18728 
f 18211 19012 18735 
f 18042 18379 17353 
f 17968 18380 17389 
f 18215 19013 18738 
f 17782 19015 18727 
f 17966 18381 17277 
f 17970 18382 17274 
f 17783 19011 18740 
f 17971 18383 17268 
f 17784 19014 18739 
f 18221 19030 18741 
f 18043 18384 17386 
s off
f 17951 18385 17157 
f 17818 17157 18385 
s 1
f 17806 18386 18587 
s 2
f 18386 17806 17526 
s 1
f 17805 18387 18593 
s 2
f 18387 17805 17522 
s 1
f 18388 17736 18396 
s 2
f 18623 17736 18388 
f 18624 17740 18389 
s 1
f 18389 17740 18397 
f 18390 17697 18395 
s 2
f 17499 17697 18390 
s 1
f 18391 17699 18398 
s 2
f 18606 17699 18391 
s 1
f 18392 17700 18399 
s 2
f 18607 17700 18392 
f 17501 17703 18393 
s 1
f 18393 17703 18400 
s 2
f 17493 17701 18394 
s 1
f 18394 17701 18405 
s 2
f 17650 17651 18395 
s 1
f 18395 17651 18390 
f 18396 17655 18388 
s 2
f 17654 17655 18396 
s 1
f 18397 17659 18389 
s 2
f 17658 17659 18397 
s 1
f 18398 17653 18391 
s 2
f 17652 17653 18398 
f 17656 17657 18399 
s 1
f 18399 17657 18392 
f 18400 17661 18393 
s 2
f 17660 17661 18400 
s 4
f 17962 17821 18401 
s 1
f 17667 18401 17821 
s 4
f 17559 17667 18402 
s 1
f 17821 18402 17667 
s 4
f 17972 17819 18403 
s 1
f 17665 18403 17819 
f 17819 18404 17665 
s 4
f 17561 17665 18404 
s 2
f 17662 17663 18405 
s 1
f 18405 17663 18394 
f 18406 17716 18412 
s 2
f 17489 17716 18406 
s 1
f 18407 17764 18409 
s 2
f 18617 17764 18407 
s 1
f 18408 17763 18410 
s 2
f 18616 17763 18408 
f 17670 17671 18409 
s 1
f 18409 17671 18407 
f 18410 17673 18408 
s 2
f 17672 17673 18410 
s 1
f 18411 17801 18416 
s 2
f 17544 17801 18411 
s 1
f 18412 17677 18406 
s 2
f 17676 17677 18412 
s off
f 18413 17842 17190 
f 17842 18413 19078 
f 18779 17847 17624 
f 18414 19124 17847 
f 19167 17580 18104 
f 18104 19179 19167 
s 1
f 18416 17695 18411 
s 2
f 17694 17695 18416 
s 1
f 18417 17817 18424 
s 2
f 17542 17817 18417 
s 1
f 18418 17526 17524 
s 2
f 17526 18418 18386 
f 17522 18419 18387 
s 1
f 18419 17522 17520 
s 2
f 18761 17831 18420 
s 1
f 18420 17831 18421 
f 18421 17739 18420 
s 2
f 17738 17739 18421 
s 1
f 18422 17834 18423 
s 2
f 18762 17834 18422 
f 17741 17742 18423 
s 1
f 18423 17742 18422 
s 2
f 17743 17744 18424 
s 1
f 18424 17744 18417 
s off
f 17202 18425 18777 
f 18425 17202 18415 
s 1
f 18426 17766 18554 
s 2
f 17554 17766 18426 
s off
f 18852 18427 17624 
f 17170 17624 18427 
f 18428 17218 18553 
f 17218 18428 18609 
s 1
f 18794 19048 19192 
f 18797 19061 19193 
f 18791 19050 19198 
f 18792 19060 19200 
f 18789 19052 19201 
f 18790 19055 19197 
f 18787 19051 19202 
f 18784 19056 19204 
f 18796 19057 19196 
f 18786 19049 19203 
f 18788 19058 19191 
f 18795 19054 19199 
f 18793 19053 19194 
f 18785 19059 19195 
f 18798 19062 19205 
f 18812 19065 19212 
f 18804 19068 19220 
f 18808 19069 19213 
f 18809 19070 19211 
f 18801 19072 19217 
f 18805 19076 19210 
f 18811 19063 19218 
f 18803 19073 19208 
f 18813 19077 19206 
f 18800 19064 19219 
f 18806 19067 19215 
f 18807 19066 19216 
f 18802 19074 19209 
f 18810 19075 19207 
f 18799 19071 19214 
f 18459 18192 18925 
s 2
f 19374 18459 17224 
s 1
f 18460 18191 18927 
s 2
f 19366 18460 17245 
s 1
f 18461 18066 18923 
s 2
f 19369 18461 17316 
s 1
f 18462 18070 18928 
s 2
f 19370 18462 17307 
s 1
f 18463 18067 18932 
s 2
f 19367 18463 17310 
s 1
f 18464 18071 18934 
s 2
f 19371 18464 17304 
s 1
f 18465 18073 18929 
s 2
f 19368 18465 17313 
s 1
f 18466 18065 18924 
s 2
f 19373 18466 17334 
s 1
f 18467 18190 18930 
s 2
f 19375 18467 17221 
s 1
f 18468 18189 18935 
s 2
f 19372 18468 17227 
s 1
f 18469 18069 18926 
s 2
f 19378 18469 17337 
s 1
f 18470 18072 18933 
s 2
f 19377 18470 17325 
s 1
f 18471 18074 18931 
s 2
f 19376 18471 17331 
s 1
f 18472 18068 18936 
s 2
f 19379 18472 17328 
s 1
f 18473 18080 18938 
s 2
f 19387 18473 17349 
s 1
f 18474 18202 18939 
s 2
f 19394 18474 17239 
s 1
f 18475 18075 18943 
s 2
f 19383 18475 17319 
s 1
f 18476 18193 18944 
s 2
f 19390 18476 17230 
s 1
f 18477 18077 18945 
s 2
f 19393 18477 17343 
s 1
f 18478 18198 18941 
s 2
f 19380 18478 17251 
s 1
f 18479 18197 18949 
s 2
f 19384 18479 17248 
s 1
f 18480 18078 18946 
s 2
f 19389 18480 17340 
f 19381 18481 17257 
s 1
f 18481 18195 18950 
f 18482 18076 18940 
s 2
f 19385 18482 17322 
s 1
f 18483 18079 18948 
s 2
f 19391 18483 17346 
s 1
f 18484 18196 18947 
s 2
f 19388 18484 17236 
s 1
f 18485 18194 18953 
s 2
f 19382 18485 17254 
f 19392 18486 17242 
s 1
f 18486 18200 18942 
f 18487 18199 18952 
s 2
f 19395 18487 17233 
s 1
f 18488 18201 18951 
s 2
f 19386 18488 17260 
f 17548 17803 18489 
s 1
f 18489 17803 18556 
s off
f 19119 19482 19180 
f 18783 17195 18188 
s 1
f 18819 19079 19252 
f 18823 19084 19250 
f 18826 19089 19246 
f 18825 19087 19251 
f 18814 19080 19247 
f 18827 19090 19243 
f 18818 19081 19244 
f 18824 19088 19242 
f 18821 19091 19240 
f 18820 19092 19245 
f 18817 19082 19248 
f 18822 19085 19239 
f 18816 19086 19241 
f 18815 19083 19249 
f 18828 19093 19253 
s off
f 17862 19160 17181 
f 19124 17181 18506 
s 1
f 18830 19094 19260 
f 18832 19098 19268 
f 18842 19100 19257 
f 18838 19095 19255 
f 18837 19096 19267 
f 18833 19099 19263 
f 18835 19106 19261 
f 18840 19103 19264 
f 18843 19108 19254 
f 18829 19097 19266 
f 18831 19102 19262 
f 18836 19104 19258 
f 18834 19101 19265 
f 18839 19107 19256 
f 18841 19105 19259 
f 18522 18110 19016 
s 2
f 19416 18522 17358 
s 1
f 18523 18243 19018 
s 2
f 19423 18523 17272 
s 1
f 18524 18118 19023 
s 2
f 19418 18524 17364 
s 1
f 18525 18111 19019 
s 2
f 19424 18525 17388 
s 1
f 18526 18244 19017 
s 2
f 19427 18526 17275 
s 1
f 18527 18115 19020 
s 2
f 19417 18527 17355 
s 1
f 18528 18113 19028 
s 2
f 19426 18528 17394 
s 1
f 18529 18112 19027 
s 2
f 19430 18529 17391 
s 1
f 18530 18114 19026 
s 2
f 19419 18530 17352 
s 1
f 18531 18242 19021 
s 2
f 19428 18531 17269 
s 1
f 18532 18116 19029 
s 2
f 19421 18532 17361 
s 1
f 18533 18117 19022 
s 2
f 19425 18533 17397 
s 1
f 18534 18241 19024 
s 2
f 19429 18534 17278 
s 1
f 18535 18119 19025 
s 2
f 19420 18535 17367 
s 1
f 18536 18127 19031 
s 2
f 19422 18536 17385 
s off
f 17199 18537 17105 
f 18537 17199 18594 
s 1
f 18538 18246 19033 
s 2
f 19440 18538 17281 
s 1
f 18539 18126 19035 
s 2
f 19434 18539 17379 
s 1
f 18540 18245 19036 
s 2
f 19433 18540 17296 
s 1
f 18541 18124 19043 
s 2
f 19437 18541 17373 
s 1
f 18542 18247 19038 
s 2
f 19439 18542 17293 
s 1
f 18543 18248 19044 
s 2
f 19442 18543 17284 
s 1
f 18544 18251 19034 
s 2
f 19435 18544 17302 
s 1
f 18545 18249 19039 
s 2
f 19443 18545 17290 
s 1
f 18546 18123 19040 
s 2
f 19436 18546 17376 
s 1
f 18547 18122 19032 
s 2
f 19445 18547 17400 
s 1
f 18548 18121 19041 
s 2
f 19431 18548 17382 
s 1
f 18549 18120 19037 
s 2
f 19438 18549 17370 
s 1
f 18550 18252 19042 
s 2
f 19432 18550 17299 
s 1
f 18551 18125 19045 
s 2
f 19441 18551 17403 
s 1
f 18552 18250 19046 
s 2
f 19444 18552 17287 
s off
f 17899 18553 17218 
f 18553 17899 18847 
s 1
f 18554 17824 18426 
s 2
f 17765 17824 18554 
s off
f 18555 17582 17649 
f 17582 18555 19114 
s 2
f 17802 17835 18556 
s 1
f 18556 17835 18489 
s off
f 18557 19179 19221 
f 17117 18557 19121 
f 17171 18352 18558 
f 18852 18558 17110 
f 18559 17190 17842 
f 17190 18559 17100 
s 2
f 17917 17796 18560 
s 1
f 18560 17796 18576 
f 18561 17798 18577 
s 2
f 17918 17798 18561 
s off
f 17572 18562 17181 
f 19221 18562 19185 
s 1
f 17466 18090 18563 
s 4
f 18563 18090 19131 
f 18564 18091 19132 
s 1
f 17462 18091 18564 
s 4
f 18565 18086 19126 
s 1
f 17682 18086 18565 
s 4
f 18566 18087 19125 
s 1
f 17681 18087 18566 
s 4
f 18567 18095 19133 
s 1
f 17526 18095 18567 
f 17522 18094 18568 
s 4
f 18568 18094 19134 
s 1
f 17490 18084 18569 
s 4
f 18569 18084 19127 
f 18570 18085 19128 
s 1
f 17494 18085 18570 
f 17685 18098 18571 
s 4
f 18571 18098 19137 
f 18572 18099 19138 
s 1
f 17684 18099 18572 
s off
f 17908 18573 17581 
f 18573 17908 17098 
s 4
f 18574 18089 19130 
s 1
f 17894 18089 18574 
f 17893 18088 18575 
s 4
f 18575 18088 19129 
s 1
f 18576 17838 18560 
s 2
f 18627 17838 18576 
s 1
f 18577 17839 18561 
s 2
f 18628 17839 18577 
s 1
f 17531 18578 18774 
s 2
f 18578 17531 17719 
s 1
f 17506 18102 18579 
s 4
f 18579 18102 19142 
s 2
f 18580 17535 17720 
s 1
f 17535 18580 18776 
s 4
f 18581 18103 19143 
s 1
f 17510 18103 18581 
s 4
f 18582 18092 19135 
s 1
f 17533 18092 18582 
f 17537 18093 18583 
s 4
f 18583 18093 19136 
f 18584 18128 19146 
s 1
f 17898 18128 18584 
f 18585 17823 18692 
s 2
f 17525 17823 18585 
s 4
f 18586 18129 19147 
s 1
f 17897 18129 18586 
s 2
f 17675 18587 18368 
s 1
f 18587 17675 17806 
f 17680 18096 18588 
s 4
f 18588 18096 19139 
f 18589 18097 19140 
s 1
f 17679 18097 18589 
s off
f 18590 17906 18780 
f 18427 18590 17170 
f 18591 17591 18691 
f 17591 18591 17108 
f 17916 18592 17618 
f 18592 17916 19122 
s 1
f 18593 17674 17805 
s 2
f 17674 18593 18369 
s off
f 18594 17804 19117 
f 17804 18594 17199 
s 1
f 17553 18133 18595 
s 4
f 18595 18133 19149 
f 18596 18134 19151 
s 1
f 17549 18134 18596 
f 18597 17828 18759 
s 2
f 17521 17828 18597 
s 4
f 18598 18105 19144 
s 1
f 17545 18105 18598 
f 17541 18106 18599 
s 4
f 18599 18106 19145 
f 18600 18135 19154 
s 1
f 17556 18135 18600 
f 17560 18136 18601 
s 4
f 18601 18136 19155 
f 18602 18131 19152 
s 1
f 17601 18131 18602 
s 4
f 17701 17493 18603 
s 1
f 19479 18603 17493 
f 17678 18604 17489 
s 4
f 17716 17489 18604 
s 1
f 17607 18132 18605 
s 4
f 18605 18132 19153 
f 17699 18606 17481 
s 1
f 17482 17481 18606 
s 4
f 17700 18607 17487 
s 1
f 17486 17487 18607 
s off
f 18608 19114 18555 
f 19114 18608 19115 
f 17579 18609 17115 
f 18609 17579 17218 
s 4
f 17697 17499 18610 
s 1
f 17498 18610 17499 
s 4
f 17703 17501 18611 
s 1
f 17502 18611 17501 
s off
f 17208 18612 17133 
f 18612 17208 17089 
s 4
f 18613 18138 19158 
s 1
f 17566 18138 18613 
f 17570 18137 18614 
s 4
f 18614 18137 19157 
s 1
f 17815 18615 18781 
s 2
f 18615 17815 17533 
s 4
f 17763 18616 17507 
s 1
f 19343 17507 18616 
s 4
f 17764 18617 17509 
s 1
f 19342 17509 18617 
s 4
f 17823 17525 18618 
s 1
f 18386 18618 17525 
f 17813 18619 18782 
s 2
f 18619 17813 17537 
s 4
f 17828 17521 18620 
s 1
f 18387 18620 17521 
s 4
f 17850 18621 17467 
s 1
f 18008 17467 18621 
s 4
f 17851 18622 17461 
s 1
f 18007 17461 18622 
s 4
f 17736 18623 17515 
s 1
f 17514 17515 18623 
s 4
f 17740 18624 17517 
s 1
f 17518 17517 18624 
s 4
f 17846 17420 18625 
s 1
f 17954 18625 17420 
s 4
f 17849 17423 18626 
s 1
f 17953 18626 17423 
s 4
f 17838 18627 17532 
s 1
f 18615 17532 18627 
s 4
f 17839 18628 17536 
s 1
f 18619 17536 18628 
f 18288 18264 18629 
f 18288 18629 18891 
f 18291 18267 18630 
f 18291 18630 18884 
f 18300 18258 18631 
f 18300 18631 18892 
f 18290 18261 18632 
f 18290 18632 18887 
f 18292 18270 18633 
f 18292 18633 18888 
f 18289 18262 18634 
f 18289 18634 18879 
f 18295 18272 18635 
f 18295 18635 18885 
f 18299 18259 18636 
f 18299 18636 18880 
f 18294 18269 18637 
f 18294 18637 18893 
f 18297 18260 18638 
f 18297 18638 18883 
f 18296 18265 18639 
f 18296 18639 18881 
f 18298 18271 18640 
f 18298 18640 18890 
f 18301 18641 18882 
f 18301 18268 18641 
f 18302 18263 18642 
f 18302 18642 18889 
f 18293 18266 18643 
f 18293 18643 18886 
f 18262 18289 18644 
f 18262 18644 18869 
f 18266 18645 18867 
f 18266 18293 18645 
f 18269 18646 18878 
f 18269 18294 18646 
f 18260 18297 18647 
f 18260 18647 18866 
f 18263 18648 18864 
f 18263 18302 18648 
f 18258 18300 18649 
f 18258 18649 18873 
f 18261 18290 18650 
f 18261 18650 18871 
f 18259 18651 18870 
f 18259 18299 18651 
f 18265 18296 18652 
f 18265 18652 18875 
f 18271 18653 18876 
f 18271 18298 18653 
f 18264 18288 18654 
f 18264 18654 18872 
f 18267 18655 18877 
f 18267 18291 18655 
f 18270 18292 18656 
f 18270 18656 18865 
f 18272 18295 18657 
f 18272 18657 18874 
f 18268 18301 18658 
f 18268 18658 18868 
s 4
f 17801 17544 18659 
s 1
f 19166 18659 17544 
f 18315 18273 18660 
f 18315 18660 18922 
f 18308 18286 18661 
f 18308 18661 18912 
f 18311 18285 18662 
f 18311 18662 18916 
f 18306 18282 18663 
f 18306 18663 18910 
f 18314 18281 18664 
f 18314 18664 18914 
f 18309 18283 18665 
f 18309 18665 18915 
f 18305 18284 18666 
f 18305 18666 18917 
f 18304 18278 18667 
f 18304 18667 18913 
f 18316 18274 18668 
f 18316 18668 18920 
f 18303 18280 18669 
f 18303 18669 18911 
f 18313 18279 18670 
f 18313 18670 18918 
f 18312 18276 18671 
f 18312 18671 18921 
f 18307 18277 18672 
f 18307 18672 18909 
f 18310 18275 18673 
f 18310 18673 18919 
f 18317 18287 18674 
f 18317 18674 18937 
f 18278 18675 18896 
f 18278 18304 18675 
f 18279 18313 18676 
f 18279 18676 18903 
f 18276 18677 18904 
f 18276 18312 18677 
f 18280 18303 18678 
f 18280 18678 18898 
f 18277 18307 18679 
f 18277 18679 18895 
f 18274 18316 18680 
f 18274 18680 18902 
f 18281 18314 18681 
f 18281 18681 18899 
f 18285 18311 18682 
f 18285 18682 18900 
f 18275 18310 18683 
f 18275 18683 18897 
f 18283 18309 18684 
f 18283 18684 18906 
f 18282 18306 18685 
f 18282 18685 18907 
f 18284 18305 18686 
f 18284 18686 18905 
f 18273 18315 18687 
f 18273 18687 18894 
f 18286 18688 18901 
f 18286 18308 18688 
s 4
f 17817 17542 18689 
s 1
f 19176 18689 17542 
f 18287 18317 18690 
f 18287 18690 18908 
s off
f 17791 18691 19160 
f 18691 17791 18591 
s 2
f 17822 17843 18692 
s 1
f 18692 17843 18585 
s 4
f 17766 17554 18693 
s 1
f 18855 18693 17554 
s 4
f 17803 17548 18694 
s 1
f 18860 18694 17548 
f 18355 18327 18695 
f 18355 18695 18976 
f 18354 18333 18696 
f 18354 18696 18973 
f 18353 18321 18697 
f 18353 18697 18979 
f 18357 18329 18698 
f 18357 18698 18980 
f 18360 18326 18699 
f 18360 18699 18983 
f 18356 18331 18700 
f 18356 18700 18972 
f 18362 18332 18701 
f 18362 18701 18982 
f 18359 18328 18702 
f 18359 18702 18975 
f 18366 18325 18703 
f 18366 18703 18984 
f 18364 18330 18704 
f 18364 18704 18985 
f 18361 18322 18705 
f 18361 18705 18974 
f 18358 18320 18706 
f 18358 18706 18981 
f 18363 18324 18707 
f 18363 18707 18978 
f 18365 18334 18708 
f 18365 18708 18977 
f 18367 18323 18709 
f 18367 18709 18986 
f 18330 18364 18710 
f 18330 18710 18968 
f 18331 18356 18711 
f 18331 18711 18971 
f 18322 18361 18712 
f 18322 18712 18963 
f 18321 18353 18713 
f 18321 18713 18958 
f 18327 18355 18714 
f 18327 18714 18969 
f 18329 18715 18964 
f 18329 18357 18715 
f 18326 18360 18716 
f 18326 18716 18970 
f 18328 18717 18959 
f 18328 18359 18717 
f 18320 18358 18718 
f 18320 18718 18960 
f 18332 18362 18719 
f 18332 18719 18967 
f 18323 18367 18720 
f 18323 18720 18962 
f 18324 18363 18721 
f 18324 18721 18957 
f 18325 18722 18965 
f 18325 18366 18722 
f 18334 18365 18723 
f 18334 18723 18966 
f 18333 18354 18724 
f 18333 18724 18961 
s 4
f 18402 18725 17559 
s 1
f 17747 17559 18725 
f 17749 17561 18726 
s 4
f 18404 18726 17561 
s 1
f 18381 18337 18727 
f 18381 18727 19015 
f 18378 18345 18728 
f 18378 18728 19004 
f 18370 18729 19005 
f 18370 18338 18729 
f 18376 18343 18730 
f 18376 18730 19006 
f 18373 18349 18731 
f 18373 18731 19008 
f 18375 18347 18732 
f 18375 18732 19009 
f 18371 18342 18733 
f 18371 18733 19003 
f 18374 18336 18734 
f 18374 18734 19010 
f 18379 18340 18735 
f 18379 18735 19012 
f 18372 18346 18736 
f 18372 18736 19002 
f 18377 18339 18737 
f 18377 18737 19007 
f 18380 18341 18738 
f 18380 18738 19013 
f 18383 18344 18739 
f 18383 18739 19014 
f 18382 18348 18740 
f 18382 18740 19011 
f 18384 18350 18741 
f 18384 18741 19030 
s 4
f 17888 18742 17600 
s 1
f 17963 17600 18742 
s 4
f 17892 18743 17606 
s 1
f 17973 17606 18743 
f 18336 18374 18744 
f 18336 18744 18988 
f 18340 18379 18745 
f 18340 18745 18996 
f 18341 18746 18997 
f 18341 18380 18746 
f 18339 18377 18747 
f 18339 18747 18992 
f 18338 18370 18748 
f 18338 18748 18990 
f 18349 18749 18989 
f 18349 18373 18749 
f 18346 18750 18995 
f 18346 18372 18750 
f 18343 18751 18999 
f 18343 18376 18751 
f 18342 18371 18752 
f 18342 18752 18993 
f 18337 18381 18753 
f 18337 18753 18991 
f 18345 18754 19000 
f 18345 18378 18754 
f 18348 18382 18755 
f 18348 18755 18987 
f 18347 18375 18756 
f 18347 18756 18994 
f 18344 18757 18998 
f 18344 18383 18757 
f 18350 18384 18758 
f 18350 18758 19001 
f 18759 17844 18597 
s 2
f 17827 17844 18759 
s off
f 18760 19122 19121 
f 19188 18760 19121 
s 4
f 17831 18761 17565 
s 1
f 19148 17565 18761 
s 4
f 17834 18762 17569 
s 1
f 19141 17569 18762 
s 2
f 17996 17830 18763 
s 1
f 18763 17830 18768 
s 4
f 17941 17614 18764 
s 1
f 17991 18764 17614 
f 17992 18765 17616 
s 4
f 17929 17616 18765 
s off
f 18766 17625 19110 
f 17625 18766 19111 
s 2
f 17998 17833 18767 
s 1
f 18767 17833 18769 
f 18768 17850 18763 
s 2
f 18621 17850 18768 
f 18622 17851 18769 
s 1
f 18769 17851 18767 
s 8
f 18109 18770 17158 
f 17191 17158 18770 
s off
f 18771 17818 17555 
f 17818 18771 19180 
s 8
f 17192 17159 18772 
f 18130 18772 17159 
s 2
f 17420 17846 18773 
s 1
f 18773 17846 18846 
f 18774 17533 17531 
s 2
f 17533 18774 18615 
s 1
f 18775 17849 18848 
s 2
f 17423 17849 18775 
f 17537 18776 18619 
s 1
f 18776 17537 17535 
s off
f 18777 17207 17202 
f 17207 18777 18861 
f 17202 18778 19167 
f 18778 17202 17207 
f 19163 19156 19454 
f 17847 18779 18414 
f 18780 17170 18590 
f 17170 18780 19163 
s 2
f 17719 18781 18578 
s 1
f 18781 17719 17815 
f 18782 17720 17813 
s 2
f 17720 18782 18580 
s off
f 18783 18490 17581 
f 18490 18783 19119 
s 1
f 19056 18784 18436 
s 2
f 17921 18436 18784 
f 17922 18442 18785 
s 1
f 19059 18785 18442 
f 19049 18786 18438 
s 2
f 17924 18438 18786 
s 1
f 19051 18787 18435 
s 2
f 18010 18435 18787 
s 1
f 19058 18788 18439 
s 2
f 18013 18439 18788 
s 1
f 19052 18789 18433 
s 2
f 18009 18433 18789 
s 1
f 19055 18790 18434 
s 2
f 17920 18434 18790 
s 1
f 19050 18791 18431 
s 2
f 17927 18431 18791 
f 18012 18432 18792 
s 1
f 19060 18792 18432 
f 19053 18793 18441 
s 2
f 17923 18441 18793 
f 17925 18429 18794 
s 1
f 19048 18794 18429 
f 19054 18795 18440 
s 2
f 18011 18440 18795 
s 1
f 19057 18796 18437 
s 2
f 18014 18437 18796 
s 1
f 19061 18797 18430 
s 2
f 17926 18430 18797 
s 1
f 19062 18798 18443 
s 2
f 17928 18443 18798 
s 1
f 19071 18799 18458 
s 2
f 17934 18458 18799 
s 1
f 19064 18800 18453 
s 2
f 17932 18453 18800 
s 1
f 19072 18801 18448 
s 2
f 17935 18448 18801 
s 1
f 19074 18802 18456 
s 2
f 18015 18456 18802 
s 1
f 19073 18803 18451 
s 2
f 18017 18451 18803 
f 17939 18445 18804 
s 1
f 19068 18804 18445 
s 2
f 18020 18449 18805 
s 1
f 19076 18805 18449 
f 19067 18806 18454 
s 2
f 17938 18454 18806 
s 1
f 19066 18807 18455 
s 2
f 18016 18455 18807 
s 1
f 19069 18808 18446 
s 2
f 18018 18446 18808 
s 1
f 19070 18809 18447 
s 2
f 17937 18447 18809 
s 1
f 19075 18810 18457 
s 2
f 17933 18457 18810 
s 1
f 19063 18811 18450 
s 2
f 18021 18450 18811 
s 1
f 19065 18812 18444 
s 2
f 17936 18444 18812 
f 18019 18452 18813 
s 1
f 19077 18813 18452 
f 19080 18814 18495 
s 2
f 17971 18495 18814 
f 17966 18504 18815 
s 1
f 19083 18815 18504 
f 19086 18816 18503 
s 2
f 18040 18503 18816 
s 1
f 19082 18817 18501 
s 2
f 18039 18501 18817 
s 1
f 19081 18818 18497 
s 2
f 17965 18497 18818 
s 1
f 19079 18819 18491 
s 2
f 17968 18491 18819 
s 1
f 19092 18820 18500 
s 2
f 17969 18500 18820 
s 1
f 19091 18821 18499 
s 2
f 17970 18499 18821 
s 1
f 19085 18822 18502 
s 2
f 18037 18502 18822 
f 18038 18492 18823 
s 1
f 19084 18823 18492 
f 19088 18824 18498 
s 2
f 17964 18498 18824 
f 18042 18494 18825 
s 1
f 19087 18825 18494 
f 19089 18826 18493 
s 2
f 18041 18493 18826 
s 1
f 19090 18827 18496 
s 2
f 17967 18496 18827 
s 1
f 19093 18828 18505 
s 2
f 18043 18505 18828 
s 1
f 19097 18829 18516 
s 2
f 18044 18516 18829 
f 18046 18507 18830 
s 1
f 19094 18830 18507 
s 2
f 17979 18517 18831 
s 1
f 19102 18831 18517 
f 19098 18832 18508 
s 2
f 18048 18508 18832 
s 1
f 19099 18833 18512 
s 2
f 17980 18512 18833 
s 1
f 19101 18834 18519 
s 2
f 17976 18519 18834 
f 17977 18513 18835 
s 1
f 19106 18835 18513 
f 19104 18836 18518 
s 2
f 17978 18518 18836 
s 1
f 19096 18837 18511 
s 2
f 18050 18511 18837 
s 1
f 19095 18838 18510 
s 2
f 18049 18510 18838 
s 1
f 19107 18839 18520 
s 2
f 18045 18520 18839 
s 1
f 19103 18840 18514 
s 2
f 18047 18514 18840 
s 1
f 19105 18841 18521 
s 2
f 17982 18521 18841 
s 1
f 19100 18842 18509 
s 2
f 17981 18509 18842 
s 1
f 19108 18843 18515 
s 2
f 18051 18515 18843 
s 8
f 17168 18844 17630 
f 17187 17630 18844 
f 17188 17631 18845 
f 17169 18845 17631 
s 1
f 18846 17863 18773 
s 2
f 17845 17863 18846 
s off
f 19190 18847 19110 
f 18847 19190 18557 
s 2
f 17848 17873 18848 
s 1
f 18848 17873 18775 
s 2
f 18849 17551 17737 
s 1
f 17551 18849 18954 
s 2
f 17854 17855 18850 
s 1
f 18850 17855 18856 
f 18851 17853 18857 
s 2
f 17852 17853 18851 
s off
f 18558 18852 17171 
f 17624 17171 18852 
f 18853 18335 17157 
f 18335 18853 17580 
s 1
f 17547 18854 19047 
s 2
f 18854 17547 17731 
s 1
f 17789 18855 19116 
s 2
f 18855 17789 17553 
s 1
f 18856 17888 18850 
s 2
f 18742 17888 18856 
s 1
f 18857 17892 18851 
s 2
f 18743 17892 18857 
s 1
f 18858 17929 18955 
s 2
f 17616 17929 18858 
f 17614 17941 18859 
s 1
f 18859 17941 18956 
s 2
f 18860 17767 17549 
s 1
f 17767 18860 19123 
s off
f 17189 18861 17093 
f 18861 17189 17207 
f 17199 18862 17804 
f 18862 17199 17106 
f 18863 17625 19150 
f 17625 18863 19159 
s 1
f 18864 17418 18263 
f 18865 17318 18270 
f 18866 17342 18260 
f 18867 17407 18266 
f 18868 17410 18268 
f 18869 17417 18262 
f 18870 17321 18259 
f 18871 17406 18261 
f 18872 17414 18264 
f 18873 17339 18258 
f 18874 17416 18272 
f 18875 17409 18265 
f 18876 17408 18271 
f 18877 17345 18267 
f 18878 17415 18269 
f 18879 17235 18289 
f 18880 17323 18299 
f 18881 17247 18296 
f 18882 17250 18301 
f 18883 17344 18297 
f 18884 17347 18291 
f 18885 17238 18295 
f 18886 17253 18293 
f 18887 17259 18290 
f 18888 17320 18292 
f 18889 17241 18302 
f 18890 17256 18298 
f 18891 17229 18288 
f 18892 17341 18300 
f 18893 17232 18294 
f 18894 17303 18273 
f 18895 17336 18277 
f 18896 17411 18278 
f 18897 17413 18275 
f 18898 17312 18280 
f 18899 17327 18281 
f 18900 17405 18285 
f 18901 17309 18286 
f 18902 17324 18274 
f 18903 17333 18279 
f 18904 17306 18276 
f 18905 17412 18284 
f 18906 17330 18283 
f 18907 17315 18282 
f 18908 17348 18287 
f 18909 17338 18307 
f 18910 17317 18306 
f 18911 17314 18303 
f 18912 17311 18308 
f 18913 17223 18304 
f 18914 17329 18314 
f 18915 17332 18309 
f 18916 17244 18311 
f 18917 17220 18305 
f 18918 17335 18313 
f 18919 17226 18310 
f 18920 17326 18316 
f 18921 17308 18312 
f 18922 17305 18315 
f 18923 17316 18461 
f 18924 17334 18466 
f 18925 17224 18459 
f 18926 17337 18469 
f 18927 17245 18460 
f 18928 17307 18462 
f 18929 17313 18465 
f 18930 17221 18467 
f 18931 17331 18471 
f 18932 17310 18463 
f 18933 17325 18470 
f 18934 17304 18464 
f 18935 17227 18468 
f 18936 17328 18472 
f 18937 17350 18317 
f 18938 17349 18473 
f 18939 17239 18474 
f 18940 17322 18482 
f 18941 17251 18478 
f 18942 17242 18486 
f 18943 17319 18475 
f 18944 17230 18476 
f 18945 17343 18477 
f 18946 17340 18480 
f 18947 17236 18484 
f 18948 17346 18483 
f 18949 17248 18479 
f 18950 17257 18481 
f 18951 17260 18488 
f 18952 17233 18487 
f 18953 17254 18485 
s 2
f 17553 18954 18855 
s 1
f 18954 17553 17551 
f 18955 17875 18858 
s 2
f 17874 17875 18955 
f 17876 17877 18956 
s 1
f 18956 17877 18859 
f 18957 17372 18324 
f 18958 17426 18321 
f 18959 17399 18328 
f 18960 17432 18320 
f 18961 17427 18333 
f 18962 17381 18323 
f 18963 17435 18322 
f 18964 17375 18329 
f 18965 17369 18325 
f 18966 17402 18334 
f 18967 17434 18332 
f 18968 17425 18330 
f 18969 17378 18327 
f 18970 17436 18326 
f 18971 17433 18331 
f 18972 17280 18356 
f 18973 17298 18354 
f 18974 17283 18361 
f 18975 17401 18359 
f 18976 17380 18355 
f 18977 17404 18365 
f 18978 17374 18363 
f 18979 17295 18353 
f 18980 17377 18357 
f 18981 17286 18358 
f 18982 17289 18362 
f 18983 17292 18360 
f 18984 17371 18366 
f 18985 17301 18364 
f 18986 17383 18367 
f 18987 17429 18348 
f 18988 17360 18336 
f 18989 17431 18349 
f 18990 17354 18338 
f 18991 17428 18337 
f 18992 17363 18339 
f 18993 17393 18342 
f 18994 17366 18347 
f 18995 17357 18346 
f 18996 17351 18340 
f 18997 17387 18341 
f 18998 17430 18344 
f 18999 17396 18343 
f 19000 17390 18345 
f 19001 17384 18350 
f 19002 17359 18372 
f 19003 17395 18371 
f 19004 17392 18378 
f 19005 17356 18370 
f 19006 17398 18376 
f 19007 17365 18377 
f 19008 17271 18373 
f 19009 17368 18375 
f 19010 17362 18374 
f 19011 17274 18382 
f 19012 17353 18379 
f 19013 17389 18380 
f 19014 17268 18383 
f 19015 17277 18381 
f 19016 17358 18522 
f 19017 17275 18526 
f 19018 17272 18523 
f 19019 17388 18525 
f 19020 17355 18527 
f 19021 17269 18531 
f 19022 17397 18533 
f 19023 17364 18524 
f 19024 17278 18534 
f 19025 17367 18535 
f 19026 17352 18530 
f 19027 17391 18529 
f 19028 17394 18528 
f 19029 17361 18532 
f 19030 17386 18384 
f 19031 17385 18536 
f 19032 17400 18547 
f 19033 17281 18538 
f 19034 17302 18544 
f 19035 17379 18539 
f 19036 17296 18540 
f 19037 17370 18549 
f 19038 17293 18542 
f 19039 17290 18545 
f 19040 17376 18546 
f 19041 17382 18548 
f 19042 17299 18550 
f 19043 17373 18541 
f 19044 17284 18543 
f 19045 17403 18551 
f 19046 17287 18552 
f 19047 17549 17547 
s 2
f 17549 19047 18860 
f 19348 19048 17438 
s 1
f 18429 17438 19048 
f 18438 17441 19049 
s 2
f 19354 19049 17441 
f 19290 19050 17225 
s 1
f 18431 17225 19050 
f 18435 17450 19051 
s 2
f 19350 19051 17450 
f 19355 19052 17449 
s 1
f 18433 17449 19052 
s 2
f 19351 19053 17440 
s 1
f 18441 17440 19053 
s 2
f 19352 19054 17448 
s 1
f 18440 17448 19054 
s 2
f 19293 19055 17222 
s 1
f 18434 17222 19055 
s 2
f 19291 19056 17219 
s 1
f 18436 17219 19056 
s 2
f 19292 19057 17243 
s 1
f 18437 17243 19057 
s 2
f 19349 19058 17447 
s 1
f 18439 17447 19058 
s 2
f 19353 19059 17437 
s 1
f 18442 17437 19059 
f 18432 17446 19060 
s 2
f 19356 19060 17446 
f 19357 19061 17439 
s 1
f 18430 17439 19061 
s 2
f 19358 19062 17445 
s 1
f 18443 17445 19062 
s 2
f 19361 19063 17451 
s 1
f 18450 17451 19063 
s 2
f 19304 19064 17231 
s 1
f 18453 17231 19064 
s 2
f 19308 19065 17237 
s 1
f 18444 17237 19065 
s 2
f 19309 19066 17246 
s 1
f 18455 17246 19066 
s 2
f 19365 19067 17442 
s 1
f 18454 17442 19067 
f 18445 17234 19068 
s 2
f 19312 19068 17234 
f 19307 19069 17255 
s 1
f 18446 17255 19069 
s 2
f 19362 19070 17443 
s 1
f 18447 17443 19070 
s 2
f 19306 19071 17240 
s 1
f 18458 17240 19071 
s 2
f 19364 19072 17444 
s 1
f 18448 17444 19072 
s 2
f 19313 19073 17258 
s 1
f 18451 17258 19073 
s 2
f 19311 19074 17252 
s 1
f 18456 17252 19074 
s 2
f 19310 19075 17228 
s 1
f 18457 17228 19075 
f 18449 17249 19076 
s 2
f 19305 19076 17249 
s 1
f 18452 17452 19077 
s 2
f 19363 19077 17452 
s off
f 17195 19078 19189 
f 19078 17195 17842 
s 2
f 19400 19079 17457 
s 1
f 18491 17457 19079 
s 2
f 19318 19080 17267 
s 1
f 18495 17267 19080 
s 2
f 19398 19081 17455 
s 1
f 18497 17455 19081 
s 2
f 19396 19082 17468 
s 1
f 18501 17468 19082 
f 18504 17276 19083 
s 2
f 19319 19083 17276 
s 1
f 18492 17471 19084 
s 2
f 19401 19084 17471 
f 19405 19085 17469 
s 1
f 18502 17469 19085 
f 18503 17470 19086 
s 2
f 19397 19086 17470 
s 1
f 18494 17473 19087 
s 2
f 19402 19087 17473 
f 19404 19088 17456 
s 1
f 18498 17456 19088 
s 2
f 19403 19089 17472 
s 1
f 18493 17472 19089 
s 2
f 19321 19090 17270 
s 1
f 18496 17270 19090 
s 2
f 19320 19091 17273 
s 1
f 18499 17273 19091 
s 2
f 19399 19092 17454 
s 1
f 18500 17454 19092 
f 18505 17479 19093 
s 2
f 19406 19093 17479 
s 1
f 18507 17297 19094 
s 2
f 19334 19094 17297 
f 19408 19095 17474 
s 1
f 18510 17474 19095 
f 18511 17475 19096 
s 2
f 19414 19096 17475 
s 1
f 18516 17294 19097 
s 2
f 19330 19097 17294 
f 19410 19098 17477 
s 1
f 18508 17477 19098 
s 2
f 19333 19099 17288 
s 1
f 18512 17288 19099 
s 2
f 19409 19100 17458 
s 1
f 18509 17458 19100 
s 2
f 19331 19101 17279 
s 1
f 18519 17279 19101 
s 2
f 19332 19102 17291 
s 1
f 18517 17291 19102 
s 2
f 19413 19103 17478 
s 1
f 18514 17478 19103 
s 2
f 19335 19104 17282 
s 1
f 18518 17282 19104 
s 2
f 19411 19105 17459 
s 1
f 18521 17459 19105 
s 2
f 19336 19106 17285 
s 1
f 18513 17285 19106 
s 2
f 19412 19107 17476 
s 1
f 18520 17476 19107 
f 18515 17300 19108 
s 2
f 19337 19108 17300 
s off
f 17618 19109 17916 
f 19109 17618 17091 
f 19110 17899 18766 
f 17899 19110 18847 
f 19111 17579 17086 
f 17579 19111 18766 
f 19112 17649 17582 
f 17649 19112 17103 
f 19113 17847 19124 
f 17847 19113 17171 
f 18164 19114 19115 
f 19114 18164 17582 
f 19115 18139 18164 
f 18139 19115 19175 
s 2
f 17737 19116 18849 
s 1
f 19116 17737 17789 
s off
f 19117 17862 17181 
f 17862 19117 17804 
s 2
f 19118 17790 17568 
s 1
f 17790 19118 19161 
s off
f 19119 18188 19175 
f 18188 19119 18783 
s 1
f 17792 19120 19162 
s 2
f 19120 17792 17564 
s off
f 19121 18351 17117 
f 18351 19121 19122 
f 19122 18425 18351 
f 18425 19122 17916 
s 1
f 19123 17731 17767 
s 2
f 17731 19123 18854 
s off
f 19124 18414 17572 
f 17181 19124 17572 
s 1
f 17690 17485 19125 
s 4
f 19125 17485 18566 
f 19126 17483 18565 
s 1
f 17691 17483 19126 
s 4
f 19127 17491 18569 
s 1
f 17693 17491 19127 
s 4
f 19128 17495 18570 
s 1
f 17698 17495 19128 
f 17612 17576 19129 
s 4
f 19129 17576 18575 
f 19130 17574 18574 
s 1
f 17611 17574 19130 
s 4
f 19131 17465 18563 
s 1
f 17629 17465 19131 
s 4
f 19132 17463 18564 
s 1
f 17628 17463 19132 
f 17675 17527 19133 
s 4
f 19133 17527 18567 
f 19134 17523 18568 
s 1
f 17674 17523 19134 
f 17719 17534 19135 
s 4
f 19135 17534 18582 
f 19136 17538 18583 
s 1
f 17720 17538 19136 
s 4
f 19137 17497 18571 
s 1
f 17696 17497 19137 
s 4
f 19138 17503 18572 
s 1
f 17692 17503 19138 
s 4
f 19139 17513 18588 
s 1
f 17687 17513 19139 
s 4
f 19140 17519 18589 
s 1
f 17686 17519 19140 
f 17793 19141 19164 
s 2
f 19141 17793 17570 
s 4
f 19142 17505 18579 
s 1
f 17704 17505 19142 
s 4
f 19143 17511 18581 
s 1
f 17705 17511 19143 
s 4
f 19144 17546 18598 
s 1
f 17713 17546 19144 
f 17706 17540 19145 
s 4
f 19145 17540 18599 
s 1
f 17911 17586 19146 
s 4
f 19146 17586 18584 
f 19147 17590 18586 
s 1
f 17910 17590 19147 
f 17794 19148 19165 
s 2
f 19148 17794 17566 
s 4
f 19149 17552 18595 
s 1
f 17737 17552 19149 
s off
f 19150 17208 18863 
f 17208 19150 17088 
s 4
f 19151 17550 18596 
s 1
f 17731 17550 19151 
s 4
f 19152 17604 18602 
s 1
f 17603 17604 19152 
s 4
f 19153 17610 18605 
s 1
f 17609 17610 19153 
f 17558 17557 19154 
s 4
f 19154 17557 18600 
f 19155 17563 18601 
s 1
f 17562 17563 19155 
s off
f 18553 19156 18428 
f 19156 18553 19454 
s 4
f 19157 17571 18614 
s 1
f 17568 17571 19157 
s 4
f 19158 17567 18613 
s 1
f 17564 17567 19158 
s off
f 19159 19188 17453 
f 19110 19159 19190 
f 18506 19160 18691 
f 19160 18506 17181 
s 1
f 19161 17570 17790 
s 2
f 17570 19161 19141 
f 17566 19162 19148 
s 1
f 19162 17566 17792 
s off
f 18779 19163 18414 
f 19163 18779 17170 
s 2
f 17568 19164 19118 
s 1
f 19164 17568 17793 
f 19165 17564 17794 
s 2
f 17564 19165 19120 
s 1
f 17712 19166 19269 
s 2
f 19166 17712 17545 
s off
f 19167 18415 17202 
f 18415 19167 19179 
f 17181 19168 19117 
f 19168 17181 18562 
s 2
f 17545 19169 19166 
s 1
f 19169 17545 17543 
f 19170 17541 17539 
s 2
f 17541 19170 19176 
s 8
f 19181 19171 17167 
f 17193 17167 19171 
f 19171 19172 17193 
f 17641 17193 19172 
f 19174 19173 17194 
f 17642 17194 19173 
f 19184 19174 17166 
f 17194 17166 19174 
s off
f 19221 19175 18562 
f 18562 19175 19115 
s 1
f 17702 19176 19289 
s 2
f 19176 17702 17541 
f 19177 17539 17706 
s 1
f 17539 19177 19170 
s 2
f 19178 17543 17713 
s 1
f 17543 19178 19169 
s off
f 19179 17117 18351 
f 18557 17117 19179 
f 19180 18490 19119 
f 18490 19180 18771 
s 8
f 17646 19181 17620 
f 17167 17620 19181 
f 17577 17642 19182 
f 19173 19182 17642 
f 19172 19183 17641 
f 17578 17641 19183 
f 17645 19184 17621 
f 17166 17621 19184 
s off
f 18562 17572 19185 
f 19185 17572 18414 
f 17190 19186 18413 
f 19186 17190 17101 
f 19187 17582 18164 
f 17582 19187 19112 
f 18760 19188 17133 
f 18863 17133 19188 
f 19189 18188 17195 
f 18188 19189 19175 
f 19190 17453 18557 
f 17453 19190 19159 
s 1
f 19191 18066 18461 
s 2
f 19228 18066 19191 
s 1
f 19192 18074 18471 
s 2
f 19234 18074 19192 
s 1
f 19193 18069 18469 
s 2
f 19229 18069 19193 
f 19232 18068 19194 
s 1
f 19194 18068 18472 
f 19195 18065 18466 
s 2
f 19230 18065 19195 
s 1
f 19196 18191 18460 
s 2
f 19317 18191 19196 
f 19314 18192 19197 
s 1
f 19197 18192 18459 
f 19198 18189 18468 
s 2
f 19316 18189 19198 
f 19235 18067 19199 
s 1
f 19199 18067 18463 
f 19200 18071 18464 
s 2
f 19237 18071 19200 
f 19231 18070 19201 
s 1
f 19201 18070 18462 
f 19202 18073 18465 
s 2
f 19233 18073 19202 
s 1
f 19203 18072 18470 
s 2
f 19236 18072 19203 
f 19315 18190 19204 
s 1
f 19204 18190 18467 
s 2
f 19238 18080 19205 
s 1
f 19205 18080 18473 
f 19206 18075 18475 
s 2
f 19223 18075 19206 
f 19303 18193 19207 
s 1
f 19207 18193 18476 
s 2
f 19300 18201 19208 
s 1
f 19208 18201 18488 
f 19209 18194 18485 
s 2
f 19295 18194 19209 
s 1
f 19210 18198 18478 
s 2
f 19302 18198 19210 
s 1
f 19211 18077 18477 
s 2
f 19226 18077 19211 
f 19299 18202 19212 
s 1
f 19212 18202 18474 
s 2
f 19294 18195 19213 
s 1
f 19213 18195 18481 
s 2
f 19298 18200 19214 
s 1
f 19214 18200 18486 
f 19215 18078 18480 
s 2
f 19227 18078 19215 
s 1
f 19216 18197 18479 
s 2
f 19297 18197 19216 
s 1
f 19217 18079 18483 
s 2
f 19224 18079 19217 
s 1
f 19218 18076 18482 
s 2
f 19225 18076 19218 
f 19301 18199 19219 
s 1
f 19219 18199 18487 
f 19220 18196 18484 
s 2
f 19296 18196 19220 
s off
f 19179 18104 19221 
f 19221 18104 17580 
s 8
f 18770 19222 17191 
f 17645 17191 19222 
s 2
f 18075 19223 17714 
f 17318 17714 19223 
f 17345 17787 19224 
f 18079 19224 17787 
f 17321 17715 19225 
f 18076 19225 17715 
f 17342 17788 19226 
f 18077 19226 17788 
f 18078 19227 17786 
f 17339 17786 19227 
f 17315 17707 19228 
f 18066 19228 17707 
f 17336 17777 19229 
f 18069 19229 17777 
f 18065 19230 17776 
f 17333 17776 19230 
f 17306 17709 19231 
f 18070 19231 17709 
f 17327 17778 19232 
f 18068 19232 17778 
f 18073 19233 17708 
f 17312 17708 19233 
f 18074 19234 17779 
f 17330 17779 19234 
f 17309 17710 19235 
f 18067 19235 17710 
f 18072 19236 17780 
f 17324 17780 19236 
f 18071 19237 17711 
f 17303 17711 19237 
f 17348 17781 19238 
f 18080 19238 17781 
f 19279 18110 19239 
s 1
f 19239 18110 18522 
f 19240 18244 18526 
s 2
f 19340 18244 19240 
f 19286 18119 19241 
s 1
f 19241 18119 18535 
s 2
f 19287 18112 19242 
s 1
f 19242 18112 18529 
s 2
f 19338 18243 19243 
s 1
f 19243 18243 18523 
f 19244 18113 18528 
s 2
f 19283 18113 19244 
f 19282 18117 19245 
s 1
f 19245 18117 18533 
s 2
f 19280 18116 19246 
s 1
f 19246 18116 18532 
s 2
f 19341 18242 19247 
s 1
f 19247 18242 18531 
f 19248 18118 18524 
s 2
f 19281 18118 19248 
s 1
f 19249 18241 18534 
s 2
f 19339 18241 19249 
s 1
f 19250 18115 18527 
s 2
f 19285 18115 19250 
s 1
f 19251 18114 18530 
s 2
f 19278 18114 19251 
f 19284 18111 19252 
s 1
f 19252 18111 18525 
s 2
f 19288 18127 19253 
s 1
f 19253 18127 18536 
f 19254 18251 18544 
s 2
f 19323 18251 19254 
f 19271 18120 19255 
s 1
f 19255 18120 18549 
f 19256 18124 18541 
s 2
f 19272 18124 19256 
f 19273 18125 19257 
s 1
f 19257 18125 18551 
f 19258 18248 18543 
s 2
f 19324 18248 19258 
f 19275 18122 19259 
s 1
f 19259 18122 18547 
f 19260 18252 18550 
s 2
f 19327 18252 19260 
s 1
f 19261 18250 18552 
s 2
f 19325 18250 19261 
s 1
f 19262 18247 18542 
s 2
f 19322 18247 19262 
f 19328 18249 19263 
s 1
f 19263 18249 18545 
f 19264 18126 18539 
s 2
f 19274 18126 19264 
f 19326 18246 19265 
s 1
f 19265 18246 18538 
s 2
f 19329 18245 19266 
s 1
f 19266 18245 18540 
f 19267 18121 18548 
s 2
f 19276 18121 19267 
f 19277 18123 19268 
s 1
f 19268 18123 18546 
f 19269 17713 17712 
s 2
f 17713 19269 19178 
s 8
f 18772 19270 17192 
f 17646 17192 19270 
s 2
f 17369 17759 19271 
f 18120 19271 17759 
f 17372 17760 19272 
f 18124 19272 17760 
f 17402 17812 19273 
f 18125 19273 17812 
f 17378 17758 19274 
f 18126 19274 17758 
f 17399 17811 19275 
f 18122 19275 17811 
f 18121 19276 17761 
f 17381 17761 19276 
f 17375 17762 19277 
f 18123 19277 17762 
f 18114 19278 17755 
f 17351 17755 19278 
f 17357 17750 19279 
f 18110 19279 17750 
f 17360 17753 19280 
f 18116 19280 17753 
f 17363 17754 19281 
f 18118 19281 17754 
f 17396 17807 19282 
f 18117 19282 17807 
f 17393 17810 19283 
f 18113 19283 17810 
f 17387 17808 19284 
f 18111 19284 17808 
f 18115 19285 17751 
f 17354 17751 19285 
f 17366 17752 19286 
f 18119 19286 17752 
f 17390 17809 19287 
f 18112 19287 17809 
f 17384 17756 19288 
f 18127 19288 17756 
f 17706 19289 19177 
s 1
f 19289 17706 17702 
s 2
f 17225 17733 19290 
f 18673 19290 17733 
f 17219 17735 19291 
f 18666 19291 17735 
f 17243 17732 19292 
f 18662 19292 17732 
f 17222 17734 19293 
f 18667 19293 17734 
f 17408 18168 19294 
f 18195 19294 18168 
f 17407 18170 19295 
f 18194 19295 18170 
f 18196 19296 18172 
f 17417 18172 19296 
f 17409 18173 19297 
f 18197 19297 18173 
f 17418 18171 19298 
f 18200 19298 18171 
f 17416 18167 19299 
f 18202 19299 18167 
f 17406 18175 19300 
f 18201 19300 18175 
f 17415 18176 19301 
f 18199 19301 18176 
f 18198 19302 18174 
f 17410 18174 19302 
f 17414 18169 19303 
f 18193 19303 18169 
f 17231 17729 19304 
f 18637 19304 17729 
f 18641 19305 17726 
f 17249 17726 19305 
f 17240 17728 19306 
f 18642 19306 17728 
f 17255 17722 19307 
f 18640 19307 17722 
f 17237 17730 19308 
f 18635 19308 17730 
f 17246 17727 19309 
f 18639 19309 17727 
f 17228 17723 19310 
f 18629 19310 17723 
f 17252 17725 19311 
f 18643 19311 17725 
f 18634 19312 17721 
f 17234 17721 19312 
f 17258 17724 19313 
f 18632 19313 17724 
f 17411 18002 19314 
f 18192 19314 18002 
f 17412 18001 19315 
f 18190 19315 18001 
f 17413 18003 19316 
f 18189 19316 18003 
f 18191 19317 18160 
f 17405 18160 19317 
f 17267 17784 19318 
f 18739 19318 17784 
f 18727 19319 17782 
f 17276 17782 19319 
f 17273 17783 19320 
f 18740 19320 17783 
f 17270 17785 19321 
f 18731 19321 17785 
f 18247 19322 18226 
f 17436 18226 19322 
f 18251 19323 18227 
f 17425 18227 19323 
f 17435 18228 19324 
f 18248 19324 18228 
f 18250 19325 18231 
f 17432 18231 19325 
f 17433 18225 19326 
f 18246 19326 18225 
f 18252 19327 18232 
f 17427 18232 19327 
f 17434 18230 19328 
f 18249 19328 18230 
f 17426 18229 19329 
f 18245 19329 18229 
f 17294 17769 19330 
f 18697 19330 17769 
f 17279 17772 19331 
f 18700 19331 17772 
f 17291 17770 19332 
f 18699 19332 17770 
f 17288 17773 19333 
f 18701 19333 17773 
f 18696 19334 17775 
f 17297 17775 19334 
f 17282 17771 19335 
f 18705 19335 17771 
f 17285 17768 19336 
f 18706 19336 17768 
f 17300 17774 19337 
f 18704 19337 17774 
f 17431 18023 19338 
f 18243 19338 18023 
f 18241 19339 18022 
f 17428 18022 19339 
f 17429 18024 19340 
f 18244 19340 18024 
f 17430 18025 19341 
f 18242 19341 18025 
f 19342 17689 17510 
s 1
f 17689 19342 19345 
f 17688 19343 19347 
s 2
f 19343 17688 17506 
s 1
f 19344 17510 17508 
s 2
f 17510 19344 19342 
f 17705 19345 17508 
s 1
f 19345 17705 17689 
s 2
f 17506 19346 19343 
s 1
f 19346 17506 17504 
s 2
f 17704 19347 17504 
s 1
f 19347 17704 17688 
s 2
f 17438 18148 19348 
f 18665 19348 18148 
f 17447 18154 19349 
f 18663 19349 18154 
f 17450 18152 19350 
f 18669 19350 18152 
f 17440 18156 19351 
f 18664 19351 18156 
f 17448 18150 19352 
f 18661 19352 18150 
f 17437 18151 19353 
f 18670 19353 18151 
f 17441 18155 19354 
f 18668 19354 18155 
f 17449 18153 19355 
f 18671 19355 18153 
f 17446 18149 19356 
f 18660 19356 18149 
f 17439 18147 19357 
f 18672 19357 18147 
f 17445 18157 19358 
f 18674 19358 18157 
f 17690 19359 17484 
s 1
f 19359 17690 17486 
f 19360 17691 17482 
s 2
f 17691 19360 17480 
f 17451 18142 19361 
f 18636 19361 18142 
f 17443 18140 19362 
f 18638 19362 18140 
f 17452 18144 19363 
f 18633 19363 18144 
f 17444 18143 19364 
f 18630 19364 18143 
f 17442 18141 19365 
f 18631 19365 18141 
f 17245 17885 19366 
f 18682 19366 17885 
f 17310 18030 19367 
f 18688 19367 18030 
f 18678 19368 18033 
f 17313 18033 19368 
f 17316 18031 19369 
f 18685 19369 18031 
f 17307 18029 19370 
f 18677 19370 18029 
f 18687 19371 18032 
f 17304 18032 19371 
f 17227 18178 19372 
f 18683 19372 18178 
f 18676 19373 18182 
f 17334 18182 19373 
f 17224 18177 19374 
f 18675 19374 18177 
f 17221 18179 19375 
f 18686 19375 18179 
f 18684 19376 18181 
f 17331 18181 19376 
f 18680 19377 18183 
f 17325 18183 19377 
f 17337 18180 19378 
f 18679 19378 18180 
f 17328 18184 19379 
f 18681 19379 18184 
f 18658 19380 17883 
f 17251 17883 19380 
f 17257 17881 19381 
f 18653 19381 17881 
f 17254 17882 19382 
f 18645 19382 17882 
f 18656 19383 18026 
f 17319 18026 19383 
f 17248 17880 19384 
f 18652 19384 17880 
f 17322 18027 19385 
f 18651 19385 18027 
f 17260 17884 19386 
f 18650 19386 17884 
f 17349 18185 19387 
f 18690 19387 18185 
f 18644 19388 17942 
f 17236 17942 19388 
f 17340 18163 19389 
f 18649 19389 18163 
f 17230 17943 19390 
f 18654 19390 17943 
f 17346 18161 19391 
f 18655 19391 18161 
f 17242 17945 19392 
f 18648 19392 17945 
f 17343 18162 19393 
f 18647 19393 18162 
f 18657 19394 17944 
f 17239 17944 19394 
f 17233 17946 19395 
f 18646 19395 17946 
f 17468 18216 19396 
f 18737 19396 18216 
f 17470 18220 19397 
f 18732 19397 18220 
f 17455 18217 19398 
f 18733 19398 18217 
f 17454 18219 19399 
f 18730 19399 18219 
f 17457 18215 19400 
f 18738 19400 18215 
f 18729 19401 18214 
f 17471 18214 19401 
f 18735 19402 18211 
f 17473 18211 19402 
f 17472 18212 19403 
f 18734 19403 18212 
f 17456 18213 19404 
f 18728 19404 18213 
f 17469 18218 19405 
f 18736 19405 18218 
f 17479 18221 19406 
f 18741 19406 18221 
s 1
f 19407 17494 17492 
s 2
f 17494 19407 19479 
f 17474 18204 19408 
f 18703 19408 18204 
f 17458 18207 19409 
f 18708 19409 18207 
f 17477 18206 19410 
f 18698 19410 18206 
f 17459 18208 19411 
f 18702 19411 18208 
f 17476 18209 19412 
f 18707 19412 18209 
f 17478 18205 19413 
f 18695 19413 18205 
f 17475 18210 19414 
f 18709 19414 18210 
f 17490 19415 17678 
s 1
f 19415 17490 17488 
s 2
f 17358 18058 19416 
f 18750 19416 18058 
f 18748 19417 18060 
f 17355 18060 19417 
f 17364 18063 19418 
f 18747 19418 18063 
f 18745 19419 18062 
f 17352 18062 19419 
f 18756 19420 18061 
f 17367 18061 19420 
f 18744 19421 18059 
f 17361 18059 19421 
f 18758 19422 18064 
f 17385 18064 19422 
f 17272 18233 19423 
f 18749 19423 18233 
f 17388 18238 19424 
f 18746 19424 18238 
f 17397 18237 19425 
f 18751 19425 18237 
f 17394 18240 19426 
f 18752 19426 18240 
f 17275 18234 19427 
f 18755 19427 18234 
f 17269 18239 19428 
f 18757 19428 18239 
f 18753 19429 18235 
f 17278 18235 19429 
f 17391 18236 19430 
f 18754 19430 18236 
f 18720 19431 18054 
f 17382 18054 19431 
f 18724 19432 17890 
f 17299 17890 19432 
f 18713 19433 17889 
f 17296 17889 19433 
f 17379 18056 19434 
f 18714 19434 18056 
f 18710 19435 17891 
f 17302 17891 19435 
f 17376 18057 19436 
f 18715 19436 18057 
f 17373 18053 19437 
f 18721 19437 18053 
f 17370 18055 19438 
f 18722 19438 18055 
f 18716 19439 17984 
f 17293 17984 19439 
f 17281 17983 19440 
f 18711 19440 17983 
f 17403 18223 19441 
f 18723 19441 18223 
f 17284 17986 19442 
f 18712 19442 17986 
f 17290 17987 19443 
f 18719 19443 17987 
f 18718 19444 17985 
f 17287 17985 19444 
f 17400 18224 19445 
f 18717 19445 18224 
f 17347 17935 19446 
f 18801 19446 17935 
f 18804 19447 17939 
f 17235 17939 19447 
f 17232 17932 19448 
f 18800 19448 17932 
f 17241 17934 19449 
f 18799 19449 17934 
f 17229 17933 19450 
f 18810 19450 17933 
f 17238 17936 19451 
f 18812 19451 17936 
f 17341 17938 19452 
f 18806 19452 17938 
f 17344 17937 19453 
f 18809 19453 17937 
s off
f 18414 19454 19185 
f 19454 18414 19163 
s 2
f 18813 19455 18019 
f 17320 18019 19455 
f 17259 18017 19456 
f 18803 19456 18017 
f 17253 18015 19457 
f 18802 19457 18015 
f 17250 18020 19458 
f 18805 19458 18020 
f 17247 18016 19459 
f 18807 19459 18016 
f 17323 18021 19460 
f 18811 19460 18021 
f 17256 18018 19461 
f 18808 19461 18018 
f 17223 17920 19462 
f 18790 19462 17920 
f 17338 17926 19463 
f 18797 19463 17926 
f 17220 17921 19464 
f 18784 19464 17921 
f 17332 17925 19465 
f 18794 19465 17925 
f 17335 17922 19466 
f 18785 19466 17922 
f 17226 17927 19467 
f 18791 19467 17927 
f 17326 17924 19468 
f 18786 19468 17924 
f 17329 17923 19469 
f 18793 19469 17923 
f 17350 17928 19470 
f 18798 19470 17928 
f 17314 18010 19471 
f 18787 19471 18010 
f 17308 18009 19472 
f 18789 19472 18009 
f 17317 18013 19473 
f 18788 19473 18013 
f 17311 18011 19474 
f 18795 19474 18011 
f 18792 19475 18012 
f 17305 18012 19475 
f 17244 18014 19476 
f 18796 19476 18014 
f 17687 19477 17512 
s 1
f 19477 17687 17514 
f 19478 17686 17518 
s 2
f 17686 19478 17516 
f 19479 17683 17494 
s 1
f 17683 19479 19480 
f 19480 17698 17683 
s 2
f 17698 19480 17492 
f 17693 19481 17488 
s 1
f 19481 17693 17678 
s off
f 17580 19482 19221 
f 19482 17580 18853 
s 2
f 17289 17980 19483 
f 18833 19483 17980 
f 17401 17982 19484 
f 18841 19484 17982 
f 17286 17977 19485 
f 18835 19485 17977 
f 17283 17978 19486 
f 18836 19486 17978 
f 17292 17979 19487 
f 18831 19487 17979 
f 17404 17981 19488 
f 18842 19488 17981 
f 17280 17976 19489 
f 18834 19489 17976 
s 1
f 19490 17692 17502 
s 2
f 17692 19490 17500 
f 17696 19491 17496 
s 1
f 19491 17696 17498 
s 2
f 17295 18044 19492 
f 18829 19492 18044 
f 17374 18045 19493 
f 18839 19493 18045 
f 17383 18050 19494 
f 18837 19494 18050 
f 17298 18046 19495 
f 18830 19495 18046 
f 17371 18049 19496 
f 18838 19496 18049 
s 4
f 19757 19741 19704 
f 19672 19782 19507 
f 19585 19729 19701 
f 19741 19757 19699 
f 19573 19765 19768 
f 19751 19786 19718 
f 19760 19603 19601 
f 19755 19718 19786 
f 19736 19513 19668 
f 19634 19764 19572 
f 19512 19702 19781 
f 19766 19622 19684 
f 19739 19781 19702 
f 19770 19705 19771 
f 19748 19747 19712 
f 19762 19562 19773 
s off
f 19551 19563 19564 
f 19752 19553 19742 
s 4
f 19507 19545 19672 
f 19729 19585 19626 
f 19562 19762 19654 
f 19622 19766 19779 
f 19769 19529 19776 
f 19705 19770 19569 
s off
f 19588 19628 19738 
s 4
f 19765 19573 19735 
f 19777 19581 19780 
f 19603 19760 19759 
s off
f 19511 19519 19644 
s 4
f 19668 19638 19736 
s off
f 19563 19552 19520 
f 19629 19540 19570 
f 19555 19557 19633 
f 19574 19590 19565 
s 4
f 19581 19777 19621 
s off
f 19742 19785 19752 
f 19586 19612 19580 
f 19641 19734 19600 
f 19700 19755 19786 
s 4
f 19776 19567 19761 
s off
f 19682 19549 19655 
f 19715 19728 19615 
f 19611 19514 19660 
f 19617 19690 19726 
f 19778 19707 19592 
f 19667 19753 19714 
f 19708 19646 19631 
f 19586 19506 19523 
s 4
f 19567 19776 19529 
s off
f 19607 19689 19651 
s 4
f 19642 19759 19758 
s off
f 19640 19499 19531 
f 19586 19595 19612 
f 19745 19748 19730 
s 4
f 19758 19532 19642 
s off
f 19540 19629 19597 
f 19669 19533 19497 
f 19706 19534 19571 
s 4
f 19727 19699 19757 
s off
f 19690 19617 19687 
f 19616 19688 19671 
f 19522 19501 19536 
f 19657 19514 19611 
s 4
f 19733 19606 19732 
f 19601 19763 19760 
s off
f 19636 19517 19568 
f 19661 19710 19561 
s 4
f 19683 19576 19737 
s off
f 19554 19655 19680 
f 19521 19570 19540 
s 4
f 19779 19584 19622 
s off
f 19626 19674 19729 
f 19565 19790 19681 
f 19499 19640 19648 
f 19754 19618 19613 
f 19543 19713 19716 
f 19534 19587 19696 
f 19639 19698 19624 
s 4
f 19592 19670 19618 
s off
f 19698 19639 19695 
f 19546 19666 19743 
f 19591 19628 19709 
f 19688 19616 19686 
f 19554 19686 19616 
s 4
f 19708 19627 19663 
f 19753 19706 19571 
s off
f 19787 19614 19585 
f 19676 19732 19719 
f 19511 19498 19551 
f 19532 19511 19642 
f 19678 19620 19791 
f 19641 19717 19734 
f 19646 19708 19663 
f 19503 19525 19605 
s 4
f 19737 19575 19683 
s off
f 19658 19555 19633 
f 19556 19693 19645 
f 19692 19716 19713 
f 19715 19526 19711 
f 19619 19589 19685 
s 4
f 19538 19772 19767 
f 19583 19783 19774 
s off
f 19559 19631 19646 
f 19694 19651 19689 
f 19697 19504 19694 
f 19782 19724 19507 
f 19656 19561 19710 
f 19654 19660 19562 
f 19722 19741 19699 
f 19563 19551 19498 
f 19520 19564 19563 
f 19638 19703 19736 
f 19565 19590 19522 
s 4
f 19621 19775 19599 
s off
f 19649 19518 19697 
f 19504 19697 19518 
f 19529 19756 19567 
f 19786 19723 19700 
f 19568 19735 19636 
f 19724 19643 19661 
f 19569 19640 19705 
f 19702 19792 19739 
f 19570 19521 19508 
f 19746 19650 19549 
f 19549 19682 19627 
f 19531 19705 19640 
f 19572 19595 19634 
f 19622 19597 19684 
f 19573 19636 19735 
f 19681 19541 19626 
f 19741 19722 19677 
f 19749 19683 19575 
f 19686 19554 19680 
s 4
f 19584 19779 19576 
s off
f 19525 19503 19691 
f 19691 19645 19693 
s 4
f 19684 19768 19766 
s off
f 19687 19671 19688 
s 4
f 19775 19621 19777 
s off
f 19695 19666 19546 
f 19580 19506 19586 
f 19660 19654 19611 
f 19621 19536 19581 
s 4
f 19582 19569 19770 
s off
f 19659 19740 19652 
s 4
f 19712 19583 19748 
s off
f 19583 19730 19748 
f 19540 19584 19521 
f 19683 19749 19658 
f 19614 19513 19585 
f 19586 19634 19595 
f 19756 19529 19586 
s 4
f 19587 19607 19651 
s off
f 19624 19696 19587 
f 19516 19739 19792 
f 19738 19516 19588 
s 4
f 19618 19778 19592 
f 19726 19543 19716 
s off
f 19501 19522 19590 
f 19628 19591 19547 
f 19784 19702 19591 
f 19750 19675 19707 
f 19750 19613 19675 
f 19693 19556 19692 
s 4
f 19764 19634 19769 
s off
f 19517 19594 19661 
f 19595 19572 19531 
f 19531 19612 19595 
s 4
f 19780 19654 19762 
s off
f 19696 19624 19698 
f 19533 19684 19597 
f 19597 19497 19533 
s 4
f 19598 19518 19606 
s off
f 19598 19695 19639 
f 19522 19599 19565 
f 19565 19729 19674 
f 19511 19532 19745 
f 19519 19511 19600 
f 19520 19601 19564 
f 19603 19564 19601 
f 19596 19602 19534 
f 19647 19650 19673 
f 19551 19642 19511 
f 19564 19603 19551 
f 19604 19655 19549 
s 4
f 19575 19737 19704 
f 19554 19503 19605 
f 19682 19663 19627 
s off
f 19789 19676 19719 
f 19649 19697 19537 
s 4
f 19571 19510 19753 
s off
f 19510 19714 19753 
f 19685 19726 19690 
s 4
f 19735 19767 19765 
s off
f 19689 19607 19667 
s 4
f 19532 19758 19747 
s off
f 19559 19646 19662 
f 19691 19610 19525 
f 19611 19536 19501 
f 19611 19581 19536 
f 19612 19531 19499 
f 19613 19750 19512 
f 19632 19613 19512 
s 4
f 19732 19665 19733 
s off
f 19676 19665 19550 
f 19526 19715 19615 
f 19722 19699 19725 
s 4
f 19645 19616 19671 
f 19503 19554 19616 
f 19671 19556 19645 
f 19716 19617 19726 
f 19637 19618 19740 
s off
f 19618 19652 19740 
f 19619 19527 19558 
f 19731 19750 19707 
f 19620 19678 19505 
f 19620 19736 19703 
f 19599 19522 19621 
f 19536 19621 19522 
f 19597 19622 19540 
f 19584 19540 19622 
f 19623 19507 19724 
f 19623 19561 19507 
s 4
f 19504 19639 19624 
f 19651 19624 19587 
s off
f 19523 19630 19625 
f 19664 19756 19723 
f 19681 19626 19502 
s 4
f 19746 19510 19571 
s off
f 19650 19746 19571 
f 19628 19547 19500 
f 19497 19597 19629 
f 19506 19630 19523 
f 19528 19667 19714 
f 19714 19679 19528 
f 19509 19652 19721 
f 19781 19632 19512 
f 19633 19508 19658 
f 19634 19586 19529 
s 4
f 19529 19769 19634 
s off
f 19515 19713 19635 
s 4
f 19771 19572 19764 
s off
f 19636 19573 19533 
f 19533 19669 19636 
s 4
f 19740 19589 19637 
s off
f 19589 19619 19637 
f 19601 19520 19638 
s 4
f 19763 19601 19638 
f 19518 19598 19639 
f 19639 19504 19518 
s off
f 19516 19648 19640 
f 19640 19569 19516 
f 19524 19717 19641 
f 19583 19744 19730 
s 4
f 19759 19642 19603 
s off
f 19642 19551 19603 
s 4
f 19772 19538 19643 
s off
f 19538 19661 19643 
f 19644 19498 19511 
s 4
f 19616 19645 19503 
s off
f 19645 19691 19503 
f 19720 19662 19646 
f 19663 19720 19646 
f 19549 19647 19604 
f 19650 19647 19549 
f 19542 19648 19516 
f 19518 19649 19606 
f 19606 19719 19732 
f 19650 19571 19534 
f 19534 19673 19650 
f 19651 19694 19504 
s 4
f 19624 19651 19504 
s off
f 19632 19754 19613 
f 19721 19652 19632 
f 19678 19653 19505 
f 19581 19611 19654 
s 4
f 19654 19780 19581 
s off
f 19539 19680 19655 
f 19655 19604 19539 
f 19561 19656 19507 
f 19611 19501 19657 
f 19658 19508 19521 
f 19584 19658 19521 
f 19652 19509 19659 
f 19635 19659 19509 
f 19725 19562 19660 
f 19660 19711 19526 
f 19661 19538 19568 
f 19661 19568 19517 
s 4
f 19761 19664 19751 
s off
f 19662 19525 19610 
s 4
f 19663 19554 19605 
s off
f 19525 19663 19605 
f 19756 19664 19567 
s 4
f 19664 19761 19567 
s off
f 19665 19743 19733 
f 19665 19676 19614 
f 19695 19598 19666 
f 19666 19733 19743 
f 19609 19689 19667 
f 19667 19528 19609 
f 19537 19789 19719 
s 4
f 19638 19668 19763 
s off
f 19517 19636 19669 
f 19670 19613 19618 
f 19670 19675 19613 
f 19671 19687 19617 
s 4
f 19556 19671 19617 
f 19643 19672 19772 
s off
f 19619 19558 19731 
f 19673 19534 19602 
s 4
f 19773 19699 19727 
s off
f 19674 19626 19541 
f 19541 19681 19790 
f 19675 19592 19707 
f 19675 19670 19592 
f 19676 19789 19513 
f 19513 19614 19676 
s 4
f 19658 19749 19555 
f 19722 19728 19677 
s off
f 19520 19552 19678 
f 19679 19774 19783 
f 19631 19559 19679 
f 19539 19788 19680 
f 19788 19548 19680 
f 19565 19681 19574 
f 19655 19554 19682 
s 4
f 19554 19663 19682 
f 19576 19683 19584 
s off
f 19658 19584 19683 
s 4
f 19768 19684 19573 
s off
f 19684 19533 19573 
f 19685 19608 19527 
f 19527 19619 19685 
f 19548 19688 19686 
f 19686 19680 19548 
f 19687 19578 19535 
f 19535 19690 19687 
f 19688 19548 19578 
f 19578 19687 19688 
f 19689 19609 19530 
f 19530 19694 19689 
f 19690 19535 19608 
f 19608 19685 19690 
f 19610 19691 19577 
f 19693 19577 19691 
f 19593 19692 19515 
f 19713 19515 19692 
f 19692 19593 19693 
f 19577 19693 19593 
f 19694 19530 19560 
f 19560 19697 19694 
f 19544 19695 19579 
f 19579 19695 19546 
f 19596 19534 19696 
f 19696 19698 19596 
f 19697 19566 19537 
f 19697 19560 19566 
f 19695 19544 19698 
f 19596 19698 19544 
s 4
f 19699 19773 19562 
s off
f 19562 19725 19699 
s 4
f 19742 19700 19785 
f 19641 19744 19524 
s off
f 19787 19546 19743 
s 4
f 19701 19599 19775 
f 19702 19512 19547 
s off
f 19547 19591 19702 
f 19791 19520 19678 
f 19703 19638 19520 
f 19737 19604 19704 
f 19704 19604 19647 
f 19705 19531 19572 
s 4
f 19572 19771 19705 
s off
f 19587 19534 19706 
s 4
f 19607 19587 19706 
s off
f 19707 19637 19619 
f 19707 19619 19731 
f 19631 19510 19708 
s 4
f 19510 19746 19708 
s off
f 19588 19709 19628 
f 19792 19702 19784 
f 19710 19661 19594 
f 19711 19660 19514 
f 19528 19712 19609 
f 19679 19783 19528 
f 19713 19543 19659 
f 19659 19635 19713 
f 19510 19631 19714 
f 19679 19714 19631 
f 19728 19715 19557 
f 19716 19692 19556 
s 4
f 19617 19716 19556 
s off
f 19524 19553 19717 
f 19559 19774 19679 
f 19662 19718 19559 
f 19649 19537 19719 
f 19719 19606 19649 
f 19525 19662 19720 
f 19663 19525 19720 
f 19632 19582 19721 
f 19770 19721 19582 
s 4
f 19722 19526 19615 
f 19728 19722 19615 
f 19756 19625 19723 
f 19723 19785 19700 
f 19561 19623 19724 
f 19724 19661 19561 
f 19526 19722 19725 
f 19725 19660 19526 
f 19543 19726 19589 
s off
f 19726 19685 19589 
f 19757 19673 19727 
f 19602 19727 19673 
s 4
f 19555 19677 19728 
s off
f 19555 19728 19557 
s 4
f 19599 19701 19729 
s off
f 19729 19565 19599 
s 4
f 19730 19641 19600 
f 19744 19641 19730 
s off
f 19731 19672 19750 
f 19558 19672 19731 
f 19732 19676 19550 
s 4
f 19665 19732 19550 
s off
f 19733 19666 19598 
s 4
f 19606 19733 19598 
s off
f 19600 19734 19519 
f 19735 19568 19538 
s 4
f 19767 19735 19538 
s off
f 19736 19620 19505 
s 4
f 19513 19736 19505 
s off
f 19576 19539 19737 
f 19604 19737 19539 
f 19516 19738 19542 
f 19739 19516 19569 
s 4
f 19569 19582 19739 
f 19589 19740 19543 
s off
f 19740 19659 19543 
f 19677 19575 19741 
s 4
f 19575 19704 19741 
f 19524 19700 19742 
s off
f 19742 19553 19524 
f 19614 19787 19743 
f 19743 19665 19614 
f 19583 19700 19744 
s 4
f 19700 19524 19744 
f 19600 19511 19745 
f 19600 19745 19730 
s off
f 19549 19627 19746 
s 4
f 19627 19708 19746 
s off
f 19758 19609 19747 
f 19747 19609 19712 
s 4
f 19747 19748 19532 
s off
f 19748 19745 19532 
f 19575 19677 19749 
s 4
f 19677 19555 19749 
s off
f 19545 19750 19672 
f 19545 19512 19750 
f 19662 19761 19751 
f 19662 19751 19718 
f 19785 19630 19752 
s 4
f 19706 19753 19607 
s off
f 19753 19667 19607 
f 19652 19754 19632 
f 19652 19618 19754 
s 4
f 19774 19755 19583 
s off
f 19700 19583 19755 
s 4
f 19756 19586 19523 
f 19625 19756 19523 
s off
f 19673 19757 19647 
f 19647 19757 19704 
f 19609 19758 19530 
f 19759 19530 19758 
f 19530 19759 19560 
f 19760 19560 19759 
f 19560 19760 19566 
f 19763 19566 19760 
f 19761 19662 19610 
f 19577 19761 19610 
f 19544 19762 19596 
f 19773 19596 19762 
f 19566 19763 19537 
f 19668 19537 19763 
f 19635 19764 19515 
f 19769 19515 19764 
f 19535 19765 19608 
f 19767 19608 19765 
f 19548 19766 19578 
f 19768 19578 19766 
f 19608 19767 19527 
f 19772 19527 19767 
f 19578 19768 19535 
f 19765 19535 19768 
f 19515 19769 19593 
f 19776 19593 19769 
f 19721 19770 19509 
f 19771 19509 19770 
f 19509 19771 19635 
f 19764 19635 19771 
f 19527 19772 19558 
f 19672 19558 19772 
f 19596 19773 19602 
f 19727 19602 19773 
f 19559 19718 19774 
s 4
f 19718 19755 19774 
s off
f 19787 19775 19546 
f 19777 19546 19775 
f 19593 19776 19577 
f 19761 19577 19776 
f 19546 19777 19579 
f 19780 19579 19777 
f 19637 19707 19778 
s 4
f 19618 19637 19778 
s off
f 19788 19779 19548 
f 19766 19548 19779 
f 19762 19544 19780 
f 19579 19780 19544 
f 19582 19632 19781 
s 4
f 19781 19739 19582 
f 19672 19643 19782 
s off
f 19643 19724 19782 
s 4
f 19583 19712 19783 
s off
f 19712 19528 19783 
s 4
f 19709 19792 19784 
f 19709 19784 19591 
s off
f 19785 19625 19630 
s 4
f 19785 19723 19625 
f 19786 19751 19664 
s off
f 19723 19786 19664 
f 19585 19701 19787 
f 19775 19787 19701 
f 19539 19576 19788 
f 19779 19788 19576 
f 19513 19789 19668 
f 19537 19668 19789 
s 4
f 19790 19565 19674 
f 19674 19541 19790 
f 19703 19520 19791 
f 19791 19620 19703 
f 19588 19516 19792 
f 19792 19709 19588 
s 2
f 19793 19795 19796 
f 19796 19794 19793 
s 4
f 19797 19798 19800 
f 19800 19799 19797 
s 8
f 19793 19794 19798 
f 19798 19797 19793 
s 16
f 19794 19796 19800 
f 19800 19798 19794 
s 32
f 19796 19795 19799 
f 19799 19800 19796 
s 64
f 19795 19793 19797 
f 19797 19799 19795 
s 2
f 19801 19803 19804 
f 19804 19802 19801 
s 4
f 19805 19806 19808 
f 19808 19807 19805 
s 8
f 19801 19802 19806 
f 19806 19805 19801 
s 16
f 19802 19804 19808 
f 19808 19806 19802 
s 32
f 19804 19803 19807 
f 19807 19808 19804 
s 64
f 19803 19801 19805 
f 19805 19807 19803 
s 2
f 19809 19811 19812 
f 19812 19810 19809 
s 4
f 19813 19814 19816 
f 19816 19815 19813 
s 8
f 19809 19810 19814 
f 19814 19813 19809 
s 16
f 19810 19812 19816 
f 19816 19814 19810 
s 32
f 19812 19811 19815 
f 19815 19816 19812 
s 64
f 19811 19809 19813 
f 19813 19815 19811 
s 2
f 19817 19819 19820 
f 19820 19818 19817 
s 4
f 19821 19822 19824 
f 19824 19823 19821 
s 8
f 19817 19818 19822 
f 19822 19821 19817 
s 16
f 19818 19820 19824 
f 19824 19822 19818 
s 32
f 19820 19819 19823 
f 19823 19824 19820 
s 64
f 19819 19817 19821 
f 19821 19823 19819 
s 2
f 19825 19827 19828 
f 19828 19826 19825 
s 4
f 19829 19830 19832 
f 19832 19831 19829 
s 8
f 19825 19826 19830 
f 19830 19829 19825 
s 16
f 19826 19828 19832 
f 19832 19830 19826 
s 32
f 19828 19827 19831 
f 19831 19832 19828 
s 64
f 19827 19825 19829 
f 19829 19831 19827 
s 2
f 19833 19835 19836 
f 19836 19834 19833 
s 4
f 19837 19838 19840 
f 19840 19839 19837 
s 8
f 19833 19834 19838 
f 19838 19837 19833 
s 16
f 19834 19836 19840 
f 19840 19838 19834 
s 32
f 19836 19835 19839 
f 19839 19840 19836 
s 64
f 19835 19833 19837 
f 19837 19839 19835 
s 2
f 19841 19843 19844 
f 19844 19842 19841 
s 4
f 19845 19846 19848 
f 19848 19847 19845 
s 8
f 19841 19842 19846 
f 19846 19845 19841 
s 16
f 19842 19844 19848 
f 19848 19846 19842 
s 32
f 19844 19843 19847 
f 19847 19848 19844 
s 64
f 19843 19841 19845 
f 19845 19847 19843 
s 2
f 19849 19850 19852 
f 19852 19851 19849 
s 4
f 19853 19855 19856 
f 19856 19854 19853 
s 8
f 19849 19853 19854 
f 19854 19850 19849 
s 16
f 19850 19854 19856 
f 19856 19852 19850 
s 32
f 19852 19856 19855 
f 19855 19851 19852 
s 64
f 19851 19855 19853 
f 19853 19849 19851 
s 2
f 19857 19858 19860 
f 19860 19859 19857 
s 4
f 19861 19863 19864 
f 19864 19862 19861 
s 8
f 19857 19861 19862 
f 19862 19858 19857 
s 16
f 19858 19862 19864 
f 19864 19860 19858 
s 32
f 19860 19864 19863 
f 19863 19859 19860 
s 64
f 19859 19863 19861 
f 19861 19857 19859 
s 2
f 19865 19866 19868 
f 19868 19867 19865 
s 4
f 19869 19871 19872 
f 19872 19870 19869 
s 8
f 19865 19869 19870 
f 19870 19866 19865 
s 16
f 19866 19870 19872 
f 19872 19868 19866 
s 32
f 19868 19872 19871 
f 19871 19867 19868 
s 64
f 19867 19871 19869 
f 19869 19865 19867 
s 2
f 19873 19874 19876 
f 19876 19875 19873 
s 4
f 19877 19879 19880 
f 19880 19878 19877 
s 8
f 19873 19877 19878 
f 19878 19874 19873 
s 16
f 19874 19878 19880 
f 19880 19876 19874 
s 32
f 19876 19880 19879 
f 19879 19875 19876 
s 64
f 19875 19879 19877 
f 19877 19873 19875 
s 2
f 19881 19882 19884 
f 19884 19883 19881 
s 4
f 19885 19887 19888 
f 19888 19886 19885 
s 8
f 19881 19885 19886 
f 19886 19882 19881 
s 16
f 19882 19886 19888 
f 19888 19884 19882 
s 32
f 19884 19888 19887 
f 19887 19883 19884 
s 64
f 19883 19887 19885 
f 19885 19881 19883 
s 2
f 19889 19890 19892 
f 19892 19891 19889 
s 4
f 19893 19895 19896 
f 19896 19894 19893 
s 8
f 19889 19893 19894 
f 19894 19890 19889 
s 16
f 19890 19894 19896 
f 19896 19892 19890 
s 32
f 19892 19896 19895 
f 19895 19891 19892 
s 64
f 19891 19895 19893 
f 19893 19889 19891 
s 2
f 19897 19898 19900 
f 19900 19899 19897 
s 4
f 19901 19903 19904 
f 19904 19902 19901 
s 8
f 19897 19901 19902 
f 19902 19898 19897 
s 16
f 19898 19902 19904 
f 19904 19900 19898 
s 32
f 19900 19904 19903 
f 19903 19899 19900 
s 64
f 19899 19903 19901 
f 19901 19897 19899 
s 2
f 19905 19907 19908 
f 19908 19906 19905 
s 4
f 19909 19910 19912 
f 19912 19911 19909 
s 8
f 19905 19906 19910 
f 19910 19909 19905 
s 16
f 19906 19908 19912 
f 19912 19910 19906 
s 32
f 19908 19907 19911 
f 19911 19912 19908 
s 64
f 19907 19905 19909 
f 19909 19911 19907 
s 2
f 19913 19915 19916 
f 19916 19914 19913 
s 4
f 19917 19918 19920 
f 19920 19919 19917 
s 8
f 19913 19914 19918 
f 19918 19917 19913 
s 16
f 19914 19916 19920 
f 19920 19918 19914 
s 32
f 19916 19915 19919 
f 19919 19920 19916 
s 64
f 19915 19913 19917 
f 19917 19919 19915 
s 2
f 19921 19923 19924 
f 19924 19922 19921 
s 4
f 19925 19926 19928 
f 19928 19927 19925 
s 8
f 19921 19922 19926 
f 19926 19925 19921 
s 16
f 19922 19924 19928 
f 19928 19926 19922 
s 32
f 19924 19923 19927 
f 19927 19928 19924 
s 64
f 19923 19921 19925 
f 19925 19927 19923 
s 2
f 19929 19931 19932 
f 19932 19930 19929 
s 4
f 19933 19934 19936 
f 19936 19935 19933 
s 8
f 19929 19930 19934 
f 19934 19933 19929 
s 16
f 19930 19932 19936 
f 19936 19934 19930 
s 32
f 19932 19931 19935 
f 19935 19936 19932 
s 64
f 19931 19929 19933 
f 19933 19935 19931 
s 2
f 19937 19939 19940 
f 19940 19938 19937 
s 4
f 19941 19942 19944 
f 19944 19943 19941 
s 8
f 19937 19938 19942 
f 19942 19941 19937 
s 16
f 19938 19940 19944 
f 19944 19942 19938 
s 32
f 19940 19939 19943 
f 19943 19944 19940 
s 64
f 19939 19937 19941 
f 19941 19943 19939 
s 2
f 19945 19947 19948 
f 19948 19946 19945 
s 4
f 19949 19950 19952 
f 19952 19951 19949 
s 8
f 19945 19946 19950 
f 19950 19949 19945 
s 16
f 19946 19948 19952 
f 19952 19950 19946 
s 32
f 19948 19947 19951 
f 19951 19952 19948 
s 64
f 19947 19945 19949 
f 19949 19951 19947 
s 2
f 19953 19955 19956 
f 19956 19954 19953 
s 4
f 19957 19958 19960 
f 19960 19959 19957 
s 8
f 19953 19954 19958 
f 19958 19957 19953 
s 16
f 19954 19956 19960 
f 19960 19958 19954 
s 32
f 19956 19955 19959 
f 19959 19960 19956 
s 64
f 19955 19953 19957 
f 19957 19959 19955 
s 2
f 19961 19962 19964 
f 19964 19963 19961 
s 4
f 19965 19967 19968 
f 19968 19966 19965 
s 8
f 19961 19965 19966 
f 19966 19962 19961 
s 16
f 19962 19966 19968 
f 19968 19964 19962 
s 32
f 19964 19968 19967 
f 19967 19963 19964 
s 64
f 19963 19967 19965 
f 19965 19961 19963 
s 2
f 19969 19970 19972 
f 19972 19971 19969 
s 4
f 19973 19975 19976 
f 19976 19974 19973 
s 8
f 19969 19973 19974 
f 19974 19970 19969 
s 16
f 19970 19974 19976 
f 19976 19972 19970 
s 32
f 19972 19976 19975 
f 19975 19971 19972 
s 64
f 19971 19975 19973 
f 19973 19969 19971 
s 2
f 19977 19978 19980 
f 19980 19979 19977 
s 4
f 19981 19983 19984 
f 19984 19982 19981 
s 8
f 19977 19981 19982 
f 19982 19978 19977 
s 16
f 19978 19982 19984 
f 19984 19980 19978 
s 32
f 19980 19984 19983 
f 19983 19979 19980 
s 64
f 19979 19983 19981 
f 19981 19977 19979 
s 2
f 19985 19986 19988 
f 19988 19987 19985 
s 4
f 19989 19991 19992 
f 19992 19990 19989 
s 8
f 19985 19989 19990 
f 19990 19986 19985 
s 16
f 19986 19990 19992 
f 19992 19988 19986 
s 32
f 19988 19992 19991 
f 19991 19987 19988 
s 64
f 19987 19991 19989 
f 19989 19985 19987 
s 2
f 19993 19994 19996 
f 19996 19995 19993 
s 4
f 19997 19999 20000 
f 20000 19998 19997 
s 8
f 19993 19997 19998 
f 19998 19994 19993 
s 16
f 19994 19998 20000 
f 20000 19996 19994 
s 32
f 19996 20000 19999 
f 19999 19995 19996 
s 64
f 19995 19999 19997 
f 19997 19993 19995 
s 2
f 20001 20002 20004 
f 20004 20003 20001 
s 4
f 20005 20007 20008 
f 20008 20006 20005 
s 8
f 20001 20005 20006 
f 20006 20002 20001 
s 16
f 20002 20006 20008 
f 20008 20004 20002 
s 32
f 20004 20008 20007 
f 20007 20003 20004 
s 64
f 20003 20007 20005 
f 20005 20001 20003 
s 2
f 20009 20010 20012 
f 20012 20011 20009 
s 4
f 20013 20015 20016 
f 20016 20014 20013 
s 8
f 20009 20013 20014 
f 20014 20010 20009 
s 16
f 20010 20014 20016 
f 20016 20012 20010 
s 32
f 20012 20016 20015 
f 20015 20011 20012 
s 64
f 20011 20015 20013 
f 20013 20009 20011 
s 1
f 20018 20133 20017 
f 20128 20130 20019 
f 20020 20132 20022 
f 20021 20135 20127 
f 20023 20129 20025 
f 20024 20134 20125 
f 20027 20131 20026 
f 20126 20136 20028 
f 20029 20140 20031 
f 20030 20139 20123 
f 20033 20141 20373 
f 20124 20137 20034 
f 20371 20138 20037 
f 20369 20142 20122 
f 20039 20144 20372 
f 20121 20143 20370 
f 20042 20148 20361 
f 20117 20147 20367 
f 20362 20146 20046 
f 20368 20152 20118 
f 20048 20149 20364 
f 20119 20150 20365 
f 20363 20145 20052 
f 20366 20151 20120 
f 20291 20155 20353 
f 20293 20157 20358 
f 20354 20154 20292 
f 20359 20158 20294 
f 20355 20156 20290 
f 20360 20160 20295 
f 20289 20153 20357 
f 20296 20159 20356 
f 20263 20181 20067 
f 20257 20180 20109 
f 20112 20182 20264 
f 20069 20184 20258 
f 20261 20178 20073 
f 20260 20183 20111 
f 20110 20179 20262 
f 20075 20177 20259 
f 20254 20170 20107 
f 20253 20175 20079 
f 20081 20176 20249 
f 20105 20169 20250 
f 20256 20172 20085 
f 20252 20173 20106 
f 20108 20174 20255 
f 20087 20171 20251 
f 20104 20164 20247 
f 20090 20168 20243 
f 20248 20162 20103 
f 20241 20167 20094 
f 20096 20161 20245 
f 20101 20165 20244 
f 20242 20166 20100 
f 20246 20163 20102 
f 20095 20211 20267 
f 20097 20216 20268 
f 20269 20212 20098 
f 20270 20214 20099 
f 20271 20215 20092 
f 20266 20213 20093 
f 20091 20210 20265 
f 20089 20209 20272 
f 20080 20220 20276 
f 20082 20224 20280 
f 20274 20219 20083 
f 20279 20222 20084 
f 20277 20221 20078 
f 20273 20218 20077 
f 20086 20217 20275 
f 20088 20223 20278 
f 20283 20226 20065 
f 20287 20232 20066 
f 20074 20229 20282 
f 20076 20231 20285 
f 20284 20225 20071 
f 20286 20228 20072 
f 20070 20230 20281 
f 20068 20227 20288 
f 20292 20233 20056 
f 20294 20238 20057 
f 20055 20234 20291 
f 20053 20239 20293 
f 20290 20237 20060 
f 20295 20236 20059 
f 20064 20240 20289 
f 20062 20235 20296 
f 20343 20297 20191 
f 20344 20298 20185 
f 20190 20301 20340 
f 20186 20300 20342 
f 20341 20304 20189 
f 20338 20302 20188 
f 20192 20303 20339 
f 20187 20299 20337 
f 20336 20311 20195 
f 20333 20306 20193 
f 20198 20312 20335 
f 20194 20305 20334 
f 20199 20309 20331 
f 20197 20310 20329 
f 20332 20307 20200 
f 20330 20308 20196 
f 20207 20317 20325 
f 20202 20313 20327 
f 20324 20315 20208 
f 20322 20316 20206 
f 20205 20320 20323 
f 20201 20314 20326 
f 20328 20319 20204 
f 20321 20318 20203 
s 2
f 20129 20023 20024 
s 1
f 20325 20024 20207 
s 2
f 20019 20321 20017 
s 1
f 20203 20017 20321 
s 2
f 20028 20026 20131 
s 1
f 20208 20028 20324 
s 2
f 20132 20020 20021 
s 1
f 20323 20021 20205 
s 2
f 20019 20017 20133 
s 1
f 20204 20019 20328 
s 2
f 20023 20327 20024 
s 1
f 20327 20023 20202 
s 2
f 20020 20326 20021 
s 1
f 20326 20020 20201 
f 20206 20026 20322 
s 2
f 20028 20322 20026 
f 20034 20330 20032 
s 1
f 20196 20032 20330 
s 2
f 20138 20371 20036 
s 1
f 20335 20036 20198 
f 20329 20029 20197 
s 2
f 20029 20329 20030 
f 20140 20029 20030 
s 1
f 20331 20030 20199 
s 2
f 20034 20373 20141 
s 1
f 20200 20034 20332 
s 2
f 20035 20334 20036 
s 1
f 20334 20035 20194 
f 20193 20038 20333 
s 2
f 20040 20333 20038 
f 20040 20372 20144 
s 1
f 20195 20040 20336 
s 2
f 20145 20363 20051 
s 1
f 20339 20051 20192 
s 2
f 20146 20362 20045 
s 1
f 20340 20045 20190 
f 20185 20041 20344 
s 2
f 20043 20344 20041 
f 20043 20361 20148 
s 1
f 20191 20043 20343 
s 2
f 20049 20364 20149 
s 1
f 20189 20049 20341 
s 2
f 20049 20338 20047 
s 1
f 20188 20047 20338 
f 20337 20050 20187 
s 2
f 20050 20337 20051 
f 20044 20342 20045 
s 1
f 20342 20044 20186 
s 2
f 20062 20357 20153 
s 1
f 20235 20062 20347 
s 2
f 20154 20354 20057 
s 1
f 20349 20057 20238 
s 2
f 20053 20353 20155 
s 1
f 20239 20053 20348 
s 2
f 20156 20355 20059 
s 1
f 20352 20059 20236 
s 2
f 20053 20351 20055 
s 1
f 20234 20055 20351 
f 20346 20056 20233 
s 2
f 20056 20346 20057 
s 1
f 20240 20064 20350 
s 2
f 20062 20350 20064 
f 20060 20345 20059 
s 1
f 20345 20060 20237 
s 2
f 20097 20245 20161 
s 1
f 20216 20097 20161 
s 2
f 20162 20248 20092 
s 1
f 20162 20092 20215 
s 2
f 20163 20246 20099 
s 1
f 20163 20099 20214 
s 2
f 20089 20247 20164 
s 1
f 20209 20089 20164 
f 20211 20095 20165 
s 2
f 20097 20165 20095 
f 20098 20166 20099 
s 1
f 20166 20098 20212 
f 20167 20093 20213 
s 2
f 20093 20167 20092 
s 1
f 20210 20091 20168 
s 2
f 20089 20168 20091 
s 1
f 20220 20080 20169 
s 2
f 20082 20169 20080 
f 20170 20254 20078 
s 1
f 20170 20078 20221 
s 2
f 20088 20251 20171 
s 1
f 20223 20088 20171 
s 2
f 20083 20172 20084 
s 1
f 20172 20083 20219 
s 2
f 20173 20252 20084 
s 1
f 20173 20084 20222 
s 2
f 20088 20174 20086 
s 1
f 20217 20086 20174 
f 20175 20077 20218 
s 2
f 20077 20175 20078 
f 20082 20249 20176 
s 1
f 20224 20082 20176 
s 2
f 20076 20259 20177 
s 1
f 20231 20076 20177 
s 2
f 20178 20261 20072 
s 1
f 20178 20072 20228 
s 2
f 20076 20179 20074 
s 1
f 20229 20074 20179 
s 2
f 20065 20180 20066 
s 1
f 20180 20065 20226 
s 2
f 20181 20263 20066 
s 1
f 20181 20066 20232 
s 2
f 20068 20264 20182 
s 1
f 20227 20068 20182 
f 20183 20071 20225 
s 2
f 20071 20183 20072 
s 1
f 20230 20070 20184 
s 2
f 20068 20184 20070 
f 20042 20185 20191 
s 1
f 20041 20185 20042 
f 20046 20186 20044 
s 2
f 20190 20186 20046 
s 1
f 20052 20187 20050 
s 2
f 20192 20187 20052 
f 20048 20188 20189 
s 1
f 20047 20188 20048 
f 20049 20189 20119 
s 2
f 20188 20302 20189 
s 1
f 20118 20190 20045 
s 2
f 20190 20300 20186 
s 1
f 20043 20191 20117 
s 2
f 20185 20298 20191 
s 1
f 20120 20192 20051 
s 2
f 20192 20299 20187 
f 20039 20193 20195 
s 1
f 20038 20193 20039 
f 20037 20194 20035 
s 2
f 20198 20194 20037 
s 1
f 20040 20195 20121 
s 2
f 20193 20306 20195 
f 20033 20196 20200 
s 1
f 20032 20196 20033 
f 20031 20197 20029 
s 2
f 20199 20197 20031 
s 1
f 20122 20198 20036 
s 2
f 20198 20305 20194 
s 1
f 20123 20199 20030 
s 2
f 20199 20310 20197 
s 1
f 20034 20200 20124 
s 2
f 20196 20308 20200 
s 1
f 20022 20201 20020 
s 2
f 20205 20201 20022 
s 1
f 20025 20202 20023 
s 2
f 20207 20202 20025 
f 20018 20203 20204 
s 1
f 20017 20203 20018 
f 20019 20204 20128 
s 2
f 20203 20318 20204 
s 1
f 20127 20205 20021 
s 2
f 20205 20314 20201 
f 20027 20206 20208 
s 1
f 20026 20206 20027 
f 20125 20207 20024 
s 2
f 20207 20313 20202 
s 1
f 20028 20208 20126 
s 2
f 20206 20316 20208 
f 20265 20210 20209 
s 1
f 20164 20104 20209 
s 2
f 20210 20090 20209 
s 1
f 20168 20090 20210 
s 2
f 20211 20101 20216 
s 1
f 20165 20101 20211 
f 20212 20100 20166 
s 2
f 20214 20100 20212 
s 1
f 20213 20094 20167 
s 2
f 20215 20094 20213 
f 20214 20212 20269 
s 1
f 20214 20102 20163 
s 2
f 20215 20213 20266 
s 1
f 20215 20103 20162 
s 2
f 20267 20211 20216 
s 1
f 20161 20096 20216 
s 2
f 20217 20108 20223 
s 1
f 20174 20108 20217 
f 20218 20079 20175 
s 2
f 20221 20079 20218 
s 1
f 20219 20085 20172 
s 2
f 20222 20085 20219 
f 20220 20105 20224 
s 1
f 20169 20105 20220 
s 2
f 20221 20218 20273 
s 1
f 20221 20107 20170 
s 2
f 20222 20219 20274 
s 1
f 20222 20106 20173 
s 2
f 20275 20217 20223 
s 1
f 20171 20087 20223 
s 2
f 20276 20220 20224 
s 1
f 20176 20081 20224 
f 20225 20111 20183 
s 2
f 20228 20111 20225 
s 1
f 20226 20109 20180 
s 2
f 20232 20109 20226 
f 20281 20230 20227 
s 1
f 20182 20112 20227 
s 2
f 20228 20225 20284 
s 1
f 20228 20073 20178 
s 2
f 20229 20110 20231 
s 1
f 20179 20110 20229 
s 2
f 20230 20069 20227 
s 1
f 20184 20069 20230 
s 2
f 20282 20229 20231 
s 1
f 20177 20075 20231 
s 2
f 20232 20226 20283 
s 1
f 20232 20067 20181 
f 20233 20058 20346 
s 2
f 20238 20058 20233 
f 20234 20054 20239 
s 1
f 20351 20054 20234 
s 2
f 20289 20240 20235 
s 1
f 20347 20116 20235 
s 2
f 20236 20237 20290 
s 1
f 20236 20115 20352 
f 20237 20061 20345 
s 2
f 20236 20061 20237 
f 20238 20233 20292 
s 1
f 20238 20113 20349 
s 2
f 20291 20234 20239 
s 1
f 20348 20114 20239 
s 2
f 20240 20063 20235 
s 1
f 20350 20063 20240 
s 2
f 20167 20241 20092 
s 1
f 20092 20241 20271 
s 2
f 20166 20242 20099 
s 1
f 20099 20242 20270 
s 2
f 20089 20243 20168 
s 1
f 20272 20243 20089 
s 2
f 20097 20244 20165 
s 1
f 20268 20244 20097 
f 20267 20245 20095 
s 2
f 20097 20095 20245 
s 1
f 20098 20246 20269 
s 2
f 20246 20098 20099 
s 1
f 20265 20247 20091 
s 2
f 20089 20091 20247 
s 1
f 20093 20248 20266 
s 2
f 20248 20093 20092 
s 1
f 20276 20249 20080 
s 2
f 20082 20080 20249 
f 20082 20250 20169 
s 1
f 20280 20250 20082 
f 20275 20251 20086 
s 2
f 20088 20086 20251 
s 1
f 20083 20252 20274 
s 2
f 20252 20083 20084 
f 20175 20253 20078 
s 1
f 20078 20253 20277 
f 20077 20254 20273 
s 2
f 20254 20077 20078 
f 20088 20255 20174 
s 1
f 20278 20255 20088 
s 2
f 20172 20256 20084 
s 1
f 20084 20256 20279 
s 2
f 20180 20257 20066 
s 1
f 20066 20257 20287 
s 2
f 20068 20258 20184 
s 1
f 20288 20258 20068 
f 20282 20259 20074 
s 2
f 20076 20074 20259 
f 20183 20260 20072 
s 1
f 20072 20260 20286 
f 20071 20261 20284 
s 2
f 20261 20071 20072 
f 20076 20262 20179 
s 1
f 20285 20262 20076 
f 20065 20263 20283 
s 2
f 20263 20065 20066 
s 1
f 20281 20264 20070 
s 2
f 20068 20070 20264 
s 1
f 20247 20265 20104 
s 2
f 20104 20265 20209 
s 1
f 20103 20266 20248 
s 2
f 20215 20266 20103 
s 1
f 20245 20267 20096 
s 2
f 20096 20267 20216 
f 20101 20268 20216 
s 1
f 20244 20268 20101 
f 20102 20269 20246 
s 2
f 20214 20269 20102 
s 1
f 20100 20270 20242 
s 2
f 20214 20270 20100 
s 1
f 20094 20271 20241 
s 2
f 20215 20271 20094 
f 20090 20272 20209 
s 1
f 20243 20272 20090 
f 20107 20273 20254 
s 2
f 20221 20273 20107 
f 20222 20274 20106 
s 1
f 20106 20274 20252 
f 20251 20275 20087 
s 2
f 20087 20275 20223 
s 1
f 20249 20276 20081 
s 2
f 20081 20276 20224 
s 1
f 20079 20277 20253 
s 2
f 20221 20277 20079 
f 20108 20278 20223 
s 1
f 20255 20278 20108 
f 20085 20279 20256 
s 2
f 20222 20279 20085 
f 20105 20280 20224 
s 1
f 20250 20280 20105 
f 20264 20281 20112 
s 2
f 20112 20281 20227 
s 1
f 20259 20282 20075 
s 2
f 20075 20282 20231 
f 20232 20283 20067 
s 1
f 20067 20283 20263 
f 20073 20284 20261 
s 2
f 20228 20284 20073 
f 20110 20285 20231 
s 1
f 20262 20285 20110 
f 20111 20286 20260 
s 2
f 20228 20286 20111 
s 1
f 20109 20287 20257 
s 2
f 20232 20287 20109 
s 1
f 20258 20288 20069 
s 2
f 20069 20288 20227 
s 1
f 20153 20289 20116 
s 2
f 20116 20289 20235 
f 20236 20290 20115 
s 1
f 20115 20290 20156 
f 20155 20291 20114 
s 2
f 20114 20291 20239 
s 1
f 20113 20292 20154 
s 2
f 20238 20292 20113 
f 20054 20293 20239 
s 1
f 20157 20293 20054 
f 20058 20294 20158 
s 2
f 20238 20294 20058 
f 20236 20295 20061 
s 1
f 20061 20295 20160 
f 20159 20296 20063 
s 2
f 20063 20296 20235 
f 20297 20042 20191 
s 1
f 20148 20042 20297 
f 20147 20117 20298 
s 2
f 20298 20117 20191 
s 1
f 20299 20120 20151 
s 2
f 20192 20120 20299 
s 1
f 20300 20118 20152 
s 2
f 20190 20118 20300 
f 20190 20046 20301 
s 1
f 20301 20046 20146 
s 2
f 20302 20119 20189 
s 1
f 20150 20119 20302 
f 20303 20052 20145 
s 2
f 20192 20052 20303 
s 1
f 20149 20048 20304 
s 2
f 20304 20048 20189 
s 1
f 20305 20122 20142 
s 2
f 20198 20122 20305 
s 1
f 20143 20121 20306 
s 2
f 20306 20121 20195 
s 1
f 20141 20033 20307 
s 2
f 20307 20033 20200 
f 20308 20124 20200 
s 1
f 20137 20124 20308 
f 20309 20031 20140 
s 2
f 20199 20031 20309 
s 1
f 20310 20123 20139 
s 2
f 20199 20123 20310 
f 20311 20039 20195 
s 1
f 20144 20039 20311 
s 2
f 20198 20037 20312 
s 1
f 20312 20037 20138 
f 20313 20125 20134 
s 2
f 20207 20125 20313 
s 1
f 20314 20127 20135 
s 2
f 20205 20127 20314 
s 1
f 20131 20027 20315 
s 2
f 20315 20027 20208 
f 20316 20126 20208 
s 1
f 20136 20126 20316 
s 2
f 20207 20025 20317 
s 1
f 20317 20025 20129 
s 2
f 20318 20128 20204 
s 1
f 20130 20128 20318 
f 20133 20018 20319 
s 2
f 20319 20018 20204 
s 1
f 20320 20022 20132 
s 2
f 20205 20022 20320 
f 20019 20130 20321 
s 1
f 20318 20321 20130 
s 2
f 20028 20136 20322 
s 1
f 20316 20322 20136 
s 2
f 20323 20132 20021 
s 1
f 20132 20323 20320 
s 2
f 20324 20028 20131 
s 1
f 20315 20324 20131 
s 2
f 20325 20129 20024 
s 1
f 20129 20325 20317 
s 2
f 20326 20135 20021 
s 1
f 20135 20326 20314 
s 2
f 20327 20134 20024 
s 1
f 20134 20327 20313 
s 2
f 20328 20019 20133 
s 1
f 20319 20328 20133 
s 2
f 20329 20139 20030 
s 1
f 20139 20329 20310 
s 2
f 20034 20137 20330 
s 1
f 20308 20330 20137 
s 2
f 20331 20140 20030 
s 1
f 20140 20331 20309 
s 2
f 20332 20034 20141 
s 1
f 20307 20332 20141 
s 2
f 20040 20143 20333 
s 1
f 20306 20333 20143 
s 2
f 20334 20142 20036 
s 1
f 20142 20334 20305 
s 2
f 20335 20138 20036 
s 1
f 20138 20335 20312 
s 2
f 20336 20040 20144 
s 1
f 20311 20336 20144 
s 2
f 20337 20151 20051 
s 1
f 20151 20337 20299 
s 2
f 20049 20150 20338 
s 1
f 20302 20338 20150 
s 2
f 20339 20145 20051 
s 1
f 20145 20339 20303 
s 2
f 20340 20146 20045 
s 1
f 20146 20340 20301 
s 2
f 20341 20049 20149 
s 1
f 20304 20341 20149 
s 2
f 20342 20152 20045 
s 1
f 20152 20342 20300 
s 2
f 20343 20043 20148 
s 1
f 20297 20343 20148 
s 2
f 20043 20147 20344 
s 1
f 20298 20344 20147 
s 2
f 20345 20160 20059 
s 1
f 20160 20345 20061 
s 2
f 20346 20158 20057 
s 1
f 20158 20346 20058 
s 2
f 20347 20062 20153 
s 1
f 20116 20347 20153 
s 2
f 20348 20053 20155 
s 1
f 20114 20348 20155 
s 2
f 20349 20154 20057 
s 1
f 20154 20349 20113 
s 2
f 20062 20159 20350 
s 1
f 20063 20350 20159 
s 2
f 20053 20157 20351 
s 1
f 20054 20351 20157 
s 2
f 20352 20156 20059 
s 1
f 20156 20352 20115 
f 20291 20353 20055 
s 2
f 20053 20055 20353 
s 1
f 20056 20354 20292 
s 2
f 20354 20056 20057 
s 1
f 20060 20355 20290 
s 2
f 20355 20060 20059 
s 1
f 20296 20356 20062 
s 2
f 20062 20356 20159 
s 1
f 20289 20357 20064 
s 2
f 20062 20064 20357 
s 1
f 20293 20358 20053 
s 2
f 20053 20358 20157 
s 1
f 20057 20359 20294 
s 2
f 20158 20359 20057 
s 1
f 20059 20360 20295 
s 2
f 20160 20360 20059 
s 1
f 20042 20361 20041 
s 2
f 20043 20041 20361 
s 1
f 20044 20362 20046 
s 2
f 20362 20044 20045 
s 1
f 20050 20363 20052 
s 2
f 20363 20050 20051 
s 1
f 20048 20364 20047 
s 2
f 20049 20047 20364 
s 1
f 20119 20365 20049 
s 2
f 20049 20365 20150 
s 1
f 20051 20366 20120 
s 2
f 20151 20366 20051 
s 1
f 20117 20367 20043 
s 2
f 20043 20367 20147 
s 1
f 20045 20368 20118 
s 2
f 20152 20368 20045 
s 1
f 20036 20369 20122 
s 2
f 20142 20369 20036 
s 1
f 20121 20370 20040 
s 2
f 20040 20370 20143 
s 1
f 20035 20371 20037 
s 2
f 20371 20035 20036 
s 1
f 20039 20372 20038 
s 2
f 20040 20038 20372 
s 1
f 20033 20373 20032 
s 2
f 20034 20032 20373 
f 20374 20397 20396 
f 20382 20399 20398 
f 20388 20394 20393 
s 4
f 20374 20381 20377 
f 20377 20385 20374 
f 20376 20384 20380 
f 20380 20405 20376 
f 20412 20383 20379 
f 20379 20387 20412 
f 20382 20375 20378 
f 20378 20386 20382 
s off
f 20410 20396 20397 
f 20385 20377 20409 
f 20400 20391 20392 
f 20387 20379 20425 
f 20425 20398 20399 
f 20386 20378 20408 
f 20416 20393 20394 
f 20384 20376 20410 
s 2
f 20412 20387 20392 
f 20377 20381 20389 
f 20389 20391 20377 
f 20380 20384 20394 
f 20394 20388 20380 
f 20378 20375 20388 
f 20388 20393 20378 
s off
f 20392 20387 20425 
f 20409 20377 20391 
f 20394 20384 20410 
f 20408 20378 20393 
s 2
f 20374 20385 20397 
f 20405 20396 20376 
f 20382 20386 20399 
f 20379 20383 20382 
f 20382 20398 20379 
s off
f 20397 20385 20409 
f 20410 20376 20396 
f 20399 20386 20408 
f 20425 20379 20398 
f 20408 20425 20399 
f 20409 20410 20397 
f 20422 20423 20407 
f 20423 20422 20402 
f 20391 20400 20409 
s 1
f 20425 20403 20401 
s off
f 20395 20415 20406 
s 1
f 20410 20401 20415 
s off
f 20403 20416 20404 
f 20393 20416 20408 
s 2
f 20396 20405 20374 
s off
f 20406 20414 20395 
f 20404 20413 20403 
f 20402 20407 20423 
f 20407 20417 20422 
s 1
f 20425 20408 20403 
f 20408 20416 20403 
f 20410 20409 20401 
f 20424 20418 20419 
s off
f 20410 20395 20394 
s 1
f 20415 20395 20410 
f 20420 20421 20411 
s off
f 20425 20400 20392 
s 2
f 20392 20390 20412 
s 1
f 20406 20413 20404 
s off
f 20413 20406 20415 
f 20414 20404 20416 
s 1
f 20404 20414 20406 
s off
f 20415 20403 20413 
s 1
f 20403 20415 20401 
s off
f 20394 20395 20416 
f 20416 20395 20414 
f 20417 20402 20422 
s 1
f 20402 20417 20407 
s 2
f 20437 20428 20476 
f 20435 20434 20438 
f 20438 20431 20435 
f 20457 20463 20462 
s 4
f 20426 20433 20429 
f 20429 20437 20426 
f 20428 20436 20432 
f 20432 20476 20428 
f 20478 20435 20431 
f 20431 20439 20478 
f 20434 20427 20430 
f 20430 20438 20434 
s off
f 20428 20437 20479 
f 20437 20429 20471 
f 20471 20460 20461 
f 20439 20431 20483 
f 20431 20438 20482 
f 20438 20430 20440 
f 20440 20462 20463 
f 20436 20428 20474 
s 2
f 20453 20444 20477 
f 20443 20467 20466 
f 20451 20450 20454 
f 20454 20447 20451 
s 4
f 20441 20449 20445 
f 20445 20453 20441 
f 20444 20452 20448 
f 20448 20477 20444 
f 20443 20451 20447 
f 20447 20455 20443 
f 20450 20442 20446 
f 20446 20454 20450 
s off
f 20444 20453 20480 
f 20453 20445 20470 
f 20470 20466 20467 
f 20455 20447 20472 
f 20447 20454 20481 
f 20454 20446 20456 
f 20456 20468 20469 
f 20452 20444 20473 
s 2
f 20478 20439 20461 
f 20429 20433 20458 
f 20458 20460 20429 
f 20432 20436 20463 
f 20463 20457 20432 
f 20430 20427 20457 
f 20457 20462 20430 
s off
f 20461 20439 20475 
f 20471 20429 20460 
f 20463 20436 20474 
f 20440 20430 20462 
s 2
f 20443 20455 20467 
f 20445 20449 20443 
f 20443 20466 20445 
f 20448 20452 20469 
f 20469 20465 20448 
f 20446 20442 20464 
f 20464 20468 20446 
s off
f 20467 20455 20472 
f 20470 20445 20466 
f 20469 20452 20473 
f 20456 20446 20468 
s 4
f 20459 20461 20468 
f 20468 20464 20459 
s 1
f 20461 20460 20469 
f 20469 20468 20461 
s 4
f 20460 20458 20465 
f 20465 20469 20460 
s off
f 20480 20473 20444 
f 20481 20472 20447 
f 20482 20483 20431 
f 20479 20474 20428 
f 20467 20472 20470 
s 1
f 20456 20470 20472 
s off
f 20473 20456 20469 
s 1
f 20456 20473 20470 
s off
f 20463 20474 20440 
s 1
f 20471 20440 20474 
s off
f 20475 20471 20461 
s 1
f 20471 20475 20440 
s 2
f 20476 20426 20437 
f 20477 20441 20453 
f 20461 20459 20478 
s 1
f 20474 20479 20471 
s off
f 20471 20479 20437 
s 1
f 20473 20480 20470 
s off
f 20470 20480 20453 
f 20456 20481 20454 
s 1
f 20472 20481 20456 
f 20475 20482 20440 
s off
f 20440 20482 20438 
f 20483 20475 20439 
s 1
f 20475 20483 20482 
f 20551 20541 20506 
s 4
f 20585 20594 20575 
s 1
f 20550 20540 20507 
s 2
f 20584 20595 20574 
f 20590 20615 20613 
f 20635 20525 20616 
s 4
f 20591 20614 20612 
f 20634 20524 20617 
s 1
f 20542 20532 20567 
s 4
f 20492 20514 20516 
s 1
f 20543 20533 20566 
s 8
f 20493 20515 20517 
s 1
f 20536 20587 20576 
s 4
f 20554 20565 20490 
s 1
f 20537 20586 20577 
s 2
f 20555 20564 20491 
s 4
f 20516 20560 20492 
s 1
f 20506 20560 20516 
f 20561 20517 20507 
s 8
f 20517 20561 20493 
s 4
f 20528 20489 20501 
s 1
f 20543 20489 20528 
s 2
f 20529 20486 20500 
s 1
f 20542 20486 20529 
s 2
f 20500 20523 20529 
s 1
f 20523 20500 20506 
f 20522 20501 20507 
s 4
f 20501 20522 20528 
s 1
f 20615 20590 20535 
s 4
f 20547 20536 20535 
s 1
f 20614 20591 20534 
s 2
f 20546 20537 20534 
s 4
f 20506 20541 20532 
f 20588 20599 20568 
s 2
f 20507 20540 20533 
f 20589 20598 20569 
s 4
f 20532 20542 20506 
s 2
f 20544 20506 20542 
s 4
f 20545 20507 20543 
s 2
f 20533 20543 20507 
s 1
f 20520 20534 20555 
s 4
f 20650 20629 20608 
s 2
f 20651 20628 20609 
s 1
f 20521 20535 20554 
s 4
f 20577 20586 20578 
s 2
f 20534 20552 20546 
f 20576 20587 20579 
s 4
f 20535 20553 20547 
s 1
f 20500 20519 20605 
f 20500 20605 20506 
f 20501 20604 20507 
f 20501 20518 20604 
s 2
f 20641 20538 20571 
s 4
f 20499 20529 20523 
s 2
f 20498 20528 20522 
f 20640 20539 20570 
s 4
f 20523 20484 20499 
s 8
f 20484 20571 20538 
s 2
f 20522 20487 20498 
s 4
f 20487 20570 20539 
f 20490 20518 20554 
s 1
f 20518 20536 20513 
f 20519 20537 20512 
s 2
f 20491 20519 20555 
s 1
f 20542 20596 20486 
f 20596 20555 20486 
f 20597 20554 20489 
f 20543 20597 20489 
f 20517 20487 20522 
f 20517 20522 20507 
f 20484 20523 20516 
f 20523 20506 20516 
s 2
f 20524 20573 20526 
s 1
f 20500 20524 20634 
s 4
f 20525 20572 20527 
s 1
f 20501 20525 20635 
s 2
f 20526 20508 20524 
s 1
f 20508 20526 20611 
f 20509 20527 20610 
s 4
f 20527 20509 20525 
s 1
f 20543 20528 20515 
f 20528 20498 20515 
f 20499 20514 20529 
f 20514 20542 20529 
s 4
f 20652 20559 20496 
s 2
f 20542 20530 20544 
s 4
f 20543 20531 20545 
s 2
f 20653 20558 20497 
s 1
f 20532 20594 20585 
f 20533 20595 20584 
f 20534 20564 20555 
s 2
f 20495 20557 20549 
s 4
f 20494 20556 20548 
s 1
f 20535 20565 20554 
s 2
f 20536 20548 20556 
s 1
f 20518 20490 20536 
s 4
f 20537 20549 20557 
s 1
f 20519 20491 20537 
f 20499 20538 20641 
s 8
f 20538 20499 20484 
s 1
f 20498 20539 20640 
s 4
f 20539 20498 20487 
s 1
f 20598 20589 20540 
f 20599 20588 20541 
f 20514 20492 20542 
f 20559 20652 20542 
f 20558 20653 20543 
f 20493 20543 20515 
f 20506 20654 20496 
s 4
f 20496 20654 20652 
s 2
f 20497 20655 20653 
s 1
f 20507 20655 20497 
f 20537 20546 20510 
s 2
f 20510 20546 20552 
s 1
f 20536 20547 20511 
s 4
f 20511 20547 20553 
s 1
f 20563 20548 20536 
s 4
f 20548 20563 20494 
s 2
f 20549 20562 20495 
s 1
f 20562 20549 20537 
f 20604 20550 20507 
s 2
f 20550 20604 20597 
s 4
f 20551 20605 20596 
s 1
f 20605 20551 20506 
s 2
f 20552 20503 20510 
s 1
f 20503 20552 20534 
f 20502 20553 20535 
s 4
f 20553 20502 20511 
s 2
f 20633 20554 20619 
s 1
f 20554 20616 20489 
f 20555 20617 20486 
s 4
f 20632 20555 20618 
s 1
f 20535 20556 20494 
s 2
f 20556 20535 20536 
s 4
f 20557 20534 20537 
s 1
f 20534 20557 20495 
f 20493 20647 20543 
s 4
f 20647 20493 20645 
s 1
f 20492 20646 20542 
s 2
f 20646 20492 20644 
f 20644 20496 20646 
s 1
f 20506 20496 20644 
f 20507 20497 20645 
s 4
f 20645 20497 20647 
s 1
f 20491 20636 20537 
s 4
f 20636 20491 20620 
s 2
f 20639 20490 20621 
s 1
f 20490 20639 20536 
s 4
f 20637 20495 20624 
s 1
f 20534 20495 20620 
f 20535 20494 20621 
s 2
f 20638 20494 20625 
s 1
f 20543 20566 20597 
s 2
f 20597 20566 20550 
s 1
f 20542 20567 20596 
s 4
f 20596 20567 20551 
f 20600 20622 20627 
s 1
f 20532 20622 20600 
f 20533 20623 20601 
s 2
f 20601 20623 20626 
f 20643 20517 20640 
s 1
f 20517 20643 20487 
f 20484 20516 20642 
s 2
f 20642 20516 20641 
s 1
f 20631 20572 20525 
s 2
f 20649 20631 20610 
s 1
f 20630 20573 20524 
s 4
f 20648 20630 20611 
s 1
f 20592 20574 20540 
s 2
f 20574 20592 20584 
s 4
f 20575 20593 20585 
s 1
f 20593 20575 20541 
s 2
f 20606 20613 20615 
s 1
f 20536 20613 20606 
s 4
f 20607 20612 20614 
s 1
f 20537 20612 20607 
s 4
f 20503 20578 20586 
s 1
f 20578 20503 20534 
s 2
f 20502 20579 20587 
s 1
f 20579 20502 20535 
f 20602 20580 20534 
s 4
f 20580 20602 20583 
s 2
f 20581 20603 20582 
s 1
f 20603 20581 20535 
f 20536 20582 20513 
s 2
f 20513 20582 20603 
s 1
f 20537 20583 20512 
s 4
f 20512 20583 20602 
s 2
f 20566 20584 20592 
s 1
f 20533 20584 20566 
f 20532 20585 20567 
s 4
f 20567 20585 20593 
f 20586 20510 20503 
s 1
f 20537 20510 20586 
s 2
f 20587 20511 20502 
s 1
f 20536 20511 20587 
f 20627 20504 20541 
s 4
f 20504 20627 20622 
s 1
f 20626 20505 20540 
s 2
f 20505 20626 20623 
f 20579 20590 20576 
s 1
f 20590 20579 20535 
f 20591 20578 20534 
s 4
f 20578 20591 20577 
s 1
f 20550 20592 20540 
s 2
f 20592 20550 20566 
s 1
f 20551 20593 20541 
s 4
f 20593 20551 20567 
f 20594 20568 20599 
s 1
f 20532 20568 20594 
s 2
f 20595 20569 20598 
s 1
f 20533 20569 20595 
s 4
f 20520 20596 20605 
s 1
f 20596 20520 20555 
f 20597 20521 20554 
s 2
f 20521 20597 20604 
f 20598 20574 20595 
s 1
f 20574 20598 20540 
f 20575 20599 20541 
s 4
f 20599 20575 20594 
f 20568 20600 20588 
s 1
f 20532 20600 20568 
f 20533 20601 20569 
s 2
f 20569 20601 20589 
s 4
f 20602 20520 20512 
s 1
f 20520 20602 20534 
f 20521 20603 20535 
s 2
f 20603 20521 20513 
s 1
f 20518 20513 20604 
s 2
f 20604 20513 20521 
s 4
f 20605 20512 20520 
s 1
f 20519 20512 20605 
f 20536 20606 20582 
s 2
f 20582 20606 20581 
s 4
f 20583 20607 20580 
s 1
f 20537 20607 20583 
s 4
f 20573 20608 20629 
s 1
f 20630 20648 20573 
f 20631 20649 20572 
s 2
f 20572 20609 20628 
f 20489 20610 20631 
s 1
f 20509 20610 20489 
f 20508 20611 20486 
s 4
f 20486 20611 20630 
s 1
f 20537 20577 20612 
s 4
f 20612 20577 20591 
s 2
f 20613 20576 20590 
s 1
f 20536 20576 20613 
s 4
f 20614 20580 20607 
s 1
f 20580 20614 20534 
s 2
f 20615 20581 20606 
s 1
f 20581 20615 20535 
f 20616 20509 20489 
s 2
f 20509 20616 20525 
s 4
f 20508 20617 20524 
s 1
f 20617 20508 20486 
f 20500 20634 20519 
s 4
f 20519 20618 20555 
s 2
f 20518 20619 20554 
s 1
f 20501 20635 20518 
f 20534 20620 20564 
s 4
f 20564 20620 20491 
s 2
f 20565 20621 20490 
s 1
f 20535 20621 20565 
f 20532 20485 20622 
s 4
f 20622 20485 20504 
s 2
f 20623 20488 20505 
s 1
f 20533 20488 20623 
f 20636 20562 20537 
s 4
f 20562 20624 20495 
s 1
f 20639 20563 20536 
s 2
f 20563 20625 20494 
s 1
f 20589 20626 20540 
s 2
f 20626 20589 20601 
s 4
f 20627 20588 20600 
s 1
f 20588 20627 20541 
f 20527 20628 20651 
s 2
f 20628 20527 20572 
s 4
f 20629 20526 20573 
s 1
f 20526 20629 20650 
f 20500 20630 20524 
s 4
f 20630 20500 20486 
s 1
f 20501 20631 20525 
s 2
f 20631 20501 20489 
s 4
f 20617 20632 20634 
s 1
f 20555 20632 20617 
s 2
f 20616 20633 20635 
s 1
f 20554 20633 20616 
s 4
f 20618 20634 20632 
s 1
f 20634 20618 20519 
s 2
f 20619 20635 20633 
s 1
f 20635 20619 20518 
s 4
f 20624 20636 20637 
s 1
f 20636 20624 20562 
f 20495 20637 20620 
s 4
f 20620 20637 20636 
s 1
f 20494 20638 20621 
s 2
f 20621 20638 20639 
s 1
f 20639 20625 20563 
s 2
f 20625 20639 20638 
s 1
f 20498 20640 20515 
s 2
f 20515 20640 20517 
s 1
f 20499 20641 20514 
s 2
f 20514 20641 20516 
s 1
f 20484 20642 20571 
s 2
f 20571 20642 20641 
f 20570 20643 20640 
s 1
f 20643 20570 20487 
s 2
f 20560 20644 20492 
s 1
f 20506 20644 20560 
f 20561 20507 20645 
s 4
f 20561 20645 20493 
s 2
f 20559 20646 20496 
s 1
f 20646 20559 20542 
s 4
f 20558 20647 20497 
s 1
f 20647 20558 20543 
s 4
f 20608 20648 20650 
s 1
f 20648 20608 20573 
s 2
f 20609 20649 20651 
s 1
f 20649 20609 20572 
s 4
f 20611 20650 20648 
s 1
f 20526 20650 20611 
f 20527 20651 20610 
s 2
f 20610 20651 20649 
s 1
f 20652 20530 20542 
s 4
f 20530 20652 20654 
s 1
f 20653 20531 20543 
s 2
f 20531 20653 20655 
s 4
f 20654 20544 20530 
s 1
f 20506 20544 20654 
s 2
f 20655 20545 20531 
s 1
f 20507 20545 20655 
s 2
f 20656 20679 20678 
f 20664 20681 20680 
f 20670 20676 20675 
s 4
f 20656 20663 20659 
f 20659 20667 20656 
f 20658 20666 20662 
f 20662 20687 20658 
f 20694 20665 20661 
f 20661 20669 20694 
f 20664 20657 20660 
f 20660 20668 20664 
s off
f 20692 20678 20679 
f 20667 20659 20691 
f 20682 20673 20674 
f 20669 20661 20707 
f 20707 20680 20681 
f 20668 20660 20690 
f 20698 20675 20676 
f 20666 20658 20692 
s 2
f 20694 20669 20674 
f 20659 20663 20671 
f 20671 20673 20659 
f 20662 20666 20676 
f 20676 20670 20662 
f 20660 20657 20670 
f 20670 20675 20660 
s off
f 20674 20669 20707 
f 20691 20659 20673 
f 20676 20666 20692 
f 20690 20660 20675 
s 2
f 20656 20667 20679 
f 20687 20678 20658 
f 20664 20668 20681 
f 20661 20665 20664 
f 20664 20680 20661 
s off
f 20679 20667 20691 
f 20692 20658 20678 
f 20681 20668 20690 
f 20707 20661 20680 
f 20690 20707 20681 
f 20691 20692 20679 
f 20704 20705 20689 
f 20705 20704 20684 
f 20673 20682 20691 
s 1
f 20707 20685 20683 
s off
f 20677 20697 20688 
s 1
f 20692 20683 20697 
s off
f 20685 20698 20686 
f 20675 20698 20690 
s 2
f 20678 20687 20656 
s off
f 20688 20696 20677 
f 20686 20695 20685 
f 20684 20689 20705 
f 20689 20699 20704 
s 1
f 20707 20690 20685 
f 20690 20698 20685 
f 20692 20691 20683 
f 20706 20700 20701 
s off
f 20692 20677 20676 
s 1
f 20697 20677 20692 
f 20702 20703 20693 
s off
f 20707 20682 20674 
s 2
f 20674 20672 20694 
s 1
f 20688 20695 20686 
s off
f 20695 20688 20697 
f 20696 20686 20698 
s 1
f 20686 20696 20688 
s off
f 20697 20685 20695 
s 1
f 20685 20697 20683 
s off
f 20676 20677 20698 
f 20698 20677 20696 
f 20699 20684 20704 
s 1
f 20684 20699 20689 
s 264
f 27234 20709 20710 
s 544
f 26620 20710 20711 
s 1040
f 24212 20711 20712 
s 544
f 28833 20712 20713 
s 1040
f 22857 20713 20714 
s 544
f 28219 20714 20715 
s 1040
f 25474 20715 20716 
s 544
f 27634 20716 20717 
s 1040
f 20708 20717 20718 
s 544
f 29358 20718 20719 
s 1040
f 25783 20719 20720 
s 544
f 23843 20720 20709 
s 2147483647
f 20716 20715 20714 
f 20717 20716 20714 
f 20717 20714 20713 
f 20717 20713 20712 
f 20718 20717 20712 
f 20719 20718 20712 
f 20719 20712 20711 
f 20719 20711 20710 
f 20720 20719 20710 
f 20720 20710 20709 
s 2049
f 27509 20722 20723 
s 4100
f 23605 20723 20724 
s 8194
f 26892 20724 20725 
s 4100
f 25518 20725 20726 
s 8194
f 29080 20726 20727 
s 4100
f 20721 20727 20728 
s 8194
f 28521 20728 20729 
s 4100
f 25603 20729 20730 
s 8194
f 27942 20730 20731 
s 4100
f 22943 20731 20732 
s 8194
f 29617 20732 20733 
s 4100
f 24423 20733 20722 
s 2147483647
f 20729 20728 20727 
f 20730 20729 20727 
f 20730 20727 20726 
f 20730 20726 20725 
f 20731 20730 20725 
f 20732 20731 20725 
f 20732 20725 20724 
f 20732 20724 20723 
f 20733 20732 20723 
f 20733 20723 20722 
s 264
f 27184 20735 20736 
s 544
f 26578 20736 20737 
s 1040
f 24207 20737 20738 
s 544
f 28813 20738 20739 
s 1040
f 22854 20739 20740 
s 544
f 28187 20740 20741 
s 1040
f 25482 20741 20742 
s 544
f 27617 20742 20743 
s 1040
f 20734 20743 20744 
s 544
f 29348 20744 20745 
s 1040
f 25786 20745 20746 
s 544
f 23841 20746 20735 
s 2147483647
f 20742 20741 20740 
f 20743 20742 20740 
f 20743 20740 20739 
f 20743 20739 20738 
f 20744 20743 20738 
f 20745 20744 20738 
f 20745 20738 20737 
f 20745 20737 20736 
f 20746 20745 20736 
f 20746 20736 20735 
s 257
f 20747 20748 26601 
s 516
f 20748 20749 24064 
s 1026
f 20749 20750 28836 
s 516
f 20750 20751 22587 
s 1026
f 20751 20752 28236 
s 516
f 20752 20753 25469 
s 1026
f 20753 20754 27660 
s 516
f 20754 20755 20759 
s 1026
f 20755 20756 29373 
s 516
f 20756 20757 25773 
s 1026
f 20757 20758 23849 
s 516
f 20758 20747 27224 
s 2147483647
f 20754 20753 20752 
f 20755 20754 20752 
f 20755 20752 20751 
f 20755 20751 20750 
f 20756 20755 20750 
f 20757 20756 20750 
f 20757 20750 20749 
f 20757 20749 20748 
f 20758 20757 20748 
f 20758 20748 20747 
s 257
f 20760 20761 26650 
s 516
f 20761 20762 24241 
s 1026
f 20762 20763 28913 
s 516
f 20763 20764 22399 
s 1026
f 20764 20765 28288 
s 516
f 20765 20766 25430 
s 1026
f 20766 20767 27678 
s 516
f 20767 20768 24428 
s 1026
f 20768 20769 29465 
s 516
f 20769 20770 20772 
s 1026
f 20770 20771 25280 
s 516
f 20771 20760 27426 
s 2147483647
f 20767 20766 20765 
f 20768 20767 20765 
f 20768 20765 20764 
f 20768 20764 20763 
f 20769 20768 20763 
f 20770 20769 20763 
f 20770 20763 20762 
f 20770 20762 20761 
f 20771 20770 20761 
f 20771 20761 20760 
s 257
f 20773 20774 26660 
s 516
f 20774 20775 24239 
s 1026
f 20775 20776 28951 
s 516
f 20776 20777 22862 
s 1026
f 20777 20778 28448 
s 516
f 20778 20779 25433 
s 1026
f 20779 20780 27856 
s 516
f 20780 20781 20785 
s 1026
f 20781 20782 29474 
s 516
f 20782 20783 25727 
s 1026
f 20783 20784 23836 
s 516
f 20784 20773 27140 
s 2147483647
f 20780 20779 20778 
f 20781 20780 20778 
f 20781 20778 20777 
f 20781 20777 20776 
f 20782 20781 20776 
f 20783 20782 20776 
f 20783 20776 20775 
f 20783 20775 20774 
f 20784 20783 20774 
f 20784 20774 20773 
s 2049
f 20786 20787 24053 
s 4100
f 20787 20788 29644 
s 8194
f 20788 20789 26216 
s 4100
f 20789 20790 30166 
s 8194
f 20790 20791 20798 
s 4100
f 20791 20792 29893 
s 8194
f 20792 20793 27954 
s 4100
f 20793 20794 24926 
s 8194
f 20794 20795 29874 
s 4100
f 20795 20796 26995 
s 8194
f 20796 20797 23258 
s 4100
f 20797 20786 29732 
s 2147483647
f 20793 20792 20791 
f 20794 20793 20791 
f 20794 20791 20790 
f 20794 20790 20789 
f 20795 20794 20789 
f 20796 20795 20789 
f 20796 20789 20788 
f 20796 20788 20787 
f 20797 20796 20787 
f 20797 20787 20786 
s 264
f 27313 20800 20801 
s 544
f 26710 20801 20802 
s 1040
f 24225 20802 20803 
s 544
f 28875 20803 20804 
s 1040
f 22830 20804 20805 
s 544
f 28311 20805 20806 
s 1040
f 25454 20806 20807 
s 544
f 27735 20807 20808 
s 1040
f 20799 20808 20809 
s 544
f 29414 20809 20810 
s 1040
f 25750 20810 20811 
s 544
f 23866 20811 20800 
s 2147483647
f 20807 20806 20805 
f 20808 20807 20805 
f 20808 20805 20804 
f 20808 20804 20803 
f 20809 20808 20803 
f 20810 20809 20803 
f 20810 20803 20802 
f 20810 20802 20801 
f 20811 20810 20801 
f 20811 20801 20800 
s off
f 21455 21188 20925 
s 264
f 20817 20816 27364 
s 544
f 20818 20817 26774 
s 1040
f 20819 20818 24162 
s 544
f 20820 20819 28903 
s 1040
f 20821 20820 22891 
s 544
f 20822 20821 28366 
s 1040
f 20823 20822 25557 
s 544
f 20824 20823 27789 
s 1040
f 20825 20824 20815 
s 544
f 20826 20825 29433 
s 1040
f 20827 20826 25619 
s 544
f 20816 20827 23767 
s 2147483647
f 20827 20816 20817 
f 20821 20822 20823 
f 20821 20823 20824 
f 20820 20821 20824 
f 20819 20820 20824 
f 20819 20824 20825 
f 20819 20825 20826 
f 20818 20819 20826 
f 20817 20818 20826 
f 20827 20817 20826 
s 2049
f 20830 20829 27508 
s 4100
f 20831 20830 23604 
s 8194
f 20832 20831 26891 
s 4100
f 20833 20832 25366 
s 8194
f 20834 20833 29079 
s 4100
f 20835 20834 20828 
s 8194
f 20836 20835 28520 
s 4100
f 20837 20836 25602 
s 8194
f 20838 20837 27941 
s 4100
f 20839 20838 22944 
s 8194
f 20840 20839 29616 
s 4100
f 20829 20840 24424 
s 2147483647
f 20840 20829 20830 
f 20834 20835 20836 
f 20834 20836 20837 
f 20833 20834 20837 
f 20832 20833 20837 
f 20832 20837 20838 
f 20832 20838 20839 
f 20831 20832 20839 
f 20830 20831 20839 
f 20840 20830 20839 
s 264
f 20843 20842 27221 
s 544
f 20844 20843 26618 
s 1040
f 20845 20844 24213 
s 544
f 20846 20845 28835 
s 1040
f 20847 20846 22847 
s 544
f 20848 20847 28228 
s 1040
f 20849 20848 25473 
s 544
f 20850 20849 27647 
s 1040
f 20851 20850 20841 
s 544
f 20852 20851 29368 
s 1040
f 20853 20852 25777 
s 544
f 20842 20853 23848 
s 2147483647
f 20853 20842 20843 
f 20847 20848 20849 
f 20847 20849 20850 
f 20846 20847 20850 
f 20845 20846 20850 
f 20845 20850 20851 
f 20845 20851 20852 
f 20844 20845 20852 
f 20843 20844 20852 
f 20853 20843 20852 
s 257
f 25958 20855 20854 
s 516
f 22198 20856 20855 
s 1026
f 28938 20857 20856 
s 516
f 23672 20858 20857 
s 1026
f 28420 20859 20858 
s 516
f 25437 20860 20859 
s 1026
f 27790 20861 20860 
s 516
f 20863 20862 20861 
s 1026
f 29425 20864 20862 
s 516
f 25741 20865 20864 
s 1026
f 27118 20866 20865 
s 516
f 23071 20854 20866 
s 2147483647
f 20866 20854 20855 
f 20859 20860 20861 
f 20859 20861 20862 
f 20858 20859 20862 
f 20857 20858 20862 
f 20857 20862 20864 
f 20857 20864 20865 
f 20856 20857 20865 
f 20855 20856 20865 
f 20866 20855 20865 
s 257
f 26649 20868 20867 
s 516
f 24208 20869 20868 
s 1026
f 28798 20870 20869 
s 516
f 22398 20871 20870 
s 1026
f 28184 20872 20871 
s 516
f 25483 20873 20872 
s 1026
f 27876 20874 20873 
s 516
f 24426 20875 20874 
s 1026
f 29461 20876 20875 
s 516
f 20878 20877 20876 
s 1026
f 25283 20879 20877 
s 516
f 27165 20867 20879 
s 2147483647
f 20879 20867 20868 
f 20872 20873 20874 
f 20872 20874 20875 
f 20871 20872 20875 
f 20870 20871 20875 
f 20870 20875 20876 
f 20870 20876 20877 
f 20869 20870 20877 
f 20868 20869 20877 
f 20879 20868 20877 
s 257
f 26539 20881 20880 
s 516
f 24178 20882 20881 
s 1026
f 28795 20883 20882 
s 516
f 22878 20884 20883 
s 1026
f 28305 20885 20884 
s 516
f 25363 20886 20885 
s 1026
f 27758 20887 20886 
s 516
f 20889 20888 20887 
s 1026
f 29492 20890 20888 
s 516
f 25614 20891 20890 
s 1026
f 23822 20892 20891 
s 516
f 27158 20880 20892 
s 2147483647
f 20892 20880 20881 
f 20885 20886 20887 
f 20885 20887 20888 
f 20884 20885 20888 
f 20883 20884 20888 
f 20883 20888 20890 
f 20883 20890 20891 
f 20882 20883 20891 
f 20881 20882 20891 
f 20892 20881 20891 
s 2049
f 24051 20894 20893 
s 4100
f 29642 20895 20894 
s 8194
f 26217 20896 20895 
s 4100
f 30169 20897 20896 
s 8194
f 20899 20898 20897 
s 4100
f 29896 20900 20898 
s 8194
f 27952 20901 20900 
s 4100
f 24930 20902 20901 
s 8194
f 29877 20903 20902 
s 4100
f 26996 20904 20903 
s 8194
f 23259 20905 20904 
s 4100
f 29734 20893 20905 
s 2147483647
f 20905 20893 20894 
f 20898 20900 20901 
f 20898 20901 20902 
f 20897 20898 20902 
f 20896 20897 20902 
f 20896 20902 20903 
f 20896 20903 20904 
f 20895 20896 20904 
f 20894 20895 20904 
f 20905 20894 20904 
s 264
f 20908 20907 27276 
s 544
f 20909 20908 26798 
s 1040
f 20910 20909 24237 
s 544
f 20911 20910 28892 
s 1040
f 20912 20911 22819 
s 544
f 20913 20912 28419 
s 1040
f 20914 20913 25438 
s 544
f 20915 20914 27819 
s 1040
f 20916 20915 20906 
s 544
f 20917 20916 29383 
s 1040
f 20918 20917 25734 
s 544
f 20907 20918 23876 
s 2147483647
f 20918 20907 20908 
f 20912 20913 20914 
f 20912 20914 20915 
f 20911 20912 20915 
f 20910 20911 20915 
f 20910 20915 20916 
f 20910 20916 20917 
f 20909 20910 20917 
f 20908 20909 20917 
f 20918 20908 20917 
s off
f 20945 21187 21265 
f 24976 24966 30471 
f 33817 29963 24836 
s 64
f 31536 31517 31559 
s off
f 23547 30682 33822 
s 64
f 31488 31550 31510 
s off
f 21182 21075 20971 
f 21007 21184 20925 
f 30466 24965 21517 
f 20812 21294 21072 
f 21178 21201 33453 
f 30478 21470 24965 
f 33483 33507 33820 
f 20970 21074 21086 
f 21080 31887 21356 
f 21357 31885 21082 
s 64
f 31512 32529 21587 
s off
f 21099 21102 33822 
f 20930 21134 21013 
f 21081 21135 20933 
f 20931 21003 20965 
f 21134 31604 30035 
f 20966 21002 20935 
f 30038 31606 21135 
f 21382 31769 31544 
f 21364 21193 33485 
f 26390 24952 31722 
f 21293 21071 21050 
s 64
f 33810 31764 31503 
s off
f 21201 33497 33452 
f 31066 21181 21286 
s 64
f 31523 31529 31530 
s off
f 31555 31763 21471 
f 21199 31442 24976 
f 31195 32277 32284 
f 33817 30667 23003 
s 64
f 31559 31497 31536 
s off
f 33547 21225 31472 
f 28002 33448 21222 
f 31721 24951 26391 
f 21051 21072 21294 
f 33342 31192 21146 
f 20987 30255 21370 
f 21219 20989 22616 
f 31760 21343 32601 
f 31729 24358 24950 
s 1
f 33548 33826 33480 
s 64
f 31554 31770 33809 
s off
f 31636 28544 23682 
s 64
f 31510 31499 31488 
s off
f 21202 33433 31469 
f 28002 21286 33449 
f 21192 29960 33822 
s 64
f 31766 31542 33492 
s off
f 21223 31174 21342 
f 31768 31500 21341 
s 64
f 21498 31494 31487 
s off
f 30412 30848 26253 
f 29975 29962 33822 
s 64
f 31502 31540 31507 
s off
f 23165 33539 20925 
s 64
f 33492 33539 31766 
s off
f 33817 21104 21950 
s 64
f 31526 31515 21586 
f 33819 31539 31765 
s off
f 33817 30653 21473 
f 24966 21517 30469 
f 21294 20927 21051 
f 33453 33446 21178 
f 21155 31448 24965 
f 20931 20965 20939 
f 21134 31890 21353 
f 20940 20966 20935 
f 21082 21081 20933 
f 21471 33540 31555 
f 31544 33450 21382 
f 33342 32040 31192 
f 20970 21224 21087 
f 20971 21088 21223 
s 64
f 31541 31538 21465 
s off
f 33485 21417 21364 
s 64
f 31530 31522 31523 
f 31533 31519 31561 
s off
f 21272 21511 30812 
f 21293 21403 30791 
s 64
f 33809 21443 31554 
s off
f 24642 29855 21225 
f 31470 33497 21201 
f 33820 33480 20977 
f 33480 33490 31553 
s 8
f 21180 21056 33509 
s off
f 31193 32039 32671 
f 33830 21118 25600 
f 33507 33483 31566 
f 33464 28612 24018 
f 23172 21077 28598 
f 28596 21067 23171 
f 21344 31175 21224 
s 64
f 31561 31492 31533 
s off
f 20945 21265 20973 
f 33464 22207 21089 
f 21445 21182 21193 
f 21318 21225 21752 
f 27982 29812 21181 
f 31494 21498 21455 
f 20954 21455 20925 
f 20922 21293 30791 
f 21071 21293 20922 
f 20988 33488 33535 
f 21517 20989 23021 
f 21191 21517 31446 
f 21222 21168 33495 
f 33443 33452 33497 
f 24969 24329 31697 
f 24968 24332 31693 
f 24957 24337 31698 
f 30790 21294 20812 
f 20922 20993 21071 
f 21072 20994 20812 
f 20989 21219 21530 
f 21170 21219 24966 
f 30788 20812 21568 
f 21181 29856 24641 
s 1
f 20942 21395 20993 
s 2
f 21105 31177 32276 
s 1
f 20949 20994 21394 
s 2
f 21103 32308 31176 
s off
f 30254 33518 24722 
f 33810 21417 33485 
f 33483 21443 33809 
f 20933 33662 22564 
f 21002 20934 20935 
f 20931 20932 21003 
f 20932 33652 22565 
s 64
f 21586 33393 31526 
s off
f 20945 21183 21004 
f 20972 21466 21364 
f 31546 21587 21102 
f 31730 24949 24349 
s 64
f 21587 31546 31512 
f 31507 31548 31502 
s off
f 33501 22534 33547 
f 29837 21622 24638 
f 24981 31695 24338 
f 21569 20922 30789 
f 21131 30255 33505 
f 30186 23027 21286 
f 25588 27979 29807 
f 24637 21621 29836 
f 21286 22532 33435 
f 21013 31600 30037 
f 20930 21013 21080 
s 64
f 31765 32684 33819 
s off
f 20945 32684 23164 
f 21426 31194 33819 
f 32290 33817 21015 
f 21427 33492 31196 
f 21016 33822 32319 
f 20937 33462 30040 
f 30035 21017 20931 
f 20935 21018 30038 
f 30041 33661 20938 
f 22392 21506 21019 
f 21275 30068 30889 
f 30888 30067 21274 
f 21022 21507 22393 
f 24980 31700 24330 
f 21050 20987 21293 
f 20926 20975 23001 
f 20926 21098 21086 
f 23002 20943 21005 
f 21182 21445 21005 
f 22392 21019 21515 
f 21516 21022 22393 
f 21104 21586 31515 
f 21104 21343 23945 
f 21145 31193 32671 
f 29703 22699 20971 
f 20971 31473 29641 
f 20970 22700 29702 
f 20970 29640 31474 
f 33518 32316 21034 
f 32316 32278 31197 
f 31772 32601 21343 
f 21114 32601 23163 
f 23166 32597 21106 
f 21341 32597 31768 
f 24966 21219 23017 
f 21219 21528 30739 
f 21529 21218 21214 
f 30738 21527 21218 
f 33507 20929 28609 
f 28558 28561 28579 
f 30792 21518 21245 
f 23024 21245 24965 
f 21272 21244 21511 
f 21244 21519 30793 
f 23026 21273 21470 
f 30811 21510 21273 
f 21056 21043 33502 
f 33435 33449 21286 
f 30736 21529 21214 
f 21518 21214 21245 
f 30050 21225 23169 
f 33547 30242 31035 
f 24532 30652 29954 
f 30721 29952 30682 
f 28528 21689 31628 
f 26256 30845 24531 
f 30668 29965 33822 
f 31646 28532 23676 
s 2
f 21104 21050 21071 
f 21072 21051 21102 
s off
f 28525 22074 31624 
f 21104 26246 30680 
f 30676 29967 21102 
f 31635 21691 28524 
f 33817 29966 30654 
f 23679 28534 31631 
f 29980 30847 30415 
f 23677 28542 31625 
f 21056 21180 21101 
f 21056 21061 22073 
f 31639 21690 28529 
f 22567 23123 21367 
f 21077 23710 28595 
f 26008 28590 28562 
f 22073 21059 21066 
f 21061 21059 22073 
s 8
f 21131 33639 26288 
s off
f 21056 21062 21061 
f 21062 21059 21061 
f 21056 21101 21062 
f 21059 21062 23111 
f 23170 21181 30051 
f 31718 31662 25069 
f 31883 32041 24690 
f 33547 21536 24986 
s 64
f 31767 31505 21382 
s off
f 26008 28562 28557 
s 8
f 23111 21062 21101 
s off
f 21343 31514 31772 
f 28596 27514 21067 
f 21574 21470 21181 
f 21225 21470 24633 
f 23146 21201 21493 
f 30785 33498 23015 
f 23147 21494 21202 
f 23016 33439 30784 
s 1
f 21395 21071 20993 
s 2
f 21071 31359 21366 
f 21365 31358 21072 
s 1
f 20994 21072 21394 
s off
f 21097 32284 33445 
f 21086 21074 20926 
f 21005 21075 21182 
s 64
f 21471 31557 31771 
s off
f 20929 23713 28605 
f 23946 21341 21102 
f 32597 21341 31759 
f 27998 29853 21202 
f 21568 20812 30476 
f 21201 29854 27999 
f 30474 20922 21569 
f 21080 31602 30039 
f 21013 31888 24351 
f 24357 31892 21135 
f 30042 31605 21081 
f 24353 31891 21081 
f 30036 31601 21082 
f 28064 21181 23148 
f 21479 31476 31596 
f 20970 31474 25899 
f 28596 23171 21084 
f 20971 21223 28000 
f 21119 23172 28598 
f 23327 21086 25600 
f 20970 21086 23653 
f 21087 21224 31175 
f 31174 21223 21088 
f 24862 21576 22186 
f 22207 21182 23328 
f 20942 21087 26003 
f 31170 31729 24950 
f 24949 31730 31171 
f 26002 21088 20949 
f 21019 21523 21515 
f 21516 21524 21022 
s 64
f 33489 31552 31553 
s off
f 20953 33809 23325 
f 29816 25586 31884 
f 33547 31472 30795 
f 29976 30838 31522 
f 30613 23479 29925 
f 31370 29797 31043 
f 24721 33445 29797 
f 21098 20926 22127 
f 24972 31131 24346 
f 20936 30559 30623 
f 31540 30839 29977 
s 8
f 21474 31197 32278 
f 28552 21101 24976 
f 26287 23111 21101 
s off
f 33822 21102 20814 
s 2
f 21365 21072 21102 
f 21103 21102 32308 
s off
f 20920 21104 33817 
s 2
f 21104 21071 21366 
f 21105 32276 21104 
s 64
f 21382 33450 31767 
f 33485 31567 31490 
s off
f 21288 33450 20968 
f 21022 21526 21507 
f 21506 21525 21019 
f 31607 24360 20933 
f 21357 21082 20933 
f 31603 20930 24352 
f 20930 21080 21356 
f 21112 20988 21465 
f 23326 33810 20988 
f 30667 29949 30722 
f 30604 23122 22566 
s 64
f 31771 33540 21471 
f 31491 31566 33483 
s off
f 20967 33540 21287 
f 22128 21005 21445 
f 24959 24345 31130 
f 21557 30605 20923 
f 29963 26254 30657 
f 22187 21577 24861 
f 21086 31706 28073 
f 20971 25900 31473 
f 31667 23651 21085 
s 8
f 21475 32277 31195 
s off
f 30050 23169 21459 
f 25068 31660 31717 
f 31613 31898 24362 
s 1
f 20942 30328 31224 
f 20949 31225 30329 
s off
f 31612 24361 31897 
f 24629 29639 21470 
f 29800 25588 29807 
f 31167 21331 22516 
f 22515 21332 31166 
f 31593 30784 31479 
f 31662 31781 25069 
f 25068 31782 31660 
f 31592 31480 30785 
f 20987 21370 28033 
f 21219 21131 21528 
f 31633 31654 30188 
f 30189 31650 31645 
f 20930 20937 21355 
f 20937 31889 21355 
f 21354 20938 20933 
f 21354 31886 20938 
f 23346 23347 21513 
s 64
f 32044 32269 21138 
s off
f 21067 24447 22997 
f 28561 22471 28579 
f 28613 23005 20941 
f 22207 21193 21182 
f 31556 30397 28640 
f 31637 28531 23686 
f 21268 30624 21993 
f 30714 29974 29962 
f 21286 31034 30243 
f 21460 23170 30051 
f 21427 33539 33492 
f 21145 32671 33539 
f 32684 33342 21146 
f 21426 33819 32684 
f 22568 24828 21420 
f 31652 21654 30444 
f 21148 23650 31668 
f 20970 28001 21224 
f 21150 21149 21216 
f 31929 21516 22393 
f 22392 21515 31930 
f 30445 21653 31647 
f 24829 23546 20962 
f 21510 21245 21273 
f 21155 24965 21245 
f 31381 21533 22393 
f 22392 21532 31380 
f 30190 28556 31651 
f 21077 22998 21271 
f 32270 21428 32272 
s 64
f 21404 21588 31580 
s off
f 31640 28545 23675 
f 22336 21053 21102 
s 64
f 26269 32270 32334 
s off
f 21597 31585 21513 
f 32269 32528 32272 
s 64
f 31582 21595 21442 
s off
f 21104 21052 21164 
f 23678 28536 31623 
f 30914 23511 30887 
f 30886 23512 30915 
f 31638 28541 23683 
f 22149 22707 31344 
f 21168 21222 33448 
f 28558 28579 23694 
f 33830 21661 28611 
f 20989 21530 30737 
f 21170 24966 31447 
f 28639 30392 31519 
f 23684 28530 31626 
f 22392 22515 21506 
f 22515 21534 21506 
f 21507 22516 22393 
f 21507 21535 22516 
f 23681 28535 31627 
f 31350 22710 22172 
f 20975 21175 23484 
s 4
f 21175 20975 21176 
s 1
f 21466 33826 33548 
s off
f 23485 21177 20943 
s 4
f 21176 20943 21177 
s off
f 31453 23937 20922 
f 20812 23938 31452 
f 28032 21425 20927 
f 21181 21273 21568 
f 21470 21273 21181 
f 23652 21182 20971 
f 28072 31705 21182 
f 20945 20973 21183 
f 32599 23677 31625 
f 32701 31636 23682 
f 21184 20954 20925 
f 32543 31640 23675 
f 23162 25168 20925 
f 23159 20945 25169 
f 32679 23678 31623 
f 32695 23679 31631 
f 20945 21014 21187 
f 32691 31646 23676 
f 21188 20960 20925 
f 21187 23336 21398 
f 33660 23687 31628 
s 64
f 21437 21190 21594 
f 21190 32044 23266 
s off
f 21244 20989 21519 
f 21519 20989 21517 
f 30725 29971 29960 
f 22568 21563 24828 
f 31567 33485 21193 
f 22547 20941 21193 
f 30937 24359 22516 
f 22515 24348 30936 
f 33819 29084 30383 
f 30445 28527 21653 
f 24447 30362 28546 
f 31649 28553 30187 
f 21452 23345 21188 
f 33515 31639 23688 
f 21199 24976 21101 
f 21527 21101 21218 
s 64
f 21583 21200 21428 
f 30052 32334 21200 
s off
f 21201 23019 21569 
f 21201 21508 30786 
f 21202 21568 23020 
f 21202 30787 21509 
f 23018 21218 24966 
f 30470 24966 24976 
f 30370 21328 21359 
f 28029 30290 22159 
f 21358 21330 30371 
f 22158 30292 28028 
f 22156 30288 28024 
f 21110 29675 25992 
f 25997 29677 21111 
f 28027 30285 22157 
f 33661 30294 28020 
f 23338 29680 25994 
f 28021 30293 33462 
f 25993 29681 23343 
f 23337 29673 25990 
f 30291 28022 20935 
f 25991 29672 23344 
f 20931 28023 30289 
f 21622 29838 24638 
f 21201 29851 27996 
f 27997 29850 21202 
f 24637 29839 21621 
f 22617 21214 21218 
f 30790 21402 21294 
f 31656 21378 30434 
f 30402 28564 21364 
f 21216 21151 21333 
f 21011 25187 31657 
f 33547 31067 31832 
f 22618 21218 21101 
f 30465 20927 21218 
f 21219 20987 30464 
f 21131 21219 22619 
f 28534 22238 31631 
f 24543 29104 30431 
f 28530 21693 31626 
f 31357 22712 22114 
f 33547 31478 21225 
f 33547 25240 23013 
f 31484 21223 21077 
f 21223 30257 28000 
f 28001 30256 21224 
f 21067 21224 31483 
f 21225 21272 21470 
f 21569 21272 21225 
f 31620 31665 21321 
f 22207 23328 21089 
f 21118 23327 25600 
f 31621 21320 31666 
s 8
f 30896 24976 29619 
f 24976 21131 24367 
s off
f 30412 29109 21504 
f 31638 22241 28541 
f 28542 22236 31625 
f 30679 29101 30421 
s 8
f 21231 33509 32682 
f 31043 29797 33509 
f 32687 33456 21232 
s off
f 31371 31042 30254 
f 21104 26243 30691 
f 23478 30613 29925 
f 30924 31721 26391 
f 20971 30994 31610 
f 20970 31611 30995 
f 26390 31722 30925 
f 31231 30327 20949 
f 31730 24349 31896 
f 20942 30326 31230 
f 31729 31895 24358 
f 20926 26379 30046 
f 31131 29631 24346 
f 30045 26380 21005 
f 24345 29632 31130 
f 24333 29890 31685 
f 31822 31062 21214 
f 31683 30000 24342 
f 21244 31055 31829 
f 28555 22086 31650 
f 30459 31360 21102 
f 31654 22085 28554 
f 21104 31361 30458 
f 21244 20922 31051 
f 20989 21244 22620 
f 22621 21245 21214 
f 31050 20812 21245 
f 31540 30398 29085 
f 31635 28524 23685 
f 25005 22392 28055 
f 25006 28054 22393 
f 30882 23941 28056 
f 28057 23940 30883 
s 64
f 30313 23495 32270 
f 26262 21160 32270 
f 32269 23494 30315 
f 32269 21163 26014 
s off
f 28059 26389 30880 
f 30881 26388 28058 
f 31834 31066 21286 
f 32030 31658 21255 
f 31810 31068 33439 
f 32034 31659 21256 
f 32033 21257 31661 
f 33498 31069 31811 
f 28061 23939 30885 
f 30884 23942 28060 
f 21201 21178 21508 
f 23937 21178 20922 
f 20812 21179 23938 
f 21202 21509 21179 
f 33817 29102 30422 
f 24328 31755 31902 
f 30423 29098 33822 
f 31901 31756 24327 
f 33507 29108 30413 
f 28543 21264 21115 
f 31533 31627 32700 
f 21187 21398 21265 
s 8
f 28552 23175 21101 
s off
f 29086 30389 31522 
f 23680 28525 31624 
f 31637 21692 28531 
f 21268 30577 30624 
f 30892 23517 30916 
f 30917 23516 30893 
f 23944 21382 22999 
f 28547 30363 21271 
f 21272 21569 31053 
f 21244 21272 22623 
f 31052 21568 21273 
f 22625 21273 21245 
f 30888 23513 30919 
f 30918 23510 30889 
f 24944 32007 31916 
f 31915 32008 24945 
f 21107 28539 23670 
f 21382 30399 28570 
f 30435 22485 31655 
f 33820 28563 30403 
f 32683 23684 31626 
f 21265 23334 20973 
f 20954 23340 21455 
f 33503 31637 23686 
f 21007 23341 21184 
f 33763 31635 23685 
f 32681 23680 31624 
f 21183 23339 21004 
f 21653 31632 31647 
f 20945 23164 21014 
f 20960 23165 20925 
f 31652 31644 21654 
f 21286 23014 25241 
f 21286 21181 31477 
f 23160 20967 21287 
f 23669 21115 31648 
f 23161 21288 20968 
f 31653 21107 23670 
f 21035 21114 23163 
f 31630 31649 30187 
f 30190 31651 31642 
f 21036 23166 21106 
f 21112 23326 20988 
f 31656 31643 21378 
f 22485 31622 31655 
f 23322 20953 23325 
f 20989 21293 31823 
f 21293 21422 30729 
f 31824 21294 21214 
f 30730 21421 21294 
f 31636 22237 28544 
f 30418 29103 30700 
f 31550 30396 29082 
f 31639 28529 23688 
f 29081 30394 31517 
f 23687 28528 31628 
f 31467 31485 31578 
f 31812 31059 25241 
f 31579 31486 31468 
f 25240 31058 31809 
f 21495 29106 30415 
f 28535 22239 31627 
f 30432 29107 30661 
f 31646 22240 28532 
f 21302 21132 23324 
f 32601 31513 31760 
f 21303 23323 21133 
f 31759 31558 32597 
f 21293 31056 31823 
f 31700 29887 24330 
f 31824 31057 21294 
f 24329 29886 31697 
f 20989 31063 31821 
f 31686 29891 24334 
f 31680 29917 24336 
f 21219 31060 31817 
f 31818 31061 21218 
f 24335 29916 31679 
f 21201 31071 31815 
f 21260 20976 25184 
f 21202 31816 31070 
f 25185 20996 21261 
f 24341 29999 31684 
f 31830 31054 21245 
f 31688 30047 24344 
f 21272 31064 31831 
f 31833 31065 21273 
f 24343 30048 31687 
f 21654 28526 30444 
f 30382 29083 33492 
f 30890 23008 30938 
f 30939 23007 30891 
f 21077 27513 28598 
f 23695 26008 28557 
f 21322 21318 27980 
f 21318 29804 27980 
f 20948 31884 33811 
f 20948 28064 23148 
f 21320 28614 31666 
f 31201 30305 24861 
f 24862 30306 31200 
f 31665 28606 21321 
f 25249 32521 30514 
f 32029 21011 31657 
f 29711 24715 20934 
f 29675 31242 25992 
f 29706 24716 20966 
f 29673 31247 25990 
f 25997 31245 29677 
f 20932 24718 29710 
f 29705 24719 30038 
f 29680 31238 25994 
f 30035 24720 29704 
f 25993 31239 29681 
f 21003 24717 29709 
f 30370 28013 21328 
f 20965 24714 29708 
f 25991 31246 29672 
f 29707 24713 21002 
f 21330 28014 30371 
f 29870 23515 30966 
f 30937 30244 21440 
f 21441 30245 30936 
f 30965 23514 29871 
f 21216 21334 21150 
f 21333 21334 21216 
f 30728 30645 29930 
f 30653 29970 30726 
f 28558 23704 28561 
f 33507 23709 28608 
f 33817 23003 30653 
f 21992 30604 22566 
f 28632 29837 24638 
f 24859 29857 21225 
f 24637 29836 28631 
f 21181 29858 24858 
f 33564 31638 23683 
f 21455 21498 31638 
f 21077 21341 26190 
f 21341 21077 21102 
f 21077 21342 21390 
f 21077 21223 21342 
f 21067 26187 21343 
f 21104 21067 21343 
f 21391 21344 21067 
f 21344 21224 21067 
f 31952 32016 24936 
f 24935 32015 31951 
f 31618 30043 29985 
f 30058 26988 20971 
f 31619 29988 30044 
f 20970 26987 30057 
f 21223 31484 31128 
f 32035 31667 21085 
f 31127 31483 21224 
f 32036 21148 31668 
f 21351 24829 20962 
f 33817 29981 21531 
f 32021 21352 23337 
f 21081 24357 21135 
f 21134 21353 21013 
f 32017 23344 24356 
f 21135 21354 20933 
f 32024 24350 23338 
f 20930 21355 21134 
f 32023 23343 24355 
f 20930 21356 24352 
f 32022 21111 24354 
f 24360 21357 20933 
f 32019 24347 21110 
f 21358 24963 26897 
f 21082 24353 21081 
f 26898 24977 21359 
f 21013 24351 21080 
f 29849 31614 31041 
f 21201 21584 21360 
f 21361 21585 21202 
f 31040 31615 29848 
f 20975 25256 31078 
f 25266 31045 27943 
f 31081 25257 20943 
f 27944 31046 25265 
f 21177 21445 21364 
f 21193 21364 21445 
s 2
f 21103 21365 21102 
s off
f 31612 24956 24361 
s 2
f 21105 21104 21366 
s off
f 31613 24362 24955 
f 22567 21367 21663 
f 30688 26248 30682 
f 32316 29700 30808 
f 24326 31757 31904 
f 31903 31758 24325 
f 30807 29701 32284 
f 21565 21370 30255 
f 24973 31696 24331 
f 21181 29867 27992 
f 29839 21078 21621 
f 27993 29866 21225 
f 21622 21079 29838 
f 21102 31171 32308 
f 30283 28030 20949 
f 21104 32276 31170 
f 20942 28031 30284 
f 24447 21375 23943 
f 28603 21471 23943 
f 30419 29100 21102 
f 31640 21376 28545 
f 28536 21377 31623 
f 21104 29099 30427 
f 30426 29105 21466 
f 21378 28533 30434 
f 31654 28554 30188 
f 21104 30360 28549 
f 30189 28555 31650 
f 28548 30361 21102 
f 20936 30623 22499 
f 30707 29953 21102 
f 21382 26381 28602 
f 28601 21382 31352 
f 30467 21517 24966 
f 23022 21214 21517 
f 28566 30401 21471 
f 23669 28543 21115 
f 30386 28575 32039 
f 23737 22932 21385 
f 32040 28572 30387 
f 21386 22931 23738 
f 21517 24965 30468 
f 24965 21244 23023 
f 21067 31363 30456 
f 31649 22067 28553 
f 21077 30457 31362 
f 28556 22068 31651 
f 21102 21390 31171 
f 21102 21077 21390 
f 21104 21391 21067 
f 21104 31170 21391 
f 23127 29766 30202 
f 32692 31524 31528 
f 22336 30430 30347 
f 22336 22714 31347 
f 23528 22977 20998 
s 1
f 21072 30287 28025 
s off
f 20997 22978 23527 
s 1
f 28026 30286 21071 
s off
f 28562 23706 23703 
f 29112 31248 21382 
f 22175 30429 30344 
f 22175 22713 31345 
s 64
f 31560 31496 31534 
s off
f 21398 23335 21265 
f 21399 20969 27511 
f 27510 21073 21400 
f 33561 23681 31627 
f 21401 21265 23335 
f 24964 24339 31692 
f 21770 21294 21402 
f 24984 31691 24340 
f 21769 21403 21293 
s 64
f 26269 31406 32270 
f 31583 21200 21583 
s off
f 21107 22577 28539 
f 30414 29097 21193 
f 33480 29110 30416 
f 30435 28537 22485 
f 29963 26249 30648 
f 30605 30593 20923 
f 27991 23711 21193 
f 28562 23703 28557 
f 28606 20984 21321 
f 27959 30248 20971 
f 20970 30249 27958 
f 21320 20980 28614 
f 20971 30301 28617 
f 23651 22702 21085 
f 21148 22701 23650 
f 20970 28616 30302 
f 22149 30420 30337 
f 26276 30502 25985 
f 24709 30503 26277 
f 30346 30425 22172 
f 21415 21090 23533 
f 25995 30284 28031 
f 28030 30283 25996 
f 23534 21091 21416 
s 64
f 31503 21417 33810 
s off
f 30402 21364 21417 
f 28002 33547 21418 
f 32590 30877 32041 
f 30340 30428 22720 
f 31346 22709 22720 
f 26278 30500 24707 
f 21049 29982 33822 
f 21558 20927 21421 
f 21421 20927 21294 
f 21293 20987 21422 
f 21559 21422 20987 
f 22985 22708 31354 
f 22985 30417 30338 
f 30345 30424 22114 
f 30659 30569 26238 
f 21425 21180 20927 
f 21566 21180 21425 
f 24979 24328 31902 
f 21426 32684 21146 
f 21427 21145 33539 
f 24958 31901 24327 
s 64
f 21159 21428 32270 
f 21159 21404 21428 
s off
f 31028 30144 21245 
f 24339 29998 31692 
f 31691 29997 24340 
f 21244 30145 31029 
f 24337 29987 31698 
f 31030 30151 21273 
f 21272 30152 31033 
f 31695 29986 24338 
f 31015 29796 21218 
f 24332 29889 31693 
f 31696 29888 24331 
f 21219 29795 31017 
f 33547 31594 31478 
f 32031 21008 31663 
f 32032 31664 21144 
f 21286 31477 31595 
s 64
f 32269 21437 21139 
f 21437 21442 21139 
s off
f 21768 20812 21438 
f 30788 21438 20812 
f 21767 21439 20922 
f 20922 21439 30789 
f 24359 24975 22516 
f 22515 24971 24348 
s 64
f 31581 21594 21190 
f 21163 25898 30310 
s off
f 21443 33820 30403 
f 33820 21443 33483 
f 21444 21175 21098 
f 21444 21098 30931 
f 21116 21445 21177 
f 21116 30930 21445 
f 33639 33546 21446 
f 30623 30552 22499 
f 30418 30700 29962 
f 33518 29627 30543 
f 21448 22961 24315 
f 24316 22962 21449 
f 30542 29628 33445 
f 30265 21483 32334 
f 21450 32334 23347 
f 32044 21596 30264 
f 21162 32282 23347 
s 64
f 31509 31498 31489 
s off
f 21455 21452 21188 
f 21067 28607 21140 
f 28579 31670 23694 
f 30650 26245 29962 
f 26234 30577 30702 
f 23342 21455 21340 
f 21455 23342 21452 
f 31658 25186 21255 
f 30794 31471 21181 
f 30796 31469 33439 
f 31659 25194 21256 
f 33498 31470 30797 
f 21257 25195 31661 
f 21008 25197 31663 
f 30485 31468 30799 
f 31664 25196 21144 
f 30798 31467 30484 
f 29806 29800 29807 
f 21470 29801 24633 
f 30666 26244 21102 
f 26235 30571 30681 
f 21104 21164 21921 
f 21164 29959 30660 
f 21175 21464 30376 
f 21444 21464 21175 
s 2
f 33826 21466 33499 
s off
f 31541 33499 21466 
s 1
f 33548 21177 21466 
s off
f 21364 21466 21177 
f 30377 21467 21177 
f 21116 21177 21467 
f 23091 21339 31040 
f 23091 21460 30051 
f 23090 30050 21459 
f 23090 31041 21338 
f 21470 21272 23025 
f 24965 21470 30479 
f 31351 21471 23713 
f 28603 23759 21471 
f 21471 31249 29113 
f 23704 22221 28583 
f 30641 30726 29970 
f 21351 22556 24829 
f 24978 24326 31904 
s 8
f 21474 32687 21232 
s off
f 24961 31903 24325 
s 8
f 21475 21231 32682 
s off
f 21132 33393 23324 
f 31563 31762 33393 
f 32529 31761 31562 
f 23323 32529 21133 
f 21104 26242 30678 
f 30613 30576 23479 
f 30948 30186 21286 
f 30877 21064 25070 
f 32521 29809 27981 
f 25588 24632 27979 
f 20948 23148 21481 
f 26264 33397 31476 
f 30624 24832 21993 
f 30682 29975 33822 
f 21483 21200 32334 
f 21597 21513 21200 
f 29800 29805 25588 
f 28064 29817 21181 
f 31662 30515 31781 
f 21286 31719 22532 
f 33547 22534 31720 
f 31782 30516 31660 
f 26421 26273 20812 
f 30523 31806 29127 
f 20922 26272 26422 
f 29128 31805 30522 
f 26423 26270 21568 
f 30525 31794 29135 
f 21569 26271 26424 
f 29134 31793 30524 
f 30535 31789 29125 
f 30465 26259 20927 
f 29126 31790 30534 
f 20987 26260 30464 
f 31480 31482 30785 
f 21201 21360 21493 
f 30784 31481 31479 
f 21494 21361 21202 
f 22114 30424 21904 
f 24702 21495 30415 
f 30117 29757 23132 
f 31565 31736 32695 
f 23133 29756 30114 
f 32691 31742 31564 
s 64
f 31487 31556 21498 
s off
f 33550 31638 21498 
f 23126 29770 30124 
f 32701 31731 31501 
f 30123 29768 23128 
f 31521 31734 32599 
f 33564 31735 31489 
f 23130 29761 30116 
f 30118 29760 23131 
f 31560 31741 33561 
f 23129 29769 30122 
f 32543 31732 31504 
f 22057 30417 22985 
f 30412 21504 24701 
f 23361 26241 21505 
f 23175 28552 20978 
f 24941 32009 31918 
f 23525 30070 30891 
f 31917 32010 24942 
f 30890 30069 23526 
f 24954 21260 29973 
f 23937 21508 21178 
f 24953 29972 21261 
f 21179 21509 23938 
f 21155 21245 21510 
f 24962 24343 31687 
f 21387 21511 21244 
f 24983 31688 24344 
s 8
f 21131 22996 26402 
s off
f 32044 21190 21596 
f 21514 21190 21513 
f 21514 21513 31584 
f 24947 32002 31909 
f 21258 30065 30885 
f 31910 32001 24948 
f 30884 30066 21259 
f 21517 21214 21518 
f 21383 31444 21517 
f 21383 21517 21518 
f 24960 24341 31684 
f 24974 31683 24342 
f 21191 21519 21517 
f 21164 30433 21096 
f 24711 21520 31522 
f 31133 21521 31474 
f 33830 28596 21084 
f 31473 21522 31132 
f 33464 21119 28598 
f 24943 32006 31913 
f 21276 30072 30887 
f 30886 30071 21277 
f 31914 32005 24946 
f 24939 32012 31920 
f 21270 30063 30893 
f 30892 30064 21269 
f 31919 32011 24940 
f 21199 21101 21527 
f 24970 24335 31679 
f 24985 31680 24336 
f 21228 21528 21131 
f 24967 24333 31685 
f 21203 21218 21529 
f 21170 21530 21219 
f 24982 31686 24334 
f 22720 30428 22129 
f 29981 30844 21531 
f 21250 30076 30883 
f 23351 32003 31911 
f 31912 32004 23352 
f 30882 30075 21249 
f 24937 32014 31921 
f 21346 30163 29871 
f 31922 32013 24938 
f 29870 30162 21345 
f 21011 22994 25187 
f 30156 31026 30514 
f 31025 30157 21470 
f 30533 31804 29132 
f 21470 30158 31023 
f 29131 31802 30532 
f 24965 30147 31019 
f 29122 31807 30528 
f 31021 30148 24965 
f 30529 31808 29123 
f 29119 31798 30527 
f 21517 30153 31018 
f 30526 31797 29120 
f 31020 30154 21517 
f 30520 31795 29121 
f 31022 30159 24966 
f 30150 31031 21569 
f 20976 21544 25184 
f 21568 31032 30149 
f 25185 21545 20996 
f 29124 31796 30521 
f 24966 30146 31027 
f 31014 30160 21214 
f 30536 31800 29117 
f 29118 31799 30537 
f 20989 30161 31013 
f 25186 21549 21255 
f 30186 31024 30155 
f 25196 21550 21144 
f 21286 31039 30134 
f 33547 30135 31038 
f 21008 21551 25197 
f 21257 21552 25195 
f 30785 30138 31037 
f 31036 30139 30784 
f 25194 21553 21256 
f 23234 32000 31908 
f 21253 30073 30880 
f 30881 30074 21254 
f 31907 31999 23235 
f 21905 30430 22336 
f 31540 21556 24710 
f 24589 30847 29980 
f 21557 24830 30605 
f 21558 21218 20927 
f 21558 31455 21218 
f 21559 21219 31459 
f 21559 20987 21219 
f 30642 30722 29949 
f 23122 26983 26250 
f 26251 26984 23123 
f 29952 30721 30643 
f 30593 30684 26247 
f 30551 30648 26249 
f 29929 30639 30723 
f 26255 26986 21563 
f 24828 22555 21420 
f 29971 30725 30644 
f 21565 30255 21131 
f 21565 21131 31457 
f 21566 21101 21180 
f 21566 31458 21101 
f 29926 30631 30709 
f 25985 30927 26276 
f 21181 21568 29867 
f 26266 21568 21202 
f 21201 21569 26265 
f 29866 21569 21225 
f 32521 21322 29809 
f 31597 31475 32521 
f 23944 22999 28597 
f 26008 22220 28590 
f 30597 30695 30079 
f 30717 30629 29924 
f 30082 30692 30558 
f 29946 30630 30713 
f 24634 29798 21181 
f 29808 29811 24644 
f 29976 30610 26209 
f 30691 30601 26219 
f 21576 31708 31129 
f 21576 31161 31708 
f 31707 31162 21577 
f 31126 31707 21577 
f 22073 24114 23414 
s 257
f 26630 25974 26017 
s 1040
f 33577 29012 28175 
s 1026
f 28243 28175 29012 
s 257
f 27359 33354 32669 
s 64
f 21404 21583 21428 
s off
f 29837 23177 27988 
f 29862 24640 21360 
f 21361 24639 29861 
f 27987 23176 29836 
f 33393 21586 31563 
f 21586 21104 30142 
f 31562 21587 32529 
f 30140 21102 21587 
s 64
f 21159 21588 21404 
s off
f 23546 26985 24002 
f 30663 30565 29939 
s 257
f 26808 32764 32779 
s 1026
f 33303 28125 27710 
s 257
f 26604 26808 26065 
s 1026
f 27685 27710 28125 
s 64
f 21437 21594 21442 
f 21442 21595 21139 
s off
f 21514 21596 21190 
f 21597 21200 21483 
f 30132 29766 23127 
f 31525 31746 32679 
f 28532 30351 30432 
f 30451 31329 31345 
f 31346 31327 30452 
f 30431 30349 28534 
f 22172 30425 21601 
f 24709 21048 24531 
f 31617 28557 28592 
f 33464 22650 26376 
f 31890 30353 24356 
f 25991 30289 28023 
f 21352 30356 31892 
f 28022 30291 25990 
s 257
f 33468 33702 25953 
s 1026
f 24643 29625 32058 
s 1040
f 32713 29625 24643 
s 264
f 33467 33812 24536 
s off
f 26245 30650 30549 
f 29936 30624 29974 
f 21049 30843 29982 
f 21610 30429 22175 
f 31895 31175 21344 
f 26003 30278 28016 
f 28017 30277 26002 
f 21342 31174 31896 
s 516
f 25591 28068 33058 
s 257
f 24097 28619 33679 
s 544
f 32055 28068 25591 
s 264
f 32588 28619 24097 
s 1026
f 33726 29664 26218 
s 1040
f 26218 29664 33425 
s 257
f 32584 28618 25907 
s 264
f 25907 28618 32577 
s off
f 27995 29863 21202 
f 21621 27987 29836 
f 21201 29864 27994 
f 29837 27988 21622 
s 257
f 32665 32666 27235 
s 1026
f 33541 33543 23619 
s 257
f 26059 25922 26789 
s 1026
f 23068 26139 27739 
s 257
f 26625 33304 33316 
s 1026
f 27653 33210 33219 
s 257
f 27173 26016 25965 
s 1026
f 23624 23069 26115 
s 257
f 25967 26052 27455 
s 1026
f 26106 24477 25372 
s 257
f 33723 33132 26577 
s 1026
f 33289 33279 27734 
s off
f 26004 30272 28019 
f 31898 31177 21105 
f 21103 31176 31897 
f 28018 30273 26005 
f 29844 29822 29163 
f 24696 31674 21569 
f 29164 29823 29845 
f 21568 31673 24697 
f 30656 30564 26230 
f 30667 26252 30673 
s 1026
f 25580 26962 26311 
f 28172 33223 33229 
s 257
f 27448 27367 25954 
s 264
f 27367 33776 33264 
s off
f 29950 30718 30634 
f 29938 30582 30664 
s 257
f 26556 24575 26067 
s 264
f 27365 32545 32417 
s 516
f 28053 33800 33802 
f 21646 28053 25261 
s 257
f 27259 25969 26711 
s 1040
f 33717 33037 22643 
s 1026
f 24434 26313 28446 
s 257
f 25969 27259 32566 
s off
f 23132 29757 30195 
f 33398 31516 31537 
f 31511 31545 33653 
f 30196 29756 23133 
f 30415 30336 28535 
f 31350 31330 30447 
f 30455 31328 31344 
f 28541 30332 30412 
s 257
f 26760 25977 27404 
s 1026
f 28329 25909 24433 
s 257
f 32559 27404 25977 
s 1040
f 22634 32956 32944 
s off
f 33830 25600 28638 
f 31616 28587 28558 
f 28544 30331 30418 
f 30437 31341 31354 
f 26221 30584 30661 
f 29954 30652 21663 
s 257
f 33829 32853 26456 
s 1026
f 27806 25536 33787 
s 257
f 26047 26456 25081 
s 1026
f 25536 27806 27524 
s 257
f 27263 33538 33537 
s 1040
f 27517 32196 32263 
s 257
f 26463 25924 26064 
s 1026
f 27523 27517 24729 
s off
f 23114 30587 26220 
f 26209 30838 29976 
s 1040
f 25138 33068 33055 
s 257
f 26565 25955 24706 
s 1026
f 21676 23230 24431 
s 264
f 27400 33730 33135 
f 33001 33716 27300 
s 516
f 33611 33599 28042 
f 25893 28042 21680 
s 257
f 26048 25940 26765 
s 544
f 32733 28052 26081 
s 264
f 33693 26795 26033 
s 516
f 27871 26081 28052 
s 257
f 27153 26033 26795 
f 26462 23691 26032 
s 1026
f 27527 27698 28155 
s 264
f 27204 33827 32847 
s 1026
f 33781 28155 27698 
s off
f 33551 31518 31534 
f 23131 29760 30201 
f 30206 29761 23130 
f 31509 31551 33549 
f 31506 31543 33663 
f 30192 29769 23129 
f 31508 31535 33545 
f 30204 29770 23126 
f 23128 29768 30199 
f 33352 31520 31532 
f 30421 30342 28542 
f 31357 31342 30436 
s 264
f 26781 25928 27211 
s 1040
f 27778 26123 24479 
f 25355 33380 33378 
s 257
f 32818 27211 25928 
s 1026
f 27649 33005 33009 
s 257
f 27439 27399 25937 
s 1026
f 25435 24482 26133 
s 264
f 27399 32903 33624 
s 1026
f 33168 33804 27879 
s 257
f 25946 27385 27169 
s 1026
f 26114 24478 25351 
s 264
f 33806 32715 27385 
s 1040
f 33263 33283 24069 
s 257
f 25936 27377 33714 
f 27377 25936 26696 
s 1026
f 24500 26116 27829 
s 1040
f 33358 33360 24617 
s 257
f 25932 27381 33598 
s 264
f 27381 25932 26535 
s 1040
f 24567 26124 27554 
s 257
f 27330 26030 26837 
s 1026
f 23620 24742 27863 
s 264
f 32553 26837 26030 
s 1040
f 32428 27863 24742 
f 24741 27855 32354 
s 257
f 26549 26029 27427 
s 1026
f 27855 24741 23623 
s 264
f 26029 26549 32444 
s off
f 30842 30896 29619 
f 30309 30897 29618 
s 257
f 24520 26040 27203 
s 1026
f 26140 23539 24597 
s 257
f 32704 32703 26576 
s 1026
f 33115 33317 27738 
s 257
f 26044 25938 26746 
s 1026
f 28141 27733 27737 
s 264
f 33772 32984 27220 
s 1026
f 27733 28141 32530 
s 257
f 25950 26034 27433 
f 33148 32504 26544 
s 1026
f 29278 27894 21734 
s 1040
f 27894 29278 32046 
s 257
f 27407 25952 26651 
s 1026
f 25161 26101 27688 
s 257
f 25952 27407 32617 
s 1026
f 26101 25161 33586 
f 24894 28115 32472 
s 257
f 27295 25926 26766 
s 1026
f 28115 24894 25808 
s 257
f 25926 27295 32400 
s 1026
f 33097 33130 28313 
s 257
f 26786 27396 27175 
s 264
f 27396 26786 33612 
s 1026
f 26338 24507 22864 
s 257
f 26542 32656 32589 
f 27425 23761 25975 
s 1026
f 24602 24740 27850 
s 1040
f 32605 27850 24740 
s off
f 24633 29801 20985 
f 24631 29799 21752 
s 264
f 27215 26804 32992 
s 1026
f 26316 24506 22827 
f 32998 32645 28270 
s 257
f 26804 27215 27228 
f 27261 24082 25959 
s 1026
f 22872 24521 28388 
s 257
f 26728 33133 33666 
s 1040
f 33656 28388 24521 
s 257
f 33426 33427 27325 
s 1040
f 32260 32167 27516 
s 257
f 26041 25917 25084 
s 1026
f 24730 27516 27534 
s off
f 21400 21765 27510 
f 27511 21766 21399 
f 21767 21244 31456 
f 21767 20922 21244 
f 21768 21245 20812 
f 21768 31450 21245 
f 21769 20989 31454 
f 21769 21293 20989 
f 21770 21214 21294 
f 21770 31451 21214 
s 257
f 26741 24576 26025 
s 1040
f 32483 27582 24934 
s 257
f 27352 32526 32387 
s 1026
f 29614 24934 27582 
s off
f 28024 30288 25992 
f 24347 30352 31885 
f 31887 30355 24354 
f 25997 30285 28027 
f 31888 30354 21359 
f 25998 30290 28029 
f 28028 30292 25999 
f 21358 30359 31891 
s 257
f 26736 26035 24392 
s 1026
f 27809 25443 25440 
s 264
f 26035 26736 32660 
s 1040
f 25443 27809 33251 
s off
f 29116 30969 30233 
f 29622 30514 32521 
s 257
f 27506 27504 25980 
s 1026
f 25507 24670 26134 
s 264
f 27504 32731 33292 
s 1026
f 27608 33232 32409 
s 1040
f 24863 28156 24600 
s 1026
f 32111 32078 28156 
s 264
f 32743 33682 26451 
f 25921 23726 23568 
s 257
f 27454 24007 25949 
s 1026
f 25368 23254 27725 
s 1040
f 33153 27725 23254 
s 257
f 26563 32917 33705 
s off
f 29934 30583 30649 
f 26248 30688 26224 
s 264
f 27210 33590 33585 
s 516
f 33681 26079 28043 
f 21800 28043 26079 
s 257
f 26785 23044 26021 
s off
f 25993 30293 28021 
f 31889 30358 24355 
f 24350 30357 31886 
f 28020 30294 25994 
f 29640 23482 31474 
f 29988 30925 30044 
f 31473 23483 29641 
f 30043 30924 29985 
s 257
f 26644 23536 23727 
s 1026
f 27682 24882 24495 
s 264
f 27172 32624 32856 
s 1040
f 25505 32840 32837 
s 264
f 32992 33073 27215 
s 1026
f 27644 25463 32977 
s 257
f 26049 23719 26779 
s 1026
f 25463 27644 27740 
s 1040
f 32273 32053 26396 
s 264
f 33494 32602 27198 
s 1026
f 24460 26290 33728 
s 264
f 33690 26261 23056 
s 257
f 26537 27198 24474 
s 264
f 27198 26537 33494 
s 1026
f 28375 24511 23249 
s 1040
f 24511 28375 33765 
s 1026
f 27845 33629 32690 
s 264
f 26621 25925 27378 
s 1040
f 27553 27845 24481 
s 257
f 33631 27378 25925 
s 1026
f 28674 26334 28248 
s 264
f 24570 27298 32306 
s 257
f 27298 24570 26707 
s 1040
f 26334 28674 32325 
s 1026
f 26329 28788 32285 
s 1040
f 28788 26329 28321 
s 257
f 24572 27507 32286 
s 264
f 27507 24572 26525 
f 27403 33023 33796 
s 1026
f 28744 29008 28308 
s 1040
f 32987 28308 29008 
s 257
f 24390 27403 25971 
s 1040
f 25576 33407 32309 
s 257
f 32323 27446 24577 
s 264
f 26610 24577 27446 
s 1040
f 27642 24868 23551 
s 1026
f 23552 24911 27702 
s 1040
f 32465 32467 25373 
s 257
f 24578 27303 32361 
f 27303 24578 26680 
s 264
f 26027 26562 26662 
s 1040
f 23109 27754 27665 
s 257
f 32670 32698 26562 
s 1026
f 32702 32831 27754 
f 27799 23981 28116 
s 264
f 33710 27208 23659 
s 1040
f 32937 28116 23981 
s 257
f 26824 23659 27208 
f 24519 27384 27146 
s 264
f 33218 33691 27384 
s 1026
f 28431 26906 22768 
s 1040
f 26906 28431 32549 
s off
f 26000 30286 28026 
f 21366 31359 31613 
f 28025 30287 26001 
f 31612 31358 21365 
s 257
f 26726 24703 24471 
s 264
f 24703 26726 33461 
s 1026
f 28376 24688 23247 
s 1040
f 24688 28376 33454 
s 1026
f 29183 27560 21859 
s 1040
f 27560 29183 32322 
s 257
f 26546 26028 27240 
s 264
f 26028 26546 33091 
s 257
f 24004 25966 26584 
s 1026
f 28152 27596 27759 
s 264
f 32750 32756 27318 
s 1026
f 27596 28152 32283 
s off
f 28601 26075 21382 
f 23706 26378 29112 
s 257
f 26780 27370 27192 
s 1026
f 24883 24668 25421 
s 264
f 27370 26780 33562 
s 1026
f 33175 33557 27816 
s 544
f 25260 28045 21872 
s 264
f 24083 23010 26594 
f 33423 32699 27418 
s 516
f 33649 33604 28045 
s 264
f 27438 32779 32764 
s 1026
f 27710 33234 33303 
s 257
f 27337 27438 23655 
s 1026
f 25457 24737 26128 
s 1040
f 33747 28378 24686 
s 257
f 24473 27308 26778 
s 264
f 33748 26778 27308 
s 1026
f 23246 24686 28378 
s 264
f 32832 32855 27414 
s 1026
f 27627 28128 32430 
s 257
f 24008 23660 26466 
s 1026
f 28128 27627 27533 
f 23267 28643 32299 
f 28643 23267 28409 
s 257
f 24573 27363 32301 
f 27363 24573 26822 
s 1040
f 23672 33413 33409 
s 264
f 24052 26596 27379 
s 1040
f 21889 26310 24510 
s 257
f 32546 27379 26596 
s 516
f 27801 25262 28047 
s 257
f 27456 26069 26727 
s 544
f 32793 28047 25262 
s 264
f 32607 26727 26069 
s 257
f 23612 27416 24463 
s 1026
f 27770 24673 26183 
s 264
f 32726 33737 27416 
s 1040
f 24673 27770 33544 
s 516
f 28046 26078 27729 
s 257
f 26642 26037 27338 
s 544
f 26078 28046 32774 
s 264
f 26037 26642 32633 
s 1040
f 32163 28206 28653 
s 257
f 23576 27204 26819 
s 264
f 32847 26819 27204 
s 1026
f 28739 28653 28206 
s 1040
f 26396 29624 32273 
s 264
f 27308 33496 33748 
f 23056 33719 33690 
s 1026
f 33701 29624 26396 
s off
f 30615 30712 30078 
f 24702 30499 26274 
f 30084 30719 30591 
f 26280 30504 24710 
f 21906 29113 31249 
f 23072 28561 28600 
s 264
f 32577 33278 25907 
s 257
f 33737 32726 23542 
s 1026
f 25904 29626 32062 
s 1040
f 32052 29626 25904 
s 1026
f 33689 29666 27512 
s 1040
f 27512 29666 32792 
s 257
f 33692 28620 24536 
s 264
f 24536 28620 33467 
s 1040
f 28121 33310 33298 
s 257
f 33167 27155 23657 
f 26826 23657 27155 
s 1026
f 27866 26088 24754 
s 257
f 26459 33796 33023 
s 1040
f 29008 32994 32987 
s 257
f 25083 26459 24006 
s 1026
f 28095 26319 24512 
f 33103 27576 29450 
s 257
f 32297 27442 25972 
s 1026
f 29445 29450 27576 
s 257
f 26827 25972 27442 
s off
f 21920 30657 26254 
f 21117 30561 26232 
f 30574 30660 29959 
f 26246 30562 29932 
f 26375 29989 30060 
f 21467 30377 29632 
f 29631 30376 21464 
f 30059 29990 26374 
s 257
f 26566 27414 24389 
s 264
f 27414 26566 32832 
s 1026
f 28440 28999 28743 
s 1040
f 28999 28440 32769 
s 264
f 27260 32954 33609 
s 1040
f 25563 32923 32915 
s 257
f 26732 25970 26038 
s 1026
f 27743 26099 24502 
f 24491 26091 27693 
s 264
f 32924 33605 27193 
s 1040
f 32878 32886 25480 
s 257
f 26055 25961 26667 
f 26022 25930 26762 
s 1026
f 24488 26131 27775 
s 264
f 32658 33635 27268 
s 1040
f 33059 33118 25450 
s 264
f 27207 33596 32851 
s 1040
f 25428 32821 32816 
s 257
f 26664 25933 26019 
s 1026
f 27686 26094 24496 
s 257
f 26730 25963 26024 
s 1026
f 27817 26083 24497 
s 264
f 27334 33238 33633 
s 1040
f 23627 33127 33096 
s 257
f 25947 26068 27138 
s 1026
f 26087 24486 28083 
s 257
f 33198 33082 26551 
s 1026
f 33184 33180 27773 
s off
f 20961 30679 29963 
f 29963 30679 30421 
s 1026
f 28094 28462 26978 
s 264
f 27251 32342 32499 
s 257
f 26465 25973 26070 
s 1026
f 28462 32073 32068 
s off
f 24367 28636 25601 
f 26402 26357 21060 
s 264
f 33749 33757 27426 
s 1040
f 33319 33337 25430 
s 264
f 26036 25935 24050 
s 1040
f 24489 26113 27951 
s 264
f 24054 25968 26051 
s 1040
f 27956 26089 24483 
s 264
f 27165 33529 33533 
s 1040
f 25483 33520 33519 
s 264
f 33307 33638 27345 
s 1040
f 33212 33221 25447 
s 257
f 26056 25956 26803 
s 1026
f 24493 26095 27643 
s off
f 28611 28587 26383 
f 27514 28596 23694 
s 264
f 27344 23979 26809 
s 1040
f 24603 23301 27830 
s 257
f 33754 26809 23979 
s 1026
f 33434 27830 23301 
s 264
f 33670 26799 27391 
s 1026
f 22782 28775 28393 
s 1040
f 33302 28393 28775 
s 257
f 27410 27391 26799 
f 23720 27341 27301 
s 1026
f 26093 25501 25481 
s 264
f 32698 32670 27341 
s 1040
f 32831 32702 25501 
s 264
f 27324 32686 33478 
s 1026
f 32321 28124 26121 
s 264
f 26718 25957 26057 
s 1040
f 27742 26121 28124 
s 257
f 25945 27299 24464 
s 1026
f 27847 24569 26186 
s 264
f 33702 33468 27299 
s 1040
f 24569 27847 33431 
s 264
f 33797 33713 27277 
s 516
f 32960 32854 28036 
f 24833 28036 27541 
s 257
f 26031 25951 26723 
s off
f 28578 28608 23709 
f 28605 28589 23704 
s 257
f 24465 26062 26797 
s 1026
f 26184 21986 24865 
s 264
f 33762 26797 26062 
s 1026
f 28065 33053 32059 
s 257
f 23702 27413 27239 
s 1026
f 27636 25163 25564 
s 264
f 32619 33338 27413 
s 1040
f 25163 27636 33326 
s off
f 24543 30567 26223 
f 24588 30845 26256 
f 26253 30848 24590 
f 26236 30554 24541 
s 1026
f 27788 26119 24485 
s 264
f 27372 33046 32647 
s 1040
f 25455 32991 32985 
s 257
f 26776 25976 23308 
f 23714 27302 27393 
s 1026
f 26108 25364 25413 
s 264
f 32885 32654 27302 
s 1040
f 32823 32791 25364 
s 4128
f 26077 28040 27941 
s 2056
f 26395 30806 23604 
s 4128
f 27942 28041 26076 
s 2056
f 23605 30805 26394 
s 1026
f 27033 24449 22008 
s 257
f 26541 27386 27136 
s 1026
f 33297 33284 29380 
s 264
f 27386 26541 33594 
s off
f 26154 30403 28563 
f 31554 31752 31655 
f 31656 31753 31503 
f 28564 30402 26153 
s 1026
f 28967 26298 28402 
s 257
f 33758 33318 27187 
f 26023 25923 26588 
s 1040
f 26298 28967 33049 
s off
f 30946 31035 30242 
f 31579 31689 32037 
f 30243 31034 30947 
f 32038 31699 31578 
s 257
f 24472 23350 25962 
s 1026
f 26173 24667 26109 
s 257
f 23543 33677 33775 
s 1026
f 24860 32707 33674 
s 257
f 27316 25944 26641 
s 1026
f 24484 26102 27700 
s 1040
f 32787 32799 21898 
s 257
f 25944 27316 32621 
s 1040
f 32632 29198 22023 
s 1026
f 24419 22023 29198 
s 257
f 27151 33651 33650 
f 26582 25931 26066 
f 27197 23721 26666 
s 1026
f 25532 27756 27668 
s 257
f 23721 27197 32515 
s 1026
f 27756 25532 33188 
s 257
f 26559 26714 27304 
s 1026
f 27600 27598 25453 
s 257
f 33724 27304 26714 
s 1026
f 33637 25453 27598 
s 257
f 26591 25927 23394 
s 1026
f 27622 26129 24669 
s 264
f 27392 33582 32761 
s 1040
f 28132 32737 32735 
s 257
f 25918 27441 32433 
f 27441 25918 25080 
s 1026
f 32065 32072 28160 
f 26976 28160 28091 
s 1040
f 32906 32910 25465 
s 257
f 25941 27371 33696 
f 27371 25941 26694 
s 1026
f 24490 26136 27722 
s 1040
f 32891 32894 25426 
s 257
f 25978 27283 33695 
f 27283 25978 26686 
s 1026
f 24498 26103 27718 
s 1040
f 23391 32933 32927 
s 257
f 32860 27297 25948 
f 26739 25948 27297 
s 1026
f 27755 26142 24501 
s off
f 26390 24861 21577 
f 29988 30057 26987 
f 21576 24862 26391 
f 26988 30058 29985 
f 26275 30498 24701 
f 30077 30711 30620 
s 257
f 26889 27286 24391 
s 1026
f 27552 24738 25376 
s 264
f 27286 26889 32644 
s 1040
f 24738 27552 32640 
s 264
f 26832 27194 27264 
s 1040
f 27865 24743 23621 
s 257
f 27194 26832 32518 
s 1026
f 24743 27865 32593 
s off
f 30442 31205 31775 
f 28529 30337 30420 
f 28553 30381 26187 
f 31514 31754 31649 
f 31651 31750 31500 
f 26190 30378 28556 
s 1040
f 23392 33246 32990 
s 257
f 33589 27373 25929 
f 26756 25929 27373 
s 1026
f 27767 26097 24494 
s off
f 21056 22073 21043 
f 23414 33322 22073 
f 30350 30433 21164 
f 31353 22711 21164 
s 264
f 33178 33734 27205 
s 1026
f 26130 28130 32920 
s 257
f 26046 23197 26615 
s 1026
f 28130 26130 27721 
f 28399 32560 32582 
f 28382 28399 26958 
s 257
f 32580 27320 26792 
f 26787 26792 27320 
f 27326 27372 26712 
s 1026
f 25452 25455 27736 
s 264
f 32647 26712 27372 
s 1040
f 32985 27736 25455 
s off
f 28554 30380 26189 
f 31563 31745 31654 
f 26188 30379 28555 
f 31650 31733 31562 
s 1026
f 22453 24503 28197 
s 1040
f 33641 28197 24503 
s 257
f 27465 27130 26524 
s 264
f 33344 26524 27130 
s off
f 31192 31755 24328 
f 26168 30387 28572 
f 24327 31756 31193 
f 28575 30386 26171 
s 257
f 25939 27272 23574 
s 264
f 32853 33829 27272 
s 1026
f 26356 28652 28745 
s 1040
f 32083 32155 28652 
s 257
f 23573 23728 26763 
s 1026
f 24608 28483 24913 
s 264
f 33831 26763 23728 
s 1040
f 28483 32222 32176 
s off
f 26158 30389 29086 
f 31523 31738 32681 
f 26161 30392 28639 
f 31561 31743 32683 
f 33763 31737 31502 
f 29085 30398 26170 
f 28640 30397 26167 
f 33503 31739 31487 
f 26159 30394 29081 
f 31559 31740 33660 
f 29082 30396 26164 
f 33515 31744 31488 
s 264
f 26523 25964 26071 
s 1026
f 33574 24767 26350 
s 1040
f 28459 26350 24767 
s 264
f 27130 33645 33344 
f 32673 32674 27224 
s 1040
f 33368 33370 25469 
s 264
f 26058 23537 24055 
s 1040
f 24487 26090 27955 
s 1026
f 28135 24872 27843 
s 257
f 26805 27415 32657 
s 1026
f 24872 28135 33124 
s 257
f 27415 26805 26616 
s off
f 22114 30078 30712 
f 22696 22114 30716 
s 257
f 23715 27160 27335 
s 1026
f 27603 25488 25427 
s 264
f 32608 32780 27160 
s 1040
f 25488 27603 32766 
f 28519 24425 32082 
s 257
f 23722 27180 27202 
s 1026
f 24425 28519 28112 
s 264
f 32740 32450 27180 
s 257
f 25979 23387 27505 
s 1026
f 27848 24739 25503 
s 1040
f 24739 27848 32609 
s 257
f 32657 32653 26805 
s 1040
f 33251 33258 25443 
s 257
f 25943 27444 33773 
f 27444 25943 26823 
s 1026
f 24492 26127 27831 
s off
f 25266 30046 26379 
f 30931 31131 24972 
f 26380 30045 25265 
f 24959 31130 30930 
f 30579 30693 30081 
f 24708 30501 26279 
s 264
f 23577 27263 26533 
s 1040
f 24609 28158 24866 
s 257
f 33537 26533 27263 
s 1026
f 28158 32263 32196 
f 28227 26347 24509 
s 257
f 26609 32729 33593 
s 1040
f 22562 32782 32778 
s 257
f 26619 26609 26018 
s 1026
f 28465 26343 24505 
s 257
f 26806 32620 33617 
s 1040
f 22561 32975 32963 
s 257
f 26771 26806 26043 
s off
f 29800 29798 24634 
f 29812 29805 24630 
s 516
f 24562 25910 28034 
s 257
f 27247 26026 23183 
s 544
f 32170 28034 25910 
s 257
f 26796 32423 32421 
f 26461 25942 27331 
s 1026
f 27525 27875 24757 
s 257
f 33805 27331 25942 
s 1026
f 27875 32176 32222 
f 25544 25552 27551 
s 264
f 33625 26729 23760 
s 1040
f 33052 27551 25552 
s 257
f 24375 23760 26729 
s off
f 30709 22149 22614 
f 23658 30420 22149 
f 26169 30401 28566 
f 31557 31749 31648 
f 28570 30399 26172 
f 31653 31751 31505 
s 264
f 32644 32604 27286 
s 1026
f 24873 28133 32628 
s 257
f 26050 23205 26733 
s 1026
f 28133 24873 27761 
s 1040
f 24886 28119 32422 
s 257
f 26042 23219 26661 
s 1026
f 28119 24886 27666 
s 257
f 32518 32519 27194 
s off
f 21082 31601 26897 
f 24713 29707 23588 
f 26898 31602 21080 
f 23590 29709 24717 
f 21081 31605 26893 
f 24716 29706 23591 
f 26895 31600 21013 
f 23594 29708 24714 
f 29083 30382 26151 
f 31652 31748 31542 
f 31539 31747 31647 
f 26152 30383 29084 
s 1026
f 24504 26300 28203 
s 257
f 32780 32608 26825 
f 26054 26825 26586 
s 1040
f 32615 32773 22116 
s 1026
f 27675 24675 33114 
s 257
f 23410 25934 26606 
s 1026
f 24675 27675 27637 
s 264
f 33594 33595 27386 
s 257
f 27143 23307 23538 
s 1026
f 25417 24499 26126 
s 257
f 26714 33299 33724 
s 1026
f 27598 33250 33637 
s 257
f 32712 32884 26685 
s 1026
f 26105 28127 32864 
s 257
f 26039 26685 26847 
s 1026
f 28127 26105 27813 
s off
f 22172 30079 30695 
f 22615 22172 30717 
s 1026
f 24508 26346 28282 
s 257
f 32654 32885 26724 
f 26060 26724 26772 
s 1040
f 32826 32867 21998 
s off
f 30713 22175 22191 
f 30692 30082 22175 
f 31353 31338 30440 
f 30427 30341 28536 
f 30438 31336 31347 
f 28545 30330 30419 
s 257
f 32636 27168 25960 
s 1026
f 33597 25162 27837 
s 257
f 26554 25960 27168 
s 1026
f 27681 27837 25162 
s 264
f 27391 33566 33670 
s 1040
f 23465 33290 33272 
s 257
f 26534 23550 26053 
s 1026
f 27858 26086 24568 
s 257
f 26709 24003 27451 
s 1026
f 27651 21739 24621 
s 264
f 24003 26709 32627 
s 1040
f 21739 27651 32849 
s off
f 29094 30410 30362 
f 31727 31763 31555 
f 31544 31769 31728 
f 30363 30411 29093 
f 25984 30250 27975 
f 31620 31323 31129 
f 27974 30251 25983 
f 31126 31322 31621 
s 1026
f 33206 33098 28337 
s 257
f 26670 23125 27382 
s 264
f 23125 26670 32575 
s 1026
f 26337 26960 28659 
s off
f 30559 30550 30623 
f 26244 30666 30559 
f 26986 22191 29960 
f 26986 26255 22191 
f 30653 22192 26985 
f 22192 24002 26985 
f 29819 29817 28064 
f 24632 30463 30086 
s 257
f 32450 32740 26816 
f 26063 26816 26614 
s 1026
f 24089 28968 33777 
f 28968 24089 22195 
s 264
f 24267 27187 27408 
s 1040
f 27708 25515 24599 
s 257
f 27187 24267 33758 
s 1026
f 25515 27708 33738 
f 20856 22198 28938 
s 1040
f 33416 28938 22198 
s 257
f 20854 23071 25958 
f 26596 32734 32546 
f 26672 32610 32631 
s 1026
f 32717 28134 26098 
s 257
f 26693 26672 26045 
s 1026
f 27692 26098 28134 
s 264
f 26608 32557 33359 
s 1040
f 27518 32080 32104 
s 257
f 26464 26608 23389 
s 1026
f 27528 27518 24671 
s off
f 23067 28557 28637 
f 23711 27991 26007 
s 257
f 33775 26061 23543 
s 1026
f 33674 29623 24860 
s 264
f 32588 33065 23543 
s 1040
f 24860 29623 33392 
s off
f 30087 29807 24096 
f 29806 29639 24629 
s 1026
f 26977 28463 28093 
s 257
f 26455 27273 32843 
f 27273 26455 25082 
s 1026
f 32049 32160 28463 
s 1040
f 32822 27615 23689 
s 257
f 24393 27176 24574 
s 1026
f 25475 23689 27615 
s 264
f 27176 32631 32610 
s 1026
f 33376 28355 25097 
s 264
f 27195 27151 26800 
s 257
f 33650 26800 27151 
s 1040
f 22919 25097 28355 
s off
f 29095 30409 30360 
f 31725 31760 31513 
f 30361 30408 29096 
f 31558 31759 31726 
f 21271 28590 26010 
f 28581 28595 23710 
f 28589 28605 23713 
f 21076 28583 26012 
s 257
f 27241 26020 24571 
s 1026
f 22767 28669 26318 
s 257
f 26792 32572 32580 
s 1040
f 28669 32582 32560 
s 257
f 27464 24704 26637 
s 1026
f 28470 25416 23729 
s 264
f 33659 26637 24704 
s 1040
f 25416 32690 33629 
s 257
f 26830 27205 27369 
s 1026
f 27723 24723 25340 
s 264
f 27205 26830 33178 
s 1040
f 24723 27723 33152 
s 264
f 33614 26634 24081 
s 1026
f 22833 25130 28193 
s 1040
f 32614 28193 25130 
s 257
f 27147 24081 26634 
s 1026
f 27512 32294 33689 
s 257
f 24462 23136 26775 
s 264
f 33742 26775 23136 
s 1026
f 23237 24687 26292 
s 257
f 26572 33264 33776 
s 1026
f 33217 25579 27599 
s 257
f 26569 26572 24009 
s 1026
f 24566 27599 25579 
s 257
f 26761 23398 27428 
s 264
f 23398 26761 33076 
s 1026
f 28360 29010 28675 
s 1040
f 29010 28360 33295 
s off
f 33559 31522 31530 
f 30342 30421 29101 
f 29103 30418 30331 
f 31507 31540 32676 
f 33414 31517 31536 
f 30349 30431 29104 
f 30336 30415 29106 
f 32700 31519 31533 
f 31510 31550 33563 
f 29107 30432 30351 
f 29109 30412 30332 
f 21498 31556 33550 
f 30662 29955 22149 
f 29955 30662 30570 
s 257
f 32132 32056 26863 
s 1026
f 32076 32181 27913 
s 257
f 23371 26863 26652 
s 1026
f 24756 27913 24561 
f 24563 27910 24755 
s 257
f 26882 32126 32077 
s 1026
f 27910 32096 32190 
s 257
f 26793 26882 23372 
s 264
f 32232 32091 26871 
s 1040
f 32240 32151 27912 
s 257
f 23374 26871 26629 
s 1026
f 24759 27912 24550 
s 257
f 26631 23376 27346 
s 1026
f 27852 24726 25377 
s 264
f 23376 26631 32583 
s 1040
f 24726 27852 32576 
s 264
f 26817 23375 27282 
s 1040
f 27677 24728 25375 
s 257
f 23375 26817 32598 
s 1026
f 24728 27677 32345 
s 264
f 32551 26840 23377 
s 1040
f 32495 27732 24725 
s 257
f 27188 23377 26840 
s 1026
f 25378 24725 27732 
s 264
f 32205 32169 26867 
s 1040
f 32203 32089 27907 
s 257
f 23369 26867 26755 
s 1026
f 24747 27907 24557 
s 257
f 26885 32149 32165 
s 1026
f 27914 32146 32156 
s 257
f 26657 26885 23370 
s 1026
f 24555 27914 24748 
s 257
f 32114 32071 26866 
s 1026
f 32214 32187 27909 
s 257
f 23368 26866 26593 
s 1026
f 24750 27909 24553 
s 257
f 32256 32164 26884 
s 1026
f 32108 32246 27917 
s 257
f 23366 26884 26747 
s 1026
f 24745 27917 24558 
s 264
f 26881 32221 32259 
s 1040
f 27915 32152 32231 
s 257
f 26749 26881 23365 
s 1026
f 24559 27915 24749 
f 24552 27908 24746 
s 257
f 26880 32085 32130 
s 1026
f 27908 32194 32129 
s 257
f 26597 26880 23367 
f 26883 32224 32112 
s 1026
f 27916 32252 32213 
s 257
f 26814 26883 23363 
s 1026
f 24556 27916 24752 
s 257
f 32110 32229 26879 
s 1026
f 32210 32191 27911 
s 257
f 23362 26879 26564 
s 1026
f 24753 27911 24554 
s 264
f 32403 26550 23378 
s 1040
f 32482 27716 24727 
s 257
f 27296 23378 26550 
s 1026
f 25380 24727 27716 
s 264
f 26886 32122 32236 
s 1040
f 27906 32120 32135 
s 257
f 26705 26886 23364 
s 1026
f 24564 27906 24751 
s 257
f 27332 23384 26836 
s 1026
f 25494 24732 27798 
s 264
f 32349 26836 23384 
s 1040
f 32494 27798 24732 
s 257
f 27167 23383 26717 
s 1026
f 25486 24731 27853 
s 264
f 32395 26717 23383 
s 1040
f 32364 27853 24731 
s 257
f 27135 23382 26839 
s 1026
f 28085 24733 27746 
s 264
f 32408 26839 23382 
s 1040
f 32508 27746 24733 
f 24734 27750 32420 
s 257
f 26794 23381 27148 
s 1026
f 27750 24734 25506 
s 264
f 23381 26794 32554 
s 257
f 27253 23379 26838 
s 1026
f 25508 24736 27646 
s 264
f 32463 26838 23379 
s 1040
f 32396 27646 24736 
s 257
f 32603 26834 23390 
s 1026
f 32595 27631 24744 
s 264
f 27402 23390 26834 
s 1040
f 23622 24744 27631 
s off
f 29962 30700 24535 
f 26228 30623 30707 
s 257
f 32547 27411 23139 
s 1026
f 32419 28145 24912 
s 257
f 26628 23139 27411 
s 1026
f 27655 24912 28145 
s off
f 26244 22336 21102 
f 30719 30084 22336 
s 257
f 27288 24705 26675 
s 264
f 32571 26675 24705 
s 1026
f 28668 29003 28283 
s 1040
f 32938 28283 29003 
s 1026
f 32151 32240 28476 
s 264
f 23216 27493 27329 
s 1040
f 24914 28476 25547 
s 257
f 32091 32232 27493 
s 1040
f 28481 32156 32146 
s 257
f 27216 27501 23203 
s 1026
f 25384 28481 24906 
s 264
f 27501 32165 32149 
s 1026
f 32089 32203 28487 
s 264
f 23201 27497 27349 
s 1040
f 24903 28487 25389 
s 257
f 32169 32205 27497 
f 23200 27500 27307 
s 1026
f 24907 28478 25393 
s 264
f 32164 32256 27500 
s 1040
f 32246 32108 28478 
s 257
f 23202 27492 27141 
s 1026
f 24904 28482 25386 
s 264
f 32071 32114 27492 
s 1040
f 32187 32214 28482 
f 25390 28485 24902 
s 257
f 27498 32259 32221 
s 1026
f 28485 32231 32152 
s 264
f 27355 27498 23198 
s 257
f 27309 27494 23199 
s 1026
f 25394 28474 24905 
s 264
f 27494 32130 32085 
s 1040
f 28474 32129 32194 
f 32191 32210 28484 
s 257
f 23196 27496 27445 
s 1026
f 24908 28484 25387 
s 264
f 32229 32110 27496 
s 1026
f 25392 28479 24909 
s 264
f 27495 32112 32224 
s 1040
f 28479 32213 32252 
s 257
f 27424 27495 23195 
f 27360 27502 23194 
s 1026
f 25379 28486 24900 
s 264
f 27502 32077 32126 
s 1040
f 28486 32190 32096 
s 257
f 23193 27499 27405 
s 1026
f 24869 28473 25545 
s 264
f 32056 32132 27499 
s 1040
f 32181 32076 28473 
f 25382 28477 24910 
s 257
f 27491 32236 32122 
s 1026
f 28477 32135 32120 
s 264
f 27452 27491 23204 
s 257
f 26759 23209 27236 
s 1026
f 27786 24890 28140 
s 257
f 32369 27236 23209 
s 1026
f 32466 28140 24890 
s 257
f 32410 27434 23210 
s 1026
f 32542 28114 24889 
s 257
f 26748 23210 27434 
s 1026
f 27629 24889 28114 
s 257
f 26701 23207 27227 
s 1026
f 27717 24891 28144 
s 257
f 32448 27227 23207 
s 1026
f 32443 28144 24891 
s 257
f 23208 27436 32402 
s 1026
f 24892 28147 32476 
s 257
f 27436 23208 26659 
s 1026
f 28147 24892 27840 
s 257
f 26829 23206 27280 
s 1026
f 25806 24893 28120 
s 257
f 32406 27280 23206 
s 1026
f 32457 28120 24893 
s 264
f 23212 27440 32452 
s 1040
f 24898 28149 32367 
s 257
f 27440 23212 26683 
s 1026
f 28149 24898 27705 
s 257
f 32485 27274 23214 
s 1026
f 32461 28143 24897 
s 257
f 26699 23214 27274 
s 1026
f 27793 24897 28143 
s 257
f 32562 27163 23211 
s 1026
f 32456 28150 24899 
s 257
f 26719 23211 27163 
s 1026
f 27748 24899 28150 
s 257
f 23213 27431 32569 
s 1026
f 24896 28117 32477 
s 257
f 27431 23213 26801 
s 1026
f 28117 24896 27805 
s 264
f 32502 27435 23217 
s 1040
f 32384 28137 24888 
s 257
f 26833 23217 27435 
s 1026
f 27839 24888 28137 
s 257
f 26845 23407 27242 
s 1026
f 27870 24724 25349 
s 264
f 23407 26845 32326 
s 1040
f 24724 27870 32307 
s off
f 31538 31541 33818 
f 29105 30426 30348 
s 257
f 33338 32619 26721 
s 264
f 23112 26721 26807 
s 1026
f 26314 25128 32776 
s 1040
f 25128 26314 28396 
s 257
f 26841 23373 27244 
s 1026
f 27860 25373 25399 
s 264
f 23373 26841 32478 
s 1040
f 25373 27860 32465 
s 257
f 27291 23388 26844 
s 1026
f 23632 25576 27711 
s 264
f 32311 26844 23388 
s 1040
f 32309 27711 25576 
s off
f 22515 22392 21153 
f 21153 22392 25005 
f 21152 22393 22516 
f 25006 22393 21152 
s 1026
f 28398 28173 29001 
s 264
f 27376 27347 26790 
s 257
f 26640 26790 27347 
s 1040
f 25572 29001 28173 
f 28306 29007 25573 
s 1026
f 29007 28306 28290 
s 264
f 26706 27306 27322 
s 257
f 27306 26706 26682 
f 20867 27165 26649 
s 1026
f 20873 25483 27876 
s 264
f 33533 26649 27165 
s 1040
f 33519 27876 25483 
s 1026
f 27678 25430 20766 
s 264
f 27426 26650 33749 
s 1040
f 25430 27678 33319 
s 257
f 26650 27426 20760 
f 26751 27345 27368 
s 1026
f 27781 25447 25444 
s 264
f 27345 26751 33307 
s 1040
f 25447 27781 33212 
s 1026
f 32525 28346 26905 
s 264
f 27177 27352 26835 
s 257
f 32387 26835 27352 
s 1040
f 22760 26905 28346 
s 257
f 27293 27210 26783 
s 264
f 33585 26783 27210 
s 1026
f 28667 26966 24626 
f 28212 32548 32612 
s 264
f 23569 27245 23265 
s 1040
f 24605 28157 24867 
s 257
f 27245 33359 32557 
s 1026
f 28157 32104 32080 
f 28880 26474 33667 
s 1040
f 26474 28880 28865 
s 264
f 33562 33487 27370 
f 23059 22408 26679 
f 26623 27235 27423 
s 1040
f 27635 23619 25338 
s 257
f 27235 26623 32665 
s 1026
f 23619 27635 33541 
f 27529 24317 24516 
s 264
f 24410 25082 26873 
s 1040
f 24317 27529 27926 
s 257
f 25082 24410 27273 
f 26653 27466 27131 
s 1026
f 25636 25360 25559 
s 257
f 27466 26653 26526 
s 1026
f 25360 25636 27869 
s 257
f 26844 26695 27291 
s 1026
f 27711 27712 23632 
s 257
f 27206 27291 26695 
s 1026
f 25359 23632 27712 
s 257
f 26729 26734 24375 
s 1026
f 27551 25634 25544 
s 264
f 27388 24375 26734 
s 1040
f 25348 25544 25634 
s 1026
f 23631 27851 27854 
s 257
f 26707 27154 27298 
s 1026
f 27851 23631 23118 
s 257
f 27154 26707 26458 
f 26665 27264 27270 
s 1026
f 27673 23621 25405 
s 264
f 27264 26665 26832 
s 1040
f 23621 27673 27865 
s 257
f 27430 27402 26811 
s 1026
f 25401 23622 27823 
s 264
f 26834 26811 27402 
s 1040
f 27631 27823 23622 
s 264
f 26600 27427 27246 
s 1040
f 27818 23623 25409 
s 257
f 27427 26600 26549 
s 1026
f 23623 27818 27855 
s 257
f 26837 26573 27330 
s 1026
f 27863 27613 23620 
s 264
f 27179 27330 26573 
s 1040
f 25406 23620 27613 
s 257
f 27356 27334 26745 
s 1026
f 25397 23627 27769 
s 264
f 33633 26745 27334 
s 1040
f 33096 27769 23627 
s 264
f 26784 27428 27409 
s 1040
f 27803 23628 25388 
s 257
f 27428 26784 26761 
s 1026
f 23628 27803 27787 
s 264
f 27328 27288 26697 
s 1040
f 25391 23625 27724 
s 257
f 26675 26697 27288 
s 1026
f 27704 27724 23625 
s 264
f 26581 27382 27271 
s 1040
f 27612 23626 25395 
s 257
f 27382 26581 26670 
s 1026
f 23626 27612 26111 
s 264
f 27191 27173 26555 
s 1040
f 25383 23624 27658 
s 257
f 25965 26555 27173 
s 1026
f 26115 27658 23624 
f 23232 27927 23630 
s 264
f 23578 24390 26876 
s 1040
f 24615 23630 27927 
s 257
f 25971 26876 24390 
s 264
f 26875 24389 23584 
s 1040
f 27924 23629 24618 
s 257
f 24389 26875 26566 
s 1026
f 23629 27924 23092 
s 264
f 24184 24391 27327 
s 1040
f 25807 25376 25434 
s 257
f 24391 24184 26889 
s 1026
f 25376 25807 27552 
f 25440 25818 27809 
s 264
f 26802 24392 27421 
s 1040
f 25818 25440 25497 
s 257
f 24392 26802 26736 
s 264
f 27281 24393 26612 
s 1040
f 25460 25475 25817 
s 257
f 24574 26612 24393 
s 1026
f 27615 25817 25475 
f 28120 23634 25806 
s 264
f 26531 26829 27462 
s 1040
f 25701 25806 23634 
s 257
f 27280 27462 26829 
f 26766 27460 27295 
s 1026
f 25808 23633 28115 
s 264
f 27460 26766 26530 
s 1040
f 23633 25808 25703 
s 257
f 26822 27419 27363 
s 1026
f 27836 23647 28123 
s 264
f 27419 26822 26648 
s 1040
f 23647 27836 27810 
s 257
f 23718 26529 27137 
s 1026
f 27664 25702 28082 
s 264
f 27461 27137 26529 
s 1040
f 23636 28082 25702 
s 264
f 26532 27138 27457 
s 1040
f 25704 28083 23635 
s 257
f 27138 26532 25947 
s 1026
f 28083 25704 26087 
s 257
f 26831 27457 27157 
s 1026
f 25801 23635 25491 
s 264
f 27457 26831 26532 
s 1040
f 23635 25801 25704 
f 25702 25800 23636 
s 257
f 26043 27461 26771 
s 1026
f 24013 23636 25800 
s 264
f 26529 26771 27461 
s 257
f 25084 24405 26041 
s 1026
f 27534 24319 24730 
s 257
f 24405 25084 26878 
s 1026
f 24319 27534 27929 
f 27524 24318 25536 
s 264
f 24406 25081 26877 
s 1040
f 24318 27524 27928 
s 257
f 25081 24406 26047 
s 264
f 27462 27135 26531 
s 1040
f 23634 28085 25701 
s 257
f 26839 26531 27135 
s 1026
f 27746 25701 28085 
s 264
f 26530 27139 27460 
s 1040
f 25703 28084 23633 
s 257
f 27139 26530 26720 
s 1026
f 28084 25703 27857 
s 257
f 27129 27465 26571 
s 1026
f 25432 25419 25816 
s 257
f 26524 26571 27465 
s 1026
f 27623 25816 25419 
s 257
f 26606 27459 23410 
s 1026
f 27637 25476 24675 
s 264
f 27459 26606 26527 
s 1040
f 25476 27637 27614 
s 257
f 23394 27458 26591 
s 1026
f 24669 25479 27622 
s 264
f 26528 26591 27458 
s 1040
f 27610 27622 25479 
s 257
f 26872 26464 24415 
s 1026
f 27923 27528 23642 
s 257
f 23389 24415 26464 
s 1026
f 24671 23642 27528 
s 1040
f 27925 27531 23640 
s 257
f 26070 24417 26465 
s 1026
f 24084 23640 27531 
s 264
f 26874 26465 24417 
s 257
f 26864 26463 24414 
s 1026
f 27918 27523 23643 
s 257
f 26064 24414 26463 
s 1026
f 24729 23643 27523 
s 257
f 26032 24413 26462 
s 1026
f 28155 23644 27527 
s 264
f 26870 26462 24413 
s 1040
f 27922 27527 23644 
s 257
f 27331 24416 26461 
s 1026
f 24757 23641 27525 
s 264
f 26865 26461 24416 
s 1040
f 27919 27525 23641 
s 257
f 26557 26582 27366 
s 1026
f 27715 27661 23646 
s 257
f 26066 27366 26582 
s 1026
f 28118 23646 27661 
s 264
f 27458 27134 26528 
s 1040
f 25479 23645 27610 
s 257
f 26543 26528 27134 
s 1026
f 25908 27610 23645 
s 257
f 25085 24403 27312 
s 1026
f 27530 24320 24674 
s 257
f 24403 25085 26869 
s 1026
f 24320 27530 27920 
s 264
f 26605 27455 27453 
s 1040
f 27777 25372 24622 
s 257
f 27455 26605 25967 
s 1026
f 25372 27777 26106 
s 257
f 25949 26754 27454 
s 1026
f 27725 27765 25368 
s 264
f 27450 27454 26754 
s 1040
f 24620 25368 27765 
s off
f 28561 28580 22471 
f 21375 24447 22471 
f 29143 30979 30103 
f 29887 30729 21422 
f 21421 30730 29886 
f 30102 30978 29144 
f 29891 30737 21530 
f 29147 30983 30100 
f 21529 30736 29890 
f 30101 30982 29148 
f 29917 30739 21528 
f 29153 30987 30096 
f 21527 30738 29916 
f 30097 30986 29156 
f 29146 30988 30105 
f 30000 30793 21519 
f 21518 30792 29999 
f 30104 30989 29145 
f 30047 30812 21511 
f 29154 30980 30109 
f 21510 30811 30048 
f 30108 30981 29155 
s 264
f 26440 23405 27152 
s 1040
f 27696 25546 25370 
s 257
f 23405 26440 32330 
s 1026
f 25546 27696 32329 
s 257
f 26526 23406 27466 
s 1026
f 27869 25358 25360 
s 264
f 23406 26526 32318 
s 1040
f 25358 27869 32315 
s off
f 26264 30513 32590 
f 26263 30877 30186 
f 30343 30416 29110 
f 31553 33813 28634 
s 257
f 27190 23180 26842 
s 264
f 32558 26842 23180 
s 1026
f 22733 28657 28443 
s 1040
f 32437 28443 28657 
s off
f 30099 30975 29160 
f 21509 30787 29972 
f 29973 30786 21508 
f 29159 30974 30098 
s 1026
f 28317 28781 22865 
s 1040
f 28781 28317 33418 
s 257
f 26716 27418 27361 
s 264
f 27418 26716 33423 
s 257
f 23409 23717 26638 
s 1026
f 25429 27728 27652 
s 264
f 33612 32968 27396 
s 1026
f 27728 25429 33109 
s 264
f 33673 26439 22494 
s 1026
f 25637 29519 27102 
s 1040
f 33230 27102 29519 
s 257
f 27420 22494 26439 
s 1026
f 33580 33080 25514 
f 22496 25514 29035 
s 257
f 33691 33218 26782 
f 23060 26782 26603 
f 23264 24011 23572 
s 1026
f 25811 28488 24613 
s 1040
f 28488 25811 32166 
s 257
f 32843 33697 26455 
s off
f 29977 30839 24854 
f 29940 30590 24843 
f 31293 31254 30369 
f 23311 30271 28012 
f 28014 30275 23312 
f 30371 31253 31292 
f 28009 30279 23313 
f 30366 31259 31299 
f 23314 30280 28008 
f 31298 31258 30367 
f 23315 30274 28013 
f 31295 31252 30370 
f 30364 31260 31301 
f 28011 30281 23317 
f 31300 31261 30365 
f 23316 30282 28010 
f 28015 30276 23318 
f 30368 31255 31294 
f 29098 30423 30334 
f 21427 31196 31901 
f 31902 31194 21426 
f 30333 30422 29102 
f 32346 31566 31491 
f 30335 30413 29108 
f 30341 30427 29099 
f 33647 31515 31526 
f 31512 31546 32688 
f 29100 30419 30330 
f 28000 30257 25981 
f 32035 31325 31128 
f 25982 30256 28001 
f 31127 31324 32036 
f 24971 22515 21172 
f 21153 21172 22515 
f 21173 22516 24975 
f 22516 21173 21152 
s 1026
f 28421 24627 26901 
s 257
f 33705 27398 26563 
s 1040
f 24766 33281 32513 
s 257
f 26552 26563 27398 
s 516
f 27610 25908 28039 
s 257
f 27134 27392 26543 
s 544
f 32732 28039 25908 
s 264
f 32761 26543 27392 
f 26451 27312 32743 
s 1040
f 32078 32111 27515 
s 257
f 27312 26451 25085 
s 1026
f 24674 27515 27530 
s 257
f 27229 27172 26607 
s 1026
f 25418 25505 27638 
s 264
f 32856 26607 27172 
s 1040
f 32837 27638 25505 
s off
f 29832 29857 24859 
f 29826 29855 24642 
f 24641 29856 29827 
f 24858 29858 29833 
f 26009 28582 28610 
f 21140 28607 22524 
f 30186 30948 26263 
f 31791 31894 31048 
s 257
f 23656 27294 27333 
s 1026
f 27714 24758 25558 
s 264
f 32884 32712 27294 
s 1040
f 24758 27714 33039 
s 257
f 27397 27365 26777 
s 1026
f 25347 25350 27804 
s 264
f 32417 26777 27365 
s 1040
f 33802 27804 25350 
s 257
f 26575 33292 32731 
s 1026
f 32409 28148 27608 
s 257
f 26791 26575 24005 
s 1026
f 27606 27608 28148 
s off
f 33439 22532 20955 
f 22532 31905 31072 
f 31481 30784 29944 
f 30137 30967 29161 
f 31073 31906 22534 
f 20928 22534 33498 
f 29945 30785 31482 
f 29162 30968 30136 
f 27981 29809 27979 
f 27980 29804 23503 
f 31467 30798 29994 
f 30088 30991 29151 
f 29152 30990 30089 
f 29993 30799 31468 
f 30093 30984 29157 
f 31469 30796 29995 
f 29996 30797 31470 
f 29158 30985 30092 
f 30111 30973 29150 
f 31471 30794 29992 
f 29991 30795 31472 
f 29149 30972 30110 
f 24635 28632 29849 
f 24640 29862 29837 
f 29836 29861 24639 
f 29848 28631 24636 
s 257
f 32544 27201 23220 
s 1026
f 32413 28151 24885 
s 257
f 26548 23220 27201 
s 1026
f 27620 24885 28151 
s 257
f 23218 27252 32493 
s 1026
f 24887 28146 32377 
s 257
f 27252 23218 26731 
s 1026
f 28146 24887 27676 
s off
f 23005 28613 23703 
f 26007 28586 23711 
s 257
f 26720 23380 27139 
s 1026
f 27857 24735 28084 
s 264
f 23380 26720 32539 
s 1040
f 24735 27857 32405 
s off
f 28608 28578 26006 
f 28558 28638 25600 
f 26237 30550 24842 
f 29953 30707 30623 
f 30810 30543 29627 
f 31757 31042 31371 
f 31370 31043 31758 
f 29628 30542 30809 
s 257
f 23215 27383 33570 
s 1026
f 27832 28153 32281 
s 257
f 27383 23215 26558 
s 1026
f 28153 27832 27878 
s off
f 29965 30668 30585 
f 26226 30614 29978 
f 30566 30654 29966 
f 29979 30594 26222 
f 23341 21007 22557 
f 33663 31635 31506 
f 22558 21004 23339 
f 31528 31624 32692 
f 22559 20973 23334 
f 31532 31626 33352 
f 23340 20954 22560 
f 33545 31637 31508 
s 257
f 27137 27243 23718 
s 1026
f 28082 25424 27664 
s 264
f 27243 33617 32620 
s 1040
f 32941 27664 25424 
s 257
f 27186 27170 23716 
s 1026
f 25478 25468 27797 
s 264
f 27170 33593 32729 
s 1040
f 32772 27797 25468 
s off
f 24360 31607 26894 
f 24715 29711 23589 
f 23595 29710 24718 
f 26896 31603 24352 
f 30695 30597 29920 
f 23122 30572 22566 
f 29921 30589 30671 
f 22567 30570 23123 
f 22568 30573 21563 
f 29922 30558 30692 
f 30496 30808 29700 
f 31904 31197 21474 
f 21475 31195 31903 
f 29701 30807 30497 
f 30648 30551 26240 
f 30701 30605 29935 
s 1026
f 33556 28772 26354 
s 1040
f 28341 26354 28772 
s 257
f 33666 27358 26728 
s 264
f 26602 26728 27358 
s 257
f 32589 27422 26542 
s 1026
f 29249 29615 27579 
s 257
f 26560 26542 27422 
s 1026
f 32581 27579 29615 
s off
f 23111 26287 22576 
f 29097 30414 30339 
f 28635 31634 31567 
s 257
f 26678 23305 27463 
s 264
f 23305 26678 32672 
s 1026
f 28269 28776 25568 
s 1040
f 28776 28269 33361 
s off
f 29982 30843 24585 
f 26225 30585 30668 
f 30654 30566 26231 
f 24586 30844 29981 
s 1040
f 28472 25882 32266 
s 257
f 26592 24010 23571 
s 1026
f 25882 28472 24606 
s 264
f 24010 26592 32338 
f 26725 27325 23575 
s 516
f 25002 28038 32105 
s 257
f 27325 26725 33426 
s 544
f 28038 25002 27929 
s 257
f 26703 26796 27213 
s 1026
f 29605 32043 32170 
s 257
f 32421 27213 26796 
s 1026
f 29257 29605 24455 
s 1040
f 25469 27660 33368 
s 257
f 26601 27224 20747 
s 1026
f 27660 25469 20753 
s 264
f 27224 26601 32673 
s 257
f 27432 26576 26580 
s 1026
f 25537 27738 27633 
s 257
f 26576 27432 32704 
s 1026
f 27738 25537 33115 
f 25267 33187 32733 
s 257
f 33280 27222 23108 
s 1026
f 29592 25267 24313 
s 257
f 26722 23108 27222 
s 1040
f 28175 33576 33577 
s 264
f 27214 27359 26590 
s 257
f 32669 26590 27359 
s 1040
f 22779 24684 26304 
f 33055 28398 25138 
s 257
f 27347 27400 26640 
s 264
f 33135 26640 27400 
s 1026
f 29001 25138 28398 
s 257
f 25086 27220 27257 
s 264
f 27220 25086 33772 
s 1026
f 28323 29015 28666 
s 1040
f 29015 28323 32534 
s off
f 30248 30300 28606 
f 28072 25895 31665 
f 28614 30299 30249 
f 31666 25896 28073 
f 30413 30335 28543 
f 31351 31332 30441 
f 28539 30339 30414 
f 30450 31334 31352 
f 30246 30302 28616 
f 28070 25899 31474 
f 31473 25900 28071 
f 28617 30301 30247 
s 257
f 26540 27318 27161 
s 264
f 27318 26540 32750 
s 1026
f 28454 25131 22859 
s 1040
f 25131 28454 32331 
s off
f 30446 31199 31779 
f 28524 30347 30430 
f 31780 31198 30443 
f 30433 30350 28525 
f 31776 31204 30439 
f 30425 30346 28528 
f 30454 31206 31773 
f 28531 30338 30417 
f 30424 30345 28530 
f 31774 31207 30449 
s 257
f 26639 23386 27262 
s 1026
f 27679 25426 25461 
s 264
f 23386 26639 33707 
s 1040
f 25426 27679 32891 
s 257
f 26646 23385 27269 
s 1026
f 27683 25465 25459 
s 264
f 23385 26646 33708 
s 1040
f 25465 27683 32906 
s off
f 26984 26251 22614 
f 26984 22614 30682 
f 30667 22615 26983 
f 22615 26250 26983 
f 29118 31013 30161 
f 31459 31700 24980 
f 30160 31014 29117 
f 24969 31697 31455 
f 29796 31015 29125 
f 24968 31693 31458 
f 29126 31017 29795 
f 31457 31696 24973 
f 31454 31691 24984 
f 29128 31029 30145 
f 30144 31028 29127 
f 24964 31692 31451 
f 29136 31031 30150 
f 31453 31690 21260 
f 29134 31033 30152 
f 31456 31695 24981 
f 30149 31032 29133 
f 21261 31694 31452 
f 30151 31030 29135 
f 24957 31698 31450 
f 24967 31685 31445 
f 30154 31020 29120 
f 29119 31018 30153 
f 31447 31686 24982 
f 30148 31021 29123 
f 24960 31684 31444 
f 29122 31019 30147 
f 31446 31683 24974 
f 30157 31025 29132 
f 24962 31687 31448 
f 31449 31688 24983 
f 29131 31023 30158 
f 24970 31679 31442 
f 30159 31022 29121 
f 29124 31027 30146 
f 31443 31680 24985 
s 257
f 27265 23395 26633 
s 1026
f 25354 25357 26118 
s 264
f 32491 26633 23395 
s 1040
f 25357 32936 33783 
s off
f 30155 31024 29130 
f 32030 31682 31596 
f 29129 31026 30156 
f 31597 31681 32029 
f 30702 22638 29962 
f 30718 22985 22638 
s 257
f 26698 33624 32903 
s 1026
f 33009 25425 27649 
s 257
f 26690 26698 23411 
s 1026
f 27641 27649 25425 
s 257
f 27250 23408 26643 
s 1026
f 25356 25355 27672 
s 264
f 33389 26643 23408 
s 1040
f 33378 27672 25355 
s 257
f 26702 23412 27219 
s 1026
f 27624 25342 25346 
s 264
f 23412 26702 33720 
s 1040
f 25342 27624 33795 
s 1026
f 27879 25362 33168 
s 257
f 23413 26545 26846 
s 1026
f 25362 27879 27867 
s 257
f 32715 33806 26545 
s off
f 27513 28559 23695 
f 28595 28581 26008 
f 30107 30971 26259 
f 21425 28032 29889 
f 29888 28033 21370 
f 26260 30970 30106 
f 23695 28598 27513 
f 21058 26376 22650 
f 30995 31611 31619 
f 30057 29988 25902 
f 25903 29985 30058 
f 31618 31610 30994 
f 25172 22653 23089 
f 32695 31493 31565 
f 23085 22654 25173 
f 31564 31495 32691 
s 1026
f 26328 26963 25581 
f 33113 33064 28186 
s off
f 26899 31604 21134 
f 23592 29704 24720 
f 21135 31606 26900 
f 24719 29705 23593 
f 30238 31394 30856 
f 30855 31397 30239 
f 30235 31391 30860 
f 30859 31390 30234 
f 30241 31396 30857 
f 30858 31395 30240 
f 30852 31400 30237 
f 30236 31403 30849 
f 30861 31393 26286 
f 26285 31392 30862 
f 26283 31398 30854 
f 30853 31399 26284 
f 30851 31402 26281 
f 26282 31401 30850 
f 22392 31380 30870 
f 30870 31380 31419 
f 30869 31381 22393 
f 31418 31381 30869 
f 21523 31385 30865 
f 30865 31385 31422 
f 30866 31384 21524 
f 31423 31384 30866 
f 21515 31375 30873 
f 30873 31375 31415 
f 31414 31374 30874 
f 30874 31374 21516 
f 30876 31377 31413 
f 21019 31377 30876 
f 31412 31376 30875 
f 30875 31376 21022 
f 30872 31378 31417 
f 21525 31378 30872 
f 30871 31379 21526 
f 31416 31379 30871 
f 30868 31383 31420 
f 21534 31383 30868 
f 30867 31382 21535 
f 31421 31382 30867 
f 21506 31386 30864 
f 30864 31386 31425 
f 30863 31387 21507 
f 31424 31387 30863 
f 29140 31038 30135 
f 31594 31677 32031 
f 30134 31039 29139 
f 32032 31678 31595 
f 29138 31037 30138 
f 31592 31675 32033 
f 32034 31676 31593 
f 30139 31036 29137 
f 25586 29816 29818 
f 27979 29803 27981 
f 32007 24944 23511 
f 23512 24945 32008 
s 1026
f 28386 26340 28789 
f 32279 28789 26340 
s 257
f 27336 27324 26813 
s 264
f 33478 26813 27324 
s 1026
f 22794 25127 28452 
s 1040
f 32312 28452 25127 
s off
f 29963 22696 30684 
f 22696 26247 30684 
f 32590 33397 26264 
f 31883 31884 20948 
s 1026
f 32643 32879 28377 
f 24438 28377 28357 
s off
f 31189 31200 30306 
f 30300 30248 27959 
f 27958 30249 30299 
f 30305 31201 31188 
f 30302 30246 27948 
f 31190 31186 30304 
f 30303 31187 31191 
f 27949 30247 30301 
s 257
f 26544 27181 33148 
s 1026
f 27841 25578 33060 
s 257
f 27181 26544 26599 
s 1026
f 25578 27841 24565 
s off
f 28631 29848 31615 
f 24637 29882 30051 
f 31614 29849 28632 
f 30050 29883 24638 
f 21167 30206 21690 
f 22707 22149 23741 
f 22708 22985 23742 
f 21423 30204 21692 
f 23743 22720 22709 
f 21653 30195 21419 
f 21689 30201 21174 
f 23745 22172 22710 
f 22074 30202 21598 
f 23747 21164 22711 
f 23746 22114 22712 
f 21693 30199 21221 
f 22713 22175 23750 
f 21397 30196 21654 
f 22714 22336 23752 
f 21393 30192 21691 
s 1026
f 27648 28126 24596 
s 264
f 27300 23608 33001 
s 1040
f 28126 27648 33611 
s 257
f 23608 27300 27321 
s 1026
f 22216 32678 32677 
f 25159 24591 22717 
s off
f 31720 32028 31857 
f 31782 31906 31073 
f 31072 31905 31781 
f 31858 32027 31719 
f 22720 30081 30693 
f 22192 22720 30720 
s 264
f 26454 27257 27339 
s 257
f 27257 26454 25086 
s 1040
f 28265 28666 22726 
s 1026
f 28666 28265 28323 
s 257
f 26439 26460 27420 
s 1040
f 22744 28679 28417 
s 1026
f 21911 28417 28679 
s 264
f 27142 27420 26460 
f 26452 27203 27185 
s 257
f 27203 26452 24520 
s 1040
f 28166 28663 22740 
s 1026
f 28663 28166 26301 
s 257
f 26453 27408 27171 
s 264
f 27408 26453 24267 
s 1026
f 28422 28677 22749 
s 1040
f 28677 28422 28379 
f 28324 28670 22735 
s 1026
f 28670 28324 21679 
s 264
f 26457 27321 27354 
s 257
f 27321 26457 23608 
f 26746 27339 26044 
s 1026
f 27737 25549 28141 
s 264
f 27339 26746 26454 
s 1040
f 25549 27737 27684 
s 257
f 27405 26715 23193 
s 1026
f 25545 27826 24869 
s 264
f 26715 27405 27447 
s 1040
f 27826 25545 28109 
s 264
f 26873 23572 24410 
s 257
f 23572 26873 23264 
s 1040
f 28508 28741 28988 
s 1026
f 28741 28508 22213 
s 264
f 23265 26872 23569 
s 1026
f 28987 28740 28506 
s 1040
f 22204 28506 28740 
s 257
f 24415 23569 26872 
f 26687 27329 27237 
s 1026
f 27632 25547 28101 
s 264
f 27329 26687 23216 
s 1040
f 25547 27632 24914 
s 264
f 26635 27244 27279 
s 1040
f 27663 25399 25529 
s 257
f 27244 26635 26841 
s 1026
f 25399 27663 27860 
s 257
f 27422 27429 26560 
s 1026
f 28136 25402 27861 
s 264
f 26828 26560 27429 
s 1040
f 27670 27861 25402 
s 257
f 26842 26589 27190 
s 1026
f 27862 27630 25400 
s 264
f 27225 27190 26589 
s 1040
f 25535 25400 27630 
s 264
f 26689 26785 27443 
s 1040
f 27687 27772 25407 
s 257
f 26021 27443 26785 
s 1026
f 21799 25407 27772 
s 257
f 26765 27354 26048 
s 1026
f 27779 25404 21678 
s 264
f 27354 26765 26457 
s 1040
f 25404 27779 27751 
s 257
f 26666 27271 27197 
s 1026
f 27668 25395 25532 
s 264
f 27271 26666 26581 
s 1040
f 25395 27668 27612 
s 264
f 27375 27309 26595 
s 1040
f 28103 25394 27601 
s 257
f 23199 26595 27309 
s 1026
f 24905 27601 25394 
s 264
f 23198 26758 27355 
s 1040
f 24902 27752 25390 
s 257
f 27183 27355 26758 
s 1026
f 28110 25390 27752 
s 264
f 27255 27216 26617 
s 1040
f 28100 25384 27659 
s 257
f 23203 26617 27216 
s 1026
f 24906 27659 25384 
s 257
f 26580 27185 27432 
s 1026
f 27633 25396 25537 
s 264
f 27185 26580 26452 
s 1040
f 25396 27633 27690 
s 257
f 27232 27191 26583 
s 1026
f 25530 25383 27619 
s 264
f 26555 26583 27191 
s 1040
f 27658 27619 25383 
f 27720 25393 28106 
s 257
f 27307 26704 23200 
s 1026
f 25393 27720 24907 
s 264
f 26704 27307 27342 
s 257
f 27141 26848 23202 
s 1026
f 25386 27859 24904 
s 264
f 26848 27141 27196 
s 1040
f 27859 25386 28099 
s 257
f 27155 27142 26826 
s 1026
f 24754 25385 27866 
s 264
f 26460 26826 27142 
s 1040
f 27833 27866 25385 
s 264
f 27412 27356 26768 
s 1040
f 25441 25397 27791 
s 257
f 26745 26768 27356 
s 1026
f 27769 27791 25397 
s 264
f 26697 26739 27328 
s 1040
f 27724 27755 25391 
s 257
f 27297 27328 26739 
s 1026
f 24501 25391 27755 
s 257
f 26757 27349 27351 
s 1026
f 27771 25389 28107 
s 264
f 27349 26757 23201 
s 1040
f 25389 27771 24903 
s 1026
f 27829 25388 24500 
s 264
f 27409 26696 26784 
s 1040
f 25388 27829 27803 
s 257
f 26696 27409 27377 
s 1026
f 25403 27822 27828 
s 257
f 26588 27171 26023 
s 1026
f 27822 25403 24058 
s 257
f 27171 26588 26453 
s 1026
f 28116 25408 27799 
s 257
f 26750 26824 27182 
s 1026
f 27780 27799 25408 
s 257
f 27208 27182 26824 
f 27435 27430 26833 
s 1026
f 28137 25401 27839 
s 257
f 26811 26833 27430 
s 1026
f 27823 27839 25401 
s 257
f 26731 27246 27252 
s 1026
f 27676 25409 28146 
s 264
f 27246 26731 26600 
s 1040
f 25409 27676 27818 
s 257
f 26661 27270 26042 
s 1026
f 27666 25405 28119 
s 257
f 27270 26661 26665 
s 1026
f 25405 27666 27673 
s 1040
f 27613 27620 25406 
s 257
f 27201 27179 26548 
s 1026
f 28151 25406 27620 
s 264
f 26573 26548 27179 
s 257
f 26584 27189 24004 
s 264
f 27189 26584 26561 
s 1026
f 28195 28660 21862 
s 1040
f 28660 28195 28171 
s 257
f 27182 27344 26750 
s 264
f 26809 26750 27344 
s 1026
f 22750 28672 28351 
s 1040
f 28202 28351 28672 
s 1026
f 28428 28423 28680 
s 264
f 27429 27425 26828 
s 257
f 25975 26828 27425 
s 1040
f 22732 28680 28423 
s 264
f 26752 27346 27395 
s 1040
f 27782 25377 25531 
s 257
f 27346 26752 26631 
s 1026
f 25377 27782 27852 
f 27732 27774 25378 
s 264
f 27310 27188 26536 
s 1040
f 25534 25378 27774 
s 257
f 26840 26536 27188 
f 27150 27177 26818 
s 1026
f 25542 25374 27814 
s 264
f 26835 26818 27177 
s 1040
f 27766 27814 25374 
s 257
f 26684 27282 27287 
s 1026
f 27706 25375 25543 
s 264
f 27282 26684 26817 
s 1040
f 25375 27706 27677 
s 264
f 27389 27360 26770 
s 1040
f 28105 25379 27795 
s 257
f 23194 26770 27360 
s 1026
f 24900 27795 25379 
f 27716 27727 25380 
s 264
f 27319 27296 26700 
s 1040
f 25540 25380 27727 
s 257
f 26550 26700 27296 
s 264
f 26677 27445 27164 
s 1040
f 27864 25387 28098 
s 257
f 27445 26677 23196 
s 1026
f 25387 27864 24908 
s 257
f 23195 26767 27424 
s 1026
f 24909 27824 25392 
s 264
f 27145 27424 26767 
s 1040
f 28111 25392 27824 
s 257
f 26783 26689 27293 
s 1040
f 22734 28667 28273 
s 1026
f 24626 28273 28667 
s 264
f 27443 27293 26689 
f 27315 27241 26744 
s 1040
f 25539 25381 27745 
s 257
f 24571 26744 27241 
s 1026
f 27597 27745 25381 
f 25336 27872 24847 
s 264
f 26743 27146 27209 
s 1040
f 27872 25336 25541 
s 257
f 27146 26743 24519 
f 26692 27423 27437 
s 1026
f 27749 25338 25369 
s 264
f 27423 26692 26623 
s 1040
f 25338 27749 27635 
s 264
f 26574 27240 27380 
s 1040
f 27730 25337 25582 
s 257
f 27240 26574 26546 
s 1026
f 25337 27730 24848 
s 264
f 24416 23573 26865 
s 257
f 26763 26865 23573 
s 1040
f 28982 28735 28509 
s 1026
f 24086 28509 28735 
s 1040
f 28504 28737 28985 
s 1026
f 28737 28504 22041 
s 264
f 26868 23571 23579 
s 257
f 23571 26868 26592 
f 26645 26874 23570 
s 1040
f 28989 28742 28507 
s 1026
f 21954 28507 28742 
s 264
f 24417 23570 26874 
s 1026
f 28503 28738 28983 
s 1040
f 28738 28503 23321 
s 257
f 26869 23568 24403 
s 264
f 23568 26869 25921 
f 26533 26864 23577 
s 1026
f 28981 28736 28502 
s 1040
f 24085 28502 28736 
s 257
f 24414 23577 26864 
s 264
f 24413 23576 26870 
s 257
f 26819 26870 23576 
s 1040
f 28984 28739 28505 
s 1026
f 28206 28505 28739 
f 28513 28747 28992 
s 1040
f 28747 28513 21763 
s 257
f 26878 23575 24405 
s 264
f 23575 26878 26725 
f 26877 23574 24406 
s 257
f 23574 26877 25939 
s 1040
f 28512 28745 28986 
s 1026
f 28745 28512 26356 
s 257
f 27238 27214 26611 
s 1026
f 25538 25410 27650 
s 264
f 26590 26611 27214 
s 1040
f 26137 27650 25410 
s 264
f 23204 26681 27452 
s 1040
f 24910 27713 25382 
s 257
f 27305 27452 26681 
s 1026
f 28102 25382 27713 
s 257
f 26053 27449 26534 
s 1026
f 24568 25566 27858 
s 264
f 26821 26534 27449 
s 1040
f 27827 27858 25566 
s 257
f 26799 26821 27410 
s 1026
f 27726 27827 25554 
s 264
f 27449 27410 26821 
s 1040
f 25566 25554 27827 
s 257
f 27284 27260 26668 
s 1026
f 25562 25563 27699 
s 264
f 33609 26668 27260 
s 1040
f 32915 27699 25563 
s 264
f 27323 27284 26691 
s 1040
f 25560 25562 27719 
s 257
f 26668 26691 27284 
s 1026
f 27699 27719 25562 
s 264
f 26691 26732 27323 
s 1040
f 27719 27743 25560 
s 257
f 26038 27323 26732 
s 1026
f 24502 25560 27743 
s 257
f 26849 27463 27132 
s 1026
f 27886 28469 28515 
s 257
f 27463 26849 26678 
s 1026
f 28469 27886 27680 
s 264
f 26688 27369 27144 
s 1040
f 27689 25340 25367 
s 257
f 27369 26688 26830 
s 1026
f 25340 27689 27723 
s 257
f 26795 26553 27153 
s 1026
f 26081 27871 25339 
s 264
f 27212 27153 26553 
s 1040
f 25371 25339 27871 
f 27554 28515 24567 
s 257
f 27132 26535 26849 
s 1026
f 28515 27554 27886 
s 264
f 26535 27132 27381 
f 26648 27242 27419 
s 1040
f 27810 25349 23647 
s 257
f 27242 26648 26845 
s 1026
f 25349 27810 27870 
s 264
f 26525 27131 27507 
s 1040
f 27741 25559 23119 
s 257
f 27131 26525 26653 
s 1026
f 25559 27741 25636 
s 257
f 26815 26718 27314 
s 1026
f 27760 27742 25561 
s 264
f 26057 27314 26718 
s 1040
f 28124 25561 27742 
s 1026
f 25561 25553 27760 
s 257
f 26813 26815 27336 
s 1026
f 27808 27760 25553 
s 257
f 27314 27336 26815 
s 1040
f 27874 27606 25565 
s 257
f 24005 27174 26791 
s 1026
f 28148 25565 27606 
s 264
f 24271 26791 27174 
f 26071 27129 26523 
s 1040
f 24091 25432 27873 
s 257
f 26571 26523 27129 
s 1026
f 25816 27873 25432 
s 257
f 27227 27292 26701 
s 1026
f 28144 25493 27717 
s 264
f 26655 26701 27292 
s 1040
f 27691 27717 25493 
s 257
f 26659 27256 27436 
s 1026
f 27840 25436 28147 
s 264
f 27256 26659 26708 
s 1040
f 25436 27840 27796 
s 257
f 27133 27464 26850 
s 1026
f 28514 28470 27887 
s 257
f 26637 26850 27464 
s 1026
f 23729 27887 28470 
s 264
f 27159 27143 26773 
s 1040
f 25489 25417 27877 
s 257
f 23538 26773 27143 
s 1026
f 26126 27877 25417 
s 264
f 27248 27229 26626 
s 1040
f 25498 25418 27662 
s 257
f 26607 26626 27229 
s 1026
f 27638 27662 25418 
s 264
f 26594 27199 24083 
s 1040
f 27628 25420 21871 
s 257
f 27199 26594 26769 
s 1026
f 25420 27628 27784 
s 257
f 26616 27162 27415 
s 1026
f 27843 25448 28135 
s 264
f 27162 26616 24268 
s 1040
f 25448 27843 27846 
s 257
f 26568 27192 27278 
s 1026
f 27611 25421 25502 
s 257
f 27192 26568 26780 
s 1026
f 25421 27611 24883 
s 257
f 26674 27301 27267 
s 1026
f 27844 25481 25466 
s 257
f 27301 26674 23720 
s 1026
f 25481 27844 26093 
s 257
f 26812 26602 27230 
s 1026
f 27825 27815 25500 
s 264
f 27358 27230 26602 
s 1040
f 24321 25500 27815 
s 264
f 27292 27253 26655 
s 1040
f 25493 25508 27691 
s 257
f 26838 26655 27253 
s 1026
f 27646 27691 25508 
s 264
f 26708 27148 27256 
s 1040
f 27796 25506 25436 
s 257
f 27148 26708 26794 
s 1026
f 25506 27796 27750 
s 1040
f 25555 27813 27757 
s 257
f 26847 27387 26039 
s 1026
f 27813 25555 28127 
s 264
f 27387 26847 26740 
f 26538 27393 27231 
s 1040
f 27783 25413 25353 
s 257
f 27393 26538 23714 
s 1026
f 25413 27783 26108 
s 1040
f 27626 27645 25423 
s 257
f 26018 27218 26619 
s 1026
f 24012 25423 27645 
s 264
f 26843 26619 27218 
f 26656 27368 27348 
s 1040
f 27800 25444 25442 
s 257
f 27368 26656 26751 
s 1026
f 25444 27800 27781 
s 264
f 26627 27340 27266 
s 1040
f 27654 25472 25462 
s 257
f 27340 26627 26585 
s 1026
f 25472 27654 27602 
s 264
f 26624 26664 27258 
s 1040
f 27671 27686 25431 
s 257
f 26019 27258 26664 
s 1026
f 24496 25431 27686 
s 257
f 26586 27401 26054 
s 1026
f 27807 25477 24092 
s 264
f 27401 26586 26636 
s 1040
f 25477 27807 27618 
s 257
f 26823 27421 27444 
s 1026
f 27831 25497 24492 
s 264
f 27421 26823 26802 
s 1040
f 25497 27831 25818 
s 257
f 26798 27276 24470 
s 1026
f 27819 25438 26178 
s 264
f 27276 26798 20908 
s 1040
f 25438 27819 20914 
s 1026
f 24485 25445 27788 
s 264
f 26737 26776 27362 
s 1040
f 27762 27788 25445 
s 257
f 23308 27362 26776 
f 26024 27412 26730 
s 1026
f 24497 25441 27817 
s 264
f 26768 26730 27412 
s 1040
f 27791 27817 25441 
s 264
f 27275 27439 26547 
s 1040
f 25467 25435 27849 
s 257
f 25937 26547 27439 
s 1026
f 26133 27849 25435 
s 257
f 27373 27290 26756 
s 1026
f 24494 25464 27767 
s 264
f 26820 26756 27290 
s 1040
f 27753 27767 25464 
s 257
f 26762 27353 26022 
s 1026
f 27775 25492 24488 
s 264
f 27353 26762 26713 
s 1040
f 25492 27775 27731 
s 264
f 26613 27228 27390 
s 1040
f 27763 25484 25451 
s 257
f 27228 26613 26804 
s 1026
f 25484 27763 26112 
f 25491 27773 25801 
s 257
f 26551 27157 33198 
s 1026
f 27773 25491 33184 
s 257
f 27157 26551 26831 
s 264
f 27362 27326 26737 
s 1040
f 25445 25452 27762 
s 257
f 26712 26737 27326 
s 1026
f 27736 27762 25452 
f 26179 25454 27735 
s 264
f 20801 26710 27313 
s 1040
f 20807 27735 25454 
s 257
f 24468 27313 26710 
f 26779 27390 26049 
s 1026
f 27740 25451 25463 
s 264
f 27390 26779 26613 
s 1040
f 25451 27740 27763 
s 1026
f 26080 27753 25449 
s 264
f 27290 27147 26820 
s 1040
f 25464 25449 27753 
s 257
f 26634 26820 27147 
s 264
f 26713 27317 27353 
s 1040
f 27731 25456 25492 
s 257
f 27317 26713 26669 
s 1026
f 25456 27731 27694 
s 1040
f 25495 27722 27703 
s 257
f 26694 27289 27371 
s 1026
f 27722 25495 24490 
s 264
f 27289 26694 26671 
s 1040
f 25458 27718 27697 
s 257
f 26686 27285 27283 
s 1026
f 27718 25458 24498 
s 264
f 27285 26686 26663 
s 1026
f 26128 27709 25457 
s 264
f 27374 27337 26764 
s 1040
f 25446 25457 27709 
s 257
f 23655 26764 27337 
s 264
f 26742 27338 27233 
s 1040
f 27729 25499 25439 
s 257
f 27338 26742 26642 
s 1026
f 25499 27729 26078 
s 264
f 26671 27269 27289 
s 1040
f 27703 25459 25495 
s 257
f 27269 26671 26646 
s 1026
f 25459 27703 27683 
s 257
f 26641 27233 27316 
s 1026
f 27700 25439 24484 
s 264
f 27233 26641 26742 
s 1040
f 25439 27700 27729 
s 264
f 26663 27262 27285 
s 1040
f 27697 25461 25458 
s 257
f 27262 26663 26639 
s 1026
f 25461 27697 27679 
s 257
f 26045 27281 26693 
s 1026
f 28134 25460 27692 
s 264
f 26612 26693 27281 
s 1040
f 25817 27692 25460 
s 257
f 26667 27266 26055 
s 1026
f 27693 25462 24491 
s 264
f 27266 26667 26627 
s 1040
f 25462 27693 27654 
s 257
f 26065 27374 26604 
s 1026
f 28125 25446 27685 
s 264
f 26764 26604 27374 
s 1040
f 27709 27685 25446 
s 257
f 26669 27268 27317 
s 1026
f 27694 25450 25456 
s 264
f 27268 26669 32658 
s 1040
f 25450 27694 33059 
s 257
f 26638 27249 23409 
s 1026
f 27652 25471 25429 
s 264
f 27249 26638 26579 
s 1040
f 25471 27652 27594 
s 257
f 26618 27221 24466 
s 1026
f 27647 25473 26181 
s 264
f 27221 26618 20843 
s 1040
f 25473 27647 20849 
s 264
f 26662 27267 26027 
s 1040
f 27665 25466 23109 
s 257
f 27267 26662 26674 
s 1026
f 25466 27665 27844 
s 1040
f 25431 25470 27671 
s 257
f 26570 26624 27223 
s 1026
f 27639 27671 25470 
s 264
f 27258 27223 26624 
s 257
f 23411 27275 26690 
s 1026
f 25425 25467 27641 
s 264
f 26547 26690 27275 
s 1040
f 27849 27641 25467 
s 1026
f 27643 25442 24493 
s 264
f 27348 26803 26656 
s 1040
f 25442 27643 27800 
s 257
f 26803 27348 26056 
f 27223 27207 26570 
s 1026
f 25470 25428 27639 
s 264
f 32851 26570 27207 
s 1040
f 32816 27639 25428 
s 257
f 24469 27184 26578 
s 1026
f 26177 25482 27617 
s 264
f 20736 26578 27184 
s 1040
f 20742 27617 25482 
f 27618 25427 25477 
s 257
f 27335 26636 23715 
s 1026
f 25427 27618 27603 
s 264
f 26636 27335 27401 
s 1040
f 20716 27634 25474 
s 257
f 24467 27234 26620 
s 1026
f 26180 25474 27634 
s 264
f 20710 26620 27234 
f 26561 27161 27189 
s 1040
f 27605 25487 24604 
s 257
f 27161 26561 26540 
s 1026
f 25487 27605 26110 
s 1040
f 25423 25478 27626 
s 257
f 23716 26843 27186 
s 1026
f 27797 27626 25478 
s 264
f 27218 27186 26843 
s 257
f 26585 27193 27340 
s 1026
f 27602 25480 25472 
s 264
f 27193 26585 32924 
s 1040
f 25480 27602 32878 
s 257
f 26660 27140 20773 
s 1026
f 27856 25433 20779 
s 264
f 27140 26660 24056 
s 1040
f 25433 27856 27957 
s 264
f 26579 27175 27249 
s 1040
f 27594 25490 25471 
s 257
f 27175 26579 26786 
s 1026
f 25490 27594 26117 
s 257
f 26769 27361 27199 
s 1026
f 27784 25504 25420 
s 257
f 27361 26769 26716 
s 1026
f 25504 27784 27811 
s 1040
f 27701 25502 28122 
s 257
f 27278 26679 26568 
s 1026
f 25502 27701 27611 
s 264
f 26679 27278 23059 
s 1040
f 28985 28091 28504 
s 257
f 25080 23579 27441 
s 264
f 23579 25080 26868 
s 1026
f 28091 28985 26976 
s 257
f 27236 27350 26759 
s 1026
f 28140 25415 27786 
s 264
f 26738 26759 27350 
s 1040
f 27764 27786 25415 
s 257
f 27434 27200 26748 
s 1026
f 28114 25414 27629 
s 264
f 26735 26748 27200 
s 1040
f 27604 27629 25414 
s 264
f 27200 27167 26735 
s 1040
f 25414 25486 27604 
s 257
f 26717 26735 27167 
s 1026
f 27853 27604 25486 
s 264
f 27350 27332 26738 
s 1040
f 25415 25494 27764 
s 257
f 26836 26738 27332 
s 1026
f 27798 27764 25494 
f 25500 25496 27825 
s 257
f 25959 26812 27261 
s 1026
f 23310 27825 25496 
s 257
f 27230 27261 26812 
f 23727 27248 26644 
s 1026
f 24495 25498 27682 
s 264
f 26626 26644 27248 
s 1040
f 27662 27682 25498 
s 1026
f 25453 25489 27600 
s 264
f 26773 26559 27159 
s 1040
f 27877 27600 25489 
s 257
f 27304 27159 26559 
s 264
f 26527 27136 27459 
s 1040
f 27614 25485 25476 
s 257
f 27136 26527 26541 
s 1026
f 25485 27614 26141 
f 27761 25434 28133 
s 264
f 27327 26733 24184 
s 1040
f 25434 27761 25807 
s 257
f 26733 27327 26050 
s 1040
f 28511 28095 28990 
s 257
f 24006 23578 25083 
s 264
f 26876 25083 23578 
s 1026
f 24512 28990 28095 
s 257
f 20880 27158 26539 
s 1026
f 20886 25363 27758 
s 264
f 24049 26539 27158 
s 1040
f 27950 27758 25363 
f 24479 25361 27778 
s 257
f 26647 26781 27417 
s 1026
f 27674 27778 25361 
s 264
f 27211 27417 26781 
s 257
f 27417 27250 26647 
s 1026
f 25361 25356 27674 
s 257
f 26643 26647 27250 
s 1026
f 27672 27674 25356 
f 27712 27642 25359 
s 264
f 27446 27206 26610 
s 1040
f 23551 25359 27642 
s 257
f 26695 26610 27206 
s 1026
f 27656 25353 24322 
s 264
f 27231 26772 26538 
s 1040
f 25353 27656 27783 
s 257
f 26772 27231 26060 
s 1040
f 27757 25558 25555 
s 257
f 27333 26740 23656 
s 1026
f 25558 27757 27714 
s 264
f 26740 27333 27387 
f 24268 27505 27162 
s 1040
f 27846 25503 25448 
s 257
f 27505 24268 25979 
s 1026
f 25503 27846 27848 
s 257
f 27442 27388 26827 
s 1026
f 21918 25348 27812 
s 264
f 26734 26827 27388 
s 1040
f 25634 27812 25348 
s 264
f 26807 27311 23112 
s 1040
f 27821 25556 24014 
s 257
f 27311 26807 26622 
s 1026
f 25556 27821 27768 
s 264
f 27378 27133 26621 
s 1040
f 24481 28514 27553 
s 257
f 26850 26621 27133 
s 1026
f 27887 27553 28514 
s 257
f 26458 27152 27154 
s 1026
f 27854 25370 23631 
s 264
f 27152 26458 26440 
s 1040
f 25370 27854 27696 
s 257
f 27239 26622 23702 
s 1026
f 25564 27768 27636 
s 257
f 26622 27239 27311 
s 1026
f 27768 25564 25556 
s 257
f 26774 27364 24461 
s 1026
f 27789 25557 26185 
s 264
f 27364 26774 20817 
s 1040
f 25557 27789 20823 
s 257
f 25980 24271 27506 
s 1026
f 26134 27874 25507 
s 264
f 27174 27506 24271 
s 1040
f 25565 25507 27874 
s 264
f 27166 27448 26658 
s 1040
f 28516 28154 27868 
s 257
f 25954 26658 27448 
s 1026
f 23053 27868 28154 
f 28131 27834 24691 
s 264
f 26587 27433 27149 
s 1040
f 27834 28131 28517 
s 257
f 27433 26587 25950 
s 1026
f 27835 25577 25223 
s 264
f 27277 26682 33797 
s 1040
f 25577 27835 32960 
s 257
f 26682 27277 27306 
s 264
f 27406 27456 26788 
s 257
f 26727 26788 27456 
s 1040
f 28673 29014 28385 
s 1026
f 26323 28385 29014 
s 264
f 26632 27451 27254 
s 257
f 27451 26632 26709 
s 1040
f 28244 29000 28664 
s 1026
f 29000 28244 26317 
f 26901 28995 28421 
s 1040
f 28340 28421 28995 
s 257
f 27398 27450 26552 
s 264
f 26754 26552 27450 
s 257
f 26567 27453 27178 
s 264
f 27453 26567 26605 
s 1026
f 28259 28994 28658 
s 1040
f 28994 28259 28162 
s 257
f 26558 27380 27383 
s 1026
f 27878 25582 28153 
s 264
f 27380 26558 26574 
s 1040
f 25582 27878 27730 
s 257
f 26846 27394 23413 
s 1026
f 27867 25344 25362 
s 264
f 27394 26846 26673 
s 1040
f 25344 27867 27842 
s 264
f 27156 27397 26810 
s 1040
f 25343 25347 27820 
s 257
f 26777 26810 27397 
s 1026
f 27804 27820 25347 
s 264
f 26673 27169 27394 
s 1040
f 27842 25351 25344 
s 257
f 27169 26673 25946 
s 1026
f 25351 27842 26114 
s 257
f 27404 27343 26760 
s 1026
f 23138 25352 27747 
s 264
f 26676 26760 27343 
s 1040
f 27695 27747 25352 
s 264
f 26753 27219 27357 
s 1040
f 27744 25346 25345 
s 257
f 27219 26753 26702 
s 1026
f 25346 27744 27624 
s 264
f 27343 27265 26676 
s 1040
f 25352 25354 27695 
s 257
f 26633 26676 27265 
s 1026
f 26118 27695 25354 
f 23105 25343 27616 
s 264
f 26810 26556 27156 
s 1040
f 27820 27616 25343 
s 257
f 26067 27156 26556 
f 26711 27357 27259 
s 1026
f 27794 25345 23120 
s 264
f 27357 26711 26753 
s 1040
f 25345 27794 27744 
s 1026
f 28188 28658 33300 
f 28658 28188 28259 
s 257
f 26577 27178 33723 
f 27178 26577 26567 
f 26651 27254 27407 
s 264
f 27254 26651 26632 
s 1026
f 28264 28664 21737 
s 1040
f 28664 28264 28244 
s 264
f 26788 26554 27406 
s 1026
f 22178 28673 28309 
s 1040
f 28385 28309 28673 
s 257
f 27168 27406 26554 
s 1026
f 28096 28991 21880 
s 1040
f 28991 28096 28510 
s 257
f 26466 23584 24008 
s 264
f 23584 26466 26875 
s 257
f 26680 27279 27303 
s 1026
f 27702 25529 23552 
s 264
f 27279 26680 26635 
s 1040
f 25529 27702 27663 
f 27630 27655 25535 
s 257
f 27411 27225 26628 
s 1026
f 28145 25535 27655 
s 264
f 26589 26628 27225 
s 257
f 26017 27238 26630 
s 1026
f 23045 25538 27667 
s 257
f 26611 26630 27238 
s 1026
f 27650 27667 25538 
s 264
f 26744 26787 27315 
s 1040
f 27745 27592 25539 
s 257
f 27320 27315 26787 
s 1026
f 25217 25539 27592 
s 257
f 27163 27319 26719 
s 1026
f 28150 25540 27748 
s 264
f 26700 26719 27319 
s 1040
f 27727 27748 25540 
s 257
f 27366 27195 26557 
s 1026
f 23646 25533 27715 
s 264
f 26800 26557 27195 
s 1040
f 27776 27715 25533 
s 257
f 33316 27232 26625 
s 1026
f 33219 25530 27653 
s 257
f 26583 26625 27232 
s 1026
f 27619 27653 25530 
s 257
f 26603 27209 23060 
s 1026
f 27595 25541 28138 
s 264
f 27209 26603 26743 
s 1040
f 25541 27595 27872 
s 257
f 26801 27395 27431 
s 1026
f 27805 25531 28117 
s 264
f 27395 26801 26752 
s 1040
f 25531 27805 27782 
s 257
f 27274 27310 26699 
s 1026
f 28143 25534 27793 
s 264
f 26536 26699 27310 
s 1040
f 27774 27793 25534 
s 257
f 26025 27150 26741 
s 1026
f 23054 25542 27593 
s 257
f 26818 26741 27150 
s 1026
f 27814 27593 25542 
f 27705 25543 28149 
s 257
f 27287 26683 26684 
s 1026
f 25543 27705 27706 
s 257
f 26683 27287 27440 
s 1026
f 27739 25369 23068 
s 257
f 27437 26789 26692 
s 1026
f 25369 27739 27749 
s 257
f 26789 27437 26059 
s 1026
f 27721 25367 28130 
s 264
f 27144 26615 26688 
s 1040
f 25367 27721 27689 
s 257
f 26615 27144 26046 
f 27222 27212 26722 
s 1026
f 28129 25371 27625 
s 264
f 26553 26722 27212 
s 1040
f 27871 27625 25371 
s off
f 31355 31339 30435 
f 30416 30343 28537 
f 30434 31343 31356 
f 28533 30348 30426 
f 31349 31331 30448 
f 30422 30333 28540 
f 28538 30334 30423 
f 30453 31326 31348 
f 33639 33558 25013 
s 257
f 27213 27226 26703 
s 1040
f 29561 29257 27893 
s 264
f 26654 26703 27226 
s 1026
f 24455 27893 29257 
f 29256 27891 22121 
s 1040
f 27891 29256 29560 
s 257
f 26614 27217 26063 
s 264
f 27217 26614 26598 
s 1026
f 25646 27936 29255 
s 257
f 24009 27166 26569 
s 1040
f 29563 29255 27936 
s 264
f 26658 26569 27166 
s 1026
f 29258 27938 21735 
s 1040
f 27938 29258 29562 
s 257
f 26599 27149 27181 
s 264
f 27149 26599 26587 
s 257
f 23570 27251 26645 
s 1026
f 24607 28471 25883 
s 264
f 32499 26645 27251 
s 1040
f 32251 25883 28471 
f 29560 27549 27891 
s 1026
f 27549 29560 29277 
s 264
f 26598 27202 27217 
s 257
f 27202 26598 23722 
s 1026
f 29546 27541 28036 
s 1040
f 27541 29546 29271 
s 257
f 26723 27322 26031 
s 264
f 27322 26723 26706 
s 1040
f 27893 27550 29561 
s 264
f 27226 27247 26654 
s 1026
f 24075 29561 27550 
s 257
f 23183 26654 27247 
s 1026
f 27905 27544 29541 
s 257
f 24706 27376 26565 
s 1040
f 29272 29541 27544 
s 264
f 26790 26565 27376 
s 8194
f 20729 25603 28521 
s 2056
f 26394 27509 23605 
s 8208
f 24363 28521 25603 
s 2049
f 20723 23605 27509 
s 2056
f 23604 27508 26395 
s 8208
f 25602 28520 24364 
s 2049
f 27508 23604 20830 
s 8194
f 28520 25602 20836 
s 1026
f 29553 27545 22252 
f 27545 29553 29244 
s 257
f 26629 27237 23374 
f 27237 26629 26687 
s 1026
f 29551 27547 22244 
s 1040
f 27547 29551 29243 
s 257
f 26652 27447 23371 
s 264
f 27447 26652 26715 
f 26595 26597 27375 
s 1026
f 22289 27536 29544 
s 257
f 23367 27375 26597 
s 1040
f 29247 29544 27536 
s 1026
f 22273 27538 29542 
s 257
f 23370 27255 26657 
s 1040
f 29212 29542 27538 
s 264
f 26617 26657 27255 
s 1026
f 29547 27542 22280 
s 1040
f 27542 29547 29221 
s 257
f 26747 27342 23366 
s 264
f 27342 26747 26704 
s 1026
f 22285 27546 29550 
s 257
f 23365 27183 26749 
s 1026
f 29225 29550 27546 
s 257
f 26758 26749 27183 
s 1026
f 29548 27543 22268 
f 27543 29548 29228 
s 257
f 26755 27351 23369 
f 27351 26755 26757 
s 1026
f 29540 27537 22276 
s 1040
f 27537 29540 29248 
s 257
f 26593 27196 23368 
s 264
f 27196 26593 26848 
f 26770 26793 27389 
s 1026
f 22249 27539 29549 
s 257
f 23372 27389 26793 
s 1040
f 29233 29549 27539 
s 1026
f 22293 27548 29552 
s 257
f 23363 27145 26814 
s 1040
f 29242 29552 27548 
s 264
f 26767 26814 27145 
s 1026
f 29539 27535 22296 
s 1040
f 27535 29539 29251 
s 257
f 26564 27164 23362 
s 264
f 27164 26564 26677 
s 1026
f 22305 27540 29545 
s 257
f 23364 27305 26705 
s 1026
f 29220 29545 27540 
s 257
f 26681 26705 27305 
s off
f 28526 30344 30429 
f 30444 31203 31777 
f 31778 31202 30445 
f 30428 30340 28527 
f 29838 29866 27993 
f 29855 29826 27989 
f 27990 29827 29856 
f 27992 29867 29839 
f 31388 31405 26386 
f 30808 30496 29115 
f 26387 31404 31389 
f 29114 30497 30807 
f 26257 31427 30878 
f 30879 31426 26258 
f 32009 24941 23516 
f 23517 24942 32010 
f 23513 24940 32011 
f 32012 24939 23510 
f 21332 31263 31166 
f 31166 31263 30933 
f 30932 31262 31167 
f 31167 31262 21331 
f 30577 30549 30624 
f 26239 30549 30650 
f 30605 30551 30593 
f 30684 30593 26229 
s 1
f 20949 29841 28006 
s off
f 28006 29841 29679 
s 1
f 20942 28007 29840 
s off
f 29678 29840 28007 
f 29843 28005 21088 
f 28005 29843 29682 
f 29683 29842 28004 
f 21087 28004 29842 
f 30372 31250 31290 
f 30329 31225 31237 
f 31236 31224 30328 
f 31291 31251 30373 
f 32015 24935 23514 
f 23515 24936 32016 
f 30094 30977 26421 
f 31085 31848 31873 
f 26422 30976 30095 
f 31874 31847 31084 
f 30091 30993 26423 
f 31091 31846 31870 
f 26424 30992 30090 
f 31869 31845 31090 
f 30718 29950 22985 
f 30711 30077 22985 
f 29986 30789 21439 
f 30992 26424 26271 
f 21438 30788 29987 
f 26270 26423 30993 
f 29997 30791 21403 
f 30976 26422 26272 
f 21402 30790 29998 
f 26273 26421 30977 
f 26398 29825 29851 
f 21079 31674 24696 
f 24697 31673 21078 
f 29850 29824 26397 
f 30106 30464 26260 
f 31877 31851 31099 
f 26259 30465 30107 
f 31098 31852 31878 
f 31803 31832 31067 
f 31866 31838 31094 
f 21367 22995 21663 
f 26224 30595 26248 
f 25013 26288 33639 
f 26357 26402 22996 
f 30456 31363 31340 
f 28546 30362 30410 
f 30411 30363 28547 
f 31333 31362 30457 
f 21571 28597 22999 
f 23705 28602 26381 
s 1026
f 25269 27562 22094 
s 1040
f 27562 25269 33208 
s off
f 31045 31078 25256 
f 31104 31101 30062 
f 25257 31081 31046 
f 30061 31103 31105 
f 30580 30673 26252 
f 23480 30653 23003 
f 29932 30680 26246 
f 30563 30678 26242 
f 23703 28594 23005 
f 26075 28601 21396 
f 30678 30563 26227 
f 30601 30691 26243 
f 30891 23007 23525 
f 32014 24937 23007 
f 23008 24938 32013 
f 23526 23008 30890 
f 23009 26377 21169 
f 33830 26377 23009 
s 1026
f 28915 26489 33606 
s 1040
f 26489 28915 28819 
s off
f 32002 24947 23940 
f 23941 24948 32001 
f 31859 31827 31074 
f 31784 31809 31058 
f 31075 31828 31860 
f 31059 31812 31786 
f 31785 31811 31069 
f 31862 31836 31077 
f 31076 31835 31861 
f 31068 31810 31783 
f 31876 31849 31082 
f 31796 31817 31060 
f 31083 31850 31875 
f 31061 31818 31795 
f 31787 31815 31071 
f 31867 31843 31088 
f 31089 31844 31868 
f 31070 31816 31788 
f 31879 31854 31080 
f 31798 31821 31063 
f 31062 31822 31797 
f 31079 31853 31880 
f 31807 31829 31055 
f 31871 31841 31086 
f 31087 31842 31872 
f 31054 31830 31808 
f 31802 31831 31064 
f 31863 31837 31096 
f 31065 31833 31804 
f 31095 31839 31865 
f 31097 31840 31864 
f 31066 31834 31801 
f 31799 31823 31056 
f 31881 31855 31092 
f 31093 31856 31882 
f 31057 31824 31800 
f 31337 31360 30459 
f 30408 30361 28548 
f 28549 30360 30409 
f 30458 31361 31335 
s 8208
f 25518 30307 28550 
s 2147483647
f 23032 27946 22004 
s 8208
f 28551 30308 25366 
s 2147483647
f 22002 27945 23033 
s off
f 26980 31591 30913 
f 30912 31590 26979 
f 31599 31586 30920 
f 30920 31586 22516 
f 22515 31587 30921 
f 30921 31587 31598 
f 29817 29819 27978 
f 29805 29812 27982 
f 23942 24946 32005 
f 32006 24943 23939 
s 1026
f 28455 24683 22770 
s 1040
f 24683 28455 32302 
s off
f 31297 31257 30375 
f 31244 31230 30326 
f 30327 31231 31243 
f 30374 31256 31296 
s 1026
f 33581 26475 29034 
f 28714 29034 26475 
s 1040
f 32744 25221 29529 
s 1026
f 29600 29529 25221 
s off
f 29974 30714 29936 
f 29975 30682 24832 
f 30927 25985 31574 
f 24532 21057 25985 
s 1040
f 32964 32965 22896 
s 1026
f 24432 21982 21984 
s off
f 23049 29089 29640 
f 26384 30044 29885 
f 29641 29090 23050 
f 29884 30043 26385 
s 1026
f 27016 24451 21747 
f 33042 33030 29403 
s 1040
f 20863 33396 33394 
f 29878 27011 24450 
s 1026
f 21641 26145 29276 
s 1040
f 32045 29276 26145 
f 32486 29002 26352 
s 1026
f 28167 26352 29002 
f 33767 32706 26015 
s 1040
f 32107 32051 25901 
s 1026
f 26943 25919 33732 
s 1040
f 33117 33417 24821 
s 1026
f 28679 26964 21911 
f 28451 33150 33100 
f 27012 24452 21756 
f 32949 32939 29346 
f 33455 33618 27097 
s 1040
f 25051 27097 23830 
s 1026
f 29585 27072 23921 
f 27072 29585 32505 
s 1040
f 21909 33363 33740 
f 24851 26268 33403 
s 1026
f 26339 24689 22875 
f 33075 33077 28253 
s off
f 26233 30559 30666 
f 29967 30676 22190 
s 1026
f 23885 23064 24653 
s 1040
f 25739 32758 32752 
s 1026
f 24399 23065 24649 
s 1040
f 25771 32902 33602 
s off
f 23066 28579 24107 
f 23694 28560 27514 
f 26382 28592 28612 
f 24018 28612 28592 
s 1026
f 26957 26333 28338 
f 32662 32663 28662 
f 28655 26961 26327 
f 28381 33237 33256 
f 24804 23070 23776 
f 33458 33759 29520 
s 1040
f 33385 27118 25741 
s 1026
f 20865 25741 27118 
s off
f 23759 28603 28580 
f 26011 28580 28603 
s 1026
f 27063 23073 24660 
s 1040
f 29570 32974 32340 
s 1026
f 29329 23074 23878 
f 23074 29329 32709 
f 33572 29317 23075 
s 1040
f 23896 23075 29317 
s 1026
f 23076 29517 32139 
f 29517 23076 25298 
f 33706 28069 24821 
s 1040
f 24821 28069 33117 
s 1026
f 23135 33093 33312 
f 25678 25046 23078 
s off
f 29994 31664 31467 
f 23170 21460 23079 
f 31472 31657 29991 
f 23080 21318 21322 
f 23081 21459 23169 
f 31468 31663 29993 
f 28071 31667 31473 
f 23172 21119 23082 
f 23083 21084 23171 
f 31474 31668 28070 
f 33549 31639 31509 
f 23345 21452 23084 
f 33563 31646 31510 
f 25173 21188 23085 
f 25171 21184 23086 
f 32676 31636 31507 
f 31534 31628 33551 
f 23087 21398 23336 
f 31530 31625 33559 
f 23088 21183 25170 
f 31536 31631 33414 
f 23089 21187 25172 
f 32037 31486 31579 
f 31717 31041 23090 
f 23091 31040 31718 
f 31578 31485 32038 
s 1040
f 26143 29275 32137 
s 1026
f 29275 26143 25715 
s off
f 21185 23093 25168 
f 31504 31547 32543 
f 32679 31527 31525 
f 25169 23094 21186 
f 32599 31531 31521 
f 25170 23095 23088 
f 31501 31549 32701 
f 23086 23096 25171 
f 31612 31897 20998 
f 23097 29745 29142 
f 31613 20997 31898 
f 29141 29744 23098 
s 1040
f 27575 29447 33442 
s 1026
f 29447 27575 21856 
s off
f 27983 29835 29864 
f 27996 29851 29825 
f 29863 29834 27984 
f 29824 29850 27997 
f 27988 29828 29854 
f 27994 29864 29835 
f 29834 29863 27995 
f 29853 29829 27987 
s 1040
f 29078 33050 32174 
s 1026
f 22586 22144 24513 
f 33018 28774 26335 
f 28461 26335 28774 
f 23106 25630 33592 
f 25630 23106 23815 
f 29177 27564 21851 
s 1040
f 27564 29177 32391 
s 1026
f 33261 26495 25365 
f 29036 25365 26495 
f 26297 28773 33554 
s 1040
f 28773 26297 28251 
s off
f 31248 29112 26378 
f 26381 28562 23705 
f 23111 21066 21059 
f 22576 21066 23111 
s 1040
f 24645 27099 23764 
s 1026
f 33320 32724 27099 
f 21878 27566 29449 
s 1040
f 33746 29449 27566 
s off
f 21164 30085 30690 
f 23475 30690 30085 
s 1026
f 27785 32882 32488 
f 27532 27785 23115 
s 516
f 33053 28065 24628 
s 544
f 24628 28065 32054 
s 1040
f 32057 33066 21616 
s 1026
f 25897 26912 33735 
s 544
f 32329 32324 28035 
s 516
f 25264 28035 25684 
f 32315 32292 28050 
s 544
f 25259 28050 21828 
s 516
f 25263 28049 21651 
f 33795 33784 28049 
s off
f 24829 30640 23546 
f 30724 30640 29927 
f 30604 30642 23122 
f 30683 30588 29941 
f 29942 30592 30710 
f 23123 30643 21367 
f 29961 30677 30415 
f 30568 30677 29961 
s 1040
f 24805 27100 33699 
s 1026
f 27100 24805 29188 
s off
f 28567 30395 26156 
f 22708 30395 28567 
f 26157 30393 28569 
f 28569 30393 22711 
f 26155 30400 28565 
f 28565 30400 22712 
f 22714 30388 28568 
f 28568 30388 26163 
f 28576 30390 26166 
f 22707 30390 28576 
f 26160 30391 28571 
f 28571 30391 22710 
f 26165 30384 28574 
f 28574 30384 22709 
f 22713 30385 28573 
f 28573 30385 26162 
s 1026
f 29014 25136 26323 
s 1040
f 25136 32958 32563 
s 1026
f 32727 27573 24807 
f 29609 24807 27573 
s 1040
f 33739 27093 25025 
s 1026
f 26363 25025 27093 
f 26218 32333 33726 
f 23236 24685 26293 
s 516
f 21658 28048 26498 
f 33783 26498 28048 
s 1026
f 32358 26506 24592 
f 29039 24592 26506 
s off
f 29944 31662 31481 
f 23140 31718 31040 
f 31482 31660 29945 
f 31041 31717 23141 
f 21456 23142 21481 
f 31596 31476 32030 
f 31593 31479 32034 
f 21457 23143 21494 
f 21493 23144 21458 
f 32033 31480 31592 
f 21322 23145 23080 
f 32029 31475 31597 
f 31470 31661 29996 
f 21458 23146 21493 
f 21494 23147 21457 
f 29995 31659 31469 
f 29992 31658 31471 
f 21481 23148 21456 
s 1026
f 23813 23149 24646 
s 1040
f 25624 32889 32881 
f 29194 33033 33019 
s 1026
f 23877 23150 24658 
f 33164 33159 23871 
f 23151 24657 29313 
s 1040
f 33362 33364 25773 
f 24655 23152 23255 
s 1026
f 23884 23153 24654 
s 1040
f 25782 32801 32798 
f 29305 32618 32789 
s 1026
f 23855 23154 24652 
s 1040
f 33011 33017 25765 
s 1026
f 24650 23155 23872 
s 1040
f 32865 32866 25792 
s 1026
f 24656 23156 23856 
f 24663 23157 23914 
s 1040
f 32973 32986 25877 
f 23911 23158 24662 
f 25869 33465 33463 
s off
f 31526 31623 33647 
f 21186 23159 25169 
f 31491 31629 32346 
f 23509 21115 23160 
f 23161 21107 23508 
f 31490 31567 31634 
f 32688 31640 31512 
f 25168 23162 21185 
f 21035 23163 21289 
f 31649 31772 31514 
f 21014 23164 21284 
f 31647 31765 31539 
f 21285 23165 20960 
f 31542 31766 31652 
f 21290 23166 21036 
f 31500 31768 31651 
f 31129 31708 31620 
f 23167 21089 23328 
f 23327 21118 23168 
f 31621 31707 31126 
f 23169 21435 23081 
f 32031 31478 31594 
f 31595 31477 32032 
f 23079 21436 23170 
f 23171 21350 23083 
f 32036 31483 31127 
f 23082 21349 23172 
f 31128 31484 32035 
f 23173 29746 29088 
f 31730 31896 21416 
f 29087 29747 23174 
f 31729 21415 31895 
f 21505 26401 23361 
s 8
f 26401 21505 21101 
s off
f 29861 29836 27986 
f 29829 29853 27998 
f 27999 29854 29828 
f 27985 29837 29862 
f 21284 23178 21014 
f 31537 31632 33398 
f 33653 31644 31511 
f 20960 23179 21285 
s 1026
f 25658 25038 27119 
s 1040
f 32432 27119 25038 
s off
f 29988 26390 30925 
f 21348 29630 23181 
f 23182 29629 21347 
f 30924 26391 29985 
s 1026
f 23437 26467 24760 
s 1040
f 33165 24760 26467 
s off
f 23184 29743 28626 
f 32019 21110 25992 
f 23185 29753 28630 
f 32024 23338 25994 
f 28629 29754 23186 
f 32023 25993 23343 
f 32022 25997 21111 
f 28627 29752 23187 
f 23188 30370 21359 
f 28624 29749 23188 
f 21358 30371 23189 
f 23189 29751 28628 
f 28623 29748 23190 
f 32017 25991 23344 
f 32021 23337 25990 
f 23191 29750 28625 
s 1040
f 25129 28237 32814 
s 1026
f 28237 25129 22838 
f 24770 26855 24116 
s 1040
f 32047 32048 26855 
f 26854 32193 32125 
s 1026
f 24143 26854 24772 
f 24151 26857 24774 
s 1040
f 26857 32215 32179 
s 1026
f 24773 26856 24152 
s 1040
f 32192 32212 26856 
s 1026
f 24771 26497 33313 
f 26497 24771 29032 
s 1040
f 24135 26852 24781 
s 1026
f 26852 32134 32119 
s 1040
f 26862 32186 32150 
s 1026
f 24130 26862 24780 
f 24779 26853 24133 
s 1040
f 32159 32185 26853 
f 24776 26859 24136 
s 1026
f 32124 32238 26859 
f 24777 26851 24128 
s 1040
f 32257 32144 26851 
s 1026
f 24138 26858 24778 
s 1040
f 26858 32161 32244 
f 24141 26860 24775 
s 1026
f 26860 32136 32220 
f 24782 26490 32630 
f 26490 24782 28887 
f 32380 26514 24790 
f 25132 24790 26514 
f 32394 26472 24789 
f 28871 24789 26472 
f 26507 24788 28952 
f 24788 26507 32401 
f 32473 26516 24793 
f 28896 24793 26516 
f 28820 24792 26511 
f 32592 26511 24792 
f 29051 24784 26482 
f 32625 26482 24784 
s 1040
f 24785 26476 32451 
s 1026
f 26476 24785 29047 
f 24787 26505 32375 
f 26505 24787 29068 
f 29043 24786 26485 
f 32374 26485 24786 
f 24794 26470 33257 
f 26470 24794 29064 
s 1040
f 24795 26861 24183 
s 1026
f 32123 32241 26861 
f 28727 24798 26479 
s 1040
f 32501 26479 24798 
s 1026
f 26512 24797 28691 
f 24797 26512 32573 
s 1040
f 24799 26510 32552 
s 1026
f 26510 24799 28696 
f 32414 26508 24796 
f 28682 24796 26508 
f 23820 23221 29322 
f 33062 29322 23221 
f 33245 29320 23222 
s 1040
f 23260 23222 29320 
s 1026
f 23223 29324 33610 
f 29324 23223 23847 
f 23224 29319 33002 
s 1040
f 29319 23224 23261 
s 1026
f 27940 21674 27035 
f 29273 33008 33029 
f 22040 27047 25815 
s 1040
f 32081 32063 27933 
f 32250 32087 27935 
s 1026
f 22211 27048 22212 
s 1040
f 22217 25218 29246 
s 1026
f 33330 29246 25218 
s 1040
f 25224 29446 33460 
s 1026
f 29446 25224 21816 
f 29541 29273 27905 
f 33029 27905 29273 
s 1040
f 32555 32857 25137 
s 1026
f 26317 25137 29000 
f 25714 26144 29274 
s 1040
f 32267 29274 26144 
s 1026
f 29487 21864 22604 
s 1040
f 21864 29487 32291 
s off
f 31908 25912 23234 
f 25005 21247 21153 
f 23235 25911 31907 
f 21152 21248 25006 
s 257
f 26797 29662 24465 
s 1026
f 24865 29787 26184 
s 257
f 26775 29654 24462 
s 1026
f 27657 29779 26182 
s 257
f 24463 29651 23612 
s 1026
f 26183 29775 27770 
s 257
f 24464 29655 25945 
s 1026
f 26186 29788 27847 
s 257
f 26620 29656 24467 
s 1026
f 27634 29780 26180 
s 257
f 26710 29658 24468 
s 1026
f 27735 29782 26179 
s 257
f 26578 29652 24469 
s 1026
f 27617 29777 26177 
s 257
f 24466 29653 26618 
s 1026
f 26181 29778 27647 
s 257
f 24470 29660 26798 
s 1026
f 26178 29783 27819 
s 257
f 24461 29659 26774 
s 1026
f 26185 29784 27789 
s 257
f 26778 29657 24473 
s 1026
f 23113 29781 26174 
s 257
f 24471 29663 26726 
s 1026
f 26175 29785 23099 
s 257
f 25962 29650 24472 
s 1026
f 26109 29776 26173 
s 257
f 24474 29661 26537 
s 1026
f 26176 29786 27707 
s 1040
f 25891 33072 33041 
s 1026
f 29238 27045 21795 
s off
f 29956 30723 30639 
f 30723 29956 29960 
f 30653 29957 30724 
f 30640 30724 29957 
s 64
f 21404 23253 30512 
s 1026
f 22469 24766 28336 
s 1040
f 32513 28336 24766 
s 544
f 26999 29873 27010 
s 264
f 24055 29729 26058 
s 544
f 26998 29876 29474 
s 264
f 24056 29731 27140 
f 27379 29736 24052 
s 544
f 27011 29878 26993 
s 4100
f 26995 29874 20795 
s 2049
f 24053 29732 20786 
f 20893 29734 24051 
s 4100
f 20903 29877 26996 
s 264
f 26051 29733 24054 
s 544
f 24881 29875 26994 
f 26997 29879 24877 
s 264
f 24050 29730 26036 
s 544
f 29492 29872 27000 
s 264
f 27158 29735 24049 
s 64
f 21163 31465 21442 
s 1040
f 26478 28698 32275 
s 1026
f 28698 26478 22728 
f 33350 28689 26494 
s 1040
f 22729 26494 28689 
s 64
f 32044 30049 30298 
s 516
f 32307 32280 28051 
f 25001 28051 25856 
s off
f 31432 31981 31960 
f 22392 31981 31432 
f 31959 31982 31433 
f 31433 31982 22393 
f 31439 31975 31956 
f 31975 31439 30883 
f 31955 31976 31438 
f 30882 31438 31976 
f 31977 31436 30880 
f 31436 31977 31962 
f 31961 31978 31437 
f 30881 31437 31978 
f 31979 31431 30885 
f 31431 31979 31958 
f 30884 31428 31980 
f 31957 31980 31428 
f 30886 30915 31125 
f 22667 31125 30915 
f 30914 31124 22666 
f 31124 30914 30887 
f 22668 31123 30916 
f 30892 30916 31123 
f 30917 31122 22669 
f 31122 30917 30893 
f 30918 31121 22670 
f 31121 30918 30889 
f 30888 30919 31120 
f 22671 31120 30919 
s 1026
f 27661 26084 28118 
s 1040
f 33712 28118 26084 
s off
f 31381 31418 30907 
f 30906 31419 31380 
f 31384 31423 30899 
f 30898 31422 31385 
f 31382 31421 30903 
f 30902 31420 31383 
f 30911 31415 31375 
f 31374 31414 30910 
f 30901 31425 31386 
f 31387 31424 30900 
f 30905 31417 31378 
f 31379 31416 30904 
f 31376 31412 30908 
f 30909 31413 31377 
s 1026
f 33620 25737 23297 
s 1040
f 23831 23297 25737 
s off
f 29698 27943 21362 
f 31045 29990 27943 
f 27944 29989 31046 
f 21363 27944 29699 
s 1040
f 32407 32383 24057 
s 1026
f 21740 24791 25135 
f 33750 28202 29009 
s 1040
f 28672 29009 28202 
s off
f 29798 29800 23302 
f 29806 29811 29800 
f 23303 29821 29847 
f 24637 23303 29839 
f 29846 29820 23304 
f 29838 23304 24638 
s 1026
f 27080 25625 25633 
s 1040
f 25625 27080 33345 
s 1026
f 24611 23306 24901 
f 27698 32759 33781 
s 1040
f 32716 27092 24648 
s 1026
f 25798 24648 27092 
f 27103 33601 32639 
f 23874 27103 24651 
f 33733 28969 21749 
f 28441 21749 28969 
s 516
f 27825 23310 28037 
s 544
f 33491 28037 23310 
s off
f 20932 30322 31233 
f 31245 31233 30322 
f 31226 30324 21002 
f 30324 31226 31241 
f 30318 31235 31247 
f 31235 30318 20966 
f 20965 30319 31234 
f 31246 31234 30319 
f 21003 30323 31227 
f 31240 31227 30323 
f 31239 31228 30320 
f 30035 30320 31228 
f 30321 31229 31238 
f 31229 30321 30038 
f 30325 31232 31242 
f 31232 30325 20934 
s 1026
f 28866 28930 23319 
f 28930 32728 32869 
f 28818 24783 21590 
s 1040
f 26473 32970 32803 
f 28684 21788 22774 
s 1026
f 21788 28684 33339 
s off
f 33813 31553 31552 
f 21292 21094 23322 
f 31562 31761 31650 
f 21477 23323 21303 
f 21302 23324 21476 
f 31654 31762 31563 
f 31655 31770 31554 
f 23322 23325 21292 
f 21291 23326 21112 
f 31503 31764 31656 
f 23168 21227 23327 
f 28073 31706 31666 
f 31665 31705 28072 
f 23328 21226 23167 
f 33818 31643 31538 
f 21112 23329 21291 
f 21036 23330 21290 
f 31728 31642 31544 
f 21289 23331 21035 
f 31555 31630 31727 
f 21476 23332 21302 
f 31513 31633 31725 
f 21303 23333 21477 
f 31726 31645 31558 
f 23334 21280 22559 
f 32683 31492 31561 
f 33561 31496 31560 
f 23335 20983 21401 
f 23336 21189 23087 
f 33660 31497 31559 
f 24357 21081 23337 
f 26893 23337 21081 
f 26900 23338 21135 
f 21354 21135 23338 
f 32681 31529 31523 
f 23339 21283 22558 
f 22560 21281 23340 
f 31487 31494 33503 
f 31502 31548 33763 
f 22557 21282 23341 
f 21340 20986 23342 
f 31489 31498 33564 
f 23343 21134 21355 
f 21134 23343 26899 
f 21013 23344 26895 
f 23344 21013 21353 
f 31488 31499 33515 
f 23084 21198 23345 
f 31462 21450 23347 
f 21513 23347 23348 
f 23347 32044 21451 
f 23347 21451 31461 
s 1026
f 23349 27008 33722 
s 1040
f 33401 33744 23061 
f 33764 27088 24806 
s 1026
f 26372 24806 27088 
s off
f 30880 26389 21253 
f 31911 26358 23351 
f 21254 26388 30881 
f 23352 26359 31912 
f 31137 30939 30891 
f 30939 31137 22964 
f 22963 31136 30938 
f 30890 30938 31136 
s 1040
f 21763 28642 28747 
s 1026
f 32092 32201 28642 
f 24845 21664 27064 
s 1040
f 33038 33146 29569 
s 1026
f 22789 26904 28163 
s 1040
f 32755 28163 26904 
s off
f 30933 31568 31166 
f 31167 31569 30932 
s 1040
f 25676 29203 23360 
s 1026
f 33340 23360 29203 
s off
f 20978 27001 23175 
s 1026
f 32102 32225 27490 
f 25029 27490 25295 
f 25296 27485 25028 
f 27485 32175 32061 
f 25290 27474 25027 
s 1040
f 27474 32118 32234 
s 1026
f 25293 27477 25030 
s 1040
f 27477 32216 32230 
s 1026
f 25035 27478 25289 
f 32172 32153 27478 
f 27488 32173 32180 
f 25287 27488 25033 
f 25032 27489 25292 
f 32209 32182 27489 
s 1040
f 32202 32223 27484 
s 1026
f 25034 27484 25288 
f 27486 32143 32217 
f 25291 27486 25031 
f 25037 27487 25275 
f 32075 32177 27487 
f 27467 32093 32188 
f 25294 27467 25036 
f 27073 25039 25657 
s 1040
f 25039 27073 32460 
f 32206 32140 27470 
s 1026
f 25057 27470 25276 
s 1040
f 27120 25050 25683 
s 1026
f 25050 27120 32344 
f 27077 25048 25680 
s 1040
f 25048 27077 32490 
s 1026
f 25681 25047 27084 
s 1040
f 32372 27084 25047 
s 1026
f 25679 25049 27115 
s 1040
f 32568 27115 25049 
f 32454 27121 25043 
s 1026
f 25720 25043 27121 
f 27124 25040 29311 
s 1040
f 25040 27124 32536 
s 1026
f 27117 25041 25719 
s 1040
f 25041 27117 32390 
f 32503 27123 25042 
s 1026
f 29312 25042 27123 
f 25788 25045 27090 
s 1040
f 32393 27090 25045 
s 1026
f 25746 25044 27125 
s 1040
f 32492 27125 25044 
s 1026
f 27076 25054 25760 
s 1040
f 25054 27076 33603 
s 1026
f 27101 25055 25761 
s 1040
f 25055 27101 33600 
s 1026
f 27503 25056 25721 
s 1040
f 25056 27503 32720 
s 1026
f 29573 25065 27126 
s 1040
f 32328 27126 25065 
f 27098 33683 32594 
s 1026
f 27060 27098 25059 
s 1040
f 29201 25064 27079 
s 1026
f 32389 27079 25064 
s 1040
f 32913 29531 25219 
s 1026
f 25692 25219 29531 
s 544
f 32895 28044 26080 
s 516
f 27753 26080 28044 
s 64
f 30267 21404 21160 
s 1026
f 33079 25214 27074 
f 24409 27074 25214 
s 1040
f 32868 27116 25215 
s 1026
f 25878 25215 27116 
f 29253 21731 22596 
s 1040
f 21731 29253 32397 
s 1026
f 23397 25795 25723 
s 1040
f 32696 33412 25795 
s 1026
f 27085 29575 29196 
s 1040
f 29575 27085 33203 
s 1026
f 21719 25063 29197 
f 32481 32371 24110 
f 29185 25062 21715 
f 24109 32343 32350 
f 27055 21668 25058 
f 29172 33823 33532 
s 1040
f 29580 32453 32820 
s 1026
f 27058 21685 25060 
s 8208
f 20721 28075 25914 
s 2147483647
f 23403 26073 30228 
f 30227 26072 23404 
s 8208
f 25915 28074 20828 
s 1040
f 27127 25819 25608 
s 1026
f 25819 27127 32289 
s 1040
f 25612 27068 32314 
s 1026
f 27068 25612 29500 
f 27128 25615 25870 
s 1040
f 25615 27128 32303 
f 33367 27107 25627 
s 1026
f 25626 25627 27107 
f 27071 25752 33016 
f 25752 27071 23850 
f 27095 25769 33265 
f 25769 27095 24408 
f 32982 25776 27082 
f 23846 27082 25776 
f 27083 25879 25874 
s 1040
f 25879 27083 33779 
s 1026
f 27110 25864 33151 
f 25864 27110 23916 
s off
f 23414 21578 24115 
f 21066 21578 23414 
s 1040
f 29577 33694 33788 
s 1026
f 27057 23415 24846 
f 33700 27006 25913 
s 1040
f 32771 33390 25913 
s off
f 21098 22127 29091 
f 29638 29091 22127 
f 29092 22128 21445 
f 22128 29092 29637 
s 1026
f 28692 23419 22194 
s 1040
f 23419 28692 29056 
f 29041 28719 23420 
s 1026
f 23104 23420 28719 
f 23962 23421 29022 
s 1040
f 28725 29022 23421 
s 1026
f 29027 23422 23963 
s 1040
f 23422 29027 28730 
s 1026
f 23954 23423 29026 
f 28707 29026 23423 
f 29057 23424 23959 
f 23424 29057 28708 
f 29055 23425 23958 
s 1040
f 23425 29055 28702 
s 1026
f 29033 23426 23956 
s 1040
f 23426 29033 28731 
s 1026
f 23957 23427 29062 
s 1040
f 28709 29062 23427 
s 1026
f 23955 23428 29042 
s 1040
f 28693 29042 23428 
s 1026
f 23961 23429 29067 
s 1040
f 28713 29067 23429 
s 1026
f 23960 23430 29049 
f 28699 29049 23430 
f 25584 23431 24306 
s 1040
f 23431 25584 29053 
s 1026
f 24307 23432 25585 
s 1040
f 29025 25585 23432 
f 25147 29066 23433 
s 1026
f 21673 23433 29066 
f 29060 23434 23970 
f 23434 29060 28723 
f 29052 23435 23048 
s 1040
f 23435 29052 25150 
f 29056 23436 23419 
s 1026
f 23436 29056 28974 
s 1040
f 23420 23437 29041 
s 1026
f 24760 29041 23437 
f 29071 23438 23953 
s 1040
f 23438 29071 28724 
s 1026
f 23439 29327 32795 
f 29327 23439 23861 
f 23440 29326 32802 
f 29326 23440 23864 
f 32830 29521 23441 
f 23792 23441 29521 
f 26903 23442 24272 
f 33311 33305 25113 
f 26915 23443 24171 
f 33191 33182 28873 
s 1040
f 24169 33387 33384 
f 28811 26932 23444 
f 32908 32912 24245 
s 1026
f 23445 26946 28868 
s 1040
f 22426 33104 33186 
s 1026
f 28898 26937 23446 
s 1040
f 24244 32841 32834 
s 1026
f 28855 26948 23447 
s 1040
f 33282 33294 24230 
s 1026
f 23448 26918 28919 
s 1040
f 32911 32919 24209 
s 1026
f 23449 26939 28859 
f 23450 26919 28932 
s 1040
f 33288 33293 24249 
s 1026
f 28901 26920 23451 
s 1040
f 22228 32979 33107 
f 23452 26933 26215 
f 33399 33415 24241 
s 1026
f 26936 23453 22444 
f 33108 33196 28826 
s 1040
f 33181 33119 24228 
s 1026
f 23454 26924 28894 
s 1040
f 32922 32925 24217 
s 1026
f 23455 26923 28872 
s 1040
f 24208 33527 33525 
f 26213 26930 23456 
f 24226 33020 33012 
s 1026
f 28902 26941 23457 
f 24240 23458 26954 
f 28856 32648 33056 
s 1040
f 32862 32875 23192 
s 1026
f 23459 26916 28944 
s 1040
f 24196 33517 33658 
f 28900 26945 23460 
f 24282 32943 32940 
s 1026
f 28882 26921 23461 
s 1040
f 33120 33086 22234 
s 1026
f 23462 26967 28721 
s 1040
f 22337 32952 32946 
s 1026
f 28705 26969 23463 
s off
f 28587 28611 21661 
f 28638 28558 23464 
s 1040
f 33236 29463 25216 
s 1026
f 21972 25216 29463 
s 1040
f 27544 27940 29272 
s 1026
f 27035 29272 27940 
s 1040
f 29271 27939 27541 
s 1026
f 27939 29271 29543 
f 27885 25220 29307 
s 1040
f 33640 29307 25220 
f 21761 25686 33794 
s 1026
f 25686 21761 27066 
f 23470 29713 33721 
s 1040
f 32771 29713 23470 
s 1026
f 33331 33333 29174 
f 25026 23471 27056 
f 27111 33578 33579 
f 25799 25052 23472 
f 33373 32846 28807 
s 1040
f 23473 28807 28955 
s 1026
f 28912 23474 25000 
s 1040
f 23474 28912 33406 
s off
f 23478 29925 21672 
f 21096 30608 29925 
s 1040
f 22403 21774 24594 
f 24934 32339 32483 
f 33632 25270 21798 
s 1026
f 22405 21798 25270 
s off
f 24840 30586 29937 
f 23478 30555 30613 
f 30715 30621 29923 
f 23479 30574 29925 
f 21992 23480 30604 
f 30673 30580 29928 
s 1026
f 25872 25869 23481 
s 1040
f 33463 23481 25869 
s off
f 21521 31133 30925 
f 23482 29640 29089 
f 29090 29641 23483 
f 30924 31132 21522 
f 20975 23484 29698 
f 27943 29698 23484 
f 23485 29699 27944 
f 29699 23485 20943 
s 1026
f 29394 33155 32895 
f 29379 29394 22071 
s off
f 30572 30655 29964 
f 22172 29964 30655 
f 30670 29958 22175 
f 29958 30670 30573 
f 30569 30659 29969 
f 22114 29969 30659 
f 30681 29968 22336 
f 29968 30681 30571 
f 30593 30569 20923 
f 30712 30615 29919 
f 29931 30591 30719 
f 20936 30571 30559 
s 1026
f 28996 25139 22038 
s 1040
f 25139 32747 32705 
s 64
f 32269 21139 30269 
f 30268 21159 32270 
s 1026
f 25587 32711 33676 
f 24991 23496 26914 
s 1040
f 23493 32754 32751 
s 1026
f 28816 26951 23497 
f 28792 33269 33276 
f 26890 23498 26938 
s off
f 23499 30141 29865 
f 30141 23499 21343 
f 29852 30143 23500 
f 21341 23500 30143 
f 29091 29638 23501 
f 31131 25266 27943 
f 23502 29637 29092 
f 27944 25265 31130 
f 27979 29799 29807 
f 23503 29814 27980 
f 21079 29822 29838 
f 29866 29838 23504 
f 23505 29839 29867 
f 29839 29823 21078 
f 24951 31721 31161 
f 21522 23506 30924 
f 30925 23507 21521 
f 31162 31722 24952 
f 23508 21288 23161 
f 31505 31767 31653 
f 31648 31771 31557 
f 23160 21287 23509 
f 30889 23510 21275 
f 22968 21275 23510 
f 30887 23511 21276 
f 22691 21276 23511 
f 23512 21277 22692 
f 21277 23512 30886 
f 21274 23513 30888 
f 23513 21274 22967 
f 29871 23514 21346 
f 22979 21346 23514 
f 21345 23515 29870 
f 23515 21345 22980 
f 30893 23516 21270 
f 22965 21270 23516 
f 21269 23517 30892 
f 23517 21269 22966 
f 23034 31168 30966 
f 29870 30966 31168 
f 30965 31169 23035 
f 31169 30965 29871 
f 31586 31599 30950 
f 30949 31598 31587 
f 23522 30140 21587 
f 29869 30140 23522 
f 21586 30142 23523 
f 23523 30142 29868 
s 1040
f 28122 27816 27701 
s 1026
f 27816 28122 33175 
s off
f 21316 23525 23007 
f 23008 23526 21315 
f 29840 29678 23527 
f 20997 23527 31898 
f 23528 29679 29841 
f 31897 23528 20998 
s 1026
f 28920 23529 26888 
s 1040
f 23529 28920 32745 
s 1026
f 23530 29073 22448 
s 1040
f 33422 32931 29073 
s 1026
f 22757 23531 28975 
s 1040
f 32708 28975 23531 
f 29045 33801 33821 
s 1026
f 25145 29045 23532 
s off
f 21415 23533 31895 
f 29842 29683 23533 
f 31896 23534 21416 
f 23534 29682 29843 
s 1026
f 29021 33099 32564 
f 25142 29021 23535 
f 33587 26469 26947 
f 28850 26947 26469 
f 26917 26500 33591 
s 1040
f 26500 26917 26214 
s 1026
f 24189 26496 26926 
s 1040
f 26496 32730 33286 
s 1026
f 32796 33095 28181 
f 26301 26965 28663 
s 1040
f 32741 32746 22603 
s 1026
f 23540 26911 25111 
s 1040
f 22694 33476 33474 
f 28879 26929 23541 
s 1026
f 33665 32719 26935 
s 1040
f 33048 26074 26935 
s 1026
f 33676 28067 25587 
s 1040
f 25587 28067 33395 
s 1026
f 27838 28138 32509 
f 28138 27838 27595 
s off
f 29933 30646 30727 
f 21563 30639 24828 
f 30720 30633 29943 
f 23546 29939 20962 
f 30432 30661 29960 
f 29960 30661 30584 
f 30696 29978 29960 
f 29978 30696 26226 
f 30653 29979 30686 
f 26222 30686 29979 
s 1026
f 33149 26493 28917 
f 28953 28917 26493 
s 1040
f 28223 26309 28771 
s 1026
f 32287 28771 26309 
f 26353 29019 32429 
f 29019 26353 28284 
f 32955 32781 29069 
f 23553 29069 28706 
f 27036 23554 23935 
s 1040
f 33126 33088 29190 
s 1026
f 33530 33534 29186 
f 27038 23555 23898 
f 33253 33244 23587 
f 23556 27041 29205 
f 33301 33296 23929 
f 23557 27039 25639 
f 29184 27042 23558 
f 23928 33185 33194 
s 1040
f 23559 25638 25651 
s 1026
f 25638 23559 23903 
s 1040
f 23560 25640 25653 
s 1026
f 25640 23560 25303 
s 1040
f 25659 25637 23561 
s 1026
f 27102 23561 25637 
s 1040
f 23562 25639 25672 
s 1026
f 25639 23562 23557 
f 23563 25641 25649 
s 1040
f 25641 23563 24411 
f 25652 29191 23564 
s 1026
f 23477 23564 29191 
f 27114 23565 29200 
s 1040
f 25647 29200 23565 
s 1026
f 23566 29204 29171 
s 1040
f 29204 23566 23840 
s 1026
f 25650 29195 23567 
s 1040
f 27078 23567 29195 
s 1026
f 27472 29260 29509 
s 1040
f 29260 27472 27108 
s 1026
f 29557 29263 27475 
s 1040
f 24020 27475 29263 
f 29558 29265 27479 
s 1026
f 24422 27479 29265 
s 1040
f 27471 29261 29510 
s 1026
f 29261 27471 27122 
s 1040
f 27476 29266 29511 
s 1026
f 29266 27476 27106 
f 27086 27469 29270 
s 1040
f 29555 29270 27469 
f 27482 29267 29514 
s 1026
f 29267 27482 23000 
f 27483 29269 29513 
s 1040
f 29269 27483 24459 
s 1026
f 24111 27473 29262 
s 1040
f 29556 29262 27473 
s 1026
f 29554 29259 27468 
s 1040
f 24108 27468 29259 
s 1026
f 29202 29512 27065 
s 1040
f 27481 27065 29512 
f 29510 27059 27471 
s 1026
f 27059 29510 23226 
f 23250 25848 23580 
s 1040
f 24386 23580 25848 
f 23581 25850 25887 
s 1026
f 25850 23581 27112 
f 23582 25852 25885 
s 1040
f 25852 23582 24387 
f 25888 25847 23583 
s 1026
f 21890 23583 25847 
f 27062 29559 29528 
s 1040
f 29559 27062 27480 
s 1026
f 29568 25888 23585 
s 1040
f 23583 23585 25888 
s 1026
f 23586 25887 27037 
s 1040
f 25887 23586 23581 
s 1026
f 23587 25885 33253 
f 25885 23587 23582 
s off
f 23588 29674 24713 
f 21330 23588 28014 
f 29675 30276 31242 
f 23589 29675 24715 
f 28013 23590 21328 
f 24717 29676 23590 
f 23591 29673 24716 
f 29673 30279 31247 
f 24720 29681 23592 
f 31239 30282 29681 
f 29680 30281 31238 
f 23593 29680 24719 
f 31246 30280 29672 
f 24714 29672 23594 
f 31245 30271 29677 
f 24718 29677 23595 
f 29749 28624 23596 
f 23596 28624 22159 
f 23597 28628 29751 
f 22158 28628 23597 
f 23598 28625 29750 
f 28625 23598 20935 
f 29748 28623 23599 
f 20931 23599 28623 
f 23600 28627 22157 
f 29752 28627 23600 
f 23601 28626 29743 
f 22156 28626 23601 
f 33661 28630 23602 
f 23602 28630 29753 
f 23603 28629 33462 
f 29754 28629 23603 
s 8208
f 25366 26891 28551 
s 8194
f 26891 25366 20832 
s 8208
f 28550 26892 25518 
s 8194
f 20725 25518 26892 
s 1026
f 28688 23606 22772 
s 1040
f 23606 28688 32817 
s 1026
f 22773 23607 28695 
s 1040
f 33025 28695 23607 
s 1026
f 25516 26448 22725 
s 1040
f 26448 25516 33613 
s 1026
f 33276 23609 28792 
f 28806 28792 23609 
s off
f 23610 29088 29746 
f 29088 23610 20949 
f 29747 29087 23611 
f 20942 23611 29087 
s 1040
f 32719 33665 26492 
s 1026
f 26956 26492 24998 
s off
f 29744 29141 23613 
s 1
f 23613 29141 21071 
s off
f 23614 29142 29745 
s 1
f 21072 29142 23614 
s 1040
f 32861 32863 23231 
s 1026
f 23615 26910 25109 
s 1040
f 23134 33116 32999 
s 1026
f 25114 26908 23616 
f 22787 28857 22578 
f 28857 22787 22790 
s 1040
f 28955 22790 23473 
s 1026
f 22790 28955 28857 
s 1040
f 28220 28662 22769 
s 1026
f 28662 28220 32662 
s 1040
f 22754 28656 28178 
s 1026
f 28444 28178 28656 
f 28258 28665 22753 
s 1040
f 28665 28258 28442 
f 28326 28406 28676 
s 1026
f 22751 28676 28406 
s 1040
f 28403 28678 22752 
s 1026
f 28678 28403 28447 
f 26327 28356 28655 
s 1040
f 22741 28655 28356 
f 22746 28668 28299 
s 1026
f 28283 28299 28668 
s 1040
f 28182 28659 22736 
s 1026
f 28659 28182 26337 
s 1040
f 33147 28342 28671 
s 1026
f 22745 28671 28342 
s 1040
f 28373 28675 22748 
s 1026
f 28675 28373 28360 
s 1040
f 28510 28743 28991 
s 1026
f 28743 28510 28440 
s 1040
f 28990 28744 28511 
s 1026
f 28308 28511 28744 
f 28248 28993 28674 
f 28993 28248 28304 
f 22882 29004 28292 
f 28291 28292 29004 
f 28466 28960 28786 
s 1040
f 28960 28466 25512 
s 1026
f 28783 28961 28467 
s 1040
f 25513 28467 28961 
s 1026
f 28468 28962 22828 
s 1040
f 28962 28468 23639 
s 1026
f 24505 28959 28465 
s 1040
f 23638 28465 28959 
s 1026
f 28929 28939 22799 
f 24270 22799 28939 
s 1040
f 22447 22442 28963 
s 1026
f 28846 28963 22442 
f 22444 28966 26936 
s 1040
f 28966 22444 22446 
s 1026
f 26978 28989 28094 
s 1040
f 28507 28094 28989 
s 1026
f 22145 28982 28087 
s 1040
f 28509 28087 28982 
s 1026
f 22203 28987 28092 
f 28506 28092 28987 
f 21670 28981 28086 
f 28502 28086 28981 
f 21687 28984 28090 
s 1040
f 28505 28090 28984 
f 22455 28996 28176 
s 1026
f 22038 28176 28996 
f 26902 29011 28278 
f 28332 28278 29011 
f 28409 28661 28643 
s 1040
f 28661 28409 28384 
f 29053 22895 23431 
s 1026
f 22895 29053 24440 
s 1040
f 23432 22894 29025 
s 1026
f 24441 29025 22894 
s off
f 23650 21412 31668 
f 22601 29791 23650 
f 23651 29792 22602 
f 31667 21411 23651 
f 21409 29794 23652 
f 23652 29794 21182 
f 23653 29793 21410 
f 21086 29793 23653 
s 1026
f 21779 25053 29499 
f 33616 33615 23690 
f 24222 26473 28933 
s 1040
f 32803 28933 26473 
s 1026
f 28870 26468 24159 
s 1040
f 26468 28870 33214 
s 1026
f 33161 26513 29075 
f 28733 29075 26513 
s off
f 30080 30671 30589 
f 30671 30080 22149 
s 1040
f 32945 26491 26974 
s 1026
f 28715 26974 26491 
f 26973 26481 33825 
f 26481 26973 25141 
s 8
f 30309 24976 21723 
s off
f 30309 21723 21266 
f 31755 21386 31902 
f 29671 30127 23662 
f 31901 21385 31756 
f 23663 30130 29670 
s 1026
f 23664 29331 32858 
f 29331 23664 23827 
s 1040
f 29325 33761 33026 
s 1026
f 26361 29325 23665 
s 1040
f 23666 29525 32723 
s 1026
f 29525 23666 23773 
s off
f 23667 30435 31655 
f 29739 30126 23667 
f 23668 30128 29740 
f 31656 30434 23668 
f 23669 30441 28543 
f 29742 30129 23669 
f 28539 30450 23670 
f 23670 30133 29741 
s 1026
f 33669 29714 23671 
s 1040
f 23671 29714 33386 
s 1026
f 20860 25437 27790 
s 1040
f 33402 27790 25437 
s off
f 23673 30112 29759 
f 31652 30444 23673 
f 23674 30445 31647 
f 29758 30113 23674 
f 23675 30122 29769 
f 28545 30438 23675 
f 23676 30114 29756 
f 28532 30451 23676 
f 23677 30436 28542 
f 29768 30123 23677 
f 23678 30440 28536 
f 29766 30132 23678 
f 29757 30117 23679 
f 23679 30452 28534 
f 29765 30131 23680 
f 23680 30443 28525 
f 23681 30447 28535 
f 29760 30118 23681 
f 23682 30124 29770 
f 28544 30437 23682 
f 28541 30455 23683 
f 23683 30116 29761 
f 29763 30119 23684 
f 23684 30449 28530 
f 28524 30446 23685 
f 23685 30125 29767 
f 23686 30115 29755 
f 28531 30454 23686 
f 23687 30439 28528 
f 29762 30120 23687 
f 23688 30121 29764 
f 28529 30442 23688 
s 1026
f 22437 28780 28425 
s 1040
f 32623 28425 28780 
s 1026
f 29338 23690 23879 
f 23690 29338 33616 
f 32770 26480 29072 
f 25144 29072 26480 
f 23854 21592 25759 
f 33123 25759 21592 
f 21809 25779 32926 
f 25779 21809 23867 
s off
f 31616 28558 23694 
f 21169 28588 23009 
f 31617 23695 28557 
f 22650 28593 21058 
s 1040
f 33353 33357 21713 
f 23696 27009 29309 
f 21698 33375 33371 
f 29372 27019 23697 
s 1026
f 29362 32989 32995 
f 21702 23698 27025 
f 27020 23699 21706 
f 33731 33089 29339 
s 1040
f 33226 33247 25854 
s 1026
f 23700 27044 29241 
f 29250 27046 23701 
s 1040
f 25837 33285 33267 
s 1026
f 26950 26502 24161 
s 1040
f 33588 33335 26502 
s off
f 21141 28585 22547 
f 23703 21141 26007 
f 26006 21336 23704 
f 28609 28577 21336 
f 22999 28591 21571 
f 28590 28591 28562 
f 23706 21396 23703 
f 21396 28584 26075 
s 1026
f 29516 23707 25300 
f 23707 29516 32253 
f 23708 29589 32298 
f 29589 23708 23922 
s off
f 28577 28609 21985 
f 20929 21985 28609 
f 26010 28615 21271 
f 21077 21271 28615 
f 22547 28604 21141 
f 28604 22547 21193 
s 1040
f 32415 29482 23712 
s 1026
f 21785 23712 29482 
s off
f 21471 21076 28599 
f 26012 28599 21076 
s 1040
f 26499 28867 32914 
s 1026
f 28867 26499 24195 
f 28801 26504 24243 
s 1040
f 26504 28801 32616 
f 32786 28809 26471 
s 1026
f 24210 26471 28809 
f 28883 26487 33195 
f 26487 28883 28843 
s 1040
f 32988 28846 26501 
s 1026
f 22442 26501 28846 
f 26483 28878 28895 
f 28878 26483 33035 
f 28845 26486 24197 
s 1040
f 26486 28845 33643 
s 1026
f 29058 26477 33266 
f 26477 29058 28697 
f 28974 26522 23436 
s 1040
f 26522 28974 32805 
s 1026
f 33224 25791 22031 
f 23832 22031 25791 
f 25747 25751 22083 
s 1040
f 25751 32639 33601 
s 1026
f 21988 25613 25621 
s 1040
f 32724 33320 25613 
f 27108 29174 29260 
s 1026
f 29174 27108 33331 
f 33579 29328 27111 
f 23833 27111 29328 
s 1040
f 33788 27086 29577 
s 1026
f 29270 29577 27086 
f 22225 27558 29453 
s 1040
f 33506 29453 27558 
s 1026
f 23730 29318 33552 
s 1040
f 29318 23730 23851 
s 1026
f 23731 29527 32348 
f 29527 23731 23777 
f 21605 23732 33789 
s 1040
f 32807 33408 21908 
s off
f 21576 23733 29984 
f 29771 29984 23733 
f 23734 29983 29772 
f 29983 23734 21577 
s 1026
f 26934 23735 24188 
f 33667 33222 28880 
f 26494 33351 33350 
f 25143 26975 23736 
s off
f 31901 23737 21385 
f 23737 30012 22508 
f 21386 23738 31902 
f 22509 30011 23738 
f 28543 23739 21264 
f 22510 30008 23739 
f 21378 23740 28533 
f 23740 30009 22385 
f 21690 23741 28529 
f 23741 30016 22707 
f 23742 30019 22708 
f 21692 23742 28531 
f 22709 30017 23743 
f 28527 23743 21653 
f 23744 30005 21229 
f 22241 23744 28541 
f 28528 23745 21689 
f 22710 30022 23745 
f 22712 30021 23746 
f 28530 23746 21693 
f 28525 23747 22074 
f 22711 30014 23747 
f 28536 23748 21377 
f 22511 30013 23748 
f 28534 23749 22238 
f 21220 30003 23749 
f 23750 30020 22713 
f 21654 23750 28526 
f 21300 30004 23751 
f 28535 23751 22239 
f 23752 30018 22714 
f 21691 23752 28524 
f 22237 23753 28544 
f 23753 30006 21295 
f 21230 30015 23754 
f 28542 23754 22236 
f 21376 23755 28545 
f 23755 30007 22512 
f 23756 30002 21301 
f 22240 23756 28532 
s 1026
f 27028 23757 22000 
f 32810 32800 29410 
f 29352 33170 33074 
f 21875 23758 27017 
s off
f 28600 21472 21471 
f 21472 28600 28561 
s 1026
f 29504 27005 27067 
s 1040
f 32300 27067 27005 
s 1026
f 29200 27043 27114 
s 1040
f 32661 27114 27043 
s 1026
f 23762 25633 25611 
f 25633 23762 27080 
s 1040
f 23763 25611 21711 
s 1026
f 25611 23763 23762 
s 1040
f 23764 25618 24645 
s 1026
f 25618 23764 23765 
f 23765 25621 25618 
f 25621 23765 21988 
s 1040
f 23766 25622 25868 
s 1026
f 25622 23766 23908 
f 23767 25619 26362 
s 1040
f 25619 23767 20827 
s 1026
f 23768 25605 25645 
s 1040
f 25605 23768 24388 
s 1026
f 25606 23769 21857 
s 1040
f 23769 25606 25821 
f 23770 25604 25826 
s 1026
f 25604 23770 24918 
f 29523 25656 23771 
s 1040
f 24385 23771 25656 
s 1026
f 23772 25654 29526 
s 1040
f 25654 23772 24384 
s 1026
f 23773 25655 29525 
f 25655 23773 24382 
f 21845 25650 23774 
f 23567 23774 25650 
f 24383 23775 25648 
f 29524 25648 23775 
f 23776 25649 24804 
f 25649 23776 23563 
f 23777 25653 29527 
s 1040
f 25653 23777 23560 
s 1026
f 23778 25651 26207 
s 1040
f 25651 23778 23559 
s 1026
f 26208 25652 23779 
s 1040
f 23564 23779 25652 
f 23565 23780 25647 
s 1026
f 25271 25647 23780 
s 1040
f 25833 25658 23781 
s 1026
f 27119 23781 25658 
s 1040
f 23782 25657 25832 
s 1026
f 25657 23782 27073 
s 1040
f 22954 25664 23783 
s 1026
f 24031 23783 25664 
s 1040
f 23784 25660 22955 
s 1026
f 25660 23784 24030 
f 23785 25672 25830 
s 1040
f 25672 23785 23562 
f 22948 25671 23786 
s 1026
f 24027 23786 25671 
s 1040
f 24378 23787 25673 
s 1026
f 25834 25673 23787 
f 23788 25670 29522 
s 1040
f 25670 23788 24379 
f 22947 25674 23789 
s 1026
f 24026 23789 25674 
f 22950 25668 23790 
s 1040
f 24024 23790 25668 
f 23791 25669 22949 
s 1026
f 25669 23791 24022 
f 29521 25667 23792 
s 1040
f 24377 23792 25667 
s 1026
f 23793 25666 22951 
s 1040
f 25666 23793 24023 
s 1026
f 24381 23794 25665 
s 1040
f 25735 25665 23794 
s 1026
f 23795 25662 21707 
s 1040
f 25662 23795 24380 
f 23796 25661 22952 
s 1026
f 25661 23796 24025 
f 21912 25659 23797 
s 1040
f 23561 23797 25659 
f 25824 25678 23798 
s 1026
f 23078 23798 25678 
s 1040
f 25822 25679 23799 
s 1026
f 27115 23799 25679 
s 1040
f 22953 25677 23800 
s 1026
f 24028 23800 25677 
s 1040
f 23360 23801 25676 
s 1026
f 25827 25676 23801 
s 1040
f 23802 25663 22946 
s 1026
f 25663 23802 24032 
s 1040
f 25829 25681 23803 
s 1026
f 27084 23803 25681 
f 25680 23804 27077 
s 1040
f 23804 25680 25828 
s 1026
f 23805 25683 25823 
s 1040
f 25683 23805 27120 
f 23476 23806 25682 
s 1026
f 25825 25682 23806 
f 22956 25675 23807 
s 1040
f 24029 23807 25675 
s 1026
f 23808 25608 29579 
s 1040
f 25608 23808 27127 
s 1026
f 25272 25610 23809 
s 1040
f 27053 23809 25610 
f 23810 25849 25886 
s 1026
f 25849 23810 24376 
s 1040
f 25889 25846 23811 
s 1026
f 21681 23811 25846 
f 23812 25851 22945 
s 1040
f 25851 23812 24021 
s 1026
f 24646 25631 23813 
s 1040
f 23817 23813 25631 
f 29316 25629 23814 
s 1026
f 24421 23814 25629 
f 23815 25628 25630 
s 1040
f 25628 23815 23886 
s 1026
f 27107 23816 25626 
f 25620 25626 23816 
s 1040
f 25631 25623 23817 
s 1026
f 23818 23817 25623 
f 25623 25624 23818 
s 1040
f 32881 23818 25624 
f 21695 25620 23819 
s 1026
f 23816 23819 25620 
f 29322 25616 23820 
s 1040
f 23821 23820 25616 
f 25616 25617 23821 
s 1026
f 25305 23821 25617 
s 1040
f 23262 23822 25614 
s 1026
f 20891 25614 23822 
f 27089 23823 25805 
f 25717 25805 23823 
f 27121 23824 25720 
s 1040
f 25756 25720 23824 
f 23825 25719 25731 
s 1026
f 25719 23825 27117 
s 1040
f 29321 25717 23826 
s 1026
f 23823 23826 25717 
f 23827 25716 29331 
s 1040
f 25716 23827 27052 
s 1026
f 25723 23828 23397 
f 23828 25723 25796 
s 1040
f 23829 25787 29506 
s 1026
f 25787 23829 24810 
s 1040
f 23830 25724 25051 
s 1026
f 25724 23830 23888 
s 1040
f 25737 25722 23831 
s 1026
f 23835 23831 25722 
f 25791 25790 23832 
s 1040
f 23894 23832 25790 
s 1026
f 29328 25768 23833 
s 1040
f 23895 23833 25768 
s 1026
f 27125 23834 25746 
s 1040
f 25802 25746 23834 
s 1026
f 25722 25733 23835 
f 27070 23835 25733 
f 23836 25727 20783 
s 1040
f 25727 23836 23256 
s 1026
f 27090 23837 25788 
s 1040
f 25803 25788 23837 
f 25792 23838 32865 
s 1026
f 23838 25792 25774 
s 1040
f 23839 25793 25775 
s 1026
f 25793 23839 24812 
s 1040
f 23840 25789 29204 
s 1026
f 25789 23840 24919 
f 26369 25786 23841 
s 1040
f 20746 23841 25786 
f 23842 25785 25736 
s 1026
f 25785 23842 25284 
f 26367 25783 23843 
s 1040
f 20720 23843 25783 
f 25780 25784 23844 
s 1026
f 23875 23844 25784 
f 25770 25782 23845 
s 1040
f 32798 23845 25782 
f 23880 23846 25781 
s 1026
f 25776 25781 23846 
f 23847 25778 29324 
s 1040
f 25778 23847 23883 
s 1026
f 23848 25777 26368 
s 1040
f 25777 23848 20853 
s 1026
f 23849 25773 20757 
s 1040
f 25773 23849 33362 
s 1026
f 23850 25775 25752 
s 1040
f 25775 23850 23839 
f 23851 25772 29318 
s 1026
f 25772 23851 23882 
s 1040
f 25766 25770 23852 
s 1026
f 23845 23852 25770 
f 29310 25771 23853 
s 1040
f 33602 23853 25771 
s 1026
f 25759 25767 23854 
s 1040
f 23862 23854 25767 
f 27051 23855 25763 
s 1026
f 24652 25763 23855 
f 23856 25764 24656 
s 1040
f 25764 23856 23881 
f 25765 23857 33011 
s 1026
f 23857 25765 25754 
s 1040
f 23858 25761 25757 
s 1026
f 25761 23858 27101 
f 23859 25762 22021 
s 1040
f 25762 23859 23865 
f 23860 25760 25755 
s 1026
f 25760 23860 27076 
f 23861 25757 29327 
s 1040
f 25757 23861 23858 
f 25767 25758 23862 
s 1026
f 25072 23862 25758 
s 1040
f 23863 25754 25742 
s 1026
f 25754 23863 23857 
f 23864 25755 29326 
s 1040
f 25755 23864 23860 
s 1026
f 25753 23865 21897 
s 1040
f 23865 25753 25762 
s 1026
f 26365 25750 23866 
s 1040
f 20811 23866 25750 
s 1026
f 23867 25749 25779 
s 1040
f 25749 23867 23870 
f 25740 25747 23868 
s 1026
f 22083 23868 25747 
s 1040
f 25744 25748 23869 
s 1026
f 27096 23869 25748 
s 1040
f 23870 25745 25749 
s 1026
f 25745 23870 25071 
f 23871 25743 33164 
f 25743 23871 24400 
f 23872 25742 24650 
s 1040
f 25742 23872 23863 
s 1026
f 25268 25744 23873 
s 1040
f 23869 23873 25744 
s 1026
f 24651 25740 23874 
s 1040
f 23868 23874 25740 
s 1026
f 25784 25739 23875 
s 1040
f 32752 23875 25739 
s 1026
f 23876 25734 26366 
s 1040
f 25734 23876 20918 
s 1026
f 24658 25735 23877 
s 1040
f 23794 23877 25735 
s 1026
f 23878 25736 29329 
s 1040
f 25736 23878 23842 
s 1026
f 23879 25732 29338 
s 1040
f 25732 23879 27054 
f 25781 25728 23880 
s 1026
f 24811 23880 25728 
s 1040
f 23881 25774 25764 
s 1026
f 25774 23881 23838 
f 23882 25730 25772 
f 25730 23882 25279 
s 1040
f 23883 25738 25778 
s 1026
f 25738 23883 25281 
f 24654 25766 23884 
s 1040
f 23852 23884 25766 
s 1026
f 24653 25780 23885 
s 1040
f 23844 23885 25780 
f 23886 25804 25628 
s 1026
f 25804 23886 25073 
f 23887 25729 29330 
s 1040
f 25729 23887 23897 
s 1026
f 23888 25797 25724 
f 25797 23888 24033 
f 29335 25756 23889 
s 1040
f 23824 23889 25756 
f 25731 23890 23825 
s 1026
f 23890 25731 29337 
s 1040
f 23891 25796 26196 
s 1026
f 25796 23891 23828 
f 29334 25802 23892 
s 1040
f 23834 23892 25802 
f 23837 23893 25803 
s 1026
f 29336 25803 23893 
s 1040
f 25790 25798 23894 
s 1026
f 27092 23894 25798 
f 23472 23895 25799 
s 1040
f 25768 25799 23895 
f 29317 25726 23896 
s 1026
f 24404 23896 25726 
s 1040
f 23897 25721 25729 
s 1026
f 25721 23897 27503 
f 23898 25645 27038 
f 25645 23898 23768 
f 23899 25643 22397 
s 1040
f 25643 23899 32947 
s 1026
f 24924 23900 25642 
s 1040
f 22936 25642 23900 
f 23901 25644 22937 
s 1026
f 25644 23901 21732 
s 1040
f 25880 25718 23902 
s 1026
f 24922 23902 25718 
f 23903 29518 25638 
s 1040
f 29518 23903 33709 
s 1026
f 23904 25862 26206 
s 1040
f 25862 23904 23910 
s 1026
f 26204 25863 23905 
s 1040
f 23913 23905 25863 
s 1026
f 29333 25880 23906 
s 1040
f 23902 23906 25880 
f 25875 25878 23907 
s 1026
f 27116 23907 25878 
f 23908 25877 25622 
s 1040
f 25877 23908 32973 
s 1026
f 26205 25875 23909 
s 1040
f 23907 23909 25875 
s 1026
f 25874 23910 27083 
s 1040
f 23910 25874 25862 
f 24662 25873 23911 
s 1026
f 23912 23911 25873 
f 25873 25872 23912 
f 23481 23912 25872 
s 1040
f 25863 25867 23913 
s 1026
f 23917 23913 25867 
f 23914 25868 24663 
s 1040
f 25868 23914 23766 
f 23915 25866 25865 
s 1026
f 25866 23915 24808 
f 23916 25865 25864 
s 1040
f 25865 23916 23915 
f 33807 23917 25871 
s 1026
f 25867 25871 23917 
s 1040
f 23918 25876 26203 
s 1026
f 25876 23918 24407 
f 29571 25825 23919 
f 23806 23919 25825 
f 23920 25823 29582 
f 25823 23920 23805 
f 23921 25826 29585 
s 1040
f 25826 23921 23770 
s 1026
f 23922 25821 29589 
s 1040
f 25821 23922 23769 
s 1026
f 29581 25824 23923 
s 1040
f 23798 23923 25824 
f 23799 23924 25822 
s 1026
f 29583 25822 23924 
f 24700 25827 23925 
f 23801 23925 25827 
s 1040
f 23803 23926 25829 
s 1026
f 29586 25829 23926 
f 23927 25828 29588 
s 1040
f 25828 23927 23804 
s 1026
f 33194 25834 23928 
f 23787 23928 25834 
f 23929 25830 33301 
f 25830 23929 23785 
f 29587 25833 23930 
s 1040
f 23781 23930 25833 
s 1026
f 29576 25831 23931 
s 1040
f 25277 23931 25831 
s 1026
f 23932 25832 29584 
s 1040
f 25832 23932 23782 
s 1026
f 32824 29568 23933 
f 23585 23933 29568 
f 29566 25889 23934 
s 1040
f 23811 23934 25889 
s 1026
f 23935 25886 27036 
s 1040
f 25886 23935 23810 
f 23936 25870 29199 
s 1026
f 25870 23936 27128 
s off
f 23937 21260 24954 
f 21260 23937 31453 
f 24953 21261 23938 
f 31452 23938 21261 
f 30885 23939 21258 
f 23040 21258 23939 
f 30883 23940 21250 
f 23011 21250 23940 
f 23941 21249 23012 
f 21249 23941 30882 
f 21259 23942 30884 
f 23942 21259 23039 
f 23943 30023 22184 
f 21471 30023 23943 
f 23944 30025 21382 
f 22185 30025 23944 
f 21343 30024 23945 
f 23945 30024 21379 
f 21380 30026 23946 
f 23946 30026 21341 
s 1026
f 28649 23947 24119 
s 1040
f 23947 28649 33166 
f 28650 23948 24124 
s 1026
f 23948 28650 32520 
f 24126 23949 28644 
s 1040
f 32351 28644 23949 
s 1026
f 28646 23950 24127 
s 1040
f 23950 28646 32500 
s 1026
f 24142 23951 28645 
s 1040
f 32570 28645 23951 
s 1026
f 28948 23952 24290 
s 1040
f 23952 28948 32288 
s 1026
f 32048 32047 28758 
f 23953 28758 29071 
s 1040
f 28765 32119 32134 
s 1026
f 29026 28765 23954 
f 29042 28768 23955 
f 28768 32244 32161 
f 32144 32257 28769 
f 23956 28769 29033 
f 28766 32150 32186 
f 29062 28766 23957 
f 32185 32159 28767 
f 23958 28767 29055 
s 1040
f 32238 32124 28764 
s 1026
f 23959 28764 29057 
s 1040
f 28761 32220 32136 
s 1026
f 29049 28761 23960 
f 28762 32125 32193 
f 29067 28762 23961 
f 28759 32179 32215 
f 29022 28759 23962 
f 23963 28760 29027 
f 32212 32192 28760 
f 24256 23964 28945 
s 1040
f 32496 28945 23964 
s 1026
f 24259 23965 28935 
s 1040
f 32446 28935 23965 
f 32511 28943 23966 
s 1026
f 24274 23966 28943 
f 28940 23967 24261 
s 1040
f 23967 28940 32392 
s 1026
f 24260 23968 28942 
s 1040
f 32398 28942 23968 
f 23969 28934 32642 
s 1026
f 28934 23969 26887 
s 1040
f 32241 32123 28763 
s 1026
f 23970 28763 29060 
s 1040
f 22421 23971 28978 
s 1026
f 32388 28978 23971 
f 28979 23972 22422 
s 1040
f 23972 28979 32435 
f 32431 28980 23973 
s 1026
f 22424 23973 28980 
s 1040
f 28977 23974 22420 
s 1026
f 23974 28977 32517 
f 29419 23975 21974 
s 1040
f 23975 29419 33565 
s 1026
f 32774 32760 29366 
f 23976 29366 29441 
s off
f 21406 30001 23977 
f 28537 23977 22485 
f 22577 23978 28539 
f 23978 30010 21405 
s 1026
f 33429 27078 29578 
s 1040
f 29195 29578 27078 
f 33214 33273 26468 
s 1026
f 23980 26928 28937 
s 1040
f 32932 27572 29612 
s 1026
f 29234 29612 27572 
s 1040
f 32835 32838 29187 
s 1026
f 27037 23982 23586 
s 1040
f 32815 32819 23062 
s 1026
f 23983 22164 28824 
s off
f 29670 23984 32039 
f 23984 29670 30130 
f 32040 23985 29671 
f 30127 29671 23985 
f 30143 29852 23986 
f 28556 23986 22068 
f 22067 23987 28553 
f 23987 29865 30141 
f 28555 23988 22086 
f 30140 29869 23988 
f 22085 23989 28554 
f 23989 29868 30142 
f 21382 29741 23990 
f 23990 29741 30133 
f 29740 23991 21417 
f 23991 29740 30128 
f 30129 29742 23992 
f 23992 29742 21471 
f 21443 23993 29739 
f 30126 29739 23993 
f 31550 29764 23994 
f 23994 29764 30121 
f 23995 29755 30115 
f 31556 29755 23995 
f 23996 29762 31517 
f 30120 29762 23996 
f 23997 29767 30125 
f 31540 29767 23997 
f 23998 29765 31522 
f 30131 29765 23998 
f 23999 29763 31519 
f 30119 29763 23999 
f 29759 24000 33492 
f 24000 29759 30112 
f 33819 24001 29758 
f 30113 29758 24001 
f 30633 30720 29951 
f 22720 29951 30720 
s 1040
f 29187 27112 32835 
s 1026
f 27112 29187 25850 
f 27069 29171 32327 
f 29171 27069 23566 
f 23906 27109 29333 
f 32512 29333 27109 
f 27065 27104 29202 
f 33792 29202 27104 
f 29193 29574 27081 
s 1040
f 33004 27081 29574 
s 1026
f 27105 29528 33778 
f 29528 27105 27062 
f 32258 29515 27087 
f 25299 27087 29515 
f 27122 29567 29261 
s 1040
f 29567 27122 32336 
s 1026
f 27106 29572 29266 
s 1040
f 29572 27106 32871 
f 25468 32775 32772 
s 1026
f 27645 26096 24012 
s 1040
f 25424 32950 32941 
s 1026
f 25800 26135 24013 
s 1040
f 33326 33327 25163 
f 24014 26125 27821 
f 26970 24015 33824 
s 1026
f 24015 26970 22465 
s off
f 21223 29738 22513 
f 22513 29738 29947 
f 29948 29737 22514 
f 22514 29737 21224 
f 22207 28637 28557 
f 33464 28637 22207 
s 1026
f 24595 28121 27621 
s 1040
f 33298 27621 28121 
f 29263 29173 24020 
s 1026
f 29173 32594 33683 
s 1040
f 24021 29281 25851 
s 1026
f 32140 32206 29281 
f 24022 29287 25669 
s 1040
f 32153 32172 29287 
f 24023 29289 25666 
s 1026
f 32223 32202 29289 
s 1040
f 25668 29286 24024 
s 1026
f 29286 32230 32216 
f 24025 29280 25661 
s 1040
f 32182 32209 29280 
s 1026
f 25674 29284 24026 
s 1040
f 29284 32180 32173 
s 1026
f 25671 29282 24027 
s 1040
f 29282 32217 32143 
s 1026
f 25677 29288 24028 
s 1040
f 29288 32188 32093 
f 25675 29290 24029 
s 1026
f 29290 32234 32118 
f 24030 29283 25660 
s 1040
f 32225 32102 29283 
s 1026
f 25664 29285 24031 
s 1040
f 29285 32061 32175 
s 1026
f 24032 29279 25663 
s 1040
f 32177 32075 29279 
f 33618 33455 29323 
s 1026
f 24033 29323 25797 
f 32540 29336 24034 
f 23893 24034 29336 
f 32532 29334 24035 
f 23892 24035 29334 
f 24036 29330 32579 
f 29330 24036 23887 
f 24037 29337 32464 
f 29337 24037 23890 
f 32574 29314 24038 
f 24401 24038 29314 
f 24039 29332 32565 
f 29332 24039 24402 
f 32439 29335 24040 
f 23889 24040 29335 
s 1040
f 23814 24041 29316 
s 1026
f 33553 29316 24041 
f 23926 24042 29586 
f 32455 29586 24042 
f 24043 29588 32475 
f 29588 24043 23927 
s 1040
f 24044 29582 32366 
s 1026
f 29582 24044 23920 
f 24045 29584 32357 
f 29584 24045 23932 
f 23930 24046 29587 
f 32355 29587 24046 
f 23924 24047 29583 
f 32622 29583 24047 
s 1040
f 32498 29524 24048 
s 1026
f 23775 24048 29524 
s 1040
f 24178 29648 26210 
s 2147483647
f 27000 26210 29648 
s 1040
f 26215 29643 23452 
s 2147483647
f 29643 26215 26997 
f 26996 26217 29642 
s 8194
f 20895 29642 26217 
s 2147483647
f 26993 26211 29649 
s 1040
f 21887 29649 26211 
s 8194
f 26216 29644 20788 
s 2147483647
f 29644 26216 26995 
f 26994 26213 29645 
s 1040
f 23456 29645 26213 
f 26214 29646 26500 
s 2147483647
f 29646 26214 26999 
s 1040
f 26212 29647 24239 
s 2147483647
f 29647 26212 26998 
s 1026
f 28941 24057 24275 
s 1040
f 24057 28941 32407 
s 1026
f 33738 33400 25515 
f 24058 26085 27822 
f 26111 24059 23626 
f 33188 33158 27756 
s 1040
f 28704 24060 24269 
s 1026
f 24060 28704 32313 
f 28651 24061 24148 
s 1040
f 24061 28651 32370 
s 1026
f 28949 24062 24179 
s 1040
f 24062 28949 32317 
f 33678 28925 24063 
s 1026
f 24173 24063 28925 
f 28836 24064 20749 
s 1040
f 24064 28836 33379 
s 1026
f 28810 33286 32730 
f 28800 28810 24065 
f 24190 24066 28829 
s 1040
f 32850 28829 24066 
s 1026
f 32738 33078 29031 
f 24067 29031 28683 
s 1040
f 32310 29070 24068 
s 1026
f 22416 24068 29070 
f 27787 24069 23628 
s 1040
f 24069 27787 33263 
s off
f 32035 21085 24070 
f 24070 29947 29738 
f 29737 29948 24071 
f 32036 24071 21148 
s 1026
f 23933 32813 32824 
f 25847 26201 21890 
f 25846 26200 21681 
f 22590 33163 33174 
f 23905 21645 26204 
s 1040
f 25871 33803 33807 
s 1026
f 25311 33287 32100 
f 22941 26191 22141 
f 21897 26198 25753 
f 32613 33573 25282 
s 1040
f 32947 32948 25643 
s 1026
f 26194 21981 25308 
f 26207 21677 23778 
s 1040
f 33709 33607 29518 
s 1026
f 23779 21797 26208 
s 1040
f 29565 33628 33627 
f 33386 33752 23671 
s 1026
f 24080 27007 33715 
f 25748 26197 27096 
s 1040
f 33569 27096 26197 
s 1026
f 25733 26199 27070 
s 1040
f 33668 27070 26199 
s 1026
f 33412 32696 27113 
s 1040
f 26196 27113 23891 
f 28471 32098 32251 
s 1026
f 27531 26147 24084 
s 1040
f 28736 28641 24085 
s 1026
f 28641 32208 32101 
s 1040
f 28654 32074 32254 
s 1026
f 28735 28654 24086 
f 24087 28779 33142 
f 28779 24087 28350 
s 1040
f 33418 33420 28781 
f 24088 26349 28205 
f 32082 32797 28519 
s 1026
f 26289 26148 24548 
s 1040
f 27607 32748 33575 
f 25410 24090 26137 
f 27873 26092 24091 
f 23468 32667 33642 
f 32766 32768 25488 
s 1026
f 24092 26100 27807 
f 33626 29321 24093 
s 1040
f 23826 24093 29321 
s 1026
f 26182 24094 27657 
s 1040
f 33741 27657 24094 
s 1026
f 25381 24095 27597 
s 1040
f 32651 27597 24095 
s off
f 21470 30462 24096 
f 24096 30462 30087 
s 1026
f 21614 25920 33736 
s 1040
f 33395 33815 25587 
s off
f 30192 21393 29769 
s 64
f 31504 31543 31506 
s off
f 29766 21598 30202 
s 64
f 31528 31524 31525 
s off
f 29760 21174 30201 
s 64
f 31534 31518 31560 
f 31489 31551 31509 
s off
f 30206 21167 29761 
s 64
f 31532 31520 31521 
s off
f 29768 21221 30199 
s 64
f 31501 31535 31508 
s off
f 30204 21423 29770 
f 30196 21397 29756 
s 64
f 31564 31545 31511 
s off
f 29757 21419 30195 
s 64
f 31537 31516 31565 
s off
f 21077 24106 31702 
f 21317 31702 24106 
f 24107 31704 23066 
f 21067 31704 24107 
s 1040
f 29259 29172 24108 
s 1026
f 33532 24108 29172 
f 23771 24109 29523 
f 32350 29523 24109 
f 24110 29526 32481 
f 29526 24110 23772 
s 1040
f 32820 24111 29580 
s 1026
f 29262 29580 24111 
f 24112 29315 26364 
s 1040
f 32706 33767 29315 
s 1026
f 24113 29522 33063 
f 29522 24113 23788 
s off
f 21066 24114 22073 
f 23414 24114 21066 
f 24115 33322 23414 
s 1040
f 28724 24116 23438 
s 1026
f 24116 28724 24770 
f 28706 24117 23553 
s 1040
f 24117 28706 25091 
f 28685 24118 24302 
s 1026
f 24118 28685 21850 
s 1040
f 28718 24119 24280 
s 1026
f 24119 28718 28649 
s 1040
f 24120 28717 25110 
s 1026
f 28717 24120 24278 
s 1040
f 28726 24121 24277 
s 1026
f 24121 28726 28648 
s 1040
f 24279 24122 28681 
s 1026
f 21683 28681 24122 
s 1040
f 24180 24123 28794 
s 1026
f 28950 28794 24123 
s 1040
f 24124 28700 28650 
s 1026
f 28700 24124 24303 
f 24304 24125 28732 
s 1040
f 22402 28732 24125 
f 24298 24126 28728 
s 1026
f 28644 28728 24126 
s 1040
f 28710 24127 24299 
s 1026
f 24127 28710 28646 
s 1040
f 28731 24128 23426 
s 1026
f 24128 28731 24777 
s 1040
f 24236 24129 28712 
s 1026
f 25106 28712 24129 
f 24780 28709 24130 
s 1040
f 23427 24130 28709 
s 1026
f 28697 24131 26477 
s 1040
f 24131 28697 25112 
s 1026
f 28683 24132 24067 
s 1040
f 24132 28683 25096 
f 28702 24133 23425 
s 1026
f 24133 28702 24779 
f 23463 24134 28705 
s 1040
f 25107 28705 24134 
s 1026
f 23423 24135 28707 
s 1040
f 24781 28707 24135 
s 1026
f 28708 24136 23424 
s 1040
f 24136 28708 24776 
s 1026
f 28721 24137 23462 
s 1040
f 24137 28721 25103 
f 23428 24138 28693 
s 1026
f 24778 28693 24138 
f 26513 24139 28733 
s 1040
f 25094 28733 24139 
f 25115 28686 24140 
s 1026
f 24296 24140 28686 
f 23430 24141 28699 
s 1040
f 24775 28699 24141 
f 24300 24142 28701 
s 1026
f 28645 28701 24142 
f 24772 28713 24143 
s 1040
f 23429 24143 28713 
s 1026
f 24297 24144 28690 
s 1040
f 28647 28690 24144 
s 1026
f 26475 24145 28714 
s 1040
f 25108 28714 24145 
s 1026
f 28711 24146 22698 
s 1040
f 24146 28711 25095 
f 25105 28729 24147 
s 1026
f 25090 24147 28729 
f 24148 28694 28651 
s 1040
f 28694 24148 24294 
f 24293 24149 28687 
s 1026
f 25104 28687 24149 
f 22222 28716 24150 
s 1040
f 24301 24150 28716 
f 23421 24151 28725 
s 1026
f 24774 28725 24151 
s 1040
f 28730 24152 23422 
s 1026
f 24152 28730 24773 
f 26491 24153 28715 
f 25099 28715 24153 
f 28691 24154 26512 
s 1040
f 24154 28691 25101 
s 1026
f 24155 28696 25100 
f 28696 24155 26510 
f 26508 24156 28682 
s 1040
f 25102 28682 24156 
s 1026
f 28722 24157 22012 
f 24157 28722 25093 
f 26479 24158 28727 
f 25098 28727 24158 
s 1040
f 28889 24159 24289 
s 1026
f 24159 28889 28870 
f 24123 24160 28950 
s 1040
f 32304 28950 24160 
s 1026
f 28831 24161 24163 
f 24161 28831 26950 
f 28903 24162 24987 
s 1040
f 24162 28903 20819 
f 28923 24163 22386 
s 1026
f 24163 28923 28831 
f 28873 24164 33191 
f 24164 28873 28954 
s 1040
f 26520 24165 28830 
s 1026
f 25152 28830 24165 
s 1040
f 23444 24166 28811 
s 1026
f 28849 28811 24166 
f 28840 28862 24167 
s 1040
f 24172 24167 28862 
s 1026
f 28884 24168 22173 
s 1040
f 24168 28884 28905 
s 1026
f 24170 24169 28847 
s 1040
f 33384 28847 24169 
s 1026
f 28847 28849 24170 
f 24166 24170 28849 
s 1040
f 28936 24171 24177 
s 1026
f 24171 28936 26915 
f 21657 24172 28891 
s 1040
f 28862 28891 24172 
s 1026
f 28925 28922 24173 
s 1040
f 24182 24173 28922 
s 1026
f 24167 24174 28840 
s 1040
f 32966 28840 24174 
f 28802 24175 24181 
s 1026
f 24175 28802 28864 
f 28864 24176 24175 
s 1040
f 24176 28864 33718 
s 1026
f 28954 24177 24164 
s 1040
f 24177 28954 28936 
s 1026
f 20882 24178 28795 
s 1040
f 26210 28795 24178 
s 1026
f 24179 28842 28949 
f 28842 24179 24292 
f 22693 24180 28916 
s 1040
f 28794 28916 24180 
f 24181 28825 28802 
s 1026
f 28825 24181 21649 
f 26509 24182 28793 
s 1040
f 28922 28793 24182 
s 1026
f 28723 24183 23434 
s 1040
f 24183 28723 24795 
f 28874 26887 24265 
s 1026
f 26887 28874 28934 
s 1040
f 28854 28871 24185 
s 1026
f 26472 24185 28871 
f 28952 24186 26507 
s 1040
f 24186 28952 28907 
f 28819 24187 26489 
s 1026
f 24187 28819 28897 
f 24188 28812 26934 
f 28812 24188 24253 
f 26926 28790 24189 
s 1040
f 24257 24189 28790 
s 1026
f 28829 28841 24190 
s 1040
f 24254 24190 28841 
s 1026
f 28799 24191 25088 
s 1040
f 24191 28799 28946 
f 28890 28896 24192 
s 1026
f 26516 24192 28896 
s 1040
f 28804 28820 24193 
s 1026
f 26511 24193 28820 
s 1040
f 28853 24194 21841 
s 1026
f 24194 28853 28837 
s 1040
f 28905 24195 24168 
s 1026
f 24195 28905 28867 
f 22799 24196 28929 
s 1040
f 33658 28929 24196 
s 1026
f 24197 28837 28845 
f 28837 24197 24194 
f 24247 24198 28852 
s 1040
f 28956 28852 24198 
f 28886 24199 24238 
s 1026
f 24199 28886 28844 
s 1040
f 25087 24200 28832 
s 1026
f 28927 28832 24200 
f 28894 24201 23454 
s 1040
f 24201 28894 28876 
s 1026
f 28872 24202 23455 
s 1040
f 24202 28872 28863 
s 1026
f 28826 24203 33108 
f 24203 28826 25133 
s 1040
f 28805 24204 24216 
s 1026
f 24204 28805 26931 
f 24205 28803 28791 
s 1040
f 28803 24205 22755 
f 28821 24206 24252 
s 1026
f 24206 28821 26942 
f 24994 24207 28813 
s 1040
f 20738 28813 24207 
s 1026
f 20869 24208 28798 
s 1040
f 33525 28798 24208 
s 1026
f 28815 24209 24215 
s 1040
f 24209 28815 32911 
f 24246 24210 28814 
s 1026
f 28809 28814 24210 
f 28817 24211 22162 
s 1040
f 24211 28817 28926 
s 1026
f 24992 24212 28833 
s 1040
f 20712 28833 24212 
s 1026
f 28835 24213 24993 
s 1040
f 24213 28835 20845 
f 24251 24214 28828 
s 1026
f 22200 28828 24214 
f 24215 28838 28815 
s 1040
f 28838 24215 24220 
s 1026
f 28843 24216 26487 
s 1040
f 24216 28843 28805 
f 24217 28851 32922 
s 1026
f 28851 24217 24221 
f 23451 24218 28901 
s 1040
f 28899 28901 24218 
f 28858 24219 24223 
s 1026
f 24219 28858 28848 
f 28859 24220 23449 
s 1040
f 24220 28859 28838 
f 28863 24221 24202 
s 1026
f 24221 28863 28851 
s 1040
f 24231 24222 28885 
s 1026
f 28933 28885 24222 
f 28868 24223 23445 
s 1040
f 24223 28868 28858 
f 28876 24224 24201 
s 1026
f 24224 28876 28860 
f 24996 24225 28875 
s 1040
f 20803 28875 24225 
f 33012 28877 24226 
s 1026
f 24227 24226 28877 
f 28877 28888 24227 
s 1040
f 24233 24227 28888 
s 1026
f 28860 24228 24224 
s 1040
f 24228 28860 33181 
f 24218 24229 28899 
s 1026
f 28822 28899 24229 
f 28893 24230 24232 
s 1040
f 24230 28893 33282 
s 1026
f 21590 24231 28818 
s 1040
f 28885 28818 24231 
f 28904 24232 24234 
s 1026
f 24232 28904 28893 
f 23457 24233 28902 
s 1040
f 28888 28902 24233 
s 1026
f 28919 24234 23448 
s 1040
f 24234 28919 28904 
f 28928 24235 24248 
s 1026
f 24235 28928 28906 
s 1040
f 28712 28898 24236 
s 1026
f 23446 24236 28898 
f 28892 24237 24995 
s 1040
f 24237 28892 20910 
f 24238 28944 28886 
s 1026
f 28944 24238 23459 
f 28951 24239 20775 
s 1040
f 24239 28951 26212 
f 24198 24240 28956 
s 1026
f 26954 28956 24240 
f 28913 24241 20762 
s 1040
f 24241 28913 33399 
s 1026
f 23447 24242 28855 
s 1040
f 28839 28855 24242 
f 28926 24243 24211 
s 1026
f 24243 28926 28801 
s 1040
f 32834 28827 24244 
s 1026
f 24250 24244 28827 
s 1040
f 24245 28848 32908 
s 1026
f 28848 24245 24219 
f 22132 24246 28834 
s 1040
f 28814 28834 24246 
s 1026
f 28852 28856 24247 
f 33056 24247 28856 
f 28932 24248 23450 
s 1040
f 24248 28932 28928 
s 1026
f 28906 24249 24235 
s 1040
f 24249 28906 33288 
f 24242 24250 28839 
s 1026
f 28827 28839 24250 
f 23319 24251 28866 
s 1040
f 28828 28866 24251 
s 1026
f 28895 24252 26483 
s 1040
f 24252 28895 28821 
f 28865 24253 26474 
s 1026
f 24253 28865 28812 
s 1040
f 28841 28850 24254 
s 1026
f 26469 24254 28850 
f 28914 28927 24255 
f 24200 24255 28927 
s 1040
f 24192 24256 28890 
s 1026
f 28945 28890 24256 
f 24065 24257 28800 
s 1040
f 28790 28800 24257 
f 28808 24258 24264 
s 1026
f 24258 28808 28924 
s 1040
f 24193 24259 28804 
s 1026
f 28935 28804 24259 
s 1040
f 24185 24260 28854 
s 1026
f 28942 28854 24260 
s 1040
f 28907 24261 24186 
s 1026
f 24261 28907 28940 
f 28897 24262 24187 
f 24262 28897 24764 
f 23497 24263 28816 
s 1040
f 25157 28816 24263 
s 1026
f 28824 24264 23983 
s 1040
f 24264 28824 28808 
s 1026
f 28887 24265 26490 
s 1040
f 24265 28887 28874 
s 1026
f 24276 24266 28823 
f 26952 28823 24266 
f 26449 28976 33512 
s 1040
f 28976 26449 22724 
s 1026
f 26888 28946 28920 
s 1040
f 28946 26888 24191 
s 1026
f 29054 24269 22418 
s 1040
f 24269 29054 28704 
s 1026
f 28939 28900 24270 
s 1040
f 23460 24270 28900 
f 24285 26890 28958 
s 1026
f 26938 28958 26890 
f 24272 29023 26903 
s 1040
f 29023 24272 22900 
f 22899 24273 29050 
s 1026
f 28703 29050 24273 
f 28943 28965 24274 
s 1040
f 22438 24274 28965 
f 28964 24275 22439 
s 1026
f 24275 28964 28941 
f 28823 28957 24276 
s 1040
f 22105 24276 28957 
s 1026
f 29064 24277 26470 
s 1040
f 24277 29064 28726 
s 1026
f 29059 24278 21623 
f 24278 29059 28717 
f 26495 24279 29036 
s 1040
f 28681 29036 24279 
s 1026
f 29032 24280 26497 
s 1040
f 24280 29032 28718 
s 1026
f 26493 24281 28953 
s 1040
f 28931 28953 24281 
f 32940 28861 24282 
s 1026
f 24284 24282 28861 
s 1040
f 23541 24283 28879 
s 1026
f 28921 28879 24283 
s 1040
f 24286 24284 28869 
s 1026
f 28861 28869 24284 
f 23609 24285 28806 
s 1040
f 28958 28806 24285 
s 1026
f 23461 24286 28882 
s 1040
f 28869 28882 24286 
f 24281 24287 28931 
s 1026
f 24762 28931 24287 
f 28918 28921 24288 
f 24283 24288 28921 
f 28937 24289 23980 
s 1040
f 24289 28937 28889 
f 28797 24290 22441 
s 1026
f 24290 28797 28948 
f 29074 24291 32839 
f 24291 29074 22450 
s 1040
f 28881 24292 26519 
s 1026
f 24292 28881 28842 
f 26506 24293 29039 
s 1040
f 28687 29039 24293 
s 1026
f 29044 24294 26484 
s 1040
f 24294 29044 28694 
f 28720 29061 24295 
s 1026
f 22463 24295 29061 
f 28686 29038 24296 
f 33291 24296 29038 
f 28690 29040 24297 
f 21579 24297 29040 
f 26485 24298 29043 
s 1040
f 28728 29043 24298 
s 1026
f 29068 24299 26505 
s 1040
f 24299 29068 28710 
s 1026
f 26482 24300 29051 
s 1040
f 28701 29051 24300 
s 1026
f 24679 24301 29063 
s 1040
f 28716 29063 24301 
s 1026
f 29035 24302 22496 
s 1040
f 24302 29035 28685 
s 1026
f 29047 24303 26476 
f 24303 29047 28700 
f 28732 29028 24304 
f 26515 24304 29028 
f 29046 24305 22396 
s 1040
f 24305 29046 32969 
s 1026
f 29030 24306 33129 
f 24306 29030 25584 
f 25585 29029 24307 
f 33248 24307 29029 
s 1040
f 24308 29444 33421 
s 1026
f 29444 24308 21980 
f 29304 24309 27889 
s 1040
f 24309 29304 32638 
f 29533 24310 25699 
s 1026
f 24310 29533 32516 
s off
f 31620 21321 24311 
f 29984 29771 21321 
f 21320 29772 29983 
f 31621 24312 21320 
s 1026
f 33201 28129 26138 
f 27625 26138 28129 
f 25400 24314 27862 
s 1040
f 32556 27862 24314 
s off
f 22569 30252 24315 
f 21448 24315 31904 
f 24316 30253 22570 
f 31903 24316 21449 
s 1026
f 28093 28988 26977 
s 1040
f 28988 28093 28508 
s 1026
f 28089 28986 21666 
s 1040
f 28986 28089 28512 
s 1026
f 28992 28097 28513 
f 28097 28992 21762 
f 28983 28088 28503 
f 28088 28983 22519 
f 27802 33622 33493 
s 1040
f 27815 27802 24321 
s 1026
f 32791 32823 27640 
f 24322 27640 27656 
s off
f 21235 29885 30044 
f 20970 29885 21235 
f 30043 29884 21234 
f 20971 21234 29884 
f 24961 24325 21231 
f 24325 31758 31043 
f 21232 24326 24978 
f 31042 31757 24326 
f 24958 24327 21145 
f 31193 21145 24327 
f 21146 24328 24979 
f 24328 21146 31192 
f 29886 24329 21421 
f 21558 21421 24329 
f 24330 21422 21559 
f 21422 24330 29887 
f 21370 24331 29888 
f 24331 21370 21565 
f 21566 21425 24332 
f 29889 24332 21425 
f 29890 24333 21529 
f 21203 21529 24333 
f 21530 24334 29891 
f 24334 21530 21170 
f 29916 24335 21527 
f 21199 21527 24335 
f 21528 24336 29917 
f 24336 21528 21228 
f 29987 24337 21438 
f 21768 21438 24337 
f 21439 24338 29986 
f 24338 21439 21767 
f 29998 24339 21402 
f 21770 21402 24339 
f 21403 24340 29997 
f 24340 21403 21769 
f 29999 24341 21518 
f 21383 21518 24341 
f 24342 21519 21191 
f 21519 24342 30000 
f 30048 24343 21510 
f 21155 21510 24343 
f 21511 24344 30047 
f 24344 21511 21387 
f 29632 24345 21467 
f 21116 21467 24345 
f 21464 24346 29631 
f 24346 21464 21444 
f 21110 24347 21357 
f 31885 21357 24347 
f 30936 24348 21441 
f 21195 21441 24348 
f 31896 24349 21342 
f 21390 21342 24349 
f 31886 21354 24350 
f 23338 24350 21354 
f 24351 21359 24977 
f 21359 24351 31888 
f 24352 21111 26896 
f 21111 24352 21356 
f 24963 21358 24353 
f 31891 24353 21358 
f 24354 21356 31887 
f 21356 24354 21111 
f 21355 24355 23343 
f 24355 21355 31889 
f 21353 24356 23344 
f 24356 21353 31890 
f 23337 21352 24357 
f 31892 24357 21352 
f 21344 24358 31895 
f 24358 21344 21391 
f 24359 21440 21194 
f 21440 24359 30937 
f 26894 21110 24360 
f 21357 24360 21110 
f 21103 24361 24956 
f 31897 24361 21103 
f 24955 24362 21105 
f 21105 24362 31898 
s 8194
f 28075 29915 29621 
s 4100
f 29790 26076 28041 
f 28040 26077 29789 
s 8194
f 29620 29914 28074 
s off
f 31757 21448 31904 
f 30232 30404 24365 
f 31903 21449 31758 
f 24366 30405 30231 
f 28636 24367 21955 
s 8
f 21131 21955 24367 
s 1040
f 32303 33125 25615 
s 1026
f 26195 24368 24412 
f 26192 24369 24420 
f 32289 32685 25819 
s 1040
f 25215 32877 32868 
s 1026
f 23909 24370 26205 
s 1040
f 32314 33504 25612 
f 26203 24371 23918 
f 33779 33790 25879 
s 1026
f 26206 24372 23904 
f 27074 33083 33079 
f 29564 26202 24373 
s off
f 31249 31724 21906 
f 23704 28583 28561 
s 1040
f 25610 29504 27053 
s 1026
f 27067 27053 29504 
f 24376 29190 25849 
s 1040
f 29190 24376 33126 
f 25667 29192 24377 
s 1026
f 27075 24377 29192 
f 23558 24378 29184 
s 1040
f 25673 29184 24378 
f 24379 29188 25670 
s 1026
f 29188 24379 27100 
s 1040
f 24380 29196 25662 
s 1026
f 29196 24380 27085 
f 25665 29194 24381 
s 1040
f 33019 24381 29194 
f 29189 24382 25286 
s 1026
f 24382 29189 25655 
f 25648 29201 24383 
s 1040
f 27079 24383 29201 
f 24384 29197 25654 
s 1026
f 29197 24384 21719 
s 1040
f 25656 29185 24385 
s 1026
f 21715 24385 29185 
s 1040
f 25848 29193 24386 
s 1026
f 27081 24386 29193 
s 1040
f 24387 29205 25852 
s 1026
f 29205 24387 23556 
s 1040
f 24388 29186 25605 
s 1026
f 29186 24388 33530 
s 1040
f 27480 29264 29559 
s 1026
f 29264 27480 24920 
s 1040
f 29512 29268 27481 
s 1026
f 24921 27481 29268 
s 1040
f 27052 29503 25716 
s 1026
f 29503 27052 25285 
s 1040
f 27054 29499 25732 
s 1026
f 29499 27054 21779 
f 24398 27051 29501 
s 1040
f 25763 29501 27051 
s 1026
f 29311 24394 27124 
s 1040
f 24394 29311 29494 
f 29495 29312 24395 
s 1026
f 27123 24395 29312 
s 1040
f 29493 29310 24396 
s 1026
f 23853 24396 29310 
s 1040
f 24397 29313 29496 
s 1026
f 29313 24397 23151 
f 29501 29305 24398 
s 1040
f 32789 24398 29305 
s 1026
f 24649 29493 24399 
s 1040
f 24396 24399 29493 
s 1026
f 24400 29496 25743 
s 1040
f 29496 24400 24397 
s 1026
f 29314 29495 24401 
s 1040
f 24395 24401 29495 
s 1026
f 24402 29494 29332 
s 1040
f 29494 24402 24394 
s 1026
f 27056 29509 25026 
f 29509 27056 27472 
f 25278 24404 29502 
f 25726 29502 24404 
f 27066 29513 25686 
f 29513 27066 27483 
f 27064 29514 24845 
s 1040
f 29514 27064 27482 
s 1026
f 24407 29500 25876 
f 29500 24407 27068 
f 24408 29506 25769 
s 1040
f 29506 24408 23829 
s 1026
f 25214 29505 24409 
s 1040
f 24418 24409 29505 
s 1026
f 27061 29511 23227 
s 1040
f 29511 27061 27476 
f 24411 29520 25641 
s 1026
f 29520 24411 33458 
f 24412 29199 26195 
s 1040
f 29199 24412 23936 
s 1026
f 25060 29556 27058 
s 1040
f 27473 27058 29556 
s 1026
f 25058 29554 27055 
f 27468 27055 29554 
f 25059 29557 27060 
f 27475 27060 29557 
f 24846 29555 27057 
s 1040
f 27469 27057 29555 
s 1026
f 24660 29558 27063 
s 1040
f 27479 27063 29558 
f 29505 29564 24418 
s 1026
f 24373 24418 29564 
f 29198 29576 24419 
f 23931 24419 29576 
f 24420 29579 26192 
f 29579 24420 23808 
f 25629 29573 24421 
f 27126 24421 29573 
f 29265 29570 24422 
s 1040
f 32340 24422 29570 
s 8208
f 29669 29617 24423 
s 8194
f 20733 24423 29617 
s 8208
f 24424 29616 29668 
s 8194
f 29616 24424 20840 
s 1026
f 29277 27930 27549 
s 1040
f 27930 29277 32042 
s 1026
f 20875 24426 29461 
s 1040
f 33514 29461 24426 
s 1026
f 29473 24427 22117 
s 1040
f 24427 29473 32762 
s 1026
f 29465 24428 20768 
s 1040
f 24428 29465 33521 
s 1026
f 29454 24429 22401 
s 1040
f 24429 29454 33087 
s 1026
f 25553 24430 27808 
s 1040
f 33470 27808 24430 
s 1026
f 29066 26907 21673 
s 1040
f 22594 33121 33111 
s 1026
f 23048 26909 29052 
s 1040
f 32969 32971 24305 
f 24174 32981 32966 
s 1026
f 28891 26944 21657 
s 1040
f 33718 33043 24176 
s 1026
f 21649 26940 28825 
f 33325 33332 22410 
f 21623 24435 29059 
f 22039 24436 22867 
s 1040
f 32817 32337 23606 
s 1026
f 25140 24437 21951 
s 1040
f 23607 32381 33025 
s 1026
f 22698 26968 28711 
s 1040
f 33613 32993 26448 
s 1026
f 22431 21627 24439 
f 29038 33275 33291 
f 24440 22655 22895 
f 33129 33122 29030 
f 22894 21640 24441 
f 29029 33239 33248 
f 33769 32710 27034 
s 1040
f 33768 32271 29111 
s off
f 31728 30190 31642 
f 21158 30190 24443 
f 24444 30187 21197 
f 31727 31630 30187 
f 31725 31633 30188 
f 24445 30188 22218 
f 22219 30189 24446 
f 31726 30189 31645 
f 21067 28610 24447 
f 24447 28610 28582 
s 1026
f 27599 33213 33217 
f 28154 24448 23053 
f 33114 33309 27675 
f 26141 22007 25485 
s 1040
f 27953 26122 23052 
f 25437 33405 33402 
s 1026
f 26117 23051 25490 
f 33109 33054 27728 
f 32977 32962 27644 
f 26112 23058 25484 
s 2147483647
f 24453 22002 23033 
s 8208
f 29668 30486 24424 
f 24423 30487 29669 
s 2147483647
f 24454 23032 22004 
s 1026
f 24547 26146 26291 
s 1040
f 28518 32983 32095 
s off
f 31411 30944 32334 
f 32044 30943 31410 
s 1026
f 28361 28212 24458 
f 32612 24458 28212 
s 1040
f 24459 26193 29269 
s 1026
f 26193 24459 33411 
s 1040
f 33460 33760 25224 
f 33799 26267 21811 
s 2147483647
f 29725 26362 29659 
s 1026
f 26362 29725 23767 
s 2147483647
f 29720 29654 26363 
s 1026
f 27093 29720 26363 
s 2147483647
f 29717 26364 29651 
s 1026
f 26364 29717 24112 
s 2147483647
f 29721 26360 29655 
s 1026
f 26360 29721 25306 
s 2147483647
f 29728 29662 26361 
s 1026
f 23665 29728 26361 
s 2147483647
f 29719 26368 29653 
s 1026
f 26368 29719 23848 
f 23843 29722 26367 
s 2147483647
f 29722 29656 26367 
s 1026
f 23866 29724 26365 
s 2147483647
f 29724 29658 26365 
s 1026
f 23841 29718 26369 
s 2147483647
f 29718 29652 26369 
s 1026
f 26366 29726 23876 
s 2147483647
f 29726 26366 29660 
f 29715 26373 29663 
s 1026
f 26373 29715 27094 
s 2147483647
f 29716 29650 26372 
s 1026
f 27088 29716 26372 
s 2147483647
f 29723 29657 26371 
s 1026
f 25273 29723 26371 
s 2147483647
f 29727 26370 29661 
s 1026
f 26370 29727 25274 
s off
f 24475 30193 21263 
f 30193 24475 33822 
f 21262 30197 24476 
f 33817 24476 30197 
s 1026
f 33300 32514 28188 
f 26344 21631 22467 
f 26331 21704 22904 
f 33179 33172 28164 
s 1040
f 22641 33383 33382 
f 28185 26306 21697 
f 32957 32261 21813 
s 1026
f 21812 24480 33729 
s 1040
f 22224 33513 33511 
f 25517 26341 21819 
s 1026
f 22823 21701 26351 
f 28245 32646 33028 
s 1040
f 22398 33524 33523 
f 21960 26348 21959 
f 32814 32836 25129 
s 1026
f 21900 26345 28414 
f 28365 26342 21994 
s 1040
f 21997 33007 32997 
s 1026
f 33193 33192 28165 
f 26336 21946 25510 
s 1040
f 33374 33377 22587 
f 22107 26332 22109 
f 33139 33084 22845 
s 1026
f 21934 26330 28348 
s 1040
f 33356 33381 22399 
f 21956 26326 21958 
s 1026
f 22043 26322 28297 
s 1040
f 32634 32918 22612 
s 1026
f 21930 26320 28271 
s 1040
f 32893 32901 22861 
f 33270 33277 21780 
s 1026
f 22125 26307 28416 
f 21962 26305 28392 
s 1040
f 33242 33262 22400 
f 25130 32909 32614 
s 1026
f 28263 26308 22069 
s 1040
f 22520 32848 32844 
s 1026
f 28260 26303 21807 
f 28267 26302 21938 
s 1040
f 22853 32828 32825 
f 28671 33162 33147 
s 1026
f 28395 26299 21942 
f 22047 26295 28293 
s 1040
f 32898 32904 22610 
s 1026
f 28315 33268 33274 
f 22800 22166 26325 
f 21708 26324 28387 
s 1040
f 33295 33308 29010 
s 1026
f 28325 26315 22051 
s 1040
f 29003 32942 32938 
s 1026
f 28319 26312 21926 
s 1040
f 22783 32935 32928 
s 1026
f 24266 22087 26952 
s 1040
f 22087 33644 32742 
s 1026
f 22162 26955 28817 
s 1040
f 32616 32777 26504 
s 1026
f 25134 26922 22136 
s 1040
f 26501 33006 32988 
s 1026
f 26942 21753 24206 
f 33035 33015 28878 
f 26931 21744 24204 
f 33195 33177 28883 
s 1040
f 32914 32951 26499 
s 1026
f 22173 26953 28884 
f 28834 26949 22132 
s 1040
f 26471 32788 32786 
s 1026
f 33567 21887 26927 
s 1040
f 26211 26927 21887 
s 1026
f 33732 33154 26943 
f 26925 21815 24990 
f 22877 26972 21914 
f 32905 21914 26972 
s 1040
f 26467 33227 33165 
s 1026
f 28719 26971 23104 
f 25258 33128 33190 
f 25222 24514 26132 
f 26110 24515 25487 
f 32283 33081 27596 
s 1040
f 32166 32090 28488 
s 1026
f 24516 26149 27529 
f 26104 24517 24598 
f 32530 33584 27733 
s 1040
f 32266 32064 28472 
s 1026
f 24518 26150 27526 
f 21850 26521 24118 
s 1040
f 33080 33580 26521 
s 1026
f 21724 26450 22723 
s 1040
f 33078 32738 26450 
f 33774 28914 21757 
s 1026
f 24255 21757 28914 
s off
f 30191 24522 21193 
f 24522 30191 22577 
f 24523 30210 21378 
f 30210 24523 21466 
f 33507 24524 30198 
f 21264 30198 24524 
f 22485 30211 24525 
f 33480 24525 30211 
s 1040
f 32792 32784 27512 
s 1026
f 24526 26913 33686 
s off
f 22236 30200 24527 
f 30679 24527 30200 
f 24528 30205 22237 
f 30205 24528 30700 
f 21104 24529 30203 
f 21377 30203 24529 
f 24530 30194 21376 
f 30194 24530 21102 
f 24543 24531 30209 
f 22238 30209 24531 
f 24532 30212 22240 
f 30212 24532 30661 
f 22239 30207 24533 
f 21495 24533 30207 
f 30208 24534 21504 
f 24534 30208 22241 
f 24535 30552 29962 
f 30552 30590 22499 
s 1026
f 33785 28066 21908 
s 1040
f 21908 28066 32807 
f 23137 33654 33482 
f 33048 33259 24537 
s off
f 24538 30649 30583 
f 30649 24538 30682 
f 30702 24539 22638 
f 24539 30702 30577 
f 30667 24540 30656 
f 30564 30656 24540 
f 30412 30674 24541 
f 24541 30674 26236 
f 29963 24542 30704 
f 30609 30704 24542 
f 24543 30651 24531 
f 26223 30651 24543 
f 21164 24544 30715 
f 30621 30715 24544 
f 31047 30956 24545 
f 20970 24545 30956 
f 24546 30955 31044 
f 30955 24546 20971 
s 1026
f 26291 28480 24547 
s 1040
f 24562 24547 28480 
s 1026
f 24548 28475 26289 
s 1040
f 28475 24548 24560 
s 1026
f 28489 28108 24549 
s 1040
f 23466 24549 28108 
s 1026
f 24550 28101 24759 
f 28101 24550 27632 
f 24551 28104 26355 
s 1040
f 28104 24551 23467 
f 27601 24552 28103 
s 1026
f 24746 28103 24552 
f 24553 28099 24750 
s 1040
f 28099 24553 27859 
s 1026
f 24554 28098 24753 
s 1040
f 28098 24554 27864 
s 1026
f 24748 28100 24555 
s 1040
f 27659 24555 28100 
s 1026
f 24752 28111 24556 
s 1040
f 27824 24556 28111 
s 1026
f 24557 28107 24747 
f 28107 24557 27771 
f 24558 28106 24745 
s 1040
f 28106 24558 27720 
s 1026
f 24749 28110 24559 
f 27752 24559 28110 
s 1040
f 24560 28112 28475 
s 1026
f 28112 24560 24425 
f 24561 28109 24756 
s 1040
f 28109 24561 27826 
f 28480 28113 24562 
s 1026
f 25910 24562 28113 
s 1040
f 27795 24563 28105 
s 1026
f 24755 28105 24563 
f 24751 28102 24564 
f 27713 24564 28102 
f 24565 28517 25578 
s 1040
f 28517 24565 27834 
s 1026
f 25579 28516 24566 
s 1040
f 27868 24566 28516 
f 21712 26296 23618 
f 33361 33646 28776 
s 1026
f 28450 26294 22180 
s 1040
f 28775 33315 33302 
s 1026
f 26321 21607 23239 
f 33686 32332 24526 
s 1040
f 21821 26517 32305 
s 1026
f 26517 21821 25151 
s 1040
f 33105 22222 26488 
s 1026
f 24150 26488 22222 
f 21825 26519 32295 
s 1040
f 26519 21825 28881 
s 1026
f 21883 26518 32293 
f 26518 21883 25160 
f 24214 26503 22200 
s 1040
f 26503 32869 32728 
s 1026
f 33032 26509 21647 
f 28793 21647 26509 
s 1040
f 32489 26515 21773 
s 1026
f 29028 21773 26515 
f 32296 26520 21833 
s 1040
f 28830 21833 26520 
s 1026
f 21837 26484 32360 
f 26484 21837 29044 
f 32839 33791 29074 
f 24579 22093 22778 
f 33512 33355 26449 
f 22012 24580 28722 
f 28916 24581 22693 
s 1040
f 24160 33137 32304 
s 1026
f 22592 33349 33347 
f 29040 24582 21579 
s off
f 24583 30620 30711 
f 21268 24583 29938 
f 30693 30579 24584 
f 29939 24584 20962 
f 24585 30638 29982 
f 26278 21420 24585 
f 24586 21351 26279 
f 29981 30636 24586 
f 26276 21663 24587 
f 24587 30617 23047 
f 24588 21992 26277 
f 26256 30611 24588 
f 29980 30628 24589 
f 24589 21557 26274 
f 24590 30626 26253 
f 26275 21993 24590 
s 1026
f 28278 22025 26902 
f 25097 32675 33376 
f 28231 22334 26959 
s 1040
f 28657 32438 32437 
f 24643 33040 32713 
s 1026
f 32060 24593 21608 
s 1040
f 25682 27004 23476 
s 1026
f 32522 23476 27004 
f 27621 27833 24595 
s 1040
f 25385 24595 27833 
f 27751 24596 25404 
s 1026
f 24596 27751 27648 
s 1040
f 27690 24597 25396 
s 1026
f 24597 27690 26140 
s 1040
f 27684 24598 25549 
s 1026
f 24598 27684 26104 
f 27828 24599 25403 
s 1040
f 24599 27828 27708 
f 24600 27920 24863 
s 1026
f 27920 24600 24320 
s 1040
f 25407 24601 27687 
s 1026
f 26107 27687 24601 
s 1040
f 25402 24602 27670 
s 1026
f 27850 27670 24602 
f 25408 24603 27780 
s 1040
f 27830 27780 24603 
s 1026
f 27759 24604 28152 
s 1040
f 24604 27759 27605 
s 1026
f 23642 24605 27923 
s 1040
f 24867 27923 24605 
f 27921 24606 24616 
s 1026
f 24606 27921 25882 
f 25883 27925 24607 
s 1040
f 23640 24607 27925 
f 23641 24608 27919 
s 1026
f 24913 27919 24608 
f 23643 24609 27918 
s 1040
f 24866 27918 24609 
f 24610 27929 25002 
s 1026
f 27929 24610 24319 
s 1040
f 23644 24611 27922 
s 1026
f 24901 27922 24611 
s 1040
f 27928 24612 24318 
s 1026
f 24612 27928 24693 
s 1040
f 27926 24613 24317 
s 1026
f 24613 27926 25811 
f 27811 24614 25504 
s 1040
f 24614 27811 33649 
s 1026
f 23115 24615 27532 
s 1040
f 27927 27532 24615 
s 1026
f 27526 24616 24518 
s 1040
f 24616 27526 27921 
s 1026
f 27680 24617 28469 
s 1040
f 24617 27680 33358 
f 24618 27533 27924 
s 1026
f 27533 24618 28128 
s 1040
f 24625 24619 27801 
s 1026
f 25262 27801 24619 
s 1040
f 27765 27792 24620 
s 1026
f 28142 24620 27792 
s 1040
f 27669 24621 24623 
s 1026
f 24621 27669 27651 
f 27609 24622 24624 
s 1040
f 24622 27609 27777 
s 1026
f 27688 24623 25161 
s 1040
f 24623 27688 27669 
s 1026
f 24624 27734 27609 
f 27734 24624 33289 
s 1040
f 27801 27681 24625 
s 1026
f 25162 24625 27681 
f 24601 28139 26107 
s 1040
f 28139 33568 33681 
s 1026
f 32873 28142 26120 
f 27792 26120 28142 
s 516
f 33044 29111 21619 
s 544
f 21619 29111 32271 
s off
f 21574 29808 21470 
f 29811 29808 21574 
f 29813 29815 21124 
f 29800 29815 29805 
f 20985 29810 24633 
f 29799 29810 29807 
f 24632 21480 27979 
f 21480 29818 29816 
f 21225 29802 21752 
f 21752 29802 24631 
f 22140 29813 21181 
f 29815 29813 22140 
f 21130 29831 29860 
f 28632 29831 29837 
f 29859 29830 21129 
f 29836 29830 28631 
f 24637 21063 23303 
f 21063 29833 29858 
f 29857 29832 21046 
f 23304 21046 24638 
f 31040 22544 29859 
f 29830 29859 22544 
f 29860 22543 31041 
f 22543 29860 29831 
f 22523 29847 29821 
f 21181 29847 22523 
f 29820 29846 22522 
f 22522 29846 21225 
s 1026
f 21606 26082 33725 
s 1040
f 33403 33110 24851 
s off
f 29639 29806 22209 
f 33436 29806 29807 
s 1040
f 33321 33323 23725 
f 21991 27014 29459 
f 21927 32907 32897 
s 1026
f 29417 27027 21928 
f 32693 32694 25609 
f 24647 21624 29604 
f 22167 22034 27013 
f 29350 33634 33241 
s 1040
f 22139 32930 32916 
s 1026
f 22137 27026 22138 
s 1040
f 33034 33047 21937 
s 1026
f 21935 27023 29429 
s 1040
f 23724 32980 32972 
s 1026
f 29432 27031 21996 
f 29382 27032 22201 
s 1040
f 25725 32812 32804 
f 22135 32767 32763 
s 1026
f 29367 27030 22134 
s 1040
f 21939 32811 32808 
s 1026
f 29387 27029 21940 
s 1040
f 22108 27010 29873 
f 33365 33366 20759 
f 32870 32874 21933 
s 1026
f 21931 27015 29390 
f 27021 21947 27519 
f 33173 33169 29340 
f 29443 27022 21944 
s 1040
f 25691 33057 33045 
s 1026
f 21725 24659 21726 
f 33314 33306 29591 
s 1040
f 27934 32066 32097 
s 1026
f 21952 27050 21953 
f 25693 24661 21629 
f 29597 33101 33200 
s 1040
f 21976 33469 33466 
f 29418 27018 21977 
f 33000 33013 22170 
s 1026
f 22169 27024 29439 
s 1040
f 33138 33143 25859 
s 1026
f 24664 22075 29610 
s 1040
f 32842 32845 25860 
s 1026
f 24665 21736 29601 
f 21632 24666 25857 
f 33271 33260 29593 
f 23248 22018 28380 
s 1040
f 33770 28380 22018 
s 1026
f 28404 21867 22804 
s 1040
f 21867 28404 33472 
s 1026
f 28424 32705 32747 
f 28200 28424 22035 
f 22893 21786 28445 
s 1040
f 32416 28445 21786 
s 1026
f 28092 28081 22203 
s 1040
f 28081 32115 32233 
s 1026
f 23092 24672 23629 
f 32430 32749 27627 
f 28161 21895 23238 
s 1040
f 21895 28161 32335 
f 32133 32113 28078 
s 1026
f 22519 28078 28088 
f 28253 28777 33075 
f 28777 28253 28213 
s 1040
f 32275 32934 26478 
s 1026
f 24676 22210 22412 
s 1040
f 24125 24677 22402 
f 21773 32487 32489 
s 1026
f 25365 33240 33261 
f 24122 24678 21683 
s 1040
f 26488 32586 33105 
s 1026
f 29063 22081 24679 
f 29075 33156 33161 
f 22722 24680 23057 
f 29034 33684 33581 
f 22766 24681 22404 
f 22188 24682 22430 
f 33266 33228 29058 
f 28648 23041 24121 
s 1040
f 23041 28648 33621 
f 24144 22592 28647 
s 1026
f 33347 28647 22592 
f 24999 23137 28909 
s 1040
f 33482 28909 23137 
s 1026
f 24988 21876 28910 
s 1040
f 33424 28910 21876 
s 1026
f 24997 22230 28911 
s 1040
f 33372 28911 22230 
s 1026
f 28908 21855 24989 
s 1040
f 21855 28908 33816 
s 1026
f 28924 23062 24258 
s 1040
f 23062 28924 32815 
s off
f 31884 31883 24690 
f 21570 24690 32041 
s 1026
f 24691 21733 28131 
f 33060 33051 27841 
f 27593 24692 23054 
f 25074 32524 32523 
f 24693 21665 24612 
f 33787 32794 27806 
s 1040
f 25552 33061 33052 
s 1026
f 27812 24694 21918 
s 1040
f 33425 33636 26218 
s 1026
f 24695 21907 33680 
s off
f 21637 29844 21569 
f 29822 29844 21637 
f 21568 29845 21638 
f 21638 29845 29823 
s 1026
f 21857 24698 25606 
f 32298 32887 23708 
f 21732 24699 25644 
f 32253 33024 23707 
f 29203 33341 33340 
f 23925 21581 24700 
s off
f 30923 24701 31572 
f 24701 30923 26275 
f 31573 24702 30922 
f 26274 30922 24702 
s 1026
f 27094 21615 26373 
s 1040
f 21615 27094 33753 
s 1026
f 25805 21818 27089 
s 1040
f 33657 27089 21818 
s 1026
f 29192 22052 27075 
s 1040
f 33704 27075 22052 
s 1026
f 32978 21675 27091 
f 25307 27091 21675 
s off
f 24707 30928 26278 
f 30928 24707 31577 
f 31576 24708 30929 
f 26279 30929 24708 
f 26277 30926 24709 
f 31575 24709 30926 
f 30935 24710 31588 
f 24710 30935 26280 
f 21672 30934 24711 
f 31589 24711 30934 
s 1026
f 32585 28136 24712 
f 27861 24712 28136 
s off
f 30030 22156 21002 
f 22156 30030 21330 
f 21329 30033 22159 
f 20965 22159 30033 
f 22564 30034 21323 
f 30034 22564 20934 
f 30031 22158 20966 
f 22158 30031 21324 
f 21328 30029 22157 
f 21003 22157 30029 
f 21325 30032 22565 
f 20932 22565 30032 
f 22657 30028 21326 
f 30028 22657 30038 
f 30035 22656 30027 
f 21327 30027 22656 
f 30231 22552 33445 
f 22552 30231 30405 
f 33518 22551 30232 
f 30404 30232 22551 
s 1026
f 28411 22077 22788 
s 1040
f 22077 28411 33157 
s 1026
f 28397 22383 22791 
s 1040
f 22383 28397 33176 
s 1026
f 22759 22263 28436 
s 1040
f 32507 28436 22263 
s 1026
f 28432 22255 22758 
s 1040
f 22255 28432 32578 
s 1026
f 22763 22299 28437 
s 1040
f 32376 28437 22299 
f 28190 22259 22761 
s 1026
f 22259 28190 32386 
s 1040
f 28079 32101 32208 
s 1026
f 28086 28079 21670 
s 1040
f 32201 32092 28080 
s 1026
f 21762 28080 28097 
f 22870 22311 28429 
s 1040
f 32442 28429 22311 
f 32538 28435 22307 
s 1026
f 22871 22307 28435 
f 22451 22315 28438 
s 1040
f 32510 28438 22315 
s 1026
f 28433 22319 22808 
s 1040
f 22319 28433 32425 
s 1026
f 28347 22548 22452 
s 1040
f 22548 28347 32497 
f 32459 28229 22323 
s 1026
f 22807 22323 28229 
f 22837 21591 28391 
s 1040
f 33021 28391 21591 
s 1026
f 28233 22059 22435 
s 1040
f 22059 28233 32641 
s 1026
f 28401 22123 22885 
s 1040
f 22123 28401 32611 
s 1026
f 28680 21750 28428 
s 1040
f 32606 28428 21750 
s 1026
f 28447 21720 28678 
s 1040
f 21720 28447 32426 
s 1026
f 28656 21717 28444 
s 1040
f 32359 28444 21717 
s 1026
f 22063 28442 33328 
s 1040
f 28442 22063 28665 
f 28676 22327 28326 
s 1026
f 32600 28326 22327 
f 22279 28501 22282 
f 32178 32247 28501 
f 28500 32243 32184 
f 22288 28500 22287 
s 1040
f 32204 32168 28495 
s 1026
f 22267 28495 22270 
f 28497 32265 32157 
f 22274 28497 22271 
s 1040
f 28492 32218 32199 
s 1026
f 22286 28492 22283 
f 22275 28494 22278 
f 32142 32264 28494 
s 1040
f 28499 32121 32235 
s 1026
f 22306 28499 22303 
f 22294 28493 22291 
f 28493 32255 32138 
f 32242 32226 28498 
f 22295 28498 22298 
f 28457 28451 21910 
f 33100 21910 28451 
f 28496 32171 32227 
f 22248 28496 22247 
f 22243 28490 22246 
f 32050 32070 28490 
f 28458 32254 32074 
f 28087 28458 22145 
f 28415 22526 22884 
s 1040
f 22526 28415 33145 
f 32207 32158 28491 
s 1026
f 22251 28491 22254 
s 1040
f 32174 22143 29078 
s 1026
f 25575 29078 22143 
f 22776 21901 24761 
f 29072 33782 32770 
f 28917 33141 33149 
f 24287 21969 24762 
s 1040
f 33643 33343 26486 
f 21841 24763 28853 
s 1026
f 24764 22490 24262 
f 33606 32697 28915 
f 22194 24765 28692 
s 1040
f 32805 32806 26522 
f 33069 28703 21792 
s 1026
f 24273 21792 28703 
f 28796 32742 33644 
s 1040
f 28957 28796 22105 
s 1026
f 22433 21829 24768 
s 1040
f 33798 24768 21829 
f 32331 32736 25131 
s 1026
f 21862 24769 28195 
f 22245 28734 22727 
s 1040
f 32070 32050 28734 
s 1026
f 22076 29013 32996 
f 29013 22076 28390 
f 22762 28754 22250 
s 1040
f 28754 32227 32171 
s 1026
f 22297 28750 22764 
s 1040
f 32226 32242 28750 
s 1026
f 22765 28751 22292 
s 1040
f 28751 32138 32255 
f 22780 28749 22304 
s 1026
f 28749 32235 32121 
s 1040
f 22269 28756 22747 
s 1026
f 32168 32204 28756 
f 22277 28748 22743 
s 1040
f 32264 32142 28748 
s 1026
f 22739 28746 22272 
s 1040
f 28746 32157 32265 
s 1026
f 22281 28752 22742 
s 1040
f 32247 32178 28752 
s 1026
f 22737 28753 22290 
s 1040
f 28753 32184 32243 
f 22738 28757 22284 
s 1026
f 28757 32199 32218 
f 22061 28782 32629 
f 28782 22061 28330 
f 28358 23320 28778 
f 32714 28778 23320 
f 32531 29016 22300 
f 28322 22300 29016 
s 1040
f 22262 29017 32449 
s 1026
f 29017 22262 28286 
f 32471 29020 22264 
f 28252 22264 29020 
f 22258 29018 32480 
f 29018 22258 28349 
f 22321 28787 32479 
f 28787 22321 28434 
f 32445 28770 22326 
f 28295 22326 28770 
f 32434 28783 22318 
f 28467 22318 28783 
f 23300 28786 32474 
f 28786 23300 28466 
f 32591 28785 22314 
f 28207 22314 28785 
f 32399 28784 22310 
f 28359 22310 28784 
f 21860 29005 33067 
f 29005 21860 28209 
s 1040
f 22253 28755 22730 
s 1026
f 32158 32207 28755 
f 28192 21718 28972 
f 32352 28972 21718 
f 21721 28971 32379 
f 28971 21721 28210 
s 1040
f 32385 28973 22328 
s 1026
f 28427 22328 28973 
s 1040
f 22065 28970 32427 
s 1026
f 28970 22065 28255 
f 21880 24800 28096 
s 1040
f 32769 32783 28999 
s 1026
f 28369 24801 21847 
f 29009 33438 33750 
f 22168 24802 28405 
s 1040
f 33145 33189 22526 
f 28318 24803 21975 
f 25127 33471 32312 
f 33324 33031 29607 
s 1026
f 22013 29607 29240 
f 29530 22030 25694 
s 1040
f 22030 29530 32892 
s 1026
f 22019 22020 29452 
s 1040
f 33766 29452 22020 
s 1026
f 33312 29581 23135 
f 23923 23135 29581 
f 24808 21703 25866 
f 33151 33144 27110 
f 23580 24809 23250 
s 1040
f 29574 33014 33004 
s 1026
f 24810 22006 25787 
f 33265 33252 27095 
f 25728 21699 24811 
f 27082 32976 32982 
f 33016 33010 27071 
f 24812 21745 25793 
s 1040
f 25741 33388 33385 
f 23257 24813 21888 
s 1026
f 21707 24814 23795 
s 1040
f 33203 33215 29575 
s 1026
f 23797 24815 21912 
s 1040
f 29519 33249 33230 
s 1026
f 23227 24816 27061 
s 1040
f 32871 32876 29572 
f 32336 32440 29567 
s 1026
f 23226 24817 27059 
s 1040
f 33345 33346 25625 
f 21711 24818 23763 
f 25627 33369 33367 
f 23819 24819 21695 
s 1026
f 22395 23225 24820 
f 27091 32959 32978 
f 33703 29665 21813 
s 1040
f 21813 29665 32957 
s off
f 30655 30572 24822 
f 23122 30629 30572 
f 24823 30643 30721 
f 30643 30583 21367 
f 30689 30576 24824 
f 30613 30563 30576 
f 30570 30631 23123 
f 24825 30570 30662 
f 30573 30630 21563 
f 24826 30573 30670 
f 30604 30564 30642 
f 30722 30642 24827 
f 24828 30644 30725 
f 30639 21564 24828 
f 30726 30641 24829 
f 24829 21054 30640 
f 24836 30575 24830 
f 24830 21920 30605 
f 24831 30634 30718 
f 21268 29938 30577 
f 24832 30596 29975 
f 30624 21482 24832 
s 1040
f 32960 32961 25577 
s 1026
f 26355 24833 24551 
s off
f 30565 30663 24834 
f 22720 24834 30663 
f 24835 30664 30582 
f 30664 24835 22985 
f 24830 30667 24836 
f 33817 24836 30667 
f 24837 30708 30578 
f 30708 24837 33822 
f 30672 24838 29962 
f 24838 30672 30600 
f 30606 30703 24839 
f 33817 24839 30703 
f 29937 30675 24840 
f 24840 30675 31522 
f 24841 30694 30581 
f 30694 24841 21102 
f 30697 24842 21102 
f 24842 30697 26237 
f 31540 30669 24843 
f 24843 30669 29940 
s 1040
f 27566 33814 33746 
f 33799 33457 21903 
f 33208 33808 27562 
s 1026
f 23356 27040 21667 
s 1040
f 27937 32162 32248 
s 1026
f 22146 27049 22098 
f 24847 23107 25336 
f 32509 33171 27838 
f 32281 32967 27832 
f 24848 21858 25337 
f 26174 24849 23113 
f 26396 32880 33701 
f 23099 24850 26175 
f 33727 33058 28068 
f 33687 29712 23061 
s 1040
f 23061 29712 33401 
s off
f 28606 24852 20984 
f 31163 31165 24852 
f 20980 24853 28614 
f 24853 31164 31160 
f 26280 22499 24854 
f 24854 30603 29977 
f 24855 31173 31157 
f 20971 31173 24855 
f 20970 24856 31172 
f 31156 31172 24856 
s 1040
f 24857 32488 32882 
s 1026
f 23630 24857 23232 
s off
f 21339 30051 29882 
f 23091 30051 21339 
f 29883 30050 21338 
f 23090 21338 30050 
s 1026
f 33672 28633 22208 
s 1040
f 22208 28633 33755 
s off
f 22055 29702 24861 
f 22700 24861 29702 
f 29703 24862 22699 
f 24862 29703 22056 
s 1040
f 21789 27591 21790 
s 1026
f 32141 32198 27591 
f 29476 24864 21970 
s 1040
f 25216 33254 33236 
s 1026
f 21987 27570 21618 
s 1040
f 27570 33475 33044 
s 1026
f 27556 32262 32195 
s 1040
f 22131 27556 21669 
s 1026
f 27555 32103 32147 
s 1040
f 22407 27555 22406 
s 1026
f 32320 27574 21835 
s 1040
f 29369 21835 27574 
f 32069 32228 27899 
s 1026
f 22359 27899 22360 
s 1040
f 22593 21582 24870 
f 29529 33771 32744 
s 1026
f 25218 32668 33330 
f 22026 24871 23282 
f 22111 27578 32649 
f 27578 22111 29457 
f 22152 27565 32626 
f 27565 22152 29423 
s 1040
f 29488 24874 22090 
f 25220 33336 33640 
s 1026
f 21782 24875 29467 
s 1040
f 33231 33243 23654 
f 32896 32899 22045 
s 1026
f 22044 24876 29407 
s 1040
f 21957 24877 29879 
f 33521 33536 24428 
f 32762 32765 24427 
s 1026
f 22118 24878 29361 
s 1040
f 33087 33202 24429 
s 1026
f 21963 24879 29456 
s 1040
f 32883 32888 22049 
s 1026
f 22048 24880 29402 
s 1040
f 29875 24881 21961 
f 24426 33516 33514 
s 1026
f 33583 27568 21805 
f 29386 21805 27568 
f 21866 27571 21868 
s 1040
f 33112 33560 27571 
s 1026
f 22121 24884 29256 
s 1040
f 32042 32154 27930 
s 1026
f 32411 27586 22545 
f 29208 22545 27586 
s 1040
f 22154 27561 32356 
s 1026
f 27561 22154 29213 
f 22546 27559 32484 
f 27559 22546 29236 
f 29245 22381 27557 
s 1040
f 32382 27557 22381 
s 1026
f 29356 22365 27589 
f 32541 27589 22365 
f 29431 22363 27584 
f 32533 27584 22363 
f 32441 27588 22367 
f 29401 22367 27588 
f 27587 22369 29466 
f 22369 27587 32470 
f 32436 27583 22371 
f 22372 22371 27583 
f 21741 27567 32468 
f 27567 21741 21743 
s 1040
f 25219 32921 32913 
s 1026
f 29226 24895 22053 
f 27580 22379 29608 
f 22379 27580 32567 
f 29594 22375 27563 
f 32458 27563 22375 
s 1040
f 22373 27590 32447 
s 1026
f 27590 22373 29603 
f 29606 22377 27569 
f 32561 27569 22377 
f 22358 27903 22357 
s 1040
f 27903 32189 32094 
s 1026
f 21902 27577 21688 
s 1040
f 27577 32827 32753 
f 22350 27901 22349 
s 1026
f 27901 32197 32116 
s 1040
f 22343 27904 22344 
s 1026
f 32088 32237 27904 
s 1040
f 32183 32211 27898 
s 1026
f 22347 27898 22348 
f 22352 27896 22351 
s 1040
f 27896 32099 32128 
s 1026
f 22342 27890 22341 
s 1040
f 27890 32219 32145 
f 32117 32067 27892 
s 1026
f 22345 27892 22346 
f 22353 27897 22354 
s 1040
f 32131 32079 27897 
s 1026
f 22356 27895 22355 
s 1040
f 27895 32109 32127 
f 22362 27902 22361 
s 1026
f 27902 32200 32084 
f 21838 27585 32424 
f 27585 21838 29602 
f 32418 27581 22332 
f 29598 22332 27581 
f 22096 27937 22097 
s 1040
f 32248 22097 27937 
f 22339 27900 22340 
s 1026
f 32148 32239 27900 
s 1040
f 28780 32852 32623 
s 1026
f 28276 22199 24915 
s 1040
f 28653 32249 32163 
s 1026
f 28090 24916 21687 
s 1040
f 32534 32635 29015 
s 1026
f 21729 24917 28316 
f 24918 21849 25604 
f 32505 33136 27072 
f 32327 33197 27069 
f 24919 23233 25789 
f 24920 21881 29264 
f 33778 32722 27105 
f 29268 21830 24921 
f 27104 32872 33792 
f 25718 21784 24922 
f 27109 33209 32512 
f 29578 33430 33429 
f 23774 24923 21845 
f 25642 21642 24924 
f 27087 33090 32258 
f 32900 24925 22517 
f 25156 22517 24925 
s 2147483647
f 26216 29874 26995 
s 8194
f 27954 29893 20792 
s 1040
f 24483 29894 27956 
s 2147483647
f 26994 29875 26213 
f 26214 29873 26999 
s 1040
f 27955 29892 24487 
s 2147483647
f 26215 29879 26997 
s 1040
f 27951 29899 24489 
s 8194
f 20900 29896 27952 
s 2147483647
f 26996 29877 26217 
s 1040
f 25363 29898 27950 
s 2147483647
f 27000 29872 26210 
s 1040
f 23052 29897 27953 
s 2147483647
f 26993 29878 26211 
f 26212 29876 26998 
s 1040
f 27957 29895 25433 
f 32373 29571 21771 
s 1026
f 23919 21771 29571 
s off
f 23514 24935 22979 
f 31951 22979 24935 
f 22980 24936 23515 
f 24936 22980 31952 
f 23007 24937 21316 
f 31921 21316 24937 
f 24938 21315 31922 
f 21315 24938 23008 
f 23510 24939 22968 
f 31920 22968 24939 
f 22967 24940 23513 
f 24940 22967 31919 
f 23516 24941 22965 
f 31918 22965 24941 
f 22966 24942 23517 
f 24942 22966 31917 
f 23939 24943 23040 
f 31913 23040 24943 
f 23511 24944 22691 
f 31916 22691 24944 
f 22692 24945 23512 
f 24945 22692 31915 
f 23039 24946 23942 
f 24946 23039 31914 
f 23940 24947 23011 
f 31909 23011 24947 
f 24948 23012 31910 
f 23012 24948 23941 
f 24349 24949 21390 
f 31171 21390 24949 
f 21391 24950 24358 
f 24950 21391 31170 
f 26391 24951 21576 
f 31161 21576 24951 
f 21577 24952 26390 
f 24952 21577 31162 
f 23938 21509 24953 
f 29972 24953 21509 
f 21508 24954 29973 
f 24954 21508 23937 
f 21105 21366 24955 
f 31613 24955 21366 
f 24956 21365 21103 
f 21365 24956 31612 
f 24337 24957 21768 
f 31450 21768 24957 
f 21145 21427 24958 
f 31901 24958 21427 
f 24345 24959 21116 
f 30930 21116 24959 
f 31444 21383 24960 
f 24341 24960 21383 
f 31903 24961 21475 
f 21231 21475 24961 
f 24343 24962 21155 
f 31448 21155 24962 
f 26897 24963 21082 
f 24353 21082 24963 
f 24339 24964 21770 
f 31451 21770 24964 
f 21387 21244 24965 
f 21387 24965 31449 
f 21203 24966 21218 
f 21203 31445 24966 
f 24333 24967 21203 
f 31445 21203 24967 
f 31458 21566 24968 
f 24332 24968 21566 
f 31455 21558 24969 
f 24329 24969 21558 
f 31442 21199 24970 
f 24335 24970 21199 
f 24348 24971 21195 
f 21172 21195 24971 
f 21444 24972 24346 
f 24972 21444 30931 
f 21565 24973 24331 
f 24973 21565 31457 
f 21191 24974 24342 
f 24974 21191 31446 
f 21194 24975 24359 
f 24975 21194 21173 
f 21228 24976 31443 
f 21228 21131 24976 
f 21080 24977 26898 
f 24977 21080 24351 
f 24978 21474 21232 
f 21474 24978 31904 
f 24979 21426 21146 
f 21426 24979 31902 
f 21559 24980 24330 
f 24980 21559 31459 
f 24981 21767 31456 
f 21767 24981 24338 
f 24982 21170 31447 
f 21170 24982 24334 
f 24983 21387 31449 
f 21387 24983 24344 
f 24984 21769 31454 
f 21769 24984 24340 
f 24985 21228 31443 
f 21228 24985 24336 
f 30517 30813 24986 
f 33547 24986 30813 
s 1026
f 24987 30181 28903 
s 2147483647
f 26362 30181 24987 
s 1026
f 28910 30179 24988 
s 2147483647
f 24988 30179 26371 
f 26373 30184 24989 
s 1026
f 24989 30184 28908 
s 2147483647
f 26370 30183 24990 
s 1026
f 24990 30183 26925 
f 26914 30173 24991 
s 2147483647
f 24991 30173 26372 
s 1026
f 28833 30178 24992 
s 2147483647
f 24992 30178 26367 
s 1026
f 24993 30175 28835 
s 2147483647
f 26368 30175 24993 
s 1026
f 28813 30174 24994 
s 2147483647
f 24994 30174 26369 
s 1026
f 24995 30182 28892 
s 2147483647
f 26366 30182 24995 
s 1026
f 28875 30180 24996 
s 2147483647
f 24996 30180 26365 
s 1026
f 28911 30177 24997 
s 2147483647
f 24997 30177 26363 
f 26364 30172 24998 
s 1026
f 24998 30172 26956 
f 28909 30185 24999 
s 2147483647
f 24999 30185 26361 
f 26360 30176 25000 
s 1026
f 25000 30176 28912 
f 28123 25001 27836 
f 25001 28123 33216 
s 1040
f 25002 28159 24610 
s 1026
f 32167 32260 28159 
s off
f 25003 30834 30536 
f 30834 25003 21294 
f 21293 25004 30835 
f 30537 30835 25004 
f 25005 23234 25912 
f 25005 32000 23234 
f 25006 23235 31999 
f 25911 23235 25006 
f 25007 30820 30526 
f 30820 25007 21214 
f 20989 25008 30823 
f 30527 30823 25008 
f 30521 30826 25009 
f 21219 25009 30826 
f 25010 30829 30520 
f 30829 25010 21218 
f 25011 30814 30515 
f 33439 30814 25011 
f 30516 30815 25012 
f 25012 30815 33498 
f 26288 25013 21512 
f 21202 30837 25014 
f 25014 30837 30539 
f 21201 25015 30836 
f 30538 30836 25015 
f 21244 25016 30822 
f 30528 30822 25016 
f 30821 25017 21245 
f 25017 30821 30529 
f 25240 25018 30817 
f 30530 30817 25018 
f 25019 30816 30531 
f 30816 25019 25241 
f 33498 25020 30833 
f 30541 30833 25020 
f 25021 30832 30540 
f 30832 25021 33439 
f 25022 30818 30519 
f 30818 25022 21181 
f 30518 30819 25023 
f 33547 25023 30819 
s 1026
f 29450 33204 33103 
f 27888 25024 21917 
f 22231 21909 29451 
s 1040
f 33740 29451 21909 
f 32198 32141 29507 
s 1026
f 21791 29507 22466 
s 1040
f 29294 32084 32200 
s 1026
f 29545 29294 22305 
f 29292 32127 32109 
f 29552 29292 22293 
f 22296 29302 29539 
f 32079 32131 29302 
s 1040
f 29295 32116 32197 
s 1026
f 29550 29295 22285 
f 29296 32145 32219 
f 29542 29296 22273 
f 32211 32183 29297 
f 22276 29297 29540 
f 29301 32128 32099 
f 29544 29301 22289 
f 22268 29298 29548 
s 1040
f 32237 32088 29298 
s 1026
f 32067 32117 29299 
f 22280 29299 29547 
f 29300 32094 32189 
f 29549 29300 22249 
f 32228 32069 29291 
f 22244 29291 29551 
s 1040
f 32362 29181 22333 
s 1026
f 22487 22333 29181 
f 29182 21840 22389 
s 1040
f 21840 29182 32462 
s 1026
f 29479 21742 27521 
s 1040
f 21742 29479 32537 
s 1026
f 29483 22320 22322 
s 1040
f 22320 29483 32353 
s 1026
f 27522 22317 29478 
s 1040
f 32506 29478 22317 
s 1026
f 22324 22325 29485 
s 1040
f 32368 29485 22325 
s 1026
f 22308 22309 29481 
s 1040
f 32404 29481 22309 
s 1026
f 22312 22313 29480 
s 1040
f 32363 29480 22313 
s 1026
f 22223 22080 29176 
s 1040
f 32650 29176 22080 
s 1026
f 22266 22265 29179 
s 1040
f 32378 29179 22265 
s 1026
f 29178 22256 22257 
s 1040
f 22256 29178 32341 
s 1026
f 22302 22301 29175 
s 1040
f 32535 29175 22301 
f 29180 22260 22261 
s 1026
f 22260 29180 32596 
f 33560 33112 29428 
s 1040
f 21869 29428 29397 
s 1026
f 22521 21808 29455 
s 1040
f 32829 29455 21808 
s 1026
f 29303 23654 27932 
s 1040
f 23654 29303 33231 
f 22045 29469 32896 
s 1026
f 29469 22045 22613 
f 29470 22049 22611 
s 1040
f 22049 29470 32883 
s 1026
f 29477 22113 22124 
s 1040
f 22113 29477 32725 
s 1026
f 22252 29293 29553 
s 1040
f 32239 32148 29293 
f 29537 32195 32262 
s 1026
f 21671 29537 23401 
s 1040
f 29538 32147 32103 
s 1026
f 22206 29538 22205 
f 29613 32753 32827 
f 21686 29613 23402 
f 21751 25061 21748 
f 29615 32652 32581 
s 1040
f 32550 29536 23400 
s 1026
f 25700 23400 29536 
f 29535 23399 25698 
s 1040
f 23399 29535 32412 
f 25697 22329 29534 
s 1026
f 32527 29534 22329 
s 1040
f 33404 29611 21834 
s 1026
f 25685 21834 29611 
s off
f 25066 30831 30533 
f 30831 25066 21273 
f 30532 30830 25067 
f 21272 25067 30830 
f 30485 30750 25068 
f 25068 26419 31782 
f 25069 30751 30484 
f 31781 26420 25069 
f 30813 30517 25070 
f 30877 25070 32041 
s 1026
f 25071 21754 25745 
s 1040
f 21754 25071 33205 
s 1026
f 25758 21874 25072 
s 1040
f 33619 25072 21874 
s 1026
f 25073 21999 25804 
s 1040
f 21999 25073 33092 
f 25374 25074 27766 
s 1026
f 32523 27766 25074 
s off
f 32316 25075 30494 
f 21368 30494 25075 
f 25076 30495 21369 
f 30495 25076 32284 
s 1040
f 23355 25077 22777 
s 1026
f 32931 33422 25077 
f 25078 33821 33801 
s 1040
f 22775 25078 22130 
s 1026
f 22771 25079 22095 
s 1040
f 25079 32564 33099 
s 1026
f 22867 26443 22039 
s 1040
f 26443 22867 25126 
s 1026
f 22450 26445 24291 
s 1040
f 26445 22450 25116 
s 1026
f 22412 26444 24676 
s 1040
f 26444 22412 25092 
s 1026
f 21914 26446 22877 
s 1040
f 25118 22877 26446 
s 1026
f 22448 26441 23530 
f 26441 22448 25119 
f 22465 26442 24015 
f 26442 22465 25123 
f 22595 26447 22721 
s 1040
f 32781 32955 26447 
s 1026
f 33623 25087 22572 
s 1040
f 28832 22572 25087 
s 1026
f 22110 25088 32587 
f 25088 22110 28799 
f 22578 25089 22787 
s 1040
f 32846 33373 25089 
s 1026
f 28729 23309 25090 
f 32655 25090 23309 
f 22721 25091 22595 
s 1040
f 25091 22721 24117 
f 25092 22728 26444 
s 1026
f 22728 25092 28698 
f 25093 22724 24157 
s 1040
f 22724 25093 28976 
f 24139 22722 25094 
s 1026
f 23057 25094 22722 
s 1040
f 25095 22725 24146 
s 1026
f 22725 25095 25516 
f 22723 25096 21724 
s 1040
f 25096 22723 24132 
f 24295 22216 28720 
s 1026
f 32677 28720 22216 
f 24158 22421 25098 
s 1040
f 28978 25098 22421 
f 25146 25099 22756 
s 1026
f 24153 22756 25099 
f 25100 22420 24155 
s 1040
f 22420 25100 28977 
f 25101 22422 24154 
s 1026
f 22422 25101 28979 
s 1040
f 24156 22424 25102 
s 1026
f 28980 25102 22424 
s 1040
f 25103 22428 24137 
s 1026
f 22428 25103 25149 
s 1040
f 32365 25104 22486 
s 1026
f 24149 22486 25104 
s 1040
f 24147 22757 25105 
s 1026
f 28975 25105 22757 
f 24129 22426 25106 
s 1040
f 33186 25106 22426 
f 24134 22429 25107 
s 1026
f 25153 25107 22429 
s 1040
f 24145 22766 25108 
s 1026
f 22404 25108 22766 
f 25109 22911 23615 
s 1040
f 22911 25109 25154 
f 25110 22410 24120 
s 1026
f 22410 25110 33325 
f 25111 22755 23540 
s 1040
f 22755 25111 28803 
f 25112 22430 24131 
s 1026
f 22430 25112 22188 
f 25113 22910 33311 
f 22910 25113 25155 
f 23616 22912 25114 
s 1040
f 25148 25114 22912 
f 24140 22431 25115 
s 1026
f 24439 25115 22431 
s 1040
f 25116 22778 26445 
s 1026
f 22778 25116 24579 
s 1040
f 25117 22434 22913 
s 1026
f 22434 25117 25158 
s 1040
f 26446 22433 25118 
s 1026
f 24768 25118 22433 
f 25119 22777 26441 
s 1040
f 22777 25119 23355 
s 1026
f 22457 22729 25120 
s 1040
f 28689 25120 22729 
s 1026
f 24761 25121 22776 
s 1040
f 22461 22776 25121 
s 1026
f 22460 22775 25122 
s 1040
f 22130 25122 22775 
s 1026
f 25123 22774 26442 
s 1040
f 22774 25123 28684 
s 1026
f 22095 25124 22771 
s 1040
f 22462 22771 25124 
f 22458 22773 25125 
s 1026
f 28695 25125 22773 
s 1040
f 25126 22772 26443 
s 1026
f 22772 25126 28688 
s 1040
f 33474 28918 22694 
s 1026
f 24288 22694 28918 
f 33335 33588 28947 
s 1040
f 22386 28947 28923 
s 1026
f 28844 23192 24199 
s 1040
f 23192 28844 32862 
s 1026
f 24229 22228 28822 
s 1040
f 33107 28822 22228 
s 1026
f 28791 22603 24205 
s 1040
f 22603 28791 32741 
s 1026
f 26514 22438 25132 
s 1040
f 28965 25132 22438 
s 1026
f 25133 22446 24203 
s 1040
f 22446 25133 28966 
s 1026
f 22136 22447 25134 
s 1040
f 28963 25134 22447 
f 22439 25135 28964 
s 1026
f 25135 22439 21740 
s 1040
f 32999 29048 23134 
s 1026
f 22897 23134 29048 
f 29037 23231 22898 
s 1040
f 23231 29037 32861 
s 1026
f 22394 22594 29065 
s 1040
f 33111 29065 22594 
s 1026
f 22464 23493 29024 
s 1040
f 32751 29024 23493 
s 1026
f 21951 22458 25140 
s 1040
f 25125 25140 22458 
s 1026
f 25141 22913 26481 
s 1040
f 22913 25141 25117 
f 25124 25142 22462 
s 1026
f 23535 22462 25142 
f 23736 22457 25143 
f 25120 25143 22457 
s 1040
f 25121 25144 22461 
s 1026
f 26480 22461 25144 
f 23532 22460 25145 
f 25122 25145 22460 
s 1040
f 22756 21967 25146 
s 1026
f 33751 25146 21967 
s 1040
f 23433 22394 25147 
s 1026
f 29065 25147 22394 
s 1040
f 22912 22897 25148 
s 1026
f 29048 25148 22897 
f 25149 22234 22428 
s 1040
f 22234 25149 33120 
f 25150 22396 23435 
s 1026
f 22396 25150 29046 
f 25151 22418 26517 
f 22418 25151 29054 
f 24165 22416 25152 
f 29070 25152 22416 
s 1040
f 32946 25153 22337 
s 1026
f 22429 22337 25153 
s 1040
f 25154 22898 22911 
s 1026
f 22898 25154 29037 
f 25155 22900 22910 
s 1040
f 22900 25155 29023 
s 1026
f 24925 22899 25156 
s 1040
f 29050 25156 22899 
s 1026
f 29024 25157 22464 
s 1040
f 24263 22464 25157 
f 21924 25158 33786 
s 1026
f 25158 21924 22434 
f 22717 22463 25159 
f 29061 25159 22463 
f 25160 22441 26518 
s 1040
f 22441 25160 28797 
s 1026
f 32857 32555 28285 
f 21737 28285 28264 
f 28320 32563 32958 
f 28309 28320 22178 
f 28198 21989 22890 
s 1040
f 21989 28198 33329 
s off
f 25164 31305 31288 
f 28553 25164 30187 
f 30189 25165 28555 
f 31286 31302 25165 
f 28554 25166 30188 
f 25166 31304 31287 
f 31289 31303 25167 
f 30190 25167 28556 
f 25168 21007 20925 
s 64
f 31506 31547 31504 
f 31525 31527 31528 
s off
f 20945 21004 25169 
f 20973 25170 21183 
s 64
f 31521 31531 31532 
s off
f 21184 25171 20954 
s 64
f 31508 31549 31501 
s off
f 21014 25172 21187 
s 64
f 31565 31493 31537 
s off
f 21188 25173 20960 
s 64
f 31511 31495 31564 
s off
f 30536 30978 31800 
f 25174 30536 30834 
f 31799 30979 30537 
f 30835 30537 25175 
f 31790 30970 30534 
f 22649 30534 25176 
f 25177 30535 22648 
f 30535 30971 31789 
f 30823 30527 25178 
f 31798 30983 30527 
f 30526 30982 31797 
f 25179 30526 30820 
f 30826 30521 25180 
f 31796 30987 30521 
f 25181 30520 30829 
f 30520 30986 31795 
f 31782 30968 30516 
f 30815 30516 25182 
f 30515 30967 31781 
f 25183 30515 30814 
f 30836 30538 25184 
f 21260 25184 22489 
f 25185 30539 30837 
f 22488 25185 21261 
f 25186 30519 30818 
f 31658 22541 25186 
f 25187 22542 31657 
f 30819 30518 25187 
f 30525 30993 31794 
f 25188 30525 30827 
f 30828 30524 25189 
f 31793 30992 30524 
f 31805 30976 30522 
f 30825 30522 25190 
f 25191 30523 30824 
f 30523 30977 31806 
f 25192 30529 30821 
f 30529 30989 31808 
f 30822 30528 25193 
f 31807 30988 30528 
f 31659 22539 25194 
f 25194 30540 30832 
f 30833 30541 25195 
f 25195 22540 31661 
f 25196 30531 30816 
f 31664 22537 25196 
f 30817 30530 25197 
f 25197 22538 31663 
f 30533 30981 31804 
f 25198 30533 30831 
f 31802 30980 30532 
f 30830 30532 25199 
f 33480 25200 31316 
f 31281 31316 25200 
f 30667 25201 31314 
f 31279 31314 25201 
f 33507 25202 31311 
f 31278 31311 25202 
f 31315 25203 30682 
f 25203 31315 31277 
f 31319 25204 21466 
f 25204 31319 31285 
f 31274 31306 25205 
f 29963 25205 31306 
f 31317 25206 29960 
f 25206 31317 31283 
f 21104 25207 31313 
f 31272 31313 25207 
f 31284 31318 25208 
f 30653 25208 31318 
f 33817 25209 31312 
f 31268 31312 25209 
f 25210 31308 31266 
f 31308 25210 29962 
f 25211 31309 31276 
f 31309 25211 21102 
f 25212 31310 31264 
f 31310 25212 33822 
f 31307 25213 21193 
f 25213 31307 31271 
s 1026
f 29353 33070 32732 
f 22036 29353 22037 
f 22635 21660 29399 
s 1040
f 32890 29399 21660 
s 1026
f 25554 23465 27726 
s 1040
f 33272 27726 23465 
s 1026
f 27592 22079 25217 
f 32785 25217 22079 
s 1040
f 25533 23228 27776 
s 1026
f 33348 27776 23228 
f 23625 23391 27704 
s 1040
f 32927 27704 23391 
f 33642 27623 23468 
s 1026
f 25419 23468 27623 
s 1040
f 33575 23045 27607 
s 1026
f 27667 27607 23045 
s 1040
f 28108 25222 23466 
s 1026
f 26132 23466 25222 
s 1040
f 23467 25223 28104 
s 1026
f 25223 23467 27835 
f 27707 23229 26176 
s 1040
f 23229 27707 33334 
s off
f 25225 30477 30757 
f 30477 25225 21273 
f 21568 30476 25226 
f 25226 30476 30755 
f 30752 30474 25227 
f 25227 30474 21569 
f 30756 30475 25228 
f 21272 25228 30475 
f 25229 30478 30761 
f 30478 25229 21470 
f 21470 25230 30479 
f 30760 30479 25230 
f 24966 25231 30471 
f 30744 30471 25231 
f 25232 30470 30745 
f 30470 25232 24966 
f 25233 30473 30754 
f 30473 25233 21245 
f 21244 25234 30472 
f 30753 30472 25234 
f 30466 25235 24965 
f 25235 30466 30749 
f 30467 25236 21517 
f 25236 30467 30748 
f 30747 30468 25237 
f 24965 25237 30468 
f 21517 25238 30469 
f 30746 30469 25238 
f 30482 25239 21218 
f 25239 30482 30764 
f 25240 30485 30799 
f 30750 30485 25240 
f 25241 30484 30751 
f 30798 30484 25241 
f 21219 25242 30483 
f 30765 30483 25242 
f 30481 25243 21214 
f 25243 30481 30762 
f 30763 30480 25244 
f 20989 25244 30480 
f 21286 30490 25245 
f 25245 30490 30742 
f 30741 30491 25246 
f 33547 25246 30491 
f 30740 30489 25247 
f 30785 25247 30489 
f 25248 30488 30743 
f 30488 25248 30784 
f 25249 30493 32521 
f 30758 30493 25249 
f 30186 30492 25250 
f 25250 30492 30759 
s 1026
f 21735 25251 29258 
s 1040
f 32046 32718 27894 
f 27558 32689 33506 
f 29308 21817 25252 
f 21968 25253 21848 
f 29612 32929 32932 
f 32391 32721 27564 
s 1026
f 21852 25254 29590 
f 22553 25255 29596 
s 1040
f 32322 32659 27560 
s off
f 29990 30059 21923 
f 20975 21923 30059 
f 21922 30060 29989 
f 30060 21922 20943 
s 1026
f 33190 28489 25258 
f 24549 25258 28489 
s 1040
f 23119 25259 27741 
f 32315 33528 25358 
f 33649 32680 24614 
f 21871 25260 27628 
s 1026
f 27616 25261 23105 
s 1040
f 25350 33102 33802 
s 1026
f 24619 21892 25262 
f 27837 32859 33597 
f 23120 25263 27794 
s 1040
f 33795 33027 25342 
s 1026
f 32329 33477 25546 
f 23118 25264 27851 
s off
f 21239 25265 30045 
f 25265 29637 31130 
f 31131 29638 25266 
f 30046 25266 21238 
s 1026
f 23934 22590 29566 
f 33174 29566 22590 
f 23873 23486 25268 
f 33233 25268 23486 
f 23000 29569 29267 
s 1040
f 29569 23000 33038 
s 1026
f 29191 29565 23477 
s 1040
f 33627 23477 29565 
s 1026
f 33131 25271 22574 
f 23780 22574 25271 
f 23809 21916 25272 
f 33630 25272 21916 
s 1040
f 33419 25273 21877 
s 1026
f 26371 21877 25273 
f 25274 21814 26370 
s 1040
f 21814 25274 33391 
s 1026
f 25275 22946 25037 
s 1040
f 22946 25275 23802 
s 1026
f 22945 25276 23812 
f 25276 22945 25057 
f 33542 25277 22024 
s 1040
f 25831 22024 25277 
s 1026
f 29502 22089 25278 
s 1040
f 32664 25278 22089 
s 1026
f 25279 21973 25730 
s 1040
f 21973 25279 33655 
s 1026
f 25280 20772 20770 
s 1040
f 20772 25280 33484 
s 1026
f 25281 21964 25738 
s 1040
f 21964 25281 33183 
s 1026
f 25282 22021 32613 
f 22021 25282 23859 
f 20877 20878 25283 
s 1040
f 33508 25283 20878 
s 1026
f 25284 22115 25785 
s 1040
f 22115 25284 32757 
s 1026
f 25285 22058 29503 
s 1040
f 22058 25285 32637 
f 25286 22062 29189 
s 1026
f 22062 25286 32347 
f 25033 22947 25287 
s 1040
f 23789 25287 22947 
s 1026
f 22951 25288 23793 
f 25288 22951 25034 
f 25289 22949 25035 
s 1040
f 22949 25289 23791 
s 1026
f 23807 25290 22956 
f 25027 22956 25290 
s 1040
f 23786 25291 22948 
s 1026
f 25031 22948 25291 
f 25292 22952 25032 
s 1040
f 22952 25292 23796 
s 1026
f 23790 25293 22950 
f 25030 22950 25293 
f 25036 22953 25294 
s 1040
f 23800 25294 22953 
f 22955 25295 23784 
s 1026
f 25295 22955 25029 
f 25028 22954 25296 
s 1040
f 23783 25296 22954 
s 1026
f 22585 22934 25297 
s 1040
f 25309 25297 22934 
f 22935 25298 25310 
s 1026
f 25298 22935 29517 
s 1040
f 23900 25299 22936 
s 1026
f 29515 22936 25299 
f 25300 22937 29516 
s 1040
f 22937 25300 23901 
s 1026
f 24820 25301 22395 
s 1040
f 22942 22395 25301 
f 25302 22397 22940 
s 1026
f 22397 25302 23899 
s 1040
f 23396 25303 33698 
s 1026
f 25303 23396 25640 
f 25304 22119 22939 
s 1040
f 22119 25304 32790 
s 1026
f 25617 21971 25305 
s 1040
f 33211 25305 21971 
s 1026
f 25306 21979 26360 
s 1040
f 21979 25306 33745 
s 1026
f 21675 22942 25307 
s 1040
f 25301 25307 22942 
s 1026
f 25308 22940 26194 
s 1040
f 22940 25308 25302 
s 1026
f 22141 25309 22941 
s 1040
f 22934 22941 25309 
f 25310 22939 22935 
s 1026
f 22939 25310 25304 
f 32100 22585 25311 
f 25297 25311 22585 
s off
f 30536 29117 31697 
f 25312 30762 30481 
f 30480 30763 25313 
f 31700 29118 30537 
f 30535 29125 31693 
f 25314 30764 30482 
f 30483 30765 25315 
f 31696 29126 30534 
f 30523 29127 31692 
f 25316 30754 30473 
f 30472 30753 25317 
f 31691 29128 30522 
f 30474 30752 25318 
f 21260 25318 20976 
f 30475 30756 25319 
f 31695 29134 30524 
f 20996 25320 21261 
f 25320 30755 30476 
f 25321 30757 30477 
f 30525 29135 31698 
f 25322 30748 30467 
f 30526 29120 31685 
f 31686 29119 30527 
f 30469 30746 25323 
f 30529 29123 31684 
f 25324 30749 30466 
f 31683 29122 30528 
f 30468 30747 25325 
f 30520 29121 31679 
f 25326 30745 30470 
f 31680 29124 30521 
f 30471 30744 25327 
f 25328 30761 30478 
f 30533 29132 31687 
f 31688 29131 30532 
f 30479 30760 25329 
f 32030 21255 25330 
f 25330 30759 30492 
f 32029 25331 21011 
f 30493 30758 25331 
f 32031 25332 21008 
f 30491 30741 25332 
f 32032 21144 25333 
f 25333 30742 30490 
f 32034 21256 25334 
f 25334 30743 30488 
f 30489 30740 25335 
f 32033 25335 21257 
s 1040
f 28460 22768 22921 
s 1026
f 22768 28460 28431 
s 1040
f 28352 22770 22901 
s 1026
f 22770 28352 28455 
f 28344 22769 22926 
s 1040
f 22769 28344 28220 
f 22928 22789 28296 
s 1026
f 28163 28296 22789 
s 1040
f 28367 22788 22927 
s 1026
f 22788 28367 28411 
f 22213 25341 28741 
s 1040
f 32160 32049 25341 
s 1026
f 28257 22643 22906 
s 1040
f 22643 28257 33717 
s 1026
f 28774 22908 28461 
s 1040
f 28394 28461 22908 
s 1026
f 28456 22902 22645 
s 1040
f 22902 28456 28339 
s 1026
f 28446 22909 24434 
s 1040
f 22909 28446 28426 
f 28426 22906 22909 
s 1026
f 22906 28426 28257 
s 1040
f 22908 22903 28394 
s 1026
f 28372 28394 22903 
f 28789 22886 28386 
s 1040
f 28077 28386 22886 
f 28384 22791 28661 
s 1026
f 22791 28384 28397 
f 22903 22528 28372 
s 1040
f 33671 28372 22528 
s 1026
f 22904 28339 26331 
s 1040
f 28339 22904 22902 
s 1026
f 24433 22905 28329 
s 1040
f 28275 28329 22905 
s 1026
f 28282 22883 24508 
s 1040
f 22883 28282 28363 
f 22905 22907 28275 
s 1026
f 28235 28275 22907 
s 1040
f 33382 28249 22641 
s 1026
f 22881 22641 28249 
f 22880 22881 28250 
f 28249 28250 22881 
s 1040
f 32944 28235 22634 
s 1026
f 22907 22634 28235 
s 1040
f 22483 28226 33531 
s 1026
f 28226 22483 22414 
s 1040
f 28771 22882 28223 
s 1026
f 28292 28223 22882 
f 28221 22414 22792 
f 22414 28221 28226 
s 1040
f 21697 22880 28185 
s 1026
f 28250 28185 22880 
f 28164 22645 33179 
f 22645 28164 28456 
f 20884 22878 28305 
s 1040
f 22879 28305 22878 
s 1026
f 28279 21998 22810 
s 1040
f 21998 28279 32826 
s 1026
f 28211 21682 28998 
f 33220 28998 21682 
s 8194
f 29079 20828 20834 
s 8208
f 20828 29079 25915 
s 1026
f 28390 22927 29013 
s 1040
f 22927 28390 28367 
f 28995 22469 28340 
s 1026
f 28336 28340 22469 
f 28338 22926 26957 
f 22926 28338 28344 
f 28304 22889 28993 
s 1040
f 22889 28304 28374 
s 1026
f 28998 22928 28211 
s 1040
f 28296 28211 22928 
f 28162 22467 28994 
s 1026
f 22467 28162 26344 
f 28208 22388 22731 
s 1040
f 22388 28208 32469 
f 28346 28418 22760 
s 1026
f 22924 22760 28418 
f 28287 22761 22925 
s 1040
f 22761 28287 28190 
f 28076 22435 22876 
s 1026
f 22435 28076 28233 
s 1040
f 28354 22758 22922 
s 1026
f 22758 28354 28432 
s 1040
f 22923 22759 28247 
s 1026
f 28436 28247 22759 
s 1040
f 25528 22762 28368 
s 1026
f 22250 28368 22762 
s 1040
f 22918 22763 28302 
s 1026
f 28437 28302 22763 
s 1040
f 22917 22767 28300 
s 1026
f 26318 28300 22767 
f 25527 22780 28289 
s 1040
f 22304 28289 22780 
s 1026
f 22920 22741 28194 
s 1040
f 28356 28194 22741 
f 25524 22739 28230 
s 1026
f 22272 28230 22739 
f 21910 22744 28457 
s 1040
f 28417 28457 22744 
f 28453 22743 25522 
s 1026
f 22743 28453 22277 
f 22764 28449 22297 
s 1040
f 28449 22764 25523 
s 1026
f 28387 22748 21708 
s 1040
f 22748 28387 28373 
s 1026
f 28345 22747 25520 
s 1040
f 22747 28345 22269 
s 1026
f 25519 22738 28333 
s 1040
f 22284 28333 22738 
s 1026
f 22051 22746 28325 
s 1040
f 28299 28325 22746 
f 25526 22765 28327 
s 1026
f 22292 28327 22765 
s 1040
f 28301 22742 25521 
s 1026
f 22742 28301 22281 
s 1040
f 25525 22737 28261 
s 1026
f 22290 28261 22737 
f 28256 22736 22027 
s 1040
f 22736 28256 28182 
s 1026
f 28191 22740 22588 
s 1040
f 22740 28191 28166 
s 1026
f 28342 28364 22745 
s 1040
f 22822 22745 28364 
f 28740 25398 22204 
s 1026
f 25398 32233 32115 
s 1040
f 28242 22731 22914 
s 1026
f 22731 28242 28208 
s 1040
f 22915 22733 28204 
s 1026
f 28443 28204 22733 
f 28406 28427 22751 
f 28973 22751 28427 
f 28969 22732 28441 
s 1040
f 28423 28441 22732 
s 1026
f 28402 22749 28967 
f 22749 28402 28422 
f 28357 22735 24438 
s 1040
f 22735 28357 28324 
s 1026
f 28255 22753 28970 
f 22753 28255 28258 
f 28972 22754 28192 
s 1040
f 28178 28192 22754 
s 1026
f 24458 22734 28361 
s 1040
f 28273 28361 22734 
s 1026
f 21847 22750 28369 
f 28351 28369 22750 
s 1040
f 22752 28210 28403 
s 1026
f 28210 22752 28971 
s 1040
f 26304 28225 22779 
s 1026
f 22916 22779 28225 
f 28742 25411 21954 
s 1040
f 25411 32068 32073 
s 1026
f 22041 25412 28737 
s 1040
f 32072 32065 25412 
f 28363 22810 22883 
s 1026
f 22810 28363 28279 
f 28785 22869 28207 
s 1040
f 28174 28207 22869 
s 1026
f 28784 22868 28359 
s 1040
f 28335 28359 22868 
f 33511 28410 22224 
s 1026
f 25509 22224 28410 
s 1040
f 22874 22800 28464 
s 1026
f 26325 28464 22800 
s 1040
f 22873 22801 28240 
s 1026
f 28222 28240 22801 
f 28197 28216 22453 
f 22796 22453 28216 
s 1040
f 28205 22802 24088 
s 1026
f 22802 28205 28362 
f 28180 22804 22866 
f 22804 28180 28404 
f 23524 25422 33199 
s 1040
f 25422 23524 28281 
s 1026
f 24509 22811 28227 
s 1040
f 28199 28227 22811 
s 1026
f 25511 22561 28246 
s 1040
f 32963 28246 22561 
s 1026
f 33028 22639 28245 
f 28239 28245 22639 
f 28254 22610 22841 
s 1040
f 22610 28254 32898 
f 28268 22856 22817 
s 1026
f 22856 28268 28170 
f 22850 22853 28218 
s 1040
f 32825 28218 22853 
s 1026
f 28313 22492 33097 
f 22492 28313 28234 
f 28288 22399 20764 
s 1040
f 22399 28288 33356 
s 1026
f 21938 22815 28267 
s 1040
f 28238 28267 22815 
f 24767 22796 28459 
s 1026
f 28216 28459 22796 
f 28448 22862 20777 
s 1040
f 22862 28448 22863 
s 1026
f 28330 22876 28782 
s 1040
f 22876 28330 28076 
s 1026
f 26351 28439 22823 
s 1040
f 22851 22823 28439 
s 1026
f 28434 22798 28787 
s 1040
f 22798 28434 28370 
f 33409 28420 23672 
s 1026
f 20858 23672 28420 
f 28419 22819 23244 
s 1040
f 22819 28419 20912 
s 1026
f 28414 22840 21900 
s 1040
f 22840 28414 28314 
f 28400 22436 22818 
s 1026
f 22436 28400 28383 
f 21942 22822 28395 
s 1040
f 28364 28395 22822 
s 1026
f 28392 22852 21962 
s 1040
f 22852 28392 28371 
s 1026
f 28383 21780 22436 
s 1040
f 21780 28383 33270 
f 28371 22812 22852 
s 1026
f 22812 28371 28353 
f 21994 22821 28365 
s 1040
f 28331 28365 22821 
f 28307 28358 22844 
s 1026
f 28778 22844 28358 
f 28353 22400 22812 
s 1040
f 22400 28353 33242 
s 1026
f 28350 22803 28779 
s 1040
f 22803 28350 28430 
f 22824 22833 28343 
s 1026
f 28193 28343 22833 
f 28272 22845 22834 
s 1040
f 22845 28272 33139 
f 22832 28334 28183 
s 1026
f 28334 22832 21755 
s 1040
f 22821 22829 28331 
s 1026
f 28312 28331 22829 
f 33274 22033 28315 
f 28169 28315 22033 
f 23241 22830 28311 
s 1040
f 20805 28311 22830 
s 1026
f 22829 21997 28312 
s 1040
f 32997 28312 21997 
f 28310 22834 22825 
s 1026
f 22834 28310 28272 
s 1040
f 22844 22837 28307 
s 1026
f 28391 28307 22837 
f 28293 22836 22047 
s 1040
f 22836 28293 28274 
f 28280 22839 22835 
s 1026
f 22839 28280 28262 
f 24915 22842 28276 
s 1040
f 28214 28276 22842 
f 28274 22841 22836 
s 1026
f 22841 28274 28254 
f 28271 22843 21930 
s 1040
f 22843 28271 28232 
s 1026
f 28270 21755 32998 
f 21755 28270 28334 
s 1040
f 28343 28263 22824 
s 1026
f 22069 22824 28263 
f 28262 22612 22839 
s 1040
f 22612 28262 32634 
f 28251 22849 28773 
s 1026
f 22849 28251 28189 
s 1040
f 28439 28239 22851 
s 1026
f 22639 22851 28239 
f 22860 22562 28241 
s 1040
f 32778 28241 22562 
s 1026
f 28236 22587 20751 
s 1040
f 22587 28236 33374 
f 22815 22850 28238 
s 1026
f 28218 28238 22850 
f 28234 22846 22492 
s 1040
f 22846 28234 28168 
f 28232 22813 22843 
s 1026
f 22813 28232 28196 
f 28228 22847 23243 
s 1040
f 22847 28228 20847 
s 1026
f 23240 22857 28219 
s 1040
f 20714 28219 22857 
s 1026
f 28425 28214 22437 
s 1040
f 22842 22437 28214 
s 1026
f 28213 22454 28777 
s 1040
f 22454 28213 28179 
s 1026
f 28203 22817 24504 
s 1040
f 22817 28203 28268 
f 22811 22860 28199 
s 1026
f 28241 28199 22860 
f 22035 22455 28200 
s 1040
f 28176 28200 22455 
s 1026
f 28196 22861 22813 
s 1040
f 22861 28196 32893 
s 1026
f 28189 22805 22849 
f 22805 28189 28298 
s 1040
f 20740 28187 22854 
s 1026
f 23242 22854 28187 
f 20871 22398 28184 
s 1040
f 33523 28184 22398 
f 28183 22827 22832 
s 1026
f 22827 28183 26316 
f 22875 28179 26339 
s 1040
f 28179 22875 22454 
s 1026
f 28429 28174 22870 
s 1040
f 22869 22870 28174 
f 28171 22859 28660 
s 1026
f 22859 28171 28454 
f 28170 22116 22856 
s 1040
f 22116 28170 32615 
s 1026
f 22033 22874 28169 
s 1040
f 28464 28169 22874 
f 28168 22864 22846 
s 1026
f 22864 28168 26338 
f 28165 22828 33193 
f 22828 28165 28468 
f 28348 22825 21934 
s 1040
f 22825 28348 28310 
s 1026
f 28770 22797 28295 
s 1040
f 28266 28295 22797 
s 1026
f 28435 28335 22871 
s 1040
f 22868 22871 28335 
s 1026
f 28297 22835 22043 
s 1040
f 22835 28297 28280 
s 1026
f 22806 22872 28407 
f 28388 28407 22872 
f 28416 22818 22125 
s 1040
f 22818 28416 28400 
s 1026
f 21807 22873 28260 
s 1040
f 28240 28260 22873 
f 28314 22838 22840 
s 1026
f 22838 28314 28237 
s 1040
f 28772 22806 28341 
s 1026
f 28407 28341 22806 
s 1040
f 21844 28298 33664 
s 1026
f 28298 21844 22805 
s 1040
f 28281 22866 25422 
s 1026
f 22866 28281 28180 
s 1040
f 28430 22885 22803 
s 1026
f 22885 28430 28401 
f 22865 28362 28317 
f 28362 22865 22802 
s 1040
f 32844 28222 22520 
s 1026
f 22801 22520 28222 
s 1040
f 28370 22808 22798 
s 1026
f 22808 28370 28433 
s 1040
f 22795 22893 28413 
s 1026
f 28445 28413 22893 
s 1040
f 22797 22807 28266 
s 1026
f 28229 28266 22807 
f 28410 23637 25509 
f 22888 25509 23637 
s 1040
f 23639 25510 28962 
s 1026
f 25510 23639 26336 
s 1040
f 28959 25511 23638 
s 1026
f 28246 23638 25511 
s 1040
f 25512 22452 28960 
s 1026
f 22452 25512 28347 
s 1040
f 28961 22451 25513 
s 1026
f 28438 25513 22451 
f 28997 23544 28201 
f 23544 28997 33106 
s 1040
f 28379 22196 28677 
s 1026
f 22196 28379 33428 
f 21679 29006 28670 
s 1040
f 32879 32643 29006 
f 21819 22888 25517 
s 1026
f 23637 25517 22888 
s 8208
f 25914 29080 20721 
s 8194
f 20727 20721 29080 
s 1026
f 28333 22286 25519 
f 22283 25519 22286 
f 22270 25520 22267 
f 25520 22270 28345 
f 22282 25521 22279 
s 1040
f 25521 22282 28301 
s 1026
f 22278 25522 22275 
s 1040
f 25522 22278 28453 
s 1026
f 22298 25523 22295 
s 1040
f 25523 22298 28449 
f 28230 22274 25524 
s 1026
f 22271 25524 22274 
f 22287 25525 22288 
s 1040
f 28261 22288 25525 
f 28327 22294 25526 
s 1026
f 22291 25526 22294 
f 28289 22306 25527 
f 22303 25527 22306 
f 22247 25528 22248 
s 1040
f 28368 22248 25528 
s 1026
f 28284 22914 29019 
s 1040
f 22914 28284 28242 
s 1026
f 33256 22920 28381 
f 28194 28381 22920 
f 28349 22922 29018 
s 1040
f 22922 28349 28354 
s 1026
f 28337 22027 33206 
f 22027 28337 28256 
f 29011 22919 28332 
s 1040
f 28355 28332 22919 
f 28247 28252 22923 
s 1026
f 29020 22923 28252 
f 26959 22915 28231 
s 1040
f 28204 28231 22915 
s 1026
f 21666 28224 28089 
f 32155 32083 28224 
f 28181 22588 32796 
f 22588 28181 28191 
f 29012 22916 28243 
f 28225 28243 22916 
f 26958 22917 28382 
s 1040
f 28300 28382 22917 
f 28302 28322 22918 
s 1026
f 29016 22918 28322 
f 28201 22921 28997 
s 1040
f 22921 28201 28460 
s 1026
f 29002 22924 28167 
f 28418 28167 22924 
f 28286 22925 29017 
f 22925 28286 28287 
s 1040
f 22886 22417 28077 
s 1026
f 28217 28077 22417 
s 1040
f 28408 22727 25571 
s 1026
f 22727 28408 22245 
s 1040
f 28374 22482 22889 
s 1026
f 22482 28374 33479 
f 28303 22730 25570 
s 1040
f 22730 28303 22253 
f 23321 25548 28738 
s 1026
f 32113 32133 25548 
f 28316 22726 21729 
s 1040
f 22726 28316 28265 
s 1026
f 24513 25550 22586 
s 1040
f 22142 22586 25550 
s 1026
f 22195 25551 28968 
s 1040
f 25551 22195 22122 
s 1026
f 22417 22147 28217 
s 1040
f 33071 28217 22147 
s 1026
f 22793 22794 28389 
f 28452 28389 22794 
s 1040
f 22781 22782 28412 
s 1026
f 28393 28412 22782 
f 28405 22809 22168 
s 1040
f 22809 28405 28328 
f 28396 22887 25128 
s 1026
f 22887 28396 28215 
s 1040
f 22891 28366 20821 
s 1026
f 28366 22891 23245 
s 1040
f 28328 22884 22809 
s 1026
f 22884 28328 28415 
s 1040
f 28321 22792 28788 
s 1026
f 22792 28321 28221 
f 21926 22786 28319 
s 1040
f 28294 28319 22786 
f 21975 22793 28318 
s 1026
f 28389 28318 22793 
s 1040
f 22786 22785 28294 
s 1026
f 28277 28294 22785 
f 22785 22783 28277 
s 1040
f 32928 28277 22783 
s 1026
f 28215 22890 22887 
f 22890 28215 28198 
f 25567 22795 28177 
s 1040
f 28413 28177 22795 
s 1026
f 22180 22781 28450 
s 1040
f 28412 28450 22781 
s 1026
f 28177 21787 25567 
f 33255 25567 21787 
f 23617 25568 25569 
f 25568 23617 28269 
s 1040
f 23618 25569 21712 
s 1026
f 25569 23618 23617 
f 22254 25570 22251 
f 25570 22254 28303 
f 22246 25571 22243 
s 1040
f 25571 22246 28408 
s 1026
f 24431 25572 21676 
s 1040
f 28173 21676 25572 
f 25573 21984 28306 
s 1026
f 21984 25573 24432 
s 1040
f 22122 25574 25551 
s 1026
f 25574 22122 25583 
s 1040
f 25550 25575 22142 
s 1026
f 22143 22142 25575 
f 29004 22390 28291 
s 1040
f 33410 28291 22390 
s 1026
f 28290 22896 29007 
s 1040
f 22896 28290 32964 
s 1026
f 28186 22703 33113 
f 22703 28186 22704 
f 33229 22232 28172 
f 22233 28172 22232 
f 26311 23649 25580 
s 1040
f 29077 25580 23649 
f 23648 25581 29076 
s 1026
f 25581 23648 26328 
f 28209 22901 29005 
s 1040
f 22901 28209 28352 
s 1026
f 25583 22120 25574 
s 1040
f 22120 25583 32245 
s 1026
f 22704 29076 22703 
s 1040
f 29076 22704 23648 
s 1026
f 22232 29077 22233 
s 1040
f 23649 22233 29077 
s off
f 30086 25586 24632 
f 25586 30086 31884 
s 1026
f 33675 29667 21616 
s 1040
f 21616 29667 32057 
s off
f 22193 21319 25588 
f 25588 33160 24632 
f 25589 31588 31280 
f 30935 31588 25589 
f 31282 31589 25590 
f 25590 31589 30934 
s 1040
f 33442 33756 27575 
f 21613 33036 33755 
s off
f 25592 31577 31269 
f 30928 31577 25592 
f 31270 31576 25593 
f 25593 31576 30929 
f 25594 31574 31265 
f 30927 31574 25594 
f 31275 31575 25595 
f 25595 31575 30926 
f 30923 31572 25596 
f 25596 31572 31267 
f 25597 31573 30922 
f 31273 31573 25597 
s 8194
f 26072 30227 25598 
s 4128
f 25598 29668 29616 
s 8194
f 25599 30228 26073 
s 4128
f 29617 29669 25599 
s off
f 21039 31703 22549 
f 31703 33507 22549 
f 30896 30842 21266 
s 8208
f 22944 27941 28040 
s 8194
f 27941 22944 20838 
s 8208
f 28041 27942 22943 
s 8194
f 20731 22943 27942 
s 1040
f 29252 21851 22497 
s 1026
f 21851 29252 29177 
f 29222 22411 21626 
s 1040
f 22411 29222 21625 
f 29218 21859 22554 
s 1026
f 21859 29218 29183 
f 21861 25607 33085 
f 25607 21861 25696 
f 29532 22419 25684 
s 1040
f 22419 29532 29165 
f 21625 25609 22411 
s 1026
f 25609 21625 32693 
f 27576 21919 29445 
s 1040
f 22148 29445 21919 
f 29309 21714 23696 
s 1026
f 21714 29309 29497 
f 29306 21827 27884 
s 1040
f 21827 29306 33510 
s 1026
f 29360 23725 21990 
s 1040
f 23725 29360 33321 
s 1026
f 20888 20889 29492 
s 1040
f 29872 29492 20889 
s 1026
f 29391 21885 22384 
s 1040
f 21885 29391 33134 
s 1026
f 21970 22181 29476 
s 1040
f 29464 29476 22181 
f 22181 21972 29464 
s 1026
f 29463 29464 21972 
s 1040
f 29459 22387 21991 
s 1026
f 22387 29459 29359 
f 29433 20815 22892 
s 1040
f 20815 29433 20825 
s 1026
f 29378 29372 21696 
s 1040
f 23697 21696 29372 
s 1026
f 29359 21990 22387 
f 21990 29359 29360 
s 1040
f 29421 22527 22171 
s 1026
f 22527 29421 29400 
f 29396 29404 22784 
s 1040
f 21929 22784 29404 
f 32897 29396 21927 
s 1026
f 22784 21927 29396 
f 29381 21713 22579 
s 1040
f 21713 29381 33353 
s 1026
f 29377 29378 22642 
f 21696 22642 29378 
f 22642 21698 29377 
s 1040
f 33371 29377 21698 
s 1026
f 29349 22174 22001 
s 1040
f 22174 29349 29427 
f 27574 21836 29369 
s 1026
f 22391 29369 21836 
f 29410 22001 32810 
f 22001 29410 29349 
f 21928 21929 29417 
s 1040
f 29404 29417 21929 
s 1026
f 21894 25632 21896 
s 1040
f 32710 33769 25632 
s 1026
f 22579 29497 29381 
f 29497 22579 21714 
s 1040
f 21919 27888 22148 
s 1026
f 21917 22148 27888 
f 22212 25635 22211 
s 1040
f 25635 22212 25709 
s 1026
f 22415 27884 21828 
f 27884 22415 29306 
s 1040
f 21913 22495 29170 
s 1026
f 24019 29170 22495 
s 1040
f 29168 22716 21680 
s 1026
f 22716 29168 25838 
s 1040
f 29167 21726 22589 
s 1026
f 21726 29167 21725 
s 1040
f 29166 22596 21730 
s 1026
f 22596 29166 29253 
f 29169 22197 22014 
s 1040
f 22197 29169 22015 
f 27936 21641 29563 
s 1026
f 29276 29563 21641 
f 29543 21983 27939 
s 1040
f 21983 29543 32953 
f 29562 21734 27938 
s 1026
f 21734 29562 29278 
f 29604 21626 24647 
f 21626 29604 29222 
f 29255 21643 25646 
f 33207 25646 21643 
f 27579 22575 29249 
s 1040
f 25689 29249 22575 
s 1026
f 27557 22382 29245 
f 22330 29245 22382 
f 29240 22014 22013 
f 22014 29240 29169 
f 25695 29234 21846 
f 27572 21846 29234 
f 29229 21680 28042 
s 1040
f 21680 29229 29168 
s 1026
f 28043 21800 29227 
s 1040
f 25688 29227 21800 
f 21730 29224 29166 
s 1026
f 29224 21730 25839 
f 29236 22423 27559 
s 1040
f 22423 29236 21722 
s 1026
f 22155 29213 22064 
f 29213 22155 27561 
f 27586 22425 29208 
s 1040
f 21716 29208 22425 
f 29214 22389 21839 
s 1026
f 22389 29214 29182 
s 1040
f 22335 22487 29209 
s 1026
f 29181 29209 22487 
f 23701 21913 29250 
s 1040
f 29170 29250 21913 
f 29251 22354 27535 
s 1026
f 22354 29251 22353 
s 1040
f 29248 22348 27537 
s 1026
f 22348 29248 22347 
f 29241 21710 23700 
s 1040
f 21710 29241 22235 
f 29243 22360 27547 
s 1026
f 22360 29243 22359 
s 1040
f 27548 22356 29242 
s 1026
f 22355 29242 22356 
s 1040
f 21943 22427 29231 
s 1026
f 21945 29231 22427 
f 29228 22344 27543 
s 1040
f 22344 29228 22343 
s 1026
f 22053 22054 29226 
s 1040
f 22338 29226 22054 
s 1026
f 27546 22350 29225 
s 1040
f 22349 29225 22350 
f 29221 22346 27542 
s 1026
f 22346 29221 22345 
s 1040
f 22029 29215 22189 
s 1026
f 29215 22029 25835 
s 1040
f 27538 22342 29212 
s 1026
f 22341 29212 22342 
s 1040
f 22589 29216 29167 
s 1026
f 29216 22589 21727 
f 21628 22432 29207 
s 1040
f 21630 29207 22432 
s 1026
f 22351 29247 22352 
s 1040
f 27536 22352 29247 
s 1026
f 27540 22362 29220 
s 1040
f 22361 29220 22362 
s 1026
f 21580 22593 29211 
s 1040
f 24870 29211 22593 
s 1026
f 22357 29233 22358 
s 1040
f 27539 22358 29233 
s 1026
f 29176 29210 22223 
s 1040
f 22082 22223 29210 
f 22378 22302 29223 
s 1026
f 29175 29223 22302 
s 1040
f 29230 22257 22380 
s 1026
f 22257 29230 29178 
s 1040
f 22376 22266 29235 
s 1026
f 29179 29235 22266 
f 21772 22403 29237 
s 1040
f 24594 29237 22403 
s 1026
f 29219 22261 22374 
s 1040
f 22261 29219 29180 
s 1026
f 21823 25684 28035 
f 25684 21823 29532 
f 21836 25685 22391 
f 29611 22391 25685 
s 1040
f 32105 32086 29508 
s 1026
f 23469 29508 21764 
f 33793 25687 21831 
f 25814 21831 25687 
f 25270 25688 22405 
s 1040
f 21800 22405 25688 
f 22575 21751 25689 
s 1026
f 21748 25689 21751 
f 25690 22235 21709 
s 1040
f 22235 25690 21710 
s 1026
f 22427 25691 21945 
s 1040
f 33045 21945 25691 
s 1026
f 29531 22338 25692 
s 1040
f 22054 25692 22338 
f 22432 25693 21630 
s 1026
f 21629 21630 25693 
s 1040
f 22189 25694 22029 
s 1026
f 25694 22189 29530 
s 1040
f 21848 25695 21968 
s 1026
f 21846 21968 25695 
s 1040
f 21863 25696 29342 
s 1026
f 25696 21863 25607 
f 22382 25697 22330 
s 1040
f 29534 22330 25697 
f 21722 25698 22423 
s 1026
f 25698 21722 29535 
f 22064 25699 22155 
s 1040
f 25699 22064 29533 
f 22425 25700 21716 
s 1026
f 29536 21716 25700 
f 29478 22316 27522 
s 1040
f 27883 27522 22316 
f 27881 27520 22443 
s 1026
f 29375 22443 27520 
s 1040
f 22440 27521 27882 
s 1026
f 27521 22440 29479 
s 1040
f 21948 27519 27880 
s 1026
f 27519 21948 27021 
s 1040
f 25845 22459 25705 
s 1026
f 22938 25705 22459 
s 1040
f 25706 22583 22042 
s 1026
f 22583 25706 22582 
s 1040
f 25843 22096 25707 
s 1026
f 22097 25707 22096 
s 1040
f 25708 22094 25813 
s 1026
f 22094 25708 25269 
s 1040
f 25709 22413 25635 
s 1026
f 22413 25709 22498 
f 25710 22449 25812 
s 1040
f 22449 25710 22584 
f 25840 21902 25711 
s 1026
f 21688 25711 21902 
s 1040
f 22406 25712 22407 
s 1026
f 25841 22407 25712 
s 1040
f 21669 25713 22131 
s 1026
f 25842 22131 25713 
s 1040
f 21915 25714 21832 
s 1026
f 29274 21832 25714 
s 1040
f 21925 25715 21882 
s 1026
f 25715 21925 29275 
f 29423 22153 27565 
s 1040
f 22153 29423 22060 
s 1026
f 29498 29308 21820 
s 1040
f 25252 21820 29308 
f 22531 21785 29489 
s 1026
f 29482 29489 21785 
s 1040
f 29434 22322 22370 
s 1026
f 22322 29434 29483 
s 1040
f 22368 22324 29389 
s 1026
f 29485 29389 22324 
s 1040
f 29471 22124 22112 
s 1026
f 22124 29471 29477 
s 1040
f 21760 22573 29436 
s 1026
f 29462 29436 22573 
f 29430 22491 21872 
f 22491 29430 29412 
s 1040
f 29397 22409 21869 
s 1026
f 22409 29397 29341 
f 27931 25725 22214 
s 1040
f 32804 22214 25725 
f 22090 22106 29488 
s 1026
f 22088 29488 22106 
f 29474 20785 20781 
s 1040
f 20785 29474 29876 
f 22640 21702 29468 
s 1026
f 27025 29468 21702 
f 29457 22112 27578 
s 1040
f 22112 29457 29471 
s 1026
f 29460 21974 21843 
f 21974 29460 29419 
f 29466 22370 27587 
s 1040
f 22370 29466 29434 
s 1026
f 29467 22126 21782 
s 1040
f 22126 29467 21781 
s 1026
f 21759 29462 21758 
f 22573 21758 29462 
f 29383 20906 22820 
s 1040
f 20906 29383 20916 
s 1026
f 21944 21943 29443 
s 1040
f 29231 29443 21943 
s 1026
f 29361 22163 22118 
s 1040
f 22163 29361 29385 
s 1026
f 29422 33555 33491 
s 1040
f 29436 29422 21760 
s 1026
f 22401 29437 29454 
s 1040
f 29437 22401 21965 
f 32763 29347 22135 
s 1026
f 22563 22135 29347 
f 21996 21995 29432 
s 1040
f 29424 29432 21995 
s 1026
f 20862 20863 29425 
s 1040
f 33394 29425 20863 
f 21936 29429 29408 
s 1026
f 29429 21936 21935 
f 29340 21949 33173 
f 21949 29340 22445 
f 22071 22072 29379 
s 1040
f 29409 29379 22072 
f 29484 21756 21810 
s 1026
f 21756 29484 27012 
s 1040
f 22364 22308 29426 
s 1026
f 29481 29426 22308 
f 29415 29424 22084 
s 1040
f 21995 22084 29424 
f 22072 22229 29409 
s 1026
f 22070 29409 22229 
f 29405 21810 23693 
s 1040
f 21810 29405 29484 
s 1026
f 22831 20799 29414 
s 1040
f 20809 29414 20799 
s 1026
f 22084 23724 29415 
s 1040
f 32972 29415 23724 
s 1026
f 29403 21746 33042 
f 21746 29403 29364 
s 1040
f 29406 21899 22022 
s 1026
f 21899 29406 24076 
s 1040
f 29408 22826 21936 
s 1026
f 22826 29408 29392 
f 29407 22046 22044 
s 1040
f 22046 29407 29398 
f 29389 29401 22368 
s 1026
f 27588 22368 29401 
f 29402 22050 22048 
s 1040
f 22050 29402 29395 
f 21593 21875 29384 
s 1026
f 27017 29384 21875 
f 33074 23692 29352 
f 29388 29352 23692 
s 1040
f 29398 22613 22046 
s 1026
f 22613 29398 29469 
s 1040
f 29395 22611 22050 
s 1026
f 22611 29395 29470 
f 29441 22022 23976 
s 1040
f 22022 29441 29406 
s 1026
f 22201 22202 29382 
s 1040
f 22215 29382 22202 
s 1026
f 29390 21932 21931 
s 1040
f 21932 29390 29370 
s 1026
f 29392 21937 22826 
s 1040
f 21937 29392 33034 
s 1026
f 21940 21941 29387 
s 1040
f 29374 29387 21941 
s 1026
f 23692 21593 29388 
s 1040
f 29384 29388 21593 
f 29376 29386 21806 
s 1026
f 27568 21806 29386 
f 22009 29380 22165 
f 29380 22009 33297 
f 29365 29374 22816 
s 1040
f 21941 22816 29374 
f 32916 29375 22139 
s 1026
f 27520 22139 29375 
s 1040
f 29371 21843 25794 
s 1026
f 21843 29371 29460 
f 29373 20759 20755 
s 1040
f 20759 29373 33365 
f 29370 22814 21932 
s 1026
f 22814 29370 29351 
s 1040
f 22493 29364 29490 
s 1026
f 29364 22493 21746 
f 29354 29362 21700 
f 32995 21700 29362 
f 29368 20841 22848 
s 1040
f 20841 29368 20851 
s 1026
f 29456 21965 21963 
s 1040
f 21965 29456 29437 
s 1026
f 29346 23693 32949 
f 23693 29346 29405 
f 22134 22133 29367 
s 1040
f 29357 29367 22133 
s 1026
f 21700 22640 29354 
s 1040
f 29468 29354 22640 
f 32808 29365 21939 
s 1026
f 22816 21939 29365 
f 22858 20708 29358 
s 1040
f 20718 29358 20708 
f 22133 22563 29357 
s 1026
f 29347 29357 22563 
s 1040
f 29385 22117 22163 
s 1026
f 22117 29385 29473 
f 22855 20734 29348 
s 1040
f 20744 29348 20734 
f 29343 22008 25809 
s 1026
f 22008 29343 27033 
s 1040
f 22366 22312 29344 
s 1026
f 29480 29344 22312 
s 1040
f 29342 22604 21863 
s 1026
f 22604 29342 29487 
s 1040
f 29491 29345 22032 
s 1026
f 23723 22032 29345 
f 33241 23723 29350 
f 29345 29350 23723 
f 29351 21933 22814 
s 1040
f 21933 29351 32870 
f 29490 21747 22493 
s 1026
f 21747 29490 27016 
f 21842 25794 32809 
s 1040
f 25794 21842 29371 
s 1026
f 29412 21873 22491 
s 1040
f 21873 29412 33648 
f 29355 21872 28045 
s 1026
f 21872 29355 29430 
f 21868 29341 21866 
f 29341 21868 22409 
f 27013 29491 22167 
s 1040
f 22032 22167 29491 
f 21806 22521 29376 
s 1026
f 29455 29376 22521 
f 22138 27881 22137 
s 1040
f 22443 22137 27881 
s 1026
f 22445 27880 21949 
s 1040
f 27880 22445 21948 
f 29426 29431 22364 
s 1026
f 27584 22364 29431 
f 27589 22366 29356 
s 1040
f 29344 29356 22366 
f 29427 22000 22174 
s 1026
f 22000 29427 27028 
f 21820 22225 29498 
f 29453 29498 22225 
f 27583 27883 22372 
s 1040
f 22316 22372 27883 
s 1026
f 27889 22060 29304 
s 1040
f 22060 27889 22153 
s 1026
f 21743 27882 27567 
s 1040
f 27882 21743 22440 
s 1026
f 22165 25809 22009 
s 1040
f 25809 22165 29343 
f 22456 22036 25810 
s 1026
f 22037 25810 22036 
f 22498 27935 22413 
s 1040
f 27935 22498 32250 
s 1026
f 21764 25812 23469 
f 25812 21764 25710 
f 21667 25813 23356 
s 1040
f 25813 21667 25708 
s 1026
f 25687 21915 25814 
s 1040
f 21832 25814 21915 
s 1026
f 25815 22042 22040 
s 1040
f 22042 25815 25706 
s 1026
f 22106 27885 22088 
f 29307 22088 27885 
s 1040
f 22202 27931 22215 
s 1026
f 22214 22215 27931 
s 1040
f 21781 27932 22126 
s 1026
f 27932 21781 29303 
f 21824 29165 33473 
s 1040
f 29165 21824 22419 
s 1026
f 21879 25820 33780 
f 25820 21879 25844 
f 29596 22554 22553 
s 1040
f 22554 29596 29218 
s 1026
f 27569 22378 29606 
s 1040
f 29223 29606 22378 
s 1026
f 29603 22374 27590 
f 22374 29603 29219 
f 27573 22082 29609 
s 1040
f 29210 29609 22082 
s 1026
f 29237 29614 21772 
f 27582 21772 29614 
f 29590 22497 21852 
s 1040
f 22497 29590 29252 
s 1026
f 29211 29600 21580 
f 25221 21580 29600 
f 29608 22380 27580 
s 1040
f 22380 29608 29230 
s 1026
f 27563 22376 29594 
s 1040
f 29235 29594 22376 
s 1026
f 29591 21727 33314 
f 21727 29591 29216 
s 1040
f 29246 29599 22217 
s 1026
f 25855 22217 29599 
f 29602 21839 27585 
s 1040
f 21839 29602 29214 
s 1026
f 27581 22335 29598 
s 1040
f 29209 29598 22335 
s 1026
f 29207 29597 21628 
f 33200 21628 29597 
f 22028 25835 33140 
f 25835 22028 29215 
f 33031 33324 25836 
s 1040
f 22015 25836 22197 
f 33267 24019 25837 
s 1026
f 22495 25837 24019 
f 25838 22715 22716 
s 1040
f 22715 25838 33608 
s 1026
f 21728 25839 33685 
f 25839 21728 29224 
s 1040
f 25711 21686 25840 
s 1026
f 23402 25840 21686 
f 22205 25841 22206 
f 25712 22206 25841 
f 23401 25842 21671 
f 25713 21671 25842 
s 1040
f 25707 22146 25843 
s 1026
f 22098 25843 22146 
f 25844 21882 25820 
s 1040
f 21882 25844 21925 
s 1026
f 21953 25845 21952 
s 1040
f 25705 21952 25845 
f 22591 21684 29254 
s 1026
f 24073 29254 21684 
f 24072 29232 21891 
s 1040
f 22179 21891 29232 
s 1026
f 21795 22470 29238 
s 1040
f 21793 29238 22470 
s 1026
f 22227 29239 22226 
s 1040
f 29239 22227 22078 
f 29217 22183 21738 
s 1026
f 22183 29217 22182 
f 29244 22340 27545 
s 1040
f 22340 29244 22339 
s 1026
f 29206 22468 21634 
s 1040
f 22468 29206 21633 
s 1026
f 22518 22456 25853 
s 1040
f 25810 25853 22456 
s 1026
f 21709 25854 25690 
s 1040
f 25854 21709 33226 
s 1026
f 29599 22026 25855 
f 23282 25855 22026 
f 21886 25856 28051 
s 1040
f 25856 21886 29448 
f 21633 25857 22468 
s 1026
f 25857 21633 21632 
s 1040
f 22470 25858 21793 
s 1026
f 21794 21793 25858 
f 22226 25859 22227 
s 1040
f 25859 22226 33138 
s 1026
f 22182 25860 22183 
s 1040
f 25860 22182 32842 
s 1026
f 25861 21790 25884 
s 1040
f 21790 25861 21789 
s 1026
f 29420 21651 28049 
s 1040
f 21651 29420 29435 
s 1026
f 28053 21646 29475 
s 1040
f 29458 29475 21646 
s 1026
f 21705 29339 29486 
f 29339 21705 33731 
f 29486 22646 21705 
s 1040
f 22646 29486 29472 
f 29472 21706 22646 
s 1026
f 21706 29472 27020 
f 29438 29458 22529 
s 1040
f 21646 22529 29458 
s 1026
f 29439 22171 22169 
s 1040
f 22171 29439 29421 
s 1026
f 22695 21976 29440 
s 1040
f 33466 29440 21976 
s 1026
f 22384 29448 29391 
s 1040
f 29448 22384 25856 
s 1026
f 22529 21648 29438 
s 1040
f 33235 29438 21648 
s 1026
f 29440 29442 22695 
f 21978 22695 29442 
s 1040
f 21977 21978 29418 
s 1026
f 29442 29418 21978 
s 1040
f 29435 22644 21651 
s 1026
f 22644 29435 29363 
s 1040
f 29393 29413 21658 
s 1026
f 28048 21658 29413 
s 1040
f 29416 21828 28050 
s 1026
f 21828 29416 22415 
s 1040
f 22170 29400 33000 
s 1026
f 29400 22170 22527 
f 29399 29393 22635 
s 1040
f 21658 22635 29393 
s 1026
f 29363 21652 22644 
s 1040
f 21652 29363 33022 
s 1026
f 25881 22531 29411 
s 1040
f 29489 29411 22531 
s 1026
f 33225 25881 22530 
f 29411 22530 25881 
f 22582 27933 22583 
s 1040
f 27933 22582 32081 
f 32097 22938 27934 
s 1026
f 22459 27934 22938 
f 22466 25884 21791 
f 25884 22466 25861 
f 29593 21634 33271 
f 21634 29593 29206 
f 29610 22078 24664 
s 1040
f 22078 29610 29239 
f 21738 29601 29217 
s 1026
f 29601 21738 24665 
f 21893 22179 29595 
s 1040
f 29232 29595 22179 
s 1026
f 24313 22591 29592 
s 1040
f 29254 29592 22591 
s 1026
f 25890 32833 32793 
f 29595 25890 21893 
s 1040
f 33041 21794 25891 
s 1026
f 25858 25891 21794 
s 544
f 33744 33467 28620 
f 33468 33745 25306 
s 1040
f 33611 33711 28126 
s 1026
f 21678 25893 27779 
f 25496 25894 23310 
s 1040
f 25894 33493 33622 
s off
f 22597 30053 25895 
f 31665 25895 30053 
f 30054 25896 31666 
f 25896 30054 22598 
s 544
f 21616 33066 33392 
s 516
f 33454 33727 23117 
s 64
f 21163 21442 25898 
s off
f 20970 25899 30055 
f 21412 30055 25899 
f 25900 30056 21411 
f 20971 30056 25900 
s 1026
f 25901 33026 33761 
s 1040
f 25901 21619 32107 
s off
f 30956 31047 21033 
f 31619 31047 29988 
f 21032 31044 30955 
f 31618 29985 31044 
s 544
f 33769 33544 27770 
f 32054 33768 24628 
s off
f 32316 25905 31372 
f 31365 31372 25905 
f 31373 25906 32284 
f 25906 31373 31364 
s 544
f 26797 33762 33654 
f 25907 33278 33048 
s 1026
f 23645 28132 25908 
s 1040
f 32735 25908 28132 
s 516
f 25352 23138 25909 
s 544
f 28235 32944 32936 
s 1026
f 28113 28518 25910 
s 1040
f 32095 25910 28518 
s off
f 25006 21248 25911 
f 25912 21247 25005 
s 544
f 23416 25913 33390 
f 25273 33419 33496 
s 4128
f 29080 25914 30307 
s 8194
f 29621 25914 28075 
s 4128
f 30308 25915 29079 
s 8194
f 28074 25915 29620 
s 64
f 21404 31463 21160 
s 516
f 33422 33426 26725 
f 25917 23530 26441 
f 25918 22039 26443 
s 544
f 32337 32338 26592 
s 516
f 33494 33732 25919 
s 544
f 33117 33719 23056 
s 516
f 33461 33736 25920 
s 544
f 23543 33065 33395 
f 33682 33824 24015 
f 21788 25921 26869 
s 516
f 33332 32665 26623 
f 25922 21623 24278 
f 33355 33758 24267 
f 25923 22012 24157 
f 26533 33537 33821 
f 22460 23532 25924 
s 544
f 24270 23460 25925 
f 26637 33659 33517 
s 516
f 25926 21740 22439 
s 544
f 32383 32539 26720 
f 26543 32761 32754 
s 516
f 24263 23497 25927 
s 544
f 26643 33389 33387 
f 24166 23444 25928 
s 516
f 24218 23451 25929 
s 544
f 26634 33614 32979 
f 33119 32658 26669 
s 516
f 25930 23454 24201 
f 26800 33650 32678 
f 22463 22717 25931 
s 544
f 33373 32672 26678 
f 25932 23473 22790 
f 26570 32851 32841 
s 516
f 24242 23447 25933 
f 25934 23983 24264 
s 544
f 32819 33594 26541 
f 25935 23452 29643 
f 33415 33749 26650 
f 33086 33076 26761 
s 516
f 25936 23462 24137 
f 24247 33056 33624 
f 26547 25937 23458 
s 544
f 32955 33772 25086 
s 516
f 25938 23553 24117 
f 22093 25939 26877 
f 32853 32839 24291 
s 544
f 32993 33001 23608 
s 516
f 25940 22698 24146 
s 544
f 32925 33708 26646 
s 516
f 25941 23455 24202 
f 22462 23535 25942 
s 544
f 26763 33831 32564 
f 33293 32660 26736 
s 516
f 25943 23450 24248 
f 25944 23459 24238 
s 544
f 32875 32633 26642 
s 516
f 23474 25945 29655 
f 33702 33789 23732 
f 23443 25946 26673 
f 33806 33191 24164 
f 23453 25947 26532 
f 33082 33108 24203 
s 544
f 26675 32571 32952 
s 516
f 24134 23463 25948 
f 24925 32900 32917 
f 26754 25949 21792 
f 32504 33129 24306 
f 22655 25950 26587 
f 25951 23048 23435 
s 544
f 32971 33797 26682 
f 32863 32627 26709 
s 516
f 25952 23615 22911 
f 23732 25953 33702 
s 544
f 24536 33812 32807 
s 516
f 26658 25954 21640 
f 24307 33248 33264 
f 23433 21673 25955 
s 544
f 26640 33135 33121 
f 33294 33307 26751 
s 516
f 25956 23448 24234 
s 544
f 26813 33478 33476 
f 24283 23541 25957 
s 516
f 20855 25958 22198 
f 21887 33567 32734 
f 26812 25959 21757 
f 25087 33623 33133 
s 544
f 26727 32607 33116 
s 516
f 22912 23616 25960 
s 544
f 32919 32924 26585 
s 516
f 25961 23449 24220 
f 29650 25962 23496 
f 28067 33676 33677 
f 24236 23446 25963 
s 544
f 26745 33633 33104 
f 24276 22105 25964 
f 26524 33344 33644 
s 516
f 26555 25965 21627 
f 24296 33291 33304 
s 544
f 32746 32750 26540 
s 516
f 25966 23540 22755 
f 23442 25967 26605 
f 33132 33311 22910 
s 544
f 29645 23456 25968 
f 26649 33533 33527 
s 516
f 25969 21649 24181 
s 544
f 33043 33720 26702 
f 26668 33609 32943 
s 516
f 24286 23461 25970 
f 21914 32905 33796 
f 26876 25971 21829 
s 544
f 26729 33625 33137 
s 516
f 24180 22693 25972 
s 544
f 26645 32499 32381 
s 516
f 22458 21951 25973 
f 24297 21579 25974 
f 26590 32669 33349 
f 26828 25975 23531 
f 25090 32655 32656 
s 544
f 26712 32647 33020 
s 516
f 24233 23457 25976 
s 544
f 26633 32491 32981 
s 516
f 24172 21657 25977 
s 544
f 32912 33707 26639 
s 516
f 25978 23445 24223 
f 23529 25979 24268 
f 32653 32587 25088 
f 23609 33276 33292 
f 24271 25980 23498 
s off
f 22702 30303 21085 
f 21085 25981 30257 
f 30256 25982 21148 
f 21148 30304 22701 
f 22187 25983 30251 
f 25983 22187 24861 
f 24862 22186 25984 
f 30250 25984 22186 
f 23047 30846 24587 
f 24532 30846 23047 
f 22974 25986 21122 
f 23527 21122 31898 
f 31897 21123 23528 
f 21123 25987 22973 
f 22976 25988 21237 
f 23533 21237 31895 
f 21236 25989 22975 
f 31896 21236 23534 
f 21210 25990 30291 
f 32021 25990 29750 
f 32017 29748 25991 
f 30289 25991 21211 
f 21206 25992 30288 
f 32019 25992 29743 
f 32023 29754 25993 
f 30293 25993 21209 
f 32024 25994 29753 
f 21208 25994 30294 
f 21415 30375 21090 
f 30284 25995 21415 
f 21416 25996 30283 
f 21091 30374 21416 
f 30285 25997 21207 
f 32022 29752 25997 
f 23188 21204 30370 
f 30290 25998 21204 
f 30371 21205 23189 
f 21205 25999 30292 
f 30286 26000 20997 
f 20997 30373 22978 
f 20998 26001 30287 
f 22977 30372 20998 
f 26002 21612 21088 
f 21612 26002 30277 
f 21087 21611 26003 
f 30278 26003 21611 
s 1
f 20942 21635 26004 
s off
f 30272 26004 21635 
f 21636 26005 30273 
s 1
f 20949 26005 21636 
s off
f 22549 26006 21039 
f 28558 26006 23704 
f 23703 26007 28557 
f 21408 26007 27991 
f 24106 26008 21317 
f 23695 31669 26008 
f 22471 26009 28579 
f 22524 26009 21140 
f 22220 26010 28590 
f 28615 26010 22220 
f 28580 26011 22471 
f 22471 26011 21375 
f 28599 26012 22221 
f 22221 26012 28583 
s 64
f 32269 30942 31408 
f 32269 30941 31409 
s 544
f 32051 32577 28618 
s 516
f 23055 26015 32706 
f 27232 33316 33185 
f 24378 23558 26016 
f 23360 33340 33354 
f 27238 26017 21581 
f 27218 26018 23064 
s 544
f 23875 32752 33593 
s 516
f 27258 26019 23153 
s 544
f 23845 32798 33596 
s 516
f 27320 32580 33093 
f 23798 23078 26020 
f 27443 26021 21797 
s 544
f 23477 33627 33590 
f 33635 33011 23857 
s 516
f 23155 26022 27353 
f 33318 33458 24411 
f 23070 26023 27171 
s 544
f 24381 33019 33238 
s 516
f 27412 26024 23150 
f 23476 32522 32526 
f 27150 26025 21771 
f 27213 32421 33287 
f 25309 22141 26026 
s 544
f 32670 33655 25279 
f 23730 26027 27267 
s 516
f 26028 21857 23769 
f 32887 33570 27383 
f 26029 21719 24384 
f 32371 32493 27252 
f 24385 21715 26030 
f 27201 32544 32343 
s 544
f 33713 32947 23899 
s 516
f 21981 26031 27322 
f 24413 26032 21685 
s 544
f 24111 32820 33827 
s 516
f 23811 21681 26033 
f 27222 33280 33163 
f 26034 21732 23901 
f 33024 33148 27181 
f 26035 21779 27054 
f 33615 33773 27444 
s 544
f 33757 33484 25280 
f 23224 26036 29730 
s 516
f 33573 32621 27316 
f 26037 21897 23865 
f 27323 26038 23149 
s 544
f 23818 32881 32954 
s 516
f 23157 26039 27387 
s 544
f 32712 32973 23908 
s 516
f 33296 32704 27432 
f 26040 23557 23562 
f 33427 33411 24459 
f 21761 26041 24405 
f 32519 32347 25286 
f 23666 26042 27270 
f 27461 26043 23065 
s 544
f 23853 33602 33617 
s 516
f 23731 26044 27339 
s 544
f 32984 33698 25303 
f 24398 32789 32631 
s 516
f 27281 26045 23154 
f 23554 26046 27144 
s 544
f 33734 33126 24376 
s 516
f 21664 26047 24406 
s 544
f 33829 33038 23000 
f 33716 33709 23903 
s 516
f 21677 26048 27354 
s 544
f 33073 33205 25071 
s 516
f 21809 26049 27390 
f 23664 26050 27327 
s 544
f 32604 32637 25285 
f 25283 33508 33529 
f 29733 26051 23222 
s 516
f 33244 33723 27178 
f 26052 23556 24387 
f 27449 26053 23221 
s 544
f 25305 33211 33566 
s 516
f 23074 26054 27401 
s 544
f 32780 32757 25284 
s 516
f 23156 26055 27266 
s 544
f 33605 32865 23838 
s 516
f 23223 26056 27348 
s 544
f 33638 33183 25281 
f 27314 26057 23158 
f 23481 33463 32686 
f 32674 33362 23849 
f 23152 26058 29729 
s 516
f 23555 26059 27437 
f 32666 33530 24388 
f 23106 26060 27231 
s 544
f 32654 33092 25073 
s 516
f 26061 33775 33764 
s 544
f 26061 23671 33752 
s 516
f 28618 32584 33026 
f 29728 23665 26062 
s 544
f 32450 32790 25304 
s 516
f 23076 26063 27217 
f 24414 26064 21668 
f 24108 33532 33538 
s 544
f 25072 33619 32779 
s 516
f 27374 26065 21592 
f 27366 26066 22023 
f 25277 33542 33651 
f 27156 26067 21645 
s 544
f 23917 33807 32545 
s 516
f 33159 33198 27157 
f 26068 23151 24397 
f 23583 21890 26069 
f 27168 32636 32813 
f 24417 26070 23073 
s 544
f 24422 32340 32342 
f 25278 32664 33645 
f 27129 26071 23075 
s 8194
f 22003 29789 26077 
s 2147483647
f 26072 29914 29620 
s 8194
f 26076 29790 22005 
s 2147483647
f 29621 29915 26073 
s 516
f 26074 23542 32726 
s 544
f 23542 26074 33048 
s off
f 26075 21865 21382 
f 21865 26075 28584 
s 8194
f 22005 24363 26076 
s 8208
f 25603 26076 24363 
s 8194
f 26077 24364 22003 
s 8208
f 24364 26077 25602 
s 1026
f 26078 21898 25499 
s 1040
f 21898 26078 32787 
s 1026
f 26079 33681 33568 
f 27772 26079 21799 
s 1040
f 32990 26080 23392 
s 1026
f 25449 23392 26080 
f 25339 23357 26081 
s 1040
f 32739 26081 23357 
s 516
f 33431 33725 26082 
s 544
f 33040 24643 26082 
s 516
f 21943 21944 26083 
s 544
f 27769 33096 33057 
s 516
f 27776 33348 32668 
f 25855 23282 26084 
f 33324 33738 27708 
f 26085 22013 22014 
s 544
f 27726 33272 33254 
s 516
f 22181 21970 26086 
f 21947 26087 25704 
f 33180 33173 21949 
s 544
f 27621 33298 33285 
s 516
f 21913 23701 26088 
s 544
f 24927 21961 26089 
f 27876 33519 33516 
f 26090 22108 24928 
f 33366 33368 27660 
f 32874 32878 27602 
s 516
f 26091 21931 21932 
s 544
f 22106 22090 26092 
f 27623 33642 33336 
s 516
f 23975 26093 27844 
f 32831 32809 25794 
s 544
f 27639 32816 32811 
s 516
f 21941 21940 26094 
s 544
f 33202 33212 27781 
s 516
f 26095 21963 21965 
s 544
f 27797 32772 32767 
s 516
f 22133 22134 26096 
s 544
f 26080 32990 32895 
s 516
f 22072 22071 26097 
f 22202 22201 26098 
s 544
f 27615 32822 32812 
f 27699 32915 32907 
s 516
f 21929 21928 26099 
s 544
f 32765 32766 27603 
s 516
f 26100 22118 22163 
f 26101 24665 21738 
s 544
f 32845 32849 27651 
s 516
f 26102 23976 22022 
s 544
f 32774 32787 26078 
f 32888 32891 27679 
s 516
f 26103 22048 22050 
f 21731 26104 27684 
f 33584 33685 25839 
s 544
f 33013 33039 27714 
s 516
f 26105 22169 22171 
f 24666 26106 27777 
f 33279 33271 21634 
f 27687 26107 21798 
f 28043 33571 33681 
f 23757 26108 27783 
f 32823 32810 22001 
f 29776 26109 22020 
f 28633 33672 32707 
f 21864 26110 27605 
f 33081 33085 25607 
f 22030 26111 27612 
f 33158 33140 25835 
f 32962 32949 23693 
f 24452 26112 27763 
s 544
f 33536 33319 27678 
f 26113 21957 24929 
s 516
f 23699 26114 27842 
f 33804 33731 21705 
f 21628 33200 33210 
f 27658 26115 24661 
s 544
f 33247 33263 27787 
s 516
f 26116 23700 21710 
f 24451 26117 27594 
f 33054 33042 21746 
f 27695 26118 21660 
f 28048 33688 33783 
s 544
f 27736 32985 32980 
s 516
f 21995 21996 26119 
s 544
f 27725 33153 33072 
s 516
f 22470 21795 26120 
s 544
f 27808 33470 33469 
f 21978 21977 26121 
f 24932 24450 26122 
f 27790 33402 33396 
f 21696 23697 26123 
f 27672 33378 33375 
f 33357 33358 27680 
f 26124 23696 21714 
f 33323 33326 27636 
f 26125 21991 22387 
s 516
f 27877 26126 22034 
f 23723 33241 33250 
s 544
f 33243 33251 27809 
s 516
f 26127 21782 22126 
f 23692 33074 33234 
f 27709 26128 23758 
f 25810 22037 26129 
s 544
f 25908 32735 32732 
f 33143 33152 27723 
s 516
f 26130 24664 22078 
s 544
f 33047 33059 27694 
s 516
f 26131 21935 21936 
f 23466 26132 21674 
f 27905 33029 33128 
f 21700 32995 33005 
f 27849 26133 23698 
f 27874 26134 23712 
f 25881 33225 33232 
s 544
f 27664 32941 32930 
s 516
f 27881 22138 26135 
s 544
f 32899 32906 27683 
s 516
f 26136 22044 22046 
s 544
f 27650 26137 21582 
f 25221 32744 32748 
f 26081 32739 32733 
s 516
f 22591 24313 26138 
f 32694 33541 27635 
f 26139 24647 21626 
f 24659 26140 27690 
f 33317 33314 21727 
f 24449 26141 27614 
f 33309 33297 22009 
s 544
f 27704 32927 32921 
s 516
f 22054 22053 26142 
f 26143 23092 27924 
f 32749 33780 25820 
f 25687 33793 32882 
f 27927 23232 26144 
f 25646 33207 33213 
f 27868 23053 26145 
f 27893 24455 26146 
s 544
f 25910 32095 32170 
f 25883 32251 32066 
s 516
f 25845 21953 26147 
f 26148 22121 27891 
s 544
f 32154 32082 24425 
f 32087 32166 25811 
s 516
f 26149 22211 25635 
f 26150 22040 22042 
s 544
f 32063 32266 25882 
s off
f 30444 21314 23673 
f 21314 26151 30382 
f 23674 21196 30445 
f 30383 26152 21196 
f 30434 21215 23668 
f 21215 26153 30402 
f 23667 21279 30435 
f 30403 26154 21279 
f 23677 21500 30436 
f 30400 26155 21500 
f 21499 26156 30395 
f 30437 21499 23682 
f 23678 21392 30440 
f 30393 26157 21392 
f 23680 21267 30443 
f 30389 26158 21267 
f 30394 26159 21297 
f 23687 21297 30439 
f 30391 26160 21502 
f 23681 21502 30447 
f 30392 26161 21171 
f 23684 21171 30449 
f 30451 21497 23676 
f 21497 26162 30385 
f 30438 21503 23675 
f 21503 26163 30388 
f 30442 21296 23688 
f 21296 26164 30396 
f 23679 21496 30452 
f 30384 26165 21496 
f 30455 21501 23683 
f 21501 26166 30390 
f 30454 21142 23686 
f 21142 26167 30397 
f 21386 30448 22931 
f 30387 26168 21386 
f 30401 26169 21384 
f 23669 21384 30441 
f 30446 21246 23685 
f 21246 26170 30398 
f 21385 26171 30386 
f 22932 30453 21385 
f 21278 26172 30399 
f 30450 21278 23670 
s 1026
f 28380 29901 23248 
s 2147483647
f 23248 29901 22019 
s 1026
f 28378 29907 23246 
s 2147483647
f 23246 29907 21878 
s 1026
f 23247 29911 28376 
s 2147483647
f 21856 29911 23247 
s 1026
f 23249 29910 28375 
s 2147483647
f 21816 29910 23249 
s 1026
f 28187 29903 23242 
s 2147483647
f 23242 29903 22855 
s 1026
f 23244 29913 28419 
s 2147483647
f 22820 29913 23244 
f 23241 29908 22831 
s 1026
f 28311 29908 23241 
f 28219 29906 23240 
s 2147483647
f 23240 29906 22858 
f 22848 29904 23243 
s 1026
f 23243 29904 28228 
f 26292 29905 23237 
s 2147483647
f 23237 29905 22231 
s 1026
f 23238 29900 28161 
s 2147483647
f 21896 29900 23238 
s 1026
f 26293 29912 23236 
s 2147483647
f 23236 29912 21987 
f 22892 29909 23245 
s 1026
f 23245 29909 28366 
f 23239 29902 26321 
s 2147483647
f 21980 29902 23239 
s off
f 26187 21388 21343 
f 21388 26187 30381 
f 21242 26188 21102 
f 30379 26188 21242 
f 21243 26189 30380 
f 21104 26189 21243 
f 21341 21389 26190 
f 30378 26190 21389 
s 516
f 29561 24075 26191 
f 22585 32100 32043 
f 32685 33473 29165 
f 21822 26192 29579 
s 544
f 26193 22584 25710 
f 32086 33794 25686 
s 516
f 24077 26194 22940 
s 544
f 32948 32953 29543 
f 33125 33134 29391 
s 516
f 21884 26195 29199 
s 544
f 33412 33648 29412 
f 21870 26196 25796 
s 516
f 29409 22070 26197 
f 25268 33233 33155 
f 26198 24076 29406 
f 32760 32613 22021 
f 25737 33620 33555 
f 29462 21759 26199 
f 29566 33174 33187 
f 29254 24073 26200 
f 29568 32824 32833 
f 29232 24072 26201 
f 22456 22518 26202 
f 25214 33079 33070 
s 544
f 33504 33510 29306 
f 21826 26203 25876 
f 29438 33235 33803 
s 516
f 25863 26204 24074 
s 544
f 29399 32890 32877 
s 516
f 25875 26205 21659 
s 544
f 33790 33022 29363 
s 516
f 21650 26206 25862 
f 24078 26207 25651 
s 544
f 33607 33608 25838 
s 516
f 25652 26208 24079 
s 544
f 25270 33632 33628 
s off
f 26209 23478 21672 
f 24711 30505 21672 
s 2147483647
f 24931 22879 30171 
s 1040
f 22878 30171 22879 
s 2147483647
f 24932 21889 30170 
s 1040
f 24510 30170 21889 
s 2147483647
f 30168 22863 24933 
s 1040
f 22863 30168 22862 
s 2147483647
f 24927 21960 30167 
s 1040
f 21959 30167 21960 
s 2147483647
f 30165 22109 24928 
s 1040
f 22109 30165 22107 
s 2147483647
f 30164 21958 24929 
s 1040
f 21958 30164 21956 
s 2147483647
f 30166 20798 24926 
s 8194
f 20798 30166 20790 
s 2147483647
f 24930 20899 30169 
s 8194
f 20897 30169 20899 
s 516
f 32333 26218 23116 
s 544
f 26218 33636 32052 
s off
f 23478 30586 30555 
f 29963 30555 20961 
f 23479 30621 30574 
f 30660 30574 21463 
f 21663 30548 29954 
f 22995 26221 21663 
f 21473 30560 22556 
f 22556 26222 24829 
f 21992 26223 23480 
f 21337 30553 21992 
f 21367 26224 22995 
f 22995 30557 23547 
f 22555 26225 21420 
f 21420 30578 30708 
f 22555 30556 21192 
f 24828 26226 22555 
f 30555 30601 30613 
f 30706 30613 21478 
f 22331 30581 30694 
f 30623 30581 30552 
f 30551 30612 30593 
f 30705 30612 22972 
f 30698 30604 20947 
f 23480 30580 30604 
f 30703 30606 21351 
f 21351 26231 22556 
f 21557 26232 24830 
f 30677 30568 21557 
f 30559 30616 30550 
f 22190 30616 29967 
f 30577 30618 30549 
f 20959 30618 30687 
f 21462 30625 30699 
f 30571 30625 30559 
f 21993 30602 21143 
f 24832 26236 21993 
f 21381 30598 22550 
f 30550 30598 30623 
f 30593 30632 30569 
f 30716 30632 21562 
f 30549 30600 30624 
f 22971 30600 30672 
f 30605 30609 30551 
f 30704 30609 21407 
f 26241 22576 26287 
f 21104 23004 30689 
f 30576 30689 23004 
f 30613 30706 23006 
f 21104 23006 30706 
f 30699 21161 22336 
f 21161 30699 30625 
f 30687 21454 29962 
f 21454 30687 30618 
f 21104 21921 30665 
f 23479 30665 21921 
f 30632 30716 21424 
f 22114 21424 30716 
f 21796 30685 21367 
f 30685 21796 30682 
f 29963 21055 30705 
f 30612 30705 21055 
f 30629 30717 21572 
f 22172 21572 30717 
f 30709 22242 22149 
f 22242 30709 30631 
f 30604 30698 21639 
f 30667 21639 30698 
f 21143 30647 21993 
f 30412 30647 21143 
f 29963 22571 30701 
f 30605 30701 22571 
f 30713 21573 22175 
f 21573 30713 30630 
f 21992 30658 21337 
f 21337 30658 24531 
f 30890 31570 31989 
f 31988 31989 31570 
f 31990 31571 30891 
f 31571 31990 31987 
f 22648 30894 25177 
f 30894 22648 20927 
f 25176 30895 22649 
f 20987 22649 30895 
s 544
f 32602 33391 25274 
f 26261 33690 33390 
s 64
f 30314 23393 21160 
s off
f 31792 31893 31049 
f 30877 31049 21064 
f 26264 30802 27947 
f 30802 26264 31476 
f 21201 26265 22990 
f 22990 26265 31899 
f 22991 26266 21202 
f 31900 26266 22991 
s 544
f 33760 33334 27707 
f 26267 33799 32053 
f 27657 33741 33363 
f 33040 33403 26268 
s 64
f 30731 31460 32334 
s off
f 30827 22987 21568 
f 22987 30827 30525 
f 30524 30828 22986 
f 21569 22986 30828 
f 30522 30825 22988 
f 20922 22988 30825 
f 30824 22989 20812 
f 22989 30824 30523 
f 21904 30627 20923 
f 21557 20923 26274 
f 21268 30622 22057 
f 26275 21268 21993 
f 26276 22567 21663 
f 22567 30619 23658 
f 21992 22566 26277 
f 21601 30607 22566 
f 26278 22568 21420 
f 22568 30637 21610 
f 21351 20962 26279 
f 22129 30635 20962 
f 26280 20936 22499 
f 20936 30599 21905 
f 31434 31973 31954 
f 31973 31434 30889 
f 31953 31974 31435 
f 30888 31435 31974 
f 31964 31983 31430 
f 30892 31430 31983 
f 31984 31429 30893 
f 31429 31984 31963 
f 31965 31986 31441 
f 30886 31441 31986 
f 31985 31440 30887 
f 31440 31985 31966 
f 26287 21505 26241 
s 8
f 21505 26287 21101 
f 21131 26288 22996 
s off
f 21512 22996 26288 
s 544
f 32797 32245 25583 
s 516
f 24089 26289 28475 
s 544
f 21813 32261 32273 
s 516
f 33765 33728 26290 
f 28480 26291 22144 
s 544
f 22143 32174 32983 
s 516
f 24593 32060 32294 
f 29905 26292 24094 
f 23116 32059 32333 
f 29912 26293 21986 
s 544
f 28393 33302 33290 
s 516
f 25566 24568 26294 
f 26295 24498 25458 
s 544
f 32894 32898 28254 
f 26296 24567 28515 
f 33360 33361 28269 
f 26297 23109 25466 
f 32702 33664 28298 
s 516
f 33400 33428 28379 
f 26298 24058 25403 
s 544
f 28342 33147 33127 
s 516
f 25441 24497 26299 
s 544
f 32768 32615 28170 
s 516
f 26300 24092 25477 
f 33095 33115 25537 
f 23539 26301 28166 
f 25431 24496 26302 
s 544
f 28218 32825 32821 
s 516
f 25498 24495 26303 
s 544
f 28222 32844 32840 
f 23045 33575 33576 
f 28225 26304 24090 
s 516
f 26305 24493 25442 
s 544
f 33221 33242 28353 
f 25361 24479 26306 
f 28249 33382 33380 
f 33258 33270 28383 
s 516
f 26307 24492 25497 
s 544
f 28193 32614 33246 
s 516
f 25464 24494 26308 
s 544
f 28291 33410 33407 
f 25359 23551 26309 
f 29897 23052 26310 
f 28420 33409 33405 
s 516
f 25579 33217 33223 
f 23649 26311 24448 
f 25560 24502 26312 
s 544
f 28277 32928 32923 
f 33027 33717 28257 
s 516
f 26313 23120 25345 
s 544
f 33327 33329 28198 
f 26314 24014 25556 
s 516
f 25391 24501 26315 
s 544
f 28283 32938 32933 
s 516
f 23058 26316 28183 
f 32645 32977 25463 
f 32555 33586 25161 
f 21739 26317 28244 
f 28300 26318 24095 
f 25217 32785 32560 
s 544
f 28308 32987 32488 
s 516
f 24615 23115 26319 
f 26320 24491 25462 
s 544
f 32886 32893 28196 
s 516
f 24569 26321 29902 
f 32332 32058 29625 
f 26322 24490 25495 
s 544
f 32910 32634 28262 
s 516
f 28385 26323 21892 
f 25162 33597 32563 
f 26324 24500 25388 
s 544
f 33283 33295 28360 
s 516
f 25453 33637 33268 
f 28464 26325 24499 
s 544
f 33337 33356 28288 
f 26326 24489 29899 
s 516
f 25530 33219 33237 
f 28356 26327 23069 
f 21733 26328 23648 
f 33064 33060 25578 
s 544
f 26329 23119 25559 
f 33528 33531 28226 
s 516
f 26330 24488 25492 
s 544
f 33118 33139 28272 
s 516
f 33172 33168 25362 
f 24478 26331 28339 
s 544
f 33370 33374 28236 
f 26332 24487 29892 
s 516
f 26333 23068 25369 
f 33543 32662 28220 
f 33477 33479 28374 
f 26334 23118 23631 
f 25343 23105 26335 
s 544
f 28372 33671 33102 
s 516
f 24486 26336 23639 
f 33192 33184 25491 
f 33098 33188 25532 
f 24059 26337 28182 
f 33130 33109 25429 
f 23051 26338 28168 
f 33077 33114 24675 
f 22007 26339 28179 
s 544
f 28217 33071 33061 
s 516
f 25348 21918 26340 
s 544
f 28410 33511 32690 
f 28514 24481 26341 
s 516
f 25445 24485 26342 
s 544
f 28312 32997 32991 
s 516
f 23636 24013 26343 
s 544
f 28246 32963 32950 
s 516
f 24477 26344 28162 
f 32514 33289 24624 
s 544
f 32799 32814 28237 
s 516
f 26345 24484 25439 
f 26346 24322 25353 
s 544
f 32791 32826 28279 
s 516
f 25423 24012 26347 
s 544
f 28241 32778 32775 
f 28184 33523 33520 
f 29894 24483 26348 
f 32680 33418 28317 
f 26349 21871 25420 
f 28197 33641 32667 
f 25432 24091 26350 
s 516
f 28439 26351 24482 
f 25425 33009 32646 
f 25542 23054 26352 
f 28346 32525 32524 
f 26353 23552 25529 
s 544
f 32467 32469 28208 
f 25500 24321 26354 
f 28388 33656 33493 
f 32961 32964 28290 
s 516
f 21982 26355 28104 
f 21665 26356 28512 
f 32083 33787 25536 
s off
f 22996 21512 26357 
f 26358 21253 26389 
f 26388 21254 26359 
s 2147483647
f 30224 21980 30176 
s 1026
f 21980 30224 29444 
f 21618 30225 21987 
s 2147483647
f 30185 21987 30225 
s 1026
f 22892 30221 29433 
s 2147483647
f 30221 22892 30181 
s 1026
f 29451 30217 22231 
s 2147483647
f 30177 22231 30217 
f 30213 21896 30172 
s 1026
f 21896 30213 21894 
s 2147483647
f 30180 22831 30220 
s 1026
f 29414 30220 22831 
f 22820 30226 29383 
s 2147483647
f 30226 22820 30182 
s 1026
f 29358 30218 22858 
s 2147483647
f 30178 22858 30218 
s 1026
f 22848 30216 29368 
s 2147483647
f 30216 22848 30175 
s 1026
f 29348 30215 22855 
s 2147483647
f 30174 22855 30215 
f 30223 21816 30183 
s 1026
f 21816 30223 29446 
f 29449 30219 21878 
s 2147483647
f 30179 21878 30219 
s 1026
f 29452 30214 22019 
s 2147483647
f 30173 22019 30214 
f 30222 21856 30184 
s 1026
f 21856 30222 29447 
s off
f 31078 31045 21026 
f 25266 31101 31045 
f 31046 31103 25265 
f 21027 31046 31081 
f 33464 31369 21602 
f 21602 31369 31617 
f 31616 31368 21966 
f 33830 21966 31368 
f 26378 31723 31248 
f 31723 26378 23706 
f 31101 31104 23001 
f 20926 23001 31104 
f 31105 23002 21005 
f 23002 31105 31103 
f 21382 23110 26381 
f 28562 26381 23110 
f 31617 28592 26382 
f 31617 26382 21602 
f 21966 26383 31616 
f 31616 26383 28587 
f 31619 31102 30995 
f 31619 30044 26384 
f 31618 26385 30043 
f 30994 31100 31618 
f 30543 30810 21448 
f 21448 26386 22961 
f 22962 26387 21449 
f 21449 30809 30542 
f 26388 23352 32004 
f 26359 23352 26388 
f 26389 23351 26358 
f 32003 23351 26389 
f 24861 26390 22055 
f 29988 29630 26390 
f 22056 26391 24862 
f 26391 29629 29985 
f 24476 26392 21262 
f 23738 21262 31902 
f 31901 21263 23737 
f 21263 26393 24475 
s 4128
f 27509 26394 30487 
s 2147483647
f 24454 30801 23032 
f 24453 23033 30800 
s 4128
f 30486 26395 27508 
s 516
f 32880 26396 24844 
s 544
f 24844 26396 32053 
s off
f 22991 26397 31900 
f 21078 26397 21621 
f 31899 26398 22990 
f 21622 26398 21079 
f 21378 26399 24523 
f 31643 33818 21378 
f 21264 32346 21115 
f 24524 26400 21264 
f 26401 23175 27001 
s 8
f 23175 26401 21101 
s off
f 21060 21955 26402 
s 8
f 21131 26402 21955 
s off
f 31646 33563 22240 
f 22240 26403 24532 
f 24531 26404 22238 
f 22238 33414 31631 
f 22239 32700 31627 
f 24533 26405 22239 
f 31638 33550 22241 
f 22241 26406 24534 
f 31636 32676 22237 
f 22237 26407 24528 
f 21598 26408 22074 
f 22074 32692 31624 
f 24529 26409 21377 
f 21377 33647 31623 
f 24527 26410 22236 
f 22236 33559 31625 
f 21691 26411 21393 
f 31635 33663 21691 
f 21653 33398 31632 
f 21419 26412 21653 
f 21692 26413 21423 
f 31637 33545 21692 
f 31639 33549 21690 
f 21690 26414 21167 
f 21654 26415 21397 
f 31644 33653 21654 
f 21693 33352 31626 
f 21221 26416 21693 
f 21689 33551 31628 
f 21174 26417 21689 
f 21376 26418 24530 
f 31640 32688 21376 
f 31035 30946 21299 
f 25068 21299 26419 
f 21298 30947 31034 
f 26420 21298 25069 
f 31050 31825 31806 
f 31825 31050 21245 
f 21244 31051 31826 
f 31805 31826 31051 
f 31052 31819 31794 
f 31819 31052 21273 
f 21272 31053 31820 
f 31793 31820 31053 
f 26425 31138 21516 
f 23290 31138 26425 
f 21515 31139 26426 
f 26426 31139 23289 
f 26427 31141 21533 
f 23283 31141 26427 
f 26428 31140 23284 
f 21532 31140 26428 
f 26429 31151 23286 
f 21523 31151 26429 
f 26430 31150 21524 
f 23285 31150 26430 
f 26431 31144 21535 
f 23287 31144 26431 
f 26432 31145 23288 
f 21534 31145 26432 
f 23294 31146 26433 
f 26433 31146 21526 
f 21525 31147 26434 
f 26434 31147 23293 
f 23295 31143 26435 
f 26435 31143 21022 
f 21019 31142 26436 
f 26436 31142 23296 
f 21506 31149 26437 
f 26437 31149 23291 
f 26438 31148 21507 
f 23292 31148 26438 
s 544
f 26439 33673 33161 
s 516
f 26460 26439 24680 
s 544
f 24060 26440 26458 
s 516
f 32305 32330 26440 
f 26441 25084 25917 
f 25084 26441 22777 
f 26442 25085 26451 
f 25085 26442 22774 
f 26443 25080 25918 
s 544
f 25080 26443 22772 
s 516
f 26444 25082 26455 
s 544
f 25082 26444 22728 
s 516
f 26445 25081 26456 
s 544
f 25081 26445 22778 
s 516
f 26459 25083 26446 
s 544
f 22433 26446 25083 
s 516
f 26447 25086 26454 
s 544
f 25086 26447 32955 
s 516
f 26448 23608 26457 
s 544
f 23608 26448 32993 
f 26449 24267 26453 
s 516
f 24267 26449 33355 
f 26450 24520 26452 
s 544
f 24520 26450 32738 
f 24015 26451 33682 
s 516
f 26451 24015 26442 
s 544
f 22723 26452 26580 
s 516
f 26452 22723 26450 
f 22724 26453 26588 
s 544
f 26453 22724 26449 
f 22721 26454 26746 
s 516
f 26454 22721 26447 
f 24676 26455 33697 
f 26455 24676 26444 
f 24291 26456 32853 
f 26456 24291 26445 
s 544
f 22725 26457 26765 
s 516
f 26457 22725 26448 
f 24269 26458 26707 
s 544
f 26458 24269 24060 
s 516
f 33796 26459 21914 
f 26446 21914 26459 
s 544
f 26826 26460 22722 
s 516
f 24680 22722 26460 
s 544
f 22771 22462 26461 
s 516
f 25942 26461 22462 
s 544
f 22776 22461 26462 
s 516
f 23691 26462 22461 
f 25924 26463 22460 
f 22775 22460 26463 
f 26608 26464 22457 
f 22729 22457 26464 
f 25973 26465 22458 
s 544
f 22773 22458 26465 
s 516
f 22913 26466 23660 
s 544
f 26466 22913 22434 
s 516
f 26654 23183 26467 
s 544
f 33227 26467 23183 
s 516
f 26468 23656 26740 
s 544
f 23656 26468 33273 
s 516
f 24254 26469 23536 
f 26469 33587 32856 
f 23215 26470 24277 
f 33091 33257 26470 
s 544
f 32788 26471 23716 
s 516
f 26843 23716 26471 
f 24185 26472 23207 
f 26472 32394 32463 
f 26764 23655 26473 
s 544
f 32970 26473 23655 
s 516
f 33562 33667 26474 
s 544
f 22408 26474 24253 
s 516
f 26475 33581 33585 
f 24145 26475 23044 
s 544
f 32598 32451 26476 
s 516
f 23212 26476 24303 
f 32575 33266 26477 
f 23721 26477 24131 
f 26478 23264 26873 
s 544
f 23264 26478 32934 
f 26479 32501 32603 
s 516
f 24158 26479 23217 
f 22461 26480 23691 
f 26480 32770 32847 
f 32832 33825 26481 
f 23660 26481 22913 
f 26482 32625 32403 
f 24300 26482 23211 
f 32992 33035 26483 
f 23719 26483 24252 
f 32478 32360 26484 
f 24578 26484 24294 
f 26485 32374 32551 
f 24298 26485 23214 
f 26486 23720 26674 
s 544
f 23720 26486 33343 
s 516
f 33612 33195 26487 
f 23717 26487 24216 
f 26744 24571 26488 
s 544
f 32586 26488 24571 
f 23010 26489 24187 
s 516
f 33423 33606 26489 
f 32644 32630 26490 
f 23205 26490 24265 
s 544
f 26491 32945 33754 
s 516
f 24153 26491 23659 
f 26492 23612 29651 
s 544
f 23612 26492 33665 
s 516
f 26493 33149 33670 
f 24281 26493 23550 
s 544
f 26872 23265 26494 
s 516
f 33351 26494 23265 
f 26495 33261 33693 
f 24279 26495 23108 
f 26773 23538 26496 
s 544
f 32730 26496 23538 
s 516
f 33178 33313 26497 
f 23197 26497 24280 
s 1026
f 27747 26498 23138 
f 26498 33783 32936 
s 516
f 26499 23714 26538 
s 544
f 23714 26499 32951 
f 23537 26500 29646 
s 516
f 32673 33591 26500 
s 544
f 33006 26501 23718 
s 516
f 26529 23718 26501 
s 544
f 23702 26502 33335 
s 516
f 26502 23702 26622 
f 26612 24574 26503 
s 544
f 32869 26503 24574 
f 23715 26504 32777 
s 516
f 26504 23715 26636 
f 23213 26505 24299 
f 32583 32375 26505 
f 26506 32358 32558 
f 24293 26506 23139 
f 23208 26507 24186 
f 32554 32401 26507 
f 26508 32414 32553 
f 24156 26508 23220 
f 26509 33032 32417 
f 24182 26509 24575 
s 544
f 32518 32552 26510 
s 516
f 23219 26510 24155 
f 24193 26511 23210 
f 26511 32592 32395 
f 32444 32573 26512 
f 23218 26512 24154 
f 26513 33161 33673 
f 24139 26513 23657 
f 26514 32380 32408 
f 22438 26514 23206 
f 24304 26515 24576 
s 544
f 26515 32489 32387 
s 516
f 24192 26516 23209 
f 26516 32473 32349 
s 544
f 32330 32305 26517 
s 516
f 24570 26517 22418 
f 32326 32293 26518 
f 24573 26518 22441 
f 32318 32295 26519 
s 544
f 24572 26519 24292 
s 516
f 26520 32296 32311 
s 544
f 24165 26520 24577 
f 24519 26521 33580 
s 516
f 26521 24519 26743 
f 26522 23722 26598 
s 544
f 23722 26522 32806 
f 25964 26523 24276 
s 516
f 24266 24276 26523 
f 26571 26524 22087 
s 544
f 33644 22087 26524 
s 516
f 26525 24292 24179 
s 544
f 24292 26525 24572 
s 516
f 24062 26526 26653 
s 544
f 32295 32318 26526 
f 24258 26527 26606 
s 516
f 26527 24258 23062 
s 544
f 26591 26528 22464 
s 516
f 23493 22464 26528 
f 26501 22442 26529 
s 544
f 26771 26529 22442 
f 24275 26530 26766 
s 516
f 26530 24275 24057 
f 23966 24274 26531 
s 544
f 26829 26531 24274 
f 22444 26532 26831 
s 516
f 26532 22444 23453 
s 544
f 26864 26533 25078 
s 516
f 33821 25078 26533 
f 23550 26534 24281 
s 544
f 24287 24281 26534 
f 22790 26535 25932 
s 516
f 26535 22790 22787 
s 544
f 26699 26536 24126 
s 516
f 23949 24126 26536 
f 21815 26537 29661 
s 544
f 33732 33494 26537 
s 516
f 26538 24195 26499 
s 544
f 24195 26538 26772 
s 516
f 20881 26539 24178 
s 544
f 29648 24178 26539 
s 516
f 22603 26540 26561 
s 544
f 26540 22603 32746 
s 516
f 23062 26541 26527 
s 544
f 26541 23062 32819 
s 516
f 24147 25090 26542 
f 32656 26542 25090 
f 26528 26543 23493 
s 544
f 32754 23493 26543 
s 516
f 24306 26544 32504 
f 26544 24306 23431 
f 26545 24164 24177 
f 24164 26545 33806 
f 23041 26546 26574 
s 544
f 33257 33091 26546 
f 26690 26547 24240 
s 516
f 23458 24240 26547 
f 23220 26548 24156 
s 544
f 22424 24156 26548 
s 516
f 23972 26549 26600 
s 544
f 32573 32444 26549 
s 516
f 26700 26550 23951 
s 544
f 26550 32403 32625 
s 516
f 24203 26551 33082 
f 26551 24203 22446 
f 26563 26552 22899 
s 544
f 24273 22899 26552 
f 26722 26553 24122 
s 516
f 24678 24122 26553 
f 25960 26554 22912 
s 544
f 22897 22912 26554 
f 26583 26555 22431 
s 516
f 21627 22431 26555 
s 544
f 24173 24182 26556 
s 516
f 24575 26556 24182 
f 26582 26557 24295 
s 544
f 22216 24295 26557 
s 516
f 24277 26558 23215 
s 544
f 26558 24277 24121 
f 24189 24257 26559 
s 516
f 26714 26559 24257 
f 26542 26560 24147 
s 544
f 22757 24147 26560 
f 24205 26561 26584 
s 516
f 26561 24205 22603 
f 21841 26562 32698 
s 544
f 26562 21841 24194 
s 516
f 32917 26563 24925 
f 22899 24925 26563 
f 23422 26564 26879 
s 544
f 26564 23422 24152 
s 516
f 25955 26565 23433 
s 544
f 22394 23433 26565 
s 516
f 21924 26566 26875 
s 544
f 33825 32832 26566 
f 26567 22900 24272 
s 516
f 22900 26567 26577 
f 24188 26568 26679 
f 26568 24188 23735 
f 26572 26569 23432 
s 544
f 22894 23432 26569 
s 516
f 26624 26570 24244 
s 544
f 32841 24244 26570 
s 516
f 26523 26571 24266 
f 22087 24266 26571 
f 23432 24307 26572 
f 33264 26572 24307 
s 544
f 26548 26573 22424 
s 516
f 23973 22424 26573 
s 544
f 24121 26574 26558 
s 516
f 26574 24121 23041 
f 33292 26575 23609 
f 24285 23609 26575 
f 24067 26576 32703 
f 26576 24067 24132 
f 22910 26577 33132 
f 26577 22910 22900 
f 29652 26578 24207 
s 544
f 20737 24207 26578 
f 24204 26579 26638 
s 516
f 26579 24204 21744 
s 544
f 26580 24132 22723 
s 516
f 24132 26580 26576 
s 544
f 22430 26581 26666 
s 516
f 26581 22430 24682 
f 24295 22463 26582 
f 25931 26582 22463 
f 26625 26583 24140 
s 544
f 22431 24140 26583 
f 26584 22755 24205 
s 516
f 22755 26584 25966 
f 24209 26585 26627 
s 544
f 26585 24209 32919 
s 516
f 24211 26586 26825 
s 544
f 26586 24211 24243 
f 22895 26587 26599 
s 516
f 26587 22895 22655 
f 24157 26588 25923 
f 26588 24157 22724 
f 22486 24149 26589 
s 544
f 26628 26589 24149 
f 26611 26590 22592 
s 516
f 33349 22592 26590 
s 544
f 22464 24263 26591 
s 516
f 25927 26591 24263 
f 23606 26592 26868 
s 544
f 26592 23606 32337 
s 516
f 23426 26593 26866 
s 544
f 26593 23426 24128 
f 24187 26594 23010 
s 516
f 26594 24187 24262 
f 26862 24130 26595 
s 544
f 26597 26595 24130 
s 516
f 32734 26596 21887 
s 544
f 29649 21887 26596 
s 516
f 26880 26597 23427 
s 544
f 24130 23427 26597 
f 23436 26598 26614 
s 516
f 26598 23436 26522 
f 23431 26599 26544 
s 544
f 26599 23431 22895 
f 22422 26600 26731 
s 516
f 26600 22422 23972 
f 24064 26601 20748 
s 544
f 33591 32673 26601 
f 26728 26602 24200 
s 516
f 24255 24200 26602 
f 24302 26603 26782 
s 544
f 26603 24302 24118 
s 516
f 26808 26604 24231 
s 544
f 24222 24231 26604 
f 24272 26605 26567 
s 516
f 26605 24272 23442 
f 24264 26606 25934 
s 544
f 26606 24264 24258 
s 516
f 26626 26607 24066 
s 544
f 26607 32856 33587 
f 32557 26608 23736 
s 516
f 22457 23736 26608 
f 32729 26609 22132 
f 24246 22132 26609 
s 544
f 24577 26610 24165 
s 516
f 22416 24165 26610 
f 26630 26611 24144 
s 544
f 22592 24144 26611 
f 26693 26612 24214 
s 516
f 26503 24214 26612 
s 544
f 24206 26613 26779 
s 516
f 26613 24206 21753 
f 23419 26614 26816 
s 544
f 26614 23419 23436 
s 516
f 24280 26615 23197 
s 544
f 26615 24280 24119 
s 516
f 24191 26616 26805 
s 544
f 26616 24191 26888 
f 26657 26617 24138 
s 516
f 26858 24138 26617 
f 24213 26618 29653 
s 544
f 26618 24213 20844 
s 516
f 26609 26619 24246 
s 544
f 24210 24246 26619 
s 516
f 29656 26620 24212 
s 544
f 20711 24212 26620 
s 516
f 22799 24270 26621 
s 544
f 25925 26621 24270 
s 516
f 24161 26622 26807 
f 26622 24161 26502 
s 544
f 22410 26623 26692 
s 516
f 26623 22410 33332 
f 24244 24250 26624 
s 544
f 26664 26624 24250 
s 516
f 24140 24296 26625 
f 33304 26625 24296 
s 544
f 26644 26626 24190 
s 516
f 24066 24190 26626 
s 544
f 24215 26627 26667 
s 516
f 26627 24215 24209 
f 23139 26628 24293 
s 544
f 24149 24293 26628 
s 516
f 23434 26629 26871 
f 26629 23434 24183 
f 24144 24297 26630 
f 25974 26630 24297 
f 23950 26631 26752 
s 544
f 32375 32583 26631 
f 22898 26632 26651 
s 516
f 26632 22898 23231 
f 26676 26633 24174 
s 544
f 32981 24174 26633 
s 516
f 26820 26634 22228 
s 544
f 32979 22228 26634 
f 24148 26635 26680 
s 516
f 26635 24148 24061 
s 544
f 24243 26636 26586 
s 516
f 26636 24243 26504 
f 26850 26637 24196 
s 544
f 33517 24196 26637 
s 516
f 24216 26638 23717 
s 544
f 26638 24216 24204 
s 516
f 24245 26639 26663 
s 544
f 26639 24245 32912 
s 516
f 26790 26640 22594 
s 544
f 33121 22594 26640 
s 516
f 24238 26641 25944 
s 544
f 26641 24238 24199 
s 516
f 23192 26642 26742 
s 544
f 26642 23192 32875 
s 516
f 26647 26643 24169 
s 544
f 33387 24169 26643 
f 24190 24254 26644 
s 516
f 23536 26644 24254 
s 544
f 32381 23607 26645 
s 516
f 26874 26645 23607 
f 24217 26646 26671 
s 544
f 26646 24217 32925 
s 516
f 26781 26647 24170 
f 24169 24170 26647 
s 544
f 24290 26648 26822 
s 516
f 26648 24290 23952 
f 20868 26649 24208 
s 544
f 33527 24208 26649 
s 516
f 24241 26650 20761 
s 544
f 26650 24241 33415 
f 26651 22911 22898 
s 516
f 22911 26651 25952 
f 23438 26652 26863 
s 544
f 26652 23438 24116 
s 516
f 24179 26653 26525 
f 26653 24179 24062 
s 544
f 26703 26654 23437 
s 516
f 26467 23437 26654 
s 544
f 26701 26655 24260 
s 516
f 23968 24260 26655 
f 26656 24232 24230 
s 544
f 24232 26656 26803 
s 516
f 26885 26657 23428 
s 544
f 24138 23428 26657 
f 26569 26658 22894 
s 516
f 21640 22894 26658 
f 24186 26659 23208 
s 544
f 26659 24186 24261 
s 516
f 24239 26660 20774 
s 544
f 26660 24239 29647 
s 516
f 26661 24155 22420 
f 24155 26661 23219 
f 26662 24194 24197 
s 544
f 24194 26662 26562 
f 24219 26663 26686 
s 516
f 26663 24219 24245 
f 25933 26664 24242 
s 544
f 24250 24242 26664 
s 516
f 22420 26665 26661 
s 544
f 26665 22420 23974 
f 26666 24131 22430 
s 516
f 24131 26666 23721 
s 544
f 26667 24220 24215 
s 516
f 24220 26667 25961 
f 26691 26668 24282 
s 544
f 32943 24282 26668 
s 516
f 24228 26669 26713 
s 544
f 26669 24228 33119 
s 516
f 24682 26670 26581 
s 544
f 33266 32575 26670 
f 24221 26671 26694 
s 516
f 26671 24221 24217 
f 24251 23319 26672 
f 32610 26672 23319 
s 544
f 24171 26673 26846 
s 516
f 26673 24171 23443 
f 24197 26674 26662 
f 26674 24197 26486 
f 26697 26675 22337 
s 544
f 32952 22337 26675 
f 26760 26676 24167 
s 516
f 24174 24167 26676 
s 544
f 24152 26677 26564 
s 516
f 26677 24152 26856 
f 25089 26678 26849 
s 544
f 26678 25089 33373 
f 24253 26679 22408 
s 516
f 26679 24253 24188 
f 24294 26680 24578 
s 544
f 26680 24294 24148 
s 516
f 26705 26681 24141 
s 544
f 26860 24141 26681 
s 516
f 24305 26682 26706 
s 544
f 26682 24305 32971 
s 516
f 24303 26683 23212 
f 26683 24303 24124 
f 24124 26684 26683 
s 544
f 26684 24124 23948 
s 516
f 23980 26685 32884 
f 26685 23980 24289 
f 24223 26686 25978 
s 544
f 26686 24223 24219 
s 516
f 24183 26687 26629 
s 544
f 26687 24183 26861 
s 516
f 26688 24119 23947 
s 544
f 24119 26688 26615 
s 516
f 24681 22766 26689 
s 544
f 26785 26689 22766 
s 516
f 26698 26690 24198 
s 544
f 24240 24198 26690 
s 516
f 24282 24284 26691 
s 544
f 26732 26691 24284 
s 516
f 24120 26692 26789 
s 544
f 26692 24120 22410 
s 516
f 26672 26693 24251 
s 544
f 24214 24251 26693 
s 516
f 24202 26694 25941 
s 544
f 26694 24202 24221 
s 516
f 24068 22416 26695 
f 26610 26695 22416 
f 24137 26696 25936 
s 544
f 26696 24137 22428 
s 516
f 22337 22429 26697 
s 544
f 26739 26697 22429 
s 516
f 24198 24247 26698 
f 33624 26698 24247 
f 23214 26699 24298 
s 544
f 24126 24298 26699 
f 26719 26700 24142 
s 516
f 23951 24142 26700 
s 544
f 24260 24185 26701 
s 516
f 23207 26701 24185 
f 24176 26702 26753 
s 544
f 26702 24176 33043 
f 23437 23420 26703 
s 516
f 26796 26703 23420 
s 544
f 24133 26704 26747 
s 516
f 26704 24133 26853 
f 26886 26705 23430 
f 24141 23430 26705 
s 544
f 22396 26706 26723 
s 516
f 26706 22396 24305 
f 22418 26707 24570 
f 26707 22418 24269 
s 544
f 24261 26708 26659 
s 516
f 26708 24261 23967 
f 23231 26709 26632 
s 544
f 26709 23231 32863 
f 20802 24225 26710 
s 516
f 29658 26710 24225 
f 24181 26711 25969 
s 544
f 26711 24181 24175 
s 516
f 26737 26712 24226 
s 544
f 33020 24226 26712 
f 24224 26713 26762 
s 516
f 26713 24224 24228 
f 33299 26714 24065 
f 24257 24065 26714 
s 544
f 24116 26715 26652 
s 516
f 26715 24116 26855 
f 22490 26716 26769 
s 544
f 33606 33423 26716 
s 516
f 26735 26717 23965 
s 544
f 26717 32395 32592 
f 25957 26718 24283 
s 516
f 24288 24283 26718 
f 23211 26719 24300 
s 544
f 24142 24300 26719 
s 516
f 24057 26720 26530 
s 544
f 26720 24057 32383 
s 516
f 22386 26721 32619 
s 544
f 26721 22386 24163 
s 516
f 23108 26722 24279 
s 544
f 24122 24279 26722 
s 516
f 23435 26723 25951 
s 544
f 26723 23435 22396 
s 516
f 22173 26724 32885 
f 26724 22173 24168 
s 544
f 25077 26725 26878 
s 516
f 26725 25077 33422 
s 544
f 33736 33461 26726 
s 516
f 21855 26726 29663 
f 26788 26727 23134 
s 544
f 33116 23134 26727 
s 516
f 33133 26728 25087 
s 544
f 24200 25087 26728 
f 33137 24160 26729 
s 516
f 26734 26729 24160 
f 25963 26730 24236 
s 544
f 24129 24236 26730 
s 516
f 24154 26731 23218 
s 544
f 26731 24154 22422 
s 516
f 25970 26732 24286 
s 544
f 24284 24286 26732 
s 516
f 24265 26733 23205 
s 544
f 26733 24265 26887 
f 26827 26734 24123 
s 516
f 24160 24123 26734 
s 544
f 26748 26735 24259 
s 516
f 23965 24259 26735 
f 24249 26736 26802 
s 544
f 26736 24249 33293 
s 516
f 24226 24227 26737 
s 544
f 26776 26737 24227 
f 26759 26738 24256 
s 516
f 23964 24256 26738 
f 25948 26739 24134 
s 544
f 22429 24134 26739 
f 24159 26740 26847 
s 516
f 26740 24159 26468 
f 24125 24304 26741 
f 24576 26741 24304 
s 544
f 24199 26742 26641 
s 516
f 26742 24199 23192 
s 544
f 24118 26743 26603 
s 516
f 26743 24118 26521 
f 26488 24150 26744 
s 544
f 26787 26744 24150 
s 516
f 26768 26745 22426 
s 544
f 33104 22426 26745 
f 26746 24117 22721 
s 516
f 24117 26746 25938 
f 23425 26747 26884 
s 544
f 26747 23425 24133 
s 516
f 23210 26748 24193 
s 544
f 24259 24193 26748 
s 516
f 26881 26749 23423 
f 24135 23423 26749 
f 26824 26750 22756 
s 544
f 21967 22756 26750 
s 516
f 24230 26751 26656 
s 544
f 26751 24230 33294 
f 24127 26752 26801 
s 516
f 26752 24127 23950 
s 544
f 24175 26753 26711 
s 516
f 26753 24175 24176 
f 21792 24273 26754 
s 544
f 26552 26754 24273 
s 516
f 23424 26755 26867 
f 26755 23424 24136 
f 25929 26756 24218 
s 544
f 24229 24218 26756 
s 516
f 24136 26757 26755 
s 544
f 26757 24136 26859 
s 516
f 26749 26758 24135 
s 544
f 26852 24135 26758 
s 516
f 23209 26759 24192 
s 544
f 24256 24192 26759 
f 24167 24172 26760 
s 516
f 25977 26760 24172 
f 22234 26761 26784 
s 544
f 26761 22234 33086 
f 26762 24201 24224 
s 516
f 24201 26762 25930 
f 26865 26763 25079 
s 544
f 32564 25079 26763 
f 26604 26764 24222 
s 516
f 26473 24222 26764 
f 24146 26765 25940 
s 544
f 26765 24146 22725 
s 516
f 22439 26766 25926 
s 544
f 26766 22439 24275 
f 26814 26767 24151 
s 516
f 26857 24151 26767 
s 544
f 26730 26768 24129 
s 516
f 22426 24129 26768 
f 24262 26769 26594 
f 26769 24262 22490 
f 26854 24143 26770 
s 544
f 26793 26770 24143 
s 516
f 26806 26771 22447 
s 544
f 22442 22447 26771 
s 516
f 24168 26772 26724 
s 544
f 26772 24168 24195 
f 26559 26773 24189 
s 516
f 26496 24189 26773 
f 24162 26774 29659 
s 544
f 26774 24162 20818 
s 516
f 29654 26775 22230 
s 544
f 26775 33742 33785 
f 24227 24233 26776 
s 516
f 25976 26776 24233 
f 26810 26777 24063 
s 544
f 26777 32417 33032 
s 516
f 29657 26778 21876 
s 544
f 26778 33748 33706 
s 516
f 24252 26779 23719 
s 544
f 26779 24252 24206 
s 516
f 23735 26780 26568 
s 544
f 33667 33562 26780 
s 516
f 24170 24166 26781 
s 544
f 25928 26781 24166 
s 516
f 22496 26782 33218 
f 26782 22496 24302 
s 544
f 26783 33585 33581 
s 516
f 26689 26783 24681 
f 26784 22428 22234 
s 544
f 22428 26784 26696 
s 516
f 23044 26785 24145 
s 544
f 22766 24145 26785 
s 516
f 21744 26786 26579 
s 544
f 33195 33612 26786 
s 516
f 26792 26787 24301 
s 544
f 24150 24301 26787 
s 516
f 23134 22897 26788 
s 544
f 26554 26788 22897 
s 516
f 24278 26789 25922 
f 26789 24278 24120 
s 544
f 26565 26790 22394 
s 516
f 22594 22394 26790 
f 26575 26791 24285 
s 544
f 26890 24285 26791 
s 516
f 32572 26792 24679 
f 24301 24679 26792 
f 26882 26793 23429 
s 544
f 24143 23429 26793 
s 516
f 23967 26794 26708 
s 544
f 32401 32554 26794 
f 26795 33693 33261 
s 516
f 26553 26795 24678 
f 23420 23104 26796 
f 32423 26796 23104 
f 29662 26797 23137 
s 544
f 33654 23137 26797 
s 516
f 24237 26798 29660 
s 544
f 26798 24237 20909 
f 26799 33670 33149 
s 516
f 26821 26799 21969 
s 544
f 26557 26800 22216 
s 516
f 32678 22216 26800 
f 24299 26801 23213 
s 544
f 26801 24299 24127 
f 24235 26802 26823 
s 516
f 26802 24235 24249 
f 24234 26803 25956 
s 544
f 26803 24234 24232 
s 516
f 21753 26804 26613 
s 544
f 33035 32992 26804 
s 516
f 25088 26805 32653 
f 26805 25088 24191 
f 32620 26806 22136 
f 22447 22136 26806 
s 544
f 24163 26807 26721 
s 516
f 26807 24163 24161 
f 32764 26808 21590 
f 24231 21590 26808 
s 544
f 26750 26809 21967 
s 516
f 26809 33754 32945 
f 24063 24173 26810 
s 544
f 26556 26810 24173 
s 516
f 26833 26811 22421 
s 544
f 23971 22421 26811 
s 516
f 21757 24255 26812 
f 26602 26812 24255 
f 26815 26813 22694 
s 544
f 33476 22694 26813 
s 516
f 26883 26814 23421 
s 544
f 24151 23421 26814 
s 516
f 22694 24288 26815 
f 26718 26815 24288 
f 22194 26816 32740 
f 26816 22194 23419 
s 544
f 23948 26817 26684 
s 516
f 32451 32598 26817 
f 26741 26818 24125 
s 544
f 24677 24125 26818 
s 516
f 26870 26819 21901 
s 544
f 26819 32847 32770 
f 26756 26820 24229 
s 516
f 22228 24229 26820 
s 544
f 26534 26821 24287 
s 516
f 21969 24287 26821 
f 22441 26822 24573 
s 544
f 26822 22441 24290 
s 516
f 24248 26823 25943 
s 544
f 26823 24248 24235 
s 516
f 22756 24153 26824 
f 23659 26824 24153 
f 22162 26825 32608 
f 26825 22162 24211 
f 23657 26826 24139 
s 544
f 22722 24139 26826 
s 516
f 25972 26827 24180 
s 544
f 24123 24180 26827 
f 26560 26828 22757 
s 516
f 23531 22757 26828 
s 544
f 24274 22438 26829 
s 516
f 23206 26829 22438 
f 23947 26830 26688 
s 544
f 33313 33178 26830 
s 516
f 22446 26831 26551 
s 544
f 26831 22446 22444 
f 23974 26832 26665 
s 516
f 32552 32518 26832 
f 23217 26833 24158 
f 22421 24158 26833 
s 544
f 26811 26834 23971 
s 516
f 26834 32603 32501 
s 544
f 26818 26835 24677 
s 516
f 26835 32387 32489 
f 26738 26836 23964 
s 544
f 26836 32349 32473 
f 26837 32553 32414 
s 516
f 26573 26837 23973 
f 26655 26838 23968 
s 544
f 26838 32463 32394 
s 516
f 26531 26839 23966 
s 544
f 26839 32408 32380 
s 516
f 26536 26840 23949 
s 544
f 26840 32551 32374 
s 516
f 24061 26841 26635 
s 544
f 32360 32478 26841 
f 26842 32558 32358 
s 516
f 26589 26842 22486 
f 26471 24210 26843 
s 544
f 26619 26843 24210 
f 26844 32311 32296 
s 516
f 26695 26844 24068 
f 23952 26845 26648 
s 544
f 32293 32326 26845 
s 516
f 24177 26846 26545 
s 544
f 26846 24177 24171 
s 516
f 24289 26847 26685 
s 544
f 26847 24289 24159 
f 24128 26848 26593 
s 516
f 26848 24128 26851 
f 26849 22787 25089 
f 22787 26849 26535 
f 26621 26850 22799 
f 24196 22799 26850 
s 544
f 23202 26851 32144 
s 516
f 26851 23202 26848 
f 32134 26852 23198 
s 544
f 26758 23198 26852 
f 23200 26853 32185 
s 516
f 26853 23200 26704 
f 26770 23194 26854 
s 544
f 32193 26854 23194 
f 23193 26855 32048 
s 516
f 26855 23193 26715 
f 26856 23196 26677 
s 544
f 23196 26856 32212 
f 32215 26857 23195 
s 516
f 26767 23195 26857 
f 26617 23203 26858 
s 544
f 32161 26858 23203 
f 26859 23201 26757 
s 516
f 23201 26859 32238 
f 32136 26860 23204 
s 544
f 26681 23204 26860 
f 26861 23216 26687 
s 516
f 23216 26861 32241 
f 26595 23199 26862 
s 544
f 32186 26862 23199 
s 516
f 23953 26863 32056 
f 26863 23953 23438 
s 544
f 25078 22775 26864 
s 516
f 26463 26864 22775 
s 544
f 26461 26865 22771 
s 516
f 25079 22771 26865 
f 23956 26866 32071 
f 26866 23956 23426 
s 544
f 23959 26867 32169 
s 516
f 26867 23959 23424 
s 544
f 22772 26868 25080 
s 516
f 26868 22772 23606 
f 22774 26869 25085 
s 544
f 26869 22774 21788 
f 26462 26870 22776 
s 516
f 21901 22776 26870 
s 544
f 23970 26871 32091 
s 516
f 26871 23970 23434 
s 544
f 26494 22729 26872 
s 516
f 26464 26872 22729 
f 26873 22728 26478 
s 544
f 22728 26873 25082 
f 26465 26874 22773 
s 516
f 23607 22773 26874 
s 544
f 22434 26875 26466 
s 516
f 26875 22434 21924 
s 544
f 25083 26876 22433 
s 516
f 21829 22433 26876 
f 26877 22778 22093 
s 544
f 22778 26877 25081 
s 516
f 22777 26878 25084 
s 544
f 26878 22777 25077 
s 516
f 26879 23963 23422 
f 23963 26879 32229 
f 32085 26880 23957 
f 23427 23957 26880 
s 544
f 32221 26881 23954 
s 516
f 23423 23954 26881 
f 32126 26882 23961 
f 23429 23961 26882 
f 32224 26883 23962 
f 23421 23962 26883 
f 23958 26884 32164 
f 26884 23958 23425 
f 23428 23955 26885 
f 32149 26885 23955 
s 544
f 32122 26886 23960 
s 516
f 23430 23960 26886 
f 24184 26887 23969 
s 544
f 26887 24184 26733 
f 26888 24268 26616 
s 516
f 24268 26888 23529 
f 23969 26889 24184 
s 544
f 32630 32644 26889 
f 26791 24271 26890 
s 516
f 23498 26890 24271 
s 4128
f 26891 23604 30806 
s 4100
f 23604 26891 20831 
s 4128
f 30805 23605 26892 
s 4100
f 20724 26892 23605 
s off
f 23337 31710 29673 
f 21324 31710 22158 
f 21323 31709 22564 
f 21110 31709 29675 
f 22159 31715 21329 
f 29672 31715 23344 
f 29677 31711 21111 
f 22565 31711 21325 
f 21330 31716 22156 
f 21358 26897 21330 
f 22157 31714 21328 
f 21328 26898 21359 
f 29681 31712 23343 
f 22656 31712 21327 
f 21326 31713 22657 
f 23338 31713 29680 
s 516
f 28995 26901 22517 
s 544
f 28703 33069 33281 
s 516
f 28720 32677 32675 
f 29011 26902 24591 
f 33305 33300 28658 
f 21631 26903 29023 
f 28681 21683 26904 
f 28998 33220 33240 
s 544
f 28732 22402 26905 
f 29002 32486 32487 
s 516
f 26906 21850 28685 
f 33080 33106 28997 
s 544
f 29065 33111 33068 
s 516
f 25572 24431 26907 
f 28673 22178 26908 
s 544
f 29048 32999 32958 
s 516
f 26909 24432 25573 
s 544
f 32965 32969 29046 
f 32857 32861 29037 
s 516
f 26910 21737 28664 
s 544
f 32736 32741 28791 
s 516
f 26911 21862 28660 
s 544
f 25587 33815 32057 
s 516
f 33816 33735 26912 
s 544
f 21908 33408 32792 
s 516
f 33406 33686 26913 
f 29667 33675 32711 
f 30173 26914 22018 
f 21704 26915 28936 
f 33182 33179 22645 
s 544
f 32836 32862 28844 
s 516
f 26916 21900 22840 
s 544
f 33377 33379 28836 
f 26917 22107 30165 
f 33262 33282 28893 
s 516
f 26918 21962 22852 
f 26919 22125 22818 
s 544
f 33277 33288 28906 
s 516
f 22824 22069 26920 
s 544
f 28822 33107 32909 
s 516
f 22786 21926 26921 
s 544
f 28861 32940 32935 
s 516
f 28959 24505 26922 
s 544
f 28846 32988 32975 
s 516
f 26923 22043 22835 
s 544
f 32918 32922 28851 
f 33084 33181 28860 
s 516
f 26924 21934 22825 
f 33154 33729 24480 
f 24511 26925 30183 
f 22033 33274 33286 
f 28790 26926 22166 
s 544
f 28938 33416 33413 
f 30170 24510 26927 
s 516
f 26928 22168 22809 
s 544
f 33189 33214 28870 
f 22793 21975 26929 
f 28918 33474 33471 
f 28798 33525 33524 
f 30167 21959 26930 
s 516
f 24507 26931 28805 
f 33177 33097 22492 
s 544
f 28847 33384 33383 
f 22880 21697 26932 
f 33381 33399 28913 
f 26933 21956 30164 
s 516
f 21867 26934 28812 
f 33222 33199 25422 
f 21907 26935 32719 
s 544
f 24537 33259 33425 
s 516
f 33196 33193 22828 
f 21946 26936 28966 
s 544
f 25106 33186 33162 
s 516
f 22822 21942 26937 
f 25567 33255 33269 
f 28958 26938 21786 
s 544
f 32901 32911 28815 
s 516
f 26939 21930 22843 
s 544
f 33037 33718 28864 
s 516
f 26940 24434 22909 
f 22821 21994 26941 
s 544
f 28877 33012 33007 
s 516
f 24506 26942 28821 
f 33015 32998 21755 
f 24480 26943 33154 
s 544
f 24821 33417 32957 
f 28840 32966 32956 
s 516
f 22905 24433 26944 
s 544
f 28929 33658 33513 
f 22888 21819 26945 
s 516
f 26946 22047 22836 
s 544
f 32904 32908 28848 
f 28829 32850 32848 
s 516
f 22873 21807 26947 
f 22815 21938 26948 
s 544
f 28827 32834 32828 
s 516
f 22811 24509 26949 
s 544
f 28809 32786 32782 
s 516
f 21989 26950 28831 
f 33588 32776 25128 
s 544
f 29024 32751 32747 
s 516
f 22455 22035 26951 
f 28823 26952 24503 
f 24767 33574 32742 
f 26953 24508 22883 
s 544
f 32867 32914 28867 
s 516
f 28956 26954 21701 
f 22639 33028 32648 
f 26955 24504 22817 
s 544
f 32773 32616 28801 
s 516
f 32719 33680 21907 
f 21895 26956 30172 
f 32663 33325 25110 
f 24435 26957 22926 
f 22917 26958 22081 
s 544
f 22222 33105 32582 
s 516
f 22915 26959 24592 
s 544
f 25104 32365 32438 
s 516
f 26960 22188 25112 
f 33228 33206 22027 
f 25115 24439 26961 
f 22920 33256 33275 
f 29025 24441 26962 
f 22232 33229 33239 
f 26963 24440 29053 
f 33122 33113 22703 
f 25094 23057 26964 
f 21910 33100 33156 
f 26965 21724 25096 
f 33078 32796 22588 
f 24458 32612 33684 
f 25108 22404 26966 
s 544
f 33308 33120 25149 
s 516
f 26967 21708 22748 
f 26968 24438 22735 
s 544
f 32643 33613 25516 
s 516
f 22746 22051 26969 
s 544
f 25153 32946 32942 
s 516
f 26970 22519 28983 
f 32133 33339 28684 
s 544
f 24760 33165 33050 
s 516
f 25550 24513 26971 
f 28990 24512 26972 
s 544
f 24768 33798 32994 
s 516
f 26973 21880 28991 
s 544
f 32783 33786 25158 
s 516
f 25146 33751 33438 
f 22750 21847 26974 
f 28987 22203 26975 
f 28689 33350 32233 
s 544
f 32065 32817 28688 
s 516
f 24436 26976 28985 
s 544
f 32049 32275 28698 
s 516
f 22210 26977 28988 
f 28989 26978 24437 
s 544
f 28695 33025 32068 
s off
f 31998 31609 29871 
f 31609 31998 31995 
f 29870 31608 31997 
f 31996 31997 31608 
f 30312 26981 32334 
f 32044 26982 30311 
f 30667 26983 30683 
f 30588 30683 26983 
f 30710 26984 30682 
f 26984 30710 30592 
f 30645 30728 26985 
f 30653 26985 30728 
f 26986 30727 30646 
f 30727 26986 29960 
f 20970 21348 26987 
f 23181 26987 21348 
f 21347 26988 23182 
f 26988 21347 20971 
f 32040 32278 32316 
f 32284 32277 32039 
f 21197 26991 24444 
f 21471 26991 21197 
f 24443 26992 21158 
f 21158 26992 21382 
s 1040
f 21888 26993 23257 
s 2147483647
f 23257 26993 24052 
f 23260 26994 24054 
s 1040
f 29320 26994 23260 
s 2147483647
f 23258 24053 26995 
s 8194
f 23258 26995 20796 
s 2147483647
f 23259 26996 24051 
s 8194
f 20904 26996 23259 
s 2147483647
f 23261 24050 26997 
s 1040
f 23261 26997 29319 
f 23256 26998 25727 
s 2147483647
f 23256 24056 26998 
f 23255 24055 26999 
s 1040
f 23255 26999 24655 
f 25614 27000 23262 
s 2147483647
f 23262 27000 24049 
s off
f 27001 23361 26401 
f 21343 27002 22218 
f 22218 27002 24445 
f 24446 27003 22219 
f 22219 27003 21341 
s 544
f 29237 24594 27004 
f 29571 32373 32339 
s 516
f 25272 33630 33204 
f 22148 21917 27005 
f 27006 33700 33746 
s 544
f 27006 21903 33457 
s 516
f 33442 33715 27007 
s 544
f 33036 21613 27007 
s 516
f 33421 33722 27008 
s 544
f 33110 33401 29712 
f 33346 33353 29381 
f 27009 21711 25611 
f 33364 33365 29373 
f 27010 24655 26999 
f 29425 33394 33388 
f 26993 21888 27011 
s 516
f 21754 27012 29484 
f 32939 32926 25779 
f 25791 33224 33634 
f 29491 27013 24648 
s 544
f 27014 24645 25618 
f 33320 33321 29360 
f 32866 32870 29351 
s 516
f 27015 24656 25764 
f 21745 27016 29490 
f 33030 33016 25752 
f 29384 27017 21874 
f 25759 33123 33170 
s 544
f 29440 33466 33465 
f 25873 24662 27018 
f 29377 33371 33369 
f 25620 21695 27019 
s 516
f 21703 27020 29472 
f 33089 33151 25864 
f 24657 27021 21948 
f 33169 33164 25743 
s 544
f 21945 33045 33033 
s 516
f 25735 24658 27022 
s 544
f 33017 33034 29392 
s 516
f 27023 24650 25742 
f 27024 24663 25868 
s 544
f 32986 33000 29400 
s 516
f 29468 27025 21699 
f 25776 32982 32989 
f 29493 24649 27026 
s 544
f 29375 32916 32902 
f 29396 32897 32889 
s 516
f 25631 24646 27027 
f 32800 33592 25630 
f 21999 27028 29427 
f 25766 24654 27029 
s 544
f 29365 32808 32801 
s 516
f 25780 24653 27030 
s 544
f 29347 32763 32758 
f 29415 32972 32639 
s 516
f 25740 24651 27031 
f 25763 24652 27032 
s 544
f 22214 32804 32618 
s 516
f 22006 27033 29343 
f 33284 33265 25769 
f 24442 27034 32710 
s 544
f 32271 32107 21619 
s 516
f 29272 27035 23225 
f 21675 32978 33008 
s 544
f 33088 33138 22226 
s 516
f 22075 27036 25886 
s 544
f 32838 32842 22182 
s 516
f 21736 27037 25887 
f 33534 32693 21625 
f 21624 27038 25645 
f 27039 21725 29167 
f 33306 33301 25830 
s 544
f 33146 33208 25269 
s 516
f 27040 24845 29514 
f 27041 21632 21633 
f 33260 33253 25885 
f 21630 21629 27042 
f 25834 33194 33101 
f 25689 21748 27043 
f 25271 33131 32652 
s 544
f 33215 33226 21709 
s 516
f 27044 21707 25662 
f 25848 23250 27045 
s 544
f 21794 33041 33014 
s 516
f 25659 21912 27046 
s 544
f 24019 33267 33249 
s 516
f 27047 23226 29510 
s 544
f 32440 32081 22582 
f 32876 32250 22498 
s 516
f 27048 23227 29511 
s 544
f 22097 32248 33694 
s 516
f 29555 24846 27049 
f 29558 24660 27050 
s 544
f 22938 32097 32974 
s 516
f 27176 24393 27051 
s 544
f 23855 27051 24393 
f 24391 27052 23827 
s 516
f 27052 24391 27286 
s 544
f 23809 27053 24375 
s 516
f 23760 24375 27053 
s 544
f 24392 27054 23879 
s 516
f 27054 24392 26035 
f 21668 27055 24414 
f 23577 24414 27055 
f 27056 24403 23568 
f 24403 27056 23471 
f 23415 27057 24416 
s 544
f 23573 24416 27057 
s 516
f 21685 27058 24413 
s 544
f 23576 24413 27058 
s 516
f 23579 27059 24817 
s 544
f 27059 23579 23571 
s 516
f 27098 27060 24415 
f 23569 24415 27060 
f 24410 27061 24816 
s 544
f 27061 24410 23572 
s 516
f 23584 27062 27105 
s 544
f 27062 23584 24389 
s 516
f 23073 27063 24417 
s 544
f 23570 24417 27063 
s 516
f 24406 27064 21664 
s 544
f 27064 24406 23574 
f 24390 23578 27065 
s 516
f 27104 27065 23578 
f 27066 24405 23575 
f 24405 27066 21761 
f 27053 27067 23760 
s 544
f 27067 32300 32297 
f 32286 32314 27068 
s 516
f 23406 27068 24407 
f 24004 27069 33197 
f 27069 24004 27189 
f 23835 27070 24082 
s 544
f 27070 33668 33666 
s 516
f 23409 27071 33010 
f 27071 23409 27249 
f 23060 27072 33136 
f 27072 23060 27209 
f 23373 27073 23782 
s 544
f 32361 32460 27073 
s 516
f 33083 27074 23394 
f 27458 23394 27074 
f 24377 27075 24705 
s 544
f 27075 33704 32860 
s 516
f 23385 27076 23860 
s 544
f 33696 33603 27076 
s 516
f 23376 27077 23804 
s 544
f 32569 32490 27077 
f 23567 27078 23979 
s 516
f 27078 33429 33710 
s 544
f 24383 27079 23390 
s 516
f 27079 32389 32502 
f 23305 27080 23762 
s 544
f 33598 33345 27080 
s 516
f 24386 27081 24007 
s 544
f 27081 33004 33705 
s 516
f 32976 27082 23411 
f 27275 23411 27082 
s 544
f 32566 33779 27083 
s 516
f 23412 27083 23910 
f 23803 27084 23377 
s 544
f 27084 32372 32485 
s 516
f 23398 27085 24380 
s 544
f 33714 33203 27085 
s 516
f 27469 27086 23728 
s 544
f 27086 33788 33805 
s 516
f 33090 27087 24009 
f 27166 24009 27087 
f 29716 27088 23350 
s 544
f 27088 33764 33775 
f 27089 33657 33631 
s 516
f 23823 27089 24704 
s 544
f 27090 32393 32410 
s 516
f 23837 27090 23383 
f 32959 27091 24706 
f 27376 24706 27091 
f 23894 27092 23307 
s 544
f 27092 32716 33724 
s 516
f 29720 27093 23136 
s 544
f 27093 33739 33692 
s 516
f 24703 27094 29715 
s 544
f 33679 33753 27094 
s 516
f 23410 27095 33252 
f 27095 23410 27459 
f 23869 27096 24081 
s 544
f 27096 33569 33589 
s 516
f 23059 27097 33618 
s 544
f 27097 23059 27278 
s 516
f 24415 23389 27098 
s 544
f 33683 27098 23389 
s 516
f 23112 27099 32724 
s 544
f 27099 23112 27311 
f 32515 33699 27100 
s 516
f 23125 27100 24379 
f 23386 27101 23858 
s 544
f 33695 33600 27101 
s 516
f 23561 27102 22494 
s 544
f 27102 33230 33167 
s 516
f 27362 23308 27103 
f 33601 27103 23308 
f 32872 27104 24006 
f 23578 24006 27104 
f 27105 24008 23584 
f 24008 27105 32722 
f 24011 27106 27476 
s 544
f 32843 32871 27106 
s 516
f 23816 27107 23408 
s 544
f 27107 33367 32818 
f 23726 27108 27472 
s 516
f 32743 33331 27108 
f 27174 24005 27109 
f 33209 27109 24005 
f 23413 27110 33144 
f 27110 23413 27394 
f 33578 27111 23727 
f 27248 23727 27111 
s 544
f 32617 32835 27112 
s 516
f 24003 27112 23581 
f 24083 27113 32696 
s 544
f 27113 24083 27199 
f 27114 32661 32589 
s 516
f 23565 27114 23761 
f 23799 27115 23378 
s 544
f 27115 32568 32562 
s 516
f 23907 27116 23395 
s 544
f 27116 32868 32559 
s 516
f 23381 27117 23825 
s 544
f 32402 32390 27117 
f 27118 33385 32546 
s 516
f 20866 27118 23071 
f 23781 27119 23180 
s 544
f 27119 32432 32547 
f 23375 27120 23805 
s 516
f 32452 32344 27120 
f 23824 27121 23379 
s 544
f 27121 32454 32448 
s 516
f 24010 27122 27471 
s 544
f 32433 32336 27122 
s 516
f 24395 27123 23382 
s 544
f 27123 32503 32406 
f 32400 32536 27124 
s 516
f 23380 27124 24394 
s 544
f 27125 32492 32369 
s 516
f 23834 27125 23384 
f 24421 27126 23388 
s 544
f 27126 32328 32323 
f 23405 27127 23808 
s 516
f 32306 32289 27127 
f 23407 27128 23936 
s 544
f 32301 32303 27128 
f 23075 23896 27129 
s 516
f 27465 27129 23896 
f 24404 25278 27130 
s 544
f 33645 27130 25278 
f 27131 23918 24371 
s 516
f 23918 27131 27466 
s 544
f 27132 23763 24818 
s 516
f 23763 27132 27463 
s 544
f 24093 23826 27133 
s 516
f 27464 27133 23826 
s 544
f 24409 24418 27134 
s 516
f 27392 27134 24418 
s 544
f 24401 24395 27135 
s 516
f 23382 27135 24395 
s 544
f 27136 23829 24408 
s 516
f 23829 27136 27386 
s 544
f 24399 24396 27137 
s 516
f 27243 27137 24396 
s 544
f 27138 24397 24400 
s 516
f 24397 27138 26068 
s 544
f 27139 24394 24402 
s 516
f 24394 27139 23380 
f 27140 23836 20784 
s 544
f 23836 27140 29731 
f 27141 23796 25292 
s 516
f 23796 27141 27492 
s 544
f 27420 27142 23797 
s 516
f 24815 23797 27142 
s 544
f 23832 23894 27143 
s 516
f 23307 27143 23894 
f 27144 23935 23554 
s 544
f 23935 27144 27369 
f 27424 27145 25296 
s 516
f 27485 25296 27145 
s 544
f 27146 23770 23921 
s 516
f 23770 27146 27384 
s 544
f 23873 23869 27147 
s 516
f 24081 27147 23869 
f 23825 27148 23381 
s 544
f 27148 23825 23890 
f 25300 27149 27433 
s 516
f 27149 25300 23707 
f 21771 23919 27150 
f 27177 27150 23919 
s 544
f 23931 25277 27151 
s 516
f 33651 27151 25277 
f 27152 23808 24420 
s 544
f 23808 27152 23405 
f 23934 23811 27153 
s 516
f 26033 27153 23811 
f 27154 24420 24369 
f 24420 27154 27152 
f 27142 27155 24815 
f 27155 33167 33230 
f 21645 23905 27156 
s 544
f 27397 27156 23905 
s 516
f 27157 23871 33159 
f 23871 27157 27457 
f 20892 23822 27158 
s 544
f 29735 27158 23822 
s 516
f 22031 23832 27159 
s 544
f 27143 27159 23832 
s 516
f 27160 25284 23842 
s 544
f 25284 27160 32780 
f 27161 23840 23566 
s 516
f 23840 27161 27318 
f 27162 23887 24036 
s 544
f 23887 27162 27505 
s 516
f 27163 32562 32568 
f 27319 27163 24047 
f 27164 25295 27490 
s 544
f 25295 27164 27445 
f 33529 27165 25283 
s 516
f 20879 25283 27165 
f 27087 25299 27166 
s 544
f 27448 27166 25299 
s 516
f 23383 27167 23837 
s 544
f 23893 23837 27167 
s 516
f 27406 27168 23933 
f 32813 23933 27168 
s 544
f 27169 23915 23916 
s 516
f 23915 27169 27385 
f 23844 23875 27170 
s 544
f 33593 27170 23875 
s 516
f 27171 23776 23070 
f 23776 27171 27408 
s 544
f 32624 27172 23472 
s 516
f 23895 23472 27172 
f 26016 27173 24378 
s 544
f 23787 24378 27173 
s 516
f 27109 23906 27174 
s 544
f 27506 27174 23906 
f 27175 23839 23850 
s 516
f 23839 27175 27396 
f 27051 24398 27176 
s 544
f 32631 27176 24398 
s 516
f 23919 23806 27177 
s 544
f 27352 27177 23806 
s 516
f 27178 23587 33244 
f 23587 27178 27453 
f 24109 23771 27179 
s 544
f 27330 27179 23771 
s 516
f 27180 25304 25310 
s 544
f 25304 27180 32450 
s 516
f 27181 23707 33024 
f 23707 27181 27149 
f 24923 23774 27182 
f 27344 27182 23774 
f 27355 27183 25293 
f 27477 25293 27183 
s 544
f 20735 27184 23841 
s 516
f 29718 23841 27184 
f 27185 23785 23929 
s 544
f 23785 27185 27203 
f 23885 23844 27186 
s 516
f 27170 27186 23844 
s 544
f 27187 24411 23563 
s 516
f 24411 27187 33318 
s 544
f 23926 23803 27188 
s 516
f 23377 27188 23803 
f 27189 23566 27069 
s 544
f 23566 27189 27161 
f 23930 23781 27190 
s 516
f 23180 27190 23781 
f 23928 23787 27191 
s 544
f 27173 27191 23787 
s 516
f 27192 23888 23830 
f 23888 27192 27370 
f 27193 23838 23881 
s 544
f 23838 27193 33605 
s 516
f 25286 27194 32519 
s 544
f 27194 25286 24382 
s 516
f 24419 23931 27195 
s 544
f 27151 27195 23931 
s 516
f 27196 25292 27489 
s 544
f 25292 27196 27141 
s 516
f 33699 32515 27197 
f 24113 27197 27271 
f 27198 25274 29727 
s 544
f 25274 27198 32602 
f 27199 23891 27113 
s 516
f 23891 27199 27361 
f 24034 23893 27200 
s 544
f 27167 27200 23893 
s 516
f 27179 27201 24109 
f 32343 24109 27201 
f 25310 27202 27180 
s 544
f 27202 25310 25298 
f 27203 23562 23785 
s 516
f 23562 27203 26040 
s 544
f 33827 27204 24111 
s 516
f 27473 24111 27204 
f 27205 24376 23810 
s 544
f 24376 27205 33734 
f 24041 23814 27206 
s 516
f 27291 27206 23814 
f 23852 23845 27207 
s 544
f 33596 27207 23845 
f 27208 33710 33429 
s 516
f 27182 27208 24923 
f 27209 23921 27072 
s 544
f 23921 27209 27146 
s 516
f 23564 23477 27210 
s 544
f 33590 27210 23477 
s 516
f 27211 32818 33367 
s 544
f 27417 27211 24819 
f 27153 27212 23934 
s 516
f 22590 23934 27212 
f 33287 25311 27213 
f 27226 27213 25311 
f 23925 23801 27214 
s 544
f 27359 27214 23801 
s 516
f 27215 25071 23870 
s 544
f 25071 27215 33073 
s 516
f 27501 27216 23786 
s 544
f 25291 23786 27216 
s 516
f 27217 25298 23076 
s 544
f 25298 27217 27202 
s 516
f 23064 23885 27218 
s 544
f 27186 27218 23885 
f 27219 23910 23904 
s 516
f 23910 27219 23412 
f 27220 25303 23560 
s 544
f 25303 27220 32984 
s 516
f 27221 23848 29719 
s 544
f 23848 27221 20842 
s 516
f 27212 27222 22590 
f 33163 22590 27222 
s 544
f 23884 23852 27223 
s 516
f 27207 27223 23852 
f 27224 23849 20758 
s 544
f 23849 27224 32674 
s 516
f 24046 23930 27225 
s 544
f 27190 27225 23930 
s 516
f 25311 25297 27226 
s 544
f 27247 27226 25297 
s 516
f 27227 32448 32454 
f 27292 27227 24040 
s 544
f 27228 23870 23867 
s 516
f 23870 27228 27215 
s 544
f 23833 23895 27229 
s 516
f 27172 27229 23895 
s 544
f 23297 23831 27230 
s 516
f 27261 27230 23831 
f 27231 23815 23106 
s 544
f 23815 27231 27393 
s 516
f 33185 23928 27232 
f 27191 27232 23928 
f 27233 23859 25282 
s 544
f 23859 27233 27338 
s 516
f 29722 23843 27234 
s 544
f 20709 27234 23843 
f 27235 24388 23768 
s 516
f 24388 27235 32666 
f 27236 32369 32492 
f 27350 27236 24035 
f 27237 25276 27470 
f 25276 27237 27329 
f 21581 23925 27238 
f 27214 27238 23925 
f 27239 23765 23764 
f 23765 27239 27413 
s 544
f 27240 23769 23922 
s 516
f 23769 27240 26028 
s 544
f 23923 23798 27241 
s 516
f 26020 27241 23798 
s 544
f 27242 23936 24412 
s 516
f 23936 27242 23407 
f 24396 23853 27243 
s 544
f 33617 27243 23853 
f 27244 23782 23932 
s 516
f 23782 27244 23373 
s 544
f 27475 24020 27245 
s 516
f 33359 27245 24020 
f 27246 23772 24110 
s 544
f 23772 27246 27427 
f 25297 25309 27247 
s 516
f 26026 27247 25309 
f 27111 23833 27248 
s 544
f 27229 27248 23833 
s 516
f 27249 23850 27071 
s 544
f 23850 27249 27175 
s 516
f 23819 23816 27250 
f 23408 27250 23816 
f 27479 24422 27251 
s 544
f 32342 27251 24422 
s 516
f 27252 24110 32371 
f 24110 27252 27246 
s 544
f 23889 23824 27253 
s 516
f 23379 27253 23824 
f 27254 23586 23982 
s 544
f 23586 27254 27451 
f 27216 27255 25291 
s 516
f 27486 25291 27255 
f 27256 23890 24037 
s 544
f 23890 27256 27148 
f 27257 23560 23777 
s 516
f 23560 27257 27220 
f 23153 23884 27258 
s 544
f 27223 27258 23884 
s 516
f 33779 32566 27259 
f 24372 27259 27357 
f 23817 23818 27260 
s 544
f 32954 27260 23818 
s 516
f 23831 23835 27261 
f 24082 27261 23835 
s 544
f 27262 23858 23861 
s 516
f 23858 27262 23386 
s 544
f 27468 24108 27263 
s 516
f 33538 27263 24108 
f 27264 24382 23773 
s 544
f 24382 27264 27194 
f 23909 23907 27265 
s 516
f 23395 27265 23907 
f 27266 23856 23156 
s 544
f 23856 27266 27340 
f 27267 23851 23730 
s 516
f 23851 27267 27301 
f 27268 23857 23863 
s 544
f 23857 27268 33635 
f 27269 23860 23864 
s 516
f 23860 27269 23385 
f 27270 23773 23666 
f 23773 27270 27264 
f 27271 23788 24113 
s 544
f 23788 27271 27382 
s 516
f 27272 23000 27482 
s 544
f 23000 27272 33829 
s 516
f 24816 27273 24410 
f 32871 32843 27273 
f 27274 32485 32372 
f 27310 27274 24042 
s 544
f 27439 27275 23846 
s 516
f 27082 23846 27275 
f 27276 23876 29726 
s 544
f 23876 27276 20907 
s 516
f 27277 23899 25302 
s 544
f 23899 27277 33713 
f 27278 23830 27097 
s 516
f 23830 27278 27192 
f 27279 23932 24045 
s 544
f 23932 27279 27244 
s 516
f 27280 32406 32503 
f 27462 27280 24038 
f 23154 23855 27281 
s 544
f 24393 27281 23855 
s 516
f 27282 23805 23920 
s 544
f 23805 27282 23375 
s 516
f 23439 27283 27285 
f 33600 33695 27283 
s 544
f 23813 23817 27284 
s 516
f 27260 27284 23817 
f 27285 23861 23439 
s 544
f 23861 27285 27262 
s 516
f 27286 25285 27052 
s 544
f 25285 27286 32604 
s 516
f 27287 23920 24044 
f 23920 27287 27282 
s 544
f 23792 24377 27288 
s 516
f 24705 27288 24377 
f 27289 23864 23440 
s 544
f 23864 27289 27269 
f 27147 27290 23873 
s 516
f 23486 23873 27290 
f 23814 24421 27291 
f 23388 27291 24421 
f 24040 23889 27292 
s 544
f 27253 27292 23889 
f 23779 23564 27293 
s 516
f 27210 27293 23564 
f 27294 23908 23766 
s 544
f 23908 27294 32712 
s 516
f 32536 32400 27295 
f 24039 27295 27460 
f 23378 27296 23799 
s 544
f 23924 23799 27296 
s 516
f 27328 27297 23441 
f 27297 32860 33704 
s 544
f 32289 32306 27298 
s 516
f 24369 27298 27154 
f 27299 25306 29721 
s 544
f 25306 27299 33468 
s 516
f 27300 23903 23559 
s 544
f 23903 27300 33716 
s 516
f 27301 23882 23851 
f 23882 27301 27341 
f 27302 25073 23886 
s 544
f 25073 27302 32654 
s 516
f 32460 32361 27303 
f 24045 27303 27279 
f 27304 33724 32716 
f 27159 27304 22031 
f 27452 27305 25290 
f 27474 25290 27305 
s 544
f 27306 25302 25308 
s 516
f 25302 27306 27277 
s 544
f 27307 23791 25289 
s 516
f 23791 27307 27500 
f 29723 25273 27308 
s 544
f 33496 27308 25273 
f 25287 23789 27309 
s 516
f 27494 27309 23789 
s 544
f 27188 27310 23926 
s 516
f 24042 23926 27310 
f 23764 27311 27239 
s 544
f 27311 23764 27099 
f 33331 32743 27312 
s 516
f 23471 27312 24403 
f 29724 23866 27313 
s 544
f 20800 27313 23866 
f 23158 23911 27314 
s 516
f 27336 27314 23911 
f 23135 23923 27315 
s 544
f 27241 27315 23923 
s 516
f 27316 25282 33573 
f 25282 27316 27233 
s 544
f 27317 23863 23872 
s 516
f 23863 27317 27268 
f 27318 24919 23840 
s 544
f 24919 27318 32756 
f 27296 27319 23924 
s 516
f 24047 23924 27319 
f 27315 27320 23135 
f 33093 23135 27320 
s 544
f 27321 23559 23778 
s 516
f 23559 27321 27300 
f 27322 25308 21981 
s 544
f 25308 27322 27306 
s 516
f 23149 23813 27323 
s 544
f 27284 27323 23813 
s 516
f 23912 23481 27324 
s 544
f 32686 27324 23481 
f 27325 24459 27483 
s 516
f 24459 27325 33427 
s 544
f 23874 23868 27326 
s 516
f 27372 27326 23868 
f 27327 23827 23664 
s 544
f 23827 27327 24391 
s 516
f 23441 23792 27328 
s 544
f 27288 27328 23792 
f 23812 27329 27493 
s 516
f 27329 23812 25276 
s 544
f 23771 24385 27330 
s 516
f 26030 27330 24385 
f 27331 33805 33788 
f 24416 27331 23415 
s 544
f 23892 23834 27332 
s 516
f 23384 27332 23834 
s 544
f 27333 23766 23914 
s 516
f 23766 27333 27294 
s 544
f 33238 27334 24381 
s 516
f 23794 24381 27334 
s 544
f 27335 23842 23878 
s 516
f 23842 27335 27160 
f 23911 23912 27336 
f 27324 27336 23912 
s 544
f 23854 23862 27337 
s 516
f 27438 27337 23862 
s 544
f 27338 23865 23859 
s 516
f 23865 27338 26037 
f 27339 23777 23731 
s 544
f 23777 27339 27257 
f 27340 23881 23856 
s 516
f 23881 27340 27193 
f 27341 25279 23882 
s 544
f 25279 27341 32670 
s 516
f 27342 25289 27478 
s 544
f 25289 27342 27307 
s 516
f 24370 23909 27343 
s 544
f 27265 27343 23909 
s 516
f 23774 23567 27344 
s 544
f 23979 27344 23567 
s 516
f 27345 25281 23883 
s 544
f 25281 27345 33638 
f 27346 23804 23927 
s 516
f 23804 27346 23376 
s 544
f 25307 25301 27347 
s 516
f 27400 27347 25301 
f 27348 23847 23223 
s 544
f 23847 27348 27368 
f 23793 27349 27497 
s 516
f 27349 23793 25288 
f 24035 23892 27350 
s 544
f 27332 27350 23892 
s 516
f 27351 25288 27484 
f 25288 27351 27349 
f 32526 27352 23476 
s 544
f 23806 23476 27352 
s 516
f 27353 23872 23155 
s 544
f 23872 27353 27317 
f 23778 27354 27321 
s 516
f 27354 23778 21677 
f 25293 23790 27355 
s 544
f 27498 27355 23790 
f 23877 23794 27356 
s 516
f 27334 27356 23794 
f 27357 23904 24372 
s 544
f 23904 27357 27219 
s 516
f 27358 33666 33668 
s 544
f 27230 27358 23297 
s 516
f 33354 27359 23360 
s 544
f 23801 23360 27359 
f 25294 23800 27360 
s 516
f 27502 27360 23800 
f 23828 27361 27418 
f 27361 23828 23891 
f 27103 23874 27362 
s 544
f 27326 27362 23874 
s 516
f 24368 27363 27419 
f 32303 32301 27363 
f 27364 23767 29725 
s 544
f 23767 27364 20816 
s 516
f 23913 23917 27365 
s 544
f 32545 27365 23917 
s 516
f 22023 24419 27366 
f 27195 27366 24419 
f 23900 24924 27367 
s 544
f 33776 27367 24924 
f 27368 23883 23847 
s 516
f 23883 27368 27345 
s 544
f 27369 23810 23935 
s 516
f 23810 27369 27205 
f 27370 24033 23888 
s 544
f 24033 27370 33487 
s 516
f 23440 27371 27289 
f 33603 33696 27371 
f 23868 22083 27372 
s 544
f 33046 27372 22083 
s 516
f 27373 33589 33569 
f 27290 27373 23486 
s 544
f 27337 27374 23854 
s 516
f 21592 23854 27374 
f 27488 25287 27375 
s 544
f 27309 27375 25287 
s 516
f 27091 25307 27376 
s 544
f 27347 27376 25307 
s 516
f 24814 27377 27409 
f 33203 33714 27377 
f 27378 33631 33657 
s 544
f 27133 27378 24093 
s 516
f 27379 32546 33385 
s 544
f 29736 27379 24813 
s 516
f 27380 23922 23708 
s 544
f 23922 27380 27240 
s 516
f 33345 33598 27381 
s 544
f 24818 27381 27132 
f 27382 24379 23788 
s 516
f 24379 27382 23125 
f 27383 23708 32887 
f 23708 27383 27380 
f 27384 24918 23770 
s 544
f 24918 27384 33691 
s 516
f 27385 24808 23915 
s 544
f 24808 27385 32715 
s 516
f 27386 24810 23829 
s 544
f 24810 27386 33595 
s 516
f 27387 23914 23157 
s 544
f 23914 27387 27333 
f 24375 27388 23809 
s 516
f 21916 23809 27388 
f 27467 25294 27389 
s 544
f 27360 27389 25294 
s 516
f 27390 23867 21809 
s 544
f 23867 27390 27228 
s 516
f 23821 25305 27391 
s 544
f 33566 27391 25305 
s 516
f 24418 24373 27392 
s 544
f 33582 27392 24373 
f 27393 23886 23815 
s 516
f 23886 27393 27302 
f 27394 23916 27110 
s 544
f 23916 27394 27169 
s 516
f 27395 23927 24043 
s 544
f 23927 27395 27346 
s 516
f 27396 24812 23839 
s 544
f 24812 27396 32968 
f 23905 23913 27397 
s 516
f 27365 27397 23913 
f 27450 27398 24809 
f 27398 33705 33004 
f 23880 24811 27399 
s 544
f 32903 27399 24811 
f 33730 27400 24820 
s 516
f 25301 24820 27400 
f 27401 23878 23074 
s 544
f 23878 27401 27335 
f 23390 27402 24383 
s 516
f 23775 24383 27402 
f 27481 24921 27403 
s 544
f 33023 27403 24921 
s 516
f 27404 32559 32868 
f 27343 27404 24370 
s 544
f 27405 23802 25275 
s 516
f 23802 27405 27499 
f 23933 23585 27406 
s 544
f 27456 27406 23585 
s 516
f 32835 32617 27407 
f 23982 27407 27254 
f 27408 23563 23776 
s 544
f 23563 27408 27187 
s 516
f 27409 23795 24814 
s 544
f 23795 27409 27428 
f 23820 23821 27410 
s 516
f 27391 27410 23821 
f 27225 27411 24046 
f 27411 32547 32432 
f 23150 23877 27412 
s 544
f 27356 27412 23877 
s 516
f 27413 21988 23765 
s 544
f 21988 27413 33338 
s 516
f 27414 24920 27480 
s 544
f 24920 27414 32855 
s 516
f 32720 32657 27415 
f 24036 27415 27162 
f 27416 24112 29717 
s 544
f 24112 27416 33737 
f 24819 23819 27417 
s 516
f 27250 27417 23819 
f 27418 23397 23828 
s 544
f 23397 27418 32699 
s 516
f 27419 24412 24368 
s 544
f 24412 27419 27242 
f 23797 23561 27420 
s 516
f 22494 27420 23561 
s 544
f 23879 27421 24392 
s 516
f 27421 23879 23690 
f 27422 32589 32661 
f 27429 27422 22574 
f 27423 23768 23898 
s 544
f 23768 27423 27235 
f 25296 23783 27424 
s 516
f 27495 27424 23783 
f 23761 27425 23565 
s 544
f 23780 23565 27425 
s 516
f 27426 25280 20771 
s 544
f 25280 27426 33757 
f 27427 24384 23772 
s 516
f 24384 27427 26029 
s 544
f 27428 24380 23795 
s 516
f 24380 27428 23398 
f 22574 23780 27429 
s 544
f 27425 27429 23780 
s 516
f 24048 23775 27430 
f 27402 27430 23775 
f 32490 32569 27431 
f 24043 27431 27395 
f 27432 23929 33296 
f 23929 27432 27185 
s 544
f 27433 23901 25300 
s 516
f 23901 27433 26034 
f 27434 32410 32393 
f 27200 27434 24034 
s 544
f 27435 32502 32389 
s 516
f 27430 27435 24048 
f 32390 32402 27436 
f 24037 27436 27256 
f 27437 23898 23555 
f 23898 27437 27423 
f 23862 25072 27438 
s 544
f 32779 27438 25072 
s 516
f 27399 27439 23880 
s 544
f 23846 23880 27439 
f 32344 32452 27440 
s 516
f 24044 27440 27287 
f 32336 32433 27441 
f 24817 27441 23579 
f 27388 27442 21916 
f 27442 32297 32300 
f 21797 23779 27443 
s 544
f 27293 27443 23779 
s 516
f 27444 23690 33615 
f 23690 27444 27421 
f 23784 27445 27496 
s 544
f 27445 23784 25295 
f 27206 27446 24041 
s 516
f 27446 32323 32328 
f 27447 25275 27487 
s 544
f 25275 27447 27405 
s 516
f 27367 27448 23900 
s 544
f 25299 23900 27448 
f 27410 27449 23820 
s 516
f 23221 23820 27449 
f 24809 23580 27450 
s 544
f 27454 27450 23580 
f 27451 23581 23586 
s 516
f 23581 27451 24003 
f 25290 23807 27452 
s 544
f 27491 27452 23807 
s 516
f 27453 23582 23587 
s 544
f 23582 27453 27455 
f 23580 24386 27454 
s 516
f 24007 27454 24386 
s 544
f 27455 24387 23582 
s 516
f 24387 27455 26052 
s 544
f 23585 23583 27456 
s 516
f 26069 27456 23583 
f 27457 24400 23871 
s 544
f 24400 27457 27138 
s 516
f 27074 24409 27458 
s 544
f 27134 27458 24409 
s 516
f 27459 24408 27095 
s 544
f 24408 27459 27136 
s 516
f 27460 24402 24039 
s 544
f 24402 27460 27139 
s 516
f 23065 24399 27461 
s 544
f 27137 27461 24399 
s 516
f 24038 24401 27462 
s 544
f 27135 27462 24401 
s 516
f 27463 23762 23763 
f 23762 27463 23305 
f 23826 23823 27464 
f 24704 27464 23823 
f 27130 27465 24404 
f 23896 24404 27465 
f 27466 24407 23918 
f 24407 27466 23406 
f 27389 23372 27467 
f 32093 27467 23372 
f 27055 27468 23577 
s 544
f 27263 23577 27468 
f 27057 27469 23573 
s 516
f 23728 23573 27469 
s 544
f 23374 27470 32140 
s 516
f 27470 23374 27237 
s 544
f 23571 27471 27059 
s 516
f 27471 23571 24010 
f 23568 27472 27056 
s 544
f 27472 23568 23726 
f 27058 27473 23576 
s 516
f 27204 23576 27473 
s 544
f 32118 27474 23364 
s 516
f 27305 23364 27474 
f 27060 27475 23569 
s 544
f 27245 23569 27475 
f 23572 27476 27061 
s 516
f 27476 23572 24011 
s 544
f 32216 27477 23365 
s 516
f 27183 23365 27477 
f 27478 23366 27342 
f 23366 27478 32153 
s 544
f 27063 27479 23570 
s 516
f 27251 23570 27479 
s 544
f 24389 27480 27062 
s 516
f 27480 24389 27414 
s 544
f 27065 27481 24390 
s 516
f 27403 24390 27481 
s 544
f 23574 27482 27064 
s 516
f 27482 23574 27272 
f 23575 27483 27066 
s 544
f 27483 23575 27325 
f 23369 27484 32223 
s 516
f 27484 23369 27351 
f 32175 27485 23363 
f 27145 23363 27485 
f 32143 27486 23370 
f 27255 23370 27486 
f 23371 27487 32177 
f 27487 23371 27447 
f 27375 23367 27488 
f 32173 27488 23367 
f 23368 27489 32182 
f 27489 23368 27196 
f 23362 27490 32225 
f 27490 23362 27164 
s 544
f 23807 24029 27491 
s 516
f 32236 27491 24029 
f 27492 24025 23796 
s 544
f 24025 27492 32114 
f 27493 24021 23812 
s 516
f 24021 27493 32232 
f 23789 24026 27494 
s 544
f 32130 27494 24026 
s 516
f 23783 24031 27495 
s 544
f 32112 27495 24031 
s 516
f 27496 24030 23784 
s 544
f 24030 27496 32110 
f 27497 24023 23793 
s 516
f 24023 27497 32205 
s 544
f 23790 24024 27498 
s 516
f 32259 27498 24024 
f 27499 24032 23802 
s 544
f 24032 27499 32132 
s 516
f 27500 24022 23791 
s 544
f 24022 27500 32256 
s 516
f 23786 24027 27501 
s 544
f 32165 27501 24027 
s 516
f 23800 24028 27502 
s 544
f 32077 27502 24028 
s 516
f 23387 27503 23897 
s 544
f 32657 32720 27503 
s 516
f 23902 24922 27504 
s 544
f 32731 27504 24922 
f 27505 23897 23887 
s 516
f 23897 27505 23387 
s 544
f 23906 23902 27506 
s 516
f 27504 27506 23902 
f 32314 32286 27507 
s 544
f 24371 27507 27131 
s 4128
f 27508 24424 30486 
s 4100
f 24424 27508 20829 
s 4128
f 30487 24423 27509 
s 4100
f 20722 27509 24423 
s off
f 33444 27510 21765 
f 29797 33445 33447 
f 33522 33518 30254 
f 21766 27511 33500 
s 544
f 27512 32784 32713 
s 516
f 32294 27512 24593 
s off
f 21077 22647 27513 
f 27513 22647 31669 
f 27514 21453 21067 
f 31670 21453 27514 
s 544
f 21791 27515 32111 
s 516
f 27515 21791 25884 
s 544
f 23469 27516 32167 
s 516
f 27516 23469 25812 
s 544
f 32196 27517 23401 
s 516
f 25842 23401 27517 
s 544
f 32080 27518 22205 
s 516
f 25841 22205 27518 
s 544
f 27519 25704 25801 
s 516
f 25704 27519 21947 
f 22139 27520 25702 
s 544
f 25800 25702 27520 
f 27521 25703 25808 
s 516
f 25703 27521 21742 
s 544
f 25806 25701 27522 
s 516
f 22317 27522 25701 
f 22131 25842 27523 
f 27517 27523 25842 
f 25813 27524 27806 
s 544
f 27524 25813 22094 
s 516
f 27875 27525 25843 
s 544
f 22096 25843 27525 
s 516
f 22042 27526 26150 
s 544
f 27526 22042 22583 
s 516
f 27698 27527 25840 
s 544
f 21902 25840 27527 
s 516
f 22407 25841 27528 
f 27518 27528 25841 
f 25635 27529 26149 
s 544
f 27529 25635 22413 
s 516
f 25884 27530 27515 
f 27530 25884 21790 
f 26147 27531 25845 
s 544
f 22459 25845 27531 
s 516
f 27785 27532 21915 
s 544
f 25714 21915 27532 
s 516
f 21882 27533 27627 
s 544
f 27533 21882 25715 
s 516
f 25812 27534 27516 
f 27534 25812 22449 
f 27535 24554 27911 
s 544
f 24554 27535 22354 
f 22352 27536 24552 
s 516
f 27908 24552 27536 
f 27537 24553 27909 
s 544
f 24553 27537 22348 
s 516
f 27914 24555 27538 
s 544
f 22342 27538 24555 
f 22358 27539 24563 
s 516
f 27910 24563 27539 
f 27906 24564 27540 
f 22362 27540 24564 
f 27541 24551 24833 
s 544
f 24551 27541 27939 
s 516
f 27542 24558 27917 
s 544
f 24558 27542 22346 
s 516
f 27543 24557 27907 
f 24557 27543 22344 
f 25258 24549 27544 
s 544
f 27940 27544 24549 
s 516
f 27545 24550 27912 
f 24550 27545 22340 
f 27915 24559 27546 
f 22350 27546 24559 
f 27547 24561 27913 
s 544
f 24561 27547 22360 
s 516
f 27916 24556 27548 
s 544
f 22356 27548 24556 
f 27549 24560 24548 
s 516
f 24560 27549 27930 
s 544
f 24547 24562 27550 
s 516
f 28034 27550 24562 
s 544
f 27551 33052 33103 
s 516
f 25634 27551 25024 
f 24309 27552 25807 
s 544
f 32626 32640 27552 
f 27845 27553 21820 
s 516
f 22225 21820 27553 
s 544
f 21714 27554 26124 
s 516
f 27554 21714 22579 
s 544
f 27923 24867 27555 
s 516
f 32103 27555 24867 
s 544
f 27918 24866 27556 
s 516
f 32262 27556 24866 
s 544
f 27557 32382 32595 
s 516
f 22382 27557 24888 
f 27887 23729 27558 
s 544
f 32689 27558 23729 
s 516
f 32354 32484 27559 
f 24887 27559 22423 
f 27560 24848 27730 
s 544
f 24848 27560 32659 
s 516
f 24886 27561 22155 
s 544
f 32593 32356 27561 
s 516
f 27562 24693 27928 
s 544
f 24693 27562 33808 
s 516
f 27563 32458 32495 
f 22376 27563 24897 
f 27564 24847 27872 
s 544
f 24847 27564 32721 
s 516
f 32640 32626 27565 
f 24873 27565 22153 
f 29781 23113 27566 
s 544
f 33814 27566 23113 
s 516
f 24894 27567 27882 
f 32405 32468 27567 
f 21806 27568 24882 
f 27568 33583 32837 
f 27569 32561 32482 
f 22378 27569 24899 
f 29787 24865 27570 
s 544
f 33475 27570 24865 
s 516
f 27571 24883 27611 
s 544
f 24883 27571 33560 
f 27572 32932 33434 
s 516
f 21846 27572 23981 
f 27573 32727 32651 
f 22082 27573 22079 
f 27574 32320 32309 
s 544
f 21836 27574 24868 
s 516
f 27575 23099 29785 
s 544
f 23099 27575 33756 
s 516
f 27576 33103 33052 
f 21919 27576 24694 
f 27922 24901 27577 
s 544
f 32827 27577 24901 
s 516
f 32609 32649 27578 
f 24872 27578 22112 
f 27579 32581 32605 
f 22575 27579 24712 
f 24896 27580 22380 
f 32576 32567 27580 
f 27581 32418 32556 
f 22335 27581 24912 
f 21772 27582 24692 
s 544
f 27582 32483 32523 
s 516
f 27583 32436 32508 
f 27883 27583 24893 
f 22364 27584 24890 
f 27584 32533 32494 
f 32465 32424 27585 
f 24911 27585 21839 
f 27586 32411 32428 
f 22425 27586 24885 
f 32420 32470 27587 
f 24892 27587 22370 
f 22368 27588 24891 
f 27588 32441 32396 
f 27589 32541 32364 
f 22366 27589 24889 
s 544
f 32345 32447 27590 
s 516
f 24898 27590 22374 
f 24863 27591 32198 
s 544
f 27591 24863 27920 
f 22223 22082 27592 
s 516
f 22079 27592 22082 
f 24692 27593 21772 
f 22403 21772 27593 
s 544
f 21747 27594 27652 
s 516
f 27594 21747 24451 
f 22497 27595 27838 
s 544
f 27595 22497 21851 
s 516
f 25607 27596 33081 
f 27596 25607 21863 
f 27745 27597 22080 
s 544
f 27597 32651 32727 
s 516
f 33250 27598 23723 
f 22032 23723 27598 
f 27936 25646 27599 
f 33213 27599 25646 
s 544
f 22167 22032 27600 
s 516
f 27598 27600 22032 
s 544
f 24552 27601 22352 
s 516
f 27896 22352 27601 
f 21933 27602 27654 
s 544
f 27602 21933 32874 
s 516
f 24427 27603 27618 
s 544
f 27603 24427 32765 
f 27629 27604 22312 
s 516
f 22313 22312 27604 
s 544
f 22604 27605 27759 
s 516
f 27605 22604 21864 
f 27608 27606 22531 
s 544
f 21785 22531 27606 
s 516
f 21580 25221 27607 
s 544
f 32748 27607 25221 
s 516
f 33232 27608 25881 
f 22531 25881 27608 
s 544
f 27609 22468 25857 
s 516
f 22468 27609 27734 
s 544
f 27622 27610 25853 
s 516
f 28039 25853 27610 
f 21868 27611 27701 
f 27611 21868 27571 
s 544
f 25694 27612 27668 
s 516
f 27612 25694 22030 
f 23400 25700 27613 
s 544
f 27620 27613 25700 
f 22008 27614 27637 
s 516
f 27614 22008 24449 
f 25817 27615 25725 
s 544
f 32812 25725 27615 
f 22529 21646 27616 
s 516
f 25261 27616 21646 
f 29777 27617 20734 
s 544
f 20743 20734 27617 
f 22117 27618 27807 
s 516
f 27618 22117 24427 
f 27653 27619 22432 
s 544
f 25693 22432 27619 
s 516
f 24885 27620 22425 
s 544
f 25700 22425 27620 
f 33285 25837 27621 
s 516
f 27833 27621 25837 
s 544
f 25853 25810 27622 
s 516
f 26129 27622 25810 
f 25816 27623 25220 
s 544
f 33336 25220 27623 
s 516
f 21652 27624 27744 
s 544
f 33784 33795 27624 
s 516
f 26138 27625 22591 
s 544
f 21684 22591 27625 
s 516
f 22135 22563 27626 
s 544
f 27645 27626 22563 
s 516
f 25820 27627 32749 
f 27627 25820 21882 
s 544
f 21872 27628 25260 
s 516
f 27628 21872 22491 
f 24889 27629 22366 
s 544
f 22312 22366 27629 
s 516
f 22333 22487 27630 
s 544
f 27655 27630 22487 
f 27823 27631 22329 
s 516
f 27631 32595 32382 
f 22340 27632 24550 
s 544
f 27632 22340 27900 
s 516
f 22589 27633 27738 
s 544
f 27633 22589 21726 
s 516
f 29780 27634 20708 
s 544
f 20717 20708 27634 
f 25609 27635 27749 
s 516
f 27635 25609 32694 
f 23725 27636 27768 
s 544
f 27636 23725 33323 
s 516
f 25809 27637 27675 
s 544
f 27637 25809 22008 
s 516
f 27662 27638 21808 
s 544
f 27638 32837 33583 
s 516
f 27671 27639 21939 
s 544
f 32811 21939 27639 
s 516
f 22001 27640 32823 
f 27640 22001 22174 
f 27649 27641 22640 
s 544
f 21702 22640 27641 
f 24868 27642 21836 
s 516
f 25685 21836 27642 
f 21965 27643 26095 
s 544
f 27643 21965 22401 
s 516
f 23693 27644 32962 
f 27644 23693 21810 
f 26096 27645 22133 
s 544
f 22563 22133 27645 
s 516
f 27691 27646 22325 
s 544
f 27646 32396 32441 
s 516
f 20841 27647 29778 
s 544
f 27647 20841 20850 
f 33599 33611 27648 
s 516
f 22715 27648 27751 
f 33005 27649 21700 
f 22640 21700 27649 
f 27667 27650 22593 
s 544
f 21582 22593 27650 
s 516
f 25860 27651 27669 
s 544
f 27651 25860 32845 
s 516
f 22493 27652 27728 
s 544
f 27652 22493 21747 
s 516
f 22432 21628 27653 
f 33210 27653 21628 
s 544
f 22814 27654 27693 
s 516
f 27654 22814 21933 
f 24912 27655 22335 
s 544
f 22487 22335 27655 
s 516
f 22174 27656 27640 
s 544
f 27656 22174 22000 
s 516
f 29779 27657 21909 
s 544
f 33363 21909 27657 
f 27619 27658 25693 
s 516
f 24661 25693 27658 
s 544
f 24555 27659 22342 
s 516
f 27890 22342 27659 
f 20759 27660 20754 
s 544
f 27660 20759 33366 
s 516
f 22217 25855 27661 
f 26084 27661 25855 
s 544
f 27682 27662 22521 
s 516
f 21808 22521 27662 
s 544
f 22389 27663 27702 
s 516
f 27663 22389 21840 
f 25702 27664 22139 
s 544
f 32930 22139 27664 
f 21843 27665 27754 
s 516
f 27665 21843 21974 
f 22155 27666 24886 
f 27666 22155 25699 
f 27607 27667 21580 
f 22593 21580 27667 
f 22029 27668 27756 
s 544
f 27668 22029 25694 
f 22183 27669 27688 
s 516
f 27669 22183 25860 
s 544
f 27861 27670 21751 
s 516
f 25061 21751 27670 
f 21939 22816 27671 
s 544
f 27686 27671 22816 
s 516
f 27674 27672 21698 
s 544
f 33375 21698 27672 
s 516
f 25699 27673 27666 
s 544
f 27673 25699 24310 
s 516
f 27778 27674 22642 
f 21698 22642 27674 
f 22009 27675 33309 
f 27675 22009 25809 
f 22423 27676 24887 
s 544
f 27676 22423 25698 
f 22260 27677 27706 
s 516
f 32447 32345 27677 
f 24428 27678 20767 
s 544
f 27678 24428 33536 
s 516
f 22049 27679 27697 
s 544
f 27679 22049 32888 
s 516
f 21713 27680 27886 
s 544
f 27680 21713 33357 
f 21891 22179 27681 
s 516
f 27837 27681 22179 
f 24882 27682 21806 
s 544
f 22521 21806 27682 
s 516
f 22045 27683 27703 
s 544
f 27683 22045 32899 
f 22596 27684 27737 
s 516
f 27684 22596 21731 
f 27710 27685 21593 
s 544
f 21875 21593 27685 
f 22816 21941 27686 
s 516
f 26094 27686 21941 
f 21798 22405 27687 
s 544
f 27772 27687 22405 
s 516
f 21738 27688 26101 
s 544
f 27688 21738 22183 
f 22227 27689 27721 
s 516
f 27689 22227 25859 
s 544
f 21726 27690 27633 
s 516
f 27690 21726 24659 
s 544
f 27717 27691 22324 
s 516
f 22325 22324 27691 
f 26098 27692 22202 
s 544
f 27931 22202 27692 
f 27693 21932 22814 
s 516
f 21932 27693 26091 
f 21937 27694 27731 
s 544
f 27694 21937 33047 
f 27747 27695 22635 
s 516
f 21660 22635 27695 
s 544
f 21824 27696 27854 
s 516
f 32324 32329 27696 
f 27697 22611 22049 
s 544
f 22611 27697 27718 
s 516
f 25840 23402 27698 
f 32759 27698 23402 
f 27719 27699 21927 
s 544
f 32907 21927 27699 
s 516
f 22022 27700 26102 
s 544
f 27700 22022 21899 
s 516
f 27701 22409 21868 
s 544
f 22409 27701 27816 
f 27702 21839 22389 
s 516
f 21839 27702 24911 
f 27703 22613 22045 
s 544
f 22613 27703 27722 
s 516
f 27724 27704 25219 
s 544
f 32921 25219 27704 
s 516
f 27705 22374 22261 
f 22374 27705 24898 
f 22261 27706 27705 
s 544
f 27706 22261 22260 
s 516
f 25224 27707 29786 
s 544
f 27707 25224 33760 
f 25836 27708 27828 
s 516
f 27708 25836 33324 
s 544
f 27685 27709 21875 
s 516
f 23758 21875 27709 
f 33234 27710 23692 
f 21593 23692 27710 
f 27712 27711 21834 
s 544
f 27711 32309 32320 
s 516
f 21834 25685 27712 
f 27642 27712 25685 
f 24564 27713 22362 
s 544
f 27902 22362 27713 
s 516
f 22170 27714 27757 
s 544
f 27714 22170 33013 
s 516
f 27661 27715 22217 
s 544
f 25218 22217 27715 
s 516
f 27727 27716 22301 
s 544
f 27716 32482 32561 
s 516
f 24891 27717 22368 
s 544
f 22324 22368 27717 
s 516
f 22050 27718 26103 
s 544
f 27718 22050 22611 
s 516
f 21927 22784 27719 
s 544
f 27743 27719 22784 
f 22346 27720 24558 
s 516
f 27720 22346 27892 
f 22078 27721 26130 
s 544
f 27721 22078 22227 
s 516
f 22046 27722 26136 
s 544
f 27722 22046 22613 
s 516
f 25859 27723 27689 
s 544
f 27723 25859 33143 
s 516
f 25219 25692 27724 
s 544
f 27755 27724 25692 
s 516
f 27765 27725 25891 
s 544
f 33072 25891 27725 
f 33254 25216 27726 
s 516
f 27827 27726 25216 
s 544
f 27748 27727 22302 
s 516
f 22301 22302 27727 
f 27728 21746 22493 
f 21746 27728 33054 
s 544
f 21899 27729 27700 
s 516
f 27729 21899 28046 
s 544
f 21859 27730 27878 
s 516
f 27730 21859 27560 
s 544
f 22826 27731 27775 
s 516
f 27731 22826 21937 
f 27774 27732 22265 
s 544
f 27732 32495 32458 
s 516
f 25839 27733 33584 
f 27733 25839 21730 
f 21634 27734 33279 
f 27734 21634 22468 
s 544
f 20808 20799 27735 
s 516
f 29782 27735 20799 
f 27762 27736 23724 
s 544
f 32980 23724 27736 
s 516
f 21730 27737 27733 
s 544
f 27737 21730 22596 
s 516
f 21727 27738 33317 
f 27738 21727 22589 
f 27739 21626 22411 
f 21626 27739 26139 
f 21810 27740 27644 
s 544
f 27740 21810 21756 
s 516
f 27741 21828 27884 
s 544
f 21828 27741 25259 
f 26121 27742 21978 
s 516
f 22695 21978 27742 
s 544
f 22784 21929 27743 
s 516
f 26099 27743 21929 
s 544
f 22644 27744 27794 
s 516
f 27744 22644 21652 
s 544
f 27592 27745 22223 
s 516
f 22080 22223 27745 
f 25701 27746 22317 
s 544
f 27746 32508 32436 
f 22635 21658 27747 
s 516
f 26498 27747 21658 
f 24899 27748 22378 
s 544
f 22302 22378 27748 
s 516
f 22411 27749 27739 
s 544
f 27749 22411 25609 
s 516
f 22320 27750 27796 
s 544
f 32470 32420 27750 
f 22716 27751 27779 
s 516
f 27751 22716 22715 
f 24559 27752 22350 
s 544
f 27901 22350 27752 
f 27767 27753 22229 
s 516
f 28044 22229 27753 
f 25794 27754 32831 
s 544
f 27754 25794 21843 
f 25692 22054 27755 
s 516
f 26142 27755 22054 
f 27756 25835 22029 
f 25835 27756 33158 
f 27757 22527 22170 
s 544
f 22527 27757 27813 
s 516
f 20887 27758 20889 
s 544
f 24931 20889 27758 
s 516
f 21863 27759 27596 
s 544
f 27759 21863 22604 
s 516
f 21976 22695 27760 
f 27742 27760 22695 
f 22153 27761 24873 
s 544
f 27761 22153 27889 
f 27788 27762 22084 
s 516
f 23724 22084 27762 
s 544
f 21756 27763 27740 
s 516
f 27763 21756 24452 
s 544
f 27786 27764 22308 
s 516
f 22309 22308 27764 
f 25891 25858 27765 
s 544
f 27792 27765 25858 
f 27814 27766 21774 
s 516
f 27766 32523 32483 
f 26097 27767 22072 
s 544
f 22229 22072 27767 
s 516
f 21990 27768 27821 
f 27768 21990 23725 
f 27791 27769 25691 
s 544
f 33057 25691 27769 
s 516
f 25632 27770 29775 
s 544
f 27770 25632 33769 
s 516
f 22344 27771 24557 
s 544
f 27771 22344 27904 
s 516
f 26079 27772 21800 
s 544
f 22405 21800 27772 
s 516
f 21949 27773 33180 
f 27773 21949 27880 
s 544
f 27793 27774 22266 
s 516
f 22265 22266 27774 
f 21936 27775 26131 
s 544
f 27775 21936 22826 
f 27715 27776 25218 
s 516
f 32668 25218 27776 
s 544
f 25857 27777 27609 
s 516
f 27777 25857 24666 
f 22642 21696 27778 
s 544
f 26123 27778 21696 
s 516
f 21680 27779 25893 
s 544
f 27779 21680 22716 
s 516
f 27799 27780 21968 
s 544
f 25253 21968 27780 
s 516
f 24429 27781 27800 
s 544
f 27781 24429 33202 
f 22257 27782 27805 
s 516
f 27782 22257 22256 
s 544
f 22000 27783 27656 
s 516
f 27783 22000 23757 
f 22491 27784 27628 
f 27784 22491 21873 
f 32882 27785 25687 
f 21915 25687 27785 
f 24890 27786 22364 
s 544
f 22308 22364 27786 
s 516
f 25854 27787 27803 
s 544
f 27787 25854 33247 
f 22084 21995 27788 
s 516
f 26119 27788 21995 
f 20815 27789 29784 
s 544
f 27789 20815 20824 
s 516
f 20861 27790 20863 
s 544
f 33396 20863 27790 
f 27817 27791 22427 
s 516
f 25691 22427 27791 
f 26120 27792 22470 
s 544
f 25858 22470 27792 
s 516
f 24897 27793 22376 
s 544
f 22266 22376 27793 
s 516
f 21651 27794 25263 
s 544
f 27794 21651 22644 
f 24563 27795 22358 
s 516
f 27903 22358 27795 
s 544
f 22322 27796 27840 
s 516
f 27796 22322 22320 
f 27626 27797 22135 
s 544
f 32767 22135 27797 
s 516
f 27764 27798 22309 
s 544
f 27798 32494 32533 
s 516
f 21968 21846 27799 
f 23981 27799 21846 
s 544
f 22401 27800 27643 
s 516
f 27800 22401 24429 
f 28047 21891 27801 
s 544
f 27681 27801 21891 
s 516
f 33622 27802 21760 
s 544
f 22573 21760 27802 
f 25690 27803 27829 
s 516
f 27803 25690 25854 
f 27820 27804 21648 
s 544
f 27804 33802 33800 
s 516
f 22380 27805 24896 
s 544
f 27805 22380 22257 
s 516
f 23356 27806 32794 
f 27806 23356 25813 
f 22163 27807 26100 
s 544
f 27807 22163 22117 
s 516
f 27760 27808 21976 
s 544
f 33469 21976 27808 
f 27809 23654 33243 
s 516
f 23654 27809 25818 
s 544
f 22384 27810 27836 
s 516
f 27810 22384 21885 
f 21873 27811 27784 
s 544
f 33604 33649 27811 
s 516
f 24694 27812 21919 
s 544
f 27888 21919 27812 
s 516
f 22171 27813 26105 
s 544
f 27813 22171 22527 
s 516
f 27593 27814 22403 
s 544
f 21774 22403 27814 
f 27802 27815 22573 
s 516
f 21758 22573 27815 
f 21869 27816 33557 
s 544
f 27816 21869 22409 
s 516
f 26083 27817 21943 
s 544
f 22427 21943 27817 
f 25698 27818 27676 
s 516
f 27818 25698 23399 
f 20906 27819 29783 
s 544
f 27819 20906 20915 
f 27616 27820 22529 
s 516
f 21648 22529 27820 
s 544
f 22387 27821 26125 
s 516
f 27821 22387 21990 
f 22014 27822 26085 
f 27822 22014 22197 
f 27839 27823 25697 
s 544
f 22329 25697 27823 
f 24556 27824 22356 
s 516
f 27895 22356 27824 
f 28037 21758 27825 
f 27815 27825 21758 
s 544
f 22360 27826 24561 
s 516
f 27826 22360 27899 
f 25216 21972 27827 
s 544
f 27858 27827 21972 
f 27828 22197 25836 
s 516
f 22197 27828 27822 
f 21710 27829 26116 
s 544
f 27829 21710 25690 
f 27780 27830 25253 
s 516
f 27830 33434 32932 
f 22126 27831 26127 
s 544
f 27831 22126 27932 
s 516
f 27832 22553 22554 
f 22553 27832 32967 
s 544
f 27866 27833 22495 
s 516
f 25837 22495 27833 
s 544
f 21734 27834 24565 
s 516
f 27834 21734 27894 
s 544
f 32854 32960 27835 
s 516
f 21983 27835 23467 
s 544
f 27836 25856 22384 
s 516
f 25856 27836 25001 
f 32859 27837 21893 
f 22179 21893 27837 
f 21852 27838 33171 
f 27838 21852 22497 
f 24888 27839 22382 
f 25697 22382 27839 
f 22370 27840 24892 
s 544
f 27840 22370 22322 
s 516
f 21735 27841 33051 
f 27841 21735 27938 
s 544
f 21706 27842 27867 
s 516
f 27842 21706 23699 
s 544
f 27843 22112 22124 
s 516
f 22112 27843 24872 
f 21974 27844 27665 
f 27844 21974 23975 
s 544
f 21820 25252 27845 
s 516
f 33629 27845 25252 
s 544
f 22124 27846 27843 
s 516
f 27846 22124 22113 
f 24308 27847 29788 
s 544
f 33725 33431 27847 
s 516
f 22113 27848 27846 
s 544
f 32649 32609 27848 
f 27641 27849 21702 
s 516
f 23698 21702 27849 
f 27670 27850 25061 
s 544
f 27850 32605 32581 
s 516
f 25684 27851 25264 
f 27851 25684 22419 
f 22256 27852 27782 
s 544
f 32567 32576 27852 
s 516
f 27604 27853 22313 
s 544
f 27853 32364 32541 
f 27854 22419 21824 
s 516
f 22419 27854 27851 
f 23399 27855 27818 
s 544
f 32484 32354 27855 
s 516
f 20785 27856 20780 
s 544
f 27856 20785 24933 
s 516
f 21742 27857 25703 
s 544
f 32468 32405 27857 
s 516
f 26086 27858 22181 
s 544
f 21972 22181 27858 
f 22348 27859 24553 
s 516
f 27859 22348 27898 
f 21840 27860 27663 
s 544
f 32424 32465 27860 
s 516
f 24712 27861 22575 
s 544
f 21751 22575 27861 
s 516
f 27630 27862 22333 
s 544
f 27862 32556 32418 
f 27863 32428 32411 
s 516
f 27613 27863 23400 
s 544
f 22354 27864 24554 
s 516
f 27864 22354 27897 
s 544
f 24310 27865 27673 
s 516
f 32356 32593 27865 
f 26088 27866 21913 
s 544
f 22495 21913 27866 
s 516
f 22646 27867 27879 
s 544
f 27867 22646 21706 
f 24566 27868 21641 
s 516
f 26145 21641 27868 
f 21827 27869 25636 
s 544
f 32292 32315 27869 
s 516
f 21885 27870 27810 
s 544
f 32280 32307 27870 
s 516
f 28052 21684 27871 
s 544
f 27625 27871 21684 
f 21851 27872 27595 
s 516
f 27872 21851 27564 
s 544
f 26092 27873 22106 
s 516
f 27885 22106 27873 
f 23712 21785 27874 
s 544
f 27606 27874 21785 
s 516
f 25843 22098 27875 
f 32176 27875 22098 
f 20874 27876 24426 
s 544
f 33516 24426 27876 
f 27600 27877 22167 
s 516
f 22034 22167 27877 
f 22554 27878 27832 
s 544
f 27878 22554 21859 
s 516
f 21705 27879 33804 
f 27879 21705 22646 
s 544
f 25801 27880 27519 
s 516
f 27880 25801 27773 
f 26135 25800 27881 
s 544
f 27520 27881 25800 
s 516
f 27882 25808 24894 
s 544
f 25808 27882 27521 
f 27522 27883 25806 
s 516
f 24893 25806 27883 
f 27884 25636 27741 
f 25636 27884 21827 
f 27873 25816 27885 
f 25220 27885 25816 
f 22579 27886 27554 
f 27886 22579 21713 
f 27553 27887 22225 
f 27558 22225 27887 
s 544
f 27812 25634 27888 
s 516
f 25024 27888 25634 
s 544
f 27889 25807 27761 
s 516
f 25807 27889 24309 
f 27659 24906 27890 
s 544
f 32219 27890 24906 
s 516
f 27891 24548 26148 
s 544
f 24548 27891 27549 
s 516
f 27892 24907 27720 
s 544
f 24907 27892 32067 
s 516
f 26146 24547 27893 
s 544
f 27550 27893 24547 
f 24691 27894 32718 
s 516
f 27894 24691 27834 
s 544
f 32109 27895 24909 
s 516
f 27824 24909 27895 
f 27601 24905 27896 
s 544
f 32099 27896 24905 
s 516
f 27897 24908 27864 
s 544
f 24908 27897 32079 
s 516
f 27898 24904 27859 
s 544
f 24904 27898 32211 
s 516
f 27899 24869 27826 
s 544
f 24869 27899 32228 
f 27900 24914 27632 
s 516
f 24914 27900 32239 
f 32197 27901 24902 
s 544
f 27752 24902 27901 
s 516
f 32200 27902 24910 
s 544
f 27713 24910 27902 
s 516
f 27795 24900 27903 
s 544
f 32189 27903 24900 
f 27904 24903 27771 
s 516
f 24903 27904 32237 
f 27544 27905 25258 
f 33128 25258 27905 
s 544
f 32120 27906 22305 
s 516
f 27540 22305 27906 
s 544
f 22268 27907 32089 
s 516
f 27907 22268 27543 
f 32194 27908 22289 
f 27536 22289 27908 
f 22276 27909 32187 
f 27909 22276 27537 
f 32096 27910 22249 
f 27539 22249 27910 
f 22296 27911 32191 
f 27911 22296 27535 
s 544
f 22252 27912 32151 
s 516
f 27912 22252 27545 
f 22244 27913 32181 
f 27913 22244 27547 
f 32146 27914 22273 
f 27538 22273 27914 
s 544
f 32152 27915 22285 
s 516
f 27546 22285 27915 
f 32252 27916 22293 
f 27548 22293 27916 
f 22280 27917 32246 
f 27917 22280 27542 
s 544
f 27556 22131 27918 
s 516
f 27523 27918 22131 
s 544
f 27525 27919 22096 
s 516
f 27937 22096 27919 
f 21790 27920 27530 
s 544
f 27920 21790 27591 
f 22583 27921 27526 
s 516
f 27921 22583 27933 
s 544
f 27527 27922 21902 
s 516
f 27577 21902 27922 
s 544
f 27555 22407 27923 
s 516
f 27528 27923 22407 
f 27924 25715 26143 
s 544
f 25715 27924 27533 
s 516
f 27934 22459 27925 
s 544
f 27531 27925 22459 
f 22413 27926 27529 
s 516
f 27926 22413 27935 
s 544
f 27532 27927 25714 
s 516
f 26144 25714 27927 
s 544
f 22094 27928 27524 
s 516
f 27928 22094 27562 
f 22449 27929 27534 
s 544
f 27929 22449 28038 
s 516
f 27930 24425 24560 
s 544
f 24425 27930 32154 
f 27692 25817 27931 
s 516
f 25725 27931 25817 
s 544
f 27932 25818 27831 
s 516
f 25818 27932 23654 
f 27933 25882 27921 
s 544
f 25882 27933 32063 
f 32066 27934 25883 
s 516
f 27925 25883 27934 
f 27935 25811 27926 
s 544
f 25811 27935 32087 
s 516
f 27599 24566 27936 
s 544
f 21641 27936 24566 
s 516
f 27919 24913 27937 
s 544
f 32162 27937 24913 
s 516
f 27938 24565 27841 
s 544
f 24565 27938 21734 
f 27939 23467 24551 
s 516
f 23467 27939 21983 
s 544
f 24549 23466 27940 
s 516
f 21674 27940 23466 
s 4128
f 27941 25602 26077 
s 4100
f 25602 27941 20837 
s 4128
f 26076 25603 27942 
s 4100
f 20730 27942 25603 
s off
f 31131 27943 29631 
f 23484 23298 27943 
f 29632 27944 31130 
f 27944 23299 23485 
s 8194
f 30308 28551 27945 
s 2147483647
f 22002 23404 27945 
s 8194
f 27946 28550 30307 
s 2147483647
f 27946 23403 22004 
s off
f 32590 27947 30877 
f 22484 27947 30802 
f 22701 27948 23650 
f 31172 31156 22701 
f 23651 27949 22702 
f 22702 31157 31173 
s 544
f 27758 27950 24931 
s 2147483647
f 24931 27950 22879 
s 544
f 24929 27951 26113 
s 2147483647
f 21958 27951 24929 
s 4100
f 20901 27952 24930 
s 2147483647
f 24930 27952 20899 
f 24932 27953 21889 
s 544
f 26122 27953 24932 
s 4100
f 24926 27954 20793 
s 2147483647
f 20798 27954 24926 
s 544
f 24928 27955 26090 
s 2147483647
f 22109 27955 24928 
s 544
f 26089 27956 24927 
s 2147483647
f 24927 27956 21960 
f 22863 27957 24933 
s 544
f 24933 27957 27856 
s off
f 20970 31160 22700 
f 22700 31160 31164 
f 22699 31163 20971 
f 31165 31163 22699 
f 22659 31106 31184 
f 31185 31107 22658 
f 22661 31108 31181 
f 31180 31109 22660 
f 31183 31110 22662 
f 22663 31111 31182 
f 22664 31112 31179 
f 31178 31113 22665 
f 30915 31114 22667 
f 22666 31115 30914 
f 30916 31116 22668 
f 22669 31117 30917 
f 30919 31118 22671 
f 22670 31119 30918 
f 20980 31188 31201 
f 21320 27974 20980 
f 20984 27975 21321 
f 31200 31189 20984 
f 23520 31208 27976 
f 27976 31208 22516 
f 22515 31209 27977 
f 27977 31209 23521 
f 29805 27978 25588 
f 23038 27978 21484 
f 21095 27979 29809 
f 27979 29814 29799 
f 21322 27980 22536 
f 22536 27980 29814 
f 22690 27981 29803 
f 32521 27981 22690 
f 21484 27982 23038 
f 23038 27982 21181 
f 29835 27983 21622 
f 21622 27983 23100 
f 21621 27984 29834 
f 23101 27984 21621 
f 29837 27985 23177 
f 23177 27985 21584 
f 21585 27986 23176 
f 23176 27986 29836 
f 21621 29834 27987 
f 23103 27987 21213 
f 27988 29835 21622 
f 21212 27988 23102 
f 22959 27989 21372 
f 29838 27989 23304 
f 23303 27990 29839 
f 21371 27990 22960 
f 31701 27991 21193 
f 27991 31701 21408 
f 22960 27992 21371 
f 21181 27992 22960 
f 21372 27993 22959 
f 22959 27993 21225 
f 23102 27994 21212 
f 21201 27994 23102 
f 21213 27995 23103 
f 23103 27995 21202 
f 21201 27996 23100 
f 23100 27996 21622 
f 21621 27997 23101 
f 23101 27997 21202 
f 21585 27998 21202 
f 23176 27998 21585 
f 21201 27999 21584 
f 21584 27999 23177 
f 20971 31191 20981 
f 20981 31191 31187 
f 20970 20982 31190 
f 31186 31190 20982 
f 31894 28002 31048 
f 31792 21286 28002 
f 31792 28002 22525 
f 31893 31792 22525 
f 21237 28004 22976 
f 21087 22976 28004 
f 22975 28005 21236 
f 28005 22975 21088 
f 22973 28006 21123 
s 1
f 20949 28006 22973 
f 20942 22974 28007 
s off
f 21122 28007 22974 
f 31234 31246 22503 
f 25991 31258 31246 
f 31247 31259 25990 
f 22502 31247 31235 
f 31228 31239 22506 
f 25993 31261 31239 
f 22505 31238 31229 
f 31238 31260 25994 
f 31233 31245 22500 
f 25997 31254 31245 
f 31227 31240 22504 
f 30370 22504 28013 
f 22501 31241 31226 
f 28014 22501 30371 
f 31242 31255 25992 
f 22507 31242 31232 
f 21090 28016 23533 
f 31230 31244 21090 
f 23534 28017 21091 
f 21091 31243 31231 
f 23528 28018 22977 
f 22977 31237 31225 
f 22978 28019 23527 
f 31224 31236 22978 
f 21018 31301 31260 
f 33661 31301 21018 
f 21017 31300 33462 
f 31261 31300 21017 
f 31299 20940 20935 
f 20940 31299 31259 
f 31258 31298 20939 
f 20931 20939 31298 
f 21002 31294 31255 
f 22156 31294 21002 
f 21394 31290 31250 
s 1
f 21072 31290 21394 
f 21395 31291 21071 
s off
f 31251 31291 21395 
f 31254 31293 21003 
f 21003 31293 22157 
f 22158 31292 20966 
f 20966 31292 31253 
f 31252 31295 20965 
f 20965 31295 22159 
f 31296 23043 20949 
f 23043 31296 31256 
f 20942 23042 31297 
f 31257 31297 23042 
f 30803 28032 20927 
f 28032 30803 21491 
f 20987 28033 30804 
f 21492 30804 28033 
s 1026
f 27550 28034 24075 
s 1040
f 28034 32170 32043 
f 21822 28035 32324 
s 1026
f 28035 21822 21823 
f 24077 28036 32854 
f 28036 24077 29546 
f 21758 28037 21759 
s 1040
f 28037 33491 33555 
f 22584 28038 22449 
s 1026
f 32086 32105 28038 
f 25853 28039 22518 
s 1040
f 28039 32732 33070 
s 8194
f 25598 28040 26072 
s 8208
f 28040 25598 22944 
s 8194
f 26073 28041 25599 
s 8208
f 22943 25599 28041 
s 1026
f 24078 28042 33599 
f 28042 24078 29229 
f 33571 28043 24079 
f 29227 24079 28043 
f 22229 28044 22070 
s 1040
f 28044 32895 33155 
f 28045 21870 29355 
s 1026
f 21870 28045 33604 
f 24076 28046 21899 
s 1040
f 32760 32774 28046 
f 28047 32793 32833 
s 1026
f 21891 28047 24072 
f 33688 28048 21659 
f 29413 21659 28048 
f 21650 28049 33784 
f 28049 21650 29420 
f 21826 28050 32292 
s 1040
f 28050 21826 29416 
s 1026
f 21884 28051 32280 
f 28051 21884 21886 
f 21684 28052 24073 
s 1040
f 28052 32733 33187 
s 1026
f 33800 28053 24074 
f 29475 24074 28053 
s off
f 31107 31185 28054 
f 28054 31185 22393 
f 22392 31184 28055 
f 28055 31184 31106 
f 31109 31180 28056 
f 30882 28056 31180 
f 28057 31181 31108 
f 31181 28057 30883 
f 31110 31183 28058 
f 30881 28058 31183 
f 28059 31182 31111 
f 31182 28059 30880 
f 30884 28060 31178 
f 31113 31178 28060 
f 31179 28061 30885 
f 28061 31179 31112 
f 30938 31135 22963 
f 22964 31134 30939 
f 25588 28064 22193 
f 20948 22193 28064 
s 1040
f 32052 32054 28065 
s 1026
f 32059 23116 28065 
s 516
f 28066 33785 33742 
s 544
f 32807 28066 24536 
s 516
f 33677 23543 28067 
s 544
f 33395 28067 23543 
s 1026
f 28068 23117 33727 
s 1040
f 28068 32055 33392 
s 516
f 28069 33706 33748 
s 544
f 33117 28069 23077 
s off
f 21412 28070 31668 
f 25899 28070 21412 
f 21411 28071 25900 
f 31667 28071 21411 
f 25895 28072 22597 
f 22597 28072 21182 
f 21086 28073 22598 
f 22598 28073 25896 
s 4100
f 24364 28074 29914 
s 4128
f 28074 24364 28520 
f 28521 24363 28075 
s 4100
f 29915 28075 24363 
s 544
f 25376 28076 28330 
s 516
f 28076 25376 24738 
s 544
f 28386 28077 25544 
s 516
f 25552 25544 28077 
s 544
f 24674 28078 32113 
s 516
f 28078 24674 24320 
f 23643 24729 28079 
s 544
f 32101 28079 24729 
f 24730 28080 32092 
s 516
f 28080 24730 24319 
f 23642 24671 28081 
s 544
f 32115 28081 24671 
f 28465 23638 28082 
s 516
f 25424 28082 23638 
s 544
f 28083 23639 28468 
s 516
f 23639 28083 24486 
s 544
f 28084 25512 28466 
s 516
f 25512 28084 24735 
s 544
f 28467 25513 28085 
s 516
f 24733 28085 25513 
f 24609 23643 28086 
f 28079 28086 23643 
f 28458 28087 23641 
s 544
f 24608 23641 28087 
s 516
f 24320 28088 28078 
f 28088 24320 24600 
f 24318 28089 28224 
s 544
f 28089 24318 24612 
s 516
f 24916 28090 23644 
s 544
f 24611 23644 28090 
s 516
f 24616 28091 28160 
s 544
f 28091 24616 24606 
s 516
f 24605 23642 28092 
f 28081 28092 23642 
f 24317 28093 28463 
s 544
f 28093 24317 24613 
f 24607 23640 28094 
s 516
f 28462 28094 23640 
s 544
f 23630 24615 28095 
s 516
f 26319 28095 24615 
f 24618 28096 24800 
s 544
f 28096 24618 23629 
s 516
f 24319 28097 28080 
f 28097 24319 24610 
f 28098 22298 28498 
s 544
f 22298 28098 25387 
f 22278 28099 25386 
s 516
f 28099 22278 28494 
f 28497 22274 28100 
s 544
f 25384 28100 22274 
s 516
f 28101 22254 28491 
f 22254 28101 25547 
f 25382 28102 22306 
f 28499 22306 28102 
f 28500 22288 28103 
s 544
f 25394 28103 22288 
s 516
f 28104 21984 21982 
s 544
f 21984 28104 25223 
s 516
f 28496 22248 28105 
s 544
f 25379 28105 22248 
f 22282 28106 25393 
s 516
f 28106 22282 28501 
f 28107 22270 28495 
f 22270 28107 25389 
f 23230 21676 28108 
s 544
f 25222 28108 21676 
f 22246 28109 25545 
s 516
f 28109 22246 28490 
f 25390 28110 22286 
f 28492 22286 28110 
s 544
f 25392 28111 22294 
s 516
f 28493 22294 28111 
s 544
f 28112 22122 22195 
s 516
f 22122 28112 28519 
s 544
f 22586 22142 28113 
s 516
f 28518 28113 22142 
f 28114 32542 32442 
f 25414 28114 22314 
f 32497 32472 28115 
f 23300 28115 23633 
s 544
f 28116 32937 33750 
s 516
f 25408 28116 24801 
f 32578 32477 28117 
f 22258 28117 25531 
s 544
f 28118 33712 33376 
s 516
f 23646 28118 22025 
s 544
f 33328 32422 28119 
s 516
f 22065 28119 25405 
f 23634 28120 22318 
f 28120 32457 32510 
f 28417 21911 28121 
s 544
f 33310 28121 21911 
f 23524 28122 25502 
s 516
f 33472 33175 28122 
f 23267 28123 23647 
f 33176 33216 28123 
s 544
f 25561 28124 24803 
s 516
f 28124 32321 32312 
f 28125 33303 33021 
f 25446 28125 23320 
f 28126 21679 28324 
s 544
f 21679 28126 33711 
s 516
f 33145 32864 28127 
f 24802 28127 25555 
f 24800 28128 24618 
f 32769 32430 28128 
f 28129 33201 32755 
f 25371 28129 21682 
f 22076 28130 25367 
f 33157 32920 28130 
s 544
f 28131 23648 22704 
s 516
f 23648 28131 21733 
f 28176 22038 28132 
s 544
f 32737 28132 22038 
s 516
f 22061 28133 25434 
f 32641 32628 28133 
f 28134 32717 32623 
f 25460 28134 22199 
f 24087 28135 25448 
f 32611 33124 28135 
f 28136 32585 32606 
f 25402 28136 21749 
s 544
f 28137 32384 32600 
s 516
f 25401 28137 22328 
f 32549 32509 28138 
f 23544 28138 25541 
f 28273 24626 28139 
s 544
f 33568 28139 24626 
s 516
f 28140 32466 32538 
f 25415 28140 22310 
f 32534 32530 28141 
f 24917 28141 25549 
f 24620 28142 24627 
f 28142 32873 32513 
f 28143 32461 32507 
f 25534 28143 22264 
f 28144 32443 32459 
f 25493 28144 22326 
f 25535 28145 22334 
f 28145 32419 32437 
f 32426 32377 28146 
f 21721 28146 25409 
f 32425 32476 28147 
f 22321 28147 25436 
f 25565 28148 21787 
f 28148 32409 32416 
f 22262 28149 25543 
s 544
f 32386 32367 28149 
s 516
f 28150 32456 32376 
f 25540 28150 22300 
f 25406 28151 21718 
f 28151 32413 32359 
f 24769 28152 24604 
f 32331 32283 28152 
f 32302 32281 28153 
f 21860 28153 25582 
f 24448 28154 23649 
s 544
f 22233 23649 28154 
s 516
f 28155 33781 32163 
f 23644 28155 24916 
s 544
f 28156 23321 28503 
s 516
f 23321 28156 32078 
s 544
f 28506 22204 28157 
s 516
f 32104 28157 22204 
s 544
f 28502 24085 28158 
s 516
f 32263 28158 24085 
s 544
f 28159 21763 28513 
s 516
f 21763 28159 32260 
f 24518 28160 32072 
f 28160 24518 24616 
f 24673 28161 29900 
s 544
f 32062 32335 28161 
f 25372 28162 28259 
s 516
f 28162 25372 24477 
f 28296 28163 23357 
s 544
f 28163 32755 33201 
s 516
f 25362 28164 33172 
f 28164 25362 25344 
f 25491 28165 33192 
f 28165 25491 23635 
s 544
f 24597 28166 28191 
s 516
f 28166 24597 23539 
f 26352 28167 25542 
f 25374 25542 28167 
s 544
f 25490 28168 28234 
s 516
f 28168 25490 23051 
f 28315 28169 25489 
s 544
f 25417 25489 28169 
s 516
f 25488 28170 28268 
s 544
f 28170 25488 32768 
f 25487 28171 28195 
s 516
f 28171 25487 24515 
f 33223 28172 25579 
f 28516 25579 28172 
s 544
f 21676 28173 25222 
s 516
f 24514 25222 28173 
s 544
f 28207 28174 25486 
s 516
f 24731 25486 28174 
s 544
f 33576 28175 23045 
s 516
f 25538 23045 28175 
s 544
f 28200 28176 23645 
s 516
f 28132 23645 28176 
s 544
f 25507 25565 28177 
s 516
f 21787 28177 25565 
s 544
f 28192 28178 23620 
s 516
f 24742 23620 28178 
s 544
f 25485 28179 28213 
s 516
f 28179 25485 22007 
f 25421 28180 28281 
f 28180 25421 24668 
f 25537 28181 33095 
f 28181 25537 25396 
s 544
f 23626 28182 28256 
s 516
f 28182 23626 24059 
f 28183 25484 23058 
s 544
f 25484 28183 28334 
f 33520 25483 28184 
s 516
f 20872 28184 25483 
s 544
f 26306 28185 25361 
s 516
f 25356 25361 28185 
f 25578 28186 33064 
f 28186 25578 28517 
s 544
f 20741 25482 28187 
s 516
f 29903 28187 25482 
f 24624 28188 32514 
f 28188 24624 24622 
f 25481 28189 28251 
f 28189 25481 25501 
s 544
f 24728 28190 28287 
s 516
f 32367 32386 28190 
f 25396 28191 28181 
s 544
f 28191 25396 24597 
f 23620 25406 28192 
s 516
f 21718 28192 25406 
f 28343 28193 23392 
s 544
f 33246 23392 28193 
s 516
f 28381 28194 25383 
s 544
f 23624 25383 28194 
s 516
f 24604 28195 24769 
s 544
f 28195 24604 25487 
s 516
f 25480 28196 28232 
s 544
f 28196 25480 32886 
f 32667 23468 28197 
s 516
f 28216 28197 23468 
f 25163 28198 28215 
s 544
f 28198 25163 33327 
f 28227 28199 25478 
s 516
f 25468 25478 28199 
f 28424 28200 25479 
s 544
f 23645 25479 28200 
f 28201 25541 25336 
s 516
f 25541 28201 23544 
s 544
f 28351 28202 23301 
s 516
f 28202 33750 32937 
s 544
f 28203 25477 25427 
s 516
f 25477 28203 26300 
s 544
f 28231 28204 25400 
s 516
f 24314 25400 28204 
s 544
f 25420 28205 26349 
s 516
f 28205 25420 25504 
f 28505 28206 23306 
s 544
f 28206 32163 33781 
s 516
f 22314 28207 25414 
s 544
f 25486 25414 28207 
s 516
f 25373 28208 28242 
s 544
f 28208 25373 32467 
s 516
f 25582 28209 21860 
s 544
f 28209 25582 25337 
s 516
f 25409 28210 21721 
s 544
f 28210 25409 23623 
f 25339 25371 28211 
s 516
f 21682 28211 25371 
f 25407 21799 28212 
f 32548 28212 21799 
f 25476 28213 28253 
s 544
f 28213 25476 25485 
s 516
f 23689 25475 28214 
s 544
f 28276 28214 25475 
s 516
f 25564 28215 28396 
f 28215 25564 25163 
f 28459 28216 25419 
f 23468 25419 28216 
f 28077 28217 25552 
s 544
f 33061 25552 28217 
s 516
f 28238 28218 25428 
s 544
f 32821 25428 28218 
f 20715 25474 28219 
s 516
f 29906 28219 25474 
s 544
f 23619 28220 28344 
s 516
f 28220 23619 33543 
f 25360 28221 28321 
f 28221 25360 25358 
f 28240 28222 25505 
s 544
f 32840 25505 28222 
s 516
f 23632 25359 28223 
s 544
f 26309 28223 25359 
s 516
f 28224 25536 24318 
f 25536 28224 32083 
f 28243 28225 25410 
s 544
f 24090 25410 28225 
s 516
f 25358 28226 28221 
s 544
f 28226 25358 33528 
f 25478 25423 28227 
s 516
f 26347 28227 25423 
f 25473 28228 29904 
s 544
f 28228 25473 20848 
s 516
f 28266 28229 24736 
s 544
f 28229 32459 32443 
s 516
f 28481 25384 28230 
s 544
f 22274 28230 25384 
f 25400 25535 28231 
s 516
f 22334 28231 25535 
s 544
f 25472 28232 28271 
s 516
f 28232 25472 25480 
f 24738 28233 28076 
s 544
f 32628 32641 28233 
s 516
f 25471 28234 28313 
s 544
f 28234 25471 25490 
s 516
f 28275 28235 25357 
s 544
f 32936 25357 28235 
s 516
f 25469 28236 20752 
s 544
f 28236 25469 33370 
s 516
f 21898 28237 28314 
s 544
f 28237 21898 32799 
f 28267 28238 25470 
s 516
f 25428 25470 28238 
f 28245 28239 25467 
s 544
f 25435 25467 28239 
f 28260 28240 25418 
s 516
f 25505 25418 28240 
f 28199 28241 25468 
s 544
f 32775 25468 28241 
f 25399 28242 28284 
s 516
f 28242 25399 25373 
f 28175 28243 25538 
f 25410 25538 28243 
s 544
f 24621 28244 28264 
s 516
f 28244 24621 21739 
f 32646 28245 25425 
f 25467 25425 28245 
f 23638 28246 25424 
s 544
f 32950 25424 28246 
s 516
f 24725 25378 28247 
s 544
f 28252 28247 25378 
s 516
f 28248 23631 25370 
f 23631 28248 26334 
f 28250 28249 25355 
s 544
f 33380 25355 28249 
s 516
f 28185 28250 25356 
f 25355 25356 28250 
s 544
f 25466 28251 26297 
s 516
f 28251 25466 25481 
f 22264 28252 25534 
s 544
f 25378 25534 28252 
s 516
f 24675 28253 33077 
f 28253 24675 25476 
f 25426 28254 28274 
s 544
f 28254 25426 32894 
s 516
f 25405 28255 22065 
f 28255 25405 23621 
f 25395 28256 28337 
s 544
f 28256 25395 23626 
s 516
f 25342 28257 28426 
s 544
f 28257 25342 33027 
s 516
f 23621 28258 28255 
s 544
f 28258 23621 24743 
s 516
f 24622 28259 28188 
s 544
f 28259 24622 25372 
s 516
f 26303 28260 25498 
s 544
f 25418 25498 28260 
f 22288 28261 25394 
s 516
f 28474 25394 28261 
f 25465 28262 28280 
s 544
f 28262 25465 32910 
f 25449 25464 28263 
s 516
f 26308 28263 25464 
f 24623 28264 28285 
s 544
f 28264 24623 24621 
f 24598 28265 28316 
s 516
f 28265 24598 24517 
s 544
f 28295 28266 25508 
s 516
f 24736 25508 28266 
f 26302 28267 25431 
s 544
f 25470 25431 28267 
f 25427 28268 28203 
s 516
f 28268 25427 25488 
f 24617 28269 23617 
s 544
f 28269 24617 33360 
s 516
f 25463 28270 32645 
f 28270 25463 25451 
f 25462 28271 26320 
s 544
f 28271 25462 25472 
s 516
f 25450 28272 28310 
s 544
f 28272 25450 33118 
f 28361 28273 24601 
s 516
f 28139 24601 28273 
s 544
f 25461 28274 28293 
s 516
f 28274 25461 25426 
s 544
f 28329 28275 25354 
s 516
f 25357 25354 28275 
f 22199 28276 25460 
s 544
f 25475 25460 28276 
s 516
f 28294 28277 25563 
s 544
f 32923 25563 28277 
s 516
f 22025 28278 23646 
f 25533 23646 28278 
f 25364 28279 28363 
s 544
f 28279 25364 32791 
f 25459 28280 28297 
s 516
f 28280 25459 25465 
s 544
f 25502 28281 23524 
s 516
f 28281 25502 25421 
f 25353 28282 26346 
s 544
f 28282 25353 25413 
s 516
f 28299 28283 23391 
s 544
f 32933 23391 28283 
s 516
f 25529 28284 26353 
s 544
f 28284 25529 25399 
s 516
f 25161 28285 32555 
f 28285 25161 24623 
f 25543 28286 22262 
f 28286 25543 25375 
f 25375 28287 28286 
s 544
f 28287 25375 24728 
s 516
f 25430 28288 20765 
s 544
f 28288 25430 33337 
s 516
f 22306 28289 25382 
s 544
f 28477 25382 28289 
s 516
f 25577 28290 28306 
s 544
f 28290 25577 32961 
s 516
f 28292 28291 25576 
s 544
f 33407 25576 28291 
s 516
f 28223 28292 23632 
f 25576 23632 28292 
f 25458 28293 26295 
s 544
f 28293 25458 25461 
f 28319 28294 25562 
s 516
f 25563 25562 28294 
f 22326 28295 25493 
s 544
f 25508 25493 28295 
f 28211 28296 25339 
s 516
f 23357 25339 28296 
f 25495 28297 26322 
s 544
f 28297 25495 25459 
s 516
f 25501 28298 28189 
s 544
f 28298 25501 32702 
f 28325 28299 23625 
s 516
f 23391 23625 28299 
s 544
f 28382 28300 25381 
s 516
f 24095 25381 28300 
s 544
f 25393 28301 22282 
s 516
f 28301 25393 28478 
f 24727 25380 28302 
s 544
f 28322 28302 25380 
s 516
f 25547 28303 22254 
s 544
f 28303 25547 28476 
s 516
f 25370 28304 28248 
s 544
f 28304 25370 25546 
s 516
f 20885 28305 25363 
s 544
f 29898 25363 28305 
s 516
f 28306 25223 25577 
s 544
f 25223 28306 21984 
s 516
f 24737 25457 28307 
s 544
f 28358 28307 25457 
s 516
f 28511 28308 24857 
s 544
f 32488 24857 28308 
s 516
f 28320 28309 24625 
s 544
f 24619 24625 28309 
f 25456 28310 28348 
s 516
f 28310 25456 25450 
f 29908 28311 25454 
s 544
f 20806 25454 28311 
s 516
f 28331 28312 25455 
s 544
f 32991 25455 28312 
s 516
f 25429 28313 33130 
f 28313 25429 25471 
s 544
f 25499 28314 28414 
s 516
f 28314 25499 21898 
f 25489 25453 28315 
f 33268 28315 25453 
f 25549 28316 24917 
s 544
f 28316 25549 24598 
s 516
f 24614 28317 28362 
s 544
f 28317 24614 32680 
f 24803 28318 25561 
s 516
f 25553 25561 28318 
f 26312 28319 25560 
s 544
f 25562 25560 28319 
s 516
f 24625 25162 28320 
f 32563 28320 25162 
s 544
f 25559 28321 26329 
s 516
f 28321 25559 25360 
f 22300 28322 25540 
s 544
f 25380 25540 28322 
s 516
f 24517 28323 28265 
s 544
f 32530 32534 28323 
f 24596 28324 28357 
s 516
f 28324 24596 28126 
f 26315 28325 25391 
s 544
f 23625 25391 28325 
f 28406 28326 24744 
s 516
f 28326 32600 32384 
s 544
f 22294 28327 25392 
s 516
f 28479 25392 28327 
s 544
f 25558 28328 28405 
s 516
f 28328 25558 24758 
f 25909 28329 25352 
s 544
f 25354 25352 28329 
s 516
f 25434 28330 22061 
s 544
f 28330 25434 25376 
f 28365 28331 25452 
s 516
f 25455 25452 28331 
f 28278 28332 25533 
s 544
f 23228 25533 28332 
s 516
f 22286 28333 25390 
s 544
f 28485 25390 28333 
s 516
f 25451 28334 28270 
s 544
f 28334 25451 25484 
f 28359 28335 25494 
s 516
f 24732 25494 28335 
f 28340 28336 23254 
s 544
f 28336 32513 32873 
s 516
f 25532 28337 33098 
f 28337 25532 25395 
f 25369 28338 26333 
f 28338 25369 25338 
s 544
f 25351 28339 28456 
s 516
f 28339 25351 24478 
s 544
f 28421 28340 25368 
s 516
f 23254 25368 28340 
s 544
f 26354 28341 25500 
s 516
f 25496 25500 28341 
s 544
f 33127 23627 28342 
s 516
f 28364 28342 23627 
f 23392 25449 28343 
s 544
f 28263 28343 25449 
s 516
f 25338 28344 28338 
s 544
f 28344 25338 23619 
s 516
f 25389 28345 22270 
s 544
f 28345 25389 28487 
s 516
f 32524 25074 28346 
s 544
f 28418 28346 25074 
f 32472 32497 28347 
s 516
f 24735 28347 25512 
f 25492 28348 26330 
s 544
f 28348 25492 25456 
s 516
f 25531 28349 22258 
s 544
f 28349 25531 25377 
s 516
f 25448 28350 24087 
s 544
f 28350 25448 25503 
s 516
f 28369 28351 24603 
s 544
f 23301 24603 28351 
f 25337 28352 28209 
s 516
f 28352 25337 21858 
f 25447 28353 28371 
s 544
f 28353 25447 33221 
f 25377 28354 28349 
s 516
f 28354 25377 24726 
s 544
f 28332 28355 23228 
s 516
f 28355 33376 33712 
s 544
f 28194 28356 23624 
s 516
f 23069 23624 28356 
f 25404 28357 28377 
s 544
f 28357 25404 24596 
f 25457 25446 28358 
s 516
f 23320 28358 25446 
f 22310 28359 25415 
s 544
f 25494 25415 28359 
s 516
f 24069 28360 28373 
s 544
f 28360 24069 33283 
s 516
f 28212 28361 25407 
s 544
f 24601 25407 28361 
s 516
f 25504 28362 28205 
f 28362 25504 24614 
s 544
f 25413 28363 28282 
s 516
f 28363 25413 25364 
s 544
f 28395 28364 25397 
s 516
f 23627 25397 28364 
f 26342 28365 25445 
s 544
f 25452 25445 28365 
s 516
f 25557 28366 29909 
s 544
f 28366 25557 20822 
f 25340 28367 28390 
s 516
f 28367 25340 24723 
s 544
f 22248 28368 25379 
s 516
f 28486 25379 28368 
f 24801 28369 25408 
f 24603 25408 28369 
s 544
f 25506 28370 28434 
s 516
f 28370 25506 24734 
s 544
f 25444 28371 28392 
s 516
f 28371 25444 25447 
f 28394 28372 25350 
s 544
f 33102 25350 28372 
f 23628 28373 28387 
s 516
f 28373 23628 24069 
s 544
f 25546 28374 28304 
s 516
f 28374 25546 33477 
f 23229 28375 29910 
s 544
f 33728 33765 28375 
s 516
f 24850 28376 29911 
s 544
f 33727 33454 28376 
s 516
f 21678 28377 32879 
f 28377 21678 25404 
f 29907 28378 24849 
s 544
f 28378 33747 33701 
f 25515 28379 28422 
s 516
f 28379 25515 33400 
f 29901 28380 24667 
s 544
f 28380 33770 33674 
s 516
f 33237 28381 25530 
f 25383 25530 28381 
f 28399 28382 25539 
s 544
f 25381 25539 28382 
s 516
f 25443 28383 28400 
s 544
f 28383 25443 33258 
f 25349 28384 28409 
s 516
f 28384 25349 24724 
s 544
f 28309 28385 24619 
s 516
f 21892 24619 28385 
s 544
f 25544 25348 28386 
s 516
f 26340 28386 25348 
f 25388 28387 26324 
s 544
f 28387 25388 23628 
s 516
f 28407 28388 25894 
s 544
f 33493 25894 28388 
s 516
f 28318 28389 25553 
f 24430 25553 28389 
f 25367 28390 22076 
s 544
f 28390 25367 25340 
s 516
f 28307 28391 24737 
s 544
f 28391 33021 33303 
s 516
f 25442 28392 26305 
s 544
f 28392 25442 25444 
s 516
f 28412 28393 23465 
s 544
f 33290 23465 28393 
f 28461 28394 25347 
s 516
f 25350 25347 28394 
f 26299 28395 25441 
s 544
f 25397 25441 28395 
f 25556 28396 26314 
s 516
f 28396 25556 25564 
f 24724 28397 28384 
s 544
f 33216 33176 28397 
f 28398 33055 33190 
s 516
f 28173 28398 24514 
f 25539 25217 28399 
f 32560 28399 25217 
s 544
f 25440 28400 28416 
s 516
f 28400 25440 25443 
f 24739 28401 28430 
s 544
f 33124 32611 28401 
s 516
f 28402 25403 24599 
f 25403 28402 26298 
f 28403 23623 24741 
s 544
f 23623 28403 28210 
s 516
f 24668 28404 28180 
s 544
f 33175 33472 28404 
s 516
f 25555 28405 24802 
s 544
f 28405 25555 25558 
f 24744 23622 28406 
s 516
f 28427 28406 23622 
f 28341 28407 25496 
f 25894 25496 28407 
s 544
f 25545 28408 22246 
s 516
f 28408 25545 28473 
f 23647 28409 23267 
s 544
f 28409 23647 25349 
f 32690 25416 28410 
s 516
f 23637 28410 25416 
f 24723 28411 28367 
s 544
f 32920 33157 28411 
f 28450 28412 25554 
s 516
f 23465 25554 28412 
s 544
f 28177 28413 25507 
s 516
f 24670 25507 28413 
f 25439 28414 26345 
s 544
f 28414 25439 25499 
s 516
f 24758 28415 28328 
s 544
f 32864 33145 28415 
s 516
f 25497 28416 26307 
s 544
f 28416 25497 25440 
f 28457 28417 24595 
s 516
f 28121 24595 28417 
f 28167 28418 25374 
s 544
f 25074 25374 28418 
s 516
f 25438 28419 29913 
s 544
f 28419 25438 20913 
f 33405 25437 28420 
s 516
f 20859 28420 25437 
s 544
f 25368 24620 28421 
s 516
f 24627 28421 24620 
f 24599 28422 28402 
s 544
f 28422 24599 25515 
f 28441 28423 24602 
s 516
f 24740 24602 28423 
f 25479 24669 28424 
f 32705 28424 24669 
s 544
f 28425 32623 32717 
s 516
f 28214 28425 23689 
s 544
f 25346 28426 28446 
s 516
f 28426 25346 25342 
f 22328 28427 25401 
f 23622 25401 28427 
s 544
f 28428 32606 32585 
s 516
f 28423 28428 24740 
s 544
f 28429 32442 32542 
s 516
f 28174 28429 24731 
s 544
f 25503 28430 28350 
s 516
f 28430 25503 24739 
f 23107 28431 28460 
s 544
f 32509 32549 28431 
f 32477 32578 28432 
s 516
f 24726 28432 28354 
f 24734 28433 28370 
s 544
f 32476 32425 28433 
s 516
f 25436 28434 22321 
s 544
f 28434 25436 25506 
s 516
f 28335 28435 24732 
s 544
f 28435 32538 32466 
s 516
f 28247 28436 24725 
s 544
f 28436 32507 32461 
s 516
f 28302 28437 24727 
s 544
f 28437 32376 32456 
s 516
f 25513 28438 24733 
s 544
f 28438 32510 32457 
s 516
f 24482 25435 28439 
s 544
f 28239 28439 25435 
s 516
f 24672 28440 28510 
s 544
f 32430 32769 28440 
s 516
f 21749 28441 25402 
s 544
f 24602 25402 28441 
s 516
f 32422 33328 28442 
s 544
f 24743 28442 28258 
s 516
f 28204 28443 24314 
s 544
f 28443 32437 32419 
s 516
f 28178 28444 24742 
s 544
f 28444 32359 32413 
s 516
f 28413 28445 24670 
s 544
f 28445 32416 32409 
s 516
f 25345 28446 26313 
s 544
f 28446 25345 25346 
s 516
f 24741 28447 28403 
s 544
f 32377 32426 28447 
f 28448 25433 29895 
s 516
f 25433 28448 20778 
s 544
f 25387 28449 22298 
s 516
f 28449 25387 28484 
s 544
f 25554 25566 28450 
s 516
f 26294 28450 25566 
f 33150 28451 24754 
f 25385 24754 28451 
f 28389 28452 24430 
s 544
f 28452 32312 32321 
f 25386 28453 22278 
s 516
f 28453 25386 28482 
f 24515 28454 28171 
s 544
f 32283 32331 28454 
f 32281 32302 28455 
s 516
f 21858 28455 28352 
f 25344 28456 28164 
s 544
f 28456 25344 25351 
f 24595 25385 28457 
s 516
f 28451 28457 25385 
f 32254 28458 24757 
f 23641 24757 28458 
s 544
f 26350 28459 25432 
s 516
f 25419 25432 28459 
s 544
f 25336 28460 28201 
s 516
f 28460 25336 23107 
f 26335 28461 25343 
s 544
f 25347 25343 28461 
s 516
f 23640 24084 28462 
f 32073 28462 24084 
f 28463 24516 24317 
f 24516 28463 32160 
s 544
f 28169 28464 25417 
s 516
f 24499 25417 28464 
s 544
f 28082 23636 28465 
s 516
f 26343 28465 23636 
f 23633 28466 23300 
s 544
f 28466 23633 28084 
s 516
f 22318 28467 23634 
s 544
f 28085 23634 28467 
s 516
f 23635 28468 28165 
s 544
f 28468 23635 28083 
s 516
f 28469 23617 23618 
f 23617 28469 24617 
f 25517 23637 28470 
f 25416 28470 23637 
f 28507 21954 28471 
s 544
f 32098 28471 21954 
s 516
f 28472 22041 28504 
s 544
f 22041 28472 32064 
s 516
f 28473 22245 28408 
s 544
f 22245 28473 32076 
s 516
f 28261 22290 28474 
s 544
f 32129 28474 22290 
s 516
f 28475 22195 24089 
s 544
f 22195 28475 28112 
f 28476 22253 28303 
s 516
f 22253 28476 32240 
s 544
f 28289 22304 28477 
s 516
f 32135 28477 22304 
f 28478 22281 28301 
s 544
f 22281 28478 32108 
s 516
f 28327 22292 28479 
s 544
f 32213 28479 22292 
s 516
f 22144 22586 28480 
s 544
f 28113 28480 22586 
s 516
f 28230 22272 28481 
s 544
f 32156 28481 22272 
s 516
f 28482 22277 28453 
s 544
f 22277 28482 32214 
s 516
f 28509 24086 28483 
s 544
f 32222 28483 24086 
s 516
f 28484 22297 28449 
s 544
f 22297 28484 32210 
f 28333 22284 28485 
s 516
f 32231 28485 22284 
f 28368 22250 28486 
s 544
f 32190 28486 22250 
f 28487 22269 28345 
s 516
f 22269 28487 32203 
f 28488 22213 28508 
s 544
f 22213 28488 32090 
s 516
f 28489 33190 33055 
f 28108 28489 23230 
f 24756 28490 32070 
f 28490 24756 28109 
s 544
f 24759 28491 32158 
s 516
f 28491 24759 28101 
s 544
f 32218 28492 24749 
s 516
f 28110 24749 28492 
f 32255 28493 24752 
f 28111 24752 28493 
f 24750 28494 32264 
f 28494 24750 28099 
s 544
f 24747 28495 32168 
s 516
f 28495 24747 28107 
f 28105 24755 28496 
f 32171 28496 24755 
f 32265 28497 24748 
f 28100 24748 28497 
f 24753 28498 32226 
f 28498 24753 28098 
s 544
f 32121 28499 24751 
s 516
f 28102 24751 28499 
f 28103 24746 28500 
f 32243 28500 24746 
f 24745 28501 32247 
f 28501 24745 28106 
f 28086 28502 24609 
s 544
f 28158 24609 28502 
s 516
f 24600 28503 28088 
s 544
f 28503 24600 28156 
s 516
f 28504 24606 28472 
s 544
f 24606 28504 28091 
f 28090 28505 24611 
s 516
f 23306 24611 28505 
f 28092 28506 24605 
s 544
f 28157 24605 28506 
f 28094 28507 24607 
s 516
f 28471 24607 28507 
s 544
f 24613 28508 28093 
s 516
f 28508 24613 28488 
s 544
f 28087 28509 24608 
s 516
f 28483 24608 28509 
s 544
f 23629 28510 28096 
s 516
f 28510 23629 24672 
f 24857 23630 28511 
s 544
f 28095 28511 23630 
f 24612 28512 28089 
s 516
f 28512 24612 21665 
f 24610 28513 28097 
s 544
f 28513 24610 28159 
f 26341 25517 28514 
s 516
f 28470 28514 25517 
s 544
f 28515 23618 26296 
s 516
f 23618 28515 28469 
f 28172 22233 28516 
s 544
f 28154 28516 22233 
s 516
f 28517 22704 28186 
s 544
f 22704 28517 28131 
s 516
f 22142 22143 28518 
s 544
f 32983 28518 22143 
s 516
f 28519 25583 22122 
s 544
f 25583 28519 32797 
s 4128
f 28520 20828 28074 
s 4100
f 20828 28520 20835 
f 20728 28521 20721 
s 4128
f 28075 20721 28521 
s off
f 25075 28522 21368 
f 24315 21368 31904 
f 31903 21369 24316 
f 21369 28523 25076 
f 28524 22605 30446 
f 22605 31280 31588 
f 31589 31282 22606 
f 30443 22606 28525 
f 28526 22957 30444 
f 22957 31269 31577 
f 31576 31270 22958 
f 30445 22958 28527 
f 31575 31275 22607 
f 30439 22607 28528 
f 28529 22066 30442 
f 22066 31265 31574 
f 31573 31273 22609 
f 30449 22609 28530 
f 28531 22608 30454 
f 22608 31267 31572 
f 21599 31283 31317 
f 28532 21599 30451 
f 28533 22930 30434 
f 22930 31285 31319 
f 30452 21600 28534 
f 31318 31284 21600 
f 30447 21655 28535 
f 31314 31279 21655 
f 30440 22176 28536 
f 31313 31272 22176 
f 30435 22929 28537 
f 31316 31281 22929 
f 22932 31264 31310 
f 23737 28538 22932 
f 22600 31271 31307 
f 28539 22600 30450 
f 31312 31268 22931 
f 22931 28540 23738 
f 21656 31277 31315 
f 28541 21656 30455 
f 31306 31274 21694 
f 30436 21694 28542 
f 30441 22599 28543 
f 31311 31278 22599 
f 28544 21662 30437 
f 21662 31266 31308 
f 22177 31276 31309 
f 28545 22177 30438 
f 24447 31288 22997 
f 22997 31288 31305 
f 31303 31289 22998 
f 22998 31289 21271 
f 31302 31286 23030 
f 23030 31286 21102 
f 21104 31287 23031 
f 23031 31287 31304 
s 4100
f 28550 27946 23032 
s 4128
f 26892 28550 30805 
s 4100
f 23033 27945 28551 
s 4128
f 30806 28551 26891 
s 8
f 23661 28552 24976 
s off
f 28552 23661 29618 
f 20952 31340 31363 
f 28553 20952 25164 
f 28554 21030 25166 
f 21030 31335 31361 
f 31360 31337 21006 
f 25165 21006 28555 
f 31362 31333 20957 
f 25167 20957 28556 
f 28557 31671 22207 
f 26007 31671 28557 
f 28558 31672 26006 
f 25600 31672 28558 
f 31669 28559 27513 
f 23695 28559 31669 
f 27514 28560 31670 
f 31670 28560 23694 
f 28583 31724 28561 
f 28561 24374 21472 
f 28562 31723 23706 
f 23110 21065 28562 
f 33820 20977 31355 
f 31339 31355 20977 
f 20972 31356 31343 
f 31356 20972 21364 
f 31342 31357 22696 
f 22696 31357 22114 
f 20929 31351 23713 
f 31332 31351 20929 
f 22985 31354 22638 
f 22638 31354 31341 
f 21053 31347 31336 
f 22336 31347 21053 
f 31338 31353 21052 
f 21052 31353 21164 
f 20941 31352 31334 
f 28601 31352 20941 
f 31330 31350 22615 
f 22615 31350 22172 
f 31331 31349 21015 
f 32040 21015 31349 
f 22175 31345 22191 
f 22191 31345 31329 
f 31327 31346 22192 
f 22192 31346 22720 
f 31348 21016 32039 
f 21016 31348 31326 
f 22149 31344 22614 
f 22614 31344 31328 
f 21336 28577 23704 
f 21985 23704 28577 
f 23709 21336 28578 
f 26006 28578 21336 
f 28579 22524 28607 
f 26009 22524 28579 
f 28580 23072 23759 
f 28561 23072 28580 
f 26008 28581 22220 
f 23710 22220 28581 
f 22471 28582 26009 
f 28582 22471 24447 
f 29113 21906 28583 
f 28583 21906 31724 
f 28584 23706 21865 
f 23706 28584 21396 
f 23703 28585 21141 
f 28585 23703 28613 
f 28586 21141 28604 
f 21141 28586 26007 
f 21661 23464 28587 
f 28587 23464 28558 
f 31616 23694 28588 
f 28588 23694 28596 
f 23704 28589 22221 
f 23713 22221 28589 
f 28597 21571 28590 
f 28590 21571 28591 
f 28591 23705 28562 
f 28602 23705 28591 
f 28592 23067 24018 
f 28557 23067 28592 
f 31617 28593 23695 
f 28598 23695 28593 
f 21396 28594 23703 
f 28594 21396 28601 
f 21077 28595 24106 
f 26008 24106 28595 
f 33830 23009 28596 
f 28596 23009 28588 
f 23944 28597 21271 
f 28590 21271 28597 
f 28593 22650 28598 
f 33464 28598 22650 
f 22221 23713 28599 
f 21471 28599 23713 
f 28600 23759 23072 
f 23759 28600 21471 
f 23005 28601 20941 
f 28601 23005 28594 
f 21382 28602 22999 
f 28591 22999 28602 
f 28603 21375 26011 
f 21375 28603 23943 
f 28604 23711 28586 
f 23711 28604 21193 
f 23704 21985 28605 
f 20929 28605 21985 
f 23652 28606 21409 
f 31665 30053 28606 
f 28607 24107 28579 
f 21067 24107 28607 
f 26006 22549 28608 
f 33507 28608 22549 
f 21336 23709 28609 
f 33507 28609 23709 
f 21067 21140 28610 
f 28610 21140 26009 
f 26383 21966 28611 
f 33830 28611 21966 
f 33464 21602 28612 
f 28612 21602 26382 
f 22547 28613 20941 
f 28613 22547 28585 
f 28614 30054 31666 
f 21410 28614 23653 
f 22220 23710 28615 
f 21077 28615 23710 
f 23650 28616 22601 
f 20970 22601 28616 
f 22602 28617 23651 
f 20971 28617 22602 
s 516
f 33026 25901 28618 
s 544
f 28618 25901 32051 
s 516
f 33753 33679 28619 
s 544
f 28619 32588 33752 
s 516
f 28620 33692 33739 
s 544
f 28620 23061 33744 
s off
f 30966 31158 23034 
f 23035 31159 30965 
f 23190 21603 28623 
f 21603 20931 28623 
f 28624 21777 22159 
f 23188 21777 28624 
f 28625 21604 23191 
f 21604 28625 20935 
f 28626 21775 23184 
f 22156 21775 28626 
f 28627 21776 22157 
f 23187 21776 28627 
f 28628 21778 23189 
f 22158 21778 28628 
f 28629 21801 33462 
f 23186 21801 28629 
f 28630 21802 23185 
f 33661 21802 28630 
f 22705 24637 28631 
f 31615 31972 28631 
f 22706 28632 24638 
f 28632 31971 31614 
s 516
f 32707 24860 28633 
s 544
f 32055 33755 28633 
s off
f 24525 28634 22485 
f 22485 28634 33813 
f 31634 28635 22577 
f 22577 28635 24522 
f 21955 21060 28636 
f 33464 24018 28637 
f 28637 24018 23067 
f 23464 21661 28638 
f 33830 28638 21661 
f 31207 31774 21495 
f 21495 31774 31519 
f 31556 31773 21504 
f 21504 31773 31206 
s 544
f 25122 22130 28641 
s 516
f 32208 28641 22130 
s 544
f 28642 23355 25119 
s 516
f 23355 28642 32201 
f 32288 32299 28643 
f 21883 28643 28661 
f 28728 28644 22263 
s 544
f 28644 32351 32471 
s 516
f 28701 28645 22299 
s 544
f 28645 32570 32531 
s 516
f 22255 28646 28710 
s 544
f 32480 32500 28646 
f 28690 28647 24684 
s 516
f 28647 33347 33577 
f 24683 28648 28726 
s 544
f 33067 33621 28648 
f 32996 33166 28649 
s 516
f 22077 28649 28718 
f 32449 32520 28650 
s 544
f 22259 28650 28700 
f 32429 32370 28651 
s 516
f 22388 28651 28694 
f 28652 24579 25116 
s 544
f 24579 28652 32155 
f 32249 28653 24761 
s 516
f 25121 24761 28653 
s 544
f 32074 28654 22095 
s 516
f 25124 22095 28654 
s 544
f 28686 25115 28655 
s 516
f 26961 28655 25115 
s 544
f 28682 25102 28656 
s 516
f 21717 28656 25102 
f 28687 25104 28657 
s 544
f 32438 28657 25104 
s 516
f 28658 25113 33305 
f 25113 28658 28994 
s 544
f 28659 25112 28697 
s 516
f 25112 28659 26960 
f 28660 25111 26911 
s 544
f 25111 28660 22859 
s 516
f 28661 25160 21883 
s 544
f 25160 28661 22791 
s 516
f 25110 28662 32663 
s 544
f 28662 25110 28717 
f 28663 25096 28683 
s 516
f 25096 28663 26965 
f 28664 25109 26910 
s 544
f 25109 28664 29000 
f 25100 28665 22063 
s 516
f 28665 25100 28696 
s 544
f 28666 25091 28706 
s 516
f 25091 28666 29015 
s 544
f 28714 25108 28667 
s 516
f 26966 28667 25108 
s 544
f 28705 25107 28668 
s 516
f 29003 28668 25107 
s 544
f 32582 28669 22222 
s 516
f 28716 22222 28669 
s 544
f 28670 25095 28711 
s 516
f 25095 28670 29006 
f 28712 25106 28671 
s 544
f 33162 28671 25106 
s 516
f 28715 25099 28672 
s 544
f 29009 28672 25099 
s 516
f 26908 25114 28673 
s 544
f 29014 28673 25114 
f 32313 32325 28674 
s 516
f 21821 28674 28993 
s 544
f 28675 25103 28721 
s 516
f 25103 28675 29010 
f 28727 25098 28676 
s 544
f 22327 28676 25098 
s 516
f 28677 25093 28722 
s 544
f 25093 28677 22196 
f 28678 25101 28691 
s 516
f 25101 28678 21720 
s 544
f 28733 25094 28679 
s 516
f 26964 28679 25094 
s 544
f 28729 25105 28680 
s 516
f 21750 28680 25105 
s 544
f 29036 28681 22789 
s 516
f 26904 22789 28681 
f 24796 28682 22754 
s 544
f 28656 22754 28682 
s 516
f 22740 28683 29031 
s 544
f 28683 22740 28663 
f 25548 28684 25123 
s 516
f 28684 25548 32133 
s 544
f 22768 28685 29035 
s 516
f 28685 22768 26906 
s 544
f 28655 22741 28686 
s 516
f 29038 28686 22741 
s 544
f 29039 28687 22733 
s 516
f 28657 22733 28687 
f 25412 28688 25126 
s 544
f 28688 25412 32065 
f 25120 28689 25398 
s 516
f 32233 25398 28689 
s 544
f 24684 22779 28690 
s 516
f 29040 28690 22779 
f 22752 28691 24797 
s 544
f 28691 22752 28678 
f 28692 25551 25574 
s 516
f 25551 28692 24765 
s 544
f 29042 28693 22739 
s 516
f 28746 22739 28693 
s 544
f 22731 28694 29044 
s 516
f 28694 22731 22388 
f 25125 28695 25411 
s 544
f 32068 25411 28695 
s 516
f 22753 28696 24799 
f 28696 22753 28665 
f 22736 28697 29058 
s 544
f 28697 22736 28659 
s 516
f 25341 28698 25092 
s 544
f 28698 25341 32049 
s 516
f 29049 28699 22780 
s 544
f 28749 22780 28699 
s 516
f 22761 28700 29047 
s 544
f 28700 22761 22259 
f 29051 28701 22763 
s 516
f 22299 22763 28701 
s 544
f 22742 28702 29055 
s 516
f 28702 22742 28752 
f 29050 28703 24766 
s 544
f 33281 24766 28703 
f 22482 28704 29054 
s 516
f 32325 32313 28704 
f 26969 28705 22746 
s 544
f 28668 22746 28705 
s 516
f 22726 28706 29069 
s 544
f 28706 22726 28666 
s 516
f 29026 28707 22738 
s 544
f 28757 22738 28707 
s 516
f 22747 28708 29057 
s 544
f 28708 22747 28756 
f 29062 28709 22737 
s 516
f 28753 22737 28709 
s 544
f 22758 28710 29068 
s 516
f 28710 22758 22255 
f 22735 28711 26968 
s 544
f 28711 22735 28670 
s 516
f 28671 22745 28712 
s 544
f 28898 28712 22745 
f 29067 28713 22762 
s 516
f 28754 22762 28713 
f 29034 28714 22734 
s 544
f 28667 22734 28714 
s 516
f 26974 28715 22750 
f 28672 22750 28715 
s 544
f 29063 28716 22767 
s 516
f 28669 22767 28716 
f 22769 28717 29059 
s 544
f 28717 22769 28662 
f 22788 28718 29032 
s 516
f 28718 22788 22077 
f 26971 28719 25550 
s 544
f 25575 25550 28719 
s 516
f 32675 25097 28720 
s 544
f 29061 28720 25097 
s 516
f 22748 28721 26967 
s 544
f 28721 22748 28675 
s 516
f 22749 28722 24580 
f 28722 22749 28677 
f 22730 28723 29060 
s 544
f 28723 22730 28755 
f 22727 28724 29071 
s 516
f 28724 22727 28734 
s 544
f 29022 28725 22765 
s 516
f 28751 22765 28725 
s 544
f 22770 28726 29064 
s 516
f 28726 22770 24683 
f 28676 22751 28727 
f 24798 28727 22751 
s 544
f 29043 28728 22759 
s 516
f 22263 22759 28728 
s 544
f 28680 22732 28729 
s 516
f 23309 28729 22732 
s 544
f 22764 28730 29027 
s 516
f 28730 22764 28750 
s 544
f 22743 28731 29033 
s 516
f 28731 22743 28748 
s 544
f 26905 22760 28732 
s 516
f 29028 28732 22760 
f 29075 28733 22744 
s 544
f 28679 22744 28733 
s 516
f 28734 24770 28724 
s 544
f 24770 28734 32050 
s 516
f 28654 28735 25124 
s 544
f 25142 25124 28735 
s 516
f 25145 25122 28736 
s 544
f 28641 28736 25122 
f 28737 25126 22867 
s 516
f 25126 28737 25412 
f 28738 25123 22465 
s 544
f 25123 28738 25548 
s 516
f 28653 28739 25121 
s 544
f 25144 25121 28739 
s 516
f 25143 25120 28740 
s 544
f 25398 28740 25120 
f 28741 25092 22412 
s 516
f 25092 28741 25341 
s 544
f 25140 25125 28742 
s 516
f 25411 28742 25125 
s 544
f 28743 25117 25141 
s 516
f 25117 28743 28999 
s 544
f 22877 25118 28744 
s 516
f 29008 28744 25118 
s 544
f 28745 25116 22450 
s 516
f 25116 28745 28652 
f 28693 24778 28746 
s 544
f 32157 28746 24778 
s 516
f 28747 25119 22448 
s 544
f 25119 28747 28642 
s 516
f 28748 24777 28731 
s 544
f 24777 28748 32142 
f 28699 24775 28749 
s 516
f 32235 28749 24775 
f 28750 24773 28730 
s 544
f 24773 28750 32242 
s 516
f 28725 24774 28751 
s 544
f 32138 28751 24774 
s 516
f 28752 24779 28702 
s 544
f 24779 28752 32178 
f 32184 28753 24780 
s 516
f 28709 24780 28753 
s 544
f 32227 28754 24772 
s 516
f 28713 24772 28754 
s 544
f 28755 24795 28723 
s 516
f 24795 28755 32207 
s 544
f 28756 24776 28708 
s 516
f 24776 28756 32204 
s 544
f 28707 24781 28757 
s 516
f 32199 28757 24781 
f 28758 22243 25571 
f 22243 28758 32047 
f 25526 22291 28759 
f 32179 28759 22291 
f 22295 28760 32192 
f 28760 22295 25523 
f 25527 22303 28761 
s 544
f 32220 28761 22303 
s 516
f 32125 28762 22247 
f 25528 22247 28762 
s 544
f 22251 28763 32123 
s 516
f 28763 22251 25570 
s 544
f 22267 28764 32124 
s 516
f 28764 22267 25520 
f 25519 22283 28765 
s 544
f 32119 28765 22283 
s 516
f 32150 28766 22287 
f 25525 22287 28766 
f 22279 28767 32159 
f 28767 22279 25521 
f 25524 22271 28768 
f 32244 28768 22271 
f 28769 22275 25522 
f 22275 28769 32257 
f 28770 32445 32398 
f 22797 28770 24789 
f 28771 32287 32310 
s 544
f 22882 28771 21833 
s 516
f 28772 33556 33774 
s 544
f 22806 28772 22572 
s 516
f 33643 33554 28773 
s 544
f 24763 28773 22849 
s 516
f 28774 33018 33678 
f 22908 28774 21647 
f 28931 24762 28775 
s 544
f 33315 28775 24762 
f 22578 28776 33646 
s 516
f 28776 22578 28857 
f 32815 33075 28777 
f 22164 28777 22454 
f 22844 28778 24783 
f 28778 32714 32803 
f 32745 33142 28779 
f 22110 28779 22803 
f 28828 22200 28780 
s 544
f 32852 28780 22200 
s 516
f 28781 24764 28897 
s 544
f 24764 28781 33420 
s 516
f 32642 32629 28782 
f 24782 28782 22876 
f 28783 32434 32511 
f 28961 28783 24790 
f 28784 32399 32496 
f 22868 28784 24793 
f 28785 32591 32446 
f 22869 28785 24792 
f 32407 32474 28786 
f 24791 28786 28960 
f 32392 32479 28787 
f 24788 28787 22798 
f 32317 32285 28788 
s 544
f 21825 28788 22792 
s 516
f 22886 28789 24581 
f 28789 32279 32304 
s 544
f 28800 28790 22800 
s 516
f 22166 22800 28790 
f 25131 28791 28803 
s 544
f 28791 25131 32736 
s 516
f 22795 25567 28792 
f 33269 28792 25567 
f 21647 28793 22908 
s 544
f 22903 22908 28793 
f 28916 28794 22417 
s 516
f 22147 22417 28794 
s 544
f 30171 22878 28795 
s 516
f 20883 28795 22878 
f 32742 28796 24767 
s 544
f 22796 24767 28796 
f 22791 28797 25160 
s 516
f 28797 22791 22383 
f 20870 28798 22398 
s 544
f 33524 22398 28798 
s 516
f 22803 28799 22110 
s 544
f 28799 22803 22885 
s 516
f 28810 28800 22874 
s 544
f 22800 22874 28800 
s 516
f 22116 28801 28926 
s 544
f 28801 22116 32773 
s 516
f 28802 22906 22643 
s 544
f 22906 28802 28825 
f 22859 28803 25111 
s 516
f 28803 22859 25131 
s 544
f 28820 28804 22870 
s 516
f 22311 22870 28804 
s 544
f 22864 28805 28843 
s 516
f 28805 22864 24507 
f 28792 28806 22795 
s 544
f 22893 22795 28806 
s 516
f 21712 28807 32846 
s 544
f 28807 21712 25569 
f 22875 28808 28824 
s 516
f 28808 22875 24689 
f 28814 28809 22562 
s 544
f 32782 22562 28809 
s 516
f 33286 28810 22033 
f 22874 22033 28810 
s 544
f 26932 28811 22880 
s 516
f 22881 22880 28811 
f 22804 28812 28865 
f 28812 22804 21867 
f 30174 28813 22854 
s 544
f 20739 22854 28813 
f 28834 28814 22860 
s 516
f 22562 22860 28814 
s 544
f 28815 22861 32901 
s 516
f 22861 28815 28838 
f 26951 28816 22455 
s 544
f 28996 22455 28816 
s 516
f 22817 28817 26955 
s 544
f 28817 22817 22856 
s 516
f 24783 28818 22844 
s 544
f 22837 22844 28818 
f 22802 28819 28915 
s 516
f 28819 22802 22865 
s 544
f 22870 22869 28820 
s 516
f 24792 28820 22869 
s 544
f 22827 28821 28895 
s 516
f 28821 22827 24506 
f 28899 28822 25130 
s 544
f 32909 25130 28822 
s 516
f 24503 22453 28823 
f 28957 28823 22453 
f 22454 28824 22164 
s 544
f 28824 22454 22875 
s 516
f 22909 28825 26940 
s 544
f 28825 22909 22906 
s 516
f 22828 28826 33196 
f 28826 22828 28962 
f 28839 28827 22853 
s 544
f 32828 22853 28827 
f 28866 28828 22437 
s 516
f 28780 22437 28828 
s 544
f 32848 22520 28829 
s 516
f 28841 28829 22520 
s 544
f 21833 28830 22882 
s 516
f 29004 22882 28830 
f 22890 28831 28923 
f 28831 22890 21989 
s 544
f 22572 28832 22806 
s 516
f 22872 22806 28832 
f 30178 28833 22857 
s 544
f 20713 22857 28833 
s 516
f 26949 28834 22811 
s 544
f 22860 22811 28834 
f 28835 22847 20846 
s 516
f 22847 28835 30175 
f 22587 28836 20750 
s 544
f 28836 22587 33377 
s 516
f 28837 22805 21844 
f 22805 28837 28853 
s 544
f 22813 28838 28859 
s 516
f 28838 22813 22861 
s 544
f 28855 28839 22850 
s 516
f 22853 22850 28839 
s 544
f 32956 22634 28840 
s 516
f 28862 28840 22634 
f 22520 22801 28841 
s 544
f 28850 28841 22801 
s 516
f 22414 28842 28881 
f 28842 22414 22483 
f 22846 28843 28883 
s 544
f 28843 22846 22864 
s 516
f 25129 28844 28886 
s 544
f 28844 25129 32836 
s 516
f 21844 28845 28837 
s 544
f 33554 33643 28845 
s 516
f 28963 28846 22561 
s 544
f 32975 22561 28846 
f 33383 22641 28847 
s 516
f 28849 28847 22641 
f 22610 28848 28858 
s 544
f 28848 22610 32904 
s 516
f 22641 22881 28849 
f 28811 28849 22881 
f 26947 28850 22873 
s 544
f 22801 22873 28850 
s 516
f 22612 28851 28863 
s 544
f 28851 22612 32918 
f 22823 22851 28852 
s 516
f 28856 28852 22851 
s 544
f 22849 28853 24763 
s 516
f 28853 22849 22805 
f 22323 22807 28854 
s 544
f 28871 28854 22807 
s 516
f 26948 28855 22815 
s 544
f 22850 22815 28855 
s 516
f 22851 22639 28856 
f 32648 28856 22639 
f 25568 28857 28955 
f 28857 25568 28776 
s 544
f 22841 28858 28868 
s 516
f 28858 22841 22610 
f 22843 28859 26939 
s 544
f 28859 22843 22813 
s 516
f 22845 28860 28876 
s 544
f 28860 22845 33084 
s 516
f 28869 28861 22783 
s 544
f 32935 22783 28861 
s 516
f 22634 22907 28862 
s 544
f 28891 28862 22907 
f 22839 28863 28872 
s 516
f 28863 22839 22612 
f 22643 28864 28802 
s 544
f 28864 22643 33037 
f 22866 28865 28880 
s 516
f 28865 22866 22804 
s 544
f 22437 22842 28866 
s 516
f 28930 28866 22842 
f 21998 28867 28905 
s 544
f 28867 21998 32867 
s 516
f 22836 28868 26946 
s 544
f 28868 22836 22841 
f 28882 28869 22785 
s 516
f 22783 22785 28869 
f 22526 28870 28889 
s 544
f 28870 22526 33189 
s 516
f 24789 28871 22797 
s 544
f 22807 22797 28871 
s 516
f 22835 28872 26923 
s 544
f 28872 22835 22839 
s 516
f 22645 28873 33182 
f 28873 22645 22902 
s 544
f 22435 28874 28887 
s 516
f 28874 22435 22059 
f 30180 28875 22830 
s 544
f 20804 22830 28875 
f 22834 28876 28894 
s 516
f 28876 22834 22845 
s 544
f 33007 21997 28877 
s 516
f 28888 28877 21997 
f 28878 21755 22832 
f 21755 28878 33015 
s 544
f 26929 28879 22793 
s 516
f 22794 22793 28879 
f 25422 28880 33222 
s 544
f 28880 25422 22866 
f 22792 28881 21825 
s 516
f 28881 22792 22414 
f 26921 28882 22786 
s 544
f 22785 22786 28882 
s 516
f 22492 28883 33177 
f 28883 22492 22846 
f 22883 28884 26953 
s 544
f 28884 22883 22810 
f 28818 28885 22837 
s 516
f 21591 22837 28885 
f 28886 22838 25129 
s 544
f 22838 28886 28944 
s 516
f 22876 28887 24782 
s 544
f 28887 22876 22435 
f 28902 28888 22829 
s 516
f 21997 22829 28888 
s 544
f 22884 28889 28937 
s 516
f 28889 22884 22526 
f 22307 22871 28890 
s 544
f 28896 28890 22871 
s 516
f 26944 28891 22905 
s 544
f 22907 22905 28891 
s 516
f 22819 28892 30182 
s 544
f 28892 22819 20911 
s 516
f 22400 28893 28904 
s 544
f 28893 22400 33262 
s 516
f 22825 28894 26924 
s 544
f 28894 22825 22834 
s 516
f 22832 28895 28878 
s 544
f 28895 22832 22827 
s 516
f 24793 28896 22868 
s 544
f 22871 22868 28896 
s 516
f 22865 28897 28819 
f 28897 22865 28781 
s 544
f 22745 22822 28898 
s 516
f 26937 28898 22822 
s 544
f 28901 28899 22833 
s 516
f 25130 22833 28899 
s 544
f 26945 28900 22888 
s 516
f 25509 22888 28900 
f 26920 28901 22824 
s 544
f 22833 22824 28901 
s 516
f 26941 28902 22821 
s 544
f 22829 22821 28902 
s 516
f 22891 28903 30181 
s 544
f 28903 22891 20820 
f 22812 28904 28919 
s 516
f 28904 22812 22400 
s 544
f 22810 28905 28884 
s 516
f 28905 22810 21998 
f 21780 28906 28928 
s 544
f 28906 21780 33277 
f 22808 28907 28952 
s 516
f 28907 22808 22319 
f 24688 28908 30184 
s 544
f 33735 33816 28908 
f 28909 33482 33726 
s 516
f 30185 28909 24685 
s 544
f 28910 33424 33703 
s 516
f 30179 28910 24686 
s 544
f 28911 33372 33689 
s 516
f 30177 28911 24687 
f 21607 28912 30176 
s 544
f 33686 33406 28912 
s 516
f 22399 28913 20763 
s 544
f 28913 22399 33381 
s 516
f 28927 28914 24521 
s 544
f 28914 33774 33556 
s 516
f 24088 28915 32697 
s 544
f 28915 24088 22802 
s 516
f 24581 28916 22886 
s 544
f 22417 22886 28916 
s 516
f 22781 22180 28917 
f 33141 28917 22180 
s 544
f 33471 25127 28918 
s 516
f 28921 28918 25127 
f 22852 28919 26918 
s 544
f 28919 22852 22812 
f 33142 32745 28920 
s 516
f 22123 28920 28946 
f 28879 28921 22794 
f 25127 22794 28921 
s 544
f 28793 28922 22903 
s 516
f 22528 22903 28922 
s 544
f 22887 28923 28947 
s 516
f 28923 22887 22890 
f 24689 28924 28808 
s 544
f 33075 32815 28924 
f 28925 33678 33018 
s 516
f 28922 28925 22528 
s 544
f 22856 28926 28817 
s 516
f 28926 22856 22116 
f 28832 28927 22872 
f 24521 22872 28927 
s 544
f 22436 28928 28932 
s 516
f 28928 22436 21780 
f 28939 28929 22224 
s 544
f 33513 22224 28929 
s 516
f 32728 28930 24915 
f 22842 24915 28930 
s 544
f 28953 28931 22782 
s 516
f 28775 22782 28931 
f 22818 28932 26919 
s 544
f 28932 22818 22436 
s 516
f 28885 28933 21591 
s 544
f 28933 32803 32714 
f 32629 32642 28934 
s 516
f 22059 28934 28874 
f 28804 28935 22311 
s 544
f 28935 32446 32591 
f 22904 28936 28954 
s 516
f 28936 22904 21704 
f 22809 28937 26928 
s 544
f 28937 22809 22884 
s 516
f 20857 28938 23672 
s 544
f 33413 23672 28938 
s 516
f 22224 25509 28939 
f 28900 28939 25509 
f 22319 28940 28907 
s 544
f 32479 32392 28940 
s 516
f 22548 28941 28964 
s 544
f 32474 32407 28941 
s 516
f 28854 28942 22323 
s 544
f 28942 32398 32445 
f 28943 32511 32434 
s 516
f 28965 28943 22315 
f 22840 28944 26916 
s 544
f 28944 22840 22838 
s 516
f 28890 28945 22307 
s 544
f 28945 32496 32399 
f 22885 28946 28799 
s 516
f 28946 22885 22123 
s 544
f 28947 25128 22887 
s 516
f 25128 28947 33588 
s 544
f 32299 32288 28948 
s 516
f 22383 28948 28797 
f 22483 28949 28842 
s 544
f 32285 32317 28949 
s 516
f 28794 28950 22147 
s 544
f 28950 32304 32279 
s 516
f 22862 28951 20776 
s 544
f 28951 22862 30168 
f 28952 22798 22808 
s 516
f 22798 28952 24788 
f 28917 28953 22781 
s 544
f 22782 22781 28953 
f 28954 22902 22904 
s 516
f 22902 28954 28873 
s 544
f 25569 28955 28807 
s 516
f 28955 25569 25568 
s 544
f 28852 28956 22823 
s 516
f 21701 22823 28956 
f 22453 22796 28957 
s 544
f 28796 28957 22796 
f 28806 28958 22893 
s 516
f 21786 22893 28958 
f 26922 25134 28959 
s 544
f 25511 28959 25134 
s 516
f 28960 25135 24791 
s 544
f 25135 28960 22452 
s 516
f 24790 25132 28961 
s 544
f 22451 28961 25132 
s 516
f 28962 25133 28826 
s 544
f 25133 28962 25510 
f 25134 28963 25511 
s 516
f 22561 25511 28963 
f 28964 22452 22548 
s 544
f 22452 28964 25135 
f 25132 28965 22451 
s 516
f 22315 22451 28965 
s 544
f 25510 28966 25133 
s 516
f 28966 25510 21946 
s 544
f 33512 33049 28967 
s 516
f 24580 28967 22749 
f 32805 33777 28968 
f 24765 28968 25551 
f 28969 33733 32708 
f 22732 28969 23309 
s 544
f 32517 32427 28970 
s 516
f 24799 28970 22753 
f 32435 32379 28971 
f 24797 28971 22752 
f 28972 32352 32431 
f 22754 28972 24796 
s 544
f 28973 32385 32388 
s 516
f 22751 28973 24798 
f 22120 28974 29056 
s 544
f 33777 32805 28974 
s 516
f 25105 28975 21750 
s 544
f 28975 32708 33733 
f 22196 28976 25093 
s 516
f 33049 33512 28976 
s 544
f 22063 28977 25100 
s 516
f 32427 32517 28977 
s 544
f 25098 28978 22327 
s 516
f 28978 32388 32385 
f 21720 28979 25101 
s 544
f 32379 32435 28979 
s 516
f 25102 28980 21717 
s 544
f 28980 32431 32352 
s 516
f 29045 25145 28981 
f 28736 28981 25145 
f 29021 25142 28982 
s 544
f 28735 28982 25142 
s 516
f 28983 22465 26970 
f 22465 28983 28738 
f 29072 25144 28984 
s 544
f 28739 28984 25144 
s 516
f 28985 22867 24436 
s 544
f 22867 28985 28737 
s 516
f 28986 22450 29074 
s 544
f 22450 28986 28745 
s 516
f 26975 25143 28987 
f 28740 28987 25143 
f 28988 22412 22210 
s 544
f 22412 28988 28741 
s 516
f 24437 25140 28989 
s 544
f 28742 28989 25140 
s 516
f 26972 22877 28990 
s 544
f 28744 28990 22877 
s 516
f 28991 25141 26973 
s 544
f 25141 28991 28743 
s 516
f 28992 22448 29073 
f 22448 28992 28747 
f 28993 25151 21821 
f 25151 28993 22889 
f 28994 25155 25113 
s 544
f 25155 28994 22467 
s 516
f 22517 25156 28995 
s 544
f 22469 28995 25156 
s 516
f 25139 28996 25157 
s 544
f 28816 25157 28996 
s 516
f 25514 28997 22921 
f 28997 25514 33080 
f 22928 28998 25365 
f 33240 25365 28998 
f 28999 25158 25117 
s 544
f 25158 28999 32783 
f 29000 25154 25109 
s 516
f 25154 29000 25137 
f 25138 29001 25147 
s 544
f 29066 25147 29001 
f 32487 21773 29002 
s 516
f 22924 29002 21773 
f 25107 25153 29003 
s 544
f 32942 29003 25153 
s 516
f 28830 25152 29004 
f 22390 29004 25152 
f 33621 33067 29005 
f 24794 29005 22901 
f 29006 25516 25095 
s 544
f 25516 29006 32643 
f 29007 25150 29052 
s 516
f 25150 29007 22896 
f 25118 24768 29008 
s 544
f 32994 29008 24768 
s 516
f 33438 29009 25146 
s 544
f 25099 25146 29009 
s 516
f 29010 25149 25103 
s 544
f 25149 29010 33308 
s 516
f 24591 25159 29011 
f 22919 29011 25159 
s 544
f 29012 33577 33347 
s 516
f 22916 29012 24582 
f 33166 32996 29013 
f 24771 29013 22927 
s 544
f 25114 25148 29014 
s 516
f 25136 29014 25148 
f 29015 22595 25091 
s 544
f 22595 29015 32635 
s 516
f 22918 29016 24784 
f 29016 32531 32570 
s 544
f 32520 32449 29017 
s 516
f 24785 29017 22925 
f 32500 32480 29018 
f 24787 29018 22922 
f 32370 32429 29019 
f 21837 29019 22914 
f 22923 29020 24786 
f 29020 32471 32351 
f 33099 29021 22145 
f 28982 22145 29021 
f 28759 29022 25526 
s 544
f 22765 25526 29022 
f 22467 29023 25155 
s 516
f 29023 22467 21631 
s 544
f 32747 25139 29024 
s 516
f 25157 29024 25139 
s 544
f 25585 29025 25580 
s 516
f 26962 25580 29025 
f 28765 29026 25519 
f 22738 25519 29026 
s 544
f 29027 25523 22764 
s 516
f 25523 29027 28760 
f 22760 22924 29028 
f 21773 29028 22924 
f 33239 29029 22232 
f 29077 22232 29029 
f 29030 22703 29076 
f 22703 29030 33122 
f 22588 29031 33078 
f 29031 22588 22740 
f 22927 29032 24771 
s 544
f 29032 22927 22788 
s 516
f 25522 29033 28769 
s 544
f 29033 25522 22743 
s 516
f 33684 29034 24458 
f 22734 24458 29034 
f 22921 29035 25514 
s 544
f 29035 22921 22768 
s 516
f 25365 29036 22928 
s 544
f 22789 22928 29036 
s 516
f 25137 29037 25154 
s 544
f 29037 25137 32857 
s 516
f 33275 29038 22920 
f 22741 22920 29038 
f 24592 29039 22915 
s 544
f 22733 22915 29039 
s 516
f 22779 22916 29040 
f 24582 29040 22916 
f 29078 25575 29041 
s 544
f 28719 29041 25575 
f 22739 25524 29042 
s 516
f 28768 29042 25524 
f 24786 29043 22923 
s 544
f 22759 22923 29043 
s 516
f 22914 29044 21837 
s 544
f 29044 22914 22731 
f 33801 29045 21670 
s 516
f 28981 21670 29045 
f 22896 29046 25150 
s 544
f 29046 22896 32965 
s 516
f 22925 29047 24785 
f 29047 22925 22761 
f 25148 29048 25136 
s 544
f 32958 25136 29048 
s 516
f 28761 29049 25527 
f 22780 25527 29049 
s 544
f 25156 29050 22469 
s 516
f 24766 22469 29050 
f 24784 29051 22918 
s 544
f 22763 22918 29051 
s 516
f 25573 29052 26909 
s 544
f 29052 25573 29007 
f 25581 29053 25584 
s 516
f 29053 25581 26963 
f 22889 29054 25151 
s 544
f 29054 22889 22482 
s 516
f 25521 29055 28767 
s 544
f 29055 25521 22742 
f 25574 29056 28692 
s 516
f 29056 25574 22120 
f 29057 25520 22747 
f 25520 29057 28764 
f 22027 29058 33228 
f 29058 22027 22736 
f 22926 29059 24435 
f 29059 22926 22769 
f 29060 25570 22730 
f 25570 29060 28763 
f 25159 29061 22919 
s 544
f 25097 22919 29061 
s 516
f 28766 29062 25525 
s 544
f 22737 25525 29062 
s 516
f 22081 29063 22917 
s 544
f 22767 22917 29063 
s 516
f 22901 29064 24794 
s 544
f 29064 22901 22770 
s 516
f 25147 29065 25138 
s 544
f 33068 25138 29065 
f 29001 25572 29066 
s 516
f 26907 29066 25572 
f 28762 29067 25528 
s 544
f 22762 25528 29067 
s 516
f 22922 29068 24787 
s 544
f 29068 22922 22758 
s 516
f 21729 29069 32781 
f 29069 21729 22726 
f 25152 29070 22390 
s 544
f 29070 32310 32287 
s 516
f 25571 29071 28758 
s 544
f 29071 25571 22727 
s 516
f 33782 29072 21687 
f 28984 21687 29072 
f 29073 21762 28992 
s 544
f 21762 29073 32931 
s 516
f 21666 29074 33791 
f 29074 21666 28986 
f 22744 21910 29075 
f 33156 29075 21910 
f 29076 25584 29030 
s 544
f 25584 29076 25581 
f 25580 29077 25585 
s 516
f 29029 25585 29077 
f 29041 24760 29078 
s 544
f 33050 29078 24760 
s 4128
f 29079 25366 30308 
s 4100
f 25366 29079 20833 
s 4128
f 30307 25518 29080 
s 4100
f 20726 29080 25518 
s off
f 31204 31776 21048 
f 21048 31776 31517 
f 31550 31775 21057 
f 21057 31775 31205 
f 31777 21049 33822 
f 21049 31777 31203 
f 31202 31778 21531 
f 33817 21531 31778 
f 21556 31779 31199 
f 31540 31779 21556 
f 31198 31780 21520 
f 21520 31780 31522 
f 23174 21374 29087 
f 20942 29087 21374 
f 29088 21373 23173 
f 21373 29088 20949 
f 30925 29089 30044 
f 29089 21803 23482 
f 23483 21804 29090 
f 30043 29090 30924 
f 21098 29091 23417 
f 23501 23417 29091 
f 29092 23418 23502 
f 23418 29092 21445 
f 30190 29093 25167 
f 23944 29093 22185 
f 22184 29094 23943 
f 25164 29094 30187 
f 25166 29095 30188 
f 21379 29095 23945 
f 23946 29096 21380 
f 30189 29096 25165 
f 21405 29097 23978 
f 29097 21405 21193 
f 29098 22508 33822 
f 22508 29098 23737 
f 23748 29099 22511 
f 21104 22511 29099 
f 29100 22512 21102 
f 22512 29100 23755 
f 30679 21230 29101 
f 23754 29101 21230 
f 23738 29102 22509 
f 33817 22509 29102 
f 21295 29103 23753 
f 29103 21295 30700 
f 23749 29104 21220 
f 24543 21220 29104 
f 29105 22385 21466 
f 22385 29105 23740 
f 23751 29106 21300 
f 21495 21300 29106 
f 29107 21301 30661 
f 21301 29107 23756 
f 33507 22510 29108 
f 23739 29108 22510 
f 29109 21229 21504 
f 21229 29109 23744 
f 23977 29110 21406 
f 33480 21406 29110 
s 1026
f 29111 33044 33475 
s 1040
f 29111 24628 33768 
s off
f 29112 21865 23706 
f 21865 29112 21382 
f 21471 29113 21076 
f 28583 21076 29113 
f 22962 31364 31373 
f 24316 29114 22962 
f 31372 31365 22961 
f 22961 29115 24315 
f 30969 29116 21418 
f 28002 21418 29116 
f 20963 29117 31014 
f 29117 30762 31697 
f 31013 29118 21025 
f 31700 30763 29118 
f 31686 30746 29119 
f 31018 29119 20995 
f 21045 29120 31020 
f 29120 30748 31685 
f 29121 30745 31679 
f 21038 29121 31022 
f 31683 30747 29122 
f 31019 29122 21041 
f 21040 29123 31021 
f 29123 30749 31684 
f 31027 29124 21037 
f 31680 30744 29124 
f 21433 29125 31015 
f 29125 30764 31693 
f 31017 29126 21434 
f 31696 30765 29126 
f 29127 30754 31692 
f 21429 29127 31028 
f 31691 30753 29128 
f 31029 29128 21430 
f 31026 29129 21011 
f 21011 31094 22994 
f 21549 31097 21255 
f 21255 29130 31024 
f 31023 29131 21068 
f 31688 30760 29131 
f 29132 30761 31687 
f 21042 29132 31025 
f 20996 29133 31032 
f 21545 31089 20996 
f 31695 30756 29134 
f 31033 29134 21432 
f 21431 29135 31030 
f 29135 30757 31698 
f 20976 31088 21544 
f 31031 29136 20976 
f 21256 29137 31036 
f 21553 31076 21256 
f 31037 29138 21257 
f 21257 31077 21552 
f 21550 31075 21144 
f 21144 29139 31039 
f 21008 31074 21551 
f 31038 29140 21008 
f 23098 21853 29141 
s 1
f 29141 21853 21071 
f 21072 21854 29142 
s off
f 29142 21854 23097 
f 21293 22472 29143 
f 30979 29143 22472 
f 22473 29144 30978 
f 29144 22473 21294 
f 22479 29145 30989 
f 29145 22479 21245 
f 30988 29146 22478 
f 21244 22478 29146 
f 20989 22474 29147 
f 30983 29147 22474 
f 22475 29148 30982 
f 29148 22475 21214 
f 30972 29149 21217 
f 33547 21217 29149 
f 21083 29150 30973 
f 29150 21083 21181 
f 29151 21012 25241 
f 21012 29151 30991 
f 25240 21121 29152 
f 30990 29152 21121 
f 30987 29153 22476 
f 21219 22476 29153 
f 21272 22480 29154 
f 30980 29154 22480 
f 22481 29155 30981 
f 29155 22481 21273 
f 29156 22477 21218 
f 22477 29156 30986 
f 21070 29157 30984 
f 29157 21070 33439 
f 33498 21069 29158 
f 30985 29158 21069 
f 21201 21309 29159 
f 30974 29159 21309 
f 21202 29160 21310 
f 21310 29160 30975 
f 22532 29161 20955 
f 20955 29161 30967 
f 20928 29162 22534 
f 30968 29162 20928 
f 24696 29163 21079 
f 21079 29163 29822 
f 21078 29164 24697 
f 29823 29164 21078 
s 544
f 25819 29165 29532 
s 516
f 29165 25819 32685 
f 29166 25640 23396 
s 544
f 25640 29166 29224 
s 516
f 29167 25639 27039 
s 544
f 25639 29167 29216 
f 25638 29168 29229 
s 516
f 29168 25638 29518 
f 25641 29169 29240 
s 544
f 29169 25641 29520 
f 29250 29170 25637 
s 516
f 29519 25637 29170 
f 32291 32327 29171 
f 21861 29171 29204 
f 33823 29172 21669 
s 544
f 25713 21669 29172 
s 516
f 32594 29173 22406 
s 544
f 25712 22406 29173 
f 29174 21789 25861 
s 516
f 21789 29174 33333 
f 29223 29175 25049 
s 544
f 29175 32535 32622 
f 29176 32650 33312 
s 516
f 29210 29176 25046 
f 21849 29177 29252 
s 544
f 32505 32391 29177 
s 516
f 25048 29178 29230 
s 544
f 32475 32341 29178 
s 516
f 29235 29179 25047 
s 544
f 29179 32378 32455 
f 25050 29180 29219 
s 516
f 32366 32596 29180 
f 29209 29181 25038 
s 544
f 29181 32362 32355 
s 516
f 25039 29182 29214 
s 544
f 32357 32462 29182 
s 516
f 24698 29183 29218 
s 544
f 32298 32322 29183 
f 29207 21630 29184 
s 516
f 27042 29184 21630 
s 544
f 29208 21716 29185 
s 516
f 25062 29185 21716 
s 544
f 29186 21625 29222 
s 516
f 21625 29186 33534 
f 29187 22182 29217 
s 544
f 22182 29187 32838 
s 516
f 22189 29188 24805 
s 544
f 29188 22189 29215 
s 516
f 29189 22064 29213 
s 544
f 22064 29189 22062 
f 22226 29190 33088 
s 516
f 29190 22226 29239 
s 544
f 29227 25688 29191 
s 516
f 29565 29191 25688 
s 544
f 29226 22338 29192 
s 516
f 22052 29192 22338 
s 544
f 29238 21793 29193 
s 516
f 29574 29193 21793 
f 29231 21945 29194 
s 544
f 33033 29194 21945 
f 29578 29195 25695 
s 516
f 29234 25695 29195 
s 544
f 29196 22235 29241 
s 516
f 22235 29196 29575 
s 544
f 29197 21722 29236 
s 516
f 21722 29197 25063 
s 544
f 29198 32632 33330 
s 516
f 29576 29198 24871 
f 29199 21886 21884 
s 544
f 21886 29199 25870 
f 29249 25689 29200 
s 516
f 27043 29200 25689 
f 29245 22330 29201 
s 544
f 25064 29201 22330 
s 516
f 29512 29202 21831 
f 29202 33792 32267 
s 544
f 29211 24870 29203 
s 516
f 33341 29203 24870 
f 29204 25696 21861 
s 544
f 25696 29204 25789 
f 29205 21633 29206 
s 516
f 21633 29205 27041 
f 25852 29206 29593 
s 544
f 29206 25852 29205 
f 29184 25673 29207 
s 516
f 29597 29207 25673 
f 22545 29208 25656 
s 544
f 29185 25656 29208 
f 29598 29209 25658 
s 516
f 25038 25658 29209 
s 544
f 29609 29210 25678 
s 516
f 25046 25678 29210 
f 29600 29211 25676 
s 544
f 29203 25676 29211 
f 29542 29212 25671 
s 516
f 29282 25671 29212 
f 25655 29213 22154 
f 29213 25655 29189 
s 544
f 25657 29214 29602 
s 516
f 29214 25657 25039 
f 25670 29215 22028 
s 544
f 29215 25670 29188 
s 516
f 25672 29216 29591 
s 544
f 29216 25672 25639 
s 516
f 29217 25850 29187 
s 544
f 25850 29217 29601 
f 25606 29218 29596 
s 516
f 29218 25606 24698 
f 25683 29219 29603 
s 544
f 29219 25683 25050 
s 516
f 29545 29220 25675 
s 544
f 29290 25675 29220 
f 25669 29221 29547 
s 516
f 29221 25669 29287 
f 25605 29222 29604 
s 544
f 29222 25605 29186 
f 29606 29223 25679 
s 516
f 25049 25679 29223 
f 25653 29224 21728 
s 544
f 29224 25653 25640 
s 516
f 29550 29225 25668 
s 544
f 29286 25668 29225 
s 516
f 24895 29226 25667 
s 544
f 29192 25667 29226 
s 516
f 24079 29227 25652 
s 544
f 29191 25652 29227 
s 516
f 25666 29228 29548 
s 544
f 29228 25666 29289 
s 516
f 25651 29229 24078 
s 544
f 29229 25651 25638 
f 25680 29230 29608 
s 516
f 29230 25680 25048 
s 544
f 29443 29231 25665 
s 516
f 29194 25665 29231 
s 544
f 29595 29232 25847 
s 516
f 26201 25847 29232 
s 544
f 29549 29233 25677 
s 516
f 29288 25677 29233 
f 29612 29234 25650 
f 29195 25650 29234 
s 544
f 29594 29235 25681 
s 516
f 25047 25681 29235 
f 25654 29236 22546 
s 544
f 29236 25654 29197 
s 516
f 29614 29237 25682 
s 544
f 27004 25682 29237 
s 516
f 27045 29238 25848 
s 544
f 29193 25848 29238 
f 25849 29239 29610 
s 516
f 29239 25849 29190 
f 25649 29240 29607 
f 29240 25649 25641 
f 25662 29241 27044 
s 544
f 29241 25662 29196 
f 29552 29242 25664 
s 516
f 29285 25664 29242 
s 544
f 25663 29243 29551 
s 516
f 29243 25663 29279 
f 25851 29244 29553 
s 544
f 29244 25851 29281 
s 516
f 29201 25648 29245 
f 22381 29245 25648 
s 544
f 29599 29246 22024 
s 516
f 29246 33330 32632 
s 544
f 29544 29247 25674 
s 516
f 29284 25674 29247 
s 544
f 25661 29248 29540 
s 516
f 29248 25661 29280 
s 544
f 29200 25647 29249 
s 516
f 29615 29249 25647 
f 27046 29250 25659 
s 544
f 25637 25659 29250 
f 25660 29251 29539 
s 516
f 29251 25660 29283 
s 544
f 25604 29252 29590 
s 516
f 29252 25604 21849 
f 23396 29253 29166 
s 544
f 32348 32397 29253 
f 29592 29254 25846 
s 516
f 26200 25846 29254 
s 544
f 25642 22936 29255 
s 516
f 21643 29255 22936 
f 22935 29256 24884 
s 544
f 29256 22935 22939 
f 22941 22934 29257 
s 516
f 29605 29257 22934 
f 22937 29258 25251 
s 544
f 29258 22937 25644 
s 516
f 21671 25713 29259 
s 544
f 29172 29259 25713 
s 516
f 29260 25861 22466 
s 544
f 25861 29260 29174 
f 29261 25706 25815 
s 516
f 25706 29261 29567 
f 29580 29262 25711 
s 544
f 21686 25711 29262 
s 516
f 22206 25712 29263 
s 544
f 29173 29263 25712 
f 29264 21925 25844 
s 516
f 21925 29264 21881 
s 544
f 21952 25705 29265 
s 516
f 29570 29265 25705 
s 544
f 29266 25709 22212 
s 516
f 25709 29266 29572 
s 544
f 29267 25708 21667 
s 516
f 25708 29267 29569 
s 544
f 25814 21832 29268 
s 516
f 21830 29268 21832 
f 29269 25710 21764 
s 544
f 25710 29269 26193 
s 516
f 29577 29270 25707 
s 544
f 22146 25707 29270 
f 22397 29271 29546 
s 516
f 29271 22397 25643 
s 544
f 29541 29272 22395 
s 516
f 23225 22395 29272 
f 33008 29273 21675 
f 22942 21675 29273 
f 21832 29274 21830 
s 544
f 29274 32267 33792 
f 33778 32137 29275 
s 516
f 21881 29275 21925 
f 29563 29276 21642 
s 544
f 29276 32045 32258 
s 516
f 22119 29277 29560 
s 544
f 32139 32042 29277 
s 516
f 24699 29278 29562 
s 544
f 32253 32046 29278 
s 516
f 29279 22359 29243 
s 544
f 22359 29279 32075 
s 516
f 29280 22347 29248 
s 544
f 22347 29280 32209 
f 29281 22339 29244 
s 516
f 22339 29281 32206 
f 29212 22341 29282 
s 544
f 32217 29282 22341 
s 516
f 29283 22353 29251 
s 544
f 22353 29283 32102 
f 32180 29284 22351 
s 516
f 29247 22351 29284 
f 29242 22355 29285 
s 544
f 32061 29285 22355 
f 29225 22349 29286 
s 516
f 32230 29286 22349 
f 29287 22345 29221 
s 544
f 22345 29287 32172 
f 32188 29288 22357 
s 516
f 29233 22357 29288 
s 544
f 29289 22343 29228 
s 516
f 22343 29289 32202 
s 544
f 29220 22361 29290 
s 516
f 32234 29290 22361 
f 25037 29291 32069 
f 29291 25037 22946 
f 22954 25028 29292 
f 32127 29292 25028 
s 544
f 25057 29293 32148 
s 516
f 29293 25057 22945 
f 22956 25027 29294 
s 544
f 32084 29294 25027 
s 516
f 22950 25030 29295 
s 544
f 32116 29295 25030 
s 516
f 22948 25031 29296 
f 32145 29296 25031 
f 25032 29297 32183 
f 29297 25032 22952 
s 544
f 25034 29298 32088 
s 516
f 29298 25034 22951 
f 25035 29299 32117 
f 29299 25035 22949 
f 32094 29300 25036 
f 22953 25036 29300 
f 32128 29301 25033 
f 22947 25033 29301 
f 25029 29302 32131 
f 29302 25029 22955 
f 25053 29303 21781 
s 544
f 33616 33231 29303 
f 32858 32638 29304 
s 516
f 22058 29304 22060 
f 22215 22214 29305 
s 544
f 32618 29305 22214 
s 516
f 25612 29306 22415 
s 544
f 29306 25612 33504 
s 516
f 22088 29307 22089 
s 544
f 29307 33640 33572 
s 516
f 25805 25717 29308 
s 544
f 21817 29308 25717 
f 25611 29309 27009 
s 516
f 29309 25611 25633 
s 544
f 22137 22443 29310 
s 516
f 25771 29310 22443 
s 544
f 29311 22440 21743 
s 516
f 22440 29311 25040 
s 544
f 22372 22316 29312 
s 516
f 25042 29312 22316 
s 544
f 29313 21948 22445 
s 516
f 21948 29313 24657 
f 29314 32574 32506 
f 29495 29314 22371 
f 29315 21894 30213 
s 544
f 21894 29315 33767 
s 516
f 29316 33553 33404 
s 544
f 25629 29316 21835 
s 516
f 29317 33572 33640 
s 544
f 25726 29317 24874 
s 516
f 33565 33552 29318 
s 544
f 21842 29318 25772 
f 24877 29319 26997 
s 516
f 33521 33002 29319 
f 29320 33245 33514 
s 544
f 26994 29320 24881 
s 516
f 29321 33626 33506 
s 544
f 25717 29321 21817 
s 516
f 29322 33062 33236 
f 25616 29322 24864 
f 29323 21866 29341 
s 544
f 21866 29323 33455 
s 516
f 33087 33610 29324 
f 24879 29324 25778 
f 30225 21618 29325 
s 544
f 33761 29325 21618 
s 516
f 32896 32802 29326 
f 24876 29326 25755 
f 32883 32795 29327 
f 24880 29327 25757 
f 29328 33579 32829 
f 25768 29328 21805 
f 32762 32709 29329 
f 24878 29329 25736 
f 32725 32579 29330 
f 22111 29330 25729 
f 32638 32858 29331 
f 22152 29331 25716 
f 32537 32565 29332 
f 21741 29332 29494 
f 29333 32512 32415 
f 25880 29333 22530 
f 29334 32532 32404 
f 25802 29334 22363 
f 29335 32439 32368 
f 25756 29335 22367 
f 29336 32540 32363 
f 25803 29336 22365 
f 22369 29337 25731 
f 32353 32464 29337 
f 24875 29338 25732 
f 33231 33616 29338 
f 25864 29339 33089 
f 29339 25864 25865 
f 25743 29340 33169 
f 29340 25743 29496 
f 29341 25797 29323 
f 25797 29341 29397 
f 29342 25789 23233 
s 544
f 25789 29342 25696 
f 25787 29343 22165 
s 516
f 29343 25787 22006 
s 544
f 29356 29344 25788 
s 516
f 25045 25788 29344 
f 29350 29345 25790 
s 544
f 25798 25790 29345 
s 516
f 29346 25779 25749 
f 25779 29346 32939 
f 29357 29347 25739 
s 544
f 32758 25739 29347 
s 516
f 30215 29348 25786 
s 544
f 20745 25786 29348 
s 516
f 25628 29349 29410 
s 544
f 29349 25628 25804 
s 516
f 33634 29350 25791 
f 25790 25791 29350 
f 25792 29351 29370 
s 544
f 29351 25792 32866 
s 516
f 25767 25759 29352 
f 33170 29352 25759 
f 33070 29353 25214 
f 29505 25214 29353 
f 29362 29354 25781 
s 544
f 25728 25781 29354 
s 516
f 29355 25796 25723 
s 544
f 25796 29355 21870 
f 25788 25803 29356 
s 516
f 22365 29356 25803 
s 544
f 29367 29357 25784 
s 516
f 25739 25784 29357 
f 30218 29358 25783 
s 544
f 20719 25783 29358 
s 516
f 25621 29359 29459 
f 29359 25621 25613 
f 25613 29360 29359 
s 544
f 29360 25613 33320 
s 516
f 25736 29361 24878 
s 544
f 29361 25736 25785 
s 516
f 25781 25776 29362 
f 32989 29362 25776 
f 25879 29363 29435 
s 544
f 29363 25879 33790 
s 516
f 25775 29364 29403 
s 544
f 29364 25775 25793 
f 32801 25782 29365 
s 516
f 29374 29365 25782 
f 22021 29366 32760 
f 29366 22021 25762 
f 27030 29367 25780 
s 544
f 25784 25780 29367 
s 516
f 25777 29368 30216 
s 544
f 29368 25777 20852 
f 21835 29369 25629 
s 516
f 29573 25629 29369 
s 544
f 25774 29370 29390 
s 516
f 29370 25774 25792 
s 544
f 25772 29371 21842 
s 516
f 29371 25772 25730 
s 544
f 27019 29372 25620 
s 516
f 25626 25620 29372 
f 25773 29373 20756 
s 544
f 29373 25773 33364 
f 29387 29374 25770 
s 516
f 25782 25770 29374 
f 22443 29375 25771 
s 544
f 32902 25771 29375 
s 516
f 25052 25799 29376 
s 544
f 29386 29376 25799 
s 516
f 29378 29377 25627 
s 544
f 33369 25627 29377 
s 516
f 25627 25626 29378 
f 29372 29378 25626 
f 29394 29379 25744 
s 544
f 25748 25744 29379 
s 516
f 25769 29380 33284 
f 29380 25769 29506 
f 25625 29381 29497 
s 544
f 29381 25625 33346 
f 29501 25763 29382 
s 516
f 27032 29382 25763 
f 25734 29383 30226 
s 544
f 29383 25734 20917 
f 29388 29384 25758 
s 516
f 21874 25758 29384 
s 544
f 25785 29385 29361 
s 516
f 29385 25785 22115 
f 21805 29386 25768 
s 544
f 25799 25768 29386 
s 516
f 27029 29387 25766 
s 544
f 25770 25766 29387 
s 516
f 29352 29388 25767 
s 544
f 25758 25767 29388 
s 516
f 25043 25720 29389 
s 544
f 29401 29389 25720 
s 516
f 25764 29390 27015 
s 544
f 29390 25764 25774 
s 516
f 25615 29391 29448 
s 544
f 29391 25615 33125 
s 516
f 25765 29392 29408 
s 544
f 29392 25765 33017 
s 516
f 25215 25878 29393 
s 544
f 29413 29393 25878 
s 516
f 25744 25268 29394 
f 33155 29394 25268 
s 544
f 25761 29395 29402 
s 516
f 29395 25761 25055 
s 544
f 32889 25624 29396 
s 516
f 29404 29396 25624 
s 544
f 25724 29397 29428 
s 516
f 29397 25724 25797 
s 544
f 25760 29398 29407 
s 516
f 29398 25760 25054 
f 29393 29399 25215 
s 544
f 32877 25215 29399 
s 516
f 25877 29400 29421 
s 544
f 29400 25877 32986 
f 25720 25756 29401 
s 516
f 22367 29401 25756 
f 25757 29402 24880 
s 544
f 29402 25757 25761 
s 516
f 25752 29403 33030 
f 29403 25752 25775 
s 544
f 29417 29404 25623 
s 516
f 25624 25623 29404 
f 25749 29405 29346 
s 544
f 29405 25749 25745 
f 25753 29406 29441 
s 516
f 29406 25753 26198 
f 25755 29407 24876 
s 544
f 29407 25755 25760 
s 516
f 29408 25754 25765 
s 544
f 25754 29408 29429 
f 29379 29409 25748 
s 516
f 26197 25748 29409 
f 25630 29410 32800 
f 29410 25630 25628 
f 22530 29411 25880 
s 544
f 25718 25880 29411 
s 516
f 25795 29412 29430 
s 544
f 29412 25795 33412 
s 516
f 21659 29413 25875 
s 544
f 25878 25875 29413 
s 516
f 30220 29414 25750 
s 544
f 20810 25750 29414 
f 32639 25751 29415 
s 516
f 29424 29415 25751 
s 544
f 25876 29416 21826 
s 516
f 29416 25876 29500 
f 27027 29417 25631 
s 544
f 25623 25631 29417 
f 27018 29418 25873 
s 516
f 25872 25873 29418 
f 21973 29419 29460 
s 544
f 33552 33565 29419 
s 516
f 25862 29420 21650 
s 544
f 29420 25862 25874 
f 25622 29421 29439 
s 516
f 29421 25622 25877 
f 33555 29422 25737 
s 544
f 25722 25737 29422 
s 516
f 25716 29423 22152 
s 544
f 29423 25716 29503 
f 29432 29424 25747 
s 516
f 25751 25747 29424 
f 20864 29425 25741 
s 544
f 33388 25741 29425 
s 516
f 25044 25746 29426 
s 544
f 29431 29426 25746 
f 25804 29427 29349 
s 516
f 29427 25804 21999 
f 25051 29428 33112 
s 544
f 29428 25051 25724 
s 516
f 25742 29429 27023 
s 544
f 29429 25742 25754 
s 516
f 25723 29430 29355 
f 29430 25723 25795 
s 544
f 25746 25802 29431 
s 516
f 22363 29431 25802 
f 27031 29432 25740 
s 544
f 25747 25740 29432 
s 516
f 25619 29433 30221 
s 544
f 29433 25619 20826 
f 25719 29434 29466 
s 516
f 29434 25719 25041 
s 544
f 25874 29435 29420 
s 516
f 29435 25874 25879 
s 544
f 29422 29436 25722 
s 516
f 25733 25722 29436 
s 544
f 25738 29437 29456 
s 516
f 29437 25738 21964 
s 544
f 33803 25871 29438 
s 516
f 29458 29438 25871 
f 25868 29439 27024 
s 544
f 29439 25868 25622 
f 33465 25869 29440 
s 516
f 29442 29440 25869 
f 25762 29441 29366 
s 544
f 29441 25762 25753 
s 516
f 29418 29442 25872 
f 25869 25872 29442 
s 544
f 25665 25735 29443 
s 516
f 27022 29443 25735 
f 21979 29444 30224 
s 544
f 33722 33421 29444 
s 516
f 29450 29445 25610 
s 544
f 29504 25610 29445 
f 33721 33460 29446 
s 516
f 21814 29446 30223 
f 21615 29447 30222 
s 544
f 33715 33442 29447 
f 25870 29448 21886 
s 516
f 29448 25870 25615 
f 30219 29449 21877 
s 544
f 29449 33746 33700 
s 516
f 25610 25272 29450 
f 33204 29450 25272 
s 544
f 29451 33740 33687 
s 516
f 30217 29451 25025 
s 544
f 29452 33766 33669 
s 516
f 30214 29452 24806 
f 29498 29453 21818 
s 544
f 29453 33506 33626 
f 33610 33087 29454 
s 516
f 21964 29454 29437 
f 29376 29455 25052 
s 544
f 29455 32829 33579 
s 516
f 25778 29456 24879 
s 544
f 29456 25778 25738 
s 516
f 25729 29457 22111 
s 544
f 29457 25729 25721 
s 516
f 25871 25867 29458 
s 544
f 29475 29458 25867 
f 25618 29459 27014 
s 516
f 29459 25618 25621 
f 29460 25730 21973 
f 25730 29460 29371 
f 20876 29461 20878 
s 544
f 29461 33514 33245 
s 516
f 29436 29462 25733 
f 26199 25733 29462 
f 29464 29463 21971 
s 544
f 29463 33236 33062 
f 29476 29464 25617 
s 516
f 21971 25617 29464 
f 20772 29465 20769 
s 544
f 33002 33521 29465 
s 516
f 25731 29466 22369 
s 544
f 29466 25731 25719 
s 516
f 25732 29467 24875 
s 544
f 29467 25732 29499 
f 29354 29468 25728 
s 516
f 21699 25728 29468 
s 544
f 32802 32896 29469 
s 516
f 25054 29469 29398 
s 544
f 32795 32883 29470 
s 516
f 25055 29470 29395 
s 544
f 25721 29471 29457 
s 516
f 29471 25721 25056 
s 544
f 25866 29472 29486 
s 516
f 29472 25866 21703 
f 22115 29473 29385 
s 544
f 32709 32762 29473 
s 516
f 25727 29474 20782 
s 544
f 29474 25727 26998 
s 516
f 24074 29475 25863 
s 544
f 25867 25863 29475 
s 516
f 24864 29476 25616 
s 544
f 25617 25616 29476 
s 516
f 25056 29477 29471 
s 544
f 32579 32725 29477 
f 29478 32506 32574 
s 516
f 22316 29478 25042 
f 25040 29479 22440 
s 544
f 32565 32537 29479 
s 516
f 29344 29480 25045 
s 544
f 29480 32363 32540 
s 516
f 29426 29481 25044 
s 544
f 29481 32404 32532 
s 516
f 29489 29482 21784 
s 544
f 29482 32415 32512 
s 516
f 25041 29483 29434 
s 544
f 32464 32353 29483 
f 25745 29484 29405 
s 516
f 29484 25745 21754 
f 29389 29485 25043 
s 544
f 29485 32368 32439 
f 29486 25865 25866 
s 516
f 25865 29486 29339 
f 23233 29487 29342 
s 544
f 32327 32291 29487 
s 516
f 29502 25726 29488 
s 544
f 24874 29488 25726 
f 29411 29489 25718 
s 516
f 21784 25718 29489 
f 29490 25793 21745 
s 544
f 25793 29490 29364 
s 516
f 24648 25798 29491 
s 544
f 29345 29491 25798 
f 27000 25614 29492 
s 516
f 20890 29492 25614 
f 27026 22137 29493 
s 544
f 29310 29493 22137 
s 516
f 29494 21743 21741 
s 544
f 21743 29494 29311 
s 516
f 22371 22372 29495 
s 544
f 29312 29495 22372 
s 516
f 29496 22445 29340 
s 544
f 22445 29496 29313 
s 516
f 25633 29497 29309 
f 29497 25633 25625 
f 21818 25805 29498 
f 29308 29498 25805 
s 544
f 29499 21781 29467 
s 516
f 21781 29499 25053 
f 22415 29500 25612 
f 29500 22415 29416 
s 544
f 29382 22215 29501 
s 516
f 29305 29501 22215 
f 29488 22088 29502 
f 22089 29502 22088 
s 544
f 29503 22060 29423 
s 516
f 22060 29503 22058 
s 544
f 29445 22148 29504 
s 516
f 27005 29504 22148 
f 29353 22036 29505 
s 544
f 29564 29505 22036 
f 22165 29506 25787 
s 516
f 29506 22165 29380 
f 29507 25026 29509 
s 544
f 25026 29507 32141 
s 516
f 29508 25686 29513 
s 544
f 25686 29508 32086 
s 516
f 29509 22466 29507 
f 22466 29509 29260 
f 29510 25815 27047 
s 544
f 25815 29510 29261 
s 516
f 29511 22212 27048 
s 544
f 22212 29511 29266 
s 516
f 21831 25814 29512 
s 544
f 29268 29512 25814 
s 516
f 29513 21764 29508 
f 21764 29513 29269 
f 29514 21667 27040 
s 544
f 21667 29514 29267 
s 516
f 22936 29515 21643 
f 29515 32258 32045 
f 25251 29516 22937 
f 32046 32253 29516 
f 24884 29517 22935 
f 32042 32139 29517 
f 29518 25838 29168 
s 544
f 25838 29518 33607 
s 516
f 29170 24019 29519 
s 544
f 33249 29519 24019 
f 29520 22015 29169 
s 516
f 22015 29520 33759 
f 29521 32830 32913 
f 25667 29521 24895 
f 32892 33063 29522 
f 22028 29522 25670 
f 29523 32350 32550 
f 25656 29523 22545 
f 25648 29524 22381 
s 544
f 29524 32498 32527 
f 32516 32723 29525 
s 516
f 22154 29525 25655 
f 22546 29526 25654 
f 32412 32481 29526 
f 32397 32348 29527 
f 21728 29527 25653 
f 32137 33778 29528 
f 21879 29528 29559 
s 544
f 33771 29529 24700 
s 516
f 25827 24700 29529 
f 24805 29530 22189 
s 544
f 33063 32892 29530 
s 516
f 22338 29531 22052 
s 544
f 29531 32913 32830 
s 516
f 25608 29532 21823 
s 544
f 29532 25608 25819 
f 22062 29533 22064 
s 516
f 32723 32516 29533 
s 544
f 22330 29534 25064 
s 516
f 29534 32527 32498 
f 25063 29535 21722 
s 544
f 32481 32412 29535 
s 516
f 21716 29536 25062 
s 544
f 29536 32550 32350 
f 32195 29537 25058 
s 516
f 29554 25058 29537 
s 544
f 32147 29538 25059 
s 516
f 29557 25059 29538 
f 22955 29539 29302 
s 544
f 29539 22955 25660 
s 516
f 22952 29540 29297 
s 544
f 29540 22952 25661 
f 22395 22942 29541 
s 516
f 29273 29541 22942 
f 29296 29542 22948 
s 544
f 25671 22948 29542 
s 516
f 25643 29543 29271 
s 544
f 29543 25643 32948 
s 516
f 29301 29544 22947 
s 544
f 25674 22947 29544 
s 516
f 29294 29545 22956 
f 25675 22956 29545 
f 22940 29546 24077 
s 544
f 29546 22940 22397 
s 516
f 22949 29547 29299 
s 544
f 29547 22949 25669 
s 516
f 29548 22951 25666 
f 22951 29548 29298 
f 29300 29549 22953 
s 544
f 25677 22953 29549 
s 516
f 29295 29550 22950 
f 25668 22950 29550 
f 22946 29551 29291 
s 544
f 29551 22946 25663 
s 516
f 29292 29552 22954 
s 544
f 25664 22954 29552 
s 516
f 29553 22945 25851 
f 22945 29553 29293 
f 29537 21671 29554 
f 29259 29554 21671 
f 27049 22146 29555 
s 544
f 29270 29555 22146 
s 516
f 29613 21686 29556 
s 544
f 29262 29556 21686 
s 516
f 29538 22206 29557 
f 29263 29557 22206 
f 27050 21952 29558 
s 544
f 29265 29558 21952 
s 516
f 29559 25844 21879 
s 544
f 25844 29559 29264 
f 22939 29560 29256 
s 516
f 29560 22939 22119 
f 26191 22941 29561 
s 544
f 29257 29561 22941 
f 25644 29562 29258 
s 516
f 29562 25644 24699 
s 544
f 29255 29563 25642 
s 516
f 21642 25642 29563 
f 26202 29564 22456 
s 544
f 22036 22456 29564 
f 33628 29565 25270 
s 516
f 25688 25270 29565 
f 25889 29566 25267 
f 33187 25267 29566 
f 29567 22582 25706 
s 544
f 22582 29567 32440 
s 516
f 32833 25890 29568 
f 25888 29568 25890 
s 544
f 25269 29569 33146 
s 516
f 29569 25269 25708 
f 25705 22938 29570 
s 544
f 32974 29570 22938 
f 32339 24934 29571 
s 516
f 25825 29571 24934 
s 544
f 22498 29572 32876 
s 516
f 29572 22498 25709 
f 29369 22391 29573 
f 25065 29573 22391 
f 21793 21794 29574 
s 544
f 33014 29574 21794 
f 21709 29575 33215 
s 516
f 29575 21709 22235 
f 24871 22026 29576 
f 25831 29576 22026 
f 25707 22097 29577 
s 544
f 33694 29577 22097 
f 25695 21848 29578 
s 516
f 33430 29578 21848 
f 29579 21823 21822 
f 21823 29579 25608 
f 25711 21688 29580 
s 544
f 32453 29580 21688 
s 516
f 29581 33312 32650 
f 25824 29581 24807 
s 544
f 32596 32366 29582 
s 516
f 22373 29582 25823 
f 25822 29583 22377 
f 29583 32622 32535 
f 32462 32357 29584 
f 21838 29584 25832 
f 25254 29585 25826 
f 32391 32505 29585 
f 25829 29586 22375 
f 29586 32455 32378 
f 29587 32355 32362 
f 25833 29587 22332 
f 32341 32475 29588 
f 22379 29588 25828 
f 32322 32298 29589 
f 25255 29589 25821 
f 25826 29590 25254 
s 544
f 29590 25826 25604 
s 516
f 25830 29591 33306 
f 29591 25830 25672 
f 25267 29592 25889 
s 544
f 25846 25889 29592 
s 516
f 25885 29593 33260 
f 29593 25885 25852 
f 22375 29594 25829 
s 544
f 25681 25829 29594 
s 516
f 25890 29595 25888 
s 544
f 25847 25888 29595 
f 29596 25821 25606 
s 516
f 25821 29596 25255 
f 25673 25834 29597 
f 33101 29597 25834 
f 22332 29598 25833 
s 544
f 25658 25833 29598 
f 22024 25831 29599 
s 516
f 22026 29599 25831 
f 25676 25827 29600 
f 29529 29600 25827 
f 25887 29601 21736 
s 544
f 29601 25887 25850 
s 516
f 25832 29602 21838 
s 544
f 29602 25832 25657 
s 516
f 25823 29603 22373 
f 29603 25823 25683 
f 25645 29604 21624 
f 29604 25645 25605 
f 32043 29605 22585 
f 22934 22585 29605 
f 22377 29606 25822 
s 544
f 25679 25822 29606 
f 24804 29607 33031 
s 516
f 29607 24804 25649 
s 544
f 29608 25828 25680 
s 516
f 25828 29608 22379 
f 24807 29609 25824 
s 544
f 25678 25824 29609 
s 516
f 25886 29610 22075 
s 544
f 29610 25886 25849 
s 516
f 22391 29611 25065 
s 544
f 29611 33404 33553 
f 32929 29612 21845 
s 516
f 25650 21845 29612 
f 32753 29613 25060 
f 29556 25060 29613 
f 25682 25825 29614 
f 24934 29614 25825 
f 32652 29615 25271 
f 25647 25271 29615 
s 4128
f 29616 22944 25598 
s 4100
f 22944 29616 20839 
s 4128
f 25599 22943 29617 
s 4100
f 20732 29617 22943 
s off
f 29618 20978 28552 
f 25601 29619 24367 
s 8
f 24976 24367 29619 
s 2147483647
f 26072 29620 23404 
s 4100
f 23404 29620 25915 
f 25914 29621 23403 
s 2147483647
f 23403 29621 26073 
s off
f 25070 21783 32041 
f 30514 29622 21783 
s 544
f 33392 29623 21616 
s 516
f 29623 33674 33770 
f 29624 33701 33747 
s 544
f 32273 29624 21813 
s 516
f 29625 24526 32332 
s 544
f 24526 29625 32713 
f 24695 29626 32052 
s 516
f 32335 32062 29626 
s off
f 33518 21034 31388 
f 31405 31388 21034 
f 21097 31389 31404 
f 31389 21097 33445 
f 26391 22056 29629 
f 29629 22056 29703 
f 29702 22055 29630 
f 29630 22055 26390 
f 30376 29631 23298 
f 27943 23298 29631 
f 29632 23299 27944 
f 23299 29632 30377 
f 32671 32039 32277 
f 32278 32040 33342 
f 31970 31967 21535 
f 21535 31967 22516 
f 21534 31968 31969 
f 22515 31968 21534 
f 29637 21239 22128 
f 25265 21239 29637 
f 22127 21238 29638 
f 29638 21238 25266 
f 22209 21461 29639 
f 29639 21461 21470 
f 29640 21235 23049 
f 20970 21235 29640 
f 23050 21234 29641 
f 20971 29641 21234 
s 4100
f 20894 24051 29642 
s 2147483647
f 26996 29642 24051 
f 24050 29643 26997 
s 544
f 29643 24050 25935 
s 2147483647
f 24053 29644 26995 
s 4100
f 29644 24053 20787 
s 544
f 25968 24054 29645 
s 2147483647
f 26994 29645 24054 
f 24055 29646 26999 
s 544
f 29646 24055 23537 
s 2147483647
f 24056 29647 26998 
s 544
f 29647 24056 26660 
f 26539 24049 29648 
s 2147483647
f 27000 29648 24049 
f 26993 29649 24052 
s 544
f 26596 24052 29649 
s 516
f 23496 24991 29650 
s 2147483647
f 29650 24991 26372 
s 516
f 29651 24998 26492 
s 2147483647
f 26364 24998 29651 
f 29652 24994 26369 
s 516
f 24207 24994 29652 
f 29653 24993 24213 
s 2147483647
f 26368 24993 29653 
s 516
f 22230 24997 29654 
s 2147483647
f 29654 24997 26363 
f 26360 25000 29655 
s 516
f 29655 25000 23474 
s 2147483647
f 29656 24992 26367 
s 516
f 24212 24992 29656 
f 21876 24988 29657 
s 2147483647
f 29657 24988 26371 
f 29658 24996 26365 
s 516
f 24225 24996 29658 
f 29659 24987 24162 
s 2147483647
f 26362 24987 29659 
s 516
f 29660 24995 24237 
s 2147483647
f 26366 24995 29660 
f 26370 24990 29661 
s 516
f 29661 24990 21815 
f 23137 24999 29662 
s 2147483647
f 29662 24999 26361 
f 26373 24989 29663 
s 516
f 29663 24989 21855 
f 29664 33726 33482 
s 544
f 33425 29664 24537 
s 516
f 29665 33703 33424 
s 544
f 32957 29665 24821 
s 516
f 29666 33689 33372 
s 544
f 32792 29666 21908 
s 516
f 32711 25587 29667 
s 544
f 32057 29667 25587 
s 4100
f 29668 25598 30227 
s 8194
f 30486 29668 22002 
f 22004 29669 30487 
s 4100
f 30228 25599 29669 
s off
f 29670 22092 23663 
f 22092 29670 32039 
f 32040 29671 22091 
f 23662 22091 29671 
f 30033 21329 29672 
f 29672 21329 31715 
f 31710 21324 29673 
f 29673 21324 30031 
f 29674 21330 30030 
f 21330 29674 23588 
f 31709 21323 29675 
f 29675 21323 30034 
f 30029 21328 29676 
f 23590 29676 21328 
f 30032 21325 29677 
f 29677 21325 31711 
f 23527 29678 21122 
f 28007 21122 29678 
f 21123 29679 23528 
f 29679 21123 28006 
f 29680 21326 30028 
f 31713 21326 29680 
f 29681 21327 31712 
f 30027 21327 29681 
f 21236 29682 23534 
f 29682 21236 28005 
f 23533 29683 21237 
f 28004 21237 29683 
f 29684 31934 31923 
f 31924 31933 29685 
f 31938 31948 29686 
f 29687 31947 31937 
f 31935 31949 29688 
f 29689 31950 31936 
f 31929 31943 29690 
f 29691 31944 31930 
f 29692 31939 31926 
f 31925 31940 29693 
f 31927 31941 29694 
f 29695 31942 31928 
f 31932 31945 29696 
f 29697 31946 31931 
f 21362 21923 29698 
f 20975 29698 21923 
f 29699 21922 21363 
f 21922 29699 20943 
f 24315 29700 22569 
f 32316 22569 29700 
f 22570 29701 24316 
f 29701 22570 32284 
f 29630 21348 29702 
f 20970 29702 21348 
f 29703 21347 29629 
f 21347 29703 20971 
f 30035 29704 23316 
f 30282 23316 29704 
f 29705 23317 30281 
f 23317 29705 30038 
f 23313 29706 20966 
f 29706 23313 30279 
f 23312 29707 21002 
f 29707 23312 30275 
f 20965 29708 23314 
f 30280 23314 29708 
f 21003 29709 23315 
f 30274 23315 29709 
f 20932 29710 23311 
f 30271 23311 29710 
f 23318 29711 20934 
f 29711 23318 30276 
s 516
f 29712 33687 33740 
s 544
f 29712 24851 33110 
s 516
f 33460 33721 29713 
s 544
f 29713 32771 33457 
s 516
f 29714 33669 33766 
s 544
f 33036 33386 29714 
s 2147483647
f 29715 29663 24471 
s 516
f 29715 24471 24703 
s 2147483647
f 29716 24472 29650 
s 516
f 23350 24472 29716 
s 2147483647
f 29717 29651 24463 
s 516
f 29717 24463 27416 
s 2147483647
f 29718 24469 29652 
s 516
f 27184 24469 29718 
s 2147483647
f 29719 29653 24466 
s 516
f 29719 24466 27221 
s 2147483647
f 29720 24462 29654 
s 516
f 23136 24462 29720 
s 2147483647
f 29721 29655 24464 
s 516
f 29721 24464 27299 
f 27234 24467 29722 
s 2147483647
f 29722 24467 29656 
f 29723 24473 29657 
s 516
f 27308 24473 29723 
f 27313 24468 29724 
s 2147483647
f 29724 24468 29658 
f 29725 29659 24461 
s 516
f 29725 24461 27364 
f 29726 24470 27276 
s 2147483647
f 29726 29660 24470 
f 29727 29661 24474 
s 516
f 29727 24474 27198 
s 2147483647
f 29728 24465 29662 
s 516
f 26062 24465 29728 
s 544
f 29729 23255 23152 
s 2147483647
f 23255 29729 24055 
s 544
f 29730 23261 23224 
s 2147483647
f 23261 29730 24050 
s 544
f 29731 23256 23836 
s 2147483647
f 23256 29731 24056 
s 4100
f 29732 23258 20797 
s 2147483647
f 23258 29732 24053 
s 544
f 23222 23260 29733 
s 2147483647
f 23260 24054 29733 
f 23259 24051 29734 
s 4100
f 20905 23259 29734 
s 2147483647
f 23262 24049 29735 
s 544
f 23822 23262 29735 
f 24813 23257 29736 
s 2147483647
f 23257 24052 29736 
s off
f 29737 24017 21224 
f 24071 24017 29737 
f 29738 24016 24070 
f 21223 24016 29738 
f 21443 29739 22010 
f 23667 22010 29739 
f 22011 29740 21417 
f 29740 22011 23668 
f 29741 22151 23670 
f 21382 22151 29741 
f 23669 22150 29742 
f 29742 22150 21471 
f 29743 21206 23601 
f 25992 21206 29743 
f 23613 20997 29744 
f 23098 29744 20997 
f 20998 29745 23097 
f 29745 20998 23614 
f 29746 21416 23610 
f 21416 29746 23173 
f 23611 21415 29747 
f 23174 29747 21415 
f 23599 21211 29748 
f 29748 21211 25991 
f 23188 29749 21204 
f 23596 21204 29749 
f 29750 21210 23598 
f 25990 21210 29750 
f 21205 29751 23189 
f 29751 21205 23597 
f 29752 21207 25997 
f 23600 21207 29752 
f 29753 21208 23602 
f 25994 21208 29753 
f 23603 21209 29754 
f 29754 21209 25993 
f 31556 22102 29755 
f 29755 22102 23686 
f 29756 24104 23676 
f 21397 24104 29756 
f 29757 24105 21419 
f 23679 24105 29757 
f 33819 29758 22161 
f 23674 22161 29758 
f 22160 29759 33492 
f 29759 22160 23673 
f 29760 24100 21174 
f 23681 24100 29760 
f 29761 24101 23683 
f 21167 24101 29761 
f 29762 22103 31517 
f 23687 22103 29762 
f 29763 22100 31519 
f 23684 22100 29763 
f 29764 22104 23688 
f 31550 22104 29764 
f 29765 22099 31522 
f 23680 22099 29765 
f 29766 24099 21598 
f 23678 24099 29766 
f 29767 22101 23685 
f 31540 22101 29767 
f 23677 24102 29768 
f 29768 24102 21221 
f 21393 24098 29769 
f 29769 24098 23675 
f 21423 24103 29770 
f 29770 24103 23682 
f 23733 24311 29771 
f 21321 29771 24311 
f 24312 29772 21320 
f 29772 24312 23734 
f 31967 31970 29773 
f 29774 31969 31968 
s 516
f 29775 21896 25632 
s 2147483647
f 21896 29775 29900 
s 516
f 22020 22019 29776 
s 2147483647
f 29901 29776 22019 
s 516
f 20734 22855 29777 
s 2147483647
f 29903 29777 22855 
s 516
f 29778 22848 20841 
s 2147483647
f 22848 29778 29904 
s 516
f 21909 22231 29779 
s 2147483647
f 29905 29779 22231 
s 516
f 20708 22858 29780 
s 2147483647
f 29906 29780 22858 
f 29907 29781 21878 
s 516
f 27566 21878 29781 
s 2147483647
f 29908 29782 22831 
s 516
f 20799 22831 29782 
s 2147483647
f 22820 29783 29913 
s 516
f 29783 22820 20906 
f 29784 22892 20815 
s 2147483647
f 22892 29784 29909 
f 21856 29785 29911 
s 516
f 29785 21856 27575 
f 29786 21816 25224 
s 2147483647
f 21816 29786 29910 
f 29912 29787 21987 
s 516
f 27570 21987 29787 
f 29788 21980 24308 
s 2147483647
f 21980 29788 29902 
s 4100
f 29789 26072 28040 
s 2147483647
f 26072 29789 29914 
f 29915 29790 26073 
s 4100
f 28041 26073 29790 
s off
f 23650 29791 21412 
f 30055 21412 29791 
f 29792 21411 30056 
f 21411 29792 23651 
f 21086 22598 29793 
f 29793 22598 30054 
f 30053 22597 29794 
f 29794 22597 21182 
f 21219 20951 31877 
f 31851 31877 20951 
f 31878 20991 21218 
f 20991 31878 31852 
s 8
f 33509 29797 21180 
s off
f 33447 21180 29797 
f 23302 21574 29798 
f 29798 21574 21181 
f 29814 23503 29799 
f 29799 23503 29804 
f 24634 21124 29800 
f 29800 21124 29815 
f 29801 24096 29807 
f 21470 24096 29801 
f 21225 24633 29802 
f 29802 24633 29810 
f 21480 29803 27979 
f 29803 21480 22690 
f 21318 21752 29804 
f 29804 21752 29799 
f 29805 21484 27978 
f 27982 21484 29805 
f 24629 24644 29806 
f 29806 24644 29811 
f 29810 20985 29807 
f 29807 20985 29801 
f 29808 24629 21470 
f 24644 24629 29808 
f 21322 22536 29809 
f 29809 22536 21095 
f 29799 24631 29810 
f 29810 24631 29802 
f 29811 23302 29800 
f 21574 23302 29811 
f 29812 22140 21181 
f 24630 22140 29812 
f 29813 24634 21181 
f 21124 24634 29813 
f 29814 21095 22536 
f 27979 21095 29814 
f 29815 24630 29805 
f 22140 24630 29815 
f 29816 22690 21480 
f 32521 22690 29816 
f 27978 23038 29817 
f 29817 23038 21181 
f 29818 24632 25586 
f 24632 29818 21480 
f 27978 29819 25588 
f 28064 25588 29819 
f 23304 29820 21046 
f 22522 21046 29820 
f 21063 29821 23303 
f 29821 21063 22523 
f 29822 23504 29838 
f 21637 23504 29822 
f 29823 23505 21638 
f 29839 23505 29823 
f 26397 29824 21621 
f 27997 21621 29824 
f 21622 29825 26398 
f 29825 21622 27996 
f 27989 29826 23304 
f 24642 23304 29826 
f 23303 29827 27990 
f 29827 23303 24641 
f 23177 29828 27988 
f 29828 23177 27999 
f 27987 29829 23176 
f 27998 23176 29829 
f 22544 24636 29830 
f 29830 24636 28631 
f 29831 24635 22543 
f 28632 24635 29831 
f 24859 24638 29832 
f 21046 29832 24638 
f 24637 29833 21063 
f 29833 24637 24858 
f 29834 21213 27987 
f 27995 21213 29834 
f 27988 21212 29835 
f 29835 21212 27994 
f 24639 21129 29836 
f 29836 21129 29830 
f 29837 21130 24640 
f 29831 21130 29837 
f 29838 21372 27989 
f 27993 21372 29838 
f 27990 21371 29839 
f 29839 21371 27992 
s 1
f 20942 29840 21635 
s off
f 23527 21635 29840 
s 1
f 20949 21636 29841 
s off
f 29841 21636 23528 
f 23533 21611 29842 
f 21087 29842 21611 
f 21612 29843 21088 
f 29843 21612 23534 
f 29163 24696 29844 
f 29844 24696 21569 
f 29845 24697 29164 
f 21568 24697 29845 
f 29846 24642 21225 
f 23304 24642 29846 
f 29847 24641 23303 
f 21181 24641 29847 
f 31040 29848 22544 
f 24636 22544 29848 
f 22543 29849 31041 
f 29849 22543 24635 
f 29850 22991 21202 
f 26397 22991 29850 
f 29851 22990 26398 
f 21201 22990 29851 
f 23500 22068 29852 
f 23986 29852 22068 
f 29853 23103 21202 
f 27987 23103 29853 
f 29854 23102 27988 
f 21201 23102 29854 
f 29855 22959 21225 
f 27989 22959 29855 
f 29856 22960 27990 
f 21181 22960 29856 
f 29857 22522 21225 
f 21046 22522 29857 
f 21181 22523 29858 
f 29858 22523 21063 
f 21129 24639 29859 
f 31040 29859 24639 
f 29860 24640 21130 
f 24640 29860 31041 
f 21361 29861 21585 
f 27986 21585 29861 
f 21584 29862 21360 
f 29862 21584 27985 
f 27984 23101 29863 
f 29863 23101 21202 
f 29864 23100 27983 
f 21201 23100 29864 
f 22067 29865 23987 
f 29865 22067 23499 
f 29866 21637 21569 
f 23504 21637 29866 
f 21568 21638 29867 
f 29867 21638 23505 
f 29868 22085 23523 
f 22085 29868 23989 
f 23988 29869 22086 
f 23522 22086 29869 
f 22970 31152 21331 
f 31321 31152 22970 
f 21332 31153 22969 
f 22969 31153 31320 
s 1040
f 20889 24931 29872 
s 2147483647
f 29872 24931 26210 
s 1040
f 29873 24928 22108 
s 2147483647
f 26214 24928 29873 
s 8194
f 29874 24926 20794 
s 2147483647
f 26216 24926 29874 
f 29875 24927 26213 
s 1040
f 21961 24927 29875 
f 29876 24933 20785 
s 2147483647
f 26212 24933 29876 
f 29877 24930 26217 
s 8194
f 20902 24930 29877 
s 2147483647
f 29878 24932 26211 
s 1040
f 24450 24932 29878 
f 29879 24929 21957 
s 2147483647
f 26215 24929 29879 
s off
f 29880 31993 31991 
f 31992 31994 29881 
f 29882 22705 21339 
f 22705 29882 24637 
f 21338 22706 29883 
f 22706 24638 29883 
f 26385 24324 29884 
f 20971 29884 24324 
f 20970 24323 29885 
f 29885 24323 26384 
f 29886 30536 31697 
f 21305 29886 30730 
f 30729 29887 21304 
f 31700 30537 29887 
f 31696 30534 29888 
f 28033 29888 21492 
f 21491 29889 28032 
f 29889 30535 31693 
f 21240 29890 30736 
f 29890 30526 31685 
f 30737 29891 21306 
f 31686 30527 29891 
s 544
f 29892 22109 26332 
s 2147483647
f 22109 29892 27955 
s 4100
f 29893 20798 20791 
s 2147483647
f 20798 29893 27954 
s 544
f 26348 21960 29894 
s 2147483647
f 27956 29894 21960 
s 544
f 29895 22863 28448 
s 2147483647
f 22863 29895 27957 
s 4100
f 20898 20899 29896 
s 2147483647
f 27952 29896 20899 
s 544
f 26310 21889 29897 
s 2147483647
f 27953 29897 21889 
f 27950 29898 22879 
s 544
f 28305 22879 29898 
f 29899 21958 26326 
s 2147483647
f 21958 29899 27951 
s 516
f 29900 26183 24673 
s 2147483647
f 29775 26183 29900 
f 29901 26173 29776 
s 516
f 24667 26173 29901 
f 29902 26186 24569 
s 2147483647
f 29788 26186 29902 
s 516
f 25482 26177 29903 
s 2147483647
f 29903 26177 29777 
s 516
f 29904 26181 25473 
s 2147483647
f 29778 26181 29904 
f 29905 26182 29779 
s 516
f 24094 26182 29905 
f 25474 26180 29906 
s 2147483647
f 29906 26180 29780 
f 29907 26174 29781 
s 516
f 24849 26174 29907 
f 25454 26179 29908 
s 2147483647
f 29908 26179 29782 
s 516
f 29909 26185 25557 
s 2147483647
f 29784 26185 29909 
s 516
f 29910 26176 23229 
s 2147483647
f 29786 26176 29910 
s 516
f 29911 26175 24850 
s 2147483647
f 29785 26175 29911 
f 29912 26184 29787 
s 516
f 21986 26184 29912 
s 2147483647
f 29783 26178 29913 
s 516
f 29913 26178 25438 
s 4100
f 29914 22003 24364 
s 2147483647
f 29789 22003 29914 
f 29915 22005 29790 
s 4100
f 24363 22005 29915 
s off
f 21308 29916 30738 
f 29916 30520 31679 
f 31680 30521 29917 
f 30739 29917 21307 
s 64
f 31407 30945 32270 
s off
f 23489 29919 23491 
f 30569 29919 20923 
f 23487 29920 21414 
f 30572 29920 22566 
f 21567 29921 21413 
f 22567 29921 30570 
f 22568 29922 30573 
f 21147 29922 23488 
f 23479 29923 30621 
f 21921 29923 23479 
f 23122 29924 30629 
f 26250 29924 23122 
f 30085 29925 23475 
f 30574 30587 29925 
f 23123 29926 26251 
f 30631 29926 23123 
f 23252 29927 21054 
f 21054 29927 30640 
f 23003 29928 23480 
f 23480 29928 30580 
f 30639 29929 21564 
f 21564 29929 23251 
f 30640 29930 23546 
f 21335 29930 23121 
f 20936 29931 30571 
f 23492 29931 23490 
f 30576 29932 23479 
f 30680 29932 21233 
f 21563 29933 30639 
f 23545 29933 20956 
f 30583 29934 21367 
f 21367 29934 21796 
f 26254 29935 21920 
f 21920 29935 30605 
f 21482 29936 30714 
f 30624 29936 21482 
f 30675 29937 23478 
f 23478 29937 30586 
f 21644 29938 20958 
f 29938 30634 30577 
f 21154 29939 21589 
f 23546 30633 29939 
f 30590 29940 22499 
f 22499 29940 30669 
f 21113 29941 21560 
f 30642 29941 23122 
f 21561 29942 20924 
f 23123 29942 30643 
f 24002 29943 23546 
f 23546 29943 30633 
f 22533 29944 30784 
f 31662 29944 30515 
f 30516 29945 31660 
f 30785 29945 22535 
f 21563 29946 26255 
f 30630 29946 21563 
f 21085 29947 24070 
f 29947 21085 22513 
f 24071 29948 21148 
f 22514 21148 29948 
f 30667 21113 29949 
f 21560 29949 21113 
f 20958 29950 21644 
f 29950 20958 22985 
f 21589 29951 21154 
f 22720 21154 29951 
f 20924 29952 21561 
f 29952 20924 30682 
f 29953 22550 21102 
f 22550 29953 21381 
f 21047 29954 30548 
f 24532 29954 21047 
f 21413 29955 21567 
f 29955 21413 22149 
f 20956 29956 23545 
f 29956 20956 29960 
f 30653 21335 29957 
f 23121 29957 21335 
f 29958 23488 22175 
f 23488 29958 21147 
f 26220 29959 23114 
f 21164 23114 29959 
f 29960 23547 33822 
f 23547 29960 22995 
f 21117 29961 30415 
f 26232 29961 21117 
f 21099 29962 30552 
f 29962 21099 33822 
f 33817 21950 29963 
f 30555 29963 21950 
f 21414 29964 23487 
f 22172 23487 29964 
f 29965 21192 33822 
f 21192 29965 22555 
f 33817 21473 29966 
f 22556 29966 21473 
f 29967 23063 21102 
f 23063 29967 30616 
f 29968 23490 22336 
f 23490 29968 23492 
f 23491 29969 23489 
f 22114 23489 29969 
f 30653 23252 29970 
f 21054 29970 23252 
f 23251 29971 21564 
f 29971 23251 29960 
f 29972 22488 21261 
f 22488 29972 30787 
f 21260 22489 29973 
f 30786 29973 22489 
f 21609 29974 30624 
f 29974 21609 29962 
f 23046 29975 30596 
f 29975 23046 29962 
f 21575 29976 31522 
f 30610 29976 21575 
f 31540 29977 21447 
f 21447 29977 30603 
f 29978 23548 29960 
f 23548 29978 30614 
f 30653 23549 29979 
f 30594 29979 23549 
f 23124 29980 30415 
f 30628 29980 23124 
f 33817 22581 29981 
f 30636 29981 22581 
f 22580 29982 30638 
f 29982 22580 33822 
f 29983 22187 21320 
f 22187 29983 21577 
f 21576 29984 22186 
f 21321 22186 29984 
f 29629 23182 29985 
f 29985 23182 26988 
f 30789 29986 21490 
f 31695 30524 29986 
f 29987 30525 31698 
f 21489 29987 30788 
f 26987 23181 29988 
f 29988 23181 29630 
f 27944 21363 29989 
f 29989 21363 21922 
f 21923 21362 29990 
f 29990 21362 27943 
f 22542 29991 31657 
f 30795 29991 22542 
f 22541 29992 30794 
f 31658 29992 22541 
f 22538 29993 31663 
f 30799 29993 22538 
f 31664 29994 22537 
f 22537 29994 30798 
f 31659 29995 22539 
f 22539 29995 30796 
f 22540 29996 31661 
f 30797 29996 22540 
f 30791 29997 21488 
f 31691 30522 29997 
f 29998 30523 31692 
f 21487 29998 30790 
f 29999 30529 31684 
f 21311 29999 30792 
f 31683 30528 30000 
f 30793 30000 21241 
f 30211 22485 30001 
f 23977 30001 22485 
f 22240 30002 23756 
f 30002 22240 30212 
f 30209 22238 30003 
f 23749 30003 22238 
f 30207 22239 30004 
f 23751 30004 22239 
f 22241 30005 23744 
f 30005 22241 30208 
f 22237 30006 23753 
f 30006 22237 30205 
f 30007 21376 30194 
f 21376 30007 23755 
f 23739 30008 21264 
f 30198 21264 30008 
f 30009 21378 30210 
f 21378 30009 23740 
f 22577 30010 23978 
f 30010 22577 30191 
f 30197 21262 30011 
f 23738 30011 21262 
f 21263 30012 23737 
f 30012 21263 30193 
f 23748 30013 21377 
f 30203 21377 30013 
f 23747 30014 22074 
f 30202 22074 30014 
f 23754 30015 22236 
f 30200 22236 30015 
f 21690 30016 23741 
f 30016 21690 30206 
f 30195 21653 30017 
f 23743 30017 21653 
f 30018 21691 30192 
f 21691 30018 23752 
f 30019 21692 30204 
f 21692 30019 23742 
f 30020 21654 30196 
f 21654 30020 23750 
f 30199 21693 30021 
f 23746 30021 21693 
f 30201 21689 30022 
f 23745 30022 21689 
f 30023 21197 30187 
f 21471 21197 30023 
f 21343 22218 30024 
f 30024 22218 30188 
f 30025 21158 21382 
f 30190 21158 30025 
f 30189 22219 30026 
f 30026 22219 21341 
f 30035 30027 24720 
f 29681 24720 30027 
f 30028 24719 29680 
f 24719 30028 30038 
f 29676 24717 30029 
f 21003 30029 24717 
f 24713 30030 21002 
f 30030 24713 29674 
f 24716 30031 20966 
f 30031 24716 29673 
f 29677 24718 30032 
f 20932 30032 24718 
f 20965 30033 24714 
f 29672 24714 30033 
f 24715 30034 20934 
f 30034 24715 29675 
f 21603 30035 20931 
f 32017 30035 21603 
f 21775 30036 32019 
f 22156 30036 21775 
f 21777 30037 22159 
f 32020 30037 21777 
f 21604 20935 30038 
f 21604 30038 32021 
f 21776 30039 22157 
f 32022 30039 21776 
f 32023 30040 21801 
f 21801 30040 33462 
f 21802 30041 32024 
f 33661 30041 21802 
f 21778 30042 32018 
f 22158 30042 21778 
f 21234 23050 30043 
f 30043 23050 29090 
f 29089 23049 30044 
f 30044 23049 21235 
f 22128 30045 21005 
f 30045 22128 21239 
f 20926 30046 22127 
f 21238 22127 30046 
f 31688 30532 30047 
f 30812 30047 21312 
f 30048 30533 31687 
f 21313 30048 30811 
s 64
f 32044 21138 31464 
s off
f 24638 24859 30050 
f 30050 24859 21225 
f 21181 24858 30051 
f 30051 24858 24637 
s 64
f 30052 21137 32334 
s off
f 29794 21409 30053 
f 30053 21409 28606 
f 28614 21410 30054 
f 30054 21410 29793 
f 20970 30055 22601 
f 29791 22601 30055 
f 30056 22602 29792 
f 20971 22602 30056 
f 25902 24545 30057 
f 20970 30057 24545 
f 30058 24546 25903 
f 24546 30058 20971 
f 26374 25256 30059 
f 20975 30059 25256 
f 30060 25257 26375 
f 25257 30060 20943 
f 25265 30061 26380 
f 31103 30061 25265 
f 25266 30062 31101 
f 26379 30062 25266 
f 30063 31221 22684 
f 22685 31220 30064 
f 30065 31214 22674 
f 22675 31215 30066 
f 22681 31211 30067 
f 30068 31213 22680 
f 22683 31217 30069 
f 30070 31216 22682 
f 22679 31210 30071 
f 30072 31212 22678 
f 30073 31218 22672 
f 22673 31219 30074 
f 22677 31222 30075 
f 30076 31223 22676 
f 30077 22057 22985 
f 22057 30077 21268 
f 22114 21904 30078 
f 20923 30078 21904 
f 22172 21601 30079 
f 22566 30079 21601 
f 23658 30080 22567 
f 30080 23658 22149 
f 22720 22129 30081 
f 20962 30081 22129 
f 21610 30082 22568 
f 30082 21610 22175 
s 64
f 21163 30083 26014 
s off
f 21905 30084 20936 
f 30084 21905 22336 
f 21164 21096 30085 
f 29925 30085 21096 
f 20948 33811 22193 
f 21319 22193 33811 
f 33436 30087 30462 
f 33436 29807 30087 
f 21550 31786 31812 
f 25196 30088 21550 
f 31809 31784 21551 
f 21551 30089 25197 
f 31820 31793 22984 
f 29134 31845 31793 
f 22983 31794 31819 
f 31794 31846 29135 
f 21552 30092 25195 
f 31811 31785 21552 
f 25194 30093 21553 
f 21553 31783 31810 
f 22981 31806 31825 
f 31806 31848 29127 
f 31826 31805 22982 
f 29128 31847 31805 
f 31817 31796 21546 
f 29124 31849 31796 
f 21543 31795 31818 
f 31795 31850 29121 
f 31815 31787 21544 
f 21544 30098 25184 
f 25185 30099 21545 
f 21545 31788 31816 
f 31821 31798 21541 
f 29119 31854 31798 
f 31797 31853 29120 
f 21542 31797 31822 
f 31800 31856 29117 
f 21547 31800 31824 
f 29118 31855 31799 
f 31823 31799 21548 
f 31808 31842 29123 
f 21540 31808 31830 
f 31829 31807 21539 
f 29122 31841 31807 
f 31814 31790 22992 
f 29126 31851 31790 
f 31789 31852 29125 
f 22993 31789 31813 
f 21537 31804 31833 
f 31804 31839 29132 
f 31831 31802 21538 
f 29131 31837 31802 
f 22994 30110 25187 
f 31832 31803 22994 
f 25186 30111 21549 
f 21549 31801 31834 
f 21314 30112 23673 
f 30112 21314 24000 
f 23674 30113 21196 
f 24001 21196 30113 
f 30114 21497 23133 
f 21497 30114 23676 
f 21142 30115 23686 
f 30115 21142 23995 
f 21501 30116 23683 
f 30116 21501 23130 
f 23679 30117 21496 
f 23132 21496 30117 
f 23681 30118 21502 
f 23131 21502 30118 
f 23684 30119 21171 
f 23999 21171 30119 
f 23996 21297 30120 
f 23687 30120 21297 
f 30121 21296 23994 
f 21296 30121 23688 
f 30122 21503 23129 
f 21503 30122 23675 
f 23128 21500 30123 
f 23677 30123 21500 
f 30124 21499 23126 
f 21499 30124 23682 
f 21246 30125 23685 
f 30125 21246 23997 
f 23667 30126 21279 
f 23993 21279 30126 
f 23662 30127 21386 
f 23985 21386 30127 
f 21215 30128 23668 
f 30128 21215 23991 
f 23992 21384 30129 
f 23669 30129 21384 
f 21385 30130 23663 
f 30130 21385 23984 
f 23680 30131 21267 
f 23998 21267 30131 
f 23678 30132 21392 
f 23127 21392 30132 
f 21278 30133 23670 
f 30133 21278 23990 
f 21286 31860 23014 
f 23014 31860 31828 
f 31827 31859 23013 
f 33547 23013 31859 
f 31906 31782 21486 
f 26419 32028 31782 
f 31781 32027 26420 
f 21485 31781 31905 
f 30785 23015 31862 
f 31836 31862 23015 
f 31861 23016 30784 
f 23016 31861 31835 
f 30140 21242 21102 
f 23988 21242 30140 
f 21388 30141 21343 
f 30141 21388 23987 
f 30142 21243 23989 
f 21104 21243 30142 
f 21341 30143 21389 
f 23986 21389 30143 
f 31873 20992 21245 
f 20992 31873 31848 
f 31847 31874 20974 
f 21244 20974 31874 
f 24966 23017 31876 
f 31849 31876 23017 
f 24965 23023 31871 
f 31841 31871 23023 
f 23024 31872 31842 
f 31872 23024 24965 
f 21568 31868 23020 
f 23020 31868 31844 
f 23019 31867 21569 
f 31843 31867 23019 
f 31870 20964 21273 
f 20964 31870 31846 
f 31845 31869 21009 
f 21272 21009 31869 
f 31854 31879 23021 
f 21517 23021 31879 
f 23022 31880 31853 
f 31880 23022 21517 
f 30186 31864 23027 
f 23027 31864 31840 
f 21536 31866 30514 
f 31838 31866 21536 
f 31865 23026 21470 
f 23026 31865 31839 
f 31837 31863 23025 
f 21470 23025 31863 
f 31875 23018 24966 
f 23018 31875 31850 
f 23029 31882 31856 
f 31882 23029 21214 
f 31855 31881 23028 
f 20989 23028 31881 
f 31152 31321 30162 
f 30163 31320 31153 
s 544
f 30164 26215 26933 
s 2147483647
f 26215 30164 24929 
f 26214 30165 24928 
s 544
f 30165 26214 26917 
s 4100
f 30166 26216 20789 
s 2147483647
f 26216 30166 24926 
f 24927 30167 26213 
s 544
f 26930 26213 30167 
f 30168 26212 28951 
s 2147483647
f 26212 30168 24933 
f 24930 30169 26217 
s 4100
f 20896 26217 30169 
s 2147483647
f 24932 30170 26211 
s 544
f 26927 26211 30170 
f 28795 26210 30171 
s 2147483647
f 24931 30171 26210 
s 516
f 30172 23238 21895 
s 2147483647
f 21896 23238 30172 
f 30173 23248 22019 
s 516
f 22018 23248 30173 
f 22854 23242 30174 
s 2147483647
f 30174 23242 22855 
f 22848 23243 30175 
s 516
f 30175 23243 22847 
f 30176 23239 21607 
s 2147483647
f 21980 23239 30176 
f 30177 23237 22231 
s 516
f 24687 23237 30177 
f 22857 23240 30178 
s 2147483647
f 30178 23240 22858 
f 30179 23246 21878 
s 516
f 24686 23246 30179 
s 2147483647
f 30180 23241 22831 
s 516
f 22830 23241 30180 
s 2147483647
f 22892 23245 30181 
s 516
f 30181 23245 22891 
f 30182 23244 22819 
s 2147483647
f 22820 23244 30182 
s 516
f 30183 23249 24511 
s 2147483647
f 21816 23249 30183 
s 516
f 30184 23247 24688 
s 2147483647
f 21856 23247 30184 
s 516
f 24685 23236 30185 
s 2147483647
f 30185 23236 21987 
s off
f 30186 21479 31596 
f 21479 30186 30877 
f 30187 22184 30023 
f 29094 22184 30187 
f 29095 21379 30188 
f 30188 21379 30024 
f 30026 21380 30189 
f 30189 21380 29096 
f 30025 22185 30190 
f 30190 22185 29093 
f 30191 21405 30010 
f 21405 30191 21193 
f 30192 22714 30018 
f 22714 30192 30388 
f 22508 30193 33822 
f 30193 22508 30012 
f 30194 22512 30007 
f 22512 30194 21102 
f 30384 30195 22709 
f 30017 22709 30195 
f 22713 30196 30385 
f 30196 22713 30020 
f 30011 22509 30197 
f 33817 30197 22509 
f 33507 30198 22510 
f 30008 22510 30198 
f 30400 30199 22712 
f 30021 22712 30199 
f 30679 30200 21230 
f 30015 21230 30200 
f 30391 30201 22710 
f 30022 22710 30201 
f 30393 30202 22711 
f 30014 22711 30202 
f 21104 30203 22511 
f 30013 22511 30203 
f 30204 22708 30019 
f 22708 30204 30395 
f 30205 21295 30006 
f 21295 30205 30700 
f 22707 30206 30390 
f 30206 22707 30016 
f 30004 21300 30207 
f 21495 30207 21300 
f 21229 30208 21504 
f 30208 21229 30005 
f 24543 30209 21220 
f 30003 21220 30209 
f 30210 22385 30009 
f 22385 30210 21466 
f 30001 21406 30211 
f 33480 30211 21406 
f 21301 30212 30661 
f 30212 21301 30002 
s 2147483647
f 26364 30213 30172 
s 516
f 30213 26364 29315 
f 24806 26372 30214 
s 2147483647
f 30173 30214 26372 
s 516
f 25786 26369 30215 
s 2147483647
f 30174 30215 26369 
f 26368 30216 30175 
s 516
f 30216 26368 25777 
f 25025 26363 30217 
s 2147483647
f 30177 30217 26363 
s 516
f 25783 26367 30218 
s 2147483647
f 30178 30218 26367 
s 516
f 21877 26371 30219 
s 2147483647
f 30179 30219 26371 
s 516
f 25750 26365 30220 
s 2147483647
f 30180 30220 26365 
s 516
f 30221 26362 25619 
s 2147483647
f 26362 30221 30181 
f 26373 30222 30184 
s 516
f 30222 26373 21615 
s 2147483647
f 26370 30223 30183 
s 516
f 30223 26370 21814 
s 2147483647
f 26360 30224 30176 
s 516
f 30224 26360 21979 
f 29325 26361 30225 
s 2147483647
f 30185 30225 26361 
s 516
f 30226 26366 25734 
s 2147483647
f 26366 30226 30182 
f 22002 30227 23404 
s 4100
f 30227 22002 29668 
f 29669 22004 30228 
s 2147483647
f 23403 30228 22004 
s off
f 30840 30229 21374 
f 30229 20942 21374 
f 21373 30230 30841 
f 30230 21373 20949 
f 24721 30231 33445 
f 30231 24721 24366 
f 33518 30232 24722 
f 24365 24722 30232 
f 31894 31791 21064 
f 21064 30233 25070 
f 23270 30234 31390 
f 30234 23270 30883 
f 31391 30235 23271 
f 30882 23271 30235 
f 31403 30236 23275 
f 30884 23275 30236 
f 30237 23274 30885 
f 23274 30237 31400 
f 31394 30238 23269 
f 23269 30238 22393 
f 22392 30239 23268 
f 23268 30239 31397 
f 30240 23272 30880 
f 23272 30240 31395 
f 31396 30241 23273 
f 30881 23273 30241 
f 33547 31720 32026 
f 31857 32026 31720 
f 31719 32025 31858 
f 21286 32025 31719 
f 23036 31367 30244 
f 30245 31366 23037 
f 28616 23650 30246 
f 27948 30246 23650 
f 23651 30247 27949 
f 30247 23651 28617 
f 28606 23652 30248 
f 30248 23652 20971 
f 30249 23653 28614 
f 20970 23653 30249 
f 22186 21321 30250 
f 27975 30250 21321 
f 21320 30251 27974 
f 30251 21320 22187 
f 30494 21368 30252 
f 24315 30252 21368 
f 30253 21369 30495 
f 21369 30253 24316 
f 30254 30255 33522 
s 8
f 33456 30254 31042 
f 30255 30254 33456 
f 33456 33505 30255 
s off
f 30256 22514 21224 
f 21148 22514 30256 
f 21223 22513 30257 
f 30257 22513 21085 
s 64
f 32269 30258 26013 
f 29918 30259 32270 
f 30260 30052 21200 
s off
f 21513 23348 30261 
f 30262 23346 21513 
s 64
f 21190 23266 30263 
s off
f 32044 30264 26982 
f 26981 30265 32334 
s 64
f 23263 30266 21442 
f 23393 30267 21160 
f 23495 30268 32270 
f 32269 30269 23494 
f 21404 30270 23253 
s off
f 30271 23595 29677 
f 29710 23595 30271 
f 21635 23527 30272 
f 28019 30272 23527 
f 30273 23528 21636 
f 23528 30273 28018 
f 29709 23590 30274 
f 28013 30274 23590 
f 30275 23588 29707 
f 23588 30275 28014 
f 29675 23589 30276 
f 30276 23589 29711 
f 23534 30277 28017 
f 30277 23534 21612 
f 28016 30278 23533 
f 21611 23533 30278 
f 29673 23591 30279 
f 30279 23591 29706 
f 30280 23594 29672 
f 29708 23594 30280 
f 29680 23593 30281 
f 30281 23593 29705 
f 30282 23592 29681 
f 29704 23592 30282 
f 30283 23610 21416 
f 23610 30283 20949 
f 21415 23611 30284 
f 20942 30284 23611 
f 30285 23600 22157 
f 21207 23600 30285 
f 20997 23613 30286 
s 1
f 30286 23613 21071 
s off
f 30287 23614 20998 
s 1
f 21072 23614 30287 
s off
f 30288 23601 21206 
f 22156 23601 30288 
f 21211 23599 30289 
f 20931 30289 23599 
f 21204 23596 30290 
f 30290 23596 22159 
f 30291 23598 21210 
f 23598 30291 20935 
f 30292 23597 21205 
f 22158 23597 30292 
f 30293 23603 33462 
f 21209 23603 30293 
f 33661 23602 30294 
f 30294 23602 21208 
s 64
f 30295 23263 21442 
s off
f 23347 30296 23348 
f 23346 30297 23347 
s 64
f 32044 30298 23266 
s off
f 30299 24853 27958 
f 24853 30299 28614 
f 28606 30300 24852 
f 27959 24852 30300 
f 30301 24855 27949 
f 20971 24855 30301 
f 27948 24856 30302 
f 20970 30302 24856 
f 30303 25981 21085 
f 25981 30303 28000 
f 28001 30304 25982 
f 21148 25982 30304 
f 25983 30305 27974 
f 30305 25983 24861 
f 24862 25984 30306 
f 27975 30306 25984 
s 4100
f 23403 30307 25914 
s 8194
f 30307 23403 27946 
s 4100
f 25915 30308 23404 
s 8194
f 27945 23404 30308 
s 8
f 30309 23661 24976 
s off
f 29618 23661 30309 
s 64
f 21163 30310 30083 
s off
f 32044 30311 24457 
f 24456 30312 32334 
s 64
f 30259 30313 32270 
f 26262 30314 21160 
f 32269 30315 30258 
s 1
f 21072 30316 21854 
s off
f 21854 30316 30461 
s 1
f 21853 30317 21071 
s off
f 30460 30317 21853 
f 30318 23313 20966 
f 23313 30318 28009 
f 20965 23314 30319 
f 28008 30319 23314 
f 28010 30320 23316 
f 30035 23316 30320 
f 23317 30321 28011 
f 30321 23317 30038 
f 20932 23311 30322 
f 28012 30322 23311 
f 21003 23315 30323 
f 28013 30323 23315 
f 30324 23312 21002 
f 23312 30324 28014 
f 23318 30325 28015 
f 30325 23318 20934 
f 20942 26003 30326 
f 28016 30326 26003 
f 30327 26002 20949 
f 26002 30327 28017 
f 28019 30328 26004 
s 1
f 20942 26004 30328 
f 20949 30329 26005 
s off
f 26005 30329 28018 
f 23755 30330 28545 
f 30330 23755 29100 
f 23753 30331 28544 
f 30331 23753 29103 
f 23744 30332 28541 
f 30332 23744 29109 
f 29102 23738 30333 
f 28540 30333 23738 
f 30334 23737 29098 
f 23737 30334 28538 
f 29108 23739 30335 
f 28543 30335 23739 
f 29106 23751 30336 
f 28535 30336 23751 
f 23741 30337 28529 
f 30337 23741 22149 
f 23742 30338 28531 
f 30338 23742 22985 
f 30339 23978 29097 
f 23978 30339 28539 
f 22720 23743 30340 
f 28527 30340 23743 
f 28536 30341 23748 
f 29099 23748 30341 
f 28542 30342 23754 
f 29101 23754 30342 
f 29110 23977 30343 
f 28537 30343 23977 
f 30344 23750 22175 
f 23750 30344 28526 
f 22114 23746 30345 
f 28530 30345 23746 
f 28528 30346 23745 
f 22172 23745 30346 
f 30347 23752 22336 
f 23752 30347 28524 
f 23740 30348 28533 
f 30348 23740 29105 
f 29104 23749 30349 
f 28534 30349 23749 
f 21164 23747 30350 
f 28525 30350 23747 
f 30351 23756 29107 
f 23756 30351 28532 
f 32019 23184 21775 
f 32019 29743 23184 
f 21603 23190 32017 
f 32017 23190 29748 
f 21777 23188 32020 
f 23188 21359 30354 
f 32022 23187 29752 
f 21776 23187 32022 
f 32021 29750 23191 
f 32021 23191 21604 
f 32024 23185 21802 
f 32024 29753 23185 
f 21801 23186 32023 
f 32023 23186 29754 
f 32018 23189 21778 
f 30359 21358 23189 
f 21104 23945 30360 
f 30360 23945 29095 
f 30361 23946 21102 
f 29096 23946 30361 
f 24447 23943 30362 
f 30362 23943 29094 
f 30363 23944 21271 
f 29093 23944 30363 
f 31260 30364 25994 
f 25994 30364 28020 
f 28021 30365 25993 
f 25993 30365 31261 
f 25990 30366 28022 
f 31259 30366 25990 
f 25991 30367 31258 
f 28023 30367 25991 
f 31255 30368 25992 
f 25992 30368 28024 
f 25997 30369 31254 
f 28027 30369 25997 
f 21204 25998 30370 
f 28029 30370 25998 
f 30371 25999 21205 
f 25999 30371 28028 
f 26001 30372 28025 
f 30372 26001 20998 
f 28026 30373 26000 
f 20997 26000 30373 
f 25996 30374 28030 
f 30374 25996 21416 
f 21415 25995 30375 
f 28031 30375 25995 
f 23298 23484 30376 
f 21175 30376 23484 
f 23485 30377 21177 
f 30377 23485 23299 
f 21389 23986 30378 
f 28556 30378 23986 
f 28555 30379 23988 
f 21242 23988 30379 
f 30380 23989 21243 
f 23989 30380 28554 
f 23987 30381 28553 
f 30381 23987 21388 
f 24000 30382 33492 
f 30382 24000 21314 
f 33819 30383 24001 
f 21196 24001 30383 
f 30384 23132 30195 
f 21496 23132 30384 
f 30385 23133 21497 
f 30196 23133 30385 
f 23984 30386 32039 
f 30386 23984 21385 
f 32040 30387 23985 
f 21386 23985 30387 
f 30388 23129 21503 
f 30192 23129 30388 
f 21267 23998 30389 
f 30389 23998 31522 
f 30206 23130 30390 
f 30390 23130 21501 
f 30391 23131 30201 
f 21502 23131 30391 
f 30392 23999 31519 
f 21171 23999 30392 
f 30393 23127 30202 
f 21392 23127 30393 
f 30394 23996 31517 
f 21297 23996 30394 
f 30395 23126 21499 
f 30204 23126 30395 
f 31550 23994 30396 
f 30396 23994 21296 
f 30397 23995 21142 
f 31556 23995 30397 
f 30398 23997 21246 
f 31540 23997 30398 
f 30399 23990 21278 
f 21382 23990 30399 
f 30400 23128 30199 
f 21500 23128 30400 
f 21384 23992 30401 
f 30401 23992 21471 
f 23991 30402 21417 
f 30402 23991 21215 
f 21279 23993 30403 
f 21443 30403 23993 
f 24365 30404 21448 
f 22551 21448 30404 
f 21449 30405 24366 
f 30405 21449 22552 
f 30406 32040 22091 
f 30958 30406 22091 
f 22092 30407 30957 
f 30407 22092 32039 
f 28548 25165 30408 
f 29096 30408 25165 
f 30409 25166 28549 
f 25166 30409 29095 
f 30410 25164 28546 
f 25164 30410 29094 
f 29093 30411 25167 
f 28547 25167 30411 
f 25203 30412 24541 
f 30412 25203 28541 
f 33507 30413 25202 
f 28543 25202 30413 
f 25213 30414 21193 
f 30414 25213 28539 
f 28535 25201 30415 
f 21117 30415 25201 
f 33480 30416 25200 
f 28537 25200 30416 
f 30923 25596 30417 
f 30417 25596 28531 
f 30418 25210 28544 
f 25210 30418 29962 
f 30419 25211 28545 
f 25211 30419 21102 
f 30420 25594 28529 
f 23658 25594 30420 
f 28542 25205 30421 
f 29963 30421 25205 
f 33817 30422 25209 
f 28540 25209 30422 
f 30423 25212 28538 
f 25212 30423 33822 
f 28530 25597 30424 
f 30424 25597 30922 
f 30425 25595 21601 
f 28528 25595 30425 
f 25204 30426 21466 
f 30426 25204 28533 
f 21104 30427 25207 
f 28536 25207 30427 
f 28527 25593 30428 
f 30428 25593 22129 
f 21610 25592 30429 
f 30429 25592 28526 
f 21905 25589 30430 
f 30430 25589 28524 
f 28534 25208 30431 
f 30653 30431 25208 
f 25206 30432 29960 
f 30432 25206 28532 
f 30433 25590 21096 
f 28525 25590 30433 
f 30434 26153 21215 
f 26153 30434 28564 
f 28563 30435 26154 
f 21279 26154 30435 
f 21500 26155 30436 
f 28565 30436 26155 
f 26156 30437 28567 
f 30437 26156 21499 
f 30438 26163 21503 
f 26163 30438 28568 
f 29081 30439 26159 
f 21297 26159 30439 
f 28569 30440 26157 
f 21392 26157 30440 
f 28566 30441 26169 
f 21384 26169 30441 
f 30442 26164 21296 
f 26164 30442 29082 
f 29086 30443 26158 
f 21267 26158 30443 
f 30444 26151 21314 
f 26151 30444 29083 
f 21196 26152 30445 
f 29084 30445 26152 
f 30446 26170 21246 
f 26170 30446 29085 
f 28571 30447 26160 
f 21502 26160 30447 
f 21386 26168 30448 
f 28572 30448 26168 
f 21171 26161 30449 
f 28639 30449 26161 
f 26172 30450 28570 
f 30450 26172 21278 
f 30451 26162 21497 
f 26162 30451 28573 
f 21496 26165 30452 
f 28574 30452 26165 
f 30453 26171 21385 
f 26171 30453 28575 
f 30454 26167 21142 
f 26167 30454 28640 
f 26166 30455 28576 
f 30455 26166 21501 
f 21067 30456 26187 
f 26187 30456 28553 
f 21077 26190 30457 
f 28556 30457 26190 
f 26189 30458 28554 
f 21104 30458 26189 
f 28555 30459 26188 
f 26188 30459 21102 
f 31613 23098 20997 
f 21853 23098 30460 
f 31612 20998 23097 
f 30461 23097 21854 
f 21470 33743 30462 
f 30462 33743 33436 
f 30086 30463 33160 
f 33160 30463 24632 
f 31790 31814 30464 
f 21219 30464 31814 
f 31813 30465 21218 
f 30465 31813 31789 
f 22628 30466 21517 
f 30466 22628 25324 
f 30467 22626 25322 
f 22626 30467 24966 
f 21517 30468 22629 
f 25325 22629 30468 
f 24966 30469 22627 
f 25323 22627 30469 
f 30470 22632 25326 
f 22632 30470 24976 
f 24976 30471 22633 
f 25327 22633 30471 
f 25317 22620 30472 
f 21244 30472 22620 
f 30473 22621 25316 
f 22621 30473 21245 
f 25318 22622 30474 
f 30474 22622 20922 
f 25319 22623 30475 
f 21272 30475 22623 
f 20812 22624 30476 
f 30476 22624 25320 
f 30477 22625 25321 
f 22625 30477 21273 
f 30478 22630 25328 
f 22630 30478 24965 
f 25329 22631 30479 
f 24965 30479 22631 
f 25313 22616 30480 
f 20989 30480 22616 
f 22617 30481 21214 
f 30481 22617 25312 
f 22618 30482 21218 
f 30482 22618 25314 
f 21219 30483 22619 
f 25315 22619 30483 
f 30484 22017 25069 
f 31467 22017 30484 
f 25068 22016 30485 
f 30485 22016 31468 
s 4100
f 26395 30486 24453 
s 8194
f 22002 24453 30486 
f 30487 24454 22004 
s 4100
f 24454 30487 26394 
s off
f 22689 30488 30784 
f 30488 22689 25334 
f 25335 22688 30489 
f 30785 30489 22688 
f 30490 22687 25333 
f 21286 22687 30490 
f 33547 30491 22686 
f 25332 22686 30491 
f 30186 22636 30492 
f 30492 22636 25330 
f 25331 22637 30493 
f 30493 22637 32521 
f 30252 22569 30494 
f 32316 30494 22569 
f 30495 22570 30253 
f 22570 30495 32284 
f 29115 30496 24315 
f 29700 24315 30496 
f 24316 30497 29114 
f 30497 24316 29701 
f 30498 26275 24590 
f 30498 24590 30848 
f 30847 24589 30499 
f 30499 24589 26274 
f 30500 24585 30843 
f 30500 26278 24585 
f 30501 24586 26279 
f 30844 24586 30501 
f 30502 24587 30846 
f 30502 26276 24587 
f 30503 24588 26277 
f 30845 24588 30503 
f 30504 26280 24854 
f 30504 24854 30839 
f 30505 26209 21672 
f 30838 26209 30505 
f 22011 30506 30962 
f 30506 22011 21417 
f 30507 21443 22010 
f 30960 30507 22010 
f 21382 30508 22151 
f 22151 30508 30963 
f 22068 23500 30509 
f 21341 30509 23500 
f 30510 23499 22067 
f 23499 30510 21343 
f 22150 30511 21471 
f 30964 30511 22150 
s 64
f 21404 30735 25916 
s off
f 27947 30513 26264 
f 32590 30513 27947 
f 21783 24986 30514 
f 21536 30514 24986 
f 29944 22533 30515 
f 30515 22533 25011 
f 25012 22535 30516 
f 30516 22535 29945 
f 24986 21783 30517 
f 25070 30517 21783 
f 25187 30518 22542 
f 25023 22542 30518 
f 30519 22541 25022 
f 22541 30519 25186 
f 30520 21308 25010 
f 29916 21308 30520 
f 30521 21307 29917 
f 25009 21307 30521 
f 30522 21488 29997 
f 22988 21488 30522 
f 30523 21487 22989 
f 29998 21487 30523 
f 30524 21490 29986 
f 22986 21490 30524 
f 29987 21489 30525 
f 30525 21489 22987 
f 30526 21240 25007 
f 29890 21240 30526 
f 30527 21306 29891 
f 25008 21306 30527 
f 30528 21241 30000 
f 25016 21241 30528 
f 29999 21311 30529 
f 30529 21311 25017 
f 25018 22538 30530 
f 25197 30530 22538 
f 30531 22537 25019 
f 22537 30531 25196 
f 30532 21312 30047 
f 25067 21312 30532 
f 30048 21313 30533 
f 30533 21313 25066 
f 30534 21492 29888 
f 30804 21492 30534 
f 30535 21491 30803 
f 29889 21491 30535 
f 30536 21305 25003 
f 29886 21305 30536 
f 25004 21304 30537 
f 30537 21304 29887 
f 25015 22489 30538 
f 25184 30538 22489 
f 22488 30539 25185 
f 30539 22488 25014 
f 22539 30540 25194 
f 30540 22539 25021 
f 25195 30541 22540 
f 25020 22540 30541 
f 22552 30542 33445 
f 30542 22552 21449 
f 21448 22551 30543 
f 33518 30543 22551 
s 64
f 30544 26262 32270 
s off
f 32044 24457 30545 
f 30546 24456 32334 
s 64
f 32269 26014 30547 
s off
f 30548 26221 21047 
f 26221 30548 21663 
f 30549 20959 26245 
f 30618 20959 30549 
f 30616 22190 30550 
f 30550 22190 30676 
f 26249 22972 30551 
f 30551 22972 30612 
f 30552 22331 21099 
f 30581 22331 30552 
f 21992 30553 26223 
f 30651 26223 30553 
f 24832 30554 26236 
f 30554 24832 30682 
f 30555 26219 30601 
f 21950 26219 30555 
f 30556 26226 30696 
f 26226 30556 22555 
f 26224 30557 22995 
f 30557 26224 30688 
f 30558 22568 30082 
f 22568 30558 29922 
f 30559 21462 26244 
f 30625 21462 30559 
f 30686 26222 30560 
f 22556 30560 26222 
f 30667 24830 30561 
f 26232 30561 24830 
f 30665 23479 30562 
f 29932 30562 23479 
f 26242 24824 30563 
f 30563 24824 30576 
f 30564 24827 30642 
f 24540 24827 30564 
f 29939 30565 24584 
f 24834 24584 30565 
f 29966 22556 30566 
f 26231 30566 22556 
f 26223 30567 23480 
f 30653 23480 30567 
f 21557 30568 26232 
f 29961 26232 30568 
f 29969 23491 30569 
f 30569 23491 29919 
f 30570 21567 29955 
f 29921 21567 30570 
f 29931 23492 30571 
f 30571 23492 29968 
f 29964 21414 30572 
f 30572 21414 29920 
f 30573 21147 29958 
f 29922 21147 30573 
f 29959 26220 30574 
f 30574 26220 30587 
f 24830 30575 21920 
f 30657 21920 30575 
f 30576 21233 29932 
f 23004 21233 30576 
f 30634 24831 30577 
f 30577 24831 24539 
f 26225 30578 21420 
f 30578 26225 24837 
f 30081 20962 30579 
f 24584 30579 20962 
f 26252 20947 30580 
f 30580 20947 30604 
f 30623 26228 30581 
f 30581 26228 24841 
f 30582 24583 24835 
f 24583 30582 29938 
f 30643 24823 30583 
f 30583 24823 24538 
f 30584 22995 29960 
f 22995 30584 26221 
f 30585 22555 29965 
f 22555 30585 26225 
f 30679 20961 30586 
f 30586 20961 30555 
f 30587 23475 29925 
f 30690 23475 30587 
f 29941 30588 23122 
f 26983 23122 30588 
f 30589 22567 30080 
f 22567 30589 29921 
f 30590 24535 30700 
f 30552 24535 30590 
f 20936 30591 29931 
f 30591 20936 30084 
f 30592 23123 26984 
f 23123 30592 29942 
f 26247 21562 30593 
f 30593 21562 30632 
f 26222 30594 24829 
f 23549 24829 30594 
f 30595 21367 30685 
f 21367 30595 26224 
f 30596 21482 23046 
f 21482 30596 24832 
f 30079 22566 30597 
f 29920 30597 22566 
f 30598 26237 30697 
f 30550 26237 30598 
f 26280 30599 20936 
f 30599 26280 30935 
f 30549 26239 30600 
f 30600 26239 24838 
f 30601 21478 30613 
f 26243 21478 30601 
f 30602 26236 30674 
f 26236 30602 21993 
f 22499 30603 24854 
f 30603 22499 21447 
f 21639 26230 30604 
f 30604 26230 30564 
f 30605 21407 30609 
f 22571 21407 30605 
f 21351 30606 26231 
f 24839 26231 30606 
f 22566 30607 26277 
f 30926 26277 30607 
f 29925 30608 21672 
f 30934 21672 30608 
f 24542 26240 30609 
f 30609 26240 30551 
f 26209 30610 23478 
f 21575 23478 30610 
f 30658 21992 30611 
f 24588 30611 21992 
f 30612 26229 30593 
f 21055 26229 30612 
f 23006 26227 30613 
f 30613 26227 30563 
f 24828 30614 26226 
f 30614 24828 23548 
f 30078 20923 30615 
f 29919 30615 20923 
f 30559 26233 30616 
f 30616 26233 23063 
f 21663 30617 24587 
f 30617 21663 30652 
f 30577 26234 30618 
f 30618 26234 21454 
f 30619 26276 30927 
f 26276 30619 22567 
f 21268 30620 24583 
f 30620 21268 30077 
f 30621 21463 30574 
f 24544 21463 30621 
f 26275 30622 21268 
f 30622 26275 30923 
f 30623 21381 29953 
f 30598 21381 30623 
f 30600 22971 30624 
f 30624 22971 21609 
f 30625 26235 21161 
f 30571 26235 30625 
f 21993 30626 24590 
f 30626 21993 30647 
f 30922 26274 30627 
f 20923 30627 26274 
f 24589 30628 21557 
f 23124 21557 30628 
f 30629 24822 30572 
f 21572 24822 30629 
f 30630 24826 21573 
f 30573 24826 30630 
f 30570 24825 30631 
f 30631 24825 22242 
f 30632 26238 30569 
f 21424 26238 30632 
f 29951 21589 30633 
f 30633 21589 29939 
f 30634 21644 29950 
f 29938 21644 30634 
f 20962 30635 26279 
f 30929 26279 30635 
f 24586 30636 21351 
f 22581 21351 30636 
f 30637 26278 30928 
f 26278 30637 22568 
f 30638 21420 22580 
f 21420 30638 24585 
f 29933 23545 30639 
f 30639 23545 29956 
f 30640 23121 29930 
f 29957 23121 30640 
f 29970 21054 30641 
f 24829 30641 21054 
f 29949 21560 30642 
f 30642 21560 29941 
f 29942 21561 30643 
f 30643 21561 29952 
f 21564 30644 24828 
f 30644 21564 29971 
f 26985 23546 30645 
f 29930 30645 23546 
f 21563 30646 29933 
f 30646 21563 26986 
f 30647 26253 30626 
f 30412 26253 30647 
f 29963 30648 24542 
f 26240 24542 30648 
f 30649 21796 29934 
f 21796 30649 30682 
f 30650 24838 26239 
f 24838 30650 29962 
f 30651 21337 24531 
f 30553 21337 30651 
f 30652 23047 30617 
f 24532 23047 30652 
f 30567 24543 30653 
f 30653 24543 30431 
f 26231 24839 30654 
f 33817 30654 24839 
f 24822 21572 30655 
f 22172 30655 21572 
f 26230 21639 30656 
f 30667 30656 21639 
f 30575 24836 30657 
f 29963 30657 24836 
f 30611 26256 30658 
f 30658 26256 24531 
f 26238 21424 30659 
f 22114 30659 21424 
f 21164 30660 24544 
f 21463 24544 30660 
f 30661 21047 26221 
f 24532 21047 30661 
f 22242 30662 22149 
f 30662 22242 24825 
f 29939 21154 30663 
f 22720 30663 21154 
f 30664 20958 29938 
f 20958 30664 22985 
f 30562 26246 30665 
f 21104 30665 26246 
f 30666 23063 26233 
f 23063 30666 21102 
f 30561 21117 30667 
f 30667 21117 25201 
f 30668 24837 26225 
f 24837 30668 33822 
f 31540 21447 30669 
f 30669 21447 22499 
f 21573 30670 22175 
f 30670 21573 24826 
f 30671 21413 29921 
f 21413 30671 22149 
f 30672 21609 22971 
f 21609 30672 29962 
f 29928 23003 30673 
f 30667 30673 23003 
f 30674 21143 30602 
f 30412 21143 30674 
f 23478 21575 30675 
f 30675 21575 31522 
f 24842 30676 21102 
f 30676 24842 30550 
f 30677 23124 30415 
f 21557 23124 30677 
f 26227 23006 30678 
f 21104 30678 23006 
f 30679 24840 24527 
f 30586 24840 30679 
f 21233 23004 30680 
f 21104 30680 23004 
f 21161 30681 22336 
f 30681 21161 26235 
f 25203 24541 30682 
f 30682 24541 30554 
f 30667 30683 21113 
f 29941 21113 30683 
f 29963 30684 21055 
f 26229 21055 30684 
f 30685 26248 30595 
f 26248 30685 30682 
f 30653 30686 21473 
f 30560 21473 30686 
f 26245 30687 29962 
f 30687 26245 20959 
f 23547 30688 30682 
f 30688 23547 30557 
f 21104 30689 26242 
f 24824 26242 30689 
f 21164 30690 23114 
f 30587 23114 30690 
f 21104 30691 21950 
f 26219 21950 30691 
f 23488 30692 22175 
f 30692 23488 29922 
f 22720 30693 24834 
f 24584 24834 30693 
f 30694 21099 22331 
f 21099 30694 21102 
f 22172 30695 23487 
f 29920 23487 30695 
f 21192 30696 29960 
f 30696 21192 30556 
f 22550 30697 21102 
f 30697 22550 30598 
f 20947 26252 30698 
f 30667 30698 26252 
f 30699 26244 21462 
f 26244 30699 22336 
f 30700 24843 30590 
f 24528 24843 30700 
f 29935 26254 30701 
f 29963 30701 26254 
f 21454 30702 29962 
f 30702 21454 26234 
f 33817 30703 22581 
f 21351 22581 30703 
f 21407 22571 30704 
f 29963 30704 22571 
f 29963 30705 26249 
f 22972 26249 30705 
f 21478 26243 30706 
f 21104 30706 26243 
f 30707 24841 26228 
f 24841 30707 21102 
f 30708 22580 21420 
f 22580 30708 33822 
f 26251 30709 22614 
f 30709 26251 29926 
f 20924 30710 30682 
f 30710 20924 29942 
f 24835 30711 22985 
f 30711 24835 24583 
f 29919 23489 30712 
f 22114 30712 23489 
f 30713 26255 29946 
f 26255 30713 22191 
f 30714 23046 21482 
f 23046 30714 29962 
f 21164 30715 21921 
f 29923 21921 30715 
f 21562 26247 30716 
f 22696 30716 26247 
f 22615 30717 26250 
f 29924 26250 30717 
f 24539 30718 22638 
f 30718 24539 24831 
f 30719 23490 29931 
f 23490 30719 22336 
f 29943 24002 30720 
f 22192 30720 24002 
f 30721 24538 24823 
f 24538 30721 30682 
f 30667 30722 24540 
f 24827 24540 30722 
f 30723 23251 29929 
f 23251 30723 29960 
f 30653 30724 23252 
f 29927 23252 30724 
f 23548 30725 29960 
f 30725 23548 24828 
f 24829 23549 30726 
f 30653 30726 23549 
f 30727 20956 29933 
f 20956 30727 29960 
f 30653 30728 21335 
f 29930 21335 30728 
f 21293 30729 25004 
f 21304 25004 30729 
f 25003 30730 21294 
f 30730 25003 21305 
s 64
f 21137 30731 32334 
s off
f 23347 30732 30296 
f 30297 30733 23347 
s 64
f 30734 30295 21442 
f 21404 30512 30735 
s off
f 25007 30736 21214 
f 30736 25007 21240 
f 20989 30737 25008 
f 21306 25008 30737 
f 30738 25010 21308 
f 25010 30738 21218 
f 21307 25009 30739 
f 21219 30739 25009 
f 25247 21257 30740 
f 25335 30740 21257 
f 25332 30741 21008 
f 25246 21008 30741 
f 21144 30742 25333 
f 30742 21144 25245 
f 21256 30743 25334 
f 30743 21256 25248 
f 30744 21037 29124 
f 25231 21037 30744 
f 29121 21038 30745 
f 30745 21038 25232 
f 25238 20995 30746 
f 30746 20995 29119 
f 25237 21041 30747 
f 30747 21041 29122 
f 29120 21045 30748 
f 30748 21045 25236 
f 29123 21040 30749 
f 30749 21040 25235 
f 25068 30750 21299 
f 25240 21299 30750 
f 21298 30751 25069 
f 30751 21298 25241 
f 25227 20976 30752 
f 25318 30752 20976 
f 30753 21430 29128 
f 25234 21430 30753 
f 29127 21429 30754 
f 30754 21429 25233 
f 30755 20996 25226 
f 20996 30755 25320 
f 25228 21432 30756 
f 30756 21432 29134 
f 30757 21431 25225 
f 29135 21431 30757 
f 25331 30758 21011 
f 25249 21011 30758 
f 30759 21255 25250 
f 21255 30759 25330 
f 25230 21068 30760 
f 30760 21068 29131 
f 29132 21042 30761 
f 30761 21042 25229 
f 29117 20963 30762 
f 30762 20963 25243 
f 25244 21025 30763 
f 30763 21025 29118 
f 30764 21433 25239 
f 29125 21433 30764 
f 25242 21434 30765 
f 30765 21434 29126 
f 22104 30766 30996 
f 31550 30766 22104 
f 31551 30767 24101 
f 24101 30767 30997 
f 22103 30768 31517 
f 30998 30768 22103 
f 24100 30769 31518 
f 30999 30769 24100 
f 24104 30770 31000 
f 31545 30770 24104 
f 31001 30771 24105 
f 24105 30771 31516 
f 22102 30772 31012 
f 31556 30772 22102 
f 31540 30773 22101 
f 22101 30773 31006 
f 22099 30774 31522 
f 31003 30774 22099 
f 24099 30775 31524 
f 31002 30775 24099 
f 31535 30776 24103 
f 24103 30776 31011 
f 30777 23522 21587 
f 22086 23522 30777 
f 30778 21586 23523 
f 30778 23523 22085 
f 31010 30779 24102 
f 24102 30779 31520 
f 31016 30780 22100 
f 22100 30780 31519 
f 30781 33819 22161 
f 31009 30781 22161 
f 22160 30782 31008 
f 30782 22160 33492 
f 24098 30783 31007 
f 31543 30783 24098 
f 30784 25011 22533 
f 33439 25011 30784 
f 22535 25012 30785 
f 30785 25012 33498 
f 21201 30786 25015 
f 22489 25015 30786 
f 30787 25014 22488 
f 21202 25014 30787 
f 22987 30788 21568 
f 30788 22987 21489 
f 21490 22986 30789 
f 21569 30789 22986 
f 22989 30790 20812 
f 30790 22989 21487 
f 21488 22988 30791 
f 20922 30791 22988 
f 25017 30792 21245 
f 30792 25017 21311 
f 21244 30793 25016 
f 21241 25016 30793 
f 30794 25022 22541 
f 25022 30794 21181 
f 33547 30795 25023 
f 22542 25023 30795 
f 30796 25021 22539 
f 25021 30796 33439 
f 33498 30797 25020 
f 22540 25020 30797 
f 30798 25019 22537 
f 25019 30798 25241 
f 25240 30799 25018 
f 22538 25018 30799 
s 2049
f 30806 26395 30800 
s 4100
f 24453 30800 26395 
s 2049
f 30801 26394 30805 
s 4100
f 26394 30801 24454 
s off
f 30802 21479 22484 
f 21479 30802 31476 
f 22648 30803 20927 
f 30803 22648 30535 
f 30534 22649 30804 
f 20987 30804 22649 
s 4100
f 23032 30805 28550 
s 2049
f 30805 23032 30801 
s 4100
f 28551 30806 23033 
s 2049
f 30800 23033 30806 
s off
f 25906 30807 32284 
f 30807 25906 29114 
f 29115 25905 30808 
f 32316 30808 25905 
f 30809 26387 29628 
f 26387 30809 21449 
f 29627 26386 30810 
f 21448 30810 26386 
f 25066 30811 21273 
f 30811 25066 21313 
f 21312 25067 30812 
f 21272 30812 25067 
f 25070 21418 30813 
f 33547 30813 21418 
f 30814 20955 25183 
f 33439 20955 30814 
f 25182 20928 30815 
f 30815 20928 33498 
f 30816 21012 25196 
f 21012 30816 25241 
f 25240 30817 21121 
f 25197 21121 30817 
f 30818 21083 25186 
f 21083 30818 21181 
f 25187 21217 30819 
f 33547 30819 21217 
f 30820 22475 25179 
f 22475 30820 21214 
f 30821 22479 25192 
f 22479 30821 21245 
f 21244 30822 22478 
f 25193 22478 30822 
f 25178 22474 30823 
f 20989 30823 22474 
f 26273 30824 20812 
f 30824 26273 25191 
f 20922 30825 26272 
f 25190 26272 30825 
f 25180 22476 30826 
f 21219 30826 22476 
f 26270 30827 21568 
f 30827 26270 25188 
f 21569 30828 26271 
f 25189 26271 30828 
f 22477 30829 21218 
f 30829 22477 25181 
f 21272 30830 22480 
f 25199 22480 30830 
f 30831 22481 25198 
f 22481 30831 21273 
f 30832 21070 25194 
f 21070 30832 33439 
f 33498 30833 21069 
f 25195 21069 30833 
f 30834 22473 25174 
f 22473 30834 21294 
f 21293 30835 22472 
f 25175 22472 30835 
f 21201 30836 21309 
f 25184 21309 30836 
f 21202 21310 30837 
f 30837 21310 25185 
f 30505 24711 30838 
f 30838 24711 31522 
f 30839 24710 30504 
f 31540 24710 30839 
f 21374 23174 30840 
f 31729 23174 21415 
f 30841 23173 21373 
f 31730 21416 23173 
f 29619 25601 30842 
f 30843 24707 30500 
f 21049 24707 30843 
f 30844 24708 21531 
f 30501 24708 30844 
f 30845 24709 24531 
f 30503 24709 30845 
f 24532 25985 30846 
f 30846 25985 30502 
f 30499 24702 30847 
f 30847 24702 30415 
f 30848 24701 30498 
f 30412 24701 30848 
f 31980 31957 21023 
f 31974 31953 21108 
f 21109 31954 31973 
f 21020 31958 31979 
f 21021 31963 31984 
f 31983 31964 21024 
f 21028 31960 31981 
f 31982 31959 21029 
f 31978 31961 21156 
f 21157 31962 31977 
f 21092 31956 31975 
f 31976 31955 21093 
f 21165 31966 31985 
f 31986 31965 21166 
f 31933 31924 21526 
f 21526 31924 21507 
f 21506 31923 21525 
f 21525 31923 31934 
f 21523 31928 21515 
f 21515 31928 31942 
f 31941 31927 21516 
f 21516 31927 21524 
f 31940 31925 21507 
f 21507 31925 21535 
f 21534 31926 21506 
f 21506 31926 31939 
f 21555 31935 22393 
f 31949 31935 21555 
f 21554 31936 31950 
f 22392 31936 21554 
f 21022 31932 21526 
f 31945 31932 21022 
f 21019 31931 31946 
f 21525 31931 21019 
f 21532 31930 31944 
f 22392 31930 21532 
f 21533 31929 22393 
f 31943 31929 21533 
f 21524 31938 21022 
f 31948 31938 21524 
f 21523 31937 31947 
f 21019 31937 21523 
f 27947 22484 30877 
f 30877 22484 21479 
f 31989 31988 21125 
f 21127 31987 31990 
f 21532 30880 22672 
f 22672 30880 30073 
f 30074 30881 22673 
f 22673 30881 21533 
f 22677 30882 21516 
f 30075 30882 22677 
f 21515 30883 22676 
f 22676 30883 30076 
f 30066 30884 22675 
f 22675 30884 21524 
f 22674 30885 30065 
f 21523 30885 22674 
f 22679 30886 21022 
f 30071 30886 22679 
f 22678 30887 30072 
f 21019 30887 22678 
f 30067 30888 22681 
f 22681 30888 21526 
f 21525 30889 22680 
f 22680 30889 30068 
f 22683 30890 21535 
f 30069 30890 22683 
f 22682 30891 30070 
f 21534 30891 22682 
f 22685 30892 21507 
f 30064 30892 22685 
f 22684 30893 30063 
f 21506 30893 22684 
f 30894 26259 30971 
f 26259 30894 20927 
f 30970 26260 30895 
f 20987 30895 26260 
f 21266 21723 30896 
s 8
f 30896 21723 24976 
s off
f 21266 30897 30309 
f 23286 30898 26429 
f 26430 30899 23285 
f 26438 30900 23292 
f 23291 30901 26437 
f 23288 30902 26432 
f 26431 30903 23287 
f 26433 30904 23294 
f 23293 30905 26434 
f 23284 30906 26428 
f 26427 30907 23283 
f 26435 30908 23295 
f 23296 30909 26436 
f 26425 30910 23290 
f 23289 30911 26426 
f 21128 31995 31998 
f 31997 31996 21126 
f 30914 23277 23511 
f 23277 30914 31115 
f 31114 30915 23276 
f 23512 23276 30915 
f 23517 23278 30916 
f 31116 30916 23278 
f 30917 23279 23516 
f 23279 30917 31117 
f 23280 30918 31119 
f 30918 23280 23510 
f 31118 30919 23281 
f 23513 23281 30919 
f 21331 31992 22516 
f 31994 31992 21331 
f 21332 31991 31993 
f 22515 31991 21332 
f 30424 30922 21904 
f 30627 21904 30922 
f 30923 22057 30622 
f 22057 30923 30417 
f 31132 30924 21804 
f 29090 21804 30924 
f 21803 30925 31133 
f 30925 21803 29089 
f 30607 21601 30926 
f 25595 30926 21601 
f 30927 23658 30619 
f 23658 30927 25594 
f 30928 21610 30637 
f 21610 30928 25592 
f 25593 30929 22129 
f 30635 22129 30929 
f 23418 30930 31130 
f 30930 23418 21445 
f 31131 30931 23417 
f 21098 23417 30931 
f 31262 30932 23359 
f 23358 30933 31263 
f 25590 30934 21096 
f 30608 21096 30934 
f 21905 30935 25589 
f 30935 21905 30599 
f 23037 30936 30245 
f 22515 30936 23037 
f 23036 30937 22516 
f 30244 30937 23036 
f 31135 30938 23354 
f 23008 23354 30938 
f 30939 23353 23007 
f 23353 30939 31134 
s 64
f 30940 30544 32270 
f 32269 30547 30941 
f 32269 26013 30942 
s off
f 32044 30545 30943 
f 30944 30546 32334 
s 64
f 30945 29918 32270 
s off
f 21299 30946 26419 
f 30242 26419 30946 
f 26420 30947 21298 
f 30947 26420 30243 
f 28003 30948 21286 
f 30948 28003 31049 
f 23521 30949 27977 
f 27976 30950 23520 
s 64
f 32044 30951 30049 
f 30952 30734 21442 
s off
f 23347 30953 30732 
f 30733 30954 23347 
f 30955 22652 21032 
f 22652 30955 20971 
f 21033 22651 30956 
f 22651 20970 30956 
f 30957 23663 22092 
f 21385 23663 31756 
f 22091 23662 30958 
f 31755 23662 21386 
f 30509 30959 22068 
f 22068 31750 31651 
f 23667 31655 31752 
f 22010 23667 30960 
f 22067 30961 30510 
f 31649 31754 22067 
f 30962 23668 22011 
f 31753 31656 23668 
f 31751 31653 23670 
f 30963 23670 22151 
f 23669 31648 31749 
f 22150 23669 30964 
f 23519 30965 31159 
f 30965 23519 23514 
f 23515 23518 30966 
f 31158 30966 23518 
f 30515 25183 30967 
f 30967 25183 20955 
f 30968 25182 30516 
f 20928 25182 30968 
f 21418 25070 30969 
f 30233 30969 25070 
f 30895 25176 30970 
f 30970 25176 30534 
f 30971 25177 30894 
f 30535 25177 30971 
f 21217 25187 30972 
f 30110 30972 25187 
f 30973 25186 21083 
f 25186 30973 30111 
f 30098 30974 25184 
f 21309 25184 30974 
f 30975 25185 21310 
f 25185 30975 30099 
f 30976 25190 30522 
f 26272 25190 30976 
f 30977 25191 26273 
f 30523 25191 30977 
f 30978 25174 22473 
f 30536 25174 30978 
f 30979 25175 30537 
f 22472 25175 30979 
f 30980 25199 30532 
f 22480 25199 30980 
f 30981 25198 22481 
f 30533 25198 30981 
f 30526 25179 30982 
f 30982 25179 22475 
f 30983 25178 30527 
f 22474 25178 30983 
f 25194 30984 30093 
f 30984 25194 21070 
f 30092 30985 25195 
f 21069 25195 30985 
f 30520 25181 30986 
f 30986 25181 22477 
f 22476 25180 30987 
f 30987 25180 30521 
f 22478 25193 30988 
f 30988 25193 30528 
f 30989 25192 22479 
f 30529 25192 30989 
f 21121 25197 30990 
f 30089 30990 25197 
f 30991 25196 21012 
f 25196 30991 30088 
f 30992 25189 30524 
f 26271 25189 30992 
f 30525 25188 30993 
f 30993 25188 26270 
f 31100 30994 24324 
f 20971 24324 30994 
f 24323 30995 31102 
f 20970 30995 24323 
f 33515 23688 31744 
f 30996 23688 22104 
f 33564 23683 31735 
f 30997 23683 24101 
f 33660 31740 23687 
f 22103 23687 30998 
f 33561 31741 23681 
f 24100 23681 30999 
f 31000 23676 24104 
f 32691 23676 31742 
f 24105 23679 31001 
f 32695 31736 23679 
f 24099 23678 31002 
f 32679 31746 23678 
f 32681 31738 23680 
f 22099 23680 31003 
f 22086 31733 31650 
f 30777 31004 22086 
f 31654 31745 22085 
f 22085 31005 30778 
f 31006 23685 22101 
f 33763 23685 31737 
f 31007 23675 24098 
f 32543 23675 31732 
f 31008 23673 22160 
f 31748 31652 23673 
f 31747 23674 31647 
f 22161 23674 31009 
f 24102 23677 31010 
f 32599 31734 23677 
f 32701 23682 31731 
f 31011 23682 24103 
f 33503 23686 31739 
f 31012 23686 22102 
f 21025 25244 31013 
f 20989 31013 25244 
f 25243 31014 21214 
f 31014 25243 20963 
f 31015 25239 21433 
f 25239 31015 21218 
f 22100 23684 31016 
f 32683 31743 23684 
f 21219 31017 25242 
f 21434 25242 31017 
f 21517 31018 25238 
f 20995 25238 31018 
f 21041 25237 31019 
f 24965 31019 25237 
f 25236 31020 21517 
f 31020 25236 21045 
f 25235 31021 24965 
f 31021 25235 21040 
f 31022 25232 21038 
f 25232 31022 24966 
f 21470 31023 25230 
f 21068 25230 31023 
f 31024 25250 21255 
f 30186 25250 31024 
f 25229 31025 21470 
f 31025 25229 21042 
f 21011 25249 31026 
f 31026 25249 30514 
f 21037 25231 31027 
f 24966 31027 25231 
f 31028 25233 21429 
f 25233 31028 21245 
f 21244 31029 25234 
f 21430 25234 31029 
f 31030 25225 21431 
f 25225 31030 21273 
f 31031 25227 21569 
f 20976 25227 31031 
f 31032 25226 20996 
f 21568 25226 31032 
f 21432 25228 31033 
f 21272 31033 25228 
f 21286 25241 31034 
f 31034 25241 21298 
f 21299 25240 31035 
f 33547 31035 25240 
f 31036 25248 21256 
f 25248 31036 30784 
f 21257 25247 31037 
f 30785 31037 25247 
f 21008 25246 31038 
f 33547 31038 25246 
f 21286 25245 31039 
f 31039 25245 21144 
f 31040 21361 23140 
f 21361 31040 24639 
f 23141 21360 31041 
f 24640 31041 21360 
f 24326 21232 31042 
s 8
f 33456 31042 21232 
s off
f 31043 21231 24325 
s 8
f 21231 31043 33509 
s off
f 31044 25903 24546 
f 29985 25903 31044 
f 31045 26374 29990 
f 25256 26374 31045 
f 31046 26375 25257 
f 29989 26375 31046 
f 24545 25902 31047 
f 31047 25902 29988 
f 30233 31048 29116 
f 28002 29116 31048 
f 30877 26263 31049 
f 31049 26263 30948 
f 31050 26421 20812 
f 26421 31050 30094 
f 20922 26422 31051 
f 30095 31051 26422 
f 26423 31052 30091 
f 31052 26423 21568 
f 21569 26424 31053 
f 30090 31053 26424 
f 31054 29145 21245 
f 29145 31054 30104 
f 30105 31055 29146 
f 21244 29146 31055 
f 30103 31056 29143 
f 21293 29143 31056 
f 31057 29144 21294 
f 29144 31057 30102 
f 25240 29152 31058 
f 30089 31058 29152 
f 31059 29151 25241 
f 29151 31059 30088 
f 21219 29153 31060 
f 30096 31060 29153 
f 31061 29156 21218 
f 29156 31061 30097 
f 29148 31062 30101 
f 31062 29148 21214 
f 30100 31063 29147 
f 20989 29147 31063 
f 30109 31064 29154 
f 21272 29154 31064 
f 29155 31065 30108 
f 31065 29155 21273 
f 31066 29150 21181 
f 29150 31066 30111 
f 30110 31067 29149 
f 33547 29149 31067 
f 29157 31068 30093 
f 31068 29157 33439 
f 33498 29158 31069 
f 30092 31069 29158 
f 29160 31070 30099 
f 21202 31070 29160 
f 21201 29159 31071 
f 30098 31071 29159 
f 22532 31072 29161 
f 29161 31072 30137 
f 30136 31073 29162 
f 29162 31073 22534 
f 21008 29140 31074 
f 30135 31074 29140 
f 29139 31075 30134 
f 31075 29139 21144 
f 29137 31076 30139 
f 31076 29137 21256 
f 30138 31077 29138 
f 21257 29138 31077 
f 21026 23001 31078 
f 20975 31078 23001 
f 31853 31079 29120 
f 29120 31079 30154 
f 29119 31080 31854 
f 30153 31080 29119 
f 23002 31081 20943 
f 31081 23002 21027 
f 30146 31082 29124 
f 29124 31082 31849 
f 31850 31083 29121 
f 29121 31083 30159 
f 30145 31084 29128 
f 29128 31084 31847 
f 31848 31085 29127 
f 29127 31085 30144 
f 29122 31086 31841 
f 30147 31086 29122 
f 29123 31087 30148 
f 31842 31087 29123 
f 20976 29136 31088 
f 30150 31088 29136 
f 29133 31089 30149 
f 31089 29133 20996 
f 29134 31090 31845 
f 30152 31090 29134 
f 29135 31091 30151 
f 31846 31091 29135 
f 30161 31092 29118 
f 29118 31092 31855 
f 31856 31093 29117 
f 29117 31093 30160 
f 30156 31094 29129 
f 21011 29129 31094 
f 31839 31095 29132 
f 29132 31095 30157 
f 30158 31096 29131 
f 29131 31096 31837 
f 31097 29130 21255 
f 29130 31097 30155 
f 31852 31098 29125 
f 29125 31098 29796 
f 29795 31099 29126 
f 29126 31099 31851 
f 31618 31100 26385 
f 24324 26385 31100 
f 31101 21026 31045 
f 23001 21026 31101 
f 31619 26384 31102 
f 31102 26384 24323 
f 31103 21027 23002 
f 31046 21027 31103 
f 20926 31104 26379 
f 30062 26379 31104 
f 26380 31105 21005 
f 31105 26380 30061 
f 31106 27960 28055 
f 28054 27961 31107 
f 31108 27962 28057 
f 28056 27963 31109 
f 28058 27964 31110 
f 31111 27965 28059 
f 31112 27966 28061 
f 28060 27967 31113 
f 23276 27968 31114 
f 31115 27969 23277 
f 23278 27970 31116 
f 31117 27971 23279 
f 23281 27972 31118 
f 31119 27973 23280 
f 30888 31120 26282 
f 31401 26282 31120 
f 26281 31121 30889 
f 31121 26281 31402 
f 31122 26284 31399 
f 26284 31122 30893 
f 31398 26283 31123 
f 30892 31123 26283 
f 31124 26286 31393 
f 26286 31124 30887 
f 30886 31125 26285 
f 31392 26285 31125 
f 31126 23734 24312 
f 23734 31126 21577 
f 24017 31127 21224 
f 31324 31127 24017 
f 24016 31128 31325 
f 21223 31128 24016 
f 21576 31129 23733 
f 24311 23733 31129 
f 29637 23502 31130 
f 31130 23502 23418 
f 23417 23501 31131 
f 31131 23501 29638 
f 31473 31132 23483 
f 21804 23483 31132 
f 31133 23482 21803 
f 23482 31133 31474 
f 31134 28063 23353 
f 23354 28062 31135 
f 31427 26257 31136 
f 30890 31136 26257 
f 26258 31137 30891 
f 31137 26258 31426 
f 31138 22677 21516 
f 31222 22677 31138 
f 21515 22676 31139 
f 31139 22676 31223 
f 31140 22672 31218 
f 21532 22672 31140 
f 31141 22673 21533 
f 31219 22673 31141 
f 21019 22678 31142 
f 31142 22678 31212 
f 31143 22679 21022 
f 31210 22679 31143 
f 31144 22683 21535 
f 31217 22683 31144 
f 31145 22682 31216 
f 21534 22682 31145 
f 31211 22681 31146 
f 31146 22681 21526 
f 21525 22680 31147 
f 31147 22680 31213 
f 31148 22685 21507 
f 31220 22685 31148 
f 31149 22684 31221 
f 21506 22684 31149 
f 31215 22675 31150 
f 31150 22675 21524 
f 31151 22674 31214 
f 21523 22674 31151 
f 31152 29870 21331 
f 30162 29870 31152 
f 21332 29871 31153 
f 31153 29871 30163 
f 31757 24365 21448 
f 24722 24365 31154 
f 21449 24366 31758 
f 31155 24366 24721 
f 22701 31156 27948 
f 24856 27948 31156 
f 27949 31157 22702 
f 31157 27949 24855 
f 23518 28621 31158 
f 31159 28622 23519 
f 31160 27958 24853 
f 20970 27958 31160 
f 31473 33464 21522 
f 21522 31161 31721 
f 21521 33830 31474 
f 31722 31162 21521 
f 31163 27959 20971 
f 24852 27959 31163 
f 20980 31164 24853 
f 31164 20980 22700 
f 24852 31165 20984 
f 22699 20984 31165 
f 22515 31166 29636 
f 29636 31166 31568 
f 29635 31167 22516 
f 31569 31167 29635 
f 31591 26980 31168 
f 29870 31168 26980 
f 26979 31169 29871 
f 31169 26979 31590 
f 30229 31170 32276 
f 31729 31170 30229 
f 30230 31171 31730 
f 30230 32308 31171 
f 20970 31172 20982 
f 22701 20982 31172 
f 20971 20981 31173 
f 31173 20981 22702 
f 31174 22975 25989 
f 22975 31174 21088 
f 25988 22976 31175 
f 21087 31175 22976 
f 31176 22973 25987 
s 1
f 20949 22973 31176 
f 20942 31177 22974 
s off
f 25986 22974 31177 
f 22665 23275 31178 
f 30884 31178 23275 
f 23274 31179 30885 
f 31179 23274 22664 
f 22660 23271 31180 
f 30882 31180 23271 
f 23270 31181 30883 
f 31181 23270 22661 
f 31182 23272 22663 
f 23272 31182 30880 
f 30881 31183 23273 
f 22662 23273 31183 
f 22392 23268 31184 
f 31184 23268 22659 
f 22658 23269 31185 
f 31185 23269 22393 
f 20982 22701 31186 
f 30304 31186 22701 
f 22702 31187 30303 
f 31187 22702 20981 
f 31188 27974 30305 
f 27974 31188 20980 
f 30306 27975 31189 
f 20984 31189 27975 
f 30304 28001 31190 
f 20970 31190 28001 
f 31191 28000 30303 
f 20971 28000 31191 
f 31755 31192 30406 
f 30406 31192 32040 
f 30407 32039 31193 
f 30407 31193 31756 
f 26392 24476 31194 
f 33817 31194 24476 
f 31195 25076 28523 
f 25076 31195 32284 
f 31196 24475 26393 
f 24475 31196 33822 
f 32316 31197 25075 
f 28522 25075 31197 
f 21520 22606 31198 
f 30443 31198 22606 
f 31199 22605 21556 
f 22605 31199 30446 
f 20984 22699 31200 
f 24862 31200 22699 
f 22700 31201 24861 
f 31201 22700 20980 
f 30445 31202 22958 
f 21531 22958 31202 
f 31203 22957 21049 
f 22957 31203 30444 
f 21048 22607 31204 
f 30439 31204 22607 
f 22066 31205 30442 
f 31205 22066 21057 
f 22608 31206 30454 
f 31206 22608 21504 
f 30449 31207 22609 
f 21495 22609 31207 
f 31367 23036 31208 
f 31208 23036 22516 
f 22515 23037 31209 
f 31209 23037 31366 
f 31143 23295 31210 
f 31146 23294 31211 
f 31212 23296 31142 
f 31213 23293 31147 
f 31214 23286 31151 
f 31150 23285 31215 
f 31216 23288 31145 
f 31144 23287 31217 
f 31218 23284 31140 
f 31141 23283 31219 
f 31148 23292 31220 
f 31221 23291 31149 
f 31138 23290 31222 
f 31223 23289 31139 
s 1
f 20942 31224 21395 
s off
f 22978 21395 31224 
s 1
f 20949 21394 31225 
s off
f 31225 21394 22977 
f 20966 31226 21002 
f 31226 20966 22501 
f 21003 31227 20965 
f 22504 20965 31227 
f 22506 21017 31228 
f 30035 31228 21017 
f 31229 21018 22505 
f 21018 31229 30038 
f 20942 31230 23042 
f 21090 23042 31230 
f 31231 23043 21091 
f 23043 31231 20949 
f 31232 21002 22507 
f 21002 31232 20934 
f 20932 31233 21003 
f 22500 21003 31233 
f 20965 31234 20939 
f 22503 20939 31234 
f 31235 20940 22502 
f 20940 31235 20966 
f 30328 28019 31236 
f 22978 31236 28019 
f 28018 31237 22977 
f 31237 28018 30329 
f 30281 28011 31238 
f 31238 28011 30321 
f 31239 28010 30282 
f 30320 28010 31239 
f 22504 31240 28013 
f 30323 28013 31240 
f 31241 28014 30324 
f 28014 31241 22501 
f 31242 28015 30325 
f 30276 28015 31242 
f 28017 31243 21091 
f 31243 28017 30327 
f 21090 31244 28016 
f 30326 28016 31244 
f 30322 28012 31245 
f 31245 28012 30271 
f 31246 28008 30280 
f 30319 28008 31246 
f 31247 28009 30318 
f 30279 28009 31247 
f 23110 31248 31723 
f 21382 31248 23110 
f 21472 31249 21471 
f 31724 31249 21472 
f 22977 31250 30372 
f 31250 22977 21394 
f 30373 31251 22978 
f 21395 22978 31251 
f 20965 22504 31252 
f 30370 31252 22504 
f 22501 31253 30371 
f 31253 22501 20966 
f 21003 22500 31254 
f 31254 22500 31245 
f 31242 22507 31255 
f 31255 22507 21002 
f 21091 31256 30374 
f 31256 21091 23043 
f 23042 21090 31257 
f 30375 31257 21090 
f 20939 22503 31258 
f 31258 22503 31246 
f 31259 22502 20940 
f 31247 22502 31259 
f 31238 22505 31260 
f 31260 22505 21018 
f 21017 22506 31261 
f 31261 22506 31239 
f 23359 22970 31262 
f 31262 22970 21331 
f 21332 22969 31263 
f 31263 22969 23358 
f 31264 28538 25212 
f 28538 31264 22932 
f 28529 31265 22066 
f 31265 28529 25594 
f 31266 28544 25210 
f 28544 31266 21662 
f 28531 31267 22608 
f 31267 28531 25596 
f 22931 31268 28540 
f 25209 28540 31268 
f 28526 31269 22957 
f 31269 28526 25592 
f 25593 28527 31270 
f 22958 31270 28527 
f 28539 31271 22600 
f 31271 28539 25213 
f 22176 31272 28536 
f 25207 28536 31272 
f 22609 31273 28530 
f 25597 28530 31273 
f 25205 28542 31274 
f 21694 31274 28542 
f 25595 28528 31275 
f 22607 31275 28528 
f 28545 31276 22177 
f 31276 28545 25211 
f 31277 28541 25203 
f 28541 31277 21656 
f 25202 28543 31278 
f 22599 31278 28543 
f 21655 31279 28535 
f 25201 28535 31279 
f 31280 28524 25589 
f 28524 31280 22605 
f 22929 31281 28537 
f 25200 28537 31281 
f 25590 28525 31282 
f 22606 31282 28525 
f 31283 28532 25206 
f 28532 31283 21599 
f 21600 31284 28534 
f 25208 28534 31284 
f 28533 31285 22930 
f 31285 28533 25204 
f 25165 28548 31286 
f 31286 28548 21102 
f 21104 28549 31287 
f 31287 28549 25166 
f 24447 28546 31288 
f 31288 28546 25164 
f 25167 28547 31289 
f 31289 28547 21271 
f 31290 28025 30372 
s 1
f 21072 28025 31290 
f 31291 28026 21071 
s off
f 30373 28026 31291 
f 22158 28028 31292 
f 31292 28028 30371 
f 30369 28027 31293 
f 31293 28027 22157 
f 22156 28024 31294 
f 31294 28024 30368 
f 30370 28029 31295 
f 31295 28029 22159 
f 31296 28030 30374 
f 28030 31296 20949 
f 20942 31297 28031 
f 30375 28031 31297 
f 30367 28023 31298 
f 20931 31298 28023 
f 31299 28022 30366 
f 28022 31299 20935 
f 30365 28021 31300 
f 31300 28021 33462 
f 33661 28020 31301 
f 31301 28020 30364 
f 23030 21006 31302 
f 25165 31302 21006 
f 25167 31303 20957 
f 22998 20957 31303 
f 21030 31304 25166 
f 31304 21030 23031 
f 20952 31305 25164 
f 31305 20952 22997 
f 21694 22696 31306 
f 29963 31306 22696 
f 20941 31307 21193 
f 31307 20941 22600 
f 22638 31308 29962 
f 31308 22638 21662 
f 31309 21053 22177 
f 21053 31309 21102 
f 31310 21016 22932 
f 21016 31310 33822 
f 22599 20929 31311 
f 33507 31311 20929 
f 33817 31312 21015 
f 22931 21015 31312 
f 22176 21052 31313 
f 21104 31313 21052 
f 30667 31314 22615 
f 21655 22615 31314 
f 22614 31315 30682 
f 31315 22614 21656 
f 33480 31316 20977 
f 22929 20977 31316 
f 31317 22191 21599 
f 22191 31317 29960 
f 21600 22192 31318 
f 30653 31318 22192 
f 31319 20972 22930 
f 20972 31319 21466 
f 31320 23358 22969 
f 22970 23359 31321 
f 31621 31322 24312 
f 24312 31322 31126 
f 31129 31323 24311 
f 31620 24311 31323 
f 32036 31324 24071 
f 24017 24071 31324 
f 31325 24070 24016 
f 32035 24070 31325 
f 31326 22932 21016 
f 22932 31326 30453 
f 22192 21600 31327 
f 30452 31327 21600 
f 31328 21656 22614 
f 21656 31328 30455 
f 21599 31329 30451 
f 31329 21599 22191 
f 30447 31330 21655 
f 22615 21655 31330 
f 30448 31331 22931 
f 21015 22931 31331 
f 30441 31332 22599 
f 20929 22599 31332 
f 20957 31333 28556 
f 30457 28556 31333 
f 22600 31334 30450 
f 31334 22600 20941 
f 31335 28554 30458 
f 28554 31335 21030 
f 31336 22177 21053 
f 22177 31336 30438 
f 30459 28555 31337 
f 21006 31337 28555 
f 21052 22176 31338 
f 30440 31338 22176 
f 30435 31339 22929 
f 20977 22929 31339 
f 28553 31340 20952 
f 31340 28553 30456 
f 31341 21662 22638 
f 21662 31341 30437 
f 30436 31342 21694 
f 22696 21694 31342 
f 31343 22930 20972 
f 22930 31343 30434 
f 31344 28576 30455 
f 22707 28576 31344 
f 22713 28573 31345 
f 31345 28573 30451 
f 30452 28574 31346 
f 31346 28574 22709 
f 22714 28568 31347 
f 31347 28568 30438 
f 28575 31348 32039 
f 31348 28575 30453 
f 30448 28572 31349 
f 32040 31349 28572 
f 30447 28571 31350 
f 31350 28571 22710 
f 31351 28566 21471 
f 30441 28566 31351 
f 31352 28570 30450 
f 21382 28570 31352 
f 30440 28569 31353 
f 31353 28569 22711 
f 31354 28567 30437 
f 22708 28567 31354 
f 30435 28563 31355 
f 33820 31355 28563 
f 31356 28564 30434 
f 28564 31356 21364 
f 30436 28565 31357 
f 31357 28565 22712 
s 1
f 21072 31358 30316 
s off
f 30316 31358 31612 
f 31613 31359 30317 
s 1
f 30317 31359 21071 
s off
f 21006 23030 31360 
f 31360 23030 21102 
f 31361 23031 21030 
f 21104 23031 31361 
f 20957 22998 31362 
f 21077 31362 22998 
f 21067 22997 31363 
f 31363 22997 20952 
f 29114 31364 22962 
f 31364 29114 25906 
f 22961 31365 29115 
f 25905 29115 31365 
f 31366 23521 31209 
f 31208 23520 31367 
f 26377 31368 31616 
f 33830 31368 26377 
f 33464 26376 31369 
f 31617 31369 26376 
f 31370 24721 29797 
f 24721 31370 31155 
f 31371 30254 24722 
f 31154 31371 24722 
f 32316 31372 21034 
f 22961 21034 31372 
f 21097 31373 32284 
f 31373 21097 22962 
f 30910 26425 31374 
f 31374 26425 21516 
f 21515 26426 31375 
f 31375 26426 30911 
f 30908 26435 31376 
f 31376 26435 21022 
f 31377 26436 30909 
f 21019 26436 31377 
f 21525 26434 31378 
f 31378 26434 30905 
f 31379 26433 21526 
f 30904 26433 31379 
f 21532 26428 31380 
f 31380 26428 30906 
f 31381 26427 21533 
f 30907 26427 31381 
f 31382 26431 21535 
f 30903 26431 31382 
f 31383 26432 30902 
f 21534 26432 31383 
f 31384 26430 21524 
f 30899 26430 31384 
f 31385 26429 30898 
f 21523 26429 31385 
f 21506 26437 31386 
f 31386 26437 30901 
f 31387 26438 21507 
f 30900 26438 31387 
f 26386 29627 31388 
f 33518 31388 29627 
f 31389 29628 26387 
f 29628 31389 33445 
f 31390 22661 23270 
f 23271 22660 31391 
f 31125 22667 31392 
f 31393 22666 31124 
f 23269 22658 31394 
f 31395 22663 23272 
f 23273 22662 31396 
f 31397 22659 23268 
f 31123 22668 31398 
f 31399 22669 31122 
f 31400 22664 23274 
f 31120 22671 31401 
f 31402 22670 31121 
f 23275 22665 31403 
f 31404 22962 21097 
f 22962 31404 26387 
f 26386 31405 22961 
f 21034 22961 31405 
s 64
f 31406 30940 32270 
f 21160 31407 32270 
f 32269 31408 21163 
f 32269 31409 21138 
s off
f 32044 31410 21451 
f 21450 31411 32334 
f 30875 29686 31412 
f 31413 29687 30876 
f 30874 29690 31414 
f 31415 29691 30873 
f 30871 29696 31416 
f 31417 29697 30872 
f 30869 29688 31418 
f 31419 29689 30870 
f 31420 29692 30868 
f 30867 29693 31421 
f 31422 29695 30865 
f 30866 29694 31423 
f 30863 29685 31424 
f 31425 29684 30864 
f 31426 22964 31137 
f 31136 22963 31427 
f 30849 31428 30236 
f 30884 30236 31428 
f 26284 31429 30853 
f 31429 26284 30893 
f 30854 31430 26283 
f 30892 26283 31430 
f 30237 31431 30852 
f 31431 30237 30885 
f 30239 31432 30855 
f 22392 31432 30239 
f 30856 31433 30238 
f 30238 31433 22393 
f 31434 26281 30889 
f 26281 31434 30851 
f 30888 26282 31435 
f 30850 31435 26282 
f 31436 30240 30880 
f 30240 31436 30858 
f 30857 31437 30241 
f 30881 30241 31437 
f 30882 30235 31438 
f 30860 31438 30235 
f 30234 31439 30859 
f 31439 30234 30883 
f 31440 26286 30887 
f 26286 31440 30861 
f 30862 31441 26285 
f 30886 26285 31441 
f 22632 31442 31679 
f 31442 22632 24976 
f 24976 22633 31443 
f 31680 31443 22633 
f 22628 31444 31684 
f 31444 22628 21517 
f 22626 31445 31685 
f 31445 22626 24966 
f 21517 22629 31446 
f 31683 31446 22629 
f 24966 22627 31447 
f 31686 31447 22627 
f 31448 22630 24965 
f 22630 31448 31687 
f 31688 31449 22631 
f 24965 22631 31449 
f 31450 22625 21245 
f 22625 31450 31698 
f 31451 22621 21214 
f 22621 31451 31692 
f 20812 31452 22624 
f 22624 31452 31694 
f 22622 31453 20922 
f 31690 31453 22622 
f 31691 31454 22620 
f 20989 22620 31454 
f 31455 22617 21218 
f 22617 31455 31697 
f 31695 31456 22623 
f 21244 22623 31456 
f 31696 31457 22619 
f 21131 22619 31457 
f 22618 31458 31693 
f 31458 22618 21101 
f 21219 22616 31459 
f 31700 31459 22616 
s 64
f 31466 26269 32334 
s off
f 23347 31461 30953 
f 30954 31462 23347 
s 64
f 21404 25916 31463 
f 32044 31464 30951 
f 31465 30952 21442 
f 31460 31466 32334 
s off
f 23091 31467 21460 
f 21460 31467 31664 
f 23090 21459 31468 
f 31663 31468 21459 
f 23147 21202 31469 
f 23147 31469 31659 
f 23146 31470 21201 
f 31661 31470 23146 
f 21181 31471 23148 
f 23148 31471 31658 
f 31657 31472 21318 
f 21318 31472 21225 
f 33464 31473 21119 
f 21119 31473 31667 
f 31668 31474 21084 
f 33830 21084 31474 
f 32521 31475 21322 
f 23145 21322 31475 
f 31476 21481 23142 
f 20948 21481 31476 
f 23170 31477 21181 
f 31477 23170 21436 
f 21435 23169 31478 
f 21225 31478 23169 
f 21494 31479 21361 
f 31479 21494 23143 
f 23144 21493 31480 
f 21360 31480 21493 
f 31479 31481 21361 
f 21361 31481 31662 
f 31660 31482 21360 
f 21360 31482 31480 
f 21067 31483 23171 
f 21350 23171 31483 
f 31484 23172 21349 
f 23172 31484 21077 
f 31485 23091 21468 
f 23091 31485 31467 
f 23090 31468 31486 
f 21469 23090 31486 
f 30772 31487 31739 
f 31556 31487 30772 
f 30766 31488 31744 
f 31550 31488 30766 
f 31551 31489 30767 
f 30767 31489 31735 
f 23161 33481 31490 
f 23161 31490 31641 
f 31629 31491 23160 
f 23160 31491 33486 
f 21280 23334 31492 
f 21265 31492 23334 
f 21014 31493 25172 
f 22653 25172 31493 
f 23340 31494 21455 
f 31494 23340 21281 
f 25173 31495 20960 
f 31495 25173 22654 
f 21398 31496 23335 
f 20983 23335 31496 
f 21187 31497 23336 
f 21189 23336 31497 
f 31498 23342 20986 
f 23342 31498 21452 
f 31499 23345 21198 
f 23345 31499 21188 
f 30509 31500 31750 
f 21341 31500 30509 
f 31535 31501 30776 
f 30776 31501 31731 
f 30773 31502 31737 
f 31540 31502 30773 
f 30506 21417 31503 
f 30506 31503 31753 
f 30783 31504 31732 
f 31543 31504 30783 
f 21382 31505 30508 
f 30508 31505 31751 
f 21007 31506 31635 
f 31547 31506 21007 
f 21184 31507 31636 
f 31548 31507 21184 
f 31549 31508 20954 
f 20954 31508 31637 
f 31498 31509 21452 
f 21452 31509 31639 
f 21188 31510 31646 
f 31499 31510 21188 
f 31495 31511 20960 
f 20960 31511 31644 
f 23162 31512 31640 
f 23162 20925 31512 
f 31633 31513 21302 
f 21302 31513 32601 
f 31754 31514 30510 
f 30510 31514 21343 
f 21104 31515 24529 
f 26409 24529 31515 
f 24105 31516 21419 
f 26412 21419 31516 
f 26404 24531 31517 
f 21048 31517 24531 
f 26417 21174 31518 
f 24100 31518 21174 
f 26405 24533 31519 
f 21495 31519 24533 
f 24102 31520 21221 
f 26416 21221 31520 
f 30779 31521 31520 
f 31734 31521 30779 
f 26410 24527 31522 
f 24840 31522 24527 
f 30774 31523 31522 
f 31738 31523 30774 
f 26408 21598 31524 
f 24099 31524 21598 
f 31746 31525 30775 
f 30775 31525 31524 
f 23159 31526 20945 
f 31623 31526 23159 
f 23094 25169 31527 
f 21004 31527 25169 
f 31624 31528 21004 
f 21004 31528 31527 
f 21183 31529 23339 
f 21283 23339 31529 
f 21183 31530 31529 
f 31625 31530 21183 
f 23095 25170 31531 
f 20973 31531 25170 
f 31626 31532 20973 
f 20973 31532 31531 
f 31627 31533 21265 
f 21265 31533 31492 
f 31628 31534 21398 
f 21398 31534 31496 
f 21423 31535 24103 
f 31535 21423 26413 
f 21187 31536 31497 
f 31631 31536 21187 
f 21014 31537 31493 
f 31632 31537 21014 
f 21112 21465 31538 
f 21112 31538 31643 
f 30781 31539 33819 
f 31747 31539 30781 
f 31540 24528 26407 
f 24528 31540 24843 
f 31541 24523 26399 
f 24523 31541 21466 
f 30782 33492 31542 
f 30782 31542 31748 
f 21393 31543 24098 
f 31543 21393 26411 
f 21036 31544 31642 
f 21036 33450 31544 
f 31545 21397 26415 
f 21397 31545 24104 
f 31546 24530 26418 
f 24530 31546 21102 
f 25168 31547 21007 
f 31547 25168 23093 
f 31548 23341 21282 
f 23341 31548 21184 
f 25171 31549 20954 
f 31549 25171 23096 
f 31550 24532 26403 
f 24532 31550 21057 
f 31551 21167 26414 
f 21167 31551 24101 
f 31622 31552 23322 
f 23322 31552 33489 
f 28634 24525 31553 
f 33480 31553 24525 
f 31752 31554 30507 
f 30507 31554 21443 
f 21035 31555 33540 
f 31630 31555 21035 
f 24534 31556 21504 
f 31556 24534 26406 
f 31749 31557 30511 
f 30511 31557 21471 
f 21303 32597 31558 
f 21303 31558 31645 
f 30768 31559 31517 
f 31740 31559 30768 
f 31741 31560 30769 
f 30769 31560 31518 
f 31743 31561 30780 
f 30780 31561 31519 
f 30777 21587 31562 
f 30777 31562 31733 
f 30778 31563 21586 
f 31745 31563 30778 
f 30770 31564 31742 
f 31545 31564 30770 
f 31736 31565 30771 
f 30771 31565 31516 
f 26400 24524 31566 
f 33507 31566 24524 
f 24522 31567 21193 
f 31567 24522 28635 
f 31568 29774 29636 
f 29635 29773 31569 
f 30890 26257 31570 
f 30878 31570 26257 
f 26258 31571 30879 
f 31571 26258 30891 
f 24701 21504 31572 
f 31572 21504 22608 
f 31573 21495 24702 
f 22609 21495 31573 
f 31574 21057 22066 
f 25985 21057 31574 
f 22607 21048 31575 
f 31575 21048 24709 
f 31576 21531 24708 
f 22958 21531 31576 
f 31577 21049 22957 
f 24707 21049 31577 
f 31467 31578 22017 
f 22017 31578 31699 
f 31689 31579 22016 
f 22016 31579 31468 
s 64
f 21404 31580 30270 
f 31581 21190 30263 
f 30266 31582 21442 
f 31583 30260 21200 
s off
f 21513 30261 31584 
f 31585 30262 21513 
f 30950 27976 31586 
f 31586 27976 22516 
f 22515 27977 31587 
f 31587 27977 30949 
f 24710 21556 31588 
f 31588 21556 22605 
f 22606 21520 31589 
f 31589 21520 24711 
f 31590 23035 31169 
f 31168 23034 31591 
f 31592 30785 22688 
f 31675 31592 22688 
f 31593 22689 30784 
f 22689 31593 31676 
f 33547 22686 31594 
f 31677 31594 22686 
f 22687 31595 31678 
f 21286 31595 22687 
f 30186 31596 22636 
f 22636 31596 31682 
f 22637 31597 32521 
f 31681 31597 22637 
f 31598 29880 30921 
f 30920 29881 31599 
f 31715 22159 31600 
f 31600 22159 30037 
f 31601 22156 31716 
f 30036 22156 31601 
f 31714 22157 31602 
f 31602 22157 30039 
f 31603 22565 20930 
f 31711 22565 31603 
f 31604 22656 30035 
f 31712 22656 31604 
f 30042 22158 31605 
f 31605 22158 31710 
f 30038 22657 31606 
f 31606 22657 31713 
f 31607 20933 22564 
f 31607 22564 31709 
f 29870 26980 31608 
f 30913 31608 26980 
f 31609 26979 29871 
f 26979 31609 30912 
f 22652 20971 31610 
f 22652 31610 31618 
f 31619 31611 22651 
f 22651 31611 20970 
f 31612 23097 30461 
f 31612 30461 30316 
f 30317 30460 31613 
f 31613 30460 23098 
f 31614 21338 31041 
f 22706 21338 31614 
f 31615 21339 22705 
f 31040 21339 31615 
f 31616 21169 26377 
f 31616 28588 21169 
f 31617 21058 28593 
f 26376 21058 31617 
f 31618 31044 21032 
f 31618 21032 22652 
f 22651 21033 31619 
f 31619 21033 31047 
f 31620 23167 21226 
f 21089 23167 31620 
f 31621 21227 23168 
f 31621 23168 21118 
f 23322 21094 31622 
f 31622 21094 21292 
f 23159 21186 31623 
f 32679 31623 21186 
f 32681 31624 22558 
f 21004 22558 31624 
f 32599 31625 23088 
f 21183 23088 31625 
f 20973 22559 31626 
f 32683 31626 22559 
f 33561 31627 21401 
f 21265 21401 31627 
f 21398 23087 31628 
f 33660 31628 23087 
f 32346 31629 21115 
f 23160 21115 31629 
f 21035 23331 31630 
f 31630 23331 21289 
f 32695 31631 23089 
f 21187 23089 31631 
f 21014 23178 31632 
f 31632 23178 21284 
f 31633 23332 21476 
f 21302 23332 31633 
f 21107 31634 22577 
f 31634 31641 31490 
f 33763 22557 31635 
f 31635 22557 21007 
f 31636 23086 21184 
f 32701 23086 31636 
f 31637 22560 20954 
f 33503 22560 31637 
f 31638 21340 21455 
f 33564 21340 31638 
f 31639 23084 21452 
f 33515 23084 31639 
f 31640 21185 23162 
f 32543 21185 31640 
f 31641 21107 23161 
f 21107 31641 31634 
f 21290 23330 31642 
f 31642 23330 21036 
f 21291 23329 31643 
f 31643 23329 21112 
f 21285 23179 31644 
f 31644 23179 20960 
f 31645 23333 21303 
f 21477 23333 31645 
f 31646 23085 21188 
f 32691 23085 31646 
f 31647 21284 23164 
f 31632 21284 31647 
f 31648 23509 21287 
f 21115 23509 31648 
f 31649 21289 23163 
f 31630 21289 31649 
f 23323 21477 31650 
f 31650 21477 31645 
f 31651 21290 31642 
f 23166 21290 31651 
f 23165 21285 31652 
f 31652 21285 31644 
f 21288 23508 31653 
f 31653 23508 21107 
f 31633 21476 31654 
f 31654 21476 23324 
f 31622 21292 31655 
f 31655 21292 23325 
f 31656 21291 31643 
f 23326 21291 31656 
f 32029 31657 23080 
f 21318 23080 31657 
f 32030 21456 31658 
f 31658 21456 23148 
f 31659 21457 23147 
f 32034 21457 31659 
f 31660 23141 31717 
f 21360 23141 31660 
f 23146 21458 31661 
f 32033 31661 21458 
f 31662 23140 21361 
f 31718 23140 31662 
f 32031 31663 23081 
f 21459 23081 31663 
f 32032 23079 31664 
f 31664 23079 21460 
f 31665 21226 23328 
f 31620 21226 31665 
f 31621 31666 21227 
f 23327 21227 31666 
f 32035 23082 31667 
f 31667 23082 21119 
f 21084 23083 31668 
f 32036 31668 23083 
f 31702 21317 31669 
f 31669 21317 26008 
f 31670 23066 31704 
f 28579 23066 31670 
f 26007 21408 31671 
f 31671 21408 31701 
f 31703 21039 31672 
f 31672 21039 26006 
f 21078 31673 26266 
f 26266 31673 21568 
f 21569 31674 26265 
f 26265 31674 21079 
f 22688 25335 31675 
f 32033 31675 25335 
f 32034 25334 31676 
f 31676 25334 22689 
f 32031 31677 25332 
f 22686 25332 31677 
f 31678 25333 22687 
f 32032 25333 31678 
f 30745 25326 31679 
f 31679 25326 22632 
f 31680 25327 30744 
f 22633 25327 31680 
f 32029 31681 25331 
f 22637 25331 31681 
f 32030 25330 31682 
f 31682 25330 22636 
f 31683 25325 30747 
f 22629 25325 31683 
f 30749 25324 31684 
f 31684 25324 22628 
f 31685 25322 22626 
f 30748 25322 31685 
f 22627 25323 31686 
f 31686 25323 30746 
f 30761 25328 31687 
f 31687 25328 22630 
f 31688 25329 30760 
f 22631 25329 31688 
f 22016 25068 31689 
f 32037 31689 25068 
f 21260 31690 25318 
f 22622 25318 31690 
f 31691 25317 30753 
f 22620 25317 31691 
f 31692 25316 22621 
f 30754 25316 31692 
f 30764 25314 31693 
f 31693 25314 22618 
f 25320 31694 21261 
f 31694 25320 22624 
f 22623 25319 31695 
f 31695 25319 30756 
f 22619 25315 31696 
f 31696 25315 30765 
f 31697 25312 22617 
f 30762 25312 31697 
f 30757 25321 31698 
f 31698 25321 22625 
f 32038 25069 31699 
f 31699 25069 22017 
f 31700 25313 30763 
f 22616 25313 31700 
f 31701 21193 22207 
f 31701 22207 31671 
f 31669 22647 31702 
f 21077 31702 22647 
f 31672 25600 31703 
f 31703 25600 33507 
f 31704 21453 31670 
f 21067 21453 31704 
f 23328 31705 31665 
f 21182 31705 23328 
f 23327 31706 21086 
f 31666 31706 23327 
f 21118 31707 31621 
f 33830 31707 21118 
f 33464 21089 31708 
f 31620 31708 21089 
f 31709 26894 31607 
f 21110 26894 31709 
f 23337 26893 31710 
f 31710 26893 31605 
f 31711 26896 21111 
f 31603 26896 31711 
f 31712 26899 23343 
f 31604 26899 31712 
f 23338 26900 31713 
f 31713 26900 31606 
f 31602 26898 31714 
f 21328 31714 26898 
f 31600 26895 31715 
f 31715 26895 23344 
f 26897 31716 21330 
f 31716 26897 31601 
f 23090 21469 31717 
f 32037 25068 31717 
f 31718 21468 23091 
f 32038 31718 25069 
f 22532 31719 22719 
f 22719 31719 32027 
f 22718 31720 22534 
f 32028 31720 22718 
f 30924 23506 31721 
f 31721 23506 21522 
f 31722 23507 30925 
f 21521 23507 31722 
f 31723 21065 23110 
f 28562 21065 31723 
f 31724 24374 28561 
f 21472 24374 31724 
f 31725 24445 27002 
f 31725 30188 24445 
f 31726 24446 30189 
f 27003 24446 31726 
f 31727 30187 24444 
f 31727 24444 26991 
f 26992 24443 31728 
f 31728 24443 30190 
f 30229 30840 31729 
f 31729 30840 23174 
f 31730 30841 30230 
f 31730 23173 30841 
f 23682 31011 31731 
f 31731 31011 30776 
f 31732 31007 30783 
f 23675 31007 31732 
f 22086 31004 31733 
f 31733 31004 30777 
f 31734 31010 23677 
f 30779 31010 31734 
f 23683 30997 31735 
f 31735 30997 30767 
f 30771 31001 31736 
f 31736 31001 23679 
f 31737 31006 30773 
f 23685 31006 31737 
f 30774 31003 31738 
f 31738 31003 23680 
f 23686 31012 31739 
f 31739 31012 30772 
f 31740 30998 23687 
f 30768 30998 31740 
f 30769 30999 31741 
f 31741 30999 23681 
f 23676 31000 31742 
f 31742 31000 30770 
f 31743 31016 23684 
f 30780 31016 31743 
f 31744 30996 30766 
f 23688 30996 31744 
f 30778 31005 31745 
f 31745 31005 22085 
f 30775 31002 31746 
f 31746 31002 23678 
f 31747 31009 23674 
f 30781 31009 31747 
f 31748 31008 30782 
f 31748 23673 31008 
f 30964 23669 31749 
f 30511 30964 31749 
f 22068 30959 31750 
f 31750 30959 30509 
f 30963 31751 23670 
f 31751 30963 30508 
f 30960 23667 31752 
f 30507 30960 31752 
f 31753 30962 30506 
f 30962 31753 23668 
f 30510 30961 31754 
f 31754 30961 22067 
f 31755 30958 23662 
f 30406 30958 31755 
f 23663 30957 31756 
f 31756 30957 30407 
f 31757 31154 24365 
f 31371 31154 31757 
f 24366 31155 31758 
f 31758 31155 31370 
f 27003 31759 21341 
f 31726 31759 27003 
f 21343 31760 27002 
f 27002 31760 31725 
f 31650 31761 23323 
f 23323 31761 32529 
f 23324 31762 31654 
f 33393 31762 23324 
f 21471 31763 26991 
f 26991 31763 31727 
f 31656 31764 23326 
f 23326 31764 33810 
f 23164 31765 31647 
f 32684 31765 23164 
f 23165 31766 33539 
f 31652 31766 23165 
f 31653 31767 21288 
f 21288 31767 33450 
f 31651 31768 23166 
f 23166 31768 32597 
f 31728 31769 26992 
f 26992 31769 21382 
f 23325 31770 31655 
f 33809 31770 23325 
f 33540 31771 21287 
f 21287 31771 31648 
f 32601 31772 23163 
f 23163 31772 31649 
f 31556 28640 31773 
f 31773 28640 30454 
f 30449 28639 31774 
f 31774 28639 31519 
f 31775 29082 30442 
f 31550 29082 31775 
f 30439 29081 31776 
f 31776 29081 31517 
f 29083 31777 33492 
f 31777 29083 30444 
f 30445 29084 31778 
f 33819 31778 29084 
f 31779 29085 30446 
f 31540 29085 31779 
f 30443 29086 31780 
f 31780 29086 31522 
f 31781 30137 31072 
f 30967 30137 31781 
f 31073 30136 31782 
f 31782 30136 30968 
f 30093 31783 21553 
f 31783 30093 31068 
f 31058 30089 31784 
f 21551 31784 30089 
f 31069 30092 31785 
f 21552 31785 30092 
f 31786 30088 31059 
f 30088 31786 21550 
f 31071 30098 31787 
f 21544 31787 30098 
f 30099 31788 21545 
f 31788 30099 31070 
f 30971 30107 31789 
f 31789 30107 30465 
f 30464 30106 31790 
f 31790 30106 30970 
f 21064 31791 30233 
f 31048 30233 31791 
f 31792 28003 21286 
f 31049 28003 31792 
f 31793 30090 30992 
f 31053 30090 31793 
f 31794 30091 31052 
f 30993 30091 31794 
f 31795 30097 31061 
f 30986 30097 31795 
f 31796 30096 30987 
f 31060 30096 31796 
f 31797 30101 31062 
f 30982 30101 31797 
f 31798 30100 30983 
f 31063 30100 31798 
f 31799 30103 30979 
f 31056 30103 31799 
f 30978 30102 31800 
f 31800 30102 31057 
f 30111 31801 21549 
f 31801 30111 31066 
f 31064 30109 31802 
f 31802 30109 30980 
f 31067 30110 31803 
f 22994 31803 30110 
f 31804 30108 31065 
f 30981 30108 31804 
f 31051 30095 31805 
f 31805 30095 30976 
f 31806 30094 31050 
f 30977 30094 31806 
f 31807 30105 30988 
f 31055 30105 31807 
f 31808 30104 31054 
f 30989 30104 31808 
f 21551 23013 31809 
f 25240 31809 23013 
f 23016 31810 33439 
f 31810 23016 21553 
f 21552 23015 31811 
f 33498 31811 23015 
f 23014 31812 25241 
f 31812 23014 21550 
f 20991 31813 21218 
f 31813 20991 22993 
f 22992 20951 31814 
f 21219 31814 20951 
f 21544 23019 31815 
f 21201 31815 23019 
f 21202 23020 31816 
f 31816 23020 21545 
f 21219 31817 23017 
f 21546 23017 31817 
f 23018 31818 21218 
f 31818 23018 21543 
f 20964 31819 21273 
f 31819 20964 22983 
f 21272 31820 21009 
f 22984 21009 31820 
f 21541 23021 31821 
f 20989 31821 23021 
f 31822 23022 21542 
f 23022 31822 21214 
f 20989 31823 23028 
f 21548 23028 31823 
f 31824 23029 21547 
f 23029 31824 21214 
f 20992 31825 21245 
f 31825 20992 22981 
f 22982 20974 31826 
f 21244 31826 20974 
f 31074 31827 21551 
f 23013 21551 31827 
f 31828 21550 23014 
f 21550 31828 31075 
f 21244 31829 23023 
f 21539 23023 31829 
f 31830 23024 21540 
f 23024 31830 21245 
f 21538 23025 31831 
f 21272 31831 23025 
f 22994 21536 31832 
f 33547 31832 21536 
f 23026 31833 21273 
f 31833 23026 21537 
f 23027 31834 21286 
f 31834 23027 21549 
f 31835 21553 23016 
f 21553 31835 31076 
f 31077 31836 21552 
f 23015 21552 31836 
f 23025 21538 31837 
f 31837 21538 31802 
f 21536 22994 31838 
f 31094 31838 22994 
f 31804 21537 31839 
f 31839 21537 23026 
f 21549 31840 31097 
f 31840 21549 23027 
f 23023 21539 31841 
f 31841 21539 31807 
f 31842 21540 23024 
f 31808 21540 31842 
f 31088 31843 21544 
f 23019 21544 31843 
f 21545 31844 31089 
f 31844 21545 23020 
f 21009 22984 31845 
f 31845 22984 31793 
f 31794 22983 31846 
f 31846 22983 20964 
f 20974 22982 31847 
f 31847 22982 31805 
f 31806 22981 31848 
f 31848 22981 20992 
f 23017 21546 31849 
f 31849 21546 31796 
f 31850 21543 23018 
f 31795 21543 31850 
f 31851 22992 31790 
f 20951 22992 31851 
f 31789 22993 31852 
f 31852 22993 20991 
f 31853 21542 23022 
f 31797 21542 31853 
f 23021 21541 31854 
f 31854 21541 31798 
f 31855 21548 31799 
f 23028 21548 31855 
f 31800 21547 31856 
f 31856 21547 23029 
f 26419 31857 32028 
f 32026 31857 26419 
f 32027 31858 26420 
f 26420 31858 32025 
f 31074 30135 31859 
f 33547 31859 30135 
f 31860 30134 31075 
f 21286 30134 31860 
f 31861 30139 31076 
f 30139 31861 30784 
f 30785 31862 30138 
f 31077 30138 31862 
f 31096 30158 31863 
f 21470 31863 30158 
f 30186 30155 31864 
f 31864 30155 31097 
f 30157 31865 21470 
f 31865 30157 31095 
f 31094 30156 31866 
f 31866 30156 30514 
f 31867 30150 21569 
f 31088 30150 31867 
f 31868 30149 31089 
f 21568 30149 31868 
f 21272 31869 30152 
f 31090 30152 31869 
f 31870 30151 31091 
f 30151 31870 21273 
f 24965 31871 30147 
f 31086 30147 31871 
f 31872 30148 31087 
f 30148 31872 24965 
f 30144 31873 21245 
f 31873 30144 31085 
f 31084 30145 31874 
f 21244 31874 30145 
f 30159 31875 24966 
f 31875 30159 31083 
f 31082 30146 31876 
f 24966 31876 30146 
f 21219 31877 29795 
f 31099 29795 31877 
f 29796 31878 21218 
f 31878 29796 31098 
f 31080 30153 31879 
f 21517 31879 30153 
f 30154 31880 21517 
f 31880 30154 31079 
f 31092 30161 31881 
f 20989 31881 30161 
f 31882 30160 31093 
f 30160 31882 21214 
f 31883 32590 32041 
f 20948 22697 31883 
f 24690 21570 31884 
f 21570 29816 31884 
f 31885 30036 21082 
f 30036 31885 30352 
f 30041 31886 30357 
f 31886 30041 20938 
f 30355 31887 30039 
f 21080 30039 31887 
f 21013 30037 31888 
f 30354 31888 30037 
f 30358 31889 30040 
f 20937 30040 31889 
f 21134 30035 31890 
f 30353 31890 30035 
f 31891 30042 21081 
f 30042 31891 30359 
f 31892 30038 21135 
f 30038 31892 30356 
f 31049 31893 21064 
f 22525 21064 31893 
f 31894 22525 28002 
f 21064 22525 31894 
f 21237 25988 31895 
f 31175 31895 25988 
f 25989 31896 31174 
f 31896 25989 21236 
f 31897 25987 21123 
f 25987 31897 31176 
f 21122 25986 31898 
f 31177 31898 25986 
f 21079 31899 26265 
f 26398 31899 21079 
f 26266 31900 21078 
f 21078 31900 26397 
f 31901 26393 21263 
f 26393 31901 31196 
f 31194 31902 26392 
f 21262 26392 31902 
f 31903 28523 21369 
f 28523 31903 31195 
f 31197 31904 28522 
f 21368 28522 31904 
f 31905 22719 21485 
f 22532 22719 31905 
f 31906 22718 22534 
f 21486 22718 31906 
f 31999 31907 27961 
f 27960 31908 32000 
f 27962 31909 32002 
f 32001 31910 27963 
f 27965 31911 32003 
f 32004 31912 27964 
f 27966 31913 32006 
f 32005 31914 27967 
f 32008 31915 27968 
f 27969 31916 32007 
f 32010 31917 27970 
f 27971 31918 32009 
f 32011 31919 27972 
f 27973 31920 32012 
f 28063 31921 32014 
f 32013 31922 28062 
f 21506 30864 31923 
f 31923 30864 29684 
f 29685 30863 31924 
f 31924 30863 21507 
f 29693 30867 31925 
f 31925 30867 21535 
f 31926 30868 29692 
f 21534 30868 31926 
f 29694 30866 31927 
f 31927 30866 21524 
f 21523 30865 31928 
f 31928 30865 29695 
f 31929 30874 21516 
f 29690 30874 31929 
f 31930 30873 29691 
f 21515 30873 31930 
f 21525 30872 31931 
f 31931 30872 29697 
f 31932 30871 21526 
f 29696 30871 31932 
f 21526 21108 31933 
f 31934 21109 21525 
f 31935 30869 22393 
f 29688 30869 31935 
f 22392 30870 31936 
f 31936 30870 29689 
f 31937 30876 29687 
f 21019 30876 31937 
f 29686 30875 31938 
f 31938 30875 21022 
f 31939 21021 21506 
f 21507 21024 31940 
f 21516 21093 31941 
f 31942 21092 21515 
f 21533 21156 31943 
f 31944 21157 21532 
f 21022 21166 31945 
f 31946 21165 21019 
f 31947 21020 21523 
f 21524 21023 31948 
f 21555 21029 31949 
f 31950 21028 21554 
f 28622 31951 32015 
f 32016 31952 28621 
f 31435 30850 31953 
f 31954 30851 31434 
f 31438 30860 31955 
f 31956 30859 31439 
f 31428 30849 31957 
f 31958 30852 31431 
f 31433 30856 31959 
f 31960 30855 31432 
f 31437 30857 31961 
f 31962 30858 31436 
f 31963 30853 31429 
f 31430 30854 31964 
f 31441 30862 31965 
f 31966 30861 31440 
f 29773 29635 31967 
f 31967 29635 22516 
f 31968 29636 29774 
f 22515 29636 31968 
f 31969 21127 21534 
f 21535 21125 31970 
f 22706 31971 28632 
f 31614 31971 22706 
f 22705 28631 31972 
f 22705 31972 31615 
f 31973 21525 21109 
f 21525 31973 30889 
f 30888 31974 21526 
f 21108 21526 31974 
f 21515 31975 30883 
f 31975 21515 21092 
f 21093 21516 31976 
f 30882 31976 21516 
f 21532 31977 30880 
f 31977 21532 21157 
f 30881 31978 21533 
f 21156 21533 31978 
f 31979 21523 21020 
f 21523 31979 30885 
f 30884 31980 21524 
f 21023 21524 31980 
f 31981 21554 21028 
f 22392 21554 31981 
f 21029 21555 31982 
f 31982 21555 22393 
f 21024 21507 31983 
f 30892 31983 21507 
f 21506 31984 30893 
f 31984 21506 21021 
f 21019 31985 30887 
f 31985 21019 21165 
f 30886 31986 21022 
f 21166 21022 31986 
f 31987 30879 31571 
f 31570 30878 31988 
f 21125 21535 31989 
f 30890 31989 21535 
f 31990 21534 21127 
f 21534 31990 30891 
f 31991 30921 29880 
f 22515 30921 31991 
f 31992 30920 22516 
f 29881 30920 31992 
f 31993 21128 21332 
f 21331 21126 31994 
f 31995 30912 31609 
f 31608 30913 31996 
f 29870 31997 21331 
f 21126 21331 31997 
f 21332 31998 29871 
f 31998 21332 21128 
f 27961 28054 31999 
f 25006 31999 28054 
f 32000 28055 27960 
f 25005 28055 32000 
f 23941 32001 28056 
f 27963 28056 32001 
f 32002 28057 27962 
f 28057 32002 23940 
f 32003 28059 27965 
f 28059 32003 26389 
f 26388 32004 28058 
f 27964 28058 32004 
f 27967 28060 32005 
f 23942 32005 28060 
f 32006 28061 27966 
f 28061 32006 23939 
f 23277 32007 23511 
f 32007 23277 27969 
f 27968 23276 32008 
f 23512 32008 23276 
f 23279 32009 23516 
f 32009 23279 27971 
f 23517 32010 23278 
f 27970 23278 32010 
f 27972 23281 32011 
f 23513 32011 23281 
f 32012 23280 27973 
f 23280 32012 23510 
f 28062 23354 32013 
f 23008 32013 23354 
f 32014 23353 28063 
f 23353 32014 23007 
f 32015 23519 28622 
f 23519 32015 23514 
f 23515 32016 23518 
f 28621 23518 32016 
f 32017 24356 30353 
f 30035 32017 30353 
f 30359 32018 30042 
f 32018 30359 23189 
f 32019 30352 24347 
f 30352 32019 30036 
f 32020 23188 30354 
f 30037 32020 30354 
f 32021 30356 21352 
f 30356 32021 30038 
f 30039 32022 30355 
f 32022 24354 30355 
f 30040 32023 30358 
f 32023 24355 30358 
f 30357 32024 30041 
f 32024 30357 24350 
f 21286 30243 32025 
f 32025 30243 26420 
f 26419 30242 32026 
f 33547 32026 30242 
f 32027 21485 22719 
f 31781 21485 32027 
f 22718 21486 32028 
f 32028 21486 31782 
f 31475 32029 23145 
f 32029 23080 23145 
f 23142 32030 31476 
f 32030 23142 21456 
f 31478 32031 21435 
f 32031 23081 21435 
f 21436 32032 31477 
f 32032 21436 23079 
f 31480 32033 23144 
f 32033 21458 23144 
f 23143 32034 31479 
f 32034 23143 21457 
f 21349 32035 31484 
f 32035 21349 23082 
f 31483 32036 21350 
f 32036 23083 21350 
f 31486 32037 21469 
f 32037 31717 21469 
f 21468 32038 31485 
f 32038 21468 31718 
f 32039 32319 32284 
f 21016 32319 32039 
f 32040 32290 21015 
f 32316 32290 32040 
f 21783 29622 32041 
f 32521 32041 29622 
s 516
f 29517 24884 32042 
s 1026
f 32154 32042 24884 
s 544
f 24075 32043 32100 
s 1040
f 32043 24075 28034 
s off
f 32044 21136 21252 
f 21136 32044 23347 
s 516
f 32045 21643 29515 
s 1026
f 21643 32045 33207 
s 516
f 29516 25251 32046 
s 1026
f 32718 32046 25251 
s 544
f 32050 32047 24770 
s 1040
f 26855 24770 32047 
s 1026
f 28758 23953 32048 
s 516
f 32056 32048 23953 
s 1026
f 28463 26977 32049 
s 516
f 32275 32049 26977 
s 1026
f 28490 22243 32050 
s 516
f 32047 32050 22243 
s 544
f 32051 26015 23055 
s 1040
f 26015 32051 32107 
f 28065 23116 32052 
s 544
f 32052 23116 26218 
f 32053 24460 26267 
s 1040
f 24460 32053 32273 
s 544
f 32054 25904 21620 
s 1040
f 25904 32054 32052 
f 33392 32055 24860 
s 544
f 28633 24860 32055 
f 32048 32056 23193 
s 264
f 27499 23193 32056 
s 1040
f 32057 26912 25897 
s 544
f 21614 26912 32057 
s 1040
f 33431 32058 24569 
s 544
f 26321 24569 32058 
s 1040
f 21986 32059 33053 
s 544
f 32059 21986 26293 
s 1040
f 24094 32060 33741 
s 544
f 32060 24094 26292 
s 516
f 25028 32061 32127 
s 1026
f 32061 25028 27485 
s 1040
f 33544 32062 24673 
s 544
f 28161 24673 32062 
s 1026
f 27047 22040 32063 
s 516
f 32266 32063 22040 
s 1026
f 26150 24518 32064 
s 516
f 32072 32064 24518 
s 1026
f 28160 26976 32065 
s 516
f 32817 32065 26976 
s 1026
f 32066 21953 27050 
s 516
f 21953 32066 32251 
s 1026
f 29299 22280 32067 
s 516
f 32246 32067 22280 
f 26978 32068 33025 
s 1026
f 32068 26978 28462 
s 544
f 32075 32069 22359 
s 1040
f 27899 22359 32069 
s 544
f 32076 32070 22245 
s 1040
f 28734 22245 32070 
s 544
f 32144 32071 23202 
s 264
f 27492 23202 32071 
s 544
f 32064 32072 22041 
s 1040
f 25412 22041 32072 
s 544
f 21954 32073 32098 
s 1040
f 32073 21954 25411 
s 1026
f 32074 22145 28458 
s 516
f 22145 32074 33099 
s 1026
f 27487 25037 32075 
s 516
f 32069 32075 25037 
s 1026
f 27913 24756 32076 
s 516
f 32070 32076 24756 
f 23372 32077 32093 
s 257
f 32077 23372 26882 
s 1040
f 27515 24674 32078 
s 544
f 32113 32078 24674 
s 1026
f 29302 22296 32079 
s 516
f 32191 32079 22296 
f 24867 32080 32103 
s 1026
f 32080 24867 28157 
s 516
f 23226 27047 32081 
s 1026
f 32063 32081 27047 
s 516
f 22121 26148 32082 
s 1026
f 32797 32082 26148 
s 544
f 33787 32083 26356 
s 1040
f 28652 26356 32083 
s 1026
f 32084 22361 27902 
s 516
f 22361 32084 32234 
s 544
f 23199 32085 32186 
s 264
f 32085 23199 27494 
s 516
f 33794 32086 22584 
s 1026
f 28038 22584 32086 
f 27048 22211 32087 
s 516
f 32166 32087 22211 
f 32202 32088 22343 
s 1026
f 27904 22343 32088 
s 516
f 32237 32089 24903 
s 1026
f 28487 24903 32089 
f 26149 24516 32090 
s 516
f 32160 32090 24516 
f 32241 32091 23216 
s 257
f 27493 23216 32091 
s 516
f 32260 32092 21763 
s 1026
f 28642 21763 32092 
s 544
f 24028 32093 32077 
s 1040
f 32093 24028 29288 
s 544
f 22357 32094 32188 
s 1040
f 32094 22357 27903 
s 1026
f 26146 32095 32983 
s 516
f 32095 26146 24455 
s 544
f 24900 32096 32189 
s 1040
f 32096 24900 28486 
s 516
f 32097 27050 24660 
s 1026
f 27050 32097 32066 
f 32098 24084 26147 
s 516
f 24084 32098 32073 
s 1026
f 32099 22289 29301 
s 516
f 22289 32099 32194 
s 1040
f 26191 32100 33287 
s 544
f 32100 26191 24075 
s 516
f 24085 32101 32263 
s 1026
f 32101 24085 28641 
f 27490 25029 32102 
s 516
f 32131 32102 25029 
s 1040
f 32103 22205 29538 
s 544
f 22205 32103 32080 
s 1040
f 32104 24671 27518 
s 544
f 24671 32104 32115 
s 1040
f 29508 23469 32105 
s 544
f 32167 32105 23469 
s off
f 33817 20921 20920 
s 1040
f 32107 24442 26015 
s 544
f 24442 32107 32271 
s 1026
f 27917 24745 32108 
s 516
f 32247 32108 24745 
s 1026
f 32109 22293 29292 
s 516
f 22293 32109 32252 
s 257
f 26879 23362 32110 
s 516
f 32225 32110 23362 
f 32198 32111 24863 
s 1026
f 28156 24863 32111 
s 257
f 32112 23363 26883 
s 516
f 23363 32112 32175 
f 32078 32113 23321 
s 1026
f 25548 23321 32113 
s 257
f 26866 23368 32114 
s 516
f 32182 32114 23368 
f 22204 32115 32104 
s 1026
f 32115 22204 25398 
f 32116 22349 27901 
s 516
f 22349 32116 32230 
s 1040
f 27892 22345 32117 
s 544
f 32172 32117 22345 
s 516
f 24029 32118 32236 
s 1026
f 32118 24029 29290 
s 516
f 24781 32119 32199 
s 1026
f 32119 24781 26852 
s 516
f 24910 32120 32200 
s 1026
f 32120 24910 28477 
s 516
f 22304 32121 32135 
s 1026
f 32121 22304 28749 
s 257
f 32122 23204 27491 
s 516
f 23204 32122 32136 
f 32207 32123 24795 
s 1026
f 26861 24795 32123 
s 516
f 32204 32124 24776 
s 1026
f 26859 24776 32124 
s 1040
f 32125 24772 26854 
s 544
f 24772 32125 32227 
f 23194 32126 32193 
s 264
f 32126 23194 27502 
s 544
f 22355 32127 32061 
s 1040
f 32127 22355 27895 
s 544
f 22351 32128 32180 
s 1040
f 32128 22351 27896 
s 1026
f 32129 24746 27908 
s 516
f 24746 32129 32243 
f 23367 32130 32173 
s 257
f 32130 23367 26880 
s 544
f 32102 32131 22353 
s 1040
f 27897 22353 32131 
s 257
f 26863 23371 32132 
s 516
f 32177 32132 23371 
s 1040
f 28078 22519 32133 
s 544
f 33339 32133 22519 
s 1040
f 32134 23954 28765 
s 544
f 23954 32134 32221 
s 1040
f 32135 24751 27906 
s 544
f 24751 32135 32121 
s 1040
f 32136 23960 28761 
s 544
f 23960 32136 32122 
s 516
f 29528 21879 32137 
s 1026
f 33780 32137 21879 
f 32138 22291 28493 
s 516
f 22291 32138 32179 
s 1040
f 32790 32139 22119 
s 544
f 29277 22119 32139 
s 516
f 32232 32140 24021 
s 1026
f 29281 24021 32140 
s 516
f 33333 32141 21789 
s 1026
f 27591 21789 32141 
f 28494 22275 32142 
s 516
f 32257 32142 22275 
s 544
f 24027 32143 32165 
s 1040
f 32143 24027 29282 
s 1026
f 28769 23956 32144 
s 516
f 32071 32144 23956 
s 544
f 22341 32145 32217 
s 1040
f 32145 22341 27890 
f 32146 24906 28481 
s 544
f 24906 32146 32219 
s 1026
f 32147 22406 27555 
s 516
f 22406 32147 32594 
f 32206 32148 22339 
s 1026
f 27900 22339 32148 
s 264
f 32149 23203 27501 
s 544
f 23203 32149 32161 
s 1040
f 32150 24780 26862 
s 544
f 24780 32150 32184 
s 516
f 32239 32151 24914 
s 1026
f 28476 24914 32151 
s 516
f 24902 32152 32197 
s 1026
f 32152 24902 28485 
s 544
f 32256 32153 24022 
s 1040
f 29287 24022 32153 
s 516
f 32082 32154 22121 
s 1026
f 24884 22121 32154 
f 28224 21666 32155 
s 516
f 33791 32155 21666 
s 1026
f 32156 24748 27914 
s 516
f 24748 32156 32265 
s 1026
f 32157 22271 28497 
s 516
f 22271 32157 32244 
f 32240 32158 22253 
s 1026
f 28755 22253 32158 
s 544
f 32178 32159 24779 
s 1040
f 26853 24779 32159 
s 544
f 32090 32160 22213 
s 1040
f 25341 22213 32160 
s 516
f 23955 32161 32149 
s 1026
f 32161 23955 28768 
f 32162 22098 27049 
s 516
f 22098 32162 32176 
f 32163 24916 28155 
s 1026
f 24916 32163 32249 
s 544
f 32185 32164 23200 
s 264
f 27500 23200 32164 
s 257
f 32165 23370 26885 
s 516
f 23370 32165 32143 
f 22211 26149 32166 
s 1026
f 32090 32166 26149 
s 516
f 32105 32167 25002 
s 1026
f 28159 25002 32167 
s 516
f 32203 32168 22269 
s 1026
f 28756 22269 32168 
s 516
f 32238 32169 23201 
s 257
f 27497 23201 32169 
s 1026
f 32170 24455 29605 
s 516
f 24455 32170 32095 
s 544
f 22250 32171 32190 
s 1040
f 32171 22250 28754 
s 516
f 32117 32172 25035 
s 1026
f 27478 25035 32172 
s 544
f 24026 32173 32130 
s 1040
f 32173 24026 29284 
s 516
f 32174 22144 26291 
s 1026
f 22144 32174 33050 
s 544
f 24031 32175 32112 
s 1040
f 32175 24031 29285 
s 544
f 24913 32176 32162 
s 1040
f 32176 24913 28483 
s 544
f 32132 32177 24032 
s 1040
f 29279 24032 32177 
s 1026
f 28501 22279 32178 
s 516
f 32159 32178 22279 
s 544
f 24774 32179 32138 
s 1040
f 32179 24774 26857 
s 1026
f 32180 25033 27488 
s 516
f 25033 32180 32128 
s 544
f 32228 32181 24869 
s 1040
f 28473 24869 32181 
s 544
f 32114 32182 24025 
s 1040
f 29280 24025 32182 
s 544
f 32209 32183 22347 
s 1040
f 27898 22347 32183 
s 1026
f 32184 22287 28500 
s 516
f 22287 32184 32150 
s 1026
f 28767 23958 32185 
s 516
f 32164 32185 23958 
s 1026
f 32186 23957 28766 
s 516
f 23957 32186 32085 
s 544
f 32211 32187 24904 
s 1040
f 28482 24904 32187 
s 1026
f 32188 25036 27467 
s 516
f 25036 32188 32094 
s 1026
f 32189 22249 29300 
s 516
f 22249 32189 32096 
s 1026
f 32190 24755 27910 
s 516
f 24755 32190 32171 
s 544
f 32079 32191 24908 
s 1040
f 28484 24908 32191 
s 544
f 32242 32192 24773 
s 1040
f 26856 24773 32192 
s 1026
f 32193 23961 28762 
s 516
f 23961 32193 32126 
s 544
f 24905 32194 32099 
s 1040
f 32194 24905 28474 
s 1026
f 32195 21669 27556 
s 516
f 21669 32195 33823 
f 24866 32196 32262 
s 1026
f 32196 24866 28158 
s 1040
f 32197 22285 29295 
s 544
f 22285 32197 32152 
s 1040
f 29507 21791 32198 
s 544
f 32111 32198 21791 
s 1040
f 32199 22283 28492 
s 544
f 22283 32199 32119 
s 1040
f 32200 22305 29294 
s 544
f 22305 32200 32120 
s 1040
f 28080 21762 32201 
s 544
f 32931 32201 21762 
s 1040
f 27484 25034 32202 
s 544
f 32088 32202 25034 
s 1040
f 27907 24747 32203 
s 544
f 32168 32203 24747 
s 1040
f 28495 22267 32204 
s 544
f 32124 32204 22267 
s 264
f 26867 23369 32205 
s 544
f 32223 32205 23369 
s 1040
f 27470 25057 32206 
s 544
f 32148 32206 25057 
s 1040
f 28491 22251 32207 
s 544
f 32123 32207 22251 
f 21670 32208 33801 
s 1040
f 32208 21670 28079 
s 516
f 32183 32209 25032 
s 1026
f 27489 25032 32209 
f 27911 24753 32210 
s 516
f 32226 32210 24753 
s 1026
f 29297 22276 32211 
s 516
f 32187 32211 22276 
s 1026
f 28760 23963 32212 
s 516
f 32229 32212 23963 
s 1026
f 32213 24752 27916 
s 516
f 24752 32213 32255 
s 1026
f 27909 24750 32214 
s 516
f 32264 32214 24750 
s 1026
f 32215 23962 28759 
s 516
f 23962 32215 32224 
f 24024 32216 32259 
s 1026
f 32216 24024 29286 
f 32217 25031 27486 
s 516
f 25031 32217 32145 
f 22284 32218 32231 
s 1026
f 32218 22284 28757 
s 516
f 22273 32219 32146 
s 1026
f 32219 22273 29296 
s 516
f 24775 32220 32235 
s 1026
f 32220 24775 26860 
s 516
f 23198 32221 32134 
s 257
f 32221 23198 27498 
s 516
f 24757 32222 32254 
s 1026
f 32222 24757 27875 
s 516
f 32205 32223 24023 
s 1026
f 29289 24023 32223 
s 264
f 32224 23195 27495 
s 544
f 23195 32224 32215 
f 32110 32225 24030 
s 1040
f 29283 24030 32225 
s 544
f 32210 32226 22297 
s 1040
f 28750 22297 32226 
s 1026
f 32227 22247 28496 
s 516
f 22247 32227 32125 
s 1026
f 29291 22244 32228 
s 516
f 32181 32228 22244 
s 544
f 32212 32229 23196 
s 264
f 27496 23196 32229 
s 544
f 25030 32230 32116 
s 1040
f 32230 25030 27477 
f 32231 24749 27915 
s 544
f 24749 32231 32218 
s 264
f 26871 23374 32232 
s 544
f 32140 32232 23374 
f 22203 32233 33350 
s 1040
f 32233 22203 28081 
s 544
f 25027 32234 32084 
s 1040
f 32234 25027 27474 
f 32235 22303 28499 
s 544
f 22303 32235 32220 
s 264
f 32236 23364 26886 
s 544
f 23364 32236 32118 
f 32089 32237 22268 
s 1040
f 29298 22268 32237 
f 28764 23959 32238 
s 544
f 32169 32238 23959 
f 32151 32239 22252 
s 1040
f 29293 22252 32239 
f 27912 24759 32240 
s 544
f 32158 32240 24759 
s 1040
f 28763 23970 32241 
s 544
f 32091 32241 23970 
s 1026
f 28498 22295 32242 
s 516
f 32192 32242 22295 
s 544
f 22290 32243 32129 
s 1040
f 32243 22290 28753 
s 544
f 24778 32244 32157 
s 1040
f 32244 24778 26858 
s 516
f 26289 24089 32245 
s 1026
f 33777 32245 24089 
s 544
f 32067 32246 24907 
s 1040
f 28478 24907 32246 
s 544
f 32108 32247 22281 
s 1040
f 28752 22281 32247 
s 516
f 32248 27049 24846 
s 1026
f 27049 32248 32162 
f 32249 21687 24916 
s 516
f 21687 32249 33782 
f 23227 27048 32250 
s 1026
f 32087 32250 27048 
s 516
f 32251 26147 21953 
s 1026
f 26147 32251 32098 
s 544
f 24909 32252 32109 
s 1040
f 32252 24909 28479 
f 33024 32253 24699 
s 544
f 29278 24699 32253 
s 1040
f 32254 24086 28654 
s 544
f 24086 32254 32222 
f 22292 32255 32213 
s 1040
f 32255 22292 28751 
s 257
f 26884 23366 32256 
s 516
f 32153 32256 23366 
s 544
f 32142 32257 24777 
s 1040
f 26851 24777 32257 
f 21642 32258 33090 
s 544
f 32258 21642 29276 
s 264
f 32259 23365 26881 
s 544
f 23365 32259 32216 
s 1040
f 27516 24730 32260 
s 544
f 32092 32260 24730 
s 1040
f 21812 32261 32957 
s 544
f 32273 32261 21812 
s 1040
f 32262 23401 29537 
s 544
f 23401 32262 32196 
s 1040
f 32263 24729 27517 
s 544
f 24729 32263 32101 
f 32214 32264 22277 
s 1040
f 28748 22277 32264 
s 544
f 22272 32265 32156 
s 1040
f 32265 22272 28746 
s 516
f 22040 26150 32266 
s 1026
f 32064 32266 26150 
f 21831 32267 33793 
s 516
f 32267 21831 29202 
s off
f 20814 20813 33822 
f 32269 21252 32274 
f 21252 32269 32044 
f 32272 32528 32270 
f 32334 32270 21251 
s 544
f 32271 27034 24442 
s 1040
f 27034 32271 33768 
s off
f 32272 21437 32269 
s 1040
f 32273 26290 24460 
s 544
f 21812 26290 32273 
s off
f 32274 21251 32270 
s 516
f 26977 22210 32275 
s 1026
f 32934 32275 22210 
s 1
f 20942 32276 31177 
s off
f 30229 32276 20942 
f 32277 32682 32671 
s 8
f 21475 32682 32277 
s off
f 33342 32687 32278 
s 8
f 21474 32278 32687 
s 1040
f 22147 32279 33071 
s 544
f 32279 22147 28950 
s 1040
f 33134 32280 21885 
s 544
f 27870 21885 32280 
f 28455 21858 32281 
s 1040
f 32967 32281 21858 
s off
f 23347 32282 21136 
s 1040
f 33081 32283 24515 
s 544
f 28454 24515 32283 
s off
f 33094 32284 32319 
f 33445 32284 33094 
s 1040
f 33531 32285 22483 
s 544
f 28949 22483 32285 
s 264
f 32318 32286 23406 
s 544
f 27068 23406 32286 
s 1040
f 22390 32287 33410 
s 544
f 32287 22390 29070 
s 516
f 28643 21883 32288 
s 1026
f 32293 32288 21883 
s 544
f 27298 24369 32289 
s 1040
f 32685 32289 24369 
s off
f 32290 26989 32106 
f 33003 26989 32290 
s 516
f 29171 21861 32291 
s 1026
f 33085 32291 21861 
s 1040
f 33510 32292 21827 
s 544
f 27869 21827 32292 
s 1040
f 32288 32293 23952 
s 544
f 26845 23952 32293 
f 26292 32294 32060 
s 1040
f 32294 26292 24687 
f 32317 32295 24062 
s 544
f 26526 24062 32295 
s 1040
f 24068 32296 32310 
s 544
f 32296 24068 26844 
s 264
f 23760 32297 33625 
s 544
f 32297 23760 27067 
s 1040
f 32887 32298 24698 
s 544
f 29183 24698 32298 
f 28948 22383 32299 
s 1040
f 33176 32299 22383 
s 516
f 32300 21916 27442 
s 1026
f 21916 32300 33630 
s 264
f 32326 32301 23407 
s 544
f 27128 23407 32301 
s 516
f 28153 21860 32302 
s 1026
f 33067 32302 21860 
s 516
f 27363 24368 32303 
s 1026
f 33125 32303 24368 
f 24581 32304 33137 
s 516
f 32304 24581 28789 
s 1026
f 32313 32305 24060 
s 516
f 26440 24060 32305 
s 257
f 32330 32306 23405 
s 516
f 27127 23405 32306 
f 28051 25001 32307 
s 1026
f 33216 32307 25001 
s off
f 30230 20949 32308 
s 1
f 20949 31176 32308 
s 516
f 32309 24868 27574 
s 1026
f 24868 32309 33407 
s 516
f 32310 21833 28771 
s 1026
f 21833 32310 32296 
s 516
f 32311 24577 26520 
s 257
f 24577 32311 32323 
s 1026
f 24803 32312 33471 
s 516
f 32312 24803 28124 
s 544
f 28674 21821 32313 
s 1040
f 32305 32313 21821 
s 516
f 27507 24371 32314 
s 1026
f 33504 32314 24371 
s 516
f 28050 25259 32315 
s 1026
f 33528 32315 25259 
s off
f 33518 33003 32316 
f 32290 32316 33003 
s 516
f 28788 21825 32317 
s 1026
f 32295 32317 21825 
s 516
f 26519 24572 32318 
s 257
f 32286 32318 24572 
s off
f 32268 26990 32319 
f 32319 26990 33094 
s 1040
f 21834 32320 33404 
s 544
f 32320 21834 27711 
s 1040
f 24430 32321 33470 
s 544
f 32321 24430 28452 
s 516
f 29589 25255 32322 
s 1026
f 32659 32322 25255 
s 264
f 23388 32323 32311 
s 544
f 32323 23388 27126 
s 1026
f 33473 32324 21824 
s 516
f 27696 21824 32324 
s 1026
f 33479 32325 22482 
s 516
f 28704 22482 32325 
f 26518 24573 32326 
s 257
f 32301 32326 24573 
s 1040
f 33197 32327 23233 
s 544
f 29487 23233 32327 
s 1026
f 24041 32328 33553 
s 516
f 32328 24041 27446 
s 544
f 28035 25264 32329 
s 1040
f 33477 32329 25264 
s 544
f 26517 24570 32330 
s 264
f 32306 32330 24570 
s 516
f 28152 24769 32331 
s 1026
f 32736 32331 24769 
s 544
f 32058 32332 26321 
s 1040
f 21607 26321 32332 
f 32333 26293 24685 
s 544
f 26293 32333 32059 
s off
f 21251 21162 32334 
f 23347 32334 21162 
s 516
f 29626 24695 32335 
s 1026
f 33680 32335 24695 
s 516
f 27441 24817 32336 
s 1026
f 32440 32336 24817 
s 516
f 32338 32337 22039 
s 1026
f 24436 22039 32337 
s 516
f 22039 25918 32338 
s 257
f 32433 32338 25918 
s 516
f 24594 32339 32373 
s 1026
f 32339 24594 21774 
s 516
f 32340 23073 26070 
s 1026
f 23073 32340 32974 
s 516
f 29588 22379 32341 
s 1026
f 32567 32341 22379 
s 257
f 32342 26070 25973 
s 516
f 26070 32342 32340 
s 544
f 21715 32343 32544 
s 1040
f 32343 21715 25062 
s 544
f 27440 24044 32344 
s 1040
f 32366 32344 24044 
s 544
f 27590 24898 32345 
s 1040
f 32367 32345 24898 
s off
f 21264 26400 32346 
f 31566 32346 26400 
s 544
f 26042 23666 32347 
s 1040
f 32723 32347 23666 
f 33698 32348 23396 
s 544
f 29253 23396 32348 
s 516
f 32349 23209 26516 
s 257
f 23209 32349 32369 
s 1040
f 25062 32350 32343 
s 544
f 32350 25062 29536 
s 516
f 32351 24786 29020 
s 1026
f 24786 32351 32374 
s 1040
f 21717 32352 32359 
s 544
f 32352 21717 28980 
s 516
f 29337 22369 32353 
s 1026
f 32470 32353 22369 
s 516
f 27559 24887 32354 
s 1026
f 32377 32354 24887 
s 1040
f 25038 32355 32432 
s 544
f 32355 25038 29181 
s 1026
f 32516 32356 24310 
s 516
f 27865 24310 32356 
s 1040
f 32460 32357 25039 
s 544
f 29182 25039 32357 
f 32358 22486 26842 
s 1040
f 22486 32358 32365 
s 516
f 32359 21718 28151 
s 1026
f 21718 32359 32352 
s 1040
f 32370 32360 24061 
s 544
f 26841 24061 32360 
s 264
f 32478 32361 23373 
s 544
f 27073 23373 32361 
s 516
f 32362 22332 29587 
s 1026
f 22332 32362 32418 
s 516
f 32363 22365 29336 
s 1026
f 22365 32363 32541 
f 24889 32364 32542 
s 516
f 32364 24889 27589 
s 1026
f 24592 32365 32358 
s 516
f 32365 24592 26959 
s 1026
f 32344 32366 25050 
s 516
f 29180 25050 32366 
s 1026
f 32345 32367 24728 
s 516
f 28190 24728 32367 
f 32368 22367 29335 
s 1026
f 22367 32368 32441 
s 544
f 32369 23384 27125 
s 264
f 23384 32369 32349 
s 516
f 29019 21837 32370 
s 1026
f 32360 32370 21837 
s 544
f 32493 32371 21719 
s 1040
f 25063 21719 32371 
s 516
f 32372 24042 27274 
s 1026
f 24042 32372 32455 
f 27004 32373 32522 
s 516
f 32373 27004 24594 
s 1040
f 23949 32374 32351 
s 544
f 32374 23949 26840 
s 1040
f 32500 32375 23950 
s 544
f 26631 23950 32375 
s 516
f 32376 22300 28150 
s 1026
f 22300 32376 32531 
s 1040
f 32354 32377 24741 
s 544
f 28447 24741 32377 
s 1026
f 22375 32378 32458 
s 516
f 32378 22375 29586 
s 1040
f 32426 32379 21720 
s 544
f 28979 21720 32379 
s 1040
f 23966 32380 32511 
s 544
f 32380 23966 26839 
s 1026
f 32381 21951 24437 
s 516
f 21951 32381 32499 
s 1026
f 22329 32382 32527 
s 516
f 32382 22329 27631 
s 1026
f 24791 21740 32383 
s 516
f 32539 32383 21740 
s 1026
f 24744 32384 32595 
s 516
f 32384 24744 28326 
s 1026
f 22327 32385 32600 
s 516
f 32385 22327 28978 
s 544
f 28149 22262 32386 
s 1040
f 32449 32386 22262 
s 264
f 24576 32387 32526 
s 544
f 32387 24576 26515 
f 32388 24798 28973 
s 1040
f 24798 32388 32501 
s 544
f 32389 24048 27435 
s 1040
f 24048 32389 32498 
s 516
f 27436 24037 32390 
s 1026
f 32464 32390 24037 
s 516
f 29585 25254 32391 
s 1026
f 32721 32391 25254 
s 516
f 28787 24788 32392 
s 1026
f 32401 32392 24788 
s 516
f 32393 24034 27434 
s 1026
f 24034 32393 32540 
s 1040
f 23968 32394 32398 
s 544
f 32394 23968 26838 
s 257
f 23210 32395 32410 
s 516
f 32395 23210 26511 
s 1026
f 24891 32396 32443 
s 516
f 32396 24891 27588 
f 29527 21728 32397 
s 1026
f 33685 32397 21728 
s 516
f 32398 24789 28770 
s 1026
f 24789 32398 32394 
s 1040
f 22307 32399 32538 
s 544
f 32399 22307 28945 
f 27124 23380 32400 
s 264
f 32539 32400 23380 
s 1040
f 32392 32401 23967 
s 544
f 26794 23967 32401 
f 27117 23381 32402 
s 264
f 32554 32402 23381 
s 516
f 32403 23211 26482 
s 257
f 23211 32403 32562 
s 516
f 32404 22363 29334 
s 1026
f 22363 32404 32533 
s 516
f 27567 24894 32405 
s 1026
f 32472 32405 24894 
s 264
f 23382 32406 32408 
s 544
f 32406 23382 27123 
s 516
f 28786 24791 32407 
s 1026
f 32383 32407 24791 
s 516
f 32408 23206 26514 
s 257
f 23206 32408 32406 
s 1040
f 24670 32409 33232 
s 544
f 32409 24670 28445 
f 32410 23383 27090 
s 264
f 23383 32410 32395 
s 1040
f 23400 32411 32550 
s 544
f 32411 23400 27863 
s 516
f 29526 22546 32412 
s 1026
f 32484 32412 22546 
s 1040
f 24742 32413 32428 
s 544
f 32413 24742 28444 
f 32414 23973 26837 
s 1040
f 23973 32414 32431 
s 516
f 32415 22530 29333 
s 1026
f 22530 32415 33225 
s 516
f 32416 21787 28148 
s 1026
f 21787 32416 33255 
s 516
f 32417 24575 26509 
s 257
f 24575 32417 32545 
s 1040
f 22333 32418 32362 
s 544
f 32418 22333 27862 
s 1040
f 24314 32419 32556 
s 544
f 32419 24314 28443 
s 516
f 27587 24892 32420 
s 1026
f 32476 32420 24892 
s 544
f 32421 26026 22141 
s 264
f 26026 32421 32423 
s 1026
f 32593 32422 24743 
s 516
f 28442 24743 32422 
s 544
f 23183 32423 33227 
s 264
f 32423 23183 26026 
s 1040
f 32462 32424 21840 
s 544
f 27860 21840 32424 
s 516
f 28147 22321 32425 
s 1026
f 32479 32425 22321 
s 516
f 28146 21721 32426 
s 1026
f 32379 32426 21721 
f 33328 32427 22063 
s 516
f 28977 22063 32427 
f 32428 24885 27586 
s 1026
f 24885 32428 32413 
s 544
f 28651 22388 32429 
s 1040
f 32469 32429 22388 
f 32749 32430 24672 
s 544
f 28440 24672 32430 
s 1026
f 24796 32431 32414 
s 516
f 32431 24796 28972 
s 1026
f 24046 32432 32355 
s 516
f 32432 24046 27411 
s 264
f 32338 32433 24010 
s 544
f 27122 24010 32433 
f 32434 22315 28943 
s 1040
f 22315 32434 32510 
s 516
f 28971 24797 32435 
s 1026
f 32573 32435 24797 
s 1040
f 22317 32436 32506 
s 544
f 32436 22317 27746 
s 516
f 32437 22334 28145 
s 1026
f 22334 32437 32438 
s 516
f 26959 32438 32365 
s 1026
f 32438 26959 22334 
s 1040
f 25043 32439 32454 
s 544
f 32439 25043 29485 
s 1026
f 24817 23226 32440 
s 516
f 32081 32440 23226 
s 1040
f 22325 32441 32368 
s 544
f 32441 22325 27646 
s 516
f 32442 22314 28114 
s 1026
f 22314 32442 32591 
s 1040
f 24736 32443 32396 
s 544
f 32443 24736 28229 
s 516
f 26512 23218 32444 
s 257
f 32493 32444 23218 
s 1040
f 22323 32445 32459 
s 544
f 32445 22323 28942 
s 516
f 32446 24792 28785 
s 1026
f 24792 32446 32592 
f 32596 32447 22260 
s 516
f 27677 22260 32447 
s 544
f 32448 23379 27121 
s 264
f 23379 32448 32463 
s 516
f 28650 22259 32449 
s 1026
f 32386 32449 22259 
s 257
f 26816 26063 32450 
s 516
f 32790 32450 26063 
s 1026
f 32520 32451 23948 
s 516
f 26817 23948 32451 
s 257
f 32598 32452 23375 
s 516
f 27120 23375 32452 
s 1026
f 32453 25060 21685 
s 516
f 25060 32453 32753 
f 32454 24040 27227 
s 1026
f 24040 32454 32439 
s 1040
f 25047 32455 32372 
s 544
f 32455 25047 29179 
s 1040
f 24727 32456 32482 
s 544
f 32456 24727 28437 
s 1040
f 24733 32457 32508 
s 544
f 32457 24733 28438 
f 32458 22265 27732 
s 1040
f 22265 32458 32378 
s 516
f 32459 22326 28144 
s 1026
f 22326 32459 32445 
s 516
f 27303 24045 32460 
s 1026
f 32357 32460 24045 
s 1040
f 24725 32461 32495 
s 544
f 32461 24725 28436 
s 516
f 29584 21838 32462 
s 1026
f 32424 32462 21838 
s 516
f 32463 23207 26472 
s 257
f 23207 32463 32448 
s 1040
f 32390 32464 25041 
s 544
f 29483 25041 32464 
s 516
f 27585 24911 32465 
s 1026
f 32467 32465 24911 
s 544
f 32466 24732 28435 
s 1040
f 24732 32466 32494 
s 1026
f 24911 23552 32467 
s 516
f 32469 32467 23552 
s 1040
f 32537 32468 21742 
s 544
f 27857 21742 32468 
s 516
f 23552 26353 32469 
s 1026
f 32429 32469 26353 
s 1040
f 32353 32470 22320 
s 544
f 27750 22320 32470 
s 1040
f 22263 32471 32507 
s 544
f 32471 22263 28644 
s 1040
f 32405 32472 24735 
s 544
f 28347 24735 32472 
s 1040
f 23964 32473 32496 
s 544
f 32473 23964 26836 
s 1040
f 32497 32474 22548 
s 544
f 28941 22548 32474 
s 1040
f 32490 32475 25048 
s 544
f 29178 25048 32475 
s 1040
f 32420 32476 24734 
s 544
f 28433 24734 32476 
f 28432 24726 32477 
s 1040
f 32576 32477 24726 
s 516
f 26484 24578 32478 
s 257
f 32361 32478 24578 
s 1040
f 32425 32479 22319 
s 544
f 28940 22319 32479 
s 1040
f 32578 32480 22255 
s 544
f 28646 22255 32480 
s 1040
f 32371 32481 25063 
s 544
f 29535 25063 32481 
s 516
f 32482 24899 27569 
s 1026
f 24899 32482 32456 
f 21774 32483 32339 
s 516
f 32483 21774 27766 
s 1040
f 32412 32484 23399 
s 544
f 27855 23399 32484 
s 264
f 23377 32485 32551 
s 544
f 32485 23377 27084 
s 1026
f 26905 32486 32525 
s 516
f 32486 26905 22402 
f 22402 32487 32486 
s 1026
f 32487 22402 24677 
f 32488 23115 27785 
s 516
f 23115 32488 32987 
s 1026
f 24677 32489 32487 
s 516
f 32489 24677 26835 
f 27431 24043 32490 
s 1026
f 32475 32490 24043 
s 516
f 32491 25977 21657 
s 257
f 25977 32491 32559 
s 516
f 32492 24035 27236 
s 1026
f 24035 32492 32532 
s 264
f 32444 32493 26029 
s 544
f 21719 26029 32493 
s 1026
f 24890 32494 32466 
s 516
f 32494 24890 27584 
f 32495 24897 27563 
s 1026
f 24897 32495 32461 
s 516
f 32496 24793 28784 
s 1026
f 24793 32496 32473 
s 516
f 28115 23300 32497 
s 1026
f 32474 32497 23300 
f 25064 32498 32389 
s 516
f 32498 25064 29534 
f 32499 25973 21951 
s 257
f 25973 32499 32342 
s 516
f 29018 24787 32500 
s 1026
f 32375 32500 24787 
f 23971 32501 32388 
s 516
f 32501 23971 26834 
f 32502 23390 27079 
s 257
f 23390 32502 32603 
s 1026
f 24038 32503 32574 
s 516
f 32503 24038 27280 
s 544
f 33129 32504 25950 
s 264
f 26034 25950 32504 
s 1040
f 33136 32505 21849 
s 544
f 29177 21849 32505 
s 516
f 32506 22371 29314 
s 1026
f 22371 32506 32436 
s 516
f 32507 22264 28143 
s 1026
f 22264 32507 32471 
s 516
f 32508 24893 27583 
s 1026
f 24893 32508 32457 
s 1040
f 33171 32509 23107 
s 544
f 28431 23107 32509 
s 1026
f 22318 32510 32434 
s 516
f 32510 22318 28120 
f 32511 24790 28783 
s 1026
f 24790 32511 32380 
s 1040
f 21784 32512 33209 
s 544
f 32512 21784 29482 
s 516
f 32513 24627 28142 
s 1026
f 24627 32513 33281 
s 544
f 33289 32514 26344 
s 1040
f 21631 26344 32514 
s 264
f 32575 32515 23125 
s 544
f 27100 23125 32515 
f 29525 22154 32516 
s 1040
f 32356 32516 22154 
s 544
f 28970 24799 32517 
s 1040
f 32552 32517 24799 
s 544
f 26510 23219 32518 
s 264
f 32519 32518 23219 
s 544
f 32347 32519 26042 
s 264
f 23219 26042 32519 
s 544
f 29017 24785 32520 
s 1040
f 32451 32520 24785 
s off
f 32041 32521 21570 
f 21570 32521 29816 
s 544
f 32522 21771 26025 
s 1040
f 21771 32522 32373 
f 24692 32523 32524 
s 544
f 32523 24692 27582 
s 1040
f 32524 23054 24692 
s 544
f 23054 32524 32525 
f 32525 26352 23054 
s 1040
f 26352 32525 32486 
s 544
f 26025 32526 32522 
s 264
f 32526 26025 24576 
s 544
f 32527 22381 29524 
s 1040
f 22381 32527 32382 
s off
f 32270 32528 32274 
f 32274 32528 32269 
f 32529 20925 21133 
f 20925 32529 31512 
s 1040
f 33584 32530 24517 
s 544
f 28323 24517 32530 
s 1040
f 22299 32531 32376 
s 544
f 32531 22299 28645 
s 1040
f 25044 32532 32492 
s 544
f 32532 25044 29481 
s 1040
f 22309 32533 32404 
s 544
f 32533 22309 27798 
s 516
f 28141 24917 32534 
s 1026
f 32635 32534 24917 
f 22377 32535 32561 
s 516
f 32535 22377 29583 
f 27295 24039 32536 
s 1026
f 32565 32536 24039 
s 516
f 29332 21741 32537 
s 1026
f 32468 32537 21741 
s 516
f 32538 22310 28140 
s 1026
f 22310 32538 32399 
s 516
f 21740 25926 32539 
s 257
f 32400 32539 25926 
s 1040
f 25045 32540 32393 
s 544
f 32540 25045 29480 
s 1040
f 22313 32541 32363 
s 544
f 32541 22313 27853 
f 32542 24731 28429 
s 1040
f 24731 32542 32364 
s off
f 32543 23093 21185 
f 23093 32543 31547 
s 264
f 26030 32544 32553 
s 544
f 32544 26030 21715 
s 516
f 26067 32545 33807 
s 257
f 32545 26067 24575 
s 544
f 32546 23071 27118 
s 264
f 23071 32546 32734 
f 23180 32547 32558 
s 544
f 32547 23180 27119 
f 24626 32548 33568 
s 1040
f 32548 24626 26966 
s 516
f 28138 23544 32549 
s 1026
f 33106 32549 23544 
s 516
f 32550 22545 29523 
s 1026
f 22545 32550 32411 
s 516
f 32551 23214 26485 
s 257
f 23214 32551 32485 
s 1026
f 32517 32552 23974 
s 516
f 26832 23974 32552 
f 32553 23220 26508 
s 257
f 23220 32553 32544 
s 516
f 26507 23208 32554 
s 257
f 32402 32554 23208 
s 1040
f 25137 26317 32555 
s 544
f 33586 32555 26317 
s 516
f 32556 24912 27581 
s 1026
f 24912 32556 32419 
s 516
f 23265 32557 33351 
s 257
f 32557 23265 27245 
s 516
f 32558 23139 26506 
s 257
f 23139 32558 32547 
s 264
f 23395 32559 32491 
s 544
f 32559 23395 27116 
f 26318 32560 32785 
s 1040
f 32560 26318 28669 
s 544
f 32561 22301 27716 
s 1040
f 22301 32561 32535 
s 264
f 23378 32562 32403 
s 544
f 32562 23378 27115 
f 26323 32563 33597 
s 1040
f 32563 26323 25136 
s 1026
f 32564 23535 29021 
s 516
f 23535 32564 33831 
s 1040
f 32536 32565 25040 
s 544
f 29479 25040 32565 
f 27083 23412 32566 
s 264
f 33720 32566 23412 
s 1040
f 32341 32567 22256 
s 544
f 27852 22256 32567 
s 516
f 32568 24047 27163 
s 1026
f 24047 32568 32622 
s 544
f 27077 23376 32569 
s 264
f 32583 32569 23376 
s 516
f 32570 24784 29016 
s 1026
f 24784 32570 32625 
s 516
f 32571 25948 23463 
s 257
f 25948 32571 32860 
s 544
f 24571 32572 32586 
s 264
f 32572 24571 26020 
s 1040
f 32435 32573 23972 
s 544
f 26549 23972 32573 
f 32574 25042 29478 
s 1040
f 25042 32574 32503 
s 516
f 26477 23721 32575 
s 257
f 32515 32575 23721 
s 1026
f 32477 32576 24896 
s 516
f 27580 24896 32576 
s 544
f 23055 32577 32051 
s 264
f 32577 23055 23542 
s 516
f 28117 22258 32578 
s 1026
f 32480 32578 22258 
s 1040
f 32720 32579 25056 
s 544
f 29477 25056 32579 
s 264
f 26020 32580 32572 
s 544
f 32580 26020 23078 
s 1040
f 25061 32581 32652 
s 544
f 32581 25061 27850 
s 516
f 26958 32582 33105 
s 1026
f 32582 26958 28399 
s 516
f 26505 23213 32583 
s 257
f 32569 32583 23213 
s 264
f 26062 32584 33762 
s 544
f 32584 26062 23665 
f 32585 24740 28428 
s 1040
f 24740 32585 32605 
s 1026
f 32586 24679 22081 
s 516
f 24679 32586 32572 
s 1040
f 32745 32587 23529 
s 544
f 25979 23529 32587 
f 33752 32588 26061 
s 264
f 23543 26061 32588 
s 544
f 32589 23761 27114 
s 264
f 23761 32589 32656 
s off
f 33397 32590 22697 
f 31883 22697 32590 
s 1040
f 22311 32591 32442 
s 544
f 32591 22311 28935 
s 1040
f 23965 32592 32446 
s 544
f 32592 23965 26717 
f 27561 24886 32593 
s 1040
f 32422 32593 24886 
f 32594 25059 27098 
s 544
f 25059 32594 32147 
f 32595 24888 27557 
s 1040
f 24888 32595 32384 
s 544
f 29582 22373 32596 
s 1040
f 32447 32596 22373 
s off
f 32597 21133 21106 
f 21303 21133 32597 
s 544
f 26476 23212 32598 
s 264
f 32452 32598 23212 
s off
f 32599 23088 23095 
f 31531 32599 23095 
s 544
f 32600 22328 28137 
s 1040
f 22328 32600 32385 
s off
f 21114 21132 32601 
f 21302 32601 21132 
s 257
f 23056 26261 32602 
s 516
f 33391 32602 26261 
s 264
f 23217 32603 32502 
s 544
f 32603 23217 26479 
s 257
f 23205 26050 32604 
s 516
f 32637 32604 26050 
s 1026
f 24712 32605 32585 
s 516
f 32605 24712 27579 
f 32606 21749 28136 
s 1026
f 21749 32606 33733 
s 516
f 32607 25960 23616 
s 257
f 25960 32607 32636 
s 544
f 32777 32608 23715 
s 264
f 27160 23715 32608 
s 516
f 27578 24872 32609 
s 1026
f 33124 32609 24872 
s 544
f 24574 32610 32869 
s 264
f 32610 24574 27176 
s 1026
f 33142 32611 24087 
s 516
f 28135 24087 32611 
s 1040
f 26966 32612 32548 
s 544
f 32612 26966 22404 
s 1040
f 33573 32613 26198 
s 544
f 24076 26198 32613 
s 516
f 32614 26308 24494 
s 1026
f 26308 32614 32909 
s 516
f 24092 26300 32615 
s 1026
f 32773 32615 26300 
s 516
f 24504 26955 32616 
s 1026
f 32777 32616 26955 
s 264
f 32627 32617 24003 
s 544
f 27112 24003 32617 
s 1026
f 32618 24652 23154 
s 516
f 24652 32618 32804 
s 544
f 33335 32619 23702 
s 264
f 27413 23702 32619 
s 544
f 23718 32620 33006 
s 264
f 32620 23718 27243 
s 544
f 21897 26037 32621 
s 264
f 32633 32621 26037 
s 1040
f 25049 32622 32568 
s 544
f 32622 25049 29175 
s 516
f 32623 22199 28134 
s 1026
f 22199 32623 32852 
s 516
f 23727 32624 33578 
s 257
f 32624 23727 23536 
s 1040
f 23951 32625 32570 
s 544
f 32625 23951 26550 
s 1040
f 32638 32626 24309 
s 544
f 27552 24309 32626 
s 516
f 23615 25952 32627 
s 257
f 32617 32627 25952 
s 1040
f 32640 32628 24738 
s 544
f 28233 24738 32628 
s 1040
f 32641 32629 22059 
s 544
f 28934 22059 32629 
s 1040
f 32642 32630 23969 
s 544
f 26889 23969 32630 
s 257
f 32631 26045 26672 
s 516
f 26045 32631 32789 
s 1026
f 22024 32632 33542 
s 516
f 32632 22024 29246 
f 23459 25944 32633 
s 257
f 32621 32633 25944 
s 516
f 24490 26322 32634 
s 1026
f 32918 32634 26322 
f 24917 21729 32635 
s 516
f 32781 32635 21729 
s 264
f 26069 32636 32607 
s 544
f 32636 26069 21890 
s 1026
f 32858 32637 23664 
s 516
f 26050 23664 32637 
f 29331 22152 32638 
s 1026
f 32626 32638 22152 
f 32639 24651 27103 
s 516
f 24651 32639 32972 
f 27565 24873 32640 
s 1026
f 32628 32640 24873 
s 516
f 28133 22061 32641 
s 1026
f 32629 32641 22061 
s 516
f 28782 24782 32642 
s 1026
f 32630 32642 24782 
f 28377 24438 32643 
s 516
f 33613 32643 24438 
f 26490 23205 32644 
s 257
f 32604 32644 23205 
s 544
f 32977 32645 26316 
s 1040
f 24506 26316 32645 
s 544
f 26351 32646 33009 
s 1040
f 32646 26351 21701 
s 516
f 32647 25976 23457 
s 257
f 25976 32647 33046 
s 544
f 26954 32648 33028 
s 1040
f 32648 26954 23458 
f 32725 32649 22113 
s 544
f 27848 22113 32649 
s 516
f 32650 24807 29581 
s 1026
f 24807 32650 32727 
s 516
f 32651 22079 27573 
s 1026
f 22079 32651 32785 
s 544
f 21748 32652 33131 
s 1040
f 32652 21748 25061 
s 544
f 32587 32653 25979 
s 264
f 23387 25979 32653 
s 257
f 26724 26060 32654 
s 516
f 33092 32654 26060 
s 1040
f 23531 32655 32708 
s 544
f 32655 23531 25975 
f 25975 32656 32655 
s 264
f 32656 25975 23761 
f 32653 32657 23387 
s 544
f 27503 23387 32657 
s 516
f 23454 25930 32658 
s 257
f 33635 32658 25930 
s 1026
f 25255 22553 32659 
s 516
f 32967 32659 22553 
f 23450 25943 32660 
s 257
f 33773 32660 25943 
s 516
f 32661 22574 27422 
s 1026
f 22574 32661 33131 
s 1040
f 32663 32662 26333 
s 544
f 23068 26333 32662 
s 1040
f 26333 26957 32663 
s 544
f 33325 32663 26957 
s 516
f 32664 23075 26071 
s 1026
f 23075 32664 33572 
s 544
f 21623 25922 32665 
s 264
f 32666 32665 25922 
s 544
f 33530 32666 26059 
s 264
f 25922 26059 32666 
s 1026
f 32667 24091 26092 
s 516
f 24091 32667 33641 
s 1040
f 32668 23282 24871 
s 544
f 23282 32668 33348 
s 264
f 25974 32669 33354 
s 544
f 32669 25974 21579 
s 516
f 33655 32670 26027 
s 257
f 26562 26027 32670 
s off
f 20946 29633 32671 
f 33539 32671 29633 
s 516
f 23473 25932 32672 
s 257
f 33598 32672 25932 
s 516
f 26500 23537 32673 
s 257
f 32674 32673 23537 
f 23537 26058 32674 
s 516
f 33362 32674 26058 
s 1040
f 32675 26902 22025 
s 544
f 26902 32675 32677 
s off
f 32676 26407 22237 
f 26407 32676 31540 
s 544
f 32677 24591 26902 
s 1040
f 24591 32677 32678 
f 32678 22717 24591 
s 544
f 22717 32678 33650 
s off
f 31527 32679 23094 
f 32679 21186 23094 
s 1026
f 25260 21871 32680 
s 516
f 33418 32680 21871 
s off
f 31529 32681 21283 
f 32681 22558 21283 
f 32671 32682 20946 
f 21120 20946 32682 
f 31492 32683 21280 
f 32683 22559 21280 
f 32684 20950 29634 
f 20945 20950 32684 
s 1040
f 24369 26192 32685 
s 544
f 33473 32685 26192 
s 516
f 26057 32686 33463 
s 257
f 32686 26057 25957 
s off
f 32687 21100 21010 
f 21100 32687 33342 
f 26418 32688 31546 
f 32688 26418 21376 
s 1026
f 32689 25252 21817 
s 516
f 25252 32689 33629 
s 1026
f 32690 24481 27845 
s 516
f 24481 32690 33511 
s off
f 32691 22654 23085 
f 22654 32691 31495 
f 31524 32692 26408 
f 22074 26408 32692 
s 544
f 27038 21624 32693 
s 1040
f 32694 32693 21624 
f 21624 24647 32694 
s 544
f 33541 32694 24647 
s off
f 32695 23089 22653 
f 31493 32695 22653 
s 544
f 32699 32696 23397 
s 1040
f 25795 23397 32696 
s 544
f 33420 32697 24764 
s 1040
f 22490 24764 32697 
s 544
f 33343 32698 23720 
s 264
f 27341 23720 32698 
s 257
f 23010 24083 32699 
s 516
f 32696 32699 24083 
s off
f 22239 26405 32700 
f 31519 32700 26405 
f 32701 23096 23086 
f 23096 32701 31549 
s 1026
f 27754 23109 32702 
s 516
f 33664 32702 23109 
s 544
f 32738 32703 24520 
s 264
f 26040 24520 32703 
f 32703 32704 26040 
s 544
f 23557 26040 32704 
s 1040
f 32705 22038 25139 
s 544
f 22038 32705 32737 
f 33737 32706 24112 
s 1040
f 29315 24112 32706 
s 544
f 26109 32707 33672 
s 1040
f 32707 26109 24667 
s 516
f 32708 23309 28969 
s 1026
f 23309 32708 32655 
s 1040
f 32757 32709 22115 
s 544
f 29473 22115 32709 
f 33767 32710 21894 
s 1040
f 25632 21894 32710 
s 544
f 26914 32711 33675 
s 1040
f 32711 26914 23496 
s 257
f 26685 26039 32712 
s 516
f 32973 32712 26039 
s 544
f 32713 24593 27512 
s 1040
f 21608 24593 32713 
f 21591 32714 33021 
s 544
f 32714 21591 28933 
s 257
f 26545 23413 32715 
s 516
f 33144 32715 23413 
f 32716 22031 27304 
s 1026
f 22031 32716 33224 
s 544
f 32717 23689 28425 
s 1040
f 23689 32717 32822 
s 1026
f 25251 21735 32718 
s 516
f 33051 32718 21735 
s 1040
f 26492 26956 32719 
s 544
f 33680 32719 26956 
s 516
f 27415 24036 32720 
s 1026
f 32579 32720 24036 
f 25254 21852 32721 
s 516
f 33171 32721 21852 
s 544
f 32855 32722 24920 
s 1040
f 21881 24920 32722 
s 1026
f 32347 32723 22062 
s 516
f 29533 22062 32723 
s 544
f 33338 32724 21988 
s 1040
f 25613 21988 32724 
s 516
f 29330 22111 32725 
s 1026
f 32649 32725 22111 
s 544
f 33665 32726 23612 
s 264
f 27416 23612 32726 
s 1040
f 22080 32727 32650 
s 544
f 32727 22080 27597 
f 22200 32728 32852 
s 1040
f 32728 22200 26503 
s 544
f 23716 32729 32788 
s 264
f 32729 23716 27170 
s 1026
f 32730 24065 28810 
s 516
f 24065 32730 33299 
s 257
f 32731 24005 26575 
s 516
f 24005 32731 33209 
s 1026
f 32732 22037 29353 
s 516
f 22037 32732 32735 
s 1026
f 32733 24313 25267 
s 516
f 24313 32733 32739 
s 544
f 25958 32734 33567 
s 264
f 32734 25958 23071 
s 516
f 32735 26129 22037 
s 1026
f 26129 32735 32737 
f 24769 21862 32736 
s 516
f 32741 32736 21862 
f 24669 32737 32705 
s 1026
f 32737 24669 26129 
f 29031 24067 32738 
s 516
f 32703 32738 24067 
f 32739 26138 24313 
s 1026
f 26138 32739 33201 
s 544
f 32806 32740 23722 
s 264
f 27180 23722 32740 
s 516
f 21862 26911 32741 
s 1026
f 32746 32741 26911 
s 544
f 26952 32742 33574 
s 1040
f 32742 26952 22087 
s 257
f 33682 32743 23726 
s 516
f 27108 23726 32743 
s 1026
f 21582 32744 33771 
s 516
f 32744 21582 26137 
f 28779 22110 32745 
s 1026
f 32587 32745 22110 
f 26911 23540 32746 
s 516
f 32750 32746 23540 
s 1026
f 32747 22035 28424 
s 516
f 22035 32747 32751 
f 26137 32748 32744 
s 1026
f 32748 26137 24090 
s 544
f 33780 32749 23092 
s 1040
f 24672 23092 32749 
s 516
f 23540 25966 32750 
s 257
f 32756 32750 25966 
s 516
f 32751 26951 22035 
s 1026
f 26951 32751 32754 
f 23064 32752 32758 
s 516
f 32752 23064 26018 
s 544
f 21688 32753 32453 
s 1040
f 32753 21688 27577 
s 1026
f 32754 23497 26951 
s 516
f 23497 32754 32761 
f 32755 21682 28129 
s 1026
f 21682 32755 33220 
s 516
f 33197 32756 24004 
s 257
f 25966 24004 32756 
s 516
f 26054 23074 32757 
s 1026
f 32709 32757 23074 
s 516
f 24653 32758 32763 
s 1026
f 32758 24653 23064 
s 544
f 24901 32759 32827 
s 1040
f 32759 24901 23306 
s 544
f 32613 32760 24076 
s 1040
f 28046 24076 32760 
s 516
f 32761 25927 23497 
s 257
f 25927 32761 33582 
s 516
f 29329 24878 32762 
s 1026
f 32765 32762 24878 
s 516
f 32763 27030 24653 
s 1026
f 27030 32763 32767 
s 264
f 32764 23655 27438 
s 544
f 23655 32764 32970 
s 1026
f 24878 22118 32765 
s 516
f 32766 32765 22118 
f 22118 26100 32766 
s 1026
f 32768 32766 26100 
f 32767 22134 27030 
s 516
f 22134 32767 32772 
s 1026
f 26100 24092 32768 
s 516
f 32615 32768 24092 
f 28128 24800 32769 
s 1026
f 32783 32769 24800 
s 1040
f 21901 32770 33782 
s 544
f 32770 21901 26819 
s 1040
f 25913 27006 32771 
s 544
f 33457 32771 27006 
s 516
f 32772 26096 22134 
s 1026
f 26096 32772 32775 
f 26300 24504 32773 
s 516
f 32616 32773 24504 
s 1026
f 29366 23976 32774 
s 516
f 32787 32774 23976 
s 1026
f 32775 24012 26096 
s 516
f 24012 32775 32778 
s 1040
f 33329 32776 21989 
s 544
f 26950 21989 32776 
s 1026
f 26955 22162 32777 
s 516
f 32608 32777 22162 
f 32778 26347 24012 
s 1026
f 26347 32778 32782 
s 257
f 32779 26065 26808 
s 516
f 26065 32779 33619 
s 257
f 26825 26054 32780 
s 516
f 32757 32780 26054 
s 544
f 32635 32781 22595 
s 1040
f 26447 22595 32781 
s 516
f 24509 32782 32786 
s 1026
f 32782 24509 26347 
s 516
f 33786 32783 21880 
s 1026
f 24800 21880 32783 
s 1040
f 24526 32784 32792 
s 544
f 32713 32784 24526 
s 1040
f 24095 32785 32651 
s 544
f 32785 24095 26318 
s 1026
f 26949 32786 32788 
s 516
f 32786 26949 24509 
f 23976 26102 32787 
s 1026
f 32799 32787 26102 
f 32788 22132 26949 
s 516
f 22132 32788 32729 
f 32789 23154 26045 
s 1026
f 23154 32789 32618 
s 516
f 26063 23076 32790 
s 1026
f 32139 32790 23076 
f 27640 24322 32791 
s 516
f 32826 32791 24322 
s 1040
f 32792 26913 24526 
s 544
f 21605 26913 32792 
s 1026
f 32793 21893 25890 
s 516
f 21893 32793 32859 
s 544
f 33808 32794 24693 
s 1040
f 21665 24693 32794 
f 33600 32795 25055 
s 544
f 29470 25055 32795 
s 1040
f 33095 32796 26965 
s 544
f 21724 26965 32796 
s 1026
f 26148 26289 32797 
s 516
f 32245 32797 26289 
s 1026
f 23153 32798 32801 
s 516
f 32798 23153 26019 
s 1026
f 26102 24484 32799 
s 516
f 32814 32799 24484 
s 544
f 33592 32800 27028 
s 1040
f 23757 27028 32800 
s 1026
f 32801 24654 23153 
s 516
f 24654 32801 32808 
s 1040
f 33603 32802 25054 
s 544
f 29469 25054 32802 
s 1026
f 24783 32803 32970 
s 516
f 32803 24783 28778 
f 32804 27032 24652 
s 1026
f 27032 32804 32812 
s 516
f 28968 24765 32805 
s 1026
f 32806 32805 24765 
f 24765 22194 32806 
s 516
f 32740 32806 22194 
s 1040
f 32807 23732 21605 
s 544
f 25953 23732 32807 
s 516
f 32808 27029 24654 
s 1026
f 27029 32808 32811 
s 1040
f 33565 32809 23975 
s 544
f 26093 23975 32809 
s 1040
f 32800 32810 23757 
s 544
f 26108 23757 32810 
s 1026
f 32811 21940 27029 
s 516
f 21940 32811 32816 
f 22201 32812 32822 
s 1026
f 32812 22201 27032 
s 544
f 21890 32813 32636 
s 1040
f 32813 21890 26201 
s 516
f 24484 26345 32814 
s 1026
f 32836 32814 26345 
s 516
f 28777 22164 32815 
s 1026
f 32819 32815 22164 
s 516
f 32816 26094 21940 
s 1026
f 26094 32816 32821 
s 516
f 26976 24436 32817 
s 1026
f 32337 32817 24436 
s 544
f 32818 23408 27107 
s 264
f 23408 32818 33389 
s 1026
f 22164 23983 32819 
s 516
f 33594 32819 23983 
f 32820 21685 26032 
s 1026
f 21685 32820 32453 
f 32821 24496 26094 
s 516
f 24496 32821 32825 
s 1026
f 26098 32822 32717 
s 516
f 32822 26098 22201 
s 544
f 32810 32823 26108 
s 1040
f 25364 26108 32823 
s 544
f 32824 26201 24072 
s 1040
f 26201 32824 32813 
s 1026
f 26302 32825 32828 
s 516
f 32825 26302 24496 
f 24322 26346 32826 
s 1026
f 32867 32826 26346 
f 32827 23402 29613 
s 516
f 23402 32827 32759 
f 21938 32828 32834 
s 1026
f 32828 21938 26302 
s 516
f 32829 21805 29328 
s 1026
f 21805 32829 33583 
s 544
f 32830 22052 29531 
s 1040
f 22052 32830 33704 
s 544
f 32809 32831 26093 
s 1040
f 25501 26093 32831 
s 516
f 26481 23660 32832 
s 257
f 32855 32832 23660 
s 544
f 24072 32833 32824 
s 1040
f 32833 24072 28047 
s 516
f 32834 26948 21938 
s 1026
f 26948 32834 32841 
s 516
f 27407 23982 32835 
s 1026
f 32838 32835 23982 
f 26345 21900 32836 
s 516
f 32862 32836 21900 
f 32837 24882 27568 
s 1026
f 24882 32837 32840 
f 23982 27037 32838 
s 516
f 32842 32838 27037 
s 1040
f 33791 32839 22093 
s 544
f 25939 22093 32839 
s 516
f 24495 32840 32844 
s 1026
f 32840 24495 24882 
f 32841 23447 26948 
s 516
f 23447 32841 32851 
f 27037 21736 32842 
s 1026
f 32845 32842 21736 
s 264
f 33697 32843 24011 
s 544
f 27106 24011 32843 
s 516
f 32844 26303 24495 
s 1026
f 26303 32844 32848 
f 21736 24665 32845 
s 516
f 32849 32845 24665 
s 544
f 33646 32846 22578 
s 1040
f 25089 22578 32846 
s 516
f 32847 23691 26480 
s 257
f 23691 32847 33827 
s 1026
f 32848 21807 26303 
s 516
f 21807 32848 32850 
f 24665 26101 32849 
s 1026
f 33586 32849 26101 
s 516
f 32850 26947 21807 
s 1026
f 26947 32850 33587 
s 516
f 32851 25933 23447 
s 257
f 25933 32851 33596 
s 1026
f 32852 24915 22199 
s 516
f 24915 32852 32728 
s 544
f 32839 32853 25939 
s 264
f 27272 25939 32853 
s 1040
f 32953 32854 21983 
s 544
f 27835 21983 32854 
s 257
f 23660 24008 32855 
s 516
f 32722 32855 24008 
f 32856 23536 26469 
s 257
f 23536 32856 32624 
s 1026
f 28285 21737 32857 
s 516
f 32861 32857 21737 
s 544
f 29304 22058 32858 
s 1040
f 32637 32858 22058 
s 544
f 25262 32859 32793 
s 1040
f 32859 25262 21892 
s 264
f 24705 32860 32571 
s 544
f 32860 24705 27075 
s 516
f 21737 26910 32861 
s 1026
f 32863 32861 26910 
s 516
f 21900 26916 32862 
s 1026
f 32875 32862 26916 
f 26910 23615 32863 
s 516
f 32627 32863 23615 
s 1040
f 33039 32864 24758 
s 544
f 28415 24758 32864 
s 516
f 26055 23156 32865 
s 1026
f 32866 32865 23156 
f 23156 24656 32866 
s 516
f 32870 32866 24656 
s 1026
f 26346 24508 32867 
s 516
f 32914 32867 24508 
f 32868 24370 27404 
s 1026
f 24370 32868 32877 
f 32869 23319 28930 
s 516
f 23319 32869 32610 
f 24656 27015 32870 
s 1026
f 32874 32870 27015 
f 32876 32871 24816 
s 516
f 27273 24816 32871 
s 544
f 24921 32872 33023 
s 1040
f 32872 24921 21830 
f 23254 32873 33153 
s 544
f 32873 23254 28336 
s 1026
f 27015 21931 32874 
s 516
f 32878 32874 21931 
s 1026
f 26916 23459 32875 
s 516
f 32633 32875 23459 
s 1026
f 24816 23227 32876 
s 516
f 32250 32876 23227 
s 1026
f 32877 26205 24370 
s 516
f 26205 32877 32890 
f 21931 26091 32878 
s 1026
f 32886 32878 26091 
s 544
f 33711 32879 21679 
s 1040
f 29006 21679 32879 
s 544
f 23113 32880 33814 
s 1040
f 32880 23113 24849 
s 1026
f 23149 32881 32889 
s 516
f 32881 23149 26038 
s 544
f 23232 32882 33793 
s 1040
f 32882 23232 24857 
s 516
f 29327 24880 32883 
s 1026
f 32888 32883 24880 
s 544
f 33273 32884 23656 
s 264
f 27294 23656 32884 
s 544
f 32951 32885 23714 
s 264
f 27302 23714 32885 
s 1026
f 26091 24491 32886 
s 516
f 32893 32886 24491 
s 544
f 33570 32887 21857 
s 1040
f 24698 21857 32887 
s 1026
f 24880 22048 32888 
s 516
f 32891 32888 22048 
s 1026
f 32889 24646 23149 
s 516
f 24646 32889 32897 
f 32890 21659 26205 
s 1026
f 21659 32890 33688 
s 516
f 22048 26103 32891 
s 1026
f 32894 32891 26103 
s 516
f 29522 22028 32892 
s 1026
f 33140 32892 22028 
s 516
f 24491 26320 32893 
s 1026
f 32901 32893 26320 
f 26103 24498 32894 
s 516
f 32898 32894 24498 
s 1026
f 32895 22071 29394 
s 516
f 22071 32895 32990 
f 29326 24876 32896 
s 1026
f 32899 32896 24876 
s 516
f 32897 27027 24646 
s 1026
f 27027 32897 32907 
s 516
f 24498 26295 32898 
s 1026
f 32904 32898 26295 
f 24876 22044 32899 
s 516
f 32906 32899 22044 
s 544
f 32900 21792 25949 
s 1040
f 21792 32900 33069 
s 1026
f 26320 21930 32901 
s 516
f 32911 32901 21930 
f 24649 32902 32916 
s 1026
f 32902 24649 23065 
s 257
f 32903 23411 26698 
s 516
f 23411 32903 32976 
f 32908 32904 22047 
s 1026
f 26295 22047 32904 
s 1040
f 21829 32905 33798 
s 544
f 32905 21829 25971 
s 516
f 22044 26136 32906 
s 1026
f 32910 32906 26136 
f 32907 21928 27027 
s 516
f 21928 32907 32915 
f 22047 26946 32908 
s 1026
f 32912 32908 26946 
f 32909 22069 26308 
s 516
f 22069 32909 33107 
s 1026
f 26136 24490 32910 
s 516
f 32634 32910 24490 
f 21930 26939 32911 
s 1026
f 32919 32911 26939 
f 26946 23445 32912 
s 516
f 33707 32912 23445 
f 32913 24895 29521 
s 1026
f 24895 32913 32921 
s 516
f 24508 26953 32914 
s 1026
f 32951 32914 26953 
s 516
f 32915 26099 21928 
s 1026
f 26099 32915 32923 
s 516
f 32916 27026 24649 
s 1026
f 27026 32916 32930 
s 544
f 25949 32917 32900 
s 264
f 32917 25949 24007 
s 516
f 32922 32918 22043 
s 1026
f 26322 22043 32918 
f 26939 23449 32919 
s 516
f 32924 32919 23449 
s 1040
f 33152 32920 24723 
s 544
f 28411 24723 32920 
s 1026
f 32921 22053 24895 
s 516
f 22053 32921 32927 
f 22043 26923 32922 
s 1026
f 32925 32922 26923 
f 32923 24502 26099 
s 516
f 24502 32923 32928 
f 23449 25961 32924 
s 257
f 33605 32924 25961 
s 1026
f 26923 23455 32925 
s 516
f 33708 32925 23455 
s 1040
f 33205 32926 21754 
s 544
f 27012 21754 32926 
s 516
f 32927 26142 22053 
s 1026
f 26142 32927 32933 
f 26312 32928 32935 
s 516
f 32928 26312 24502 
f 21848 32929 33430 
s 1026
f 32929 21848 25253 
f 32930 22138 27026 
s 516
f 22138 32930 32941 
f 32201 32931 23355 
s 1026
f 25077 23355 32931 
f 25253 32932 32929 
s 516
f 32932 25253 27830 
s 1026
f 32933 24501 26142 
s 516
f 24501 32933 32938 
s 1026
f 22210 24676 32934 
s 516
f 33697 32934 24676 
f 21926 32935 32940 
s 1026
f 32935 21926 26312 
s 516
f 23138 32936 32944 
s 1026
f 32936 23138 26498 
f 23301 32937 33434 
s 516
f 32937 23301 28202 
s 1026
f 26315 32938 32942 
s 516
f 32938 26315 24501 
s 544
f 32926 32939 27012 
s 1040
f 24452 27012 32939 
s 516
f 32940 26921 21926 
s 1026
f 26921 32940 32943 
s 516
f 32941 26135 22138 
s 1026
f 26135 32941 32950 
s 516
f 22051 32942 32946 
s 1026
f 32942 22051 26315 
f 32943 23461 26921 
s 516
f 23461 32943 33609 
s 1026
f 25909 32944 32956 
s 516
f 32944 25909 23138 
s 1026
f 21967 32945 33751 
s 516
f 32945 21967 26809 
f 32946 26969 22051 
s 1026
f 26969 32946 32952 
s 516
f 26031 21981 32947 
s 1026
f 32948 32947 21981 
f 21981 26194 32948 
s 516
f 32953 32948 26194 
s 1040
f 32939 32949 24452 
s 544
f 26112 24452 32949 
s 1026
f 32950 24013 26135 
s 516
f 24013 32950 32963 
s 1026
f 26953 22173 32951 
s 516
f 32885 32951 22173 
s 1026
f 32952 23463 26969 
s 516
f 23463 32952 32571 
f 26194 24077 32953 
s 1026
f 32854 32953 24077 
s 257
f 32954 26038 25970 
s 516
f 26038 32954 32881 
s 1026
f 29069 23553 32955 
s 516
f 33772 32955 23553 
s 1026
f 32956 24433 25909 
s 516
f 24433 32956 32966 
s 1040
f 32957 24480 21812 
s 544
f 26943 24480 32957 
s 516
f 22178 32958 32999 
s 1026
f 32958 22178 28320 
s 544
f 24820 32959 33730 
s 1040
f 32959 24820 23225 
s 516
f 28036 24833 32960 
s 1026
f 32961 32960 24833 
f 24833 26355 32961 
s 516
f 32964 32961 26355 
s 544
f 32949 32962 26112 
s 1040
f 23058 26112 32962 
s 516
f 32963 26343 24013 
s 1026
f 26343 32963 32975 
s 516
f 26355 21982 32964 
s 1026
f 32965 32964 21982 
f 21982 24432 32965 
s 516
f 32969 32965 24432 
f 32966 26944 24433 
s 1026
f 26944 32966 32981 
s 544
f 32659 32967 24848 
s 1040
f 21858 24848 32967 
s 516
f 33010 32968 23409 
s 257
f 23717 23409 32968 
s 516
f 24432 26909 32969 
s 1026
f 32971 32969 26909 
s 516
f 21590 32970 32764 
s 1026
f 32970 21590 24783 
s 516
f 33797 32971 23048 
s 1026
f 26909 23048 32971 
s 516
f 32972 27031 24651 
s 1026
f 27031 32972 32980 
s 516
f 26039 23157 32973 
s 1026
f 32986 32973 23157 
s 516
f 24660 32974 32097 
s 1026
f 32974 24660 23073 
s 516
f 24505 32975 32988 
s 1026
f 32975 24505 26343 
s 544
f 24811 32976 32903 
s 1040
f 32976 24811 21699 
f 32962 32977 23058 
s 544
f 26316 23058 32977 
s 1040
f 23225 32978 32959 
s 544
f 32978 23225 27035 
s 516
f 23451 32979 33614 
s 1026
f 32979 23451 26920 
f 32980 21996 27031 
s 516
f 21996 32980 32985 
s 1026
f 32981 21657 26944 
s 516
f 21657 32981 32491 
s 1040
f 21699 32982 32976 
s 544
f 32982 21699 27025 
s 516
f 26291 32983 32174 
s 1026
f 32983 26291 26146 
s 516
f 33698 32984 26044 
s 257
f 25938 26044 32984 
s 516
f 32985 26119 21996 
s 1026
f 26119 32985 32991 
f 23157 24663 32986 
s 516
f 33000 32986 24663 
f 32987 26319 23115 
s 1026
f 26319 32987 32994 
f 26922 32988 33006 
s 516
f 32988 26922 24505 
s 544
f 27025 32989 32982 
s 1040
f 32989 27025 23698 
s 516
f 32990 26097 22071 
s 1026
f 26097 32990 33246 
f 32991 24485 26119 
s 516
f 24485 32991 32997 
f 26483 23719 32992 
s 257
f 33073 32992 23719 
s 1026
f 26968 22698 32993 
s 516
f 33001 32993 22698 
s 1026
f 32994 24512 26319 
s 516
f 24512 32994 33798 
s 1040
f 23698 32995 32989 
s 544
f 32995 23698 26133 
s 1040
f 33157 32996 22077 
s 544
f 28649 22077 32996 
s 1026
f 26342 32997 33007 
s 516
f 32997 26342 24485 
s 1040
f 32645 32998 24506 
s 544
f 26942 24506 32998 
s 516
f 32999 26908 22178 
s 1026
f 26908 32999 33116 
s 516
f 24663 27024 33000 
s 1026
f 33013 33000 27024 
s 516
f 22698 25940 33001 
s 257
f 33716 33001 25940 
s 1040
f 33484 33002 20772 
s 544
f 29465 20772 33002 
s off
f 33518 20969 33003 
f 26989 33003 20969 
s 1026
f 24809 33004 33014 
s 516
f 33004 24809 27398 
s 1040
f 33005 26133 24482 
s 544
f 26133 33005 32995 
s 1026
f 33006 22136 26922 
s 516
f 22136 33006 32620 
s 1026
f 33007 21994 26342 
s 516
f 21994 33007 33012 
s 544
f 27035 33008 32978 
s 1040
f 33008 27035 21674 
f 24482 33009 33005 
s 544
f 33009 24482 26351 
f 32968 33010 24812 
s 1040
f 21745 24812 33010 
s 516
f 26022 23155 33011 
s 1026
f 33017 33011 23155 
s 516
f 33012 26941 21994 
s 1026
f 26941 33012 33020 
f 27024 22169 33013 
s 516
f 33039 33013 22169 
f 23250 33014 33041 
s 1026
f 33014 23250 24809 
s 544
f 32998 33015 26942 
s 1040
f 21753 26942 33015 
f 33010 33016 21745 
s 544
f 27016 21745 33016 
s 1026
f 23155 24650 33017 
s 516
f 33034 33017 24650 
s 544
f 33018 22528 28925 
s 1040
f 22528 33018 33671 
s 516
f 33019 23150 26024 
s 1026
f 23150 33019 33033 
f 33020 23457 26941 
s 516
f 23457 33020 32647 
f 33021 23320 28125 
s 1026
f 23320 33021 32714 
s 516
f 26206 21650 33022 
s 1026
f 33784 33022 21650 
s 257
f 33023 24006 26459 
s 516
f 24006 33023 32872 
s 544
f 33148 33024 21732 
s 1040
f 24699 21732 33024 
s 1026
f 24437 33025 32381 
s 516
f 33025 24437 26978 
s 1040
f 33026 23665 29325 
s 544
f 23665 33026 32584 
s 1026
f 25263 23120 33027 
s 516
f 33717 33027 23120 
s 1040
f 21701 33028 32646 
s 544
f 33028 21701 26954 
s 1040
f 21674 33029 33008 
s 544
f 33029 21674 26132 
f 33016 33030 27016 
s 1040
f 24451 27016 33030 
s 516
f 33759 33031 22015 
s 1026
f 25836 22015 33031 
s 1040
f 24063 33032 33678 
s 544
f 33032 24063 26777 
s 1026
f 33033 24658 23150 
s 516
f 24658 33033 33045 
f 24650 27023 33034 
s 1026
f 33047 33034 27023 
s 1040
f 33015 33035 21753 
s 544
f 26804 21753 33035 
f 29714 22208 33036 
s 1040
f 33755 33036 22208 
s 1026
f 26313 24434 33037 
s 516
f 33718 33037 24434 
s 1026
f 33146 33038 21664 
s 516
f 26047 21664 33038 
f 22169 26105 33039 
s 1026
f 32864 33039 26105 
s 1040
f 32713 33040 21608 
s 544
f 26268 21608 33040 
s 516
f 33041 27045 23250 
s 1026
f 27045 33041 33072 
s 1040
f 33030 33042 24451 
s 544
f 26117 24451 33042 
s 1026
f 26940 21649 33043 
s 516
f 33720 33043 21649 
s 544
f 21618 33044 33761 
s 1040
f 33044 21618 27570 
s 516
f 33045 27022 24658 
s 1026
f 27022 33045 33057 
s 516
f 23308 33046 33601 
s 257
f 33046 23308 25976 
s 1026
f 27023 21935 33047 
s 516
f 33059 33047 21935 
s 1040
f 24537 21617 33048 
s 544
f 33048 21617 25907 
s 1026
f 33428 33049 22196 
s 516
f 28976 22196 33049 
s 1026
f 33050 24513 22144 
s 516
f 24513 33050 33165 
s 544
f 32718 33051 24691 
s 1040
f 21733 24691 33051 
s 516
f 33052 24694 27576 
s 1026
f 24694 33052 33061 
s 544
f 24865 33053 33475 
s 1040
f 33053 24865 21986 
s 544
f 33042 33054 26117 
s 1040
f 23051 26117 33054 
s 516
f 33055 23230 28489 
s 1026
f 23230 33055 33068 
s 1040
f 23458 33056 32648 
s 544
f 33056 23458 25937 
s 1026
f 33057 21944 27022 
s 516
f 21944 33057 33096 
s 544
f 33756 33058 23099 
s 1040
f 24850 23099 33058 
s 516
f 21935 26131 33059 
s 1026
f 33118 33059 26131 
s 1040
f 33051 33060 21733 
s 544
f 26328 21733 33060 
s 1026
f 33061 21918 24694 
s 516
f 21918 33061 33071 
s 1040
f 21971 33062 33211 
s 544
f 33062 21971 29463 
s 1040
f 33699 33063 24805 
s 544
f 29530 24805 33063 
f 33060 33064 26328 
s 1040
f 26963 26328 33064 
s 544
f 33395 33065 24097 
s 264
f 24097 33065 32588 
s 544
f 33392 33066 25897 
s 1040
f 25897 33066 32057 
f 32302 33067 24683 
s 544
f 28648 24683 33067 
s 1026
f 33068 24431 23230 
s 516
f 24431 33068 33111 
s 1026
f 22517 33069 32900 
s 516
f 33069 22517 26901 
s 544
f 22518 33070 33079 
s 1040
f 33070 22518 28039 
s 516
f 33071 26340 21918 
s 1026
f 26340 33071 32279 
f 33072 21795 27045 
s 516
f 21795 33072 33153 
s 257
f 23719 26049 33073 
s 516
f 33205 33073 26049 
s 544
f 33074 23758 26128 
s 1040
f 23758 33074 33170 
f 33077 33075 24689 
s 544
f 28924 24689 33075 
s 516
f 23462 25936 33076 
s 257
f 33714 33076 25936 
s 544
f 33114 33077 26339 
s 1040
f 24689 26339 33077 
s 544
f 32796 33078 21724 
s 1040
f 26450 21724 33078 
f 26202 33079 33083 
s 544
f 33079 26202 22518 
f 33106 33080 21850 
s 1040
f 26521 21850 33080 
s 544
f 33085 33081 26110 
s 1040
f 24515 26110 33081 
s 544
f 33108 33082 25947 
s 264
f 26068 25947 33082 
s 1040
f 33083 24373 26202 
s 544
f 24373 33083 33582 
s 1026
f 26330 21934 33084 
s 516
f 33181 33084 21934 
s 1040
f 32291 33085 21864 
s 544
f 26110 21864 33085 
s 1026
f 26967 23462 33086 
s 516
f 33076 33086 23462 
f 29324 24879 33087 
s 1026
f 33202 33087 24879 
f 23554 27036 33088 
s 516
f 33138 33088 27036 
s 544
f 33151 33089 27020 
s 1040
f 23699 27020 33089 
s 544
f 24924 33090 33776 
s 1040
f 33090 24924 21642 
s 516
f 26470 23215 33091 
s 257
f 33570 33091 23215 
s 1026
f 33592 33092 23106 
s 516
f 26060 23106 33092 
s 544
f 23078 33093 32580 
s 1040
f 33093 23078 25046 
s off
f 26990 21073 33094 
f 33445 33094 21073 
s 544
f 33115 33095 26301 
s 1040
f 26965 26301 33095 
s 1026
f 26083 33096 33127 
s 516
f 33096 26083 21944 
s 1040
f 33130 33097 24507 
s 544
f 26931 24507 33097 
s 1040
f 26960 26337 33098 
s 544
f 33188 33098 26337 
f 22095 33099 32074 
s 1040
f 33099 22095 25079 
f 26964 33100 33150 
s 544
f 33100 26964 23057 
f 21629 33101 33194 
s 1040
f 33101 21629 24661 
s 1026
f 33102 23105 25261 
s 516
f 23105 33102 33671 
s 544
f 33103 25024 27551 
s 1040
f 25024 33103 33204 
s 1026
f 33104 23446 26937 
s 516
f 23446 33104 33633 
f 33105 22081 26958 
s 1026
f 22081 33105 32586 
s 1040
f 32549 33106 26906 
s 544
f 21850 26906 33106 
s 1026
f 26920 33107 32979 
s 516
f 33107 26920 22069 
s 1040
f 33196 33108 23453 
s 544
f 25947 23453 33108 
s 1040
f 33054 33109 23051 
s 544
f 26338 23051 33109 
f 33110 21606 27008 
s 1040
f 21606 33110 33403 
s 516
f 33111 26907 24431 
s 1026
f 26907 33111 33121 
s 544
f 33455 33112 21866 
s 1040
f 27571 21866 33112 
f 33064 33113 26963 
s 544
f 24440 26963 33113 
f 26339 22007 33114 
s 1040
f 33309 33114 22007 
f 33317 33115 23539 
s 544
f 26301 23539 33115 
s 1026
f 33116 23616 26908 
s 516
f 23616 33116 32607 
s 1040
f 33117 25919 26943 
s 544
f 23056 25919 33117 
s 516
f 33139 33118 24488 
s 1026
f 26131 24488 33118 
f 26924 23454 33119 
s 516
f 32658 33119 23454 
f 21708 26967 33120 
s 1026
f 33086 33120 26967 
f 33121 21673 26907 
s 516
f 21673 33121 33135 
s 544
f 33113 33122 24440 
s 1040
f 22655 24440 33122 
f 21874 33123 33619 
s 544
f 33123 21874 27017 
s 1040
f 32609 33124 24739 
s 544
f 28401 24739 33124 
s 1026
f 24368 26195 33125 
s 516
f 33134 33125 26195 
f 26046 23554 33126 
s 1026
f 33088 33126 23554 
f 33127 24497 26083 
s 516
f 24497 33127 33147 
s 544
f 26132 33128 33029 
s 1040
f 33128 26132 24514 
f 33122 33129 22655 
s 544
f 25950 22655 33129 
s 1040
f 24507 26338 33130 
s 544
f 33109 33130 26338 
s 1040
f 27043 33131 32661 
s 544
f 33131 27043 21748 
f 33311 33132 25967 
s 264
f 26052 25967 33132 
s 544
f 25959 33133 33623 
s 264
f 33133 25959 24082 
s 516
f 26195 21884 33134 
s 1026
f 32280 33134 21884 
s 257
f 25955 33135 33730 
s 516
f 33135 25955 21673 
s 544
f 33691 33136 24918 
s 1040
f 21849 24918 33136 
s 1026
f 33137 22693 24581 
s 516
f 22693 33137 33625 
f 27036 22075 33138 
s 1026
f 33143 33138 22075 
s 516
f 24488 26330 33139 
s 1026
f 33084 33139 26330 
s 1040
f 32892 33140 22030 
s 544
f 26111 22030 33140 
f 24762 33141 33315 
s 1040
f 33141 24762 21969 
s 544
f 28920 22123 33142 
s 1040
f 32611 33142 22123 
s 1026
f 22075 24664 33143 
s 516
f 33152 33143 24664 
s 544
f 32715 33144 24808 
s 1040
f 21703 24808 33144 
s 516
f 28127 24802 33145 
s 1026
f 33189 33145 24802 
f 21664 24845 33146 
s 516
f 33208 33146 24845 
f 33147 26299 24497 
s 1026
f 26299 33147 33162 
s 264
f 32504 33148 26034 
s 544
f 21732 26034 33148 
f 33149 21969 26799 
s 1040
f 21969 33149 33141 
s 544
f 21911 33150 33310 
s 1040
f 33150 21911 26964 
f 33144 33151 21703 
s 544
f 27020 21703 33151 
s 516
f 24664 26130 33152 
s 1026
f 32920 33152 26130 
s 516
f 33153 26120 21795 
s 1026
f 26120 33153 32873 
s 1040
f 21815 26925 33154 
s 544
f 33729 33154 26925 
f 22070 33155 33233 
s 1040
f 33155 22070 28044 
s 544
f 23057 33156 33100 
s 1040
f 33156 23057 24680 
s 516
f 28130 22076 33157 
s 1026
f 32996 33157 22076 
s 544
f 33140 33158 26111 
s 1040
f 24059 26111 33158 
s 544
f 33198 33159 23151 
s 1040
f 24657 23151 33159 
s off
f 25588 21319 33160 
f 33811 33160 21319 
s 544
f 33161 24680 26439 
s 1040
f 24680 33161 33156 
s 1026
f 33162 21942 26299 
s 516
f 21942 33162 33186 
s 544
f 21681 33163 33280 
s 1040
f 33163 21681 26200 
f 33159 33164 24657 
s 544
f 27021 24657 33164 
s 516
f 33165 26971 24513 
s 1026
f 26971 33165 33227 
s 516
f 29013 24771 33166 
s 1026
f 33313 33166 24771 
s 264
f 22494 33167 33673 
s 544
f 33167 22494 27102 
f 26331 24478 33168 
s 1040
f 33804 33168 24478 
s 544
f 33164 33169 27021 
s 1040
f 21947 27021 33169 
s 544
f 27017 33170 33123 
s 1040
f 33170 27017 23758 
s 544
f 32721 33171 24847 
s 1040
f 23107 24847 33171 
s 544
f 33168 33172 26331 
s 1040
f 21704 26331 33172 
f 33169 33173 21947 
s 544
f 26087 21947 33173 
s 1040
f 26200 33174 33163 
s 544
f 33174 26200 24073 
s 1040
f 33557 33175 24668 
s 544
f 28404 24668 33175 
s 1026
f 32299 33176 23267 
s 516
f 28123 23267 33176 
s 544
f 33097 33177 26931 
s 1040
f 21744 26931 33177 
s 516
f 26497 23197 33178 
s 257
f 33734 33178 23197 
s 1040
f 33172 33179 21704 
s 544
f 26915 21704 33179 
f 33173 33180 26087 
s 1040
f 24486 26087 33180 
s 516
f 21934 26924 33181 
s 1026
f 33119 33181 26924 
s 544
f 33179 33182 26915 
s 1040
f 23443 26915 33182 
s 516
f 26056 23223 33183 
s 1026
f 33610 33183 23223 
s 1040
f 33180 33184 24486 
s 544
f 26336 24486 33184 
f 23558 33185 33316 
s 1040
f 33185 23558 27042 
s 516
f 33186 26937 21942 
s 1026
f 26937 33186 33104 
s 1040
f 33187 24073 28052 
s 544
f 24073 33187 33174 
s 1040
f 33158 33188 24059 
s 544
f 26337 24059 33188 
s 516
f 33214 33189 22168 
s 1026
f 24802 22168 33189 
s 544
f 33190 24514 28398 
s 1040
f 24514 33190 33128 
f 33182 33191 23443 
s 544
f 25946 23443 33191 
f 33184 33192 26336 
s 1040
f 21946 26336 33192 
s 544
f 26936 21946 33193 
s 1040
f 33192 33193 21946 
f 27042 33194 33185 
s 544
f 33194 27042 21629 
s 1040
f 33177 33195 21744 
s 544
f 26786 21744 33195 
f 33193 33196 26936 
s 1040
f 23453 26936 33196 
f 23233 24919 33197 
s 544
f 32756 33197 24919 
s 264
f 33082 33198 26068 
s 544
f 23151 26068 33198 
s 1040
f 33472 33199 21867 
s 544
f 26934 21867 33199 
s 1040
f 24661 33200 33101 
s 544
f 33200 24661 26115 
s 1040
f 23357 33201 32739 
s 544
f 33201 23357 28163 
s 1026
f 24879 21963 33202 
s 516
f 33212 33202 21963 
s 1026
f 33215 33203 24814 
s 516
f 27377 24814 33203 
s 1040
f 33204 21917 25024 
s 544
f 21917 33204 33630 
s 516
f 26049 21809 33205 
s 1026
f 32926 33205 21809 
s 1040
f 33098 33206 26960 
s 544
f 22188 26960 33206 
s 1040
f 26145 33207 32045 
s 544
f 33207 26145 23053 
s 516
f 24845 27040 33208 
s 1026
f 33808 33208 27040 
s 544
f 24922 33209 32731 
s 1040
f 33209 24922 21784 
s 544
f 26115 33210 33200 
s 1040
f 33210 26115 23069 
s 516
f 33211 23221 26053 
s 1026
f 23221 33211 33062 
s 516
f 21963 26095 33212 
s 1026
f 33221 33212 26095 
s 1040
f 33213 23053 24448 
s 544
f 23053 33213 33207 
s 516
f 22168 26928 33214 
s 1026
f 33273 33214 26928 
f 24814 21707 33215 
s 516
f 33226 33215 21707 
s 1040
f 32307 33216 24724 
s 544
f 28397 24724 33216 
f 33217 24448 26311 
s 1040
f 24448 33217 33213 
s 544
f 33580 33218 24519 
s 264
f 27384 24519 33218 
s 544
f 33219 23069 26327 
s 1040
f 23069 33219 33210 
f 26904 33220 32755 
s 544
f 33220 26904 21683 
s 1026
f 26095 24493 33221 
s 516
f 33242 33221 24493 
s 544
f 33199 33222 26934 
s 1040
f 23735 26934 33222 
s 544
f 26311 33223 33217 
s 1040
f 33223 26311 26962 
f 24648 33224 32716 
s 544
f 33224 24648 27013 
s 1040
f 23712 33225 32415 
s 544
f 33225 23712 26134 
s 516
f 21707 27044 33226 
s 1026
f 33247 33226 27044 
f 33227 23104 26971 
s 516
f 23104 33227 32423 
s 544
f 33206 33228 22188 
s 1040
f 24682 22188 33228 
f 26962 33229 33223 
s 544
f 33229 26962 24441 
s 516
f 33230 24815 27155 
s 1026
f 24815 33230 33249 
s 516
f 29338 24875 33231 
s 1026
f 33243 33231 24875 
s 544
f 26134 33232 33225 
s 1040
f 33232 26134 24670 
f 26197 33233 33569 
s 544
f 33233 26197 22070 
f 26128 33234 33074 
s 1040
f 33234 26128 24737 
s 516
f 33235 24074 26204 
s 1026
f 24074 33235 33800 
f 24864 33236 33254 
s 516
f 33236 24864 29322 
s 544
f 26327 33237 33219 
s 1040
f 33237 26327 26961 
s 516
f 26024 33238 33019 
s 257
f 33238 26024 25963 
s 544
f 24441 33239 33229 
s 1040
f 33239 24441 21640 
s 544
f 21683 33240 33220 
s 1040
f 33240 21683 24678 
f 22034 33241 33634 
s 544
f 33241 22034 26126 
s 516
f 24493 26305 33242 
s 1026
f 33262 33242 26305 
f 24875 21782 33243 
s 516
f 33251 33243 21782 
s 544
f 33723 33244 23556 
s 1040
f 27041 23556 33244 
f 20878 33245 33508 
s 544
f 33245 20878 29461 
s 1026
f 33246 24494 26097 
s 516
f 24494 33246 32614 
s 1026
f 27044 23700 33247 
s 516
f 33263 33247 23700 
s 1040
f 21640 33248 33239 
s 544
f 33248 21640 25954 
s 516
f 21912 33249 33267 
s 1026
f 33249 21912 24815 
s 544
f 26126 33250 33241 
s 1040
f 33250 26126 24499 
s 516
f 21782 26127 33251 
s 1026
f 33258 33251 26127 
s 544
f 33595 33252 24810 
s 1040
f 22006 24810 33252 
f 33244 33253 27041 
s 544
f 21632 27041 33253 
s 1026
f 33254 21970 24864 
s 516
f 21970 33254 33272 
s 1040
f 21786 33255 32416 
s 544
f 33255 21786 26938 
s 1040
f 26961 33256 33237 
s 544
f 33256 26961 24439 
s 1040
f 33621 33257 23041 
s 544
f 26546 23041 33257 
s 1026
f 26127 24492 33258 
s 516
f 33270 33258 24492 
s 544
f 33425 33259 26935 
s 1040
f 26935 33259 33048 
s 544
f 33253 33260 21632 
s 1040
f 24666 21632 33260 
s 544
f 33261 24678 26795 
s 1040
f 24678 33261 33240 
s 1026
f 26305 21962 33262 
s 516
f 33282 33262 21962 
f 23700 26116 33263 
s 1026
f 33283 33263 26116 
s 544
f 25954 33264 33248 
s 264
f 33264 25954 27367 
s 1040
f 33252 33265 22006 
s 544
f 27033 22006 33265 
s 1040
f 33228 33266 24682 
s 544
f 26670 24682 33266 
s 1026
f 27046 33267 33285 
s 516
f 33267 27046 21912 
s 544
f 26325 33268 33637 
s 1040
f 33268 26325 22166 
f 33269 26938 23498 
s 544
f 26938 33269 33255 
s 516
f 24492 26307 33270 
s 1026
f 33277 33270 26307 
s 1040
f 33260 33271 24666 
s 544
f 26106 24666 33271 
s 516
f 33272 26086 21970 
s 1026
f 26086 33272 33290 
f 26928 23980 33273 
s 516
f 32884 33273 23980 
s 544
f 33274 22166 26926 
s 1040
f 22166 33274 33268 
s 544
f 24439 33275 33256 
s 1040
f 33275 24439 21627 
s 544
f 33276 23498 25980 
s 1040
f 23498 33276 33269 
s 1026
f 26307 22125 33277 
s 516
f 33288 33277 22125 
s 544
f 33048 33278 23542 
s 264
f 23542 33278 32577 
s 544
f 33271 33279 26106 
s 1040
f 24477 26106 33279 
s 264
f 26033 33280 33693 
s 544
f 33280 26033 21681 
s 516
f 26901 33281 33069 
s 1026
f 33281 26901 24627 
s 516
f 21962 26918 33282 
s 1026
f 33294 33282 26918 
f 26116 24500 33283 
s 516
f 33295 33283 24500 
s 544
f 33265 33284 27033 
s 1040
f 24449 27033 33284 
s 1026
f 33285 23701 27046 
s 516
f 23701 33285 33298 
s 544
f 26926 33286 33274 
s 1040
f 33286 26926 26496 
s 544
f 22141 33287 32421 
s 1040
f 33287 22141 26191 
s 516
f 22125 26919 33288 
s 1026
f 33293 33288 26919 
s 1040
f 33279 33289 24477 
s 544
f 26344 24477 33289 
s 1026
f 33290 24568 26086 
s 516
f 24568 33290 33302 
s 1040
f 21627 33291 33275 
s 544
f 33291 21627 25965 
f 25980 33292 33276 
s 264
f 33292 25980 27504 
s 1026
f 26919 23450 33293 
s 516
f 32660 33293 23450 
s 1026
f 26918 23448 33294 
s 516
f 33307 33294 23448 
f 24500 26324 33295 
s 1026
f 33308 33295 26324 
s 1040
f 27039 23557 33296 
s 544
f 32704 33296 23557 
s 1040
f 33284 33297 24449 
s 544
f 26141 24449 33297 
s 516
f 33298 26088 23701 
s 1026
f 26088 33298 33310 
s 544
f 23538 33299 32730 
s 264
f 33299 23538 23307 
s 544
f 26903 21631 33300 
s 1040
f 32514 33300 21631 
f 33296 33301 27039 
s 544
f 21725 27039 33301 
s 516
f 33302 26294 24568 
s 1026
f 26294 33302 33315 
s 1040
f 24737 33303 33234 
s 544
f 33303 24737 28391 
f 25965 33304 33291 
s 264
f 33304 25965 26016 
s 544
f 33300 33305 26903 
s 1040
f 23442 26903 33305 
s 544
f 33301 33306 21725 
s 1040
f 24659 21725 33306 
s 516
f 23448 25956 33307 
s 257
f 33638 33307 25956 
s 1026
f 26324 21708 33308 
s 516
f 33120 33308 21708 
s 544
f 33297 33309 26141 
s 1040
f 22007 26141 33309 
s 1026
f 33310 24754 26088 
s 516
f 24754 33310 33150 
s 1040
f 33305 33311 23442 
s 544
f 25967 23442 33311 
f 33312 25046 29176 
s 1040
f 25046 33312 33093 
f 33166 33313 23947 
s 544
f 26830 23947 33313 
s 1040
f 33306 33314 24659 
s 544
f 26140 24659 33314 
s 1026
f 33315 22180 26294 
s 516
f 22180 33315 33141 
s 544
f 33316 26016 23558 
s 264
f 26016 33316 33304 
s 544
f 33314 33317 26140 
s 1040
f 23539 26140 33317 
s 264
f 25923 26023 33318 
s 544
f 33458 33318 26023 
s 516
f 21957 26113 33319 
s 1026
f 33337 33319 26113 
s 516
f 33321 33320 24645 
s 1026
f 27099 24645 33320 
s 516
f 24645 27014 33321 
s 1026
f 33323 33321 27014 
s off
f 22073 33322 21043 
f 33322 24115 21043 
s 1026
f 27014 21991 33323 
s 516
f 33326 33323 21991 
s 1040
f 29607 22013 33324 
s 544
f 33738 33324 22013 
f 26957 24435 33325 
s 1040
f 33332 33325 24435 
s 516
f 21991 26125 33326 
s 1026
f 33327 33326 26125 
f 26125 24014 33327 
s 516
f 33329 33327 24014 
s 544
f 28119 22065 33328 
s 1040
f 32427 33328 22065 
s 516
f 24014 26314 33329 
s 1026
f 32776 33329 26314 
s 544
f 33330 24871 29198 
s 1040
f 24871 33330 32668 
s 544
f 27312 23471 33331 
s 1040
f 33333 33331 23471 
f 24435 21623 33332 
s 544
f 32665 33332 21623 
s 1040
f 23471 25026 33333 
s 544
f 32141 33333 25026 
s 516
f 26267 24460 33334 
s 1026
f 33728 33334 24460 
f 28947 22386 33335 
s 516
f 32619 33335 22386 
f 22090 33336 33642 
s 1026
f 33336 22090 24874 
f 26113 24489 33337 
s 516
f 33356 33337 24489 
s 257
f 26721 23112 33338 
s 516
f 32724 33338 23112 
s 544
f 22519 26970 33339 
s 1040
f 33824 33339 26970 
s 544
f 33340 21581 26017 
s 1040
f 21581 33340 33341 
f 33341 24700 21581 
s 544
f 24700 33341 33771 
s off
f 29634 33342 32684 
f 33342 29634 21100 
s 1026
f 24763 21841 33343 
s 516
f 32698 33343 21841 
s 257
f 25964 33344 33645 
s 516
f 33344 25964 22105 
f 27381 24818 33345 
s 1026
f 33346 33345 24818 
f 24818 21711 33346 
s 516
f 33353 33346 21711 
s 544
f 33347 24582 29012 
s 1040
f 24582 33347 33349 
s 544
f 33348 26084 23282 
s 1040
f 26084 33348 33712 
f 33349 21579 24582 
s 544
f 21579 33349 32669 
f 33350 26975 22203 
s 1040
f 26975 33350 33351 
f 33351 23736 26975 
s 544
f 23736 33351 32557 
s off
f 21693 26416 33352 
f 31520 33352 26416 
s 516
f 21711 27009 33353 
s 1026
f 33357 33353 27009 
s 544
f 26017 33354 33340 
s 264
f 33354 26017 25974 
s 1040
f 24580 22012 33355 
s 544
f 33758 33355 22012 
s 516
f 24489 26326 33356 
s 1026
f 33381 33356 26326 
f 27009 23696 33357 
s 516
f 33358 33357 23696 
f 23696 26124 33358 
s 1026
f 33360 33358 26124 
s 264
f 33359 23389 26608 
s 544
f 23389 33359 33683 
s 1026
f 26124 24567 33360 
s 516
f 33361 33360 24567 
f 24567 26296 33361 
s 1026
f 33646 33361 26296 
s 516
f 26058 23152 33362 
s 1026
f 33364 33362 23152 
f 33363 26268 24851 
s 516
f 26268 33363 33741 
s 1026
f 23152 24655 33364 
s 516
f 33365 33364 24655 
f 24655 27010 33365 
s 1026
f 33366 33365 27010 
s 516
f 33368 33366 22108 
s 1026
f 27010 22108 33366 
s 516
f 33367 24819 27211 
s 1026
f 24819 33367 33369 
s 516
f 22108 26090 33368 
s 1026
f 33370 33368 26090 
f 33369 21695 24819 
s 516
f 21695 33369 33371 
s 1026
f 26090 24487 33370 
s 516
f 33374 33370 24487 
f 33371 27019 21695 
s 1026
f 27019 33371 33375 
s 516
f 33372 21908 29666 
s 1026
f 21908 33372 33785 
f 28807 23473 33373 
s 516
f 32672 33373 23473 
f 24487 26332 33374 
s 1026
f 33377 33374 26332 
f 33375 23697 27019 
s 516
f 23697 33375 33378 
s 1040
f 22025 33376 32675 
s 544
f 33376 22025 28118 
s 1026
f 26332 22107 33377 
s 516
f 33379 33377 22107 
f 33378 26123 23697 
s 1026
f 26123 33378 33380 
s 516
f 22107 26917 33379 
s 1026
f 33591 33379 26917 
s 516
f 24479 33380 33382 
s 1026
f 33380 24479 26123 
f 26326 21956 33381 
s 516
f 33399 33381 21956 
f 33382 26306 24479 
s 1026
f 26306 33382 33383 
f 33383 21697 26306 
s 516
f 21697 33383 33384 
f 33384 26932 21697 
s 1026
f 26932 33384 33387 
s 516
f 33385 24813 27379 
s 1026
f 24813 33385 33388 
s 1040
f 33386 27007 24080 
s 544
f 27007 33386 33036 
s 1026
f 33387 23444 26932 
s 516
f 23444 33387 33389 
s 1026
f 33388 21888 24813 
s 516
f 21888 33388 33394 
f 33389 25928 23444 
s 257
f 25928 33389 32818 
s 544
f 33390 23470 26261 
s 1040
f 23470 33390 32771 
s 516
f 26261 23470 33391 
s 1026
f 33721 33391 23470 
s 1040
f 33392 23117 28068 
s 544
f 25897 23117 33392 
s off
f 31526 33393 20945 
f 21132 20945 33393 
s 516
f 33394 27011 21888 
s 1026
f 27011 33394 33396 
s 1040
f 33395 25920 21614 
s 544
f 24097 25920 33395 
s 1026
f 33396 24450 27011 
s 516
f 24450 33396 33402 
s off
f 22697 20948 33397 
f 20948 31476 33397 
f 31516 33398 26412 
f 21653 26412 33398 
s 516
f 21956 26933 33399 
s 1026
f 33415 33399 26933 
s 1040
f 26085 24058 33400 
s 544
f 33428 33400 24058 
s 1040
f 33401 27008 23349 
s 544
f 27008 33401 33110 
s 1026
f 26122 33402 33405 
s 516
f 33402 26122 24450 
s 1040
f 33403 26082 21606 
s 544
f 26082 33403 33040 
s 516
f 33404 21835 29316 
s 1026
f 21835 33404 32320 
s 516
f 23052 33405 33409 
s 1026
f 33405 23052 26122 
s 516
f 26913 21605 33406 
s 1026
f 33789 33406 21605 
f 33407 23551 24868 
s 516
f 23551 33407 33410 
s 544
f 32792 33408 21605 
s 1040
f 21605 33408 32807 
s 516
f 33409 26310 23052 
s 1026
f 26310 33409 33413 
s 516
f 33410 26309 23551 
s 1026
f 26309 33410 32287 
s 544
f 26041 21761 33411 
s 1040
f 33794 33411 21761 
s 1026
f 27113 26196 33412 
s 516
f 33648 33412 26196 
s 1026
f 33413 24510 26310 
s 516
f 24510 33413 33416 
s off
f 31517 33414 26404 
f 22238 26404 33414 
s 516
f 33749 33415 23452 
s 1026
f 26933 23452 33415 
s 516
f 33416 26927 24510 
s 1026
f 26927 33416 33567 
s 544
f 32957 33417 26943 
s 1040
f 26943 33417 33117 
s 516
f 21871 26349 33418 
s 1026
f 33420 33418 26349 
s 516
f 33419 25913 23416 
s 1026
f 25913 33419 33700 
f 26349 24088 33420 
s 516
f 32697 33420 24088 
f 27008 21606 33421 
s 1026
f 33725 33421 21606 
s 1040
f 29073 23530 33422 
s 544
f 33426 33422 23530 
s 257
f 32699 33423 23010 
s 516
f 26489 23010 33423 
f 33424 24821 29665 
s 1026
f 24821 33424 33706 
s 1040
f 33425 21907 24695 
s 544
f 26935 21907 33425 
f 23530 25917 33426 
s 264
f 33427 33426 25917 
f 25917 26041 33427 
s 544
f 33411 33427 26041 
f 24058 26298 33428 
s 1040
f 33049 33428 26298 
s 544
f 33429 24923 27208 
s 1040
f 24923 33429 33430 
f 33430 21845 24923 
s 544
f 21845 33430 32929 
s 516
f 26082 24643 33431 
s 1026
f 32058 33431 24643 
s off
f 33433 33452 33443 
f 31469 33433 33439 
f 33443 33439 33433 
s 544
f 33434 23981 27572 
s 1040
f 23981 33434 32937 
s off
f 33449 33435 33437 
f 33441 33435 22532 
f 21461 22209 33436 
f 33436 22209 29806 
f 20944 33437 33435 
s 1040
f 33438 21847 24801 
s 544
f 21847 33438 33751 
s off
f 22532 33439 33441 
f 33441 33439 33443 
f 20990 33432 33440 
f 33440 33432 20944 
f 33435 33441 20944 
f 20944 33441 33440 
s 516
f 27007 21613 33442 
s 1026
f 33756 33442 21613 
s off
f 33526 33440 33443 
f 33443 33440 33441 
f 21765 20979 33444 
f 33447 33445 27510 
f 33445 21073 27510 
f 21179 33446 33453 
f 27510 33444 33447 
f 33448 33451 21168 
f 33449 33448 28002 
f 33437 21044 33449 
f 33448 33449 21044 
f 33450 21106 20968 
f 21036 21106 33450 
f 21044 33451 33448 
f 33453 21202 21179 
f 33452 33433 21202 
f 33452 33453 21201 
f 21202 33453 33452 
s 516
f 23117 25897 33454 
s 1026
f 33735 33454 25897 
f 27097 25051 33455 
s 516
f 33112 33455 25051 
s off
f 21010 33456 32687 
f 33459 33505 33456 
s 544
f 33457 21811 29713 
s 1040
f 21811 33457 33799 
s 544
f 26023 23070 33458 
s 1040
f 33759 33458 23070 
s off
f 33456 21010 33459 
s 516
f 29713 21811 33460 
s 1026
f 33760 33460 21811 
s 516
f 25920 24097 33461 
s 257
f 33679 33461 24097 
s off
f 21017 33462 20931 
s 1026
f 23158 33463 33465 
s 516
f 33463 23158 26057 
s off
f 33464 31161 21522 
f 31161 33464 31708 
s 1026
f 33465 24662 23158 
s 516
f 24662 33465 33466 
f 33466 27018 24662 
s 1026
f 27018 33466 33469 
s 264
f 33467 25892 25953 
s 544
f 25892 33467 33744 
s 257
f 25953 25892 33468 
s 516
f 33745 33468 25892 
s 1026
f 33469 21977 27018 
s 516
f 21977 33469 33470 
f 33470 26121 21977 
s 1026
f 26121 33470 32321 
s 516
f 21975 33471 33474 
s 1026
f 33471 21975 24803 
s 516
f 28122 23524 33472 
s 1026
f 33199 33472 23524 
s 544
f 26192 21822 33473 
s 1040
f 32324 33473 21822 
s 516
f 33474 26929 21975 
s 1026
f 26929 33474 33476 
f 33475 24628 29111 
s 516
f 24628 33475 33053 
s 1026
f 33476 23541 26929 
s 516
f 23541 33476 33478 
s 1040
f 25264 23118 33477 
s 544
f 33479 33477 23118 
s 516
f 33478 25957 23541 
s 257
f 25957 33478 32686 
s 544
f 23118 26334 33479 
s 1040
f 32325 33479 26334 
s 1
f 33480 21175 33548 
s off
f 21175 33480 33820 
f 23161 20968 33481 
f 33810 33481 20968 
s 516
f 33482 24537 29664 
s 1026
f 24537 33482 33654 
s off
f 33809 33486 33483 
s 64
f 33483 33486 31491 
s 516
f 26036 23224 33484 
s 1026
f 33002 33484 23224 
s off
f 33485 33481 33810 
s 64
f 31490 33481 33485 
s off
f 23160 33486 20967 
f 20967 33486 33809 
s 516
f 33618 33487 23059 
s 257
f 22408 23059 33487 
s off
f 33535 33488 20953 
f 23322 33489 20953 
f 20953 33489 33535 
s 64
f 31553 33490 33489 
s 32
f 33535 33489 33490 
s 1026
f 33491 21760 29422 
s 516
f 21760 33491 33622 
s off
f 31196 33492 33822 
f 31777 33822 33492 
s 1026
f 33493 24321 27802 
s 516
f 24321 33493 33656 
f 25919 23056 33494 
s 257
f 32602 33494 23056 
s off
f 33495 33501 21222 
s 516
f 23416 33496 33419 
s 257
f 33496 23416 23077 
s off
f 33498 33497 31470 
f 33497 33498 33443 
f 33443 33498 33526 
f 33526 33498 22534 
s 64
f 21465 33499 31541 
s 32
f 33535 33828 33499 
s off
f 33500 20999 21766 
f 22534 33501 33526 
f 33501 33495 20990 
f 33509 21056 33502 
f 21281 33503 31494 
f 33503 21281 22560 
s 1026
f 24371 26203 33504 
s 516
f 33510 33504 26203 
s off
f 33505 33459 21446 
f 21446 33546 33505 
s 516
f 33506 21817 29321 
s 1026
f 21817 33506 32689 
s off
f 21086 33507 25600 
f 33507 21086 21098 
s 516
f 33508 23222 26051 
s 1026
f 23222 33508 33245 
s off
f 33502 21120 33509 
f 32682 33509 21120 
s 516
f 26203 21826 33510 
s 1026
f 32292 33510 21826 
s 516
f 33511 26341 24481 
s 1026
f 26341 33511 33513 
s 544
f 28967 24580 33512 
s 1040
f 33355 33512 24580 
s 1026
f 33513 21819 26341 
s 516
f 21819 33513 33658 
f 33514 24881 29320 
s 1026
f 24881 33514 33516 
s off
f 21198 33515 31499 
f 33515 21198 23084 
s 1026
f 33516 21961 24881 
s 516
f 21961 33516 33519 
s 1026
f 33517 23460 26945 
s 516
f 23460 33517 33659 
s off
f 27511 33518 33522 
f 33518 27511 20969 
s 1026
f 26089 33519 33520 
s 516
f 33519 26089 21961 
s 1026
f 33520 24483 26089 
s 516
f 24483 33520 33523 
s 1026
f 33536 33521 24877 
s 516
f 29319 24877 33521 
s off
f 33522 33500 27511 
s 516
f 33523 26348 24483 
s 1026
f 26348 33523 33524 
f 33524 21959 26348 
s 516
f 21959 33524 33525 
f 33525 26930 21959 
s 1026
f 26930 33525 33527 
s off
f 33440 33526 20990 
f 20990 33526 33501 
s 1026
f 33527 23456 26930 
s 516
f 23456 33527 33533 
f 33531 33528 23119 
s 1026
f 25259 23119 33528 
s 516
f 26051 33529 33508 
s 257
f 33529 26051 25968 
s 544
f 26059 23555 33530 
s 1040
f 33534 33530 23555 
s 516
f 23119 26329 33531 
s 1026
f 32285 33531 26329 
s 1040
f 21668 33532 33823 
s 544
f 33532 21668 26064 
s 257
f 25968 33533 33529 
s 516
f 33533 25968 23456 
s 1040
f 23555 27038 33534 
s 544
f 32693 33534 27038 
s off
f 33535 21465 20988 
s 32
f 33499 21465 33535 
s 1026
f 24877 21957 33536 
s 516
f 33319 33536 21957 
s 544
f 33537 25924 23532 
s 264
f 25924 33537 33538 
f 33538 26064 25924 
s 544
f 26064 33538 33532 
s off
f 33539 21031 20925 
f 29633 21031 33539 
f 21035 33540 21114 
f 20967 21114 33540 
s 544
f 24647 26139 33541 
s 1040
f 33543 33541 26139 
s 544
f 33542 22023 26066 
s 1040
f 22023 33542 32632 
f 26139 23068 33543 
s 544
f 32662 33543 23068 
s 516
f 21620 25904 33544 
s 1026
f 32062 33544 25904 
s off
f 26413 33545 31535 
f 33545 26413 21692 
f 33505 33546 21131 
s 8
f 21131 33546 33639 
s off
f 21222 33547 28002 
f 33547 21222 33501 
s 4
f 21177 33548 21176 
f 21176 33548 21175 
s off
f 26414 33549 31551 
f 33549 26414 21690 
f 33550 26406 22241 
f 26406 33550 31556 
f 21689 26417 33551 
f 31518 33551 26417 
s 1040
f 33655 33552 21973 
s 544
f 29419 21973 33552 
f 33553 25065 29611 
s 1040
f 25065 33553 32328 
f 33664 33554 21844 
s 544
f 28845 21844 33554 
f 21759 33555 33620 
s 1040
f 33555 21759 28037 
s 544
f 33556 24521 28914 
s 1040
f 24521 33556 33656 
s 544
f 33560 33557 24883 
s 1040
f 24668 24883 33557 
s off
f 22933 20919 33558 
f 31522 33559 26410 
f 22236 26410 33559 
s 1026
f 29428 21869 33560 
s 516
f 33557 33560 21869 
s off
f 33561 21401 20983 
f 31496 33561 20983 
s 516
f 26474 22408 33562 
s 257
f 33487 33562 22408 
s off
f 26403 33563 31550 
f 33563 26403 22240 
f 33564 20986 21340 
f 20986 33564 31498 
s 516
f 29318 21842 33565 
s 1026
f 32809 33565 21842 
s 257
f 33566 26053 23550 
s 516
f 26053 33566 33211 
s 1040
f 22198 33567 33416 
s 544
f 33567 22198 25958 
s 1026
f 33568 21799 26079 
s 516
f 21799 33568 32548 
f 33569 23486 27373 
s 1026
f 23486 33569 33233 
s 544
f 21857 26028 33570 
s 264
f 33091 33570 26028 
s 1040
f 21798 33571 33632 
s 544
f 33571 21798 26107 
s 1040
f 22089 33572 32664 
s 544
f 33572 22089 29307 
f 32621 33573 21897 
s 1040
f 26198 21897 33573 
f 24503 33574 33641 
s 544
f 33574 24503 26952 
s 516
f 33575 24090 26304 
s 1026
f 24090 33575 32748 
f 33576 26304 24684 
s 516
f 26304 33576 33575 
s 1026
f 24684 33577 33576 
s 516
f 33577 24684 28647 
s 1040
f 33578 23472 25052 
s 544
f 23472 33578 32624 
s 1040
f 25052 33579 33578 
s 544
f 33579 25052 29455 
s 1026
f 25514 22496 33580 
s 516
f 33218 33580 22496 
s 1040
f 24681 33581 33684 
s 544
f 33581 24681 26783 
s 516
f 23394 33582 33083 
s 257
f 33582 23394 25927 
s 1040
f 21808 33583 32829 
s 544
f 33583 21808 27638 
f 33685 33584 26104 
s 1040
f 24517 26104 33584 
s 516
f 33585 23044 26475 
s 257
f 23044 33585 33590 
s 1040
f 32849 33586 21739 
s 544
f 26317 21739 33586 
s 1040
f 24066 33587 32850 
s 544
f 33587 24066 26607 
f 32776 33588 26950 
s 1040
f 26502 26950 33588 
s 264
f 24081 33589 33614 
s 544
f 33589 24081 27096 
s 257
f 33590 26021 23044 
s 516
f 26021 33590 33627 
s 1040
f 33379 33591 24064 
s 544
f 26601 24064 33591 
f 27028 21999 33592 
s 1040
f 33092 33592 21999 
s 257
f 33593 26018 26609 
s 516
f 26018 33593 32752 
f 23983 25934 33594 
s 257
f 33595 33594 25934 
f 25934 23410 33595 
s 516
f 33252 33595 23410 
s 257
f 33596 26019 25933 
s 516
f 26019 33596 32798 
s 1040
f 21892 33597 32859 
s 544
f 33597 21892 26323 
s 264
f 32672 33598 23305 
s 544
f 27080 23305 33598 
f 27648 22715 33599 
s 1040
f 33608 33599 22715 
s 516
f 27283 23439 33600 
s 1026
f 32795 33600 23439 
s 1040
f 33601 22083 25751 
s 544
f 22083 33601 33046 
s 516
f 33602 23065 26043 
s 1026
f 23065 33602 32902 
s 516
f 27371 23440 33603 
s 1026
f 32802 33603 23440 
s 1040
f 33648 33604 21873 
s 544
f 27811 21873 33604 
s 516
f 32865 33605 26055 
s 257
f 25961 26055 33605 
s 1040
f 32697 33606 22490 
s 544
f 26716 22490 33606 
s 1026
f 21677 26207 33607 
s 516
f 33608 33607 26207 
s 1026
f 33599 33608 24078 
s 516
f 26207 24078 33608 
f 33609 25970 23461 
s 257
f 25970 33609 32954 
s 544
f 29454 21964 33610 
s 1040
f 33183 33610 21964 
s 516
f 28042 25893 33611 
s 1026
f 33711 33611 25893 
s 516
f 26487 23717 33612 
s 257
f 32968 33612 23717 
s 516
f 24438 26968 33613 
s 1026
f 32993 33613 26968 
s 516
f 33614 25929 23451 
s 257
f 25929 33614 33589 
s 544
f 33773 33615 21779 
s 1040
f 25053 21779 33615 
f 33615 33616 25053 
s 544
f 29303 25053 33616 
s 257
f 33617 26043 26806 
s 516
f 26043 33617 33602 
s 1040
f 29323 24033 33618 
s 544
f 33487 33618 24033 
s 516
f 33619 21592 26065 
s 1026
f 21592 33619 33123 
s 544
f 33620 26199 21759 
s 1040
f 26199 33620 33668 
s 516
f 29005 24794 33621 
s 1026
f 33257 33621 24794 
s 544
f 23310 33622 33491 
s 1040
f 33622 23310 25894 
f 21757 33623 33774 
s 544
f 33623 21757 25959 
s 264
f 33624 25937 27399 
s 544
f 25937 33624 33056 
s 516
f 33625 25972 22693 
s 257
f 25972 33625 32297 
s 1040
f 21818 33626 33657 
s 544
f 33626 21818 29453 
s 1026
f 21797 33627 33628 
s 516
f 33627 21797 26021 
f 26208 33628 33632 
s 1026
f 33628 26208 21797 
s 544
f 23729 33629 32689 
s 1040
f 33629 23729 25416 
f 27005 33630 32300 
s 544
f 33630 27005 21917 
f 33631 24704 27089 
s 264
f 24704 33631 33659 
s 516
f 33632 24079 26208 
s 1026
f 24079 33632 33571 
s 257
f 25963 33633 33238 
s 516
f 33633 25963 23446 
s 544
f 27013 33634 33224 
s 1040
f 33634 27013 22034 
s 516
f 33011 33635 26022 
s 257
f 25930 26022 33635 
s 1040
f 24695 33636 33425 
s 544
f 32052 33636 24695 
s 1040
f 24499 33637 33250 
s 544
f 33637 24499 26325 
s 257
f 25956 26056 33638 
s 516
f 33183 33638 26056 
s off
f 21446 22933 33639 
f 33558 33639 22933 
s 516
f 33640 24874 29317 
s 1026
f 24874 33640 33336 
s 516
f 33641 26350 24091 
s 1026
f 26350 33641 33574 
f 26092 33642 32667 
s 516
f 33642 26092 22090 
f 28773 24763 33643 
s 1026
f 33343 33643 24763 
f 33644 22105 28796 
s 516
f 22105 33644 33344 
s 257
f 33645 26071 25964 
s 516
f 26071 33645 32664 
s 1026
f 26296 21712 33646 
s 516
f 32846 33646 21712 
s off
f 21377 26409 33647 
f 31515 33647 26409 
s 516
f 26196 21870 33648 
s 1026
f 33604 33648 21870 
s 516
f 28045 25260 33649 
s 1026
f 32680 33649 25260 
s 544
f 33650 25931 22717 
s 264
f 25931 33650 33651 
s 544
f 26066 33651 33542 
s 264
f 33651 26066 25931 
s off
f 22565 33652 20930 
f 33653 26415 21654 
f 26415 33653 31545 
s 1026
f 33654 21617 24537 
s 516
f 21617 33654 33762 
f 26027 23730 33655 
s 1026
f 33552 33655 23730 
f 26354 33656 33556 
s 516
f 33656 26354 24321 
f 33657 24093 27378 
s 1026
f 24093 33657 33626 
s 516
f 33658 26945 21819 
s 1026
f 26945 33658 33517 
s 257
f 25925 33659 33631 
s 516
f 33659 25925 23460 
s off
f 31497 33660 21189 
f 33660 23087 21189 
f 20935 33661 21018 
f 22564 33662 20934 
f 26411 33663 31543 
f 33663 26411 21691 
s 516
f 23109 26297 33664 
s 1026
f 33554 33664 26297 
f 26935 26074 33665 
s 516
f 32726 33665 26074 
s 264
f 24082 33666 33133 
s 544
f 33666 24082 27070 
s 1040
f 33222 33667 23735 
s 544
f 26780 23735 33667 
s 1026
f 23297 33668 33620 
s 516
f 33668 23297 27358 
s 544
f 33669 24806 29452 
s 1040
f 24806 33669 33764 
s 516
f 33670 23550 26493 
s 257
f 23550 33670 33566 
s 1026
f 26335 33671 33018 
s 516
f 33671 26335 23105 
s 1040
f 22020 33672 33766 
s 544
f 33672 22020 26109 
s 516
f 33673 23657 26513 
s 257
f 23657 33673 33167 
s 1040
f 24667 33674 32707 
s 544
f 33674 24667 28380 
f 33675 22018 26914 
s 1040
f 22018 33675 33770 
f 23496 33676 32711 
s 544
f 33676 23496 25962 
f 25962 33677 33676 
s 264
f 33677 25962 23350 
s 516
f 33678 21647 28774 
s 1026
f 21647 33678 33032 
s 264
f 33461 33679 24703 
s 544
f 27094 24703 33679 
s 1040
f 32335 33680 21895 
s 544
f 26956 21895 33680 
f 26107 33681 33571 
s 1040
f 33681 26107 28139 
s 516
f 33824 33682 25921 
s 257
f 23726 25921 33682 
s 516
f 24020 33683 33359 
s 1026
f 33683 24020 29173 
s 1040
f 33684 22404 24681 
s 544
f 22404 33684 32612 
s 1040
f 32397 33685 21731 
s 544
f 26104 21731 33685 
s 1040
f 32332 33686 21607 
s 544
f 28912 21607 33686 
f 33687 25025 29451 
s 1040
f 25025 33687 33739 
f 21660 33688 32890 
s 544
f 33688 21660 26118 
f 33689 24687 28911 
s 1040
f 24687 33689 32294 
s 544
f 33390 33690 23416 
s 264
f 23077 23416 33690 
s 257
f 26782 23060 33691 
s 516
f 33136 33691 23060 
s 264
f 23136 33692 33742 
s 544
f 33692 23136 27093 
s 516
f 33693 23108 26495 
s 257
f 23108 33693 33280 
s 1026
f 33694 24846 23415 
s 516
f 24846 33694 32248 
s 264
f 33707 33695 23386 
s 544
f 27101 23386 33695 
s 264
f 33708 33696 23385 
s 544
f 27076 23385 33696 
f 32934 33697 23264 
s 264
f 24011 23264 33697 
s 516
f 26044 23731 33698 
s 1026
f 32348 33698 23731 
s 516
f 27197 24113 33699 
s 1026
f 33063 33699 24113 
s 1040
f 21877 33700 33419 
s 544
f 33700 21877 29449 
s 1040
f 24849 33701 32880 
s 544
f 33701 24849 28378 
f 33789 33702 25945 
s 264
f 27299 25945 33702 
s 544
f 33703 24686 28910 
s 1040
f 24686 33703 33747 
s 1026
f 23441 33704 32830 
s 516
f 33704 23441 27297 
s 264
f 24007 33705 32917 
s 544
f 33705 24007 27081 
s 1040
f 21876 33706 33424 
s 544
f 33706 21876 26778 
s 516
f 23445 25978 33707 
s 257
f 33695 33707 25978 
s 516
f 23455 25941 33708 
s 257
f 33696 33708 25941 
s 516
f 26048 21677 33709 
s 1026
f 33607 33709 21677 
s 257
f 23979 33710 33754 
s 516
f 33710 23979 27078 
s 1026
f 25893 21678 33711 
s 516
f 32879 33711 21678 
s 1026
f 23228 33712 33348 
s 516
f 33712 23228 28355 
s 257
f 25951 26031 33713 
s 516
f 32947 33713 26031 
s 264
f 33076 33714 23398 
s 544
f 27085 23398 33714 
s 1040
f 33753 33715 21615 
s 544
f 29447 21615 33715 
s 257
f 25940 26048 33716 
s 516
f 33709 33716 26048 
f 23120 26313 33717 
s 1026
f 33037 33717 26313 
s 516
f 24434 26940 33718 
s 1026
f 33043 33718 26940 
s 544
f 23077 33719 33117 
s 264
f 33690 33719 23077 
s 516
f 21649 25969 33720 
s 257
f 32566 33720 25969 
s 1040
f 33391 33721 21814 
s 544
f 29446 21814 33721 
s 1040
f 33745 33722 21979 
s 544
f 29444 21979 33722 
s 264
f 33132 33723 26052 
s 544
f 23556 26052 33723 
s 264
f 23307 33724 33299 
s 544
f 33724 23307 27092 
s 1040
f 33421 33725 24308 
s 544
f 27847 24308 33725 
f 33726 24685 28909 
s 1040
f 24685 33726 32333 
f 33058 33727 24850 
s 544
f 28376 24850 33727 
s 1040
f 33334 33728 23229 
s 544
f 28375 23229 33728 
s 1040
f 33765 33729 24511 
s 544
f 26925 24511 33729 
s 516
f 24706 33730 32959 
s 257
f 33730 24706 25955 
s 1040
f 33089 33731 23699 
s 544
f 26114 23699 33731 
s 1040
f 33154 33732 21815 
s 544
f 26537 21815 33732 
s 1040
f 21750 33733 32606 
s 544
f 33733 21750 28975 
s 257
f 23197 26046 33734 
s 516
f 33126 33734 26046 
s 1040
f 33454 33735 24688 
s 544
f 28908 24688 33735 
s 1040
f 33816 33736 21855 
s 544
f 26726 21855 33736 
s 257
f 23542 23055 33737 
s 516
f 32706 33737 23055 
s 544
f 22013 26085 33738 
s 1040
f 33400 33738 26085 
s 516
f 33739 23061 28620 
s 1026
f 23061 33739 33687 
s 516
f 33740 24851 29712 
s 1026
f 24851 33740 33363 
s 516
f 33741 21608 26268 
s 1026
f 21608 33741 32060 
s 516
f 33742 24536 28066 
s 257
f 24536 33742 33692 
s off
f 33436 33743 21461 
f 21461 33743 21470 
s 544
f 33744 23349 25892 
s 1040
f 23349 33744 33401 
s 516
f 25892 23349 33745 
s 1026
f 33722 33745 23349 
s 516
f 33746 21903 27006 
s 1026
f 21903 33746 33814 
f 21813 33747 33703 
s 516
f 33747 21813 29624 
f 33748 23077 28069 
s 257
f 23077 33748 33496 
s 516
f 23452 25935 33749 
s 257
f 33757 33749 25935 
s 1040
f 24801 33750 33438 
s 544
f 33750 24801 28116 
f 33751 26974 21847 
s 1040
f 26974 33751 32945 
f 24080 33752 33386 
s 544
f 33752 24080 28619 
s 516
f 28619 24080 33753 
s 1026
f 33715 33753 24080 
s 544
f 33754 23659 26491 
s 264
f 23659 33754 33710 
s 1040
f 33755 25591 21613 
s 544
f 25591 33755 32055 
s 1026
f 21613 25591 33756 
s 516
f 33058 33756 25591 
s 257
f 25935 26036 33757 
s 516
f 33484 33757 26036 
s 544
f 22012 25923 33758 
s 264
f 33318 33758 25923 
s 1040
f 23070 24804 33759 
s 544
f 33031 33759 24804 
s 1026
f 21811 26267 33760 
s 516
f 33334 33760 26267 
s 1026
f 33761 21619 25901 
s 516
f 21619 33761 33044 
f 33762 25907 21617 
s 257
f 25907 33762 32584 
s off
f 33763 21282 22557 
f 21282 33763 31548 
s 1026
f 23671 33764 33669 
s 516
f 33764 23671 26061 
f 26290 21812 33765 
s 1026
f 33729 33765 21812 
s 516
f 33766 22208 29714 
s 1026
f 22208 33766 33672 
f 26015 24442 33767 
s 516
f 32710 33767 24442 
s 1040
f 33768 21620 27034 
s 544
f 21620 33768 32054 
s 1026
f 27034 21620 33769 
s 516
f 33544 33769 21620 
s 1026
f 21616 33770 33675 
s 516
f 33770 21616 29623 
f 24870 33771 33341 
s 1026
f 33771 24870 21582 
s 516
f 23553 25938 33772 
s 257
f 32984 33772 25938 
s 264
f 32660 33773 26035 
s 544
f 21779 26035 33773 
s 516
f 33774 22572 28772 
s 1026
f 22572 33774 33623 
s 264
f 23350 33775 33677 
s 544
f 33775 23350 27088 
s 257
f 33776 24009 26572 
s 516
f 24009 33776 33090 
s 1040
f 32245 33777 22120 
s 544
f 28974 22120 33777 
s 1040
f 32722 33778 21881 
s 544
f 29275 21881 33778 
s 516
f 27259 24372 33779 
s 1026
f 33790 33779 24372 
s 1040
f 32137 33780 26143 
s 544
f 23092 26143 33780 
s 1040
f 23306 33781 32759 
s 544
f 33781 23306 28206 
f 24761 33782 32249 
s 1040
f 33782 24761 21901 
s 544
f 26118 33783 33688 
s 1040
f 33783 26118 25357 
f 33022 33784 21652 
s 544
f 27624 21652 33784 
s 1040
f 22230 33785 33372 
s 544
f 33785 22230 26775 
s 516
f 21880 26973 33786 
s 1026
f 33825 33786 26973 
s 1040
f 32794 33787 21665 
s 544
f 26356 21665 33787 
s 516
f 33788 23415 27331 
s 1026
f 23415 33788 33694 
s 1040
f 33406 33789 23474 
s 544
f 25945 23474 33789 
s 1026
f 24372 26206 33790 
s 516
f 33022 33790 26206 
s 544
f 32155 33791 24579 
s 1040
f 22093 24579 33791 
f 21830 33792 32872 
s 544
f 33792 21830 29274 
f 33793 26144 23232 
s 1040
f 26144 33793 32267 
s 1026
f 33411 33794 26193 
s 516
f 22584 26193 33794 
f 28049 25263 33795 
s 1026
f 33027 33795 25263 
s 264
f 33796 25971 27403 
s 544
f 25971 33796 32905 
s 516
f 23048 25951 33797 
s 257
f 33713 33797 25951 
s 1026
f 26972 33798 32905 
s 516
f 33798 26972 24512 
s 1040
f 21903 24844 33799 
s 544
f 32053 33799 24844 
s 1040
f 21648 33800 33235 
s 544
f 33800 21648 27804 
s 1026
f 33801 22130 25078 
s 516
f 22130 33801 32208 
f 33802 25261 28053 
s 1026
f 25261 33802 33102 
f 33803 26204 21645 
s 516
f 26204 33803 33235 
s 544
f 33731 33804 26114 
s 1040
f 24478 26114 33804 
s 544
f 33805 23728 27086 
s 264
f 23728 33805 33831 
s 544
f 33191 33806 25946 
s 264
f 27385 25946 33806 
s 1026
f 21645 33807 33803 
s 516
f 33807 21645 26067 
s 1026
f 27040 23356 33808 
s 516
f 32794 33808 23356 
s off
f 20953 21001 33809 
f 33809 21001 20967 
f 20968 21000 33810 
f 33810 21000 20988 
f 33160 33811 30086 
f 30086 33811 31884 
s 544
f 32807 33812 25953 
s 264
f 25953 33812 33467 
s off
f 31552 31622 33813 
f 22485 33813 31622 
s 1026
f 33814 24844 21903 
s 516
f 24844 33814 32880 
s 544
f 32057 33815 21614 
s 1040
f 21614 33815 33395 
s 516
f 26912 21614 33816 
s 1026
f 33736 33816 21614 
s off
f 32106 33817 32290 
f 26399 33818 31541 
f 33818 26399 21378 
f 33817 33819 31194 
f 33819 33817 31778 
f 21098 33820 33507 
f 33820 21098 21175 
s 1040
f 33821 23532 29045 
s 544
f 23532 33821 33537 
s off
f 32319 33822 32268 
s 1040
f 33823 25058 21668 
s 544
f 25058 33823 32195 
s 1026
f 33339 33824 21788 
s 516
f 25921 21788 33824 
s 1040
f 33786 33825 21924 
s 544
f 26566 21924 33825 
s 2
f 33499 33828 33826 
f 33490 33480 33826 
s 516
f 26032 33827 32820 
s 257
f 33827 26032 23691 
s 2
f 33826 33828 33490 
s 32
f 33490 33828 33535 
s 257
f 26456 26047 33829 
s 516
f 33038 33829 26047 
s off
f 31707 33830 31162 
f 21521 31162 33830 
s 257
f 25942 33831 33805 
s 516
f 33831 25942 23535 
s 1
f 33838 33839 33836 33837 
s off
f 20975 20926 33833 33832 
f 20926 21005 33834 33833 
f 21005 20943 33835 33834 
f 20943 20975 33832 33835 
f 33832 33833 33837 33836 
f 33833 33834 33838 33837 
f 33834 33835 33839 33838 
f 33835 33832 33836 33839 
s 8
f 33840 33841 33842 
f 33842 33843 33840 
s 16
f 33844 33840 33843 
f 33843 33845 33844 
s 32
f 33846 33847 33848 
f 33848 33849 33846 
s 64
f 33841 33846 33849 
f 33849 33842 33841 
s 16
f 33847 33844 33845 
f 33845 33848 33847 
s 8
f 33850 33851 33852 
f 33852 33853 33850 
s 16
f 33854 33850 33853 
f 33853 33855 33854 
s 32
f 33856 33857 33858 
f 33858 33859 33856 
s 64
f 33851 33856 33859 
f 33859 33852 33851 
s 16
f 33857 33854 33855 
f 33855 33858 33857 
s 8
f 33860 33863 33862 
f 33862 33861 33860 
s 16
f 33864 33865 33863 
f 33863 33860 33864 
s 32
f 33866 33869 33868 
f 33868 33867 33866 
s 64
f 33861 33862 33869 
f 33869 33866 33861 
s 16
f 33867 33868 33865 
f 33865 33864 33867 
s 8
f 33870 33873 33872 
f 33872 33871 33870 
s 16
f 33874 33875 33873 
f 33873 33870 33874 
s 32
f 33876 33879 33878 
f 33878 33877 33876 
s 64
f 33871 33872 33879 
f 33879 33876 33871 
s 16
f 33877 33878 33875 
f 33875 33874 33877 
s off
f 33844 33847 33846 
f 33840 33844 33846 
f 33840 33846 33841 
f 33854 33857 33856 
f 33850 33854 33856 
f 33850 33856 33851 
f 33861 33866 33867 
f 33861 33867 33864 
f 33861 33864 33860 
f 33871 33876 33877 
f 33871 33877 33874 
f 33871 33874 33870 
s 8
f 33891 33894 33893 
f 33893 33892 33891 
s 16
f 33894 33891 34463 
s 32
f 33896 33899 33898 
f 33898 33897 33896 
s 64
f 33892 33893 33899 
f 33899 33896 33892 
s off
f 33880 33938 33881 
f 33881 33938 33882 
f 33883 33994 33884 
s 16
f 33897 33898 33895 
s off
f 33882 33938 33883 
f 33880 33884 33885 
s 8
f 33900 33903 33902 
f 33902 33901 33900 
s 16
f 33903 33900 34461 
s 32
f 33905 33908 33907 
f 33907 33906 33905 
s 64
f 33901 33902 33908 
f 33908 33905 33901 
s off
f 33886 33941 33887 
f 33887 33941 33888 
f 33889 33994 33890 
s 16
f 33906 33907 33904 
s off
f 33888 33941 33889 
f 33886 33890 33885 
s 8
f 33881 33891 33892 
f 33892 33880 33881 
s 16
f 33882 34463 33891 
f 33891 33881 33882 
s 32
f 33884 33896 33897 
f 33897 33883 33884 
s 64
f 33880 33892 33896 
f 33896 33884 33880 
s 16
f 33883 33897 34463 
f 34463 33882 33883 
s 8
f 33887 33900 33901 
f 33901 33886 33887 
s 16
f 33888 34461 33900 
f 33900 33887 33888 
s 32
f 33890 33905 33906 
f 33906 33889 33890 
s 64
f 33886 33901 33905 
f 33905 33890 33886 
s 16
f 33889 33906 34461 
f 34461 33888 33889 
s 8
f 33920 33921 33922 
f 33922 33923 33920 
s 16
f 34464 33920 33923 
s 32
f 33925 33926 33927 
f 33927 33928 33925 
s 64
f 33921 33925 33928 
f 33928 33922 33921 
s off
f 33909 33910 33939 
f 33910 33911 33939 
f 33912 33913 33995 
s 16
f 33924 33927 33926 
s off
f 33911 33912 33939 
f 33909 33914 33913 
s 8
f 33929 33930 33931 
f 33931 33932 33929 
s 16
f 34462 33929 33932 
s 32
f 33934 33935 33936 
f 33936 33937 33934 
s 64
f 33930 33934 33937 
f 33937 33931 33930 
s off
f 33915 33916 33940 
f 33916 33917 33940 
f 33918 33919 33995 
s 16
f 33933 33936 33935 
s off
f 33917 33918 33940 
f 33915 33914 33919 
s 8
f 33910 33909 33921 
f 33921 33920 33910 
s 16
f 33911 33910 33920 
f 33920 34464 33911 
s 32
f 33913 33912 33926 
f 33926 33925 33913 
s 64
f 33909 33913 33925 
f 33925 33921 33909 
s 16
f 33912 33911 34464 
f 34464 33926 33912 
s 8
f 33916 33915 33930 
f 33930 33929 33916 
s 16
f 33917 33916 33929 
f 33929 34462 33917 
s 32
f 33919 33918 33935 
f 33935 33934 33919 
s 64
f 33915 33919 33934 
f 33934 33930 33915 
s 16
f 33918 33917 34462 
f 34462 33935 33918 
s off
f 33883 33938 33994 
f 33880 33994 33938 
f 33912 33995 33939 
f 33909 33939 33995 
f 33915 33940 33995 
f 33918 33995 33940 
f 33889 33941 33994 
f 33886 33994 33941 
f 33942 33955 33954 
f 33945 33954 33955 
s 2
f 33946 33947 33949 
f 33949 33948 33946 
s 1
f 33950 33951 33953 
f 33953 33952 33950 
s off
f 33954 33944 33942 
f 33955 33943 33945 
s 4
f 33956 34075 34097 
f 33961 33956 33958 
f 33956 33960 34062 
f 33961 34098 34076 
s 8
f 34159 34282 34310 
f 34146 34388 34363 
f 34312 34293 34156 
f 34357 34387 34147 
f 34387 34357 34186 
f 34408 34340 34224 
f 34187 34363 34388 
f 34223 34344 34411 
f 34362 34389 34148 
f 34240 34266 34236 
f 34150 34398 34352 
f 34239 34269 34243 
f 34367 34391 34149 
f 34241 34267 34234 
f 34151 34396 34361 
f 34235 34268 34242 
f 34321 34294 34230 
f 34315 34283 34157 
f 34229 34288 34319 
f 34158 34287 34306 
f 34207 34422 34418 
f 34153 34379 34373 
f 34419 34424 34204 
f 34369 34383 34152 
f 34208 34427 34421 
f 34155 34380 34375 
f 34420 34423 34209 
f 34370 34377 34154 
f 34328 34272 34161 
f 34394 34354 34188 
f 34191 34366 34397 
f 34163 34277 34329 
s off
f 33880 34029 33994 
f 33994 34002 33884 
f 33886 34002 33994 
f 33994 34013 33890 
f 33919 34014 33995 
f 33915 33995 34003 
f 33913 34003 33995 
f 33909 33995 34030 
s 8
f 34441 34437 34144 
f 34145 34439 34442 
f 34214 34450 34456 
f 34454 34447 34213 
f 34212 34448 34457 
f 34459 34451 34215 
s off
f 33884 34002 33885 
f 33886 33885 34002 
f 33915 34003 33914 
f 33914 34003 33913 
s 8
f 34221 34440 34438 
f 34436 34443 34218 
f 34160 34276 34322 
f 34326 34274 34162 
f 34432 34428 34194 
f 34192 34430 34435 
f 34433 34431 34195 
f 34193 34429 34434 
s 4
f 33956 34062 34012 
s off
f 33890 34013 33885 
f 33914 34014 33919 
s 8
f 34343 34404 34255 
f 34249 34407 34347 
f 34253 34405 34341 
f 34345 34406 34248 
f 34402 34350 34196 
f 34197 34348 34403 
f 34320 34286 34227 
f 34226 34295 34314 
f 34308 34290 34231 
f 34228 34291 34313 
f 34236 34262 34240 
f 34234 34263 34241 
f 34243 34264 34239 
f 34242 34265 34235 
s off
f 33880 33885 34029 
f 33909 34030 33914 
s 8
f 34259 34403 34348 
f 34256 34400 34349 
f 34350 34402 34258 
f 34351 34401 34257 
s 4
f 33961 34035 34107 
s 8
f 34211 34455 34444 
f 34445 34452 34210 
f 34178 34311 34281 
f 34280 34307 34179 
f 34292 34316 34171 
f 34174 34318 34285 
f 34169 34323 34273 
f 34167 34324 34278 
f 34279 34325 34168 
f 34275 34327 34166 
f 34382 34374 34181 
f 34183 34371 34376 
f 34189 34360 34385 
f 34399 34355 34184 
f 34164 34417 34426 
f 34425 34416 34165 
f 34173 34314 34295 
f 34286 34320 34175 
f 34290 34308 34170 
f 34172 34313 34291 
f 34270 34260 34233 
f 34232 34261 34271 
f 34205 34426 34417 
f 34416 34425 34206 
s 4
f 33956 34012 34060 
f 33961 34061 34035 
f 33960 33957 34062 
s 8
f 34334 34305 34142 
f 34141 34301 34332 
f 34140 34304 34331 
f 34330 34302 34143 
f 34177 34271 34261 
f 34260 34270 34176 
f 34246 34434 34429 
f 34431 34433 34247 
f 34237 34435 34430 
f 34428 34432 34238 
f 34340 34408 34252 
f 34254 34411 34344 
s 4
f 33956 34060 34075 
f 33961 34076 34061 
s 8
f 34220 34442 34439 
f 34437 34441 34219 
f 34386 34356 34190 
f 34185 34365 34395 
f 34381 34368 34180 
f 34182 34372 34378 
f 34415 34412 34217 
f 34216 34413 34414 
f 34244 34335 34303 
f 34300 34333 34245 
f 34201 34342 34409 
f 34410 34346 34202 
f 34404 34343 34200 
f 34203 34347 34407 
f 34401 34351 34199 
f 34198 34349 34400 
f 34406 34345 34225 
f 34222 34341 34405 
f 34250 34409 34342 
f 34346 34410 34251 
s 4
f 34460 33956 34097 
f 33961 33958 34098 
s 8
f 34099 34332 34301 
f 34100 34331 34304 
f 34302 34330 34101 
f 34305 34334 34102 
f 34298 34338 34103 
f 34104 34336 34299 
f 34297 34337 34105 
f 34106 34339 34296 
s 4
f 33959 33961 34107 
s 8
f 34338 34298 34108 
f 34109 34299 34336 
f 34110 34296 34339 
f 34337 34297 34111 
f 34333 34300 34112 
f 34113 34303 34335 
f 34443 34436 34114 
f 34115 34438 34440 
f 34116 34414 34413 
f 34412 34415 34117 
f 34118 34444 34455 
f 34452 34445 34119 
f 34120 34421 34427 
f 34423 34420 34121 
f 34122 34418 34422 
f 34424 34419 34123 
f 34124 34376 34371 
f 34368 34381 34125 
f 34126 34378 34372 
f 34374 34382 34127 
f 34354 34394 34128 
f 34129 34385 34360 
f 34130 34395 34365 
f 34356 34386 34131 
f 34355 34399 34132 
f 34133 34397 34366 
f 34294 34321 34134 
f 34135 34319 34288 
f 34136 34456 34450 
f 34451 34459 34137 
f 34447 34454 34138 
f 34139 34457 34448 
f 34065 34392 34353 
f 34064 34390 34364 
f 34359 34393 34063 
f 34358 34384 34066 
f 34446 34458 34059 
f 34058 34453 34449 
f 34057 34289 34309 
f 34317 34284 34056 
f 34307 34280 34045 
f 34316 34292 34044 
f 34043 34281 34311 
f 34042 34285 34318 
f 34327 34275 34039 
f 34038 34278 34324 
f 34325 34279 34040 
f 34041 34273 34323 
f 34274 34326 34019 
f 34272 34328 34046 
f 34047 34329 34277 
f 34020 34322 34276 
f 34006 34310 34282 
f 34283 34315 34049 
f 34293 34312 34007 
f 34048 34306 34287 
f 33985 34449 34453 
f 34458 34446 33982 
f 34383 34369 33970 
f 33973 34373 34379 
f 34377 34370 33976 
f 33979 34375 34380 
f 34393 34359 33991 
f 34391 34367 33977 
f 33988 34364 34390 
f 33990 34353 34392 
f 33981 34361 34396 
f 34384 34358 33989 
f 34284 34317 34068 
f 34067 34309 34289 
f 33975 34352 34398 
f 34389 34362 33971 
f 33966 34180 34081 
f 33992 34181 33984 
f 34082 34182 33964 
f 33986 34183 33993 
f 34018 34184 33983 
f 34080 34185 34004 
f 34096 34186 34033 
f 34031 34187 34095 
f 34015 34188 34034 
f 33987 34189 34017 
f 34005 34190 34079 
f 34032 34191 34016 
f 34009 34192 34071 
f 34011 34193 34069 
f 34072 34194 34008 
f 34070 34195 34010 
f 34088 34196 33965 
f 33962 34197 34087 
f 34092 34198 34090 
f 34089 34199 34091 
f 34091 34200 34089 
f 34087 34201 33962 
f 33965 34202 34088 
f 34090 34203 34092 
f 33972 34204 34053 
f 33997 34205 34050 
f 34051 34206 33996 
f 34055 34207 33974 
f 34052 34208 33980 
f 33978 34209 34054 
f 33967 34210 33968 
f 33969 34211 33963 
f 34071 34212 34009 
f 34010 34213 34070 
f 34069 34214 34011 
f 34008 34215 34072 
f 34084 34216 33998 
f 33999 34217 34083 
f 34037 34218 34073 
f 34093 34219 34078 
f 34077 34220 34094 
f 34074 34221 34036 
f 34094 34222 34077 
f 34036 34223 34074 
f 34073 34224 34037 
f 34078 34225 34093 
f 33980 34226 34052 
f 34053 34227 33972 
f 33974 34228 34055 
f 34050 34229 33997 
f 33996 34230 34051 
f 34054 34231 33978 
f 33963 34232 33969 
f 33968 34233 33967 
f 34263 34234 34023 
f 34022 34235 34265 
f 34262 34236 34021 
f 34000 34237 34085 
f 34086 34238 34001 
f 34024 34239 34264 
f 34266 34240 34025 
f 34267 34241 34026 
f 34028 34242 34268 
f 34027 34243 34269 
f 34085 34244 34000 
f 34001 34245 34086 
f 33998 34246 34084 
f 34083 34247 33999 
f 33983 34248 34018 
f 34016 34249 34032 
f 34095 34250 34031 
f 34033 34251 34096 
f 34079 34252 34005 
f 34017 34253 33987 
f 34004 34254 34080 
f 34034 34255 34015 
f 33993 34256 33986 
f 33984 34257 33992 
f 34081 34258 33966 
f 33964 34259 34082 
f 34176 34056 34260 
f 34261 34057 34177 
f 34021 34101 34262 
f 34023 34102 34263 
f 34264 34099 34024 
f 34265 34100 34022 
f 34025 34105 34266 
f 34026 34103 34267 
f 34268 34104 34028 
f 34269 34106 34027 
f 34233 33968 34270 
f 34271 33969 34232 
f 34046 34127 34272 
f 34273 34155 34169 
f 34019 34125 34274 
f 34166 34152 34275 
f 34276 34126 34020 
f 34277 34124 34047 
f 34278 34153 34167 
f 34168 34154 34279 
f 34179 34148 34280 
f 34281 34150 34178 
f 34282 34130 34006 
f 34049 34132 34283 
f 34068 34147 34284 
f 34285 34151 34174 
f 34175 34066 34286 
f 34287 34129 34048 
f 34288 34133 34135 
f 34289 34146 34067 
f 34170 34063 34290 
f 34291 34064 34172 
f 34171 34149 34292 
f 34007 34131 34293 
f 34134 34128 34294 
f 34295 34065 34173 
f 34296 34027 34106 
f 34105 34025 34297 
f 34103 34026 34298 
f 34299 34028 34104 
f 34245 34001 34300 
f 34301 34024 34099 
f 34101 34021 34302 
f 34303 34000 34244 
f 34304 34022 34100 
f 34102 34023 34305 
f 34306 34163 34158 
f 34045 34039 34307 
f 34231 34054 34308 
f 34309 34177 34057 
f 34310 34160 34159 
f 34311 34038 34043 
f 34156 34162 34312 
f 34313 34055 34228 
f 34314 34052 34226 
f 34157 34161 34315 
f 34044 34040 34316 
f 34056 34176 34317 
f 34318 34041 34042 
f 34319 33997 34229 
f 34227 34053 34320 
f 34230 33996 34321 
f 34322 34159 34160 
f 34323 34042 34041 
f 34324 34043 34038 
f 34040 34044 34325 
f 34162 34156 34326 
f 34039 34045 34327 
f 34161 34157 34328 
f 34329 34158 34163 
f 34143 33989 34330 
f 34331 33990 34140 
f 34332 33988 34141 
f 34112 33977 34333 
f 34142 33991 34334 
f 34335 33981 34113 
f 34336 33979 34109 
f 34111 33970 34337 
f 34108 33976 34338 
f 34339 33973 34110 
f 34252 34079 34340 
f 34341 33987 34253 
f 34342 34031 34250 
f 34255 34034 34343 
f 34344 34080 34254 
f 34248 33983 34345 
f 34251 34033 34346 
f 34347 34032 34249 
f 34348 34082 34259 
f 34349 33986 34256 
f 34258 34081 34350 
f 34257 33984 34351 
f 34352 34178 34150 
f 34353 34173 34065 
f 34128 34134 34354 
f 34132 34049 34355 
f 34131 34007 34356 
f 34147 34068 34357 
f 34066 34175 34358 
f 34063 34170 34359 
f 34360 34048 34129 
f 34361 34174 34151 
f 34148 34179 34362 
f 34363 34067 34146 
f 34364 34172 34064 
f 34365 34006 34130 
f 34366 34135 34133 
f 34149 34171 34367 
f 34125 34019 34368 
f 34152 34166 34369 
f 34154 34168 34370 
f 34371 34047 34124 
f 34372 34020 34126 
f 34373 34167 34153 
f 34127 34046 34374 
f 34375 34169 34155 
f 34376 33993 34183 
f 33976 34108 34377 
f 34378 33964 34182 
f 34379 34110 33973 
f 34380 34109 33979 
f 34180 33966 34381 
f 34181 33992 34382 
f 33970 34111 34383 
f 33989 34143 34384 
f 34385 34017 34189 
f 34190 34005 34386 
f 34186 34096 34387 
f 34388 34095 34187 
f 33971 34117 34389 
f 34390 34141 33988 
f 33977 34112 34391 
f 34392 34140 33990 
f 33991 34142 34393 
f 34188 34015 34394 
f 34395 34004 34185 
f 34396 34113 33981 
f 34397 34016 34191 
f 34398 34116 33975 
f 34184 34018 34399 
f 34400 34090 34198 
f 34199 34089 34401 
f 34196 34088 34402 
f 34403 34087 34197 
f 34200 34091 34404 
f 34405 34077 34222 
f 34225 34078 34406 
f 34407 34092 34203 
f 34224 34073 34408 
f 34409 33962 34201 
f 34202 33965 34410 
f 34411 34074 34223 
f 34117 33971 34412 
f 34413 33975 34116 
f 34414 33998 34216 
f 34217 33999 34415 
f 34206 34051 34416 
f 34417 34050 34205 
f 34418 33974 34207 
f 34204 33972 34419 
f 34209 33978 34420 
f 34421 33980 34208 
f 34422 34136 34122 
f 34121 34137 34423 
f 34123 34138 34424 
f 34165 34059 34425 
f 34426 34058 34164 
f 34427 34139 34120 
f 34238 34086 34428 
f 34429 34084 34246 
f 34430 34085 34237 
f 34247 34083 34431 
f 34194 34072 34432 
f 34195 34070 34433 
f 34434 34069 34193 
f 34435 34071 34192 
f 34218 34037 34436 
f 34219 34093 34437 
f 34438 34036 34221 
f 34439 34094 34220 
f 34440 34118 34115 
f 34144 33982 34441 
f 34442 33985 34145 
f 34114 34119 34443 
f 34444 33963 34211 
f 34210 33967 34445 
f 34059 34165 34446 
f 34138 34123 34447 
f 34448 34120 34139 
f 34449 34164 34058 
f 34450 34122 34136 
f 34137 34121 34451 
f 34119 34114 34452 
f 34453 34145 33985 
f 34213 34010 34454 
f 34455 34115 34118 
f 34456 34011 34214 
f 34457 34009 34212 
f 33982 34144 34458 
f 34215 34008 34459 
s 4
f 33956 34460 33958 
s 16
f 33904 34461 33906 
f 34461 33904 33903 
f 33932 33933 34462 
f 33935 34462 33933 
f 34463 33895 33894 
f 33895 34463 33897 
f 33923 33924 34464 
f 33926 34464 33924 
s 8
f 34465 34466 34467 
f 34467 34468 34465 
s 16
f 34469 34465 34468 
f 34468 34470 34469 
s 32
f 34471 34472 34473 
f 34473 34474 34471 
s 64
f 34466 34471 34474 
f 34474 34467 34466 
s 16
f 34472 34469 34470 
f 34470 34473 34472 
s 8
f 34475 34476 34477 
f 34477 34478 34475 
s 16
f 34479 34475 34478 
f 34478 34480 34479 
s 32
f 34481 34482 34483 
f 34483 34484 34481 
s 64
f 34476 34481 34484 
f 34484 34477 34476 
s 16
f 34482 34479 34480 
f 34480 34483 34482 
s 8
f 34485 34488 34487 
f 34487 34486 34485 
s 16
f 34489 34490 34488 
f 34488 34485 34489 
s 32
f 34491 34494 34493 
f 34493 34492 34491 
s 64
f 34486 34487 34494 
f 34494 34491 34486 
s 16
f 34492 34493 34490 
f 34490 34489 34492 
s 8
f 34495 34498 34497 
f 34497 34496 34495 
s 16
f 34499 34500 34498 
f 34498 34495 34499 
s 32
f 34501 34504 34503 
f 34503 34502 34501 
s 64
f 34496 34497 34504 
f 34504 34501 34496 
s 16
f 34502 34503 34500 
f 34500 34499 34502 
s off
f 34469 34472 34471 
f 34465 34469 34471 
f 34465 34471 34466 
f 34479 34482 34481 
f 34475 34479 34481 
f 34475 34481 34476 
f 34486 34491 34492 
f 34486 34492 34489 
f 34486 34489 34485 
f 34496 34501 34502 
f 34496 34502 34499 
f 34496 34499 34495 
s 8
f 34516 34519 34518 
f 34518 34517 34516 
s 16
f 34519 34516 35088 
s 32
f 34521 34524 34523 
f 34523 34522 34521 
s 64
f 34517 34518 34524 
f 34524 34521 34517 
s off
f 34505 34563 34506 
f 34506 34563 34507 
f 34508 34619 34509 
s 16
f 34522 34523 34520 
s off
f 34507 34563 34508 
f 34505 34509 34510 
s 8
f 34525 34528 34527 
f 34527 34526 34525 
s 16
f 34528 34525 35086 
s 32
f 34530 34533 34532 
f 34532 34531 34530 
s 64
f 34526 34527 34533 
f 34533 34530 34526 
s off
f 34511 34566 34512 
f 34512 34566 34513 
f 34514 34619 34515 
s 16
f 34531 34532 34529 
s off
f 34513 34566 34514 
f 34511 34515 34510 
s 8
f 34506 34516 34517 
f 34517 34505 34506 
s 16
f 34507 35088 34516 
f 34516 34506 34507 
s 32
f 34509 34521 34522 
f 34522 34508 34509 
s 64
f 34505 34517 34521 
f 34521 34509 34505 
s 16
f 34508 34522 35088 
f 35088 34507 34508 
s 8
f 34512 34525 34526 
f 34526 34511 34512 
s 16
f 34513 35086 34525 
f 34525 34512 34513 
s 32
f 34515 34530 34531 
f 34531 34514 34515 
s 64
f 34511 34526 34530 
f 34530 34515 34511 
s 16
f 34514 34531 35086 
f 35086 34513 34514 
s 8
f 34545 34546 34547 
f 34547 34548 34545 
s 16
f 35089 34545 34548 
s 32
f 34550 34551 34552 
f 34552 34553 34550 
s 64
f 34546 34550 34553 
f 34553 34547 34546 
s off
f 34534 34535 34564 
f 34535 34536 34564 
f 34537 34538 34620 
s 16
f 34549 34552 34551 
s off
f 34536 34537 34564 
f 34534 34539 34538 
s 8
f 34554 34555 34556 
f 34556 34557 34554 
s 16
f 35087 34554 34557 
s 32
f 34559 34560 34561 
f 34561 34562 34559 
s 64
f 34555 34559 34562 
f 34562 34556 34555 
s off
f 34540 34541 34565 
f 34541 34542 34565 
f 34543 34544 34620 
s 16
f 34558 34561 34560 
s off
f 34542 34543 34565 
f 34540 34539 34544 
s 8
f 34535 34534 34546 
f 34546 34545 34535 
s 16
f 34536 34535 34545 
f 34545 35089 34536 
s 32
f 34538 34537 34551 
f 34551 34550 34538 
s 64
f 34534 34538 34550 
f 34550 34546 34534 
s 16
f 34537 34536 35089 
f 35089 34551 34537 
s 8
f 34541 34540 34555 
f 34555 34554 34541 
s 16
f 34542 34541 34554 
f 34554 35087 34542 
s 32
f 34544 34543 34560 
f 34560 34559 34544 
s 64
f 34540 34544 34559 
f 34559 34555 34540 
s 16
f 34543 34542 35087 
f 35087 34560 34543 
s off
f 34508 34563 34619 
f 34505 34619 34563 
f 34537 34620 34564 
f 34534 34564 34620 
f 34540 34565 34620 
f 34543 34620 34565 
f 34514 34566 34619 
f 34511 34619 34566 
f 34567 34580 34579 
f 34570 34579 34580 
s 2
f 34571 34572 34574 
f 34574 34573 34571 
s 1
f 34575 34576 34578 
f 34578 34577 34575 
s off
f 34579 34569 34567 
f 34580 34568 34570 
s 4
f 34581 34700 34722 
f 34586 34581 34583 
f 34581 34585 34687 
f 34586 34723 34701 
s 8
f 34784 34907 34935 
f 34771 35013 34988 
f 34937 34918 34781 
f 34982 35012 34772 
f 35012 34982 34811 
f 35033 34965 34849 
f 34812 34988 35013 
f 34848 34969 35036 
f 34987 35014 34773 
f 34865 34891 34861 
f 34775 35023 34977 
f 34864 34894 34868 
f 34992 35016 34774 
f 34866 34892 34859 
f 34776 35021 34986 
f 34860 34893 34867 
f 34946 34919 34855 
f 34940 34908 34782 
f 34854 34913 34944 
f 34783 34912 34931 
f 34832 35047 35043 
f 34778 35004 34998 
f 35044 35049 34829 
f 34994 35008 34777 
f 34833 35052 35046 
f 34780 35005 35000 
f 35045 35048 34834 
f 34995 35002 34779 
f 34953 34897 34786 
f 35019 34979 34813 
f 34816 34991 35022 
f 34788 34902 34954 
s off
f 34505 34654 34619 
f 34619 34627 34509 
f 34511 34627 34619 
f 34619 34638 34515 
f 34544 34639 34620 
f 34540 34620 34628 
f 34538 34628 34620 
f 34534 34620 34655 
s 8
f 35066 35062 34769 
f 34770 35064 35067 
f 34839 35075 35081 
f 35079 35072 34838 
f 34837 35073 35082 
f 35084 35076 34840 
s off
f 34509 34627 34510 
f 34511 34510 34627 
f 34540 34628 34539 
f 34539 34628 34538 
s 8
f 34846 35065 35063 
f 35061 35068 34843 
f 34785 34901 34947 
f 34951 34899 34787 
f 35057 35053 34819 
f 34817 35055 35060 
f 35058 35056 34820 
f 34818 35054 35059 
s 4
f 34581 34687 34637 
s off
f 34515 34638 34510 
f 34539 34639 34544 
s 8
f 34968 35029 34880 
f 34874 35032 34972 
f 34878 35030 34966 
f 34970 35031 34873 
f 35027 34975 34821 
f 34822 34973 35028 
f 34945 34911 34852 
f 34851 34920 34939 
f 34933 34915 34856 
f 34853 34916 34938 
f 34861 34887 34865 
f 34859 34888 34866 
f 34868 34889 34864 
f 34867 34890 34860 
s off
f 34505 34510 34654 
f 34534 34655 34539 
s 8
f 34884 35028 34973 
f 34881 35025 34974 
f 34975 35027 34883 
f 34976 35026 34882 
s 4
f 34586 34660 34732 
s 8
f 34836 35080 35069 
f 35070 35077 34835 
f 34803 34936 34906 
f 34905 34932 34804 
f 34917 34941 34796 
f 34799 34943 34910 
f 34794 34948 34898 
f 34792 34949 34903 
f 34904 34950 34793 
f 34900 34952 34791 
f 35007 34999 34806 
f 34808 34996 35001 
f 34814 34985 35010 
f 35024 34980 34809 
f 34789 35042 35051 
f 35050 35041 34790 
f 34798 34939 34920 
f 34911 34945 34800 
f 34915 34933 34795 
f 34797 34938 34916 
f 34895 34885 34858 
f 34857 34886 34896 
f 34830 35051 35042 
f 35041 35050 34831 
s 4
f 34581 34637 34685 
f 34586 34686 34660 
f 34585 34582 34687 
s 8
f 34959 34930 34767 
f 34766 34926 34957 
f 34765 34929 34956 
f 34955 34927 34768 
f 34802 34896 34886 
f 34885 34895 34801 
f 34871 35059 35054 
f 35056 35058 34872 
f 34862 35060 35055 
f 35053 35057 34863 
f 34965 35033 34877 
f 34879 35036 34969 
s 4
f 34581 34685 34700 
f 34586 34701 34686 
s 8
f 34845 35067 35064 
f 35062 35066 34844 
f 35011 34981 34815 
f 34810 34990 35020 
f 35006 34993 34805 
f 34807 34997 35003 
f 35040 35037 34842 
f 34841 35038 35039 
f 34869 34960 34928 
f 34925 34958 34870 
f 34826 34967 35034 
f 35035 34971 34827 
f 35029 34968 34825 
f 34828 34972 35032 
f 35026 34976 34824 
f 34823 34974 35025 
f 35031 34970 34850 
f 34847 34966 35030 
f 34875 35034 34967 
f 34971 35035 34876 
s 4
f 35085 34581 34722 
f 34586 34583 34723 
s 8
f 34724 34957 34926 
f 34725 34956 34929 
f 34927 34955 34726 
f 34930 34959 34727 
f 34923 34963 34728 
f 34729 34961 34924 
f 34922 34962 34730 
f 34731 34964 34921 
s 4
f 34584 34586 34732 
s 8
f 34963 34923 34733 
f 34734 34924 34961 
f 34735 34921 34964 
f 34962 34922 34736 
f 34958 34925 34737 
f 34738 34928 34960 
f 35068 35061 34739 
f 34740 35063 35065 
f 34741 35039 35038 
f 35037 35040 34742 
f 34743 35069 35080 
f 35077 35070 34744 
f 34745 35046 35052 
f 35048 35045 34746 
f 34747 35043 35047 
f 35049 35044 34748 
f 34749 35001 34996 
f 34993 35006 34750 
f 34751 35003 34997 
f 34999 35007 34752 
f 34979 35019 34753 
f 34754 35010 34985 
f 34755 35020 34990 
f 34981 35011 34756 
f 34980 35024 34757 
f 34758 35022 34991 
f 34919 34946 34759 
f 34760 34944 34913 
f 34761 35081 35075 
f 35076 35084 34762 
f 35072 35079 34763 
f 34764 35082 35073 
f 34690 35017 34978 
f 34689 35015 34989 
f 34984 35018 34688 
f 34983 35009 34691 
f 35071 35083 34684 
f 34683 35078 35074 
f 34682 34914 34934 
f 34942 34909 34681 
f 34932 34905 34670 
f 34941 34917 34669 
f 34668 34906 34936 
f 34667 34910 34943 
f 34952 34900 34664 
f 34663 34903 34949 
f 34950 34904 34665 
f 34666 34898 34948 
f 34899 34951 34644 
f 34897 34953 34671 
f 34672 34954 34902 
f 34645 34947 34901 
f 34631 34935 34907 
f 34908 34940 34674 
f 34918 34937 34632 
f 34673 34931 34912 
f 34610 35074 35078 
f 35083 35071 34607 
f 35008 34994 34595 
f 34598 34998 35004 
f 35002 34995 34601 
f 34604 35000 35005 
f 35018 34984 34616 
f 35016 34992 34602 
f 34613 34989 35015 
f 34615 34978 35017 
f 34606 34986 35021 
f 35009 34983 34614 
f 34909 34942 34693 
f 34692 34934 34914 
f 34600 34977 35023 
f 35014 34987 34596 
f 34591 34805 34706 
f 34617 34806 34609 
f 34707 34807 34589 
f 34611 34808 34618 
f 34643 34809 34608 
f 34705 34810 34629 
f 34721 34811 34658 
f 34656 34812 34720 
f 34640 34813 34659 
f 34612 34814 34642 
f 34630 34815 34704 
f 34657 34816 34641 
f 34634 34817 34696 
f 34636 34818 34694 
f 34697 34819 34633 
f 34695 34820 34635 
f 34713 34821 34590 
f 34587 34822 34712 
f 34717 34823 34715 
f 34714 34824 34716 
f 34716 34825 34714 
f 34712 34826 34587 
f 34590 34827 34713 
f 34715 34828 34717 
f 34597 34829 34678 
f 34622 34830 34675 
f 34676 34831 34621 
f 34680 34832 34599 
f 34677 34833 34605 
f 34603 34834 34679 
f 34592 34835 34593 
f 34594 34836 34588 
f 34696 34837 34634 
f 34635 34838 34695 
f 34694 34839 34636 
f 34633 34840 34697 
f 34709 34841 34623 
f 34624 34842 34708 
f 34662 34843 34698 
f 34718 34844 34703 
f 34702 34845 34719 
f 34699 34846 34661 
f 34719 34847 34702 
f 34661 34848 34699 
f 34698 34849 34662 
f 34703 34850 34718 
f 34605 34851 34677 
f 34678 34852 34597 
f 34599 34853 34680 
f 34675 34854 34622 
f 34621 34855 34676 
f 34679 34856 34603 
f 34588 34857 34594 
f 34593 34858 34592 
f 34888 34859 34648 
f 34647 34860 34890 
f 34887 34861 34646 
f 34625 34862 34710 
f 34711 34863 34626 
f 34649 34864 34889 
f 34891 34865 34650 
f 34892 34866 34651 
f 34653 34867 34893 
f 34652 34868 34894 
f 34710 34869 34625 
f 34626 34870 34711 
f 34623 34871 34709 
f 34708 34872 34624 
f 34608 34873 34643 
f 34641 34874 34657 
f 34720 34875 34656 
f 34658 34876 34721 
f 34704 34877 34630 
f 34642 34878 34612 
f 34629 34879 34705 
f 34659 34880 34640 
f 34618 34881 34611 
f 34609 34882 34617 
f 34706 34883 34591 
f 34589 34884 34707 
f 34801 34681 34885 
f 34886 34682 34802 
f 34646 34726 34887 
f 34648 34727 34888 
f 34889 34724 34649 
f 34890 34725 34647 
f 34650 34730 34891 
f 34651 34728 34892 
f 34893 34729 34653 
f 34894 34731 34652 
f 34858 34593 34895 
f 34896 34594 34857 
f 34671 34752 34897 
f 34898 34780 34794 
f 34644 34750 34899 
f 34791 34777 34900 
f 34901 34751 34645 
f 34902 34749 34672 
f 34903 34778 34792 
f 34793 34779 34904 
f 34804 34773 34905 
f 34906 34775 34803 
f 34907 34755 34631 
f 34674 34757 34908 
f 34693 34772 34909 
f 34910 34776 34799 
f 34800 34691 34911 
f 34912 34754 34673 
f 34913 34758 34760 
f 34914 34771 34692 
f 34795 34688 34915 
f 34916 34689 34797 
f 34796 34774 34917 
f 34632 34756 34918 
f 34759 34753 34919 
f 34920 34690 34798 
f 34921 34652 34731 
f 34730 34650 34922 
f 34728 34651 34923 
f 34924 34653 34729 
f 34870 34626 34925 
f 34926 34649 34724 
f 34726 34646 34927 
f 34928 34625 34869 
f 34929 34647 34725 
f 34727 34648 34930 
f 34931 34788 34783 
f 34670 34664 34932 
f 34856 34679 34933 
f 34934 34802 34682 
f 34935 34785 34784 
f 34936 34663 34668 
f 34781 34787 34937 
f 34938 34680 34853 
f 34939 34677 34851 
f 34782 34786 34940 
f 34669 34665 34941 
f 34681 34801 34942 
f 34943 34666 34667 
f 34944 34622 34854 
f 34852 34678 34945 
f 34855 34621 34946 
f 34947 34784 34785 
f 34948 34667 34666 
f 34949 34668 34663 
f 34665 34669 34950 
f 34787 34781 34951 
f 34664 34670 34952 
f 34786 34782 34953 
f 34954 34783 34788 
f 34768 34614 34955 
f 34956 34615 34765 
f 34957 34613 34766 
f 34737 34602 34958 
f 34767 34616 34959 
f 34960 34606 34738 
f 34961 34604 34734 
f 34736 34595 34962 
f 34733 34601 34963 
f 34964 34598 34735 
f 34877 34704 34965 
f 34966 34612 34878 
f 34967 34656 34875 
f 34880 34659 34968 
f 34969 34705 34879 
f 34873 34608 34970 
f 34876 34658 34971 
f 34972 34657 34874 
f 34973 34707 34884 
f 34974 34611 34881 
f 34883 34706 34975 
f 34882 34609 34976 
f 34977 34803 34775 
f 34978 34798 34690 
f 34753 34759 34979 
f 34757 34674 34980 
f 34756 34632 34981 
f 34772 34693 34982 
f 34691 34800 34983 
f 34688 34795 34984 
f 34985 34673 34754 
f 34986 34799 34776 
f 34773 34804 34987 
f 34988 34692 34771 
f 34989 34797 34689 
f 34990 34631 34755 
f 34991 34760 34758 
f 34774 34796 34992 
f 34750 34644 34993 
f 34777 34791 34994 
f 34779 34793 34995 
f 34996 34672 34749 
f 34997 34645 34751 
f 34998 34792 34778 
f 34752 34671 34999 
f 35000 34794 34780 
f 35001 34618 34808 
f 34601 34733 35002 
f 35003 34589 34807 
f 35004 34735 34598 
f 35005 34734 34604 
f 34805 34591 35006 
f 34806 34617 35007 
f 34595 34736 35008 
f 34614 34768 35009 
f 35010 34642 34814 
f 34815 34630 35011 
f 34811 34721 35012 
f 35013 34720 34812 
f 34596 34742 35014 
f 35015 34766 34613 
f 34602 34737 35016 
f 35017 34765 34615 
f 34616 34767 35018 
f 34813 34640 35019 
f 35020 34629 34810 
f 35021 34738 34606 
f 35022 34641 34816 
f 35023 34741 34600 
f 34809 34643 35024 
f 35025 34715 34823 
f 34824 34714 35026 
f 34821 34713 35027 
f 35028 34712 34822 
f 34825 34716 35029 
f 35030 34702 34847 
f 34850 34703 35031 
f 35032 34717 34828 
f 34849 34698 35033 
f 35034 34587 34826 
f 34827 34590 35035 
f 35036 34699 34848 
f 34742 34596 35037 
f 35038 34600 34741 
f 35039 34623 34841 
f 34842 34624 35040 
f 34831 34676 35041 
f 35042 34675 34830 
f 35043 34599 34832 
f 34829 34597 35044 
f 34834 34603 35045 
f 35046 34605 34833 
f 35047 34761 34747 
f 34746 34762 35048 
f 34748 34763 35049 
f 34790 34684 35050 
f 35051 34683 34789 
f 35052 34764 34745 
f 34863 34711 35053 
f 35054 34709 34871 
f 35055 34710 34862 
f 34872 34708 35056 
f 34819 34697 35057 
f 34820 34695 35058 
f 35059 34694 34818 
f 35060 34696 34817 
f 34843 34662 35061 
f 34844 34718 35062 
f 35063 34661 34846 
f 35064 34719 34845 
f 35065 34743 34740 
f 34769 34607 35066 
f 35067 34610 34770 
f 34739 34744 35068 
f 35069 34588 34836 
f 34835 34592 35070 
f 34684 34790 35071 
f 34763 34748 35072 
f 35073 34745 34764 
f 35074 34789 34683 
f 35075 34747 34761 
f 34762 34746 35076 
f 34744 34739 35077 
f 35078 34770 34610 
f 34838 34635 35079 
f 35080 34740 34743 
f 35081 34636 34839 
f 35082 34634 34837 
f 34607 34769 35083 
f 34840 34633 35084 
s 4
f 34581 35085 34583 
s 16
f 34529 35086 34531 
f 35086 34529 34528 
f 34557 34558 35087 
f 34560 35087 34558 
f 35088 34520 34519 
f 34520 35088 34522 
f 34548 34549 35089 
f 34551 35089 34549 
s 8
f 35091 35506 35505 
f 35506 35091 35090 
s 16
f 35505 35096 35091 
s 32
f 35092 35508 35507 
f 35508 35092 35093 
s 64
f 35090 35507 35506 
f 35507 35090 35092 
s off
f 35094 35638 35097 
s 16
f 35093 35661 35508 
s off
f 35097 35638 35095 
f 35660 35094 35098 
f 35638 35099 35095 
f 35099 35645 35098 
s 8
f 35102 35494 35493 
f 35494 35102 35101 
s 16
f 35493 35108 35102 
s 32
f 35103 35496 35495 
f 35496 35103 35104 
s 64
f 35101 35495 35494 
f 35495 35101 35103 
s off
f 35105 35107 35109 
s 16
f 35104 35666 35496 
s off
f 35109 35107 35106 
f 35105 35112 35107 
f 35112 35105 35110 
f 35107 35111 35106 
f 35111 35107 35112 
s 8
f 35446 35450 35449 
f 35450 35446 35445 
s 16
f 35663 35445 35446 
s 32
f 35452 35456 35455 
f 35456 35452 35451 
s 64
f 35449 35451 35452 
f 35451 35449 35450 
s off
f 35117 35609 35120 
s 16
f 35662 35455 35456 
s off
f 35120 35609 35118 
f 35686 35117 35121 
f 35609 35122 35118 
f 35122 35646 35121 
s 8
f 35125 35510 35509 
f 35510 35125 35124 
s 16
f 35509 35130 35125 
s 32
f 35126 35512 35511 
f 35512 35126 35127 
s 64
f 35124 35511 35510 
f 35511 35124 35126 
s off
f 35128 35639 35131 
s 16
f 35127 35616 35512 
s off
f 35131 35639 35129 
f 35134 35128 35132 
f 35639 35133 35129 
s 8
f 35530 35546 35545 
f 35546 35530 35529 
s 16
f 35628 35529 35530 
s 32
f 35532 35548 35547 
f 35548 35532 35531 
s 64
f 35545 35531 35532 
f 35531 35545 35546 
s off
f 35139 35606 35140 
f 35140 35606 35144 
f 35142 35633 35141 
s 16
f 35676 35547 35548 
s off
f 35144 35606 35142 
f 35139 35141 35145 
s 8
f 35534 35550 35549 
f 35550 35534 35533 
s 16
f 35615 35533 35534 
s 32
f 35536 35552 35551 
f 35552 35536 35535 
s 64
f 35549 35535 35536 
f 35535 35549 35550 
s off
f 35150 35612 35151 
f 35151 35612 35155 
f 35153 35633 35152 
s 16
f 35657 35551 35552 
s off
f 35155 35612 35153 
f 35150 35152 35145 
s 8
f 35422 35426 35425 
f 35426 35422 35421 
s 16
f 35683 35421 35422 
s 32
f 35428 35432 35431 
f 35432 35428 35427 
s 64
f 35425 35427 35428 
f 35427 35425 35426 
s off
f 35160 35608 35161 
f 35161 35608 35165 
f 35163 35635 35162 
s 16
f 35669 35431 35432 
s off
f 35165 35608 35163 
f 35160 35162 35166 
s 8
f 35434 35438 35437 
f 35438 35434 35433 
s 16
f 35670 35433 35434 
s 32
f 35440 35444 35443 
f 35444 35440 35439 
s 64
f 35437 35439 35440 
f 35439 35437 35438 
s off
f 35171 35614 35172 
f 35172 35614 35176 
f 35174 35635 35173 
s 16
f 35671 35443 35444 
s off
f 35176 35614 35174 
f 35171 35173 35166 
s 8
f 35458 35462 35461 
f 35462 35458 35457 
s 16
f 35679 35457 35458 
s 32
f 35464 35468 35467 
f 35468 35464 35463 
s 64
f 35461 35463 35464 
f 35463 35461 35462 
s off
f 35181 35183 35185 
s 16
f 35681 35467 35468 
s off
f 35185 35183 35182 
f 35181 35188 35183 
f 35188 35181 35186 
f 35183 35187 35182 
f 35187 35183 35188 
f 35186 35187 35188 
s 8
f 35470 35474 35473 
f 35474 35470 35469 
s 16
f 35678 35469 35470 
s 32
f 35476 35480 35479 
f 35480 35476 35475 
s 64
f 35473 35475 35476 
f 35475 35473 35474 
s off
f 35193 35195 35197 
s 16
f 35685 35479 35480 
s off
f 35197 35195 35194 
f 35193 35200 35195 
f 35200 35193 35198 
f 35195 35199 35194 
f 35199 35195 35200 
f 35198 35199 35200 
s 8
f 35482 35486 35485 
f 35486 35482 35481 
s 16
f 35677 35481 35482 
s 32
f 35488 35492 35491 
f 35492 35488 35487 
s 64
f 35485 35487 35488 
f 35487 35485 35486 
s off
f 35205 35207 35209 
s 16
f 35668 35491 35492 
s off
f 35209 35207 35206 
f 35205 35212 35207 
f 35212 35205 35210 
f 35207 35211 35206 
f 35211 35207 35212 
f 35210 35211 35212 
s 8
f 35214 35498 35497 
f 35498 35214 35213 
s 16
f 35497 35220 35214 
s 32
f 35215 35500 35499 
f 35500 35215 35216 
s 64
f 35213 35499 35498 
f 35499 35213 35215 
s off
f 35217 35219 35221 
s 16
f 35216 35664 35500 
s off
f 35221 35219 35218 
f 35217 35224 35219 
f 35224 35217 35222 
f 35219 35223 35218 
f 35223 35219 35224 
s 8
f 35226 35502 35501 
f 35502 35226 35225 
s 16
f 35501 35232 35226 
s 32
f 35227 35504 35503 
f 35504 35227 35228 
s 64
f 35225 35503 35502 
f 35503 35225 35227 
s off
f 35229 35231 35233 
s 16
f 35228 35665 35504 
s off
f 35233 35231 35230 
f 35229 35236 35231 
f 35236 35229 35234 
f 35231 35235 35230 
f 35235 35231 35236 
s 8
f 35237 35239 35238 
f 35239 35237 35240 
s 16
f 35241 35239 35242 
f 35239 35241 35238 
s 32
f 35243 35245 35244 
f 35245 35243 35246 
s 64
f 35237 35245 35240 
f 35245 35237 35244 
s off
f 35240 35607 35239 
f 35239 35607 35242 
f 35246 35634 35245 
s 16
f 35243 35242 35246 
f 35242 35243 35241 
s off
f 35242 35607 35246 
f 35240 35245 35247 
s 8
f 35248 35250 35249 
f 35250 35248 35251 
s 16
f 35252 35250 35253 
f 35250 35252 35249 
s 32
f 35254 35256 35255 
f 35256 35254 35257 
s 64
f 35248 35256 35251 
f 35256 35248 35255 
s off
f 35251 35613 35250 
f 35250 35613 35253 
f 35257 35634 35256 
s 16
f 35254 35253 35257 
f 35253 35254 35252 
s off
f 35253 35613 35257 
f 35251 35256 35247 
s 8
f 35258 35260 35259 
f 35260 35258 35261 
s 16
f 35262 35260 35263 
f 35260 35262 35259 
s 32
f 35264 35266 35265 
f 35266 35264 35267 
s 64
f 35258 35266 35261 
f 35266 35258 35265 
s off
f 35260 35268 35263 
s 16
f 35264 35263 35267 
f 35263 35264 35262 
s off
f 35263 35268 35267 
f 35260 35261 35268 
f 35268 35266 35267 
f 35261 35266 35268 
f 35110 35111 35112 
f 35222 35223 35224 
f 35234 35235 35236 
s 8
f 35538 35554 35553 
f 35554 35538 35537 
s 16
f 35627 35537 35538 
s 32
f 35540 35556 35555 
f 35556 35540 35539 
s 64
f 35553 35539 35540 
f 35539 35553 35554 
s off
f 35272 35604 35271 
f 35271 35604 35274 
f 35278 35632 35277 
s 16
f 35682 35555 35556 
s off
f 35274 35604 35278 
f 35272 35277 35279 
s 8
f 35542 35558 35557 
f 35558 35542 35541 
s 16
f 35625 35541 35542 
s 32
f 35544 35560 35559 
f 35560 35544 35543 
s 64
f 35557 35543 35544 
f 35543 35557 35558 
s off
f 35283 35611 35282 
f 35282 35611 35285 
f 35289 35632 35288 
s 16
f 35667 35559 35560 
s off
f 35285 35611 35289 
f 35283 35288 35279 
s 8
f 35290 35292 35291 
f 35292 35290 35293 
s 16
f 35294 35292 35295 
f 35292 35294 35291 
s 32
f 35296 35298 35297 
f 35298 35296 35299 
s 64
f 35290 35298 35293 
f 35298 35290 35297 
s off
f 35293 35605 35292 
f 35292 35605 35295 
f 35299 35636 35298 
s 16
f 35296 35295 35299 
f 35295 35296 35294 
s off
f 35295 35605 35299 
f 35293 35298 35300 
s 32
f 35603 35306 35305 
f 35306 35603 35307 
s 64
f 35301 35306 35303 
f 35306 35301 35305 
s off
f 35303 35610 35302 
f 35302 35610 35304 
f 35307 35636 35306 
f 35304 35610 35307 
f 35303 35306 35300 
s 8
f 35308 35310 35309 
f 35310 35308 35311 
s 16
f 35312 35310 35313 
f 35310 35312 35309 
s 32
f 35314 35316 35315 
f 35316 35314 35317 
s 64
f 35308 35316 35311 
f 35316 35308 35315 
s off
f 35310 35318 35313 
s 16
f 35314 35313 35317 
f 35313 35314 35312 
s off
f 35313 35318 35317 
f 35310 35311 35318 
f 35318 35316 35317 
f 35311 35316 35318 
s 8
f 35319 35321 35320 
f 35321 35319 35322 
s 16
f 35323 35321 35324 
f 35321 35323 35320 
s 32
f 35325 35327 35326 
f 35327 35325 35328 
s 64
f 35319 35327 35322 
f 35327 35319 35326 
s off
f 35321 35329 35324 
s 16
f 35325 35324 35328 
f 35324 35325 35323 
s off
f 35324 35329 35328 
f 35321 35322 35329 
f 35329 35327 35328 
f 35322 35327 35329 
s 8
f 35330 35332 35331 
f 35332 35330 35333 
s 16
f 35334 35332 35335 
f 35332 35334 35331 
s 32
f 35336 35338 35337 
f 35338 35336 35339 
s 64
f 35330 35338 35333 
f 35338 35330 35337 
s off
f 35332 35340 35335 
s 16
f 35336 35335 35339 
f 35335 35336 35334 
s off
f 35335 35340 35339 
f 35332 35333 35340 
f 35340 35338 35339 
f 35333 35338 35340 
s 1
f 35352 35341 35342 
f 35341 35352 35351 
s 4
f 35353 35342 35343 
f 35342 35353 35352 
f 35354 35343 35344 
f 35343 35354 35353 
f 35355 35344 35345 
f 35344 35355 35354 
f 35356 35345 35346 
f 35345 35356 35355 
f 35357 35346 35347 
f 35346 35357 35356 
f 35358 35347 35348 
f 35347 35358 35357 
f 35359 35348 35349 
f 35348 35359 35358 
s 2
f 35360 35349 35350 
f 35349 35360 35359 
s 4
f 35351 35350 35341 
f 35350 35351 35360 
s 16
f 35341 35343 35342 
f 35341 35344 35343 
f 35348 35350 35349 
f 35347 35350 35348 
f 35346 35350 35347 
f 35345 35350 35346 
f 35344 35350 35345 
f 35341 35350 35344 
f 35360 35358 35359 
f 35360 35357 35358 
f 35360 35356 35357 
f 35360 35355 35356 
f 35360 35354 35355 
f 35351 35354 35360 
f 35351 35353 35354 
f 35351 35352 35353 
s 1
f 35372 35361 35362 
f 35361 35372 35371 
s 4
f 35373 35362 35363 
f 35362 35373 35372 
f 35374 35363 35364 
f 35363 35374 35373 
f 35375 35364 35365 
f 35364 35375 35374 
f 35376 35365 35366 
f 35365 35376 35375 
f 35377 35366 35367 
f 35366 35377 35376 
f 35378 35367 35368 
f 35367 35378 35377 
f 35379 35368 35369 
f 35368 35379 35378 
s 2
f 35380 35369 35370 
f 35369 35380 35379 
s 4
f 35371 35370 35361 
f 35370 35371 35380 
s 16
f 35361 35363 35362 
f 35361 35364 35363 
f 35368 35370 35369 
f 35367 35370 35368 
f 35366 35370 35367 
f 35365 35370 35366 
f 35364 35370 35365 
f 35361 35370 35364 
f 35380 35378 35379 
f 35380 35377 35378 
f 35380 35376 35377 
f 35380 35375 35376 
f 35380 35374 35375 
f 35371 35374 35380 
f 35371 35373 35374 
f 35371 35372 35373 
s 1
f 35392 35381 35382 
f 35381 35392 35391 
s 4
f 35393 35382 35383 
f 35382 35393 35392 
f 35394 35383 35384 
f 35383 35394 35393 
f 35395 35384 35385 
f 35384 35395 35394 
f 35396 35385 35386 
f 35385 35396 35395 
f 35397 35386 35387 
f 35386 35397 35396 
f 35398 35387 35388 
f 35387 35398 35397 
f 35399 35388 35389 
f 35388 35399 35398 
s 2
f 35400 35389 35390 
f 35389 35400 35399 
s 4
f 35391 35390 35381 
f 35390 35391 35400 
s 16
f 35381 35383 35382 
f 35381 35384 35383 
f 35388 35390 35389 
f 35387 35390 35388 
f 35386 35390 35387 
f 35385 35390 35386 
f 35384 35390 35385 
f 35381 35390 35384 
f 35400 35398 35399 
f 35400 35397 35398 
f 35400 35396 35397 
f 35400 35395 35396 
f 35400 35394 35395 
f 35391 35394 35400 
f 35391 35393 35394 
f 35391 35392 35393 
s 1
f 35412 35401 35402 
f 35401 35412 35411 
s 4
f 35413 35402 35403 
f 35402 35413 35412 
f 35414 35403 35404 
f 35403 35414 35413 
f 35415 35404 35405 
f 35404 35415 35414 
f 35416 35405 35406 
f 35405 35416 35415 
f 35417 35406 35407 
f 35406 35417 35416 
f 35418 35407 35408 
f 35407 35418 35417 
f 35419 35408 35409 
f 35408 35419 35418 
s 2
f 35420 35409 35410 
f 35409 35420 35419 
s 4
f 35411 35410 35401 
f 35410 35411 35420 
s 16
f 35401 35403 35402 
f 35401 35404 35403 
f 35408 35410 35409 
f 35407 35410 35408 
f 35406 35410 35407 
f 35405 35410 35406 
f 35404 35410 35405 
f 35401 35410 35404 
f 35420 35418 35419 
f 35420 35417 35418 
f 35420 35416 35417 
f 35420 35415 35416 
f 35420 35414 35415 
f 35411 35414 35420 
f 35411 35413 35414 
f 35411 35412 35413 
s 8
f 35161 35424 35160 
f 35424 35161 35423 
f 35423 35425 35424 
f 35425 35423 35422 
f 35156 35421 35157 
f 35421 35156 35426 
s 16
f 35165 35423 35161 
f 35423 35165 35622 
f 35622 35422 35423 
f 35669 35157 35421 
s 32
f 35162 35430 35163 
f 35430 35162 35429 
f 35429 35431 35430 
f 35431 35429 35428 
f 35159 35427 35158 
f 35427 35159 35432 
s 64
f 35160 35429 35162 
f 35429 35160 35424 
f 35424 35428 35429 
f 35428 35424 35425 
f 35158 35426 35156 
f 35426 35158 35427 
s 16
f 35163 35622 35165 
f 35622 35163 35430 
f 35683 35430 35431 
f 35164 35432 35159 
s 8
f 35172 35436 35171 
f 35436 35172 35435 
f 35435 35437 35436 
f 35437 35435 35434 
f 35167 35433 35168 
f 35433 35167 35438 
s 16
f 35176 35435 35172 
f 35435 35176 35623 
f 35623 35434 35435 
f 35671 35168 35433 
s 32
f 35173 35442 35174 
f 35442 35173 35441 
f 35441 35443 35442 
f 35443 35441 35440 
f 35170 35439 35169 
f 35439 35170 35444 
s 64
f 35171 35441 35173 
f 35441 35171 35436 
f 35436 35440 35441 
f 35440 35436 35437 
f 35169 35438 35167 
f 35438 35169 35439 
s 16
f 35174 35623 35176 
f 35623 35174 35442 
f 35670 35442 35443 
f 35175 35444 35170 
s 8
f 35117 35448 35121 
f 35448 35117 35447 
f 35446 35448 35447 
f 35448 35446 35449 
f 35113 35445 35114 
f 35445 35113 35450 
s 16
f 35120 35447 35117 
f 35447 35120 35618 
f 35618 35446 35447 
f 35662 35114 35445 
s 32
f 35122 35454 35118 
f 35454 35122 35453 
f 35453 35455 35454 
f 35455 35453 35452 
f 35116 35451 35115 
f 35451 35116 35456 
s 64
f 35121 35453 35122 
f 35453 35121 35448 
f 35448 35452 35453 
f 35452 35448 35449 
f 35115 35450 35113 
f 35450 35115 35451 
s 16
f 35118 35618 35120 
f 35618 35118 35454 
f 35663 35454 35455 
f 35119 35456 35116 
s 8
f 35181 35460 35186 
f 35460 35181 35459 
f 35459 35461 35460 
f 35461 35459 35458 
f 35177 35457 35178 
f 35457 35177 35462 
s 16
f 35185 35459 35181 
f 35459 35185 35620 
f 35620 35458 35459 
f 35681 35178 35457 
s 32
f 35187 35466 35182 
f 35466 35187 35465 
f 35465 35467 35466 
f 35467 35465 35464 
f 35180 35463 35179 
f 35463 35180 35468 
s 64
f 35186 35465 35187 
f 35465 35186 35460 
f 35460 35464 35465 
f 35464 35460 35461 
f 35179 35462 35177 
f 35462 35179 35463 
s 16
f 35182 35620 35185 
f 35620 35182 35466 
f 35679 35466 35467 
f 35184 35468 35180 
s 8
f 35193 35472 35198 
f 35472 35193 35471 
f 35471 35473 35472 
f 35473 35471 35470 
f 35189 35469 35190 
f 35469 35189 35474 
s 16
f 35197 35471 35193 
f 35471 35197 35629 
f 35629 35470 35471 
f 35685 35190 35469 
s 32
f 35199 35478 35194 
f 35478 35199 35477 
f 35477 35479 35478 
f 35479 35477 35476 
f 35192 35475 35191 
f 35475 35192 35480 
s 64
f 35198 35477 35199 
f 35477 35198 35472 
f 35472 35476 35477 
f 35476 35472 35473 
f 35191 35474 35189 
f 35474 35191 35475 
s 16
f 35194 35629 35197 
f 35629 35194 35478 
f 35678 35478 35479 
f 35196 35480 35192 
s 8
f 35205 35484 35210 
f 35484 35205 35483 
f 35483 35485 35484 
f 35485 35483 35482 
f 35201 35481 35202 
f 35481 35201 35486 
s 16
f 35209 35483 35205 
f 35483 35209 35626 
f 35626 35482 35483 
f 35668 35202 35481 
s 32
f 35211 35490 35206 
f 35490 35211 35489 
f 35489 35491 35490 
f 35491 35489 35488 
f 35204 35487 35203 
f 35487 35204 35492 
s 64
f 35210 35489 35211 
f 35489 35210 35484 
f 35484 35488 35489 
f 35488 35484 35485 
f 35203 35486 35201 
f 35486 35203 35487 
s 16
f 35206 35626 35209 
f 35626 35206 35490 
f 35677 35490 35491 
f 35208 35492 35204 
s 8
f 35105 35494 35110 
f 35494 35105 35493 
s 16
f 35109 35493 35105 
f 35493 35109 35666 
s 32
f 35111 35496 35106 
f 35496 35111 35495 
s 64
f 35110 35495 35111 
f 35495 35110 35494 
s 16
f 35106 35666 35109 
f 35666 35106 35496 
s 8
f 35217 35498 35222 
f 35498 35217 35497 
s 16
f 35221 35497 35217 
f 35497 35221 35664 
s 32
f 35223 35500 35218 
f 35500 35223 35499 
s 64
f 35222 35499 35223 
f 35499 35222 35498 
s 16
f 35218 35664 35221 
f 35664 35218 35500 
s 8
f 35229 35502 35234 
f 35502 35229 35501 
s 16
f 35233 35501 35229 
f 35501 35233 35665 
s 32
f 35235 35504 35230 
f 35504 35235 35503 
s 64
f 35234 35503 35235 
f 35503 35234 35502 
s 16
f 35230 35665 35233 
f 35665 35230 35504 
s 8
f 35094 35506 35098 
f 35506 35094 35505 
s 16
f 35097 35505 35094 
f 35505 35097 35661 
s 32
f 35099 35508 35095 
f 35508 35099 35507 
s 64
f 35098 35507 35099 
f 35507 35098 35506 
s 16
f 35095 35661 35097 
f 35661 35095 35508 
s 8
f 35582 35562 35561 
f 35562 35582 35581 
s 16
f 35630 35581 35582 
s 32
f 35584 35564 35563 
f 35564 35584 35583 
s 64
f 35561 35583 35584 
f 35583 35561 35562 
s 16
f 35687 35563 35564 
s 8
f 35586 35566 35565 
f 35566 35586 35585 
s 16
f 35619 35585 35586 
s 32
f 35588 35568 35567 
f 35568 35588 35587 
s 64
f 35565 35587 35588 
f 35587 35565 35566 
s 16
f 35672 35567 35568 
s 8
f 35590 35570 35569 
f 35570 35590 35589 
s 16
f 35617 35589 35590 
s 32
f 35592 35572 35571 
f 35572 35592 35591 
s 64
f 35569 35591 35592 
f 35591 35569 35570 
s 16
f 35673 35571 35572 
s 8
f 35594 35574 35573 
f 35574 35594 35593 
s 16
f 35621 35593 35594 
s 32
f 35596 35576 35575 
f 35576 35596 35595 
s 64
f 35573 35595 35596 
f 35595 35573 35574 
s 16
f 35680 35575 35576 
s 8
f 35598 35578 35577 
f 35578 35598 35597 
s 16
f 35624 35597 35598 
s 32
f 35600 35580 35579 
f 35580 35600 35599 
s 64
f 35577 35599 35600 
f 35599 35577 35578 
s 16
f 35658 35579 35580 
s 8
f 35513 35545 35514 
f 35545 35513 35530 
f 35135 35529 35136 
f 35529 35135 35546 
s 16
f 35674 35530 35513 
f 35676 35136 35529 
s 32
f 35515 35547 35516 
f 35547 35515 35532 
f 35138 35531 35137 
f 35531 35138 35548 
s 64
f 35514 35532 35515 
f 35532 35514 35545 
f 35137 35546 35135 
f 35546 35137 35531 
s 16
f 35628 35516 35547 
f 35143 35548 35138 
s 8
f 35517 35549 35518 
f 35549 35517 35534 
f 35146 35533 35147 
f 35533 35146 35550 
s 16
f 35675 35534 35517 
f 35657 35147 35533 
s 32
f 35519 35551 35520 
f 35551 35519 35536 
f 35149 35535 35148 
f 35535 35149 35552 
s 64
f 35518 35536 35519 
f 35536 35518 35549 
f 35148 35550 35146 
f 35550 35148 35535 
s 16
f 35615 35520 35551 
f 35154 35552 35149 
s 8
f 35521 35553 35522 
f 35553 35521 35538 
f 35269 35537 35270 
f 35537 35269 35554 
s 16
f 35684 35538 35521 
f 35682 35270 35537 
s 32
f 35523 35555 35524 
f 35555 35523 35540 
f 35275 35539 35276 
f 35539 35275 35556 
s 64
f 35522 35540 35523 
f 35540 35522 35553 
f 35276 35554 35269 
f 35554 35276 35539 
s 16
f 35627 35524 35555 
f 35273 35556 35275 
s 8
f 35525 35557 35526 
f 35557 35525 35542 
f 35280 35541 35281 
f 35541 35280 35558 
s 16
f 35659 35542 35525 
f 35667 35281 35541 
s 32
f 35527 35559 35528 
f 35559 35527 35544 
f 35286 35543 35287 
f 35543 35286 35560 
s 64
f 35526 35544 35527 
f 35544 35526 35557 
f 35287 35558 35280 
f 35558 35287 35543 
s 16
f 35625 35528 35559 
f 35284 35560 35286 
s 8
f 35128 35561 35132 
f 35561 35128 35582 
f 35510 35581 35509 
f 35581 35510 35562 
s 16
f 35131 35582 35128 
f 35582 35131 35630 
f 35687 35509 35581 
s 32
f 35133 35563 35129 
f 35563 35133 35584 
f 35512 35583 35511 
f 35583 35512 35564 
s 64
f 35132 35584 35133 
f 35584 35132 35561 
f 35511 35562 35510 
f 35562 35511 35583 
s 16
f 35129 35630 35131 
f 35630 35129 35563 
f 35616 35564 35512 
s 8
f 35140 35565 35139 
f 35565 35140 35586 
f 35514 35585 35513 
f 35585 35514 35566 
s 16
f 35144 35586 35140 
f 35586 35144 35619 
f 35672 35513 35585 
s 32
f 35141 35567 35142 
f 35567 35141 35588 
f 35516 35587 35515 
f 35587 35516 35568 
s 64
f 35139 35588 35141 
f 35588 35139 35565 
f 35515 35566 35514 
f 35566 35515 35587 
s 16
f 35142 35619 35144 
f 35619 35142 35567 
f 35674 35568 35516 
s 8
f 35151 35569 35150 
f 35569 35151 35590 
f 35518 35589 35517 
f 35589 35518 35570 
s 16
f 35155 35590 35151 
f 35590 35155 35617 
f 35673 35517 35589 
s 32
f 35152 35571 35153 
f 35571 35152 35592 
f 35520 35591 35519 
f 35591 35520 35572 
s 64
f 35150 35592 35152 
f 35592 35150 35569 
f 35519 35570 35518 
f 35570 35519 35591 
s 16
f 35153 35617 35155 
f 35617 35153 35571 
f 35675 35572 35520 
s 8
f 35271 35573 35272 
f 35573 35271 35594 
f 35522 35593 35521 
f 35593 35522 35574 
s 16
f 35274 35594 35271 
f 35594 35274 35621 
f 35680 35521 35593 
s 32
f 35277 35575 35278 
f 35575 35277 35596 
f 35524 35595 35523 
f 35595 35524 35576 
s 64
f 35272 35596 35277 
f 35596 35272 35573 
f 35523 35574 35522 
f 35574 35523 35595 
s 16
f 35278 35621 35274 
f 35621 35278 35575 
f 35684 35576 35524 
s 8
f 35282 35577 35283 
f 35577 35282 35598 
f 35526 35597 35525 
f 35597 35526 35578 
s 16
f 35285 35598 35282 
f 35598 35285 35624 
f 35658 35525 35597 
s 32
f 35288 35579 35289 
f 35579 35288 35600 
f 35528 35599 35527 
f 35599 35528 35580 
s 64
f 35283 35600 35288 
f 35600 35283 35577 
f 35527 35578 35526 
f 35578 35527 35599 
s 16
f 35289 35624 35285 
f 35624 35289 35579 
f 35659 35580 35528 
s 8
f 35302 35301 35303 
f 35301 35302 35601 
s 16
f 35304 35601 35302 
f 35601 35304 35602 
f 35307 35602 35304 
f 35602 35307 35603 
s off
f 35272 35632 35604 
f 35278 35604 35632 
f 35299 35605 35636 
f 35293 35636 35605 
f 35139 35633 35606 
f 35142 35606 35633 
f 35240 35634 35607 
f 35246 35607 35634 
f 35163 35608 35635 
f 35160 35635 35608 
f 35121 35637 35686 
f 35686 35123 35122 
f 35303 35636 35610 
f 35307 35610 35636 
f 35283 35632 35611 
f 35289 35611 35632 
f 35150 35633 35612 
f 35153 35612 35633 
f 35251 35634 35613 
f 35257 35613 35634 
f 35171 35635 35614 
f 35174 35614 35635 
s 16
f 35533 35615 35657 
f 35551 35657 35615 
f 35130 35509 35616 
f 35616 35127 35130 
f 35520 35615 35675 
f 35534 35675 35615 
f 35454 35663 35618 
f 35446 35618 35663 
f 35548 35143 35676 
f 35136 35676 35143 
f 35468 35184 35681 
f 35178 35681 35184 
f 35556 35273 35682 
f 35270 35682 35273 
f 35421 35683 35669 
f 35431 35669 35683 
f 35444 35175 35671 
f 35168 35671 35175 
f 35542 35659 35625 
f 35528 35625 35659 
f 35541 35625 35667 
f 35559 35667 35625 
f 35481 35677 35668 
f 35491 35668 35677 
f 35576 35684 35680 
f 35521 35680 35684 
f 35568 35674 35672 
f 35513 35672 35674 
f 35469 35678 35685 
f 35479 35685 35678 
f 35581 35630 35687 
f 35563 35687 35630 
s off
f 35660 35631 35099 
f 35100 35098 35645 
f 35283 35640 35632 
f 35277 35632 35640 
f 35272 35652 35632 
f 35288 35632 35647 
f 35150 35642 35633 
f 35141 35633 35642 
f 35139 35655 35633 
f 35152 35633 35651 
f 35256 35634 35650 
f 35251 35643 35634 
f 35245 35634 35643 
f 35240 35654 35634 
f 35173 35635 35648 
f 35171 35641 35635 
f 35162 35635 35641 
f 35160 35653 35635 
f 35303 35644 35636 
f 35298 35636 35644 
f 35306 35636 35649 
f 35293 35656 35636 
f 35123 35686 35637 
f 35637 35121 35646 
f 35094 35660 35638 
f 35099 35638 35660 
f 35133 35639 35134 
f 35128 35134 35639 
f 35283 35279 35640 
f 35279 35277 35640 
f 35171 35166 35641 
f 35166 35162 35641 
f 35150 35145 35642 
f 35145 35141 35642 
f 35251 35247 35643 
f 35247 35245 35643 
f 35303 35300 35644 
f 35300 35298 35644 
f 35645 35099 35631 
f 35646 35122 35123 
f 35279 35288 35647 
f 35166 35173 35648 
f 35300 35306 35649 
f 35247 35256 35650 
f 35145 35152 35651 
f 35272 35279 35652 
f 35160 35166 35653 
f 35240 35247 35654 
f 35139 35145 35655 
f 35293 35300 35656 
s 16
f 35552 35154 35657 
f 35147 35657 35154 
f 35597 35624 35658 
f 35579 35658 35624 
f 35580 35659 35658 
f 35525 35658 35659 
s off
f 35098 35100 35660 
f 35631 35660 35100 
s 16
f 35661 35093 35096 
f 35096 35505 35661 
f 35456 35119 35662 
f 35114 35662 35119 
f 35445 35663 35662 
f 35455 35662 35663 
f 35220 35497 35664 
f 35664 35216 35220 
f 35665 35228 35232 
f 35232 35501 35665 
f 35108 35493 35666 
f 35666 35104 35108 
f 35560 35284 35667 
f 35281 35667 35284 
f 35492 35208 35668 
f 35202 35668 35208 
f 35432 35164 35669 
f 35157 35669 35164 
f 35434 35623 35670 
f 35442 35670 35623 
f 35433 35670 35671 
f 35443 35671 35670 
f 35585 35619 35672 
f 35567 35672 35619 
f 35589 35617 35673 
f 35571 35673 35617 
f 35530 35674 35628 
f 35516 35628 35674 
f 35572 35675 35673 
f 35517 35673 35675 
f 35529 35628 35676 
f 35547 35676 35628 
f 35482 35626 35677 
f 35490 35677 35626 
f 35470 35629 35678 
f 35478 35678 35629 
f 35458 35620 35679 
f 35466 35679 35620 
f 35593 35621 35680 
f 35575 35680 35621 
f 35457 35679 35681 
f 35467 35681 35679 
f 35537 35627 35682 
f 35555 35682 35627 
f 35422 35622 35683 
f 35430 35683 35622 
f 35524 35627 35684 
f 35538 35684 35627 
f 35480 35196 35685 
f 35190 35685 35196 
s off
f 35117 35686 35609 
f 35122 35609 35686 
s 16
f 35564 35616 35687 
f 35509 35687 35616 
s 8
f 35688 35689 35690 
f 35690 35691 35688 
s 16
f 35692 35688 35691 
f 35691 35693 35692 
s 32
f 35694 35695 35696 
f 35696 35697 35694 
s 64
f 35689 35694 35697 
f 35697 35690 35689 
s 16
f 35695 35692 35693 
f 35693 35696 35695 
s 8
f 35698 35699 35700 
f 35700 35701 35698 
s 16
f 35702 35698 35701 
f 35701 35703 35702 
s 32
f 35704 35705 35706 
f 35706 35707 35704 
s 64
f 35699 35704 35707 
f 35707 35700 35699 
s 16
f 35705 35702 35703 
f 35703 35706 35705 
s 8
f 35708 35711 35710 
f 35710 35709 35708 
s 16
f 35712 35713 35711 
f 35711 35708 35712 
s 32
f 35714 35717 35716 
f 35716 35715 35714 
s 64
f 35709 35710 35717 
f 35717 35714 35709 
s 16
f 35715 35716 35713 
f 35713 35712 35715 
s 8
f 35718 35721 35720 
f 35720 35719 35718 
s 16
f 35722 35723 35721 
f 35721 35718 35722 
s 32
f 35724 35727 35726 
f 35726 35725 35724 
s 64
f 35719 35720 35727 
f 35727 35724 35719 
s 16
f 35725 35726 35723 
f 35723 35722 35725 
s off
f 35692 35695 35694 
f 35688 35692 35694 
f 35688 35694 35689 
f 35702 35705 35704 
f 35698 35702 35704 
f 35698 35704 35699 
f 35709 35714 35715 
f 35709 35715 35712 
f 35709 35712 35708 
f 35719 35724 35725 
f 35719 35725 35722 
f 35719 35722 35718 
s 8
f 35739 35742 35741 
f 35741 35740 35739 
s 16
f 35742 35739 36311 
s 32
f 35744 35747 35746 
f 35746 35745 35744 
s 64
f 35740 35741 35747 
f 35747 35744 35740 
s off
f 35728 35786 35729 
f 35729 35786 35730 
f 35731 35842 35732 
s 16
f 35745 35746 35743 
s off
f 35730 35786 35731 
f 35728 35732 35733 
s 8
f 35748 35751 35750 
f 35750 35749 35748 
s 16
f 35751 35748 36309 
s 32
f 35753 35756 35755 
f 35755 35754 35753 
s 64
f 35749 35750 35756 
f 35756 35753 35749 
s off
f 35734 35789 35735 
f 35735 35789 35736 
f 35737 35842 35738 
s 16
f 35754 35755 35752 
s off
f 35736 35789 35737 
f 35734 35738 35733 
s 8
f 35729 35739 35740 
f 35740 35728 35729 
s 16
f 35730 36311 35739 
f 35739 35729 35730 
s 32
f 35732 35744 35745 
f 35745 35731 35732 
s 64
f 35728 35740 35744 
f 35744 35732 35728 
s 16
f 35731 35745 36311 
f 36311 35730 35731 
s 8
f 35735 35748 35749 
f 35749 35734 35735 
s 16
f 35736 36309 35748 
f 35748 35735 35736 
s 32
f 35738 35753 35754 
f 35754 35737 35738 
s 64
f 35734 35749 35753 
f 35753 35738 35734 
s 16
f 35737 35754 36309 
f 36309 35736 35737 
s 8
f 35768 35769 35770 
f 35770 35771 35768 
s 16
f 36312 35768 35771 
s 32
f 35773 35774 35775 
f 35775 35776 35773 
s 64
f 35769 35773 35776 
f 35776 35770 35769 
s off
f 35757 35758 35787 
f 35758 35759 35787 
f 35760 35761 35843 
s 16
f 35772 35775 35774 
s off
f 35759 35760 35787 
f 35757 35762 35761 
s 8
f 35777 35778 35779 
f 35779 35780 35777 
s 16
f 36310 35777 35780 
s 32
f 35782 35783 35784 
f 35784 35785 35782 
s 64
f 35778 35782 35785 
f 35785 35779 35778 
s off
f 35763 35764 35788 
f 35764 35765 35788 
f 35766 35767 35843 
s 16
f 35781 35784 35783 
s off
f 35765 35766 35788 
f 35763 35762 35767 
s 8
f 35758 35757 35769 
f 35769 35768 35758 
s 16
f 35759 35758 35768 
f 35768 36312 35759 
s 32
f 35761 35760 35774 
f 35774 35773 35761 
s 64
f 35757 35761 35773 
f 35773 35769 35757 
s 16
f 35760 35759 36312 
f 36312 35774 35760 
s 8
f 35764 35763 35778 
f 35778 35777 35764 
s 16
f 35765 35764 35777 
f 35777 36310 35765 
s 32
f 35767 35766 35783 
f 35783 35782 35767 
s 64
f 35763 35767 35782 
f 35782 35778 35763 
s 16
f 35766 35765 36310 
f 36310 35783 35766 
s off
f 35731 35786 35842 
f 35728 35842 35786 
f 35760 35843 35787 
f 35757 35787 35843 
f 35763 35788 35843 
f 35766 35843 35788 
f 35737 35789 35842 
f 35734 35842 35789 
f 35790 35803 35802 
f 35793 35802 35803 
s 2
f 35794 35795 35797 
f 35797 35796 35794 
s 1
f 35798 35799 35801 
f 35801 35800 35798 
s off
f 35802 35792 35790 
f 35803 35791 35793 
s 4
f 35804 35923 35945 
f 35809 35804 35806 
f 35804 35808 35910 
f 35809 35946 35924 
s 8
f 36007 36130 36158 
f 35994 36236 36211 
f 36160 36141 36004 
f 36205 36235 35995 
f 36235 36205 36034 
f 36256 36188 36072 
f 36035 36211 36236 
f 36071 36192 36259 
f 36210 36237 35996 
f 36088 36114 36084 
f 35998 36246 36200 
f 36087 36117 36091 
f 36215 36239 35997 
f 36089 36115 36082 
f 35999 36244 36209 
f 36083 36116 36090 
f 36169 36142 36078 
f 36163 36131 36005 
f 36077 36136 36167 
f 36006 36135 36154 
f 36055 36270 36266 
f 36001 36227 36221 
f 36267 36272 36052 
f 36217 36231 36000 
f 36056 36275 36269 
f 36003 36228 36223 
f 36268 36271 36057 
f 36218 36225 36002 
f 36176 36120 36009 
f 36242 36202 36036 
f 36039 36214 36245 
f 36011 36125 36177 
s off
f 35728 35877 35842 
f 35842 35850 35732 
f 35734 35850 35842 
f 35842 35861 35738 
f 35767 35862 35843 
f 35763 35843 35851 
f 35761 35851 35843 
f 35757 35843 35878 
s 8
f 36289 36285 35992 
f 35993 36287 36290 
f 36062 36298 36304 
f 36302 36295 36061 
f 36060 36296 36305 
f 36307 36299 36063 
s off
f 35732 35850 35733 
f 35734 35733 35850 
f 35763 35851 35762 
f 35762 35851 35761 
s 8
f 36069 36288 36286 
f 36284 36291 36066 
f 36008 36124 36170 
f 36174 36122 36010 
f 36280 36276 36042 
f 36040 36278 36283 
f 36281 36279 36043 
f 36041 36277 36282 
s 4
f 35804 35910 35860 
s off
f 35738 35861 35733 
f 35762 35862 35767 
s 8
f 36191 36252 36103 
f 36097 36255 36195 
f 36101 36253 36189 
f 36193 36254 36096 
f 36250 36198 36044 
f 36045 36196 36251 
f 36168 36134 36075 
f 36074 36143 36162 
f 36156 36138 36079 
f 36076 36139 36161 
f 36084 36110 36088 
f 36082 36111 36089 
f 36091 36112 36087 
f 36090 36113 36083 
s off
f 35728 35733 35877 
f 35757 35878 35762 
s 8
f 36107 36251 36196 
f 36104 36248 36197 
f 36198 36250 36106 
f 36199 36249 36105 
s 4
f 35809 35883 35955 
s 8
f 36059 36303 36292 
f 36293 36300 36058 
f 36026 36159 36129 
f 36128 36155 36027 
f 36140 36164 36019 
f 36022 36166 36133 
f 36017 36171 36121 
f 36015 36172 36126 
f 36127 36173 36016 
f 36123 36175 36014 
f 36230 36222 36029 
f 36031 36219 36224 
f 36037 36208 36233 
f 36247 36203 36032 
f 36012 36265 36274 
f 36273 36264 36013 
f 36021 36162 36143 
f 36134 36168 36023 
f 36138 36156 36018 
f 36020 36161 36139 
f 36118 36108 36081 
f 36080 36109 36119 
f 36053 36274 36265 
f 36264 36273 36054 
s 4
f 35804 35860 35908 
f 35809 35909 35883 
f 35808 35805 35910 
s 8
f 36182 36153 35990 
f 35989 36149 36180 
f 35988 36152 36179 
f 36178 36150 35991 
f 36025 36119 36109 
f 36108 36118 36024 
f 36094 36282 36277 
f 36279 36281 36095 
f 36085 36283 36278 
f 36276 36280 36086 
f 36188 36256 36100 
f 36102 36259 36192 
s 4
f 35804 35908 35923 
f 35809 35924 35909 
s 8
f 36068 36290 36287 
f 36285 36289 36067 
f 36234 36204 36038 
f 36033 36213 36243 
f 36229 36216 36028 
f 36030 36220 36226 
f 36263 36260 36065 
f 36064 36261 36262 
f 36092 36183 36151 
f 36148 36181 36093 
f 36049 36190 36257 
f 36258 36194 36050 
f 36252 36191 36048 
f 36051 36195 36255 
f 36249 36199 36047 
f 36046 36197 36248 
f 36254 36193 36073 
f 36070 36189 36253 
f 36098 36257 36190 
f 36194 36258 36099 
s 4
f 36308 35804 35945 
f 35809 35806 35946 
s 8
f 35947 36180 36149 
f 35948 36179 36152 
f 36150 36178 35949 
f 36153 36182 35950 
f 36146 36186 35951 
f 35952 36184 36147 
f 36145 36185 35953 
f 35954 36187 36144 
s 4
f 35807 35809 35955 
s 8
f 36186 36146 35956 
f 35957 36147 36184 
f 35958 36144 36187 
f 36185 36145 35959 
f 36181 36148 35960 
f 35961 36151 36183 
f 36291 36284 35962 
f 35963 36286 36288 
f 35964 36262 36261 
f 36260 36263 35965 
f 35966 36292 36303 
f 36300 36293 35967 
f 35968 36269 36275 
f 36271 36268 35969 
f 35970 36266 36270 
f 36272 36267 35971 
f 35972 36224 36219 
f 36216 36229 35973 
f 35974 36226 36220 
f 36222 36230 35975 
f 36202 36242 35976 
f 35977 36233 36208 
f 35978 36243 36213 
f 36204 36234 35979 
f 36203 36247 35980 
f 35981 36245 36214 
f 36142 36169 35982 
f 35983 36167 36136 
f 35984 36304 36298 
f 36299 36307 35985 
f 36295 36302 35986 
f 35987 36305 36296 
f 35913 36240 36201 
f 35912 36238 36212 
f 36207 36241 35911 
f 36206 36232 35914 
f 36294 36306 35907 
f 35906 36301 36297 
f 35905 36137 36157 
f 36165 36132 35904 
f 36155 36128 35893 
f 36164 36140 35892 
f 35891 36129 36159 
f 35890 36133 36166 
f 36175 36123 35887 
f 35886 36126 36172 
f 36173 36127 35888 
f 35889 36121 36171 
f 36122 36174 35867 
f 36120 36176 35894 
f 35895 36177 36125 
f 35868 36170 36124 
f 35854 36158 36130 
f 36131 36163 35897 
f 36141 36160 35855 
f 35896 36154 36135 
f 35833 36297 36301 
f 36306 36294 35830 
f 36231 36217 35818 
f 35821 36221 36227 
f 36225 36218 35824 
f 35827 36223 36228 
f 36241 36207 35839 
f 36239 36215 35825 
f 35836 36212 36238 
f 35838 36201 36240 
f 35829 36209 36244 
f 36232 36206 35837 
f 36132 36165 35916 
f 35915 36157 36137 
f 35823 36200 36246 
f 36237 36210 35819 
f 35814 36028 35929 
f 35840 36029 35832 
f 35930 36030 35812 
f 35834 36031 35841 
f 35866 36032 35831 
f 35928 36033 35852 
f 35944 36034 35881 
f 35879 36035 35943 
f 35863 36036 35882 
f 35835 36037 35865 
f 35853 36038 35927 
f 35880 36039 35864 
f 35857 36040 35919 
f 35859 36041 35917 
f 35920 36042 35856 
f 35918 36043 35858 
f 35936 36044 35813 
f 35810 36045 35935 
f 35940 36046 35938 
f 35937 36047 35939 
f 35939 36048 35937 
f 35935 36049 35810 
f 35813 36050 35936 
f 35938 36051 35940 
f 35820 36052 35901 
f 35845 36053 35898 
f 35899 36054 35844 
f 35903 36055 35822 
f 35900 36056 35828 
f 35826 36057 35902 
f 35815 36058 35816 
f 35817 36059 35811 
f 35919 36060 35857 
f 35858 36061 35918 
f 35917 36062 35859 
f 35856 36063 35920 
f 35932 36064 35846 
f 35847 36065 35931 
f 35885 36066 35921 
f 35941 36067 35926 
f 35925 36068 35942 
f 35922 36069 35884 
f 35942 36070 35925 
f 35884 36071 35922 
f 35921 36072 35885 
f 35926 36073 35941 
f 35828 36074 35900 
f 35901 36075 35820 
f 35822 36076 35903 
f 35898 36077 35845 
f 35844 36078 35899 
f 35902 36079 35826 
f 35811 36080 35817 
f 35816 36081 35815 
f 36111 36082 35871 
f 35870 36083 36113 
f 36110 36084 35869 
f 35848 36085 35933 
f 35934 36086 35849 
f 35872 36087 36112 
f 36114 36088 35873 
f 36115 36089 35874 
f 35876 36090 36116 
f 35875 36091 36117 
f 35933 36092 35848 
f 35849 36093 35934 
f 35846 36094 35932 
f 35931 36095 35847 
f 35831 36096 35866 
f 35864 36097 35880 
f 35943 36098 35879 
f 35881 36099 35944 
f 35927 36100 35853 
f 35865 36101 35835 
f 35852 36102 35928 
f 35882 36103 35863 
f 35841 36104 35834 
f 35832 36105 35840 
f 35929 36106 35814 
f 35812 36107 35930 
f 36024 35904 36108 
f 36109 35905 36025 
f 35869 35949 36110 
f 35871 35950 36111 
f 36112 35947 35872 
f 36113 35948 35870 
f 35873 35953 36114 
f 35874 35951 36115 
f 36116 35952 35876 
f 36117 35954 35875 
f 36081 35816 36118 
f 36119 35817 36080 
f 35894 35975 36120 
f 36121 36003 36017 
f 35867 35973 36122 
f 36014 36000 36123 
f 36124 35974 35868 
f 36125 35972 35895 
f 36126 36001 36015 
f 36016 36002 36127 
f 36027 35996 36128 
f 36129 35998 36026 
f 36130 35978 35854 
f 35897 35980 36131 
f 35916 35995 36132 
f 36133 35999 36022 
f 36023 35914 36134 
f 36135 35977 35896 
f 36136 35981 35983 
f 36137 35994 35915 
f 36018 35911 36138 
f 36139 35912 36020 
f 36019 35997 36140 
f 35855 35979 36141 
f 35982 35976 36142 
f 36143 35913 36021 
f 36144 35875 35954 
f 35953 35873 36145 
f 35951 35874 36146 
f 36147 35876 35952 
f 36093 35849 36148 
f 36149 35872 35947 
f 35949 35869 36150 
f 36151 35848 36092 
f 36152 35870 35948 
f 35950 35871 36153 
f 36154 36011 36006 
f 35893 35887 36155 
f 36079 35902 36156 
f 36157 36025 35905 
f 36158 36008 36007 
f 36159 35886 35891 
f 36004 36010 36160 
f 36161 35903 36076 
f 36162 35900 36074 
f 36005 36009 36163 
f 35892 35888 36164 
f 35904 36024 36165 
f 36166 35889 35890 
f 36167 35845 36077 
f 36075 35901 36168 
f 36078 35844 36169 
f 36170 36007 36008 
f 36171 35890 35889 
f 36172 35891 35886 
f 35888 35892 36173 
f 36010 36004 36174 
f 35887 35893 36175 
f 36009 36005 36176 
f 36177 36006 36011 
f 35991 35837 36178 
f 36179 35838 35988 
f 36180 35836 35989 
f 35960 35825 36181 
f 35990 35839 36182 
f 36183 35829 35961 
f 36184 35827 35957 
f 35959 35818 36185 
f 35956 35824 36186 
f 36187 35821 35958 
f 36100 35927 36188 
f 36189 35835 36101 
f 36190 35879 36098 
f 36103 35882 36191 
f 36192 35928 36102 
f 36096 35831 36193 
f 36099 35881 36194 
f 36195 35880 36097 
f 36196 35930 36107 
f 36197 35834 36104 
f 36106 35929 36198 
f 36105 35832 36199 
f 36200 36026 35998 
f 36201 36021 35913 
f 35976 35982 36202 
f 35980 35897 36203 
f 35979 35855 36204 
f 35995 35916 36205 
f 35914 36023 36206 
f 35911 36018 36207 
f 36208 35896 35977 
f 36209 36022 35999 
f 35996 36027 36210 
f 36211 35915 35994 
f 36212 36020 35912 
f 36213 35854 35978 
f 36214 35983 35981 
f 35997 36019 36215 
f 35973 35867 36216 
f 36000 36014 36217 
f 36002 36016 36218 
f 36219 35895 35972 
f 36220 35868 35974 
f 36221 36015 36001 
f 35975 35894 36222 
f 36223 36017 36003 
f 36224 35841 36031 
f 35824 35956 36225 
f 36226 35812 36030 
f 36227 35958 35821 
f 36228 35957 35827 
f 36028 35814 36229 
f 36029 35840 36230 
f 35818 35959 36231 
f 35837 35991 36232 
f 36233 35865 36037 
f 36038 35853 36234 
f 36034 35944 36235 
f 36236 35943 36035 
f 35819 35965 36237 
f 36238 35989 35836 
f 35825 35960 36239 
f 36240 35988 35838 
f 35839 35990 36241 
f 36036 35863 36242 
f 36243 35852 36033 
f 36244 35961 35829 
f 36245 35864 36039 
f 36246 35964 35823 
f 36032 35866 36247 
f 36248 35938 36046 
f 36047 35937 36249 
f 36044 35936 36250 
f 36251 35935 36045 
f 36048 35939 36252 
f 36253 35925 36070 
f 36073 35926 36254 
f 36255 35940 36051 
f 36072 35921 36256 
f 36257 35810 36049 
f 36050 35813 36258 
f 36259 35922 36071 
f 35965 35819 36260 
f 36261 35823 35964 
f 36262 35846 36064 
f 36065 35847 36263 
f 36054 35899 36264 
f 36265 35898 36053 
f 36266 35822 36055 
f 36052 35820 36267 
f 36057 35826 36268 
f 36269 35828 36056 
f 36270 35984 35970 
f 35969 35985 36271 
f 35971 35986 36272 
f 36013 35907 36273 
f 36274 35906 36012 
f 36275 35987 35968 
f 36086 35934 36276 
f 36277 35932 36094 
f 36278 35933 36085 
f 36095 35931 36279 
f 36042 35920 36280 
f 36043 35918 36281 
f 36282 35917 36041 
f 36283 35919 36040 
f 36066 35885 36284 
f 36067 35941 36285 
f 36286 35884 36069 
f 36287 35942 36068 
f 36288 35966 35963 
f 35992 35830 36289 
f 36290 35833 35993 
f 35962 35967 36291 
f 36292 35811 36059 
f 36058 35815 36293 
f 35907 36013 36294 
f 35986 35971 36295 
f 36296 35968 35987 
f 36297 36012 35906 
f 36298 35970 35984 
f 35985 35969 36299 
f 35967 35962 36300 
f 36301 35993 35833 
f 36061 35858 36302 
f 36303 35963 35966 
f 36304 35859 36062 
f 36305 35857 36060 
f 35830 35992 36306 
f 36063 35856 36307 
s 4
f 35804 36308 35806 
s 16
f 35752 36309 35754 
f 36309 35752 35751 
f 35780 35781 36310 
f 35783 36310 35781 
f 36311 35743 35742 
f 35743 36311 35745 
f 35771 35772 36312 
f 35774 36312 35772 
s off
f 36316 36319 36315 36318 36313 36317 36314 36320 
f 36323 36326 36325 
f 36324 36325 36326 
f 36325 36324 36322 
f 36326 36323 36321 
f 36329 36331 36332 
f 36330 36332 36331 
f 36331 36328 36330 
f 36332 36327 36329 
f 36335 36337 36334 
f 36334 36337 36336 
f 36337 36333 36336 
f 36335 36333 36337 
f 36341 36344 36340 36343 36338 36342 36339 36345 
f 36347 36349 36346 
f 36349 36347 36348 
s 8
f 36351 36765 36766 
f 36766 36350 36351 
s 16
f 36765 36351 36356 
s 32
f 36352 36767 36768 
f 36768 36353 36352 
s 64
f 36350 36766 36767 
f 36767 36352 36350 
s off
f 36354 36357 36898 
s 16
f 36353 36768 36921 
s off
f 36357 36355 36898 
f 36920 36358 36354 
f 36898 36355 36359 
f 36359 36358 36905 
s 8
f 36362 36753 36754 
f 36754 36361 36362 
s 16
f 36753 36362 36368 
s 32
f 36363 36755 36756 
f 36756 36364 36363 
s 64
f 36361 36754 36755 
f 36755 36363 36361 
s off
f 36365 36369 36367 
s 16
f 36364 36756 36926 
s off
f 36369 36366 36367 
f 36365 36367 36372 
f 36372 36370 36365 
f 36367 36366 36371 
f 36371 36372 36367 
s 8
f 36706 36709 36710 
f 36710 36705 36706 
s 16
f 36923 36706 36705 
s 32
f 36712 36715 36716 
f 36716 36711 36712 
s 64
f 36709 36712 36711 
f 36711 36710 36709 
s off
f 36377 36380 36869 
s 16
f 36922 36716 36715 
s off
f 36380 36378 36869 
f 36946 36381 36377 
f 36869 36378 36382 
f 36382 36381 36906 
s 8
f 36385 36769 36770 
f 36770 36384 36385 
s 16
f 36769 36385 36390 
s 32
f 36386 36771 36772 
f 36772 36387 36386 
s 64
f 36384 36770 36771 
f 36771 36386 36384 
s off
f 36388 36391 36899 
s 16
f 36387 36772 36876 
s off
f 36391 36389 36899 
f 36394 36392 36388 
f 36899 36389 36393 
s 8
f 36790 36805 36806 
f 36806 36789 36790 
s 16
f 36888 36790 36789 
s 32
f 36792 36807 36808 
f 36808 36791 36792 
s 64
f 36805 36792 36791 
f 36791 36806 36805 
s off
f 36399 36400 36866 
f 36400 36404 36866 
f 36402 36401 36893 
s 16
f 36936 36808 36807 
s off
f 36404 36402 36866 
f 36399 36405 36401 
s 8
f 36794 36809 36810 
f 36810 36793 36794 
s 16
f 36875 36794 36793 
s 32
f 36796 36811 36812 
f 36812 36795 36796 
s 64
f 36809 36796 36795 
f 36795 36810 36809 
s off
f 36410 36411 36872 
f 36411 36415 36872 
f 36413 36412 36893 
s 16
f 36917 36812 36811 
s off
f 36415 36413 36872 
f 36410 36405 36412 
s 8
f 36682 36685 36686 
f 36686 36681 36682 
s 16
f 36943 36682 36681 
s 32
f 36688 36691 36692 
f 36692 36687 36688 
s 64
f 36685 36688 36687 
f 36687 36686 36685 
s off
f 36420 36421 36868 
f 36421 36425 36868 
f 36423 36422 36895 
s 16
f 36929 36692 36691 
s off
f 36425 36423 36868 
f 36420 36426 36422 
s 8
f 36694 36697 36698 
f 36698 36693 36694 
s 16
f 36930 36694 36693 
s 32
f 36700 36703 36704 
f 36704 36699 36700 
s 64
f 36697 36700 36699 
f 36699 36698 36697 
s off
f 36431 36432 36874 
f 36432 36436 36874 
f 36434 36433 36895 
s 16
f 36931 36704 36703 
s off
f 36436 36434 36874 
f 36431 36426 36433 
s 8
f 36718 36721 36722 
f 36722 36717 36718 
s 16
f 36939 36718 36717 
s 32
f 36724 36727 36728 
f 36728 36723 36724 
s 64
f 36721 36724 36723 
f 36723 36722 36721 
s off
f 36441 36445 36443 
s 16
f 36941 36728 36727 
s off
f 36445 36442 36443 
f 36441 36443 36448 
f 36448 36446 36441 
f 36443 36442 36447 
f 36447 36448 36443 
f 36446 36448 36447 
s 8
f 36730 36733 36734 
f 36734 36729 36730 
s 16
f 36938 36730 36729 
s 32
f 36736 36739 36740 
f 36740 36735 36736 
s 64
f 36733 36736 36735 
f 36735 36734 36733 
s off
f 36453 36457 36455 
s 16
f 36945 36740 36739 
s off
f 36457 36454 36455 
f 36453 36455 36460 
f 36460 36458 36453 
f 36455 36454 36459 
f 36459 36460 36455 
f 36458 36460 36459 
s 8
f 36742 36745 36746 
f 36746 36741 36742 
s 16
f 36937 36742 36741 
s 32
f 36748 36751 36752 
f 36752 36747 36748 
s 64
f 36745 36748 36747 
f 36747 36746 36745 
s off
f 36465 36469 36467 
s 16
f 36928 36752 36751 
s off
f 36469 36466 36467 
f 36465 36467 36472 
f 36472 36470 36465 
f 36467 36466 36471 
f 36471 36472 36467 
f 36470 36472 36471 
s 8
f 36474 36757 36758 
f 36758 36473 36474 
s 16
f 36757 36474 36480 
s 32
f 36475 36759 36760 
f 36760 36476 36475 
s 64
f 36473 36758 36759 
f 36759 36475 36473 
s off
f 36477 36481 36479 
s 16
f 36476 36760 36924 
s off
f 36481 36478 36479 
f 36477 36479 36484 
f 36484 36482 36477 
f 36479 36478 36483 
f 36483 36484 36479 
s 8
f 36486 36761 36762 
f 36762 36485 36486 
s 16
f 36761 36486 36492 
s 32
f 36487 36763 36764 
f 36764 36488 36487 
s 64
f 36485 36762 36763 
f 36763 36487 36485 
s off
f 36489 36493 36491 
s 16
f 36488 36764 36925 
s off
f 36493 36490 36491 
f 36489 36491 36496 
f 36496 36494 36489 
f 36491 36490 36495 
f 36495 36496 36491 
s 8
f 36497 36498 36499 
f 36499 36500 36497 
s 16
f 36501 36502 36499 
f 36499 36498 36501 
s 32
f 36503 36504 36505 
f 36505 36506 36503 
s 64
f 36497 36500 36505 
f 36505 36504 36497 
s off
f 36500 36499 36867 
f 36499 36502 36867 
f 36506 36505 36894 
s 16
f 36503 36506 36502 
f 36502 36501 36503 
s off
f 36502 36506 36867 
f 36500 36507 36505 
s 8
f 36508 36509 36510 
f 36510 36511 36508 
s 16
f 36512 36513 36510 
f 36510 36509 36512 
s 32
f 36514 36515 36516 
f 36516 36517 36514 
s 64
f 36508 36511 36516 
f 36516 36515 36508 
s off
f 36511 36510 36873 
f 36510 36513 36873 
f 36517 36516 36894 
s 16
f 36514 36517 36513 
f 36513 36512 36514 
s off
f 36513 36517 36873 
f 36511 36507 36516 
s 8
f 36518 36519 36520 
f 36520 36521 36518 
s 16
f 36522 36523 36520 
f 36520 36519 36522 
s 32
f 36524 36525 36526 
f 36526 36527 36524 
s 64
f 36518 36521 36526 
f 36526 36525 36518 
s off
f 36520 36523 36528 
s 16
f 36524 36527 36523 
f 36523 36522 36524 
s off
f 36523 36527 36528 
f 36520 36528 36521 
f 36528 36527 36526 
f 36521 36528 36526 
f 36370 36372 36371 
f 36482 36484 36483 
f 36494 36496 36495 
s 8
f 36798 36813 36814 
f 36814 36797 36798 
s 16
f 36887 36798 36797 
s 32
f 36800 36815 36816 
f 36816 36799 36800 
s 64
f 36813 36800 36799 
f 36799 36814 36813 
s off
f 36532 36531 36864 
f 36531 36534 36864 
f 36538 36537 36892 
s 16
f 36942 36816 36815 
s off
f 36534 36538 36864 
f 36532 36539 36537 
s 8
f 36802 36817 36818 
f 36818 36801 36802 
s 16
f 36885 36802 36801 
s 32
f 36804 36819 36820 
f 36820 36803 36804 
s 64
f 36817 36804 36803 
f 36803 36818 36817 
s off
f 36543 36542 36871 
f 36542 36545 36871 
f 36549 36548 36892 
s 16
f 36927 36820 36819 
s off
f 36545 36549 36871 
f 36543 36539 36548 
s 8
f 36550 36551 36552 
f 36552 36553 36550 
s 16
f 36554 36555 36552 
f 36552 36551 36554 
s 32
f 36556 36557 36558 
f 36558 36559 36556 
s 64
f 36550 36553 36558 
f 36558 36557 36550 
s off
f 36553 36552 36865 
f 36552 36555 36865 
f 36559 36558 36896 
s 16
f 36556 36559 36555 
f 36555 36554 36556 
s off
f 36555 36559 36865 
f 36553 36560 36558 
s 32
f 36863 36565 36566 
f 36566 36567 36863 
s 64
f 36561 36563 36566 
f 36566 36565 36561 
s off
f 36563 36562 36870 
f 36562 36564 36870 
f 36567 36566 36896 
f 36564 36567 36870 
f 36563 36560 36566 
s 8
f 36568 36569 36570 
f 36570 36571 36568 
s 16
f 36572 36573 36570 
f 36570 36569 36572 
s 32
f 36574 36575 36576 
f 36576 36577 36574 
s 64
f 36568 36571 36576 
f 36576 36575 36568 
s off
f 36570 36573 36578 
s 16
f 36574 36577 36573 
f 36573 36572 36574 
s off
f 36573 36577 36578 
f 36570 36578 36571 
f 36578 36577 36576 
f 36571 36578 36576 
s 8
f 36579 36580 36581 
f 36581 36582 36579 
s 16
f 36583 36584 36581 
f 36581 36580 36583 
s 32
f 36585 36586 36587 
f 36587 36588 36585 
s 64
f 36579 36582 36587 
f 36587 36586 36579 
s off
f 36581 36584 36589 
s 16
f 36585 36588 36584 
f 36584 36583 36585 
s off
f 36584 36588 36589 
f 36581 36589 36582 
f 36589 36588 36587 
f 36582 36589 36587 
s 8
f 36590 36591 36592 
f 36592 36593 36590 
s 16
f 36594 36595 36592 
f 36592 36591 36594 
s 32
f 36596 36597 36598 
f 36598 36599 36596 
s 64
f 36590 36593 36598 
f 36598 36597 36590 
s off
f 36592 36595 36600 
s 16
f 36596 36599 36595 
f 36595 36594 36596 
s off
f 36595 36599 36600 
f 36592 36600 36593 
f 36600 36599 36598 
f 36593 36600 36598 
s 1
f 36612 36602 36601 
f 36601 36611 36612 
s 4
f 36613 36603 36602 
f 36602 36612 36613 
f 36614 36604 36603 
f 36603 36613 36614 
f 36615 36605 36604 
f 36604 36614 36615 
f 36616 36606 36605 
f 36605 36615 36616 
f 36617 36607 36606 
f 36606 36616 36617 
f 36618 36608 36607 
f 36607 36617 36618 
f 36619 36609 36608 
f 36608 36618 36619 
s 2
f 36620 36610 36609 
f 36609 36619 36620 
s 4
f 36611 36601 36610 
f 36610 36620 36611 
s 16
f 36601 36602 36603 
f 36601 36603 36604 
f 36608 36609 36610 
f 36607 36608 36610 
f 36606 36607 36610 
f 36605 36606 36610 
f 36604 36605 36610 
f 36601 36604 36610 
f 36620 36619 36618 
f 36620 36618 36617 
f 36620 36617 36616 
f 36620 36616 36615 
f 36620 36615 36614 
f 36611 36620 36614 
f 36611 36614 36613 
f 36611 36613 36612 
s 1
f 36632 36622 36621 
f 36621 36631 36632 
s 4
f 36633 36623 36622 
f 36622 36632 36633 
f 36634 36624 36623 
f 36623 36633 36634 
f 36635 36625 36624 
f 36624 36634 36635 
f 36636 36626 36625 
f 36625 36635 36636 
f 36637 36627 36626 
f 36626 36636 36637 
f 36638 36628 36627 
f 36627 36637 36638 
f 36639 36629 36628 
f 36628 36638 36639 
s 2
f 36640 36630 36629 
f 36629 36639 36640 
s 4
f 36631 36621 36630 
f 36630 36640 36631 
s 16
f 36621 36622 36623 
f 36621 36623 36624 
f 36628 36629 36630 
f 36627 36628 36630 
f 36626 36627 36630 
f 36625 36626 36630 
f 36624 36625 36630 
f 36621 36624 36630 
f 36640 36639 36638 
f 36640 36638 36637 
f 36640 36637 36636 
f 36640 36636 36635 
f 36640 36635 36634 
f 36631 36640 36634 
f 36631 36634 36633 
f 36631 36633 36632 
s 1
f 36652 36642 36641 
f 36641 36651 36652 
s 4
f 36653 36643 36642 
f 36642 36652 36653 
f 36654 36644 36643 
f 36643 36653 36654 
f 36655 36645 36644 
f 36644 36654 36655 
f 36656 36646 36645 
f 36645 36655 36656 
f 36657 36647 36646 
f 36646 36656 36657 
f 36658 36648 36647 
f 36647 36657 36658 
f 36659 36649 36648 
f 36648 36658 36659 
s 2
f 36660 36650 36649 
f 36649 36659 36660 
s 4
f 36651 36641 36650 
f 36650 36660 36651 
s 16
f 36641 36642 36643 
f 36641 36643 36644 
f 36648 36649 36650 
f 36647 36648 36650 
f 36646 36647 36650 
f 36645 36646 36650 
f 36644 36645 36650 
f 36641 36644 36650 
f 36660 36659 36658 
f 36660 36658 36657 
f 36660 36657 36656 
f 36660 36656 36655 
f 36660 36655 36654 
f 36651 36660 36654 
f 36651 36654 36653 
f 36651 36653 36652 
s 1
f 36672 36662 36661 
f 36661 36671 36672 
s 4
f 36673 36663 36662 
f 36662 36672 36673 
f 36674 36664 36663 
f 36663 36673 36674 
f 36675 36665 36664 
f 36664 36674 36675 
f 36676 36666 36665 
f 36665 36675 36676 
f 36677 36667 36666 
f 36666 36676 36677 
f 36678 36668 36667 
f 36667 36677 36678 
f 36679 36669 36668 
f 36668 36678 36679 
s 2
f 36680 36670 36669 
f 36669 36679 36680 
s 4
f 36671 36661 36670 
f 36670 36680 36671 
s 16
f 36661 36662 36663 
f 36661 36663 36664 
f 36668 36669 36670 
f 36667 36668 36670 
f 36666 36667 36670 
f 36665 36666 36670 
f 36664 36665 36670 
f 36661 36664 36670 
f 36680 36679 36678 
f 36680 36678 36677 
f 36680 36677 36676 
f 36680 36676 36675 
f 36680 36675 36674 
f 36671 36680 36674 
f 36671 36674 36673 
f 36671 36673 36672 
s 8
f 36421 36420 36684 
f 36684 36683 36421 
f 36683 36684 36685 
f 36685 36682 36683 
f 36416 36417 36681 
f 36681 36686 36416 
s 16
f 36425 36421 36683 
f 36683 36882 36425 
f 36882 36683 36682 
f 36929 36681 36417 
s 32
f 36422 36423 36690 
f 36690 36689 36422 
f 36689 36690 36691 
f 36691 36688 36689 
f 36419 36418 36687 
f 36687 36692 36419 
s 64
f 36420 36422 36689 
f 36689 36684 36420 
f 36684 36689 36688 
f 36688 36685 36684 
f 36418 36416 36686 
f 36686 36687 36418 
s 16
f 36423 36425 36882 
f 36882 36690 36423 
f 36943 36691 36690 
f 36424 36419 36692 
s 8
f 36432 36431 36696 
f 36696 36695 36432 
f 36695 36696 36697 
f 36697 36694 36695 
f 36427 36428 36693 
f 36693 36698 36427 
s 16
f 36436 36432 36695 
f 36695 36883 36436 
f 36883 36695 36694 
f 36931 36693 36428 
s 32
f 36433 36434 36702 
f 36702 36701 36433 
f 36701 36702 36703 
f 36703 36700 36701 
f 36430 36429 36699 
f 36699 36704 36430 
s 64
f 36431 36433 36701 
f 36701 36696 36431 
f 36696 36701 36700 
f 36700 36697 36696 
f 36429 36427 36698 
f 36698 36699 36429 
s 16
f 36434 36436 36883 
f 36883 36702 36434 
f 36930 36703 36702 
f 36435 36430 36704 
s 8
f 36377 36381 36708 
f 36708 36707 36377 
f 36706 36707 36708 
f 36708 36709 36706 
f 36373 36374 36705 
f 36705 36710 36373 
s 16
f 36380 36377 36707 
f 36707 36878 36380 
f 36878 36707 36706 
f 36922 36705 36374 
s 32
f 36382 36378 36714 
f 36714 36713 36382 
f 36713 36714 36715 
f 36715 36712 36713 
f 36376 36375 36711 
f 36711 36716 36376 
s 64
f 36381 36382 36713 
f 36713 36708 36381 
f 36708 36713 36712 
f 36712 36709 36708 
f 36375 36373 36710 
f 36710 36711 36375 
s 16
f 36378 36380 36878 
f 36878 36714 36378 
f 36923 36715 36714 
f 36379 36376 36716 
s 8
f 36441 36446 36720 
f 36720 36719 36441 
f 36719 36720 36721 
f 36721 36718 36719 
f 36437 36438 36717 
f 36717 36722 36437 
s 16
f 36445 36441 36719 
f 36719 36880 36445 
f 36880 36719 36718 
f 36941 36717 36438 
s 32
f 36447 36442 36726 
f 36726 36725 36447 
f 36725 36726 36727 
f 36727 36724 36725 
f 36440 36439 36723 
f 36723 36728 36440 
s 64
f 36446 36447 36725 
f 36725 36720 36446 
f 36720 36725 36724 
f 36724 36721 36720 
f 36439 36437 36722 
f 36722 36723 36439 
s 16
f 36442 36445 36880 
f 36880 36726 36442 
f 36939 36727 36726 
f 36444 36440 36728 
s 8
f 36453 36458 36732 
f 36732 36731 36453 
f 36731 36732 36733 
f 36733 36730 36731 
f 36449 36450 36729 
f 36729 36734 36449 
s 16
f 36457 36453 36731 
f 36731 36889 36457 
f 36889 36731 36730 
f 36945 36729 36450 
s 32
f 36459 36454 36738 
f 36738 36737 36459 
f 36737 36738 36739 
f 36739 36736 36737 
f 36452 36451 36735 
f 36735 36740 36452 
s 64
f 36458 36459 36737 
f 36737 36732 36458 
f 36732 36737 36736 
f 36736 36733 36732 
f 36451 36449 36734 
f 36734 36735 36451 
s 16
f 36454 36457 36889 
f 36889 36738 36454 
f 36938 36739 36738 
f 36456 36452 36740 
s 8
f 36465 36470 36744 
f 36744 36743 36465 
f 36743 36744 36745 
f 36745 36742 36743 
f 36461 36462 36741 
f 36741 36746 36461 
s 16
f 36469 36465 36743 
f 36743 36886 36469 
f 36886 36743 36742 
f 36928 36741 36462 
s 32
f 36471 36466 36750 
f 36750 36749 36471 
f 36749 36750 36751 
f 36751 36748 36749 
f 36464 36463 36747 
f 36747 36752 36464 
s 64
f 36470 36471 36749 
f 36749 36744 36470 
f 36744 36749 36748 
f 36748 36745 36744 
f 36463 36461 36746 
f 36746 36747 36463 
s 16
f 36466 36469 36886 
f 36886 36750 36466 
f 36937 36751 36750 
f 36468 36464 36752 
s 8
f 36365 36370 36754 
f 36754 36753 36365 
s 16
f 36369 36365 36753 
f 36753 36926 36369 
s 32
f 36371 36366 36756 
f 36756 36755 36371 
s 64
f 36370 36371 36755 
f 36755 36754 36370 
s 16
f 36366 36369 36926 
f 36926 36756 36366 
s 8
f 36477 36482 36758 
f 36758 36757 36477 
s 16
f 36481 36477 36757 
f 36757 36924 36481 
s 32
f 36483 36478 36760 
f 36760 36759 36483 
s 64
f 36482 36483 36759 
f 36759 36758 36482 
s 16
f 36478 36481 36924 
f 36924 36760 36478 
s 8
f 36489 36494 36762 
f 36762 36761 36489 
s 16
f 36493 36489 36761 
f 36761 36925 36493 
s 32
f 36495 36490 36764 
f 36764 36763 36495 
s 64
f 36494 36495 36763 
f 36763 36762 36494 
s 16
f 36490 36493 36925 
f 36925 36764 36490 
s 8
f 36354 36358 36766 
f 36766 36765 36354 
s 16
f 36357 36354 36765 
f 36765 36921 36357 
s 32
f 36359 36355 36768 
f 36768 36767 36359 
s 64
f 36358 36359 36767 
f 36767 36766 36358 
s 16
f 36355 36357 36921 
f 36921 36768 36355 
s 8
f 36842 36821 36822 
f 36822 36841 36842 
s 16
f 36890 36842 36841 
s 32
f 36844 36823 36824 
f 36824 36843 36844 
s 64
f 36821 36844 36843 
f 36843 36822 36821 
s 16
f 36947 36824 36823 
s 8
f 36846 36825 36826 
f 36826 36845 36846 
s 16
f 36879 36846 36845 
s 32
f 36848 36827 36828 
f 36828 36847 36848 
s 64
f 36825 36848 36847 
f 36847 36826 36825 
s 16
f 36932 36828 36827 
s 8
f 36850 36829 36830 
f 36830 36849 36850 
s 16
f 36877 36850 36849 
s 32
f 36852 36831 36832 
f 36832 36851 36852 
s 64
f 36829 36852 36851 
f 36851 36830 36829 
s 16
f 36933 36832 36831 
s 8
f 36854 36833 36834 
f 36834 36853 36854 
s 16
f 36881 36854 36853 
s 32
f 36856 36835 36836 
f 36836 36855 36856 
s 64
f 36833 36856 36855 
f 36855 36834 36833 
s 16
f 36940 36836 36835 
s 8
f 36858 36837 36838 
f 36838 36857 36858 
s 16
f 36884 36858 36857 
s 32
f 36860 36839 36840 
f 36840 36859 36860 
s 64
f 36837 36860 36859 
f 36859 36838 36837 
s 16
f 36918 36840 36839 
s 8
f 36773 36774 36805 
f 36805 36790 36773 
f 36395 36396 36789 
f 36789 36806 36395 
s 16
f 36934 36773 36790 
f 36936 36789 36396 
s 32
f 36775 36776 36807 
f 36807 36792 36775 
f 36398 36397 36791 
f 36791 36808 36398 
s 64
f 36774 36775 36792 
f 36792 36805 36774 
f 36397 36395 36806 
f 36806 36791 36397 
s 16
f 36888 36807 36776 
f 36403 36398 36808 
s 8
f 36777 36778 36809 
f 36809 36794 36777 
f 36406 36407 36793 
f 36793 36810 36406 
s 16
f 36935 36777 36794 
f 36917 36793 36407 
s 32
f 36779 36780 36811 
f 36811 36796 36779 
f 36409 36408 36795 
f 36795 36812 36409 
s 64
f 36778 36779 36796 
f 36796 36809 36778 
f 36408 36406 36810 
f 36810 36795 36408 
s 16
f 36875 36811 36780 
f 36414 36409 36812 
s 8
f 36781 36782 36813 
f 36813 36798 36781 
f 36529 36530 36797 
f 36797 36814 36529 
s 16
f 36944 36781 36798 
f 36942 36797 36530 
s 32
f 36783 36784 36815 
f 36815 36800 36783 
f 36535 36536 36799 
f 36799 36816 36535 
s 64
f 36782 36783 36800 
f 36800 36813 36782 
f 36536 36529 36814 
f 36814 36799 36536 
s 16
f 36887 36815 36784 
f 36533 36535 36816 
s 8
f 36785 36786 36817 
f 36817 36802 36785 
f 36540 36541 36801 
f 36801 36818 36540 
s 16
f 36919 36785 36802 
f 36927 36801 36541 
s 32
f 36787 36788 36819 
f 36819 36804 36787 
f 36546 36547 36803 
f 36803 36820 36546 
s 64
f 36786 36787 36804 
f 36804 36817 36786 
f 36547 36540 36818 
f 36818 36803 36547 
s 16
f 36885 36819 36788 
f 36544 36546 36820 
s 8
f 36388 36392 36821 
f 36821 36842 36388 
f 36770 36769 36841 
f 36841 36822 36770 
s 16
f 36391 36388 36842 
f 36842 36890 36391 
f 36947 36841 36769 
s 32
f 36393 36389 36823 
f 36823 36844 36393 
f 36772 36771 36843 
f 36843 36824 36772 
s 64
f 36392 36393 36844 
f 36844 36821 36392 
f 36771 36770 36822 
f 36822 36843 36771 
s 16
f 36389 36391 36890 
f 36890 36823 36389 
f 36876 36772 36824 
s 8
f 36400 36399 36825 
f 36825 36846 36400 
f 36774 36773 36845 
f 36845 36826 36774 
s 16
f 36404 36400 36846 
f 36846 36879 36404 
f 36932 36845 36773 
s 32
f 36401 36402 36827 
f 36827 36848 36401 
f 36776 36775 36847 
f 36847 36828 36776 
s 64
f 36399 36401 36848 
f 36848 36825 36399 
f 36775 36774 36826 
f 36826 36847 36775 
s 16
f 36402 36404 36879 
f 36879 36827 36402 
f 36934 36776 36828 
s 8
f 36411 36410 36829 
f 36829 36850 36411 
f 36778 36777 36849 
f 36849 36830 36778 
s 16
f 36415 36411 36850 
f 36850 36877 36415 
f 36933 36849 36777 
s 32
f 36412 36413 36831 
f 36831 36852 36412 
f 36780 36779 36851 
f 36851 36832 36780 
s 64
f 36410 36412 36852 
f 36852 36829 36410 
f 36779 36778 36830 
f 36830 36851 36779 
s 16
f 36413 36415 36877 
f 36877 36831 36413 
f 36935 36780 36832 
s 8
f 36531 36532 36833 
f 36833 36854 36531 
f 36782 36781 36853 
f 36853 36834 36782 
s 16
f 36534 36531 36854 
f 36854 36881 36534 
f 36940 36853 36781 
s 32
f 36537 36538 36835 
f 36835 36856 36537 
f 36784 36783 36855 
f 36855 36836 36784 
s 64
f 36532 36537 36856 
f 36856 36833 36532 
f 36783 36782 36834 
f 36834 36855 36783 
s 16
f 36538 36534 36881 
f 36881 36835 36538 
f 36944 36784 36836 
s 8
f 36542 36543 36837 
f 36837 36858 36542 
f 36786 36785 36857 
f 36857 36838 36786 
s 16
f 36545 36542 36858 
f 36858 36884 36545 
f 36918 36857 36785 
s 32
f 36548 36549 36839 
f 36839 36860 36548 
f 36788 36787 36859 
f 36859 36840 36788 
s 64
f 36543 36548 36860 
f 36860 36837 36543 
f 36787 36786 36838 
f 36838 36859 36787 
s 16
f 36549 36545 36884 
f 36884 36839 36549 
f 36919 36788 36840 
s 8
f 36562 36563 36561 
f 36561 36861 36562 
s 16
f 36564 36562 36861 
f 36861 36862 36564 
f 36567 36564 36862 
f 36862 36863 36567 
s off
f 36532 36864 36892 
f 36538 36892 36864 
f 36559 36896 36865 
f 36553 36865 36896 
f 36399 36866 36893 
f 36402 36893 36866 
f 36500 36867 36894 
f 36506 36894 36867 
f 36423 36895 36868 
f 36420 36868 36895 
f 36381 36946 36897 
f 36946 36382 36383 
f 36563 36870 36896 
f 36567 36896 36870 
f 36543 36871 36892 
f 36549 36892 36871 
f 36410 36872 36893 
f 36413 36893 36872 
f 36511 36873 36894 
f 36517 36894 36873 
f 36431 36874 36895 
f 36434 36895 36874 
s 16
f 36793 36917 36875 
f 36811 36875 36917 
f 36390 36876 36769 
f 36876 36390 36387 
f 36780 36935 36875 
f 36794 36875 36935 
f 36714 36878 36923 
f 36706 36923 36878 
f 36808 36936 36403 
f 36396 36403 36936 
f 36728 36941 36444 
f 36438 36444 36941 
f 36816 36942 36533 
f 36530 36533 36942 
f 36681 36929 36943 
f 36691 36943 36929 
f 36704 36931 36435 
f 36428 36435 36931 
f 36802 36885 36919 
f 36788 36919 36885 
f 36801 36927 36885 
f 36819 36885 36927 
f 36741 36928 36937 
f 36751 36937 36928 
f 36836 36940 36944 
f 36781 36944 36940 
f 36828 36932 36934 
f 36773 36934 36932 
f 36729 36945 36938 
f 36739 36938 36945 
f 36841 36947 36890 
f 36823 36890 36947 
s off
f 36920 36359 36891 
f 36360 36905 36358 
f 36543 36892 36900 
f 36537 36900 36892 
f 36532 36892 36912 
f 36548 36907 36892 
f 36410 36893 36902 
f 36401 36902 36893 
f 36399 36893 36915 
f 36412 36911 36893 
f 36516 36910 36894 
f 36511 36894 36903 
f 36505 36903 36894 
f 36500 36894 36914 
f 36433 36908 36895 
f 36431 36895 36901 
f 36422 36901 36895 
f 36420 36895 36913 
f 36563 36896 36904 
f 36558 36904 36896 
f 36566 36909 36896 
f 36553 36896 36916 
f 36383 36897 36946 
f 36897 36906 36381 
f 36354 36898 36920 
f 36359 36920 36898 
f 36393 36394 36899 
f 36388 36899 36394 
f 36543 36900 36539 
f 36539 36900 36537 
f 36431 36901 36426 
f 36426 36901 36422 
f 36410 36902 36405 
f 36405 36902 36401 
f 36511 36903 36507 
f 36507 36903 36505 
f 36563 36904 36560 
f 36560 36904 36558 
f 36905 36891 36359 
f 36906 36383 36382 
f 36539 36907 36548 
f 36426 36908 36433 
f 36560 36909 36566 
f 36507 36910 36516 
f 36405 36911 36412 
f 36532 36912 36539 
f 36420 36913 36426 
f 36500 36914 36507 
f 36399 36915 36405 
f 36553 36916 36560 
s 16
f 36812 36917 36414 
f 36407 36414 36917 
f 36857 36918 36884 
f 36839 36884 36918 
f 36840 36918 36919 
f 36785 36919 36918 
s off
f 36358 36920 36360 
f 36891 36360 36920 
s 16
f 36921 36356 36353 
f 36356 36921 36765 
f 36716 36922 36379 
f 36374 36379 36922 
f 36705 36922 36923 
f 36715 36923 36922 
f 36480 36924 36757 
f 36924 36480 36476 
f 36925 36492 36488 
f 36492 36925 36761 
f 36368 36926 36753 
f 36926 36368 36364 
f 36820 36927 36544 
f 36541 36544 36927 
f 36752 36928 36468 
f 36462 36468 36928 
f 36692 36929 36424 
f 36417 36424 36929 
f 36694 36930 36883 
f 36702 36883 36930 
f 36693 36931 36930 
f 36703 36930 36931 
f 36845 36932 36879 
f 36827 36879 36932 
f 36849 36933 36877 
f 36831 36877 36933 
f 36790 36888 36934 
f 36776 36934 36888 
f 36832 36933 36935 
f 36777 36935 36933 
f 36789 36936 36888 
f 36807 36888 36936 
f 36742 36937 36886 
f 36750 36886 36937 
f 36730 36938 36889 
f 36738 36889 36938 
f 36718 36939 36880 
f 36726 36880 36939 
f 36853 36940 36881 
f 36835 36881 36940 
f 36717 36941 36939 
f 36727 36939 36941 
f 36797 36942 36887 
f 36815 36887 36942 
f 36682 36943 36882 
f 36690 36882 36943 
f 36784 36944 36887 
f 36798 36887 36944 
f 36740 36945 36456 
f 36450 36456 36945 
s off
f 36377 36869 36946 
f 36382 36946 36869 
s 16
f 36824 36947 36876 
f 36769 36876 36947 
s off
f 36949 36948 36951 
f 36951 36950 36949 
s 8
f 36952 36954 36953 
f 36954 36952 36955 
s 16
f 36956 36955 36952 
f 36955 36956 36957 
s 32
f 36958 36960 36959 
f 36960 36958 36961 
s 64
f 36953 36961 36958 
f 36961 36953 36954 
s 16
f 36959 36957 36956 
f 36957 36959 36960 
s 8
f 36962 36964 36963 
f 36964 36962 36965 
s 16
f 36966 36965 36962 
f 36965 36966 36967 
s 32
f 36968 36970 36969 
f 36970 36968 36971 
s 64
f 36963 36971 36968 
f 36971 36963 36964 
s 16
f 36969 36967 36966 
f 36967 36969 36970 
s 8
f 36972 36974 36975 
f 36974 36972 36973 
s 16
f 36976 36975 36977 
f 36975 36976 36972 
s 32
f 36978 36980 36981 
f 36980 36978 36979 
s 64
f 36973 36981 36974 
f 36981 36973 36978 
s 16
f 36979 36977 36980 
f 36977 36979 36976 
s 8
f 36982 36984 36985 
f 36984 36982 36983 
s 16
f 36986 36985 36987 
f 36985 36986 36982 
s 32
f 36988 36990 36991 
f 36990 36988 36989 
s 64
f 36983 36991 36984 
f 36991 36983 36988 
s 16
f 36989 36987 36990 
f 36987 36989 36986 
s off
f 36956 36958 36959 
f 36952 36958 36956 
f 36952 36953 36958 
f 36966 36968 36969 
f 36962 36968 36966 
f 36962 36963 36968 
f 36973 36979 36978 
f 36973 36976 36979 
f 36973 36972 36976 
f 36983 36989 36988 
f 36983 36986 36989 
f 36983 36982 36986 
s 8
f 37003 37005 37006 
f 37005 37003 37004 
s 16
f 37006 37575 37003 
s 32
f 37008 37010 37011 
f 37010 37008 37009 
s 64
f 37004 37011 37005 
f 37011 37004 37008 
s off
f 36992 36993 37050 
f 36993 36994 37050 
f 36995 36996 37106 
s 16
f 37009 37007 37010 
s off
f 36994 36995 37050 
f 36992 36997 36996 
s 8
f 37012 37014 37015 
f 37014 37012 37013 
s 16
f 37015 37573 37012 
s 32
f 37017 37019 37020 
f 37019 37017 37018 
s 64
f 37013 37020 37014 
f 37020 37013 37017 
s off
f 36998 36999 37053 
f 36999 37000 37053 
f 37001 37002 37106 
s 16
f 37018 37016 37019 
s off
f 37000 37001 37053 
f 36998 36997 37002 
s 8
f 36993 37004 37003 
f 37004 36993 36992 
s 16
f 36994 37003 37575 
f 37003 36994 36993 
s 32
f 36996 37009 37008 
f 37009 36996 36995 
s 64
f 36992 37008 37004 
f 37008 36992 36996 
s 16
f 36995 37575 37009 
f 37575 36995 36994 
s 8
f 36999 37013 37012 
f 37013 36999 36998 
s 16
f 37000 37012 37573 
f 37012 37000 36999 
s 32
f 37002 37018 37017 
f 37018 37002 37001 
s 64
f 36998 37017 37013 
f 37017 36998 37002 
s 16
f 37001 37573 37018 
f 37573 37001 37000 
s 8
f 37032 37034 37033 
f 37034 37032 37035 
s 16
f 37576 37035 37032 
s 32
f 37037 37039 37038 
f 37039 37037 37040 
s 64
f 37033 37040 37037 
f 37040 37033 37034 
s off
f 37021 37051 37022 
f 37022 37051 37023 
f 37024 37107 37025 
s 16
f 37036 37038 37039 
s off
f 37023 37051 37024 
f 37021 37025 37026 
s 8
f 37041 37043 37042 
f 37043 37041 37044 
s 16
f 37574 37044 37041 
s 32
f 37046 37048 37047 
f 37048 37046 37049 
s 64
f 37042 37049 37046 
f 37049 37042 37043 
s off
f 37027 37052 37028 
f 37028 37052 37029 
f 37030 37107 37031 
s 16
f 37045 37047 37048 
s off
f 37029 37052 37030 
f 37027 37031 37026 
s 8
f 37022 37033 37021 
f 37033 37022 37032 
s 16
f 37023 37032 37022 
f 37032 37023 37576 
s 32
f 37025 37038 37024 
f 37038 37025 37037 
s 64
f 37021 37037 37025 
f 37037 37021 37033 
s 16
f 37024 37576 37023 
f 37576 37024 37038 
s 8
f 37028 37042 37027 
f 37042 37028 37041 
s 16
f 37029 37041 37028 
f 37041 37029 37574 
s 32
f 37031 37047 37030 
f 37047 37031 37046 
s 64
f 37027 37046 37031 
f 37046 37027 37042 
s 16
f 37030 37574 37029 
f 37574 37030 37047 
s off
f 36995 37106 37050 
f 36992 37050 37106 
f 37024 37051 37107 
f 37021 37107 37051 
f 37027 37107 37052 
f 37030 37052 37107 
f 37001 37106 37053 
f 36998 37053 37106 
f 37054 37066 37067 
f 37057 37067 37066 
s 2
f 37058 37061 37059 
f 37061 37058 37060 
s 1
f 37062 37065 37063 
f 37065 37062 37064 
s off
f 37066 37054 37056 
f 37067 37057 37055 
s 4
f 37068 37209 37187 
f 37073 37070 37068 
f 37068 37174 37072 
f 37073 37188 37210 
s 8
f 37271 37422 37394 
f 37258 37475 37500 
f 37424 37268 37405 
f 37469 37259 37499 
f 37499 37298 37469 
f 37520 37336 37452 
f 37299 37500 37475 
f 37335 37523 37456 
f 37474 37260 37501 
f 37352 37348 37378 
f 37262 37464 37510 
f 37351 37355 37381 
f 37479 37261 37503 
f 37353 37346 37379 
f 37263 37473 37508 
f 37347 37354 37380 
f 37433 37342 37406 
f 37427 37269 37395 
f 37341 37431 37400 
f 37270 37418 37399 
f 37319 37530 37534 
f 37265 37485 37491 
f 37531 37316 37536 
f 37481 37264 37495 
f 37320 37533 37539 
f 37267 37487 37492 
f 37532 37321 37535 
f 37482 37266 37489 
f 37440 37273 37384 
f 37506 37300 37466 
f 37303 37509 37478 
f 37275 37441 37389 
s off
f 36992 37106 37141 
f 37106 36996 37114 
f 36998 37106 37114 
f 37106 37002 37125 
f 37031 37107 37126 
f 37027 37115 37107 
f 37025 37107 37115 
f 37021 37142 37107 
s 8
f 37553 37256 37549 
f 37257 37554 37551 
f 37326 37568 37562 
f 37566 37325 37559 
f 37324 37569 37560 
f 37571 37327 37563 
s off
f 36996 36997 37114 
f 36998 37114 36997 
f 37027 37026 37115 
f 37026 37025 37115 
s 8
f 37333 37550 37552 
f 37548 37330 37555 
f 37272 37434 37388 
f 37438 37274 37386 
f 37544 37306 37540 
f 37304 37547 37542 
f 37545 37307 37543 
f 37305 37546 37541 
s 4
f 37068 37124 37174 
s off
f 37002 36997 37125 
f 37026 37031 37126 
s 8
f 37455 37367 37516 
f 37361 37459 37519 
f 37365 37453 37517 
f 37457 37360 37518 
f 37514 37308 37462 
f 37309 37515 37460 
f 37432 37339 37398 
f 37338 37426 37407 
f 37420 37343 37402 
f 37340 37425 37403 
f 37348 37352 37374 
f 37346 37353 37375 
f 37355 37351 37376 
f 37354 37347 37377 
s off
f 36992 37141 36997 
f 37021 37026 37142 
s 8
f 37371 37460 37515 
f 37368 37461 37512 
f 37462 37370 37514 
f 37463 37369 37513 
s 4
f 37073 37219 37147 
s 8
f 37323 37556 37567 
f 37557 37322 37564 
f 37290 37393 37423 
f 37392 37291 37419 
f 37404 37283 37428 
f 37286 37397 37430 
f 37281 37385 37435 
f 37279 37390 37436 
f 37391 37280 37437 
f 37387 37278 37439 
f 37494 37293 37486 
f 37295 37488 37483 
f 37301 37497 37472 
f 37511 37296 37467 
f 37276 37538 37529 
f 37537 37277 37528 
f 37285 37407 37426 
f 37398 37287 37432 
f 37402 37282 37420 
f 37284 37403 37425 
f 37382 37345 37372 
f 37344 37383 37373 
f 37317 37529 37538 
f 37528 37318 37537 
s 4
f 37068 37172 37124 
f 37073 37147 37173 
f 37072 37174 37069 
s 8
f 37446 37254 37417 
f 37253 37444 37413 
f 37252 37443 37416 
f 37442 37255 37414 
f 37289 37373 37383 
f 37372 37288 37382 
f 37358 37541 37546 
f 37543 37359 37545 
f 37349 37542 37547 
f 37540 37350 37544 
f 37452 37364 37520 
f 37366 37456 37523 
s 4
f 37068 37187 37172 
f 37073 37173 37188 
s 8
f 37332 37551 37554 
f 37549 37331 37553 
f 37498 37302 37468 
f 37297 37507 37477 
f 37493 37292 37480 
f 37294 37490 37484 
f 37527 37329 37524 
f 37328 37526 37525 
f 37356 37415 37447 
f 37412 37357 37445 
f 37313 37521 37454 
f 37522 37314 37458 
f 37516 37312 37455 
f 37315 37519 37459 
f 37513 37311 37463 
f 37310 37512 37461 
f 37518 37337 37457 
f 37334 37517 37453 
f 37362 37454 37521 
f 37458 37363 37522 
s 4
f 37572 37209 37068 
f 37073 37210 37070 
s 8
f 37211 37413 37444 
f 37212 37416 37443 
f 37414 37213 37442 
f 37417 37214 37446 
f 37410 37215 37450 
f 37216 37411 37448 
f 37409 37217 37449 
f 37218 37408 37451 
s 4
f 37071 37219 37073 
s 8
f 37450 37220 37410 
f 37221 37448 37411 
f 37222 37451 37408 
f 37449 37223 37409 
f 37445 37224 37412 
f 37225 37447 37415 
f 37555 37226 37548 
f 37227 37552 37550 
f 37228 37525 37526 
f 37524 37229 37527 
f 37230 37567 37556 
f 37564 37231 37557 
f 37232 37539 37533 
f 37535 37233 37532 
f 37234 37534 37530 
f 37536 37235 37531 
f 37236 37483 37488 
f 37480 37237 37493 
f 37238 37484 37490 
f 37486 37239 37494 
f 37466 37240 37506 
f 37241 37472 37497 
f 37242 37477 37507 
f 37468 37243 37498 
f 37467 37244 37511 
f 37245 37478 37509 
f 37406 37246 37433 
f 37247 37400 37431 
f 37248 37562 37568 
f 37563 37249 37571 
f 37559 37250 37566 
f 37251 37560 37569 
f 37177 37465 37504 
f 37176 37476 37502 
f 37471 37175 37505 
f 37470 37178 37496 
f 37558 37171 37570 
f 37170 37561 37565 
f 37169 37421 37401 
f 37429 37168 37396 
f 37419 37157 37392 
f 37428 37156 37404 
f 37155 37423 37393 
f 37154 37430 37397 
f 37439 37151 37387 
f 37150 37436 37390 
f 37437 37152 37391 
f 37153 37435 37385 
f 37386 37131 37438 
f 37384 37158 37440 
f 37159 37389 37441 
f 37132 37388 37434 
f 37118 37394 37422 
f 37395 37161 37427 
f 37405 37119 37424 
f 37160 37399 37418 
f 37097 37565 37561 
f 37570 37094 37558 
f 37495 37082 37481 
f 37085 37491 37485 
f 37489 37088 37482 
f 37091 37492 37487 
f 37505 37103 37471 
f 37503 37089 37479 
f 37100 37502 37476 
f 37102 37504 37465 
f 37093 37508 37473 
f 37496 37101 37470 
f 37396 37180 37429 
f 37179 37401 37421 
f 37087 37510 37464 
f 37501 37083 37474 
f 37078 37193 37292 
f 37104 37096 37293 
f 37194 37076 37294 
f 37098 37105 37295 
f 37130 37095 37296 
f 37192 37116 37297 
f 37208 37145 37298 
f 37143 37207 37299 
f 37127 37146 37300 
f 37099 37129 37301 
f 37117 37191 37302 
f 37144 37128 37303 
f 37121 37183 37304 
f 37123 37181 37305 
f 37184 37120 37306 
f 37182 37122 37307 
f 37200 37077 37308 
f 37074 37199 37309 
f 37204 37202 37310 
f 37201 37203 37311 
f 37203 37201 37312 
f 37199 37074 37313 
f 37077 37200 37314 
f 37202 37204 37315 
f 37084 37165 37316 
f 37109 37162 37317 
f 37163 37108 37318 
f 37167 37086 37319 
f 37164 37092 37320 
f 37090 37166 37321 
f 37079 37080 37322 
f 37081 37075 37323 
f 37183 37121 37324 
f 37122 37182 37325 
f 37181 37123 37326 
f 37120 37184 37327 
f 37196 37110 37328 
f 37111 37195 37329 
f 37149 37185 37330 
f 37205 37190 37331 
f 37189 37206 37332 
f 37186 37148 37333 
f 37206 37189 37334 
f 37148 37186 37335 
f 37185 37149 37336 
f 37190 37205 37337 
f 37092 37164 37338 
f 37165 37084 37339 
f 37086 37167 37340 
f 37162 37109 37341 
f 37108 37163 37342 
f 37166 37090 37343 
f 37075 37081 37344 
f 37080 37079 37345 
f 37375 37135 37346 
f 37134 37377 37347 
f 37374 37133 37348 
f 37112 37197 37349 
f 37198 37113 37350 
f 37136 37376 37351 
f 37378 37137 37352 
f 37379 37138 37353 
f 37140 37380 37354 
f 37139 37381 37355 
f 37197 37112 37356 
f 37113 37198 37357 
f 37110 37196 37358 
f 37195 37111 37359 
f 37095 37130 37360 
f 37128 37144 37361 
f 37207 37143 37362 
f 37145 37208 37363 
f 37191 37117 37364 
f 37129 37099 37365 
f 37116 37192 37366 
f 37146 37127 37367 
f 37105 37098 37368 
f 37096 37104 37369 
f 37193 37078 37370 
f 37076 37194 37371 
f 37288 37372 37168 
f 37373 37289 37169 
f 37133 37374 37213 
f 37135 37375 37214 
f 37376 37136 37211 
f 37377 37134 37212 
f 37137 37378 37217 
f 37138 37379 37215 
f 37380 37140 37216 
f 37381 37139 37218 
f 37345 37382 37080 
f 37383 37344 37081 
f 37158 37384 37239 
f 37385 37281 37267 
f 37131 37386 37237 
f 37278 37387 37264 
f 37388 37132 37238 
f 37389 37159 37236 
f 37390 37279 37265 
f 37280 37391 37266 
f 37291 37392 37260 
f 37393 37290 37262 
f 37394 37118 37242 
f 37161 37395 37244 
f 37180 37396 37259 
f 37397 37286 37263 
f 37287 37398 37178 
f 37399 37160 37241 
f 37400 37247 37245 
f 37401 37179 37258 
f 37282 37402 37175 
f 37403 37284 37176 
f 37283 37404 37261 
f 37119 37405 37243 
f 37246 37406 37240 
f 37407 37285 37177 
f 37408 37218 37139 
f 37217 37409 37137 
f 37215 37410 37138 
f 37411 37216 37140 
f 37357 37412 37113 
f 37413 37211 37136 
f 37213 37414 37133 
f 37415 37356 37112 
f 37416 37212 37134 
f 37214 37417 37135 
f 37418 37270 37275 
f 37157 37419 37151 
f 37343 37420 37166 
f 37421 37169 37289 
f 37422 37271 37272 
f 37423 37155 37150 
f 37268 37424 37274 
f 37425 37340 37167 
f 37426 37338 37164 
f 37269 37427 37273 
f 37156 37428 37152 
f 37168 37429 37288 
f 37430 37154 37153 
f 37431 37341 37109 
f 37339 37432 37165 
f 37342 37433 37108 
f 37434 37272 37271 
f 37435 37153 37154 
f 37436 37150 37155 
f 37152 37437 37156 
f 37274 37438 37268 
f 37151 37439 37157 
f 37273 37440 37269 
f 37441 37275 37270 
f 37255 37442 37101 
f 37443 37252 37102 
f 37444 37253 37100 
f 37224 37445 37089 
f 37254 37446 37103 
f 37447 37225 37093 
f 37448 37221 37091 
f 37223 37449 37082 
f 37220 37450 37088 
f 37451 37222 37085 
f 37364 37452 37191 
f 37453 37365 37099 
f 37454 37362 37143 
f 37367 37455 37146 
f 37456 37366 37192 
f 37360 37457 37095 
f 37363 37458 37145 
f 37459 37361 37144 
f 37460 37371 37194 
f 37461 37368 37098 
f 37370 37462 37193 
f 37369 37463 37096 
f 37464 37262 37290 
f 37465 37177 37285 
f 37240 37466 37246 
f 37244 37467 37161 
f 37243 37468 37119 
f 37259 37469 37180 
f 37178 37470 37287 
f 37175 37471 37282 
f 37472 37241 37160 
f 37473 37263 37286 
f 37260 37474 37291 
f 37475 37258 37179 
f 37476 37176 37284 
f 37477 37242 37118 
f 37478 37245 37247 
f 37261 37479 37283 
f 37237 37480 37131 
f 37264 37481 37278 
f 37266 37482 37280 
f 37483 37236 37159 
f 37484 37238 37132 
f 37485 37265 37279 
f 37239 37486 37158 
f 37487 37267 37281 
f 37488 37295 37105 
f 37088 37489 37220 
f 37490 37294 37076 
f 37491 37085 37222 
f 37492 37091 37221 
f 37292 37493 37078 
f 37293 37494 37104 
f 37082 37495 37223 
f 37101 37496 37255 
f 37497 37301 37129 
f 37302 37498 37117 
f 37298 37499 37208 
f 37500 37299 37207 
f 37083 37501 37229 
f 37502 37100 37253 
f 37089 37503 37224 
f 37504 37102 37252 
f 37103 37505 37254 
f 37300 37506 37127 
f 37507 37297 37116 
f 37508 37093 37225 
f 37509 37303 37128 
f 37510 37087 37228 
f 37296 37511 37130 
f 37512 37310 37202 
f 37311 37513 37201 
f 37308 37514 37200 
f 37515 37309 37199 
f 37312 37516 37203 
f 37517 37334 37189 
f 37337 37518 37190 
f 37519 37315 37204 
f 37336 37520 37185 
f 37521 37313 37074 
f 37314 37522 37077 
f 37523 37335 37186 
f 37229 37524 37083 
f 37525 37228 37087 
f 37526 37328 37110 
f 37329 37527 37111 
f 37318 37528 37163 
f 37529 37317 37162 
f 37530 37319 37086 
f 37316 37531 37084 
f 37321 37532 37090 
f 37533 37320 37092 
f 37534 37234 37248 
f 37233 37535 37249 
f 37235 37536 37250 
f 37277 37537 37171 
f 37538 37276 37170 
f 37539 37232 37251 
f 37350 37540 37198 
f 37541 37358 37196 
f 37542 37349 37197 
f 37359 37543 37195 
f 37306 37544 37184 
f 37307 37545 37182 
f 37546 37305 37181 
f 37547 37304 37183 
f 37330 37548 37149 
f 37331 37549 37205 
f 37550 37333 37148 
f 37551 37332 37206 
f 37552 37227 37230 
f 37256 37553 37094 
f 37554 37257 37097 
f 37226 37555 37231 
f 37556 37323 37075 
f 37322 37557 37079 
f 37171 37558 37277 
f 37250 37559 37235 
f 37560 37251 37232 
f 37561 37170 37276 
f 37562 37248 37234 
f 37249 37563 37233 
f 37231 37564 37226 
f 37565 37097 37257 
f 37325 37566 37122 
f 37567 37230 37227 
f 37568 37326 37123 
f 37569 37324 37121 
f 37094 37570 37256 
f 37327 37571 37120 
s 4
f 37068 37070 37572 
s 16
f 37016 37018 37573 
f 37573 37015 37016 
f 37044 37574 37045 
f 37047 37045 37574 
f 37575 37006 37007 
f 37007 37009 37575 
f 37035 37576 37036 
f 37038 37036 37576 
s off
f 37580 37583 37579 37582 37577 37581 37578 37584 
s 1026
f 37786 37816 37738 
s 257
f 37681 37714 37805 
s 1026
f 37707 37738 37624 
s 257
f 37714 37681 37698 
s 1026
f 37648 37625 37728 
s 264
f 37789 37778 37723 
s 1040
f 37780 37784 37658 
s 257
f 37652 37620 37700 
s 1026
f 37649 37724 37727 
s 264
f 37815 37777 37721 
s 1026
f 37812 37797 37724 
s 257
f 37628 37621 37695 
s 1026
f 37768 37725 37809 
f 37725 37768 37600 
s 257
f 37792 37779 37696 
f 37682 37696 37699 
f 37626 37722 37716 
s 1026
f 37732 37657 37667 
s 264
f 37779 37792 37722 
s 1040
f 37657 37732 37799 
s 257
f 37701 37723 37712 
s 1026
f 37731 37658 37669 
s 264
f 37723 37701 37789 
s 1040
f 37658 37731 37780 
s 257
f 37697 37721 37719 
s 1040
f 37647 37750 37794 
s 264
f 37721 37697 37815 
s 1026
f 37750 37647 37614 
s 257
f 37703 37683 37715 
s 1026
f 37729 37739 37668 
s 264
f 37683 37703 37819 
s 1040
f 37739 37729 37790 
s 264
f 37639 37715 37717 
s 1040
f 37645 37668 37745 
s 257
f 37715 37639 37703 
s 1026
f 37668 37645 37729 
s 264
f 37717 37698 37639 
s 1040
f 37745 37730 37645 
s 257
f 37698 37717 37714 
s 1026
f 37730 37745 37650 
s 264
f 37718 37699 37692 
s 1040
f 37743 37733 37734 
s 257
f 37699 37718 37682 
s 1026
f 37733 37743 37598 
s 264
f 37720 37700 37694 
s 1040
f 37744 37728 37735 
s 257
f 37700 37720 37652 
s 1026
f 37728 37744 37648 
s 264
f 37713 37695 37693 
s 1040
f 37666 37727 37736 
s 257
f 37695 37713 37628 
s 1026
f 37727 37666 37649 
s 264
f 37693 37719 37713 
s 1040
f 37736 37670 37666 
s 257
f 37719 37693 37697 
s 1026
f 37670 37736 37596 
s 264
f 37694 37712 37720 
s 1040
f 37735 37669 37744 
s 257
f 37712 37694 37701 
s 1026
f 37669 37735 37731 
s 264
f 37692 37716 37718 
s 1040
f 37734 37667 37743 
s 257
f 37716 37692 37626 
s 1026
f 37667 37734 37732 
f 37688 37656 37798 
f 37656 37688 37623 
s 1040
f 37726 37660 37782 
s 1026
f 37660 37726 37679 
f 37811 37814 37767 
f 37684 37767 37588 
f 37689 37755 37757 
f 37755 37689 37821 
f 37691 37753 37758 
f 37753 37691 37806 
f 37752 37622 37702 
s 1040
f 37622 37752 37793 
s 1026
f 37756 37611 37617 
s 1040
f 37611 37756 37761 
s 1026
f 37754 37610 37585 
s 1040
f 37610 37754 37760 
s 1026
f 37820 37822 37769 
f 37685 37769 37591 
f 37705 37690 37636 
s 1040
f 37787 37801 37690 
s 1026
f 37627 37776 37608 
s 1040
f 37795 37810 37776 
s 1026
f 37804 37791 37711 
f 37708 37711 37642 
f 37673 37629 37633 
s 1040
f 37629 37673 37772 
s 1026
f 37674 37630 37632 
s 1040
f 37630 37674 37773 
s 1026
f 37821 37818 37755 
f 37704 37631 37634 
f 37632 37765 37674 
s 1040
f 37824 37807 37765 
s 1026
f 37633 37709 37673 
s 1040
f 37709 37633 37802 
f 37762 37634 37637 
s 1026
f 37634 37762 37704 
s 1040
f 37759 37635 37638 
s 1026
f 37635 37759 37706 
s 1040
f 37761 37636 37611 
s 1026
f 37636 37761 37705 
f 37757 37637 37689 
s 1040
f 37637 37757 37762 
s 1026
f 37758 37638 37691 
s 1040
f 37638 37758 37759 
f 37760 37702 37610 
s 1026
f 37702 37760 37752 
s 1040
f 37773 37640 37630 
s 1026
f 37640 37773 37590 
f 37771 37641 37618 
s 1040
f 37641 37771 37774 
s 1026
f 37642 37774 37708 
s 1040
f 37774 37642 37641 
f 37775 37643 37661 
s 1026
f 37643 37775 37587 
s 1040
f 37772 37644 37629 
s 1026
f 37644 37772 37653 
s 1040
f 37763 37737 37678 
s 1026
f 37737 37763 37607 
f 37585 37646 37754 
s 1040
f 37793 37813 37622 
s 1026
f 37806 37796 37753 
f 37706 37605 37635 
f 37687 37589 37817 
f 37589 37687 37671 
f 37686 37593 37781 
f 37593 37686 37742 
f 37650 37619 37730 
s 1040
f 37790 37803 37739 
s 1026
f 37598 37651 37733 
s 1040
f 37799 37808 37657 
s 1026
f 37807 37824 37710 
f 37590 37710 37640 
f 37597 37653 37788 
f 37653 37597 37644 
f 37654 37594 37595 
s 1040
f 37782 37783 37726 
s 1026
f 37809 37800 37768 
f 37601 37655 37602 
f 37801 37787 37751 
f 37617 37751 37756 
s 1040
f 37599 37740 37785 
s 1026
f 37740 37599 37616 
s 1040
f 37592 37741 37823 
s 1026
f 37741 37592 37615 
f 37596 37659 37670 
s 1040
f 37797 37812 37659 
s 1026
f 37618 37764 37771 
s 1040
f 37791 37804 37764 
s 1026
f 37608 37661 37627 
s 1040
f 37661 37608 37775 
s 1026
f 37810 37795 37662 
f 37587 37662 37643 
f 37624 37663 37707 
s 1040
f 37663 37624 37748 
f 37816 37786 37664 
s 1026
f 37586 37664 37609 
f 37623 37665 37656 
s 1040
f 37665 37623 37749 
s 1026
f 37742 37613 37593 
s 1040
f 37613 37742 37747 
s 1026
f 37616 37749 37740 
s 1040
f 37749 37616 37665 
s 1026
f 37609 37748 37586 
s 1040
f 37748 37609 37663 
s 1026
f 37615 37746 37741 
s 1040
f 37746 37615 37612 
s 1026
f 37614 37747 37750 
s 1040
f 37747 37614 37613 
s 1026
f 37671 37612 37589 
s 1040
f 37612 37671 37746 
s 1026
f 37603 37672 37604 
s 1040
f 37822 37820 37672 
f 37766 37602 37675 
s 1026
f 37602 37766 37601 
s 1040
f 37770 37604 37676 
s 1026
f 37604 37770 37603 
f 37600 37675 37725 
s 1040
f 37675 37600 37766 
s 1026
f 37591 37676 37685 
s 1040
f 37676 37591 37770 
s 1026
f 37595 37677 37654 
s 1040
f 37677 37595 37606 
s 1026
f 37588 37678 37684 
s 1040
f 37678 37588 37763 
f 37606 37679 37677 
s 1026
f 37679 37606 37660 
f 37607 37680 37737 
s 1040
f 37814 37811 37680 
s 544
f 37813 37819 37703 
s 516
f 37681 37585 37610 
f 37597 37682 37718 
s 544
f 37792 37802 37633 
s 516
f 37795 37805 37714 
f 37683 37627 37661 
s 544
f 37811 37790 37729 
s 516
f 37619 37684 37678 
s 544
f 37820 37780 37731 
s 516
f 37625 37685 37676 
f 37686 37649 37666 
s 544
f 37812 37794 37750 
s 516
f 37687 37648 37744 
s 544
f 37784 37823 37741 
s 516
f 37688 37598 37743 
s 544
f 37808 37785 37740 
s 516
f 37789 37821 37689 
f 37620 37689 37637 
s 544
f 37626 37690 37801 
s 516
f 37690 37626 37692 
f 37815 37806 37691 
f 37621 37691 37638 
f 37692 37636 37690 
s 544
f 37636 37692 37699 
s 516
f 37693 37635 37605 
s 544
f 37635 37693 37695 
s 516
f 37694 37634 37631 
s 544
f 37634 37694 37700 
s 516
f 37638 37695 37621 
s 544
f 37695 37638 37635 
s 516
f 37617 37696 37779 
f 37696 37617 37611 
s 544
f 37806 37815 37697 
s 516
f 37605 37697 37693 
f 37610 37698 37681 
s 544
f 37698 37610 37702 
s 516
f 37611 37699 37696 
s 544
f 37699 37611 37636 
s 516
f 37637 37700 37620 
s 544
f 37700 37637 37634 
f 37821 37789 37701 
s 516
f 37631 37701 37694 
f 37639 37702 37622 
s 544
f 37702 37639 37698 
f 37703 37622 37813 
s 516
f 37622 37703 37639 
f 37592 37704 37762 
f 37818 37817 37589 
f 37599 37705 37761 
f 37787 37798 37656 
f 37647 37706 37759 
f 37796 37781 37593 
f 37646 37707 37663 
s 544
f 37786 37793 37752 
s 516
f 37594 37708 37774 
s 544
f 37804 37782 37660 
s 516
f 37709 37601 37766 
f 37800 37788 37653 
f 37652 37710 37824 
f 37710 37652 37720 
f 37628 37711 37791 
f 37711 37628 37713 
s 544
f 37712 37630 37640 
s 516
f 37630 37712 37723 
f 37713 37642 37711 
s 544
f 37642 37713 37719 
s 516
f 37714 37662 37795 
f 37662 37714 37717 
s 544
f 37715 37661 37643 
s 516
f 37661 37715 37683 
s 544
f 37716 37629 37644 
s 516
f 37629 37716 37722 
f 37717 37643 37662 
s 544
f 37643 37717 37715 
s 516
f 37718 37644 37597 
s 544
f 37644 37718 37716 
f 37719 37641 37642 
s 516
f 37641 37719 37721 
f 37720 37640 37710 
s 544
f 37640 37720 37712 
f 37618 37721 37777 
s 516
f 37721 37618 37641 
s 544
f 37633 37722 37792 
s 516
f 37722 37633 37629 
s 544
f 37632 37723 37778 
s 516
f 37723 37632 37630 
f 37654 37724 37797 
f 37724 37654 37677 
f 37799 37809 37725 
f 37651 37725 37675 
s 544
f 37596 37726 37783 
s 516
f 37726 37596 37736 
f 37677 37727 37724 
s 544
f 37727 37677 37679 
s 516
f 37676 37728 37625 
s 544
f 37728 37676 37604 
s 516
f 37680 37729 37645 
s 544
f 37729 37680 37811 
s 516
f 37678 37730 37619 
s 544
f 37730 37678 37737 
f 37731 37672 37820 
s 516
f 37672 37731 37735 
f 37655 37732 37734 
s 544
f 37809 37799 37732 
s 516
f 37675 37733 37651 
s 544
f 37733 37675 37602 
s 516
f 37734 37602 37655 
s 544
f 37602 37734 37733 
s 516
f 37735 37604 37672 
s 544
f 37604 37735 37728 
s 516
f 37736 37679 37726 
s 544
f 37679 37736 37727 
s 516
f 37645 37737 37680 
s 544
f 37737 37645 37730 
s 516
f 37650 37738 37816 
f 37738 37650 37745 
s 544
f 37586 37739 37803 
s 516
f 37739 37586 37748 
f 37657 37740 37749 
s 544
f 37740 37657 37808 
s 516
f 37658 37741 37746 
s 544
f 37741 37658 37784 
s 516
f 37666 37742 37686 
s 544
f 37742 37666 37670 
s 516
f 37743 37623 37688 
s 544
f 37623 37743 37667 
s 516
f 37744 37671 37687 
s 544
f 37671 37744 37669 
s 516
f 37745 37624 37738 
s 544
f 37624 37745 37668 
f 37669 37746 37671 
s 516
f 37746 37669 37658 
s 544
f 37670 37747 37742 
s 516
f 37747 37670 37659 
s 544
f 37668 37748 37624 
s 516
f 37748 37668 37739 
s 544
f 37667 37749 37623 
s 516
f 37749 37667 37657 
f 37659 37750 37747 
s 544
f 37750 37659 37812 
s 516
f 37751 37656 37665 
f 37656 37751 37787 
f 37664 37752 37760 
s 544
f 37752 37664 37786 
s 516
f 37753 37593 37613 
f 37593 37753 37796 
s 544
f 37754 37663 37609 
s 516
f 37663 37754 37646 
f 37755 37589 37612 
f 37589 37755 37818 
s 544
f 37756 37665 37616 
s 516
f 37665 37756 37751 
s 544
f 37757 37612 37615 
s 516
f 37612 37757 37755 
s 544
f 37758 37613 37614 
s 516
f 37613 37758 37753 
s 544
f 37614 37759 37758 
s 516
f 37759 37614 37647 
s 544
f 37609 37760 37754 
s 516
f 37760 37609 37664 
s 544
f 37616 37761 37756 
s 516
f 37761 37616 37599 
s 544
f 37615 37762 37757 
s 516
f 37762 37615 37592 
s 544
f 37608 37763 37588 
s 516
f 37763 37608 37776 
f 37764 37660 37606 
s 544
f 37660 37764 37804 
s 516
f 37765 37603 37770 
s 544
f 37603 37765 37807 
f 37673 37766 37600 
s 516
f 37766 37673 37709 
f 37767 37587 37775 
f 37587 37767 37814 
f 37768 37653 37772 
f 37653 37768 37800 
f 37769 37590 37773 
f 37590 37769 37822 
s 544
f 37674 37770 37591 
s 516
f 37770 37674 37765 
s 544
f 37771 37606 37595 
s 516
f 37606 37771 37764 
s 544
f 37600 37772 37673 
s 516
f 37772 37600 37768 
s 544
f 37591 37773 37674 
s 516
f 37773 37591 37769 
s 544
f 37595 37774 37771 
s 516
f 37774 37595 37594 
s 544
f 37588 37775 37608 
s 516
f 37775 37588 37767 
f 37776 37607 37763 
s 544
f 37607 37776 37810 
s 516
f 37791 37777 37628 
s 257
f 37621 37628 37777 
s 516
f 37824 37778 37652 
s 257
f 37620 37652 37778 
s 544
f 37801 37779 37626 
s 264
f 37722 37626 37779 
s 516
f 37685 37625 37780 
s 1026
f 37784 37780 37625 
s 1040
f 37794 37781 37647 
s 544
f 37706 37647 37781 
s 1026
f 37783 37782 37594 
s 516
f 37708 37594 37782 
s 1026
f 37594 37654 37783 
s 516
f 37797 37783 37654 
f 37823 37784 37648 
s 1026
f 37625 37648 37784 
s 516
f 37598 37688 37785 
s 1026
f 37798 37785 37688 
f 37738 37707 37786 
s 516
f 37793 37786 37707 
s 544
f 37798 37787 37705 
s 1040
f 37690 37705 37787 
f 37802 37788 37709 
s 544
f 37601 37709 37788 
s 516
f 37689 37620 37789 
s 257
f 37778 37789 37620 
s 516
f 37684 37619 37790 
s 1026
f 37803 37790 37619 
s 1040
f 37764 37618 37791 
s 544
f 37777 37791 37618 
s 516
f 37802 37792 37682 
s 257
f 37696 37682 37792 
s 1026
f 37813 37793 37646 
s 516
f 37707 37646 37793 
f 37649 37686 37794 
s 1026
f 37781 37794 37686 
s 1040
f 37776 37627 37795 
s 544
f 37805 37795 37627 
f 37781 37796 37706 
s 1040
f 37605 37706 37796 
s 544
f 37783 37797 37596 
s 1040
f 37659 37596 37797 
f 37785 37798 37599 
s 544
f 37705 37599 37798 
s 516
f 37725 37651 37799 
s 1026
f 37808 37799 37651 
s 1040
f 37655 37601 37800 
s 544
f 37788 37800 37601 
s 516
f 37779 37801 37617 
s 1026
f 37751 37617 37801 
s 516
f 37682 37597 37802 
s 1026
f 37788 37802 37597 
s 516
f 37816 37803 37650 
s 1026
f 37619 37650 37803 
f 37711 37708 37804 
s 516
f 37782 37804 37708 
s 544
f 37627 37683 37805 
s 264
f 37819 37805 37683 
s 544
f 37697 37605 37806 
s 1040
f 37796 37806 37605 
s 1026
f 37710 37590 37807 
s 516
f 37822 37807 37590 
f 37785 37808 37598 
s 1026
f 37651 37598 37808 
s 544
f 37732 37655 37809 
s 1040
f 37800 37809 37655 
s 1026
f 37662 37587 37810 
s 516
f 37814 37810 37587 
s 1026
f 37767 37684 37811 
s 516
f 37790 37811 37684 
f 37794 37812 37649 
s 1026
f 37724 37649 37812 
s 516
f 37819 37813 37585 
s 1026
f 37646 37585 37813 
s 1040
f 37680 37607 37814 
s 544
f 37810 37814 37607 
s 516
f 37691 37621 37815 
s 257
f 37777 37815 37621 
s 1040
f 37664 37586 37816 
s 544
f 37803 37816 37586 
s 1040
f 37823 37817 37592 
s 544
f 37704 37592 37817 
f 37817 37818 37704 
s 1040
f 37631 37704 37818 
s 516
f 37585 37681 37819 
s 257
f 37805 37819 37681 
s 1026
f 37769 37685 37820 
s 516
f 37780 37820 37685 
s 544
f 37701 37631 37821 
s 1040
f 37818 37821 37631 
f 37672 37603 37822 
s 544
f 37807 37822 37603 
s 516
f 37648 37687 37823 
s 1026
f 37817 37823 37687 
s 1040
f 37765 37632 37824 
s 544
f 37778 37824 37632 
s off
f 37825 37826 37827 37828 
f 37829 37830 37831 37832 
f 37829 37832 37833 37834 
f 37832 37831 37835 37833 
f 37831 37830 37836 37835 
f 37830 37829 37834 37836 
s 109
f 37834 37833 37837 37838 
f 37833 37835 37839 37837 
f 37835 37836 37840 37839 
f 37836 37834 37838 37840 
s off
f 37838 37837 37826 37825 
f 37837 37839 37827 37826 
f 37839 37840 37828 37827 
f 37840 37838 37825 37828 
# 40 polygons - 62552 triangles`